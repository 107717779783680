//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CheckCircle } from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import { API } from 'aws-amplify';
import Loading from 'components/Loading';
import { lighten } from 'polished';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleGetClassByCode } from 'store/classes/thunks';
import { handleUpdateOrganization , getOrganizationById } from 'store/organizations/thunks';
//  -- Thunks -------------------------
import { handleAddStudentWithClass, handleCheckUsername, handleRegisterStudent } from 'store/users/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { map , get } from 'lodash';
import { authHelper } from 'utils/lib/authHelper';
import { Auth } from 'aws-amplify';
import config from 'config';




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Description = styled.span`
    color: ${COLOR.black500};
    display: block;
    font-size: ${p => p.align ? '12px' : '14px'};
    font-weight: 300;
    line-height: 20px;
    text-align: ${p => p.align && 'center'};
`;

const Error = styled.div`
    background-color: ${lighten(0.45, 'red')};
    border: 1px solid ${lighten(0.2, 'red')};
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    width: 100%;
`;

const Form = styled.form`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Header = styled.span`
    color: ${COLOR.black100};
    display: block;
    font-size: ${p => p.align ? '20px' : '24px'};
    font-weight: 700;
    line-height: 26px;
    margin-bottom: ${p => p.align ? '12px' : '16px'};
    text-align: ${p => p.align && 'center'};
`;

const Link = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.green100};
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    transition: all 300ms ease;

    &:hover {
        text-decoration: underline;
    }
`;

const RegisterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
`;

const SuccessContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SuccessDescription = styled.span`
    color: ${COLOR.black500};
    display: block;
    font-size: 13px;
    margin-bottom: 16px;
    text-align: center;
`;

const SuccessHeader = styled.span`
    color: ${COLOR.black100};
    display: block;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Text = styled.span`
    color: ${COLOR.black400};
    display: inline-block;
    font-size: 13px;
    margin-right: 3px;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  -- Description
//  User class registration
//
//  -- Props
//  ...
//
const RegistrationForm = ({ toggleRegistration }) => {
    const dispatch = useDispatch();
    const [usernameError, setUsernameError] = useState(false);
    const [errorCaracteres, setErrorCaracteres] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formatPasswordError, setFormatPasswordError] = useState(false);
    const [userNameCheckLoader, setUserNameCheckLoader] = useState(false);

    const usernameValidation = async (id, value) => {
        var validation = /^[0-9a-zA-Z]+$/;
        if(!value){
            setUsernameError(true)
        }
       else if (validation.test(value.trim())) {
            setErrorCaracteres(false);
            setUserNameCheckLoader(true);
            const nameCheck = await dispatch(handleCheckUsername(value.trim()));
            if (nameCheck) {
                setUsernameError(true)
            } else {
                setUsernameError(false)
            }
            setUserNameCheckLoader(false);
        }
        else{
            if(value.trim() != ""){
                setUsernameError(false)
                setErrorCaracteres(true);
            }
        }
    };

    //  -- Form Data ---------------------
    const [data, setData] = useState({});

    const handleChange = (id, value) => {
        let _value = value;
        _value = value?.trim()
        setData({
            ...data,
            [id]: _value
        });
        setFormatPasswordError(false);
        setError(null);
    };

    //  -- Form Submit --------------------


    const handleSubmit = async e => {
        e.preventDefault();
        setError("");
        let goodPassword = verifyPassword(data.password);
        if(goodPassword){
            setLoading(true);
            const codeClass = await dispatch(handleGetClassByCode(data.classCode.trim()));
            if (codeClass == undefined) {
                setError("Class with that code doesn't exist, please contact your administrator")
                setLoading(false);
            } else {
                const nameCheck = await dispatch(handleCheckUsername(data.username.trim()));
                // const classBook = map(codeClass?.books,b=>({
                //     "bookId": b.bookId,
                //     "description": b.description
                // }))
                if(!nameCheck){
                    // var booksUser = [];
                    let res = authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD })
                    await Auth.signIn(res);
                    const createStudent = await dispatch(handleAddStudentWithClass({
                        classId: codeClass.id,
                        books: [],
                        username: get(data, "username", ''),
                        password: get(data, 'password', ''),
                        firstName: get(data, "firstName", ''),
                        lastName: get(data, "lastName", ''),
                        grade: get(data, "grade", ''),
                        orgId: codeClass.orgId,
                    }))
                    Auth.signOut();
                    setLoading(false);
                    if (createStudent&&!createStudent.error) {
                        setSuccess('Student account successfully created.')
                    } else {
                        setError('There was an issue creating your account, please contact your administrator');
                    }
                    // if(classBook.length > 0){
                    //     booksUser = {
                    //         "books": classBook,
                    //         "userId": "",
                    //         "validityDate": "2022-01-01",
                    //         "orgId": codeClass.orgId
                    //         }
                    //     let infoUser = {
                    //         ...data,
                    //         orgId: codeClass.orgId,
                    //         role: 'student'
                    //     }
                    //     const classOrgId = codeClass.orgId;
                    //     const classOrg = await getOrganizationById(classOrgId);
                    //     const newOrgCount = Number((classOrg.maxCount != "undefined" ? classOrg.maxCount : 0)) + Number(classBook.length);
                    //     let studentLicenses = Number(classOrg.studentCount)+1;
                    //     const orgData = {
                    //         studentCount: studentLicenses.toString(),
                    //         maxCount: newOrgCount.toString()
                    //     };

                    //     let cClass = [{...codeClass}]
                    //     if (newOrgCount < parseInt(classOrg.licenses)) {
                    //         const registrationSuccess = await dispatch(handleRegisterStudent(infoUser,booksUser, cClass, true,true));
                    //         await dispatch(handleUpdateOrganization(classOrg.id, { ...classOrg, ...orgData }))

                    //         setLoading(false);
                    //         if (registrationSuccess&&!registrationSuccess.error) {
                    //             setSuccess('Student account successfully created.')
                    //         } else {
                    //             setError('There was an issue creating your account, please contact your administrator');
                    //         }
                    //     } else {
                    //         setError("Unable to register, max number of licenses exceeded.");
                    //         setLoading(false);
                    //     }

                    // }else{
                    //     setError("Class with that code doesn't have books, please contact your administrator");
                    //     setLoading(false);
                    // }
                }else{
                    setError("Username not available");
                    setLoading(false);
                }
            }
        }else{
            setFormatPasswordError(true);
            setLoading(false);
        }
    };

    function verifyPassword(password){
        var paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if(!password){
                setFormatPasswordError(true);
                return false;
        }
        else if(password.match(paswd))
        {
            setFormatPasswordError(false);
            return true;
        }else
        {
            setFormatPasswordError(true);
            return false;
        }
    }

    return (
        <>
            {!success ? (
                <>
                    <Form onSubmit={handleSubmit}>
                        <Row style={{ width: '100%' }}>
                            <Col span={24}>
                                <Header align="center">Register for a Class</Header>
                            </Col>
                        </Row>
                        <Row gutter={[0, 40]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Description align="center">You will need your class code, please get in contact with your teacher if you do not have this information.</Description>
                            </Col>
                        </Row>
                        {error && (
                            <Row gutter={[0, 24]} style={{ width: '100%' }}>
                                <Col span={24}>
                                    <Error>{error}</Error>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={[8, 24]} style={{ width: '100%',marginTop:10 }}>
                            <Col span={24}>
                                <TextField
                                    autoComplete="off"
                                    id="username"
                                    defaultValue={' '}
                                    error={usernameError}
                                    onChange={e => handleChange(e.target.id, e.target.value)}
                                    label="Unique Username"
                                    onBlur={e => usernameValidation(e.target.id, e.target.value)}
                                    variant="outlined"
                                    value={data.username}
                                    helperText={usernameError && 'Username not available'}
                                />
                            </Col>
                            { errorCaracteres &&
                                <>
                                    <FormHelperText error>Please user only alphanumeric characters.</FormHelperText>
                                </>
                            }
                        </Row>
                        <Row gutter={[8, 24]} style={{ width: '100%',marginTop:10 }}>
                            <Col span={24}>
                                <TextField
                                    autoComplete="off"
                                    id="classCode"
                                    defaultValue={' '}
                                    value={data.classCode}
                                    onChange={e => handleChange(e.target.id, e.target.value)}
                                    label="Class Code"
                                    variant="outlined"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 24]} style={{ width: '100%',marginTop:10 }}>
                            <Col span={24}>
                                <TextField
                                    autoComplete="off"
                                    id="firstName"
                                    defaultValue={' '}
                                    value={data.firstName}
                                    onChange={e => handleChange(e.target.id, e.target.value)}
                                    label="First Name"
                                    variant="outlined"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 24]} style={{ width: '100%',marginTop:10 }}>
                            <Col xs={{ span: 24 }} md={{ span: 18 }}>
                                <TextField
                                    autoComplete="off"
                                    id="lastName"
                                    defaultValue={' '}
                                    value={data.lastName}
                                    onChange={e => handleChange(e.target.id, e.target.value)}
                                    label="Last Name"
                                    variant="outlined"
                                />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 6 }}>
                                <TextField
                                    autoComplete="off"
                                    id="grade"
                                    defaultValue={' '}
                                    value={data.grade}
                                    onChange={e => handleChange(e.target.id, e.target.value)}
                                    label="Grade"
                                    variant="outlined"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 24]} style={{ width: '100%',marginTop:10 }}>
                            <Col span={24}>
                                <TextField
                                    autoComplete="off"
                                    id="password"
                                    error={formatPasswordError}
                                    defaultValue={' '}
                                    value={data.password}
                                    onChange={e => handleChange(e.target.id, e.target.value)}
                                    onBlur={e => verifyPassword(e.target.value)}
                                    label="Password"
                                    variant="outlined"
                                />
                                { formatPasswordError &&
                                    <>
                                        <FormHelperText error>Please use 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.</FormHelperText>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row gutter={[8, 24]} style={{ width: '100%',marginTop:10 }}>
                            <Col span={24}>
                                <Button
                                    disabled={(!(data.username && data.classCode && data.firstName && data.lastName && data.grade && data.password)||userNameCheckLoader||usernameError|| errorCaracteres || loading || error)}
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    type='submit'
                                >
                                    {loading ? <Loading size={20} white /> : 'Register'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <RegisterContainer>
                        <Text>Already registered for a class?</Text>
                        <Link onClick={toggleRegistration}>Login Now</Link>
                    </RegisterContainer>
                </>
            ) : (
                <SuccessContainer>
                    <CheckCircle style={{ color: COLOR.green100, fontSize: '128px', marginBottom: '24px' }} />
                    <SuccessHeader>Welcome {data.firstName} {data.lastName}</SuccessHeader>
                    <SuccessDescription>You have been successfully registered, click the button below to login and get started.</SuccessDescription>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={toggleRegistration}
                        style={{ width: '100%' }}
                    >
                        Login
                    </Button>
                </SuccessContainer>
            )}
        </>
    );
};

//  -- Prop types ---------------------
RegistrationForm.propTypes = {};

//  -- Default props ------------------
RegistrationForm.defaultProps = {};

export default RegistrationForm;
