//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from "react-redux";
import { map } from "lodash";
import { filter } from "lodash";

//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const bookListSelecter = (state) => state.booksAllList;
const bookOrderSelecter = (state) => state.bookOrder;
const bookListActiveInActiveSelecter = (state) => state.booksActiveInActive;
//  -- Examples array selector --------

export const useAllBook = () => {
    const selectedData = useSelector(bookListSelecter, shallowEqual);
    return selectedData;
};

export const useBookOrder = () => {
    const selectedData = useSelector(bookOrderSelecter, shallowEqual);
    return selectedData;
};
export const useAllActiveInActiveBook = () => {
  const selectedData = useSelector(
    bookListActiveInActiveSelecter,
    shallowEqual
  );
  return selectedData;
};
