//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth, API } from 'aws-amplify';
import { v4 } from 'uuid';
import _, { flattenDeep, flatMap, map, filter } from 'lodash';
//  -- Actions ------------------------
import * as UsersActions from './actions';

//  -- Lib ----------------------------
import { authHelper, signUpHelper } from 'utils/lib/authHelper';
import { handleCreateUserClass } from 'store/userClasses/thunks';
import { handleUpdateClass } from 'store/classes/thunks';
import base64 from 'base-64';
import * as ChatAction  from "../chat/actions"
import * as Sentry from "@sentry/react";
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create user --------------------
export const handleCreateUser = (data, fromReg) => async (dispatch) => {
    await dispatch(UsersActions.createUser());
    try {
        const userId = v4();
        const result = { error: false, userId, message: "", errorDesctiption: {} }
        const res = signUpHelper({
            userId,
            ...data.auth,
        });
        await Auth.signUp(res);
        if (fromReg) {
            const authData = authHelper(data.auth);
            await Auth.signIn(authData);
        }
        let user;
        try {
            user = await API.post('usersRDS', `/users`, {
                body: {
                    userId: userId,
                    ...data.user,
                    username: data.auth.username,
                    password: base64.encode(data.auth.password),
                }
            });
        } catch (error) {
            console.log(error, "useruser");
            result.error = true;
            result.message = "Something went wrong , please try with different username"
            result.errorDesctiption = error
        }
        if (_.hasIn(data, "userBooks.books") && data.userBooks.books.length > 0) {
            try {
                data.userBooks.userId = userId;
                await API.post('userBooksRDS', `/userBooks`, {
                    body: data.userBooks
                });
            } catch (error) {
                console.log(error, " data.userBooks");
                result.error = true;
                result.message = "User created but issue in  book assignment."
                result.errorDesctiption = error
            }

        }
        if (!result.error) {
            await dispatch(UsersActions.createUserSuccess(user));
        }
        return { ...result, user };
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.createUserFail(error));
        return ({ error: true, message: error.message, errorDesctiption: error });
    }
};

//  -- Delete user --------------------
export const handleDeleteUser = (userID, username) => async (dispatch) => {
    await dispatch(UsersActions.deleteUser());

    try {
        const user = await API.del('usersRDS', `/users/${userID}?username=${username}`);
        await dispatch(UsersActions.deleteUserSuccess(user));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.deleteUserFail(error));
    }
};
export const handleDeleteUserData = (userId, username, orgId, classId) => async (dispatch) => {
    await dispatch(UsersActions.deleteUser());

    try {
        const userData = await API.post('usersRDS', `/usersData`, {
            body: {
                userId,
                username,
                orgId,
                classId
            }
        });
        await dispatch(UsersActions.deleteUserSuccess({ id: userId }));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.deleteUserFail(error));
    }
};

//  -- Get user -----------------------
export const handleGetUser = (userID) => async (dispatch) => {
    await dispatch(UsersActions.getUser());

    try {
        const user = await API.get('usersRDS', `/users/${userID}`);
        await dispatch(UsersActions.getUserSuccess(user));
        return user;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.getUserFail(error));
    }
};

//  -- Get All user -----------------------
export const handleGetAllUser = () => async (dispatch) => {
    await dispatch(UsersActions.listAllUsers());
    const USERS_LENGTH = 1000;
    try {
        const numberOfusers = await API.get('usersRDS', `/listLength`);
        const usersArr = Array.apply(
            0,
            new Array(Math.floor(numberOfusers.length / USERS_LENGTH) + 1)
        ).map((k, i) => i);
        const allUsers = await Promise.allSettled(
            map(usersArr, async (k) => {
                return API.get("usersRDS", `/listAllusers/${USERS_LENGTH}/${k * USERS_LENGTH}`);
            })
        ).then((v) => filter(v, ["status", "fulfilled"]).map((k) => k.value));
        // const user = await API.get('usersRDS', `/listAllusers`);
        const user = flatMap(allUsers);
        await dispatch(UsersActions.listAllUsersSuccess(user));
        return user;
    } catch (error) {
        console.log('error user', error);
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.listAllUsersFail(error));
    }
};

//  -- List organization users --------
export const handleListOrgUsers = (orgs) => async (dispatch) => {
    await dispatch(UsersActions.listOrgUsers());


    try {
        const orgUser = await API.post('usersRDS', `/usersByOrgsWithBooks`, {
            body: orgs.map(x => (`'${x.id}'`))
        });
        let userData = []
        if (Array.isArray(orgUser)) {
            userData = orgUser;
        }
        await dispatch(UsersActions.listOrgUsersSuccess(userData));
        return userData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.listOrgUsersFail(error));
    }
};
export const handleListDistUsers = (orgId) => async (dispatch) => {
    await dispatch(UsersActions.listOrgUsers());
    try {
        const numberOfUsers = await API.get('usersRDS', `/districtUserLength/${orgId}`);
        if (numberOfUsers && numberOfUsers.length) {
            const userarr = Array.apply(0, new Array(Math.floor(numberOfUsers.length / 1000) + 1)).map((k, i) => i)
            const allUsers = await Promise.allSettled(map(userarr, async k => {
                return API.get('usersRDS', `/listDistrictUserLimit/${orgId}/${(k * 1000)}/1000`);
            })
            ).then(v => filter(v, ["status", "fulfilled"]).map(k => k.value))
            const usersRds = flatMap(allUsers);
            await dispatch(UsersActions.listOrgUsersSuccess(usersRds));
            return usersRds;
        } else {

            const orgUser = await API.get('usersRDS', `/userByDistrict/${orgId}`);
            let userData = []
            if (Array.isArray(orgUser)) {
                userData = orgUser;
            }
            await dispatch(UsersActions.listOrgUsersSuccess(userData));
            return userData;
        }

    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.listOrgUsersFail(error));
    }
};

export const handleListDistTeacher = (orgId) => async (dispatch) => {
    await dispatch(UsersActions.listAllTeacherUsers());
    try {
        const numberOfTeachers = await API.get('usersRDS', `/districtUsersLength/${orgId}`)
        if (numberOfTeachers && numberOfTeachers.length) {
            const userarr = Array.apply(0, new Array(Math.floor(numberOfTeachers.length / 1000) + 1)).map((k, i) => i)
            const allUsers = await Promise.allSettled(map(userarr, async k => {
                return API.get('usersRDS', `/listDistrictUsersLimit/${orgId}/${(k * 1000)}/1000`);
            })
            ).then(v => filter(v, ["status", "fulfilled"]).map(k => k.value))
            const usersRds = flatMap(allUsers);
            await dispatch(UsersActions.listAllTeacherUsersSuccess(usersRds));
            return usersRds;
        } else {
            return [];
        }

    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.listAllTeacherUsersFail(error));
    }
};





export const handleListDistStudent = (orgId) => async (dispatch) => {
  await dispatch(UsersActions.listAllStudentsUsers());
  try {
    const numberOfTeachers = await API.get(
      "usersRDS",
      `/districtStudentLength/${orgId}`
    );
    if (numberOfTeachers && numberOfTeachers.length) {
      const userarr = Array.apply(
        0,
        new Array(Math.floor(numberOfTeachers.length / 1000) + 1)
      ).map((k, i) => i);
      const allUsers = await Promise.allSettled(
        map(userarr, async (k) => {
          return API.get(
            "usersRDS",
            `/listDistrictStudent/${orgId}/${k * 1000}/1000`
          );
        })
        //   /listDistrictStudent/{id}/{offset}/{limit}
      ).then((v) => filter(v, ["status", "fulfilled"]).map((k) => k.value));
      const usersRds = flatMap(allUsers);
        await dispatch(UsersActions.listAllStudentsUsersSuccess(usersRds));
      return usersRds;
    } else {
      return [];
    }
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    await dispatch(UsersActions.listAllStudentsUsersFail(error));
  }
};





//  -- Username check -----------------
export const handleCheckUsername = (username) => async (dispatch) => {
    await dispatch(UsersActions.listOrgUsers());
    if (!username) return;
    try {
        const userRDS = await API.get('usersRDS', `/userByUsername/${username}`);
        if (typeof userRDS == "object") {
            if (userRDS.error) {
                return false;
            } else {
                return userRDS;
            }
        } else {
            return false;
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.listOrgUsersFail(error));
    }
};

//  -- Register student --------------------
export const handleRegisterStudent = (userData, booksUser, classData, updateClass, fromReg) => async (dispatch) => {
    await dispatch(UsersActions.registerStudent());

    try {
        const cData = classData[0];
        const createData = {
            auth: {
                username: userData.username.trim(),
                password: userData.password.trim()
            },
            user: {
                orgId: userData.orgId,
                firstName: userData.firstName.trim(),
                lastName: userData.lastName.trim(),
                grade: userData.grade.trim(),
                role: 'student',
            },
            userBooks: booksUser
        };

        if (updateClass) {
            const newCount = cData.studentCount + 1;
            const data = {
                ...cData,
                studentCount: newCount,
                maxCount: cData.maxCount ? (cData.maxCount < newCount ? newCount : cData.maxCount) : newCount
            };

            await dispatch(handleUpdateClass(cData.id, data));
        }

        const newUser = await dispatch(handleCreateUser(createData, fromReg));
        if (!newUser.error) {
            dispatch(handleCreateUserClass({ userId: newUser.userId, classId: cData.id }))
            await dispatch(UsersActions.registerStudentSuccess({ ...createData.user, username: createData.auth.username, id: newUser.userId }));
        }
        return newUser;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.registerStudentFail(error));
        return ({ error: true, message: error.message, errorDesctiption: error });
    }
};

//  -- Update user --------------------
export const handleUpdateUser = (userID, data, booksUser, userBookId) => async (dispatch) => {
    await dispatch(UsersActions.updateUser());

    try {
        const user = await API.put('usersRDS', `/users/${userID}`, {
            body: { ...data }
        });
        if (booksUser && booksUser.books && booksUser.books.length > 0) {
            booksUser.userId = userID;
            if (userBookId) {
                let bkup = await API.put('userBooksRDS', `/userBooks/${userBookId}`, {
                    body: booksUser
                });
            } else {
                await API.post('userBooksRDS', `/userBooks`, {
                    body: booksUser
                });
            }
        }

        await dispatch(UsersActions.updateUserSuccess({ id: user.id, data: user }));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.updateUserFail(error));
    }
};

//  -- Update New User --------------------
export const handleUpdateNewUser = (userID) => async (dispatch) => {
    await dispatch(UsersActions.updateNewUser());

    try {
        const user = await API.put('usersRDS', `/updateNewUser/${userID}`);
        await dispatch(UsersActions.updateNewUserSuccess({ id: user.id, data: user }));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.updateNewUserFail(error));
    }
};

// -- Create Student With Class ----------------
export const handleAddStudentWithClass = (data) => async (dispatch) => {
    // await dispatch(UsersActions.userPasswordChange());

    try {
        const addStudent = await API.post('usersRDS', `/addStudentToClass`, {
            body: data
        });
        // await dispatch(UsersActions.userPasswordChangeSuccess(addStudent));
        // console.log('data', data);
        return addStudent;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return await dispatch(UsersActions.userPasswordChangeFail(error));
    }
}

//  -- Change User Password without old password ---------------------
export const handleForcePasswordChange = (data) => async (dispatch) => {
    await dispatch(UsersActions.userPasswordChange());

    try {
        const updatePassword = await API.put('usersRDS', `/updatePassword`, {
            body: { ...data }
        });
        await dispatch(UsersActions.userPasswordChangeSuccess(updatePassword));
        return updatePassword;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return await dispatch(UsersActions.userPasswordChangeFail(error));
    }
}

//  -- Change User Password ---------------------
export const handlePasswordChange = (data) => async (dispatch) => {
    await dispatch(UsersActions.userPasswordChange());

    try {
        const user = await Auth.currentAuthenticatedUser();
        const status = await Auth.changePassword(user, data.currentPassword, data.newPassword);
        if (status == "SUCCESS") {
            await dispatch(handleUpdatePassword({ username: user.username, password: data.newPassword }));
        }
        await dispatch(UsersActions.userPasswordChangeSuccess(status));
        return status;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return await dispatch(UsersActions.userPasswordChangeFail(error));
    }
}

const handleUpdatePassword = (data) => async (dispatch) => {
    try {
        const user = await API.put('usersRDS', `/updateUserPassword`, {
            body: { ...data }
        });
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error, "ee");
    }

}

export const getUserBySchoologyId = async (uid) => {
    try {
        return await API.get("usersRDS", `/schoology/${uid}`, {});
    } catch (error) {
        return ({ error })
    }
}

export const getUserByClasslinkId = async (uid, tenantId) => {
    try {
        return await API.get("usersRDS", `/classlink/${uid}/${tenantId}`, {});
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return ({ error })
    }
}

export const getUserById = async (id) => {
    try {
        return await API.get("usersRDS", `/userById/${id}`)
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return []
    }
}
export const getUserByUsername = async (_username) => {
    try {
        return API.get('usersRDS', `/userByUsername/${_username}`);
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return []
    }
}

export const handleUserStatus = async (id, status) => {
    try {
        await API.put('usersRDS', `/updateStatus/${id}`, {
            body: { active: status }
        });
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}

export const handleEnableDiableUser = (userId, status) => async (dispatch) => {
    if (userId) {
        try {
            const enableUser = await API.put("usersRDS", "/enableUserById", {
                body: {
                    status: status,
                    userId: userId
                }
            })
            if(enableUser&&enableUser.id){
                await dispatch(UsersActions.userEnableSuccess(enableUser))
            }
        } catch (error) {
            if (error && error.message != "Network Error") {
                Sentry.captureException(error)
            }
            return await dispatch(UsersActions.userEnableFail(error));
        }
    }
}
export const handleCheckUserStatus = (userId) => async (dispatch) => {
    if (userId) {
        try {
            const info = await API.get("usersRDS", `/checkUserStatus/${userId}`, {})
            if (!info.active) {
                await dispatch(UsersActions.getUserSuccess(info.user));
            }
            return info;
        }
        catch (error) {
            return null;
        }
    } else {
        return null
    }


}

export const getUsersCount = () => async (dispatch) => {
    try {
        const usersCount = await API.get('usersRDS', `/getUsersCount`);
        if (usersCount) {
            await dispatch(UsersActions.listAllStudentUsersSuccess(usersCount));
        }
        return usersCount;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(UsersActions.listAllStudentUsersFail(error));
    }
}

export const handleSearchStudents = async (orgId, username) => {
    try {
        const usersCount = await API.get('usersRDS', `/searchStudents/${orgId}/${username}`);
        if (usersCount) {
            // await dispatch(UsersActions.listAllStudentUsersSuccess(usersCount));

        }
        return usersCount;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        // await dispatch(UsersActions.listAllStudentUsersFail(error));
    }
}
export const handleUpdateUserConfig =  (user_id, config) => async (dispatch) => {
    try {
        const usersCount = await API.put('usersRDS', `/update_user_config`,{
            body:{
                user_id,
                config
            }
        });
        return usersCount;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null
    }
}
export const handlefetchUserProfileConfig = (user_id) => async (dispatch) => {
    try {
        const config = await API.post('usersRDS', `/get_profile_config`, {
            body: {
                user_id
            }
        });
        if (config) {
            dispatch(UsersActions.setUserProfileConfig(config));
        }
        return config;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
export const handleupdateserProfileConfig = (user_id, config) => async (dispatch) => {
    try {
        const configdata = await API.put('usersRDS', `/update_user_config`, {
            body: {
                user_id,
                config
            }
        });
        if (configdata && configdata.profile_config) {
            dispatch(UsersActions.setUserProfileConfig(configdata.profile_config));
            if (configdata.chat_config) {
                dispatch(ChatAction.updateChatUserConfig(configdata.chat_config))
            }
          return configdata;
        }
       return { };
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
       return null
    }
}

// --- get district teacher ---------

export const handleGetDistrictTeacher = (id, userId) => async (dispatch) => {
    if (!id) return;
    try {
        const teacherRDS = await API.get('usersRDS', `/listDistrictAllTeacher/${id}`);
        if (typeof teacherRDS == "object") {
            if (teacherRDS.error) {
                return false;
            } else {
                const filteredTeacherRDS = teacherRDS?.filter((obj) => obj.id !== userId)
                await dispatch(UsersActions.list_all_district_teacher(filteredTeacherRDS));
                return filteredTeacherRDS;
            }
        } else {
            return false;
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log('errrrorororor',);
    }
};
