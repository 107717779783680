//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash";
//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";

//  -- Constants ----------------------
export const handleSetSchoologyAuth = (schoologyUid) => {
    return async (dispatch) => {
        try {
            const response = await API.get("schoologyRDS", `schoology/${schoologyUid}`)
            dispatch(ActivityActions.createSchoologyAuth(response));
            if (!response || !response.oauth_token) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            if(error&&error.message!="Network Error"){
                Sentry.captureException(error)
             }
            return false;
        }
    }
}
export const handleCreateSchoologyAuth = (data) => {
    return async (dispatch) => {
        try {
            await API.post("schoologyRDS", `schoology`, { body: data })
            dispatch(ActivityActions.createSchoologyAuth(data));
            return true;
        } catch (error) {
            if(error&&error.message!="Network Error"){
                Sentry.captureException(error)
             }
            return false;
        }
    }
}
export const handleUpdateSchoologyAuth = (schoologyUid, data) => {
    return async (dispatch) => {
        try {
            await API.put("schoologyRDS", `schoology/${schoologyUid}`, { body: data })
            dispatch(ActivityActions.updateSchoologyAuth(data));
            return true;
        } catch (error) {
            if(error&&error.message!="Network Error"){
                Sentry.captureException(error)
             }
            return false;
        }
    }
}

export const handleGetCourcesFromSchoologyId = (schoologyUid) => {
    return async (dispatch) => {
        try {
            const response = await API.get("schoologyRDS", `schoologyCources/${schoologyUid}`)
            // console.log(response);
            if (Array.isArray(response)) {
                return response;
            }
            else {
                return [];
            }
        } catch (error) {
            if(error&&error.message!="Network Error"){
                Sentry.captureException(error)
             }
            return [];
        }
    }
}

export const handleGetDistrictsFromSchoologyIdOrName = async (schoologyUid, name) => {
    try {
        return await API.get("schoologyRDS", `schoologyDistricts/${schoologyUid}/${name}`)
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return null;
    }
}
export const handleGetSchoolsFromSchoologyIdOrName = async (schoologyUid, name) => {
    try {
        return await API.get("schoologyRDS", `schoologySchools/${schoologyUid}/${name}`)
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return null;
    }
}
export const handleSchoologyLogin = async (data) => {
    try {
        return await API.post("schoologyRDS", `loginHandler`,{
            body:data
        })
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return null;
    }
}
export const handleSyncSchoologyClasses = async (uid) => {
    try {
        return await API.get("schoologyRDS", `syncClassesAndBook/${uid}`)
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return null;
    }
}
export const handleSyncSchoologyClassesUsers = async (uid,classId) => {
    try {
        return await API.get("schoologyRDS", `syncClassUsers/${uid}/${classId}`)
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return null;
    }
}
export const handleSyncSchoologyClassesGrades = async (userId,classId) => {
    try {
        return await API.post("schoologyRDS", `grade_sync`,{
            body:{
                "classId":classId,
                "userId":userId
            }
        })
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return null;
    }
}
