//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState, useCallback } from "react";
import { string, shape, number } from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
import _, { find, uniqBy, flatMap, map, get , has } from "lodash";
//  -- Components ---------------------
import { Button, Modal } from "@material-ui/core";
import { Check, WatchLater, ArrowRight , Close } from "@material-ui/icons";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Lib ----------------------------
import { imageFetch, structureFetch, imageFetchFromPodcast, getExceptionStatus, getStatusByCode, isVideo , getStoryUrl , generateUrlForNonEbook } from "utils/lib/ebookHelper";

//  -- Thunks -------------------------
import { handleUpdateSubmission } from "store/submissions/thunks";
import { handleCreatePastSubmission } from 'store/pastSubmission/thunks';
import { TootipWrapper } from "components/TooltipWrapper";
import GradeDisplay from 'components/GradeDisplay';
import RetakeModal from "components/RetakeModal";
import { BookSynoname } from 'utils/config/reference';
import { useResources } from "store/resources/selectors";
import { useOrgTimezone } from 'store/organizations/selectors';
import { useServertime } from 'store/servertime/selectors';
import { isAfterTime, isBeforTime } from "utils/methods/math"
import { useBookUnits } from 'store/booksUnits/selectors';
import { useStory } from "store/story/selectors";
import "moment-timezone"
import { handleCreateNotification } from "store/notification/thunks";
import { useSocket } from "store/chat/selectors";

import { BACK_TO_BOOK } from 'utils/config/reference';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  bottom: 56px;

  // @media (max-width: 400px) {
  //   width: calc(100% - 40px);
  // }
  @media (max-width:500px) {
    position: unset;
  }
`;

const Responce = styled.button`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 14px;
  bottom: 16px;
  border-radius: 15px;
  border-width: 0px;
  background-color: ${COLOR.blueish100};
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
  color: ${COLOR.white};
  width: 150px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    position: inherit;
    margin-top: 10px;
  }
`;
const CompleteText = styled.span`
  align-items: center;
  color: ${p => p.deactivate ? COLOR.redishpink : COLOR.green100};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

const Header = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
`;

const Image = styled.img`
  border: 1px solid ${COLOR.black800};
  width: 100%;
  height: auto;
`;

const ImageContiner = styled.button`
  background-color: transparent;
  border: none;
  cursor: zoom-in;
  flex: 1;
  max-width: 112px;
  outline: none;
  padding-right: 24px;
`;

const ImageModal = styled.div`
  left: 50%;
  max-width: 544px;
  padding: 0px 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const TextContainer = styled.div`
  justify-content: flex-end;
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
`;

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: auto;
  padding: 16px;
  border-radius: 3px;
  margin-bottom: 16px;
  position: relative;

  @media (max-width: 400px) {
    padding-bottom: 72px;
  }
`;
const ResponceContainer = styled.div`
  min-height: 50px;
  padding-left:16px;
  padding-right:16px
`;
const OuterWrapper = styled.div`
  border-bottom: 2px solid #aaacaf61;
`
const AccordianTitle = styled(Typography)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
`
const Rotate = styled.div`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;
const InprogressMessgae = styled.div`
    color: #614f4fad;
    background: #ff000021;
    padding-left: 12px;
`;
const Accordion = withStyles({
  root: {
    border: '1px solid white',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#03989e',
    color: 'white',
    textTransform: 'capitalize',
    borderBottom: '1px solid white',
    marginBottom: -1,
    minHeight: 30,
    height: 30,
    '&$expanded': {
      minHeight: 30,
    },
  },
  content: {
    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);
const CurrentSubmission = {
  backgroundColor: '#9933ff'
}
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Interaction
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...

const ParentAssignmentStatus = ({
  chapter,
  data,
  submission,
  booksAllList,
  currentUser,
  showResponce,
  book,
  onShowResponce,
  currentClass
}) => {
  const servertime = useServertime()
  const timezone = useOrgTimezone()
  const [modalOpen, setModalState] = useState(false);
  const isAssignmentTakable = useCallback(() => (get(data, "lateDate") == "always" ? true : get(data, "lateDate") ? isAfterTime(get(data, "lateDate"), servertime, timezone) : isAfterTime(get(data, "dueDate"), servertime, timezone)), [servertime, data])
  let pastSubmissions = submission && submission.pastSubmissions ? submission.pastSubmissions : [];
  const resources = useResources()
  pastSubmissions = uniqBy(pastSubmissions, "submissions").sort((a, b) => parseInt(a.submissions) - parseInt(b.submissions))
  const ungraded = get(data, "ungraded", false)
  const grade_type = get(data,"grade_type");
  const isCompleteInComplete = grade_type === "complete_incomplete"
  const max_point = data?.max_point && !isNaN(data?.max_point) ? Number.parseInt(data.max_point, 10) : 100
  const isPointSystem =  grade_type === "points"
  const is_game = submission?.assignmentType === "game" || submission?.type === "game" ;
  const is_debate = submission?.assignmentType === "debate" || submission?.type === "debate" ;

  const retakeAvailable = submission
    ? parseInt(submission?.submissions, 10) < parseInt(data?.retakes, 10) + 1
    : true;
  const { isException, exceptionStatusText } = getExceptionStatus(submission,currentUser);
  const hasDuration = !isNaN(parseInt(data?.timeDuration)) && data?.timeDuration > 0;
  const isActive = get(data, "active", true)
  const [expanded, setExpanded] = React.useState(null);

  if (data && data.lateDate == "null") {
    data.lateDate = null;
  }
  const getNumberMask = useCallback((_page) => {
    let _book = find(booksAllList, { id: chapter })
    if (_book) {
      let numberMask = find(_book.pages, k => k.pageId == _page)
      if (numberMask) {
        if (numberMask.NumberMasked)
          return numberMask.NumberMasked
        else
          return _page;
      } else {
        return _page;
      }
    } else {
      return _page;
    }
  }, [chapter, booksAllList])
  const getButtonText = useCallback(() => {
    if (isException) {
      return exceptionStatusText
    }
    else if (isAssignmentTakable()) {
      if (submission?.complete && retakeAvailable) {
        return "Retake";
      } else if (submission) {
        return "Resume";
      } else {
        return "Start"
      }
    } else {
      if (submission?.complete && !submission.retaking) {
        return "completed"
      }
      return "Past due"
    }
  }, [servertime, submission])
  const getAssignmentName = () => {
    let displayName = data?.displayName ? data?.displayName : data?.data?.displayName
    let shortName = BookSynoname[chapter];
    let shortN = data?.typeId === "quiz" ? "Q" : "A";
    let pg = getNumberMask(data?.data?.page);
    pg = pg?.length > 20 ? data?.data?.bookId?.substring(0, 10) : `Pg.${pg ?? ""}`;
    return `${displayName} (${shortName ?? shortN}|${pg})`
  }
  const getThumbnail = (zoom) => {
    const src = isVideo(get(data, "data.chapter"), resources) ? imageFetchFromPodcast(get(data, "data.page"), resources) : imageFetch(booksAllList, chapter, get(data, "data.page"))
    return <Image
      src={src}
      alt=""
    />
  }
  const getResources = () => {
    const dataResource = find(flatMap(map(resources, k => k.videos)), { id: data?.data?.page })
    return dataResource.resource;
  }

  const getCompleteInclompleteMark = (isComplete)=>{
      if(isComplete){
        return <Check style={{ color: COLOR.green100, fontSize: 18 }} />
      } else{
        return <Close style={{ color: COLOR.red, fontSize: 18 }} />
      }
}

  const getGrade = () => {
    if(!submission||!submission.id) return '-';
    if (submission.retaking && !submission.complete) return "in progress";
    const grade = submission?.grade>=0 ? submission?.grade : ''
    let isTbgObj = find(get(submission, "assignment.questions"), (e) => e.type != "multipleChoice")
    const isTbg = submission?.manualGrading && typeof isTbgObj == "object"
    if (ungraded) return 'Turned in'
    if (isCompleteInComplete && (has(submission, "id") || !isAssignmentTakable())) {
      return getCompleteInclompleteMark(submission?.grade > 0);
    } else if (isPointSystem) {
      if (isTbg) return "TBG";
      else if (submission) {
        const face_grade = submission.face_grade
        if (face_grade >= 0) {
          return `${face_grade}/${max_point}`
        } else {
          return `0/${max_point}`;
        }
      } else {
        return '-'
      }

    }
    return isTbg ? "TBG" : (grade>0||grade == 0 ?grade:'-')
  }
  const handleChangeExpend = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };
  const getGradePastSubmissions = (pt) => {
    const grade = pt?.grade || 0
    if (ungraded) return 'Turned in'
    if (isCompleteInComplete) {
      return grade > 0 ? 'Completed' : 'In completed';
    } else if (isPointSystem) {
      if (grade > 0 && max_point > 0) {
        return `${Math.round((grade / 100) * max_point)}/${max_point}`
      } else {
        return `0/${max_point}`;
      }

    }
    return `Grade -${grade}`;

  }
  const pastSubmissionView = useCallback(() => {
    if (pastSubmissions && pastSubmissions.length > 0) {
      // console.log(pastSubmissions,"fff")
      return (<div>
        {map(pastSubmissions, pt => (<Accordion square expanded={expanded === pt.id} onChange={handleChangeExpend(pt.id)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <AccordianTitle>
              <span>{"Submission-" + pt.submissions} {getGradePastSubmissions(pt)}</span> <Rotate open={expanded === pt.id}><ArrowRight /></Rotate> </AccordianTitle>
          </AccordionSummary>
          <AccordionDetails>
            <GradeDisplay
              key={get(pt, "id")}
              answers={get(pt, "answers")}
              feedback={get(pt, "feedback")}
              ai_feedback={get(pt, "ai_feedback")}
              ai_suggestion={get(pt, "ai_suggestion",[])}
              audioFeedback={get(pt, "audioFeedback")}
              grade={get(pt, "grade")}
              name={get(submission, "displayName", get(data, "assignment.displayName"))}
              questions={get(submission, "assignment.questions", [])}
              type={"past due"}
              isLate={pt.islate}
              isExpended
              hasFeedback={true &&(get(pt, "feedback")|| get(pt, "audioFeedback"))}
              assignment={pt}
            />
          </AccordionDetails>
        </Accordion>
        ))}
      </div>)

    }
    else {
      return null;
    }

  })

  const CurrentSubmissionView = useCallback(() => {
    if (get(submission, "submissions") != 0 || get(submission, "graded")) {
      let PastSub = 0;
      map(pastSubmissions, pt => (
        PastSub = parseInt(pt.submissions)
      ));
      const LastSub = PastSub ? PastSub : 0;
      const CurrentSub = LastSub + 1;
      let Title = "";
      if (CurrentSub === 1) {
        Title = CurrentSub;
      } else {
        Title = CurrentSub + ' (Last Submmission)';
      }
      return (<div>
        {<Accordion square expanded={expanded === get(submission, "id")} onChange={handleChangeExpend(get(submission, "id"))}>
          <AccordionSummary style={CurrentSubmission} aria-controls="panel1d-content" id="panel1d-header">
            <AccordianTitle><span>{"Submission-" + Title}  {` Grade - ${get(submission, "exceptionStatus") > 0 ? getStatusByCode(get(submission, "exceptionStatus"),currentUser) : getGrade()}`}</span> <Rotate open={expanded === get(submission, "id")}><ArrowRight /></Rotate> </AccordianTitle>
          </AccordionSummary>
          <AccordionDetails>
            <GradeDisplay
              key={get(submission, "id")}
              answers={get(submission, "answers")}
              feedback={get(submission, "feedback")}
              ai_feedback={get(submission, "ai_feedback")}
              ai_suggestion={get(submission, "ai_suggestion",[])}
              audioFeedback={get(submission, "audioFeedback")}
              grade={get(submission, "exceptionStatus") > 0 ? getStatusByCode(get(submission, "exceptionStatus"),currentUser) : getGrade()}
              name={get(submission, "displayName", get(submission, "assignment.displayName"))}
              questions={get(submission, "assignment.questions", [])}
              selected_student_id={submission?.userId}
              type={"current due"}
              is_game={is_game}
              is_debate={is_debate}
              isLate={get(submission, "isLate")}
              isExpended
              assignment={submission}
            />
          </AccordionDetails>
        </Accordion>}
      </div>)
    }else{
      return <InprogressMessgae>In progress</InprogressMessgae>
    }
  })
  if (!data.data || !chapter || !Object.keys(data.data ?? {}).length > 0)
    return null;
  return (
    <OuterWrapper>
      <Wrapper>
        <Modal
          open={modalOpen}
          onClose={() => setModalState(false)}
          style={{ height: "100%", width: "100%" }}
        >
          <ImageModal>
            {getThumbnail(true)}
          </ImageModal>
        </Modal>
        <ImageContiner onClick={() => setModalState(true)}>

          {getThumbnail()}
        </ImageContiner>
        <Container>
          <Header>
            {getAssignmentName()}
            {!isNaN(parseInt(data?.timeDuration)) && data?.timeDuration > 0 && (
              <WatchLater style={{ fontSize: "15px", marginLeft: "6px" }} />
            )}
          </Header>
          <TextContainer>
            {isVideo(chapter, resources) ? (<Text>Resource: <strong style={{ color: COLOR.green300, fontWeight: 600 }}>{getResources()}</strong> </Text>
            ) : (<Text>Book:{" "} <strong style={{ color: COLOR.green300, fontWeight: 600 }}>{book?.description} </strong>{" "}</Text>)}
            {isVideo(chapter, resources) ? null : <Text>
              Page:{" "}
              <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                {getNumberMask(data?.data ? data.data.page : data.page)}
              </strong>{" "}
              {!data?.dueDate && (
                <strong
                  style={{ marginLeft: "8px", textDecoration: "underline" }}
                >
                  {structureFetch(booksAllList, chapter, data?.page)}
                </strong>
              )}
            </Text>}
            {data?.scheduleAt && (
              <Text>
                Start Date:{" "}
                <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                  {moment(get(data, "scheduleAt")).format('MMM DD, YYYY hh:mm A')}
                </strong>
              </Text>
            )}
            {data?.dueDate && (
              <Text>
                Due Date:{" "}
                <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                  {moment(get(data, "dueDate")).format('MMM DD, YYYY hh:mm A')}
                </strong>
              </Text>
            )}
            {data?.lateDate ? (
              <Text>
                Late Submission:{" "}
                <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                  {get(data, "lateDate") == "always" ? "No limit" : moment(get(data, "lateDate")).format('MMM DD, YYYY hh:mm A')}
                </strong>
              </Text>
            ) : null}
            {data?.retakes && (
              <Text>
                Retakes Allowed:{" "}
                <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                  {data?.retakes}
                </strong>
              </Text>
            )}
            {!isNaN(parseInt(data?.timeDuration)) && data?.timeDuration > 0 && (
              <Text>
                Duration in minutes:{" "}
                <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                  {data?.timeDuration}
                </strong>
              </Text>
            )}
            <Text>
              Grade:{" "}
              <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                {ungraded && submission ? "Turned in" : submission?.exceptionStatus > 0 ? getStatusByCode(submission?.exceptionStatus,currentUser) : getGrade()}
              </strong>
            </Text>
            {is_game ? <Text>
              Type:{" "}
              <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                Game
              </strong>
            </Text> : null}
            {is_debate ? <Text>
              Type:{" "}
              <strong style={{ color: COLOR.green300, fontWeight: 600 }}>
                Debate
              </strong>
            </Text> : null}
            {!data?.dueDate && (
              <Text>
                Status: <strong>Unassigned</strong>
              </Text>
            )}
          </TextContainer>
          {data?.dueDate && (
            <Actions>
              <>
                {!get(submission, "complete") && !isAssignmentTakable() ? (
                  <CompleteText>Past Due</CompleteText>
                ) : (
                  <>
                    {get(submission, "complete") && (!retakeAvailable || (isBeforTime(get(data, "dueDate"), servertime, timezone) && isBeforTime(get(data, "lateDate", get(data, "dueDate")), servertime, timezone)))
                      ? (
                        <CompleteText deactivate={!isActive}>
                          {isActive ? <Check
                            style={{ fontSize: "15px", marginRight: "6px" }}
                          /> : null}
                          {!isActive ? "Deactivated" : "Completed"}
                        </CompleteText>
                      ) : (
                        <TootipWrapper
                          hasToolTip={hasDuration || !isActive}
                          label={!isActive ? "Assignment is deactivated by teacher" : submission ? `This activity includes a "timer", are you sure you want to resume it?` : null
                          }
                        >
                          <Button
                            color={!isActive ? "secondary" : "primary"}
                            disabled
                            variant="contained"
                            style={{ marginBottom: "4px" }}
                          >
                            {!isActive ? "Deactivated" : getButtonText()}
                            {hasDuration && (
                              <WatchLater
                                style={{ fontSize: "15px", marginLeft: "6px" }}
                              />
                            )}
                          </Button>
                        </TootipWrapper>
                      )}
                  </>
                )}
              </>
            </Actions>
          )}
          {data?.dueDate &&
            typeof submission == "object" &&
            Object.keys(submission).length > 0 && (
              <Responce onClick={onShowResponce}>{showResponce ? "Hide Responses" : "See Responses"}</Responce>
            )}
        </Container>
      </Wrapper>
      {showResponce && (
        <ResponceContainer>
          {pastSubmissionView()}
          {CurrentSubmissionView()}
        </ResponceContainer>
      )}
    </OuterWrapper>
  );
};

//  -- Prop types ---------------------
ParentAssignmentStatus.propTypes = {
  data: shape({
    title: string,
    img: string,
    description: string | null | number,
    dueDate: string,
    retakes: string,
    status: string,
  }),
};

//  -- Default props ------------------
ParentAssignmentStatus.defaultProps = {};
const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps, null)(ParentAssignmentStatus);
