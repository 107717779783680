//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;

const Text = styled.span`
    // color: rgb(0 0 0 / 85%);
    font-size: 15px;
    color:white;
`;

const TextContainer = styled.div`
    border-top: 1px solid ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    padding:0px 16px;
    background-color: #171E2D;
   
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;
const Label = styled.div`
    position: absolute;
    top: 30%;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    background: ${p => p.bgColor ? p.bgColor : COLOR.redishpink}; 

    @media only screen and (max-width:500px) {
        font-size:12px;
    }
    @media only screen and (max-width:400px) {
        font-size:9px;
    }
     @media only screen and (max-width: 600px) {
        font-size:11px;
     }
`;

const AssessmentCoversCard = ({ data, handleClick, labelBgColor, label, showLabel = true }) => {
    return (
        <Wrapper>
            <div className="card-resource card-resourceCstm">
                <a onClick={handleClick}>
                    <div className="card-resource-overlay"></div>
                    <Image className="card-resource-image" src={data.src} alt={data.displayName} />
                    {showLabel && <Label bgColor={labelBgColor}>{label}</Label>}
                    <div className="card-resource-details fadeIn-bottom">
                        <h3 className="card-resource-title">{data.displayName}</h3>
                        {/* <p className="card-resource-text">{data.label}</p> */}
                    </div>
                    {/* <TextContainer>
                        <Text>{data.displayName}</Text>
                    </TextContainer> */}
                </a>
            </div>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentCoversCard.propTypes = {
    data: shape({
        img: string,
        title: string,
        description: string,
    }),
    handleClick: func
};

//  -- Default props ------------------
AssessmentCoversCard.defaultProps = {
    data: {},
};

export default AssessmentCoversCard;