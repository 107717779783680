//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal as ModalSection, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { PlusOutlined } from '@ant-design/icons';
import { Button as ButtonSection, Card, Col, Divider, Input, Modal, Row } from 'antd';
import Loading from 'components/Loading';
//  -- Constants ----------------------
import base64 from 'base-64';
import { filter, find, get, isEmpty, sortBy } from 'lodash';
import { useDispatch } from "react-redux";
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createKeystore } from "store/keystore/thunks";
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleEnableDiableUser } from 'store/users/thunks';
import { v4 } from 'uuid';
import SchoolForm from "./School";
import Login_as from './Login_as';
const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const StableWrapper = styled.div`
   width: 100%;
  overflow-x: auto;
//   padding:24px;
//   @media (max-width: 992px) {
//     padding:24px 0px !important;
//   }
`;
const StableWrapperMain = styled.div`

`;
const ViewSectionText = styled.p`
    color:black;
    font-size:17px;
    font-weight:400;
`;
const SectionText = styled.span`
   font-weight:600;
`;
const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
font-size: 12px;
font-weight: 600;
`;
const OverViewValue = styled.span`
font-size: 17px;
font-weight: 500;
`;
const ProfileContainer = styled.span`
    display:flex;
    align-items:center;
    flex-direction:row;
`;

const FilterContainer = styled.span`
        font-size:17px;
        font-weight:500;
        cursor:pointer;
`;
const ViewDemo = styled.span`
textAlign: "left";
color: ${(p) => (p.color)};
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const DistrictTeacherTable = ({
    users,
    schools,
    classes,
    userloading,
    distTeacher,
    selectedDist,
    onUserAddOrUpdate = () => null,
    onTabsJump = () => null,
}) => {
    const [schoolForm, setSchoolForm] = useState(false);
    const [editSchool, setEditSchool] = useState(false);
    const [school, setSchool] = useState(null);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [showSchoolsTeachers, setShowSchoolsTeachers] = useState(-1);
    const currentOrg = useCurrentOrg()
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = React.useState(0);
    const [loginLoader, setLoginLoader] = useState(null)
    const [modal2Open, setModal2Open] = useState(false);
    const [enableLoader, setEnableLoader] = useState(-1)
    const [teacherData, setTeacherData] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const filterByUser = filter(distTeacher, obj => obj.role == 'teacher')
    const filterByAllUser = filter(distTeacher, obj => obj.role == 'teacher')
    const [alertForAccount, setAlertForAccount] = useState(false)
    const [userToEnable, setuserToEnable] = useState({})
    const [classNo, setClassNo] = useState();
    useEffect(() => {
        let filterTeacher = [];
        if (get(selectedDist, 'id')) {
            if (get(selectedDist, 'typeId') == 'district') {
                setFilteredSchools(sortBy(filterByUser, ["firstName", "lastName"]))
            } else if (get(selectedDist, 'typeId') == 'school') {
                filterTeacher = filter(filterByAllUser, obj => obj.orgId == get(selectedDist, 'id'));
                setFilteredSchools(sortBy(filterTeacher, ["firstName", "lastName"]))
            } else {
                filterTeacher = filter(filterByAllUser, obj => obj.id == get(selectedDist, 'teacherId'));
                setFilteredSchools(sortBy(filterTeacher, ["firstName", "lastName"]))
            }
            console.log('selectedDist', selectedDist);
        } else {
            setFilteredSchools(sortBy(filterByAllUser, ["firstName", "lastName"]))
        }
    }, [distTeacher])

    const handleSearchDistrict = (event) => {
        if (event.target.value && event.target.value.length > 2) {
            var search = event.target.value;
            if (get(selectedDist, 'id')) {
                const filtered = filterByUser.filter(x => {
                    return (
                        `${x.firstName} ${x.lastName}`.toLowerCase().includes(search.toLowerCase())
                        ||
                        `${x.role}`.toLowerCase().includes(search.toLowerCase())
                    );
                });
                setFilteredSchools(sortBy(filtered, ["firstName", "lastName"]))
            } else {
                const filtered = filterByAllUser.filter(x => {
                    return (
                        `${x.firstName} ${x.lastName}`.toLowerCase().includes(search.toLowerCase())
                        ||
                        `${x.role}`.toLowerCase().includes(search.toLowerCase())
                    );
                });
                setFilteredSchools(sortBy(filtered, ["firstName", "lastName"]))
            }
            console.log('search If', search);
        } else {
            if (get(selectedDist, 'id')) {
                setFilteredSchools(sortBy(filterByUser, ["firstName", "lastName"]))
            } else {
                setFilteredSchools(sortBy(filterByAllUser, ["firstName", "lastName"]))
            }
            setShowSchoolsTeachers(-1);
            console.log('search else', search);
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const goToClass = async (id, user) => {
        openLoginInNewWindow(user.id)
    };
    const openLoginInNewWindow = async (userId) => {
        setLoginLoader(userId);
        const key = v4()
        await createKeystore(userId, key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoginLoader(null);
    }
    const options = [];
    for (let i = 10; i < 36; i++) {
        options.push({
            value: i.toString(36) + i,
            label: i.toString(36) + i,
        });
    }

    const addTeachers = (value) => {
        const editData = {
            userData: teacherData,
            isEdit: true
        }
        const addData = {
            userData: null,
            isEdit: false
        }
        if (value == 'edit') {
            history.push({ pathname: "/add-users", state: editData });
        } else {
            history.push({ pathname: "/add-users", state: addData });
        }
    }

    const enableDisableUser = async (userId, status) => {
        setEnableLoader(userId)
        await dispatch(handleEnableDiableUser(userId, status));
        setEnableLoader(-1)
        setAlertForAccount(false)
        setuserToEnable({})
    }

    return (
        <>
            <ModalSection
                open={alertForAccount}
                onClose={() => setAlertForAccount(false)}
            >
                <ModalContent>
                    Are you sure you want to {get(userToEnable, "active", true) ? "disable" : "enable"}  {get(userToEnable, "firstName", '')}  {get(userToEnable, "lastName", '')}
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setAlertForAccount(false)
                                setuserToEnable({})
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginRight: '4px' }}
                            color="secondary"
                            variant="contained"
                            className={`${enableLoader != -1 ? "disabled-button" : ""}`}
                            onClick={() => enableDisableUser(userToEnable?.id, !userToEnable?.active)}
                        >
                            {enableLoader != -1 ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </ModalSection>
            {schoolForm ? (
                <StableWrapper style={{ padding: 24 }}>
                    <SchoolForm
                        currentOrg={currentOrg}
                        school={school}
                        editing={editSchool}
                        onClose={() => {
                            setSchoolForm(false);
                            setSchool(null);
                            setEditSchool(false);
                            onUserAddOrUpdate()
                        }}
                    />
                </StableWrapper>
            ) : (
                <StableWrapperMain>
                    <Row style={{ marginBottom: 15 }}>
                        <Col>
                            <ButtonSection style={{ minHeight: 38 }} type="primary" onClick={() => addTeachers()}>
                                <PlusOutlined /> Add Teachers
                            </ButtonSection>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={24}>
                            <ViewSectionText>
                                View <SectionText>Teachers</SectionText> records.
                            </ViewSectionText>
                        </Col>
                        <Col xs={24} lg={24}>
                            {/* <TextField id="search" name="search" label="Search" type="text" variant="filled" onKeyUp={handleSearch} size="small" style={{ backgroundColor: "rgba(255, 255, 255, 1)" }} /> */}
                            <FilterContainer className="FilterHeading">
                                <Card
                                    type="inner"
                                    title="Filters"
                                    // extra={<a>Clear</a>}
                                >
                                    <Row>
                                        <Col xs={24} lg={24} md={24} className="ColCardPadding">
                                            <Card style={{ width: '100%' }}>
                                                <Input placeholder="Search by name" bordered={false} style={{ height: '32px' }} onChange={handleSearchDistrict} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </FilterContainer>
                        </Col>
                    </Row>
                    <StableWrapper style={{ marginTop: 30 }}>
                        <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>School Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>First Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Last Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Classes</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Status</TableCell>
                                    {/* <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Edit</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isEmpty(filteredSchools) && (
                                    userloading ? <Loading size={30} /> : (
                                        <TableRow key={'0'} style={{ border: 0 }}>
                                            <TableCell colSpan={6} style={{ textAlign: "center" }}>No results</TableCell>
                                        </TableRow>
                                    )
                                )}
                                {(!isEmpty(filteredSchools)) && (
                                    filteredSchools.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const schoolName = find(schools, obj => obj.id == row.orgId);
                                        const classCount = filter(classes, obj => obj.teacherId == row.id);
                                        const demoSchool = get(schoolName, 'demo', '');
                                        row.schoolName = get(schoolName, 'displayName', '')
                                        return (
                                            <>
                                                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, cursor: 'pointer' }}>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }}
                                                        onClick={() => {
                                                            const tempDate = get(row, 'createdAt', '')
                                                            const date = moment(parseInt(tempDate));
                                                            const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
                                                            console.log('formattedDate', tempDate)
                                                            row.date = formattedDate ?? '';
                                                            setModal2Open(!modal2Open);
                                                            setTeacherData(row);
                                                            setClassNo(classCount?.length)
                                                        }}
                                                    >{get(schoolName, 'displayName', '')} <ViewDemo color={BORDERCOLOR[3]}>{demoSchool && "(Demo)"}</ViewDemo></TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                        const tempDate = get(row, 'createdAt', '')
                                                        const date = moment(parseInt(tempDate));
                                                        const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
                                                        console.log('formattedDate', tempDate)
                                                        row.date = formattedDate ?? '';
                                                        setModal2Open(!modal2Open);
                                                        setTeacherData(row);
                                                        setClassNo(classCount?.length)
                                                    }}>{row.firstName}</TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                        const tempDate = get(row, 'createdAt', '')
                                                        const date = moment(parseInt(tempDate));
                                                        const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
                                                        console.log('formattedDate', tempDate)
                                                        row.date = formattedDate ?? '';
                                                        setModal2Open(!modal2Open);
                                                        setTeacherData(row);
                                                        setClassNo(classCount?.length)
                                                    }}>{row.lastName}</TableCell>
                                                    <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0], cursor: 'pointer' }} onClick={() => {
                                                        onTabsJump("see_classes")
                                                    }}>{classCount?.length}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>
                                                        <Button
                                                            variant="contained"
                                                            color={row.active ? "primary" : "secondary"}
                                                            onClick={x => {
                                                                setAlertForAccount(true)
                                                                setuserToEnable(row)
                                                                // enableDisableUser(row.id, !row.active)
                                                            }} style={{ marginRight: '8px', color: COLOR.white, fontSize: 12, width: 80 }} >
                                                            {enableLoader == row.id ? <Loading size={'18px'} white /> : (row.active ? "Enabled" : 'Disabled')}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                {/* <TableRow style={{ display: (showSchoolsTeachers == index ? '' : 'none') }} height={showSchoolsTeachers == index && teacherByOrg[row.id]?.length > 0 ? ((teacherByOrg[row.id]?.length * 39) + 8) : 0}>
                                                <TableCell colSpan={6}>
                                                    <Table style={{ width: "100%" }}>
                                                        {teacherByOrg[row.id]?.map((t) => (
                                                            <TableRow key={t.id} style={{ border: 0, width: "100%" }}>
                                                                <TableCell style={{ textAlign: "center" }}>{`${get(t, "firstName", "")} ${get(t, "lastName", "")}`}</TableCell>
                                                                <TableCell style={{ textAlign: "center" }}>
                                                                    <Button onClick={() => { goToClass(t.id, t); }} disabled={!t.active} style={{ cursor: t.active ? "pointer" : "not-allowed", color: t.active ? "black" : "red" }}>
                                                                        {loginLoader == t.id && <Loading size={14} />}  Log in to account
                                                                        <ArrowForward style={{ fontSize: "11px", marginLeft: "8px" }} />
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </Table>
                                                </TableCell>
                                            </TableRow> */}
                                            </>
                                        )
                                    })
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {!isEmpty(filteredSchools) && <TablePagination
                                        count={filteredSchools.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                    />}
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </StableWrapper>

                </StableWrapperMain>
            )}
            <Modal
                footer={null}
                title={`${get(teacherData, 'firstName', '')} ${get(teacherData, 'lastName', '')}`}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={'60%'}
                className="FilterHeading"
            >
                <ProfileContainer>
                    <ButtonSection type="primary" ghost onClick={() => addTeachers('edit')}>
                        Edit Profile
                    </ButtonSection>
                    <Login_as user={teacherData} />
                </ProfileContainer>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Detail View"
                >
                    <Row>
                        <Col xs={24} lg={24}>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>First name</OverViewLabel>
                                <OverViewValue>{get(teacherData, 'firstName', '')}</OverViewValue>
                            </OverViewContainer>
                            {/* <OverViewContainer>
                                <OverViewLabel>District</OverViewLabel>
                                <OverViewValue>{'12-09-2023'}</OverViewValue>
                            </OverViewContainer> */}
                            <OverViewContainer>
                                <OverViewLabel>User Name</OverViewLabel>
                                <OverViewValue>{get(teacherData, 'username', '')}</OverViewValue>
                            </OverViewContainer>

                            <OverViewContainer>
                                <OverViewLabel>Created At</OverViewLabel>
                                <OverViewValue>{get(teacherData, 'date', '')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Last name</OverViewLabel>
                                <OverViewValue>{get(teacherData, 'lastName', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Password</OverViewLabel>
                                <OverViewValue>{teacherData?.password ? base64.decode(teacherData?.password) : ''}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col xs={12} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>School Name</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_schools", teacherData)}>{get(teacherData, 'schoolName', '')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={12} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Classes</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_classes", teacherData)}>{classNo}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
};


//  -- Prop types ---------------------
DistrictTeacherTable.propTypes = {};

//  -- Default props ------------------
DistrictTeacherTable.defaultProps = {};

export default DistrictTeacherTable;
