import React, { useCallback, useEffect, useState } from 'react'
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
import { get, map } from 'lodash';
import styled from 'styled-components';
import { Button, FormHelperText, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { ArrowRight, Close, Search, Edit , ArrowForward, Add  } from '@material-ui/icons';
import COLOR from 'utils/constants/color';
import { useDispatch } from 'react-redux';
import { handleListCanvasCourse , handleUpdateCanvasCources  } from 'store/booksUnits/thunks';
import { Col, message, Row } from 'antd';
import SelectableBook from 'components/SelectableBook';
import { useAllBook } from 'store/books/selectors';
import { handleListClasslinkKeys } from 'store/classlink/thunks';
import { useDistricts } from 'store/organizations/selectors';
import { handleListCanvasKeys, updateCanvasKeyInfo } from 'store/canvas/thunks';
import { testValidUrl } from 'utils/methods/math';
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;
const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px; 
    @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;

const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;

const KeyUpdate = ({ openEditModal, setOpenEditModal, selectedData , onDone}) => {
    const [canvasKeyInfoLoadinf, setCanvasKeyInfoLoading] = useState(false);
    const [error, setError] = useState(false);
    const [key_info_error, setKeyInfoError] = useState(false);
    const [canvasKeyInfo, setCanvasKeyInfo] = useState({});
    useEffect(()=>{
        if(typeof selectedData==="object"){
            setCanvasKeyInfo(selectedData)
        }
    },[selectedData])
    const onUpdate = async () => {
        if (!canvasKeyInfo.base_url) {
            setError(`Base url required!`)
            return;
        } else if (!canvasKeyInfo.key) {
            setError(`Key required!`)
            return;
        } else if (!canvasKeyInfo.display_name) {
            setError(`Display name required!`)
            return;
        } else if (!canvasKeyInfo.redirect_uri) {
            setError(`Redirect uri required!`)
            return;
        } else if (!testValidUrl(canvasKeyInfo.redirect_uri)) {
            setError(`Enter a valid redirect URI`)
            return;
        }else if (!canvasKeyInfo.redirect_uri) {
            setError(`Redirect url required!`)
            return;
        }else if (!canvasKeyInfo.secrate) {
            setError(`Secret required.`)
            return;
        }
         else {
            setCanvasKeyInfoLoading(true);
            const keyInfo = await updateCanvasKeyInfo(canvasKeyInfo);
            if (keyInfo && keyInfo.success) {
                setCanvasKeyInfo(keyInfo.data)
                close()
                if(onDone){
                    onDone();
                }
                message.success("Canvas key info updated!")
            } else {
                message.error("Having issue while updating key info")
            }
            setCanvasKeyInfoLoading(false);
        }

    }
    const fieldUpdate = (key, value) => {
        setError(false);
        setCanvasKeyInfo({ ...canvasKeyInfo, [key]: value })
    }
    const close = () =>{
        setOpenEditModal(false)
    }
    return (
        <Modal
            open={openEditModal}
            onClose={close}
            style={{ height: "100%", width: "100%" }}
        >
            <ModalContent style={{ width: "70vw" }}>
                <Row style={{ justifyContent: "space-between" }}>
                    <IconButton
                        onClick={close}
                        style={{ padding: 0 }}
                    >
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <LinkArea>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="display_name"
                                    name="display_name"
                                    label="display_name"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("display_name", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "display_name", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="base_url"
                                    name="base_url"
                                    label="Base url"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("base_url", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "base_url", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="key"
                                    name="key"
                                    label="key"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("key", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "key", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="secrate"
                                    name="secrate"
                                    label="secrate"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("secrate", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "secrate", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="api_version"
                                    name="api_version"
                                    label="api version"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("api_version", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "api_version", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="redirect_uri"
                                    name="redirect_uri"
                                    label="Redirect uri"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("redirect_uri", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "redirect_uri", "")}
                                />
                            </Col>
                        </Row>
                    </LinkArea>
                </ModalConfigArea>
                {error ? (
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                ) : null}
                {key_info_error ? (
                    <FormHelperText error>
                        {`Haveing issue while feting key information`}
                    </FormHelperText>
                ) : null}
                <Row
                    gutter={[24, 24]}
                    style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
                >
                    <Button
                        fullWidth
                        onClick={onUpdate}
                        disabled={canvasKeyInfoLoadinf || key_info_error}
                        color="primary"
                        variant="contained"
                    >
                        {canvasKeyInfoLoadinf ? <Loading white size={14} /> : null} Update
                    </Button>
                </Row>
            </ModalContent>
        </Modal>
    )
}







export default function CanvasKeyMap() {
    const [KeysItems,setKeysItems] = useState([])
    const [loading , setLoading] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const districts = useDistricts()
    const getDistrictDIsplayName = useCallback((id) => {
        if(Array.isArray(districts)&&districts.length){
            const district = districts.find(k => k.id == id)
            return get(district, 'displayName', '')
        }
    },[districts]);
    async function fetchdata() {
        setLoading(true)
        try {
            const data = await handleListCanvasKeys()
            if (data && data.success) {
                setKeysItems(get(data, 'data', []))
            }
        } catch (error) {
            console.error(error,"error while loading canvas key")
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchdata()
    }, [])
    const onEdit = (data) => {
        setSelectedData(data)
        setOpenEditModal(true)
    }
  return (
   <>
        <KeyUpdate openEditModal={openEditModal} selectedData={selectedData} setOpenEditModal={setOpenEditModal} onDone={fetchdata}  />
          <Wrapper>
              <LoadingWrapper loading={loading}>
                  {KeysItems.length > 0 ? <Table style={{ width: "100%", textAlign: "center" }}>
                      <TableHead>
                          <TableRow>
                              <TableCell style={{ textAlign: "center" }}>District</TableCell>
                              <TableCell style={{ textAlign: "center" }}>key</TableCell>
                              <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {map(KeysItems, keys => {
                            if(!keys) return null;
                              return (<>
                                  <TableRow key={keys.id}>
                                      <TableCell style={{ textAlign: "center" }}>{getDistrictDIsplayName(keys.orgId)}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}>{keys.key||''}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}><Button style={{ width: 85, height: 35 }} variant="text" onClick={()=>onEdit(keys)} > <Edit style={{ color: COLOR.green }} /> </Button></TableCell>
                                  </TableRow>
                              </>)
                          })}
                      </TableBody>
                  </Table> : null}
              </LoadingWrapper>
          </Wrapper>
   </>
  )
}
