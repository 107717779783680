//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';




//  -- List All books ----------------
export const setDistrictAuth = (payload) => ({ type: ActionTypes.canvas.setDistrictAuth, payload })
export const updateDistrictAuth = (payload) => ({ type: ActionTypes.canvas.updateDistrictAuth, payload })

export const setCanvasAssignmentsCategory = (payload) => ({ type: ActionTypes.canvas.setCanvasAssignmentsCategory , payload });
export const updateCanvasAssignmentsCategory = (payload) => ({ type: ActionTypes.canvas.updateCanvasAssignmentsCategory, payload });
export const clearCanvasAssignmentsCategory = (payload) => ({ type: ActionTypes.canvas.cleearCanvasAssignmentsCategory, payload });