import Tooltip from "antd/es/tooltip";
import React from "react";

const SharedAndAssignedTask = ({
  sharedBy = "",
  isSharedActive = true,
  isAssignedActive = true,
}) => {
  return (
    <div style={{ position: "relative" }}>
      {isSharedActive && (
        <>
          <img className="assigned-image-0" alt="" src="./shared-bg.svg" />
          <img className="assigned-image-3" alt="" src="./shared-left.svg" />
          <img className="assigned-image-2" alt="" src="./shared-mid.svg" />
          <img className="shared-image-right" alt="" src="./shared-right.svg" />

          <Tooltip  title={`Shared by ${sharedBy}`} placement="top" showArrow={false}>
          <b className="assigned-text">Shared by {sharedBy?.length > 8 ? sharedBy.slice(0,8) + "..." : sharedBy}</b>
          </Tooltip>
        </>
      )}
      {isAssignedActive && (
        <>
          <img
            style={{ left: isSharedActive && "185px" }}
            className={"assigned-image-0"}
            alt=""
            src="./assigned-bg.svg"
          />
          <img
            style={{ left: isSharedActive && "255px" }}
            className={"assigned-image-1"}
            alt=""
            src="./assigned-right.svg"
          />
          <img
            style={{ left: isSharedActive && "191px" }}
            className={"assigned-image-2"}
            alt=""
            src="./assigned-mid.svg"
          />
          <img
            style={{ left: isSharedActive && "183px" }}
            className={"assigned-image-3"}
            alt=""
            src="./assigned-left.svg"
          />

          <b
            style={{ left: isSharedActive && "209px" }}
            className="assigned-text"
          >
            Assigned
          </b>
        </>
      )}
    </div>
  );
};

export default SharedAndAssignedTask;
