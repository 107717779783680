import config from "../../../config";
import WriteEdit from "./WriteEdit.svg"
import WriteEditWhite from "./WriteEditWhite.svg"
import TrashSvg from "./Trash.svg"
const getFireStoreUrl = (path) => (`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2F${path}?alt=media&token=6a9a7f5a-4959-431c-a305-8eabe60a4d46`);
const getUrl = (path) => (`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/${path}?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`)

const NoAssignmentCompletedimg = getFireStoreUrl("no_assignment_completed.jpg");
const NoAssignmentPastimg = getFireStoreUrl("no_assignment_past.jpg");
const NoAssignmentTodayimg = getFireStoreUrl("no_assignment_today.jpg");
const NoAssignmentWeekimg = getFireStoreUrl("no_assignment_week.png");
const Worldimg = getFireStoreUrl("world.png");
const Worksheetimg = getFireStoreUrl("worksheet.jpg");
const Apimg = getFireStoreUrl("ap.png");
const Birdimg = getFireStoreUrl("bird.png");
const BoxSettimgimg = getFireStoreUrl("boxsetting.png");
const Calendarimg = getFireStoreUrl("calendar.png");
const CotationBgimg = getFireStoreUrl("cotation_bg.png");
const CotationBgimgNew = getFireStoreUrl("cotation_bg_new.png");
const GraphicOrganizerimg = getFireStoreUrl("graphic_organizer.jpg");
const HarramientsImg = getFireStoreUrl("herramientas.jpg");
const LoadingBgimg = getFireStoreUrl("loading_bg.jpg");
const Logoimg = getFireStoreUrl("logo.jpg");
const mobileMenuimg = getFireStoreUrl("mobile_menu.png");
const NoNotesimg = getFireStoreUrl("no_notes.jpg");
const noQuestionimg = getFireStoreUrl("no_question.png");
const NoDataTeacherGraphimg = getFireStoreUrl("noDataTeacherGraph.png");
const Nostroonlineimg = getFireStoreUrl("nostro_online.jpg");
const NoteIconimg = getFireStoreUrl("note_icon.png");
const UpdatedNoteIcon  =  `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/note-empty-icon.svg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
const UpdatedAssignableImg = getUrl("assignable-image.svg")
const UpdatedNoteIconimg =`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/note-icon.svg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
const UpdatedAssignedImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assigned-icon.svg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
const IsolationMode =`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/Isolation_Mode.svg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
const QuestionMarkimg = getFireStoreUrl("question_mark.png");
const ResourceBgimg = getFireStoreUrl("resource_bg.jpg");
const SadEmojiimg = getFireStoreUrl("sad_emoji.png");
const Tbgimg = getFireStoreUrl("tbg.jpg");
const TbgIconimg = getFireStoreUrl("tbg-icon.jpg");
const TrueMarkimg = getFireStoreUrl("true_mark.png");
const VideoCover = getFireStoreUrl("VideoCover.png");
const Ps = getFireStoreUrl("ps.png");
const AuthBG = getFireStoreUrl("AuthBG.png");
const DefaultLogo = getFireStoreUrl("nostro_online.jpg");
const MenuImg = getFireStoreUrl("menuimg.png");
const placeImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fplace.jpg?alt=media&token=647ef082-70d3-4f3e-bc1d-9b55771f97f1`;
const colorImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fcolor.jpg?alt=media&token=c8a8c431-da5a-4f30-861b-d00ef490cf2b`;
const MenuWhiteImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fimageedit_12_3714614806.png?alt=media&token=2c287677-6fbe-4414-9e88-31cbd0a1080c`
const Logo_transparent = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/Logos%2Flogo_transparent.png?alt=media&token=b0871bf7-069c-4906-9884-68f981cf7ef2"
const LoginBg = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/login_cover-min.png?alt=media&token=9fa50222-7c1f-4eb1-8e5d-23c722e10c3e"
const IntoVideoDev = "https://owedev-welcome-videos.s3.amazonaws.com/An%C3%A9cdotas_+vida%2Cactualidad+y+cultura.mp4"
const IntoVideoLausd = "https://owedev-welcome-videos.s3.amazonaws.com/intro_video.mp4"
const circural_arrow = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fcircular_arrow.png?alt=media&token=f5d72608-d06a-4d0b-b313-34847f06fe7b";
const DistrictBg = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fdistinct_bg.png?alt=media&token=59ccb373-a9a0-41ef-b91d-00f4d5636743";
const SimpleLogo = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fsimplelogo.png?alt=media&token=faa01b3c-4aa8-4b0a-8f53-cad73b311379"
const Death_Day = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fhome_logo_1.png?alt=media&token=b74c0654-7eba-4df5-a6b6-f532d47d03e5&_gl=1*10m6fbm*_ga*MTg0Njk4NDQxNS4xNjk3MDAwODI1*_ga_CW55HF8NVT*MTY5OTM0NDQ1NC44LjEuMTY5OTM0NTI2Mi42MC4wLjA."
const home_icon = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/christmas.png?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b";
const IntroVideoCover = getFireStoreUrl("intro_video_cover.png");
const Notesicon = getFireStoreUrl("notesicon.png");
const Questionicon = getFireStoreUrl("questionicon.png");
const IntroVideo = config.isDev ? IntoVideoLausd : IntoVideoDev
const IntroVideoPoster = IntroVideoCover
const CologG = getFireStoreUrl("colorG.png");
const TableBg = getFireStoreUrl("tablebg.png")
const BirdLogo = getFireStoreUrl("birdwithtext.png");
const ButtonBg = getFireStoreUrl("buttonbg.png");
const ClassLeftImg = getFireStoreUrl("classleftimg.png");
const BirdLogoLarge = getFireStoreUrl("BIRDLOGO.png");
const SelectArrow = getFireStoreUrl("selectArrow.png");
const Pic1 = getFireStoreUrl("pic1.jpeg");
const blogbanner = getFireStoreUrl("blogbanner.jpg");
const img34 = getFireStoreUrl("34.png");
const backBg = getFireStoreUrl("backBg.png");
const blog = getFireStoreUrl("blog.jpeg");
const bloglink = getFireStoreUrl("blogLink.png");
const btnBg = getFireStoreUrl("btnBg.png");
const closeicon = getFireStoreUrl("closeIcon.png");
const closeModal = getFireStoreUrl("closeModel.png");
const editIcon = getFireStoreUrl("editIcon.png");
const globeBg3 = getFireStoreUrl("globeBg3.png");
const learnImg = getFireStoreUrl("learnImg.jpg");
const locationImg = getFireStoreUrl("location.png");
const locationResource = getFireStoreUrl("locationResource.png");
const LOGO = getFireStoreUrl("LOGO.png");
const modalBanner = getFireStoreUrl("modalBanner.jpeg");
const nostroOnline = getFireStoreUrl("nostroOnline.jpg");
const profileArrow = getFireStoreUrl("profileArrow.png");
const resource1 = getFireStoreUrl("resource1.png");
const userImg = getFireStoreUrl("user.png");
const videoBlog = getFireStoreUrl("videoBlog.png");
const videoImg = getFireStoreUrl("videoImg.png");
const videoLink = getFireStoreUrl("videoLink.png");
const bubbleNotes = getUrl("bubble_note.png");
const rootImage = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Frobert.jpg?alt=media&token=cabe0039-a258-4863-888a-abba90a994b2`
const bootImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fboot.jpg?alt=media&token=0f1993a2-dcac-4967-9b3c-45c423cb7ed8`
const AIimg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FAI.jpg?alt=media&token=b223d31e-6dd9-4c3f-b456-350c90c02ec9`
const searchIconImage = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FsearchIcon.png?alt=media&token=beaf5a4c-ea94-4030-9770-e6738c071a32`
const LogoOnExam = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/Logos%2Flogo_on_exam.png?alt=media&token=077e2ebd-a61e-4d3f-a309-c11c0e48d639'
const SearchIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fsearch.png?alt=media&token=bbbf3422-4b31-4c95-a7cf-794bb4d0d247`
const MenuIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fmenu.png?alt=media&token=3c732e0c-9fd8-4fbe-8775-b57c2818db59`
const MessageBottal = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FmessageBottal.png?alt=media&token=d7cddf37-96a9-4788-a960-4f622c16ec94`
const MessageRocket = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FmessageRocket.png?alt=media&token=2828d72d-c484-4667-8280-e255a97bb099`
const TransparentLogoWhite = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FtransparentLogo.png?alt=media&token=76a67577-0cd6-4aca-9a17-16da58c62480`
const CatImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2Fcat_av.jpeg?alt=media&token=5ff24895-96f2-4831-b8c2-d17c064f9a0e`
const CalendarIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2Fcalendar%20.png?alt=media&token=9b3f3072-a798-47d1-8173-692e7f8fd94f`;
const ClockIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2Fclock.png?alt=media&token=150ed32d-3a2a-493a-93f9-ef1e7c7dcd3d`
const MobIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2Fmob.png?alt=media&token=a201f5b9-76e9-4d36-9f67-9bd920a2c712`
const IMG_7614 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7614.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7611 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7611.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7605 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7605.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7600 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7600.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7596 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7596.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7595 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7595.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7577 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7577.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7576 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7576.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7575 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7575.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const IMG_7574 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2FIMG_7574.JPG?alt=media&token=b68adaf0-a1e0-401b-86b6-d691432f0f51`
const uploadImg = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/chat%2Fupload.jpeg?alt=media&token=75695184-ee4f-47e0-994e-0554adbb8e4a"
const threeDotImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fthreedots.png?alt=media&token=5eeb8f48-dd5a-47ce-9966-53f0208fbacd`
const LoginLogo = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FoneWayEducationLogo.png?alt=media&token=e6c83bf1-6f18-4fb0-a778-3ea3b142ad95`
const StarRating = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FstarRating.png?alt=media&token=264ba4b3-baf3-40e7-822d-f800be8f8515'
const personGreen2 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FpersonGreen2.png?alt=media&token=a0376a4a-c0b5-47ed-9421-0fe2ec62a86c'
const personGreen1 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FpersonGreen1.png?alt=media&token=75f53aa8-1d80-43d5-ab3d-b4e1b709a946'
const WheelImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2F146.png?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`
const WheelTransparentImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fwheel_transparent.png?alt=media&token=856fa8c6-f1d0-4ec2-bb0a-615746400831`
const WheelClock = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fclock-bg.png?alt=media&token=7693d603-1a84-4e84-bb10-0ed41b9602ef`
const colorCircle = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FcolorCircle.png?alt=media&token=f3b1b390-a5a1-4a31-8d20-af6e31dab6a4`
const robert2 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FA2%20-%20GRAPHIC%20ORGANIZERS.png?alt=media&token=161fe3dc-3b30-492d-a67c-e68c83d6a77c`
const datagrid_close = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fdatagrid%2Fclose.png?alt=media&token=775a9f9b-600f-4110-b639-cef31d62a048`;
const datagrid_logo = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fdatagrid%2Flogo.png?alt=media&token=775a9f9b-600f-4110-b639-cef31d62a048`;
const datagrid_colorCombo = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fdatagrid%colorCombo.jpg?alt=media&token=775a9f9b-600f-4110-b639-cef31d62a048`;
const datagrid_searchIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fdatagrid%2FsearchIcon.png?alt=media&token=83bbc5a4-bb2a-4cf2-a552-e3134eb81545`;
const datagrid_eyeIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fdatagrid%2FeyeIcon.png?alt=media&token=056408b8-b1d8-4607-949c-eca2e54ce75f`;
const datagrid_logoColored = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fdatagrid%2FlogoColored.png?alt=media&token=7ba15483-64ea-4bdc-a8ad-b13a6e41df12`;
const pic11 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fpic11.jpg?alt=media&token=d497d1e2-a6d9-48c8-9cb0-5f7ac49eeb47`;
const pic22 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fpic22.jpg?alt=media&token=d497d1e2-a6d9-48c8-9cb0-5f7ac49eeb47`;
const pic33 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fpic33.jpg?alt=media&token=d497d1e2-a6d9-48c8-9cb0-5f7ac49eeb47`;
const pic44 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fpic44.jpg?alt=media&token=d497d1e2-a6d9-48c8-9cb0-5f7ac49eeb47`;
const pic55 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fpic55.jpg?alt=media&token=d497d1e2-a6d9-48c8-9cb0-5f7ac49eeb47`;
const DueTodayIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2F31.png?alt=media&token=800ef2d4-654f-4633-84fd-c308c7d801b0`
const PastDueTodayIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2F32.png?alt=media&token=9ba771c0-6367-4149-9ede-742703f56083`;

const ChatMenue = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FChat_Icon.jpg?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`;
const Questions = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FQuestion_icon.jpg?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`;
const Notes = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FNotes_icon.jpg?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`;
const Notification = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FNotification_Icon.jpg?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`;
const oneWayLessonsBalcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FBalcon_cultural.jpg?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`;
const oneWayLessonsConectandome = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FConectandome_Cover.jpg?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`;
const oneWayLessonsComunidad = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FComunidad_cover.jpg?alt=media&token=2b19792a-1a6d-4e50-a0a1-a614eb0905fe`;
const IdeaOneWay = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/idea1.png?alt=media&token=1809af4d-ebbe-437e-ae33-6088891bc72f`
const IdeaAi = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/AiImg.png?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
const CheckListImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/checklistImg.webp?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
const AIfeebackImage = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fai_ico.png?alt=media&token=c48f0aa0-0bab-4cd8-822f-b47158829b3a`
const AiFeedbackImage = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/roboAISuggest.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
// const ChatMenue = require('./menuIcon/Chat_Icon.jpg')
const DUMMY_POSTER = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fpreview_blank_image.webp?alt=media&token=fd60ec0e-7479-42e4-b05b-59cfc75113ba`
// const Debate = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/one-way-debate.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
const DebateLftIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/flipArrowLeft.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
const DebateRgtIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/flipArrowRight.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
const EditDebate =`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/editdebate.jpg?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
// const DebateOneWayLogo =`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/debateWith1Way_Logo.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`;
const DebateNewLogo = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/debateNew_Logo1.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
const DebateComment = require('./dogprofile.png')
export const AbsentSvg  = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/Logos%2Fabsent.svg?alt=media&token=f31dddc8-9de3-4ee5-b1e6-3450e68c07fd`
export const LateSvg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/Logos%2Flate.svg?alt=media&token=ca23390b-96f9-4ff2-9196-a89b3ab8f8ae`
export const Pinpoint = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/Logos%2FpinIcon2.png?alt=media&token=9523516f-de6b-4cef-afae-9c9f1e33cba6`
export const debatePoster = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FGRADEBOOK%20DEBATE%20VIEW.png?alt=media&token=48ffbe1c-a318-4d96-ac76-2af6f2ecaa6b`
export const message_alert_bg1 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/alert_message_bg1.jpg?alt=media&token=5b8815b5-4867-4b32-9abd-c60357302b66`
export const message_alert_bg2 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/alert_message_bg2.jpg?alt=media&token=5b8815b5-4867-4b32-9abd-c60357302b66`
export const message_alert_bg3 = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/alert_message_bg3.jpg?alt=media&token=5b8815b5-4867-4b32-9abd-c60357302b66`
export const noise_meter_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fnoise_meter_icon.png?alt=media&token=77b59904-f372-45ce-9612-7fa2eb79db08`
export const ClocksImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/clockImg.webp?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
export const ExpressionImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/expressionmg.webp?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
export const DownArrowImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/downArrowmg.png?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
export const birdLogoImg = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/birdmgLogo.png?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
export const newIdeas = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newIdeas.png?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
export const newDebates = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newDebate.png?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
export const newChats = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newChats.png?alt=media&token=c2ad5451-5f0a-48e4-9aba-267214075739`
export const trafficAudio = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/light-sound.mp3?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const femaleAI = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/femaleAI.webp?alt=media&token=5b8815b5-4867-4b32-9abd-c60357302b66`
export const maleAI = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/maleAI.webp?alt=media&token=5b8815b5-4867-4b32-9abd-c60357302b66`
export {
  VideoCover,
  NoAssignmentCompletedimg,
  NoAssignmentPastimg,
  NoAssignmentTodayimg,
  NoAssignmentWeekimg,
  Worldimg,
  Worksheetimg,
  Apimg,
  Birdimg,
  BoxSettimgimg,
  Calendarimg,
  CotationBgimg,
  GraphicOrganizerimg,
  HarramientsImg,
  LoadingBgimg,
  Logoimg,
  mobileMenuimg,
  UpdatedAssignableImg,
  NoNotesimg,
  noQuestionimg,
  NoDataTeacherGraphimg,
  Nostroonlineimg,
  NoteIconimg,
  QuestionMarkimg,
  ResourceBgimg,
  SadEmojiimg,
  Tbgimg,
  TbgIconimg,
  TrueMarkimg,
  Ps,
  AuthBG,
  DefaultLogo,
  LoginBg,
  Logo_transparent,
  IntroVideo,
  IntroVideoPoster,
  circural_arrow,
  DistrictBg,
  SimpleLogo,
  Death_Day,
  MenuImg,
  Notesicon,
  Questionicon,
  CologG,
  TableBg,
  BirdLogo,
  ButtonBg,
  ClassLeftImg,
  BirdLogoLarge,
  SelectArrow,
  Pic1,
  blogbanner,
  img34,
  backBg,
  blog,
  bloglink,
  btnBg,
  closeicon,
  closeModal,
  editIcon,
  globeBg3,
  learnImg,
  locationImg,
  locationResource,
  LOGO,
  modalBanner,
  nostroOnline,
  profileArrow,
  resource1,
  userImg,
  UpdatedNoteIcon,
  videoBlog,
  videoImg,
  videoLink,
  LogoOnExam,
  CotationBgimgNew,
  SearchIcon,
  MenuIcon,
  MessageBottal,
  MessageRocket,
  TransparentLogoWhite,
  CatImg,
  CalendarIcon,
  ClockIcon,
  MobIcon,
  IMG_7614,
  IMG_7611,
  IMG_7605,
  IMG_7600,
  IMG_7574,
  IMG_7575,
  IMG_7576,
  IMG_7577,
  IMG_7595,
  IMG_7596,
  WriteEdit,
  WriteEditWhite,
  TrashSvg,
  uploadImg,
  MenuWhiteImg,
  threeDotImg,
  searchIconImage,
  UpdatedAssignedImg,
  AIimg,
  bootImg,
  rootImage,
  colorImg,
  placeImg,
  LoginLogo,
  StarRating,
  personGreen1,
  personGreen2,
  WheelImg,
  WheelTransparentImg,
  WheelClock,
  colorCircle,
  robert2,
  datagrid_close,
  datagrid_colorCombo,
  datagrid_eyeIcon,
  datagrid_logo,
  datagrid_searchIcon,
  datagrid_logoColored,
  pic11,
  pic22,
  pic33,
  pic44,
  pic55,
  DueTodayIcon,
  PastDueTodayIcon,
  ChatMenue,
  Questions,
  Notes,
  UpdatedNoteIconimg,
  Notification,
  oneWayLessonsBalcon,
  oneWayLessonsConectandome,
  oneWayLessonsComunidad,
  IdeaOneWay,
  IdeaAi,
  home_icon,
  DUMMY_POSTER,
  AIfeebackImage,
  // Debate,
  DebateLftIcon,
  DebateRgtIcon,
  EditDebate,
  DebateComment,
  // DebateOneWayLogo,
  DebateNewLogo,
  CheckListImg,
  bubbleNotes,
  IsolationMode,
  AiFeedbackImage,
};
