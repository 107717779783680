//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import _, { filter, find, map, uniqBy, get } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import React from "react"
//  -- Components ---------------------
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Col, Row } from 'antd';
import ChapterCard from 'components/AssignmentBookCover';
import DemoModal from 'components/DemoModal';
import Section from 'components/Section';
//  -- Constants ----------------------


//  -- Features -----------------------
import AnimatedText from "components/AnimatedText";
import ChapterList from 'features/ChapterList';
import { ResourceBgimg } from "utils/assets/images";
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useBookOrder } from "store/books/selectors"
import { mapBookByOrder } from "utils/lib/ebookHelper"


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CustomCol = styled.div`
    margin: 0px;
    width: 100%;

    @media (min-width: 992px) {
        margin: ${p => p.margin};
        width: calc(50% - 12px);
    }
`;

const CustomRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 992px) {
        flex-direction: row;
    }
`;

const ListItem = styled.li`
    margin: 0px 16px 10px;
    width:25%;
    max-width:150px;
    @media only screen and (min-width: 600px) and (max-width: 800px) {
        margin: 0px 16px 10px;
        width: calc(50% - 32px);
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin: 0px 10px 10px;
        width: calc(50% - 32px);
    }
`
const List = styled.ul`
    /* width: calc(100% + 40px); */
    width: 100%;
    height: auto;
    margin-left: -16px;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    list-style-type: none;

    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0px;
      
    }
    @media (max-width: 992px) {
        flex-wrap: wrap;
    }
`;

export const Wrapper = styled.div`
        height: 100%;
        width:calc(100% + 48px);
        margin-left: -24px;
        padding: 0px 24px;
        // background-image:url(${ResourceBgimg});
        background-repeat: no-repeat;
        justify-content:center;
`;
const BookSection = styled.div`
    border: 2px solid #e9e9e9;
    margin-bottom: 26px;
    position: relative;
    padding-left: 16px;
    padding-top: 24px;
    padding-right: 16px;
`;
const SectionTitle = styled.div`
    font-size: 14px;
    color: #ffffff;
    background: ${p=>p.bgColor?p.bgColor:'#00c4cc'};
    padding: 4px 8px;
    border-radius: 6px !important;
    cursor: pointer;
    position: absolute;
    top: -10px;
    left: -6px;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Chapter management
//
//  -- Description
//  Activity and quiz management for selected chapter
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const ChapterManagement = ({
    booksAllList,
    activities,
    classActivities,
    classQuizzes,
    currentOrg,
    handleCreateActivity,
    handleEditActivity,
    handleDeleteActivity,
    handleCreateQuiz,
    handleEditQuiz,
    handleDeleteQuiz,
    quizzes,
    userBooksList
}) => {
    const [chaptersForUser, setChaptersForUser] = useState([])
    const bookOrder = useBookOrder()
    const [chapter, setChapter] = useState(null);
    const [modalOpen, toggleModal] = useState(false);
    const [unassignedActivities, setActivities] = useState([]);
    const [unassignedQuizzes, setQuizzes] = useState([]);
    const [bookDesc, setBookDesc] = useState("");
    const [subtopics, setSubtopics] = useState([]);
    const [subtopicId, setSubtopicId] = useState(0);
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = get(orgPermissions, 'books', [])

    const handleSetChapter = ((chapter) => {
        setChapter(chapter);
        const book = filter(_.get(userBooksList, "books", []), x => x.bookId == chapter);
        let desc = book[0].description
        if (desc && typeof desc === 'string') {
            if (desc.includes('Anecdotas')) {
                desc = desc.replace('Anecdotas', 'Anécdotas')
            }
            setBookDesc(desc)
        }
        if (Array.isArray(booksAllList)) {
            const books = filter(booksAllList, x => x.id == chapter);
            setSubtopics(books[0]?.subTopics);
        }
    });

    const handleSubtopics = ((idTopic) => {
        if (idTopic == "0") {
            setSubtopicId(idTopic);
            setActivities(filter(activities, x => !find(classActivities, ['activityId', x.id])));
            setQuizzes(filter(quizzes, x => !find(classQuizzes, ['quizId', x.id])));
            return;
        }
        // Actualizar id Topic select
        setSubtopicId(idTopic);
        // Obtener datos de Subtopic seleccionado
        const subtopic = filter(subtopics, x => x.subTopicId == idTopic);
        // Obtener rango de páginas del subtopic
        const subtopicStart = parseInt(subtopic[0].startPage);
        const subtopicFinish = parseInt(subtopic[0].finishPage);

        let subtopicAct = [];
        let subtopicQuiz = [];
        // Crear lista de Actividades dentro del subtopic
        for (const element of unassignedActivities) {
            if (parseInt(element.page) >= subtopicStart && parseInt(element.page) <= subtopicFinish) {
                subtopicAct.push(element);
            }
        }
        // Actualizar Actividades de Subtopic
        setActivities(subtopicAct);

        // Crear lista de Quizzes dentro del subtopic
        for (const element of unassignedQuizzes) {
            if (parseInt(element.page) >= subtopicStart && parseInt(element.page) <= subtopicFinish) {
                subtopicQuiz.push(element);
            }
        }

        // Actualizar Quizzes de subtopic
        setQuizzes(subtopicQuiz);
    });

    useEffect(() => {
        setActivities(filter(activities, x => !find(classActivities, ['activityId', x.id])));
    }, [activities, classActivities]);

    useEffect(() => {
        setQuizzes(filter(quizzes, x => !find(classQuizzes, ['quizId', x.id])));
    }, [quizzes, classQuizzes]);
    useEffect(() => {
        let _chaptersForUser = [], uBook = [];
        map(userBooksList?.books, item => {
            let f = find(booksAllList, ["id", item.bookId])
            if (f) {
                uBook.push(f);
            }
        })
        if (uBook.length > 0) {
            _chaptersForUser = _.filter(uBook, k => {
                if (bookPermissions.length == 0) {
                    return true;
                }
                const bookaccess = _.find(bookPermissions, b => b.bookId == k.id || b.bookId == k.bookId)
                if (bookaccess) {
                    return bookaccess.available
                } else {
                    return true;
                }
            })
            setChaptersForUser(uniqBy(_chaptersForUser, "id"))
        }
    }, [userBooksList])
    useEffect(() => {
        if (chaptersForUser && chaptersForUser.length > 0) {
            const book = mapBookByOrder(chaptersForUser, bookOrder)
            const books =book[0].books[0]
            if (!chapter) {
                handleSetChapter(books.id)
            }
        }
    }, [chaptersForUser,bookOrder])
    const getSortedData = (data) => {
        return filter(data, x => x.data ? x.data?.chapter === chapter : x.chapter === chapter)
    }
    const renderAssessment = () => {
        const assignedAssignment = getSortedData(_.concat(classActivities, classQuizzes))
        const unassignedChapterActivities = getSortedData(unassignedActivities)
        const unassignedChapterQuizzes = getSortedData(unassignedQuizzes)
        return (<>
            <Row style={{ marginBottom: 24 }}>
                <AnimatedText text={bookDesc} />
            </Row>
            {assignedAssignment.length > 0 ? <Row gutter={[0, 24]} style={{ width: '100%' }} className="assignDetail">
                <ChapterList
                    chapter={chapter}
                    data={assignedAssignment}
                    handleEditActivity={handleEditActivity}
                    handleDeleteActivity={handleDeleteActivity}
                    handleEditQuiz={handleEditQuiz}
                    handleDeleteQuiz={handleDeleteQuiz}
                    message="No Activites or Quizzes assigned for this chapter"
                    title='Assigned'
                    bookDesc={bookDesc}
                    booksAllList={booksAllList}
                />
            </Row> : null}
            {subtopics.length > 0 && (<Row gutter={[0, 32]}>
                <Col span={24} className="subtopicsCstm">
                    <FormControl variant='outlined'>
                        <InputLabel id="subtopics-label">SUBTOPICS</InputLabel>
                        <Select
                            labelId="subtopics-label"
                            id="subtopicsId"
                            value={subtopicId}
                            onChange={e => handleSubtopics(e.target.value)}
                        >
                            {subtopics?.map(subtopic => (
                                <MenuItem key={subtopic.subTopicId} value={subtopic.subTopicId}>
                                    {subtopic.shortName}
                                </MenuItem>
                            ))}
                            <MenuItem key="0" value="0">Todos</MenuItem>

                        </Select>
                        <FormHelperText className="selectSubTpc">Select a Subtopic</FormHelperText>
                    </FormControl>
                </Col>
            </Row>)}
            <CustomRow className="quizzesUpr">
                {unassignedChapterActivities.length > 0 && <CustomCol margin="0px 12px 0px 0px">
                    <ChapterList
                        chapter={chapter}
                        data={unassignedChapterActivities}
                        onAssign={handleCreateActivity}
                        message="No Activites remaining for this chapter"
                        title='Activities'
                        bookDesc={bookDesc}
                        booksAllList={booksAllList}
                    />
                </CustomCol>}
                {unassignedChapterQuizzes.length > 0 && <CustomCol margin="0px 0px 0px 12px">
                    <ChapterList
                        chapter={chapter}
                        data={unassignedQuizzes}
                        onAssign={handleCreateQuiz}
                        message="No Quizzes remaining for this chapter"
                        title='Quizzes'
                        bookDesc={bookDesc}
                        booksAllList={booksAllList}
                    />
                </CustomCol>}
            </CustomRow>
        </>)
    }
    const handleChapterClick = (chapter) => {
        if ((chapter.id !== '1' && currentOrg?.demo) || chapter.id === '6') {
            toggleModal(true)
        } else {
            handleSetChapter(chapter.id)
        }
    }
    const orderedBook = mapBookByOrder(chaptersForUser, bookOrder)
    return (
        <Wrapper>
            <DemoModal
                open={modalOpen}
                handleClose={() => toggleModal(false)}
                message={currentOrg?.demo ? 'Not available in demo' : 'Chapter is currently under maintenance'}
            />
            <Row style={{ paddingTop: "1%", paddingBottom: 0 }}>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Section style={{ fontSize: '18px' }}
                        description="Below are the chapters for the book. Clicking on a chapter will display the activities and quizzes for that chapter. You can then assign any of the activities or quizzes to the class."
                        header='Assignments'
                    >
                        {map(orderedBook, bk => (<BookSection>
                            {bk.title ? <SectionTitle bgColor={bk.color}>{bk.title}</SectionTitle> : null}
                            <List>
                                {map(bk.books, book => {
                                    if (!book || !book.id) return null;
                                    return (
                                        <ListItem key={book.id} className={`${book.id == chapter ? "card-resource-selected" : ""}`}>
                                            <ChapterCard data={book}
                                                handleClick={() => handleChapterClick(book)}
                                                booksAllList={booksAllList}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </BookSection>))}

                    </Section>
                </Col>
            </Row>
            {chapter !== null && renderAssessment()}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ChapterManagement.propTypes = {};

//  -- Default props ------------------
ChapterManagement.defaultProps = {};

export default ChapterManagement;
