//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { map , filter , orderBy } from 'lodash';
import { useMemo } from 'react';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const videosSelecter = (state) => state.videos;
const resourcesSelecter = (state) => state.resources;

//  -- Examples array selector --------

export const useResources = () => {
    const selectedData = useSelector(resourcesSelecter, shallowEqual)
    return selectedData;
}
export const useVideos = () => {
    const selectedData = useSelector(videosSelecter, shallowEqual)
    return selectedData;
}
export const useFunVideos = (role) => {
    const selectedData = useSelector(videosSelecter, shallowEqual)
    const fun_videos = useMemo(()=>orderBy(filter(selectedData,k=>k.metadata.day&&k.metadata.role ===role),"metadata.day_count","asc"),[selectedData,role])
    return fun_videos;
}
export const useDemoVideos = (role) => {
    const selectedData = useSelector(videosSelecter, shallowEqual)
    const demo_videos = useMemo(() => filter(selectedData, k => k.metadata.type == 'demo'), [selectedData])
    return demo_videos;
}
