//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { ArrowLeftOutlined } from '@ant-design/icons';
//  -- Constants ----------------------
import { filter, find, get, groupBy, isEmpty, sortBy } from 'lodash';
//  -- Thunks -------------------------
import { Button, Col, Row } from 'antd';
import UserForm from 'features/forms/admin/User';
import { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleListGetSchool, handleUpdateLicences } from "store/organizations/thunks";
import { handleListDistUsers } from 'store/users/thunks';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const StableWrapperMain = styled.div`
    margin-top:10px;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------

const AddTeachers = ({
    userloading,
    districts,
    onTabsJump = () => null,
    onUserAddOrUpdate = () => null,
    schools,
    booksAllList,
    users
}) => {
    const history = useHistory();
    const location = useLocation();
    const data = location.state;
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const currentOrg = useCurrentOrg()
    const dispatch = useDispatch();
    const userData = get(data, 'userData', null)
    const editDistrict = get(data, 'isEdit', false);
    const usersTeachers = filter(users, (u) => u.role === "teacher");
    const usersStudent = filter(users, (u) => u.role === "student");
    const teacherByOrg = groupBy(usersTeachers, (u) => u.orgId);
    const studentByOrg = groupBy(usersStudent, (u) => u.orgId);
    const setFilterdDistrict = (dist) => {
        if (dist) {
            const cOrg = find(dist, d => d.id == get(currentOrg, "id"))
            if (cOrg) {
                const sortedDist = sortBy(filter(dist, d => d.id != get(currentOrg, "id")), ["displayName"])
                const res = [cOrg, ...sortedDist]
                setFilteredDistricts(res)
            } else {
                setFilteredDistricts(sortBy(dist, ["displayName"]))
            }

        } else {
            setFilteredDistricts([])
        }
    }
    useEffect(() => {
        setFilterdDistrict(districts)
    }, [districts])

    // useEffect(() => {
    //     async function fetchData() {
    //         // dispatch(handleListDistricts());
    //         // dispatch(handleListAllBooks());
    //         // dispatch(handleProcessClassTenant())
    //         // dispatch(handleGetCoursesForClever('1'))
    //         // fetchDistrictData();
    //     }
    //     fetchData();
    // }, []);

    // useEffect(() => {
    //     fetchDistrictData();
    // }, [currentOrg]);
    const fetchDistrictData = async () => {
        try {
        //   setUserLoading(true);
          if (!isEmpty(currentOrg)) {
            // await handleUpdateLicences(currentOrg.id)
            dispatch(handleListGetSchool(currentOrg.id))
            await dispatch(handleListDistUsers(currentOrg.id));
          }
        //   setUserLoading(false);
        } catch (error) {
        //   setUserLoading(false);
        }
    };
    const backToDataBrowser = () => {
        history.push('/data-browsers');
    };

    let listBooks = [];

    for (var book in booksAllList) {
        let isNotAssignable = booksAllList[book].isNotAssignable;
        let _data = {
            id: booksAllList[book].id,
            description: booksAllList[book].description,
            count: booksAllList[book].count,
        };
        if (!isNotAssignable) {
            listBooks.push(_data);
        }
    }
    return (
        <>
            <Row>
                <Col lg={24}>
                    <Button type="link" primary style={{ paddingLeft: 0, fontSize: 17, marginBottom: 20 }} onClick={backToDataBrowser}>
                        <ArrowLeftOutlined />
                        Back
                    </Button>
                </Col>
            </Row>
            <StableWrapperMain>
                <UserForm
                    userData={userData}
                    editing={editDistrict}
                    teacherByOrg={teacherByOrg}
                    studentByOrg={studentByOrg}
                    listBooks={listBooks}
                    onClose={() => {
                        fetchDistrictData();
                        // setSearchText("");
                    }}
                    schools={schools}
                    currentOrg={currentOrg}
                />
            </StableWrapperMain>
        </>
    );
};


//  -- Prop types ---------------------
AddTeachers.propTypes = {};

//  -- Default props ------------------
AddTeachers.defaultProps = {};
const mapStateToProps = (state) => ({
    schools: state.schools,
    booksAllList: state.booksAllList,
    users: state.users
});

export default connect(mapStateToProps, null)(AddTeachers);
