//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const notesInitial = [];
const bubbleInitial = [];

export const notes = (state = notesInitial, action) => {
    switch (action.type) {
        case ActionTypes.notes.createNoteSuccess:
            return [action.payload, ...state];
        case ActionTypes.notes.deleteNoteSuccess:
            return state.filter(x => x.id !== action.payload.id);
        case ActionTypes.notes.listNotesSuccess:
        case ActionTypes.notes.updateNoteSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload };
                } else {
                    return x;
                }
            });
        case ActionTypes.notes.listChapterNotesSuccess:
        case ActionTypes.notes.listClassNotesSuccess:
        case ActionTypes.notes.listOrgNotesSuccess:
        case ActionTypes.notes.listUserNotesSuccess:
            return action.payload;
        case ActionTypes.notes.createNoteFail:
        case ActionTypes.notes.deleteNoteFail:
        case ActionTypes.notes.listNotesFail:
        case ActionTypes.notes.listChapterNotesFail:
        case ActionTypes.notes.listClassNotesFail:
        case ActionTypes.notes.listOrgNotesFail:
        case ActionTypes.notes.listUserNotesFail:
            return state;
        default:
            return state;
        
    }
};

export const classNotes = (state = notesInitial, action) => {
    switch (action.type) {
        case ActionTypes.notes.listClassNotesSuccess:
            return action.payload;
        case ActionTypes.notes.listClassNotesFail:
            return state;
        default:
            return state;
    }
};

export const bookNotes = (state = bubbleInitial, action) => {
    switch (action.type) {
        case ActionTypes.notes.listNoteByBookSuccess:
            return action.payload;
        case ActionTypes.notes.listNoteByBookFail:
            return state;
        default:
            return state;
    }
}