import { Col, Row, Switch } from "antd";
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { Debate, DebateLftIcon, DebateRgtIcon } from "utils/assets/images";
import { get, map } from "lodash";
import DebateCommentSection from "../DebateCommentSection";

// const ResponseText = styled.div`
//   margin-left: 20px;
// `;

const GoodJob = () => {
 
    return (
        <>
          <div className="goodjobpage">
            <img src="https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/debetlogoImage.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222" alt="Debate" className="debate1waysticker" />
          </div>
          <div className="goodjobbg">
            <img
              src={require('../../../utils/assets/images/good_job_1.gif')}
              alt="Debate"
              className="goodjobimage"
            />
          </div>
        </>
      );
    };

export default GoodJob;


