import { Card, Col, Row, Badge } from "antd";
import AssignmentReviewByNotify from "components/AssignmentReviewByNotify";
import _, { filter, find, get, map } from 'lodash';
import Lottie from "lottie-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useAllBook } from "store/books/selectors";
import { useBookUnits } from "store/booksUnits/selectors";
import { useClassActivities } from "store/classActivities/selectors";
import { handleListClassActivities } from "store/classActivities/thunks";
import { useClassQuizzes } from "store/classQuizzes/selectors";
import { handleListClassQuizzes } from "store/classQuizzes/thunks";
import { useCurrentClass } from "store/classes/selectors";
import { useNotifications } from "store/notification/selectors";
import { handleCreateNotification } from "store/notification/thunks";
import { useResources } from "store/resources/selectors";
import { useClassSubmissions } from "store/submissions/selectors";
import { handleListClassSubmissions } from "store/submissions/thunks";
import { useCurrentUser } from "store/users/selectors";
import styled from "styled-components";
import { NOTIFICATIONICONS } from "utils/assets/images/lootie";
import {
  imageFetch,
  imageFetchFromPodcast,
  isVideo
} from "utils/lib/ebookHelper";

const Container = styled.div`
  min-height: 50vh;
  width: 100%;
  padding: 30px 60px;
  @media (max-width: 600px) {
    padding: 20px 0px;
  }
`;
const NotificationSection = styled.div`
  padding-left: 10px;
`;
const TextTitle = styled.div`
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  text-align: left;
`;
const TextDate = styled.div`
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  text-align: left;
`;
const TextDateTitle = styled.div`
  font-size: 13px;
  color: #000000;
  font-weight: 600;
  text-align: left;
`;

const Image = styled.img`
  height: 70px;
`;

const NotificationsMainSection = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  padding-left: 20px;
`;
const NotificationTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
`;
const SeenSection = styled.div`
  width: 8px;
  height: 8px;
  background-color: rgb(28, 49, 115);
  border-radius: 50%;
  margin-right: 8px;
`;
const Notification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookUnits = useBookUnits();
  const resources = useResources();
  const booksAllList = useAllBook();
  const currentUser = useCurrentUser();
  const currentClass = useCurrentClass();
  const submissions = useClassSubmissions();
  const allNotifications = useNotifications();
  const [reviewData, setReviewData] = useState();
  const classActivities = useClassActivities()
  const classQuizzes = useClassQuizzes()
  const allAssignment = [...classActivities, ...classQuizzes];
  const [isnotification, setIsnotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState();
  const [assignmentDrawer, toggleAssignmentDrawer] = useState(false);
  const filterByClass = get(currentUser, 'role', '') == 'teacher' ? filter(allNotifications, obj => obj.classId == get(currentClass, 'id', '') && obj.userId !== get(currentUser, "id")) : filter(allNotifications, obj => (obj.createdBy == get(currentClass, 'id', '') || obj.createdBy == get(currentUser, 'id', '')) && obj.userId !== get(currentUser, "id"));
  const sortedData = filterByClass.slice().sort((a, b) => {
    const dateA = new Date(a.modified);
    const dateB = new Date(b.modified);
    return dateB - dateA; // Compare in reverse order for new to past
  });

  const modalSrc = (activeAssignment) => {
    return isVideo(get(activeAssignment, "data.chapter"), resources)
      ? imageFetchFromPodcast(get(activeAssignment, "data.chapter"), resources)
      : imageFetch(
        booksAllList,
        get(activeAssignment, "data.chapter"),
        get(activeAssignment, "data.page")
      );
  };

  const gridStyle = {
    width: "100%",
    textAlign: "center",
    padding: "7px",
    cursor: "pointer",
  };
  const gridStyleSeen = {
    width: "100%",
    textAlign: "center",
    padding: "7px",
    cursor: "pointer",
    backgroundColor: "#e8e4ee"
  }

  const onNotificationClick = (data) => {
    const chapter = _.get(data, "data.chapter", '')
    const assignmentId = _.get(data, "data.id", '')
    const assignmentPage = _.get(data, "data.page", 0)
    const type = _.get(data, 'type', '')
    if (isVideo(chapter, resources)) {
      // redirectToAssignmentVideo(chapter,assignmentId);
      // console.log('redirectToAssignmentVideo');
    } else if (chapter == "33" || chapter == "41" || chapter == "43" || chapter == "49") {
      const BALCON_PAGES = _.get(_.find(bookUnits, ["bookId", chapter]), "pages", [])
      const typeId = "";
      const bookitem = _.find(BALCON_PAGES, k => parseInt(k.start) <= assignmentPage && parseInt(k.finish) >= assignmentPage)
      if (chapter == '33') {
        let url = `/balconCulturalBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
        history.push(url);
      } else if (chapter == '41') {
        let url = `/informateBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
        history.push(url);
      } else if (chapter == '43') {
        let url = `/ComunidadBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
        history.push(url);
      } else if (chapter == '49') {
        let url = `/ConectandomeBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
        history.push(url);
      }
    } 
    // else if (type == 'update_grade') {
    //   history.push('/student/class?view=studentData');
    // }
     else {
      history.push(`/ebook?chapter=${chapter}&page=${assignmentPage}&activity=${data?.data.typeId === 'activity' ? assignmentId : null}&quiz=${data?.data.typeId === 'quiz' ? assignmentId : null}`);
    }
  }

  const createNotification = async (notificationData) => {
    dispatch(handleCreateNotification(notificationData));
  }

  const getClassAssignment = async () => {
    dispatch(handleListClassActivities(currentClass.id));
    dispatch(handleListClassQuizzes(currentClass.id));
    dispatch(handleListClassSubmissions(get(currentClass, "id")))
  }

  const onStudentEndNotification = (data) => {
    if (data.type == 'delete_activity' || data?.type == 'delete_quiz') {
      return;
    } else {
      onNotificationClick(data);
      setIsnotification(false);
    }
  }

  const onNotificationCheck = async (data, findData) => {
    const notificationData = {
      ...data,
      seen: false
    }
    await dispatch(handleCreateNotification(notificationData));
    setIsnotification(false);
    if (get(currentUser, "role") === "teacher") {
      setReviewData(findData);
      toggleAssignmentDrawer(true);
      setIsnotification(false);
    } else {
      if (data.type == 'delete_activity' || data?.type == 'delete_quiz') {
        return;
      } else {
        onNotificationClick(data);
      }
    }

  }

  const onRoleCheck = async (data, findData) => {
    const notificationData = {
      ...data,
      seen: false
    }
    if (get(currentUser, "role") === "teacher") {
      await Promise.allSettled([
        createNotification(notificationData),
        getClassAssignment()
      ]);
      setReviewData(findData);
      setSelectedNotification(data);
      toggleAssignmentDrawer(true);
      setIsnotification(false);
    } else {
      createNotification(notificationData);
      onStudentEndNotification(data);
    }
  }

  const SingleNotificationSection = ({ item }) => {
    const inputDate = get(item, "modified", "");
    const seenStatus = get(item, "seen", '');
    const date = new Date(inputDate);
    const assignmentId = _.get(item, "data.id", '')
    const findData = find(allAssignment, obj => get(obj, 'data.id', '') == assignmentId)
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    // Combine the formatted date and time
    const result = `${formattedDate} at ${formattedTime}`;
    return (
      <Badge.Ribbon text={seenStatus ? 'New' : 'Done'} color={seenStatus ? 'red' : '#03989e'}>
      <Card.Grid style={seenStatus ? gridStyleSeen : gridStyle} onClick={() => {
        onRoleCheck(item, findData)
      }}> 
        <Row>
          <Col xs={24} lg={24}>
            <NotificationsMainSection>
              {/* {seenStatus && (
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}>
                  <SeenSection />
                </div>
              )} */}
              <Image
                src={modalSrc(item)}
                alt={""}
              />
              <NotificationSection>
                <TextTitle>{item.displayName}</TextTitle>
                <TextDateTitle>{item.message}</TextDateTitle><span style={{ width: '6px' }}></span>
                <TextDate>{result}</TextDate>
              </NotificationSection>
            </NotificationsMainSection>
          </Col>
        </Row>
      </Card.Grid>
      </Badge.Ribbon>
    );
  };
  return (
    <Container>
      <AssignmentReviewByNotify assignment={reviewData} assignmentDrawer={assignmentDrawer} selectedNotification={selectedNotification} oncloseDrawer={() => {
        toggleAssignmentDrawer(false)
      }} />
      <NotificationTitle>Notifications</NotificationTitle>
      {sortedData.length === 0 ? (
        <div className="notifiPlaceholder">
          <Lottie animationData={NOTIFICATIONICONS.poster} />
        </div>
      ) : (
        map(sortedData, (item) => (
          <SingleNotificationSection item={item} />
        ))
      )}
    </Container>
  );
};

export default Notification;
