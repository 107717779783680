//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from "aws-amplify";
import * as Sentry from "@sentry/react";
import * as Actions from './actions';
export const getUserByCleverId = async (id) => {
  try {
    return API.get("clever", `/getUserByCleverId/${id}`);
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error)
    }
  }
};

export const handleGetCoursesForClever = (distId) => {
  return async (dispatch) => {
    try {
      const response = await API.get("clever", `/getClevelCources/${distId}`)
      if (Array.isArray(response)) {
        dispatch(Actions.createSuccessCleverCources(response))
      }
      else {
        dispatch(Actions.createSuccessCleverCources([]))
      }
    } catch (error) {
      if (error && error.message != "Network Error") {
        Sentry.captureException(error)
      }
      return [];
    }
  }
}
export const handleUserByCleverName = async (name) => {
  if(typeof name!="string") return [];
  try {
    const firstNamme = name.replace(/\s\s+/g, ' ').split(" ")[0].trim();
		const secondNamme = name.replace(/\s\s+/g, ' ').split(" ")[1]?.trim();
      const user = await API.get("usersRDS", `/cleverUserByName/${firstNamme}/${secondNamme}`)
      return user;

  } catch (error) {
      if (error && error.message != "Network Error") {
          Sentry.captureException(error)
      }
      return ({ error: true });
  }
}
export const handleCleverUserWithCode = (code) => async (dispatch) => {
  try {
    const cleverUser = await API.get("clever", `/usercode/${code}`)
    if (cleverUser && cleverUser.access_token) {
      dispatch(Actions.setCleverUserInfo(cleverUser))
    }
    return cleverUser;
  } catch (error) {
    return null;
  }
}

export const handleCleverUserRegistration = async (id, token) => {
  return API.post("clever", `/registerUser`, {
    body: {
      id: id,
      token: token
    }
  });
}

export const handleCleverUserClasses = async (id, token) => {
  return API.post("clever", `/classesAndBook`, {
    body: {
      id: id,
      token: token
    }
  });
}
export const handleCleverClassesUsers = async (id, token) => {
  return API.post("clever", `/classStudentsProcess`, {
    body: {
      id: id,
      token: token
    }
  });
}