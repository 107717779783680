//  —————————————new ————————————————————————code of ap
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
//  -- Components ---------------------
import { Col, Row } from "antd";
import BalconCover from "components/BalconCover";
import CoversCard from "components/Covers";
import Loading from "components/Loading";
import _, { chunk, filter, get, map } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState, useReducer, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlertView } from "store/alert/selectors";
import { useBookUnits } from "store/booksUnits/selectors";
import { handleListBookUnits } from "store/booksUnits/thunks";
import { useCurrentOrgPermission, useOrgTimezone } from 'store/organizations/selectors';
import { useDemoStatus } from "store/users/selectors";
import styled from "styled-components";
import { SearchIcon } from "utils/assets/images";
import { AnecdotasThemes, APTHEME, CATEGORIAS, COMUNIDAD_BASE64, getFireBaseUrl, IBTHEME } from "utils/config/reference";
import COLOR from "utils/constants/color";
import { isAfterTime, isMobileDevice, isMobileDevices } from "utils/methods/math";
import useIsMobile from "./chat/hooks";
import useMobileDeviceDetection from "./chat/hooks";
import HeartIcon from "utils/assets/images/heart-icon.svg";
import BackIcon from "utils/assets/images/back-icon-resource.svg";
import ChampionIcon from "utils/assets/images/champion.svg";
import TaskIcon from "utils/assets/images/task-icon.svg";
import DropdownMenu from "components/DropDowns/DropdownMenu";
import { handleCreateFavouriteVideo, handleListFavouriteVideo, handleListMostUsedEbookById } from "store/FavouriteVideo/thunks";
import { useFavouriteVideo } from "store/FavouriteVideo/selectors";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useFirebase } from "./FirebaseContext";
import { useResourceViews } from "utils/methods/firebaseResource";
import { EmptyImagePlaceholder } from "components/EmptyPlaceholderLesson";

//  -- Constants ----------------------

//  -- Features -----------------------

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  min-height: 90vh;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 0px 24px;
  justify-content: center;
`;
const Block = styled.div`
  min-height: 300px;
`;
// export const Wrapper = styled.div`
//     height: 100%;
//     width: 100%;
//     background-image:url(${BgImage})
// `;

const ListItem = styled.li`
  margin: 0px 16px 0px;
  width: calc(25% - 32px);

  // @media only screen and (min-width: 600px) and (max-width: 800px) {
  //   margin: 0px 16px 32px;
  //   width: calc(50% - 32px);
  // }

  // @media only screen and (max-width: 600px) {
  //   flex-direction: column;
  //   margin: 0px 16px 32px;
  //   width: 100%;
  // }
`;
const List = styled.ul`
    width:100%;
  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
`;
//   margin-left: 12%;
// width: 75%;
const ListItemCovers = styled.li`
  margin: 0px 16px 32px;
  width: calc(16% - 25px);

  @media only screen and (min-width: 600px) and (max-width: 800px) {
    margin: 0px 16px 32px;
    width: calc(50% - 32px);
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0px 16px 32px;
    width: 100%;
  }
`;
const ListCovers = styled.ul`
  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
`;
const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
    padding:10px 0px;
    padding-bottom:20px;
`;
const BoxRow = styled.div`
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 20px;

    @media (max-width: 1366px) {
      // display: inherit;
      gap:14px;
      padding: 20px 20px;
    }
`;
const Column = styled.div`
  width:23%;
  cursor: pointer;
  margin: 10px;
  position:relative;
  @media (max-width: 1366px) {
     width:25%;
     margin:0;
  }
`;
const CoverSecion = styled.div`
  width:100%;
  height:100%;
  overflow-y:${p => p.overflowItem ? "scroll" : "hidden"};
`;
const CategoryMenu = styled.div`
      background-color: #0b1c2b;
  border-radius: 30px;
  color: #fff;
  width: 300px;
  height: 42px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  // text-align: center;
  color: #fff;
  // font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #fff;
  color: #fff;
  padding-left: 15px;
  // padding-right: 15px;
  justify-content: space-between;
  // margin: 20px 10px;
  // margin: 20px 20px 20px 10px;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 576px) {
    // margin: 15px 8px;
  }


`;
const MenuIconImg = styled.img`
    margin-left:10px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
`;
const SearchInput = styled.input`
    // width: 100px;
  // width: 80px;
  height: 100%;
  color: #fff;
  border: none;
  background: transparent;
  outline: none;
  // text-align: center;
  // font-weight: 800;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR.white};
  }
  ::focus {
    outline: none;
  }
  @media only screen and (max-width: 360px) {
    width: 92px;
  }
`;
const NewTag = styled.div`
    color:#fff;
    background-color:rgb(211,51,49);
    text-transform: uppercase;
    font-weight:800;
    font-weight: 800;
    width: 60px;
    position: absolute;
    text-align:center;
    top: -22px;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const filterWithTextVideos = (units, txt) => {
  const itemtext = txt.toLowerCase().trimStart().trimEnd()
  return filter(units, k => {
    if (get(k, 'categorias', "").toLowerCase().includes(itemtext) || get(k, 'tags', "").toLowerCase().includes(itemtext)) {
      return true;
    } else if (get(k, 'shortName', "").toLowerCase().includes(itemtext)) {
      return true;
    } else {
      return false;
    }
  })

}
const sortUnits = function (a, b) {
  if (Number(a.id) < Number(b.id)) {
    return 1;
  }
  if (Number(a.id) > Number(b.id)) {
    return -1;
  }
  // a must be equal to b
  return 0;
}

const resourceData = [{
  id: "1",
  key: "2",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/electro.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Correo electrónico",
  chapter_ids: "69"

},
{
  id: "2",
  key: "2",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/compCultral.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Comparación cultural",
  chapter_ids: "70"
},
{
  id: "3",
  key: "3",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/compSimulada.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Conversación simulada",
  chapter_ids: "72"
},
{
  id: "4",
  key: "4",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/artInformose.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Artículos informativos",
  chapter_ids: "73"
},
{
  id: "5",
  key: "5",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/audioPrag.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Audios comprensivos",
  chapter_ids: "75"
},
{
  id: "6",
  key: "6",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/argumentato.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Ensayo argumentativo",
  chapter_ids: "78"
},
{
  id: "7",
  key: "7",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/coverExamTab.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Exámenes de práctica",
  chapter_ids: "8"
},
{
  id: "8",
  key: "8",
  src: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/argumentato.jpg?alt=media&token=32c177f5-6e31-47ff-a515-a36fbb5a4e8b",
  displayName: "Gradebook",
  chapter_ids: "9"
},
]
const Ap = ({
  currentUser,
  userBooksList,
  booksAllList,
}) => {
  const history = useHistory();
  const deviceType = useMobileDeviceDetection();
  const search = queryString.parse(history.location.search);
  const [CHAPTER_ID, setCHAPER_ID] = useState(search.id);
  const dispatch = useDispatch();
  const demoStatus = useDemoStatus()
  const timeZone = useOrgTimezone()
  const [, invokeAlert, revokeALert] = useAlertView()
  const orgPermissions = useCurrentOrgPermission()
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    setCHAPER_ID(search.id);
  }, [search.id])
  if (!CHAPTER_ID) {
    history.goBack()
  }
  const bookUnitList = useBookUnits()
  const UNIT_PAGES = _.get(_.find(bookUnitList, ["bookId", CHAPTER_ID]), "pages", [])
  const UNIT_IMAGE = _.get(_.find(bookUnitList, ["bookId", CHAPTER_ID]), "img", COMUNIDAD_BASE64)
  const [units, setUnits] = useState(UNIT_PAGES)
  const [loading, setLoading] = useState(false);
  const [mostUsedData, setMostUsedData] = useState([]);

  useEffect(() => {
    fetchFavorites()
  }, [CHAPTER_ID])

  const deviceInfo = isMobileDevice();
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    type: 'error'
  });
  const showCopyRight = () => {
    setAlert({
      open: true,
      message: "Content is protected!",
      type: "error"
    })
  }
  useEffect(() => {
    setUnits(sortUnitsByNew(UNIT_PAGES))
  }, [bookUnitList, UNIT_PAGES])
  useEffect(() => {
    if (orgPermissions && typeof orgPermissions['comunidad'] === "boolean") {
      if (!orgPermissions['comunidad']) {
        invokeAlert()
        setTimeout(() => {
          history.goBack();
          revokeALert()
        }, 5000)
      }
    }
  }, [orgPermissions])

  const fetchFavorites = async () => {
    const data = {
      user_id: currentUser.id,
      type: `AP-${CHAPTER_ID}`
    };
    
    try {
      const result = await dispatch(handleListFavouriteVideo(data));
      setIsFetchDataComplete(true);
      setFetchFavorites(result);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };

  const fetchData = async () => {
    const forUserLoaded = localStorage.getItem("forUser");
    if (forUserLoaded == currentUser?.id || forUserLoaded == "nouser") {
      setLoading(false);
    } else {
      if (Array.isArray(bookUnitList) && bookUnitList.length > 0) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    await dispatch(handleListBookUnits())
    setLoading(false);
    localStorage.setItem("forUser", currentUser?.id ?? "nouser");
  }
  const fetchMostUsedVideo = async () => {
    try{
      const arr = [];
      const data = await dispatch(handleListMostUsedEbookById(CHAPTER_ID));
      if(data){
        arr.push(data);
        setMostUsedData(arr);
      }
    }catch(err){
      console.log(err)
    }
  }
  useEffect(() => {
    Promise.all([fetchMostUsedVideo(),fetchData()])
  }, [])
  const showAdoption = () => {
    setAlert({
      open: true,
      message: "Comming soon...",
      type: "success"
    })
  }
  const handleClose = () => {
    setAlert({
      open: false,
      message: "",
      type: "error"
    })
  };

  const onPageClick = useCallback((item, i, index) => {
    const startDate = get(item, 'startDate');
    if (startDate) {
      if (isAfterTime(startDate, moment(), timeZone)) {
        showAdoption()
      } else {
        const url = `/apBook?chapter=${CHAPTER_ID}&id=${item.id}&page=${item.start}&activity=null&quiz=null`
        history.push(url)
      }
    }
    else {
      const firstitem = i == 0 && index === 0
      if (demoStatus && !firstitem) {
        setAlert({
          open: true,
          message: "Restricted access (legend)",
          type: "error"
        })
        return;
      }
      const url = `/apBook?chapter=${CHAPTER_ID}&id=${item.id}&page=${item.start}&activity=null&quiz=null`
      history.push(url)
    }
  }, [CHAPTER_ID])

  const Covers = useCallback(() => {
    return (<CoverSecion overflowItem={units.length > 12} style={{ paddingTop: deviceType ? 10 : 0 }}>
      {chunk(units, 4).map((bookitem, i) => {
        return (<BoxRow key={i + "bookrow"}> {bookitem.map((item, index) => {
          return (
            <Column key={index + i + "bookcol"}>
              {get(item, "new", false) ? <NewTag>New</NewTag> : null}
              <BalconCover handleClick={() => {
                onPageClick(item, i, index)
              }}
                data={item}
              />
            </Column>
          );
        })}
        </BoxRow>)
      })}
    </CoverSecion>)
  }, [units])

  const sortUnitsByNew = (res) => {
    const newUnits = filter(res, ["new", true]).sort(sortUnits)
    const oldUnits = filter(res, k => !k.new).sort(sortUnits)
    return [...newUnits, ...oldUnits]
  }
  const searchUnits = (text) => {
    if (!text || text.length < 2) {
      setUnits(sortUnitsByNew(UNIT_PAGES))
    } else {
      setUnits(sortUnitsByNew(filterWithTextVideos(UNIT_PAGES, text)))
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.body.classList.add("blue-bg-ebook");
    document.body.classList.add("overflow-x-hidden");

    return () => {
      document.body.classList.remove("overflow-x-hidden");
      document.body.classList.remove("blue-bg-ebook");
    };
  }, []);

  const [apThemeFilter, setApThemeFilter] = useState([]);
  const [ibThemeFilter, setIbThemeFilter] = useState([]);
  const [anecotasThemeFilter, setAnecotasThemeFilter] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [isDropdownOpen, setIsDropdownOpen] = useState({ type: "", open: false });
  const [selectedCategoray, setSelectedCatogray] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSelectedFilter, setIsSelectedFilter] = useState("");
  const [loader, setLoader] = useState("");
  const [mostViewed, setMostViewed] = useState([]);
  const allFavorites = useFavouriteVideo();
  const { firestore } = useFirebase()
  const { createResourceViews } = useResourceViews()
  const [fetchFavoritesList, setFetchFavorites] = useState(allFavorites);
  const [isFetchDataComplete, setIsFetchDataComplete] = useState(false);

  useEffect(() => {
    if (isFetchDataComplete) {
      const createFavourite = async () => {
        await dispatch(handleCreateFavouriteVideo(fetchFavoritesList));
      }
      createFavourite();
    }
  }, [fetchFavoritesList])

  const clearAllFilter = () => {
    setSelectedCatogray("");
    setIbThemeFilter([]);
    setApThemeFilter([]);
    setAnecotasThemeFilter([]);
  }

  const handleDropdownClick = useCallback((value) => {
    setIsDropdownOpen(prev => ({
      type: value,
      open: prev.type !== value || !prev.open
    }));
  }, []);

  const dropDownHandler = (action, value) => {
    if (action === "category") {
      setSelectedCatogray(value?.title);
      handleDropdownClick("")
    }
    if (action === "ibThemeFitler") {
      setIbThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    } else if (action === "apThemeFilter") {
      setApThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    }
    else if (action === "anecotasThemeFilter") {
      setAnecotasThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    }

  }



  const getFilteredResult = (result) => {
    const matchThemes = (dataThemes, filterThemes) => {
      if (!dataThemes || dataThemes.length === 0 || filterThemes.length === 0) return false;
      const themes = dataThemes?.toLowerCase().split(',').map(theme => theme.trim());
      return filterThemes?.some(filter =>
        themes.some(theme => theme?.includes(filter?.toLowerCase()))
      );
    };

    let filteredResults = new Set();

    if (searchQuery !== "" && searchQuery?.length > 0) {
      sortUnitsByNew(filterWithTextVideos(result, searchQuery)).forEach(item => filteredResults.add(item));
    }

    if (selectedCategoray !== "" && selectedCategoray?.toLowerCase() !== "all") {
      result.filter(data => data.Categorias?.toLowerCase()?.includes(selectedCategoray?.toLowerCase()))
        .forEach(item => filteredResults.add(item));
    }

    if (anecotasThemeFilter.length > 0) {
      result.filter(data => matchThemes(data?.anécdotas_themes, anecotasThemeFilter))
        .forEach(item => filteredResults.add(item));
    }

    if (ibThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ib_themes, ibThemeFilter))
        .forEach(item => filteredResults.add(item));
    }

    if (apThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ap_themes, apThemeFilter))
        .forEach(item => filteredResults.add(item));
    }

    // If no filters are active, return all results
    if (filteredResults?.size === 0 &&
      anecotasThemeFilter?.length === 0 &&
      ibThemeFilter?.length === 0 &&
      apThemeFilter?.length === 0 &&
      (selectedCategoray.length === 0 || selectedCategoray.toLowerCase() === "all") &&
      searchQuery?.length === 0
    ) {
      return result;
    }

    return Array.from(filteredResults);
  };

  const bookUnits = useMemo(() => {
    if (isSelectedFilter == "category") {
      return sortUnitsByNew(getFilteredResult(fetchFavoritesList?.videos));
    }
    else if (isSelectedFilter === "viewed") {
      const data = UNIT_PAGES.filter(item =>
        mostViewed.some(viewItem => viewItem.id === item.id)
      ).map(item => {
        const viewItem = mostViewed.find(view => view.id === item.id)
        return { ...item, viewed_video: viewItem ? viewItem.viewed_video : 0 };
      })
      return sortUnitsByNew(getFilteredResult(data)).sort((a, b) => b.viewed_video - a.viewed_video);
    }
    else if(isSelectedFilter === "used"){
      return getFilteredResult(mostUsedData);
    }
    return sortUnitsByNew(getFilteredResult(UNIT_PAGES));
  }, [UNIT_PAGES,mostUsedData, apThemeFilter, ibThemeFilter, anecotasThemeFilter, selectedCategoray, searchQuery])

  const handleFavouriteVideo = async (data) => {
    const isBookInFavorites = fetchFavoritesList?.videos?.some(video => video.id === data.id);
    const updatedVideos = isBookInFavorites
      ? fetchFavoritesList.videos.filter(video => video.id !== data.id)
      : [...(fetchFavoritesList?.videos || []), data];
    let updatedData;
    if (fetchFavoritesList?.id) {
      updatedData = {
        ...fetchFavoritesList,
        videos: updatedVideos,
      };
    } else {
      updatedData = {
        user_id: currentUser.id,
        videos: updatedVideos,
        type: `AP-${CHAPTER_ID}`
      };
    }
    setLoader(data?.id);
    setFetchFavorites(updatedData);
    // await dispatch(handleCreateFavouriteVideo(updatedData));
    setLoader('');
  }

  useEffect(() => {
    let unsubscribe = () => { };

    listenToMostViewed(`AP-${CHAPTER_ID}`).then(unsubscribeFunc => {
      unsubscribe = unsubscribeFunc;
    });

    return () => unsubscribe();
  }, []);

  const listenToMostViewed = (type) => {
    return new Promise((resolve) => {
      const collectionRef = collection(firestore, "most_viewed_video");
      const q = query(collectionRef, where("type", "==", type), orderBy("viewed_video", "desc"));

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const viewData = [];
          querySnapshot.forEach((doc) => {
            viewData.push({ id: doc.id, ...doc.data() });
          });
          setMostViewed(viewData);
        },
        (error) => {
          console.error("Error listening to session changes:", error);
        }
      );

      resolve(unsubscribe);
    });
  };

  const createViewedVideo = (id) => {
    const data = mostViewed.find(data => data.id === id);
    const CreateNewView = {
      type: `AP-${CHAPTER_ID}`,
      id: id,
      viewed_video: (data?.viewed_video || 0) + 1
    };
    createResourceViews(CreateNewView)
  };

  return (
    <>
      <Wrapper
        onContextMenu={(e) => {
          e.preventDefault();
          showCopyRight();
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alert.open}
          autoHideDuration={3000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={alert.type}>
            <strong>ALERT:</strong> {alert.message}
          </Alert>
        </Snackbar>
        {loading ? (
          <CenterContainer>
            <Loading />
          </CenterContainer>
        ) : (
          <>
            <CategoryMenu
              className={`search-bar-resource lg-h-36px`}
              style={{ display: isMobile >= 1201 ? "none" : "flex", border: "1px solid #ffffff", marginTop: "24px", paddingRight: 0, width: "160px" }}
            >
              <SearchInput
                style={{ width: "100%" }}
                className="search-input"
                type={"text"}
                placeholder="SEARCH"
                value={searchQuery}
                onChange={(e) => { setSearchQuery(e.target.value) }}
              />
              <div className="dropdown-resource-arrow-container">
                <div
                  style={{
                    transition: "transform 0.4s ease-in-out",
                  }}
                >
                  <img
                    // className="-mt-5px"
                    src={getFireBaseUrl("search-icon-placeholder.svg")}
                    alt=""
                  />
                </div>
              </div>
              {/* <MenuIconImg src={SearchIcon} />{" "} */}
            </CategoryMenu>
            <div style={{ justifyContent: "center", marginTop: "20px", marginBottom: "20px" }} className="flex flex-wrap items-center gap-24px">
              <Row className="all-dropdown-mobile-view gap-24px lg-gap-20px md-wrap nowrap lg-gap-26px">

                <div className="md-w-full-2" style={{ position: "relative" }}>
                  {/* start dropdown */}
                  <DropdownMenu type={"category"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={selectedCategoray} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "category" &&
                    isDropdownOpen?.open} data={CATEGORIAS} />
                </div>

                {/* mob ib dropdown */}
                {isMobile <= 768 && (
                  <div className="md-w-full-2  md-d-block d-none" style={{ position: "relative" }}>
                    <DropdownMenu type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                      closeDropDown={() => handleDropdownClick("")} themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ibtheme" &&
                        isDropdownOpen?.open} data={IBTHEME} />
                  </div>
                )}

                <div className="md-w-full-2" style={{ position: "relative" }}>
                  <DropdownMenu type={"ANéCDOTAS THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={anecotasThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ANéCDOTAS THEMES" &&
                    isDropdownOpen?.open} data={AnecdotasThemes} isResizingWindow={isMobile}
                  />
                </div>
                <div className="md-w-full-2" style={{ position: "relative" }}>
                  <DropdownMenu type={"AP THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={apThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "AP THEMES" &&
                    isDropdownOpen?.open} data={APTHEME} />
                </div>

                {/* laptop ib dropdown */}
                {isMobile >= 768 && (
                  <div className="md-w-full-2 md-d-none d-block" style={{ position: "relative" }}>
                    <DropdownMenu type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                      closeDropDown={() => handleDropdownClick("")} themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ibtheme" &&
                        isDropdownOpen?.open} data={IBTHEME} />
                  </div>
                )}

                <CategoryMenu style={{ width: "160px", position: "relative", paddingLeft: "19px" }} className={`story-search-laptop topStorySearch lg-h-36px`}>

                  <SearchInput
                    style={{ width: "100%" }}
                    type={"text"} placeholder="SEARCH"
                    value={searchQuery}
                    onChange={(e) => { setSearchQuery(e.target.value) }}
                  />
                  <div className="dropdown-resource-arrow-container">
                    <div
                      style={{
                        transition: "transform 0.4s ease-in-out",
                      }}
                    >
                      <img
                        // className="-mt-5px"
                        src={getFireBaseUrl("search-icon-placeholder.svg")}
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <MenuIconImg src={SearchIcon} /> */}
                </CategoryMenu>
                {/* End dropdown */}
              </Row>
              <Row style={{ margin: 0 }} className=" flex gap-28px lg-gap-19px boxes-parent lg-ml-0px nowrap">
                {isSelectedFilter === "category" ? (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      clearAllFilter();
                      setIsSelectedFilter("");
                      // fetchVideoData()
                    }}
                    className="w-197px md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                  >
                    <div className="back-text-resource">Back</div>
                    <img
                      width={33}
                      src={BackIcon}
                      alt="heart"
                      className="w-30px h-25px md-h-20 md-w-18px"
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      clearAllFilter();
                      setIsSelectedFilter("category")
                      // setAllVideos(allFavorites?.videos||[])
                    }}
                    className="md-w-104px  h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                  >
                    <img src={HeartIcon} alt="heart" className="mr-6px md-h-20 md-w-18px w-30px h-25px" />
                    <div className="my-library-font">MIS FAVORITOS</div>
                  </div>
                )}
                {isSelectedFilter === "used" ? (
                <div
                  onClick={(event) => {
                    clearAllFilter();
                    event.stopPropagation();
                    setIsSelectedFilter("");
                  }}
                  className="w-197px  md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                >
                   <div className="back-text-resource">Back</div>
                  <img
                    width={33}
                    src={BackIcon}
                    alt="heart"
                    className="w-30px h-25px md-h-20 md-w-18px"
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    clearAllFilter();
                    setIsSelectedFilter("used");
                  }}
                  className="md-h-20 md-w-18px md-w-104px h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                >
                   <img src={TaskIcon} alt="task"  className="mr-6px md-h-20 md-w-18px w-30px h-25px" />
                  <div className="my-library-font">Más UTILIZADOS</div> 
                </div>
              )}
                {isSelectedFilter === "viewed" ? (
                  <div
                    onClick={(event) => {
                      clearAllFilter();
                      event.stopPropagation();
                      setIsSelectedFilter("");
                    }}
                    className="w-219px md-w-104px cursor-pointer justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                  >
                    <div className="back-text-resource">Back</div>
                    <img
                      width={33}
                      src={BackIcon}
                      alt="heart"
                      className="w-30px h-25px md-h-20 md-w-18px"
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      clearAllFilter();
                      // fetchVideoData()
                      setIsSelectedFilter("viewed");
                    }}
                    className=" h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                  >
                    <img
                      src={ChampionIcon}
                      alt="champion"
                      className="mr-6px md-h-20 md-w-18px w-30px h-25px"
                    />
                    <div className="my-library-font">
                      más VISTOS EN EL PAíS
                    </div>

                  </div>
                )}
              </Row>
            </div>
            <Row>
              {/* <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <CategoryMenu style={{ marginLeft: 30, marginBottom: deviceType ? 10 : 0 }}>
                  <SearchInput type={"text"} placeholder="SEARCH" onChange={(e) => { searchUnits(e.target.value) }}
                    onFocus={e => e.target.placeholder = ''}
                    onBlur={e => e.target.placeholder = 'SEARCH'} />
                  <MenuIconImg src={SearchIcon} />
                </CategoryMenu>
              </Col> */}
              <Col xs={{ span: 24 }} lg={{ span: 14 }}>

              </Col>
            </Row>
            <Row style={{ paddingTop: "1%", paddingBottom: 0, flexDirection: deviceType ? 'column' : 'row' }}>
              <Col xs={{ span: 24 }} lg={{ span: deviceType ? 24 : 10 }}>
                <div>
                  {/* {deviceInfo?.isIOS || deviceInfo.isAndroidMobile || deviceInfo.isAndroidTablet ? */}
                  {deviceType ?
                    <List className="APScrollContainer">
                      {map(resourceData, portada => (
                        <ListItem style={{ marginTop: "15px" }} key={portada.key} className={`${portada.key ? "card-resource-selected resourceShadowPArt" : ""} APScrollContainerCard`}>
                          <CoversCard data={portada} handleClick={() => {
                            if (portada?.chapter_ids === '9') {
                              history.push('/ap/gradbook')
                            }
                            else if (portada?.chapter_ids === '8') {
                              history.push('/ap-exams')
                            }
                            else {
                              setCHAPER_ID(portada?.chapter_ids)
                            }
                          }
                          } 
                          isPodcastImage={true}
                          />
                        </ListItem>
                      ))}
                    </List>
                    :
                    <ImageContainer
                    >
                      <Images src={UNIT_IMAGE} />
                    </ImageContainer>
                  }
                </div>
              </Col>
              <Col style={{ marginTop: deviceType ? '20px' : 0, marginBottom: deviceType ? 60 : 0 }} xs={{ span: 24 }} lg={{ span: deviceType ? 24 : 14 }}>
                <CoverSecion overflowItem={units.length > 12} style={{ paddingTop: deviceType ? 10 : 0 }}>
                  {bookUnits?.length > 0 ? (
                    <>
                      {chunk(bookUnits, 4).map((bookitem, i) => {
                        return (<BoxRow key={i + "bookrow"}> {bookitem.map((item, index) => {
                          return (
                            <Column key={index + i + "bookcol"}>
                              {get(item, "new", false) ? <NewTag>New</NewTag> : null}
                              <BalconCover handleClick={() => {
                                createViewedVideo(item?.id)
                                onPageClick(item, i, index)
                              }}
                                page="balcon"
                                loader={loader}
                                allFavorites={fetchFavoritesList?.videos}
                                handleFavouriteVideo={handleFavouriteVideo}
                                data={item}
                              />
                            </Column>
                          );
                        })}
                        </BoxRow>)
                      })}
                    </>
                  ) : (
                    <EmptyImagePlaceholder />
                  )}

                </CoverSecion>
              </Col>
            </Row>
          </>
        )}
      </Wrapper>

    </>
  );
};

//  -- Prop types ---------------------
Ap.propTypes = {};

//  -- Default props ------------------
Ap.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentClass: state.currentClass,
  currentUser: state.currentUser,
  userBooksList: state.userBooksList,
  tipsBook: state.tipsBook,
  currentClass: state.currentClass,
  classUsers: state.classUsers,
  classActivities: state.classActivities,
  classQuizzes: state.classQuizzes,
  submissions: state.submissions,
  booksAllList: state.booksAllList,
});

export default connect(mapStateToProps, null)(Ap);


