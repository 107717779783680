//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal } from "@material-ui/core";
import {
  Add,
  CloudDownload
} from "@material-ui/icons";
//  -- Components ---------------------
import AssignableNotification from "components/AssignableNotification";
import AssignedNotification from "components/AssignedNotification";
import AssignmentNotification from "components/AssignmentNotification";
import AssignmentPreview from "components/AssignmentPreview";
import Loading from "components/Loading";
import Note from "components/NoteNew";
import Assignment from "features/Assignment";
//  -- Features -----------------------
import NoteForm from "features/forms/Note";
import { filter, find, get, map } from "lodash";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleDeleteClassQuiz } from "store/classQuizzes/thunks";
import { handleBubbleUpdateNote } from 'store/notes/thunks';
//  -- Thunks -------------------------
import { handleCreateBubbleNote } from "store/notes/thunks";
import { handleCreateQuestion } from "store/questions/thunks";
import AnswerForm from 'features/forms/AnswerTOStudent';
import PastAssignment from 'components/PastAssignment';
import EditNoteForm from 'features/forms/EditNote';
import { handleCreateNote } from "store/notes/thunks";
import styled from "styled-components";
import Schoology from 'utils/Schoology';
//  -- Constants ----------------------
import AssignmentForm from "features/forms/AssignmentForm";
import "moment-timezone";
import { handleDeleteCanvasAssignment } from "store/canvas/thunks";
import { handleDeleteGoogleAssignment } from 'store/googleClassRoom/thunks';
import { useOrgTimezone } from 'store/organizations/selectors';
import { useSchoologyAssignment } from "store/schoologyauth/selectors";
import { useServertime } from 'store/servertime/selectors';
import COLOR from "utils/constants/color";
import { getBookData, getPageData, modifyAssignmentForStudent } from "utils/lib/ebookHelper";
import { isBeforTime } from "utils/methods/math";
import { jsPDF } from 'jspdf';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const AssignmentDrawer = styled.div`
  background-color: ${COLOR.white};
  overflow: auto;
  transition: left 300ms ease, width 300ms ease;
  width: ${(p) => (p.active ? "100%" : "0px")};
  z-index: 1;
`;





const FormDescription = styled.span`
  color: ${COLOR.black400};
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24px;
`;

const FormHeader = styled.span`
  color: ${COLOR.black200};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const Section = styled.div`
  padding: 0px 0px 24px;
  margin-bottom: 16px;
  overflow-y: auto;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  &:first-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding: 0px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    // height: 300px;
  }

  // ::-webkit-scrollbar {
  //   display: none;
  // }
`;

const SectionHeader = styled.span`
  align-items: center;
  color: ${COLOR.black200};
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 8px;
  text-transform: uppercase;
  width: 100%;
`;


const Toolbar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 4px;
  width: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;
const NoActivityText = styled.div`
  white-space: pre-line;
  color: ${COLOR.white};
  background-color: ${COLOR.blue};
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  line-height: 17px;
  margin-top:24px;
`;



const DescriptionWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #0b0b0c;
  background-color: rgb(234,228,237);
  width: 100%;
  height: 100%;
  min-height: 310px;
  padding: 10px;
  flex: 1;
`
//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook tooling
//
//  -- Description
//  Ebook student tooling
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const TrackDownloader = ({vttText,id , title}) => {

  const formatVttText = (vttText) => {
    return vttText
      .split('\n\n')
      .filter((block) => block.includes('-->'))
      .map((block) => {
        const [time, ...textLines] = block.split('\n');
        return `\n${textLines.join('\n')}\n`;
      })
      .join('\n');
  };
  const downloadVttAsPdf = () => {
    const fileName = title || id || 'subtitles';
    const doc = new jsPDF();
    const formattedText = formatVttText(vttText);
    doc.text(formattedText, 10, 10);
    doc.save(`${fileName}.pdf`);
  };

  if(!vttText) return null;
  return  <Button variant="contained" color="primary" onClick={downloadVttAsPdf} startIcon={<CloudDownload color="#fff" style={{ fontSize:"12px"}} />}>Download Subtitle</Button>
}
const PodcastTooling = ({
  currentClass,
  currentOrg,
  currentUser,
  data,
  id,
  quiz,
  activity,
  likes,
  notes,
  questions,
  section,
  tipsBook,
  quizzes,
  activities,
  classUsers,
  classQuizzes,
  interactions,
  submissions,
  classActivities,
  setSubmitRef,
  timmer,
  onFinish,
  search,
  booksAllList,
  videoInfo,
  track,
  title,
  listenActions
}) => {
  const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId) || [];
  const history = useHistory();
  const dispatch = useDispatch();
  const servertime = useServertime()
  const timezone = useOrgTimezone()
  const isStudent = currentUser && currentUser.role === 'student'
  // ---new logic-------------//
  const bookData = getBookData(activities, quizzes, questions, notes, likes, interactions, classActivities, classQuizzes, id)
  const pageData = getPageData(bookData, id, quiz, activity)
  const getUri = (q, a) => (`video?id=${id}&quiz=${q}&activity=${a}&link=${search.link}`)
  const availableActivity = modifyAssignmentForStudent(pageData.availableActivity, isStudent, false, currentUser, servertime, timezone , currentClass,getHiddenBooks);
  const assignableActivity = pageData.assignableActivity;
  const activeActivity = pageData.activeActivity;
  const availableQuiz = modifyAssignmentForStudent(pageData.availableQuiz, isStudent, true, currentUser, servertime, timezone , currentClass,getHiddenBooks);
  const assignableQuiz = pageData.assignableQuiz;
  const activeQuiz = pageData.activeQuiz;
  // ---new logic end-------------//
  const podcastNotes = bookData.notes;
  const descriptionVideo = filter(tipsBook, (x) => x.texType === "description");

  const [studentsName, setStudentsName] = React.useState([]);
  const [studentsError, setStudentsError] = React.useState(false);
  const [diffSection, setDiffSection] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assignmentData, setAssignmentData] = useState({});
  const [editNotes, setEditNotes] = useState(null);
  const [createNotesActive, setCreateNotesActive] = useState(false);
  const schoologyAuth = useSelector(state => state.schoologyAuth)
  const client = new Schoology(schoologyAuth)
  const [schoologyAssignment,listSchoologyAssignment] = useSchoologyAssignment()
  const [customAssignmentDrawer, setCustomAssignmentDrawer] = useState(false);
  const [deleteClassAssignedQuizToggle, setdeleteClassAssignedQuizToggle] =
    useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  let infoDescription;
  if (descriptionVideo.length > 0) {
    infoDescription = descriptionVideo[0];
  } else {
    infoDescription = {
      textTip: "",
    };
  }

  let infoClassUSers = filter(classUsers, (x) => x.role === "student");
  let listStudents = [];
  for (let u in infoClassUSers) {
    listStudents.push({
      id: infoClassUSers[u].id,
      description: `${infoClassUSers[u].lastName}  ${infoClassUSers[u].firstName} (${infoClassUSers[u].username})`
    })
  }
  const onPageSwipe = () => {
    setPreview(false);
    setAssignmentData({});
  };
  const handleChangeMultiSelect = (event) => {
    setStudentsError(false);
    setStudentsName(event.target.value);
  };
  const isPastDue = (_quiz) => {
    if (_quiz && _quiz.dueDate && _quiz.lateDate) {
      return isBeforTime(_quiz.lateDate, servertime, timezone)
    } else if (_quiz && _quiz.dueDate) {
      return isBeforTime(_quiz.dueDate, servertime, timezone)
    } else {
      return false
    }
  }
  const createNote = (data, color) => {
    dispatch(
      handleCreateBubbleNote({
        content: data,
        color: color,
        chapter: id,
        page: id,
        userId: currentUser?.id,
        classId: currentClass.id,
        orgId: currentOrg?.id,
      })
    );
    setCreateNotesActive(false);
  };

  const deleteClassQuiz = async (e) => {
    e.preventDefault();
    setDataLoading(true);
    await dispatch(handleDeleteClassQuiz(availableQuiz.quizId, availableQuiz.classId));
    if (availableQuiz && availableQuiz.sAssignmentId && currentClass.sectionId) {
      await client.setToken(schoologyAuth);
      await client.deleteAssignment(currentClass.sectionId, availableQuiz.sAssignmentId)
      listSchoologyAssignment(currentClass.sectionId)
    }
    if(currentClass.googleId&&availableQuiz.googleId){
      await handleDeleteGoogleAssignment(currentUser.googleId,currentClass.googleId,availableQuiz.googleId)
    }
    if(availableQuiz && availableQuiz.canvas_id){
      await handleDeleteCanvasAssignment(currentUser.canvas_id,[{
          canvas_id: availableQuiz.canvas_id,
          canvas_section_id:currentClass.canvas_id,
          canvas_course_id:currentClass.canvas_course_id
      }],currentUser.canvas_auth_id);
  }
    setdeleteClassAssignedQuizToggle(false);
    setDataLoading(false);
  };

  const closeAssignment = () => {
    history.push(getUri(null, null))
  };
  const updateNote = (id, data, color) => {
    let note = editNotes;
    note.content = data;
    note.color = color;
    dispatch(handleBubbleUpdateNote(id, data, color));
    setEditNotes(null);
    setCreateNotesActive(false)
  };

  const assignmentPreivew = (assignment) => {
    try {
      setAssignmentData(assignment.data ? assignment.data : assignment)
      setPreview(true);
    } catch (error) { }
  };
  const getMessageForNotActivity = () => {
    if (currentUser?.role == "student") {
      if (availableActivity.length == 0 && availableQuiz.length == 0) {
        return <NoActivityText>{"Activity not assigned \n by teacher"}</NoActivityText>
      } else {
        return null;
      }
    } else {
      if ((availableActivity.length == 0 && availableQuiz.length == 0) && assignableActivity.length==0&&assignableQuiz.length==0) {
        return <NoActivityText>No activity</NoActivityText>;
      } else return null;
    }
  }

  const hasActiveAssignmentDrawer = () => {
    const hasActiveQz = activeActivity || activeQuiz
    return (typeof quiz == "string" && quiz.length > 2 && quiz !== 'null') || (typeof activity == "string" && activity.length > 2 && activity !== 'null' && hasActiveQz)
  }
  const isTeacher = get(currentUser, "role") === "teacher";
  return (
    <>
      <AssignmentForm open={customAssignmentDrawer} onClose={() => setCustomAssignmentDrawer(false)} bookId={id} pageId={id} pageSrc={''} />
      <Modal
        open={deleteClassAssignedQuizToggle}
        onClose={() => setdeleteClassAssignedQuizToggle(false)}
      >
        <ModalContent>
          Are you sure you want to delete the assignment? All grades will be
          removed as well.
          <ModalRow>
            <Button
              style={{ marginRight: "4px" }}
              onClick={() => setdeleteClassAssignedQuizToggle(false)}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={deleteClassQuiz}
              className={`${dataLoading ? "disabled-button" : ""}`}
            >
              {dataLoading ? <Loading size={20} white /> : "Yes"}
            </Button>
          </ModalRow>
        </ModalContent>
      </Modal>
      {(isTeacher || listenActions?.caption) && (
         <TrackDownloader vttText={track}  id={id} title={title} />
      )}
      {section == 3 && (
        <>
          <Wrapper>
            <div>
              <Section>

                <DescriptionWrapper>
                  {infoDescription.textTip?infoDescription.textTip:get(videoInfo,"description")}
                </DescriptionWrapper>

              </Section>
            </div>
          </Wrapper>
        </>
      )}
      {section == 2 && (
        <>
          <Wrapper>
            {/* diable for testing */}
            {!hasActiveAssignmentDrawer() && isTeacher ? (
              <Section style={{
                height: "auto", overflowY: 'auto', backgroundColor: "transparent",
                position: "relative",
                marginBottom: 0, borderBottomWidth: 0, padding: 5
              }}>
                <SectionHeader>
                  <Button
                    color="primary"
                    onClick={() => setCustomAssignmentDrawer(true)}
                    style={{ fontSize: '12px' }}
                  >
                    <Add style={{ marginRight: '4px', fontSize: '13px' }} />
                    Add Assignment
                  </Button>
                  {/* <Vocabilary pageId={id} bookId={id}  /> */}
                </SectionHeader>
              </Section>
            ) : null}
          
            {!hasActiveAssignmentDrawer() ? (<Section style={{maxHeight: "60%" }} className="addSection container-activity-video">
              {getMessageForNotActivity()}
              {isStudent ? <>
                {availableActivity.length > 0 ? map(availableActivity, (act, i) => {
                  const isPast = isPastDue(act)
                  if (!isPast) return (<>
                    {i == 0 && <SectionHeader>
                      Activities
                    </SectionHeader>}
                    <AssignmentNotification
                      assignment={act}
                      onClick={() => history.push(getUri(null,get(act, "data.id")))}
                      submissions={submissions}
                      userRol={currentUser?.role}
                      currentUser={currentUser}
                      currentClass={currentClass}
                    /></>)
                  return (<>
                    {i == 0 && <SectionHeader>
                      Activities
                    </SectionHeader>}
                    <PastAssignment
                      assignment={act}
                      submissions={submissions}
                    /></>)
                }) : null}
                {availableQuiz.length > 0 ? map(availableQuiz, (act, i) => {
                  const isPast = isPastDue(act)
                  if (!isPast) return (<>
                    {i == 0 && <SectionHeader>
                      Activities
                    </SectionHeader>}
                    <AssignmentNotification
                      assignment={act}
                      onClick={() => {
                        history.push(getUri(get(act, "data.id"), null))
                      }}
                      submissions={submissions}
                      userRol={currentUser?.role}
                      currentUser={currentUser}
                      currentClass={currentClass}
                    /></>)
                  return (<>
                    {i == 0 && <SectionHeader>
                      Activities
                    </SectionHeader>}
                    <PastAssignment
                      assignment={act}
                      submissions={submissions}
                    /></>)
                }) : null}
              </> : null}
              {!isStudent ? <>
                {availableActivity.length > 0 && !preview ? (
                  map(availableActivity, act => (
                    <AssignedNotification
                      assignment={act}
                      listStudents={listStudents}
                      handleChangeMultiSelect={handleChangeMultiSelect}
                      diffSection={diffSection}
                      setDiffSection={setDiffSection}
                      studentsName={studentsName}
                      studentsError={studentsError}
                      setStudentsName={setStudentsName}
                      currentClass={currentClass}
                      onPreviewCick={() => { assignmentPreivew(act) }}
                      booksAllList={booksAllList}
                      viewType="podcast"
                    />
                  ))

                ) : null}
                {assignableActivity.length > 0 && !preview ? (
                  map(assignableActivity, aAct => (
                    <AssignableNotification
                      assignment={aAct}
                      listStudents={listStudents}
                      handleChangeMultiSelect={handleChangeMultiSelect}
                      diffSection={diffSection}
                      setDiffSection={setDiffSection}
                      studentsName={studentsName}
                      studentsError={studentsError}
                      onPreviewCick={() => { assignmentPreivew(aAct) }}
                    />
                  ))) : null}

                {availableQuiz.length && !preview ? (
                  map(availableQuiz, aq => (
                    <AssignedNotification
                      assignment={aq}
                      listStudents={listStudents}
                      handleChangeMultiSelect={handleChangeMultiSelect}
                      diffSection={diffSection}
                      setDiffSection={setDiffSection}
                      studentsName={studentsName}
                      studentsError={studentsError}
                      setStudentsName={setStudentsName}
                      currentClass={currentClass}
                      onPreviewCick={() => { assignmentPreivew(aq) }}
                    />
                  ))) : null}
                {assignableQuiz.length > 0 && !preview ? (
                  map(assignableQuiz, aq => (<AssignableNotification
                    assignment={aq}
                    listStudents={listStudents}
                    handleChangeMultiSelect={handleChangeMultiSelect}
                    diffSection={diffSection}
                    setDiffSection={setDiffSection}
                    studentsName={studentsName}
                    studentsError={studentsError}
                    onPreviewCick={() => { assignmentPreivew(aq) }}
                  />))
                ) : null}
              </> : null}
              {preview ? <AssignmentPreview data={assignmentData} onClose={onPageSwipe} /> : null}
            </Section>) : (<AssignmentDrawer active={hasActiveAssignmentDrawer()}>
              {(activity || quiz)  && activeActivity ? (
                <Assignment
                  chapter={id}
                  currentSubmission={find(submissions, ['assignmentId', activeActivity?.data.id])}
                  data={activeActivity?.data}
                  shuffle={activeActivity?.shuffled}
                  onClose={closeAssignment}
                  page={id}
                  retakes={activeActivity.retakes}
                  userRol={currentUser?.role}
                  setSubmitRef={setSubmitRef}
                  dueDate={activeActivity.dueDate}
                  timmer={timmer}
                  redirectTo={getUri(null, null)}
                  onFinish={onFinish}
                />
              ) : null}
              {(activity || quiz) && activeQuiz ? (
                <Assignment
                  chapter={id}
                  currentSubmission={find(submissions, ['assignmentId', activeQuiz?.data.id])}
                  data={activeQuiz?.data}
                  shuffle={activeQuiz?.shuffled}
                  onClose={closeAssignment}
                  page={id}
                  retakes={activeQuiz.retakes}
                  userRol={currentUser?.role}
                  setSubmitRef={setSubmitRef}
                  dueDate={activeQuiz.dueDate}
                  timmer={timmer}
                  onFinish={onFinish}
                  redirectTo={getUri(null, null)}
                />
              ) : null}
            </AssignmentDrawer>)}
          </Wrapper>
        </>
      )}
    </>
  );
};

//  -- Prop types ---------------------
PodcastTooling.propTypes = {};

//  -- Default props ------------------
PodcastTooling.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentClass: state.currentClass,
  currentOrg: state.currentOrg,
  currentUser: state.currentUser,
  likes: state.likes,
  notes: state.notes,
  questions: state.questions,
  classActivities: state.classActivities,
  classQuizzes: state.classQuizzes,
  interactions: state.interactions,
  quizzes: state.quizzes,
  activities: state.activities,
  submissions: state.submissions,
  booksAllList: state.booksAllList,
});

export default connect(mapStateToProps, null)(PodcastTooling);
