import CanvasKeys from "components/admin/bookmaps/CanvasKeys";
import ClasslinkKeys from "components/admin/bookmaps/ClasslinkKeys";
import queryString from "query-string";
import React from 'react';
import { useHistory } from "react-router-dom";

export default function Bookmaps() {
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const comp = {
        "canvas": <CanvasKeys />,
        "classlink": <ClasslinkKeys />
    }
    if (comp[search.course]) return comp[search.course];
    return (
        <div></div>
    )
}
