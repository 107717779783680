import React from 'react'
import styled from 'styled-components'
import "./styles.css"
const Container = styled.div`
    background-color: #ffffff;
    width: 80%;
    margin:auto;
    padding:10px;
    @media only screen and (min-width: 600px) and (max-width: 800px) {
        width: 90%;
        margin: auto;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
        margin: auto;
    }
`;
export const privacy = (props) => {
        return (<Container>
                <div className='privacy'>
                        <p className="c17 c32 title" id="h.ca3k52kopd0g"><span className="c11 c20 c29">One Way Education, LLC Data Privacy Policy
                        </span></p>
                        <p className="c0"><span className="c6">Last modified: October 10</span><span className="c6 c28">th</span><span className="c1">,
                                2022</span></p>
                        <h3 className="c3" id="h.wui02zu265zk"><span>&nbsp;</span><span className="c11 c19 c25">Introduction</span></h3>
                        <p className="c0"><span className="c6">One Way Education (&quot;</span><span className="c4">Company</span><span
                                className="c6">&quot; or &quot;</span><span className="c4">We</span><span className="c1">&quot;) respects your privacy
                                        and are committed to protecting it through our adoption of and compliance with this policy.</span></p>
                        <p className="c0"><span className="c6">This policy describes the types of information we may collect from you or that you
                                may provide when you visit the One Way Education, LLC website or access our Learning Site&reg;
                                (collectively, our &quot;</span><span className="c4">Website</span><span className="c1">&quot;) and our practices
                                        for collecting, using, maintaining, protecting, and disclosing that information.</span></p>
                        <p className="c0"><span className="c1">This policy applies to information we collect:</span></p>
                        <ul className="c15 lst-kix_7dqofl2v7iwf-0 start">
                                <li className="c2 li-bullet-0"><span className="c1">On the Website.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">In email, text, and other electronic messages between you and the
                                        Website.</span></li>
                                <li className="c2 li-bullet-0"><span className="c24">&nbsp;</span><span className="c1">Through mobile and desktop
                                        applications you download, which provide dedicated non-browser-based interaction between you and the
                                        Website.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">It does not apply to information collected by:</span></p>
                        <ul className="c15 lst-kix_afdlfndiiq3n-0 start">
                                <li className="c2 li-bullet-0"><span className="c1">us offline or through any other means, including on any other
                                        website operated by One Way Education, LLC or any third party (including our affiliates and
                                        subsidiaries); or</span></li>
                                <li className="c2 li-bullet-0"><span className="c24">&nbsp;</span><span className="c1">any third party (including our
                                        affiliates and subsidiaries), including through any application or content (including advertising) that
                                        may link to or be accessible from or on the Website.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">Please read this policy carefully to understand our policies and practices regarding
                                your information and how we will treat it. If you do not agree with our policies and practices, your choice
                                is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy
                                may change from time to time. Your continued use of this Website after we make changes is deemed to be
                                acceptance of those changes, so please check the policy periodically for updates.</span></p>
                        <h4 className="c3" id="h.gijxss29ofn8"><span className="c11 c14">Children Under the Age of 13</span></h4>
                        <p className="c0"><span className="c6">Except for use of our educational services for students which are covered by the One
                                Way Education, LLC Student Information Privacy Notice, our Website is generally not intended for children
                                under 13 years of age. No one under age 13 may provide any personal information to or on the Website. We do
                                not knowingly collect personal information from children under 13. If you are under 13, do not register on
                                the Website, use or provide any information on this Website or on or through any of its features, make any
                                purchases through the Website, use any of the interactive or public comment features of the Website or
                                provide any information about yourself to us, including your name, address, telephone number, email address,
                                or any screen name or user name you may use. If we learn we have collected or received personal information
                                from a child under 13 without verification of parental consent, we will delete that information. If you
                                believe we might have any information from or about a child under 13, please contact us at: Phone: 1 (888)
                                746 - 2388; Email: </span><a className="c4" href='mailto:support@1wayeducation.com'>Support@1wayeducation.com</a><span className="c1">; or Postal
                                        Address: 5600 NW Central Dr, ST 278, Houston, TX 77092</span></p>
                        <h4 className="c3" id="h.ng21jw4nwksq"><span className="c19">&nbsp;</span><span className="c11 c19 c30">A Note on Student Users
                                and Education Records</span></h4>
                        <p className="c0"><span className="c6">One Way Education has additional policies, protocols, and protections in place for
                                the personal information and education records of K-12 student users of One Way&rsquo;s educational
                                services. Our use and disclosure of student information is more limited than described in this general
                                Privacy Policy and certain provisions of this Privacy Policy do not apply to student information. Please see
                                the </span><span><a className="c10"
                                        href="https://www.google.com/url?q=http://learningsite.waysidepublishing.com/student-info-privacy-notice&amp;sa=D&amp;source=editors&amp;ust=1676459690708559&amp;usg=AOvVaw3PK0Sg0thUdzaBt8bJ1UHa">&nbsp;</a></span><span
                                                className="c33"><a className="c10"
                                                        href="https://www.google.com/url?q=http://learningsite.waysidepublishing.com/student-info-privacy-notice&amp;sa=D&amp;source=editors&amp;ust=1676459690709095&amp;usg=AOvVaw3li0ji1a387oA7xf6t3sg8">One
                                                Way Education, LLC Student Information Privacy Notice</a></span><span className="c26"><a className="c10"
                                                        href="https://www.google.com/url?q=http://learningsite.waysidepublishing.com/student-info-privacy-notice&amp;sa=D&amp;source=editors&amp;ust=1676459690709528&amp;usg=AOvVaw3IbTbtvx2hQdJ2QB-gpfHS">&nbsp;</a></span><span
                                                                className="c1">for more specific information on how One Way collects, uses, maintains, protects, and discloses
                                        student information. To the extent that there is a conflict between the terms of this Privacy Policy and the
                                        One Way Education, LLC Student Information Privacy Notice with regard to the personally identifiable
                                        information of a student user, the terms of the Student Information Privacy Notice shall control.</span></p>
                        <h4 className="c3" id="h.26wzcpxkflc3"><span className="c11 c14">Information We Collect About You and How We Collect
                                It</span></h4>
                        <p className="c0"><span className="c1">We collect several types of information from and about users of our Website,
                                including information:</span></p>
                        <ul className="c15 lst-kix_i2arr5l0ywve-0 start">
                                <li className="c8 c13 li-bullet-0"><span className="c24">&nbsp;</span><span className="c6">that identifies, relates to,
                                        describes, references, is capable of being associated with, or could reasonably be linked, directly or
                                        indirectly, with a particular consumer or device or by which you may be personally identified, such as
                                        name, postal address, e-mail address, telephone number, or any other identifier by which you may be
                                        contacted online or offline (&quot;</span><span className="c4">personal information</span><span
                                                className="c1">&quot;);</span></li>
                                <li className="c8 c13 li-bullet-0"><span className="c24">&nbsp;</span><span className="c1">that is about you but does not
                                        identify you individually, such as anonymous demographic data; and/or</span></li>
                                <li className="c8 c13 li-bullet-0"><span className="c24">&nbsp;</span><span className="c1">about your internet connection,
                                        the equipment you use to access our Website, and usage details.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">We collect this information:</span></p>
                        <ul className="c15 lst-kix_xqufkfipu4xo-0 start">
                                <li className="c2 li-bullet-0"><span className="c1">Directly from you when you provide it to us.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Automatically as you navigate through the Website. Information
                                        collected automatically may include usage details, IP addresses, and information collected through
                                        cookies, web beacons, and other tracking technologies.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">From third parties, for example, our business partners.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">&nbsp;</span></p>
                        <h4 className="c3" id="h.16942uv1x3mq"><span className="c11 c14">Information You Provide to Us</span></h4>
                        <p className="c0"><span className="c1">The information we collect on or through our Website may include:</span></p>
                        <ul className="c15 lst-kix_vjradbic4v3z-0 start">
                                <li className="c2 li-bullet-0"><span className="c24">&nbsp;</span><span className="c1">Information that you provide by
                                        filling in forms or placing orders on our Website. This includes information provided at the time of
                                        registering to use our Website, subscribing to our service, posting material, or requesting further
                                        products or services. We may also ask you for information when you interact with us or report a problem
                                        with our Website.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Records and copies of your correspondence (including email
                                        addresses), if you contact us.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Your responses to surveys that we might ask you to complete for
                                        research purposes.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Details of transactions you carry out through our Website and of the
                                        fulfillment of your orders. You may be required to provide financial information before placing an order
                                        through our Website.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Your search queries on the Website.</span></li>
                        </ul>
                        <p className="c0"><span className="c6">You also may provide information to be published or displayed (&quot;</span><span
                                className="c4">posted</span><span className="c6">&quot;) on public areas of the Website, or transmitted to other
                                        users of the Website or third parties (collectively, &quot;</span><span className="c4">User
                                                Contributions</span><span className="c1">&quot;). Your User Contributions are posted on and transmitted to
                                                        others at your own risk. Although we may limit access to certain pages or you may set certain privacy
                                                        settings for such information by logging into your account profile, please be aware that no security
                                                        measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the
                                                        Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee
                                                        that your User Contributions will not be viewed by unauthorized persons.</span></p>
                        <h4 className="c3" id="h.uy1qxcryl8jx"><span className="c11 c14">Information We Collect Through Automatic Data Collection
                                Technologies</span></h4>
                        <p className="c0"><span className="c1">As you navigate through and interact with our Website, we may use automatic data
                                collection technologies to collect certain information about your equipment, browsing actions, and patterns,
                                including:</span></p>
                        <ul className="c15 lst-kix_8gjjzmkq3liq-0 start">
                                <li className="c0 c21 li-bullet-0"><span className="c1">Details of your visits to our Website, including traffic data,
                                        location data, logs, and other communication data and the resources that you access and use on the
                                        Website.</span></li>
                                <li className="c0 c21 li-bullet-0"><span className="c1">Information about your computer and internet connection,
                                        including your IP address, operating system, and browser type.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">We also may use these technologies to collect information about your online
                                activities over time and across third-party websites or other online services (behavioral tracking). Most
                                web browsers have settings where you can adjust tracking settings. Please note that One Way does not change
                                its practices or procedures in response to a &ldquo;Do Not Track&rdquo; signal from a web browser or in the
                                HTTP header from a browser or mobile application.</span></p>
                        <p className="c0"><span className="c1">The information we collect automatically is statistical data and may include personal
                                information, or we may maintain it or associate it with personal information we collect in other ways or
                                received from third parties. It helps us to improve our Website and to deliver a better and more
                                personalized service, including by enabling us to:</span></p>
                        <p className="c0 c7"><span className="c11 c18"></span></p>
                        <ul className="c15 lst-kix_djk675awdb8l-0 start">
                                <li className="c2 li-bullet-0"><span className="c1">Estimate our audience size and usage patterns.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Store information about your preferences, allowing us to customize
                                        our Website according to your individual interests.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Speed up your searches.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">Recognize you when you return to our Website.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">The technologies we use for this automatic data collection may include:</span></p>
                        <ul className="c15 lst-kix_dnldyc39fi57-0 start">
                                <li className="c8 c13 li-bullet-0"><span className="c4">Cookies (or browser cookies)</span><span className="c1">. A cookie
                                        is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by
                                        activating the appropriate setting on your browser. However, if you select this setting you may be
                                        unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it
                                        will refuse cookies, our system will issue cookies when you direct your browser to our Website.</span>
                                </li>
                                <li className="c8 c13 li-bullet-0"><span className="c4">Flash Cookies</span><span className="c6">. Certain features of our
                                        Website may use local stored objects (or Flash cookies) to collect and store information about your
                                        preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same
                                        browser settings as are used for browser cookies. For information about managing your privacy and
                                        security settings for Flash cookies, see </span><span className="c27 c6">Choices About How We Use and
                                                Disclose Your Information</span><span className="c1">.</span></li>
                                <li className="c8 c13 li-bullet-0"><span className="c4">Web Beacons</span><span className="c1">. Pages of our the Website
                                        and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs,
                                        pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited
                                        those pages or opened an email and for other related website statistics (for example, recording the
                                        popularity of certain website content and verifying system and server integrity).</span></li>
                        </ul>
                        <p className="c0 c7"><span className="c11 c14"></span></p>
                        <h4 className="c3" id="h.2k1uvq7t7ctz"><span className="c20">How We Use Your Information</span></h4>
                        <p className="c0"><span className="c1">We use information that we collect about you or that you provide to us, including any
                                personal information:</span></p>
                        <ul className="c15 lst-kix_9cxaqnqw0379-0 start">
                                <li className="c2 li-bullet-0"><span className="c1">To present our Website and its contents to you.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To provide you with information, products, or services that you
                                        request from us.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To fulfill any other purpose for which you provide it.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To provide you with notices about your account, including expiration
                                        and renewal notices.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To carry out our obligations and enforce our rights arising from any
                                        contracts entered into between you and us, including for billing and collection.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To notify you about changes to our Website or any products or
                                        services we offer or provide though it.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To allow you to participate in interactive features on our
                                        Website.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">In any other way we may describe when you provide the
                                        information.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">For any other purpose with your consent.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">We may also use your information to contact you about our own goods and services that
                                may be of interest to you.</span></p>
                        <h4 className="c3" id="h.tfer0pz66mlx"><span className="c11 c14">Disclosure of Your Information</span></h4>
                        <p className="c0"><span className="c1">We may disclose aggregated information about our users, and information that does not
                                identify any individual, without restriction.</span></p>
                        <p className="c0"><span className="c1">We do not disclose information to third-party advertisers for their direct marketing
                                purposes.</span></p>
                        <p className="c0"><span className="c1">We may disclose personal information that we collect or you provide as described in
                                this privacy policy:</span></p>
                        <p className="c0 c7"><span className="c11 c18"></span></p>
                        <ul className="c15 lst-kix_kzkekc6fn0m0-0 start">
                                <li className="c2 li-bullet-0"><span className="c1">To our subsidiaries and affiliates.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To contractors, service providers, and other third parties we use to
                                        support our business and who are bound by contractual obligations to keep personal information
                                        confidential and use it only for the purposes for which we disclose it to them.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To a buyer or other successor in the event of a merger, divestiture,
                                        restructuring, reorganization, dissolution, or other sale or transfer of some or all of One Way assets,
                                        whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
                                        personal information held by One Way about our Website users is among the assets transferred.</span>
                                </li>
                                <li className="c2 li-bullet-0"><span className="c1">To fulfill the purpose for which you provide it.\</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">For any other purpose disclosed by us when you provide the
                                        information.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">With your consent.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">We may also disclose your personal information:</span></p>
                        <ul className="c15 lst-kix_arckf1deumci-0 start">
                                <li className="c2 li-bullet-0"><span className="c1">To comply with any court order, law, or legal process, including to
                                        respond to any government or regulatory request.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">To enforce or apply our Terms of Use and other agreements, including
                                        for billing and collection purposes.</span></li>
                                <li className="c2 li-bullet-0"><span className="c1">If we believe disclosure is necessary or appropriate to protect the
                                        rights, property, or safety of One Way Education, LLC, our customers, users, employees, or
                                        others.</span></li>
                        </ul>
                        <p className="c0"><span className="c1">&nbsp;</span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0"><span className="c11 c4">Choices About How We Use and Disclose Your Information</span></p>
                        <p className="c0"><span className="c1">We strive to provide you with choices regarding the personal information you provide
                                to us. We have created mechanisms to provide you with the following control over your information:</span>
                        </p>
                        <ul className="c15 lst-kix_4gdllw75fcgi-0 start">
                                <li className="c8 c13 li-bullet-0"><span className="c4">Tracking Technologies and Advertising</span><span className="c1">.
                                        You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being
                                        sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on
                                        Adobe&#39;s website. If you disable or refuse cookies, please note that some parts of this site may then
                                        be inaccessible or not function properly.</span></li>
                                <li className="c8 c13 li-bullet-0"><span className="c4">Promotional Offers from the Company</span><span className="c6">. If
                                        you do not wish to have your email address used by the Company to promote our own products or services,
                                        you can opt-out by sending us an email stating your request to </span><a
                                                className="c4" href='mailto:support@1wayeducation.com'>support@1wayeducation.com</a><span className="c1">. If we have sent you a promotional email,
                                                        you may send us a return email asking to be omitted from future email distributions. This opt out does
                                                        not apply to information provided to the Company as a result of a product purchase, warranty
                                                        registration, product service experience or other transactions.</span></li>
                        </ul>
                        <p className="c0"><span className="c11 c4">Accessing and Correcting Your Information</span></p>
                        <p className="c0"><span className="c1">You can review and change your personal information by logging into the Website and
                                visiting your account profile page.</span></p>
                        <p className="c0"><span className="c1">You may also send us an email at support@1wayeducation.com to request access to,
                                correct or delete any personal information that you have provided to us. We cannot delete your personal
                                information except by also deleting your user account. We may not accommodate a request to change
                                information if we believe the change would violate any law or legal requirement or cause the information to
                                be incorrect.</span></p>
                        <p className="c0"><span className="c1">If you delete your User Contributions from the Website, copies of your User
                                Contributions may remain viewable in cached and archived pages or might have been copied or stored by other
                                Website users.</span></p>
                        <p className="c0"><span className="c11 c4">Data Security</span></p>
                        <p className="c0"><span className="c1">We have implemented measures designed to secure your personal information from
                                accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide
                                to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL
                                technology.</span></p>
                        <p className="c0"><span className="c1">The safety and security of your information also depends on you. Where we have given
                                you (or where you have chosen) a password for access to certain parts of our Website, you are responsible
                                for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be
                                careful about giving out information in public areas of the Website like message boards. The information you
                                share in public areas may be viewed by any user of the Website.</span></p>
                        <p className="c0"><span className="c1">Unfortunately, the transmission of information via the internet is not completely
                                secure. Although we do our best to protect your personal information, we cannot guarantee the security of
                                your personal information transmitted to our Website. Any transmission of personal information is at your
                                own risk. We are not responsible for circumvention of any privacy settings or security measures contained on
                                the Website.</span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <hr />
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c23 title" id="h.czunbsn6rzdw"><span className="c9">One Way Education, LLC Student Information Privacy Notice
                        </span></p>
                        <p className="c0"><span className="c1">One Way Education, LLC (&ldquo;One Way Education,&rdquo; &ldquo;us,&rdquo; or
                                &ldquo;we&rdquo;) is committed to protecting the privacy of students using One Way&rsquo;s services. This
                                One Way Education, LLC Student Information Privacy Notice supplements One Way&rsquo;s general Private Policy
                                and applies to K-12 student users of One Way&rsquo;s and Learning Site&reg; online services and related
                                mobile applications (the &ldquo;Services&rdquo;). This Privacy Policy describes how One Way collects and
                                uses the information provided by or on behalf of students when registering for and using our Services so
                                that you can understand how we protect and use your information. If you are under 18, we suggest that you
                                review this information with your parents. By visiting or using our Services, you acknowledge and consent to
                                the information handling practices described in this Student Information Privacy Notice and our general
                                Privacy Policy.</span></p>
                        <p className="c0"><span className="c1">End users of the Services may access the Services in varying ways. Where a student
                                registers with One Way directly, the student would be considered a customer of One Way Education, LLC. In
                                other circumstances, One Way may enter into a contract directly with a school pursuant to which the Services
                                would be used as part of the school&rsquo;s curriculum in which case One Way may receive student data
                                directly from the school or through a school&rsquo;s third-party &ldquo;rostering&rdquo; service provider.
                                In certain other circumstances, a teacher from a student&rsquo;s school may provide registration data for
                                students who will have access to the Services in connection with a particular course or program. When One
                                Way provides Services through a school or teacher at a school (which, for purposes of this Student
                                Information Privacy Notice are referred to collectively as a &ldquo;School&rdquo;) as part of the
                                school&rsquo;s curriculum, we may have access to personal information about K-12 students (&ldquo;Student
                                Users&rdquo;) and certain information provided or received in connection with the Services may be considered
                                a student &ldquo;educational record&rdquo; under applicable law. In addition, personally identifiable
                                student information or data created or provided by a student or parent through use of the Services, created
                                or provided by a School in connection with the Services, or gathered through use of the Services may be
                                protected by certain state Student Information Privacy laws.</span></p>
                        <p className="c0"><span className="c1">This Student Information Privacy Notice describes how One Way handles and protects
                                the personal and educational information of Student Users of our Services. This Student Information Privacy
                                Notice pertains only to One Way&rsquo;s handling and use of personally identifiable information of Student
                                Users. Our general Private Policy &nbsp;describes our practices for collecting, using, maintaining, and
                                disclosing other information of users of our Services. To the extent that there is a conflict between the
                                terms of this Student Information Privacy Notice and our general Privacy Policy with regard to the
                                personally identifiable information of a Student User, the terms of this Student Information Privacy Notice
                                shall control.</span></p>
                        <p className="c0"><span className="c1">&nbsp;</span></p>
                        <p className="c0"><span className="c11 c4">What information do we collect?</span></p>
                        <p className="c0"><span className="c1">If you create an account for use of the One Way Education, LLC Services or
                                communicate with One Way you may provide what is generally called &ldquo;personal information&rdquo; or
                                &ldquo;personal data,&rdquo; such as your full name, email address or other information that can be used to
                                identify you. Information that cannot be used to identify you as an individual is called
                                &ldquo;de-identified&rdquo; or non-personal information.</span></p>
                        <p className="c0"><span className="c1">We collect information, including personal information, in a variety of ways which
                                may vary according to your use of the Services and your account settings. The categories of personal
                                information we collect may include:</span></p>
                        <p className="c0"><span className="c27 c4">Contact and profile information.</span></p>
                        <p className="c0"><span className="c1">When you create an account with One Way or communicate with us we may collect
                                personal information including your name, email address, school name, school district name, teacher name,
                                class name, and course name/ID. In addition, we may ask you for personal information at other times, such as
                                when you contact our technical support team, send us an email, or otherwise communicate with us.</span></p>
                        <p className="c0"><span className="c27 c4">Information from Others.</span></p>
                        <p className="c0"><span className="c1">We make available certain features on our Services that allow other users to provide
                                us information about you. For example, a parent or School may provide information relating to a Student
                                User, such as when a School or parent registers an account for their student or child and provide us with
                                certain information about the student or child, such as username.</span></p>
                        <p className="c0"><span className="c4 c27">Information about your use of our Services.</span></p>
                        <p className="c0"><span className="c1">We may collect usage information about your use of our Services, such as materials
                                accessed, lessons completed, problems you have attempted, and test and quiz results.</span></p>
                        <p className="c0"><span className="c27 c4">Location information.</span></p>
                        <p className="c0"><span className="c1">We may collect and use information about your location or infer your approximate
                                location based on your IP, but we do not collect the precise geolocation of you or your device. </span></p>
                        <p className="c0"><span className="c4 c16">Usage and device information.</span></p>
                        <p className="c0"><span className="c1">&nbsp;</span></p>
                        <p className="c0"><span className="c6">We may use various technologies that automatically record certain technical
                                information from your browser or device when you visit our website, read our emails, use our Services or
                                otherwise engage with us. This information is typically collected through a variety of tracking
                                technologies, including cookies, web beacons, Flash objects, log files, and similar technology
                                (collectively, &ldquo;tracking technologies&rdquo;). These tracking technologies collect information about
                                how you use the Services (e.g., the pages you view, the links you click, and other actions you take on the
                                Services), information about your browser and online usage patterns (e.g., Internet Protocol
                                (&ldquo;IP&rdquo;) address, browser type, browser language, pages viewed, whether you opened an email, links
                                clicked), and information about the device(s) you use to access the Services (e.g., mobile device
                                identifier, mobile carrier, device type, model and manufacturer, mobile device operating system brand and
                                model, and whether you access the Services from multiple devices). We may collect analytics data or use
                                third-party analytics tools such as Google Analytics or Amazon Analytics, to help us measure traffic and
                                usage trends for the Services and to understand more about the demographics of our users. You can learn more
                                about Google&rsquo;s and Amazon&rsquo;s practices at</span><span className="c6"><a className="c10"
                                        href="https://www.google.com/url?q=http://www.google.com/policies/privacy/partners&amp;sa=D&amp;source=editors&amp;ust=1676459690726676&amp;usg=AOvVaw3rP_TF-qsZa_9zIvPSZ6C_">&nbsp;</a></span><span
                                                className="c27 c31"><a className="c10"
                                                        href="https://www.google.com/url?q=http://www.google.com/policies/privacy/partners&amp;sa=D&amp;source=editors&amp;ust=1676459690727211&amp;usg=AOvVaw1fe6IFe1285ZRsmnpqT1Df">http://www.google.com/policies/privacy/partners</a></span><span
                                                                className="c6">,</span><span className="c6"><a className="c10"
                                                                        href="https://www.google.com/url?q=https://aws.amazon.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1676459690727672&amp;usg=AOvVaw0L4Nbu4uFiI1s1VZJXEcGE">&nbsp;</a></span><span
                                                                                className="c27 c31"><a className="c10"
                                                                                        href="https://www.google.com/url?q=https://aws.amazon.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1676459690728014&amp;usg=AOvVaw3M-dt_wcX88-UE3OrZMHR8">https://aws.amazon.com/privacy/</a></span><span
                                                                                                className="c6">, &nbsp;and view its currently available opt-out options at</span><span className="c6"><a className="c10"
                                                                                                        href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1676459690728418&amp;usg=AOvVaw0xyfSngobQLG6ZmQ_Mlnhn">&nbsp;</a></span><span
                                                                                                                className="c27 c31"><a className="c10"
                                                                                                                        href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1676459690728724&amp;usg=AOvVaw1wyvmM0k7YCUAn4S4CWzXP">https://tools.google.com/dlpage/gaoptout</a></span><span
                                                                                                                                className="c6">,</span><span className="c6"><a className="c10"
                                                                                                                                        href="https://www.google.com/url?q=https://docs.aws.amazon.com/pinpoint/latest/userguide/channels-sms-limitations-opt-out.html&amp;sa=D&amp;source=editors&amp;ust=1676459690729160&amp;usg=AOvVaw3T-GVS8c9y1z3mYvL_D2or">&nbsp;</a></span><span
                                                                                                                                                className="c27 c31"><a className="c10"
                                                                                                                                                        href="https://www.google.com/url?q=https://docs.aws.amazon.com/pinpoint/latest/userguide/channels-sms-limitations-opt-out.html&amp;sa=D&amp;source=editors&amp;ust=1676459690729556&amp;usg=AOvVaw3BKYjtJlt_Y5uRHCIXJpB8">https://docs.aws.amazon.com/pinpoint/latest/userguide/channels-sms-limitations-opt-out.html</a></span>
                        </p>
                        <p className="c0"><span className="c4">How we use the information we collect</span></p>
                        <p className="c0"><span className="c1">One Way Education, LLC uses this information in the following ways:</span></p>
                        <p className="c0"><span className="c27 c4">To provide and enhance our Services.</span></p>
                        <p className="c0"><span className="c1">One Way uses the information you provide or that we collect to operate, maintain,
                                enhance, and provide all of the features of our Services.</span></p>
                        <p className="c0"><span className="c27 c4">To personalize your experience.</span></p>
                        <p className="c0"><span className="c1">We use the information to personalize your experience while using the Services,
                                including on various devices you may use to access the Services.</span></p>
                        <p className="c0"><span className="c27 c4">To communicate with you.</span></p>
                        <p className="c0"><span className="c1">We may use your information to communicate with you about your account and respond to
                                inquiries.</span></p>
                        <p className="c0"><span className="c16 c4">To understand, improve, and develop our Services.</span></p>
                        <p className="c0"><span className="c1">&nbsp;</span></p>
                        <p className="c0"><span className="c1">One Way uses all of the information that you provide or that we collect from users to
                                understand and analyze the usage trends, learning behaviors, and preferences of our users, to improve the
                                way the Services works and looks, and to create new features and functionality. We may also use information
                                to maintain, develop, support and improve our Services and other educational products and Services. When we
                                use Student User information for these purposes, we de-identify or aggregate the data so that an individual
                                user may not be personally identified.</span></p>
                        <p className="c0"><span className="c4">Student Users and Student Education Records</span></p>
                        <p className="c0"><span className="c1">When the Services are used as part of the School&rsquo;s educational curriculum, the
                                personal information related to the School&rsquo;s Student Users that is (i) provided to One Way by a
                                student or by a School, or (ii) collected by One Way during the provision of the Services to a School, may
                                include information defined as &ldquo;educational records&rdquo; by the Family Educational Rights and
                                Privacy Act (&ldquo;FERPA&rdquo;). In addition, we may gather other information through use of the Services
                                that describes or identifies a Student User which may come within the definition of &ldquo;student
                                data,&rdquo; &ldquo;covered information,&rdquo; or &ldquo;personally identifiable information&rdquo; that
                                may be protected under certain state Student Information Privacy laws. For purposes of this Student
                                Information Privacy Notice, we call this information, collectively, &ldquo;Student Education
                                Records.&rdquo;</span></p>
                        <p className="c0"><span className="c1">Our collection and use of Student Education Records is governed by our contracts with
                                the Schools, by our Privacy Policy, and by applicable privacy laws. For example, we work with Schools to
                                help protect personal information from the Student&rsquo;s educational record, as required by the Family
                                Educational Rights and Privacy Act (&ldquo;FERPA&rdquo;), and we work to protect the personal information of
                                students under 13 consistent with the Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;).
                                We also observe applicable requirements of state Student Information Privacy laws when using or storing
                                Student Education Records.</span></p>
                        <p className="c0"><span className="c1">In our effort to protect personal and educational information of Student Users and to
                                comply with applicable law, we observe the following standards and protocols regarding Student Education
                                Records:</span></p>
                        <p className="c0"><span className="c1">&nbsp;</span></p>
                        <ul className="c15 lst-kix_n3wkp728pxmr-0 start">
                                <li className="c5 li-bullet-0"><span className="c1">We collect, maintain, use and share Student Education Records only
                                        for an authorized educational purpose in connection with the Services, or as directed by the School, the
                                        Student User and/or the student&rsquo;s parent or legal guardian (a &ldquo;Parent&rdquo;).</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We do not disclose Student Education Records for targeted
                                        advertising purposes and do not engage in targeted advertising of Student Users.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We do not build a personal profile of a Student User other than in
                                        furtherance of an educational purpose in connection with use of the Services.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We maintain a data security program designed to protect Student
                                        Education Records maintained by the Services.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We will take reasonable steps to protect the Student Education
                                        Records from unauthorized access, destruction, use, modification, or disclosure.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We will never sell Student Education Records unless the sale is part
                                        of a corporate transaction, such as a merger, acquisition, bankruptcy, or other sale of assets, in which
                                        case we will require the new owner to continue to honor the terms provided in this Privacy Policy or we
                                        will provide the School with notice and an opportunity to opt-out of the transfer of Student Education
                                        Records by deleting the Student Education Records before the transfer occurs.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We will aggregate or de-identify student data used for purposes of
                                        helping us to improve our services or develop or improve our products or services.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We will contractually require any service providers that we engage
                                        in connection with the Services who may receive Student Education Records acquired through our Services
                                        to use the information only to provide the contracted services, not to further disclose the information,
                                        to maintain reasonable data security measures, and to comply with applicable data privacy laws.</span>
                                </li>
                                <li className="c5 li-bullet-0"><span className="c1">We will clearly and transparently disclose our data policies and
                                        practices to our users.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We will not make any material changes to our Privacy Policy or
                                        contractual agreements that relate to the collection or use of Student Education Records without first
                                        giving notice to the School and providing a choice before the Student Education Records are used in a
                                        materially different manner than was disclosed when the information was collected.</span></li>
                                <li className="c5 li-bullet-0"><span className="c1">We will delete Student User information if the school requests
                                        deletion of data under its control.</span></li>
                        </ul>
                        <p className="c0"><span className="c6">Educational records are within the control of the School that has entered into a
                                contract with One Way Education, LLC. If you have any questions about reviewing, modifying, or deleting the
                                educational records of a Student User accessing the Services through a School, please contact your School
                                directly.</span><span className="c4 c11">&nbsp;</span></p>
                        <p className="c0"><span className="c4">How we share and disclose Student Education Records.</span></p>
                        <p className="c0"><span className="c1">Schools, including teachers, will have access to Student Education Records contained
                                on the Services. Parents may have access to Student Education Records contained on the Services through the
                                School. If you have any questions about reviewing, modifying, or deleting the educational records of a
                                Student User accessing the Services through a School, please contact your School directly.</span></p>
                        <p className="c0"><span className="c1">&nbsp;</span></p>
                        <p className="c0"><span className="c1">We may use or make permitted disclosures of student information and data as required
                                or allowed by applicable law.</span></p>
                        <p className="c0"><span className="c6">&nbsp;</span><span className="c11 c4">How we retain and delete Student Education
                                Records.</span></p>
                        <p className="c0"><span className="c1">&nbsp;Student Education Records stored within the Services are protected through
                                technical, physical, and administrative safeguards instituted by One Way Education, LLC.</span></p>
                        <p className="c0"><span className="c1">&nbsp;We will not knowingly retain Student Education Records beyond the time period
                                required to support an educational purpose, unless authorized by a School, adult student or Parent.</span>
                        </p>
                        <p className="c0"><span className="c1">&nbsp;Student Users who create their own accounts (or their Parents) can delete their
                                accounts and all personal information associated with the account by sending us an email at
                                SUPPORT@1WAYEDUCATION.COM</span></p>
                        <p className="c0"><span className="c1">&nbsp;The School is responsible for managing Student Education Records which the
                                School no longer needs for an educational purpose by submitting a deletion request when such data is no
                                longer needed. Please note that One Way Education cannot comply with a School&rsquo;s request to delete
                                personal information in a user account except for Student User accounts created by a School (i.e., using a
                                School email address and/or an account login provided by a School) pursuant to a contractual agreement
                                between the School and One Way, or unless the Student User (or the user&rsquo;s Parent) requests deletion
                                directly.</span></p>
                        <p className="c0"><span className="c6">&nbsp;</span><span className="c16 c6 c34">Questions about Student Education
                                Records.</span></p>
                        <p className="c0"><span className="c1">&nbsp;If you have questions about specific practices relating to Student Education
                                Records provided to One Way by a School, please direct your questions to your School.</span></p>
                        <p className="c0"><span className="c6">&nbsp;</span><span className="c11 c4">One Way Privacy Policy for Student Users under 13
                                Years of Age (Young Student Users)</span></p>
                        <p className="c0"><span className="c6">&nbsp;The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;)
                                provides certain protections for information provided by individuals under the age of 13. One Way does not
                                knowingly collect any personal information from children under the age of 13 unless and until the School has
                                obtained appropriate parental consent for the student to use the One Way Services. One Way collects and uses
                                data regarding Student Users at the direction of and under the control of a School. One Way relies on each
                                School to provide appropriate notice to parents of the School&#39;s use of third party service providers
                                such as One Way, and for the Schools to provide consent, if necessary, and authorization for One Way to
                                collect data regarding Student Users, as permitted by COPPA. If One Way learns that personal information of
                                a Young Student User has been collected on our Services without parental or School consent, One Way will
                                take appropriate steps to delete the information. If you are a parent or guardian and discover that your
                                child under the age of 13 has a registered account with our Services without your consent, please advise One
                                Way at </span><a className="c26" href='mailto:support@1wayeducation.com'>support@1wayeducation.com</a><span className="c1">&nbsp;and request that we
                                        delete your child&rsquo;s personal information from our systems.</span></p>
                        <p className="c0 c7"><span className="c11 c4"></span></p>
                        <p className="c0 c7"><span className="c11 c4"></span></p>
                        <p className="c0 c7"><span className="c11 c4"></span></p>
                        <p className="c0"><span className="c11 c4">Changes and updates to Privacy Policy</span></p>
                        <p className="c0"><span className="c1">&nbsp;We will provide notice on our website if we make any material changes to our
                                privacy policies that relate to the collection or use of Student Education Records.</span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c17"><span className="c11 c12">&nbsp;</span></p>
                        <p className="c8 c7"><span className="c11 c12"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <p className="c0 c7"><span className="c1"></span></p>
                        <h4 className="c3" id="h.cfht5oh6t1rg"><span className="c11 c14">Contact Information</span></h4>
                        <p className="c0"><span className="c1">To ask questions or comment about this privacy policy and our privacy practices,
                                contact us at:</span></p>
                        <p className="c0"><a href='mailto:support@1wayeducation.com' className="c1">By email: SUPPORT@1WAYEDUCATION.COM</a></p>
                        <p className="c0"><span className="c1">By mail: One Way Education, LLC</span></p>
                        <p className="c0"><span className="c1">5600 NW Central Dr, Suite 278, Houston, TX 77092</span></p>
                        <p className="c0"><span className="c1">By Phone: 1 (888) 746 &ndash; 2388</span></p>
                        <p className="c0"><span className="c1">Monday &ndash; Friday: 8 am to 5 pm (Central Time)</span></p>
                        <p className="c17"><span className="c11 c12">&nbsp;</span></p>
                        <p className="c0"><span className="c11 c12">&nbsp;</span></p>
                        <p className="c8 c7"><span className="c11 c12"></span></p>
                        <p className="c8 c7"><span className="c11 c12"></span></p>
                </div>
        </Container>)
}



export default privacy