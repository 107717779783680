import { Button, Checkbox, Chip, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, MenuItem, Modal, Select, TextField, makeStyles, withStyles, Switch } from '@material-ui/core';
import { Add, Check, Close, Delete, Edit, AccessTime } from '@material-ui/icons';
import { Row as Rows, Typography } from "antd";
import { chunk, filter, find, findIndex, has, map, shuffle, cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCurrentClass } from 'store/classes/selectors';
import { useUsersByClass } from "store/userClasses/selectors";
import { useCheckList, useWheelModal } from 'store/wheel/selectors';
import styled from 'styled-components';
import { TransparentLogoWhite, WheelTransparentImg, WriteEditWhite, WheelClock } from "utils/assets/images";
import COLOR from 'utils/constants/color';
import { generateRandomLightColor } from 'utils/methods/math';
import NewWheelComponent from "./NewWheel";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { EditOutlined, ClockCircleOutlined } from "@ant-design/icons"
import TimerWheel from "components/TimerWheel"
import StopwatchWheel from "components/Stopwatch"
import SystemTimerWheel from "components/Clock"
import DemoPreview from './ViewDemoModal';
import TimerWheelWorker from './TimeWheelWithWorker';
import { wheel_icon } from 'utils/assets/images/icons';
import CheckListModal from './CheckListModal';

const COLORS = [
    COLOR.PinkExam,
    COLOR.OrangeExam,
    COLOR.green,
    COLOR.radish100,
    COLOR.blueish100,
    COLOR.blueishdeep,
    COLOR.pink100,
    COLOR.sky100,
    COLOR.indigo100,
    COLOR.lightseagreen100,
    COLOR.yellow,
    COLOR.blue,
    COLOR.red,
    COLOR.redishpink,
    COLOR.greentrue,
    COLOR.tabActive,
    COLOR.bluelight,
    COLOR.WheelEditColor1]
const STATIONS = [
    { "label": "LEE", "enabled": true, "custom": false },
    { "label": "ESCRIBE", "enabled": true, "custom": false },
    { "label": "ESCUCHA", "enabled": true, "custom": false },
    { "label": "DEBATE", "enabled": true, "custom": false },
    { "label": "INVESTIGA", "enabled": true, "custom": false }
]
const IconsImg = styled.img`
    height: 15px;
    width: 15px;
    object-fit: cover;
    margin-left: 10px;
`;

const BirdLogoImg = styled.div`
    width: 270px;
    height: 180px;
    margin-top: 20px;
    background-image: url(${TransparentLogoWhite});
    background-position: top;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    right: 10px;
`;
const Logo = styled.img`
    height: auto;
    width: 160px;
`;
const LogoTopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LogoBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    font-size: 24px;
    font-size: 20px;
    letter-spacing: 1px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: transparent;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 60%;
    min-height: 50%;
    background-color: rgb(58,58,58);
    position: relative;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;

`;

const Row = styled.div`
    align-items: center;
    justify-content:center;
    display: flex;
    flex-direction: row;
    @media (max-width: 997px) {
         flex-direction: column;
         padding: 0px 30px;
   }
`;
const WheelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: transparent;
    z-index: 11;
    transition: all 1s ease 0s;
    cursor: pointer;
`;
const WheelImg = styled.img`
   height: auto;
   width: 50px;
   margin-bottom: 5px;
`;

const Col = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: row;
    }
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
`;
const CloseBtn = styled.div`
    background-color:${COLOR.PinkExam};
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;
const EditListOption = styled.div`
    text-align: center;
    align-items: center;
    background-image: linear-gradient(90deg, ${COLOR.WheelEditColor1} 0%, ${COLOR.WheelEditColor2} 40%, ${COLOR.WheelEditColor3} 100%); 
    color: #fff;
    display: flex;
    transition: all 300ms ease;
    white-space: nowrap;
    width: 100px;
    height:30px;
    border-radius:10px;
    justify-content: center;
    font-weight: bolder;
    cursor: pointer;
`;
const ResetButton = styled.div`
    text-align: center;
    align-items: center;
    background: #fff;
    color: red;
    display: flex;
    transition: all 300ms ease;
    white-space: nowrap;
    width: 100px;
    height:30px;
    border-radius:10px;
    justify-content: center;
    font-weight: bolder;
    cursor: pointer;
    @media (max-width: 997px) {
        margin-top: 16px;

 }
`;
const WinnerText = styled.div`
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    margin-left: 100px;
    @media (max-width: 997px) {
           margin-left: 0px;
    }
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;
const UserListArea = styled.div`
    background: #cbbbe7;
    min-height: 114px;
    width:200px;
    border-radius: 10px;
    padding: 10px;
    margin: 12px 16px;
`;
const UserItem = styled.div`
    background: #fff;
    padding: 8px 10px;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: move;
`;
const UserArea = styled.div`
    display:flex;
    flex-direction: row;
    margin-top:16px;
    flex-wrap: wrap;
    max-height:55vh;
    max-width: 50vw;
    @media (max-width: 997px) {
        max-width: 90vw;
 }
    overflow: auto !important;
    ::-webkit-scrollbar {
        width: 3px;
        height: 6px;
    }
`;
const UserInput = styled(TextField)`
  margin-top: 8px;

`;
const UserInputControled = styled(TextField)`
  margin-top: 8px;
  
  .MuiInputBase-root {
    height: 25px; // Adjust the height as needed
    padding: 0 14px; // Reduce padding to decrease height

    .MuiInputBase-input {
      font-size: 0.875rem; // Adjust font size to match the smaller input
    }
  }

  .MuiInputLabel-root {
    font-size: 0.875rem; // Adjust label font size to match
  }

  .MuiOutlinedInput-root {
    padding: 0;
    position: relative;
  }
`;
const GroupHeaderText = styled.div`
  background-color:#FD9D1A;
  padding: 8px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width:200px;
  text-align: center;
  color: #fff;
  justify-content: center;
`;
const SectionsGroup = styled.div` 
   display: flex;
   display: flex;
   flex-direction: column;
   align-items: center;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
`;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    },

    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: 120
    },
    formControlStudent: {
        backgroundColor: "#fff",
        width: '400px'
    },
    '@media (max-width: 500px)': {
        formControlStudent: {
            width: '100%'
        }
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));
const MultiMenu = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    variant: "menu",
    getContentAnchorEl: () => null,
};

const InputField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

const StopwatchButton = styled.button`
        background-color: black;
        color: white;
        padding: 10px 20px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:150px;
        color: #fff;
        // float: right;
        border:0px solid;
        // position: absolute;
        right: 10%;
        cursor: pointer;
        border-radius: 10px !important;
        z-index: 9999;
    `;

const StyledClockIcon = styled(ClockCircleOutlined)`
        margin-right: 8px;
        color: white; 
        font-size: 16px;
`;

function MultiSelecter({ options, onSelect, defaultValue, onListChange }) {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const isAllSelected = options.length > 0 && selected.length === options.length;
    const [list, setList] = useState([])
    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options);
            return;
        }
        setSelected(value);
    };
    useEffect(() => {
        setSelected(defaultValue)
        setList([])
    }, [defaultValue, options])
    const onDone = () => {
        onSelect(selected)
    }
    const onChange = (txt, i, key) => {
        let _lst = cloneDeep(list);
        _lst[i][key] = txt;
        setList(_lst);
    }
    const onRemove = (i) => {
        let _lst = cloneDeep(list);
        _lst.splice(i, 1);
        setList(_lst);
    }
    const addNew = () => {
        let _lst = cloneDeep(list);
        _lst.push({ firstName: '', lastName: '', id: list.length });
        setList(_lst);
    }
    const onDoneAddItem = () => {
        let _lst = filter(list, lst => lst.firstName || lst.lastName).map(item => `${item.firstName}  ${item.lastName}`);
        if (_lst.length > 0) {
            onListChange(_lst)

            onSelect([...selected, ..._lst], true)
            setList([])
        } else {

        }
    }
    return (
        <div className='allEditType'>
        <FormControl variant="outlined" className={classes.formControlStudent}>
            <InputLabel id="mutiple-select-label" style={{ backgroundColor: "#fff" }}>Select students</InputLabel>
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {/* { `${isAllSelected||selected.length==0?"All students":"Students("+selected.length+")"}`} */}
                        <Chip label={`${isAllSelected || selected.length == 0 ? "All students" : "Students(" + selected.length + ")"}`} className={classes.chip} />
                    </div>
                )}
                MenuProps={MultiMenu}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                    />
                </MenuItem>
                {map(options, (option) => (
                    <MenuItem key={option} value={option}>
                        <ListItemIcon>
                            <Checkbox checked={Boolean(find(selected, k => k === option))} />
                        </ListItemIcon>
                        <ListItemText primary={`${option}`} />
                    </MenuItem>
                ))}
            </Select>
            {map(list, (lst, i) => (
                <ModalRow style={{ justifyContent: "space-between" }}>
                    <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        style={{ width: 150, color: "white" }}
                        InputLabelProps={{ shrink: true, required: true }}
                        inputProps={{ shrink: true, required: true }}
                        variant="outlined"
                        onChange={e => {
                            const txt = e.target.value;
                            onChange(txt, i, "firstName");
                        }}
                        value={lst.fistName}
                    />
                    <TextField
                        className='editTxtSection'
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        style={{ width: 150, color: "white" }}
                        InputLabelProps={{ shrink: true, required: true }}
                        inputProps={{ shrink: true, required: true }}
                        variant="outlined"
                        onChange={e => {
                            const txt = e.target.value;
                            onChange(txt, i, "lastName");
                        }}
                        value={lst.lastName}
                    />
                    <IconButton color="secondary" onClick={() => {
                        onRemove(i)
                    }} >
                        <Delete />
                    </IconButton>
                </ModalRow>))}
            <ModalRow>
                <Button color="primary" variant="contained" onClick={addNew}>
                    <Add /> Add student
                </Button>
            </ModalRow>
            <ModalRow>
                <Button
                    style={{ marginRight: "4px" }}
                    onClick={() => {
                        if (list.length > 0) {
                            setList([]);
                        } else {
                            onSelect(defaultValue)
                            setSelected(defaultValue)
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={() => {
                    if (!list.length) {
                        onDone()
                    } else {
                        onDoneAddItem()
                    }
                }}>
                    Done
                </Button>
            </ModalRow>
        </FormControl>
        </div>
    );
}

const StudentGroupArea = ({ setNumberOfGroups, numberOfGroups, createStudentGroup, studentGroup, resetGroup }) => {
    const [groups, setGroups] = useState(studentGroup);
    const [dragging, setDragging] = useState(null);
    const [expandedGroup, setExpandedGroup] = useState(null);
    const [newUser, setNewUser] = useState({});
    const [showInput, setShowInput] = useState({});

    useEffect(() => { setGroups(studentGroup) }, [studentGroup])
    const handleDragStart = (e, user, groupIndex) => {
        const dragData = { userId: user.id, groupIndex };
        setDragging(user.id);
        e.dataTransfer.setData('application/json', JSON.stringify(dragData));
        e.target.style.opacity = '0.5';
    };
    const toggleInput = (groupIndex) => {
        setShowInput((prev) => ({ ...prev, [groupIndex]: !prev[groupIndex] }));
    };
    const handleDragEnd = (e) => {
        setDragging(null);
        setExpandedGroup(null);
        e.target.style.opacity = ''; // Reset visual cue
    };

    const handleDragOver = (e, groupIndex) => {
        e.preventDefault();
        setExpandedGroup(groupIndex);
    };

    const handleDragEnter = (e, groupIndex) => {
        e.preventDefault();
        setExpandedGroup(groupIndex); // Same as DragOver, to ensure consistency
    };
    const handleDragLeave = (e, groupIndex) => {
        // Optional: Only collapse if leaving the container, not moving to another child
        if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
            setExpandedGroup(null);
        }
    };
    const handleDrop = (e, groupIndex) => {
        e.preventDefault();
        const dropData = JSON.parse(e.dataTransfer.getData('application/json'));
        if (dropData.groupIndex !== groupIndex) {
            const sourceGroup = groups[dropData.groupIndex].filter(user => user.id !== dropData.userId);
            const droppedUser = groups[dropData.groupIndex].find(user => user.id === dropData.userId);
            const targetGroup = [...groups[groupIndex], droppedUser];
            const newGroups = groups.map((group, index) => {
                if (index === dropData.groupIndex) return sourceGroup;
                if (index === groupIndex) return targetGroup;
                return group;
            });

            setGroups(newGroups);
        }
        setExpandedGroup(null);
        setDragging(null);
    };
    const handleAddNewUserToGroup = (groupIndex) => {
        if (newUser[groupIndex]) {
            const updatedGroups = groups.map((group, index) => {
                if (index === groupIndex) {
                    return [...group, newUser[groupIndex]];
                }
                return group;
            });
            setGroups(updatedGroups);
            // Reset the newUser input for this group
            setNewUser({ ...newUser, [groupIndex]: null });
        }
    };
    const handleNewUserChange = (e, groupIndex) => {
        setNewUser({ ...newUser, [groupIndex]: { ...newUser[groupIndex], [e.target.name]: e.target.value } });
    };

    // Function to handle scrolling when dragging near the edges
    const userAreaRef = useRef(null);
    const handleScroll = (event) => {
        const { clientY } = event;
        const userArea = userAreaRef.current;

        if (!userArea) return;

        const { top, bottom } = userArea.getBoundingClientRect();
        const offset = 10; 

        if (clientY - top < offset) {
            userArea.scrollTop -= offset; 
        } else if (bottom - clientY < offset) {
            userArea.scrollTop += offset; 
        }
    };

    return (<>
        <Rows style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
            <InputField
                id="noOfGroup"
                name="noOfGroup"
                label="Students per group"
                style={{ width: 150, color: "white" }}
                InputLabelProps={{ shrink: true, required: true, style: { color: '#fff' } }}
                inputProps={{ shrink: true, required: true, style: { color: '#fff' } }}
                type="number"
                variant="outlined"
                onChange={e => {
                    if (e.target.value >= 0) {
                        setNumberOfGroups(e.target.value)
                    }
                }}
                value={numberOfGroups}
            />
            <EditListOption style={{ marginLeft: 16 }} onClick={createStudentGroup}>
                Generate
            </EditListOption>
            <ResetButton style={{ marginLeft: 16 }} onClick={resetGroup}>
                Reset
            </ResetButton>
        </Rows>
        <UserArea ref={userAreaRef} onDragOver={handleScroll}>
            {map(groups, (item, groupIndex) => (<UserListArea
                key={groupIndex}
                className={expandedGroup === groupIndex ? 'expandOnDragOver' : ''}
                onDrop={(e) => handleDrop(e, groupIndex)}
                onDragOver={(e) => handleDragOver(e, groupIndex)}
                onDragEnter={(e) => handleDragEnter(e, groupIndex)}
                onDragLeave={(e) => handleDragLeave(e, groupIndex)}
            >
                {map(item, user => (<UserItem
                    key={user.id}
                    className={dragging === user.id ? 'hidden' : ''}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, user, groupIndex)}
                    onDragEnd={handleDragEnd}
                >
                    {`${user.firstName || ''} ${user.lastName || ''}`}
                </UserItem>))}
                {!showInput[groupIndex] && <IconButton color="primary" onClick={() => toggleInput(groupIndex)}>
                    <AddIcon />
                </IconButton>}
                {showInput[groupIndex] && (
                    <div>
                        <UserInput
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            variant="outlined"
                            size="small"
                            style={{ color: "white" }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ shrink: true }}
                            value={newUser[groupIndex]?.firstName || ""}
                            onChange={(e) => handleNewUserChange(e, groupIndex)}
                        />
                        <UserInput
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            variant="outlined"
                            size="small"
                            style={{ color: "white", marginTop: "10px" }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ shrink: true }}
                            value={newUser[groupIndex]?.lastName || ""}
                            onChange={(e) => handleNewUserChange(e, groupIndex)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginTop: "10px", borderRadius: "50%" }}
                            startIcon={<CheckIcon />}
                            onClick={() => {
                                handleAddNewUserToGroup(groupIndex);
                                toggleInput(groupIndex); // Hide inputs after adding
                            }}
                        />
                        {/* <IconButton
                            color="primary"
                            
                            onClick={() => {
                                handleAddNewUserToGroup(groupIndex);
                                toggleInput(groupIndex); // Hide inputs after adding
                            }}
                        >
                            <CheckIcon />
                        </IconButton> */}
                    </div>
                )}
            </UserListArea>))}
        </UserArea>
    </>)

}
const StudentSectionArea = ({ setNumberOfGroups, numberOfGroups, createStudentGroup, studentGroup, resetGroup, stations, onStationChange }) => {
    const [groups, setGroups] = useState(studentGroup);
    const [dragging, setDragging] = useState(null);
    const [expandedGroup, setExpandedGroup] = useState(null);
    const [newUser, setNewUser] = useState({});
    const [showInput, setShowInput] = useState({});
    const [addStations, setAddStations] = useState(false);
    const [stationsList, setStationsList] = useState(stations);
    const [newStation, setNewStation] = useState({ label: '', enabled: true, custom: true });
    const [editableIndex, setEditableIndex] = useState(-1);

    useEffect(() => { setGroups(studentGroup) }, [studentGroup]);
    useEffect(() => { setStationsList(stations) }, [stations]);

    const handleToggleEnabled = (index) => {
        const updatedStations = [...stationsList];
        updatedStations[index].enabled = !updatedStations[index].enabled;
        onStationChange(updatedStations);
    };

    const handleDeleteStation = (index) => {
        const updatedStations = stationsList.filter((_, i) => i !== index);
        onStationChange(updatedStations);
    };


    const handleDragStart = (e, user, groupIndex) => {
        const dragData = { userId: user.id, groupIndex };
        setDragging(user.id);
        e.dataTransfer.setData('application/json', JSON.stringify(dragData));
        e.target.style.opacity = '0.5';
    };

    const handleDragEnd = (e) => {
        setDragging(null);
        setExpandedGroup(null);
        e.target.style.opacity = ''; // Reset visual cue
    };

    const handleDragOver = (e, groupIndex) => {
        e.preventDefault();
        setExpandedGroup(groupIndex);
    };

    const handleDragEnter = (e, groupIndex) => {
        e.preventDefault();
        setExpandedGroup(groupIndex); // Same as DragOver, to ensure consistency
    };

    const handleDragLeave = (e, groupIndex) => {
        if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
            setExpandedGroup(null);
        }
    };

    const handleDrop = (e, groupIndex) => {
        e.preventDefault();
        const dropData = JSON.parse(e.dataTransfer.getData('application/json'));
        if (dropData.groupIndex !== groupIndex) {
            const sourceGroupStudents = groups[dropData.groupIndex].students.filter(user => user.id !== dropData.userId);
            const droppedUser = groups[dropData.groupIndex].students.find(user => user.id === dropData.userId);
            const targetGroupStudents = [...groups[groupIndex].students, droppedUser];

            const newGroups = groups.map((group, index) => {
                if (index === dropData.groupIndex) return { ...group, students: sourceGroupStudents };
                if (index === groupIndex) return { ...group, students: targetGroupStudents };
                return group;
            });

            setGroups(newGroups);
        }
        setExpandedGroup(null);
        setDragging(null);
    };
    const toggleInput = (groupIndex) => {
        setShowInput((prev) => ({ ...prev, [groupIndex]: !prev[groupIndex] }));
    };

    const handleAddNewUserToGroup = (groupIndex) => {
        if (newUser[groupIndex]) {
            const updatedGroups = groups.map((group, index) => {
                if (index === groupIndex) {
                    return { ...group, students: [...group.students, newUser[groupIndex]] };
                }
                return group;
            });

            setGroups(updatedGroups);
            setNewUser({ ...newUser, [groupIndex]: null });
        }
    };

    const handleNewUserChange = (e, groupIndex) => {
        setNewUser({ ...newUser, [groupIndex]: { ...newUser[groupIndex], [e.target.name]: e.target.value } });
    };

    // Function to handle scrolling when dragging near the edges
    const userAreaRef = useRef(null);
    const handleScroll = (event) => {
        const { clientY } = event;
        const userArea = userAreaRef.current;

        if (!userArea) return;

        const { top, bottom } = userArea.getBoundingClientRect();
        const offset = 10; 

        if (clientY - top < offset) {
            userArea.scrollTop -= offset; 
        } else if (bottom - clientY < offset) {
            userArea.scrollTop += offset;  
        }
    };

    return (<>
        <Rows style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
            <InputField
                id="noOfGroup"
                name="noOfGroup"
                label="Students per stations"
                style={{ width: 150, color: "white" }}
                InputLabelProps={{ shrink: true, required: true, style: { color: '#fff', fontSize: 14 } }}
                inputProps={{ shrink: true, required: true, style: { color: '#fff' } }}
                type="number"
                variant="outlined"
                onChange={e => {
                    if (e.target.value >= 0) {
                        setNumberOfGroups(e.target.value)
                    }
                }}
                value={numberOfGroups}
            />
            <EditListOption style={{ marginLeft: 16 }} onClick={createStudentGroup}>
                Generate
            </EditListOption>
            <ResetButton style={{ marginLeft: 16 }} onClick={resetGroup}>
                Reset
            </ResetButton>
        </Rows>
        <UserArea  ref={userAreaRef} onDragOver={handleScroll}>
            {groups.length > 0 ? groups.map((group, groupIndex) => (
                <SectionsGroup>
                    <GroupHeaderText>{group.station || ''}</GroupHeaderText>
                    <UserListArea
                        key={group.station}
                        style={{ borderRadius: 20 }}
                        className={expandedGroup === groupIndex ? 'expandOnDragOver' : ''}
                        onDrop={(e) => handleDrop(e, groupIndex)}
                        onDragOver={(e) => handleDragOver(e, groupIndex)}
                        onDragEnter={(e) => handleDragEnter(e, groupIndex)}
                        onDragLeave={(e) => handleDragLeave(e, groupIndex)}
                    >
                        {group.students.map(user => (
                            <UserItem
                                key={user.id}
                                className={dragging === user.id ? 'hidden' : ''}
                                draggable="true"
                                onDragStart={(e) => handleDragStart(e, user, groupIndex)}
                                onDragEnd={handleDragEnd}
                            >
                                {`${user.firstName || ''} ${user.lastName || ''}`}
                            </UserItem>
                        ))}
                        {!showInput[groupIndex] && <IconButton color="primary" style={{ color: COLOR.blueishdeep }} onClick={() => toggleInput(groupIndex)}>
                            <AddIcon />
                        </IconButton>}
                        {showInput[groupIndex] && (
                            <div>
                                <UserInput
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    variant="outlined"
                                    size="small"
                                    style={{ color: "white" }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ shrink: true }}
                                    value={newUser[groupIndex]?.firstName || ""}
                                    onChange={(e) => handleNewUserChange(e, groupIndex)}
                                />
                                <UserInput
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    variant="outlined"
                                    size="small"
                                    style={{ color: "white", marginTop: "10px" }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ shrink: true }}
                                    value={newUser[groupIndex]?.lastName || ""}
                                    onChange={(e) => handleNewUserChange(e, groupIndex)}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginTop: "10px", borderRadius: "50%" }}
                                    startIcon={<CheckIcon />}
                                    onClick={() => {
                                        handleAddNewUserToGroup(groupIndex);
                                        toggleInput(groupIndex); // Hide inputs after adding
                                    }}
                                />
                                {/* <IconButton
                            color="primary"
                            
                            onClick={() => {
                                handleAddNewUserToGroup(groupIndex);
                                toggleInput(groupIndex); // Hide inputs after adding
                            }}
                        >
                            <CheckIcon />
                        </IconButton> */}
                            </div>
                        )}
                    </UserListArea></SectionsGroup>)) : <>
                <UserListArea >
                    {map(stationsList, (station, i) => (<UserItem
                        key={station.label}
                        style={{ cursor: "default" }}
                    >
                        {editableIndex === i ? <UserInputControled
                            type="text"
                            name="station"
                            placeholder="station"
                            variant="outlined"
                            size="small"
                            autoFocus
                            style={{ color: "white" }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ shrink: true }}
                            value={newStation.label}
                            onChange={(e) => {
                                setNewStation({ ...newStation, label: e.target.value });
                            }}
                        /> : station.label}
                        {editableIndex === i ? <Check style={{ cursor: "pointer", fontSize: 18 }} onClick={() => {
                            setEditableIndex(-1)
                            if (newStation.label.length > 0) {
                                let v = [...stationsList];
                                v[i] = newStation;
                                onStationChange(v);
                                setNewStation({ label: '', enabled: true, custom: true });
                            }
                        }} /> : <div> <EditOutlined style={{ cursor: "pointer" }} onClick={() => {
                            setEditableIndex(i)
                            setNewStation(station)
                            setAddStations(false);
                        }} />
                            {!station.custom && (
                                <Switch
                                    checked={station.enabled}
                                    size="small"
                                    onChange={() => handleToggleEnabled(i)}
                                    name="enabled"
                                    color="primary"
                                />
                            )}
                            {station.custom && (
                                <IconButton size="small" color="secondary" style={{ marginLeft: 10, fontSize: 20 }} onClick={() => handleDeleteStation(i)}>
                                    <Delete style={{ fontSize: 20 }} />
                                </IconButton>
                            )}
                        </div>}
                    </UserItem>))}
                    {!addStations ? <IconButton color="primary" onClick={() => setAddStations(true)}>
                        <AddIcon />
                    </IconButton> : (
                        <div>
                            <UserInput
                                type="text"
                                name="station"
                                placeholder="station"
                                variant="outlined"
                                size="small"
                                style={{ color: "white" }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ shrink: true }}
                                value={newStation.label}
                                onChange={(e) => {
                                    setNewStation({ ...newStation, label: e.target.value });
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginTop: "10px", borderRadius: "50%" }}
                                startIcon={<CheckIcon />}
                                onClick={() => {
                                    if (newStation.label.length > 0) {
                                        onStationChange([...stationsList, newStation])
                                        setNewStation({ label: '', enabled: true, custom: true });
                                        setAddStations(false)
                                    } else {
                                        setAddStations(false)
                                    }
                                }}
                            />
                        </div>
                    )}
                </UserListArea>

            </>}
        </UserArea>
    </>)

}
export default function Wheel({ wheelsModal, setWheelsModal }) {
    const usersByClass = useUsersByClass()
    const currentClass = useCurrentClass()
    const [segments, setSegments] = useState([])
    const [segColors, setSegColors] = useState([])
    const [usersList, setUsers] = useState([])
    const [result, setResult] = useState('')
    const [wheelModal, invokeWheelModal, revokeWheelModal] = useWheelModal()
    const [studentSelectModal, setStudentSelectModal] = useState(false)
    const [showGroupSelectModal, setShowGroupSelectModal] = useState(null)
    const [studentGroup, setStudentGroup] = useState([])
    const [stationStudentGroup, setStationStudentGroup] = useState([])
    const [numberOfGroups, setNumberOfGroups] = useState(0)
    const [numberOfStudentPerStation, setNumberOfStudentsPerStation] = useState(0)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
    const [stations, setStations] = useState(STATIONS)
    const [isStopWatch, setIsStopWatch] = useState(false);
    const [isMilitaryClock, setMilitaryClock] = useState(false);
    const wheelRef = useRef()
    const [,invokeCheckList] = useCheckList()
    useEffect(() => {
        if (usersByClass && has(currentClass, "id")) {
            const usr = map(usersByClass[currentClass.id], k => `${k.firstName || ''} ${k.lastName || ''}`)
            setUsers(usr)
            setStations(STATIONS)
        }
    }, [usersByClass, currentClass, wheelModal])
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, [])
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        if (!wheelModal.view) {
            setSegments([])
            setSegColors([])
            setStudentGroup([])
            setStationStudentGroup([])
            setNumberOfGroups(0)
            setNumberOfStudentsPerStation(0)
            setShowGroupSelectModal(null)
            setResult('')
        } else {
            if (has(currentClass, "id")) {
                setSegments(shuffle(shuffle(usersList)))
                const segColors = map(usersList, (k, i) => `${COLORS[i] ? COLORS[i] : generateRandomLightColor()}`)
                setSegColors(segColors)
                setResult('')
            }
            else {
                setSegments([])
                setSegColors([])
                setResult('')
            }
        }
    }, [wheelModal, usersList])
    const onFinished = (winner) => {
        if (segments.length > 2) {
            const fIndex = findIndex(segments, s => s !== winner)
            const _sg = filter(segments, s => s !== winner)
            if (fIndex > -1) {
                const _sgColor = [...segColors]
                _sgColor.splice(fIndex, 1)
                setSegColors(_sgColor)
            }
            setTimeout(() => {
                setSegments(_sg)
            }, 1000)
        }
        setResult(winner)
    }
    const onClose = () => {
        revokeWheelModal();
        setWheelsModal(false);
    }
    const createStudentGroupStation = () => {
        if (usersByClass) {
            const studentOfCurrentClass = usersByClass[currentClass.id];
            if (Array.isArray(studentOfCurrentClass)) {
                const shuffledStudents = shuffle(studentOfCurrentClass);
                let filteredStations = stations.filter(k => k.enabled && k.label).map(k => k.label)
                const studentGroupsWithStations = filteredStations.map((station, index) => {
                    let start = index * numberOfStudentPerStation;
                    let end = index === filteredStations.length - 1 ? undefined : start + parseInt(numberOfStudentPerStation);
                    let groupStudents = shuffledStudents.slice(start, end);

                    return {
                        station: station,
                        students: groupStudents
                    };
                });
                setStationStudentGroup(studentGroupsWithStations)
            } else {
                setStationStudentGroup([])
            }
        } else {
            setStationStudentGroup([])
        }
    };

    const createStudentGroup = () => {
        if (usersByClass) {
            const studentOfCurrentClass = usersByClass[currentClass.id];
            if (Array.isArray(studentOfCurrentClass) && numberOfGroups > 0) {
                const studentOfCurrent = chunk(shuffle(studentOfCurrentClass), numberOfGroups)
                setStudentGroup(studentOfCurrent)
            } else {
                setStudentGroup([])
            }
        } else {
            setStudentGroup([])
        }
    }

    const onCheckListView = () => {
        onClose()
    }

    const getSegments = useCallback(() => {
        return map(segments, (k, i) => ({ color: segColors[i], label: k }))
    }, [segments, segColors])
    return (<>
        <WheelWrapper onClick={invokeWheelModal} className='wheelSpinImg'>
            <WheelImg src={wheel_icon} />
        </WheelWrapper>
        <Modal
            open={studentSelectModal}
        >
            <ModalContent className="wheelEditSection">
                <MultiSelecter
                    options={usersList}
                    onListChange={(items) => {
                        setUsers([...usersList, ...items]);
                    }}
                    onSelect={(value) => {
                        setSegments(value)
                        setStudentSelectModal(false)
                        if (!value.includes(result)) {
                            setResult('')
                        }
                    }}
                    defaultValue={segments}
                />
            </ModalContent>

        </Modal>
        <Modal
            open={wheelModal.view || wheelsModal}
            onClose={onClose}
            style={{ width: '100%', backgroundColor: "transparent" }}
            className='spinWheelMdl'
        >
            <Wrapper className='spinWheelWrap'>
                <Container className='spinWheelCntnr'>
                    <LogoTopRow>
                        <Logo src={TransparentLogoWhite} />   <DemoPreview video_id={"1way-wheel-demo"} wrapperStyle={{ justifyContent: "flex-end", width: "50%", paddingRight: "50px" }} />
                    </LogoTopRow>
                    <CloseBtn onClick={onClose}>
                        <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                    </CloseBtn>
                    {showGroupSelectModal === 'student_group' ? <>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", minHeight: '40vh' }}>
                            <StudentGroupArea
                                setNumberOfGroups={setNumberOfGroups}
                                createStudentGroup={createStudentGroup}
                                studentGroup={studentGroup}
                                resetGroup={() => {
                                    setNumberOfGroups(0)
                                    setStudentGroup([])
                                }}
                                numberOfGroups={numberOfGroups} />
                        </div>
                        <Row style={{ justifyContent: "space-between", marginTop: 24 }}>
                            <Typography.Paragraph style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <EditListOption onClick={() => setShowGroupSelectModal(null)}>
                                    View Wheel
                                </EditListOption>
                                <Typography.Text strong style={{ color: "#fff", marginLeft: 8 }} >Simply drag and drop student names to effortlessly reassign them to other groups.</Typography.Text>
                            </Typography.Paragraph>
                            <LogoBottomRow className='wayWheelLogo'>
                                1WAYWHEEL  <Logo style={{ width: 80, marginLeft: "-12px" }} src={WheelTransparentImg} />
                            </LogoBottomRow>
                        </Row>
                    </> : null}
                    {showGroupSelectModal === 'station' ? <>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", minHeight: '40vh' }}>
                            <StudentSectionArea
                                setNumberOfGroups={setNumberOfStudentsPerStation}
                                createStudentGroup={createStudentGroupStation}
                                studentGroup={stationStudentGroup}
                                resetGroup={() => {
                                    setNumberOfStudentsPerStation(0)
                                    setStationStudentGroup([])
                                }}
                                stations={stations}
                                onStationChange={setStations}
                                numberOfGroups={numberOfStudentPerStation} />
                        </div>
                        <Row style={{ justifyContent: "space-between", marginTop: 24 }}>
                            <Typography.Paragraph style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <EditListOption onClick={() => setShowGroupSelectModal(null)}>
                                    View Wheel
                                </EditListOption>
                                <Typography.Text strong style={{ color: "#fff", marginLeft: 8 }} >Simply drag and drop student names to effortlessly reassign them to other groups.</Typography.Text>
                            </Typography.Paragraph>
                            <LogoBottomRow className='wayWheelLogo'>
                                1WAYWHEEL  <Logo style={{ width: 80, marginLeft: "-12px" }} src={WheelTransparentImg} />
                            </LogoBottomRow>
                        </Row>
                    </> : null}
                    {showGroupSelectModal === 'timer' ? <>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", minHeight: '40vh' }}>
                            {/* <TimerWheel/> */}
                            <div className='timerAndStopwatch'>
                                <StopwatchButton onClick={() => setIsStopWatch(!isStopWatch)}>
                                    <StyledClockIcon /> {isStopWatch ? "Timer" : "Stopwatch"}
                                </StopwatchButton>
                            </div>
                            {/* {isStopWatch ? <StopwatchWheel /> : <TimerWheel />} */}
                            {isStopWatch ? <StopwatchWheel /> : <TimerWheelWorker />}
                        </div>
                        <Row style={{ justifyContent: "space-between", marginTop: 24 }}>
                            <Typography.Paragraph style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <EditListOption onClick={() => setShowGroupSelectModal(null)}>
                                    View Wheel
                                </EditListOption>
                            </Typography.Paragraph>
                            <LogoBottomRow>
                                1WAYTIMER  <Logo style={{ width: 50 }} src={WheelClock} />
                            </LogoBottomRow>
                        </Row>
                    </> : null}
                    {showGroupSelectModal === 'clock' ? <>
                        <div className='clickTimer' style={{ paddingLeft: "20px", paddingRight: "20px", minHeight: '40vh' }}>
                        <div className='timerAndStopwatch'>
                            <StopwatchButton style={{ width: "auto" }} onClick={() => setMilitaryClock(!isMilitaryClock)}>
                                <StyledClockIcon /> {!isMilitaryClock ? "Change to Military Time" : "Change to Standard Time"}
                            </StopwatchButton>
                        </div>    
                            <SystemTimerWheel isMilitaryClock={isMilitaryClock} />
                        </div>
                        <Row style={{ justifyContent: "space-between", marginTop: 24 }}>
                            <Typography.Paragraph style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <EditListOption onClick={() => setShowGroupSelectModal(null)}>
                                    View Wheel
                                </EditListOption>
                            </Typography.Paragraph>
                            <LogoBottomRow>
                                1WAYTIMER  <Logo style={{ width: 50 }} src={WheelClock} />
                            </LogoBottomRow>
                        </Row>
                    </> : null}
                    {showGroupSelectModal === 'check_list' ? <>
                        <div className='clickTimer' style={{ paddingLeft: "20px", paddingRight: "20px", minHeight: '40vh' }}>
                            <CheckListModal currentClass={currentClass} onClose={onCheckListView} />
                        </div>
                        <Row style={{ justifyContent: "space-between", marginTop: 24 }}>
                            <Typography.Paragraph style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <EditListOption onClick={() => setShowGroupSelectModal(null)}>
                                    View Wheel
                                </EditListOption>
                            </Typography.Paragraph>
                            <LogoBottomRow>
                                1WAYCHECKLIST
                            </LogoBottomRow>
                        </Row>
                    </> : null}
                    {!showGroupSelectModal && <>
                        <Row class="spinCircle" style={{ paddingLeft: "20px", paddingRight: "20px", }}>
                            <NewWheelComponent
                                segments={getSegments()}
                                onFinished={onFinished}
                            />
                        </Row>
                        <Row className='whelRowName'>
                            {Boolean(result) ? <WinnerText>
                                {result}
                            </WinnerText> : null}
                        </Row>
                        <Row className='whelRowBtns'>
                            <Col className='whelColBtns'>
                                {/* <EditListOption onClick={() => setShowGroupSelectModal('clock')}>
                                    Clock
                                </EditListOption> */}
                                <EditListOption onClick={() => setShowGroupSelectModal('station')}>
                                    {stationStudentGroup.length > 0 ? "View Station" : "Create Station"}
                                </EditListOption>
                                <EditListOption onClick={() => setShowGroupSelectModal('student_group')}>
                                    {studentGroup.length > 0 ? "View Group" : "Create Group"}
                                </EditListOption>
                                {/* <EditListOption onClick={() => setShowGroupSelectModal('timer')}>
                                    Timer <AccessTime style={{ fontSize: 16 }} />
                                </EditListOption> */}
                                <EditListOption onClick={() => setShowGroupSelectModal('check_list')}>
                                    CheckList
                                </EditListOption>
                                <EditListOption onClick={() => setStudentSelectModal(true)}>
                                    Edit  <IconsImg src={WriteEditWhite} />
                                </EditListOption>
                            </Col>
                        </Row>
                        <Row className='whelRowLogo'>
                            <LogoBottomRow className='wayWheelLogo'>
                                1WAYWHEEL  <Logo style={{ width: 80, marginLeft: "-12px" }} src={WheelTransparentImg} />
                            </LogoBottomRow>
                        </Row>
                    </>}

                </Container>
            </Wrapper>
        </Modal>
    </>)
}
