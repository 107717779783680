//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createExams = () => ({ type: ActionTypes.exams.createExams });
export const createExamsFail = (returnedPayload) => ({ type: ActionTypes.exams.createExamsFail, payload: returnedPayload });
export const createExamsSuccess = (returnedPayload) => ({ type: ActionTypes.exams.createExamsSuccess, payload: returnedPayload });

export const updateExams = () => ({ type: ActionTypes.exams.updateExams });
export const updateExamsFail = (returnedPayload) => ({ type: ActionTypes.exams.updateExamsFail, payload: returnedPayload });
export const updateExamsSuccess = (returnedPayload) => ({ type: ActionTypes.exams.updateExamsSuccess, payload: returnedPayload });

export const deleteExams = () => ({ type: ActionTypes.exams.deleteExams });
export const deleteExamsFail = (returnedPayload) => ({ type: ActionTypes.exams.deleteExamsFail, payload: returnedPayload });
export const deleteExamsSuccess = (returnedPayload) => ({ type: ActionTypes.exams.deleteExamsSuccess, payload: returnedPayload });


//  -- List exams --------------
export const listExamss = () => ({ type: ActionTypes.exams.listExams });
export const listExamssFail = (returnedPayload) => ({ type: ActionTypes.exams.listExamsFail, payload: returnedPayload });
export const listExamssSuccess = (returnedPayload) => ({ type: ActionTypes.exams.listExamsSuccess, payload: returnedPayload });

