//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const invokeMainBook = (open, pages) => ({ type: ActionTypes.alert.invokeMainBook, payload: { open, pages } });
export const revokeMainbook = () => ({ type: ActionTypes.alert.removeMainBook, payload: { open: false, pages: [] } });

export const invokeEbookGalleryView = (open, pages) => ({ type: ActionTypes.alert.invokeEbookGalleryViewer, payload: { open, pages } });
export const revokeEbookGalleryView = () => ({ type: ActionTypes.alert.removeEbookGalleryView, payload: { open: false, pages: [] } });

//  -- List All books ----------------

//  -- For Sentence Starter ----------

export const invokeSentence = (open, pages) => ({ type: ActionTypes.alert.invokeSentence, payload: { open, pages } });
export const revokeSentence = () => ({ type: ActionTypes.alert.removeSentence, payload: { open: false, pages: [] } });