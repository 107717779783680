//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Extension } from "@material-ui/icons";
// -- Components ----------------------
import MessageRenderer from "components/MessageRenderer";
// -- Features ------------------------
import EditNoteForm from "features/forms/EditNote";
import moment from "moment";
import { lighten } from "polished";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleBubbleUpdateNote } from "store/notes/thunks";
import styled from "styled-components";
import { NoteIconimg } from "utils/assets/images";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Caption = styled.span`
  // color: ${COLOR.black400};
  // font-size: 14px;
  // font-weight: 400;
  color: #000;
  font-family: Arial;
  font-size: 17.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Row = styled.div`
  align-items: ${(p) => p.align || "center"};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`;

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`;

const TextLarge = styled(Text)`
  color: rgb(0 0 0);
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

const Wrapper = styled.button`
  background-color: ${(p) =>
    p.color ? lighten(0.4, `${p.color}`) : "rgb(234,227,236)"};
  border: 2px dotted rgb(0 0 0);
  border-radius: 4px;
  color: rgb(0 0 0);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;

  &:last-child {
    margin-bottom: 16px;
  }
`;
const NoNotesWrapper = styled.div`
  background-color: rgb(234, 227, 236);
  border: 2px dotted rgb(0 0 0);
  border-radius: 4px;
  color: rgb(0 0 0);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;
  height: 100%;
  &:last-child {
    margin-bottom: 16px;
  }
`;

const AssignmentDrawer = styled.div`
  background-color: #fcfcfc;
  height: 100%;
  overflow: scroll;
  position: absolute;
  left: ${(p) => (p.active ? "0px" : "calc(100% + 32px)")};
  top: 32px;
  transition: left 300ms ease, width 300ms ease;
  width: ${(p) => (p.active ? "100%" : "0px")};
  z-index: 1;
`;

const CreationDrawer = styled.div`
  background-color: #fcfcfc;
  overflow: scroll;
  position: absolute;
  top: 32px;
  transition: left 300ms ease, width 300ms ease;
  z-index: 1;
  height: 100%;
  width: 100%;
  left: -1px;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const FormHeader = styled.span`
  // color: ${COLOR.black200};
  display: inline-block;
  // font-size: 16px;
  // font-weight: 600;
  margin-bottom: 4px;
  // color: #000;
  font-family: Arial;
  font-size: 17.5px;
  font-weight: 700;
`;

const FormDescription = styled.span`
  color: ${COLOR.black400};
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24px;
`;

const margenTop = styled(CreationDrawer)`
  margin-top: -24px;
`;
const Noteicon = styled.img`
  height: 35px;
  width: 25px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  object-fit: fill;
  margin-right: 5px;
`;
const NoteSec = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
  margin-top: 10px;
`;
const NotesText = styled.span`
  color: #545658;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 5px;
  margin-top: 3px;
`;
const CaptionSec = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Note
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const NoNotesSection = () => {
  return (
    <NoNotesWrapper>
      <Row
        align="flex-start"
        style={{
          alignItems: "justify",
          justifyContent: "flex-start",
          marginBottom: 0,
        }}
      >
        <Noteicon src={NoteIconimg} />
        <CaptionSec>
          <Caption style={{ color: "rgb(0 0 0)", lineHeight: "14px" }}>
            {"Notes"}
          </Caption>
          <Caption style={{ color: "rgb(0 0 0)" }}>
            {moment().format("MM/DD/YYYY")}
          </Caption>
        </CaptionSec>
      </Row>
      <NoteSec>
        <NotesText>Notes:</NotesText>
      </NoteSec>
    </NoNotesWrapper>
  );
};
const Note = ({ note, view, hasNoNotes, onEditNotes }) => {
  let addMargen;
  if (view == "notas") {
    addMargen = true;
  } else {
    addMargen = false;
  }
  var [creationType, setCreationType] = useState(null);
  const dispatch = useDispatch();

  const updateNote = (id, data, color) => {
    note.content = data;
    note.color = color;
    dispatch(handleBubbleUpdateNote(id, data, color));
    setCreationType(null);
  };
  if (hasNoNotes) {
    return <NoNotesSection />;
  }
  return (
    <Wrapper
      style={{ paddingLeft: "9px", paddingTop: "11px", paddingRight: "21px" }}
      color={note?.color?.color}
    >
      {creationType != "note" && (
        <Row
          align="flex-start"
          style={{
            alignItems: "justify",
            justifyContent: "flex-start",
            marginBottom: 0,
          }}
        >
          <Noteicon src={NoteIconimg} />
          <CaptionSec>
            <Caption style={{ color: "rgb(0 0 0)", lineHeight: "14px" }}>
              {"Mis notas"}
            </Caption>
            <Caption style={{ color: "rgb(0 0 0)", marginTop: "2.9px" }}>
              {moment(parseInt(note?.createdAt || Date.now())).format(
                "MM/DD/YYYY"
              )}
            </Caption>
          </CaptionSec>
        </Row>
      )}
      {creationType != "note" && (
        <NoteSec>
          <NotesText>Notes:</NotesText>{" "}
          <MessageRenderer
            style={{ color: "rgb(0 0 0)" }}
            data={note?.content}
          />
        </NoteSec>
      )}
      {creationType != "note" && (
        <Row>
          <Column></Column>
          <TextLarge
            onClick={() => {
              if (onEditNotes) {
                onEditNotes(note);
              } else {
                setCreationType("note");
              }
            }}
          >
            Edit Note
          </TextLarge>
        </Row>
      )}
      {creationType === "note" ? (
        <CreationDrawer
          active={creationType}
          className={addMargen ? "margin-top-notas" : ""}
          style={{ paddingLeft: 5, paddingRight: 5 }}
        >
          <FormHeader>Edit a Note</FormHeader>
          <FormDescription>
            Students will have their notes for the entire school year, motivate
            them to write as many notes as they need. This is a great tool to
            keep their work organized. You can write your notes here as well,
            keep track of any important information.
          </FormDescription>
          <EditNoteForm
            note={note}
            handleClose={() => setCreationType(null)}
            handleSubmit={updateNote}
          />
        </CreationDrawer>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

//  -- Prop types ---------------------
Note.propTypes = {};

//  -- Default props ------------------
Note.defaultProps = {
  note: {
    content: "Here is a note for viewing",
    created: "2020-06-24T12:34:00Z",
  },
};

export default Note;
