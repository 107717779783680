//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { MenuOutlined } from '@ant-design/icons';
import { Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//  -- Components ---------------------
import { Col, Collapse, Drawer, Dropdown, Menu, Row } from 'antd';
import queryString from 'query-string';
import {  Auth } from "aws-amplify";
import LoadingWrapper from 'components/LoadingWrapper';
import SentryWrap from 'components/Sentry';
import CotationLoader from 'components/CotationLoader';
//  -- Features -----------------------
import Announcements from 'features/Announcements';
import Calendar from 'features/Calendar';
import ChapterManagementBook from 'features/ChapterManagementBook';
import PostForm from 'features/forms/Post';
import StudentVisuals from 'features/StudentVisuals';
import _,{ filter, find,  uniqBy, map  , get , has } from 'lodash';
import React, { useEffect, useState , useRef , useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleListAllBooks } from 'store/books/thunks';
//  -- Thunks -------------------------
import { handleListClassActivities , handleListClassActivitiesForStudent } from 'store/classActivities/thunks';
import { handleListClassQuizzes , handleListClassQuizzesForStudent } from 'store/classQuizzes/thunks';
import { handleListUserBooks ,handleUpdateUserBook } from 'store/userBooks/thunks';
import { handleListUserClasses ,handleDeleteUserClassList , handleCreateUserClass } from 'store/userClasses/thunks';
import { getClassBySectionId, handleGetClass } from "store/classes/thunks";
import { getUserById, handleUpdateNewUser } from "store/users/thunks"
import styled from 'styled-components';
import { mobileMenuimg, IntroVideo, IntroVideoPoster } from "utils/assets/images";
import { handleListClassSubmissions , handleUpdateSubmission , handleListUserSubmissions } from 'store/submissions/thunks';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { getRandomCotation } from "utils/methods/math"
import { handleSetSchoologyAuth } from 'store/schoologyauth/thunks';
import { handleSyncSchoologyClasses } from 'store/schoology/thunks';
import Schoology from 'utils/Schoology';
import { handleInvokeMulticlassForm } from 'store/multiClassStudent/thunks';
import { handleListClassBookmarks } from 'store/bookmark/thunks';
import { handleListInstructionByClass } from "store/instructions/thunks"
import { handleListActivities , handleListCustomAssignment } from 'store/activities/thunks';
import { handleListQuizzes } from 'store/quizzes/thunks';
import AssignmentList from "components/AssignmentLists"
import WelcomeVideo from 'components/WelcomeVideo';
import { useClassGameActivity } from 'store/one_game/selectors';
import TuneIcon from '@material-ui/icons/Tune';
import useMobileDeviceDetection from './chat/hooks';
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


const ButtonClear = styled.button`
    background-color: transparent;
    border: none;
    // color: ${COLOR.black600};
    cursor: pointer;
    outline: none;
    padding: 0px;
`;

const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black200};
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 2px 0px;
    text-transform: uppercase;
`;

const CenterRow = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction; row;
    width: 100%;
`;

const Container = styled.div`
    // padding: 24px 0px;
`;

const CustomCol = styled.li`
    margin: 0px 0px 24px 0px;
    width: 100%;

    @media (min-width: 992px) {
        margin: 0px 0px 0px 12px;
        width: calc(50% - 12px);

        &:first-child {
            margin: 0px 12px 0px 0px;
        }
    }
`;

const CustomRow = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 992px) {
        flex-direction: row;
    }
`;

const DrawerContainer = styled.div`
    height: 100%;
    padding: 40px;
    width: 100%;
`;

const Header = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const HeaderTitle = styled.span`
    color: #213F4B;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
`;

const MobileMenu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;

    @media (min-width: 992px) {
        display: none;
    }
`;

const Section = styled.div`
    margin-bottom: 64px;
    width: 100%;

    &:first-child {
        margin-bottom: 24px;
    }
`;

const SectionHeader = styled.span`
    border-bottom: 1px dashed ${COLOR.black800};
    color: ${COLOR.black300};
    display: block;
    font-size: 11px;
    font-weight 600;
    margin-bottom: 16px;
    padding-bottom: 4px;
    text-transform: uppercase;
     visibility: hidden;
`;

const Title = styled.span`
    color: ${COLOR.black100};
    display: inline-block;
    font-size: 16px;
    font-weight: 300;

    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

const TitleContainer = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction; row;
`;



const WrapperHeader = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 6px;
`;

const VideoContainer = styled.div`
    height: auto;
    flex: 1;
    justify-content: center;
`;

const Video = styled.video`
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    height: auto;
    flex: 1;
    width: 50%;
    justify-content: center;
    @media only screen and (max-width: 600px){
        width: 95%;
    }
`;

const TitleVideo = styled.span`
    color: ${COLOR.white};
    display: inline-block;
    font-size: 16px;
    flex: 1;
    font-weight: 300;
    justify-content: center;
    text-align: center;
    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

const RowVideo = styled.div`
    flex: 1;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-content: center;
`;

const Group = styled.div`
    display: none;

    @media (min-width: 992px) {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
`;

const Mobicon = styled.img`
  height: 30px;
  width: 30px;
  align-self: center;
  object-fit: cover;
`;
const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 16px;
`;

const TabButton = styled.button`
    background-color: transparent;
    border: none;
    border-bottom: 2px solid ${p => p.active ? COLOR.green100 : 'transparent'};
    /* color: ${p => p.active ? COLOR.black100 : COLOR.black500}; */
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 16px 24px;
    text-transform: uppercase;
    transition: all 300ms ease;
    margin-right: 5px;

    &:hover {
        border-bottom: 2px solid ${COLOR.green700};
        color: white;
    }
`;

const HeaderContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black700};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin-bottom: 32px;
    padding: 32px 0px 6px;
    position: relative;

    @media (min-width: 992px) {
        // margin-bottom: 40px;
        padding: 32px 0px 0px;
    }
`;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Student dashboard container
//
//  -- Description
//  Student class view. Contains current class data (grades, assignments submitted,
//  etc.), upcoming assignments and class communication.
//
//  -- Props
//  classActivities {array} - list of activities assigned to current class
//  classQuizzes {array} - list of quizzes assigned to current class
//  currentClass {object} - selected class
//  currentUser {object} - current authenticated user
//  submissions {array} - list of student assignment submissions
//
const getTab = (key = "") => {
    switch (key) {
        case 'home':
            return key;
        case 'assessments':
            return key;
        case 'studentData':
            return key;
        default:
            return "home";
    }
}

const StudentDashboard = ({
    classActivities,
    classQuizzes,
    currentClass,
    currentUser,
    currentOrg,
    submissions,
    booksAllList,
    userBooksList,
    selectedUser,
    schoologyAuth,
    userClasses,
    activities,
    quizzes
}) => {
    let activeCurrentUserId = currentUser?.id
    if (selectedUser && selectedUser.id) {
        activeCurrentUserId = selectedUser.id
    }
    const deviceType = useMobileDeviceDetection();
    const { fetchClassGameActivity } = useClassGameActivity()
    const classes = useStyles();
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const dispatch = useDispatch();
    const [loading, setLoadingFlag] = useState(true);
    const [cotationLoader, setCotationLoader] = useState(true);
    const [announcementDrawer, toggleAnnouncementDrawer] = useState(false);
    const [modalOpen, setModalState] = useState(false);
    const searchView = getTab(search.view);
    const isReviewAccount = currentUser?.reviewAccount || false
    const cotationRef = useRef(null)
    const client = new Schoology(schoologyAuth)
    if (!cotationRef.current) {
        cotationRef.current = getRandomCotation()
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // const invokeMultiClassPopup = () => {
    //     dispatch(handleInvokeMulticlassForm())
    // }
    // useEffect(() => {
    //     if (cotationLoader) {
    //         const hasMoreThenOneActive = filter(userClasses, ['userclass.active', true]).length > 1;
    //         if (hasMoreThenOneActive) {
    //             invokeMultiClassPopup()
    //         }
    //     }
    // }, [userClasses, cotationLoader])
    useEffect(() => {
        if (searchView == "assessments"||searchView == "studentData") {
            listCurrentClassInfo()
            dispatch(handleListClassActivities(currentClass?.id));
            dispatch(handleListClassQuizzes(currentClass?.id));
            fetchClassGameActivity();
            listAllAssignments()
        }
    }, [searchView])
    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);
    const listAllAssignments = async () => {
        if (!Array.isArray(activities) || activities.length == 0) {
           await  dispatch(handleListActivities());
        }
       
        if (!Array.isArray(quizzes) || quizzes.length == 0) {
          await   dispatch(handleListQuizzes());
        }
        await dispatch(handleListCustomAssignment(get(currentUser,"role"),get(currentUser,"id")))
    }

    const listCurrentClassInfo = async () => {
        if (has(currentClass, "id")) {
            dispatch(handleListClassActivitiesForStudent(get(currentClass, "id"),get(currentUser,"id")));
            dispatch(handleListClassQuizzesForStudent(get(currentClass, "id"),get(currentUser,"id")));
        }
    }
    const listInstructionByClass = async () => {
        if (has(currentClass, "id")) {
            dispatch(handleListInstructionByClass(get(currentClass, "id")));
        }
    }
    const getUserSubmissions = async () => {
        dispatch(handleListUserSubmissions(currentUser?.id))
    }
    const listAllBooks = async () => {
        if (!booksAllList || booksAllList.length == 0) {
            dispatch(handleListAllBooks())
        }
    }
    const getDataForAdmin = async () => {
        if (get(currentUser, "role") == "admin" && activeCurrentUserId) {
            const classes = await dispatch(handleListUserClasses(activeCurrentUserId));
            if (classes.length > 0) {
                await dispatch(handleGetClass(classes[0].id));
            }
        }
    }
    const listClassBookmark = async () => {
        if (has(currentClass, "id")) {
            dispatch(handleListClassBookmarks(currentClass.id))
        }
    }
    const fetchAllData = async () => {
        setLoadingFlag(true);
        setCotationLoader(true)
        let currentClassId = localStorage.getItem("currentClassId")
        if ((currentClassId && (currentClassId ===currentClass?.id || currentClassId === "nocurrentuser"))) {
            setLoadingFlag(false)
            setCotationLoader(false)
        }
        await Promise.allSettled([
            listCurrentClassInfo(),
            listInstructionByClass(),
            getUserSubmissions(),
            listAllBooks(),
            getDataForAdmin(),
            listClassBookmark()
            
        ])
        handleUserClassForSchoology()
        localStorage.setItem("currentClassId", currentClass?.id ?? "nocurrentuser")
        setLoadingFlag(false);
        setCotationLoader(false)
        dispatch(handleListUserBooks(activeCurrentUserId))
    }

    useEffect(() => {
        fetchAllData();
        if(has(currentClass,"id")&&currentClass.archived){
            history.goBack();
        }
    }, [currentClass, activeCurrentUserId]);

    useEffect(() => {
        // Validar si usuario es nuevo
        // if (currentUser?.newUser) {
        //      if (!cotationLoader) {
        //         setModalState(true);
        //     }
        // }
        if (!cotationLoader) {
            setModalState(true);
        }
    }, [currentUser, cotationLoader]);
    const handleUserClassForSchoology = async () => {
        if (currentUser && !currentUser.schoologyid) {
            return;
        }
        if (currentUser && currentUser.schoologyid) {
            await  setSchoologyAuth()
            await handleSyncSchoologyClasses(currentUser?.schoologyid)
        }

    }
    
    const setSchoologyAuth = async ()=>{
        let schoologyId = currentUser?.schoologyid
        if(schoologyId){
            if ((!schoologyAuth || !schoologyAuth?.oauth_token ||  (schoologyAuth?.id==schoologyId))&&currentClass?.teacherId) {
                const teacherOfClass = await getUserById(currentClass?.teacherId)
                if (teacherOfClass && teacherOfClass[0]?.schoologyid) {
                    schoologyId = teacherOfClass[0].schoologyid
                }
                await dispatch(handleSetSchoologyAuth(schoologyId))
                await client.setToken(schoologyAuth);
            }
    }
}


    const handleCloseVideo = (() => {
        currentUser.newUser = false;
        setModalState(false);
    });

    const updateUser = async (e) => {
        e.preventDefault();
        await dispatch(handleUpdateNewUser(activeCurrentUserId));
        // console.log("Update User Data Returned", userData)
        currentUser.newUser = false;
        setModalState(false);

    };

    // const group = (data) => groupBy(data, a => moment(a.dueDate).startOf('month').format('MMMM'));
    const videoTitle = "Welcome Video";
    const allGraded = filter(submissions, x => x.graded);
    // const filteredActivities = filter(submissions,x => x.assignmentType === 'activity' && x.graded);
    // const filteredQuizzes = filter(submissions,x => x.assignmentType === 'quiz' && x.graded);

    // const groupedActivities = group(filteredActivities);
    // const groupedQuizzes = group(filteredQuizzes);


    // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // const activitiesOrder = Object.keys(groupedActivities).sort((a, b) => months.indexOf(a) - months.indexOf(b));
    // const quizzesOrder = Object.keys(groupedQuizzes).sort((a, b) => months.indexOf(a) - months.indexOf(b));
    const getAssignment = () => {
        const uniqueActivity = uniqBy(classActivities, "activityId");
        const uniqueQuiz = uniqBy(classQuizzes, 'quizId')
        return [...uniqueActivity, ...uniqueQuiz]
    }

    const toolbarNav = () => {
        return (
            <Menu className='studentMobTabContent'>
                <Menu.Item>
                    <ButtonClear onClick={() => handleTabs('home')}>
                        Home
                    </ButtonClear>
                </Menu.Item>
                <Menu.Item>
                    <ButtonClear onClick={() => handleTabs('assessments')}>
                        Calendar
                    </ButtonClear>
                </Menu.Item>
                <Menu.Item>
                    <ButtonClear onClick={() => handleTabs('studentData')}>
                        Student data
                    </ButtonClear>
                </Menu.Item>
            </Menu>
        )
    };
    const handleTabs = (key) => {
        switch (key) {
            case 'home':
                history.push('/student/class?view=home');
                fetchAllData();
                break;
            case 'assessments':
                history.push('/student/class?view=assessments');
                fetchAllData();
                break;
            case 'studentData':
                history.push('/student/class?view=studentData');
                break;
            default:
                break;
        }
    }
    const getBook = useCallback(() => {
        const ubook = _.get(currentClass, "books.length", 0) > 0 ? currentClass : userBooksList;
        if (_.has(ubook, "books")) {
            ubook.books = filter(ubook.books, k => {
                const bk = find(booksAllList, ['id', k.bookId])
                if (bk) {
                    return !bk.onlyTeacher
                } else {
                    return true;
                }
            })
            return ubook;
        } else {
            return ({ books: [] })
        }
    },[currentClass,userBooksList,booksAllList])
    return (
        <>
            <Modal
                className={classes.modal}
                open={cotationLoader}
            >
                <VideoContainer>
                    <CotationLoader cotation={cotationRef.current} />
                </VideoContainer>
            </Modal>
            {/* <Modal
                className={classes.modal}
                open={modalOpen}
                onClose={handleCloseVideo}
            >
                <VideoContainer>
                    <RowVideo>
                        <TitleVideo>{videoTitle}</TitleVideo>
                    </RowVideo>
                    <RowVideo>
                        <Video
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                            poster={IntroVideoPoster}
                            controls controlsList="nodownload" width="50%">
                            <source src={IntroVideo} type="video/mp4" />
                        </Video>
                    </RowVideo>
                    <RowVideo>
                        <div className={classes.root}>
                            <Button variant="contained" size="large" color="primary" onClick={handleCloseVideo}>
                                WATCH LATER
                            </Button>
                            {isReviewAccount ? null :
                                <Button variant="contained" size="large" color="secondary" onClick={updateUser}>
                                    DON'T SHOW AGAIN
                                </Button>}
                        </div>
                    </RowVideo>
                </VideoContainer>
            </Modal> */}
                <WelcomeVideo open={modalOpen} onClose={handleCloseVideo} />
            <Drawer
                height='100%'
                onClose={() => toggleAnnouncementDrawer(false)}
                placement='top'
                open={announcementDrawer}
                key='announcements'
            >
                <DrawerContainer>
                    <SentryWrap>
                        <Announcements />
                        {currentClass.studentPosting && (
                            <PostForm type='class' />
                        )}
                    </SentryWrap>
                </DrawerContainer>
            </Drawer>
            <Row>
                <Col span={24}>
                    <HeaderContainer>
                        <TitleContainer>
                            <CenterRow>
                                <Mobicon src={mobileMenuimg} />
                                {/* <MenuBook style={{ marginRight: '10px', marginTop: '-6px' }} /> */}
                                <Title>{currentClass.displayName}</Title>
                            </CenterRow>
                            {/* <MobileMenu>
                            <Dropdown overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                                <ButtonLink onClick={e => e.preventDefault()}>
                                    <MenuOutlined style={{ fontSize: '18px' }} />
                                </ButtonLink>
                            </Dropdown>
                        </MobileMenu> */}
                        </TitleContainer>
                        <Group>
                            <ButtonGroup>
                                <TabButton
                                    style={{ backgroundImage: getColor(searchView === 'home') }}
                                    active={searchView === 'home'} onClick={() => handleTabs("home")}>
                                    Home
                                </TabButton>
                                <TabButton
                                    style={{ backgroundImage: getColor(searchView === 'assessments') }}
                                    active={searchView === 'assessments'} onClick={() => handleTabs('assessments')}>
                                    Calendar
                                </TabButton>
                                <TabButton
                                    style={{ backgroundImage: getColor(searchView === 'studentData') }}
                                    active={searchView === 'studentData'} onClick={() => handleTabs('studentData')}>
                                    Student data
                                </TabButton>
                            </ButtonGroup>
                        </Group>
                        <MobileMenu>
                            <Dropdown overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                                <ButtonLink onClick={e => e.preventDefault()}>
                                    {/* <MenuOutlined style={{ fontSize: '18px' }} /> */}
                                    <TuneIcon style={{ fontSize: '32px' }} />
                                </ButtonLink>
                            </Dropdown>
                        </MobileMenu>
                    </HeaderContainer>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Container>
                        <Row className="tabCollection wrapperAdmnDash">
                            <Col className="contentAdmnDash" xs={{ span: 24 }} sm={24} md={24} lg={{ span: (searchView == "home") ? 17 : 24 }}>
                            
                        {searchView == 'home' && (
                            <>
                                <Section
                                    description="Below are the chapters for the book. Clicking on a chapter will display the book."
                                    header='Home'
                                >
                                    <LoadingWrapper loading={loading} hasCotationLoaded={true}>
                                        <SentryWrap>
                                            <ChapterManagementBook
                                                userBooksList={getBook()}
                                                listBook={userBooksList}
                                                currentOrg={currentOrg}
                                                booksAllList={booksAllList}
                                                currentUser={currentUser}
                                            />
                                        </SentryWrap>
                                    </LoadingWrapper>
                                </Section>
                            </>
                        )}
                        {searchView == 'assessments' && (
                            <>
                                <Section>
                                    <SectionHeader>My Calendar</SectionHeader>
                                    <LoadingWrapper loading={loading}>
                                        <SentryWrap>
                                            <Calendar
                                                assignments={getAssignment()}
                                                submissions={submissions}
                                                booksAllList={booksAllList}
                                                currentUser={currentUser}
                                                userBooks={getBook().books}
                                             />
                                        </SentryWrap>
                                    </LoadingWrapper>
                                </Section>
                                {/* <Section>
                                <SectionHeader>My Assignments</SectionHeader>
                                <LoadingWrapper loading={loading}>
                                    <SentryWrap>
                                        <AssignmentManagement
                                            activities={classActivities}
                                            quizzes={classQuizzes}
                                            booksAllList={booksAllList}
                                        />
                                    </SentryWrap>
                                </LoadingWrapper>
                            </Section> */}
                            </>
                        )}
                        {searchView == 'studentData' && (
                            <>
                                <Section>
                                    <SectionHeader>My Data</SectionHeader>
                                    <LoadingWrapper loading={loading}>
                                        <SentryWrap>
                                            <StudentVisuals
                                                allGraded={allGraded}
                                                classActivities={classActivities}
                                                classQuizzes={classQuizzes}
                                                assignments={getAssignment()}
                                            />
                                        </SentryWrap>
                                    </LoadingWrapper>
                                </Section>
                            </>
                        )}
                        </Col>
                            {(searchView == "home") && <Col className="sidebarAdmnDash assignment-list-dashboard" xl={7}>
                                <div className="assignmentListSection" style={{display:deviceType ? 'none' : 'inherit'}}>
                                    <AssignmentList />
                                </div>
                            </Col>}
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

//  -- Prop types ---------------------
StudentDashboard.propTypes = {};

//  -- Default props ------------------
StudentDashboard.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    currentOrg: state.currentOrg,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
    userBooksList: state.userBooksList,
    selectedUser: state.selectedUser,
    schoologyAuth: state.schoologyAuth,
    userClasses: state.userClasses,
    activities: state.activities,
    quizzes: state.quizzes,
});

export default connect(mapStateToProps, null)(StudentDashboard);
