//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from "aws-amplify";
import * as Sentry from "@sentry/react";
import * as Actions from './actions';

export const handeleCanvasCode = async (code,auth_id) => {
  try {
    return API.post("canvas", "/codeVerify", {
      body: {
        code: code,
        redirect_uri: window.location.origin,
        auth_id:auth_id
      }
    })
  } catch (error) {
    console.log(error,"rr")
    return null;
  }
  

};
export const handleCheckUserLti = async (user_id, auth_url) => {
  try {
    return API.post("canvas", "/check_user_lti", {
      body: {
        user_id,
        auth_url
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
};
export const handleCheckUserAuthOnLti = async (id_token, target_uri) => {
  try {
    return API.post("canvas", "/check_id_token", {
      body: {
        id_token,
        target_uri
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
};
export const handeleCanvasUserLogin = async (access_code, global_id , auth_id) => {
  try {
    return API.post("canvas", "/handleUser", {
      body: {
        "access_token": access_code,
        "global_id": global_id,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }


};
export const handeleCanvasUserClasses = async (access_code,canvas_id,auth_id) => {
  try {
    return API.post("canvas", "/handleUserCourses", {
      body: {
        "access_token": access_code,
        canvas_id,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
};
export const handeleCanvasClassUsers = async (canvas_id,auth_id,class_id) => {
  try {
    return API.post("canvas", "/handleClassStudents", {
      body: {
        canvas_id,
        auth_id,
        class_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
};
export const handleListDistrictAuth =  () => async (dispatch) => {
  try {
    const result = await   API.get("canvas", "/list_district", {})
    dispatch(Actions.setDistrictAuth(result))
  } catch (error) {
    console.log(error,"rr")
    return null;
  }
  

};

// "assignments": [
//   {
//     "canvas_course_id": "2",
//     "due_date": "2023-07-20T05:59:00.000Z",
//     "weightage": "100",
//     "displayName": "assignment throgh lambda"
//   }
// ]
export const handleCreateCanvasAssignment = async (canvas_User_Id, assignments,auth_id) =>{
  try {
    return API.post("canvas", "/handleCreateAssignment", {
      body: {
        canvas_User_Id,
        assignments,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}

// "assignments": [
//   {
//     "canvas_user_id": "109",
//     "canvas_course_id": "2",
//     "displayName": "assignment throgh lambda update",
//     "weightage": "100",
//     "due_date": "2023-07-22T05:59:00.000Z",
//     "canvas_id": "116"
//   }
// ]
export const handleUpdateCanvasAssignment = async (canvas_User_Id, assignments,auth_id) =>{
  try {
    return API.put("canvas", "/handleUpdateAssignment", {
      body: {
        canvas_User_Id,
        assignments,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}

// "assignments": [
//   {
//  assignment id =>     "canvas_id": "115",
//       "canvas_course_id": "2"
//   }
// ]
export const handleDeleteCanvasAssignment = async (canvas_User_Id, assignments,auth_id) =>{
  try {
    return API.del("canvas", "/handleDeleteAssignment", {
      body: {
        canvas_User_Id,
        assignments,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}
// "assignments": {
//   "canvas_assignment_id":"116",
//   "course_id":"2",
//   "canvas_user_id":"5",
//   "grade":"10"
// }
export const handleUpdateCanvasGrade = async (canvas_User_Id, assignments,auth_id) =>{
  try {
    return API.put("canvas", "/handleUpdateGrades", {
      body: {
        canvas_User_Id,
        assignments,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}
export const handleSyncGrade = async (canvas_User_Id, course_id, section_id ,auth_id) =>{
  try {
    return API.post("canvas", "/syncGrade", {
      body: {
        canvas_User_Id,
        course_id,
        auth_id,
        section_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}
export const handleSyncAssignment = async (canvas_User_Id, course_id , section_id,auth_id) =>{
  try {
    return API.post("canvas", "/syncClassAssignment", {
      body: {
        canvas_User_Id,
        course_id,
        section_id,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}

// "assignments": {
//   "canvas_assignment_id":"116",
//   "course_id":"2",
//   "canvas_user_id":"5",
//   "status": 1 1 for excuse and 3 for missing
// }
export const handleUpdateCanvasStatus = async (canvas_User_Id, assignments,auth_id) =>{
  try {
    return API.put("canvas", "/assignmentSubmissionStatusUpdate", {
      body: {
        canvas_User_Id,
        assignments,
        auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}
/*
canvas_assignment_id 
course_id
*/
export const handleCreateCanvaSubmissions = async (canvas_user_id, assignments,auth_id) =>{
  try {
    return API.post("canvas", "/handleCreateSubmissions", {
      body: {
        canvas_user_id, assignments, auth_id
      }
    })
  } catch (error) {
    console.log(error, "rr")
    return null;
  }
}
export const handleListCanvasAssignmentCat = (canvas_user_Id, course_id, auth_id) => async (dispatch) => {
    try {
      const categ = await API.post("canvas", "/getWeightageCategoryByClass", {
        body: {
          canvas_user_Id: canvas_user_Id,
          auth_id: auth_id,
          course_id: course_id,
        },
      });
      if(categ&&!categ.error){
        dispatch(Actions.setCanvasAssignmentsCategory(categ))
      }
      return categ;
    } catch (error) {
      console.log(error, "rr");
      return null;
    }
  };

  export const handleUserByCanvasName = async (name) => {
    if(typeof name!="string") return [];
    try {
      const firstName = name.replace(/\s\s+/g, ' ').split(" ")[0].trim();
      const lastName = name.replace(/\s\s+/g, ' ').split(" ")[1]?.trim();
        const user = await API.post("canvas", `/listUserByName`,{
          body:{
            firstName,
            lastName
          }
        })
        return user;
  
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return ({ error: true });
    }
  }
  export const handleGetUserInfoById = async (canvas_id,auth_id) => {
    if(!canvas_id||!auth_id) return [];
    try {

        const user = await API.post("canvas", `/getUserInfoById`,{
          body:{
            canvas_id,auth_id
          }
        })
        return user;
  
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return ({ error: true });
    }
  }
export const getCanvasKeyInfo = async (canvas_id, orgId) => {
  if (!canvas_id || !orgId) return {};
  try {

    const data = await API.post("canvas", `/getKeyInfo`, {
      body: {
        canvas_id, orgId
      }
    })
    return data;

  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error)
    }
    return {};
  }
}
export const updateCanvasKeyInfo = async (config) => {
  if (!config) return {};
  try {
    const data = await API.post("canvas", `/update_key_info`, {
      body: {
        config
      }
    })
    return data;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error)
    }
    return {};
  }
}

export const handleListCanvasKeys = async () => {
  try {
      const data = await API.get('canvas', `/list_keys`);
      return data;
  } catch (error) {
      if (error && error.message != "Network Error") {
          Sentry.captureException(error)
      }
      return {}
  }
}