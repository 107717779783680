//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const classActivitiesInitial = [];
function mapToGraphic  (data){
    return map(data,k=>{
        if (k && k.data && k.data.activity_type == 'graphic_organizer') {
            if(!k.data.page){
                k.data.page = k.assigned_on_page
            }
        }
        return k;
    })
}
export const classActivities = (state = classActivitiesInitial, action) => {
    switch (action.type) {
        case ActionTypes.classActivities.listClassActivitiesSuccess:
            return mapToGraphic(action.payload);
        case ActionTypes.classActivities.createClassActivitySuccess:
            return mapToGraphic([action.payload, ...state]);
        case ActionTypes.classActivities.deleteClassActivitySuccess:
            return state.filter(x => x.id !== action.payload.id && x.activityId !== action.payload.id);
        case ActionTypes.classActivities.updateClassActivitySuccess:
            return mapToGraphic(state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload };
                } else {
                    return x;
                }
            }));
        case ActionTypes.classActivities.listClassActivitiesFail:
        case ActionTypes.classActivities.createClassActivityFail:
        case ActionTypes.classActivities.deleteClassActivityFail:
        case ActionTypes.classActivities.uodateClassActivityFail:
        case ActionTypes.classActivities.createDeactivateByStudent:  
        return state.map(data => {
            if (data?.id === action.payload.assignmentId) {
                return {
                    ...data,
                    deactivated_student: action.payload.deactivateStudent
                };
            }
            return data;
        });
        default:
            return state;
    }
};
