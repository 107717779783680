//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create activity ----------------
/*export const createActivity = () => ({ type: ActionTypes.activities.createActivity });
export const createActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.createActivityFail, payload: returnedPayload });
export const createActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.createActivitySuccess, payload: returnedPayload });

//  -- Get activity -------------------
export const getActivity = () => ({ type: ActionTypes.activities.getActivity });
export const getActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.getActivityFail, payload: returnedPayload });
export const getActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.getActivitySuccess, payload: returnedPayload });

//  -- List chapter activities --------
export const listChapterActivities = () => ({ type: ActionTypes.activities.listChapterActivities });
export const listChapterActivitiesFail = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesFail, payload: returnedPayload });
export const listChapterActivitiesSuccess = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesSuccess, payload: returnedPayload });*/

//  -- List books ----------------
export const listTipsBook = () => ({ type: ActionTypes.tips.listTipsBook });
export const listTipsBookFail = (returnedPayload) => ({ type: ActionTypes.tips.listTipsBookFail, payload: returnedPayload });
export const listTipsBookSuccess = (returnedPayload) => ({ type: ActionTypes.tips.listTipsBookSuccess, payload: returnedPayload });

export const createTipsBook = () => ({ type: ActionTypes.tips.createTipsBook });
export const createTipsBookFail = (returnedPayload) => ({ type: ActionTypes.tips.createTipsBookFail, payload: returnedPayload });
export const createTipsBookSuccess = (returnedPayload) => ({ type: ActionTypes.tips.createTipsBookSuccess, payload: returnedPayload });

export const updateTipsBook = () => ({ type: ActionTypes.tips.updateTipsBook });
export const updateTipsBookFail = (returnedPayload) => ({ type: ActionTypes.tips.updateTipsBookFail, payload: returnedPayload });
export const updateTipsBookSuccess = (returnedPayload) => ({ type: ActionTypes.tips.updateTipsBookSuccess, payload: returnedPayload });

export const deleteTipsBook = () => ({ type: ActionTypes.tips.deleteTipsBook });
export const deleteTipsBookFail = (returnedPayload) => ({ type: ActionTypes.tips.deleteTipsBookFail, payload: returnedPayload });
export const deletetTipsBookSuccess = (returnedPayload) => ({ type: ActionTypes.tips.deleteTipsBookSuccess, payload: returnedPayload });
