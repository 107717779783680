//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { func, number, shape, string } from "prop-types";
import React from "react";
import styled from "styled-components";


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;




const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`;

const ChapterCardBook = ({ data, handleClick }) => {
    return (
        <Wrapper onClick={handleClick}>
            <Image src={data.img} alt={data.description} />
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ChapterCardBook.propTypes = {
    data: shape({
        img: string,
        title: string,
        description: string,
        activities: number,
        quizzes: number,
    }),
    handleClick: func
};

//  -- Default props ------------------
ChapterCardBook.defaultProps = {
    data: {},
};

export default ChapterCardBook;