//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const logsInitial = [];

export const logs = (state = logsInitial, action) => {
    switch (action.type) {
        case ActionTypes.logs.createLogSuccess:
            return [action.payload, ...state];
        case ActionTypes.logs.listLogsSuccess:
            return action.payload;
        case ActionTypes.logs.createLogsFail:
        case ActionTypes.logs.listLogsFail:
            return state;
        default:
            return state;
    }
};
