import { CloseOutlined } from '@ant-design/icons';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { Row } from "antd";
import React, { useEffect, useRef, useState } from 'react';
import { useMainbookView } from 'store/main_book_popup/selectors';
import styled from "styled-components";
import COLOR from 'utils/constants/color';

const Wrapper = styled.div`
    position: absolute;
    top: -36px;
    width: ${p => p.width}px;
    height: ${p => p.height}px;
    background-color: rgba(0, 0, 0,0.3);
    z-index: 2;
    box-shadow: 7px -3px 26px 20px rgb(66 66 66), -4px -3px 26px 15px rgb(95 109 110);
`;
const ButtonContainer = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.black600};
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
    width: 40px;
    z-index: 1;
    
`;
const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    object-fit: fill;
    height:97%;
    width: 100%;
    @media print {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
`;
export default function MainbookPopup({ getRef }) {
    const { mainbookPageView, revokeMainbookView } = useMainbookView()
    const [pages, setPages] = useState([])
    const [index, setIndex] = useState(0)
    const containerRef = getRef()
    const [style, setStyle] = useState({ height: 0, width: 0 })
    const backBtnRef = useRef();
    const forwardBtnRef = useRef();
    useEffect(() => {
        if (mainbookPageView.open) {
            setIndex(0)
            setPages(mainbookPageView.pages)
        } else {
            setIndex(0)
            setPages([])
        }
    }, [mainbookPageView])

    useEffect(() => {
        function updateStyle() {
            if (containerRef) {
                const { width, height } = containerRef.getBoundingClientRect()
                setStyle({ height, width })
            }
        }

        updateStyle();
        window.addEventListener('resize', updateStyle);

        return () => {
            window.removeEventListener('resize', updateStyle);
        }
    }, [mainbookPageView])
    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", () => { })
        }
    }, [])
    const handleClose = () => {
        revokeMainbookView();
    };
    function checkKey(e) {
        let isMainBook = document.getElementsByClassName("main-book-popup").length > 0;
        if (isMainBook) {
            e = e || window.event;
            if (e.keyCode == '37') {
                if (backBtnRef.current && !backBtnRef.current.disabled) {
                    backBtnRef.current.click()
                }

            }
            else if (e.keyCode == '39') {
                if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                    forwardBtnRef.current.click()
                }

            }
        }
    }
    const getPgSrc = () =>{
        if(pages&&pages.length>0&&index>=0){
            const page = pages[index];
            return page ? page.resource : null
        }
    }
    const getImg = (ind) => {
        const page = pages[ind];
        return page ? page.resource : null
    }
    const preloadImage = () => {
        let cPage = index + 1
        const remin_pages = pages.length - cPage
        if (remin_pages <= 0) return;
        for (let i = cPage; i < cPage + 5; i++) {
            let url = getImg(i)
            if (url) {
                new Image().src = url
            }
        }
    }
    if (!mainbookPageView.open) return null;
    return (
        <Wrapper width={style.width} height={style.height + 36}  className={mainbookPageView.open?'main-book-popup':''}>
           <CloseOutlined onClick={handleClose} style={{ 
                position: 'absolute', 
                top: '-10px', 
                right: '-10px', 
                cursor: 'pointer', 
                fontSize: '16px', 
                color: 'white', 
                background: 'red', 
                padding: '5px', 
                borderRadius: '50%' 
            }} />
            <Row style={{ height: 35 , justifyContent:"space-between" , display:"flex", backgroundColor:COLOR.white , paddingRight:20 }}>
                <ButtonContainer
                    ref={backBtnRef}
                    disabled={index <= 0}
                    onClick={() => {
                       if(index>0){
                        setIndex(index-1)
                       }
                    }}
                    left={0} className="cstmLeftArow">
                    <ArrowBackIos style={{ fontSize: '20px' }} />
                </ButtonContainer>
                <ButtonContainer
                    ref={forwardBtnRef}
                    disabled={index >= pages.length-1}
                    onClick={() => {
                        if (index < pages.length) {
                            setIndex(index + 1)
                            preloadImage()
                        }
                    }}
                    right={0} className="cstmRghtArow">
                    <ArrowForwardIos style={{ fontSize: '20px' }} />
                </ButtonContainer>
            </Row>
            <Images src={getPgSrc()} />
        </Wrapper>
    )
}
