//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { DownloadOutlined, PlusSquareOutlined, InfoCircleOutlined , EditOutlined , CaretDownOutlined  } from '@ant-design/icons';
import { Modal } from '@material-ui/core';
//  -- Components ---------------------
import { Col, Drawer, Dropdown, Menu, Row , Tooltip } from 'antd';
import EmptyState from 'components/EmptyState';
import Submission from 'components/Submission';

import Loading from 'components/Loading';
import { TbgIconimg } from "utils/assets/images";
//  -- Features -----------------------
import AssignmentReview from 'features/forms/AssignmentReview';
import ChangePasswordForm from 'features/forms/ChangePassword';
import StudentForm from 'features/forms/Student';
import GradebookCalendar from 'features/GradebookCalendar';
import GradeTable from 'features/GradeTable';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { lighten } from 'polished';
import { arrayOf, shape, string } from 'prop-types';
import React, { useEffect, useState , useMemo, useRef  } from 'react';
import { connect, useDispatch } from 'react-redux';
import { handleListAllBooks } from 'store/books/thunks';
//  -- Thunks -------------------------
import { handleCreateUserPreference, handleUpdateUserPreference, handleGetUserPreference } from 'store/userPreference/thunks';
import { handleSyncGrade  } from "store/canvas/thunks"
import { handleListClassQuestions } from 'store/questions/thunks';
import { handleListClassSubmissions } from 'store/submissions/thunks';
import { handleListUserBooks } from 'store/userBooks/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import styled from 'styled-components';
import {  filterNonStoryClassQuizOrActivity , filterMainGradebookClassQuizOrActivity  } from 'utils/lib/ebookHelper';
import { useStory } from 'store/story/selectors';
//  -- Constants ----------------------
import _, { map, uniqBy, find, get, filter, groupBy } from 'lodash';
import COLOR from 'utils/constants/color';
import XLSX from 'xlsx';
import moment from "moment"
import Schoology from 'utils/Schoology';
import StudentReview from './forms/StudentReview';
import { useApExams } from 'store/apexams/selectors';
import { useUserClasses } from 'store/userClasses/selectors';
import {  MenuBook } from "@material-ui/icons"
import { handleGetClass } from 'store/classes/thunks';
import { useClassGameActivity } from 'store/one_game/selectors';
import { handleSyncClassworkAssignment, handleSyncClassworkGeades } from 'store/googleClassRoom/thunks';
import TuneIcon from '@material-ui/icons/Tune';
import GradeDownloader from 'components/GradeDownloader';
import { useSchoologyGradeCategory } from 'store/schoologyauth/selectors';
import { storage } from 'firebaseConfig';
import { ref as fireRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { handleCreateDownloadList } from 'store/exams/thunks';
const GRADE_PREF = [
    { key: "average", value: "Average Grade" },
    { key: "scale_1_5", value: "Grade in 0-4" },
    { key: 'gradebook_a_f', value: "Gradebook with A-F" }
]
const ORDER_BY = [
    { key: "asc", value: "Ascending" },
    { key: "desc", value: "Descending" },
]


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const ModalContent = styled.div`
    align-items: center;
    background-color: #11ffee00;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

const Button = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.green200};
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;

    &:hover {
        color: ${COLOR.green}
    }
`;

const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const Error = styled.div`
    background-color: ${lighten(0.4, 'red')};
    border: 1px solid red;
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin: 0px auto 16px;
    max-width: 480px;
    padding: 8px;
    text-align: center;
    width: 100%;
`;

const Header = styled.div`
    align-items: center;
    border-bottom: 1px dashed ${COLOR.black800};
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 8px;
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
`;

const Wrapper = styled.div`
    width: 100%;
`;

const TbgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px;
    background-color: ${COLOR.blueish100};
    border: 3px solid ${COLOR.indigo400};
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    `
const TbgIconWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
    `
const TbgIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
}
    `

const TbgTitle = styled.span`
    color: ${COLOR.white};
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    `
const TbgTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    `
const TbgList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 14px;
`
const TbgListItem = styled.li`
    color: ${COLOR.white};
    font-size: 10px;
    text-align: left;
    font-weight: 400;
    display: flex;
    flex-direction:row;
    margin-top: 4px;
    align-items:center;
    `
const ColorBox = styled.div`
    height:10px;
    width:20px;
    margin-right: 5px;
`;
const ClassSelector = styled.div`
    min-height: 20px;
    min-width:100px;
    border-radius: 20px;
    background: #2d8a88;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    overlow:hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 3px 7px;
    font-size: 12px;
    margin: 5px 0px 5px 5px;
`;
const ClassMenu = styled(Menu)`
 &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px; 
    border: 3px solid #f1f1f1; 
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 20px; 
  }

  &::-webkit-scrollbar-button:single-button {
    background-color: #f1f1f1; 
    display: block;
    background-size: 10px; 
    background-repeat: no-repeat;
  }


`
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Gradebook
//
//  -- Description
//  District and class announcements
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Gradebook />
//
const getCellStyle = (isLate, isDiffStudent, isTbg) => {
    if (isLate) {
        return ({ backgroundColor: "rgba(255,0,0,0.8)" })
    } else if (isDiffStudent) {
        return ({ backgroundColor: "#00ff134f" })
    } else if (isTbg) {
        return ({ backgroundColor: "#72bef1e0" })
    } else {
        return ({})
    }
}
const groupAssignment = (submissions) => {
    const gp = groupBy(submissions, 'assignmentId');
    const res = {}
    Object.keys(gp).forEach(key => {
        res[key] = uniqBy(gp[key], "userId")
    })
    return res;
}

const Gradebook = ({
    classActivities,
    classQuizzes,
    currentClass,
    currentOrg,
    classUsers,
    submissions,
    currentUser,
    userBooksList,
    booksAllList,
    handleEditQuiz,
    handleEditActivity,
    userPreference,
    selectedUser,
    activities,
    quizzes,
    schoologyAuth,
    onswithClass
}) => {
    const story = useStory()
    const apExam = useApExams()
    const userClasses = useUserClasses()
    const { class_game_activity } = useClassGameActivity()
    const classQuizzesData = useMemo(() => filterMainGradebookClassQuizOrActivity(classQuizzes,story,get(apExam[0],"exams")), [classQuizzes,story,apExam])
    const classActivityData = useMemo(() => [...class_game_activity,...filterMainGradebookClassQuizOrActivity(classActivities,story,get(apExam[0],"exams"))], [classActivities,story,apExam,class_game_activity])
    const assignment_submission = useMemo(() => {
        return submissions.map(k => {
            if (k.assignmentType === 'game' || k.assignmentType === 'debate') {
                const game = find(class_game_activity, ['session_id', k.assignmentId])
                if (game) {
                    return ({
                        ...k,
                        assignment: game.assignment,
                    })
                } else {
                    return k;
                }
            }
            const graphic_organizer = find(classActivityData, ["activityId", k.assignmentId])
            if (graphic_organizer && graphic_organizer.data?.activity_type === "graphic_organizer") {
                return ({
                    ...k,
                    assignment: graphic_organizer.data,
                })
            }
            return k;
        })
    }, [submissions, class_game_activity,classActivityData]); 
    const currentUserId = selectedUser && selectedUser.id ? selectedUser.id : currentUser?.id;
    const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
    const isCleverUser = !!(currentUser && currentUser.cleverId);
    const isCanvasUser = !!(currentUser && currentUser.canvas_id);
    const isGoogleUser = !!(currentUser && currentUser.googleId);
    const isClassLinkuser = !!(currentUser && currentUser.classlinkid);
    const isUserFromOtherPlatform =  isSchoologyUser || isCleverUser || isGoogleUser || isClassLinkuser || isCanvasUser
    const howGradeScale =    !isGoogleUser &&  !isCanvasUser && !isSchoologyUser
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const isReviewAccount = currentUser?.reviewAccount || false
    const [editing, setEditing] = useState({});
    const [userDrawer, toggleUserDrawer] = useState(false);
    const [changePasswordDrawer, toggleChangePasswordDrawer] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [maxExceedError, setMaxExccedError] = useState(false);
    const [userBook, setUserBook] = useState(userBooksList);
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = _.get(orgPermissions, "books", [])
    const [gradeView, setGradeViw] = useState("gradetable")
    const [examData, setExamData] = useState([])
    const [gradeData, setGradeData] = useState([])
    const [LegendsShow, setLegendsShow] = useState(false);
    const scale_1_5 = userPreference?.gradePref == "scale_1_5"
    const gradebook_a_f = userPreference?.gradePref == "gradebook_a_f"
    const [updatePrefLoader, setUpdatePrefLoader] = useState(false);
    const [updateorderByLoader, setUpdateOrderByLoader] = useState(false);
    const [orderBy, setOrderBy] = useState("desc")
    const [editPrevSemData,setEditPrevSemData] = useState(false)
    const [isCurrentSem,setCurrentSem]=useState(true)
    const [syncLoader, setSyncLoader] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState({})
    const client = new Schoology(schoologyAuth);
    const [schoologyGradeCategory,] = useSchoologyGradeCategory()
    const gradeDownloadRef = useRef()

    const [current, setCurrent] = useState('mail');
    const onClickMenu = (e) => {
        if (e.key === 'Ascending') {
            setOrderBy('asc')
            handleUpdateorderByPref('asc')
        }
        if (e.key === 'Descending') {
            setOrderBy('desc')
            handleUpdateorderByPref('desc')
        }
        if (e.key === 'Average_Grade') {
            handleUpdateGradePref('average')
        }
        if (e.key === 'Sync_to_Schoology') {
            syncToSchoology();
        }
        if (e.key === 'Sync_canvas') {
            syncToCanvas();
        }
        if (e.key === 'Sync_google_classroom') {
            handleGoogleClassRomm();
        }
        if (e.key === 'Grade_in') {
            handleUpdateGradePref('scale_1_5')
        }
        if (e.key === 'Gradebook_with') {
            handleUpdateGradePref('gradebook_a_f')
        }
        if (e.key === 'Legends') {
            handleLegendsShow();
        }
        if (e.key === 'XLSX') {
            if (gradeView == "gradetable") {
                generateCSV(gradeData, 'xlsx');
            } else {
                generateCSV(examData, 'xlsx');
            }
        }
        if (e.key === 'CSV') {
            if (gradeView == "gradetable") {
                generateCSV(gradeData, 'csv');
            } else {
                generateCSV(examData, 'csv');
            }
        }
        if(e.key === "download"){
            gradeDownloadRef.current.open()
        }
        if (e.key === 'Add_Student') {
            setEditing(false);
            setUserData({
                username: '',
                password: ''
            });
            toggleUserDrawer(true);
        }
        setCurrent(e.key);
    };

    useEffect(() => {
        if (userPreference && typeof userPreference.orderBy === "string") {
            if (userPreference.orderBy === "asc" || userPreference.orderBy === 'desc') {
                setOrderBy(userPreference.orderBy)
            }
        }
    }, [userPreference])
    useEffect(()=>{
        setEditPrevSemData(Boolean(localStorage.getItem("editPrevSemData")))
    },[])
    const handleLegendsShow = () => {
        setLegendsShow(true);
    }

    const TbgLabels = () => {
        return (
            <TbgContainer>
                <TbgTitleWrapper>
                    <TbgIconWrapper>
                        <TbgIcon src={TbgIconimg} />
                    </TbgIconWrapper>
                    <TbgTitle>Legends</TbgTitle>
                </ TbgTitleWrapper>
                <TbgList>
                    <TbgListItem>TBG: To Be Graded</TbgListItem>
                    <TbgListItem><ColorBox style={getCellStyle(true)} /> RED: Late Submission</TbgListItem>
                    <TbgListItem><ColorBox style={getCellStyle(false, true)} /> GREEN: Differentiation</TbgListItem>
                    <TbgListItem><ColorBox style={getCellStyle(false, false, true)} /> BLUE: TBG </TbgListItem>
                </TbgList>
            </TbgContainer>
        )
    }
    const listAllBooks = async () => {
        if (!booksAllList || booksAllList.length == 0) {
            dispatch(handleListAllBooks())
        }
    }
    const fetchAllData = async () => {
        setLoading(true);
        const currentClassIdForGrade = localStorage.getItem("currentClassIdForGrade")
        if ((currentClassIdForGrade && currentClassIdForGrade === currentClass?.id) || (!currentClass?.id && currentClassIdForGrade === "noclasssid")) {
            setLoading(false);
        }
        await Promise.allSettled([
            dispatch(handleListUserBooks(currentUserId)),
            listAllBooks(),
            dispatch(handleListClassUsers(get(currentClass, "id"))),
            handleGoogleClassRomm(),
            syncGradeForCanvas()
        ])

        // await dispatch(handleListClassUsers(currentClass.id));
        // await dispatch(handleListClassSubmissions(currentClass.id));
        localStorage.setItem("currentClassIdForGrade", currentClass?.id ?? "noclasssid")
        setLoading(false);
        await dispatch(handleListClassQuestions(currentClass.id))
    }
    const handleGoogleClassRomm = async () => {
        try {
            setSyncLoader(true);
            if (_.has(currentUser, "googleId") && currentUser.googleId) {
                await handleSyncClassworkAssignment(currentUser.googleId, currentClass.id);
                await handleSyncClassworkGeades(currentUser.googleId, currentClass.id, currentClass.googleId);
            }
            await dispatch(handleListClassSubmissions(get(currentClass, "id")))
            setSyncLoader(false);
        } catch (error) {
            setSyncLoader(false);
        }
    }
    useEffect(() => {
        fetchAllData();
    }, [currentClass])


    const syncGradeForCanvas = async () => {
        try {
            if (currentClass?.canvas_id) {
                setSyncLoader(true)
                await handleSyncGrade(currentUser.canvas_id,currentClass.canvas_course_id  , currentClass.canvas_id ,  currentUser.canvas_auth_id )
                await  dispatch(handleListClassSubmissions(get(currentClass, "id")))
                setSyncLoader(false)
            }
        } catch (error) {
            console.log(error, "bid-err");
        }

    }

    const [assignmentData, setAssignmentData] = useState({});
    const [selectedAssignmentUserSubmission, setSelectedAssignmentSubmissions] = useState([])
    const [assignmentDrawer, toggleAssignmentDrawer] = useState(false);
    const [studentAssignmentDrawer, setStudentAssignmentDrawer] = useState(false);
    const [formatPasswordError, setFormatPasswordError] = useState(false);
    //  -- User ---------------------------



    const updateUserData = (id, value) => {
        setUserData({
            ...userData,
            [id]: value
        });
        setFormatPasswordError(false);
    };

    const studentSubmissions = groupAssignment(assignment_submission) || {}
    const reviewAssignment = (data, tempData, users, userSubmissions) => {
        const _assignment = _.concat(classActivityData, classQuizzesData, class_game_activity).filter(a => (a.quizId === data?.assignmentId || a.activityId === data?.assignmentId || a.session_id == data?.assignmentId))[0];
        let sAssignmentId = get(data, "sAssignmentId");
        if (_assignment&&_assignment.sAssignmentId) {
            sAssignmentId = _assignment.sAssignmentId;
        }
        if (data) {
            data.sAssignmentId = sAssignmentId;
        }
        if (!data) {
            const utemp = _.cloneDeep(tempData);
            utemp.isTemp = true;
            data = utemp;
            data.sAssignmentId = sAssignmentId;
        }
        const userdata = map(users, usr => {
            const sub = userSubmissions[usr.id]
            if (sub) {
                const asub = find(sub, n => get(n, "assignmentId") == get(data, "assignmentId", get(tempData, "assignmentId")))
                if (asub) {
                    asub.sAssignmentId = sAssignmentId;
                    return asub
                } else {
                    const utemp = _.cloneDeep(tempData);
                    utemp.userId = usr.id;
                    utemp.user = usr;
                    utemp.isTemp = true;
                    utemp.sAssignmentId = sAssignmentId;
                    return utemp;
                }
            } else {
                const utemp = _.cloneDeep(tempData);
                utemp.userId = usr.id;
                utemp.user = usr;
                utemp.isTemp = true;
                utemp.sAssignmentId = sAssignmentId;
                return utemp;
            }
        })
        setSelectedAssignmentSubmissions(userdata)
        setAssignmentData(data);
        toggleAssignmentDrawer(true);
    };

    const userReviewAssignment = (user, assignment, userSubmissions) => {
        const usersub = userSubmissions[user.id];
        let data = {
            user: user,
            assignments: map(assignment, asign => {
                let tempData = getAssignmentCreation(asign, user)
                const is_game = asign.type === 'game'
                const is_debate = asign.type === 'debate'
                const assignment_id = is_game || is_debate ? asign.session_id : asign.data?.id
                let u_submissions = find(usersub, ['assignmentId', assignment_id])
                if ((is_game||is_debate) && !asign.data && asign.assignment) {
                    asign.data = {
                        ...asign.assignment,
                        "bookId": asign.assignment.chapter,
                        "typeId": asign.type ||  "game",
                    }
                }
                return ({
                    ...asign,
                    submissions: u_submissions && Object.keys(u_submissions || {}).length > 0 ? u_submissions : tempData
                })
            }),
        }
        // const data = submission && Object.keys(submission || {}).length > 0 ? submission : tempData
        // const userId = get(users, 'id', '');
        // const getUserAssignment = tempData[userId]
        // setSelectedSubmissions(getUserAssignment);
        setSelectedUserData(data)
        setStudentAssignmentDrawer(true)
    }


    const getPref = async () => {
        await dispatch(handleGetUserPreference(currentUser?.id));

    }

    const handleUpdateGradePref = async (_scale) => {
        setUpdatePrefLoader(true)
        if (!userPreference || typeof userPreference == "object" && Object.keys(userPreference).length == 0) {
            await dispatch(handleCreateUserPreference(currentUser?.id, { gradePref: _scale }))
        } else {
            await dispatch(handleUpdateUserPreference(currentUser?.id, { gradePref: _scale }))
        }
        // await getPref()
        setUpdatePrefLoader(false)
    }
    const handleUpdateorderByPref = async (_orderBy) => {
        setUpdateOrderByLoader(true)
        if (currentUser) {
            if (!userPreference || typeof userPreference == "object" && Object.keys(userPreference).length == 0) {
                await dispatch(handleCreateUserPreference(currentUser.id, { orderBy: _orderBy }))
            } else {
                await dispatch(handleUpdateUserPreference(currentUser.id, { orderBy: _orderBy }))
            }
            // await getPref()
        }
        setUpdateOrderByLoader(false)
    }
    const getAssignmentCreation = (assignmentdata, userdata) => {
        try {
            const is_game = assignmentdata && (assignmentdata.type === 'game' || assignmentdata.type === 'debate');
            if(is_game) {
                let quizdata = {
                    answers: [],
                    assignment: assignmentdata.assignment,
                    assignmentId: assignmentdata.session_id,
                    assignmentType: assignmentdata.type,
                    chapter: assignmentdata.bookId,
                    classId: currentClass.id,
                    complete: false,
                    createdAt: Date.now(),
                    feedback: '',
                    grade: '0',
                    graded: true,
                    isLate: false,
                    orgId: currentOrg?.id,
                    retakes: 0,
                    retaking: false,
                    submissions: 0,
                    timmer: 0,
                    user: userdata,
                    userId: userdata.id,
                    bookId: assignmentdata.bookId,
                    isTemp: true
                }
                return quizdata;
            }else{
                let _assignmentinfo = assignmentdata.data ?? {};
                let quizdata = {
                    answers: [],
                    assignment: _assignmentinfo,
                    assignmentId: _assignmentinfo.id,
                    assignmentType: _assignmentinfo.typeId,
                    chapter: _assignmentinfo.chapter,
                    classId: currentClass.id,
                    complete: false,
                    createdAt: Date.now(),
                    feedback: '',
                    grade: '0',
                    graded: true,
                    isLate: false,
                    orgId: currentOrg?.id,
                    retakes: assignmentdata.retakes,
                    retaking: false,
                    submissions: 0,
                    timmer: 0,
                    user: userdata,
                    userId: userdata.id,
                    bookId: _assignmentinfo.bookId,
                    isTemp: true
                }
                return quizdata;
            }
           
        } catch (error) {
            return {};
        }
    }

    const generateCSV = (studentScores, type, f_name , c_id) => {
        if (gradeView == "gradetable") {
            const newStudentScores = studentScores.map(student => {
                delete student.scores
                return student
            });
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(newStudentScores);
            XLSX.utils.book_append_sheet(wb, ws, `gradebook ${moment().format("DD-MM-YY hh:mm")}`);
            const file_name = f_name ? f_name : `gradebook ${moment().format("DD-MM-YY hh:mm")}.${type}`
            XLSX.writeFile(wb, file_name);
            const wbArrayBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });

            const file = new Blob([wbArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            uploadToStorage(file, 'xlsx', file_name,c_id).then(url => {
                console.log(url, "sss-xls")
            }).catch(err => {
                console.log(err, "err-slx")
            });
        } else {
            const wb = XLSX.utils.book_new();
            const exambook = [[], [], [], []]
            Object.keys(studentScores.firstRow).map(keys => exambook[0].push(keys))
            Object.values(studentScores.firstRow).map(value => exambook[1].push(value))
            Object.keys(studentScores.data[0]).map(keys => exambook[3].push(keys))
            map(studentScores.data, userd => {
                const d = []
                Object.values(userd).map(value => d.push(value))
                exambook.push(d)
            })
            var ws = XLSX.utils.aoa_to_sheet(exambook);
            XLSX.utils.book_append_sheet(wb, ws, `score`);
            const file_name = f_name ? f_name : `gradebook ${moment().format("DD-MM-YY hh:mm:SS")}.${type}`
            XLSX.writeFile(wb, file_name);
            const wbArrayBuffer = XLSX.write(wb, { type: 'array', bookType: type });
            const file = new Blob([wbArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            uploadToStorage(file, type, file_name,c_id).then(url => {
                console.log(url, "sss-csv")
            }).catch(err => {
                console.log(err, "err-csv")
            });
        }
    }
    const uploadToStorage = async (file, type , file_name,c_id) => {
        if (file) {
          const location = `/grade_book/${type || 'xlsx'}`;
          const id = file_name || `${moment().format("MMM DD, YYYY hh:mm A")}-${type}`;
          const fileRef = fireRef(storage, `${location}/${id}`);
          return new Promise((resolve, reject) => {
            try {
              const uploadTask = uploadBytesResumable(fileRef, file);
              uploadTask.on('state_changed',
                (snapshot) => {},
                (error) => {
                  console.error("Upload error:", error);
                  reject(error);
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    dispatch(handleCreateDownloadList({
                        title:file_name,
                        url:downloadURL, 
                        class_id:c_id?c_id:currentClass.id, 
                        created_by:currentUser.id
                    }))
                    resolve(downloadURL);
                  });
                }
              );
            } catch (error) {
              console.error("Upload error", error);
              reject(error);
            }
          });
        } else {
          return null;
        }
      };
    const getAssignment = () => {
        const uniqueActivity = uniqBy(classActivityData, "activityId");
        const uniqueQuiz = uniqBy(classQuizzesData, 'quizId');
        return [...uniqueActivity, ...uniqueQuiz].filter(k => {
            if (bookPermissions.length == 0) {
                return true;
            }
            const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "data.bookId")])
            if (bookaccess) {
                return bookaccess.available
            } else {
                return true;
            }
        })
    }

    const onDownloadFile = (type, data, f_name , c_id) => {
        if (type == "XLSX") {
            if (gradeView == "gradetable") {
                generateCSV(data?data:gradeData, 'xlsx',f_name,c_id);
            } else {
                generateCSV(examData, 'xlsx',f_name,c_id);
            }
        } else {
            if (gradeView == "gradetable") {
                generateCSV(data?data:gradeData, 'csv',f_name,c_id);
            } else {
                generateCSV(examData, 'csv',f_name,c_id);
            }
        }
    }

    const downloadNav = (activity, student, submission) => {
        return (
            <Menu>
                <Menu.Item key={"XLSX"} style={{ padding: '0px' }}>

                    <PaddedBtn onClick={() => {
                        if (gradeView == "gradetable") {
                            generateCSV(gradeData, 'xlsx');
                        } else {
                            generateCSV(examData, 'xlsx');
                        }
                    }}>
                        XLSX
                    </PaddedBtn>

                </Menu.Item>
                <Menu.Item key={"csv"} style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (gradeView == "gradetable") {
                            generateCSV(gradeData, 'csv');
                        } else {
                            generateCSV(examData, 'csv');
                        }
                    }}>
                        CSV
                    </PaddedBtn>
                </Menu.Item>
            </Menu>
        );
    };
    const gradeNav = () => {
        return (
            <Menu>
                {GRADE_PREF.map((item) => (<Menu.Item key={item.key} style={{ padding: '0px' }}>

                    <PaddedBtn onClick={() => {
                        handleUpdateGradePref(item.key)
                    }}>
                        {item.value}
                    </PaddedBtn>

                </Menu.Item>))}
            </Menu>
        );
    };
    const orderNav = () => {
        return (
            <Menu>
                {ORDER_BY.map((item) => (<Menu.Item key={item.key} style={{ padding: '0px' }}>

                    <PaddedBtn onClick={() => {
                        setOrderBy(item.key)
                        handleUpdateorderByPref(item.key)
                    }}>
                        {item.value}
                    </PaddedBtn>

                </Menu.Item>))}
            </Menu>
        );
    };
    const classNav = () => {
        return (
            <ClassMenu style={{ maxHeight:250 , overflow:"auto"}}>
                {map(userClasses,x => {
                    if (x.displayName) {
                        const archived = get(x, "archived", false);
                        if(archived) return;
                        const isSelected = currentClass.id=== x.id;
                        return (
                            <Menu.Item key={x.id}  style={{ color:isSelected?COLOR.white: COLOR.black100  , background:isSelected?COLOR.indigo100:"transparent" , height:30 }}>
                                <PaddedBtn
                                    onClick={() => {
                                        onswithClass(x.id,x.displayName)
                                        // dispatch(handleGetClass(x.id));
                                        // history.push(currentUser?.role === 'student' ? '/student/class' : `/admin/class?view=home`);
                                    }}>
                                    {x.displayName}
                                </PaddedBtn>
                            </Menu.Item>
                        )
                    }
                })}
            </ClassMenu>
        );
    };
    const getClassBook = () => {
        const classBookIds = map(currentClass.books, b => b.bookId);
        const classBookList = filter(booksAllList, b => classBookIds.includes(b.id));
        if (classBookList.length > 0) return classBookList;
        const userbookIds = map(_.get(userBooksList, "books", []), b => b.bookId);
        const userBooklist = filter(booksAllList, b => userbookIds.includes(b.id));
        if (userBooklist.length > 0) return userBooklist;
        return []
    }
    const syncToSchoology = async () => {
        let assignmentToSync = [];
        setSyncLoader(true);
        let userToSync = [];
        const uniqueActivity = uniqBy(classActivityData, "activityId");
        const studentSubmissionsData = groupBy(assignment_submission, 'userId');
        const uniqueQuiz = uniqBy(classQuizzesData, 'quizId');
        let _assignments = [...uniqueActivity, ...uniqueQuiz]
        assignmentToSync = filter(_assignments, (e) => e.sAssignmentId)
        userToSync = filter(uniqBy(filter(classUsers, x => x.role === 'student'), "username"), (e) => e.schoologyid)
        const enrollmentsObj = await client.getEnrollmentsBySectionId(currentClass?.sectionId)
        await map(userToSync, async (user) => {
            await map(assignmentToSync, async assignment => {
                const submissionData = find(studentSubmissionsData[user.id], ['assignmentId', get(assignment, "data.id")]);
                const value= submissionData && submissionData.grade >= 0 ? submissionData.grade : null;
                const exceptionStatus= get(submissionData, "exceptionStatus", 0) 
                let userEnrollment = find(enrollmentsObj?.enrollment, { uid: user.schoologyid });
                if ((value && value > 0 || exceptionStatus > 0) && userEnrollment && userEnrollment.id && assignment.sAssignmentId) {
                    let _data = {
                        "grades": {
                            "grade": [
                                {
                                    "type": "assignment",
                                    "assignment_id": assignment.sAssignmentId,
                                    "enrollment_id": userEnrollment.id,
                                    "grade": value,
                                    "exception": exceptionStatus || 0,
                                    "comment": "grade updated from lausd-grade-book"
                                }

                            ]
                        }
                    }
                    await client.updateGrades(currentClass?.sectionId, _data)
                }
            })
        })
        setSyncLoader(false);
    }

    const syncToCanvas = async()=>{
        syncGradeForCanvas()
    }

    const items = [
        {
            key: 'sub1',
            icon: <TuneIcon style={{ fontSize: 32, color:'#2d8a88'}} />,
            children: [
                {
                    key: 'Order_By_date',
                    label: 'Order By date',
                    children: [
                        {
                            key: 'Ascending',
                            label: 'Ascending',
                        },
                        {
                            key: 'Descending',
                            label: 'Descending',
                        },
                    ],
                },
                howGradeScale && {
                    key: 'Gradebook_with_A-F',
                    label: 'Gradebook with A-F',
                    children: [
                        {
                            key: 'Average_Grade',
                            label: 'Average Grade',
                        },
                        {
                            key: 'Grade_in',
                            label: 'Grade in 0-4',
                        },
                        {
                            key: 'Gradebook_with',
                            label: 'Gradebook with A-F',
                        },
                    ],
                },
                isSchoologyUser &&  {
                    key: 'Sync_to_Schoology',
                    label:'Sync to Schoology'
                },
                isCanvasUser &&  {
                    key: 'Sync_canvas',
                    label:'Sync canvas'
                },
                isCanvasUser &&  {
                    key: 'Sync_google_classroom',
                    label:'Sync google classroom'
                },
                {
                    key: 'Legends',
                    label: 'Legends',
                },
                {
                    key: 'download',
                    label: 'Download',
                    ... gradeView !== "gradetable"?{
                        children:[
                            {
                                key: 'XLSX',
                                label: 'XLSX',
                            },
                            {
                                key: 'CSV',
                                label: 'CSV',
                            },
                        ]
                    }:{ }
                },
                !isUserFromOtherPlatform && {
                    key: 'Add_Student',
                    label: 'Add Student',
                },
            ].filter(Boolean),
        },
    ];


    return (
        <>
            <Modal
                open={LegendsShow}
                onClose={() => setLegendsShow(false)}
            ><ModalContent>
                    <TbgLabels />
                </ModalContent>
            </Modal>
            <Drawer
                height='100%'
                destroyOnClose
                onClose={() => {
                    toggleChangePasswordDrawer(false);
                }}
                placement='top'
                open={changePasswordDrawer}
                key='changePassword'
            >
                <CenterContainer>
                    <ChangePasswordForm
                        userData={userData}
                        loading={loading}
                        onClosed={() => {
                            toggleChangePasswordDrawer(false);
                        }}
                    />
                </CenterContainer>
            </Drawer>
            <Drawer
                height='100%'
                destroyOnClose
                onClose={() => {
                    toggleUserDrawer(false);
                }}
                placement='top'
                open={userDrawer}
                key='quizNew'
            >
                <CenterContainer>
                    {maxExceedError && (
                        <Error>Unable to register student, max number of licenses exceeded.</Error>
                    )}
                    {userDrawer && <StudentForm
                        data={userData}
                        currentClass={currentClass}
                        newListBooks={getClassBook()}
                        setFormatPasswordError={setFormatPasswordError}
                        formatPasswordError={formatPasswordError}
                        isReviewAccount={isReviewAccount}
                        editing={editing}
                        loading={loading}
                        handleChange={(id, value, usernameError) => updateUserData(id, value, usernameError)}
                        onClosed={() => {
                            setUserData({});
                            toggleUserDrawer(false);
                        }}
                    />}
                </CenterContainer>
            </Drawer>
            <Drawer
                height='100%'
                destroyOnClose
                onClose={() => {
                    toggleAssignmentDrawer(false);
                    setAssignmentData({});
                    setSelectedAssignmentSubmissions([])
                }}
                placement='top'
                open={assignmentDrawer}
                key='assignmentNew'
            >
                {assignmentDrawer ? <AssignmentReview
                    booksAllList={booksAllList}
                    data={assignmentData}
                    usersSubmissionData={selectedAssignmentUserSubmission}
                    currentClass={currentClass}
                    handleClose={() => {
                        setLoading(true)
                        toggleAssignmentDrawer(false)
                        setLoading(false)
                        fetchAllData();
                    }}
                    scale_1_5={userPreference?.gradePref == "scale_1_5"}
                    gradebook_a_f={userPreference?.gradePref === "gradebook_a_f"}
                /> : <></>}
            </Drawer>
            <Drawer
                height='100%'
                destroyOnClose
                onClose={() => {
                    setStudentAssignmentDrawer(false);
                    setAssignmentData({});
                    setSelectedAssignmentSubmissions([])
                }}
                placement='top'
                open={studentAssignmentDrawer}
                key='studentReviewNew'
            >
                {studentAssignmentDrawer ? <StudentReview
                    booksAllList={booksAllList}
                    data={selectedUserData}
                    // usersSubmissionData={selectedUserSubmission}
                    currentClass={currentClass}
                // handleClose={() => {
                //     setLoading(true)
                //     toggleAssignmentDrawer(false)
                //     setLoading(false)
                //     fetchAllData();
                // }}
                scale_1_5={userPreference?.gradePref == "scale_1_5"}
                gradebook_a_f={userPreference?.gradePref === "gradebook_a_f"}
                /> : <></>}
            </Drawer>
            <Wrapper>

                <Row gutter={[0, 40]}>
                    <Col span={24}>
                        <GradebookCalendar assignments={getAssignment()} onSelect={id => setSelectedAssignment(id)} booksAllList={booksAllList} />
                    </Col>
                </Row>
                {selectedAssignment && (
                    <>
                        <Row gutter={[0, 40]}>
                            <Col span={24}>
                                {selectedAssignment && (
                                    <>
                                        <Header>
                                            Submissions
                                        </Header>
                                        {studentSubmissions&&studentSubmissions[selectedAssignment]?.length > 0 ? (
                                            studentSubmissions[selectedAssignment].map(x => (
                                                <Submission
                                                    key={x.id}
                                                    onClick={() => reviewAssignment(x, getAssignmentCreation({ data: get(x, "assignment") }, get(x, "user")), filter(classUsers, x => x.role === 'student' && x.active), groupBy(assignment_submission, 'userId'))}
                                                    data={x}
                                                />
                                            ))
                                        ) : (
                                            <EmptyState message="No submissions for selected assignment" />
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
                <Row gutter={[0, 40]}>
                    <Col span={24}>
                        <Header className='gradeBokHdr'>
                            <div style={{display:"flex",flexDirection:"row", alignItems:"center"}}>
                                <b>Grades &nbsp;  &nbsp;   <em> No. of students - {get(filter(classUsers, x => x.role === 'student'), "length", 0)} </em> </b>  &nbsp;  &nbsp;
                                <Dropdown className="selctChatDrop" overlay={classNav()} placement="bottomLeft" toggle={['click']}>
                                    <ClassSelector className="selctChatOptn" onClick={e => e.preventDefault()}>
                                        <MenuBook className="selctChatMnu" /> {get(currentClass, "displayName", "")} <CaretDownOutlined className="selctChatOut" />
                                    </ClassSelector>
                                </Dropdown>
                            </div>
                            <Menu selectable={false} className='menuListMobile' style={{backgroundColor:'transparent'}} onClick={onClickMenu} selectedKeys={[current]} mode="horizontal" items={items} />
                            {/* <Wrap className='gradeBokWrp hideMenuSection'> */}
                            <Wrap className='gradeBokWrp'>


                               {!isCurrentSem? <Tooltip title={editPrevSemData?"Previous semester data edit enabled":"Previous semester data edit disabled"}>
                                    <Button onClick={() => {
                                        setEditPrevSemData(!editPrevSemData)
                                        localStorage.setItem('editPrevSemData', !editPrevSemData)
                                    }}
                                        style={{ marginRight: '16px', color: !editPrevSemData ? COLOR.green100 : COLOR.red }}
                                    >
                                        <EditOutlined style={{ marginRight: '4px' }} />
                                        {editPrevSemData ? "Disable Edit" : "Enable Edit"}
                                    </Button>
                                </Tooltip>:null}
                          
                                <Button>
                                  
                                </Button>
                                <Dropdown overlay={orderNav()} placement="bottomLeft" trigger={['click']}>
                                    <Button style={{ marginRight: '16px', display: "flex", flexDirection: "row", alignItems: "center" }}
                                        disabled={updateorderByLoader}
                                        onClick={(e) => {
                                            e.preventDefault()
                                        }} >
                                        <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                        {updateorderByLoader && <Loading size={15} />}
                                        Order By date ({orderBy})
                                    </Button>
                                </Dropdown>
                                {howGradeScale&&
                                    <Dropdown overlay={gradeNav()} placement="bottomLeft" trigger={['click']}>
                                        <Button style={{ marginRight: '16px', display: "flex", flexDirection: "row", alignItems: "center" }}
                                            disabled={updatePrefLoader}
                                            onClick={(e) => {
                                                e.preventDefault()
                                            }} >
                                            <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                            {updatePrefLoader && <Loading size={15} />}
                                            {scale_1_5 ? GRADE_PREF[1].value : gradebook_a_f ? GRADE_PREF[2].value : GRADE_PREF[0].value}
                                        </Button>
                                    </Dropdown>

                                }
                                {isSchoologyUser &&
                                    <Button style={{ marginRight: '16px', display: "flex", flexDirection: "row", alignItems: "center" }}
                                        onClick={(e) => {
                                            syncToSchoology()
                                        }} >
                                        <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                        {syncLoader ? <Loading size={20}  /> :null} Sync to Schoology
                                    </Button>
                                }
                                {isCanvasUser &&
                                    <Button style={{ marginRight: '16px', display: "flex", flexDirection: "row", alignItems: "center" }}
                                        onClick={(e) => {
                                            syncToCanvas()
                                        }} >
                                        <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                        {syncLoader ? <Loading size={20}  /> :null} Sync canvas
                                    </Button>
                                }
                                {isGoogleUser &&
                                    <Button style={{ marginRight: '16px', display: "flex", flexDirection: "row", alignItems: "center" }}
                                        onClick={(e) => {
                                            handleGoogleClassRomm()
                                        }} >
                                        <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                        {syncLoader ? <Loading size={20}  /> :null} Sync google classroom
                                    </Button>
                                }
                                <Button onClick={() => {
                                    handleLegendsShow();
                                }} style={{ marginRight: '16px' }}>
                                    <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                    Legends
                                </Button>
                                {gradeView !== "gradetable"?<Dropdown overlay={downloadNav(classActivityData.concat(classQuizzesData), filter(classUsers, x => x.role === 'student'), groupBy(assignment_submission, 'userId'))} placement="bottomLeft" trigger={['click']}>
                                    <Button onClick={e => e.preventDefault()} style={{ marginRight: '16px' }}>
                                        <DownloadOutlined style={{ marginRight: '4px' }} />
                                        Download
                                    </Button>
                                </Dropdown>:
                                 <GradeDownloader
                                    onDownoad={onDownloadFile}
                                    ref={gradeDownloadRef}
                                    scale_1_5={userPreference?.gradePref == "scale_1_5"}
                                    gradebook_a_f={userPreference?.gradePref === "gradebook_a_f"}
                                    schoologyGradeCategory={schoologyGradeCategory}
                                />}
                                {!isUserFromOtherPlatform && <Button onClick={() => {
                                    setEditing(false);
                                    setUserData({
                                        username: '',
                                        password: ''
                                    });
                                    toggleUserDrawer(true);
                                }}>
                                    <PlusSquareOutlined style={{ marginRight: '4px' }} />
                                    Add Student
                                </Button>}
                            </Wrap>
                        </Header>
                        <GradeTable
                            activities={activities}
                            currentClass={currentClass}
                            currentOrg={currentOrg}
                            dataLoading={loading}
                            reviewAssignment={reviewAssignment}
                            userReviewAssignment={userReviewAssignment}
                            setUserData={setUserData}
                            toggleUserDrawer={toggleUserDrawer}
                            toggleChangePasswordDrawer={toggleChangePasswordDrawer}
                            quizzes={quizzes}
                            submissions={assignment_submission}
                            orderBy={orderBy}
                            users={classUsers}
                            setEditing={setEditing}
                            setUserBook={setUserBook}
                            booksAllList={booksAllList}
                            isReviewAccount={isReviewAccount}
                            handleEditQuiz={handleEditQuiz}
                            handleEditActivity={handleEditActivity}
                            currentUser={currentUser}
                            userPreference={userPreference}
                            classActivities={classActivityData}
                            getAssignmentCreation={getAssignmentCreation}
                            classQuizzes={classQuizzesData}
                            gradeView={gradeView}
                            setGradeViw={setGradeViw}
                            setExamData={setExamData}
                            gradeData={gradeData}
                            setGradeData={setGradeData}
                            editPrevSemData={editPrevSemData}
                            isCurrentSem={isCurrentSem}
                            setCurrentSem={setCurrentSem}
                        />
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
Gradebook.propTypes = {
    data: arrayOf(shape({})),
    header: string,
};

//  -- Default props ------------------
Gradebook.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentUser: state.currentUser,
    classUsers: state.classUsers,
    userBooksList: state.userBooksList,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
    userPreference: state.userPreference,
    selectedUser: state.selectedUser,
    activities: state.activities,
    quizzes: state.quizzes,
    schoologyAuth: state.schoologyAuth
});

export default connect(mapStateToProps, null)(Gradebook);
