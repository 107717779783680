//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as PostsActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create post --------------------
export const handleCreatePost = (data) => async(dispatch) => {
    await dispatch(PostsActions.createPost());

    try {
        const post = await API.post('postsRDS', `/posts`, {
            body: { ...data }
        });
       if(typeof post == "object"&&!post.error){
        await dispatch(PostsActions.createPostSuccess(post));
       }
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(PostsActions.createPostFail(error));
    }
};

//  -- Delete post --------------------
export const handleDeletePost = (postID) => async(dispatch) => {
    await dispatch(PostsActions.deletePost());

    try {
        await API.del('postsRDS', `/posts/${postID}`);

        await dispatch(PostsActions.deletePostSuccess({ id: postID }));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(PostsActions.deletePostFail(error));
    }
};


//  -- List class posts ---------------
export const handleListClassPosts = (classID) => async(dispatch) => {
    await dispatch(PostsActions.listClassPosts());
    try {
        let postData = [];
        const postsRDS = await API.get('postsRDS', `/postsByClass/${classID}`);
        if(Array.isArray(postsRDS))
        {
            postData=postsRDS
        }
        await dispatch(PostsActions.listClassPostsSuccess(postData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(PostsActions.listClassPostsFail(error));
    }
};

//  -- List organization posts --------
export const handleListOrgPosts = (orgID) => async(dispatch) => {
    await dispatch(PostsActions.listOrgPosts());

    try {
        let postData = [];
        const postsRDS = await API.get('postsRDS', `/postsByOrg/${orgID}`);
        if(Array.isArray(postsRDS))
        {
            postData=postsRDS
        }
        await dispatch(PostsActions.listOrgPostsSuccess(postData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(PostsActions.listOrgPostsFail(error));
    }
};
