//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const hyperlinkInit = {
    view: false,
    hyperlinks: []
};


export const hyperlikModalInfo = (state = hyperlinkInit, action) => {
    switch (action.type) {
        case ActionTypes.hyperlink.invokeHyperlinkModal:
            return ({ ...state, view: true, hyperlinks: action.payload });
        case ActionTypes.hyperlink.revokeHyperlinkModal:
            return ({ ...state, view: false, hyperlinks: action.payload });
        default:
            return state;
    }
};


