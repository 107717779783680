
 const createQuizActivityStruct=[
    "quizId",
    "activityId",
    "bookId",
    "chapter",
    "classId",
    "displayName",
    "dueDate",
    "id",
    "page",
    "questions",
    "typeId"
   ]
   const skipforQuiz=["activityId"]
   const skipforActivity=["quizId"]
 const hasKeyAndValue=(data,key)=>{
     return data[key]&&data[key].length>0 
   }
export const validateQuizeActivityData=(data,type,keyArray)=>{
  let isValid=true;
  let reason=""
  if(typeof data=="object"&&Object.keys(data).length>0){
      if(keyArray&&Array.isArray(keyArray)){
        keyArray.map(item=>{
            if(!hasKeyAndValue(data,item)){
                isValid=false
                reason=`${item} unavailable`
            }
          })  
      }
      else{
        createQuizActivityStruct.map(item=>{
            if(type=="quiz"){
                if(skipforQuiz.includes(item)){
                    return;
                }
            } else if(type=="activity"){
              if(skipforActivity.includes(item)){
                  return;
              }
            }
            if(!hasKeyAndValue(data,item)){
                isValid=false
                reason=`${item} unavailable`
            }
          })  
      }
  }else{
      isValid =false
  }
  return {isValid,reason};
}