//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const mainbookPopup = {
   open:false,
   pages:[]
};

const sentencePopup = {
    open:false,
    pages:[]
}

export const mainbookPageView = (state = mainbookPopup, action) => {
    switch (action.type) {
        case ActionTypes.alert.invokeMainBook:
            return ({ ...state, ...action.payload });
        case ActionTypes.alert.removeMainBook:
            return ({ ...state, ...action.payload });
        default:
            return state;
    }
};

export const sentenceView = (state = sentencePopup, action) => {
    switch (action.type) {
        case ActionTypes.alert.invokeSentence:
            return ({ ...state, ...action.payload });
        case ActionTypes.alert.removeSentence:
            return ({ ...state, ...action.payload });
        default:
            return state;
    }
};

const ebookGalleryViewInit = {
   open:false,
   pages:[]
};


export const ebookGalleryView = (state = ebookGalleryViewInit, action) => {
    switch (action.type) {
        case ActionTypes.alert.invokeEbookGalleryViewer:
            return ({ ...state, ...action.payload });
        case ActionTypes.alert.removeEbookGalleryView:
            return ({ ...state, ...action.payload });
        default:
            return state;
    }
};


