//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { Modal } from "@material-ui/core";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";

//  -- Thunks -------------------------
import { Form, Switch } from 'antd';
import CotationLoader from 'components/CotationLoader';
import FunVideoSetting from "./addBook/FunVideoSetting";
import { WelcomeVideoNew } from "./addBook/welcomeVideoNew";
import { useWelcomeVideos } from "store/welcomeVideo/selectors";
import { handleUpdateVideos } from "store/resources/thunks";
import DemoVideos from "./addBook/DemoVideoSetting";

// const MENU = [
//   { id: "fun_videos_teacher", value: "Fun Videos Teacher" },
//   { id: "fun_videos_student", value: "Fun Videos Student" },
// ];
const MENU = [
  { id: "welcome_video", value: "Welcome Video" },
  { id: "fun_videos_teacher", value: "Fun Videos Teacher" },
  { id: "fun_videos_student", value: "Fun Videos Student" },
  { id: "demo_videos", value: "Demo Video" }
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0px;
  width: 100%;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const VideoContainer = styled.div`
  height: auto;
  flex: 1;
  justify-content: center;
`;
const ContainerTabs = styled.div`
  margin-top:30px;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------

const ActivateDeactive = ({ item , dispatch }) => {
  const [switch_active, setSwitchActive] = useState(false);
  useEffect(() => {
      if (item && item.metadata) {
          setSwitchActive(item.metadata.active_mode === 'fun_video')
      }
  }, [item]);
  const onCreateOrUpdate = async (state) => {
      setSwitchActive(state)
      if (item.id) {
          const data = item;
          data.metadata.active_mode = state?'fun_video':'welcome_video';
          await dispatch(handleUpdateVideos(data))
      }
  }
  return (<Form style={{ height: 30 }}>
      <Form.Item label="Welcome video / Fun video" valuePropName="checked" >
          <Switch checked={switch_active} onChange={onCreateOrUpdate} />
      </Form.Item>
  </Form>)
}

const SettingsContainer = ({
  currentOrg,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const welcomeVideo = useWelcomeVideos()
  const [dataTab, setDataTab] = useState("");
  const [bookCreateLoader, setBookCreateLoader] = useState(false);
  const cotationRef = useRef(null);

  if (!cotationRef.current) {
    cotationRef.current = getRandomCotation()
  }
  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
    setDataTab(MENU[0].id);
  }, []);

  function navigationToMenu(id) {
    setDataTab(id);
  }
  return (
    <>
      <Modal
        open={bookCreateLoader}
      >
        <VideoContainer>
          <CotationLoader cotation={cotationRef.current} messages={"Please wait while the book is being created."} />
        </VideoContainer>
      </Modal>
      <Wrapper>
        {/* <ActivateDeactive item={welcomeVideo} dispatch={dispatch} /> */}
        <MenuWrapper top={"10px"} style={{ width: "100%" }}>
          {MENU.map((x) => (
            <MenuBtn style={{ borderBottom: dataTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenu(x.id)}>
              {x.value}
            </MenuBtn>
          ))}
        </MenuWrapper>
        {dataTab == MENU[0].id && (
          <ContainerTabs>
            <WelcomeVideoNew />
          </ContainerTabs>
        )}
        {dataTab == MENU[1].id && (
          <ContainerTabs>
            <FunVideoSetting role={"teacher"} />
          </ContainerTabs>
        )}
        {dataTab == MENU[2].id && (
          <ContainerTabs>
            <FunVideoSetting role={"student"} />
          </ContainerTabs>
        )}
        {dataTab == MENU[3].id && (
         <ContainerTabs>
           <DemoVideos />
         </ContainerTabs>
       )}
      </Wrapper>
    </>
  );
};

//  -- Prop types ---------------------
SettingsContainer.propTypes = {};

//  -- Default props ------------------
SettingsContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  classes: state.classes,
  currentOrg: state.currentOrg,
});

export default connect(mapStateToProps, null)(SettingsContainer);
