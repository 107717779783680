//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



export const googleAuthKey = (state=null,action) =>{
    switch(action.type){
        case ActionTypes.googleclasses.setGoogleAuth:
            return action.payload;
        case ActionTypes.googleclasses.updateGoogleAuth:
            return action.payload;
        case ActionTypes.googleclasses.clearGoogleAuth:
            return null;
        default: 
            return state;
    }
}