import React, { useCallback, useEffect, useRef, useState, useReducer, useLayoutEffect, useMemo } from "react";

const rand = (m, M) => Math.random() * (M - m) + m;

const WheelComponent = ({ segments, onFinished, dimensions }) => {
    const [result, setResult] = useState(null);
    const runningAudio = useMemo(() => new Audio(require(`utils/assets/audio/start_audio.mp3`)), []);
    const endAudio = useMemo(() => new Audio(require(`utils/assets/audio/end_audio.mp3`)), []);
    runningAudio.loop = true;
    runningAudio.volume = 1;

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const tot = segments.length;
    const canvasRef = useRef(null);
    const [ctx, setCtx] = useState(null);

    const dia = ctx ? ctx.canvas.width : 400;
    const rad = dia / 2;
    const PI = Math.PI;
    const TAU = 2 * PI;
    const arc = TAU / tot;
    const friction = 0.90;  // 0.995=soft, 0.99=mid, 0.98=hard
    const angVelMin = 0.002; // Below that number will be treated as a stop
    let angVelMax = 0;
    let angVel = 0;
    let ang = 0;
    let isSpinning = false;
    let isAccelerating = false;
    let animFrame = null;

    const getIndex = () => Math.floor(tot - ang / TAU * tot) % tot;

    useEffect(() => {
        runningAudio.pause();
        endAudio.pause();
        return () => {
            runningAudio.pause();
            endAudio.pause();
            cancelAnimationFrame(animFrame);
        };
    }, []);

    useEffect(() => {
        if (canvasRef.current) {
            setCtx(canvasRef.current.getContext('2d'));
        }
    }, [canvasRef]);

    const clearCanvas = () => {
        if (ctx) {
            ctx.save();
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.restore();
        }
    };

    const drawSector = (sector, i) => {
        if (ctx) {
            const ang = arc * i;
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = sector.color;
            ctx.moveTo(rad, rad);
            ctx.arc(rad, rad, rad, ang, ang + arc);
            ctx.lineTo(rad, rad);
            ctx.fill();

            ctx.translate(rad, rad);
            ctx.rotate(ang + arc / 2);
            ctx.textAlign = "right";
            ctx.fillStyle = "#fff";
            ctx.font = "bold 12px sans-serif";
            ctx.fillText(sector?.label, rad - 20, 5);
            ctx.restore();
        }
        forceUpdate();
    };

    const rotate = () => {
        if (ctx) {
            ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`;
        }
    };

    const frame = () => {
        if (!isSpinning) return;
        if (angVel >= angVelMax) isAccelerating = false;
        const runningSpeed = (angVel / angVelMax) * 1000;
        if (runningSpeed < 160 && isAccelerating) {
            runningAudio.playbackRate = 1;
        } else if (runningSpeed < 100 && !isAccelerating) {
            runningAudio.playbackRate = 0.5;
        } else if (runningSpeed < 150 && !isAccelerating) {
            runningAudio.playbackRate = 1;
        } else if (runningSpeed < 250 && !isAccelerating) {
            runningAudio.playbackRate = 1.5;
        } else if (runningSpeed < 500) {
            runningAudio.playbackRate = 2;
        } else if (runningSpeed < 800) {
            runningAudio.playbackRate = 3;
        } else if (runningSpeed < 1000) {
            runningAudio.playbackRate = 4;
        } else {
            runningAudio.playbackRate = 5;
        }
        if (isAccelerating) {
            angVel = angVel || angVelMin;
            angVel *= 1.06;
        } else {
            angVel *= friction;
            if (angVel < angVelMin) {
                isSpinning = false;
                angVel = 0;
                cancelAnimationFrame(animFrame);
                endAudio.play();
                runningAudio.pause();
                const sector = segments[getIndex()];
                onFinished(sector?.label);
                setResult(sector?.label);
                setTimeout(() => setResult(null), 2000);
            }
        }

        ang += angVel;
        ang %= TAU;
        rotate();
    };

    const engine = () => {
        frame();
        animFrame = requestAnimationFrame(engine);
    };

    const drawer = () => {
        if (isSpinning || result) return;
        isSpinning = true;
        isAccelerating = true;
        angVelMax = rand(0.25, 0.40);
        runningAudio.play();
        engine();
    };

    const handleTouchDrawer = (event) => {
        event.preventDefault();
        drawer()
    }

    useLayoutEffect(() => {
        clearCanvas();
        if (segments && segments.length) {
            segments.forEach(drawSector);
        }
    }, [segments, ctx]);

    const getCalculatedHeight = () => {
   
        if (300 < dimensions.height && dimensions.height < 791) {
            return dimensions?.height - 150;
        }
        else if (791 < dimensions.height && dimensions.height < 1529) { 
            return dimensions?.height - 300;
        }
        else if (dimensions.height > 1547) { 
            return 1317;
        }
    }

    return (
        <div id="newWheelOfFortune" onClick={drawer} onTouchStart={handleTouchDrawer}>
            {result
             ? (
                <div  style={{width: "100%" ,height: getCalculatedHeight()}} id="newWheelOfFortuneResult">
                    <div  id="newWheelOfFortuneResultText">{result}</div>
                </div>
            ) : null}
            <canvas style={{height: getCalculatedHeight() }} ref={canvasRef} id="newWheel" width="400" height="400"></canvas>
            <div id="newSpin">SPIN</div>
        </div>
    );
};

export default WheelComponent;
