//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash"
//  -- Actions ------------------------
import * as Sentry from "@sentry/react";

import * as action from "./actions"


export const handleSyncTeacher = (id) => async (dispatch) => {
    try {
        const oneGameSyncData = await API.post('one_game', `/sync_teacher`, {
            body: {
                userId: id
            }
        });
        return oneGameSyncData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};



export const handleAutologinOneGame = (userId, classId, metadata) => async (dispatch) => {
    try {
        const oneGameSyncData = await API.post('one_game', `/session_sync`, {
            body: {
                "uri": window.location.href,
                "userId": userId,
                "classId": classId,
                metadata
            }
        });
        return oneGameSyncData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleSyncBookCategories = (userId) => async (dispatch) => {
    try {
        const oneGameSyncData = await API.post('one_game', `/user_category_sync`, {
            body: {
                "userId": userId
            }
        });
        return oneGameSyncData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleListGame = (userId, bookId, pageId, type, game_type) => async (dispatch) => {
    try {
        const oneGameSyncData = await API.post('one_game', `/list_games`, {
            body: {
                userId,
                bookId,
                pageId,
                type,
                game_type
            }
        });
        return oneGameSyncData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleStartGame = (userId, gameId, classId , game_config ) => async (dispatch) => {
    try {
        const oneGameSyncData = await API.post('one_game', `/start_game_session`, {
            body: {
                userId,
                gameId,
                classId,
                game_config
            }
        });
        return oneGameSyncData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleGameByCode = (userId, pin) => async (dispatch) => {
    try {
        const oneGameSyncData = await API.post('one_game', `/get_game_code`, {
            body: {
                userId,
                pin
            }
        });
        return oneGameSyncData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleGameBySessionId = (userId, session_id) => async (dispatch) => {
    if(!userId || !session_id)  return;
    try {
        const oneGameSyncData = await API.post('one_game', `/get_game_session_by_id`, {
            body: {
                userId,
                session_id
            }
        });
        return oneGameSyncData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleGameDelete = (userId, id) => async (dispatch) => {
    try {
        const oneGameDeleteData = await API.post('one_game', `/delete_game`, {
            body: {
                userId,
                id,
            }
        });
        return oneGameDeleteData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleListClassGameActivity = (class_id) => async (dispatch) => {
    try {
        const class_game_activity = await API.post('one_game', `/list_class_game`, {
            body: {
                class_id
            }
        });
        if (Array.isArray(class_game_activity)) {
            dispatch(action.setClassGameActivity(class_game_activity))
        }
        return class_game_activity;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error,"rttot")
        return null;
    }
};

