import React from 'react';
import { Button, Modal } from "@material-ui/core";
import styled from "styled-components";
import COLOR from "utils/constants/color";

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;

export default function RetakeModal({ isRetakeOpen, onClose, onSubmit }) {
  const handleCancel = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    onClose();
    onSubmit();
  };

  return (
    <Modal
      open={isRetakeOpen}
      onClose={onClose}
      style={{ height: "100%", width: "100%" }}
    >
      <ModalContent>
        You have already completed this assignment. Are you sure you want to retake it?
        <ModalRow>
          <Button
            style={{ marginRight: '4px' }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleConfirm}
          >
            Yes
          </Button>
        </ModalRow>
      </ModalContent>
    </Modal>
  );
}
