import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    makeStyles
} from "@material-ui/core";
import {
    Delete,
    InsertLink
} from "@material-ui/icons";
import { Col, Row } from "antd";
import { cloneDeep, get, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HyperLinkForm = ({ hyperlinks, pageId, onChangeHyperlink }) => {
    const [hyperLinkData, setHyPerlinkData] = useState(null);
    const [error, setError] = useState([]);
    const classes = useStyles();
    const scrollRef = useRef();
    useEffect(() => {
      if (hyperlinks) {
        setHyPerlinkData(hyperlinks);
      } else {
        setHyPerlinkData({
          page: pageId,
          links: [
            {
              href: "",
              type: "main_pg",
              title: "",
            },
          ],
        });
      }
    }, [hyperlinks, pageId]);
    const onChangeLink = (index, key, value) => {
      const h = cloneDeep(hyperLinkData);
      h.links[index][key] = value;
      setHyPerlinkData(h);
      setError([]);
    };
    const addHyperlink = () => {
      const h = cloneDeep(hyperLinkData);
      h.links.push({
        href: "",
        type: "main_pg",
        title: "",
      });
      setHyPerlinkData(h);
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
        }
      }, 500);
    };
    const removeHyperLink = (i) => {
      const h = cloneDeep(hyperLinkData);
      h.links.splice(i, 1);
      setHyPerlinkData(h);
    };
    const onUpdate = () => {
      const _error = [];
      map(get(hyperLinkData, "links"), (links, i) => {
        _error.push(!links.href);
      });
      if (_error.filter((k) => k).length == 0) {
        onChangeHyperlink(hyperLinkData);
      }
      setError(_error);
    };
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<InsertLink />}
          onClick={addHyperlink}
          style={{ marginTop: 10 }}
        >
          Add links
        </Button>
        <ModalConfigArea ref={scrollRef}>
          {map(get(hyperLinkData, "links"), (links, i) => (
            <LinkArea key={i}>
              <Row
                gutter={[24, 24]}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <IconButton
                  onClick={() => removeHyperLink(i)}
                  style={{ padding: 0, marginRight: 10 }}
                >
                  <Delete color="secondary" />
                </IconButton>
              </Row>
              <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <TextField
                    id="title"
                    name="title"
                    label="Title"
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    variant="outlined"
                    onChange={(e) => {
                      onChangeLink(i, "title", e.target.value);
                    }}
                    value={get(links, "title", "")}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <TextField
                    id="hypelink"
                    name="hypelink"
                    label="Link"
                    error={error[i]}
                    InputLabelProps={{ shrink: true, required: true }}
                    type="text"
                    variant="outlined"
                    onChange={(e) => {
                      onChangeLink(i, "href", e.target.value);
                    }}
                    value={get(links, "href", "")}
                  />
                </Col>
              </Row>
              <Row
                gutter={[24, 24]}
                style={{ marginTop: 20, marginRight: 0, marginLeft: 0 }}
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    value={links.type || "main_pg"}
                    onChange={(e) => {
                      onChangeLink(i, "type", e.target.value);
                    }}
                    label="Type"
                  >
                    <MenuItem value={"main_pg"}>Page</MenuItem>
                    <MenuItem value={"teacher_ed"}>Teacher edition</MenuItem>
                  </Select>
                </FormControl>
              </Row>
            </LinkArea>
          ))}
        </ModalConfigArea>
        {error.filter((k) => k).length > 0 ? (
          <FormHelperText error>
            All links are requird , Please fill it or remove it.
          </FormHelperText>
        ) : null}
        <Row
          gutter={[24, 24]}
          style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
        >
          <Button
            fullWidth
            onClick={onUpdate}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
        </Row>
      </div>
    );
  };

export default HyperLinkForm;