//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Flag, Check, Visibility    } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Dropdown, Menu } from 'antd';
import { Tooltip } from '@material-ui/core';
//  -- Thunks -------------------------
import { handleUpdateSubmission , handleUpdateGrade } from 'store/submissions/thunks';
import DescriptionIcon from '@material-ui/icons/Description';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { useEffect } from 'react';

import { handleListClassSubmissions } from 'store/submissions/thunks';
import Schoology from 'utils/Schoology';
import { find, map ,get , debounce } from 'lodash'
import { TootipWrapper } from 'components/TooltipWrapper';
const COLORSTATUS = ["", COLOR.greentrue, COLOR.orange100, COLOR.red]
const Poligon = styled.div`
    display:flex;
    flex-direction:row;
    clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
    height: 18px;
    width: 18px;
    margin-right: 5px;
    background-color:${p => p.bgColor ? p.bgColor : COLOR.green};
`;




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.button`
    align-items: center;
    background-color: ${COLOR.green100};
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    display: flex;
    height: 24px;
    margin-left: 4px;
    justify-content: center;
    opacity: 0;
    outline: none;
    padding: 2px;
    transition: all 300ms ease;
    width: 24px;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`;

const Input = styled.input`
    border: 1px solid;
    border-radius: 3px;
    box-shadow: none;
    font-size: inherit;
    outline: none;
    padding: 0px 4px;
    transition: all 300ms ease;
    text-align:center;
    width: 40px;
`;

const Form = styled.form`
    algin-items: center;
    color: ${p => p.graded ? 'inherit' : 'red'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;

    &:hover ${Input} {
        border: 1px solid ${p => p.disabled ? 'white' : 'white'};
    }

    &:hover ${Button} {
        opacity: ${p => p.disabled ? 0 : 1};
        color: white;
    }
`;
const CheckBox = styled.div`
    align-items: center;
    height: 24px;
    display: flex;
    justify-content: center;
    width: 24px;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    transition: all 300ms ease;
    `;
const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    `;
const TbgArea = styled.div`
    position: absolute;
    bottom: -12px;
    font-size: 8px;
    color:${COLOR.black}
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Grade editing form
//
//  -- Description
//  Allows editing of grades/submissions
//
//  -- Props
//  ...
//
//  -- State
//  ...
//

const hasGradeInA_F = (val) => {
    if (typeof val === "string") {
        const hasInGrade =  [{key:'A',value:90},{key:'B',value:70},{key:'C',value:50},{key:'D',value:30},{key:'F',value:10}].find(k => k.key.toLowerCase() === val.toLowerCase())
        if (hasInGrade) {
            return ({
                value: hasInGrade.value,
                hasValue: true
            })
        }
        return ({
            value: val,
            hasValue: false
        })
    } else {
        return ({
            value: val,
            hasValue: false
        })
    }

}


const GradeEditor = ({
    assignmentId,
    data,
    reviewAssignment,
    submission,
    userId,
    value,
    isReviewAccount,
    disabled,
    scale_1_5,
    gradebook_a_f,
    tempData,
    assignment,
    user,
    currentClass,
    assignments,
    studentSubmissions,
    assignemnt,
    onFlagMenuViewChange,
    isTbg,
    handleGradeValueShow,
    ungraded
}) => {
    const dispatch = useDispatch();
    const schoologyAuth = useSelector(state => state.schoologyAuth);
    const client = new Schoology(schoologyAuth);
    const [tempValue, setTempValue] = useState(value);
    const [tempFlag, setTempFlag] = useState(submission?.exceptionStatus || 0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const editValue = () => {
        const input = document.getElementById(assignmentId + userId);
        input.focus();
        input.select();
    };
    useEffect(() => {
        setTempValue(value);
        setTempFlag(submission?.exceptionStatus || 0);
    }, [value, submission])
    const handleChange = (e) => {
        e.preventDefault();
        let _value = e.target.value;
        const af =  hasGradeInA_F(_value)
        const isAf = af.hasValue && !!gradebook_a_f;
        const isScale = scale_1_5 && _value <= 4 && _value >= 0
        const isAv = !scale_1_5 && !gradebook_a_f && _value <= 100 && _value >= 0
        if (isScale ||isAf || isAv || _value==="") {
            setTempValue(_value);
        }        
    };
    const updateGradetoSchoology = (_val, statusCode) => {
        if (currentClass && currentClass.sectionId && user && user.schoologyid) {
            try {
                client.getEnrollmentsBySectionId(currentClass?.sectionId).then(async res => {
                    if (res && res.enrollment) {
                        let userEnrollment = find(res.enrollment, { uid: user.schoologyid });
                        if (!assignment.sAssignmentId) {
                            const _assignments = await client.getSectionAssignment(currentClass?.sectionId);
                            const all_assignment = await client.getSectionAssignmentWithLimit(currentClass?.sectionId, _assignments.total);
                            const assignmentf = find(all_assignment?.assignment, { title: assignment.displayName });
                            if (assignmentf) {
                                assignment.sAssignmentId = assignmentf.id;
                            }
                        }
                        if (userEnrollment && assignment.sAssignmentId) {
                            client.getSectionAssignmentById(currentClass?.sectionId, assignment.sAssignmentId).then(res => {
                                if (res && res.id) {
                                    let _data = {
                                        "grades": {
                                            "grade": [
                                                {
                                                    "type": "assignment",
                                                    "assignment_id": assignment.sAssignmentId,
                                                    "enrollment_id": userEnrollment.id,
                                                    "grade": _val,
                                                    "exception": statusCode || 0,
                                                    "comment": "grade updated from lausd-grade-book"
                                                }

                                            ]
                                        }
                                    }
                                    client.updateGrades(currentClass?.sectionId, _data).then(res => {
                                        console.log("updated grade");
                                    })
                                }
                            })
                        }
                    }
                })
            } catch (error) {
                console.log(error, "issue in update grade");
            }
        }
    }
    const submit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _val = tempValue;
        const hasInA_F = hasGradeInA_F(tempValue)
        if(tempValue === '' || tempValue === null || tempValue === undefined || tempValue === '-'){
         setTempValue(value);
          return;
        }
        if ((
            tempValue === value ||
            (!gradebook_a_f && isNaN(tempValue))) ||
            (scale_1_5 && tempValue > 4) ||
            (gradebook_a_f && !hasInA_F.hasValue) ||
            (!scale_1_5 && !gradebook_a_f && tempValue > 100)
        ) {
            setTempValue(value);
            return;
        }

        if (scale_1_5 && tempValue <= 4) {
            _val = tempValue==0?tempValue:`${(tempValue*20)+10}`
        }
        if (gradebook_a_f && hasInA_F.hasValue) {
            _val = hasInA_F.value
        }
        tempData.grade = _val;
        tempData.graded = true;
        tempData.manualGrading = false;
        tempData.submissions = 1
        updateGradetoSchoology(_val);
        if (assignemnt?.assignments?.length > 0) {
            let avggrade = _val / (assignemnt.assignments.length + 1);
            let tempDataArray = []
            let _data = {
                answers: data?.answers,
                complete: data?.complete,
                grade: avggrade,
                submissions: data?.submissions,
                retaking: data?.retaking || false,
                graded: true,
                feedback: data?.feedback || '',
                manualGrading: false,
            }
            tempData.grade = avggrade;
            let allSubmissions = assignments.assignments.map((_assignemnt, i) => {
                let sub = find(studentSubmissions, ['assignmentId', _assignemnt?.data?.id]);
                if (sub) {
                    return ({
                        answers: sub?.answers,
                        complete: sub?.complete,
                        grade: avggrade,
                        submissions: sub?.submissions,
                        retaking: sub?.retaking || false,
                        graded: true,
                        feedback: sub?.feedback || '',
                        manualGrading: false,
                        id: sub?.id,
                    })
                } else {
                    let _assignmentinfo = _assignemnt.data ?? _assignemnt ?? {};
                    let quizdata = { ...tempData }
                    quizdata.grade = avggrade;
                    quizdata.assignment = _assignmentinfo;
                    quizdata.assignmentId = _assignmentinfo.id;
                    quizdata.chapter = _assignmentinfo.chapter;
                    quizdata.bookId = _assignmentinfo.bookId
                    quizdata.retakes = _assignemnt.retakes;
                    tempDataArray[i] = quizdata;
                    return null
                }
            })
            Promise.all(map(allSubmissions, async subm => {
                if (subm) {
                    await dispatch(handleUpdateSubmission(subm.id, subm));
                }
            })).then(() => {
                Promise.all(map(tempDataArray, async subm => {
                    if (subm) {
                        await dispatch(handleUpdateSubmission(subm?.id, subm, subm));
                    }
                })).then(async () => {
                    await dispatch(handleUpdateSubmission(submission?.id, _data, tempData))
                    await dispatch(handleListClassSubmissions(tempData.classId));
                })
            })

        } else {
            updateGradetoSchoology(_val);
            if(get(submission,"id")){
                const dt = {
                    id:submission.id,
                    grade: _val,
                }
                await dispatch(handleUpdateGrade(dt))
            }else{
            await dispatch(handleUpdateSubmission(submission?.id, {
                answers: data?.answers,
                complete: data?.complete,
                grade: _val,
                submissions: data?.submissions?data?.submissions:1,
                retaking: data?.retaking || false,
                graded: true,
                feedback: data?.feedback || '',
                manualGrading: false,
            }, tempData))

        }
        if (!submission?.id) {
            await dispatch(handleListClassSubmissions(tempData.classId));
        }
    }
    };
    const updateStatus = async (status) => {
        tempData.grade = status == 3 ? 0 : null;
        tempData.graded = true;
        tempData.exceptionStatus = status;
        tempData.submissions = 1
        if(get(submission,"id")){
            const dt = {
                id:submission.id,
                grade: status == 3 ? 0 : null,
                exceptionStatus : status
            }
            await dispatch(handleUpdateGrade(dt))
        }else{
            await dispatch(handleUpdateSubmission(submission?.id, {
                answers: data?.answers,
                complete: data?.complete,
                grade: data?.grade,
                submissions: data?.submissions,
                retaking: data?.retaking || false,
                graded: true,
                feedback: data?.feedback || '',
                manualGrading: false,
                exceptionStatus: status
            }, tempData))
        }
        await dispatch(handleListClassSubmissions(tempData.classId));
        // if(!submission?.id){
        //     await dispatch(handleListClassSubmissions(tempData.classId));
        // }
    }


    const graded = () => {
        if (submission?.retaking) {
            return true;
        } else if (submission?.complete && submission?.graded) {
            return true;
        } else if (!submission) {
            return true;
        } else {
            return false;
        }
    }
    const updateStatusCode = (status) => {
        menuVisibalityChange(false);
        const _status = tempFlag == status ? 0 : status;
        updateStatus(_status)
        setTempFlag(_status);
        updateGradetoSchoology(status == 3 ? 0 : null, _status)
    }
    const getCheckIcon = (status) => {
        if (tempFlag == status) {
            return <Check style={{ color: COLOR.green100, fontSize: 15 }} />
        } else {
            return null;
        }
    }
    const menuVisibalityChange = (visiblity) => {
        setIsMenuOpen(visiblity);
        onFlagMenuViewChange(visiblity)
    }
  
    const isDisable = value === '--' || isReviewAccount || disabled || value === '--' || tempFlag > 0 || ungraded;
    const getReviewSubmission = () => {
        return submission && Object.keys(submission).length > 0 ? submission : tempData
    }
    const getValue = () => {
        if (ungraded) return "-";
        else if (tempFlag == 0 || !tempFlag) {
            if (tempValue === null || tempValue === "NULL") {
                return '-';
            }
            return (typeof tempValue === "number" && tempValue >= 0) || (typeof tempValue === "string") ? tempValue : '-';
        } else if (tempFlag == 3) {
            return '0';
        } else {
            return "--";
        }
    }
    return (
        <Form
            disabled={disabled&&tempFlag<=0}
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();

                const input = document.getElementById(assignmentId + userId);
                input.blur();
            }}
            graded={graded()}
        >
            <Button onClick={e => e.preventDefault()} style={{ marginRight: 5 }}>
                <DescriptionIcon onClick={() => { handleGradeValueShow({ ...assignment, user, submissionData: { value, submission: data } }); }} />
            </Button> 
            <Input
                disabled={isDisable}
                id={assignmentId + userId}
                onBlur={submit}
                onClick={editValue}
                onChange={handleChange}
                value={getValue()}
                style={{ color: 'black' }}
            />
            <Button disabled={disabled&&tempFlag<=0} onClick={() => reviewAssignment(getReviewSubmission())} type="button">
                <Visibility style={{ fontSize: '16px' }} />
            </Button>
           {isTbg&&<TbgArea>TBG</TbgArea>}
        </Form>
    );
};

//  -- Prop types ---------------------
GradeEditor.propTypes = {};

//  -- Default props ------------------
GradeEditor.defaultProps = {};

export default GradeEditor;
