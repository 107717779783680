//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

//  -- Components ---------------------
import Announcement from 'components/Announcement';
import EmptyState from 'components/EmptyState';
import LoadingWrapper from 'components/LoadingWrapper';
import SentryWrap from 'components/Sentry';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Thunks -------------------------
import { handleListClassPosts, handleListOrgPosts } from 'store/posts/thunks';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Header = styled.span`
    color: ${COLOR.black200};
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
`;

const Overflow = styled.div`
    border-bottom: 1px solid ${COLOR.black700};
    border-top: 1px solid ${COLOR.black700};
    box-sizing: content-box;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    margin-bottom: 24px;
    max-height: 480px;
    padding-right: 24px;
    width: 100%;
`;

const Wrapper = styled.div`
    overflow: hidden;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Class announcements
//
//  -- Description
//  Student view of class announcements, display only
//
//  -- Props
//  currentClass {object} - selected class
//  currentUser {object} - current authenticated user
//  posts {array} - list of class posts
//
const Announcements = ({
    currentClass,
    currentOrg,
    currentUser,
    header,
    noHeader,
    posts,
    tab,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            if (!header) {
                setLoading(true);
                await dispatch(handleListClassPosts(currentClass.id));
                setLoading(false);
            } else {
                setLoading(true);
                const id = currentOrg.parentId !== 'null' ? currentOrg.parentId : currentOrg?.id;
                await dispatch(handleListOrgPosts(id));
                setLoading(false);
            }
        }
        fetchData();
    }, [currentClass, currentUser, tab, dispatch])

    // Sort posts by timestamp
    const sorted = posts?.sort((a, b) => b.createdAt - a.createdAt);

    return (
        <Wrapper>
            {!noHeader && (
                <Header>{header ? header : 'Class Announcements'}</Header>
            )}
            <LoadingWrapper loading={loading}>
                <SentryWrap>
                    <Overflow>
                        {sorted?.length > 0 ? (
                            sorted.map(x => (
                                <Announcement key={x.id} data={x} currentUser={currentUser}>{x.content}</Announcement>
                            ))
                        ) : (
                            <EmptyState message={header ? 'No district announcements' : "No class announcements"} />
                        )}
                    </Overflow>
                </SentryWrap>
            </LoadingWrapper>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
Announcements.propTypes = {};

//  -- Default props ------------------
Announcements.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    posts: state.posts
});

export default connect(mapStateToProps, null)(Announcements);
