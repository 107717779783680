import React, { useEffect, useRef, useState } from "react";
import { useTraffic } from "store/wheel/selectors";
import Draggable from "react-draggable";
import styled from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";
import ShareIcon from '@material-ui/icons/Share';
import { revokeTrafficModal } from "store/wheel/actions";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { find, get } from "lodash";
import moment from "moment";
import { v4 } from 'uuid';
import ResizeAndDragabble from "./ResizeAndDraggable";
import AnalogClock from "utils/assets/images/Clock_Base_widget.webp";
import { useCurrentUser } from "store/users/selectors";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { Button as AntdButton } from 'antd'
import color from 'utils/constants/color'
import AccordinButton from "./AccordinButton";
import AnimatedCheckMark from "./AnimatedCheckMark";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
  //   margin: 15px auto;/
  transform: translate(31%, 0%);

  @media (max-width: 1450px) {
    // width: 96%;
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    // width: 95%;
    transform: translate(0%, 0%);
  }
`;

const StopwatchButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 0px solid;
  position: absolute;
  right: 10%;
  cursor: pointer;
  border-radius: 10px !important;
  z-index: 9999;
  width: 100%;
  max-width: 80%;
  min-height: 50px;
  margin-top: 40px;
  font-size: 13px;
`;

const StyledClockIcon = styled(ClockCircleOutlined)`
  margin-right: 8px;
  color: white;
  font-size: 16px;
`;
const formatTime = (time, format = "12") => {
  const momentFormat = format === "12" ? "hh:mm:ss A" : "HH:mm:ss";
  return moment().format(momentFormat);
};

const DigitalClock = ({
  isMilitaryClock,
  dimensions,
  isFontSizeResponsive,
}) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    let interval = setInterval(() => {
      setTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [time]);

  return (
    <div
      className="digital-clock"
      style={{
        fontSize: `${isFontSizeResponsive &&
          `clamp(40px, ${dimensions.width / 7}px, 100px)`
          }`,
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <span className="widget-clock-text">{formatTime(time, isMilitaryClock ? "24" : "12")}</span>
    </div>
  );
};

const WidgetsClock = ({ currentClass, sharableWidget }) => {
  const [showAccordin, setShowAccordin] = useState(true);
  const [trafficModal] = useTraffic();
  const secondHandRef = useRef(null);
  const minuteHandRef = useRef(null);
  const hourHandRef = useRef(null);
  const currentUser = useCurrentUser();
  const { createCollectionWidgets } = useCollectionWidgets();
  const [dimensions, setDimensions] = useState({});
  const [isMilitaryClock, setMilitaryClock] = useState(false);
  const dispatch = useDispatch();
  const isTeacher = get(currentUser, "role") === "teacher";
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "widget_clock"
  );
  const filterShareClock = find(sharableWidget, (obj) => obj?.widget_type === 'widget_clock')

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const seconds = now.getSeconds();
      const minutes = now.getMinutes();
      const hours = now.getHours();

      const secondDeg = (seconds / 60) * 360 + 90;
      const minuteDeg = (minutes / 60) * 360 + (seconds / 60) * 6 + 90;
      const hourDeg = (hours / 12) * 360 + (minutes / 60) * 30 + 90;

      if (secondHandRef.current) {
        secondHandRef.current.style.transform = `rotate(${secondDeg}deg)`;
      }
      if (minuteHandRef.current) {
        minuteHandRef.current.style.transform = `rotate(${minuteDeg}deg)`;
      }
      if (hourHandRef.current) {
        hourHandRef.current.style.transform = `rotate(${hourDeg}deg)`;
      }
    };
    const intervalId = setInterval(updateClock, 1000);
    updateClock();
    return () => clearInterval(intervalId);
  }, []);

  const onClose = async () => {
    const closeShareData = {
      ...filterShareClock,
      status: false
    }
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    if (filterShareClock?.status) {
      createCollectionWidgets(closeShareData);
    }
  };

  const shareClock = () => {
    if (!filterShareClock?.id) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: 'widget_clock',
        status: true,
        data: {
          isMilitaryClock: isMilitaryClock
        }
      }
      createCollectionWidgets(shareData)
    }
  }

  const switchToMilitry = () => {
    const dataShare = {
      isMilitaryClock: !isMilitaryClock
    }
    const editShareData = {
      ...filterShareClock,
      data: dataShare
    }
    setMilitaryClock(!isMilitaryClock)
    createCollectionWidgets(editShareData)
  }

  const handleTouchClose = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchSwitch = (event) => {
    event.preventDefault();
    switchToMilitry();
  };

  const handleTouchShare = (event) => {
    event.preventDefault();
    shareClock();
  };

  const sharedData = filterShareClock?.data || {}

  const directionResizingConfig = dimensions.width  - dimensions.height


  const [directionReszing, setDirectionResizing] = useState(false);

  const startupAnalog = directionResizingConfig === -78;

  return (
    <>
      {((filterByWidget?.view && filterByWidget?.menu === "widget_clock") || filterShareClock?.id) && (
        <Container className="allCheckListSEction wigetsContainer">
          <div className="mainCheckList">
            <ResizeAndDragabble
              defaultValue={{
                x: 150,
                y: 205,
                // width: 351,
                // height: 449,
                width: 265,
                height: 343,
              }}
              minWidth={215}
              minHeight={255}
              aspectRatio={false}
              resize={(data) => setDimensions(data)}
              page={"clock"}
              setDirectionResizing = {(data) => setDirectionResizing(data)}
              showAccordin={showAccordin}
            >
              <div
                className="checkListTopSection"
                style={{
                  position: "relative",
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                  height: showAccordin ? "100%" : "0px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  placeItems: "center",
                  padding: "0px"
                }}
              >
                <div
                  className={`checkListFirstTxt w-full bg-blue-10 pt-17px pb-14px ${
                    showAccordin ? "br-topLeft-topRight-20px" : "br-20px"
                  } `}
                >
                    <AccordinButton
                      open={showAccordin}
                      onClose={() => setShowAccordin((prev) => !prev)}
                      shareableWidget = {sharableWidget}
                    />

                  <span className="text-white text-ft12-700">Clock</span>
                </div>

                {isTeacher && (
                  <CancelIcon
                    onClick={() => onClose()}
                    onTouchStart={handleTouchClose}
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      right: 7,
                      top: 16,
                      fontSize: 18,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer"
                    }}
                  />
                )}
                {isTeacher && (
                  <div style={{
                    marginBottom: "20px",
                  }}>
                    <span
                      onClick={() => shareClock()}
                      onTouchStart={handleTouchShare}
                      style={{
                        marginLeft: "5px",
                        position: "absolute",
                        right: 34,
                        top:17,
                        cursor: "pointer"
                      }}>
                          {!filterShareClock?.status ? 
                      <ShareIcon style={{ color: "white", fontSize: 16 }} />
                        : <AnimatedCheckMark />
                      }
                      {/* <AntdButton type="primary" style={{ borderRadius: 10, minWidth: 60, backgroundColor: filterShareClock?.status ? color.greentrue : color.green100, border: "0px" }}>
                        {filterShareClock?.status ? 'Sharing' : 'Share'}
                      </AntdButton> */}
                    </span>
                  </div>
                )}
                 {showAccordin && (
<>
                  {isTeacher &&  (
                    <>
                    {directionReszing && (directionResizingConfig >= -250 && directionResizingConfig <= -69) || startupAnalog ? null : (
                      
                      <div className="stop-watch-container">
                    <StopwatchButton
                      onClick={() => {
                        switchToMilitry()
                      }}
                      onTouchStart={handleTouchSwitch}
                    >
                      <StyledClockIcon />{" "}
                      {!isMilitaryClock
                        ? "Military Time"
                        : "Standard Time"}
                    </StopwatchButton>
                  </div>
                    )}
                    
                    </>
                  
                )}

                {(() => {
                  if (
                    directionReszing && (directionResizingConfig >= -250 && directionResizingConfig <= -69) || startupAnalog
                    // dimensions.width > 300 &&
                    // dimensions.width < 416 &&
                    // dimensions.height > 350 &&
                    // dimensions.height < 450
                  ) {
                    return (
                      <>
                        <div
                          style={{
                            position: "relative",
                            width: dimensions?.width - 100 || "300px",
                            height: dimensions?.width - 100 || "300px",
                            borderRadius: "50%",
                            backgroundImage: `url(${AnalogClock})`,
                            backgroundSize: "cover",
                            marginTop: "50px",
                          }}
                        >
                          <div
                            style={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor: "black",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              zIndex: 1,
                            }}
                          />
                          <div
                            ref={hourHandRef}
                            style={{
                              position: "absolute",
                              width: "20%",
                              height: "6px",
                              backgroundColor: "transparent",
                              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fclock%2FHour-Hand.png?alt=media&token=81d124b2-bd4b-4820-9ec5-8f9facc5c667)`,
                              transformOrigin: "100%",
                              top: "51%",
                              left: "32%",
                            }}
                          />
                          <div
                            ref={minuteHandRef}
                            style={{
                              position: "absolute",
                              width: "28%",
                              height: "4px",
                              backgroundColor: "transparent",
                              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fclock%2FMinute-Hand.png?alt=media&token=39840d79-cb52-431a-8be5-4e98f2b88046)`,
                              transformOrigin: "100%",
                              top: "51%",
                              left: "24%",
                            }}
                          />
                          <div
                            ref={secondHandRef}
                            style={{
                              position: "absolute",
                              width: "32%",
                              height: "2px",
                              backgroundColor: "transparent",
                              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fclock%2FSeconds-Hand.png?alt=media&token=de26c623-e796-4bd1-93d0-0a9019dcd4b2)`,
                              transformOrigin: "100%",
                              top: "51%",
                              left: "20%",
                            }}
                          />
                        </div>
                        {/* <DigitalClock
                          isFontSizeResponsive={false}
                          dimensions={dimensions}
                          isMilitaryClock={sharedData?.isMilitaryClock ? sharedData?.isMilitaryClock : isMilitaryClock}
                        /> */}
                      </>
                    );
                  } else {
                    return (
                      <DigitalClock
                        isFontSizeResponsive={true}
                        dimensions={dimensions}
                        isMilitaryClock={sharedData?.isMilitaryClock ? sharedData?.isMilitaryClock : isMilitaryClock}
                      />
                    );
                  }
                })()}
</>
                 )}
               

                {/* {(dimensions.width > 390  ) && dimensions.height < 600 && (
                 
                )} */}
              </div>
            </ResizeAndDragabble>
          </div>
        </Container>
      )}
    </>
  );
};

export default WidgetsClock;
