//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const usersInitial = {};

export const userPreference = (state = usersInitial, action) => {
    switch (action.type) {
        case ActionTypes.user_preference.getUserPreferencSuccess:
            return action.payload;
        case ActionTypes.user_preference.updateUserPreferencSuccess:
            return action.payload;
        case ActionTypes.user_preference.createUserPreferencSuccess:
            return action.payload;
        case ActionTypes.user_preference.getUserPreferencFail:
            return state;
        default:
            return state;
    }
};


