//  ----------------------------------------------------------------------------
//  Navigation config
//  ----------------------------------------------------------------------------
//
//  Navigation constant directory
//
//  -- Description
//  Source of truth for navigation config values
//

//  -- Containers ---------------------
import AccountContainer from 'containers/Account';
import AdminDashboard from 'containers/AdminDashboard';
import TeacherHome from 'containers/TeacherHome';
import StudentHome from 'containers/StudentHome';
import Dashboard from 'containers/DashboardNew';
import NewDashboard from 'containers/DataBrowsers';
import Standard from 'containers/Standard';
import DisctrictNewDashboard from 'containers/DistrictDataBrowsers';
import Literacy from "containers/Literacy"
import Director from 'containers/Director';
import StudentDashboard from 'containers/StudentDashboard';
// import DistrictNew from 'containers/DistrictNew';
import DistrictNew from 'containers/district_admin';
import AddBook from "containers/addBook"
import EditBookUnits from "containers/addBook/bookUnits"
import EditResources from "containers/addBook/resources"
import EditExams from "containers/addBook/exams"
import EditStory from "containers/addBook/Story"
import EditApExams from "containers/addBook/apexams"
import EditHowToVideos from "containers/addBook/howToVideos"
import Edit1wayTeacherVideo from "containers/addBook/1wayTeacherVideo"
import Edit1gameVideo from "containers/addBook/1gameVideo"
import Edit1gameUpdateVideo from "containers/addBook/1gameUpdateVideo"
import bookGrouping from 'containers/addBook/bookGrouping';
import DistrictPreference from "components/AdminExams/Districtpreference"
import AdminPreference from "components/AdminExams/index"
import Content_exam from "containers/district_admin/content_exam"
import AP_exam from "containers/district_admin/ap_exam"
import EditSentenceStarter from "containers/addBook/SentenceStarter"
import ParentHome from "containers/parent/index"
import ParentClasses from "containers/parent/classes"
import BookMap from "containers/admin/bookmaps"
import KeyManage from "containers/admin/bookmaps/keys"
//  -- Routes -------------------------
const student = [
    {
        component: StudentDashboard,
        path: '/student/class',
    },
    {
        component: StudentHome,
        path: '/student/home',
    }
];

const teacher = [
    {
        component: AdminDashboard,
        path: '/admin/class',
    },
    {
        component: AccountContainer,
        path: '/account',
    },
    {
        component: TeacherHome,
        path: '/teacher/home',
    }
];

const admin = [
    {
        component: AdminDashboard,
        path: '/admin/class',
    },
    {
        component: StudentDashboard,
        path: '/student/class',
    },
    {
        component: DistrictNew,
        path: '/district',
    },
    {
        component: AccountContainer,
        path: '/account',
    },
    {
        component: AddBook,
        path: '/add-book',
    },
    {
        component: EditBookUnits,
        path: '/edit-book-units',
    },
    {
        component: EditResources,
        path: '/edit-resources',
    },
    {
        component: EditExams,
        path: '/edit-exams',
    },
    {
        component: EditStory,
        path: '/edit-story',
    },
    {
        component: EditApExams,
        path: '/edit-ap-exams',
    },
    {
        component: EditHowToVideos,
        path: '/edit-how-to-videos',
    },
    {
        component: Edit1wayTeacherVideo,
        path: '/edit-1way-teacher',
    },
    {
        component: EditSentenceStarter,
        path: '/sentence-starter',
    },
    {
        component: DisctrictNewDashboard,
        path: '/data-browsers',
    },
    {
        component: Literacy,
        path: '/literacy',
    },
    {
        component: Edit1gameVideo,
        path: '/edit-1game-tutorial',
    },
    {
        component: Edit1gameUpdateVideo,
        path: '/edit-1game-update',
    },
    {
        component: DistrictPreference,
        path: '/proficiency',
    },
    {
        component: Content_exam,
        path: '/content_exam',
    },
    {
        component: AP_exam,
        path: '/ap_exams',
    }
];

const director = [
    {
        component: AdminDashboard,
        path: '/admin/class',
    },
    {
        component: Director,
        path: '/director',
    },
    {
        component: AccountContainer,
        path: '/account',
    }
];
const parent = [
    {
        component: ParentHome,
        path: '/parent/class',
    },
    {
        component: ParentClasses,
        path: '/parent/home',
    }
]
const owe = [
    {
        component: AdminDashboard,
        path: '/admin/class',
    },
    {
        component: DistrictNew,
        path: '/district',
    },
    {
        component: Dashboard,
        path: '/dashboard',
    },
    {
        component: AccountContainer,
        path: '/account',
    },
    {
        component: AddBook,
        path: '/add-book',
    },
    {
        component: EditBookUnits,
        path: '/edit-book-units',
    },
    {
        component: EditResources,
        path: '/edit-resources',
    },
    {
        component: EditExams,
        path: '/edit-exams',
    },
    {
        component: bookGrouping,
        path: '/edit-book-grouping',
    },
    {
        component: EditStory,
        path: '/edit-story',
    },
    {
        component: EditApExams,
        path: '/edit-ap-exams',
    },
    {
        component: EditHowToVideos,
        path: '/edit-how-to-videos',
    },
    {
        component: Edit1wayTeacherVideo,
        path: '/edit-1way-teacher',
    },
    {
        component: EditSentenceStarter,
        path: '/sentence-starter',
    },
    {
        component: Edit1gameVideo,
        path: '/edit-1game-tutorial',
    },
    {
        component: Edit1gameUpdateVideo,
        path: '/edit-1game-update',
    },
    {
        component: NewDashboard,
        path: '/data-browsers',
    },
    {
        component: Standard,
        path: '/standard',
    },
    {
        component: AdminPreference,
        path: '/proficiency',
    },
    {
        component: AP_exam,
        path: '/ap_exams',
    },
    {
        component: BookMap,
        path: '/book-maps',
    },
    {
        component: KeyManage,
        path: '/keys',
    }
];

export const generateRoutes = (user) => {
    switch (user?.role) {
        case 'teacher':
            return teacher;
        case 'admin':
            return admin;
        case 'director':
            return director;
        case 'parent':
            return parent;
        case 'owe_admin':
        case 'owe_cs':
            return owe;
        default:
            return student;
    }
}

export const generateNavigation = () => {
    
}
