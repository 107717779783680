//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import { lighten } from 'polished';
//  -- Components ---------------------
//  -- Features -----------------------
import React, { useEffect, useMemo, useState } from 'react';
//  -- Thunks -------------------------
import { DueTodayIcon, PastDueTodayIcon, colorCircle, robert2 } from "utils/assets/images";
//  -- Constants ----------------------
import { Close } from "@material-ui/icons";
import Loading from "components/Loading";
import _,{ filter, find, get, map } from "lodash";
import moment from 'moment';
import { Img } from 'react-image';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAllBook } from 'store/books/selectors';
import { useBookUnits } from "store/booksUnits/selectors";
import { useClassActivities } from 'store/classActivities/selectors';
import { useClassQuizzes } from 'store/classQuizzes/selectors';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useResources, useVideos } from 'store/resources/selectors';
// import { useServertime } from 'store/servertime/selectors';
import AssignmentReviewByTeacher from "components/AssignmentReviewByTeacher";
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentUser } from 'store/users/selectors';
import styled from 'styled-components';
import COLOR from 'utils/constants/color';
import { useClassSubmissions } from 'store/submissions/selectors'
import { getAssignmentLable, getBookDisplayName, getUserAssignment, imageFetch, imageFetchFromPodcast, isVideo , getStoryUrl , generateUrlForNonEbook } from "utils/lib/ebookHelper";
import { useStory } from "store/story/selectors";
import { BACK_TO_BOOK } from 'utils/config/reference';
moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  })
const Wrapper = styled.div`
  background-color: #e8e4ee;
  padding: 32px 15px;
  border-radius: 50px 50px 50px 50px;
//   margin-left: 52px;
  margin-top: 15px;
  margin-bottom: 5px;
  height:100%;
//   overflow:auto;
  margin-bottom:100px;
`;
const Header = styled.div`
`;
const HeaderIcon = styled.img`

`;
const Section = styled.div`
    margin-bottom: 26px;
`;
const SectionHeader = styled.p`
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 15px;
`;
const AssignmentArea = styled.div`
    background: #cbbbe7;
    min-height: 114px;
    border-radius: 35px;
    padding: 18px 18px;
    margin: 12px 16px;
    box-shadow: 0px 2px 6px #606060;
`;
const AssignmentDac = styled.div`
    background: #ffffff;
    padding: 8px 10px;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: ${p => p.past ? 'not-allowed' : 'pointer'};
`;
const AssignmentText = styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: ${p => p.past ? 'not-allowed' : 'pointer'};
        color:black;
        width:100%;
        min-height:30px !important;
`;
const AssignmentLabel = styled.label`
        cursor: ${p => p.past ? 'not-allowed' : 'pointer'};
        width:100%;
`;
const AssignmentTrack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0px 12px;
`;
const TrackIcon = styled.img`
        height:100px;
`;
const TrackLabel = styled.label`
  font-size:16px !important;
`;
const ImageModal = styled.div`
    left: 50%;
    max-width: 450px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const ModalDescription = styled.div`
    background: #E7E8EA;
    display: flex;
    padding: 5px;
    flex-direction: column;
    position:relative;
`;
const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    color: white;
    z-index: 1;
`;
const ModelDescRow = styled.div`
  font-size:12px;
  text-align: center;
  font-weight: bold;
`;
const ItemTab = styled.button`
    height:100%;
    width:80%;
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    padding:8px;
    cursor: pointer;
`;
const ItemTab2 = styled.button`
    height:100%;
    width:10%;
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    padding:8px;
    cursor: pointer;
`;
const Item = styled.div`
    align-items: center;
    background-color: ${p => p.deactivated ? COLOR.redishpink : p.disabled ? COLOR.green900 : (p.past ? lighten(0.4, 'red') : COLOR.white)};
    border: 1px dashed ${p => (p.deactivated || p.disabled || p.past) ? COLOR.white : COLOR.black700};
    border-radius: 2px;
    color: ${p => (p.deactivated || p.disabled) ? COLOR.white : COLOR.black200};
    cursor: ${p => p.past ? 'not-allowed' : 'pointer'};
    display: flex;
    flex-direction: row;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 4px;
    position: relative;
    text-transform: capitalize;
    transition: all 450ms ease;
    width: 100%;

    @media (min-width: 1000px) {
        &:hover {
            background-color: ${p => !p.past && '#f9f9f9'};
            color: ${p => !p.past && COLOR.green};
            box-shadow: ${p => !p.past && '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'};
        }
    }
`
const IconCol = styled.div`
   display: flex;
   flex-direction: row;
   width: 50px;
`;

export default function AssignmentLists() {
    const classActivities = useClassActivities()
    const currentUser = useCurrentUser()
    const classQuizzes = useClassQuizzes()
    const booksAllList = useAllBook()
    const story = useStory()
    const orgPermissions = useCurrentOrgPermission()
    const resources = useResources()
    const videos = useVideos()
    const currentClass = useCurrentClass()
    const bookPermissions = get(orgPermissions, "books", [])
    const [dueToday, setDueToday] = useState([])
    const [upcommingThisWeek, setUpcommingThisWeek] = useState([])
    const [pastDueThisWeek, setPastDueThisWeek] = useState([])
    const [modalOpen, setModalState] = useState(false);
    const [activeAssignment, setActiveAssignment] = useState(null)
    const bookUnits = useBookUnits()
    const history = useHistory();
    const submissions = useClassSubmissions();
    
    const filterWeekAssignment = (assignment) => {
        if (!assignment) return false;
        const _dueDate = get(assignment, "dueDate")
        return moment(_dueDate).isSame(moment(), "week")
    }
    const modalSrc = () => {
        return isVideo(get(activeAssignment, "data.chapter"), resources) ? imageFetchFromPodcast(get(activeAssignment, "data.chapter"), resources) : imageFetch(booksAllList, get(activeAssignment, "data.chapter"), get(activeAssignment, "data.page"))
    }
    useEffect(() => {
        if (get(currentUser, "role") == 'student') {
            const allAssignment = [...classActivities, ...classQuizzes]
            const userAssignment = getUserAssignment(get(currentClass, "books"), booksAllList, allAssignment, currentUser, bookPermissions , currentClass)
            const weekAssignment = filter(userAssignment, filterWeekAssignment)
            const todayAssignment = filter(weekAssignment, a => moment(a.dueDate).isSame(moment(), "day")&& moment(a.dueDate).isAfter(moment(), "minute"))
            const pastThisWeek = filter(weekAssignment, a => moment(a.dueDate).isBefore(moment(), "minute"))
            const upcommingWeek = filter(weekAssignment, a =>!moment(a.dueDate).isSame(moment(), "day")&& moment(a.dueDate).isAfter(moment(), "minute"))
            setDueToday(todayAssignment)
            setUpcommingThisWeek(upcommingWeek)
            setPastDueThisWeek(pastThisWeek)
        }
    }, [classActivities, classQuizzes, currentUser, booksAllList, orgPermissions, currentClass])
    useEffect(() => {
        if (get(currentUser, "role") != 'student') {
            const allAssignment = [...classActivities, ...classQuizzes]
            const weekAssignment = filter(allAssignment, filterWeekAssignment)
            const todayAssignment = filter(weekAssignment, a =>moment(a.dueDate).isSame(moment(), "day")&& moment(a.dueDate).isAfter(moment(), "minute"))
            const pastThisWeek = filter(weekAssignment, a => moment(a.dueDate).isBefore(moment(), "minute"))
            const upcommingWeek = filter(weekAssignment, a =>!moment(a.dueDate).isSame(moment(), "day")&& moment(a.dueDate).isAfter(moment(), "minute"))
            setDueToday(todayAssignment)
            setUpcommingThisWeek(upcommingWeek)
            setPastDueThisWeek(pastThisWeek)
        }
    }, [classActivities, classQuizzes, currentUser, orgPermissions])
    const viewInfo = (x) => {
        setActiveAssignment(x)
        setModalState(true)
    }
    const redirectToAssignmentVideo = (id, quizId) => {
        let url = `/video?id=${id}&quiz=null`
        history.push(url);
    }
    const generateUrl = (CHAPTER_ID, page, activity, quiz) => {
        const storyUrl = getStoryUrl(story, CHAPTER_ID, page, quiz, activity)
        const backToLabel = BACK_TO_BOOK[CHAPTER_ID]
        const BALCON_PAGES = _.get(_.find(bookUnits, ["bookId", CHAPTER_ID]), "pages", [])
        const bookitem = _.find(BALCON_PAGES, k => parseInt(k.start) <= page && parseInt(k.finish) >= page)
        const url = generateUrlForNonEbook(bookitem?.id, page, activity, quiz, backToLabel, null, CHAPTER_ID)
        if (url && backToLabel) {
            return url;
        }
        else if (storyUrl) {
            return storyUrl;
        } else {
            return null;
        }
    }
    const redirectToBook = (data) => {
        const chapter = get(data, "data.chapter", "")
        const assignmentPage = get(data, "data.page", 0)
        const generatedUrl = generateUrl(chapter,assignmentPage,null,null) 
        if (isVideo(get(data, "data.chapter"), resources)) {
            redirectToAssignmentVideo(get(data, "data.chapter"), get(data, "data.id"));
        }
        else if (generatedUrl) {
            history.push(generatedUrl); 
        }
        else {
            history.push(`/ebook?chapter=${chapter}&page=${assignmentPage}&activity=null&quiz=null`);
        }
    }

    const getAssignmentStatus = (retakeAvailable, submission) => {
        const completed = !retakeAvailable && get(submission, "complete", false)
        const retake =retakeAvailable && get(submission, "complete", false)
        if (completed) {
            return ` (Completed) `
        } else if (retake) {
            return ` (Retake) `
        } else{
           return ''
        }
    }
    const renderStudentAssignment = (x) => {
        const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId) || [];
        if(get(currentUser, "role") === "student" &&  getHiddenBooks?.includes(x?.data?.bookId)){
            return null;
        }
        const submission = find(submissions, ['assignmentId', x.data?.id]);
        let retakeAvailable = false;
        const diff = get(x, "diff[0]")
        const hasUserDiff = find(x.userDiff, ['id', get(currentUser, "id")])
        if (hasUserDiff && diff) {
            retakeAvailable = submission ? parseInt(get(submission, "submissions", 0), 10) < (parseInt(get(diff, "retakes"), 10) + 1) : true;
        } else {
            retakeAvailable = submission ? parseInt(get(submission, "submissions", 0), 10) < (parseInt(get(x, "retakes"), 10) + 1) : true;
        }
        const completed = !retakeAvailable && get(submission, "complete", false)
       
        return (<AssignmentDac className="assignDateInr" key={x.id}>
            <AssignmentText className="assignDateLft" onClick={() => redirectToBook(x)}>
                <DescriptionIcon /><AssignmentLabel> {getAssignmentLable(x, booksAllList, videos, resources)} {getAssignmentStatus(retakeAvailable, submission)}</AssignmentLabel>
            </AssignmentText>
            <ItemTab2 onClick={() => viewInfo(x)}>
                <InfoIcon className="assignDateRght" />
            </ItemTab2>
        </AssignmentDac>)

    }
    const renderTeacherAssignment = (x) => {
        return (<AssignmentDac className="assignDateInr"  key={x.id}>
            <AssignmentText className="assignDateLft">
                <AssignmentReviewByTeacher assignment={x} />  <AssignmentLabel> {getAssignmentLable(x, booksAllList, videos, resources)}</AssignmentLabel>
            </AssignmentText>
            <IconCol>
                <ItemTab2 onClick={() => redirectToBook(x)}>
                    <DescriptionIcon />
                </ItemTab2>
                <ItemTab2 onClick={() => viewInfo(x)} style={{ marginLeft: 10, marginTop: 3 }}>
                    <InfoIcon className="assignDateRght" />
                </ItemTab2>
            </IconCol>

        </AssignmentDac>)
    }
    const closeViewinfo = () => {
        setModalState(false)
        setActiveAssignment(null)
    }

    const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId) || [];

    const pastCheckOnDisable = useMemo(() => {
      if (get(currentUser, "role") === "student") {
        const data = pastDueThisWeek
          ?.map((x) => {
            if (getHiddenBooks?.includes(x?.data?.bookId)) {
              return x;
            } else {
              return;
            }
          })
          .filter((x) => x !== undefined);

        if (data?.length === 0) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }, [pastDueThisWeek, getHiddenBooks]);


     const upComingcheckOnDisable = useMemo(() => {
       if (get(currentUser, "role") === "student") {
         const data = upcommingThisWeek
           ?.map((x) => {
             if (getHiddenBooks?.includes(x?.data?.bookId)) {
               return x;
             } else {
               return;
             }
           })
           .filter((x) => x !== undefined);

         if (data?.length === 0) {
           return false;
         } else {
           return true;
         }
       }
       return false;
     }, [upcommingThisWeek, getHiddenBooks]);

     const dueCheckOnDisable = useMemo(() => {
       if (get(currentUser, "role") === "student") {
         const data = dueToday
           ?.map((x) => {
             if (getHiddenBooks?.includes(x?.data?.bookId)) {
               return x;
             } else {
               return;
             }
           })
           .filter((x) => x !== undefined);

         if (data?.length === 0) {
           return false;
         } else {
           return true;
         }
       }
       return false;
     }, [dueToday, getHiddenBooks]);

    return (<>
        <Modal
            open={modalOpen}
            onClose={closeViewinfo}
            style={{ height: '100%', width: '100%' }}
        >
            <ImageModal>
                <ModalDescription>
                    <CloseBtn onClick={closeViewinfo}>
                        <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 12 }} />
                    </CloseBtn>
                    <ModelDescRow>{getBookDisplayName(booksAllList, get(activeAssignment, "data.chapter"), true, videos, resources)}</ModelDescRow>
                </ModalDescription>

                <Img
                    style={{ height: "auto", width: "100%", border: `1px solid ${COLOR.black800}` }}
                    src={modalSrc()}
                    loader={<Loading />}
                />
            </ImageModal>
        </Modal>
        <Wrapper  className="rghtAssignment">

            <Header className="myAssignBtn allAssignmentBtn">
                <Button style={{ cursor: "default" }}>my assignments
                    <HeaderIcon src={colorCircle} title="My Assignments" alt="My Assignments" />
                </Button>
            </Header>
            <Section className="rghtAssignSec">
                <SectionHeader>Due Today <span style={{fontSize:10}}>(Last day to submit assignment)</span></SectionHeader>
                <AssignmentArea className="assignDate">
                    {dueToday.length === 0 || dueCheckOnDisable ? <AssignmentTrack className="assigntrack">
                        <TrackLabel>No assignments at this moment.</TrackLabel>
                        <TrackIcon src={DueTodayIcon} title="" alt="" />
                    </AssignmentTrack> : map(dueToday, d => (currentUser.role == "teacher" ? renderTeacherAssignment(d) : renderStudentAssignment(d)))}
                </AssignmentArea>
            </Section>
            <Section className="rghtAssignSec">
                <SectionHeader>upcoming this week</SectionHeader>
                <AssignmentArea className="assignDate">
                    {upcommingThisWeek.length === 0 ||  upComingcheckOnDisable ? <AssignmentTrack className="assigntrack">
                        <TrackLabel>No assignments at this moment.</TrackLabel>
                        <TrackIcon src={robert2} title="" alt="" />
                    </AssignmentTrack> : map(upcommingThisWeek, d => (currentUser.role == "teacher" ? renderTeacherAssignment(d) : renderStudentAssignment(d)))}
                </AssignmentArea>
            </Section>
            <Section className="rghtAssignSec">
                <SectionHeader>past due this week</SectionHeader>
                <AssignmentArea className="assignDate">
                    {pastDueThisWeek.length === 0 || pastCheckOnDisable ? <AssignmentTrack className="assigntrack">
                        <TrackLabel>You are on track!</TrackLabel>
                        <TrackIcon src={PastDueTodayIcon} title="" alt="" />
                    </AssignmentTrack> : map(pastDueThisWeek, d => (currentUser.role == "teacher" ? renderTeacherAssignment(d) : renderStudentAssignment(d)))}
                </AssignmentArea>
            </Section>
        </Wrapper>
    </>)
}
