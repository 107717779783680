import React, { createContext, useState, useContext, useRef } from 'react';

// Create the context
const AudioContext = createContext();

export const useAudio = () => {
    return useContext(AudioContext);
};

export const AudioProvider = ({ children }) => {
    const [currentAudio, setCurrentAudio] = useState(null);
    const audioRef = useRef(null);

    const playAudio = (audioUrl) => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        const audio = new Audio(audioUrl);
        audioRef.current = audio;
        audio.play();
        setCurrentAudio(audio);
    };
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setCurrentAudio(null);
        }
    };

    const value = {
        playAudio,
        stopAudio,
        audioRef,
        currentAudio
    };

    return (
        <AudioContext.Provider value={value}>
            {children}
        </AudioContext.Provider>
    );
};
