//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'lodash';
import { useDispatch } from 'react-redux'
import { handlefetchUserProfileConfig , handleupdateserProfileConfig } from './thunks';
import { setHideProfilePickViewer , setShowProfilePickViewer } from "./actions"

//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const currentUserSelector = (state) => state.currentUser;
const cureentOrgSelecter = (state) => state.currentOrg;
const userCount = (state) => state.allUserCount;
const StudentLists = (state) => state.distStudents;
//  -- Examples array selector --------

export const useCurrentUser = () => {
    const selectedData = useSelector(currentUserSelector, shallowEqual)
    return selectedData;
}
export const useDemoStatus = () => {
    const currentUser = useSelector(currentUserSelector, shallowEqual)
    const currentOrg = useSelector(cureentOrgSelecter, shallowEqual)
    const parentOrg = get(currentOrg, "parent", null)
    if(currentUser&&currentUser.role=="owe_admin"){
        return false;
    }
    if (currentUser && currentUser.demo) {
        return true;
    } else if (currentOrg && currentOrg.demo) {
        return true;
    } else if (parentOrg && parentOrg.demo) {
        return true;
    } else {
        return false;
    }

}

export const useAllCount = () => {
    const selectedData = useSelector(userCount, shallowEqual)
    return selectedData;
}
export const useStudentsList = () => {
    const selectedData = useSelector(StudentLists, shallowEqual);
    return selectedData;
}


const UserProfileConfig = (state) => state.profile_config;
export const useUserProfileConfig = () => {
    const profile_config = useSelector(UserProfileConfig, shallowEqual);
    const dispatch = useDispatch();
    const currentUser = useCurrentUser();
    async function fetchUserProfileConfig() {
        if (currentUser) {
            await dispatch(handlefetchUserProfileConfig(currentUser.id))
        }
    }
    async function updateserProfileConfig(config) {
        if (currentUser) {
            return dispatch(handleupdateserProfileConfig(currentUser.id, config))
        }
        return ({ });
    }



    return { profile_config, fetchUserProfileConfig, updateserProfileConfig }
}


const profilePicturePicker = (state) => state.profile_picture_picker;
const allDistrictTeacher = (state) => state.all_district_teacher
export const useProfilePicturePicker = () => {
    const profile_picture_picker = useSelector(profilePicturePicker, shallowEqual);
    const dispatch = useDispatch();
    const showProfilePhotoViewer = () => {
        dispatch(setShowProfilePickViewer())
    }
    const hideProfilePhotoViewer = () => {
        dispatch(setHideProfilePickViewer())
    }
    return { profile_picture_picker, showProfilePhotoViewer, hideProfilePhotoViewer }
}

export const useAllDistrictTeacher = () => {
    const all_district_teacher = useSelector(allDistrictTeacher, shallowEqual);
    return all_district_teacher;
}