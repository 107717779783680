//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import DemoModal from 'components/DemoModal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { get } from "lodash";
import Unlike from "utils/assets/images/unlike-image.svg"
import Liked from "utils/assets/images/liked-image.svg"
import NewAssignedImage from "utils/assets/images/new-assigned-image.svg"
import { useFavouriteVideo } from "store/FavouriteVideo/selectors";
import Loading from "./Loading";
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;

const Text = styled.span`
    color: rgb(0 0 0 / 85%);
    font-size: 20px;
`;

const TextContainer = styled.div`
    border-top: 1px solid ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 16px 16px;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;
    position:relative;
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;
const NewTag = styled.div`
    color:#fff;
    background-color:rgb(211,51,49);
    text-transform: uppercase;
    font-weight:800;
    font-weight: 800;
    width: 60px;
    position: absolute;
    top: -22px;
`;

const CoversCardList = ({ data, link, isProtectedForDemo, page = "", handleFavouriteVideo, allFavorites, loader }) => {
    const history = useHistory();
    const [modalOpenVideosDemo, toggleModalVideosDemo] = useState(false);
    const [state, setState] = React.useState({
        open: false,
        message: 'Restricted access (legend)',
    });
    const getFavorites = allFavorites?.some(video => video.id === data.id)

    const goToVideo = () => {
        if (isProtectedForDemo) {
            showCopyRight()
            return;
        }
        if (data.availableAfterAdoption) {
            showAfterAdoptionAlert()
            return;
        }
        if (data.demo) {
            //toggleModalVideosDemo(true);
            if (link == 'podcast') {
                history.push(`/video?id=${data.id}&quiz=null&link=${link}`);
            } else {
                history.push(`/video?id=${data.id}&quiz=null&link=${link}`);
            }
        }
        else {
            if (link == 'podcast') {
                history.push(`/video?id=${data.id}&quiz=null&link=${link}`);
            } else {
                history.push(`/video?id=${data.id}&quiz=null&link=${link}`);
            }
        }
    }
    const showCopyRight = () => {
        setState({ open: true, message: 'Restricted access (legend)' });
    }
    const handleClose = () => {
        setState({ open: false });
    };
    const showAfterAdoptionAlert = () => {
        setState({ open: true, message: 'Available after adoption!' });
    }
    const { open, message } = state;
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>Warning:</strong> {message}
                </Alert>
            </Snackbar>
            <DemoModal open={modalOpenVideosDemo} handleClose={() => toggleModalVideosDemo(false)} message={'Not available in demo'} />
            <Wrapper style={{ opacity: isProtectedForDemo || data.availableAfterAdoption ? 0.5 : 1 }}  >
                {get(data, "new", false) ? (
                    <div className="new-tag-resource">
                        <img className="" src={NewAssignedImage} alt="" />
                    </div>
                ) : null}
                <div className="card-resource">
                    <a onClick={goToVideo}>
                        <div className="card-resource-overlay"></div>
                        <Image className="card-resource-image" src={data.thumbnail} alt={data.displayName} />
                        <div className="card-resource-details fadeIn-bottom">
                            <h3 className="card-resource-title">{data.displayName}</h3>
                            {/* <p className="card-resource-text">{data.description}</p> */}
                        </div>
                        {/* <TextContainer><Text>{data.displayName}</Text></TextContainer> */}

                    </a>
                    {page === "1wayResource" && (
                        <div onClick={() => handleFavouriteVideo(data)} className="like-unlike-resource-container">
                            {loader === data?.id ? <div style={{marginBottom: 6}}><Loading size={18} /></div> : <img width={23} height={23} src={getFavorites ? Liked : Unlike} alt="" />}
                        </div>
                    )}
                </div>


            </Wrapper>
            {page !== "1wayResource" && (
                <div style={{ color: "#030A4E", background: "unset", justifyContent: "start", color: "white" }} className="cstmVdoTitle">{data.displayName}</div>
            )}

        </>
    );
};

//  -- Prop types ---------------------
CoversCardList.propTypes = {
    data: shape({
        img: string,
        title: string,
        description: string,
        activities: number,
        quizzes: number,
    }),
    handleClick: func
};

//  -- Default props ------------------
CoversCardList.defaultProps = {
    data: {},
};

export default CoversCardList;