//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import { has } from "lodash";
//  -- Actions ------------------------
import * as Sentry from "@sentry/react";
import * as WidgetsActions from './actions';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create Widgets --------------------
export const handleCreateWidgets = (data) => async (dispatch) => {
    try {
        const WidgetsData = await API.post('widgetsRDS', `/create`, {
            body: data
        });
        if (data.id) {
            await dispatch(WidgetsActions.updateWidgets(WidgetsData));
        } else {
            await dispatch(WidgetsActions.addWidgets(WidgetsData));
        }
        return WidgetsData
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

//  -- List Widgets ----------------
export const handleListWidgets = (userId) => async (dispatch) => {
    try {
        const getWidgets = await API.get('widgetsRDS', `/list/${userId}`);
        await dispatch(WidgetsActions.listWidgets(getWidgets));
        return getWidgets;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};


//  -- Delete Widgets ----------
export const handleDeleteWidgets = (id) => async (dispatch) => {
    if (!id) return;
    // dispatch(WidgetsActions.deleteWidgets())
    try {
        const deleteWidgets = await API.del('widgetsRDS', `/delete/${id}`);
        if (has(deleteWidgets, "id")) {
            await dispatch(WidgetsActions.deleteWidgets(deleteWidgets));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
