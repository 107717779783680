import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { handleTextToSpeech } from 'store/vocabulary/thunks';
import { femaleAI, maleAI } from 'utils/assets/images';
import { useAudio } from 'utils/context/AudioProvider';
const ReaderSpanish = ({ text,style = {}, isPlaying, setIsPlaying }) => {
  const { playAudio, stopAudio, audioRef,
    currentAudio } = useAudio();
  const [buttonPress, setButtonPress] = useState(false);
  const [audio, setAudio] = useState(null);
  const [audioVersion, setAudioVersion] = useState('male');
  const [voiceSetting, setVoiceSetting] = useState({
    voice_languageCode: '',
    voice_name: ''
  });
  useEffect(() => {
    return () => {
      if (audioRef.current)
        audioRef.current.pause();
    }
  }, [])
  useEffect(() => {
    // Reset audio when voice setting changes
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      stopAudio();
      setAudio(null);
    }
  }, [voiceSetting]);


    useEffect(() => {
    if (audioRef.current) {
      const handleAudioEnd = () => {
        setIsPlaying(false);
      };

      audioRef.current.addEventListener('ended', handleAudioEnd);

      return () => {
        audioRef.current.removeEventListener('ended', handleAudioEnd);
      };
    }
  }, [audioRef.current]);


  const handleVoiceChange = (event) => {
    // const value = event.target.value;
    const value = event;
    setAudioVersion(value);
    if (value === 'female') {
      setVoiceSetting({
        voice_languageCode: 'es-ES',
        voice_name: 'es-ES-Neural2-A'
      });
    } else if (value === 'male') {
      setVoiceSetting({
        voice_languageCode: 'es-US',
        voice_name: 'es-US-Standard-B'
      });
    }
  };

  const handleSpeacker = async (voc, voiceSetting) => {
    if (!audio) {
      setButtonPress(true);
      // Fetch and play the audio if not already fetched
      const newIdea = {
        text: voc,
        pitch: '',
        volume: '',
        speed: audioVersion === 'male' ? 0.9 : '',
        voice_name: voiceSetting.voice_name,
        voice_languageCodes: voiceSetting.voice_languageCode
      };
      let data = await handleTextToSpeech(newIdea);
      const audioData = data.body.data;

      // Create a Blob from the audio data
      const audioBlob = new Blob([Uint8Array.from(audioData)], { type: 'audio/mp3' });

      // Create a URL from the Blob
      const url = URL.createObjectURL(audioBlob);

      // Create a new Audio object with the URL
      // const newAudio = new Audio(url);

      // audioRef.current = newAudio;
      setAudio(url);
      playAudio(url);
      // Handle audio end
      // newAudio.addEventListener('ended', () => {
      // setIsPlaying(false);
      //   newAudio.currentTime = 0; // Reset audio to the beginning
      // });

      // newAudio.play();
      setIsPlaying(true);
      setButtonPress(false);
    } else {
      // Toggle play and pause
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
        stopAudio()
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
      <Button type="link" style={{ borderRadius: 50 }} onClick={() => handleVoiceChange('female')}
        icon={<img src={femaleAI} style={{ width: '100%', border: audioVersion === 'female' ? '1px solid red' : 0, borderRadius: 50 }} />} />

      <Button type="link" style={{ borderRadius: 50 }} onClick={() => handleVoiceChange('male')}
        icon={<img src={maleAI} style={{ width: '100%', border: audioVersion === 'male' ? '1px solid red' : 0, borderRadius: 50 }} />} />

      {/* <Button type="link" style={{ borderRadius: 50 }} disabled={buttonPress} onClick={() => handleSpeacker(text, voiceSetting)}
        icon={isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon /> } loading={buttonPress} /> */}
      <Button type="link" style={{
        borderRadius: 50, position: 'absolute',
        bottom: '-45px',
        right: '5px'
      }} disabled={buttonPress} onClick={() => handleSpeacker(text, voiceSetting)}
        icon={isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />} loading={buttonPress} />
    </div>
  );
};

export default ReaderSpanish;
