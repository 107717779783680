//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep } from 'lodash';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const notificationsInitial = []

export const notifications = (state = notificationsInitial, action) => {
    switch (action.type) {
        case ActionTypes.notifications.setNotification:
            return action.payload;
        case ActionTypes.notifications.createNotificationSuccess:
            return state.map(x => { if (x.id === action.payload?.id) { return { ...x, ...action.payload }; } return x; });
        case ActionTypes.notifications.updateNotificationSuccess:
            return [action.payload, ...state];
        case ActionTypes.notifications.updateNotificationSeen:
            return action.payload;
        case ActionTypes.notifications.clearListCources:
            return {};
        default:
            return state;
    }
}