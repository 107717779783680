//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const handleGetWelcomeVideos = () => async (dispatch) => {
	try {
		const videos = await API.get('resources', `/getWelcomeVideoUrl`)
		if (videos) {
			await dispatch(Actions.setWelcomeVideos(videos));
		}
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};

export const handleUpdateWelcomeVideos = (data) => async (dispatch) => {
	try {
		const videos = await API.put('resources', `/updateWelcomeVideos`,{
			body:data
		})
		if (videos) {
			await dispatch(Actions.updateWelcomeVideos(videos));
		}
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};

