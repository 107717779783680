//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import { has } from "lodash";
import axios from "axios"
//  -- Actions ------------------------
import * as Sentry from "@sentry/react";
import { sortBy } from "lodash";
import * as SuperLessonActions from './actions';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create Super Lesson --------------------

export const handleCreateSuperLesson = (data) => async (dispatch) => {
    // await dispatch(SuperLessonActions.createVocabulary());
    try {
        const lessonData = await API.post('super_lesson', `/create`, {
            body: data
        });
        if (data.id) {
            await dispatch(SuperLessonActions.updateLessonSuccess(lessonData));
        } else {
            await dispatch(SuperLessonActions.createLessonSuccess(lessonData));
        }
        return lessonData
    } catch (error) {
        if (error && error.message !== "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleAiSuperLesson = (LessonData) => async (dispatch) => {
  // await dispatch(ActivityActions.createBooks());

  try {
    // const data = await API.post("vocabularyRDS", `/ai_super_lesson`, {
    //     body: LessonData,
    // });
    const lessonData = {
      ...LessonData,
      mode: process.env.REACT_APP_STAGE === "prod" ? "prod" : "dev",
    };
    const base_url = process.env.REACT_APP_STAGE === "prod"?`https://autologin.nosotrosonline.com:8080/super_lesson`:`https://autologin.nosotrosonline.com:3000/api/create-lesson`;
    const data = await axios
      .post(base_url, lessonData)
      .then((response) => response.data)
      .then((response) => response);

    // if (LessonData.id) {
    //     await dispatch(SuperLessonActions.updateLessonSuccess(data));
    // } else {
    await dispatch(SuperLessonActions.createLessonSuccess(data));
    // }
    return data;
  } catch (error) {
    console.log("create book error", error);
  }
};

export const handleGetSuperLesson = (data) => async (dispatch) => {
    try {
        const lessonData = await API.post('super_lesson', `/list`, {
            body: data
        });
        await dispatch(SuperLessonActions.listLessonSuccess(lessonData));
        return lessonData;
    } catch (error) {
        if (error) {
            Sentry.captureException(error)
            return null;
        }
    }
};

//  -- Delete Book vocabulary ----------
export const handleDeleteSuperLesson = (id) => async (dispatch) => {
    if (!id) return;
    // dispatch(SuperLessonActions.deleteVocabulary())
    try {
        const vocabulary = await API.del('super_lesson', `/delete/${id}`);
        if (has(vocabulary, "id")) {
            await dispatch(SuperLessonActions.deleteLessonSuccess(vocabulary));
        }
    } catch (error) {
        if (error && error.message !== "Network Error") {
            Sentry.captureException(error)
        }
    }
};
