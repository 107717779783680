//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'lodash';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selectore = (state) => state.currentClass;
//  -- Examples array selector --------

export const useCurrentClassBook = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return get(selectedData,"books",[]);
}

export const useCurrentClass = () =>{
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}