import React from "react";
import styled, { keyframes } from "styled-components";
import COLOR from "utils/constants/color";
import styles from "./styles.css";
const bottomBorder = keyframes`
0% {
    width: 0%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
`;
const mainBlock = keyframes` 
    0% {
      width: 0%;
      left: 0;
    }
    50% {
      width: 100%;
      left: 0;
    }
    100% {
      width: 0%;
      left: 100%;
    }
  }
  `;
const mainFadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;
const Text = styled.span`
  color: ${COLOR.black};
  font-size: 34px;
  font-weight: 600;
  animation-delay: 1.6s;
  opacity: 0;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
// -webkit-animation: ${mainFadeIn} 2s forwards;
// -o-animation: ${mainFadeIn} 2s forwards;
// animation: ${mainFadeIn} 2s forwards;
const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;
  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
`;

const TextWrapper = styled.div`
  transition: width 2s;
  position: relative;
`;
const TextBottomBorder = styled.div`
  border-bottom: 2px solid ${COLOR.green};
  height: 2px;
  width: 0%;

  animation-delay: 2s;
  margin-top: -15px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
// animation: ${bottomBorder} 2s cubic-bezier(0.43, 0.33, 0.36, 1.2) forwards;
const TopBox = styled.span`
  width: 0%;
  height: 60px;
  background: #ffb510;
  position: absolute;

  display: flex;

  @media (max-width: 768px) {
    height: 50px;
  }
`;
// animation: ${mainBlock} 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;

const AnimatedText = ({ text, page="",textColor="", fontSize="" }) => {
  const [isLoading, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    setIsLoaded(false);
    setTimeout(() => {
      setIsLoaded(true);
    }, 100);
  }, [text]);

  return (
    <TextContainer className={page === "resource" && "resource-container-text"}>
      <TextWrapper>
        <TopBox className={isLoading ? "yellowBlock" : ""} />
        <Text
          style={{color: textColor && `${textColor}`, fontSize: fontSize && fontSize}}
          className={isLoading ? `${page === "resource" && "resource-animated-text"} textItem addFontBalcon` : page === "resource" ? "resource-animated-text": ""}
        >
          {text}
        </Text>
        {/* <TextBottomBorder className={isLoading?"borderBottom":""} /> */}
      </TextWrapper>
    </TextContainer>
  );
};
export default AnimatedText;
