//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------


//  -- List books ----------------
export const handleInvokeAlert = (title,message) => async (dispatch) => {
    await dispatch(ActivityActions.invokeAlert(title?title:"Restricted access (legend)",message?message:''));
}
export const handleRevokeAlert = () => async (dispatch) => {
    await dispatch(ActivityActions.revokeAlert());
}
