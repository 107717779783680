//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

//  -- Components ---------------------

//  -- Constants ----------------------
import { Modal } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { twoSidePageIcon } from "utils/assets/images/icons";
import COLOR from 'utils/constants/color';
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import { findIndex } from "lodash"

// two side view css

const BookMarkWrapper = styled.div`
  display: flex !important;
`;


const BookmarkLinkWrapper = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 0px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    display:flex;
    flex-direction:row;
    @media only screen and (max-width: 568px) {
       display: none !important;
    }
`;

const AddBookmarkButton = styled.button`
    // padding: 2px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    background-color:${COLOR.green};
    // padding-right: 10px;
    // padding-left: 5px;
    color: #fff;
    border-radius: 0px;
    font-size: 12px;
    width: 40.8px;
    height: 36.1px;
    padding-right:1px;
`;

const LearnPlanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
`;

const LessionPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
//   height: 100%;
  position: relative;
  justify-content: center;
  padding: 20px;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const CloseBtn = styled.div`
  background-color: rgb(204, 80, 71);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const LessonContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
//   max-width: 1200px;
  padding: 64px 0px 0px;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: 997px) {
    flex-direction: row;
    padding: 0px 30px;
  }
`;

const PrintWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Images = styled.img`
  cursor: pointer;
  background-color: ${COLOR.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  object-fit: cover;
//   height: 600px;
  max-width:48%;
//   @media (max-width: 768px) {
//     height: 400px;
//   }
//   @media (min-width: 768px) {
//     height: 720px;
//   }
//   @media (min-width: 1600px) {
//     height: 980px;
//   }
//   @media print {
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background-color: white;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: 100%;
  }
`;

const ButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  color: ${COLOR.black600};
  cursor: pointer;
  height: 40px;
  left: ${(p) => `${p.left}px`};
  outline: none;
  position: absolute;
  right: ${(p) => `${p.right}px`};
  top: 50%;
  transition: all 300ms ease;
  width: 40px;
  z-index: 1;
`;

// end two side view css

const TwoSidePages = ({
    getAllPages,
    pageId,
    onTwoSideChange = () => null,
    onTwoSideButtonClick = () => null,
}) => {
    const backBtnRef = useRef(null);
    const forwardBtnRef = useRef(null);
    const [openView, setOpenView] = useState(false);
    const [alertSakeBar, setAlertSakeBar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const fetchPageIndex = findIndex(getAllPages, (obj) => obj.pageId === pageId);
    const [currentPageIndex, setCurrentPageIndex] = useState(() => fetchPageIndex);

    const getCurrentPage = getAllPages[currentPageIndex] || {};
    const getNextPage = getAllPages[currentPageIndex + 1] || {};

    function checkKey(e) {
        let isLessonPlan = document.getElementsByClassName("lessonPlanPopUp").length > 0;
        let isDrawerOpen = document.getElementsByClassName("ant-drawer-open").length > 0;
        let isModalOpen = document.getElementsByClassName("ant-modal-mask").length > 0;
        let isRubrics = document.getElementsByClassName("rubricsPopUp").length > 0;
        let isMainBook = document.getElementsByClassName("main-book-popup").length > 0;
        e = e || window.event;
        if (e.keyCode == '37') {
            if (backBtnRef.current && !backBtnRef.current.disabled) {
                backBtnRef.current.click()
            }

        }
        else if (e.keyCode == '39') {
            if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                forwardBtnRef.current.click()
            }

        }

    }

    useEffect(() => {
        setCurrentPageIndex(fetchPageIndex);
    }, [pageId])

    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", () => { })
        }
    }, [])

    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: "", severity: "success" });
    };

    // const getPgSrc = () => {
    //     if (pages) {
    //         return pages
    //     }
    // }

    const onPageChange = (direction) => {
        if (direction === "right" && currentPageIndex < getAllPages.length - 2) {
            setCurrentPageIndex(currentPageIndex + 2);
        } else if (direction === "left" && currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 2);
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={alertSakeBar.open}
                autoHideDuration={3000}
                onClose={closeAlert}
            >
                <Alert
                    onClose={() => {
                        setAlertSakeBar({ open: false, message: "" });
                    }}
                    severity={alertSakeBar.severity}
                >
                    <strong>ALERT:</strong> {alertSakeBar.message}
                </Alert>
            </Snackbar>
            <BookMarkWrapper>
                <BookmarkLinkWrapper>
                    <AddBookmarkButton
                        color="primary"
                        variant="contained"
                        style={{
                            marginRight: 0, borderTopRightRadius: "8px", borderTopLeftRadius: "8px", marginLeft: "1.5px"
                        }}
                        onClick={() => {
                            onTwoSideButtonClick();
                            setOpenView(true);
                        }}
                    >
                        <img src={twoSidePageIcon} />
                    </AddBookmarkButton>
                </BookmarkLinkWrapper>
            </BookMarkWrapper>
            <Modal
                open={openView}
                onClose={() => setOpenView(false)}
                className={"lessonPlanPopUp"}
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                }}
            >
                <LearnPlanWrapper>
                    <LessionPlanContainer>
                        <CloseBtn
                            onClick={() => {
                                setOpenView(false);
                                setCurrentPageIndex(fetchPageIndex);
                            }}
                        >
                            <Close
                                style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
                            />
                        </CloseBtn>
                        <ButtonContainer
                            ref={backBtnRef}
                            disabled={currentPageIndex === 0}
                            onClick={() => {
                                onPageChange("left");
                            }}
                            left={8}
                        >
                            <ArrowBackIos style={{ fontSize: "40px" }} />
                        </ButtonContainer>
                        <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={currentPageIndex >= getAllPages.length - 2}
                            onClick={() => {
                                onPageChange("right");
                            }}
                            right={0}
                        >
                            <ArrowForwardIos style={{ fontSize: "40px" }} />
                        </ButtonContainer>
                        <LessonContainer>
                            <PrintWrapper>
                                <Images
                                    src={getCurrentPage.src || getCurrentPage?.thumbnail}
                                    onDoubleClick={() => {
                                        setOpenView(false);
                                        onTwoSideChange(getCurrentPage?.pageId)
                                    }}
                                />
                                <Images
                                    src={getNextPage?.src || getNextPage.thumbnail}
                                    onDoubleClick={() => {
                                        setOpenView(false);
                                        onTwoSideChange(getNextPage?.pageId)
                                    }}
                                />
                            </PrintWrapper>
                        </LessonContainer>
                    </LessionPlanContainer>
                </LearnPlanWrapper>
            </Modal>
        </>
    );
};

export default TwoSidePages;
