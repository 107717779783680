//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    TextField,
    Modal
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import _,{ filter, map,uniqBy,sortBy,find , get  } from "lodash";
import { func } from 'prop-types';
import React, { useState ,useEffect} from 'react';
import { useDispatch } from 'react-redux';
//  -- Thunks -------------------------
import { handleCheckCode, handleCreateClass,handleGetClassByCode ,handleUpdateClass,handleGetClass} from 'store/classes/thunks';
import { handleCreateUserClass,handleListUserClasses } from 'store/userClasses/thunks';
import { handleRemoveBooksFromClass } from "store/userBooks/thunks";
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { validateData } from 'utils/methods/validateData';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SelectableBook from 'components/SelectableBook';
import { ClassLeftImg , BirdLogoLarge , SelectArrow } from "utils/assets/images";
import { useCurrentOrgPermission } from 'store/organizations/selectors';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Description = styled.span`
    color: #000000;
    display: block;
    font-size: 17px;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 25px;

    @media (max-width: 767px) {
        font-size: 15px;
        line-height: 21px;
    }
`;

const Header = styled(Description)`
    color: #35383d;
    font-size: 32px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 26px;
    margin-bottom: 14px;

    @media (max-width: 767px) {
        margin-top: 4px;
        margin-bottom: 7px;
        font-size: 22px;
    }
`;

const Wrapper = styled.form`
    margin: 0px auto;
    width:100%;
    overflow-y: auto;
    height:100%;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
`;
const Text = styled.div`
    font-size: 15px;
    color:${COLOR.black300};
    white-space: pre-line;
    text-align: left;
`;
const ClassNameError = styled.div`
    font-size: 12px;
    color:${COLOR.radish100};
    white-space: pre-line;
    text-align: left;
`;
const LeftBannerImg = styled.img`
    width: 20% !important;
    height: 100%;
    position: fixed;
`;
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
   
const BookSection = styled.div`
    width: 100%;
`
const BookErrorSec = styled.div`
    padding-left:16px;  
    `
const Legend = styled.div`
    color: #383a3e;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-left: 10px;
    margin-bottom: 16px;
`
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const BtnConatiner = styled.div`
    display:flex;
    flex-direction:column;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Class form
//
//  -- Description
//  Create / edit class information
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <ClassForm handleChange={func} handleSubmit={func} />
//

const filterAssignableBook=(_userBooksList)=>{
    return _.uniqBy(filter(_userBooksList,k=>!get(k,"dataBook.isNotAssignable")&&!get(k,"isNotAssignable")),"bookId")
 }
const ClassForm = ({
    data,
    setData,
    dataBooks,
    currentOrg,
    currentUser,
    classUsers,
    listBooks,
    onClosed,
    currentClass,
    classActivities,
    classQuizzes,
    classNotes,
    classQuestions,
    userClasses
}) => {
    const orgPermissions = useCurrentOrgPermission()
    const isUpdate = data.id != undefined || data.editing;
    const isReviewAccount = currentUser?.reviewAccount || false
    // console.log(bookOrder,"bookOrder")
    const classes = useStyles();
    const theme = useTheme();
    const [selectedBook, setSelectedBook] = React.useState([]);
    //const [data, setData] = useState({})});
    const [booksError, setBooksError] = useState(false);
    const [noLicensesError, setNoLicensesError] = useState(false);
    const dispatch = useDispatch();
    const [classCodeError, setClassCodeError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openReviewDialog, setReviewDialog] = React.useState(false);
    const [chaptersForUser,setChaptersForUser]=useState([])
    const [deleteBookModal, setDeleteBookModal] = React.useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [deletableBookName, setDeletableBookName] = React.useState('');
    const [dataRemovedBooks, setDataRemovedBooks] = React.useState([]);
    const [dataAddedBooks, setDataAddedBooks] = React.useState([]);
    const [errorClassPresent, setErrorClassPresent] = useState(false);
    let addedBooks = [];
    const checkCode = async e => {
        if(isUpdate){
            if(data.code === e.target.value){
                setClassCodeError(false)
                return
            }
        }
        const exists = await dispatch(handleCheckCode(e.target.value));
        if (exists) {
            setClassCodeError(true)
        } else {
            setClassCodeError(false)
        }
    };

    const handleChange = e => {
        const { id, value } = e.target;
        let _value = value;
        if(id=="code"){
            _value = value.trim()
        }
        const maxLength = 60;
        const trimmedValue = _value.slice(0, maxLength);
        setData({
            ...data,
            [e.target.id]: trimmedValue
        });
    };

    useEffect(()=>{
       
    },[data])
    const checkDisplayName = async (value)=> {
        const _displayName =  value.trimEnd();
        let _userclasses=filter(userClasses,e=>(isUpdate?e.id==currentClass.id?false:true:true))
        let hasClassName =find(_userclasses,e=>e.displayName?.toLocaleLowerCase() === _displayName?.toLocaleLowerCase())
        handleChange({target:{id:"displayName",value:_displayName}})
        if(hasClassName){
            setErrorClassPresent(true)
            return false
           }else{
            setErrorClassPresent(false)
            return true
           }
    }
    const submit = async (e) => {
        e.preventDefault();
        if(isReviewAccount){
           setReviewDialog(true)
            return;
        }
        setBooksError(selectedBook.length === 0);
        if(selectedBook.length === 0){
            return;
        }
        if(checkDisplayName(data.displayName)===false){
            return;
        }
        if(isUpdate){
            const preSelectedBooks = data?.books?.map((e) => e.bookId);
            const removedBooks = _.difference(preSelectedBooks, selectedBook);
            addedBooks = _.difference(selectedBook, preSelectedBooks);
            setDataAddedBooks(addedBooks)
            setDataRemovedBooks(removedBooks);
            if (removedBooks.length>0) {
                let bookname=null
            removedBooks.map(e=>{
                if(!bookname){
                    bookname=chaptersForUser.find(k=>k.id===e).description
                }else{
                    bookname=bookname+", "+chaptersForUser.find(k=>k.id===e).description
                }
                setDeletableBookName(bookname)
            })
                setDeleteBookModal(true)
            }
            else{
                infoUpdateClass();
            }
           
            return;
        }
        setLoading(true);
        let _code = data.code;
        const _codee=e.target.value?e.target.value:_code
        const exists = await dispatch(handleCheckCode(_codee));
        if(!exists){
            var booksUser = [];
            if(selectedBook.length > 0){
                setBooksError(false);
                booksUser = {
                    "books": createArrayBooks(selectedBook),
                    "userId": "",
                    "validityDate": "2022-01-01",
                    "orgId": currentOrg?.id
                }
                let _data={
        
                    orgId: currentOrg?.id,
                    teacherId: currentUser?.id,
                    ...data
                }
                let validStatus=validateData(booksUser,["books","orgId"])
                let validStatus2=validateData(_data,["code","displayName","orgId","teacherId"])
                if(!validStatus.isValid||!validStatus2.isValid){
                    console.log(validStatus.reason,validStatus2.reason,"invalid")
                    return;
                }
                const classObj = await dispatch(handleCreateClass({
                  ..._data 
                },{...booksUser}));

                await dispatch(handleCreateUserClass({ classId: classObj?.id, userId: currentUser?.id }));

                setData({});
                onClosed(true,classObj?.id);
                setSelectedBook([])
            }else{
                setBooksError(true);
            }
        }else{
            setClassCodeError(true);
        }
        setLoading(false);
    };
    const infoUpdateClass = async (e) => {
        let codeFinal;
        if(!data) return;
        setDataLoading(true);
        setLoading(true);
        const preSelectedBooks = data?.books?.map((e) => e.bookId);
        if(selectedBook.length > 0){
            if(data.code == undefined){
                codeFinal = currentClass.code;
            }else{
                codeFinal = data.code;
            }
            let displayNameFinal;
            if(data.displayName == undefined){
                displayNameFinal = currentClass.displayName;
            }else{
                displayNameFinal = data.displayName;
            }
            let codeClass;
            if(codeFinal != currentClass.code){
                codeClass = await dispatch(handleGetClassByCode(data.code.trim()));
            }

            let newBooks = _.difference(preSelectedBooks, dataRemovedBooks);
            if(dataRemovedBooks.length == 0){
                newBooks = newBooks.concat(addedBooks)
            };
            await handleDeletedBook(dataRemovedBooks)
            let sbook=createArrayBooks(newBooks);
            data["books"]= sbook
            let booksUser = {
                "books": sbook,
                "userId": "",
                "validityDate": "2022-01-01",
                "orgId": currentOrg?.id
            }
            if(codeClass == undefined){
            await dispatch(handleUpdateClass(currentClass.id, {
                    ...data,
                    displayName: displayNameFinal,
                    code: codeFinal
                },booksUser,data.userBookId));
                currentClass.displayName = displayNameFinal;
                currentClass.code = codeFinal;
                await dispatch(handleListUserClasses(currentUser?.id));
                await(dispatch(handleGetClass(currentClass.id)))
            }else{
            setBooksError(true);
            setLoading(false);
            }
        }
        setLoading(false);
        onClosed(false);
        setSelectedBook([])
        setDataLoading(false);
        setDeleteBookModal(false);
    }
    const handleDeletedBook = (removedBooks) =>{
        try {
        if (Array.isArray(removedBooks) && removedBooks.length > 0) {
            if (removedBooks.length) {
                const removableActivity=[]
                const removableQuiz=[]
                const removableQuestions=[]
                const removableNotes=[];
                const userArray=_.filter(classUsers,(e)=>e.id!=currentUser?.id).map(e=>e.id);
                map(removedBooks,_b=>{
                 let acti= _.filter(classActivities,act=>(act.data.chapter==_b||act.data.bookId==_b));
                 let quiz= _.filter(classQuizzes,qz=>(qz.data.chapter==_b||qz.data.bookId==_b));
                 const cq =  _.filter(classQuestions,q=>q.chapter==_b)
                 const cn =  _.filter(classNotes,n=>n.chapter==_b)
                    acti.map(e=>{
                        removableActivity.push(`'${e.id}'`)
                    })
                    quiz.map(e=>{
                        removableQuiz.push(`'${e.id}'`)
                    })
                    cq.map(e=>{
                        removableQuestions.push(`'${e.id}'`)
                    })
                    cn.map(e=>{
                        removableNotes.push(`'${e.id}'`)
                    })
                })
                const data={
                    removableActivity,
                    removableQuiz,
                    removableQuestions,
                    removableNotes,
                    removedBooks,
                    userArray,
                    classId:currentClass.id
                }
               dispatch(handleRemoveBooksFromClass(data));
            }
          }
        } catch (error) {
          console.log(error,"Error in deleting book related data")   
        }
    }
    useEffect(()=>{
        let uBook=[];
        const bookPermissions = _.get(orgPermissions, "books", [])
        const assignableBooks = _.filter(filterAssignableBook(_.get(dataBooks,"books",listBooks)), k => {
            if (bookPermissions.length == 0) {
                return true;
            }
            const bookaccess = _.find(bookPermissions, b=>b.bookId==k.id||b.bookId==k.bookId)
            if (bookaccess) {
                return bookaccess.available
            } else {
                return true;
            }
        })
            map(assignableBooks,item=>{
                let f =find(listBooks,["id",item.bookId])
                if(f){
                  uBook.push({...f,...item});
                }
            })
            if(uBook.length>0){
                setChaptersForUser(uniqBy(uBook,"id"))
            }
       },[dataBooks,orgPermissions])
        useEffect(()=>{
            if(Array.isArray(data.books)&&data.books.length>0){
                setSelectedBook(data.books.map((i)=>i.bookId??i.id))
            }
        },[data]) 
    function createArrayBooks(selectedBook){
        let arrayBooks = [];
        for(var b in selectedBook){
            let finded = find(listBooks,["id",selectedBook[b]]);
            if(finded){
            arrayBooks.push({
                bookId: finded.id,
                description: finded.description
            })
          }
        }
        return arrayBooks;
    }
    return (
        <Wrapper id="classCreationForm" onSubmit={submit}>
              <Modal
                open={deleteBookModal}
                onClose={() => setDeleteBookModal(false)}
            >
                <ModalContent>
                <Text><strong>{`Deleting ${deletableBookName} book from your class will also delete the following:`}</strong></Text>
                <Text>{"—All grades and assessments submitted\n—All data related to this book (notes, questions, etc.)\n—You will never be able to get this data again Are you sure you want to delete it? "}</Text>
                   <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setDeleteBookModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={infoUpdateClass}
                            className={`${dataLoading ? "disabled-button" : ""}`}
                        >
                            {dataLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>

            <Snackbar
            anchorOrigin={{ vertical:"top", horizontal:"center" }}
            open={openReviewDialog} 
            autoHideDuration={3000}
            onClose={()=>setReviewDialog(false)}>
                <Alert onClose={()=>setReviewDialog(false)} severity="error">
                    <strong>ALERT:</strong> Only for Review!
                </Alert>
            </Snackbar>

         <Row gutter={[25, 0]}>
            <Col xs={24} sm={24} md={8} xl={6} className="addInfoLogoUpr">
               <div className="addInfoLogo addInfoLogoWeb">
                   <LeftBannerImg src={ClassLeftImg}/></div> 
                <div className="addInfoLogoMob">
                   <img src={BirdLogoLarge}/></div> 
            </Col>    
            <Col xs={24} sm={24} md={16} xl={16} className="spcRght">
            <Header>{isUpdate?"Update a  class":"Create a Class"}</Header>
            <Description>You can create your class periods in this section. Remember to name your classes after the subject’s names and levels to make it easier on you and your students using NosotrosOnline.</Description>
            <Row gutter={[16, 24]} className="setLabel">
                <Col span={16}>
                    <TextField
                        disableUnderline={true}
                        id="displayName"
                        label="Class Name"
                        onChange={handleChange}
                        error={errorClassPresent}
                        onBlur={e=>checkDisplayName(e.target.value)}
                        required
                        variant="outlined"
                        value={data?.displayName}
                        helperText={errorClassPresent && 'Please use differenet class name'}
                    />
                </Col>
                <Col span={8}>
                    <TextField
                        id="code"
                        error={classCodeError}
                        label="Class Code"
                        onBlur={checkCode}
                        onChange={handleChange}
                        variant="outlined"
                        helperText={classCodeError && 'Class code not available'}
                        value={data?.code}
                    />
                </Col>
            </Row>

            {/* <Row gutter={[16, 24]} className="startEndDate">
                <Col span={12}>
                    <TextField
                        id="startDate"
                        label="Start Date"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={data?.startDate}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        id="endDate"
                        label="End Date"
                        type="date"
                        value={data?.endDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Col>
            </Row> */}
            {/* <Legend>Please, select the books for this class</Legend> */}

            <p className="selectBooks">Select books for class</p>
            <Row gutter={[16, 24]} className="setLabel">
                <Col xs={24} md={12}>
            <div>
               <BookSection>
                  <SelectableBook 
                  chapter={chaptersForUser} 
                  selected={isUpdate?selectedBook:[]} 
                  preSelected={[]}
                  onSelect={(item)=>{
                      setSelectedBook(item)
                      if (item.length > 0) {
                          setBooksError(false);
                          setNoLicensesError(false);
                      } else {
                          setBooksError(true);
                      }
                  }}  />
                </BookSection>
                <BookErrorSec>
                    { noLicensesError &&
                        <FormHelperText error>The selected school does not have enough licenses available</FormHelperText>
                    }
                </BookErrorSec>
            </div>  
            </Col>
            </Row>
            <div>
              <BtnConatiner className="makeClassBtn mnageCreateBtn">
                {booksError && <FormHelperText error>Choose at least one book</FormHelperText>}
                <Button
                    disabled={!(data.displayName && data.code && !classCodeError)||loading || errorClassPresent}
                    variant="contained"
                    color="primary"
                    type='submit'>
                    {loading ? <Loading size={20} white /> :isUpdate?"Update Class": 'Create Class'}
                    <ArrowForward style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                </Button>
                </BtnConatiner>
            </div>      
        </Col>
      </Row>
    </Wrapper>
  );
};

//  -- Prop types ---------------------
ClassForm.propTypes = {
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
};

//  -- Default props ------------------
ClassForm.defaultProps = {
    handleChange: Function.prototype,
    handleSubmit: Function.prototype,
};

export default ClassForm;