//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { find, map, groupBy } from 'lodash';

//  -- Components ---------------------
import { ResponsiveBar } from '@nivo/bar';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const ExpandButton = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.green100};
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin: 32px auto 64px;
    outline: none;
    padding: 8px 8px 8px 8px;
    position: absolute;
    right: 0px;
    top: -24px;
`;

const Wrapper = styled.div`
    height: ${p => `${p.height}px`};
    box-shadow: 0 0px 15px rgb(0 0 0 / 85%), 0 0px 2px rgb(0 0 0 / 85%);
    padding-bottom: 64px;
    position: relative;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Todo
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
const DistrictVisuals = ({
    data,
    dataTab,
    orgs,
    users,
}) => {
    const [loading, setLoading] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [lessOrgData, setLessOrgData] = useState([]);
    const [teacherData, setTeacherData] = useState([]);
    const [lessTeacherData, setLessTeacherData] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        groupData(data);
    }, [data]);

    useEffect(() => {
        setLoading(true);
        setTimeout(setLoading(false), 1000);
    }, [dataTab]);

    useEffect(() => {
        setLoading(true);
        setTimeout(setLoading(false), 1000);
    }, [showMore])

    const parseData = (data, type) => {
        const datum = {};

        map(data, (classData, i) => {
            classData.map(x => {
                if (datum[x[type]]) {
                    datum[x[type]] = {
                        ...datum[x[type]],
                        "Number of Students": datum[x[type]]["Number of Students"] += x.studentCount,
                        "Activities": datum[x[type]]["Activities"] += x.activities.length,
                        "Quizzes": datum[x[type]]["Quizzes"] += x.quizzes?.length,
                    }
                } else {
                    datum[x[type]] = {}
                    datum[x[type]].type = type === 'orgId' ? find(orgs, ['id', x.orgId]) : find(users, ['id', x.teacherId]);
                    datum[x[type]]["Number of Students"] = x.studentCount;
                    datum[x[type]]["Activities"] = x.activities.length;
                    datum[x[type]]["Quizzes"] = x.quizzes?.length;
                }
            })
        })

        const parsed = map(datum, (x) => {
            return {
                ...x,
                type: x?.type?.displayName ? x?.type?.displayName : `${x?.type?.firstName} ${x?.type?.lastName}`
            }
        })

        return parsed;
    }

    const groupData = async (data) => {
        setLoading(true);
        const groupedByOrg = groupBy(data, 'orgId');
        const groupedByTeacher = groupBy(data, 'teacherId');
        const orgData = await parseData(groupedByOrg, 'orgId');
        const teacherData = await parseData(groupedByTeacher, 'teacherId');

        const lessOrg = orgData.slice().splice(0, 5);
        const lessTeacher = teacherData.slice().splice(0, 5);

        setLessOrgData(lessOrg);
        setOrgData(orgData);
        setLessTeacherData(lessTeacher);
        setTeacherData(teacherData);
        setLoading(false);
    }

    const teachers = showMore ? teacherData : lessTeacherData;
    const schools = showMore ? orgData : lessOrgData;

    return (
        <Wrapper height={dataTab === 'teacher' ? (teachers.length + 1) * 80 : (schools.length + 1) * 80}>
            <ResponsiveBar
                data={dataTab === 'teacher' ? teachers : schools}
                keys={[ 'Number of Students', 'Activities', 'Quizzes' ]}
                indexBy="type"
                margin={{ top: 0, right: 160, bottom: 26, left: 160 }}
                padding={0.3}
                groupMode="grouped"
                layout="horizontal"
                colors={['rgb(3, 152, 158)', 'rgb(207, 38, 184)', 'rgb(255, 177, 0)']}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={'white'}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                animate={false}
                motionStiffness={90}
                motionDamping={15}
            />
            {dataTab === 'teacher'
                ? teacherData.length > 4 && (
                    <ExpandButton onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : 'Show More'}</ExpandButton>
                )
                : orgData.length > 4 && (
                    <ExpandButton onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : 'Show More'}</ExpandButton>
                )
            }
        </Wrapper>
    );
};

//  -- Prop types ---------------------
DistrictVisuals.propTypes = {};

//  -- Default props ------------------
DistrictVisuals.defaultProps = {};

export default DistrictVisuals;