import React, { useState } from 'react'
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import { Row } from 'antd';
import {debounce} from 'lodash';
import Loading from 'components/Loading';
import { useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import { BirdLogo } from 'utils/assets/images';
import { useCurrentUser } from 'store/users/selectors';
import { handleCreateFeedback, handleRevokeFeedbackForm } from 'store/feedback/thunks';

const FeedBackWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;

const BirdLogoImg = styled.div`
    width: 270px;
    height: 180px;
    margin-top: 20px;
    background-image: url(${BirdLogo});
    background-position: top;
    background-size: 150% 130%;
    background-repeat: no-repeat;
`;

const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;

const FeedbackTitle = styled.div`
    color: white;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-line;
`;

const Input = styled.textarea`
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    box-shadow: none;
    font-size: inherit;
    font-weight: 600;
    outline: none;
    padding: 0px 2px;
    transition: all 300ms ease;
    width:100%;
    height:100px;
    text-align:left;
`;

const FeedbackSendBtn = styled.button`
    background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    color: white;
    z-index: 1;
    padding: 5px;
`;

const FeedbackInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border:1px solid #fff;
`;

const FeedbackThnks = styled.div`
    width:100%;
    height:100px;
    background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    color: white;
    z-index: 1;
    white-space: pre-line;
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
`;

const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50%;
    min-height: 50%;
    background-color: rgb(58,58,58);
    position: relative;
    justify-content: center;
    padding: 20px;
    padding-top: 0px;
`;

const FeedbackForm = ({ feedbackForm, revokeFeedback, feedback }) => {
    const dispatch = useDispatch();
    const currentUser = useCurrentUser()
    const [feedbackLoad, setFeedbackLoad] = useState(false)
    const [feedBackData, setFeedBackData] = useState({ content: "" });
    const [feedbackSubmission, setFeedbackSubmission] = useState(false);

    const submitFeedback = () => {
        setFeedbackLoad(true)
        const data = { ...feedBackData }
        data['userId'] = currentUser?.id
        data['userRole'] = currentUser?.role
        data['username'] = currentUser?.username
        data['name'] = `${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}`;
        dispatch(handleCreateFeedback(data, () => {
            setFeedbackSubmission(true)
            setFeedBackData({ content: "" })
            setTimeout(() => {
                setFeedbackSubmission(false)
                dispatch(handleRevokeFeedbackForm())
                setFeedbackLoad(false);
            }, 4000)
        }))
    }

    return (
        <Modal
            open={feedbackForm.isLoading}
            onClose={revokeFeedback}
            style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
        >
            <FeedBackWrapper>
                <FeedbackContainer>
                    <Row style={{ justifyContent: "center" }}>
                        <BirdLogoImg />
                        <CloseBtn onClick={revokeFeedback}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                        </CloseBtn>
                    </Row>
                    {!feedbackSubmission ? (<>
                        <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                            <FeedbackTitle>Report an issue to One Way Education:</FeedbackTitle>
                        </Row>
                        <Row style={{ justifyContent: "center", paddingLeft: "20px", paddingRight: "20px" }}>
                            <Input
                                placeholder=""
                                value={feedBackData.content}
                                onChange={(e) => setFeedBackData({ ...feedBackData, content: e.target.value })}
                            />
                        </Row>
                        <Row style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "15px" }}>
                            <FeedbackSendBtn onClick={submitFeedback} disabled={feedBackData.content.length == 0 || feedbackLoad}>
                                <FeedbackInner>
                                    {feedback.isLoading ? <Loading color={"#fff"} size={20} /> : "Send"}
                                </FeedbackInner>
                            </FeedbackSendBtn>
                        </Row>
                    </>) : (<>
                        <Row style={{ justifyContent: "center", paddingLeft: "20px", paddingRight: "20px", marginTop: 20 }}>
                            <FeedbackThnks>
                                <FeedbackInner>
                                    {`Thank you!  your feedback is needed! we listen to \n your needs, and we start working right way.`}
                                </FeedbackInner>
                            </FeedbackThnks>
                        </Row>
                        <Row style={{ paddingTop: "15px" }}>
                        </Row>
                    </>)}
                </FeedbackContainer>
            </FeedBackWrapper>
        </Modal>
    )
}

export default FeedbackForm;