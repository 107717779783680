//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import Logo from 'components/Logo';
import React from 'react';
import styled, { keyframes } from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const bounce = keyframes`
    to {
        opacity: 0.3;
        transform: translate3d(0, -1rem, 0);
    }
`;

const Container = styled.div`
    margin-top: ${p => p.mounted ? '0px' : '24px'};
    opacity: ${p => p.mounted ? 1 : 0};
    transition: all 300ms ease;
    transition-delay: ${p => p.delay && `${p.delay}ms`};
`;

const Loading = styled.div`
    display: flex;
    justify-content: center;
`;

const Circle = styled.div`
    width: 1rem;
    height: 1rem;
    margin: 2rem 0.3rem;
    background: ${COLOR.green100};
    border-radius: 50%;
    animation: 0.6s ${bounce} infinite alternate;

    &:nth-child(2) {
        animation-delay: 0.2s;
    }

    &:nth-child(3) {
        animation-delay: 0.4s;
    }
`;

export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Experimental app logo
//
//  -- Props
//  ...
//
const InitialLoading = ({ mounted, transparent }) => {
    return (
        <Wrapper>
            <Container mounted={mounted}>
                <Loading>
                    <Circle></Circle>
                    <Circle></Circle>
                    <Circle></Circle>
                </Loading>
            </Container>
            <Container mounted={mounted} delay={500}>
                {transparent ? (
                    <img alt='logo' style={{ height: '160px' }} src={'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FLOGO.png?alt=media&token=6a9a7f5a-4959-431c-a305-8eabe60a4d46'}></img>
                ) : (
                    <Logo height={40} />
                )}
            </Container>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
InitialLoading.propTypes = {};

//  -- Default props ------------------
InitialLoading.defaultProps = {};

export default InitialLoading;
