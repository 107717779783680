import { useFirebase } from "containers/FirebaseContext";
import {
  collection,
  doc as firestoreDoc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "firebaseConfig";

export const useCollectionWidgets = () => {
  const { firestore } = useFirebase();
  const createCollectionWidgets = async (WidgetsData) => {
    try {
      const generated_widget_id =
        WidgetsData?.class_id + WidgetsData?.widget_type;
      const docRef = firestoreDoc(
        firestore,
        "share_widgets",
        generated_widget_id
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, WidgetsData);
      } else {
        await setDoc(docRef, WidgetsData);
      }
      return;
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateTextCollectionWidgets = async (class_id, text) => {
    try {
      const generated_widget_id =
      class_id + "widget_text";
      const docRef = firestoreDoc(
        firestore,
        "share_widgets",
        generated_widget_id
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const existingData = docSnap.data();
        if(existingData && existingData.status){
          existingData.data.text =text
          await updateDoc(docRef, existingData);
        }
      }
      return;
    } catch (error) {
      console.log(error, "error");
    }
  };

  const createCollectionTimer = async (WidgetsData) => {
    try {
      const generated_widget_id =
        WidgetsData?.class_id + WidgetsData?.widget_type;
      const docRef = firestoreDoc(
        firestore,
        "widget_timer",
        generated_widget_id
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, WidgetsData);
      } else {
        await setDoc(docRef, WidgetsData);
      }
      return;
    } catch (error) {
      console.log(error, "error");
    }
  };
  
  const updateCollectionWidgets = async (id, userId) => {
    try {
      const notificationsRef = collection(firestore, "share_widgets");
      const q = query(notificationsRef, where("id", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();
        const existingUser = data.userSeen && data.userSeen.includes(userId);
        if (!existingUser) {
          const docRef = firestoreDoc(notificationsRef, doc.id);
          await updateDoc(docRef, {
            userSeen: [...(data.userSeen || []), userId],
          });
        }
      });
    } catch (error) {
      // Handle errors
    }
  };

  const uploadOnBucket = (file, location,setPercant) => {
    const storageRef = ref(storage, location);
    try {
      const uploadTask = uploadBytesResumable(storageRef, file);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setPercant(percent);
            // update progress
            // setPercent(percent);
          },
          (err) => reject(err),
          async () => {
            // download url
            await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              if (url) {
                resolve(url);
                setPercant(0);
              }
            });
          }
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteOnBucket = async (location) => {
    const storageRef = ref(storage, location);
    try {
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };
  return { createCollectionWidgets,updateTextCollectionWidgets, updateCollectionWidgets, uploadOnBucket, deleteOnBucket, createCollectionTimer };
};
