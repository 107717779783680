import CancelIcon from "@material-ui/icons/Cancel";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@material-ui/icons/Edit";
import { map, find, get } from "lodash";
import React, { useState } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import styled from "styled-components";
import ReadIcon from "utils/assets/images/Read-Expression.png";
import SilenceIcon from "utils/assets/images/Silence-Expression.webp";
import GroupDiscussion from "utils/assets/images/Speak-Groups-Expression.png";
import ShareWithClass from "utils//assets/images/Share-Class-Expression.png";
import SpeakInParis from "utils/assets/images/Speak-pairs-Expression.png";
import Analize from "utils/assets/images/Analize-Expression.png";
import Research from "utils/assets/images/Research-Expression.png";
import WriteAction from "utils/assets/images/Write-Expression.png";
import { SettingOutlined } from "@ant-design/icons";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { v4 } from "uuid";
import { useCurrentUser } from "store/users/selectors";
import { Button as AntdButton } from "antd";
import color from "utils/constants/color";
import {
  DoneOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowDownOutlined,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";
import AnimatedCheckMark from "components/AnimatedCheckMark";
import AccordinButton from "components/AccordinButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
  //   margin: 15px auto;/
  transform: translate(31%, 0%);

  @media (max-width: 1450px) {
    // width: 96%;
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    // width: 95%;
    transform: translate(0%, 0%);
  }
`;

const AllExpressions = [
  { icons: SilenceIcon, key: "silence" },
  { icons: GroupDiscussion, key: "group_discussion" },
  { icons: ReadIcon, key: "read" },
  { icons: ShareWithClass, key: "share_with_class" },
  { icons: SpeakInParis, key: "speak_in_paris" },
  { icons: Analize, key: "analize" },
  { icons: Research, key: "research" },
  { icons: WriteAction, key: "write_action" },
];

const WidgetsExpression = ({ currentClass, sharableWidget }) => {
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets } = useCollectionWidgets();
  const [dimensions, setDimensions] = useState({});
  const [selectedSymbol, setSelectedSymbol] = useState(AllExpressions[0]);
  const [topExpression, setTopExpression] = useState(
    AllExpressions.slice(0, 3)
  );
  const [showAccordin, setShowAccordin] = useState(true);
  const [editingIndex, setEditingIndex] = useState(null);
  const [imageForward, setImageForward] = useState({
    startIndex: 0,
    endIndex: 3,
  });
  const isTeacher = get(currentUser, "role") === "teacher";
  const isStudent = get(currentUser, "role") === "student";
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "widget_expressions"
  );
  const filterShareExpression = find(
    sharableWidget,
    (obj) => obj?.widget_type === "widget_expressions"
  );

  const handleEditClick = (index) => {
    setEditingIndex(index);
  };

  const handleIconClick = (icon) => {
    if (editingIndex !== null && !topExpression.includes(icon)) {
      const dataShare = {
        icons: icon?.icons,
      };
      const editShareData = {
        ...filterShareExpression,
        data: dataShare,
      };
      setTopExpression((prevTopIcons) => {
        const newTopIcons = [...prevTopIcons];
        newTopIcons[editingIndex] = icon;
        return newTopIcons;
      });
      setSelectedSymbol(icon);
      setEditingIndex(null);
      createCollectionWidgets(editShareData);
    }
  };

  const onClose = async () => {
    const closeShareData = {
      ...filterShareExpression,
      status: false,
    };
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    setEditingIndex(null);
    if (filterShareExpression?.status) {
      createCollectionWidgets(closeShareData);
    }
  };

  const onSymbolClick = (item, index) => {
    const dataShare = {
      icons: item?.icons,
    };
    const editShareData = {
      ...filterShareExpression,
      data: dataShare,
    };
    setSelectedSymbol(item);
    setEditingIndex(null);
    createCollectionWidgets(editShareData);
  };

  const shareExpression = () => {
    if (!filterShareExpression?.id) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "widget_expressions",
        status: true,
        data: {
          icons: selectedSymbol.icons,
        },
      };
      createCollectionWidgets(shareData);
    }
  };

  const sharedData = filterShareExpression?.data || {};

  const handleLeftRightMover = (value) => {
    switch (value) {
      case "forward":
        if (AllExpressions.length === imageForward.endIndex) {
          return;
        }

        return setImageForward((prev) => ({
          ...prev,
          startIndex: prev.startIndex + 1,
          endIndex: prev.endIndex + 1,
        }));
      case "backward":
        if (imageForward?.startIndex === 0) {
          return;
        }
        return setImageForward((prev) => ({
          ...prev,
          startIndex: prev.startIndex - 1,
          endIndex: prev.endIndex - 1,
        }));
      default:
        return setImageForward({
          startIndex: 0,
          endIndex: 3,
        });
    }
  };

  const handleTouch = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchShare = (event) => {
    event.preventDefault();
    shareExpression();
  };

  const handleTouchRightMover = (event, value) => {
    event.preventDefault();
    if (value === "forward") {
      handleLeftRightMover("forward");
    } else {
      handleLeftRightMover("backward");
    }
  };

  const handleTouchSymbolClick = (event, obj, index) => {
    event.preventDefault();
    onSymbolClick(obj, index);
  };

  return (
    <>
      {((filterByWidget?.view &&
        filterByWidget?.menu === "widget_expressions") ||
        filterShareExpression?.id) && (
        <Container className="allCheckListSEction wigetsContainer">
          <div className="mainCheckList  h-full">
            <ResizeAndDragabble
              defaultValue={{
                x: 150,
                y: 205,
                width: 215,
                height: 255,
                // width: 330,
                // height: 450,
              }}
              minWidth={215}
              minHeight={255}
              resize={(data) => setDimensions(data)}
              resizeEnd={(data) => setDimensions(data)}
              showAccordin={showAccordin}
            >
              <div
                className="checkListTopSection"
                style={{
                  position: "relative",
                  width: "100%",
                  // height: "100%",
                  height: showAccordin ? "100%" : "0px",
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                  padding: "0px",
                }}
              >
                {isTeacher && (
                  <CancelIcon
                    onClick={() => onClose()}
                    onTouchStart={handleTouch}
                    style={{
                      // marginLeft: "5px",
                      position: "absolute",
                      right: 7,
                      top: 16,
                      fontSize: 18,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                )}

                {isTeacher && (
                  <div>
                    <span
                      onClick={() => shareExpression()}
                      onTouchStart={handleTouchShare}
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        position: "absolute",
                        right: 34,
                        top: 17,
                      }}
                    >
                      {!filterShareExpression?.status ? (
                        <ShareIcon style={{ color: "white", fontSize: 16 }} />
                      ) : (
                        <AnimatedCheckMark />
                      )}

                      {/* <AntdButton
                        type="primary"
                        style={{
                          borderRadius: 10,
                          minWidth: 60,
                          backgroundColor: filterShareExpression?.status
                            ? color.greentrue
                            : color.green100,
                          border: "0px",
                        }}
                      >
                        {filterShareExpression?.status ? "Sharing" : "Share"}
                      </AntdButton> */}
                    </span>
                  </div>
                )}
                <div
                  className={`checkListFirstTxt bg-blue-10 pt-17px pb-14px ${
                    showAccordin ? "br-topLeft-topRight-20px" : "br-20px"
                  } `}
                >
                    <AccordinButton
                      open={showAccordin}
                      onClose={() => setShowAccordin((prev) => !prev)}
                      shareableWidget = {sharableWidget}
                    />
                  <span className="text-white text-ft12-700">Expressions</span>
                </div>

                {showAccordin && (
                  <div style={{ height: "205px", marginTop: "12px" }}>
                    <div className="expression-selection ">
                      <img
                        style={{
                          height: `${dimensions?.width - 111}`,
                        }}
                        className="image-select-none"
                        height={`${dimensions?.width/2 }`}
                        src={
                          sharedData?.icons
                            ? sharedData?.icons
                            : selectedSymbol.icons
                        }
                      />
                    </div>
                    <p
                      style={{
                        marginTop: "6.6px",
                        fontSize: `${`clamp(12px, ${
                          dimensions.width / 19
                        }px, 72px)`}`,
                      }}
                      className="express-selection-text"
                    >
                      {selectedSymbol?.key}
                    </p>
                    {isTeacher && (
                      <div
                        style={{
                          marginBottom: "10px",
                          justifyContent: "space-between",
                          paddingInline: "10px",
                        }}
                        className="expression-Section"
                      >
                        <ArrowBackIosOutlined
                          fontSize="small"
                          style={{
                            width: `clamp(18px, ${
                              dimensions.width / 15
                            }px, 72px)`,
                            height: `clamp(18px, ${
                              dimensions.width / 15
                            }px, 72px)`,
                          }}
                          className="cursor-pointer"
                          onClick={() => handleLeftRightMover("backward")}
                          onTouchStart={(e) =>
                            handleTouchRightMover(e, "backward")
                          }
                        />

                        {map(
                          AllExpressions.slice(
                            imageForward.startIndex,
                            imageForward.endIndex
                          ),
                          (obj, index) => {
                            return (
                              <div>
                                {selectedSymbol.icons === obj.icons && (
                                  <div className="edit-expression"></div>
                                )}
                                <div
                                  style={{
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    className="expression-selected"
                                    style={{
                                      position: "absolute",
                                      top: -4,
                                      right: 0,
                                    }}
                                  >
                                    {selectedSymbol.icons === obj.icons && (
                                      <img
                                        src={require("utils/assets/images/greenRight.png")}
                                        alt=""
                                        height="14px"
                                      />
                                    )}
                                  </div>
                                  <div
                                    onClick={() => {
                                      onSymbolClick(obj, index);
                                    }}
                                    onTouchStart={(e) =>
                                      handleTouchSymbolClick(e, obj, index)
                                    }
                                  >
                                    <img
                                      className="image-select-none "
                                      height={`${
                                        dimensions.width
                                          ? dimensions.width / 6.5
                                          : "100px"
                                      }`}
                                      src={obj.icons}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}

                        <ArrowForwardIosOutlined
                          fontSize="small"
                          className="cursor-pointer"
                          onClick={() => handleLeftRightMover("forward")}
                          onTouchStart={(e) =>
                            handleTouchRightMover(e, "forward")
                          }
                          style={{
                            width: `clamp(18px, ${
                              dimensions.width / 15
                            }px, 72px)`,
                            height: `clamp(18px, ${
                              dimensions.width / 15
                            }px, 72px)`,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </ResizeAndDragabble>
          </div>
        </Container>
      )}
    </>
  );
};

export default WidgetsExpression;
