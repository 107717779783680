import { IconButton, Typography } from '@material-ui/core';
import { PauseCircleFilled, Replay } from '@material-ui/icons';
import { Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const TimerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
//   padding: 20px;
`;

const CircularWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-top:20px
`;

const TimeDisplay = styled(Typography)`
    width: 80%;
    border-bottom: 2px solid;
    text-align: center;
    border-bottom: 4px solid transparent;
    border-image: linear-gradient(0.96turn, rgba(121,47,252), rgba(121,47,252), rgba(121,47,252));
    border-image-slice: 1;
    font-weight: 400;
`;

const AddMinuteButton = styled.button`
    transform: translate(50%,-50%);
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    top: 16%;
    left: 25%;
    border-radius: 15px;
    background-color: rgba(236,240,242);
    padding: 5px 20px;
    border: 0px;
    cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    width: 150px;
    position: absolute;
    bottom: 14%;
`;


const TimmerInner = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;   
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;


const formatTime = (time) => {
    const milliseconds = time % 1000;
    let seconds = Math.floor(time / 1000) % 60;
    let minutes = Math.floor(time / 60000);

    return `${minutes < 10 ? `0${minutes}` : minutes} : ${seconds < 10 ? `0${seconds}` : seconds
        } : ${milliseconds < 100 ? `0${Math.floor(milliseconds / 10)}` : Math.floor(milliseconds / 10)}`;
};
function StopwatchWheel({
    clockSize,
    isTeacher,
}) {
    const [time, setTime] = useState(0);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 10); // Update time every 10 milliseconds
            }, 10);
        }
        return () => clearInterval(interval);
    }, [isActive, time]);

    const handleStartStop = () => {
        setIsActive(!isActive);
    };

    const handleReset = () => {
        setIsActive(false);
        setTime(0);
    };

    const handleTouchStartStop = (event, value) => {
        event.preventDefault();
        if (value === 'start') {
            handleStartStop();
        } else {
            handleReset()
        }
    }

    const normalizedTime = (time / 1000) % 60;
    return (
        <TimerContainer>
        <CircularWrapper className='stopWatchSection'>
            <Progress
                type="circle"
                percent={(normalizedTime * 100) / 60}
                    width={clockSize?.width - 40}
                    showInfo={false}
                    trailColor={"rgba(236,240,242)"}
                    strokeWidth={3}
                    strokeColor={{
                        '0%': 'rgba(121,47,252)',
                        '50%': 'rgba(74,144,201)',
                        '100%': 'rgba(89,190,210)',
                    }}
                />
                <TimmerInner>
                    <TimeDisplay style={{ fontSize: clockSize?.width / 10 }} variant="h2">{formatTime(time)}</TimeDisplay>
                    {isTeacher && (
                        <Controls>
                            <IconButton onClick={handleStartStop} onTouchStart={(e) => handleTouchStartStop(e, 'start')} size="medium" style={{ backgroundColor: "rgba(121,47,252)", height: 60, width: 60 }}>
                                {isActive ? <PauseCircleFilled style={{ color: "#fff" }} /> : <Typography style={{ color: "#fff" }}>Start</Typography>}
                            </IconButton>
                            <IconButton onClick={handleReset} onTouchStart={(e) => handleTouchStartStop(e, 'reset')} size="medium" style={{ backgroundColor: "rgba(236,240,242)", height: 60, width: 60 }}>
                                <Replay />
                            </IconButton>
                        </Controls>
                    )}
                </TimmerInner>
            </CircularWrapper>
        </TimerContainer>
    );
}

export default StopwatchWheel;


// import { IconButton, Typography } from '@material-ui/core';
// import { PauseCircleFilled, Replay } from '@material-ui/icons';
// import { Progress } from 'antd';
// import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';


// const TimerContainer = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 20px;
// `;

// const CircularWrapper = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color:#fff;
//   border-radius: 50%;
//   justify-content: center;
//   align-items: center;
// `;

// const TimeDisplay = styled(Typography)`
//     width: 80%;
//     border-bottom: 2px solid;
//     text-align: center;
//     border-bottom: 4px solid transparent;
//     border-image: linear-gradient(0.96turn, rgba(121,47,252), rgba(121,47,252), rgba(121,47,252));
//     border-image-slice: 1;
//     font-weight: 400;
// `;

// const AddMinuteButton = styled.button`
//     transform: translate(50%,-50%);
//     position: absolute;
//     font-size: 18px;
//     font-weight: bold;
//     top: 16%;
//     left: 25%;
//     border-radius: 15px;
//     background-color: rgba(236,240,242);
//     padding: 5px 20px;
//     border: 0px;
//     cursor: pointer;
//   &:hover {
//     background-color: #f0f0f0;
//   }
// `;

// const Controls = styled.div`
//     display: flex;
//     justify-content: space-between;
//     width: 150px;
//     position: absolute;
//     bottom: 14%;
// `;


// const TimmerInner = styled.div`
//     position: absolute;
//     width: 100%;
//     height: 100%;   
//     justify-content: center;
//     align-items: center;
//     display: flex;
//     flex-direction: column;
// `;


// const formatTime = (time) => {
//     const milliseconds = time % 1000;
//     let seconds = Math.floor(time / 1000) % 60;
//     let minutes = Math.floor(time / 60000);

//     return `${minutes < 10 ? `0${minutes}` : minutes} : ${
//         seconds < 10 ? `0${seconds}` : seconds
//     } : ${milliseconds < 100 ? `0${Math.floor(milliseconds / 10)}` : Math.floor(milliseconds / 10)}`;
// };
// function StopwatchWheel() {
//     const [time, setTime] = useState(0);
//     const [isActive, setIsActive] = useState(false);

//     useEffect(() => {
//         let interval = null;
//         if (isActive) {
//             interval = setInterval(() => {
//                 setTime((prevTime) => prevTime + 10); // Update time every 10 milliseconds
//             }, 10);
//         }
//         return () => clearInterval(interval);
//     }, [isActive, time]);

//     const handleStartStop = () => {
//         setIsActive(!isActive);
//     };

//     const handleReset = () => {
//         setIsActive(false);
//         setTime(0);
//     };
//     const normalizedTime = (time / 1000) % 60;
//     return (
//         <TimerContainer>
//         <CircularWrapper>
//             <Progress
//                 type="circle"
//                 percent={(normalizedTime * 100) / 60}
//                 width={400}
//                 showInfo={false}
//                 trailColor={"rgba(236,240,242)"}
//                 strokeWidth={3}
//                 strokeColor={{
//                     '0%': 'rgba(121,47,252)',
//                     '50%': 'rgba(74,144,201)',
//                     '100%': 'rgba(89,190,210)',
//                 }}
//             />
//             <TimmerInner>
//                 <TimeDisplay variant="h2">{formatTime(time)}</TimeDisplay>
//                 <Controls>
//                     <IconButton onClick={handleStartStop} size="medium" style={{ backgroundColor: "rgba(121,47,252)", height: 60, width: 60 }}>
//                         {isActive ? <PauseCircleFilled style={{ color: "#fff" }} /> : <Typography style={{ color: "#fff" }}>Start</Typography>}
//                     </IconButton>
//                     <IconButton onClick={handleReset} size="medium" style={{ backgroundColor: "rgba(236,240,242)", height: 60, width: 60 }}>
//                         <Replay />
//                     </IconButton>
//                 </Controls>
//             </TimmerInner>
//         </CircularWrapper>
//     </TimerContainer>
//     );
// }

// export default StopwatchWheel;