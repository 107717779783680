//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash";
//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";

//  -- Constants ----------------------
export const handleSetSchoologyAuth = (schoologyUid, cb) => {
    return async (dispatch) => {
        try {
            const response = await API.get("schoologyRDS", `schoology/${schoologyUid}`);
            dispatch(ActivityActions.createSchoologyAuth(response));
            if (cb && typeof cb == "function") {
                cb(response)
            }
            if (!response || !response.oauth_token) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            if (error && error.message != "Network Error") {
                Sentry.captureException(error)
            }
            return false;
        }
    }
}

export const handleCreateLogs = async(data) => {
        try {
            const re = await API.post("schoologyRDS", `logs`, { body: data })
            localStorage.setItem("log_id",re.id);
        } catch (error) {
            if (error && error.message != "Network Error") {
                Sentry.captureException(error)
            }
           
        }
}
export const handleUpdateLogs = async(data) => {
        try {
            const re = await API.put("schoologyRDS", `update_logs`, { body: data })
            console.log(re,"")
        } catch (error) {
            if (error && error.message != "Network Error") {
                Sentry.captureException(error)
            }
           
        }
}
export const handleCreateSchoologyAuth = (data) => {
    return async (dispatch) => {
        try {
            const re = await API.post("schoologyRDS", `schoology`, { body: data })
            dispatch(ActivityActions.createSchoologyAuth(data));
            return true;
        } catch (error) {
            if (error && error.message != "Network Error") {
                Sentry.captureException(error)
            }
            return false;
        }
    }
}
export const handleUpdateSchoologyAuth = (schoologyUid, data) => {
    return async (dispatch) => {
        try {
            await API.put("schoologyRDS", `schoology/${schoologyUid}`, { body: data })
            dispatch(ActivityActions.updateSchoologyAuth(data));
            return true;
        } catch (error) {
            if (error && error.message != "Network Error") {
                Sentry.captureException(error)
            }
            return false;
        }
    }
}
export const handleUserBySchoologyId = async (schoologyUid) => {
    try {
        const schoologyuser = await API.get("schoologyRDS", `getUserBySchoologyId/${schoologyUid}`)
        return schoologyuser;

    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return ({ error: true });
    }
}
export const handleUserBySchoologyName = async (name) => {
    if(typeof name!="string") return [];
    try {
        const firstNamme = name.replace(/\s\s+/g, ' ').split(" ")[0].trim();
		const secondNamme = name.replace(/\s\s+/g, ' ').split(" ")[1]?.trim();
        const schoologyuser = await API.get("usersRDS", `/schoologyUserByName/${firstNamme}/${secondNamme}`)
        return schoologyuser;

    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return ({ error: true });
    }
}
