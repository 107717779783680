//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const createServertime = (returnedPayload) => ({ type: ActionTypes.servertime.createServerTime , payload: returnedPayload  });
export const updateServertime = (returnedPayload) => ({ type: ActionTypes.servertime.updateServertime, payload: returnedPayload });

//  -- List logs ----------------

