//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Col, Row } from 'antd';
import TextEditor from 'components/TextEditor';
//  -- Features -----------------------
import AudioRecorder from 'features/AudioRecorder';
import { func, object } from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { map, get } from "lodash"





//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const AssignmentDescription = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
`;

const AssignmentTitle = styled.span`
    color: ${COLOR.black100};
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
`;




const Container = styled.div`
    display: flex;
    flex-direction: column;
    height:auto;
    margin-top:0px;
    overflow: hidden;
    width: 100%;
`;

const Content = styled.div`
    margin-top: 24px;
`;

const Detail = styled(AssignmentDescription)`
    color: ${COLOR.black600};
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top:${p => p.index == 0 ? "opx" : "24px"}
`;

const Option = styled.button`
    background-color: ${p => p.selected ? COLOR.green800 : COLOR.white};
    border: 1px dashed ${p => p.selected ? COLOR.green300 : COLOR.black700};
    border-radius: 3px;
    color: ${p => p.selected ? COLOR.white : COLOR.black400};
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 6px;
    outline: none;
    text-align: left;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: ${p => p.selected ? 'none' : '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
        color: '${p => p.selected ? COLOR.green100 : COLOR.black200}';
    }
`;

const Question = styled(AssignmentTitle)`
    font-size: 16px;
    line-height: 22px;
`;

const QuestionContainer = styled(Container)`
    margin-top: 0px;
    position: relative;
`;

const RelativeContainer = styled.div`
    position: relative;
    margin-top:${p => p.marginTop ? p.marginTop : "0px"}
    width: 100%;
`;

const Section = styled.div`
    width: 100%;
`;





const Close = styled.span`
flex-direction:row;
padding-top:5px;
padding-bottom:5px;
padding-right:10px;
background-color: ${p => p.selected ? COLOR.green800 : COLOR.white};
border: 1px dashed ${p => p.selected ? COLOR.green300 : COLOR.black700};
border-radius: 3px;
color: ${p => p.selected ? COLOR.white : COLOR.black400};
cursor: pointer;
margin-bottom:12px;
padding-left:10px;
width:150px;
margin-top:6  px;
`

const Wrapper = styled.div`
    height: 100%;
    // max-width: 480px;
    padding:0px;
    width: 100%;
    min-height:480px;
   
`;

const InnerWrapper = styled.div`
    max-height: 55vh;
    // max-width: 480px;
    padding: 0px;
    width: 100%;
    overflow-y:scroll;
    scroll-behavior: smooth;
    padding-bottom:5px;
    border-bottom:1px dotted;
   
`;
// -ms-overflow-style: none;
// &::-webkit-scrollbar {
//     display: none;
//   }
const editorDefault = [{
    type: 'paragraph ',
    children: [
        {
            text: ''
        }
    ]
}];

//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Assignment
//
//  -- Description
//  Assignment participation workflow
//
//  -- Props
//  data {object} - activity or quiz data
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Assignment data={data} />
//
const Assignment = ({
    data = {},
    onClose = () => null,
    innerScrollStyle = {}
}) => {
    const [selections, setSelections] = useState("");
    const innerRef = useRef(null)
    useEffect(() => {
        if (innerRef.current) {
            innerRef.current.scrollTop = 0
        }
    }, [innerRef])
    return (
        <Wrapper>
            <Container>
                <Close
                    onClick={onClose}
                    selected
                >
                    <ArrowLeftOutlined style={{ height: '10px', marginRight: '8px' }} /> Close Preview
                </Close>
                <Section>
                    <Row align="middle" gutter={[0, 16]}>
                        <Col span={24}>
                            <AssignmentTitle>{data?.displayName}</AssignmentTitle>
                        </Col>
                    </Row>
                    {data?.description && data?.description.length > 0 && (
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <AssignmentDescription>{data?.description}</AssignmentDescription>
                            </Col>
                        </Row>
                    )}
                </Section>
            </Container>
            <InnerWrapper style={innerScrollStyle} ref={innerRef} id='previewInner'>
                {map(get(data, "questions", []), (question, index) => (
                    <RelativeContainer key={index}>
                        <QuestionContainer key={question.id} >
                            <Detail index={index}>Question {index + 1} of {data?.questions.length}</Detail>
                            <Question>{question.question}</Question>
                            <Content>
                                {question.type === 'email' && (
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <TextEditor
                                                handleChange={setSelections}
                                                value={selections}
                                                autoFocus={false}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {question.type === 'essay' && (
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <TextEditor
                                                handleChange={setSelections}
                                                value={selections}
                                                autoFocus={false}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {question.type === 'multipleChoice' && (
                                    <>
                                        {map(get(question, "options", []), (option, i) => (
                                            <Row key={i} style={{ marginBottom: '8px' }}>
                                                <Option selected={false} onClick={() => { }}>{option}</Option>
                                            </Row>
                                        ))}
                                    </>
                                )}
                                {question.type === 'audio' && (
                                    <>
                                        <ReactPlayer
                                            url={question.audio}
                                            height="40px"
                                            width="100%"
                                            controls
                                            playsinline
                                            style={{ marginBottom: '8px' }}
                                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                        />
                                        <AudioRecorder
                                            maxTime={20}
                                            handleAudio={() => { }}
                                            index={index}
                                            currentSubmission={{}}
                                            refresh={() => { }}
                                            isPreview
                                        />
                                    </>
                                )}
                                {question.type === 'twoMinAudio' && (
                                    <>
                                        <AudioRecorder
                                            maxTime={120}
                                            handleAudio={() => { }}
                                            refresh={() => { }}
                                            isPreview
                                        />
                                    </>
                                )}
                            </Content>
                        </QuestionContainer>
                    </RelativeContainer>
                ))}
            </InnerWrapper>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
Assignment.propTypes = {
    data: object,
    onClose: func,
};

//  -- Default props ------------------
Assignment.defaultProps = {
    data: {}
};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({

});

export default connect(mapStateToProps, null)(Assignment);
