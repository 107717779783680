import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import VideoLinks from '../../containers/videoLinks';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinkDropdown from '../../containers/videoLinkDropdown';
import VideoLinkDetailsBanner from '../VideoLinkDetailsBanner';

const ButtonLink = styled.button``;

const classNav = () => {
    return (
        <VideoLinkDropdown />
    );
};

export default function LearnScreen() {
    return (
        <>
            <div className="detailMain">

                <Row gutter={[25, 0]}>
                    <Col xs={24} sm={24} md={6} className="searchMob">
                        <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                            <ButtonLink onClick={e => e.preventDefault()}>
                                Search
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </ButtonLink>
                        </Dropdown>
                    </Col>
                    <Col xs={24} sm={24} md={18}>
                       <div className="detailInfo detailSpc">

                             {/* Satrt Include Banner */}
                                 <VideoLinkDetailsBanner />
                             {/* End Include Banner */}
                             
                            <div><h1>Class Passwords/ Contraseñas</h1></div>
                            <div>
                                <p>“Soy fuerte y puedo superar todos los retos de mi vida". Let's be real, teaching is no easy job, and it’s fair to say that we all
                                need a little encouragement and self-affirmation now and then, and so do our students. In order to enter my classroom, each student
                                must recite the “contraseña”, which is a phrase/ quote that they either memorize or read off of. I give them their contraseña for the
                                week on a Monday and change it every two weeks, giving students plenty of time to rehearse and recite it. These phrases/ quotes are usually
                                short, sweet and to the point. They are helpful in so many ways and serve many purposes. I use them in my Spanish 1 class to teach students helpful
                                phrases that can be used in interpersonal communication in and outside class. For example, after students learn the verb “ser” they are then given the
                                contraseña “No es justo” towards the beginning of the first semester. Throughout the year, they end up using the phrase during class, especially when I
                                assign them homework. Students get very creative and feel confident using the phrases, since  they have been rehearsed and used many times. </p>

                                <p>For my Spanish speakers, I give them an affirmation as their contraseña such as the one mentioned above. I find that this creates a positive environment right
                                from the moment students enter the classroom. It also gives students an opportunity to practice their pronunciation without the stress of doing it in front of the
                                whole class, and allows for immediate correction. It is an easy way to expose students to more vocabulary, idioms and useful phrases  in a way that is comprehensible.
                                Besides exposing students to new vocabulary, practicing pronunciation, and creating a positive learning environment, these phrases can also be beneficial for all proficiency
                                levels when teaching grammar concepts as students hear the grammar concept in a natural and comprehensible manner. </p>
                                <p>
                                    <strong>Here are some examples:</strong>
                                    <ul>
                                        <li>“Cada día trae nuevas opciones” </li>
                                        <li>“La mejor manera de predecir el futuro es inventarlo”</li>
                                        <li>“Siempre parece imposible hasta que se convierte en realidad”</li>
                                        <li>“Es igual de importante el viaje que el destino”</li>
                                    </ul>
                                </p>
                            </div>
                            <div><label><b>- Jodie Sullivan, Los Angeles, California </b></label></div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={6} className="searchWeb">
                        <VideoLinks />
                    </Col>
                </Row>
            </div>

        </>
    );
}
