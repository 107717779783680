//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ebookHighLightsAction from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------


export const handleCreateEbookHighlights = (data) => async dispatch =>{
	try {
		const highlights = await API.post('ebook_highlight', `/create_highlight`, {
			body: { ...data }
		});
		if (highlights && highlights.id) {
			dispatch(ebookHighLightsAction.createOrUpdateEbookHighlight(highlights))
		}
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return error;
	}
};
export const handleListUserEbooklights =  (user_id,book_id,class_id) => async dispatch =>{
	dispatch(ebookHighLightsAction.clearEbookHighlighter());
	try {
		const highlights = await API.post('ebook_highlight', `/list_ebook_highlights`, {
			body: { user_id, book_id,class_id }
		});
		if (Array.isArray(highlights)) {
			dispatch(ebookHighLightsAction.setEbookHighlight(highlights))
		}
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return error;
	}
};
