//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import styled from 'styled-components';

//  -- Components ---------------------
import AssignmentStatus from  'components/AssignmentStatus';
import EmptyState from  'components/EmptyState';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import {filter } from "lodash"

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Header = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const HeaderTitle = styled.span`
    color: #213F4B;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.05em;
`;

const Wrapper = styled.div`
    width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Interaction
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...

const ChapterList = ({
    chapter,
    data,
    handleEditActivity,
    handleEditQuiz,
    handleDeleteActivity,
    handleDeleteQuiz,
    message,
    onAssign,
    title,
    bookDesc,
    booksAllList
}) => {
    const { Panel } = Collapse;
    const sortedData = filter(data,x => x.data ? x.data?.chapter === chapter : x.chapter === chapter)?.sort((a, b) => {
        if (a?.data) {
            return a?.data?.page - b?.data?.page;
        } else {
            return a?.page - b?.page;
        }
    });
    // if(sortedData?.length == 0){
    //     return null;
    // }
    return (
        <Wrapper>
            <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} defaultActiveKey={['1']} >
            <Panel
                    header={
                        <Header>
                            <HeaderTitle>{title}</HeaderTitle>
                        </Header>
                    }
                    key="1"
                >
                    {sortedData?.length > 0 ? (
                        <>
                            {sortedData?.map((x,i) => (
                                <AssignmentStatus
                                    key={x.id+i}
                                    chapter={chapter}
                                    data={x}
                                    handleEditActivity={handleEditActivity}
                                    handleEditQuiz={handleEditQuiz}
                                    handleDeleteActivity={handleDeleteActivity}
                                    handleDeleteQuiz={handleDeleteQuiz}
                                    onAssign={() => onAssign(x)}
                                    bookDesc={bookDesc}
                                    booksAllList={booksAllList}
                                />
                            ))}  
                        </>
                    ) : (
                        <EmptyState message={message} />
                    )}
                </Panel>
            </Collapse>
        </Wrapper>
    );
}

//  -- Prop types ---------------------
ChapterList.propTypes = {
    data: arrayOf(shape({
        title: string,
        img: string,
        description: string | null,
        dueDate: string,
        retakes: string,
        status: string
    })),
    title: string,
};

//  -- Default props ------------------
ChapterList.defaultProps = {};

export default ChapterList;