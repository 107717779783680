import { makeStyles } from "@material-ui/core";
import { CheckOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import { Button as AntdButton, Select as AntSelect, Space, Tag, Tooltip } from "antd";
import { filter, find, get, map } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDifferentiationLesson } from "store/DifferentiationLesson/selectors";
import { handleCreateDifferentiationLesson, handleDeleteDifferentiationLesson } from "store/DifferentiationLesson/thunks";
import { useAllDistrictTeacher, useCurrentUser } from "store/users/selectors";
import styled from "styled-components";
import color from "utils/constants/color";
import SuperLessonLogo from "../../utils/assets/images/super-lesson-logo.svg";
import { useReactToPrint } from "react-to-print";
import Loading from "components/Loading";
import PrintIcon from '@material-ui/icons/Print';

const LessonContainer = styled.section`
  padding-top: 15px;
  padding-left: 27px;
  padding-right: 33px;
  padding-bottom: 20px;
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  @media (max-width: 700px) {
    display: none;
  }
`;

const LessonModal = styled.div`
  background: #000f4c;
  border-radius: 11px;
  padding-left: ${(p) => (p.view ? "51px" : "19px")};
  padding-right: ${(p) => (p.view ? "51px" : "19px")};
  padding-bottom: ${(p) => (p.view ? "34px" : "26px")};
  padding-top: ${(p) => (p.view ? "51px" : "10px")};
  height: ${(p) => (p.view ? "unset" : "100%")};
  // overflow: hidden auto;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
`;

const SuperLessonLogoImg = styled.img`
  @media (max-width: 768px) {
    width: 57px;
    height: 51px;
  }
`;

const BoxContainer = styled.div`
  display: flex;

  align-items: center;
  gap: 12px;
`;

const Label = styled.label`
  color: #fff;
  // font-family: "Gotham";
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  // font-family: Gotham;
  white-space: nowrap;

  @media (max-width: 768px) {
    color: #fff;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
  }
`;

const FieldShareBox = styled.div`
  display: grid;
  place-items: center;
  border-radius: 9.5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  text-align: center;
  min-width: 63px;

  // width: 108px;
  height: 31px;
  color: #000;
  // font-family: Gotham;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  flex: 1;
  max-width: 241px;

  @media (max-width: 768px) {
    color: #000;
    // font-family: "Gotham";
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
  }
`;

const WhiteBox = styled.div`
  height: calc(100% - 185px);
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #e8e8e8;
  background: #fff;
  width: 100%;
  // height: 100%;
  // max-height: 700px;

  margin-top: 24px;
  overflow-x: hidden;
  background-color: #fff;
  overflow-y: auto;

  @media (max-width: 1367px) {
    height: calc(100% - 195px);
  }

  @media (max-width: 768px) {
    height: calc(100% - 188px);
  }
  @media print {
    position: relative;
    width: auto;
    height: auto;
    overflow: visible;
    display: block;

    &,
    & * {
      page-break-inside: avoid;
    }

    .lesson-plan-container {
      page-break-after: avoid;
    }
  }
`;

const convertToHtml = (lessonData) => {
  const lines = lessonData?.split("\n");
  let html = '<div class="lesson-plan">';

  lines.forEach((line) => {
    if (line.startsWith("###")) {
      html += `<h3>${line.substring(4)}</h3>`;
    } else if (line.startsWith("**")) {
      const boldParts = line.split("**").filter(Boolean);
      html += `<strong>${boldParts.join("</strong><strong>")}</strong>`;
    } else if (line.startsWith("- ")) {
      html += `<li>${line.substring(2)}</li>`;
    } else if (line.startsWith("  - ")) {
      html += `<ul><li>${line.substring(4)}</li></ul>`;
    } else if (line.startsWith("    - ")) {
      html += `<ul><ul><li>${line.substring(6)}</li></ul></ul>`;
    } else {
      html += `<p>${line}</p>`;
    }
  });

  html += "</div>";
  return html;
};

const useStyles = makeStyles({
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent !important",
    },
    menuItemText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "150px",
      display: "block",
    },
    disabledButton: {
      color: "white",
      "&.Mui-disabled": {
        color: "white !important",
      },
    },
  },
});

const DifferentiationViewModal = ({
  open,
  onClose,
  showModal,
  viewLessonType,
  superLessonData,
  data,
  from
}) => {
  const imageRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const superLesson = useDifferentiationLesson();
  const allDistrictTeacher = useAllDistrictTeacher();
  const [loader, setLoader] = useState(false);
  const [showShareList, setShowShareList] = useState(true);
  const [printLoader, setPrintloader] = useState(false)
  const filterLesson =
    find(
      superLesson,
      (data) =>
        data?.userId === currentUser?.id &&
        data?.access_by?.includes(currentUser?.id)
    ) || {};
  const filterShareLesson =
    filter(
      superLesson,
      (data) =>
        data?.userId !== currentUser?.id &&
        data?.access_by?.includes(currentUser?.id)
    ) || [];
  const [filterMyData, setFilterMyData] = useState(filterLesson);
  const [filterSharedLesson, setFilterSharedLesson] =
    useState(filterShareLesson);
  const [selectedTeacherIds, setSelectedTeacherIds] = useState([]);
  const [initialValues, setInitialValues] = useState([]);
  const superData = filterMyData?.differentiation_data ?? {};
  const filterByTeacher = allDistrictTeacher.find((item) =>
    filterMyData.userId === item.id
  );
  const getAllOptions = useMemo(() => {
    return allDistrictTeacher.map((item) => ({
      label: `${item.firstName} ${item.lastName},`,
      value: `${item?.id}`,
    }));
  }, [allDistrictTeacher]);

  const sharedProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
    filterOption: (input, option) => {
      return option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
    },
  };

  const [value, setValue] = useState([]);
  const selectProps = {
    value,
    onChange: (selectedIds) => {
      const newSelections = selectedIds.filter(
        (item) => !initialValues.includes(item.value)
      );

      // Combine the new selections with the initial values
      const updatedSelection = [
        ...value.filter((item) => initialValues.includes(item.value)),
        ...newSelections,
      ];

      setValue(updatedSelection);
    },
    labelInValue: true,
  };

  useEffect(() => {
    setFilterSharedLesson(filterShareLesson);
    setFilterMyData(filterLesson);
    setSelectedTeacherIds(filterLesson?.access_by || []);
    const initialAccessBy =
      filterLesson?.access_by?.filter(
        (element) => element !== filterLesson?.userId
      ) || [];
    setInitialValues(initialAccessBy);
    setValue(
      (
        filterLesson?.access_by?.filter(
          (element) => element !== filterLesson?.userId
        ) || []
      ).map((item) => ({ value: item }))
    );
  }, [superLesson]);

  useEffect(() => {
    onClose();
  }, [showModal]);

  const isDisable=()=>{
    const selectedTeacher = value?.map((element) => element?.value)
      const combinedArray = [...selectedTeacher];
      combinedArray.push(filterLesson?.userId);
      const shareableArr = filter(combinedArray, (val) => val !== currentUser?.id)
      return shareableArr.length==0 ? false : true;
  }

  const handleShare = async () => {
    try {
      setLoader(true);
      const selectedTeacher = value?.map((element) => element?.value);
      const combinedArray = [...selectedTeacher];
      combinedArray.push(filterLesson?.userId);
      const shareableArr = filter(combinedArray, (val) => val !== currentUser?.id)
      if (shareableArr?.length === 0) return;
      const data = {
        ...filterMyData,
        access_by: combinedArray,
      };
      await dispatch(handleCreateDifferentiationLesson(data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleBack = () => {
    setShowShareList(true);
    setFilterSharedLesson(filterShareLesson);
    setFilterMyData(filterLesson);
    onClose();
  };

  const handleShareDelete = async () => {
    const data = {
      ...filterMyData,
      access_by: filter(
        filterMyData?.access_by,
        (access) => access !== currentUser?.id
      ),
    };
    if (data?.access_by?.length === 0) {
      setLoader(true);
      await dispatch(handleDeleteDifferentiationLesson(data?.id));
      setLoader(false);
    } else {
      setLoader(true);
      await dispatch(handleCreateDifferentiationLesson(data));
      setLoader(false);
    }
    setSelectedTeacherIds([]);
    setValue([]);
    setShowShareList(true);
    setFilterSharedLesson(filterShareLesson);
    setFilterMyData(filterLesson);
    onClose();
  };

  const validationPrint = useReactToPrint({
    content: () => imageRef.current,
    onBeforeGetContent: () => { setPrintloader(true) },
    onBeforePrint: () => {
      setPrintloader(false);
    }
  });

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const isInitiallySelected = initialValues.includes(value);
    return (
      <Tag
        color={"blue"}
        onMouseDown={onPreventMouseDown}
        closable={!isInitiallySelected && closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label?.length > 10 ? label.slice(0, 10) : label}
      </Tag>
    );
  };

  const getMaskIdFrom = get(
    find(get(data, "content"), (k) => k.pageId === superLessonData?.from),
    "numberMasked",
    superLessonData?.from
  );

  const getMaskIdTo = get(
    find(get(data, "content"), (k) => k.pageId === superLessonData?.to),
    "numberMasked",
    superLessonData?.to
  );


  const { Option } = AntSelect;

  return (
    <>
      {open && (
        <LessonContainer>
          <LessonModal
            style={{ position: "relative" }}
            view={viewLessonType === "shared" && showShareList ? true : false}
          >
            <span
              onClick={() => {
                // setSelectedTeacherIds([]);
                setShowShareList(true);
                setFilterSharedLesson(filterShareLesson);
                setFilterMyData(filterLesson);
                onClose();
              }}
            >
              <CancelIcon
                style={{
                  float: "right",
                  fontSize:
                    viewLessonType === "shared" && showShareList ? 25 : 18,
                  color: "red",
                  background: "white",
                  borderRadius: 20,
                  cursor: "pointer",
                  marginBottom: 5
                }}
              />
            </span>
            {viewLessonType === "shared" && showShareList ? (
              <div className="shared-lesson-container">
                <div className="shared-lesson">Shared Differentiation</div>
                <div className="shared-lesson-title-container">
                  {map(filterSharedLesson, (item) => {
                    const findShareBy = allDistrictTeacher.find(
                      (obj) => item.userId === obj?.id
                    );
                    return (
                      <>
                        <div
                          className="share-by-lesson"
                          onClick={() => {
                            setShowShareList(false);
                            setFilterMyData(item);
                          }}
                        >
                          <span className="title-for-share">
                            {`${from !== 'ebook' ? 'Differentiation' : `Differentiation (P. ${getMaskIdFrom}-${getMaskIdTo})`}`}{" "}
                            -
                          </span>
                          <span className="shared-by-text">
                            &nbsp; shared by:
                          </span>
                          <span className="shared-by-teacher-name">{` ${findShareBy?.firstName} ${findShareBy?.lastName}`}</span>
                        </div>
                        <div className="share-by-line-lesson" />
                      </>
                    );
                  })}
                </div>
                <div
                  className="share-by-Back-lesson"
                  onClick={() => {
                    handleBack();
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <AntdButton
                      className="back-button-super-lesson"
                      type="primary"
                      loading={loader}
                      style={{
                        borderRadius: 10,
                        minWidth: 179,
                        backgroundColor: "#017216",
                        border: "1px solid white",
                        minHeight: 55,
                        fontSize: 22,
                      }}
                    >
                      {"Back"}
                    </AntdButton>
                  </span>
                </div>
              </div>
            ) : (
              <>
                <Container>
                  <div>
                    <div className="mainDifferentiationLession">
                      <SuperLessonLogoImg src={SuperLessonLogo} />
                      <div className="differentiationLessionHead">
                        <span>
                          Differentiation: Basic - Intermediate - Advance
                        </span>
                      </div>
                    </div>

                    {filterMyData?.userId === currentUser?.id ? (
                      <BoxContainer
                        style={{
                          marginRight: 6,
                          marginLeft: 108,
                          marginBottom: 22,
                        }}
                      >
                        <Label>Share With:</Label>
                        <FieldShareBox
                          style={{
                            background: "none",
                            flex: "1",
                            border: "none",
                            placeItems: "start",
                          }}
                        >
                          <Space
                            direction="vertical"
                            style={{
                              width: "100%",
                            }}
                          >
                            <AntSelect
                              dropdownStyle={{ minWidth: "300px" }}
                              tagRender={tagRender}
                              className="dropdown-select"
                              {...sharedProps}
                              {...selectProps}
                              maxTagPlaceholder={(omittedValues) => (
                                <Tooltip
                                  overlayStyle={{
                                    pointerEvents: "none",
                                  }}
                                  title={omittedValues.map(
                                    ({ label }) => label
                                  )}
                                >
                                  <span>...more</span>
                                </Tooltip>
                              )}
                            >
                              {getAllOptions.map((item) => (
                                <>
                                  <Option key={item.value} value={item.value}>
                                    {initialValues.includes(item?.value) ? (
                                      <span className="shared-text-super-lesson">
                                        Shared
                                      </span>
                                    ) : (
                                      <>
                                        {value.some(
                                          (data) => data.value === item?.value
                                        ) && (
                                            <CheckOutlined className="checkmark-icon-super-lesson" />
                                          )}
                                      </>
                                    )}
                                    <Space>
                                      {item.label.length > 19
                                        ? item?.label?.slice(0, 19) + "..."
                                        : item?.label}
                                    </Space>
                                  </Option>
                                </>
                              ))}
                            </AntSelect>
                          </Space>
                        </FieldShareBox>

                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // if (!AssignmentShareList?.includes(render?.id)) {
                            //   handleSend(render?.id)
                            // }
                            if(isDisable()){
                            handleShare();
                            }
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <AntdButton
                              className="share-button-super-lesson"
                              type="primary"
                              loading={loader}
                              disabled={!isDisable()}
                              style={{
                                borderRadius: 10,
                                border: "0px",
                                color: "black",
                                cursor: isDisable() ? "pointer": "not-allowed",
                                backgroundColor: isDisable() ? undefined: "#b3b3b3" ,
                              }}
                            >
                              Share
                            </AntdButton>
                          </span>
                        </div>
                        <div
                          style={{ cursor: "pointer", width: "50px" }}
                          onClick={() => {
                            validationPrint();
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <AntdButton
                              className="share-button-super-lesson"
                              type="primary"
                              style={{
                                borderRadius: 10,
                                border: "0px",
                                color: "black",
                              }}
                            >
                              {printLoader ? <Loading size={20} /> : <PrintIcon />}
                            </AntdButton>
                          </span>
                        </div>
                      </BoxContainer>
                    ) : (
                      <BoxContainer
                        style={{
                          marginRight: 6,
                          marginLeft: 108,
                          marginBottom: 22,
                        }}
                      >
                        <Label>Share By:</Label>
                        <FieldShareBox>
                          {`${filterByTeacher?.firstName} ${filterByTeacher?.lastName}`}
                        </FieldShareBox>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleShareDelete();
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <AntdButton
                              type="primary"
                              loading={loader}
                              style={{
                                borderRadius: 10,
                                minWidth: 108,
                                backgroundColor: color.greentrue,
                                border: "0px",
                              }}
                            >
                              {"Delete"}
                            </AntdButton>
                          </span>
                        </div>
                        <div
                          style={{ cursor: "pointer", width: "50px" }}
                          onClick={() => {
                            validationPrint();
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <AntdButton
                              className="share-button-super-lesson"
                              type="primary"
                              style={{
                                borderRadius: 10,
                                border: "0px",
                                color: "black",
                              }}
                            >
                              {printLoader ? <Loading size={20} /> : <PrintIcon />}
                            </AntdButton>
                          </span>
                        </div>
                      </BoxContainer>
                    )}
                  </div>
                </Container>
                <WhiteBox ref={imageRef}>
                  <div
                    style={{ maxHeight: "unset" }}
                    className="lesson-plan-container"
                    dangerouslySetInnerHTML={{
                      __html: convertToHtml(filterMyData?.lesson_content || ""),
                    }}
                  />
                </WhiteBox>
              </>
            )}
          </LessonModal>
        </LessonContainer>
      )}
    </>
  );
};

export default DifferentiationViewModal;
