//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createInteraction = () => ({ type: ActionTypes.interactions.createInteraction });
export const createInteractionFail = (returnedPayload) => ({ type: ActionTypes.interactions.createInteractionFail, payload: returnedPayload });
export const createInteractionSuccess = (returnedPayload) => ({ type: ActionTypes.interactions.createInteractionSuccess, payload: returnedPayload });

export const updateInteraction = () => ({ type: ActionTypes.interactions.updateInteraction });
export const updateInteractionFail = (returnedPayload) => ({ type: ActionTypes.interactions.updateInteractionFail, payload: returnedPayload });
export const updateInteractionSuccess = (returnedPayload) => ({ type: ActionTypes.interactions.updateInteractionSuccess, payload: returnedPayload });

export const deleteInteraction = () => ({ type: ActionTypes.interactions.deleteInteraction });
export const deleteInteractionFail = (returnedPayload) => ({ type: ActionTypes.interactions.deleteInteractionFail, payload: returnedPayload });
export const deleteInteractionSuccess = (returnedPayload) => ({ type: ActionTypes.interactions.deleteInteractionSuccess, payload: returnedPayload });

//  -- Get interaction ----------------
export const getInteraction = () => ({ type: ActionTypes.interactions.getInteraction });
export const getInteractionFail = (returnedPayload) => ({ type: ActionTypes.interactions.getInteractionFail, payload: returnedPayload });
export const getInteractionSuccess = (returnedPayload) => ({ type: ActionTypes.interactions.getInteractionSuccess, payload: returnedPayload });

//  -- List interactions --------------
export const listInteractions = () => ({ type: ActionTypes.interactions.listInteractions });
export const listInteractionsFail = (returnedPayload) => ({ type: ActionTypes.interactions.listInteractionsFail, payload: returnedPayload });
export const listInteractionsSuccess = (returnedPayload) => ({ type: ActionTypes.interactions.listInteractionsSuccess, payload: returnedPayload });

//  -- List chapter interactions ------
export const listChapterInteractions = () => ({ type: ActionTypes.interactions.listChapterInteractions });
export const listChapterInteractionsFail = (returnedPayload) => ({ type: ActionTypes.interactions.listChapterInteractionsFail, payload: returnedPayload });
export const listChapterInteractionsSuccess = (returnedPayload) => ({ type: ActionTypes.interactions.listChapterInteractionsSuccess, payload: returnedPayload });

//  -- List interactions by type ------
export const listInteractionsByType = () => ({ type: ActionTypes.interactions.listInteractionsByType });
export const listInteractionsByTypeFail = (returnedPayload) => ({ type: ActionTypes.interactions.listInteractionsByTypeFail, payload: returnedPayload });
export const listInteractionsByTypeSuccess = (returnedPayload) => ({ type: ActionTypes.interactions.listInteractionsByTypeSuccess, payload: returnedPayload });