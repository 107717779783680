//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from "react";
import { shape, string, number, func,array } from "prop-types";
import styled from "styled-components";
import { filter } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;

const Text = styled.div`
    // color: rgb(0 0 0 / 85%);
    font-size: 15px;
    color:white;
    white-space: pre-line;
`;

const TextContainer = styled.div`
    border-top: 1px solid ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding:0px 16px;
    background-color: #171E2D;
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;


const AssessmentCard = ({ data, handleClick , booksAllList}) => {
    // let activityLength=data.activities??0
    // let quizzLength=data.quizzes??0
    // if(Array.isArray(activities)){
    //     activityLength=activities.length
      
    // }
    // if(Array.isArray(quizzes)){
    //     quizzLength=quizzes?.length
    // }
    const getBookDesc = ((bookId) => {
        if(Array.isArray(booksAllList)){
             const bookD = filter(booksAllList,x => x.id == bookId);
            return bookD[0].description;
        }else{
            return ""
        }   
     });
    return (
        <Wrapper>
            <div className="card-resource">
                <a onClick={handleClick} href="#assignment">
                    <div className="card-resource-overlay"></div>
                    <Image className="card-resource-image" src={data.img} alt={getBookDesc(data.id)} />
                    <div className="card-resource-details fadeIn-bottom">
                        <h3 className="card-resource-title">{getBookDesc(data.id)}</h3>
                    </div>
                    {/* <TextContainer>
                        <Text>{`${activityLength} Activities`}</Text>
                        <Text>{`${quizzLength} Quizzes`}</Text>
                    </TextContainer> */}
                </a>
            </div>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentCard.propTypes = {
    data: shape({
        img: string,
        title: string,
        description: string,
    }),
    handleClick: func,
    activities: array,
    quizzes: array,
};

//  -- Default props ------------------
AssessmentCard.defaultProps = {
    data: {img: "", title: "", description: ""},
    activities: [],
    quizzes: [],
};

export default AssessmentCard;