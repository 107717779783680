//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState } from "react"
import { Close, AccountCircle } from "@material-ui/icons";
import { Avatar } from 'antd';
import { Modal, Typography, Grid, Paper } from "@material-ui/core";
import styled, { } from 'styled-components';
import COLOR from 'utils/constants/color';
import { makeStyles } from '@material-ui/core/styles';
import { find, get, map } from "lodash"
import moment  from 'moment';
import { getAvatar } from "utils/assets/images/lootie";


const UserList = styled.div`
    min-height: 40px;
    width: 98.5%;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    margin-top: 10px;
    background: transparent;
    border: none;
    color: #fff;
    text-align: left;
    display:flex;
    flex-direction:row;
    font-size: 12px;
    text-transform: capitalize;
    align-items: center;
`;

const UserModal = styled.div`
    left: 50%;
    max-width: 400px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const UserPopUpArea = styled.div`
    background-color: rgb(82,84,82);
    border-radius: 10px;
    padding-right: 0px;
    padding-left: 10px;
    position:relative;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px){
        padding: 15px;
    }
`;
const CloseBtn = styled.div`
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    z-index: 1;
`;
const UserArea = styled.div`
    display:flex;
    flex-direction:column;
    overflow:auto;
    overflow-x:hidden;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    max-height: 70vh;
    padding: 10px;
`;
const UserRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction:row;
    width:100%;
    position: relative;
`;
const AvtarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    position:relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
`;
const NameCol = styled.div`
    display: flex;
    align-items: center;
    width:83%;
    flex-direction:column;
    @media (max-width: 768px) { 
      width: 88%;
      margin-right: 12px;
    }  
`;
const NameInnerRow = styled.div`
    display: flex;
    width:100%;
    color: #fff;
    font-weight: bold;
`;


const useStyles = makeStyles((theme) => ({
    titleText: {
        fontWeight: 400,
        color: 'rgb(255, 255, 255)',
        fontSize: 20,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 17
    },
    usericonPopUp: {
        color: COLOR.white,
        fontSize: '30px',
        marginRight: ' 5px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    backgroundTransparent: {
        background: "transparent"
    },
    closeIcon: {
        color: 'rgb(255, 255, 255)',
        fontWeight: "bolder",
        fontSize: '25px',
        position: 'absolute',
        right: '-15px',
        top: '-15px',
        background: 'red',
        borderRadius: '50%'
    }
}));

export const MessageSeenModal = ({ classUsers, seenUsers, deliverdUsers, showModal, closeModal }) => {
    const cssClass = useStyles()
    const [seenUser, setSeenUser] = useState([])
    const [deliveredUser, setdeliveredUser] = useState([])
    const [undeliverdUser, setUndeliverdUser] = useState([])
    const getUserIcon = (usr) => {
        const avatar = getAvatar(get(usr, "config.avatar"))
        return (avatar ? <Avatar size={30} src={avatar} /> : <AccountCircle className={cssClass.usericonPopUp} />)
    }
    useEffect(() => {
        const _seenUser = []
        const _deliveredUser = []
        const _undeliverdUser = []
        map(classUsers, cu => {
            const hasSeen = find(seenUsers, ["id", get(cu, "id")])
            const hasDeliverd = find(deliverdUsers, ["id", get(cu, "id")])
            if (hasSeen) {
                _seenUser.push({ ...cu, ...hasSeen })
            } else if (hasDeliverd) {
                _deliveredUser.push({ ...cu, ...hasDeliverd })
            } else {
                _undeliverdUser.push(cu)
            }
            setSeenUser(_seenUser)
            setdeliveredUser(_deliveredUser)
            setUndeliverdUser(_undeliverdUser)
        })
    }, [classUsers, seenUsers, deliverdUsers])
    const renderSeenDeliverd = (usr, type, date) => {
        return (<UserList key={get(usr, "id")}>
            <UserRow>
                <AvtarContainer>
                    {getUserIcon(usr)}
                </AvtarContainer>
                <NameCol>
                    <NameInnerRow style={{ color: COLOR.white, paddingLeft: 5 }}>  {get(usr, "lastName", "")} ,  {get(usr, "firstName", "")}  </NameInnerRow>
                    {date&&<NameInnerRow style={{ color: COLOR.white, paddingLeft: 5 , fontSize:10, fontWeight:'200' , textTransform:"lowercase" }}>{moment(date).format("MM-DD-YYYY   hh:mm a")} </NameInnerRow>}
                </NameCol>
            </UserRow>
        </UserList>)
    }
    return (<>
        <Modal
            open={showModal}
            onClose={closeModal}
            style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            className="avtarModl">
            <UserModal className="avtarUsrModl" style={{padding:10}}>
                <UserPopUpArea className="avtarUsrArea">
                    <CloseBtn className={cssClass.closeIcon} onClick={closeModal}>
                        <Close style={{ color: COLOR.white, fontWeight: "bolder", fontSize: 25 }} />
                    </CloseBtn>
                    <UserArea>
                        <Grid container spacing={1} className={cssClass.backgroundTransparent}>
                            {seenUser.length > 0 && <Grid item xs={12} className={cssClass.backgroundTransparent}>
                                <Paper className={cssClass.paper} style={{ background: "transparent" }} elevation={0}>
                                    <Typography variant="h4" className={cssClass.titleText}>Seen</Typography>
                                    {map(seenUser, usr => {
                                        return renderSeenDeliverd(usr, "seen", get(usr, "readAt"))
                                    })}
                                </Paper>
                            </Grid>}
                            {deliveredUser.length > 0 && <Grid item xs={12} className={cssClass.backgroundTransparent}>
                                <Paper className={cssClass.paper} style={{ background: "transparent" }} elevation={0}>
                                    <Typography variant="h4" className={cssClass.titleText}>Delivered</Typography>
                                    {map(deliveredUser, usr => {
                                        return renderSeenDeliverd(usr, "delivered", get(usr, "deliverAt"))
                                    })}
                                </Paper>
                            </Grid>}
                            {undeliverdUser.length > 0 && <Grid item xs={12} className={cssClass.backgroundTransparent}>
                                <Paper className={cssClass.paper} style={{ background: "transparent" }} elevation={0}>
                                    <Typography variant="h4" className={cssClass.titleText}>Unseen</Typography>
                                    {map(undeliverdUser, usr => {
                                        return renderSeenDeliverd(usr, "undelived", null)
                                    })}
                                </Paper>
                            </Grid>}
                        </Grid>
                    </UserArea>
                </UserPopUpArea>
            </UserModal>
        </Modal>
    </>)
}