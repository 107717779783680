//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { AddToPhotos, Delete } from "@material-ui/icons";
//  -- Components ---------------------

//  -- Constants ----------------------
import { Modal , Button, IconButton } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { findIndex , get , find} from "lodash";
import COLOR from 'utils/constants/color';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useAllBook } from "store/books/selectors";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Row , Col, Progress} from "antd";
import { useClassSubmissions } from "store/submissions/selectors";
import { handleCreateSubmission, handleUpdateSubmission  } from 'store/submissions/thunks';
import { handleUpdateCanvasGrade , handleCreateCanvaSubmissions } from 'store/canvas/thunks';
import { handleCreateNotification } from 'store/notification/thunks';
import { useSocket } from 'store/chat/selectors';
import { handleUpdateGradeToGoogleClassRoom } from "store/googleClassRoom/thunks"
import { useCurrentClass } from "store/classes/selectors";
import { useCurrentUser } from "store/users/selectors";
import Schoology from 'utils/Schoology';
import { useSchoologyAuth } from "store/schoologyauth/selectors";
import { useServertime } from "store/servertime/selectors";
import { useOrgTimezone } from "store/organizations/selectors";
import { isBeforTime } from "utils/methods/math";
import { useDispatch } from "react-redux";
import Loading from "./Loading";
import { storage } from 'firebaseConfig';
import { ref as fireRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { FileUploader } from "react-drag-drop-files";
import moment from "moment-timezone";
const fileTypes = ["JPG", "PNG","JPEG"];

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiInput-underline:before': {
        borderBottomColor: (props) => (props.hasBorder ? 'black' : 'transparent'), // default bottom border color
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: (props) => (props.hasBorder ? 'black' : 'transparent'), // bottom border color on hover
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: (props) => (props.hasBorder ? 'black' : 'transparent'), // bottom border color when focused
      },
      '& .MuiInputBase-root': {
        padding: theme.spacing(1),
        width:"100%"
      },
    },
    input: {
      fontSize: 16,
    },
  }));

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  position: relative;
  justify-content: center;
  padding: 20px;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const CloseBtn = styled.div`
  background-color: rgb(204, 80, 71);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
`;

const PrintWrapper = styled.div`
  display: flex;
`;

const Images = styled.img`
  background-color: ${COLOR.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  object-fit: cover;
  height: 600px;
   height: 96vh;
`;
const UploadedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    cursor: pointer;
`;
const ButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  color: ${COLOR.black600};
  cursor: pointer;
  height: 40px;
  left: ${(p) => `${p.left}px`};
  outline: none;
  position: absolute;
  right: ${(p) => `${p.right}px`};
  top: 50%;
  transition: all 300ms ease;
  width: 40px;
  z-index: 1;
`;
const AnswerWindowWrapper = styled.div`
   position: relative;
   width: 100%;
`;
const FileUploadArea = styled.div`
    height: 100%;
    border: 1px dashed gray;
    padding: 5px 12px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
`;
const Column = styled(Col)`
display: flex;
    justify-content: center;
    align-items: center;
}
`;
const Text = styled.div`
    font-size: 12px;
`;
const ImageModal = styled.div`
    left: 50%;
    max-width: 544px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;
function ImageUploader({ src, onChange, positions }) {
    const [pagePoster, setpagePoster] = useState(src)
    const [loader, setLoader] = useState(false)
    const [loadingMap, setLoadingMap] = useState(0)
    const [modalOpen, setModalState] = useState(false)
    useEffect(() => {
        setpagePoster(src)
    }, [src])
    const handleChange = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        uploadToStorage(file)
        reader.onloadend = function (e) {
            let v = reader.result;
            setpagePoster(v)
        }.bind(this);
    };
    const uploadToStorage = async (file) => {
        if (file) {
            const location = '/assignments/graphic_organizer';
            const id = `${moment().format('YYYY-MM-DD HH:mm:ss')}-${file.name}`;
            const fileRef = fireRef(storage, `${location}/${id}`);

            try {
                const uploadTask = uploadBytesResumable(fileRef, file);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        const per = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setLoadingMap(per);
                        setLoader(true);
                    },
                    (error) => {
                        console.error("Upload error:", error);
                        setLoader(false);
                        setLoadingMap(0);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setLoader(false);
                            setLoadingMap(0);
                            onChange(downloadURL);
                        });
                    }
                );
            } catch (error) {
                console.error("Upload error:", error);
                setLoader(false);
                setLoadingMap(0);
            }
        } else {
            console.log("No file to upload.");
        }
    };
    const max_height = positions.max_height ? { maxHeight: positions.max_height } : {};
    const min_height = positions.min_height ? { minHeight: positions.min_height } : { minHeight:"50px"};
    return (<>
        <Modal
            open={modalOpen}
            onClose={() => setModalState(false)}
            style={{ height: '100%', width: '100%' , zIndex:9999999999999}}
        >
            <ImageModal>
                <Image src={pagePoster} alt='' />
            </ImageModal>
        </Modal>
        <ImageContainer onContextMenu={(e) => { }} style={{
            position: 'absolute',
            width: `calc(${positions.width} * 100%)`,
            height: positions.height ? `calc(${positions.height} * 100%)` : 'auto',
            left: `calc(${positions.left} * 100%)`,
            top: `calc(${positions.top} * 100%)`,
            ...max_height,
            ...min_height,
            justifyContent: "center",
            alignItems: 'center',
            display: "flex",
        }}>
            {pagePoster ? <>
                <UploadedImage onClick={() => setModalState(true)} src={pagePoster} />
                <IconButton color="secondary" style={{ position: "absolute", right: 0, top: -15 , zIndex:9999 , padding:0 }} onClick={() => setpagePoster(null)}><Delete /></IconButton>
            </> :
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}

                >
                    <FileUploadArea>
                        {loader && <Progress type="line" percent={loadingMap} size={"small"} className={"fileProgress"} showInfo={false} />}
                        <Row className="box box-1" style={{ display: "flex", gap: 4 }}>
                            <Column >
                                <AddToPhotos color="primary" />
                            </Column>
                            <Column>
                                <Text>
                                    Upload here
                                </Text>
                            </Column>
                        </Row>
                        <Row>
                            <Text style={{ fontSize: 8 }}>
                                {fileTypes.join(",")}
                            </Text>
                        </Row>

                    </FileUploadArea>
                </FileUploader>}
        </ImageContainer>
    </>)
}

const InputArea = ({ positions, onChange, value }) => {
    const classes = useStyles({ hasBorder: typeof positions.bottom_border === "boolean" ? positions.bottom_border : true });

    if (positions.input_type === "image") {
        return (<ImageUploader src={value} onChange={onChange} positions={positions} />)
    }
    const max_height = positions.max_height ? { maxHeight: positions.max_height } : {};
    const min_height = positions.min_height ? { minHeight: positions.min_height } : {};

    return (
        <TextField
            className={classes.root}
            InputProps={{ classes: { input: classes.input } }}
            InputLabelProps={{ shrink: true }}
            multiline={positions.text_length === "multi_line"}
            style={{
                position: 'absolute',
                width: `calc(${positions.width} * 100%)`,
                height:positions.height?`calc(${positions.height} * 100%)`:'auto',
                left: `calc(${positions.left} * 100%)`,
                top: `calc(${positions.top} * 100%)`,
                justifyContent: "center",
                overflow:"auto",
                alignItems: 'center',
                ...max_height,
                ...min_height
            }}
            placeholder={positions.placeholder || ''}
            onChange={e => onChange(e.target.value)}
            value={value}
            label=""
        />
    );
};
  const AnswerWindow = ({ src ,input_position , onSunmit , isLoading , submission }) => {
    const [inputs, setInputs] = useState(input_position||[]);
    const [values,setValues] = useState([])
    const [loader,setLoader] = useState('submit');
    const onValueChange = (v,index, type) =>{
       let vals = [...values];
        vals[index] = ({ answer: v, type });
       setValues(vals);
    }
    useEffect(()=>{
        if(submission&&Array.isArray(submission.answers)){
            setValues(submission.answers)
        }
       
    }, [submission])
    const onSubmission = () =>{
        onSunmit(values,true) 
        setLoader('submit')
    }
    const onFinishLater = () =>{
        onSunmit(values,false) 
        setLoader('finish_later')
    }
    const submit_disbale = inputs.length !== values.filter(k=>k).length  || isLoading
      return (
          <AnswerWindowWrapper>
              <Images src={src} />
              <Button
                  startIcon={loader === "finish_later" && isLoading ? <Loading size={12} white /> : null}
                  disabled={isLoading} 
                  style={{ position: "absolute", top: 6, right: 110, backgroundColor: COLOR.green100, color: "#fff"  }} 
                  onClick={onFinishLater}>Finish later</Button>
              <Button
                  startIcon={loader === "submit" && isLoading ? <Loading size={12} white /> : null}
                  disabled={submit_disbale} style={{ position: "absolute", top: 6, right: 30, backgroundColor: COLOR.green100, color: "#fff", opacity: submit_disbale ? 0.5 : 1 }} onClick={onSubmission}>Submit</Button>
              {inputs.map((input, index) => (
                  <InputArea key={index} positions={input} value={values[index]?.answer} onChange={v => onValueChange(v, index, input.input_type)} />
              ))}
          </AnswerWindowWrapper>
      );
  };
  

const GraphicAssignmentTake = ({
   open ,
   setOpen,
   assignment
}) => {
    const dispatch = useDispatch();
    const backBtnRef = useRef(null);
    const forwardBtnRef = useRef(null);
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const currentClass  = useCurrentClass()
    const currentUser = useCurrentUser();
    const schoologyAuth= useSchoologyAuth()
    const client = new Schoology(schoologyAuth)
    const booksAllList = useAllBook()
    const servertime = useServertime()
    const timezone = useOrgTimezone()
    const {socketConnection:socket , reCreateSocket } = useSocket()
    const [alertSakeBar, setAlertSakeBar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const selected_book = useMemo(() => find(booksAllList, { id: assignment.data.chapter }), [booksAllList,assignment]);
    const graphic_book = useMemo(() => find(booksAllList, { id: '7' }), [booksAllList]);
    const select_book_pages = useMemo(() => get(selected_book, "pages", []), [selected_book]);
    const graphic_book_pages = useMemo(() => get(graphic_book, "pages", []), [graphic_book]);
    const pageId = useMemo(() => get(assignment, "data.assignment_page"), [assignment])
    const currentPageResourse = useMemo(() => get(find(graphic_book_pages, (obj) => obj.pageId == pageId), "resource"), [assignment,graphic_book_pages])
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const activePageResource = useMemo(() => select_book_pages[currentPageIndex]?.resource, [currentPageIndex, select_book_pages]);
    const input_position = useMemo(() => get(assignment, "data.input_position", []) || [], [assignment])
    const submissions = useClassSubmissions()
    const currentSubmission = useMemo(()=>find(submissions, ['assignmentId', assignment?.data.id]), [assignment,submissions]) 
    const data = useMemo(()=>get(assignment, "data",{}) || [], [assignment])
    const grade_type = assignment?.grade_type;
    const isCompleteInComplete = grade_type === "complete_incomplete"
    const max_point = assignment?.max_point && !isNaN(assignment?.max_point) ? Number.parseInt(assignment.max_point, 10) : 100
    const isPointSystem =  grade_type === "points"
    const [loading,setLoading] = useState(false)
    function checkKey(e) {
        e = e || window.event;
        if (e.keyCode == '37') {
            if (backBtnRef.current && !backBtnRef.current.disabled) {
                backBtnRef.current.click()
            }

        }
        else if (e.keyCode == '39') {
            if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                forwardBtnRef.current.click()
            }

        }

    }

    useEffect(() => {
        let pg = get(assignment, "assigned_on_page")
        const index = findIndex(select_book_pages, (obj) => obj.pageId == pg)
        setCurrentPageIndex(index);
    }, [select_book_pages, pageId])

    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", checkKey)
        }
    }, [])

    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: "", severity: "success" });
    };

    const onPageChange = (direction) => {
        if (direction === "right" && currentPageIndex < select_book_pages.length - 1) {
            setCurrentPageIndex(currentPageIndex + 1);
        } else if (direction === "left" && currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };
    const sendNotifications = async (notificationData) => {
        try {
            const updateNotification = await dispatch(handleCreateNotification(notificationData))
            const messageCreate = {
                ...updateNotification,
                senderId: get(currentUser, 'id', ''),
                receiverId: get(notificationData, 'createdBy', ''),
                // classId: get(currentClass, 'id', ''),
                code: 'system_notification'
            }
            invokSend(messageCreate)
        } catch (error) {
            console.log(error, "error")
        }
    }

    const invokSend = (data) => {
        if (socket) {
            try {
                if (socket.readyState == 3) {
                    reCreateSocket()
                    setTimeout(() => {
                        socket.send(JSON.stringify(data))
                    }, 2000)
                } else {
                    socket.send(JSON.stringify(data))
                }
            } catch (error) {
                console.log(error, "error")
            }
        }
    }
    const updateGradeToCanvas = async (_val) => {
        if (currentUser && currentUser.canvas_id && currentClass.canvas_id) {
            let assignmentId = assignment?.canvas_id ||  assignment?.data?.canvas_id
                await handleUpdateCanvasGrade(currentUser.canvas_id,{
                    "canvas_assignment_id": assignmentId,
                    "course_id":currentClass.canvas_course_id,
                    "canvas_user_id":currentUser.canvas_id,
                    "grade": isCompleteInComplete?'complete': _val
                },currentUser.canvas_auth_id)
        }
    }
    const canvasSubmissionCreate = async () => {
        if (currentUser && currentUser.canvas_id && currentClass.canvas_id) {
            let assignmentId = assignment?.canvas_id ||  assignment?.data?.canvas_id
                await handleCreateCanvaSubmissions(currentUser.canvas_id,{
                    "canvas_assignment_id": assignmentId,
                    "course_id":currentClass.canvas_course_id,
                    "canvas_user_id":currentUser.canvas_id,
                },currentUser.canvas_auth_id)
        }
    }
    const updateGradetoSchoology = (_val, statusCode) => {
        if (currentClass && currentClass.sectionId && currentUser && currentUser.schoologyid) {
            try {
                client.getEnrollmentsBySectionId(currentClass?.sectionId).then(async res => {
                    if (res && res.enrollment) {
                        let userEnrollment = find(res.enrollment, { uid: currentUser.schoologyid });
                        if (!assignment.sAssignmentId) {
                            const _assignments = await client.getSectionAssignment(currentClass?.sectionId);
                            const all_assignment = await client.getSectionAssignmentWithLimit(currentClass?.sectionId, _assignments.total);
                            const assignmentf = find(all_assignment?.assignment, { title: assignment.displayName });
                            if (assignmentf) {
                                assignment.sAssignmentId = assignmentf.id;
                            }
                        }
                        if (userEnrollment && assignment?.sAssignmentId) {
                            client.getSectionAssignmentById(currentClass?.sectionId, assignment.sAssignmentId).then(res => {
                                if (res && res.id) {
                                    let _data = {
                                        "grades": {
                                            "grade": [
                                                {
                                                    "type": "assignment",
                                                    "assignment_id": assignment.sAssignmentId,
                                                    "enrollment_id": userEnrollment.id,
                                                    "grade": _val,
                                                    "exception": statusCode || 0,
                                                    "comment": "grade updated from lausd-grade-book"
                                                }

                                            ]
                                        }
                                    }
                                    client.updateGrades(currentClass?.sectionId, _data).then(res => {
                                        console.log("updated grade", res);
                                    })
                                }
                            })
                        }
                    }
                })
            } catch (error) {
                console.log(error, "issue in update grade");
            }
        }
    }
    const updateGradetoGoogleClassRoom = async (_val) => {
        if (currentClass && currentClass.googleId && currentUser && currentUser.googleId) {
            try {
                let assignmentId = assignment?.googleId || assignment?.data?.googleId
                await handleUpdateGradeToGoogleClassRoom(currentUser.googleId, currentUser.googleId, currentClass.googleId, assignmentId, _val)
            } catch (error) {
                console.log(error, "issue in update grade to classroom");
            }
        }
    }
    const handleRedirect = () =>{
        if(history.location.pathname.includes("ebook")){
            history.push(`/ebook?chapter=${data?.chapter}&page=${data?.page}`)
        }else{
            const parsedQuery = queryString.parse(history.location.search);
            delete parsedQuery.assignment_type;
            delete parsedQuery.activity_id;
            const updatedQueryString = queryString.stringify(parsedQuery);
            history.replace(`${history.location.pathname}?${updatedQueryString}`);
        }
       
    }
    const sendNotiifcationFinal  = async () =>{
        if(!assignment) return;
        setOpen(false);
        handleRedirect()
        const chapter =  assignment.data.chapter;
        const page =  assignment.data.page;
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(data, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter:chapter,
                page: page,
                id: get(assignment, 'data.id', ''),
            },
            message: `${get(currentUser, 'firstName', '')} ${get(currentUser, 'lastName', '')} Submit this Assignment`,
            createdBy: get(currentClass, 'teacherId', ''),
            type: 'update_activity',
            seen: true
        }
        sendNotifications(notificationData);
    }
    const updateAnswers = async (answers,complete=true) => {
        if (!assignment) return;
        const chapter = assignment.data.chapter;
        const page = assignment.data.page;
        let isLate = false
        if (assignment?.dueDate) {
            isLate = isBeforTime(assignment?.dueDate, servertime, timezone);
        }
        if (!currentSubmission) {
            setLoading(true);
            const submission_data = await dispatch(handleCreateSubmission({
                assignmentId: data.id,
                assignmentType: data.typeId,
                classId: currentClass.id,
                orgId: currentUser?.orgId,
                userId: currentUser?.id,
                chapter: chapter,
                grade: isCompleteInComplete && complete ? '100' : null,
                face_grade: isCompleteInComplete && complete ? max_point : null,
                answers: answers,
                complete: complete,
                finishLater: null,
                submissions: 1,
                retakes: 0,
                retaking: false,
                graded: false,
                timmer: 0,
                isLate,
                manualGrading: true
            }))
            sendNotiifcationFinal()
            setLoading(false);
        }
        else {
            setLoading(true);
            await dispatch(handleUpdateSubmission(
                currentSubmission.id,
                {
                    answers: answers,
                    complete: complete,
                    grade: isCompleteInComplete && complete? 100 : currentSubmission.grade,
                    face_grade: currentSubmission.face_grade,
                    submissions: currentSubmission.submissions ? (parseInt(currentSubmission.submissions, 10) || 0) + 1 : 1,
                    retaking: false,
                    graded: false,
                    feedback: '',
                    timmer: 0,
                    finishLater: null,
                    isLate,
                    assignment: assignment.data
                }
            ));
            sendNotiifcationFinal()
            setLoading(false)
        }
        if (isCompleteInComplete) {
            updateGradeToCanvas('complete')
        }
        setLoading(false)
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={alertSakeBar.open}
                autoHideDuration={3000}
                onClose={closeAlert}
            >
                <Alert
                    onClose={() => {
                        setAlertSakeBar({ open: false, message: "" });
                    }}
                    severity={alertSakeBar.severity}
                >
                    <strong>ALERT:</strong> {alertSakeBar.message}
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                className={"lessonPlanPopUp"}
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                }}
            >
                <PlanWrapper>
                    <PlanContainer>
                        <CloseBtn
                            onClick={(e) => {
                                e.preventDefault();
                                setOpen(false);
                            }}
                        >
                            <Close
                                style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
                            />
                        </CloseBtn>
                        <ButtonContainer
                            ref={backBtnRef}
                            disabled={currentPageIndex === 0}
                            onClick={(e) => {
                                e.preventDefault();
                                onPageChange("left");
                            }}
                            left={8}
                        >
                            <ArrowBackIos style={{ fontSize: "40px" }} />
                        </ButtonContainer>
                        <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={currentPageIndex >= select_book_pages.length - 2}
                            onClick={() => {
                                onPageChange("right");
                            }}
                            right={0}
                        >
                            <ArrowForwardIos style={{ fontSize: "40px" }} />
                        </ButtonContainer>
                        <Container>
                            <PrintWrapper>
                                <Row style={{ width: "100%", height: "100%" , maxWidth:"100%" , padding:0}}>
                                    <Col lg={12} md={12} xl={12} xs={24} >
                                        <Images
                                            src={activePageResource}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} xl={12} xs={24} >
                                        <AnswerWindow src={currentPageResourse} input_position={input_position}  onSunmit={updateAnswers} isLoading={loading} submission={currentSubmission}/>
                                    </Col>
                                </Row>
                            </PrintWrapper>
                        </Container>
                    </PlanContainer>
                </PlanWrapper>
            </Modal>
        </>
    );
};

export default GraphicAssignmentTake;
