//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _, { omit , get } from 'lodash';
//  -- Actions ------------------------
import * as ClassActivityActions from './actions';
import * as Sentry from "@sentry/react";
import { getDateTime } from 'utils/methods/math';
import moment from 'moment';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create class activity ----------
export const handleCreateClassActivity = (data) => async (dispatch) => {
    await dispatch(ClassActivityActions.createClassActivity());

    try {
        data.scheduleAt =data.scheduleAt&&moment(data.scheduleAt).isValid()?data.scheduleAt: moment().toString()
        let dataClassActivity = omit(data, ['studentsSelected', 'dueDateDif', 'retakesDif']);
        if (dataClassActivity.classes && dataClassActivity.classes.length > 0) {
            dataClassActivity.classes = dataClassActivity.classes.map(cls => ({ ...cls, users:cls.users?cls.users:[]}))
        }
        const diffActivity = [];
        let dataSS = data.studentsSelected;
        if (Array.isArray(dataSS)) {
            dataSS.map(student => {
                diffActivity.push({
                    classId: data.classId,
                    type: 'activity',
                    exerciseId: data.activityId,
                    userId: student.studentId,
                    dueDate: data.dueDateDif?data.dueDateDif:data.dueDate,
                    retakes: data.retakesDif?data.retakesDif:data.retakes,
                    displayName: data.displayName,
                    scheduleAt: data.scheduleAtDiff ? data.scheduleAtDiff: data.scheduleAt ,
                    lateDate: data.lateDate,
                })
            })
        }
        const clsAct = await API.post('classActivitiesRDS', `/classActivitiesDiff`, {
            body: { activity: dataClassActivity, diff: diffActivity  }
        });
        if (clsAct && !clsAct.error) {
            const update = {
                ...clsAct.activity.filter(act => act.classId == data.classId)[0],
                diff:get(clsAct,"diff",[]),
                data: omit(dataClassActivity, ['activityId', 'classId', 'dueData'])
            };
            await dispatch(ClassActivityActions.createClassActivitySuccess(update));
        }

    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassActivityActions.createClassActivityFail(error));
    }
};

//  -- Delete class activity ----------
export const handleDeleteClassActivity = (activityID, classId) => async (dispatch) => {
    if (!activityID) return;
    await dispatch(ClassActivityActions.deleteClassActivity());

    try {
        await API.del('classActivitiesRDS', `/classActivitiesDiff/${activityID}?classId=${classId}`);

        await dispatch(ClassActivityActions.deleteClassActivitySuccess({ id: activityID }));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassActivityActions.deleteClassActivityFail(error));
    }
};

//  -- List class activities ----------
export const handleListClassActivities = (classID) => async (dispatch) => {
    if (!classID) return;
    await dispatch(ClassActivityActions.listClassActivities());

    try {
        let activityData = [];
        const activitiesRDS = await API.get('classActivitiesRDS', `/classActivitiesData/${classID}`);
        if (Array.isArray(activitiesRDS) && activitiesRDS.length > 0) {
            activityData = activitiesRDS;
        }
        await dispatch(ClassActivityActions.listClassActivitiesSuccess(activityData));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassActivityActions.listClassActivitiesFail(error));
    }
};

export const handleListClassActivitiesForStudent = (classId,userId) => async (dispatch) => {
    if (!classId) return;
    await dispatch(ClassActivityActions.listClassActivities());

    try {
        let activityData = [];
        const activitiesRDS = await API.get('classActivitiesRDS', `/classActivityDataStudent/${classId}/${userId}`);
        if (Array.isArray(activitiesRDS) && activitiesRDS.length > 0) {
            activityData = activitiesRDS;
        }
        await dispatch(ClassActivityActions.listClassActivitiesSuccess(activityData));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassActivityActions.listClassActivitiesFail(error));
    }
};

//  -- Update class activity ----------
export const handleUpdateClassActivity = (activityID, data, infoDiffSection, toCreateStudent, activityInfo, updateTOAllClass) => async (dispatch) => {
    // await dispatch(ClassActivityActions.updateClassActivity());
    const diffUpdate = [];
    const diffCreate = [];
    try {
        if (get(infoDiffSection,"diff.length",0) > 0) {
            let infoDiff = infoDiffSection.diff;
            for (var i in infoDiff) {
                let idDiff = infoDiff[i].id;
                let bodyDiff = {
                    id: idDiff,
                    classId: infoDiff[i].classId,
                    dueDate: infoDiffSection.dueDateDif,
                    retakes: infoDiffSection.retakesDif || 0,
                    levelExercise: infoDiffSection.levelExercise || "",
                    scheduleAt:  infoDiffSection.scheduleAtDiff  ||  infoDiffSection.scheduleAt ,
                    lateDate: infoDiffSection.lateDate,
                }
                diffUpdate.push(bodyDiff)
            }
        }

        if (Array.isArray(toCreateStudent) && toCreateStudent.length > 0 && typeof activityInfo === 'object') {

            for (var s in toCreateStudent) {
                let body = {
                    classId: activityInfo.classId,
                    type: 'activity',
                    exerciseId: activityInfo.activityId,
                    userId: toCreateStudent[s].studentId,
                    dueDate: infoDiffSection.dueDateDif,
                    retakes: infoDiffSection.retakesDif || 0,
                    levelExercise: infoDiffSection.levelExercise || "",
                    scheduleAt: infoDiffSection.scheduleAtDiff ,
                    lateDate: infoDiffSection.lateDate,
                }
                diffCreate.push(body)
            }
        }
        let body = {
            activityID,
            activity: data,
            diffUpdate,
            diffCreate,
            updateTOAllClass
        }
        await API.put('classActivitiesRDS', `/classActivitiesDiff`, { body });
        await dispatch(ClassActivityActions.updateClassActivitySuccess({ id: activityID, ...data }));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassActivityActions.updateClassActivityFail(error));
    }
};
export const handleChangeStatusActivity = (activityID, active) => async (dispatch) => {
    if (!activityID) return;
    try {
        const statusData = await API.put('classActivitiesRDS', `/changeStatus`, {
            body: {
                id: activityID,
                active: active
            }
        });
        if (statusData) {
            await dispatch(ClassActivityActions.updateClassActivitySuccess({ id: activityID, ...statusData }));
        }
        return statusData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleDeactivateByUser = (payload) => async (dispatch) => {
    if (!payload) return;
    try {
        const statusData = await API.post('classActivitiesRDS', `/deactivateStudent`, {
            body:payload
        });
        if (statusData) {
            const reduxPayload = {
                assignmentId: payload?.id,
                deactivateStudent:  statusData?.deactivated_student
            }
            await dispatch(ClassActivityActions.createDeactivateByStudent(reduxPayload));
        }
        return statusData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};