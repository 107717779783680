//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createHowToVideosSuccess = (returnedPayload) => ({ type: ActionTypes.howToVideos.createHowToVideosSuccess, payload: returnedPayload });

export const updateHowToVideosSuccess = (returnedPayload) => ({ type: ActionTypes.howToVideos.updateHowToVideosSuccess, payload: returnedPayload });

export const deleteHowToVideosSuccess = (returnedPayload) => ({ type: ActionTypes.howToVideos.deleteHowToVideosSuccess, payload: returnedPayload });

export const listHowToVideosSuccess = (returnedPayload) => ({ type: ActionTypes.howToVideos.listHowToVideosSuccess, payload: returnedPayload });

