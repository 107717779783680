//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { CaretDownOutlined, MenuOutlined } from '@ant-design/icons';
import { Badge, FormControlLabel, Modal, Radio, RadioGroup } from '@material-ui/core';
import Snackbar from "@material-ui/core/Snackbar";
import { Close } from '@material-ui/icons';
import Alert from "@material-ui/lab/Alert";
import Loading from 'components/Loading';
import WheelModal from 'components/Wheel'
//  -- Components ---------------------
import { Card, Col, Drawer, Dropdown, Menu, Row, Tooltip, Badge as Badges } from 'antd';
import DemoModal from 'components/DemoModal';
import InitialLoading from 'components/InitialLoading';
import LogoIcon from 'components/LogoIcon';

//  -- Features -----------------------
import AnswerForm from 'features/forms/Answer';
import _, { filter, find, get, groupBy, isEmpty, map, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//  -- Thunks -------------------------
import { handleListActivities, handleListCustomAssignment } from 'store/activities/thunks';
import { handleFetchUserData, handleLogout } from 'store/auth/thunks';
import { handleListBookUnits } from "store/booksUnits/thunks";
import { handleGetClass } from 'store/classes/thunks';
import { handleListUserQuestions } from 'store/questions/thunks';
import { handleListQuizzes } from 'store/quizzes/thunks';
import { handleGetServertime } from 'store/servertime/thunks';
import { handleListUserBooks } from 'store/userBooks/thunks';
import { handleListClassUsers, handleListUserClasses } from 'store/userClasses/thunks';
import { useDemoStatus, useProfilePicturePicker, useUserProfileConfig } from "store/users/selectors";
import styled from 'styled-components';
//  -- Constants ----------------------
// import OneWayDebateModal from './OneWayDebateModal';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupIcon from '@material-ui/icons/Group';
import ReportIcon from '@material-ui/icons/Report';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ActiveStudentStatus, { MessageAlertViewer } from 'components/ActiveStudentStatus';
import AssignmentReviewByNotify from 'components/AssignmentReviewByNotify';
import BottomIcons from 'components/HomeMenu/BottomIcons';
import AdminMenu from "components/HomeMenu/admin";
import DistrictAdminMenu from "components/HomeMenu/district_admin_new";
import { ProfileAvatarPicker } from 'components/ProfileImagePicker';
import { useAlertView } from 'store/alert/selectors';
import { handleListApExams } from 'store/apexams/thunks';
import { handleListAllBooks, handleListBookOrder } from 'store/books/thunks';
import { useBookUnits } from 'store/booksUnits/selectors';
import { useChatMessage, useSocket } from 'store/chat/selectors';
import { handleListChatInfo, handleMessageChat } from "store/chat/thunks";
import { useClassActivities } from 'store/classActivities/selectors';
import { handleListClassActivities } from 'store/classActivities/thunks';
import { useClassQuizzes } from 'store/classQuizzes/selectors';
import { handleListClassQuizzes } from 'store/classQuizzes/thunks';
import { handleListExams } from 'store/exams/thunks';
import { handleInvokeFeedbackForm, handleRevokeFeedbackForm } from 'store/feedback/thunks';
import { handleListHowToVideos } from "store/howToVideos/thunks";
import { useHyperlinkModal } from 'store/hyperlinkModal/selectors';
import { handleListInteractions } from 'store/interactions/thunks';
import { handleChangeUserClassStatus, handleRevokeMulticlassForm } from 'store/multiClassStudent/thunks';
import { useNotifications } from 'store/notification/selectors';
import { handleCreateNotification, handleListNotification, handleMarkAsDoneNotify } from 'store/notification/thunks';
import { useCurrentOrgPermission } from "store/organizations/selectors";
import { handleListGetSchool } from 'store/organizations/thunks';
import { useResources } from 'store/resources/selectors';
import { handleListResourcesAndVideo } from "store/resources/thunks";
import { handleListStory, handleListStoryStandard } from 'store/story/thunks';
import { handleListClassSubmissions } from 'store/submissions/thunks';
import { useTimmerRunning } from 'store/timmer/selectors';
import { handleListTipsBook } from 'store/tips/thunks';
import { handleGetDistrictTeacher, handleListDistUsers } from 'store/users/thunks';
import { handleGetWelcomeVideos } from 'store/welcomeVideo/thunks';
import { BirdLogo, profileArrow, userImg } from "utils/assets/images";
import { activity_icon, chat_menu_icon, notes_icon, notification_icon } from 'utils/assets/images/icons';
import { getAvatar } from 'utils/assets/images/lootie';
import Lottie from "lottie-react";
import { NOTIFICATIONICONS } from "utils/assets/images/lootie";
import { KEY_FOR_REMOVE } from "utils/config/reference";
import COLOR from 'utils/constants/color';
import { imageFetch, imageFetchFromPodcast, isVideo } from 'utils/lib/ebookHelper';
import IdeaModal from './ideaModal';
import DebateModal from './OneWayDebateModal';
import WheelCheckList from 'components/WheelCheckList';
import NoiseMeter from 'components/Widget/NoiseMeter';
import { handleListWidgets } from 'store/widgets/thunks';
import TrafficModal from 'components/TrafficModal';
import WidgetsClock from 'components/widgetsClock';
import WidgetsImages from 'components/WidgetsImages';
import WidgetsTimer from 'components/Widget/WidgetsTimer';
import WidgetsText from 'components/Widget/WidgetsText';
import WidgetWheel from 'components/Widget/WidgetWheel';
import WidgetsExpression from 'components/Widget/WidgetsExpression';
import CheckListModal from 'components/CheckListModal';
import WidgetsStation from 'components/Widget/WidgetsStation';
import WidgetsGroup from 'components/Widget/WidgetsGroup';
import { useFirebase } from 'containers/FirebaseContext';
import {
    collection,
    onSnapshot,
    query,
    where,
} from 'firebase/firestore';
import { useMobileDetect } from 'containers/chat/hooks';
import useMobileDeviceDetection from 'containers/chat/hooks';
import AssignableSharePopup from 'components/AssignableSharePopup';
import SearchComponent from 'components/Search';
import FeedbackForm from './FeedbackForm';
import WidgetStationModal from 'components/Modals/widgetStationModal';
import ModalMenu from 'components/Modals';
import { useCollectionWidgets } from 'utils/methods/firebaseWidgets';

const IconWrapper = styled.div`
  max-height: 58px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: transparent;
  z-index: 11;
  -webkit-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  cursor: pointer;
  margin-bottom: 5px;
}
`;

const MobileMenu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
    line-height: initial;
    @media (min-width: 1100px) {
        display: none;
    }
    // @media (min-width: 992px) {
    //     display: none;
    // }
`;
const IdeaModalWrapper = styled.div`
    width: 90px;
    max-height: 90px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: fixed;
    bottom: 10px;
    right: 410px;
    background: transparent;
    z-index: 11;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    cursor: pointer;
`;



const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    /* color: ${COLOR.black200}; */
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    outline: none;
    padding: 4px 7px;
    color: white;
    text-transform: uppercase;

    &:hover {
        background-color: ${COLOR.green300};
        color: ${COLOR.white}
    }
    @media (min-width:992px) and (max-width:1168px) {
        font-size: 9px;
        padding: 4px 6px;
    }
       @media (max-width: 991px) {
            font-size:20px;
       }
    @media (min-width:993px) and (max-width: 1366px) {
        font-size: ${p => p.to !== "desktop" && "25px"  };
    }
    @media  (max-width: 1200px) {
        font-size: ${p => p.to === "desktop" && "9px"  };
    }
    @media (max-width: 568px) {
        padding: 4px 2px;
        // font-size: 20px;
        &:hover {
            font-size: 14px;
            background-color: ${COLOR.green300};
            color: ${COLOR.white}
        }
    }
    @media (max-width: 320px) {
        padding: 4px 1px;
        font-size: 10px;
    }
`;
const ButtonLinkLogo = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    /* color: ${COLOR.black200}; */
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 14px;
    outline: none;
    padding: 2px 8px;
    color: white;
    margin: 0px 0px 2px;
    @media (max-width: 568px) {
        padding: 0px 2px;
        font-size: 12px;
        &:hover {
 
        }
    }
    @media (max-width: 320px) {
        padding: 4px 1px;
        font-size: 10px;
    }
`;

const CenterContainer = styled.div`
    height: 100%;
    min-height: 100vh;
    padding: 64px 0px;
    width: 100%;

    @media (min-width: 800px) {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0px;
    }
`;

const ChildContainer = styled.div`
    height: 110%;
    width: 100%;
`;


const Container = styled.div`
    height: 100%;
    width: 100%;
`;

const CustomBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    text-align: left;
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;

const InnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    width: 100%;
`;


const MaxWidthContainer = styled.div`
    height: ${p => p.height || 'calc(100% - 55px)'};
    margin: 0px auto;
    // max-width: 1360px; 
    padding: ${props => (props.val ? '0px 0px' : '0px 24px')};
    width: 100%;
    @media (min-width:992px) and (max-width:1168px) {
         padding: ${props => (props.val && '0px 0px')};
    }


     @media (max-width: 1300px) {
    padding: ${props => {
        if (props.informateBook) return '0px 30px !important';
        return '';
    }};
`;


const MobileBar = styled.div`
    /* border-bottom: 1px solid ${COLOR.black800}; */
    display: block;
    height: 60px;
    padding: 8px 16px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    @media (min-width: 992px) {
        display: none;
    }
    @media (min-width: 772px) {
       background-color:black;
    }
    @media (max-width: 568px) {
        padding: 8px 10px;
    }
`;

const Navigation = styled.ul`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
`;

const NavigationItem = styled.li`
    align-items: center;
    display: ${p => p.desktop ? 'none' : 'flex'};
    flex-direction: row;
    height: 100%;
    margin-right: 8px;

    &:first-child {
        margin-left: 0px;
        margin-right: 10px;
    }
    @media (min-width:992px) and (max-width:1168px) {
        margin-right: 0px !important;
    }
    @media (min-width: 1100px) {
        display: flex;
        margin-right: 4px;
    }
    @media (max-width: 568px){
        margin-right: 6px;
        margin-left: 6px;
    }
    @media (max-width: 320px){
        margin-right: 1px;
    }
`;

const NavigationItemLogo = styled.li`
    align-items: center;
    flex-direction: row;
    height: 100%;
    margin-top: 0px;
    @media (max-width: 568px){
        margin-right: 6px;
        margin-top: 0px;
    }
`;



const RowContainer = styled.div`
    border-left: ${p => p.border && `1px solid ${COLOR.black700}`};
    margin-left: 10px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const RowItem = styled.div`
    margin-right: 8px;
    margin-top: 2px;
`;

const NavWrap = styled.div`
    background-color: ${COLOR.white};
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.17);
    overflow-x: hidden;
    overflow-y: hidden;
`;

const TopBar = styled.div`
    /* border-bottom: 1px solid ${COLOR.black800}; */
    height: 40px;
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
        height: 105px;

    }
    @media (max-width: 992px) {
        height: 90px;
        border-bottom:0px
    }
`;

const UserContainer = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;

const UserDetails = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (min-width: 768px) {
        display: flex;
    }
`;
const UserDetailsMob = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (max-width: 768px) {
        display: flex;
    }
`;
const UserDetailsMobs = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (max-width: 1099px) {
        display: flex;
    }
`;
const UserName = styled.span`
color: white;
    display: block;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 0px;
`;
const UserDistrict = styled.span`
    color: white;
    font-size: 10px;
    font-weight: 500;
    line-height: 11px;
    text-align: center;
    margin-top: 5px;
`;


const FeedBackWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;
const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50%;
    min-height: 50%;
    background-color: rgb(58,58,58);
    position: relative;
    justify-content: center;
    padding: 20px;
    padding-top: 0px;

`;
const BirdLogoImg = styled.div`
    width: 270px;
    height: 180px;
    margin-top: 20px;
    background-image: url(${BirdLogo});
    background-position: top;
    background-size: 150% 130%;
    background-repeat: no-repeat;
`;

const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;
const FeedbackTitle = styled.div`
    color: white;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-line;
`;
const AlertTitle = styled.div`   
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-line;
    text-align: center;
    width: 100%;
    color: red;
    `;
const AlertMessage = styled.div`   
    color: white;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-line;
    width: 100%;
    color: #fff;
    `;
const Input = styled.textarea`
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    box-shadow: none;
    font-size: inherit;
    font-weight: 600;
    outline: none;
    padding: 0px 2px;
    transition: all 300ms ease;
    width:100%;
    height:100px;
    text-align:left;
`;
const FeedbackThnks = styled.div`
    width:100%;
    height:100px;
    background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    color: white;
    z-index: 1;
    white-space: pre-line;
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;

`;
const FeedbackSendBtn = styled.button`
    background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    color: white;
    z-index: 1;
    padding: 5px;
}
`;
const FeedbackInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border:1px solid #fff;
`;
const HyperLinkContainer = styled.div`
    width:100%;
    background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    color: white;
    z-index: 1;
    white-space: pre-line;
    padding: 10px;
    min-height: 50px;
`;
const HyperLinksInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border:1px solid #fff;
    min-height: 50px;
    color: #fff;
    padding: 15px;
`;
const LinksItem = styled.a`
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
        color: ${COLOR.white};
    }
    &:before {
        display: inline-block;
        content: '';
        -webkit-border-radius: 0.375rem;
        border-radius: 0.375rem;
        height: 0.75rem;
        width: 0.75rem;
        margin-right: 0.5rem;
        background-color: #fff;
    }
`;
const NotificationContainer = styled.div`
   min-width:380px;
   min-height:50px;
   padding:10px 15px 20px 15px;
   overflow-y:scroll;
   max-width:391px;
    @media (max-width: 600px) {
     min-width:100%;
   }
`;
const NotificationHeader = styled.div`
    display:flex;
    align-item:center;
    justify-content:space-between;
    border-bottom:1px dashed #e7eaef;
`;
const TextHeader = styled.p`
    font-size:17px;
    font-weight:600;
    color:rgb(28, 49, 115);
    margin-bottom:6px;
`;
const TextReadAll = styled.p`
    font-size:15px;
    font-weight:600;
    color:rgb(28, 49, 115);
    margin-bottom:6px;
`;
const NotificationBody = styled.div`
   padding:16px 0px;
`;
const NoNotificationText = styled.p`
   font-size:14px;
   color:gray;
   margin:30px 0px;
   text-align:center;
`;
const ViewAllBtn = styled.button`
    background-color: rgb(28, 49, 115);
    color:white;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: 40px;
    outline: none;
    transition: all 300ms ease;
    width: 100%;
    &:hover {
        box-shadow: 0 10px 15px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;
const ReadAllBtn = styled.button`
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
`;
const NotificationSection = styled.div`
  padding-left: 10px;
`;
const TextTitle = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  text-align: left;
`;
const TextDate = styled.div`
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  text-align: left;
`;
const TextDateTitle = styled.div`
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  text-align: left;
`;

const Image = styled.img`
  height: 50px;
`;

const NotificationsMainSection = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
`;
const SeenSection = styled.div`
  width: 8px;
  height: 8px;
//   background-color: rgb(28, 49, 115);
  border-radius: 50%;
  margin-right: 8px;
`;
const MenuIconsWrapper = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
         @media (max-width: 1099px) {
            display:none;
         }
`;
const StyledDrawer = styled(Drawer)`
    @media (min-width: 1367px) {
        display: none;
    }
`;

const Wrapper = ({
    activities,
    classQuestions,
    currentOrg,
    currentUser,
    children,
    userClasses,
    questions,
    quizzes,
    userBooksList,
    booksAllList,
    currentClass,
    feedbackForm,
    feedback,
    multiclassPopupForm,
    districts,
}) => {
    const bookUnits = useBookUnits();
    const resourcess = useResources();
    const classActivities = useClassActivities()
    const classQuizzes = useClassQuizzes()
    const allNotifications = useNotifications();
    const filterByClass = get(currentUser, 'role', '') == 'teacher' ? filter(allNotifications, obj => obj.classId == get(currentClass, 'id', '') && obj.userId !== get(currentUser, "id")) : filter(allNotifications, obj => (obj.createdBy == get(currentClass, 'id', '') || obj.createdBy == get(currentUser, 'id', '')) && obj.userId !== get(currentUser, "id"));
    const sortedData = filterByClass.slice().sort((a, b) => {
        const dateA = new Date(a.modified);
        const dateB = new Date(b.modified);
        return dateB - dateA; // Compare in reverse order for new to past
    });
    const deviceType = useMobileDeviceDetection();
    const { createCollectionWidgets } = useCollectionWidgets();
    const allAssignment = [...classActivities, ...classQuizzes];
    const orgPermissions = useCurrentOrgPermission()
    const demoStatus = useDemoStatus()
    const bookPermissions = get(orgPermissions, "books", [])
    const [alertView, , revokeAlertView] = useAlertView()
    const [hyperlinkInfo, , revokeHyperlink] = useHyperlinkModal()
    const chatMessages = useChatMessage()
    const dispatch = useDispatch();
    const history = useHistory();
    const isTeacherHome = history.location.pathname == "/teacher/home" || history.location.pathname == "/student/home" || currentUser?.role == "parent"
    const [modalOpen, toggleModal] = useState(false);
    const [modalOpenVideosDemo, toggleModalVideosDemo] = useState(false);
    const [answerData, setAnswerData] = useState({});
    const [answerDrawer, toggleAnswerDrawer] = useState(false);
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(false);
    const [activeClassForStudent, setActiveClassForStudent] = useState(null)
    const [open, setOpen] = React.useState(false);
    const socketRef = useRef()
    const { firestore } = useFirebase();
    const { socketConnection, reCreateSocket } = useSocket()
    const [messageCounter, setMessageCounter] = useState(0)
    const { fetchUserProfileConfig, profile_config } = useUserProfileConfig()
    const { showProfilePhotoViewer } = useProfilePicturePicker()
    const [timmerRunning, ,] = useTimmerRunning()
    const [popupMessage, setPopupMessage] = React.useState('');
    const [notificationCount, setNotificationCount] = useState(0);
    const [reviewData, setReviewData] = useState();
    const [assignmentDrawer, toggleAssignmentDrawer] = useState(false);
    const [isnotification, setIsnotification] = useState(false);
    const [activeStatus, setActiveStatus] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState()
    const [sharableWidget, setSharableWidget] = useState([])
    const [sharableUserWidget, setSharableUserWidget] = useState([])
    const modalSrc = (activeAssignment) => {
        return isVideo(get(activeAssignment, "data.chapter"), resourcess) ? imageFetchFromPodcast(get(activeAssignment, "data.chapter"), resources) : imageFetch(booksAllList, get(activeAssignment, "data.chapter"), get(activeAssignment, "data.page"))
    }
    const isMobile = useMobileDetect();
    const [openBottom, setOpenBottom] = useState(false);
    const [openMenuBook, setOpenMenuBook] = useState(false);
    // const [ideasModal, setIdeasModal] = useState(false);
    const [wheelsModal, setWheelsModal] = useState(false);
    const [debatesModal, setDebatesModal] = useState(false);

    const showDrawerBottom = () => {
        setOpenBottom(true);
    };
    const showDrawerMenuBook = () => {
        setOpenMenuBook(true);
    };
    const onCloseBottom = () => {
        setOpenBottom(false);
    };
    const onCloseMenuBook = () => {
        setOpenMenuBook(false);
    };
    const onGetAssignment = () => {
        listAssignmentsAll()
    }

    const currentUrl = history.location.pathname;
    let isDirector = false;
    if (currentUser?.role === 'admin') {
        isDirector = true;
    }
    const socketListner = () => {
        console.log("socket listner called")
        removeSocketListner()
        socketRef.current.onmessage = onMessage
        socketRef.current.onclose = onclose;
        socketRef.current.onopen = onOpen;
        socketRef.current.onerror = onError;
    }
    const onMessage = (event) => {
        dispatch(handleMessageChat(event.data, get(currentUser, "id")))
    }


    const onclose = (event) => {
        if (currentUser && currentUser.id && event.reason != "logout_close") {
            reCreateSocket()
        }
    }


    const onOpen = (event) => {
        console.log("connection open")
    }
    const onError = (event) => {
        console.log(event, "connection error")
    }
    const removeSocketListner = () => {
        if (socketRef.current) {
            socketRef.current.removeEventListener("open", onOpen)
            socketRef.current.removeEventListener("close", onclose)
            socketRef.current.removeEventListener("message", onMessage)
            socketRef.current.removeEventListener("error", onError)
        }
    }
    useEffect(() => {
        if (socketConnection) {
            socketRef.current = socketConnection;
            socketListner()
        } else {
            removeSocketListner()
        }
    }, [socketConnection])

    useEffect(() => {
        const unsubscribe = listenToShareWidgetData(get(currentClass, 'id', ''));
        return () => {
            unsubscribe();
        };
    }, [firestore, currentClass, currentUser])

    const listenToShareWidgetData = (classID) => {
        if (get(currentUser, "role") === "teacher" && isMobile) {
            return () => { };
        }

        if (!classID || !currentUser) {
            return () => { };
        }
        const collectionRef = collection(firestore, "share_widgets");
        const q = query(collectionRef,
            where("class_id", "==", classID),
            where("status", "==", true)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const sharableData = [];
            querySnapshot.forEach((doc) => {
                sharableData.push({ id: doc.id, ...doc.data() });
            });
            setSharableWidget(sharableData)
        }, (error) => {
            console.error("Error listening to session changes:", error);
        });

        return unsubscribe;
    }

    useEffect(() => {
        const unsubscribe = listenToUserShareWidgetData(get(currentUser, 'id', ''));
        return () => {
            unsubscribe();
        };
    }, [firestore, currentClass, currentUser])

    const listenToUserShareWidgetData = (userId) => {
        if (get(currentUser, "role") === "teacher" && isMobile) {
            return () => { };
        }

        if (!userId || !currentUser) {
            return () => { };
        }
        const collectionRef = collection(firestore, "share_widgets");
        const userQuery = query(collectionRef,
            where("created_by", "==", userId),
            where("status", "==", true)
        );
        const unsubscribe = onSnapshot(userQuery, (querySnapshot) => {
            const sharableUserData = [];
            querySnapshot.forEach((doc) => {
                sharableUserData.push({ id: doc.id, ...doc.data() });
            });
            setSharableUserWidget(sharableUserData);
        }, (error) => {
            console.error("Error listening to session changes:", error);
        });

        return unsubscribe;
    }

    useEffect(() => {
        if (chatMessages && chatMessages.length > 0) {
            let _count = (chatMessages || []).reduce((accumulator, c) => {
                if (
                    c.receiverId === get(currentUser, 'id') &&
                    !c.seen &&
                    !c.brodcastId &&
                    c.classId === currentClass?.id
                ) {
                    return accumulator + 1;
                }
                return accumulator;
            }, 0);
            const brodcastMessage = groupBy(chatMessages, "brodcastId")
            if (get(currentUser, "role") === "student") {
                let v = {};
                Object.keys(brodcastMessage).map(key => {
                    if (key == null || !key || key == "null") {
                        return;
                    }
                    else {
                        v[key] = filter(brodcastMessage[key], msg => !find(msg.brodcastSeen, ["id", currentUser.id])).length;
                    }
                })
                const activrClassVal = v[get(currentClass, "id")] > 0 ? v[get(currentClass, "id")] : 0
                _count += activrClassVal
            }
            setMessageCounter(_count)
        }
    }, [chatMessages, currentClass])
    useEffect(() => {
        if (currentClass) {
            dispatch(handleListClassUsers(currentClass.id))
            sharableUserWidget.forEach((widget) => {
                if (widget?.class_id !== currentClass.id) {
                    const updatedWidget = {
                        ...widget,
                        status: false,
                    };
                    createCollectionWidgets(updatedWidget);
                }
            });
        }
    }, [currentClass])
    useEffect(() => {
        const count = filter(sortedData, obj => obj.seen).length
        setNotificationCount(count)
    }, [notificationCount, sortedData, allNotifications, socketConnection])

    const closeSocket = () => {
        removeSocketListner()
        socketRef.current = null;
    }
    useEffect(() => {
        setTimeout(() => setMounted(true), 250);
        dispatch(handleGetServertime())
        return closeSocket;
    }, []);
    useEffect(() => {
        if (multiclassPopupForm.view) {
            const activeClass = filter(userClasses, ['userclass.active', true])
            const userclass = get(activeClass[0], "userclass", {})
            if (!activeClassForStudent) {
                setActiveClassForStudent(userclass.id)
            }
        } else {
            setActiveClassForStudent(null)
        }
    }, [multiclassPopupForm, userClasses])
    useEffect(() => {
        getAssignmentNotification()
    }, [])
    const getListOfBook = async () => {
        if (!booksAllList || booksAllList.length == 0) {
            dispatch(handleListAllBooks())
        }
        dispatch(handleListBookOrder())
        dispatch(handleListBookUnits())
        dispatch(handleListUserBooks(get(currentUser, "id")))
    }
    const getUserData = async () => {
        await dispatch(handleFetchUserData(currentUser));
    }
    const getListOfClasses = async () => {
        if (get(currentUser, "role") != "teacher" && get(currentUser, "role") != "student") {
            await dispatch(handleListUserClasses(get(currentUser, "id")))
        }
    }
    const getListUserQuestion = async () => {
        if (get(currentUser, "role") === 'student' && get(currentUser, "id")) {
            dispatch(handleListUserQuestions(get(currentUser, "id")))
        }
    }

    const listAssignmentsAll = async () => {
        if (!Array.isArray(activities) || activities.length == 0) {
            await dispatch(handleListActivities());
        } else {
            dispatch(handleListActivities());
        }

        if (!Array.isArray(quizzes) || quizzes.length == 0) {
            await dispatch(handleListQuizzes());
        } else {
            dispatch(handleListQuizzes());
        }
        await dispatch(handleListCustomAssignment(get(currentUser, "role"), get(currentUser, "id")))
    }
    const listInteractions = async () => {
        if (get(currentUser, "role") !== 'owe' || get(currentUser, "role") === 'admin') {
            dispatch(handleListInteractions());
            dispatch(handleListTipsBook());
        }
    }
    const getWelcomeVideoUrl = async () => {
        await dispatch(handleGetWelcomeVideos());
    }

    const getWidgets = async () => {
        await dispatch(handleListWidgets(currentUser?.id))
    }
    useEffect(() => {
        async function fetchData() {
            //disable if not want to loader
            KEY_FOR_REMOVE.map(key => localStorage.removeItem(key))
            setLoading(true);
            // return;
            await Promise.allSettled([
                getWidgets(),
                getWelcomeVideoUrl(),
                getUserData(),
                getListOfBook(),
                getListUserQuestion(),
                getListOfClasses(),
                dispatch(handleListResourcesAndVideo()),
                listAssignmentsAll(),
                listInteractions(),
                dispatch(handleListChatInfo(currentUser)),
                dispatch(handleListExams()),
                dispatch(handleListApExams()),
                dispatch(handleListStory()),
                dispatch(handleListStoryStandard()),
                dispatch(handleListHowToVideos()),
                fetchUserProfileConfig(),
                dispatch(handleGetDistrictTeacher(get(currentUser, 'orgId', ''), get(currentUser, 'id', '')))
            ])
            setLoading(false);
        }
        fetchData();
    }, [currentUser, dispatch])

    useEffect(() => {
        fetchDistrictData();
    }, [currentOrg]);

    const fetchDistrictData = async () => {
        try {
            if (!isEmpty(currentOrg)) {
                await dispatch(handleListGetSchool(currentOrg.id))
                await dispatch(handleListDistUsers(currentOrg.id));
            }
        } catch (error) {
        }
    };
    const onLogOutPress = () => {
        if (!timmerRunning) {
            dispatch(handleLogout());
            history.push('/');
        }
        if (currentUser?.role === "teacher" && sharableWidget?.length > 0) {
            sharableWidget.forEach((widget) => {
                const updatedWidget = {
                    ...widget,
                    status: false,
                };
                createCollectionWidgets(updatedWidget);
            });
        }
    }
    const handleClose = () => {
        setOpen(false);
    };
    const invokeFeedback = () => {
        dispatch(handleInvokeFeedbackForm())
    }
    const openAccessDenineMessage = () => {
        setOpen(true)
        setPopupMessage('Restricted access (legend)')
    }

    const getAssignmentNotification = async () => {
        await dispatch(handleListNotification(get(currentUser, "id")));
    }


    const getUserBooks = useCallback(() => {
        const ubook = currentClass && currentClass.books ? currentClass : userBooksList
        let booksData = [];
        if (_.has(ubook, "books")) {
            map(ubook.books, ub => {
                const bk = find(booksAllList, ['id', ub.bookId])
                if (bk) {
                    booksData.push(bk)
                }
                if (bk && bk.teacherEditionBook) {
                    const tbk = find(booksAllList, ['id', bk.teacherEditionBook])
                    if (tbk) {
                        const hasAlready = find(booksData, ["id", tbk.id])
                        if (!hasAlready) {
                            booksData.push(tbk)
                        }
                    }
                }
            })
            booksData = _.filter(booksData, k => {
                if (bookPermissions.length == 0) {
                    return true;
                }
                const bookaccess = _.find(bookPermissions, b => b.bookId == k.id || b.bookId == k.bookId)
                if (bookaccess) {
                    return bookaccess.available
                } else {
                    return true;
                }
            })
            if (currentUser.role == 'student') {
                booksData = booksData.filter(k => !k.onlyTeacher && !k.isNotAssignable)
            }
            return uniqBy(booksData, "id");
        } else {
            return [];
        }
    }, [currentClass, userBooksList, booksAllList])

    const navigateToEbook = (chapter) => {
        const demoPages = get(find(booksAllList, ["id", chapter]), "demoPages")
        if (demoStatus && demoPages) {
            history.push(`/ebook?chapter=${chapter}&page=${demoPages.from}&activity=null&quiz=null`);
        } else {
            history.push(`/ebook?chapter=${chapter}&page=1&activity=null&quiz=null`);
        }
    }

    const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId) || [];

    const eBookNav = () => {
        const ebooklist = getUserBooks();
        const isStudent = currentUser?.role === "student";
        return (
            <Menu>
                {ebooklist?.length > 0 ? (
                    map(ebooklist, x => {
                        if(isStudent && getHiddenBooks?.includes(x?.id)){
                            return null;
                        }
                        return (
                            <Menu.Item key={x.id} style={{ padding: '0px' }} className="contextCenter">
                                <PaddedBtn onClick={() => {
                                    navigateToEbook(x.id)
                                    onCloseMenuBook()
                                }} >
                                    {x.description }
                                </PaddedBtn>
                            </Menu.Item>
                        )
                    })
                ) : (
                    <Menu.Item style={{ padding: '0px' }} className="contextCenter">
                        <PaddedBtn>
                            No books
                        </PaddedBtn>
                    </Menu.Item>
                )}
            </Menu>
        )
    };

    const toolbarNav = () => {
        return (<Navigation className="mobileMenu">
            {userClasses.length > 1 && !isDirector && !isTeacherHome ? (
                <NavigationItem>
                    {/* <Dropdown overlay={classNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            Classes
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown> */}
                    <ButtonLink onClick={e => history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")}>
                        Classes
                    </ButtonLink>
                </NavigationItem>
            ) : (
                <NavigationItem>
                    <ButtonLink onClick={getRoute}>
                        Dashboard
                    </ButtonLink>
                </NavigationItem>
            )}
            {!isTeacherHome && <NavigationItem>
                <Dropdown overlay={eBookNav()} placement="bottomLeft" trigger={['click']}>
                    <ButtonLink onClick={e => e.preventDefault()}>
                        EBook
                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                    </ButtonLink>
                </Dropdown>
            </NavigationItem>}
            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, '1nformate', true) && (
                <NavigationItem>
                    <Dropdown overlay={informateNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            1nformate
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </NavigationItem>
            )}
            {get(orgPermissions, '1WAYRESOURCES', true) && <NavigationItem>
                <ButtonLink onClick={resources}>
                    1WAYRESOURCES
                </ButtonLink>
            </NavigationItem>}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={logs}>
                            Logs
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={schoologyLink}>
                            Schoology
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={classlinkLink}>
                            Classlink
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={cleverLink}>
                            Clever
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}



            {(currentUser?.role === 'student' || currentUser?.role === 'teacher') && !isTeacherHome && (
                <NavigationItem desktop>
                    <ButtonLink onClick={onWayLessons}>
                        1waylessons
                    </ButtonLink>
                </NavigationItem>
            )}

            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, '1WAYSTORY', true) && !isTeacherHome && (
                <NavigationItem desktop>
                    <Dropdown overlay={storyNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}   >
                            1WayStory
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </NavigationItem>
            )}
            {(currentUser?.role === 'teacher') && get(orgPermissions, '1WAYTEACHER', true) && !isTeacherHome && (
                <NavigationItem desktop>
                    <Dropdown overlay={resourceNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}   >
                            1WAYTEACHER
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </NavigationItem>
            )}
            {(currentUser?.role === 'student') && get(orgPermissions, '1WAYTEACHER', true) && !isTeacherHome && (
                <NavigationItem>
                    <ButtonLink onClick={onWayTeacher}>
                        1WAYSTUDENT
                    </ButtonLink>
                </NavigationItem>
            )}
            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && !isTeacherHome && get(orgPermissions, 'ap', true) && (
                <NavigationItem desktop>
                    <Dropdown overlay={apNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            AP
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </NavigationItem>
            )}
            {currentUser?.role === 'teacher' && currentUser?.demo && (
                <NavigationItem >
                    <ButtonLink onClick={pd}>
                        PD
                    </ButtonLink>
                </NavigationItem>
            )}
            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, 'exams', true) && (
                <NavigationItem>
                    <ButtonLink onClick={onAssessments}>
                        EXAMS
                    </ButtonLink>
                </NavigationItem>
            )}
        </Navigation>)
    };

    const userNav = () => {
        return (
            <Menu>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={showProfilePhotoViewer}>
                        Change Avatar
                    </PaddedBtn>
                </Menu.Item>
                {currentUser?.role !== 'student' && currentUser?.role !== 'parent' && (
                    <Menu.Item style={{ padding: '0px' }}>
                        <PaddedBtn onClick={() => history.push('/account')}>
                            Reset Password
                        </PaddedBtn>
                    </Menu.Item>
                )}
                {currentUser?.role !== 'parent' && (
                    <Menu.Item style={{ padding: '0px' }}>
                        <PaddedBtn onClick={() => {
                            invokeFeedback();
                        }}>
                            Report an issue
                        </PaddedBtn>
                    </Menu.Item>
                )}
                {currentUser?.role === 'teacher' && (<Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")
                    }}>
                        Manage classes
                    </PaddedBtn>
                </Menu.Item>)}
                {currentUser?.role === 'student' && userClasses?.length > 1 && (<Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")
                    }}>
                        Manage classes
                    </PaddedBtn>
                </Menu.Item>)}
                <Menu.Item disabled={timmerRunning} style={{ padding: '0px' }}>
                    <PaddedBtn onClick={onLogOutPress}>
                        Logout
                    </PaddedBtn>
                </Menu.Item>
            </Menu>
        );
    };

    const informateNav = () => {
        return (
            <Menu>
                <Menu.Item style={{ padding: '0px' }} className="contextCenter">
                    <PaddedBtn onClick={() => {
                        history.push('/informate')
                        onCloseMenuBook()
                    }}>
                        1nformate lessons
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }} className="contextCenter">
                    <PaddedBtn onClick={() => {
                        history.push('/1nformate')
                        onCloseMenuBook()
                    }}>
                        1nformate blog
                    </PaddedBtn>
                </Menu.Item>
            </Menu>
        );
    };
    const onWayTeacher = () => {
        if (demoStatus) {
            openAccessDenineMessage()
        } else {
            history.push('/Strategies')
        }
    }
    const resourceNav = () => {
        return (
            <Menu className="resourceNavUpr">
                <Menu.Item style={{ padding: '0px' }} className="contextCenter">
                    <PaddedBtn onClick={() => {
                        if (demoStatus) {
                            openAccessDenineMessage()
                            onCloseMenuBook()
                        } else {
                            history.push('/graphic_organizers')
                            onCloseMenuBook()
                        }
                    }}>
                        Graphic Organizers
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }} className="contextCenter">
                    <PaddedBtn onClick={() => {
                        if (demoStatus) {
                            openAccessDenineMessage()
                            onCloseMenuBook()
                        } else {
                            history.push('/Strategies')
                            onCloseMenuBook()
                        }
                    }}>
                        1WayTeacher
                    </PaddedBtn>
                </Menu.Item>
            </Menu>
        );
    };
    const apNav = () => {
        return (
            <Menu className="resourceNavUpr APListMenu">
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap?id=69')
                        onCloseMenuBook()
                    }}>
                        Correo electrónico
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap?id=70')
                        onCloseMenuBook()
                    }}>
                        Comparación cultural
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap?id=72')
                        onCloseMenuBook()
                    }}>
                        Conversación simulada
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap?id=73')
                        onCloseMenuBook()
                    }}>
                        Artículos informativos
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap?id=75')
                        onCloseMenuBook()
                    }}>
                        Audios comprensivos
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap?id=78')
                        onCloseMenuBook()
                    }}>
                        Ensayo argumentativo
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap-exams')
                        onCloseMenuBook()
                    }}>
                        Exámenes de práctica
                    </PaddedBtn>
                </Menu.Item>
                {currentUser.role === 'teacher' && <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        history.push('/ap/gradbook')
                        onCloseMenuBook()
                    }}>
                        Gradebook
                    </PaddedBtn>
                </Menu.Item>}

            </Menu>
        );
    };
    const onReadAllNotification = async (data) => {
        const filterById = map(data, obj => obj.id)
        const dataa = {
            notificationId: filterById
        }
        await dispatch(handleMarkAsDoneNotify(dataa));
    }

    const notificationNav = () => {
        return (
            <Menu>
                <NotificationContainer>
                    <NotificationHeader>
                        <TextHeader>Notifications</TextHeader>
                        {sortedData?.length > 0 && (
                            <ReadAllBtn onClick={() => {
                                onReadAllNotification(sortedData)
                            }} >
                                <TextReadAll>Mark All As Done</TextReadAll>
                            </ReadAllBtn>
                        )}
                    </NotificationHeader>
                    <NotificationBody>
                        {sortedData?.length > 0 ? (
                            <>
                                {map(sortedData?.slice(0, 5), (item, index) => {
                                    return (
                                        <SingleNotificationSection item={item} index={index} key={index} />
                                    )
                                }
                                )}
                            </>
                        )
                            : (
                                <NoNotificationText>
                                    {/* You have not received any notifications. */}
                                    <div className="notifiPlaceholder">
                                        <Lottie animationData={NOTIFICATIONICONS.poster} />
                                    </div>
                                </NoNotificationText>
                            )}
                    </NotificationBody>
                    <Menu.Item style={{ backgroundColor: 'white', padding: '0px' }}>
                        <ViewAllBtn
                            style={{
                                opacity: sortedData?.length > 0 ? 1 : 0.7,
                                display: !sortedData?.length > 0 && 'none'
                            }}
                            disabled={sortedData?.length > 0 ? false : true}
                            onClick={() => {
                                history.push('/notification')
                                setIsnotification(false)
                            }}>View All</ViewAllBtn>
                    </Menu.Item>
                </NotificationContainer>
            </Menu>
        );
    };


    const storyNav = () => {
        return (
            <Menu className="resourceNavUpr">
                <Menu.Item style={{ padding: '0px' }} className="contextCenter">
                    <PaddedBtn onClick={() => {
                        onStorylevel()
                        onCloseMenuBook()
                    }}>
                        Story
                    </PaddedBtn>
                </Menu.Item>
                {get(currentUser, "role") !== "student" ?
                    <Menu.Item style={{ padding: '0px' }} className="contextCenter">
                        <PaddedBtn onClick={() => {
                            history.push('/story/gradebook')
                            onCloseMenuBook()
                        }}>
                            Gradebook
                        </PaddedBtn>
                    </Menu.Item> : null}
            </Menu>
        );
    };
    const getRoute = () => {
        if (isTeacherHome) {
            return;
        }
        if (currentUser?.role === 'admin') {
            history.push('/district');
        } else if (currentUser?.role === 'teacher') {
            history.push('/admin/class?view=home');
            // history.push('/teacher/home');
        } else if (currentUser?.role === 'owe_admin') {
            history.push('/dashboard');
        } else if (currentUser?.role === 'director') {
            history.push('/director');
        } else if (currentUser?.role === 'parent') {
            history.push('/parent/class?view=home');
        }
        else {
            history.push('/student/class?view=home');
        }
    };

    const logs = () => {
        history.push('/logs');
    };
    const schoologyLink = () => {
        history.push('/schoology');

    };

    const classlinkLink = () => {
        history.push('/classlink');
    };
    const cleverLink = () => {
        history.push('/clever');
    };
    const resources = () => {
        history.push('/resources');
    };

    const pd = () => {
        history.push('/pd');
    }

    const onAssessments = () => {
        history.push("/exams");
    }
    const onStorylevel = () => {
        history.push("/story-levels");
    }
    const getBadgeCountForQuestion = () => {
        let count = 0;
        if (currentUser?.role === "student") {
            count = filter(questions, x => x.answerCreated && !x.viewed).length
        }
        else if (currentUser?.role === "teacher") {
            count = filter(classQuestions, x => !x.answer).length
        }
        return count;
    }
    const revokeFeedback = () => {
        dispatch(handleRevokeFeedbackForm())
    }
    const revokeMulticlassPopup = () => {
        dispatch(handleRevokeMulticlassForm())
    }

    const setStudentClassActive = () => {
        const uclass = _.find(userClasses, ["userclass.id", activeClassForStudent])
        if (uclass) {
            const { classId, userId } = _.get(uclass, "userclass")
            dispatch(handleChangeUserClassStatus(userId, classId, () => {
                dispatch(handleGetClass(uclass.id))
                dispatch(handleListUserClasses(get(currentUser, "id")))
            }))
        }
    }
    const getLink = (link) => {
        if (typeof link == "string") {
            return link.match(/\bhttps?:\/\/\S+/gi)[0];
        } else if (typeof link == "object") {
            return get(link, "href", "").match(/\bhttps?:\/\/\S+/gi)[0];
        } else {
            return '';
        }
    }
    const getHrefTitle = (link) => {
        if (typeof link == "string") {
            return link;
        } else if (typeof link == "object") {
            return link.title && link.title.length > 0 ? link.title : link.href;
        } else {
            return ''
        }
    }

    const gridStyle = {
        width: "100%",
        textAlign: "center",
        padding: "7px",
        cursor: "pointer"
    };

    const gridStyleSeen = {
        width: "100%",
        textAlign: "center",
        padding: "7px",
        cursor: "pointer",
        backgroundColor: "#e8e4ee"
    };

    const onNotification = async () => {
        setIsnotification(!isnotification);
    }

    const getAssignmentWithSubmission = async () => {
        dispatch(handleListClassActivities(currentClass.id));
        dispatch(handleListClassQuizzes(currentClass.id));
    }

    const getClassAssignment = async () => {
        dispatch(handleListClassActivities(currentClass.id));
        dispatch(handleListClassQuizzes(currentClass.id));
    }

    const createNotification = async (data) => {
        if (data.seen) {
            const notificationData = {
                ...data,
                seen: false
            }
            dispatch(handleCreateNotification(notificationData));
        } else {
            return;
        }
    }

    const redirectToAssignmentVideo = (id, quizId) => {
        let url = `/video?id=${id}&quiz=${quizId}`
        history.push(url);
    }

    const onNotificationClick = (data) => {
        const chapter = _.get(data, "data.chapter", '')
        const assignmentId = _.get(data, "data.id", '')
        const assignmentPage = _.get(data, "data.page", 0)
        const type = _.get(data, 'type', '')
        if (isVideo(chapter, resourcess)) {
            redirectToAssignmentVideo(chapter, null);
        } else if (chapter == "33" || chapter == "41" || chapter == "43" || chapter == "49") {
            const BALCON_PAGES = _.get(_.find(bookUnits, ["bookId", chapter]), "pages", [])
            const typeId = "";
            const bookitem = _.find(BALCON_PAGES, k => parseInt(k.start) <= assignmentPage && parseInt(k.finish) >= assignmentPage)
            if (chapter == '33') {
                let url = `/balconCulturalBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
                history.push(url);
            } else if (chapter == '41') {
                let url = `/informateBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
                history.push(url);
            } else if (chapter == '43') {
                let url = `/ComunidadBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
                history.push(url);
            } else if (chapter == '49') {
                let url = `/ConectandomeBook?id=${bookitem.id}&page=${assignmentPage}&activity=${typeId.includes('activity') ? assignmentId : null}&quiz=${typeId.includes('quiz') ? assignmentId : null}`
                history.push(url);
            }
        }
        // else if (type == 'update_grade') {
        //     history.push('/student/class?view=studentData');
        // }
        else {
            history.push(`/ebook?chapter=${chapter}&page=${assignmentPage}&activity=${data?.data.typeId === 'activity' ? assignmentId : null}&quiz=${data?.data.typeId === 'quiz' ? assignmentId : null}`);
        }
    }



    const onStudentEndNotification = (data) => {
        if (data.type == 'delete_activity' || data?.type == 'delete_quiz') {
            return;
        } else {
            onNotificationClick(data);
            setIsnotification(false);
        }
    }

    const onRoleCheck = async (data, findData) => {
        if (get(currentUser, "role") === "teacher") {
            await Promise.allSettled([
                createNotification(data),
                getAssignmentWithSubmission()
            ]);
            if (findData) {
                await dispatch(handleListClassSubmissions(get(currentClass, "id")))
                setReviewData(findData);
                toggleAssignmentDrawer(true);
            } else {
                setOpen(true)
                setPopupMessage('The Assignment is already deleted')
            }
            setSelectedNotification(data);
            setIsnotification(false);
        } else {
            await Promise.allSettled([
                createNotification(data),
                getClassAssignment()
            ]);
            onStudentEndNotification(data);
        }
    }

    const SingleNotificationSection = ({ item }) => {
        const inputDate = get(item, "modified", "");
        const seenStatus = get(item, "seen", '');
        const date = new Date(inputDate);
        const assignmentId = _.get(item, "data.id", '')
        const findData = find(allAssignment, obj => get(obj, 'data.id', '') == assignmentId)


        const formattedDate = date.toLocaleDateString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        // Combine the formatted date and time
        const result = `${formattedDate} at ${formattedTime}`;
        return (
            <Badges.Ribbon text={seenStatus ? 'New' : 'Done'} color={seenStatus ? 'red' : '#03989e'}>
                <Card.Grid style={seenStatus ? gridStyleSeen : gridStyle} onClick={() => {
                    onRoleCheck(item, findData)
                }}>
                    <Row>
                        <Col xs={24} lg={24}>
                            <NotificationsMainSection>
                                {/* {seenStatus && (
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <SeenSection />
                                </div>
                            )} */}
                                <Image
                                    src={modalSrc(item)}
                                    alt={""}
                                />
                                <NotificationSection>
                                    <TextTitle>{item.displayName}</TextTitle>
                                    <TextDateTitle>{item.message}</TextDateTitle><span style={{ width: '6px' }}></span>
                                    <TextDate>{result}</TextDate>
                                </NotificationSection>
                            </NotificationsMainSection>
                        </Col>
                    </Row>
                </Card.Grid>
            </Badges.Ribbon>
        );
    };






    const onWayLessons = () => {
        history.push("/OneWayLessons");
    }
    const getUserProfileImg = useMemo(() => {
        if (profile_config && profile_config.config) {
            const avt = getAvatar(profile_config.config.profile_pic)
            if (avt) {
                return avt
            }
        }
        return userImg;
    }, [profile_config])
    return loading ? (
        <CenterContainer>
            <InitialLoading mounted={mounted} />
        </CenterContainer>
    ) : (
        <div style={{ position: "relative" }} onClick={() => {
            if (isnotification) {
                setIsnotification(false)
            }
        }}>
            <ProfileAvatarPicker />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {popupMessage}
                </Alert>
            </Snackbar>
            <AssignmentReviewByNotify assignment={reviewData} assignmentDrawer={assignmentDrawer} selectedNotification={selectedNotification} oncloseDrawer={() => {
                toggleAssignmentDrawer(false)
            }} />
            <FeedbackForm feedbackForm={feedbackForm} revokeFeedback={revokeFeedback} feedback={feedback} />
            {!isTeacherHome && get(currentUser, "role") === "teacher" && <AssignableSharePopup currentClass={currentClass} currentUser={currentUser} onGetAssignment={onGetAssignment} onNoData={(msg) => {
                setOpen(true)
                setPopupMessage(msg)
            }} />}
            <Modal
                open={multiclassPopupForm.view && currentUser && currentUser.role == "student"}
                onClose={revokeMulticlassPopup}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            >
                <FeedBackWrapper>
                    <FeedbackContainer>
                        <Row style={{ justifyContent: "center" }}>
                            <BirdLogoImg />
                            <CloseBtn onClick={revokeMulticlassPopup}>
                                <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                            </CloseBtn>
                        </Row>
                        {(<>
                            <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <FeedbackTitle>{"We found two classes for you, please consult with your teacher about which one is the correct one.\n Then, select the correct class as default."}</FeedbackTitle>
                            </Row>
                            <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <RadioGroup id="interaction" value={activeClassForStudent} onChange={(e) => { setActiveClassForStudent(e.target.value) }} style={{ justifyContent: 'space-around', color: COLOR.white }}>
                                    {map(userClasses, ucls => {
                                        const userclass = _.get(ucls, "userclass", {})
                                        return (<FormControlLabel key={userclass.id} className='selectStudentClass' value={userclass.id} control={<Radio />} label={ucls.displayName} />)
                                    })}
                                </RadioGroup>
                            </Row>
                            <Row style={{ justifyContent: "center", paddingLeft: "20px", paddingRight: "20px" }}>

                            </Row>
                            <Row style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "15px" }}>
                                <FeedbackSendBtn onClick={setStudentClassActive}>
                                    <FeedbackInner>
                                        {multiclassPopupForm.isLoading ? <Loading color={"#fff"} size={20} /> : "Save"}
                                    </FeedbackInner>
                                </FeedbackSendBtn>
                            </Row>
                        </>)}
                    </FeedbackContainer>
                </FeedBackWrapper>
            </Modal>
            <Modal
                open={alertView.view}
                onClose={revokeAlertView}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            >
                <FeedBackWrapper>
                    <FeedbackContainer>
                        <Row style={{ justifyContent: "center" }}>
                            <BirdLogoImg />
                            <CloseBtn onClick={revokeAlertView}>
                                <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                            </CloseBtn>
                        </Row>
                        <Row style={{ paddingLeft: "20px", paddingRight: "20px", flexDirection: "column" }}>
                            <AlertTitle>{alertView.title}</AlertTitle>

                        </Row>
                        <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                            {alertView.message ? <AlertMessage>{alertView.message}</AlertMessage> : null}
                        </Row>
                    </FeedbackContainer>
                </FeedBackWrapper>
            </Modal>
            <Modal
                open={hyperlinkInfo.view}
                onClose={revokeHyperlink}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            >
                <FeedBackWrapper>
                    <FeedbackContainer>
                        <Row style={{ justifyContent: "center" }}>
                            <BirdLogoImg />
                            <CloseBtn onClick={revokeHyperlink}>
                                <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                            </CloseBtn>
                        </Row>
                        <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                            <HyperLinkContainer>
                                <HyperLinksInner>
                                    {map(hyperlinkInfo.hyperlinks, liks => (<LinksItem href={getLink(liks)} target={"_blank"} >{getHrefTitle(liks)}</LinksItem>))}
                                </HyperLinksInner>
                            </HyperLinkContainer>
                        </Row>
                    </FeedbackContainer>
                </FeedBackWrapper>
            </Modal>
            {/* Wheel modal  */}
            <BottomIcons sharableWidget={sharableWidget} />
            <MessageAlertViewer />
            <Drawer
                height='100%'
                destroyOnClose
                onClose={() => {
                    toggleAnswerDrawer(false);
                }}
                placement='top'
                open={answerDrawer}
                key='answerNew'>
                <CenterContainer>
                    <AnswerForm data={answerData} handleClose={() => toggleAnswerDrawer(false)} booksAllList={booksAllList} />
                </CenterContainer>
            </Drawer>
            <DemoModal open={modalOpen} handleClose={() => toggleModal(false)} message={currentOrg?.demo ? 'Not available in demo' : 'Chapter is currently under maintenance'} />
            <DemoModal open={modalOpenVideosDemo} handleClose={() => toggleModalVideosDemo(false)} message={'Not available in demo'} />

            <Container>
                {get(currentUser, 'role', '') !== 'owe_admin' && get(currentUser, 'role', '') !== 'admin' && (
                    <NavWrap className="cstmHeader">
                        <TopBar>
                            <MaxWidthContainer height='100%' informateBook={history.location.pathname === "/informateBook"}>
                                <InnerContainer>
                                    <Navigation>
                                        <NavigationItemLogo desktop>
                                            <ButtonLinkLogo onClick={getRoute}>
                                                <LogoIcon />
                                            </ButtonLinkLogo>
                                        </NavigationItemLogo>
                                        {userClasses && userClasses.length > 0 && !isTeacherHome && currentUser?.role === 'teacher' && (
                                            <NavigationItem desktop>
                                                <ButtonLink  to={"desktop"}  onClick={e => history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")}>
                                                    CLASSES
                                                </ButtonLink>
                                            </NavigationItem>
                                        )}
                                        {userClasses && userClasses.length > 1 && !isTeacherHome && currentUser?.role === 'student' && (
                                            <NavigationItem desktop>
                                                <ButtonLink  to={"desktop"}  onClick={e => history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")}>
                                                    CLASSES
                                                </ButtonLink>
                                            </NavigationItem>
                                        )}
                                        {!isTeacherHome && <NavigationItem desktop>
                                            <Dropdown overlay={eBookNav()} placement="bottomLeft" trigger={['click']}>
                                                <ButtonLink to={"desktop"}   onClick={e => e.preventDefault()}>
                                                    EBook
                                                    <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                </ButtonLink>
                                            </Dropdown>
                                        </NavigationItem>}
                                        {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, '1nformate', true) && !isTeacherHome && (
                                            <NavigationItem desktop>
                                                <Dropdown overlay={informateNav()} placement="bottomLeft" trigger={['click']}>
                                                    <ButtonLink  to={"desktop"}  onClick={e => e.preventDefault()}>
                                                        1nformate
                                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                    </ButtonLink>
                                                </Dropdown>
                                            </NavigationItem>
                                        )}


                                        {get(orgPermissions, '1WAYRESOURCES', true) && !isTeacherHome && <NavigationItem desktop>
                                            <ButtonLink  to={"desktop"}  onClick={resources}>
                                                1WAYRESOURCES
                                            </ButtonLink>
                                        </NavigationItem>}
                                        {currentUser?.role === 'owe_admin' && (
                                            <>
                                                <NavigationItem desktop>
                                                    <ButtonLink  to={"desktop"}  onClick={logs}>
                                                        Logs
                                                    </ButtonLink>
                                                </NavigationItem>
                                            </>
                                        )}
                                        {currentUser?.role === 'owe_admin' && (
                                            <>
                                                <NavigationItem desktop>
                                                    <ButtonLink  to={"desktop"}  onClick={schoologyLink}>
                                                        Schoology
                                                    </ButtonLink>
                                                </NavigationItem>
                                            </>
                                        )}
                                        {currentUser?.role === 'owe_admin' && (
                                            <>
                                                <NavigationItem desktop>
                                                    <ButtonLink  to={"desktop"}  onClick={classlinkLink}>
                                                        Classlink
                                                    </ButtonLink>
                                                </NavigationItem>
                                            </>
                                        )}
                                        {currentUser?.role === 'owe_admin' && (
                                            <>
                                                <NavigationItem desktop>
                                                    <ButtonLink  to={"desktop"}  onClick={cleverLink}>
                                                        Clever
                                                    </ButtonLink>
                                                </NavigationItem>
                                            </>
                                        )}
                                        {(currentUser?.role === 'student' || currentUser?.role === 'teacher') && !isTeacherHome && (
                                            <NavigationItem desktop>
                                                <ButtonLink  to={"desktop"}  onClick={onWayLessons}>
                                                    1waylessons
                                                </ButtonLink>
                                            </NavigationItem>
                                        )}
                                        {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, '1WAYSTORY', true) && !isTeacherHome && (
                                            <NavigationItem desktop>
                                                <Dropdown overlay={storyNav()} placement="bottomLeft" trigger={['click']}>
                                                    <ButtonLink  to={"desktop"}  onClick={e => e.preventDefault()}>
                                                        1WayStory
                                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                    </ButtonLink>
                                                </Dropdown>
                                            </NavigationItem>
                                        )}
                                        {(currentUser?.role === 'teacher') && get(orgPermissions, '1WAYTEACHER', true) && !isTeacherHome && (
                                            <NavigationItem desktop>
                                                <Dropdown overlay={resourceNav()} placement="bottomLeft" trigger={['click']}>
                                                    <ButtonLink  to={"desktop"}  onClick={e => e.preventDefault()}>
                                                        1WAYTEACHER
                                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                    </ButtonLink>
                                                </Dropdown>
                                            </NavigationItem>
                                        )}
                                        {(currentUser?.role === 'student') && get(orgPermissions, '1WAYTEACHER', true) && !isTeacherHome && (
                                            <NavigationItem desktop>
                                                <ButtonLink  to={"desktop"}  onClick={onWayTeacher}>
                                                    1WAYSTUDENT
                                                </ButtonLink>
                                            </NavigationItem>
                                        )}

                                        {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, 'ap', true) && !isTeacherHome && (
                                            <NavigationItem desktop>
                                                <Dropdown overlay={apNav()} placement="bottomLeft" trigger={['click']}>
                                                    <ButtonLink  to={"desktop"}  onClick={e => e.preventDefault()}   >
                                                        AP
                                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                    </ButtonLink>
                                                </Dropdown>
                                            </NavigationItem>
                                        )}
                                        {currentUser?.role === 'teacher' && currentUser?.demo && (
                                            <NavigationItem desktop>
                                                <ButtonLink  to={"desktop"}  onClick={pd}>
                                                    PD
                                                </ButtonLink>
                                            </NavigationItem>
                                        )}
                                        {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, 'exams', true) && !isTeacherHome && (
                                            <NavigationItem desktop>
                                                <ButtonLink  to={"desktop"}  onClick={onAssessments}>
                                                    EXAMS
                                                </ButtonLink>
                                            </NavigationItem>
                                        )}
                                    </Navigation>
                                    <MenuIconsWrapper>
                                        <Row>
                                            {(currentUser?.role === 'teacher' || currentUser?.role === 'student') && !isTeacherHome && (
                                                <RowItem>
                                                    <SearchComponent />
                                                </RowItem>
                                            )}
                                            {(currentUser?.role === 'teacher' || currentUser?.role === "student") && !isTeacherHome && (
                                                <RowItem style={{ cursor: 'pointer' }}>
                                                    <Dropdown overlay={notificationNav()} placement="bottom" trigger={['click']} open={isnotification} >
                                                        <CustomBtn onClick={() => {
                                                            onNotification()
                                                        }}
                                                        >
                                                            <Badge overlap="rectangular" badgeContent={notificationCount} color="error" variant="standard" style={{ marginTop: '-6px' }}>
                                                                <img src={notification_icon} style={{ width: "25px" }} />
                                                            </Badge>
                                                        </CustomBtn>
                                                    </Dropdown>
                                                </RowItem>
                                            )}
                                            {(currentUser?.role === 'teacher' || currentUser?.role === "student") && !isTeacherHome && (
                                                <RowItem style={{ cursor: 'pointer' }}>
                                                    <Tooltip placement="bottom" title="Chat">
                                                        <CustomBtn onClick={() => history.push('/chat')}>
                                                            <Badge overlap="rectangular" badgeContent={messageCounter} color="error" variant="standard" style={{ marginTop: '-6px' }}>
                                                                <img src={chat_menu_icon} style={{ width: "25px" }} />
                                                            </Badge>
                                                        </CustomBtn>
                                                    </Tooltip>
                                                </RowItem>
                                            )}
                                            {/* {(currentUser?.role === 'teacher' || currentUser?.role === 'student') && !isTeacherHome && (
                                                <RowItem>
                                                    <Tooltip placement="bottom" title="Student Notes">
                                                        <CustomBtn onClick={() => history.push('/notes')}>
                                                            <img src={notes_icon} style={{ width: "27.5px", marginBottom: 5, marginTop: -3 }} />
                                                        </CustomBtn>
                                                    </Tooltip>
                                                </RowItem>
                                            )} */}
                                        </Row>
                                        <Row>
                                            {currentUser?.role === 'teacher' && !isTeacherHome && <ActiveStudentStatus activeStatus={activeStatus} setActiveStatus={setActiveStatus} />}
                                        </Row>
                                    </MenuIconsWrapper>
                                    <Row className='iconsMenuItem'>
                                        <RowContainer className="usrDetailUpr" border={currentUser?.role === 'teacher'}>

                                            <Dropdown className="usrDetailInr dontShowInMobile hideOnMobile" overlay={userNav()} placement="bottomRight" trigger={['click']}>
                                                <UserContainer>
                                                    <UserDetails className="usrDetail">
                                                        <div className="usrDetailInfo">
                                                            <div className="usrPic desk"><img src={getUserProfileImg} /></div>
                                                            <div><UserName>{currentUser?.firstName} {currentUser?.lastName}</UserName></div>
                                                            <div className="usrArrow"><img src={profileArrow} /></div>
                                                        </div>
                                                        <UserDistrict>{currentOrg?.displayName}</UserDistrict>
                                                    </UserDetails>
                                                    <UserDetailsMob className="usrDetail">
                                                        <div className="usrPic mob" style={{ marginLeft: 15 }}><img src={getUserProfileImg} /></div>
                                                    </UserDetailsMob>
                                                </UserContainer>
                                            </Dropdown>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {!isTeacherHome && (
                                                    <>
                                                        {(currentUser?.role === 'teacher' || currentUser?.role === 'student') && !isTeacherHome && (
                                                            <RowItem className='dontShowInDesktop hideOnDesktop'>
                                                                <SearchComponent />
                                                            </RowItem>
                                                        )}
                                                        <IconWrapper
                                                            onClick={() => setActiveStatus(true)}
                                                            className="debateLogoBtnPart dontShowInDesktop hideOnDesktop"
                                                        >
                                                            {currentUser?.role === "teacher" && (
                                                                <img style={{ height: 28 }} id='debade_icon' src={activity_icon} alt="Student activity" title='Student activity' />
                                                            )}
                                                        </IconWrapper>
                                                        <MobileBar className="mobileBar">
                                                            <MobileMenu className="mobileMenuDrop">
                                                                <div onClick={() => showDrawerMenuBook()}>
                                                                    {/* <Dropdown> */}
                                                                    <ButtonLink onClick={e => e.preventDefault()}>
                                                                        <MenuOutlined />
                                                                    </ButtonLink>
                                                                    {/* </Dropdown> */}
                                                                </div>
                                                            </MobileMenu>
                                                        </MobileBar>
                                                    </>
                                                )}
                                                <UserDetailsMobs className="usrDetail" onClick={() => showDrawerBottom()}>
                                                    <div className="usrPic" style={{ marginLeft: 15 }}><img src={getUserProfileImg} /></div>
                                                </UserDetailsMobs>
                                            </div>
                                        </RowContainer>
                                    </Row>
                                </InnerContainer>
                            </MaxWidthContainer>
                        </TopBar>
                    </NavWrap>
                )}
                {get(currentUser, 'role', '') === 'admin' ? <DistrictAdminMenu>{children}</DistrictAdminMenu> : null}
                {get(currentUser, 'role', '') === 'owe_admin' ? <AdminMenu districts={districts} >{children}</AdminMenu> : null}
                <ChildContainer>
                    {(get(currentUser, 'role', '') !== 'owe_admin' && get(currentUser, 'role', '') !== 'admin') &&
                        <MaxWidthContainer informateBook={history.location.pathname === "/informateBook"} val={currentUrl === '/Strategies' || currentUrl === "/graphic_organizers" || currentUrl === "/story-levels"}>
                            {!isTeacherHome && <WheelCheckList currentClass={currentClass} />}
                            {!isTeacherHome && <TrafficModal currentClass={currentClass} sharableWidget={sharableWidget} />}
                            {!isTeacherHome && <WidgetsClock currentClass={currentClass} sharableWidget={sharableWidget} />}
                            {!isTeacherHome && <WidgetsImages currentClass={currentClass} sharableWidget={sharableWidget} />}
                            {!isTeacherHome && <WidgetsTimer currentClass={currentClass} sharableWidget={sharableWidget} />}
                            {!isTeacherHome && <WidgetsText currentClass={currentClass} sharableWidget={sharableWidget} />}
                            {!isTeacherHome && <WidgetWheel currentClass={currentClass} sharableWidget={sharableWidget} />}
                            {!isTeacherHome && <WidgetsExpression currentClass={currentClass} sharableWidget={sharableWidget} />}
                            {!isTeacherHome && <CheckListModal currentClass={currentClass} />}
                            {!isTeacherHome &&
                            currentUser?.role === 'teacher' ?
                                <ModalMenu type='widgetStation' currentClass={currentClass} sharableWidget={sharableWidget} /> 
                                :
                                <WidgetsStation currentClass={currentClass} sharableWidget={sharableWidget}  />
                            }
                           {!isTeacherHome && (
                                <>
                                   {currentUser?.role === 'teacher' ?
                                      <ModalMenu type='widgetGroup' currentClass={currentClass} sharableWidget={sharableWidget} /> 
                                   : 
                                      <WidgetsGroup currentClass={currentClass} sharableWidget={sharableWidget} />
                                   }
                                </>
                            )}
                            {!isTeacherHome && <NoiseMeter currentClass={currentClass} />}
                            {children}
                        </MaxWidthContainer>}
                </ChildContainer>
            </Container>
            <StyledDrawer
                title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <img src={getUserProfileImg} className="bottomBtnIconss" style={{ height: 35, borderRadius: 20 }} onClick={() => {
                        showProfilePhotoViewer();
                        onCloseBottom();
                    }} />
                    <div>
                        <div style={{ fontSize: 20 }}>{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
                        <div style={{ fontSize: 12 }}>{`${currentClass?.displayName}`}</div>
                    </div>
                </div>}
                placement={'bottom'}
                width={500}
                onClose={onCloseBottom}
                open={openBottom}
                className={(currentUser?.role === 'student' || currentUser?.role === 'parent') ? 'BottomDrawerProfile BottomDrawerProfileStud' : 'BottomDrawerProfile'}
            >
                <Menu>
                    {/* <Menu.Item style={{ padding: '0px' }}>
                        <PaddedBtn className="bottomBtnMenu" onClick={() => {
                            showProfilePhotoViewer()
                            onCloseBottom();
                        }}> */}
                    {/* <VpnKeyIcon className="bottomBtnIconss" /> */}
                    {/* <img src={getUserProfileImg} className="bottomBtnIconss" style={{ height: 25, borderRadius: 20 }} />
                            Change Avatar
                        </PaddedBtn>
                    </Menu.Item> */}
                    {currentUser?.role !== 'student' && currentUser?.role !== 'parent' && (
                        <Menu.Item style={{ padding: '0px' }}>
                            <PaddedBtn className="bottomBtnMenu" onClick={() => {
                                history.push('/account')
                                onCloseBottom();
                            }}>
                                <VpnKeyIcon className="bottomBtnIconss" />
                                Reset Password
                            </PaddedBtn>
                        </Menu.Item>
                    )}
                    {currentUser?.role !== 'parent' && (
                        <Menu.Item style={{ padding: '0px' }}>
                            <PaddedBtn className="bottomBtnMenu" onClick={() => {
                                invokeFeedback();
                                onCloseBottom();
                            }}>
                                <ReportIcon className="bottomBtnIconss" />
                                Report an issue
                            </PaddedBtn>
                        </Menu.Item>
                    )}
                    {currentUser?.role === 'teacher' && (<Menu.Item style={{ padding: '0px' }}>
                        <PaddedBtn className="bottomBtnMenu" onClick={() => {
                            history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")
                            onCloseBottom();
                        }}>
                            <GroupIcon className="bottomBtnIconss" />
                            Manage classes
                        </PaddedBtn>
                    </Menu.Item>)}
                    {currentUser?.role === 'student' && userClasses?.length > 1 && (<Menu.Item style={{ padding: '0px' }}>
                        <PaddedBtn className="bottomBtnMenu" onClick={() => {
                            history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")
                            onCloseBottom();
                        }}>
                            <GroupIcon className="bottomBtnIconss" />
                            Manage classes
                        </PaddedBtn>
                    </Menu.Item>)}
                    <Menu.Item disabled={timmerRunning} style={{ padding: '0px' }}>
                        <PaddedBtn className="bottomBtnMenu" onClick={() => {
                            onLogOutPress();
                            onCloseBottom();
                        }}>
                            <ExitToAppIcon className="bottomBtnIconss" />
                            Logout
                        </PaddedBtn>
                    </Menu.Item>
                </Menu>
            </StyledDrawer>


            <StyledDrawer
                title={`Menu`}
                placement={'right'}
                onClose={onCloseMenuBook}
                open={openMenuBook}
                className="HeaderMenuMObile"
            >
                <Navigation className="mobileMenu">
                    {/* {userClasses.length > 1 && !isDirector && !isTeacherHome ? ( */}
                    {!isDirector && !isTeacherHome ? (

                        <NavigationItem>
                            {/* <Dropdown overlay={classNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            Classes
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown> */}
                            <ButtonLink onClick={e => {
                                history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")
                                onCloseMenuBook()
                            }}>
                                Classes
                            </ButtonLink>
                        </NavigationItem>
                    ) : (
                        <NavigationItem>
                            <ButtonLink onClick={() => {
                                getRoute()
                                onCloseMenuBook()
                            }}>
                                Dashboard
                            </ButtonLink>
                        </NavigationItem>
                    )}
                    {!isTeacherHome && <NavigationItem>
                        <Dropdown overlay={eBookNav()} placement="bottomLeft" trigger={['click']}>
                            <ButtonLink onClick={e => e.preventDefault()}>
                                EBook
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </ButtonLink>
                        </Dropdown>
                    </NavigationItem>}
                    {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, '1nformate', true) && (
                        <NavigationItem>
                            <Dropdown overlay={informateNav()} placement="bottomLeft" trigger={['click']}>
                                <ButtonLink onClick={e => e.preventDefault()}>
                                    1nformate
                                    <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                </ButtonLink>
                            </Dropdown>
                        </NavigationItem>
                    )}
                    {get(orgPermissions, '1WAYRESOURCES', true) && <NavigationItem>
                        <ButtonLink onClick={() => {
                            resources()
                            onCloseMenuBook()
                        }}>
                            1WAYRESOURCES
                        </ButtonLink>
                    </NavigationItem>}
                    {currentUser?.role === 'owe_admin' && (
                        <>
                            <NavigationItem>
                                <ButtonLink onClick={logs}>
                                    Logs
                                </ButtonLink>
                            </NavigationItem>
                        </>
                    )}
                    {currentUser?.role === 'owe_admin' && (
                        <>
                            <NavigationItem>
                                <ButtonLink onClick={() => {
                                    schoologyLink()
                                    onCloseMenuBook()
                                }}>
                                    Schoology
                                </ButtonLink>
                            </NavigationItem>
                        </>
                    )}
                    {currentUser?.role === 'owe_admin' && (
                        <>
                            <NavigationItem>
                                <ButtonLink onClick={() => {
                                    classlinkLink()
                                    onCloseMenuBook()
                                }}>
                                    Classlink
                                </ButtonLink>
                            </NavigationItem>
                        </>
                    )}
                    {currentUser?.role === 'owe_admin' && (
                        <>
                            <NavigationItem>
                                <ButtonLink onClick={() => {
                                    cleverLink()
                                    onCloseMenuBook()
                                }}>
                                    Clever
                                </ButtonLink>
                            </NavigationItem>
                        </>
                    )}



                    {(currentUser?.role === 'student' || currentUser?.role === 'teacher') && !isTeacherHome && (
                        <NavigationItem desktop>
                            <ButtonLink onClick={() => {
                                onWayLessons()
                                onCloseMenuBook()
                            }}>
                                1waylessons
                            </ButtonLink>
                        </NavigationItem>
                    )}
                    {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, '1WAYSTORY', true) && !isTeacherHome && (
                        <NavigationItem desktop>
                            <Dropdown overlay={storyNav()} placement="bottomLeft" trigger={['click']}>
                                <ButtonLink onClick={e => {
                                    e.preventDefault()
                                }}>
                                    1WayStory
                                    <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                </ButtonLink>
                            </Dropdown>
                        </NavigationItem>
                    )}
                    {(currentUser?.role === 'teacher') && get(orgPermissions, '1WAYTEACHER', true) && !isTeacherHome && (
                        <NavigationItem desktop>
                            <Dropdown overlay={resourceNav()} placement="bottomLeft" trigger={['click']}>
                                <ButtonLink onClick={e => {
                                    e.preventDefault()
                                }}   >
                                    1WAYTEACHER
                                    <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                </ButtonLink>
                            </Dropdown>
                        </NavigationItem>
                    )}
                    {(currentUser?.role === 'student') && get(orgPermissions, '1WAYTEACHER', true) && !isTeacherHome && (
                        <NavigationItem>
                            <ButtonLink onClick={() => {
                                onWayTeacher()
                                onCloseMenuBook()
                            }}>
                                1WAYSTUDENT
                            </ButtonLink>
                        </NavigationItem>
                    )}
                    {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && !isTeacherHome && get(orgPermissions, 'ap', true) && (
                        <NavigationItem desktop>
                            {deviceType ? <ButtonLink onClick={e => {
                                history.push('/ap?id=69')
                                onCloseMenuBook()
                            }
                            }>
                                AP
                            </ButtonLink> : <Dropdown overlay={apNav()} placement="bottomLeft" trigger={['click']}>
                                <ButtonLink onClick={e => { e.preventDefault() }
                                }>
                                    AP
                                    <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                </ButtonLink>
                            </Dropdown>}
                            {/* <Dropdown overlay={apNav()} placement="bottomLeft" trigger={['click']}>
                                    <ButtonLink onClick={e => { e.preventDefault() }
                                    }>
                                        AP
                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                    </ButtonLink>
                                </Dropdown> */}
                        </NavigationItem>
                    )}
                    {currentUser?.role === 'teacher' && currentUser?.demo && (
                        <NavigationItem >
                            <ButtonLink onClick={() => {
                                pd()
                                onCloseMenuBook()
                            }}>
                                PD
                            </ButtonLink>
                        </NavigationItem>
                    )}

                    {currentUser?.role === "teacher" && (
                        <>
                            <NavigationItem>
                                <ButtonLink onClick={() => {
                                    history.push("/admin/class?view=manage")
                                    onCloseMenuBook()
                                }}>
                                    Assignments
                                </ButtonLink>
                            </NavigationItem>
                            <NavigationItem >
                                <ButtonLink onClick={() => {
                                    history.push("/admin/class?view=gradebook")
                                    onCloseMenuBook()
                                }}>
                                    Gradebook
                                </ButtonLink>
                            </NavigationItem>
                            <NavigationItem >
                                <ButtonLink onClick={() => {
                                    history.push("/admin/class?view=classdata")
                                    onCloseMenuBook()
                                }}>
                                    Class Data
                                </ButtonLink>
                            </NavigationItem>
                            {/* <NavigationItem>
                                    <ButtonLink onClick={() => {
                                        setIdeasModal(true);
                                        // history.push("/admin/class?view=manage")
                                        onCloseMenuBook()
                                    }}>
                                        idea with1WAY
                                    </ButtonLink>
                                </NavigationItem> */}
                        </>
                    )}
                    {(currentUser?.role === "teacher" || currentUser?.role === "student") && (
                        <NavigationItem>
                            <ButtonLink onClick={() => {
                                setDebatesModal(true)
                                onCloseMenuBook()
                            }}>
                                1Debate!
                            </ButtonLink>
                        </NavigationItem>
                    )}
                    {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, 'exams', true) && (
                        <NavigationItem>
                            <ButtonLink onClick={() => {
                                onAssessments()

                                onCloseMenuBook()
                            }}>
                                EXAMS
                            </ButtonLink>
                        </NavigationItem>
                    )}
                </Navigation>
            </StyledDrawer>
            <div style={{ display: 'none' }}>
                {/* <IdeaModal ideasModal={ideasModal} setIdeasModal={setIdeasModal} /> */}
                <WheelModal wheelsModal={wheelsModal} setWheelsModal={setWheelsModal} />
                <DebateModal debatesModal={debatesModal} setDebatesModal={setDebatesModal} />
            </div>
        </div>
    );
};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    activities: state.activities,
    userBooksList: state.userBooksList,
    classQuestions: state.classQuestions,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    questions: state.questions,
    quizzes: state.quizzes,
    userClasses: state.userClasses,
    booksAllList: state.booksAllList,
    currentClass: state.currentClass,
    selectedUser: state.selectedUser,
    feedbackForm: state.feedbackForm,
    feedback: state.feedback,
    classUsers: state.classUsers,
    multiclassPopupForm: state.multiclassPopupForm,
    submissions: state.submissions,
    schools: state.schools,
    districts: state.districts,
    users: state.users,
});

export default connect(mapStateToProps, null)(Wrapper);
