//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Modal as ModalSection } from '@material-ui/core';
import { Add, Edit } from "@material-ui/icons";
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { Col, Row, Tabs, Card, Input } from 'antd';
import Loading from 'components/Loading';

//  -- Constants ----------------------
import { filter, get, isEmpty, map, find } from 'lodash';
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAllActiveInActiveBook, useAllBook } from 'store/books/selectors';
import { useCurrentOrg } from 'store/organizations/selectors';
import { useBookUnits } from 'store/booksUnits/selectors';
import { handleActiveInActiveBook } from 'store/books/thunks';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Header = styled.span`
    color: ${COLOR.black100};
    display: block;
	font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
	margin: 0px auto;
    // max-width: 480px;
    width: 100%;
`;
const StableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const FilterContainer = styled.span`
    font-size:17px;
    font-weight:500;
    cursor:pointer;
`;
const { TabPane } = Tabs;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//

const sortBookById = (books) => {
    if (Array.isArray(books)) {
        return books.sort((a, b) => (parseInt(a.id) - parseInt(b.id)))
    } else {
        return books;
    }

}
const BooksTable = ({ }) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const bookUnits = useBookUnits();
    const booksAllList = useAllBook();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [ActiveLoader, setActiveLoader] = useState(-1);
    const [filteredBooks, setFilteredBooks] = useState(null);
    const [inActivepage, setInActivePage] = React.useState(0);
    const [alertForStatus, setAlertForStatus] = useState(false)
    const [bookToActive, setBookToActive] = useState({})
    const [bookStatus, setBookStatus] = useState('')
    const [filteredBooksDeActive, setFilteredBooksDeActive] = useState(null)
    const [rowsPerPageInActive, setRowsPerPageInActive] = useState(50);
    useEffect(() => {
        // setFilteredBooks(sortBookById(booksAllList));
        setFilteredBooks(sortBookById(booksAllList?.filter(book => book?.active)));
        setFilteredBooksDeActive(sortBookById(booksAllList?.filter(book => !book?.active)));
    }, [booksAllList])
    const handleSearch = (event) => {
        if (event.target.value && event.target.value.length > 1) {
            var search = event.target.value;
            const filtered = sortBookById(filter(booksAllList, x => {
                return (`${x.description}`.toLowerCase().includes(search.toLowerCase()) || `${x.id}`.toLowerCase().includes(search.toLowerCase()));
            }));

            setFilteredBooks(filtered);
        } else {
            setFilteredBooks(sortBookById(booksAllList));
        }
    };
    const editResources = () => {
        history.push('/edit-resources')
    }
    const editExams = () => {
        history.push('/edit-exams')
    }
    const editStories = () => {
        history.push('/edit-story')
    }
    const editApExams = () => {
        history.push('/edit-ap-exams')
    }
    const editHowTO = () => {
        history.push('/edit-how-to-videos')
    }
    const editBookGroup = () => {
        history.push('/edit-book-grouping')
    }
    const handleEditBook = (bookId) => {
        history.push(`/add-book?bookId=${bookId}`)
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const hasBookUnits = (id) => {
        return Boolean(find(bookUnits, ['id', id]))
    }

    const handleActiveBook = async (bookId) => {

        setActiveLoader(bookId)
        await dispatch(handleActiveInActiveBook(bookId, false))
        setActiveLoader(-1)
        setAlertForStatus(false)
        setBookStatus('')

    }
    const handleDeActiveBook = async (bookId) => {
        setActiveLoader(bookId)
        await dispatch(handleActiveInActiveBook(bookId, true))
        setActiveLoader(-1)
        setAlertForStatus(false)
        setBookStatus('')
    }

    const handleChangePageInActive = (event, newPage) => {
        setInActivePage(newPage);
    };

    const handleChangeRowsPerPageinActive = (event) => {
        setRowsPerPageInActive(parseInt(event.target.value, 10));
        setInActivePage(0);
    }

    return (
        <>
            <ModalSection
                open={alertForStatus}
                onClose={() => setAlertForStatus(false)}
            >
                <ModalContent>
                    Are you sure you want to {get(bookToActive, "active", true) ? "In-Active" : "Active"}  {get(bookToActive, "description", '')}
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setAlertForStatus(false)
                                setBookToActive({})
                                setBookStatus('')
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginRight: '4px' }}
                            color="secondary"
                            variant="contained"
                            className={`${ActiveLoader != -1 ? "disabled-button" : ""}`}
                            onClick={() => {
                                // enableDisableUser(userToEnable.id, !userToEnable.active)
                                {bookStatus == 'active' ? handleActiveBook(bookToActive.id) :handleDeActiveBook(bookToActive?.id);}
                                
                            }}
                        >
                            {ActiveLoader != -1 ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </ModalSection>
            <StableWrapper style={{ paddingTop: 24 }}>
            <FilterContainer className="FilterHeading">
                    <Card
                        type="inner"
                        title="Filters"
                    >
                        <Row>
                            <Col xs={24} lg={24} md={24} className="ColCardPadding">
                                <Card style={{ width: '100%' }}>
                                    <Input placeholder="Search by name" bordered={false} style={{ height: '32px' }} onChange={handleSearch} />
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </FilterContainer>
                <TableContainer>
                    {/* <Row style={{ paddingBottom: 12 }}>
                        <Col span={3}>
                            <Button variant="contained" color="default" onClick={editResources} style={{ textTransform: 'inherit' }}>
                                <Edit style={{ fontSize: "16px", marginRight: "4px" }} />
                                Edit resources
                            </Button>
                        </Col>
                        <Col span={3}>
                            <Button variant="contained" color="default" onClick={editExams} style={{ textTransform: 'inherit' }}>
                                <Edit style={{ fontSize: "16px", marginRight: "4px" }} />
                                Edit Exams
                            </Button>
                        </Col>
                        <Col span={3}>
                            <Button variant="contained" color="default" onClick={editHowTO} style={{ textTransform: 'inherit' }}>
                                <Edit style={{ fontSize: "16px", marginRight: "4px" }} />
                                How To Videos
                            </Button>
                        </Col>
                        <Col span={3}>
                            <Button variant="contained" color="default" onClick={editBookGroup} style={{ textTransform: 'inherit' }}>
                                <Edit style={{ fontSize: "16px", marginRight: "4px" }} />
                                Book Grouping
                            </Button>
                        </Col>
                        <Col offset={12} span={4}>
                            <TextField
                                id="search"
                                name="search"
                                label="Search"
                                type="text"
                                variant="filled"
                                onKeyUp={handleSearch}
                                size="small" style={{ backgroundColor: "rgba(255, 255, 255, 1)" }} />
                        </Col>
                    </Row> */}
                    <Tabs defaultActiveKey="1" style={{ backgroundColor: '#fff', marginLeft: 10 }}>
                        <TabPane tab={<div style={{ marginLeft: '10px' }}>Active Books</div>} key="1"   >


                            <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ textAlign: "center" }}>id</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Display Name</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>pages</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Printable</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Units</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Status</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(!isEmpty(filteredBooks)) && (
                                        filteredBooks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <TableRow key={row.id} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }} onClick={x => { }}>
                                                <TableCell style={{ textAlign: "center" }}>{row.id}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{row.description}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{get(row, "pages.length", 0)}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{`${get(row, "printable", false) ? get(row, "printable", false) : ''}`}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>
                                                    <Button onClick={x => {
                                                        handleEditBook(row.id)
                                                    }} style={{ marginRight: '8px' }} >
                                                        <Edit></Edit>
                                                    </Button>
                                                </TableCell>

                                                <TableCell style={{ textAlign: "center" }}>
                                                    {hasBookUnits(row.id) ? <Button
                                                        variant="contained"
                                                        color='primary'
                                                        onClick={x => {
                                                            history.push(`/edit-book-units/?bookId=${row.id}`)
                                                        }} style={{ marginRight: '8px' }} >
                                                        Edit
                                                    </Button> : null}
                                                </TableCell>
                                                <TableCell style={{ textAlign: "center" }}>
                                                    <Button
                                                        variant="contained"
                                                        color='primary'
                                                        onClick={x => {
                                                            // handleActiveBook(row.id)
                                                            setAlertForStatus(true)
                                                            setBookToActive(row)
                                                            setBookStatus('active')
                                                        }} style={{ marginRight: '8px' }} >
                                                        {ActiveLoader == row.id ? <Loading size={'18px'} white /> : ('Active')}

                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {!isEmpty(filteredBooks) && (
                                            <TablePagination
                                                count={filteredBooks.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                backIconButtonProps={{
                                                    color: "secondary"
                                                }}
                                                nextIconButtonProps={{ color: "secondary" }}
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>

                            </Table>
                        </TabPane>
                        <TabPane tab="In-Active Books" key="2">

                            <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ textAlign: "center" }}>id</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Display Name</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>pages</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Units</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>Status</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(!isEmpty(filteredBooksDeActive)) && (
                                        filteredBooksDeActive.slice(inActivepage * rowsPerPageInActive, inActivepage * rowsPerPageInActive + rowsPerPageInActive).map((row) => (
                                            <TableRow key={row.id} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }} onClick={x => { }}>
                                                <TableCell style={{ textAlign: "center" }}>{row.id}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{row.description}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{get(row, "pages.length", 0)}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>
                                                    <Button onClick={x => {
                                                        handleEditBook(row.id)
                                                    }} style={{ marginRight: '8px' }} >
                                                        <Edit></Edit>
                                                    </Button>
                                                </TableCell>
                                                <TableCell style={{ textAlign: "center" }}>
                                                    {hasBookUnits(row.id) ? <Button
                                                        variant="contained"
                                                        color='primary'
                                                        onClick={x => {
                                                            history.push(`/edit-book-units/?bookId=${row.id}`)
                                                        }} style={{ marginRight: '8px' }} >
                                                        Edit
                                                    </Button> : null}
                                                </TableCell>
                                                <TableCell style={{ textAlign: "center" }}>
                                                    <Button
                                                        variant="contained"
                                                        color='crimson'//crimson
                                                        onClick={x => {
                                                            // handleDeActiveBook(row.id);
                                                            setAlertForStatus(true)
                                                            setBookToActive(row);
                                                            setBookStatus('Inactive')
                                                            console.log('row', row)
                                                        }} style={{ marginRight: '8px', backgroundColor: 'crimson', color: 'white', textTransform: 'inherit' }} >
                                                        {ActiveLoader == row.id ? <Loading size={'18px'} white /> : 'InActive'}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {!isEmpty(filteredBooksDeActive) && (
                                            <TablePagination
                                                count={filteredBooksDeActive.length}
                                                rowsPerPage={rowsPerPageInActive}
                                                page={inActivepage}
                                                onPageChange={handleChangePageInActive}
                                                onRowsPerPageChange={handleChangeRowsPerPageinActive}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                backIconButtonProps={{
                                                    color: "secondary"
                                                }}
                                                nextIconButtonProps={{ color: "secondary" }}
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>

                            </Table>
                        </TabPane>
                    </Tabs>

                </TableContainer>
            </StableWrapper>
        </>
    );
};


//  -- Prop types ---------------------
BooksTable.propTypes = {};

//  -- Default props ------------------
BooksTable.defaultProps = {};

export default BooksTable;
