//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import styled from 'styled-components';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    font-family: monospace;
    font-size: 32px;
    min-height: 500px;
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const NotFoundContainer = () => {
    return (
        <Wrapper>404: Page not found.</Wrapper>
    );
};

//  -- Prop types ---------------------
NotFoundContainer.propTypes = {};

//  -- Default props ------------------
NotFoundContainer.defaultProps = {};

export default NotFoundContainer;
