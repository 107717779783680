import React, { useState } from "react";
import { EmojiEmotions } from "@material-ui/icons";
import styled from "styled-components";
import { useComponentVisible } from "./hooks";
import COLOR from "utils/constants/color";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
// import { Button, Modal } from 'antd';
import { Modal } from "@material-ui/core";

const EmojiButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
const EmojiWrapper = styled.div`
  //   position: absolute;
  //   bottom: ${(p) => (p.bottom ? p.bottom : "60px")};
  //   overflow: visible;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
//   padding-top: 5px;
`;

export default function CheckListEmoji({ imageUrl, onEmojiClick, bottom = "60px" }) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTouchOpen = (event) => {
    event.preventDefault();
    handleOpen();
  }

  return (
    <Wrapper>
      <Modal open={open} onClose={handleClose} className="checkListEmojiModal">
        <EmojiWrapper ref={ref} bottom={bottom}>
          <Picker
            data={data}
            onEmojiSelect={(val) => {
              handleClose()
              onEmojiClick(val)
            }}
            theme="light"
            previewPosition="none"
            style={{ height: 300, width: 250 }}
          />
        </EmojiWrapper>
      </Modal>
      <EmojiButton onClick={() => handleOpen()} onTouchStart={handleTouchOpen} >
        {/* <EmojiEmotions style={{ color: COLOR.yellow }} /> */}
        {imageUrl ? (
          <span>{imageUrl}</span>
        ) : (
          <img
            src={require("../../utils/assets/images/checkList_Emoji.jpg")}
            alt=""
          />
        )}
      </EmojiButton>
    </Wrapper>
  );
}
