//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Button, Modal } from "@material-ui/core";
import { ReactMicGold } from 'audio-recorder';
import { storage } from 'firebaseConfig';
import { ref as fireRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import moment from 'moment';
import { lighten } from 'polished';
import React, { useEffect, useRef, useState ,useImperativeHandle } from "react";
import styled from "styled-components";
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { v4 } from 'uuid';
import Loading from "components/Loading";



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

const Container = styled.div`
    padding: 16px;
    overflow: hidden;
    width: 100%;
`;

const Counter = styled.span`
    color: ${COLOR.green100};
    display: block;
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    margin-bottom: 16px;
    text-align: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0px;
    }
`;

const Text = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 24px;
    text-align: center;
`;

const Disclosure = styled.span`
    color: ${COLOR.yellow};
    display: block;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    text-align: center;
`;

const Warning = styled.div`
    align-items: center;
    background-color: ${lighten(0.45, 'red')};
    border: 1px dashed red;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    padding: 16px;
    text-align: center;
    width: 100%;
`;

const Wrapper = styled.div`
    border-bottom: 1px dashed ${COLOR.black700};
    margin-bottom: 8px;
    padding: 0px 0px 24px;
    width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Interaction
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...

/**
 *   handleAudio,
    maxTime,
    message,
    refresh,
    storageLoc,
    isPreview=false,
    source,
    wrapperStyle={},
    hasSaveButton
 */
const AudioRecorder =React.forwardRef(({
    handleAudio,
    maxTime,
    message,
    refresh,
    storageLoc,
    isPreview=false,
    source,
    wrapperStyle={},
    hasSaveButton,
    doneButton
},ref) => {
    const [AllowAudio, setAllowAudio] = useState(false);
    const [ErrorAllowAudio, setErrorAllowAudio] = useState(false);
    const [audio, setAudio] = useState(null);
    const [counter, setCounter] = useState(null);
    const [recording, setRecording] = useState(false);
    const [noAccess, setNoAccess] = useState(false);
	const [noPermission, setNoPermission] = useState(false);
    const [inUrlProcess, setInUrlProcess] = useState(false);
    const [pause, setPause] = useState(false);
    const timmerRef=useRef()
    useEffect(() => {
        if(source&&source.length>0){
            setInUrlProcess(true)
            setAudio(source)
            setInUrlProcess(false)
            setRecording(false)
        }else{
            setAudio(source)
            setInUrlProcess(false)
            setRecording(false)
        }
    } ,[source])
    const uploadToStorage = async (blob) => {
        if (blob) {
            const location = storageLoc || '/audio/activity-submissions';
            const id = `${moment().format('YYYY-MM-DD')}-${v4()}`;
            const audioRef = fireRef(storage, `${location}/${id}`);
            try {
                await uploadBytes(audioRef, blob);
                const url = await getDownloadURL(audioRef);
                setData(url);
            } catch (error) {
                console.error("Upload error:", error);
            }
        } else {
            setInUrlProcess(false);
        }
    };

    useImperativeHandle(ref, () => ({
        pause:onPause,
        resume:onResume,
        stop:stopRecord,
        reset:resetRecording,
        start:startRecord,
        setAudio:setAudio
      }));

    useEffect(() => {
		try {
			if(isPreview){
				setNoAccess(false)
				setNoPermission(false)
				return;
			}
			const isFirefox = navigator.userAgent.indexOf("Firefox") > -1;
			if(navigator.permissions&&!isFirefox){
				navigator.permissions.query(
					{ name: 'microphone' }
				).then(function(permissionStatus){
					if(permissionStatus.state==='denied'){
						setNoAccess(false)
						setNoPermission(true)
					}
					permissionStatus.onchange = function(){
						if(this.state==='denied'){
							setNoAccess(false)
							setNoPermission(true)
						}else{
							setNoAccess(false)
							setNoPermission(false)
						}
					}
				})
			}
			if(navigator?.mediaDevices&&!isFirefox){
				navigator.mediaDevices.getUserMedia({ audio: true })
					.then((stream) => {
						stream.getTracks().forEach(_tr=>_tr.stop())
						setNoAccess(false)
						setNoPermission(false)
					})
					.catch(() => {
						setNoAccess(true)
						setNoPermission(false)
					});
			}
		} catch (error) {
			setNoPermission(true)
		}
    }, []);

    useEffect(() => {
        timmerRef.current = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

        if (counter === 1) {
            stopRecord();
        }

        return () => clearInterval(timmerRef.current);
    }, [counter])


    const onStop = (data) => {
        clearInterval(timmerRef.current);
        uploadToStorage(data.blob);
    };

    const startRecord = () => {
        if (!recording) {
            setAllowAudio(true);
            const permissions = navigator.mediaDevices.getUserMedia({audio: true, video: false})
            permissions.then((stream) => {
                setAllowAudio(false);
                setRecording(true);
                setCounter(maxTime);
            })
            .catch((err) => {
                setAllowAudio(false);
                setErrorAllowAudio(true);
                console.log(`${err.name} : ${err.message}`)
            });
        }
    };

    const stopRecord = async () => {
        setInUrlProcess(true);
        setRecording(false);
		clearInterval(timmerRef.current);
    };

    const resetRecording = () => {
        setCounter(null);
        setAudio(null);
        timmerRef.current=null;
        setRecording(false)
    };

    const setData = (url) => {
        setInUrlProcess(false);
        setAudio(url);
        handleAudio(url);
    }

    const onPause = () => {
        if(recording){
            setPause(true);
            clearInterval(timmerRef.current);
        }
    }

    const onResume = () => {
        if(recording){
            setPause(false);
            setCounter(counter-1);
        }
    }

	const secondsToTime = (secs, description = false) => {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		var string = "";

		if (description) {
			if (hours > 0) {
				string += hours + " hours, "
			}

			if (minutes > 0) {
				string += minutes + " minutes"
			}

			if (seconds > 0) {
				string += ", " + (seconds < 10 ? "0" : "") + seconds + " seconds"
			}
		} else {
			if (hours > 0) {
				string += hours + ":"
			}

			if (minutes > 0) {
				string += (minutes < 10 ? "0" : "") + minutes
			} else {
				string += "00"
			}

			if (seconds > 0) {
				string += ":" + (seconds < 10 ? "0" : "") + seconds
			} else {
				string += ":00"
			}
		}

		return string;
	}

	const [actualSampleRate, setActualSampleRate] = useState(null)
	useEffect(() => {
		const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
		setActualSampleRate(audioCtx.sampleRate)
	}, [])

    return (
        <Wrapper style={wrapperStyle}>
            <Modal
                open={AllowAudio}
                onClose={() => setAllowAudio(false)}
                ><ModalContent>
                    To record the audio, click “Allow” above to give One Way Education access to your computer’s microphone.
                </ModalContent>
            </Modal>
            <Modal
                open={ErrorAllowAudio}
                onClose={() => setErrorAllowAudio(false)}
                ><ModalContent>
                    To record the audio, One Way Education needs access to your computer's microphone. Click “Allow” to give One Way Education access to your computers microphone.
                    <ModalRow>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setErrorAllowAudio(false);
                            }}
                        >
                            Ok, I got it!
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Container>
				{noAccess || noPermission ? (
                    <Warning>
						{noPermission ? 'Please allow access to your microphone and click the button below. This will ensure you have proper access to the recording functionality.' : ''}
						{noAccess && !noPermission ? 'Please connect your microphone and click the button below. This will ensure you have proper access to the recording functionality.' : ''}
                        <Button variant='contained' onClick={refresh} style={{ backgroundColor: 'red', color: 'white', marginTop: '16px', width: '100%' }}>Refresh</Button>
                    </Warning>
                ) : (
                    <>
                        {(!recording && audio) ? (
                            <>
                                <Row>
                                    <audio controls controlsList="nodownload"  src={audio} style={{ height: '40px' }}></audio>
                                </Row>
                                <Row style={{ justifyContent: 'space-between',}}>
                                    <Button onClick={() => resetRecording()} style={{ marginLeft: '8px' }}>Re-record</Button>
                                    {doneButton?doneButton:null}
                                </Row>
                            </>
                        ) : (
                            <>
                                {counter === null && (
									<>
										<Text style={{ marginBottom:'5px' }}>{message ?? `To begin your answer click the "Record" button below. You will have ${secondsToTime(maxTime, true)} to record your answer.`}</Text>
										<Disclosure>{`Place your microphone close to have a better audio quality.`}</Disclosure>
									</>
                                )}
                                {(maxTime && counter > 0) && (
                                    <>
										<Text style={{ marginBottom:'5px' }}>Time Remaining</Text>
										<Disclosure>{`Place your microphone close to have a better audio quality.`}</Disclosure>
										<Counter>{ secondsToTime(counter) }</Counter>
                                        <ReactMicGold
                                            record={recording}
                                            onStop={onStop}
                                            pause={pause}
                                            strokeColor="#3D5F8F"
                                            echoCancellation={true}
                                            bitRate={256000}
                                            sampleRate={actualSampleRate}
                                            noiseSuppression={true}
                                            autoGainControl={true}
                                            onError={e=>{
                                                console.log(e,"error in golden mic")
                                                setInUrlProcess(false)
                                            }}
                                            height={40}
                                            mimeType="audio/mp3"
                                        />
                                    </>
                                )}
                                {!maxTime && (
                                    <ReactMicGold
                                        record={recording}
                                        onStop={onStop}
                                        pause={pause}
                                        strokeColor="#3D5F8F"
                                        echoCancellation={true}
                                        bitRate={256000}
                                        sampleRate={actualSampleRate}
                                        noiseSuppression={true}
                                        autoGainControl={true}
                                        height={40}
                                        mimeType="audio/mp3"
                                        onError={e=>{
                                            console.log(e,"error in golden mic")
                                            setInUrlProcess(false)
                                        }}
                                    />
                                )}
                                        <Row>
                                            <Button
                                                disabled={inUrlProcess || isPreview}
                                                color="primary" variant='contained' onClick={() => {
                                                    if (!isPreview) {
                                                        if (recording && pause) {
                                                            onResume()
                                                        } else if (recording && !pause) {
                                                            onPause()
                                                        } else {
                                                            startRecord()
                                                        }
                                                    }
                                                }} style={{ marginRight: '8px' }}>
                                                {inUrlProcess && <Loading white size={14} />}
                                                {recording && pause ? "Resume" : recording && !pause ? "Pause" : "Record"}  </Button>
                                            <Button disabled={!recording || (maxTime && maxTime === counter)} onClick={() => stopRecord()}>{hasSaveButton ? "Save" : "Stop"}</Button>
                                        </Row>
                            </>
                        )}
                    </>
                )}
            </Container>
        </Wrapper>
    )
});

//  -- Prop types ---------------------
AudioRecorder.propTypes = {};

//  -- Default props ------------------
AudioRecorder.defaultProps = {};

export default AudioRecorder;
