export const signUpHelper = ({ username, password, userId }) => {
    const value = password;
    return {
        username: username.trim(),
        password: value.trim(),
        attributes: {
            'custom:userId': `${userId}`,
        },
    };
}

export const authHelper = ({ username, password }) => {
    const value = password;
    return {
        username: username.trim(),
        password: value.trim(),
    };
}