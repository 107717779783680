//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const alertViewInit = {
    view:false,
    title:'',
    message:''
};


export const alertView = (state = alertViewInit, action) => {
    switch (action.type) {
        case ActionTypes.alert.invokeAlert:
            return ({ ...state, view:true  , title:action.payload.title , message:action.payload.message});
        case ActionTypes.alert.revokeAlert:
            return ({ ...state, view:false  , title:'', message:''  });
        default:
            return state;
    }
};


