//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import SentryWrap from "components/Sentry";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
// import Table from "components/Table";
import { filter, find, groupBy , get } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";

//  -- Thunks -------------------------

import { Select, Space } from 'antd';
import { isEmpty , cloneDeep } from 'lodash';
import moment from "moment-timezone";
import { useExams } from "store/exams/selectors";
import { handleListExamDataByConfig } from "store/exams/thunks";
import { handleListGetSchool } from "store/organizations/thunks";
import { handleListDistTeacher, handleListDistUsers } from "store/users/thunks";
import ExamTableForDistrict from "./ExamTableForDistrict";
import { IconButton } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Loading from "components/Loading";
import { DownloadOutlined } from '@ant-design/icons';
import XLSX from 'xlsx';
const years = new Date().getFullYear();
const upcomming_year = `${years}-${years + 1}`;
const downcomming_year = `${years - 1}-${years}`;
const ACIFL_LEVEL_ABBR = ['AH ', 'AM', 'AL', 'IH', 'IM', 'IL', 'NH', 'NM', 'NL', 'FA']
const ACIFL_LEVEL = ['ADV HIGH ', 'ADV MID', 'ADV LOW', 'INTER HIGH', 'INTER MID', 'INTER LOW', 'NOVICE HIGH', 'NOVICE MID', 'NOVICE LOW', 'FALTANTES']
const EXAM_LABELS_FULL = ['Interpretativa(A)', 'Interpretativa(B)', 'Comprensión literaria', 'Comprensión auditiva', 'Comunicación interpersonal', 'Presentación oral informal']
const { Option } = Select;


const SORTING_ORDER = [
  { value: 'district', label: 'District' },
  { value: 'school', label: 'Schools' },
  { value: 'teacher', label: 'Teacher' },
]
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.button`
    border: none;
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
    margin-right: 16px;
    height: 40px;
    background: ${COLOR.green200};
    color: #fff;
    margin-top: 20px;
    padding-left: 12px;
    padding-right: 12px;
    &:hover {
      background: ${COLOR.green1000};
    }
`;

const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0px;
  width: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;


const VideoContainer = styled.div`
  height: auto;
  flex: 1;
  justify-content: center;
`;

const OverviewText = styled.span`
  color: black;
  font-size: 19px;
  font-weight: 600;
  display:flex !important;
  align-items:center;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 0px !important;
    border: 1px solid #d9d9d9 !important; 
    background-color: rgb(237 237 237) !important;
    height: 40px !important;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .ant-select-dropdown {
   
  }
`;
const Title = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const SELECT_WIDTH = 200

const SortingMenu = ({
  grades,
  onFilter,
  selected_class
}) => {
  const [selectedClass, setSelectedClass] = useState(null)
  const classData = useMemo(() => {
    try {
      const g = groupBy(grades, 'user.class_id')
      const class_data = Object.keys(g).map(k => {
        const cls = g[k][0]
        if (cls && cls.user) {
          return {
            value: cls.user.class_id,
            label: cls.user.class_name
          }
        }
        return null;
      }).filter(k => k)
      return class_data
    } catch (error) {
      console.log(error, "error")
      return []
    }

  }, [grades])

  useEffect(() => {
    setSelectedClass(selected_class)
  }, [selected_class])

  const onSelectCh = (txt) => {
    setSelectedClass(txt)
    onFilter(txt)
  }
  return (<div>
    <label htmlFor="schoolSelect" style={{ display: 'block', }}>
      Classes
    </label>
    <StyledSelect
      showSearch
      bordered={false}
      placeholder={"Select classes"}
      style={{ width: SELECT_WIDTH }}
      value={selectedClass}
      onChange={onSelectCh}
      options={classData}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    /></div>)
}

const Litracy = ({
  currentOrg,
  districts,
  schools,
  users,
  distTeacher,
  distStudents
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(false);
  const exams = useExams()
  const [teachers, setTeachers] = useState([]);
  const [activeSession, setActiveSession] = useState(null)
  const [resultData, setResultData] = useState({
    question_length:[],
    grades:[]
  })
  const [resultDataCombine, setResultDataCombine] = useState({
    question_length: [{
      exam1: '',
      exam2: '',
      exam3: ''
    }],
    grades: [
      {
        user: {},
        submissions:[{
          exam1: '',
          exam2: '',
          exam3: ''
        }]
      }
    ]
  })
  const [selected_school_type, setSelectedSchoolType] = useState('high')
  const [examTab, setExamTab] = useState('exam1')
  const [selected_teacher, setSelectedTeacher] = useState(null);
  const [selected_school, setSelectedSchool] = useState(null);
  const [selected_class, setSelectedClass] = useState(null);
  const [printloader, setPrintloader] = useState(false)
    useEffect(() => {
        if(moment().get("month")<7){
            setActiveSession(downcomming_year) 
        }else{
            setActiveSession(upcomming_year)
        }
    }, [exams])
  useEffect(() => {
    if (Array.isArray(users)) {
      setTeachers(filter(distTeacher, ['role', 'teacher']))
    }
  }, [distStudents, distTeacher])
  const cotationRef = useRef(null)
  if (!cotationRef.current) {
    cotationRef.current = getRandomCotation()
  }
  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      const val = getExamValue(examTab);
      if (val === 'combine') {
        await onFilterFetchResultCombine(activeSession, selected_school_type, selected_school, selected_teacher);
      } else {
        await fetchForExam(activeSession, '1', selected_school_type, selected_school, selected_teacher)
      }
    }
    if (activeSession) {
      fetchData();
    }
  }, [currentOrg, activeSession, selected_school, examTab, selected_teacher, selected_school_type]);
  useEffect(() => {
    fetchDistrictData()
  }, [currentOrg]);

  const fetchDistrictData = async () => {
    try {
      if (!isEmpty(currentOrg)) {
        dispatch(handleListGetSchool(currentOrg.id));
        dispatch(handleListDistUsers(currentOrg.id));
        dispatch(handleListDistTeacher(currentOrg.id));
      }
    } catch (error) {
    }
  };


  const onExamTabClick = (txt) => {
    setExamTab(txt)
    // onFilterFetchResult('exams', txt)
}
  async function fetchForExam(session, e_type, s_level, school_id, teacher_id) {
    if(!school_id || !teacher_id) return null;
    setLoadingData(true);
    const data = await dispatch(handleListExamDataByConfig({
      "session": session,
      "exam_type": e_type,
      "school_level": s_level,
      "id": currentOrg.id,
      school_id: school_id === 'all' ? null : school_id,
      teacher_id: teacher_id === 'all' ? null : teacher_id,
    }));
    if (data && data.question_length) {
      setResultData({
        question_length: data.question_length || [],
        grades: data.grades || [],
      })
      let first_student = data.grades[0]
      if (first_student) {
        if (selected_class && selected_class != 'all') {
          const has_class = find(data.grades, ['user.class_id', selected_class])
          if (!has_class) {
            setSelectedClass(first_student.user.class_id)
          }
        } else {
          setSelectedClass(first_student.user.class_id)
        }
      }
    }else{
      setSelectedClass(null)
    }
    setLoadingData(false);
  }
  function combinePlaceholderTxt(txt) {
    switch (txt) {
      case 'NOT ASSIGNED':
        return 'NA'
      case 'NOT STARTED':
        return 'NS'
      case 'NOT FINISHED':
        return 'NF'
      default:
        break;
    }
    return ACIFL_LEVEL_ABBR[ACIFL_LEVEL.findIndex(k => k === txt)] || ''
  }
  async function onFilterFetchResultCombine(session, school_level, school_id, teacher_id) {
    if(!school_id || !teacher_id) return null;
    try {
      setLoadingData(true);
      const examTypes = [1, 2, 3];
      const dataExams = await Promise.all(examTypes.map(exam_type =>
        dispatch(handleListExamDataByConfig({
          session,
          exam_type,
          school_level,
          "id": currentOrg.id,
          school_id: school_id === 'all' ? null : school_id,
          teacher_id: teacher_id === 'all' ? null : teacher_id,
        }))
      ));
  
      if (dataExams[0]?.question_length) {
        // Assuming data structure is similar across exams
        const question_length = dataExams[0].question_length.map((_, i) => ({
          exam1: dataExams[0].question_length[i],
          exam2: dataExams[1].question_length[i],
          exam3: dataExams[2].question_length[i],
        }));
  
        const grades = dataExams[0].grades.map(({ user, submissions }) => ({
          user,
          submissions: submissions.map((_, i) => {
            const exam1Sub = find(dataExams[0].grades, ['user.id', user.id]);
            const exam2Sub = find(dataExams[1].grades, ['user.id', user.id]);
            const exam3Sub = find(dataExams[2].grades, ['user.id', user.id]);
  
            const exam1Txt = exam1Sub.submissions[i];
            const exam2Txt = exam2Sub.submissions[i];
            const exam3Txt = exam3Sub.submissions[i];
  
            return {
              exam1: i === 0 ? combinePlaceholderTxt(exam1Txt) : exam1Txt,
              exam2: i === 0 ? combinePlaceholderTxt(exam2Txt) : exam2Txt,
              exam3: i === 0 ? combinePlaceholderTxt(exam3Txt) : exam3Txt,
            };
          }),
        }));
  
        setResultDataCombine({ question_length, grades });
        let first_student = grades[0]
        if (first_student) {
          if (selected_class && selected_class != 'all') {
            const has_class = find(grades, ['user.class_id', selected_class])
            if (!has_class) {
              setSelectedClass(first_student.user.class_id)
            }
          } else {
            setSelectedClass(first_student.user.class_id)
          }
        }else{
          setSelectedClass(null)
        }
      }
    } catch (error) {
      console.error(error, "error in data compose");
    } finally {
      setLoadingData(false);
    }
  }
  
  const getExamValue = (key) => {
    const examMap = { exam1: 1, exam2: 2, exam3: 3, combine: 'combine' };
    return examMap[key] || 'combine';
  };


  
  const onSchoolTypeChange = (txt) => {
    setSelectedSchoolType(txt);

  };
 
  const school_option = useMemo(() => {
    const values = []
    if (!isEmpty(schools)) {
      values.push(...schools.filter(k => !k.demo).map(v => ({ label: v.displayName, value: v.id })))
    }
    return values;
  }, [schools])

  useEffect(() => {
    const opt = school_option[0]
    if (opt) {
      setSelectedSchool(opt.value)
    }
  }, [school_option]);

  useEffect(() => {
    if (selected_school) {
      const teacher_list = filter(teachers, ['orgId', selected_school])
      const first_teacher = teacher_list[0];
      if (first_teacher && (!selected_teacher || !find(teacher_list, { 'id': selected_teacher }))) {
        setSelectedTeacher(first_teacher.id)
      }
    }
  }, [selected_school, teachers, selected_teacher])

  const exam_data = useMemo(() => {
    if (selected_class === 'all' || !selected_class) {
      return resultData
    } else {
      const v = cloneDeep(resultData);
      v.grades = filter(v.grades, ['user.class_id', selected_class])
      return v;
    }
  }, [selected_class, resultData])
  const exam_data_combine = useMemo(() => {
    if (selected_class === 'all' || !selected_class) {
      return resultDataCombine
    } else {
      const v = cloneDeep(resultDataCombine);
      v.grades = filter(v.grades, ['user.class_id', selected_class])
      return v;
    }
  }, [selected_class, resultDataCombine])
  const teacher_map = useMemo(() => {
    let tavl = []
    const demo_school = schools.filter(k => k.demo).map(k => k.id)
    if (selected_school === 'all') {
      tavl = teachers.filter(e=> !demo_school.includes(e.orgId) && !e.demo).map(v => ({ label: `${v.lastName || ''}, ${v.firstName || ''}`, value: v.id }))
    } else {
      tavl = filter(teachers, ['orgId', selected_school]).map(v => ({ label: `${v.lastName || ''}, ${v.firstName || ''}`, value: v.id }))
    }
    return tavl;
  }, [schools, teachers, selected_school]);
  const onSchoolSelect = (v) => {
    setSelectedTeacher(null)
    setSelectedSchool(v)
  };

  const generateCSVForCombinedExam = () => {
    try {
      const uniqueRows = new Set();
      const headers = ['Name', 'Username', ...Object.keys(exam_data_combine.question_length[0])];

      // Add headers for each exam and question
      exam_data_combine.question_length.forEach((question, index) => {
        Object.entries(question).forEach(([examKey, questionValue]) => {
          if (index === 0) { // First row for placement statuses
            headers.push(`${examKey} Placement`);
          } else { // Subsequent rows for questions
            headers.push(`${examKey} Q${index}`);
          }
        });
      });

      // Process grades for each user
      exam_data_combine.grades.forEach(({ user, submissions }) => {
        const rowData = {
          Name: `${user.lastName || ""}, ${user.firstName || ""}`,
          Username: user.username,
          ...submissions.reduce((acc, submission, index) => {
            Object.entries(submission).forEach(([examKey, value]) => {
              const headerKey = index === 0 ? `${examKey} Placement` : `${examKey} Q${index}`;
              acc[headerKey] = value;
            });
            return acc;
          }, {})
        };
        uniqueRows.add(rowData);
      });

      const finalData = Array.from(uniqueRows);
      const wb = XLSX.utils.book_new();
      const wsData = [headers, ...finalData.map(row => headers.map(header => row[header] || '-'))];
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, 'Combined Proficiency');
      XLSX.writeFile(wb, `combined_proficiency ${moment().format("DD-MM-YY hh:mm:ss")}.csv`);
    } catch (error) {
      console.error("Error in combined exam gradebook download:", error);
    }
  };
  
  const generateCSV = () => {
    if (examTab === "combined") {
      generateCSVForCombinedExam()
      return;
    }
  
    try {
      const uniqueRows = new Set();
      const headerData = {
        name: 'Questions per section:',
        username: '',
        placement: exam_data.question_length[0],
        ...EXAM_LABELS_FULL.reduce((acc, label, i) => {
          acc[label] = exam_data.question_length[i + 1];
          return acc;
        }, {})
      };
      uniqueRows.add(headerData);
  
      exam_data.grades.forEach(({ user, submissions }) => {
        const userData = {
          name: `${user.lastName || ""}, ${user.firstName || ""}`,
          username: user.username,
          placement: submissions[0],
          ...EXAM_LABELS_FULL.reduce((acc, label, i) => {
            acc[label] = submissions[i + 1];
            return acc;
          }, {})
        };
        uniqueRows.add(userData);
      });
      const groupdata = groupBy(exam_data.grades, ({ submissions }) => submissions[0])
      const extraData = ACIFL_LEVEL.reduce((acc, type) => {
        const sub = type === ACIFL_LEVEL.at(-1) 
          ? get(groupdata['NOT STARTED'], "length", 0) + get(groupdata["NOT FINISHED"], "length", 0)
          : get(groupdata[type], "length", 0);
        acc[type] = sub;
        return acc;
      }, {});
  
      const finalData = { data: Array.from(uniqueRows), firstRow: extraData };
  
      const wb = XLSX.utils.book_new();
      const exambook = [
        Object.keys(finalData.firstRow),
        Object.values(finalData.firstRow),
        [],
        Object.keys(finalData.data[0])
      ];
  
      finalData.data.forEach(userd => {
        exambook.push(Object.values(userd));
      });
  
      const ws = XLSX.utils.aoa_to_sheet(exambook);
      XLSX.utils.book_append_sheet(wb, ws, `proficiency`);
      XLSX.writeFile(wb, `proficiency ${moment().format("DD-MM-YY hh:mm:ss")}.csv`);
    } catch (error) {
      console.error("Error in exam gradebook download:", error);
    }
  };
  
  const onDownload = () => {
    generateCSV()
  };
  return (
      <SentryWrap>
          <Wrapper className={"adminForm"}>
              <OverviewText>Admin</OverviewText>
              <Space size="middle" style={{ display: 'flex', gap: 1 }}>
            <div>
              <label htmlFor="schoolSelect" style={{ display: 'block', }}>
                Schools
              </label>
              <StyledSelect
                defaultValue={selected_school}
                style={{ width: SELECT_WIDTH }}
                showSearch
                bordered={false}
                value={selected_school}
                placeholder={"Select"}
                options={school_option}
                onChange={onSchoolSelect}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </div>
            <div>
              <label htmlFor="schoolSelect" style={{ display: 'block', }}>
                Teachers
              </label>
              <StyledSelect
                defaultValue={'all'}
                value={selected_teacher}
                style={{ width: SELECT_WIDTH }}
                showSearch
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                options={teacher_map}
                onChange={(v) => {
                  setSelectedTeacher(v)
                }} />
            </div>
                <SortingMenu
                  grades={resultData.grades}
                  selected_class={selected_class}
                  onFilter={(v) => {
                    setSelectedClass(v)
                  }} />
              <Button onClick={onDownload} style={{ marginRight: '16px' }}>
                {printloader ? <Loading size={20} /> : <DownloadOutlined style={{ marginRight: '4px' }} />}
                Download
              </Button>
              {/* <IconButton onClick={onDownload} style={{ padding: 0 }} aria-label="download pdf" disabled={printloader} className="no-print">
                
              </IconButton> */}
              </Space>
             <ExamTableForDistrict 
                dataLoading={loadingData}
                exams={exams}
                examType={examTab}
                onExamTabClick={onExamTabClick}
                resultData={exam_data}
                onSchoolTypeChange={onSchoolTypeChange}
                schoolType={selected_school_type}
                activeSession={activeSession}
                resultDataCombine={exam_data_combine}
                setActiveSession={setActiveSession}
             />
          </Wrapper>
      </SentryWrap>
  );
};

//  -- Prop types ---------------------
Litracy.propTypes = {};

//  -- Default props ------------------
Litracy.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  activities: state.activities,
  classes: state.classes,
  currentOrg: state.currentOrg,
  districts: state.districts,
  interactions: state.interactions,
  quizzes: state.quizzes,
  schools: state.schools,
  users: state.users,
  booksAllList: state.booksAllList,
  allSchools: state.allSchools,
  allUser: state.allUser,
  allClasses: state.allClasses,
  distTeacher: state.distTeacher,
  distStudents: state.distStudents
});

export default connect(mapStateToProps, null)(Litracy);
