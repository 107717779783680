//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    FormControl, FormControlLabel, InputLabel,
    MenuItem,
    Select, Switch, TextField
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from "@material-ui/lab/Alert";
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import _, { filter, find, get, map } from "lodash";
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getOrganizationById, handleUpdateOrganization } from 'store/organizations/thunks';
import ChangePasswordForm from './changePassword';
//  -- Thunks -------------------------
import { handleAddStudentWithClass, handleCheckUsername, handleCreateUser, handleUpdateUser } from 'store/users/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import {
    DatePicker,
} from "@material-ui/pickers";
import { isEmpty } from 'lodash';
import moment from "moment";
import { handleCreateClassForClever, handleListTeacherClasses } from 'store/classes/thunks';
import { useCleverCources } from 'store/clever/selectors';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { handleCreateUserClass } from 'store/userClasses/thunks';
import COLOR from 'utils/constants/color';
import filterAssignableBook from 'utils/methods/filterAssignableBook';
import { isBeforTime } from 'utils/methods/math';
import { useHistory } from "react-router-dom";
import { handleUpdateDistLicence, handleUpdateDistrictLicences, handleUpdateSchoolLicences } from 'store/licences/thunks';
import { useLicences } from 'store/licences/selectors';
import SelectableBook from 'components/SelectableBook';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
    margin: 0px auto;
	// max-width: 480px;
    width: 100%;
`;

const BookSection = styled.div`
    width: 100%;
`

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },


    label: {
        textTransform: 'uppercase',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: () => null,
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Class form
//
//  -- Description
//  ...
//
//  -- Props
//  ...
//
const UserForm = ({
    userData,
    editing,
    onClose,
    schools,
    listBooks,
    demoToggle,
    onlyAdd,
    currentOrg,
    teacherByOrg,
    studentByOrg,
    currentUser
}) => {
    let booksByUser = [];
    let showBooksInitial = false;
    const permissions = useCurrentOrgPermission()
    const bookPermissions = _.get(permissions, "books", [])
    const cleverCources = useCleverCources()
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const assignableBook = _.filter(filterAssignableBook(listBooks, false), k => {
        if (bookPermissions.length == 0) {
            return true;
        }
        const bookaccess = _.find(bookPermissions, b => b.bookId == k.id || b.bookId == k.bookId)
        if (bookaccess) {
            return bookaccess.available
        } else {
            return true;
        }
    })
    if (editing) {
        if (userData?.books?.length > 0) {
            let booksU = userData.books;
            for (var b in booksU) {
                let finded = listBooks.find(e => e.id == booksU[b].bookId);
                if (finded != undefined) {
                    booksByUser.push(finded.description)
                }
            }
        }
        if (userData.role != 'owe_admin') {
            showBooksInitial = true;
        }
    }
    listBooks.sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
    const [showBooks, setShowBooks] = useState(showBooksInitial);
    const dispatch = useDispatch();
    const [data, setData] = useState(editing ? userData : {});
    const [loading, setLoading] = useState(false);
    const [viewChangePassword, setViewChangePassword] = useState(false);
    const [createUserError, setCreateUserError] = useState(null)
    const [isDemo, setDemo] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState('');
    const licencesCount = useLicences()
    let roles = [];
    if (get(currentUser, 'role', '') == 'admin') {
        roles = [
            {
                label: 'Director',
                value: 'director'
            },
            {
                label: 'Teacher',
                value: 'teacher'
            },
            {
                label: 'Student',
                value: 'student'
            }
        ]
    } else {
        roles = [
            {
                label: 'Owe Admin',
                value: 'owe_admin'
            },
            {
                label: 'District Admin',
                value: 'admin'
            },
            // {
            //     label: 'Director',
            //     value: 'director'
            // },
            {
                label: 'Teacher',
                value: 'teacher'
            },
            {
                label: 'Student',
                value: 'student'
            },
            {
                label: 'Parent',
                value: 'parent'
            },
        ]
    }

    function showChangePassword() {
        setViewChangePassword(true)
    }

    function hiddenChangePassword(option) {
        if (option) {
            setViewChangePassword(false);
            onClose();
        } else {
            setViewChangePassword(false);
        }
    }

    const classes = useStyles();
    const theme = useTheme();
    const [booksName, setBooksName] = React.useState(booksByUser);

    const [roleError, setRoleError] = useState(false);
    const [schoolError, setSchoolError] = useState(false);
    const [userError, setUserError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [formatPasswordError, setFormatPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameCharError, setFirstNameCharError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameCharError, setLastNameCharError] = useState(false);
    const [gradeError, setGradeError] = useState(false);
    const [booksError, setBooksError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [noLicensesError, setNoLicensesError] = useState(false);
    const [teacherStdError, setTeacherStdError] = useState(false);
    const [parentStdError, setparentStdError] = useState(false);
    const [classStdError, setClassStdError] = useState(false)
    const [typeOrg, setTypeOrg] = useState('NoSet');
    const [showDemo, setShowDemo] = useState(false)
    const [isClaver, setIsClaver] = useState(false)
    const [userClasses, setuserClasses] = useState([])
    const [teacherClass, setTeacherClass] = useState([])
    const [selectedTeacher, setSelectedTeacher] = useState('')
    const [selectedStudentForParent, setselectedStudentForParent] = useState('')
    const [selectedClass, setSelectedClass] = useState('')
    const [teacherClassLoader, setTeacherClassloader] = useState(false)
    const [findClassBook, setFindClassBook] = useState()
    const [selectedBook, setSelectedBook] = React.useState([]);

    const history = useHistory();
    useEffect(() => {
        if (editing && userData) {
            setDemo(userData.demo)
            setselectedStudentForParent(userData.parent_of)
            if (userData.role == "teacher" || userData.role == 'student') {
                setShowDemo(true)
            }
        }

    }, [userData])
    useEffect(() => {
        if (get(currentOrg, "cleverId")) {
            setIsClaver(true)
        } else {
            setIsClaver(false)
        }
    }, [currentOrg])

    useEffect(() => {
        if (Array.isArray(data.books) && data.books.length > 0) {
            setSelectedBook(data.books.map((i) => i.bookId ?? i.id))
        }
    }, [data])

    const handleChange = e => {
        var validation = e.target.value?.length >= 50;
        if (e.target.id == 'firstName') {
            if (validation) {
               setFirstNameCharError(true);
            } else {
                setFirstNameError(false);
                setFirstNameCharError(false);
            }
        }
        if (e.target.id == 'lastName') {
            if (validation) {
                setLastNameCharError(true);
             } else {
                setLastNameError(false);
                setLastNameCharError(false);
             }
        }
        if (e.target.id == 'grade') {
            setGradeError(false);
        }
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    };
    const handleUpdateData = (key, value) => {
        const resp = data;
        resp[key] = value;
        setData(resp)
        forceUpdate()
    }
    const handleSelect = (id, value) => {
        if (id == 'role') {
            if (!['owe_admin','parent'].includes(value)) {
                setShowBooks(true);
            }
            else {
                setShowBooks(false);
            }
            if (value == "teacher" || value == "student") {
                setShowDemo(true)
            } else {
                setShowDemo(false)
            }
            setRoleError(false);

            if (value == 'student' || value == 'teacher' || value == 'director' || value == 'parent') {
                setTypeOrg('school');
            }
            else {
                setTypeOrg('district');
            }
        }


        if (id == 'orgId') {
            setSchoolError(false);
        }
        if (id == 'teacherForStd') {
            setTeacherStdError(false);
        }
        if(id==="parent_of"){
            setparentStdError(false);
        }
        if (id == 'classForStd') {
            setClassStdError(false)
        }
        setData({
            ...data,
            [id]: value
        });
    };
    const getStartMintime = (value) => {
        if (get(data, "editing") && value) {
            isBeforTime(moment(value).toISOString()) ? moment().toISOString() : moment(value).toISOString()
        } else {
            return moment().toISOString()
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setCreateUserError(null)
        let allForm = true;
        allForm = validInput('role');
        allForm = validInput('orgId');
        allForm = validInput('username');
        allForm = validInput('firstName');
        allForm = validInput('lastName');
        if (validInput('role')) {
            if(data.role ==='parent'){
                allForm = validInput('parent_of');
            }
            if (data.role != 'student') {
                if (!editing) {
                    allForm = validInput('password');
                }
            } else {
                allForm = validInput('grade');
                allForm = validInput('classForStd')
                allForm = validInput('teacherForStd');
            }
        }
        if (allForm) {
            var booksUser = {};
            if (data.role == 'teacher' ) {
                const userbooks =  createArrayBooks(selectedBook)
                if (userbooks.length > 0) {
                    setBooksError(false);
                    booksUser = {
                        "books": userbooks,
                        "userId": "",
                        "validityDate": "2022-01-01",
                        "orgId": data.orgId
                    }
                    setLoading(true);
                    if (editing) {
                        await dispatch(handleUpdateUser(userData.id, {
                            ...userData,
                            ...data
                        }, {
                            ...booksUser
                        },
                            userData.userBookId));
                        history.push('/data-browsers')
                    } else {
                            const newUser = await dispatch(handleCreateUser({
                                auth: {
                                    username: data.username,
                                    password: data.password,
                                },
                                user: {
                                    firstName: data.firstName,
                                    lastName: data.lastName,
                                    orgId: data.role == 'admin' ? currentOrg?.id : data.orgId,
                                    role: data.role,
                                    demo: data.demo,
                                    startDate: data.startDate,
                                    endDate: data.endDate,
                                    cleverId: data.cleverId,
                                    email: data.email,
                                },
                                userBooks: booksUser
                            }));
                            if (newUser && !newUser.error) {
                                await assignClassToStudent(newUser)
                                history.push('/data-browsers')
                            } else {
                                if (newUser.errorDesctiption && newUser.errorDesctiption.code == "UsernameExistsException") {
                                    setCreateUserError(" Please, create the user again with a new username. ")
                                } else {
                                    setCreateUserError(newUser.message || "Something went wrong!")
                                }
                            }
                    }
                    setLoading(false);
                } else {
                    if (isClaver) {
                        setOpen(true)
                        setPopupMessage("Please add atleast one cources")
                    } else {
                        setBooksError(true);
                    }

                }
            }
            else if (data.role == 'student') {
                setLoading(true);
                const BooksLength = findClassBook?.books ?? ''
                const createStudent = await dispatch(handleAddStudentWithClass({
                    classId: selectedClass,
                    books: BooksLength,
                    username: get(data, "username", ''),
                    password: get(data, 'password', ''),
                    firstName: get(data, "firstName", ''),
                    lastName: get(data, "lastName", ''),
                    grade: get(data, "grade", ''),
                    orgId: get(data, "orgId", ''),
                    email: data.email,
                }))
                if (createStudent && !createStudent.error) {
                    setLoading(false);
                    history.push('/data-browsers')
                } else {
                    if (createStudent.error && createStudent.error.code == "UsernameExistsException") {
                        setCreateUserError(" Please, create the user again with a new username. ")
                    } else {
                        setCreateUserError(createStudent.message || "Something went wrong!")
                    }
                    setLoading(false);
                }
            }
            else {
                setLoading(true);
                if (editing) {
                    await dispatch(handleUpdateUser(userData.id, {
                        ...userData,
                        ...data
                    }, {
                        ...booksUser
                    },
                        userData.userBookId));
                    history.push('/data-browsers')
                } else {
                    const newUser = await dispatch(handleCreateUser({
                        auth: {
                            username: data.username,
                            password:  data.password,
                        },
                        user: {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            orgId: data.role == 'admin' ? currentOrg?.id : data.orgId,
                            role: data.role,
                            parent_of:data.parent_of
                        },
                        userBooks: booksUser
                    }));
                    if (newUser && !newUser.error) {
                        history.push('/data-browsers')
                    } else {
                        if (newUser.errorDesctiption && newUser.errorDesctiption.code == "UsernameExistsException") {
                            setCreateUserError(" Please, create the user again with a new username. ")
                        } else {
                            setCreateUserError(newUser.message || "Something went wrong!")
                        }
                    }
                }
                setLoading(false);
            }
        }
    };

    const assignClassToStudent = async (student) => {
        const studentId = get(student, "user.id", '')
        if(selectedClass)
        await dispatch(handleCreateUserClass({ userId: studentId, classId: selectedClass }))

    }
    const checkUsername = async e => {
        const nameCheck = await dispatch(handleCheckUsername(e.target.value));

        if (nameCheck) {
            setUsernameError(true)
        } else {
            setUsernameError(false)
        }
    };
    const getClassForTeacher = async (teacherId) => {
        setTeacherClassloader(true)
        setSelectedClass('')
        const teacherClassData = await dispatch(handleListTeacherClasses(teacherId))
        setTeacherClass(teacherClassData)
        setTeacherClassloader(false)
    }

    const getClassesBook = (value) => {
        const getClasses = find(teacherClass, obj => obj.id == value);
        setFindClassBook(getClasses)
    }
    const usernameValidation = (e) => {
        var validation = /^[0-9a-zA-Z]+$/;
        if (e.target.value === "" || validation.test(e.target.value)) {
            setUserError(false);
            handleChange(e);
        }
    };

    const passwordValidation = (e) => {
        if (e.target.value != "") {
            setPasswordError(false);
            setFormatPasswordError(false);
        }
        handleChange(e);
    };

    // const handleChangeMultiSelect = (event) => {
    //     setBooksError(false);
    //     setNoLicensesError(false);
    //     setBooksName(event.target.value);
    // };

    const handleChangeMultiSelect = (value) => {
        setBooksError(false);
        setNoLicensesError(false);
        // setBooksName(event.target.value);
        setSelectedBook(value)
    };

    function createArrayBooks(booksName) {
        let arrayBooks = [];
        for (var b in booksName) {
            let finded = listBooks.find(e => e.id == booksName[b]);
            let count = false;
            if (finded && finded.count != undefined) {
                count = finded.count == "true" ? true : false
            }
            if (finded) {
                arrayBooks.push({
                    bookId: finded.id,
                    description: finded.description,
                    count: count
                })
            }
        }
        return arrayBooks;
    }

    function validInput(input) {
        switch (input) {
            case 'role':
                if (data.role == undefined || data.role == null || data.role == '') {
                    setRoleError(true);
                    return false;
                } else {
                    setRoleError(false);
                    return true;
                }
            case 'orgId':
                if (data.orgId == undefined || data.orgId == null || data.orgId == '') {
                    setSchoolError(true);
                    return false;
                } else {
                    setSchoolError(false);
                    return true;
                }
            case 'username':
                if (data.username == undefined || data.username == null || data.username == '') {
                    setUserError(true);
                    return false;
                } else {
                    setUserError(false);
                    return true;
                }
            case 'password':
                if (data.password == undefined || data.password == null || data.password == '') {
                    setPasswordError(true);
                    return false;
                } else {
                    setPasswordError(false);
                    var paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
                    if (data.password.match(paswd)) {
                        setFormatPasswordError(false);
                        return true;
                    } else {
                        setFormatPasswordError(true);
                        return false;
                    }
                }
            case 'firstName':
                if (data.firstName == undefined || data.firstName == null || data.firstName == '') {
                    setFirstNameError(true);
                    return false;
                } else if (data.firstName?.length >= 50){
                    setFirstNameCharError(true);
                    return false;
                } else {
                    setFirstNameError(false);
                    setFirstNameCharError(false);
                    return true;
                }
            case 'lastName':
                if (data.lastName == undefined || data.lastName == null || data.lastName == '') {
                    setLastNameError(true);
                    return false;
                } else if (data.lastName?.length >= 50){
                    setLastNameCharError(true);
                    return false;
                } else {
                    setLastNameError(false);
                    setLastNameCharError(false);
                    return true;
                }
            case 'grade':
                if (data.grade == undefined || data.grade == null || data.grade == '') {
                    setGradeError(true);
                    return false;
                } else {
                    setGradeError(false);
                    return true;
                }
            case 'teacherForStd':
                if (data.teacherForStd == undefined || data.teacherForStd == null || data.teacherForStd == '') {
                    setTeacherStdError(true);
                    return false;
                } else {
                    setTeacherStdError(false);
                    return true;
                }
            case 'parent_of':
                if(!selectedStudentForParent){
                    setparentStdError(true)
                    return false;
                }else{
                    setparentStdError(false)
                    return true;
                }
            case 'classForStd':
                if (data.classForStd == undefined || data.classForStd == null || data.classForStd == '') {
                    setClassStdError(true);
                    return false;
                } else {
                    setClassStdError(false);
                    return true;
                }
            default:
                return false;
        }
    }
    const handleClose = () => {
        setOpen(false);
    };

    const submitValidation = () => {
        if (loading || booksError || noLicensesError || gradeError || lastNameError || lastNameCharError || firstNameError || firstNameCharError || passwordError || formatPasswordError || usernameError || parentStdError) {
            return true;
        } else if (data.role == undefined || data.role == null || data.role == '') {
            return true;
        } else if (data.orgId == undefined || data.orgId == null || data.orgId == '') {
            return true;
        } else if (data.username == undefined || data.username == null || data.username == '') {
            return true;
        } else if (data.password == undefined || data.password == null || data.password == '') {
            return true;
        } else if (data.firstName == undefined || data.firstName == null || data.firstName == '') {
            return true;
        } else if (data.lastName == undefined || data.lastName == null || data.lastName == '') {
            return true;
        } else if (data.grade == undefined || data.grade == null || data.grade == '') {
            return true;
        } else {
            return false;
        }
    }

    const disabledSubmit = submitValidation();

    const checkClassExistOrNot = useMemo(() => {
        if (data?.role === "student" && !editing) {
          return (
            !data?.orgId || data.orgId.length === 0 ||
            !data?.role || data.role.length === 0 ||
            !selectedTeacher || selectedTeacher?.trim().length === 0 ||
            !selectedClass || selectedClass?.trim().length === 0 ||
            !data?.username || data.username?.trim().length === 0 ||
            !data?.lastName || data.lastName?.trim().length === 0 ||
            !data?.firstName || data.firstName?.trim().length === 0 ||
            !data?.password || data.password?.trim().length === 0 
          );
        }
        else if (data?.role === "student" && editing) {
            return (
              !data?.lastName || data.lastName?.trim().length === 0 ||
              !data?.firstName || data.firstName?.trim().length === 0 
            );
          }
        return false;
      }, [data, selectedTeacher, selectedClass]);

    
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {popupMessage}
                </Alert>
            </Snackbar>
            {viewChangePassword && (
                <Wrapper>
                    <ChangePasswordForm
                        userData={userData}
                        loading={loading}
                        onClosed={() => {
                            hiddenChangePassword(true)
                        }}
                        onCancelled={() => {
                            hiddenChangePassword(false)
                        }}
                    />
                </Wrapper>
            )}
            {!viewChangePassword && (
                <Wrapper onSubmit={onSubmit}>
                    <Header style={{ borderColor: COLOR.black100 }}>{editing ? 'Update User' : 'Create User'}</Header>
                    <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 50 }}>
                        <Col span={12} className={"floatingLabelBG"}>
                            <FormControl variant="outlined">
                                <InputLabel id="roleLabel" style={{ borderColor: COLOR.black100 }}>Select a Role</InputLabel>
                                <Select
                                    labelId="roleLabel"
                                    id="role"
                                    onChange={e => handleSelect('role', e.target.value)}
                                    value={data?.role}

                                >
                                    {roles?.map(role => (
                                        <MenuItem key={role.value} value={role.value}>
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {roleError &&
                                    <FormHelperText error>Role required</FormHelperText>
                                }
                            </FormControl>
                        </Col>
                        {!editing && (
                            <Col span={12} className={"floatingLabelBG"}>
                                {get(data, "role") !== "owe_admin" && get(data, "role") !== "admin" && (
                                <FormControl variant="outlined">
                                    <InputLabel id="schoolLabel">{typeOrg == 'school' ? 'Select School' : 'Select Organization'}</InputLabel>
                                    <Select
                                        labelId="schoolLabel"
                                        id="orgId"
                                        onChange={e => {
                                            setSelectedClass('')
                                            setSelectedTeacher('')
                                            handleSelect('orgId', e.target.value)
                                        }}
                                        value={data?.orgId}

                                    >
                                        {schools?.map(school => (
                                            typeOrg == school.typeId &&
                                            <MenuItem key={school.id} value={school.id} >
                                                {school.displayName}
                                            </MenuItem>
                                        ))}
                                        {typeOrg == 'NoSet' &&
                                            <MenuItem key='noSet' value='noSet' >
                                                Select a User Role
                                            </MenuItem>
                                        }
                                    </Select>
                                    {schoolError &&
                                        <FormHelperText error>Organization is required</FormHelperText>
                                    }
                                </FormControl>
                                )}
                            </Col>
                        )}
                    </Row>
                    {get(data, "role") === "student" && !editing && get(data, "orgId", null) && (
                        <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 18 }}>
                            <Col span={12} className={"floatingLabelBG"}>
                                <FormControl variant="outlined">
                                    <InputLabel id="cleverTeacherLavel" style={{ borderColor: COLOR.black100 }}>Select Teacher for student </InputLabel>
                                    <Select
                                        labelId="cleverCourseLever"
                                        id="cleverCourse"
                                        onChange={e => {
                                            getClassForTeacher(e.target.value)
                                            setSelectedTeacher(e.target.value)
                                            handleSelect('teacherForStd', e.target.value)
                                        }}
                                        value={selectedTeacher}
                                    // className={"schoolForm"}
                                    >
                                        {map(teacherByOrg[data.orgId], teacherd => (
                                            <MenuItem key={teacherd.id} value={teacherd.id} >
                                                {teacherd.lastName || ''} {teacherd.firstName || ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {teacherStdError &&
                                        <FormHelperText error>Teacher required</FormHelperText>
                                    }
                                </FormControl>
                            </Col>
                            <Col span={12} className={"floatingLabelBG"}>
                                <FormControl variant="outlined">
                                    <InputLabel id="cleverTeacherLavel">Select Class for student </InputLabel>
                                    {teacherClassLoader ? <Loading /> : <Select
                                        labelId="cleverCourseLever"
                                        id="cleverCourse"
                                        onChange={e => {
                                            setSelectedClass(e.target.value);
                                            handleSelect('classForStd', e.target.value)
                                            getClassesBook(e.target.value)
                                        }}
                                        value={selectedClass}
                                    // className={"schoolForm"}
                                    >
                                        {teacherClass?.map(classd => (
                                            <MenuItem key={classd.id} value={classd.id} >
                                                {classd.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>}
                                    {classStdError &&
                                        <FormHelperText error>Class required</FormHelperText>
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                    )}
                    {!data.editing && (
                        <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 20 }}>
                            <Col span={12} className={"floatingLabelBG"}>
                                <TextField
                                    error={usernameError}
                                    id="username"
                                    onBlur={checkUsername}
                                    onChange={usernameValidation}
                                    label="User ID"
                                    variant="outlined"
                                    value={data?.username}
                                    disabled={editing}
                                    // className={"schoolForm"}
                                    style={{ borderColor: COLOR.black100 }}
                                    helperText={usernameError && 'Username not available'}
                                />
                                {userError &&
                                    <FormHelperText error>Username required</FormHelperText>
                                }
                            </Col>
                            {(!editing) && (
                                <Col span={12} className={"floatingLabelBG"}>
                                    <TextField
                                        id="password"
                                        onChange={passwordValidation}
                                        label="Password"
                                        variant="outlined"
                                        // className={"schoolForm"}
                                        style={{ borderColor: COLOR.black100 }}
                                    />
                                    {passwordError &&
                                        <FormHelperText error>Password required</FormHelperText>
                                    }
                                    {formatPasswordError &&
                                        <FormHelperText error>Use 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.</FormHelperText>
                                    }
                                </Col>
                            )}
                        </Row>
                    )}
                    <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 20 }}>
                        <Col span={data.role === 'student' ? 10 : 12} className={"floatingLabelBG"}>
                            <TextField
                                id="firstName"
                                onChange={handleChange}
                                label="First Name"
                                variant="outlined"
                                value={data?.firstName ?? ' '}
                                // className={"schoolForm"}
                                style={{ borderColor: COLOR.black100 }}
                            />
                            {firstNameError &&
                                <FormHelperText error>First name required</FormHelperText>
                            }
                            {firstNameCharError && 
                                <FormHelperText error>First name cannot exceed 50 characters.</FormHelperText>
                            }
                        </Col>
                        <Col span={data.role === 'student' ? 10 : 12} className={"floatingLabelBG"}>
                            <TextField
                                id="lastName"
                                onChange={handleChange}
                                label="Last Name"
                                variant="outlined"
                                value={data?.lastName ?? ' '}
                                // className={"schoolForm"}
                                style={{ borderColor: COLOR.black100 }}
                            />
                            {lastNameError &&
                                <FormHelperText error>Last name required</FormHelperText>
                            }
                            {lastNameCharError && 
                                <FormHelperText error>Last name cannot exceed 50 characters.</FormHelperText>
                            }
                        </Col>
                        {data.role === 'student' && (
                            <Col span={4} className={"floatingLabelBG"}>
                                <TextField
                                    id="grade"
                                    onChange={handleChange}
                                    label="Grade"
                                    variant="outlined"
                                    value={data?.grade ?? ' '}
                                    // className={"schoolForm"}
                                    style={{ borderColor: COLOR.black100 }}
                                />
                                {gradeError &&
                                    <FormHelperText error>Grade required</FormHelperText>
                                }
                            </Col>
                        )}
                    </Row>
                    {showDemo && <Row justify="start" style={{ marginBottom: 10, marginLeft: 5, marginTop: 20 }} className={"floatingLabelBG"}>
                        <FormControlLabel
                            classes={{
                                root: classes.rootDif,
                                label: classes.label,
                            }}
                            control={
                                <Switch
                                    classes={{
                                        sizeSmall: classes.sizeSmall,
                                        checked: classes.checkedSwitch
                                    }}
                                    className={"schoolForm"}
                                    checked={isDemo}
                                    id="demo"
                                    onChange={e => {
                                        setDemo(e.target.checked)
                                        let event = { target: { id: "demo", value: e.target.checked } }
                                        handleChange(event)
                                    }}
                                    name="demo"
                                    color="primary"
                                    size="small"
                                />
                            }
                            // className={"schoolForm"}
                            label="Demo Account"
                        />
                    </Row>}
                     {/* if user is parent */}
                     {get(data, "role") === "parent"  && get(data, "orgId", null) && Boolean(studentByOrg) && (
                        <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 18 }}>
                            <Col span={12} className={"floatingLabelBG"}>
                                <FormControl variant="outlined">
                                    <InputLabel id="cleverTeacherLavel" style={{ borderColor: COLOR.black100 }}>Select Student for parent </InputLabel>
                                    <Select
                                        labelId="cleverCourseLever"
                                        id="parent_of"
                                        disabled={editing}
                                        onChange={e => {
                                            handleSelect('parent_of', e.target.value)
                                            setselectedStudentForParent(e.target.value)
                                        }}
                                        value={selectedStudentForParent}
                                    >
                                        {map(studentByOrg[data.orgId], student => (
                                            <MenuItem key={student.id} value={student.id} >
                                                {student.lastName || ''} {student.firstName || ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {parentStdError &&
                                        <FormHelperText error>Student required</FormHelperText>
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                    )}
                    {get(data, "role") === "teacher" || get(data, "role") === "student" ? <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 20 }}>
                        <Col span={12} className={"floatingLabelBG"}>
                            <TextField
                                id="email"
                                onChange={handleChange}
                                label="email"
                                variant="outlined"
                                value={data?.email ?? ' '}
                                type="email"
                                // className={"schoolForm"}
                                style={{ borderColor: COLOR.black100 }}
                            />
                        </Col>
                    </Row> : null}
                    <Row gutter={[16, 24]} style={{ marginTop: 20 }}>
                        <Col span={6}>
                            <DatePicker
                                id="startDate"
                                label="Start Date"
                                inputVariant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                format="MM-dd-yyyy"
                                // minDate={getStartMintime(get(data, "startDate"))}
                                initialFocusedDate={moment().toISOString()}
                                defaultValue={moment()}
                                // className={"schoolForm"}
                                value={get(data, "startDate")}
                                onChange={e => {
                                    handleUpdateData('startDate', e.toISOString())
                                }} />
                        </Col>
                        <Col span={6}>
                            <DatePicker
                                id="endDate"
                                label="End Date"
                                inputVariant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                format="MM-dd-yyyy"
                                // className={"schoolForm"}
                                minDate={getStartMintime(get(data, "endDate"))}
                                initialFocusedDate={moment().toISOString()}
                                defaultValue={null}
                                value={get(data, "endDate")}
                                onChange={e => {
                                    handleUpdateData('endDate', e.toISOString())
                                }} />
                        </Col>

                    </Row>
                    {isClaver && <Row style={{ marginTop: 20 }}>
                        <Col span={6} className={"floatingLabelBG"}>
                            <TextField
                                disabled={editing}
                                id="cleverId"
                                label="Clever Id"
                                variant="outlined"
                                value={get(data, "cleverId", '')}
                                className={"districForm"}
                                onChange={handleChange}
                                style={{ borderColor: COLOR.black100 }}
                            />
                        </Col>
                    </Row>}

                    {get(data, "role") === "teacher" && isClaver && !editing && (<Row style={{ marginTop: 12 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            onClick={(e) => {
                                e.preventDefault()
                                let uc = _.cloneDeep(userClasses)
                                uc.push({})
                                setuserClasses(uc)
                            }} style={{ marginRight: '8px', width: 200, textTransform: 'inherit' }}>
                            Add Cources
                        </Button>
                    </Row>)}

                  
                    {get(data, "role") === "teacher" && isClaver && !editing && (userClasses.map((selectedCourse, i) => (<Row style={{ marginTop: 10 }}><Col span={12} style={{ paddingRight: 10 }}>
                        <FormControl variant="outlined">
                            <InputLabel id="cleverCourseLever"
                            // className={"schoolForm"}
                            >Select Cources for clever</InputLabel>
                            <Select
                                labelId="cleverCourseLever"
                                id="cleverCourse"
                                onChange={e => {
                                    const crs = find(cleverCources, ["id", e.target.value])
                                    let uc = _.cloneDeep(userClasses)
                                    uc[i] = { ...uc[i], ...crs };

                                    setuserClasses(uc)
                                }}
                                value={get(selectedCourse, 'id', '')}
                            // className={"schoolForm"}
                            >
                                <MenuItem key={"selectcr"} value={''} >
                                    {"Select cources"}
                                </MenuItem>
                                {cleverCources.map(cources => (
                                    <MenuItem key={cources.id} value={cources.id} >
                                        {cources.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                        <Col span={6} style={{ paddingLeft: 10 }} className={"floatingLabelBG"}>
                            <TextField
                                id="cleverId"
                                label="Clever Id"
                                variant="outlined"
                                value={get(selectedCourse, 'cleverId', '')}
                                className={"districForm"}
                                onChange={(e) => {
                                    let uc = _.cloneDeep(userClasses)
                                    uc[i] = { ...uc[i], cleverId: e.target.value };
                                    setuserClasses(uc)
                                }}
                            />
                        </Col>
                        <Col span={6} style={{ paddingLeft: 10 }}>
                            <Button variant="contained" color="secondary"
                                // className={"schoolForm"}
                                onClick={(e) => {
                                    let uc = _.cloneDeep(userClasses)
                                    uc.splice(i, 1)
                                    setuserClasses(uc)

                                }}
                                style={{ marginRight: '8px', width: 100, textTransform: 'inherit' }}>
                                remove
                            </Button>
                        </Col>
                    </Row>))
                    )}


                    {/* {!isClaver && showBooks && get(data, "role") !== "student" &&
                        <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 12 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="bookList-label"
                                // className={"schoolForm"}
                                >Books by user</InputLabel>
                                <Select
                                    labelId="bookList-label"
                                    id="bookList-mutiple-chip"
                                    multiple
                                    variant="outlined"
                                    value={booksName}
                                    onChange={handleChangeMultiSelect}
                                    input={<Input id="select-multiple-chip" />}
                                    // className={"schoolForm"}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={classes.chip} />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {assignableBook && assignableBook.map((list) => (
                                        <MenuItem key={list.id} value={list.description} style={getStyles(list.description, booksName, theme)}>
                                            {list.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {booksError &&
                                    <FormHelperText error>Choose at least one book</FormHelperText>
                                }
                            </FormControl>
                        </Row>
                    } */}
                    {!isClaver && showBooks &&   get(data, "role") == "teacher"  &&
                        <><Row gutter={[24, 24]} style={{ width: '100%', marginTop: 12 }}>
                            <InputLabel id="bookList-label" className={"schoolForm"}>Books by user</InputLabel>
                            <FormControl className={classes.formControl}>
                                <BookSection>
                                    <SelectableBook chapter={assignableBook} selected={selectedBook ? selectedBook : []} preSelected={[]} onSelect={(item) => {
                                        handleChangeMultiSelect(item)
                                    }} />
                            </BookSection>
                            {!editing && (<>
                                {booksError &&
                                    <FormHelperText error>Choose at least one book</FormHelperText>
                                }
                            </>)}
                                {/* {noLicensesError &&
                                    <FormHelperText error>The selected school does not have enough licenses available</FormHelperText>
                                }
                                {!!createUserError &&
                                    <FormHelperText error>{createUserError}</FormHelperText>
                                } */}
                            </FormControl>
                        </Row>
                        </>
                    }
                    {noLicensesError &&
                        <FormHelperText error>The selected school does not have enough licenses available</FormHelperText>
                    }
                    {!!createUserError &&
                        <FormHelperText error>{createUserError}</FormHelperText>
                    }
                    <Row gutter={[12, 12]} style={{ width: '100%', marginTop: 40 }}>
                        <Col span={4}>
                            <Button
                                disabled={loading || booksError || noLicensesError || gradeError || lastNameError || lastNameCharError || firstNameError || firstNameCharError || passwordError || formatPasswordError || usernameError || parentStdError || checkClassExistOrNot}
                                variant="contained" color="primary" style={{ marginRight: '8px', width: '100%' }} type='submit'>
                                {loading ? <Loading size={20} white /> : (editing ? 'Update' : 'Create')}
                            </Button>
                        </Col>
                        {(editing) && (
                            <>
                                <Col span={4}>
                                    <Button variant="contained" color="default" disabled={loading} onClick={() => { showChangePassword() }} style={{ marginRight: '8px', width: '100%', textTransform: 'inherit' }}>
                                        Change password
                                    </Button>
                                </Col>
                            </>
                        )}
                        {!onlyAdd && (
                            <>
                                {(editing) ? (
                                    <Col span={12}></Col>
                                ) : (
                                    <Col span={16}></Col>
                                )}

                                <Col span={4}>
                                    <Button variant="contained" color="secondary"
                                        // className={"schoolForm"}
                                        onClick={() => history.push('/data-browsers')} style={{ marginRight: '8px', width: '100%', textTransform: 'inherit' }}>
                                        Cancel
                                    </Button>
                                </Col>
                            </>
                        )}
                    </Row>
                </Wrapper>
            )}
        </>
    );
};

//  -- Prop types ---------------------
UserForm.propTypes = {};

//  -- Default props ------------------
UserForm.defaultProps = {};
const mapStateToProps = (state) => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(UserForm);
