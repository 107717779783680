import { useState, useEffect, useRef , useLayoutEffect } from "react";
import {debounce} from 'lodash';

export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}



export function useComponentVisibleHook(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return [ref, isComponentVisible, setIsComponentVisible]
}


// export const useIsMobile = () => {
//   const [isMobile, setIsMobile] = useState(false);
//   const checkMobileDevice = () => {
//     const isIOS = [
//       'iPad Simulator',
//       'iPhone Simulator',
//       'iPod Simulator',
//       'iPad',
//       'iPhone',
//       'iPod'
//     ].includes(navigator.platform)
//       // iPad on iOS 13 detection
//       || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

//     const isAndroidMobile = /Android/i.test(navigator.userAgent) && /Mobile/i.test(navigator.userAgent);
//     const isAndroidTablet = /Android/i.test(navigator.userAgent) && !/Mobile/i.test(navigator.userAgent);

//     return {
//       isIOS,
//       isAndroidMobile,
//       isAndroidTablet
//     };
//   };
//   useLayoutEffect(() => {
//     const updateSize = () => {
//       const winSize = window.innerWidth < 1367;
//       setIsMobile(winSize || checkMobileDevice.isIOS || checkMobileDevice.isAndroidMobile || checkMobileDevice.isAndroidTablet);
//     };
//     window.addEventListener('resize', debounce(updateSize, 250));
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);

//   return isMobile;
// };

// export default useIsMobile;



// const useIsMobile = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   const checkMobileDevice = () => {
//     const isIOS = [
//       'iPad Simulator',
//       'iPhone Simulator',
//       'iPod Simulator',
//       'iPad',
//       'iPhone',
//       'iPod'
//     ].includes(navigator.platform)
//       // iPad on iOS 13 detection
//       || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

//     const isAndroidMobile = /Android/i.test(navigator.userAgent) && /Mobile/i.test(navigator.userAgent);
//     const isAndroidTablet = /Android/i.test(navigator.userAgent) && !/Mobile/i.test(navigator.userAgent);

//     return {
//       isIOS,
//       isAndroidMobile,
//       isAndroidTablet
//     };
//   };

//   useLayoutEffect(() => {
//     const updateSize = () => {
//       const winSize = window.innerWidth < 1367;
//       setIsMobile(winSize || checkMobileDevice().isIOS || checkMobileDevice().isAndroidMobile || checkMobileDevice().isAndroidTablet);
//     };
//     // Execute updateSize once immediately after the component mounts
//     updateSize();
//     window.addEventListener('resize', debounce(updateSize, 250));
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);

//   return isMobile;
// };

// export default useIsMobile;


const useMobileDeviceDetection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document);

      const isAndroidMobile = /Android/i.test(navigator.userAgent) && /Mobile/i.test(navigator.userAgent);
      const isAndroidTablet = /Android/i.test(navigator.userAgent) && !/Mobile/i.test(navigator.userAgent);

      setIsMobile(isIOS || isAndroidMobile || isAndroidTablet);
    };

    handleResize(); // Call initially to set the correct state

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export const useTabletDetection = () => {
  const [isTablet, setIsTablet] = useState(false);
  useEffect(() => {
    const handleDeviceDetection = () => {
      if (768 <= window.innerWidth && window.innerWidth <= 1280) {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|playbook|silk|pad|tablet)/g.test(userAgent) && !/(android|iphone|ipod)/g.test(userAgent) || userAgent.includes("macintosh");
        setIsTablet(isTablet) ;
      } else {
        setIsTablet(false);
      }
    };
    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);
    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, []);
  return isTablet;
};

export const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobileDetect = Boolean(
      userAgent.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile|mobile|CriOS/i
      )
    );

    setIsMobile(mobileDetect);
  }, []);

  return isMobile;
};
export default useMobileDeviceDetection;

export function useOutsideClick(callback) {
  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
}
