//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import { array, number, oneOfType, string } from 'prop-types';
import styled from 'styled-components';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
    align-items: center;
    background-color: ${p=>p.bgColor?p.bgColor:COLOR.green200};
    border-radius: 3px;
    color: ${COLOR.white};
    display: inline-flex;
    fill: ${COLOR.white};
    flex-direction: row;
    fill: ${COLOR.white};
    font-size: ${p => `${p.fontSize}px`};
    font-weight: 600;
    line-height: 15px;
    margin-right: 4px;
    padding: 3px 7px 2px 5px;
    text-transform: uppercase;
    height: ${p => p.height?`${p.height}px`:"auto"};
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Tag
//
//  -- Description
//  Tag component for helping draw attention or displaying something that is
//  important
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Tag>
//      children
//  </Tag>
//
const Tag = ({ children, fontSize , height  , bgColor  }) => (
    <Wrapper fontSize={fontSize} height={height} bgColor={bgColor} >
        {children}
    </Wrapper>
);

//  -- Prop types ---------------------
Tag.propTypes = {
    children: oneOfType([
        array,
        string,
    ]),
    fontSize: number,
};

//  -- Default props ------------------
Tag.defaultProps = {
    fontSize: 10
};

export default Tag;