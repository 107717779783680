import { Button, FormHelperText, FormLabel, IconButton, Modal, TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Close, Edit } from "@material-ui/icons";
import { Col, Form, Row, Switch } from 'antd';
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import VideoPicker from "components/AdminBooks/VideoPicker";
import Loading from 'components/Loading';
import { filter, find, get, has, map } from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFunVideos } from 'store/resources/selectors';
import { handleListVideos, handleUpdateVideos } from "store/resources/thunks";
import styled from 'styled-components';
import { DUMMY_POSTER, videoLink } from "utils/assets/images";
const style = {};
const TopRow = styled(Row)`
    border: 0.5px solid #3c393957;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    justify-content:space-between;
`;

const ModalContent = styled.div`
    left: 50%;
    width:70vw;
    padding: 0px 24px;
    position: absolute;
    min-height:350px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color:#fff;
    padding:20px;
     @media only screen and (max-width: 600px) {
        width: 90%;
    }
`;
const ModalConfigArea = styled.div`
    min-height:75vh;
    max-height:90vh;
    overflow:auto;
    margin-top:10px;
`;
const CoverImage = styled.div`
    background-image: url(${p=>p.src?`${p.src}`:DUMMY_POSTER});
    height: 100%;
    width: 100%;
    box-shadow: rgb(0 0 0) 0px 3px 9px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
`;

const EditVideo = ({ editResourseModal, onCloseModal, item }) => {
    const [resourceVideo, setResourcesVideo] = useState({})
    const [poster, setPoster] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [src, setSrc] = useState('')
    const [loading, setloading] = useState(false)
    const [error, setError] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        if (item) {
            setResourcesVideo(item)
            setPoster(get(item, "poster", ''))
            setDisplayName(get(item, "displayName", ''))
            setSrc(get(item, "src", ''))
            setError([])
        }
    }, [item])
    const onCreateOrUpdate = async () => {
        let err = []
        if (!src) {
            err.push({
                key: "src", message: 'Video required!'
            })
        }
        if (!poster) {
            err.push({
                key: "poster", message: 'Poster image required!'
            })
        }
        if (!displayName) {
            err.push({
                key: "displayName", message: 'Title  required!'
            })
        }
        setError(err)
        if (err.length == 0) {
            setloading(true)
            if (has(item, "id")) {
                if (!item.src) {
                    item.metadata.active = true;
                }
                const data = {
                    ...item,
                    poster,
                    src,
                    displayName
                }
                await dispatch(handleUpdateVideos(data))
            }
            setloading(false)
            onCloseModal()
        }
    }
    return (<Modal
        open={editResourseModal}
        onClose={() => { }}
        style={{ height: '100%', width: '100%' }}
    >
        <ModalContent>
            <Row style={{ justifyContent: "space-between" }}>
                <TitleText>{has(item, "id") ? 'Update Video' : 'Add Video'}</TitleText>
                <IconButton onClick={onCloseModal} style={{ padding: 0 }}>
                    <Close color="secondary" />
                </IconButton>
            </Row>
            <ModalConfigArea>
                <Row>
                    <Col lg={12} xs={24}>
                        <FormLabel style={{color:'black'}} component="legend">Video</FormLabel>
                        <VideoPicker
                            src={src}
                            onChange={(item) => { 
                                setSrc(item.url) 
                                setError(filter(error,e=>e.key !="src"))
                             }}
                        />
                    </Col>
                    <Col lg={12} xs={24} style={{ paddingLeft: 10, paddingTop: 10 }}>
                        <FormLabel style={{ color: 'black' }} component="legend">Poster for videos:</FormLabel>
                        <PageImagePicker
                            src={poster}
                            path={`/poster}`}
                            style={{ height: 270, marginBottom: 24 }}
                            onChange={item => { 
                                setPoster(item.url)
                                setError(filter(error,e=>e.key !="poster"))
                             }}
                            hasDescription={false}
                        />
                        {has(item, "id")?<Row justify="start" style={{ marginBottom: 10, }}>
                            <TextField
                                id="id"
                                name="id"
                                label="Id"
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                disabled
                                variant="outlined"
                                value={get(resourceVideo, "id")}
                            />
                        </Row>:null}
                        <Row justify="start" style={{ marginBottom: 10, }}>
                            <TextField
                                id="day"
                                name="day"
                                label="Day"
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                disabled
                                variant="outlined"
                                value={get(resourceVideo, "metadata.day")}
                            />
                        </Row>
                        <Row justify="start" style={{ marginBottom: 30, }}>
                            <TextField
                                id="displayName"
                                name="displayName"
                                error={find(error, ['key', 'displayName'])}
                                label="Title"
                                InputLabelProps={{ shrink: true, required: true }}
                                required
                                type="text"
                                variant="outlined"
                                onChange={e => {
                                    setDisplayName(e.target.value)
                                    setError(filter(error,e=>e.key !="displayName"))
                                }}
                                value={displayName}
                            />
                        </Row>
                        {error.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                            {error.map(v => (<FormHelperText key={v.key} error>
                                * {get(v, "message")}
                            </FormHelperText>))}
                        </Row> : null}
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                            <Button disabled={loading} fullWidth onClick={onCreateOrUpdate} variant="contained" color="primary">
                                {loading ? <Loading white size={15} /> : null}
                                {has(item, "id") ? "Update" : "Create"} </Button>
                        </Row>
                    </Col>
                </Row>
            </ModalConfigArea>
        </ModalContent>
    </Modal>)
}

const ActivateDeactive = ({ item , dispatch }) => {
    const [switch_active, setSwitchActive] = useState(false);
    useEffect(() => {
        if (item && item.metadata) {
            setSwitchActive(item.metadata.active)
        }
    }, [item]);
    const onCreateOrUpdate = async (state) => {
        setSwitchActive(state)
        if (has(item, "id")) {
            const data = item;
            data.metadata.active = state;
            await dispatch(handleUpdateVideos(data))
        }
    }
    return (<Form style={{ height: 30 }}>
        <Form.Item label="Deactivate / Activate" valuePropName="checked" >
            <Switch checked={switch_active} onChange={onCreateOrUpdate} />
        </Form.Item>
    </Form>)
}
const ClosableMenu = ({ item, dispatch }) => {
    const [switch_active, setSwitchActive] = useState(false);
    useEffect(() => {
        if (item && item.metadata) {
            setSwitchActive(item.metadata.closable)
        }
    }, [item]);
    const onCreateOrUpdate = async (state) => {
        setSwitchActive(state)
        if (has(item, "id")) {
            const data = item;
            data.metadata.closable = state;
            await dispatch(handleUpdateVideos(data))
        }
    }
    return (<Form style={{ height: 30 }}>
        <Form.Item label="Watch Later" valuePropName="checked" >
            <Switch checked={switch_active} onChange={onCreateOrUpdate} />
        </Form.Item>
    </Form>)
}

export default function FunVideos({role}) {
    const funvideos = useFunVideos(role)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [videoModalInfo, setVideoModalInfo] = React.useState({
        open: false,
        item: null
    });
  
    useEffect(() => {
        if (funvideos?.length == 0) {
            dispatch(handleListVideos())
        }
    }, [])

    const handleEdit = (item) => {
        setVideoModalInfo({open: true, item: item})
    }
 
    return (
        <>
            <EditVideo
                editResourseModal={videoModalInfo.open}
                item={videoModalInfo.item}
                onCloseModal={() => setVideoModalInfo({ open: false, item: null })}
            />
            <Row className="learnContentUpr" gutter={[25, 0]}>
                <Col xs={24} sm={24} md={24}>
                    <div className="learnContent">
                        <Row gutter={[25, 0]} className="learnDetail">
                            {map(funvideos, item => (<Col key={item.id} xs={12} sm={12} md={8} className="cstmLrnRow">
                                <TopRow>
                                   <ClosableMenu item={item} dispatch={dispatch} />
                                   <ActivateDeactive item={item} dispatch={dispatch} />
                                </TopRow>
                                <div className="ShowDetailBnr card-resource" style={{ cursor: "pointer", maxWidth:"100%" , height:260, overflow:"hidden"}} onClick={() => handleEdit(item)}>
                                    <div className="card-resource-overlay"></div>
                                    <CoverImage src={item.poster} />
                                    <Link className="ShowDetail"><img src={videoLink} /></Link>
                                    <div className="card-resource-details fadeIn-bottom">
                                        <h3 className="card-resource-title">{item.displayName} <Edit /> </h3>
                                    </div>
                                </div>
                                <div className="learnCntLink"><a>{get(item,"metadata.day",'')}</a></div>
                            </Col>))}
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    );
}
