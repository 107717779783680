import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import Loading from "components/Loading";
import { map } from "lodash";
import { US_STATE } from "utils/config/reference";
import Baseball from "utils/assets/images/Baseball.gif"
import Dance from "utils/assets/images/Dance.gif"
import Girlsoccer from "utils/assets/images/Girlsoccer.gif"
import LetterB from "utils/assets/images/LetterB.gif"
import InitialLoading from "components/InitialLoading";


const timeMenuItems = [
  { value: 25, label: "25 min" },
  { value: 35, label: "35 min" },
  { value: 45, label: "45 min" },
  { value: 55, label: "55 min" },
  { value: 70, label: "1 hour and 10 min" },
  { value: 80, label: "1 hour and 20 min" },
];

const standardSelection = [
  {
    value: 10,
    label:
      "American Council on the Teaching of Foreign Languages (ACTFL) Standards",
  },
  { value: 20, label: "California World Language Standards" },
  { value: 30, label: "Colorado Academic Standards for World Languages" },
  { value: 40, label: "Common Core State Standards (Spanish)" },
  { value: 50, label: "Common Core State Standards" },
  {
    value: 60,
    label: "Georgia Standards of Excellence for Modern Languages",
  },
  { value: 70, label: "Illinois Learning Standards for World Languages" },
  { value: 80, label: "Maryland World Language Standards" },
  {
    value: 90,
    label: "New Mexico World-Readiness Standards for Learning Languages",
  },
  {
    value: 100,
    label:
      "New York State Learning Standards for Languages Other Than English",
  },
  { value: 110, label: "Oregon World Language Standards" },
  { value: 120, label: "TEKS ELAR" },
  { value: 130, label: "TEKS SLAR" },
  {
    value: 140,
    label: "Washington State K-12 World Languages Learning Standards",
  },
  { value: 150, label: "WIDA Standards" },
];

const activitiesSelection = [
  {
    value: 10,
    label:
      "Interactive activities",
  },
  { value: 20, label: "Group Activities" },
  { value: 30, label: "Reading" },
  { value: 40, label: "Research" },
  { value: 50, label: "Creation of mental maps" },
  {
    value: 60,
    label: "Art Project",
  }
];

const gradeLevelData = [
  { value: 6, label: "6th" },
  { value: 7, label: "7th" },
  { value: 8, label: "8th" },
  { value: 9, label: "9th" },
  { value: 10, label: "10th" },
];

const messages = [
  { text: "We are loading the magic now.", image: Baseball },
  { text: "The magic takes some time.", image: Dance },
  { text: "It is now happening!", image: Girlsoccer },
  { text: "Ok, you ready! Welcome.", image: LetterB },
];

const useStyles = makeStyles({
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent !important",
    },
    menuItemText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "150px",
      display: "block",
    },
    disabledButton: {
      color: 'white',
      '&.Mui-disabled': {
        color: 'white !important',
      },

    },
  },
});
const CreateLessonModal = ({ open, onClose, loader, onCreate = async () => { } }) => {
  const classes = useStyles();
  const [gradeLevel, setGradeLevel] = useState("");
  const [state, setState] = useState("");
  const [timeFrame, setTimeFrame] = useState("");
  const [days, setDays] = useState("");
  const [standard, setStandard] = useState("");
  const [activities, setActivites] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [openDropDown, setOpenDropDown] = useState({
    gradeLevel: false,
    state: false, 
    timeFrame: false,
    days: false,
    standard: false,
    activities: false,
  });

  const updateState = (name, value) => {
    setOpenDropDown(prev => ({...prev,  [name]: value }));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex(prevIndex => (prevIndex + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleGradeLevelChange = (event) => {
    setGradeLevel(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  const handleDaysChange = (event) => {
    setDays(event.target.value);
  };

  const handleStandardChange = (event) => {
    setStandard(event.target.value);
  };

  const handleActivitiesChange = (event) => {
    setActivites(event.target.value);
  };

  const clearAllState = () => {
    setGradeLevel("");
    setState("");
    setTimeFrame("");
    setDays("");
    setStandard("");
    setActivites([]);
  }

  const handleCreateLesson = async () => {
    const payload = {
      days,
      state,
      standard,
      activities: activities.join(', '),
      grade_level: gradeLevel,
      time_per_lesson: timeFrame
    }
    await onCreate(payload);
    clearAllState();
  };

  const handleMenuOpen = (value) => {
    updateState(value, true);
  };

  const handleMenuClose = (value) => {
    updateState(value, false);
  };

  const handleScroll = () => {
    const openKeys = Object.keys(openDropDown).filter(key => openDropDown[key]);
    if (openKeys.length) {
      openKeys.forEach(key => handleMenuClose(key));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [openDropDown]);

  const renderSelectedValues = (selected) => {
    if (selected.length === 0) {
      return <span>Select</span>;
    }

    // Display up to 3 selected items directly, others in a tooltip
    const displayedItems = selected.slice(0, 1).map((value) => (
      <span key={value} className={classes.menuItemText}>
        {value}
      </span>
    ));

    const tooltipItems = selected.slice(1).map((value) => value);

    return (
      <Tooltip title={tooltipItems.join(', ')} placement="top">
        <div>
          {displayedItems}
          {selected.length > 1 && (
            <span className={classes.menuItemText}>...</span>
          )}
        </div>
      </Tooltip>
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  

  return (
    <>
      {open && (
        <div className="superLessionModal">
          <div className={`createMyLession create-lesson-modal ${loader ? 'createMyLessonLoader' : ''}`}>
            {loader ? (
              <div className="super-lesson-waiting">
                {/* <img src={messages[currentMessageIndex].image} alt="placeholder" height='300px' /> */}
                <InitialLoading mounted={true} transparent={true} />
                <span className="super-lesson-waiting-txt">
                  {messages[currentMessageIndex].text}
                </span>
              </div>
            ) : (
              <>
                <span onClick={onClose}>
                  <CancelIcon
                    style={{
                      position: "absolute",
                      right: -7,
                      top: -7,
                      fontSize: 18,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                </span>
                <div className="allCreateMyLession">
                  <label>Grade level:</label>
                  <FormControl fullWidth>
                    <Select
                      className={classes.select}
                      value={gradeLevel}
                      onChange={handleGradeLevelChange}
                      displayEmpty
                      open={openDropDown?.gradeLevel}
                      onOpen={() => handleMenuOpen("gradeLevel")}
                      onClose={() => handleMenuClose("gradeLevel")}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true 
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <span>Select</span>;
                        }
                        return selected
                      }}
                    >
                      {gradeLevelData.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="allCreateMyLession">
                  <label>State:</label>
                  <FormControl fullWidth>
                    <Select
                      className={classes.select}
                      value={state}
                      onChange={handleStateChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      open={openDropDown?.state}
                      onOpen={() => handleMenuOpen("state")}
                      onClose={() => handleMenuClose("state")}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true
                      
                      }}
                    >
                      <MenuItem value="" hidden>Select</MenuItem>
                      {map(US_STATE, tz => (
                        <MenuItem key={tz.code} value={tz.name}>
                          {tz.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="allCreateMyLession">
                  <label>Time frame per lesson:</label>
                  <FormControl fullWidth>
                    <Select
                      className={classes.select}
                      value={timeFrame}
                      onChange={handleTimeFrameChange}
                      open={openDropDown?.timeFrame}
                      onOpen={() => handleMenuOpen("timeFrame")}
                      onClose={() => handleMenuClose("timeFrame")}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true 
                      }}
                      renderValue={(selected)=>{
                        if (!selected) {
                          return <span>Select</span>;
                        }
                        return selected;
                      }}
                    >
                      {map(timeMenuItems, (item, index) => (
                        <MenuItem key={index} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="allCreateMyLession">
                  <label>Days:</label>
                  <FormControl fullWidth>
                    <Select
                      className={classes.select}
                      value={days}
                      onChange={handleDaysChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      open={openDropDown?.days}
                      onOpen={() => handleMenuOpen("days")}
                      onClose={() => handleMenuClose("days")}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true 
                      }}
                    >
                      <MenuItem value="" hidden> Select</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      {/* <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
                <div className="allCreateMyLession">
                  <label>Standard Selection:</label>
                  <FormControl fullWidth>
                    <Select
                      className={classes.select}
                      value={standard}
                      onChange={handleStandardChange}
                      displayEmpty
                      open={openDropDown?.standard}
                      onOpen={() => handleMenuOpen("standard")}
                      onClose={() => handleMenuClose("standard")}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true 
                      }}
                      renderValue={(selected) => {
                         if (!selected) {
                          return <span>Select</span>;
                        }
                        const selectedItem = standardSelection.find(
                          (item) => item.label === selected
                        );
                        return (
                          <Tooltip
                            title={selectedItem ? selectedItem.label : ""}
                            placement="top"
                          >
                            <span className={classes.menuItemText}>
                              {selectedItem
                                ? selectedItem.label?.length > 10
                                  ? selectedItem?.label.slice(0, 10) + "..."
                                  : selectedItem?.label
                                : ""}
                            </span>
                          </Tooltip>
                        );
                      }}
                    >
                      {standardSelection.map((item, index) => (
                        <MenuItem key={index} value={item?.label}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="allCreateMyLession">
                  <label>Activities:</label>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      className={classes.select}
                      value={activities}
                      onChange={handleActivitiesChange}
                      displayEmpty
                      open={openDropDown?.activities}
                      onOpen={() => handleMenuOpen("activities")}
                      onClose={() => handleMenuClose("activities")}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                        getContentAnchorEl: null,
                        disableScrollLock: true 
                      }}
                      renderValue={() => renderSelectedValues(activities)}
                    >
                                  

                      {activitiesSelection.map((item, index) => (
                        <MenuItem key={index} value={item?.label}>
                        <Checkbox checked={activities.indexOf(item?.label) > -1} />
                        <ListItemText primary={item?.label} />
                        </MenuItem>

                        // <MenuItem key={index} value={item?.label}>
                        //   {item?.label}
                        // </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="myLessionBtn create-lesson-button">
                  <Button
                    style={{ opacity: loader || !(gradeLevel && state && standard && timeFrame && days && activities?.length > 0) ? 0.6 : 1 }}
                    onClick={handleCreateLesson} disabled={loader || !(gradeLevel && state && standard && timeFrame && days && activities?.length > 0)}>{loader && <Loading size={17} />} Create my lesson</Button>
                </div>
                <div className="createdTopicContnt">
                  <span>
                    Note: The lesson will be created using the topic and the content
                    covered in the lesson that includes this page.
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CreateLessonModal;
