import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  makeStyles,
  withStyles
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Add, Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import ShareIcon from "@material-ui/icons/Share";
import { Row, Row as Rows } from "antd";
import AnimatedCheckMark from "components/AnimatedCheckMark";
import { chunk, cloneDeep, filter, find, get, has, isEmpty, map, shuffle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useUsersByClass } from "store/userClasses/selectors";
import { useCurrentUser } from "store/users/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import { WriteEditWhite } from "utils/assets/images";
import COLOR from "utils/constants/color";
import { useUserWidgetsConfig } from "utils/methods/firebaseUserWidgets";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { v4 } from "uuid";
import { useFirebase } from "containers/FirebaseContext";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { handleListClassUsers } from "store/userClasses/thunks";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const WidgetGroupModal = ({ sharableWidget, currentClass }) => {
  const timeoutRef = useRef();
  const [isStudentGenerated, setStudentGenerated] = useState(false);
  const usersByClass = useUsersByClass();
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const { firestore } = useFirebase();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets } = useCollectionWidgets();
  const { createUserWidgetsConfig } = useUserWidgetsConfig();
  const isTeacher = get(currentUser, "role") === "teacher";
  const [studentGroup, setStudentGroup] = useState([]);
  const [usersList, setUsers] = useState([]);
  const [originalStationUser, setOriginalStationUser] = useState([]);
  const [studentMinimized, setStudentMinimized] = useState(false);
  const [numberOfGroups, setNumberOfGroups] = useState(0);
  const [shareGroupData, setShareGroupData] = useState([]);
  const [studentOfCurrentClass, setStudentOfCurrentClass] = useState(
    usersByClass[currentClass.id]
  );

  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "new_group"
  );
  const filterShareGroup = find(
    sharableWidget,
    (obj) => obj?.widget_type === "new_group"
  );
  const [dimensions, setDimensions] = useState({});
  const widgetStationOpen =
    get(currentUser, "role") === "teacher"
      ? filterByWidget?.view && filterByWidget?.menu === "new_group"
      : filterShareGroup?.id && !studentMinimized;

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (widgetStationOpen) {
      document.body.classList.add("body-overflow-hidden");
    }

    return () => {
      document.body.classList.remove("body-overflow-hidden");
    };
  }, [widgetStationOpen]);

  useEffect(() => {
    if (usersByClass && has(currentClass, "id")) {
      const usr = map(
        usersByClass[currentClass.id],
        (k) => `${k.firstName || ""} ${k.lastName || ""}`
      );
      setUsers(usr);
      setOriginalStationUser(usr);
    }
  }, [usersByClass, currentClass]);

  useEffect(() => {
    const unsubscribe = listenToWidgetUserConfig(get(currentClass, 'id', ''));
    return () => {
      unsubscribe();
    };
  }, [firestore, currentClass, currentUser, filterByWidget])

  const listenToWidgetUserConfig = (classID) => {
    if (!classID || !currentUser || filterByWidget?.menu !== "new_group") {
      return () => { };
    }
    const fifteenHoursAgo = new Date();
    fifteenHoursAgo.setHours(fifteenHoursAgo.getHours() - 12);
    const collectionRef = collection(firestore, `widgets_user_config/${classID}/widgets`);
    const q = query(collectionRef,
      where("id", "==", 'new_group'),
      where("modified_on", ">=", fifteenHoursAgo)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let sharableData = null;
      querySnapshot.forEach((doc) => {
        sharableData = { id: doc.id, ...doc.data() };
      });
      if (sharableData) {
        const selectedSet = new Set(sharableData?.user_data);
        const filteredShareData = usersByClass[currentClass.id]?.filter((student) => {
          const fullName = `${student.firstName} ${student.lastName}`;
          return selectedSet.has(fullName);
        });

        const filteredOriginalData = createStudentPayload(sharableData?.user_data);
        setStudentOfCurrentClass([...filteredShareData, ...filteredOriginalData]);
        setShareGroupData(sharableData?.user_data);
      } else {
        setShareGroupData([])
        setStudentOfCurrentClass(usersByClass[currentClass.id]);
      }
    }, (error) => {
      console.error("Error listening to widgets user config changes:", error);
    });

    return unsubscribe;
  };

  const createStudentPayload = (items) => {
    const filteredData = items.filter(el => !originalStationUser.includes(el));
    if (filteredData?.length === 0) return [];
    setUsers([...usersList, ...filteredData])
    const newUserListData = filteredData?.map((name) => {
      const nameParts = name.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(" ");

      return {
        id: v4(),
        firstName: firstName,
        lastName: lastName,
        role: "student",
        classId: currentClass?.id,
      };
    });
    return newUserListData;
  }

  const createStudentGroup = () => {
    setStudentGenerated(true);
    if (usersByClass) {
      // const studentOfCurrentClass = usersByClass[currentClass.id];
      if (Array.isArray(studentOfCurrentClass) && numberOfGroups > 0) {
        const studentOfCurrent = chunk(
          shuffle(studentOfCurrentClass),
          numberOfGroups
        );
        setStudentGroup(studentOfCurrent);
        if (filterShareGroup?.status) {
          const dataShare = {
            ...filterShareGroup?.data,
            group: JSON.stringify(studentOfCurrent),
          };
          const editShareData = {
            ...filterShareGroup,
            data: dataShare,
          };
          createCollectionWidgets(editShareData);
        }
      } else {
        setStudentGroup([]);
      }
    } else {
      setStudentGroup([]);
    }
  };

  const onClose = async () => {
    await onResetGroup();
    const closeShareData = {
      ...filterShareGroup,
      status: false,
      data: {},
    };
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    if (filterShareGroup?.status) {
      createCollectionWidgets(closeShareData);
    }
    setStudentOfCurrentClass(usersByClass[currentClass.id]);
  };

  const shareGroup = () => {
    if (!filterShareGroup?.id && studentGroup?.length > 0) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "new_group",
        status: true,
        data: {
          group: JSON.stringify(studentGroup),
        },
      };
      createCollectionWidgets(shareData);
    }
  };

  const onResetGroup = () => {
    if (filterShareGroup?.status) {
      const dataShare = {
        ...filterShareGroup?.data,
        group: JSON.stringify([]),
      };
      const editShareData = {
        ...filterShareGroup,
        data: dataShare,
      };
      createCollectionWidgets(editShareData);
    }
    setStudentGenerated(false);
    setNumberOfGroups(0);
    setStudentGroup([]);
  };

  const sharedData = filterShareGroup?.data || {};
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const shareHandler = async () => {
    if (!filterShareGroup?.status) {
      shareGroup();
      return;
    }
    // await onResetGroup();
    const closeShareData = {
      ...filterShareGroup,
      status: false,
      data: {},
    };
    if (filterShareGroup?.status) {
      createCollectionWidgets(closeShareData);
    }
    setStudentOfCurrentClass(usersByClass[currentClass.id]);
  };

  const onCloseHandler = async () => {
    if (isTeacher) {
      await dispatch(revokeTrafficModal(filterByWidget?.id));
      return;
    }
    setStudentMinimized(true);
  };

  const SendOnFirebase = (value) => {
    const WidgetsData = {
      id: 'new_group',
      modified_on: new Date(),
      user_data: value
    }
    createUserWidgetsConfig(WidgetsData, currentClass?.id)
  }

  return (
    <>
      {widgetStationOpen && (
        <div className="custom-fullscreen-container">
          <div className="modal-container">
            <div className="heading-label">New Group</div>
            <div className="modal-inner-container">
              {isTeacher && (
                <button
                  style={{
                    backgroundColor: filterShareGroup?.status && "green",
                  }}
                  onClick={shareHandler}
                  className="share-button-widget-modal "
                >
                  {!filterShareGroup?.status ? (
                    <ShareIcon style={{ color: "white", fontSize: 13 }} />
                  ) : (
                    <AnimatedCheckMark />
                  )}
                  {!filterShareGroup?.status ? "Share" : "Shared"}
                </button>
              )}

              <CloseIcon onClose={onCloseHandler} />
            </div>
            <div>
              <StudentGroupArea
                usersList={usersList}
                setUsers={setUsers}
                dimensions={dimensions}
                setNumberOfGroups={setNumberOfGroups}
                createStudentGroup={createStudentGroup}
                studentGroup={studentGroup}
                resetGroup={() => {
                  onResetGroup();
                }}
                SendOnFirebase={(val) => {
                  SendOnFirebase(val);
                }}
                numberOfGroups={numberOfGroups}
                isTeacher={isTeacher}
                sharedData={sharedData}
                currentClass={currentClass}
                currentUser={currentUser}
                setStudentOfCurrentClass={setStudentOfCurrentClass}
                studentOfCurrentClass={studentOfCurrentClass}
                setOriginalStationUser={setOriginalStationUser}
                shareGroupData={shareGroupData}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CloseIcon = ({ onClose }) => {
  return (
    <div onClick={onClose} className="cancel-icon-station">
      <CancelIcon />
    </div>
  );
};

const StudentGroupArea = ({
  usersList,
  setUsers,
  setNumberOfGroups,
  numberOfGroups,
  createStudentGroup,
  studentGroup,
  resetGroup,
  dimensions,
  isTeacher,
  sharedData,
  setStudentOfCurrentClass,
  currentClass,
  studentOfCurrentClass,
  currentUser,
  setOriginalStationUser,
  SendOnFirebase,
  shareGroupData
}) => {
  const classes = useStyles();
  const [groups, setGroups] = useState(studentGroup);
  const [dragging, setDragging] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [studentSelectModal, setStudentSelectModal] = useState(false);
  const [editSelectedOption, setEditSelectedOption] = useState(
    shareGroupData?.length > 0 ? shareGroupData : []
  );
  const [newUser, setNewUser] = useState({});
  const [showInput, setShowInput] = useState({});
  const [open, setOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setEditSelectedOption(shareGroupData?.length > 0 ? shareGroupData : [])
  }, [shareGroupData]);

  useEffect(() => {
    setGroups(studentGroup);
  }, [studentGroup]);
  const handleDragStart = (e, user, groupIndex) => {
    const dragData = { userId: user.id, groupIndex };
    setDragging(user.id);
    e.dataTransfer.setData("application/json", JSON.stringify(dragData));
    e.target.style.opacity = "0.5";
  };
  const toggleInput = (groupIndex) => {
    setShowInput((prev) => ({ ...prev, [groupIndex]: !prev[groupIndex] }));
  };
  const handleDragEnd = (e) => {
    setDragging(null);
    setExpandedGroup(null);
    e.target.style.opacity = ""; // Reset visual cue
  };

  const handleDragOver = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex);
  };

  const handleDragEnter = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex); // Same as DragOver, to ensure consistency
  };
  const handleDragLeave = (e, groupIndex) => {
    // Optional: Only collapse if leaving the container, not moving to another child
    if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
      setExpandedGroup(null);
    }
  };
  const handleDrop = (e, groupIndex) => {
    e.preventDefault();
    const dropData = JSON.parse(e.dataTransfer.getData("application/json"));
    if (dropData.groupIndex !== groupIndex) {
      const sourceGroup = groups[dropData.groupIndex].filter(
        (user) => user.id !== dropData.userId
      );
      const droppedUser = groups[dropData.groupIndex].find(
        (user) => user.id === dropData.userId
      );
      const targetGroup = [...groups[groupIndex], droppedUser];
      const newGroups = groups.map((group, index) => {
        if (index === dropData.groupIndex) return sourceGroup;
        if (index === groupIndex) return targetGroup;
        return group;
      });

      setGroups(newGroups);
    }
    setExpandedGroup(null);
    setDragging(null);
  };
  const handleAddNewUserToGroup = (groupIndex) => {
    if (newUser[groupIndex]) {
      const updatedGroups = groups.map((group, index) => {
        if (index === groupIndex) {
          return [...group, newUser[groupIndex]];
        }
        return group;
      });
      setGroups(updatedGroups);
      // Reset the newUser input for this group
      setNewUser({ ...newUser, [groupIndex]: null });
      const newData = {
        ...newUser[groupIndex],
        id: v4(),
        role: "student",
        classId: currentClass?.id,
      };
      // setStudentOfCurrentClass([...studentOfCurrentClass, newData]);
      const newStudentData = [`${newUser[groupIndex]?.firstName} ${newUser[groupIndex]?.lastName}`];
      SendOnFirebase([...shareGroupData, ...newStudentData])
    }
  };
  const handleNewUserChange = (e, groupIndex) => {
    setNewUser({
      ...newUser,
      [groupIndex]: { ...newUser[groupIndex], [e.target.name]: e.target.value },
    });
  };

  const handleTouchCreateGroup = (event) => {
    event.preventDefault();
    createStudentGroup();
  };

  const handleTouchReset = (event) => {
    event.preventDefault();
    resetGroup();
  };

  const editDoneHandler = (value) => {
    // const selectedSet = new Set(editSelectedOption);
    // const addedStudentData = addUserOnStationGroupList(usersList);
    // const filteredData = addedStudentData?.filter((student) => {
    //   const fullName = `${student.firstName} ${student.lastName}`;
    //   return selectedSet.has(fullName);
    // });
    // setStudentOfCurrentClass(filteredData);
    if (value?.length === 0) {
      setOpen(true)
      setPopupMessage('Atleast one student Required')
    } else {
      setOpen(false)
      SendOnFirebase(value)
      setStudentSelectModal(false)
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle scrolling when dragging near the edges
  const userAreaRef = useRef(null);
  const handleScroll = (event) => {
    const { clientY } = event;
    const userArea = userAreaRef.current;

    if (!userArea) return;

    const { top, bottom } = userArea.getBoundingClientRect();
    const offset = 10;

    if (clientY - top < offset) {
      userArea.scrollTop -= offset;
    } else if (bottom - clientY < offset) {
      userArea.scrollTop += offset;
    }
  };
  const getNewgroup = sharedData?.group
    ? JSON.parse(sharedData?.group)
    : groups;
  return (
    <>
      {isTeacher && (
        <>
          {/* <div
            style={{ marginTop: dimensions?.width > 250 ? "40px" : "10px" }}
            className="student-per-group"
          >
            Student per Group
          </div> */}
          <Rows
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: "5px",
              marginTop: "52px",
            }}
          >
            <InputField
              id="noOfGroup"
              name="noOfGroup"
              label={windowSize?.width >= 768 ? "Students per stations" : ""}
              style={{
                width: windowSize?.width >= 768 ? 124 : 46,
                color: "#000",
              }}
              InputLabelProps={{
                shrink: true,
                required: true,
                style: { color: "#000" },
                fontSize: "8px",
              }}
              inputProps={{
                shrink: true,
                required: true,
                style: { color: "#000" },
                fontSize: "8px",
              }}
              type="number"
              variant="outlined"
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setNumberOfGroups(e.target.value);
                }
              }}
              onTouchStart={(e) => {
                e.preventDefault();
                if (e.target.value >= 0) {
                  setNumberOfGroups(e.target.value);
                }
              }}
              value={numberOfGroups}
              className={`${classes.noSpinners} group-input-label`}
            />
            <EditListOption
              style={{ width: dimensions?.width < 299 && "76px" }}
              onClick={() => {
                createStudentGroup()
              }}
              onTouchStart={handleTouchCreateGroup}
            >
              Generate
            </EditListOption>
            <ResetButton
              style={{ width: dimensions?.width < 299 && "47px" }}
              onClick={resetGroup}
              onTouchStart={handleTouchReset}
            >
              Reset
            </ResetButton>
            <EditListOption onClick={() => setStudentSelectModal(true)}>
              Edit <IconsImg src={WriteEditWhite} />
            </EditListOption>
          </Rows>
        </>
      )}
      <UserArea ref={userAreaRef} onDragOver={handleScroll}>
        {isEmpty(getNewgroup) ? (
          <UserListAreaEmpty
            className="gradient-blue-pink"
            style={{
              justifyContent: "center",
              width: 654,
              height: 304

            }}
          >
            <span style={{ color: "white", fontSize: 18 }}>Not available.</span>
          </UserListAreaEmpty>
        ) : (
          <>
            {map(getNewgroup, (item, groupIndex) => (
              <UserListArea
                style={{ width: dimensions?.width < 505 ? "100%" : "200px" }}
                key={groupIndex}
                className={
                  expandedGroup === groupIndex ? "expandOnDragOver" : ""
                }
                onDrop={(e) => handleDrop(e, groupIndex)}
                onDragOver={(e) => handleDragOver(e, groupIndex)}
                onDragEnter={(e) => handleDragEnter(e, groupIndex)}
                onDragLeave={(e) => handleDragLeave(e, groupIndex)}
              >
                {map(item, (user) => {
                  const MyUser = user?.id === currentUser?.id;
                  return (
                    <UserItem
                      getMyUser={MyUser}
                      key={user.id}
                      className={dragging === user.id ? "hidden" : ""}
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e, user, groupIndex)}
                      onDragEnd={handleDragEnd}
                    >
                      {`${user.firstName || ""} ${user.lastName || ""}`}
                    </UserItem>
                  );
                })}
                {!showInput[groupIndex] && isTeacher && (
                  <IconButton
                    color="primary"
                    onClick={() => toggleInput(groupIndex)}
                    onTouchStart={() => toggleInput(groupIndex)}
                  >
                    <AddIcon />
                  </IconButton>
                )}
                {showInput[groupIndex] && (
                  <div>
                    <UserInput
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      variant="outlined"
                      size="small"
                      style={{ color: "white" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.firstName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <UserInput
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      variant="outlined"
                      size="small"
                      style={{ color: "white", marginTop: "10px" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.lastName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: "10px", borderRadius: "50%" }}
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        handleAddNewUserToGroup(groupIndex);
                        toggleInput(groupIndex); // Hide inputs after adding
                      }}
                      onTouchStart={() => {
                        handleAddNewUserToGroup(groupIndex);
                        toggleInput(groupIndex); // Hide inputs after adding
                      }}
                    />
                  </div>
                )}
              </UserListArea>
            ))}
          </>
        )}
      </UserArea>
      <Modal open={studentSelectModal}>
        <ModalContent style={{ marginTop: "20px" }}>
          <MultiSelecter
            open={open}
            popupMessage={popupMessage}
            options={usersList}
            onListChange={(items) => {
              setUsers([...usersList, ...items]);
              setOriginalStationUser([...usersList, ...items]);
            }}
            onSelect={(value) => {
              // setSegments(value);
              // setStudentSelectModal(false);
              // if (!value.includes(result)) {
              //   setResult("");
              // }
            }}
            setEditSelectedOption={setEditSelectedOption}
            editSelectedOption={editSelectedOption}
            // defaultValue={segments}
            hideModal={() => setStudentSelectModal(false)}
            editDoneHandler={editDoneHandler}
            shareGroupData={shareGroupData}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default WidgetGroupModal;

const MultiSelecter = ({
  open,
  popupMessage,
  options,
  onSelect,
  defaultValue,
  onListChange,
  hideModal,
  setEditSelectedOption,
  editDoneHandler,
  editSelectedOption,
  shareGroupData
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options?.length > 0 && selected?.length === options?.length;
  const [list, setList] = useState([]);
  const MultiMenu = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    variant: "menu",
    getContentAnchorEl: () => null,
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value?.length - 1] === "all") {
      setSelected(selected?.length === options?.length ? [] : options);
      setEditSelectedOption(selected?.length === options?.length ? [] : options);
      return;
    }
    setSelected(value);
    setEditSelectedOption(value);

  };

  useEffect(() => {
    if (editSelectedOption?.length > 0 || shareGroupData?.length > 0) {
      setSelected(shareGroupData);
      setEditSelectedOption(shareGroupData);

    } else {
      setSelected(options);
      setEditSelectedOption(options);
    }
    setList([]);
  }, [shareGroupData]);

  const onDone = () => {
    onSelect(selected);
  };
  const onChange = (txt, i, key) => {
    let _lst = cloneDeep(list);
    _lst[i][key] = txt;
    setList(_lst);
  };
  const onRemove = (i) => {
    let _lst = cloneDeep(list);
    _lst.splice(i, 1);
    setList(_lst);
  };
  const addNew = () => {
    let _lst = cloneDeep(list);
    _lst.push({ firstName: "", lastName: "", id: list?.length });
    setList(_lst);
  };
  const onDoneAddItem = () => {
    let _lst = filter(list, (lst) => lst.firstName || lst.lastName).map(
      (item) => `${item.firstName}  ${item.lastName}`
    );

    if (_lst?.length > 0) {
      onListChange(_lst);
      setSelected([...selected, ..._lst]);
      // setEditSelectedOption([...selected, ..._lst]);
      editDoneHandler([...selected, ..._lst])
      onSelect([...selected, ..._lst], true);
      setList([]);
    } else {
    }
  };

  return (
    <FormControl
      style={{ width: "410px" }}
      variant="outlined"
      className={classes.formControlStudent}
    >
      <div
        style={{
          border: list?.length === 0 && "none",
          margin: list?.length === 0 && "0px",
          padding: list?.length === 0 && "0px",
        }}
        className="w-full form-field-container"
      >
        {map(list, (lst, i) => (
          <ModalRow style={{ justifyContent: "space-between" }}>
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              style={{ width: 150, color: "white", marginRight: "5px" }}
              InputLabelProps={{ shrink: true, required: true }}
              inputProps={{ shrink: true, required: true }}
              variant="outlined"
              onChange={(e) => {
                const txt = e.target.value;
                onChange(txt, i, "firstName");
              }}
              value={lst.fistName}
            />
            -
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              style={{ width: 150, color: "white", marginLeft: "5px" }}
              InputLabelProps={{ shrink: true, required: true }}
              inputProps={{ shrink: true, required: true }}
              variant="outlined"
              onChange={(e) => {
                const txt = e.target.value;
                onChange(txt, i, "lastName");
              }}
              value={lst.lastName}
            />
            <IconButton
              color="secondary"
              onClick={() => {
                onRemove(i);
              }}
            >
              <Delete />
            </IconButton>
          </ModalRow>
        ))}
        {list?.length > 0 && (
          <div className="flex items-center justify-end">
            <Button
              style={{ marginRight: "4px", background: "lightGrey" }}
              onClick={() => {
                setList([]);
              }}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              variant="contained"
              style={{ float: "right" }}
              onClick={() => {
                if (!list?.length) {
                  onDone();
                } else {
                  onDoneAddItem();
                }
              }}
            >
              Done
            </Button>
          </div>
        )}
      </div>
      <ModalRow>
        <Button color="primary" variant="contained" onClick={addNew}>
          <Add /> {list?.length > 0 ? "Add more student" : "Add student"}
        </Button>
        {open && <span style={{ color: 'red' }}>{popupMessage}</span>}
        <Row>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              // hideModal();
              editDoneHandler(selected);
            }}
          >
            Done
          </Button>
        </Row>
      </ModalRow>
      <FormControl variant="outlined" className={classes.formControlStudent}>
        <InputLabel
          id="mutiple-select-label"
          style={{ backgroundColor: "#fff" }}
        >
          Select students
        </InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => (
            <div className={classes.chips}>
              <Chip
                label={`${isAllSelected || selected?.length == 0
                  ? "All students"
                  : "Students(" + selected?.length + ")"
                  }`}
                className={classes.chip}
              />
            </div>
          )}
          MenuProps={MultiMenu}
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected?.length > 0 && selected?.length < options?.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
          {map(options, (option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox
                  checked={Boolean(find(selected, (k) => k === option))}
                />
              </ListItemIcon>
              <ListItemText primary={`${option}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  noSpinners: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  //   min-height: 500px;
  //   background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: default;
  left: 25%;
  border-radius: 13px;
`;

const LogoTopRow = styled.div``;
const Logo = styled.img`
  height: auto;
  width: 160px;
`;

const CloseBtn = styled.div`
  background-color: ${COLOR.PinkExam};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const TimerContainer = styled.div`
  gap: 22px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (max-width: 1366px) {
    flex-direction: column;
  }
`;

const EditListOption = styled.div`
  text-align: center;
  align-items: center;
  background-image: linear-gradient(
    90deg,
    ${COLOR.WheelEditColor1} 0%,
    ${COLOR.WheelEditColor2} 40%,
    ${COLOR.WheelEditColor3} 100%
  );
  color: #fff;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 90px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
`;

const IconsImg = styled.img`
  height: 15px;
  width: 15px;
  object-fit: cover;
  margin-left: 10px;
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const ResetButton = styled.div`
  text-align: center;
  align-items: center;
  background: #d11919;
  color: #fff;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
  // @media (max-width: 997px) {
  //   margin-top: 16px;
  // }
`;

const UserArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  flex-wrap: wrap;
  max-height: 55vh;
  justify-content: center;
  // max-width: 50vw;
  // @media (max-width: 997px) {
  //   max-width: 90vw;
  // }
  //   overflow: auto !important;
  ::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }
`;

const SectionsGroup = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GroupHeaderText = styled.div`
  background-color: #fd9d1a;
  padding: 8px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 200px;
  text-align: center;
  color: #fff;
  justify-content: center;
`;

const UserListArea = styled.div`
  background: linear-gradient(
    90deg,
    #df6074 0%,
    #bd57b4 30%,
    #a451e7 50%,
    #9a59e8 60%,
    #7579f0 90%,
    #6786f4 100%
  );
  min-height: 114px;
  // width: 200px;
  min-width: 175px;
  border-radius: 10px;
  padding: 10px;
  margin: 12px 16px;
`;

const UserListAreaEmpty = styled.div`
  background: #cbbbe7;
  min-height: 114px;
  min-width: 175px;
  border-radius: 10px;
  padding: 10px;
  margin: 12px 16px;
  display: flex;
  align-items: center;
`;

const UserItem = styled.div`
  background: ${(p) => (p.getMyUser ? "#152B69" : "#fff")};
  color: ${(p) => (p.getMyUser ? "#fff" : "#000")};
  padding: 8px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
`;

const UserInput = styled(TextField)`
  margin-top: 8px;
`;

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const UserInputControled = styled(TextField)`
  margin-top: 8px;

  .MuiInputBase-root {
    height: 25px; // Adjust the height as needed
    padding: 0 14px; // Reduce padding to decrease height

    .MuiInputBase-input {
      font-size: 0.875rem; // Adjust font size to match the smaller input
    }
  }

  .MuiInputLabel-root {
    font-size: 0.875rem; // Adjust label font size to match
  }

  .MuiOutlinedInput-root {
    padding: 0;
    position: relative;
  }
`;

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
})(TextField);
