import React , { useState , useEffect } from 'react';
import { Button } from 'antd';
import { SoundOutlined , PauseCircleOutlined } from '@ant-design/icons';
import { debounce } from "lodash"
import { IconButton } from '@material-ui/core';
import { useAudio } from 'utils/context/AudioProvider';
import { handleTextToSpeech } from 'store/vocabulary/thunks';
import Loading from './Loading';
import { message } from "antd"

const TextReader = ({ text, language = "en-US", style = {},
   stopAudio
  }) => {
  const voice_text = Array.isArray(text) ? text.join(" ") : text
  const [isPlaying , setIsPlaying ] = useState(false);
  const [voices, setVoices] = useState([]);
  const { playAudio, audioRef, currentAudio } = useAudio();
  const [audio, setAudio] = useState(null);
  const [audioVersion, setAudioVersion] = useState('female');
  const [loader,setLoading] = useState(false);
  const [voiceSetting, setVoiceSetting] = useState({
    voice_languageCode: language ? language : 'es-ES',
    voice_name: 'es-ES-Neural2-A'
  });
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying (false);
      stopAudio();
      setAudio(null);
    }
  }, [voiceSetting]);

  useEffect(() => {
    if (audioRef.current) {
      const handleAudioEnd = () => {
        setIsPlaying (false);
      };

      audioRef.current.addEventListener('ended', handleAudioEnd);

      return () => {
        audioRef.current.removeEventListener('ended', handleAudioEnd);
      };
    }
  }, [audioRef.current]);

  const speakText = debounce(async () => {
    if (!voice_text) return; 
    try {
      setLoading(true)
      if (!audio) {
        const newIdea = {
          text: voice_text,
          pitch: '',
          volume: '',
          speed: audioVersion === 'male' ? 0.9 : '',
          voice_name: voiceSetting.voice_name,
          voice_languageCodes: voiceSetting.voice_languageCode
        };
        let data = await handleTextToSpeech(newIdea);
        const audioData = data.body.data;
        const audioBlob = new Blob([Uint8Array.from(audioData)], { type: 'audio/mp3' });
        const url = URL.createObjectURL(audioBlob);
        setAudio(url);
        playAudio(url);
        setIsPlaying (true);
      } else {
        if (isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
          stopAudio()
        } else {
          audioRef.current.play();
          setIsPlaying(true);
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error("Something went wrong")
    } 
  }, 1000);

  if (!voice_text) {
    return null; 
  }
  const icon = isPlaying  ? <PauseCircleOutlined /> : <SoundOutlined />;
  const iconStyle = { position: 'absolute', top: 5, right: 2, padding: 0, ...style };

  return (
    <IconButton onClick={speakText} style={iconStyle}>
      {loader?<Loading size={14} />: icon}
    </IconButton>
  );
};

export default TextReader;
