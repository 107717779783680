//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep, filter } from 'lodash';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const init = {
    view: false
};


export const wheelModal = (state = init, action) => {
    switch (action.type) {
        case ActionTypes.wheel.invokeWheelModal:
            return ({ ...state, view: true });
        case ActionTypes.wheel.revokeWheelModal:
            return ({ ...state, view: false });
        default:
            return state;
    }
};


const checkListView = {
    view: false,
    data: []
};


export const checkListModal = (state = checkListView, action) => {
    switch (action.type) {
        case ActionTypes.wheel.invokeCheckList:
            if (state.data.some(item => item.id === action.payload.id)) {
                return ({ ...state, view: true, data: [...state.data] });
            } else {
                return ({ ...state, view: true, data: [...state.data, action.payload] });
            }
        case ActionTypes.wheel.revokeCheckList:
            const delvv = cloneDeep(state?.data)
            const result = filter(delvv, k => k.id != action.payload.id)
            return ({ ...state, view: false, data: result });
        default:
            return state;
    }
};

const noiseinit = {
    view: false
};


export const noiseModal = (state = noiseinit, action) => {
    switch (action.type) {
        case ActionTypes.wheel.invokeNoiseMeter:
            return ({ ...state, view: true});
        case ActionTypes.wheel.revokeNoiseMeter:
            return ({ ...state, view: false});
        default:
            return state;
    }
};

const trafficinit = {
    widget_menu: []
}

export const trafficModal = (state = trafficinit, action) => {
    switch (action.type) {
        case ActionTypes.wheel.invokeTrafficView:
            if (state.widget_menu.some(item => item.id === action.payload.id)) {
                return ({ ...state, widget_menu: [...state.widget_menu] });
            } else {
                return ({ ...state, widget_menu: [...state.widget_menu, action.payload] });
            }
        case ActionTypes.wheel.revokeTrafficModal:
            const delvv = cloneDeep(state?.widget_menu)
            const result = filter(delvv, k => k.id != action.payload)
            return ({ ...state, widget_menu: result});
        case ActionTypes.wheel.revokeAllWidget:
            return ({ ...state, widget_menu: [] });
        default:
            return state;
    }
};
