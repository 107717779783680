//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth, label } from "aws-amplify";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { Col, Input, Row, Select, Space, Table, Modal, Spin, Tooltip , Form  } from "antd";
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import SentryWrap from "components/Sentry";
//  -- Constants ----------------------

//  -- Features -----------------------

//  -- Thunks -------------------------
import { DistrictBg } from "utils/assets/images";
import { handleListContentExamData, handleListContentPassingExamData, handleListContentPassingExamStudentData, handleListGetSchool } from "store/organizations/thunks";
import moment from 'moment'
import color from "utils/constants/color";
import { handleListDistTeacher } from "store/users/thunks";
import { isEmpty, filter , map , find , groupBy } from 'lodash'
import { Button, TablePagination , Modal as MMOdal , Icon} from "@material-ui/core"
import { ViewList } from "@material-ui/icons"
import Loading from "components/Loading";
import { DownloadOutlined } from '@ant-design/icons';
import XLSX from 'xlsx';
import COLOR from "utils/constants/color"
const { Option } = Select;
const { Search } = Input;
const years = new Date().getFullYear();
const upcomming_year = `${years}-${years + 1}`;
const downcomming_year = `${years - 1}-${years}`;
const YEAR_SELECTER = [upcomming_year, downcomming_year]

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const VideoContainer = styled.div`
    height: auto;
    flex: 1;
    justify-content: center;
`;

const ContentLoading = ({ isLoading, isClose }) => {
    return (
        <MMOdal
            // className={classes.modal}
            open={isLoading}
        >
            <VideoContainer>
                <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%' }} />
            </VideoContainer>
        </MMOdal>
    )
};

const Wrapper = styled.div`
  height: 100%;
  width: calc(100% + 50px);
  margin-left: -25px;
  background-image: url(${DistrictBg});
  background-position: 100% 100%;
  background-size: cover;
`;


const TableContainer = styled.div`
  margin-bottom: 20px;
`;
const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: rgb(239,246,254); 
  }
`;
const CustomTableStudentSub = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: ${color.indigo200}; 
    color: #fff;
  }
  &.ant-table-wrapper {
    .ant-table-thead > tr > th {
      background-color: ${color.indigo200};
      color: #fff; 
    }
  
    .ant-table-tbody > tr > td {
      background-color: rgb(58,58,58);
      color: #fff; 
    }

    .ant-table-tbody > tr:hover > td {
      background-color: rgb(58,58,58);
      color: #fff; 
    }
    
    .ant-table-column-sort {
      background: rgb(58,58,58);
      color: #fff; 
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 0px !important;
    border: 1px solid #d9d9d9 !important; 
    background-color: rgb(237 237 237) !important;
    height: 40px !important;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
  }
  .ant-select-dropdown {
   
  }
`;
const Title = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`;




const StyledSearchInput = styled(Input)`
  height: 50px;
  .ant-input-prefix {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-input {
    border-radius: 4px;
  }

  /* Add more styles if you need */
`;

const StyledTable = styled(Table)`
  && tbody > tr > td {
    text-align: center;
  }
`;
const NoOfQuestions = styled.span`
    position: absolute;
    right: 10px;
    top: 14px;
    z-index: 99;
`;
const IconButton = styled.button`
   border: none;
   cursor: pointer;
   height: 20px;
   margin-left: 5px;
   background-color: transparent;
`;


const StyledModalHeader = styled.div`
  background-color: #f0f0f0;
  padding: 16px;
  color: #333;
  font-weight: 500;
  text-align: center; // Center the title text if desired
`;
const DownloadButton = styled.button`
    border: none;
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
    margin-right: 16px;
    height: 40px;
    background: ${COLOR.green200};
    color: #fff;
    margin-top: 20px;
    padding-left: 12px;
    padding-right: 12px;
    &:hover {
      background: ${COLOR.green1000};
    }
`;

// Styled modal body
const StyledModalBody = styled.div`
  padding: 16px;
`;


const SearchBar = ({ onSearch }) => {
    return (
        <StyledSearchInput
            prefix={<SearchOutlined />}
            placeholder="Search by teacher or student"
            onChange={(e) => onSearch(e.target.value)}
        />
    );
};

const SortingMenu = ({
    grades,
    onFilter,
    selected_class
  }) => {
    const [selectedClass, setSelectedClass] = useState(null)
    const classData = useMemo(() => {
      try {
        const g = groupBy(grades, 'user.class_id')
        const class_data = Object.keys(g).map(k => {
          const cls = g[k][0]
          if (cls && cls.user) {
            return {
              value: cls.user.class_id,
              label: cls.user.class_name
            }
          }
          return null;
        }).filter(k => k)
        return class_data
      } catch (error) {
        console.log(error, "error")
        return []
      }
  
    }, [grades])
  
    useEffect(() => {
      setSelectedClass(selected_class)
    }, [selected_class])
  
    const onSelectCh = (txt) => {
      setSelectedClass(txt)
      onFilter(txt)
    }
    return (<div>
      <label htmlFor="schoolSelect" style={{ display: 'block', }}>
        Classes
      </label>
      <StyledSelect
        showSearch
        bordered={false}
        placeholder={"Select classes"}
        style={{ width: SELECT_WIDTH }}
        value={selectedClass}
        onChange={onSelectCh}
        options={classData}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      /></div>)
  }

const InfoTooltip = ({ message }) => (
    <Tooltip title={message || ''}>
        <InfoCircleOutlined style={{ marginLeft: 8, color: color.redishpink }} />
    </Tooltip>
);

const SELECT_WIDTH = 200
const fail_pass ={
    fail: "🔴",
    pass: "🟢"
}

const DistrictContainer = ({
    currentUser,
    users,
    schools,
    currentOrg,
    distTeacher,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [teacherInfoLoading, setTeacherInfoLoading] = useState(true);
    const [activeSession, setActiveSession] = useState(moment().get("month") < 7 ? downcomming_year : upcomming_year)
    const [studentExamData, setStudentExamData] = useState([]);
    const [filteredstudentExamData, setFilteredStudentExamData] = useState([]);
    const [studentUnitExamData, setStudentUnitExamData] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [number_of_questions, setNumberOfQuestions] = useState(0)
    const [selected_teacher, setSelectedTeacher] = useState(null);
    const [selected_school, setSelectedSchool] = useState(null);
    const [selected_class, setSelectedClass] = useState(null);
    const [selected_units, setSelectedUnits] = useState('all');
    const [selected_exam, setSelectedExam] = useState('all');
    const [selected_books, setSelectedBooks] = useState('all');
    const [showStudentSubmission, setShowStudentSubmission] = useState(false)
    const [studentSubmissionData, setStudentSubmissionData] = useState([])
    const [studentSubmissionDataLoading, setStudentSubmissionDataLoading] = useState(false)
    const [selectedstudentUnitExamData, setselectedStudentUnitExamData] = useState([]);
    const [selected_sort_by , setSelectedSortBy] = useState("highest")
    const [printloader, setPrintloader] = useState(false)

    useEffect(() => {
        if (Array.isArray(users)) {
            setTeachers(filter(distTeacher, ['role', 'teacher']))
        }
    }, [distTeacher])

    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch (error) {

            }
        }
        fetchData();
    }, []);
    const fetchDistrictData = async () => {
        try {
            if (!isEmpty(currentOrg)) {
                dispatch(handleListGetSchool(currentOrg.id));
                dispatch(handleListDistTeacher(currentOrg.id));
            }
        } catch (error) {
        }
    };
    useEffect(() => {
        fetchDistrictData()
    }, [currentOrg]);

   
    async function fetchData() {
        if(!currentOrg || ! selected_school || !selected_teacher) return;
        setLoading(true)
        const dt = {
            "session": activeSession,
            "id": currentOrg.id,
            "school_id": selected_school,
            "units": selected_units === 'all' ? null : selected_units,
            "exams": selected_exam === 'all' ? null : selected_exam,
            "book_id": selected_books === 'all' ? null : selected_books,
            "teacher_id": selected_teacher
        }
        try {
            if(dt.units && dt.exams){
                const exam_data = await dispatch(handleListContentPassingExamData(dt))
                if(exam_data && exam_data.statusCode == 200){
                    setStudentUnitExamData(exam_data.data || [])
                }
            }
            const data = await dispatch(handleListContentExamData(dt))
        
            if (data && data.statusCode == 200) {
                setStudentExamData(data.data)
                setFilteredStudentExamData(data.data)
                setNumberOfQuestions(data.number_of_questions || 0)
                if (data.data[0]) {
                    if (!find(data.data, ["user.class_id", selected_class])) {
                        setSelectedClass(data.data[0].user.class_id)
                    }
                } else {
                    setSelectedClass(null);
                }
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }

    }
    useEffect(() => {
        fetchData()
    }, [currentOrg, selected_school, selected_units, selected_exam, selected_books, selected_teacher,activeSession]);
    const fetchUserData = async (user_id) =>{
       setShowStudentSubmission(true);
       setStudentSubmissionDataLoading(true)
       setselectedStudentUnitExamData([])
       const dt = {
        "session": activeSession,
        "id": currentOrg.id,
        "school_id":  selected_school,
        "units": selected_units === 'all' ? null : selected_units,
        "exams": selected_exam === 'all' ? null : selected_exam,
        "book_id": selected_books === 'all' ? null : selected_books,
        "teacher_id":  selected_teacher,
        user_id: user_id
    }
    try {
        const exam_data = await dispatch(handleListContentPassingExamStudentData(dt))
        if(exam_data &&exam_data.statusCode === 200) {
            setselectedStudentUnitExamData(exam_data.data || [])
        }
    } catch (error) {

    } finally {
        setStudentSubmissionDataLoading(false)
    }

    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'user',
            render: (user, { number_of_submissions }) => (
                <div style={{ marginRight: 8  , display:"flex" , alignItems:"center"}}>
                    {user?.firstName || ''} {user?.lastName || ''} {number_of_submissions > 0 ? <IconButton onClick={()=>fetchUserData(user.id)}> <ViewList  style={{ fontSize:14 }}/></IconButton> : null}
                </div>
            ),
        },
        {
            title: selected_exam === 'all' ? 'Overall Score' : 'Score',
            dataIndex: 'score',
            key: 'score',
            width: 140,
            render: (score, { is_completed, manual_graded, no_assessment_assigned }) => (
                <span style={{ marginRight: 8 }}>
                    {score} {
                        (!is_completed || manual_graded) && no_assessment_assigned > 0 ? <InfoTooltip message={!is_completed ? `Assignment submission incomplete.` : `The grade has been manually entered by the teacher.`} /> : null
                    }
                </span>
            )
        },
        {
            title: 'Questions Passed',
            dataIndex: 'correct',
            width: 140,
            key: 'correct',
            render: c => (
                <span style={{ color: color.green }}>
                    {c}
                </span>
            ),
        },
        {
            title: 'Questions Failed',
            dataIndex: 'incorrect',
            width: 140,
            key: 'incorrect',
            render: c => (
                <span style={{ color: color.red }}>
                    {c}
                </span>
            ),
        },
        {
            title: 'Questions Incomplete',
            dataIndex: 'not_attempted',
            key: 'not_attempted',
            width: 140,
            render: c => (
                <span style={{ color: color.red }}>
                    {c}
                </span>
            ),
        },
        {
            title: 'Number of Assessments',
            dataIndex: 'no_assessment_assigned',
            width: 140,
            key: 'no_assessment_assigned'
        }
    ];
    const ExamUnitcolumns = [
        {
            title: 'Questions',
            dataIndex: 'question_id',
            key: 'question_id',
            render: (value, { question }) => (<span>{value + 1}
                <InfoTooltip message={question ? question : ''} />
            </span>)
        },
        {
            title: 'Passing',
            dataIndex: 'correct_percent',
            key: 'correct_percent',
            render:(value) => (<span style={{ color: color.green }}>{value||'0'}%</span>)
        },
        {
            title: 'Failing',
            dataIndex: 'incorrect_percent',
            key: 'incorrect_percent',
            render:(value) => (<span style={{ color: color.red }}>{value ||'0'}%</span>)
        },
    ];
    const StudentExamUnitcolumns = [
        {
            title: 'Question',
            dataIndex: 'question_id',
            key: 'question_id',
            width: 140,
            render:(value) => (<span>{value}</span>)
        },
        {
            title: 'Pregunta',
            dataIndex: 'question',
            key: 'question',
            render:(value) => (<span style={{ }}>{value}</span>)
        },
        {
            title: 'Met/fail',
            dataIndex: 'is_correct',
            key: 'is_correct',
            width: 80,
            render:(value) => (<span style={{  }}>{value?fail_pass.pass:fail_pass.fail} </span>)
        },
    ];
    const school_option = useMemo(() => {
        const values = []
        if (!isEmpty(schools)) {
            values.push(...schools.filter(k => !k.demo).map(v => ({ label: v.displayName, value: v.id })))
        }
        return values;
    }, [schools])

    useEffect(() => {
        const opt = school_option[0]
        if (opt) {
            setSelectedSchool(opt.value)
        }
    }, [school_option]);

    useEffect(() => {
        if (selected_school) {
            const teacher_list = filter(teachers, ['orgId', selected_school])
            const first_teacher = teacher_list[0];
            if (first_teacher && (!selected_teacher || !find(teacher_list, { 'id': selected_teacher }))) {
                setSelectedTeacher(first_teacher.id)
            }
        }
    }, [selected_school, teachers, selected_teacher])

    const teacher_map = useMemo(() => {
        let tavl = []
        const demo_school = schools.filter(k => k.demo).map(k => k.id)
        if (selected_school === 'all') {
            tavl = teachers.filter(e => !demo_school.includes(e.orgId) && !e.demo ).map(v => ({ label: `${v.lastName || ''}, ${v.firstName || ''}`, value: v.id }))
        } else {
            tavl = filter(teachers, ['orgId', selected_school]).map(v => ({ label: `${v.lastName || ''}, ${v.firstName || ''}`, value: v.id }))
        }
        return tavl;
    }, [schools, teachers, selected_school]);
    const handleSearch = (txt) => {
        try {
            if (txt && txt.length > 0) {
                const searchText = txt.toLowerCase();
                const filteredData = studentExamData.filter(k =>
                    k.user.firstName.toLowerCase().includes(searchText) ||
                    k.user.lastName.toLowerCase().includes(searchText)
                );
                setFilteredStudentExamData(filteredData);
            } else {
                setFilteredStudentExamData(studentExamData);
            }
        } catch (error) {
            console.error('An error occurred while filtering data:', error);
        }
    }
    const handleClose = () => {
        setShowStudentSubmission(false);
    };
    function sortData(data, sortOrder = 'asc') {
        return data.sort((a, b) => {
            if (a.no_assessment_assigned === 0 && b.no_assessment_assigned !== 0) return 1;
            if (a.no_assessment_assigned !== 0 && b.no_assessment_assigned === 0) return -1;
            if (a.overall_score !== b.overall_score) {
                return sortOrder === 'asc' ? a.overall_score - b.overall_score : b.overall_score - a.overall_score;
            }
            return sortOrder === 'asc' ? a.no_assessment_assigned - b.no_assessment_assigned : b.no_assessment_assigned - a.no_assessment_assigned;
        });
    }
    
    const tableData = useMemo(() => {
        const dataToSort = [...filteredstudentExamData];
        return sortData(dataToSort, selected_sort_by === 'highest' ? 'desc' : 'asc');
    }, [filteredstudentExamData, selected_sort_by]);

    const tableDataByClass = useMemo(() => {
        if (selected_class) {
            return tableData.filter(student => student.user.class_id === selected_class);
        } else {
            return tableData;
        }
    }, [tableData, selected_class]);


    const generateCSV = () => {
        try {
            const uniqueRows = new Set();
            studentExamData.map((v) => {
                let userData = {}
                columns.forEach((acc) => {
                    let k_name = acc.title;
                    if (acc.key === 'user') {
                        userData[k_name] = `${v.user.firstName || ''} ${v.user.lastName || ''}`;
                    } else {
                        userData[k_name] = v[acc.key]
                    }
                })
                uniqueRows.add(userData);
            })
            const rowdata = Array.from(uniqueRows)
            const finalData = { data: rowdata };
            const wb = XLSX.utils.book_new();
            const exambook = [
                columns.map((acc) => acc.title)
            ];
            finalData.data.forEach(userd => {
                exambook.push(Object.values(userd));
            });
            const ws = XLSX.utils.aoa_to_sheet(exambook);
            XLSX.utils.book_append_sheet(wb, ws, `content_exam`);
            XLSX.writeFile(wb, `content_exam ${moment().format("DD-MM-YY hh:mm:ss")}.csv`);
        } catch (error) {
            console.error("Error in exam gradebook download:", error);
        }
    };
      
      const onDownload = () => {
        generateCSV()
      };
    
    return (
        <SentryWrap>
            <Modal
                title={null}
                open={showStudentSubmission}
                onCancel={handleClose}
                closable={true}
                className="content-exam-pop"
                footer={null}
            >
                <StyledModalBody>
                    <CustomTableStudentSub
                        columns={StudentExamUnitcolumns}
                        dataSource={selectedstudentUnitExamData}
                        pagination={{ position: "bottomRight" , pageSize: 100  }} 
                        scroll={{ y: 'calc(70vh - 104px)' }}
                        sticky
                        bordered
                        loading={studentSubmissionDataLoading}
                    />
                    {studentSubmissionDataLoading && (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Spin size="large" />
                        </div>
                    )}
                </StyledModalBody>
            </Modal>
            <div style={{ position: "relative" }}>
                <ContentLoading isLoading={loading} />
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Space size="middle" style={{ display: 'flex', gap: 1 }}>
                        <div>
                            <label htmlFor="schoolSelect" style={{ display: 'block', }}>
                                Schools
                            </label>
                            <StyledSelect
                                defaultValue={selected_school}
                                style={{ width: SELECT_WIDTH }}
                                showSearch
                                bordered={false}
                                value={selected_school}
                                placeholder={"Select"}
                                options={school_option}
                                onChange={v => {
                                    setSelectedTeacher('all')
                                    setSelectedSchool(v)
                                }}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </div>
                        <div>
                            <label htmlFor="schoolSelect" style={{ display: 'block', }}>
                                Teachers
                            </label>
                            <StyledSelect
                                defaultValue={'all'}
                                value={selected_teacher}
                                style={{ width: SELECT_WIDTH }}
                                showSearch
                                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                options={teacher_map}
                                onChange={(v) => {
                                    setSelectedTeacher(v)
                                }} />
                        </div>
                        <SortingMenu
                            grades={studentExamData}
                            selected_class={selected_class}
                            onFilter={(v) => {
                                setSelectedClass(v)
                            }} />
                        <div>
                            <label htmlFor="schoolSelect" style={{ display: 'block', }}>
                                Session
                            </label>
                            <StyledSelect value={activeSession} style={{ width: SELECT_WIDTH }} onChange={setActiveSession}>
                                {map(YEAR_SELECTER, x => <Option value={x} key={x}>{x}</Option>)}
                            </StyledSelect>
                        </div>
                    </Space>
                    <Row>
                        <Col span={24} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap:4 }}>
                            <Space size="middle" style={{ display: 'flex', gap: 1 }}>
                                <StyledSelect value={selected_books} onChange={setSelectedBooks} style={{ width: SELECT_WIDTH }}>
                                    <Option value="all">Books(all)</Option>
                                    <Option value="13">Assessment 1</Option>
                                    <Option value="14">Assessment 2</Option>
                                </StyledSelect>
                                <StyledSelect value={selected_units} onChange={v => {
                                    setSelectedExam('all');
                                    setSelectedUnits(v)
                                }} style={{ width: SELECT_WIDTH }}>
                                    <Option value="all">Units (all)</Option>
                                    <Option value="Unidad 1">Unidad 1</Option>
                                    <Option value="Unidad 2">Unidad 2</Option>
                                    <Option value="Unidad 3">Unidad 3</Option>
                                    <Option value="Unidad 4">Unidad 4</Option>
                                    <Option value="Unidad 5">Unidad 5</Option>
                                    <Option value="Unidad 6">Unidad 6</Option>
                                </StyledSelect>
                                <StyledSelect value={selected_exam} onChange={v => {
                                    setStudentUnitExamData([])
                                    setSelectedClass(null)
                                    setSelectedExam(v)
                                }}
                                    style={{ width: SELECT_WIDTH }}
                                    disabled={selected_units === 'all'}
                                >
                                    <Option value="all">Exams(all)</Option>
                                    <Option value="1">Exam 1</Option>
                                    <Option value="2">Exam 2</Option>
                                    <Option value="3">Exam 3</Option>
                                    <Option value="4">Exam 4</Option>
                                    <Option value="5">Exam 5</Option>
                                    <Option value="6">Exam 6</Option>
                                </StyledSelect>
                                <Form.Item label="" name="select" style={{ marginBottom: 0 }}>
                                    <StyledSelect defaultValue="highest" value={selected_sort_by} style={{ width: 120 }} onChange={setSelectedSortBy}>
                                        <Option value="highest">Highest</Option>
                                        <Option value="lowest">Lowest</Option>
                                    </StyledSelect>
                                </Form.Item>
                                <DownloadButton onClick={onDownload} style={{ marginRight: '16px', marginTop: 0 }}>
                                    {printloader ? <Loading size={20} /> : <DownloadOutlined style={{ marginRight: '4px' }} />}
                                    Download
                                </DownloadButton>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={16} xs={24} style={{ position: "relative" }}>
                            <SearchBar onSearch={handleSearch} placeholder="Search by name" style={{ width: "100%" }} /> <NoOfQuestions>Number of questions {number_of_questions} </NoOfQuestions>
                        </Col>
                    </Row>
                </Space>
                <Row>
                    <Col sm={16} xs={24}>
                        <TableContainer>
                            <CustomTable columns={columns} dataSource={tableDataByClass} pagination={{ position: "bottomRight" }} />
                        </TableContainer>
                    </Col>
                    <Col sm={8} xs={24} style={{ paddingLeft: 24 }}>
                        {selected_units != 'all' && selected_exam != 'all' && <>
                            <Title>Overall Passing per Question</Title>
                            <StyledTable
                                columns={ExamUnitcolumns}
                                dataSource={studentUnitExamData}
                                pagination={false}
                            />
                        </>}

                    </Col>
                </Row>

            </div>

        </SentryWrap>
    );
};

//  -- Prop types ---------------------
DistrictContainer.propTypes = {};

//  -- Default props ------------------
DistrictContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    currentOrg: state.currentOrg,
    users: state.users,
    booksAllList: state.booksAllList,
    schools: state.schools,
    userBooksList: state.userBooksList,
    distTeacher: state.distTeacher,
});

export default connect(mapStateToProps, null)(DistrictContainer);
