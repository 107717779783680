import {
  Button,
  Modal
} from "@material-ui/core";
import Loading from "components/Loading";
import React from "react";
import styled from "styled-components";
import COLOR from "utils/constants/color";

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`

const ModalRowDelete = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;
const ModalContentDelete = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const DeleteModal = ({ deleteToggle, setDeleteToggle, message, onDelete, loading, buttonTitle = 'Delete', colorSchema = "secondary" }) => {
  return (
    <Modal open={Boolean(deleteToggle)} onClose={() => {
      if (!loading) {
        setDeleteToggle(false)
      }
    }}>
      <ModalContentDelete>
        <Text>
          <strong style={{ fontSize: 14, fontWeight: 500, color: 'black' }}>{message}</strong>
        </Text>
        <ModalRowDelete>
          <Button
            style={{ marginRight: "4px" }}
            onClick={() => setDeleteToggle(false)}
          >
            Cancel
          </Button>
          <Button color={colorSchema} variant="contained" onClick={onDelete} disabled={loading}>
            {loading ? <Loading white size={14} /> : null}  {buttonTitle}
          </Button>
        </ModalRowDelete>
      </ModalContentDelete>
    </Modal>
  );
};