//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createOnewayDebate = (returnedPayload) => ({ type: ActionTypes.oneway_debate.create_oneway_debate, payload: returnedPayload });

export const updateOnewayDebate = (returnedPayload) => ({ type: ActionTypes.oneway_debate.update_oneway_debate, payload: returnedPayload });

export const deleteOnewayDebate = (returnedPayload) => ({ type: ActionTypes.oneway_debate.delete_oneway_debate, payload: returnedPayload });

export const listOnewayDebate = (returnedPayload) => ({ type: ActionTypes.oneway_debate.list_oneway_debate, payload: returnedPayload });

