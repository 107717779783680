//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import _, { groupBy, sum, sumBy, filter, cloneDeep, find, sortBy, orderBy, map, flatMap, get , has , uniqBy } from "lodash";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import Loading from "components/Loading";
import { Popover, Menu, MenuItem } from "@material-ui/core"
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { handleListUserNotes } from "store/notes/thunks";
import { handleListUserQuestions } from "store/questions/thunks";
import { handleListUserSubmissions } from "store/submissions/thunks";
import { withStyles } from '@material-ui/core/styles';
import ReactHighcharts from "react-highcharts";
import moment from "moment";
import AssignmentStatusStudent from "components/AssignmentStatusStudent";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useCurrentOrgPermission, useOrgTimezone } from 'store/organizations/selectors';
import {
  NoAssignmentCompletedimg,
  NoAssignmentPastimg,
  NoAssignmentTodayimg,
  NoAssignmentWeekimg,
  BoxSettimgimg,
  Apimg
} from "utils/assets/images"
import { semeterDateByYear, getCurrenSem, isBeforTime } from "utils/methods/math"
import { useResources } from 'store/resources/selectors';
import { useServertime } from 'store/servertime/selectors';
import { useUserBookList } from "store/userBooks/selectors"
import "moment-timezone"
import { useComponentVisibleHook } from "utils/hooks"
import { useCurrentClass } from 'store/classes/selectors';
import { isVideo , getUserAssignment  } from "utils/lib/ebookHelper"
import { useExams } from "store/exams/selectors";
import { useStory } from "store/story/selectors";
import { useApExams } from "store/apexams/selectors";
import { useClassGameActivity } from "store/one_game/selectors";
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getBgColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
})

const config = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
    marginTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    spacingTop: 0,
    height: 160,
    animation: true,
    backgroundColor: "transparent",
  },
  title: {
    text: "",
    align: "center",
    verticalAlign: "middle",
    y: 0,

    style: {
      fontWeight: 200,
      fontSize: "24px",
      color: "#fff",
    },
  },
  tooltip: {
    enabled: true,
    formatter: function () {
      return `${this.key ?? ""}` + ":" + this.y + "%";
    },
  },
  plotOptions: {
    series: {
      animation: {
        duration: 2000,
      },
    },
    pie: {
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      point: {
        events: {
          legendItemClick: () => false,
        },
      },
      showInLegend: false,
      borderWidth: 0,
      colors: ["rgb(90,177,208)", "rgb(130,222,228)"],
    },
  },
  series: [
    {
      innerSize: "67%",
      data: [
        { name: "Overall Grade", y: 1 },
        { name: "", y: 1 },
      ],
    },
  ],
};

const DROPDOWN = [
  { value: "all", label: "All" },
  { value: "due_today", label: "Due today" },
  { value: "due_this_week", label: "Due this week" },
  { value: "due_past", label: "Past due" },
  { value: "completed", label: "Completed" },
  { value: "incompleted", label: "Incompleted" },
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Days = styled.span`
  color: white;
  display: block;
  font-size: 64px;
  font-weight: 600;
  line-height: 88px;
  text-align: center;

  @media (min-width: 992px) {
    font-size: 80px;
  }
`;
const Header = styled.span`
  color: ${COLOR.black300};
  display: block;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Section = styled.div`
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0px 15px rgb(0 0 0 / 85%), 0 0px 2px rgb(0 0 0 / 85%);
  flex-direction: row;
  height: ${(p) => `${p.height}px` || "100%"};
  margin-bottom: ${(p) => `${p.marginBottom}px`};
  padding: 16px;
  width: 100%;
`;

const Wrapper = styled.div`
  height: auto;
  margin-bottom: 40px;
  width: 100%;
`;

const SettingIcon = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: -20px;
`;
const Box = styled.div`
  -webkit-box-shadow: -2px 0px 7px 0px rgba(163, 150, 150, 0.48);
  -moz-box-shadow: -2px 0px 7px 0px rgba(163, 150, 150, 0.48);
  box-shadow: -2px 0px 7px 0px rgba(163, 150, 150, 0.48);
  height: 260px;
  width: 170px;
  padding-bottom: 20px;
  margin-left:5px;
  margin-right:5px;
  cursor: pointer;
  background-image: linear-gradient(
    to bottom,
    ${COLOR.indigo300},
    ${COLOR.indigo600},
    ${COLOR.indigo200},
    ${COLOR.indigo100},
    ${COLOR.indigo500}
  );
`;

const BoxRow = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const NoteTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  color: white;
  padding-top: 10px;
  text-transform: uppercase;
  white-space: pre-line;
  line-height: 20px;
`;
const NoteText = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: white;
  margin-left: auto;
  white-space: pre-line;
`;
const Column = styled.div`
  padding-bottom: 20px;
`;
const ContentWrapper = styled.div`
  height: ${(p) => (p.height ? p.height : "30%")};
`;
const OverallGrade = styled.div`
  height: 150px;
  width: 190px;
  border-radius: 50%;

`;
const OverallGradeSection = styled.div``;
const OverallGradeTitleSec = styled.div`
  background-color: ${COLOR.indigo300};
  min-height: 100px;
  flex-direction: row;
  display: flex;
`;
const OverallGradeTitle = styled.div`
  color: white;
  font-size: 28px;
  font-weight: 300;
  white-space: pre-line;
  margin-left: 60px;
`;
const SearchByTitle = styled(OverallGradeTitle)`
  font-size: 20px;
  font-weight: 500;
  margin-top: 8px;
  text-align: end;
  margin-right: 5px;
  margin-left:20px;
`;
const OverallGradeTitleWrapper = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;
const WrapperSec1 = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid ${COLOR.black400};
  border-bottom: 10px solid #03989e;
  margin-bottom: 0px;
`;
// const DropdownSec = styled.div`
//   background-color: ${COLOR.green100};
//   min-height: 50px;
//   width: 100%;
// `;
// const Dropdown = styled.button`
//   width: 200px;
//   height: 30px;
//   margin-top: 10px;
//   background-color: ${COLOR.white};
//   border-radius: 15px;
//   display: flex;
//   flex-direction: row;
//   padding-left: 10px;
//   padding-top: 5px;
//   border-width: 0px;
//   cursor: pointer;
// `;
// const DropdownTitle = styled.div`
//   color: ${COLOR.black300};
//   font-size: 12px;
//   font-weight: 600;
//   width: 150px;
// `;
// const DropdownIcon = styled.div`
//   margin-top: -2px;
// `;
// const DropdownContainer = styled.div`
//   display: flex;
//   position: relative;
//   max-width: 300px;
//   max-height: 500px;
//   overflow: auto;
//   overflow-x: hidden;
//   background: transparent;
// `
// const DropdownContent = styled.div`
//   display: flex;
//   min-height: 100px;
//   flex-direction: column;
//   justify-content: center;
//   width: 200px;
//   padding: 10px;
//   z-index:999999;
//   transition: display 2s;

// `
// const DropdownItem = styled.button`
//   background-color: ${COLOR.blueish100};
//   color: ${COLOR.white};
//   text-align:center;
//   border-radius: 10px;
//   height: 30px;
//   justify-content:center;
//   align-items:center;
//   margin-top:10px;
//   border-width:0px;
//   padding-left:10px;
//   padding-right:10px;
//   cursor: pointer;
// `
const DropdownSec = styled.div`
  background-color: ${COLOR.green100};
  min-height: 50px;
  width: 100%;
`;
const Dropdown = styled.button`
  width: 200px;
  height: 30px;
  margin-top: 10px;
  background-color: ${COLOR.white};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 5px;
  border-width: 0px;
  cursor: pointer;
`;
const DropdownTitle = styled.div`
  color: ${COLOR.black300};
  font-size: 12px;
  font-weight: 600;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
`;
const DropdownIcon = styled.div`
  margin-top: -2px;
`;
const DropdownContainer = styled.div`
  display: flex;
  position:relative;
  margin-right:16px;
`
const DropdownContent = styled.div`
  display: ${(p) => (p.show ? "flex" : "none")};
  position: absolute;
  top:50px;
  min-height: 100px;
  flex-direction: column;
  justify-content: center;
  z-index:999999;
  transition: display 2s;
    background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 5px;
    border-radius: 8px;
    padding-bottom: 11px;

  @media screen and (max-width: 680px) {
    right:0px;
  }
`
const DropdownItem = styled.button`
  background-color: ${COLOR.blueish100};
  color: ${COLOR.white};
  text-align:center;
  border-radius: 10px;
  height: 30px;
  justify-content:center;
  align-items:center;
  margin-top:10px;
  border-width:0px;
  padding-left:10px;
  padding-right:10px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: 280px;
  display: flex
`
const SectionContainer = styled.div`
  min-height:325px;
  width:100%;
  overflow-y:auto;
`
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  `
const NoAssignmentImg = styled.img`
  width: 100%;
  object-fit: cover;
  `
const DroDownMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  `
const Tab = styled.button`
    border: none;
    background-image: ${p => getBgColor(p.active)};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Admin class visuals
//
//  -- Description
//  Admin view class visuals
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcements data={array} />
//
const getKey = (sem, year) => (`${sem == 1 ? "semester 1" : "semester 2"} - ${year}`)

const CardItem = ({ title, value, isAplang, subValue, onClick = () => null }) => {
  return (
    <Box onClick={onClick}>
      <ContentWrapper height={isAplang ? "20%" : "30%"}>
        <NoteTitle>{title}</NoteTitle>
      </ContentWrapper>
      <ContentWrapper height={isAplang ? "15%" : "20%"}>
        <NoteText style={{ fontSize: isAplang ? 16 : 20 }}>{value}</NoteText>
      </ContentWrapper>
      <ContentWrapper height="50%" style={{ marginTop: isAplang ? 20 : 0 }}>
        <SettingIcon src={isAplang ? Apimg : BoxSettimgimg} style={{ width: isAplang ? "90%" : "100%" }} />
        {isAplang && <NoteText style={{ fontSize: 16 }}>{subValue}</NoteText>}
      </ContentWrapper>
    </Box>
  );
};
const StyledMenu = withStyles({
  paper: {
    border: '0px solid #d3d4d5',
  },
  root: {
    width: 200
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: ` ${COLOR.blueish100}`,
    color: `${COLOR.white}`,
    textAlign: 'center',
    borderRadius: ' 10px',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    borderWidth: '0px',
    paddingLeft: '10px',
    paddingRight: '10px',
    cursor: 'pointer',
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StudentVisuals = ({
  notes,
  questions,
  currentUser,
  submissions,
  allGraded,
  classActivities,
  classQuizzes,
  booksAllList,
  assignments,
  selectedUser
}) => {
  let activeCurrentUserId = currentUser?.id
  if (selectedUser && selectedUser.id) {
    activeCurrentUserId = selectedUser.id
  }
  const { class_game_activity } = useClassGameActivity()
  const assignment_data = useMemo(() => [...assignments, ...class_game_activity], [class_game_activity, assignments])
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSem = getCurrenSem()
  const resources = useResources()
  const servertime = useServertime()
  const timezone = useOrgTimezone()
  const exams = useExams();
  const apexams = useApExams();
  const story = useStory();
  const userBookList = useUserBookList()
  const allresourcesVideo = flatMap(map(resources, k => k.videos))
  const [loading, setLoading] = useState(true);
  const overChartRef = useRef(null);
  const [section, setSection] = useState("all");
  const [bookSelection, setBookSelection] = useState("all");
  const [pastDueQuizCount, setPastDueQuizCount] = useState(0);
  const [pastDueActivityCount, setPastDueActivityCount] = useState(0);
  const [completedQuizCount, setCompletedQuizCount] = useState(0);
  const [completedActivityCount, setCompletedActivityCount] = useState(0);
  const [pastDueAssignmentArr, setPastDueAssignment] = useState([]);
  const [completedAssignmentArr, setCompletedAssignment] = useState([]);
  const [incompletedAssignmentArr, setInCompletedAssignment] = useState([]);
  const [todayPastDueAssignmentArr, setTodayPastDueAssignment] = useState([]);
  const [weekPastDueAssignmentArr, setWeekPastDueAssignment] = useState([]);
  const [allAssignment, setAllAssignment] = useState([])
  const [showResponce, setShowResponce] = useState(-1);
  const [isdropdown, setIsDropdown] = useState(false);
  const activeFilterRef = useRef(null);
  const [activeSem, setActiveSem] = useState(currentSem);
  const [semAssignment, setSemAssignment] = useState({});
  const [activeSemData, setActiveSemData] = useState([]);
  const [semWiseNotes, setSemWiseNotes] = useState({})
  const [activeSemNotesLength, setActiveSemNotesLength] = useState(0)
  const orgPermissions = useCurrentOrgPermission()
  const bookPermissions = _.get(orgPermissions, "books", [])
  const [filteredAssignments, setFilteredAssignments] = useState([])
  const [filterRef, isFilterVisible, setIsFilterVisible] = useComponentVisibleHook(false);
  const [bookfilterRef, isBookFilterVisible, setIsBookFilterVisible] = useComponentVisibleHook(false);
  const currentClass = useCurrentClass()

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let gradebookUserId = localStorage.getItem("gradebookUserId");
      if (
        (gradebookUserId && gradebookUserId === activeCurrentUserId) ||
        (!activeCurrentUserId && gradebookUserId === "nocurrentuser")
      ) {
        setLoading(false);
      }
      if (activeCurrentUserId) {
        await Promise.allSettled([
          dispatch(handleListUserNotes(activeCurrentUserId)),
          dispatch(handleListUserQuestions(activeCurrentUserId)),
          dispatch(handleListUserSubmissions(activeCurrentUserId))
        ])
        localStorage.setItem(
          "gradebookUserId",
          activeCurrentUserId ?? "nocurrentuser"
        );
      }
      setLoading(false);
    }
    fetchData();
  }, [currentUser, dispatch]);
  const getWeightedGrade = (grade, weight) => {
    if (weight === 0) {
      return 0;
    }
    return (grade) * weight / 100;
  };
  const getGrade = (submission) => {
    let isTbgObj = find(get(submission, "assignment.questions"), (e) => e.type != "multipleChoice")
    return submission?.manualGrading && typeof isTbgObj == "object" ? "TBG" : (submission?.grade ? submission?.grade : 0)
  }
  const setOverAllChartData = () => {
    let _assignments = 0;
    let total = 0;
    activeSemData.map((item) => {
      let grade = getGrade(item.submission)
      if (!isNaN(parseInt(grade))) {
        _assignments++;
        let weightage = parseInt(item.weightage) || 100
        let _grd = getWeightedGrade(parseInt(grade, 10), weightage)
        total += parseInt(_grd, 10);
      }
    })
    let grade = (parseInt(total, 10) / parseInt(_assignments, 10)).toFixed(0);
    grade = isNaN(grade) ? 0 : grade
    const remain = 100 - grade;
    let conf = config;
    conf.series[0].data = [
      { name: "Overall Grade", y: parseInt(grade, 10) },
      { name: "", y: remain },
    ];
    conf.title.text = `${grade ?? ""}%`;
    if (overChartRef.current) {
      overChartRef.current.chart.update(conf);
    }
  };
  const sortByDueDate = (arr) => {
    return orderBy(arr, ["dueDate"], ["desc"]);
  }
  useEffect(() => {
    filterAssignmentsBasedOnsem()
  }, [activeSemData, activeSem, semAssignment])
  const getAssignmentDate = (assignment) => {
    if (assignment) {
      if (assignment.lateDate && assignment.lateDate.length > 0) {
        return assignment.lateDate
      } else {
        return assignment.dueDate
      }
    } else {
      return null;
    }
  }
  const filterCompletedAssignment = (assignment) => {
    if (!assignment) return false;
    return (assignment.submission && assignment.submission.complete && !assignment.retaking)
  }
  const checkNotCompleted = (assignment) => {
    return (get(assignment, "submission.submissions", 0) <= get(assignment, "retakes", 0) || get(assignment, "submission.retaking")) && !(get(assignment, "submission.submissions", 0) == 0 && !get(assignment, "submission.retaking"))
  }
  const filterPastdueAssignment = (assignment) => {
    if (!assignment) return false;
    const isCompleted = has(assignment, "submission.complete") && get(assignment, "submission.complete", true);
    return !!(isBeforTime(assignment.dueDate, servertime, timezone) && !isCompleted);
  }
  const filterInCompleteAssignment = (assignment) => {
    if (!assignment) return false;
    // const isNotCompleted = checkNotCompleted(assignment)
    return (!isBeforTime(getAssignmentDate(assignment), servertime, timezone) && (get(assignment, "submission.submissions", 0) == 0 || get(assignment, "submission.retaking")));
  }
  const filterTodayAssignment = (assignment) => {
    if (!assignment) return false;
    const _lateDate = get(assignment, "lateDate")
    const _dueDate = get(assignment, "dueDate")
    const isCompleted = has(assignment, "submission.complete") && get(assignment, "submission.complete", true);
    return (moment(_lateDate).isSame(moment(), "days") || moment(_dueDate).isSame(moment(), "days")) && !isCompleted
  }
  const filterWeekAssignment = (assignment) => {
    if (!assignment) return false;
    const _lateDate = get(assignment, "lateDate")
    const _dueDate = get(assignment, "dueDate")
    const isCompleted = has(assignment, "submission.complete") && get(assignment, "submission.complete", true);
    return (moment(_lateDate).isSame(moment(), "week") || moment(_dueDate).isSame(moment(), "week")) && !isCompleted
  }
  const filterAssignmentsBasedOnsem = () => {
    const assignmentWithSubmission = semAssignment[activeSem]
    setAllAssignment(assignmentWithSubmission)
    setFilteredAssignments(assignmentWithSubmission)
    const pastDueAssignment = sortByDueDate(filter(assignmentWithSubmission, filterPastdueAssignment));
    const completedAssignment = sortByDueDate(filter(assignmentWithSubmission, filterCompletedAssignment));
    const todayPastDueAssignment = sortByDueDate(filter(assignmentWithSubmission, filterTodayAssignment));
    const weekPastDueAssignment = sortByDueDate(filter(assignmentWithSubmission, filterWeekAssignment));
    const _pastDueQuizCount = pastDueAssignment.filter(assignment => assignment.data?.typeId === "quiz").length;
    const _pastDueActivityCount = pastDueAssignment.filter(assignment => assignment.data?.typeId === "activity").length;
    const _completeQuizCount = completedAssignment.filter(assignment => assignment.data?.typeId === "quiz").length;
    const _completeActivityCount = completedAssignment.filter(assignment => assignment.data?.typeId === "activity").length;
    const _incompletedAssignmentArr = sortByDueDate(filter(assignmentWithSubmission, filterInCompleteAssignment));
    setPastDueAssignment(pastDueAssignment);
    setCompletedAssignment(completedAssignment);
    setWeekPastDueAssignment(weekPastDueAssignment);
    setTodayPastDueAssignment(todayPastDueAssignment);
    setPastDueActivityCount(_pastDueActivityCount);
    setPastDueQuizCount(_pastDueQuizCount);
    setCompletedActivityCount(_completeActivityCount);
    setCompletedQuizCount(_completeQuizCount);
    setInCompletedAssignment(_incompletedAssignmentArr)
  }
  useEffect(() => {
    const userAssignment = getUserAssignment(get(currentClass, "books"), booksAllList, assignment_data, currentUser, bookPermissions, currentClass)
    const withSubmission = map(userAssignment, assignment => {
      const is_game = assignment.type === "game"
      const is_debate = assignment.type === "debate"
      const chapter_id = is_game ? get(assignment, "assignment.chapter") : get(assignment, "data.chapter")
      const assignmentId = is_game ? get(assignment, "session_id") : get(assignment, "data.id")
      let book = find(booksAllList, k => k.id == chapter_id)
      try {
        if (!book) {
          book = find(allresourcesVideo, { "id": chapter_id })
          if (!book && isVideo(get(assignment, "data.chapter"), resources)) {
            book = find(allresourcesVideo, { "id": chapter_id?.substring(0, 36) })
          }
        }
      }
      catch (error) {

      }
      const submi = find(submissions, ['assignmentId', assignmentId])

      if (submi && (!submi.assignment || Object.keys(submi.assignment).length === 0)) {
        submi.assignment = assignment.assignment;
      }
      return ({
        ...assignment,
        submission: find(submissions, ['assignmentId', assignmentId]),
        data: is_game || is_debate ? assignment.assignment : assignment.data,
        book: book ? book : {}
      })
    })
    let assignmentWithSubmission = filter(withSubmission, "book");
    let yearData = groupBy(assignmentWithSubmission, x => moment(x.dueDate).format("YYYY"))
    const semData = {}
    Object.keys(yearData).map(year => {
      const { sem1StartDate, sem1EndDate, sem2StartDate, sem2EndDate } = semeterDateByYear(year)
      const sem1 = yearData[year].filter(x => moment(x.dueDate).isSameOrAfter(sem1StartDate, "day") && moment(x.dueDate).isSameOrBefore(sem1EndDate, "day"))
      const sem2 = yearData[year].filter(x => moment(x.dueDate).isSameOrAfter(sem2StartDate, "day") && moment(x.dueDate).isSameOrBefore(sem2EndDate, "day"))

      if (sem1.length > 0 || currentSem === getKey(1, year)) {
        semData[getKey(1, year)] = sem1
      }
      if (sem2.length > 0 || currentSem === getKey(2, year)) {
        semData[getKey(2, year)] = sem2
      }
    })
    if (semData[currentSem]) {
      setActiveSemData(semData[currentSem])
    }
    if (Object.keys(semData).length > 0) {
      let sem_data = {};
      const current_year = moment().format("YYYY");
      const prevYear = parseInt(current_year) - 1;
      
      const semesters = {
        currentYearSem1: getKey(1, current_year),
        currentYearSem2: getKey(2, current_year),
        prevYearSem1: getKey(1, prevYear),
        prevYearSem2: getKey(2, prevYear)
      };
      
      const addSemData = (key) => {
        if (semData[key]) {
          sem_data[key] = semData[key];
          return true;
        }
        return false;
      };
      
      sem_data[currentSem] = semData[currentSem];
      
      if (currentSem === semesters.currentYearSem1) {
        addSemData(semesters.currentYearSem2) || 
        addSemData(semesters.prevYearSem1) || 
        addSemData(semesters.prevYearSem2);
      } else if (currentSem === semesters.currentYearSem2) {
        addSemData(semesters.currentYearSem1) || 
        addSemData(semesters.prevYearSem1) || 
        addSemData(semesters.prevYearSem2);
      }
      
      setSemAssignment(sem_data);
    }


  }, [assignment_data, submissions, resources]);
  useEffect(setOverAllChartData, [activeSemData, activeSem]);
  useEffect(() => {
    let yearData = groupBy(notes, x => moment(parseInt(x.createdAt)).format("YYYY"))
    const semNotes = {}
    Object.keys(yearData).map(year => {
      const { sem1StartDate, sem1EndDate, sem2StartDate, sem2EndDate } = semeterDateByYear(year)
      const sem1 = yearData[year].filter(x => moment(parseInt(x.createdAt)).isSameOrAfter(sem1StartDate, "day") && moment(parseInt(x.createdAt)).isSameOrBefore(sem1EndDate, "day"))
      const sem2 = yearData[year].filter(x => moment(parseInt(x.createdAt)).isSameOrAfter(sem2StartDate, "day") && moment(parseInt(x.createdAt)).isSameOrBefore(sem2EndDate, "day"))

      if (sem1.length > 0 || currentSem === getKey(1, year)) {
        semNotes[getKey(1, year)] = sem1
      }
      if (sem2.length > 0 || currentSem === getKey(2, year)) {
        semNotes[getKey(2, year)] = sem2
      }
    })
    if (!semNotes[currentSem]) {
      semNotes[currentSem] = []
    }
    setActiveSemNotesLength(semNotes[currentSem]?.length ?? 0)
    setSemWiseNotes(semNotes)
  }, [notes])

  const todayDate = moment();
  const apExamLAN = moment('25/05/2025', "DD/MM/YYYY");
  const apExamLIT = moment('16/05/2025', "DD/MM/YYYY");
 
  const getTitle = () => {
    const obj = find(DROPDOWN, ["value", section])
    if (obj) return obj.label
    return "All";
  };

  const bookForFilter = useMemo(() => {
    let allBook = [
      { bookId: "all", description: "All" },
      { bookId: "story", description: "Story" },
      { bookId: "ap", description: "AP Exams" },
      { bookId: "exams", description: "Exams" }
    ];
    allBook = _.concat(allBook, _.get(currentClass, 'books', userBookList));
    const dataBookIds = _(booksAllList)
      .filter(book => _.some(allBook, { bookId: book.id }) && (book.onlyTeacher || book.isNotAssignable))
      .map('id')
      .value();
    const filterBookId = ['13', '14', ...dataBookIds];
    const filteredBooks = _.reject(allBook, book => _.includes(filterBookId, book.bookId));
    const enrichedBooks = _.map(filteredBooks, book => {
      const bookDetail = _.find(booksAllList, { id: book.bookId });
      return bookDetail ? { ...book, description: bookDetail.description } : book;
    });
    return _.uniqBy(enrichedBooks, 'bookId');
  }, [currentClass, booksAllList, userBookList]); 
  
  const getTitleBookFilterTitle = () => {
    const bookData = find(bookForFilter, ["bookId", bookSelection])
    if (bookData) {
      return get(bookData, "description", '')
    }
    return "All";
  };
  const filterdBasedOnBook = (arr, bookVal) => {
    if (bookVal == "all" || !bookVal) {
      return arr;
    } else if (bookVal === 'ap') {
      return filter(arr, k => apexams?.flatMap(k => k.exams).map(n => `${n.bookId}`).includes(get(k, "data.bookId")))
    } else if (bookVal === "story") {
      return filter(arr, k => story?.map(n => `${n.bookId}`).includes(get(k, "data.bookId")))
    } else if (bookVal === "exams") {
      return filter(arr, k => exams?.flatMap(k => k.exams).map(n => `${n.bookId}`).includes(get(k, "data.bookId")))
    }
    else {
      const arrval = filter(arr, k => get(k, "data.bookId") == bookVal ||   get(k, "data.chapter")== bookVal)
      return arrval;
    }
  }
  const onselectionchange = (value, bookVal,type) => {
    setShowResponce(-1)
    if(type=="book"){
      setIsBookFilterVisible(false)
      setBookSelection(bookVal)
    }else{
      setIsFilterVisible(false)
      setSection(value);
    }
    setIsDropdown(!isdropdown);
    activeFilterRef.current = "all";
    const obj = find(DROPDOWN, ["value", value])
    if (obj||type=="book") {
      switch (value) {
        case 'all':
          setFilteredAssignments(filterdBasedOnBook(allAssignment,bookVal))
          break;
        case 'due_today':
          setFilteredAssignments(filterdBasedOnBook(todayPastDueAssignmentArr,bookVal))
          break;
        case 'due_this_week':
          setFilteredAssignments(filterdBasedOnBook(weekPastDueAssignmentArr,bookVal))
          break;
        case 'due_past':
          setFilteredAssignments(filterdBasedOnBook(getPastDueAssignment(),bookVal))
          break;
        case 'completed':
          setFilteredAssignments(filterdBasedOnBook(getCompletedAssignment(),bookVal))
          break;
        case 'incompleted':
          setFilteredAssignments(filterdBasedOnBook(getInCompletedAssignment(),bookVal))
          break;
        default:
          setFilteredAssignments(filterdBasedOnBook(allAssignment,bookVal))
          break;
      }
    } else {
      setFilteredAssignments(filterdBasedOnBook(allAssignment,bookVal))
    }
  }
  const getNoAssignmentBg = (value) => {
    switch (value) {
      case 'all':
        return NoAssignmentTodayimg
      case 'due_today':
        return NoAssignmentTodayimg
      case 'due_this_week':
        return NoAssignmentWeekimg
      case 'due_past':
        return NoAssignmentPastimg
      case 'completed':
        return NoAssignmentCompletedimg
      case 'incompleted':
        return NoAssignmentTodayimg
      default:
        return NoAssignmentTodayimg
    }
  }

  const getPastDueAssignment = () => {
    if (activeFilterRef.current == "all") {
      return pastDueAssignmentArr;
    } else if (activeFilterRef.current == "quiz") {
      return pastDueAssignmentArr.filter(assignment => assignment.data?.typeId === "quiz");
    }
    else if (activeFilterRef.current == "activity") {
      return pastDueAssignmentArr.filter(assignment => assignment.data?.typeId === "activity");
    }
    else {
      return pastDueAssignmentArr;
    }
  }
  const getCompletedAssignment = () => {
    if (activeFilterRef.current == "all") {
      return completedAssignmentArr;
    } else if (activeFilterRef.current == "quiz") {
      return completedAssignmentArr.filter(assignment => assignment.data?.typeId === "quiz");
    }
    else if (activeFilterRef.current == "activity") {
      return completedAssignmentArr.filter(assignment => assignment.data?.typeId === "activity");
    }
    else {
      return completedAssignmentArr;
    }
  }
  const getInCompletedAssignment = () => {
    if (activeFilterRef.current == "all") {
      return incompletedAssignmentArr;
    } else if (activeFilterRef.current == "quiz") {
      return incompletedAssignmentArr.filter(assignment => assignment.data?.typeId === "quiz");
    }
    else if (activeFilterRef.current == "activity") {
      return incompletedAssignmentArr.filter(assignment => assignment.data?.typeId === "activity");
    }
    else {
      return incompletedAssignmentArr;
    }
  }
  const renderGrades = useMemo(() => {
    if (filteredAssignments && filteredAssignments.length > 0) {
      const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId) || [];
      return (map(filteredAssignments, (assignment, i) => {
        if(get(currentUser,"role")=='student' && getHiddenBooks?.includes(assignment?.book?.id)){
          return null
        }
        return (
          <AssignmentStatusStudent
          key={assignment.id}
          chapter={get(assignment, "data.chapter")}
          data={assignment}
          submission={get(assignment, "submission")}
          showResponce={showResponce == i}
          onShowResponce={() => setShowResponce(showResponce == i ? -1 : i)}
          book={get(assignment, "book")}
          booksAllList={booksAllList}
          currentClass={currentClass}
        />
        )
      }
       
      ))
    }
    return <NoAssignmentImg src={getNoAssignmentBg(section)} />
  }, [filteredAssignments,showResponce,booksAllList])
  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          {Object.keys(semAssignment).map((key, index) => {
            return (
              <Tab
                active={key == activeSem} key={key}
                onClick={() => {
                  setActiveSem(key)
                  setActiveSemData(semAssignment[key])
                  setActiveSemNotesLength(semWiseNotes[key]?.length ?? 0)
                }}>
                {key}
              </Tab>)
          })}
          <Row>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 14 }}
              style={{ marginBottom: 20 }}
            >
              <WrapperSec1>
                <OverallGradeSection>
                  <OverallGradeTitleSec>
                    <OverallGrade>
                      <ReactHighcharts config={config} ref={overChartRef} />
                    </OverallGrade>
                    <OverallGradeTitleWrapper>
                      <OverallGradeTitle>
                        {"This is your overall \ngrade for this class"}
                      </OverallGradeTitle>
                    </OverallGradeTitleWrapper>
                  </OverallGradeTitleSec>
                  <DropdownSec>
                    <Row className="srchByData">
                      <SearchByTitle className="srchByDataTitle">Search by</SearchByTitle>
                      <div className="srchByDataRght">
                      <DropdownContainer ref={filterRef}>
                        <Dropdown onClick={()=>{
                          setIsFilterVisible(!isFilterVisible)
                          setIsDropdown(!isdropdown)
                        }}>
                          <DropdownTitle>{getTitle()}</DropdownTitle>
                          <DropdownIcon>
                            {isdropdown &&isFilterVisible  ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </DropdownIcon>
                        </Dropdown>
                        <DropdownContent show={isFilterVisible}>
                          {DROPDOWN.map((x) => (<DropdownItem key={x.value} onClick={() => onselectionchange(x.value,bookSelection)}>{x.label}</DropdownItem>))}
                        </DropdownContent>
                      </DropdownContainer>
                      <DropdownContainer ref={bookfilterRef}>
                        <Dropdown onClick={()=>{
                          setIsBookFilterVisible(!isBookFilterVisible)
                          setIsDropdown(!isdropdown)
                        }}>
                          <DropdownTitle>{getTitleBookFilterTitle()}</DropdownTitle>
                          <DropdownIcon>
                            {isdropdown && isBookFilterVisible ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </DropdownIcon>
                        </Dropdown>
                        <DropdownContent show={isBookFilterVisible}>
                          {map(bookForFilter, (x) => (<DropdownItem key={x.bookId} onClick={() => onselectionchange(section, x.bookId,"book")}>{get(x, "description")}</DropdownItem>))}
                        </DropdownContent>
                      </DropdownContainer>
                      </div>
                    </Row>
                  </DropdownSec>
                </OverallGradeSection>
                <SectionContainer>
                  <SectionContent>
                   {renderGrades}
                  </SectionContent>
                </SectionContainer>
              </WrapperSec1>

            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 10 }}
              style={{ paddingLeft: 20 }}
            >
              <BoxRow>
                <Column>
                  <CardItem
                    title={"Notes\ntaken"}
                    value={activeSemNotesLength}
                    onClick={() => {
                      history.push('/notes')
                    }}
                  />
                </Column>
                <Column>
                  <CardItem
                    title={"Quizzes\n completed"}
                    value={completedQuizCount}
                    onClick={() => {
                      activeFilterRef.current = "quiz"
                      onselectionchange("completed",bookSelection)
                    }}
                  />
                </Column>
                <Column>
                  <CardItem
                    title={"Quizzes\n past due"}
                    value={pastDueQuizCount}
                    onClick={() => {
                      activeFilterRef.current = "quiz"
                      onselectionchange("due_past",bookSelection)
                    }}
                  />
                </Column>
              </BoxRow>
              <BoxRow>
                <Column>
                  <CardItem
                    title={"Activities\n completed"}
                    value={completedActivityCount}
                    onClick={() => {
                      activeFilterRef.current = "activity"
                      onselectionchange("completed",bookSelection)

                    }}
                  />
                </Column>
                <Column>
                  <CardItem
                    title={"Activities\n past due"}
                    value={pastDueActivityCount}
                    onClick={() => {
                      activeFilterRef.current = "activity"
                      onselectionchange("due_past",bookSelection)
                    }}
                  />
                </Column>
                <Column>
                  <CardItem
                    title={"Days until AP  Exam"}
                    value={`${apExamLAN.diff(todayDate, "days")} - AP\n SPAN LAN`}
                    subValue={`${apExamLIT.diff(todayDate, "days")} - AP\n SPAN LIT`}
                    isAplang={true}
                  />
                </Column>
              </BoxRow>
            </Col>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

//  -- Prop types ---------------------
StudentVisuals.propTypes = {};

//  -- Default props ------------------
StudentVisuals.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  notes: state.notes,
  questions: state.questions,
  currentUser: state.currentUser,
  submissions: state.submissions,
  booksAllList: state.booksAllList,
  selectedUser: state.selectedUser,
});

export default connect(mapStateToProps, null)(StudentVisuals);
