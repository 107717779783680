//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {filter} from "lodash"
//  -- Components ---------------------
import { ArrowForward, ArrowRight, MenuBook } from '@material-ui/icons';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Thunks -------------------------
import { handleGetClass } from 'store/classes/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Bold = styled.span`
    font-weight: 600;
`;

const Button = styled.button`
    align-items: center;
    background-color: transparent;
    border: 1px dashed ${COLOR.black700};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;
    outline: none;
    padding: 6px 8px;
    width: 100%;
`;

const Class = styled.button`
    background-color: ${COLOR.black900};
    border: 1px solid ${COLOR.black800};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 6px;
    outline: none;
    padding: 8px 10px;
    text-transform: uppercase;
    width: 100%;
`;

const Container = styled.div`
    height: ${p => `${p.height}px`};
    overflow: hidden;
    transition: height 300ms ease;
    width: 100%;
`;

const Count = styled.span`
    font-size: 11px;
    text-transform: uppercase;
`;

const Rotate = styled.div`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;

const Wrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const Wrapper = styled.div`
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Todo
//
//  -- Description
//  ...
//
//  -- Props
//  ...
//
const TeacherClass = ({ typeView, classes, teacher,usersStudents }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [height, setHeight] = useState(0);
    const [open, setOpen] = useState(false);
    const [totalStudents, setTotalStudents] = useState(false);

    useEffect(() => {
        setHeight((classes.length * 39) + 8);
    }, [classes.length]);

    useEffect(() => {
        async function fetchData() {
            let total = 0;
            for(var i in classes){
                let userForClass = await dispatch(handleListClassUsers(classes[i].id));
                if(userForClass != undefined){
                    total += filter(userForClass,x => x.role == "student").length;
                }
            }
            setTotalStudents(total);
        }
        fetchData();
    }, []);

    const goToClass = async (id) => {
        if(typeView != 'director'){
            await dispatch(handleGetClass(id));
            history.push('/admin/class?view=manage')
        }
    };

    return (
        <Wrapper>
            <Button onClick={() => setOpen(!open)}>
                <Wrap>
                    <Rotate open={open}>
                        <ArrowRight />
                    </Rotate>
                    <span>{teacher.firstName} {teacher.lastName}</span>
                </Wrap>
            <Count># of Students: <Bold>{totalStudents}</Bold></Count>
            </Button>
            <Container height={open ? height : 0}>
                {classes.map(x => (
                    <Class key={x.id} onClick={() => goToClass(x.id)}>
                        <Wrap>
                            <MenuBook style={{ fontSize: '14px', marginRight: '4px', marginTop: '-2px' }} />
                            {x.displayName}
                        </Wrap>
                        { typeView != 'director' && (
                            <Wrap>
                                Go To Class
                                <ArrowForward style={{ fontSize: '11px', marginLeft: '8px' }} />
                            </Wrap>
                        )}
                    </Class>
                ))}
            </Container>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
TeacherClass.propTypes = {};

//  -- Default props ------------------
TeacherClass.defaultProps = {};

export default TeacherClass;