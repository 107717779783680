//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    Checkbox,
    FormControlLabel,
    Modal,
    Switch, TextField ,
    ListItemText,
    MenuItem,
    FormControl,
    Select,
    InputLabel
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowForward, Delete, Star } from '@material-ui/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import Tag from 'components/Tag';
import { filter, get, map , find } from "lodash";
import moment from 'moment';
import { func, shape, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
//  -- Constants ----------------------
import {
    DateTimePicker,
} from "@material-ui/pickers";
import AssignmentClassConfig from "components/assignmentClassConfig";
import { handleDeleteCanvasAssignment } from "store/canvas/thunks";
import { handleDeleteClassQuiz } from 'store/classQuizzes/thunks';
import { handleDeleteGoogleAssignment } from 'store/googleClassRoom/thunks';
import { useResources } from 'store/resources/selectors';
import { useSchoologyAssignment, useSchoologyGradeCategory } from "store/schoologyauth/selectors";
import Schoology from 'utils/Schoology';
import { NEWSLETTERS  , GRADE_TYPE_ALL , GRADE_TYPE_CANVAS} from 'utils/config/reference';
import COLOR from 'utils/constants/color';
import { imageFetchDinamic, imageFetchFromPodcast, isVideo, newsImageFetch } from 'utils/lib/ebookHelper';
import { isBeforTime , getCurrentSemStartAndEndDate, isAfterTime } from 'utils/methods/math';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Acent from 'components/Acent';
import CustomDateTimePicker from "components/DateTimePicker"
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Container = styled.div`
    border-top: 1px solid ${COLOR.black700};
    margin-top: 24px;
    padding-top: 40px;
    width: 100%;
`;

const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 25px;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Note = styled.span`
    color: ${COLOR.black400};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
    margin: 0px auto;
    width: 100%;
    position: relative;
    top: 0px;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const Image = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContiner = styled.div`
    background-color: transparent;
    border: none;
    flex: 1;
    outline: none;
    width: 100%;
    height:100%;
    margin-right: 16px;
    padding-right: 10px;
`;
const ClassNameText = styled.div`
   font-size:18px;
   font-weight:bold;
   text-align: left;
   text-transform: uppercase;
`;
const ModalInnerWrapper = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 450px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow:scroll;
`;
const ClassConfigContainer = styled.div`
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
`;
const useStyles = makeStyles((theme) => ({
    root: {
        width: 200,
        height:60
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "fit-content"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: 120
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, studentsName, theme) {
    return {
        fontWeight:
            studentsName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Quiz form
//
//  -- Description
//  Create / edit quiz questions
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <QuizForm
//      data={data}
//      handleChange={func}
//      handleSubmit={func}
//  />
//

const mapToUserClass = (userClass, classArray) => {
    return filter(userClass, (x => classArray.includes(x.displayName)))
}
const QuizForm = ({
    listStudents,
    data,
    handleChange,
    handleChangeLevel,
    handleSubmit,
    handleChangeMultiSelect,
    studentsName,
    studentsError,
    diffSection,
    setDiffSection,
    lateSection,
    setlateSection,
    setDurationSection,
    durationSection,
    loading,
    userClasses,
    currentClass,
    onClose,
    booksAllList,
    allAssignOnce,
    onDelete,
    currentUser
}) => {
    const classes = useStyles();


    const dispatch = useDispatch();
    const resources = useResources()
    const [deleteClassAssignedQuizToggle, setdeleteClassAssignedQuizToggle] = React.useState(false);
    const [dataLoading, setDataLoading] = React.useState(false);
    const [submissionLoading, setSubmissionLoading] = React.useState(false);
    const schoologyAuth = useSelector(state => state.schoologyAuth)
    const client = new Schoology(schoologyAuth)
    const [initialDueDate, setInitialDueDate] = useState(null)
    const [initialLateDate, setInitialLateDate] = useState(null)
    const isSchoologyUser = currentUser && currentUser.schoologyid ? true : false;
    const isCanvas_user = currentUser && currentUser.canvas_id;
    const [schoologyGradeCategory, , schoologyAllClassGradeCategory] = useSchoologyGradeCategory()
    const prevData = useRef()
    const [, listSchoologyAssignment] = useSchoologyAssignment()
    const [initialData,setInitialData] = useState(null)
    const currentSemDateAndTime = getCurrentSemStartAndEndDate()
    const [openAlert, setAlert] = React.useState({ open: false, message: "" });
    const [errorForDateConfig, setErrorForDateConfig] = useState({
        lateDate: null,
        dueDate: null,
        dueDateDif: null,
        classCnfError: []
    })
    const isEditing = get(data, "editing", false)
    const dispNameRef = useRef()
    if (data && data.lateDate == "null") {
        data.lateDate = null;
    }
    useEffect(() => {
        if (data) {
            const todayDtime = moment().toISOString()
            const nextDtime = moment().add(1, "day").toISOString()
            const dueDate = get(data, "dueDate")
            const scheduleAt = get(data, "scheduleAt")
            if (!dueDate) {
                handleChange({ target: { id: "dueDate", value: nextDtime } })
            }
            if (!scheduleAt) {
                handleChange({ target: { id: "scheduleAt", value: todayDtime } })
            }
            if (currentClass && get(data, "classes.length", 0) == 0) {
                const v = {
                    target: {
                        id: "classes", value: [{
                            displayName: `${currentClass.displayName} (${currentClass.code})`,
                            id: currentClass.id,
                            dueDate: data.dueDate ? data.dueDate : nextDtime,
                            scheduleAt: data.scheduleAt ? data.scheduleAt : todayDtime,
                            sectionId: currentClass.sectionId,
                            schoologyCategory: data.schoologyCategory,
                            diff:{
                                id: currentClass.id,
                                retakesDif:0,
                                levelExercise: 'hard',
                                dueDateDif: moment().add(1, 'day').toISOString(),
                                scheduleAtDiff: moment().toISOString(),
                                student: []
                            }
                        }]
                    }
                }
                handleChange(v)
            }
            if (get(data, "editing")) {
                if(!initialData||get(initialData, "id") !== get(data, "id")){
                    setInitialData(data)
                 }
                if (get(prevData.current, "id") === get(data, "id")) {
                    if (!initialDueDate) {
                        setInitialDueDate(get(data, "dueDate"))
                    }
                    if (!initialLateDate) {
                        if (isBeforTime(get(data, "lateDate"), get(data, "dueDate"))) {
                            setInitialLateDate(null)

                        } else {
                            setInitialLateDate(get(data, "lateDate"))
                        }
                    }

                } else {
                    setInitialDueDate(get(data, "dueDate"))
                    setInitialLateDate(get(data, "lateDate"))
                }

            } else {
                setInitialData(null)
                setInitialDueDate(null)
                setInitialLateDate(null)
            }
        }
        prevData.current = data
    }, [data])


    const getImages = React.useCallback(() => {
        let _chapter = data.chapter ? data.chapter : data?.data?.chapter;
        if ((_chapter == 36 || _chapter == 35 || _chapter == 31 || _chapter == 50 || _chapter == 51) && allAssignOnce) {
            return imageFetchDinamic(_chapter, 1, booksAllList)?.resource
        }
        let _page = data.page ? data.page : data?.data?.page;
        let url = NEWSLETTERS[_chapter] ? newsImageFetch(_chapter, _page)?.src : imageFetchFromPodcast(_chapter, resources) ? imageFetchFromPodcast(_chapter, resources) : imageFetchDinamic(_chapter, _page, booksAllList)?.resource
        return url;
    })
    const levels = [
        {
            id: '1',
            value: 'easy',
            label: '2'
        },
        {
            id: '2',
            value: 'medium',
            label: '3'
        },
        {
            id: '3',
            value: 'hard',
            label: '4'
        }
    ]
    const onSubmit = (e) => {
        if (isNaN(parseInt(data?.timeDuration))) {
            let v = { target: { id: "timeDuration", value: 0 } }
            handleChange(v)
        }
        if (!submissionLoading && !studentsError) {
            setSubmissionLoading(true);
        }
        handleSubmit(e);
    };
    useEffect(() => {
        const isNotLoading = !studentsError ? loading : false
        setSubmissionLoading(isNotLoading)
    }, [loading, studentsError])
    const deleteClassQuiz = async (e) => {
        e.preventDefault();
        setDataLoading(true);
        if (onDelete) {
            onDelete(onDeleteSuccess)
            return;
        }
        await dispatch(handleDeleteClassQuiz(
            data.quizId,
            data.classId
        ));
        if (data && data.sAssignmentId && currentClass.sectionId) {
            await client.setToken(schoologyAuth);
            await client.deleteAssignment(currentClass.sectionId, data.sAssignmentId)
            listSchoologyAssignment(currentClass.sectionId)
        }
        if(currentClass.googleId&&data.googleId){
            await handleDeleteGoogleAssignment(currentUser.googleId,currentClass.googleId,data.googleId)
          }
        if(data && data.canvas_id){
            try {
                await handleDeleteCanvasAssignment(currentUser.canvas_id,[{
                    canvas_id: data.canvas_id,
                    canvas_section_id:currentClass.canvas_id,
                    canvas_course_id:currentClass.canvas_course_id
                }],currentUser.canvas_auth_id);
            } catch (error) {
                
            }
        }
        onDeleteSuccess()
    };
    const onDeleteSuccess = () => {
        setdeleteClassAssignedQuizToggle(false);
        setDataLoading(false);
        onClose && onClose();
    }
    const updateClassConfig = (i, key, value) => {
        const v = map(data.classes, (k) => {
            if (k.id === i) {
                k[key] = value;
                return k;
            } else {
                return k;
            }
        })
        let val = { target: { id: "classes", value: v } }
        handleChange(val)
    }
    const dateTimeSet = (key, value) => {
        try {
            let v = value.toISOString()
            handleChange({ target: { id: key, value: v } })
        } catch (error) {

        }
    }
    const getMinDate = (date1, date2) => {
        if (date1 && date2) {
            return isBeforTime(date1, date2) ? moment(date2).add(1, "minute").toISOString() : moment(date1).add(1, "minute").toISOString();
        } else if (date1 || date2) {
            return date1 ? moment(date1).add(1, "minute").toISOString() : moment(date2).add(1, "minute").toISOString();
        } else {
            return moment().toString()
        }
    }
    const hasClassConfigError = () => {
        const v = errorForDateConfig.classCnfError.filter(k => Object.values(k).filter(v => v).length > 0)
        return v.length > 0
    }
    const hasSubmitbuttonDeactive = () => {
        if (!data.dueDate || errorForDateConfig.dueDate) return true;
        if (isSchoologyUser && schoologyGradeCategory.length == 0) return true;
        // else if (diffSection && (!data.dueDateDif || errorForDateConfig.dueDateDif)) return true;
        // else if (diffSection && (!data.scheduleAtDiff || errorForDateConfig.scheduleAtDiff)) return true;
        // else if (diffSection && (data.dueDateDif&&data.scheduleAtDiff&&moment(data.dueDateDif).isBefore(data.scheduleAtDiff,"minute"))) return true;
        else if (get(data, "lateDate") && errorForDateConfig.lateDate) return true;
        else if (loading || submissionLoading || hasClassConfigError()) return true;
        else if(moment(data.dueDate).isBefore(data.scheduleAt)) return true;
        else if(data.lateDate&&moment(data.lateDate).isBefore(data.dueDate)) return true;
        else return false;
    }
    const getStartMintime = (value) => {
        if (get(data, "editing") && value ) {
           return isBeforTime(moment(value).toISOString()) ? moment(value).toISOString(): moment().toISOString()
        } else {
            return moment().toISOString()
        }
    }
    const getDueMinDate = () => {
        if (!!initialDueDate) {
            return isBeforTime(initialDueDate) ? initialDueDate : moment();
        } else {
            return getMinDate(get(data, "scheduleAt"))
        }
    }
    const getMinLateDate = () => {
        if (!!initialLateDate) {
            const _dueDate = get(data, "dueDate")
            return isBeforTime(initialLateDate) ? initialDueDate : getMinDate(_dueDate, moment());
        } else {
            const _dueDate = get(data, "dueDate")
            const _scheduleAt = get(data, "scheduleAt")
            const minScheduledDate = getMinDate(_scheduleAt)
            return getMinDate(_dueDate, minScheduledDate)
        }
    }

    const openDueDateAlert = () =>{
        setAlert({
            open: true,
            message:`Assigning activities with due dates beyond the current semester is not possible.`
        })
    }
    const closeDueDateAlert = () =>{
        setAlert({open:false,message:''})
    }
    const fetchIsVideos = () => {
        let _chapter = data.chapter ? data.chapter : data?.data?.chapter;
        const videoData = isVideo(_chapter, resources)
        return videoData;
    }
    const hasGradeCatMissing = filter(data.classes,c=>isSchoologyUser&&!c.schoologyCategory)
    const submitButtonDeactive = hasGradeCatMissing.length>0|| hasSubmitbuttonDeactive()
    return (
        <Wrapper onSubmit={onSubmit}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openAlert.open}
                autoHideDuration={3000}
                onClose={closeDueDateAlert}>
                <Alert onClose={closeDueDateAlert} severity="error">
                    <strong>ALERT:</strong> {openAlert.message}
                </Alert>
            </Snackbar>
            <Modal
                open={deleteClassAssignedQuizToggle}
                onClose={() => setdeleteClassAssignedQuizToggle(false)}
            >
                <ModalContent>
                    Are you sure you want to delete the assignment? All grades will be removed as well.
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setdeleteClassAssignedQuizToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={deleteClassQuiz}
                            className={`${dataLoading ? "disabled-button" : ""}`}
                        >
                            {dataLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Row className="editQuizUpr">
                <Col xs={24} sm={12} md={12} style={{ padding: "16px 10px", paddingBottom: "60px" }}>
                    <ImageContiner>
                        <Image src={getImages()} alt='' />
                    </ImageContiner>
                </Col>
                <Col xs={24} sm={12} md={12} className="AsignQuiz">
                    <Header>{data.editing ? 'Edit Quiz' : 'Assign Quiz'}</Header>
                    <Description>Please, remember the quiz due date is to create a logic that will automatically make the quiz disappear from the student dashboard. If you want to make it visible after this date, you can go back and edit the details.</Description>
                    {/* <FormControlLabel
                    classes={{
                        root: classes.root,
                        label: classes.label,
                    }}
                    control={
                        <Switch
                            classes={{
                                sizeSmall: classes.sizeSmall,
                                checked: classes.checkedSwitch
                            }}
                            checked={shareActive}
                            onChange={e => setShareActive(e.target.checked)}
                            name="viewShareActive"
                            color="primary"
                            size="small"
                        />
                    }
                    label="Share answers with class"
                /> */}
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                        <Acent inputRef={dispNameRef} setValue={v=>handleChange( { target: { id: "displayName", value: v } })}  style={{ marginBottom: 5 ,  display:"flex" ,justifyContent:"flex-end" }} />
                            <TextField
                                id="displayName"
                                label="Display name"
                                type="text"
                                inputRef={dispNameRef}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChange}
                                variant="outlined"
                                value={data?.displayName ?? data?.data?.displayName ?? ''}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]} className="durDateSpc">

                        <Col span={24}>
                            <CustomDateTimePicker
                                id="scheduleAt"
                                label="Start date"
                                inputVariant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                format="MM-dd-yyyy hh:mm a"
                                disabled={isEditing}
                                minDate={isEditing ? null : moment().toISOString()}
                                minDateMessage={`Start date and time has to be after the ${moment(getStartMintime(get(data, "scheduleAt"))).format("MM-DD-YYYY hh:mm a")} `}
                                defaultValue={null}
                                initialFocusedDate={moment().toString()}
                                value={get(data, "scheduleAt")}
                                onChange={e => {
                                    dateTimeSet("scheduleAt", e)
                                }} />
                        </Col>
                        <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                        <Note>The assignment will take effect after the scheduled date and time.</Note>
                    </Row>
                    <Row gutter={[16, 24]} className="durDateSpc">
                        <Col span={24}>
                            <CustomDateTimePicker
                                id="dueDate"
                                label="Due Date"
                                inputVariant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                defaultValue={null}
                                format="MM-dd-yyyy hh:mm a"
                                minDate={getDueMinDate()}
                                initialFocusedDate={moment().toString()}
                                strictCompareDates
                                minDateMessage={"Due Date and Time has to be after the Start Date and Time."}
                                value={get(data, "dueDate")}
                                onChange={e => {
                                    if(!isBeforTime(e,currentSemDateAndTime.start)&& !isAfterTime(e,currentSemDateAndTime.end)){
                                        dateTimeSet("dueDate", e)
                                        setInitialLateDate(null)
                                    }else{
                                        openDueDateAlert()
                                    }
                                }} />
                        </Col>
                        <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                        <Note>Due at midnight from the chosen date.</Note>
                    </Row>
                    {/* Shuffle start */}
                    <Container>
                        <Row justify="start" style={{ marginBottom: 10 }}>
                            <FormControlLabel
                                classes={{
                                    root: classes.rootDif,
                                    label: classes.label,
                                }}
                                control={
                                    <Switch
                                        classes={{
                                            sizeSmall: classes.sizeSmall,
                                            checked: classes.checkedSwitch
                                        }}
                                        checked={data.shuffled}
                                        disabled={isEditing}
                                        id="shuffled"
                                        onChange={e => {
                                            let v = { target: { id: "shuffled", value: e.target.checked } }
                                            handleChange(v)
                                        }}
                                        name="shuffled"
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label="Shuffle"
                            />
                        </Row>
                        {get(data, "shuffled") && <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                                <Note>Questions will be rearranged on a per-student basis.</Note>
                            </div>
                        </>
                        }
                    </Container>
                    {/* Shuffle End */}
                    {/* Late date field */}
                    <Container>
                        <Row justify="start" style={{ marginBottom: 10 }}>
                            <FormControlLabel
                                classes={{
                                    root: classes.rootDif,
                                    label: classes.label,
                                }}
                                control={
                                    <Switch
                                        classes={{
                                            sizeSmall: classes.sizeSmall,
                                            checked: classes.checkedSwitch
                                        }}
                                        checked={data.lateDate}
                                        id="lateSubmissionSection"
                                        onChange={e => {
                                            if (setlateSection) {
                                                setlateSection(e.target.checked)
                                                if (e.target.checked) {
                                                    let v = { target: { id: "lateDate", value: isBeforTime(get(data, "dueDate")) ? moment().add(1, "days").toISOString() : getMinDate(get(data, "dueDate"), moment()) } }
                                                    handleChange(v)
                                                } else {
                                                    let v = { target: { id: "lateDate", value: null } }
                                                    handleChange(v)
                                                }

                                            }

                                        }}
                                        name="lateSubmissionSection"
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label="Late Submission"
                            />
                        </Row>
                        {get(data, "lateDate") ? <>
                            <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                            <Note>If you don't choose a date, it will be counted as "unlimited" time to submit this assessment.</Note>
                            <Row gutter={[16, 24]}>
                                <Col span={24}>
                                    <CustomDateTimePicker
                                        id="lateDate"
                                        label="Late Submission"
                                        inputVariant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        strictCompareDates
                                        defaultValue={null}
                                        format="MM-dd-yyyy hh:mm a"
                                        minDate={getMinLateDate()}
                                        initialFocusedDate={moment().toString()}
                                        minDateMessage={"Late Date and Time has to be after the Due Date and Time."}
                                        value={get(data, "lateDate") == "always" ? undefined : get(data, "lateDate")}
                                        onChange={e => {
                                            dateTimeSet("lateDate", e)
                                        }} />
                                </Col>
                            </Row>
                        </> : null
                        }
                    </Container>
                    {/* End late date field */}
                    <Container>
                        <Row justify="start" style={{ marginBottom: 10 }}>
                            <FormControlLabel
                                classes={{
                                    root: classes.rootDifFull,
                                    label: classes.label,
                                }}
                                control={
                                    <Switch
                                        classes={{
                                            sizeSmall: classes.sizeSmall,
                                            checked: classes.checkedSwitch
                                        }}
                                        checked={durationSection}
                                        disabled={isEditing}
                                        id="timeDuration"
                                        onChange={e => {
                                            if (!e.target.checked) {
                                                let v = { target: { id: "timeDuration", value: 0 } }
                                                handleChange(v)
                                            }
                                            if (setDurationSection) {
                                                setDurationSection(e.target.checked)
                                                let v = { target: { id: "timeDuration", value: 0 } }
                                                let v2 = { target: { id: "retakes", value: "0" } }
                                                handleChange(v)
                                                handleChange(v2)
                                            }
                                        }}
                                        name="timeDuration"
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label="Set a TIME-LIMIT for this assessment"
                            />
                        </Row>
                        {isEditing&&durationSection?<Note style={{ marginBottom: 0 ,  color:COLOR.radish100  }}>Time limit cannot be modified nor removed after it has been assigned.</Note>:null}
                 
                        {durationSection &&
                            <Row gutter={[16, 24]} style={{ marginTop: 16 }}>
                                <Col span={24}>
                                    <TextField
                                        id="timeDuration"
                                        onChange={handleChange}
                                        disabled={isEditing}
                                        variant="outlined"
                                        label={"Duration in minutes"}
                                        value={parseInt(data?.timeDuration ?? 0, 10)}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                    </Container>
                   <Container>
                        <AssignmentClassConfig
                            userClasses={userClasses}
                            data={data}
                            handleChange={handleChange}
                            currentClass={currentClass}
                            isSchoologyUser={isSchoologyUser}
                            schoologyAllClassGradeCategory={schoologyAllClassGradeCategory}
                            errorForDateConfig={errorForDateConfig}
                            setErrorForDateConfig={setErrorForDateConfig} />
                    </Container>
                    <Container>
                        <Row gutter={[16, 24]}>
                            <Col span={16}>
                                <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> Weightage</Tag>
                                <Note style={{ marginBottom: 0 }}>This weightage is used to calculate grade.</Note>
                                <Note style={{ color: COLOR.red, fontSize: 10 }}>You will not be able to edit the weightage after assigning activity or quiz.</Note>
                                {/* {isSchoologyUser && !get(data, "schoologyCategory") ? <Note style={{ marginBottom: 0, color: COLOR.radish100 }}>Weightage category required!</Note> : null}
                                {isSchoologyUser && get(schoologyGradeCategory, "length") == 0 ? <Note style={{ marginBottom: 0, color: COLOR.radish100 }}>Please create category on schoology platform then try again!</Note> : null} */}
                            </Col>
                            <Col span={8}>
                                {!isSchoologyUser && !isCanvas_user &&!data.ungraded ?  
                                    <TextField
                                        id="weightage"
                                        disabled={data.editing}
                                        onChange={(e) => {
                                            let val = e.target.value
                                            val = val > 100 ? data?.weightage : val
                                            let v = { target: { id: "weightage", value: val } }
                                            handleChange(v)
                                        }}
                                        variant="outlined"
                                        defaultValue={100}
                                        value={parseInt(data?.weightage ?? 100, 10)}
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                max: 100
                                            }
                                        }}
                                    />:null}
                                {!isSchoologyUser  && !isCanvas_user? <FormControlLabel
                                    classes={{
                                        root: classes.rootDifFull,
                                        label: classes.label,
                                    }}
                                    control={
                                        <Checkbox
                                            classes={{
                                                checked: classes.colorPrimary
                                            }}
                                            checked={data.ungraded}
                                            id="ungraded"
                                            onChange={e => {
                                                handleChange({ target: { id: "ungraded", value: e.target.checked } })
                                            }}
                                            name="ungraded"
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label="Ungraded"
                                /> : null}
                            </Col>
                        </Row>
                       {!isSchoologyUser &&<Row gutter={[16, 24]}>
                            <Col span={8}>
                            <FormControl size="small" variant="outlined" className={classes.formControl}>
                                        <InputLabel id="grade_type" style={{ backgroundColor: "#fff", width: 'max-content' }}>Display Grade as</InputLabel>
                                        <Select
                                            labelId="grade_type"
                                            disabled={data.editing}
                                            value={get(find(isCanvas_user?GRADE_TYPE_CANVAS:GRADE_TYPE_ALL,["value",data.grade_type]),"type","Percentage")}
                                            onChange={e => {
                                                handleChange({ target: { id: "grade_type", value :e.target.value } })
                                            }}
                                            renderValue={(selected) => {
                                                return (
                                                    <div className={classes.chips}>
                                                        <ListItemText primary={selected} />
                                                    </div>
                                                )
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {map(isCanvas_user?GRADE_TYPE_CANVAS:GRADE_TYPE_ALL, (option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    <ListItemText primary={`${option.type}`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                            </Col>
                            <Col span={4} style={{justifyContent:"center",alignItems:"center", display:"flex"}}>
                                {data.grade_type === 'points' ? <TextField
                                    id="max_point"
                                    onChange={(e) => {
                                        handleChange({ target: { id: "max_point", value: e.target.value } })
                                    }}
                                    defaultValue={100}
                                    disabled={data.editing}
                                    variant="outlined"
                                    value={data.max_point}
                                    className={"max_point"}
                                    size="medium"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    
                                    style={{height:50}}
                                /> : null}
                            </Col>
                        </Row>}
                        {/* <Row>
                        <FormControlLabel
                                    classes={{
                                        root: classes.rootDifFull,
                                        label: classes.label,
                                    }}
                                    control={
                                        <Checkbox
                                            classes={{
                                                checked: classes.colorPrimary
                                            }}
                                            checked={data.final_grade_exclude}
                                            id="final_grade_exclude"
                                            onChange={e => {
                                                handleChange({ target: { id: "final_grade_exclude", value: e.target.checked } })
                                            }}
                                            name="final_grade_exclude"
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label="Do not count this assignment towards the final grade"
                                />
                        </Row> */}
                    </Container>
                    <Container>
                        <Row gutter={[16, 24]}>
                            <Col span={16}>
                                <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                                <Note>This is the number of additional attempts the student will have for this quiz.</Note>
                            </Col>
                            <Col span={8}>
                                <TextField
                                    id="retakes"
                                    onChange={(e) => {
                                        let val = e.target.value ?? 0
                                        val = val > 10 ? data?.retakes : val
                                        val = val == 0 ? "" : val
                                        let v = { target: { id: "retakes", value: val } }
                                        handleChange(v)
                                    }}
                                    variant="outlined"
                                    value={parseInt(data?.retakes ?? 0, 10)}
                                    type="number"
                                    defaultValue={0}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 10
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    {fetchIsVideos() && (
                        <Container>
                            <Row gutter={[16, 24]}>
                            <Col span={16}>
                                <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                                <Note>This is the number of times student can play video during this quiz.</Note>
                            </Col>
                            <Col span={8}>
                                <TextField
                                    id="play_video"
                                    onChange={(e) => {
                                        let val = e.target.value ?? 0
                                        val = val > 10 ? data?.play_video : val
                                        val = val == 0 ? "" : val
                                        let v = { target: { id: "play_video", value: val } }
                                        handleChange(v)
                                    }}
                                    variant="outlined"
                                    value={parseInt(data?.play_video ?? 0, 10)}
                                    type="number"
                                    defaultValue={0}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 10
                                        }
                                    }}
                                />
                            </Col>
                            </Row>
                        </Container>
                    )}
                    {/* <Row>
                        {data.editing && data.classes && data.classes.length > 0 && <Col span={24}>
                            <FormControlLabel
                                classes={{
                                    root: classes.rootDifFull,
                                    label: classes.label,
                                }}
                                control={
                                    <Checkbox
                                        classes={{
                                            checked: classes.colorPrimary
                                        }}
                                        checked={data.updateTOAllClass}
                                        id="updateTOAllClass"
                                        onChange={e => {
                                            handleChange({ target: { id: "updateTOAllClass", value: e.target.checked } })
                                        }}
                                        name="updateTOAllClass"
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label="Update to all classes."
                            />
                        </Col>}
                    </Row> */}
                    {isSchoologyUser && hasGradeCatMissing.length>0  ? <Note style={{ marginBottom: 0, color: COLOR.radish100 }}>Weightage category required! in  {`${hasGradeCatMissing.map(k=>k.displayName).join(',')}`}</Note> : null}
                    <Row justify="space-between" style={{marginBottom:16}}>
                        <Col span={12} style={{ display: "flex", justifyContent: "flex-start" ,alignItems:"flex-end"}}>
                            {(data.editing && !loading) && <Button
                                color="secondary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setdeleteClassAssignedQuizToggle(true)
                                }}
                                variant="contained"
                                style={{ padding: '0px 0px !important' , height:30 }}
                            >
                            <Delete style={{ fontSize: '16px', width: '16px' }} />
                            </Button>}

                        </Col>
                         <Col span={12} style={{ display: "flex", alignItems: "flex-end", flexDirection:"column" }} >
                         {data.editing && data.classes && data.classes.length > 0 && <FormControlLabel
                                classes={{
                                    root: classes.rootDifFull,
                                    label: classes.label,
                                }}
                                control={
                                    <Checkbox
                                        classes={{
                                            checked: classes.colorPrimary
                                        }}
                                        checked={data.updateTOAllClass}
                                        id="updateTOAllClass"
                                        onChange={e => {
                                            handleChange({ target: { id: "updateTOAllClass", value: e.target.checked } })
                                        }}
                                        name="updateTOAllClass"
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label="Update to all classes."
                            />}
                        <Button
                            disabled={submitButtonDeactive}
                            variant="contained"
                            color="primary"
                            type='submit'
                            endIcon={<ArrowForward style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />}
                            className={`${loading ? "disabled-button" : ""}`}
                            style={{width:160}}
                        >
                            {data.editing ? ((loading || submissionLoading) ? <Loading size={20} white /> : 'Update Quiz') : ((loading || submissionLoading) ? <Loading size={20} white /> : 'Assign Quiz')}
                        </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
QuizForm.propTypes = {
    data: shape({
        dueDate: string,
        retake: string,
    }),
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
};

//  -- Default props ------------------
QuizForm.defaultProps = {
    data: {}
};
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    userClasses: state.userClasses,
    booksAllList: state.booksAllList,
    currentUser: state.currentUser
});


export default connect(mapStateToProps, null)(QuizForm);
