import React, { useState, useEffect } from "react"
import { Typography, Collapse, FormControlLabel, Switch, withStyles, Popover, TextField } from "@material-ui/core";
import styled from 'styled-components';
import COLOR from 'utils/constants/color';
import { Row, Col } from "antd"
import {
    TimePicker
} from "@material-ui/pickers";
import { MoreHoriz } from "@material-ui/icons";
import moment from "moment"
import Loading from "components/Loading";
import { isEmpty, get, debounce } from 'lodash'
import { useChatConfig } from 'store/chat/selectors';
import { useCurrentUser } from 'store/users/selectors';
import { useDispatch } from "react-redux"
import { handleUpdateUserChatHour, handleUpdateUserChatSeen, handleUpdateUNotificationConfig , handleUpdateUserChatDiable} from "store/chat/thunks";
const time_format = "HH:mm";


const SettingContainer = styled.div`
    background-color: #fff;
    width: 300px;
    min-height:100px;
    padding: 20px;
    position:relative;
    display: flex;
    flex-direction: column;
    min-height:100px;
    border-radius: 6px;
`;
const RowChatHourSwitch = styled.div`
   display:flex;
   flex-direction:row;
   width: 100%;
   justify-content: space-between;

`;
const HrUpdateButton = styled.button`
    color: #fff;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 2px;
    font-size: 12px;
    width: 70px;
    background: rgb(80,114,116);
    cursor: pointer;
`;

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 25,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        color: "red",
        '&$checked': {
            transform: 'translateX(16px)',
            color: "green",
            '& + $track': {
                backgroundColor: 'rgb(52,51,74)',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 16,
        height: 16,
        marginTop: 3,
        marginRight: 0,
        marginLeft: 4.5
    },
    track: {
        borderRadius: 26 / 2,
        backgroundColor: "rgb(52,51,74)",
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export const Settings = ({ setAlertView, socket }) => {
    const userChatConfig = useChatConfig()
    const [chatHour, setChatHour] = useState(null)
    const [messageSeen, setMessageSeen] = useState(false)
    const [notification, setNotification] = useState(false)
    const [settingEl, setSettingEl] = React.useState(null);
    const [chatHourSwitch, setChatHourSwitch] = useState(false)
    const dispatch = useDispatch()
    const settingPopOveropen = Boolean(settingEl);
    const settingPopOverid = settingPopOveropen ? 'simple-popover' : undefined;
    const currentUser = useCurrentUser()
    const isStudent = get(currentUser, "role") === "student";
    const [hrUpdateLoader, sethrUpdateLoader] = useState(false)
    const [chatDisabled,setChatDisabled] = useState(false)
    useEffect(() => {
        if(!isEmpty(userChatConfig)){
            setChatHourSwitch(!!get(userChatConfig, "chatHour"))
            setChatDisabled(get(userChatConfig, "disabled",false))
            setChatHour(get(userChatConfig, "chatHour"))
            setMessageSeen(get(userChatConfig, "messageSeen", true))
            setNotification(get(userChatConfig, "notification", true))
        }
    }, [userChatConfig])
    const updateChatHourConfig = async (cHour,isTurnOff) => {
        if(get(userChatConfig, "chatHour")==cHour) {
            setChatHourSwitch(!!cHour)
            return;
        }
        sethrUpdateLoader(true)
        if (!isTurnOff) {
            if (!cHour || !cHour.endTime || !cHour.startTime) {
                setAlertView({ open: true, message: "Plese set time first." })
                sethrUpdateLoader(false)
                return;
            }
            if (moment(cHour.endTime, time_format).isSameOrBefore(moment(cHour.startTime, time_format), "hours")) {
                setAlertView({ open: true, message: "End time has to  be after start time." })
                sethrUpdateLoader(false)
                return;
            }

        }
        const config = await dispatch(handleUpdateUserChatHour(currentUser.id, cHour))
        if (socket) {
            try {
                socket.send(JSON.stringify({ type: "config_update", code: 'config_update', config: config, id: currentUser.id }))
            } catch (error) {
                console.log(error, "socket send update error")
            }
        }
        sethrUpdateLoader(false)
    }
    const updateMessageSeenConfig = async (seenVal) => {
        const config = await dispatch(handleUpdateUserChatSeen(currentUser.id, typeof seenVal == "boolean" ? seenVal : messageSeen))
        if (socket) {
            try {
                socket.send(JSON.stringify({ type: "config_update", code: 'config_update', config: config, id: currentUser.id }))
            } catch (error) {
                console.log(error, "socket send update error")
            }
        }
    }
    const updateDiabledConfig = async (diableValue) => {
        const config = await dispatch(handleUpdateUserChatDiable(currentUser.id, typeof diableValue == "boolean" ? diableValue : chatDisabled))
        if (socket) {
            try {
                socket.send(JSON.stringify({ type: "config_update", code: 'config_update', config: config, id: currentUser.id }))
            } catch (error) {
                console.log(error, "socket send update error")
            }
        }
    }
    const updateNotificationConfig = async (val) => {
        await dispatch(handleUpdateUNotificationConfig(currentUser.id, typeof val == "boolean" ? val : notification))
    }
    const onChatHourChange = (time, type) => {
        let cHour = { ...chatHour }
        if (type == "startTime") {
            const startTimeTemp = moment(time, time_format)
            if (cHour.endTime) {
                const lastEndTime = moment(cHour.endTime, time_format)
                if (lastEndTime.isSameOrAfter(startTimeTemp, "hours") && lastEndTime.isSameOrAfter(startTimeTemp, "minutes")) {
                    cHour.startTime = startTimeTemp.format(time_format);
                } else {
                    setAlertView({ open: true, message: "Start time has to be before end time." })
                }

            } else {
                cHour.startTime = startTimeTemp.format(time_format);
            }

        } else {
            const endTimeTemp = moment(time, time_format)
            if (cHour.startTime) {
                const lastEndTime = moment(cHour.startTime, time_format)
                if (lastEndTime.isSameOrBefore(endTimeTemp, "hours") && lastEndTime.isSameOrBefore(endTimeTemp, "minutes")) {
                    cHour.endTime = endTimeTemp.format(time_format);
                } else {
                    setAlertView({ open: true, message: "End time has to  be after start time." })
                }
            } else {
                cHour.endTime = endTimeTemp.format(time_format);
            }
        }
        setChatHour(cHour)
    }
    const onClickSettingClick = (event) => {
        setSettingEl(event.currentTarget);
    };

    const onSettingClose = () => {
        setSettingEl(null);
    };
    return (
        <>
            {!isStudent&&<MoreHoriz style={{ fontSize: 34, marginRight: 4, marginLeft: 2, color: "#fff", cursor: "pointer" }} aria-describedby={settingPopOverid} onClick={onClickSettingClick} />}
            <Popover
                style={{
                    background: 'transparent',
                    bordeRadius: '20px'
                }}
                id={settingPopOverid}
                open={settingPopOveropen}
                anchorEl={settingEl}
                className={"chatSettingPopOver"}
                onClose={onSettingClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>

                <SettingContainer className="messagesBoxSet">
                    {!isStudent ? <> <RowChatHourSwitch className="messagesBoxSwthr">
                        <Typography style={{
                            color: 'black',
                            fontSize: '13px',
                            fontWeight: '500'
                        }} variant="subtitle2">My Chat Hours</Typography>
                        <FormControlLabel
                            control={<IOSSwitch disabled={hrUpdateLoader} checked={chatHourSwitch} onChange={e => {
                                if (hrUpdateLoader) return;
                                if (e.target.checked) {
                                    setChatHour({
                                        startTime: null,
                                        endTime: null
                                    })
                                    setChatHourSwitch(true)
                                } else {
                                    setChatHour(null)
                                    updateChatHourConfig(null,true)
                                }
                            }} name="checkedB" />}
                        />
                    </RowChatHourSwitch>
                        <Collapse className="messagesBoxColap" in={chatHourSwitch} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Row style={{ width: "100%", marginTop: 10 }}>
                                <Col span={12} style={{ paddingRight: 5 }}>
                                    {chatHourSwitch && <TextField
                                        id="startTime"
                                        variant="outlined"
                                        type="time"
                                        className={"schedultTimePicker"}
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        value={get(chatHour, "startTime")}
                                        onChange={(e) => {
                                            onChatHourChange(e.target.value, "startTime")
                                        }}
                                    />}
                                    {/* <TimePicker placeholder='start time'
                                        inputVariant="outlined"
                                        className={"schedultTimePicker"}
                                        value={chatHour ? moment(get(chatHour, "startTime"), 'h:mm a') : null}
                                        onChange={e => {
                                            onChatHourChange(e, "startTime")
                                        }} /> 
                                        
                                        <TimePicker placeholder='end time'
                                        inputVariant="outlined"
                                        value={chatHour ? moment(get(chatHour, "endTime"), 'h:mm a') : null}
                                        className={"schedultTimePicker"}
                                        onChange={e => {
                                            onChatHourChange(e, "endTime")
                                        }} /> */}
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5 }}>

                                    {chatHourSwitch && <TextField
                                        id="endTime"
                                        variant="outlined"
                                        type="time"
                                        className={"schedultTimePicker"}
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        value={get(chatHour, "endTime")}
                                        onChange={(e) => onChatHourChange(e.target.value, "endTime")}
                                    />}
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: 10, justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                                <HrUpdateButton onClick={() => updateChatHourConfig(chatHour)}  >{hrUpdateLoader ? <Loading white size={15} /> : "Update"}</HrUpdateButton>
                            </Row>
                        </Collapse>
                        <RowChatHourSwitch className="messagesBoxHrRut" style={{ marginTop: 10 }}>
                            <Typography style={{
                                color: 'black',
                                fontSize: '13px',
                                fontWeight: '500'
                            }} variant="subtitle2">Show Read Message</Typography>
                            <FormControlLabel
                                control={<IOSSwitch checked={messageSeen} onChange={e => {
                                    updateMessageSeenConfig(e.target.checked)
                                    setMessageSeen(e.target.checked)
                                }} name="messageSeen" />}
                            />
                        </RowChatHourSwitch></> : null}
                    <RowChatHourSwitch className="messagesBoxHrRut" style={{ marginTop: 10 }}>
                        <Typography style={{
                            color: 'black',
                            fontSize: '13px',
                            fontWeight: '500'
                        }} variant="subtitle2">Block Pop-Up Notifications</Typography>
                        <FormControlLabel
                            control={<IOSSwitch checked={!notification} onChange={e => {
                                updateNotificationConfig(!e.target.checked)
                                setNotification(!e.target.checked)
                            }} name="messageSeen" />}
                        />
                    </RowChatHourSwitch>
                    {!isStudent ? <RowChatHourSwitch className="messagesBoxHrRut" style={{ marginTop: 10 }}>
                        <Typography style={{
                            color: 'black',
                            fontSize: '13px',
                            fontWeight: '500'
                        }} variant="subtitle2">Disable</Typography>
                        <FormControlLabel
                            control={<IOSSwitch checked={chatDisabled} onChange={e => {
                                updateDiabledConfig(e.target.checked)
                                setChatDisabled(e.target.checked)
                            }} name="chatDisable" />}
                        />
                    </RowChatHourSwitch> : null}
                </SettingContainer>
            </Popover>
        </>)
}