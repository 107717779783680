//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useCallback, useMemo, useRef, useEffect } from "react";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SunEditor from "suneditor-react";
import { SPANISH_ITEM_LOWER, SPANISH_ITEM_UPPER } from "utils/config/reference";
import {
  blockquote,
  align,
  font,
  fontSize,
  fontColor,
  hiliteColor,
  horizontalRule,
  list,
  table,
  formatBlock,
  lineHeight,
  template,
  paragraphStyle,
  textStyle,
  link,
  image,
} from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { useState } from "react";
const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const BUTTON_LIST = [
  ["font", "fontSize"],
  ["bold", "underline", "italic"],
  ["fullScreen"],
  [
    {
      name: "SPANISH_ITEM_LOWER",
      dataCommand: "SPANISH_ITEM_LOWER",
      buttonClass: "",
      title: `${SPANISH_ITEM_LOWER[0]}`,
      dataDisplay: "submenu",
      innerHTML: `<span>${SPANISH_ITEM_LOWER[0]}</span>`,
    },
  ],
  [
    {
      name: "SPANISH_ITEM_UPPER",
      dataCommand: "SPANISH_ITEM_UPPER",
      buttonClass: "",
      title: `${SPANISH_ITEM_UPPER[0]}`,
      dataDisplay: "submenu",
      innerHTML: `<span>${SPANISH_ITEM_UPPER[0]}</span>`,
    },
  ],
];
const SPANISH_ITEM_LOWER_PLUGIN = {
  name: "SPANISH_ITEM_LOWER",
  display: "submenu",
  add: function (core, targetElement) {
    let listDiv = this.setSubmenu.call(core);
    var self = this;
    listDiv.querySelectorAll(".se-btn-list-lower").forEach(function (btn) {
      btn.addEventListener("click", self.onClick.bind(core));
    });
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu: function () {
    const listDiv = this.util.createElement("DIV");
    listDiv.className = "se-submenu se-list-layer";
    listDiv.innerHTML = `<div class="se-list-inner se-list-font-size" style="width:30px;">
      <ul class="se-list-basic">
         ${SPANISH_ITEM_LOWER.map(
           (item) =>
             `<li><button type="button" class="se-btn-list-lower" value="${item}">${item}</button></li>`
         ).join("")}
      </ul></div>`;

    return listDiv;
  },
  onClick: function (e) {
    e.preventDefault()
    const value = e.target.value;
    const node = this.util.createElement("span");
    this.util.addClass(node, "se-custom-tag");
    node.textContent = value;
    this.insertNode(node);
    const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
    node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);
    this.submenuOff();
  },
};
const SPANISH_ITEM_UPPER_PLUGIN = {
  name: "SPANISH_ITEM_UPPER",
  display: "submenu",
  add: function (core, targetElement) {
    let listDiv = this.setSubmenu.call(core);
    var self = this;
    listDiv.querySelectorAll(".se-btn-list-upper").forEach(function (btn) {
      btn.addEventListener("click", self.onClick.bind(core));
    });
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu: function () {
    const listDiv = this.util.createElement("DIV");
    listDiv.className = "se-submenu se-list-layer";
    listDiv.innerHTML = `<div class="se-list-inner se-list-font-size" style="width:30px;">
      <ul class="se-list-basic">
         ${SPANISH_ITEM_UPPER.map(
           (item) =>
             `<li><button type="button" class="se-btn-list-upper" value="${item}">${item}</button></li>`
         ).join("")}
      </ul></div>`;

    return listDiv;
  },
  onClick: function (e) {
    const value = e.target.value;
    const node = this.util.createElement("span");
    this.util.addClass(node, "se-custom-tag");
    node.textContent = value;
    this.insertNode(node);
    const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
    node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);
    this.submenuOff();
  },
};
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.div`
  color: ${(p) => (p.active ? COLOR.black200 : COLOR.black500)};
  cursor: pointer;
  font-size: 14px;
  padding: 2px;
  transition: all 300ms ease;

  &:hover {
    color: ${COLOR.black200};
  }
`;

const Content = styled.div`
  padding: 16px 16px 0px;
`;

const Icon = styled.span`
  padding: 0px 2px;
  pointer-events: none;
`;

const Toolbar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 8px 8px 0px;
  position: relative;
  border-bottom: 2px solid #eee;
`;

const Wrapper = styled.div`
  border: 1px solid #eee;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
`;
const Row = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 20px;
`;
const SpenishBtn = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid #eee;
  color: ${COLOR.black500};
  cursor: pointer;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    color: ${COLOR.black200};
  }
`;
const Container = styled.div``;
const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  padding-top: 40px;
  overflow-y: auto;
`;

const FormHeader = styled.span`
  // color: ${COLOR.black200};
  display: inline-block;
  // font-size: 16px;
  // font-weight: 600;
  margin-bottom: 4px;
  // color: #000;
  font-family: Arial;
  font-size: 17.5px;
  font-weight: 700;
`;

const FormDescription = styled.span`
  color: ${COLOR.black400};
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24px;
`;
//  ----------------------------------------------------------------------------
//  Helpers
//  ----------------------------------------------------------------------------
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const NotesTextEditor = ({
  handleChange,
  value,
  autoFocus = true,
  disabled,
  page=""
}) => {
  const editorRef = useRef();
  const [open, setOpen] = React.useState(false);
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  const insertHTML = (html) => {
    const _editor = editorRef.current;
    if (_editor) {
      _editor.insertHTML(html);
    }
  };
  useEffect(() => {
    if (
      editorRef.current &&
      editorRef.current.getContents(true) == "<p><br></p>" &&
      value
    ) {
      editorRef.current.setContents(value);
    }
  }, [value]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    onPasteItem();
  };
  const onPasteItem = (item) => {
    setOpen(true);
    const currentContent = editorRef.current.getContents(true);
    setTimeout(() => {
      editorRef.current.setContents(currentContent);
    }, 200);
  };
  return (
    <Container className={`${page === "ebookAndBubble" ? "ebookAndBubble" : ""}`}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{width:"100%"}}
      >
        <Alert onClose={handleClose} severity="error" >
          <strong>ALERT:</strong> Paste not allowed.
        </Alert>
      </Snackbar>
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        setAllPlugins={true}
        autoFocus={autoFocus}
        disable={disabled}
        disableToolbar={disabled}
        onDrop={handleDrop}
        placeholder="Please type here..."
        setOptions={{
          // height: "200px",
          width: "100%",
          showPathLabel: false,
          buttonList: BUTTON_LIST,
          plugins: [
            blockquote,
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link,
            SPANISH_ITEM_LOWER_PLUGIN,
            SPANISH_ITEM_UPPER_PLUGIN,
          ],
        }}
        setDefaultStyle={"text-align: left, overflow: auto"}
        onPaste={(e, cleanData, maxCharCount) => {
          onPasteItem();
        }}
        onChange={handleChange}
        defaultValue={value}
      />
    </Container>
  );
};

//  -- Prop types ---------------------
NotesTextEditor.propTypes = {};

//  -- Default props ------------------
NotesTextEditor.defaultProps = {};

export default NotesTextEditor;
