import { useFirebase } from "containers/FirebaseContext";
import {
  doc as firestoreDoc,
  getDoc,
  setDoc,
  updateDoc
} from "firebase/firestore";

export const useResourceActions = () => {
  const { firestore } = useFirebase();
  const createResourceActions = async (ResourceData) => {
    try {
      const generated_action_id = ResourceData?.classId + ResourceData.videoId;
      const docRef = firestoreDoc(firestore, "resource_video_actions", generated_action_id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, ResourceData);
      } else {
        await setDoc(docRef, ResourceData);
      }
      return;
    } catch (error) {
      console.log(error, "error");
    }
  };

  return { createResourceActions };
};
