import { Modal, Box, Button } from '@material-ui/core';
import { find } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDemoVideos } from 'store/resources/selectors'
import styled from "styled-components"
import { closeModal, videoLink } from "utils/assets/images"



const VideoModal = styled.video`
    height:100%;
    width:100%;
`;
const VideoContainer = styled.div`
        display: flex;
        justify-content: center;
        height: 50vh;
        background: #fff;
        align-items: center;
`;


const PreviewButton = styled.button`
  background-color: rgba(2, 15, 73);
  color: white;
  padding: 2px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-shadow: none;
  border-radius: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  outline-width: 0px;
  border: 0px;
  cursor: pointer;
  height:30px;
  &:hover {
    background-color: rgba(2, 15, 90);
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
`;


export default function DemoPreview({ style = {}, video_id , wrapperStyle = {} }) {
    const demoVideos = useDemoVideos()
    const [demoModal, setdemoModal] = useState(false);
    const video_item = useMemo(() => {
        if (!video_id) return null
        return find(demoVideos, { id: video_id })
    }, [video_id, demoVideos]);


    useEffect(() => {


    }, [video_id, demoVideos])
    const hidedemoModal = () => {
        setdemoModal(false)
    }
    const viewdemoModal = () => {
        setdemoModal(true)
    }

    return (
        <div style={{ display: "flex", ...wrapperStyle }}>
            <Modal open={demoModal} onClose={hidedemoModal} className="modelCntnt">
                <Box sx={{}} className="modelCntntIner">
                    <div className="lernModalBg">
                        <VideoContainer className="modelDtl">
                            <VideoModal controls controlsList="nodownload" autoPlay src={video_item?.src} poster={video_item?.poster} />
                            <img src={closeModal} onClick={hidedemoModal} className="closeModelInfo" />
                        </VideoContainer>
                    </div>
                </Box>
            </Modal>
            {video_item && video_item.metadata.active ? <PreviewButton
                style={style}
                onClick={viewdemoModal}
                disabled={!video_item}
            >Preview
            </PreviewButton> : null}
        </div>
    )
}






