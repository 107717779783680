import { Switch, FormControlLabel, Typography , Button } from '@material-ui/core';
import React, { useState, useEffect, useRef, useMemo } from "react";
//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { makeStyles } from '@material-ui/core/styles';
import { Col, Drawer, Row } from 'antd';
import ClassCard from "components/ClassCard";
import SentryWrap from 'components/Sentry';
import ClassForm from 'features/forms/Class';
import FlatList from 'flatlist-react';
import { connect, useDispatch } from 'react-redux';
import { handleListUserClasses, handleUpdateClasseOrder } from 'store/userClasses/thunks';
import { handleGetClass } from "store/classes/thunks"
import styled from 'styled-components';
import { get , filter , find } from 'lodash';
import COLOR from 'utils/constants/color';
import { useHistory } from 'react-router-dom';
import { Star } from '@material-ui/icons';
import Tag from 'components/Tag';
import { handleUserClassesSync } from 'store/classlink/thunks';
import { groupLinkedSection } from 'utils/lib/ebookHelper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useDrag, useDrop, DndProvider , useDragLayer  } from 'react-dnd';

const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };
const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;
const ButtonClear = styled.button`
    border-radius: 7px;
    color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    margin: 10px;
`;
const Note = styled.span`
    color: ${COLOR.black400};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
`;
const ButtonClass = styled.button`
    background: #582f81;
  color: #ffffff;
  font-size: 18px;
  width: 150px;
  height: 45px;
  margin-right: 24px;
  margin-top: 24px;
  border-radius: 8px !important;
  padding: 9px 7px !important;
  border:0;
  &:hover{
     background: #582f81e3 !important;
  }
`;
const SectionTitle = styled.div`
    font-size: 14px;
    color: #ffffff;
    background: ${COLOR.tabActive};
    padding: 4px 8px;
    border-radius: 6px !important;
    cursor: pointer;
    position: absolute;
    top: -15px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
`;
const OuterWrapper = styled.div`
   ${'' /* width:400px; */}
  ${'' /* @media (max-width: 400px) {
    padding-bottom:100%;
  } */}
    // min-width:300px;
    @media only screen and (max-width: 576px) {
        margin: 0px 12px;
        width: calc(100% - 24px);
    }
    @media only screen and (min-width: 576px) and (max-width: 992px){
        margin: 0px 12px;
        width: calc(50% - 24px);
    }
    @media only screen and (min-width: 992px) and (max-width: 1200px){
        margin: 0px 12px;
        width: calc(33.33% - 24px);
    }
    @media only screen and (min-width: 1200px) {
        margin: 0px 12px;
        width: calc(25% - 24px);
    }


`;

const Label = styled(Typography)`
  font-size: 18px;
  color: #582f81; // Use your primary color here
`;

function ToggleClasses({ showHiddenClasses, setShowHiddenClasses }) {
    const handleToggle = (event) => {
      setShowHiddenClasses(event.target.checked);
    };
  
    return (
      <FormControlLabel
        control={
          <Switch
            checked={showHiddenClasses}
            onChange={handleToggle}
            color="primary"
          />
        }
         label={<Label>{showHiddenClasses ? "Archived classes " : "Active classes"}</Label>}
        labelPlacement="start"
        style={{ margin: 24 }}
      />
    );
  }

const ItemType = 'CARD';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  };
  
  function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }
  
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
      opacity: 0.9, // Set desired opacity here
      maxWidth:400
    };
  }
  
  const CustomDragLayer = ({all_items}) => {
    const {
      itemType,
      isDragging,
      item,
      initialOffset,
      currentOffset,
    } = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));
    const item_data = useMemo(()=>find(all_items,['id',item?.id]) || item , [all_items,item])
    if (!isDragging) {
      return null;
    }
  
    return (
      <div style={layerStyles}>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          <ClassCard key={item_data.id} classInfo={item_data} />
        </div>
      </div>
    );
  };
  
const DraggableCard = ({ item, index, moveCard, setDragOverIndex }) => {
    const ref = useRef(null);
  
    const [, drop] = useDrop({
      accept: ItemType,
      hover: (draggedItem, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = draggedItem.index;
        const hoverIndex = index;
  
        if (dragIndex === hoverIndex) {
          return;
        }
  
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
  
        moveCard(dragIndex, hoverIndex);
        draggedItem.index = hoverIndex;
      },
      drop: (draggedItem) => {
        moveCard(draggedItem.index, index);
      },
    });
  
    const [{ isDragging }, drag, preview] = useDrag({
      type: ItemType,
      item: { id: item.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    drag(drop(ref));
    useEffect(() => {
        const emptyImage = new Image();
        emptyImage.src = '';
        emptyImage.style.opacity = 0;
        preview(emptyImage);
    }, [preview])
    return (
      <OuterWrapper ref={ref} style={{ opacity: 1 }}>
        <ClassCard key={item.id} classInfo={item} />
      </OuterWrapper>
    );
  };

  
function LearnScreen({
    classActivities,
    classQuizzes,
    currentClass,
    currentOrg,
    currentUser,
    userClasses,
    userBooksList,
    classUsers,
    classNotes,
    booksAllList,
    classQuestions,
}) {
    const [classDrawer, toggleClassDrawer] = useState(false);
    const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
    const isCleverUser = !!(currentUser && currentUser.cleverId);
    const isCanvasUser = !!(currentUser && currentUser.canvas_id);
    const isGoogleUser = !!(currentUser && currentUser.googleId);
    const isClassLinkuser = !!(currentUser && currentUser.classlinkid);
    const isUserFromOtherPlatform =  isSchoologyUser || isCleverUser || isGoogleUser || isClassLinkuser || isCanvasUser
    const [classes,setClasses] = useState(filter(userClasses,k=>!k.archived))
    const [showHiddenClasses,setShowHiddenClasses] = useState(false);
    const history = useHistory()
    const [data, setData] = useState({});
    const dispatch = useDispatch();
    const [non_crossed_courses,setNonCrossedCourses] = useState([])
    const crossed_courses = useMemo(()=>filter(classes, k => k.canvas_cross_course_id),[classes]);
    const [dragOverIndex, setDragOverIndex] = useState(null);
    const [update_done,setUpdateDone] = useState(0)
    const is_touch = useMemo(()=>isTouchDevice(),[])
    useEffect(()=>{
        const non_crossed_courses_item = filter(classes, k => !k.canvas_cross_course_id && !k.schoology_parent_id)
        setNonCrossedCourses(non_crossed_courses_item.sort((a,b) => a.order - b.order))
    },[classes])
   
    const moveCard = (fromIndex, toIndex) => {
        const updatedItems = [...non_crossed_courses];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        setNonCrossedCourses(updatedItems)
        setTimeout(()=>{
            setUpdateDone(i=>i+1);
        },1000)
      };
    const renderItem = (item, index) => {
        return (<ClassCard key={item.id} classInfo={item} />)
    }
    useEffect(()=>{
        onClassDataChange()
    },[userClasses,showHiddenClasses])
    useEffect(() => {
        if (Array.isArray(non_crossed_courses)&&update_done>0) {
            dispatch(handleUpdateClasseOrder(non_crossed_courses.map((a, ind) => ({ id: a.id, order: ind }))))
        }
    }, [update_done])
    useEffect(()=>{
        if(currentUser&&currentUser.classlinkid){
            handleUserClassesSync(currentUser.classlinkid,currentUser.classlinkTenantId)
        }
    },[currentUser])
    const onClassDataChange = () => {
        if (showHiddenClasses) {
            setClasses(filter(userClasses, k => k.archived))
        } else {
            setClasses(filter(userClasses, k => !k.archived))
        }
    }
    const getClass = async () => {
        if (currentUser && currentUser.id) {
            await dispatch(handleListUserClasses(currentUser.id));
        }
        toggleClassDrawer(false);
    }
    const redirectToHome = () => {
        history.push(`/admin/class?view=home`);
    }
    useEffect(() => {
        if (userClasses && userClasses.length == 0) {
            if (isUserFromOtherPlatform) {
                redirectToHome()
            } else {
                toggleClassDrawer(true)
            }
        }
    }, [userClasses])
    const archiveClasses = filter(userClasses, k => k.archived);
    const archiveCount = archiveClasses.length > 0 ? `(${archiveClasses.length})` : ""
    // const crossed_courses = filter(classes, k => k.canvas_cross_course_id)
    // const non_crossed_courses = filter(classes, k => !k.canvas_cross_course_id && !k.schoology_parent_id)
    const archiveTxt = `Archived Classes ${archiveCount}`;
    const linked_classes = groupLinkedSection(classes)
    const has_link = crossed_courses.length >0 || Object.keys(linked_classes).length>0
    return (
        <>
            <Drawer
                height='100%'
                onClose={() => {
                    toggleClassDrawer(false);
                    setData({});
                }}
                placement='top'
                open={classDrawer}
                key='classNew'
                className="addNewDrawer"
            >
                <CenterContainer>
                    <SentryWrap>
                        {classDrawer && <ClassForm
                            data={data}
                            dataBooks={userBooksList}
                            listBooks={booksAllList}
                            setData={setData}
                            currentOrg={currentOrg}
                            currentUser={currentUser}
                            currentClass={currentClass}
                            classUsers={classUsers}
                            classActivities={classActivities}
                            classQuizzes={classQuizzes}
                            classNotes={classNotes}
                            classQuestions={classQuestions}
                            userClasses={userClasses}
                            onClosed={(hasClassLoad, classId) => {
                                setData({})
                                getClass(hasClassLoad, classId);
                                toggleClassDrawer(false)

                            }}
                        />}
                    </SentryWrap>
                </CenterContainer>
            </Drawer>
            <Row className="teacherHm">

                <Col xs={24} sm={24} md={24} lg={24} className='allTechrColectBtn'>
                    <Row className='techrColectBtn' style={{ padding: '0px 0 80px 0' }}>
                        {!isUserFromOtherPlatform && <ButtonClass onClick={() => toggleClassDrawer(true)}>Create classes</ButtonClass>}
                        <ToggleClasses setShowHiddenClasses={setShowHiddenClasses} showHiddenClasses={showHiddenClasses} />
                    </Row>
        
                    {showHiddenClasses ?
                        <Row style={{ marginBottom: 24, marginTop: -50 }} >
                            <Tag height={20} bgColor={COLOR.redishpink} ><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                            <Note style={{ color: COLOR.black100 }}>These are hidden classes.</Note>
                        </Row> : <Row style={{ marginBottom: 24, marginTop: -50 }} >
                            <Tag height={20} bgColor={COLOR.redishpink} ><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                            <Note style={{ color: COLOR.black100 }}>You can drag and reorganize your classes as needed.</Note>
                        </Row>}
                    {Object.keys(linked_classes).length > 0 ? <Row gutter={[40, 30]}>
                        {Object.keys(linked_classes).map((key, index) => {
                            return <div key={key} className="teacherHmInr cross-section-teacher-home" style={{ display: "flex" , gap: "50px",flexWrap: "wrap" ,  width:"100%"}}>
                                <SectionTitle>{"Linked section"}</SectionTitle>
                                <FlatList
                                    list={linked_classes[key]}
                                    renderItem={renderItem}
                                    renderWhenEmpty={() => <div>{showHiddenClasses ? `No Archived classes` : `No Class found!`}</div>}
                                    sortBy={["id"]}
                                />
                            </div>
                        })}
                    </Row> : null}
                    {crossed_courses.length > 0 ? <Row className="teacherHmInr cross-section-teacher-home" gutter={[40, 30]} style={{ gap: "50px",flexWrap: "wrap" , width:"100%" }}>
                        <SectionTitle>Cross-listed</SectionTitle>
                        <FlatList
                            list={crossed_courses}
                            renderItem={renderItem}
                            renderWhenEmpty={() => <div>{showHiddenClasses ? `No Archived classes` : `No Class found!`}</div>}
                            sortBy={["id"]}
                        />
                    </Row> : null}
                    <Row className="teacherHmInr non-cross-section-teacher-home" style={{ marginTop: has_link > 0 ? 50 : 0 , padding:  has_link > 0 ? 30 : 0 , gap: "50px",flexWrap: "wrap"}}>
                    <DndProvider backend={is_touch ? TouchBackend : HTML5Backend}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '50px', width:"100%" }}>
                                <FlatList
                                    list={non_crossed_courses}
                                    renderItem={(item) => {
                                        const index = non_crossed_courses.findIndex((i) => i.id === item.id);
                                        return (
                                            <DraggableCard
                                                key={item.id}
                                                item={item}
                                                index={index}
                                                moveCard={moveCard}
                                                setDragOverIndex={setDragOverIndex}
                                            />
                                        )
                                    }}
                                    renderWhenEmpty={() => <div>No items</div>}
                                />
                            </div>
                            <CustomDragLayer  all_items={non_crossed_courses}/>
                        </DndProvider>
                    </Row>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={4}></Col> */}
            </Row>
        </>
    );
}

const mapStateToProps = state => ({
    activities: state.activities,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    quizzes: state.quizzes,
    userClasses: state.userClasses,
    userBooksList: state.userBooksList,
    booksAllList: state.booksAllList,
    schools: state.schools,
    users: state.users,
    classUsers: state.classUsers,
    submissions: state.submissions,
    classNotes: state.classNotes,
    classQuestions: state.classQuestions,
    selectedUser: state.selectedUser,
    schoologyAuth: state.schoologyAuth,
});

export default connect(mapStateToProps, null)(LearnScreen);
