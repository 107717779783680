import _ from "lodash"

export default (_userBooksList, count = false)=>{
	var filteredBook= _.filter(_userBooksList,e=>e.isNotAssignable!=="true")

	if (count) {
		filteredBook= _.filter(_userBooksList,e=>e.count===true)
	}

    if(Array.isArray(filteredBook)){
        filteredBook.sort((a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
        return filteredBook
    }else{
        return []
    }

 }
