//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { lighten, invert } from "polished";

//  -- Components ---------------------
import { Drawer, Col, Dropdown, Menu, Button, Space } from "antd";
import {
  CheckCircle,
  Edit,
  Delete,
  KeyboardArrowDown,
} from "@material-ui/icons";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { map, filter, get, find } from "lodash";
//  -- Features -----------------------
import ActivityForm from "features/forms/Activity";
import QuizForm from "features/forms/Quiz";

//  -- Thunks -------------------------
import {
  handleUpdateClassQuiz,
  handleChangeStatusQuizzes,
  handleListClassQuizzes,
} from "store/classQuizzes/thunks";
import {
  handleListClassActivities,
  handleChangeStatusActivity,
  handleUpdateClassActivity,
} from "store/classActivities/thunks";
import Schoology from "utils/Schoology";
import { TootipWrapper } from "components/TooltipWrapper";
import {
  FormControlLabel,
  Switch,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import AssignmentForm from "features/forms/AssignmentForm";
import { DeleteModal } from "components/Dialog";
import { handleDeleteCustomAssignment } from "store/activities/thunks";
import { useSchoologyAssignment } from "store/schoologyauth/selectors";
import { imageFetch, imageFetchFromPodcast, isVideo, modifyAssignmentWithDiff } from "utils/lib/ebookHelper";
import { handleUpdateGoogleAssignment } from "store/googleClassRoom/thunks";
import { handleUpdateCanvasAssignment } from "store/canvas/thunks";
import { isValidColorCode } from "utils/methods/math";
import { handleCreateNotification } from "store/notification/thunks";
import { useSocket } from "store/chat/selectors";
import { UpdatedAssignedImg } from "utils/assets/images";
import SharedAndAssignedTask from "./SharedAndAssigned";
import { useAllDistrictTeacher } from "store/users/selectors";
import { useAllBook } from "store/books/selectors";
import { useResources } from "store/resources/selectors";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Row = styled.div`
  align-items: ${(p) => p.align || "center"};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`;

const Text = styled.span`
  color: ${COLOR.black500};
  margin-top: 2px;
  text-align: left;
  font-family: Arial;
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const TextLarge = styled(Text)`
  color: ${COLOR.green100};
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

const Wrapper = styled.div`
position: relative;
  background-color: ${(p) =>
    p.deactivated ? "#F5D9FF" : p.bgColor ? p.bgColor : "#C6E5E4"};
  border: 2px dashed
    ${(p) =>
      p.deactivated
        ? "#94ADD1"
        : p.bgColor
        ? lighten(0.2, invert(p.bgColor))
        : lighten(0.3, COLOR.green100)};
  border-radius: 4px;
  color: ${COLOR.green100};
  cursor: ${(p) => (p.disabled ? "no-drop" : "pointer")};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;

  &:hover {
    background-color: ${(p) =>
      p.deactivated ? "rgba(255,0,0,0.2)" : lighten(0.55, COLOR.green100)};
  }
`;
const BookDescRow = styled(Row)`
  font-size: 10px;
  padding-left: 40px;
  margin-top: -10px;
`;

const useStyles = makeStyles({
  root: {
    //   margin: "5px",
    // width: '175px'
  },
  sizeSmall: {
    width: "55px",
    height: "25px",
  },
  checkedSwitch: {
    transform: "translateX(30px) !important",
  },
  label: {
    textTransform: "uppercase",
    color: "#3E5F8F !important",
    fontFamily: "Arial !important",
    fontSize: "8px !important",
    fontWeight: "700 !important",
  },
});

const DropDownMenu = ({ data }) => {
  const items = data?.map((item, index) => ({
    key: index,
    label: item.displayName || "",
  }));

  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown menu={{ items }} arrow>
          <div style={{ display: "flex", alignItems: "center" }}>
            {data?.length > 1
              ? " Assigned to multiple class"
              : " Assigned to single class"}
            <KeyboardArrowDown style={{ fontSize: "medium" }} />
          </div>
        </Dropdown>
      </Space>
    </Space>
  );
};

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Announcement
//
//  -- Description
//  Announcement component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcement data={data} />
//
const AssignedNotification = ({
  assignment,
  listStudents,
  handleChangeMultiSelect,
  diffSection,
  setDiffSection,
  studentsName,
  setStudentsName,
  currentClass,
  onPreviewCick = () => null,
  booksAllList,
  isAssignedAllExam,
  viewType = ""
}) => {
  const bookAllList = useAllBook();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { socketConnection, reCreateSocket } = useSocket();
  const [activityDrawer, toggleActivityDrawer] = useState(false);
  const [quizDrawer, toggleQuizDrawer] = useState(false);
  const [activityData, setActivityData] = useState(assignment);
  const [quizData, setQuizData] = useState(assignment);
  const [studentsError, setStudentsError] = React.useState(false);
  const schoologyAuth = useSelector((state) => state.schoologyAuth);
  const currentUser = useSelector((state) => state.currentUser);
  const [dataLoading, setDataLoading] = useState(false);
  const [lateSection, setlateSection] = useState(false);
  const [durationSection, setDurationSection] = useState(false);

  const [editCustomAssignmentData, setEditCustomAssignmentData] =
    useState(null);
  const [editCustomAssignmentModal, setEditCustomAssignmentModal] =
    useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [schoologyAssignment, listSchoologyAssignment] =
    useSchoologyAssignment();
  const client = new Schoology(schoologyAuth);
  const isActive = get(assignment, "active", true);
  const bookDescription = get(assignment, "data.bookDescription");
  const colorCode = isValidColorCode(get(assignment, "data.colorCode"))
    ? get(assignment, "data.colorCode")
    : null;
  const allDistrictTeacher = useAllDistrictTeacher();
  const filterByTeacher = allDistrictTeacher.find(
    (item) => item.id === assignment.data.share_by
  );
  const filterByBook =
    bookAllList.find((book) => book.id === assignment.data.chapter) || {};
  const chapter = get(assignment, "data.chapter")
  const assignment_page =  get(assignment, "data.page")
  const resources = useResources()
  const modalSrc = useMemo(() => isVideo(chapter, resources) ? imageFetchFromPodcast(chapter, resources) : imageFetch(bookAllList, chapter, assignment_page),[resources,bookAllList]);
  const invokSend = (data) => {
    let socket = socketConnection ?? "";
    if (socket) {
      try {
        if (socket.readyState == 3) {
          reCreateSocket();
          setTimeout(() => {
            socket.send(JSON.stringify(data));
          }, 2000);
        } else {
          socket.send(JSON.stringify(data));
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const sendNotifications = async (notificationData) => {
    try {
      const updateNotification = await dispatch(
        handleCreateNotification(notificationData)
      );
      const messageCreate = {
        ...updateNotification,
        senderId: get(currentUser, "id", ""),
        receiverId: null,
        classId: get(currentClass, "id", ""),
        code: "system_notification",
      };
      invokSend(messageCreate);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateActivity = async (e) => {
    e.preventDefault();
    let toCreateStudent = [];
    let infoDiffSection = {};
    const notificationData = {
      classId: get(currentClass, "id", ""),
      displayName: get(activityData, "displayName", ""),
      userId: get(currentUser, "id", ""),
      modified: new Date(),
      data: {
        chapter: get(activityData, "data.chapter", ""),
        page: get(activityData, "data.page", ""),
        id: get(activityData, "data.id", ""),
      },
      message: `Your teacher Update this Activity`,
      createdBy: get(currentClass, "id", ""),
      type: "update_activity",
      seen: true,
    };
    if (activityData && activityData.sAssignmentId) {
      await updateAssignmentToSchoology(activityData);
    }
    if (activityData && activityData.canvas_id && currentClass.canvas_id) {
      await handleUpdateCanvasAssignment(
        currentUser.canvas_id,
        [
          {
            canvas_user_id: currentUser.canvas_id,
            canvas_id: activityData.canvas_id,
            canvas_section_id: currentClass.canvas_id,
            canvas_course_id: currentClass.canvas_course_id,
            due_date: get(activityData, "dueDate"),
            weightage: get(activityData, "weightage"),
            displayName: get(activityData, "displayName"),
            max_point: activityData.max_point,
          },
        ],
        currentUser.canvas_auth_id
      );
    }
    if (diffSection) {
      infoDiffSection = {
        diff: activityData.diff,
        retakesDif: activityData.retakesDif,
        dueDateDif: activityData.dueDateDif,
        scheduleAt: activityData.scheduleAtDiff || activityData.scheduleAt,
        lateDate: activityData.lateDate,
      };
      const diffstudent = map(activityData.diff, (item) => item.userId);
      const studentArray = createArrayStudents(studentsName);
      toCreateStudent = studentArray.filter(
        (item) => !diffstudent.includes(item.studentId)
      );
    } else {
      infoDiffSection = {
        diff: [],
        retakesDif: "",
        dueDateDif: "",
      };
    }
    setDataLoading(true);
    let updateActivityData = {
      ...activityData,
      retakes: activityData.retakes && activityData.retakes.trim(),
      dueDate: activityData.dueDate,
      lateDate: activityData.lateDate,
      timeDuration: activityData.timeDuration,
      classes: activityData.classes,
      weightage: activityData.weightage,
      displayName: activityData.displayName,
      classId: activityData.classId,
    };
    if (currentClass.googleId && assignment.googleId) {
      await handleUpdateGoogleAssignment(
        currentUser.googleId,
        assignment,
        currentClass.googleId,
        assignment.googleId
      );
    }
    await dispatch(
      handleUpdateClassActivity(
        assignment?.id,
        updateActivityData,
        infoDiffSection,
        toCreateStudent,
        activityData,
        activityData.updateTOAllClass
      )
    );

    await dispatch(handleListClassActivities(currentClass.id));
    await dispatch(handleListClassQuizzes(currentClass.id));
    sendNotifications(notificationData);
    toggleActivityDrawer(false);
    setDataLoading(false);
    setDiffSection(false);
    setStudentsName([]);
    setActivityData({});
  };
  const updateAssignmentToSchoology = async (quizeDataforCreate) => {
    let sectionId = currentClass.sectionId;
    const classConfig = find(quizeDataforCreate.classes, [
      "id",
      currentClass.id,
    ]);
    if (classConfig && classConfig.sectionId) {
      sectionId = classConfig.sectionId;
    }
    if (currentUser.schoologyid && sectionId) {
      client.setToken(schoologyAuth);
      let data = {
        title: quizeDataforCreate.displayName,
        due: `${quizeDataforCreate.dueDate}`,
        grading_category: get(classConfig, "schoologyCategory")
          ? get(classConfig, "schoologyCategory")
          : quizeDataforCreate.schoologyCategory,
      };
      const v = await client.updateAssignment(
        sectionId,
        quizeDataforCreate.sAssignmentId,
        data
      );
      listSchoologyAssignment(sectionId);
      return v;
    }
    return null;
  };
  function createArrayStudents(studentsName) {
    let arrayStudents = [];
    for (var s in studentsName) {
      let finded = listStudents?.find((e) => e.description == studentsName[s]);
      if (finded) {
        let name =
          finded.description?.includes("(") && finded.description?.includes(")")
            ? finded.description.substring(0, finded.description.indexOf("("))
            : finded.description;
        arrayStudents.push({
          studentId: finded.id,
          description: name,
        });
      }
    }
    return arrayStudents;
  }
  const updateQuiz = async (e) => {
    e.preventDefault();
    let infoDiffSection = {};
    let toCreateStudent = [];
    const notificationData = {
      classId: get(currentClass, "id", ""),
      displayName: get(quizData, "displayName", ""),
      userId: get(currentUser, "id", ""),
      modified: new Date(),
      data: {
        chapter: get(quizData, "data.chapter", ""),
        page: get(quizData, "data.page", ""),
        id: get(quizData, "data.id", ""),
      },
      message: `Your teacher Update this Quiz`,
      createdBy: get(currentClass, "id", ""),
      type: "update_quiz",
      seen: true,
    };
    if (quizData && quizData.sAssignmentId) {
      await updateAssignmentToSchoology(quizData);
    }
    if (quizData && quizData.canvas_id && currentClass.canvas_id) {
      const assignmentData = [
        {
          canvas_user_id: currentUser.canvas_id,
          canvas_id: quizData.canvas_id,
          canvas_section_id: currentClass.canvas_id,
          canvas_course_id: currentClass.canvas_course_id,
          due_date: get(quizData, "dueDate"),
          weightage: get(quizData, "weightage"),
          displayName: get(quizData, "displayName"),
          max_point: quizData.max_point,
        },
      ];
      await handleUpdateCanvasAssignment(
        currentUser.canvas_id,
        assignmentData,
        currentUser.canvas_auth_id
      );
    }
    if (setDiffSection) {
      infoDiffSection = {
        diff: quizData.diff,
        retakesDif: quizData.retakesDif,
        dueDateDif: quizData.dueDateDif,
        levelExercise: quizData.levelExercise,
        scheduleAt: quizData.scheduleAtDiff || quizData.scheduleAt,
        lateDate: quizData.lateDate,
      };
      const diffstudent = map(quizData.diff, (item) => item.userId);
      const studentArray = createArrayStudents(studentsName);
      toCreateStudent = studentArray.filter(
        (item) => !diffstudent.includes(item.studentId)
      );
    } else {
      infoDiffSection = {
        diff: [],
        retakesDif: "",
        dueDateDif: "",
        levelExercise: "",
      };
    }
    setDataLoading(true);
    await dispatch(
      handleUpdateClassQuiz(
        quizData.id,
        {
          ...quizData,
          retakes: quizData.retakes && quizData.retakes.trim(),
          dueDate: quizData.dueDate,
          lateDate: quizData.lateDate,
          timeDuration: quizData.timeDuration,
          classes: quizData.classes,
          weightage: quizData.weightage,
          displayName: quizData.displayName,
          scheduleAt: quizData.scheduleAt,
          classId: quizData.classId,
          schoologyCategory: quizData.schoologyCategory,
        },
        infoDiffSection,
        toCreateStudent,
        quizData,
        quizData.updateTOAllClass
      )
    );

    await dispatch(handleListClassActivities(currentClass.id));
    await dispatch(handleListClassQuizzes(currentClass.id));
    sendNotifications(notificationData);
    setDataLoading(false);
    toggleQuizDrawer(false);
    setDiffSection(false);
    setStudentsName([]);
    setQuizData({});
  };
  const activeDeactiveQuizzes = async (id, active) => {
    await dispatch(handleChangeStatusQuizzes(id, active));
  };
  const activeDeactiveActivites = async (id, active) => {
    await dispatch(handleChangeStatusActivity(id, active));
  };
  const updateActivityData = (id, value) => {
    const val =
      value && value.length > 1 && value[0] === "0"
        ? value.replace("0", "")
        : value;
    setActivityData({
      ...activityData,
      [id]: val,
    });
  };

  const updateQuizData = (id, value) => {
    const val =
      value && value.length > 1 && value[0] === "0"
        ? value.replace("0", "")
        : value;
    setQuizData({
      ...quizData,
      [id]: val,
    });
  };

  const openEditQuiz = () => {
    let quiz = assignment;
    if (!isNaN(parseInt(quiz.timeDuration)) && quiz.timeDuration > 0) {
      setDurationSection(true);
    }
    if (quiz && quiz.lateDate && quiz.lateDate.length > 0) {
      setlateSection(true);
    }
    if (get(quiz, "diff.length", 0) > 0) {
      if (get(quiz, "userDiff.length", 0) > 0) {
        let users = quiz.userDiff;
        let stuName = [];
        for (var u in users) {
          if (users[u]) {
            let name = `${users[u].lastName}  ${users[u].firstName} (${users[u].username})`;
            stuName.push(name);
          }
        }
        setStudentsName(stuName);
      } else {
        setStudentsName([]);
      }
      setDiffSection(true);
      quiz = modifyAssignmentWithDiff(quiz, currentClass);
      setQuizData({
        editing: true,
        dueDateDif: quiz.diff[0].dueDate,
        retakesDif: quiz.diff[0].retakes,
        levelExercise: quiz.diff[0].levelExercise,
        scheduleAtDiff: quiz.diff[0].scheduleAt,
        ...quiz,
      });
      toggleQuizDrawer(true);
    } else {
      setQuizData({
        editing: true,
        ...quiz,
      });
      toggleQuizDrawer(true);
    }
  };

  const openEditActivity = () => {
    let activity = assignment;
    if (!isNaN(parseInt(activity.timeDuration)) && activity.timeDuration > 0) {
      setDurationSection(true);
    }
    if (activity && activity.lateDate && activity.lateDate.length > 0) {
      setlateSection(true);
    }
    if (get(activity, "diff.length", 0) > 0) {
      if (get(activity, "userDiff.length", 0) > 0) {
        let users = activity.userDiff;
        let stuName = [];
        for (var u in users) {
          if (users[u]) {
            let name = `${users[u].lastName}  ${users[u].firstName} (${users[u].username})`;
            stuName.push(name);
          }
        }
        setStudentsName(stuName);
      } else {
        setStudentsName([]);
      }
      setDiffSection(true);
      activity = modifyAssignmentWithDiff(activity, currentClass);
      setActivityData({
        editing: true,
        dueDateDif: activity.diff[0].dueDate,
        retakesDif: activity.diff[0].retakes,
        scheduleAtDiff: activity.diff[0].scheduleAt,
        ...activity,
      });
      toggleActivityDrawer(true);
    } else {
      setActivityData({
        editing: true,
        ...activity,
      });
      toggleActivityDrawer(true);
    }
  };
  const onCloseDrawer = () => {
    toggleQuizDrawer(false);
    toggleActivityDrawer(false);
    setQuizData({});
    setActivityData({});
    setStudentsError(false);
    setDataLoading(false);
    setlateSection(false);
    setDurationSection(false);
    setDiffSection(false);
    handleChangeMultiSelect({ target: { value: [] } });
  };
  const deleteCustomAssignment = async () => {
    setDeleteLoading(true);
    await dispatch(
      handleDeleteCustomAssignment(get(editCustomAssignmentData, "id"))
    );
    setDeleteLoading(false);
    setDeleteModal(false);
    setEditCustomAssignmentData({});
    const notificationData = {
      classId: get(currentClass, "id", ""),
      displayName: get(editCustomAssignmentData, "displayName", ""),
      userId: get(currentUser, "id", ""),
      modified: new Date(),
      data: {
        chapter: get(editCustomAssignmentData, "chapter", ""),
        page: get(editCustomAssignmentData, "page", ""),
        id: get(editCustomAssignmentData, "id", ""),
      },
      message: `Your teacher Delete this Assignment`,
      createdBy: get(currentClass, "id", ""),
      type: "delete_activity",
      seen: true,
    };
    sendNotifications(notificationData);
  };


  return (
    <>
      <Drawer
        height="100%"
        destroyOnClose
        onClose={onCloseDrawer}
        placement="top"
        visible={quizDrawer}
        key="quizNew"
        className="assignments-drawer"
      >
        <>
          {quizDrawer && (
            <QuizForm
              data={{ ...quizData, editing: true }}
              handleChange={(e) => updateQuizData(e.target.id, e.target.value)}
              handleChangeLevel={(e) =>
                updateQuizData("levelExercise", e.target.value)
              }
              handleSubmit={updateQuiz}
              listStudents={listStudents}
              handleChangeMultiSelect={handleChangeMultiSelect}
              diffSection={diffSection}
              lateSection={lateSection}
              setlateSection={setlateSection}
              setDurationSection={setDurationSection}
              durationSection={durationSection}
              setDiffSection={setDiffSection}
              studentsName={studentsName}
              studentsError={studentsError}
              onClose={() => toggleQuizDrawer(false)}
              booksAllList={booksAllList}
            />
          )}
        </>
      </Drawer>
      <Drawer
        height="100%"
        destroyOnClose
        onClose={onCloseDrawer}
        placement="top"
        visible={activityDrawer}
        key="activityNew"
        className="assignments-drawer"
      >
        <>
          {activityDrawer && (
            <ActivityForm
              data={{ ...activityData, editing: true }}
              handleChange={(e) =>
                updateActivityData(e.target.id, e.target.value)
              }
              handleSubmit={updateActivity}
              listStudents={listStudents}
              handleChangeMultiSelect={handleChangeMultiSelect}
              diffSection={diffSection}
              setDiffSection={setDiffSection}
              setDurationSection={setDurationSection}
              durationSection={durationSection}
              lateSection={lateSection}
              setlateSection={setlateSection}
              studentsName={studentsName}
              studentsError={studentsError}
              loading={dataLoading}
              onClose={() => toggleActivityDrawer(false)}
              booksAllList={booksAllList}
            />
          )}
        </>
      </Drawer>
      <DeleteModal
        message={`Are you sure to delete , You will loss all submissions and grades for this assignment?`}
        deleteToggle={deleteModal}
        setDeleteToggle={setDeleteModal}
        loading={deleteLoading}
        onDelete={deleteCustomAssignment}
      />
      <AssignmentForm
        bookId={get(editCustomAssignmentData, "chapter")}
        pageId={get(editCustomAssignmentData, "page")}
        open={editCustomAssignmentModal}
        data={editCustomAssignmentData}
        onClose={() => {
          setEditCustomAssignmentModal(false);
          setEditCustomAssignmentData({});
        }}
      />
      <TootipWrapper
        hasToolTip={isAssignedAllExam}
        label={`Please edit this ${
          assignment?.data?.typeId === "activity" ? "activity" : "quiz"
        } with Entire exam button.`}
      >
        <Wrapper
          style={{ paddingRight: "25px", marginTop: viewType === "balcon" ? "15px"  : "6px" }}
          disabled={isAssignedAllExam}
          deactivated={!isActive}
          bgColor={colorCode}
        >
          <SharedAndAssignedTask
            isSharedActive={
              currentUser?.id !== assignment?.data?.share_by &&
              assignment?.data?.share_by !== "" &&
              !!filterByTeacher
            }
            isAssignedActive={true}
            sharedBy={`${filterByTeacher?.firstName} ${filterByTeacher?.lastName}`}
          />

          <Row
            align="center"
            style={{ marginBottom: 10, justifyContent: "space-between" }}
          >
            <Col
              span={get(assignment, "data.type") === "custom" ? 20 : 24}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "13px",
              }}
            >
              {/* <CheckCircle style={{ height: '18px', marginRight: '4px' }} /> */}
              <img
                src={UpdatedAssignedImg}
                width="18px"
                height={"18px"}
                style={{ marginRight: "9px" }}
                alt="assigned-note"
              />
              <Title className="assigned-task-name">
                {get(
                  assignment,
                  "displayName",
                  get(assignment, "data.displayName"),
                  ""
                )}
              </Title>
            </Col>
            {get(assignment, "data.type") === "custom" && get(assignment, "data.activity_type") != "graphic_organizer"? (
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  color="error"
                  style={{ padding: 0, marginRight: 7 }}
                  onClick={() => {
                    setDeleteModal(true);
                    setEditCustomAssignmentData(assignment.data);
                  }}
                >
                  <Delete style={{ fontSize: "26px" }} color="error" />
                </IconButton>
                <IconButton
                  color="primary"
                  style={{ padding: 0 }}
                  onClick={() => {
                    setEditCustomAssignmentModal(true);
                    setEditCustomAssignmentData(assignment.data);
                  }}
                >
                  <Edit style={{ fontSize: "26px" }} />
                </IconButton>
              </Col>
            ) : null}
          </Row>
          <img  className="assigned-image-book" src={modalSrc} width="" height={""} alt="" />
          {/* {bookDescription ? (
            <BookDescRow>
              <strong>Book</strong>: {bookDescription}
            </BookDescRow>
          ) : null} */}
          <Row style={{ paddingLeft: 10, marginBottom: 0 }}>
            <FormControlLabel
              classes={{
                root: classes.root,
                label: classes.label,
              }}
              control={
                <Switch
                  defaultChecked={isActive}
                  value={isActive}
                  onChange={(e) => {
                    if (
                      get(assignment, "data.typeId") == "quiz" ||
                      get(assignment, "data.typeId") === "quizresource"
                    ) {
                      activeDeactiveQuizzes(get(assignment, "id"), !isActive);
                    } else {
                      activeDeactiveActivites(get(assignment, "id"), !isActive);
                    }
                  }}
                  name="view"
                  color="primary"
                  size="small"
                />
              }
              label="Deactivate / Activate"
            />
          </Row>
          <Row className={`${viewType}-start-due-date`} style={{ marginBottom: 0 }}>
            <>
              {get(assignment, "scheduleAt") ? (
                <Text className={`${viewType}-assigned-start-date`}>
                  <strong style={{ color: COLOR.black950 }}>
                    Start Date:{" "}
                  </strong>{" "}
                  <label style={{ color: "#676B71"}}>
                  {moment(get(assignment, "scheduleAt")).format(
                    "MMM DD, YYYY hh:mm A"
                  )}
                  </label>
                </Text>
              ) : null}
              <Text className={`${viewType}-assigned-end-date assigned-due-date`}>
                <strong   style={{ color: COLOR.black950 }}>Due Date: </strong>
                <label style={{ color: "#676B71"}}>
                {moment(get(assignment, "dueDate")).format(
                  "MMM DD, YYYY hh:mm A"
                )}
                </label>
              
              </Text>
            </>
          </Row>
          <Row style={{ marginBottom: 0 }}></Row>
          {get(assignment, "lateDate") ? (
            <Row style={{ marginBottom: 0 }}>
              <Text>
                <strong style={{ color: COLOR.black300 }}>
                  Late Submission:{" "}
                </strong>
                {moment(get(assignment, "lateDate")).format(
                  "MMM DD, YYYY hh:mm A"
                )}
              </Text>
            </Row>
          ) : null}
          <div className="status-text">
            <div className="status-container">
              <label className="status-text">Status:&nbsp;</label>
              <div
                className="assigned-class-text"
                style={{ display: "flex", alignItems: "center" }}
              >
                <DropDownMenu data={assignment?.classes} />
              </div>

              {filterByBook.id&&assignment.data.chapter !== filterByBook.id && (
                <div className="book-name-text">
                <>
                  &nbsp;Book: <label>{filterByBook?.description}</label>
                </>
              </div>
              )}
              
            </div>
          </div>
          <div className="preview-edit-container">
            <TextLarge className="preview-edit-text" onClick={onPreviewCick}>
              Preview |&nbsp;
            </TextLarge>
            <TextLarge
              className="preview-edit-text"
              onClick={() => {
                if (isAssignedAllExam) {
                  return;
                }
                if (assignment?.data?.typeId === "activity") {
                  openEditActivity();
                } else {
                  openEditQuiz();
                }
              }}
            >
              Edit
            </TextLarge>
          </div>
        </Wrapper>
      </TootipWrapper>
    </>
  );
};

//  -- Prop types ---------------------
AssignedNotification.propTypes = {};

//  -- Default props ------------------
AssignedNotification.defaultProps = {
  fontSize: 10,
};

export default AssignedNotification;
