//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import { has } from "lodash";
//  -- Actions ------------------------
import * as Sentry from "@sentry/react";
import { sortBy } from "lodash";
import * as VocabularyActions from './actions';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create vocabulary --------------------
const onFilterVocabularyStd = (bookVocabulary, bookId, pageId, currentUser) => {
    // const filterVocab = filter(bookVocabulary, obj => obj.chapter == bookId && obj.page == pageId && obj.created_by == 'student' && obj.user_id == currentUser);
    let sorted_obj = sortBy(bookVocabulary,
        [
            function (o) {
                // Ensuring the property exists and is a string
                if (o.data && typeof o.data.word === 'string') {
                    // Converting to lowercase for case-insensitive comparison
                    return o.data.word.toLowerCase();
                }
                return ''; // Default value in case the property doesn't exist
            }
        ]);
    return sorted_obj;
}
export const handleCreateVocabulary = (data) => async (dispatch) => {
    await dispatch(VocabularyActions.createVocabulary());
    try {
        const vocabularyData = await API.post('vocabularyRDS', `/addVocabulary`, {
            body: data
        });
        if (data.id) {
            
            await dispatch(VocabularyActions.updateVocabularySuccess(vocabularyData));
        } else {
            await dispatch(VocabularyActions.createVocabularySuccess(vocabularyData));
        }
        return vocabularyData
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

//  -- List vocabulary ----------------
export const handleListVocabularyNew = (data, bookId, pageId, currentUser) => async (dispatch) => {
    // await dispatch(VocabularyActions.listVocabulary());
    try {
        const vocabularyRDS = await API.post('vocabularyRDS', `/listVocabulary`, {
            body: data
        });
        let vocabularyupdatasort = onFilterVocabularyStd(vocabularyRDS, bookId, pageId, currentUser)
        await dispatch(VocabularyActions.listVocabularySuccess(vocabularyupdatasort));

      
        return vocabularyRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};


export const setDataVocabular = (data) => async (dispatch) => {
    await dispatch(VocabularyActions.listVocabularySuccess(data));
    

}
function parseResponse(responseString) {
    const cleanedString = typeof responseString === "string"?responseString.replace(/```json\n|\n```|\\n/g, ''):null;
    if(typeof responseString === "object" && Object.keys(responseString).length>0) return responseString;
    try {
        return JSON.parse(cleanedString);
    } catch (error) {
        console.error("Failed to parse response:", error);
        return null; // or handle the error as you see fit
    }
}
//  -- Create vocabulary AI --------------------
export const handleCreateVocabularyAI = (data) => async (dispatch) => {
    try {
        const vocabularyData = await API.post('vocabularyRDS', `/vocabulary_ai`, {
            body: data
        });
        // return parseResponse(vocabularyData)
        if (vocabularyData && vocabularyData.id) {
            await dispatch(VocabularyActions.createVocabularySuccess(vocabularyData));
        }
        return vocabularyData;
    } catch (error) {
        if (error) {
            Sentry.captureException(error)
            return null;
        }
    }
};
export const handleGenerateVocabularyWithAI = (data) => async (dispatch) => {
    try {
        const vocabularyData = await API.post('vocabularyRDS', `/generate_vocabulary`, {
            body: data
        });
        if (vocabularyData && vocabularyData.id) {
            await dispatch(VocabularyActions.createVocabularySuccess(vocabularyData));
        }
        return vocabularyData;
    } catch (error) {
        if (error) {
            Sentry.captureException(error)
            return null;
        }
    }
};

export const handleGenerateVocabularyWithMode = (data) => async (dispatch) => {
    try {
        const vocabularyData = await API.post('vocabularyRDS', `/generate_vocabulary_with_mode`, {
            body: data
        });
        if (vocabularyData && vocabularyData.id) {
            await dispatch(VocabularyActions.createVocabularySuccess(vocabularyData));
        }
        return vocabularyData;
    } catch (error) {
        if (error) {
            Sentry.captureException(error)
            return null;
        }
    }
};


//  -- List vocabulary ----------------
export const handleListVocabulary = ( bookId, pageId, currentUser) => async (dispatch) => {
    // await dispatch(VocabularyActions.listVocabulary());
    try {
        const vocabularyRDS = await API.get('vocabularyRDS', `/getVocabulary`);
        let vocabularyupdatasort = onFilterVocabularyStd(vocabularyRDS, bookId, pageId, currentUser)

        
        await dispatch(VocabularyActions.listVocabularySuccess(vocabularyupdatasort));
        return vocabularyRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};


//  -- Delete Book vocabulary ----------
export const handleDeleteVocabulary = (id) => async (dispatch) => {
    if (!id) return;
    dispatch(VocabularyActions.deleteVocabulary())
    try {
        const vocabulary = await API.del('vocabularyRDS', `/deleteVocabulary/${id}`);
        if (has(vocabulary, "id")) {
            await dispatch(VocabularyActions.deleteVocabularySuccess(vocabulary));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};


export const handleIdeaFeach = async (topic) => {
    try {
        const data = await API.post('vocabularyRDS', `/idea`, {
            body: { topic }
        });
        return data;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};


export const handleTextToSpeech = async (datas) => {
    try {
        const data = await API.post('textToSpeech', `/text_to_speach_token`, {
            body: datas 
        });
        return data;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};


//  -- List vocabulary ----------------
export const handleListVocabularyFetch = () => async (dispatch) => {
    try {
        const vocabularyRDS = await API.get('vocabularyRDS', `/fetch_antonyms`);
        return vocabularyRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
