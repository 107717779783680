//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const invokeFeedback = () => ({ type: ActionTypes.feedback.invokeFeedback });
export const revokeFeedback = () => ({ type: ActionTypes.feedback.revokeFeedback });
export const createFeedback = (returnedPayload) => ({ type: ActionTypes.feedback.createFeedback, payload: returnedPayload });
export const feedbackSuccess = (returnedPayload) => ({ type: ActionTypes.feedback.createFeedbackSuccess, payload: returnedPayload });

//  -- List All books ----------------
