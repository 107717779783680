//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, IconButton, Modal, TextField } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Add, Close, Delete, Edit } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import { DeleteModal } from 'components/Dialog';
import Loading from "components/Loading";
import Section from 'components/Section';
import { get, map } from "lodash";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useBookOrder } from 'store/books/selectors';
import { handleUpdateListBookOrder } from 'store/books/thunks';
import styled from 'styled-components';
import { groupBookByOrder } from '../../utils/lib/ebookHelper';
import OrderBookGroup, { BookSignleList } from './orderBookGroup';
//  -- Features -----------------------



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;

const ModalContent = styled.div`
  left: 50%;
  width: 70vw;
  padding: 0px 24px;
  position: absolute;
//   height:10vh;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
`;
const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;

const BookSection = styled.div`
    border: 2px solid #e9e9e9;
    box-shadow: ${(p) => p.active ? p.preActive ? "-3px 2px 8px 0px #9d0001, 3px 1px 5px #9d0000;" : " -3px 2px 8px 0px #00989d, 3px 1px 5px #00989d;" : ""};
    margin-bottom: 26px;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    width: fit-content;
    margin-right: 16px;
    padding-bottom: 16px;
    
`;

const SectionTitle = styled.div`
    font-size: 14px;
    color: #ffffff;
    background: ${p => p.bgColor ? p.bgColor : '#00c4cc'};
    padding: 4px 8px;
    border-radius: 6px !important;
    cursor: pointer;

    position: absolute;
    top: -10px;
    left: -6px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const BookGrouping = ({ open, close, data, booksAllList, onCreateOrUpdate = () => { }, loading, orderedBook }) => {
    const [examTitle, setExamTitle] = useState('');
    const [isDisabled, setIsDisabled] = useState(false)
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')
    const order = data?.books.map(obj => obj.id)
    const [selectedBookOrder, setSelectedBookOrder] = useState([]);
    const bookOrders = selectedBookOrder.length > 0 ? selectedBookOrder : order;

    const onValidate = () => {


        if (examTitle) {
            if (bookOrders && bookOrders.length > 0) {
                setError(false);
                onCreateOrUpdate(examTitle, bookOrders);
                setExamTitle("");
                setSelectedBookOrder([]);
            } else {
                setError(true);
                setErrorMsg("Please Select Books");
            }
        } else {
            setError(true);
            setErrorMsg("Please Enter Group Name and Select Books");
        }
    };
    useEffect(() => {
        setExamTitle(get(data, 'title'))
    }, [data])
    // const isDisabled = !get(examData, "src") || !get(examData, "displayName") || !get(examData, "bookId")
    return (
        <Modal
            open={open}
            onClose={() => {
                setSelectedBookOrder([])
                setError(false)
                close()
            }}
            style={{ height: '100%', width: '100%' }}
        >
            <ModalContent>
                <Row style={{ justifyContent: "space-between" }}>
                    <TitleText>{data ? 'Update Book Grouping' : 'Add Book Grouping'}</TitleText>
                    <IconButton onClick={() => {
                        setSelectedBookOrder([])
                        setError(false)
                        close()
                    }} style={{ padding: 0, marginRight: 10 }}>
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <Row>
                        <Col lg={12} xs={24} style={{ paddingLeft: 10, paddingTop: 10 }}>
                            <Row justify="start" style={{ marginBottom: 10, }}>
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    label="Group name"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        setExamTitle(e.target.value)
                                    }}
                                    value={examTitle}
                                />
                            </Row>
                        </Col>
                        <BookSignleList data={booksAllList} selected={order ? order : []} orderedBook={orderedBook} onSelect={(item) => {

                            if (item.length > 0) {
                                setIsDisabled(false)
                                setSelectedBookOrder(item)
                            } else {
                                setIsDisabled(true)
                                setSelectedBookOrder([])
                            }
                        }} />
                    </Row>
                    {error && (
                        <Row style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                            <span style={{ color: 'red', textAlign: 'center' }}>{errorMsg}</span>
                        </Row>
                    )}
                    <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                        <Button fullWidth
                            onClick={() => {
                                onValidate()
                            }}
                            disabled={isDisabled}
                            variant="contained" color="primary">
                            {loading ? <Loading white size={15} /> : null}
                            {data ? "Update" : "Create"}
                        </Button>
                    </Row>
                </ModalConfigArea>
            </ModalContent>
        </Modal>
    );
};


const AssessmentContainer = ({
    currentUser,
    booksAllList,
}) => {
    const dispatch = useDispatch()
    const [loadingModal, setLoadingModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const bookOrder = useBookOrder()
    const [activeIndex, setActiveIndex] = useState();
    const [selectedExam, setSelectedExam] = useState();
    const [openEditExamModal, setOpenEditExamModal] = React.useState(false);
    const [deleteLodaing, setDeleteLoading] = useState(false)
    const [openDeleteExamModal, setOpenDeleteExamModal] = React.useState(false);
    const [allBookOrder, setAllBookOrder] = useState(bookOrder);
    const orderedBook = groupBookByOrder(booksAllList, allBookOrder);
    const [state, setState] = React.useState({
        open: false,
        message: ""
    });

    const handleClose = () => {
        setState({ open: false, message: "" });
    };
    const onEditGroup = (grpData, index) => {
        setSelectedExam(grpData)
        setActiveIndex(index)
        setOpenEditExamModal(true)
    }
    const onClose = () => {
        setOpenEditExamModal(false)
        setSelectedExam(null)
        setActiveIndex(null)
    }
    const onDeleteExam = async () => {
        const updatedBookOrder = [...allBookOrder];
        updatedBookOrder.splice(activeIndex, 1);
        setDeleteLoading(true)
        const updateList = await dispatch(handleUpdateListBookOrder(updatedBookOrder))
        setActiveIndex(null)
        setSelectedExam(null)
        setDeleteLoading(false)
        setOpenDeleteExamModal(false)
        setAllBookOrder(updateList)
    }
    const { open, message } = state;

    const onSubmit = async (examTitle, bookOrders) => {
        if (selectedExam) {
            const updateData = {
                color: selectedExam.color,
                order: bookOrders,
                title: examTitle
            }

            allBookOrder[activeIndex] = updateData;
            setLoadingModal(true)
            const updateList = await dispatch(handleUpdateListBookOrder(allBookOrder))

            setAllBookOrder(updateList)
            setSelectedExam(null)
            setLoadingModal(false)
            onClose()
        } else {
            const updateData = {
                color: 'rgb(53,90,92)',
                order: bookOrders,
                title: examTitle
            }
            let dummyOrder = allBookOrder;
            dummyOrder = [...dummyOrder, updateData]
            setLoadingModal(true)
            const updateList = await dispatch(handleUpdateListBookOrder(dummyOrder))

            setAllBookOrder(updateList)
            setLoadingModal(false)
            onClose()
            // console.log('create', updateData);
        }
    }
    const onRearrangedBookOrder = async (index, orderbook) => {
        const newOrd = [...bookOrder];
        const ord = orderbook.map(k => k.id)
        if (!newOrd || !newOrd[index]) return;
        newOrd[index].order = ord;
        setLoadingModal(true)
        const updateList = await dispatch(handleUpdateListBookOrder(newOrd))
        setAllBookOrder(updateList)
        setLoadingModal(false)
    }
    return (
        <Wrapper>
            {loading ? (
                <Loading />
            ) : (<>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        <strong>ALERT:</strong> {message}
                    </Alert>
                </Snackbar>
                <DeleteModal
                    deleteToggle={openDeleteExamModal}
                    onDelete={onDeleteExam}
                    message={`Are you sure you want to delete ${get(selectedExam, "title", "this Exam")}?`}
                    setDeleteToggle={() => {
                        setOpenDeleteExamModal(false)
                    }} loading={deleteLodaing} />
                <BookGrouping
                    open={openEditExamModal}
                    close={onClose}
                    data={selectedExam}
                    bookOrder={bookOrder}
                    activeIndex={activeIndex}
                    orderedBook={orderedBook}
                    // exam={activeExamData}
                    loading={loadingModal}
                    booksAllList={booksAllList}
                    // redirectToBook={redirectToBook}
                    onCreateOrUpdate={(examTitle, bookOrders) => {
                        if (examTitle && bookOrders && bookOrders.length > 0) {
                            onSubmit(examTitle, bookOrders)
                        } else {
                            console.log('error')
                        }
                    }}
                />
                <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0, marginBottom: 30 }}>
                    <Button onClick={() => {
                        setActiveIndex(null)
                        setOpenEditExamModal(true)
                    }} color='primary' variant="contained" >
                        <Add /> Add Book Grouping
                    </Button>
                </Row>
                <Row style={{ marginTop: '1%' }}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Section style={{ fontSize: '18px' }}
                            description="Resources books"
                            header='Resources'>
                            {map(orderedBook, (books, index) => {
                                return (
                                    <LinkArea>
                                        <Row>
                                            <Col span={24}>
                                                <Button onClick={() => {
                                                    onEditGroup(books, index)
                                                }} color='primary' variant="contained" >
                                                    <Edit style={{ fontSize: 20, marginRight: 3 }} /> EDIT
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setOpenDeleteExamModal(true)
                                                        setSelectedExam(books);
                                                        setActiveIndex(index);
                                                    }}
                                                    color="secondary"
                                                    variant="contained"
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    <Delete style={{ fontSize: 20, marginRight: 3 }} /> Delete
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '1%', marginLeft: 20 }}>
                                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                                <BookSection>
                                                    <SectionTitle bgColor={books.color} >{books.title}</SectionTitle>
                                                    <OrderBookGroup chapter={books?.books} onRearrangeDone={(orderbook)=>onRearrangedBookOrder(index,orderbook)} />
                                                </BookSection>
                                            </Col>
                                        </Row>
                                    </LinkArea>
                                )
                            })}
                        </Section>
                    </Col>
                </Row>
            </>)}

        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentContainer.propTypes = {};

//  -- Default props ------------------
AssessmentContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser,
    userBooksList: state.userBooksList,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    booksAllList: state.booksAllList
});

export default connect(mapStateToProps, null)(AssessmentContainer);
