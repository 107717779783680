//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from "aws-amplify";

//  -- Actions ------------------------
import * as Actions from "./actions";
import * as NotificationActions from "../notification/actions";
import * as Sentry from "@sentry/react";
import { isArray, has, isEmpty, get } from "lodash";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const handleGetChatUsers = (userId) => async (dispatch) => {
  try {
    const chatUsers = await API.get("chat", `/users/${userId}`);
    await dispatch(Actions.setChatUser(chatUsers));
    return chatUsers;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    return error;
  }
};

export const handleMessageChat = (message) => async (dispatch) => {
  try {
    let data = {};
    if (typeof message == "object") {
      data = message;
    } else {
      data = JSON.parse(message);
    }
    if (data.code == "connect") {
      await dispatch(Actions.setUserActiveStatus(data.userId));
    } else if (data.code == "disconnect") {
      await dispatch(Actions.setUserDeactiveStatus(data.userId));
    } else if (data.code == "message") {
      await dispatch(handleUpdateOnCreateMessageChat(data));
    } else if (
      data.code === "seen_deliver_update" ||
      data.code === "seen_deliver_update_brodcast"
    ) {
      if (isArray(data.data) && data.data.length > 0) {
        await dispatch(Actions.updateSeenDelivery(data.data));
      }
    } else if (data.code === "sent") {
      if (typeof data.data == "object" && has(data.data, "id")) {
        await dispatch(Actions.updateSeenDelivery([data.data]));
      }
    } else if (data.code === "user_config_updated") {
      if (!isEmpty(data.config) && data.config.id) {
        await dispatch(Actions.updateUsersConfigStatus(data.config));
      }
    } else if (data.code === "seen_deliver_dispatched") {
      if (has(data, "with")) {
        await dispatch(
          Actions.updateUserSeenMessageByUserId(get(data, "with"))
        );
      }
    } else if (data.code === "system_notification_dispatched") {
      await dispatch(
        NotificationActions.updateNotificationSuccess(get(data, "data", ""))
      );
    } else if (data.code === "system_notification") {
      await dispatch(NotificationActions.updateNotificationSuccess(data));
    }
  } catch (error) {
    console.log(error, "rrr");
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
  }
};

export const handleSendMessageChat = (message) => async (dispatch) => {
  try {
    dispatch(Actions.createMessage(message));
    // const messageCreated = await API.post("chat", "/createMessage", {
    // 	body: message
    // })
    // if(get(messageCreated,"id")){
    // 	dispatch(Actions.setMessageStored(get(messageCreated,"id")))
    // }
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    console.log(error, "erere");
  }
};
export const handleUpdateOnCreateMessageChat =
  (message) => async (dispatch) => {
    dispatch(Actions.createMessage(message));
    dispatch(Actions.removeScheduledMessage(message.id));
  };
export const handleUpdateSeenMessageByUserId = (userId) => async (dispatch) => {
  dispatch(Actions.updateUserSeenMessageByUserId(userId));
};
export const handleGetMessagesChat = (userId) => async (dispatch) => {
  try {
    const allMessages = await API.get("chat", "/getMessage/" + userId);
    dispatch(Actions.setMessage(allMessages));
    return allMessages;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    return null;
  }
};
export const handleSetMessageSeen = (messageId) => async (dispatch) => {
  try {
    await API.put("chat", "/updateSeen/" + messageId);
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
  }
};

export const handleGetUserChatConfig = (userId) => async (dispatch) => {
  try {
    const config = await API.get("chat", "/getUserConfig/" + userId);
    dispatch(Actions.updateChatUserConfig(config));
    return config;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
  }
};

export const handleUpdateUserChatAvatar =
  (userId, avatar) => async (dispatch) => {
    try {
      const config = await API.put("chat", "/updateAvatar", {
        body: {
          id: userId,
          avatar: avatar,
        },
      });
      dispatch(Actions.updateChatUserConfig(config));
      return config;
    } catch (error) {
      if (error && error.message != "Network Error") {
        Sentry.captureException(error);
      }
    }
  };
export const handleUpdateUserChatHour =
  (userId, chatHour) => async (dispatch) => {
    try {
      const config = await API.put("chat", "/updateChatHour", {
        body: {
          id: userId,
          chatHour: chatHour,
        },
      });
      dispatch(Actions.updateChatUserConfig(config));
      return config;
    } catch (error) {
      if (error && error.message != "Network Error") {
        Sentry.captureException(error);
      }
    }
  };
export const handleUpdateUserChatSeen =
  (userId, messageSeen) => async (dispatch) => {
    try {
      const config = await API.put("chat", "/updateMessageSeenConfig", {
        body: {
          id: userId,
          messageSeen: messageSeen,
        },
      });
      dispatch(Actions.updateChatUserConfig(config));
      return config;
    } catch (error) {
      if (error && error.message != "Network Error") {
        Sentry.captureException(error);
      }
    }
  };
export const handleUpdateUserChatDiable =
  (id, disabled) => async (dispatch) => {
    try {
      const config = await API.put("chat", "/enableDisbaleChat", {
        body: { id, disabled },
      });
      dispatch(Actions.updateChatUserConfig(config));
      return config;
    } catch (error) {
      if (error && error.message != "Network Error") {
        Sentry.captureException(error);
      }
    }
  };
export const handleUpdateUNotificationConfig =
  (userId, notification) => async (dispatch) => {
    try {
      const config = await API.put("chat", "/updateNotificationConfig", {
        body: {
          id: userId,
          notification: notification,
        },
      });
      dispatch(Actions.updateChatUserConfig(config));
      return config;
    } catch (error) {
      if (error && error.message != "Network Error") {
        Sentry.captureException(error);
      }
    }
  };

//scheduled message
export const handleCreateScheduleMessage = (data) => async (dispatch) => {
  try {
    const messageCreated = await API.post("chat", "/createScheduledMessage", {
      body: data,
    });
    await dispatch(Actions.createScheduledMessage(messageCreated));
    return messageCreated;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    console.log(error, "erere");
  }
};
export const handleGetScheduleMessage = (userId) => async (dispatch) => {
  try {
    const scheduledMessages = await API.get(
      "chat",
      `/getScheduledMessage/${userId}`
    );
    await dispatch(Actions.setScheduledMessage(scheduledMessages));
    return scheduledMessages;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    console.log(error, "erere");
  }
};
export const handleRemoveScheduleMessage = (id) => async (dispatch) => {
  try {
    await API.del("chat", `/deleteScheduledMessage/${id}`);
    await dispatch(Actions.removeScheduledMessage(id));
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    console.log(error, "erere");
  }
};
export const handleUpdateScheduleMessage = (data) => async (dispatch) => {
  try {
    const messageCreated = await API.put("chat", "/updateScheduledMessage", {
      body: data,
    });
    await dispatch(Actions.updateScheduledMessage(messageCreated));
    return messageCreated;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    console.log(error, "erere");
  }
};

export const handleListChatInfo = (currentUser) => async (dispatch) => {
  if (
    get(currentUser, "role") == "teacher" ||
    get(currentUser, "role") == "student"
  ) {
    dispatch(handleGetChatUsers(get(currentUser, "id")));
    dispatch(handleGetMessagesChat(get(currentUser, "id")));
    dispatch(handleGetUserChatConfig(get(currentUser, "id")));
    if (get(currentUser, "role") == "teacher") {
      dispatch(handleGetScheduleMessage(get(currentUser, "id")));
    }
  }
};
