//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Checkbox,
    FormControl,
    TextField,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
//  -- Components ---------------------
import React, { useEffect, useState , useRef} from 'react';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { map , filter  } from 'lodash'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultiMenu = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    variant: "menu",
    disablePortal: true,
    getContentAnchorEl: () => null,
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: 120
    },
    formControlStudent: {
        backgroundColor: "#fff"
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));


function MultiSelecter({ options, onSelect, selectedValue ,label  }) {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [filteredOption, setFilteredOption] = useState([])
    const [searchText, setSearchText] = useState('');
    const handleChange = (event) => {
        const value = event.target.value;
        const re = filter(value,k=>k);
        onSelect(re.join(","));
        setSelected(value);
    };
    useEffect(() => {
        if(Array.isArray(selectedValue)){
            setSelected(selectedValue)
        }
      
    }, [selectedValue])
    useEffect(() => {
        if(Array.isArray(options)){
           if(filteredOption.length==0){
            setFilteredOption(options)
           } 
        }
    }, [options])
    const onSearchText = (element) => {
      const value = element.target.value;
      setSearchText(value);
      if (value && value.length > 0) {     
        setFilteredOption(
            filter(options, (option) =>
            option.toLowerCase().includes(value.toLowerCase())
          )
        );
      } else {
        setFilteredOption(options);
      }
    };

      
    return (
      <FormControl variant="outlined" className={classes.formControlStudent}>
        <InputLabel
          id="mutiple-select-label"
          style={{ backgroundColor: "#fff" }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => (
            <div className={classes.chips}>
              {map(selected, (v) => (
                <Chip label={v} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MultiMenu}
        >
          <MenuItem  onKeyDown={(e) => e.stopPropagation()}>
            <TextField
              id="search"
              name="search"
              placeholder="Search"
              InputLabelProps={{ shrink: true }}
              type="text"
              variant="outlined"
              onChange={onSearchText}
              value={searchText}
            />
          </MenuItem>
          {map(filteredOption, (option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected?.includes(option)} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
}
export default MultiSelecter;