import { Button, Modal } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Avatar, Col , Row, Tooltip } from 'antd';
import React, { useEffect, useState, useRef, useMemo } from 'react';

//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { makeStyles } from '@material-ui/core/styles';
import { Close } from "@material-ui/icons";
import Loading from "components/Loading";
import { chunk, get, map , filter } from "lodash";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleGetClass, handleUpdateClass } from "store/classes/thunks";
import { handleCreateOrUpdateClassConfig, handleListUserClasses , handleHideShowClasses } from "store/userClasses/thunks";
import { useCurrentUser } from 'store/users/selectors';
import styled from 'styled-components';
import { handeleCanvasClassUsers , handleListCanvasAssignmentCat } from "store/canvas/thunks"
import { handleGoogleClassRoomStudents } from 'store/googleClassRoom/thunks'
import { handlesynClassUsers  } from 'store/classlink/thunks'
import { handleSyncSchoologyClassesUsers } from "store/schoology/thunks"
import {
    IMG_7574,
    IMG_7575,
    IMG_7576,
    IMG_7577,
    IMG_7595,
    IMG_7596,
    IMG_7600,
    IMG_7605,
    IMG_7611,
    IMG_7614,
    TransparentLogoWhite,
} from 'utils/assets/images';
import COLOR from 'utils/constants/color';
import { DeleteModal } from './Dialog';
const THEME = [
    'linear-gradient(90deg, #5270fc, #5270fc 100%, #ffffff)',
    'linear-gradient(90deg, #fc9e19, #fc9e19 100%, #ffffff)',
    'linear-gradient(90deg, #002d75, #002d75 100%, #ffffff)',
    'linear-gradient(90deg, #00bdab, #00bdab 100%, #ffffff)',
    'linear-gradient(90deg, #7c2ae8, #7c2ae8 100%, #ffffff)',
    'linear-gradient(90deg, #05c1c9, #05c1c9 100%, #ffffff)',
    'linear-gradient(90deg, #ff6347, #ff6347 100%, #ffffff)',
    'linear-gradient(90deg, #3cb371, #3cb371 100%, #ffffff)',
    'linear-gradient(90deg, #8a0309, #8a0309 100%, #ffffff)',
    'linear-gradient(90deg, #ee82ee, #ee82ee 100%, #ffffff)',
    'linear-gradient(90deg, #ff0000, #ff0000 100%, #ffffff)',
    'linear-gradient(90deg, #ffc0cb, #ffc0cb 100%, #ffffff)',
]
const AVATARS = {
    IMG_7614,
    IMG_7611,
    IMG_7605,
    IMG_7600,
    IMG_7574,
    IMG_7575,
    IMG_7576,
    IMG_7577,
    IMG_7595,
    IMG_7596
}
const dummyPicture = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FdummyPicture.png?alt=media&token=1354d5b3-b159-401e-aac8-122e76009c06`

const UserModal = styled.div`
left: 50%;
max-width: 85vw;
position: absolute;
top: 50%;
transform: translate(-50%, -50%);
width: 100%;
@media screen and (max-width: 768px){
    max-width: 95vw;
}
`;
const UserPopUpArea = styled.div`
background-color: rgb(82,84,82);
border-radius: 10px;
padding: 28px;
position:relative;
display: flex; 
flex-direction: column;
@media screen and (max-width: 768px){
    padding: 15px;
}
`;
const CloseBtn = styled.div`
border: none;
cursor: pointer;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 30px;
height: 30px;
border-radius: 50%;
position: absolute;
top: 0px;
right: 0px;
color: white;
z-index: 1;
`;
const UserArea = styled.div`
display:flex;
flex-direction:column;
overflow:auto;
overflow-x:auto;
width: 100%;
display:flex;
flex-wrap: wrap;
flex-direction:row;
align-items: center;
justify-content: center;
`;
const Wrapper = styled.div`
cursor: pointer;
border: ${p => p.active ? ` 2px solid green` : 'none'};
padding: 4px;
flex-direction: row;
padding: 2px;
border-radius: 50%;
margin:${p => p.active ? ` 18px` : '20px'};
justify-content: center;
align-items: center;
display: flex;
flex-direction: row;
position:relative;
@media screen and (max-width: 768px){
    margin:${p => p.active ? ` 4px` : '6px'};
}
`;
const Logo = styled.img`
height: auto;
width: 100px;
`;
const LogoBottomRow = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
width:100%;
`;
const LoaderWrapper = styled.div`
position:absolute;
z-index:100;
`;
const ImageRow = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
`;
const ColorCircle = styled.li`
   background:${p => p.bgColor ? p.bgColor : THEME[0]};
   cursor: pointer;
`;
const CardItem = styled.div`
background:${p => p.bgColor ? p.bgColor : THEME[0]};
`;
const NoteText = styled.span`
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
`;
const CoTeacherText = styled.div`
    font-size: 14px;
    font-weight: 500;
    color:#fff;
    text-transform: capitalize;
`;
const CoteacherTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
`;
const OuterWrapper = styled.div`
   cursor: grabbing;
   background-color: #fff;
   ${'' /* width:400px; */}
  ${'' /* @media (max-width: 400px) {
    padding-bottom:100%;
  } **/}
    // min-width:300px;
    // @media only screen and (max-width: 576px) {
    //     margin: 0px 12px;
    //     width: calc(100% - 24px);
    // }
    // @media only screen and (min-width: 576px) and (max-width: 992px){
    //     margin: 0px 12px;
    //     width: calc(50% - 24px);
    // }
    // @media only screen and (min-width: 992px) and (max-width: 1200px){
    //     margin: 0px 12px;
    //     width: calc(33.33% - 24px);
    // }
    // @media only screen and (min-width: 1200px) {
    //     margin: 0px 12px;
    //     width: calc(25% - 24px);
    // }


`;
const useStyles = makeStyles((theme) => ({
    titleText: {
        fontWeight: 400,
        color: 'rgb(255, 255, 255)',
        fontSize: 20,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 17
    }
}));

export default function ClassCard({ classInfo }) {
    const [visible, setVisible] = React.useState(false);
    const [editTitle, setEditTitle] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayName, setDisplayName] = useState('')
    const [avatarmodal, setAvatarModal] = useState(false)
    const [thememodal, setThemeModal] = useState(false)
    const [avatarSelected, setAvatarSelected] = useState(null)
    const [themeLoader, setThemeLoader] = useState(false)
    const [classLoader, setClassLoader] = useState(false)
    const [classGeadeLoader, setclassGeadeLoader] = useState(false)
    const [displaynameUpdate, setdisplaynameUpdate] = useState(false)
    const [theme, setTheme] = useState('')
    const [notes, setNotes] = useState('')
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    const cssClass = useStyles()
    const dispatch = useDispatch();
    const [archiveToggle,setArchiveToggle] = useState(false)
    const [onArchiveLoading,setOnArchiveloading] = useState(false)
    const currentUser = useCurrentUser()
    const [coteacherModal , setCoteacherModal] = useState(false)
    const classTeachers =  useMemo(()=>filter(get(classInfo,"teachers",[]),t=>t&&t.id!=currentUser.id),[currentUser,classInfo])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    useEffect(() => {
        setDisplayName(get(classInfo, "displayName", ''))
        setNotes(get(classInfo, "config.notes", ''))
        setAvatarSelected(get(classInfo, "config.avatar", ''))
        setTheme(get(classInfo, "config.theme", ''))
    }, [classInfo])

    const updateAvatar = async (avatar) => {
        setLoader(true)
        setAvatarSelected(avatar)
        await dispatch(handleCreateOrUpdateClassConfig({ classId: classInfo.id, avatar: avatar }))
        setLoader(false)
        setAvatarModal(false)
        handleClose()
    }
    const onDisplayNameUpdate = async () => {
        if (get(classInfo, "displayName", '') === displayName) {
            setEditTitle(false)
        } else {
            onUpdateClassName()
        }
        setEditTitle(false)
    }
    const onUpdateClassName = async () => {
        setdisplaynameUpdate(true)
        await dispatch(handleUpdateClass(classInfo.id, { ...classInfo, displayName }))
        await dispatch(handleListUserClasses(currentUser.id))
        setdisplaynameUpdate(false)
    }
    const onThemeUpdate = async (_color) => {
        setTheme(_color)
        setThemeLoader(true)
        try {
            await dispatch(handleCreateOrUpdateClassConfig({ classId: classInfo.id, theme: _color }))
        } catch (error) {
            console.log(error, "error in update theme")
        }
        setThemeModal(false)
        setThemeLoader(false)
        handleClose()
    }
    const onNotesDone = async () => {
        setVisible(false)
        try {
            await dispatch(handleCreateOrUpdateClassConfig({ classId: classInfo.id, notes }))
        } catch (error) {
            console.log(error, "error in update notes")
        }
        setVisible(false)
    }
    const handleStudentSync = async () => {
        try {
            if (currentUser.canvas_id) {
                await handeleCanvasClassUsers(currentUser.canvas_id, currentUser.canvas_auth_id, classInfo.id)
                await dispatch(handleListCanvasAssignmentCat(currentUser.canvas_id,classInfo.canvas_id,currentUser.canvas_auth_id))
            }
            if (currentUser.classlinkid) {
                await handlesynClassUsers(currentUser.classlinkid,currentUser.classlinkTenantId,classInfo.id)
            }
            else if (currentUser.googleId) {
                await handleGoogleClassRoomStudents(currentUser.googleId, classInfo.id)
            } else if (currentUser.schoologyid) {
                await handleSyncSchoologyClassesUsers(currentUser.schoologyid, classInfo.id)
            }
        } catch (error) {
            console.log("student sync issue schoology: " + error)
        }
    }
    const redirectToHome = async () => {
        if (classGeadeLoader || classLoader) return;
        setClassLoader(true)
        await dispatch(handleGetClass(classInfo.id));
        await handleStudentSync()
        setClassLoader(false)
        history.push(`/admin/class?view=home`);
    }
    const redirectToGradeBook = async () => {
        if (classGeadeLoader || classLoader) return;
        setclassGeadeLoader(true)
        await dispatch(handleGetClass(classInfo.id));
        await handleStudentSync()
        setclassGeadeLoader(false)
        history.push(`/admin/class?view=gradebook`);
    }
    const onArchiveClass = () =>{
        setArchiveToggle(true)
    }
    const onArchiveDone = async () =>{
       setOnArchiveloading(true);
       await dispatch(handleHideShowClasses(currentUser.id,classInfo.id,!classInfo.archived))
       setOnArchiveloading(false);
       setArchiveToggle(false);
    }

    const combinedClassCode = `${get(classInfo, "displayName", '')} (${get(classInfo, "code", '')})`;
    const totalNameCode = combinedClassCode.length > 40 ? combinedClassCode.slice(0, 40) + '...' : combinedClassCode;

    const handleInputChange = (e) => {
        const maxLength = 60;
        const value = e.target.value;
        const trimmedValue = value.slice(0, maxLength);
        setDisplayName(trimmedValue);
    }

    return (
        <OuterWrapper className="userSection">
            <CardItem className="userHeader" bgColor={theme}>
                <div className="userHeadEdit"><BorderColorIcon onClick={() => setEditTitle(!editTitle)} /></div>
                {editTitle && 
                <div className="newTitle">
                    <textarea autoFocus value={displayName} onChange={e => {
                        // setDisplayName(e.target.value);
                        handleInputChange(e)
                        }} 
                        onBlur={onDisplayNameUpdate}>
                        Edit Title
                    </textarea>
                </div>}
                {!editTitle && 
                  <label> 
                    {displaynameUpdate && <Loading white size={15} />} 
                    <Tooltip title={combinedClassCode.length > 40 ? combinedClassCode : null}>
                        {/* {get(classInfo, "displayName", '')}  */}
                        {/* ({get(classInfo, "code", '')})  */}
                        {totalNameCode}
                    </Tooltip>
                    {classInfo.archived ? "(Archived)" : ""}
                  </label>}
                <div className="userHeadPic">
                    <img src={AVATARS[avatarSelected] ? AVATARS[avatarSelected] : dummyPicture} />
                </div>
            </CardItem>
            <div className="noteSelf">
                <div className="noteSelfInr">
                    <div className="noteSelfLeft">
                        <Button onClick={() => {
                            setVisible(!visible)
                        }}>Note to self:</Button>
                        {visible ? <textarea autoFocus value={notes} onChange={e => setNotes(e.target.value)} onBlur={onNotesDone}>Words..</textarea> : <NoteText className='noteTxtAply'>{notes}</NoteText>}
                    </div>
                    <div className="changeOption noteSelfRght"><MoreVertIcon aria-describedby={id} variant="contained" onClick={handleClick} />
                        <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                            <Typography sx={{ p: 2 }} className="chngOptList">
                                <ul>
                                    <li><a onClick={() => setAvatarModal(true)}>Change Avatar</a></li>
                                    <li><a onClick={() => setThemeModal(true)}>Change Theme</a></li>
                                </ul>
                            </Typography>
                        </Popover>
                    </div>

                </div>

                {/* Start of Change Avater Modal */}
                <Modal
                    open={avatarmodal}
                    onClose={() => setAvatarModal(false)}
                    style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
                    className="avtarModl">
                    <UserModal className="avtarUsrModl">
                        <UserPopUpArea className="avtarUsrArea">
                            <Typography variant="h4" className={cssClass.titleText}>Please select your avatar:</Typography>
                            <CloseBtn onClick={() => setAvatarModal(false)}>
                                <Close style={{ color: COLOR.white, fontWeight: "bolder", fontSize: 25 }} />
                            </CloseBtn>
                            <UserArea className="avtarUsrImg">
                                {chunk(Object.keys(AVATARS)).map((item, i) => (<ImageRow className="avtarUsrRw" key={`avtar-${i}`}>
                                    {map(item, key => (<Wrapper className="avtarUsrWrap" active={key == avatarSelected}
                                        key={key}
                                        onClick={() => {
                                            if (!loader) {
                                                updateAvatar(key)
                                            }
                                        }}>
                                        {loader && key == avatarSelected ? <LoaderWrapper> <Loading size={25} /> </LoaderWrapper> : null}
                                        <Avatar src={AVATARS[key]} size={{ xs: 84, sm: 90, xl: 150, lg: 100, xxl: 160, md: 120 }} />
                                    </Wrapper>))}
                                </ImageRow>)
                                )}
                            </UserArea>
                            <LogoBottomRow>
                                <Logo src={TransparentLogoWhite} />
                            </LogoBottomRow>
                        </UserPopUpArea>
                    </UserModal>
                </Modal>
                {/* co teacher modal  */}
                <Modal
                    open={coteacherModal}
                    onClose={() => setCoteacherModal(false)}
                    style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
                    className="avtarModl">
                    <UserModal className="avtarUsrModl" style={{ minWidth: "30vw", width: "auto" }} >
                        <UserPopUpArea className="avtarUsrArea" >
                            <Typography variant="h4" className={cssClass.titleText}>Co-teachers:</Typography>
                            <CloseBtn onClick={() => setCoteacherModal(false)}>
                                <Close style={{ color: COLOR.white, fontWeight: "bolder", fontSize: 25 }} />
                            </CloseBtn>
                            <UserArea className="avtarUsrImg" style={{ justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column" }}>
                                {map(classTeachers, (cls, i) => (<CoTeacherText key={cls.id}>({i + 1}) {cls.firstName || ""} {cls.lastName || ""}</CoTeacherText>))}
                            </UserArea>
                            <LogoBottomRow>
                                <Logo src={TransparentLogoWhite} />
                            </LogoBottomRow>
                        </UserPopUpArea>
                    </UserModal>
                </Modal>
                {/* End of Change Avater Modal */}

                {/* Start of Change Theme Modal */}
                <Modal
                    open={thememodal}
                    onClose={() => setThemeModal(false)}
                    style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
                    className="avtarModl">
                    <UserModal className="avtarUsrModl">
                        <UserPopUpArea className="avtarUsrArea">
                            <Typography variant="h4" className={cssClass.titleText}>Please select your Theme:</Typography>
                            <CloseBtn onClick={() => setThemeModal(false)}>
                                <Close style={{ color: COLOR.white, fontWeight: "bolder", fontSize: 25 }} />
                            </CloseBtn>
                            <UserArea className="avtarUsrImg">
                                <div>
                                    <ul className="assignColor">
                                        {map(THEME, _color => (<ColorCircle key={_color} bgColor={_color} active={_color === theme} onClick={() => { onThemeUpdate(_color) }}>
                                            {themeLoader && _color == theme ? <LoaderWrapper> <Loading size={25} /> </LoaderWrapper> : null}
                                        </ColorCircle>))}
                                    </ul>
                                </div>
                            </UserArea>
                            <LogoBottomRow>
                                <Logo src={TransparentLogoWhite} />
                            </LogoBottomRow>
                        </UserPopUpArea>
                    </UserModal>
                </Modal>
                {/* End of Change Theme Modal */}
                <DeleteModal
                    deleteToggle={archiveToggle}
                    setDeleteToggle={setArchiveToggle}
                    loading={onArchiveLoading}
                    message={`Are you sure you want to ${classInfo.archived ? "Show" : "Hide"} this class?`}
                    buttonTitle={classInfo.archived ? "Show" : "Hide"}
                    onDelete={onArchiveDone}
                    colorSchema={classInfo.archived ? "primary" : "secondary"}
                />
                <div className="noteLinks">
                    <Row>
                        <Col span={6} style={{ marginBottom: 0 }}>
                            {classTeachers.length > 0 ? <>
                                <CoteacherTitle onClick={() => setCoteacherModal(true)}>Co-teacher</CoteacherTitle>
                            </> : null}
                        </Col>
                        <Col span={18} style={{ marginBottom: 0 }}>
                            <ul>
                                {!classInfo.archived && <li onClick={redirectToHome}><a> {classLoader ? (<LoaderWrapper> <Loading size={25} /> </LoaderWrapper>) : null} Home</a></li>}
                                {!classInfo.archived && <li onClick={redirectToGradeBook}><a>{classGeadeLoader ? (<LoaderWrapper> <Loading size={25} /> </LoaderWrapper>) : null} Gradebook</a></li>}
                                <li onClick={onArchiveClass}><a>{!classInfo.archived ? "Hide" : "Show"}</a></li>
                            </ul>

                        </Col>
                    </Row>
                </div>
            </div>
        </OuterWrapper>
    )
}
