//  ----------------------------------------------------------------------------
//  Store config
//  ----------------------------------------------------------------------------
//
//  Store constant directory
//
//  -- Description
//  Source of truth for store config values
//

//  -- Action types -------------------
export const ActionTypes = {
  base: {
    example: "BASE_EXAMPLE",
    exampleFail: "BASE_EXAMPLE_FAIL",
    exampleSuccess: "BASE_EXAMPLE_SUCCESS",
  },

  activities: {
    createActivity: "CREATE_ACTIVITY",
    createActivityFail: "CREATE_ACTIVITY_FAIL",
    createActivitySuccess: "CREATE_ACTIVITY_SUCCESS",

    updateActivites: "updateActivites",
    updateActivitesSuccess: "updateActivitesSuccess",
    updateActivitesFail: "updateActivitesFail",

    deleteActivites: "updateActivites",
    deleteActivitesSuccess: "deleteActivitesSuccess",
    deleteActivitesFail: "deleteActivitesFail",

    getActivity: "GET_ACTIVITY",
    getActivityFail: "GET_ACTIVITY_FAIL",
    getActivitySuccess: "GET_ACTIVITY_SUCCESS",

    listActivities: "LIST_ACTIVITIES",
    listActivitiesFail: "LIST_ACTIVITIES_FAIL",
    listActivitiesSuccess: "LIST_ACTIVITIES_SUCCESS",

    listChapterActivities: "LIST_CHAPTER_ACTIVITIES",
    listChapterActivitiesFail: "LIST_CHAPTER_ACTIVITIES_FAIL",
    listChapterActivitiesSuccess: "LIST_CHAPTER_ACTIVITIES_SUCCESS",
  },
  assignmenent: {
    listAssignmentByUser: "LIST_ASSIGNMENT_BY_USER",
    listAssignmentByUserSuccess: "LIST_ASSIGNMENT_BY_USER_SUCCESS",
    listAssignmentByUserFail: "LIST_ASSIGNMENT_BY_USER_FAIL",

    createCustomAssignment: "CREATE_CUSTOM_ASSIGNMENT",
    createCustomAssignmentFail: "CREATE_CUSTOM_ASSIGNMENT_FAIL",
    createCustomAssignmentSuccess: "CREATE_CUSTOM_ASSIGNMENT_SUCCESS",

    updateCustomAssignment: "UPDATE_CUSTOM_ASSIGNMENT",
    updateCustomAssignmentFail: "UPDATE_CUSTOM_ASSIGNMENT_FAIL",
    updateCustomAssignmentSuccess: "UPDATE_CUSTOM_ASSIGNMENT_SUCCESS",

    deleteCustomAssignment: "DELETE_CUSTOM_ASSIGNMENT",
    deleteCustomAssignmentFail: "DELETE_CUSTOM_ASSIGNMENT_FAIL",
    deleteCustomAssignmentSuccess: "DELETE_CUSTOM_ASSIGNMENT_SUCCESS",
  },

  userBooks: {
    listUserBooks: "LIST_USERBOOKS",
    listUserBooksFail: "LIST_USERBOOKS_FAIL",
    listUserBooksSuccess: "LIST_USERBOOKS_SUCCESS",
  },

  books: {
    listBooks: "LIST_BOOKS",
    listBooksFail: "LIST_BOOKS_FAIL",
    listBooksSuccess: "LIST_BOOKS_SUCCESS",

    listAllBooks: "LIST_ALL_BOOKS",
    listAllBooksFail: "LIST_ALL_BOOKS_FAIL",
    listAllBooksSuccess: "LIST_ALL_BOOKS_SUCCESS",

    createBook: "createBook",
    createBookSuccess: "createBookSuccess",
    createBookFail: "createBookFail",

    updateBook: "updateBook",
    updateBookSuccess: "updateBookSuccess",
    updateBookFail: "updateBookFail",

    setBookOrder: "setBookOrder",
    setBookOrderSuccess: "setBookOrderSuccess",
    setBookOrderFail: "setBookOrderFail",
  },
  bookmarks: {
    liastClassBookmarksSuccess: "liastClassBookmarksSuccess",
    liastClassBookmarksFail: "liastClassBookmarksFail",
    updateBookmarks: "updateBookmarks",
    createClassBookmarksSuccess: "createClassBookmarksSuccess",
    deleteBookmark: "deletebookmark",
  },
  bookUnits: {
    listUnits: "LIST_UNITS",
    listUnitsSuccess: "LIST_UNITS_SUCCESS",
    listUnitsFail: "LIST_UNITS_FAIL",
    updateUnits: "update_UNITS",
    updateUnitsSuccess: "update_UNITS_SUCCESS",
    updateUnitsFail: "update_UNITS_FAIL",
  },
  tips: {
    listTipsBook: "LIST_TIPSBOOK",
    listTipsBookFail: "LIST_TIPSBOOK_FAIL",
    listTipsBookSuccess: "LIST_TIPSBOOK_SUCCESS",

    createTipsBook: "CREATE_TIPSBOOK",
    createTipsBookFail: "CREATE_TIPSBOOK_FAIL",
    createTipsBookSuccess: "CREATE_TIPSBOOK_SUCCESS",

    updateTipsBook: "UPDATE_TIPSBOOK",
    updateTipsBookFail: "UPDATE_TIPSBOOK_FAIL",
    updateTipsBookSuccess: "UPDATE_TIPSBOOK_SUCCESS",

    deleteTipsBook: "DELETE_TIPSBOOK",
    deleteTipsBookFail: "DELETE_TIPSBOOK_FAIL",
    deleteTipsBookSuccess: "DELETE_TIPSBOOK_SUCCESS",
  },

  auth: {
    userLogin: "USER_LOGIN",
    userLoginFail: "USER_LOGIN_FAIL",
    userLoginSuccess: "USER_LOGIN_SUCCESS",

    userLogout: "USER_LOGOUT",
    userLogoutFail: "USER_LOGOUT_FAIL",
    userLogoutSuccess: "USER_LOGOUT_SUCCESS",
    USER_LOGOUT_CLEAN_STORAGE: "USER_LOGOUT_CLEAN_STORAGE",
  },

  classes: {
    createClass: "CREATE_CLASS",
    createClassFail: "CREATE_CLASS_FAIL",
    createClassSuccess: "CREATE_CLASS_SUCCESS",

    getClass: "GET_CLASS",
    getClassFail: "GET_CLASS_FAIL",
    getClassSuccess: "GET_CLASS_SUCCESS",

    getClassByCode: "GET_CLASS_BY_CODE",
    getClassByCodeFail: "GET_CLASS_BY_CODE_FAIL",
    getClassByCodeSuccess: "GET_CLASS_BY_CODE_SUCCESS",

    listClasses: "LIST_CLASSES",
    listClassesFail: "LIST_CLASSES_FAIL",
    listClassesSuccess: "LIST_CLASSES_SUCCESS",

    listAllClasses: "LIST_ALL_CLASSES",
    listAllClassesFail: "LIST_ALL_CLASSES_FAIL",
    listAllClassesSuccess: "LIST_ALL_CLASSES_SUCCESS",

    listOrgClasses: "LIST_ORGANIZATION_CLASSES",
    listOrgClassesFail: "LIST_ORGANIZATION_CLASSES_FAIL",
    listOrgClassesSuccess: "LIST_ORGANIZATION_CLASSES_SUCCESS",

    listTeacherClasses: "LIST_TEACHER_CLASSES",
    listTeacherClassesFail: "LIST_TEACHER_CLASSES_FAIL",
    listTeacherClassesSuccess: "LIST_TEACHER_CLASSES_SUCCESS",

    updateClass: "UPDATE_CLASS",
    updateClassFail: "UPDATE_CLASS_FAIL",
    updateClassSuccess: "UPDATE_CLASS_SUCCESS",
  },

  classActivities: {
    createClassActivity: "CREATE_CLASS_ACTIVITY",
    createClassActivityFail: "CREATE_CLASS_ACTIVITY_FAIL",
    createClassActivitySuccess: "CREATE_CLASS_ACTIVITY_SUCCESS",
    createDeactivateByStudent: "CREATE_DEACTIVATE_BY_STUDENT",

    deleteClassActivity: "DELETE_CLASS_ACTIVITY",
    deleteClassActivityFail: "DELETE_CLASS_ACTIVITY_FAIL",
    deleteClassActivitySuccess: "DELETE_CLASS_ACTIVITY_SUCCESS",

    listClassActivities: "LIST_CLASS_ACTIVITIES",
    listClassActivitiesFail: "LIST_CLASS_ACTIVITIES_FAIL",
    listClassActivitiesSuccess: "LIST_CLASS_ACTIVITIES_SUCCESS",

    updateClassActivity: "UPDATE_CLASS_ACTIVITY",
    updateClassActivityFail: "UPDATE_CLASS_ACTIVITY_FAIL",
    updateClassActivitySuccess: "UPDATE_CLASS_ACTIVITY_SUCCESS",
  },

  classQuizzes: {
    createClassQuiz: "CREATE_CLASS_QUIZ",
    createClassQuizFail: "CREATE_CLASS_QUIZ_FAIL",
    createClassQuizSuccess: "CREATE_CLASS_QUIZ_SUCCESS",

    deleteClassQuiz: "DELETE_CLASS_QUIZ",
    deleteClassQuizFail: "DELETE_CLASS_QUIZ_FAIL",
    deleteClassQuizSuccess: "DELETE_CLASS_QUIZ_SUCCESS",

    listClassQuizzes: "LIST_CLASS_QUIZZES",
    listClassQuizzesFail: "LIST_CLASS_QUIZZES_FAIL",
    listClassQuizzesSuccess: "LIST_CLASS_QUIZZES_SUCCESS",

    updateClassQuiz: "UPDATE_CLASS_QUIZ",
    updateClassQuizFail: "UPDATE_CLASS_QUIZ_FAIL",
    updateClassQuizSuccess: "UPDATE_CLASS_QUIZ_SUCCESS",
  },

  differentiation: {
    createDifferentiation: "CREATE_DIFFERENTIATION",
    createDifferentiationFail: "CREATE_DIFFERENTIATION_FAIL",
    createDifferentiationSuccess: "CREATE_DIFFERENTIATION_SUCCESS",
  },

  interactions: {
    createInteraction: "CREATE_INTERACTION",
    createInteractionFail: "CREATE_INTERACTION_FAIL",
    createInteractionSuccess: "CREATE_INTERACTION_SUCCESS",

    updateInteraction: "UPDATE_INTERACTION",
    updateInteractionFail: "UPDATE_INTERACTION_FAIL",
    updateInteractionSuccess: "UPDATE_INTERACTION_SUCCESS",

    deleteInteraction: "DELETE_INTERACTION",
    deleteInteractionFail: "DELETE_INTERACTION_FAIL",
    deleteInteractionSuccess: "DELETE_INTERACTION_SUCCESS",

    getInteraction: "GET_INTERACTION",
    getInteractionFail: "GET_INTERACTION_FAIL",
    getInteractionSuccess: "GET_INTERACTION_SUCCESS",

    listInteractions: "LIST_INTERACTIONS",
    listInteractionsFail: "LIST_INTERACTIONS_FAIL",
    listInteractionsSuccess: "LIST_INTERACTIONS_SUCCESS",

    listChapterInteractions: "LIST_CHAPTER_INTERACTIONS",
    listChapterInteractionsFail: "LIST_CHAPTER_INTERACTIONS_FAIL",
    listChapterInteractionsSuccess: "LIST_CHAPTER_INTERACTIONS_SUCCESS",

    listInteractionsByType: "LIST_INTERACTIONS_BY_TYPE",
    listInteractionsByTypeFail: "LIST_INTERACTIONS_BY_TYPE_FAIL",
    listInteractionsByTypeSuccess: "LIST_INTERACTIONS_BY_TYPE_SUCCESS",
  },
  instructions: {
    createInstructions: "CREATE_INSTRUCTIONS",
    createInstructionsFail: "CREATE_INSTRUCTIONS_FAIL",
    createInstructionsSuccess: "CREATE_INSTRUCTIONS_SUCCESS",

    updateInstructions: "UPDATE_INSTRUCTIONS",
    updateInstructionsFail: "UPDATE_INSTRUCTIONS_FAIL",
    updateInstructionsSuccess: "UPDATE_INSTRUCTIONS_SUCCESS",

    listInstructions: "LIST_INSTRUCTIONS",
    listInstructionsFail: "LIST_INSTRUCTIONS_FAIL",
    listInstructionsSuccess: "LIST_INSTRUCTIONS_SUCCESS",

    deleteInstructions: "DELETE_INSTRUCTIONS",
    deleteInstructionsFail: "DELETE_INSTRUCTIONS_FAIL",
    deleteInstructionsSuccess: "DELETE_INSTRUCTIONS_SUCCESS",
  },
  likes: {
    createLike: "CREATE_LIKE",
    createLikeFail: "CREATE_LIKE_FAIL",
    createLikeSuccess: "CREATE_LIKE_SUCCESS",

    deleteLike: "DELETE_LIKE",
    deleteLikeFail: "DELETE_LIKE_FAIL",
    deleteLikeSuccess: "DELETE_LIKE_SUCCESS",

    listLikes: "LIST_LIKES",
    listLikesFail: "LIST_LIKES_FAIL",
    listLikesSuccess: "LIST_LIKES_SUCCESS",

    listChapterLikes: "LIST_CHAPTER_LIKES",
    listChapterLikesFail: "LIST_CHAPTER_LIKES_FAIL",
    listChapterLikesSuccess: "LIST_CHAPTER_LIKES_SUCCESS",

    listClassLikes: "LIST_CLASS_LIKES",
    listClassLikesFail: "LIST_CLASS_LIKES_FAIL",
    listClassLikesSuccess: "LIST_CLASS_LIKES_SUCCESS",

    listOrgLikes: "LIST_ORGANIZATION_LIKES",
    listOrgLikesFail: "LIST_ORGANIZATION_LIKES_FAIL",
    listOrgLikesSuccess: "LIST_ORGANIZATION_LIKES_SUCCESS",

    listUserLikes: "LIST_USER_LIKES",
    listUserLikesFail: "LIST_USER_LIKES_FAIL",
    listUserLikesSuccess: "LIST_USER_LIKES_SUCCESS",
  },

  notes: {
    createNote: "CREATE_NOTE",
    createNoteFail: "CREATE_NOTE_FAIL",
    createNoteSuccess: "CREATE_NOTE_SUCCESS",

    deleteNote: "DELETE_NOTE",
    deleteNoteFail: "DELETE_NOTE_FAIL",
    deleteNoteSuccess: "DELETE_NOTE_SUCCESS",

    listNotes: "LIST_NOTES",
    listNotesFail: "LIST_NOTES_FAIL",
    listNotesSuccess: "LIST_NOTES_SUCCESS",

    listChapterNotes: "LIST_CHAPTER_NOTES",
    listChapterNotesFail: "LIST_CHAPTER_NOTES_FAIL",
    listChapterNotesSuccess: "LIST_CHAPTER_NOTES_SUCCESS",

    listClassNotes: "LIST_CLASS_NOTES",
    listClassNotesFail: "LIST_CLASS_NOTES_FAIL",
    listClassNotesSuccess: "LIST_CLASS_NOTES_SUCCESS",

    listNoteByBookFail: "LIST_BOOK_NOTES_FAIL",
    listNoteByBookSuccess: "LIST_BOOK_NOTES_SUCCESS",

    listOrgNotes: "LIST_ORGANIZATION_NOTES",
    listOrgNotesFail: "LIST_ORGANIZATION_NOTES_FAIL",
    listOrgNotesSuccess: "LIST_ORGANIZATION_NOTES_SUCCESS",

    listUserNotes: "LIST_USER_NOTES",
    listUserNotesFail: "LIST_USER_NOTES_FAIL",
    listUserNotesSuccess: "LIST_USER_NOTES_SUCCESS",

    updateNote: "UPDATE_NOTE",
    updateNoteFail: "UPDATE_NOTE_FAIL",
    updateNoteSuccess: "UPDATE_NOTE_SUCCESS",
  },

  organizations: {
    createOrganization: "CREATE_ORGANIZATION",
    createOrganizationFail: "CREATE_ORGANIZATION_FAIL",
    createOrganizationSuccess: "CREATE_ORGANIZATION_SUCCESS",

    getOrganization: "GET_ORGANIZATION",
    getOrganizationFail: "GET_ORGANIZATION_FAIL",
    getOrganizationSuccess: "GET_ORGANIZATION_SUCCESS",

    listDistricts: "LIST_DISTRICTS",
    listDistrictsFail: "LIST_DISTRICTS_FAIL",
    listDistrictsSuccess: "LIST_DISTRICTS_SUCCESS",

    listAllSchools: "LIST_ALL_SCHOOLS",
    listAllSchoolsFail: "LIST_ALL_SCHOOLS_FAIL",
    listAllSchoolsSuccess: "LIST_ALL_SCHOOLS_SUCCESS",

    listOrgsByParent: "LIST_ORGS_BY_PARENT",
    listOrgsByParentFail: "LIST_ORGS_BY_PARENT_FAIL",
    listOrgsByParentSuccess: "LIST_ORGS_BY_PARENT_SUCCESS",

    listOrganizations: "LIST_ORGANIZATIONS",
    listOrganizationsFail: "LIST_ORGANIZATIONS_FAIL",
    listOrganizationsSuccess: "LIST_ORGANIZATIONS_SUCCESS",

    listSchools: "LIST_SCHOOLS",
    listSchoolsFail: "LIST_SCHOOLS_FAIL",
    listSchoolsSuccess: "LIST_SCHOOLS_SUCCESS",

    updateOrganization: "UPDATE_ORGANIZATION",
    updateOrganizationFail: "UPDATE_ORGANIZATION_FAIL",
    updateOrganizationSuccess: "UPDATE_ORGANIZATION_SUCCESS",
  },
  orgTeacher: {
    listOrgTeacherData: "listOrgTeacherData",
    listOrgTeacherDataSuccess: "listOrgTeacherDataSuccess",
    listOrgTeacherDataFail: "listOrgTeacherDataFail",
  },
  distDashboardInfo: {
    setDistDashboardInfo: "setDistDashboardInfo",
    updateDistDashboardInfo: "updateDistDashboardInfo",
    clearDistDashboardInfo: "clearDistDashboardInfo",

    setExamsGraphData: "setExamsGraphData",
    updateExamsGraphData: "updateExamsGraphData",
    clearExamsGraphData: "clearExamsGraphData",


    setBookUsesGraphData: "setBookUsesGraphData",
    updateBookUsesGraphData: "updateBookUsesGraphData",
    clearBookUsesGraphData: "clearBookUsesGraphData",

    setContentExamGraphData : "setContentExamGraphData",
    updateContentExamGraphData : "updateContentExamGraphData",
    clearContentExamGraphData : "clearContentExamGraphData",
    
  },

  questions: {
    createQuestion: "CREATE_QUESTION",
    createQuestionFail: "CREATE_QUESTION_FAIL",
    createQuestionSuccess: "CREATE_QUESTION_SUCCESS",

    deleteQuestion: "DELETE_QUESTION",
    deleteQuestionFail: "DELETE_QUESTION_FAIL",
    deleteQuestionSuccess: "DELETE_QUESTION_SUCCESS",

    listQuestions: "LIST_QUESTIONS",
    listQuestionsFail: "LIST_QUESTIONS_FAIL",
    listQuestionsSuccess: "LIST_QUESTIONS_SUCCESS",

    listChapterQuestions: "LIST_CHAPTER_QUESTIONS",
    listChapterQuestionsFail: "LIST_CHAPTER_QUESTIONS_FAIL",
    listChapterQuestionsSuccess: "LIST_CHAPTER_QUESTIONS_SUCCESS",

    listClassQuestions: "LIST_CLASS_QUESTIONS",
    listClassQuestionsFail: "LIST_CLASS_QUESTIONS_FAIL",
    listClassQuestionsSuccess: "LIST_CLASS_QUESTIONS_SUCCESS",

    listOrgQuestions: "LIST_ORGANIZATION_QUESTIONS",
    listOrgQuestionsFail: "LIST_ORGANIZATION_QUESTIONS_FAIL",
    listOrgQuestionsSuccess: "LIST_ORGANIZATION_QUESTIONS_SUCCESS",

    listUserQuestions: "LIST_USER_QUESTIONS",
    listUserQuestionsFail: "LIST_USER_QUESTIONS_FAIL",
    listUserQuestionsSuccess: "LIST_USER_QUESTIONS_SUCCESS",

    updateQuestion: "UPDATE_QUESTION",
    updateQuestionFail: "UPDATE_QUESTION_FAIL",
    updateQuestionSuccess: "UPDATE_QUESTION_SUCCESS",
  },

  posts: {
    createPost: "CREATE_POST",
    createPostFail: "CREATE_POST_FAIL",
    createPostSuccess: "CREATE_POST_SUCCESS",

    deletePost: "DELETE_POST",
    deletePostFail: "DELETE_POST_FAIL",
    deletePostSuccess: "DELETE_POST_SUCCESS",

    listPosts: "LIST_POSTS",
    listPostsFail: "LIST_POSTS_FAIL",
    listPostsSuccess: "LIST_POSTS_SUCCESS",

    listClassPosts: "LIST_CLASS_POSTS",
    listClassPostsFail: "LIST_CLASS_POSTS_FAIL",
    listClassPostsSuccess: "LIST_CLASS_POSTS_SUCCESS",

    listOrgPosts: "LIST_ORGANIZATION_POSTS",
    listOrgPostsFail: "LIST_ORGANIZATION_POSTS_FAIL",
    listOrgPostsSuccess: "LIST_ORGANIZATION_POSTS_SUCCESS",
  },

  quizzes: {
    createQuiz: "CREATE_QUIZ",
    createQuizFail: "CREATE_QUIZ_FAIL",
    createQuizSuccess: "CREATE_QUIZ_SUCCESS",

    updateQuiz: "updateQuiz",
    updateQuizSuccess: "updateQuizSuccess",
    updateQuizFail: "updateQuizFail",

    deleteQuiz: "deleteQuiz",
    deleteQuizSuccess: "deleteQuizSuccess",
    deleteQuizFail: "deleteQuizFail",

    getQuiz: "GET_QUIZ",
    getQuizFail: "GET_QUIZ_FAIL",
    getQuizSuccess: "GET_QUIZ_SUCCESS",

    listQuizzes: "LIST_QUIZZES",
    listQuizzesFail: "LIST_QUIZZES_FAIL",
    listQuizzesSuccess: "LIST_QUIZZES_SUCCESS",

    listChapterQuizzes: "LIST_CHAPTER_QUIZZES",
    listChapterQuizzesFail: "LIST_CHAPTER_QUIZZES_FAIL",
    listChapterQuizzesSuccess: "LIST_CHAPTER_QUIZZES_SUCCESS",
  },

  submissions: {
    createSubmission: "CREATE_SUBMISSION",
    createSubmissionFail: "CREATE_SUBMISSION_FAIL",
    createSubmissionSuccess: "CREATE_SUBMISSION_SUCCESS",

    deleteUserSubmissions: "DELETE_USER_SUBMISSIONS",
    deleteUserSubmissionsFail: "DELETE_USER_SUBMISSIONS_FAIL",
    deleteUserSubmissionsSuccess: "DELETE_USER_SUBMISSIONS_SUCCESS",

    deleteUserSubmissionsWithAssignment:
      "DELETE_USER_SUBMISSIONS_WITH_ASSIGNMENT",
    deleteUserSubmissionsWithAssignmentFail:
      "DELETE_USER_SUBMISSIONS_WITH_ASSIGNMENT_FAIL",
    deleteUserSubmissionsWithAssignmentSuccess:
      "DELETE_USER_SUBMISSIONS_WITH_ASSIGNMENT_SUCCESS",

    getSubmission: "GET_SUBMISSION",
    getSubmissionFail: "GET_SUBMISSION_FAIL",
    getSubmissionSuccess: "GET_SUBMISSION_SUCCESS",

    listAssignmentSubmissions: "LIST_ASSIGNMENT_SUBMISSIONS",
    listAssignmentSubmissionsFail: "LIST_ASSIGNMENT_SUBMISSIONS_FAIL",
    listAssignmentSubmissionsSuccess: "LIST_ASSIGNMENT_SUBMISSIONS_SUCCESS",

    listClassSubmissions: "LIST_CLASS_SUBMISSIONS",
    listClassSubmissionsFail: "LIST_CLASS_SUBMISSIONS_FAIL",
    listClassSubmissionsSuccess: "LIST_CLASS_SUBMISSIONS_SUCCESS",

    listOrgSubmissions: "LIST_ORG_SUBMISSIONS",
    listOrgSubmissionsFail: "LIST_ORG_SUBMISSIONS_FAIL",
    listOrgSubmissionsSuccess: "LIST_ORG_SUBMISSIONS_SUCCESS",

    listUserSubmissions: "LIST_USER_SUBMISSIONS",
    listUserSubmissionsFail: "LIST_USER_SUBMISSIONS_FAIL",
    listUserSubmissionsSuccess: "LIST_USER_SUBMISSIONS_SUCCESS",

    updateSubmission: "UPDATE_SUBMISSION",
    updateSubmissionFail: "UPDATE_SUBMISSION_FAIL",
    updateSubmissionSuccess: "UPDATE_SUBMISSION_SUCCESS",
    updatePastSubmissions: "UPDATE_PAST_SUBMISSIONS",
  },
  pastSubmissions: {
    createPastSub: "create_past_submissions",
    createPastSubFail: "create_past_submissions_fail",
    listPastSub: "list_past_submissions",
  },

  userClasses: {
    createUserClass: "CREATE_USER_CLASS",
    createUserClassFail: "CREATE_USER_CLASS_FAIL",
    createUserClassSuccess: "CREATE_USER_CLASS_SUCCESS",

    deleteUserClass: "DELETE_USER_CLASS",
    deleteUserClassFail: "DELETE_USER_CLASS_FAIL",
    deleteUserClassSuccess: "DELETE_USER_CLASS_SUCCESS",

    listClassUsers: "LIST_CLASS_USERS",
    listClassUsersFail: "LIST_CLASS_USERS_FAIL",
    listClassUsersSuccess: "LIST_CLASS_USERS_SUCCESS",
    updateUserClassesOrder: "UPDATE_USER_CLASS_ORDER",

    listUserClasses: "LIST_USER_CLASSES",
    listUserClassesFail: "LIST_USER_CLASSES_FAIL",
    listUserClassesSuccess: "LIST_USER_CLASSES_SUCCESS",
    updateClassConfig: "update_class_config",

    setUsersByClass: "setUsersByClass",
    updateUsersByClass: "updateUsersByClass",
    clearUsersByClass: "clearUsersByClass",
  },

  users: {
    changeUserPassword: "CHANGE_USER_PASSWORD",
    changeUserPasswordSuccess: "CHANGE_USER_PASSWORD_SUCCESS",
    changeUserPasswordFail: "CHANGE_USER_PASSWORD_FAIL",

    createUser: "CREATE_USER",
    createUserFail: "CREATE_USER_FAIL",
    createUserSuccess: "CREATE_USER_SUCCESS",

    deleteUser: "DELETE_USER",
    deleteUserFail: "DELETE_USER_FAIL",
    deleteUserSuccess: "DELETE_USER_SUCCESS",

    getUser: "GET_USER",
    getUserFail: "GET_USER_FAIL",
    getUserSuccess: "GET_USER_SUCCESS",

    listUsers: "LIST_USERS",
    listUsersFail: "LIST_USERS_FAIL",
    listUsersSuccess: "LIST_USERS_SUCCESS",

    listAllUsers: "LIST_ALL_USERS",
    listAllUsersFail: "LIST_ALL_USERS_FAIL",
    listAllUsersSuccess: "LIST_ALL_USERS_SUCCESS",

    listAllStudentUsers: "LIST_ALL_STUDENT_USERS",
    listAllStudentUsersFail: "LIST_ALL_STUDENT_USERS_FAIL",
    listAllStudentUsersSuccess: "LIST_ALL_STUDENT_USERS_SUCCESS",

    listAllTeacherUsers: "LIST_ALL_TEACHER_USERS",
    listAllTeacherUsersFail: "LIST_ALL_TEACHER_USERS_FAIL",
    listAllTeacherUsersSuccess: "LIST_ALL_TEACHER_USERS_SUCCESS",

    // student constants
    listAllStudentsUsers: "LIST_ALL_STUDENTS_USERS",
    listAllStudentsUsersFail: "LIST_ALL_STUDENTS_USERS_FAIL",
    listAllStudentsUsersSuccess: "LIST_ALL_STUDENTS_USERS_SUCCESS",

    listOrgUsers: "LIST_ORGANIZATION_USERS",
    listOrgUsersFail: "LIST_ORGANIZATION_USERS_FAIL",
    listOrgUsersSuccess: "LIST_ORGANIZATION_USERS_SUCCESS",

    registerStudent: "REGISTER_STUDENT",
    registerStudentFail: "REGISTER_STUDENT_FAIL",
    registerStudentSuccess: "REGISTER_STUDENT_SUCCESS",

    updateUser: "UPDATE_USER",
    updateUserFail: "UPDATE_USER_FAIL",
    updateUserSuccess: "UPDATE_USER_SUCCESS",

    updateNewUser: "UPDATE_NEW_USER",
    updateNewUserFail: "UPDATE_NEW_USER_FAIL",
    updateNewUserSuccess: "UPDATE_NEW_USER_SUCCESS",

    userEnable: "USER_ENABLE",
    userEnableFail: "USER_ENABLE_FAIL",
    userEnableSuccess: "USER_ENABLE_SUCCESS",


    set_profile_config: "SET_PROFILE_CONFIG",
    update_profile_config: "UPDATE_PROFILE_CONFIG",
    clear_profile_config: "CLEAR_PROFILE_CONFIG",

    show_profile_pic_viewer: "SHOW_PROFILE_PHOTO_VIEWER",
    close_profile_pic_viewer: "CLOSE_PROFILE_PHOTO_VIEWER",

    list_all_district_teacher: "LIST_ALL_DISTRICT_TEACHER"
  },
  user_preference: {
    createUserPrefrence: "createUserPrefrence",
    createUserPreferenceFail: "createUserPreferenceFail",
    createUserPreferencSuccess: "createUserPreferencSuccess",
    getUserPreference: "getUserPreference",
    getUserPreferencFail: "getUserPreferencFail",
    getUserPreferencSuccess: "getUserPreferencSuccess",
    updateUserPreference: "updateUserPreference",
    updateUserPreferencFail: "updateUserPreferencFail",
    updateUserPreferencSuccess: "updateUserPreferencSuccess",
  },
  selectedUser: {
    setSelectedUser: "setSelectedUser",
    setSelectedUserFail: "setSelectedUserFail",
    setSelectedUserSuccess: "setSelectedUserSuccess",
    clearSelectedUser: "clearSelectedUser",
    clearSelectedUserFail: "clearSelectedUserFail",
    clearSelectedUserSuccess: "clearSelectedUserSuccess",
  },
  schoology: {
    setAuthToken: "setAuthToken",
    setAuthTokenFail: "setAuthTokenFail",
    setAuthTokenSuccess: "setAuthTokenSuccess",
    clearAuthToken: "clearAuthToken",

    setGradeCategory: "setGradeCategory",
    setGradeCategoryFail: "setGradeCategoryFail",
    setGradeCategorySuccess: "setGradeCategorySuccess",

    setAllClassGradeCategory: "setAllClassGradeCategory",
    setAllClassGradeCategoryFail: "setAllClassGradeCategoryFail",
    setAllClassGradeCategorySuccess: "setAllClassGradeCategorySuccess",

    updateGradeCategory: "updateGradeCategory",
    updateGradeCategoryFail: "updateGradeCategoryFail",
    updateGradeCategorySuccess: "updateGradeCategorySuccess",

    setSchoologyAssignment: "setSchoologyAssignment",
    updateSchoologyAssignment: "updateSchoologyAssignment",
  },
  feedback: {
    invokeFeedback: "invokeFeedback",
    revokeFeedback: "revokeFeedback",
    createFeedback: "createFeedback",
    createFeedbackFail: "createFeedbackFail",
    createFeedbackSuccess: "createFeedbackSuccess",
    getFeedback: "getFeedback",
    getFeedbackFail: "getFeedbackFail",
    getFeedbackSuccess: "getFeedbackSuccess",
  },
  multiclassStudent: {
    invokeMulticlassPopup: "invokeMulticlassPopup",
    revokeMulticlassPopup: "revokeMulticlassPopup",
    disableMulticlass: "disableMulticlass",
    disableMulticlassCreate: "disableMulticlassCreate",
    disableMulticlassFail: "disableMulticlassFail",
    disableMulticlassSuccess: "disableMulticlassSuccess",
  },
  hyperlink: {
    invokeHyperlinkModal: "invokeHyperlinkModal",
    revokeHyperlinkModal: "revokeHyperlinkModal",
  },
  alert: {
    invokeAlert: "invokeAlert",
    revokeAlert: "revokeAlert",

    invokeMessageAlert: "invokeMessageAlert",
    revokeMessageAlert: "revokeMessageAlert",

    invokeMainBook: "invokeMainBook",
    removeMainBook: "removeMainBook",

    invokeEbookGalleryViewer: "invokeEbookGalleryViewer",
    removeEbookGalleryView: "removeEbookGalleryView",

    invokeSentence: "invokeSentence",
    removeSentence: "removeSentence"
  },
  logs: {
    createLog: "CREATE_LOG",
    createLogFail: "CREATE_LOG_FAIL",
    createLogSuccess: "CREATE_LOG_SUCCESS",

    listLogs: "LIST_LOGS",
    listLogsFail: "LIST_LOGS_FAIL",
    listLogsSuccess: "LIST_LOGS_SUCCESS",

    set_active_class_logs: "SET_ACTIVE_CLASS_LOGS",
    clear_active_class_log: "CLEAR_ACTIVE_CLASS_LOG",
  },
  classlink: {
    processClasslink: "PROCESS_CLASSLINK",
    processClasslinkFail: "PROCESS_CLASSLINK_FAIL",
    processClasslinkSuccess: "PROCESS_CLASSLINK_SUCCESS",
    setTenantSuccess: "SET_SUCCESS_TENANT",
    setTenantFail: "SET_TENANT_FAIL",
  },
  resources: {
    setResourcesSuccess: "setResourcesSuccess",
    setResourcesFail: "setResourcesFail",
    setResourcesInit: "setResourcesInit",
    setVideoResourcesSuccess: "setVideoResourcesSuccess",
    setVideoResourcesFail: "setVideoResourcesFail",
    setVideoResourcesInit: "setVideoResourcesInt",
    updateResources: "updateResources",
    updateResourcesSuccess: "updateResourcesSuccess",
    updateResourcesFail: "updateVideoResourcesFail",
    updateVideoResources: "updateVideoResources",
    updateVideoResourcesSuccess: "updateVideoResourcesSuccess",
    updateVideoResourcesFail: "updateResourcesFail",
    deleteResources: "deleteResources",
    deleteResourcesSuccess: "deleteResourcesSuccess",
    deleteResourcesFail: "deleteResourcesFail"
  },
  clever: {
    setCleverCources: "setCleverCources",
    setCleverAuthToken: "setCleverAuthToken",
    setCleverUserInfo: "setCleverUserInfo",
  },
  servertime: {
    createServerTime: "createServerTime",
    updateServertime: "updateServertime",
  },
  chat: {
    users: {
      setChatUser: "setchatuser",
      updateChatUser: "updateChatUser",
      clearChatUser: "clearChatUser",
      updateChatUserConfig: "updateChatUserConfig",
    },
    status: {
      setActive: "setActive",
      deactive: "setDeactive",
    },
    message: {
      setMessage: "setMessage",
      updateMessage: "updateMessage",
      createMessage: "createMessage",
      updateseenDelivery: "updateseenDelivery",
      messageStore: "messageStored",
      userMessageSeenUpdate: "userMessageSeenUpdate",
    },
    socket: {
      setSocket: "setSocket",
      clearSocket: "clearSocket",
      updateSocket: "updateSocket",
    },
    chatUserConfig: {
      setChatUserConfig: "setChatUserConfig",
      updateChatUserConfig: "updateUserConfig",
      clearChatUserConfig: "clearChatUserConfig",
    },
    scheduledMessage: {
      setScheduledMessage: "setScheduledMessage",
      createScheduledMessage: "createScheduledMessage",
      updateScheduledMessage: "updateScheduledMessage",
      clearScheduledMessage: "clearScheduledMessage",
      removeScheduledMessage: "removeScheduledMessage",
    },
  },
  timmer: {
    createTimmerRunning: "createTimmerRunning",
    clearTimmerRunning: "clearTimmerRunning",
  },
  exams: {
    createExams: "createExams",
    createExamsFail: "createExamsFail",
    createExamsSuccess: "createExamsSuccess",
    listExams: "listExams",
    listExamsFail: "listExamsFail",
    listExamsSuccess: "listExamsSuccess",
    updateExams: "updateExams",
    updateExamsFail: "updateExamsFail",
    updateExamsSuccess: "updateExamsSuccess",
    deleteExams: "deleteExams",
    deleteExamsFail: "deleteExamsFail",
    deleteExamsSuccess: "deleteExamsSuccess",
  },
  apexams: {
    createApExams: "createApExams",
    createApExamsFail: "createApExamsFail",
    createApExamsSuccess: "createApExamsSuccess",
    listApExams: "listApExams",
    listApExamsFail: "listApExamsFail",
    listApExamsSuccess: "listApExamsSuccess",
    updateApExams: "updateApExams",
    updateApExamsFail: "updateApExamsFail",
    updateApExamsSuccess: "updateApExamsSuccess",
    deleteApExams: "deleteApExams",
    deleteApExamsFail: "deleteApExamsFail",
    deleteApExamsSuccess: "deleteApExamsSuccess",
  },
  wheel: {
    invokeWheelModal: "invokeWheelModal",
    revokeWheelModal: "revokeWheelModal",
    
    invokeCheckList: "invokeCheckList",
    revokeCheckList: "revokeCheckList",

    invokeNoiseMeter: "invokeNoiseMeter",
    revokeNoiseMeter: "revokeNoiseMeter",

    invokeTrafficView: "invokeTrafficView",
    revokeTrafficModal: "revokeTrafficModal",
    revokeAllWidget: "revokeAllWidget"
  },
  googleclasses: {
    setGoogleAuth: "setGoogleAuth",
    updateGoogleAuth: "updateGoogleAuth",
    clearGoogleAuth: "clearGoogleAuth",
  },
  story: {
    createStorySuccess: "createStorySuccess",
    createStoryFailure: "createStoryFailure",
    listStorySuccess: "listStorySuccess",
    listStoryFailure: "listStoryFailure",
    updateStorySuccess: "updateStorySuccess",
    updateStoryFailure: "updateStoryFailure",
    deleteStorySuccess: "deleteStorySuccess",
    deleteStoryFailure: "deleteStoryFailure",
    setStandardsSuccess: "setStandardsSuccess",
    setStandardsFailure: "setStandardsFailure",
    createStanderdsSuccess: "createStanderdsSuccess",
    updateStanderdsSuccess: "updateStanderdsSuccess",
    deleteStanderdsSuccess: "deleteStanderdsSuccess"
  },
  howToVideos: {
    createHowToVideos: "createHowToVideos",
    createHowToVideosSuccess: "createHowToVideosSuccess",
    createHowToVideosFail: "createHowToVideosFail",

    updateHowToVideosSuccess: "updateHowToVideosSuccess",
    updateHowToVideosFail: "updateHowToVideosFail",

    deleteHowToVideosSuccess: "deleteHowToVideosSuccess",
    deleteHowToVideosFail: "deleteHowToVideosFail",

    listHowToVideosSuccess: "listHowToVideosSuccess",
    listHowToVideosFail: "listHowToVideosFail",
  },
  canvas: {
    setDistrictAuth:"setDistrictAuth",
    updateDistrictAuth:"updateDistrictAuth",
    clearDistrictAuth:"clearDistrictAuth",
    setCanvasAssignmentsCategory:"setCanvasAssignmentsCategory",
    updateCanvasAssignmentsCategory:"updateCanvasAssignmentsCategory",
    cleearCanvasAssignmentsCategory:"clearCanvasAssignmentsCategory"
  },
  welcome_videos: {
    setWelcomeVideos: "setWelcomeVideos",
    updateWelcomeVideos: "updateWelcomeVideos",
  },
  booksActiveInactive: {
    listBooksActiveInactive: "LIST_BOOKS_ACTIVE_INACTIVE",
    listBooksActiveInactiveFail: "LIST_BOOKS_ACTIVE_INACTIVE_FAIL",
    listBooksActiveInactiveSuccess: "LIST_BOOKS_ACTIVE_INACTIVE_SUCCESS",

    updateBookActiveInactive: "updateBookActiveInactive",
    updateBookActiveInactiveSuccess: "updateBookActiveInactiveSuccess",
    updateBookActiveInactiveFail: "updateBookActiveInactiveFail",

    createBookActiveInactive: "createBookActiveInactive",
    createBookActiveInactiveSuccess: "createBookActiveInactiveSuccess",
    createBookActiveInactiveFail: "createBookActiveInactiveFail",
  },
  licences: {
    createLicences: "CREATE_LICENCES",
    createLicencesFail: "CREATE_LICENCES_FAIL",
    createLicencesSuccess: "CREATE_LICENCES_SUCCESS",
    listLicences: "LIST_LICENCES",
    listLicencesFail: "LIST_LICENCES_FAIL",
    listLicencesSuccess: "LIST_LICENCES_SUCCESS",
    updateLicencesSuccess: "UPDATE_LICENCES_SUCCESS",
  },
  notifications: {
    setNotification: 'SET_NOTIFICATIONS',
    createNotificationSuccess: 'CREATE_NOTIFICATION_SUCCESS',
    updateNotificationSuccess: 'UPDATE_NOTIFICATION_SUCCES',
    updateNotificationSeen: 'UPDATE_NOTIFICATION_SEEN',
    clearNotification: 'CLEAR_NOTIFICATIONS'
  },
  vocabulary: {
    listVocabulary: "LIST_VOCABULARY",
    listVocabularyFail: "LIST_VOCABULARY_FAIL",
    listVocabularySuccess: "LIST_VOCABULARY_SUCCESS",

    createVocabulary: "CREATE_VOCABULARY",
    createVocabularyFail: "CREATE_VOCABULARY_FAIL",
    createVocabularySuccess: "CREATE_VOCABULARY_SUCCESS",
    updateVocabularySuccess: "UPDATE_VOCABULARY_SUCCESS",

    deleteVocabulary: 'DELETE_VOCABULARY',
    deleteVocabularySuccess: "DELETE_VOCABULARY_SUCCESS",
   
  },
  literacy_purpose:{
    setOverAll: "SET_OVER_ALL",
  },
  idea:{
    setIdea:'SET_IDEA',
    clearIdea: 'CLEAR_IDEA',
    updateIdea: 'UPDATE_IDEA',
    setIdeaModal: 'SET_IDEA_MODAL',
  },
  game:{
    set_player_modal: 'SET_PLAYER_MODAL',
    set_one_game_modal:'SET_ONE_GAME_MODAL',
    set_current_game_session: 'SET_CURRENT_GAME_SESSION',
    update_current_game_session:"UPDATE_CURRENT_GAME_SESSION",
    clear_current_game_session:"CLEAR_CURRENT_GAME_SESSION",

    set_class_game_activity: 'SET_CLASS_GAME_ACTIVITY',
    update_class_game_activity:"UPDATE_CLASS_GAME_ACTIVITY",
    clear_class_game_activity: "CLEAR_CLASS_GAME_ACTIVITY"
  },
  oneway_teacher: {
    create_oneway_teacher_video: "create_oneway_teacher_video",
    update_oneway_teacher_video: "update_oneway_teacher_video",
    delete_oneway_teacher_video: "deleteHowToVideosSuccess",
    list_oneway_teacher_video: "oneway_teacher_video"
  },
  one_game_video: {
    create_one_game_video: "create_one_game_video",
    update_one_game_video: "update_one_game_video",
    delete_one_game_video: "delete_one_game_video",
    list_one_game_video: "one_game_video"
  },
  debate:{
      invokeDebateModal: "invokeDebateModal",
      revokeDebateModal: "revokeDebateModal",
  },
  oneway_debate: {
    create_oneway_debate: "create_oneway_debate",
    update_oneway_debate: "update_oneway_debate",
    delete_oneway_debate: "deleteDebateSuccess",
    list_oneway_debate: "oneway_debate"
  },
  checkList: {
    listCheckList: "listCheckList",
    addCheckList: "addCheckList",
    deleteCheckList: "deleteCheckList",
    updateCheckList: "updateCheckList"
  },
  widgets: {
    listWidgets: "listWidgets",
    addWidgets: "addWidgets",
    deleteWidgets: "deleteWidgets",
    updateWidgets: "updateWidgets"
  },
  super_lesson: {
    listLessonSuccess: 'listLessonSuccess',
    createLessonSuccess: 'createLessonSuccess',
    updateLessonSuccess: 'updateLessonSuccess',
    deleteLessonSuccess: 'deleteLessonSuccess'
  },
  favourite_video : {
    create_favourite_video: "create_favourite_video",
    update_favourite_video: "update_favourite_video",
    list_favourite_video: "list_favourite_video",
    most_used_video: "most_used_video"
  },
  ebook_highlight: {
    set_ebook_highlight: "set_ebook_highlight",
    update_ebook_highlight: "update_ebook_highlight",
    clear_ebook_highlight: "clear_ebook_highlight"
  },
  differentiations: {
    listDifferentionLesson: 'listDifferentionLesson',
    createDifferentionLesson: 'createDifferentionLesson',
    updateDifferentionLesson: 'updateDifferentionLesson',
    deleteDifferentionLesson: 'deleteDifferentionLesson'
  }
};
