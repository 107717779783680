//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from "aws-amplify";
import * as Sentry from "@sentry/react";
export const sendWelcomeEmailForSchoology = async (data) => {
  try {
    await API.post("emailService", "/welcomeEmail", {
      body: {
        to: data.email,
        name: data.name,
        username: data.username,
        password: data.password,
      },
    });
  } catch (error) {
    if(error&&error.message!="Network Error"){
      Sentry.captureException(error)
   }
  }
};
export const sendInvitationEmail = async (data) => {
  try {
    return API.post("emailService", "/parent_invitation", {
      body: data,
    });
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error)
    }
    return ({ error });
  }
};
