import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useRef, useMemo } from "react";
import { useFirebase } from 'containers/FirebaseContext';
import { useHistory } from 'react-router-dom';
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentUser } from 'store/users/selectors';
import queryString from 'query-string';
import { doc, setDoc, serverTimestamp, collection, onSnapshot, query, updateDoc, getDoc } from 'firebase/firestore';
import * as Act from './actions';


const selectore = (state) => {
  return state.class_user_logs
};
export const useClassStudentLogs = () => {
  const selectedData = useSelector(selectore, shallowEqual)
  return selectedData;
}



export function useStudentClassPathLogListner() {
  const { firestore } = useFirebase();
  const currentClass = useCurrentClass();
  const currentUser = useCurrentUser();
  const [class_user_logs, setClassUserLogs] = useState([])
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Act.clearActiveClassStudentLog())
    if (!currentClass?.id || !currentUser?.id) return;
    if (currentUser.role === 'student') return;
    const classId = currentClass.id;
    const logsRef = collection(firestore, `class_logs/${classId}/user_logs`);
    const q = query(logsRef);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const usersList = [];
      querySnapshot.forEach((doc) => {
        usersList.push({ id: doc.id, ...doc.data() });
      });
      setClassUserLogs(usersList)
      dispatch(Act.setActiveClassStudentLog(usersList))
    });
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [currentUser, currentClass, firestore]);
  return class_user_logs
}



export function useActionPathLog() {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const currentClass = useCurrentClass();
  const { firestore } = useFirebase();
  const lastPathname = useRef('');

  useEffect(() => {
    let intervalId;
    const logRef = currentUser?.id && currentClass?.id
      ? doc(firestore, `class_logs/${currentClass.id}/user_logs`, currentUser.id)
      : null;

    const updateLog = async (location, resetIdleTime = false) => {
      if (!logRef) return;
      const logSnapshot = await getDoc(logRef);
      let idleTime = logSnapshot.exists() ? logSnapshot.data().idle_time || 0 : 0;
      if (resetIdleTime || location.pathname !== lastPathname.current) {
        idleTime = 0;
        lastPathname.current = location.pathname;
      } else {
        idleTime += 3;
      }
      const searchParams = queryString.parse(location.search);
      const logEntry = {
        userId: currentUser.id,
        classId: currentClass.id,
        lastVisitedPage: location.pathname,
        timestamp: serverTimestamp(),
        query: searchParams,
        active: true,
        idle_time: idleTime
      };

      setDoc(logRef, logEntry, { merge: true })
        .catch(error => console.error("Error updating page visit:", error));
    };

    const unlisten = history.listen((location) => {
      if (currentClass?.id && currentUser?.id && currentUser.role === 'student') {
        updateLog(location, true);
        clearInterval(intervalId);
        intervalId = setInterval(() => updateLog(location), 180000);
      }
    });
    return () => {
      unlisten();
      clearInterval(intervalId);
    };
  }, [history, currentUser, currentClass, firestore]);

  async function updateFirebaseOnClose() {
    try {
      const last_log = sessionStorage.getItem('last_log');
      const last_log_user = sessionStorage.getItem('last_log_user');
      const last_log_class = sessionStorage.getItem('last_log_class');
      if (last_log && last_log_user && last_log_class) {
        const logRef = doc(firestore, last_log, last_log_user);
        const logEntry = {
          userId: last_log_user,
          classId: last_log_class,
          lastVisitedPage: '/',
          timestamp: serverTimestamp(),
          query: {},
          active: false
        };
        await setDoc(logRef, logEntry, { merge: true })
        sessionStorage.removeItem('last_log');
        sessionStorage.removeItem('last_log_user');
        sessionStorage.removeItem('last_log_class');
      }
    } catch (error) {

    }
  }
  return { updateFirebaseOnClose };
}

export const useUrlBroadcaster = () => {
  const { firestore } = useFirebase();
  const currentClass = useCurrentClass();
  const currentUser = useCurrentUser();
  const history = useHistory();
  const broadcastUrlToClass = async () => {
    if (!currentClass?.id) return;
    const classId = currentClass.id;
    const classDocRef = doc(firestore, 'classes_broadcast_url', classId);
    try {
      const path = history.location.pathname === '/admin/class' ? "/student/class" : history.location.pathname;
      let search = history.location.search;
      search = search.replace('view=manage', 'view=home')
        .replace('view=gradebook', 'view=assessments')
        .replace('view=classdata', 'view=studentData');
      await setDoc(classDocRef, {
        activeUrl: `${path}${search}`,
        actionTimestamp: serverTimestamp(),
        actionBy: currentUser.id,
        classId: classId,
        active: true
      }, { merge: true });

      setTimeout(async () => {
        await setDoc(classDocRef, {
          activeUrl: '',
          actionTimestamp: serverTimestamp(),
          active: false
        }, { merge: true });
        console.log('URL cleared');
      }, 5000);
    } catch (error) {
      console.error('Error broadcasting URL:', error);
    }
  };

  return broadcastUrlToClass;
};
export const useMessageBroadcaster = () => {
  const { firestore } = useFirebase();
  const currentClass = useCurrentClass();
  const currentUser = useCurrentUser();
  const broadcastMessageToClass = async (message, users) => {
    if (!currentClass?.id) return;
    const classId = currentClass.id;
    const classDocRef = doc(firestore, 'classes_broadcast_message', classId);
    try {
      await setDoc(classDocRef, {
        message: message,
        actionTimestamp: serverTimestamp(),
        actionBy: currentUser.id,
        classId: classId,
        active: true,
        users: users
      }, { merge: true });

      setTimeout(async () => {
        await setDoc(classDocRef, {
          message: '',
          actionTimestamp: serverTimestamp(),
          active: false,
          users: []
        }, { merge: true });
        console.log('broadcast message cleared');
      }, 5000);
    } catch (error) {
      console.error('Error broadcasting message:', error);
    }
  };

  return broadcastMessageToClass;
};


export const useActiveClassUrl = () => {
  const [activeUrl, setActiveUrl] = useState('');
  const currentClass = useCurrentClass();
  const currentUser = useCurrentUser();
  const { firestore } = useFirebase();
  useEffect(() => {
    if (!currentClass?.id || !currentUser?.id) return;
    if (currentUser.role != 'student') return;
    const classDocRef = doc(firestore, 'classes_broadcast_url', currentClass.id);
    const unsubscribe = onSnapshot(classDocRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const url = data.activeUrl;
        const actionTime = data.actionTimestamp?.toDate().getTime();
        if (url && actionTime && (Date.now() - actionTime) <= 5000) {
          setActiveUrl(url);
        } else {
          setActiveUrl('');
        }
      }
    });

    return () => unsubscribe();
  }, [currentClass, currentUser]);

  return activeUrl;
};
export const useActiveClassBrodcastMessage = () => {
  const [active_message, setActiveMessage] = useState('');
  const currentClass = useCurrentClass();
  const currentUser = useCurrentUser();
  const { firestore } = useFirebase();
  const { invokeMessageAlert, revokeMessageAlert } = useMessageAlertView()
  useEffect(() => {
    if (!currentClass?.id || !currentUser?.id) return;
    if (currentUser.role != 'student') return;
    let timoutref = null;
    const classDocRef = doc(firestore, 'classes_broadcast_message', currentClass.id);
    const unsubscribe = onSnapshot(classDocRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const message = data.message;
        const users = data.users || [];
        const actionTime = data.actionTimestamp?.toDate().getTime();
        if (data.active && message && actionTime && (Date.now() - actionTime) <= 5000) {
          if (users.length == 0 || users.includes(currentUser.id)) {
            if (timoutref) {
              clearTimeout(timoutref)
            }
            setActiveMessage(message);
            invokeMessageAlert(message);
            timoutref = setTimeout(() => {
              revokeMessageAlert()
              setActiveMessage('');
              timoutref = null;
            }, 5000)
          }
        } else {
          setActiveMessage('');
        }
      }
    });

    return () => unsubscribe();
  }, [currentClass, currentUser]);

  return active_message;
};


export const useIsUriMovable = () => {
  const history = useHistory();

  return useMemo(() => {
    const movablePaths = [
      "/ebook", "/1nformate", "/informate", "/informateBook",
      "/resources", "/video", "/OneWayLessons", "/balconCultural",
      "/balconCulturalBook", "/Conectandome", "/ConectandomeBook",
      "/comunidad", "/ComunidadBook", "/exams", "/story-levels",
      "/stories", "/graphic_organizers", "/ap", "/apBook",
      "/admin/class"
    ];

    return movablePaths.includes(history.location.pathname);
  }, [history.location.pathname]);
};

const messageAlertSelector = state => state.messageAlertView
export const useMessageAlertView = () => {
  const messageAlertView = useSelector(messageAlertSelector, shallowEqual)
  const dispatch = useDispatch()
  const invokeMessageAlert = (message) => {
    dispatch(Act.setMessageAlertView(message))
  }
  const revokeMessageAlert = () => {
    dispatch(Act.revokeMessageAlerView())
  }
  return { messageAlertView, invokeMessageAlert, revokeMessageAlert };
}
