//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Modal,
    Select,
    TextField,
    IconButton
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ClassOutlined, Star, Add, Edit , Close , Delete} from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Tag from 'components/Tag';
import { filter, find, get, map , has } from "lodash";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//  -- Constants ----------------------
import {
    DateTimePicker,
} from "@material-ui/pickers";
import { useAllBook } from 'store/books/selectors';
import { useResources } from 'store/resources/selectors';
import { useUsersByClass } from "store/userClasses/selectors";
import COLOR from 'utils/constants/color';
import { groupLinkedSection, isVideo } from 'utils/lib/ebookHelper';
import { isBeforTime } from 'utils/methods/math';
import { useExams } from 'store/exams/selectors';
import { useCanvasAssignmentCategory } from 'store/canvas/selectors';
import { useCurrentUser } from 'store/users/selectors';
import CustomDateTimePicker from "components/DateTimePicker"

const levels = [
    {
        id: '1',
        value: 'easy',
        label: '2'
    },
    {
        id: '2',
        value: 'medium',
        label: '3'
    },
    {
        id: '3',
        value: 'hard',
        label: '4'
    }
]


const ClassNameText = styled.div`
   font-size:18px;
   font-weight:bold;
   text-align: left;
   text-transform: uppercase;
   line-break: anywhere;
`;
const ModalInnerWrapper = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 550px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow:scroll;
    max-height: 90vh; 
`;
const ClassConfigContainer = styled.div`
    // max-height: 500px;
    // overflow-y: auto;
    // overflow-x: hidden;
    padding: 12px;
`;

const Container = styled.div`
    border-top: 1px solid ${COLOR.black700};
    margin-top: 24px;
    padding-top: 40px;
    width: 100%;
`;
const Note = styled.span`
    color: ${COLOR.black400};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
`;
const CrossSection = styled.span`
    border: 1px solid;
    padding: 10px;
    margin-top: 30px;
    position: relative;
`;
const SectionTitle = styled.div`
    font-size: 14px;
    color: #ffffff;
    background: ${COLOR.tabActive};
    padding: 4px 8px;
    border-radius: 6px !important;
    cursor: pointer;
    position: absolute;
    top: -15px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: 120
    },
    formControlStudent: {
        backgroundColor: "#fff"
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));
// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginBottom: '25px',
//         width: '300px'
//     },
//     rootDif: {
//         width: '200px'
//     },
//     rootDifFull: {
//         width: "100%"
//     },
//     sizeSmall: {
//         width: '55px',
//         height: '25px'
//     },
//     checkedSwitch: {
//         transform: 'translateX(30px) !important'
//     },
//     label: {
//         textTransform: 'uppercase',
//     },
//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: 120
//     },
//     colorPrimary: {
//         color: `${COLOR.green}!important`
//     }
// }));


const defaultVal = {
    id: "all_student",
    label: "All studnet"
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: () => null,
};

const MultiMenu = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    variant: "menu",
    getContentAnchorEl: () => null,
};
function getStyles(name, studentsName, theme) {
    return {
        fontWeight:
            studentsName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const mapToClass = (availableClass, id, classes, data, currentClass) => {
    const classToInclude = availableClass.find(cls => cls.id === id);

    if (!classToInclude) {
        return classes;
    }

    const hasClass = classes.find(cls => cls.id === id);

    if (hasClass) {
        if (classes.length === 1) {
            return classes;
        }
        return classes.filter(cls => cls.id !== id);
    } else {
        const newClass = {
            displayName: classToInclude.displayName,
            dueDate: data.dueDate,
            id: classToInclude.id,
            scheduleAt: data.scheduleAt,
            sectionId: classToInclude.sectionId,
            schoologyCategory: null,
            diff: {
                id: classToInclude.id,
                retakesDif: 0,
                levelExercise: 'hard',
                dueDateDif: moment().add(1, 'day').toISOString(),
                scheduleAtDiff: moment().toISOString(),
                student: []
            }
        };

        return classes ? [...classes, newClass] : [newClass];
    }
}


function MultiSelecter({ options, onSelect, defaultValue }) {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [initialLoaded, setInitialLoaded] = useState(false)
    const isAllSelected =
        options.length > 0 && selected.length === options.length;
    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options);
            onSelect(selected.length === options.length ? [] : options)
            return;
        }
        onSelect(value)
        setSelected(value);
    };
    useEffect(() => {
        if (defaultValue && selected.length == 0 && !initialLoaded) {
            if (defaultValue.length > 0) {
                setSelected(filter(options, k => defaultValue.includes(k.id)))
            } else {
                setSelected(options);
            }
            setInitialLoaded(true)
        }
    }, [defaultValue, options])
    return (
        <FormControl variant="outlined" className={classes.formControlStudent}>
            <InputLabel id="mutiple-select-label" style={{ backgroundColor: "#fff" }}>Select students</InputLabel>
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {`${isAllSelected || selected.length == 0 ? "All students" : "Students(" + selected.length + ")"}`}
                        {/* {selected.map((value) => {
                            const label = value?`${value.lastName || ''} ,${value.firstName || ''}`:''
                            return (
                                <Chip key={value} label={label} className={classes.chip} />
                            )
                        })} */}
                        {/* <Chip  label={`${isAllSelected||selected.length==0?"All students":"Students("+selected.length+")"}`} className={classes.chip} /> */}
                    </div>
                )}
                MenuProps={MultiMenu}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                    />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.id} value={option}>
                        <ListItemIcon>
                            <Checkbox checked={Boolean(find(selected, k => k.id === option.id))} />
                        </ListItemIcon>
                        <ListItemText primary={`${option.lastName || ''} ,${option.firstName || ''}`} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const ClassDiffConfig = ({ diffConfigModal, setDiffConfigModal, data, listStudents, onChangeDone , classInfo}) => {
    const [selectedStudent, setSelectedStudent] = useState([])
    const [studentsError, setStudentError] = useState()
    const [scheduledDiffDate, setScheduledDiffDate] = useState()
    const [levelExercise, setLevelExercise] = useState()
    const [diffDate, setDiffDate] = useState()
    const [retake, setRetake] = useState()
    const [initalConfig, setInitalConfig] = useState(null);
    const classes = useStyles();
    const theme = useTheme();
    const hasDiff =  selectedStudent.length>0;

    const hasdiffConfigError = () => {
        if (!scheduledDiffDate || !diffDate) return true;
        else if (moment(scheduledDiffDate).isAfter(diffDate)) {
            return true;
        }
        return !hasDiff;
    }
    useEffect(() => {
        const todayDtime = moment().toISOString()
        const nextDtime = moment().add(1, "day").toISOString()
        if (data) {
            setSelectedStudent(get(data, 'student', []))
            if (data.scheduleAtDiff) {
                setScheduledDiffDate(get(data, 'scheduleAtDiff'))
            } else {
                setScheduledDiffDate(todayDtime)
            }
            if (data.levelExercise) {
                setLevelExercise(get(data, 'levelExercise'))
            } else {
                setLevelExercise('hard')
            }
            if (data.dueDateDif) {
                setDiffDate(get(data, 'dueDateDif', nextDtime))
            } else {
                setDiffDate(nextDtime)
            }
            if (data.retakesDif >= 0) {
                setRetake(get(data, 'retakesDif', 0))
            } else {
                setRetake(0)
            }

        } else {
            setScheduledDiffDate(todayDtime)
            setLevelExercise('hard')
            setDiffDate(nextDtime)
            setRetake(0)
            setSelectedStudent([])
        }
        if(data&& (!initalConfig||initalConfig.id != data.id)){
            setInitalConfig(data)
        }else{
            setInitalConfig(null)
        }
    }, [data])
    const getStartMintime = (value) => {
        if (value) {
            return isBeforTime(moment(value).toISOString()) ? moment(value).toISOString() : moment().toISOString()
        } else {
            return moment().toISOString()
        }
    }
    const getMinDate = (date1, date2) => {
        if (date1 && date2) {
            return isBeforTime(date1, date2) ? moment(date2).add(1, "minute").toISOString() : moment(date1).add(1, "minute").toISOString();
        } else if (date1 || date2) {
            return date1 ? moment(date1).add(1, "minute").toISOString() : moment(date2).add(1, "minute").toISOString();
        } else {
            return moment().toString()
        }
    }
    const onDone = () => {
        if (onChangeDone) {
            onChangeDone({
                id: classInfo.id?classInfo.id:data?.id,
                retakesDif: retake,
                levelExercise: levelExercise,
                dueDateDif: diffDate,
                scheduleAtDiff: scheduledDiffDate,
                student: selectedStudent
            })
        }
        setDiffConfigModal(false)
    }  
   const onDelete = () =>{
    if (onChangeDone) {
        onChangeDone({
            id: data?.id,
            retakesDif: 0,
            levelExercise: 'hard',
            dueDateDif: moment().add(1, 'day').toISOString(),
            scheduleAtDiff: moment().toISOString(),
            student: []
        })
     }
    setDiffConfigModal(false)
   }
    return (<Modal
        open={diffConfigModal}
        onClose={(e, r) => r == 'backdropClick' ? null : setDiffConfigModal(false)}
    >
        <ModalInnerWrapper className='Diff_modalContainer'>
            <IconButton
                onClick={() => setDiffConfigModal(false)}
                style={{ padding: 0, marginRight: 10 , position:"absolute" , right:0 , top:10 }}>
                <Close color="secondary" />
            </IconButton>
            <Row gutter={[16, 24]} className="durDateSpc">
                <Col span={24}>
                    <ClassNameText>Differentiation - {get(classInfo,"displayName")}</ClassNameText>
                </Col>
            </Row>
            <ClassConfigContainer>
                <Row gutter={[24, 24]} className="selectStudents" style={{ width: '100%', paddingLeft: 5 }}>
                    <FormControl className={classes.formControl} style={{ marginTop: '15px' }}>
                        <InputLabel id="bookList-label">Select students</InputLabel>
                        <Select
                            labelId="mutiple-select-label"
                            multiple
                            value={selectedStudent}
                            onChange={v => {
                                setSelectedStudent(v.target.value)
                            }}
                            displayEmpty
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => {
                                        const student = find(listStudents, k => k.id === value)
                                        const label = student ? `${student.lastName || ''} ,${student.firstName || ''}` : ''
                                        return (
                                            <Chip key={value} label={label} className={classes.chip} />
                                        )
                                    })}
                                </div>
                            )}
                            MenuProps={MultiMenu}
                        >
                            {map(listStudents, (option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    <ListItemIcon>
                                          <Checkbox checked={Boolean(find(selectedStudent, k => k == option.id))} />
                                    </ListItemIcon>
                                    <ListItemText primary={`${option.lastName || ''} ,${option.firstName || ''}`} />
                                </MenuItem>
                            ))}
                        </Select>
                        {studentsError &&
                            <FormHelperText error>Choose at least one student</FormHelperText>
                        }
                    </FormControl>
                </Row>
                <Row gutter={[16, 24]} className="durDateSpc">
                    <Col span={24}>
                        <CustomDateTimePicker
                            id="scheduleAtDiff"
                            label="Start Date Differentiation"
                            inputVariant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={null}
                            format="MM-dd-yyyy hh:mm a"
                            minDate={getStartMintime(get(initalConfig,"scheduleAtDiff"))}
                            minDateMessage={`Start date and time has to be after the ${moment(getStartMintime(get(initalConfig,"scheduleAtDiff"))).format("MM-DD-YYYY hh:mm a")} `}
                            initialFocusedDate={moment().toString()}
                            value={scheduledDiffDate}
                            onChange={e => {
                                setScheduledDiffDate(e.toISOString())
                            }} />
                    </Col>
                    <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                    <Note>The assignment will take effect after the scheduled date and time.</Note>
                </Row>
                <Row gutter={[16, 24]} style={{ margin: '25px -8px 12px' }}>
                    <Col span={24}>
                        <CustomDateTimePicker
                            id="dueDateDif"
                            label="Due Date Differentiation"
                            inputVariant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={null}
                            format="MM-dd-yyyy hh:mm a"
                            minDate={getMinDate(scheduledDiffDate)}
                            strictCompareDates
                            initialFocusedDate={moment().toString()}
                            minDateMessage={"Due Date and Time has to be after the Start Date and Time."}
                            value={diffDate}
                            onChange={e => {
                                setDiffDate(e.toISOString())
                            }} />
                    </Col>
                    <Col span={24}>
                        <FormControl variant="outlined">
                            <InputLabel>Select number of answers</InputLabel>
                            <Select
                                id="levelExercise"
                                value={levelExercise}
                                onChange={e => setLevelExercise(e.target.value)}
                                label="Select number of answers"
                            >
                                {map(levels, level => (
                                    <MenuItem key={level.id} value={level.value}>
                                        {level.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Container>
                    <Row gutter={[16, 24]}>
                        <Col span={16}>
                            <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                            <Note>This is the differentiation number of total attempts the differentiation student will have for this quiz.</Note>
                        </Col>
                        <Col span={8}>
                            <TextField
                                id="retakesDif"
                                onChange={e => {
                                    if (e.target.value < 0 || e.target.value > 10) {
                                        return;
                                    }
                                    setRetake(e.target.value)
                                }}
                                variant="outlined"
                                value={parseInt(retake, 10) || 0}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                            />
                        </Col>
                    </Row>
            <Row gutter={[16, 24]} style={{ marginTop: 16, width: "100%" }}>
                <Col span={12} style={{ display: "flex", justifyContent: "flex-start" }}>
                    {hasDiff && <Button color="secondary" variant='contained' onClick={onDelete}>
                        <Delete />
                    </Button>}
                </Col>
                <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Button color="primary" variant='contained' disabled={hasdiffConfigError()} onClick={onDone}>
                        done
                    </Button>
                </Col>
            </Row>
                </Container>

            </ClassConfigContainer>

        </ModalInnerWrapper>
    </Modal>)
}

export default function AssignmentClassConfig({
    data,
    userClasses,
    handleChange,
    currentClass,
    setErrorForDateConfig,
    errorForDateConfig,
    isSchoologyUser,
    schoologyAllClassGradeCategory
}) {
    const isEditing = get(data, "editing", false)
    const classes = useStyles();
    const [classConfigModal, setClassConfigModal] = useState(false)
    const [diffConfigModal, setDiffConfigModal] = useState(false)
    const [selectedClassInfo, setselectedClassInfo] = useState({})
    const [selectedClassDiff, setSelectedClassDiff] = React.useState({});
    const [availableClasses, setAvailableClasses] = React.useState([]);
    const [initalData,setInitialData] = useState(null);
    const usersByClass = useUsersByClass()
    const resources = useResources()
    const booksAllList = useAllBook()
    const exams = useExams();
    const canvasAssignmentCategory =  useCanvasAssignmentCategory()
    const currentUser  = useCurrentUser()
    const isCanvasUser  = !!get(currentUser,"canvas_id")
    // console.log(canvasAssignmentCategory,"canvasAssignmentCategorycanvasAssignmentCategory",availableClasses)
    useEffect(() => {
        if(!initalData||(data?.id!=initalData?.id)){
            setInitialData(data);
        }
    }, [data])
    useEffect(() => {
        try {
          if (userClasses && Array.isArray(userClasses) && userClasses.length > 0) {
            const booksNotAssignableIds = booksAllList.filter(b => b.isNotAssignable).map(book => `${book.id}`);
            const examBookIds = exams?.flatMap(entry => entry.exams.map(exam => `${exam.bookId}`));
            const chapter = get(data, "chapter") || get(data, "data.chapter");
            const bookId = get(data, "bookId") || get(data, "data.bookId");
              const filteredClasses = userClasses.filter(x => {
                  if (x.archived) {
                      return false;
                  }
                  const archivedBooks = get(x, "books.archived_books", []);
                  const userBooks = get(x, "books.books", []);
                  const hasArchived = archivedBooks.some(k => k.bookId == bookId || k.bookId == chapter)
                  if (hasArchived) {
                      return false;
                  }
                  if (chapter || bookId) {
                      if (isVideo(chapter, resources)) {
                          return true;
                      }
                      if (booksNotAssignableIds.includes(chapter) || examBookIds.includes(chapter) || booksNotAssignableIds.includes(bookId) || examBookIds.includes(bookId)) {
                          return true;
                      }
                     
                      return userBooks.some(k => k.bookId == bookId || k.bookId == chapter);
                  }
                  return false;
              });

              setAvailableClasses(filteredClasses);
          }
        } catch (error) {
          console.log(error, "error,quiz");
        }
      }, [userClasses, booksAllList, exams, data, resources]);
      
    const updateClassConfig = (i, key, value,dt) => {
        const v = map(data.classes, (k) => {
            if (k.id === i) {
                k[key] = value;
                if(dt){
                    if(has(dt,"group_weight")){
                        k.canvas_weightage  = dt.group_weight;
                    }
                }
                return k;
            } else {
                return k;
            }
        })
        let val = { target: { id: "classes", value: v } }
        handleChange(val)
    }
    const getMinDate = (date1, date2) => {
        if (date1 && date2) {
            return isBeforTime(date1, date2) ? moment(date2).add(1, "minute").toISOString() : moment(date1).add(1, "minute").toISOString();
        } else if (date1 || date2) {
            return date1 ? moment(date1).add(1, "minute").toISOString() : moment(date2).add(1, "minute").toISOString();
        } else {
            return moment().toString()
        }
    }
    const updateError = (key, i, value) => {
        const errordata = errorForDateConfig;
        if (i >= 0 && i != null) {
            if (errordata.classCnfError[i]) {
                errordata.classCnfError[i][key] = value;
            } else {
                errordata.classCnfError[i] = {}
                errordata.classCnfError[i][key] = value;
            }
        } else {
            const errordata = errorForDateConfig;
            errordata[key] = value
        }
        setErrorForDateConfig(errordata)
    }
    const hasClassConfigError = () => {
        const v = errorForDateConfig.classCnfError.filter(k => Object.values(k).filter(v => v).length > 0)
        return v.length > 0
    }
    const getStartMintime = (value) => {
        if (value) {
            return isBeforTime(moment(value).toISOString()) ? moment(value).toISOString() : moment().toISOString()
        } else {
            return moment().toISOString()
        }
    }
    const onClassSelectorClick = (id) => {
        const v = { target: { id: "classes", value: mapToClass(availableClasses, id, data.classes, data, currentClass) } }
        handleChange(v)
    }
    const onDiffClick = (id) => {
        setDiffConfigModal(true)
        const classConf = find(data.classes, ['id', id])
        if (classConf) {
            setSelectedClassDiff(classConf.diff)
        }
    }
    const crossed_courses = filter(availableClasses, k => k.canvas_cross_course_id)
    const non_crossed_courses = filter(availableClasses, k => !k.canvas_cross_course_id && !k.schoology_parent_id)
    const linked_classes = groupLinkedSection(availableClasses)
    return (<>
        <Row gutter={[0, 24]} style={{ width: '100%' }}>
            <Col span={24}>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="classLabel">Classes</InputLabel>
                     {Object.keys(linked_classes).length>0?Object.keys(linked_classes).map(lin_sec=>(<CrossSection>
                        <SectionTitle>Linked section</SectionTitle>
                        {map(linked_classes[lin_sec], sb => (<React.Fragment key={sb.id}>
                            <Row className='differRwCstm' style={{ margin: " 10px 0px" }}>
                                <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                                    <MenuItem key={sb.id}
                                        style={{ height: 35  , paddingLeft:5}}
                                        disabled={Boolean(find(get(initalData, "classes"), ['id', sb.id]))}
                                        value={sb}
                                        onClick={() => {
                                            onClassSelectorClick(sb.id)
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={Boolean(find(data.classes, ['id', sb.id]))}
                                                onChange={e => { }} />
                                        </ListItemIcon>
                                        <ListItemText primary={`${sb.displayName || ''}`} />
                                    </MenuItem>
                                </Col>
                                <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                                    {Boolean(find(data.classes, ['id', sb.id])) && <Button color="primary" onClick={() => {
                                        onDiffClick(sb.id)
                                        setselectedClassInfo(sb)
                                    }}>
                                        {get(find(data.classes, ['id', sb.id]), "diff.student.length", 0) > 0 ? <Edit /> : <Add />}  Differentiation
                                    </Button>}
                                </Col>
                                <Col span={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                    {isSchoologyUser && Boolean(find(data.classes, ['id', sb.id])) &&
                                        <FormControl size="small" variant="outlined" className={classes.formControlStudent}>
                                            <InputLabel id="schoologyCategory" style={{ backgroundColor: "#fff", width: 'max-content' }}>Select Categories</InputLabel>
                                            <Select
                                                labelId="schoologyCategory"
                                                value={find(schoologyAllClassGradeCategory[sb.sectionId], ['id', get(find(data.classes, ['id', sb.id]), "schoologyCategory")])}
                                                onChange={e => {
                                                    const catId = get(e.target.value, "id")
                                                    updateClassConfig(sb.id, "schoologyCategory", catId)
                                                }}
                                                renderValue={(selected) => {
                                                    return (
                                                        <div className={classes.chips}>
                                                            <ListItemText primary={`${selected.title || ''}`} />
                                                        </div>
                                                    )
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {map(schoologyAllClassGradeCategory[sb.sectionId], (option) => (
                                                    <MenuItem key={option.id} value={option}>
                                                        <ListItemText primary={`${option.title || ''} (${option.weight || '100'})`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                    {isCanvasUser && Boolean(find(data.classes, ['id', sb.id])) &&
                                        <FormControl size="small" variant="outlined" className={classes.formControlStudent}>
                                            <InputLabel id="schoologyCategory" style={{ backgroundColor: "#fff", width: 'max-content' }}>Select Assignment Categories</InputLabel>
                                            <Select
                                                labelId="schoologyCategory"
                                                value={find(canvasAssignmentCategory[sb.canvas_id], ['id', get(find(data.classes, ['id', sb.id]), "canvas_assignment_catrgory")])}
                                                onChange={e => {
                                                    const catId = get(e.target.value, "id")
                                                    updateClassConfig(sb.id, "canvas_assignment_catrgory", catId, e.target.value)
                                                }}
                                                renderValue={(selected) => {
                                                    return (
                                                        <div className={classes.chips}>
                                                            <ListItemText primary={`${selected.name || ''}`} />
                                                        </div>
                                                    )
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {map(canvasAssignmentCategory[sb.canvas_id], (option) => (
                                                    <MenuItem key={option.id} value={option}>
                                                        <ListItemText primary={`${option.name || ''} (${option.group_weight || '0'})`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                </Col>
                            </Row>
                        </React.Fragment>))}
                    </CrossSection>)):null}

                    {crossed_courses.length > 0 ? <CrossSection>
                        <SectionTitle>Cross-listed</SectionTitle>
                        {map(crossed_courses, sb => (<React.Fragment key={sb.id}>
                            <Row className='differRwCstm' style={{ margin: " 10px 0px" }}>
                                <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                                    <MenuItem key={sb.id}
                                        style={{ height: 35  , paddingLeft:5}}
                                        disabled={Boolean(find(get(initalData, "classes"), ['id', sb.id]))}
                                        value={sb}
                                        onClick={() => {
                                            onClassSelectorClick(sb.id)
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={Boolean(find(data.classes, ['id', sb.id]))}
                                                onChange={e => { }} />
                                        </ListItemIcon>
                                        <ListItemText primary={`${sb.displayName || ''}`} />
                                    </MenuItem>
                                </Col>
                                <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                                    {Boolean(find(data.classes, ['id', sb.id])) && <Button color="primary" onClick={() => {
                                        onDiffClick(sb.id)
                                        setselectedClassInfo(sb)
                                    }}>
                                        {get(find(data.classes, ['id', sb.id]), "diff.student.length", 0) > 0 ? <Edit /> : <Add />}  Differentiation
                                    </Button>}
                                </Col>
                                <Col span={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                    {isSchoologyUser && Boolean(find(data.classes, ['id', sb.id])) &&
                                        <FormControl size="small" variant="outlined" className={classes.formControlStudent}>
                                            <InputLabel id="schoologyCategory" style={{ backgroundColor: "#fff", width: 'max-content' }}>Select Categories</InputLabel>
                                            <Select
                                                labelId="schoologyCategory"
                                                value={find(schoologyAllClassGradeCategory[sb.sectionId], ['id', get(find(data.classes, ['id', sb.id]), "schoologyCategory")])}
                                                onChange={e => {
                                                    const catId = get(e.target.value, "id")
                                                    updateClassConfig(sb.id, "schoologyCategory", catId)
                                                }}
                                                renderValue={(selected) => {
                                                    return (
                                                        <div className={classes.chips}>
                                                            <ListItemText primary={`${selected.title || ''}`} />
                                                        </div>
                                                    )
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {map(schoologyAllClassGradeCategory[sb.sectionId], (option) => (
                                                    <MenuItem key={option.id} value={option}>
                                                        <ListItemText primary={`${option.title || ''} (${option.weight || '100'})`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                    {isCanvasUser && Boolean(find(data.classes, ['id', sb.id])) &&
                                        <FormControl size="small" variant="outlined" className={classes.formControlStudent}>
                                            <InputLabel id="schoologyCategory" style={{ backgroundColor: "#fff", width: 'max-content' }}>Select Assignment Categories</InputLabel>
                                            <Select
                                                labelId="schoologyCategory"
                                                value={find(canvasAssignmentCategory[sb.canvas_id], ['id', get(find(data.classes, ['id', sb.id]), "canvas_assignment_catrgory")])}
                                                onChange={e => {
                                                    const catId = get(e.target.value, "id")
                                                    updateClassConfig(sb.id, "canvas_assignment_catrgory", catId, e.target.value)
                                                }}
                                                renderValue={(selected) => {
                                                    return (
                                                        <div className={classes.chips}>
                                                            <ListItemText primary={`${selected.name || ''}`} />
                                                        </div>
                                                    )
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {map(canvasAssignmentCategory[sb.canvas_id], (option) => (
                                                    <MenuItem key={option.id} value={option}>
                                                        <ListItemText primary={`${option.name || ''} (${option.group_weight || '0'})`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                </Col>
                            </Row>
                        </React.Fragment>))}
                    </CrossSection> : null}



                    {map(non_crossed_courses, sb => (<React.Fragment key={sb.id}>
                        <Row className='differRwCstm' style={{ margin: " 10px 0px" }}>
                            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                                <MenuItem key={sb.id}
                                    style={{ height: 35}}
                                    disabled={Boolean(find(get(initalData, "classes"), ['id', sb.id]))}
                                    value={sb}
                                    onClick={() => {
                                        onClassSelectorClick(sb.id)
                                    }}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={Boolean(find(data.classes, ['id', sb.id]))}
                                            onChange={e => { }} />
                                    </ListItemIcon>
                                    <ListItemText primary={`${sb.displayName || ''}`} />
                                </MenuItem>
                            </Col>
                            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                                {Boolean(find(data.classes, ['id', sb.id])) && <Button color="primary" onClick={() => {
                                    onDiffClick(sb.id)
                                    setselectedClassInfo(sb)
                                }}>
                                    {get(find(data.classes, ['id', sb.id]), "diff.student.length", 0) > 0 ? <Edit /> : <Add />}  Differentiation
                                </Button>}
                            </Col>
                            <Col span={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                {isSchoologyUser && Boolean(find(data.classes, ['id', sb.id])) &&
                                    <FormControl size="small" variant="outlined" className={classes.formControlStudent}>
                                        <InputLabel id="schoologyCategory" style={{ backgroundColor: "#fff", width: 'max-content' }}>Select Categories</InputLabel>
                                        <Select
                                            labelId="schoologyCategory"
                                            value={find(schoologyAllClassGradeCategory[sb.sectionId], ['id', get(find(data.classes, ['id', sb.id]), "schoologyCategory")])}
                                            onChange={e => {
                                                const catId = get(e.target.value, "id")
                                                updateClassConfig(sb.id, "schoologyCategory", catId)
                                            }}
                                            renderValue={(selected) => {
                                                return (
                                                    <div className={classes.chips}>
                                                        <ListItemText primary={`${selected.title || ''}`} />
                                                    </div>
                                                )
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {map(schoologyAllClassGradeCategory[sb.sectionId], (option) => (
                                                <MenuItem key={option.id} value={option}>
                                                    <ListItemText primary={`${option.title || ''} (${option.weight || '100'})`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                                {isCanvasUser && Boolean(find(data.classes, ['id', sb.id])) &&
                                    <FormControl size="small" variant="outlined" className={classes.formControlStudent}>
                                        <InputLabel id="schoologyCategory" style={{ backgroundColor: "#fff", width: 'max-content' }}>Select Assignment Categories</InputLabel>
                                        <Select
                                            labelId="schoologyCategory"
                                            value={find(canvasAssignmentCategory[sb.canvas_id], ['id', get(find(data.classes, ['id', sb.id]), "canvas_assignment_catrgory")])}
                                            onChange={e => {
                                                const catId = get(e.target.value, "id")
                                                updateClassConfig(sb.id, "canvas_assignment_catrgory", catId, e.target.value)
                                            }}
                                            renderValue={(selected) => {
                                                return (
                                                    <div className={classes.chips}>
                                                        <ListItemText primary={`${selected.name || ''}`} />
                                                    </div>
                                                )
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {map(canvasAssignmentCategory[sb.canvas_id], (option) => (
                                                <MenuItem key={option.id} value={option}>
                                                    <ListItemText primary={`${option.name || ''} (${option.group_weight || '0'})`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            </Col>
                        </Row>
                    </React.Fragment>))}
                </FormControl>
            </Col>
        </Row>
        <Row>
            <Button color="primary" onClick={() => {
                setClassConfigModal(true)
            }}>
                <ClassOutlined /> CLASS CONFIGURATION
            </Button>
        </Row>
        <Modal
            open={classConfigModal}
            onClose={(e, r) => r == 'backdropClick' ? null : setClassConfigModal(false)}
        >
            <ModalInnerWrapper className='Diff_modalContainer'>
                <ClassConfigContainer>
                    {
                        map(data.classes, (cnf, i) => {
                            const classUser = []
                            classUser.push(...get(usersByClass, cnf.id, []))
                            return (<div key={i}>
                                <Row gutter={[16, 24]} className="durDateSpc">
                                    <Col span={24}>
                                        <ClassNameText>{cnf.displayName}</ClassNameText>
                                    </Col>
                                    <Col span={24}>
                                        <CustomDateTimePicker
                                            id="scheduleAt"
                                            label="Start Date"
                                            inputVariant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={isEditing}
                                            format="MM-dd-yyyy hh:mm a"
                                            minDate={isEditing ? null : moment().toISOString()}
                                            minDateMessage={`Start date and time  has to be after the ${moment(getStartMintime(get(cnf, "scheduleAt"))).format("MM-DD-YYYY hh:mm a")} `}
                                            initialFocusedDate={getMinDate()}
                                            defaultValue={null}
                                            value={get(cnf, "scheduleAt", get(data, "scheduleAt", moment().toString()))}
                                            onChange={e => {
                                                updateClassConfig(cnf.id, "scheduleAt", e.toISOString())
                                            }} />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 24]} style={{ marginTop: 16 }} className="durDateSpc">
                                    <Col span={24}>
                                        <CustomDateTimePicker
                                            id="dueDate"
                                            label="Due Date"
                                            inputVariant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onError={e => {
                                                updateError("dueDate", i, e)
                                            }}
                                            strictCompareDates
                                            defaultValue={null}
                                            format="MM-dd-yyyy hh:mm a"
                                            minDate={getMinDate(get(cnf, "scheduleAt"))}
                                            initialFocusedDate={moment().toString()}
                                            minDateMessage={"Due Date and Time has to be after the Start Date and Time."}
                                            value={get(cnf, "dueDate", get(data, "dueDate", moment().toString()))}
                                            onChange={e => {
                                                updateClassConfig(cnf.id, "dueDate", e.toISOString())
                                            }} />
                                    </Col>
                                </Row>
                                <Row gutter={[16, 24]} style={{ marginTop: 16 }} className="durDateSpc">
                                    <Col span={24}>
                                        <MultiSelecter options={classUser} onSelect={(value) => {
                                            if (value && value.length === classUser.length) {
                                                updateClassConfig(cnf.id, "users", [])
                                            } else {
                                                updateClassConfig(cnf.id, "users", value.map(k => k.id))
                                            }
                                        }}
                                            defaultValue={get(cnf, "users", [])}
                                        />
                                    </Col>
                                </Row>
                                {get(data, "lateDate") ? (<Row gutter={[16, 24]} style={{ marginTop: 16 }}>
                                    <Col span={24}>
                                        <CustomDateTimePicker
                                            id="lateDate"
                                            label="Late Submission date"
                                            inputVariant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onError={e => {
                                                updateError("lateDate", i, e)
                                            }}
                                            strictCompareDates
                                            format="MM-dd-yyyy hh:mm a"
                                            minDate={getMinDate(get(cnf, "dueDate"), get(cnf, "scheduleAt"))}
                                            defaultValue={null}
                                            initialFocusedDate={moment().toString()}
                                            minDateMessage={"Late Date and Time has to be after the Due Date and Time."}
                                            value={get(cnf, "lateDate", get(data, "lateDate")) == "always" ? undefined : get(cnf, "lateDate", get(data, "lateDate"))}
                                            onChange={e => {
                                                updateClassConfig(cnf.id, "lateDate", e.toISOString())
                                            }} />
                                    </Col>
                                </Row>) : null}
                            </div>)
                        })
                    }
                </ClassConfigContainer>
                <Row gutter={[16, 24]} style={{ marginTop: 16, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <Button color="primary" variant='contained' disabled={hasClassConfigError()} onClick={() => setClassConfigModal(false)}>
                        done
                    </Button>

                </Row>

            </ModalInnerWrapper>
        </Modal>
        {/*  diff config section */}
        <ClassDiffConfig
            diffConfigModal={diffConfigModal}
            setDiffConfigModal={setDiffConfigModal}
            listStudents={get(usersByClass, get(selectedClassInfo, 'id'), [])}
            data={selectedClassDiff}
            classInfo={selectedClassInfo}
            onChangeDone={v => {
                if (v && v.id) {
                    updateClassConfig(v.id, "diff", v)
                }
            }} />
    </>)
}
