//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

//  -- Components ---------------------
import {
    Button,
    FormControlLabel,
    Switch,
    TextField
} from '@material-ui/core';
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import { get } from 'lodash';
//  -- Constants ----------------------
import { useHistory } from 'react-router-dom';
import COLOR from 'utils/constants/color';
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { handleCreateOrganization, handleGetDistrictsWithCount, handleListDistricts, handleUpdateOrganization } from 'store/organizations/thunks';
import { handleCreateLicences, handleListLicences, handleUpdateDistrictLicences } from 'store/licences/thunks';
import moment from 'moment'

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Header = styled.span`
    color: black !important;
    display: block;
	font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
	margin: 0px auto;
    // max-width: 480px;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//

const SchoolForm = ({
    currentOrg,
    school,
    district,
    editing,
    onClose,
}) => {
    const dispatch = useDispatch();
    const [data, setData] = useState(editing ? school : { demo: true });
    const [loading, setLoading] = useState(false);
    const [licensesError, setLicensesError] = useState(false);
    const [licensesErrorMsg, setLicensesErrorMsg] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMsg, setNameErrorMsg] = useState('');
    const [isClaver, setIsClaver] = useState(false)
    const [statusError, setStatusError] = useState(true);
    const history = useHistory();
    const [freeLicenses, setFreeLicenses] = useState(Number(currentOrg?.licenses) - Number((currentOrg?.maxCount != "undefined" ? currentOrg?.maxCount : 0)));
    const [actualLicenses, setActualLicenses] = useState(Number(school?.licenses));
    const [maxLicenses, setMaxLicenses] = useState((Number(currentOrg?.licenses) - Number((currentOrg?.maxCount != "undefined" ? currentOrg?.maxCount : 0))) + Number(school?.licenses));
    const actualOrgLicenses = Number(currentOrg?.licenses)
    const handleChange = e => {
        // Validate licenses count at editing School
        if (editing && e.target.id === "licenses") {
            if (Number(e.target.value) < Number((data.maxCount != "undefined" ? data.maxCount : 0))) {
                setLicensesError(true);
                setLicensesErrorMsg('Assigned licenses cannot be less than already used.');
            } else if (Number(e.target.value) > Number(maxLicenses)) {
                setLicensesError(true);
                setLicensesErrorMsg('Increased licenses cannot be greater than available in district.');
                setStatusError(true);
            } else if (Number(freeLicenses) < Number(e.target.value)) {
                setLicensesError(true);
                setLicensesErrorMsg('Assigned licenses cannot be grater than available in district.');
                setStatusError(true);
            }
            else if (Number(e.target.value === '')) {
                setLicensesError(true);
                setLicensesErrorMsg('Assigned licenses must be grater than 0 in district.');
                setStatusError(true);
            }
            else {
                setLicensesError(false);
                setLicensesErrorMsg('');
                setStatusError(false);
            }
        }
        // Validate licenses count at creating School
        else {
            if (e.target.id === "licenses") {
                if (Number(freeLicenses) < Number(e.target.value)) {
                    setLicensesError(true);
                    setLicensesErrorMsg('Assigned licenses cannot be grater than available in district.');
                    setStatusError(true);
                }
                else if (Number(e.target.value) < 0 || Number(e.target.value) === 0) {
                    setLicensesError(true);
                    setLicensesErrorMsg('Assigned licenses must be grater than 0 in district.');
                    setStatusError(true);
                }

                else {
                    setLicensesError(false);
                    setLicensesErrorMsg('');
                    setStatusError(false);
                }
                
            }
            else if (e.target.id === "displayName") {
                if (e.target.value === '') {
                    setNameError(true);
                    setNameErrorMsg('Please enter school name');
                }
                else {
                    setNameError(false);
                    setNameErrorMsg('');
                    if (editing) {
                        setStatusError(false);
                    }
                }
            }
            else {
                setLicensesError(false);
                setLicensesErrorMsg('');
            }
        }

        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (editing) {
            // Update school info
            await dispatch(handleUpdateOrganization(school.id, {
                ...school,
                ...data,
                canvas_id:data.canvas_id?data.canvas_id:currentOrg?.canvas_id
            }));
            const incSchoolLic = Number(data.licenses) - Number(actualLicenses);
            const districtActLicenses = Number(currentOrg.maxCount) + Number(incSchoolLic);
            await dispatch(handleUpdateOrganization(currentOrg?.id, {
                ...currentOrg,
                maxCount: districtActLicenses,
            }));

            await dispatch(handleListDistricts());
            onClose();
            history.push('/data-browsers');
        } else {
            const createSchool = await dispatch(handleCreateOrganization({
                ...data,
                parentId: currentOrg?.id,
                canvas_id:currentOrg?.canvas_id,
                type: 'school',
            }));

            // Calculate actual available district licences.
            const districtActLicenses = Number(currentOrg.maxCount) + Number(data.licenses);

            // Update District info
            await dispatch(handleUpdateOrganization(currentOrg?.id, {
                ...currentOrg,
                maxCount: districtActLicenses,
            }));

            await dispatch(handleListDistricts());
            // onClose();
            history.push('/data-browsers');
            const now = moment();
            const unixTimestamp = now.valueOf();
            const availableDstLicenses = actualOrgLicenses - districtActLicenses
            dispatch(handleCreateLicences({
                districtId: get(currentOrg, 'id', ''),
                allocatedLicense: get(data, 'licenses', ''),
                usedLicense: 0,
                schoolId: get(createSchool, 'id', ''),
                modified: unixTimestamp,
                availableLicenses: get(data, 'licenses', '')
            }))
            // dispatch(handleUpdateDistrictLicences(currentOrg?.id, {
            //     usedLicense: districtActLicenses,
            //     availableLicenses: availableDstLicenses
            // }))
            dispatch(handleListLicences())
        }
        setLoading(false);
    };
    useEffect(() => {
        if (currentOrg.cleverId) {
            setIsClaver(true)
        } else {
            setIsClaver(false)
        }
    }, [currentOrg])

    const backToDataBrowser = () => {
        history.push('/data-browsers');
    };
    return (
        <Wrapper onSubmit={onSubmit}>
            <Header className={"schoolForm"} style={{ marginBottom: 50 }} >{editing ? 'Edit School' : 'Create School'}</Header>
            <Row gutter={[24, 24]}>
                <Col xs={24} lg={12} className={"floatingLabelBG"}>
                    <TextField
                        id="displayName"
                        error={nameError}
                        onChange={handleChange}
                        // className={"schoolForm"}
                        label="School Name"
                        variant="outlined"
                        value={data?.displayName}
                        helperText={nameError && nameErrorMsg}
                        style={{ borderColor: COLOR.black100 }}
                    />
                </Col>
                <Col xs={24} lg={12} className={"floatingLabelBG"}>
                    <TextField
                        error={licensesError}
                        id="licenses"
                        onChange={handleChange}
                        label="Licenses"
                        variant="outlined"
                        helperText={licensesError && licensesErrorMsg}
                        value={data?.licenses}
                        style={{ borderColor: COLOR.black100 }}
                        type="number"
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={e => setData({
                                    ...data,
                                    'demo': e.target.checked
                                })}
                                name="view"
                                color="primary"
                                size="small"
                                // className={"schoolForm"}
                                checked={data?.demo}
                                style={{ borderColor: COLOR.black100 }}
                            />
                        }
                        // className={"schoolForm"}
                        label="Demo"
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <h3 style={{ borderColor: COLOR.black100 }}>Un-Allocated Licenses: {freeLicenses} </h3>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                {editing === true &&
                    <>
                        <Col span={4} className={"floatingLabelBG"}>
                            <TextField
                                disabled
                                id="usedLicenses"
                                label="Used Licenses"
                                variant="outlined"
                                // value={(data?.maxCount != "undefined" ? data.maxCount : 0)}
                                style={{ borderColor: COLOR.black100 }}
                            />
                        </Col>
                        <Col span={4} className={"floatingLabelBG"}>
                            <TextField
                                disabled
                                id="freeLicenses"
                                label="Licenses Available"
                                variant="outlined"
                            // value={data?.licenses - (data?.maxCount != "undefined" ? data.maxCount : 0)}
                            // className={"schoolForm"}
                            />
                        </Col>
                    </>
                }
            </Row>
            {isClaver && <Row style={{ marginTop: 8 }}>
                <Col span={6}>
                    <TextField
                        disabled={editing}
                        id="cleverId"
                        label="Clever Id"
                        variant="outlined"
                        value={get(data, "cleverId", '')}
                        className={"districForm"}
                        onChange={handleChange}
                    />
                </Col>
            </Row>}
            {/* <Row gutter={[8, 24]}>
                <Col span={12} className={"floatingLabelBG"}>
                    <TextField
                        id="displayName"
                        onChange={handleChange}
                        // className={"schoolForm"}
                        label="School Name"
                        variant="outlined"
                        value={data?.displayName}
                        style={{ borderColor: COLOR.black100 }}
                    />
                </Col>
                <Col offset={2} span={10}>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={e => setData({
                                    ...data,
                                    'demo': e.target.checked
                                })}
                                name="view"
                                color="primary"
                                size="small"
                                // className={"schoolForm"}
                                checked={data?.demo}
                                style={{ borderColor: COLOR.black100 }}
                            />
                        }
                        // className={"schoolForm"}
                        label="Demo"
                    />
                </Col>
            </Row>
            {
                <Row gutter={[8, 24]} style={{ marginTop: 20 }}>
                    <Col span={12}>
                        <h3 style={{ borderColor: COLOR.black100 }}>Available District Licenses: {freeLicenses} </h3>
                    </Col>
                </Row>
            }
            <Row gutter={[8, 24]} style={{ marginTop: 8 }}>
                <Col span={4} className={"floatingLabelBG"}>
                    <TextField
                        error={licensesError}
                        id="licenses"
                        onChange={handleChange}
                        label="Licenses"
                        variant="outlined"
                        helperText={licensesError && licensesErrorMsg}
                        value={data?.licenses}
                        style={{ borderColor: COLOR.black100 }}
                    />
                </Col>
                {editing === true &&
                    <>
                        <Col span={4} className={"floatingLabelBG"}>
                            <TextField
                                disabled
                                id="usedLicenses"
                                label="Used Licenses"
                                variant="outlined"
                                value={(data?.maxCount != "undefined" ? data.maxCount : 0)}
                                style={{ borderColor: COLOR.black100 }}
                            />
                        </Col>
                        <Col span={4}>
                            <TextField
                                disabled
                                id="freeLicenses"
                                label="Licenses Available"
                                variant="outlined"
                                value={data?.licenses - (data?.maxCount != "undefined" ? data.maxCount : 0)}
                                className={"schoolForm"}
                            />
                        </Col>
                    </>
                }
            </Row>
            {isClaver && <Row style={{ marginTop: 8 }}>
                <Col span={6}>
                    <TextField
                        disabled={editing}
                        id="cleverId"
                        label="Clever Id"
                        variant="outlined"
                        value={get(data, "cleverId", '')}
                        className={"districForm"}
                        onChange={handleChange}
                    />
                </Col>
            </Row>}*/}
            <Row style={{ width: '100%', marginTop: 30 }}>
                <Col span={4}>
                    <Button disabled={nameError || statusError || licensesError} style={{ width: '100%' }} variant="contained" color="primary" type='submit'>
                        {loading ? <Loading size={20} white /> : (editing ? 'Update' : 'Create')}
                    </Button>
                </Col>
                <Col span={16}></Col>
                <Col span={4}>
                    <Button variant="contained" color="secondary" className={"schoolForm"} onClick={backToDataBrowser} style={{ marginRight: '8px', width: '100%', textTransform: 'inherit' }}>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Wrapper>
    );
};


//  -- Prop types ---------------------
SchoolForm.propTypes = {};

//  -- Default props ------------------
SchoolForm.defaultProps = {};

export default SchoolForm;
