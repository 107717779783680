
import React, { useEffect, useRef } from "react";
import { ApThemeDropDown } from "./ApThemeDropDown";
import IBThemeDropdown from "./IBThemeDropdown";
import AncotasDropDown from "./AnoctasDropDown";
import CategroyDropdown from "./CategroyDropdown";

const DropdownMenu = ({
  type,
  data,
  open,
  dropDownHandler,
  themeFilter,
  closeDropDown,
  handleDropdownClick,
  isResizingWindow,
  typeOf = ""
}) => {
  return (
    <>
      {(() => {
        switch (type) {
          case "category":
            return (
              <CategroyDropdown
                handleDropdownClick={handleDropdownClick}
                themeFilter={themeFilter}
                closeDropDown={closeDropDown}
                data={data}
                open={open}
                dropDownHandler={dropDownHandler}
              />
            );
          case "ANéCDOTAS THEMES":
            return (
              <AncotasDropDown
                anecotasThemeFilter={themeFilter}
                closeDropDown={closeDropDown}
                data={data}
                open={open}
                dropDownHandler={dropDownHandler}
                handleDropdownClick={handleDropdownClick}
                isResizingWindow={isResizingWindow}
                typeOf = {typeOf}
              />
            );
          case "AP THEMES":
            return (
              <ApThemeDropDown
                themeFilter={themeFilter}
                closeDropDown={closeDropDown}
                data={data}
                open={open}
                dropDownHandler={dropDownHandler}
                handleDropdownClick={handleDropdownClick}
              />
            );
            case "ibtheme":
              return (
                <IBThemeDropdown
                  themeFilter={themeFilter}
                  closeDropDown={closeDropDown}
                  data={data}
                  open={open}
                  dropDownHandler={dropDownHandler}
                  handleDropdownClick={handleDropdownClick}
                />
              );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default DropdownMenu;
