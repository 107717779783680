//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { MenuOutlined } from '@ant-design/icons';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//  -- Components ---------------------
import { Col, Dropdown, Menu, Row } from 'antd';
import { Auth } from "aws-amplify";
import CotationLoader from 'components/CotationLoader';
import LoadingWrapper from 'components/LoadingWrapper';
import SentryWrap from 'components/Sentry';
import queryString from 'query-string';
//  -- Features -----------------------
import Calendar from 'features/Calendar';
import StudentVisuals from 'features/StudentVisuals';
import _, { filter, find, get, has, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleListAllBooks } from 'store/books/thunks';
//  -- Thunks -------------------------
import { handleListClassActivities, handleListClassActivitiesForStudent } from 'store/classActivities/thunks';
import { handleListClassQuizzes, handleListClassQuizzesForStudent } from 'store/classQuizzes/thunks';
import { handleListUserSubmissions } from 'store/submissions/thunks';
import styled from 'styled-components';
import { mobileMenuimg } from "utils/assets/images";
//  -- Constants ----------------------
import { handleListActivities, handleListCustomAssignment } from 'store/activities/thunks';
import { handleListClassBookmarks } from 'store/bookmark/thunks';
import { handleListInstructionByClass } from "store/instructions/thunks";
import { useClassGameActivity } from 'store/one_game/selectors';
import { handleListQuizzes } from 'store/quizzes/thunks';
import COLOR from 'utils/constants/color';
import { getRandomCotation } from "utils/methods/math";
import ParentVisual from 'features/ParentVisual';
import ParentCalenar from 'features/ParentCalendar';
import ParentGradebook from './ParentGradebook';
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


const ButtonClear = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.black600};
    cursor: pointer;
    outline: none;
    padding: 0px;
`;

const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black200};
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 2px 0px;
    text-transform: uppercase;
`;

const CenterRow = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction; row;
    width: 100%;
`;

const Container = styled.div`
    padding: 24px 0px;
    padding-top:0px;
`;

const MobileMenu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;

    @media (min-width: 992px) {
        display: none;
    }
`;

const Section = styled.div`
    margin-bottom: 64px;
    width: 100%;

    &:first-child {
        margin-bottom: 24px;
    }
`;

const SectionHeader = styled.span`
    border-bottom: 1px dashed ${COLOR.black800};
    color: ${COLOR.black300};
    display: block;
    font-size: 11px;
    font-weight 600;
    margin-bottom: 16px;
    padding-bottom: 4px;
    text-transform: uppercase;
     visibility: hidden;
`;

const Title = styled.span`
    color: ${COLOR.black100};
    display: inline-block;
    font-size: 16px;
    font-weight: 300;

    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

const TitleContainer = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction; row;
`;



const WrapperHeader = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 6px;
`;

const VideoContainer = styled.div`
    height: auto;
    flex: 1;
    justify-content: center;
`;

const Group = styled.div`
    display: none;

    @media (min-width: 992px) {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
`;

const Mobicon = styled.img`
  height: 30px;
  width: 30px;
  align-self: center;
  object-fit: cover;
`;
const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 16px;
`;

const TabButton = styled.button`
    background-color: transparent;
    border: none;
    border-bottom: 2px solid ${p => p.active ? COLOR.green100 : 'transparent'};
    /* color: ${p => p.active ? COLOR.black100 : COLOR.black500}; */
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 16px 24px;
    text-transform: uppercase;
    transition: all 300ms ease;
    margin-right: 5px;

    &:hover {
        border-bottom: 2px solid ${COLOR.green700};
        color: white;
    }
`;

const HeaderContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black700};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 24px 0px 6px;
    position: relative;

    @media (min-width: 992px) {
        margin-bottom: 0px;
        padding: 24px 0px 0px;
    }
`;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));


const getTab = (key = "") => {
    switch (key) {
        case 'home':
            return key;
        case 'assessments':
            return key;
        case 'studentData':
            return key;
        default:
            return "home";
    }
}

const ParentDashboard = ({
    classActivities,
    classQuizzes,
    currentClass,
    currentUser,
    submissions,
    booksAllList,
    userBooksList,
    activities,
    quizzes
}) => {
    const { fetchClassGameActivity } = useClassGameActivity()
    const classes = useStyles();
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const dispatch = useDispatch();
    const [loading, setLoadingFlag] = useState(true);
    const [cotationLoader, setCotationLoader] = useState(true);
    const searchView = getTab(search.view);
    const cotationRef = useRef(null)
    if (!cotationRef.current) {
        cotationRef.current = getRandomCotation()
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (searchView == "home"||searchView == "studentData") {
            listCurrentClassInfo()
            dispatch(handleListClassActivities(currentClass?.id));
            dispatch(handleListClassQuizzes(currentClass?.id));
            fetchClassGameActivity();
            listAllAssignments()
        }
    }, [searchView])
    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);
    const listAllAssignments = async () => {
        if (!Array.isArray(activities) || activities.length == 0) {
           await  dispatch(handleListActivities());
        }
       
        if (!Array.isArray(quizzes) || quizzes.length == 0) {
          await   dispatch(handleListQuizzes());
        }
        await dispatch(handleListCustomAssignment('student',get(currentUser,"parent_of")))
    }

    const listCurrentClassInfo = async () => {
        if (has(currentClass, "id")) {
            dispatch(handleListClassActivitiesForStudent(get(currentClass, "id"),get(currentUser,"parent_of")));
            dispatch(handleListClassQuizzesForStudent(get(currentClass, "id"),get(currentUser,"parent_of")));
        }
    }
    const listInstructionByClass = async () => {
        if (has(currentClass, "id")) {
            dispatch(handleListInstructionByClass(get(currentClass, "id")));
        }
    }
    const getUserSubmissions = async () => {
        dispatch(handleListUserSubmissions(currentUser?.parent_of))
    }
    const listAllBooks = async () => {
        if (!booksAllList || booksAllList.length == 0) {
            dispatch(handleListAllBooks())
        }
    }

    const listClassBookmark = async () => {
        if (has(currentClass, "id")) {
            dispatch(handleListClassBookmarks(currentClass.id))
        }
    }
    const fetchAllData = async () => {
        setLoadingFlag(true);
        setCotationLoader(true)
        let currentClassId = localStorage.getItem("currentClassId")
        if ((currentClassId && (currentClassId ===currentClass?.id || currentClassId === "nocurrentuser"))) {
            setLoadingFlag(false)
            setCotationLoader(false)
        }
        await Promise.allSettled([
            listCurrentClassInfo(),
            listInstructionByClass(),
            getUserSubmissions(),
            listAllBooks(),
            listClassBookmark()
            
        ])
        localStorage.setItem("currentClassId", currentClass?.id ?? "nocurrentuser")
        setLoadingFlag(false);
        setCotationLoader(false)
    }

    useEffect(() => {
        fetchAllData();
        if(has(currentClass,"id")&&currentClass.archived){
            history.goBack();
        }
    }, [currentClass]);
    const allGraded = filter(submissions, x => x.graded);;
    const getAssignment = () => {
        const uniqueActivity = uniqBy(classActivities, "activityId");
        const uniqueQuiz = uniqBy(classQuizzes, 'quizId')
        return [...uniqueActivity, ...uniqueQuiz]
    }

    const toolbarNav = () => {
        return (
            <Menu>
                <Menu.Item>
                    <ButtonClear onClick={() => handleTabs('home')}>
                        Home
                    </ButtonClear>
                </Menu.Item>
                {/* <Menu.Item>
                    <ButtonClear onClick={() => handleTabs('assessments')}>
                        Calendar
                    </ButtonClear>
                </Menu.Item> */}
                <Menu.Item>
                    <ButtonClear onClick={() => handleTabs('studentData')}>
                        Student data
                    </ButtonClear>
                </Menu.Item>
            </Menu>
        )
    };
    const handleTabs = (key) => {
        switch (key) {
            case 'home':
                history.push('/parent/class?view=home');
                fetchAllData();
                break;
            // case 'assessments':
            //     history.push('/parent/class?view=assessments');
            //     fetchAllData();
            //     break;
            case 'studentData':
                history.push('/parent/class?view=studentData');
                break;
            default:
                break;
        }
    }
    const getBook = useCallback(() => {
        const ubook = _.get(currentClass, "books.length", 0) > 0 ? currentClass : userBooksList;
        if (_.has(ubook, "books")) {
            ubook.books = filter(ubook.books, k => {
                const bk = find(booksAllList, ['id', k.bookId])
                if (bk) {
                    return !bk.onlyTeacher
                } else {
                    return true;
                }
            })
            return ubook;
        } else {
            return ({ books: [] })
        }
    },[currentClass,userBooksList,booksAllList])
    return (
        <>
            <Modal
                className={classes.modal}
                open={cotationLoader}
            >
                <VideoContainer>
                    <CotationLoader cotation={cotationRef.current} />
                </VideoContainer>
            </Modal>
            <Row>
                <Col span={24}>
                    <HeaderContainer>
                        <TitleContainer>
                            <CenterRow>
                                <Mobicon src={mobileMenuimg} />
                                <Title>{currentClass.displayName}</Title>
                            </CenterRow>
                        </TitleContainer>
                        <Group>
                            <ButtonGroup>
                                <TabButton
                                    style={{ backgroundImage: getColor(searchView === 'home') }}
                                    active={searchView === 'home'} onClick={() => handleTabs("home")}>
                                    Home
                                </TabButton>
                                {/* <TabButton
                                    style={{ backgroundImage: getColor(searchView === 'assessments') }}
                                    active={searchView === 'assessments'} onClick={() => handleTabs('assessments')}>
                                    Calendar
                                </TabButton> */}
                                <TabButton
                                    style={{ backgroundImage: getColor(searchView === 'studentData') }}
                                    active={searchView === 'studentData'} onClick={() => handleTabs('studentData')}>
                                    Student data
                                </TabButton>
                            </ButtonGroup>
                        </Group>
                        <MobileMenu>
                            <Dropdown overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                                <ButtonLink onClick={e => e.preventDefault()}>
                                    <MenuOutlined style={{ fontSize: '18px' }} />
                                </ButtonLink>
                            </Dropdown>
                        </MobileMenu>
                    </HeaderContainer>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Container>
                        <Row className="tabCollection wrapperAdmnDash" style={{ marginTop:12}}>
                            <Col className="contentAdmnDash" xs={{ span: 24 }} sm={24} lg={{ span: 24 }}>
                            
                        {/* {searchView == 'home' && (
                            <>
                                <Section
                                    description="Below are the chapters for the book. Clicking on a chapter will display the book."
                                    header='Home'
                                >
                                    <LoadingWrapper loading={loading} hasCotationLoaded={true}>
                                      
                                    </LoadingWrapper>
                                </Section>
                            </>
                        )} */}
                        {searchView == 'home' && (
                            <>
                                <Section>
                                    <SectionHeader>My Calendar</SectionHeader>
                                    <LoadingWrapper loading={loading}>
                                        <SentryWrap>
                                            <ParentCalenar
                                                assignments={getAssignment()}
                                                submissions={submissions}
                                                booksAllList={booksAllList}
                                                currentUser={currentUser}
                                                userBooks={getBook().books}
                                             />
                                             <ParentGradebook/>
                                        </SentryWrap>
                                    </LoadingWrapper>
                                </Section>
                            </>
                        )}
                        {searchView == 'studentData' && (
                            <>
                                <Section>
                                    <SectionHeader>My Data</SectionHeader>
                                    <LoadingWrapper loading={loading}>
                                        <SentryWrap>
                                            <ParentVisual
                                                allGraded={allGraded}
                                                classActivities={classActivities}
                                                classQuizzes={classQuizzes}
                                                assignments={getAssignment()}
                                            />
                                        </SentryWrap>
                                    </LoadingWrapper>
                                </Section>
                            </>
                        )}
                        </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => ({
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    currentOrg: state.currentOrg,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
    userBooksList: state.userBooksList,
    selectedUser: state.selectedUser,
    schoologyAuth: state.schoologyAuth,
    userClasses: state.userClasses,
    activities: state.activities,
    quizzes: state.quizzes,
});

export default connect(mapStateToProps, null)(ParentDashboard);
