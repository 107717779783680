//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------


export const handleListHowToVideos = () => async (dispatch) => {
    try {
        const exams = await API.get('howToVideos', `/list`);
        if (Array.isArray(exams)) {
            await dispatch(Actions.listHowToVideosSuccess(exams));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleCreateHowToVideos = (data) => async (dispatch) => {
    try {
        const videos = await API.post('howToVideos', `/create`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.createHowToVideosSuccess(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleUpdateHowToVideos = (data) => async (dispatch) => {
    try {
        const videos = await API.put('howToVideos', `/update`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.updateHowToVideosSuccess(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error,"error")
    }
};
export const handleDeleteHowToVideos = (id) => async (dispatch) => {
    try {
        const videos = await API.del('howToVideos', `/delete/${id}`);
        if (has(videos, "id")) {
            await dispatch(Actions.deleteHowToVideosSuccess(id));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};