import React, { Component } from 'react';
import Link from '@material-ui/core/Link';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { locationResource } from 'utils/assets/images';
import { map } from "lodash"

const Index = ({ Items, onClick }) => {
    const history = useHistory();
    return (
        <div>
            <div className="learnWay">
                <img src={locationResource} />
                <Link>How to Videos with one way</Link>
            </div>
            {/* <div className="listSrch"><Link><Button>search</Button></Link></div> */}
            <div className="learnListingUpr learnListingUprBlog">
                <ul className="learnListing">
                    {map(Items, itm => (
                        <li><Link onClick={() => onClick(itm)}>{itm.title}</Link></li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
//  -- Prop types ---------------------
Index.propTypes = {};

//  -- Default props ------------------
Index.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({});
export default connect(mapStateToProps, null)(Index);
