//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import { ArrowRight, Close, Search, Visibility , ArrowForward, Settings , BookOutlined } from '@material-ui/icons';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
//  -- Constants ----------------------
import Typography from '@material-ui/core/Typography';
import base64 from "base-64";
import COLOR from 'utils/constants/color';
//  -- Components ---------------------
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
//  -- Thunks -------------------------
import { get, isEmpty, map } from 'lodash';
import { lighten } from 'polished';
import { handleUserByCanvasName  , handleGetUserInfoById } from 'store/canvas/thunks';
import { createKeystore } from "store/keystore/thunks";
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;

const WrapperForm = styled.form`
	padding: 40px 0px;
	width: 100%;
`;
const Error = styled.div`
    background-color: ${lighten(0.4, 'red')};
    border: 1px solid red;
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin: 0px auto 16px;
    max-width: 480px;
    padding: 8px;
    text-align: center;
    width: 100%;
`;
const Label = styled.div`
   font-size:14px;
   color:${COLOR.white};
`;
const LabelTop = styled.div`
   font-size:18px;
   color:${COLOR.white};
`;
const ItemsTabsList = styled.div`
    display: inline-block;
    flex-direction: column;
    min-height: 100px;
    border-radius: 12px;
    min-width: 200px;
    background-color: ${COLOR.indigodeep};
    width: 90%;
    padding: 16px;
    margin-top: 10px;
    min-height: 220px;
`;
const ClassUsersWrapper = styled.div`
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    margin-bottom: 8px;
    width:102%;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:90vh;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalSection = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:100%;
    overflow:auto;
    text-align: center;
`;


const AccordianTitle = styled(Typography)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
`
const Accordion = withStyles({
    root: {
        border: '1px solid white',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#03989e',
        color: 'white',
        textTransform: 'capitalize',
        borderBottom: '1px solid white',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiAccordionDetails);
const Rotate = styled.div`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;
const ContentArea = styled.div`
width: 90%;
margin-top: 28px;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
const UserInfoAres = ({ data }) => {
    const { school, district } = data;
    const [loader,setLoader] = useState(false);
    const openLoginInNewWindow = async () => {
        setLoader(true);
        const key = v4()
        await createKeystore(get(data, "user.id", ''), key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoader(false);
      }
    return (<Row style={{ width: "100%" }}>
        <Col span={12}>
            {!isEmpty(data.user) && <ItemsTabsList>
                <LabelTop>User on OWE Platform </LabelTop>
                <Label>Id: {get(data, "user.id", '')}  </Label>
                <Label >Create at: {get(data, "user.createdAt", null) ? moment(parseInt(get(data, "user.createdAt", ''))).format("MM/DD/YYYY") : null}</Label>
                <Label>First Name: {get(data, "user.firstName", '')}</Label>
                <Label >Last name: {get(data, "user.lastName", '')}</Label>
                <Label >Role: {get(data, "user.role", '')}</Label>
                <Label >User Name: {get(data, "user.username", '')}</Label>
                <Label >Password: {get(data, "user.password") ? base64.decode(get(data, "user.password", '')) : null}</Label>
                <Button color="primary" variant="contained" onClick={openLoginInNewWindow}>
                    {loader && <Loading size={14} white />}
                    Login to account
                    <ArrowForward
                        style={{
                            fontSize: "11px",
                            marginLeft: "8px",
                        }}
                    />
                </Button>
            </ItemsTabsList>}
        </Col>
        <Col span={12}>
            {!isEmpty(school) && <ItemsTabsList>
                <LabelTop>School </LabelTop>
                <Label>display name: {get(school, "displayName", '')}  </Label>
                <LabelTop>district </LabelTop>
                <Label>display name: {get(district, "displayName", '')}  </Label>
            </ItemsTabsList>}

        </Col>

    </Row>)
}
const ClassInfoArea = ({ handleChange, data, classEpendOwe,canvasInfo }) => {
    const { classes } = data;
    return (<Row style={{width:"100%"}}>
    <Col span={12} style={{display:"flex",justifyContent:"center"}}>
            <ContentArea>
                {map(classes, cls => {
                    return (<Accordion square key={cls.canvas_id} expanded={classEpendOwe == cls.canvas_id} onChange={handleChange(cls.canvas_id)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle><span>{cls.displayName}</span> <Rotate open={classEpendOwe == cls.canvas_id}><ArrowRight /></Rotate></AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ClassUsersWrapper>
                                <TableContainer>
                                    <Table style={{ width: "100%", textAlign: "center" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>Id</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Username</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>First name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Last Name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>canvas id</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Password</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(cls.classUsers, cuser => {
                                                return (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "id", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "username", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "firstName", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "lastName", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "canvas_id", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "password", null) ? base64.decode(get(cuser, "password")) : null}</TableCell>
                                                    </TableRow>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ClassUsersWrapper>
                        </AccordionDetails>
                    </Accordion>
                    )
                })}
            </ContentArea>
        </Col>
        <Col span={12} style={{display:"flex",justifyContent:"center"}}>
            <ContentArea>
                {map(canvasInfo.classes, cls => {
                    return (<Accordion square expanded={classEpendOwe == cls.id} key={cls.id+"u"} onChange={handleChange(cls.id)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle><span>Section: {cls.name} (${get(cls,"course_info.name","")})</span> <Rotate open={classEpendOwe == cls.id}><ArrowRight /></Rotate></AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ClassUsersWrapper>
                                <TableContainer>
                                    <Table style={{ width: "100%", textAlign: "center" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>id</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Short name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Login id</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(cls.student, cuser => {
                                                return (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "user_id", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "user.name", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "user.short_name", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "user.login_id", '')}</TableCell>
                                                    </TableRow>

                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ClassUsersWrapper>


                        </AccordionDetails>
                    </Accordion>
                    )
                })}
            </ContentArea>
        </Col>
    </Row>)
}

const CanvasUserViewContiner = ({
}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ users: null, classes: [] });
    const [classEpendOwe, setClassExpendOwe] = useState(-1)
    const [canvasUserName, setCleverUsername] = useState('')
    const [userList, setUserList] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [userInfoLoader, setUserInfoLoader] = useState(-1)
    const [selectedUserCanvasInfo, setSelectedUserCanvasInfo] = useState({
        users: null, classes: []
    })
    const history = useHistory()
    const handleChange = (panel) => (event, newExpanded) => {
        setClassExpendOwe(newExpanded ? panel : -1);
    };

    const fetchCleverUserByName = async () => {
        if (canvasUserName) {
            setLoading(true)
            const users = await handleUserByCanvasName(canvasUserName.trim())
            setUserList(users)
            setLoading(false)
        }

    }
    const fetchApis = async (id,auth_id) => {
        setUserInfoLoader(id)       
        setSelectedUserCanvasInfo({ users: null, classes: []})
        const userData = await handleGetUserInfoById(id,auth_id)
        if(userData&&userData.user){
            setSelectedUserCanvasInfo(userData)
        }
        setUserInfoLoader(null)
        setOpenModal(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        fetchCleverUserByName()

    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={e => setOpenModal(false)}
                style={{ height: "100%", width: "100%" }}
            >
                <ModalContent>
                    <Row style={{ justifyContent: "flex-end", width: "100%" }}>
                        <IconButton
                            onClick={e => setOpenModal(false)}
                            style={{ padding: 0, marginRight: 10 }}
                        >
                            <Close color="secondary" />
                        </IconButton>
                    </Row>
                    <ModalSection>
                        <UserInfoAres data={data} />
                        <ClassInfoArea data={data} classEpendOwe={classEpendOwe} handleChange={handleChange} canvasInfo={selectedUserCanvasInfo} />
                    </ModalSection>

                </ModalContent>
            </Modal>
            <WrapperForm onSubmit={handleSubmit}>
                <Row gutter={[24, 24]}>
                <Col>
                    <Button variant="contained" startIcon={<BookOutlined style={{color:"#fff" , fontSize:14}}/>} color="primary" style={{ height: 40 }} onClick={e => {
                            e.preventDefault();
                            history.push("/book-maps?course=canvas")
                        }}>
                            Book mapping
                        </Button>
                    <Button variant="contained" color="primary" startIcon={<Settings style={{color:"#fff" , fontSize:14}}/>} style={{ height: 40 , marginLeft:8 }} onClick={e => {
                            e.preventDefault();
                            history.push("/keys?course=canvas")
                        }}>
                           Manage keys
                        </Button>
                    </Col>
                    <Col size={5}>
                        <TextField
                            id="name"
                            name="name"
                            label="First name or last name"
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            variant="outlined"
                            onChange={e => {
                                setCleverUsername(e.target.value)
                            }}
                            value={canvasUserName}
                        />
                    </Col>

                    <Col>
                        <Button disabled={loading} variant="contained" color="primary" type="submit">
                            {(loading) ? <Loading size={20} white /> : 'Search'}
                            <Search style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                        </Button>
                    </Col>
                </Row>
                {/* {errorMessage ? <Error>{errorMessage}</Error> : null} */}
            </WrapperForm>
            <Wrapper>
                <LoadingWrapper loading={false}>
                    {userList.length > 0 ? <Table style={{ width: "100%", textAlign: "center" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: "center" }}>Username</TableCell>
                                <TableCell style={{ textAlign: "center" }}>First name</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Last Name</TableCell>
                                <TableCell style={{ textAlign: "center" }}>role</TableCell>
                                <TableCell style={{ textAlign: "center" }}>canvas id</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Password</TableCell>
                                <TableCell style={{ textAlign: "center" }}>view</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {map(userList, u => {
                                const cuser = u.user;
                                return (<>
                                    <TableRow key={cuser.id}>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "username", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "firstName", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "lastName", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "role", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "canvas_id", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "password", null) ? base64.decode(get(cuser, "password")) : null}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}><IconButton onClick={() => {
                                            fetchApis(cuser.canvas_id,cuser.canvas_auth_id)
                                            setData(u)
                                        }}> {userInfoLoader ===cuser?.canvas_id?<Loading size={14}/>:<Visibility style={{ color: COLOR.green }} />}</IconButton></TableCell>
                                    </TableRow>
                                </>)
                            })}
                        </TableBody>
                    </Table> : null}
                </LoadingWrapper>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
CanvasUserViewContiner.propTypes = {};

//  -- Default props ------------------
CanvasUserViewContiner.defaultProps = {
    logs: []
};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    logs: state.logs,
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(CanvasUserViewContiner);
