//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create like --------------------
export const createLike = () => ({ type: ActionTypes.likes.createLike });
export const createLikeFail = (returnedPayload) => ({ type: ActionTypes.likes.createLikeFail, payload: returnedPayload });
export const createLikeSuccess = (returnedPayload) => ({ type: ActionTypes.likes.createLikeSuccess, payload: returnedPayload });

//  -- Delete like --------------------
export const deleteLike = () => ({ type: ActionTypes.likes.deleteLike });
export const deleteLikeFail = (returnedPayload) => ({ type: ActionTypes.likes.deleteLikeFail, payload: returnedPayload });
export const deleteLikeSuccess = (returnedPayload) => ({ type: ActionTypes.likes.deleteLikeSuccess, payload: returnedPayload });

//  -- List likes ---------------------
export const listLikes = () => ({ type: ActionTypes.likes.listLikes });
export const listLikesFail = (returnedPayload) => ({ type: ActionTypes.likes.listLikesFail, payload: returnedPayload });
export const listLikesSuccess = (returnedPayload) => ({ type: ActionTypes.likes.listLikesSuccess, payload: returnedPayload });

//  -- List chapter likes -------------
export const listChapterLikes = () => ({ type: ActionTypes.likes.listChapterLikes });
export const listChapterLikesFail = (returnedPayload) => ({ type: ActionTypes.likes.listChapterLikesFail, payload: returnedPayload });
export const listChapterLikesSuccess = (returnedPayload) => ({ type: ActionTypes.likes.listChapterLikesSuccess, payload: returnedPayload });

//  -- List class likes ---------------
export const listClassLikes = () => ({ type: ActionTypes.likes.listClassLikes });
export const listClassLikesFail = (returnedPayload) => ({ type: ActionTypes.likes.listClassLikesFail, payload: returnedPayload });
export const listClassLikesSuccess = (returnedPayload) => ({ type: ActionTypes.likes.listClassLikesSuccess, payload: returnedPayload });

//  -- List organization likes --------
export const listOrgLikes = () => ({ type: ActionTypes.likes.listOrgLikes });
export const listOrgLikesFail = (returnedPayload) => ({ type: ActionTypes.likes.listOrgLikesFail, payload: returnedPayload });
export const listOrgLikesSuccess = (returnedPayload) => ({ type: ActionTypes.likes.listOrgLikesSuccess, payload: returnedPayload });

//  -- List user likes ----------------
export const listUserLikes = () => ({ type: ActionTypes.likes.listUserLikes });
export const listUserLikesFail = (returnedPayload) => ({ type: ActionTypes.likes.listUserLikesFail, payload: returnedPayload });
export const listUserLikesSuccess = (returnedPayload) => ({ type: ActionTypes.likes.listUserLikesSuccess, payload: returnedPayload });
