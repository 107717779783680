//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { createGlobalStyle } from 'styled-components';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
//
//  Global styles to be applied across the app
//

//  -- Global reset -------------------
export default createGlobalStyle`
   @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        background-color: #fcfcfc;
        font-family: sans-serif;
        font-smoothing: antialiased;
        overflow-y:scroll;
    }
    .highcharts-credits{
        display:none;
    }
`;
