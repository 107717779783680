//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create user --------------------
export const createUser = () => ({ type: ActionTypes.users.createUser });
export const createUserFail = (returnedPayload) => ({ type: ActionTypes.users.createUserFail, payload: returnedPayload });
export const createUserSuccess = (returnedPayload) => ({ type: ActionTypes.users.createUserSuccess, payload: returnedPayload });

//  -- Delete user --------------------
export const deleteUser = () => ({ type: ActionTypes.users.deleteUser });
export const deleteUserFail = (returnedPayload) => ({ type: ActionTypes.users.deleteUserFail, payload: returnedPayload });
export const deleteUserSuccess = (returnedPayload) => ({ type: ActionTypes.users.deleteUserSuccess, payload: returnedPayload });

//  -- Get user -----------------------
export const getUser = () => ({ type: ActionTypes.users.getUser });
export const getUserFail = (returnedPayload) => ({ type: ActionTypes.users.getUserFail, payload: returnedPayload });
export const getUserSuccess = (returnedPayload) => ({ type: ActionTypes.users.getUserSuccess, payload: returnedPayload });

//  -- List users ---------------------
export const listUsers = () => ({ type: ActionTypes.users.listUsers });
export const listUsersFail = (returnedPayload) => ({ type: ActionTypes.users.listUsersFail, payload: returnedPayload });
export const listUsersSuccess = (returnedPayload) => ({ type: ActionTypes.users.listUsersSuccess, payload: returnedPayload });

//  -- List All users ---------------------
export const listAllUsers = () => ({ type: ActionTypes.users.listAllUsers });
export const listAllUsersFail = (returnedPayload) => ({ type: ActionTypes.users.listAllUsersFail, payload: returnedPayload });
export const listAllUsersSuccess = (returnedPayload) => ({ type: ActionTypes.users.listAllUsersSuccess, payload: returnedPayload });

//  -- List All users Student ---------------------
export const listAllStudentUsers = () => ({ type: ActionTypes.users.listAllStudentUsers });
export const listAllStudentUsersFail = (returnedPayload) => ({ type: ActionTypes.users.listAllStudentUsersFail, payload: returnedPayload });
export const listAllStudentUsersSuccess = (returnedPayload) => ({ type: ActionTypes.users.listAllStudentUsersSuccess, payload: returnedPayload });

//  -- List All users Teacher ---------------------
export const listAllTeacherUsers = () => ({ type: ActionTypes.users.listAllTeacherUsers });
export const listAllTeacherUsersFail = (returnedPayload) => ({ type: ActionTypes.users.listAllTeacherUsersFail, payload: returnedPayload });
export const listAllTeacherUsersSuccess = (returnedPayload) => ({ type: ActionTypes.users.listAllTeacherUsersSuccess, payload: returnedPayload });

// list of all the student
export const listAllStudentsUsers = () => ({
  type: ActionTypes.users.listAllStudentsUsers,
});
export const listAllStudentsUsersFail = (returnedPayload) => ({
  type: ActionTypes.users.listAllStudentsUsersFail,
  payload: returnedPayload,
});
export const listAllStudentsUsersSuccess = (returnedPayload) => ({
  type: ActionTypes.users.listAllStudentsUsersSuccess,
  payload: returnedPayload,
});

//  -- List organization users --------
export const listOrgUsers = () => ({ type: ActionTypes.users.listOrgUsers });
export const listOrgUsersFail = (returnedPayload) => ({ type: ActionTypes.users.listOrgUsersFail, payload: returnedPayload });
export const listOrgUsersSuccess = (returnedPayload) => ({ type: ActionTypes.users.listOrgUsersSuccess, payload: returnedPayload });

//  -- Register student ---------------
export const registerStudent = () => ({ type: ActionTypes.users.registerStudent });
export const registerStudentFail = (returnedPayload) => ({ type: ActionTypes.users.registerStudentFail, payload: returnedPayload });
export const registerStudentSuccess = (returnedPayload) => ({ type: ActionTypes.users.registerStudentSuccess, payload: returnedPayload });

//  -- Update user --------------------
export const updateUser = () => ({ type: ActionTypes.users.updateUser });
export const updateUserFail = (returnedPayload) => ({ type: ActionTypes.users.updateUserFail, payload: returnedPayload });
export const updateUserSuccess = (returnedPayload) => ({ type: ActionTypes.users.updateUserSuccess, payload: returnedPayload });

//  -- Update New User --------------------
export const updateNewUser = () => ({ type: ActionTypes.users.updateNewUser });
export const updateNewUserFail = (returnedPayload) => ({ type: ActionTypes.users.updateNewUserFail, payload: returnedPayload });
export const updateNewUserSuccess = (returnedPayload) => ({ type: ActionTypes.users.updateNewUserSuccess, payload: returnedPayload });

//  -- Change user password --------------------
export const userPasswordChange = () => ({ type: ActionTypes.users.changeUserPassword });
export const userPasswordChangeSuccess = (returnedPayload) => ({ type: ActionTypes.users.changeUserPasswordSuccess, payload: returnedPayload });
export const userPasswordChangeFail = (returnedPayload) => ({ type: ActionTypes.users.changeUserPasswordFail, payload: returnedPayload });

//  -- Enable user Status --------------------
export const userEnable = () => ({ type: ActionTypes.users.userEnable });
export const userEnableSuccess = (returnedPayload) => ({ type: ActionTypes.users.userEnableSuccess, payload: returnedPayload });
export const userEnableFail = (returnedPayload) => ({ type: ActionTypes.users.userEnableFail, payload: returnedPayload });


export const setUserProfileConfig = (payload) => ({ type: ActionTypes.users.set_profile_config , payload: payload });


export const setShowProfilePickViewer = () => ({ type: ActionTypes.users.show_profile_pic_viewer, payload: true });
export const setHideProfilePickViewer = () => ({ type: ActionTypes.users.show_profile_pic_viewer, payload: false });

export const list_all_district_teacher = (returnedPayload) => ({ type: ActionTypes.users.list_all_district_teacher, payload: returnedPayload })