//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const init = {
    view: false
};


export const debateModal = (state = init, action) => {
    switch (action.type) {
        case ActionTypes.debate.invokeDebateModal:
            return ({ ...state, view: true});
        case ActionTypes.debate.revokeDebateModal:
            return ({ ...state, view: false});
        default:
            return state;
    }
};


