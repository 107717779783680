//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const ebook_highlight = [];

export const ebook_highlights = (state = ebook_highlight, action) => {
    switch (action.type) {
        case ActionTypes.ebook_highlight.set_ebook_highlight:
            return action.payload;
        case ActionTypes.ebook_highlight.update_ebook_highlight:
            const n_state = cloneDeep(state);
            if (n_state.find(k => k.id === action.payload.id)) {
                return n_state.map(k => k.id === action.payload.id ? action.payload : k);
            } else {
                return [...n_state, action.payload];
            }
        case ActionTypes.ebook_highlight.clear_ebook_highlight:
            return [];
        default:
            return state;
    }
};
