//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selectore = (state) => state.ebook_highlights;
//  -- Examples array selector --------

export const useEbookHighlightes = () => {
    const selectedData = useSelector(selectore, shallowEqual)
   return selectedData;
}
