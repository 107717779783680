//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';

import * as Sentry from "@sentry/react";
import { has } from 'lodash'
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------


//  -- List books ----------------
export const handleListBookUnits = () => async (dispatch) => {
    await dispatch(ActivityActions.listBookUnits());
    try {
        let bookUnits = [];
        const booksUnitRDS = await API.get('bookUnits', `/bookUnitsList`);
        if (Array.isArray(booksUnitRDS) && booksUnitRDS.length > 0) {
            bookUnits = booksUnitRDS
        }
        await dispatch(ActivityActions.listBookUnitsSuccess(bookUnits));
        return bookUnits;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.listBookUnitsFail(error));
    }
};

export const handleUpdateUnits = (id, unit) => async (dispatch) => {
    await dispatch(ActivityActions.updateBookUnits());
    try {
        const booksUnitRDS = await API.put('bookUnits', `/update/${id}`, {
            body: unit
        });
        if (has(booksUnitRDS, "id")) {
            await dispatch(ActivityActions.updateBookUnitsSuccess(booksUnitRDS));
        }

        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.updateBookUnitsFail(error));
    }


}

// schoology course map
export const handleListSchoologyCources = () => async (dispatch) => {
    try {
        const booksUnitRDS = await API.get('bookUnits', `/list_schoology_bookmap`);
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
export const handleUpdateSchoologyCources = (data) => async (dispatch) => {
    try {
        const booksUnitRDS = await API.put('bookUnits', `/update_schoology_coursemap`, {
            body: data
        });
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
//canvas course map
export const handleListCanvasCourse = () => async (dispatch) => {
    try {
        const booksUnitRDS = await API.get('bookUnits', `/list_canvas_bookmap`);
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
export const handleUpdateCanvasCources = (data) => async (dispatch) => {
    try {
        const booksUnitRDS = await API.put('bookUnits', `/update_canvas_coursemap`, {
            body: data
        });
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
//clever course map
export const handleListCleverCourse = () => async (dispatch) => {
    try {
        const booksUnitRDS = await API.get('bookUnits', `/list_clever_bookmap`);
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
export const handleUpdateCleverCources = (data) => async (dispatch) => {
    try {
        const booksUnitRDS = await API.put('bookUnits', `/update_clever_coursemap`, {
            body: data
        });
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
// google course map
export const handleListGoogleCourse = () => async (dispatch) => {
    try {
        const booksUnitRDS = await API.get('bookUnits', `/list_google_bookmap`);
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
export const handleUpdateGoogleCources = (data) => async (dispatch) => {
    try {
        const booksUnitRDS = await API.put('bookUnits', `/update_google_coursemap`, {
            body: data
        });
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
// class link course map
export const handleListClasslinkCourse = () => async (dispatch) => {
    try {
        const booksUnitRDS = await API.get('bookUnits', `/list_classlink_bookmap`);
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}
export const handleUpdateClasslinkCources = (data) => async (dispatch) => {
    try {
        const booksUnitRDS = await API.put('bookUnits', `/update_classlink_coursemap`, {
            body: data
        });
        return booksUnitRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
}

