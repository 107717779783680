//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import SentryWrap from "components/Sentry";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { Modal } from "@material-ui/core";
// import Table from "components/Table";
import { filter, find, groupBy } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";

//  -- Thunks -------------------------
import CotationLoader from 'components/CotationLoader';
import ClassesTable from "features/forms/admin/ClassesTable";
import DistrictsAdminTable from "features/forms/admin/DistrictsAdminTable";
import DistrictsTable from "features/forms/admin/DistrictsTable";
import SchoolTable from "features/forms/admin/SchoolTable";
import StudentsTable from "features/forms/admin/StudentsTable";
import TeacherTable from "features/forms/admin/TeacherTable";
import { isEmpty } from 'lodash';
import { handleGetOrgClasses } from "store/classes/thunks";
import { handleListLicences, handleUpdateDistLicence } from "store/licences/thunks";
import { handleGetDistrictsWithCount, handleGetOrganization, handleListGetSchool, handleListSchools } from "store/organizations/thunks";
import { handleListDistStudent, handleListDistUsers } from "store/users/thunks";
import { useCurrentOrg } from "store/organizations/selectors";
import { Spin  } from "antd"
import ParentTable from "features/forms/admin/ParentTable";

const BORDERCOLOR = [
  "rgb(200,109,152)",
  "rgb(146,158,227)",
  "rgb(80,233,200)",
  "rgb(238,206,151)",
];
const MENU = [
  { id: "see_districts", value: "Districts" },
  { id: "see_schools", value: "Schools" },
  { id: "see_teachers", value: "Teachers" },
  { id: "see_students", value: "Students" },
  { id: "parent", value: "Parents" },
  { id: "district_admins", value: "District Admins" },
  { id: "see_classes", value: "Classes" }
];
const BOOKLIST = ["32", "7", "8", "1"];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0px;
  width: 100%;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  // border-radius: 12px;
  // background-color: ${COLOR.indigodeep};
  width: 100%;
  // padding: 16px;
  margin-top: 32px;
`;
const SectionTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ViewBtn = styled.button`
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  color: ${COLOR.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  border-radius: 7px;
  padding: 2px 16px;
  margin-right: 2px;
  margin-left: 2px;
  min-width: 180px;
  height: 30px;
  margin-left: 68.5%;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;

const VideoContainer = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------

const getBookList = (allBook) => {
  return BOOKLIST.map((e) => find(allBook, { id: e })).filter((e) => e);
};

const SectionsView = ({
  label,
  children,
  currentView,
  id,
  backButton = () => { },
  onCloseView = () => { },
  onClose = () => { },
  hasBoxButton,
}) => {
  return (
    <SectionWrapper>
      <SectionTopRow>
        {currentView == "boxes" && id != "" && hasBoxButton && (
          <>
            <ViewBtn onClick={backButton}>BACK TO BOXES</ViewBtn>
          </>
        )}
        {id == "" && hasBoxButton && (
          <>
            <ViewBtn onClick={onCloseView}>
              {currentView == "list" ? "VIEW BOXES" : "VIEW LIST"}
            </ViewBtn>
          </>
        )}
      </SectionTopRow>
      {children}
    </SectionWrapper>
  );
};


const NewDashboardsContainer = ({
  // currentOrg,
  districts,
  schools,
  users,
  booksAllList,
  allUser,
  classes,
  allSchools,
  distStudents
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentOrg = useCurrentOrg();
  const LICENCEDBOOOK = getBookList(booksAllList);
  const [dataTab, setDataTab] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userloading, setUserLoading] = useState(true);
  const [currentView, setCurrentView] = useState("boxes");
  const [idSchoolBox, setIdSchoolBox] = useState("");
  const [dataloader, setDataLoader] = useState(false);
  const [selectedDist, setSelectedDist] = useState()
  const cotationRef = useRef(null)
  if (!cotationRef.current) {
    cotationRef.current = getRandomCotation()
  }
  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
    setDataTab("see_districts");
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch(handleListSchools());
      dispatch(handleGetDistrictsWithCount());
      // dispatch(handleListDistUsers(currentOrg.id));
      if(currentOrg){
        dispatch(handleListGetSchool(currentOrg.id));
      }
      // dispatch(handleListDistStudent(currentOrg.id));
      // dispatch(handleGetAllUser())
      // dispatch(handleProcessClassTenant())
      // await dispatch(handleListAllBooks());
      // dispatch(handleGetCoursesForClever('1'))
      // fetchDistrictData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    fetchDistrictData();
  }, [currentOrg]);

  const fetchDistrictData = async () => {
    try {
      setUserLoading(true);
      setDataLoader(localStorage.getItem('last_org_loaded')!==currentOrg.id)
      if (!isEmpty(currentOrg)) {
       await  Promise.allSettled([
          dispatch(handleGetOrgClasses([currentOrg, ...schools])),
          dispatch(handleListDistStudent(currentOrg.id))
        ])
      localStorage.setItem('last_org_loaded', currentOrg.id);
      }
      setUserLoading(false);
    } catch (error) {
      setUserLoading(false);
    } finally{
      setDataLoader(false)
    }
  };

  let usersStudents = filter(users, (u) => u.role === "student");
  const usersTeachers = filter(users, (u) => u.role === "teacher");
  const teacherByOrg = groupBy(usersTeachers, (u) => u.orgId);
  const studentsByOrg = groupBy(usersStudents, (u) => u.orgId);

  // Array Books
  let listBooks = [];

  for (var book in booksAllList) {
    let isNotAssignable = booksAllList[book].isNotAssignable;
    let _data = {
      id: booksAllList[book].id,
      description: booksAllList[book].description,
      count: booksAllList[book].count,
    };
    if (!isNotAssignable) {
      listBooks.push(_data);
    }
  }

  function navigationToMenu(id) {
    setDataTab(id);
    if (id == 'see_districts') {
      setSelectedDist('')
    }
  }
  return (
    <SentryWrap>
      <Modal
        open={dataloader}
      >
        <VideoContainer>
          <Spin size="large" />
        </VideoContainer>
      </Modal>
      <Wrapper className={"adminForm"}>
        <MenuWrapper top={"10px"} style={{ width: "100%" }}>
          {MENU.map((x) => (
            <MenuBtn style={{ borderBottom: dataTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenu(x.id)}>
              {x.value}
            </MenuBtn>
          ))}
        </MenuWrapper>
        {dataTab == MENU[0].id && (
          <>
            <SectionsView
              label={"Districts"}
              currentView={currentView}
              id={"districtBox"}
              backButton={() => { }}
              onCloseView={() => { }}
              onClose={() => {
                setDataTab("")
              }}
            >
              <DistrictsTable
                setDataTab
                schools={schools}
                districts={districts}
                allSchools={allSchools}
                userloading={userloading}
                onUpdateOrAdd={() => {
                  fetchDistrictData();
                  // dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />
            </SectionsView>
          </>
        )}
        {dataTab == MENU[1].id && (
          <>
            <SectionsView
              label={"Schools"}
              currentView={currentView}
              id={idSchoolBox}
              backButton={() => { }}
              onCloseView={() => {
                setCurrentView(currentView == "list" ? "boxes" : "list");
              }}
              onClose={() => { }}
            >
              <SchoolTable
                districts={districts}
                schools={schools}
                selectedDist={selectedDist}
                userloading={userloading}
                studentsByOrg={studentsByOrg}
                teacherByOrg={teacherByOrg}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                  // dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />

            </SectionsView>
          </>
        )}
        {dataTab == MENU[2].id && (
          <>
            <SectionsView
              label={"Teachers"}
              currentView={currentView}
              id={idSchoolBox}
              backButton={() => { }}
              onCloseView={() => {
                setCurrentView(currentView == "list" ? "boxes" : "list");
              }}
              onClose={() => { }}
            >
              <TeacherTable
                users={users}
                allUser={allUser}
                schools={schools}
                classes={classes}
                selectedDist={selectedDist}
                userloading={userloading}
                studentsByOrg={studentsByOrg}
                teacherByOrg={teacherByOrg}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                  // dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />

            </SectionsView>
          </>
        )}
        {dataTab == MENU[3].id && (
          <>
            <SectionsView
              label={"Students"}
              currentView={currentView}
              id={idSchoolBox}
              backButton={() => { }}
              onCloseView={() => {
                setCurrentView(currentView == "list" ? "boxes" : "list");
              }}
              onClose={() => { }}
            >
              <StudentsTable
                users={users}
                schools={schools}
                classes={classes}
                allUser={allUser}
                userloading={userloading}
                selectedDist={selectedDist}
                teacherByOrg={teacherByOrg}
                distStudents={distStudents}
                studentsByOrg={studentsByOrg}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                  // dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />
            </SectionsView>
          </>
        )}
        {dataTab == MENU[4].id && (
          <>
            <SectionsView
              label={"Students"}
              currentView={currentView}
              id={idSchoolBox}
              backButton={() => { }}
              onCloseView={() => {
                setCurrentView(currentView == "list" ? "boxes" : "list");
              }}
              onClose={() => { }}
            >
              <ParentTable 
                users={users}
                schools={schools}
                classes={classes}
                allUser={allUser}
                userloading={userloading}
                selectedDist={selectedDist}
                teacherByOrg={teacherByOrg}
                distStudents={distStudents}
                studentsByOrg={studentsByOrg}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                  // dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />
            </SectionsView>
          </>
        )}

        {dataTab == MENU[5].id && (
          <>
            <SectionsView
              label={"District Admins"}
              currentView={currentView}
              id={"userbox"}
              backButton={() => { }}
              onCloseView={() => { }}
              onClose={() => {
                setDataTab("");
              }}
            >
              <DistrictsAdminTable
                users={users}
                allUser={allUser}
                userloading={userloading}
                teacherByOrg={teacherByOrg}
                selectedDist={selectedDist}
                schools={[...schools, currentOrg]}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                }}
                listBooks={listBooks}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />
            </SectionsView>
          </>
        )}
        {dataTab == MENU[6].id && (
          <>
            <SectionsView
              label={"Classes"}
              currentView={currentView}
              id={"userbox"}
              backButton={() => { }}
              onCloseView={() => { }}
              onClose={() => {
                setDataTab("");
              }}
            >
              <ClassesTable
                users={users}
                allUser={allUser}
                classes={classes}
                userloading={userloading}
                teacherByOrg={teacherByOrg}
                selectedDist={selectedDist}
                schools={[...schools, currentOrg]}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
                listBooks={listBooks}
              />
            </SectionsView>
          </>
        )}
        {/* {dataTab == MENU[6].id && (
                    <>
                        <SectionsView
                            label={"Users"}
                            currentView={currentView}
                            id={"userbox"}
                            backButton={() => { }}
                            onCloseView={() => { }}
                            onClose={() => {
                                setDataTab("");
                            }}
                        >
                            <UserTable
                                userloading={userloading}
                                users={users}
                                teacherByOrg={teacherByOrg}
                                schools={[...schools, currentOrg]}
                                onUserAddOrUpdate={() => {
                                    fetchDistrictData();
                                }}
                                listBooks={listBooks}
                            />
                        </SectionsView>
                    </>
                )}
              
                {dataTab == MENU[7].id && (
                    <>
                        <SectionsView
                            label={"Users"}
                            currentView={currentView}
                            id={"userbox"}
                            backButton={() => { }}
                            onCloseView={() => { }}
                            onClose={() => {
                                setDataTab("");
                                setSearchText("");
                            }}
                        >
                            <CenterItem>
                                <UserForm
                                    userData={userData}
                                    listBooks={listBooks}
                                    onlyAdd={true}
                                    onClose={() => {
                                        fetchDistrictData();
                                        setSearchText("");
                                        setDataTab(MENU[2].id)
                                    }}
                                    schools={[...schools, currentOrg]}
                                />
                            </CenterItem>
                        </SectionsView>
                    </>
                )}
                {dataTab == MENU[8].id && (
                    <>
                        <SectionsView
                            label={""}
                            currentView={currentView}
                            id={"userbox"}
                            backButton={() => { }}
                            onCloseView={() => { }}
                            onClose={() => {
                                setDataTab("");
                            }}
                        >
                            <CenterItem>
                                <BooksTable />
                            </CenterItem>
                        </SectionsView>
                    </>
                )}
                {dataTab == MENU[9].id && (
                    <>
                        <SectionsView
                            label={""}
                            currentView={currentView}
                            id={"userbox"}
                            backButton={() => { }}
                            onCloseView={() => { }}
                            onClose={() => {
                                setDataTab("");
                            }}
                        >
                            <CenterItem>
                            </CenterItem>
                        </SectionsView>
                    </>
                )} */}
      </Wrapper>
    </SentryWrap>
  );
};

//  -- Prop types ---------------------
NewDashboardsContainer.propTypes = {};

//  -- Default props ------------------
NewDashboardsContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  activities: state.activities,
  classes: state.classes,
  // currentOrg: state.currentOrg,
  districts: state.districts,
  interactions: state.interactions,
  quizzes: state.quizzes,
  schools: state.schools,
  users: state.users,
  booksAllList: state.booksAllList,
  allUser: state.allUser,
  allSchools: state.allSchools,
  distStudents: state.distStudents
});

export default connect(mapStateToProps, null)(NewDashboardsContainer);
