//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import {debounce} from "lodash"
//  -- Components ---------------------
import { Col, Row } from 'antd';
import { Button } from '@material-ui/core';
import TextEditor from 'components/TextEditor';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.form`
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Question form
//
//  -- Description
//  Create question
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <QuestionForm
//      handleClose={func}
//      handleSubmit={func}
//  />
//
const QuestionForm = ({
    handleClose,
    handleSubmit,
}) => {

    const [refresh, setRefresh] = useState(false);
    const initialState = [{
        type: 'paragraph ',
        children: [
            {
                text: ''
            }
        ]
    }];
    const [questionData, setQuestionData] = useState("");

    const onClose = (e) => {
        e.preventDefault();
        setRefresh(true);
        setQuestionData("");
        setTimeout(() => setRefresh(false), 500);
        handleClose();
    };
   const handler=debounce(()=>{
          handleSubmit(questionData);
   },1000)
    const onSubmit = (e) => {
        e.preventDefault();
        handler()
        onClose(e);
    };

    return (
        <Wrapper onSubmit={onSubmit}>
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    {!refresh && (
                        <TextEditor
                            handleChange={setQuestionData}
                            value={questionData}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button
                        variant="contained"
                        color="primary"
                        type='submit'
                        style={{ width: '100%' }}
                    >
                        Create Question
                    </Button>
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type='submit'
                        style={{ marginTop: '8px', width: '100%' }}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Wrapper>
    );
};


//  -- Prop types ---------------------
QuestionForm.propTypes = {
    handleSubmit: func.isRequired,
};

//  -- Default props ------------------
QuestionForm.defaultProps = {};

export default QuestionForm;