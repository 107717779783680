//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
// -- Components ----------------------
import MessageRenderer from "components/MessageRenderer";
import AnswerForm from "features/forms/AnswerTOStudent";
import moment from "moment";
import { lighten } from "polished";
import React, { useState } from "react";
import styled from "styled-components";
import { QuestionMarkimg } from "utils/assets/images";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Caption = styled.span`
  color: ${COLOR.black400};
  font-size: 14px;
  font-weight: 400;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Row = styled.div`
  align-items: ${(p) => p.align || "center"};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`;

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`;

const TextLarge = styled(Text)`
  color: rgb(0 0 0);
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
  margin-top: 10px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

const Wrapper = styled.button`
  background-color: ${(p) =>
    p.color ? lighten(0.4, `${p.color}`) : "rgb(234,227,236)"};
  border: 2px dotted rgb(0 0 0);
  border-radius: 4px;
  color: rgb(0 0 0);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;

  &:last-child {
    margin-bottom: 16px;
  }
`;
const NoQuestionWrapper = styled.div`
  background-color: ${(p) =>
    p.color ? lighten(0.4, `${p.color}`) : "rgb(234,227,236)"};
  border: 2px dotted rgb(0 0 0);
  border-radius: 4px;
  color: rgb(0 0 0);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;
  height: 100%;
  &:last-child {
    margin-bottom: 16px;
  }
`;

const AssignmentDrawer = styled.div`
  background-color: #fcfcfc;
  height: 100%;
  overflow: scroll;
  position: absolute;
  left: ${(p) => (p.active ? "0px" : "calc(100% + 32px)")};
  top: 32px;
  transition: left 300ms ease, width 300ms ease;
  width: ${(p) => (p.active ? "100%" : "0px")};
  z-index: 1;
`;

const CreationDrawer = styled.div`
  background-color: #fcfcfc;
  overflow: scroll;
  position: absolute;
  top: 0;
  transition: left 300ms ease, width 300ms ease;
  z-index: 1;
  height:100%;
  width: 102%;
  left: -5px;
  scroll-snap-align: center
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const FormHeader = styled.span`
  color: ${COLOR.black200};
  display: inline-block;-
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const FormDescription = styled.span`
  color: ${COLOR.black400};
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24px;
`;

const margenTop = styled(CreationDrawer)`
  margin-top: -24px;
`;
const Questionicon = styled.img`
  height: 35px;
  width: 45px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  object-fit: fill;
`;
const NoteSec = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 7px;
  padding-bottom: 10px;
  width: 80%;
  background-color: ${(p) => (p.isQuestion ? COLOR.yellow : COLOR.indigo100)};
  color: ${(p) => (p.isQuestion ? "#000" : COLOR.white)};
  margin-left: ${(p) => (p.isQuestion ? "0px" : "auto")};
  margin-right: ${(p) => (p.isQuestion ? "auto" : "0px")};
  border-radius: 5px;
`;
const InnerButton = styled.div`
  background-color: transparent;
  border: 2.3px dashed white;
  position: relative;
  padding: 3px 10px;
  width: 100%;
  border-radius: 5px;
`;

const NotesText = styled.span`
  color: #545658;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 5px;
  margin-top: 3px;
  text-align: left;
  width: 100%;
`;
const CaptionSec = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;
const Pointer = styled.div`
  background-color: ${(p) => (p.isQuestion ? COLOR.yellow : COLOR.indigo100)};
  height: 15px;
  width: 15px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  left: calc(0.5vw - 50px);
  border: 2.5px dashed white;
  border-top-width: 0px;
  position: absolute;
  bottom: -10px;
  left: 80%;
  border-left-width: 0px;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Question
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const NoQuestionView = () => {
  return (
    <NoQuestionWrapper>
      <Row
        align="flex-start"
        style={{
          alignItems: "justify",
          justifyContent: "flex-start",
          marginBottom: 0,
        }}
      >
        <Questionicon src={QuestionMarkimg} />
        <CaptionSec>
          <Caption style={{ color: "rgb(0 0 0)", lineHeight: "14px" }}>
            {"Question"}
          </Caption>
          <Caption style={{ color: "rgb(0 0 0)" }}>
            {moment().format("MM/DD/YYYY")}
          </Caption>
        </CaptionSec>
      </Row>
      <NotesText>Question:</NotesText>
    </NoQuestionWrapper>
  );
};
const Question = ({
  question,
  view,
  hasAnswerView,
  hasNoQuestion = false,
  onEditQuestion,
}) => {
  let addMargen;
  if (view == "notas") {
    addMargen = true;
  } else {
    addMargen = false;
  }
  var [creationType, setCreationType] = useState(null);
  if (hasNoQuestion) {
    return <NoQuestionView />;
  }
  return (
    <Wrapper color={question?.color?.color}>
      <Row
        align="flex-start"
        style={{
          alignItems: "justify",
          justifyContent: "flex-start",
          marginBottom: 0,
        }}
      >
        <Questionicon src={QuestionMarkimg} />
        <CaptionSec>
          <Caption style={{ color: "rgb(0 0 0)", lineHeight: "14px" }}>
            {"Mis preguntas"}
          </Caption>
          <Caption style={{ color: "rgb(0 0 0)" }}>
            {moment(new Date(parseInt(question?.questionCreated))).format('MM/DD/YYYY')}
          </Caption>
        </CaptionSec>
      </Row>
      <NotesText>Question:</NotesText>
      <NoteSec isQuestion>
        <InnerButton>
          {question?.question ? (
            <MessageRenderer
              style={{ color: "rgb(0 0 0)" }}
              data={question?.question}
            />
          ) : null}
          <Pointer isQuestion />
        </InnerButton>
      </NoteSec>
      {question?.answer !== null && (
        <NoteSec>
          <InnerButton>
            <MessageRenderer
              style={{ color: "rgb(0 0 0)" }}
              data={question?.answer}
            />
            <Pointer />
          </InnerButton>
        </NoteSec>
      )}
      <Row>
        <Column></Column>
        {hasAnswerView && (
          <TextLarge
            onClick={() => {
              if (onEditQuestion) {
                onEditQuestion(question);
              } else {
                setCreationType("question");
              }
            }}
          >
            Answer
          </TextLarge>
        )}
      </Row>

      {creationType === "question" ? (
        <CreationDrawer
          active={creationType}
          style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 24 }}
          className={addMargen ? "margin-top-notas" : ""}
        >
          <AnswerForm
            data={question}
            handleClose={() => setCreationType(null)}
          />
        </CreationDrawer>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

//  -- Prop types ---------------------
Question.propTypes = {};

//  -- Default props ------------------
Question.defaultProps = {
  question: {
    content: "Here is a question for viewing",
    created: "2020-06-24T12:34:00Z",
  },
};

export default Question;
