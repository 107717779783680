//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { ArrowLeftOutlined } from '@ant-design/icons';

//  -- Constants ----------------------
import { filter, find, get, sortBy } from 'lodash';
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { Button, Col, Row } from 'antd';
import SchoolForm from 'features/forms/admin/School';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleGetDistrictsWithCount } from "store/organizations/thunks";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const StableWrapperMain = styled.div`
    margin-top:10px;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const AddSchools = ({
    userloading,
    districts,
    onTabsJump = () => null,
    onUserAddOrUpdate = () => null,
}) => {
    const location = useLocation();
    const data = location.state;
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const currentOrg = useCurrentOrg()
    const dispatch = useDispatch();
    const [orgLoader, setOrgLoader] = useState(-1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = React.useState(0);
    const [schoolForm, setSchoolForm] = useState(false);
    const [editSchool, setEditSchool] = useState(get(data, 'editSchool', false));
    const [school, setSchool] = useState(get(data, 'school', null));
    const history = useHistory();
    // const userData = get(data, 'userData', null)
    // const editDistrict = get(data, 'editSchool', false);
    const setFilterdDistrict = (dist) => {
        if (dist) {
            const cOrg = find(dist, d => d.id == get(currentOrg, "id"))
            if (cOrg) {
                const sortedDist = sortBy(filter(dist, d => d.id != get(currentOrg, "id")), ["displayName"])
                const res = [cOrg, ...sortedDist]
                setFilteredDistricts(res)
            } else {
                setFilteredDistricts(sortBy(dist, ["displayName"]))
            }

        } else {
            setFilteredDistricts([])
        }
    }
    useEffect(() => {
        setFilterdDistrict(districts)
    }, [districts])
    useEffect(() => {
        async function fetchData() {
            // await dispatch(handleListAllBooks());
            await dispatch(handleGetDistrictsWithCount());
            // await dispatch(handleListInteractionsByType("audio"));
            // await dispatch(handleListInteractionsByType("video"));

            fetchDistrictData();
        }
        fetchData();
    }, [currentOrg]);

    useEffect(() => {
        fetchDistrictData();
    }, [currentOrg]);
    const fetchDistrictData = async () => {
        // try {
        //   setUserLoading(true);
        //   if (!isEmpty(currentOrg)) {
        //     await handleUpdateLicences(currentOrg.id)
        //     await dispatch(handleListOrgsByParent(currentOrg.id))
        //     await dispatch(handleListDistUsers(currentOrg.id));
        //   }
        //   setUserLoading(false);
        // } catch (error) {
        //   setUserLoading(false);
        // }
    };
    const backToDataBrowser = () => {
        history.push('/data-browsers');
    };
    return (
        <>
            <Row>
                <Col lg={24}>
                    <Button type="link" primary style={{ paddingLeft: 0, fontSize: 17, marginBottom: 20 }} onClick={backToDataBrowser}>
                        <ArrowLeftOutlined />
                        Back
                    </Button>
                </Col>
            </Row>
            <StableWrapperMain>
                <SchoolForm
                    currentOrg={currentOrg}
                    school={school}
                    editing={editSchool}
                    onClose={() => {
                        setSchoolForm(false);
                        setSchool(null);
                        setEditSchool(false);
                        onUserAddOrUpdate()
                    }}
                />
            </StableWrapperMain>
        </>
    );
};


//  -- Prop types ---------------------
AddSchools.propTypes = {};

//  -- Default props ------------------
AddSchools.defaultProps = {};

export default AddSchools;
