//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep } from 'lodash';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const iInitial = {
    open:false,
    data:{}
}

export const playGameModal = (state = iInitial, action) => {
    switch (action.type) {
        case ActionTypes.game.set_player_modal:
            return action.payload;
        default:
            return state;
    }
}
const iModalInitial = {
    open:false,
    iframeUrl:''
}
export const oneGameModal = (state = iModalInitial, action) => {
    switch (action.type) {
        case ActionTypes.game.set_one_game_modal:
            return action.payload;
        default:
            return state;
    }
}
const initalGame = []
export const class_game_activity = (state = initalGame, action) => {
    switch (action.type) {
        case ActionTypes.game.set_class_game_activity:
            return action.payload;
        case ActionTypes.game.update_class_game_activity:
            return [...state, action.payload];
        case ActionTypes.game.clear_class_game_activity:
            return initalGame;
        default:
            return state;
    }
}