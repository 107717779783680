//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { map } from "lodash";
import { ActionTypes } from 'store/constants';

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const favouriteInitial = [];

export const favourite_video = (state = favouriteInitial, action) => {
    switch (action.type) {
        case ActionTypes.favourite_video.create_favourite_video:
            return action.payload;
        case ActionTypes.favourite_video.list_favourite_video:
            return action.payload;
        case ActionTypes.favourite_video.update_favourite_video:
            return action.payload;
            case ActionTypes.favourite_video.most_used_video:
                return action.payload;
        default:
            return state;
    }
};
