import { Button, FormHelperText, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Add, Close, Edit } from '@material-ui/icons';
import { Col, Row , message } from 'antd';
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
import { get, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleListClasslinkKeys, handleUpdateClasslinkKeys } from 'store/classlink/thunks';
import styled from 'styled-components';
import COLOR from 'utils/constants/color';
import { testValidUrl } from 'utils/methods/math';

const DefaultKeyConfig = {
    "id": null,
    "tenantId": "",
    "endpoint": "",
    "clientid": "",
    "clientsecret": "",
    "displayName": ""
}
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;

const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px; 
    @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;

const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;

const KeyUpdate = ({ openEditModal, setOpenEditModal, selectedData , onDone}) => {
    const [classlinkinfoLoading, setClasslinkinfoLoading] = useState(false);
    const [error, setError] = useState(false);
    const [key_info_error, setKeyInfoError] = useState(false);
    const [classlinkKeyInfo, setClasslinkKeyInfo] = useState({});
    useEffect(()=>{
        if(typeof selectedData==="object"){
            setClasslinkKeyInfo(selectedData)
        }
    },[selectedData])
    const onUpdate = async () => {
        if (!classlinkKeyInfo.tenantId) {
            setError(`tenantId required!`)
            return;
        } else if (!classlinkKeyInfo.clientid) {
            setError(`client id required!`)
            return;
        } else if (!classlinkKeyInfo.displayName) {
            setError(`Display name required!`)
            return;
        } else if (!testValidUrl(classlinkKeyInfo.endpoint)) {
            setError(`Enter a valid set URI`)
            return;
        }else if (!classlinkKeyInfo.endpoint) {
            setError(`endpoint url required!`)
            return;
        }else if (!classlinkKeyInfo.clientsecret) {
            setError(`Secret required.`)
            return;
        }
         else {
            setClasslinkinfoLoading(true);
            const keyInfo = await handleUpdateClasslinkKeys(classlinkKeyInfo);
            if (keyInfo && keyInfo.success) {
                setClasslinkKeyInfo(keyInfo.data)
                close()
                if(onDone){
                    onDone();
                }
                message.success("Canvas key info updated!")
            } else {
                message.error("Having issue while updating key info")
            }
            setClasslinkinfoLoading(false);
        }

    }
    const fieldUpdate = (key, value) => {
        setError(false);
        setClasslinkKeyInfo({ ...classlinkKeyInfo, [key]: value })
    }
    const close = () =>{
        setOpenEditModal(false)
    }
    return (
        <Modal
            open={openEditModal}
            onClose={close}
            style={{ height: "100%", width: "100%" }}
        >
            <ModalContent style={{ width: "70vw" }}>
                <Row style={{ justifyContent: "space-between" }}>
                    <IconButton
                        onClick={close}
                        style={{ padding: 0 }}
                    >
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <LinkArea>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="tenantId"
                                    name="tenantId"
                                    label="tenantId"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("tenantId", e.target.value);
                                    }}
                                    value={get(classlinkKeyInfo, "tenantId", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="endpoint"
                                    name="endpoint"
                                    label="endpoint"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("endpoint", e.target.value);
                                    }}
                                    value={get(classlinkKeyInfo, "endpoint", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="clientid"
                                    name="clientid"
                                    label="clientid"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("clientid", e.target.value);
                                    }}
                                    value={get(classlinkKeyInfo, "clientid", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="clientsecret"
                                    name="clientsecret"
                                    label="client secret"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("clientsecret", e.target.value);
                                    }}
                                    value={get(classlinkKeyInfo, "clientsecret", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    label="displayName"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("displayName", e.target.value);
                                    }}
                                    value={get(classlinkKeyInfo, "displayName", "")}
                                />
                            </Col>
                        </Row>
                    </LinkArea>
                </ModalConfigArea>
                {error ? (
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                ) : null}
                {key_info_error ? (
                    <FormHelperText error>
                        {`Haveing issue while feting key information`}
                    </FormHelperText>
                ) : null}
                <Row
                    gutter={[24, 24]}
                    style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
                >
                    <Button
                        fullWidth
                        onClick={onUpdate}
                        disabled={classlinkinfoLoading || key_info_error}
                        color="primary"
                        variant="contained"
                    >
                        {classlinkinfoLoading ? <Loading white size={14} /> : null}{classlinkKeyInfo?.id?"Update":"Add"}
                    </Button>
                </Row>
            </ModalContent>
        </Modal>
    )
}







export default function CanvasCourseMap() {
    const [classlinkKeys,setclasslinkKeys] = useState([])
    const [loading , setLoading] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const dispatch = useDispatch()
    async function fetchdata() {
        setLoading(true)
        try {
            const data = await handleListClasslinkKeys()
            if (data && data.success) {
                setclasslinkKeys(get(data, 'data', []))
            }  
        } catch (error) {
            
        } finally{
            
        }
       
        setLoading(false)
    }
    useEffect(() => {
        fetchdata()
    }, [])
    const onEdit = (data) => {
        setSelectedData(data)
        setOpenEditModal(true)
    }
    const createNew = () => {
        setSelectedData(DefaultKeyConfig)
        setOpenEditModal(true)
    }
  return (
   <>
        <KeyUpdate openEditModal={openEditModal} selectedData={selectedData} setOpenEditModal={setOpenEditModal} onDone={fetchdata}  />
          <Wrapper>
              <Button
                  onClick={createNew}
                  color="primary"
                  variant="contained"
                  startIcon={<Add style={{ color: COLOR.white }} />}
              >
                  Add
              </Button>
              <LoadingWrapper loading={loading}>
                  {classlinkKeys.length > 0 ? <Table style={{ width: "100%", textAlign: "center" }}>
                      <TableHead>
                          <TableRow>
                              <TableCell style={{ textAlign: "center" }}>District</TableCell>
                              <TableCell style={{ textAlign: "center" }}>SourceId</TableCell>
                              <TableCell style={{ textAlign: "center" }}>ClientId</TableCell>
                              <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {map(classlinkKeys, keys => {
                              return (<>
                                  <TableRow key={keys.id}>
                                      <TableCell style={{ textAlign: "center" }}>{keys.displayName||''}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}>{keys.tenantId}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}>{keys.clientid}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}><Button style={{ width: 85, height: 35 }} variant="text" onClick={()=>onEdit(keys)} > <Edit style={{ color: COLOR.green }} /> </Button></TableCell>
                                  </TableRow>
                              </>)
                          })}
                      </TableBody>
                  </Table> : null}
              </LoadingWrapper>
          </Wrapper>
   </>
  )
}
