//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash";
import { PromiseSettledAll } from 'utils/methods/getSettledPromisses';
//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";

//  -- Constants ----------------------
export const handleCreateUserPreference = (userId,data) => {
    return async (dispatch) => {
        dispatch(ActivityActions.createUserPreference());
        try {
            const response = await API.post('userPreferenceRDS', '/userPreference', {
                body: {
                    userId,
                    ...data
                }
            });
            if(response&&response.id){
                dispatch(ActivityActions.createUserPreferencSuccess(response));
            }
           return response;
        } catch (error) {
            if(error&&error.message!="Network Error"){
                Sentry.captureException(error)
             }
            dispatch(ActivityActions.createUserPreferenceFail(error));
        }
    }
}
export const handleUpdateUserPreference = (userId, data) => {
    return async (dispatch) => {
        dispatch(ActivityActions.updatePreferenceUser());
        try {
            const response = await API.put('userPreferenceRDS', `/userPreference/${userId}`, {
                body: {
                    ...data
                }
            });
            if(response&&response.id){
                dispatch(ActivityActions.updateUserPreferenceSuccess(response));
            }
           return response;
        } catch (error) {
            if(error&&error.message!="Network Error"){
                Sentry.captureException(error)
             }
            dispatch(ActivityActions.updateUserPreferenceFail(error));
        }
    }
}
export const handleGetUserPreference = (userId) => {
    return async (dispatch) => {
        dispatch(ActivityActions.getUserPreference());
        try {
            const response = await API.get('userPreferenceRDS', `/userPreference/${userId}`);
            dispatch(ActivityActions.getUserPreferenceSuccess(response));
            return response;
        } catch (error) {
            if(error&&error.message!="Network Error"){
                Sentry.captureException(error)
             }
            dispatch(ActivityActions.getUserPreferenceFail(error));
            return error;
        }
    }
}