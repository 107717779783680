//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { connect, useDispatch } from 'react-redux';
//  -- Components ---------------------
import { Col, Drawer, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
//  -- Constants ----------------------
import { Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import Acent from 'components/Acent';
import Loading from 'components/Loading';
import TextEditor from 'components/TextEditor';
import AudioRecorder from 'features/AudioRecorder';
import { cloneDeep, filter, find, get, has, map } from "lodash";
import { handleCreateCustomAssignment, handleGetAssignmentStatus, handleUpdateCustomAssignment } from "store/activities/thunks";
import { useAllBook } from 'store/books/selectors';
import { useClassActivities } from 'store/classActivities/selectors';
import { useClassQuizzes } from 'store/classQuizzes/selectors';
import { useResources } from 'store/resources/selectors';
import { useUserClasses } from 'store/userClasses/selectors';
import { NEWSLETTERS } from 'utils/config/reference';
import COLOR from 'utils/constants/color';
import { imageFetchDinamic, imageFetchFromPodcast, newsImageFetch } from 'utils/lib/ebookHelper';
import AudioPicker from "./AudioPicker";
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const LABEL = ['a', 'b', 'c', 'd', 'e']
const QUESTION_TYPE_ACTIVITY = ['essay', 'email', 'audio', 'multipleChoice','twoMinAudio']
const DURATIONS = [{
    duration: 30,
    label: '30s',
    value: 30
},
{
    duration: 60,
    label: '1m',
    value: 60
},
{
    duration: 120,
    label: '2m',
    value: 120
}
]
const Container = styled.div`
    width: 100%;
`;

const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 25px;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Note = styled.span`
    color: ${COLOR.black400};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
`;

const Wrapper = styled.div`
    margin: 0px auto;
    width: 100%;
    position: relative;
    top: 0px;
`;
const AssignmentDescription = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const Image = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContiner = styled.div`
    background-color: transparent;
    border: none;
    flex: 1;
    outline: none;
    width: 100%;
    height:100%;
    margin-right: 16px;
    padding-right: 10px;
`;
const ClassNameText = styled.div`
   font-size:18px;
   font-weight:bold;
   text-align: left;
   text-transform: uppercase;
`;
const ModalInnerWrapper = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 450px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow:scroll;
`;
const QuestionArea = styled.div`
     
`;
const ClassConfigContainer = styled.div`
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
`;
const RelativeContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 10px;
    margin: 2px;
    border: 1px dashed;
    margin-top:16px;
    margin-bottom:16px;
`;
const AssignmentTitle = styled.span`
    color: ${COLOR.black100};
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
`;
const Question = styled(AssignmentTitle)`
    font-size: 16px;
    line-height: 22px;
`;


const QuestionContainer = styled(Container)`
    margin-top: 0px;
    position: relative;
`;


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    formControl: {
        minWidth: 120
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    }



}));

const Content = styled.div`
    margin-top: 24px;
`;

const Detail = styled(AssignmentDescription)`
    color: ${COLOR.black600};
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top:${p => p.index == 0 ? "opx" : "24px"}
`;

const Option = styled.button`
    background-color: ${p => p.selected ? COLOR.green800 : COLOR.white};
    color: ${p => p.selected ? COLOR.white : COLOR.black400};
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 6px;
    outline: none;
    text-align: left;
    transition: all 300ms ease;
    width: 100%;
`;
const InnerWrapper = styled.div`
        height: 100%;
        padding: 0px;
        width: 100%;
        overflow-y:scroll;
        overflow-x: hidden;
        scroll-behavior: smooth;
        height: max-content;
        padding-bottom: 5px;
        max-height:60vh;
        border-bottom: 1px dotted;
        padding-right: 6px;
        ::-webkit-scrollbar {
            width: 5px;
            height: 3px;
          }
}
`;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, studentsName, theme) {
    return {
        fontWeight:
            studentsName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Quiz form
//
//  -- Description
//  Create / edit quiz questions
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <AssignmentForm
//      data={data}
//      handleChange={func}
//      handleSubmit={func}
//  />
//


const AssignmentForm = ({
    data,
    bookId,
    pageId,
    open,
    onClose,
    currentUser
}) => {
    const classActivities = useClassActivities()
    const classQuizzes = useClassQuizzes()
    const userClasses = useUserClasses()
    const classes = useStyles();
    const [questions, setQuestions] = useState([])
    const [displayName, setdisplayName] = useState('')
    const [loading, setLoading] = useState(false)
    const [languageFeedback, setLanguageFeedback] = useState('SPANISH')
    const [errors, setError] = useState([])
    const dispatch = useDispatch()
    const isEditing = has(data, "id")
    const innerRef = useRef(null)
    const booksAllList = useAllBook()
    const resources = useResources()
    const [isAssigned, setAssigned] = useState(true)
    const [assignedloading,setAssignedLoading] = useState(false)
    const dispNameRef = useRef();
    const questionRefs = useRef([]);
    const optionRefs = useRef([]);
    const [assignmentMessage,setAssignmentMessage] = useState('This activity has already been assigned  therefore you can only change the display name and edit question text. Please unassign the activity if you wish to make changes to it.')
    const getImages = React.useCallback(() => {
        if (NEWSLETTERS[bookId]) {
            return get(newsImageFetch(bookId, pageId), "src")
        } else if (imageFetchFromPodcast(bookId, resources)) {
            return imageFetchFromPodcast(bookId, resources)
        } else {
            return get(imageFetchDinamic(bookId, pageId, booksAllList), "resource")
        }
    })
    const onSubmit = async (e) => {
        const error = []
        const assignmentData = {
            ...data,
            chapter: bookId,
            bookId: bookId,
            displayName: displayName,
            page: pageId,
            questions: questions,
            typeId: (isEditing && data.typeId) ? data.typeId : 'activity',
            createdBy: get(currentUser, "id"),
            language_feedback: languageFeedback
        }
        setLoading(true)
        if (!assignmentData.displayName) {
            error.push({ type: 'display_name', message: 'Displayname required' })
        }
        if (!assignmentData.language_feedback) {
            error.push({ type: 'language_feedback', message: 'Language feedback required!' })
        }
        if (!Array.isArray(assignmentData.questions) || assignmentData.questions.length == 0) {
            error.push({ type: 'question_error', message: 'Questions are required , please add atleast one question.' })
        } else {
            let messageQ = `Question text reqired on q.no (`
            let optionMessage = `Option text required on (`
            let hasQuestionError = false
            let hasOptionErrorError = false
            assignmentData.questions.map((q, i) => {
                if (!q.question && q.type != 'audio') {
                    messageQ += `${i + 1},`
                    hasQuestionError = true
                }
                if (q.type === 'multipleChoice') {
                    const noOption = filter(q.options, (o, i) => !o)
                    if (noOption.length > 0) {
                        hasOptionErrorError = 2;
                        optionMessage += `${i + 1},`
                    }
                }

            })
            messageQ = messageQ.substring(0, messageQ.length - 1) + `)`
            optionMessage = optionMessage.substring(0, optionMessage.length - 1) + `)`
            let finalMessage = ``
            if (hasQuestionError) {
                finalMessage += messageQ + ".\n"
            }
            if (hasOptionErrorError) {
                finalMessage += optionMessage + ".\n"
            }
            if (finalMessage) {
                error.push({
                    type: "question_error",
                    message: finalMessage
                })
            }
        }
        setError(error)
        if (error.length == 0) {
            if (isEditing) {
                await dispatch(handleUpdateCustomAssignment(assignmentData))
            } else {
                await dispatch(handleCreateCustomAssignment(assignmentData))
            }
            onClose()
        }
        setLoading(false)

    };
    const fetchStatus = async()=>{
        setAssignedLoading(true)
        const ID = get(data, "id")?get(data, "id"):get(data, "cloneId")
        const status = await handleGetAssignmentStatus(ID,currentUser.id)
        const classesAssiged = map(status,k=>get(find(userClasses,["id",k.classId]),"displayName"))
        //   const classesAssiged=[]
        if(status&&status.length>0){
            setAssigned(true)
            if(get(data, "cloneId")){
                const aText = `This activity has already been assigned (${classesAssiged.join(",")}) therefore you cannot make changes,  Please unassign the activity if you wish to make changes to it.`;
                setAssignmentMessage(aText)
            }else{
                const aText = `This activity has already been assigned (${classesAssiged.join(",")}) therefore you can only change the display name and edit question text. Please unassign the activity if you wish to make changes to it.`;
                setAssignmentMessage(aText)
            }
        }else{
            setAssigned(false)
            setAssignmentMessage('')
        }
        setAssignedLoading(false)
    }
    useEffect(() => {
        if(open&&(get(data, "id")||get(data, "cloneId"))){
            fetchStatus()
        }else{
            setAssigned(false)
            setAssignmentMessage('')
        }
    }, [classActivities, classQuizzes, open, data])
    useEffect(() => {
        if (data) {
            setQuestions(get(data, "questions", []))
            setdisplayName(get(data, "displayName", ''))
            setLanguageFeedback(get(data, "language_feedback", ''))
        } else {
            setQuestions([])
            setdisplayName('')
            setLanguageFeedback('SPANISH')
        }
    }, [data])
    useEffect(() => {
        if (!open) {
            setQuestions([])
            setdisplayName('')
            setLanguageFeedback('SPANISH')
        }
    }, [open])
    const onChangeQuestionData = (val, key, i) => {
        const v = cloneDeep(questions)
        v[i][key] = val;
        setQuestions(v)
        setError([])
    }
    const onChangeQuestionType = (type, i) => {
        const v = cloneDeep(questions)
        v[i].type = type;
        if (type === "multipleChoice") {
            if (!Array.isArray(v[i].options) || v[i].options.length == 0) {
                v[i].options = ['', '', '', '']
            }
            if (isNaN(parseInt(v[i].answer))) {
                v[i].answer = 0;
            }
        }else if (type === "audio") {
            v[i].duration = 60
        }

        setQuestions(v)
    }
    const onchangeOption = (index, optIndex, value) => {
        const v = cloneDeep(questions)
        if (v[index].options) {
            v[index].options[optIndex] = value
        } else {
            v[index].options = ['', '', '', '']
            v[index].options[optIndex] = value
        }
        setQuestions(v)
    }
    const onChangeOptionsLength = (index, length) => {
        const v = cloneDeep(questions)
        if (v[index].options) {
            let opt = v[index].options.slice(0, length)
            if (opt.length == length) {
                v[index].options = opt;
                if (v[index].answer >= opt.length) {
                    v[index].answer = 0;
                }
            } else {
                let len = length - opt.length;
                let arr = new Array(len).fill('', 0, len)
                opt.push(...arr)
                v[index].options = opt;
            }
        } else {
            v[index].options = new Array(length).fill('')
            v[index].answer = 0;
        }
        setQuestions(v)
    }
    const onDeleteQuestion = (i) => {
        const v = cloneDeep(questions)
        v.splice(i, 1)
        setQuestions(v)
    }
    const onAddQuestion = () => {
        const v = cloneDeep(questions)
        let _id = parseInt(get(v[v.length - 1], "id", 0))
        _id = isNaN(_id) ? 0 : _id + 1
        v.push({
            "type": "essay",
            "id": _id,
        })
        setQuestions(v)
        if (innerRef.current) {
            setTimeout(() => {
                innerRef.current.scrollTo(0, innerRef.current.scrollHeight)
            }, 500)
        }
    }

    return (
        <Drawer
            height="100%"
            destroyOnClose
            className="assignments-drawer"
            onClose={onClose}
            placement="top"
            visible={open}
        >
            <Wrapper>
                <Row className="editQuizUpr">
                    <Col xs={24} sm={12} md={12} style={{ padding: "16px 10px" }}>
                        <ImageContiner>
                            <Image src={getImages()} alt='' />
                        </ImageContiner>
                    </Col>
                    <Col xs={24} sm={12} md={12} style={{ padding: "16px 16px", paddingBottom: "76px" }}>
                        <Header>{isEditing ? `Edit assignment` : `Create assignment`}</Header>
                        <Row justify="start" style={{ marginBottom: 10 }}>
                            <Col span={16}>
                                <Acent inputRef={dispNameRef} setValue={setdisplayName}  style={{ marginBottom: 5 ,  display:"flex" ,justifyContent:"flex-end" }}  />
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    label="displayName"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    inputRef={dispNameRef}
                                    required
                                    variant="outlined"
                                    onChange={e => {
                                        setdisplayName(e.target.value)
                                    }}
                                    value={displayName}
                                />
                            </Col>
                        </Row>
                        <Row justify="start" style={{ marginBottom: 10 }}>
                        <Col span={16}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="type" style={{background: 'white'}}>Language Feedback</InputLabel>
                                <Select
                                    labelId="language_feedback"
                                    id="language_feedback"
                                    required={false}
                                    value={languageFeedback || ''}
                                    onChange={(v) => {
                                        setLanguageFeedback(v.target.value)
                                    }}
                                    label="Type"
                                >
                                    <MenuItem value={'ENGLISH'}>English</MenuItem>
                                    <MenuItem value={'SPANISH'}>Spanish</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        </Row>
                        <Row style={{ justifyContent: "space-between", marginBottom: 16 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={onAddQuestion}
                                disabled={isAssigned || assignedloading }
                            >
                                Add question
                            </Button>
                        </Row>
                        {assignedloading?<Typography variant="subtitle2"  style={{ whiteSpace: "pre-wrap", display:"flex" , flexDirection:"row" }} color="primary"><Loading size={15}/> Please wait while checking assignment assigned status.</Typography>:null}
                        {isAssigned&&!assignedloading?<Typography variant="subtitle2"  style={{ whiteSpace: "pre-wrap" }} color="error">{assignmentMessage}</Typography>:null}
                        {questions.length > 0 ? <InnerWrapper ref={innerRef} id='previewInner'>
                            {map(questions, (question, index) => (<RelativeContainer key={question.id + index}>
                                <Row gutter={[24, 24]} style={{ justifyContent: "flex-end", display: "flex" }}>
                                    <IconButton disabled={Boolean(isAssigned || assignedloading)} onClick={() => { onDeleteQuestion(index) }} style={{ padding: 0, marginRight: 10 }}>
                                        <Delete color="secondary" />
                                    </IconButton>
                                </Row>
                                <QuestionContainer key={question.id} >
                                    <Detail index={index}>Question {index + 1} of {questions.length}</Detail>
                                    <Question>
                                        <Acent inputRef={questionRefs.current[index]} setValue={v=> onChangeQuestionData(v, "question", index)}  style={{ marginBottom: 5 ,  display:"flex" ,justifyContent:"flex-end" }}  />
                                        <TextField
                                            id="question"
                                            name="question"
                                            label="question"
                                            inputRef={ref=>questionRefs.current[index]=ref}
                                            InputLabelProps={{ shrink: true, required: true }}
                                            type="text"
                                            required
                                            variant="outlined"
                                            onChange={e => {
                                                onChangeQuestionData(e.target.value, "question", index)
                                            }}
                                            value={question.question}
                                        />
                                    </Question>
                                    <Question style={{ marginTop: 16 }}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel id="type">type</InputLabel>
                                            <Select
                                                labelId="type"
                                                id="type"
                                                value={question.type}
                                                disabled={isAssigned || assignedloading }
                                                required
                                                onChange={(v) => {
                                                    onChangeQuestionType(v.target.value, index)
                                                }}
                                                label="Type"
                                            >
                                                {QUESTION_TYPE_ACTIVITY.map(v => (<MenuItem key={v} value={v}>{v}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Question>
                                    <Content>
                                        {question.type === 'email' && (
                                            <Row gutter={[0, 24]}>
                                                <Col span={24}>
                                                    <TextEditor
                                                        handleChange={() => { }}
                                                        value={''}
                                                        autoFocus={false}
                                                        disabled={true}
                                                        containerClassName='question-ebook-text-field'
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        {question.type === 'essay' && (
                                            <Row gutter={[0, 24]}>
                                                <Col span={24}>
                                                    <TextEditor
                                                        handleChange={() => { }}
                                                        value={''}
                                                        autoFocus={false}
                                                        disabled={true}
                                                        containerClassName='question-ebook-text-field'
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        {question.type === 'multipleChoice' && (
                                            <>
                                                <FormControl variant="outlined" className={classes.formControl} style={{ marginBottom: 16 }}>
                                                    <InputLabel id="type" style={{ backgroundColor: "#fff" }}>Number of options</InputLabel>
                                                    <Select
                                                        labelId="level"
                                                        id="level"
                                                        required={false}
                                                        disabled={isAssigned || assignedloading}
                                                        value={get(question, "options.length", 4)}
                                                        onChange={(v) => {
                                                            onChangeOptionsLength(index, v.target.value)
                                                        }}
                                                        label="Type"
                                                    >
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                        <MenuItem value={4}>4</MenuItem>
                                                        <MenuItem value={5}>5</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {map(get(question, "options", ['', '', '', '']), (option, optId) => (
                                                    <Row key={optId} style={{ marginBottom: '8px' }}>
                                                        <RadioGroup style={{ display: "flex", flexDirection: "row", width: "100%" }} aria-label="options"
                                                            name={`options-${index}`}
                                                            value={`${question.answer}`}
                                                            onChange={(e) => { onChangeQuestionData(e.target.value, "answer", index) }}>
                                                            <Row style={{ width: "100%" }}>
                                                                <Col span={2}>
                                                                    <FormControlLabel  disabled={isAssigned || assignedloading}  value={`${optId}`} checked={optId == question.answer} control={<Radio />} />
                                                                </Col>
                                                                <Col span={22}>
                                                                    <Acent inputRef={optionRefs.current[`${index}-${optId}`]}  setValue={v=> onchangeOption(index, optId,v)}  style={{ marginBottom: 5 ,  display:"flex" ,justifyContent:"flex-end" }} />
                                                                    <TextField
                                                                        id={`question${index + optId}`}
                                                                        name="options"
                                                                        label={`option ${LABEL[optId]}`}
                                                                        InputLabelProps={{ shrink: true, required: true }}
                                                                        // disabled={isAssigned || assignedloading}
                                                                        type="text"
                                                                        required
                                                                        variant="outlined"
                                                                        inputRef={ref=>optionRefs.current[`${index}-${optId}`]=ref}
                                                                        onChange={e => {
                                                                            onchangeOption(index, optId, e.target.value)
                                                                        }}
                                                                        value={option}
                                                                    />

                                                                </Col>

                                                            </Row>


                                                        </RadioGroup>

                                                    </Row>
                                                ))}
                                            </>
                                        )}
                                        {question.type === 'audio' && (
                                            <>
                                                <Question style={{ marginBottom: 16 }}>
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="durations">Time</InputLabel>
                                                        <Select
                                                            labelId="durations"
                                                            id="durations"
                                                            value={question.duration || '60'}
                                                            required
                                                            disabled={isAssigned || assignedloading}
                                                            onChange={(v) => {
                                                                onChangeQuestionData(v.target.value, "duration", index)
                                                            }}
                                                            label="Type"
                                                        >
                                                            {DURATIONS.map(v => (<MenuItem value={v.value}>{v.label}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                </Question>
                                                <Question style={{ marginBottom: 16 }}>
                                                    <AudioRecorder
                                                        handleAudio={(v) => {
                                                            onChangeQuestionData(v, "audio", index)
                                                        }}
                                                        index={index}
                                                        currentSubmission={{}}
                                                        refresh={() => { }}
                                                        source={question.audio}
                                                        hasSaveButton
                                                        message={`Record a question`}
                                                    />
                                                </Question>
                                                <Question style={{ marginBottom: 16, textAlign: "center" }}>
                                                    OR
                                                </Question>
                                                <AudioPicker
                                                    message={"Upload a audio file for question..."}
                                                    onChange={v => {
                                                        onChangeQuestionData(v, "audio", index)
                                                    }}
                                                />
                                                {/* <ReactPlayer
                                                    url={question.audio}
                                                    height="40px"
                                                    width="100%"
                                                    controls
                                                    playsinline
                                                    style={{ marginBottom: '8px' }}
                                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                /> */}
                                                <AudioRecorder
                                                    maxTime={20}
                                                    handleAudio={() => { }}
                                                    index={index}
                                                    currentSubmission={{}}
                                                    refresh={() => { }}
                                                    isPreview
                                                />
                                            </>
                                        )}
                                        {question.type === 'twoMinAudio' && (
                                            <>
                                                <AudioRecorder
                                                    maxTime={120}
                                                    handleAudio={() => { }}
                                                    refresh={() => { }}
                                                    isPreview
                                                />
                                            </>
                                        )}
                                    </Content>
                                </QuestionContainer>
                            </RelativeContainer>))}
                        </InnerWrapper> : null}
                        {errors.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                            {errors.map(v => (<Typography variant="subtitle2" key={v.type} style={{ whiteSpace: "pre-wrap" }} color="error">
                                * {get(v, "message")}
                            </Typography>))}
                        </Row> : null}
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                            <Button fullWidth
                                onClick={onSubmit}
                                disabled={Boolean(questions.length == 0 || (!displayName || displayName.length == 0) || loading || assignedloading || (isAssigned&&!get(data, "id")&&get(data, "cloneId")))}
                                variant="contained"
                                color="primary">
                                {loading ? <Loading white size={15} /> : null} {isEditing||get(data, "cloneId") ? "Update" : "Create"}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Wrapper>
        </Drawer>
    );
};

//  -- Prop types ---------------------
AssignmentForm.propTypes = {

};

//  -- Default props ------------------
AssignmentForm.defaultProps = {
    data: {}
};
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    userClasses: state.userClasses,
    booksAllList: state.booksAllList,
    currentUser: state.currentUser
});


export default connect(mapStateToProps, null)(AssignmentForm);
