//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create class -------------------
export const createClass = () => ({ type: ActionTypes.classes.createClass });
export const createClassFail = (returnedPayload) => ({ type: ActionTypes.classes.createClassFail, payload: returnedPayload });
export const createClassSuccess = (returnedPayload) => ({ type: ActionTypes.classes.createClassSuccess, payload: returnedPayload });

//  -- Get class ----------------------
export const getClass = () => ({ type: ActionTypes.classes.getClass });
export const getClassFail = (returnedPayload) => ({ type: ActionTypes.classes.getClassFail, payload: returnedPayload });
export const getClassSuccess = (returnedPayload) => ({ type: ActionTypes.classes.getClassSuccess, payload: returnedPayload });

//  -- Get class by code --------------
export const getClassByCode = () => ({ type: ActionTypes.classes.getClassByCode });
export const getClassByCodeFail = (returnedPayload) => ({ type: ActionTypes.classes.getClassByCodeFail, payload: returnedPayload });
export const getClassByCodeSuccess = (returnedPayload) => ({ type: ActionTypes.classes.getClassByCodeSuccess, payload: returnedPayload });

//  -- List classes -------------------
export const listClasses = () => ({ type: ActionTypes.classes.listClasses });
export const listClassesFail = (returnedPayload) => ({ type: ActionTypes.classes.listClassesFail, payload: returnedPayload });
export const listClassesSuccess = (returnedPayload) => ({ type: ActionTypes.classes.listClassesSuccess, payload: returnedPayload });

//  -- List  All classes -------------------
export const listAllClasses = () => ({ type: ActionTypes.classes.listAllClasses });
export const listAllClassesFail = (returnedPayload) => ({ type: ActionTypes.classes.listAllClassesFail, payload: returnedPayload });
export const listAllClassesSuccess = (returnedPayload) => ({ type: ActionTypes.classes.listAllClassesSuccess, payload: returnedPayload });

//  -- List organization classes ------
export const listOrgClasses = () => ({ type: ActionTypes.classes.listOrgClasses });
export const listOrgClassesFail = (returnedPayload) => ({ type: ActionTypes.classes.listOrgClassesFail, payload: returnedPayload });
export const listOrgClassesSuccess = (returnedPayload) => ({ type: ActionTypes.classes.listOrgClassesSuccess, payload: returnedPayload });

//  -- List teacher classes -----------
export const listTeacherClasses = () => ({ type: ActionTypes.classes.listTeacherClasses });
export const listTeacherClassesFail = (returnedPayload) => ({ type: ActionTypes.classes.listTeacherClassesFail, payload: returnedPayload });
export const listTeacherClassesSuccess = (returnedPayload) => ({ type: ActionTypes.classes.listTeacherClassesSuccess, payload: returnedPayload });

//  -- Update class -------------------
export const updateClass = () => ({ type: ActionTypes.classes.updateClass });
export const updateClassFail = (returnedPayload) => ({ type: ActionTypes.classes.updateClassFail, payload: returnedPayload });
export const updateClassSuccess = (returnedPayload) => ({ type: ActionTypes.classes.updateClassSuccess, payload: returnedPayload });
