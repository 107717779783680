//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
//  -- Features -----------------------
import { Modal } from '@material-ui/core';
import { Close, Home } from '@material-ui/icons';
import { Button, Col, Row } from 'antd';
import LoginForm from 'features/forms/Login';
import RegistrationForm from 'features/forms/Registration';
import { useHistory } from 'react-router-dom';
import { useAlertView } from 'store/alert/selectors';
import { BirdLogo, LoginLogo, StarRating, personGreen1 } from "utils/assets/images";
const Container = styled.div`
    background-color: rgba(255, 255, 255, 1);
    border-radius: 9px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    height: auto;
    max-width: 315px;
    padding: 22px 20px 22px;
    width: 100%;
    margin-bottom:15px;
`;
const Link = styled.span`
    color: ${COLOR.white};
    font-size: 12px;
    margin-bottom: 4px;
    cursor: pointer; 
    &:last-child {
        margin-bottom: 0px;
    }

    @media (min-width: 600px) {
        margin-bottom: 0px;
    }
    &:hover {
        // border-bottom: 2px solid ${COLOR.white};
        text-decoration: underline;
        color: white;
    }

`;
const Wrapper = styled.div`
    align-items: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    width: 100%;
`;
const FeedBackWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;
const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50%;
    min-height: 50%;
    background-color: rgb(58,58,58);
    position: relative;
    justify-content: center;
    padding: 20px;
    padding-top: 0px;

`;
const BirdLogoImg = styled.div`
    width: 270px;
    height: 180px;
    margin-top: 20px;
    background-image: url(${BirdLogo});
    background-position: top;
    background-size: 150% 130%;
    background-repeat: no-repeat;
`;
const AlertTitle = styled.div`  
    color: white; 
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-line;
    text-align: center;
    width: 100%;
    `;
const AlertMessage = styled.div`   
    color: white;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase; 
    white-space: pre-line;
    width: 100%;
    `;
const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;

const LoginContainer = () => {
    const [registrationFlag, setRegistrationFlag] = useState(false);
    const [visible, setVisible] = useState(false);
    const [alertView, invokeAlertView, revokeAlertView] = useAlertView()
    const history = useHistory()

    return (
        <div className="logCstmMainUpr">
            <Row className="logCstmMain">
                <Col xs={24} md={24} lg={7} className="logCstmLeft"  style={{display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
                    <div><img  onClick={()=>history.push("/")} src={LoginLogo} style={{cursor:"pointer"}} title="One Way Education" alt="One Way Education" /></div>
                </Col>
                <Col xs={24} md={24} lg={10} className="logCstmMid">
                    <Wrapper>
                        <Modal
                            open={alertView.view} onClose={revokeAlertView}
                            style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}>
                            <FeedBackWrapper>
                                <FeedbackContainer>
                                    <Row style={{ justifyContent: "center" }}>
                                        <BirdLogoImg />
                                        <CloseBtn onClick={revokeAlertView}>
                                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                                        </CloseBtn>
                                    </Row>
                                    <Row style={{ paddingLeft: "20px", paddingRight: "20px", flexDirection: "column" }}>
                                        <AlertTitle>{alertView.title}</AlertTitle>
                                    </Row>
                                    <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                        {alertView.message ? <AlertMessage>{alertView.message}</AlertMessage> : null}
                                    </Row>
                                </FeedbackContainer>
                            </FeedBackWrapper>
                        </Modal>
                        <Container>
                        {/* <Transition visible={visible} />  */}
                            {/* <LogoContainer>
                            <Logo height={56} />
                        </LogoContainer> */}
                            {registrationFlag ? (
                                <RegistrationForm toggleRegistration={() => setRegistrationFlag(false)} />
                            ) : (
                                <LoginForm toggleTransition={() => setVisible(true)} toggleRegistration={() => setRegistrationFlag(true)} />
                            )}
                        </Container>
                        <Link onClick={() => history.push("/terms-conditions")}>Terms and Conditions</Link>
                        <Link onClick={() => history.push("/privacy-policy")}>Data Privacy Policy</Link>
                        <Link onClick={() => history.push("/student-information-privacy-notice")}>Student Information Privacy Notice</Link>
                    </Wrapper>
                </Col>
                <Col xs={24} md={24} lg={7} className="logCstmRght">
                    <div><img src={StarRating} title="" alt="" /></div>
                </Col>
                <div className="logBtmImg"><img src={personGreen1} title="" alt="" /></div>
            </Row>
        </div>
    );
};

//  -- Prop types ---------------------
LoginContainer.propTypes = {};

//  -- Default props ------------------
LoginContainer.defaultProps = {};

export default LoginContainer;
