//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import SentryWrap from "components/Sentry";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
// import Table from "components/Table";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";
import { handleListStoryStandard } from "store/story/thunks"
//  -- Thunks -------------------------
import StandardTable from "features/forms/admin/StandardTable";

const MENU = [
  { id: "see_standard", value: "Standard" },
];


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0px;
  width: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;











const StandardContainer = ({
  currentOrg,
  districts,
  allSchools
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataTab, setDataTab] = useState("see_standard");
  const [loading, setLoading] = useState(false);
  const cotationRef = useRef(null)
  if (!cotationRef.current) {
    cotationRef.current = getRandomCotation()
  }
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        await Auth.currentAuthenticatedUser();
        await  dispatch(handleListStoryStandard())
        setLoading(false);
      } catch {
        history.push("/");
      }
    }
    fetchData();
  }, []);



  return (
    <SentryWrap>
      <Wrapper className={"adminForm"}>
        <MenuWrapper top={"10px"} style={{ width: "100%" }}>
          {MENU.map((x) => (
            <MenuBtn
              style={{
                borderBottom:
                  dataTab === x?.id
                    ? "5px solid rgb(28,49,115)"
                    : "5px solid #fcfcfc",
                color: dataTab === x?.id ? "rgb(28,49,115)" : "black",
              }}
              key={x.id}
              onClick={() => {}}
            >
              {x.value}
            </MenuBtn>
          ))}
        </MenuWrapper>
          <StandardTable
            setDataTab
            districts={districts}
            allSchools={allSchools}
            userloading={loading}
            onUpdateOrAdd={() => {
            }}
          />
      </Wrapper>
    </SentryWrap>
  );
};

//  -- Prop types ---------------------
StandardContainer.propTypes = {};

//  -- Default props ------------------
StandardContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  activities: state.activities,
  classes: state.classes,
  currentOrg: state.currentOrg,
  districts: state.districts,
  interactions: state.interactions,
  quizzes: state.quizzes,
  schools: state.schools,
  users: state.users,
  booksAllList: state.booksAllList,
  allSchools: state.allSchools,
  allUser: state.allUser,
  allClasses: state.allClasses,
});

export default connect(mapStateToProps, null)(StandardContainer);
