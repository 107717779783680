//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const createSuccessCleverCources = (returnedPayload) => ({ type: ActionTypes.clever.setCleverCources , payload: returnedPayload });
export const setCleverUserInfo = (returnedPayload) => ({ type: ActionTypes.clever.setCleverUserInfo , payload: returnedPayload });


//  -- List All books ----------------
