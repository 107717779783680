//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    FormControlLabel,
    Modal,
    IconButton,
    TextField,
    FormHelperText
} from '@material-ui/core';
//  -- Components ---------------------
import { Settings, Close } from '@material-ui/icons';
import { Row , Col, message} from 'antd';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
//  -- Thunks -------------------------
import { Switch } from "antd";
import { get } from "lodash";
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { getCanvasKeyInfo, updateCanvasKeyInfo } from 'store/canvas/thunks';
import { testValidUrl } from 'utils/methods/math';



const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px; 
    @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;

const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    // margin-left:5px;
    // margin-top:5px;
`;

const SwitchWrappers = styled.div`
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-direction:row;
    width:100%;
    gap:24px;
`;

const DemoButton = styled.div`
    border: 0.5px solid white;
    background-color: ${p => p.bgColor ? p.bgColor : `rgb(113,0,225)`};
    font-size: 10px;
    font-weight: 600;
    outline: none;
    width:${p => p.width ? p.width : `80px`};
    color: white;
    border-radius: 3px;
    text-align:center;
    height:${p => p.height ? p.height : `20px`};
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const SwitchControllers = ({ bgColor, value, onChange = () => { }, label, width = '80px', height = '20px', disabled }) => {
    return (<SwitchWrappers>
        <DemoButton style={{ minHeight: 32 }} width={width} height={height} bgColor={bgColor}>{label}</DemoButton>
        <FormControlLabel
            style={{ marginTop: 8 }}
            control={
                <Switch
                    onChange={onChange}
                    name="view"
                    color="primary"
                    size="default"
                    checked={value}
                    className={"districForm"}
                    disabled={disabled ? disabled : false}
                />
            }
            className={"districForm"}
            label=""
        />
    </SwitchWrappers>)
}
const defaultCanvasSec = {
    id: null, base_url: '', key: '', secrate: '', created_on: '', api_version: 'api/v1', orgId: "", display_name:'', redirect_uri: 'https://nosotrosonline.com/login'
}


export default function CanvasConfig({ can_be_canvas, isCanvas, setIsCanvas, setCanvasKeyInfo, disabled, canvasKeyInfo, canvas_id, orgId }) {
    const [canvasKeyInfoLoadinf, setCanvasKeyInfoLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [key_info_error, setKeyInfoError] = useState(false);
    const onUpdate = async () => {
        if (!canvasKeyInfo.base_url) {
            setError(`Base url required!`)
            return;
        } else if (!canvasKeyInfo.key) {
            setError(`Key required!`)
            return;
        } else if (!canvasKeyInfo.display_name) {
            setError(`Display name required!`)
            return;
        } else if (!canvasKeyInfo.redirect_uri) {
            setError(`Redirect uri required!`)
            return;
        } else if (!testValidUrl(canvasKeyInfo.redirect_uri)) {
            setError(`Enter a valid redirect URI`)
            return;
        }else if (!canvasKeyInfo.redirect_uri) {
            setError(`Redirect url required!`)
            return;
        }else if (!canvasKeyInfo.secrate) {
            setError(`Secret required.`)
            return;
        }
         else {
            setCanvasKeyInfoLoading(true);
            const keyInfo = await updateCanvasKeyInfo(canvasKeyInfo);
            if (keyInfo && keyInfo.success) {
                setCanvasKeyInfo(keyInfo.data)
                setOpen(false)
                message.success("Canvas key info updated!")
            } else {
                message.error("Having issue while updating key info")
            }
            setCanvasKeyInfoLoading(false);
        }

    }
    const fetchInfo = async () => {
        setCanvasKeyInfoLoading(true);
        try {
            const data = await getCanvasKeyInfo(canvas_id, orgId);
            if (data && data.success && data.data) {
                setCanvasKeyInfo(data.data);
                setKeyInfoError(false)
            }else{
                setKeyInfoError(true)
            }

        } catch (e) {
            console.log(e);
        } finally {
            setCanvasKeyInfoLoading(false);
        }
    }
    useEffect(() => {
        if (isCanvas && (canvas_id || orgId)) {
            fetchInfo()
        }
    }, [isCanvas, canvas_id, orgId])
    const close = () => {
        setOpen(false)
    }
    const fieldUpdate = (key, value) => {
        setError(false);
        setCanvasKeyInfo({ ...canvasKeyInfo, [key]: value })
    }
    if (!can_be_canvas) return null;
    return (<>
        <Modal
            open={open}
            onClose={close}
            style={{ height: "100%", width: "100%" }}
        >
            <ModalContent style={{ width: "70vw" }}>
                <Row style={{ justifyContent: "space-between" }}>
                    <TitleText>

                    </TitleText>
                    <IconButton
                        onClick={close}
                        style={{ padding: 0 }}
                    >
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <LinkArea>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="display_name"
                                    name="display_name"
                                    label="display_name"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("display_name", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "display_name", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="base_url"
                                    name="base_url"
                                    label="Base url"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("base_url", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "base_url", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="key"
                                    name="key"
                                    label="key"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("key", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "key", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="secrate"
                                    name="secrate"
                                    label="secrate"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("secrate", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "secrate", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="api_version"
                                    name="api_version"
                                    label="api version"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("api_version", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "api_version", "")}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                            <Col span={24}>
                                <TextField
                                    id="redirect_uri"
                                    name="redirect_uri"
                                    label="Redirect uri"
                                    required
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={(e) => {
                                        fieldUpdate("redirect_uri", e.target.value);
                                    }}
                                    value={get(canvasKeyInfo, "redirect_uri", "")}
                                />
                            </Col>
                        </Row>
                    </LinkArea>
                </ModalConfigArea>
                {error ? (
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                ) : null}
                {key_info_error ? (
                    <FormHelperText error>
                        {`Haveing issue while feting key information`}
                    </FormHelperText>
                ) : null}
                <Row
                    gutter={[24, 24]}
                    style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
                >
                    <Button
                        fullWidth
                        onClick={onUpdate}
                        disabled={canvasKeyInfoLoadinf || key_info_error}
                        color="primary"
                        variant="contained"
                    >
                        {canvasKeyInfoLoadinf ? <Loading white size={14} /> : null}  {canvasKeyInfo?.id ? "Update" : "Create"}
                    </Button>
                </Row>
            </ModalContent>
        </Modal>
        <Row gutter={[24, 24]} style={{ width: '100%', paddingLeft: 10, marginTop: 25 }}>
            <SwitchControllers label={"Canvas"} bgColor={COLOR.orange100} disabled={disabled} value={isCanvas} onChange={e => setIsCanvas(e)} />
        </Row>
        {isCanvas && can_be_canvas && <Row style={{ marginTop: 25 }} className="floatingLabelBG">
            <Button
                variant="contained"
                color="primary"
                disabled={canvasKeyInfoLoadinf}
                onClick={() => setOpen(true)}
                startIcon={canvasKeyInfoLoadinf ? <Loading size={14} white /> :
                    <Settings style={{ color: "#fff", fontSize: 14 }} />}>
                {canvasKeyInfo.id ? "Update canvas configuration" : "Add canvas configuration"}
            </Button>
        </Row>}
    </>)
}
