//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Features -----------------------
import ChapterCardBook from 'components/ChapterBook';
//  -- Components ---------------------
import DemoModal from 'components/DemoModal';
import { filter, map, uniqBy, find , has } from "lodash";
import React, { useState, useEffect , useCallback  } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import _, { get } from 'lodash';
import { useCurrentUser, useDemoStatus } from "store/users/selectors";
import { useBookOrder } from "store/books/selectors"
import { mapBookByOrder } from "utils/lib/ebookHelper"
import { useCurrentClass } from 'store/classes/selectors';
import { Button, Tooltip } from "@material-ui/core"
import { handleHideOrShowParticularClassBooks, handleHideShowClassBooks  } from "store/userClasses/thunks"
import { DeleteModal } from 'components/Dialog';
import { useDispatch } from 'react-redux';
import  COLOR  from 'utils/constants/color';
import { Row } from "antd"
import { Star } from '@material-ui/icons';
import Tag from 'components/Tag';
import {Button as AntButton} from "antd";
import {  EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import Loading from 'components/Loading';
//  -- Constants ----------------------




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CustomCol = styled.div`
    margin: 0px;
    width: 100%;

    @media (min-width: 992px) {
        margin: ${p => p.margin};
        width: calc(50% - 12px);
    }
`;

const CustomRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 992px) {
        flex-direction: row;
    }
`;

const ListItem = styled.li`
    margin: 0px 16px 10px;
    width:25%;
    max-width:150px;
    @media only screen and (min-width: 600px) and (max-width: 800px) {
        margin: 0px 16px 10px;
        width: calc(50% - 32px);
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin: 0px 10px 10px;
        width: calc(50% - 32px);
    }
`
const List = styled.ul`
    height: auto;
    margin-left: -16px;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    list-style-type: none;

    @media (max-width: 992px) {
      width: 100%;
      margin-left: 0px;
    }
    @media (max-width: 992px) {
        flex-wrap: wrap;
    }
`;
const Section = styled.div`
    border: 2px solid #e9e9e9;
    margin-bottom: 26px;
    position: relative;
    padding-left: 16px;
    padding-top: 24px;
    padding-right: 16px;
`;
const SectionTitle = styled.div`
    font-size: 14px;
    color: #ffffff;
    background: ${p=>p.bgColor?p.bgColor:'#00c4cc'};
    padding: 4px 8px;
    border-radius: 6px !important;
    cursor: pointer;

    position: absolute;
    top: -10px;
    left: -6px;
`;
export const Wrapper = styled.div`
    width: 100%;
`;
const Note = styled.span`
    color: ${COLOR.black400};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
`;
const HiddenBookSection = styled.div`

`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Chapter management
//
//  -- Description
//  Activity and quiz management for selected chapter
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const ChapterManagementBook = ({
    currentOrg,
    userBooksList,
    listBook,
    booksAllList,
}) => {
    const dispatch = useDispatch();
    const [chaptersForUser, setChaptersForUser] = useState([])
    const [modalOpen, toggleModal] = useState(false);
    const bookOrder = useBookOrder()
    const currentUser = useCurrentUser();
    const history = useHistory();
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = get(orgPermissions, 'books', [])
    const demoStatus = useDemoStatus()
    const currentClass = useCurrentClass();
    const hasClass = !!has(currentClass, 'id');
    const [archiveToggle,setArchiveToggle] = useState(false)
    const [onArchiveLoading,setOnArchiveloading] = useState(false)
    const [booksToArchiveDearchive,setBooksToArchiveDearchive] = useState([])
    const [isArchive,setIsArchive] = useState(false)
    const [showHiddentBooks,setShowHiddentBooks] = useState(false);
    const [hiddenBook,setHiddenBooks] = useState([]);
    const [loading,setLoading] = useState({bookId: "", loading: false});
    
    const handleSetChapter = ((chapter) => {
        const demoPages = get(find(booksAllList, ["id", chapter]), "demoPages")
        if (demoStatus && demoPages && demoPages.from>=0) {
            history.push(`/ebook?chapter=${chapter}&page=${demoPages.from}&activity=null&quiz=null`);
        } else {
            history.push(`/ebook?chapter=${chapter}&page=1&activity=null&quiz=null`);
        }
    });
    const getUserBooks = useCallback(() => {
        const ubook = currentClass && currentClass.books ? currentClass : userBooksList
        let  booksData = [];
        if (_.has(ubook, "books")) {
            map(ubook.books, ub => {
                const bk = find(booksAllList, ['id', ub.bookId])
                if(bk){
                    booksData.push(bk)
                }
                if (bk && bk.teacherEditionBook) {
                    const tbk = find(booksAllList, ['id', bk.teacherEditionBook])
                    if (tbk) {
                        const hasAlready = find(booksData, ["id", tbk.id])
                        if (!hasAlready) {
                            booksData.push(tbk)
                        }
                    }
                }
            }) 
            booksData = _.filter(booksData, k => {
                if (bookPermissions.length == 0) {
                    return true;
                }
                const bookaccess = _.find(bookPermissions, b => b.bookId == k.id || b.bookId == k.bookId)
                if (bookaccess) {
                    return bookaccess.available
                } else {
                    return true;
                }
            })
            if(get(currentUser,"role")=='student'){
                booksData = booksData.filter(k => !k.onlyTeacher&&!k.isNotAssignable)
            }
            return uniqBy(booksData,"id");
        } else {
            return [];
        }
    },[currentClass,userBooksList,booksAllList,currentUser])

    const getArchivedBooks = () =>{
        const archiveBooks = get(currentClass,"archived_books",[])
        return map(archiveBooks,abk=>{
            return find(booksAllList, ['id', abk.bookId])
        }).filter(k=>k);
    }

    const getAllHiddenBook= () =>{
        const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId);
        return getHiddenBooks;
    }

    useEffect(()=> {
        const hiddenBooks = getAllHiddenBook();
        setHiddenBooks(hiddenBooks || []);
    },[currentClass])

    useEffect(() => {
        let classBook =  getUserBooks();
        if (Array.isArray(classBook)) {
            setChaptersForUser(uniqBy(classBook, "id"))
        }
        const archiveBooks = getArchivedBooks()
        if(archiveBooks.length==0){
           setShowHiddentBooks(false);
        }
    }, [userBooksList, booksAllList, listBook,currentClass,currentUser])
    // chapter.id !== '1' && currentOrg?.demo) || chapter.id === '6'

    const getBookDesc = ((bookId) => {
        if (Array.isArray(booksAllList)) {
            const bookD = filter(booksAllList, x => x.id == bookId);
            return bookD[0].description;
        } else {
            return ""
        }
    });
    const isHiddentBook = (books)=>{
        const archived_books = get(currentClass,"archived_books",[])
        if(find(books,bk=>find(archived_books,k=>k.bookId == bk.id||k.bookId == bk.bookId))){
            return true;
        }else{
            return false;
        }
        
    }
    const onArchiveDone = async() =>{
        setOnArchiveloading(true);
        let archive_book = [];
        const archived_books = get(currentClass,"archived_books",[])
        if(isArchive){ 
            archive_book = filter(archived_books,k=>!find(booksToArchiveDearchive,j=>k.bookId == j.id||k.bookId == j.bookId))
        }else{
            const dt = map(booksToArchiveDearchive,k=>({bookId:k.id,description:k.description}))
            archive_book  = uniqBy([...archived_books,...dt],"bookId")
        }
       await dispatch(handleHideShowClassBooks(currentClass.userBookId,archive_book,currentClass.id))
       setOnArchiveloading(false);
       setArchiveToggle(false)
    }
    const orderedBook = mapBookByOrder(chaptersForUser, bookOrder)
    const hiddenOrderedBook = mapBookByOrder(getArchivedBooks(), bookOrder);

    const handleHideShowBook = async (bookId, value) => {
      try {
        setLoading({bookId, loading: true});
        const payload = {
          id: currentClass.userBookId,
          bookId,
          action: value ? "show" : "hide",
        };
        await dispatch(
          handleHideOrShowParticularClassBooks(payload, currentClass?.id)
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading({bookId: "", loading: false});
      }
    };

      const HiddenBook = ({ chapter }) => {
        const isLoading = loading?.bookId === chapter?.id 
        return (
          <>
            {get(currentUser, "role") == "teacher" && (
              <>
                {isLoading ? (
                  <AntButton
                    className={"show-hide-book-home"}
                    icon={
                      <Loading
                        style={{ width: "20px", height: "20px", marginInline: "auto" }}
                      
                      />
                    }
                  />
                ) : (
                  <button
                    disabled={isLoading}
                    className="show-hide-button"
                    onClick={(event) => {
                     event.stopPropagation();
                      handleHideShowBook(
                        chapter.id,
                        hiddenBook.includes(chapter?.id) ? true : false
                      )}
                    }
                  >

                    {hiddenBook?.includes(chapter?.id) ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )
                    }
                  </button>
                )}
              </>
            )}
          </>
        );
      };
    return (
        <Wrapper>
            <>
                <DemoModal
                    open={modalOpen}
                    handleClose={() => toggleModal(false)}
                    message={currentOrg?.demo ? 'Not available in demo' : 'Chapter is currently under maintenance'}
                />
                  <DeleteModal
                        deleteToggle={archiveToggle}
                        setDeleteToggle={setArchiveToggle}
                        loading={onArchiveLoading}
                        message={isArchive?`Are you sure you want to show  these books in  class?`:`If you hide these books, all assignments, grades, notes, and any changes made to the books will be hidden. Are you sure you want to hide these books?
                        `}
                        buttonTitle={isArchive?"Show":"Hide"}
                        onDelete={onArchiveDone}
                        colorSchema={isArchive?"primary":"secondary"}
                    />
                {get(currentUser,"role")=='teacher'&&(get(currentClass,"archived_books.length",0)>0||showHiddentBooks)&& <Button color={"primary"} size="small" style={{marginBottom:16}} variant="contained"  onClick={()=>{setShowHiddentBooks(!showHiddentBooks)}} >{showHiddentBooks?"Show books": "Show hidden books"}</Button>}
                
                {/* // show all archive book that hide by teacher */}
                {showHiddentBooks && (get(currentClass,"archived_books.length",0)>0||showHiddentBooks) && 
                <HiddenBookSection>
                    <Row>
                        <Tag height={20} bgColor={COLOR.redishpink} ><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                        <Note style={{ color: COLOR.black100 }}>These are hidden books.</Note>
                    </Row>
                    {map(hiddenOrderedBook, (bk, i) => {
                        const archived = isHiddentBook(bk.books)
                        return (<Section key={i}>
                            {bk.title ? <SectionTitle bgColor={bk.color}>{bk.title}</SectionTitle> : null}
                            {hasClass && get(currentUser,"role")=='teacher'  && <Button onClick={() => {
                                setBooksToArchiveDearchive(bk.books)
                                setIsArchive(archived)
                                setArchiveToggle(true)
                            }} variant="contained" style={{ position: "absolute", top: "-10px", right: "10px" }} color="primary">{archived ? "Show" : "Hide"}</Button>}
                            <List>
                                {map(bk.books, chapter => {
                                    if (!chapter || !chapter.id) return null;
                                    return (
                                        <Tooltip color='black' title={`${get(currentUser,"role")=='student' && hiddenBook.includes(chapter?.id) ? "The book is hidden by the teacher." : "" }`}>
                                        <ListItem style={{opacity: hiddenBook?.includes(chapter?.id) ? "0.6" : "1"}} key={chapter?.id} className='home-view-book'>  
                                            <ChapterCardBook data={chapter} handleClick={() => { }} />
                                            <HiddenBook chapter={chapter} />
                                            <div className="bookName"><Link>{getBookDesc(chapter.id)}</Link></div>
                                        </ListItem>
                                        </Tooltip>
                                    )
                                })}
                            </List>
                        </Section>)
                    })}
                </HiddenBookSection>
                }
    
                {!showHiddentBooks? map(orderedBook, (bk,i) => { 
                    const archived  = isHiddentBook(bk.books)
                    return(<Section key={i}>
                    {bk.title ? <SectionTitle bgColor={bk.color}>{bk.title}</SectionTitle> : null}
                   {hasClass&&get(currentUser,"role")=='teacher'&&<Button onClick={()=>{
                        setBooksToArchiveDearchive(bk.books)
                        setIsArchive(archived)
                        setArchiveToggle(true)
                    }} variant="contained" style={{position:"absolute",top:"-19px",  right:"8px"}} color="primary">{archived?"Show":"Hide"}</Button>}
                    <List>
                       
                        {map(bk.books, chapter => {
                            if (!chapter || !chapter.id) return null;
                            if(get(currentUser, "role") == "student" && hiddenBook?.includes(chapter?.id)){
                                return null;
                            }
                            return (
                                <Tooltip color='black' title={`${get(currentUser,"role")=='student' && hiddenBook.includes(chapter?.id) ? "The book is hidden by the teacher." : "" }`}>
                                <ListItem style={{opacity: hiddenBook?.includes(chapter?.id) ? "0.6" : "1"}} className='home-view-book' key={chapter?.id}>
                                    <ChapterCardBook  data={chapter} handleClick={() => {
                                        if(hiddenBook.includes(chapter?.id) && get(currentUser,"role")=='student'){
                                            return;
                                        }
                                        handleSetChapter(chapter.id)
                                    }} />
                                    <HiddenBook chapter={chapter} />
                                    <div className="bookName"><Link>{getBookDesc(chapter.id)}</Link></div>
                                </ListItem>
                                </Tooltip>
                            )
                        })}
                    </List>
                </Section>)}):null}
            </>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ChapterManagementBook.propTypes = {};

//  -- Default props ------------------
ChapterManagementBook.defaultProps = {};

export default ChapterManagementBook;
