import { API } from 'aws-amplify';
import { map } from 'lodash';
import { parse } from 'query-string';
import config from '../../config';

//  -- Thunks -------------------------
import { handleCreateLog } from 'store/logs/thunks';

const crypto = require('crypto')
const SCHOOLOGY_API_HOST = 'https://api.schoology.com'
const REALM_PARAM = { 'OAuth realm': 'Schoology API' }
const Token = {
  oauth_token: config.schoology.consumerKey,
  oauth_token_secret:config.schoology.secrateKey,
}
const SIGNATURE_METHOD = 'HMAC-SHA1' | 'PLAINTEXT'
const defaultMessage = {
  subject: '',
  message: '',
  recipient_ids: '',
}
const headerFormat = (components) => {
  const parts = []
  Object.keys(components).forEach((key) =>
    parts.push(key + '="' + components[key] + '"'),
  )
  return parts.join(',')
}

const baseStringFormat = (components) => {
  const parts = []
  Object.keys(components).forEach((key) =>
    parts.push(key + '=' + components[key]),
  )
  return parts.join('&')
}

class SchoologyAPI {
  constructor(auth) {
    this.client_key = Token.oauth_token
    this.client_secret = Token.oauth_token_secret
    this.api_base = `${SCHOOLOGY_API_HOST}/v1`
    if (auth && typeof auth === 'object') {
      this.setToken(auth)
    }
  }

  getAuthHeaderComponents = (signatureMethod = 'PLAINTEXT', token = '') => {
    const nonce = crypto.randomBytes(16).toString('base64')
    const timestamp = Math.round(new Date().getTime() / 1000)
    return {
      oauth_consumer_key: this.client_key,
      oauth_nonce: nonce,
      oauth_signature_method: signatureMethod,
      oauth_timestamp: timestamp,
      oauth_token: token,
      oauth_version: '1.0',
    }
  }

  getUnsignedAuthHeader = () =>
    headerFormat({
      ...REALM_PARAM,
      ...this.getAuthHeaderComponents(),
      oauth_signature: this.client_secret + '%26',
    })

  getPlaintextAuthHeader = () => {
    const authHeaderComponents = this.getAuthHeaderComponents(
      'PLAINTEXT',
      this.oauth_token,
    )

    const key = [this.client_secret, this.oauth_token_secret].join('&')

    return headerFormat({
      ...REALM_PARAM,
      ...authHeaderComponents,
      oauth_signature: key,
    })
  }

  getSignedAuthHeader = (method, url) => {
    const authHeaderComponents = this.getAuthHeaderComponents(
      'HMAC-SHA1',
      this.oauth_token,
    )

    const baseString = [
      method.toUpperCase(),
      url,
      baseStringFormat(authHeaderComponents),
    ]
      .map(encodeURIComponent)
      .join('&')

    const key = [this.client_secret, this.oauth_token_secret].join('&')

    const signature = crypto
      .createHmac('sha1', key)
      .update(baseString)
      .digest('base64')

    return headerFormat({
      ...REALM_PARAM,
      ...authHeaderComponents,
      oauth_signature: signature,
    })
  }

  setToken = (token) => {
    if (token && typeof token === 'object') {
      this.oauth_token = token.oauth_token
      this.oauth_token_secret = token.oauth_token_secret
    }
  }

  getRequestToken = () => {
    const Authorization = this.getUnsignedAuthHeader()
    return API.post('schoologyRDS', `makeRequest`, {
      body: {
        headers: { Authorization },
        method: 'get',
        url: this.api_base + '/oauth/request_token',
      },
    }).then((data) => {
      const token = parse(data)

      this.setToken(token)

      return token
    })
  }

  makeRequest = async (method, path, data) => {
    const url = this.api_base + path
    const authHeader = this.getPlaintextAuthHeader()
    try {
		const apiResponse = API.post('schoologyRDS', `makeRequest`, {
			body: {
			headers: {
				Authorization: authHeader,
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
			},
			method,
			url,
			data,
			},
		})

		if (config.logging.schoologySuccess) {
			// This will register the error in the table LogsRequest in order to store all the failed requests
			handleCreateLog({
				requestType: method,
				endPoint: this.api_base,
				statusCode: '',
				method: path,
				process: 'makeRequest',
				requestHeaders: JSON.stringify({Authorization: authHeader, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}),
				requestBody: JSON.stringify(data),
				responseHeaders: '',
				responseBody: JSON.stringify(apiResponse),
				responseMessage: '',
			})(()=>{});
		}

		return apiResponse;
    } catch (error) {
		if (config.logging.schoologyFailed) {
			// This will register the error in the table LogsRequest in order to store all the failed requests
			handleCreateLog({
				requestType: method,
				endPoint: this.api_base,
				statusCode: '',
				method: path,
				process: 'makeRequest',
				requestHeaders: JSON.stringify({Authorization: authHeader, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}),
				requestBody: JSON.stringify(data),
				responseHeaders: '',
				responseBody: '',
				responseMessage: JSON.stringify(error),
			})(()=>{});;
		}
      return
    }
  }

  getAccessToken = (requestToken) => {
    requestToken && this.setToken(requestToken)

    return this.makeRequest('get', '/oauth/access_token').then((response) => {
      const token = parse(response)
      localStorage.setItem('token', JSON.stringify(token))
      this.setToken(token)

      return token
    })
  }

  getUserInfo = (accessToken) => {
    accessToken && this.setToken(accessToken)

    return this.makeRequest('get', '/app-user-info')
  }
  getUsers = () => {
    return this.makeRequest('get', `/users`)
  }
  getUserInfoById = (userId) => {
    return this.makeRequest('get', `/users/${userId}`)
  }
  getSchoolInfoById = (schoolId) => {
    return this.makeRequest('get', `/schools/${schoolId}`)
  }
  getClassById = (classId) => {
    return this.makeRequest('get', `/classes/${classId}`)
  }
  getGradesOfUser = (userId) => {
    return this.makeRequest('get', `/users/${userId}/grades`)
  }
  getSectionAssignment = (sectionId,limit) => {
    return this.makeRequest('get', `/sections/${sectionId}/assignments?limit=${limit?limit:300}`)
  }
  getSectionAssignmentWithLimit = (sectionId,limit) => {
    return this.makeRequest('get', `/sections/${sectionId}/assignments?limit=${limit?limit:300}`)
  }
  getSectionAssignmentById = (sectionId, assignmentId) => {
    return this.makeRequest(
      'get',
      `/sections/${sectionId}/assignments/${assignmentId}`,
    )
  }
  createAssignment = (sectionId, data) => {
    return this.makeRequest('post', `/sections/${sectionId}/assignments`, data)
  }
  updateAssignment = (sectionId, assignmentId, data) => {
    return this.makeRequest(
      'put',
      `/sections/${sectionId}/assignments/${assignmentId}`,
      data,
    )
  }
  unassignAssignment = (sectionId, assignmentId) => {
  const data = {
    "published": 0
  }
    return this.makeRequest(
      'put',
      `/sections/${sectionId}/assignments/${assignmentId}`,
      data,
    )
  }
  deleteAssignment = (sectionId, assignmentId) => {
    return this.makeRequest(
      'delete',
      `/sections/${sectionId}/assignments/${assignmentId}`,
    )
  }
  updateGrades = (sectionId, gradesData) => {
    // let gradesData =JSON.stringify({
    //   grades: {
    //     grade: [
    //       {
    //         type: "assignment",
    //         assignment_id: assignmentId,
    //         enrollment_id: enrollmentId,
    //         grade: grade,
    //         comment: comments,
    //       },
    //     ],
    //   },
    // });
    return this.makeRequest('put', `/sections/${sectionId}/grades`, gradesData)
  }
  getEnrollmentsBySectionId = (sectionId) => {
    return this.makeRequest('get', `/sections/${sectionId}/enrollments?limit=150`)
  }
  getEnrollmentsByClassId = (classId) => {
    return this.makeRequest('get', `/classes/${classId}/enrollments`)
  }
  getSectionByUserId = (userId,limit) => {
    return this.makeRequest('get', `/users/${userId}/sections?limit=${limit?limit:100}`)
  }
  getGradingCateogryBySectionId = (sectionId,limit) => {
    return this.makeRequest('get', `/sections/${sectionId}/grading_categories?limit=${limit?limit:100}`)
  }
  createGradingCategory = (sectionId, data) => {
    let _data = {
      grading_categories: {
        grading_category: [
          {
            title: data.title,
            weight: data.weight || 100,
          },
        ],
      },
    }
    return this.makeRequest(
      'post',
      `/sections/${sectionId}/grading_categories`,
      _data,
    )
  }
  getGradingCategoryById = (sectionId, gradingCategoryId) => {
    return this.makeRequest(
      'get',
      `/sections/${sectionId}/grading_categories/${gradingCategoryId}`,
    )
  }
  getGradingScaleBySectionId = (sectionId,limit) => {
    return this.makeRequest('get', `/sections/${sectionId}/grading_scales?limit=${limit?limit:100}`)
  }
  getGradeCategory = async (schoologyid) => {
    let gradeCat = {
      sectionId: '',
      categories: [],
    }
    try {
      const res = await this.getSectionByUserId(schoologyid)
      const sections = res?.section
      return Promise.all(
        map(sections, async (s) => {
          if (gradeCat.sectionId && gradeCat.sectionId.length > 0) return false
          let caltegories = await this.getGradingCateogryBySectionId(s.id)
          if (
            typeof caltegories == 'object' &&
            Array.isArray(caltegories?.grading_category)
          ) {
            gradeCat.sectionId = s.id
            gradeCat.categories = caltegories?.grading_category
            return gradeCat
          } else {
            return false
          }
        }),
      ).then((res) => {
        return res.filter((k) => k)[0]
      })
    } catch (error) {
      console.log(error, '1')
      return gradeCat
    }
  }
  getSectionGrades = (sectionId,limit) => {
    return this.makeRequest('get', `/sections/${sectionId}/grades?limit=${limit?limit:300}`)
  }
  createMessages = async (data = defaultMessage) => {
    return this.makeRequest('post', '/messages', data)
  }
  getRoleById =async (roleId) => {
    return this.makeRequest('get', `/roles/${roleId}`)
  }
}

export default SchoologyAPI
