//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const cleverCoursesSelector = (state) => state.cleverCourses;
const cleverUserInfoSelector = (state) => state.cleverUserInfo;
//  -- Examples array selector --------

export const useCleverCources = () => {
    const selectedData = useSelector(cleverCoursesSelector, shallowEqual)
    return selectedData;
}
export const useCleverUserInfo = () => {
    const selectedData = useSelector(cleverUserInfoSelector, shallowEqual)
    return selectedData;
}

