//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from "store/constants";

//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create activity ----------------
/*export const createActivity = () => ({ type: ActionTypes.activities.createActivity });
export const createActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.createActivityFail, payload: returnedPayload });
export const createActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.createActivitySuccess, payload: returnedPayload });

//  -- Get activity -------------------
export const getActivity = () => ({ type: ActionTypes.activities.getActivity });
export const getActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.getActivityFail, payload: returnedPayload });
export const getActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.getActivitySuccess, payload: returnedPayload });

//  -- List chapter activities --------
export const listChapterActivities = () => ({ type: ActionTypes.activities.listChapterActivities });
export const listChapterActivitiesFail = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesFail, payload: returnedPayload });
export const listChapterActivitiesSuccess = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesSuccess, payload: returnedPayload });*/

//  -- List books ----------------
export const listBooks = () => ({ type: ActionTypes.books.listBooks });
export const listBooksFail = (returnedPayload) => ({
  type: ActionTypes.books.listBooksFail,
  payload: returnedPayload,
});
export const listBooksSuccess = (returnedPayload) => ({
  type: ActionTypes.books.listBooksSuccess,
  payload: returnedPayload,
});

//  -- List All books ----------------
export const listAllBooks = () => ({ type: ActionTypes.books.listAllBooks });
export const listAllBooksFail = (returnedPayload) => ({
  type: ActionTypes.books.listAllBooksFail,
  payload: returnedPayload,
});
export const listAllBooksSuccess = (returnedPayload) => ({
  type: ActionTypes.books.listAllBooksSuccess,
  payload: returnedPayload,
});

export const createBooks = () => ({ type: ActionTypes.books.createBook });
export const createBooksFail = (returnedPayload) => ({
  type: ActionTypes.books.createBookFail,
  payload: returnedPayload,
});
export const createBooksSuccess = (returnedPayload) => ({
  type: ActionTypes.books.createBookSuccess,
  payload: returnedPayload,
});

export const updateBooks = () => ({ type: ActionTypes.books.updateBook });
export const updateBooksFail = (returnedPayload) => ({
  type: ActionTypes.books.updateBookFail,
  payload: returnedPayload,
});
export const updateBooksSuccess = (returnedPayload) => ({
  type: ActionTypes.books.updateBookSuccess,
  payload: returnedPayload,
});

export const addEditBooks = (returnedPayload) => ({
  type: ActionTypes.books.addEditBook,
  payload: returnedPayload,
});

export const setBookOrder = (returnedPayload) => ({
  type: ActionTypes.books.setBookOrder,
  payload: returnedPayload,
});
export const setBookOrderFail = (returnedPayload) => ({
  type: ActionTypes.books.setBookOrderFail,
  payload: returnedPayload,
});
export const setBookOrderSuccess = (returnedPayload) => ({
  type: ActionTypes.books.setBookOrderSuccess,
  payload: returnedPayload,
});

export const createActiveSuccess = (returnedPayload) => ({
  type: ActionTypes.booksActiveInactive.createBookActiveInactiveSuccess,
  payload: returnedPayload,
});
export const updateActiveSuccess = (returnedPayload) => ({
  type: ActionTypes.booksActiveInactive.updateBookActiveInactiveSuccess,
  payload: returnedPayload,
});
export const listAllActiveInActiveBooksSuccess = (returnedPayload) => ({
  type: ActionTypes.booksActiveInactive.listBooksActiveInactiveSuccess,
  payload: returnedPayload,
});
