//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
  width: 100%;
`;

const Text = styled.div`
  font-size: 12px;
  color: white;
  line-height: 17px;
  white-space: pre-line;
`;

const TextContainer = styled.div`
  /* border-top: 1px solid ${COLOR.black800}; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  padding: 0px 8px;
  background-color: #000f4c;
`;

const Wrapper = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  height: auto;
  outline: none;
  transition: all 300ms ease;
  width: 100%;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    opacity: 0.7;
  }
`;

const PodcastGrid = styled.div`
  width: 128.8px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-mini) 0 0;
  box-sizing: border-box;
  gap: var(--gap-mini-1);
  cursor: pointer;

  @media screen and (max-width: 464px) {
    width: 86.8px;
  }
`;

const PodcastImage = styled.img`
  width: 112.9px;
  height: 112.9px;
  position: relative;
  object-fit: cover;
  flex-shrink: 0;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  border-radius: 50%;
  margin-bottom: 14px;

  @media screen and (max-width: 464px) {
    width: 92.9px;
    height: 92.9px;
  }
  @media (min-width: 320px) and (max-width: 400px) {
    width: 76.9px;
    height: 76.9px;
    }
  }
`;

const PodcastText = styled.div`
color: #030A4E;

font-family: Avenir;
font-size: 13.8px;
font-style: normal;
font-weight: 500;
line-height: normal;`
const CoversCard = ({ data, handleClick, isPodcastImage=false }) => {
  return (
    <>
      <div onClick={handleClick} className="podcast-image-resource-container">
        {/* <img  className="podcast-image-resource clip-path-group2 image-select-none" src={data.src} /> */}
        <img 
        className={`clip-path-group2 image-select-none ${isPodcastImage ? "allApImgResource" : "podcast-image-resource"}`} 
        src={data.src} 
      />
        {/* <div
          style={{
            backgroundImage: `url(${data.src})`,
            backgroundSize: "100% 100%",
          }}
          className="podcast-image-resource clip-path-group2 image-select-none"
        /> */}
      </div>
      {/* <PodcastImage
          className="clip-path-group2 image-select-none"
          loading="lazy"
          alt=""
          src={data.src}
        /> */}
      {/* <PodcastGrid onClick={handleClick}>
        <PodcastImage
          className="clip-path-group2 image-select-none"
          loading="lazy"
          alt=""
          src={data.src}
        />

        <PodcastText>{data.displayName}</PodcastText>
      </PodcastGrid> */}

      {/* <Wrapper>
            <div className="card-resource">
                <a onClick={handleClick}>
                    <div className="card-resource-overlay cardSectionTwo"></div>
                    <Image className="card-resource-image cardSectionThree" src={data.src} alt={data.displayName} />
                    <div className="card-resource-details fadeIn-bottom cardSectionFour">
                        <h3 className="card-resource-title cardSectionFive">{data.displayName}</h3>
                        <p className="card-resource-text cardSectionSix">{data.description}</p>
                    </div>
                    <TextContainer className="allResourceCardName">
                      <Text>{data.displayName}</Text>
                    </TextContainer>
                </a>
            </div>
        </Wrapper> */}
    </>
  );
};

//  -- Prop types ---------------------
CoversCard.propTypes = {
  data: shape({
    img: string,
    title: string,
    description: string,
    activities: number,
    quizzes: number,
  }),
  handleClick: func,
};

//  -- Default props ------------------
CoversCard.defaultProps = {
  data: {},
};

export default CoversCard;
