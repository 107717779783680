//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const createInitTimmer = (returnedPayload) => ({ type: ActionTypes.timmer.createTimmerRunning });
export const clearTimmer = () => ({ type: ActionTypes.timmer.clearTimmerRunning });

//  -- List logs ----------------

