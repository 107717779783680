//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Check, Close, Flag, MoreVert } from '@material-ui/icons';
import { Dropdown, Menu } from 'antd';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//  -- Thunks -------------------------
import { handleUpdateCanvasGrade, handleUpdateCanvasStatus } from 'store/canvas/thunks';
import { handleListClassSubmissions, handleReturnSubmission, handleUpdateGrade, handleUpdateSubmission } from 'store/submissions/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { find, get, has, map } from 'lodash';
import { handleUpdateGradeToGoogleClassRoom } from "store/googleClassRoom/thunks";
import { useCurrentUser } from 'store/users/selectors';
import Schoology from 'utils/Schoology';
import COLOR from 'utils/constants/color';
import { handleCreateNotification } from 'store/notification/thunks';
import { useSocket } from 'store/chat/selectors';
import { isBeforTime } from 'utils/methods/math';
import { AbsentSvg , LateSvg } from "utils/assets/images"
const COLORSTATUS = ["", COLOR.greentrue, COLOR.orange100, COLOR.red ,"","",COLOR.yellow]
const Poligon = styled.div`
    display:flex;
    flex-direction:row;
    clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
    height: 18px;
    width: 18px;
    margin-right: 5px;
    background-color:${p => p.bgColor ? p.bgColor : COLOR.green};
`;

const ALLUSER_FLAG = [
  { title: "Excused", value: 1, action: true },
  { title: "Incomplete", value: 2, action: true },
  { title: "Missing", value:3, action: true },
];
const SCHOOLOGY_ALLUSER_FLAG = [
  { title: "Exempt", value: 1, action: true },
  { title: "Incomplete", value: 2, action: true },
  { title: "Missing", value:3, action: true },
  { title: "Absent", value:5, action: true },
  { title: "Late", value:6, action: true },
];
const CANVAS_FLAG = [
  { title: "Excused", value: 1, action: true },
  { title: "Missing", value:3, action: true }
];
const CANVAS_FLAG_WITH_COMPLETE = [
  { title: "Excused", value: 1, action: true },
  { title: "Missing", value:3, action: true },
  { title: "Complete", value:"complete", action: true },
  { title: "Incomplete", value:"incomplete", action: true }
];


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.button`
    align-items: center;
    background-color: ${COLOR.green100};
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    display: flex;
    height: 24px;
    margin-left: 4px;
    justify-content: center;
    opacity: 0;
    outline: none;
    padding: 2px;
    transition: all 300ms ease;
    width: 24px;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`;

const Input = styled.input`
    border: 1px solid;
    border-radius: 3px;
    box-shadow: none;
    font-size: inherit;
    outline: none;
    padding: 0px 4px;
    transition: all 300ms ease;
    text-align:center;
    width: 40px;
`;

const Form = styled.form`
    algin-items: center;
    color: ${p => p.graded ? 'inherit' : 'red'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;

    &:hover ${Input} {
        border: 1px solid ${p => p.disabled ? 'white' : 'white'};
    }

    &:hover ${Button} {
        opacity: ${p => p.disabled ? 0 : 1};
        color: white;
    }
`;
const CheckBox = styled.div`
    align-items: center;
    height: 24px;
    display: flex;
    justify-content: center;
    width: 24px;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    transition: all 300ms ease;
    `;
const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    `;
const TbgArea = styled.div`
    position: absolute;
    bottom: -12px;
    font-size: 8px;
    color:${COLOR.black}
`;
const StatusText = styled.div`
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: ${p => p.color ? p.color : '#1b1e23'};
    font-weight: normal;
`;
const MaxPointText = styled.span`
color:${COLOR.gray100}
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Grade editing form
//
//  -- Description
//  Allows editing of grades/submissions
//
//  -- Props
//  ...
//
//  -- State
//  ...
//

const hasGradeInA_F = (val) => {
    if (typeof val === "string") {
        const hasInGrade = [{ key: 'A', value: 90 }, { key: 'B', value: 70 }, { key: 'C', value: 50 }, { key: 'D', value: 30 }, { key: 'F', value: 10 }].find(k => k.key.toLowerCase() === val.toLowerCase())
        if (hasInGrade) {
            return ({
                value: hasInGrade.value,
                hasValue: true
            })
        }
        return ({
            value: val,
            hasValue: false
        })
    } else {
        return ({
            value: val,
            hasValue: false
        })
    }

}


const GradeEditor = React.forwardRef(({
    assignmentId,
    data,
    reviewAssignment,
    submission,
    userId,
    value,
    isReviewAccount,
    disabled,
    scale_1_5,
    gradebook_a_f,
    tempData,
    assignment,
    user,
    currentClass,
    assignments,
    studentSubmissions,
    onFlagMenuViewChange,
    onFocus,
    onBlur,
    isTbg,
    isLate,
    ungraded,
    face_grade,
    submissions
}, ref) => {
    const dispatch = useDispatch();
    const { socketConnection, reCreateSocket } = useSocket();
    const schoologyAuth = useSelector(state => state.schoologyAuth);
    const client = new Schoology(schoologyAuth);
    const [tempValue, setTempValue] = useState(value);
    const [tempFlag, setTempFlag] = useState(submission?.exceptionStatus || 0);
    const currentUser = useCurrentUser()
    const inputRef = useRef()
    const [alertSakeBar, setAlertSakeBar] = useState({ open: false, message: '', severity: "success" })
    const [dialogOpenn, setDialogOpenn] = useState(false)
    const hasUser = useMemo(()=>has(user,"id"),[user]);
    const grade_type = assignment?.grade_type;
    const max_point = assignment?.max_point && !isNaN(assignment?.max_point) ? Number.parseInt(assignment.max_point, 10) : 100
    const isCompleteInComplete = grade_type === "complete_incomplete"
    const isPointSystem =  grade_type === "points"
    const editValue = () => {
        const input = document.getElementById(assignmentId + userId);
        input.focus();
        input.select();
    };
    const setVal = () =>{
        if (isPointSystem) {
            const val = ((typeof face_grade == "number" && face_grade >= 0) || (typeof face_grade == "string" && face_grade >= 0)) ? face_grade : null;
            setTempValue(val);
        } else {
            setTempValue(value);
        }
        setTempFlag(submission?.exceptionStatus || 0);
    }
    useEffect(() => {
        setVal()
    }, [value, submission, assignment,face_grade])
    const handleChange = (e) => {
        e.preventDefault();
        let _value = e.target.value;
        const af = hasGradeInA_F(_value)
        const isAf = af.hasValue && !!gradebook_a_f;
        const isScale = scale_1_5 && _value <= 4 && _value >= 0
        const isAv = !scale_1_5 && !gradebook_a_f && _value <= 100 && _value >= 0
        if (isPointSystem) {
            if (typeof max_point === "number") {
             
                if (_value >= 0 && _value <= max_point) {
                    setTempValue(_value);
                }
            } else {
                if (_value >= 0 && _value <= 100) {
                    setTempValue(_value);
                }
            }
        }
        else if (isScale || isAf || isAv || _value === "") {
                setTempValue(_value);
        }
    };
    const updateGradetoSchoology = (_val, statusCode) => {
        if (currentClass && currentClass.sectionId && user && user.schoologyid) {
            try {
                client.getEnrollmentsBySectionId(currentClass?.sectionId).then(async res => {
                    if (res && res.enrollment) {
                        let userEnrollment = find(res.enrollment, { uid: user.schoologyid });
                        if (!assignment.sAssignmentId) {
                            const _assignments = await client.getSectionAssignment(currentClass?.sectionId);
                            const all_assignment = await client.getSectionAssignmentWithLimit(currentClass?.sectionId, _assignments.total);
                            const assignmentf = find(all_assignment?.assignment, { title: assignment.displayName });
                            if (assignmentf) {
                                assignment.sAssignmentId = assignmentf.id;
                            }
                        }
                        if (userEnrollment && assignment.sAssignmentId) {
                            client.getSectionAssignmentById(currentClass?.sectionId, assignment.sAssignmentId).then(res => {
                                if (res && res.id) {
                                    let _data = {
                                        "grades": {
                                            "grade": [
                                                {
                                                    "type": "assignment",
                                                    "assignment_id": assignment.sAssignmentId,
                                                    "enrollment_id": userEnrollment.id,
                                                    "grade": _val,
                                                    "exception": statusCode || 0,
                                                    "comment": "grade updated from lausd-grade-book"
                                                }

                                            ]
                                        }
                                    }
                                    client.updateGrades(currentClass?.sectionId, _data).then(res => {
                                        console.log("updated grade");
                                    })
                                }
                            })
                        }
                    }
                })
            } catch (error) {
                console.log(error, "issue in update grade");
            }
        }
    }
    const updateGradetoGoogleClassRoom = async(_val) => {
        if (currentClass && currentClass.googleId && user && user.googleId) {
            try {
              await handleUpdateGradeToGoogleClassRoom(currentUser.googleId,user.googleId,currentClass.googleId,assignments.googleId,_val)
            } catch (error) {
                console.log(error, "issue in update grade to classroom");
            }
        }
    }
    const updateGradeToCanvas = async (_val, status) => {
        if (currentUser && currentUser.canvas_id && currentClass.canvas_id && assignments?.canvas_id) {
            if (status>=0) {
                await handleUpdateCanvasStatus(currentUser.canvas_id,{
                    "canvas_assignment_id":assignments.canvas_id,
                    "course_id":currentClass.canvas_course_id,
                    "canvas_user_id":user.canvas_id,
                    "status": status
                },currentUser.canvas_auth_id)
            } else {
                await handleUpdateCanvasGrade(currentUser.canvas_id,{
                    "canvas_assignment_id": assignments?.canvas_id,
                    "course_id":currentClass.canvas_course_id,
                    "canvas_user_id":user.canvas_id,
                    "grade": _val
                },currentUser.canvas_auth_id)
            }
        }
        }

        const returnNotification = (submission) => {
            const notificationData = {
                classId: get(currentClass, 'id', ''),
                displayName: get(submission, 'assignment.displayName', ''),
                userId: get(currentUser, 'id', ''),
                modified: new Date(),
                data: {
                    chapter: get(submission, 'assignment.chapter', ''),
                    page: get(submission, 'assignment.page', ''),
                    id: get(submission, 'assignment.id', ''),
                },
                message: 'Your Teacher Returned this Assignment',
                createdBy: get(submission, 'userId', ''),
                type: 'update_quiz',
                seen: true
            }
            sendNotifications(notificationData);
        }

    const returnAssignment = async () => {
        if (submission && submission.id) {
            if(submission.returned ===submission.submissions+1){
                setAlertSakeBar({ open: true, message: `Assignment already returned!`, severity: "error" });
                return;
            }else if(isDueDatePassed()){
                setAlertSakeBar({ open: true, message: `You can’t return this assignment because it is already past due. Please change the due date if you’d like to return the submission.`, severity: "error" });
                return;
            }else if(!submission.complete){
                setAlertSakeBar({ open: true, message: `You can’t return this assignment because it is in progress.`, severity: "error" });
                return;
            }
            const v = await dispatch(handleReturnSubmission(submission.id))
            if (v && v.id) {
                setAlertSakeBar({ open: true, message: `Assignment returned successfully`, severity: "success" });
                returnNotification(submission)
            } else {
                setAlertSakeBar({ open: true, message: `Having issue while returning Assignment`, severity: "error" })
            }
        } else {
            setAlertSakeBar({ open: true, message: `Assignment is not submitted by student.`, severity: "error" })
        }
    }
    const submit = async (e) => {
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(tempData, 'assignment.displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(tempData, 'assignment.chapter', ''),
                page: get(tempData, 'assignment.page', ''),
                id: get(tempData, 'assignment.id', ''),
            },
            message: `Your Teacher Graded this Assignment`,
            createdBy: get(tempData, 'userId', ''),
            type: 'update_grade',
            seen: true
        }
        e.preventDefault();
        e.stopPropagation();
        onBlur();
        let _val = tempValue;
        const hasInA_F = hasGradeInA_F(tempValue)
        if(isPointSystem){
            if((tempValue === face_grade && !isTbg)||tempValue ==="-"){
                return;
            }
             if(!(tempValue>=0&&tempValue<=max_point)){
                return;
             }
        }
        else if ((
            (tempValue === value && !isTbg) || tempValue ==="-" ||
            (!gradebook_a_f && isNaN(tempValue))) ||
            (scale_1_5 && tempValue > 4) ||
            (gradebook_a_f && !hasInA_F.hasValue) ||
            (!scale_1_5 && !gradebook_a_f && tempValue > 100)
        ) {
            setTempValue(value);
            return;
        }

        if (scale_1_5 && tempValue <= 4) {
            _val = tempValue == 0 ? tempValue : `${(tempValue * 20) + 10}`
        }
        if (gradebook_a_f && hasInA_F.hasValue) {
            _val = hasInA_F.value
        }
        if(isPointSystem){
            const per = (tempValue / max_point ) * 100;
            _val=per;
        }

        tempData.grade = _val;
        tempData.graded = true;
        tempData.manualGrading = false;
        tempData.submissions = 1
        tempData.face_grade = tempValue
        sendNotifications(notificationData);
        if(_val===null || _val==='' || _val ==='-' || _val ==='--') return
        updateGradeToCanvas(isPointSystem ? tempValue : _val)
        updateGradetoSchoology(isPointSystem ? tempValue : _val);
        updateGradetoGoogleClassRoom(isPointSystem ? tempValue : _val);
            if (get(submission, "id")) {
                const dt = {
                    id: submission.id,
                    grade: _val,
                    face_grade:tempValue
                }
                await dispatch(handleUpdateGrade(dt))
            } else {
                await dispatch(handleUpdateSubmission(submission?.id, {
                    answers: data?.answers,
                    complete: data?.complete,
                    grade: _val,
                    submissions: data?.submissions ? data?.submissions : 1,
                    retaking: data?.retaking || false,
                    graded: true,
                    feedback: data?.feedback || '',
                    manualGrading: false,
                    face_grade:tempValue
                }, tempData))

            }
            // setAlertSakeBar({ severity: "success", open: true, message: `Updated Successfully` })
            if (!submission?.id) {
                await dispatch(handleListClassSubmissions(tempData.classId));
            }
    };
    const updateStatus = async (status) => {
        tempData.grade = status == 3 ? 0 : null;
        tempData.graded = true;
        tempData.exceptionStatus = status;
        tempData.submissions = 1
        tempData.face_grade = tempValue
        let subCount = parseInt(get(data, "submissions", 1), 10);
        if (status == 0 && subCount > 0) {
            subCount = subCount - 1;
        } else if (status != 0 && subCount == 0) {
            subCount = subCount + 1;
        }
        if (get(submission, "id")) {
            const dt = {
                id: submission.id,
                grade: status == 3 ? 0 : null,
                exceptionStatus: status,
                submissions : subCount,
                face_grade : status == 3 ? 0 : null,
                manualGrading:false
            }
            await dispatch(handleUpdateGrade(dt))
        } else {
            await dispatch(handleUpdateSubmission(submission?.id, {
                answers: data?.answers,
                complete: data?.complete,
                grade: data?.grade,
                submissions: subCount,
                retaking: data?.retaking || false,
                graded: true,
                feedback: data?.feedback || '',
                manualGrading: false,
                exceptionStatus: status,
                face_grade:tempValue
            }, tempData))
        }
        await dispatch(handleListClassSubmissions(tempData.classId));
        // if(!submission?.id){
        //     await dispatch(handleListClassSubmissions(tempData.classId));
        // }
    }
    useImperativeHandle(ref, () => ({
        focus: () => {
            editValue()
        },
        blur: () => {
            inputRef.current.blur();
        },
        isFocused: () => {
            const input = document.getElementById(assignmentId + userId);
            return input === document.activeElement;
        },
        ref: inputRef.current
    }));

    const graded = () => {
        if (submission?.retaking) {
            return true;
        } else if (submission?.complete && submission?.graded) {
            return true;
        } else if (!submission) {
            return true;
        } else {
            return false;
        }
    }
    const invokSend = (data) => {
        let socket = socketConnection ?? '';
        if (socket) {
            try {
                if (socket.readyState == 3) {
                    reCreateSocket()
                    setTimeout(() => {
                        socket.send(JSON.stringify(data))
                    }, 2000)
                } else {
                    socket.send(JSON.stringify(data))
                }
            } catch (error) {
                console.log(error, "error")
            }
        }
    }
    const getNotificationStatus = () => {
        const message = get(tempData, 'exceptionStatus', '')
        if (message  == 1) {
           return `Your teacher turned this Assignment into ${currentUser.schoologyid?"Exempt":"Excused"}`
        } else if(message == 2){
            return `Your teacher turned this Assignment into Incomplete`
        } else if(message == 3){
            return `Your teacher turned this Assignment into Missing`
        }
    }
    const updateNotification = async () => {
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(tempData, 'assignment.displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(tempData, 'assignment.chapter', ''),
                page: get(tempData, 'assignment.page', ''),
                id: get(tempData, 'assignment.id', ''),
            },
            message: getNotificationStatus(),
            createdBy: get(tempData, 'userId', ''),
            type: 'update_quiz',
            seen: true
        }
        sendNotifications(notificationData)
    }


    const sendNotifications = async (notificationData) => {
        try {
            const updateNotifications = await dispatch(handleCreateNotification(notificationData))
            const messageCreate = {
                ...updateNotifications,
                senderId: get(currentUser, 'id', ''),
                receiverId: get(notificationData, 'createdBy', ''),
                // classId: get(currentClass, 'id', ''),
                code: 'system_notification'
            }
            invokSend(messageCreate)
        } catch (error) {
            console.log(error, "error")
        }
    }
    const updateStatusCode = (status) => {
        menuVisibalityChange(false);
        if (tempFlag == status) {
            setDialogOpenn(true);
        } else {
            updateStatus(status)
            setTempFlag(status);
            updateGradetoSchoology(status == 3 ? 0 : null, status)
            updateNotification()
            updateGradeToCanvas(status == 3 ? 0 : null, status)
        }
    }
    const onAreeRemoveException = () => {
        updateStatus(0)
        setTempFlag(0);
        setDialogOpenn(false);
        updateGradetoSchoology(0, 0)
        updateGradeToCanvas(0,0)
    };
    const getCheckIcon = (status,isCcomlete,isInComplete) => {
        if (tempFlag == status) {
            return <Check style={{ color: COLOR.green100, fontSize: 15 }} />
        }else if(isCcomlete&&isCompleteInComplete){
            return <Check style={{ color: COLOR.green100, fontSize: 15 }} />;
        }else if(isInComplete&&isCompleteInComplete){
            return <Check style={{ color: COLOR.green100, fontSize: 15 }} />;
        }
         else {
            return null;
        }
    }
    const isDueDatePassed = () => {
        if (!assignment) return false;
        const classConfig = find(assignment.classes, ['id', currentClass?.id]);
        const diffConfig = get(classConfig, 'diff');
        const isDiffStudent = find(get(diffConfig, 'student'), ['id', user?.id]);
        if (isDiffStudent) {
            return isBeforTime(diffConfig.dueDateDif);
        } else {
            return assignment.lateDate ? isBeforTime(assignment.lateDate) : isBeforTime(assignment.dueDate);
        }
    };
    const getCompleteInclompleteMark = ()=>{
        const isComplete= has(submission,"id")&& submission.grade>0;
        const isInComplete  = has(submission,"id")&& submission.grade==0;
        const isPastDue = isDueDatePassed()
        const isPassedIncomplete  = !has(submission,"id")&& isPastDue
          if(isComplete){
            return <Check style={{ color: COLOR.green100, fontSize: 18 }} />
          }else if(isInComplete || isPassedIncomplete ){
            return <Close style={{ color: COLOR.red, fontSize: 18 }} />
          }else{
            return <span  style={{ color: COLOR.gray100, fontSize: 18 }}>-</span>
          }
    }
    const getStatusIcon = useCallback((status, fromTab) => {
        if(isNaN(status)){
            if(status === "complete"){
               return  <Check style={{ color: COLOR.white, fontSize: 15 , marginRight:5 , marginTop:2 , backgroundColor:COLOR.green100 , borderRadius:20 }} />;
            }else if(status === "incomplete"){
                return  <Close style={{ color: COLOR.white , fontSize: 15 , marginRight:5 , marginTop:2 , backgroundColor:COLOR.red , borderRadius:20 }} />;
            }
        }if(status == 5){
            return <img src={AbsentSvg} style={{ marginRight: fromTab ? 0 : 5 , height : 20 }}/>
        }
        if(status == 6){
            return <img src={LateSvg} style={{ marginRight: fromTab ? 0 : 5 , height : 20 }}/>
        }
        else{
            return <Poligon bgColor={COLORSTATUS[status]} style={{ marginRight: fromTab ? 0 : 5 }} />
        }
    })
    const getStatusByCode = useCallback((status) => {
        if (status == 0) {
            return ''
        } else if (status == 1) {
            return currentUser.schoologyid ? "Exempt" : "Excused";
        } else if (status == 2) {
            return 'Incomplete'
        } else if (status == 3) {
            return 'Missing'
        } else {
            return ""
        }
    }, [])
    const menuVisibalityChange = (visiblity) => {
        onFlagMenuViewChange(visiblity)
    }
    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: '', severity: '' })
    }
    const updateCompleteStatus = async(v)=>{
         if(v === "complete" ||v === "incomplete" ){
            const gvalue =   v === "complete" ? 100:0
            const mx_val = v === "complete" ? max_point : 0
            if (get(submission, "id")) {
                const dt = {
                    id: submission.id,
                    grade: gvalue,
                    face_grade:mx_val
                }
                await dispatch(handleUpdateGrade(dt))
            } else {
                tempData.grade = gvalue;
                tempData.graded = true;
                tempData.manualGrading = false;
                tempData.submissions = 1
                tempData.face_grade = mx_val
                await dispatch(handleUpdateSubmission(null, {
                    answers: data?.answers,
                    complete: data?.complete,
                    grade:  gvalue,
                    submissions: data?.submissions ? data?.submissions : 1,
                    retaking: data?.retaking || false,
                    graded: true,
                    feedback: data?.feedback || '',
                    manualGrading: false,
                    face_grade:mx_val
                }, tempData))

            }
            if (!submission?.id) {
                await dispatch(handleListClassSubmissions(tempData.classId));
            }
            await handleUpdateCanvasGrade(currentUser.canvas_id,{
                "canvas_assignment_id": assignments?.canvas_id,
                "course_id":currentClass.canvas_course_id,
                "canvas_user_id":user.canvas_id,
                "grade": v,
            },currentUser.canvas_auth_id)
         }

    }
    const userNavForFLag = useCallback(() => {
        let FLAGS = [];
        if (currentUser.canvas_id) {
            if (isCompleteInComplete) {
                FLAGS = CANVAS_FLAG_WITH_COMPLETE;
            } else {
                FLAGS = CANVAS_FLAG;
            }

        } else if (currentUser.schoologyid) {
            FLAGS = SCHOOLOGY_ALLUSER_FLAG;
        } else {
            FLAGS = ALLUSER_FLAG
        }
      return (
        <Menu key={assignmentId + "aass"}>
          <Menu.Item onClick={() => {}}>MARK WITH AN EXCEPTION</Menu.Item>
          <hr color="gray" size="1" style={{ height: 1 }} />
          {map(FLAGS, (flg, i) => (
              <Menu.Item
                key={flg.value}
                onClick={() => {
                    if(typeof flg.value === "number"){
                        updateStatusCode(flg.value);
                    }else{
                        updateCompleteStatus(flg.value)
                    }
                 
                }}
              >
                <MenuItem>
                      <CheckBox>{getCheckIcon(flg.value, flg.value === "complete" && has(submission, "id") && submission.grade > 0, flg.value === "incomplete" && has(submission, "id") && submission.grade == 0)}</CheckBox>{" "}
                      {getStatusIcon(flg.value)} {flg.title}
                </MenuItem>
              </Menu.Item>
          ))}
        </Menu>
      );
    }, [tempFlag]);

    const visabilityNavForFLag = useCallback(() => {
        return (
            <Menu key={assignmentId + "nav"}>
                <Menu.Item onClick={() => {
                    reviewAssignment(getReviewSubmission())
                }}>
                    <MenuItem>
                        View Submission
                    </MenuItem>
                </Menu.Item>
                <Menu.Item disabled={!has(submission,"id")} onClick={() => {
                    returnAssignment();
                }}>
                    <MenuItem>
                        Return
                    </MenuItem>
                </Menu.Item>
            </Menu>
        );
    }, [submission,user,submissions]);

    const renderStatusText = useMemo(() => {
        if (!ungraded && submission && Object.keys(submission || {}).length > 0 && parseInt(get(submission, "submissions")) == (parseInt(get(submission, "returned", 0)) - 1)) {
            // const returned_Count = get(submission, "returned_at.length", 1)
            return (<StatusText>Returned
                {/* ({returned_Count==0?1:returned_Count}) */}
            </StatusText>)
        }
        else if (ungraded && submission && Object.keys(submission).length > 0) {
            return (<StatusText>Turned in</StatusText>)
        }
        else if (tempFlag > 0) {
            return (<StatusText color={COLORSTATUS[tempFlag]}>{getStatusByCode(tempFlag)}</StatusText>)
        } else if (isTbg && !ungraded) {
            return (<StatusText>TBG</StatusText>)
        }
        else if (isLate && !ungraded) {
            return (<StatusText>Done late</StatusText>)
        }
        else if (!ungraded && parseInt(get(submission, "submissions")) > 1 || (parseInt(get(submission, "returned", 0)) > 0 && parseInt(get(submission, "submissions")) == 1)) {
            return (<StatusText>Resubmitted
                {/* ({get(submission, "pastSubmissions.length")}) */}
            </StatusText>)
        }
        else if (ungraded) {
            return (<StatusText>Ungraded</StatusText>)
        }
        else {
            return null;
        }

    }, [tempFlag, isTbg, ungraded, isLate, submission])
    const isDisable = useMemo(() => (value === '--' || isReviewAccount || disabled || tempFlag > 0), [value, isReviewAccount, disabled, tempFlag]);
    const getReviewSubmission = useCallback(() => {
        if(has(submission,"id")){
            submission.user = user;
            if(!has(submission,"assignment")){
                submission.assignment = get(assignments,"data");
            }
            submission.ungraded = ungraded;
            return submission
        }else{
            tempData.user = user;
            if(!has(tempData,"assignment")){
                tempData.assignment = get(assignments,"data");
            }
            tempData.ungraded = ungraded;
            return tempData;
        }
    }, [submission, tempData,user , ungraded , submissions ]);
   const isDisabled  =  (disabled && tempFlag <= 0 )|| !hasUser

    const getValue = () => {
        if (ungraded) return "-";
        else if (tempFlag == 0 || !tempFlag) {
            if (tempValue === null || tempValue === "NULL") {
                return '-';
            }
            return (typeof tempValue === "number" && tempValue >= 0) || (typeof tempValue === "string") ? tempValue : '-';
        } else if (tempFlag == 3) {
            return '0';
        } else {
            return "--";
        }
    }
    return (<>
        <Dialog
            open={dialogOpenn}
            onClose={() => setDialogOpenn(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ backgroundColor: "transparent" }}
        >
            <DialogTitle id="alert-dialog-title">{"Remove this flag?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Once you remove this flag, student submission will be deleted.
                    Are you sure you want to remove the flag?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogOpenn(false)} color="primary" type="button" style={{ opacity: 1, width: 100, backgroundColor: COLOR.green1000 }}  >
                    Disagree
                </Button>
                <Button onClick={onAreeRemoveException} color="secondary" autoFocus style={{ opacity: 1, width: 100, backgroundColor: COLOR.red }}>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertSakeBar.open}
            autoHideDuration={3000}
            onClose={closeAlert}>
            <Alert onClose={() => { setAlertSakeBar({ open: false, message: '' }) }} severity={alertSakeBar.severity}>
                <strong>ALERT:</strong> {alertSakeBar.message}
            </Alert>
        </Snackbar>
        <Form
            disabled={isDisabled}
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();

                const input = document.getElementById(assignmentId + userId);
                input.blur();
            }}
            graded={graded()}
        >
             <Dropdown overlay={userNavForFLag()}
                placement="bottomLeft" trigger={['hover']}
                onOpenChange={menuVisibalityChange}
                disabled={isDisabled || ungraded }
            >
                {tempFlag > 0 ?
                    <Button 
                    disabled={isDisabled || ungraded }
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                    }} style={{ marginRight: 5, opacity: 1, backgroundColor: "transparent", color: COLOR.black }} >
                        {getStatusIcon(tempFlag, true)}
                    </Button>
                    : <Button 
                     disabled={isDisabled || ungraded }
                     onClick={e => {
                        e.preventDefault()
                    }} style={{ marginRight: 5 }}>
                        <Flag style={{ fontSize: '16px' }} />
                    </Button>}
            </Dropdown> 
            {!isCompleteInComplete ? <Input
                disabled={isDisable || !hasUser || ungraded || isCompleteInComplete}
                id={assignmentId + userId}
                onBlur={(e) => {
                    if (e.target.value === '' || e.target.value === null) {
                        e.target.value = getValue()
                        setVal()
                        return;
                    }
                    submit(e)
                }}
                onClick={editValue}
                onChange={handleChange}
                onFocus={e => {
                    if (e.target.value === '-') {
                        e.target.value = '';
                    }
                    onFocus(e)
                }}
                className='cell-input'
                ref={inputRef}
                value={getValue()}
                style={{ color: 'black' }}
            /> : <CheckBox>{getCompleteInclompleteMark()}</CheckBox>}
            {!isCompleteInComplete ? renderStatusText :null}
            {isPointSystem?<MaxPointText>{`/${max_point}`}</MaxPointText>:null}
            <Dropdown overlay={visabilityNavForFLag()}
                placement="bottomLeft" trigger={['click']}
                disabled={isDisabled}
            >
                <Button disabled={isDisabled} onClick={(e) => e.preventDefault()} type="button">
                    <MoreVert style={{ fontSize: '16px' }} />
                </Button>
            </Dropdown>
        </Form>
    </>
    );
});

//  -- Prop types ---------------------
GradeEditor.propTypes = {};

//  -- Default props ------------------
GradeEditor.defaultProps = {};

export default GradeEditor;
