import { Button, FormHelperText, IconButton, Modal, makeStyles } from '@material-ui/core';
import { Add, Close, Delete, Edit } from "@material-ui/icons";
import PersonIcon from '@material-ui/icons/Person';
import { Col, Row } from 'antd';
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import { DeleteModal } from 'components/Dialog';
import Loading from 'components/Loading';
import { filter, get, has } from "lodash";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useShareNotification } from 'utils/methods/firebaseShareAssignment';

const ModalContent = styled.div`
    left: 50%;
    width:50vw;
    padding: 0px 24px;
    position: absolute;
    min-height:350px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color:#fff;
    padding:20px;
     @media only screen and (max-width: 600px) {
        width: 90%;
    }
`;
const ModalConfigArea = styled.div`
    min-height:75vh;
    max-height:90vh;
    overflow:auto;
    margin-top:10px;
`;

const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const EditSentenceStarter = ({ editResourseModal, onCloseModal, item }) => {
    const [poster, setPoster] = useState('')
    const [loading, setloading] = useState(false)
    const [error, setError] = useState([])
    const { createSentenceStarter } = useShareNotification()

    useEffect(() => {
        if (item) {
            setPoster(get(item, "url", ''))
            setError([])
        } else {
            setPoster('')
        }
    }, [item])

    const onCreateOrUpdate = async () => {
        if (poster) {
            const SentenceStarter = {
                id: 'admin_sentence',
                created_at: new Date(),
                url: poster
            }
            createSentenceStarter(SentenceStarter)
            onCloseModal()
        }
    }
    return (<Modal
        open={editResourseModal}
        onClose={() => { }}
        style={{ height: '100%', width: '100%' }}
    >
        <ModalContent>
            <Row style={{ justifyContent: "space-between" }}>
                <TitleText>{has(item, "id") ? 'Update Sentence Starter' : 'Add Sentence Starter'}</TitleText>
                <IconButton onClick={onCloseModal} style={{ padding: 0 }}>
                    <Close color="secondary" />
                </IconButton>
            </Row>
            <ModalConfigArea>
                <Row className='allStarterSection'>
                    <Col lg={16} xs={24} style={{ paddingLeft: 10, paddingTop: 10 }}>
                        <PageImagePicker
                            src={poster}
                            path={`/poster}`}
                            style={{ height: 270, marginBottom: 24 }}
                            onChange={item => {
                                setPoster(item.url)
                                setError(filter(error, e => e.key != "poster"))
                            }}
                            hasDescription={false}
                        />
                        {error.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                            {error.map(v => (<FormHelperText key={v.key} error>
                                * {get(v, "message")}
                            </FormHelperText>))}
                        </Row> : null}
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                            <Button disabled={loading} fullWidth onClick={onCreateOrUpdate} variant="contained" color="primary">
                                {loading ? <Loading white size={15} /> : null}
                                {has(item, "id") ? "Update" : "Create"} </Button>
                        </Row>
                    </Col>
                </Row>
            </ModalConfigArea>
        </ModalContent>
    </Modal>)
}

export default function SentenceStarter() {
    const [loading, setLoading] = useState(false)
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [sentenceData, setSentenceData] = useState({})
    const {deleteSentenceData, listenToSentenceData} = useShareNotification()
    const [videoModalInfo, setVideoModalInfo] = React.useState({
        open: false,
        item: null
    });

    useEffect(() => {
        const unsubscribe = listenToSentenceData("admin_sentence", (data) => {
            setSentenceData(data?.admin_sentence);
        });
        return () => unsubscribe();
    }, []);

    const onAdd = () => {
        setVideoModalInfo({ open: true, item: {} })
    }
    const handleEdit = (item) => {
        setVideoModalInfo({ open: true, item: item })
    }
    const onDeleteItem = async () => {
        if (has(sentenceData, "id")) {
            setLoading(true)
            await deleteSentenceData(sentenceData?.id)
            setLoading(false)
        }
        setDeleteToggle(false)
    }

    return (
        <>
            <EditSentenceStarter
                editResourseModal={videoModalInfo.open}
                item={sentenceData}
                onCloseModal={() => setVideoModalInfo({ open: false, item: null })}
            />
            <DeleteModal
                deleteToggle={deleteToggle}
                setDeleteToggle={setDeleteToggle}
                onDelete={onDeleteItem}
                message={`Are you sure you want to delete ?`}
                loading={loading} />
            <Button variant="contained" color="primary" style={{ marginTop: 24 }} onClick={onAdd} disabled={sentenceData?.id}>
                <Add /> Add Sentence Starter
            </Button>
            <Row className="learnContentUpr" gutter={[25, 0]}>
                <Col xs={24} sm={24} md={24}>
                    <div className="learnContent">
                        <div className='myCategorys'>
                            {sentenceData?.id && (
                                <div className='myCategoryList OneWayVideoCover'>
                                    <div className=" card-resource ShowDetailBnr">
                                        <div className="btnsGrpMain">
                                            <div className="btnsGrpTxt" style={{ justifyContent: "center" }}>
                                                <Button className="btnsTxtYellow" onClick={() => handleEdit(sentenceData)} ><Edit style={{ fontSize: 20, marginRight: 3 }} />Edit</Button>
                                                <Button style={{ marginLeft: 10 }} variant="contained" color="secondary" onClick={() => {
                                                    setDeleteToggle(true)
                                                }} ><Delete style={{ fontSize: 20, marginRight: 3 }} />Delete</Button>
                                            </div>
                                        </div>
                                        <img src={sentenceData?.url} alt="" /></div>
                                    <div className='SentenceBtnTxt'>
                                        <div className='sentence-starter-txt'>Sentence Starter</div>
                                        <div className='btm1Category'>
                                            <div className='persionIconImg'>
                                                <PersonIcon className='iconClrChange' />
                                                <span>{"ONE WAY EDUCATION"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}


