//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create class quiz --------------
export const createClassQuiz = () => ({ type: ActionTypes.classQuizzes.createClassQuiz });
export const createClassQuizFail = (returnedPayload) => ({ type: ActionTypes.classQuizzes.createClassQuizFail, payload: returnedPayload });
export const createClassQuizSuccess = (returnedPayload) => ({ type: ActionTypes.classQuizzes.createClassQuizSuccess, payload: returnedPayload });

//  -- Delete class quiz --------------
export const deleteClassQuiz = () => ({ type: ActionTypes.classQuizzes.deleteClassQuiz });
export const deleteClassQuizFail = (returnedPayload) => ({ type: ActionTypes.classQuizzes.deleteClassQuizFail, payload: returnedPayload });
export const deleteClassQuizSuccess = (returnedPayload) => ({ type: ActionTypes.classQuizzes.deleteClassQuizSuccess, payload: returnedPayload });

//  -- List class quizzes -------------
export const listClassQuizzes = () => ({ type: ActionTypes.classQuizzes.listClassQuizzes });
export const listClassQuizzesFail = (returnedPayload) => ({ type: ActionTypes.classQuizzes.listClassQuizzesFail, payload: returnedPayload });
export const listClassQuizzesSuccess = (returnedPayload) => ({ type: ActionTypes.classQuizzes.listClassQuizzesSuccess, payload: returnedPayload });

//  -- Update class quiz --------------
export const updateClassQuiz = () => ({ type: ActionTypes.classQuizzes.updateClassQuiz });
export const updateClassQuizFail = (returnedPayload) => ({ type: ActionTypes.classQuizzes.updateClassQuizFail, payload: returnedPayload });
export const updateClassQuizSuccess = (returnedPayload) => ({ type: ActionTypes.classQuizzes.updateClassQuizSuccess, payload: returnedPayload });
