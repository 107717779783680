import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from "@material-ui/core";
import { Col, Row } from 'antd';
import { Logo_transparent, home_icon } from "utils/assets/images";
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const OuterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    // height: 100vh; // Ensure it takes the full height of the viewport
    // background-color: #f0f2f5; // Optional: Add background color for better contrast
`;
const Container = styled.div`
    background-color: rgba(255, 255, 255, 1);
    border-radius: 9px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    height: auto;
    max-width: 315px;
    padding: 22px 20px 22px;
    width: 100%;
`;
const ImageContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`;
const ImageContainers = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin:10px 0;
   @media screen and (max-width: 992px){
      margin-top: 35px !important;
   }
`;
const Paragraphs = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:17px;
    margin-bottom:20px;
    font-weight:300;

    @media (min-width: 992px) and (max-width: 1150px) {
        margin-right:10px;
    }
`;
const SSOPragraphs = styled.div`
    font-weight:600;
    text-align:center;
    font-size:18px;
    margin-top:25px;
    margin-bottom:10px;
`;
const BtnContainer = styled.div`
    display:flex;
    align-items:center;
`;
const ClassBtn = styled.button`
  background:rgb(0, 184, 54);
  min-width:170px;
  border:0;
  height:38px;
  border-radius:22px;
  margin-right:10px;
  margin-top:10px;
  color:white;
  font-weight:600;
    @media screen and (max-width: 568px){
        width:100% !important;
        margin-right:0 !important;
   }
`;
const ClassBtns = styled.button`
  min-width:170px;
  border:0;
  height:38px;
  border-radius:22px;
  margin-right:10px;
  margin-top:10px;
  color:black;
  font-weight:600;
  background:transparent;
  font-size:17px;
     @media screen and (max-width: 568px){
        width:100% !important;
   }
`;
const DocLinks = styled.p`
    font-size:16px;
    font-weight:600;
    margin:4px 0;
    text-align:center;
    cursor:pointer;
`;

const InnerContainer = styled.div`
    width: 90%;
    // max-width: 1500px; // Control the maximum width of the content
    // background-color: rgba(255, 255, 255, 1);
    // border-radius: 9px;
    // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    // padding: 22px 20px 22px;

    @media screen and (min-width: 1920px) {
        max-width: 1500px; 
    }
`;

// const ImageContainer = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `;

// const ImageContainers = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin: 10px 0;
//     @media screen and (max-width: 992px) {
//         margin-top: 35px !important;
//     }
// `;

// const Paragraphs = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 17px;
//     margin-bottom: 20px;
//     font-weight: 300;
//     // text-align: center; // Ensure the text is centered
// `;

// const SSOPragraphs = styled.div`
//     font-weight: 600;
//     text-align: center;
//     font-size: 18px;
//     margin-top: 25px;
//     margin-bottom: 10px;
// `;

// const BtnContainer = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center; // Center the buttons
// `;

// const ClassBtn = styled.button`
//     background: rgb(0, 184, 54);
//     min-width: 170px;
//     border: 0;
//     height: 38px;
//     border-radius: 22px;
//     margin-right: 10px;
//     margin-top: 10px;
//     color: white;
//     font-weight: 600;
//     @media screen and (max-width: 568px) {
//         width: 100% !important;
//         margin-right: 0 !important;
//     }
// `;

// const ClassBtns = styled.button`
//     min-width: 170px;
//     border: 0;
//     height: 38px;
//     border-radius: 22px;
//     margin-right: 10px;
//     margin-top: 10px;
//     color: black;
//     font-weight: 600;
//     background: transparent;
//     font-size: 17px;
//     @media screen and (max-width: 568px) {
//         width: 100% !important;
//     }
// `;

// const DocLinks = styled.p`
//     font-size: 16px;
//     font-weight: 600;
//     margin: 4px 0;
//     text-align: center;
//     cursor: pointer;
// `;

const HomeScreen = () => {
    const history = useHistory();
    useEffect(() => {
        const search = queryString.parse(history.location.search);
        if (Object.keys(search).length > 0) {
            history.push(`/login${history.location.search}`);
        }
    }, [history]);

    return (
        <OuterContainer>
            <InnerContainer>
                <Row style={{ justifyContent: 'flex-end', marginBottom: 10 }} className='deathDeskBtn'>
                    <Button onClick={() => history.push('/login')} variant="contained" style={{ width: 170, background: 'rgb(0, 45, 117)', color: 'white', borderRadius: 22, height: 41, fontWeight: 600, textTransform: 'inherit' }}>
                        Login
                    </Button>
                </Row>
                <Row>
                    <Col xs={24} lg={24} xl={12}>
                        <ImageContainer>
                            <img alt='logo' style={{ height: '160px' }} src={Logo_transparent}></img>
                        </ImageContainer>
                        <Paragraphs style={{ paddingTop: 20 }}>
                            Discover the hub of immersive language instruction at NosotrosOnline®-your premier destination for all One
                            Way Education online access. Here, you'll find an integrated suite of digital learning tools designed to enhance
                            the educational experience. Dive into our online textbooks, explore a wealth of authentic resources, and utilize
                            teacher tools to track progress and achievements.
                        </Paragraphs>
                        <Paragraphs>
                            Join our vibrant online learning community and unlock a treasure trove of features that will elevate your language
                            courses, fostering connection, engagement, and a deeper understanding of the material.
                        </Paragraphs>
                        <SSOPragraphs>
                            SSO, Rostering, and Grade Transferring
                        </SSOPragraphs>
                        <Row wrap={true}>
                            <ClassBtn>Google Classroom</ClassBtn>
                            <ClassBtn>Clever</ClassBtn>
                            <ClassBtn>Classlink</ClassBtn>
                            <ClassBtn>Schoology</ClassBtn>
                            <ClassBtn>Canvas</ClassBtn>
                            <ClassBtns>and more...</ClassBtns>
                        </Row>
                    </Col>
                    <Col xs={24} lg={24} xl={12}>
                        <Row style={{ justifyContent: 'flex-end' }} className='deathMObBtn'>
                            <Button onClick={() => history.push('/login')} variant="contained" style={{ width: 170, background: 'rgb(0, 45, 117)', color: 'white', borderRadius: 22, height: 41, fontWeight: 600, textTransform: 'inherit' }}>
                                Login
                            </Button>
                        </Row>
                        <ImageContainers>
                            <img alt='logo' className='deathImg' src={home_icon}></img>
                        </ImageContainers>
                        <DocLinks onClick={() => history.push("/terms-conditions")}>
                            Terms and Conditions
                        </DocLinks>
                        <DocLinks onClick={() => history.push("/privacy-policy")}>
                            Data Privacy Policy
                        </DocLinks>
                        <DocLinks onClick={() => history.push("/privacy-policy")}>
                            Student Information Privacy Policy
                        </DocLinks>
                    </Col>
                </Row>
            </InnerContainer>
        </OuterContainer>
    );
};

export default HomeScreen;

