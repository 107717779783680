//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";
import { Col, Row } from 'antd';
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { Edit, Delete } from "@material-ui/icons"
import { IconButton, Modal, Button } from "@material-ui/core"
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;

const Text = styled.div`
    font-size: 12px;
    color: white;
    line-height: 17px;
    white-space: pre-line;
`;

const TextContainer = styled.div`
    /* border-top: 1px solid ${COLOR.black800}; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    padding: 0px 8px;
    background-color: #000f4c;
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`
const TopRow = styled(Row)`
    border: 0.5px solid #3c393957;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;


const CoversCard = ({ data, handleClick, onEditClick, onDelete }) => {
    const [deleteToggle, setDeleteToggle] = useState(false)
    return (<>
        <TopRow>
            <IconButton onClick={() => setDeleteToggle(true)} style={{ padding: 0 }}>
                <Delete color="secondary" />
            </IconButton>
            <IconButton onClick={onEditClick} style={{ padding: 0 }}>
                <Edit color="primary" />
            </IconButton>
        </TopRow>
        <Wrapper>
            <Modal open={deleteToggle} onClose={() => setDeleteToggle(false)}>
                <ModalContent>
                    <Text style={{ fontWeight: "bold", color: "black" }}>
                        <strong>{`Are you sure to remove  this resource?`}</strong>
                    </Text>
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setDeleteToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                if (onDelete) {
                                    onDelete(data.id)
                                }
                                setDeleteToggle(false)
                            }}
                        >
                            Delete
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <div className="card-resource">
                <a onClick={handleClick}>
                    <div className="card-resource-overlay"></div>
                    <Image className="card-resource-image" src={data.src} alt={data.displayName} />
                    <div className="card-resource-details fadeIn-bottom">
                        <h3 className="card-resource-title">{data.displayName}</h3>
                        <p className="card-resource-text">{data.description}</p>
                    </div>
                    <TextContainer>
                        <Text>{data.displayName}</Text>
                    </TextContainer>
                </a>
            </div>
        </Wrapper>
    </>);
};

//  -- Prop types ---------------------
CoversCard.propTypes = {
    data: shape({
        src: string,
        displayName: string,
        description: string,
    }),
    handleClick: func
};

//  -- Default props ------------------
CoversCard.defaultProps = {
    data: {},
};

export default CoversCard;