//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep } from 'lodash';
//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------
const licencesInitial = []
export const licences = (state = licencesInitial, action) => {
    switch (action.type) {
        case ActionTypes.licences.listLicencesSuccess:
            return action.payload;
        case ActionTypes.licences.createLicencesSuccess:
            return [action.payload, ...state];
        // case ActionTypes.licences.updateLicencesSuccess:
            // const result = state.map(x => {
            //     if (x.id === action.payload.id) {
            //         console.log('x.id === action.payload.id', { ...x, ...action.payload });
            //         return { ...x, ...action.payload };
            //     }
            //     return x;
            // });
            // console.log('action.payload', action.payload, 'state', state, '{ ...state, ...action.payload }', { ...state, ...action.payload });
        case ActionTypes.licences.createLicencesFail:
        case ActionTypes.licences.listLicencesFail:
        default:
            return state;
    }
}