//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------


//  -- List books ----------------
export const handleInvokeFeedbackForm = (feedback) => async(dispatch) => {
    await dispatch(ActivityActions.invokeFeedback());
}
export const handleRevokeFeedbackForm = (feedback) => async(dispatch) => {
    await dispatch(ActivityActions.revokeFeedback());

}
export const handleCreateFeedback = (feedback,cb) => async(dispatch) => {
    await dispatch(ActivityActions.createFeedback(feedback));
    try {
        const feedbackResonce = await API.post('feedbackRDS', '/feedback', {
            body: feedback
        });
        cb(feedbackResonce)
    await dispatch(ActivityActions.feedbackSuccess(feedbackResonce));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        console.log(error);
        cb();
    }
   
}