//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create question ----------------
export const createQuestion = () => ({ type: ActionTypes.questions.createQuestion });
export const createQuestionFail = (returnedPayload) => ({ type: ActionTypes.questions.createQuestionFail, payload: returnedPayload });
export const createQuestionSuccess = (returnedPayload) => ({ type: ActionTypes.questions.createQuestionSuccess, payload: returnedPayload });

//  -- Delete question ----------------
export const deleteQuestion = () => ({ type: ActionTypes.questions.deleteQuestion });
export const deleteQuestionFail = (returnedPayload) => ({ type: ActionTypes.questions.deleteQuestionFail, payload: returnedPayload });
export const deleteQuestionSuccess = (returnedPayload) => ({ type: ActionTypes.questions.deleteQuestionSuccess, payload: returnedPayload });

//  -- List questions -----------------
export const listQuestions = () => ({ type: ActionTypes.questions.listQuestions });
export const listQuestionsFail = (returnedPayload) => ({ type: ActionTypes.questions.listQuestionsFail, payload: returnedPayload });
export const listQuestionsSuccess = (returnedPayload) => ({ type: ActionTypes.questions.listQuestionsSuccess, payload: returnedPayload });

//  -- List chapter questions ---------
export const listChapterQuestions = () => ({ type: ActionTypes.questions.listChapterQuestions });
export const listChapterQuestionsFail = (returnedPayload) => ({ type: ActionTypes.questions.listChapterQuestionsFail, payload: returnedPayload });
export const listChapterQuestionsSuccess = (returnedPayload) => ({ type: ActionTypes.questions.listChapterQuestionsSuccess, payload: returnedPayload });

//  -- List class questions -----------
export const listClassQuestions = () => ({ type: ActionTypes.questions.listClassQuestions });
export const listClassQuestionsFail = (returnedPayload) => ({ type: ActionTypes.questions.listClassQuestionsFail, payload: returnedPayload });
export const listClassQuestionsSuccess = (returnedPayload) => ({ type: ActionTypes.questions.listClassQuestionsSuccess, payload: returnedPayload });

//  -- List organization questions ----
export const listOrgQuestions = () => ({ type: ActionTypes.questions.listOrgQuestions });
export const listOrgQuestionsFail = (returnedPayload) => ({ type: ActionTypes.questions.listOrgQuestionsFail, payload: returnedPayload });
export const listOrgQuestionsSuccess = (returnedPayload) => ({ type: ActionTypes.questions.listOrgQuestionsSuccess, payload: returnedPayload });

//  -- List user questions -------------
export const listUserQuestions = () => ({ type: ActionTypes.questions.listUserQuestions });
export const listUserQuestionsFail = (returnedPayload) => ({ type: ActionTypes.questions.listUserQuestionsFail, payload: returnedPayload });
export const listUserQuestionsSuccess = (returnedPayload) => ({ type: ActionTypes.questions.listUserQuestionsSuccess, payload: returnedPayload });

//  -- Update question ----------------
export const updateQuestion = () => ({ type: ActionTypes.questions.updateQuestion });
export const updateQuestionFail = (returnedPayload) => ({ type: ActionTypes.questions.updateQuestionFail, payload: returnedPayload });
export const updateQuestionSuccess = (returnedPayload) => ({ type: ActionTypes.questions.updateQuestionSuccess, payload: returnedPayload });
