//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const processClasslink = () => ({ type: ActionTypes.classlink.processClasslink });
export const processClasslinkFail = (returnedPayload) => ({ type: ActionTypes.classlink.processClasslinkFail, payload: returnedPayload });
export const processClasslinkSuccess = (returnedPayload) => ({ type: ActionTypes.classlink.processClasslinkSuccess, payload: returnedPayload });
export const setTenantSuccess = (returnedPayload) => ({ type: ActionTypes.classlink.setTenantSuccess  ,  payload: returnedPayload });
export const setTenantFail = (returnedPayload) => ({ type: ActionTypes.classlink.setTenantFail, payload: returnedPayload });

