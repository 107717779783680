//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createInstructions = () => ({ type: ActionTypes.instructions.createInstructions });
export const createInstructionsFail = (returnedPayload) => ({ type: ActionTypes.instructions.createInstructionsFail, payload: returnedPayload });
export const createIntstructionsuccess = (returnedPayload) => ({ type: ActionTypes.instructions.createInstructionsSuccess, payload: returnedPayload });

//  -- Get interaction ----------------

export const listInstructions = () => ({ type: ActionTypes.instructions.listInstructions });
export const listInstructionsFail = (returnedPayload) => ({ type: ActionTypes.instructions.listInstructionsFail, payload: returnedPayload });
export const listIntstructionsSuccess = (returnedPayload) => ({ type: ActionTypes.instructions.listInstructionsSuccess, payload: returnedPayload });

//  -- Update interaction -------------
export const updateInstructions = (id, data) => ({ type: ActionTypes.instructions.updateInstructions, payload: { id, data } });
export const updateInstructionsFail = (returnedPayload) => ({ type: ActionTypes.instructions.updateInstructionsFail, payload: returnedPayload });
export const updateIntstructionsSuccess = (returnedPayload) => ({ type: ActionTypes.instructions.updateInstructionsSuccess, payload: returnedPayload });

//  -- Delete interaction -------------
export const deleteInstructions = (id) => ({ type: ActionTypes.instructions.deleteInstructions, payload: { id } });
export const deleteInstructionsFail = (returnedPayload) => ({ type: ActionTypes.instructions.deleteInstructionsFail, payload: returnedPayload });
export const deleteIntstructionsSuccess = (returnedPayload) => ({ type: ActionTypes.instructions.deleteInstructionsSuccess, payload: returnedPayload });