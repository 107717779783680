//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import ExamCover from 'components/AdminBooks/ExamCover';
import Section from 'components/Section';
import { get, map } from "lodash";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
//  -- Constants ----------------------
import { Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField, makeStyles } from '@material-ui/core';
import { Add, Close, Delete, Edit } from '@material-ui/icons';
import {
    DateTimePicker,
} from "@material-ui/pickers";
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import { DeleteModal } from 'components/Dialog';
import Loading from "components/Loading";
import { cloneDeep, has } from 'lodash';
import moment from "moment";
import { useApExams } from 'store/apexams/selectors';
import { handleCreateApExams, handleDeleteApExam, handleListApExams, handleUpdateApExams } from 'store/apexams/thunks';
import COLOR from 'utils/constants/color';
import { isBeforTime } from "utils/methods/math";
//  -- Features -----------------------



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;

const ListItem = styled.li`
    margin: 0px 4% 32px;
    width: calc(21%);

    @media only screen and (min-width: 600px) and (max-width: 800px) {
        margin: 0px 16px 32px;
        width: calc(50% - 32px);
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin: 0px 16px 32px;
        width: 100%;
    }
`
const List = styled.ul`
    height: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content:center;
`;
//   margin-left: 12%;
// width: 75%;
//horizental line with styled-components
const Hr = styled.div`
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 32px;
    width: 100%;
`;

const ModalContent = styled.div`
  left: 50%;
  width: 70vw;
  padding: 0px 24px;
  position: absolute;
//   height:80vh;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
    @media only screen and (max-width: 600px) {
        width: 90%;
    }
`;
const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const getMinDate = (date1, date2) => {
    if (date1 && date2) {
        return isBeforTime(date1, date2) ? moment(date2).add(1, "minute").toISOString() : moment(date1).add(1, "minute").toISOString();
    } else if (date1 || date2) {
        return date1 ? moment(date1).add(1, "minute").toISOString() : moment(date2).add(1, "minute").toISOString();
    } else {
        return moment().toString()
    }
}

const EditExams = ({ open, close, data, booksAllList, exam  , redirectToBook }) => {
    const [loading, setLoading] = useState(false)
    const [examData, setExamData] = useState()
    const classes = useStyles();
    const dispatch = useDispatch()
    const onCreateOrUpdate = async () => {
        setLoading(true)
        const dt = cloneDeep(exam)
        if (examData&&examData.id) {
            exam.exams.map((e,i)=>{
                if(e.id===examData.id){
                    dt.exams[i]=examData
                }
            })
            await dispatch(handleUpdateApExams(dt))
        } else {
            let maxId = Math.max(...exam.exams.map(o => parseInt(o.id)))
            if (exam.exams.length == 0) {
                maxId = 1
            }else{
                if(isNaN(maxId)||maxId==-Infinity){
                    maxId = 0;
                }
                maxId++;
            }
            dt.exams.push({ ...examData, id: maxId, order: maxId })
            await dispatch(handleUpdateApExams(dt))
        }
        setLoading(false)
        close()
    }
    useEffect(() => {
        setExamData(data)
    }, [data])
    const isDisabled = !get(examData,"src")||!get(examData,"displayName")||!get(examData,"bookId")
    return (
        <Modal
            open={open}
            onClose={() => close()}
            style={{ height: '100%', width: '100%' }}
        >
            <ModalContent>
                <Row style={{ justifyContent: "space-between" }}>
                    <TitleText>{data ? 'Update Exams' : 'Add Exams'}</TitleText>
                    <IconButton onClick={() => close()} style={{ padding: 0 }}>
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <Row style={{marginTop: 20}}>
                        <Col xs={24} lg={12} style={{marginBottom:20}}>
                            <PageImagePicker
                                src={get(examData, "src")}
                                path={`/exams`}
                                onChange={item => {
                                    setExamData({ ...examData, src: item.url })
                                }}
                                hasDescription={false}
                            />
                        </Col>
                        <Col xs={24} lg={12} style={{ paddingLeft: 10, paddingTop: 10 }}>
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    label="Display name"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        setExamData({ ...examData, displayName: e.target.value })
                                    }}
                                    value={get(examData, "displayName")}
                                />
                            </Row>
                            <Row
                                style={{ marginTop: 0, marginRight: 0, marginLeft: 0,marginBottom: -10 }}
                            >
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel style={{backgroundColor:'white'}} id="bookId">Book</InputLabel>
                                    <Select
                                        labelId="bookId"
                                        id="bookId"
                                        value={`${get(examData, "bookId")}`}
                                        onChange={(e) => {
                                            setExamData({
                                                ...examData,
                                                bookId: e.target.value,
                                            });
                                        }}
                                    // label="Lession Plan book"
                                    >
                                        {map(booksAllList, (bk) => (
                                            <MenuItem key={bk.id} value={bk.id}>{`${bk.description || ""
                                                }(${bk.id || ""})`}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Row>
                            <Row className="durDateSpc" style={{marginBottom:-10}}>
                                <Col span={24}>
                                    <DateTimePicker
                                        id="StartFrom"
                                        label="Start Date"
                                        inputVariant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={null}
                                        format="MM-dd-yyyy hh:mm a"
                                        initialFocusedDate={moment().toString()}
                                      
                                        strictCompareDates
                                        value={get(examData, "startDate")}
                                        onChange={e => {
                                            setExamData({
                                                ...examData,
                                                startDate: e.toISOString(),
                                            });
                                        }} />
                                </Col>
                            </Row>
                            <Row className="durDateSpc" style={{marginBottom:-10}}>
                                <Col span={24}>
                                    <DateTimePicker
                                        id="ednFromDate"
                                        label="End Date"
                                        inputVariant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={null}
                                        format="MM-dd-yyyy hh:mm a"
                                        initialFocusedDate={moment().toString()}
                                        strictCompareDates
                                        minDate={get(examData, "startDate")?getMinDate(get(examData, "startDate")):null}
                                        minDateMessage={"End Date and Time has to be after the Start Date and Time."}
                                        value={get(examData, "endDate")}
                                        onChange={e => {
                                            setExamData({
                                                ...examData,
                                                endDate: e.toISOString(),
                                            });
                                        }} />
                                </Col>
                            </Row>
                            <Row  style={{ marginTop: 30, marginRight: 0, marginLeft: 0 }}>
                                <Button fullWidth 
                                onClick={onCreateOrUpdate} 
                                disabled={isDisabled}
                                variant="contained" color="primary">
                                    {loading ? <Loading white size={15} /> : null}
                                    {data ? "Update" : "Create"}
                                </Button>
                            </Row>
                           {has(data,"id")? <Row  style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                                <Button fullWidth 
                                onClick={()=>{
                                    redirectToBook(examData.bookId)
                                }} 
                                disabled={isDisabled}
                                variant="contained" color="primary">
                                    Open book
                                </Button>
                            </Row>:null}
                        </Col>
                    </Row>
                </ModalConfigArea>
            </ModalContent>
        </Modal>
    );
};

const EditExamsLavel = ({ open, close, data }) => {
    const [loading, setLoading] = useState(false)
    const [examData, setExamData] = useState()
    const dispatch = useDispatch()
    const onCreateOrUpdate = async () => {
        setLoading(true)
        if (examData && examData.id) {
            await dispatch(handleUpdateApExams(examData))
        } else {
            await dispatch(handleCreateApExams({...examData,exams:[]}))
        }
        setLoading(false)
        close()
    }
    useEffect(() => {
        setExamData(data)
    }, [data])
    const isDisabled = !get(examData,"displayName")
    return (
        <Modal
            open={open}
            onClose={() => close()}
            style={{ height: '100%', width: '100%' }}
        >
            <ModalContent style={{width:"40vw",height:"auto",paddingTop:16}}>
                <Row style={{ justifyContent: "space-between" }}>
                    <TitleText>{data ? 'Edit Exam level' : 'Add Exam level'}</TitleText>
                    <IconButton onClick={() => close()} style={{ padding: 0, marginRight: 10 }}>
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <Row>
                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop:16 }}>
                            <Row justify="start" style={{ marginBottom: 10, }}>
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    label="Display name"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        setExamData({ ...examData, displayName: e.target.value })
                                    }}
                                    value={get(examData, "displayName")}
                                />
                            </Row>
                            <Row gutter={[24, 24]} style={{ marginTop: 30, marginRight: 0, marginLeft: 0 }}>
                                <Button fullWidth 
                                onClick={onCreateOrUpdate} 
                                disabled={isDisabled}
                                variant="contained" color="primary">
                                    {loading ? <Loading white size={15} /> : null}
                                    {data ? "Update" : "Create"}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </ModalConfigArea>
            </ModalContent>
        </Modal>
    );
};
const AssessmentContainer = ({
    currentUser,
    booksAllList,
}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [examDatam, setExamData] = useState([])
    const dispatch = useDispatch()
    const exams = useApExams()
    const [activeExamData, setActiveData] = useState();
    const [selectedExam, setSelectedExam] = useState();
    const [openEditExamModal, setOpenEditExamModal] = React.useState(false);
    const [openEditExamLevelModal, setOpenEditExamLevelModal] = React.useState(false);
    const [deleteLodaing,setDeleteLoading] = useState(false)
    const [openDeleteExamModal, setOpenDeleteExamModal] = React.useState(false);
    const [state, setState] = React.useState({
        open: false,
        message: ""
    });
    const loadExams = async () => {
        setLoading(get(exams,"length")==0)
        await dispatch(handleListApExams())
        setLoading(false)
    }
    useEffect(() => {
        loadExams()
    }, [])
    const redirectToBook = (bookId) => {
        history.push(`/add-book?bookId=${bookId}&pageId=1`)
    }
    useEffect(() => {
        //sort exam by order
        if(exams){
            const sortedExams = exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
            setExamData(sortedExams)
        }
       
    }, [exams])
    const handleClose = () => {
        setState({ open: false, message: "" });
    };
    const onAddExam = (exmDt, exm) => {
        setActiveData(exmDt)
        setSelectedExam(exm)
        setOpenEditExamModal(true)
    }
    const onAddEditExamLevel = (exm) => {
        setActiveData(exm)
        setOpenEditExamLevelModal(true)
    }
    const onClose = () => {
        setOpenEditExamModal(false)
        setActiveData(null)
        setSelectedExam(null)
        setOpenEditExamLevelModal(false)
    }
    const onDeleteExam = async() => {
        setDeleteLoading(true)
        await dispatch(handleDeleteApExam(get(activeExamData,"id")))
        setActiveData(null)
        setOpenDeleteExamModal(false)
        setDeleteLoading(false)
    }
    const { open, message } = state;
    return (
        <Wrapper>
            {loading ? (
                <Loading />
            ) : (<>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        <strong>ALERT:</strong> {message}
                    </Alert>
                </Snackbar>
                    <DeleteModal
                        deleteToggle={openDeleteExamModal}
                        onDelete={onDeleteExam}
                        message={`Are you sure you want to delete ${get(activeExamData,"displayName","this Exam")}?`}
                        setDeleteToggle={() => {
                            setOpenDeleteExamModal(false)
                        }} loading={deleteLodaing} />
                    <EditExams
                        data={selectedExam}
                        open={openEditExamModal}
                        close={onClose}
                        exam={activeExamData}
                        booksAllList={booksAllList}
                        redirectToBook={redirectToBook} />
                    <EditExamsLavel
                        data={activeExamData}
                        open={openEditExamLevelModal}
                        close={onClose} />
                    <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                        <Button onClick={() => onAddEditExamLevel()} color='primary' variant="contained" >
                            <Add style={{fontSize:20,marginRight:3}} /> Exam level
                        </Button>
                    </Row>
                <Row style={{ marginTop: '1%' }}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Section style={{ fontSize: '18px',marginBottom:20 }}
                            description="Ap exams"
                            header='Ap exams'>
                            {map(examDatam, exm => (<LinkArea>
                                <Row>
                                    <Col span={24}>
                                        <Button onClick={() => onAddExam(exm)} color='primary' variant="contained" >
                                            <Add style={{fontSize:20,marginRight:3}} /> exams
                                        </Button>
                                        <Button
                                            onClick={() => {onAddEditExamLevel(exm) }}
                                            color='primary'
                                            variant="contained"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            <Edit style={{fontSize:20,marginRight:3}} /> Edit Exam level
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                 setOpenDeleteExamModal(true)
                                                 setActiveData(exm)
                                                }}
                                            color="secondary"
                                            variant="contained"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            <Delete style={{fontSize:20,marginRight:3}} /> Exam level
                                        </Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '1%' }}>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                        <List>
                                            {map(exm.exams, (obj, i) => (
                                                <ListItem key={obj.id}>
                                                    <ExamCover
                                                        data={obj}
                                                        handleClick={() => onAddExam(exm, obj)}
                                                        labelBgColor={COLOR.redishpink}
                                                        label={get(exm, "displayName")}
                                                        exam={exm}
                                                        from='apexams'
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>

                                    </Col>
                                </Row>
                            </LinkArea>))}
                        </Section>
                    </Col>
                </Row>
            </>)}

        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentContainer.propTypes = {};

//  -- Default props ------------------
AssessmentContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser,
    userBooksList: state.userBooksList,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    booksAllList: state.booksAllList
});

export default connect(mapStateToProps, null)(AssessmentContainer);
