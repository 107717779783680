//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { CaretDownOutlined } from '@ant-design/icons';
import Snackbar from '@material-ui/core/Snackbar';
import { filter, find, groupBy, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
//  -- Components ---------------------
import { Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EmptyState from 'components/EmptyState';
import Loading from 'components/Loading';
import { useExams } from 'store/exams/selectors';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------
import GradeEditor from './ParentGradeEditor';

//  -- Thunks -------------------------
import base64 from "base-64";
import { TootipWrapper } from 'components/TooltipWrapper';
import _, { get, has, map } from 'lodash';
import moment from "moment";
import { useCallback } from 'react';
import { useResources, useVideos } from 'store/resources/selectors';
import { useSchoologyAssignment, useSchoologyGradeCategory } from "store/schoologyauth/selectors";
import { useStory } from "store/story/selectors";
import { handleGetUserPreference } from 'store/userPreference/thunks';
import { getAssignmentLable, getBookDisplayName } from 'utils/lib/ebookHelper';
import { getCurrenSem, semeterDateByYear } from "utils/methods/math";
import ExamTables from "./ParentExamGradetable";

const rowOneColor = "#7ed6df"
const rowTwoColor = "#03989e"
const rowSemester = COLOR.OrangeExam
const rowThreeColor = "#0abde3"
const getKey = (sem, year) => (`${sem == 1 ? "semester 1" : "semester 2"} - ${year}`)
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getBgColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR
const getBgColorSemester = (isActive) => isActive ? ACTIVE_BTN_COLOR : rowSemester
const NAME_SHORTING = [{ key: "last_a_z", title: "Last Name, A-Z" }, { key: "last_z_a", title: "Last Name, Z-A" }, { key: "first_a_z", title: "First Name, A-Z" }, { key: "first_z_a", title: "First Name, Z-A" }]
const TABLE_CELL_HEIGHT = '60px';
const TABLE_CELL_MIN_WIDTH = 150

const getSemDateTime = (sem) => {
    let dt = sem.split(" ")
    const year = dt[3]
    const semNum = dt[1]
    const { sem1EndDate, sem1StartDate, sem2EndDate, sem2StartDate } = semeterDateByYear(year)
    if (semNum == 1) {
        return ({ start: sem1StartDate, end: sem1EndDate })
    } else {
        return ({ start: sem2StartDate, end: sem2EndDate })
    }

}


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CustomBtn = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black300};
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 4px;
    outline: none;

    &:hover {
        color: ${COLOR.green100};
    }
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;




// border-right-color:${COLOR.black100}
const OverflowOne = styled.div`
    height: auto;
    width: 417px;
    display: inline-block;
    border-right: 1px solid black;
    @media (max-width: 634px){
    
    }
`;
const OverflowTwo = styled.div`
    height: auto;
    display: inline-block;
    @media (max-width: 634px) {
       
    }
`;

const AssignmentDeleteWrapper = styled.div`
   cursor:pointer;
   margin-top: 5px;
   opacity:0;
`;

const TableCell = styled.div`
    text-align: center;
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    overflow:hidden;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const DueDateTableCell = styled(TableCell)`
   background-color: ${rowThreeColor};
   color: white;
   height: 30px; 
   font-size: 12px;
   font-weight: bold;
   border-right: 1px solid ${COLOR.black100};
   text-align: center;
   justify-content: center !important;
`;
const AssignmentTableCell = styled(TableCell)`
   background-color: ${p => p.assignmentActive ? rowTwoColor : COLOR.redishpink};
   color: white;
   height: 30px; 
   font-size: 12px;
   font-weight: bold;
   border-right: 1px solid ${COLOR.black100};
   text-align: center;
   justify-content: center !important;
   cursor: pointer;
`;
const BookLabelTableCell = styled(TableCell)`
   background-color: ${rowOneColor};
   color: white;
   height: 30px; 
   font-size: 10px;
   font-weight: bold;
   border-right: 1px solid ${COLOR.black100};
   text-align: center;
   justify-content: center !important;
   cursor: pointer;
   padding-left: 4;
   padding-right: 4;
`;
const AverageTableCell = styled(TableCell)`
   width:208px;
   font-size: 14px;
   height:${TABLE_CELL_HEIGHT};
`;
const UserNameCellTableCell = styled(TableCell)`
   width:208px;
   color:${p => p.userActive ? "inherit" : COLOR.radish100};
   height:${TABLE_CELL_HEIGHT};
`;

const TableCellSemester = styled.div`
    text-align: center;
    align-items: center;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColorSemester(p.active)};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const TableCellExam = styled.div`
    text-align: center;
    align-items: center;
    background-image: linear-gradient(90deg, ${COLOR.PinkExam} 0%, ${COLOR.OrangeExam} 100%); 
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const TableCellEdit = styled.div`
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${TABLE_CELL_HEIGHT};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    border-right: 1px solid ${COLOR.black100};
    &:first-child {
        justify-content: flex-start;
    }
    &:hover {
        color: white;
    }
`;
const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
`;

const TableRow = styled.div`
    align-items: center;
    background-color: ${p => p.active ? '#03989e82' : p.index % 2 === 0 ? 'rgb(243, 249, 255)' : 'transparent'};
    border-bottom: 1px solid;
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    width: auto;
`;

const Table = styled.div`
    padding-bottom: 0px;
`;


const Wrapper = styled.div`
    width: 100%;
`;

const Tab = styled.button`
    border: none;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColor(p.active)};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;

const BookNameTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  `;

const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 90%;
    border-right: none;
    margin-bottom: 52px;
    @media (max-width: 634px){
        width: 100%;
        overflow: scroll;
    }
`;
const TableWrapperInner2 = styled.div`
    overflow: scroll;
    overflow-y: hidden;
    @media (max-width: 634px){
        overflow: visible;
        
    }
    ::-webkit-scrollbar {
        width: 3px;
        height: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
`;
const AssignmentLabelText = styled.span`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Gradebook
//
//  -- Description
//  District and class announcements
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Gradebook />
//



const sortname = (a, b) => {
    const nameA = a?.lastName?.toLowerCase()?.replace(/\s/g, '');
    const nameB = b?.lastName?.toLowerCase()?.replace(/\s/g, '');;
    if (nameA < nameB) { return -1 }
    else if (nameA > nameB) { return 1 }
    else { return 0 }
}
const SemesterToShow = () => {
    const currentYear = moment().get("year");
    const lastYear = currentYear - 1;
    const semYearToShow = { sem1: '', sem2: '' }
    const { sem1StartDate, sem1EndDate } = semeterDateByYear(currentYear)
    const currentSem = getCurrenSem();

    if (moment().isSameOrAfter(sem1StartDate) && moment().isSameOrBefore(sem1EndDate)) {
        semYearToShow.sem1 = getKey(1, currentYear);
        semYearToShow.sem2 = getKey(2, currentYear);
    } else {
        semYearToShow.sem1 = getKey(1, lastYear);
        semYearToShow.sem2 = currentSem;
    }
    return semYearToShow;

}
const defaultAssignmentData = {}
defaultAssignmentData[getCurrenSem()] = new Array(8).fill({}, 0, 8)

const ParentGradetable = ({
    activities,
    currentClass,
    dataLoading,
    quizzes,
    users,
    reviewAssignment,
    submissions,
    booksAllList,
    isReviewAccount,
    currentUser,
    userPreference,
    classActivities,
    classQuizzes,
    setGradeViw,
    setExamData,
    setGradeData,
    getAssignmentCreation,
    orderBy,
    editPrevSemData,
    setCurrentSem
}) => {
    const dispatch = useDispatch();
    const orgPermissions = useCurrentOrgPermission()
    const story = useStory()
    const bookPermissions = _.get(orgPermissions, "books", [])
    const currentSem = getCurrenSem()
    const [schoologyGradeCategory,] = useSchoologyGradeCategory()

    const exams = useExams()
    const sortExam = () => {
        if (exams) {
            return exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
        } else {
            return [];
        }
    }
    const [semAssignment, setSemAssignment] = useState(defaultAssignmentData);
    const [activeSemData, setActiveSemData] = useState(new Array(8).fill({}, 0, 8));
    const scale_1_5 = userPreference?.gradePref == "scale_1_5"
    const gradebook_a_f = userPreference?.gradePref == "gradebook_a_f"
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [activeGradeTab, setActiveGradeTab] = useState("")
    const [filteredStudent, setFilteredStudent] = useState([])
    const [activeSem, setActiveSem] = useState(currentSem);
    const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
   

    const [listStudentUser, setListStudentUser] = useState(users && users.length > 0 ? filter(users, x => x.role === 'student').sort(sortname) : new Array(1).fill({}, 0, 1))


    const [nameShortType, setNameShortType] = useState(NAME_SHORTING[0]);
    const videos = useVideos()
    const resources = useResources()
    const [openPop, setOpen] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState('');
    const [schoologyAssignment, listSchoologyAssignment] = useSchoologyAssignment()
    const [activeIndex, setActiveIndex] = useState(-1)
    const inputRefs = useRef([]);
    const inputCells = useRef([]);
    const inputRowCells = useRef([]);
    const nameCellRefs = useRef([]);

    let CountSemester = 0
    const orderedActiveSemData = useMemo(() => {
        const val = _.orderBy(filter(activeSemData, k => k.dueDate), ["dueDate"], [orderBy])
        val.push(...filter(activeSemData, k => !k.dueDate))
        return val;
    }, [orderBy, activeSemData])
    const numRows = listStudentUser.length;
    const numCols = orderedActiveSemData.length;
    const sortByName = (a, b) => {
        const sortingFunctions = {
            [NAME_SHORTING[0].key]: (nameA, nameB) => nameA.localeCompare(nameB, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[1].key]: (nameA, nameB) => nameB.localeCompare(nameA, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[2].key]: (nameA, nameB) => nameA.localeCompare(nameB, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[3].key]: (nameA, nameB) => nameB.localeCompare(nameA, 'en', { sensitivity: 'base' }),
        };

        const getKey = (type) => {
            return (type === NAME_SHORTING[2].key || type === NAME_SHORTING[3].key) ? 'firstName' : 'lastName';
        };

        const key = getKey(nameShortType.key);

        const getNormalizedString = (obj, key) =>
            obj[key].toLowerCase()?.replace(/\s/g, "") +
            obj[key === "firstName" ? "lastName" : "firstName"]
                .toLowerCase()
                .replace(/\s/g, "");

        const nameA = getNormalizedString(a, key);
        const nameB = getNormalizedString(b, key);

        return sortingFunctions[nameShortType.key](nameA, nameB);
    };
    const getUserName = (usr) => {
        if (!usr || !has(usr, "firstName")) return "";

        const firstName = get(usr, "firstName", "");
        const lastName = get(usr, "lastName", "");

        if (nameShortType.key === NAME_SHORTING[2].key || nameShortType.key === NAME_SHORTING[3].key) {
            return `${firstName}, ${lastName}`;
        }

        return `${lastName}, ${firstName}`;
    };
    useEffect(() => {
        handleSetgradeData()
    }, [users, activeSemData, submissions, activeGradeTab])
    useEffect(() => {
        const allstudents = _.uniqBy(filter(users, x => x.role === 'student').sort(sortByName), "username");
        const defaultStudent = [{}];
        setListStudentUser(allstudents.length > 0 ? allstudents : defaultStudent);
        setFilteredStudent(allstudents.length > 0 ? allstudents : defaultStudent);

    }, [users])
    useEffect(() => {
        setFilteredStudent(filteredStudent.sort(sortByName))
        setListStudentUser(listStudentUser.sort(sortByName))
        setTimeout(forceUpdate, 500)

    }, [nameShortType])

    useEffect(() => {
        const toShowSemObj = SemesterToShow();

        if (!classActivities || !classQuizzes) return;

        const uniqueActivity = uniqBy(classActivities, "activityId");
        const uniqueQuiz = uniqBy(classQuizzes, 'quizId');
        const archived_books = get(currentClass, "archived_books", [])
        let _assignments = [...uniqueActivity, ...uniqueQuiz].filter(k => {
            if (archived_books && archived_books.some(abk => abk.bookId === get(k, "data.bookId") || abk.bookId === get(k, "data.chapter"))) {
                return false;
            }
            if (bookPermissions.length === 0) {
                return true;
            }
            const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "data.bookId")]);
            return bookaccess ? bookaccess.available : true;
        });

        const _sortedAssignments = _assignments.slice().sort((a, b) => moment(a.dueDate).diff(b.dueDate));

        let semData = {};
        _sortedAssignments.forEach(assignment => {
            const year = moment(assignment.dueDate).format("YYYY");
            const { sem1StartDate, sem1EndDate, sem2StartDate, sem2EndDate } = semeterDateByYear(year);
            const sem1 = semData[getKey(1, year)] || [];
            const sem2 = semData[getKey(2, year)] || [];

            const currentDate = moment(assignment.dueDate);

            if (currentDate.isBetween(sem1StartDate, sem1EndDate, 'day', '[]')) {
                sem1.push(assignment);
            } else if (currentDate.isBetween(sem2StartDate, sem2EndDate, 'day', '[]')) {
                sem2.push(assignment);
            }

            semData[getKey(1, year)] = sem1;
            semData[getKey(2, year)] = sem2;
        });
        Object.keys(semData).forEach(key => {
            if (semData[key] !== undefined && semData[key].length < 8) {
                const len = 8 - semData[key].length;
                const arr = Array.from({ length: len }, () => ({}));
                semData[key] = semData[key].concat(arr);
            }
        })
        const activeSemData = semData[currentSem] || new Array(8).fill({}, 0, 8);
        setActiveSemData(activeSemData);

        const tempSemData = {};
        Object.keys(toShowSemObj).forEach(key => {
            const dt = semData[toShowSemObj[key]] || new Array(8).fill({}, 0, 8);
            tempSemData[toShowSemObj[key]] = dt;
        });

        setSemAssignment(tempSemData);
    }, [classActivities, classQuizzes, currentClass]);

    useEffect(() => {
        getPref()
    }, [classActivities, classQuizzes])
    const getPref = async () => {
        await dispatch(handleGetUserPreference(currentUser?.id));

    }
    useEffect(() => {
        setCurrentSem(currentSem == activeSem)
    }, [activeSem, currentSem])
    const PopPupExam = (message) => {
        setPopupMessage(message)
        setOpen(true);

    }

    const handleKeyDown = useCallback(
        (e) => {
            if (document.querySelector('.ant-drawer') || document.querySelector('.one_game_open')) return;
            const { key } = e;
            const hasref = inputRefs.current.find(e => e && e.ref == document.activeElement)
            const currentInput = inputRefs.current[activeIndex]
            const row = Math.floor(activeIndex / numCols);
            const StartRow = row * numCols;
            const EndRow = StartRow + numCols;
            switch (key) {
                case "ArrowUp":
                case 38:
                    if (activeIndex / numCols >= 1) {
                        onBlurInput()
                        setActiveIndex(activeIndex - numCols);
                        // onFousInput(activeIndex - numCols);
                    }
                    break;
                case "ArrowDown":
                case 40:
                    // Move down a row, add num cols to index
                    if (activeIndex < numRows * numCols - numCols) {
                        onBlurInput()
                        setActiveIndex(activeIndex + numCols);
                        // onFousInput(activeIndex + numCols);
                    }
                    break;
                case "ArrowRight":
                case 39:
                    if (activeIndex < (EndRow - 1)) {
                        setActiveIndex(activeIndex + 1);
                        onBlurInput()
                        // onFousInput(activeIndex + 1);
                    }
                    break;
                case "ArrowLeft":
                case 37:
                    if (activeIndex > StartRow) {
                        setActiveIndex(activeIndex - 1);
                        onBlurInput()
                        // onFousInput(activeIndex - 1);
                    }
                    break;
                case "Enter":
                case 13:
                    if (hasref) {
                        if (activeIndex < numRows * numCols - numCols) {
                            onBlurInput()
                            setActiveIndex(activeIndex + numCols);
                        } else {
                            onBlurInput()
                        }
                    } else {
                        if (currentInput && currentInput.ref.disabled) {
                            if (activeIndex < numRows * numCols - numCols) {
                                setActiveIndex(activeIndex + numCols);
                            }
                        } else {
                            onFousInput(activeIndex);
                        }
                    }
                    break;
                case "Escape":
                    onBlurInput()
                    break;
                default:
                    break;
            }
        },
        [activeIndex, numRows, numCols]
    );

    const onFousInput = (index) => {
        const inputRef = inputRefs.current[index];
        if (inputRef) {
            inputRef.focus();
        }
    }
    const onBlurInput = () => {
        document.activeElement.blur();
    }
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);
    const handleClose = () => {
        setOpen(false);
    };

    const studentSubmissions = groupBy(submissions, 'userId');
    const getWeightedGrade = (grade, weight) => {
        if (weight === 0) {
            return 0;
        }
        return (grade) * weight / 100;
    };
    const getWeightageFroQuiz = (assignment) => {
        return classQuizzes?.find(x => x.quizId === assignment.id)?.weightage
    }
    const getWeightageForActivity = (assignment) => {
        return classActivities?.find(x => x.activityId === assignment.id)?.weightage
    }
    const getWeightageForAssignment = useCallback((assignemnt) => {
        let weightage = 100;
        if (assignemnt.typeId === 'quiz') {
            let calculatedWeightage = getWeightageFroQuiz(assignemnt);
            if (!isNaN(calculatedWeightage) && calculatedWeightage > 0 && calculatedWeightage <= 100) {
                weightage = calculatedWeightage
            }
        } else {
            let calculatedWeightage = getWeightageForActivity(assignemnt);
            if (!isNaN(calculatedWeightage) && calculatedWeightage > 0 && calculatedWeightage <= 100) {
                weightage = calculatedWeightage
            }
        }
        return weightage;
    }, [classQuizzes, classActivities])
    const getSkipAssignment = (data) => {
        if (!isSchoologyUser) return data;
        for (const key in data) {
            if (data.hasOwnProperty(key) && Array.isArray(data[key])) {
                const drop_lowest = get(find(schoologyGradeCategory, k => k.id == key), "drop_lowest", 0)
                data[key].sort((a, b) => a.grade - b.grade);
                data[key].splice(0, drop_lowest);
            }
        }
        return data;
    }

    const calculateGrade = useMemo(() => (userID) => {
        if (!userID) return "-";
        let numberOfAssignment = 0;
        let total = 0;
        const data = studentSubmissions[userID];
        const currentSemSubmitted = map(activeSemData, x => {
            if (!x || !x.classId) return null;
            const sub = find(data, y => y.assignmentId === x.quizId || y.assignmentId === x.activityId)
            const schoologyCategory = get(find(x.classes, ["id", currentClass.id]), "schoologyCategory")
            return sub ? ({ ...sub, assignment: x, schoologyCategory }) : null;
        }).filter(k => k)
        const assignemtBySchoologyCategory = {}
        if (isSchoologyUser) {
            map(currentSemSubmitted, x => {
                if (get(x, "assignment.ungraded")) return;
                if (!isNaN(x.grade) && !x.manualGrading) {
                    const grd = getWeightedGrade(parseInt(x.grade, 10), getWeightageForAssignment(x.assignment))
                    if (assignemtBySchoologyCategory[x.schoologyCategory]) {
                        assignemtBySchoologyCategory[x.schoologyCategory].push({
                            assignemtId: x.assignment.id,
                            grade: x.exceptionStatus > 0 ? 0 : parseInt(grd, 10)
                        })
                    } else {
                        assignemtBySchoologyCategory[x.schoologyCategory] = [{
                            assignemtId: x.assignment.id,
                            grade: x.exceptionStatus > 0 ? 0 : parseInt(grd, 10)
                        }]
                    }
                }
            })
        }
        const skippedAssignment = getSkipAssignment(assignemtBySchoologyCategory)
        map(currentSemSubmitted, x => {
            if (get(x, "assignment.ungraded")) return;
            const grade_type = get(x, "assignment.grade_type")
            const ispointsystem = grade_type === "points";
            const iscomplete_incomplete = grade_type === "complete_incomplete"
            const max_points = get(x, "assignment.max_point", 100)
            const weightage = parseInt(get(x, "assignment.weightage", "100") || "100", 10)
            if (isSchoologyUser) {
                const schoologyCategory = get(x, "schoologyCategory");
                const gradeAssignment = skippedAssignment[schoologyCategory]
                const hasAssignment = find(gradeAssignment, k => k.assignemtId == get(x, "assignment.id"))
                if (!hasAssignment) return;
            }

            if (x.exceptionStatus != 1 && x.exceptionStatus != 2 && x.exceptionStatus != 5 && x.exceptionStatus != 6 && !x.manualGrading) {
                numberOfAssignment++;
            }
            if (!isNaN(x.grade) && !x.manualGrading) {
                if (ispointsystem) {
                    const face_grade = get(x, "face_grade")
                    if (max_points > 0 && !isNaN(face_grade)) {
                        let grd = (face_grade / max_points) * 100;
                        if (!isNaN(weightage)) {
                            grd = getWeightedGrade(grd, weightage)
                        }
                        total += x.exceptionStatus > 0 ? 0 : grd;
                    }

                } else if (iscomplete_incomplete) {
                    if (parseInt(x.grade, 10) > 0) {
                        let grd = getWeightedGrade(100, weightage)
                        total += x.exceptionStatus > 0 ? 0 : grd;
                    }
                } else {
                    const grd = getWeightedGrade(parseInt(x.grade, 10), getWeightageForAssignment(x.assignment))
                    if (!isNaN(parseInt(grd, 10))) {
                        total += x.exceptionStatus > 0 ? 0 : grd;
                    }
                }
            }
        })
        const grade = (parseInt(total, 10) / parseInt(numberOfAssignment, 10)).toFixed(0);
        let gradeMarks = grade;
        if (scale_1_5) {
            if (grade !== "NaN") {
                const g = grade <= 0 ? 0 : _.floor((grade - 1) / 20)
                gradeMarks = g > 4 ? 4 : g;
            }
            else {
                gradeMarks = "-";
            }
        }
        if (gradebook_a_f) {
            if (grade !== "NaN") {
                const g = parseInt(grade)
                if (g <= 20 && g >= 0) {
                    gradeMarks = 'F';
                }
                else if (g <= 40 && g >= 21) {
                    gradeMarks = 'D';
                }
                else if (g <= 60 && g >= 41) {
                    gradeMarks = 'C';
                }
                else if (g <= 80 && g >= 61) {
                    gradeMarks = 'B';
                }
                else if (g >= 80) {
                    gradeMarks = 'A';
                } else {
                    gradeMarks = '-'
                }

            }
            else {
                gradeMarks = "-";
            }
        }
        return gradeMarks !== "NaN" ? gradeMarks : '-';
    }, [submissions, activeSem, scale_1_5, classActivities, classQuizzes, activeSemData, gradebook_a_f]);

    const calculateValue = useMemo(() => (assignment, user) => {
        if (!user || !get(assignment, "id")) return ({ value: "--", isTbg: false, isLate: false, manualGrading: false, completeTbg: false, })
        let isDiffStudent = false;
        const grade_type = assignment?.grade_type;
        const is_game = assignment.type === "game"
        const is_debate = assignment.type === "debate"
        const complete_incomplete = grade_type === "complete_incomplete"
        const point_system = grade_type === "points"
        const assignment_id = is_game || is_debate ? assignment.session_id : get(assignment, "data.id")
        if (assignment.diff) {
            map(assignment.diff, x => x.userId === user.id ? isDiffStudent = true : null)
        }
        if (Array.isArray(assignment.classes) && assignment.classes.length > 0) {
            const classConfig = find(assignment.classes, ['id', get(currentClass, "id")])
            if (classConfig && classConfig.diff) {
                isDiffStudent = Boolean(find(classConfig.diff.student, k => k == user.id))
            }
        }
        const hasAssigned = is_game || is_debate ? true : assignment.users == null || assignment.users.length == 0 || assignment.users.includes(user.id)
        const user_submission = find(studentSubmissions[user.id], ['assignmentId', assignment_id]);
        if (!user_submission) {
            return { value: null, isTbg: false, isLate: false, isDiffStudent, manualGrading: false, completeTbg: false, hasAssigned, is_game, face_grade: null, point_system, complete_incomplete, is_debate }
        } else if (!user_submission.complete && user_submission.pastSubmissions.length === 0 && !is_game && !is_debate) {
            return { value: null, isTbg: false, isLate: false, isDiffStudent, manualGrading: false, completeTbg: false, hasAssigned, is_game, face_grade: null, point_system, complete_incomplete, is_debate }
        }
        const retaking = get(user_submission, "retaking", false)
        let score = 0;
        const isTbgObj = find(get(user_submission, "assignment.questions", []), (e) => e.type != "multipleChoice")
        const isTbg = typeof isTbgObj == "object"
        const isAllTab = filter(get(user_submission, "assignment.questions", []), (e) => e.type != "multipleChoice").length === get(user_submission, "assignment.questions.length", 0)
        // const isPartialTbg = isTbg && !isAllTab;
        // const tbgTxt = isTbg ? "TBG" : "-"
        const manualGrading = get(user_submission, "manualGrading")
        const face_grade = get(user_submission, "face_grade")
        let value = user_submission && user_submission.grade >= 0 ? user_submission.grade : '-'
        value = manualGrading && (isAllTab && !is_game && !is_debate) ? "-" : value;
        value = score > 0 ? score : value
        value = retaking && value == 0 ? "-" : value
        let isLate = false
        if (user_submission) {
            isLate = user_submission.isLate;
        }
        if (!isNaN(value) && scale_1_5) {
            if (value !== null && value !== '') {
                let _g = value <= 0 ? 0 : _.floor((value - 1) / 20)
                value = `${_g > 4 ? 4 : _g}`
            }
        }
        else if (!isNaN(value) && gradebook_a_f && value !== null) {
            const g = parseInt(value)
            if (g <= 20 && g >= 0) {
                value = 'F';
            }
            else if (g <= 40 && g >= 21) {
                value = 'D';
            }
            else if (g <= 60 && g >= 41) {
                value = 'C';
            }
            else if (g <= 80 && g >= 61) {
                value = 'B';
            }
            else if (g >= 80) {
                value = 'A';
            } else {
                value = '-'
            }
        }
        return { value, isTbg, isLate, isDiffStudent, manualGrading, completeTbg: isAllTab, hasAssigned, face_grade, point_system, complete_incomplete, is_game, is_debate }
    });

 

    const getCellStyle = useCallback((isLate, isDiffStudent, isActiveCol) => {
        if (isLate) {
            if (isActiveCol) {
                return ({ backgroundColor: "rgba(255,0,0,0.2)", border: "2px solid white" })
            }
            return ({ backgroundColor: "rgba(255,0,0,0.2)" })
        } else if (isDiffStudent) {
            if (isActiveCol) {
                return ({ backgroundColor: "#00ff134f", border: "2px solid white" })
            }
            return ({ backgroundColor: "#00ff134f" })
        }
        else if (isActiveCol) {
            return ({ border: "2px solid white" })
        }
        else {
            return ({})
        }
    })


    const onExamTabClick = (tab) => {
        setActiveGradeTab(tab);
        setGradeViw("exam")
        if (tab == "exam2") {
            const hExam = get(find(exams, k => k.order == 1), "exams[1]")
            const mExam = get(find(exams, k => k.order == 2), "exams[1]")
            const hStartDate = get(hExam, "startDate")
            const mStartDate = get(mExam, "startDate")
            if (hStartDate && mStartDate) {
                if (moment().isBefore(hStartDate) && moment().isBefore(mStartDate)) {
                    PopPupExam(`Exam is available after ${moment(hStartDate).isBefore(mStartDate) ? moment(hStartDate).format("DD/MM/YYYY") : moment(mStartDate).format("DD/MM/YYYY")}`)
                    return;
                }
            }
        } else if (tab == "exam3") {
            const hExam = get(find(exams, k => k.order == 1), "exams[2]")
            const mExam = get(find(exams, k => k.order == 2), "exams[2]")
            const hStartDate = get(hExam, "startDate")
            const mStartDate = get(mExam, "startDate")
            if (hStartDate && mStartDate) {
                if (moment().isBefore(hStartDate) && moment().isBefore(mStartDate)) {
                    PopPupExam(`Exam is available after ${moment(hStartDate).isBefore(mStartDate) ? moment(hStartDate).format("DD/MM/YYYY") : moment(mStartDate).format("DD/MM/YYYY")}`)
                    return;
                }
            }
        }

    }
    const handleSetgradeData = () => {
        try {
            const row1 = ['', '', '', 'Books']
            const row2 = ['', '', '', 'assignment']
            const row3 = ['', 'username', 'password', 'due date/average']
            const userData = []
            map(activeSemData, (assignment, i) => {
                let bookname = getBookDisplayName(booksAllList, assignment?.data?.chapter)
                row1.push(bookname)
                if (assignment?.data?.typeId == 'quizresource') {
                    row2.push(`${assignment?.data?.bookId}(${assignment?.displayName || assignment?.data?.displayName || ""})`)
                } else {
                    row2.push(`${getAssignmentLable(assignment, booksAllList, videos, resources)}(${assignment?.displayName || assignment?.data?.displayName || ""})`)
                }
                if (assignment?.dueDate) {
                    row3.push(`${moment(assignment?.dueDate).format("MMM DD")}`)
                } else {
                    row3.push('')
                }
            })
            map(listStudentUser, (user, i) => {
                let data = []
                data.push(`${user.lastName}, ${user.firstName}`)
                data.push(user.username)
                data.push(user.password ? base64.decode(user.password) : '')
                data.push(calculateGrade(user.id))
                map(activeSemData, (assignment, i) => {
                    let { value } = calculateValue(assignment, user)
                    data.push(value)
                })

                userData[i] = data;
            })
            const finaldata = [row1, row2, row3, ...userData]
            setGradeData(finaldata)
        } catch (error) {
            console.log(error, "error in exam gradebook downolad")
        }
    }
    const onSemClick = (key) => {
        const currentYear = parseInt(moment().format("YYYY"))
        let yearc = parseInt(key.split("-")[1])
        if (key == currentSem || yearc <= currentYear) {
            setActiveSem(key)
            setActiveSemData(semAssignment[key])
        } else {
            setOpen(true)
            const startMonth = key.split("-")[0].includes(1) ? "June" : "January"
            setPopupMessage(`Available ${startMonth}  ${yearc + 1}`)
        }
    }

    const isEditable = useCallback(() => {
        if (editPrevSemData) return false;
        else if (currentSem !== activeSem) {
            const datetime = getSemDateTime(activeSem);
            if (datetime.end.isSameOrBefore(moment())) {
                return datetime.end.isBefore(moment().subtract(1, "month"))
            } else {
                return true;
            }
        } else {
            return false;
        }
    }, [activeSem, currentSem, editPrevSemData])
    const getValue = (hasAssigned, value) => {
        return hasAssigned ? typeof value === "string" ? value.toUpperCase() : value : "N/A"
    }
    const renderTableCell = useMemo(() => {
        return map(listStudentUser, (user, i) => (
            <TableRow ref={(el) => (inputRowCells.current[i] = el)} key={get(user, "id") ? get(user, "id") : i + "user"} index={i + 1} style={{ borderLeft: '0px' }}>
                {map(orderedActiveSemData, (assignment, j) => {
                    const { value, isTbg, isLate, isDiffStudent, manualGrading, completeTbg, hasAssigned, face_grade, point_system, complete_incomplete, is_game, is_debate } = calculateValue(assignment, user)
                    const tempData = getAssignmentCreation(assignment, user)
                    const index = i * numCols + j;
                    const ungraded = get(assignment, "ungraded", false)

                    const isActiveUserClass = typeof user?.userclass?.active === "boolean" ? user.userclass.active : false;
                    const has_assignment = is_game || is_debate ? assignment?.id : assignment?.data?.id;
                    const disable_edit = isEditable() || !hasAssigned || !isActiveUserClass || !has_assignment

                    const assignmentId = is_game || is_debate ? assignment.session_id : assignment?.data?.id
                    return (
                        <TableCellEdit
                            style={getCellStyle(isLate, isDiffStudent)}
                            key={get(assignment, "id") || index}
                            ref={(el) => (inputCells.current[index] = el)}
                            minWidth={TABLE_CELL_MIN_WIDTH}
                        >
                            <GradeEditor
                                disabled={disable_edit}
                                assignmentId={assignment?.id ?? (j + "assignment")}
                                isReviewAccount={isReviewAccount}
                                data={find(studentSubmissions[user?.id], ['assignmentId', assignmentId])}
                                reviewAssignment={(v) => { reviewAssignment(v, tempData, listStudentUser, studentSubmissions) }}
                                tempData={tempData}
                                submission={find(studentSubmissions[user.id], ['assignmentId', assignmentId])}
                                userId={user?.id ?? (j + "userr")}
                                value={getValue(hasAssigned, value)}
                                scale_1_5={scale_1_5}
                                gradebook_a_f={gradebook_a_f}
                                assignments={assignment}
                                currentClass={currentClass}
                                user={user}
                                assignment={assignment}
                                isTbg={isTbg && manualGrading}
                                studentSubmissions={studentSubmissions[user.id]}
                                onFlagMenuViewChange={(visiblity) => {

                                }}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onFocus={() => {
                                    setActiveIndex(index)
                                }}
                                onBlur={() => { }}
                                isLate={isLate}
                                hasTbg={isTbg}
                                isDiffStudent={isDiffStudent}
                                ungraded={ungraded}
                                face_grade={face_grade}
                                point_system={point_system}
                                complete_incomplete={complete_incomplete}
                                submissions={submissions}
                            />

                        </TableCellEdit>
                    )
                })}
            </TableRow>
        ))
    }, [listStudentUser, orderedActiveSemData, submissions, activeSem, currentSem, editPrevSemData, userPreference])

    const renderDueDates = useMemo(() => {
        return (map(orderedActiveSemData, (assignment, i) => (
            <span key={`${i} assignment`}>
                <DueDateTableCell
                    key={`${assignment?.id} - ${i}`}
                    id={assignment?.id}
                    minWidth={TABLE_CELL_MIN_WIDTH}
                >
                    {get(assignment, "dueDate") ? <span>{`${moment(get(assignment, "dueDate")).format("MMM DD")}`}</span> : <span></span>}
                </DueDateTableCell>
            </span>
        )))
    }, [orderedActiveSemData])

    const renderAssignmentLabel = useMemo(() => {
        return (map(orderedActiveSemData, (assignment, i) => {
            const isActive = get(assignment, "active", true)
            const is_game = get(assignment, "type") === 'game';
            const is_debate = get(assignment, "type") === 'debate'
            const cell_style = is_game ? { backgroundColor: COLOR.sky200 } : is_debate ? { backgroundColor: COLOR.tabActive } : {}
            return (<span key={i + "assignment"}>
                <AssignmentTableCell
                    assignmentActive={isActive}
                    key={assignment?.id}
                    id={assignment?.id}
                    header="true"
                    minWidth={TABLE_CELL_MIN_WIDTH}
                    style={cell_style}
                >
                    <Tooltip title={get(assignment, "displayName", get(assignment, "data.displayName", ""))}>
                        <AssignmentLabelText>{is_game || is_debate ? get(assignment, "displayName", '') : getAssignmentLable(assignment, booksAllList, videos, resources)}</AssignmentLabelText>
                    </Tooltip>
                </AssignmentTableCell>
            </span>)
        }))
    }, [orderedActiveSemData, booksAllList, videos, resources])

    const renderBookLabel = useMemo(() => {
        return (map(orderedActiveSemData, (assignment, i) => {
            const is_game = assignment?.type === "game"
            const is_debate = assignment?.type === "debate"
            const chapter = is_game ? assignment.bookId : assignment?.data?.chapter
            let bookname = is_debate ? 'Debate' : getBookDisplayName(booksAllList, chapter, true, videos, resources)
            return (<span key={i + "assignment"}>
                <BookLabelTableCell
                    key={assignment?.id + i}
                    id={assignment?.id}
                    header="true"
                    minWidth={TABLE_CELL_MIN_WIDTH}
                >
                    <Tooltip title={bookname}><AssignmentLabelText>
                         <BookNameTitle>{bookname}</BookNameTitle>
                    </AssignmentLabelText>
                    </Tooltip>
                </BookLabelTableCell>
            </span>)
        }))
    }, [orderedActiveSemData, booksAllList, videos, resources, schoologyAssignment])
    useEffect(() => {
        map(listStudentUser, (user, i) => {
            const activeRow = activeIndex >= 0 && Math.floor(activeIndex / numCols) === i;
            if (activeRow) {
                if (inputRowCells.current[i]) {
                    inputRowCells.current[i].classList.add('selectedRowAndColumn')
                }
                if (nameCellRefs.current[i]) {
                    nameCellRefs.current[i].classList.add('selectedRowAndColumn')
                }
            } else {
                if (inputRowCells.current[i]) {
                    inputRowCells.current[i].classList.remove('selectedRowAndColumn')
                }
                if (nameCellRefs.current[i]) {
                    nameCellRefs.current[i].classList.remove('selectedRowAndColumn')
                }
            }
            map(orderedActiveSemData, (assignment, j) => {
                const index = i * numCols + j;
                const isActiveCol = activeIndex >= 0 && (activeIndex - ((i * numCols) + j)) % numCols === 0;
                const isActiveCell = (isActiveCol && activeIndex >= 0 && Math.floor(activeIndex / numCols) === i)
                if (isActiveCol) {
                    if (inputCells.current[index]) {
                        if (isActiveCell) {
                            inputCells.current[index].classList.add('active-input-cell')
                        } else {
                            inputCells.current[index].classList.remove('active-input-cell')
                        }
                        inputCells.current[index].classList.add('selectedRowAndColumn')

                    }
                } else {
                    if (inputCells.current[index]) {
                        inputCells.current[index].classList.remove('selectedRowAndColumn')
                        inputCells.current[index].classList.remove('active-input-cell')
                    }
                }
            })
        })
    }, [activeIndex, listStudentUser, orderedActiveSemData])

    const mainGradeTableActive = activeGradeTab == ""
    return (
        <Wrapper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openPop}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {popupMessage}
                </Alert>
            </Snackbar>


            {dataLoading ? (
                <Loading />
            ) : (
                <div>
                    {mainGradeTableActive ?
                        <TableWrapper className="orgTableData">
                            <OverflowOne>
                                <Table>
                                    <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                        <TableCellExam header="true" width='415px' style={{ width: '100%', backgroundColor: rowSemester, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', justifyContent: "center" }}
                                            onClick={() => {
                                                onExamTabClick("exam1")
                                            }}>
                                            <div>
                                                Exams
                                            </div>
                                        </TableCellExam>
                                    </TableHeader>
                                    <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                        {Object.keys(semAssignment).map((key, index) => {
                                            CountSemester = Object.keys(semAssignment).length
                                            return (
                                                <TableCellSemester header="true" width={"208px"} style={{ backgroundColor: rowSemester, color: 'white', height: "30px", fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', justifyContent: "center" }}
                                                    active={key == activeSem} key={key} onClick={() => onSemClick(key)}>
                                                    {key}
                                                </TableCellSemester>
                                            )
                                        })
                                        }
                                        {CountSemester === 1 &&
                                            <TableCellSemester header="true" width='208px' style={{ backgroundColor: rowTwoColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', justifyContent: "center" }}></TableCellSemester>
                                        }
                                    </TableHeader>
                                    <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>

                                        <TableCell header="true" width='208px' style={{ backgroundColor: rowThreeColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', justifyContent: "center" }}>
                                            <div>
                                                {nameShortType.title}
                                            </div>
                                        </TableCell>


                                        <TableCell header="true" width={"208px"} style={{ backgroundColor: rowThreeColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', justifyContent: "center" }}>Average</TableCell>
                                    </TableHeader>
                                    {listStudentUser.map((user, i) => (
                                        <TableRow key={user?.id ?? i + "user"} index={i + 1} style={{ borderRight: '0px' }} ref={(el) => (nameCellRefs.current[i] = el)}>
                                            <UserNameCellTableCell userActive={user?.userclass?.active}>
                                                {getUserName(user)}
                                            </UserNameCellTableCell>
                                            <AverageTableCell header active style={{ fontSize: '14px' }}>
                                                <TootipWrapper hasToolTip label={"Average grade"}>
                                                    {calculateGrade(user.id)}
                                                </TootipWrapper>
                                            </AverageTableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </OverflowOne>
                            <TableWrapperInner2>
                                <OverflowTwo >
                                    <Table>
                                        <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                            {renderBookLabel}
                                        </TableHeader>
                                        <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                            {renderAssignmentLabel}
                                        </TableHeader>
                                        <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                            {renderDueDates}
                                        </TableHeader>
                                        {listStudentUser.length === 0 ? (
                                            <EmptyState message="Currently 0 students enrolled in this class." />
                                        ) : (
                                            renderTableCell
                                        )}
                                    </Table>
                                </OverflowTwo>
                            </TableWrapperInner2>
                        </TableWrapper> : <ExamTables
                            onClose={() => {
                                setActiveGradeTab(""); setGradeViw('gradetable')
                            }}
                            onExamTabClick={onExamTabClick}
                            activities={activities}
                            dataLoading={dataLoading}
                            quizzes={quizzes}
                            users={users}
                            submissions={submissions}
                            currentUser={currentUser}
                            classActivities={classActivities}
                            classQuizzes={classQuizzes}
                            setExamData={setExamData}
                            examType={activeGradeTab}
                            exams={sortExam()}
                        />}
                </div>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ParentGradetable.propTypes = {};

//  -- Default props ------------------
ParentGradetable.defaultProps = {};

export default ParentGradetable;
