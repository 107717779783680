//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
const selectore = (state) => {
    return state.licences
};
export const useLicences = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}