import { Tooltip } from "antd";
import ClickOutside, { OnClickOutside } from "components/OnClickOutside";
import React from "react";
import styled from "styled-components";
import BottomDropDown from "utils/assets/images/resource-dropdown.svg";

const CategoryMenu = styled.div`
  background-color: #0b1c2b;
  border-radius: 30px;
  color: #fff;
  // width: 201px;
  height: 42px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  // text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #2784a3;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 768px) {
    // width: 100% !important;
    height: 36px;
    color: #fff;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const PaddedBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 8px;
  text-align: left;
  width: 100%;
`;

const IBThemeDropdown = ({
  data,
  open,
  dropDownHandler,
  themeFilter,
  closeDropDown,
  handleDropdownClick,
  isResizingSizing
  
}) => {
  return (
    <>
      <CategoryMenu
        onClick={() => handleDropdownClick("ibtheme")}
        style={{
          padding: 0,
          margin: 0,
          border: "1px solid #ffffff",
        }}
        className="cursor-pointer md-w-full-2 box-shadow-resource-hover lg-ml-0 ml-1px  lg-w-160px arrow-contanier-resoruce h-43px lg-h-36px  w-173px"
      >
        <Tooltip placement="top" title={themeFilter?.join(", ").toString()}>
          <span className="dropdown-text lg-pl-17px pl-16px">
            {themeFilter &&
            themeFilter?.join(",") !== "" &&
            themeFilter?.length > 0
              ? themeFilter?.join(",").length > 10
                ? themeFilter?.join(",").slice(0, 10) + "..."
                : themeFilter
              : "IB THEMES"}
          </span>
        </Tooltip>

        {/* <MenuIconImg src={MenuIcon} />{" "} */}
        <div className="dropdown-resource-arrow-container">
          <div
            style={{
              transition: "transform 0.4s ease-in-out",
              transform:
               open
                  ? "rotate(-180deg)"
                  : "rotate(0deg)",
            }}
          >
            <img src={BottomDropDown} alt="" />
          </div>
        </div>
      </CategoryMenu>
      {open && (
          <ClickOutside onClickOutside={closeDropDown} className="ib-theme-dropdown-resource bg-dropdown-for-resource top-59px  box-shadow-dropdown-resource border-radius-14px absolute z-index-990">
            {data?.map((ct, index) => (
              <PaddedBtn
                key={index}
                style={{
                  padding: "0",
                  height: "60px",
                  borderBottom:
                    index === data.length - 1 ? "unset !important" : undefined,
                }}
                className="ib-theme-button border-for-inner"
              >
                <div
                  className="dropdown-menus-item cursor-pointer h-full d-flex items-center h-full w-full"
                  style={{
                    paddingLeft: "20px",
                  }}
                  onClick={() => dropDownHandler("ibThemeFitler", ct?.title)}
                >
                  <input
                    className="w-16px-h-16px mdm-mr-12px"
                    type="checkbox"
                    checked={themeFilter?.includes(ct?.title)}
                  />
                  <img
                    src={ct?.icon}
                    loading="lazy"
                    className="md-d-none d-block image-dropdown-ancodtas"
                    alt=""
                  />
                  <div className="dropdown-list-name-ap-theme">{ct?.title}</div>
                </div>
              </PaddedBtn>
            ))}
          </ClickOutside>
      )}
    </>
  );
};

export default IBThemeDropdown;
