//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ClasslinkActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------


export const handleClassLinksUserClass = async (data, sourceId) => {

	try {
		return await API.post('classlinkRDS', `/userClasses/${sourceId}`, {
			body: { ...data }
		});

	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return error;
	}
};
export const handleUserClassesSync = async (sourceId , tenantId ) => {

	try {
		return await API.post('classlinkRDS', `/handleUserClasses`, {
			body: {   
			"SourcedId": sourceId, 
			"TenantId": tenantId 
		}
		});

	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return error;
	}
};
export const handlesynClassUsers = async (sourceId , tenantId , class_id ) => {

	try {
		return await API.post('classlinkRDS', `/studentSync`, {
			body: {   
			"SourcedId": sourceId, 
			"TenantId": tenantId ,
			class_id
		}
		});

	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return error;
	}
};
export const handleClassLinksUserInfo = async (sourceId, tenantId) => {

	try {
		return await API.get('classlinkRDS', `/handleUserById/${sourceId}/${tenantId}`);

	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return error;
	}
};


export const getClasslinkInformation = async (code) => {
	try {
		return await API.get("classlinkRDS", `/classlinkInformation/${code}`, {});
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return ({ error })
	}
}


export const handleProcessClassTenant = () => async (dispatch) => {

	try {
		const tenant = await API.get('classlinkRDS', `/getClasslinkTenant`);
		await dispatch(ClasslinkActions.setTenantSuccess(tenant));
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		await dispatch(ClasslinkActions.setTenantFail(error));
	}
};
export const getClasslinkUserInfo = async (tenantid, id) => {
	try {
		return await API.get("classlinkRDS", `/getClasslinkUserInfo/${tenantid}/${id}`);
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return ({ error })
	}
}
export const getClasslinkUserInfoOnOwe = async (tenantid, id) => {
	try {
		return await API.get("classlinkRDS", `/getClasslinkUserById/${tenantid}/${id}`);
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return ({ error })
	}
}
export const handleUserByClassLinkName = async (name) => {
	if (typeof name != "string") return [];
	try {
		const firstNamme = name.replace(/\s\s+/g, ' ').split(" ")[0].trim() || '';
		const secondNamme = name.replace(/\s\s+/g, ' ').split(" ")[1]?.trim() ||'';
		const classlinkuser = await API.get("usersRDS", `/classlinkUserByName/${firstNamme || secondNamme }/${secondNamme || firstNamme}`)
		return classlinkuser;

	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return ({ error: true });
	}
}


export const handleListClasslinkKeys = async () => {
	try {
		const data = await API.get('classlinkRDS', `/list_keys`);
		return data;
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return {}
	}
}
export const handleUpdateClasslinkKeys = async (config) => {
	try {
		const data = await API.post('classlinkRDS', `/update_keys`, {
			body: { config }
		});
		return data;
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return {}
	}
}