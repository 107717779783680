import React, { useEffect, useState } from 'react';
import { useAllBook } from 'store/books/selectors';
import { useClassActivities } from 'store/classActivities/selectors';
import { useClassQuizzes } from 'store/classQuizzes/selectors';
import { useCurrentClass } from 'store/classes/selectors';
import { sortname } from "utils/methods/math";
// import { useServertime } from 'store/servertime/selectors';
import { useClassSubmissions } from 'store/submissions/selectors';
import { useUsersByClass } from "store/userClasses/selectors";
import _, { get, find, map, filter, groupBy } from "lodash"
import { Drawer } from "antd"
import AssignmentReview from 'features/forms/AssignmentReview';
import { useUserPreference } from "store/userPreference/selectors"
import { useDispatch } from 'react-redux';
import { handleListClassSubmissions } from 'store/submissions/thunks';
import { useCurrentOrg } from "store/organizations/selectors"
import { Visibility } from "@material-ui/icons";
import { IconButton } from "@material-ui/core"
export default function AssignmentReviewByNotify({ assignment, oncloseDrawer, assignmentDrawer, selectedNotification }) {
    const classActivities = useClassActivities()
    const usersByClass = useUsersByClass()
    const classQuizzes = useClassQuizzes()
    const submissions = useClassSubmissions()
    const booksAllList = useAllBook()
    const dispatch = useDispatch();
    const currentClass = useCurrentClass()
    const userPreference = useUserPreference()
    const currentOrg = useCurrentOrg()
    const [selectedAssignmentUserSubmission, setSelectedAssignmentSubmissions] = useState([])
    const [assignmentData, setAssignmentData] = useState({});
    const currentClassUsers = usersByClass ? usersByClass[get(currentClass, "id")] : []
    const studentSubmissions = groupBy(submissions, 'userId');
    const listStudentUser = filter(currentClassUsers, x => x.role === 'student').sort(sortname)
    // const firstStudentOfClass = currentClassUsers ? currentClassUsers[0] : {}
    const fetchAllData = async () => {
        dispatch(handleListClassSubmissions(get(currentClass, "id")))
    }
    const getAssignmentCreation = (assignmentdata, userdata) => {
        try {
            let _assignmentinfo = assignmentdata.data ?? {};
            let quizdata = {
                answers: [],
                assignment: _assignmentinfo,
                assignmentId: _assignmentinfo.id,
                assignmentType: _assignmentinfo.typeId,
                chapter: _assignmentinfo.chapter,
                classId: currentClass.id,
                complete: false,
                createdAt: Date.now(),
                feedback: '',
                grade: '0',
                graded: true,
                isLate: false,
                orgId: currentOrg?.id,
                retakes: assignmentdata.retakes,
                retaking: false,
                submissions: 0,
                timmer: 0,
                user: userdata,
                userId: userdata.id,
                bookId: _assignmentinfo.bookId,
                isTemp: true
            }
            return quizdata;
        } catch (error) {
            return {};
        }
    }
    const reviewAssignment = (data, tempData, users, userSubmissions) => {
        const _assignment = [...classActivities, ...classQuizzes].filter(a => (a.quizId === get(data, "assignmentId")) || a.activityId === get(data, "assignmentId"))[0];
        let sAssignmentId = get(data, "sAssignmentId");
        if (_assignment && _assignment.sAssignmentId) {
            sAssignmentId = _assignment.sAssignmentId;
        }
        if (data) {
            data.sAssignmentId = sAssignmentId;
        }
        if (!data) {
            const utemp = _.cloneDeep(tempData);
            utemp.isTemp = true;
            data = utemp;
            data.sAssignmentId = sAssignmentId;
        }
        const userdata = map(users, usr => {
            const sub = userSubmissions[usr.id]
            if (sub) {
                const asub = find(sub, n => get(n, "assignmentId") == get(data, "assignmentId", get(tempData, "assignmentId")))
                if (asub) {
                    asub.sAssignmentId = sAssignmentId;
                    return asub
                } else {
                    const utemp = _.cloneDeep(tempData);
                    utemp.userId = usr.id;
                    utemp.user = usr;
                    utemp.isTemp = true;
                    utemp.sAssignmentId = sAssignmentId;
                    return utemp;
                }
            } else {
                const utemp = _.cloneDeep(tempData);
                utemp.userId = usr.id;
                utemp.user = usr;
                utemp.isTemp = true;
                utemp.sAssignmentId = sAssignmentId;
                return utemp;
            }
        })
        setSelectedAssignmentSubmissions(userdata)
        setAssignmentData(data);
        // toggleAssignmentDrawer(true);
    };
    const onClick = () => {
        const firstStudentOfClass = find(currentClassUsers, obj => get(obj, 'id', '') == get(selectedNotification, 'userId', ''));
        const tempData = getAssignmentCreation(assignment, firstStudentOfClass);
        let v = tempData;
        if (firstStudentOfClass && studentSubmissions[firstStudentOfClass.id]) {
            const asub = find(studentSubmissions[firstStudentOfClass.id], n => get(n, "assignmentId") == get(assignment, "assignmentId", get(tempData, "assignmentId")))
            if (asub) {
                v = asub;
            }
        }
        reviewAssignment(v, tempData, listStudentUser, studentSubmissions)
    }
    useEffect(() => {
        // fetchAllData()
        onClick()
    }, [assignment, assignmentDrawer, selectedNotification])
    const onClose = () => {
        // toggleAssignmentDrawer(false)
        oncloseDrawer()
    }
    return (<>
        <Drawer
            height='100%'
            destroyOnClose
            onClose={() => {
                // toggleAssignmentDrawer(false);
                setAssignmentData({});
                setSelectedAssignmentSubmissions([])
                oncloseDrawer()
            }}
            placement='top'
            open={assignmentDrawer}
            key='assignmentNew'
        >
            <AssignmentReview
                booksAllList={booksAllList}
                data={assignmentData}
                usersSubmissionData={selectedAssignmentUserSubmission}
                currentClass={currentClass}
                handleClose={() => {
                    onClose()
                    fetchAllData();
                }}
                scale_1_5={userPreference?.gradePref == "scale_1_5"}
                gradebook_a_f={userPreference?.gradePref === "gradebook_a_f"}
            />
        </Drawer>
    </>

    )
}
