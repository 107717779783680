//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';

//  -- Components ---------------------
import Loading from 'components/Loading';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Config -------------------------
import { instructionVideos } from 'utils/config/instructionVideos';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 16px 0px 0px;
    position: relative;
    width: 100%;

    @media (min-width: 800px) {
        flex-direction: row;
        padding: 0px 64px;
    }
`;

const Header = styled.div`
    border-bottom: 1px solid ${COLOR.black700};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
    padding-bottom: 8px;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
`;

const Item = styled.button`
    background-color: ${p => p.active ? lighten(0.5, '#418E4A') : lighten(0.5, '#3D5F8F')};
    border: 1px dashed ${p => p.active ? '#418E4A' : '#3D5F8F'};
    border-radius: 3px;
    color: ${p => p.active ? '#418E4A' : '#3D5F8F'};
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 8px;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;

    &:hover {
        background-color: ${p => p.active ? lighten(0.4, '#418E4A') : lighten(0.4, '#3D5F8F')};
    }
`;

const ToolsContainer = styled.div`
    height: auto;
    margin-top: 48px;
    width: 100%;

    @media (min-width: 800px) {
        height: ${p => `${p.height}px`};
        margin-left: 48px;
        margin-top: 0px;
        width: 280px;
    }
`;

const Video = styled.video`
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    height: auto;
    outline: none;
    width: 100%;
`;

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 5% 0px;
    position: relative;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook
//
//  -- Description
//  Ebook display component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const InstructionsLayout = () => {
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(0);

    const selectVideo = (i) => {
        setLoading(true);
        setSelected(i);
        setTimeout(() => setLoading(false), 1000);
    }

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    const showCopyRight = () => {
        setState({open: true,vertical: 'top', horizontal: 'center'});
    }

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return (
        <>
            <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open} 
            autoHideDuration={3000}
            onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> Content is protected!
                </Alert>
            </Snackbar>
        <Wrapper>
            <Container>
                <ImageContainer>
                    {loading ? (
                        <Loading />
                    ) : (
                        <Video onContextMenu={(e)=>{
                            e.preventDefault();
                            showCopyRight()
                        }} controls controlsList="nodownload" width="100%">
                            <source src={instructionVideos[selected]?.src} type="video/mp4" />
                        </Video>
                    )}
                </ImageContainer>
                <ToolsContainer height={602}>
                    <Header>How To Videos</Header>
                    {instructionVideos.map((video, i) => (
                        <Item active={i === selected} onClick={() => selectVideo(i)}>
                            {video.displayName}
                        </Item>
                    ))}
                </ToolsContainer>
            </Container>
        </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
InstructionsLayout.propTypes = {
    children: string
};

//  -- Default props ------------------
InstructionsLayout.defaultProps = {
    children: 'Hello world'
};

export default InstructionsLayout;
