//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- Update user --------------------
export const createSchoologyAuth = (returnedPayload) => ({ type: ActionTypes.schoology.setAuthToken, payload: returnedPayload });
export const updateSchoologyAuth = (returnedPayload) => ({ type: ActionTypes.schoology.setAuthToken, payload: returnedPayload });
export const clearSchoologyAuth = () => ({ type: ActionTypes.schoology.clearAuthToken });

export const setSchoologyGradeCategory = (returnedPayload) => ({ type: ActionTypes.schoology.setGradeCategory , payload: returnedPayload });
export const setSchoologyAllClassGradeCategory = (returnedPayload) => ({ type: ActionTypes.schoology.setAllClassGradeCategorySuccess , payload: returnedPayload });
export const updateSchoologyGradeCategory = (returnedPayload) => ({ type: ActionTypes.schoology.updateGradeCategory , payload: returnedPayload });


export const setSchoologyAssignment = (returnedPayload) => ({ type: ActionTypes.schoology.setSchoologyAssignment , payload: returnedPayload });
export const updateSchoologyAssignment = (returnedPayload) => ({ type: ActionTypes.schoology.updateSchoologyAssignment , payload: returnedPayload });


