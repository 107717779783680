//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';
import { AppBar, Badge, Collapse, IconButton, Slide, Toolbar, Typography, alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle, ArrowBack, AttachFile, Notifications, WbIncandescent } from "@material-ui/icons";
import { Avatar } from 'antd';
import { filter, find, get, groupBy, has, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { useChatConfig, useChatMessage, useChatUser, useSocket } from 'store/chat/selectors';
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentUser } from 'store/users/selectors';
import styled from 'styled-components';
import { getAvatar } from 'utils/assets/images/lootie';
import COLOR from 'utils/constants/color';
import { sortMessage } from "utils/methods/math";
import Messages from "./messages";
import { chat_icon } from 'utils/assets/images/icons';
import { newChats } from 'utils/assets/images';
//  -- Constants ----------------------
//  -- Components ---------------------
//  -- Thunks -------------------------

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  })

const FILE_TYPE = {
    'application/msword': "word",
    "application/vnd.ms-excel": "excel",
    "application/vnd.ms-powerpoint": "powerpoint",
    "text/plain": "text",
    "application/pdf": "pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "word",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "excel",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "powerpoint"
}
const Wrapper = styled.div`
    width: 250px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    // background:rgb(82,84,82);
    background:#fff;
    z-index: 11;
    box-shadow: -1px 0px 0px 0px #69696936, 0px -2px 7px dimgrey;
    transition: all 1s ease 0s;
    opacity:${p => p.visible ? 1 : 0};
    overflow: hidden;
`;
const UserList = styled.div`
    min-height: 40px;
    width: 98.5%;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    margin-top: 10px;
    background: transparent;
    border: none;
    text-align: left;
    display:flex;
    flex-direction:row;
    font-size: 12px;
    text-transform: capitalize;
    align-items: center;
`;

const OnlineIndicater = styled.div`
        height:10px;
        width:10px;
        background-color:${COLOR.green100};
        border-radius: 50%;
        position: absolute;
        right: 0px;
        top: 0px;
`;

const UserArea = styled.div`
  display:flex;
  flex-direction:column;
  overflow:auto;
  overflow-x:hidden;
  padding-top: 0px;
`;
const Container = styled.div`
    display:flex;
    flex-direction:column;
    //height:75vh;
`;
const OptionArea = styled.div`
    display:flex;
    flex-direction:column;
    padding: 0px 10px;
    width: 100%;
    height: 18vh;
`;
const MessageText = styled.div`
  width:70%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  text-align:center;
  color:#fff;
  font-size: 2.5vw;
  font-weight: 400;
  padding-left:10px
`;
const SearchArea = styled.div`
  border-radius: 20px;
  height: 32px;
  display:flex;
  flex-direction:row;
  padding:2px;
  background: #3c7275;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
`;
const SearchIconArea = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

`;
const SearchInput = styled.input`
  width:90%;
  height:30px;
  font-size:15px;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 5px;
  color:#fff;
  ::placeholder,
  ::-webkit-input-placeholder {
      color: ${COLOR.white};
   }
`;
const ClassAnnouncment = styled.div`
    min-height: 32px;
    margin-top: 10px;
    background: #3c7275;
    color: #fff;
    width: 98%;
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    overlow:hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px 10px;
`;

const UserRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction:row;
    width:100%;
    position: relative;
`;
const AvtarIconCol = styled.div`
    display: flex;
    align-items: center;
    width:17%;
    position:relative;
    @media (max-width: 992px) {
        width: auto;
        margin-right: 12px;
    }
`;
const AvtarContainer = styled.div`
        display: flex;
        align-items: center;
        justify-content:center;
        position:relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
`;
const NameCol = styled.div`
    display: flex;
    align-items: center;
    width:83%;
    flex-direction:column;

`;
const ChatNameCol = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: row;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;

`;
const ThumbContainser = styled.div`
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right:5px;
`;
const NameInnerRow = styled.div`
    display: flex;
    width:100%;
    font-weight: bold;
`;
const NameText = styled.span`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
`;
const TimeText = styled.span`
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    width: 40%;
    text-align: right;
}
`;
const MessageInnerRow = styled.div`
    display: block;
    width:100%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ui-sans-serif;
    align-items:center;
`;
const UserModal = styled.div`
    left: 50%;
    max-width: 400px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const UserPopUpArea = styled.div`
    background-color: #fff;
    height: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    position:relative;
`;
const MessageCounterNotificationv = styled.span`
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0px;
    top: 40%;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    background: rgb(20 89 194);
    overflow: hidden;
    color:#fff;
`;
const ButtonWrapper = styled.div`
  max-height: 58px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: transparent;
  z-index: 11;
  -webkit-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  cursor: pointer;
  margin-bottom: 5px;
}
`;
const UserName = styled.span`
    // color: rgb(218,218,218);
    text-transform: capitalize;
    font-size: 12px;
`;
const RowHeader = styled.div`
    display:flex;
    flex-direction:row;
    width:100%
`;
const Header = styled.div`
    width:100%;
    border-bottom:1px solid gray;
    background-color:transpatent;
    display:flex;
    align-items: center;
    position: relative;
`;
const OptionAreaBox = styled.div`
     min-height:10vh;
     width:100%
     fidplay:flex;
     flex-direction:column;
`;
const IdeaImage = styled.img`
    // height: 30px;
    // width: 90px;
`;
const useStyles = makeStyles((theme) => ({
    bulbicon: {
        color: ' rgb(223 175 109)',
        fontSize: '18px',
        rotate: 'deg(180)',
        transform: 'rotate(180deg)',
        marginRight: '5px'
    },
    usericon: {
        fontSize: '30px',
        marginRight: ' 5px'
    },
    usericonPopUp: {
        color: COLOR.gray100,
        fontSize: '30px',
        marginRight: ' 5px'
    },
    addIcon: {
        color: 'rgb(255, 255, 255)',
        fontSize: '20px',
        marginRight: ' 5px'
    },
    sendIcon: {
        color: 'rgb(255, 255, 255)',
        fontSize: '20px',
        marginRight: ' 5px'
    },
    fileIconWhite: {
        color: "#fff",
        fontSize: 10
    },
    fileIconGray: {
        color: COLOR.gray100,
        fontSize: 10
    },
    newMessageTitle: {
        marginTop: '-10px',
        marginBottom: '10px',
        fontWeight: "bold",
        textAlign: "center"
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    paper: {

    }
}));
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------

const getTimeMessageTime = (messageTime) => {
    const dayDiff = moment().diff(moment(messageTime), "day");
    const weekDiff = moment().diff(moment(messageTime), "week");
    const monthDiff = moment().diff(moment(messageTime), "month");
    const yearDiff = moment().diff(moment(messageTime), "year")
    if (!messageTime) return null;
    if (dayDiff == 0) {
        const hourDiff = moment().diff(moment(messageTime), "hours")
        const minutDiff = moment().diff(moment(messageTime), "minutes")
        const secondDiff = moment().diff(moment(messageTime), "seconds")
        if (secondDiff > 15) {
            if (minutDiff > 59) {
                return `${hourDiff} hr`
            }
            return minutDiff > 0 ? `${minutDiff} min` : `${secondDiff} sec`;
        }
        return `Just now.`
    } else {
        if (dayDiff > 7) {
            if (weekDiff > 5) {
                if (monthDiff > 12) {
                    return `${yearDiff} y`
                }
                return `${monthDiff} m`
            }
            return `${weekDiff} w`
        }
        return `${dayDiff} d`
    }
}
const SearchAreaInput = ({ onChange }) => {
    return (
        <SearchArea>
            <SearchIconArea>
                <SearchOutlined />
            </SearchIconArea>
            <SearchInput placeholder='Search' onChange={onChange} />
        </SearchArea>
    )
}

const ChatPopUp = ({
}) => {
    const cssClasses = useStyles();
    const currentClass = useCurrentClass()
    const chatUser = useChatUser()
    const chatMessages = useChatMessage()
    const { socketConnection, reCreateSocket } = useSocket()
    const currentUser = useCurrentUser()
    const [activeUsers, setactiveUser] = useState([])
    const [activeClass, setactiveClass] = useState({})
    const [expendChatArea, setExpendChatArea] = useState(false)
    const [messageCounter, setMessageCounter] = useState(0)
    const [recentUserMessages, setRecentMessage] = useState([])
    const [filteredUser, setFilteredUser] = useState([])
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [selectedUser, setSelectedUser] = useState(null)
    const userChatConfig = useChatConfig()
    const [unreadBrodCastMessage,setUnreadBrodcastmessage] = useState({})
    useEffect(() => {
        if(chatMessages&&chatMessages.length>0){
         let _count = (chatMessages || []).reduce((accumulator, c) => {
            if (
                c.receiverId === get(currentUser, 'id') &&
                !c.seen &&
                !c.brodcastId &&
                c.classId === activeClass?.id
            ) {
                return accumulator + 1;
            }
            return accumulator;
        }, 0);
       
        const brodcastMessage = groupBy(chatMessages, "brodcastId")
        if(get(currentUser,"role")==="student"){
            let v = {};
            Object.keys(brodcastMessage).map(key=>{
            if(key==null||!key || key=="null"){ 
                return;
            }
            else{
                v[key] =  filter(brodcastMessage[key],msg =>!find(msg.brodcastSeen, ["id", currentUser.id])).length;
            }
            })
            setUnreadBrodcastmessage(v)
            const activrClassVal  =  v[get(activeClass,"id")]>0? v[get(activeClass,"id")]:0
            _count+=activrClassVal
        }
        setMessageCounter(_count)
    }
    }, [chatMessages,activeClass])
    useEffect(()=>{
        const isStudent = get(currentUser,"role") === 'student';
        const notifi = get(userChatConfig, "notification", true)
        if((notifi||isStudent) && messageCounter>0){
            setExpendChatArea(true)
        }
    },[messageCounter,userChatConfig,currentUser])
    useEffect(() => {
        const interval = setInterval(forceUpdate, 15000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    useEffect(() => {
        const currentClassChatUser = find(chatUser, c => get(c, "classes.id") === get(currentClass, "id"))
        const _activeClass = get(currentClassChatUser, "classes", {});
        const _activeUsers = get(currentClassChatUser, "user", [])
        setactiveClass(_activeClass)
        setactiveUser(_activeUsers)
    }, [chatUser, currentClass])
    useEffect(() => {
        const currentClassChatUser = find(chatUser, c => get(c, "classes.id") === activeClass.id)
        if (selectedUser) {
            let _selectedUser = find(get(currentClassChatUser, "user", []), n => n.id === selectedUser.id)
            if (_selectedUser) {
                setSelectedUser(_selectedUser)
            }
        }
    }, [chatUser, activeClass])
    useEffect(() => {
        const userMessages = { ...groupBy(chatMessages, "senderId"), ...groupBy(chatMessages, "receiverId"), ...groupBy(chatMessages, "brodcastId") }
        const userMessagesData = map(Object.keys(userMessages), key => {
            let usr = find(activeUsers, ['id', key])
            if (!usr) {
                return null;
            }
            const mData = userLastmessage(key)
            const unseenCount = unseenMessage(key)
            return ({
                usr,
                mData,
                unseenCount
            })
        }).filter(k => k);
        map(activeUsers, act => {
            const nuser = find(userMessagesData, ["usr.id", act.id])
            if (!nuser) {
                userMessagesData.push({
                    usr: act,
                    mData: {},
                    unseenCount: 0
                })
            }
        })
        const sorteduserMessagesData = userMessagesData.sort((a, b) => (new Date(get(b, "mData.createdAt")) - new Date(get(a, "mData.createdAt"))))
        setRecentMessage(sorteduserMessagesData)
        setFilteredUser(sorteduserMessagesData)
    }, [chatMessages, chatUser, activeUsers])

    const userLastmessage = (userId) => {
        return sortMessage(filter(chatMessages, c => c.receiverId && (c.senderId === userId || c.receiverId === userId))).reverse()[0]
    }
    const unseenMessage = (userId) => {
        return filter(chatMessages, c => (c.senderId === userId && c.receiverId && !c.seen)).length
    }
    const getUserIcon = (usr) => {
        const avatar = getAvatar(get(usr, "config.avatar"))
        return (avatar ? <Avatar size={30} src={avatar} /> : <AccountCircle className={cssClasses.usericon} />)
    }
    const RecenctRender = useCallback(() => {
        return (map(filteredUser, dt => {
            const usr = dt.usr
            const mData = dt.mData
            const unseenCount = dt.unseenCount
            const isBordcast = !get(usr, "username")
            return (<UserList key={get(usr, "id")} onClick={() => {
                setSelectedUser(usr)
            }}>
                <UserRow>
                    <AvtarIconCol>
                        <AvtarContainer>
                            {getUserIcon(usr)}
                            {usr.active && <OnlineIndicater />}
                        </AvtarContainer>
                    </AvtarIconCol>
                    <NameCol className="recenctUsr">
                        {unseenCount > 0 ? <MessageCounterNotificationv>{unseenCount}</MessageCounterNotificationv> : null}
                        <NameInnerRow className="recenctUsrRw"> <NameText>{isBordcast ? `${get(usr, "displayName")}` : `${get(usr, "lastName", "")} ,  ${get(usr, "firstName", "")}`} </NameText> <TimeText>{getTimeMessageTime(get(mData, "createdAt"))} </TimeText> </NameInnerRow>
                        <MessageInnerRow className="recenctUsrIn"> {mData ? get(mData, "type") == "media" ? <> {<AttachFile style={{ fontSize: 10 }} />} Attachment  {get(mData, "message", "")} </> : get(mData, "message", "") : ''} </MessageInnerRow>
                    </NameCol>
                </UserRow>
            </UserList>)
        }))
    }, [filteredUser])
    const getMessage = useCallback(() => {
        if (selectedUser && selectedUser.id) {
            const brodcastMessage = groupBy(chatMessages, "brodcastId")
            const selectedUserId = selectedUser.id
            if (brodcastMessage[selectedUserId]) {
                return sortMessage(brodcastMessage[selectedUserId]);
            } else {
                return sortMessage(filter(chatMessages, k => k.receiverId && (k.senderId == selectedUserId || k.receiverId && k.receiverId == selectedUserId)))
            }
        } else {
            return []
        }
    }, [selectedUser, chatMessages])
    const RenderChatScreen = useCallback(() => {
        const selectedUserConfig = get(selectedUser, "config", {})
        const isBordcast = !get(selectedUser, "username")
        return (<>
            <Header>
                <RowHeader>
                    <ChatNameCol>
                        <ThumbContainser>
                            {!isEmpty(selectedUserConfig) && selectedUserConfig.avatar && getAvatar(selectedUserConfig.avatar) ? <Avatar src={getAvatar(selectedUserConfig.avatar)} size={30} style={{ marginRight: 10 }} /> : <AccountCircle style={{
                                fontSize: '30px',
                                marginRight: ' 10px'
                            }} />}
                            {has(selectedUser, "active") && selectedUser.active && <OnlineIndicater />}
                        </ThumbContainser>


                        {!isBordcast ? <UserName>{get(selectedUser, "lastName", "")}, {get(selectedUser, "firstName", "")}({get(selectedUser, "username", "")})</UserName> : <UserName>{get(selectedUser, "displayName", "")}(Broadcast)</UserName>}
                    </ChatNameCol>
                </RowHeader>
            </Header>
            <Messages
                socket={socketConnection}
                socketRenew={reCreateSocket}
                expendChatArea={expendChatArea}
                selectedUser={selectedUser}
                activeClass={activeClass}
                message={getMessage()}
                FILE_TYPE={FILE_TYPE}
                activeUsers={activeUsers}
                classes={map(chatUser, u => u.classes)}
            />
        </>)
    }, [selectedUser, socketConnection, chatUser, chatMessages , activeUsers , activeClass])
    const onSearchUser = (e) => {
        try {
            let txt = e.target.value ? e.target.value : '';
            if (txt && txt.length > 0) {
                txt = txt.toLowerCase().replace(/\s/g, '')
                const val = filter(recentUserMessages, data => {
                    const act = data.usr;
                    if (!act) return false;
                    const lastName = get(act, "lastName", "").toLowerCase().replace(/\s/g, '');
                    const firstName = get(act, "firstName", "").toLowerCase().replace(/\s/g, '');
                    const username = get(act, "username", "").toLowerCase().replace(/\s/g, '');
                    const displayname = get(act, "displayName", "").toLowerCase().replace(/\s/g, '');
                    if (lastName.includes(txt)) {
                        return true;
                    } else if (firstName.includes(txt)) {
                        return true;
                    } else if (username.includes(txt)) {
                        return true;
                    } else if (displayname.includes(txt)) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setFilteredUser(val)

            } else {
                setFilteredUser(recentUserMessages)
            }
        } catch (error) {
            setFilteredUser(recentUserMessages)
        }
    }
    function onExpend(event) {
        if (typeof event.detail === "object" && event.detail.userId) {
            const user = find(activeUsers, { id: event.detail.userId })
            if (user) {
                setSelectedUser(user)
                setExpendChatArea(true)
            }
        } else {
            setExpendChatArea(!expendChatArea)
        }
    }
    return (
        <>
            {!expendChatArea && <ButtonWrapper onClick={() => setExpendChatArea(!expendChatArea)} id='chat-popup-window'>
                <IdeaImage style={{ height: get(currentUser, "role") === "student" ? 40 : 30 }} src={get(currentUser, "role") === "student" ? chat_icon : newChats} alt="Idea 11" />
            </ButtonWrapper>}
            {expendChatArea && <Wrapper visible className={`msgSystemWrap ${get(currentUser, "role") === "student" && 'chatIconsDesk'}`}>
                <AppBar position="static" color="inherit" style={{ background: "rgb(28,49,115)" }}>
                    <Toolbar style={{ paddingLeft: 10, paddingRight: 0, minHeight: 40, height: 40 }}>
                        {selectedUser ? <IconButton aria-label="back" style={{ marginLeft: "-15px" }} color="inherit" onClick={() => setSelectedUser(null)}>
                            <ArrowBack style={{ fontSize: 20, color: "#fff" }} />
                        </IconButton> : null}
                        <Typography className={cssClasses.title} variant="h6" style={{ color: "#fff", fontSize: 16 }} noWrap onClick={() => setExpendChatArea(!expendChatArea)}>
                            {selectedUser ? "Chat Notification" : "1WayMessaging"}
                        </Typography>
                        <div className={cssClasses.grow} />
                        <div className={cssClasses.sectionDesktop}>
                            {!expendChatArea && messageCounter > 0 && <IconButton aria-label="show 17 new notifications" color="inherit">
                                <Badge badgeContent={messageCounter} color="secondary" style={{ height: 12, width: 12, fontSize: 8 }}>
                                    <Notifications style={{ fontSize: 20, color: "#fff" }} />
                                </Badge>
                            </IconButton>}
                            <IconButton aria-label="" data-expended={expendChatArea} id='chat-popup-window' color="inherit" onClick={onExpend}>
                                {expendChatArea ? <CaretDownOutlined style={{ fontSize: 20, color: "#fff" }} /> : <CaretUpOutlined style={{ fontSize: 20, color: "#fff" }} />}
                            </IconButton>
                        </div>

                    </Toolbar>
                </AppBar>
                <Collapse in={expendChatArea} style={{ background: "transparent" }}>
                    <Container >
                        <Slide direction="left" in={!selectedUser} mountOnEnter unmountOnExit
                            style={{ background: "transparent", paddingLeft: 10, paddingRight: 10, paddingTop: expendChatArea ? 10 : 0, paddingBottom: expendChatArea ? 10 : 0 }}>
                            <div>
                                <OptionAreaBox>
                                    <SearchAreaInput onChange={onSearchUser} />
                                    <ClassAnnouncment style={{ position: "relative" }} onClick={() => setSelectedUser(activeClass)}>
                                        <WbIncandescent className={cssClasses.bulbicon} />Class Announcement
                                        {unreadBrodCastMessage[get(activeClass, "id")] > 0 ? <MessageCounterNotificationv style={{ backgroundColor: COLOR.red, position: "relative", marginLeft: 10 }}>{unreadBrodCastMessage[get(activeClass, "id")]}</MessageCounterNotificationv> : null}
                                    </ClassAnnouncment>
                                </OptionAreaBox>

                                <UserArea style={{ height: '60vh' }}>
                                    {RecenctRender()}
                                </UserArea>
                            </div>
                        </Slide>
                        {<Slide direction="right" in={!!selectedUser} mountOnEnter unmountOnExit>
                            <div>{RenderChatScreen()}</div>
                        </Slide>}
                    </Container>
                </Collapse>
            </Wrapper>}
        </>
    );
};

//  -- Prop types ---------------------
ChatPopUp.propTypes = {};

//  -- Default props ------------------
ChatPopUp.defaultProps = {

};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(ChatPopUp);
