//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


const selectore = (state) => {
    return state.userClasses
};
const classSelectore = (state) => state.usersByClass
const classUsersSelector = (state) => state.classUsers
export const useUserClasses = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}

export const useUsersByClass = () => {
    const selectedData = useSelector(classSelectore, shallowEqual)
    return selectedData;
}
export const useClassUsers = () => {
    const selectedData = useSelector(classUsersSelector, shallowEqual)
    return selectedData;
}