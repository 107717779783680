import { Button } from '@material-ui/core';
import { Col } from 'antd';
import React, { useEffect, useState } from 'react';

//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Loading from "components/Loading";
import { get } from "lodash";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleGetClass } from "store/classes/thunks";
import styled from 'styled-components';
import {
    IMG_7574,
    IMG_7575,
    IMG_7576,
    IMG_7577,
    IMG_7595,
    IMG_7596,
    IMG_7600,
    IMG_7605,
    IMG_7611,
    IMG_7614
} from 'utils/assets/images';
const THEME = [
    'linear-gradient(90deg, #5270fc, #5270fc 100%, #ffffff)',
    'linear-gradient(90deg, #fc9e19, #fc9e19 100%, #ffffff)',
    'linear-gradient(90deg, #002d75, #002d75 100%, #ffffff)',
    'linear-gradient(90deg, #00bdab, #00bdab 100%, #ffffff)',
    'linear-gradient(90deg, #7c2ae8, #7c2ae8 100%, #ffffff)',
    'linear-gradient(90deg, #05c1c9, #05c1c9 100%, #ffffff)'
]
const AVATARS = {
    IMG_7614,
    IMG_7611,
    IMG_7605,
    IMG_7600,
    IMG_7574,
    IMG_7575,
    IMG_7576,
    IMG_7577,
    IMG_7595,
    IMG_7596
}
const dummyPicture = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2FdummyPicture.png?alt=media&token=1354d5b3-b159-401e-aac8-122e76009c06`



const LoaderWrapper = styled.div`
position:absolute;
z-index:100;
`;

const CardItem = styled.div`
background:${p => p.bgColor ? p.bgColor : THEME[0]};
`;
const NoteText = styled.span`
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
`;


export default function ParentClassCard({ classInfo }) {
    const [avatarSelected, setAvatarSelected] = useState(null)
    const [classLoader, setClassLoader] = useState(false)
    const [theme, setTheme] = useState('')
    const [notes, setNotes] = useState('')
    const history = useHistory()
    const dispatch = useDispatch();
    const [openAlert, setOpenAlert] = React.useState({ open: false, message: "" });

    useEffect(() => {
        setNotes(get(classInfo, "config.notes", ''))
        setAvatarSelected(get(classInfo, "config.avatar", ''))
        setTheme(get(classInfo, "config.theme", ''))
    }, [classInfo])


   
    const redirectToHome = async () => {
        const isArchived = get(classInfo,"archived",false); 
        if(isArchived){
            setOpenAlert({open:true,message:`Class has been archived by your teacher please contact your teacher`});
            return;
        }
        if (classLoader) return;
        setClassLoader(true)
        await dispatch(handleGetClass(classInfo.id));
        setClassLoader(false)
        history.push(`/parent/class?view=home`);
    }
    const handleClose = () => {
        setOpenAlert({ open: false, message: '' })
    };
    return (
        <Col xs={24} sm={24} md={12} lg={8}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openAlert.open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {openAlert.message}
                </Alert>
            </Snackbar>
            <div className="userSection">
                <CardItem className="userHeader" bgColor={theme}>
                    <div className="userHeadEdit"></div>
                        <label> {get(classInfo, "displayName", '')} ({get(classInfo, "code", '')}) {classInfo.archived?"(Archived)":""}</label>
                    <div className="userHeadPic">
                        <img src={AVATARS[avatarSelected] ? AVATARS[avatarSelected] : dummyPicture } />
                    </div>
                </CardItem>
                <div className="noteSelf">
                    <div className="noteSelfInr">
                        <div className="noteSelfLeft">
                            <Button>Notes:</Button>
                            <NoteText className='noteTxtAply'>{notes}</NoteText>
                        </div>
                    </div>
                   
                
                    <div className="noteLinks">
                        <ul>
                            <li onClick={redirectToHome}><a> {classLoader ? (<LoaderWrapper> <Loading size={25} /> </LoaderWrapper>) : null} Home</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Col>
    )
}
