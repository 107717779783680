//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create quiz --------------------
export const createQuiz = () => ({ type: ActionTypes.quizzes.createQuiz });
export const createQuizFail = (returnedPayload) => ({ type: ActionTypes.quizzes.createQuizFail, payload: returnedPayload });
export const createQuizSuccess = (returnedPayload) => ({ type: ActionTypes.quizzes.createQuizSuccess, payload: returnedPayload });


export const updateQuiz = () => ({ type: ActionTypes.quizzes.updateQuiz });
export const updateQuizFail = (returnedPayload) => ({ type: ActionTypes.quizzes.updateQuizFail, payload: returnedPayload });
export const updateQuizSuccess = (returnedPayload) => ({ type: ActionTypes.quizzes.updateQuizSuccess, payload: returnedPayload });

export const deleteQuiz = () => ({ type: ActionTypes.quizzes.deleteQuiz });
export const deleteQuizFail = (returnedPayload) => ({ type: ActionTypes.quizzes.deleteQuizFail, payload: returnedPayload });
export const deleteQuizSuccess = (returnedPayload) => ({ type: ActionTypes.quizzes.deleteQuizSuccess, payload: returnedPayload });

//  -- Get quiz -----------------------
export const getQuiz = () => ({ type: ActionTypes.quizzes.getQuiz });
export const getQuizFail = (returnedPayload) => ({ type: ActionTypes.quizzes.getQuizFail, payload: returnedPayload });
export const getQuizSuccess = (returnedPayload) => ({ type: ActionTypes.quizzes.getQuizSuccess, payload: returnedPayload });

//  -- List chapter quizzes -----------
export const listChapterQuizzes = () => ({ type: ActionTypes.quizzes.listChapterQuizzes });
export const listChapterQuizzesFail = (returnedPayload) => ({ type: ActionTypes.quizzes.listChapterQuizzesFail, payload: returnedPayload });
export const listChapterQuizzesSuccess = (returnedPayload) => ({ type: ActionTypes.quizzes.listChapterQuizzesSuccess, payload: returnedPayload });

//  -- List quizzes -------------------
export const listQuizzes = () => ({ type: ActionTypes.quizzes.listQuizzes });
export const listQuizzesFail = (returnedPayload) => ({ type: ActionTypes.quizzes.listQuizzesFail, payload: returnedPayload });
export const listQuizzesSuccess = (returnedPayload) => ({ type: ActionTypes.quizzes.listQuizzesSuccess, payload: returnedPayload });
