import { Col, Row, Switch } from "antd";
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { DebateNewLogo, DebateLftIcon, DebateRgtIcon } from "utils/assets/images";

const DebateStudentDetail = ({ 
    debateInfo, 
    studentSubmission,
    indexs,
    handleClickAnswerDetail = () => null,
}) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [showStudentNames, setShowStudentNames] = useState(false);

    const swiperRef = useRef(null);
    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <div className="ideaMdlUpr">
            <div className="debateBackBtn" onClick = {() => handleClickAnswerDetail()}>
                <img src={DebateLftIcon} alt="Debate" />
            </div>
            <Row>
                <Col xs={24}>
                    <div className="debateMdlTop debateAllTopTxt debateTopBckBtn debateAllTopSpace">
                        <img src={DebateNewLogo} alt="Debate" />
                        <div className="topSliderHeaderTxt">
                            <span>{debateInfo?.question}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={24}>
                    <div className="debateStdntDetailSection">
                        <Swiper
                            effect={"flip"}
                            grabCursor={true}
                            pagination={false}
                            navigation={false}
                            className="mySwiper"
                            ref={swiperRef}
                            initialSlide={indexs}
                            onSlideChange={(swiper) => {
                                setCurrentQuestionIndex(swiper.activeIndex);
                              }}
                        >
                            {studentSubmission.map((item, index) => {
                                return(
                            <SwiperSlide>
                                <div className="allStudentDetails debateStdntSectionResult">
                                    <span>{item?.answer_text}</span>
                                    {showStudentNames ? (
                                    <p>{item?.user_name}</p>
                                    ): (
                                        <p>{`Estudiante ${index + 1}`}</p>
                                    )}
                                </div>
                            </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>

                    <div className="debateCardNavUpr">
                        <div className="debatStdntDetail">
                            <span>Show student names</span>
                            <Switch 
                                onClick={(checked) => setShowStudentNames(checked)}
                            />
                        </div>
                        <div className="debateCardNav">
                            <button
                                className={`debateNavbtn debateNavPre ${currentQuestionIndex === 0  ? 'btnDisabled' : ''}`}
                                onClick={handlePrev}
                                disabled={currentQuestionIndex === 0}
                            >
                                <img src={DebateLftIcon} alt="Debate" />
                            </button>
                            <button
                                className={`debateNavbtn debateNavNxt ${currentQuestionIndex === studentSubmission?.length - 1 ? 'btnDisabled' : ''}`}
                                onClick={handleNext}
                                disabled={currentQuestionIndex === studentSubmission?.length - 1}
                            >
                                <img src={DebateRgtIcon} alt="Debate" />
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};


export default DebateStudentDetail;