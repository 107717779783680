//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map , filter } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const examsInitial = [];




export const oneway_teacher_video = (state = examsInitial, action) => {
    switch (action.type) {
        case ActionTypes.oneway_teacher.create_oneway_teacher_video:
            return [action.payload, ...state];
        case ActionTypes.oneway_teacher.list_oneway_teacher_video:
            return action.payload;
        case ActionTypes.oneway_teacher.update_oneway_teacher_video:
            return map(state,dt=>{
                if(dt.id===action.payload.id){
                    return action.payload;
                }
                return dt;
            })
        case ActionTypes.oneway_teacher.delete_oneway_teacher_video:
            return filter(state,dt=>dt.id!==action.payload)
        default:
            return state;
    }
};
