//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _,{ omit , filter , find } from 'lodash';


//  -- Actions ------------------------
import * as ClassActions from './actions';
import { map, flatMap } from 'lodash';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create class -------------------
export const handleCreateClass = (data,userBooks) => async(dispatch) => {
    await dispatch(ClassActions.createClass());

    try {
        const classData = await API.post('classesRDS', `/classes`, {
            body: { ...data }
        });
        if(userBooks.books.length > 0&&classData.id){
            userBooks.userId = classData.id;
            await API.post('userBooksRDS', `/userBooks`, {
                body:  userBooks
            });
        }
        if(classData.id){
        await dispatch(ClassActions.createClassSuccess(classData));
        }
        return classData;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ClassActions.createClassFail(error));
    }
};
export const handleCreateClassForClever = (data) => async(dispatch) => {
    try {
        const classData = await API.post('classesRDS', `/createClassesForClever`, {
            body: { ...data }
        });
        return classData;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};

//  -- Get class ----------------------
export const handleGetClass = (classID) => async(dispatch) => {
    await dispatch(ClassActions.getClass());
    localStorage.setItem("LastActiveclassID",classID)
    if(!classID) return;
    try {
        let classData = {}
        const classWithBook = await API.get('classesRDS', `/getCurrentClass/${classID}`);
        if(classWithBook&&!classWithBook.error){
            classWithBook.books = filter(classWithBook.books,bk=>!find(classWithBook.archived_books,abk=>bk.id==abk.bookId||bk.bookId==abk.bookId))
            classData = classWithBook;
        }
       
        await dispatch(ClassActions.getClassSuccess(classData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ClassActions.getClassFail(error));
    }
};

//  -- Get class by code --------------
export const handleGetClassByCode = (code) => async(dispatch) => {
    await dispatch(ClassActions.getClassByCode());

    try {
        const classData = await API.get('classesRDS', `/classByCode/${code}`);
        if(classData&&!classData.error){
        const books = await API.get('userBooksRDS', `/userBooksByUser/${classData?.id}`);
        let filterBooks = omit(books, ['id']);
        await dispatch(ClassActions.getClassByCodeSuccess('Success'));
        return  {
            ...classData,
            ...filterBooks
        }
    }
    else{
        return null;
    }
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ClassActions.getClassByCodeFail(error));
    }
};



//  -- Get organization classes -------
export const handleGetOrgClasses = (orgs) => async(dispatch) => {
    // await dispatch(ClassActions.listOrgClasses());
    let classDataBook = [];

    try {
     if(orgs&&orgs.length > 0){
      const orgData = await API.post('classesRDS', `/classesByOrgs`,{
            body:{
                orgs:map(orgs,org=>org.id)
            }
        });
        if(Array.isArray(orgData)&&orgData.length>0){
            classDataBook = orgData;
        }
    }
        await dispatch(ClassActions.listOrgClassesSuccess(classDataBook));
    } catch (error) {
        await dispatch(ClassActions.listOrgClassesFail(error));
    }
};

//  -- List teacher classes -----------
export const handleListTeacherClasses = (teacherID) => async(dispatch) => {
    await dispatch(ClassActions.listTeacherClasses());

    try {
       let  classes = []
        const classesRDS = await API.get('classesRDS', `/classesByTeacher/${teacherID}`);
        if(classesRDS&&!classesRDS.error){
            classes = classesRDS;
        }
        await dispatch(ClassActions.listTeacherClassesSuccess('Succeses'));
        return classes;
    } catch (error) {
        await dispatch(ClassActions.listTeacherClassesFail(error));
    }
};

//  -- Update class -------------------
export const handleUpdateClass = (classID, data,booksUser,userBookId) => async(dispatch) => {
    await dispatch(ClassActions.updateClass());

    try {
        await API.put('classesRDS', `/classes/${classID}`, {
            body: { ...data }
        });
        let upd1=[]
        if(booksUser&&booksUser.books.length > 0){
            booksUser.userId = classID;
            upd1= await API.put('userBooksRDS', `/userBooks/${userBookId}`, {
                body: booksUser
            });
        }
        await dispatch(ClassActions.updateClassSuccess(data));
    } catch (error) {
        await dispatch(ClassActions.updateClassFail(error));
    }
};

//  -- Username check -----------------
export const handleCheckCode = (code) => async () => {
    try {
        const classes = await API.get('classesRDS', `/classByCode/${code}`);
        if(classes&&classes.error){
            return false;
        }else{
            return classes ? classes : false;
        } 
    } catch (error) {
        console.log(error);
    }
};



export const handleDeleteClassData = (classId) => async (dispatch) => {
    try {
        await API.del('classesRDS', `/classesData/${classId}`);
    } catch (error) {
        console.log(error);
    }
};

export const getClassBySectionId = async (sectionId) => {
    try {
        const classes =  await API.get('classesRDS', `/classBySection/${sectionId}`)
        return classes;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export const handleGetAllClass = () => async(dispatch) => {
    await dispatch(ClassActions.listAllClasses());
    const CLASSES_LENGTH = 1000;
    try {
        const numberOfClasses = await API.get('classesRDS', `/getAllClassesLength`);
        const classesArr = Array.apply(
            0,
            new Array(Math.floor(numberOfClasses.length / CLASSES_LENGTH) + 1)
          ).map((k, i) => i);
          const allClasses = await Promise.allSettled(
            map(classesArr, async (k) => {
              return API.get("classesRDS", `/getAllClasses/${CLASSES_LENGTH}/${k * CLASSES_LENGTH}`);
            })
          ).then((v) => filter(v, ["status", "fulfilled"]).map((k) => k.value));
          const classesRDS = flatMap(allClasses);
        await dispatch(ClassActions.listAllClassesSuccess(classesRDS));
        return classesRDS;
    } catch (error) {
        console.log(error);
        await dispatch(ClassActions.listAllClassesFail(error));
        return [];
    }
}