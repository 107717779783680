import React from 'react';
import { Slider, Typography, makeStyles } from '@material-ui/core';
import styled from 'styled-components'; 

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    width: 85%;
    height:13px;
`;


function CustomSlider({ value , onChange, dimensions}) {


  const useStyles = makeStyles({
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
      opacity: 1,
      backgroundColor: '#e8e8e8', // Adjust color based on your preference
    },
    thumb: {
      height: `clamp(21px, ${
        dimensions.width / 15
      }px, 18px)`,
      width: `clamp(21px, ${
        dimensions.width / 15
      }px, 18px)`,
      backgroundColor: '#00d6d3', // Adjust thumb color based on your preference
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    valueLabel: {
      left: 'calc(-50% + 4px)',
      fontSize: `clamp(21px, ${
        dimensions.width / 23
      }px, 30px)`
    },
    mark: {
      backgroundColor: '#e8e8e8',
      height: 18,
      width: 2,
      marginTop: -5,
 
    },
    markLabel: {
      top: 20,
      color: '#000',
      fontSize: `clamp(13px, ${
        dimensions?.width / 35
      }px, 40px)`
    },
    markActive: {
      backgroundColor: 'currentColor',
    
    },
  });
  const classes = useStyles();
  return (
    <Container>
      {/* <Typography id="discrete-slider-custom" gutterBottom>
        Regulate the noise
      </Typography> */}
      <Slider
        defaultValue={3}
        getAriaValueText={(value) => `${value}`}
        aria-labelledby="discrete-slider-custom"
        step={1}
        value={value}
        onChange={(e, val) => onChange(val)}
        valueLabelDisplay="auto"
        marks={Array.from({length: 10}, (_, i) => ({ value: i + 1, label: i + 1 }))}
        min={1}
        max={10}
        classes={{
          rail: classes.rail,
          track: classes.track,
          thumb: classes.thumb,
          valueLabel: classes.valueLabel,
          mark: classes.mark,
          markLabel: classes.markLabel,
          markActive: classes.markActive,
        }}
      />
    </Container>
  );
}

export default CustomSlider;
