//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import SentryWrap from "components/Sentry";
import React, { useEffect, useRef, useState, useReducer } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { Modal, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core";
// import Table from "components/Table";
import { filter, find, get, map, sortBy } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";

//  -- Thunks -------------------------

import Tooltip from '@material-ui/core/Tooltip';
import Loading from "components/Loading";
import { isEmpty } from 'lodash';
import { handleListGetSchool } from "store/organizations/thunks";
import { useOverallLiteracyPurpose, useStandards } from "store/story/selectors";
import { handleListOrganizationLitracyOverall, handleListSchoolsLitracy, handleListUsersLitracy, handleListStudentLitracy, handleListTeacherLitracy } from "store/story/thunks";
import { handleListDistStudent, handleListDistTeacher, handleListDistUsers } from "store/users/thunks";
import { Card, Col, Divider, Input, Row, Select, Modal as AntdModal } from 'antd';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DownloadOutlined } from '@ant-design/icons';
import XLSX from 'xlsx';
import moment from "moment-timezone";
const BORDERCOLOR = [
  "rgb(200,109,152)",
  "rgb(146,158,227)",
  "rgb(80,233,200)",
  "rgb(238,206,151)",
];

const MENU = [
  { id: "overall", value: "Overall" },
  { id: "see_schools", value: "Schools" },
  { id: "see_teachers", value: "Teachers" },
  { id: "see_students", value: "Students" }
];
const OVERALL = [
  { id: "standards", value: "Standards" },
  { id: "standards_frequency", value: "Standard frequency" },
  { id: "time_assigned", value: "Times assigned" },
  { id: "basico", value: "Básico" },
  { id: "intermedio", value: "Intermedio" },
  { id: "avanzado", value: "Avanzado" },
]
const SCHOOL_MENU = [
  { id: "standards", value: "Standards" },
  { id: "basico", value: "Básico" },
  { id: "intermedio", value: "Intermedio" },
  { id: "avanzado", value: "Avanzado" },
]
const TEACHERS_MENU = [
  { id: "standards", value: "Standards" },
  { id: "basico", value: "Básico" },
  { id: "intermedio", value: "Intermedio" },
  { id: "avanzado", value: "Avanzado" },
]
const SORTING_ORDER = [
  { value: 'highest', label: 'Highest (Standard frequency)' },
  { value: 'lowest', label: 'Lowest (Standard frequency)' },
  { value: 'most_used', label: 'Most assigned' },
  { value: 'least_used', label: 'Least assigned' },
]
const BOOKLIST = ["32", "7", "8", "1"];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0px;
  width: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  // border-radius: 12px;
  // background-color: ${COLOR.indigodeep};
  width: 100%;
  // padding: 16px;
  margin-top: 32px;
`;
const SectionTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ViewBtn = styled.button`
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  color: ${COLOR.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  border-radius: 7px;
  padding: 2px 16px;
  margin-right: 2px;
  margin-left: 2px;
  min-width: 180px;
  height: 30px;
  margin-left: 68.5%;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const VideoContainer = styled.div`
  height: auto;
  flex: 1;
  justify-content: center;
`;

const OverviewText = styled.span`
  color: black;
  font-size: 19px;
  font-weight: 600;
  display:flex !important;
  align-items:center;
`;

const ModalContent = styled.div`
        align-items: center;
        background-color: #11ffee00;
        border-radius: 3px;
        display: inline-flex;
        flex-direction: column;
        left: 50%;
        padding: 16px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
    `;
const MessageContgainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${COLOR.white};
    border: 3px solid ${COLOR.indigo400};
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    overflow: auto;
    padding: 12px;
    min-height: fit-content;
    max-height: 80%;
    `;

const FilterContainer = styled.span`
        font-size:17px;
        font-weight:500;
        cursor:pointer;
`;
const SectionText = styled.span`
   font-weight:600;
`;
const ViewSectionText = styled.p`
    color:black;
    font-size:17px;
    font-weight:400;
    align-items: center;
    display: flex;
`;

const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
font-size: 12px;
font-weight: 600;
`;
const OverViewValue = styled.span`
font-size: 17px;
font-weight: 500;
cursor: pointer;
text-transform:capitalize;
`;

const PassFailText = styled.span`
font-size: 12px;
margin-left:2px;
`;

const DownloadButton = styled.button`
    border: none;
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
    margin-right: 16px;
    height: 100%;
    background: ${COLOR.green200};
    color: #fff;
    padding-left: 12px;
    padding-right: 12px;
    &:hover {
      background: ${COLOR.green1000};
    }
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const sortData = (data, sortBy, order = 'desc') => {
  return data.sort((a, b) => {
    let aValue, bValue;

    if (sortBy === 'passPercentage') {
      aValue = a.BASICO_passPercentage + a.INTERMEDIO_passPercentage + a.AVANZADO_passPercentage;
      bValue = b.BASICO_passPercentage + b.INTERMEDIO_passPercentage + b.AVANZADO_passPercentage;
    } else if (sortBy === 'usage') {
      aValue = a.BASICO + a.INTERMEDIO + a.AVANZADO;
      bValue = b.BASICO + b.INTERMEDIO + b.AVANZADO;
    }
    else if (sortBy === 'overall') {
      aValue = a.standard_frequncy;
      bValue = b.standard_frequncy;
    }
    else if (sortBy === 'overall_uses') {
      aValue = a.times_assigned;
      bValue = b.times_assigned;
    }

    if (order === 'desc') {
      return bValue - aValue; // For descending order
    } else {
      return aValue - bValue; // For ascending order
    }
  });
};
const getPassFailPercent = (row, key) => {
  const passPercentage = row[`${key}_passPercentage`];
  const failPercentage = row[`${key}_failPercentage`];
  const unattendedPercentage = row[`${key}_unattendedPercentage`];
  if (row[key] <= 0) return '';
  const not_submitted = passPercentage === 0 && failPercentage === 0 && unattendedPercentage === 0;
  let notYetStr = unattendedPercentage > 0 ? `| ${unattendedPercentage}% not yet` : '';
  notYetStr = not_submitted ? `| 100% not yet ` : notYetStr;
  return `(${passPercentage}% pass | ${failPercentage}% fail ${notYetStr} )`;
}

const SortingMenu = ({ label, onSearch, onDropDown, onBack , onDownload=()=>{}}) => {
  return (<>
    <Row>
      {label ? <Col xs={24} lg={24}>
        <ViewSectionText onClick={onBack}>
          <ArrowBackIosIcon style={{ marginRight: '10px', cursor: 'pointer' }} />
          <SectionText>{label}</SectionText>
        </ViewSectionText>
      </Col> : null}
    </Row>
    <Row>
      <Col xs={24} lg={6} md={12} className="ColCardPadding">
        <Card style={{ width: '100%' }}>
          <Select
            bordered={false}
            defaultValue="Sort by"
            style={{ width: '100%', fontSize: 13 }}
            onChange={onDropDown}
            options={SORTING_ORDER}
          />
        </Card>
      </Col>
      <Col xs={24} lg={16} md={10} className="ColCardPadding">
        <Card style={{ width: '100%' }}>
          <Input placeholder="Search by standards" bordered={false} style={{ height: '32px' }} onChange={onSearch} />
        </Card>
      </Col>
      <Col xs={24} lg={2} md={2} className="ColCardPadding">
        <DownloadButton onClick={onDownload} style={{ marginRight: '16px', marginTop: 0 }}>
          <DownloadOutlined style={{ marginRight: '4px' }} />
          Download
        </DownloadButton>
      </Col>
    </Row>
  </>)
}


const OverAllView = ({ data, isLoading, getStandard, onBack, setOverAllViewModal }) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filteredData, setFilteredData] = useState([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    setFilteredData(data || [])
  }, [data])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const onSearch = (event) => {
    const textInput = event.target.value;
    const filteredData = data.filter(item => {
      return item.standard.toLowerCase().includes(textInput.toLowerCase());
    });
    setFilteredData(filteredData)
  }
  const onSorting = (value) => {
    let filterdata = data;
    switch (value) {
      case 'all':
        setFilteredData(data);
        break;
      case 'highest':
        filterdata = sortData(data, 'overall', 'desc');
        break;
      case 'lowest':
        filterdata = sortData(data, 'overall', 'asc');
        break;
      case 'most_used':
        filterdata = sortData(data, 'overall_uses', 'desc');
        break;
      case 'least_used':
        filterdata = sortData(data, 'overall_uses', 'asc');
        break;
      default:
        break;
    }
    setFilteredData(filterdata);
    forceUpdate();
  }
  if (isLoading) return (<Loading />);
  const onDownload = () => {
    try {
      const wb = XLSX.utils.book_new();
      const exambook = [
        ["Standards", "Standard frequency", "Times assigned", "Básico", "Intermedio", "Avanzado"]
      ];
      data.map((row) => {
        exambook.push([
          row.standard,
          row.standard_frequncy || 0,
          row.times_assigned || 0,
          row.BASICO,
          row.INTERMEDIO,
          row.AVANZADO
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(exambook);
      XLSX.utils.book_append_sheet(wb, ws, `overall_literacy`);
      XLSX.writeFile(wb, `overall_literacy ${moment().format("DD-MM-YY hh:mm:ss")}.csv`);
    } catch (error) {
      console.error("Error in exam gradebook download:", error);
    }
  }
  return (<>
    <SortingMenu onDropDown={onSorting} onSearch={onSearch} onBack={onBack} onDownload={onDownload} />
   
    <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
      <TableHead>
        <TableRow>
          {map(OVERALL, (x) => (<TableCell key={x.id} style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>{x.value}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(filteredData) && (
          <TableRow key={'0'} style={{ border: 0 }}>
            <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
          </TableRow>
        )}
        {(!isEmpty(filteredData)) && (
          filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            const fullText = getStandard(row.standard)
            return (
              <React.Fragment key={index}>
                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 } }}>
                  <TableCell style={{ textAlign: "center", cursor: "pointer" }} onClick={() => {
                    setOverAllViewModal({ open: true, describe: fullText, data: row, sortName: row.standard })
                  }}>
                    <Tooltip title={fullText || ''}>
                      <span>
                        {row.standard || ''}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>{row.standard_frequncy || 0} </TableCell>
                  <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0] }}>{row.times_assigned || 0}</TableCell>
                  <TableCell style={{ textAlign: "left", color: BORDERCOLOR[1] }}>{row.BASICO}</TableCell>
                  <TableCell style={{ textAlign: "left", color: BORDERCOLOR[2] }}>
                    {row.INTERMEDIO}
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }} >{row.AVANZADO}</TableCell>
                </TableRow>
              </React.Fragment>
            )
          }

          )
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          {!isEmpty(filteredData) && <TablePagination
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            backIconButtonProps={{
              color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
          />}
        </TableRow>
      </TableFooter>
    </Table></>)
}


const SchoolsView = ({ loading, data, getStandard, setLabelDescriptionView, selectedSchool, onBack }) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filteredData, setFilteredData] = useState([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    setFilteredData(data || [])
  }, [data])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const onSearch = (event) => {
    const textInput = event.target.value;
    const filteredData = data.filter(item => {
      return item.standard.toLowerCase().includes(textInput.toLowerCase());
    });
    setFilteredData(filteredData)
  }
  const onSorting = (value) => {
    let filterdata = data;
    switch (value) {
      case 'all':
        setFilteredData(data);
        break;
      case 'highest':
        filterdata = sortData(data, 'passPercentage', 'desc');
        break;
      case 'lowest':
        filterdata = sortData(data, 'passPercentage', 'asc');
        break;
      case 'most_used':
        filterdata = sortData(data, 'usage', 'desc');
        break;
      case 'least_used':
        filterdata = sortData(data, 'usage', 'asc');
        break;
      default:
        break;
    }
    setFilteredData(filterdata);
    forceUpdate();
  }

  if (loading) return (<Loading />);
  const onDownload = () => {
    try {
      const wb = XLSX.utils.book_new();
      const exambook = [
        ["Standards", "Básico", "Intermedio", "Avanzado"]
      ];
      data.map((row) => {
        exambook.push([
          row.standard,
          `${row.BASICO} ${getPassFailPercent(row, 'BASICO')}`,
          `${row.INTERMEDIO} ${getPassFailPercent(row, 'INTERMEDIO')}`,
          `${row.AVANZADO} ${getPassFailPercent(row, 'AVANZADO')}`
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(exambook);
      XLSX.utils.book_append_sheet(wb, ws, `overall_literacy_schools`);
      XLSX.writeFile(wb, `overall_literacy_schools ${moment().format("DD-MM-YY hh:mm:ss")}.csv`);
    } catch (error) {
      console.error("Error in exam gradebook download:", error);
    }
  }
  return (<>
    <SortingMenu onDropDown={onSorting} onSearch={onSearch} label={`${get(selectedSchool, "displayName", "")}`} onBack={onBack} onDownload={onDownload} />
    <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
      <TableHead>
        <TableRow>
          {map(SCHOOL_MENU, (x) => (<TableCell key={x.id} style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>{x.value}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(filteredData) && (
          <TableRow key={'0'} style={{ border: 0 }}>
            <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
          </TableRow>
        )}
        {(!isEmpty(filteredData)) && (
          filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            const fullText = getStandard(row.standard)
            return (
              <React.Fragment key={index}>
                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }}>
                  <TableCell style={{ textAlign: "center", cursor: "pointer" }} onClick={() => {
                    setLabelDescriptionView({ open: true, describe: fullText })
                  }}>
                    <Tooltip title={fullText || ''}>
                      <span>
                        {row.standard || ''}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.BASICO}<PassFailText>{getPassFailPercent(row,'BASICO')}</PassFailText>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.INTERMEDIO} <PassFailText>{getPassFailPercent(row,'INTERMEDIO')}</PassFailText>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }} >
                    {row.AVANZADO}<PassFailText>{getPassFailPercent(row,'AVANZADO')}</PassFailText>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          }

          )
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          {!isEmpty(filteredData) && <TablePagination
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            backIconButtonProps={{
              color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
          />}
        </TableRow>
      </TableFooter>
    </Table></>)
}
const TeacherView = ({ loading, data, getStandard, setLabelDescriptionView, selectedTeacher, onBack }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filteredData, setFilteredData] = useState([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    setFilteredData(data || [])
  }, [data])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const onSearch = (event) => {
    const textInput = event.target.value;
    const filteredData = data.filter(item => {
      return item.standard.toLowerCase().includes(textInput.toLowerCase());
    });
    setFilteredData(filteredData)
  }
  const onSorting = (value) => {
    let filterdata = data;
    switch (value) {
      case 'all':
        setFilteredData(data);
        break;
      case 'highest':
        filterdata = sortData(data, 'passPercentage', 'desc');
        break;
      case 'lowest':
        filterdata = sortData(data, 'passPercentage', 'asc');
        break;
      case 'most_used':
        filterdata = sortData(data, 'usage', 'desc');
        break;
      case 'least_used':
        filterdata = sortData(data, 'usage', 'asc');
        break;
      default:
        break;
    }
    setFilteredData(filterdata);
    forceUpdate();
  }
  const onDownload = () => {
    try {
      const wb = XLSX.utils.book_new();
      const exambook = [
        ["Standards", "Básico", "Intermedio", "Avanzado"]
      ];
      data.map((row) => {
        exambook.push([
          row.standard,
          `${row.BASICO} ${getPassFailPercent(row, 'BASICO')}`,
          `${row.INTERMEDIO} ${getPassFailPercent(row, 'INTERMEDIO')}`,
          `${row.AVANZADO} ${getPassFailPercent(row, 'AVANZADO')}`
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(exambook);
      XLSX.utils.book_append_sheet(wb, ws, `overall_literacy_teacher`);
      XLSX.writeFile(wb, `overall_literacy_teacher ${moment().format("DD-MM-YY hh:mm:ss")}.csv`);
    } catch (error) {
      console.error("Error in exam gradebook download:", error);
    }
  }
  if (loading) return (<Loading />);
  return (<>
    <SortingMenu onDropDown={onSorting} onSearch={onSearch} label={`${get(selectedTeacher, "firstName", "")} ${get(selectedTeacher, "lastName", "")}`} onBack={onBack}   onDownload={onDownload} />
    <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
      <TableHead>
        <TableRow>
          {map(SCHOOL_MENU, (x) => (<TableCell key={x.id} style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>{x.value}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(filteredData) && (
          <TableRow key={'0'} style={{ border: 0 }}>
            <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
          </TableRow>
        )}
        {(!isEmpty(filteredData)) && (
          filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            const fullText = getStandard(row.standard)
            return (
              <React.Fragment key={index}>
                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }}>
                  <TableCell style={{ textAlign: "center", cursor: "pointer" }} onClick={() => {
                    setLabelDescriptionView({ open: true, describe: fullText })
                  }}>
                    <Tooltip title={fullText || ''}>
                      <span>
                        {row.standard || ''}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.BASICO}<PassFailText>{getPassFailPercent(row,'BASICO')}</PassFailText>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.INTERMEDIO} <PassFailText>{getPassFailPercent(row,'INTERMEDIO')}</PassFailText>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }} >
                    {row.AVANZADO}<PassFailText>{getPassFailPercent(row,'AVANZADO')}</PassFailText>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          }

          )
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          {!isEmpty(filteredData) && <TablePagination
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            backIconButtonProps={{
              color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
          />}
        </TableRow>
      </TableFooter>
    </Table></>)
}
const StudentView = ({ loading, data, getStandard, setLabelDescriptionView, selectedStudent, onBack }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filteredData, setFilteredData] = useState([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    setFilteredData(data || [])
  }, [data])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const onSearch = (event) => {
    const textInput = event.target.value;
    const filteredData = data.filter(item => {
      return item.standard.toLowerCase().includes(textInput.toLowerCase());
    });
    setFilteredData(filteredData)
  }
  const onSorting = (value) => {
    let filterdata = data;
    switch (value) {
      case 'all':
        setFilteredData(data);
        break;
      case 'highest':
        filterdata = sortData(data, 'passPercentage', 'desc');
        break;
      case 'lowest':
        filterdata = sortData(data, 'passPercentage', 'asc');
        break;
      case 'most_used':
        filterdata = sortData(data, 'usage', 'desc');
        break;
      case 'least_used':
        filterdata = sortData(data, 'usage', 'asc');
        break;
      default:
        break;
    }
    setFilteredData(filterdata);
    forceUpdate();
  }
  const onDownload = () => {
    try {
      const wb = XLSX.utils.book_new();
      const exambook = [
        ["Standards", "Básico", "Intermedio", "Avanzado"]
      ];
      data.map((row) => {
        exambook.push([
          row.standard,
          `${row.BASICO} ${getPassFailPercent(row, 'BASICO')}`,
          `${row.INTERMEDIO} ${getPassFailPercent(row, 'INTERMEDIO')}`,
          `${row.AVANZADO} ${getPassFailPercent(row, 'AVANZADO')}`
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(exambook);
      XLSX.utils.book_append_sheet(wb, ws, `overall_literacy_student`);
      XLSX.writeFile(wb, `overall_literacy_student ${moment().format("DD-MM-YY hh:mm:ss")}.csv`);
    } catch (error) {
      console.error("Error in exam gradebook download:", error);
    }
  }
  if (loading) return (<Loading />);
  return (<>
    <SortingMenu onDropDown={onSorting} onSearch={onSearch} label={`${get(selectedStudent, "firstName", "")} ${get(selectedStudent, "lastName", "")}`} onBack={onBack}  onDownload={onDownload} />
    <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
      <TableHead>
        <TableRow>
          {map(SCHOOL_MENU, (x) => (<TableCell key={x.id} style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>{x.value}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(filteredData) && (
          <TableRow key={'0'} style={{ border: 0 }}>
            <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
          </TableRow>
        )}
        {(!isEmpty(filteredData)) && (
          filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            const fullText = getStandard(row.standard)
            return (
              <React.Fragment key={index}>
                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }}>
                  <TableCell style={{ textAlign: "center", cursor: "pointer" }} onClick={() => {
                    setLabelDescriptionView({ open: true, describe: fullText })
                  }}>
                    <Tooltip title={fullText || ''}>
                      <span>
                        {row.standard || ''}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.BASICO}<PassFailText>{getPassFailPercent(row,'BASICO')}</PassFailText>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>
                    {row.INTERMEDIO} <PassFailText>{getPassFailPercent(row,'INTERMEDIO')}</PassFailText>
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }} >
                    {row.AVANZADO}<PassFailText>{getPassFailPercent(row,'AVANZADO')}</PassFailText>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          }

          )
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          {!isEmpty(filteredData) && <TablePagination
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            backIconButtonProps={{
              color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
          />}
        </TableRow>
      </TableFooter>
    </Table></>)
}
const ListSchools = ({ schools, onSchoolSelect , districts }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filteredSchools, setFilteredSchools] = useState([]);

  useEffect(() => {
    if (Array.isArray(schools)) {
      setFilteredSchools(schools);
    }
  }, [schools])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onSearch = e => {
    try {
      const text = e.target.value.toLowerCase();
      if (text.length > 0) {
        const filtered = filter(schools, (school) => {
          return school.displayName?.toLowerCase().includes(text);
        });
        setFilteredSchools(filtered);
      } else {
        setFilteredSchools(schools || []);
      }
    } catch (error) {
      console.error('Error occurred during search:', error);
      setFilteredSchools([]);
    }
  };
  return (<>
    <Row>
      <Col xs={24} lg={24}>
        <FilterContainer className="FilterHeading">
          <Card
            type="inner"
            title="Filters"
          >
            <Row>
              <Col xs={24} lg={24} md={24} className="ColCardPadding">
                <Card style={{ width: '100%' }}>
                  <Input placeholder="Search by displayname" bordered={false} style={{ height: '32px' }} onChange={onSearch} />
                </Card>
              </Col>
            </Row>
          </Card>
        </FilterContainer>
      </Col>
    </Row>
    <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Name</TableCell>
          <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Students</TableCell>
          <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Teachers</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(filteredSchools) && (
          <TableRow key={'0'} style={{ border: 0 }}>
            <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
          </TableRow>
        )}
        {(!isEmpty(filteredSchools)) && (
          filteredSchools.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            return (
              <>
                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, cursor: "pointer" }} onClick={() => { onSchoolSelect(row) }}>
                  <TableCell style={{ textAlign: "left", cursor: 'pointer' }}>{row.displayName} </TableCell>
                  <TableCell style={{ textAlign: "left", cursor: 'pointer' }} >{get(row, 'student_count', '0')}</TableCell>
                  <TableCell style={{ textAlign: "left", cursor: 'pointer' }} >{get(row, 'teacher_count', '0')}</TableCell>
                </TableRow>
              </>
            )
          }

          )
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          {!isEmpty(filteredSchools) && <TablePagination
            count={filteredSchools.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            backIconButtonProps={{
              color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
          />}
        </TableRow>
      </TableFooter>
    </Table></>)
}
const ListTeachers = ({ teachers, schools, onSelect }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [filteredTeachers, setFilteredTeachers] = useState([]);

  useEffect(() => {
    if (Array.isArray(teachers)) {
      setFilteredTeachers(teachers);
    }
  }, [teachers])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onSearch = e => {
    try {
      const text = e.target.value.toLowerCase();
      if (text.length > 0) {
        const filtered = filter(teachers, (teacher) => {
          return teacher.firstName.toLowerCase().includes(text) || teacher.lastName.toLowerCase().includes(text);
        });
        setFilteredTeachers(filtered);
      } else {
        setFilteredTeachers(teachers || []);
      }
    } catch (error) {
      console.error('Error occurred during search:', error);
      setFilteredTeachers([]);
    }
  };

  return (
    <>
      <Row>
        <Col xs={24} lg={24}>
          <FilterContainer className="FilterHeading">
            <Card
              type="inner"
              title="Filters"
            >
              <Row>
                <Col xs={24} lg={24} md={24} className="ColCardPadding">
                  <Card style={{ width: '100%' }}>
                    <Input placeholder="Search by first name or last name" bordered={false} style={{ height: '32px' }} onChange={onSearch} />
                  </Card>
                </Col>
              </Row>
            </Card>
          </FilterContainer>
        </Col>
      </Row>
      <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>School Name</TableCell>
            <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>First Name</TableCell>
            <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Last Name</TableCell>
            <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(filteredTeachers) && (
            <TableRow key={'0'} style={{ border: 0 }}>
              <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
            </TableRow>
          )}
          {(!isEmpty(filteredTeachers)) && (
            filteredTeachers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              const schoolName = find(schools, obj => obj.id == row.orgId);
              row.schoolName = get(schoolName, 'displayName', '')
              return (
                <>
                  <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, cursor: "pointer" }} onClick={() => { onSelect(row) }}>
                    <TableCell style={{ textAlign: "left" }}>{get(schoolName, 'displayName', '')} </TableCell>
                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }}>{row.firstName} </TableCell>
                    <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0] }}>{row.lastName}</TableCell>
                    <TableCell style={{ textAlign: "left", color: !row.active ? 'red' : BORDERCOLOR[1] }}> {row.active ? "Enabled" : 'Disabled'}</TableCell>
                  </TableRow>
                </>
              )
            }

            )
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            {!isEmpty(filteredTeachers) && <TablePagination
              count={filteredTeachers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
              backIconButtonProps={{
                color: "secondary"
              }}
              nextIconButtonProps={{ color: "secondary" }}
            />}
          </TableRow>
        </TableFooter>
      </Table></>)
}
const ListStudents = ({ students, schools, onSelect }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filteredStudents, setfilteredStudents] = useState([]);

  useEffect(() => {
    if (Array.isArray(students)) {
      setfilteredStudents(students);
    }
  }, [students])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onSearch = e => {
    try {
      const text = e.target.value.toLowerCase();
      if (text.length > 0) {
        const filtered = filter(students, (student) => {
          return student.firstName.toLowerCase().includes(text) || student.lastName.toLowerCase().includes(text);
        });
        setfilteredStudents(filtered);
      } else {
        setfilteredStudents(students || []);
      }
    } catch (error) {
      console.error('Error occurred during search:', error);
      setfilteredStudents([]);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  return (<>
    <Row>
      <Col xs={24} lg={24}>
        <FilterContainer className="FilterHeading">
          <Card
            type="inner"
            title="Filters"
          >
            <Row>
              <Col xs={24} lg={24} md={24} className="ColCardPadding">
                <Card style={{ width: '100%' }}>
                  <Input placeholder="Search by first name or last name" bordered={false} style={{ height: '32px' }} onChange={onSearch} />
                </Card>
              </Col>
            </Row>
          </Card>
        </FilterContainer>
      </Col>
    </Row>
    <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>School Name</TableCell>
          <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>First Name</TableCell>
          <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Last Name</TableCell>
          <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(filteredStudents) && (
          <TableRow key={'0'} style={{ border: 0 }}>
            <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
          </TableRow>
        )}
        {(!isEmpty(filteredStudents)) && (
          filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            const schoolName = find(schools, obj => obj.id == row.orgId);
            return (
              <>
                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, cursor: "pointer" }} onClick={() => { onSelect(row) }}>
                  <TableCell style={{ textAlign: "left" }}>{get(schoolName, 'displayName', '')} </TableCell>
                  <TableCell style={{ textAlign: "left", cursor: 'pointer' }}>{row.firstName} </TableCell>
                  <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0] }}>{row.lastName}</TableCell>
                  <TableCell style={{ textAlign: "left", color: !row.active ? 'red' : BORDERCOLOR[1] }}> {row.active ? "Enabled" : 'Disabled'}</TableCell>
                </TableRow>
              </>
            )
          }

          )
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          {!isEmpty(filteredStudents) && <TablePagination
            count={filteredStudents.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            backIconButtonProps={{
              color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
          />}
        </TableRow>
      </TableFooter>
    </Table></>)
}
const Litracy = ({
  currentOrg,
  districts,
  schools,
  users,
  booksAllList,
  distTeacher,
  distStudents
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataTab, setDataTab] = useState("overall");
  const [loadingData, setLoadingData] = useState(false);
  const overAllLitracy = useOverallLiteracyPurpose()
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [schoolLitracy, setSchoolLitracy] = useState([]);
  const [schoolLitracyLoadings, setSchoolLitracyLoadings] = useState(false);
  const [activeSchool, setActiveSchool] = useState(null);
  const standards = useStandards()
  const [teacherLitracy, setteacherLitracy] = useState([]);
  const [teacherLitracyLoadings, setteacherLitracyLoadings] = useState(false);
  const [activeteacher, setActiveteacher] = useState(null);


  const [studentLitracy, setstudentLitracy] = useState([]);
  const [studentLitracyLoadings, setstudentLitracyLoadings] = useState(false);
  const [activestudent, setActivestudent] = useState(null);

  const [labelDescriptionView, setLabelDescriptionView] = useState({
    open: false,
    describe: ""
  })
  const [overAllViewModal, setOverAllViewModal] = useState({
    open: false,
    describe: "",
    data: {},
    sortName: ''
  })

  useEffect(() => {
    if (Array.isArray(users)) {
      setTeachers(filter(distTeacher, ['role', 'teacher']))
      setStudents(filter(distStudents, ['role', 'student']))
    }
  }, [distStudents, distTeacher])
  const cotationRef = useRef(null)
  if (!cotationRef.current) {
    cotationRef.current = getRandomCotation()
  }
  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
    setDataTab("overall");
  }, []);
  /////////  
  useEffect(() => {
    async function fetchData() {
      setLoadingData(true);
      await dispatch(handleListOrganizationLitracyOverall(currentOrg.id));
      setLoadingData(false);
      fetchDistrictData();
    }
    fetchData();
  }, [currentOrg]);

  const getStandard = (abbr) => {
    const dt = get(find(standards, ["abbr", abbr]), "description")
    if (dt) return dt;
    return null
  }

  const fetchDistrictData = async () => {
    try {
      if (!isEmpty(currentOrg)) {
        // await handleUpdateLicences(currentOrg.id)
        dispatch(handleListGetSchool(currentOrg.id));
        dispatch(handleListDistUsers(currentOrg.id));
        dispatch(handleListDistTeacher(currentOrg.id));
        dispatch(handleListDistStudent(currentOrg.id));
      }
    } catch (error) {

    }
  };

  const fetchSchoolLitracyData = async (id) => {
    setSchoolLitracy([])
    setSchoolLitracyLoadings(true);
    const data = await dispatch(handleListSchoolsLitracy(id));
    setSchoolLitracyLoadings(false);
    setSchoolLitracy(data)
  }
  const fetchTeacherLitracyData = async (id) => {
    setteacherLitracy([])
    setteacherLitracyLoadings(true);
    const data = await dispatch(handleListTeacherLitracy(id));
    setteacherLitracyLoadings(false);
    setteacherLitracy(data)
  }
  const fetchStudentLitracyData = async (id) => {
    setstudentLitracy([])
    setstudentLitracyLoadings(true);
    const data = await dispatch(handleListStudentLitracy(id));
    setstudentLitracyLoadings(false);
    setstudentLitracy(data)
  }

  // Array Books
  let listBooks = [];

  for (var book in booksAllList) {
    let isNotAssignable = booksAllList[book].isNotAssignable;
    let _data = {
      id: booksAllList[book].id,
      description: booksAllList[book].description,
      count: booksAllList[book].count,
    };
    if (!isNotAssignable) {
      listBooks.push(_data);
    }
  }

  function navigationToMenu(id) {
    setDataTab(id);
    clearSelection()
  }
  const clearSelection = () => {
    setActiveSchool(null)
    setActivestudent(null);
    setActiveteacher(null);
  }
  const onOverAllPopupClose = () => {
    setOverAllViewModal({
      open: false,
      describe: '',
      data: {},
      sortName: ''
    })
  }
  return (
    <SentryWrap>
      <AntdModal
        footer={null}
        title={get(currentOrg, 'displayName', '')}
        centered
        open={overAllViewModal.open}
        onOk={onOverAllPopupClose}
        onCancel={onOverAllPopupClose}
        width={'60%'}
        className="FilterHeading"
      >
        <Card
          style={{ marginTop: 16 }}
          type="inner"
          title="Detail View"
        >
          <Row>
            <OverViewLabel style={{ fontSize: 18 }}>{overAllViewModal.sortName || ''}</OverViewLabel>
            <OverViewValue style={{ fontWeight: 400, fontSize: 14, lineHeight: "1em", textTransform:"none" }}>{overAllViewModal.describe || ''}</OverViewValue>
          </Row>
          <Row style={{ marginTop: 16 }}>
            <Col xs={24} lg={12}>
              <OverViewContainer>
                <OverViewLabel>Questions available in Platform</OverViewLabel>
                <OverViewValue>{get(overAllViewModal.data, "standard_frequncy", '')}</OverViewValue>
              </OverViewContainer>
              <OverViewContainer>
                <OverViewLabel>Questions Assigned in Activity</OverViewLabel>
                <OverViewValue>{get(overAllViewModal.data, "times_assigned", '')}</OverViewValue>
              </OverViewContainer>
              <OverViewContainer>
                <OverViewLabel>Total Students Assigned</OverViewLabel>
                <OverViewValue>{get(overAllViewModal.data, "student_count", '')}</OverViewValue>
              </OverViewContainer>
              <OverViewContainer>
                <OverViewLabel>Number of responses</OverViewLabel>
                <OverViewValue>{get(overAllViewModal.data, "reponse", '') || '-'}</OverViewValue>
              </OverViewContainer>

            </Col>
            <Col xs={24} lg={12}>
              <OverViewContainer>
                <OverViewLabel>Not Responded</OverViewLabel>
                <OverViewValue>{get(overAllViewModal.data, "no_resonse", '') || '-'}</OverViewValue>
              </OverViewContainer>
              <OverViewContainer>
                <OverViewLabel>Passed</OverViewLabel>
                <OverViewValue>{get(overAllViewModal.data, "pass", '') || '-'}</OverViewValue>
              </OverViewContainer>
              <OverViewContainer>
                <OverViewLabel>Failed</OverViewLabel>
                <OverViewValue>{get(overAllViewModal.data, "fail", '') || '-'}</OverViewValue>
              </OverViewContainer>
            </Col>
          </Row>
        </Card>
      </AntdModal>
      <Modal
        open={labelDescriptionView.open}
        onClose={() => setLabelDescriptionView({ open: false, describe: "" })}
      ><ModalContent>
          <MessageContgainer>
            {labelDescriptionView.describe}
          </MessageContgainer>
        </ModalContent>
      </Modal>
      <Wrapper className={"adminForm"}>
        <OverviewText>Admin</OverviewText>

        <MenuWrapper top={"10px"} style={{ width: "100%" }}>
          {MENU.map((x) => (
            <MenuBtn style={{ borderBottom: dataTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => {
              navigationToMenu(x.id)
            }
            }>
              {x.value}
            </MenuBtn>
          ))}
        </MenuWrapper>
        {dataTab === "overall" && <OverAllView
          data={overAllLitracy}
          getStandard={getStandard}
          setOverAllViewModal={setOverAllViewModal}
          // setLabelDescriptionView={setLabelDescriptionView}
          isLoading={loadingData && overAllLitracy?.length == 0} />}
        {dataTab === "see_schools" &&
          (activeSchool ? <SchoolsView
            data={schoolLitracy}
            getStandard={getStandard}
            selectedSchool={activeSchool}
            onBack={clearSelection}
            setLabelDescriptionView={setLabelDescriptionView}
            loading={schoolLitracyLoadings} /> :
            <ListSchools
              schools={sortBy(schools, ["displayName"])}
              districts={districts} onSchoolSelect={school => {
                setActiveSchool(school)
                fetchSchoolLitracyData(school.id)
              }} />)}
        {dataTab === "see_teachers" && (activeteacher ?
          <TeacherView
            data={teacherLitracy}
            getStandard={getStandard}
            selectedTeacher={activeteacher}
            onBack={clearSelection}
            setLabelDescriptionView={setLabelDescriptionView}
            loading={teacherLitracyLoadings} /> : <ListTeachers
            teachers={teachers}
            schools={sortBy(schools, ["displayName"])}
            onSelect={(usr) => {
              setActiveteacher(usr)
              fetchTeacherLitracyData(usr.id)
            }} />)}
        {dataTab === "see_students" && (activestudent ?
          <StudentView
            data={studentLitracy}
            getStandard={getStandard}
            selectedStudent={activestudent}
            onBack={clearSelection}
            setLabelDescriptionView={setLabelDescriptionView}
            loading={studentLitracyLoadings} /> :
          <ListStudents
            students={students}
            schools={sortBy(schools, ["displayName"])}
            onSelect={(usr) => {
              setActivestudent(usr)
              fetchStudentLitracyData(usr.id)
            }} />)}
      </Wrapper>
    </SentryWrap>
  );
};

//  -- Prop types ---------------------
Litracy.propTypes = {};

//  -- Default props ------------------
Litracy.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  activities: state.activities,
  classes: state.classes,
  currentOrg: state.currentOrg,
  districts: state.districts,
  interactions: state.interactions,
  quizzes: state.quizzes,
  schools: state.schools,
  users: state.users,
  booksAllList: state.booksAllList,
  allSchools: state.allSchools,
  allUser: state.allUser,
  allClasses: state.allClasses,
  distTeacher: state.distTeacher,
  distStudents: state.distStudents
});

export default connect(mapStateToProps, null)(Litracy);
