import { DeleteFilled } from "@ant-design/icons";
import { LinearProgress, Modal, Snackbar } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import ShareIcon from "@material-ui/icons/Share";
import { Alert } from "@material-ui/lab";
import { Button } from "antd";
import { find, get } from "lodash";
import React, { useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { useCurrentUser } from "store/users/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { v4 } from "uuid";
import ResizeAndDragabble from "./ResizeAndDraggable";
import { Button as AntdButton } from "antd";
import color from "utils/constants/color";
import GalleryImage from "../utils/assets/images/gallery-image.svg";
import COLOR from "utils/constants/color";
import AnimatedCheckMark from "./AnimatedCheckMark";
import AccordinButton from "./AccordinButton";

const ModalContent = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  padding: 0 20px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  transform: translate(31%, 0%);
  @media (max-width: 1450px) {
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    transform: translate(0%, 0%);
  }
`;

const WidgetsImages = ({ currentClass, sharableWidget }) => {
  const filterShareImage = find(
    sharableWidget,
    (obj) => obj?.widget_type === "image"
  );
  const sharedData = filterShareImage?.data || {};
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets, uploadOnBucket, deleteOnBucket } =
    useCollectionWidgets();
  const isTeacher = get(currentUser, "role") === "teacher";
  const [image, setImage] = useState(null);
  const [dimensions, setDimensions] = useState({});
  const [widgetsImg, setWidgetsImg] = useState([]);
  const [userModal, setuserModal] = useState(false);
  const [isPredefinedImage, setIsPredefinedImage] = useState(
    sharedData?.image ? true : false
  );
  const [selectedPredefinedImage, setSelectedPredefinedImage] = useState(null);
  const [showAccordin, setShowAccordin] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });
  const [percant, setPercant] = useState(0);
  const MAX_SIZE_MB = 2 * 1024 * 1024;

  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(trafficData, (item) => item?.menu === "image");

  const closeuserModal = () => {
    setuserModal(false);
  };

  const handleImageUpload = async (file) => {
    if (widgetsImg.length >= 10) {
      fileInputRef.current.value = null;
      setAlert({
        open: true,
        message: "You can only upload up to 10 images.",
      });
      return;
    } else if (file) {
      if (!file.type.startsWith("image/")) {
        fileInputRef.current.value = null;
        setAlert({
          open: true,
          message: "Please upload a valid image file.",
        });
        return;
      } else if (file.size > MAX_SIZE_MB) {
        fileInputRef.current.value = null;
        setAlert({
          open: true,
          message: "The file size exceeds the maximum limit of 2MB.",
        });
        return;
      }
      // const reader = new FileReader();
      // reader.onloadend = () => {
      const location = `/Image-widget/${v4()}`;
      const url = await generateUrl(file, location);
      const newFileObject = {
        location,
        name: file.name,
        type: file.type,
        size: file.size,
        url: url,
      };
      setWidgetsImg([...widgetsImg, newFileObject]);
      // };
      // reader.readAsDataURL(file);
      fileInputRef.current.value = null;
      setAlert("");
    }
  };

  const deleteImage = async (imageToDelete, image) => {
    await deleteOnBucket(image?.location);
    const removeData = sharedData?.image?.url === image?.url;
    if (removeData) {
      const imageData = {
        image: "",
      };
      const shareData = {
        ...filterShareImage,
        data: imageData,
      };
      createCollectionWidgets(shareData);
    }
    const filterImg = widgetsImg.filter(
      (img, index) => index !== imageToDelete
    );
    setWidgetsImg(filterImg);
    if (widgetsImg.length > 1) {
      if (widgetsImg[imageToDelete] === image) {
        setImage(filterImg.length > 0 ? filterImg[0] : null);
      }
    } else {
      setImage(null);
      setIsPredefinedImage(false);
    }
  };

  const onClose = async () => {
    const closeShareData = {
      ...filterShareImage,
      status: false,
    };
    closeuserModal();
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    createCollectionWidgets(closeShareData);
  };

  const predefinedimage = (file) => {
    setSelectedPredefinedImage(file);
    setImage(file);
    setIsPredefinedImage(true);
    if (filterShareImage?.status) {
      const imageData = {
        image: file,
      };
      const shareData = {
        ...filterShareImage,
        data: imageData,
      };
      createCollectionWidgets(shareData);
    }

    const element = document.querySelector(".checkListAddTaskBtn");
    if (element) {
      element.style.position = "absolute";
      element.style.top = "0px";
      element.style.height = "100%";
    }
    const allElement = document.querySelector(".image-widget");
    if (element) {
      allElement.style.position = "relative";
      allElement.style.boxShadow = "none";
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: "",
    });
  };

  const shareImage = () => {
    if (!filterShareImage?.status) {
      createCollection();
    }
  };

  const generateUrl = async (file, location) => {
    try {
      const url = await uploadOnBucket(file, location, setPercant);
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const createCollection = async () => {
    try {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "image",
        status: true,
        data: {
          image: image,
        },
      };
      createCollectionWidgets(shareData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTouch = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchShare = (event) => {
    event.preventDefault();
    shareImage();
  };

  const handleTouchUpload = (event) => {
    event.preventDefault();
    setuserModal(true);
  };

  return (
    <div style={{ position: "relative", zIndex: 1000 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="error">
          <strong>ALERT:</strong> {alert.message}
        </Alert>
      </Snackbar>
      {((filterByWidget?.view && filterByWidget?.menu === "image") ||
        filterShareImage?.id) && (
        <Container className="allCheckListSEction wigetsContainer">
          <div className="mainCheckList h-full">
            <ResizeAndDragabble
              defaultValue={{
                x: 150,
                y: 205,
                width: 215,
                height: 255,
              }}
              minWidth={215}
              minHeight={255}
              showAccordin={showAccordin}
              resize={(data) => setDimensions(data)}
              resizeEnd={(data) => setDimensions(data)}
            >
              <div
                className="checkListTopSection"
                style={{
                  position: "relative",
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                  height: showAccordin ? "100%" : "0px",
                  width: "100%",
                  padding: "0px",
                }}
              >
                {isTeacher && (
                  <CancelIcon
                    onClick={() => onClose()}
                    onTouchStart={handleTouch}
                    style={{
                      position: "absolute",
                      right: 7,
                      top: 14,
                      fontSize: 18,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                )}
                {isTeacher && (
                  <div>
                    <span
                     disabled={image === "" || image === null}
                      onClick={() => shareImage()}
                      onTouchStart={handleTouchShare}
                      style={{
                        // marginLeft: "5px",
                        cursor: "pointer",
                        position: "absolute",
                        right: 34,
                        top: 15,
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {!filterShareImage?.status ? (
                        <ShareIcon style={{ color: "white", fontSize: 16 }} />
                      ) : (
                        <AnimatedCheckMark />
                      )}
                      {/* <AntdButton
                        onClick={() => shareImage()}
                        onTouchStart={handleTouchShare}
                        disabled={image === "" || image === null}
                        type="primary"
                        style={{
                          borderRadius: 10,
                          minWidth: 60,
                          backgroundColor: filterShareImage?.status
                            ? color.greentrue
                            : color.green100,
                          border: "0px",
                        }}
                      >
                        {filterShareImage?.status ? "Sharing" : "Share"}
                      </AntdButton> */}
                    </span>

                    {isPredefinedImage && !userModal && (
                      <span
                        style={{
                          // marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          right: 54,
                          top: 12,
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          onClick={() => setuserModal(true)}
                          onTouchStart={() => setuserModal(true)}
                          style={{ width: 21, height: 27, cursor: "pointer" }}
                          src={GalleryImage}
                          alt="gallery-image"
                        />
                      </span>
                    )}
                  </div>
                )}

                <div
                  className={`checkListFirstTxt bg-blue-10 h-44px pt-17px pb-14px ${
                    showAccordin ? "br-topLeft-topRight-20px" : "br-20px"
                  } `}
                >
                    <AccordinButton
                      open={showAccordin}
                      onClose={() => setShowAccordin((prev) => !prev)}
                      shareableWidget = {sharableWidget}
                    />               
                  <span className="text-white text-ft12-700">Image</span>
                </div>
                {showAccordin && (
                  <div
                    className="image-widget"
                    style={{
                      backgroundImage:
                        image?.url.length > 0 ||
                        sharedData?.image?.url.length > 0
                          ? "unset"
                          : "",
                    }}
                  >
                    {sharedData?.image ? (
                      <img
                        src={sharedData?.image?.url}
                        alt="Uploaded"
                        className="uploaded-image image-select-none"
                      />
                    ) : (
                      image && (
                        <img
                          src={image.url}
                          alt="Uploaded"
                          className="uploaded-image image-select-none"
                        />
                      )
                    )}

                    {isTeacher && !isPredefinedImage && (
                      <div
                        className="upload-placeholder"
                        style={{ height: isPredefinedImage ? "auto" : "100%" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e.target.files[0])}
                          id="imageUpload"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <div
                          className="uploadImgIcn"
                          style={{ textAlign: "center" }}
                        >
                          <label htmlFor="imageUpload" className="upload-label">
                            {isTeacher && (
                              <div
                                style={{
                                  margin: 0,
                                  display: "grid",
                                  placeItems: "center",
                                }}
                                className="checkListAddTaskBtn"
                              >
                                <Button
                                  style={{ marginRight: 0 }}
                                  onClick={() => setuserModal(true)}
                                  onTouchStart={() => setuserModal(true)}
                                >
                                  Upload Image
                                </Button>
                              </div>
                            )}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </ResizeAndDragabble>
            <Modal
              open={userModal}
              onClose={closeuserModal}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
              className="galleryPicUpr msgSystemMdlMn"
            >
              <ModalContent>
                <div className="galleryMdlCls" onClick={closeuserModal}>
                  <CancelIcon
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      fontSize: 30,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="galleryPic widgetsImgGallery">
                  <label>Gallery images</label>
                  <ul>
                    {widgetsImg.map((image, index) => (
                      <>
                        <li
                          key={index}
                          className={
                            selectedPredefinedImage === image
                              ? "selectedpreimage"
                              : ""
                          }
                          onClick={() => predefinedimage(image)}
                        >
                          <div className="widgetsSelectImg">
                            <img
                              src={image.url}
                              alt="Selected"
                              className="image-select-none"
                            />
                            <DeleteFilled
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteImage(index, image);
                              }}
                              className="widgetImgDelete"
                            />
                          </div>
                        </li>
                      </>
                    ))}
                  </ul>
                  {0 < percant && percant < 100 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <LinearProgress
                        className="msgSndLine"
                        style={{ width: "70%", color: COLOR.green100 }}
                        variant="determinate"
                        value={percant}
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    opacity: percant !== 0 || widgetsImg?.length === 10 ? 0.5 : 1,
                    cursor: percant !== 0 || widgetsImg?.length !== 10 ? "pointer" : "default",
                  }}
                  className="galleryUpload"
                >
                  <Dropzone
                    disabled={percant !== 0 || widgetsImg?.length === 10}
                    onDrop={(acceptedFiles) =>
                      handleImageUpload(acceptedFiles[0])
                    }
                    accept="image/*"
                    style={{
                      cursor: percant !== 0 || widgetsImg?.length !== 10 ? "pointer" : "default",
                    }}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    }) => {
                      const additionalClass = isDragAccept
                        ? "accept"
                        : isDragReject
                        ? "reject"
                        : "";

                      return (
                        <div
                          {...getRootProps({
                            className: `dropzone ${additionalClass}`,
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>
                            Drag and drop images here, or click to select files
                          </p>
                          <label
                            onClick={(event) => event.stopPropagation}
                            style={{ color: "red" }}
                          >
                            {`You can upload only 10 images.(Max Size: 2 MB)`}
                          </label>
                        </div>
                      );
                    }}
                  </Dropzone>
                </div>
              </ModalContent>
            </Modal>
          </div>
        </Container>
      )}
    </div>
  );
};

export default WidgetsImages;
