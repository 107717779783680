//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------


export const handleListOneWayTeacherVideo = () => async (dispatch) => {
    try {
        const video = await API.get('oneway_teacher_video', `/list`);
        if (Array.isArray(video)) {
            await dispatch(Actions.listOnewayTeacherVideo(video));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleCreateHOneWayTeacherVideo = (data) => async (dispatch) => {
    try {
        const videos = await API.post('oneway_teacher_video', `/create`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.createOnewayTeacherVideo(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleUpdateOneWayTeacherVideo = (data) => async (dispatch) => {
    try {
        const videos = await API.put('oneway_teacher_video', `/update`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.updateOnewayTeacherVideo(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error,"error")
    }
};
export const handleDeleteOnewayTeacherVideo = (id) => async (dispatch) => {
    try {
        const videos = await API.del('oneway_teacher_video', `/delete/${id}`);
        if (has(videos, "id")) {
            await dispatch(Actions.deleteOnewayTeacherVideo(id));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};