//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _, { omit ,get } from 'lodash';
//  -- Actions ------------------------
import * as ClassQuizActions from './actions';
import * as Sentry from "@sentry/react";
import moment from "moment"
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create class quiz --------------
export const handleCreateClassQuiz = (data) => async (dispatch) => {
    await dispatch(ClassQuizActions.createClassQuiz());

    try {
        data.scheduleAt =data.scheduleAt&&moment(data.scheduleAt).isValid()?data.scheduleAt: moment().toString()
        let dataClassQuiz = omit(data, ['studentsSelected', 'dueDateDif', 'retakesDif', 'levelExercise'])
        if (dataClassQuiz.classes && dataClassQuiz.classes.length > 0) {
            dataClassQuiz.classes = dataClassQuiz.classes.map(cls => ({ ...cls , users:cls.users?cls.users:[] }))
        }
        const diffQuiz = [];
        let dataSS = data.studentsSelected;
        if (Array.isArray(dataSS)) {
            dataSS.map(student => {
                diffQuiz.push({
                    classId: data.classId,
                    type: 'quiz',
                    exerciseId: data.quizId,
                    userId: student.studentId,
                    dueDate: data.dueDateDif?data.dueDateDif:data.dueDate,
                    retakes: data.retakesDif?data.retakesDif:data.retakes,
                    lateDate: data.lateDate,
                    timeDuration: data.timeDuration,
                    levelExercise: data.levelExercise,
                    weightage: data.weightage,
                    displayName: data.displayName,
                    scheduleAt: data.scheduleAtDiff ? data.scheduleAtDiff: data.scheduleAt ,
                })
            })
        }
        const clsAct = await API.post('classQuizzesRDS', `/classQuizDiff`, {
            body: { quiz: dataClassQuiz, diff: diffQuiz}
        });
        if (typeof clsAct == "object" && !clsAct.error) {
            const update = {
                ...clsAct.quiz.filter(act => act.classId == data.classId)[0],
                diff:get(clsAct,"diff",[]),
                data: omit(dataClassQuiz, ['activityId', 'classId', 'dueData'])
            };

            await dispatch(ClassQuizActions.createClassQuizSuccess(update));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassQuizActions.createClassQuizFail(error));
    }
};

//  -- Delete class quiz --------------
export const handleDeleteClassQuiz = (quizID, classId) => async (dispatch) => {
    await dispatch(ClassQuizActions.deleteClassQuiz());

    try {
        await API.del('classQuizzesRDS', `/classQuizzesDiff/${quizID}?classId=${classId}`);
        await dispatch(ClassQuizActions.deleteClassQuizSuccess({ id: quizID }));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassQuizActions.deleteClassQuizFail(error));
    }
};

export const handleDeleteEntireBookAssignment = async (bookId, classId) => {
    try {
        await API.del('classQuizzesRDS', `/deleteEntireBookAssignment/${bookId}/${classId}`);
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

//  -- List class quizzes -------------
export const handleListClassQuizzes = (classID) => async (dispatch) => {
    await dispatch(ClassQuizActions.listClassQuizzes());
    try {
        const quizzes = [];
        const quizzesRDS = classID ? await API.get('classQuizzesRDS', `/classQuizzesData/${classID}`) : [];
        if (Array.isArray(quizzesRDS) && quizzesRDS.length > 0) {
            quizzes.push(...quizzesRDS);
        }
        await dispatch(ClassQuizActions.listClassQuizzesSuccess(quizzes));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassQuizActions.listClassQuizzesFail(error));
    }
};


export const handleListClassQuizzesForStudent = (classId ,userId ) => async (dispatch) => {
    await dispatch(ClassQuizActions.listClassQuizzes());
    try {
        const quizzes = [];
        const quizzesRDS =  await API.get('classQuizzesRDS', `/classQuizDataStudent/${classId}/${userId}`);
        if (Array.isArray(quizzesRDS) && quizzesRDS.length > 0) {
            quizzes.push(...quizzesRDS);
        }
        await dispatch(ClassQuizActions.listClassQuizzesSuccess(quizzes));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassQuizActions.listClassQuizzesFail(error));
    }
};
//  -- Update class quiz --------------
export const handleUpdateClassQuiz = (quizID, data, infoDiffSection, toCreateStudent, quizInfo, updateTOAllClass) => async (dispatch) => {
    await dispatch(ClassQuizActions.updateClassQuiz());
    const diffUpdate = [];
    const diffCreate = [];
    try {
        if (get(infoDiffSection,"diff.length",0)) {
            let infoDiff = infoDiffSection.diff;
            for (let i in infoDiff) {
                let idDiff = infoDiff[i].id;
                let bodyDiff = {
                    id: idDiff,
                    classId: infoDiff[i].classId,
                    dueDate: infoDiffSection.dueDateDif,
                    retakes: infoDiffSection.retakesDif,
                    exerciseId: quizInfo.quizId,
                    levelExercise: infoDiffSection.levelExercise || "easy",
                    scheduleAt:  infoDiffSection.scheduleAtDiff  ||  infoDiffSection.scheduleAt ,
                    lateDate: infoDiffSection.lateDate,
                }
                diffUpdate.push(bodyDiff);


            }
        }
        if (Array.isArray(toCreateStudent) && toCreateStudent.length > 0 && typeof quizInfo === 'object') {

            for (var s in toCreateStudent) {
                let body = {
                    classId: quizInfo.classId,
                    type: 'quiz',
                    exerciseId: quizInfo.quizId,
                    userId: toCreateStudent[s].studentId,
                    dueDate: infoDiffSection.dueDateDif,
                    retakes: infoDiffSection.retakesDif,
                    lateDate: quizInfo.lateDate,
                    timeDuration: quizInfo.timeDuration,
                    levelExercise: infoDiffSection.levelExercise || "easy",
                    scheduleAt: infoDiffSection.scheduleAtDiff ,
                    lateDate: infoDiffSection.lateDate,
                }
                diffCreate.push(body);
            }
        }
        let body = {
            quizId: quizID,
            quiz: data,
            diffUpdate,
            diffCreate,
            updateTOAllClass
        }
        await API.put('classQuizzesRDS', `/classQuizzesDiff`, { body });
        await dispatch(ClassQuizActions.updateClassQuizSuccess({ id: quizID, ...data }));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ClassQuizActions.updateClassQuizFail(error));
    }
};


export const handleChangeStatusQuizzes = (quizId, active) => async (dispatch) => {
    if (!quizId) return;
    try {
        const statusData = await API.put('classQuizzesRDS', `/changeStatus`, {
            body: {
                id: quizId,
                active: active
            }
        });
        if (statusData) {
            await dispatch(ClassQuizActions.updateClassQuizSuccess({ id: quizId, ...statusData }));
        }
        return statusData;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};