//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Sentry from "@sentry/react";
import { v4 } from 'uuid';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const createKeystore = async (userId, key) => {
    try {
        await API.post('keystore', `/keystore`, {
            body: {
                userId,
                key
            }
        });
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const getKeystore = async (key) => {
    try {
       return await API.get('keystore', `/keystore/${key}`);
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};


export const openLoginInNewWindow = async (userId) => {
    const key = v4()
    await createKeystore(userId, key)
    const url = `${window.location.origin}?key=${key}`
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
  }