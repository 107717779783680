import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Switch, TimePicker, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DebateNewLogo, EditDebate } from "utils/assets/images";
import { map } from "lodash";
import { MenuItem, Select } from "@material-ui/core";

const DebateStudentType = ({
  debateInfo,
  handleClickStudentDetail = () => null,
  timer,
  studentSubmission,
  onEditTimer = () => null
}) => {
  const [showStudentNames, setShowStudentNames] = useState(false);
  const [timeSelected, setTimeSelected] = useState('00:00:00');
  const [isEdit, setIsEdit] = useState(false)
  const handleEditTImer = () => {
    setIsEdit(!isEdit);
  }

  // const getTimeFromDate = (dateString) => {
  //   const dateObject = new Date(dateString);

  const getTimeFromDate = (timer) => {
    const timeComponents = timer.split(":");
    const minutes = parseInt(timeComponents[0]); // Extract minutes part
    const seconds = parseInt(timeComponents[1]); // Extract seconds part
  const totalTimeInSeconds = minutes * 60 + seconds; // Convert minutes to seconds and add seconds

    return totalTimeInSeconds;
  };

  const audio = new Audio(require("utils/assets/audio/timer_end.mp3"));
    // audio.loop = true
    audio.volume = 1

    useEffect(() => {
      if (getTimeFromDate(timer) <= 0) {
        audio.pause()
      } else if (getTimeFromDate(timer) <= 5) {
        audio.play();
      } else {
        
      }
    }, [timer, audio]);

  return (
    <div className="ideaMdlUpr">
      <Row>
        <Col xs={24}>
          <div className="uprDebateStudntListing">
            <div className="debateMdlTop debateAllTopTxt debateAllTopSpace">
              <img src={DebateNewLogo} alt="Debate" />
              <div className="topSliderHeaderTxt">
                <span>{debateInfo?.question}</span>
              </div>
            </div>
            <div className="stdntTopCommitSec">
              <div className="studntCommitSection">
                
                <div className={getTimeFromDate(timer) <= 0 ? 'blackTimerSection' : getTimeFromDate(timer) <= 5  ? 'stduntTimerSection' : ''}>
                  <span>{timer}</span>
                </div>
                
                {isEdit && getTimeFromDate(timer) > 0 &&
                  <div className="blogInputBox">
                    <div style={{ marginRight: 12 }}>
                      <button onClick={() => {
                        onEditTimer(1 * 60);
                      }}>+ 1</button>
                    </div>
                  </div>
                }
                {getTimeFromDate(timer) > 0 &&
                   <img style={{ cursor: 'pointer' }} src={EditDebate} alt="Debate" onClick={() => { handleEditTImer() }} />
                }
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className="debateStdntSection">
            <div className="debateTpcBtns">
              {map(studentSubmission, (item, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      handleClickStudentDetail(index);
                    }}
                  >
                    {showStudentNames ? (
                      item?.user_name && item?.user_name?.length > 8 ? (
                        <Tooltip title={item.user_name}>
                          {item?.user_name?.slice(0, 8) + "..."}
                        </Tooltip>
                      ) : (
                        item?.user_name
                      )
                    ) : (
                      `Student ${index + 1}`
                    )}
                  </Button>
                );
              })}
              {studentSubmission?.length === 0 && (
                <div className="studentResponceSec">
                  <span>Waiting for students response...</span>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className="debeteCountTypeSec debeatLstStdentSec">
            <div className="debatCountTxt">
              <span>Show student names</span>
              <Switch
                onChange={(checked) => setShowStudentNames(checked)}
              />
            </div>
            <div className="debeatStdntRspns">
              <span>Click on the response to show the answer.</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DebateStudentType;
