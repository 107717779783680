//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import _, { groupBy,  filter, map , get } from 'lodash';
import { lighten } from 'polished';
import moment from 'moment';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Thunks -------------------------
import { handleListBubbleClassNotes } from 'store/notes/thunks';
import { handleListClassQuestions } from 'store/questions/thunks';
import { handleListClassSubmissions } from 'store/submissions/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import ReactHighcharts from "react-highcharts";
import { Birdimg, Worldimg, BoxSettimgimg, Ps, NoDataTeacherGraphimg } from "utils/assets/images"
import { getRandomCotation , isBeforTime } from 'utils/methods/math';
import { useCurrentOrgPermission , useOrgTimezone } from 'store/organizations/selectors';
import { useServertime } from 'store/servertime/selectors';
import "moment-timezone"
const config = {
    chart: {
        type: 'column',
        backgroundColor: 'transparent',
    },
    title: {
        text: ''
    },
    accessibility: {
        announceNewData: {
            enabled: true
        }
    },
    xAxis: {
        type: 'category'
    },
    yAxis: {
        title: {
            text: ''
        }

    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            groupPadding: 0,
            pointPadding: 0,
            borderWidth: 0,
            color: COLOR.green,
            maxPointWidth: 70,
            dataLabels: {
                enabled: true,
            }
        }
    },

    tooltip: {
        headerFormat: '',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>'
    },

    series: [
        {
            name: "",
            colorByPoint: false,
            data: [
                {
                    name: "Notes taken",
                    y: 0,

                },
                {
                    name: "Question asked",
                    y: 0,


                },
                {
                    name: "Actitivies assigned",
                    y: 0,
                },
                {
                    name: "Actitivies completed",
                    y: 0,
                },
                {
                    name: "Actitivies past due",
                    y: 0,
                },
                {
                    name: "Quizzes Assigned",
                    y: 0,
                },
                {
                    name: "Quizzes completed",
                    y: 0,
                },
                {
                    name: "Quizzes past due",
                    y: 0,
                }
            ]
        }
    ],

}


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Days = styled.span`
    /* color: ${lighten(0.15, '#3D5F8F')} */;
    color: white;
    display: block;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    width: 100%;
`;
const Header = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 8px;
`;

const Overlay = styled.div`
    align-items: center;
    border: 1px solid ${COLOR.black800};
    border-radius: 3px;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 10;
`;

const Section = styled.div`
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 85%), 0 0px 2px rgb(0 0 0 / 85%);
    display: ${p => p.blur ? 'none' : 'flex'};
    flex-direction: row;
    height: ${p => `${p.height}px` || '100%'};
    margin-bottom: ${p => `${p.marginBottom}px`};
    padding: 16px;
    width: 100%;
`;

const Wrap = styled.div`
    color: ${COLOR.black100};
    height: ${p => p.blur ? p.height : 'auto'};
    margin: ${p => p.blur ? p.margin : '0px'};
    position: relative;
    width: 100%;
`;

const Wrapper = styled.div`
    height: auto;
    margin-bottom: 40px;
    width: 100%;
`;
const SettingIcon = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: -20px;
`;
const Box = styled.div`
    -webkit-box-shadow: -2px 0px 7px 0px rgba(163,150,150,0.48);
    -moz-box-shadow: -2px 0px 7px 0px rgba(163,150,150,0.48);
    box-shadow: -2px 0px 7px 0px rgba(163,150,150,0.48);
    height: 350px;
    width:250px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom,${COLOR.indigo300} ,${COLOR.indigo600},${COLOR.indigo200},${COLOR.indigo100},${COLOR.indigo500});
`;

const BoxRow = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
`
const NoteTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
    color: white;
    padding-top: 10px;
    text-transform: uppercase;
    white-space: pre-line;
    line-height: 20px;
`;
const NoteText = styled.div`
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    color: white;
    margin-left: auto;
`
const Column = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-bottom:20px;
`;
const ContentWrapper = styled.div`
height:${p => p.height ? p.height : "30%"};
`
const Birtdimg = styled.img`
    height: 280px;
    width: 320px;
    position: absolute;
    z-index: -1;
    left: -112px;
    top: -90px;

     @media only screen and (max-width: 425px){
        z-index: -1;
        left: -112px;
        top: -90px;
    }
   
`

const PSimg = styled.img`
    height: 40px;
    width: 60px;
`
const WorldImage = styled.div`
    width: 60%;
    height: 250px;
    background-image: url(${Worldimg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    margin: auto;
    position: relative;
    

    @media only screen and (max-width: 425px){
      max-width: 100%;
     width: 100%;
    //  background-size: unset;
    object-fit: cover;
    
    }
}

`
const CotationTxt = styled.div`
    text-align:center;
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
`
const AuthorTxt = styled.div`
    text-align:center;
    font-weight:bold;
    color: #000000;
    text-transform: uppercase;

`
const DateTxt = styled.div`
    text-align:center;
    margin-bottom: 30px;
    color: #000000;
    text-transform: uppercase;

    @media (max-width:500px){
        margin-bottom: 60px;
    }
`
const MessageWrapper = styled.div`
  margin:auto;
  display:flex;
  flex-direction:row;
  margin-bottom:20px;
  padding-left:10%;
  padding-right:10%;
`
const WorkMapWrapper = styled.div`
    margin:auto;
    display:flex;
    flex-direction:row;
    width:100%;
    height:100%;
    position: relative;
`
const NoDataImg = styled.img`
  width: 100%;
  height: 80%;
  object-fit: fill;
  `
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Admin class visuals
//
//  -- Description
//  Admin view class visuals
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcements data={array} />
//
const CardItem = ({ title, value }) => {
    return (
        <Box>
            <ContentWrapper height="30%">
                <NoteTitle>{title}</NoteTitle>
            </ContentWrapper>
            <ContentWrapper height="20%">
                <NoteText>{value}</NoteText>
            </ContentWrapper>
            <ContentWrapper height="50%">
                <SettingIcon src={BoxSettimgimg} />
            </ContentWrapper>
        </Box>)
}
const AdminVisuals = ({
    classActivities,
    classNotes,
    classQuizzes,
    classQuestions,
    classUsers,
    currentClass,
    submissions,
}) => {
    const dispatch = useDispatch();
    const servertime = useServertime()
    const timezone = useOrgTimezone()
    const [loading, setLoading] = useState(true);
    const [quotation, setQuotation] = useState(getRandomCotation());
    const [pastDueQuizCount, setPastDueQuizCount] = useState(0);
    const [pastDueActivityCount, setPastDueActivityCount] = useState(0);
    const [completedQuizCount, setCompletedQuizCount] = useState(0);
    const [completedActivityCount, setCompletedActivityCount] = useState(0);
    const [hasNoData, setHasNoData] = useState(true);
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = _.get(orgPermissions, "books", [])
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let adminVisualClassId = localStorage.getItem("adminVisualClassId")
            if ((adminVisualClassId && adminVisualClassId == currentClass?.id) || (!currentClass?.id && adminVisualClassId === "noclasssid")) {
                setLoading(false);
            }
            await Promise.allSettled([
                 dispatch(handleListClassSubmissions(currentClass.id)),
                 dispatch(handleListBubbleClassNotes(currentClass.id))
            ])
           
            localStorage.setItem("adminVisualClassId", currentClass?.id ?? "noclasssid")
            setLoading(false);
            dispatch(handleListClassUsers(currentClass.id));
            dispatch(handleListClassQuestions(currentClass.id));
        }
        fetchData();
    }, [currentClass, dispatch])
    useEffect(() => {
        setQuotation(getRandomCotation())
    }, [])
    const allBarGrafRef = useRef(null);



    useEffect(() => {
        let pastDueQizlength = 0;
        let pastDueActivitylength = 0;
        const filteredQuizzes = _.filter(classQuizzes, k => {
            if (bookPermissions.length == 0) {
                return true;
            }
            const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "data.bookId")])
            if (bookaccess) {
                return bookaccess.available
            } else {
                return true;
            }
        })
        const filteredActivities = _.filter(classActivities, k => {
            if (bookPermissions.length == 0) {
                return true;
            }
            const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "data.bookId")])
            if (bookaccess) {
                return bookaccess.available
            } else {
                return true;
            }
        })
        const filteredSubmissions = _.filter(submissions, k => {
            if(!get(k,"user.active",true)){
                return false
            }
            if (bookPermissions.length == 0) {
                return true;
            }
            const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "assignment.bookId")])
            if (bookaccess) {
                return bookaccess.available
            } else {
                return true;
            }
        })
        const numberOfUser = filter(classUsers,k=>get(k,"role")=='student'&& get(k,"active",true)).length
        const quizAssigned = filteredQuizzes?.length * numberOfUser;
        const activityAssigned = filteredActivities?.length * numberOfUser
        setHasNoData(filteredQuizzes?.length == 0 && filteredActivities?.length == 0)
        const { activity, quiz } = groupBy(filteredSubmissions, 'assignmentType')
        const groupedActivityByAssignmentId = groupBy(activity, 'assignmentId');
        const groupedQuizByAssignmentId = groupBy(quiz, 'assignmentId');
        const quizCompleted = filter(quiz, ["complete", true]).length;
        const activityCompleted = filter(activity, ["complete", true]).length;
        const pastDueQuiz = filter(filteredQuizzes, x => isBeforTime(get(x, "dueDate"),servertime,timezone) && (!x.lateDate || isBeforTime(get(x, "lateDate"),servertime,timezone)));
        const pastDueActivity = filter(filteredActivities, x => isBeforTime(get(x, "dueDate"),servertime,timezone)&& (!x.lateDate || isBeforTime(get(x, "lateDate"),servertime,timezone)));
        map(pastDueQuiz, x => {
            let numberOfAttempted = groupedQuizByAssignmentId[x.quizId]?.length ?? 0;
            let remaining = numberOfUser - numberOfAttempted;
            pastDueQizlength += Math.abs(remaining);
        })
        map(pastDueActivity, x => {
            let numberOfAttempted = groupedActivityByAssignmentId[x.activityId]?.length ?? 0;
            let remaining = numberOfUser - numberOfAttempted;
            pastDueActivitylength += remaining;
        })
        const questionAsked = classQuestions?.length ?? 0;
        const noteTaken = classNotes?.length ?? 0;
        setPastDueActivityCount(pastDueActivitylength)
        setPastDueQuizCount(pastDueQizlength)
        setCompletedActivityCount(activityCompleted)
        setCompletedQuizCount(quizCompleted)
        if (activityAssigned >= activityCompleted && quizAssigned >= quizCompleted) {
            config.series[0].data = [
                {
                    name: "Notes taken",
                    y: noteTaken,
                },
                {
                    name: "Question asked",
                    y: questionAsked,

                },
                {
                    name: "Actitivies assigned",
                    y: activityAssigned,
                },
                {
                    name: "Actitivies completed",
                    y: activityCompleted,
                },
                {
                    name: "Actitivies past due",
                    y: pastDueActivitylength,
                },
                {
                    name: "Quizzes assigned",
                    y: quizAssigned,
                },
                {
                    name: "Quizzes completed",
                    y: quizCompleted,
                },
                {
                    name: "Quizzes past due",
                    y: pastDueQizlength,
                }
            ];
        }

    }, [classUsers, classQuizzes, classActivities, submissions, orgPermissions])




    // const date1 = new Date();
    // const date2 = new Date("05/11/2022")
    // const apExamDate2=new Date("05/02/2022")
    // date2.setHours(23,59,59,999);
    // apExamDate2.setHours(23,59,59,999);
    // const timeDiff = date2.getTime() - date1.getTime();
    // const apexam2diff = apExamDate2.getTime() - date1.getTime();
    // const diffDays = Math.ceil(apexam2diff / (1000 * 3600 * 24));
    // const days = (timeDiff / (1000 * 3600 * 24)).toFixed(0);

    return (
        <Wrapper>
            {loading ? (
                <Loading />
            ) : (<>
                <BoxRow>
                    <Column>
                        <CardItem
                            title={"Student \n Count"}
                            value={classUsers?.length === 0 ? 0 : filter(classUsers,["active",true]).length - 1}
                        />
                    </Column>
                    <Column>
                        <CardItem
                            title={"Quizzes\n completed"}
                            value={completedQuizCount}
                        />
                    </Column>
                    <Column>
                        <CardItem
                            title={"Quizzes\n past due"}
                            value={pastDueQuizCount}
                        />
                    </Column>
                    <Column>
                        <CardItem
                            title={"Activities\n completed"}
                            value={completedActivityCount}
                        />
                    </Column>
                    <Column>
                        <CardItem
                            title={"Activities\n past due"}
                            value={pastDueActivityCount}
                        />
                    </Column>
                    {/* <Column>
                    <CardItem 
                      title={"Days until AP Spanish and Language Exam"} 
                      value={days <= 0 ? 0 :days} 
                    />
                </Column>  */}
                    {/* <Column>
                    <CardItem 
                      title={"Days until AP Spanish and Literature Exam"}  
                      value={diffDays <= 0 ? 0 :diffDays} 
                    />
                </Column>  */}
                </BoxRow>
                <Row style={{ marginTop: 40 }}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ paddingLeft: hasNoData ? "0%" : "5%" }}>
                        {hasNoData ? <NoDataImg src={NoDataTeacherGraphimg} /> :
                            <ReactHighcharts config={config} ref={allBarGrafRef} />}
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                <Row>
                                    <MessageWrapper>
                                        <PSimg src={Ps} />
                                        <CotationTxt>{quotation.message}</CotationTxt>
                                    </MessageWrapper>
                                </Row>
                                <AuthorTxt>-{quotation.author}</AuthorTxt>
                                <DateTxt>{moment().format('MMMM, Do YYYY')}</DateTxt>
                            </Col>
                        </Row>
                        <Row>
                            <WorkMapWrapper>
                                <WorldImage>
                                    <Birtdimg src={Birdimg} />
                                </WorldImage>
                            </WorkMapWrapper>
                        </Row>
                    </Col>
                    {/* <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                        <Row>
                            <Col span={24}>
                                <Header>Assignment Distribution</Header>
                                <Wrap blur={(classQuizzes?.length === 0 && classActivities?.length === 0)} height="200px">
                                    {(classQuizzes?.length === 0 && classActivities?.length === 0) && (
                                        <Overlay>
                                            No Activities or Quizzes Assigned
                                        </Overlay>
                                    )}
                                    <Section height={353} marginBottom={24} blur={(classQuizzes?.length === 0 && classActivities?.length === 0)}>
                                        <ResponsivePie
                                            data={(classQuizzes?.length === 0 && classActivities?.length === 0) ? MockAssigned : PIE_DATA}
                                            margin={{ top: 10, right: 10, bottom: 65, left: 10 }}
                                            innerRadius={0.5}
                                            padAngle={0.7}
                                            cornerRadius={3}
                                            colors={['rgb(207, 38, 184)', '#1890ff']}
                                            borderWidth={1}
                                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                            enableRadialLabels={false}
                                            radialLabelsSkipAngle={10}
                                            radialLabelsTextXOffset={6}
                                            radialLabelsTextColor="white"
                                            radialLabelsLinkOffset={0}
                                            radialLabelsLinkDiagonalLength={16}
                                            radialLabelsLinkHorizontalLength={24}
                                            radialLabelsLinkStrokeWidth={1}
                                            radialLabelsLinkColor={{ from: 'color' }}
                                            slicesLabelsSkipAngle={10}
                                            slicesLabelsTextColor="white"
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    translateY: 56,
                                                    itemWidth: 90,
                                                    itemHeight: 18,
                                                    itemTextColor: 'rgb(0 0 0 / 85%)',
                                                    symbolSize: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: 'rgb(0 0 0 / 85%)'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        />
                                    </Section>
                                </Wrap>
                            </Col>
                        </Row>
                    </Col> */}
                    {/* <Col xs={{ span: 24 }} lg={{ offset: 1, span: 15 }}> */}
                    {/* <ReactHighcharts config={config} ref={allBarGrafRef}  /> */}
                    {/* <Header>Activities & Quizzes</Header>
                        <Wrap blur={(quizLength === 0 && activityLength === 0)} height="140px" margin='0px 0px 24px 0px'>
                            {(quizLength === 0 && activityLength === 0) && (
                                <Overlay>
                                    No Student Submissions
                                </Overlay>
                            )}
                            <Section height={230} marginBottom={24} blur={(quizLength === 0 && activityLength === 0)}>
                                <ResponsiveBar
                                    data={(quizLength === 0 && activityLength === 0) ? MockSubmissions : DATA}
                                    keys={[ 'Completed', 'Average Score' ]}
                                    indexBy="type"
                                    margin={{ top: 0, right: 130, bottom: 26, left: 30 }}
                                    padding={0.3}
                                    groupMode="grouped"
                                    layout="horizontal"
                                    colors={['#ffb100', 'rgba(0, 0, 0, 0.78)']}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        tickValues: 5,
                                        legend: 'type',
                                        legendPosition: 'middle',
                                        legendOffset: 32
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'assignments',
                                        legendPosition: 'middle',
                                        legendOffset: -40
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={'white'}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'bottom-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    animate={false}
                                    motionStiffness={90}
                                    motionDamping={15}
                                />
                            </Section>
                        </Wrap> */}
                    {/* <Row>
                            <Col xs={{ span: 24 }}>
                                <Header>Notes & Questions</Header>
                                <Wrap blur={(classNotes?.length === 0 && classQuestions?.length === 0)} height="132px">
                                    {(classNotes?.length === 0 && classQuestions?.length === 0) && (
                                        <Overlay>
                                            No Notes Taken or Questions Asked
                                        </Overlay>
                                    )}
                                    <Section height={195} marginBottom={24} blur={(classNotes?.length === 0 && classQuestions?.length === 0)}>
                                        <ResponsiveBar
                                            data={(classNotes?.length === 0 && classQuestions?.length === 0) ? MockNotes : DATA2}
                                            keys={[ 'Questions Asked', 'Notes Taken' ]}
                                            indexBy="type"
                                            margin={{ top: 0, right: 135, bottom: 26, left: 10 }}
                                            padding={0.3}
                                            groupMode="grouped"
                                            layout="horizontal"
                                            colors={['rgb(207, 38, 184)', 'rgba(0, 0, 0, 0.78)']}
                                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                tickValues: (length > 5 ? (length % 5) : length),
                                                legend: 'type',
                                                legendPosition: 'middle',
                                                legendOffset: 32
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'assignments',
                                                legendPosition: 'middle',
                                                legendOffset: -40
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={'white'}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 120,
                                                    translateY: 0,
                                                    itemsSpacing: 2,
                                                    itemWidth: 100,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={false}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />
                                    </Section>
                                </Wrap>
                            </Col>
                        </Row> */}
                    {/* </Col> */}
                </Row>
            </>)}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AdminVisuals.propTypes = {};

//  -- Default props ------------------
AdminVisuals.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    classActivities: state.classActivities,
    classNotes: state.classNotes,
    classQuestions: state.classQuestions,
    classQuizzes: state.classQuizzes,
    classUsers: state.classUsers,
    currentClass: state.currentClass,
    submissions: state.submissions,
});

export default connect(mapStateToProps, null)(AdminVisuals);
