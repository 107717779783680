import React, { useEffect, useState } from 'react';
import { Col, Row , Dropdown } from 'antd';
import Link from '@material-ui/core/Link';
import { Modal, Box } from '@material-ui/core';
import {  map } from "lodash"
import styled from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinks from './../containers/videoLinks';
import VideoLinkDropdown from './../containers/videoLinkDropdown';
import { closeModal ,  videoLink } from "utils/assets/images"
import { useHowToVideos } from 'store/howToVideos/selectors';
import { useDispatch } from 'react-redux';
import { handleListHowToVideos } from "store/howToVideos/thunks"

const VideoModal = styled.video`
     max-width:60vw; 
`;
const style = {};

export default function LearnScreen() {
    const howToVideos = useHowToVideos()
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const [videoModalInfo, setVideoModalInfo] = React.useState({
        open: false,
        video: '',
        poster: ''
    });
    const handleOpen = (item) => setVideoModalInfo({
        open: true,
        video: item.video,
        poster: item.poster
    });
    const handleClose = () => setVideoModalInfo({
        open: false,
        video: '',
        poster: ''
    });
    useEffect(()=>{
        if(howToVideos?.length ==0){
            dispatch(handleListHowToVideos())
        }
    },[])
    return (
        <>
            <Modal open={videoModalInfo.open} onClose={handleClose} className="modelCntnt">
                <Box sx={style} className="modelCntntIner modalVideoPlay">
                    <div className="lernModalBg">
                        <div className="modelDtl">
                            <div className="closeModelInfo closeVideoModal" onClick={handleClose}>
                                <img style={{height: "30px", zIndex: 50}} src={closeModal} onClick={handleClose}   />
                            </div>
                            <VideoModal controls autoPlay   controlsList="nodownload"  src={videoModalInfo.video} poster={videoModalInfo.poster} />
                        </div>
                    </div>
                </Box>
            </Modal>
            <Row className="learnContentUpr videoContentUpr" gutter={[25, 0]}>

                <Col xs={24} sm={24} md={6} className="searchMob">
                </Col>

                <Col xs={24} sm={24} md={18}>
                    <div className="learnContent">
                        <Row gutter={[25, 0]} className="learnDetail">
                            {map(howToVideos,item=>( <Col key={item.id} xs={12} sm={12} md={8} className="cstmLrnRow">
                                <div className="ShowDetailBnr">
                                    <img src={item.poster} />
                                    <Link style={{ cursor:"pointer"}} className="ShowDetail"><img src={videoLink} onClick={()=>handleOpen(item)} /></Link>
                                </div>
                                <div className="learnCntLink learnVideoSubTitle"><a>{item.title}</a></div>
                            </Col>))}
                        </Row>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={6} className="searchWeb">
                    <VideoLinks Items={howToVideos} onClick={handleOpen} />
                </Col>

            </Row>
        </>
    );
}
