//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import _, { filter, groupBy, map , get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { Check, Search } from '@material-ui/icons';
import { Card, Col, Dropdown, Menu, Select, Spin } from 'antd';
import ReactHighcharts from "react-highcharts";
import { CologG, LogoOnExam, TableBg } from "utils/assets/images";
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------

//  -- Thunks -------------------------
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Modal, TablePagination } from "@material-ui/core";
import { TootipWrapper } from 'components/TooltipWrapper';
import FlatList from "flatlist-react";
// import { HIGH_ASSESSMENTS, MIDDLE_ASSESSMENTS } from "utils/config/reference"
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getBgColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR
const PLACEMNETCOLOR = [COLOR.greentrue, COLOR.green900, COLOR.yellow, COLOR.blueish100, COLOR.orange100, COLOR.radish100, , COLOR.red]
const ACIFL_LEVEL = ['ADV HIGH ', 'ADV MID', 'ADV LOW', 'INTER HIGH', 'INTER MID', 'INTER LOW', 'NOVICE HIGH', 'NOVICE MID', 'NOVICE LOW', 'MISSING']
const ACIFL_LEVEL_ABBR = ['AH ', 'AM', 'AL', 'IH', 'IM', 'IL', 'NH', 'NM', 'NL', 'FA']
const EXAM_SCHOOLS = [{ key: 'high', value: 'HIGH SCHOOL' }, { value: 'MIDDLE SCHOOL', key: 'middle' }]
const EXAM_LABELS = ['Interpretativa(A)', 'Interpretativa(B)', 'Comprensión lit..', 'Comprensión aud..', 'Comunicación int..', 'Presentación']
const EXAM_LABELS_FULL = ['Interpretativa(A)', 'Interpretativa(B)', 'Comprensión literaria', 'Comprensión auditiva', 'Comunicación interpersonal', 'Presentación oral informal']
const years = new Date().getFullYear();

function containsNumber(arr) {
    return arr.some(item => !isNaN(item) && typeof item !== 'boolean'); // Ensures it's not a NaN or a boolean
  }
const VideoContainer = styled.div`
    height: auto;
    flex: 1;
    justify-content: center;
`;

const ContentLoading = ({ isLoading, isClose }) => {
    return (
        <Modal
            // className={classes.modal}
            open={isLoading}
        >
            <VideoContainer>
                <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%' }} />
            </VideoContainer>
        </Modal>
    )
};

const config = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        marginTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        height: 120,
        width: 120,
        animation: true,
        backgroundColor: "transparent",
    },
    title: {
        text: "0",
        align: "center",
        verticalAlign: "middle",
        y: 15,
        style: {
            fontWeight: 200,
            fontSize: "14px",
        },
    },
    tooltip: {
        enabled: false,
        formatter: function () {
            return `${this.key ?? ""}` + ":" + this.y + "";
        }
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            cursor: "pointer",
            dataLabels: {
                enabled: false,
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%',
            showInLegend: false,
            borderWidth: 0,
            colors: ["rgb(90,177,208)", COLOR.gray100],
        }
    },
    series: [{
        type: 'pie',
        name: '',
        innerSize: '50%',
        data: [
            ['', 0],
            ['', 100],
        ]
    }]
};



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------



const OverflowTwo = styled.div`
    height: auto;
    overflow-x: scroll;
    width: 70%;
    display: inline-block;
    border-right:1px solid black;
    @media (max-width: 634px) {
        width: 50%;
    }
`;

const AssignmentDeleteWrapper = styled.div`
   cursor:pointer;
   margin-top: 5px;
   opacity:0;
`;


const OneLogoRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 8px 4px 8px;
    margin-bottom: 4px;
`;




const Wrapper = styled.div`
    width: 100%;
`;
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
`;
const TbgImgWrapper = styled.div`
    height: 69px;
    width: 100%;
    display: flex;
    justify-content: center;
`

const Tab = styled.button`
    border: none;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColor(p.active)};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
`;

const OverviewIcon = styled.div`
    width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
    background-image: url(${p => p.src});
    background-position: center;
    background-size: 2880% 288%;
    background-repeat: no-repeat;
}
    `

const Logo = styled.img`
  width: 60px;
  border-radius: 50%;
`;
const OneWayText = styled.span`
  color: #424447;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  text-transform: uppercase;
  line-height: 20px;
`;

const AssignmentTxt = styled.div`
    font-size: 14px;
    margin-left: 20px;
    font-weight: 600;
`;
const NameContent = styled.span`
    color: #fff;
    margin-left: 10px;
    width: 220px;

`;
const StudentNameTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    height:40px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    `;
const QuestionTab = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(128,161,90);
    width: 95%;
    height: 40px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
`;
const StudentName = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(89,118,243);
    width: 95%;
    height: 40px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
`;
const HeaderTab = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:${COLOR.indigo100};
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:0px 5px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const HeaderTabP = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:rgb(11,9,84);
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const TableTopBtnCont = styled.div`
    justify-content: center;
    align-items: center;
    padding-left: 1.5%;
    overflow: hidden;
    display: flex;
    padding-left:5px;
    padding-right:5px;
`;
const TopTabCont = styled.div`
    display:flex;
    flex-direction:row;
`;
const TotalNStudentTxt = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
    white-space:pre-line;
    `;
const Exam2TableHeader = styled(TableHeader)`
    background-color:rgb(54,58,78);
    height:45px;
    border-radius:5px;
    align-items:center;
 

`;
const ChartContainer = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap:wrap;
    @media (max-width: 992px) {
       width:100%;
      }
    
`;
const ChartContainerInner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:50px;
    margin-left:10px;
    margin-right:10px;
`;
const ChartLabel = styled.span`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -20px;
`;
const SearchBox = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 7px;
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  outline: none;
  padding: 2px 16px;
  width: 90%;
  margin-right: 2px;
  margin-left: 2px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
  }
  :-ms-input-placeholder {
    color: #fff;
  }
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const SearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color:rgb(126,112,165);
    color: ${COLOR.green};
    font-size: 14px;
    align-items: center;
    width:100%;
    padding-right:12px;
    height: 30px;
    @media (max-width: 992px) {
       min-width:200px;
       margin-bottom:30px;
      }
`;
const ExamTableSection1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    justify-content: space-between;
    `;
const ExamTableSection2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 992px) {
        width: 100%;
    }
    `;
const TableItem = styled.td`
    height:40px;
    width: 25%;
    border: 0.1px solid rgba(0,0,0,0.2);
    border-collapse: collapse;
    text-align:center;
    padding-top: 10px;
    `;
const ExamGradeTable = styled.table`
  border-collapse: collapse;
`;
const ExamGradeBoday = styled.tbody`
`;
const ExamRow = styled.tr`
    display: flex;
    flex-direction: row;`;
const PlacementText = styled.span`
       color:${p => p.textColor ? p.textColor : COLOR.gray100}
    `;
const GraphRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:20px;
   @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    }

`;
const ExamOverflowTwo = styled(OverflowTwo)`
    width:100%;
    border-right: 0px;
    @media (max-width: 992px) {
        width:100%;
    }
    `;
const CloseBtn = styled.span`
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    align-self: flex-end;
    display:flex;
    flex-direction:row;
    color:rgb(197,2,91);
    font-weight:bold;
    justify-content: flex-end;
    `;
const ExamGradeSec = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image:url(${TableBg});
    padding:16px;
    margin-bottom:60px;
`;


const ExamButton = styled.button`
     background-color:${COLOR.blueishdeep};
     background-image: ${p => !p.active ? COLOR.blueishdeep : getBgColor(p.active)};
     width:120px;
     height:30px;
     border-radius:5px;
     margin-left:5px;
     margin-right:5px;
     border: 1px solid ${p => p.active ? "#f6ebeb" : "transparent"};
     cursor: pointer;
     box-sizing: border-box;
     box-shadow:${p => p.active ? "2px 2px 2px rgb(35 53 89 / 19%), -2px -2px 2px rgb(35 53 89 / 19%)" : "none"};
     color:rgb(255,255,255);
}
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;

const getWidth = (index) => {
    const minSize = 110
    let txt = ACIFL_LEVEL[index]
    if (!txt) return minSize + 50
    return txt.length * 2 + minSize + 10

}
const getPlacemEntColor = (v) => {
    if (v == ACIFL_LEVEL[2] || v == ACIFL_LEVEL_ABBR[2]) {
        return PLACEMNETCOLOR[0]
    } else if (v == ACIFL_LEVEL[3] || v == ACIFL_LEVEL_ABBR[3]) {
        return PLACEMNETCOLOR[1]
    } else if (v == ACIFL_LEVEL[4] || v == ACIFL_LEVEL_ABBR[4]) {
        return PLACEMNETCOLOR[2]
    } else if (v == ACIFL_LEVEL[5] || v == ACIFL_LEVEL_ABBR[5]) {
        return PLACEMNETCOLOR[3]
    } else if (v == ACIFL_LEVEL[6] || v == ACIFL_LEVEL_ABBR[6]) {
        return PLACEMNETCOLOR[4]
    } else if (v == ACIFL_LEVEL[7] || v == ACIFL_LEVEL_ABBR[7]) {
        return PLACEMNETCOLOR[5]
    } else if (v == ACIFL_LEVEL[8] || v == ACIFL_LEVEL_ABBR[8]) {
        return PLACEMNETCOLOR[7]
    } else if (v == 'NOT FINISHED' || v == 'NF') {
        return COLOR.yellow
    }
    else {
        return COLOR.gray100;
    }
}

const ViewGradeTable = ({ filteredusers_response, question_length }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    useEffect(()=>{
        setPage(0)
    },[filteredusers_response])
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const checkMark = (submissions) => {
        if (!submissions.includes("-")) {
            return <TootipWrapper hasToolTip label={`Completed`}>
                <Check style={{ backgroundColor: COLOR.greentrue, color: COLOR.white, fontSize: 12, borderRadius: "50%" }} />
            </TootipWrapper>
        } else if (containsNumber(submissions)) {
            return <TootipWrapper hasToolTip label={`Not finished`}>
                <Check style={{ backgroundColor: COLOR.orange100, color: COLOR.white, fontSize: 12, borderRadius: "50%" }} />
            </TootipWrapper>
        } else {
            return null;
        }
    }
    // data.grades.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) 
    return (<>

        <ExamGradeBoday style={{ display: "flex", flexDirection: "column", marginTop: -3 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <ExamTableSection1>
                    <StudentNameTable>
                        <QuestionTab>Questions per section:</QuestionTab>
                    </StudentNameTable>
                </ExamTableSection1>
                <ExamTableSection2>
                    <ExamGradeTable borderd>
                        <ExamRow>
                            {question_length.map((k, i) => {
                                let _width = i == 0 ? 140 : getWidth(i - 1);
                                return (
                                    <TableItem key={i+"q_len"} style={{ width: _width }}>{i == 0 ? "" : k}</TableItem>
                                )
                            })}
                        </ExamRow>

                    </ExamGradeTable>
                </ExamTableSection2>
            </div>
            <FlatList
                list={filteredusers_response.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                renderItem={({ user, submissions }, i) => (
                    <div style={{ display:"flex"  , flexDirection:"row"}} key={i+"res"}>
                        <ExamTableSection1>
                            <StudentNameTable>
                                <StudentName>{user.firstName} , {user.lastName}</StudentName>{" "}
                            </StudentNameTable>
                        </ExamTableSection1>
                        <ExamTableSection2>
                            <ExamGradeTable borderd>
                                <ExamRow>
                                    {map(submissions, (k, ind) => {
                                        let _width = ind == 0 ? 140 : getWidth(ind - 1);
                                       
                                        return (
                                            <TableItem style={{ width: _width }} key={ind+"plac"}>
                                                {ind == 0 ? (
                                                    <PlacementText textColor={getPlacemEntColor(k)}>
                                                        {k} {checkMark(submissions)}
                                                    </PlacementText>
                                                ) : (
                                                    k
                                                )}
                                            </TableItem>
                                        )
                                    })}
                                </ExamRow>
                            </ExamGradeTable>
                        </ExamTableSection2>
                    </div>
                )}
                renderWhenEmpty={() => <div></div>}
            />




        </ExamGradeBoday>
        <TablePagination
            count={filteredusers_response.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[50, 100, 150, 200 , 300, 400 , 500]}
            style={{   float: "right" }}
            backIconButtonProps={{
                color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
        />
    </>)
}
const ViewGradeTableCombine = ({ filteredusers_response, question_length }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    useEffect(()=>{
        setPage(0)
    },[filteredusers_response])
    // data.grades.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) 
    return (<>

        <ExamGradeBoday style={{ display: "flex", flexDirection: "column", marginTop: -3 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <ExamTableSection1>
                    <StudentNameTable>
                        <QuestionTab style={{ backgroundColor: COLOR.indigo200 }}>Exams</QuestionTab>
                    </StudentNameTable>
                    <StudentNameTable>
                        <QuestionTab>Questions per section:</QuestionTab>
                    </StudentNameTable>
                </ExamTableSection1>
                <ExamTableSection2>
                    <ExamGradeTable borderd>
                        <ExamRow>
                            {map(['', ...EXAM_LABELS], (k, i) => {
                                let _width = i == 0 ? 140 : getWidth(i - 1);
                                return (<div key={k + i} style={{ backgroundColor: COLOR.indigo400, color: "#fff", width: _width }}>
                                    <TableItem style={{ width: _width / 3 }}>E1</TableItem>
                                    <TableItem style={{ width: _width / 3 }}>E2</TableItem>
                                    <TableItem style={{ width: _width / 3 }}>E3</TableItem>
                                </div>)
                            })}
                        </ExamRow>

                        <ExamRow>
                            {question_length.map(({ exam1, exam2, exam3 }, i) => {
                                let _width = i == 0 ? 140 : getWidth(i - 1);
                                return (
                                    <div key={exam1 + i} style={{ width: _width, backgroundColor: i % 2 != 0 ? "rgb(242,248,254)" : "transparent" }}>
                                        <TableItem style={{ width: _width / 3 }}>
                                            {i == 0 ? "" : exam1}
                                        </TableItem>
                                        <TableItem style={{ width: _width / 3 }}>
                                            {i == 0 ? "" : exam2}
                                        </TableItem>
                                        <TableItem style={{ width: _width / 3 }}>
                                            {i == 0 ? "" : exam3}
                                        </TableItem>
                                    </div>
                                )
                            })}
                        </ExamRow>

                    </ExamGradeTable>
                </ExamTableSection2>
            </div>
            <FlatList
                list={filteredusers_response.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                renderItem={({ user, submissions }, ind) => (
                    <div style={{ display: "flex", flexDirection: "row" }} key={ind+"res"}>
                        <ExamTableSection1>
                            <StudentNameTable>
                                <StudentName>{user.firstName} , {user.lastName}</StudentName>{" "}
                            </StudentNameTable>
                        </ExamTableSection1>
                        <ExamTableSection2>
                            <ExamGradeTable borderd>
                                <ExamRow>
                                    {map(submissions, ({ exam1, exam2, exam3 }, ind) => {
                                        let _width = ind == 0 ? 140 : getWidth(ind - 1);
                                        return (
                                            <div key={ind} style={{ width: _width, backgroundColor: ind % 2 != 0 ? "rgb(242,248,254)" : "transparent" }}>

                                                {ind == 0 ? (
                                                    <div>
                                                        <TableItem style={{ width: _width / 3 }}>
                                                            <PlacementText textColor={getPlacemEntColor(exam1)}>
                                                                {exam1}
                                                            </PlacementText>
                                                        </TableItem>
                                                        <TableItem style={{ width: _width / 3 }}>
                                                            <PlacementText textColor={getPlacemEntColor(exam2)}>
                                                                {exam2}
                                                            </PlacementText>
                                                        </TableItem>
                                                        <TableItem style={{ width: _width / 3 }}>
                                                            <PlacementText textColor={getPlacemEntColor(exam3)}>
                                                                {exam3}
                                                            </PlacementText>
                                                        </TableItem>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <TableItem style={{ width: _width / 3 }}>
                                                            {exam1}
                                                        </TableItem>
                                                        <TableItem style={{ width: _width / 3 }}>
                                                            {exam2}
                                                        </TableItem>
                                                        <TableItem style={{ width: _width / 3 }}>
                                                            {exam3}
                                                        </TableItem>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </ExamRow>
                            </ExamGradeTable>
                        </ExamTableSection2>
                    </div>
                )}
                renderWhenEmpty={() => <div>No Story Articles</div>}
            />




        </ExamGradeBoday>
        <TablePagination
            count={filteredusers_response.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[50, 100, 150, 200 , 300, 400 , 500]}
            style={{   float: "right" }}
            backIconButtonProps={{
                color: "secondary"
            }}
            nextIconButtonProps={{ color: "secondary" }}
        />
    </>)
}
const ExamTable = ({
    dataLoading,
    examType,
    exams,
    onExamTabClick,
    resultData,
    resultDataCombine,
    onSchoolTypeChange,
    schoolType,
    activeSession,
    setActiveSession
}) => {
    // console.log(exams,"examsexams")
    const [filteredusers_response, setUserResponse] = useState(resultData.grades)
    const [filteredusers_response_combine, setUserResponseCpmbine] = useState(resultDataCombine.grades)

    const isCombined = examType === "combined"

    useEffect(() => {
        setUserResponse(resultData.grades)
    }, [resultData])
    useEffect(() => {
        setUserResponseCpmbine(resultDataCombine.grades || [])
    }, [resultDataCombine])


    const filterStudent = (e) => {
        let value = e.target.value?.toLowerCase();
        if (!isCombined) {
            if (value && value.length > 0) {
                let st = filter(resultData.grades, ({ user }) => {
                    return (user?.username?.toLowerCase()?.includes(value?.toLowerCase()) || user?.firstName?.toLowerCase()?.includes(value?.toLowerCase()) || user?.lastName?.toLowerCase()?.includes(value?.toLowerCase()))
                })
                setUserResponse(st)
            } else {
                setUserResponse(resultData.grades)
            }
        } else {
            if (value && value.length > 0) {
                let st = filter(resultDataCombine.grades, ({ user }) => {
                    return (user?.username?.toLowerCase()?.includes(value?.toLowerCase()) || user?.firstName?.toLowerCase()?.includes(value?.toLowerCase()) || user?.lastName?.toLowerCase()?.includes(value?.toLowerCase()))
                })
                setUserResponseCpmbine(st)
            } else {
                setUserResponseCpmbine(resultDataCombine.grades)
            }
        }

    }
    const filterStudentByClass = (classId) => {
        if (!isCombined) {
            if (classId == "All") {
                setUserResponse(resultData.grades)
            } else {
                let st = filter(resultData.grades, ({ user }) => {
                    return (user.class_id === classId)
                })
                setUserResponse(st)
            }
        } else {
            if (classId == "All") {
                setUserResponseCpmbine(resultDataCombine.grades)
            } else {
                let st = filter(resultDataCombine.grades, ({ user }) => {
                    return (user.class_id === classId)
                })
                setUserResponseCpmbine(st)
            }
        }
    }
    const getChartConfig = (type) => {
        let conf = _.cloneDeep(config);
        conf.chart.height = 90
        conf.chart.width = 80
        conf.title.style.fontSize = "10px"
        conf.title.y = 10
        if (isCombined) return conf;
        const groupdata = groupBy(resultData.grades, ({ submissions }) => submissions[0])
        const not_assigned = get(groupdata['NOT ASSIGNED'], "length", 0)
        const totalStudent = resultData.grades.length;
        let sub = 0;
        const assigned_student = totalStudent - not_assigned || 100
        if (type == ACIFL_LEVEL[ACIFL_LEVEL.length - 1]) {
            sub = get(groupdata['NOT STARTED'], "length", 0) + get(groupdata["NOT FINISHED"], "length", 0)
            conf.plotOptions.pie.colors[0] = "rgb(197,2,91)"
        } else {
            sub = get(groupdata[type], "length", 0)
        }
        let v = [
            [type, sub],
            ['', assigned_student - sub],
        ]
        conf.title.text = sub;
        conf.series[0].data = v;
        return conf;
    }


    const menuData = () => {
        return (<Menu>
            {map(Object.keys(groupBy(exams, "session")), x => {
                return (
                    <Menu.Item key={x} style={{ padding: '0px' }}>
                        <PaddedBtn onClick={() => setActiveSession(x)}>{x}</PaddedBtn>
                    </Menu.Item>
                )
            })}
        </Menu>)
    }

    const Charts = useMemo(() => {
        return (<ChartContainer>
            {map(ACIFL_LEVEL, k => {
                return (<ChartContainerInner key={k}>
                    <ChartLabel style={{ fontSize: 10, marginBottom: -20, color: ACIFL_LEVEL[ACIFL_LEVEL.length - 1] == k ? "rgb(197,2,91)" : COLOR.black }}>{k}</ChartLabel>
                    <ReactHighcharts config={getChartConfig(k)} />
                </ChartContainerInner>)
            })}
        </ChartContainer>)
    }, [resultData, examType])
    // {dataLoading ? (
    //     <Loading />
    // ) : (
    return (
        <Wrapper>
            <ContentLoading isLoading={dataLoading} />
                <div>
                    <ExamGradeSec>
                        <OneLogoRow>
                            <Col span={20} style={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
                                <Logo src={LogoOnExam} />
                                <OneWayText>
                                    {"ONE  WAY"}
                                    <AssignmentTxt> {"Assignments"}</AssignmentTxt>
                                </OneWayText>
                                <Dropdown overlay={menuData()} placement="bottomLeft" trigger={['click']}>
                                    <Button onClick={e => e.preventDefault()}>
                                        {activeSession ? activeSession : 'sessions'}
                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                    </Button>
                                </Dropdown>
                                <ExamButton
                                    active={examType === "exam1"}
                                    style={{ marginLeft: 16 }}
                                    onClick={() => {
                                        onExamTabClick("exam1");
                                    }}
                                >
                                    Exam1
                                </ExamButton>
                                <ExamButton
                                    active={examType === "exam2"}
                                    onClick={() => {
                                        onExamTabClick("exam2");
                                    }}
                                >
                                    Exam2
                                </ExamButton>
                                <ExamButton
                                    active={examType === "exam3"}
                                    onClick={() => {
                                        onExamTabClick("exam3");
                                    }}
                                >
                                    Exam3
                                </ExamButton>
                                <ExamButton
                                    active={examType == "combined"}
                                    onClick={() => {
                                        onExamTabClick("combined");
                                    }}
                                >
                                    Combined
                                </ExamButton>
                            </Col>
                            <Col span={4}>
                            </Col>
                        </OneLogoRow>
                        <Row style={{ marginTop: 20, paddingLeft: 12 }}>
                            <Col style={{ width: 25, paddingTop: 8 }}>
                                <OverviewIcon src={CologG} />
                            </Col>
                            <Col span={8}>
                                <span style={{ fontSize: 20, fontWeight: 600 }}>
                                    Overview
                                </span>
                            </Col>
                            <Col span={14} style={{ justifyContent: "flex-end", display: "flex" }}>
                                {EXAM_SCHOOLS.map(({ key, value }) => (
                                    <Tab
                                        bgColor={key == schoolType ? null : COLOR.orange100}
                                        active={key == schoolType}
                                        key={key}
                                        onClick={() => {
                                            onSchoolTypeChange(key);
                                        }}
                                    >
                                        {value}
                                    </Tab>
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            <TotalNStudentTxt>{`Total number \n of  students: ${resultData.grades.length}`}</TotalNStudentTxt>
                        </Row>
                        <GraphRow>
                            <Col span={4} style={{ alignItems: "flex-end", paddingTop: 0 }}>
                                <SearchBoxContainer>
                                    <SearchBox
                                        placeholder="Find your student"
                                        onChange={filterStudent}
                                    />
                                    <Search style={{ fontSize: 18 }} />
                                </SearchBoxContainer>
                                    {/* <SortingMenu grades={isCombined ? resultDataCombine.grades : resultData.grades} onFilter={filterStudentByClass} /> */}
                            </Col>
                            <Col span={20}>
                               {Charts}
                            </Col>
                        </GraphRow>
                        <div style={{ display: "flex", flex: 1 }}>
                            <ExamOverflowTwo>
                                <ExamGradeTable>
                                    <ExamGradeBoday>
                                        <Exam2TableHeader>
                                            <ExamTableSection1 style={{ width: 220 }}>
                                                <NameContent>Name</NameContent>
                                            </ExamTableSection1>
                                            <ExamTableSection2>
                                                <TopTabCont>
                                                    <TableTopBtnCont style={{ width: 140 }}>
                                                        <HeaderTabP style={{ fontSize: 12, width: "90%" }}>Placement</HeaderTabP>
                                                    </TableTopBtnCont>
                                                    {map(EXAM_LABELS, (key, index) => (<TableTopBtnCont key={key + index} style={{ width: getWidth(index) }}>
                                                        <TootipWrapper hasToolTip label={EXAM_LABELS_FULL[index]}>
                                                            <HeaderTab key={key} style={{ fontSize: 12, textOverflow: "ellipsis", width: "90%" }}>{key}</HeaderTab>
                                                        </TootipWrapper>
                                                    </TableTopBtnCont>
                                                    ))}
                                                </TopTabCont>
                                            </ExamTableSection2>
                                        </Exam2TableHeader>
                                    </ExamGradeBoday>
                                </ExamGradeTable>
                                {!isCombined?<ViewGradeTable 
                                filteredusers_response={filteredusers_response} 
                                question_length={resultData.question_length}  
                                />:<ViewGradeTableCombine 
                                filteredusers_response={filteredusers_response_combine} 
                                question_length={resultDataCombine.question_length} 
                                />}
                            </ExamOverflowTwo>
                        </div>
                    </ExamGradeSec>
                </div>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ExamTable.propTypes = {};

//  -- Default props ------------------
ExamTable.defaultProps = {};

export default ExamTable;
