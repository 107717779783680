//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { Col, Row } from 'antd';
import DemoModal from 'components/DemoModal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { get } from "lodash";
import { Edit, Delete } from "@material-ui/icons"
import { IconButton, Modal, Button } from "@material-ui/core"
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;

const Text = styled.span`
    color: rgb(0 0 0 / 85%);
    font-size: 20px;
`;

const TextContainer = styled.div`
    border-top: 1px solid ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 16px 16px;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;
    position:relative;
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;
const NewTag = styled.div`
    color:#fff;
    background-color:rgb(211,51,49);
    text-transform: uppercase;
    font-weight:800;
    font-weight: 800;
    width: 60px;
    position: absolute;
    top: -22px;
`;
const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`
const TopRow = styled(Row)`
    border: 0.5px solid #3c393957;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: flex-end;
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;

const CoversCardList = ({ data , onClick , onDelete  }) => {
    const [deleteToggle, setDeleteToggle] = useState(false)
    return (
        <>
          <TopRow>
            <IconButton onClick={() => setDeleteToggle(true)} style={{ padding: 0 }}>
                <Delete color="secondary" />
            </IconButton>
        </TopRow>
        <Modal open={deleteToggle} onClose={() => setDeleteToggle(false)}>
                <ModalContent>
                    <Text style={{ fontWeight: "bold", color: "black" }}>
                        <strong>{`Are you sure to delete this?`}</strong>
                    </Text>
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setDeleteToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                if (onDelete) {
                                    onDelete(data.id)
                                }
                                setDeleteToggle(false)
                            }}
                        >
                            Delete
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Wrapper style={{ opacity: 1 }}  >
                {get(data, "new", false) ? <NewTag>New</NewTag> : null}
                <div className="card-resource">
                    <a onClick={()=>onClick(data)}>
                        <div className="card-resource-overlay"></div>
                        <Image className="card-resource-image" src={data.thumbnail} alt={data.displayName} />
                        <div className="card-resource-details fadeIn-bottom">
                            <h3 className="card-resource-title">{data.displayName} <Edit/> </h3>
                        </div>
                        <div className="cstmVdoTitle">{data.displayName}  </div>
                    </a>
                </div>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
CoversCardList.propTypes = {
    data: shape({
        img: string,
        thumbnail: string,
        displayName: string,
    }),
    handleClick: func
};

//  -- Default props ------------------
CoversCardList.defaultProps = {
    data: {},
};

export default CoversCardList;