//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { lighten, invert } from "polished";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
//  -- Components ---------------------
import { Drawer, Col } from "antd";
import { Assignment, Delete, Extension, Edit, Share } from "@material-ui/icons";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import ActivityForm from "features/forms/Activity";
import QuizForm from "features/forms/Quiz";

//  -- Thunks -------------------------
import { handleCreateClassActivity } from "store/classActivities/thunks";
import { handleCreateClassQuiz } from "store/classQuizzes/thunks";
import { handleListClassActivities } from "store/classActivities/thunks";
import { handleListClassQuizzes } from "store/classQuizzes/thunks";
import { find, filter, map, get, omit, has } from "lodash";
import { validateQuizeActivityData } from "utils/methods/createQuizeActivityValidator";
import Schoology from "utils/Schoology";
import { useEffect } from "react";
import AssignmentForm from "features/forms/AssignmentForm";
import { IconButton } from "@material-ui/core";
import { DeleteModal } from "components/Dialog";
import {
  handleCreateCustomAssignment,
  handleDeleteCustomAssignment,
  handleListActivities,
  handleListCustomAssignment,
  handleUpdateCustomAssignment,
} from "store/activities/thunks";
import {
  useSchoologyAssignment,
  useSchoologyGradeCategory,
} from "store/schoologyauth/selectors";
import { useUserClasses } from "store/userClasses/selectors";
import { handleCreateGoogleAssignment } from "store/googleClassRoom/thunks";
import { handleCreateCanvasAssignment } from "store/canvas/thunks";
import { handleCreateNotification } from "store/notification/thunks";
import { useSocket } from "store/chat/selectors";
import AssignableNotificationShare from "./AssignableNotificationShare";
import { editIcons } from "utils/assets/images/icons";
import { useAllDistrictTeacher } from "store/users/selectors";
import SharedAndAssignedTask from "./SharedAndAssigned";
import { IsolationMode } from "utils/assets/images";
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Row = styled.div`
  align-items: ${(p) => p.align || "center"};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`;

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`;

const TextLarge = styled(Text)`
  // color: #3D5F8F;
  color: black;
  font-size: 13px;
  font-weight: 600;
  margin-right: 4px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

const Wrapper = styled.button`
padding-right: 25px;
  background-color: ${(p) =>
    p.bgColor ? p.bgColor : lighten(0.55, "#3D5F8F")};
  border: 2px dashed
    ${(p) =>
      p.borderColor
        ? lighten(0.2, invert(p.bgColor))
        : lighten(0.3, "#3D5F8F")};
  border-radius: 15px;
  color: #3d5f8f;
  cursor: ${(p) => (p.disabled ? "no-drop" : "pointer")};
  font-size: 14px;
  font-weight: 400;
  // margin-bottom: 16px;
  margin-bottom: 6px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;
  border-radius: 15px;
  position: relative;
  // margin-top: 22px;
  margin-top: 16px;
  position: relative;
  &:hover {
    background-color: ${(p) =>
      p.hoverColor ? p.hoverColor : lighten(0.5, "#3D5F8F")};
  }
`;
const BookDescRow = styled(Row)`
  font-size: 10px;
  padding-left: 30px;
  margin-top: -15px;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Announcement
//
//  -- Description
//  Announcement component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcement data={data} />
//
const AssignableNotification = ({
  assignment,
  listStudents,
  handleChangeMultiSelect,
  diffSection,
  setDiffSection,
  studentsName,
  currentClass,
  currentUser,
  booksAllList,
  onPreviewCick = () => null,
  onDeleteGetAssignment = () => null,
  schoologyAuth,
  disabled,
  chapter,
  allAssignable,
}) => {
  const dispatch = useDispatch();
  const { socketConnection, reCreateSocket } = useSocket();
  const [activityDrawer, toggleActivityDrawer] = useState(false);
  const [quizDrawer, toggleQuizDrawer] = useState(false);
  const [activityData, setActivityData] = useState({});
  const [quizData, setQuizData] = useState({});
  const [studentsError, setStudentsError] = React.useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [lateSection, setlateSection] = useState(false);
  const [durationSection, setDurationSection] = useState(false);
  const isReviewAccount = currentUser?.reviewAccount || false;
  const [openReviewDialog, setReviewDialog] = React.useState({
    open: false,
    message: "",
  });
  const bookid = assignment?.bookId ?? assignment?.chapter;
  const isAssignmentBook = find(
    booksAllList,
    (e) => e.id == bookid
  )?.quizAssessment;
  const colorCode = get(assignment, "colorCode");
  const bookDescription = get(assignment, "bookDescription");
  const assignmentColor = isAssignmentBook
    ? lighten(0.3, COLOR.orange100)
    : colorCode;
  const client = new Schoology(schoologyAuth);
  const [editCustomAssignmentData, setEditCustomAssignmentData] =
    useState(null);
  const [editCustomAssignmentModal, setEditCustomAssignmentModal] =
    useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [Assignloader, setAssignLoader] = useState(false);
  const [, listSchoologyAssignment] = useSchoologyAssignment();
  const userClasses = useUserClasses();
  const [schoologyGradeCategory] = useSchoologyGradeCategory();
  const allDistrictTeacher = useAllDistrictTeacher();
  const AssignmentShareList =
    assignment?.share_list?.length > 0 ? assignment?.share_list : [];
  const fetchShareBy =
    find(allDistrictTeacher, (obj) => obj.id === assignment?.share_by) ?? {};
  const sendNotifications = async (notificationData) => {
    try {
      const updateNotification = await dispatch(
        handleCreateNotification(notificationData)
      );
      const messageCreate = {
        ...updateNotification,
        senderId: get(currentUser, "id", ""),
        receiverId: "",
        classId: get(currentClass, "id", ""),
        code: "system_notification",
      };
      invokSend(messageCreate);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const invokSend = (data) => {
    let socket = socketConnection ?? "";
    if (socket) {
      try {
        if (socket.readyState == 3) {
          reCreateSocket();
          setTimeout(() => {
            socket.send(JSON.stringify(data));
          }, 2000);
        } else {
          socket.send(JSON.stringify(data));
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const createActivity = async (e) => {
    e.preventDefault();
    let studentsSelected = [];
    let actvityDataForCreate = {
      classId: currentClass.id,
      ...activityData,
      activityId: activityData.id,
      studentsSelected: studentsSelected,
    };
    const notificationData = {
      classId: get(currentClass, "id", ""),
      displayName: get(activityData, "displayName", ""),
      userId: get(currentUser, "id", ""),
      modified: new Date(),
      data: {
        chapter: get(activityData, "chapter", ""),
        page: get(activityData, "page", ""),
        id: get(activityData, "id", ""),
      },
      message: `Your teacher Create this Activity`,
      createdBy: get(currentClass, "id", ""),
      type: "create_activity",
      seen: true,
    };
    if (!actvityDataForCreate.bookId)
      actvityDataForCreate.bookId = activityData.chapter;
    let { isValid, reason } = validateQuizeActivityData(
      actvityDataForCreate,
      "activity"
    );
    if (!isValid) {
      setDataLoading(false);
      console.log(reason);
      return;
    }
    if (diffSection && studentsName.length == 0) {
      setStudentsError(true);
      setDataLoading(false);
      return;
    }
    setStudentsError(false);
    setDataLoading(true);
    actvityDataForCreate.studentsSelected =
      studentsName.length > 0 ? createArrayStudents(studentsName) : [];
    const sAssignmentData = await createAssignmetToSchoologyMultipleClass(
      actvityDataForCreate
    );
    const canvasData = await createAssignmetToCanvasMultipleClass(
      actvityDataForCreate
    );
    if (sAssignmentData && sAssignmentData.length > 0) {
      actvityDataForCreate.sAssignmentData = sAssignmentData;
    }
    const googleCreateAssignment = await createAssignmentsForGoogleClassRoom(
      actvityDataForCreate
    );
    if (googleCreateAssignment && googleCreateAssignment.length > 0) {
      actvityDataForCreate.googleClassRoomAssignment = googleCreateAssignment;
    }
    if (canvasData && canvasData.length > 0) {
      actvityDataForCreate.canvasData = canvasData;
    }
    await dispatch(handleCreateClassActivity(actvityDataForCreate));
    sendNotifications(notificationData);
    toggleActivityDrawer(false);
    setDataLoading(false);
    setActivityData({});
  };
  useEffect(() => {
    if (!quizDrawer && !activityDrawer) {
      setQuizData({});
      setActivityData({});
      setStudentsError(false);
      setDataLoading(false);
      setlateSection(false);
      setDurationSection(false);
      setDiffSection(false);
      handleChangeMultiSelect({ target: { value: [] } });
    }
  }, [quizDrawer, activityDrawer]);
  const createQuiz = async (e) => {
    e.preventDefault();
    let studentsSelected = [];
    let quizDataForCreate = {
      classId: currentClass.id,
      ...quizData,
      quizId: quizData.id,
      studentsSelected: studentsSelected,
    };
    const notificationData = {
      classId: get(currentClass, "id", ""),
      displayName: get(quizData, "displayName", ""),
      userId: get(currentUser, "id", ""),
      modified: new Date(),
      data: {
        chapter: get(quizData, "chapter", ""),
        page: get(quizData, "page", ""),
        id: get(quizData, "id", ""),
      },
      message: `Your teacher Create this Quiz`,
      createdBy: get(currentClass, "id", ""),
      type: "create_quiz",
      seen: true,
    };
    setDataLoading(true);
    if (!quizDataForCreate.bookId) quizDataForCreate.bookId = quizData.chapter;
    let { isValid, reason } = validateQuizeActivityData(
      quizDataForCreate,
      "quiz"
    );
    if (!isValid) {
      setDataLoading(false);
      console.log(reason);
      return;
    }
    quizDataForCreate.studentsSelected =
      studentsName.length > 0 ? createArrayStudents(studentsName) : [];
    const sAssignmentData = await createAssignmetToSchoologyMultipleClass(
      quizDataForCreate
    );
    const canvasData = await createAssignmetToCanvasMultipleClass(
      quizDataForCreate
    );
    if (sAssignmentData && sAssignmentData.length > 0) {
      quizDataForCreate.sAssignmentData = sAssignmentData;
    }
    const googleCreateAssignment = await createAssignmentsForGoogleClassRoom(
      quizDataForCreate
    );
    if (googleCreateAssignment && googleCreateAssignment.length > 0) {
      quizDataForCreate.googleClassRoomAssignment = googleCreateAssignment;
    }
    if (canvasData && canvasData.length > 0) {
      quizDataForCreate.canvasData = canvasData;
    }
    if (diffSection && studentsName.length == 0) {
      setStudentsError(true);
      setDataLoading(false);
      return;
    }
    setStudentsError(false);
    setDataLoading(true);
    await dispatch(handleCreateClassQuiz(quizDataForCreate));
    await dispatch(handleListClassQuizzes(currentClass.id));
    sendNotifications(notificationData);
    toggleQuizDrawer(false);
    setDataLoading(false);
    setQuizData({});
    setDataLoading(false);
  };
  const updateActivityData = (id, value) => {
    setActivityData({
      ...activityData,
      [id]: value,
    });
  };

  const updateQuizData = (id, value) => {
    setQuizData({
      ...quizData,
      [id]: value,
    });
  };

  function createArrayStudents(studentsName) {
    let arrayStudents = [];
    for (var s in studentsName) {
      let finded = listStudents.find((e) => e.description == studentsName[s]);
      if (finded) {
        arrayStudents.push({
          studentId: finded.id,
          description: finded.description,
        });
      }
    }
    return arrayStudents;
  }
  const handleClose = () => {
    setReviewDialog({ open: false, message: "" });
  };
  const handleClick = async () => {
    if (disabled && (chapter == 14 || chapter == 13)) {
      setReviewDialog({
        open: true,
        message: `Please assign an assignment from ${
          chapter == "13" ? "Anécdotas 1" : "Anécdotas 2"
        } `,
      });
      return;
    }
    if (isReviewAccount) {
      setReviewDialog({ open: true, message: "Only for Review!" });
      return;
    }
    if (
      assignment?.createdBy !== currentUser?.id &&
      AssignmentShareList?.includes(currentUser?.id) &&
      assignment?.type === "custom"
    ) {
      const { id, ...remainingAssignment } = assignment;
      const data = {
        ...remainingAssignment,
        createdBy: currentUser?.id,
        share_list: [],
        cloneId: assignment?.id,
      };
      setAssignLoader(true);
      const updatedData = await dispatch(handleCreateCustomAssignment(data));
      setAssignLoader(false);
      if (updatedData?.typeId === "activity") {
        toggleActivityDrawer(true);
        setActivityData(updatedData);
      } else {
        toggleQuizDrawer(true);
        setQuizData(updatedData);
      }
    } else {
      if (assignment?.typeId === "activity") {
        toggleActivityDrawer(true);
        setActivityData(assignment);
      } else {
        toggleQuizDrawer(true);
        setQuizData(assignment);
      }
    }
  };
  const createAssignmentsForGoogleClassRoom = async (assignment) => {
    if (currentUser.googleId) {
      return Promise.all(
        map(assignment.classes, async (cls) => {
          const classInfo = find(userClasses, ["id", cls.id]);
          if (classInfo && classInfo.googleId) {
            const assignmentData =
              currentClass.id === cls.id
                ? assignment
                : { ...assignment, dueDate: cls.dueDate };
            const createdAssignment = await handleCreateGoogleAssignment(
              currentUser.googleId,
              assignmentData,
              classInfo.googleId
            );
            return {
              classId: classInfo.id,
              googleAssignmentId: get(createdAssignment, "id"),
            };
          } else {
            return null;
          }
        })
      ).then((v) => v.filter((k) => k));
    } else {
      return [];
    }
  };
  const createAssignmetToSchoologyMultipleClass = async (
    quizeDataforCreate
  ) => {
    const hasClass = filter(quizeDataforCreate?.classes, (e) => e?.sectionId);
    const sAssignmentData = [];
    if (hasClass.length > 0) {
      await Promise.allSettled(
        map(hasClass, async (item) => {
          const schoologyCategory = item.schoologyCategory
            ? item.schoologyCategory
            : quizeDataforCreate.schoologyCategory;
          let s_id = await createAssignmentToSchoology(
            { ...quizeDataforCreate, schoologyCategory },
            item.sectionId
          );
          const schoologyCategoryData = find(
            schoologyGradeCategory,
            (k) => k.id == schoologyCategory
          );
          const isWightageEnabled = find(
            schoologyGradeCategory,
            (k) => k.weight > 0
          );
          let max_point = 100;
          let grade_type = "percent";
          let weightage = 100;
          if (schoologyCategoryData) {
            if (schoologyCategoryData.calculation_type == 2) {
              grade_type = "points";
            }
            if (has(schoologyCategoryData, "default_max_points")) {
              max_point = schoologyCategoryData.default_max_points;
            }
            if (isWightageEnabled) {
              weightage = get(schoologyCategoryData, "weight") || 100;
            }
          }
          if (s_id) {
            sAssignmentData.push({
              classId: item.id,
              sAssignmentId: s_id.id,
              max_point,
              grade_type,
              weightage,
            });
          }
        })
      );
    } else {
      let s_assignment = await createAssignmentToSchoology(
        quizeDataforCreate,
        currentClass.sectionId
      );
      if (s_assignment) {
        let max_point = 100;
        let grade_type = "percent";
        let weightage = 100;
        sAssignmentData.push({
          classId: currentClass.id,
          sAssignmentId: s_assignment.id,
          max_point,
          grade_type,
          weightage,
        });
      }
    }
    return sAssignmentData;
  };
  const createAssignmetToCanvasMultipleClass = async (quizeDataforCreate) => {
    if (!get(currentUser, "canvas_id") || !quizeDataforCreate) return;
    const hasClass = map(quizeDataforCreate?.classes, (e) => {
      const dueDate =
        e.id === currentClass.id ? quizeDataforCreate.dueDate : e.dueDate;
      return {
        ...e,
        due_date: dueDate,
        weightage: quizeDataforCreate.weightage
          ? quizeDataforCreate.weightage
          : "100",
        displayName: quizeDataforCreate.displayName,
        canvas_section_id: get(find(userClasses, ["id", e.id]), "canvas_id"),
        canvas_course_id: get(
          find(userClasses, ["id", e.id]),
          "canvas_course_id"
        ),
        grade_type: quizeDataforCreate.grade_type,
        max_point: quizeDataforCreate.max_point,
        final_grade_exclude: quizeDataforCreate.final_grade_exclude,
      };
    });
    const createdAssignment = await handleCreateCanvasAssignment(
      currentUser.canvas_id,
      hasClass,
      currentUser.canvas_auth_id
    );
    const result = map(createdAssignment, (q) => {
      if (q && q.canvas_course_id) {
        const classInfo = find(
          userClasses,
          (uc) => uc.canvas_id == q.canvas_section_id
        );
        return {
          canvas_section_id: q.canvas_section_id,
          canvas_course_id: q.canvas_course_id,
          canvas_assignment_id: q.id,
          classId: get(classInfo, "id"),
        };
      }
      return null;
    }).filter((q) => q);
    return result;
  };
  const createAssignmentToSchoology = async (quizeDataforCreate, sectionId) => {
    let hasClass = filter(quizeDataforCreate?.classes, (e) => e?.sectionId);
    let toBeUnassigned = filter(hasClass, (e) => e?.sectionId !== sectionId);

    if (currentUser.schoologyid && sectionId) {
      let grading_category = get(quizeDataforCreate, "schoologyCategory");
      let weightage = quizeDataforCreate.weightage;
      if (!weightage || isNaN(parseInt(weightage))) {
        weightage = 100;
      }
      client.setToken(schoologyAuth);
      let data = {
        title: quizeDataforCreate.displayName,
        description: quizeDataforCreate.displayName,
        due: `${quizeDataforCreate.dueDate}`,
        type: "assignment",
        assignees: [`${currentUser.schoologyid}`],
        grading_group_ids: [],
        grading_category: grading_category,
      };
      const createAssignment = await client.createAssignment(sectionId, data);
      listSchoologyAssignment(sectionId);
      map(toBeUnassigned, (item) => {
        client.unassignAssignment(item.sectionId, createAssignment.id);
      });
      return createAssignment;
    }
    return null;
  };
  const onCloseDrawer = () => {
    toggleQuizDrawer(false);
    toggleActivityDrawer(false);
    setQuizData({});
    setActivityData({});
    setStudentsError(false);
    setDataLoading(false);
    setlateSection(false);
    setDurationSection(false);
    setDiffSection(false);
    handleChangeMultiSelect({ target: { value: [] } });
  };
  const deleteCustomAssignment = async () => {
    setDeleteLoading(true);
    const filterCloneData = allAssignable?.find(
      (obj) => obj?.id === get(editCustomAssignmentData, "cloneId", "") || ""
    );
    const CloneAssignmentShareList =
      filterCloneData?.share_list?.length > 0
        ? filterCloneData?.share_list
        : [];
    await dispatch(
      handleDeleteCustomAssignment(get(editCustomAssignmentData, "id"))
    );
    if (
      filterCloneData &&
      filterCloneData?.createdBy !== currentUser?.id &&
      CloneAssignmentShareList?.includes(currentUser?.id) &&
      filterCloneData?.type === "custom"
    ) {
      const deleteData = {
        ...filterCloneData,
        share_list: filterCloneData?.share_list?.filter(
          (obj) => !obj?.includes(currentUser?.id)
        ),
      };
      await dispatch(handleUpdateCustomAssignment(deleteData, "deleteClone"));
    }
    setDeleteLoading(false);
    setDeleteModal(false);
    setEditCustomAssignmentData({});
  };
  const onEditAssignment = async () => {
    if (
      assignment?.createdBy !== currentUser?.id &&
      AssignmentShareList?.includes(currentUser?.id) &&
      assignment?.type === "custom"
    ) {
      const { id, ...remainingAssignment } = assignment;
      const data = {
        ...remainingAssignment,
        createdBy: currentUser?.id,
        share_list: [],
        cloneId: assignment?.id,
      };
      const updatedData = await dispatch(handleCreateCustomAssignment(data));
      setEditCustomAssignmentModal(true);
      const assignmentData =
        get(updatedData, "type") === "custom"
          ? updatedData
          : omit(updatedData, "id");
      if (get(updatedData, "type") !== "custom") {
        assignmentData.cloneId = get(updatedData, "id");
      }
      setEditCustomAssignmentData(assignmentData);
    } else {
      setEditCustomAssignmentModal(true);
      const assignmentData =
        get(assignment, "type") === "custom"
          ? assignment
          : omit(assignment, "id");
      if (get(assignment, "type") !== "custom") {
        assignmentData.cloneId = get(assignment, "id");
      }
      setEditCustomAssignmentData(assignmentData);
    }
  };
  const isDisabled = false;
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openReviewDialog.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          <strong>ALERT:</strong> {openReviewDialog.message}
        </Alert>
      </Snackbar>
      <DeleteModal
        message={`Are you sure to delete , You will loss all submissions and grades for this assignment?`}
        deleteToggle={deleteModal}
        setDeleteToggle={setDeleteModal}
        loading={deleteLoading}
        onDelete={deleteCustomAssignment}
      />
      <AssignmentForm
        bookId={get(editCustomAssignmentData, "chapter")}
        pageId={get(editCustomAssignmentData, "page")}
        open={editCustomAssignmentModal}
        data={editCustomAssignmentData}
        onClose={() => {
          setEditCustomAssignmentModal(false);
          setEditCustomAssignmentData({});
        }}
      />
      <Drawer
        height="100%"
        destroyOnClose
        onClose={onCloseDrawer}
        placement="top"
        open={quizDrawer}
        key="quizNew"
        className="assignments-drawer"
      >
        <>
          <QuizForm
            data={quizData}
            handleChange={(e) => updateQuizData(e.target.id, e.target.value)}
            handleChangeLevel={(e) =>
              updateQuizData("levelExercise", e.target.value)
            }
            handleSubmit={createQuiz}
            listStudents={listStudents}
            handleChangeMultiSelect={handleChangeMultiSelect}
            diffSection={diffSection}
            setDiffSection={setDiffSection}
            studentsName={studentsName}
            studentsError={studentsError}
            lateSection={lateSection}
            setlateSection={setlateSection}
            setDurationSection={setDurationSection}
            durationSection={durationSection}
            loading={dataLoading}
            onClose={() => toggleQuizDrawer(false)}
            booksAllList={booksAllList}
          />
        </>
      </Drawer>
      <Drawer
        height="100%"
        destroyOnClose
        onClose={onCloseDrawer}
        placement="top"
        open={activityDrawer}
        key="activityNew"
        className="assignments-drawer"
      >
        <>
          <ActivityForm
            data={activityData}
            handleChange={(e) =>
              updateActivityData(e.target.id, e.target.value)
            }
            handleSubmit={createActivity}
            listStudents={listStudents}
            handleChangeMultiSelect={handleChangeMultiSelect}
            diffSection={diffSection}
            setDiffSection={setDiffSection}
            studentsName={studentsName}
            studentsError={studentsError}
            setDurationSection={setDurationSection}
            durationSection={durationSection}
            lateSection={lateSection}
            setlateSection={setlateSection}
            onClose={() => toggleActivityDrawer(false)}
            booksAllList={booksAllList}
          />
        </>
      </Drawer>
      {/* <Wrapper
                bgColor={assignmentColor}
                borderColor={null}
                hoverColor={assignmentColor}
            >
                <Row align="flex-start">
                    <Col span={20} style={{ display: "flex", flexDirection: "row", alignItems: 'center',marginBottom:20,marginTop:5 }}>
                        {assignment?.typeId === 'activity'
                            ? <img style={{ width: 24, marginRight: 4 }} id='debade_icon' src={editIcons} alt="Student activity" title='Student activity' />
                            // <Extension style={{ height: '18px', marginRight: '4px' }} />
                            : <Assignment style={{ height: '18px', marginRight: '4px' }} />
                        }
                        <Title style={{color:'black',fontWeight:'500'}}>{get(assignment, "displayName", "")}</Title>
                    </Col>
                    <Col span={4} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        {get(assignment, "type") === "custom" && (
                            <>
                                
                                <IconButton color='error'
                                    style={{ padding: 0 }}
                                    onClick={() => {
                                        setDeleteModal(true)
                                        setEditCustomAssignmentData(assignment)
                                    }}>
                                    <Delete color="error" />
                                </IconButton>
                            </>
                        )}
                        <IconButton color='primary'
                            style={{ padding: 0 }}
                            onClick={onEditAssignment}>
                            <Edit style={{color:'black'}} />
                            
                        </IconButton>
                    </Col>
                </Row>
                {bookDescription ? <BookDescRow><strong>Book</strong>: {bookDescription}</BookDescRow> : null}
                <Row style={{ flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', marginBottom: 8 }}>
                    <Column style={{ flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', background: '#0d2e6d', padding: '1.5px 10px 1.5px 10px', borderRadius: 7,marginRight:12 }}>
                        <Text style={{ color: 'white',fontSize:12 }}>Status: <strong style={{ color: 'white' }}>Unassigned</strong></Text>
                    </Column>
                    <TextLarge style={{ display: 'flex', alignItems: 'center',marginRight:12 }} onClick={onPreviewCick}>
                        Preview</TextLarge>
                    <TextLarge style={{ display: 'flex', alignItems: 'center',marginRight:12 }} onClick={handleClick}>
                        Assign Now</TextLarge>
                </Row>
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',marginBottom:1 }}>
                    <TextLarge style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} onClick={handleClick}>
                        Share with other teachers
                    </TextLarge>
                </Row>
                <Row style={{ position: 'absolute', top: -16,left:0 }}>
                    <TextLarge style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color: 'white', backgroundColor: '#166c17',padding:'1.5px 10px',borderRadius:7 }}>
                        Shared by iris cordova
                    </TextLarge>
                </Row>
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 1 }}>
                    <TextLarge  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} onClick={handleClick}>
                        {assignment?.createdBy === currentUser?.id && <AssignableNotificationShare assignment={assignment} />}
                    </TextLarge>
                </Row>
                <Row style={{ position: 'absolute', top: -16, left: 0 }}>
                    <TextLarge style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color: 'white', backgroundColor: '#166c17', padding: '1.5px 10px', borderRadius: 7 }}>
                        Shared by iris cordova
                    </TextLarge>
                </Row>
            </Wrapper> */}

      <Wrapper
        style={{paddingRight: "25px", paddingLeft: "13px"}}
        bgColor={assignmentColor}
        borderColor={null}
        hoverColor={assignmentColor}
      >
        {assignment?.share_by !== currentUser?.id &&
          Object.keys(fetchShareBy).length > 0 && (
            <SharedAndAssignedTask sharedBy={`${fetchShareBy?.firstName} ${fetchShareBy?.lastName}`} isAssignedActive={false} isSharedActive={true} />
            // <Row align="flex-start">
            //   <TextLarge
            //     style={{
            //       display: "flex",
            //       alignItems: "center",
            //       justifyContent: "flex-end",
            //       color: "white",
            //       backgroundColor: "#166c17",
            //       padding: "1.5px 10px",
            //       borderRadius: 7,
            //     }}
            //   >
            //     {`Shared by ${fetchShareBy?.firstName} ${fetchShareBy?.lastName}`}
            //   </TextLarge>
            // </Row>
          )}
        <Row align="center" style={{justifyContent: "space-between"}}>
          <Col
            span={20}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 20,
              marginTop: 5,
            }}
          >
            {assignment?.typeId === "activity" ? (
              <img
                style={{ width: 12, marginRight: 9 }}
                id="debade_icon"
                src={IsolationMode}
                alt="Student activity"
                title="Student activity"
              />
            ) : (
              // <img  style={{ height: "18px", marginRight: "4px" }} src={UpdatedAssignableImg} alt="" />
              // <Extension style={{ height: '18px', marginRight: '4px' }} />
              <Assignment style={{ height: "18px", marginRight: "4px" }} />
            )}
            <Title className="assigned-task-name">
              {get(assignment, "displayName", "")}
            </Title>
          </Col>
          <Row
            // span={4}
            style={{
            //   display: "flex",
            //   flexDirection: "row",
            //   justifyContent: "space-between",
            }}
          >
            {get(assignment, "type") === "custom" ? (
              <IconButton
                color="error"
                style={{ padding: 0, marginRight: "7px" }}
                onClick={() => {
                  setDeleteModal(true);
                  setEditCustomAssignmentData(assignment);
                }}
              >
                <Delete color="error" />
              </IconButton>
            ) : (
              <div></div>
            )}
            <IconButton
              color="primary"
              style={{ padding: 0 }}
              onClick={onEditAssignment}
            >
              <Edit style={{ color: "black" }} />
              {/* <img style={{ width: 24, marginRight: 4 }} id='debade_icon' src={pencileIcons} alt="Student activity" title='Student activity' /> */}
            </IconButton>
          </Row>
        </Row>
        {bookDescription ? (
          <BookDescRow>
            <strong>Book</strong>: {bookDescription}
          </BookDescRow>
        ) : null}

        <Row
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: 8,
           
          }}
        >
          <Column
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: 0
         
              //   background: "#0d2e6d",
            //   padding: "1.5px 10px 1.5px 10px",
              //   borderRadius: 7,
            }}
          >
            {/* <img style={{ width: 24, marginRight: 4 }} id='debade_icon' src={docIcons} alt="Student activity" title='Student activity' /> */}
            <Text  className="assignable-status-text">
              Status: <strong className="assignable-value">Unassigned</strong>
            </Text>
          </Column>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
       
          }}
        >
          {assignment?.createdBy === currentUser?.id && (
            <AssignableNotificationShare assignment={assignment} />
          )}
          
          <TextLarge
           className="preview-edit-text"
            style={{ display: "flex", alignItems: "center" }}
            onClick={onPreviewCick}
          >
            {/* <img style={{ width: 35 }} id='debade_icon' src={previewIcons} alt="Student activity" title='Student activity' /> */}
             Preview | 
          </TextLarge>
          <TextLarge
          className="preview-edit-text"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              if (Assignloader) return
              handleClick()
            }}
          >
            {/* <img style={{ width: 24, marginRight: 4 }} id='debade_icon' src={editIcons} alt="Student activity" title='Student activity' /> */}
            &nbsp;Assign Now
          </TextLarge>
        </Row>
      </Wrapper>
    </>
  );
};

//  -- Prop types ---------------------
AssignableNotification.propTypes = {};

//  -- Default props ------------------
AssignableNotification.defaultProps = {
  fontSize: 10,
};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentClass: state.currentClass,
  booksAllList: state.booksAllList,
  currentUser: state.currentUser,
  schoologyAuth: state.schoologyAuth,
});

export default connect(mapStateToProps, null)(AssignableNotification);
