import 'animate.css';
import { Card, Col as Cols, List, Row as Rows, message } from "antd";
import Chat from "containers/chat/popup";
import DebateModal from 'features/OneWayDebateModal';
import IdeaModal from 'features/ideaModal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { invokeTrafficView } from "store/wheel/actions";
import { useNoiseMeter } from 'store/wheel/selectors';
import styled from 'styled-components';
import { CheckListImg, ClocksImg, DownArrowImg, birdLogoImg, newChats, newDebates, newIdeas } from "utils/assets/images";
import Expressions from '../utils/assets/images/Expressions.png';
import Image from '../utils/assets/images/Image.webp';
import NewGroup from '../utils/assets/images/New-Group.webp';
import NewStation from '../utils/assets/images/New-Station.webp';
import NoiseMeter from '../utils/assets/images/Noise-Meter.webp';
import Text from '../utils/assets/images/Text.webp';
import Timer from '../utils/assets/images/Timer.webp';
import TrafficSignal from '../utils/assets/images/Traffic Signal.webp';
import WheelImg1 from '../utils/assets/images/Wheel.webp';
import { useMobileDetect } from 'containers/chat/hooks';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const data = [
  {
    id: 1,
    title: 'Wheel',
    icons: WheelImg1,
    key: 'widget_wheel'
  },
  {
    id: 2,
    title: 'Noise Meter',
    icons: NoiseMeter,
    key: 'noise_meter'
  },
  {
    id: 3,
    title: 'Image',
    icons: Image,
    key: 'image'
  },
  {
    id: 4,
    title: 'Text',
    icons: Text,
    key: 'widget_text'
  },
  {
    id: 5,
    title: 'Traffic Signal',
    icons: TrafficSignal,
    key: 'traffic_signal'
  },
  {
    id: 6,
    title: 'Check List',
    icons: CheckListImg,
    key: 'widget_checklist'
  },
  {
    id: 7,
    title: 'Timer',
    icons: Timer,
    key: 'widget_timer'
  },
  {
    id: 8,
    title: 'Clock',
    icons: ClocksImg,
    key: 'widget_clock'
  },
  {
    id: 9,
    title: 'Expressions',
    icons: Expressions,
    key: 'widget_expressions'
  },
  {
    id: 10,
    title: 'New Station',
    icons: NewStation,
    key: 'new_station'
  },
  {
    title: 'New Group',
    icons: NewGroup,
    key: 'new_group'
  },
  {
    title: '1Idea',
    icons: newIdeas,
    key: 'idea_withoneway',
    Btn: <IdeaModal />
  },
  {
    title: '1Debate',
    icons: newDebates,
    key: 'one_debate',
    Btn: <DebateModal />
  },
  {
    title: 'Chat',
    icons: newChats,
    key: 'chat',
    Btn: <Chat />
  },
];

const dataOnTab = [
    {
      id: 1,
      title: 'Wheel',
      icons: WheelImg1,
      key: 'widget_wheel'
    },
    {
      id: 2,
      title: 'Noise Meter',
      icons: NoiseMeter,
      key: 'noise_meter'
    },
    {
      id: 3,
      title: 'Image',
      icons: Image,
      key: 'image'
    },
    {
      id: 4,
      title: 'Text',
      icons: Text,
      key: 'widget_text'
    },
    {
      id: 5,
      title: 'Traffic Signal',
      icons: TrafficSignal,
      key: 'traffic_signal'
    },
    {
      id: 6,
      title: 'Check List',
      icons: CheckListImg,
      key: 'widget_checklist'
    },
    {
      id: 7,
      title: 'Timer',
      icons: Timer,
      key: 'widget_timer'
    },
    {
      id: 8,
      title: 'Clock',
      icons: ClocksImg,
      key: 'widget_clock'
    },
    {
      id: 9,
      title: 'Expressions',
      icons: Expressions,
      key: 'widget_expressions'
    },
    {
      id: 10,
      title: 'New Station',
      icons: NewStation,
      key: 'new_station'
    },
    {
      title: 'New Group',
      icons: NewGroup,
      key: 'new_group'
    }
  ];

const BirdLogo = styled.img`
  height: 60px;
  margin-left: -10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
//   opacity: 0;
`;

const DownArrow = styled.img`
  height: 13px;
  transition: transform 0.3s ease;
`;

// Wrapper to handle hover state
const HoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
//   padding: 13px 26px;
    max-height: 110px;
    width: 45px;
    border-radius: 23px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    // bottom: 6px;
    // right: 2px;  

// background-color: white;
//   border-radius: 30px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   padding: 13px 26px;
//   min-height: 110px;
//   justify-content: space-between;
//   position: relative;
//   bottom: 6px;
//   right: -20px;
  

  &:hover ${BirdLogo} {
    transform: translateY(-10px);
    // opacity: 1;
    
  }
`;


const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: transparent;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100vh !important;
    // background-color: rgb(58,58,58);
    position: relative;
    justify-content: center;
    // padding-left: 20px;
    // padding-right: 20px;
    padding-top: 0px;

`;

const Row = styled.div`
    align-items: center;
    justify-content:center;
    display: flex;
    flex-direction: row;
    @media (max-width: 997px) {
         flex-direction: column;
         padding: 0px 30px;
   }
`;
const WheelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: transparent;
    z-index: 11;
    transition: all 1s ease 0s;
    cursor: pointer;
    // @media (max-width: 600px) {
    //     display:none;
    // }
`;

export default function WidgetsMenu({
  sharableWidget,
  tabletMode,
  widgetOpened,
  setIsWidgetOpened,
}) {
  const isMobile = useMobileDetect();
  const tabletWidget = widgetOpened;
  const dispatch = useDispatch();
  const [widgetsMenuModal, setWidgetsMenuModal] = useState(false)
  const [, invokeNoiseMeter] = useNoiseMeter()
  const [showWrapper, setShowWrapper] = useState(false);
  const [showOutAnimation, setShowOutAnimation] = useState(false);
  const [toast, setToast] = useState({
    value: false,
    message: ""
  });

  const onWheelWrapperClick = () => {
    if (isMobile) {
      setToast({
        value: true, message: "Widget is not supported on mobile"
      })
      return;
    }
    setShowOutAnimation(true);
    setShowWrapper(true);
  };
  const handleCloseWidgets = () => {
    setShowOutAnimation(false);
    setTimeout(() => {
      setShowWrapper(false);
    }, 1000)
  }

  const onCloseWidgetsModal = () => {
    setWidgetsMenuModal(false)
  }

  const onWidgetClick = async (item) => {
    const widgetData = {
      id: item.id,
      view: true,
      menu: item.key
    }
    if (item?.key === 'traffic_signal') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'widget_clock') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'noise_meter') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'image') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'widget_timer') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'widget_text') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'widget_wheel') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'widget_expressions') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'widget_checklist') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'new_station') {
      await dispatch(invokeTrafficView(widgetData));
    } else if (item?.key === 'new_group') {
      await dispatch(invokeTrafficView(widgetData));
    }
  }


  const TabletWidgetWrapper = () => {
    return (
      <Wrapper
        className="spinWheelWrap"
        onClick={() => {
          setIsWidgetOpened(false);
        }}
      >
        <Container className="spinWheelCntnr">
          <div
            style={{
              width: "90%",
              position: "fixed",
              bottom: 127,
              transform: "translate(-50 %, -50 %)",
              right: "4%",
              bottom: "6px",
            }}
            className={`allWidgetsContainer animate__animated`}
          >
            <Rows gutter={30} style={{ width: "100%" }}>
              <Cols sm={24} lg={24}>
                <div
                  className="widgetsMenuContainer"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 55,
                    width: "100%",
                  }}
                >
                  <div className="allWidgetsSection">
                    <img
                      src={birdLogoImg}
                      style={{ height: 60, marginLeft: -10 }}
                    />
                    <div className="widgetsMidSEction">
                      <List
                        style={{
                          padding: "10px 20px",
                          overflowX: "auto",
                          flexWrap: "wrap",
                        }}
                        grid={{
                          gutter: 5,
                          column: 10,
                        }}
                        dataSource={dataOnTab}
                        renderItem={(item) => {
                          const isMatched = sharableWidget.some(sharedItem => sharedItem.widget_type === item.key);
                          const titleStyle = {
                            color: isMatched ? 'green' : 'black',
                            fontWeight: isMatched ? 'bold' : ''
                          };
                          return (
                          <List.Item
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              onWidgetClick(item);
                            }}
                          >
                            <Card title={<span style={titleStyle}>{item.title}</span>}>
                              {item?.Btn ? (
                                item?.Btn
                              ) : (
                                <img height="30px" src={item?.icons} />
                              )}
                            </Card>
                          </List.Item>
                        )}}
                      />
                    </div>
                    <img
                      src={DownArrowImg}
                      className="checkListCursor"
                      style={{ height: 13 }}
                      onClick={() => handleCloseWidgets()}
                    />
                  </div>
                </div>
              </Cols>
            </Rows>
          </div>
        </Container>
      </Wrapper>
    );
  };

  const handleClose = () => {
    setToast({ open: false, message: "" });

  }

  return (<>
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={toast?.value}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error">
        <strong>ALERT:</strong> {toast.message}
      </Alert>
    </Snackbar>
    {widgetOpened && <TabletWidgetWrapper />}
    {!showWrapper ? (
      <WheelWrapper
        style={{ display: tabletMode && "none" }}
        onClick={() => onWheelWrapperClick()}
        className='wheelSpinImg wheel-spin-image'>
        {/* <WheelImg src={wheel_icon} /> */}
        <Cols sm={24} lg={2} style={{ alignItems: 'center' }}>
          <div style={{
            backgroundColor: 'white', borderRadius: 30, cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '13px 26px',
            minHeight: 110,
            justifyContent: 'space-between',
            position: 'relative',
            bottom: 6,
            right: -5,
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
          }} onClick={onCloseWidgetsModal} className="bird-container tabs-widgets">
            <HoverWrapper>
              <BirdLogo className='bird-logo-widget' src={birdLogoImg} />
              <DownArrow className='down-arrow-widget' src={DownArrowImg} />
            </HoverWrapper>
          </div>
        </Cols>
      </WheelWrapper>
    ) : (
      <Wrapper className='spinWheelWrap' onClick={() => setWidgetsMenuModal(true)}>
        <Container className='spinWheelCntnr'>
          <div style={{ width: '90%', position: 'fixed', bottom: 127, transform: 'translate(-50 %, -50 %)', right: '4%', bottom: '6px' }} className={`allWidgetsContainer animate__animated ${showOutAnimation ? 'animate__bounceInUp' : 'animate__bounceOutDown'}`}>
            <Rows gutter={30} style={{ width: '100%' }}>
              <Cols sm={24} lg={24}>
                <div className='widgetsMenuContainer' style={{ backgroundColor: 'white', borderRadius: 55, width: '100%', }}>
                  <div className='allWidgetsSection'>
                    <img src={birdLogoImg} style={{ height: 60, marginLeft: -10 }} />
                    <div className='widgetsMidSEction'>
                      <List
                        style={{ padding: '10px 20px', overflowX: 'auto', flexWrap: 'wrap' }}
                        grid={{
                          gutter: 5,
                          column: 10,
                        }}
                        dataSource={data}
                        renderItem={(item) => {
                          const isMatched = sharableWidget.some(sharedItem => sharedItem.widget_type === item.key);
                          const titleStyle = {
                            color: isMatched ? 'green' : 'black',
                            fontWeight: isMatched ? 'bold' : ''
                          };
                          return (
                          <List.Item style={{ cursor: 'pointer' }} onClick={() => {
                            onWidgetClick(item)
                          }}>
                            <Card title={<span style={titleStyle}>{item.title}</span>}>{item?.Btn ? item?.Btn : <img height="30px" src={item?.icons} />}</Card>
                          </List.Item>
                        )}}
                      />
                    </div>
                    <img src={DownArrowImg} className="checkListCursor" style={{ height: 13 }} onClick={() => handleCloseWidgets()} />
                  </div>
                </div>
              </Cols>
            </Rows>
          </div>
        </Container>
      </Wrapper>
    )}
  </>)
}
