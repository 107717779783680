//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import { API } from 'aws-amplify';
import Loading from 'components/Loading';
import _,{ filter } from "lodash";
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleUpdateOrganization, getOrganizationById } from 'store/organizations/thunks';
//  -- Thunks -------------------------
import { handleCheckUsername, handleCreateUser, handleUpdateUser } from 'store/users/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { useCurrentOrgPermission } from 'store/organizations/selectors';




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
    margin: 0px auto;
    max-width: 480px;
    width: 100%;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    }, 
    getContentAnchorEl: () => null,

};

function getStyles(name, booksName, theme) {
    return {
        fontWeight:
            booksName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Class form
//
//  -- Description
//  ...
//
//  -- Props
//  ...
//
const filterAssignableBook = (_userBooksList) => {
    return filter(_userBooksList, e => e.isNotAssignable !== "true")
}
const UserForm = ({
    userData,
    editing,
    view,
    onClose,
    schools,
    listBooks
}) => {
    //console.log(userData);
    //console.log(booksByUser);
    //console.log(schools);
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = _.get(orgPermissions, "books", [])
    const assignableBooks = _.filter(filterAssignableBook(listBooks), k => {
        if (bookPermissions.length == 0) {
            return true;
        }
        const bookaccess = _.find(bookPermissions, b=>b.bookId==k.id||b.bookId==k.bookId)
        if (bookaccess) {
            return bookaccess.available
        } else {
            return true;
        }
    })
    let booksByUser = [];
    let showBooksInitial = false;
    if (editing) {
        if (userData.books.length > 0) {
            let booksU = userData.books;
            for (let b in booksU) {
                let finded = listBooks.find(e => e.id == booksU[b].bookId);
                if (finded != undefined) {
                    if (finded.description != undefined) {
                        booksByUser.push(finded.description)
                    }
                }
            }
        }
        if (userData.role != 'admin' && userData.role != 'owe_admin') {
            showBooksInitial = true;
        }
    }
    assignableBooks.sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
    const [showBooks, setShowBooks] = useState(showBooksInitial);
    const dispatch = useDispatch();
    const [data, setData] = useState(editing ? userData : {});
    const [loading, setLoading] = useState(false);
    let roles = [];
    if (view == 'district') {
        roles = [
            {
                label: 'Director',
                value: 'director'
            },
            {
                label: 'Teacher',
                value: 'teacher'
            },
            {
                label: 'Student',
                value: 'student'
            }
        ]
    } else if (view == 'director') {
        roles = [
            {
                label: 'Teacher',
                value: 'teacher'
            },
            {
                label: 'Student',
                value: 'student'
            }
        ]
    } else {
        roles = [
            {
                label: 'Owe Admin',
                value: 'owe_admin'
            },
            {
                label: 'District Admin',
                value: 'admin'
            },
            {
                label: 'Director',
                value: 'director'
            },
            {
                label: 'Teacher',
                value: 'teacher'
            },
            {
                label: 'Student',
                value: 'student'
            },
        ]
    }

    const classes = useStyles();
    const theme = useTheme();
    const [booksName, setBooksName] = React.useState(booksByUser);

    const [roleError, setRoleError] = useState(false);
    const [schoolError, setSchoolError] = useState(false);
    const [userError, setUserError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [formatPasswordError, setFormatPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [gradeError, setGradeError] = useState(false);
    const [booksError, setBooksError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [noLicensesError, setNoLicensesError] = useState(false);
    const [createUserError,setCreateUserError] = useState(null)
    const [typeOrg, setTypeOrg] = useState('NoSet');

    const handleChange = e => {
        if (e.target.id == 'firstName') {
            setFirstNameError(false);
        }
        if (e.target.id == 'lastName') {
            setLastNameError(false);
        }
        if (e.target.id == 'grade') {
            setGradeError(false);
        }
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    };

    const handleSelect = (id, value) => {
        if (id == 'role') {
            if (value != 'admin' && value != 'owe_admin') {
                setShowBooks(true);
            } else {
                setShowBooks(false);
            }
            setRoleError(false);

            if (value == 'student' || value == 'teacher' || value == 'director') {
                setTypeOrg('school');
            }
            else {
                setTypeOrg('district');
            }
        }


        if (id == 'orgId') {
            setSchoolError(false);
        }
        setData({
            ...data,
            [id]: value
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setCreateUserError(null)
        let allForm = true;
        if (!validInput('role')) {
            allForm = false;
        };
        if (!validInput('orgId')) {
            allForm = false;
        };
        if (!validInput('username')) {
            allForm = false;
        };
        if (!validInput('firstName')) {
            allForm = false;
        };
        if (!validInput('lastName')) {
            allForm = false;
        };
        if (validInput('role')) {
            if (data.role != 'student') {
                if (!editing) {
                    if (!validInput('password')) {
                        allForm = false;
                    };
                }
            } else {
                if (!validInput('grade')) {
                    allForm = false;
                };
            }
        }
        if (allForm) {
            var booksUser = [];
            if (data.role == 'director' || data.role == 'teacher' || data.role == 'student') {
                if (booksName.length > 0) {
                    setBooksError(false);
                    booksUser = {
                        "books": createArrayBooks(booksName),
                        "userId": "",
                        "validityDate": "2022-01-01",
                        "orgId": data.orgId
                    }
                    setLoading(true);
                    if (editing) {
                        const org = await getOrganizationById(userData.orgId);
                        let licensesBooks = filter(booksUser?.books, x => x.count == true).length;
                        let currentLicensesBooks = filter(userData?.books, x => x.count == true).length;
                        const newOrgCount = Number(org.maxCount) + Number(licensesBooks) - Number(currentLicensesBooks);
                        const orgData = {
                            studentCount: org.studentCount.toString(),
                            maxCount: newOrgCount.toString()
                        };
                        if (newOrgCount < parseInt(org.licenses)) {
                            await dispatch(handleUpdateUser(userData.id, {
                                ...userData,
                                ...data
                            }, {
                                ...booksUser
                            },
                                userData.userBookId));
                            await dispatch(handleUpdateOrganization(org.id, { ...org, ...orgData }))
                            onClose();
                        } else {
                            setNoLicensesError(true);
                        }
                    } else {
                        const nameCheck = await dispatch(handleCheckUsername(data.username));
                        if (!nameCheck) {
                            const org = await getOrganizationById(data.orgId);
                            let licensesBooks = filter(booksUser?.books, x => x.count == true).length;
                            const newOrgCount = Number(org.maxCount) + Number(licensesBooks);
                            let studentLicenses = Number(org.studentCount);
                            if (data.role == 'student') {
                                studentLicenses = studentLicenses + 1;
                            }
                            const orgData = {
                                studentCount: studentLicenses.toString(),
                                maxCount: newOrgCount.toString()
                            };
                            if (newOrgCount < parseInt(org.licenses)) {
                                const newUser = await dispatch(handleCreateUser({
                                    auth: {
                                        username: data.username,
                                        password: data.role === 'student' ? null : data.password,
                                    },
                                    user: {
                                        firstName: data.firstName,
                                        lastName: data.lastName,
                                        orgId: data.orgId,
                                        role: data.role,
                                    },
                                    userBooks: booksUser
                                }));
                                if (newUser && !newUser.error) {
                                    await dispatch(handleUpdateOrganization(org.id, { ...org, ...orgData }))
                                    onClose();
                                } else {
                                    if(newUser.errorDesctiption&&newUser.errorDesctiption.code=="UsernameExistsException"){
                                        setCreateUserError(" Please, create the student again with a new username. ")
                                    }else{
                                        setCreateUserError(newUser.message||"Something went wrong!")
                                    }
                                }
                            } else {
                                setNoLicensesError(true);
                            }
                        } else {
                            setUsernameError(true)
                        }
                    }
                    setLoading(false);
                } else {
                    setBooksError(true);
                }
            } else {
                setLoading(true);
                if (editing) {
                    await dispatch(handleUpdateUser(userData.id, {
                        ...userData,
                        ...data
                    }, {
                        ...booksUser
                    },
                        userData.userBookId));
                    onClose();
                } else {
                    const nameCheck = await dispatch(handleCheckUsername(data.username));
                    if (!nameCheck) {
                        const newUser = await dispatch(handleCreateUser({
                            auth: {
                                username: data.username,
                                password: data.role === 'student' ? null : data.password,
                            },
                            user: {
                                firstName: data.firstName,
                                lastName: data.lastName,
                                orgId: data.orgId,
                                role: data.role,
                            },
                            userBooks: booksUser
                        }));
                        if (newUser && !newUser.error) {
                            onClose();
                        } else {
                            if(newUser.errorDesctiption&&newUser.errorDesctiption.code=="UsernameExistsException"){
                                setCreateUserError(" Please, create the student again with a new username. ")
                            }else{
                                setCreateUserError(newUser.message||"Something went wrong!")
                            }

                        }
                    } else {
                        setUsernameError(true)
                    }
                }
                setLoading(false);
            }
        }
        
    };

    const checkUsername = async e => {
        const nameCheck = await dispatch(handleCheckUsername(e.target.value));

        if (nameCheck) {
            setUsernameError(true)
        } else {
            setUsernameError(false)
        }
    };

    const usernameValidation = (e) => {
        var validation = /^[0-9a-zA-Z]+$/;
        if (e.target.value === "" || validation.test(e.target.value)) {
            setUserError(false);
            handleChange(e);
        }
    };

    const passwordValidation = (e) => {
        if (e.target.value != "") {
            setPasswordError(false);
            setFormatPasswordError(false);
        }
        handleChange(e);
    };

    const handleChangeMultiSelect = (event) => {
        setBooksError(false);
        setNoLicensesError(false);
        setBooksName(event.target.value);
    };

    function createArrayBooks(booksName) {
        let arrayBooks = [];
        for (let b in booksName) {
            let finded = listBooks.find(e => e.description == booksName[b]);
            let count = false;
            if (finded.count != undefined) {
                count = finded.count == "true" ? true : false
            }
            if (finded) {
                arrayBooks.push({
                    bookId: finded.id,
                    description: finded.description,
                    count: count
                })
            }
        }
        return arrayBooks;
    }

    function validInput(input) {
        switch (input) {
            case 'role':
                if (data.role == undefined || data.role == null || data.role == '') {
                    setRoleError(true);
                    return false;
                } else {
                    setRoleError(false);
                    return true;
                }
            case 'orgId':
                if (data.orgId == undefined || data.orgId == null || data.orgId == '') {
                    setSchoolError(true);
                    return false;
                } else {
                    setSchoolError(false);
                    return true;
                }
            case 'username':
                if (data.username == undefined || data.username == null || data.username == '') {
                    setUserError(true);
                    return false;
                } else {
                    setUserError(false);
                    return true;
                }
            case 'password':
                if (data.password == undefined || data.password == null || data.password == '') {
                    setPasswordError(true);
                    return false;
                } else {
                    setPasswordError(false);
                    var paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
                    if (data.password.match(paswd)) {
                        setFormatPasswordError(false);
                        return true;
                    } else {
                        setFormatPasswordError(true);
                        return false;
                    }
                }
            case 'firstName':
                if (data.firstName == undefined || data.firstName == null || data.firstName == '') {
                    setFirstNameError(true);
                    return false;
                } else {
                    setFirstNameError(false);
                    return true;
                }
            case 'lastName':
                if (data.lastName == undefined || data.lastName == null || data.lastName == '') {
                    setLastNameError(true);
                    return false;
                } else {
                    setLastNameError(false);
                    return true;
                }
            case 'grade':
                if (data.grade == undefined || data.grade == null || data.grade == '') {
                    setGradeError(true);
                    return false;
                } else {
                    setGradeError(false);
                    return true;
                }
            default:
                return false;
        }
    }

    return (
        <Wrapper onSubmit={onSubmit}>
            <Header>{editing ? 'Update User' : 'Create User'}</Header>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
                <Col span={12}>
                    <FormControl variant="outlined">
                        <InputLabel id="roleLabel">Select a Role</InputLabel>
                        <Select
                            labelId="roleLabel"
                            id="role"
                            onChange={e => handleSelect('role', e.target.value)}
                            value={data?.role}
                        >
                            {roles?.map(role => (
                                <MenuItem key={role.value} value={role.value}>
                                    {role.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {roleError &&
                            <FormHelperText error>Role required</FormHelperText>
                        }
                    </FormControl>
                </Col>
                {!editing && (
                    <Col span={12}>
                        <FormControl variant="outlined">
                            <InputLabel id="schoolLabel">{typeOrg == 'school' ? 'Select School' : 'Select Organization'}</InputLabel>
                            <Select
                                labelId="schoolLabel"
                                id="orgId"
                                onChange={e => handleSelect('orgId', e.target.value)}
                                value={data?.orgId}
                            >
                                {schools?.map(school => (
                                    typeOrg == school.typeId &&
                                    <MenuItem key={school.id} value={school.id}>
                                        {school.displayName}
                                    </MenuItem>
                                ))}
                                {typeOrg == 'NoSet' &&
                                    <MenuItem key='noSet' value='noSet'>
                                        Select a User Role
                                    </MenuItem>
                                }
                            </Select>
                            {schoolError &&
                                <FormHelperText error>Organization is required</FormHelperText>
                            }
                        </FormControl>
                    </Col>
                )}
            </Row>
            {!data.editing && (
                <Row gutter={[24, 24]} style={{ width: '100%' }}>
                    <Col span={data.role === 'student' ? 24 : 12}>
                        <TextField
                            error={usernameError}
                            id="username"
                            onBlur={checkUsername}
                            onChange={usernameValidation}
                            label="User ID"
                            variant="outlined"
                            value={data?.username}
                            disabled={editing}
                            helperText={usernameError && 'Username not available'}
                        />
                        {userError &&
                            <FormHelperText error>Username required</FormHelperText>
                        }
                    </Col>
                    {(data.role !== 'student' && !editing) && (
                        <Col span={12}>
                            <TextField
                                id="password"
                                onChange={passwordValidation}
                                label="Password"
                                variant="outlined"
                            />
                            {passwordError &&
                                <FormHelperText error>Password required</FormHelperText>
                            }
                            {formatPasswordError &&
                                <FormHelperText error>Use 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.</FormHelperText>
                            }
                        </Col>
                    )}
                </Row>
            )}
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
                <Col span={data.role === 'student' ? 10 : 12}>
                    <TextField
                        id="firstName"
                        onChange={handleChange}
                        label="First Name"
                        variant="outlined"
                        value={data?.firstName ?? ' '}
                    />
                    {firstNameError &&
                        <FormHelperText error>First name required</FormHelperText>
                    }
                </Col>
                <Col span={data.role === 'student' ? 10 : 12}>
                    <TextField
                        id="lastName"
                        onChange={handleChange}
                        label="Last Name"
                        variant="outlined"
                        value={data?.lastName ?? ' '}
                    />
                    {lastNameError &&
                        <FormHelperText error>Last name required</FormHelperText>
                    }
                </Col>
                {data.role === 'student' && (
                    <Col span={4}>
                        <TextField
                            id="grade"
                            onChange={handleChange}
                            label="Grade"
                            variant="outlined"
                            value={data?.grade ?? ' '}
                        />
                        {gradeError &&
                            <FormHelperText error>Grade required</FormHelperText>
                        }
                    </Col>
                )}
            </Row>
            {showBooks &&
                <Row gutter={[24, 24]} style={{ width: '100%' }}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="bookList-label">Books by user</InputLabel>
                        <Select
                            labelId="bookList-label"
                            id="bookList-mutiple-chip"
                            multiple
                            value={booksName}
                            onChange={handleChangeMultiSelect}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {assignableBooks.map((list) => (
                                <MenuItem key={list.id} value={list.description} style={getStyles(list.description, booksName, theme)}>
                                    {list.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {booksError &&
                            <FormHelperText error>Choose at least one book</FormHelperText>
                        }
                        {noLicensesError &&
                            <FormHelperText error>The selected school does not have enough licenses available</FormHelperText>
                        }
                         {!!createUserError &&
                                <FormHelperText error>{createUserError}</FormHelperText>
                    }
                    </FormControl>
                </Row>
            }
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
                <Col span={24}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: '8px', width: '100%' }}
                        type='submit'
                    >
                        {loading ? <Loading size={20} white /> : (editing ? 'Update User' : 'Create User')}
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
UserForm.propTypes = {};

//  -- Default props ------------------
UserForm.defaultProps = {};

export default UserForm;
