//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Slider, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack, MenuOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import queryString from "query-string";
//  -- Components ---------------------
import Loading from "components/Loading";
//  -- Features -----------------------
import { Col, Row, Dropdown, Menu, Button as AntdButton } from "antd";
import PodcastTooling from "features/PodcastToolingNew";
import { find, get, filter } from "lodash";
import { string } from "prop-types";
import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useResources, useVideos } from "store/resources/selectors";
import styled from "styled-components";
import { NoteIconimg, QuestionMarkimg } from "utils/assets/images";
import COLOR from "utils/constants/color";
import { getBookData, getBookDisplayName, getPageData, imageFetchFromPodcast } from 'utils/lib/ebookHelper';
import { useAllBook } from "store/books/selectors";
import moment from 'moment';
import { useTimmerRunning } from 'store/timmer/selectors';
import { MenuImg, MenuWhiteImg } from "utils/assets/images"
import Vocabilary from "components/Vocabilary"
import { useResourceViews } from "utils/methods/firebaseResource";
import {
  collection,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { useFirebase } from "containers/FirebaseContext";
import BubbleNotes from "components/Notes";
import { useNotesByBook } from "store/notes/bubbleSelector";
import ClosedCaption from "@material-ui/icons/ClosedCaption";
import { useResourceActions } from "utils/methods/firebaseResourceDisable";
import { useActivities } from "store/activities/selectors";
import { useQuizzes } from "store/quizzes/selectors";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
const { Item } = Menu;
const BTN_KEY = ["add_notes", "add_question", "activity", "description"];

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Container = styled.div`
  max-width: 1600px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px 0px 0px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    flex-direction: row;
    padding: 0px 32px;
  }
  @media only screen and (min-width: 800px) and (max-width: 1200px) {
    padding: 0 14px;
  }
`;

const ImageContainer = styled.div`
  height: auto;
  flex: 1;
`;

const ToolsContainer = styled.div`
  height: 100%;
  margin-top: 48px;
  width: 100%;

  @media (min-width: 800px) {
    margin-top: 0px;
    width: 340px;
  }
`;

const Video = styled.video`
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: auto;
  outline: none;
  width: 100%;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-top: 2%;
  position: relative;
  width: 100%;
`;
const VideoContainer = styled.div`
  width: 100%;
  border-bottom: 10px solid rgb(130, 222, 228);
  margin-bottom: 0px;
  border-bottom-width: ${(p) => (p.bottomWidth ? p.bottomWidth : "10px")};
  margin-right: 20px;
  position: relative;
  height:100%;
`;
const ToolingContainer = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 10px solid rgb(130, 222, 228);
  margin-bottom: 0px;
  border-bottom-width: ${(p) => (p.bottomWidth ? p.bottomWidth : "10px")};
  margin-left: 20px;
  overflow: hidden;
  @media (max-width: 1200px) {
    margin-left: 0px;
    margin-top: 24px;
  }
`;
const VideoTitleSection = styled.div`
  background-color: ${COLOR.indigo300};
  min-height: 50px;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
`;
const VideoTitle = styled.div`
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const AddButton = styled.div`
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  background-color: ${COLOR.indigo300};
  color: ${COLOR.white};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const NotesIconImg = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
`;
const QuestionIconImg = styled.img`
  height: 28px;
  width: 28px;
  object-fit: contain;
`;
const ToolingSubsection = styled.div`
  border: 2px dotted rgb(0 0 0);
  border-bottom-width: 0px;
  width: 100%;
  padding: 10px;
  margin-top: 16px;
  height:100%;
  background-color:rgb(241,239,240);
  //  overflow-y: auto;
  //  height:500px;
`;
const ActivityButton = styled(AddButton)`
  font-size: 12px;
  justify-content: center;
  align-items: center;
  width: 48%;
  min-height: 30px;
  cursor: pointer;
  display:flex;
  flex-direction:row;
`;

const ActivtyButtonConatiner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ToolsWrapper = styled.div`
  width: 100%;
  // height: 100%;
  margin-top: 16px;
  background-color:rgb(241,239,240);
   overflow-y: auto;
   max-height: 400px;
`;

const ButtonClear = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.white};
    cursor: pointer;
    height: 18px;
    outline: none;
    padding: 0px;
    transition: all 300ms ease;

    &:hover {
        color: ${COLOR.black};
    }
`;


const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black200};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 2px 0px;
    text-transform: uppercase;
`;

const SubtitleToggleButton = styled(AntdButton)`
  position: absolute !important;
    right: 92px;
    bottom: 31px;
  background-color: transparent !important;
  color: white !important;
  z-index: 10;
  border: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
`;

const MobileBar = styled.div`
`;
const MobileMenu = styled.div`
`;

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: "5px",
  },
  selectedOptions: {
    color: "#1890ff !important",
  },



  backText: {
    lineHeight: 1.5,
    color: "#1890ff",
    fontWeight: "bold",
  },
});

//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook
//
//  -- Description
//  Ebook display component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const useTrackVtt = (data) => {
  const [subtitleUrl, setSubtitleUrl] = useState('');
  const [vttText, setVttText] = useState('');

  useEffect(() => {
    const fetchSubtitles = async () => {
      if (data?.srt) {
        try {
          const response = await fetch(data.srt);
          let vttText = await response.text();
          if (data.srt.includes(".srt")) {
            vttText = 'WEBVTT\n\n' + vttText
              .replace(/\r/g, '')
              .replace(/(\d\d:\d\d:\d\d),(\d\d\d)/g, '$1.$2');
          }
          setVttText(vttText);
          const blob = new Blob([vttText], { type: 'text/vtt' });
          const url = URL.createObjectURL(blob);
          setSubtitleUrl(url);
        } catch (error) {
          console.error('Failed to load subtitles:', error);
        }
      } else {
        setSubtitleUrl(null);
        setVttText('');
      }
    };
    fetchSubtitles();
    return () => {
      if (subtitleUrl) {
        URL.revokeObjectURL(subtitleUrl);
      }
    };
  }, [data]);

  return { subtitleUrl, vttText };
};

const VideoPlayer = ({ data, getPoster, showCopyRight, vttTrack, listenActions, role, activeActivity, activeQuiz, setAlert }) => {
  const [subtitlesEnabled, setSubtitlesEnabled] = useState(true);
  const [volume, setVolume] = useState(50);
  const [hover, setHover] = useState(true);
  const [playCount, setPlayCount] = useState(0);
  const [controlsEnabled, setControlsEnabled] = useState(true);
  const [mute, setMute] = useState(false);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [lastVolume, setLastVolume] = useState(50);
  const videoRef = useRef(null);

  const handleVolumeChange = (val) => {
    if (val === 0) {
      setMute(true);
    } else {
      setMute(false);
    }
    setVolume(val);
    const video = document.querySelector(".oneWayVideoContainer");
    if (video) {
      video.volume = val / 100;
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && role === 'student') {
      const handlePlay = () => {
        if (!controlsEnabled) {
          videoElement.pause();
        }
      };
      const handlePause = (e) => {
        if (!controlsEnabled) return; 
        if (!listenActions?.play) {
          e.preventDefault();
          e.stopPropagation();
          videoElement.play();
          setAlert({ open: true, message: "Teacher Block students from stopping videos." })
        }
      };
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
      return () => {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
      };
    }
  }, [listenActions?.play, controlsEnabled]);

  const toggleSubtitles = () => {
    setSubtitlesEnabled(!subtitlesEnabled);
  };

  const handleVideoEnd = () => {
    if ((activeQuiz?.play_video || activeActivity?.play_video)) {
      setPlayCount(prevCount => {
        const newCount = prevCount + 1;
        if (role === 'student' && newCount >= (activeQuiz?.play_video || activeActivity?.play_video)) {
          setControlsEnabled(false);
        }

        return newCount;
      });
    }
  };

  return (
    <VideoContainer
      className="video-container"
      onMouseEnter={() => setHover(true)}
      onTouchStart={() => {
        setHover(true)
        setTimeout(() => {
          setHover(false);
        }, 3000)
      }}
      onMouseLeave={() => setHover(false)}
    >
      <div className="videoWrapper">
        {role === 'student' && listenActions?.caption && (
          <>
            {vttTrack && hover && (
              <SubtitleToggleButton
                className="subtitle-toggle-button"
                onClick={toggleSubtitles}
              >
                {subtitlesEnabled
                  ? <ClosedCaption color="#fff" />
                  : <ClosedCaption style={{ color: "rgb(252 239 239 / 26%)" }} color="rgb(252 239 239 / 26%)" />
                }
              </SubtitleToggleButton>
            )}
          </>
        )}
        {role === "teacher" && (
          <>
            {vttTrack && hover && (
              <SubtitleToggleButton
                className="subtitle-toggle-button"
                onClick={toggleSubtitles}
              >
                {subtitlesEnabled
                  ? <ClosedCaption color="#fff" />
                  : <ClosedCaption style={{ color: "rgb(252 239 239 / 26%)" }} color="rgb(252 239 239 / 26%)" />
                }
              </SubtitleToggleButton>
            )}
          </>
        )}
        <video
          ref={videoRef}
          className="oneWayVideoContainer videoBackground"
          // autoPlay={role === 'student' ? true : false}
          // loop={role === 'student' ? true : false}
          onContextMenu={(e) => {
            e.preventDefault();
            showCopyRight();
          }}
          onEnded={handleVideoEnd}
          controls
          poster={getPoster()}
          controlsList="nodownload"
          width="100%"
        >
          <source src={data?.src} type="video/mp4" />
          {role === "teacher" && (
            <>
              {vttTrack && subtitlesEnabled && (
                <track
                  kind="subtitles"
                  src={vttTrack}
                  srcLang="en"
                  label="English"
                  default
                />
              )}
            </>
          )}
          {role === "student" && (
            <>
              {vttTrack && subtitlesEnabled && listenActions?.caption && (
                <track
                  kind="subtitles"
                  src={vttTrack}
                  srcLang="en"
                  label="English"
                  default
                />
              )}
            </>
          )}
        </video>
        {hover && (
          <div
            style={{ background: !showVolumeSlider && "none" }}
            onMouseEnter={() => setShowVolumeSlider(true)}
            onTouchStart={() => {
              setShowVolumeSlider(true);
              // setTimeout(()=>{
              //   setShowVolumeSlider(false);
              // },5000)
            }}
            onMouseLeave={() => setShowVolumeSlider(false)}
            className="volumeSlider"
          >
            {mute ? (
              <VolumeOffIcon
                style={{ color: "white" }}
                onClick={() => {
                  const newVolume = lastVolume;
                  const video = document.querySelector(".oneWayVideoContainer");
                  if (video) {
                    video.volume = newVolume / 100;
                  }
                  setVolume(newVolume);
                  setMute((prev) => !prev);
                }}
              />
            ) : (
              <VolumeUpIcon
                style={{ color: "white" }}
                onClick={() => {
                  setLastVolume(volume);
                  const newVolume = 0;
                  const video = document.querySelector(".oneWayVideoContainer");
                  if (video) {
                    video.volume = newVolume / 100;
                  }
                  setVolume(newVolume);
                  setMute((prev) => !prev);
                }}
              />
            )}
            {showVolumeSlider && (
              <Slider
                defaultValue={50}
                value={volume}
                onChange={(e, val) => handleVolumeChange(val)}
                aria-labelledby="continuous-slider"
                min={0}
                max={100}
                step={1}
                valueLabelDisplay="auto"
                style={{ color: "#1976d2", width: "150px", marginLeft: "12px" }}
              />
            )}
          </div>
        )}
      </div>
    </VideoContainer>
  );
};
const PodcastLayout = ({
  data,
  id,
  quiz,
  activity,
  loading,
  tipsBook,
  quizzes,
  classUsers,
  classQuizzes,
  classActivities,
  submissions,
  loadingContent,
  link,
  currentUser,
  currentClass,
  activities,
  questions,
  notes,
  likes,
  interactions
}) => {
  const pageNotes = useNotesByBook();
  const classes = useStyles();
  const history = useHistory();
  const search = queryString.parse(history.location.search);
  const [value, setValue] = React.useState(0);
  const [activeBtn, setActiveBtn] = React.useState(BTN_KEY[2]);
  const booksAllList = useAllBook()
  const timmerRef = useRef(null)
  const timeIntRef = useRef(0)
  const clearPreviewRef = useRef(null)
  const [timeInt, setTimeInt] = useState(0)
  const [timmerRunning, setTimmerRunning, clearTimmerRunning] = useTimmerRunning()
  const submitRef = useRef(null)
  const videos = useVideos()
  const isTeacher = get(currentUser, "role") === "teacher"
  const isStudent = get(currentUser, "role") === "student"
  const resources = useResources()
  const { subtitleUrl: vttTrack, vttText } = useTrackVtt(data)
  const { createResourceViews } = useResourceViews()
  const { createResourceActions } = useResourceActions()
  const { firestore } = useFirebase()
  const [alert, setAlert] = useState({
    open: false,
    message: ""
  })
  const bookData = getBookData(activities, quizzes, questions, notes, likes, interactions, classActivities, classQuizzes, id)
  const pageData = getPageData(bookData, id, quiz, activity)
  const activeActivity = pageData.activeActivity;
  const activeQuiz = pageData.activeQuiz;
  const videoInfo = find(get(find(resources, ['key', search.link]), "videos"), ["id", search.id])
  const [studentViewFlag, setStudentViewFlag] = React.useState(false);
  const [resourceView, setResourceView] = useState({})
  const [hasViewed, setHasViewed] = useState(false);
  const [actionShowCaptions, setActionShowCaptions] = useState(true);
  const [actionShowPlay, setActionShowPlay] = useState(true);
  const [listenActions, setListenActions] = useState({});

  useEffect(() => {
    const unsubscribe = listenToResourceActions(get(videoInfo, 'id', ''), get(currentClass, 'id', ''));
    return () => {
      unsubscribe();
    };
  }, [firestore, currentClass, videoInfo])

  const listenToResourceActions = (videoId, classId) => {
    if (!videoId || !classId) {
      return () => { };
    }
    const collectionRef = collection(firestore, "resource_video_actions");
    const q = query(collectionRef, where("classId", "==", classId), where("videoId", "==", videoId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let viewData = null;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          viewData = { id: doc.id, ...doc.data() };
        });
      }
      if (viewData) {
        setActionShowCaptions(viewData?.action?.caption);
        setActionShowPlay(viewData?.action?.play)
        setListenActions(viewData?.action);
      } else {
        setListenActions({
          caption: true,
          play: true
        });
      }
    }, (error) => {
      console.error("Error listening to session changes:", error);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = listenToShareWidgetData(get(videoInfo, 'id', ''));
    return () => {
      unsubscribe();
    };
  }, [])

  const listenToShareWidgetData = (videoId) => {
    if (!videoId) {
      return () => { };
    }

    const collectionRef = collection(firestore, "most_viewed_video");
    const q = query(collectionRef, where("id", "==", videoId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let viewData = null;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          viewData = { id: doc.id, ...doc.data() };
        });
      }
      setResourceView(viewData);
    }, (error) => {
      console.error("Error listening to session changes:", error);
    });

    return unsubscribe;
  };


  useEffect(() => {
    if (resourceView && resourceView.id && !hasViewed) {
      createViewedVideo();
      setHasViewed(true);
    } else if (!resourceView) {
      createViewedVideo();
      setHasViewed(true);
    }
  }, [resourceView, hasViewed]);

  const createViewedVideo = () => {
    const CreateNewView = {
      // ...resourceView,
      type: "resource",
      id: get(videoInfo, 'id', ''),
      viewed_video: (resourceView?.viewed_video || 0) + 1
    };
    createResourceViews(CreateNewView)
  };

  const showCopyRight = () => {
    setAlert({ open: true, message: "Content is protected!" })
  };

  const handleClose = () => {
    setAlert({ open: false, message: "" })
  };

  const backToResources = () => {
    history.push(`/resources?link=${search?.link ?? null}`);
  };
  useEffect(() => {
    if (currentUser?.role != 'student') return;
    try {
      let actAct = find(classActivities, x => (get(x, "data.id") === activity))
      let actqz = find(classQuizzes, x => (get(x, "data.id") === quiz))
      let Actdata = find(submissions, ['assignmentId', get(actAct, "activityId")])
      let qzdata = find(submissions, ['assignmentId', get(actqz, "quizId")])
      let _timmer = 0
      let _durration = 0;
      if (activity && typeof activity === "string" && activity != "null" && activity != "undefined" && actAct) {
        _durration = actAct.timeDuration
        if (Actdata) {
          if (!Actdata.complete) {
            _timmer = Actdata.timmer
          }
          handleTimmer(_durration, _timmer)
        } else {
          handleTimmer(_durration, _timmer)
        }

      } else if (quiz && typeof quiz === "string" && quiz != "null" && quiz != "undefined" && actqz) {
        _durration = actqz.timeDuration
        if (qzdata) {
          if (!qzdata.complete) {
            _timmer = qzdata.timmer
            handleTimmer(_durration, _timmer)
          }
        } else {
          handleTimmer(_durration, _timmer)
        }
      }

    } catch (error) {
      console.log(error, "errr")
    }

  }, [activity, quiz, classActivities, classQuizzes])
  const handleTimmer = (timeDuration, timer) => {
    try {
      if (timeDuration && !isNaN(parseInt(timeDuration)) && parseInt(timeDuration) > 0) {
        let _timer = parseInt(timer) > 0 ? parseInt(timer) : parseInt(timeDuration) * 60
        if (timmerRef.current) { onFinish() };
        timeIntRef.current = _timer;
        setTimmerRunning()
        timmerRef.current = setInterval(() => {
          if (timeIntRef.current > 0) {
            timeIntRef.current = timeIntRef.current - 1
            let timePrint = moment.utc(timeIntRef.current * 1000).format('HH:mm:ss');
            setTimeInt(timePrint)
          } else {
            if (typeof submitRef.current == "function") {
              submitRef.current(true)
              submitRef.current = null
              onFinish()
            }
          }
        }, 1000)
      }
    } catch (error) {
      console.log(error, "error in effect");
    }
    return () => {
      onFinish()
    }
  }
  const onFinish = () => {
    clearInterval(timmerRef.current)
    setTimeInt(0)
    timeIntRef.current = 0
    timmerRef.current = null
    clearTimmerRunning()
  }
  const setSubmitRef = (ref) => {
    submitRef.current = ref
  }
  const getPoster = () => {
    return get(find(resources, ['key', search.link]), "poster", '')
  }
  const timmerAlert = () => {
    setAlert({ open: true, message: "This functionality is blocked for time-based activities." })
  }

  const onHideShowVideos = (hideFrom) => {
    if (hideFrom === 'caption') {
      setActionShowCaptions(!actionShowCaptions)
      const ResourceData = {
        classId: get(currentClass, 'id', ''),
        videoId: id,
        action: {
          caption: !actionShowCaptions,
          play: actionShowPlay
        }
      }
      createResourceActions(ResourceData)
    } else {
      setActionShowPlay(!actionShowPlay)
      const ResourceData = {
        classId: get(currentClass, 'id', ''),
        videoId: id,
        action: {
          caption: actionShowCaptions,
          play: !actionShowPlay
        }
      }
      createResourceActions(ResourceData)
    }
  }

  const navigateToTeacherDashboard = (to) => {
    switch (to) {
      case "manage":
        history.push("/admin/class?view=manage")
        break;
      case "gradebook":
        history.push("/admin/class?view=gradebook")
        break;
      case "classdata":
        history.push("/admin/class?view=classdata")
        break;
      default:
        history.push("/admin/class?view=home")
        break;
    }
  }
  const navigateToStudentDashboard = (to) => {
    switch (to) {
      case "calendar":
        history.push("/student/class?view=assessments")
        break;
      case "studentData":
        history.push("/student/class?view=studentData")
        break;
      default:
        history.push("/student/class?view=home")
        break;
    }
  }
  const toolbarNav = () => {
    return (
      <Menu style={{ backgroundColor: "rgb(121 120 199)" }}>
        {isStudent && (<Menu.Item style={{ borderBottom: "1px dotted white" }}>
          <ButtonClear onClick={() => navigateToStudentDashboard("calendar")}>
            calendar
          </ButtonClear>
        </Menu.Item>)}
        {isStudent && (<Menu.Item>
          <ButtonClear onClick={() => navigateToStudentDashboard("studentData")}>
            Student Data
          </ButtonClear>
        </Menu.Item>)}
        {isTeacher && (<Menu.Item>
          <ButtonClear onClick={() => navigateToTeacherDashboard("manage")}>
            Assignments
          </ButtonClear>
        </Menu.Item>)}
        {isTeacher && (<Menu.Item>
          <ButtonClear onClick={() => navigateToTeacherDashboard("gradebook")}>
            Gradebook
          </ButtonClear>
        </Menu.Item>)}
        {isTeacher && (<Menu.Item>
          <ButtonClear onClick={() => navigateToTeacherDashboard("classdata")}>
            Class Data
          </ButtonClear>
        </Menu.Item>)}
      </Menu>
    )
  };
  const RenderMenuContent = ({ showLargeDrawer }) => {
    return (
      <Menu className="ulLitItme">
        <Item
          style={{
            backgroundColor: `${listenActions?.caption ? 'none' : 'red'}`,
            color: `${listenActions?.caption ? 'none' : 'white'}`
          }}
          className='ulLitItmeList'
          onClick={() => {
            onHideShowVideos('caption');
          }}>{`${listenActions?.caption ? 'Disable' : 'Enable'} Caption`}</Item>
        {isTeacher && <Item
          style={{
            backgroundColor: `${listenActions?.play ? 'none' : 'red'}`,
            color: `${listenActions?.play ? 'none' : 'white'}`
          }}
          className='ulLitItmeList'
          onClick={() => {
            onHideShowVideos('play');
          }}>{`${listenActions?.play ? 'Disable' : 'Enable'} Play Video`}</Item>}
      </Menu>
    )
  }
  const video_title = useMemo(() => {
    return getBookDisplayName(booksAllList, id, true, videos, resources)
  }, [booksAllList, videos, resources, id]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          <strong>ALERT:</strong> {alert.message}
        </Alert>
      </Snackbar>
      <Wrapper
        onContextMenu={(e) => {
          e.preventDefault();
          showCopyRight();
        }}>
        <Container>
          <ImageContainer>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Row>
                  <Button
                    onClick={() => {
                      backToResources();
                    }}
                  >
                    <ArrowBack style={{ fontSize: "14px", marginRight: "4px" }} />
                    Back to Resources
                  </Button>
                  <Vocabilary pageId={id} bookId={id} isVocabulary={true} />
                  {currentUser?.role === 'teacher' && (
                    <>
                      <MobileBar className="mobileTabs eBookTabLft eBookTabMob scheduledWebHide">
                        <MobileMenu className="mobileTabDropVideo">
                          <Dropdown overlay={<RenderMenuContent showLargeDrawer={true} />} trigger={['click']}>
                            <Button style={{ display: 'flex', alignItems: 'center' }} className="BtnMenuOptions">
                              <MenuOutlined />
                            </Button>
                          </Dropdown>
                        </MobileMenu>
                      </MobileBar>
                      <Button style={{
                        borderRadius: "0px !important",
                        minWidth: "unset",
                        height: "36px",
                        marginLeft: currentUser?.role === 'student' && 0,
                        backgroundColor: `${ listenActions.hasOwnProperty('caption') ? listenActions?.caption ? '#002b70' : 'red' : "#002b70"}`
                      }}
                        onClick={() => {
                          onHideShowVideos('caption');
                        }}
                        className={'videoSectionDisable superLessonButton'}
                      >{`${listenActions?.caption ? 'disable' : 'enable'} caption`}</Button>
                      <Button style={{
                        borderRadius: "0px !important",
                        minWidth: "unset",
                        height: "36px",
                        marginLeft: currentUser?.role === 'student' && 0,
                       backgroundColor: `${ listenActions.hasOwnProperty('play') ? listenActions?.play ? '#002b70' : 'red' : "#002b70"}`
                      }}
                        onClick={() => {
                          onHideShowVideos('play');
                        }}
                        className={'videoSectionDisable superLessonButton'}
                      >{`${listenActions?.play ? 'disable' : 'enable'} play video`}</Button>
                    </>
                  )}
                </Row>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 16 }}>
                    <VideoPlayer
                      data={data}
                      showCopyRight={showCopyRight}
                      getPoster={getPoster}
                      vttTrack={vttTrack}
                      listenActions={listenActions}
                      role={currentUser?.role}
                      activeActivity={activeActivity}
                      activeQuiz={activeQuiz}
                      setAlert={setAlert}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 8 }}>
                    <ToolingContainer style={{ maxHeight: 700, height: "100%" }}>
                      <VideoTitleSection>
                        <VideoTitle>{video_title}  </VideoTitle>
                        <div className="flex items-center">
                          <span style={{ marginRight: "5px" }}>
                            <BubbleNotes
                              pageName={"videosection"}
                              pageSrc={imageFetchFromPodcast(id, resources)}
                              data={data}
                              page={id} // pageID
                              newMaskNote={""}
                              currentUser={currentUser}
                              pageNotes={pageNotes}
                              chapter={id}
                              dataBook={data}
                            />
                          </span>
                          <Dropdown overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                            <ButtonLink onClick={e => e.preventDefault()} style={{ marginLeft: '16px' }}>
                              <img src={MenuWhiteImg} style={{ height: 20 }} />
                            </ButtonLink>
                          </Dropdown>
                        </div>



                      </VideoTitleSection>
                      <Typography variant="h5" align="center" style={{ fontSize: '20px !important;', fontFamily: "sans-serif", marginTop: '12px' }}>
                        {timeInt !== 0 && currentUser?.role == 'student' ? timeInt : ""}
                      </Typography>
                      <ToolingSubsection>
                        <ActivtyButtonConatiner>
                          <ActivityButton
                            onClick={() => {
                              if (timmerRunning) {
                                timmerAlert()
                              } else {
                                setActiveBtn(BTN_KEY[2]);
                              }

                            }}
                          >
                            Activity
                          </ActivityButton>
                          <ActivityButton
                            onClick={() => {
                              if (timmerRunning) {
                                timmerAlert()
                              } else {
                                setActiveBtn(BTN_KEY[3]);
                              }
                            }}
                          >
                            Description
                          </ActivityButton>
                        </ActivtyButtonConatiner>
                        {/* <ActivtyButtonConatiner style={{ marginTop: 16 }}>
                          <ActivityButton
                            onClick={() => {
                              if (timmerRunning) {
                                timmerAlert()
                              } else {
                                setActiveBtn(BTN_KEY[0]);
                              }
                            }}
                          >
                            <NotesIconImg src={NoteIconimg} />  Notes
                          </ActivityButton>
                        </ActivtyButtonConatiner> */}
                        <ToolsWrapper>
                          {loadingContent ? <Loading /> :
                            <PodcastTooling
                              data={data}
                              id={id}
                              quiz={search.quiz}
                              activity={search.activity}
                              section={BTN_KEY.findIndex((k) => k == activeBtn)}
                              tipsBook={tipsBook}
                              quizzes={quizzes}
                              classUsers={classUsers}
                              classQuizzes={classQuizzes}
                              submissions={submissions}
                              studentViewFlag={studentViewFlag}
                              setStudentViewFlag={setStudentViewFlag}
                              loadingContent={loadingContent}
                              search={search}
                              setSubmitRef={setSubmitRef}
                              timeInt={timeInt}
                              timmer={timeIntRef.current}
                              videoInfo={find(get(find(resources, ['key', search.link]), "videos"), ["id", search.id])}
                              onFinish={onFinish}
                              onPageChage={re => clearPreviewRef.current = re}
                              track={vttText}
                              listenActions={listenActions}
                              title={getBookDisplayName(booksAllList, id, true, videos, resources)}
                              onClose={() => {
                                setActiveBtn(BTN_KEY[2]);
                              }}
                            />}
                        </ToolsWrapper>
                      </ToolingSubsection>
                    </ToolingContainer>
                  </Col>
                </Row>
                {/* <Video onContextMenu={(e)=>{
                            e.preventDefault();
                            showCopyRight()
                        }} controls controlsList="nodownload" width="100%">
                            <source src={data?.src} type="video/mp4" />
                        </Video> */}
                {/* <BottomNavigation
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            showLabels
                            className={classes.root}
                            >
                            <BottomNavigationAction classes={{
                                selected: classes.selectedOptions
                            }} label="Description" icon={<DescriptionIcon />} />
                            <BottomNavigationAction classes={{
                                selected: classes.selectedOptions
                            }} label="Activity" icon={<ArtTrackIcon />} />
                            <BottomNavigationAction classes={{
                                selected: classes.selectedOptions
                            }} label="Summary" icon={<NotesIcon />} />
                            <BottomNavigationAction classes={{
                                selected: classes.selectedOptions
                            }} label="Notes & Questions" icon={<QuestionAnswerIcon />} />
                            <BottomNavigationAction classes={{
                                selected: classes.selectedOptions
                            }} label="Transcription" icon={<SubtitlesIcon />} />
                        </BottomNavigation> */}
              </>
            )}
          </ImageContainer>
          {/* <ToolsContainer>
                    <PodcastTooling 
                        data={data} 
                        id={id}
                        quiz={quiz} 
                        section={value}
                        tipsBook={tipsBook}
                        quizzes={quizzes}
                        classUsers={classUsers}
                        classQuizzes={classQuizzes}
                        submissions={submissions}
                        studentViewFlag={studentViewFlag}
                        setStudentViewFlag={setStudentViewFlag}
                        loadingContent={loadingContent}
                    />
                </ToolsContainer> */}
        </Container>
      </Wrapper>
    </>
  );
};

//  -- Prop types ---------------------
PodcastLayout.propTypes = {
  children: string,
};

//  -- Default props ------------------
PodcastLayout.defaultProps = {
  children: "Hello world",
};

export default PodcastLayout;
