import { EditOutlined } from "@ant-design/icons";
import ShareIcon from "@material-ui/icons/Share";
import {
  Button,
  IconButton,
  Switch,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Check, Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import { Button as AntdButton, Row as Rows } from "antd";
import AnimatedCheckMark from "components/AnimatedCheckMark";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import { find, has, map, shuffle, chunk, get, isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { useUsersByClass } from "store/userClasses/selectors";
import { useCurrentUser } from "store/users/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import COLOR from "utils/constants/color";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { v4 } from "uuid";
import AccordinButton from "components/AccordinButton";


const useStyles = makeStyles((theme) => ({
  noSpinners: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  //   min-height: 500px;
  //   background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: default;
  left: 25%;
  border-radius: 13px;
`;

const LogoTopRow = styled.div``;
const Logo = styled.img`
  height: auto;
  width: 160px;
`;

const CloseBtn = styled.div`
  background-color: ${COLOR.PinkExam};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const TimerContainer = styled.div`
  gap: 22px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (max-width: 1366px) {
    flex-direction: column;
  }
`;

const EditListOption = styled.div`
  text-align: center;
  align-items: center;
  background-image: linear-gradient(
    90deg,
    ${COLOR.WheelEditColor1} 0%,
    ${COLOR.WheelEditColor2} 40%,
    ${COLOR.WheelEditColor3} 100%
  );
  color: #fff;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 90px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
`;

const ResetButton = styled.div`
  text-align: center;
  align-items: center;
  background: #d11919;
  color: #fff;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
  // @media (max-width: 997px) {
  //   margin-top: 16px;
  // }
`;

const UserArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  flex-wrap: wrap;
  max-height: 55vh;
  justify-content: center;
  // max-width: 50vw;
  // @media (max-width: 997px) {
  //   max-width: 90vw;
  // }
  //   overflow: auto !important;
  ::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }
`;

const SectionsGroup = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GroupHeaderText = styled.div`
  background-color: #fd9d1a;
  padding: 8px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 200px;
  text-align: center;
  color: #fff;
  justify-content: center;
`;

const UserListArea = styled.div`
  background: linear-gradient(
    90deg,
    #df6074 0%,
    #bd57b4 30%,
    #a451e7 50%,
    #9a59e8 60%,
    #7579f0 90%,
    #6786f4 100%
  );
  min-height: 114px;
  // width: 200px;
  min-width: 175px;
  border-radius: 10px;
  padding: 10px;
  margin: 12px 16px;
`;

const UserListAreaEmpty = styled.div`
  background: #cbbbe7;
  min-height: 114px;
  min-width: 175px;
  border-radius: 10px;
  padding: 10px;
  margin: 12px 16px;
  display: flex;
  align-items: center;
`;

const UserItem = styled.div`
  background: ${(p) => (p.getMyUser ? "#152B69" : "#fff")};
  color: ${(p) => (p.getMyUser ? "#fff" : "#000")};
  padding: 8px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
`;

const UserInput = styled(TextField)`
  margin-top: 8px;
`;

const UserInputControled = styled(TextField)`
  margin-top: 8px;

  .MuiInputBase-root {
    height: 25px; // Adjust the height as needed
    padding: 0 14px; // Reduce padding to decrease height

    .MuiInputBase-input {
      font-size: 0.875rem; // Adjust font size to match the smaller input
    }
  }

  .MuiInputLabel-root {
    font-size: 0.875rem; // Adjust label font size to match
  }

  .MuiOutlinedInput-root {
    padding: 0;
    position: relative;
  }
`;

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
})(TextField);

const STATIONS = [
  { label: "LEE", enabled: true, custom: false },
  { label: "ESCRIBE", enabled: true, custom: false },
  { label: "ESCUCHA", enabled: true, custom: false },
  { label: "DEBATE", enabled: true, custom: false },
  { label: "INVESTIGA", enabled: true, custom: false },
];

const StudentGroupArea = ({
  setNumberOfGroups,
  numberOfGroups,
  createStudentGroup,
  studentGroup,
  resetGroup,
  dimensions,
  isTeacher,
  sharedData,
  setStudentOfCurrentClass,
  currentClass,
  studentOfCurrentClass,
  currentUser,
}) => {
  const classes = useStyles();
  const [groups, setGroups] = useState(studentGroup);
  const [dragging, setDragging] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [newUser, setNewUser] = useState({});
  const [showInput, setShowInput] = useState({});

  useEffect(() => {
    setGroups(studentGroup);
  }, [studentGroup]);
  const handleDragStart = (e, user, groupIndex) => {
    const dragData = { userId: user.id, groupIndex };
    setDragging(user.id);
    e.dataTransfer.setData("application/json", JSON.stringify(dragData));
    e.target.style.opacity = "0.5";
  };
  const toggleInput = (groupIndex) => {
    setShowInput((prev) => ({ ...prev, [groupIndex]: !prev[groupIndex] }));
  };
  const handleDragEnd = (e) => {
    setDragging(null);
    setExpandedGroup(null);
    e.target.style.opacity = ""; // Reset visual cue
  };

  const handleDragOver = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex);
  };

  const handleDragEnter = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex); // Same as DragOver, to ensure consistency
  };
  const handleDragLeave = (e, groupIndex) => {
    // Optional: Only collapse if leaving the container, not moving to another child
    if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
      setExpandedGroup(null);
    }
  };
  const handleDrop = (e, groupIndex) => {
    e.preventDefault();
    const dropData = JSON.parse(e.dataTransfer.getData("application/json"));
    if (dropData.groupIndex !== groupIndex) {
      const sourceGroup = groups[dropData.groupIndex].filter(
        (user) => user.id !== dropData.userId
      );
      const droppedUser = groups[dropData.groupIndex].find(
        (user) => user.id === dropData.userId
      );
      const targetGroup = [...groups[groupIndex], droppedUser];
      const newGroups = groups.map((group, index) => {
        if (index === dropData.groupIndex) return sourceGroup;
        if (index === groupIndex) return targetGroup;
        return group;
      });

      setGroups(newGroups);
    }
    setExpandedGroup(null);
    setDragging(null);
  };
  const handleAddNewUserToGroup = (groupIndex) => {
    if (newUser[groupIndex]) {
      const updatedGroups = groups.map((group, index) => {
        if (index === groupIndex) {
          return [...group, newUser[groupIndex]];
        }
        return group;
      });
      setGroups(updatedGroups);
      // Reset the newUser input for this group
      setNewUser({ ...newUser, [groupIndex]: null });
      const newData = {
        ...newUser[groupIndex],
        id: v4(),
        role: "student",
        classId: currentClass?.id,
      };
      setStudentOfCurrentClass([...studentOfCurrentClass, newData]);
    }
  };
  const handleNewUserChange = (e, groupIndex) => {
    setNewUser({
      ...newUser,
      [groupIndex]: { ...newUser[groupIndex], [e.target.name]: e.target.value },
    });
  };

  const handleTouchCreateGroup = (event) => {
    event.preventDefault();
    createStudentGroup();
  };

  const handleTouchReset = (event) => {
    event.preventDefault();
    resetGroup();
  };

  // Function to handle scrolling when dragging near the edges
  const userAreaRef = useRef(null);
  const handleScroll = (event) => {
    const { clientY } = event;
    const userArea = userAreaRef.current;

    if (!userArea) return;

    const { top, bottom } = userArea.getBoundingClientRect();
    const offset = 10;

    if (clientY - top < offset) {
      userArea.scrollTop -= offset;
    } else if (bottom - clientY < offset) {
      userArea.scrollTop += offset;
    }
  };
  const getNewgroup = sharedData?.group
    ? JSON.parse(sharedData?.group)
    : groups;
  return (
    <>
      {isTeacher && (
        <>  
         <div style={{ marginTop: dimensions?.width > 250 ? "40px": "10px"}} className="student-per-group">Student per Group</div>
        <Rows
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            gap: "5px",
            marginTop: dimensions.width > 250 ? "30px": "10px",
          }}
        >
         
          <InputField
            id="noOfGroup"
            name="noOfGroup"
            // label="Students per group"
            style={{  width: dimensions.width < 299 ? 50 : 83, color: "#000" }}
            InputLabelProps={{
              shrink: true,
              required: true,
              style: { color: "#000" },
              fontSize: "8px",
            }}
            inputProps={{
              shrink: true,
              required: true,
              style: { color: "#000" },
              fontSize: "8px",
            }}
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (e.target.value >= 0) {
                setNumberOfGroups(e.target.value);
              }
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              if (e.target.value >= 0) {
                setNumberOfGroups(e.target.value);
              }
            }}
            value={numberOfGroups}
            className={`${classes.noSpinners} group-input-label`}
          />
          <EditListOption
            style={{ width: dimensions?.width < 299 && "76px"}}
            onClick={createStudentGroup}
            onTouchStart={handleTouchCreateGroup}
          >
            Generate
          </EditListOption>
          <ResetButton style={{ width: dimensions?.width < 299 && "47px"}} onClick={resetGroup} onTouchStart={handleTouchReset}>
            Reset
          </ResetButton>
        </Rows>
        </>
      )}
      <UserArea ref={userAreaRef} onDragOver={handleScroll}>
        {isEmpty(getNewgroup) ? (
          <UserListAreaEmpty
            className="gradient-blue-pink"
            style={{
              justifyContent: "center",
            }}
          >
            <span style={{ color: "white", fontSize: 18 }}>Not available.</span>
          </UserListAreaEmpty>
        ) : (
          <>
            {map(getNewgroup, (item, groupIndex) => (
              <UserListArea
                style={{ width: dimensions?.width < 505 ? "100%" : "200px" }}
                key={groupIndex}
                className={
                  expandedGroup === groupIndex ? "expandOnDragOver" : ""
                }
                onDrop={(e) => handleDrop(e, groupIndex)}
                onDragOver={(e) => handleDragOver(e, groupIndex)}
                onDragEnter={(e) => handleDragEnter(e, groupIndex)}
                onDragLeave={(e) => handleDragLeave(e, groupIndex)}
              >
                {map(item, (user) => {
                  const MyUser = user?.id === currentUser?.id;
                  return (
                    <UserItem
                      getMyUser={MyUser}
                      key={user.id}
                      className={dragging === user.id ? "hidden" : ""}
                      style={{ cursor: !isTeacher ?  "default" : "move" }}
                      draggable={isTeacher ? true : false}
                      onDragStart={(e) => handleDragStart(e, user, groupIndex)}
                      onDragEnd={handleDragEnd}
                    >
                      {`${user.firstName || ""} ${user.lastName || ""}`}
                    </UserItem>
                  );
                })}
                {!showInput[groupIndex] && isTeacher && (
                  <IconButton
                    color="primary"
                    onClick={() => toggleInput(groupIndex)}
                    onTouchStart={() => toggleInput(groupIndex)}
                  >
                    <AddIcon />
                  </IconButton>
                )}
                {showInput[groupIndex] && (
                  <div>
                    <UserInput
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      variant="outlined"
                      size="small"
                      style={{ color: "white" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.firstName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <UserInput
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      variant="outlined"
                      size="small"
                      style={{ color: "white", marginTop: "10px" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.lastName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: "10px", borderRadius: "50%" }}
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        handleAddNewUserToGroup(groupIndex);
                        toggleInput(groupIndex); // Hide inputs after adding
                      }}
                      onTouchStart={() => {
                        handleAddNewUserToGroup(groupIndex);
                        toggleInput(groupIndex); // Hide inputs after adding
                      }}
                    />
                    {/* <IconButton
                          color="primary"
                          
                          onClick={() => {
                              handleAddNewUserToGroup(groupIndex);
                              toggleInput(groupIndex); // Hide inputs after adding
                          }}
                      >
                          <CheckIcon />
                      </IconButton> */}
                  </div>
                )}
              </UserListArea>
            ))}
          </>
        )}
      </UserArea>
    </>
  );
};

const WidgetsGroup = ({ currentClass, sharableWidget }) => {
  const [editorFocus, setEditorFocus] = useState(false);
  const timeoutRef = useRef();
  const [showAccordin, setShowAccordin] = useState(true);
  const [isStudentGenerated, setStudentGenerated] = useState(false);
  const usersByClass = useUsersByClass();
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets } = useCollectionWidgets();
  const isTeacher = get(currentUser, "role") === "teacher";
  const [studentGroup, setStudentGroup] = useState([]);
  const [stations, setStations] = useState(STATIONS);
  const [numberOfGroups, setNumberOfGroups] = useState(0);
  const [studentOfCurrentClass, setStudentOfCurrentClass] = useState(
    usersByClass[currentClass.id]
  );
  const [studentMinimized, setStudentMinimized] = useState(false);
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "new_group"
  );
  const filterShareGroup = find(
    sharableWidget,
    (obj) => obj?.widget_type === "new_group"
  );
  const [dimensions, setDimensions] = useState({});


  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (usersByClass && has(currentClass, "id")) {
      setStations(STATIONS);
    }
  }, [usersByClass]);

  const createStudentGroup = () => {
    setStudentGenerated(true);
    if (usersByClass) {
      // const studentOfCurrentClass = usersByClass[currentClass.id];
      if (Array.isArray(studentOfCurrentClass) && numberOfGroups > 0) {
        const studentOfCurrent = chunk(
          shuffle(studentOfCurrentClass),
          numberOfGroups
        );
        setStudentGroup(studentOfCurrent);
        if (filterShareGroup?.status) {
          const dataShare = {
            ...filterShareGroup?.data,
            group: JSON.stringify(studentOfCurrent),
          };
          const editShareData = {
            ...filterShareGroup,
            data: dataShare,
          };
          createCollectionWidgets(editShareData);
        }
      } else {
        setStudentGroup([]);
      }
    } else {
      setStudentGroup([]);
    }
  };

  const onClose = async () => {
    if(!isTeacher){
      setStudentMinimized(true);
      return;
    }
    await onResetGroup();
    const closeShareData = {
      ...filterShareGroup,
      status: false,
      data: {},
    };
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    if (filterShareGroup?.status) {
      createCollectionWidgets(closeShareData);
    }
    setStudentOfCurrentClass(usersByClass[currentClass.id]);
  };

  const shareGroup = () => {
    if (!filterShareGroup?.id && studentGroup?.length > 0) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "new_group",
        status: true,
        data: {
          group: JSON.stringify(studentGroup),
        },
      };
      createCollectionWidgets(shareData);
    }
  };

  const handleTouchClose = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchShare = (event) => {
    event.preventDefault();
    shareGroup();
  };

  const onResetGroup = () => {
    if (filterShareGroup?.status) {
      const dataShare = {
        ...filterShareGroup?.data,
        group: JSON.stringify([]),
      };
      const editShareData = {
        ...filterShareGroup,
        data: dataShare,
      };
      createCollectionWidgets(editShareData);
    }
    setStudentGenerated(false);
    setNumberOfGroups(0);
    setStudentGroup([]);
  };

  const sharedData = filterShareGroup?.data || {};

  const handleTouchStart = (event) => {
    event.preventDefault();
    setEditorFocus(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setEditorFocus(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {((filterByWidget?.view && filterByWidget?.menu === "new_group") ||
        filterShareGroup?.id && !studentMinimized) && (
        <Container className="allCheckListSEction wigetsContainer">
          <div className="mainCheckList">
            <ResizeAndDragabble
              defaultValue={{
                x: 150,
                y: 205,
                width: windowSize?.width >= 768 ? 468 : 215,
                height:windowSize?.width >= 768 ?  555 : 255,
              }}
              aspectRatio={true}
              showAccordin={showAccordin}
              minWidth={215}
              minHeight={255}
              page={"group"}
              resize={(data) => setDimensions(data)}
              onFocusValue={editorFocus}
            >
              <div
                style={{
                  backgroundColor: showAccordin && "white",
                  position: "relative",
                  boxShadow: showAccordin && "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                  height: "100%",
                  width: "100%",

                  borderRadius: "13px",
                  overflow: isTeacher
                    ? studentGroup.length !== 0
                      ? "hidden auto"
                      : "hidden"
                    : "auto",
                  // overflow: "scroll",
                  overflowX: "hidden",
                  marginBottom: "20px",
                }}
                className="station-cancel"
              >
                
                  <CancelIcon
                    onClick={() => onClose()}
                    onTouchStart={handleTouchClose}
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      right: 7,
                      top: 14,
                      fontSize: 18,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                
                {isTeacher && (
                  <div>
                    <span
                      disabled={studentGroup?.length === 0}
                      onClick={() => shareGroup()}
                      onTouchStart={handleTouchShare}
                      style={{
                        marginLeft: "5px",
                        position: "absolute",
                        right: 34,
                        top: 15,
                        cursor: "pointer",
                      }}
                    >
                      {!filterShareGroup?.status ? (
                        <ShareIcon style={{ color: "white", fontSize: 16 }} />
                      ) : (
                        <AnimatedCheckMark />
                      )}

                      {/* <AntdButton disabled={studentGroup?.length === 0} type="primary" style={{ borderRadius: 10, minWidth: 60, backgroundColor: filterShareGroup?.status ? COLOR.greentrue : COLOR.green100, border: "0px" }}>
                        {filterShareGroup?.status ? 'Sharing' : 'Share'}
                      </AntdButton> */}
                    </span>
                  </div>
                )}

                <div
                  className={`checkListFirstTxt w-full bg-blue-10 h-44px pt-17px pb-14px ${
                    showAccordin ? "" : "br-20px"
                  } `}
                >
                    <AccordinButton
                      open={showAccordin}
                      onClose={() => setShowAccordin((prev) => !prev)}
                      shareableWidget = {sharableWidget}
                    />
                  <span className="text-white text-ft12-700">Group</span>
                </div>
                {showAccordin && (
                  <div onTouchStart={handleTouchStart}
                    style={{
                      paddingLeft: dimensions.width < 250 ? "0" : "20px",
                      paddingRight:  dimensions.width < 250 ? "0" : "20px",
                      minHeight: !isEmpty(studentGroup) && "40vh",
                    }}
                  >
                    
                    <div
                      style={{
                        display: isTeacher ? "grid" : null,
                        placeItems: !isStudentGenerated && "center",
                      }}
                    >
                      <StudentGroupArea
                        dimensions={dimensions}
                        setNumberOfGroups={setNumberOfGroups}
                        createStudentGroup={createStudentGroup}
                        studentGroup={studentGroup}
                        resetGroup={() => {
                          onResetGroup();
                        }}
                        numberOfGroups={numberOfGroups}
                        isTeacher={isTeacher}
                        sharedData={sharedData}
                        currentClass={currentClass}
                        currentUser={currentUser}
                        setStudentOfCurrentClass={setStudentOfCurrentClass}
                        studentOfCurrentClass={studentOfCurrentClass}
                      />
                    </div>
                  </div>
                )}
              </div>
            </ResizeAndDragabble>
          </div>
        </Container>
      )}
    </>
  );
};

export default WidgetsGroup;
