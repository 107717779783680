//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const classQuizzesInitial = [];

export const classQuizzes = (state = classQuizzesInitial, action) => {
    switch (action.type) {
        case ActionTypes.classQuizzes.listClassQuizzesSuccess:
            return action.payload;
        case ActionTypes.classQuizzes.createClassQuizSuccess:
            return [action.payload, ...state];
        case ActionTypes.classQuizzes.deleteClassQuizSuccess:
            return state.filter(x => x.id !== action.payload.id && x.quizId !== action.payload.id);
        case ActionTypes.classQuizzes.updateClassQuizSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload };
                } else {
                    return x;
                }
            });
        case ActionTypes.classQuizzes.listClassQuizzesFail:
        case ActionTypes.classQuizzes.createClassQuizFail:
        case ActionTypes.classQuizzes.deleteClassQuizFail:
        case ActionTypes.classQuizzes.uodateClassQuizFail:
        default:
            return state;
    }
};
