//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { AddToPhotos } from "@material-ui/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
//  -- Components ---------------------

//  -- Constants ----------------------
import { Modal } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { Col, Row } from "antd";
import { find, findIndex, get } from "lodash";
import queryString from 'query-string';
import { FileUploader } from "react-drag-drop-files";
import { useHistory } from 'react-router-dom';
import { useAllBook } from "store/books/selectors";
import COLOR from 'utils/constants/color';
const fileTypes = ["JPG", "PNG","JPEG"];

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiInput-underline:before': {
        borderBottomColor: (props) => (props.hasBorder ? 'black' : 'transparent'), // default bottom border color
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: (props) => (props.hasBorder ? 'black' : 'transparent'), // bottom border color on hover
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: (props) => (props.hasBorder ? 'black' : 'transparent'), // bottom border color when focused
      },
      '& .MuiInputBase-root': {
        padding: theme.spacing(1),
        width:"100%"
      },
    },
    input: {
      fontSize: 16,
    },
  }));

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  position: relative;
  justify-content: center;
  padding: 20px;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const CloseBtn = styled.div`
  background-color: rgb(204, 80, 71);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
`;

const PrintWrapper = styled.div`
  display: flex;
`;

const Images = styled.img`
  background-color: ${COLOR.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  object-fit: cover;
  height: 600px;
   height: 96vh;
`;

const ButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  color: ${COLOR.black600};
  cursor: pointer;
  height: 40px;
  left: ${(p) => `${p.left}px`};
  outline: none;
  position: absolute;
  right: ${(p) => `${p.right}px`};
  top: 50%;
  transition: all 300ms ease;
  width: 40px;
  z-index: 1;
`;
const AnswerWindowWrapper = styled.div`
   position: relative;
   width: 100%;
`;
const FileUploadArea = styled.div`
    height: 100%;
    border: 1px dashed gray;
    padding: 5px 12px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
`;
const Column = styled(Col)`
display: flex;
    justify-content: center;
    align-items: center;
}
`;
const Text = styled.div`
    font-size: 12px;
`;
const UploadedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
`;
function ImageUploader({  positions }) {
    const [pagePoster, setpagePoster] = useState(null)
    const max_height = positions.max_height ? { maxHeight: positions.max_height } : {};
    const min_height = positions.min_height ? { minHeight: positions.min_height } : {};
    return (
        <ImageContainer onContextMenu={(e) => { }} style={{
            position: 'absolute',
            width: `calc(${positions.width} * 100%)`,
            height: positions.height ? `calc(${positions.height} * 100%)` : '50px',
            left: `calc(${positions.left} * 100%)`,
            top: `calc(${positions.top} * 100%)`,
            justifyContent: "center",
            alignItems: 'center',
            display: "flex",
            ...max_height,
            ...min_height
        }}>
             {pagePoster? <UploadedImage src={pagePoster}/>:null}
                <FileUploader
                    handleChange={(file)=>{setpagePoster(URL.createObjectURL(file))}}
                    name="file"
                    types={fileTypes}

                >
                <FileUploadArea>
                    <Row className="box box-1" style={{ display: "flex", gap: 4 }}>
                        <Column >
                            <AddToPhotos color="primary" />
                        </Column>
                        <Column>
                            <Text>
                                Upload here
                            </Text>
                        </Column>
                    </Row>
                    <Row>
                        <Text style={{ fontSize: 8 }}>
                            {fileTypes.join(",")}
                        </Text>
                    </Row>

                </FileUploadArea>
                </FileUploader>
        </ImageContainer>
    )
}

const InputArea = ({ positions }) => {
    const classes = useStyles({ hasBorder: typeof positions.bottom_border === "boolean" ? positions.bottom_border : true });

    if (positions.input_type === "image") {
        return (<ImageUploader positions={positions} />)
    }
    const max_height = positions.max_height ? { maxHeight: positions.max_height } : {};
    const min_height = positions.min_height ? { minHeight: positions.min_height } : {};
    return (
        <TextField
            className={classes.root}
            InputProps={{ classes: { input: classes.input } }}
            InputLabelProps={{ shrink: true }}
            multiline={positions.text_length === "multi_line"}
            inputProps={{ style:{
                margin:0,
            }}}
            style={{
                position: 'absolute',
                width: `calc(${positions.width} * 100%)`,
                height: positions.height ? `calc(${positions.height} * 100%)` : 'auto',
                left: `calc(${positions.left} * 100%)`,
                top: `calc(${positions.top} * 100%)`,
                justifyContent: "center",
                ...max_height,
                ...min_height,
                overflow:"auto",
                alignItems: 'center',
            }}
            placeholder={positions.placeholder || ''}
            label=""
        />
    );
};
const AnswerWindow = ({ src, input_position }) => {
    const [inputs] = useState(input_position || []);
    return (
        <AnswerWindowWrapper>
            <Images src={src} />
            {inputs.map((input, index) => (
                <InputArea key={index} positions={input} />
            ))}
        </AnswerWindowWrapper>
    );
};
  

const GraphicAssignmentPreview = ({
    open,
    setOpen,
    assignment,
    page_id,
    bookId
}) => {
    const chapter = '7';
    const backBtnRef = useRef(null);
    const forwardBtnRef = useRef(null);
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const booksAllList = useAllBook()

    const [alertSakeBar, setAlertSakeBar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const selected_book = useMemo(() => find(booksAllList, { id: bookId }), [booksAllList]);
    const graphic_book = useMemo(() => find(booksAllList, { id: chapter }), [booksAllList]);
    const select_book_pages = useMemo(() => get(selected_book, "pages", []), [selected_book]);
    const graphic_book_pages = useMemo(() => get(graphic_book, "pages", []), [graphic_book]);
    const pageId = useMemo(() => get(assignment, "assignment_page"), [assignment])
    const currentPageResourse = useMemo(() => get(find(graphic_book_pages, (obj) => obj.pageId == pageId), "resource"), [assignment,graphic_book_pages])
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const activePageResource = useMemo(() => select_book_pages[currentPageIndex]?.resource, [currentPageIndex, select_book_pages]);
    const input_position = useMemo(() => get(assignment, "input_position", []) || [], [assignment])

    function checkKey(e) {
        e = e || window.event;
        if (e.keyCode == '37') {
            if (backBtnRef.current && !backBtnRef.current.disabled) {
                backBtnRef.current.click()
            }

        }
        else if (e.keyCode == '39') {
            if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                forwardBtnRef.current.click()
            }

        }

    }

    useEffect(() => {
        const index = findIndex(select_book_pages, (obj) => obj.pageId == page_id)
        setCurrentPageIndex(index);
    }, [select_book_pages, pageId])

    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", checkKey)
        }
    }, [])

    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: "", severity: "success" });
    };

    const onPageChange = (direction) => {
        if (direction === "right" && currentPageIndex < select_book_pages.length - 1) {
            setCurrentPageIndex(currentPageIndex + 1);
        } else if (direction === "left" && currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={alertSakeBar.open}
                autoHideDuration={3000}
                onClose={closeAlert}
            >
                <Alert
                    onClose={() => {
                        setAlertSakeBar({ open: false, message: "" });
                    }}
                    severity={alertSakeBar.severity}
                >
                    <strong>ALERT:</strong> {alertSakeBar.message}
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                className={"lessonPlanPopUp"}
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                }}
            >
                <PlanWrapper>
                    <PlanContainer>
                        <CloseBtn
                            onClick={(e) => {
                                e.preventDefault();
                                setOpen(false);
                            }}
                        >
                            <Close
                                style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
                            />
                        </CloseBtn>
                        <ButtonContainer
                            ref={backBtnRef}
                            disabled={currentPageIndex === 0}
                            onClick={(e) => {
                                e.preventDefault();
                                onPageChange("left");
                            }}
                            left={8}
                        >
                            <ArrowBackIos style={{ fontSize: "40px" }} />
                        </ButtonContainer>
                        <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={currentPageIndex >= select_book_pages.length - 2}
                            onClick={() => {
                                onPageChange("right");
                            }}
                            right={0}
                        >
                            <ArrowForwardIos style={{ fontSize: "40px" }} />
                        </ButtonContainer>
                        <Container>
                            <PrintWrapper>
                                <Row style={{ width: "100%", height: "100%", maxWidth: "100%", padding: 0 }}>
                                    <Col lg={12} md={12} xl={12} xs={24} >
                                        <Images
                                            src={activePageResource}
                                        />
                                    </Col>
                                    <Col lg={12} md={12} xl={12} xs={24} >
                                        <AnswerWindow src={currentPageResourse} input_position={input_position} />
                                    </Col>
                                </Row>
                            </PrintWrapper>
                        </Container>
                    </PlanContainer>
                </PlanWrapper>
            </Modal>
        </>
    );
};

export default GraphicAssignmentPreview;
