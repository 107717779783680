//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash"
//  -- Actions ------------------------
import * as UserClassActions from './actions';
import * as Sentry from "@sentry/react";
import { handleGetClass  } from "store/classes/thunks"
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create user class --------------
export const handleCreateUserClass = (data) => async(dispatch) => {
    await dispatch(UserClassActions.createUserClass());

    try {
        const userclass = await API.post('userClassesRDS', `/userClasses`, {
            body: { ...data }
        });
        // if(hasUpdate){
        //     await dispatch(UserClassActions.createUserClassSuccess(userclass));
        // }
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(UserClassActions.createUserClassFail(error));
    }
};



//  -- List class users ---------------
export const handleListClassUsers = (classID) => async(dispatch) => {
    if(!classID) return;
    await dispatch(UserClassActions.listClassUsers());

    try {
        let  filteredUsersRDS =[];
        const userclasss = await API.get('userClassesRDS', `/usersByClass/${classID}`);
        if(Array.isArray(userclasss)){
            filteredUsersRDS = userclasss.filter(x => x.ucId != undefined);
        }
        await dispatch(UserClassActions.listClassUsersSuccess(filteredUsersRDS));
        return filteredUsersRDS;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(UserClassActions.listClassUsersFail(error));
    }
};
export const handleHideShowClasses = (userId,classID, status) => async (dispatch) => {
    if (!classID) return;

    try {
        const userclasssStatus = await API.post('classesRDS', `/archivehandller`, {
            body: {
                id: classID,
                status: status
            }
        });
        await dispatch(handleListUserClasses(userId));
        return userclasssStatus;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
}
export const handleHideShowClassBooks = (userClassId,archived_books,classId) => async (dispatch) => {
    try {
        const userclasssStatus = await API.post('userClassesRDS', `/updateArchiveBooks`, {
            body: {
                id:userClassId,
                archived_books
            }
        });
        await dispatch(handleGetClass(classId));
        return userclasssStatus;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
}

export const handleHideOrShowParticularClassBooks = (payload,classId) => async (dispatch) => {
    try {
        const userclasssStatus = await API.post('userClassesRDS', `/updateHideShowBooks`, {
            body:payload
        });
        await dispatch(handleGetClass(classId));
        return userclasssStatus;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
}
export const handleListUserByClassForTeacher = (teacherId) => async(dispatch) => {
    if(!teacherId) return;
    await dispatch(UserClassActions.listClassUsers());

    try {
        const studentsByClass = await API.get('userClassesRDS', `/usersByteacher/${teacherId}`);
        if(studentsByClass){
            await dispatch(UserClassActions.setUsersByClass(studentsByClass));
        }
        return studentsByClass;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};

//  -- List user classes --------------
export const handleListUserClasses = (userID) => async(dispatch) => {
    await dispatch(UserClassActions.listUserClasses());

    try {
        let userClass=[]
        const userClassesRDS= await API.get('userClassesRDS', `/listUserClasses/${userID}`);
        if(Array.isArray(userClassesRDS)){
            userClass = userClassesRDS
        }
        await dispatch(UserClassActions.listUserClassesSuccess(userClass));
        return userClass;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(UserClassActions.listUserClassesFail(error));
        return []
    }
};

export const getClassUsers =  async(classId) => {
    try {
        let userClass=[]
        const userClassesRDS=  await API.get('userClassesRDS', `/usersByClass/${classId}`)
        if(Array.isArray(userClassesRDS)){
            userClass = userClassesRDS
        }
        return userClass;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return []
    }
}

export const handleUpdateUserClassStatus = async (id, status) => {
    try {
        await API.put('userClassesRDS', `/updateClassStatus/${id}`, {
            body: { active: status }
        });
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};
export const handleCreateOrUpdateClassConfig = (data) =>async(dispatch) => {
    try {
     const config =    await API.post('userClassesRDS', `/createOrUpdateClassConfig`, {
            body:data
        });
        if(config&&config.classId){
            dispatch(UserClassActions.updateClassConfig(config))
        }

    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};
export const handleDeleteUserClassList = async (list) => {
    try {
        await API.post('userClassesRDS', `/userClassesByList`, {
            body: list
        });
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};
export const handleDeleteUserClassByUserIdAndClassId = async (classId,userId) => {
    try {
        await API.del('userClassesRDS', `/deleteByClassAndUser/${classId}/${userId}`);
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};



export const handleUpdateClasseOrder = (data) => async(dispatch) => {
    try {
        const userclass = await API.post('userClassesRDS', `/updateOrder`, {
            body: data
        });
      await dispatch(UserClassActions.updateUserClassesOrder(data));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};
