//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------


export const handleListFavouriteVideo = (data) => async (dispatch) => {
    try {
        const video = await API.post('favourite_video', `/list`, {
            body: data
        });
        if (video) {
            await dispatch(Actions.listFavouriteVideo(video));
        }
        return video;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleCreateFavouriteVideo = (data) => async (dispatch) => {
    try {
        const videos = await API.post('favourite_video', `/create`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.updateFavouriteVideo(videos));
        } else {
            await dispatch(Actions.createFavouriteVideo(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleListMostUsedVideo = () => async (dispatch) => {
    try {
        const response = await API.get('favourite_video', `/mostUsedVideo`);
        if (response) {
            // await dispatch(Actions.listOfMostUsedVideo(response));
        }
        return response[0];
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }

    
};

export const handleListMostUsedEbookById = (id) => async (dispatch) => {
    try {
        const response = await API.get('favourite_video', `/mostUsedEbook/${id}`);
        if (response) {
            // await dispatch(Actions.listOfMostUsedVideo(response));
            const data = response?.data;
            return data; 
        }
            
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }

    
};