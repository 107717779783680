//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";
import moment from "moment-timezone"
var servertimeinterval;
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const handleGetServertime = () => async (dispatch) => {
	try {
		const servetime = await API.get('assignmentsRDS', `/servertime`)
		if (servetime) {
			await dispatch(Actions.createServertime(servetime));
			let t = moment(servetime)
			clearInterval(servertimeinterval)
			servertimeinterval = setInterval(async () => {
				t.add(10, "seconds")
				await dispatch(Actions.createServertime(t.toISOString()));
			}, 10000)
		}
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};

