//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import _,{ find, flattenDeep, forEach, groupBy, isEmpty ,filter} from 'lodash';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

//  -- Components ---------------------
import { Col, Drawer, Dropdown, Menu, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Add, ArrowForwardIos } from '@material-ui/icons';
import { Button, TextField } from '@material-ui/core';
import LoadingWrapper from 'components/LoadingWrapper';
import SentryWrap from 'components/Sentry';
import TeacherClass from 'components/TeacherClass';
import { Business, MoreVert } from '@material-ui/icons';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------
import Announcements from 'features/Announcements';
import DistrictVisuals from 'features/DistrictVisuals';
import PostForm from 'features/forms/Post';
import UserForm from 'features/forms/User';


//  -- Thunks -------------------------
import { handleListTeacherClasses } from 'store/classes/thunks';
import { handleListUserBooks } from 'store/userBooks/thunks';
import { getOrganizationById, getOrganizationByParent, handleListOrgsByParent } from 'store/organizations/thunks';
import { handleListOrgUsers } from 'store/users/thunks';
import { handleUpdateUser } from 'store/users/thunks';
import { handleFetchDistrictData } from 'store/visuals/thunks';
import { handleCheckUsername } from 'store/users/thunks';
import { handleListAllBooks } from 'store/books/thunks';
import getSettledPromisses from 'utils/methods/getSettledPromisses';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Border = styled.div`
    border: 1px dashed ${COLOR.black800};
    height: 100%;
    margin: 0 auto;
    width: 1px;
`;

const Btn = styled.button`
    background-color: transparent;
    border: none;
    border-top: ${p => p.active && `1px solid ${COLOR.green100}`};
    color: ${p => p.active ? COLOR.green100 : COLOR.black500};
    cursor: pointer;
    font-weight: 600;
    margin-left: 16px;
    outline: none;
    padding: 4px 1px 0px 1px;
    text-transform: uppercase;

    &:last-child {
        padding-right: 0px;
    }

    &:hover {
        color: ${COLOR.green100};
    }
`;

const ButtonClear = styled.button`
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    outline: none;
    padding: 0px;
`;

const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black200};
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 2px 0px;
    text-transform: uppercase;
`;

const CenterRow = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction; row;
    width: 100%;
`;

const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const Container = styled.div`
    padding: 24px 0px;
`;

const ContainerHeader = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 4px;
    text-transform: uppercase;
`;

const CustomBtn = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black300};
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 4px;
    outline: none;

    &:hover {
        color: ${COLOR.green100};
    }
`;

const CustomCol = styled.div`
    margin: 0px;
    width: 100%;

    @media (min-width: 992px) {
        margin: ${p => p.margin};
        width: calc(50% - 24px);
    }
`;

const CustomRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 992px) {
        flex-direction: row;
    }
`;

const DrawerContainer = styled.div`
    height: 100%;
    padding: 40px;
    width: 100%;
`;

const ExpandButton = styled.button`
    background-color: transparent;
    border: 1px solid ${COLOR.green100};
    color: ${COLOR.green100};
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin: 16px auto;
    outline: none;
    padding: 8px 12px;
`;

const Image = styled.img`
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: auto;
    margin-top: 40px;
    width: 100%;
`;

const MobileMenu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;

    @media (min-width: 992px) {
        display: none;
    }
`;

const Role = styled.span`
    background-color: ${COLOR.black800};
    border-radius: 2px;
    color: ${COLOR.black300};
    font-size: 10px;
    font-weight: 600;
    padding: 2px 6px;
    text-transform: uppercase;
`;

const Center = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Section = styled.div`
    margin-bottom: 64px;
    width: 100%;

    &:first-child {
        margin-bottom: 24px;
    }
`;

const SectionHeader = styled.div`
    align-items: flex-end;
    border-bottom: 1px dashed ${COLOR.black800};
    color: ${COLOR.black300};
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight 600;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 4px;
    text-transform: uppercase;
`;

const Title = styled.span`
    color: ${COLOR.black100};
    display: inline-block;
    font-size: 16px;
    font-weight: 300;

    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

const TitleContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black700};
    display: inline-flex;
    flex-direction; row;
    justify-content: space-between;
    width: 100%;
`;

const User = styled.div`
    align-items: center;
    background-color: ${p => p.index % 2 === 0 ? COLOR.black900 : 'transparent'};
    border: 1px dashed ${COLOR.black700};
    border-radius: 2px;
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;
    padding: 6px 12px 6px 4px;
    width: 100%;
`;

const Wrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const WrapMenu = styled.div`
    display: none;

    @media (min-width: 992px) {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
`;

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const VisualContainer = styled.div`
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 85%), 0 0px 2px rgb(0 0 0 / 85%);
    flex-direction: row;
    height: ${p => `${p.height}px` || '100%'};
    margin-bottom: ${p => `${p.marginBottom}px`};
    padding: 16px;
    width: 100%;
`;

const VisualText = styled.span`
    color: white;
    display: block;
    font-size: 64px;
    font-weight: 600;
    line-height: 88px;
    text-align: center;

    @media (min-width: 992px) {
        font-size: 80px;
    }
`;

const CustomButton = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.green100};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    outline: none;
`;

const TabButton = styled.button`
    background-color: transparent;
    border: none;
    border-bottom: 2px solid ${p => p.active ? COLOR.green100 : 'transparent'};
    /* color: ${p => p.active ? COLOR.black100 : COLOR.black500}; */
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 16px 24px;
    text-transform: uppercase;
    transition: all 300ms ease;
    margin-right: 5px;

    &:hover {
        border-bottom: 2px solid ${COLOR.green700};
        color: white;
    }
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Director admin container
//
//  -- Description
//  Director admin dashboard
//
//  -- Props
//  ...
//
const DirectorContainer = ({
    currentUser,
    users,
    booksAllList,
    schools,
    userBooksList
}) => {
    let ufilters =filter(users,u => u.role === 'teacher' || u.role === 'student');
    // Array Books
    let booksByUser = [];
    if(_.get(userBooksList,"books.length",) > 0){
        let booksU =_.get(userBooksList,"books",[]);
        for(let b in booksU){
            let finded = find(booksAllList,e => e.id == booksU[b].bookId);
            if(finded&&finded.description != undefined){
                booksByUser.push(finded)
            }
        }
    }
    let usersfilter = [];
    for(let b in ufilters){
        let finded = usersfilter.find(e => e.id == ufilters[b].id);
        if(finded == undefined){
            usersfilter.push(ufilters[b])
        }
    }
    usersfilter.sort((a,b) => (a.lastName.trim().toLowerCase()  > b.lastName.trim().toLowerCase() ) ? 1 : ((b.lastName.trim().toLowerCase()  > a.lastName.trim().toLowerCase() ) ? -1 : 0));


    const history = useHistory();
    const dispatch = useDispatch();
    const [dataTab, setDataTab] = useState('teacher');
    const [loading, setLoading] = useState(true);
    const [classData, setClassData] = useState();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [orgData, setOrgData] = useState();
    const [showMore, setShowMore] = useState(true);
    const [userData, setUserData] = useState({});
    const [userDrawer, toggleUserDrawer] = useState(false);
    const [userFilter, setUserFilter] = useState('');
    const [teacherData, setTeacherData] = useState([]);
    const [groupedClasses, setGroupedClasses] = useState(null);
    const [announcementDrawer, toggleAnnouncementDrawer] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [editUserAdmin, setEditUserAdmin] = useState(false);
    const [viewUserAdmin, setViewUserAdmin] = useState(false);
    const [licensesOrg, setLicensesOrg] = useState(0);
    const [studentCountOrg, setStudentCountOrg] = useState(0);
    const [maxCountOrg, setMaxCountOrg] = useState(0);

    let currentOrg = [];

    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            await dispatch(handleListAllBooks());
            await dispatch(handleListUserBooks(currentUser?.id))
            currentOrg = await getOrganizationById(currentUser.orgId);
            await setLicensesOrg(currentOrg.licenses);
            await setStudentCountOrg(currentOrg.studentCount);
            await setMaxCountOrg((currentOrg.maxCount != "undefined" ? currentOrg.maxCount : 0));
            const schools = await dispatch(handleListOrgsByParent(currentOrg.parentId));
            await dispatch(handleListOrgUsers([currentOrg, ...schools]));
        }
        fetchData();
    }, [currentOrg?.id, dispatch])

    useEffect(() => {
        async function fetchData() {
            const orgs = await getOrganizationByParent(currentOrg.orgId);
            let classes = users && await Promise.allSettled(
                users?.map(async x => {
                    if (x.role === 'teacher') {
                        return await dispatch(handleListTeacherClasses(x.id));
                    }
                })
            ).then(getSettledPromisses);
            //console.log('clasess')
            //console.log(classes)
            //console.log('clasess')

            const filteredClasses = filter(classes,x => x);
            const classDataBlob = await dispatch(handleFetchDistrictData(filteredClasses));
            const groupedClasses = groupBy(flattenDeep(filteredClasses), x => x.teacherId);
            for(let x in groupedClasses){
                let ele = groupedClasses[x];
                let filterElements = [];
                for(let y in ele){
                    let finded = filterElements.find(e => e.id == ele[y].id);
                    if(finded == undefined){
                        filterElements.push(ele[y])
                    }
                }
                groupedClasses[x] = filterElements;
            }
            await setGroupedClasses(groupedClasses);
            await setClassData(classDataBlob);
            await setOrgData([...orgs, currentOrg]);
            setLoading(false);
        }
        fetchData();
    }, [dispatch, users])

    useEffect(() => {
        filterUsers();
    }, [userFilter])

    useEffect(() => {
        renderTeachers();
    }, [showMore, groupedClasses])

    const fetchData = async () => {
        currentOrg = await getOrganizationById(currentUser.orgId);
        await setLicensesOrg(currentOrg.licenses);
        await setStudentCountOrg(currentOrg.studentCount);
        await setMaxCountOrg((currentOrg.maxCount != "undefined" ? currentOrg.maxCount : 0));
        const schools = await dispatch(handleListOrgsByParent(currentOrg.parentId));
        await dispatch(handleListOrgUsers([currentOrg, ...schools]));
    };

    const userNav = (user) => {
        return (
            <Menu>
                <Menu.Item onClick={() => {
                    setUserData({
                        editing: true,
                        ...user
                    });
                    setEditUser(true);
                    toggleUserDrawer(true);
                }}>
                    Edit
                </Menu.Item>
            </Menu>
        );
    };

    const userNavAdminUsers = (user) => {
        return (
            <Menu>
                <Menu.Item onClick={() => {
                    setUserData({
                        editing: true,
                        ...user
                    });
                    setEditUserAdmin(true);
                    setViewUserAdmin(true);
                }}>
                    Edit
                </Menu.Item>
            </Menu>
        );
    };

    const updateUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        const nameCheck = await dispatch(handleCheckUsername(userData.username));
        if (!nameCheck) {
            await dispatch(handleUpdateUser(userData.id, userData));
            toggleUserDrawer(false);
            setUserData({});
        }
        setLoading(false);
    };

    const updateUserData = (id, value) => {
        setUserData({
            ...userData,
            [id]: value
        });
    };

    const filterUsers = () => {
        if (userFilter.length > 1) {
            const filtered = filter(usersfilter,user => {
                return (user.firstName?.toLowerCase().indexOf(userFilter.toLowerCase()) > -1 || user.lastName?.toLowerCase().indexOf(userFilter.toLowerCase()) > -1);
            });

            setFilteredUsers(filtered);
        } else {
            setFilteredUsers([]);
        }
    };

    const renderTeachers = () => {
        const data = [];

        if (!isEmpty(groupedClasses)) {
            forEach(groupedClasses, (x, key) => {
                if (showMore) {
                    if (data.length < 5) {
                        data.push({
                            classes: x,
                            teacher: find(users, ['id', key]),
                        })
                    }
                } else {
                    data.push({
                        classes: x,
                        teacher: find(users, ['id', key]),
                    })
                }
            })
        }

        data.sort((a, b) => {
            const nameA = a.teacher.firstName.trim().toLowerCase();
            const nameB = b.teacher.firstName.trim().toLowerCase();

            if (nameA < nameB) { return -1 }
            else if (nameA > nameB) { return 1 }
            else { return 0 }
        })

        setTeacherData(data);
    };

    const toolbarNav = () => {
        return (
            <Menu>
                <Menu.Item>
                    <ButtonClear onClick={() => setDataTab('teacher')}>
                        By Teacher
                    </ButtonClear>
                </Menu.Item>
                <Menu.Item>
                    <ButtonClear onClick={() => setDataTab('adminUsers')}>
                        Admin Users
                    </ButtonClear>
                </Menu.Item>
            </Menu>
        )
    };

    return (
        <>
            <Drawer
                height='100%'
                onClose={() => {
                    toggleUserDrawer(false);
                    setEditUser(false);
                    setUserData({});
                }}
                placement='top'
                visible={userDrawer}
                key='quizNew'
            >
                <CenterContainer>
                    <UserForm
                        userData={userData}
                        editing={editUser}
                        listBooks={booksByUser}
                        onClose={() => {
                            setEditUser(false);
                            //setUser(null);
                            //fetchDistrictData();
                        }}
                        schools={[...schools, currentOrg]}
                    />
                </CenterContainer>
            </Drawer>
            <Drawer
                height='100%'
                onClose={() => toggleAnnouncementDrawer(false)}
                placement='top'
                visible={announcementDrawer}
                key='announcements'
            >
                <DrawerContainer>
                    <SentryWrap>
                        <Announcements header="District Announcements" />
                        <PostForm type='district' />
                    </SentryWrap>
                </DrawerContainer>
            </Drawer>
            <Wrapper>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Container>
                            <Section>
                                <TitleContainer>
                                    <CenterRow>
                                        <Business style={{ marginRight: '10px', marginTop: '-6px' }} />
                                        <Title>Director Overview</Title>
                                    </CenterRow>
                                    {/* <MobileMenu>
                                        <Dropdown overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                                            <ButtonLink onClick={e => e.preventDefault()}>
                                                <MenuOutlined style={{ fontSize: '18px' }} />
                                            </ButtonLink>
                                        </Dropdown>
                                    </MobileMenu> */}
                                </TitleContainer>
                            </Section>
                            <Section>
                                <SectionHeader>
                                    School Licenses
                                </SectionHeader>
                                <LoadingWrapper loading={!classData}>
                                    <SentryWrap>
                                        <Row gutter={[16, 80]}>
                                            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                                <ContainerHeader>Licenses</ContainerHeader>
                                                <VisualContainer style={{backgroundColor: 'rgb(255, 177, 0)'}}>
                                                    <VisualText color>{parseInt(licensesOrg, 10)}</VisualText>
                                                </VisualContainer>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                                <ContainerHeader>Current Student Count</ContainerHeader>
                                                <VisualContainer style={{backgroundColor: 'rgb(3, 152, 158)'}}>
                                                    <VisualText>{parseInt(studentCountOrg, 10)}</VisualText>
                                                </VisualContainer>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                                <ContainerHeader>Licenses Count</ContainerHeader>
                                                <VisualContainer style={{backgroundColor: 'rgb(207, 38, 184)'}}>
                                                    <VisualText>{parseInt(maxCountOrg, 10)}</VisualText>
                                                </VisualContainer>
                                            </Col>
                                        </Row>
                                    </SentryWrap>
                                </LoadingWrapper>
                            </Section>
                            <Section>
                                <SectionHeader>
                                    School Data
                                    <WrapMenu>
                                        <TabButton
                                            style={{backgroundColor: 'rgb(255, 177, 0)'}}
                                            active={dataTab === 'teacher'} onClick={() => setDataTab('teacher')}>
                                                By Teacher
                                        </TabButton>
                                        <TabButton
                                            style={{backgroundColor: 'rgb(207, 38, 184)'}}
                                            active={dataTab === 'adminUsers'} onClick={() => setDataTab('adminUsers')}>
                                                Admin Users
                                        </TabButton>
                                    </WrapMenu>
                                    <MobileMenu>
                                        <Dropdown overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                                            <ButtonLink onClick={e => e.preventDefault()}>
                                                <MenuOutlined style={{ fontSize: '18px' }} />
                                            </ButtonLink>
                                        </Dropdown>
                                    </MobileMenu>
                                </SectionHeader>
                                <LoadingWrapper loading={!classData}>
                                    <SentryWrap>
                                    { dataTab != 'adminUsers' &&(
                                        <DistrictVisuals
                                            data={classData}
                                            dataTab={dataTab}
                                            orgs={orgData}
                                            users={users}
                                        />)}
                                        { dataTab == 'adminUsers' && (
                                            <>
                                                 {!viewUserAdmin && (
                                                     <>
                                                    <CustomButton onClick={() => {
                                                            setUserData({});
                                                            setEditUserAdmin(false);
                                                            setViewUserAdmin(true);
                                                        }}>
                                                        <Add style={{ fontSize: '16px', marginRight: '4px'}} />
                                                        New User
                                                    </CustomButton>
                                                    <br></br>
                                                    {usersfilter?.length > 0 ? (
                                                        usersfilter.map((x, i) => (
                                                        <User key={x.id} index={i}>
                                                            <Wrap>
                                                                <Dropdown overlay={userNavAdminUsers(x)} placement="bottomLeft">
                                                                    <CustomBtn onClick={e => e.preventDefault()}>
                                                                        <MoreVert />
                                                                    </CustomBtn>
                                                                </Dropdown>
                                                                <span>{x.firstName ? `${x.lastName} ${x.firstName}` : x.displayName}</span>
                                                            </Wrap>
                                                            <Role>{x.role}</Role>
                                                        </User>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <CustomButton onClick={() => {
                                                                setUserData({});
                                                                setEditUserAdmin(false);
                                                                setViewUserAdmin(true);
                                                                }}>
                                                                <Add style={{ fontSize: '16px', marginRight: '4px'}} />
                                                                New User
                                                            </CustomButton>
                                                        </>
                                                        )}
                                                    </>
                                                 )}
                                                 {viewUserAdmin && (
                                                    <UserForm
                                                        userData={userData}
                                                        editing={editUserAdmin}
                                                        view={'director'}
                                                        listBooks={booksByUser}
                                                        onClose={() => {
                                                            setEditUserAdmin(false);
                                                            setViewUserAdmin(false);
                                                            //setUser(null);
                                                            fetchData();
                                                        }}
                                                        schools={[...schools, currentOrg]}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </SentryWrap>
                                </LoadingWrapper>
                            </Section>
                            <Section>
                                <CustomRow>
                                    <CustomCol margin="0px 24px 20px 0px" style={{marginBottom: '20px'}}>
                                        <SectionHeader>Teachers</SectionHeader>
                                        <LoadingWrapper loading={loading}>
                                            <SentryWrap>
                                                {teacherData?.map(x => (
                                                    <TeacherClass
                                                        typeView={'director'}
                                                        key={x.teacher.id}
                                                        classes={x.classes}
                                                        teacher={x.teacher}
                                                    />
                                                ))}
                                                {groupedClasses && Object.keys(groupedClasses).length > 5 && (
                                                    <Center>
                                                        <ExpandButton onClick={() => setShowMore(!showMore)}>
                                                            {showMore ? 'Show More' : 'Show Less'}
                                                        </ExpandButton>
                                                    </Center>
                                                )}
                                            </SentryWrap>
                                        </LoadingWrapper>
                                    </CustomCol>
                                    <CustomCol margin="0px 0px 0px 24px">
                                        <SectionHeader>
                                            Director Users
                                        </SectionHeader>
                                        <LoadingWrapper loading={loading}>
                                            <SentryWrap>
                                                <Row gutter={[16, 16]}>
                                                    <Col span={24}>
                                                        <TextField
                                                            id="userFilter"
                                                            placeholder="User search..."
                                                            onChange={e => setUserFilter(e.target.value)}
                                                            variant="outlined"
                                                        />
                                                    </Col>
                                                </Row>
                                                {filteredUsers.map((x, i) => (
                                                    <User key={x.id} index={i}>
                                                        <Wrap>
                                                            <Dropdown overlay={userNav(x)} placement="bottomLeft">
                                                                <CustomBtn onClick={e => e.preventDefault()}>
                                                                    <MoreVert />
                                                                </CustomBtn>
                                                            </Dropdown>
                                                            <span>{x.firstName ? `${x.firstName} ${x.lastName}` : x.displayName}</span>
                                                        </Wrap>
                                                        <Role>{x.role}</Role>
                                                    </User>
                                                ))}
                                                <Image src="https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/Logos%2FLogo%202.png?alt=media&token=d9ba7bb6-0129-49e9-8bc9-f73fd8e11cb4" alt="Owe Way Education" />
                                            </SentryWrap>
                                        </LoadingWrapper>
                                    </CustomCol>
                                </CustomRow>
                            </Section>
                        </Container>
                    </Col>

                </Row>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
DirectorContainer.propTypes = {};

//  -- Default props ------------------
DirectorContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentOrg: state.currentOrg,
    userBooksList: state.userBooksList,
    currentUser: state.currentUser,
    users: state.users,
    booksAllList: state.booksAllList,
    schools: state.schools
});

export default connect(mapStateToProps, null)(DirectorContainer);
