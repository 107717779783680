//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash"
//  -- Actions ------------------------
import * as QuestionsActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create question --------------------
export const handleCreateQuestion = (data) => async(dispatch) => {
    await dispatch(QuestionsActions.createQuestion());

    try {
        const questionRDS = await API.post('questionsRDS', `/questions`, {
            body: { ...data }
        });
        await dispatch(QuestionsActions.createQuestionSuccess(questionRDS));
        return questionRDS;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(QuestionsActions.createQuestionFail(error));
    }
};


export const handleDeleteQuestionsByClass = (classId) => async(dispatch) => {
    try {
        await API.del('questionsRDS', `/questionsByClass/${classId}`);
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        console.log(error,"error in delete note");
    }
}
export const handleDeleteQuestionsByBook = (classId,chapters) => async(dispatch) => {
    try {
        await API.post('questionsRDS', `/questionByBookAndClass/${classId}`,{
            body:chapters
        });
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        console.log(error,"error in delete note");
    }
}




//  -- List class questions ---------------
export const handleListClassQuestions = (classID) => async(dispatch) => {
    if(!classID) return;
    await dispatch(QuestionsActions.listClassQuestions());

    try {
        
        const questions = [];
        const questionsRDS=await API.get('questionsRDS', `/questionsByClass/${classID}`);
        if(Array.isArray(questionsRDS)&& questionsRDS.length>0){
            questions.push(...questionsRDS);
        }

        await dispatch(QuestionsActions.listClassQuestionsSuccess(questions));
    } catch (error) {
        await dispatch(QuestionsActions.listClassQuestionsFail(error));
    }
};


//  -- List user questions ----------------
export const handleListUserQuestions = (userID) => async(dispatch) => {
    if(!userID) return;
    await dispatch(QuestionsActions.listUserQuestions());

    try {
        const questions = [];
        const questionsRDS = await API.get('questionsRDS', `/questionsByUser/${userID}`);
        if(Array.isArray(questionsRDS)&& questionsRDS.length>0){
            questions.push(...questionsRDS);
        }
        await dispatch(QuestionsActions.listUserQuestionsSuccess(questions));
    } catch (error) {
        await dispatch(QuestionsActions.listUserQuestionsFail(error));
    }
};

//  -- Update question --------------------
export const handleUpdateQuestion = (questionID, data) => async(dispatch) => {
    if(!questionID) return;
    await dispatch(QuestionsActions.updateQuestion());

    try {
        await API.put('questionsRDS', `/questions/${questionID}`, {
            body: { ...data }
        });
        await dispatch(QuestionsActions.updateQuestionSuccess({ id: questionID, data }));
    } catch (error) {
        await dispatch(QuestionsActions.updateQuestionFail(error));
    }
};
