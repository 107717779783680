import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import VideoLinks from '../../containers/videoLinks';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinkDropdown from '../../containers/videoLinkDropdown';
import VideoLinkDetailsBanner from '../VideoLinkDetailsBanner';

const ButtonLink = styled.button``;

const classNav = () => {
    return (
        <VideoLinkDropdown />
    );
};

export default function LearnScreen() {
    return (
        <>
            <div className="detailMain">
                <Row gutter={[25, 0]}>

                    <Col xs={24} sm={24} md={6} className="searchMob">
                        <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                            <ButtonLink onClick={e => e.preventDefault()}>
                                Search
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </ButtonLink>
                        </Dropdown>
                    </Col>

                    <Col xs={24} sm={24} md={18}>

                        {/* Satrt Include Banner */}
                             <VideoLinkDetailsBanner />
                        {/* End Include Banner */}

                        <div className="detailInfo detailSpc">
                            <div><h1>Teaching Heritage Language Learners </h1></div>
                            <div>
                                <p>Teaching Spanish varies if you are teaching Language 2 students or  Heritage Language Learners. According to Dr. María Carreira there are three must do’s when teaching HLL:
                                1. Expand proficiency by focusing on what students already know,
                                2. Respond to learners motivations and experiences,
                                3. Start with the learner, not the curriculum (STARTALK 2021). </p>
                                <p>Teachers should instruct with a macro-based approach: using authentic materials to later conduct and
                                interleave “form focused instruction”. Scaffolding is the key to achieve formal academic registers in
                                language learning and with HLL Olga Kagan’s “From to Principles”: Teachers  take HLL from listening to
                                reading, speaking to writing, home based register to academic register, everyday life activities to academic
                                activities, HLL motivations to classroom content. These principles allow teachers to expose students to instructional
                                strategies that target all four language modalities: listening, reading, speaking, and writing. As a rule of thumb when
                                you are planning your lessons, start with listening activities that take student to speaking activities. Build on those two
                                modalities to introduce reading activities that then take students to sources of content for academic writing. Always remember to
                                build on what learners already know! Have fun teaching! </p>
                            </div>
                            <div><label><b>Vivanie Barrios, Los Angeles, California </b></label></div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={6} className="searchWeb">
                        <VideoLinks />
                    </Col>

                </Row>
            </div>

        </>
    );
}
