import { IconButton, Snackbar } from '@material-ui/core'
import { Alert } from "@material-ui/lab"
import { Button as AntdButton, Input, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { handleUpdateCustomAssignment } from 'store/activities/thunks'
import { useAllDistrictTeacher, useCurrentUser } from 'store/users/selectors'
import styled from "styled-components"
import color from 'utils/constants/color'
import { useShareNotification } from 'utils/methods/firebaseShareAssignment'
import { filter } from 'lodash'

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: rgb(239,246,254); 
  }
  .ant-table-tbody > tr > td {
    background-color: #fff; 
  }
`;

const TableContainer = styled.div`
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background:#fff;
  border-radius: 20px;
  position: relative;
  border: 1px solid;
  margin-top: 2px;
  margin-bottom: 10px;
  max-width: "250px";
`;

const StyledInput = styled(Input)`
  border: none;
  outline: none;
  background: transparent !important;
  &:focus {
    box-shadow: none;
  }
  flex-grow: 1;
`;

const AssignableNotificationShare = ({ assignment }) => {
    const dispatch = useDispatch()
    const currentUser = useCurrentUser();
    const allDistrictTeacher = useAllDistrictTeacher();
    const { createShareNotification } = useShareNotification();
    const [shareLoader, setShareLoader] = useState(false)
    const [shareModal, setShareModal] = useState(false);
    const filterShareBy = filter(allDistrictTeacher, (item) => item?.id !== assignment?.share_by)
    const [searchTeacher, setSearchTeacher] = useState(filterShareBy);
    const [alert, setAlert] = useState({
        open: false,
        message: "",
    });
    useEffect(() => {
        if (shareModal) {
            document.body.style.overflowX = "hidden";
        } else {
            document.body.style.overflowX = "auto";
        }
    }, [shareModal])
    const AssignmentShareList = assignment?.share_list?.length > 0 ? assignment?.share_list : [];

    const hideShareModal = () => {
        setShareModal(false);
    }

    const ViewShareModal = () => {
        setShareModal(true);
    }

    const handleSend = async (selectedUsers) => {
        setShareLoader(selectedUsers);
        const selectedTeacher = [selectedUsers];
        const sharedList = assignment?.share_list?.length > 0 ? assignment?.share_list : [];
        const data = {
            ...assignment,
            share_list: [...selectedTeacher, ...sharedList],
            share_by: assignment?.createdBy
        }
        const updateData = await dispatch(handleUpdateCustomAssignment(data))
        if (updateData) {
            const { bookDescription, category, colorCode, level, linked_to, questions, ...remainingData } = updateData;
            const notificationData = {
                ...remainingData,
                notification_list: [...selectedTeacher],
                message: `${currentUser?.firstName} ${currentUser?.lastName} Share '${updateData?.displayName}' Activity`
            }
            createShareNotification(notificationData, assignment?.createdBy)
        }
        setShareLoader(false);
        setAlert({
            open: true,
            message: "Activity shared successfully",
        });
    };

    const handleCloseAlert = () => {
        setAlert({
            open: false,
            message: "",
        });
    };

    const handleInputChange = (text) => {
        if (text?.length > 0) {
            const filterBySearch = searchTeacher?.filter((obj) =>
                obj?.firstName?.toLowerCase().includes(text.toLowerCase()) ||
                obj?.lastName.toLowerCase().includes(text.toLowerCase())
            );
            setSearchTeacher(filterBySearch);
        } else {
            setSearchTeacher(filterShareBy);
        }
    };


    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (render) => `${render.firstName} ${render.lastName}`
        },
        {
            title: 'Status',
            key: 'status',
            render: (render) => (
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    if (!AssignmentShareList?.includes(render?.id)) {
                        handleSend(render?.id)
                    }
                }}>
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {/* {AssignmentShareList?.includes(render?.id) ? 'Shared' : shareLoader === render?.id ? <Loading size={14} primary /> : 'Share'} */}
                        <AntdButton type="primary" loading={shareLoader === render?.id} style={{ borderRadius: 10, minWidth: 108, backgroundColor: AssignmentShareList?.includes(render?.id) ? color.greentrue : color.green100, border: "0px" }}
                        // disabled={AssignmentShareList?.includes(render?.id)}
                        >
                            {AssignmentShareList?.includes(render?.id) ? 'Shared' : 'Share'}
                        </AntdButton>
                    </span>
                </div>
            )
        }
    ];

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={alert.open}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity="success">
                    <strong>ALERT:</strong> {alert.message}
                </Alert>
            </Snackbar>
            <Modal
                visible={shareModal}
                onCancel={hideShareModal}
                className='activeStudentModal'
            >
                <div style={{ position: "relative", width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <InputContainer>
                        <StyledInput
                            // ref={inputRef}
                            // value={message}
                            onChange={(e) => handleInputChange(e.target.value)}
                            placeholder="Search District Teacher"
                            maxLength={60}
                            autoFocus
                        />
                    </InputContainer>
                </div>
                <div>
                    <div className='activeStudentMdlContent'>
                        <TableContainer>
                            <CustomTable columns={columns} dataSource={searchTeacher} pagination={{ position: "bottomRight", pageSize: 8 }} />
                        </TableContainer>
                    </div>
                </div>
            </Modal>
            <IconButton color='primary'
                style={{ padding: 0, color: '#3D5F8F', }}
                className="preview-edit-text"
                onClick={() => {
                    ViewShareModal()
                }}>
                Share with other teachers | &nbsp;
            </IconButton>
        </>
    )
}

export default AssignableNotificationShare;