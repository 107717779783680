//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Assignment, Extension , Check , Close } from "@material-ui/icons";
import _, { find, get , has } from "lodash";
import moment from "moment";
import { lighten, invert } from "polished";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//  -- Thunks -------------------------
import { handleUpdateSubmission } from "store/submissions/thunks";
import { handleCreatePastSubmission } from "store/pastSubmission/thunks";
import styled from "styled-components";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { getExceptionStatus } from "utils/lib/ebookHelper";
import { TootipWrapper } from "components/TooltipWrapper";
import RetakeModal from "components/RetakeModal";
import { handleCreateNotification } from 'store/notification/thunks';
import { useSocket } from 'store/chat/selectors';
import GraphicAssignmentTake from "./GraphicAssignmentTake";
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const Row = styled.div`
  align-items: ${(p) => p.align || "center"};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`;

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`;

const TextLarge = styled(Text)`
  color: ${(p) =>
    p.disabled
      ? `${COLOR.redishpink}`
      : p.complete
      ? COLOR.green100
      : "#3D5F8F"};
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

const Wrapper = styled.button`
  background-color: ${(p) => (p.bgColor ? p.bgColor : COLOR.white)};
  border: 2px dashed
    ${(p) =>
      p.disabled
        ? `${COLOR.redishpink}`
        : p.complete
        ? lighten(0.3, COLOR.green100)
        : p.bgColor
        ? lighten(0.2, invert(p.bgColor))
        : lighten(0.3, "#3D5F8F")};
  border-radius: 4px;
  color: ${(p) =>
    p.disabled
      ? `${COLOR.redishpink}`
      : p.complete
      ? COLOR.green100
      : "#3D5F8F"};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;

  &:last-child {
    margin-bottom: 16px;
  }

  &:hover {
    background-color: ${(p) =>
      p.complete ? lighten(0.55, COLOR.green100) : lighten(0.55, "#3D5F8F")};
  }
`;
const BookDescRow = styled(Row)`
  font-size: 10px;
  padding-left: 30px;
  margin-top: -15px;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Announcement
//
//  -- Description
//  Announcement component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcement data={data} />
//
const AssignmentNotification = ({
  assignment,
  onClick,
  submissions,
  userRol,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const activeSubmission = find(submissions, [
    "assignmentId",
    assignment?.data?.id,
  ]);
  const { isException, exceptionStatusText } =
    getExceptionStatus(activeSubmission,currentUser);
  const [isRetakeOpen, setisRetakeOpen] = useState(false);
  const bookDescription = get(assignment, "data.bookDescription");
  const colorCode = get(assignment, "data.colorCode");
  const retakeAvailable = activeSubmission
    ? parseInt(activeSubmission?.submissions, 10) <
      parseInt(assignment?.retakes, 10) + 1
    : true;
  const isActive = get(assignment, "active", true);

  const ungraded = get(assignment, "ungraded", false)
  const grade_type = get(assignment,"grade_type");
  const isCompleteInComplete = grade_type === "complete_incomplete"
  const max_point = assignment?.max_point && !isNaN(assignment?.max_point) ? Number.parseInt(assignment.max_point, 10) : 100
  const isPointSystem =  grade_type === "points"
  const [openAssignmentModal,setOpenAssignmentModal] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const search = queryString.parse(history.location.search);
    if (search.assignment_type === "graphic_organizer" && (search?.id === assignment.id||search.activity_id === assignment.id ||search.activity_id === assignment.data.id)) {
      setOpenAssignmentModal(true)
    }
  }, [history])
  const is_graphic_organizer = get(assignment, "data.activity_type") === "graphic_organizer"
  const handleClick = async (e) => {
    if (!isActive) {
      return;
    }
    if (activeSubmission?.complete && !retakeAvailable) {
      return;
    } else if (activeSubmission?.complete && retakeAvailable) {
      await dispatch(
        handleCreatePastSubmission({ submissionId: activeSubmission?.id })
      );
      if (is_graphic_organizer) {
        setOpenAssignmentModal(true)
      } else {
        onClick();
      }
    } else {
      if (is_graphic_organizer) {
        setOpenAssignmentModal(true)
      } else {
        onClick();
      }
    }
  };
  const checkRetake = (e) => {
    if (isRetakeOpen) return;
    else if (activeSubmission?.complete && retakeAvailable) {
        setisRetakeOpen(true);
    } else {
        handleClick();
    }
  };
  const getCompleteInclompleteMark = (isComplete)=>{
    if(isComplete){
      return <Check style={{ color: COLOR.green100, fontSize: 18 }} />
    } else{
      return <Close style={{ color: COLOR.red, fontSize: 18 }} />
    }
}
  const getGrade = () => {
    if (!activeSubmission) return '-';
    const grade = activeSubmission?.grade >= 0 ? activeSubmission?.grade : '';
    const in_progress = activeSubmission &&!activeSubmission.complete && activeSubmission.retaking
    if(in_progress) return "in progress";
    let isTbgObj = find(get(activeSubmission, "assignment.questions"), (e) => e.type != "multipleChoice")
    const isTbg = activeSubmission?.manualGrading && typeof isTbgObj == "object"
    if(isTbg) return "TBG";
    if (ungraded) return 'Turned in'
    if (isCompleteInComplete && has(activeSubmission, "id")) {
      return getCompleteInclompleteMark(activeSubmission?.grade > 0);
    } else if (isPointSystem) {
      if (isTbg) return "TBG";
      else if (grade > 0 && max_point > 0) {
        return `${Math.round((grade / 100) * max_point)}/${max_point}`
      } else {
        return `0/${max_point}`;
      }
    }

    return isTbg ? "TBG" : (grade)
  }
  return (<>
    <GraphicAssignmentTake assignment={assignment} open={openAssignmentModal} setOpen={setOpenAssignmentModal} />

    <Wrapper
      complete={activeSubmission?.complete}
      onClick={checkRetake}
      disabled={
        (isException && userRol === "student") ||
        (!isActive && userRol === "student")
      }
      bgColor={colorCode}
    >

      <RetakeModal
        isRetakeOpen={isRetakeOpen}
        onClose={(e) => {
          setisRetakeOpen(false);
        }}
        onSubmit={handleClick}
      />
      <Row align="flex-start">
        {assignment?.data?.typeId === "activity" ? (
          <Extension style={{ height: "18px", marginRight: "4px" }} />
        ) : (
          <Assignment style={{ height: "18px", marginRight: "4px" }} />
        )}
        <Title>
          {get(
            assignment,
            "displayName",
            get(assignment, "data.displayName", "")
          )}
        </Title>
      </Row>
      {bookDescription ? (
        <BookDescRow>
          <strong>Book</strong>: {bookDescription}
        </BookDescRow>
      ) : null}
      <Row>
        <Column>
          <Text>
            Submissions:{" "}
            <strong style={{ color: COLOR.black300 }}>
              {activeSubmission?.submissions || "0"}
            </strong>
          </Text>
          {get(assignment, "scheduleAt") ? (
            <Text>
              Start Date:{" "}
              <strong style={{ color: COLOR.black300 }}>
                {" "}
                {moment(get(assignment, "scheduleAt")).format(
                  "MMM DD, YYYY hh:mm A"
                )}
              </strong>
            </Text>
          ) : null}
          <Text>
            Due Date:{" "}
            <strong style={{ color: COLOR.black300 }}>
              {" "}
              {moment(get(assignment, "dueDate")).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </strong>
          </Text>
          {activeSubmission && (
            <Text>
              Grade:{" "}
              <strong style={{ color: COLOR.black300 }}>
                {getGrade()}
              </strong>
            </Text>
          )}
        </Column>
        {userRol === "student" ? (
          <TootipWrapper
            hasToolTip={!isActive}
            label={!isActive ? "Assignment is deactivated by teacher" : null}
          >
            <TextLarge
              complete={activeSubmission?.complete}
              disabled={!isActive}
            >
              {!isActive && userRol === "student"
                ? "Deactivated"
                : isException
                  ? exceptionStatusText
                  : activeSubmission?.complete && retakeAvailable
                    ? "Retake"
                    : activeSubmission?.complete
                      ? "Completed"
                      : activeSubmission
                        ? "Resume"
                        : "Take Now"}
            </TextLarge>
          </TootipWrapper>
        ) : (
          <TextLarge complete={activeSubmission?.complete}>Preview</TextLarge>
        )}
      </Row>
    </Wrapper>
  </>);
};

export default AssignmentNotification;
