//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create post --------------------
export const createPost = () => ({ type: ActionTypes.posts.createPost });
export const createPostFail = (returnedPayload) => ({ type: ActionTypes.posts.createPostFail, payload: returnedPayload });
export const createPostSuccess = (returnedPayload) => ({ type: ActionTypes.posts.createPostSuccess, payload: returnedPayload });

//  -- Delete post --------------------
export const deletePost = () => ({ type: ActionTypes.posts.deletePost });
export const deletePostFail = (returnedPayload) => ({ type: ActionTypes.posts.deletePostFail, payload: returnedPayload });
export const deletePostSuccess = (returnedPayload) => ({ type: ActionTypes.posts.deletePostSuccess, payload: returnedPayload });

//  -- List posts ---------------------
export const listPosts = () => ({ type: ActionTypes.posts.listPosts });
export const listPostsFail = (returnedPayload) => ({ type: ActionTypes.posts.listPostsFail, payload: returnedPayload });
export const listPostsSuccess = (returnedPayload) => ({ type: ActionTypes.posts.listPostsSuccess, payload: returnedPayload });

//  -- List class posts ---------------
export const listClassPosts = () => ({ type: ActionTypes.posts.listClassPosts });
export const listClassPostsFail = (returnedPayload) => ({ type: ActionTypes.posts.listClassPostsFail, payload: returnedPayload });
export const listClassPostsSuccess = (returnedPayload) => ({ type: ActionTypes.posts.listClassPostsSuccess, payload: returnedPayload });

//  -- List organization posts --------
export const listOrgPosts = () => ({ type: ActionTypes.posts.listOrgPosts });
export const listOrgPostsFail = (returnedPayload) => ({ type: ActionTypes.posts.listOrgPostsFail, payload: returnedPayload });
export const listOrgPostsSuccess = (returnedPayload) => ({ type: ActionTypes.posts.listOrgPostsSuccess, payload: returnedPayload });
