//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create user --------------------
export const createUserPreference = () => ({ type: ActionTypes.user_preference.createUserPrefrence });
export const createUserPreferenceFail = (returnedPayload) => ({ type: ActionTypes.user_preference.createUserPreferenceFail, payload: returnedPayload });
export const createUserPreferencSuccess = (returnedPayload) => ({ type: ActionTypes.user_preference.createUserPreferencSuccess, payload: returnedPayload });


//  -- Get user -----------------------
export const getUserPreference = () => ({ type: ActionTypes.user_preference.getUserPreference });
export const getUserPreferenceFail = (returnedPayload) => ({ type: ActionTypes.user_preference.getUserPreferencFail, payload: returnedPayload });
export const getUserPreferenceSuccess = (returnedPayload) => ({ type: ActionTypes.user_preference.getUserPreferencSuccess, payload: returnedPayload });


//  -- Update user --------------------
export const updatePreferenceUser = () => ({ type: ActionTypes.user_preference.updateUserPreference });
export const updateUserPreferenceFail = (returnedPayload) => ({ type: ActionTypes.user_preference.updateUserPreferencFail, payload: returnedPayload });
export const updateUserPreferenceSuccess = (returnedPayload) => ({ type: ActionTypes.user_preference.updateUserPreferencSuccess, payload: returnedPayload });
