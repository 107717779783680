//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Loading from 'components/Loading';
import React , { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useAlertView } from "store/alert/selectors";
import { useHistory } from "react-router-dom";

//  -- Components ---------------------


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    margin: 0px auto;
    width: calc(100% + 48px);
    margin-left: -24px;
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const iformateFrame = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
    src: https://www.1wayeducation.com/1nformatecon1way2023;
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
const InformateContainer = ({ currentUser }) => {
    const [loader, setLoader] = React.useState(true);
    const history = useHistory();
    const [,invokeAlert,revokeALert ] =useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    useEffect(()=>{
        if(orgPermissions && typeof orgPermissions['1nformate']==="boolean"){
          if(!orgPermissions['1nformate']){
            invokeAlert()
            setTimeout(()=>{
              history.goBack();
              revokeALert()
            },5000)
          }
        }
      },[orgPermissions])
    return (
        <Wrapper>
            {loader&&<Loading />}
            <iframe width='100%' height='100%' src="https://www.1wayeducation.com/1nformatecon1way2023" style={{border: 'none'}}
             onLoad={(e)=>{
               setLoader(false);
            }}
            ></iframe>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
InformateContainer.propTypes = {};

//  -- Default props ------------------
InformateContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------

const mapStateToProps = state => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(InformateContainer);
