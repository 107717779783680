//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, IconButton, Modal, makeStyles } from '@material-ui/core';
//  -- Components ---------------------
//  -- Features -----------------------
import React, { useEffect, useState } from 'react';
import { useCurrentUser } from 'store/users/selectors';
import { handleUpdateUserConfig } from 'store/users/thunks';
import { useWelcomeVideos } from 'store/welcomeVideo/selectors';
//  -- Thunks -------------------------
import { Close } from "@material-ui/icons";
import { find, get } from "lodash";
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { useFunVideos } from 'store/resources/selectors';
import styled from 'styled-components';
import { IntroVideoPoster } from "utils/assets/images";
import COLOR from "utils/constants/color";
import Loading from './Loading';
//  -- Constants ----------------------

const useStyles = makeStyles((theme) => ({
    manageColor: {
        backgroundColor: '#cf26b8 !important',
        margin: '5px'
    },
    gradebookColor: {
        backgroundColor: '#ffb100 !important',
        margin: '5px'


    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));

const VideoContainer = styled.div`
    height: auto;
    flex: 1;
    justify-content: center;
`;
const TitleVideo = styled.span`
    color: ${COLOR.white};
    display: inline-block;
    font-size: 16px;
    flex: 1;
    font-weight: 300;
    justify-content: center;
    text-align: center;
    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

const RowVideo = styled.div`
    flex: 1;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-content: center;
    display:flex;
`;
const Video = styled.video`
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    height: auto;
    flex: 1;
    width: 50%;
    justify-content: center;
    @media only screen and (max-width: 600px){
        width: 95%;
    }
`;
const FunVideo = styled.video`
    border-radius: 8px;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
    // height: 70vh;
    flex: 1;
    width: auto;
    // width:100vw;
    padding:0px 20px;
    height:600px;

    @media only screen and (max-width: 1150px){
        width:100vw;
        height: 100%;
    }
`;
function isRunningOnIpad() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|Macintosh/.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return true;
    }
    if (/iPad/.test(userAgent)) {
      return true;
    }
  
    return false;
  }
  
export default function WelcomeVideo({ open, onClose }) {
    const classes = useStyles();
    const currentUser = useCurrentUser();
    // const isReviewAccount = currentUser?.reviewAccount || false
    const welcomeVideo = useWelcomeVideos();
    const fun_videos = useFunVideos(currentUser?.role);
    const [loader, setLoader] = useState(false);
    const [show_modal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const [isFunVideo, setFunVideo] = useState(false);
    const [videoConetent, setActiveVideo] = useState({});
    const isIpad = isRunningOnIpad()
    const onDontShow = async () => {
        onClose()
        setLoader(true);
        setShowModal(false)
        await dispatch(handleUpdateUserConfig(currentUser.id,{
            ...currentUser.config,
            last_video_shown:moment().toISOString(),
            welcome_video_show: false
         }));
        setLoader(false);
    }
   const handleFunVideo = async () => {
    const fun_video_shown = localStorage.getItem("fun_video_shown"+currentUser.id);
    const is_video_shown = fun_video_shown ? moment(fun_video_shown).isSame(moment(), "date") : false;
    const today_video = find(fun_videos, ["metadata.day_count", moment().get("day")])
    if (today_video && today_video.metadata.active && today_video.src && !is_video_shown) {
        if (currentUser && currentUser.config) {
            if (currentUser && currentUser.config.last_video_shown && moment(currentUser.config.last_video_shown).isBefore(moment(), "date")) {
                setFunVideo(true)
                setActiveVideo(today_video)
                setShowModal(true)
            }
        } else {
            setFunVideo(true)
            setActiveVideo(today_video)
            setShowModal(true)
        }

        localStorage.setItem("fun_video_shown"+currentUser.id,moment().toISOString());
    }
   }
    useEffect(() => {
        if (open) {
            // if (welcomeVideo && welcomeVideo.metadata) {
            //     if (welcomeVideo.metadata.active_mode === "fun_video") {
                  
            //     } else {
                    // showWelcomeVideo() //disabled for now
            //     }
            // } else {
            //     showWelcomeVideo()
            // }
            // handleFunVideo()
        }
    }, [welcomeVideo, fun_videos, open])
    const showWelcomeVideo = () => {
        if (! welcomeVideo?.metadata) {
            welcomeVideo.metadata = {
                closable: true
            }
        } else { 
            welcomeVideo.metadata.closable = true
        }
        if (currentUser?.newUser) {
            if (typeof currentUser.config?.welcome_video_show === "boolean" && !currentUser.config?.welcome_video_show) return;
            setFunVideo(false)
            setShowModal(true)
            setActiveVideo(welcomeVideo)
        }
    }
    const handleVideoEnd = () => {
        onDontShow()
    };
    const onWatchLater = async () => {
        setShowModal(false)
        onClose()
    }
    const onCancel =() =>{
        onDontShow();
        setShowModal(false);
        onClose();
    }
    return (
        <Modal
            className={classes.modal}
            open={show_modal}
            onClose={onClose}
            onContextMenu={(e) => {
                e.preventDefault();
            }}
        >
            <VideoContainer>
                {!isFunVideo && <RowVideo>
                    <TitleVideo>{get(videoConetent, "displayName", "Welcome video")}</TitleVideo>
                </RowVideo>}
                <RowVideo>
                    <div style={{ position: "relative", width: "max-content" }}>
                        <IconButton
                            onClick={onCancel}
                            style={{
                                // marginRight: 10,
                                position: "absolute",
                                // right:-7,
                                right:7,
                                // top: -10,
                                top: -8,
                                backgroundColor: "red",
                                borderRadius: "50%",
                                color: "white",
                                cursor:"pointer",
                                zIndex: "10000",
                                padding:12,
                                height: 30,
                                width: 30
                            }}>
                            <Close />
                        </IconButton>
                        
                        <FunVideo
                            autoPlay
                            controls={isIpad}
                            // controlsList="nodownload"
                            onError={handleVideoEnd}
                            poster={get(videoConetent, "poster", IntroVideoPoster)}
                            onEnded={handleVideoEnd}
                        >
                            <source src={get(videoConetent, "src")} type="video/mp4" />
                        </FunVideo>
                    </div>
                </RowVideo>
                {get(videoConetent, "metadata.closable", false) && <RowVideo>
                    <div className={classes.root}>
                        <Button variant="contained" size="large" color="primary" onClick={onWatchLater}>
                            WATCH LATER
                        </Button>
                        <Button disabled={loader} variant="contained" size="large" color="secondary" onClick={onDontShow}>
                            {loader ? <Loading /> : null}   DON'T SHOW AGAIN
                        </Button>
                    </div>
                </RowVideo>}
            </VideoContainer>
        </Modal>
    )
}
