//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------


export const handleListOneWayDebate = (id) => async (dispatch) => {
    try {
        const video = await API.get('oneway_debate', `/listDebate/${id}`);
        if (Array.isArray(video)) {
            await dispatch(Actions.listOnewayDebate(video));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleCreateOneWayDebate = (data) => async (dispatch) => {
    try {
        const videos = await API.post('oneway_debate', `/createDebate`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.createOnewayDebate(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleUpdateOneWayDebate = (data) => async (dispatch) => {
    try {
        const videos = await API.put('oneway_debate', `/updateDebate`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.updateOnewayDebate(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error,"error")
    }
};
export const handleSyncDebate = (data) => async (dispatch) => {
    try {
        const videos = await API.post('oneway_debate', `/create_assignment`, {
            body: data
        });
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error,"error")
    }
};
export const handledeleteDebate = (id) => async (dispatch) => {
    try {
        const data = await API.post('oneway_debate', `/delete_assignment`, {
            body: {
                debate_id:id
            }
        });
        return data;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error,"error")
    }
};
export const handleDeleteOnewayDebate = (id) => async (dispatch) => {
    try {
        const videos = await API.del('oneway_debate', `/delete/${id}`);
        if (has(videos, "id")) {
            await dispatch(Actions.deleteOnewayDebate(id));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleDebateAI = async (data) => {
    try {
        const videos = await API.post('oneway_debate', `/debate_ai`, {
            body: data
        });
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};