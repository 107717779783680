//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Action from './actions';
import { SocketIo } from 'socket';
import { useEffect } from 'react';
import { useCurrentUser } from 'store/users/selectors';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selectore = (state) => state.chatUser;
const selectchatMessages = (state) => state.chatMessages;
const selectUserChatConfig = (state) => state.userChatConfig;
const selectScheduledMessage = (state) => state.scheduledMessage;
//  -- Examples array selector --------

export const useChatUser = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}
export const useChatMessage = () => {
    const selectedData = useSelector(selectchatMessages, shallowEqual)
    return selectedData;
}
export const useChatConfig = () => {
    const selectedData = useSelector(selectUserChatConfig, shallowEqual)
    return selectedData;
}
export const useSocket = () => {
    const socketConnection = useSelector(state => state.socketConnection, shallowEqual)
    const currentUser = useCurrentUser()
    const dispatch = useDispatch()
    const reCreateSocket = async () => {
        if (currentUser && currentUser.id) {
            await dispatch(Action.clearSocket())
            await dispatch(Action.createSocket(SocketIo(currentUser.id)))
        }
    }
    useEffect(() => {
        if (!socketConnection) {
            reCreateSocket()
        }
    }, [currentUser])
    // useEffect(()=>{
    //     if(socketConnection){
    //         if(socketConnection.CLOSED || socketConnection.readyState==3 || socketConnection.CLOSING){
    //             reCreateSocket()
    //         }
    //     }
    // })

    return ({ socketConnection, reCreateSocket });
}
export const useScheduledMessages = () =>{
    const selectedData = useSelector(selectScheduledMessage, shallowEqual)
    return selectedData;
}