//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const timmerRunningInit = false

export const timmerRunning = (state = timmerRunningInit, action) => {
    switch (action.type) {
        case ActionTypes.timmer.createTimmerRunning:
            return true;
        case ActionTypes.timmer.clearTimmerRunning:
            return false;
        default:
            return state;
    }
};
