//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const auth = {
    oauth_token: "",
    oauth_token_secret: ""
};

export const schoologyAuth = (state = auth, action) => {
    switch (action.type) {
        case ActionTypes.schoology.setAuthToken:
            return action.payload;
        case ActionTypes.schoology.setAuthTokenSuccess:
            return action.payload;
        case ActionTypes.schoology.clearAuthToken:
            return state;
        default:
            return state;
    }
};


export const schoologyGradeCategory = (state = [], action) => {
    switch (action.type) {
        case ActionTypes.schoology.setGradeCategory:
            return action.payload;
        case ActionTypes.schoology.updateGradeCategory:
            return action.payload;
        default:
            return state;

    }
}
export const schoologyAllClassGradeCategory = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.schoology.setAllClassGradeCategorySuccess:
            return action.payload;
        case ActionTypes.schoology.setAllClassGradeCategoryFail:
            return action.payload;
        default:
            return state;

    }
}
export const schoologyAssignment = (state = [], action) => {
    switch (action.type) {
        case ActionTypes.schoology.setSchoologyAssignment:
            return action.payload;
        case ActionTypes.schoology.updateSchoologyAssignment:
            return [...state,action.payload];
        default:
            return state;

    }
}