//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create note --------------------
export const createNote = () => ({ type: ActionTypes.notes.createNote });
export const createNoteFail = (returnedPayload) => ({ type: ActionTypes.notes.createNoteFail, payload: returnedPayload });
export const createNoteSuccess = (returnedPayload) => ({ type: ActionTypes.notes.createNoteSuccess, payload: returnedPayload });

//  -- Delete note --------------------
export const deleteNote = () => ({ type: ActionTypes.notes.deleteNote });
export const deleteNoteFail = (returnedPayload) => ({ type: ActionTypes.notes.deleteNoteFail, payload: returnedPayload });
export const deleteNoteSuccess = (returnedPayload) => ({ type: ActionTypes.notes.deleteNoteSuccess, payload: returnedPayload });

//  -- List notes ---------------------
export const listNotes = () => ({ type: ActionTypes.notes.listNotes });
export const listNotesFail = (returnedPayload) => ({ type: ActionTypes.notes.listNotesFail, payload: returnedPayload });
export const listNotesSuccess = (returnedPayload) => ({ type: ActionTypes.notes.listNotesSuccess, payload: returnedPayload });

//  -- List chapter notes -------------
export const listChapterNotes = () => ({ type: ActionTypes.notes.listChapterNotes });
export const listChapterNotesFail = (returnedPayload) => ({ type: ActionTypes.notes.listChapterNotesFail, payload: returnedPayload });
export const listChapterNotesSuccess = (returnedPayload) => ({ type: ActionTypes.notes.listChapterNotesSuccess, payload: returnedPayload });

//  -- List class notes ---------------
export const listClassNotes = () => ({ type: ActionTypes.notes.listClassNotes });
export const listClassNotesFail = (returnedPayload) => ({ type: ActionTypes.notes.listClassNotesFail, payload: returnedPayload });
export const listClassNotesSuccess = (returnedPayload) => ({ type: ActionTypes.notes.listClassNotesSuccess, payload: returnedPayload });

//  -- List organization notes --------
export const listOrgNotes = () => ({ type: ActionTypes.notes.listOrgNotes });
export const listOrgNotesFail = (returnedPayload) => ({ type: ActionTypes.notes.listOrgNotesFail, payload: returnedPayload });
export const listOrgNotesSuccess = (returnedPayload) => ({ type: ActionTypes.notes.listOrgNotesSuccess, payload: returnedPayload });

//  -- List user notes ----------------
export const listUserNotes = () => ({ type: ActionTypes.notes.listUserNotes });
export const listUserNotesFail = (returnedPayload) => ({ type: ActionTypes.notes.listUserNotesFail, payload: returnedPayload });
export const listUserNotesSuccess = (returnedPayload) => ({ type: ActionTypes.notes.listUserNotesSuccess, payload: returnedPayload });


//  -- Update class notes --------------
export const updateNote = () => ({ type: ActionTypes.notes.updateNote });
export const updateNoteFail = (returnedPayload) => ({ type: ActionTypes.notes.updateNoteFail, payload: returnedPayload });
export const updateNoteSuccess = (returnedPayload) => ({ type: ActionTypes.notes.updateNoteSuccess, payload: returnedPayload });

//  -- List notes Book -----------------
export const listNoteByBookSuccess = (returnedPayload) => ({ type: ActionTypes.notes.listNoteByBookSuccess, payload: returnedPayload });
export const listNoteByBookFail = (returnedPayload) => ({ type: ActionTypes.notes.listNoteByBookFail, payload: returnedPayload });