//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Newsletters --------------------
import * as NEWSLETTERS from 'utils/config/reference';

export const newsletterConfig = (id) => {
    const newsletter = NEWSLETTERS[id];

    return newsletter;
};
