//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'



const selectoreWidgets = (state) => state.oneWayWidgets;
export const useOneWayWidgets = () => {
    const selectedData = useSelector(selectoreWidgets, shallowEqual)
    return selectedData;
}
