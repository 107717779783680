import React, { useEffect, useState } from 'react';
// import { Switch } from 'antd';
import { FormControlLabel, Switch, makeStyles } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import color from 'utils/constants/color';
import { get } from 'lodash';
const Container = styled.div`
  // margin-top: 10px;
  // margin-bottom: 10px;
  // margin-bottom: 5px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LinkContainer = styled.div`
  margin-top: 10px;
  background-color: ${color.green100};
  padding: 8px;
  border-radius: 4px;
  &.fade-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  &.fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }
`;

const LinkItem = styled.div`
  margin-bottom: 5px;
  color: #fff;
  white-space: pre-line;
`;
const LinksItem = styled.a`
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 14px;
    &:hover {
        color: ${color.white};
    }
    &:before {
        display: inline-block;
        content: '';
        -webkit-border-radius: 0.375rem;
        border-radius: 0.375rem;
        height: 0.55rem;
        width: 0.55rem;
        margin-right: 0.5rem;
        background-color: #fff;
    }
`;
const useStyles = makeStyles({
    root: {
        margin: '5px',
        width: '175px'
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
});
const LinkList = ({ links }) => {
  const [isVisible, setIsVisible] = useState(false);
  const classes = useStyles();
  const handleSwitchChange = (checked) => {
    setIsVisible(checked);
  };
  useEffect(() => {
    if(!links){
        setIsVisible(false);
    }
  }, [links])

  if (!links || links.length === 0) {
    return null;
  }
  const getLink = (link) => {
    if (typeof link == "string") {
        return link.match(/\bhttps?:\/\/\S+/gi)[0];
    } else if (typeof link == "object") {
        return get(link, "href", "").match(/\bhttps?:\/\/\S+/gi)[0];
    } else {
        return '';
    }
}
const getHrefTitle = (link) => {
    if (typeof link == "string") {
        return link;
    } else if (typeof link == "object") {
        return link.title && link.title.length > 0 ? link.title : link.href;
    } else {
        return ''
    }
}

  return (
    <Container>
          <FormControlLabel
              classes={{
                  root: classes.root,
                  label: classes.label,
              }}
              control={
                  <Switch
                      classes={{
                          sizeSmall: classes.sizeSmall,
                          checked: classes.checkedSwitch
                      }}
                      checked={isVisible}
                      onChange={e => handleSwitchChange(e.target.checked)}
                      name="viewFlag"
                      color="primary"
                      size="small"
                  />
              }
              label="Show Links"
          />
      <CSSTransition
        in={isVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <LinkContainer>
          {links.map((link, index) => (
            <LinkItem key={index}>
                <LinksItem href={getLink(link)} target={"_blank"}  rel="noopener noreferrer"  >{getHrefTitle(link)}</LinksItem>
            </LinkItem>
          ))}
        </LinkContainer>
      </CSSTransition>
    </Container>
  );
};

export default LinkList;
