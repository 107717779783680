import { ArrowBackIos, ArrowForwardIos, Add, Delete, Close, Star } from '@material-ui/icons';
import Pages from "components/AdminBooks/pages";
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import PageToolbar from "components/AdminBooks/pageToolbar";
import _, { get, cloneDeep, filter, find, findIndex, has, map } from "lodash";
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import COLOR from "utils/constants/color"
import { Col, Row , Spin , message as MessageAlert } from 'antd';
import { Button, IconButton, Modal, FormControlLabel, Switch, TextField, InputLabel, Select, FormControl, MenuItem, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { convertToRoman, getNextNumberMask, ValidationOfRomanNumerals } from "utils/methods/math"
import { handleCreateBook, handleUpdateBook , handleUpdateBookByPage  } from "store/books/thunks"
import Loading from 'components/Loading';
import queryString from 'query-string';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { DeleteModal } from "components/Dialog"
import Tag from 'components/Tag';
import { handleListVocabularyNew } from 'store/vocabulary/thunks';

const BLANK_PG = {
    pageId: null,
    resource: null,
    description: null,
    NumberMasked: null
}

const ButtonContainer = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.black600};
    cursor: pointer;
    height: 40px;
    left: ${p => `${p.left}px`};
    outline: none;
    position: absolute;
    right: ${p => `${p.right}px`};
    top: 10px;
    transition: all 300ms ease;
    width: 40px;
    z-index: 1;
    
`;

const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    max-width: 1268px;
    padding: 64px 0px 0px;
    position: relative;
    width: 100%;

    @media (min-width: 997px) {
        flex-direction: row;
        padding: 0px 30px;
    }
`;

const ToolsContainer = styled.div`
    height: 100%;
    margin-top: 48px;
    width: 100%;

    @media (min-width: 997px) {
        margin-left: 48px;
        margin-top: 0px;
        width: 350px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
`;
const CoverText = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
font-size: 12px;
font-weight: bold;
margin-top: -10px;
`;
const ModalContent = styled.div`
  left: 50%;
  width: 80vw;
  padding: 0px 24px;
  position: absolute;
  top: 50%;
  height:90%;
  transform: translate(-50%, -50%);
  background-color:#fff;
  padding:20px;
  overflow:scroll;
     @media only screen and (max-width: 600px) {
        width: 90%;
    }
`;
const ModalConfigArea = styled.div`
  margin-top:10px;
`;

const Note = styled.span`
    color: ${COLOR.black400};
    display: block;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    margin-left:5px;
    margin-top:5px;
`;
const VideoContainer = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    formControl: {
        minWidth: 120
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    }
}));


function Index({ currentUser, booksAllList, quizzes, activities, interactions, tips }) {
    const history = useHistory()
    const classes = useStyles();
    const dispatch = useDispatch()
    const search = queryString.parse(history.location.search);
    const bookTips = filter(tips, t => t.bookId == search.bookId)
    const bookIntractions = filter(interactions, t => t.chapter == search.bookId || t.bookId == search.bookId)
    const [description, setDescription] = useState('')
    const [pages, setPages] = useState([{
        pageId: 1,
        resource: null,
        description: null,
        NumberMasked: convertToRoman(1),
        new: true
    }])
    const [editBook, setEditBook] = useState(null)
    const [pagesTeacher, setPagesTeacher] = useState([])
    const [teacherEdition, setTeacherEdition] = useState([])
    const [subTopics, setSubTopics] = useState([])
    const [lessonPlan, setLessonPlan] = useState([])
    const [superLesson, setSuperLesson] = useState([])
    const [differentiationLesson, setDifferentiationLesson] = useState([])
    const [assignmentPlan, setAssignmentPlan] = useState([])
    const [workbook_plan, setWorkbook_plan] = useState([])
    const [main_book, setMainBook] = useState([])
    const [rubricsPlan, setRubricsPlan] = useState([])
    const [demoPages, setDemoPages] = useState({})
    const [hyperlinks, setHyperlinks] = useState([])
    const [count, setCount] = useState(false)
    const [printable, setPrintable] = useState(false)
    const [isNotAssignable, setIsNotAssignable] = useState(false)
    const [onlyTeacher, setOnlyTeacher] = useState(false)
    const [teacherEditionBook, setTeacherEditionBook] = useState('')
    const [alertSakeBar, setAlertSakeBar] = useState({ open: false, message: '', severity: "success" })
    const [cover, setCover] = useState('')
    const [level, setlevel] = useState(null)
    const [type, setType] = useState(null)
    const [storyId, setStoryId] = useState(null)
    const [activeIndex, setActiveindex] = useState(0)
    const [saveModal, setSaveModal] = useState(false)
    const [bookActivities, setBookActivties] = useState([])
    const [bookQuizzes, setBookQuizzes] = useState([])
    const backBtnRef = useRef(null)
    const forwardBtnRef = useRef(null)
    const [bookErrors, setErrors] = useState([])
    const [loading, setloading] = useState(false)
    const pg = pages[activeIndex] ? pages[activeIndex] : {}
    const [deletePageModal, setDeletePageModal] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [updateLoader, setUpdateLoader] = useState(false);
    const [bookIdVoc, setBookIdVoc] = useState();
    const [dataloader,setdataloader] = useState(false);
    const vocabLoader = async () =>{
        setdataloader(true);
       await dispatch(handleListVocabularyNew({
            user_id: get(currentUser, "id", ''),
            created_by:"owe_admin",
            book_id: search.bookId
        }))
        setdataloader(false);
    }
    useEffect(()=>{
        vocabLoader()
    },[currentUser])
    useEffect(() => {
        if (get(currentUser, "role") !== "admin" && get(currentUser, "role") !== "owe_admin") {
            if (history) {
                history.goBack();
            }
        }
    }, [currentUser, history])
    useEffect(() => {
        if (search.bookId) {
            setBookActivties(filter(activities, ac => ac.bookId == search.bookId || ac.chapter == search.bookId))
        }
    }, [activities])
    useEffect(() => {
        if (search.bookId) {
            setBookQuizzes(filter(quizzes, ac => ac.bookId == search.bookId || ac.chapter == search.bookId))
        }
    }, [quizzes])
    useEffect(() => {
        const book = find(booksAllList, bk => bk.id == search.bookId)
        const pageId = search.pageId;
        const booksForVoc = find(booksAllList, bk => bk.id)
        setBookIdVoc(booksForVoc);
        if (book && pages[0].new) {
            setEditBook(book)
            setPages(book.pages)
            setDescription(book.description)
            setPagesTeacher(book.pagesTeacher)
            setTeacherEdition(book.teacherEdition)
            setSubTopics(book.subTopics)
            setLessonPlan(book.lessonPlan)
            setSuperLesson(book.superLesson)
            setDifferentiationLesson(book.differentiationLesson)
            setAssignmentPlan(book.assignmentPlan)
            setWorkbook_plan(book.workbook_plan)
            setMainBook(book.main_book)
            setRubricsPlan(book.rubrics)
            setDemoPages(book.demoPages)
            setHyperlinks(book.hyperlinks)
            setCount(book.count)
            setPrintable(book.printable)
            setIsNotAssignable(book.isNotAssignable)
            setOnlyTeacher(book.onlyTeacher)
            setTeacherEditionBook(book.teacherEditionBook)
            setCover(book.img)
            setlevel(book.level)
            setType(book.type)
            setStoryId(book.stoyId)
            if (pageId) {
                const ind = findIndex(book.pages, k => k.pageId == pageId)
                if (ind >= 0&&activeIndex==0) {
                    setActiveindex(ind)
                }
            }
        }
    }, [booksAllList])
    const onPageImageChange = (item) => {
        const pageId = item.pageId;
        // const index = findIndex(pages, k => k.pageId == pageId)
        // const pagesfile = pages[index]
        // const url = item.url
        // const _pgs = cloneDeep(pages)
   
        updateBookDataByPageWithKey(pageId, { resource: item.url }, 'pages', 'Cover image updated!', "success")
        // const _pgs = cloneDeep(pages)
        // if (pagesfile) {
        //     if (url) {
        //         pagesfile.resource = url;
        //         _pgs[index] = pagesfile
        //         setPages(_pgs)
        //     }
        // } else {
        //     if (url) {
        //         _pgs.push({
        //             pageId: pageId,
        //             resource: url,
        //             NumberMasked: getNextNumberMask(pageId),
        //             description: ''
        //         })
        //         setPages(_pgs)
        //     }
        // }
        // updateBookData({ pages: _pgs }, `Page updated!`, "success")
    }
    const onChangeOrUpdateDescription = (item) => {
        const pageId = item.pageId;
        // const index = findIndex(pages, k => k.pageId == pageId)
        // const pagesfile = pages[index]
        // const _pgs = cloneDeep(pages)
        updateBookDataByPageWithKey(pageId, { description: item.description, tags: item.tags }, 'pages', 'Description and tags updated!', "success")
        // if (pagesfile) {
        //     pagesfile.description = item.description;
        //     pagesfile.tags = item.tags;
        //     _pgs[index] = pagesfile
        //     setPages(_pgs)
        // }
        // updateBookData({ pages: _pgs }, `Description and tags updated!`, "success")
    }
    const onMaskChanged = (v) => {
        const pagesfile = pages[activeIndex]
        const _pgs = cloneDeep(pages)
        if (pagesfile) {
            pagesfile.NumberMasked = v;
            _pgs[activeIndex] = pagesfile
            setPages(_pgs)
        }
    }
    const onChangeDescription = (v) => {
        // const pagesfile = pages[activeIndex]
        // const _pgs = cloneDeep(pages)
        // if (pagesfile) {
        //     pagesfile.description = v;
        //     _pgs[activeIndex] = pagesfile
        //     setPages(_pgs)
        // }
    }
    function onForward() {
        setActiveindex(activeIndex + 1)
    }
    function onBackward() {
        if (activeIndex > 0) {
            setActiveindex(activeIndex - 1)
        }
    }
    function checkKey(e) {
        const isDrawerOpen = document.getElementsByClassName("ant-drawer-open").length > 0;
        const hasModal = document.querySelectorAll('[role="presentation"]').length > 0;
        if (isDrawerOpen || hasModal) return;
        e = e || window.event;
        if (e.keyCode == '37') {
            if (backBtnRef.current && !backBtnRef.current.disabled) {
                backBtnRef.current.click()
            }
        }
        else if (e.keyCode == '39') {
            if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                forwardBtnRef.current.click()
            }
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", () => { })
        }
    }, [])
    const getIdInSeq = (_pgs) => {
        let id = 1;
        while (find(_pgs, p => p.pageId == id)) {
            id++;
        }
        return id;
    }
    const addPage = (index) => {
        if (!pages[pages.length - 1]?.resource) {
            setAlertSakeBar({
                open: true,
                message: "Please insert images befor adding new pages",
                severity: "error"
            })
            return;
        }
        let newPageId = 1;
        const _pgs = []
        map(pages, (p, i) => {
            if (!isNaN(p.pageId)) {
                newPageId = p.pageId
                p.pageId = parseInt(p.pageId, 10)
            } else {
                const getId = getIdInSeq(_pgs)
                if (!find(_pgs, k => k.pageId == newPageId + 1)) {
                    newPageId++
                    p.pageId = newPageId;
                } else {
                    newPageId = getId
                    p.pageId = getId;
                }
            }
            _pgs.push(p);
        })
        const _pg = cloneDeep(BLANK_PG);
        const lastPages = _pgs[_pgs.length - 1]
        const secondlastPages = _pgs[_pgs.length - 2]
        const lastPageId = isNaN(lastPages.pageId) ? newPageId : lastPages.pageId;
        _pg.pageId = parseInt(lastPageId) + 1
        _pg.NumberMasked = getNextNumberMask(lastPages.NumberMasked, get(secondlastPages, "NumberMasked"))
        if (index >= 0) {
            _pgs.splice(index, 0, _pg)
        } else {
            _pgs.push(_pg)
        }
        setPages(_pgs)
        // updateBookData({pages:_pgs},"Pages added!","success")
        const last = _pgs.length - 1
        setActiveindex(last)
    }
    const onRemove = async () => {
        const _pages = cloneDeep(pages)
        setDeleteLoader(true)
        _pages.splice(activeIndex, 1)
        setActiveindex(activeIndex - 1)
        setPages(_pages)

        await updateBookData({ pages: _pages }, "Pages removed!", "error")
        setDeletePageModal(false)
        setDeleteLoader(false)
    }
    const onChangeHyPerLink = (data) => {
        const _pageHyperLinkIndex = findIndex(hyperlinks, k => k.page == data.page)
        let _hyperLink = hyperlinks ? cloneDeep(hyperlinks) : []
        if (_pageHyperLinkIndex >= 0) {
            _hyperLink[_pageHyperLinkIndex] = data
        } else {
            _hyperLink.push(data)
        }
        _hyperLink = _hyperLink.filter(k => k.links.length > 0)
        setHyperlinks(_hyperLink)
        updateBookData({ hyperlinks: _hyperLink }, "Hyperlink updated", "success")
    }

    const onChangeAnswerPage = (item) => {
        const url = item.url
        const pgTg = find(pagesTeacher, k => k.pageId == item.pageId)
        const _pagesTeacher = pagesTeacher ? cloneDeep(pagesTeacher) : []
        if (pgTg) {
            pgTg.resource = url;
            _pagesTeacher[findIndex(pagesTeacher, k => k.pageId == item.pageId)] = pgTg
        } else {
            _pagesTeacher.push({
                pageId: item.pageId,
                resource: url,
                description: ''
            })
        }
        setPagesTeacher(_pagesTeacher)
        updateBookData({ pagesTeacher: _pagesTeacher }, `Answer page updated!`, "success")
    }
    const onChangeAnswerDesc = (txt) => {
        const pgTg = find(pagesTeacher, k => k.pageId == pg.pageId)
        const _pagesTeacher = pagesTeacher ? cloneDeep(pagesTeacher) : []
        if (pgTg) {
            pgTg.description = txt;
            _pagesTeacher[findIndex(pagesTeacher, k => k.pageId == pg.pageId)] = pgTg
        } else {
            _pagesTeacher.push({
                pageId: pg.pageId,
                resource: '',
                description: txt
            })
        }
        setPagesTeacher(_pagesTeacher)
    }
    const onRemoveAnsPage = () => {
        const pgTgindex = findIndex(pagesTeacher, k => k.pageId == pg.pageId)
        const _pagesTeacher = cloneDeep(pagesTeacher)
        if (pgTgindex >= 0) {
            _pagesTeacher.splice(pgTgindex, 1)
            setPagesTeacher(_pagesTeacher)
        }
        updateBookData({ pagesTeacher: _pagesTeacher }, `Answer page removed!`, "error")
    }
    const onCHangeTeacherEdition = (item) => {
        const url = item.url
        const pgEd = find(teacherEdition, k => k.pageId == item.pageId)
        const _teacherEdition = teacherEdition ? cloneDeep(teacherEdition) : []
        if (pgEd) {
            pgEd.resource = url;
            _teacherEdition[findIndex(teacherEdition, k => k.pageId == item.pageId)] = pgEd
        } else {
            _teacherEdition.push({
                pageId: item.pageId,
                resource: url,
                description: ''
            })
        }
        setTeacherEdition(_teacherEdition)
        updateBookData({ teacherEdition: _teacherEdition }, `Teacher edition  page updated!`, "success")
    }
    const onCHangeTeacherEditionDesc = (txt) => {
        const pgEd = find(teacherEdition, k => k.pageId == pg.pageId)
        const _teacherEdition = teacherEdition ? cloneDeep(teacherEdition) : []
        if (pgEd) {
            pgEd.description = txt;
            _teacherEdition[findIndex(teacherEdition, k => k.pageId == pg.pageId)] = pgEd
        } else {
            _teacherEdition.push({
                pageId: pg.pageId,
                resource: '',
                description: txt
            })
        }
        setTeacherEdition(_teacherEdition)

    }
    const onRemoveTeacherEdition = () => {
        const pgEdindex = findIndex(teacherEdition, k => k.pageId == pg.pageId)
        const _teacherEdition = cloneDeep(teacherEdition)
        if (pgEdindex >= 0) {
            _teacherEdition.splice(pgEdindex, 1)
            setTeacherEdition(_teacherEdition)
        }
        updateBookData({ teacherEdition: _teacherEdition }, `Teacher edition  page removed!`, "error")
    }
    const onChangeLessionPlan = (data) => {
        const lessionPlanIndex = findIndex(lessonPlan, k => k.page == data.page)
        const _lessonPlan = lessonPlan ? cloneDeep(lessonPlan) : []
        if (lessionPlanIndex >= 0) {
            _lessonPlan[lessionPlanIndex] = data
        } else {
            _lessonPlan.push({
                page: pg.pageId,
                ...data
            })
        }
        setLessonPlan(_.uniqBy(_lessonPlan, "page"))
        updateBookData({ lessonPlan: _.uniqBy(_lessonPlan, "page") }, "Lession plan updated!", "success")
    }
    const onRemoveLessionPlan = (pageId) => {
        const lessionPlanIndex = findIndex(lessonPlan, k => k.page == pageId)
        const _lessonPlan = cloneDeep(lessonPlan)
        if (lessionPlanIndex >= 0) {
            _lessonPlan.splice(lessionPlanIndex, 1)
            setLessonPlan(_lessonPlan)
            updateBookData({ lessonPlan: _lessonPlan }, "Lession plan removed!", "error")
        }

    }

    const onChangeSuperLesson = (data) => {
        const superLessonIndex = findIndex(superLesson, k => k.page == data.page)
        const _superlesson = superLesson ? cloneDeep(superLesson) : []
        if (superLessonIndex >= 0) {
            _superlesson[superLessonIndex] = data
        } else {
            _superlesson.push({
                page: pg.pageId,
                ...data
            })
        }
        setSuperLesson(_.uniqBy(_superlesson, "page"))
        updateBookData({ superLesson: _.uniqBy(_superlesson, "page") }, "super Lesson plan updated!", "success")
    }
    const onRemoveSuperLesson = (pageId) => {
        const superLessonIndex = findIndex(superLesson, k => k.page == pageId)
        const _superlesson = cloneDeep(superLesson)
        if (superLessonIndex >= 0) {
            _superlesson.splice(superLessonIndex, 1)
            setSuperLesson(_superlesson)
            updateBookData({ superLesson: _superlesson }, "super Lesson plan removed!", "error")
        }

    }

    const onChangeDifferentiationLesson = (data) => {
        const superLessonIndex = findIndex(differentiationLesson, k => k.page == data.page)
        const _superlesson = differentiationLesson ? cloneDeep(differentiationLesson) : []
        if (superLessonIndex >= 0) {
            _superlesson[superLessonIndex] = data
        } else {
            _superlesson.push({
                page: pg.pageId,
                ...data
            })
        }
        setDifferentiationLesson(_.uniqBy(_superlesson, "page"))
        updateBookData({ differentiationLesson: _.uniqBy(_superlesson, "page") }, "Differentiation Lesson plan updated!", "success")
    }
    const onRemoveDifferentiationLesson = (pageId) => {
        const superLessonIndex = findIndex(differentiationLesson, k => k.page == pageId)
        const _superlesson = cloneDeep(differentiationLesson)
        if (superLessonIndex >= 0) {
            _superlesson.splice(superLessonIndex, 1)
            setDifferentiationLesson(_superlesson)
            updateBookData({ differentiationLesson: _superlesson }, "Differentiation Lesson plan removed!", "error")
        }

    }

    const onRemoveRubricsPlan = (pageId) => {
        const rubricsPlanIndex = findIndex(rubricsPlan, k => k.page == pageId)
        const _rubricsPlan = cloneDeep(rubricsPlan)
        if (rubricsPlanIndex >= 0) {
            _rubricsPlan.splice(rubricsPlanIndex, 1)
            setRubricsPlan(_rubricsPlan)
            updateBookData({ rubrics: _rubricsPlan }, "Rubrics  removed!", "error")
        }

    }
    const onChangeAssignmentPlan = (data) => {
        const assignmentPlanIndex = findIndex(assignmentPlan, k => k.page == data.page)
        const _assignmentPlan = assignmentPlan ? cloneDeep(assignmentPlan) : []
        if (assignmentPlanIndex >= 0) {
            _assignmentPlan[assignmentPlanIndex] = data
        } else {
            _assignmentPlan.push(data)
        }
        setAssignmentPlan(_.uniqBy(_assignmentPlan, "page"))
        updateBookData({ assignmentPlan: _.uniqBy(_assignmentPlan, "page") }, "Assignment plan updated!", "success")
    }
    const onChangeWorkbookPlan = (data) => {
        const workbookPlanIndex = findIndex(workbook_plan, k => k.page == data.page)
        const _workbook_plan = workbook_plan ? cloneDeep(workbook_plan) : []
        if (workbookPlanIndex >= 0) {
            _workbook_plan[workbookPlanIndex] = data
        } else {
            _workbook_plan.push(data)
        }
        setWorkbook_plan(_.uniqBy(_workbook_plan, "page"))
        updateBookData({ workbook_plan: _.uniqBy(_workbook_plan, "page") }, "Assignment plan updated!", "success")
    }

    const onChangeMainBook = (data) => {
        const mainBookIndex = findIndex(main_book, k => k.page == data.page)
        const _main_book = main_book ? cloneDeep(main_book) : []
        if (mainBookIndex >= 0) {
            _main_book[mainBookIndex] = data
        } else {
            _main_book.push(data)
        }
        setMainBook(_.uniqBy(_main_book, "page"))
        updateBookData({ main_book: _.uniqBy(_main_book, "page") }, "Main book updated!", "success")
    }
    const onChangeRunricsPlan = (data) => {
        const rubricsPlanIndex = findIndex(rubricsPlan, k => k.page == data.page)
        const _rubricsPlan = rubricsPlan ? cloneDeep(rubricsPlan) : []
        if (rubricsPlanIndex >= 0) {
            _rubricsPlan[rubricsPlanIndex] = data
        } else {
            _rubricsPlan.push(data)
        }
        setRubricsPlan(_.uniqBy(_rubricsPlan, "page"))
        updateBookData({ rubrics: _.uniqBy(_rubricsPlan, "page") }, "Rubrics plan updated!", "success")
    }
    const onRemoveAssignmentPlan = (pageId) => {
        const assignmentPlanIndex = findIndex(assignmentPlan, k => k.page == pageId)
        const _assignmentPlan = cloneDeep(assignmentPlan)
        if (assignmentPlanIndex >= 0) {
            _assignmentPlan.splice(assignmentPlanIndex, 1)
            setAssignmentPlan(_assignmentPlan)
            updateBookData({ assignmentPlan: _assignmentPlan }, "Assignment plan removed!", "error")
        }

    }
    const onRemoveWorkbookPlan = (pageId) => {
        const workbookPlanIndex = findIndex(workbook_plan, k => k.page == pageId)
        const _workbook_plan = cloneDeep(workbook_plan)
        if (workbookPlanIndex >= 0) {
            _workbook_plan.splice(workbookPlanIndex, 1)
            setAssignmentPlan(_workbook_plan)
            updateBookData({ workbook_plan: _workbook_plan }, "Assignment plan removed!", "error")
        }

    }

    const onRemoveMainbookPlan = (pageId) => {
        const mainbookindex = findIndex(main_book, k => k.page == pageId)
        const _main_book = cloneDeep(main_book)
        if (mainbookindex >= 0) {
            _main_book.splice(mainbookindex, 1)
            setMainBook(_main_book)
            updateBookData({ main_book: _main_book }, "main book removed!", "error")
        }

    }
    const onUpdateSubTopics = (data) => {
        setSubTopics(data)
        updateBookData({ subTopics: data }, "Units updated!", "success")
    }
    const onCreateOrUpdate = async () => {
        const errors = []
        const hasMaskedNumber = _.find(pages, p => p.NumberMasked)
        const _pages = []
        _.map(pages, (p, i) => {
            try {
                if (hasMaskedNumber) {
                    if (i == 0) {
                        if (!p.NumberMasked) {
                            // p.NumberMasked = convertToRoman(1)
                        }
                    }
                    else {
                        if (!p.NumberMasked) {
                            const maskNumber = getNextNumberMask(get(_pages[i - 1], "NumberMasked", convertToRoman(1)), get(_pages[i - 2], "NumberMasked"))
                            if (maskNumber != p.NumberMasked) {
                                if (!p.NumberMasked) {
                                    // p.NumberMasked = maskNumber;
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.log(error, "num mask error", error);
            }

            _pages.push(p);
        })
        const book = {
            count,
            description,
            idTypeBook: '1',
            img: cover,
            resource: cover,
            stateBook: 'active',
            pages: _pages,
            pagesTeacher: filter(pagesTeacher, pg => pg.resource),
            subTopics,
            isNotAssignable,
            lessonPlan,
            teacherEdition: filter(teacherEdition, pg => pg.resource),
            assignmentPlan,
            workbook_plan,
            main_book,
            demoPages,
            hyperlinks,
            onlyTeacher,
            teacherEditionBook,
            level,
            storyId,
            type,
            printable
        }
        if (!book.description || book.description.length == 0) {
            errors.push({ type: 'description_error', message: 'Book title required.\n' })
        }
        if (!book.img || book.img.length == 0) {
            errors.push({ type: 'cover_error', message: 'Cover image required.\n' })
        }
        const hasNotResonPage = filter(pages, pg => !pg.resource)
        if (hasNotResonPage.length > 0) {
            errors.push({ type: 'page_error_img', message: `Page image on page id ${hasNotResonPage.map(k => k.pageId).join(",")} is required \n` })
        }

        setErrors(errors)
        if (errors.length == 0) {
            setloading(true)
            if (editBook) {
                await dispatch(handleUpdateBook(editBook.id, book))
                setAlertSakeBar({
                    open: true,
                    message: "Book updated successfully",
                    severity: "success"
                })
                setSaveModal(false)
            } else {
                const cBook = await dispatch(handleCreateBook(book))
                if (has(cBook, "id")) {
                    setSaveModal(false)
                    setAlertSakeBar({
                        open: true,
                        message: "Book created successfully",
                        severity: "success"
                    })
                    setTimeout(() => {
                        history.push(`/add-book?bookId=${cBook.id}`)
                        history.go(0)
                    }, 1000)

                } else {
                    setErrors([{ type: 'book_creation', message: 'Error in book creation please retry!\n' }])
                }
            }
            setloading(false)
        } else {
            setloading(false)
        }

    }
    const updateBookData = async (book, message, messageType) => {
        setdataloader(true)
        try {
            if (editBook && editBook.id) {
                setUpdateLoader(true)
                const data = await dispatch(handleUpdateBook(editBook.id, book))
                if (has(data, "id")) {
                    setAlertSakeBar({
                        open: true,
                        message: message ? message : "Book updated successfully",
                        severity: messageType ? messageType : "success"
                    })
                    setUpdateLoader(false)
                } else {
                    setAlertSakeBar({
                        open: true,
                        message: "having some issue while updating book.",
                        severity: "error"
                    })
                }
            }  
        } catch (error) {
            MessageAlert.error(`having some issue while updating book.`)
            console.log("having some issue while updating book.", error)
            
        } finally {
            setdataloader(false)
        }
    }
    const updateBookDataByPageWithKey = async (pageId,fieldData, fieldName , message, messageType) => {
        setdataloader(true)
        try {
            if (editBook && editBook.id) {
                setUpdateLoader(true)
                const data = await dispatch(handleUpdateBookByPage(
                    editBook.id, pageId?pageId:pg.pageId,fieldData, fieldName
                ))
                if (data.success) {
                    MessageAlert.success( message ? message : "Book updated successfully")
                    setUpdateLoader(false)
                } else {
                    MessageAlert.error("having some issue while updating book.")
                }
            }  
        } catch (error) {
            MessageAlert.error(`having some issue while updating book.`)
            console.log("having some issue while updating book.", error)
            
        } finally {
            setdataloader(false)
        }
    }
    const onPageIdChange = (id) => {
        const pageIndex = findIndex(pages, p => p.pageId == id)
        if (pageIndex >= 0) {
            setActiveindex(pageIndex)
        }
    }
    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: '', severity: '' })
    }
    const disableForWard = (activeIndex >= pages.length - 1)
    const disableBackWrad = activeIndex <= 0
    const pageHyperLink = find(hyperlinks, k => k.page == pg.pageId)
    const pageTeacherPage = find(pagesTeacher, k => k.pageId == pg.pageId)
    const pageteacherEdition = find(teacherEdition, k => k.pageId == pg.pageId)
    const pgLessonPlan = find(lessonPlan, k => k.page == pg.pageId)
    const pgSuperLesson = find(superLesson, k => k.page == pg.pageId)
    const pgDifferentiation = find(differentiationLesson, k => k.page == pg.pageId)
    const pgAssignmentPlan = find(assignmentPlan, k => k.page == pg.pageId)
    const pgWorkbookPlan = find(workbook_plan, k => k.page == pg.pageId)
    const pgMainbook = find(main_book, k => k.page == pg.pageId)
    const pgRubricsPlan = find(rubricsPlan, k => k.page == pg.pageId)
    const pageActivities = filter(bookActivities, bk => bk.page == pg.pageId)
    const pageQuizzes = filter(bookQuizzes, bk => bk.page == pg.pageId)
    const pageTips = find(bookTips, bk => bk.page == pg.pageId)
    const pageIntrections = filter(bookIntractions, bk => bk.page == pg.pageId)
    return (
        <>
            <Modal
                open={dataloader}
            >
                <VideoContainer>
                    <Spin size="large" />
                </VideoContainer>
            </Modal>
            <DeleteModal deleteToggle={deletePageModal} setDeleteToggle={setDeletePageModal} loading={deleteLoader} message={`Are you sure to remove this page?`} onDelete={onRemove} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertSakeBar.open}
                autoHideDuration={3000}
                onClose={closeAlert}>
                <Alert onClose={() => { setAlertSakeBar({ open: false, message: '' }) }} severity={alertSakeBar.severity}>
                    <strong>ALERT:</strong> {alertSakeBar.message}
                </Alert>
            </Snackbar>
            <Modal
                open={saveModal}
                onClose={() => {
                    setSaveModal(false)
                    setErrors([])
                }}
                style={{ height: '100%', width: '100%' }}
            >
                <ModalContent>
                    <Row style={{ justifyContent: "space-between" }}>
                        <TitleText>{editBook ? 'Update Book' : 'Add Book'}</TitleText>
                        <IconButton onClick={() => setSaveModal(false)} style={{ padding: 0, marginRight: 10 }}>
                            <Close color="secondary" />
                        </IconButton>
                    </Row>
                    <ModalConfigArea>
                        <Row>
                            <Col xs={24} lg={12} style={{ marginBottom: 20 }}>
                                <CoverText>Cover image</CoverText>
                                <PageImagePicker
                                    src={cover}
                                    onChange={item => setCover(item.url)}
                                    pageId={pg.pageId}
                                    hasDescription={false}
                                />
                            </Col>
                            <Col xs={24} lg={12} style={{ paddingLeft: 10, paddingTop: 10 }}>
                                <Row justify="start" style={{ marginBottom: 20, }}>
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="Book title"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        type="text"
                                        required
                                        variant="outlined"
                                        onChange={e => {
                                            setDescription(e.target.value)
                                        }}
                                        value={description}
                                    />
                                </Row>
                                <Row justify="start" style={{ marginBottom: 20, }}>
                                    <TextField
                                        id="teacherEditionBook"
                                        name="teacherEditionBook"
                                        label="Book Id for teacher edition book."
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        onChange={e => {
                                            setTeacherEditionBook(e.target.value)
                                        }}
                                        value={teacherEditionBook}
                                    />
                                </Row>
                                <Row justify="start" style={{ marginBottom: 20, }} gutter={[24,24]}>
                                    <Col xs={24} lg={12}>
                                        <TextField
                                            id="demoPageFrom"
                                            name="demoPageFrom"
                                            label="Demo page start from."
                                            required={false}
                                            InputLabelProps={{ shrink: true }}
                                            type="text"
                                            variant="outlined"
                                            onChange={e => {
                                                if (demoPages) {
                                                    setDemoPages({ ...demoPages, from: e.target.value })
                                                } else {
                                                    setDemoPages({ from: e.target.value, to: '' })
                                                }
                                            }}
                                            value={get(demoPages, "from")}
                                        />
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <TextField
                                            id="demoPageTo"
                                            name="demoPageTo"
                                            label="Demo page end to."
                                            required={false}
                                            InputLabelProps={{ shrink: true }}
                                            type="text"
                                            variant="outlined"
                                            onChange={e => {
                                                if (demoPages) {
                                                    setDemoPages({ ...demoPages, to: e.target.value })
                                                } else {
                                                    setDemoPages({ to: e.target.value, from: '' })
                                                }
                                            }}
                                            value={get(demoPages, "to")}
                                        />
                                    </Col>

                                </Row>
                                <Row style={{marginBottom:20}}>
                                    <Col xs={12} lg={12}>
                                        <FormControlLabel
                                            classes={{
                                                root: classes.rootDifFull,
                                                label: classes.label,
                                            }}
                                            control={
                                                <Switch
                                                    classes={{
                                                        sizeSmall: classes.sizeSmall,
                                                        checked: classes.checkedSwitch
                                                    }}
                                                    checked={Boolean(printable)}
                                                    id="Printable"
                                                    onChange={e => {
                                                        setPrintable(e.target.checked)
                                                    }}
                                                    name="Printable"
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Printable"
                                        />
                                    </Col>
                                    <Col xs={12} lg={12}>
                                        <FormControlLabel
                                            classes={{
                                                root: classes.rootDifFull,
                                                label: classes.label,
                                            }}
                                            control={
                                                <Switch
                                                    classes={{
                                                        sizeSmall: classes.sizeSmall,
                                                        checked: classes.checkedSwitch
                                                    }}
                                                    checked={Boolean(count)}
                                                    id="count"
                                                    onChange={e => {
                                                        setCount(e.target.checked)
                                                    }}
                                                    name="count"
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Count"
                                        />
                                    </Col>
                                    <Col xs={12} lg={12} style={{marginTop:10}}>
                                        <FormControlLabel
                                            classes={{
                                                root: classes.rootDifFull,
                                                label: classes.label,
                                            }}
                                            control={
                                                <Switch
                                                    classes={{
                                                        sizeSmall: classes.sizeSmall,
                                                        checked: classes.checkedSwitch
                                                    }}
                                                    checked={Boolean(onlyTeacher)}
                                                    id="onlyTeacher"
                                                    onChange={e => {
                                                        setOnlyTeacher(e.target.checked)
                                                    }}
                                                    name="onlyTeacher"
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Only for teacher"
                                        />
                                    </Col>
                                </Row>
                                {/* <Row justify="start" style={{ marginBottom: 20, }}>
                                    <FormControlLabel
                                        classes={{
                                            root: classes.rootDifFull,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Switch
                                                classes={{
                                                    sizeSmall: classes.sizeSmall,
                                                    checked: classes.checkedSwitch
                                                }}
                                                checked={Boolean(count)}
                                                id="count"
                                                onChange={e => {
                                                    setCount(e.target.checked)
                                                }}
                                                name="count"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Count"
                                    />
                                </Row>
                                <Row justify="start" style={{ marginBottom: 20, }}>
                                    <FormControlLabel
                                        classes={{
                                            root: classes.rootDifFull,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Switch
                                                classes={{
                                                    sizeSmall: classes.sizeSmall,
                                                    checked: classes.checkedSwitch
                                                }}
                                                checked={Boolean(onlyTeacher)}
                                                id="onlyTeacher"
                                                onChange={e => {
                                                    setOnlyTeacher(e.target.checked)
                                                }}
                                                name="onlyTeacher"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Only for teacher"
                                    />
                                </Row> */}
                                <Tag style={{marginTop:20}} height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                                <Note style={{ marginBottom: 10 }}>BOOK IS NOT ASSIGNABLE mins This book cannot be assigned to a teacher or student as the main book. </Note>
                                <Row justify="start" style={{ marginBottom: 20, }}>
                                    <FormControlLabel
                                        classes={{
                                            root: classes.rootDifFull,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Switch
                                                disabled={editBook && editBook.id && ['49', '33', '41', '43'].includes(editBook.id)}
                                                classes={{
                                                    sizeSmall: classes.sizeSmall,
                                                    checked: classes.checkedSwitch
                                                }}
                                                checked={Boolean(isNotAssignable)}
                                                id="isNotAssignable"
                                                onChange={e => {
                                                    setIsNotAssignable(e.target.checked)
                                                }}
                                                name="isNotAssignable"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Book is not Assignable"
                                    />
                                </Row>
                                <Row justify="start" style={{ marginBottom: 20, }}>
                                    <FormControlLabel
                                        classes={{
                                            root: classes.rootDifFull,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Switch
                                                classes={{
                                                    sizeSmall: classes.sizeSmall,
                                                    checked: classes.checkedSwitch
                                                }}
                                                checked={type === "story"}
                                                id="type_story"
                                                onChange={e => {
                                                    setType(e.target.checked ? "story" : null)
                                                }}
                                                name="type_story"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="Story book"
                                    />
                                </Row>
                                {type === "story" ? <Row justify="start" style={{ marginBottom: 20, }}>
                                    <TextField
                                        id="storyId"
                                        name="storyId"
                                        label="storyId"
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        onChange={e => {
                                            setStoryId(e.target.value)
                                        }}
                                        value={storyId}
                                    />
                                </Row> : null}

                                <Row style={{ marginBottom: 30, marginRight: 0, marginLeft: 0 }} className="floatingLabelBG">
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="type">Level</InputLabel>
                                        <Select
                                            labelId="level"
                                            id="level"
                                            value={level || ''}
                                            onChange={(v) => {
                                                setlevel(v.target.value)
                                            }}
                                            label="Type"
                                        >
                                            <MenuItem value={''}>Other</MenuItem>
                                            <MenuItem value={'BASICO'}>BASICO</MenuItem>
                                            <MenuItem value={'INTERMEDIO'}>INTERMEDIO</MenuItem>
                                            <MenuItem value={'AVANZADO'}>AVANZADO</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Row>
                                {bookErrors.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                                    {bookErrors.map(v => (<Typography variant="subtitle2" key={v.type} style={{ whiteSpace: "pre-wrap" }} color="error">
                                        * {get(v, "message")}
                                    </Typography>))}
                                </Row> : null}
                                <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                                    <Button fullWidth onClick={onCreateOrUpdate} variant="contained" color="primary">    {loading ? <Loading white size={15} /> : null}  {editBook ? "Update" : "Create"}</Button>
                                </Row>
                            </Col>
                        </Row>
                    </ModalConfigArea>
                </ModalContent>
            </Modal>
            <Wrapper className="slideLftRghtUpr">
                <Row style={{ height: 50 }}>
                    <Col span={1}>
                        <ButtonContainer
                            ref={backBtnRef}
                            disabled={disableBackWrad}
                            onClick={onBackward}
                            left={0} className="cstmLeftArow">
                            <ArrowBackIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>
                    </Col>
                    <Col span={22} style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={addPage}
                            disabled={updateLoader}
                            startIcon={<Add />}
                        >
                            Add Page
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={updateLoader}
                            onClick={() => { setSaveModal(true) }}
                        >
                            Save
                        </Button>
                        {activeIndex === pages.length - 1 && pages.length > 1 ? <IconButton onClick={() => setDeletePageModal(true)}>
                            <Delete disabled={updateLoader} />
                        </IconButton> : null}
                    </Col>
                    <Col span={1}>
                        <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={disableForWard}
                            onClick={onForward}
                            right={0} className="cstmRghtArow">
                            <ArrowForwardIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>
                    </Col>
                </Row>
                <Container className="eBookTabLftUpr">
                    <Pages
                        src={pg ? pg.resource : null}
                        onChange={onPageImageChange}
                        pageId={get(pg, "pageId")}
                        disabled={updateLoader}
                        description={get(pg, "description", "")}
                        onChangeDescription={onChangeDescription}
                        hasDescription={false}
                    />
                    <ToolsContainer>
                        <PageToolbar
                            pageId={pg.pageId}
                            numberMask={pg.NumberMasked}
                            onMaskChange={onMaskChanged}
                            hyperlinks={pageHyperLink}
                            teacherPage={pageTeacherPage}
                            teacherEdition={pageteacherEdition}
                            pageActivities={pageActivities}
                            pageQuizzes={pageQuizzes}
                            onChangeHyperlink={onChangeHyPerLink}
                            onChangeAnswerPage={onChangeAnswerPage}
                            onChangeAnswerDesc={onChangeAnswerDesc}
                            onCHangeTeacherEdition={onCHangeTeacherEdition}
                            onCHangeTeacherEditionDesc={onCHangeTeacherEditionDesc}
                            onRemoveAnsPage={onRemoveAnsPage}
                            onRemoveTeacherEdition={onRemoveTeacherEdition}
                            pgLessonPlan={pgLessonPlan}
                            pgSuperLesson={pgSuperLesson}
                            pgDifferentiation={pgDifferentiation}
                            pgAssignmentPlan={pgAssignmentPlan}
                            pgWorkbookPlan={pgWorkbookPlan}
                            pgMainbook={pgMainbook}
                            pgRubricsPlan={pgRubricsPlan}
                            booksAllList={booksAllList}
                            onChangeAssignmentPlan={onChangeAssignmentPlan}
                            onChangeWorkbookPlan={onChangeWorkbookPlan}
                            onChangeMainBook={onChangeMainBook}
                            onChangeRunricsPlan={onChangeRunricsPlan}
                            onChangeLessionPlan={onChangeLessionPlan}
                            onChangeSuperLesson={onChangeSuperLesson}
                            onChangeDifferentiationLesson={onChangeDifferentiationLesson}
                            onRemoveLessionPlan={onRemoveLessionPlan}
                            onRemoveSuperLesson={onRemoveSuperLesson}
                            onRemoveDifferentiationLesson={onRemoveDifferentiationLesson}
                            onRemoveRubricsPlan={onRemoveRubricsPlan}
                            onRemoveAssignmentPlan={onRemoveAssignmentPlan}
                            onRemoveWorkbookPlan={onRemoveWorkbookPlan}
                            onRemoveMainbookPlan={onRemoveMainbookPlan}
                            subTopics={subTopics}
                            onUpdateSubTopics={onUpdateSubTopics}
                            onPageIdChange={onPageIdChange}
                            page={pg}
                            bookIdVoc={bookIdVoc}
                            disabled={updateLoader}
                            bookId={get(editBook, "id")}
                            pageTips={pageTips}
                            pageIntrections={pageIntrections}
                            pgDescription={pg.description}
                            pgTags={pg.tags}
                            onChangeOrUpdateDescription={onChangeOrUpdateDescription}
                        />
                    
                    </ToolsContainer>
                </Container>
            </Wrapper>
        </>
    )
}

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    booksAllList: state.booksAllList,
    activities: state.activities,
    quizzes: state.quizzes,
    interactions: state.interactions,
    tips: state.tipsBook
});

export default connect(mapStateToProps, null)(Index);