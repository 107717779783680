//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { lighten } from 'polished';
import { find , get , has } from 'lodash';

//  -- Components ---------------------
import { Assignment, Extension } from '@material-ui/icons';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 4px;
`;

const Row = styled.div`
    align-items: ${p => p.align || 'center'};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;
    width: 100%;

    &:last-child {
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 0px;
    }
`;

const Text = styled.span`
    color: ${COLOR.black500};
    font-size: 11px;
    margin-top: 2px;
    text-align: left;
`;

const TextLarge = styled(Text)`
    color: ${lighten(0.15, 'red')};
    font-size: 14px;
    font-weight: 600;
    margin-right: 4px;
`;

const Title = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
`;

const Wrapper = styled.div`
    background-color: ${COLOR.white};
    border: 2px dashed ${lighten(0.25, 'red')};
    border-radius: 4px;
    color: ${lighten(0.15, 'red')};
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
    outline: none;
    padding: 12px 12px 10px;
    transition: all 300ms ease;
    width: 100%;

    &:last-child {
        margin-bottom: 16px;
    }
`;
const BookDescRow = styled(Row)`
        font-size: 10px;
        padding-left: 30px;
        margin-top: -15px;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Announcement component
//
//  -- Props
//  ...
//
const PastAssignment = ({
    assignment,
    submissions,
}) => {
    const activeSubmission = find(submissions, ['assignmentId', assignment.data.id]);
    const bookDescription = get(assignment,"data.bookDescription")
    const displayname  = get(assignment,"data.displayName","")
    const ungraded = get(assignment, "ungraded", false)
    const grade_type = get(assignment,"grade_type");
    const isCompleteInComplete = grade_type === "complete_incomplete"
    const max_point = assignment?.max_point && !isNaN(assignment?.max_point) ? Number.parseInt(assignment.max_point, 10) : 100
    const isPointSystem =  grade_type === "points"
    const getGrade = () => {
        if (!activeSubmission || !activeSubmission.id) return '-';
        const grade = activeSubmission?.grade >= 0 ? activeSubmission?.grade : ''
        let isTbgObj = find(get(activeSubmission, "assignment.questions"), (e) => e.type != "multipleChoice")
        const isTbg = activeSubmission?.manualGrading && typeof isTbgObj == "object"
        if (ungraded) return 'Turned in'
        if (isCompleteInComplete && has(activeSubmission, "id")) {
            return activeSubmission?.grade > 0 ? 'Completed' : 'In completed';
        } else if (isPointSystem) {
            if (isTbg) return "TBG";
            else if (grade > 0 && max_point > 0) {
                return `${Math.round((grade / 100) * max_point)}/${max_point}`
            } else {
                return `0/${max_point}`;
            }
        }
        return isTbg ? "TBG" : (grade)
    }
    return (
        <Wrapper>
            <Row align="flex-start">
                {assignment?.data?.typeId === 'activity'
                    ? <Extension style={{ height: '18px', marginRight: '4px' }} />
                    : <Assignment style={{ height: '18px', marginRight: '4px' }} />
                }
                <Title>{displayname}</Title>
            </Row>
            {bookDescription ? <BookDescRow><strong>Book</strong>: {bookDescription}</BookDescRow> : null}
            <Row>
                <Column>
                    <Text>Submissions: <strong style={{ color: COLOR.black300 }}>{activeSubmission?.submissions || '0'}</strong></Text>
                    {get(assignment,"scheduleAt")?(<Text>Start Date: <strong style={{ color: COLOR.black300 }}> {moment(get(assignment,"scheduleAt")).format('MMM DD, YYYY hh:mm A')}</strong></Text>):null}
                    <Text>Due Date: <strong style={{ color: COLOR.black300 }}> {moment(get(assignment,"dueDate")).format('MMM DD, YYYY hh:mm A')}</strong></Text>
                    {activeSubmission?.graded && (
                        <Text>
                            Grade:{" "}
                            <strong style={{ color: COLOR.black300 }}>
                                {getGrade()}
                            </strong>
                        </Text>
                    )}
                </Column>
                <TextLarge complete={activeSubmission?.complete}>{activeSubmission?.complete ? 'Completed' : 'Past Due'}</TextLarge>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
PastAssignment.propTypes = {};

//  -- Default props ------------------
PastAssignment.defaultProps = {};

export default PastAssignment;
