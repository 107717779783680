
//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------


const cleverCourcesInit =[]
export const cleverCourses = (state = cleverCourcesInit, action) => {
    switch (action.type) {
        case ActionTypes.clever.setCleverCources:
            return action.payload
        default:
            return state;
    }
};

export const cleverUserInfo = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.clever.setCleverCources:
            return action.payload
        default:
            return state;
    }
};