import { Checkbox, LinearProgress } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useEffect, useState } from "react";
import Draggable from 'react-draggable';
import { useDispatch } from "react-redux";
import { revokeCheckListModal } from "store/wheel/actions";
import { useCheckList } from "store/wheel/selectors";
import styled from "styled-components";
import COLOR from "utils/constants/color";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
//   margin: 15px auto;/
    transform: translate(31%, 0%);

    @media (max-width: 1450px) {
        // width: 96%;
        transform: translate(0%, 0%);
    }
    @media (max-width: 992px) {
        // width: 95%;
        transform: translate(0%, 0%);
    }

`;

const CheckListWorker = ({ checkData }) => {
    const dispatch = useDispatch();
    const [checkListModal] = useCheckList();
    const [checked, setChecked] = useState([]);
    const [worker, setWorker] = useState(null);

    const [currentTaskIndex, setCurrentTaskIndex] = useState(-1);
    const [taskQue, setTaskQue] = useState([]);
    const [progress, setProgress] = useState(0);
    const filterByClass = checkListModal?.data ?? []

    function getTimeDifference(targetTime) {
        const [targetHours, targetMinutes] = targetTime.split(':').map(Number);
    
        const now = new Date();
        const targetDate = new Date(now);
        targetDate.setHours(targetHours);
        targetDate.setMinutes(targetMinutes);
        targetDate.setSeconds(0);
        targetDate.setMilliseconds(0);
    
        const timeDifference = targetDate - now;
        console.log('timeDifference d', timeDifference);
        return timeDifference >= 0 ? timeDifference : timeDifference + 24 * 60;
      }
    

    useEffect(() => {
        if (Array.isArray(checkData?.tasks)) {
            const timeDifference = getTimeDifference(checkData?.tasks[0].startTime);
            // console.log('timeDifference', timeDifference);
            setTaskQue(checkData?.tasks);
        }
    }, [checkData])


    useEffect(() => {
        if (taskQue.length > 0 && (currentTaskIndex > 0 && taskQue.length > currentTaskIndex)) {
            const task = taskQue[currentTaskIndex];
            const totalTime = parseInt(task.minutes || 0) * 60
            worker.postMessage({ command: 'start', time: totalTime });
        }
    }, [currentTaskIndex, taskQue])

    const onTaskDone = () => {
        setCurrentTaskIndex(currentTaskIndex + 1)
    }


    useEffect(() => {
        const newWorker = new Worker(`${process.env.PUBLIC_URL}/timeWorker.js`);
        setWorker(newWorker);
        newWorker.onmessage = (event) => {
            switch (event.data.type) {
                case 'tick':
                    // setTime(event.data.countdownTime);
                    const task = taskQue[currentTaskIndex];
                    const totalTime = parseInt(task.minutes || 0) * 60
                    const percentage = Math.min(100, (event.data.countdownTime / totalTime) * 100);
                    setProgress(percentage)
                    console.log('tick', event.data, 'percentage', percentage);
                    break;
                case 'done':
                    onTaskDone()
                    console.log('done', event.data);
                    break;
            }
        };
        return () => newWorker.terminate();
    }, []);



    // const calculateProgress = (taskText) => {
    //   return progress[taskText] || 0;
    // };

    function convertTo12HourFormat(time24) {
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`;
    }

    const calculateProgress = (startTime, endTime) => {
        const start = new Date();
        const end = new Date();
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        start.setHours(startHours);
        start.setMinutes(startMinutes);
        const [endHours, endMinutes] = endTime.split(':').map(Number);
        end.setHours(endHours);
        end.setMinutes(endMinutes);

        const currentTime = Date.now();
        if (currentTime < start.getTime()) {
            return 0;
        }

        if (currentTime > end.getTime()) {
            return 100;
        }

        const totalTime = end - start;
        const elapsedTime = currentTime - start.getTime();
        const progress = (elapsedTime / totalTime) * 100;
        console.log('progress', progress);
        return progress;
    };

    const handleChange = (event, value) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setChecked([...checked, value]);
        } else {
            setChecked(checked.filter((item) => item !== value));
        }
    };

    const onClose = async (data) => {
        await dispatch(revokeCheckListModal(data));
    };

    return (
        <Draggable>
            <Container className="allCheckListSEction wigetsContainer">
                <div className="mainCheckList">
                    <div className="checkListTopSection" style={{ position: 'relative', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px' }}>
                        <CancelIcon onClick={() => onClose(checkData)} style={{ marginLeft: '5px', position: 'absolute', right: 0, top: 0, fontSize: 30, color: 'red', background: 'white', borderRadius: 20 }} />
                        <div className="checkListFirstTxt">
                            <span>{checkData?.title}</span>
                        </div>
                        <div className="checkListTopWidth">
                            {checkData?.tasks.map((task, index) => (
                                <>
                                    <div key={index} className="mainMidSection secondCheckList checkListName">
                                        <div className="checkListMidSection secondCheckListNum">
                                            {task?.imageUrl ? (
                                                <span>{task?.imageUrl}</span>
                                            ) : (
                                                <img
                                                    src={require("../utils/assets/images/checkList_Emoji.jpg")}
                                                    alt=""
                                                />
                                            )}
                                            <span style={{ textDecoration: checked.includes(task?.text) ? 'line-through' : 'none' }}>{task?.text ? task?.text : 'Untitled List'}</span>
                                        </div>
                                        {checkData?.list_type === "checkBox" && (
                                            <div className="">
                                                <Checkbox checked={checked.includes(task?.text)} onChange={(e) => handleChange(e, task?.text)} />
                                            </div>
                                        )}
                                    </div>
                                    {checkData.list_type === 'timeBased' && (
                                        <div key={index} className="mainMidSection secondCheckList checkListName">
                                            <span style={{ textDecoration: checked.includes(task?.text) ? 'line-through' : 'none' }}>{convertTo12HourFormat(task.startTime)}</span>
                                            <LinearProgress className="msgSndLine" style={{ width: "70%", color: COLOR.green100 }} variant="determinate" value={calculateProgress(task.startTime, task.endTime)} />
                                            <span style={{ textDecoration: checked.includes(task?.text) ? 'line-through' : 'none' }}>{convertTo12HourFormat(task.endTime)}</span>
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </Draggable>
    );
};

export default CheckListWorker;
