//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const invokeHyperlinkModal = (links) => ({ type: ActionTypes.hyperlink.invokeHyperlinkModal, payload: links });
export const revokeHyperlinkModal = () => ({ type: ActionTypes.hyperlink.revokeHyperlinkModal, payload: [] });

//  -- List All books ----------------
