//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import * as Sentry from '@sentry/react';


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Note
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const SentryWrap = ({ children }) => (
    <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
            <div>This component has encountered an error. Don't worry, we are on it!</div>
        </React.Fragment>
    )}>
        {children}
    </Sentry.ErrorBoundary>
);

//  -- Prop types ---------------------
SentryWrap.propTypes = {};

//  -- Default props ------------------
SentryWrap.defaultProps = {
    note: {
        content: 'Here is a note for viewing',
        created: '2020-06-24T12:34:00Z'
    }
};

export default SentryWrap;