//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map, filter } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const instructionsInitial = [];


const instructions = (state = instructionsInitial, action) => {
    switch (action.type) {
        case ActionTypes.instructions.createInstructionsSuccess:
            return [action.payload, ...state];
        case ActionTypes.instructions.listInstructionsSuccess:
            return action.payload;
        case ActionTypes.instructions.updateInstructions:
            return map(state, (instructions) => instructions.id === action.payload.id ? action.payload : instructions)
        case ActionTypes.instructions.deleteInstructionsSuccess:
            return filter(state, (instructions) => instructions.id !== action.payload);
        default:
            return state;
    }
};

export default instructions;