//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { Row, Col } from "antd"
import { DeleteModal } from "components/Dialog";
import { useDispatch } from 'react-redux';
import { handleUpdateExams } from 'store/exams/thunks';
import { handleUpdateApExams } from 'store/apexams/thunks';
import { cloneDeep } from 'lodash';
import { filter } from 'lodash';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;

const Text = styled.span`
    // color: rgb(0 0 0 / 85%);
    font-size: 15px;
    color:white;
`;

const TextContainer = styled.div`
    border-top: 1px solid ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    padding:0px 16px;
    background-color: #171E2D;
   
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;
const Label = styled.div`
    position: absolute;
    top: 30%;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    background: ${p => p.bgColor ? p.bgColor : COLOR.redishpink}; 
`;

const AssessmentCoversCard = ({ data, handleClick, labelBgColor, label, exam , from='exams' }) => {
    const [deleteLoading, setDeleteLoading] = useState(false)
    const disptch = useDispatch()
    const [deleteModal, setDeleteModal] = useState(false)
    const onDelete = async () => {
        setDeleteLoading(true)
        let dt = cloneDeep(exam)
        dt.exams = filter(dt.exams, k => k.id != data.id)
        if(from == 'apexams'){
            await disptch(handleUpdateApExams(dt))
        }else{
            await disptch(handleUpdateExams(dt))
        }
        setDeleteLoading(false)
    }
    return (
        <Wrapper>
            <DeleteModal deleteToggle={deleteModal} setDeleteToggle={setDeleteModal} loading={deleteLoading} message={`Are you sure you want to delete this exam? `} onDelete={onDelete} />
            <Row>
                <Col>
                    <IconButton color="error" size="small" onClick={() => { setDeleteModal(true) }}>
                        <Delete color="error" />
                    </IconButton>
                </Col>
            </Row>
            <div className="card-resource">
                <a onClick={handleClick}>
                    <div className="card-resource-overlay"></div>
                    <Image className="card-resource-image" src={data.src} alt={data.displayName} />
                    <Label bgColor={labelBgColor}>{label}</Label>
                    <div className="card-resource-details fadeIn-bottom" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h3 className="card-resource-title">{data.displayName}</h3>
                        <Edit  color="action" style={{ color: "#fff",fontSize:20,marginLeft:5 }} />

                    </div>
                </a>
            </div>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentCoversCard.propTypes = {
    data: shape({
        img: string,
        title: string,
        description: string,
    }),
    handleClick: func
};

//  -- Default props ------------------
AssessmentCoversCard.defaultProps = {
    data: {},
};

export default AssessmentCoversCard;