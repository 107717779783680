//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { FormControlLabel, Switch } from '@material-ui/core';
import Loading from 'components/Loading';
//  -- Features -----------------------
import Announcements from 'features/Announcements';
import PostForm from 'features/forms/Post';
import { arrayOf, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
//  -- Thunks -------------------------
import { handleUpdateClass } from 'store/classes/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';






//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 64px 0px;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
`;

const Tab = styled.button`
    background-color: transparent;
    border: none;
    border-bottom: ${p => p.active ? `2px solid #3D5F8F` : `2px solid ${COLOR.black800}`};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 50%;
`;

const Wrap = styled.div`
    align-items: center;
    border-bottom: 1px dashed ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 16px;
    padding: 0px 0px 16px;
`;

const Wrapper = styled.div`
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  AdminAnnouncements
//
//  -- Description
//  District and class announcements
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <AdminAnnouncements data={array} />
//
const AdminAnnouncements = ({
    currentClass,
    currentOrg,
    posts,
    type,
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [tab, setActiveTab] = useState(0);

    // useEffect(() => {
    //     async function fetchData() {
    //         if (tab === 0) {
    //             setLoading(true);
    //             await dispatch(handleListClassPosts(currentClass.id));
    //             setLoading(false);
    //         } else {
    //             setLoading(true);
    //             const id = currentOrg.parentId ? currentOrg.parentId : currentOrg?.id;
    //             await dispatch(handleListOrgPosts(id));
    //             setLoading(false);
    //         }
    //     }
    //     fetchData();
    // }, [currentClass, currentOrg, dispatch, tab])

    const togglePosting = async (checked) => {
        await dispatch(handleUpdateClass(currentClass.id, { ...currentClass, studentPosting: checked }))
    };

    return (
        <Wrapper>
            <Row>
                <Tab active={tab === 0} onClick={() => setActiveTab(0)}>Class</Tab>
                <Tab active={tab === 1} onClick={() => setActiveTab(1)}>District</Tab>
            </Row>
            {tab === 0 ? (
                <>
                    {type === 'class' && (
                        <Wrap>
                            <FormControlLabel
                                control={
                                    <Switch
                                        defaultChecked={currentClass.studentPosting}
                                        onChange={e => togglePosting(e.target.checked)}
                                        name="view"
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label="Allow Students to Post"
                            />
                        </Wrap>
                    )}
                    {loading ? (
                        <Container>
                            <Loading />
                        </Container>
                    ) : (
                        <>
                            <Announcements noHeader tab={tab} />
                            <PostForm type='class' />
                        </>
                    )}
                </>
            ) : (
                <>
                    {loading ? (
                        <Container>
                            <Loading />
                        </Container>
                    ) : (
                        <>
                            <Announcements header="District Announcements" tab={tab} />
                            <PostForm type='district' />
                        </>
                    )}
                </>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AdminAnnouncements.propTypes = {
    data: arrayOf(shape({})),
    header: string,
};

//  -- Default props ------------------
AdminAnnouncements.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    posts: state.posts,
});

export default connect(mapStateToProps, null)(AdminAnnouncements);
