//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { CirclePicker } from 'react-color';
import _ from "lodash"
//  -- Components ---------------------
import { Col, Row } from 'antd';
import { Button } from '@material-ui/core';
import TextEditor from 'components/TextEditor';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.form`
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Note form
//
//  -- Description
//  Create note with highlighting
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleColorChange {func} - invoked on color picker change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <NoteForm
//      handleSubmit={func}
//  />
//
const NoteForm = ({
    handleClose,
    handleSubmit,
}) => {
    const initialState = [{
        type: 'paragraph ',
        children: [
            {
                text: ''
            }
        ]
    }];
    const [noteData, setNoteData] = useState("");
    const [color, setColor] = useState(null)

    const handleColorChange = (color) => {
        setColor({
            color: color.hex
        });
    };

    const onClose = (e) => {
        e.preventDefault();
        handleClose();
        setNoteData("");
    };

    const handler=_.debounce(()=>{
        handleSubmit(noteData, color);
    },1000)
    const onSubmit =(e) => {
        e.preventDefault();
        handler()
    };

    return (
        <Wrapper onSubmit={onSubmit}>
            <Row gutter={[0, 32]}>
                <Col span={24} style={{ padding: '24px 4px 12px' }} className='circleWraper'>
                    <CirclePicker onChangeComplete={handleColorChange} />
                </Col>
            </Row>
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <TextEditor
                        handleChange={setNoteData}
                        value={noteData}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button
                        variant="contained"
                        color="primary"
                        type='submit'
                        style={{ width: '100%' }}
                    >
                        Create Note
                    </Button>
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type='submit'
                        style={{ marginTop: '8px', width: '100%' }}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Wrapper>
    );
};


//  -- Prop types ---------------------
NoteForm.propTypes = {
    handleSubmit: func.isRequired,
};

//  -- Default props ------------------
NoteForm.defaultProps = {};

export default NoteForm;