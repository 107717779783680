//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map } from 'lodash'

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const booksInitial = {};
const booksUnitInitial = [];



export const bookUnitList = (state = booksUnitInitial, action) => {
    switch (action.type) {
        case ActionTypes.bookUnits.listUnitsSuccess:
            return action.payload;
        case ActionTypes.bookUnits.listUnitsFail:
            return state;
        case ActionTypes.bookUnits.updateUnitsSuccess:
            return map(state,st=>{
                if(st.id==action.payload.id){
                    return ({...st,...action.payload})
                }
                return st;
            });
        default:
            return state;
    }
};
