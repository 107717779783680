//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//  -- Components ---------------------
import AccountForm from '../features/forms/AccountForm';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0px auto;
    max-width: 480px;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
const AccountContainer = ({ currentUser }) => {
    return (
        <Wrapper>
            <AccountForm />
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AccountContainer.propTypes = {};

//  -- Default props ------------------
AccountContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------

const mapStateToProps = state => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(AccountContainer);
