//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from "aws-amplify";

//  -- Actions ------------------------
import * as ActivityActions from "./actions";

import * as IntereactionActions from "../interactions/actions";
import * as LikesActions from "../likes/actions";
import * as TipsActions from "../tips/actions";
import * as Sentry from "@sentry/react";
import { has, filter, flatMap, map, get } from "lodash";
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------

//  -- List books ----------------
export const handleListAllBooks = () => async (dispatch) => {
  await dispatch(ActivityActions.listAllBooks());
  const BOOK_LENGTH = 20;
  // Array.apply(0, new Array(Math.floor(53/20)+1)).map((k,i) => i)
  try {
    const numberOfBook = await API.get("booksRDS", `/getBooksLength`);
    if (numberOfBook && numberOfBook.length) {
      const bookarr = Array.apply(
        0,
        new Array(Math.floor(numberOfBook.length / BOOK_LENGTH) + 1)
      ).map((k, i) => i);
      const allBooks = await Promise.allSettled(
        map(bookarr, async (k) => {
          return API.get("booksRDS", `/get/${k * BOOK_LENGTH}/${BOOK_LENGTH}`);
        })
      ).then((v) => filter(v, ["status", "fulfilled"]).map((k) => k.value));
      const booksRDS = flatMap(allBooks);
      await dispatch(ActivityActions.listAllBooksSuccess(booksRDS));
      return booksRDS;
    } else {
      let books = [];
      const booksRDS = await API.get("booksRDS", `/books`);
      if (Array.isArray(booksRDS) && booksRDS.length > 0) {
        books = booksRDS;
      }
      await dispatch(ActivityActions.listAllBooksSuccess(books));
      return books;
    }
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    await dispatch(ActivityActions.listAllBooksFail(error));
  }
};

//Bug Tips
export const handleListEbookData = (chapter) => async (dispatch) => {
  await dispatch(IntereactionActions.listChapterInteractions());
  await dispatch(LikesActions.listChapterLikes());
  await dispatch(TipsActions.listTipsBook());

  try {
    const booksData = await API.get("booksRDS", `/booksData/${chapter}`);
    if (typeof booksData == "object" && !booksData.error) {
      const tips = booksData.tips;
      const interactions = booksData.interactions;
      const likes = booksData.likes;
      await dispatch(
        IntereactionActions.listChapterInteractionsSuccess(interactions)
      );
      await dispatch(LikesActions.listChapterLikesSuccess(likes));
      await dispatch(TipsActions.listTipsBookSuccess(tips));
    }
    // await dispatch(ActivityActions.listAllBooksSuccess(books));
    return booksData;
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    await dispatch(ActivityActions.listAllBooksFail(error));
  }
};

export const handleCreateBook = (bookData) => async (dispatch) => {
  await dispatch(ActivityActions.createBooks());

  try {
    const booksRDS = await API.post("booksRDS", `/create`, {
      body: bookData,
    });

    if (has(booksRDS, "id")) {
      await dispatch(ActivityActions.createBooksSuccess(booksRDS));
      return booksRDS;
    } else {
      return { error: `unable to create` };
    }
  } catch (error) {
    console.log("create book error", error);
  }
};
export const handleUpdateBook = (id, bookData) => async (dispatch) => {
  await dispatch(ActivityActions.updateBooks());
  try {
    const booksRDS = await API.put("booksRDS", `/update/${id}`, {
      body: bookData,
    });

    if (has(booksRDS, "id")) {
      await dispatch(ActivityActions.updateBooksSuccess(booksRDS));
    }
    return booksRDS;
  } catch (error) {
    console.log("create book error", error);
  }
};
export const handleUpdateBookByPage = (chapter, pageId, fieldData, fieldname) => async (dispatch) => {
  try {
    const update_info = await API.put("booksRDS", `/updateByPage`, {
      body: {
        chapter, pageId , fieldData, fieldname
      },
    });

    if (update_info && update_info.success) {
      await dispatch(ActivityActions.updateBooksSuccess(update_info.data));
    }
    return update_info;
  } catch (error) {
    console.log("create book error", error);
  }
};

export const handleListBookOrder = () => async (dispatch) => {
  try {
    const bookOrder = await API.get("booksRDS", `/listBookOrder`);
    if (has(bookOrder, "id")) {
      await dispatch(
        ActivityActions.setBookOrderSuccess(get(bookOrder, "order", []))
      );
    }
    return get(bookOrder, "order", []);
  } catch (error) {
    console.log("create book error", error);
  }
};

export const handleUpdateListBookOrder = (order) => async (dispatch) => {
  // await dispatch(ActivityActions.updateBooks());
  try {
    const updateBookOrder = await API.put("booksRDS", `/updateBookOrders`, {
      body: { order },
    });

    if (has(updateBookOrder, "id")) {
      // await dispatch(ActivityActions.updateBooksSuccess(booksRDS));
    }
    return updateBookOrder?.order;
  } catch (error) {
    console.log("create book error", error);
  }
};

export const handleListActiveInActiveBooks = () => async (dispatch) => {
  await dispatch(ActivityActions.listAllBooks());
  const BOOK_LENGTH = 20;

  try {
    const numberOfBook = await API.get("booksRDS", `/allBooksLength`);
    if (numberOfBook && numberOfBook.length) {
      const bookarr = Array.apply(
        0,
        new Array(Math.floor(numberOfBook.length / BOOK_LENGTH) + 1)
      ).map((k, i) => i);
      const allBooks = await Promise.allSettled(
        map(bookarr, async (k) => {
          return API.get(
            "booksRDS",
            `/allBookget/${k * BOOK_LENGTH}/${BOOK_LENGTH}`
          );
        })
      ).then((v) => filter(v, ["status", "fulfilled"]).map((k) => k.value));
      const booksRDS = flatMap(allBooks);
      await dispatch(ActivityActions.listAllBooksSuccess(booksRDS));
      return booksRDS;
    } else {
      let books = [];
      const booksRDS = await API.get("booksRDS", `/allBookList`);
      if (Array.isArray(booksRDS) && booksRDS.length > 0) {
        books = booksRDS;
      }
      await dispatch(ActivityActions.listAllActiveInActiveBooksSuccess(books));
      return books;
    }
  } catch (error) {
    if (error && error.message != "Network Error") {
      Sentry.captureException(error);
    }
    await dispatch(ActivityActions.listAllBooksFail(error));
  }
};

export const handleActiveInActiveBook = (id, values) => async (dispatch) => {
  const booksRDS = await API.put("booksRDS", "/stateChange", {
    body: {
      id: id,
      active: values,
    },
  });

  if (has(booksRDS, "id")) {
    // await dispatch(ActivityActions.updateActiveSuccess(booksRDS));
      // await dispatch(ActivityActions.addEditBooks(booksRDS));
      dispatch(handleListActiveInActiveBooks());
      return booksRDS
  }
};