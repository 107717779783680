//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import _, {
  filter,
  groupBy,
  map
} from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { Col, Row, Select, Spin } from "antd";
import SentryWrap from "components/Sentry";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------

//  -- Thunks -------------------------
import { DownloadOutlined } from '@ant-design/icons';
import Loading from "components/Loading";
import moment from "moment-timezone";
import HighchartsReact from 'react-highcharts';
import { useReactToPrint } from 'react-to-print';
import { handleListApExamDataForGraphByConfig } from "store/apexams/thunks";
import { useAllBook } from "store/books/selectors";
import { handleGetOrgClasses } from "store/classes/thunks";
import { handleProcessClassTenant } from "store/classlink/thunks";
import { handleGetCoursesForClever } from "store/clever/thunks";
import { useExams } from "store/exams/selectors";
import { handleListExamDataforGraph } from "store/exams/thunks";
import { useBookUsesGraphData, useContentExamGraphData, useDistrictDashboardInfo, useExamGraphData } from 'store/organizations/selectors';
import { handleContentExamGraphData, handleListDashboardInfo, handleListbookUsed } from "store/organizations/thunks";
import { handleListUserBooks } from "store/userBooks/thunks";
import { DistrictBg } from "utils/assets/images";
const { Option } = Select;
const MENU = [
  {
    label: "See schools",
    value: "see_schools",
  }
];
const years = new Date().getFullYear();
const upcomming_year = `${years}-${years + 1}`;
const downcomming_year = `${years - 1}-${years}`;

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

export const Wrapper = styled.div`
  height: 100%;
  width: calc(100% + 50px);
  margin-left: -25px;
  background-image: url(${DistrictBg});
  background-position: 100% 100%;
  background-size: cover;
`;
//-----------new component --------------//


const StatsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  gap: 50px;
  flex-wrap: wrap;
`;


const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bgColor || '#f5f5f5'};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 240px; // Adjust width as needed
  height: 120px; // Adjust height as needed
`;

const Title = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
`;

const Number = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #333;
`;

const ChartContainer = styled.div`
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  position: relative;
`;
const ChartContainerLoader = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 9999;
`;
const ChartContainerPi = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  position: relative;

`;
const ChartContainerCe = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  position: relative;

`;

const GraphRow = styled(Row)`
  display: flex;
  flex-direction: row;

  @media print {
    flex-direction: column;
  }
`;
const GraphCol = styled(Col)`
  display: flex;
  justify-content: flex-end;

  @media print {
    justify-content:flex-start;
  }
`;
const DownButton = styled.button`
    border: none;
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;
    margin-right: 16px;
    height: 40px;
    background: ${COLOR.green200};
    color: #fff;
    margin-top: 20px;
    padding-left: 12px;
    padding-right: 12px;
    &:hover {
      background: ${COLOR.green1000};
    }
`;
const chartOptions = {
  chart: {
    type: 'line',
    height: 250
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: ['Nov Low', 'Nov Mid', 'Nov High', 'Inter Low', 'Inter Mid', 'Inter High', 'Adv Low', 'Adv Mid', 'Adv High']
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  series: [{
    type: 'scatter',
    data: []
  }],
  plotOptions: {
    scatter: {
      marker: {
        radius: 5,
        symbol: 'circle'
      }
    }
  },
  tooltip: {
    formatter: function () {
      return '<b>' + this.x + '</b><br>Value: <b>' + this.y + '</b>';
    }
  }
};
const ORDERED_DATA = ["NOVICE LOW", "NOVICE MID", "NOVICE HIGH", "INTER LOW", "INTER MID", "INTER HIGH", "ADV LOW", "ADV MID", "ADV HIGH"]
const initialgraphData = {
  "ADV HIGH": 0,
  "ADV MID": 0,
  "ADV LOW": 0,
  "INTER HIGH": 0,
  "INTER MID": 3,
  "INTER LOW": 0,
  "NOVICE HIGH": 0,
  "NOVICE MID": 0,
  "NOVICE LOW": 0
}

const OverallProficiencyChart = ({ currentOrg }) => {
  const [selectedExam, setSelectedExam] = useState('1');
  const [selectedSchool, setSelectedSchool] = useState('high');
  const [activeSession, setActiveSession] = useState(moment().get("month") < 7 ? downcomming_year : upcomming_year)
  const dispatch = useDispatch()
  const [graphData, setGraphData] = useState(initialgraphData)
  const [loading, setLoading] = useState(false)
  const examGraphData = useExamGraphData()
  async function hasData() {
    return examGraphData && Object.keys(examGraphData.config).length > 0
  }
  useEffect(() => {
    if (hasData()) {
      const { config, data } = examGraphData;
      setGraphData(data)
    }
  }, [examGraphData])
  const chatConfig = useMemo(() => {
    const g_data = _.cloneDeep(chartOptions)
    const keys = _.orderBy(Object.keys(graphData), [element => ORDERED_DATA.indexOf(element)]);
    g_data.xAxis.categories = keys.map(x => _.capitalize(x))
    g_data.series[0].data = keys.map(v => graphData[v])
    return g_data;
  }, [graphData])
  async function fetchData() {
    setLoading(true)
    try {

      const data = await dispatch(handleListExamDataforGraph({
        "session": activeSession,
        "exam_type": selectedExam,
        "school_level": selectedSchool,
        "id": currentOrg.id
      }))
      if (typeof data === "object") {
        setGraphData(data)
      } else {
        setGraphData(initialgraphData)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [dispatch, selectedExam, selectedSchool, activeSession, currentOrg])

  const chart = useMemo(() => <HighchartsReact
    config={chatConfig}
  />, [chatConfig])

  const exams = useExams()
  return (
    <ChartContainer >
      {loading && <ChartContainerLoader><Spin size="large" /></ChartContainerLoader>}
      <GraphRow>
        <Col span={12}>
          <Title>Overall Proficiency</Title>
        </Col>
        <GraphCol  span={12}>
          <Select defaultValue={selectedExam} style={{ width: 120, marginBottom: '20px' }} onChange={v => {
            setSelectedExam(v)
          }}>
            <Option value="1">Exam1</Option>
            <Option value="2">Exam2</Option>
            <Option value="3">Exam3</Option>
            <Option value="combine">Combine</Option>
          </Select>
          <Select defaultValue={selectedSchool} style={{ width: 120, marginBottom: '20px' }} onChange={v => setSelectedSchool(v)}>
            <Option value="high">High school</Option>
            <Option value="middle">Middle school</Option>
          </Select>
          <Select defaultValue={activeSession} style={{ width: 120, marginBottom: '20px' }} onChange={v => setActiveSession(v)}>
            {map(Object.keys(groupBy(exams, "session")), x => <Option value={x} key={x}>{x}</Option>)}
          </Select>
        </GraphCol>
      </GraphRow>
      {chart}
    </ChartContainer>
  );
};
const initialAPgraphData = [
  {
      "label": "Score 1",
      "value": 0
  },
  {
      "label": "Score 2",
      "value": 0
  },
  {
      "label": "Score 3",
      "value": 0
  },
  {
      "label": "Score 4",
      "value": 0
  },
  {
      "label": "Score 5",
      "value": 0
  }
]
const ApchartOptions = {
  chart: {
    type: 'line',
    height: 250
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: ['Score 1', 'Score 2', 'Score 3', 'Score 4', 'Score 5']
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  series: [{
    type: 'scatter',
    data: []
  }],
  plotOptions: {
    scatter: {
      marker: {
        radius: 5,
        symbol: 'circle'
      }
    }
  },
  tooltip: {
    formatter: function () {
      return '<b>' + this.x + '</b><br>Value: <b>' + this.y + '</b>';
    }
  }
};
const OverallApChart = ({ currentOrg }) => {
  const [selectedExam, setSelectedExam] = useState('1');
  const [activeSession, setActiveSession] = useState(moment().get("month") < 7 ? downcomming_year : upcomming_year)
  const dispatch = useDispatch()
  const [graphData, setGraphData] = useState(initialAPgraphData)
  const [loading, setLoading] = useState(false)

  const chatConfig = useMemo(() => {
    const g_data = _.cloneDeep(ApchartOptions)
    g_data.xAxis.categories = graphData.map(k=>k.label)
    g_data.series[0].data = graphData.map(k=>k.value)
    return g_data;
  }, [graphData])
  async function fetchData() {
    setLoading(true)
    try {

      const data = await dispatch(handleListApExamDataForGraphByConfig({
        "session": activeSession,
        "exam_type": selectedExam,
        "id": currentOrg.id
      }))
      if (Array.isArray(data)) {
        setGraphData(data)
      } else {
        setGraphData(initialAPgraphData)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [dispatch, selectedExam, activeSession, currentOrg])

  const chart = useMemo(() => <HighchartsReact
    config={chatConfig}
  />, [chatConfig])

  const exams = useExams()
  return (
    <ChartContainer >
      {loading && <ChartContainerLoader><Spin size="large" /></ChartContainerLoader>}
      <GraphRow>
        <Col span={8}>
          <Title>Overall AP® Spanish</Title>
        </Col>
        <GraphCol span={16}>
          <Select defaultValue={selectedExam} style={{ width: 120, marginBottom: '20px' }} onChange={v => {
            setSelectedExam(v)
          }}>
            <Option value="1">Exam1</Option>
            <Option value="2">Exam2</Option>
            <Option value="3">Exam3</Option>
            <Option value="combine">Combine</Option>
          </Select>
          <Select defaultValue={activeSession} style={{ width: 120, marginBottom: '20px' }} onChange={v => setActiveSession(v)}>
            {map(Object.keys(groupBy(exams, "session")), x => <Option value={x} key={x}>{x}</Option>)}
          </Select>
        </GraphCol>
      </GraphRow>
      {chart}
    </ChartContainer>
  );
};

const chartOptionsPi = {
  chart: {
    type: 'pie',
    height: '75%' // Adjust height as needed
  },
  title: {
    text: 'Overall Program Usage'
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },
  plotOptions: {
    pie: {
      innerSize: '50%',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}'
      },
      showInLegend: true
    }
  },
  series: [{
    name: 'Usage',
    colorByPoint: true,
    data: [
      { name: 'Anécdotas 1', y: 344, color: '#FF6384' }, // Use your own colors
      { name: 'Anécdotas Inicial 1', y: 245, color: '#36A2EB' },
      { name: 'Anécdotas 2', y: 452, color: '#FFCE56' },
      { name: 'America Ed. 4', y: 512, color: '#4BC0C0' }
    ]
  }]
};
const RANDOM_COLOR = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  COLOR.OrangeExam,
  COLOR.PinkExam,
  COLOR.blue
]
const initialbookData = {
  "Anécdotas Inicial 1": 0,
  "America Ed. 4 - AP Span Lang": 0,
  "Anécdotas Inicial 2 ": 0,
  "Anécdotas 2": 0,
  "Anécdotas 1": 0,
  "Antorcha ": 0,
  "Anécdotas Inicial 3": 0
}
const OverallProgramUsageChart = ({ currentOrg }) => {
  const dispatch = useDispatch()
  const [graphData, setGraphData] = useState(initialbookData)
  const [loading, setLoading] = useState(false)
  const [activeSession, setActiveSession] = useState(moment().get("month") < 7 ? downcomming_year : upcomming_year)
  const bookUsesData = useBookUsesGraphData()
  async function hasData() {
    return bookUsesData && Object.keys(bookUsesData.config).length > 0
  }
  useEffect(() => {
    if (hasData()) {
      const { config, data } = bookUsesData;
      setGraphData(data)
    }
  }, [bookUsesData])
  const chatConfig = useMemo(() => {
    const g_data = _.cloneDeep(chartOptionsPi)
    g_data.series[0].data = Object.keys(graphData).map((v, i) => {
      return {
        name: v,
        y: graphData[v],
        color: RANDOM_COLOR[i]
      }
    })
    return g_data;
  }, [graphData])
  async function fetchDistBookUsed() {
    setLoading(true)
    try {
      const data = await dispatch(handleListbookUsed(currentOrg.id, {
        "session": activeSession,
        "id": currentOrg.id
      }))
      if (typeof data === "object") {
        setGraphData(data)
      } else {
        setGraphData(initialbookData)
      }
    } catch (e) {

    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchDistBookUsed()
  }, [currentOrg, activeSession]);
  const chart = useMemo(() => <HighchartsReact
    config={chatConfig}
  />, [chatConfig])
  const exams = useExams()
  return (
    <ChartContainerPi>
      {loading && <ChartContainerLoader><Spin size="large" /></ChartContainerLoader>}
      <GraphRow>
        <Col span={12}>
          <Title>Overall Program Usage</Title>
        </Col>
        <GraphCol span={12}>
          <Select defaultValue={activeSession} style={{ width: 120, marginBottom: '20px' }} onChange={v => setActiveSession(v)}>
            {map(Object.keys(groupBy(exams, "session")), x => <Option value={x} key={x}>{x}</Option>)}
          </Select>
        </GraphCol>
      </GraphRow>

      {chart}
    </ChartContainerPi>
  );
};

const CeConfig = {
  chart: {
    type: 'column',
    height: 250
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 4', 'Unit 5', 'Unit 6'],
    crosshair: true
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Percentage (%)'
    }
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y}%'
      }
    }
  },
  series: [{
    name: 'Passing Rate',
    data: [0, 0, 0, 0, 0, 0]
  }]
};

const initdata = {
  by_book: {
    13: {
      unit_by: [],
      book_name: 'Assessment 1'
    },
    14: {
      unit_by: [],
      book_name: 'Assessment 1'
    }
  },
  passing_by_units: {
    "Unidad 1": 0,
    "Unidad 2": 0,
    "Unidad 3": 0,
    "Unidad 4": 0,
    "Unidad 5": 0,
    "Unidad 6": 0
  }
}
const order_d = ['Unidad 1', 'Unidad 2', 'Unidad 3', 'Unidad 4','Unidad 5','Unidad 6']
const CEChart = ({ currentOrg }) => {
  const [activeSession, setActiveSession] = useState(moment().get("month") < 7 ? downcomming_year : upcomming_year)
  const dispatch = useDispatch()
  const [graphData, setGraphData] = useState(initdata)
  const [loading, setCeLoading] = useState(false)
  const examGraphData = useContentExamGraphData()
  const [books, setBooks] = useState([{
    title: 'all',
    'value': 'all'
  }])
  const [selectedbooks, setSelectedBooks] = useState('Books')
  const allbooks = useAllBook()
  const exams = useExams()
  async function hasData() {
    return examGraphData && Object.keys(examGraphData.config).length > 0
  }
  useEffect(() => {
    if (books.length == 1) {
      const assessmentbook = filter(allbooks, k => k.is_assessment_book).map(k => ({ title: k.description, value: k.id }))
      setBooks([...books, ...assessmentbook])
    }
  }, [allbooks])
  useEffect(() => {
    if (hasData()) {
      const { config, data } = examGraphData;
      setGraphData(data)
    }
  }, [examGraphData])
  const chatConfig = useMemo(() => {
    const g_data = _.cloneDeep(CeConfig)
    if (selectedbooks === 'all' || selectedbooks === "Books") {
      let gph_data = graphData.passing_by_units
      const keys = _.orderBy(Object.keys(gph_data), [element => order_d.indexOf(element)]);
      g_data.xAxis.categories = keys;
      g_data.series[0].data = keys.map(v => gph_data[v])
    } else {
      let gph_data = graphData.by_book[selectedbooks] ? graphData.by_book[selectedbooks].unit_by : [];
      g_data.xAxis.categories = gph_data.map(k => k.title);
      g_data.series[0].data = gph_data.map(k => k.percent);
    }
    return g_data;
  }, [graphData, selectedbooks])

  async function fetchData() {
    setCeLoading(true)
    try {

      const data = await dispatch(handleContentExamGraphData({
        "session": activeSession,
        "id": currentOrg.id
      }))
      if (typeof data === "object") {
        setGraphData(data)
      } else {
        setGraphData(initdata)
      }
    } catch (error) {
      console.log(error, "error in fetching content")
      setGraphData(initdata)
    } finally {
      setCeLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [dispatch, activeSession, currentOrg])



  const chart = useMemo(() => <HighchartsReact
    config={chatConfig}
  />, [chatConfig])


  return (
    <ChartContainerCe>
      {loading && <ChartContainerLoader><Spin size="large" /></ChartContainerLoader>}
      <GraphRow>
        <Col span={16}>
          <Title>Content Exams (Passing Rates)</Title>
        </Col>
        <GraphCol span={8}>
          <Select defaultValue="books" style={{ width: 120, marginBottom: '20px' }} onChange={v => setSelectedBooks(v)}>
            {books.map(x => <Option value={x.value} key={x.value}>{x.title}</Option>)}
          </Select>
          <Select defaultValue={activeSession} style={{ width: 120, marginBottom: '20px' }} onChange={v => setActiveSession(v)}>
            {map(Object.keys(groupBy(exams, "session")), x => <Option value={x} key={x}>{x}</Option>)}
          </Select>
        </GraphCol>
      </GraphRow>
      {chart}
    </ChartContainerCe>
  );
};
  
const DashboardStats = ({isLoading , school , teacher , student , assignment }) => {
    return (
      <StatsContainer>
        <StatCard bgColor="rgb(231,254,238)">
          <Title>Schools</Title>
          <Number>{school }</Number>
        </StatCard>
        <StatCard bgColor="rgb(252,255,227)">
          <Title>Teachers</Title>
          <Number>{teacher}</Number>
        </StatCard>
        <StatCard bgColor="rgb(239,246,254)">
          <Title>Students</Title>
          <Number>{student}</Number>
        </StatCard>
        <StatCard bgColor="rgb(252,239,239)">
          <Title>Assignments Submitted</Title>
          <Number>{assignment}</Number>
        </StatCard>
      </StatsContainer>
    );
  };
const DistrictContainer = ({
  currentUser,
  users,
  schools,
  currentOrg
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [teacherInfoLoading, setTeacherInfoLoading] = useState(true);
  const distDashInfo = useDistrictDashboardInfo()
  const [printloader, setPrintloader] = useState(false)
  const containerRef = useRef();
  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        await Auth.currentAuthenticatedUser();
        // await dispatch(handleListAllBooks());
        dispatch(handleGetOrgClasses([currentOrg, ...schools]));
        await dispatch(handleListUserBooks(currentUser?.id));
        await dispatch(handleGetCoursesForClever('1'))
      } catch (error) {

      }
      finally {
        setLoading(false)
      }
    }
    fetchData();
  }, []);


  useEffect(() => {
    async function fetchData() {
      try {
        setTeacherInfoLoading(true);
        await Promise.all([
          dispatch(handleListDashboardInfo(currentOrg.id))
        ])
      } catch (error) {
        console.log(error, "errror");
      } finally {
        setTeacherInfoLoading(false);
      }
    }
    fetchData();
  }, [currentOrg]);


  useEffect(() => {
    async function fetchData() {
      dispatch(handleProcessClassTenant())
      dispatch(handleGetCoursesForClever('1'))
    }
    fetchData();
  }, []);
  const validationPrint = useReactToPrint({
    content: () => containerRef.current,
    onBeforeGetContent: () => { setPrintloader(true) },
    onBeforePrint: () => {
      setPrintloader(false);
    }
  });
  const chart1 = useMemo(()=><OverallProficiencyChart  currentOrg={currentOrg} />,[currentOrg])
  const chart2 = useMemo(()=><OverallProgramUsageChart currentOrg={currentOrg} />,[currentOrg])
  const CEChartItem = useMemo(()=><CEChart currentOrg={currentOrg} />,[currentOrg])
  const ApChartItem = useMemo(()=><OverallApChart currentOrg={currentOrg} />,[currentOrg])
  return (
    <SentryWrap>
      <DownButton onClick={validationPrint} style={{ padding: 0, position: "absolute", right: 50 , paddingLeft:12 , paddingRight:12 }}>
        {printloader ? <Loading size={12} white /> : <DownloadOutlined style={{ marginRight: '4px' }} />}
        Download
      </DownButton>
      {/* <IconButton onClick={validationPrint} style={{ padding: 0, position: "absolute", right: 50 }} aria-label="download pdf" disabled={printloader} className="no-print">
        {printloader ? <Loading size={20} /> : <CloudDownloadIcon />}
      </IconButton> */}
      <div ref={containerRef}>
          <DashboardStats
              isLoading
              school={distDashInfo.no_of_schools}
              teacher={distDashInfo.no_of_teachers}
              student={distDashInfo.no_of_students}
              assignment={distDashInfo.no_of_complete_submission} />
          <GraphRow>
              <Col xs={24} sm={12}>
                 {chart1} 
                 {CEChartItem}
                 {ApChartItem}
              </Col>
              <Col xs={24} sm={12}>
                {chart2}
              </Col>
          </GraphRow>
      </div>
    </SentryWrap>
  );
};

//  -- Prop types ---------------------
DistrictContainer.propTypes = {};

//  -- Default props ------------------
DistrictContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  currentOrg: state.currentOrg,
  users: state.users,
  booksAllList: state.booksAllList,
  schools: state.schools,
  userBooksList: state.userBooksList,
});

export default connect(mapStateToProps, null)(DistrictContainer);
