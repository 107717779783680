//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Chapters -----------------------
import _, { filter, find, flatMap, get, map , findIndex } from "lodash";
import * as NEWSLETTERS from 'utils/config/reference';
import { isBeforTime } from "utils/methods/math"
import { BookSynoname } from 'utils/config/reference';
import moment from 'moment'
const BOOKORDER = [
    {title:"Anécdotas Inicial 1",order:["32", "37", "55"] , color:"rgb(27,45,79)"},
    {title:"Anécdotas Inicial 2 ",order:["46", "59"],color:"rgb(53,90,92)"},
    {title:"Anécdotas Inicial 3",order:["57", "60"],color:"rgb(62,41,119)"},
    {title:"Anécdotas 1",order:["7", "11", "53", "13", "9"],color:"rgb(85,151,156)"},
    {title:"Anécdotas 2",order:["8", "12", "54", "14", "10"],color:"rgb(62,41,119)"},
    {title:"América Ed. 3",order:["1"],color:"rgb(237,187,80)"}
 ]

export const ebookConfigDinamic = (id,allBooks) => {
    try {
        let dataBook = {}
        const dbook = find(allBooks, x => x.id == id);
            if (dbook) {
                dataBook = {
                    bookId: dbook.id,
                    count: false,
                    dataBook: dbook,
                    description: dbook.description
                }
            }else{
                return ({
                    content: [],
                    structures: [],
                });
            }
        const bookSubTopics = map(dataBook.dataBook.subTopics, sb=>({
            id: sb.subTopicId.toString(),
            finish: sb.finishPage.toString(),
            start: sb.startPage.toString(),
            shortName: sb.shortName.toString(),
            subUnits: sb.subUnits
        }))
        return {
            content: getBookContent(id,allBooks,null,null),
            structures: bookSubTopics,
        };
    } catch (error) {
        console.log(error, "error in databook")
        return {
            content: [],
            structures: [],
        };
    }
};

export const getBookContent = ( id,allBooks , start=null , end=null)=> {
    try {
      if (!id) return [];
      const book = find(allBooks, k => k.id == id);
      if (book) {
        const content = map(book.pages, (p, i) => {
          return {
            id: i,
            pageId: p.pageId,
            src: p.resource,
            tip: p.tip,
            video: p.video,
            description: p.description,
            numberMasked: p.NumberMasked || p.pageId,
            answerPageSrc: find(
              book.pagesTeacher,(k) => k.pageId == p.pageId,
            )?.resource,
            teacherEditionPageSrc: find(book.teacherEdition,(k) => k.pageId == p.pageId)?.resource,
            thumbnail:p.thumbnail ||  p.resource
          };
        })
        if ((typeof start === 'string' || typeof start === 'number') && (typeof end === 'number' || typeof end === 'string')) {
          return filter(content, (ct) => (parseInt(`${ct.pageId}`) >= parseInt(`${start}`) && parseInt(`${ct.pageId}`) <=  parseInt(`${end}`)))
        } else {
          return content;
        }
      }
      return [];
    } catch (error) {
      return [];
    }
  };
//  -- Image fetch --------------------
export const imageFetch = (booksAllList, chapter, page) => {
    let url = ""
    if (Array.isArray(booksAllList)) {
        let dataBook = filter(booksAllList, x => x.id == chapter);
        if (Array.isArray(dataBook) && dataBook[0] && dataBook[0].pages != undefined) {
            let dataPage = filter(dataBook[0].pages, x => x.pageId == page);
            url = dataPage[0]?.resource;
        }
    }
    return url;
};

export const imageFetchResource = (booksAllList, chapter, page) => {
    let url = ""
    if (Array.isArray(booksAllList)) {
        let dataBook = filter(booksAllList, x => x.id == chapter);
        if (Array.isArray(dataBook) && dataBook != undefined) {
            let dataPage = filter(dataBook, x => x.pageId == page);
            url = dataPage.length > 0 ? dataPage[0].resource : '';
        }
    }
    return url;
};

export const imageFetchDinamic = (chapter, page, listAllBooks) => {
    try {
        const dataBook = filter(listAllBooks, x => x.id == chapter);
        if (dataBook != null || dataBook != undefined && Array.isArray(dataBook) && dataBook.length > 0) {
            const dataPage = filter(dataBook[0].pages, x => x.pageId == page);
            return dataPage[0];
        }
    } catch (error) {
        return ""
    }
}
export const imageFetchFromPodcast = (id, resources) => {
    const allresourcesVideo = flatMap(map(resources, k => k.videos))
    return get(find(allresourcesVideo, ['id', id]), 'thumbnail', '')
}
export const getPodcastBookName = (id, resources) => {
    const allresourcesVideo = flatMap(map(resources, k => k.videos))
    return get(find(allresourcesVideo, ['id', id]), 'displayName', '')
}
export const getBookDisplayName = (AllBook, chapter, hasName , videos, resources) => {
    let chapterName = "";
    if (isEbook(chapter,AllBook)) {
        chapterName = get(find(AllBook, ["id", chapter]), "description", "");
    } else {
        if (NEWSLETTERS.NEWSLETTERS[chapter]) {
            chapterName = "Newsletter";
        } else if (isVideo(chapter,resources)) {
            const videoName = get(find(videos, ['id', chapter]), 'displayName', '');
            chapterName = hasName ? `Video ${videoName||''}` : "Video";
        }
        else {
            const postCastName =  getPodcastBookName(chapter, resources);
            if(postCastName){
                chapterName = postCastName
            }else{
                chapterName =``
            }
           
        }
    }
    return chapterName;
  };
  


//  -- Structure fetch ----------------
export const structureFetch = (booksAllList, chapter, page) => {
    let newSubtopics = [];
    let bookSubTopics
    if (Array.isArray(booksAllList)) {
        let dataBook = filter(booksAllList, x => x.id == chapter);
        bookSubTopics = dataBook[0]?.subTopics;
    }
    for (var sub in bookSubTopics) {
        newSubtopics.push({
            id: bookSubTopics[sub].subTopicId.toString(),
            finish: bookSubTopics[sub].finishPage.toString(),
            start: bookSubTopics[sub].startPage.toString(),
            shortName: bookSubTopics[sub].shortName.toString(),
            subUnits: bookSubTopics[sub].subUnits
        })
    }
    const structures = newSubtopics;

    for (let i = 0; i < structures.length; i++) {
        const structStart = structures[i].start;
        const structEnd = structures[i].finish;

        if (parseInt(page, 10) <= parseInt(structEnd, 10) && parseInt(page, 10) >= parseInt(structStart, 10)) {
            return `Subtema ${i + 1}`;
        }
    }
};

//  -- Newsletter Image fetch ---------
export const newsImageFetch = (id, page) => {
    return NEWSLETTERS[id][page - 1];
};

export const getBookData = (activities, quizzes, questions, notes, likes, interactions, classActivities, classQuizzes, chapter, classId, isAssessmentBook) => {
    const clonedActivities = filter(activities, k => k.cloneId).map(k => k.cloneId)
    const _activities = filter(activities, k => !clonedActivities.includes(k.id))
    const _quizzes = filter(quizzes, k => !clonedActivities.includes(k.id))
    const filterByBookId = x =>isAssessmentBook&&x.bookId?x.bookId === chapter : x.chapter === chapter;
    const filteDatarByBookId = x =>isAssessmentBook && get(x,"data.bookId") ?get(x,"data.bookId") === chapter : get(x,"data.chapter") === chapter;
    return {
        "activities": filter(_activities, (activity) => activity.chapter == chapter),
        "quizzes": filter(_quizzes, filterByBookId),
        "questions": filter(questions, (question) => classId ? question.chapter == chapter && question.classId == classId : question.chapter == chapter),
        "notes": filter(notes, (note) => classId ? (note.chapter == chapter && note.classId == classId) : (note.chapter == chapter)),
        "likes": filter(likes, (like) => classId ? like.chapter == chapter && like.classId == classId : like.chapter == chapter),
        "interactions": filter(interactions, (interaction) => interaction.chapter == chapter),
        "classActivities": filter(classActivities, (classActivity) => classActivity.data?.chapter == chapter),
        "classQuizzes": filter(classQuizzes, filteDatarByBookId),
    }
}
function filterPageAssignment(activities, classActivities, page, chapter) {
    return filter(activities, x => (!find(classActivities, ['data.id', x.id]) && (x.page === page || x.assessment_page_id == page || x.linked_to?.find(k => k.chapter == chapter && k.pageId == page))))
}
function filertPageClassAssignment(activities, page, chapter) {
    return filter(activities, x => (x.data?.page === page || x.data?.linked_to?.find(k => k.chapter == chapter && k.pageId == page) || get(x, "data.assessment_page_id") == page))
}
export const getPageData = (bookData, page, quiz, activity, isAssessmentBook, chapter) => {
    return ({
        pageInteractions: filter(bookData.interactions, x => (x.page === page && x.typeId != 'videoTeacher')),
        videoSupport: filter(bookData.interactions, x => (x.page === page && x.typeId === 'videoTeacher')),
        pageLike: filter(bookData.likes, x => (x.page == page)),
        pageNotes: filter(bookData.notes, x => (x.page == page)),
        pageQuestions: filter(bookData.questions, x => (x.page == page)),
        availableActivity: filertPageClassAssignment(bookData.classActivities, page, chapter),
        availableQuiz: filertPageClassAssignment(bookData.classQuizzes, page, chapter),
        assignableActivity: filterPageAssignment(bookData.activities, bookData.classActivities, page, chapter).filter(k=>k.activity_type != 'graphic_organizer'),
        assignableQuiz: filterPageAssignment(bookData.quizzes, bookData.classQuizzes, page, chapter),
        activeQuiz: find(bookData.classQuizzes, x => (quiz && quiz != "null" && get(x, "data.id", "").includes(quiz)) || (activity && activity != "null" && get(x, "data.id", "").includes(activity))),
        activeActivity: find(bookData.classActivities, x => (quiz && quiz != "null" && get(x, "data.id", "").includes(quiz)) || (activity && activity != "null" && get(x, "data.id", "").includes(activity))),
    })
}
export const getStatusByCode = (status,currentUser) => {
    if (status == 0) {
        return ''
    } else if (status == 1) {
        return currentUser && currentUser.schoologyid ? "Exempt" : "Excused";
    } else if (status == 2) {
        return 'Incomplete'
    } else if (status == 3) {
        return 'Missing'
    } else if (status == 5) {
        return 'Absent'
    }
    else if (status == 6) {
        return 'Late'
    }
    else {
        return ""
    }
}
export const getExceptionStatus = (submission,currentUser) => {
    const exceptionStatus = submission?.exceptionStatus || 0;
    const isException = exceptionStatus > 0;
    const exceptionStatusText = getStatusByCode(exceptionStatus , currentUser )
    return { isException, exceptionStatusText, exceptionStatus }
}
export const getQuestionForDiff = (nivel, data) => {
    if (nivel == 'easy') {
        return map(data, qus => {
            const ans = qus.answer
            let optAdded = 0;
            let correctAdded = false
            if (qus.options) {
                qus.options = map(qus.options, (opt, i) => {
                    if (ans == i) {
                        ++optAdded;
                        correctAdded = true;
                        return opt;
                    }
                    else if ((correctAdded && optAdded == 1) || (!correctAdded && optAdded == 0)) {
                        ++optAdded;
                        return opt;
                    }
                    else {
                        return null;
                    }
                })
            }
            return qus;
        })
    } else if (nivel == 'medium') {
        return map(data, qus => {
            const ans = qus.answer
            let optAdded = 0;
            let correctAdded = false
            if (qus.options) {
                qus.options = map(qus.options, (opt, i) => {
                    if (ans == i) {
                        ++optAdded;
                        correctAdded = true;
                        return opt;
                    }
                    else if ((correctAdded && optAdded <= 2) || (!correctAdded && optAdded < 2)) {
                        ++optAdded;
                        return opt;
                    }
                    else {
                        return null;
                    }
                })
            }
            return qus;
        })

    } else if (nivel == 'hard') {
        return data;
    } else {
        return data;
    }

}
export const modifyAssignmentForStudent = (assignment, isStudent, isQuiz, currentUser, servertime, timezone , currentClass,hiddenBooks=[] ) => {
    if (!isStudent) return assignment;
    if (!assignment) return null;
    assignment = !_.isArray(assignment) ? [assignment] : assignment;
    if (_.isArray(assignment)) {
        return map(assignment, assign => {
            if (get(assign, "diff.length",0) > 0) {
                let foundDiff = filter(assign.diff, x => x.userId == currentUser.id);
                if (foundDiff.length > 0 && foundDiff[0].dueDate && foundDiff[0].dueDate.length > 0) {
                    assign.dueDate = foundDiff[0].dueDate;
                    assign.retakes = foundDiff[0].retakes;
                    assign.scheduleAt = foundDiff[0].scheduleAt;
                    assign.lateDate = get(foundDiff[0], "lateDate") ? get(foundDiff[0], "lateDate") : assign.lateDate
                    assign.timeDuration = get(foundDiff[0], "timeDuration") > 0 ? get(foundDiff[0], "timeDuration") : assign.timeDuration
                    assign.data.questions = getQuestionForDiff(foundDiff[0].levelExercise, assign.data.questions)
                }
            }
            const classConfig = find(assign.classes,['id',get(currentClass,"id")])
            if(classConfig&&classConfig.diff){
                const hasDiff = find(classConfig.diff.student,k=>k==currentUser.id)
                if(hasDiff){
                    assign.dueDate = classConfig.diff.dueDateDif;
                    assign.retakes = classConfig.diff.retakesDif;
                    assign.scheduleAt = classConfig.diff.scheduleAtDiff;
                    assign.data.questions = getQuestionForDiff(classConfig.diff.levelExercise, assign.data.questions)
                }
            }
            const isAfterSchedule = assign.scheduleAt ? isBeforTime(assign.scheduleAt, servertime, timezone) : true;
            // check disable student userId exist then return null else return assign
            const deactivatedStudentData = assign?.deactivated_student?.includes(currentUser?.id);
            
            // assign?.data?.chapter exist on hidden array simple return null
            const  checkBookIsHiddenOrNot = hiddenBooks?.includes(assign?.data?.chapter)

            return !isAfterSchedule || deactivatedStudentData ||checkBookIsHiddenOrNot  ? null : assign;
        }).filter(k => k)
    }
    return []
}
export const isEbook = (chapter, allBooks) => {
    return Boolean(_.find(allBooks, x => x.id == chapter))
}
export const isVideo = (chapter, resources) => {
    const allresourcesVideo = flatMap(map(resources, k => k.videos))
    return Boolean(_.find(allresourcesVideo, x => x.id == chapter))
}

export const getNumberMask = (chapter, _page , booksAllList ) => {
    let book = find(booksAllList, { id: chapter })
    if (book) {
        let numberMask = find(book.pages, k=>k.pageId == _page)
        if (numberMask) {
            return numberMask.NumberMasked
        } else {
            return _page;
        }
    } else {
        return _page;
    }
}
export const getAssignmentLable = (assignment, booksAllList, videos, resources) => {
    const pageId = get(assignment, "data.page")
    const chapter = get(assignment, "data.chapter")
    if (!assignment) return ""
    const assignmentType = assignment.activityId ? 'A' : 'Q'
    if (isVideo(pageId, resources)) {
        return get(assignment, "data.displayName", "resource")
    }
    else if (isEbook(chapter, booksAllList)) {
        const numberMasked = getNumberMask(chapter, pageId, booksAllList)
        const shortName = BookSynoname[chapter]
        if (shortName && numberMasked) {
            return ` ${shortName} | Pg.${numberMasked}`
        } else if (numberMasked) {
            return ` ${chapter} | Pg.${numberMasked}`
        } else if (shortName) {
            return ` ${shortName} | Pg.${pageId}`;
        } else {
            return ` ${chapter} | Pg.${pageId}`;
        }
    } else {
        return getBookDisplayName(booksAllList, pageId, false, videos, resources)
    }
}

export const mapBookByOrderCreateUser = (books, bookOrder) => {
    if (Array.isArray(books)) {
        const resp = []
        const order = bookOrder && bookOrder.length > 0 ? bookOrder : BOOKORDER;
        order.forEach(book => {
            const bk = []
            map(book.order, id => {
                const hasBook = find(books, k => k.id == id);
                if (hasBook) {
                    bk.push(hasBook)
                }
            })
            if (bk.length > 0) {
                resp.push({
                    title: book.title,
                    color: book.color,
                    books: bk
                })
            }
        })
        const addedbook = flatMap(map(resp,k=>k.books))
        const unAddedBook = filter(books,b=>!find(addedbook,k=>k.id==b.id))
        // if(unAddedBook.length>0){
        //     map(unAddedBook,ub=>{
        //         resp.push({
        //             title:'',
        //             color:null,
        //             books:[ub]
        //         })
        //     })
        // }
        return resp;
    }
    return books;
}

export const mapBookByOrder = (books, bookOrder) => {
    if (Array.isArray(books)) {
        const resp = []
        const order = bookOrder && bookOrder.length > 0 ? bookOrder : BOOKORDER;
        order.forEach(book => {
            const bk = []
            map(book.order, id => {
                const hasBook = find(books, k => k.id == id);
                if (hasBook) {
                    bk.push(hasBook)
                }
            })
            if (bk.length > 0) {
                resp.push({
                    title: book.title,
                    color: book.color,
                    books: bk
                })
            }
        })
        const addedbook = flatMap(map(resp,k=>k.books))
        const unAddedBook = filter(books,b=>!find(addedbook,k=>k.id==b.id))
        if(unAddedBook.length>0){
            map(unAddedBook,ub=>{
                resp.push({
                    title:'',
                    color:null,
                    books:[ub]
                })
            })
        }
        return resp;
    }
    return books;
}

export const groupBookByOrder = (books, bookOrder) => {
    if (Array.isArray(books)) {
        const resp = []
        const order = bookOrder && bookOrder.length > 0 ? bookOrder : BOOKORDER;
        order.forEach(book => {
            const bk = []
            map(book.order, id => {
                const hasBook = find(books, k => k.id == id);
                if (hasBook) {
                    bk.push(hasBook)
                }
            })
            if (bk.length > 0) {
                resp.push({
                    title: book.title,
                    color: book.color,
                    books: bk
                })
            }
        })
        return resp;
    }
    return books;
}

export  const getUserAssignment = (userBooks, booksAllList, assignments, currentUser, bookPermissions,currentClass) => {
    let USER_BOOKS_IDS = map(userBooks, 'bookId');
    let USER_BOOKS_EXCEPTIONS = filter(booksAllList, k => k.isNotAssignable).map(k => k.id);
    const userassignment = filter(assignments, e => {
        const chapter =  get(e, "data.chapter", '')
        if (!find(booksAllList, k => k.id == chapter)) {
            return true;
        }
        return USER_BOOKS_EXCEPTIONS.includes(chapter)||USER_BOOKS_IDS.includes(chapter);
    })
    return userassignment.filter(k => {
        if (bookPermissions.length == 0) {
            return true;
        }
        const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "data.chapter")])
        if (bookaccess) {
            return bookaccess.available
        } else {
            return true;
        }
    }).map(k=>{
        const hasDiff = find(k.diff, ["userId", currentUser.id])
        const classConfig = find(k.classes,['id',get(currentClass,"id")])
        if (hasDiff) {
            k.lateDate = get(hasDiff, "lateDate", k.lateDate)
            k.scheduleAt = get(hasDiff, "scheduleAt", k.scheduleAt)
            k.dueDate = get(hasDiff, "dueDate", k.dueDate)
            k.retakes = get(hasDiff, "retakes", k.retakes)
            k.timeDuration = get(hasDiff, "timeDuration") > 0 ? get(hasDiff, "timeDuration") : k.timeDuration
        }
        if(classConfig&&classConfig.diff){
            const hasClassDiff = find(classConfig.diff.student,k=>k==currentUser.id)
            if(hasClassDiff){
                k.dueDate = classConfig.diff.dueDateDif;
                k.retakes = classConfig.diff.retakesDif;
                k.scheduleAt = classConfig.diff.scheduleAtDiff;
            }
        }
        return k;
    }).filter(k=>{
        const scheduleAt = get(k,"scheduleAt");
        if (scheduleAt && moment(scheduleAt).isValid()) {
            const isAfterSchedule = moment().diff(moment(scheduleAt), 'minutes') >= 0;
             return isAfterSchedule;
        }else{
            return true;
        }
    })
}

export const getStoryUrl = (story, bookId, pageId, quiz, activity) => {
    const hasStories = filter(story, ["bookId", bookId])
    if (hasStories.length > 0) {
        let storyId = null;
        let level = null;
        map(hasStories, st => {
            map(st.levels, lv => {
                if (lv) {
                    if (parseInt(pageId) >= parseInt(lv.start) && parseInt(pageId) <= parseInt(lv.finish)) {
                        storyId = st.id;
                        level = lv.level;
                    }
                }
            })
        })
        if (storyId && level) {
            return `/stories?id=${storyId}&page=${pageId}&level=${level}&activity=${activity}&quiz=${quiz}`
        }
    } else {
        return null;
    }
}
export const modifyAssignmentWithDiff= (quiz,currentClass) =>{
    if (get(quiz,"diff.length",0) > 0) {
        if (get(quiz,"userDiff.length",0) > 0) {
            const diffStudent = map( quiz.userDiff,u=>u.id)
            if(quiz&&quiz.classes){
                const findex = findIndex(quiz.classes,c=>c.id==currentClass.id);
                if(findex>=0){
                    quiz.classes = map(quiz.classes,cls=>{
                        if(cls.id==currentClass.id){
                            if(!cls.diff){
                                cls.diff =  {
                                    id: currentClass.id,
                                    retakesDif: quiz.diff[0].retakes,
                                    levelExercise: quiz.diff[0].levelExercise,
                                    dueDateDif:  quiz.diff[0].dueDate,
                                    scheduleAtDiff :quiz.diff[0].scheduleAt ,
                                    student: diffStudent
                                }
                            }
                        }
                        return cls;
                    })
                }else{
                    quiz.classes.push({
                        displayName: quiz.displayName,
                        dueDate: quiz.dueDate,
                        id: currentClass.id,
                        scheduleAt: quiz.scheduleAt,
                        sectionId: currentClass.sectionId,
                        schoologyCategory: quiz.schoologyCategory,
                        diff: {
                            id: currentClass.id,
                            retakesDif: quiz.diff[0].retakes,
                            levelExercise: quiz.diff[0].levelExercise,
                            dueDateDif:  quiz.diff[0].dueDate,
                            scheduleAtDiff :quiz.diff[0].scheduleAt ,
                            student: diffStudent
                        }
                    }) 
                }
        
            }else{
                quiz.classes = [{
                    displayName: quiz.displayName,
                    dueDate: quiz.dueDate,
                    id: currentClass.id,
                    scheduleAt: quiz.scheduleAt,
                    sectionId: currentClass.sectionId,
                    schoologyCategory: quiz.schoologyCategory,
                    diff: {
                        id: currentClass.id,
                        retakesDif: quiz.diff[0].retakes,
                        levelExercise: quiz.diff[0].levelExercise,
                        dueDateDif:  quiz.diff[0].dueDate,
                        scheduleAtDiff :quiz.diff[0].scheduleAt ,
                        student: diffStudent
                    }
                }] 
            }
        }}
   return quiz;
}
export const filterStoryClassQuizOrActivity = (data,story) => {
    return filter(data, k => find(story,["bookId",get(k, "data.chapter")]))
}
export const filterNonStoryClassQuizOrActivity = (data,story) => {
    return filter(data, k => !find(story,["bookId",get(k, "data.chapter")]))
}

export const filterMainGradebookClassQuizOrActivity = (data, story, exam) => {
  return filter(data, (k) => {
    const bookId = get(k, "data.chapter")
    const hasInExam = find(exam, k=>k.bookId ==  bookId);
    const hasInStory = find(story,k=>k.bookId ==  bookId);
    if (hasInExam || hasInStory) {
      return false;
    }
    return true;
  });
};
export const groupLinkedSection = (classes) =>{
    const parentClass = filter(classes, k => k.schoology_parent_id == 0);
    let group_classes = {}
    map(parentClass, k => {
        const child_class = filter(classes, ["schoology_parent_id", k.sectionId])
        child_class.push(k)
        group_classes[k.id] = child_class
    })
    return group_classes;
}
export const generateUrlForNonEbook = (id, page, activity, quiz, backToLabel, level, CHAPTER_ID, is_graphic_organizer) => {
    if (backToLabel == 'Balcón cultural') {
        if (!is_graphic_organizer) {
            return `/balconCulturalBook?id=${id}&page=${page}&activity=${activity}&quiz=${quiz}`
        } else {
            return `/balconCulturalBook?id=${id}&page=${page}&assignment_type=graphic_organizer&activity_id=${activity}`
        }
    }
    if (backToLabel == '1nformate lessons') {
        if (!is_graphic_organizer) {
            return `/informateBook?id=${id}&page=${page}&activity=${activity}&quiz=${quiz}`;
        } else {
            return `/informateBook?id=${id}&page=${page}&assignment_type=graphic_organizer&activity_id=${activity}`;
        }
    }
    if (backToLabel == 'Comunidad') {
        if (!is_graphic_organizer) {
            return `/ComunidadBook?id=${id}&page=${page}&activity=${activity}&quiz=${quiz}`;
        } else {
            return `/ComunidadBook?id=${id}&page=${page}&assignment_type=graphic_organizer&activity_id=${activity}`;
        }
    }
    if (backToLabel == 'Conectándome') {
        if (!is_graphic_organizer) {
            return `/ConectandomeBook?id=${id}&page=${page}&activity=${activity}&quiz=${quiz}`;
        } else {
            return `/ConectandomeBook?id=${id}&page=${page}&assignment_type=graphic_organizer&activity_id=${activity}`;
        }
    }
    if (backToLabel == 'Worksheet') {
        if (!is_graphic_organizer) {
            return `/worksheet-1NFORMATE?id=${id}&page=${page}&activity=${activity}&quiz=${quiz}`;
        } else {
            return `/worksheet-1NFORMATE?id=${id}&page=${page}&assignment_type=graphic_organizer&activity_id=${activity}`;
        }
    }
    if (backToLabel == 'Graphic Organizers') {
        if (!is_graphic_organizer) {
            return `/graphic-organizers-book?id=${id}&page=${page}&activity=${activity}&quiz=${quiz}`;
        } else {
            return `/graphic-organizers-book?id=${id}&page=${page}&assignment_type=graphic_organizer&activity_id=${activity}`;
        }
    }
    if (backToLabel == 'AP') {
        if (!is_graphic_organizer) {
            return `/apBook?chapter=${CHAPTER_ID}&id=${id}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
        } else {
            return `/apBook?chapter=${CHAPTER_ID}&id=${id}&assignment_type=graphic_organizer&activity_id=${activity}`;
        }
    }
    if (backToLabel == 'Stories') {
        if (!is_graphic_organizer) {
            return `/stories?id=${id}&level=${level}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
        } else {
            return `/stories?id=${id}&level=${level}&page=${page}&assignment_type=graphic_organizer&activity_id=${activity ?? null}`;
        }
    }
}
export function updateUrlPathWithActivityOrQuiz(originalUrl, activity = null, quiz = null) {
    const url = new URL(originalUrl);
    const updateQueryParam = (param, value) => {
      if (value !== null && url.searchParams.get(param) === 'null') {
        url.searchParams.set(param, value);
      }
    };
    updateQueryParam('activity', activity);
    updateQueryParam('quiz', quiz);
    return `${url.pathname}${url.search}`;
  }

export  const getBackToUrl =(backToLabel,CHAPTER_ID)=>{
    if(backToLabel == 'AP'&&CHAPTER_ID){
        return `/ap?id=${CHAPTER_ID}`
    }
    if(backToLabel == '1nformate lessons'){
        return `/informate`
    }
    if(backToLabel == 'Balcón cultural'){
        if (window.innerWidth >=1032) {
            return `/balconCultural`
        } else {
            return '/OneWayLessons'
        }
    }
    if(backToLabel == 'Comunidad'){
        if (window.innerWidth >=1032) {
            return `/comunidad`
        } else {
            return '/OneWayLessons'
        }
    }
    if(backToLabel == 'Conectándome'){
        if (window.innerWidth >=1032) {
            return `/Conectandome`
        } else {
            return '/OneWayLessons'
        }
    }
    if(backToLabel == 'Worksheet'){
        return `/worksheet`
    }
    if(backToLabel == 'Graphic Organizers') {
        return `/graphic-organizers`
    }
    if(backToLabel == 'AP') {
        return `/graphic-organizers`
    }
    if(backToLabel == 'Stories'){
        return "/story-levels"
    }
}