//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Loading from 'components/Loading';
//  -- Components ---------------------


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    margin: 0px auto;
    width: 100%;
    /* margin-left: -22%; */

    @media (max-width: 768px) {
        margin-left: 0px !important;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: -25px !important;
    }

    @media (min-width: 1024px) and (max-width: 1400px) {
        margin-left: -2% !important;
    }
`;

export const iformateFrame = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
    src: https://www.1wayeducation.com/pd;
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
const  PdContainer = ({ currentUser }) => {
    const [loader, setLoader] = React.useState(true);
    return (
        <Wrapper style={{ width: '98.8vw' }}>
            {loader&&<Loading />}
            <iframe width='100%' height='100%' src="https://www.1wayeducation.com/pd" style={{border: 'none'}}
            onLoad={(e)=>{
                setLoader(false)
            }}
            ></iframe>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
PdContainer.propTypes = {};

//  -- Default props ------------------
PdContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------

const mapStateToProps = state => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(PdContainer);
