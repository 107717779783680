//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { CaretDownOutlined } from '@ant-design/icons';
import Snackbar from '@material-ui/core/Snackbar';
import { filter, find, groupBy, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
//  -- Components ---------------------
import { Button, Modal, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import { Dropdown, Menu } from 'antd';
import EmptyState from 'components/EmptyState';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { handleChangeStatusActivity, handleDeleteClassActivity, handleUpdateClassActivity } from 'store/classActivities/thunks';
import { handleChangeStatusQuizzes, handleDeleteClassQuiz, handleUpdateClassQuiz } from 'store/classQuizzes/thunks';
import { useExams } from 'store/exams/selectors';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { TableBg } from "utils/assets/images";
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------
import GradeEditor from 'features/forms/GradeEditorStory';

//  -- Thunks -------------------------
import base64 from "base-64";
import TooltipComp from 'components/Toottip';
import _, { get, has, map } from 'lodash';
import moment from "moment";
import { useCallback } from 'react';
import { useBookUnits } from "store/booksUnits/selectors";
import { useResources, useVideos } from 'store/resources/selectors';
import { useSchoologyAssignment } from "store/schoologyauth/selectors";
import { useStory } from "store/story/selectors";
import { handleListClassSubmissions, handleUpdateSubmission } from 'store/submissions/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import { handleGetUserPreference } from 'store/userPreference/thunks';
import { handleDeleteUserData } from 'store/users/thunks';
import { isArray } from 'underscore';
import Schoology from 'utils/Schoology';
import { handleSyncSchoologyClassesGrades} from "store/schoology/thunks"
import { getAssignmentLable, getStoryUrl, isVideo , generateUrlForNonEbook } from 'utils/lib/ebookHelper';
import { getCurrenSem, semeterDateByYear } from "utils/methods/math";
import { BACK_TO_BOOK } from 'utils/config/reference';
import { openLoginInNewWindow  } from "store/keystore/thunks"
const rowOneColor = "#5f4c82"
const rowTwoColor = "#7b96d4"
const rowSemester = COLOR.OrangeExam
const rowThreeColor = "#7c458b"
const rowFourColor = COLOR.lightgreen

const getKey = (sem, year) => (`${sem == 1 ? "semester 1" : "semester 2"} - ${year}`)
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getBgColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR
const getBgColorSemester = (isActive) => isActive ? ACTIVE_BTN_COLOR : rowSemester
const NAME_SHORTING = [{ key: "last_a_z", title: "Last Name, A-Z" }, { key: "last_z_a", title: "Last Name, Z-A" }, { key: "first_a_z", title: "First Name, A-Z" }, { key: "first_z_a", title: "First Name, Z-A" }]
const CELLARRAY_LENGTH = 15


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CustomBtn = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black300};
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 4px;
    outline: none;

    &:hover {
        color: ${COLOR.green100};
    }
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;


// border-right-color:${COLOR.black100}
const OverflowOne = styled.div`
    height: auto;
    display: inline-block;
    border-right: 1px solid black;
    @media (max-width: 634px){
    
    }
`;
const OverflowTwo = styled.div`
    height: auto;
    display: inline-block;
    @media (max-width: 634px) {
       
    }
`;

const AssignmentDeleteWrapper = styled.div`
   cursor:pointer;
   margin-top: 5px;
   opacity:0;
`;

const TableCell = styled.div`
    text-align: center;
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    overflow:hidden;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;


const TableCellSemester = styled.div`
    text-align: center;
    align-items: center;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColorSemester(p.active)};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const TableCellExam = styled.div`
    text-align: center;
    align-items: center;
    background-image: linear-gradient(90deg, ${COLOR.PinkExam} 0%, ${COLOR.OrangeExam} 100%); 
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const TableCellEdit = styled.div`
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};

    &:first-child {
        justify-content: flex-start;
    }
    &:hover {
        color: white;
    }
`;
const AssignmentLabel = styled.button`
  border:none;
  background-color:transparent;
  width:100%;
  cursor:pointer;
  overflow:hidden;
`;
const OneLogoRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
`;

const TableRow = styled.div`
    align-items: center;
    background-color: ${p => p.index % 2 === 0 ? '#40a9ff5e' : 'transparent'};
    border: 1px solid;
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    width: auto;
`;

const Table = styled.div`
    padding-bottom: 0px;
`;


const Wrapper = styled.div`
    width: 100%;
`;
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
`;
const TbgImgWrapper = styled.div`
    height: 69px;
    width: 100%;
    display: flex;
    justify-content: center;
`
const TbgImage = styled.img`
    width: 100%;
    height: 135%;
`
const Tab = styled.button`
    border: none;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColor(p.active)};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;
const TabExam = styled.button`
    border: none;
    background-image: linear-gradient(90deg, ${COLOR.PinkExam} 0%, ${COLOR.OrangeExam} 100%); 
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;
const SyncTab = styled(Tab)`
    justify-self: flex-end;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    margin-left:5px;
    margin-right:5px;
    height: 35px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
`;
const BookNameTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  `;

const TbgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 93px;
    background-color: ${COLOR.blueish100};
    border: 3px solid ${COLOR.indigo400};
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    `
const TbgIconWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
    `
const TbgIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
}
    `
const OverviewIcon = styled.div`
    width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
    background-image: url(${p => p.src});
    background-position: center;
    background-size: 2880% 288%;
    background-repeat: no-repeat;
}
    `
const TbgTitle = styled.span`
    color: ${COLOR.white};
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    `
const TbgTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    `
const TbgList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 14px;
`
const TbgListItem = styled.li`
    color: ${COLOR.white};
    font-size: 10px;
    font-weight: 400;
    `

const Logo = styled.img`
  width: 60px;
  border-radius: 50%;
`;
const OneWayText = styled.span`
  color: #424447;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  text-transform: uppercase;
  line-height: 20px;
`;

const AssignmentTxt = styled.div`
    font-size: 14px;
    margin-left: 20px;
    font-weight: 600;
`;
const NameContent = styled.span`
    color: #fff;
    margin-left: 10px;
    width: 220px;

`;
const StudentNameTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    height:40px;
    padding-right: 20px;;
    `;
const QuestionTab = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(128,161,90);
    width: 95%;
    height: 25px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
`;
const StudentName = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(89,118,243);
    width: 95%;
    height: 25px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
`;
const HeaderTab = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:${COLOR.indigo100};
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:0px 5px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const HeaderTabP = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:rgb(11,9,84);
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const TableTopBtnCont = styled.div`
    justify-content: center;
    align-items: center;
    padding-left: 1.5%;
    overflow: hidden;
    display: flex;
    padding-left:5px;
    padding-right:5px;
`;
const TopTabCont = styled.div`
    display:flex;
    flex-direction:row;
`;
const TotalNStudentTxt = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
    white-space:pre-line;
    `;
const Exam2TableHeader = styled(TableHeader)`
    background-color:rgb(54,58,78);
    height:45px;
    border-radius:5px;
    align-items:center;
 

`;
const ChartContainer = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap:wrap;
    @media (max-width: 992px) {
       width:100%;
      }
    
`;
const ChartContainerInner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:50px;
    margin-left:10px;
    margin-right:10px;
`;
const ChartLabel = styled.span`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -20px;
`;
const SearchBox = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 7px;
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  outline: none;
  padding: 2px 16px;
  width: 90%;
  margin-right: 2px;
  margin-left: 2px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
  }
  :-ms-input-placeholder {
    color: #fff;
  }
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const SearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color:rgb(126,112,165);
    color: ${COLOR.green};
    font-size: 14px;
    align-items: center;
    width:100%;
    padding-right:12px;
    height: 30px;
    @media (max-width: 992px) {
       min-width:200px;
       margin-bottom:30px;
      }
`;
const ExamTableSection1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    margin-top:10px;
    `;
const ExamTableSection2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 992px) {
        width: 100%;
    }
    `;
const TableItem = styled.td`
    height:40px;
    width: 25%;
    border: 0.1px solid rgba(0,0,0,0.2);
    border-collapse: collapse;
    text-align:center;
    padding-top: 10px;
    `;
const ExamGradeTable = styled.table`
  border-collapse: collapse;
`;
const ExamRow = styled.tr`
    display: flex;
    flex-direction: row;`;
const PlacementText = styled.span`
       color:${p => p.textColor ? p.textColor : COLOR.gray100}
    `;
const GraphRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:20px;
   @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    }

`;
const ExamOverflowTwo = styled(OverflowTwo)`
    width:90%;
    border-right: 0px;
    @media (max-width: 992px) {
        width:100%;
    }
    `;
const CloseBtn = styled.span`
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    align-self: flex-end;
    display:flex;
    flex-direction:row;
    color:rgb(197,2,91);
    font-weight:bold;
    justify-content: flex-end;
    `;
const ExamGradeSec = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image:url(${TableBg});
    padding:16px;
`;
const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;
const SortingMenu = styled.span`
  background-color: ${(p) => (p.active ? COLOR.indigo100 : COLOR.white)};
`;
const ChartPerText = styled.span`
    position: absolute;
    top: -10px;
    font-size: 11px;
`;
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    border-right: none;
    margin-bottom: 52px;
    @media (max-width: 634px){
        width: 100%;
        overflow: scroll;
    }
`;
const TableWrapperInner2 = styled.div`
    overflow: scroll;
    overflow-y: hidden;
    @media (max-width: 634px){
        overflow: visible;
        
    }
    ::-webkit-scrollbar {
        width: 3px;
        height: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
`;
const AssignmentLabelText = styled.span`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
`;
const NameText = styled.span`
width: 200px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Gradebook
//
//  -- Description
//  District and class announcements
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Gradebook />
//



const sortname = (a, b) => {
    const nameA = a?.lastName?.toLowerCase()?.replace(/\s/g, '');
    const nameB = b?.lastName?.toLowerCase()?.replace(/\s/g, '');;
    if (nameA < nameB) { return -1 }
    else if (nameA > nameB) { return 1 }
    else { return 0 }
}
const SemesterToShow = () => {
    const currentYear = moment().get("year");
    const lastYear = currentYear - 1;
    const semYearToShow = { sem1: '', sem2: '' }
    const { sem1StartDate, sem1EndDate } = semeterDateByYear(currentYear)
    const currentSem = getCurrenSem();

    if (moment().isSameOrAfter(sem1StartDate) && moment().isSameOrBefore(sem1EndDate)) {
        semYearToShow.sem1 = getKey(1, currentYear);
        semYearToShow.sem2 = getKey(2, currentYear);
    } else {
        semYearToShow.sem1 = getKey(1, lastYear);
        semYearToShow.sem2 = currentSem;
    }
    return semYearToShow;

}
const defaultAssignmentData = {}
defaultAssignmentData[getCurrenSem()] = new Array(CELLARRAY_LENGTH).fill({}, 0, CELLARRAY_LENGTH)
const GradeTable = ({
    currentClass,
    currentOrg,
    dataLoading,
    users,
    reviewAssignment,
    submissions,
    booksAllList,
    isReviewAccount,
    handleEditQuiz,
    handleEditActivity,
    currentUser,
    classActivities,
    classQuizzes,
    getAssignmentCreation,
    handleoverAllShow,
    handleGradeValueShow
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const orgPermissions = useCurrentOrgPermission()
    const story = useStory()
    const bookPermissions = _.get(orgPermissions, "books", [])
    const currentSem = getCurrenSem()
    const schoologyAuth = useSelector(state => state.schoologyAuth);
    const client = new Schoology(schoologyAuth);
    const [index, setIndex] = useState(-1);
    const [open, setModalState] = useState(false);
    const exams = useExams()
    const [loginAsUserId,setLoginAsUserId] = useState(null);
    const [loginasLoader,setLoginasLoader] = useState(false);
    const [loginAsModal,setLoginAsModal] = useState(false);
    const [passwordToView, setPasswordToView] = useState({
        username: "",
        password: "",
        message: '',
        open: false
    });
    const [selectedUser, setSelectedUser] = useState({});
    const [semAssignment, setSemAssignment] = useState(defaultAssignmentData);
    const [activeSemData, setActiveSemData] = useState(new Array(CELLARRAY_LENGTH).fill({}, 0, CELLARRAY_LENGTH));
    const scale_1_5 = false
    const gradebook_a_f = false
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [activeGradeTab, setActiveGradeTab] = useState("")
    const [filteredStudent, setFilteredStudent] = useState([])
    const [activeSem, setActiveSem] = useState(currentSem);
    const [isFlagMenuOpen, setFlagmenuOpen] = useState(false)
    const isSchoologyUser = currentUser && currentUser.schoologyid ? true : false;
    const [listStudentUser, setListStudentUser] = useState(users && users.length > 0 ? filter(users, x => x.role === 'student' && x.active).sort(sortname) : new Array(1).fill({}, 0, 1))
    const [deleteClassAssignmentToggle, setDeleteClassAssignmentToggle] = useState(false);
    const [assignmentData, setAssignmentData] = useState(null)
    const [deleteAssignmentLoading, setSeleteAssignmentLoading] = useState(false);
    const [nameShortType, setNameShortType] = useState(NAME_SHORTING[0]);
    const videos = useVideos()
    const resources = useResources()
    const [openPop, setOpen] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState('');
    const [schoologyAssignment, listSchoologyAssignment] = useSchoologyAssignment()
    const sortByName = (a, b) => {
        const sortingFunctions = {
            [NAME_SHORTING[0].key]: (nameA, nameB) => nameA.localeCompare(nameB, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[1].key]: (nameA, nameB) => nameB.localeCompare(nameA, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[2].key]: (nameA, nameB) => nameA.localeCompare(nameB, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[3].key]: (nameA, nameB) => nameB.localeCompare(nameA, 'en', { sensitivity: 'base' }),
        };
    
        const getKey = (type) => {
            return (type === NAME_SHORTING[2].key || type === NAME_SHORTING[3].key) ? 'firstName' : 'lastName';
        };
    
        const key = getKey(nameShortType.key);
    
        const getNormalizedString = (obj, key) =>
            obj[key].toLowerCase().replace(/\s/g, "") +
            obj[key === "firstName" ? "lastName" : "firstName"]
                .toLowerCase()
                .replace(/\s/g, "");
    
        const nameA = getNormalizedString(a, key);
        const nameB = getNormalizedString(b, key);
    
        return sortingFunctions[nameShortType.key](nameA, nameB);
    };
    useEffect(() => {
        const allstudents = _.uniqBy(filter(users, x => x.role === 'student').sort(sortByName), "username");
        let arr = new Array(1).fill({}, 0, 1)
        setListStudentUser(allstudents.length > 0 ? allstudents : arr)
        setFilteredStudent(allstudents.length > 0 ? allstudents : arr)

    }, [users])
    useEffect(() => {
        setFilteredStudent(filteredStudent.sort(sortByName))
        setListStudentUser(listStudentUser.sort(sortByName))
        setTimeout(forceUpdate, 500)

    }, [nameShortType])

    useEffect(() => {
        const toShowSemObj = SemesterToShow()
        if (classActivities && classQuizzes) {
            const uniqueActivity = uniqBy(classActivities, "activityId");
            const uniqueQuiz = uniqBy(classQuizzes, 'quizId');
            let _assignments = [...uniqueActivity, ...uniqueQuiz].filter(k => {
                if (bookPermissions.length == 0) {
                    return true;
                }
                const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "data.bookId")])
                if (bookaccess) {
                    return bookaccess.available
                } else {
                    return true;
                }
            })
            const _sortedssignment = _assignments
            let yearData = groupBy(_sortedssignment, x => moment(x.dueDate).format("YYYY"))
            const semData = {}
            Object.keys(yearData).map(year => {
                const { sem1StartDate, sem1EndDate, sem2StartDate, sem2EndDate } = semeterDateByYear(year)
                const sem1 = yearData[year].filter(x => moment(x.dueDate).isSameOrAfter(sem1StartDate, "day") && moment(x.dueDate).isSameOrBefore(sem1EndDate, "day")).sort((a, b) => new moment(a.dueDate).format('YYYYMMDD') - new moment(b.dueDate).format('YYYYMMDD'))
                const sem2 = yearData[year].filter(x => moment(x.dueDate).isSameOrAfter(sem2StartDate, "day") && moment(x.dueDate).isSameOrBefore(sem2EndDate, "day")).sort((a, b) => new moment(a.dueDate).format('YYYYMMDD') - new moment(b.dueDate).format('YYYYMMDD'))
                if (sem1.length < CELLARRAY_LENGTH) {
                    let len = CELLARRAY_LENGTH - sem1.length;
                    let arr = new Array(len).fill({}, 0, len)
                    sem1.push(...arr)
                }
                if (sem2.length < CELLARRAY_LENGTH) {
                    let len = CELLARRAY_LENGTH - sem2.length;
                    let arr = new Array(len).fill({}, 0, len)
                    sem2.push(...arr)
                }

                if (sem1.length > 0 || currentSem === getKey(1, year)) {
                    semData[getKey(1, year)] = sem1

                }
                if (sem2.length > 0 || currentSem === getKey(2, year)) {
                    semData[getKey(2, year)] = sem2
                }
            })
            if (semData[currentSem]) {
                setActiveSemData(semData[currentSem])
            } else {
                setActiveSemData(new Array(CELLARRAY_LENGTH).fill({}, 0, CELLARRAY_LENGTH))
                semData[currentSem] = new Array(CELLARRAY_LENGTH).fill({}, 0, CELLARRAY_LENGTH)
            }
            if (Object.keys(semData).length > 0) {
                const tempSemData = {}
                Object.keys(toShowSemObj).map(key => {
                    const dt = semData[toShowSemObj[key]];
                    tempSemData[toShowSemObj[key]] = isArray(dt) && dt.length > 0 ? dt : new Array(CELLARRAY_LENGTH).fill({}, 0, CELLARRAY_LENGTH)
                })
                setSemAssignment(tempSemData)
            }

        }
    }, [classActivities, classQuizzes]);
    useEffect(() => {
        getPref()
        getGradesFromScoology()
        getAssignmentFromSchoology()
    }, [classActivities, classQuizzes])
    const getPref = async () => {
        await dispatch(handleGetUserPreference(currentUser?.id));

    }


    const handleClose = () => {
        setOpen(false);
    };

    const updateSubmission = async (subm) => {
        if (!subm || !subm.userId) return;
        await dispatch(handleUpdateSubmission(subm?.id, subm, subm))
    }
    const getGradesFromScoology = async () => {
        try {
            if (currentClass?.sectionId) {
                await handleSyncSchoologyClassesGrades(currentUser.id, currentClass.id);
                await dispatch(handleListClassSubmissions(currentClass?.id))
            }

        } catch (error) {
            console.log(error, "bid-err");
        }
    }
    const history = useHistory();
    const bookUnits = useBookUnits()
    const generateUrl = (CHAPTER_ID, page, activity, quiz) => {
        const storyUrl = getStoryUrl(story, CHAPTER_ID, page, quiz, activity)
        const backToLabel = BACK_TO_BOOK[CHAPTER_ID]
        const BALCON_PAGES = _.get(_.find(bookUnits, ["bookId", CHAPTER_ID]), "pages", [])
        const bookitem = _.find(BALCON_PAGES, k => parseInt(k.start) <= page && parseInt(k.finish) >= page)
        const url = generateUrlForNonEbook(bookitem?.id, page, activity, quiz, backToLabel, null, CHAPTER_ID)
        if (url && backToLabel) {
            return url;
        }
        else if (storyUrl) {
            return storyUrl;
        } else {
            return null;
        }
    } 
    const goToBook = (chapter, pageId) => {
        const generatedUrl = generateUrl(chapter, pageId, null, null)
        if (isVideo(chapter, resources)) {
            history.push(`/video?id=${chapter}&quiz=null`);
        } else if (generatedUrl) {
            history.push(generatedUrl);
        }
        else {
            history.push(`/ebook?chapter=${chapter}&page=${pageId}&activity=null&quiz=null`);
        }
    }
    const booknav = (assignment) => {
        const isActive = get(assignment, "active", true)
        return (
            <Menu>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (assignment?.quizId) {
                            handleEditQuiz(assignment)
                        } else if (assignment.activityId) {
                            handleEditActivity(assignment)
                        }
                    }}>
                        Edit
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        goToBook(get(assignment, "data.chapter"), get(assignment, "data.page"))
                    }}>
                        Go to book
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (assignment && Object.keys(assignment).length > 0) {
                            setDeleteClassAssignmentToggle(true)
                            setAssignmentData(assignment)
                        }
                    }}>
                        Delete
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn style={{ color: isActive ? COLOR.black : COLOR.red }}
                        onClick={() => {
                            if (get(assignment, "data.typeId") == "quiz" || get(assignment, "data.typeId") === "quizresource") {
                                activeDeactiveQuizzes(get(assignment, "id"), !isActive)
                            } else {
                                activeDeactiveActivites(get(assignment, "id"), !isActive)
                            }
                        }}>
                        {isActive ? "Deactivate" : "Activate"}
                    </PaddedBtn>
                </Menu.Item>
            </Menu>
        );
    };


    const activeDeactiveQuizzes = async (id, active) => {
        await dispatch(handleChangeStatusQuizzes(id, active))
    }
    const activeDeactiveActivites = async (id, active) => {
        await dispatch(handleChangeStatusActivity(id, active))

    }
    const getAssignmentFromSchoology = async () => {
        try {
            if (currentClass?.sectionId) {
                const assignment = await client.getSectionAssignment(currentClass.sectionId)
                if (assignment && Array.isArray(assignment.assignment)) {
                    let _assignment = assignment.assignment;
                    const updateTolausd = [];
                    const deleteFromLausd = []
                    const schoologActivityyAssignment = filter(classActivities, k => k.sAssignmentId)
                    const schoologQuizAssignment = filter(classQuizzes, k => k.sAssignmentId)
                    const allAssignment = [...schoologActivityyAssignment, ...schoologQuizAssignment]
                    map(_assignment, async a => {
                        let _lausdAssignment = find(allAssignment, k => k.sAssignmentId == a.id)
                        let s_time = new Date(parseInt(`${a.last_updated}000`))
                        if (_lausdAssignment) {
                            let l_time = _lausdAssignment.modified ? new Date(_lausdAssignment.modified) : new Date(parseInt(`${_lausdAssignment.createdAt}`))
                            if (moment(l_time).isBefore(moment(s_time)) && moment().diff(moment(l_time), "minutes") > 3) {
                                console.log("updated assignment from schoology")
                                let s_due = new Date(a.due).toISOString()
                                let l_due = _lausdAssignment.dueDate
                                if (a.title != _lausdAssignment.displayName || s_due != l_due) {
                                    updateTolausd.push({ ..._lausdAssignment, displayName: a.title, dueDate: s_due })
                                }
                            }
                        }
                    })
                    // map(allAssignment, a => {
                    //     const hasAssignment = find(_assignment, k => k.id == a.sAssignmentId)
                    //     if (!hasAssignment) {
                    //         deleteFromLausd.push(a)
                    //     }
                    // })
                    // if (deleteFromLausd.length > 0) {
                    //     await Promise.all(deleteFromLausd.map(async a => {
                    //         if (a.quizId) {
                    //             await dispatch(handleDeleteClassQuiz(a.quizId, currentClass.id))
                    //         } else {
                    //             await dispatch(handleDeleteClassActivity(a.activityId, currentClass.id))
                    //         }
                    //     }))
                    // }
                    if (updateTolausd.length > 0) {
                        await Promise.all(updateTolausd.map(async a => {
                            if (a.quizId) {
                                await dispatch(handleUpdateClassQuiz(a.id, a))
                            } else {
                                await dispatch(handleUpdateClassActivity(a.id, a))
                            }
                        }))
                    }
                }

            }
        } catch (error) {
            console.log(error, "bid-err");
        }
    }
    const checkSchoologyAssignment = (assignment) => {
        if (!assignment?.sAssignmentId) return null;
        const hasAssignment = find(schoologyAssignment, k => k.id == assignment?.sAssignmentId)
        return hasAssignment ? null : `Deleted from schoology!`
    }

    // if (students.length < 1) {
    //     let arr = new Array(1).fill({}, 0, 1)
    //     students.push(...arr)
    // }
    const studentSubmissions = groupBy(submissions, 'userId');
    const getWeightedGrade = (grade, weight) => {
        if (weight === 0) {
            return 0;
        }
        return (grade) * weight / 100;
    };
    const getWeightageFroQuiz = (assignment) => {
        return classQuizzes?.find(x => x.quizId === assignment.id)?.weightage
    }
    const getWeightageForActivity = (assignment) => {
        return classActivities?.find(x => x.activityId === assignment.id)?.weightage
    }
    const getWeightageForAssignment = useCallback((assignemnt) => {
        let weightage = 100;
        if (assignemnt.typeId === 'quiz') {
            let calculatedWeightage = getWeightageFroQuiz(assignemnt);
            if (!isNaN(calculatedWeightage) && calculatedWeightage > 0 && calculatedWeightage <= 100) {
                weightage = calculatedWeightage
            }
        } else {
            let calculatedWeightage = getWeightageForActivity(assignemnt);
            if (!isNaN(calculatedWeightage) && calculatedWeightage > 0 && calculatedWeightage <= 100) {
                weightage = calculatedWeightage
            }
        }
        return weightage;
    }, [classQuizzes, classActivities])

    const calculateValue = useMemo(() => (assignment, user) => {
        if (!user || !get(assignment, "data")) return ({ value: "--", isTbg: false, isLate: false, manualGrading: false, completeTbg: false })
        let isDiffStudent = false;
        if (assignment.diff) {
            map(assignment.diff, x => x.userId === user.id ? isDiffStudent = true : null)
        }
        if (Array.isArray(assignment.classes) && assignment.classes.length > 0) {
            const classConfig = find(assignment.classes, ['id', get(currentClass, "id")])
            if (classConfig && classConfig.diff) {
                isDiffStudent = Boolean(find(classConfig.diff.student, k => k == user.id))
            }
        }
        const hasAssigned = assignment.users == null || assignment.users.length == 0 || assignment.users.includes(user.id)
        const quize = find(studentSubmissions[user.id], ['assignmentId', get(assignment, "data.id")]);
        const retaking = get(quize, "retaking", false)
        let score = 0;
        const isTbgObj = find(get(quize, "assignment.questions", []), (e) => e.type != "multipleChoice")
        const isTbg = typeof isTbgObj == "object"
        const isAllTab = filter(get(quize, "assignment.questions", []), (e) => e.type != "multipleChoice").length === get(quize, "assignment.questions.length", 0)
        const isPartialTbg = isTbg && !isAllTab;
        const tbgTxt = isTbg ? "TBG" : "-"
        const manualGrading = get(quize, "manualGrading")
        let value = (isTbg && manualGrading && !isPartialTbg) ? tbgTxt : (quize && quize.grade >= 0 ? quize.grade : tbgTxt)
        value = value == tbgTxt && quize?.submissions == 0 ? "-" : value
        value = score > 0 ? score : value
        value = retaking && value == 0 ? "-" : value
        let isLate = false
        if (quize) {
            isLate = quize.isLate;
        }
        if (!isNaN(value) && scale_1_5) {
            let _g = value <= 0 ? 0 : _.floor((value - 1) / 20)
            value = `${_g > 4 ? 4 : _g}`
        }
        else if (!isNaN(value) && gradebook_a_f) {
            const g = parseInt(value)
            if (g <= 20 && g >= 0) {
                value = 'F';
            }
            else if (g <= 40 && g >= 21) {
                value = 'D';
            }
            else if (g <= 60 && g >= 41) {
                value = 'C';
            }
            else if (g <= 80 && g >= 61) {
                value = 'B';
            }
            else if (g >= 80) {
                value = 'A';
            } else {
                value = '-'
            }
        }
        else {
            value = `${value}`
        }
        return { value, isTbg, isLate, isDiffStudent, manualGrading: manualGrading, completeTbg: isAllTab, hasAssigned }
    });

    const userNav = (user) => {
        return (
            <Menu>
                 <Menu.Item onClick={() => {
                  if(user&&user.id){
                    setLoginAsUserId(user.id)
                    setLoginAsModal(true);
                  }
                }}>
                    Login as
                </Menu.Item>
                <Menu.Item onClick={() => {
                    if (has(user, "password") && user.password && user.password.length > 0) {
                        setPasswordToView({
                            username: user.username,
                            password: base64.decode(user.password),
                            open: true,
                            message: ''
                        })
                    } else {
                        setPasswordToView({
                            username: '',
                            password: '',
                            open: true,
                            message: `No password is stored for username:- ${user.username}.`
                        })
                    }
                }}>
                    View username and  password
                </Menu.Item>
            </Menu>
        );
    };
    const sortingNav = (user) => {
        return (
            <Menu>
                {NAME_SHORTING.map(item => (<Menu.Item key={item.title} onClick={() => {
                    setNameShortType(item)
                }} style={{ backgroundColor: item.key == nameShortType.key ? COLOR.indigo600 : "#fff", color: item.key == nameShortType.key ? COLOR.white : COLOR.gray100 }}>
                    {item.title}
                </Menu.Item>))}
            </Menu>
        );
    };
    const getCellStyle = (isLate, isDiffStudent, isTbg) => {
        if (isLate) {
            return ({ backgroundColor: "rgba(255,0,0,0.2)" })
        } else if (isDiffStudent) {
            return ({ backgroundColor: "#00ff134f" })
        }
        else {
            return ({})
        }
    }
    const removeUser = async () => {
        setLoading(true);
        const orgId = currentOrg.parentId !== 'null' ? currentOrg.parentId : currentOrg?.id;
        await dispatch(handleDeleteUserData(selectedUser.id, selectedUser.username, orgId, currentClass.id));
        await dispatch(handleListClassUsers(currentClass.id));
        setSelectedUser({});
        setLoading(false);
        setModalState(false);
    };

    const deleteAssignment = async (e) => {
        e.preventDefault();
        setSeleteAssignmentLoading(true);
        try {
            if (assignmentData && assignmentData?.quizId) {
                await dispatch(handleDeleteClassQuiz(
                    assignmentData.quizId,
                    assignmentData.classId
                ));
            } else if (assignmentData && assignmentData.activityId) {
                await dispatch(handleDeleteClassActivity(
                    assignmentData.activityId,
                    assignmentData.classId
                ));
            }

            if (assignmentData && assignmentData.sAssignmentId && assignmentData.sAssignmentId.length > 0 && currentClass.sectionId) {
                await client.setToken(schoologyAuth);
                await client.deleteAssignment(currentClass.sectionId, assignmentData.sAssignmentId)
                listSchoologyAssignment(currentClass.sectionId)
            }
            onDeleteSuccess()
        } catch (error) {
            onDeleteSuccess()
        }

    };
    const onDeleteSuccess = () => {
        setDeleteClassAssignmentToggle(false);
        setSeleteAssignmentLoading(false)
        setAssignmentData(null)
    }
    const loginAsStudent = async ()=>{
        setLoginasLoader(true);
        await openLoginInNewWindow(loginAsUserId);
        setLoginasLoader(false);
        setLoginAsModal(false);
   }
    const isEditable = useCallback(() => {
        return false;
    }, [])
    const orderedActiveSemData = useMemo(() => {
        const val = _.orderBy(filter(activeSemData, k => k.dueDate), ["dueDate"], ["desc"])
        val.push(...filter(activeSemData, k => !k.dueDate))
        return val;
    }, [activeSemData])
    return (
        <Wrapper>
              <Modal
                open={loginAsModal}
                onClose={() => setLoginAsModal(false)}
            >
                <ModalContent>
                       You will login as your student, any changes you make will be APPLIED to this account.
                    <ModalRow>
                        <Button
                          color="secondary"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setLoginAsModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={loginAsStudent}
                        >
                            {loginasLoader ? <Loading size={20} white /> : 'Login as Student'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openPop}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {popupMessage}
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={() => setModalState(false)}
            >
                <ModalContent>
                    Are you sure you want to remove this student? All information and assignment submissions will be deleted.
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setModalState(false)
                                setSelectedUser({})
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={removeUser}
                        >
                            {loading ? <Loading size={20} white /> : 'Remove'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Modal
                open={passwordToView.open}
                onClose={() => setPasswordToView({
                    username: '',
                    password: '',
                    open: false,
                    message: ''
                })}
            >
                <ModalContent style={{ minWidth: 300 }}>
                    <div>
                        {!!passwordToView.password && <Row> username:- {passwordToView.username} </Row>}
                        {!!passwordToView.password && <Row> password:- {passwordToView.password} </Row>}
                        {!!passwordToView.message && <Row> {passwordToView.message} </Row>}
                    </div>
                    <ModalRow>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setPasswordToView({
                                    username: '',
                                    password: '',
                                    open: false,
                                    message: ''
                                })
                            }}
                        >
                            ok
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Modal
                open={deleteClassAssignmentToggle}
                onClose={() => setDeleteClassAssignmentToggle(false)}
            >
                <ModalContent>
                    Are you sure you want to delete the assignment? All grades will be removed as well.
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => onDeleteSuccess()}
                            disabled={deleteAssignmentLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={(e) => deleteAssignmentLoading ? null : deleteAssignment(e)}
                            className={`${deleteAssignmentLoading ? "disabled-button" : ""}`}
                        >
                            {deleteAssignmentLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            {dataLoading ? (
                <Loading />
            ) : (
                <div>
                    {activeGradeTab == "" &&
                        <>
                            <TableWrapper className="orgTableData">
                                <OverflowOne>
                                    <Table>
                                        <TableHeader style={{ paddingBottom: 0, marginBottom: 1 }}>
                                            <TableCellExam header="true" width='286px' style={{ backgroundColor: rowOneColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', cursor: 'pointer', justifyContent: "center", backgroundImage: "none" }}>
                                                Name of story
                                            </TableCellExam>
                                        </TableHeader>
                                        <TableHeader style={{ paddingBottom: 0, marginBottom: 1 }}>
                                            <TableCellSemester header="true" width='286px' style={{ backgroundColor: rowTwoColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', justifyContent: "center" }}>
                                                Page Number:
                                            </TableCellSemester>
                                        </TableHeader>
                                        <TableHeader style={{}}>
                                            <Dropdown overlay={sortingNav()} trigger={['click']}>
                                                <TableCell header="true" width='286px' style={{ backgroundColor: rowThreeColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', cursor: 'pointer', justifyContent: "center" }}>
                                                    <div>
                                                        {nameShortType.title}
                                                    </div>
                                                    <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                </TableCell>
                                            </Dropdown>
                                        </TableHeader>
                                        <TableHeader style={{ }}>
                                            <TableCellSemester header="true" width='286px' style={{ backgroundColor: rowFourColor, color:"#4e5156" , height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', justifyContent: "center" }}>
                                                Number of questions:
                                            </TableCellSemester>
                                        </TableHeader>
                                        {listStudentUser.map((user, i) => (
                                            <TableRow key={user?.id ?? i + "user"} index={i + 1} style={{ borderRight: '0px' }}>
                                                <TableCell width='100%' style={!user?.userclass?.active ? { color: COLOR.radish100 } : {}}>
                                                    <Dropdown overlay={userNav(user)} disabled={isReviewAccount || Object.keys(user ?? {}).length == 0} placement="bottomLeft" trigger={['click']}>
                                                        <CustomBtn onClick={e => e.preventDefault()}>
                                                            <MoreVert />
                                                        </CustomBtn>
                                                    </Dropdown>
                                                    <NameText>
                                                    {user.lastName}, {user.firstName}
                                                    </NameText>
                                                    <CustomBtn
                                                        onClick={e => e.preventDefault()}>
                                                        <DescriptionIcon style={{}} onClick={() => {
                                                            handleoverAllShow(user);
                                                        }} />
                                                    </CustomBtn>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </Table>
                                </OverflowOne>
                                <TableWrapperInner2>
                                    <OverflowTwo >
                                        <Table>
                                            <TableHeader style={{ paddingBottom: 0, marginBottom: 1 }}>
                                                {orderedActiveSemData?.map((assignment, i) => {
                                                    return (<span key={i + "assignment"}>
                                                        <TableCell
                                                            style={{ backgroundColor: rowOneColor, color: 'white', height: '30px', fontSize: '10px', fontWeight: 'bold', borderRight: '1px solid white', paddingLeft: 4, paddingRight: 4 }}
                                                            key={assignment?.id + i}
                                                            active={index === i}
                                                            id={assignment?.id}
                                                            onMouseEnter={() => setIndex(i)}
                                                            onMouseLeave={() => setIndex(-1)}
                                                            header="true"
                                                            minWidth={96}
                                                        >
                                                            <Tooltip title={get(assignment,"displayName")}><AssignmentLabelText>
                                                            <BookNameTitle>{get(assignment,"displayName")}</BookNameTitle>
                                                                </AssignmentLabelText>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </span>)
                                                })}
                                            </TableHeader>
                                            <TableHeader style={{ paddingBottom: 0, marginBottom: 1 }}>
                                                {orderedActiveSemData?.map((assignment, i) => {
                                                    const isActive = get(assignment, "active", true)
                                                    return (<span key={i + "assignment"}>
                                                        <Dropdown overlay={booknav(assignment)} disabled={!get(assignment, "id")} placement="bottomRight" trigger={['click']}>
                                                            <TableCell
                                                                style={{ backgroundColor: !isActive ? COLOR.redishpink : rowTwoColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', cursor: 'pointer', justifyContent: "center" }}
                                                                key={assignment?.id}
                                                                active={index === i}
                                                                id={assignment?.id}
                                                                onMouseEnter={() => setIndex(i)}
                                                                onMouseLeave={() => setIndex(-1)}
                                                                header="true"
                                                                minWidth={96}
                                                            >
                                                                <Tooltip title={get(assignment, "displayName", get(assignment, "data.displayName", ""))}>
                                                                    <AssignmentLabelText>{getAssignmentLable(assignment, booksAllList, videos, resources)}</AssignmentLabelText>
                                                                </Tooltip>
                                                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                            </TableCell>
                                                        </Dropdown>
                                                    </span>)
                                                })}
                                            </TableHeader>
                                            <TableHeader style={{}}>
                                                {orderedActiveSemData?.map((assignment, i) => (
                                                    <span key={`${i} assignment`}>
                                                        <TableCell
                                                            style={{ backgroundColor: rowThreeColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', textAlign: "center", justifyContent: "center" }}
                                                            key={`${assignment?.id} - ${i}`}
                                                            active={index === i}
                                                            id={assignment?.id}
                                                            onMouseEnter={() => setIndex(i)}
                                                            onMouseLeave={() => setIndex(-1)}
                                                            minWidth={96}
                                                        >
                                                            {assignment?.dueDate ? <span>{`${moment(assignment?.dueDate).format("MMM DD")}`}</span> : <span></span>}
                                                        </TableCell>
                                                    </span>
                                                ))}
                                            </TableHeader>
                                            <TableHeader style={{}}>
                                                {orderedActiveSemData?.map((assignment, i) => (
                                                    <span key={`${i} assignment`}>
                                                        <TableCell
                                                            style={{ backgroundColor: rowFourColor, color:"#4e5156", height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', textAlign: "center", justifyContent: "center" }}
                                                            key={`${assignment?.id} - ${i}`}
                                                            active={index === i}
                                                            id={assignment?.id}
                                                            onMouseEnter={() => setIndex(i)}
                                                            onMouseLeave={() => setIndex(-1)}
                                                            minWidth={96}
                                                        >
                                                            {get(assignment, "data.questions.length", '-')}
                                                        </TableCell>
                                                    </span>
                                                ))}
                                            </TableHeader>
                                            {listStudentUser.length === 0 ? (
                                                <EmptyState message="Currently 0 students enrolled in this class." />
                                            ) : (
                                                <>
                                                    {listStudentUser.map((user, i) => (
                                                        <TableRow key={user?.id ?? i + "user"} index={i + 1} style={{ borderLeft: '0px' }}>
                                                            {orderedActiveSemData?.map((assignment, j) => {
                                                                const { value, isTbg, isLate, isDiffStudent, manualGrading, completeTbg, hasAssigned } = calculateValue(assignment, user)
                                                                const tempData = getAssignmentCreation(assignment, user)
                                                                const ungraded = get(assignment,"ungraded",false)
                                                                return (
                                                                    <TooltipComp
                                                                        hasToolTip={isTbg}
                                                                        hideVisability={isFlagMenuOpen}
                                                                        title={isTbg ? "This includes open-ended questions." : ""}
                                                                        key={j + "tooltip"} >
                                                                        <TableCellEdit
                                                                            style={getCellStyle(isLate, isDiffStudent, isTbg)}
                                                                            key={assignment?.id ?? (j + "tcell")}
                                                                            active={index === j}
                                                                            onMouseEnter={() => setIndex(j)}
                                                                            onMouseLeave={() => setIndex(-1)}
                                                                            minWidth={96}
                                                                        >
                                                                            <GradeEditor
                                                                                disabled={isEditable() || !hasAssigned || (typeof user?.userclass?.active == "boolean" ? !user?.userclass?.active : false) || !assignment?.data?.id}
                                                                                assignmentId={assignment?.id ?? (j + "assignment")}
                                                                                isReviewAccount={isReviewAccount}
                                                                                data={find(studentSubmissions[user?.id], ['assignmentId', assignment?.data?.id])}
                                                                                reviewAssignment={(v) => { reviewAssignment(v, tempData, listStudentUser, studentSubmissions) }}
                                                                                tempData={tempData}
                                                                                submission={find(studentSubmissions[user.id], ['assignmentId', assignment?.data?.id])}
                                                                                userId={user?.id ?? (j + "userr")}
                                                                                value={hasAssigned ? value.toUpperCase() : "N/A"}
                                                                                scale_1_5={scale_1_5}
                                                                                gradebook_a_f={gradebook_a_f}
                                                                                assignments={assignment}
                                                                                currentClass={currentClass}
                                                                                user={user}
                                                                                assignment={assignment}
                                                                                handleGradeValueShow={handleGradeValueShow}
                                                                                isTbg={isTbg && !completeTbg && manualGrading}
                                                                                ungraded={ungraded}
                                                                                studentSubmissions={studentSubmissions[user.id]}
                                                                                onFlagMenuViewChange={(visiblity) => {
                                                                                    setFlagmenuOpen(visiblity)
                                                                                }}
                                                                            />

                                                                        </TableCellEdit>
                                                                    </TooltipComp>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    ))}
                                                </>
                                            )}
                                        </Table>
                                    </OverflowTwo>
                                </TableWrapperInner2>
                            </TableWrapper></>}
                </div>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
GradeTable.propTypes = {};

//  -- Default props ------------------
GradeTable.defaultProps = {};

export default GradeTable;
