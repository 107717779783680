//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { lighten } from 'polished';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
//  -- Components ---------------------

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------
import ActivityForm from 'features/forms/Activity';
import QuizForm from 'features/forms/Quiz';

//  -- Thunks -------------------------
import { filter, map } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { handleCreateClassActivity, handleListClassActivities, handleUpdateClassActivity } from 'store/classActivities/thunks';
import { handleCreateClassQuiz, handleDeleteEntireBookAssignment, handleListClassQuizzes, handleUpdateClassQuiz } from 'store/classQuizzes/thunks';
import Schoology from 'utils/Schoology';
import { validateQuizeActivityData } from "utils/methods/createQuizeActivityValidator";
import { v4 } from 'uuid';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 4px;
`;

const Row = styled.div`
    align-items: ${p => p.align || 'center'};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;
    width: 100%;

    &:last-child {
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 0px;
    }
`;

const Text = styled.span`
    color: ${COLOR.black500};
    font-size: 11px;
    margin-top: 2px;
    text-align: left;
`;

const TextLarge = styled(Text)`
    color: #3D5F8F;
    font-size: 14px;
    font-weight: 600;
    margin-right: 4px;
`;

const Title = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
`;

const Wrapper = styled.button`
    background-color: ${p=>p.bgColor?p.bgColor:lighten(0.55, '#3D5F8F')};
    border: 2px dashed ${p=>p.borderColor?p.borderColor:lighten(0.3, '#3D5F8F')};
    border-radius: 4px;
    color: #3D5F8F;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    outline: none;
    padding: 12px 12px 10px;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        background-color: ${p=>p.hoverColor?p.hoverColor:lighten(0.5, '#3D5F8F')};
    }
`;
const getExamTitle = (bookid) => {
    const finalAssignmentTitle = "EXAMEN #3 ANÉCDOTAS SERIES"
    const midAssignmentTitle = "EXAMEN #2 ANÉCDOTAS SERIES"
    const entryAssignmentTitle = "EXAMEN DE ENTRADA  ANÉCDOTAS SERIES"
    const titles = {
        "31":entryAssignmentTitle,
        "50":midAssignmentTitle,
        "45":entryAssignmentTitle,
        "51":midAssignmentTitle
    }
   return titles[bookid]?titles[bookid]: "EXAMEN  ANÉCDOTAS SERIES"
}

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Announcement
//
//  -- Description
//  Announcement component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcement data={data} />
//
const FinalExamForms = ({
    assignment,
    listStudents,
    handleChangeMultiSelect,
    diffSection,
    setDiffSection,
    studentsName,
    currentClass,
    currentUser,
    booksAllList,
    activities,
    quizzes,
    onClose=()=>null,
    isEdit,
    classActivities,
    classQuizzes
}) => {
    const dispatch = useDispatch();
    const [assignmentData, setAssignmentData] = useState(assignment);
    const [studentsError, setStudentsError] = React.useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [lateSection,setlateSection] = useState(false);
    const [durationSection,setDurationSection] = useState(false);
    const schoologyAuth = useSelector(state=>state.schoologyAuth)
    const client = new Schoology(schoologyAuth)
    useEffect(()=>{
            setAssignmentData({...assignment,
                editing:isEdit,
                displayName:isEdit?assignment.displayName: getExamTitle(assignment.chapter)
            })
        
    },[assignment,isEdit])

    const createActivity = async (aData) => {
        let studentsSelected = [];
        let actvityDataForCreate ={
            classId: currentClass.id,
            ...aData,
            activityId: aData.id,
            studentsSelected: studentsSelected
        }
        if(!actvityDataForCreate.bookId) actvityDataForCreate.bookId=aData.chapter
        let {isValid,reason}=validateQuizeActivityData(actvityDataForCreate,"activity")
        if(!isValid) {
            console.log(reason)
            return;
        }
        actvityDataForCreate.studentsSelected = studentsName.length>0? createArrayStudents(studentsName):[];
        if(diffSection&&studentsName.length==0){
            setStudentsError(true);
            return;
        }
        await dispatch(handleCreateClassActivity(actvityDataForCreate));
    };

    const createQuiz = async (aData) => {
        let studentsSelected = [];
        let quizDataForCreate= {
            classId: currentClass.id,
            ...aData,
            quizId: aData.id,
            studentsSelected: studentsSelected,
        }
        if(!quizDataForCreate.bookId) quizDataForCreate.bookId=aData.chapter
        let {isValid,reason}=validateQuizeActivityData(quizDataForCreate,"quiz")
        if(!isValid) {
            console.log(reason)
            return;
        }
        quizDataForCreate.studentsSelected =studentsName.length>0? createArrayStudents(studentsName):[];
        if(diffSection&&studentsName.length==0){
            setStudentsError(true);
            return;
        }
        await dispatch(handleCreateClassQuiz(quizDataForCreate));
    };

    
    const createAssignment =async (e) =>{
        e.preventDefault();
        if(isEdit){
            updateAssignment()
            return;
        }
        let arrayToActivities = activities;
        let arrayToQuizzes = quizzes;
        assignmentData.groupId =v4()
        setDataLoading(true);
        if(assignment.typeId=="quiz"){
            await createQuiz(assignmentData);
            arrayToQuizzes = filter(quizzes,a=>a.id!=assignment.id);
          
        }else{
            await createActivity(assignmentData);
            arrayToActivities = filter(activities,a=>a.id!=assignment.id);
        }
        await Promise.all(map(arrayToActivities,async item=>{
            item.dueDate=assignmentData.dueDate;
            item.retakes=assignmentData.retakes;
            item.displayName = assignmentData.displayName
            if(assignmentData.groupId){
                item.groupId=assignmentData.groupId
            }
            if(assignmentData.timeDuration){
                item.timeDuration=assignmentData.timeDuration;
            }
            if(assignmentData.dueDateDif){
                item.dueDateDif=assignmentData.dueDateDif;
            }
            if(assignmentData.scheduleAtDiff){
                item.scheduleAtDiff=assignmentData.scheduleAtDiff;
            }
            if(assignmentData.levelExercise){
                item.levelExercise=assignmentData.levelExercise;
            }
            if(assignmentData.retakesDif){
                item.retakesDif=assignmentData.retakesDif;
            }
            if(assignmentData.weightage){
                item.weightage=assignmentData.weightage;
            }
            if(assignmentData.lateDate){
                item.lateDate=assignmentData.lateDate;
            }
            await createActivity(item);  
        }))
        await Promise.all(map(arrayToQuizzes,async item=>{
        item.dueDate=assignmentData.dueDate;
        item.retakes=assignmentData.retakes;
        item.displayName = assignmentData.displayName
        if(assignmentData.groupId){
            item.groupId=assignmentData.groupId
        }
        if(assignmentData.timeDuration){
            item.timeDuration=assignmentData.timeDuration;
        }
        if(assignmentData.dueDateDif){
            item.dueDateDif=assignmentData.dueDateDif;
        }
        if(assignmentData.scheduleAtDiff){
            item.scheduleAtDiff=assignmentData.scheduleAtDiff;
        }
        if(assignmentData.levelExercise){
            item.levelExercise=assignmentData.levelExercise;
        }
        if(assignmentData.retakesDif){
            item.retakesDif=assignmentData.retakesDif;
        }
        if(assignmentData.weightage){
            item.weightage=assignmentData.weightage;
        }
        if(assignmentData.lateDate){
            item.lateDate=assignmentData.lateDate;
        }
        await createQuiz(item);
       }))
       await dispatch(handleListClassActivities(currentClass.id));
       await dispatch(handleListClassQuizzes(currentClass.id));
       setDataLoading(false);
       onClose();
    }
    const updateAssignment =async() =>{
        const examQuizes = filter(classQuizzes,['data.chapter',assignment.data.chapter])
        const examActivity = filter(classActivities,['data.chapter',assignment.data.chapter])
        await  Promise.all(map(examQuizes,async cq=>{
            let infoDiffSection = {};
        let toCreateStudent = [];
        if(diffSection){
            infoDiffSection = {
                diff: assignmentData.diff,
                retakesDif: assignmentData.retakesDif,
                dueDateDif: assignmentData.dueDateDif,
                levelExercise: assignmentData.levelExercise,
                scheduleAt:  assignmentData.scheduleAtDiff  ||  assignmentData.scheduleAt ,
                lateDate: assignmentData.lateDate,
            }
            const diffstudent = map(assignmentData.diff, item => item.userId);
            const studentArray = createArrayStudents(studentsName);
            toCreateStudent = studentArray.filter(item=>!diffstudent.includes(item.studentId));
        }else{
            infoDiffSection = {
                diff: [],
                retakesDif: "",
                dueDateDif: "",
                levelExercise: ""
            }
        }
        setDataLoading(true);
        await dispatch(handleUpdateClassQuiz(
            cq.id,
            {
                ...assignmentData,
                retakes: assignmentData.retakes && assignmentData.retakes.trim(),
                dueDate: assignmentData.dueDate,
                lateDate: assignmentData.lateDate,
                timeDuration: assignmentData.timeDuration,
                classes : assignmentData.classes,
                weightage : assignmentData.weightage,
                displayName : assignmentData.displayName,
                scheduleAt: assignmentData.scheduleAt,
                classId:assignmentData.classId
            },
            infoDiffSection,
            toCreateStudent,
            assignmentData,
            assignmentData.updateTOAllClass
        ));     
        }))
        await  Promise.all(map(examActivity,async ca=>{
            let  toCreateStudent = [];
            let infoDiffSection = {};
            if(diffSection){
                infoDiffSection = {
                    diff: assignmentData.diff,
                    retakesDif: assignmentData.retakesDif,
                    dueDateDif: assignmentData.dueDateDif,
                    scheduleAt:  assignmentData.scheduleAtDiff  ||  assignmentData.scheduleAt ,
                    lateDate: assignmentData.lateDate,
                }
                const diffstudent = map(assignmentData.diff, item => item.userId);
                const studentArray = createArrayStudents(studentsName);
                toCreateStudent = studentArray.filter(item=>!diffstudent.includes(item.studentId));
            }else{
                infoDiffSection = {
                    diff: [],
                    retakesDif: "",
                    dueDateDif: ""
                }
            }
            let updateActivityData={
                ...assignmentData,
                retakes: assignmentData.retakes && assignmentData.retakes.trim(),
                dueDate: assignmentData.dueDate,
                lateDate: assignmentData.lateDate,
                timeDuration: assignmentData.timeDuration,
                classes : assignmentData.classes,
                weightage : assignmentData.weightage,
                displayName : assignmentData.displayName,
                classId:assignmentData.classId
            }
            await dispatch(handleUpdateClassActivity(
                ca?.id,
                updateActivityData,
                infoDiffSection,
                toCreateStudent,
                assignmentData,
                assignmentData.updateTOAllClass
            ));      
        }))
        await dispatch(handleListClassActivities(currentClass.id));
        await dispatch(handleListClassQuizzes(currentClass.id));
       onClose()
    }

 
    const updateAssignmentData = (id, value) => {
        setAssignmentData({
            ...assignmentData,
            [id]: value
        });
    };
    function createArrayStudents(studentsName){
        let arrayStudents = [];
        for(var s in studentsName){
            let finded = listStudents.find(e => e.description == studentsName[s]);
            if(finded){
                arrayStudents.push({
                    studentId: finded.id,
                    description: finded.description
                })
            } 
        }
        return arrayStudents;
    }
    const deleteAssignment =async (onDeleteSuccess) =>{
        const examQuizes = filter(classQuizzes,['chapter',assignment.data.bookId])
        const examActivity = filter(classActivities,['chapter',assignment.data.bookId])
        await client.setToken(schoologyAuth);
        await handleDeleteEntireBookAssignment(assignment.data.bookId,currentClass.id)
        await dispatch(handleListClassActivities(currentClass.id));
        await dispatch(handleListClassQuizzes(currentClass.id));
        await  Promise.all(map(examQuizes,async cq=>{
            if(cq&&cq.sAssignmentId&&currentClass.sectionId){
                await client.deleteAssignment(currentClass.sectionId,cq.sAssignmentId)
            }
        }))
        await  Promise.all(map(examActivity,async ca=>{
        if(ca&&ca.sAssignmentId&&currentClass.sectionId){
            await client.deleteAssignment(currentClass.sectionId,ca.sAssignmentId)
        }
        }))
        console.log(assignment,"assignmentassignment")
        onDeleteSuccess() 
        onClose()
    }
    return (
        <>
           {(assignment?.typeId=="quiz"||assignment?.quizId)?
                    <QuizForm
                        data={assignmentData}
                        handleChange={e => updateAssignmentData(e.target.id, e.target.value)}
                        handleChangeLevel={e => updateAssignmentData('levelExercise', e.target.value)}
                        handleSubmit={createAssignment}
                        listStudents={listStudents}
                        handleChangeMultiSelect={handleChangeMultiSelect}
                        diffSection={diffSection}
                        setDiffSection={setDiffSection}
                        studentsName={studentsName}
                        studentsError={studentsError}
                        lateSection={lateSection}
                        setlateSection={setlateSection}
                        setDurationSection={setDurationSection}
                        durationSection={durationSection}
                        loading={dataLoading}
                        onClose={()=>{}}
                        booksAllList={booksAllList}
                        allAssignOnce
                        onDelete={deleteAssignment}
                    />:
                    <ActivityForm
                        data={assignmentData}
                        handleChange={e => updateAssignmentData(e.target.id, e.target.value)}
                        handleSubmit={createAssignment}
                        listStudents={listStudents}
                        handleChangeMultiSelect={handleChangeMultiSelect}
                        diffSection={diffSection}
                        setDiffSection={setDiffSection}
                        studentsName={studentsName}
                        studentsError={studentsError}
                        setDurationSection={setDurationSection}
                        durationSection={durationSection}
                        lateSection={lateSection}
                        setlateSection={setlateSection}
                        onClose={()=>{}}
                        booksAllList={booksAllList}
                        allAssignOnce
                        onDelete={deleteAssignment}
                    /> }
        </>
    );
};

//  -- Prop types ---------------------
FinalExamForms.propTypes = {};

//  -- Default props ------------------
FinalExamForms.defaultProps = {
    fontSize: 10
};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    booksAllList: state.booksAllList,
    currentUser: state.currentUser,
});

export default connect(mapStateToProps, null)(FinalExamForms);
