//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { ref as fireRef, getDownloadURL, uploadBytes } from 'firebase/storage';
import { storage } from 'firebaseConfig';
import moment from 'moment';
import { lighten } from 'polished';
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { v4 } from 'uuid';



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Container = styled.div`
    padding: 16px;
    overflow: hidden;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0px;
    }
`;

const Wrapper = styled.div`
    border-bottom: 1px dashed ${COLOR.black700};
    margin-bottom: 8px;
    padding: 0px 0px 24px;
    width: 100%;
`;

const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Interaction
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...

/**
 *   handleAudio,
    maxTime,
    message,
    refresh,
    storageLoc,
    isPreview=false,
    source,
    wrapperStyle={},
    hasSaveButton
 */
const AudioRecorderParent = React.forwardRef(({
    handleAudio,
    maxTime,
    message,
    refresh,
    storageLoc,
    isPreview = false,
    source,
    wrapperStyle = {},
    hasSaveButton,
    doneButton
}, ref) => {
    const [AllowAudio, setAllowAudio] = useState(false);
    const [ErrorAllowAudio, setErrorAllowAudio] = useState(false);
    const [audio, setAudio] = useState(null);
    const [counter, setCounter] = useState(null);
    const [recording, setRecording] = useState(false);
    const [noAccess, setNoAccess] = useState(false);
    const [noPermission, setNoPermission] = useState(false);
    const [inUrlProcess, setInUrlProcess] = useState(false);
    const [pause, setPause] = useState(false);
    const timmerRef = useRef()
    useEffect(() => {
        if (source && source.length > 0) {
            setInUrlProcess(true)
            setAudio(source)
            setInUrlProcess(false)
            setRecording(false)
        } else {
            setAudio(source)
            setInUrlProcess(false)
            setRecording(false)
        }
    }, [source])
    const uploadToStorage = async (blob) => {
        if (blob) {
            const location = storageLoc || '/audio/activity-submissions';
            const id = `${moment().format('YYYY-MM-DD')}-${v4()}`;
            const audioRef = fireRef(storage, `${location}/${id}`);
            try {
                await uploadBytes(audioRef, blob);
                const url = await getDownloadURL(audioRef);
                setData(url);
            } catch (error) {
                console.error("Upload error:", error);
            }
        } else {
            setInUrlProcess(false);
        }
    };

    useImperativeHandle(ref, () => ({
        pause: onPause,
        resume: onResume,
        stop: stopRecord,
        reset: resetRecording,
        start: startRecord,
        setAudio: setAudio
    }));

    useEffect(() => {
        try {
            if (isPreview) {
                setNoAccess(false)
                setNoPermission(false)
                return;
            }
            const isFirefox = navigator.userAgent.indexOf("Firefox") > -1;
            if (navigator.permissions && !isFirefox) {
                navigator.permissions.query(
                    { name: 'microphone' }
                ).then(function (permissionStatus) {
                    if (permissionStatus.state === 'denied') {
                        setNoAccess(false)
                        setNoPermission(true)
                    }
                    permissionStatus.onchange = function () {
                        if (this.state === 'denied') {
                            setNoAccess(false)
                            setNoPermission(true)
                        } else {
                            setNoAccess(false)
                            setNoPermission(false)
                        }
                    }
                })
            }
            if (navigator?.mediaDevices && !isFirefox) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then((stream) => {
                        stream.getTracks().forEach(_tr => _tr.stop())
                        setNoAccess(false)
                        setNoPermission(false)
                    })
                    .catch(() => {
                        setNoAccess(true)
                        setNoPermission(false)
                    });
            }
        } catch (error) {
            setNoPermission(true)
        }
    }, []);

    useEffect(() => {
        timmerRef.current = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

        if (counter === 1) {
            stopRecord();
        }

        return () => clearInterval(timmerRef.current);
    }, [counter])


    const onStop = (data) => {
        clearInterval(timmerRef.current);
        uploadToStorage(data.blob);
    };

    const startRecord = () => {
        if (!recording) {
            setAllowAudio(true);
            const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            permissions.then((stream) => {
                setAllowAudio(false);
                setRecording(true);
                setCounter(maxTime);
            })
                .catch((err) => {
                    setAllowAudio(false);
                    setErrorAllowAudio(true);
                    console.log(`${err.name} : ${err.message}`)
                });
        }
    };

    const stopRecord = async () => {
        setInUrlProcess(true);
        setRecording(false);
        clearInterval(timmerRef.current);
    };

    const resetRecording = () => {
        setCounter(null);
        setAudio(null);
        timmerRef.current = null;
        setRecording(false)
    };

    const setData = (url) => {
        setInUrlProcess(false);
        setAudio(url);
        handleAudio(url);
    }

    const onPause = () => {
        if (recording) {
            setPause(true);
            clearInterval(timmerRef.current);
        }
    }

    const onResume = () => {
        if (recording) {
            setPause(false);
            setCounter(counter - 1);
        }
    }
    const [actualSampleRate, setActualSampleRate] = useState(null)
    useEffect(() => {
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        setActualSampleRate(audioCtx.sampleRate)
    }, [])

    return (
        <Wrapper style={wrapperStyle}>
            <Container>
                {(!recording && audio) && (
                    <>
                        <Description style={{ marginBottom: 10 }}>
                            Recorded student feedback:
                        </Description>
                        <Row>
                            <audio controls controlsList="nodownload" src={audio} style={{ height: '40px' }}></audio>
                        </Row>
                    </>
                )}
            </Container>
        </Wrapper>
    )
});

//  -- Prop types ---------------------
AudioRecorderParent.propTypes = {};

//  -- Default props ------------------
AudioRecorderParent.defaultProps = {};

export default AudioRecorderParent;
