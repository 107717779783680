import { EnterOutlined } from '@ant-design/icons';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  makeStyles,
  Chip,
  Typography
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import {
  Add,
  CheckCircle,
  Close,
  Delete,
  MenuBook
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { Col, Drawer, Input as InputArea, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AssignmentForm from "components/AdminBooks/AssignmentForm";
import Assignment from "components/AdminBooks/Assignments";
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import { DeleteModal } from "components/Dialog";
import Loading from "components/Loading";
import { filter, get, map, sortBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useCurrentClass } from "store/classes/selectors";
import { useCurrentUser } from "store/users/selectors";
import { useVocabulary } from "store/vocabulary/selectors";
import { handleCreateVocabulary, handleCreateVocabularyAI, handleDeleteVocabulary, setDataVocabular } from "store/vocabulary/thunks";
import styled from "styled-components";
import COLOR from "utils/constants/color";
import { HyperLinkForm, IntrectionModal, SubTopicUnits, TipsModal } from "./PageToolbarModals/index";
import { Autocomplete } from '@material-ui/lab';

const TipsType = ['transcription', 'description', 'talented', 'special']

const Wrapper = styled.div`
  height: 100%;
  min-height: 600px;
  width: 100%;

  position: relative;
`;

const Toolbar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${COLOR.black600};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 4px;
  width: 100%;
  min-height: 30px;
  margin-top: 30px;
`;

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`

const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px; 
    @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;
const ModalContents = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
`;
const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const Page = styled.div`
  align-items: center;
  color: ${COLOR.black100};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
`;

const Input = styled.input`
  background-color: transparent;
  border: 1px dashed gray;
  border-radius: 3px;
  box-shadow: none;
  font-size: inherit;
  font-weight: 600;
  outline: none;
  padding: 0px 2px;
  transition: all 300ms ease;
  width: 80px;
`;
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;
const UnitsArea = styled.div`
  margin-top: 16px;
`;
const UnitsSubArea = styled.div`
  margin-top: 16px;
`;
const AssignmemntArea = styled.div`
  margin-top: 16px;
`;

const ModalRowDelete = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`;
const ModalContentDelete = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const TextAreaAuto = styled(TextareaAutosize)`
    height: 200px;
    width: 100%;
    border-radius: 2px;
    padding: 5px;
    overflow: hidden;
    border-color: rgb(0 0 0 / 32%);
    ::focus-visible {
      outline: ${COLOR.green100}auto 1px !importent;
     }
    ::focus{
      border-color: ${COLOR.green100} !importent;
  }
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    // margin-left:5px;
    // margin-top:5px;
`;
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AssignmentPlanModal = ({ planData, booksAllList, close, pageId, onRemoveAssignmentPlan, updatePlan, type }) => {
  const [plan, setPlan] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const classes = useStyles();
  useEffect(() => {
    if (planData) {
      setPlan(planData)
    } else {
      setPlan({
        page: pageId,
        from: '',
        to: '',
        resourceId: ''
      })
    }
  }, [planData])
  function validateFromTo(fromValue, toValue) {
    const difference = Math.abs(toValue - fromValue);
    return difference <= 4;
}
  const onUpdate = () => {
    if (type === 'Super Lesson' || type === 'Differentiation') {
      if (!validateFromTo(plan.from, plan.to)) {
        setError(true);
        setErrorMsg("AI can't generate more than 5 Images.")
      }else if (!(plan.from >= 0 && plan.to >= 0)) {
        setError(true);
        setErrorMsg("All field are required.")
      } else {
        updatePlan(plan)
      }
    } else {
      if (!(plan.from >= 0 && plan.resourceId && plan.to >= 0)) {
        setError(true)
        setErrorMsg("All field are required.")
      } else {
        updatePlan(plan)
      }
    }
  }

  const disableBtn = () => {
    if (type === 'Super Lesson' || type === 'Differentiation') {
      return !Boolean(plan) || !(plan.from && plan.from >= 0 && plan.to && plan.to >= 0)
    } else {
      return !Boolean(plan) || !(plan.from >= 0 && plan.resourceId && plan.to >= 0)
    }
  }

  return (<ModalContent>
    <DeleteModal deleteToggle={deleteModal} onDelete={() => {
      onRemoveAssignmentPlan(pageId)
      setDeleteModal(false)
    }}
      setDeleteToggle={setDeleteModal}
      message={`Are you sure to remove ${type} plan on pageId ${pageId}`} />
    <Row style={{ justifyContent: "space-between" }}>
      <TitleText>{type}</TitleText>
      <IconButton
        onClick={close}
        style={{ padding: 0 }}
      >
        <Close color="secondary" />
      </IconButton>
    </Row>
    <ModalConfigArea>
      <LinkArea>
        <Row
          gutter={[24, 24]}
          style={{ justifyContent: "flex-end", display: "flex" }}
        >
          {planData ? <IconButton
            onClick={() => setDeleteModal(true)}
            style={{ padding: 0, marginRight: 10 }}
          >
            <Delete color="secondary" />
          </IconButton> : null}
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: 10 }}>
          <Col span={24}>
            <TextField
              id="pageId"
              name="pageId"
              label="pageId"
              disabled
              InputLabelProps={{ shrink: true, required: true }}
              type="number"
              variant="outlined"
              onChange={(e) => {

              }}
              value={pageId}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
          <Col span={24}>
            <TextField
              id="from"
              name="from"
              label="From pageId"
              InputLabelProps={{ shrink: true, required: true }}
              type="number"
              variant="outlined"
              onChange={(e) => {
                setError(false)
                setPlan({
                  ...plan,
                  from: e.target.value,
                });
              }}
              value={get(plan, "from")}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
          <Col span={24}>
            <TextField
              id="to"
              name="to"
              label="To PageId"
              InputLabelProps={{ shrink: true, required: true }}
              type="number"
              variant="outlined"
              onChange={(e) => {
                setError(false)
                setPlan({
                  ...plan,
                  to: e.target.value,
                });
              }}
              value={get(plan, "to")}
            />
          </Col>
        </Row>
        {type !== "Super Lesson" && type !== "Differentiation" && (
          <Row
            gutter={[24, 24]}
            style={{ marginTop: 20, marginRight: 0, marginLeft: 0 }}
            className="floatingLabelBG"
          >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="bookId">Book</InputLabel>
              <Select
                labelId="bookId"
                id="bookId"
                value={`${get(plan, "resourceId")}`}
                onChange={(e) => {
                  setError(false)
                  setPlan({
                    ...plan,
                    resourceId: e.target.value,
                  });
                }}
              // label="Lession Plan book"
              >
                {map(booksAllList, (bk) => (
                  <MenuItem key={bk.id} value={bk.id}>{`${bk.description || ""
                    }(${bk.id || ""})`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
        )}
      </LinkArea>
      {error ? (
        <FormHelperText error>
          {errorMsg}
        </FormHelperText>
      ) : null}
      <Row
        gutter={[24, 24]}
        style={{ marginTop: 20, marginRight: 0, marginLeft: 0 }}
      >
        <Button
          fullWidth
          disabled={disableBtn()}
          onClick={onUpdate}
          color="primary"
          variant="contained"
        >
          Update
        </Button>
      </Row>
    </ModalConfigArea>
  </ModalContent>)
}

const AnswerPageModal = ({ onCloseDrawer, pageId, onRemove, data, onChangeDesc, onChangeImage, title }) => {
  const [deleteModal, setDeleteModal] = useState(false)
  return (<>
    <DeleteModal deleteToggle={deleteModal} onDelete={() => {
      onCloseDrawer();
      onRemove(data);
    }}
      setDeleteToggle={setDeleteModal}
      message={`Are you sure to remove  ${title} on pageId ${pageId}`} />
    <ModalContent style={{ width: "60vw" }}>
      <Row style={{ justifyContent: "space-between" }}>
        <TitleText>{title}</TitleText>
        {data ? <IconButton
          onClick={() => {
            setDeleteModal(true)
          }}
          style={{ padding: 0 }}
        >
          <Delete color="secondary" />
        </IconButton> : null}
        <IconButton
          onClick={() => onCloseDrawer()}
          style={{ padding: 0, marginRight: 10 }}
        >
          <Close color="secondary" />
        </IconButton>
      </Row>
      <ModalConfigArea>
        <PageImagePicker
          src={data ? data.resource : null}
          onChange={onChangeImage}
          pageId={pageId}
          description={get(data, "description", "")}
          onChangeDescription={onChangeDesc}
          hasDescription
        />
      </ModalConfigArea>
    </ModalContent>
  </>)
}

const PageDescriptionAndTags = ({ pgDescription , pgTags , onChangeOrUpdateDescription , pageId }) => {
  const [openModal, setOpenModal] = useState(false)
  const [description, setDescription] = useState('')
  const [pageTags, setPageTags] = useState('')
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    setDescription(pgDescription)
    setPageTags(pgTags)
  }, [pgDescription, pgTags])
  const onUpdate = async() => {
    if(onChangeOrUpdateDescription){
      setLoader(true)
      await onChangeOrUpdateDescription({
        description,
        tags: pageTags,
        pageId
      })
      setLoader(false)
      setOpenModal(false)
    }
   }
  return (<>
    <Button
      size="small"
      variant="contained"
      color="primary"
      style={{ margin: 2 }}
      onClick={() => setOpenModal(true)}
    startIcon={ pgDescription && pgTags && <CheckCircle />}
    >
      Description and Tags
    </Button>
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      style={{ height: "100%", width: "100%" }}
    >
      <ModalContent>
        <Row style={{ justifyContent: "space-between" }}>
          <TitleText>Description and Tags</TitleText>
          <IconButton
            onClick={()=>setOpenModal(false)}
            style={{ padding: 0 }}
          >
            <Close color="secondary" />
          </IconButton>
        </Row>
        <ModalConfigArea>
          <LinkArea>
            <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
              <Col span={24}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  InputLabelProps={{ shrink: true, required: true }}
                  variant="outlined"
                  onChange={(e) => {
                    setDescription(e.target.value.trimStart());
                  }}
                  value={description}
                />
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
              <Col span={24}>
                <Autocomplete
                  multiple
                  freeSolo
                  id="tags-outlined"
                  options={[]}
                  value={pageTags && pageTags.length > 0 ? pageTags.split(",") : []}
                  style={{ width: "100%" }}
                  onChange={(event, value) => {
                    const filteredTags = value.map(tag => tag.trim()).filter(tag => {
                      const t_txt = tag.trim()
                      return t_txt.length > 0
                    });
                    setPageTags(filteredTags.join(","))
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Tags"
                      placeholder="Tags"
                    />
                  )}

                />
                <Typography variant="subtitle2" style={{ fontWeight:"lighter" , fontSize:"12px "}} >Note: press enter to add tags</Typography>
              </Col>

            </Row>
          </LinkArea>
          <Row
            gutter={[24, 24]}
            style={{ marginTop: 20, marginRight: 0, marginLeft: 0 }}
          >
            <Button
              fullWidth
              onClick={onUpdate}
              color="primary"
              variant="contained"
            >
              Update
            </Button>
          </Row>
        </ModalConfigArea>
      </ModalContent>
    </Modal>
  </>)
}

export default function PageToolbar({
  pageId,
  numberMask,
  onMaskChange,
  hyperlinks,
  teacherPage,
  teacherEdition,
  onChangeHyperlink,
  onChangeAnswerPage,
  onChangeAnswerDesc,
  onCHangeTeacherEdition,
  onCHangeTeacherEditionDesc,
  onRemoveAnsPage,
  onRemoveTeacherEdition,
  pgLessonPlan,
  pgSuperLesson,
  pgDifferentiation,
  pgAssignmentPlan,
  pgWorkbookPlan,
  pgRubricsPlan,
  onChangeRunricsPlan,
  onRemoveRubricsPlan,
  booksAllList,
  onChangeAssignmentPlan,
  onChangeLessionPlan,
  onChangeSuperLesson,
  onChangeDifferentiationLesson,
  onRemoveLessionPlan,
  onRemoveSuperLesson,
  onRemoveDifferentiationLesson,
  onRemoveAssignmentPlan,
  subTopics,
  pageActivities,
  pageQuizzes,
  onPageIdChange,
  page,
  bookId,
  onUpdateSubTopics,
  pageTips,
  pageIntrections,
  bookIdVoc,
  onChangeWorkbookPlan,
  onRemoveWorkbookPlan,
  onChangeMainBook,
  onRemoveMainbookPlan,
  pgMainbook,
  pgDescription,
  pgTags,
  onChangeOrUpdateDescription
}) {
  const dispatch = useDispatch()
  const currentClass = useCurrentClass()
  const bookVocabulary = useVocabulary()
  const currentUser = useCurrentUser()
  const [hyperLinkDrawer, setHyperlinkDrawer] = useState(false);
  const [assignmentPlanDrawer, setAssignmentPlanDrawer] = useState(false);
  const [workbookPlanDrawer, setWorkbookPlanDrawer] = useState(false);
  const [mainbookPlanDrawer, setmainbookPlanDrawer] = useState(false);
  const [lessionPlanDrawer, setLessionPlanDrawer] = useState(false);
  const [superLessionDrawer, setSuperLessionDrawer] = useState(false);
  const [differentiationLessonDrawer, setDifferentiationLessonDrawer] = useState(false);
  const [rubricsPlanDrawer, setRubricsPlanDrawer] = useState(false);
  const [answerPageDrawer, setAnswerpageDrawer] = useState(false);
  const [teacherEditionPageDrawer, setTeacherEditionDrawer] = useState(false);
  const [activityDrawer, setActivityDrawer] = useState(false);
  const [quizDrawer, setQuizDrawer] = useState(false);
  const [unitsDrawer, setUnitsDrawer] = useState(false);
  const [pageid, setpageid] = useState(pageId);
  const [tipModal, setTipModal] = useState(false)
  const [intrectionModal, setintrectionModal] = useState(false)
  const [openVocabulary, setVocabulary] = useState(false);
  const [vocabularyPage, setVocabularyPage] = useState(true)
  const [vocabularyMy, setVocabularyMy] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteVocab, setDeleteVocab] = useState({});
  const [showAdd, setShowAdd] = useState(false)
  const [addVocabData, setAddVocabData] = useState({});
  const [datas, setDatas] = useState({});
  const [deleteVoc, setDeleteVoc] = useState(false);
  const [isEdited, setIsEdited] = useState(-1);
  const [getBooksVocab, setGetBooksVocab] = useState({});
  const [vocabularyAI, setVocabularyAI] = useState();
  const [showWordLoader, setShowWordLoader] = useState(false)
  const [newItem, setNewItem] = useState({ isEditable: true });

  useEffect(() => {
    const filteredVocab = filter(bookVocabulary, obj => obj.chapter == bookId && obj.page == pageId);
    // const sorted_obj = sortBy(filteredVocab, (o) => {
    //   if (o.created_by === 'teacher') {
    //     return 0;
    //   } else {
    //     return 1;
    //   }
    // });
    setGetBooksVocab(filteredVocab)
    // onFilterVocabulary();
  }, [bookVocabulary, pageId, bookId])

  const onFilterVocabulary = () => {
    let sorted_obj = sortBy(bookVocabulary,
      [
        function (o) {
          // Ensuring the property exists and is a string
          if (o.data && typeof o.data.word === 'string') {
            // Converting to lowercase for case-insensitive comparison
            return o.data.word.toLowerCase();
          }
          return ''; // Default value in case the property doesn't exist
        }
      ]);
    return sorted_obj;
  }


  useEffect(() => {
    setTimeout(() => {
      if (addVocabData.word == undefined) {
      } else {
        addVocabulary()
      }
    }, 2000)

  }, [vocabularyAI])
  const inputRef = useRef(null);

  const [alertSakeBar, setAlertSakeBar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const classes = useStyles();
  const unitScrollRef = useRef();
  const onCloseDrawer = () => {
    setHyperlinkDrawer(false);
    setAnswerpageDrawer(false);
    setTeacherEditionDrawer(false);
    setLessionPlanDrawer(false);
    setSuperLessionDrawer(false);
    setDifferentiationLessonDrawer(false);
    setAssignmentPlanDrawer(false);
    setWorkbookPlanDrawer(false);
    setmainbookPlanDrawer(false)
    setRubricsPlanDrawer(false)
    setUnitsDrawer(false);
  };
  useEffect(() => {
    setpageid(pageId);
  }, [pageId]);

  const closeAlert = () => {
    setAlertSakeBar({ open: false, message: "", severity: "error" });
  };
  const onCreateOrUpdate = () => {
    setActivityDrawer(false);
    setQuizDrawer(false);
  };
  const teacherEditionImageUpload = (item) => {
    if (item.url && item.url.includes("https://firebasestorage")) {
      onCHangeTeacherEdition(item);
      onCloseDrawer();
    }
  };
  const teacherAnswerPageUpload = (item) => {
    if (item.url && item.url.includes("https://firebasestorage")) {
      onChangeAnswerPage(item);
      onCloseDrawer();
    }
  };
  const handleVocabulary = () => {
    setVocabulary(true)
  }
  const handleVocabularyClose = () => {
    setVocabulary(false)
    setVocabularyAI({})
    const dataToSend = getBooksVocab.filter(item => !item.isEditable);
    setGetBooksVocab(dataToSend)
    dispatch(setDataVocabular(onFilterVocabulary()))
  }

  const handleVocabularyPage = () => {
    setVocabularyPage(true)
    setVocabularyMy(false)
  }
  const handleVocabularyMy = () => {
    setVocabularyPage(false)
    setVocabularyMy(true)
  }
  const onDelete = () => {
    console.log('delete is here');
  }

  const onDeleteVocab = async () => {
    await dispatch(handleDeleteVocabulary(deleteVocab?.id));
    setDeleteModal(false);
    setDeleteVoc(false);
  }

  const handleAddVocData = (key, value) => {
    if (key == 'word') {
      const v = addVocabData;
      addVocabData[key] = value;
      setAddVocabData(v);
    } else {
      const v = vocabularyAI;
      vocabularyAI[key] = value;
      setVocabularyAI(v);
    }
  }

  const addVocabulary = (vocabData) => {
    const dataToAdd = vocabData ? vocabData : vocabularyAI
    const dataVocab = {
      book_id: bookId,
      page: pageId,
      chapter: bookId,
      created_by: currentUser?.role,
      user_id: get(currentUser, 'id', ''),

      data: {
        word: addVocabData.word,
        ...dataToAdd
      },
    };
    dispatch(handleCreateVocabulary(dataVocab));
    setAddVocabData({})
    setVocabularyAI({})
    setShowAdd(false);
    setAlertSakeBar({
      open: true,
      message: "Created Succesfully",
      severity: "success",
    })
  }

  const handleVocData = (key, value) => {
    const v = datas;
    datas[key] = value;
    setDatas(v);
  }

  // const handleUpdateData = async (data) => {
  //   const dataVocab = {
  //     book_id: bookId,
  //     page: pageId,
  //     chapter: bookId,
  //     created_by: currentUser?.role,
  //     user_id: get(currentUser, 'id', ''),
  //     class_id: get(currentClass, 'id', ''),
  //     data: {
  //       ...datas
  //     },
  //     id: data
  //   }
  //   dispatch(handleCreateVocabulary(dataVocab));

  // }
  const handleUpdateData = async (data) => {
    let datas = getBooksVocab.find(item => item.id === data?.id);
    await dispatch(handleCreateVocabulary(datas));
    setAlertSakeBar({
      open: true,
      message: "Updated Succesfully",
      severity: "success",
    })
  }

  function editDataProperty(targetId, newValue, propertyName) {
    const itemToEdit = getBooksVocab.find(item => item.id === targetId);
    const updatedData = getBooksVocab.map((item) =>
      item.id === targetId
        ? {
          ...item,
          data: {
            ...item.data,
            [propertyName]: newValue,
          },
        }
        : item
    );
    if (itemToEdit) {
      itemToEdit.data[propertyName] = newValue;
    }
    setGetBooksVocab(updatedData)
  }

  const callVocAI = async () => {
    if (addVocabData?.word) {
      setShowWordLoader(true);
      const result = await dispatch(handleCreateVocabularyAI({
        topic: addVocabData?.word,
        book_id: bookId,
        page: pageId,
        chapter: bookId,
        created_by: currentUser?.role,
        user_id: get(currentUser, 'id', ''),
        class_id: get(currentClass, 'id', '')
      }));

      if (result) {
        // const convertedData = {
        //   definition: result.definition,
        //   english_translation: result.translation,
        //   synonyms: result.synonyms,
        //   sentence: result.exampleSentence
        // };
        // addVocabulary(convertedData)
        setAddVocabData({})
        setVocabularyAI({})
        setShowAdd(false);
        setAlertSakeBar({
          open: true,
          message: "Created Succesfully",
          severity: "success",
        })
        // setVocabularyAI(convertedData);
      } else {
        setAlertSakeBar({
          open: true,
          message: "No Result Found",
          severity: "error",
        })
      }
      setShowWordLoader(false);
    }
  }

  const addNewItem = () => {
    const isEditableItemExists = getBooksVocab.some(item => item.isEditable);
    if (!isEditableItemExists) {
      const updatedData = [...getBooksVocab, newItem];
      setGetBooksVocab(updatedData);
    }
  };

  const ActionText = styled.p`
    font-size:14px;
    color:white;
    font-weight:500;
  `;
  return (
    <Wrapper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertSakeBar.open}
        autoHideDuration={3000}
        onClose={closeAlert}
      >
        <Alert
          onClose={() => {
            setAlertSakeBar({ open: false, message: "", severity: "error" });
          }}
          severity={alertSakeBar.severity}
        >
          <strong>ALERT:</strong> {alertSakeBar.message}
        </Alert>
      </Snackbar>

      {/* Tips modal  */}

      <TipsModal
        data={pageTips}
        pageId={pageId}
        bookId={bookId}
        open={tipModal}
        close={() => setTipModal(false)}
        setAlertSakeBar={setAlertSakeBar}
      />
      <IntrectionModal
        data={pageIntrections}
        pageId={pageId}
        bookId={bookId}
        open={intrectionModal}
        close={() => setintrectionModal(false)}
        setAlertSakeBar={setAlertSakeBar}
      />

      {/* assignment plan */}
      <Modal
        open={assignmentPlanDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <AssignmentPlanModal
          planData={pgAssignmentPlan}
          onRemoveAssignmentPlan={id => {
            onRemoveAssignmentPlan(id)
            onCloseDrawer()
          }}
          pageId={pageId}
          booksAllList={booksAllList}
          close={() => onCloseDrawer()}
          updatePlan={data => {
            onChangeAssignmentPlan(data)
            onCloseDrawer()
          }}
          type={"Quiz"}
        />
      </Modal>
      {/* workbook plan */}
      <Modal
        open={workbookPlanDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <AssignmentPlanModal
          planData={pgWorkbookPlan}
          onRemoveAssignmentPlan={id => {
            onRemoveWorkbookPlan(id)
            onCloseDrawer()
          }}
          pageId={pageId}
          booksAllList={booksAllList}
          close={() => onCloseDrawer()}
          updatePlan={data => {
            onChangeWorkbookPlan(data)
            onCloseDrawer()
          }}
          type={"workbook"}
        />
      </Modal>

      {/* main book  */}
      <Modal
        open={mainbookPlanDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <AssignmentPlanModal
          planData={pgMainbook}
          onRemoveAssignmentPlan={id => {
            onRemoveMainbookPlan(id)
            onCloseDrawer()
          }}
          pageId={pageId}
          booksAllList={booksAllList}
          close={() => onCloseDrawer()}
          updatePlan={data => {
            onChangeMainBook(data)
            onCloseDrawer()
          }}
          type={"Main book"}
        />
      </Modal>
      {/* lession plan */}
      <Modal
        open={lessionPlanDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <AssignmentPlanModal
          planData={pgLessonPlan}
          onRemoveAssignmentPlan={id => {
            onRemoveLessionPlan(id)
            onCloseDrawer()
          }}
          pageId={pageId}
          booksAllList={booksAllList}
          close={() => onCloseDrawer()}
          updatePlan={data => {
            onChangeLessionPlan(data)
            onCloseDrawer()
          }}
          type={"Lesson"}
        />
      </Modal>

      {/* Super Lesson */}
      <Modal
        open={superLessionDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <AssignmentPlanModal
          planData={pgSuperLesson}
          onRemoveAssignmentPlan={id => {
            onRemoveSuperLesson(id)
            onCloseDrawer()
          }}
          pageId={pageId}
          booksAllList={booksAllList}
          close={() => onCloseDrawer()}
          updatePlan={data => {
            onChangeSuperLesson(data)
            onCloseDrawer()
          }}
          type={"Super Lesson"}
        />
      </Modal>

      {/* differentiation Lesson */}
      <Modal
        open={differentiationLessonDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <AssignmentPlanModal
          planData={pgDifferentiation}
          onRemoveAssignmentPlan={id => {
            onRemoveDifferentiationLesson(id)
            onCloseDrawer()
          }}
          pageId={pageId}
          booksAllList={booksAllList}
          close={() => onCloseDrawer()}
          updatePlan={data => {
            onChangeDifferentiationLesson(data)
            onCloseDrawer()
          }}
          type={"Differentiation"}
        />
      </Modal>

      {/* Rubrics plan */}
      <Modal
        open={rubricsPlanDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <AssignmentPlanModal
          planData={pgRubricsPlan}
          onRemoveAssignmentPlan={id => {
            onRemoveRubricsPlan(id)
            onCloseDrawer()
          }}
          pageId={pageId}
          booksAllList={booksAllList}
          close={() => onCloseDrawer()}
          updatePlan={data => {
            onChangeRunricsPlan(data)
            onCloseDrawer()
          }}
          type={"Rubrics"}
        />
      </Modal>

      {/* rubrics plan end */}
      {/*-- units plan--- */}
      <Modal
        open={unitsDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <SubTopicUnits
          data={subTopics}
          onCloseDrawer={onCloseDrawer}
          onUpdateUnits={onUpdateSubTopics}
        />
      </Modal>


      {/* hyper link config */}
      <Modal
        open={hyperLinkDrawer}
        onClose={() => onCloseDrawer()}
        style={{ height: "100%", width: "100%" }}
      >
        <ModalContent>
          <Row style={{ justifyContent: "space-between" }}>
            <TitleText>HyperLinks</TitleText>
            <IconButton
              onClick={() => onCloseDrawer()}
              style={{ padding: 0 }}
            >
              <Close color="secondary" />
            </IconButton>
          </Row>

          <HyperLinkForm
            hyperlinks={hyperlinks}
            pageId={pageId}
            onChangeHyperlink={(data) => {
              onCloseDrawer();
              onChangeHyperlink(data);
            }}
          />
        </ModalContent>
      </Modal>


      {/* answer page drawer */}
      <Modal
        open={answerPageDrawer}
        onClose={() => { }}
        style={{ height: "100%", width: "100%" }}
      >
        <AnswerPageModal
          data={teacherPage}
          onChangeDesc={onChangeAnswerDesc}
          onChangeImage={teacherAnswerPageUpload}
          onCloseDrawer={onCloseDrawer}
          title={`Answer Page`}
          pageId={pageId}
          onRemove={onRemoveAnsPage}
        />
      </Modal>


      <Modal
        open={teacherEditionPageDrawer}
        onClose={() => { }}
        style={{ height: "100%", width: "100%" }}
      >
        <AnswerPageModal
          data={teacherEdition}
          onChangeDesc={onCHangeTeacherEditionDesc}
          onChangeImage={teacherEditionImageUpload}
          onCloseDrawer={onCloseDrawer}
          title={`Teacher edition Page`}
          pageId={pageId}
          onRemove={onRemoveTeacherEdition}
        />
      </Modal>
      {/* Start of Vocabulary Modal */}
      <DeleteModal deleteToggle={deleteModal} loading={deleteVoc} onDelete={() => {
        onDeleteVocab();
        setDeleteVoc(true);
      }}
        setDeleteToggle={setDeleteModal}
        message={`Are you sure to remove this "${deleteVocab?.data?.word}" Vocabulary`} />
      <Modal className='vocabulModal' open={openVocabulary} onClose={handleVocabularyClose}
        style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}>
        <ModalContents className='vocabulContent'>
          <Close color="secondary" onClick={handleVocabularyClose} className='vocabulClose' />
          <div className='VocabularyTab'>
            <Button onClick={handleVocabularyPage}>Page Vocabulary</Button>
          </div>
          <div className='vocabulTableUpr' style={{ maxHeight: 550, overflowY: 'scroll' }}>
            {vocabularyPage &&
              <table border="1" className='vocabulTable'>
                <tr className='vocabulHeadTh'>
                  <th>Word</th>
                  <th>Definition</th>
                  <th>English translation</th>
                  <th>Synonymos</th>
                  <th>Antonyms</th>
                  <th>Sentence</th>
                  <th>Actions</th>
                </tr>
                {map(getBooksVocab, value => {
                  const data = get(value, 'data', '')
                  if (value?.created_by == 'student') return
                  return (
                    <tr className='vocabulHeadTr vocIcons'>
                      <td>
                        {value?.isEditable == true ? (
                          <div style={{ position: 'relative' }}>
                            <InputArea
                              onPressEnter={() => {
                                callVocAI();
                              }}
                              onChange={(d) => { handleAddVocData('word', d.target.value) }}
                              placeholder='word'
                              autoSize
                              style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200 }}
                            />
                            <div style={{ position: 'absolute', top: 6, right: 4 }}>
                              {showWordLoader ? <Loading size={17} /> : <EnterOutlined onClick={() => {
                                callVocAI();
                              }} style={{ fontSize: '25px' }} />}
                            </div>
                          </div>
                        ) : (
                          <InputArea
                            disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' || value?.created_by === 'teacher')}
                            value={`${get(data, 'word', '')} (${value?.created_by == 'owe_admin' ? 'Admin' : value?.created_by})`}
                            onChange={(d) => editDataProperty(value.id, d.target.value, 'word')}
                            placeholder='word'
                            autoSize
                            style={{ width: 150, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200 }}
                          />
                        )}
                      </td>
                      <td>
                        <TextArea
                          onBlur={() => {
                            handleUpdateData(value)
                          }}
                          value={get(data, 'definition', '')}
                          onChange={(d) => editDataProperty(value.id, d.target.value, 'definition')}
                          autoSize
                          style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                        />
                      </td>
                      <td>
                        <TextArea
                          onBlur={() => {
                            handleUpdateData(value)
                          }}
                          value={get(data, 'english_translation', '')}
                          onChange={(d) => editDataProperty(value.id, d.target.value, 'english_translation')}
                          autoSize
                          style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                        />
                      </td>
                      <td>
                        <TextArea
                          onBlur={() => {
                            handleUpdateData(value)
                          }}
                          value={get(data, 'synonyms', '')}
                          onChange={(d) => editDataProperty(value.id, d.target.value, 'synonyms')}
                          autoSize
                          style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                        />
                      </td>
                      <td>
                        <TextArea
                          onBlur={() => {
                            handleUpdateData(value)
                          }}
                          value={get(data, 'antonyms', '')}
                          onChange={(d) => editDataProperty(value.id, d.target.value, 'antonyms')}
                          autoSize
                          style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                        />
                      </td>
                      <td>
                        <TextArea
                          onBlur={() => {
                            handleUpdateData(value)
                          }}
                          value={get(data, 'sentence', '')}
                          onChange={(d) => editDataProperty(value.id, d.target.value, 'sentence')}
                          autoSize
                          style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                        />
                      </td>
                      {value?.created_by !== 'student' ? (

                        <td style={{ textAlign: 'center' }}>

                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Delete style={{ fontSize: '20px' }} onClick={() => {
                              setDeleteModal(true);
                              setDeleteVocab(value);
                            }} />
                          </div>
                        </td>
                      )
                        :
                        <td style={{ textAlign: 'center' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ActionText>
                              Student
                            </ActionText>
                          </div>
                        </td>
                      }
                    </tr>
                  )
                })}
                {showAdd && (
                  <tr className='vocabulHeadTr vocIcons'>
                    <td>
                      {/* <div style={{ position: 'relative' }}>
                        <InputArea
                          onPressEnter={() => {
                            callVocAI();
                          }}
                          onChange={(d) => { handleAddVocData('word', d.target.value) }}
                          placeholder='word'
                          autoSize
                          style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200 }}
                        />
                        <div style={{ position: 'absolute', top: 6, right: 4 }}>
                          {showWordLoader ? <Loading size={17} /> : <EnterOutlined onClick={() => {
                            callVocAI();
                          }} style={{ fontSize: '25px' }} />}
                        </div>

                      </div> */}
                    </td>
                    <td>
                      <TextArea
                        disabled={!vocabularyAI || vocabularyAI?.definition === undefined}
                        onChange={(e) => {
                          e.preventDefault()
                          setVocabularyAI({ ...vocabularyAI, definition: e.target.value })
                        }}
                        value={get(vocabularyAI, "definition", "")}

                        autoSize
                        style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}

                      />
                    </td>
                    <td>
                      <TextArea
                        disabled={!vocabularyAI || vocabularyAI?.english_translation === undefined}
                        onChange={(e) => {
                          e.preventDefault()
                          setVocabularyAI({ ...vocabularyAI, english_translation: e.target.value })
                        }}
                        value={vocabularyAI?.english_translation}
                        autoSize
                        style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                      />
                    </td>
                    <td>
                      <TextArea
                        disabled={!vocabularyAI || vocabularyAI?.synonyms === undefined}
                        onChange={(e) => {
                          e.preventDefault()
                          setVocabularyAI({ ...vocabularyAI, synonyms: e.target.value })
                        }}
                        value={vocabularyAI?.synonyms}

                        autoSize
                        style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                      />
                    </td>
                    <td>
                      <TextArea
                        disabled={!vocabularyAI || vocabularyAI?.sentence === undefined}
                        onChange={(e) => {
                          e.preventDefault()
                          setVocabularyAI({ ...vocabularyAI, sentence: e.target.value })
                        }}
                        value={vocabularyAI?.sentence}

                        autoSize
                        style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                      />
                    </td>
                    <td>

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      </div>
                    </td>
                  </tr>
                )}
                <tr className='vocabulHeadTr'>
                  <td><IconButton>
                    <Add style={{ fontSize: '18px' }} onClick={() => {
                      addNewItem()
                    }} />
                  </IconButton>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            }
          </div>
        </ModalContents>
      </Modal>
      {/* End of Vocabulary Modal */}
      {/* Activites Drawer  */}
      <Drawer
        height="100%"
        destroyOnClose
        className="assignments-drawer"
        onClose={() => {
          setActivityDrawer(false);
        }}
        placement="top"
        visible={activityDrawer}
      >
        {activityDrawer && (
          <AssignmentForm
            pageSrc={get(page, "resource")}
            bookId={bookId}
            pageId={pageId}
            onCreateOrUpdate={onCreateOrUpdate}
            type={"activity"}
          />
        )}
      </Drawer>


      <Drawer
        height="100%"
        destroyOnClose
        className="assignments-drawer"
        onClose={() => {
          setQuizDrawer(false);
        }}
        placement="top"
        visible={quizDrawer}
      >
        {quizDrawer && (
          <AssignmentForm
            pageId={pageId}
            pageSrc={get(page, "resource")}
            data={null}
            bookId={bookId}
            onCreateOrUpdate={onCreateOrUpdate}
            type={"quiz"}
          />
        )}
      </Drawer>


      <Toolbar>
        <Page>
          PageId:{" "}
          <Input
            style={{ boder: "none" }}
            type="text"
            value={pageid}
            onChange={(e) => {
              setpageid(e.target.value);
            }}
            onSubmit={(e) => {
              onPageIdChange(pageid);
            }}
            onBlur={(e) => {
              onPageIdChange(e.target.value);
            }}
          />
        </Page>
        <Page>
          NumberMasked :{" "}
          <Input
            type="text"
            value={numberMask}
            onChange={(e) => onMaskChange(e.target.value)}
          />{" "}
        </Page>
      </Toolbar>
      <Row
        style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setUnitsDrawer(true);
          }}
          startIcon={<MenuBook />}
        >
          Units
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setAnswerpageDrawer(true);
          }}
          startIcon={teacherPage ? <CheckCircle /> : null}
        >
          Show answers
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setTeacherEditionDrawer(true);
          }}
          startIcon={teacherEdition ? <CheckCircle /> : null}
        >
          Teacher edition
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setHyperlinkDrawer(true);
          }}
          startIcon={hyperlinks ? <CheckCircle /> : null}
        >
          Hyperlinks
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setLessionPlanDrawer(true);
          }}
          startIcon={pgLessonPlan ? <CheckCircle /> : null}
        >
          Lesson Plan
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setSuperLessionDrawer(true);
          }}
          startIcon={pgSuperLesson ? <CheckCircle /> : null}
        >
          Super Lesson
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setDifferentiationLessonDrawer(true);
          }}
          startIcon={pgDifferentiation ? <CheckCircle /> : null}
        >
          Differentiation
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setAssignmentPlanDrawer(true);
          }}
          startIcon={pgAssignmentPlan ? <CheckCircle /> : null}
        >
          Quiz
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setWorkbookPlanDrawer(true);
          }}
          startIcon={pgWorkbookPlan ? <CheckCircle /> : null}
        >
          Workbook
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setmainbookPlanDrawer(true);
          }}
          startIcon={pgMainbook ? <CheckCircle /> : null}
        >
          Main book
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setRubricsPlanDrawer(true);
          }}
          startIcon={pgRubricsPlan ? <CheckCircle /> : null}
        >
          Rubrics
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setTipModal(true)
          }}
          startIcon={pageTips ? <CheckCircle /> : null}
        >
          Tips
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            setintrectionModal(true)
          }}
          startIcon={pageIntrections && pageIntrections.length > 0 ? <CheckCircle /> : null}
        >
          Interactions
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            if (!bookId) {
              setAlertSakeBar({
                open: true,
                message: "Please create book first.",
                severity: "error",
              });
              return;
            }
            setActivityDrawer(true);
          }}
        >
          Assignment
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => handleVocabulary(true)}
          startIcon={getBooksVocab.length > 0 && <CheckCircle />}
        >
          Vocabulary
        </Button>
        <PageDescriptionAndTags pgDescription={pgDescription} pgTags={pgTags} onChangeOrUpdateDescription={onChangeOrUpdateDescription} pageId={pageId} />
        {/* <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ margin: 2 }}
          onClick={() => {
            if (!bookId) {
              setAlertSakeBar({
                open: true,
                message: "Please create book first.",
                severity: "error",
              });
              return;
            }
            setQuizDrawer(true);
          }}
        >
          Quizzes
        </Button> */}
      </Row>
      <AssignmemntArea>
        {map(pageActivities, (ac) => (
          <Assignment
            key={ac.id}
            pageSrc={get(page, "resource")}
            pageId={pageId}
            bookId={bookId}
            assignment={ac}
          />
        ))}
        {map(pageQuizzes, (aq) => (
          <Assignment
            key={aq.id}
            pageSrc={get(page, "resource")}
            pageId={pageId}
            bookId={bookId}
            assignment={aq}
          />
        ))}
      </AssignmemntArea>
    </Wrapper>
  );
}
