//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'



//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.classQuizzes;

//  -- Examples array selector --------

export const useClassQuizzes = () => {
    const selectedData = useSelector(selector, shallowEqual)
    return selectedData;
}
