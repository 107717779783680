//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';
import { LOGO } from 'utils/assets/images';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const Wrapper = styled.img`
    height: 100px;
    width: auto;

    @media (max-width: 768px) {
        height: 85px !important;
    }
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Logo
//
//  -- Description
//  Experimental app logo
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const Logo = ({ height }) => (<>
<Wrapper src={LOGO} style={{ height: height }}></Wrapper>
</>

);

//  -- Prop types ---------------------
Logo.propTypes = {
    height: number,
};

//  -- Default props ------------------
Logo.defaultProps = {
    height: 100,
};

export default Logo;