//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create organization ------------
export const createOrganization = () => ({ type: ActionTypes.organizations.createOrganization });
export const createOrganizationFail = (returnedPayload) => ({ type: ActionTypes.organizations.createOrganizationFail, payload: returnedPayload });
export const createOrganizationSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.createOrganizationSuccess, payload: returnedPayload });

//  -- Get organization ---------------
export const getOrganization = () => ({ type: ActionTypes.organizations.getOrganization });
export const getOrganizationFail = (returnedPayload) => ({ type: ActionTypes.organizations.getOrganizationFail, payload: returnedPayload });
export const getOrganizationSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.getOrganizationSuccess, payload: returnedPayload });

//  -- List organizations -------------
export const listOrganizations = () => ({ type: ActionTypes.organizations.listOrganizations });
export const listOrganizationsFail = (returnedPayload) => ({ type: ActionTypes.organizations.listOrganizationsFail, payload: returnedPayload });
export const listOrganizationsSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.listOrganizationsSuccess, payload: returnedPayload });

//  -- List schools -------------------
export const listOrgsByParent = () => ({ type: ActionTypes.organizations.listOrgsByParent });
export const listOrgsByParentFail = (returnedPayload) => ({ type: ActionTypes.organizations.listOrgsByParentFail, payload: returnedPayload });
export const listOrgsByParentSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.listOrgsByParentSuccess, payload: returnedPayload });

//  -- List districts -----------------
export const listDistricts = () => ({ type: ActionTypes.organizations.listDistricts });
export const listDistrictsFail = (returnedPayload) => ({ type: ActionTypes.organizations.listDistrictsFail, payload: returnedPayload });
export const listDistrictsSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.listDistrictsSuccess, payload: returnedPayload });

//  -- List all Schools -----------------
export const listAllSchools = () => ({ type: ActionTypes.organizations.listAllSchools });
export const listAllSchoolsFail = (returnedPayload) => ({ type: ActionTypes.organizations.listAllSchoolsFail, payload: returnedPayload });
export const listAllSchoolsSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.listAllSchoolsSuccess, payload: returnedPayload });

//  -- List schools -------------------
export const listSchools = () => ({ type: ActionTypes.organizations.listSchools });
export const listSchoolsFail = (returnedPayload) => ({ type: ActionTypes.organizations.listSchoolsFail, payload: returnedPayload });
export const listSchoolsSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.listSchoolsSuccess, payload: returnedPayload });

export const listorgTeacherData = () => ({ type: ActionTypes.orgTeacher.listOrgTeacherData });
export const listorgTeacherDataFail =(returnedPayload) => ({ type: ActionTypes.orgTeacher.listOrgTeacherDataFail, payload: returnedPayload });
export const listorgTeacherDataSuccess =  (returnedPayload) => ({ type: ActionTypes.orgTeacher.listOrgTeacherDataSuccess, payload: returnedPayload });

//  -- Update organization ------------
export const updateOrganization = () => ({ type: ActionTypes.organizations.updateOrganization });
export const updateOrganizationFail = (returnedPayload) => ({ type: ActionTypes.organizations.updateOrganizationFail, payload: returnedPayload });
export const updateOrganizationSuccess = (returnedPayload) => ({ type: ActionTypes.organizations.updateOrganizationSuccess, payload: returnedPayload });


export const setDistDashboardInfo = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.setDistDashboardInfo , payload: returnedPayload });
export const updateDistDashboardInfo = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.updateDistDashboardInfo, payload: returnedPayload });
export const clearDistDashboardInfo = () => ({ type: ActionTypes.distDashboardInfo.clearDistDashboardInfo});

export const setDistExamGraphData = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.setExamsGraphData , payload: returnedPayload });
export const updateDistExamGraphData = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.updateExamsGraphData, payload: returnedPayload });
export const clearDistExamGraphData= () => ({ type: ActionTypes.distDashboardInfo.clearExamsGraphData});

export const setDistBookUsesGraphData = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.setBookUsesGraphData , payload: returnedPayload });
export const updateDistBookUsesGraphData = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.updateBookUsesGraphData, payload: returnedPayload });
export const clearDistBookUsesGraphData = () => ({ type: ActionTypes.distDashboardInfo.clearBookUsesGraphData});


export const setDistContentExamGraphData = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.setContentExamGraphData , payload: returnedPayload });
export const updateDistContentExamGraphData = (returnedPayload) => ({ type: ActionTypes.distDashboardInfo.updateContentExamGraphData, payload: returnedPayload });
export const clearDistContentExamGraphData = () => ({ type: ActionTypes.distDashboardInfo.clearContentExamGraphData});
