//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState, useCallback } from 'react';
import { string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import _,{ filter, find, flatMap, map, get } from 'lodash';
import { Img } from 'react-image';
//  -- Components ---------------------
import { Button, Modal } from '@material-ui/core';
import { Check, Settings, Delete, WatchLater } from '@material-ui/icons';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Lib ----------------------------
import { imageFetch, imageFetchResource, imageFetchFromPodcast, structureFetch, getExceptionStatus, isEbook , isVideo } from 'utils/lib/ebookHelper';


//  -- Thunks -------------------------
import { handleUpdateSubmission } from 'store/submissions/thunks';
import { handleCreatePastSubmission } from 'store/pastSubmission/thunks';
import { TootipWrapper } from "components/TooltipWrapper"
import Loading from './Loading';
import { BookSynoname } from 'utils/config/reference';
import { coversAssigments } from 'utils/config/resources/coversAssigments.js';
import { useResources } from 'store/resources/selectors';
import { useOrgTimezone } from 'store/organizations/selectors';
import { useServertime } from 'store/servertime/selectors';
import { isBeforTime } from "utils/methods/math"
import RetakeModal from "components/RetakeModal";
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Actions = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 20px;
    bottom: 16px;

    @media (max-width: 400px) {
        width: calc(100% - 40px);
    }
`;

const CompleteText = styled.span`
    align-items: center;
    color: ${COLOR.green100};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
`;

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`

const Header = styled.span`
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
`;

const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;

const ImageContiner = styled.button`
    background-color: transparent;
    border: none;
    cursor: zoom-in;
    flex: 1;
    max-width: 112px;
    outline: none;
    padding-right: 24px;
`;

const ImageModal = styled.div`
    left: 50%;
    max-width: 544px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;

const TextContainer = styled.div`
    justify-content: flex-end;
`;

const Text = styled.div`
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
`;

const Wrapper = styled.div`
    align-items: flex-start;
    display: flex;
    width: 100%;
    height: auto;
    padding: 16px;
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    margin-bottom: 16px;
    position: relative;

    @media (max-width: 400px) {
        padding-bottom: 92px;
    }
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Interaction
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...

const AssignmentStatus = ({
    chapter,
    data,
    handleEditActivity,
    handleEditQuiz,
    handleDeleteQuiz,
    handleDeleteActivity,
    isStudent,
    onAssign,
    submission,
    booksAllList,
    currentUser
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const servertime = useServertime()
    const timezone = useOrgTimezone()
    const [modalOpen, setModalState] = useState(false);
    const [isRetakeOpen, setisRetakeOpen] = useState(false);
    const page = data && data.data ? data?.data?.page : data?.page
    const { isException, exceptionStatusText } = getExceptionStatus(submission,currentUser);
    const hasLateDate = get(data, "lateDate") ? isBeforTime(get(data, "lateDate"),servertime,timezone) : null
    const isPastDue = isBeforTime(get(data, "dueDate"),servertime,timezone)
    const resources = useResources()
    const allresourcesVideo = flatMap(map(resources, k => k.videos))
    const isActive = get(data, "active", true)
    // if(data&&!data.data){
    //     data.data=data;
    // }
    const bookDesc = useCallback(
        () => {
            let book = find(booksAllList, { id: chapter })
            return book ? book.description : ""
        },
        [chapter, booksAllList],
    )
    const getNumberMask = useCallback((_page) => {
        let book = find(booksAllList, { id: chapter })
        if (book) {
            let numberMask = find(book.pages,k=>k.pageId == _page)
            if (numberMask) {
                if (numberMask.NumberMasked)
                    return numberMask.NumberMasked
                else
                    return _page;
            } else {
                return _page;
            }
        } else {
            return _page;
        }
    }, [chapter, booksAllList])
    const retakeAvailable = submission
        ? parseInt(submission?.submissions, 10) < (parseInt(data?.retakes, 10) + 1)
        : true;
    const isLateDate = hasLateDate
    const hasDuration = !isNaN(parseInt(data?.timeDuration)) && data?.timeDuration > 0
    const [openReviewDialog, setReviewDialog] = React.useState(false);
    const isReviewAccount = currentUser?.reviewAccount || false
    const graphicOrganizationRedirect = () => {
        if (data?.data.activity_type === "graphic_organizer") {
            history.push(`/ebook?chapter=${data?.data?.chapter}&page=${data?.data?.page}&assignment_type=graphic_organizer&id=${data.id}`);
            return true;
        }
        return false;
    }
    const onSubmit = async () => {
        if (!isActive) {
            return;
        }
        const assignmentId = _.get(data,"data.id",0)
        const assignmentTypeId = _.get(data,"data.typeId","")
        if (submission?.complete && !retakeAvailable) {
            return;
        } else if (submission?.complete && retakeAvailable) {
            await dispatch(handleCreatePastSubmission({ submissionId: submission?.id }))
            // await dispatch(handleUpdateSubmission(submission?.id, {
            //     ...submission,
            //     complete: false,
            //     retaking: true,
            //     answers: [],
            //     graded: false,
            //     feedback: '',
            //     audioFeedback: null,
            //     timmer: 0,
            //     ai_feedback: []
            // }))
            if (isVideo(chapter,resources)) {
                redirectToAssignmentVideo(get(data,"data.chapter"));
            } else {
                if(!graphicOrganizationRedirect())
                history.push(`/ebook?chapter=${chapter}&page=${page}&activity=${assignmentTypeId === 'activity' ? assignmentId : null}&quiz=${assignmentTypeId === 'quiz' ? assignmentId : null}`);
            }
        } else {
            if (isVideo(chapter,resources)) {
                redirectToAssignmentVideo(data.data.chapter);
            } else {
                if(!graphicOrganizationRedirect())
                history.push(`/ebook?chapter=${chapter}&page=${page}&activity=${assignmentTypeId === 'activity' ? assignmentId : null}&quiz=${assignmentTypeId === 'quiz' ? assignmentId : null}`);
            }
        }
    }
    const handleClose = () => {
        setReviewDialog(false)
    };
    const redirectToAssignmentVideo = (id) => {
        let url = `/video?id=${id}&quiz=1`
        history.push(url);
    }
    const getAssignmentName = () => {
        let displayName = data?.displayName ? data?.displayName : data?.data?.displayName
        let shortName = BookSynoname[chapter];
        let shortN = data?.typeId === "quiz" ? "Q" : "A";
        let pg = getNumberMask(data?.page ? data?.page : data?.data?.page);
        let booksub = data?.bookId ? data?.bookId : data?.data?.bookId
        booksub = booksub ? booksub : "";
        pg = pg?.length > 20 ? (booksub.substring(0, 10)) : `Pg.${pg ?? ""}`;
        return `${displayName} (${shortName ?? shortN}|${pg})`
    }
    const getResources = () => {
        const dataResource = find(allresourcesVideo, { id: page })
        return dataResource.resource;
    }
    if (!page) return null
    let existsCoversOfBook = coversAssigments.filter(x => x?.id == chapter);
    let coverAssigmentURL = '';
    if (existsCoversOfBook.length > 0) {
        coverAssigmentURL = imageFetchResource(coversAssigments, chapter, page);
        // console.log('url from covers')
    } else {
        coverAssigmentURL = imageFetch(booksAllList, chapter, page);
        // console.log('url from books')
    }

    if (coverAssigmentURL == "") {
        coverAssigmentURL = imageFetch(booksAllList, chapter, page);
    }

    const checkRetake = () => {
        if (get(submission, "complete") && retakeAvailable) {
            setisRetakeOpen(true)
        } else {
            onSubmit()
        }
    } 
    return (
        <Wrapper>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openReviewDialog}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> Only for Review!
                </Alert>
            </Snackbar>
            <RetakeModal isRetakeOpen={isRetakeOpen} onClose={()=>{
                setisRetakeOpen(false)
            }} onSubmit={onSubmit}  />
            <Modal
                open={modalOpen}
                onClose={() => setModalState(false)}
                style={{ height: '100%', width: '100%' }}
            >
                <ImageModal>
                    <Image src={isVideo(page,resources) ? imageFetchFromPodcast(page, resources) : imageFetch(booksAllList, chapter, page)} alt='' />
                </ImageModal>
            </Modal>
            <ImageContiner onClick={() => setModalState(true)}>
                <Img
                    style={{ height: "auto", width: "100%", border: `1px solid ${COLOR.black800}` }}
                    src={ isVideo(page,resources) ? imageFetchFromPodcast(page, resources) : coverAssigmentURL}
                    loader={<Loading />}
                />
                {/* <Image src={imageFetch(booksAllList, chapter, page)} alt='' /> */}
            </ImageContiner>
            <Container>
                <Header>{getAssignmentName()}
                    {!isNaN(parseInt(data?.timeDuration)) && data?.timeDuration > 0 && <WatchLater style={{ fontSize: '15px', marginLeft: '6px' }} />}
                </Header>
                <TextContainer>
                    {!isVideo(page,resources) && (
                        <>
                            <Text>Book: <strong style={{ color: COLOR.green300, fontWeight: 600 }}>{bookDesc()}</strong> </Text>
                            <Text>Page: <strong style={{ color: COLOR.green300, fontWeight: 600 }}>{getNumberMask(data?.data ? data.data.page : data.page)}</strong>
                                {!data?.dueDate && <strong style={{ marginLeft: '8px', textDecoration: 'underline' }}>{structureFetch(booksAllList, chapter, data?.page)}</strong>}</Text>
                        </>
                    )}
                    {isVideo(page,resources) && (
                        <>
                            <Text>Resource: <strong style={{ color: COLOR.green300, fontWeight: 600 }}>{getResources()}</strong> </Text>
                        </>
                    )}
                    {get(data, "scheduleAt") ? (<Text>Start Date: <strong style={{ color: COLOR.green300, fontWeight: 600 }}> {moment(get(data, "scheduleAt")).format('MMM DD, YYYY hh:mm A')}</strong></Text>) : null}
                    {get(data, "dueDate") ? (<Text>Due Date: <strong style={{ color: COLOR.green300, fontWeight: 600 }}> {moment(get(data, "dueDate")).format('MMM DD, YYYY hh:mm A')}</strong></Text>) : null}
                    {get(data, "lateDate") ? (<Text> Late Submission: <strong style={{ color: COLOR.green300, fontWeight: 600 }}> {moment(get(data, "lateDate")).format('MMM DD, YYYY hh:mm A')}</strong></Text>) : null}
                    {data?.retakes && (<Text>Retakes Allowed: <strong style={{ color: COLOR.green300, fontWeight: 600 }}>{data?.retakes}</strong></Text>)}
                    {!isNaN(parseInt(data?.timeDuration)) && data?.timeDuration > 0 && (<Text>Duration in minutes: <strong style={{ color: COLOR.green300, fontWeight: 600 }}>{data?.timeDuration}</strong></Text>)}
                    {!data?.dueDate && (<Text>Status: <strong>Unassigned</strong></Text>)}
                    {!data?.dueDate && !isStudent && (
                        <Button
                            color="primary"
                            onClick={() => {
                                if (isReviewAccount) {
                                    setReviewDialog(true)
                                } else {
                                    onAssign()
                                }
                            }}
                            variant="contained"
                            style={{ marginTop: '4px', width: '100%' }}
                        >
                            Assign to Class
                        </Button>
                    )}
                </TextContainer>
                {data?.dueDate && (
                    <Actions className="settingBtnsUpr">
                        {isStudent ? (
                            <>
                                {!get(submission,"complete",false) && isPastDue && !isLateDate ? (
                                    <CompleteText>
                                        Past Due
                                    </CompleteText>
                                ) : (
                                    <>
                                        {get(submission,"complete") && (!retakeAvailable && !isLateDate) ? (
                                            <CompleteText>
                                                <Check style={{ fontSize: '15px', marginRight: '6px' }} />
                                                Completed
                                            </CompleteText>
                                        ) : (<TootipWrapper hasToolTip={hasDuration || !isActive} label={!isActive ? "Assignment is deactivated by teacher" : isException ? `Assignment is marked as ${exceptionStatusText}` : submission ? `This activity includes a "timer", are you sure you want to resume it?` : null} >
                                            <Button
                                                color={!isActive ? "secondary" : "primary"}
                                                onClick={checkRetake}
                                                disabled={isException}
                                                variant="contained"
                                                style={{ marginBottom: '4px' }}
                                            >
                                                {(!isActive) ? "Deactivated" : (isException ? exceptionStatusText : (get(submission,"complete") && retakeAvailable ? 'Retake' : (submission ? 'Resume' : 'Start')))}
                                                {hasDuration && <WatchLater style={{ fontSize: '15px', marginLeft: '6px' }} />}
                                            </Button>
                                        </TootipWrapper>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Button
                                    color="primary"
                                    onClick={() => data?.data?.typeId === 'quiz' ? handleEditQuiz(data) : handleEditActivity(data)}
                                    variant="contained"
                                    style={{ marginBottom: '4px', padding: '6px 2px !important' }}
                                >
                                    <Settings style={{ fontSize: '16px', width: '16px' }} />
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => data?.data?.typeId === 'quiz' ? handleDeleteQuiz(data) : handleDeleteActivity(data)}
                                    variant="contained"
                                    style={{ marginBottom: '4px', padding: '6px 2px !important' }}
                                >
                                    <Delete style={{ fontSize: '16px', width: '16px' }} />
                                </Button>
                            </>
                        )}
                    </Actions>
                )}
            </Container>
        </Wrapper>
    );
}

//  -- Prop types ---------------------
AssignmentStatus.propTypes = {
    data: shape({
        title: string,
        img: string,
        description: string | null | Number,
        dueDate: string,
        retakes: string,
        status: string
    }),
};

//  -- Default props ------------------
AssignmentStatus.defaultProps = {};
const mapStateToProps = state => ({
    currentUser: state.currentUser,
});

export default connect(mapStateToProps, null)(AssignmentStatus);
