//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from "store/constants";
import { cloneDeep, map } from "lodash";

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------

const booksAllInitial = [];

export const booksAllList = (state = booksAllInitial, action) => {
  switch (action.type) {
    case ActionTypes.books.listAllBooksSuccess:
      return action.payload;
    case ActionTypes.books.createBookSuccess:
      return [...state, action.payload];
    case ActionTypes.books.updateBookSuccess:
      const bk = cloneDeep(state);
      return map(bk, (b) => {
        if (b.id == action.payload.id) {
          return { ...b, ...action.payload };
        }
        return b;
      });
    case ActionTypes.books.listAllBooksFail:
      return state;
    case ActionTypes.books.addEditBook:
      const bks = cloneDeep(state);
      const indexToUpdate = bks.findIndex(
        (item) => item.id === action.payload.id
      );
      if (indexToUpdate !== -1) {
        if (!action.payload.active) {
          return bks.splice(indexToUpdate, 1);
        } else {
          bks[indexToUpdate] = action.payload;
        }
      } else if (action.payload.active) {
        bks.push(action.payload);
      }
      console.log("bks", bks);
      return bks;
    default:
      return state;
  }
};

export const bookOrder = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.books.setBookOrder:
      return action.payload;
    case ActionTypes.books.setBookOrderSuccess:
      return action.payload;
    case ActionTypes.books.setBookOrderFail:
      return state;
    default:
      return state;
  }
};

const booksAllActiveInActive = [];

export const booksActiveInActive = (state = booksAllActiveInActive, action) => {
  switch (action.type) {
    case ActionTypes.booksActiveInactive.listBooksActiveInactiveSuccess:
      return action.payload;
    case ActionTypes.booksActiveInactive.createBookActiveInactiveSuccess:
      return [...state, action.payload];
    case ActionTypes.booksActiveInactive.updateBookActiveInactiveSuccess:
      const bk = cloneDeep(state);
      return map(bk, (b) => {
        if (b.id == action.payload.id) {
          return { ...b, ...action.payload };
        }
        return b;
      });

    default:
      return state;
  }
};
