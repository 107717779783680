import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import VideoLinks from '../../containers/videoLinks';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinkDropdown from '../../containers/videoLinkDropdown';
import VideoLinkDetailsBanner from '../VideoLinkDetailsBanner';

const ButtonLink = styled.button``;

const classNav = () => {
    return (
        <VideoLinkDropdown />
    );
};

export default function LearnScreen() {
    return (
        <>
            <div className="detailMain">
                <Row gutter={[25, 0]}>

                    <Col xs={24} sm={24} md={6} className="searchMob">
                        <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                            <ButtonLink onClick={e => e.preventDefault()}>
                                Search
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </ButtonLink>
                        </Dropdown>
                    </Col>

                    <Col xs={24} sm={24} md={18}>
                        
                        {/* Satrt Include Banner */}
                         <VideoLinkDetailsBanner />
                        {/* End Include Banner */}

                       <div className="detailInfo detailSpc">
                            <div><h1>Heritage Language Teaching objectives </h1></div>
                            <div>
                                <p>We all have objectives in life, they range from personal, financial to health and economical objectives. Have you asked yourself what
                                objectives are best to set when teaching Spanish to Spanish Speakers, Receptive Bilingual, or Heritage Language Learners?</p>
                                <p>According to Guadalupe Valdés, our main objectives should be language maintenance, expansion of linguistic skills in L1 and L2, and
                                the transfer of reading and writing skills between languages. In short, promote an environment of learning where students feel comfortable
                                using the language.  Expand motivation in students to strive to be truly bilingual, and bicultural. Create environments where students feel
                                comfortable receiving and giving feedback. Welcome cross linguistic references and promote that “orgullo” of being bilingual.</p>
                                <p>Do not  forget that the language skills learned in Spanish class are transferred to all the other subject areas. Cada granito de arena cuenta.</p>
                            </div>
                            <div><label><b>- Vivanie Barrios, Los Angeles, California</b></label></div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={6} className="searchWeb">
                        <VideoLinks />
                    </Col>

                </Row>
            </div>

        </>
    );
}
