//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create Notification ----------

export const setNotification = (returnedPayload) => ({ type: ActionTypes.notifications.setNotification, payload: returnedPayload });
export const createNotificationSuccess = (returnedPayload) => ({ type: ActionTypes.notifications.createNotificationSuccess, payload: returnedPayload });
export const updateNotificationSuccess = (returnedPayload) => ({ type: ActionTypes.notifications.updateNotificationSuccess, payload: returnedPayload });
export const updateNotificationSeen = (returnedPayload) => ({ type: ActionTypes.notifications.updateNotificationSeen, payload: returnedPayload });