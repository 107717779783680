//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React,{ useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Col, Row } from 'antd';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination } from '@material-ui/core';
import { Search, FileCopy } from '@material-ui/icons';
import { func, shape, string } from 'prop-types';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Components ---------------------
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';

//  -- Thunks -------------------------
import { handleListLogs, handleCreateLog } from 'store/logs/thunks';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;

const WrapperForm = styled.form`
	padding: 40px 0px;
	width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
const LogsContainer = ({
	logs,
	currentUser
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [data, setData] = useState({});
	const [search, setSearch] = useState('');
	const [originalLogs, setOriginalLogs] = useState([]);

	// console.log("original" + JSON.stringify(data));

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	// if (currentUser?.role != 'owe_admin') {
	// 	// history.push('/admin/class?view=home');
	// }

	const fetchApis = async() => {
		setLoading(true);

		// dispatch(handleCreateLog({
		// 	requestType: 'POST',
		// 	endPoint: 'testing.com',
		// 	statusCode: '',
		// 	method: 'logs',
		// 	process: 'search',
		// 	requestHeaders: JSON.stringify({Authorization: '', 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}),
		// 	requestBody: '',
		// 	responseHeaders: '',
		// 	responseBody: '',
		// 	responseMessage: 'Error testing registrarion',
		// }));

		if (data.startDate != undefined && data.endDate != undefined) {
			await dispatch(handleListLogs(data.startDate, data.endDate));
		} else {
			await dispatch(handleListLogs());
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchApis();
	}, [dispatch])

	const handleSubmit = async (e) => {
        e.preventDefault();
        fetchApis();
    };

	const handleChange = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    };

	const handleSearch = (event) => {
		setSearch(event.target.value);

		if (originalLogs.length == 0) {
			setOriginalLogs(logs);
		}

		if (event.target.value.length > 0) {
			logs.filter(item => {
				return item.endPoint.toLowerCase() == search.toLowerCase();
			});
		} else {
			if (originalLogs.length > 0) {
				logs = originalLogs;
			}
		}
	};

	return (
		<>
			<WrapperForm onSubmit={handleSubmit}>
				<Row gutter={[24, 24]}>
					<Col>
						<TextField
							id="startDate"
							name="startDate"
							label="From"
							InputLabelProps={{ shrink: true, required: true }}
							type="date"
							variant="outlined"
							onChange={handleChange}
							inputProps={{ max:(data.endDate != undefined ? data.endDate : undefined)}}
							value={(data.startDate != undefined ? data.startDate : undefined)}
						/>
					</Col>
					<Col>
						<TextField
							id="endDate"
							name="endDate"
							label="To"
							InputLabelProps={{ shrink: true, required: true }}
							type="date"
							variant="outlined"
							onChange={handleChange}
							inputProps={{ min:(data.startDate != undefined ? data.startDate : undefined)}}
							value={(data.endDate != undefined ? data.endDate : undefined)}
						/>
					</Col>
					<Col>
						<Button disabled={loading} variant="contained" color="primary" type="submit">
							{(loading) ? <Loading size={20} white /> : 'Filter'}
							<Search style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
						</Button>
					</Col>
				</Row>
			</WrapperForm>
			<Wrapper>
				<LoadingWrapper loading={loading}>
					{/* <Row justify="end" style={{ paddingBottom: 12 }}>
						<Col span={4}>
							<TextField id="search" name="search" label="Search" type="text" variant="filled" onChange={handleSearch} style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}/>
						</Col>
					</Row> */}
					<TableContainer>
						<Table style={{ width: "100%", textAlign: "center" }}>
							<TableHead>
								<TableRow>
									<TableCell style={{ textAlign: "center" }}>Type</TableCell>
									<TableCell style={{ textAlign: "center" }}>EndPoint</TableCell>
									<TableCell style={{ textAlign: "center" }}>Code</TableCell>
									<TableCell style={{ textAlign: "center" }}>Method</TableCell>
									<TableCell style={{ textAlign: "center" }}>Process</TableCell>
									<TableCell style={{ textAlign: "center" }}>Request Headers</TableCell>
									<TableCell style={{ textAlign: "center" }}>Request Body</TableCell>
									<TableCell style={{ textAlign: "center" }}>Response Headers</TableCell>
									<TableCell style={{ textAlign: "center" }}>Response Body</TableCell>
									<TableCell style={{ textAlign: "center" }}>Response Message</TableCell>
									<TableCell style={{ textAlign: "center" }}>Created At</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{logs != undefined && logs.length > 0 && (
									logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
										<TableRow key={row.id} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }}>
											<TableCell style={{ textAlign: "center" }}>{row.requestType}</TableCell>
											<TableCell style={{ textAlign: "center" }}>{row.endPoint}</TableCell>
											<TableCell style={{ textAlign: "center" }}>{row.statusCode}</TableCell>
											<TableCell style={{ textAlign: "center" }}>{row.method}</TableCell>
											<TableCell style={{ textAlign: "center" }}>{row.process}</TableCell>
											<TableCell style={{ textAlign: "center" }} onClick={() => { navigator.clipboard.writeText(row.requestHeaders).then(() => {alert("successfully copied");}) }}>
												{
													row.requestHeaders != undefined && (
														<FileCopy />
													)
												}
											</TableCell>
											<TableCell style={{ textAlign: "center" }} onClick={() => {navigator.clipboard.writeText(row.requestBody).then(() => {alert("successfully copied");}) }}>
												{
													row.requestBody != undefined && (
														<FileCopy />
													)
												}
											</TableCell>
											<TableCell style={{ textAlign: "center" }} onClick={() => {navigator.clipboard.writeText(row.responseHeaders).then(() => {alert("successfully copied");}) }}>
												{
													row.responseHeaders != undefined && (
														<FileCopy />
													)
												}
											</TableCell>
											<TableCell style={{ textAlign: "center" }} onClick={() => {navigator.clipboard.writeText(row.responseBody).then(() => {alert("successfully copied");}) }}>
												{
													row.responseBody != undefined && (
														<FileCopy />
													)
												}
											</TableCell>
											<TableCell style={{ textAlign: "center" }}>{row.responseMessage}</TableCell>
											<TableCell style={{ textAlign: "center" }}>{moment(parseInt(row.createdAt)).format('MMM, DD YYYY @ hh:mm')}</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
							{logs != undefined && logs.length > 0 && (
								<TableFooter>
									<TableRow>
										<TablePagination
											count={logs.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											rowsPerPageOptions={[5, 10, 25]}
											backIconButtonProps={{
												color: "secondary"
											}}
											nextIconButtonProps={{ color: "secondary" }}
										/>
									</TableRow>
								</TableFooter>
							)}
						</Table>
					</TableContainer>
				</LoadingWrapper>
			</Wrapper>
		</>
	);
};

//  -- Prop types ---------------------
LogsContainer.propTypes = {};

//  -- Default props ------------------
LogsContainer.defaultProps = {
	logs: []
};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
	logs: state.logs,
	currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(LogsContainer);
