//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

//  -- Components ---------------------
import { Col, Row } from 'antd';
import { Button } from '@material-ui/core';
import MessageRenderer from 'components/MessageRenderer';
import TextEditor from 'components/TextEditor';

//  -- Config -------------------------
import { NEWSLETTERS } from 'utils/config/reference';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Lib ----------------------------
import { imageFetch, newsImageFetch } from 'utils/lib/ebookHelper';

//  -- Thunks -------------------------
import { handleUpdateQuestion } from 'store/questions/thunks';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;

const ImageContiner = styled.div`
    background-color: transparent;
    border: none;
    flex: 1;
    outline: none;
    width: 100%;
    
    @media (min-width: 800px) {
        max-width: 480px;
    }
`;

const QuestionContainer = styled.div`
    margin-top: 80px;
    padding-bottom: 64px;
    width: 100%;

    @media (min-width: 800px) {
        margin-left: 64px;
        margin-top: 0px;
        max-width: 280px;
        padding-bottom: 0px;
    }
`;

const Text = styled.span`
    color: ${COLOR.green100};
    font-size: 14px;
    font-weight: 600;
`;

const Wrapper = styled.form`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 0px 24px;
    width: 100%;

    @media (min-width: 800px) {
        flex-direction: row;
        padding: 0px;
    }
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Question form
//
//  -- Description
//  Create question
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <AnswerForm
//      handleClose={func}
//      handleSubmit={func}
//  />
//

const AnswerForm = ({
    data,
    handleClose,
    booksAllList
}) => {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(false);
    const [answerData, setAnswerData] = useState("");

    const onClose = (e) => {
        e.preventDefault();
        setRefresh(true);
        setAnswerData("");
        setTimeout(() => setRefresh(false), 500);
        handleClose();
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(handleUpdateQuestion(data.id, {
            answer: answerData,
            answerCreated: Date.now()
        }));
        onClose(e);
    };
    return (
        <Wrapper onSubmit={onSubmit}>
            {(NEWSLETTERS[data?.chapter] || data?.chapter.length === 1) && (
                <ImageContiner>
                    <Image src={NEWSLETTERS[data?.chapter] ? newsImageFetch(data?.chapter, data?.page)?.src : imageFetch(booksAllList,data.chapter, data.page)} alt='' />
                </ImageContiner>
            )}
            <QuestionContainer>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Text>Question from {data?.user?.firstName} {data?.user?.lastName}</Text>
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <MessageRenderer data={data?.question} />
                    </Col>
                </Row>
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        {!refresh && (
                            <TextEditor
                                handleChange={setAnswerData}
                                value={answerData}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            style={{ width: '100%' }}
                        >
                            Submit
                        </Button>
                        <Button
                            color="secondary"
                            onClick={onClose}
                            type='submit'
                            style={{ marginTop: '8px', width: '100%' }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </QuestionContainer>
        </Wrapper>
    );
};


//  -- Prop types ---------------------
AnswerForm.propTypes = {};

//  -- Default props ------------------
AnswerForm.defaultProps = {};

export default AnswerForm;