//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const bookSelector = (state) => state.bookNotes;

export const useNotesByBook = () => {
    const selectedData = useSelector(bookSelector, shallowEqual)
    return selectedData;
}
