//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import SentryWrap from "components/Sentry";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { IconButton, Modal } from "@material-ui/core";
import {
  ArrowBackIos,
  Home
} from "@material-ui/icons";
// import Table from "components/Table";
import { filter, find, groupBy } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";

//  -- Thunks -------------------------
import { Card, Col, Layout, Row  , Spin } from 'antd';
import Loading from "components/Loading";
import DistrictForm from "features/forms/admin/District";
import DistrictTable from "features/forms/admin/DistrictTable";
import SchoolsTable from "features/forms/admin/SchoolsTable";
import { get, isEmpty } from 'lodash';
import { handleListActiveInActiveBooks } from "store/books/thunks";
import { handleGetOrgClasses } from "store/classes/thunks";
import { handleProcessClassTenant } from "store/classlink/thunks";
import { handleGetCoursesForClever } from "store/clever/thunks";
import { createKeystore } from "store/keystore/thunks";
import { useCurrentOrgPermission } from "store/organizations/selectors";
import { handleGetDistrictsWithCount, handleGetOrganization, handleListGetSchool, handleListOrgsByParent, handleListSchools } from "store/organizations/thunks";
import { useTimmerRunning } from "store/timmer/selectors";
import { useAllCount, useCurrentUser } from "store/users/selectors";
import { getUsersCount, handleGetAllUser } from "store/users/thunks";
import { v4 } from 'uuid';
// import { handleCreateBook } from "store/books/thunks"
import CotationLoader from 'components/CotationLoader';
import { WelcomeVideoDrawer } from "./addBook/welcomeVideo";
import { useLicences } from "store/licences/selectors";
import moment from "moment-timezone";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const { Header, Sider, Content } = Layout;

const items = [
  getItem('Home', 'Home', <Home style={{ fontSize: '25px' }} />),
  getItem('Data Browser', 'data-browsers', <Home style={{ fontSize: '25px' }} />),
];

const MENUS = [
  { id: "your_districts", value: "Your Districts" },
];
const OVERVIEWMENU = [
  { id: "see_overview", value: "Overview" },
  { id: "see_settings", value: "Settings" },
];
const BOOKLIST = ["32", "7", "8", "1"];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  padding:20px;
`;

const Column = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  align-items: flex-end;
  border-bottom: 1px dashed ${COLOR.black700};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-bottom: 16px;
`;

const CustomButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${COLOR.green100};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  width: 200px;
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 14px;
`;

const District = styled.div`
  align-items: center;
  background-color: white;
  border: 1px dashed ${COLOR.black700};
  border-radius: 2px;
  color: ${COLOR.black200};
  display: flex;
  flex-direction: row;
  font-size: 13px;
  height: 52px;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 0px 8px;
  position: relative;
  transition: all 450ms ease;
  width: 100%;
  &:hover {
    background-color: #f9f9f9;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
`;

const Headers = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 8px;
`;

const Navigation = styled.div`
  width: 100%;
`;

const NavigationItem = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${COLOR.black700};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  outline: none;
  padding: 16px 12px;
  width: 100%;
`;

const Section = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.span`
  color: ${COLOR.black100};
  display: inline-block;
  font-size: 16px;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const TitleContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black700};
    display: inline-flex;
    flex-direction; row;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  height: 100%;
  // padding: 20px 0px;
  width: 100%;
`;
const OverviewText = styled.span`
  color: white;
  font-size: 19px;
  font-weight: 600;
  display:flex !important;
  align-items:center;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: -18px;
  margin-left: 18px;
  @media (max-width: 992px) {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
const DataBrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: -18px;
  margin-left: 18px;
  @media (max-width: 992px) {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
const SearchItem = styled.div`
  position: absolute;
  right: -20px;
  min-height: 100px;
  max-height: 300px;
  width: 300px;
  background-color: rgb(130, 222, 228);
  z-index: 99999;
  top: 40px;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const SearchBox = styled.input`
  background-color: rgb(28, 49, 115);
  border: none;
  border-radius: 7px;
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  outline: none;
  padding: 2px 16px;
  width: 130px;
  margin-right: 16px;
  margin-left: 16px;
  text-align: center;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR.white};
  }
  :-ms-input-placeholder {
    color: ${COLOR.white};
  }
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-right: 2px;
    margin-left: 2px;
  }
`;
const ItemsTabs = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  border-radius: 12px;
  min-width: 200px;
  background-color: ${COLOR.indigodeep};
  width: 32%;
  padding: 16px;
  margin-top: 10px;
`;

const ItemsTabsList = styled.div`
  display: inline-block;
  flex-direction: column;
  min-height: 100px;
  border-radius: 12px;
  min-width: 200px;
  background-color: ${COLOR.indigodeep};
  width: 32%;
  padding: 16px;
  margin-top: 10px;
`;
const ItemRow1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-flow: row wrap;
  margin-top: -10px;
  @media (max-width: 992px) {
  }
`;
const Itemicon = styled.div`
  height: 15px;
  width: 15px;
  background-image: linear-gradient(${COLOR.green100}, ${COLOR.pink100});
`;
const ItemLabel = styled.span`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  text-transform: capitalize;
`;
const ItemLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
const ItemValue = styled.span`
  color: #fff;
  font-size: 32px;
  font-weight: 500;
`;

const ItemValueList = styled.span`
  height: 30px;
  width: 190px;
  border-radius: 5px;
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  text-align: center;
  padding-top: 2px;
  flex: 70%;
`;
const BookWhiteDot = styled.div`
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  position: absolute;
  left: 0px;
`;
const CircularArrow = styled.img`
  height: 35px;
  width: 35px;
`;
const TotalQuizLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
`;
const TotalQuizLabel = styled.span`
  color: ${COLOR.black100};
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
`;
const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding-left: 30px;
`;
const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  width: 100%;
  height: 100%;
  background-color: ${COLOR.indigodeep};
`;
const HomeBtnb = styled.button`
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  margin-bottom: 16px;
`;
const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  // border-radius: 12px;
  // background-color: ${COLOR.indigodeep};
  width: 100%;
  // padding: 16px;
  // margin-top: 32px;
`;
const SectionTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ViewBtn = styled.button`
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  color: ${COLOR.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  border-radius: 7px;
  padding: 2px 16px;
  margin-right: 2px;
  margin-left: 2px;
  min-width: 180px;
  height: 30px;
  margin-left: 68.5%;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const VideoContainer = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const Containermain = styled.div`
`;
const OverViewDist = styled.p`
  font-size:20px;
  font-weight:600;
`;
const CountContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  min-width:100px;
  cursor:pointer;
`;
const CountTitle = styled.span`
  font-size:14px;
  font-weight:600;
  margin-bottom:-15px;
`;
const CountNumber = styled.span`
  font-size:4rem;
  font-weight:700;
   margin-bottom:-15px;
   min-height:100px;
   display:flex;
   align-items:center;
`;
const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
  font-size:12px;
  font-weight:400;
`;
const OverViewValue = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;
const BackButtonContainer = styled.div`
  display:flex;
  align-items:center;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const getBookList = (allBook) => {
  return BOOKLIST.map((e) => find(allBook, { id: e })).filter((e) => e);
};

const SectionsView = ({
  label,
  children,
  currentView,
  id,
  backButton = () => { },
  onCloseView = () => { },
  onClose = () => { },
  hasBoxButton,
}) => {
  return (
    <SectionWrapper>
      <SectionTopRow>
        <SectionTitle>
          {/* <Itemicon style={{ height: 20, width: 20 }} /> */}
          {/* <ItemLabel style={{ fontSize: 20 }}>{label}</ItemLabel> */}
        </SectionTitle>
        {currentView == "boxes" && id != "" && hasBoxButton && (
          <>
            <ViewBtn onClick={backButton}>BACK TO BOXES</ViewBtn>
          </>
        )}
        {id == "" && hasBoxButton && (
          <>
            <ViewBtn onClick={onCloseView}>
              {currentView == "list" ? "VIEW BOXES" : "VIEW LIST"}
            </ViewBtn>
          </>
        )}
        {/* <HomeBtnb onClick={onClose}>

          <Home style={{ color: "#fff", fontWeight: "bold", fontSize: 30 }} />
        </HomeBtnb> */}
      </SectionTopRow>
      {children}
    </SectionWrapper>
  );
};


const DashboardContainer = ({
  currentOrg,
  districts,
  schools,
  users,
  booksAllList,
  allSchools,
  allUser,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const licencesCount = useLicences();
  const currentUser = useCurrentUser();
  const [dataTab, setDataTab] = useState("Home");
  const [distTab, setDistTab] = useState("your_districts");
  const [overViewTab, setOverViewTab] = useState("see_overview");
  const [userloading, setUserLoading] = useState(true);
  const [currentView, setCurrentView] = useState("boxes");
  const [idSchoolBox, setIdSchoolBox] = useState("");
  const [loginLoader, setLoginLoader] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const cotationRef = useRef(null)
  const [collapsed, setCollapsed] = useState(false);
  const [district, setDistrict] = useState(null);
  const [districtForm, setDistrictForm] = useState(false);
  const [editDistrict, setEditDistrict] = useState(true);
  const [timmerRunning, ,] = useTimmerRunning();
  const orgPermissions = useCurrentOrgPermission();
  const bookPermissions = get(orgPermissions, "books", [])
  const usersCount = useAllCount();
  const [selectedDist, setSelectedDist] = useState();
  const getLicences = find(licencesCount, obj => obj.districtId == selectedDist?.id && obj.schoolId == null);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  let isDirector = false;
  if (currentUser?.role === 'admin') {
    isDirector = true;
  }
  const [welcomvideoDrawer, setWelcomeVideoDrawer] = useState(false);

  if (!cotationRef.current) {
    cotationRef.current = getRandomCotation()
  }
  useEffect(() => {
    fetchDistrictData();
  }, [currentOrg]);
  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const has_loaded  = localStorage.getItem("admin_last_update");
        if(has_loaded && moment().isSame(has_loaded,"date")){
          setDataLoading(false);
        }else{
          setDataLoading(true);
        }
        await Promise.allSettled([
          dispatch(getUsersCount()),
          dispatch(handleListSchools()),
          dispatch(handleGetOrgClasses([currentOrg, ...schools])),
          dispatch(handleProcessClassTenant()),
          dispatch(handleGetDistrictsWithCount()),
          dispatch(handleGetCoursesForClever('1')),
          dispatch(handleListActiveInActiveBooks()),
          fetchDistrictData()
        ]);

      } catch (error) {

      } finally {
        localStorage.setItem("admin_last_update", moment().toISOString());
        setDataLoading(false);
      }
    }
    fetchData();
  }, []);


  const fetchDistrictData = async () => {
    try {
      setUserLoading(true);
      if (!isEmpty(currentOrg)) {
        dispatch(handleListGetSchool(currentOrg.id));
        // await dispatch(handleListDistUsers(currentOrg.id));
      }
      setUserLoading(false);
    } catch (error) {
      setUserLoading(false);
    }
  };

  let usersStudents = filter(users, (u) => u.role === "student");
  const usersTeachers = filter(users, (u) => u.role === "teacher");
  const teacherByOrg = groupBy(usersTeachers, (u) => u.orgId);
  const studentsByOrg = groupBy(usersStudents, (u) => u.orgId);

  // Array Books
  let listBooks = [];

  for (var book in booksAllList) {
    let isNotAssignable = booksAllList[book].isNotAssignable;
    let _data = {
      id: booksAllList[book].id,
      description: booksAllList[book].description,
      count: booksAllList[book].count,
    };
    if (!isNotAssignable) {
      listBooks.push(_data);
    }
  }

  const openLoginInNewWindow = async (userId) => {
    setLoginLoader(userId);
    const key = v4()
    await createKeystore(userId, key)
    const url = `${window.location.origin}?key=${key}`
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
    setLoginLoader(null);
  }

  function navigationToMenu(id) {
    setDistTab(id)
  }
  function navigationToMenus(id) {
    setOverViewTab(id);
  }

  const substract = (a, b) => {
    let _a = parseInt(a);
    let _b = parseInt(b);
    if (isNaN(_a)) _a = 0;
    if (isNaN(_b)) _b = 0;
    return _a - _b;
  };

  const formatUserCount = (count) => {
    if (count >= 10000) {
      const formattedCount = (count / 1000).toFixed(1);
      return `${formattedCount}k`;
    } else {
      return count;
    }
  };
  return (
    <SentryWrap>
      <Modal
        open={dataLoading}
      >
        <VideoContainer>
          <Spin size="large" />
        </VideoContainer>
      </Modal>
      <WelcomeVideoDrawer videoDrawer={welcomvideoDrawer} setVideoDrawer={setWelcomeVideoDrawer} />
      <Containermain>
        {distTab == 'your_districts' && (
          <>
            <Row>
              <Col>
                <OverViewDist>
                  District Overview
                </OverViewDist>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col className="ColCardPadding">
                <Card
                  onClick={() => setDataTab('dataBrowser')}
                  hoverable
                  style={{ minWidth: 130 }}
                >
                  <CountContainer>

                    <CountTitle>
                      Districts
                    </CountTitle>
                    <CountNumber>
                      {/* {districts?.length > 0 ? districts?.length : <Loading size={38} />} */}
                      {usersCount && Object.keys(usersCount).length > 0 ? formatUserCount(usersCount?.district_count) : <Loading size={38} />}
                    </CountNumber>
                  </CountContainer>
                </Card>
              </Col>
              <Col className="ColCardPadding">
                <Card
                  hoverable
                  style={{ minWidth: 130 }}
                >
                  <CountContainer>
                    <CountTitle>
                      Schools
                    </CountTitle>
                    <CountNumber>
                      {usersCount && Object.keys(usersCount).length > 0 ? formatUserCount(usersCount?.school_count) : <Loading size={38} />}
                    </CountNumber>
                  </CountContainer>
                </Card>
              </Col>
              <Col className="ColCardPadding">
                <Card
                  hoverable
                  style={{ minWidth: 130 }}
                >
                  <CountContainer>
                    <CountTitle>
                      Teacher
                    </CountTitle>
                    <CountNumber>
                      {usersCount && Object.keys(usersCount).length > 0 ? formatUserCount(usersCount?.teacher_count) : <Loading size={38} />}
                    </CountNumber>
                  </CountContainer>
                </Card>
              </Col>
              <Col className="ColCardPadding">
                <Card
                  hoverable
                  style={{ minWidth: 130 }}
                >
                  <CountContainer>
                    <CountTitle>
                      Student
                    </CountTitle>
                    <CountNumber>
                      {usersCount && Object.keys(usersCount).length > 0 ? formatUserCount(usersCount?.student_count) : <Loading size={38} />}
                    </CountNumber>
                  </CountContainer>
                </Card>
              </Col>
              <Col className="ColCardPadding">
                <Card
                  hoverable
                  style={{ minWidth: 130 }}
                >
                  <CountContainer>
                    <CountTitle>
                      Classes
                    </CountTitle>
                    <CountNumber>
                      {usersCount && Object.keys(usersCount).length > 0 ? formatUserCount(usersCount?.classes_count) : <Loading size={38} />}
                    </CountNumber>
                  </CountContainer>
                </Card>
              </Col>
            </Row>
            <MenuWrapper top={"10px"} style={{ width: "100%" }}>
              {MENUS.map((x) => (
                <MenuBtn style={{ borderBottom: distTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: distTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenu(x.id)}>
                  {x.value}
                </MenuBtn>
              ))}
            </MenuWrapper>

            <SectionsView
              label={"Districts"}
              currentView={currentView}
              id={"districtBox"}
              backButton={() => { }}
              onCloseView={() => { }}
              onClose={() => {
                setDataTab("")
              }}
            >
              <DistrictTable
                setDataTab
                districts={districts}
                allSchools={allSchools}
                userloading={userloading}
                onUpdateOrAdd={() => {
                  fetchDistrictData();
                  dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, editData) => {
                  setSelectedDist(editData)
                  setDistTab(data);
                }}
              />
            </SectionsView>
          </>
        )}
        {distTab == 'see_overviews' && (
          <>
            <BackButtonContainer>
              <IconButton onClick={() => {
                setDistTab('your_districts');
              }}>
                <ArrowBackIos style={{ fontSize: '20px' }} />
              </IconButton>
              <OverViewValue style={{ fontWeight: '700' }}>{get(selectedDist, 'displayName', '')}</OverViewValue>
            </BackButtonContainer>
            <MenuWrapper top={"10px"} style={{ width: "100%" }}>
              {OVERVIEWMENU.map((x) => (
                <MenuBtn style={{ borderBottom: overViewTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: overViewTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenus(x.id)}>
                  {x.value}
                </MenuBtn>
              ))}
            </MenuWrapper>
            {overViewTab === 'see_overview' && (
              <div className="FilterHeading">
                <Card
                  style={{ marginTop: 16 }}
                  type="inner"
                  title="District Info"

                >
                  <Row>
                    <Col xs={24} lg={12}>
                      <OverViewContainer>
                        <OverViewLabel>TOTAL LICENSES</OverViewLabel>
                        <OverViewValue>{get(getLicences, 'allocatedLicense', '0') || 0}</OverViewValue>
                      </OverViewContainer>
                      <OverViewContainer>
                        <OverViewLabel>TOTAL LICENSES USED</OverViewLabel>
                        <OverViewValue>{(selectedDist.maxCount != "undefined" ? selectedDist.maxCount : 0)}</OverViewValue>
                      </OverViewContainer>
                      <OverViewContainer>
                        <OverViewLabel>TOTAL LICENSES AVAILABLE</OverViewLabel>
                        <OverViewValue>{substract(get(selectedDist, 'licenses', ''), (get(selectedDist, 'maxCount', '') != "undefined" ? get(selectedDist, 'maxCount', '') : 0))}</OverViewValue>
                      </OverViewContainer>
                    </Col>
                    <Col xs={24} lg={12}>
                      <OverViewContainer>
                        <OverViewLabel>TOTAL SCHOOLS</OverViewLabel>
                        <OverViewValue>{schools?.length}</OverViewValue>
                      </OverViewContainer>
                      {get(selectedDist, 'startDate', '-') && (
                        <OverViewContainer>
                          <OverViewLabel>START DATE</OverViewLabel>
                          <OverViewValue>{get(selectedDist, 'startDate', '-')}</OverViewValue>
                        </OverViewContainer>
                      )}
                    </Col>
                  </Row>
                </Card>
                <SectionsView
                  label={"Schools"}
                  currentView={currentView}
                  id={idSchoolBox}
                  backButton={() => { }}
                  onCloseView={() => {
                    setCurrentView(currentView == "list" ? "boxes" : "list");
                  }}
                  onClose={() => { }}
                >
                  <SchoolsTable
                    schools={schools}
                    userloading={userloading}
                    studentsByOrg={studentsByOrg}
                    teacherByOrg={teacherByOrg}
                    onUserAddOrUpdate={() => {
                      fetchDistrictData();
                      dispatch(handleGetOrganization(currentOrg?.id));
                    }}
                    onTabsJump={(data) => {
                      setDataTab(data);
                    }}
                  />

                </SectionsView>
              </div>
            )}
            {overViewTab === 'see_settings' && (
              <DistrictForm
                district={selectedDist}
                editing={editDistrict}
                onClose={() => {
                  setDistrictForm(false);
                  setEditDistrict(false);
                  setDistrict(null);
                  // onUpdateOrAdd()
                }}
              />
            )}
          </>
        )}
      </Containermain>
    </SentryWrap>
  );
};

//  -- Prop types ---------------------
DashboardContainer.propTypes = {};

//  -- Default props ------------------
DashboardContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  activities: state.activities,
  classes: state.classes,
  currentOrg: state.currentOrg,
  districts: state.districts,
  interactions: state.interactions,
  quizzes: state.quizzes,
  schools: state.schools,
  users: state.users,
  booksAllList: state.booksAllList,
  allSchools: state.allSchools,
  allUser: state.allUser
});

export default connect(mapStateToProps, null)(DashboardContainer);

