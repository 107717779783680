import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown } from 'antd';
import Link from '@material-ui/core/Link';
import { Modal, Box, Button, IconButton, FormLabel ,FormHelperText  , TextField} from '@material-ui/core';
import { map , get , find , has , filter } from "lodash"
import styled from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinks from 'containers/videoLinks';
import { closeModal, videoLink } from "utils/assets/images"
import { useHowToVideos } from 'store/howToVideos/selectors';
import { useDispatch } from 'react-redux';
import { handleListHowToVideos } from "store/howToVideos/thunks"
import { Add, Edit, Delete, Close } from "@material-ui/icons"
import { DeleteModal } from 'components/Dialog';
import VideoPicker from "components/AdminBooks/VideoPicker";
import PageImagePicker from "components/AdminBooks/PageImagePicker"
import Loading from 'components/Loading';
import { handleCreateHowToVideos , handleDeleteHowToVideos , handleUpdateHowToVideos } from "store/howToVideos/thunks"
const style = {};
const TopRow = styled(Row)`
    border: 0.5px solid #3c393957;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: flex-end;
`;

const ModalContent = styled.div`
    left: 50%;
    width:70vw;
    padding: 0px 24px;
    position: absolute;
    min-height:350px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color:#fff;
    padding:20px;
     @media only screen and (max-width: 600px) {
        width: 90%;
    }
`;
const ModalConfigArea = styled.div`
    min-height:75vh;
    max-height:90vh;
    overflow:auto;
    margin-top:10px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
`;

const EditVideo = ({ editResourseModal, onCloseModal, item }) => {
    const [resourceVideo, setResourcesVideo] = useState({})
    const [poster, setPoster] = useState('')
    const [title, setTitle] = useState('')
    const [video, setVideo] = useState('')
    const [loading, setloading] = useState(false)
    const [error, setError] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        if (item) {
            setResourcesVideo(item)
            setPoster(get(item, "poster", ''))
            setTitle(get(item, "title", ''))
            setVideo(get(item, "video", ''))
            setError([])
        }
    }, [item])
    const onCreateOrUpdate = async () => {
        let err = []
        if (!video) {
            err.push({
                key: "video", message: 'Video required!'
            })
        }
        if (!poster) {
            err.push({
                key: "poster", message: 'Poster image required!'
            })
        }
        if (!title) {
            err.push({
                key: "title", message: 'Title  required!'
            })
        }
        setError(err)
        if (err.length == 0) {
            setloading(true)
             if(has(item,"id")){
                const data = {
                    ...item,
                    poster,
                    video,
                    title
                }
             await dispatch(handleUpdateHowToVideos(data))
             }else{
                const data = {
                    poster,
                    video,
                    title
                }
             await dispatch(handleCreateHowToVideos(data))
             }
            setloading(false)
            onCloseModal()
        }
    }
    return (<Modal
        open={editResourseModal}
        onClose={() => { }}
        style={{ height: '100%', width: '100%' }}
    >
        <ModalContent>
            <Row style={{ justifyContent: "space-between" }}>
                <TitleText>{has(item, "id") ? 'Update Video' : 'Add Video'}</TitleText>
                <IconButton onClick={onCloseModal} style={{ padding: 0 }}>
                    <Close color="secondary" />
                </IconButton>
            </Row>
            <ModalConfigArea>
                <Row>
                    <Col lg={12} xs={24}>
                        <FormLabel style={{color:'black'}} component="legend">Video</FormLabel>
                        <VideoPicker
                            src={video}
                            onChange={(item) => { 
                                setVideo(item.url) 
                                setError(filter(error,e=>e.key !="video"))
                             }}
                        />
                    </Col>
                    <Col lg={12} xs={24} style={{ paddingLeft: 10, paddingTop: 10 }}>
                        <FormLabel style={{ color: 'black' }} component="legend">Poster for videos:</FormLabel>
                        <PageImagePicker
                            src={poster}
                            path={`/poster}`}
                            style={{ height: 270, marginBottom: 24 }}
                            onChange={item => { 
                                setPoster(item.url)
                                setError(filter(error,e=>e.key !="poster"))
                             }}
                            hasDescription={false}
                        />
                        {has(item, "id")?<Row justify="start" style={{ marginBottom: 10, }}>
                            <TextField
                                id="id"
                                name="id"
                                label="Id"
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                disabled
                                variant="outlined"
                                value={get(resourceVideo, "id")}
                            />
                        </Row>:null}
                        <Row justify="start" style={{ marginBottom: 30, }}>
                            <TextField
                                id="title"
                                name="title"
                                error={find(error, ['key', 'title'])}
                                label="Title"
                                InputLabelProps={{ shrink: true, required: true }}
                                required
                                type="text"
                                variant="outlined"
                                onChange={e => {
                                    setTitle(e.target.value)
                                    setError(filter(error,e=>e.key !="title"))
                                }}
                                value={title}
                            />
                        </Row>
                        {error.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                            {error.map(v => (<FormHelperText key={v.key} error>
                                * {get(v, "message")}
                            </FormHelperText>))}
                        </Row> : null}
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                            <Button disabled={loading} fullWidth onClick={onCreateOrUpdate} variant="contained" color="primary">
                                {loading ? <Loading white size={15} /> : null}
                                {has(item, "id") ? "Update" : "Create"} </Button>
                        </Row>
                    </Col>
                </Row>
            </ModalConfigArea>
        </ModalContent>
    </Modal>)
}

export default function LearnScreen() {
    const howToVideos = useHowToVideos()
    const [loading, setLoading] = useState(false)
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [selectedItem,setSelectedItem] = useState(null)
    const dispatch = useDispatch()
    const [videoModalInfo, setVideoModalInfo] = React.useState({
        open: false,
        item: null
    });

    useEffect(() => {
        if (howToVideos?.length == 0) {
            dispatch(handleListHowToVideos())
        }
    }, [])
    const onAdd = () => {
        setVideoModalInfo({open: true, item: {}})
    }
    const handleEdit = (item) => {
        setVideoModalInfo({open: true, item: item})
    }
    const onDeleteItem = async() =>{
        if(has(selectedItem,"id")){
            setLoading(true)
            await dispatch(handleDeleteHowToVideos(selectedItem.id))
            setLoading(false)
        }
        setSelectedItem(null)
        setDeleteToggle(false)
    }
    return (
        <>
            <EditVideo
                editResourseModal={videoModalInfo.open}
                item={videoModalInfo.item}
                onCloseModal={() => setVideoModalInfo({ open: false, item: null })}
            />
            <DeleteModal
                deleteToggle={deleteToggle}
                setDeleteToggle={setDeleteToggle}
                onDelete={onDeleteItem}
                message={`Are you sure to delete - ${get(selectedItem,"title")}`}
                loading={loading} />
            <Button variant="contained" color="primary" style={{ marginTop: 24 }} onClick={onAdd}>
                <Add /> Add videos
            </Button>
            <Row className="learnContentUpr" gutter={[25, 0]}>

                <Col xs={24} sm={24} md={6} className="searchMob">
                </Col>

                <Col xs={24} sm={24} md={18}>
                    <div className="learnContent">
                        <Row gutter={[25, 0]} className="learnDetail">
                            {map(howToVideos, item => (<Col key={item.id} xs={12} sm={12} md={8} className="cstmLrnRow">
                                <TopRow>
                                    <IconButton onClick={() => {
                                        setSelectedItem(item)
                                        setDeleteToggle(true)
                                    }} style={{ padding: 0 }}>
                                        <Delete color="secondary" />
                                    </IconButton>
                                </TopRow>
                                <div className="ShowDetailBnr card-resource" style={{ cursor: "pointer" }} onClick={() => handleEdit(item)}>
                                    <div className="card-resource-overlay"></div>
                                    <img src={item.poster} />
                                    <Link className="ShowDetail" style={{ cursor:"pointer"}} ><img src={videoLink} /></Link>
                                    <div className="card-resource-details fadeIn-bottom">
                                        <h3 className="card-resource-title">{item.title} <Edit /> </h3>
                                    </div>
                                </div>
                                <div className="learnCntLink"><a>{item.title}</a></div>
                            </Col>))}
                        </Row>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={6} className="searchWeb">
                    <VideoLinks Items={howToVideos} onClick={handleEdit} />
                </Col>

            </Row>
        </>
    );
}
