import { Button, Modal } from 'antd';
import Acent from 'components/Acent';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useIdeaModal } from "store/idea/selectors";
import { useCurrentUser } from 'store/users/selectors';
import { handleIdeaFeach } from "store/vocabulary/thunks";
import styled from 'styled-components';
import DemoPreview from 'components/ViewDemoModal';
import { IdeaAi, IdeaOneWay, newIdeas } from "utils/assets/images";
import { idea_icon } from 'utils/assets/images/icons';
import { get } from "lodash";
const IdeaImage = styled.img`
//   height:30px;
  margin-bottom: 2px;
`;
const ResponseText = styled.p`
    white-space: pre-line;
`;

const ButtonWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: transparent;
  z-index: 11;
  -webkit-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  cursor: pointer;
}
`;

const IdeaModal = ({ setIdeasModal, ideasModal }) => {
    const currentUser = useCurrentUser();
    const [searchText, setSearchText] = useState('');
    const [ideas, setIdeas] = useState([]);
    const {  ideaModal , hideIdeaModal , viewIdeaModal  } = useIdeaModal();
    useEffect(() => {
        const element = document.querySelector('.ideaMdlTxt');
        if (element) {
            element.scrollTop = 0;
        }
    }, [ideas])

    const onFetchData = async () => {
        if(!searchText) return;
        const newIdea = {
            isLoading: true,
            text: searchText,
            responseText: null,
            time: moment().toISOString()
        };
        setIdeas([newIdea, ...ideas]);
        setSearchText('');

        try {
            let data = await handleIdeaFeach(newIdea.text);
            setIdeas(prevIdeas => prevIdeas.map((idea, index) => 
                index === 0 ? { ...idea, isLoading: false, responseText: typeof data === "string"?data:'' } :  { ...idea, isLoading: false } 
            ));
        } catch (error) {
            console.error("Error in data fetch or parse:", error);
            setIdeas(prevIdeas => prevIdeas.map((idea, index) => 
                index === 0 ? { ...idea, isLoading: false, responseText: '' } :  { ...idea, isLoading: false } 
            ));
        }
    };

    return (
        <div>
            <ButtonWrapper onClick={viewIdeaModal}>
                <IdeaImage style={{ height: get(currentUser, "role") === "student" ? 40 : 30}} id={"idea_bottom_icom"} src={get(currentUser, "role") === "student" ? idea_icon : newIdeas} alt="Idea 11" />
            </ButtonWrapper>
            <Modal
                open={ideaModal || ideasModal}
                onCancel={() => {
                    hideIdeaModal();
                    if(setIdeasModal){
                        setIdeasModal(false);
                    }
       
                }}
                className='ideaOneModel'
            >
                <IdeaContent searchText={searchText} setSearchText={setSearchText} onFetchData={onFetchData} ideas={ideas} />
            </Modal>
        </div>
    );
};

const IdeaContent = ({ searchText, setSearchText, onFetchData, ideas }) => {
    const inpRef = useRef();
    return (
        <div className='ideaMdlUpr'>
            <div className='ideaMdlTop' style={{ display:"flex" , width:"100%"}}>
                <img src={IdeaOneWay} alt="Idea" />   <DemoPreview video_id={"idea-demo"} wrapperStyle={{ justifyContent: "flex-end" , width:"100%"}} />
            </div>
            <div className='ideaMdlContent'>
                <div className='ideaMdlLbl'>
                    <Acent inputRef={inpRef} setValue={setSearchText} style={{marginBottom: 5 , display:"flex" ,justifyContent:"flex-end"}}/>
                    <textarea
                        placeholder='Type here ...'
                        value={searchText}
                        ref={inpRef}
                        onChange={e => setSearchText(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                onFetchData();
                            }
                        }}
                    />
                    <Button className='ideaEnterButton' onClick={onFetchData}>&#9166;</Button>
                </div>
                <div className='ideaMdlTxt'>
                    <ul>
                        {ideas.map((idea, i) => (
                            <React.Fragment key={i}>
                                <li className='ideaTxtMe'>
                                    <label>You</label>
                                    <p>{idea.text}</p>
                                </li>
                                <li className='ideaTxtRespns'>
                                    <label><span>ideaswith1WAY</span><img src={IdeaAi} alt="Idea" /></label>
                                    <ResponseText>{idea.isLoading ?
                                        <div className="dot-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                        : <div className='ideaContent'  dangerouslySetInnerHTML={{__html:idea.responseText||''}} ></div>}
                                    </ResponseText>
                                </li>
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default IdeaModal;
