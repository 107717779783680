//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState , useMemo , useRef } from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";
import { map, find, filter } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { useEffect } from 'react';
import { useAllBook, useBookOrder } from "store/books/selectors"
import { mapBookByOrder } from "utils/lib/ebookHelper"
import { get } from 'lodash';
import { DndProvider, useDrag, useDrop , useDragLayer } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const ImageItem = styled.img`
  height: 100px;
  width: 80px;
object-fit:cover;
`;

const Text = styled.div`
  font-size: 9px;
  line-height: 10px;
  color: white;
  white-space: pre-line;
`;

const TextContainer = styled.div`
  border-top: 1px solid ${COLOR.black800};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  width:150px;
  padding:2px;
  background-color: #171e2d;
`;

const Wrapper = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  height: auto;
  outline: none;
  transition: all 300ms ease;
  width: 100%;

`;

const ListItemCovers = styled.li`
  margin-top: 32px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: ${(p) =>
    p.active ?" -3px 2px 8px 0px #00989d, 3px 1px 5px #00989d;" : p.preActive ? "-3px 2px 8px 0px #9d0001, 3px 1px 5px #9d0000;" : "none"};
`;
const List = styled.ul`
  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
  margin-top:24px;
`;
const CoverImage = styled.img`
    height: 200px;
    width: 150px;
    object-fit: cover;
`;
const OuterWrapper = styled.div``;


export const BookSignleList = ({ data, selected, onSelect,orderedBook }) => {
  const [bookid, setBookid] = useState([]);
  const [preSelected, setPreSelected] = useState([]);
  const bookOrder = useBookOrder()
  const isActive = React.useCallback((id) => { return bookid.includes(id) })
  const sortedItems = [...data.filter((book) => selected.includes(book.id)), ...data.filter((book) => !selected.includes(book.id))];
  const findBookId = (id,orderedBook) => {
  
    let find = false
    orderedBook.forEach((book) => {
      book.books.forEach((book) => {
        if(book.id == id){
          find = true
        }
      })
    })
    return find
  }
 
  const handleSelectArea = (data) => {

    if(!selected.includes(data.id)&&findBookId(data.id,orderedBook)){
      if(!preSelected.includes(data.id)){
        setPreSelected([data.id])
      }else{
        setPreSelected([])
      }
      
  return 
    }
   
  
   
    let bids = Array.from(bookid);
    const hasBook = bookid.includes(data?.id);
    if (hasBook) {
      bids = bids.filter((i) => data.id !== i);
    } else {
      bids.push(data?.id)
    }
   

    // const alreadyIn =  map(bookOrder, k => {
    //   const bk = [];
    //   const hasGrouped = find(k?.order, item => item == data.id )
    //   if (hasGrouped) {
    //     bk.push(hasGrouped)
    //   }
    //   return bk;
    // })

    // let alreadyIn = bookOrder.forEach(book => {
    //   map(book.order, id => {
    //     const hasBook = data.id == id
    //     // return hasBook
    //     console.log('has(data, "id")', id);
    //   })
    // })
    // console.log('alreadyIn', alreadyIn);
    onSelect(bids);
    setBookid(bids);
  }
  useEffect(() => {
    try {
      if (selected && selected.length > 0 && bookid.length == 0) {
        setBookid(selected);
      }
    } catch (error) {

    }
  }, [selected]);

  return (
    <List>
      {map(sortedItems, book => {
        if (!book || !book.id) return null;
        const activeStatus = isActive(book.id);
        const preactive = preSelected?.includes(book.id);
        
        return (
          <ListItemCovers key={book.id}
            active={activeStatus}
             preActive={preactive}
            onClick={(e) => {
              e.preventDefault();
              handleSelectArea(book)
            }}
          >
            <Wrapper>
              <div className="card-resource">
                <a >
                  <div className="card-resource-overlay" style={{ opacity: 0 }}></div>
                  <ImageItem
                    className="card-resource-image"
                    src={book.img}
                    alt={book.description}
                  />
                  <TextContainer>
                    <Text>{book.description}</Text>
                  </TextContainer>
                </a>
              </div>
            </Wrapper>
          </ListItemCovers>
        )
      })}
    </List>
  )
}

const ItemType = 'CARD';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
    opacity: 0.9, // Set desired opacity here
    maxWidth: 400
  };
}

const BookCovers = ({ book }) => {
  return (
    <div className="card-resource">
      <a>
        <div className="card-resource-overlay" style={{ opacity: 0 }}></div>
        <CoverImage
          className="card-resource-image"
          src={book.img}
          alt={book.description}
        />
        <TextContainer>
          <Text>{book.description}</Text>
        </TextContainer>
      </a>
    </div>
  )
}

const CustomDragLayer = ({ all_items }) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const item_data = useMemo(() => find(all_items, ['id', item?.id]) || item, [all_items, item])
  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <BookCovers key={item_data.id} book={item_data} />
      </div>
    </div>
  );
};
  
const DraggableCard = ({ item, index, moveCard, setDragOverIndex, disabled }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem, monitor) => {
      if (!ref.current || disabled) {
        return;
      }
      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
    drop: (draggedItem) => {
      moveCard(draggedItem.index, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType,
    item: { id: item.id, index },
    canDrag: !disabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  useEffect(() => {
    const emptyImage = new Image();
    emptyImage.src = '';
    emptyImage.style.opacity = 0;
    preview(emptyImage);
  }, [preview])
  return (
    <OuterWrapper ref={ref} style={{ opacity: 1 }}>
      <BookCovers key={item.id} book={item} />
    </OuterWrapper>
  );
};


const OrderBookGroup = ({ chapter, disabled, onRearrangeDone }) => {
  const [books, setBooks] = useState([])
  useEffect(() => {
    setBooks(chapter)
  }, [])
  //  -- Handlers ---------------------
  const moveBook = (dragIndex, hoverIndex) => {
    const newChapter = [...books];
    const draggedBook = newChapter[dragIndex];
    newChapter.splice(dragIndex, 1);
    newChapter.splice(hoverIndex, 0, draggedBook);
    setBooks(newChapter)
    onRearrangeDone(newChapter);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: "flex", gap: 12, marginTop: 24 }}>
        {map(books, (book, index) => (
          book && book.id ? (
            <DraggableCard
              key={book.id}
              id={book.id}
              item={book}
              index={index}
              moveCard={moveBook}
              disabled={disabled}
            />
          ) : null
        ))}
      </div>
      <CustomDragLayer all_items={books} />
    </DndProvider>
  );
};

//  -- Prop types ---------------------
OrderBookGroup.propTypes = {
  data: shape({
    img: string,
    title: string,
    displayName: string,
  }),
  handleClick: func,
};

//  -- Default props ------------------
OrderBookGroup.defaultProps = {
  data: {},
};

export default OrderBookGroup;
