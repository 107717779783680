//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------
//  -- Create Licences --------------------
export const createLicences = () => ({ type: ActionTypes.licences.createLicences });
export const createLicencesFail = (returnedPayload) => ({ type: ActionTypes.licences.createLicencesFail, payload: returnedPayload });
export const createLicencesSuccess = (returnedPayload) => ({ type: ActionTypes.licences.createLicencesSuccess, payload: returnedPayload });
//  -- List Licences ---------------------
export const listLicences = () => ({ type: ActionTypes.licences.listLicences });
export const listLicencesFail = (returnedPayload) => ({ type: ActionTypes.licences.listLicencesFail, payload: returnedPayload });
export const listLicencesSuccess = (returnedPayload) => ({ type: ActionTypes.licences.listLicencesSuccess, payload: returnedPayload });

//  -- Update Licences -------------------
// export const updateClass = () => ({ type: ActionTypes.classes.updateClass });
// export const updateClassFail = (returnedPayload) => ({ type: ActionTypes.classes.updateClassFail, payload: returnedPayload });
export const updateLicencesSuccess = (returnedPayload) => ({ type: ActionTypes.licences.updateLicencesSuccess, payload: returnedPayload });