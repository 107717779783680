//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import styled from "styled-components";
//  -- Components ---------------------
import { ArrowLeftOutlined } from "@ant-design/icons";

//  -- Constants ----------------------
import { cloneDeep, filter, find, findIndex, get, map } from "lodash";
//  -- Thunks -------------------------
import {
  Button as ButtonUpdate,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField,
  TextareaAutosize
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Button, Card, Col, Input, Row } from "antd";
import Loading from "components/Loading";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStandards } from "store/story/selectors";
import { handleCreateStanders } from "store/story/thunks";
const { TextArea } = Input;

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const StableWrapperMain = styled.div`
  margin-top: 10px;
`;

const TextAreaAuto = styled(TextareaAutosize)`
  height: 400px;
  max-height: 210px;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  overflow: scroll;
  border-color: rgb(0 0 0 / 32%);
  ::focus-visible {
    outline: #03989e 1px !importent;
    border: 5px solid red !important;
  }
  ::focus {
    border-color: #03989e !importent;
  }
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//

const NEW_ABBR = {
    abbr: "",
    description: "",
    grade_level: "",
}
const StanderForm = ({ onStandardCreate }) => {
    const [loading , setLoader] = useState(false)
    const [standerdsNew, setStanderdsNew] = useState([NEW_ABBR]);
    const dispatch = useDispatch();
    const [error, setError] = useState([]);
    const allStanderds = useStandards();
    const [abbrError, setAbbrError] = useState([]);
    useEffect(() => {
        setStanderdsNew([NEW_ABBR]);
    }, []);
    const onChangeData = (index, key, value) => {
        const h = cloneDeep(standerdsNew);
        if (key === "abbr") {
          const hasAllReady = find(
            allStanderds,
            (std) => std.abbr.toLowerCase() == value.toLowerCase()
          );
          const item = cloneDeep(abbrError);
          if (hasAllReady) {
            if (!find(abbrError, (ab) => ab.ind != index))
              item.push({
                ind: index,
                message: "Abbreviation already  defined please use different",
              });
            setAbbrError(item);
          } else {
            setAbbrError(filter(item, (ab) => ab.ind != index));
          }
        }
        h[index][key] = key === "abbr"?value.toUpperCase():value;
        setStanderdsNew(h);
        setError([]);
    };
    const onAddNew = () => {
        const h = cloneDeep(standerdsNew);
        h.push(NEW_ABBR);
        setStanderdsNew(h);
    };
    const removeHyperLink = (i) => {
        const h = cloneDeep(standerdsNew);
        h.splice(i, 1);
        setStanderdsNew(h);
    };
    const onCreate =async() => { 
        const h = cloneDeep(standerdsNew);
        if (find(h, (k) => !k.abbr)) {
          setError({
            index: findIndex(h, (k) => !k.abbr),
            message: "Abbreviation is required",
          });
          return;
        } else if (find(h, (k) => !k.description)) {
          setError({
            index: findIndex(h, (k) => !k.description),
            message: "Abbreviation is required",
          });
          return;
        }
        setLoader(true);
        await dispatch(handleCreateStanders(standerdsNew));
        onStandardCreate()
        setLoader(false);
    };
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddNew}
          style={{ marginTop: 10, marginBottom: 24 }}
        >
          Add Standard
        </Button>

        <Row gutter={[15, 15]}>
          {map(standerdsNew, (std, i) => (
            <Col xs={24} lg={12} key={i}>
              <Card hoverable>
                <Row
                  gutter={[24, 24]}
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  {standerdsNew.length > 0 && (
                    <IconButton
                      onClick={() => removeHyperLink(i)}
                      style={{ padding: 0, marginRight: 10 }}
                    >
                      <Delete color="secondary" />
                    </IconButton>
                  )}
                </Row>
                <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                  <Col xs={24} lg={24}>
                    <TextField
                      id="title"
                      name="title"
                      label="Abbreviation"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      variant="outlined"
                      onChange={(e) => {
                        onChangeData(i, "abbr", e.target.value);
                      }}
                      value={get(std, "abbr", "")}
                    />
                    {find(abbrError, (ab) => ab.ind == i) && (
                      <FormHelperText style={{ color: "red" }}>
                        Abbreviation already defined please use different
                      </FormHelperText>
                    )}
                  </Col>
                  <Col xs={24} lg={24} className="floatingLabelBG">
                    <TextField
                      id="grade_level"
                      name="grade_level"
                      label="Grade Level"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      variant="outlined"
                      onChange={(e) => {
                        onChangeData(i, "grade_level", e.target.value);
                      }}
                      value={get(std, "grade_level", "")}
                    />
                  </Col>
                  <Col xs={24} lg={24}>
                    <FormControl variant="outlined">
                      <InputLabel
                        id="textTip"
                        style={{
                          backgroundColor: "#fff",
                          paddingLeft: 2,
                          paddingRight: 2,
                        }}
                      >
                        Description
                      </InputLabel>
                      <TextAreaAuto
                        id="description"
                        name="description"
                        label="Description"
                        error={error[i]}
                        InputLabelProps={{ shrink: true, required: true }}
                        type="text"
                        variant="outlined"
                        onChange={(e) => {
                          onChangeData(i, "description", e.target.value);
                        }}
                        value={get(std, "description", "")}
                        style={{ height:250 , overflow:"scroll"}}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        {filter(error,(k) => k).length > 0 ? (
          <FormHelperText error>
            Abbreviation and description are required.
          </FormHelperText>
        ) : null}
        <Row
          gutter={[24, 24]}
          style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
        >
          <ButtonUpdate
            onClick={onCreate}
            color="primary"
            variant="contained"
            disabled={loading}
            style={{ minWidth: 200 }}
          >
            {loading ? <Loading white size={14} /> : "Create"}
          </ButtonUpdate>
        </Row>
      </div>
    );
};
const AddDistricts = ({ }) => {
    const history = useHistory();
    const backToDataBrowser = () => {
        history.push("/standard");
    };

    return (
        <>
            <Row>
                <Col lg={24}>
                    <Button
                        type="link"
                        primary
                        style={{ paddingLeft: 0, fontSize: 17, marginBottom: 20 }}
                        onClick={backToDataBrowser}
                    >
                        <ArrowLeftOutlined />
                        Back
                    </Button>
                </Col>
            </Row>
            <StableWrapperMain>
                <StanderForm
                    onStandardCreate={backToDataBrowser}
                />
            </StableWrapperMain>
        </>
    );
};

//  -- Prop types ---------------------
AddDistricts.propTypes = {};

//  -- Default props ------------------
AddDistricts.defaultProps = {};

export default AddDistricts;
