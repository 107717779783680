//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const createLog = () => ({ type: ActionTypes.logs.createLog });
export const createLogFail = (returnedPayload) => ({ type: ActionTypes.logs.createLogFail, payload: returnedPayload });
export const createLogSuccess = (returnedPayload) => ({ type: ActionTypes.logs.createLogSuccess, payload: returnedPayload });

//  -- List logs ----------------
export const listLogs = () => ({ type: ActionTypes.logs.listLogs });
export const listLogsFail = (returnedPayload) => ({ type: ActionTypes.logs.listLogsFail, payload: returnedPayload });
export const listLogsSuccess = (returnedPayload) => ({ type: ActionTypes.logs.listLogsSuccess, payload: returnedPayload });
