//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import styled from 'styled-components';

//  -- Components ---------------------
import { Button, Modal } from '@material-ui/core';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const Wrapper = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    padding: 16px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Demo modal
//
//  -- Description
//  Demo modal indicator for demo accounts
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const DemoModal = ({
    handleClose,
    open,
    message,
}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Wrapper>
                {message || 'This is a demo acount, please contact One Way Education'}
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: '8px' }}
                >
                    Close
                </Button>
            </Wrapper>
        </Modal>
    );
};

//  -- Prop types ---------------------
DemoModal.propTypes = {};

//  -- Default props ------------------
DemoModal.defaultProps = {};

export default DemoModal;
