import { Button } from 'antd';
import React from 'react';
import { useIdeaModal } from "store/idea/selectors";
import styled from 'styled-components';
import { IdeaOneWay } from "utils/assets/images";

const IdeaImage = styled.img`
  height:48px;
`;
const IdeaModal = ({imageStyle}) => {
    const {  viewIdeaModal } = useIdeaModal();
    return (
        <div className='ideaImgUpr'>
            <Button type="primary" onClick={viewIdeaModal} size="small" style={{ border: "0px" }} className='ideaImgBtn'>
                <IdeaImage style={imageStyle} src={IdeaOneWay} alt="Idea 11" />
            </Button>
        </div>
    );
};


export default IdeaModal;
