//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const currentOrgInitial = null;
const organizationsInitial = [];

export const organizations = (state = organizationsInitial, action) => {
    switch (action.type) {
        case ActionTypes.organizations.listOrganizationsSuccess:
            return action.payload;
        case ActionTypes.organizations.createOrganizationSuccess:
            return [action.payload, ...state];
        case ActionTypes.organizations.updateOrganizationSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload.data };
                }
                return x;
            });
        case ActionTypes.organizations.listOrganizationsFail:
        case ActionTypes.organizations.createOrganizationFail:
        case ActionTypes.organizations.uodateOrganizationFail:
        default:
            return state;
    }
};

export const districts = (state = organizationsInitial, action) => {
    switch (action.type) {
        case ActionTypes.organizations.listDistrictsSuccess:
            return action.payload;
        case ActionTypes.organizations.listDistrictsFail:
        default:
            return state;
    }
};

export const allSchools = (state = organizationsInitial, action) => {
    switch (action.type) {
        case ActionTypes.organizations.listAllSchoolsSuccess:
            return action.payload;
        case ActionTypes.organizations.listAllSchoolsFail:
        default:
            return state;
    }
};

export const schools = (state = organizationsInitial, action) => {
    switch (action.type) {
        case ActionTypes.organizations.listOrgsByParentSuccess:
            return action.payload;
        case ActionTypes.organizations.listOrgsByParentFail:
        default:
            return state;
    }
};

export const currentOrg = (state = currentOrgInitial, action) => {
    switch (action.type) {
        case ActionTypes.organizations.getOrganizationSuccess:
            return action.payload;
        case ActionTypes.organizations.getOrganizationFail:
        default:
            return state;
    }
};
export const orgTeacherData = (state = [], action) => {
    switch (action.type) {
        case ActionTypes.orgTeacher.listOrgTeacherDataSuccess:
            return action.payload;
        case ActionTypes.orgTeacher.listOrgTeacherDataFail:
        default:
            return state;
    }
};
const initDashboardInfo ={
    no_of_schools: 0,
    no_of_teachers: 0,
    no_of_students:0,
    no_of_complete_submission:0
}
export const distDashboardInfo = (state = initDashboardInfo, action) => {
    switch (action.type) {
        case ActionTypes.distDashboardInfo.setDistDashboardInfo:
            return action.payload;
        case ActionTypes.distDashboardInfo.updateDistDashboardInfo:
        default:
            return state;
    }
};
const initExamGraphData = {
    config:{},
    data:{},
}
export const distExamGraphData = (state = initExamGraphData, action) => {
    switch (action.type) {
        case ActionTypes.distDashboardInfo.setExamsGraphData:
            return action.payload;
        case ActionTypes.distDashboardInfo.updateExamsGraphData:
        default:
            return state;
    }
};
const initBookUsesGraphData = {
    config:{},
    data:{},
}
export const distBookUsesData = (state = initBookUsesGraphData, action) => {
    switch (action.type) {
        case ActionTypes.distDashboardInfo.setBookUsesGraphData:
            return action.payload;
        case ActionTypes.distDashboardInfo.updateBookUsesGraphData:
        default:
            return state;
    }
};
const initContentExamGraphData = {
    config:{},
    data:{
        by_book:{
            13:{
                unit_by:[],
                book_name:'Assessment 1'
            },
            14:{
                unit_by:[],
                book_name:'Assessment 1'
            }
        },
        passing_by_units:{
            "Unidad 1": 0,
            "Unidad 2": 0,
            "Unidad 3": 0,
            "Unidad 4": 0,
            "Unidad 5": 0,
            "Unidad 6": 0
        }
    },
}
export const distContentExamData = (state = initContentExamGraphData, action) => {
    switch (action.type) {
        case ActionTypes.distDashboardInfo.setContentExamGraphData:
            return action.payload;
        case ActionTypes.distDashboardInfo.updateContentExamGraphData:
            return action.payload;
        default:
            return state;
    }
};
