//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash';


// {
//     "id": "240af8f1-0faf-4db8-97c4-4b435b77f45c",
//     "1nformate": false,
//     "comunidad": false,
//     "balcónCultural": true,
//     "1WAYRESOURCES": false,
//     "exams": true,
//     "1WAYTEACHER": true,
//     "conectandome": true,
//     "printing": false,
//     "lessonPlan": false,
//     "teacherEdition": false,
//     "books": [
//         {
//             "bookId": "7",
//             "available": true,
//             "displayName": "Anécdotas 1"
//         },
//         {
//             "bookId": "11",
//             "available": true,
//             "displayName": "Workbook 1"
//         },
//         {
//             "bookId": "9",
//             "available": false,
//             "displayName": "Anécdotas Literarias 1"
//         },
//         {
//             "bookId": "13",
//             "available": true,
//             "displayName": "Assessment 1"
//         },
//         {
//             "bookId": "8",
//             "available": true,
//             "displayName": "Anécdotas 2"
//         },
//         {
//             "bookId": "12",
//             "available": false,
//             "displayName": "Workbook 2"
//         },
//         {
//             "bookId": "10",
//             "available": false,
//             "displayName": "Anécdotas Literarias 2"
//         },
//         {
//             "bookId": "14",
//             "available": true,
//             "displayName": "Assessment 2"
//         },
//         {
//             "bookId": "1",
//             "available": false,
//             "displayName": "America Ed 3"
//         },
//         {
//             "bookId": "37",
//             "available": false,
//             "displayName": "Anécdotas Inicial WorkBook"
//         },
//         {
//             "bookId": "46",
//             "available": true,
//             "displayName": "Anécdotas Inicial 2"
//         },
//         {
//             "bookId": "32",
//             "available": true,
//             "displayName": "Anécdotas Inicial"
//         }
//     ]
// }

//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selecter = (state) => state.currentOrg;
const initialPermissions = {
    "1nformate": true,
    "comunidad": true,
    "balcónCultural": true,
    "1WAYRESOURCES": true,
    "exams": true,
    "1WAYTEACHER": true,
    "conectandome": true,
    "printing": true,
    "lessonPlan": true,
    "teacherEdition": true,
    "active":true,
    "userActive":true,
    "books": []

}
//  -- Examples array selector --------
const orgTeacherSelecter = (state) => state.orgTeacherData;
export const useCurrentOrgPermission = () => {
    const selectedData = useSelector(selecter, shallowEqual)
    return _.get(selectedData,'permissions',initialPermissions);
}
export const useCurrentOrg = () => {
    const selectedData = useSelector(selecter, shallowEqual)
    return selectedData;
}
export const useOrgTeacherData = () => {
    const selectedData = useSelector(orgTeacherSelecter, shallowEqual)
    return selectedData;
}
export const useOrgTimezone= () => {
    const selectedData = useCurrentOrg()
    return  _.get(selectedData,"parent.timezone") || _.get(selectedData,"timezone");
}
const distDashboardSelecter = (state) => state.distDashboardInfo;
export const useDistrictDashboardInfo = () => {
    const selectedData = useSelector(distDashboardSelecter, shallowEqual)
    return selectedData;
}
const distExamGraphDataSelecter = (state) => state.distExamGraphData;
export const useExamGraphData = () => {
    const selectedData = useSelector(distExamGraphDataSelecter, shallowEqual)
    return selectedData;
}
const bookUsesGraphDataSelecter = (state) => state.distBookUsesData;
export const useBookUsesGraphData = () => {
    const selectedData = useSelector(bookUsesGraphDataSelecter, shallowEqual)
    return selectedData;
}
const distContentExamGraphDataSelecter = (state) => state.distContentExamData;
export const useContentExamGraphData = () => {
    const selectedData = useSelector(distContentExamGraphDataSelecter, shallowEqual)
    return selectedData;
}
const districtsSelector= state => state.districts;

export const useDistricts = () => {
    const selectedData = useSelector(districtsSelector, shallowEqual)
    return selectedData;
}