//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";
import { has } from "lodash"
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const handleListStory = () => async (dispatch) => {
	try {
		const stories = await API.get('story', `/stories`)
		if (Array.isArray(stories)) {
			dispatch(Actions.listStory(stories))
			return stories;
		}
		return [];
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};

export const handleListStoryStandard = () => async (dispatch) => {
	try {
		const standards = await API.get('story', `/standards`)
		if (Array.isArray(standards)) {
			dispatch(Actions.setStandards(standards))
			return standards;
		}
		return [];
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};
export const handleUpdateStanders = (data) => async (dispatch) => {
	try {
		const standards = await API.put('story', `/standards`,{
			body:data
		})
		if (standards) {
			dispatch(Actions.setUpdatedStandards(standards))
			return standards;
		}
		return standards;
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};
export const handleCreateStanders = (data) => async (dispatch) => {
	try {
		const standards = await API.post('story', `/standards`,{
			body:data
		})
		if (standards) {
			dispatch(Actions.setNewStandards(standards))
			return standards;
		}
		return standards;
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};
export const handleDeleteStanders = (id) => async (dispatch) => {
	try {
		const standards = await API.del('story', `/standards/${id}`)
		if (standards) {
			dispatch(Actions.setDeleteStandards(standards))
			return standards;
		}
		return standards;
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};
export const handleCreateStories = (data) => async (dispatch) => {
	try {
		const stories = await API.post('story', `/story`,{
			body:data
		})
		if (has(stories,"id")) {
			dispatch(Actions.createStory(stories))
			return stories;
		}
		return null;;
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}

}

export const handleUpdateStories = (id,data) => async (dispatch) => {
	try {
		const stories = await API.put('story', `/story/${id}`,{
			body:data
		})
		if (has(stories,"id")) {
			dispatch(Actions.updateStory(stories))
			return stories;
		}
		return null;;
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}

};
export const handleDeleteStories = (id) => async (dispatch) => {
	try {
		const stories = await API.del('story', `/story/${id}`)
		dispatch(Actions.deleteStory(id))
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}	
};
export const handleListOrganizationLitracyOverall = (orgId) => async (dispatch) => {
	try {
		const overAllData = await API.get('story', `/listDistrictOverAll/${orgId}`)
		dispatch(Actions.setOverallLiteracyPurpose(overAllData))
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
	}
};
export const handleListSchoolsLitracy = (orgId) => async (dispatch) => {
	try {
		const overAllData = await API.get('story', `/listBySchools/${orgId}`)
		if(Array.isArray(overAllData)){
			return overAllData;
		}
		return [];
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return [];
	}
};
export const handleListUsersLitracy = (orgId) => async (dispatch) => {
	try {
		const overAllData = await API.get('story', `/listByUser/${orgId}`)
		if(Array.isArray(overAllData)){
			return overAllData;
		}
		return [];
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return [];
	}
};
export const handleListTeacherLitracy = (userId) => async (dispatch) => {
	try {
		const overAllData = await API.get('story', `/listByTeacher/${userId}`)
		if(Array.isArray(overAllData)){
			return overAllData;
		}
		return [];
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return [];
	}
};
export const handleListStudentLitracy = (userId) => async (dispatch) => {
	try {
		const overAllData = await API.get('story', `/listByStudent/${userId}`)
		if(Array.isArray(overAllData)){
			return overAllData;
		}
		return [];
	} catch (error) {
		if (error && error.message != "Network Error") {
			Sentry.captureException(error)
		}
		return [];
	}
};