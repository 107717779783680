//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { filter, find, groupBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { Search , Visibility } from '@material-ui/icons';
import { Col, Drawer } from 'antd';
import Loading from 'components/Loading';
import ReactHighcharts from "react-highcharts";
import { CologG, LogoOnExam, TableBg } from "utils/assets/images";
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { IconButton } from "@material-ui/core"
//  -- Features -----------------------
//  -- Thunks -------------------------
import { TootipWrapper } from 'components/TooltipWrapper';
import _, { get, map , has } from 'lodash';
import { useCurrentUser } from 'store/users/selectors';
import { useCurrentClass } from 'store/classes/selectors';
import { handleUpdateGrade , handleListClassSubmissions } from 'store/submissions/thunks';
import { useDispatch } from 'react-redux';
import AssignmentReview from 'features/forms/AssignmentReview';
import { useAllBook } from 'store/books/selectors';
import { isAfterTime } from 'utils/methods/math';
import { useOrgTimezone } from 'store/organizations/selectors';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import moment  from 'moment-timezone';
import { Dropdown, Menu } from 'antd';

const ACIFL_LEVEL = ['Score 5 ', 'Score 4', 'Score 3', 'Score 2', 'Score 1']
const EXAM_LABELS = ['Reading Only', 'Reading and Li..', 'Listening Only', 'Email Response', 'Essay (Argume..', 'Simulated Conversa..', 'Cultural Comparison']
const EXAM_LABELS_FULL = ['Reading Only', 'Reading and Listening', 'Listening Only', 'Email Response', 'Essay (Argumentative)', 'Simulated Conversation', 'Cultural Comparison']
const NUMBER_OF_QUESTINS = [23,30,12,1,1,1,1]
const config = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        marginTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        height: 120,
        width: 120,
        animation: true,
        backgroundColor: "transparent",
    },
    title: {
        text: "0",
        align: "center",
        verticalAlign: "middle",
        y: 15,
        style: {
            fontWeight: 200,
            fontSize: "14px",
        },
    },
    tooltip: {
        enabled: true,
        formatter: function () {
            return `${this.key ?? ""}` + ":" + this.y + "";
        }
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            cursor: "pointer",
            dataLabels: {
                enabled: false,
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%',
            showInLegend: false,
            borderWidth: 0,
            colors: ["rgb(90,177,208)", COLOR.gray100],
        }
    },
    series: [{
        type: 'pie',
        name: '',
        innerSize: '50%',
        data: [
            ['', 50.0],
            ['', 50.0],
        ]
    }]
};



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


const OverflowTwo = styled.div`
    height: auto;
    overflow-x: scroll;
    width: 70%;
    display: inline-block;
    border-right:1px solid black;
    @media (max-width: 634px) {
        width: 50%;
    }
`;



const OneLogoRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 8px 4px 8px;
    margin-bottom: 4px;
`;



const Wrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
`;

const OverviewIcon = styled.div`
    width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
    background-image: url(${p => p.src});
    background-position: center;
    background-size: 2880% 288%;
    background-repeat: no-repeat;
}
    `
const Logo = styled.img`
  width: 100px;
  border-radius: 50%;
  margin-left:30px;
`;
const OneWayText = styled.span`
  color: #424447;
  font-size: 19px;
  font-weight: 600;
  margin-left: 10px;
  text-transform: uppercase;
  line-height: 20px;
  margin-bottom:20px;
`;

const NameContent = styled.span`
    color: #fff;
    margin-left: 10px;
    width: 220px;

`;
const StudentNameTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    height:45px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    `;
const QuestionTab = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(128,161,90);
    width: 95%;
    height: 45px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
`;
const StudentName = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(89,118,243);
    width: 95%;
    height: 45px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
`;
const HeaderTab = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:${COLOR.indigo100};
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:0px 5px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const HeaderTabP = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:rgb(11,9,84);
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const TableTopBtnCont = styled.div`
    justify-content: center;
    align-items: center;
    padding-left: 1.5%;
    overflow: hidden;
    display: flex;
    padding-left:5px;
    padding-right:5px;
`;
const TopTabCont = styled.div`
    display:flex;
    flex-direction:row;
`;
const TotalNStudentTxt = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
    white-space:pre-line;
    `;
const Exam2TableHeader = styled(TableHeader)`
    background-color:rgb(54,58,78);
    height:45px;
    border-radius:5px;
    align-items:center;
 

`;
const ChartContainer = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap:wrap;
    @media (max-width: 992px) {
       width:100%;
      }
    
`;
const ChartContainerInner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:50px;
    margin-left:10px;
    margin-right:10px;
`;
const ChartLabel = styled.span`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -20px;
`;
const SearchBox = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 7px;
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  outline: none;
  padding: 2px 16px;
  width: 90%;
  margin-right: 2px;
  margin-left: 2px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
  }
  :-ms-input-placeholder {
    color: #fff;
  }
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const SearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color:rgb(126,112,165);
    color: ${COLOR.green};
    font-size: 14px;
    align-items: center;
    width:100%;
    padding-right:12px;
    height: 30px;
    @media (max-width: 992px) {
       min-width:200px;
       margin-bottom:30px;
      }
`;
const ExamTableSection1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    justify-content: space-between;
    `;
const ExamTableSection2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 992px) {
        width: 100%;
    }
    `;
const TableItem = styled.td`
    height:45px;
    width: 25%;
    border: 0.1px solid rgba(0,0,0,0.2);
    border-collapse: collapse;
    text-align:center;
    padding-top: 10px;
    `;
const ExamGradeTable = styled.table`
  border-collapse: collapse;
`;
const ExamGradeBoday = styled.tbody`
`;
const ExamRow = styled.tr`
    display: flex;
    flex-direction: row;`;
const PlacementText = styled.span`
       color:${p => p.textColor ? p.textColor : COLOR.gray100}
    `;
const GraphRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:20px;
   @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    }

`;
const ExamOverflowTwo = styled(OverflowTwo)`
    width:100%;
    border-right: 0px;
    @media (max-width: 992px) {
        width:100%;
    }
    `;
const CloseBtn = styled.span`
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    align-self: flex-end;
    display:flex;
    flex-direction:row;
    color:rgb(197,2,91);
    font-weight:bold;
    justify-content: flex-end;
    `;
const ExamGradeSec = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image:url(${TableBg});
    padding:16px;
    margin-bottom:60px;
`;
const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;
const FaultedText = styled.span`
    color:red;
    font-size:15px;
    font-weight:600;
`;
const FaultedContainer = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
`;

const FaultedCount = styled.div`
    width:100px;
    height:45px;
    border:1px solid white;
    border-radious:5px;
    background-color:rgb(11,9,84);
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
    border-radius:25px;
    // margin-top:-15px;
`;


const ExamButton = styled.button`
     background-color:${COLOR.blueishdeep};
     width:120px;
     height:30px;
     border-radius:5px;
     margin-left:5px;
     margin-right:5px;
     border: 1px solid ${p => p.active ? "#f6ebeb" : "transparent"};
     cursor: pointer;
     box-sizing: border-box;
     box-shadow:${p => p.active ? "2px 2px 2px rgb(35 53 89 / 19%), -2px -2px 2px rgb(35 53 89 / 19%)" : "none"};
     color:rgb(255,255,255);
}
`;

const Input = styled.input`
    border: 1px solid;
    border-radius: 3px;
    box-shadow: none;
    font-size: inherit;
    outline: none;
    padding: 0px 4px;
    transition: all 300ms ease;
    text-align:center;
    width: 40px;
`;
const TbgText = styled.span`
    margin-left: 2px;
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
`;

const Button = styled.button`
    align-items: center;
    background-color: ${COLOR.green100};
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    display: flex;
    height: 24px;
    margin-left: 4px;
    justify-content: center;
    opacity: 0;
    outline: none;
    padding: 2px;
    transition: all 300ms ease;
    width: 24px;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`;

const GradeInputArea = styled(Row)`
    algin-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;

    &:hover ${Button} {
        opacity: ${p => p.disabled ? 0 : 1};
        color: white;
    }
`;

const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    `;
const Form = styled.form`
    algin-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;

    &:hover ${Input} {
        border: 1px solid ${p => p.disabled ? 'white' : 'black'};
    }

    // &:hover ${Button} {
    //     opacity: ${p => p.disabled ? 0 : 1};
    //     color: white;
    // }
`;

const sortname = (a, b) => {
    const nameA = get(a, "lastName", "").toLowerCase()?.replace(/\s/g, '');
    const nameB = get(b, "lastName", "").toLowerCase()?.replace(/\s/g, '');;
    if (nameA < nameB) { return -1 }
    else if (nameA > nameB) { return 1 }
    else { return 0 }
}
const sortAssignment = (a, b) => parseInt(get(a, "data.page", 0)) > parseInt(get(b, "data.page", 0)) ? 1 : -1
const sortAssignedAssignment = (a, b) => parseInt(get(a, "page", 0)) > parseInt(get(b, "page", 0)) ? 1 : -1

const Editer = ({ submission, value, isOpenEnded }) => {
    const [grade, setGrade] = useState()
    const dispatch = useDispatch()
    const inputRef = useRef()
    useEffect(()=>{
        setGrade(value)
    },[value])
    const onChangeValue = (e) => {
        const val = e.target.value;
        if ((val > 0 && val <= 5)||!val) {
            setGrade(val)
        }
    } 
    const handleUpdateGradeToTbg =async (_val) => {
        await dispatch(handleUpdateGrade({
            id: submission.id,
            grade: _val,
        }))
    }
  const submit = async (event) => {
    if(inputRef){
        inputRef.current.blur();
    }
    if (grade > 0 && grade <= 5) {
        handleUpdateGradeToTbg(grade)
    }
  }
    if (submission && isOpenEnded) {
        return (<div>
             <Form
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                submit(e);
            }}
        >
           <Input ref={inputRef} value={grade} onChange={onChangeValue} onBlur={submit} onFocus={event=> event.target.select()} type="text" />   
        </Form>
          
            <TbgText>{submission.manualGrading ? "tbg" : ''}</TbgText>
        </div>)
    } else if (isOpenEnded) {
        return (<span>-</span>)
    }
    return (<span>{value}</span>)
}
const examAssignment = (classQuizzes, classActivities, quizzes, activities, exams) => {
    const examData = exams[0];
    const EARLY_BOOKID = get(examData, "exams[0].bookId")
    const MID_BOOKID = get(examData, "exams[1].bookId")
    const FINAL_BOOKID = get(examData, "exams[2].bookId")
    const ALL_ASSIGNED = []
    const ALL_ASSIGNMENT = []
    if (Array.isArray(classActivities)) {
        ALL_ASSIGNED.push(...classActivities)
    }
    if (Array.isArray(classQuizzes)) {
        ALL_ASSIGNED.push(...classQuizzes)
    }
    if (Array.isArray(quizzes)) {
        ALL_ASSIGNMENT.push(...quizzes)
    }
    if (Array.isArray(activities)) {
        ALL_ASSIGNMENT.push(...activities)
    }
    const earlyAssignedExam = filter(ALL_ASSIGNED, c => get(c, "data.chapter") == EARLY_BOOKID && get(c, "data.type") != 'custom').sort(sortAssignment)
    const midAssignedExam = filter(ALL_ASSIGNED, c => get(c, "data.chapter") == MID_BOOKID && get(c, "data.type") != 'custom').sort(sortAssignment)
    const finalAssignedExam = filter(ALL_ASSIGNED, c => get(c, "data.chapter") == FINAL_BOOKID && get(c, "data.type") != 'custom').sort(sortAssignment)

    const earlyAssignments = filter(ALL_ASSIGNMENT, c => c.chapter == EARLY_BOOKID && c.type != 'custom').sort(sortAssignedAssignment)
    const midAssignments = filter(ALL_ASSIGNMENT, c => c.chapter == MID_BOOKID && c.type != 'custom').sort(sortAssignedAssignment)
    const finaldAssignments = filter(ALL_ASSIGNMENT, c => c.chapter == FINAL_BOOKID && c.type != 'custom').sort(sortAssignedAssignment)

    return ({
        earlyAssignedExam,
        midAssignedExam,
        finalAssignedExam,
        earlyAssignments,
        midAssignments,
        finaldAssignments
    })
}

const getBookId = (exams, examTab) => {
    const examData = exams[0];
    const EARLY_BOOKID = get(examData, "exams[0].bookId");
    const MID_BOOKID = get(examData, "exams[1].bookId");
    const FINAL_BOOKID = get(examData, "exams[2].bookId");
    if (examTab == "exam1") {
        return EARLY_BOOKID;
    } else if (examTab == "exam2") {
        return MID_BOOKID;
    } else if (examTab == "exam3") {
        return FINAL_BOOKID;
    }
};
const ExamTable = ({
    activities,
    dataLoading,
    quizzes,
    users,
    submissions,
    classActivities,
    classQuizzes,
    exams
}) => {
    const [filteredStudent, setFilteredStudent] = useState([])
    const [examTab, setExamTab] = useState('exam1')
    const examData = useMemo(() => examAssignment(classQuizzes, classActivities, quizzes, activities, exams), [classQuizzes, classActivities, quizzes, activities, exams])
    const [listStudentUser, setListStudentUser] = useState(new Array(1).fill({}, 0, 1))
    const studentSubmissions = groupBy(submissions, 'userId');
    const activeBookId = getBookId(exams, examTab)
    const isCombined = examTab === "combined"
    const currentUser = useCurrentUser()
    const currentClass = useCurrentClass()
    const booksAllList = useAllBook()
    const [assignmentDrawer, toggleAssignmentDrawer] = useState(false);
    const [assignmentData, setAssignmentData] = useState({});
    const [selectedAssignmentUserSubmission, setSelectedAssignmentSubmissions] = useState([])
    const dispatch = useDispatch()
    const orgTimezone = useOrgTimezone()
    const [state, setState] = React.useState({
        open: false,
        message: ""
    });


    const reviewAssignment = (user , index) => {
        const examdt = getExamAssignmentData()
        let assignmentdt = null
        map(EXAM_LABELS_FULL, (type, i) => {
            if(i===index){
                const classBookAssignmentByCategory  = filter(examdt.bookAssignment,['category',type]);
                assignmentdt = classBookAssignmentByCategory[0];
            }
       })
       if(!assignmentdt) return ;
       let subMinData = null;
        const userdata = map(listStudentUser, usr => {
            if(usr&&usr.id && studentSubmissions ){
                let sub =   find(studentSubmissions[usr.id],['assignmentId',assignmentdt.id])
                if(sub && sub.userId === user?.id){
                    subMinData = sub;
                }
                if (sub) {
                    return sub;
                } else {
                    const createdSub = getAssignmentCreation(assignmentdt, usr);
                    createdSub.disableEdit = true;
                    return createdSub;
                }
               
            }else{
                const createdSub = getAssignmentCreation(assignmentdt, usr);
                createdSub.disableEdit = true;
                return createdSub;
            }
        }).filter(Boolean)
        if(subMinData){
            // console.log(userdata,"ssss",subMinData)
            setSelectedAssignmentSubmissions(userdata)
            setAssignmentData(subMinData);
            toggleAssignmentDrawer(true);
        }
       
    };

    const fetchSubmissions = () =>{
        dispatch(handleListClassSubmissions(get(currentClass, "id")))
    }
    useEffect(() => {
        const allstudents = _.uniqBy(filter(users, x => x.role === 'student').sort(sortname), "username");
        let arr = new Array(1).fill({}, 0, 1)
        setListStudentUser(allstudents.length > 0 ? allstudents : arr)
        setFilteredStudent(allstudents.length > 0 ? allstudents : arr)
    }, [users])

    const getExamAssignmentData = (etype) => {
        if (!etype) etype = examTab;
        if (etype == "exam3") {
            const assignmentData = examData.finalAssignedExam;
            const bookAssignment = examData.finaldAssignments;
            return ({
                assignmentData,
                bookAssignment
            })
        }
        else if (etype == "exam2") {
            const assignmentData = examData.midAssignedExam;
            const bookAssignment = examData.midAssignments;
            return ({
                assignmentData,
                bookAssignment
            })
        } else {
            const assignmentData = examData.earlyAssignedExam;
            const bookAssignment = examData.earlyAssignments;
            return ({
                assignmentData,
                bookAssignment
            })
        }
    }
    const getCombinedLatestAssignemntBookId = () => {
        const exam1 = getExamAssignmentData("exam1")
        const exam2 = getExamAssignmentData("exam2")
        const exam3 = getExamAssignmentData("exam3")
        let bookId = null
        let exam = exam1
        if (exam3.assignmentData.length > 0) {
            bookId = getBookId(exams, "exam3")
            exam = 'exam3'
        } else if (exam2.assignmentData.length > 0) {
            bookId = getBookId(exams, "exam2")
            exam = 'exam2'
        } else {
            bookId = getBookId(exams, "exam1")
            exam = 'exam1'
        }
        return { bookId, exam }
    }
    const getAssignmentCreation = (assignmentdata, userdata) => {
        try {
            if(!assignmentdata) return {};
            const { id, typeId, chapter, bookId } = assignmentdata ?? {};
            return {
                answers: [],
                assignment: assignmentdata,
                assignmentId: id,
                assignmentType: typeId,
                chapter: chapter,
                classId: currentClass?.id,
                complete: false,
                createdAt: Date.now(),
                feedback: '',
                grade: '0',
                graded: true,
                isLate: false,
                orgId: currentUser?.orgId,
                retakes: assignmentdata.retakes,
                retaking: false,
                submissions: 0,
                timmer: 0,
                user: userdata,
                userId: userdata.id,
                bookId: bookId,
                isTemp: true
            };
        } catch (error) {
            console.error("Error in getAssignmentCreation:", error);
            return {};
        }
    }
    
    const getAssignmentDiterData = useCallback(
        (key, index, user) => {
          const data = getExamAssignmentData(key);
          const { bookAssignment } = data;
          const submissionData = studentSubmissions[user?.id] || [];
      
          const assignments = EXAM_LABELS_FULL.map(level => {
            return bookAssignment
              .filter(assignment => assignment.category === level)
              .map(assignment => {
                const submission = submissionData.find(dt => dt.assignmentId === assignment.id);
                return submission || getAssignmentCreation(assignment,user);
              });
          });
        
      
          return { isOpenEnded: true, submission: assignments[index], assignments };
        },
        [examTab, submissions, currentUser]
      );      
    const getExamGrade = (user, i) => {
        let result = Array.from(new Array(EXAM_LABELS.length + 1)).fill("-")
        const data = getExamAssignmentData()
        const assignmentData = data.assignmentData
        const bookAssignment = data.bookAssignment
        if (!user) {
            result = ["-"]
            map(EXAM_LABELS_FULL, (type, i) => {
                const classBookAssignmentByCategory = filter(bookAssignment, ['category', type]);
                let numberOfQuestion = 0;
                classBookAssignmentByCategory.map((assignment) => numberOfQuestion += assignment.questions.length)
                if (i === 5) {
                    numberOfQuestion = 1;
                }
                if (numberOfQuestion == 0) {
                    numberOfQuestion = NUMBER_OF_QUESTINS[i];
                }
                result.push(numberOfQuestion)
            })
        } else {
            const data = studentSubmissions[user?.id];
            if (data && assignmentData.length > 0) {
                result = ["-"]
                    map(EXAM_LABELS_FULL, (level, i) => {
                    const classBookAssignmentByCategory  = filter(bookAssignment,['category',level]);
                    let grade =  '-';
                    let qlength = 0
                    map(classBookAssignmentByCategory, assignment => {
                        const submission = find(data, dt => dt.assignmentId == assignment.id)
                        const cAssignment = find(assignmentData, k => k.data.id == assignment.id)
                        const questions = get(cAssignment, "data.questions", []);
                        qlength += questions?.length || 0
                        const isOpenEnded = filter(questions, k => k.type != "multipleChoice").length > 0;
                        if (!isOpenEnded) {
                            if (submission && cAssignment) {
                                const answer = get(submission, "answers", []);
                                const correct = filter(questions, (q, i) => (answer[i] && q.answer == answer[i].answer))
                                if(grade === '-'){
                                    grade =   correct.length;
                                }else{
                                    grade +=   correct.length;
                                }
                               
                            }
                        } else {
                            if (submission && cAssignment) {
                                if(grade === '-'){
                                    grade = 0;
                                    grade +=  typeof submission.grade === "string" ||  typeof submission.grade === "number"  ? parseInt(`${submission.grade}`,10) : 0;
                                }else{
                                    grade +=  typeof submission.grade === "string" ||  typeof submission.grade === "number"  ? parseInt(`${submission.grade}`,10) : 0;
                                }
                            } 
                        }
                    })
                    if(grade!='-'&&qlength && i<3){
                        result.push(Math.round(grade/qlength *100))
                    }else{
                        result.push(grade);
                    }
                })
                if (result.length < EXAM_LABELS.length + 1) {
                    let len = (EXAM_LABELS.length - result.length) + 1
                    let re = Array.from(new Array(len)).fill("-")
                    result.push(...re)
                }
            }
        }
        if (result.length < EXAM_LABELS.length + 1) {
            let len = (EXAM_LABELS.length - result.length) + 1
            let re = Array.from(new Array(len)).fill("-")
            result.push(...re)
        }
        return result.length > 8 ? result.slice(0,8):result;
    }
    const getExamGradeCombine = useCallback((user, i) => {
        const res = {
            exam1: '',
            exam2: '',
            exam3: ''
        }
        const result = [res]
        Object.keys(res).map(key => {
            const data = getExamAssignmentData(key)
            const assignmentData = data.assignmentData
            const bookAssignment = data.bookAssignment
            if (!user) {
                map(EXAM_LABELS_FULL, (type, i) => {
                    const classBookAssignmentByCategory  = filter(bookAssignment,['category',type]);
                    let numberOfQuestion = 0;
                     classBookAssignmentByCategory.map((assignment)=>numberOfQuestion +=  assignment.questions.length)
                     if(i===5){
                        numberOfQuestion = 1;
                     }
                     if (numberOfQuestion == 0) {
                        numberOfQuestion = NUMBER_OF_QUESTINS[i];
                     }
                     if (result[i + 1]) {
                        result[i + 1][key] = numberOfQuestion
                    } else {
                        result[i + 1] = {
                            exam1: '-',
                            exam2: '-',
                            exam3: '-'
                        }
                        result[i + 1][key] = numberOfQuestion
                    }
               })
                if (result.length < EXAM_LABELS.length + 1) {
                    let len = (EXAM_LABELS.length - result.length) + 1
                    let re = Array.from(new Array(len)).fill(res)
                    result.push(...re)
                }
            } else {
                const data = studentSubmissions[user?.id];
                if (data && assignmentData.length > 0) {
                    result[0] = {}

                    map(EXAM_LABELS_FULL, (level, i) => {
                        const classBookAssignmentByCategory  = filter(bookAssignment,['category',level]);
                        let grade =  '-';
                        let qlength = 0
                        map(classBookAssignmentByCategory, assignment => {
                            const submission = find(data, dt => dt.assignmentId == assignment.id)
                            const cAssignment = find(assignmentData, k => k.data.id == assignment.id)
                            const questions = get(cAssignment, "data.questions", []);
                            qlength += questions?.length || 0
                            const isOpenEnded = filter(questions, k => k.type != "multipleChoice").length > 0;
                            if (!isOpenEnded) {
                                if (submission && cAssignment) {
                                    const answer = get(submission, "answers", []);
                                    const correct = filter(questions, (q, i) => (answer[i] && q.answer == answer[i].answer))
                                    if(grade === '-'){
                                        grade =   correct.length;
                                    }else{
                                        grade +=   correct.length;
                                    }
                                   
                                }
                            } else {
                                if (submission && cAssignment) {
                                    if(grade === '-'){
                                        grade = 0;
                                        grade +=  typeof submission.grade === "string" ||  typeof submission.grade === "number"  ? parseInt(`${submission.grade}`,10) : 0;
                                    }else{
                                        grade +=  typeof submission.grade === "string" ||  typeof submission.grade === "number"  ? parseInt(`${submission.grade}`,10) : 0;
                                    }
                                } 
                            }
                        })
                        if(grade!='-'&&qlength && i<3){
                           grade =  Math.round(grade/qlength *100)
                        }
                        if (result[i + 1]) {
                            result[i + 1][key] = grade
                        } else {
                            result[i + 1] = {
                                exam1: '-',
                                exam2: '-',
                                exam3: '-'
                            }
                            result[i + 1][key] = grade
                        }
                    })
                    // map(bookAssignment, (assign, i) => {
                    //     let submission = find(data, dt => dt.assignmentId == assign.id)
                    //     const cAssignment = find(assignmentData, k => k.data.id == assign.id)
                    //     const questions = get(cAssignment, "data.questions", []);
                    //     const isOpenEnded = filter(questions, k => k.type != "multipleChoice").length > 0;
                    //     if (submission && cAssignment) {
                    //         if (isOpenEnded) {
                    //             if (result[i + 1]) {
                    //                 result[i + 1][key] = submission.grade ? submission.grade : '-'
                    //             } else {
                    //                 result[i + 1] = {
                    //                     exam1: '-',
                    //                     exam2: '-',
                    //                     exam3: '-'
                    //                 }
                    //                 result[i + 1][key] = submission.grade ? submission.grade : '-'
                    //             }
                    //         } else {
                    //             const answer = get(submission, "answers", []);
                    //             const correct = filter(questions, (q, i) => (answer[i] && q.answer == answer[i].answer))
                    //             if (result[i + 1]) {
                    //                 result[i + 1][key] = correct.length
                    //             } else {
                    //                 result[i + 1] = {
                    //                     exam1: '-',
                    //                     exam2: '-',
                    //                     exam3: '-'
                    //                 }
                    //                 result[i + 1][key] = correct.length;
                    //             }
                    //         }

                    //     } else {
                    //         if (result[i + 1]) {
                    //             result[i + 1][key] = '-'
                    //         } else {
                    //             result[i + 1] = {
                    //                 exam1: '-',
                    //                 exam2: '-',
                    //                 exam3: '-'
                    //             }
                    //             result[i + 1][key] = '-'
                    //         }
                    //     }
                    // })
                    if (result.length < EXAM_LABELS.length + 1) {
                        let len = (EXAM_LABELS.length - result.length) + 1
                        let re = Array.from(new Array(len)).fill(res)
                        result.push(...re)
                    }
                }
            }
        })
        if (result.length < EXAM_LABELS.length + 1) {
            let len = (EXAM_LABELS.length - result.length) + 1
            let re = Array.from(new Array(len)).fill({
                exam1: '-',
                exam2: '-',
                exam3: '-',
            })
            result.push(...re)
        }
        return result.length > 8 ? result.slice(0,8):result;
    }, [examData, submissions, listStudentUser])
    const getPlacement = (user) => {
        let bookId = null;
        let examtype = examTab;
        if (isCombined) {
            const cr = getCombinedLatestAssignemntBookId().bookId
            examtype = cr.exam;
            bookId = cr.bookId;
        } else {
            bookId = activeBookId
        }
        let txt = "";
        const data = studentSubmissions[user?.id];
        let finalSubmission = []
        let qCount = 0
        let correctCount = 0
        const edata = getExamAssignmentData(examtype)
        const assignmentData = edata.assignmentData;
        if (data) {
            finalSubmission = filter(data, k => get(k, "assignment.chapter") == bookId)
            // console.log(finalSubmission,"finalSubmissionfinalSubmission")
            qCount = finalSubmission.length;
            if (finalSubmission.length > 0) {
                map(finalSubmission, assin => {
                    const cAssignment = find(assignmentData, k => k.data.id == assin.assignmentId)
                    const questions = get(cAssignment, "data.questions", []);
                    const answer = get(assin, "answers", []);
                    const isOpenEnded = filter(questions, t => t.type != "multipleChoice").length > 0;
                    if (!isOpenEnded) {
                        const correct = filter(questions, (q, i) => (answer[i] && q.answer == answer[i].answer))
                        correctCount += correct.length
                    } else {
                        correctCount += assin.grade >= 0 ? parseInt(assin.grade, 10) * 3.5 : 0
                    }
                })
            }
        }
        // console.log(correctCount,"correctCount",assignmentData)
        if (assignmentData && assignmentData.length) {
            if (correctCount >= 0 && qCount > 0) {
                if (correctCount >= 0 && correctCount <= 40) {
                    txt = '1'
                }
                else if (correctCount > 40 && correctCount <= 62) {
                    txt = '2'
                }
                else if (correctCount > 62 && correctCount <= 86) {
                    txt = '3'
                }
                else if (correctCount > 87 && correctCount <= 105) {
                    txt = '4'
                }
                else if (correctCount > 106 && correctCount <= 130) {
                    txt = '5'
                }
            } else {
                if (txt == "")
                    txt = "NOT STARTED";
            }
            let hasNotSubmission = false
            map(assignmentData, a => {
                if (!find(finalSubmission, k => k?.assignment?.id == get(a, "data.id"))) {
                    hasNotSubmission = true
                }
            })
            if (finalSubmission.length != 0 && (finalSubmission.length < assignmentData.length && hasNotSubmission)) {
                txt = "NOT FINISHED"
            }
            else if (finalSubmission.length == 0) {
                txt = "NOT STARTED"
            }
        } else {
            txt = "NOT FINISHED"
        }
        return txt;
    }
    const getPlacementCombine = (user) => {
        const res = {
            exam1: '',
            exam2: '',
            exam3: ''
        }
        if (!user) return res;
        Object.keys(res).map(key => {
            const bookId = getBookId(exams, key)
            let txt = "";
            const data = studentSubmissions[user.id];
            let finalSubmission = []
            let qCount = 0
            let correctCount = 0
            const edata = getExamAssignmentData(key)
            const assignmentData = edata.assignmentData;
            if (data) {
                finalSubmission = filter(data, k => k?.assignment?.chapter == bookId)
                qCount = finalSubmission.length;
                if (finalSubmission.length > 0) {
                    map(finalSubmission, assin => {
                        const cAssignment = find(assignmentData, k => k.data.id == assin.assignmentId)
                        const questions = get(cAssignment, "data.questions", []);
                        const answer = get(assin, "answers", []);
                        const isOpenEnded = filter(questions, t => t.type != "multipleChoice").length > 0;
                        if (!isOpenEnded) {
                            const correct = filter(questions, (q, i) => (answer[i] && q.answer == answer[i].answer))
                            correctCount += correct.length
                        } else {
                            correctCount += assin.grade >= 0 ? parseInt(assin.grade, 10) * 3.5 : 0
                        }
                    })
                }
            }
            if (assignmentData && assignmentData.length) {
                if (correctCount >= 0 && qCount > 0) {
                    if (correctCount >= 0 && correctCount <= 40) {
                        txt = '1'
                    }
                    else if (correctCount > 40 && correctCount <= 62) {
                        txt = '2'
                    }
                    else if (correctCount > 62 && correctCount <= 86) {
                        txt = '3'
                    }
                    else if (correctCount > 87 && correctCount <= 105) {
                        txt = '4'
                    }
                    else if (correctCount > 106 && correctCount <= 130) {
                        txt = '5'
                    }
                } else {
                    if (txt == "")
                        txt = "NS";
                }
                let hasNotSubmission = false
                map(assignmentData, a => {
                    if (!find(finalSubmission, k => k.assignment.id == get(a, "data.id"))) {
                        hasNotSubmission = true
                    }
                })
                if (finalSubmission.length != 0 && (finalSubmission.length < assignmentData.length && hasNotSubmission)) {
                    txt = "NF"
                }
                else if (finalSubmission.length == 0) {
                    txt = "NS"
                }
            } else {
                txt = "NA"
            }
            res[key] = txt
        })
        return res;
    }
    const getPlacemEntColor = (v) => {
        if (v == 'NOT FINISHED' || v == 'NF') {
            return COLOR.yellow
        }
        else {
            return COLOR.gray100;
        }
    }
    const filterStudent = (e) => {
        let value = e.target.value?.toLowerCase();
        let st = filter(listStudentUser, (student) => {
            let fullName = (student?.firstName?.toLowerCase() + " " + student?.lastName?.toLowerCase()).trim();
            return (
                student?.username?.toLowerCase()?.includes(value) || 
                student?.firstName?.toLowerCase()?.includes(value) || 
                student?.lastName?.toLowerCase()?.includes(value) || 
                fullName.includes(value)
            );
        });
    
        if (value && value.length > 0) {
            setFilteredStudent(st);
        } else {
            setFilteredStudent(listStudentUser);
        }
    };
    
    const getNoStartedLength = useMemo(() => {
        let not_started = 0;
        map(listStudentUser, (u) => {
            const placement = getPlacement(u);
            if (placement === "NOT STARTED" || placement === "NOT FINISHED") {
                not_started++;
            }
        });
        return not_started;
    }, [listStudentUser, submissions, examTab])
    const getLableFromScore = (score) => {
        if (score == 1) {
            return ACIFL_LEVEL[ACIFL_LEVEL.length - 1];
        } else if (score == 2) {
            return ACIFL_LEVEL[ACIFL_LEVEL.length - 2];
        } else if (score == 3) {
            return ACIFL_LEVEL[ACIFL_LEVEL.length - 3];
        } else if (score == 4) {
            return ACIFL_LEVEL[ACIFL_LEVEL.length - 4];
        } else if (score == 5) {
            return ACIFL_LEVEL[ACIFL_LEVEL.length - 5];
        } else {
            return '';
        }
    }
    const getChartConfig = useCallback((type) => {
        let conf = _.cloneDeep(config);
        let per = 0;
        conf.chart.height = 90
        conf.chart.width = 80
        conf.title.style.fontSize = "10px"
        conf.title.y = 10
        const dt = {};
        map(listStudentUser, (u) => {
            const placement = getPlacement(u);
            const scr = getLableFromScore(placement);
            if (scr) {
                if (dt[scr]) {
                    dt[scr] = dt[scr] + 1;
                } else {
                    dt[scr] = 1;
                }
            } else {
                if (dt[placement]) {
                    dt[placement] = dt[placement] + 1;
                } else {
                    dt[placement] = 1;
                }
            }
        });
        const totalStudent = listStudentUser.length;
        if (dt[type]) {
            let t = dt[type];
            per = ((t / totalStudent) * 100).toFixed(0);
        }
        if (per > 0) {
            let v = [
                [type, parseFloat(per)],
                ['', 100 - per],
            ]
            let txt = `${per ?? "0"}`;
            conf.title.text = txt;
            conf.series[0].data = v;
        }
        return conf;
    }, [listStudentUser, submissions, examTab]);
    const getWidth = (index) => {
        const minSize = 110
        let txt = ACIFL_LEVEL[index]
        if (!txt) return minSize + 50
        return txt.length * 2 + minSize + 10

    }
    const onExamTabClick = (txt) => {
        setExamTab(txt)
    }
    const showMsg = (text) => {
        setState({ open: true, message: text });
    };
    const handleClose = () => {
        setState({ open: false, message: "" });
    };
    const onExam1Click = () => {
        const exm =  exams? exams[0]:null;
        const startDate = get(exm, "exams[0].startDate")
        if (startDate && isAfterTime(startDate, moment(), orgTimezone)) {
            showMsg(` Available by ${moment(startDate).format("MM/DD/YYYY hh:mm A")}`)
        } else {
            onExamTabClick("exam1");
        }
    }
    const onExam2Click = () => {
        const exm =  exams? exams[0]:null;
        const startDate = get(exm, "exams[1].startDate")
        if (startDate && isAfterTime(startDate, moment(), orgTimezone)) {
            showMsg(` Available by ${moment(startDate).format("MM/DD/YYYY hh:mm A")}`)
        } 
        onExamTabClick("exam2");
    }

    const onExam3Click = () => {
        const exm =  exams? exams[0]:null;
        const startDate = get(exm, "exams[2].startDate")
        if (startDate && isAfterTime(startDate, moment(), orgTimezone)) {
            showMsg(` Available by ${moment(startDate).format("MM/DD/YYYY hh:mm A")}`)
        } 
        onExamTabClick("exam3");
    }
    const reviewByEach = (sub) =>{
        const userdata = map(listStudentUser, usr => {
            if (usr && usr.id && studentSubmissions) {
                let subdata = find(studentSubmissions[usr.id], ['assignmentId', sub.assignment.id])
                if (subdata) {
                    return subdata;
                } else {
                    const createdSub = getAssignmentCreation(sub.assignment, usr);
                    createdSub.disableEdit = true;
                    return createdSub;
                }

            } else {
                const createdSub = getAssignmentCreation(sub.assignment, usr);
                createdSub.disableEdit = true;
                return createdSub;
            }
        }).filter(Boolean)
        setSelectedAssignmentSubmissions(userdata)
        setAssignmentData(sub);
        toggleAssignmentDrawer(true);
    }
    const assignmentPreviewNav = useCallback((assignments) => {
        return (
            <Menu>
                {map(assignments, (assign,i) => (<Menu.Item key={i} onClick={() => {
                   reviewByEach({...assign,disableEdit: true})
                }}>
                    <MenuItem>
                        {get(assign,"assignment.displayName","")}
                    </MenuItem>
                </Menu.Item>))}
            </Menu>
        );
    }, [submissions]);

    return (
        <Wrapper>
            {dataLoading ? (
                <Loading />
            ) : (
                <div>
                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            open={state.open}
                            autoHideDuration={3000}
                            onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error">
                                <strong>ALERT:</strong> {state.message}
                            </Alert>
                        </Snackbar>
                        <Drawer
                            height='100%'
                            destroyOnClose
                            onClose={() => {
                                toggleAssignmentDrawer(false);
                                setAssignmentData({});
                                setSelectedAssignmentSubmissions([])
                            }}
                            placement='top'
                            visible={assignmentDrawer}
                            key='assignmentNew'
                        >
                            {assignmentDrawer ? <AssignmentReview
                                booksAllList={booksAllList}
                                data={assignmentData}
                                usersSubmissionData={selectedAssignmentUserSubmission}
                                currentClass={currentClass}
                                handleClose={() => {
                                    // setLoading(true)
                                    toggleAssignmentDrawer(false)
                                    fetchSubmissions()
                                    // setLoading(false)
                                }}
                                scale_1_5={false}
                                gradebook_a_f={false}
                                ap_scale={true}
                            /> : <></>}
                        </Drawer>
                    <ExamGradeSec>
                        <OneLogoRow>
                            <Col span={20} style={{ alignItems: "center", display: "flex", flexDirection: "row", }}>
                                {/* <div> */}
                                <Logo src={LogoOnExam} />
                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginLeft: 15 }} className='headdes'>

                                    <OneWayText className='spanheaddes'>
                                        AP® Spanish Language and Culture Practice Exams
                                    </OneWayText>
                                    <div>
                                        <ExamButton
                                            active={examTab === "exam1"}
                                            // style={{ marginLeft: 16 }}
                                            onClick={onExam1Click}
                                        >
                                            Exam1
                                        </ExamButton>
                                        <ExamButton
                                            active={examTab === "exam2"}
                                            onClick={onExam2Click}
                                        >
                                            Exam2
                                        </ExamButton>
                                        <ExamButton
                                            active={examTab === "exam3"}
                                            onClick={onExam3Click}
                                        >
                                            Exam3
                                        </ExamButton>
                                        <ExamButton
                                            active={examTab == "combined"}
                                            onClick={()=>{
                                                onExamTabClick("combined");
                                            }}
                                        >
                                            Combined
                                        </ExamButton>
                                    </div>
                                </div>
                                {/* </div> */}
                            </Col>
                            <Col span={4}>
                                {/* <CloseBtn
                                >
                                    <Close />
                                </CloseBtn> */}
                            </Col>
                        </OneLogoRow>
                        <Row style={{ marginTop: 20, paddingLeft: 12 }}>
                            <Col style={{ width: 25, paddingTop: 8 }}>
                                <OverviewIcon src={CologG} />
                            </Col>
                            <Col span={8}>
                                <span style={{ fontSize: 20, fontWeight: 600 }}>
                                    Overview
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <TotalNStudentTxt>{`Total number \n of  students: ${listStudentUser?.length}`}</TotalNStudentTxt>
                        </Row>
                        <GraphRow>
                            <Col xs={24} lg={4} style={{ alignItems: "flex-end", paddingTop: 0 }}>
                                <SearchBoxContainer>
                                    <SearchBox
                                        placeholder="Find your student"
                                        onChange={filterStudent}
                                    />
                                    <Search style={{ fontSize: 18 }} />
                                </SearchBoxContainer>
                            </Col>
                            <Col xs={24} lg={18}>
                                <ChartContainer>
                                    {map(ACIFL_LEVEL, k => {
                                        return (<ChartContainerInner key={k}>
                                            <ChartLabel style={{ fontSize: 10, marginBottom: -13, color: ACIFL_LEVEL[ACIFL_LEVEL.length - 1] == k ? "COLOR.black" : COLOR.black }}>{k}</ChartLabel>
                                            <ReactHighcharts config={getChartConfig(k)} />
                                        </ChartContainerInner>)
                                    })}
                                </ChartContainer>
                            </Col>
                            <Col xs={24} lg={2} className="falts" style={{ display: 'flex' }}>
                                <FaultedContainer>
                                    <FaultedText>
                                        FALTANTES
                                    </FaultedText>
                                    <FaultedCount>
                                        {getNoStartedLength}
                                    </FaultedCount>
                                </FaultedContainer>
                            </Col>
                        </GraphRow>
                        <div style={{ display: "flex", flex: 1 }}>
                            <ExamOverflowTwo>
                                <ExamGradeTable>
                                    <ExamGradeBoday>
                                        <Exam2TableHeader>
                                            <ExamTableSection1 style={{ width: 220 }}>
                                                <NameContent>Name</NameContent>
                                            </ExamTableSection1>
                                            <ExamTableSection2>
                                                <TopTabCont>
                                                    <TableTopBtnCont style={{ width: 140 }}>
                                                        <HeaderTabP style={{ fontSize: 12, width: "90%" }}>Score</HeaderTabP>
                                                    </TableTopBtnCont>
                                                    {map(EXAM_LABELS, (key, index) => (<TableTopBtnCont key={key + index} style={{ width: getWidth(index) }}>
                                                        <TootipWrapper hasToolTip label={EXAM_LABELS_FULL[index]}>
                                                            <HeaderTab key={key} style={{ fontSize: 12, textOverflow: "ellipsis", width: "90%" }}>{key}</HeaderTab>
                                                        </TootipWrapper>
                                                    </TableTopBtnCont>
                                                    ))}
                                                </TopTabCont>
                                            </ExamTableSection2>
                                        </Exam2TableHeader>
                                    </ExamGradeBoday>
                                </ExamGradeTable>
                                <ExamGradeBoday style={{ display: "flex", flexDirection: "row", marginTop: -3 }}>
                                    <ExamTableSection1>
                                        {isCombined && <StudentNameTable>
                                            <QuestionTab style={{ backgroundColor: COLOR.indigo200 }}>Exams</QuestionTab>
                                        </StudentNameTable>}
                                        <StudentNameTable>
                                            <QuestionTab>Questions per section:</QuestionTab>
                                        </StudentNameTable>
                                        {filteredStudent?.map((user, i) => (
                                            <StudentNameTable>
                                                <StudentName>{user.firstName} , {user.lastName}</StudentName>{" "}
                                            </StudentNameTable>
                                        ))}
                                    </ExamTableSection1>
                                    {!isCombined ?
                                        <ExamTableSection2>
                                            <ExamGradeTable borderd>
                                                <ExamRow>
                                                    {getExamGrade().map((k, i) => {
                                                        let _width = i == 0 ? 140 : getWidth(i - 1);
                                                        return (
                                                            <TableItem style={{ width: _width }}>{i == 0 ? "" : k}</TableItem>
                                                        )
                                                    })}
                                                </ExamRow>
                                                {filteredStudent?.map((user, i) => {
                                                    const res = getExamGrade(user);
                                                    return (
                                                        <ExamRow>
                                                            {map(res, (k, ind) => {
                                                                let v = getPlacement(user, res)
                                                                let exm = { isOpenEnded: false, submission: null, temp: null , assignments:[] }
                                                                exm = getAssignmentDiterData(examTab, ind - 1, user);
                                                                let hasSubmission = null;
                                                                if(ind>0){
                                                                    const examdt =   exm.assignments[ind-1]
                                                                    if(examdt){
                                                                        hasSubmission = examdt.find(v=>Object.keys(v).length>0 && !v.isTemp)
                                                                    }
                                                                }
                                                                if(ind>3){
                                                                    exm.submission = exm.submission.find(k=>!k.isTemp);
                                                                }
                                                                let _width = ind == 0 ? 140 : getWidth(ind - 1);
                                                                return (
                                                                    <TableItem style={{ width: _width }}>
                                                                        {ind == 0 ? (
                                                                            <PlacementText textColor={getPlacemEntColor(v)}>
                                                                                {v}
                                                                            </PlacementText>
                                                                        ) : (
                                                                            <GradeInputArea style={{ marginBottom: 0, justifyContent: "center" }}>
                                                                                <Button style={{ opacity: 0, cursor: "default" }} type="button" />

                                                                                <Editer submission={exm.submission} isOpenEnded={ind > 3} value={k} />
                                                                                {exm.submission && ind > 3 ? <Button type="button" onClick={() => {
                                                                                    reviewAssignment(user, ind - 1)
                                                                                }}>
                                                                                     <Visibility style={{ fontSize: '16px' }} />
                                                                                </Button>
                                                                                    : hasSubmission ? <Dropdown overlay={assignmentPreviewNav(exm.assignments[ind-1])}
                                                                                        placement="bottomLeft" trigger={['click']}
                                                                                    >
                                                                                        <Button onClick={(e) => e.preventDefault()} type="button">
                                                                                             <Visibility style={{ fontSize: '16px' }} />
                                                                                        </Button>
                                                                                    </Dropdown> : <Button style={{ opacity: 0, cursor: "default" }} type="button" />}

                                                                            </GradeInputArea>
                                                                        )}
                                                                    </TableItem>
                                                                )
                                                            })}
                                                        </ExamRow>
                                                    );
                                                })}
                                            </ExamGradeTable>
                                        </ExamTableSection2> :
                                        <ExamTableSection2>
                                            <ExamGradeTable borderd>
                                                <ExamRow>
                                                    {map(['', ...EXAM_LABELS], (k, i) => {
                                                        let _width = i == 0 ? 140 : getWidth(i - 1);
                                                        return (<div key={k + i} style={{ backgroundColor: COLOR.indigo400, color: "#fff", width: _width }}>
                                                            <TableItem style={{ width: _width / 3 }}>E1</TableItem>
                                                            <TableItem style={{ width: _width / 3 }}>E2</TableItem>
                                                            <TableItem style={{ width: _width / 3 }}>E3</TableItem>
                                                        </div>)
                                                    })}
                                                </ExamRow>
                                                <ExamRow>
                                                    {getExamGradeCombine().map((k, i) => {
                                                        //  console.log(k,"kkkk")
                                                        let _width = i == 0 ? 140 : getWidth(i - 1);
                                                        return (
                                                            <div key={k + i} style={{ width: _width, backgroundColor: i % 2 != 0 ? "rgb(242,248,254)" : "transparent" }}>
                                                                <TableItem style={{ width: _width / 3 }}>
                                                                    {i == 0 ? "" : k.exam1}
                                                                </TableItem>
                                                                <TableItem style={{ width: _width / 3 }}>
                                                                    {i == 0 ? "" : k.exam2}
                                                                </TableItem>
                                                                <TableItem style={{ width: _width / 3 }}>
                                                                    {i == 0 ? "" : k.exam3}
                                                                </TableItem>
                                                            </div>
                                                        )
                                                    })}
                                                </ExamRow>
                                                {map(filteredStudent, (user, i) => {
                                                    const res = getExamGradeCombine(user);
                                                    return (
                                                        <ExamRow>
                                                            {map(res, (k, ind) => {
                                                                let v = {}
                                                                if (ind == 0) {
                                                                    v = getPlacementCombine(user)
                                                                }
                                                                let _width = ind == 0 ? 140 : getWidth(ind - 1);
                                                                return (
                                                                    <div key={ind} style={{ width: _width, backgroundColor: ind % 2 != 0 ? "rgb(242,248,254)" : "transparent" }}>

                                                                        {ind == 0 ? (
                                                                            <div key={k + i}>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    <PlacementText textColor={getPlacemEntColor(v.exam1)}>
                                                                                        {v.exam1}
                                                                                    </PlacementText>
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    <PlacementText textColor={getPlacemEntColor(v.exam2)}>
                                                                                        {v.exam2}
                                                                                    </PlacementText>
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    <PlacementText textColor={getPlacemEntColor(v.exam3)}>
                                                                                        {v.exam3}
                                                                                    </PlacementText>
                                                                                </TableItem>
                                                                            </div>
                                                                        ) : (
                                                                            <div key={k + i}>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    {k.exam1}
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    {k.exam2}
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    {k.exam3}
                                                                                </TableItem>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            })}
                                                        </ExamRow>
                                                    );
                                                })}
                                            </ExamGradeTable>
                                        </ExamTableSection2>}
                                </ExamGradeBoday>
                            </ExamOverflowTwo>
                        </div>
                    </ExamGradeSec>
                </div>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ExamTable.propTypes = {};

//  -- Default props ------------------
ExamTable.defaultProps = {};

export default ExamTable;
