import React, { useEffect } from "react";
import WidgetStationModal from "./widgetStationModal";
import WidgetGroupModal from "./widgetGroupModal";
import "./modal.css";
import DeactivateByStudent from "./deactivateByStudent";

const ModalMenu = ({ type = "", open = true, close = false, ...props }) => {


  return (
    <>
      {(() => {
        switch (type) {
          case "widgetStation":
            return <WidgetStationModal {...props} />;
          case "widgetGroup":
            return <WidgetGroupModal  {...props} />;
            case "deactivateByStudent": 
            return <DeactivateByStudent open={open} close={close} {...props} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

export default ModalMenu;
