//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create activity ----------------
/*export const createActivity = () => ({ type: ActionTypes.activities.createActivity });
export const createActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.createActivityFail, payload: returnedPayload });
export const createActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.createActivitySuccess, payload: returnedPayload });

//  -- Get activity -------------------
export const getActivity = () => ({ type: ActionTypes.activities.getActivity });
export const getActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.getActivityFail, payload: returnedPayload });
export const getActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.getActivitySuccess, payload: returnedPayload });

//  -- List chapter activities --------
export const listChapterActivities = () => ({ type: ActionTypes.activities.listChapterActivities });
export const listChapterActivitiesFail = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesFail, payload: returnedPayload });
export const listChapterActivitiesSuccess = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesSuccess, payload: returnedPayload });*/

//  -- List books ----------------
export const listUserBooks = () => ({ type: ActionTypes.userBooks.listUserBooks });
export const listUserBooksFail = (returnedPayload) => ({ type: ActionTypes.userBooks.listUserBooksFail, payload: returnedPayload });
export const listUserBooksSuccess = (returnedPayload) => ({ type: ActionTypes.userBooks.listUserBooksSuccess, payload: returnedPayload });
