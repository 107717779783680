//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------
// -- CheckList -------------------

export const listWidgets = (returnedPayload) => ({ type: ActionTypes.widgets.listWidgets, payload: returnedPayload });
export const addWidgets = (returnedPayload) => ({ type: ActionTypes.widgets.addWidgets, payload: returnedPayload });
export const deleteWidgets = (returnedPayload) => ({ type: ActionTypes.widgets.deleteWidgets, payload: returnedPayload });
export const updateWidgets = (returnedPayload) => ({ type: ActionTypes.widgets.updateWidgets, payload: returnedPayload });
