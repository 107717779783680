//  ----------------------------------------------------------------------------
// Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Amplify } from 'aws-amplify';
import configureStore from 'store';
import { GoogleOAuthProvider } from '@react-oauth/google';
//  -- Global style -------------------
import GlobalStyles from 'GlobalStyles';
import 'index.css';
import config from './config';
//  -- Routes -------------------------
import Routes from 'containers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import FirebaseProvider from 'containers/FirebaseContext';
import { AudioProvider } from 'utils/context/AudioProvider';
//  -- AWS Configuration --------------

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'activities',
        endpoint: config.apiGateway.ACTIVITIES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'userBooks',
        endpoint: config.apiGateway.USERBOOKS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'books',
        endpoint: config.apiGateway.BOOKS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'tipsByBook',
        endpoint: config.apiGateway.TIPS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'assignments',
        endpoint: config.apiGateway.ASSIGNMENT_SUBMISSIONS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'classActivities',
        endpoint: config.apiGateway.CLASS_ACTIVITIES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'classes',
        endpoint: config.apiGateway.CLASSES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'classQuizzes',
        endpoint: config.apiGateway.CLASS_QUIZZES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'differentiation',
        endpoint: config.apiGateway.DIFFERENTIATION,
        region: config.apiGateway.REGION,
      },
      {
        name: 'interactions',
        endpoint: config.apiGateway.INTERACTIONS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'likes',
        endpoint: config.apiGateway.LIKES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'notes',
        endpoint: config.apiGateway.NOTES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'organizations',
        endpoint: config.apiGateway.ORGANIZATIONS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'posts',
        endpoint: config.apiGateway.POSTS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'questions',
        endpoint: config.apiGateway.QUESTIONS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'quizzes',
        endpoint: config.apiGateway.QUIZZES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'userClasses',
        endpoint: config.apiGateway.USER_CLASSES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'users',
        endpoint: config.apiGateway.USERS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'userPreference',
        endpoint: config.apiGateway.USERS_PREFERENCE,
        region: config.apiGateway.REGION,
      },
      {
        name: 'activitiesRDS',
        endpoint: config.apiGateway.ACTIVITYRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'userBooksRDS',
        endpoint: config.apiGateway.USERBOOKSRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'booksRDS',
        endpoint: config.apiGateway.BOOKSRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'tipsByBookRDS',
        endpoint: config.apiGateway.TIPSRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'assignmentsRDS',
        endpoint: config.apiGateway.SUBMISSIONSRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'classActivitiesRDS',
        endpoint: config.apiGateway.CLASSACTIVITYRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'classesRDS',
        endpoint: config.apiGateway.CLASSRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'classQuizzesRDS',
        endpoint: config.apiGateway.CLASSQUIZZESRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'differentiationRDS',
        endpoint: config.apiGateway.DIFFERENTIATIONRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'interactionsRDS',
        endpoint: config.apiGateway.INTERACTIONRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'likesRDS',
        endpoint: config.apiGateway.LIKESRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'notesRDS',
        endpoint: config.apiGateway.NOTESRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'organizationsRDS',
        endpoint: config.apiGateway.ORGANIZATIONRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'postsRDS',
        endpoint: config.apiGateway.POSTSRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'questionsRDS',
        endpoint: config.apiGateway.QUESTIONRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'quizzesRDS',
        endpoint: config.apiGateway.QUIZZERDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'userClassesRDS',
        endpoint: config.apiGateway.USERCLASSESRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'usersRDS',
        endpoint: config.apiGateway.USERRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'userPreferenceRDS',
        endpoint: config.apiGateway.USERS_PREFERENCE_RDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'schoology',
        endpoint: config.apiGateway.SCHOOLOGY,
        region: config.apiGateway.REGION,
      },
      {
        name: 'schoologyRDS',
        endpoint: config.apiGateway.SCHOOLOGY_RDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'feedbackRDS',
        endpoint: config.apiGateway.FEEDBACK_RDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'logsRDS',
        endpoint: config.apiGateway.LOGSRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'classlinkRDS',
        endpoint: config.apiGateway.CLASSLINKRDS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'emailService',
        endpoint: config.apiGateway.EMAILSERVICE,
        region: config.apiGateway.REGION,
      },
      {
        name: 'pastSubmission',
        endpoint: config.apiGateway.PASTSUBMISSIONS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'bookUnits',
        endpoint: config.apiGateway.BOOKUNITS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'orgPermissions',
        endpoint: config.apiGateway.ORGPERMISSIONS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'resources',
        endpoint: config.apiGateway.RESOURCES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'keystore',
        endpoint: config.apiGateway.KEYSTORE,
        region: config.apiGateway.REGION,
      },
      {
        name: 'clever',
        endpoint: config.apiGateway.CLEVER,
        region: config.apiGateway.REGION,
      },
      {
        name: 'bookmarks',
        endpoint: config.apiGateway.BOOKMARKS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'chat',
        endpoint: config.apiGateway.CHAT,
        region: config.apiGateway.REGION,
      },
      {
        name: 'exams',
        endpoint: config.apiGateway.EXAMS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'instructions',
        endpoint: config.apiGateway.INSTRUCTIONS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'customAssignments',
        endpoint: config.apiGateway.ASSIGNMENT,
        region: config.apiGateway.REGION,
      },
      {
        name: 'googleClassRoom',
        endpoint: config.apiGateway.GOOGLE_CLASSROOM,
        region: config.apiGateway.REGION,
      },
      {
        name: 'canvas',
        endpoint: config.apiGateway.CANVAS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'story',
        endpoint: config.apiGateway.STORY,
        region: config.apiGateway.REGION,
      },
      {
        name: 'apexams',
        endpoint: config.apiGateway.APEXAMS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'howToVideos',
        endpoint: config.apiGateway.HOWTOVIDEOS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'notificationRDS',
        endpoint: config.apiGateway.NOTIFICATION,
        region: config.apiGateway.REGION,
      },
      {
        name: 'licencesRDS',
        endpoint: config.apiGateway.LICENCES,
        region: config.apiGateway.REGION,
      },
      {
        name: 'vocabularyRDS',
        endpoint: config.apiGateway.VOCABULARY,
        region: config.apiGateway.REGION,
      },
      {
        name: 'oneway_teacher_video',
        endpoint: config.apiGateway.ONEWAYTEACHER_VIDEO,
        region: config.apiGateway.REGION,
      },
      {
        name: 'one_game',
        endpoint: config.apiGateway.ONEGAME,
        region: config.apiGateway.REGION,
      },
      {
        name: 'one_game_video',
        endpoint: config.apiGateway.ONE_GAME_VIDEO,
        region: config.apiGateway.REGION,
      },
      {
        name: 'oneway_debate',
        endpoint: config.apiGateway.ONE_WAY_DEBATE,
        region: config.apiGateway.REGION,
      },
      {
        name: 'firebase',
        endpoint: config.apiGateway.FIREBASE,
        region: config.apiGateway.REGION,
      },
      {
        name: 'favourite_video',
        endpoint: config.apiGateway.FAVOURITE_VIDEO,
        region: config.apiGateway.REGION,
      },
      {
        name: 'textToSpeech',
        endpoint: config.apiGateway.TEXT_SPEECH,
        region: config.apiGateway.REGION,
      },
      {
        name: 'checkListRDS',
        endpoint: config.apiGateway.CHECKLIST,
        region: config.apiGateway.REGION,
      },
      {
        name: 'widgetsRDS',
        endpoint: config.apiGateway.WIDGETS,
        region: config.apiGateway.REGION,
      },
      {
        name: 'super_lesson',
        endpoint: config.apiGateway.SUPER_LESSON,
        region: config.apiGateway.REGION,
      },
      {
        name: 'ebook_highlight',
        endpoint: config.apiGateway.EBOOK_HIGHLIGHT,
        region: config.apiGateway.REGION,
      },
      {
        name: 'differentiation_lesson',
        endpoint: config.apiGateway.DIFFERENTIATION_LESSON,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

//  -- Sentry init --------------------
Sentry.init({
  dsn: 'https://b44133e7dac6bf5f0823d71957c5c0a4@o4506743508631552.ingest.sentry.io/4506754519924736',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'nosotrosonline'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !window.location.hostname.includes('localhost'),
});

//  -- Store Configutation ------------
const store = configureStore();
const persistor = persistStore(store);

//  ----------------------------------------------------------------------------
// Router
//  ----------------------------------------------------------------------------
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GoogleOAuthProvider clientId={config.G_CLIENT_ID}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FirebaseProvider>
            <AudioProvider>
              <Router>
                <GlobalStyles />
                <Routes store={store} />
              </Router>
            </AudioProvider>
          </FirebaseProvider>
        </MuiPickersUtilsProvider>
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
