//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import AssessmentCoversCard from 'components/AssessmentCover';
import Section from 'components/Section';
import React, { useEffect  , useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { find, map , get  } from "lodash"
import { useCurrentOrgPermission, useOrgTimezone } from 'store/organizations/selectors';
import { useAlertView } from "store/alert/selectors";
//  -- Constants ----------------------
import { HIGH_ASSESSMENTS, MIDDLE_ASSESSMENTS } from 'utils/config/reference';
import { useApExams } from 'store/apexams/selectors';
import COLOR from 'utils/constants/color';
import { useDemoStatus } from "store/users/selectors";
import Loading from "components/Loading";
import { handleListApExams } from 'store/apexams/thunks';
import { isAfterTime, isBeforTime } from 'utils/methods/math';
import moment from 'moment-timezone';
//  -- Features -----------------------



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const ListItem = styled.li`
    margin: 0px 4% 32px;
    width: calc(21%);

    @media only screen and (min-width: 600px) and (max-width: 800px) {
        margin: 0px 16px 32px;
        width: calc(50% - 32px);
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin: 0px 16px 32px;
        width: 100%;
    }
`
const List = styled.ul`
    height: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content:center;
`;
//   margin-left: 12%;
// width: 75%;
//horizental line with styled-components
const Hr = styled.div`
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 32px;
    width: 100%;
`;



//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const noAssignedMsg = "Not Assigned by Teacher"
const AssessmentContainer = ({
    currentUser,
    classActivities,
    classQuizzes
}) => {
    const history = useHistory();
    const exams = useApExams()
    const orgTimezone = useOrgTimezone()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [state, setState] = React.useState({
        open: false,
        message: ""
    });
    // const isDemo= currentUser?.demo || false
    const demoStatus = useDemoStatus()
    const isReviewAccount = currentUser?.reviewAccount || false
    const isStudent = currentUser?.role === "student" || false
    const [, invokeAlert, revokeALert] = useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    useEffect(() => {
        if (orgPermissions && typeof orgPermissions['exams'] === "boolean") {
            if (!orgPermissions['exams']) {
                invokeAlert()
                setTimeout(() => {
                    history.goBack();
                    revokeALert()
                }, 5000)
            }
        }
    }, [orgPermissions, invokeAlert, revokeALert, history])

    const loadExams = async () => {
        setLoading(get(exams, "length") == 0)
        await dispatch(handleListApExams())
        setLoading(false)
    }
    useEffect(() => {
        loadExams()
    }, [])
    const handleClose = () => {
        setState({ open: false, message: "" });
    };
    const showMsg = (text) => {
        setState({ open: true, message: text });
    };
    const showCopyRight = () => {
        setState({ open: true, message: "Restricted access (legend)" });
    }
    const onCoverCLick = (obj) => {
        const chapter = get(obj,"bookId")

        if (demoStatus || isReviewAccount) {
            showCopyRight()
            return;
        }
        const url = `/ebook?chapter=${chapter}&page=1&activity=null&quiz=null`
        const activitiy = find(classActivities, k => get(k, "data.bookId") == chapter)
        const quiz = find(classQuizzes, k => get(k, "data.bookId") == chapter)
        if(isStudent){
            if (!activitiy && !quiz) {
                showMsg(noAssignedMsg)
                return;
            }else{
                history.push(url); 
            }
        }else{
            const startDate =  get(obj,"startDate")
            const endDate = get(obj,"endDate")
            if (startDate && endDate) {
                if (isAfterTime(startDate, moment(), orgTimezone)) {
                    showMsg(` Available by ${moment(startDate).format("MM/DD/YYYY hh:mm A")}`)
                } else if (isBeforTime(endDate, moment(), orgTimezone)) {
                    showMsg(` Exam is ended on ${moment(endDate).format("MM/DD/YYYY hh:mm A")}`)
                } else {
                    history.push(url);
                }

            } else if (startDate) {
                if (isAfterTime(startDate, moment(), orgTimezone)) {
                    showMsg(` Available by ${moment(startDate).format("MM/DD/YYYY hh:mm A")}`)
                } else {
                    history.push(url);
                }

            } else if (endDate) {
                if (isBeforTime(endDate, moment(), orgTimezone)) {
                    showMsg(` Exam is ended on ${moment(endDate).format("MM/DD/YYYY hh:mm A")}`)
                } else {
                    history.push(url);
                }
            }
            else {
                history.push(url);
            }
        }
    }
    const sortExam = () => {
        if (exams) {
           return exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
        } else {
            return [];
        }
    }
    const { open, message } = state;
    return (
        <Wrapper onContextMenu={(e) => {
            e.preventDefault();
            showCopyRight()
        }}>
            {loading ? (
                <Loading />
            ) : (<>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        <strong>ALERT:</strong> {message}
                    </Alert>
                </Snackbar>
                <Row style={{ marginTop: '1%' }}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Section style={{ fontSize: '18px' }}
                            description="Resources books"
                            header='Resources'>
                            {map(sortExam(), exm => (
                                <List>
                                    {map(exm.exams, (obj, i) => (
                                        <ListItem key={obj.id}>
                                            <AssessmentCoversCard data={obj} handleClick={() => onCoverCLick(obj)} labelBgColor={COLOR.redishpink}   label={get(exm, "displayName")}  />
                                        </ListItem>
                                    ))}
                                </List>
                            ))}
                        </Section>
                    </Col>
                </Row>
                </>)}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentContainer.propTypes = {};

//  -- Default props ------------------
AssessmentContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser,
    userBooksList: state.userBooksList,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
});

export default connect(mapStateToProps, null)(AssessmentContainer);
