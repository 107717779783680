import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import VideoLinks from '../../containers/videoLinks';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinkDropdown from '../../containers/videoLinkDropdown';
import VideoLinkDetailsBanner from '../VideoLinkDetailsBanner';

const ButtonLink = styled.button``;

const classNav = () => {
    return (
        <VideoLinkDropdown />
    );
};

export default function LearnScreen() {
    return (
        <>
            <div className="detailMain">
                <Row gutter={[25, 0]}>

                    <Col xs={24} sm={24} md={6} className="searchMob">
                        <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                            <ButtonLink onClick={e => e.preventDefault()}>
                                Search
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </ButtonLink>
                        </Dropdown>
                    </Col>

                    <Col xs={24} sm={24} md={18}>

                        {/* Satrt Include Banner */}
                            <VideoLinkDetailsBanner />
                        {/* End Include Banner */}
                        
                        <div className="detailInfo">
                            <div><h2>Differentiation</h2></div>
                            <div>
                                <p>Differentiation is understanding that we teach students that are ALL different. Students learn at different pace
                                and  in different ways. The number one differentiation strategy is lowering the affective filter or in simple words
                                creating a learning environment where students trust each other as they learn and as they make mistakes. Next, we have to
                                make sure our instruction is purposeful and students see the real life value of learning specific content. To assess that content,
                                we must provide opportunities for formative assessment where students become accountable for their own learning in individual as well as
                                collaborative settings. Finally, the key element in differentiation, is flexibility. A teacher must understand that an assignment might look
                                different when providing student voice, choice, therefore the biggest asset of differentiation in a classroom is not a book or a tool, is the teacher.
                                We must understand differences in student learning and flexible enough to make input engaging and comprehensible. </p>
                            </div>
                        </div>

                        <div className="detailDiffer detailSpc">
                            <Row gutter={[25, 0]}>
                                <Col xs={24} sm={24} md={24}><h3>Differentiation Tips: </h3></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Get to know your students</label></Col>
                                <Col xs={12} sm={12} md={12}><p>Consistent Teacher to Student Feedback</p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Grouping</label></Col>
                                <Col xs={12} sm={12} md={12}><p>Using Graphic organizers</p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Peer/ Buddy System </label></Col>
                                <Col xs={12} sm={12} md={12}><p>Authentic IPAs</p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Choice Boards  </label></Col>
                                <Col xs={12} sm={12} md={12}><p>Be consistent with your strategies. </p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Peer to peer feedback   </label></Col>
                                <Col xs={12} sm={12} md={12}><p>Analyze formative and summative </p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Project Based Learning  </label></Col>
                                <Col xs={12} sm={12} md={12}><p>assessment results </p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={24} sm={24} md={24} className="OrgLink">
                                    <h4>Place a Link to the Graphic Organizers </h4>
                                    <p><b> - Vivanie Barrios, Los Angeles, California </b></p>
                                </Col>
                            </Row>
                        </div>

                    </Col>

                    <Col xs={24} sm={24} md={6} className="searchWeb">
                        <VideoLinks />
                    </Col>

                </Row>
            </div>

        </>
    );
}
