//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Checkbox, Drawer, FormControlLabel, Modal, Switch } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
//  -- Components ---------------------
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos, ArrowForwardIos, Bookmark, Delete, KeyboardArrowDown, KeyboardArrowDownIcon, KeyboardArrowUp, MenuOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
//  -- Features -----------------------
import EbookTooling from 'features/EbookTooling';
import { filter, find, get, groupBy, map } from 'lodash';
import moment from 'moment';
import { string } from 'prop-types';
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
//  -- Constants ----------------------
import { Col, Menu, Row, Space } from 'antd';
import Loading from 'components/Loading';
import { useDispatch } from 'react-redux';
import { useBookmarks } from 'store/bookmark/selectors';
import { handleCreateBookmarks, handleDeleteBookmarks, handleUpdateBookmarks } from 'store/bookmark/thunks';
import { useDemoStatus } from "store/users/selectors";
import COLOR from 'utils/constants/color';
import { Tooltip } from 'antd';

import { useHyperlinkModal } from 'store/hyperlinkModal/selectors';
import { useTimmerRunning } from 'store/timmer/selectors';

import { Dropdown } from 'antd';
// import { MenuOutlined } from '@ant-design/icons';
import Instructions from "components/Instructions"
import Idea from "features/Idea"
import Vocabilary from "components/Vocabilary"
import VocabilaryForNonSpanish from 'components/VocabilaryNonSpanish';
import MainbookPopup from 'components/MainbookPopup';
import EbookGallleryView, { ViewGallery } from 'components/EbookGallleryView';
import SentenceStarterPopup from 'components/SentenceStarterPopup';
import SuperLessons from 'components/SuperLesson/SuperLesson';
import LessonViewModal from 'components/SuperLesson/LessonViewModal';
import ImageHighlighter from 'components/ImageHighlighter';
import Differentiation from 'components/Differentiation/Differentiation';
import DifferentiationViewModal from 'components/Differentiation/DifferentiationViewModal';
import TwoSidePages from 'components/TwoSideView/TwoSidePages';
import ClickOutside from 'components/OnClickOutside';
const { Item } = Menu;
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const ButtonContainer = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.black600};
    cursor: pointer;
    height: 40px;
    left: ${p => `${p.left}px`};
    outline: none;
    position: absolute;
    right: ${p => `${p.right}px`};
    top: 10px;
    transition: all 300ms ease;
    width: 40px;
    z-index: 1;
    
`;

const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    // max-width: 1268px;
    max-width: 1350px;
    padding: 64px 0px 0px;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 1367px){
        padding: 0px 0px 0px;
    }

    @media (min-width: 997px) {
        flex-direction: row;
        // padding: 0px 30px;
    }
     @media (max-width:1200px) {
        flex-direction:column;
     }
`;

const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: fit-content;
    flex: 1;

    @media only screen and (max-width: 1200px){
        padding-left: 40px;
        padding-right: 40px;
    }
    @media only screen and (max-width: 450px){
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const NavContainer = styled.div`
    display: none;

    @media (min-width: 997px) {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin-right: 8px;
        width: 24px;
    }

    @media (min-width: 1192px) {
       align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        width: 24px;
    }
     @media (min-width: 997px) and (max-width:1366px) {
        display:none;
     }
`;

const NavItemOverflow = styled.div`
    display: inline-table;
    font-size: 0.95em;
    margin-right: 1px;
    margin-top: 1px;
    overflow: hidden;
    transition: width 300ms ease 500ms;
    width: 60px;
        @media (min-width: 997px) and (max-width:1366px) {
            width:100%;
        }
`;

const NavItem = styled.button`
    background-color: ${p => p.active ? COLOR.indigo100 : COLOR.green900};
    border: none;
    border-radius: 2px;
    box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    color: ${p => p.active ? COLOR.white : COLOR.white};
    cursor: pointer;
    /* display: initial; */
    flex-direction: row;
    font-size: 0.85em;
    font-weight: 600;
    margin-bottom: 0px;
    outline: none;
    padding: 8px;
    transition: all 300ms ease;
    border-bottom: 1px solid #ffffff87;
    &:hover {
        box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    }
    
    &:hover ${NavItemOverflow} {
        /*   width: ${p => p.active ? '8px' : '49px'}; */
        width: '49px'};
    }
    @media (max-width: 1366px) {
        background-color: ${p => p.active ? COLOR.indigo100 : '#F6F6F6'};
        color: ${p => p.active ? COLOR.white : 'black'};
    }
`;

const ToolsContainer = styled.div`
    height: 100%;

    width: 100%;
    flex: 1;
    // max-width: 469px;



    @media (max-width: 1200px){
    margin-top: 45px;
    }

    @media (min-width: 1200px){
        // max-width: unset;
            margin-top: 48px;
                max-width: 485px;
    }
    
    @media (min-width: 1200px) {
        margin-left: 11px;
        margin-top: 0px;
        width: 322px;
    }
    @media (min-width: 1200px) {
        // margin-left: 48px;
        margin-left: 16px;
        margin-top: 0px;
        width: 350px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    padding: 5% 0px;
    position: relative;
    width: 100%;
    @media (min-width: 997px) and (max-width:1366px){
        padding-top:20px;
    }
`;
const MainWrapper = styled.div`
    padding-left:24px;
    padding-right:24px;
@media only screen and (max-width: 1200px){
    padding-left:0px;
    padding-right:0px;
}
`
const ItemWrapper = styled.div`
    display: flex;
   flex-direction:row;
  
`;
const ListItem = styled.li`
    list-style-type: none;
    display: flex;
    background-color: ${p => p.active ? COLOR.indigo100 : "#006b6e"};
    border: none;
    border-radius: 2px;
    box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    color: ${p => p.active ? COLOR.white : COLOR.white};
    cursor: pointer;
    /* display: initial; */
    flex-direction: row;
    font-size: 0.75em;
    font-weight: 600;
    margin-bottom: 0px;
    outline: none;
    padding: 8px;
    transition: all 300ms ease;
    justify-content:center;
    text-align:center;
    border-bottom: 1px solid #ffffff47;
    &:hover {
        box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    }

    &:hover ${NavItemOverflow} {
    /*   width: ${p => p.active ? '8px' : '49px'}; */
        width: '49px'};
    }
       @media (max-width: 1366px) {
        background-color: ${p => p.active ? COLOR.indigo100 : '#F6F6F6'};
        color: ${p => p.active ? COLOR.white : 'black'};
    }
`
const List = styled.ul`
    padding: 0px;
    margin-bottom:-10px;
    display: block;
    width: 100%;
`
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const BookmarkButton = styled(Button)`
    margin-right: 4px;
    font-size: 10px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
`
const BookmarkLinkWrapper = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 0px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    display:flex;
    flex-direction:row;
`;
const TrashButton = styled.button`
    padding: 2px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
`;
const MarkButton = styled.button`
    padding: 2px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
`;
const AddBookmarkButton = styled.button`
    // padding: 2px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    background-color:${COLOR.green};
    // padding-right: 10px;
    // padding-left: 5px;
    color: #fff;
    border-radius: 0px;
    font-size: 12px;
    width: 40.8px;
    height: 36.1px;
    padding-right:1px;
`;
const MobileBar = styled.div`
`;
const MobileMenu = styled.div`
`;
const ButtonLink = styled.button`
    border:none;
    background-color:${COLOR.green};
    padding:4px;
    color:white;
    border-radius:8px 8px 0 0;
    padding:6px 8px;
    margin-right:5px;
`;
const IdeaRow = styled.div`
   display: flex !important;
   flex-direction: column !important;
   justify-content: flex-start !important;
`;
const Title = styled.span`
    display: inline-block;
    display: inline-block;
    background-color: #ff682c;
    color: #ffffff;
    font-size: 12px;
    border-radius: 7px;
    padding: 4px 13px 4px 26px !important;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border: none;
    left: 45%;
    outline: none;
    position: absolute;
    top: 10px;
    transition: all 300ms ease;
    z-index: 1;
`;
const BookMarkWrapper = styled.div`
  display: flex !important;
`;
const Wrap = styled.div`
    align-items: center;
    border-bottom: 1px dashed ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 8px 0px;
    flex-wrap:wrap;
`;
const useStyles = makeStyles({
    root: {
        // margin: '5px',
        // width: '175px'
    },
    sizeSmall: {
        // width: '55px',
        // height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(20px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
});
const ImageArea = styled.div`
  width: 100% !important;
  height: 100% !important;
`;
//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook
//
//  -- Description
//  Ebook display component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const EbookLayout = ({
    classUsers,
    activity,
    chapter,
    data,
    page,
    quiz,
    ebookFlag,
    setEbookFlag,
    ebookFlagTeacherEdition,
    setEbookFlagTeacherEdition,
    tips,
    existTeacherEdition,
    existShowAnswer,
    submissions,
    currentUser,
    classActivities,
    classQuizzes,
    lessonPlan,
    SuperLesson,
    DifferentiationLesson,
    rubrics,
    assignmentPlan,
    workbook_plan,
    main_book,
    booksAllList,
    currentClass,
    book,
    dataBook
}) => {
    const [toolsDropdown,setToolsDropdown] = useState(false);
    const [openSuperLessonToolModal, setOpenSuperLessonToolModal] = useState(false);
    const [openDiffernationToolModal, setOpenDiffernationToolModal] = useState(false);
    const [imageConfiguration, setImageConfiguration] = useState({});
    const [showSuperLessonAlert, setSuperLessonAlert] = useState(false);
    const [WarningPrint, setWarningPrint] = useState(false);
    const [removeBookMark, setRemoveBookMark] = useState(false);
    const [CheckTerms, setCheckTerms] = useState(false);
    const [showSpecialSupport, setShowSpecialSupport] = React.useState(false);
    const [studentViewFlag, setStudentViewFlag] = React.useState(false);
    const dispatch = useDispatch()
    const [printLoader, setPrintloader] = useState(false)
    const [, invokeHyperlink,] = useHyperlinkModal()
    const bookHyperlinks = get(find(booksAllList, ['id', chapter]), "hyperlinks")
    const pageHyperlink = get(find(bookHyperlinks, p => p.page == page), "links")
    const is_non_spanish = useMemo(() => !!book?.non_spanish, [book])
    const backBtnRef = useRef(null)
    const forwardBtnRef = useRef(null)
    const history = useHistory();
    const timmerRef = useRef(null)
    const timeIntRef = useRef(0)
    const clearPreviewRef = useRef(null)
    const [activeTab, setActiveTab] = useState(-1)
    const [timeInt, setTimeInt] = useState(0)
    const submitRef = useRef(null)
    const generateUrl = (page) => `/ebook?chapter=${chapter}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
    const demoPages = get(find(booksAllList, ["id", chapter]), "demoPages")
    const chapterActivities = groupBy(classActivities, 'data.chapter');
    const chapterQuizzes = groupBy(classQuizzes, 'data.chapter');
    const demoStatus = useDemoStatus()
    const classBookmark = useBookmarks()
    const bookBookmarks = find(classBookmark, ["bookId", chapter])
    const [bookmarkloader, setbookmarloader] = useState(false)
    const [deleteBookmarkloader, setDeleteBookmarloader] = useState(false)
    const [vocModal, setVocModal] = useState(false);
    const [instModal, setInstModal] = useState(false);
    const [, setTimmerRunning, clearTimmerRunning] = useTimmerRunning()
    const getNumberMask = useCallback((_page) => get(find(get(find(booksAllList, { id: chapter }), "pages"), k => k.pageId == _page), "NumberMasked", _page), [chapter, booksAllList, classBookmark])

    const book_tips = useMemo(() => filter(tips, x => x.bookId == chapter), [chapter]);
    const page_tips = useMemo(() => filter(book_tips, x => x.page == page), [book_tips, page]);

    const [open, setOpen] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState('');
    const [viewLesson, setViewLesson] = useState(false);
    const [viewLessonDifferentiation, setViewLessonDifferentiation] = useState(false);
    const [viewLessonType, setViewLessonType] = useState("")
    const [viewLessonTypeDiff, setViewLessonTypeDiff] = useState("")
    const [superLessonOpen, setSuperLessonOpen] = useState(false);
    const [differentiationOpen, setDifferentiationOpen] = useState(false);
    const classes = useStyles();
    const isAssessmentBook = useMemo(
        () => book && book.is_assessment_book,
        [book]
    );

    const hasSuperLessonPage = SuperLesson
        ? find(SuperLesson, (p) => p.page == page)
        : null;

    const hasDifferentiationLessonPage = DifferentiationLesson
        ? find(DifferentiationLesson, (p) => p.page == page)
        : null;

    const showCopyRight = () => {
        setPopupMessage("Content is protected!")
        setOpen(true);

    }
    const [learnPlanModal, setLearnPlanModal] = useState(false)
    const showAdoption = () => {
        setPopupMessage("Available after adoption")
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };



    const [opens, setOpens] = useState(false);
    const [size, setSize] = useState();
    const showDefaultDrawer = () => {
        setSize('default');
        setOpens(true);
    };
    const showLargeDrawer = () => {
        setSize('large');
        setOpens(true);
    };
    const onClose = () => {
        setOpens(false);
    };


    const getSrcPage = useCallback((page) => {
        let dataPage = find(data?.content, x => x.pageId == Number(page));
        let url = "";
        if (dataPage) {
            if (ebookFlagTeacherEdition && dataPage.teacherEditionPageSrc) {
                url = dataPage.teacherEditionPageSrc
            } else if (ebookFlag && dataPage.answerPageSrc) {
                url = dataPage.answerPageSrc
            } else {
                url = dataPage.src;
            }

        }
        return url;
    }, [data, page, ebookFlagTeacherEdition, ebookFlag])

    const setFlagsFalse = () => {
        setStudentViewFlag(false);
        setShowSpecialSupport(false);
        setEbookFlag(false);
        setEbookFlagTeacherEdition(false);
    }

    const imageRef = useRef();

    const handleImagePrint = () => {
        setWarningPrint(true);
    }
    const handleCheckTerms = (e) => {
        setCheckTerms(e.target.checked);
    }
    const SendCheckTerms = () => {
        if (CheckTerms) {
            setWarningPrint(false);
            validationPrint()
        }
        else {
            setPopupMessage("Acepta los Terminos y Condiciones para Continuar.")
            setOpen(true);
        }
    }
    const validationPrint = useReactToPrint({
        content: () => imageRef.current,
        onBeforeGetContent: () => { setPrintloader(true) },
        onBeforePrint: () => {
            setPrintloader(false);
        }
    });

    useEffect(() => {
        if (demoStatus && demoPages) {
            if (parseInt(page, 10) < demoPages.from || parseInt(page, 10) > demoPages.to) {
                history.goBack()
                setPopupMessage("Restricted access (legend)")
                setOpen(true);
            }
        }
    }, [demoStatus, demoPages, page])
    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", () => { })
        }
    }, [])
    useEffect(() => {
        preloadImage()
    }, [data])
    useEffect(() => {
        if (currentUser?.role != 'student') return;
        try {
            let actAct = find(chapterActivities[chapter], x => (get(x, "data.id") === activity))
            let actqz = find(chapterQuizzes[chapter], x => (get(x, "data.id") === quiz))
            let Actdata = find(submissions, ['assignmentId', get(actAct, "activityId")])
            let qzdata = find(submissions, ['assignmentId', get(actqz, "quizId")])
            let _timmer = 0
            let _durration = 0;
            if (activity && typeof activity === "string" && activity != "null" && activity != "undefined" && actAct) {
                _durration = actAct.timeDuration
                if (Actdata) {
                    if (!Actdata.complete) {
                        _timmer = Actdata.timmer
                    }
                    handleTimmer(_durration, _timmer)
                } else {
                    handleTimmer(_durration, _timmer)
                }

            } else if (quiz && typeof quiz === "string" && quiz != "null" && quiz != "undefined" && actqz) {
                _durration = actqz.timeDuration
                if (qzdata) {
                    if (!qzdata.complete) {
                        _timmer = qzdata.timmer
                        handleTimmer(_durration, _timmer)
                    }
                } else {
                    handleTimmer(_durration, _timmer)
                }
            }

        } catch (error) {
            console.log(error, "errr")
        }

    }, [activity, quiz, classQuizzes, classActivities])
    const handleTimmer = (timeDuration, timer) => {
        try {
            if (timeDuration && !isNaN(parseInt(timeDuration)) && parseInt(timeDuration) > 0) {
                let _timer = parseInt(timer) > 0 ? parseInt(timer) : parseInt(timeDuration) * 60
                if (timmerRef.current) { onFinish() };
                timeIntRef.current = _timer;
                setTimmerRunning()
                timmerRef.current = setInterval(() => {
                    if (timeIntRef.current > 0) {
                        timeIntRef.current = timeIntRef.current - 1
                        let timePrint = moment.utc(timeIntRef.current * 1000).format('HH:mm:ss');
                        setTimeInt(timePrint)
                    } else {
                        if (typeof submitRef.current == "function") {
                            submitRef.current(true)
                            submitRef.current = null
                            onFinish()
                        }
                    }
                }, 1000)
            }
        } catch (error) {
            console.log(error, "error in effect");
        }
        return () => {
            onFinish()
        }
    }
    const onFinish = () => {
        clearInterval(timmerRef.current)
        setTimeInt(0)
        timeIntRef.current = 0
        timmerRef.current = null
        clearTimmerRunning()
    }
    function checkKey(e) {
        let isLessonPlan = document.getElementsByClassName("lessonPlanPopUp").length > 0;
        let isDrawerOpen = document.getElementsByClassName("ant-drawer-open").length > 0;
        let isModalOpen = document.getElementsByClassName("ant-modal-mask").length > 0;
        let isRubrics = document.getElementsByClassName("rubricsPopUp").length > 0;
        let isMainBook = document.getElementsByClassName("main-book-popup").length > 0;
        let bubbleNotes = document.getElementsByClassName("bubbleNotesTopSection").length >0;

        if (isDrawerOpen || isLessonPlan || isRubrics || isModalOpen || isMainBook || bubbleNotes) return;
        e = e || window.event;
        if (e.keyCode == '37') {
            if (backBtnRef.current && !backBtnRef.current.disabled) {
                backBtnRef.current.click()
            }
        }
        else if (e.keyCode == '39') {
            if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                forwardBtnRef.current.click()
            }
        }
    }
    const setSubmitRef = (ref) => {
        submitRef.current = ref
    }
    const preloadImage = () => {
        let cPage = parseInt(page, 10) + 1
        if (cPage + 3 > data?.content?.length) return;
        for (let i = cPage; i < cPage + 5; i++) {
            let url = getSrcPage(i)
            if (url) {
                new Image().src = url
            }
        }
    }


    // left menu
    const RenderMenu = ({ menu, shortName }) => {

        if (Array.isArray(menu)) {
            let firstSubUnitIndex = menu.findIndex(item => Array.isArray(item.subUnits));
            let lastSubUnitIndex = -2;
            map(menu, (item, i) => { if (Array.isArray(item.subUnits)) { lastSubUnitIndex = i } })
            {
                return map(menu, (x, i) => {
                    const isOverflow = x.shortName.length > 15;
                    const displayText = isOverflow ? `${x.shortName.substring(0, 15)}...` : x.shortName;

                    let acco = x.subUnits;
                    let margin_top = firstSubUnitIndex == i ? 0 : 10;
                    if (acco) {
                        return (
                            <div key={x.id + i} className="secondMenuItm">
                                <NavItem
                                    className="ebookCstmItm"
                                    onClick={() => {
                                        history.push(generateUrl(x.start));
                                        setActiveTab(x.id == activeTab ? -1 : x.id);
                                    }}
                                    active={
                                        page >= parseInt(x.start, 10) &&
                                        page <= parseInt(x.finish, 10)
                                    }
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: margin_top,
                                    }}>
                                    <ItemWrapper className="ebookCstmWrap">
                                        {/* <NavItemOverflow>{x.shortName}</NavItemOverflow> */}
                                        <NavItemOverflow>
                                            <Tooltip title={x.shortName} placement="top">
                                                <span>{displayText}</span>
                                            </Tooltip>
                                        </NavItemOverflow>
                                        {x.id == activeTab ? (
                                            <KeyboardArrowUp
                                                style={{ marginTop: -5, marginRight: -5 }}
                                            />
                                        ) : (
                                            <KeyboardArrowDown
                                                style={{ marginTop: -5, marginRight: -5 }}
                                            />
                                        )}
                                    </ItemWrapper>
                                </NavItem>
                                <List className="ebookCstmList">
                                    {x.id == activeTab &&
                                        map(acco, (item, j) => (
                                            <ListItem
                                                active={
                                                    page >= parseInt(item.start, 10) &&
                                                    page <= parseInt(item.finish, 10)
                                                }
                                                key={item.label + j}
                                                onClick={() => history.push(generateUrl(item.start))}
                                                className="ebookCstmLI">
                                                {item.label}
                                            </ListItem>
                                        ))}
                                </List>
                            </div>
                        );
                    } else {
                        return (
                            <NavItem
                                style={{ width: 96, marginTop: lastSubUnitIndex + 1 == i ? 11 : 0 }}
                                key={x.id}
                                active={
                                    page >= parseInt(x.start, 10) &&
                                    page <= parseInt(x.finish, 10)
                                }
                                onClick={() => history.push(generateUrl(x.start))}
                                className="firstMenuItm"
                            >
                                <NavItemOverflow>{x.shortName}</NavItemOverflow>
                            </NavItem>
                        );
                    }
                });
            }
        } else {
            return <></>;
        }
    };

    // const RenderMenuContent = () => {
    //     return (
    //         <div className="secondMenuItm secondMenuItmss">
    //             <NavItem
    //                 className="ebookCstmItm"
    //                 onClick={() => {
    //                     showLargeDrawer();
    //                     // history.push(generateUrl(x.start));
    //                     // setActiveTab(x.id == activeTab ? -1 : x.id);
    //                 }}
    //                 // active={
    //                 // page >= parseInt(x.start, 10) &&
    //                 // page <= parseInt(x.finish, 10)
    //                 // }
    //                 style={{
    //                     flexDirection: "row",
    //                     justifyContent: "center",
    //                     marginTop: 1,
    //                 }}>
    //                 <ItemWrapper className="ebookCstmWrap">
    //                     <NavItemOverflow>{'Contents'}</NavItemOverflow>
    //                 </ItemWrapper>
    //             </NavItem>
    //             <NavItem
    //                 className="ebookCstmItm"
    //                 onClick={() => {
    //                     setInstModal(true);
    //                     // history.push(generateUrl(x.start));
    //                     // setActiveTab(x.id == activeTab ? -1 : x.id);
    //                 }}
    //                 // active={
    //                 // page >= parseInt(x.start, 10) &&
    //                 // page <= parseInt(x.finish, 10)
    //                 // }
    //                 style={{
    //                     flexDirection: "row",
    //                     justifyContent: "center",
    //                     marginTop: 1,
    //                 }}>
    //                 <ItemWrapper className="ebookCstmWrap">
    //                     <NavItemOverflow>{'Recorded'}</NavItemOverflow>
    //                 </ItemWrapper>
    //             </NavItem>
    //             <NavItem
    //                 className="ebookCstmItm"
    //                 onClick={() => {
    //                     setVocModal(true);
    //                     // history.push(generateUrl(x.start));
    //                     // setActiveTab(x.id == activeTab ? -1 : x.id);
    //                 }}
    //                 // active={
    //                 // page >= parseInt(x.start, 10) &&
    //                 // page <= parseInt(x.finish, 10)
    //                 // }
    //                 style={{
    //                     flexDirection: "row",
    //                     justifyContent: "center",
    //                     marginTop: 1,
    //                 }}>
    //                 <ItemWrapper className="ebookCstmWrap">
    //                     <NavItemOverflow>{'Vocubalry'}</NavItemOverflow>
    //                 </ItemWrapper>
    //             </NavItem>
    //         </div>
    //     );
    // };

    const handleCloseSuperLessonToast = () => {
        setSuperLessonAlert(false);
    }

    const handleOpenSuperLessonToast = () => {
        setPopupMessage("Supper Lesson is not available for mobile");
        setOpen(true);
    }
    const handleOpenDifferentiationToast = () => {
        setPopupMessage("Differentiation is not available for mobile");
        setOpen(true);
    }

    const RenderMenuContent = ({ showLargeDrawer }) => {
        return (
            <Menu className="ulLitItme">
                <Item className='ulLitItmeList' onClick={() => {
                    showLargeDrawer();
                }}>Contents</Item>
                {isTeacher && <Item className='ulLitItmeList' onClick={() => {
                    setInstModal(true);
                }}>Recorded</Item>}
                <Item className='ulLitItmeList' onClick={() => {
                    setVocModal(true);
                }}>Vocubalry</Item>
                {isTeacher && <Item className='ulLitItmeList' onClick={handleOpenSuperLessonToast}>Super Lesson</Item>}
                {isTeacher && <Item className='ulLitItmeList' onClick={handleOpenDifferentiationToast}>Differentiation</Item>}
            </Menu>
        );
    };

    const disableBackWrad = () => {
        if (demoStatus) {
            if (demoPages) {
                return parseInt(page, 10) <= demoPages.from
            }
        }
        return parseInt(page, 10) === 1;
    }
    const disableForWard = () => {
        if (demoStatus) {
            if (demoPages) {
                return parseInt(page, 10) >= demoPages.to
            }
        }
        return parseInt(page, 10) === data?.content?.length
    }
    const bookmarkhandler = async () => {
        setbookmarloader(true)
        if (!bookBookmarks) {
            await dispatch(handleCreateBookmarks({
                bookId: chapter,
                page: page,
                userId: get(currentUser, "id", ""),
                classId: get(currentClass, "id", "")
            }))
        } else {
            if (bookBookmarks.page == page) {
                setbookmarloader(false)
                return;
            }
            await dispatch(handleUpdateBookmarks(bookBookmarks.id, page))
        }
        setbookmarloader(false)
    }
    const deleteBookMark = async () => {
        setDeleteBookmarloader(true)
        await dispatch(handleDeleteBookmarks(bookBookmarks.id))
        setRemoveBookMark(false)
        setDeleteBookmarloader(false)
    }
    const getImageRef = () => {
        return imageRef.current;
    }
    const goToBookmark = () => {
        history.push(generateUrl(bookBookmarks.page));
    };
    const getHyperlink = () => {
        let links = null;
        if (pageHyperlink) {
            links = pageHyperlink;
            if (ebookFlagTeacherEdition) {
                links = filter(pageHyperlink, ["type", "teacher_ed"])
            } else {
                links = filter(pageHyperlink, k => k.type !== "teacher_ed")
            }
        }
        return links;
    }
    const isTeacher = get(currentUser, "role") === "teacher";

    const dropdownRef = useRef();

    const handleCloseDropdown = () => {
        if (dropdownRef.current) {
            dropdownRef.current.setVisible(false);
        }
    };

    const setFlagsTeacherEdition = (flag) => {
        setEbookFlagTeacherEdition(flag);
        setEbookFlag(false);
        setStudentViewFlag(false);
    }

    // const setFlagsShowAnswer = (flag) => {
    //     setEbookFlagTeacherEdition(false);
    //     setEbookFlag(flag);
    //     setStudentViewFlag(false);
    // }
    // let existStudentView = false;
    // if (availableActivity.length || availableQuiz.length > 0) {
    //     existStudentView = true;
    // }

    const onLessonView = (val) => {
        setViewLesson(true);
        setViewLessonType(val)
    }

    const onTwoSideChange = (page) => {
        preloadImage()
        if (typeof clearPreviewRef.current == "function") {
            clearPreviewRef.current()
        }
        history.push(generateUrl(parseInt(page, 10)));
        setFlagsFalse();
    }

    const onLessonViewDiff = (val) => {
        setViewLessonDifferentiation(true);
        setViewLessonTypeDiff(val)
    }

 

    const getPageInfo = useCallback(() => {
        const mask_from = getNumberMask(hasSuperLessonPage?.from)
        const mask_to = getNumberMask(hasSuperLessonPage?.to)
        return mask_from && mask_to ? `(P. ${mask_from}-${mask_to})` : ''
      }, [hasSuperLessonPage])

      const getDiffernationPage = useCallback(() => {
        const mask_from = getNumberMask(hasDifferentiationLessonPage?.from)
        const mask_to = getNumberMask(hasDifferentiationLessonPage?.to)

          return mask_from && mask_to ? `(P. ${mask_from}-${mask_to})` : ''
      }, [hasDifferentiationLessonPage])

      const mediaQuery = 1451;

      const DisplaySuperLessonAndDiff = window.innerWidth < mediaQuery && currentUser?.role !== "student" && (hasSuperLessonPage?.from === hasDifferentiationLessonPage?.from)

      const checkSuperLessonAndDiff = (hasSuperLessonPage?.from && currentUser?.role !== "student") ||  (hasDifferentiationLessonPage?.from && currentUser?.role !== "student")

    return (
        <>
            <Modal
                open={WarningPrint}
                onClose={() => setWarningPrint(false)}
            ><ModalContent>
                    Aceptar Terminos y Condiciones de el uso exclusivo de los documentos de One Way Education
                    <ModalRow>
                        <FormControlLabel control={<Checkbox checked={CheckTerms} onChange={handleCheckTerms} />} label="Acepto Terminos y Condiciones" />
                    </ModalRow>
                    <ModalRow>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                SendCheckTerms();
                            }}
                        >
                            Enviar
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Modal
                open={removeBookMark}
                onClose={() => setRemoveBookMark(false)}
            ><ModalContent>
                    Are you sure to remove bookmarks?
                    <ModalRow>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setRemoveBookMark(false)
                            }}
                        >
                            No
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                deleteBookMark()
                            }}
                        >
                            {deleteBookmarkloader ? <Loading size={18} white /> : "Yes"}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {popupMessage}
                </Alert>
            </Snackbar>
            <MainWrapper>
                <Wrapper className="slideLftRghtUpr">
                    <div className="ebookSlideLftRght">
                        <div className="dontShowInDesktop">
                            <Wrap className='ebokToolWrap ebokborder'>
                                {/* <FormControlLabel
                                style={{ minWidth: 85 }}
                                control={<Switch
                                    name="viewTeacherEdition"
                                    color="primary"
                                    classes={{
                                        sizeSmall: classes.sizeSmall,
                                        checked: classes.checkedSwitch
                                    }}
                                    checked={ebookFlagTeacherEdition}
                                    onChange={e => setFlagsTeacherEdition(e.target.checked)}
                                />}
                                label="TE"
                            /> */}
                                {/* {(existShowAnswer || existStudentView) && ( */}
                                {/* <FormControlLabel
                                    style={{ minWidth: 125 }}
    
                                    control={<Switch
                                        color="primary"
                                        classes={{
                                            sizeSmall: classes.sizeSmall,
                                            checked: classes.checkedSwitch
                                        }}
                                        // checked={ebookFlag}
                                        // onChange={e => setFlagsShowAnswer(e.target.checked)}
                                    />}
                                    label="Answers"
                                /> */}
                                {/* )} */}
                                {/* <FormControlLabel
                                style={{ minWidth: 80 }}
                                control={<Switch
                                    color="primary"
                                    classes={{
                                        sizeSmall: classes.sizeSmall,
                                        checked: classes.checkedSwitch
                                    }}
                                    checked={learnPlanModal}
                                    onChange={e => setLearnPlanModal(e.target.checked)}
                                />}
                                label="LP"
                            /> */}

                                {/* <FormControlLabel
                            classes={{
                                root: classes.root,
                                label: classes.label,
                                }}
                                style={{ width: 120 }}
                            control={
                                <Switch
                                    classes={{
                                        sizeSmall: classes.sizeSmall,
                                        checked: classes.checkedSwitch
                                    }}
                                    // checked={ebookFlagTeacherEdition}
                                    // onChange={e => setFlagsTeacherEdition(e.target.checked)}
                                    name="viewTeacherEdition"
                                    color="primary"
                                    size="small"
                                />
                            }
                                label="Answers"
                        /> */}
                                {/* <FormControlLabel
                            classes={{
                                root: classes.root,
                                label: classes.label,
                            }}
                            control={
                                <Switch
                                    classes={{
                                        sizeSmall: classes.sizeSmall,
                                        checked: classes.checkedSwitch
                                    }}
                                    // checked={ebookFlagTeacherEdition}
                                    // onChange={e => setFlagsTeacherEdition(e.target.checked)}
                                    name="viewTeacherEdition"
                                    color="primary"
                                    size="small"
                                />
                            }
                            label="LP"
                        /> */}
                            </Wrap>
                        </div>
                        <div className="arowEbok">
                            <ButtonContainer
                                ref={backBtnRef}
                                disabled={disableBackWrad()}
                                onClick={() => {
                                    setViewLesson(false);
                                    setViewLessonDifferentiation(false);
                                    setDifferentiationOpen(false);
                                    setSuperLessonOpen(false);
                                    history.push(generateUrl(parseInt(page, 10) - 1));
                                    if (typeof clearPreviewRef.current == "function") {
                                        clearPreviewRef.current()
                                    }
                                    // onFinish()
                                    setFlagsFalse();
                                }}
                                left={0} className="cstmLeftArow">
                                <ArrowBackIos style={{ fontSize: '40px' }} />
                            </ButtonContainer>
                            <ButtonContainer
                                ref={forwardBtnRef}
                                disabled={disableForWard()}
                                onClick={() => {
                                    setViewLesson(false);
                                    setViewLessonDifferentiation(false);
                                    setDifferentiationOpen(false);
                                    setSuperLessonOpen(false);
                                    preloadImage()
                                    if (typeof clearPreviewRef.current == "function") {
                                        clearPreviewRef.current()
                                    }
                                    history.push(generateUrl(parseInt(page, 10) + 1));
                                    setFlagsFalse();
                                }}
                                right={0} className="cstmRghtArow">
                                <ArrowForwardIos style={{ fontSize: '40px' }} />
                            </ButtonContainer>
                        </div>
                    </div>
                    <Container style={{ padding: "0" }} className="eBookTabLftUpr">
                        <NavContainer className="eBookTabLft eBookTabWeb">
                            {<RenderMenu menu={data?.structures} />}
                        </NavContainer>
                        {/* <MobileBar className="mobileTabs eBookTabLft eBookTabMob">
                        <MobileMenu className="mobileTabDrop">
                            <Dropdown overlay={<RenderMenu menu={data?.structures} />} trigger={['click']}>
                                <ButtonLink onClick={e => e.preventDefault()}>
                                    <MenuOutlined />
                                </ButtonLink>
                            </Dropdown>
                        </MobileMenu>
                    </MobileBar> */}
                        <ImageContainer onContextMenu={(e) => {
                            e.preventDefault();
                            showCopyRight()
                        }}>
                            {/* <Row style={{ marginTop: -30 }} className='playInsRw'> */}
                            <Row className='playInsRw'>
                                <Col span={21} className='addInsRw' style={{ display: "flex", flexDirection: "row",alignItems: "end" }}>


                                    <MobileBar className="mobileTabs eBookTabLft eBookTabMob scheduledWebHide">
                                        <MobileMenu className="mobileTabDrop">
                                            {/* <Dropdown overlay={<RenderMenuContent />} trigger={['click']}>
                                            <ButtonLink style={{ display: 'flex', alignItems: 'center', borderRadius: '8px 8px 0 0' }}>
                                                <MenuOutlined />
                                            </ButtonLink>
                                        </Dropdown> */}
                                            <Dropdown overlay={<RenderMenuContent showLargeDrawer={showLargeDrawer} />} trigger={['click']}>
                                                <Button style={{ display: 'flex', alignItems: 'center' }} className="BtnMenuOptions">
                                                    <MenuOutlined />
                                                </Button>
                                            </Dropdown>
                                        </MobileMenu>
                                    </MobileBar>
                                    <MobileBar className="mobileTabs eBookTabLft eBookTabMob scheduledWeb">
                                        <MobileMenu className="mobileTabDrop">
                                            {/* <Dropdown> */}
                                            <ButtonLink onClick={() => showLargeDrawer()} style={{ borderRight: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 16, height: 36 }}>
                                                {/* <MenuOutlined /> */}
                                                contents <KeyboardArrowDown />
                                            </ButtonLink>
                                            {/* </Dropdown> */}
                                        </MobileMenu>
                                    </MobileBar>
                                    <Instructions pageId={page} bookId={chapter} instModal={instModal} setInstModal={setInstModal} />
                                    {is_non_spanish ? <VocabilaryForNonSpanish vocabilaryButtonClassName='ebook-vocab-book'  pageId={page} bookId={chapter} /> : <Vocabilary vocabilaryButtonClassName='ebook-vocab-book' pageId={page} bookId={chapter} isCheckingOpen={vocModal} setVocModal={setVocModal} />}
                                        <div style={{position: "relative"}} className='relative '>
                                            {DisplaySuperLessonAndDiff  &&
                                            
                                            (
                                                <Button
                                            onClick={()=> setToolsDropdown(true)}
                                            style={{ minWidth: "unset", height: "36px", borderRadius: "0px !important",display: checkSuperLessonAndDiff ? "block !important" : "none" }}
                                            className="vocabularyBtnDiff scheduledWeb superLessonButton toolButton"
                                            >
                                            Tools
                                            </Button>
                                            )}
                                            

                                        {toolsDropdown && (
                                            <ClickOutside  className='dropdown-tooling-ebook top-59px  box-shadow-dropdown-resource border-radius-14px absolute z-index-990' onClickOutside={()=> setToolsDropdown(false)} >
                                                {hasSuperLessonPage?.from && currentUser?.role !== "student" && (
                                                     <div onClick={()=>setOpenSuperLessonToolModal(true)} className='superlesson-tool'>SuperLesson {getPageInfo()}</div>
                                                )}

                                                    {hasDifferentiationLessonPage?.from && currentUser?.role !== "student" && (
                                                         <div  onClick={()=>setOpenDiffernationToolModal(true)} className='differnation-tool'>Differnation {getDiffernationPage()}</div>
                                                    )}
                                               
                                               
                                            </ClickOutside>
                                        )}
                                        </div>
                                      
                                    {hasSuperLessonPage?.from && currentUser?.role !== "student" && (
                                        <SuperLessons
                                            showModal={hasSuperLessonPage?.from && currentUser?.role !== "student"}
                                            viewLesson={viewLesson}
                                            superLessonData={hasSuperLessonPage}
                                            bookId={chapter}
                                            pageId={page}
                                            setVocModal={setVocModal}
                                            onLessonOpen={onLessonView}
                                            onSuperLessonOpen={(value) => {
                                                setSuperLessonOpen(value);
                                            }}
                                            data={data}
                                            differentiationOpen={differentiationOpen}
                                            from={'ebook'}
                                            openSuperLessonToolModal={openSuperLessonToolModal}
                                            setToolsDropdown ={setToolsDropdown}
                                            setOpenSuperLessonToolModal={setOpenSuperLessonToolModal}
                                            mediaQuery={mediaQuery}
                                            DisplaySuperLessonAndDiff={DisplaySuperLessonAndDiff}
                                        />
                                    )}
                                    {hasDifferentiationLessonPage?.from && currentUser?.role !== "student" && (
                                        <Differentiation
                                            showModal={hasDifferentiationLessonPage?.from && currentUser?.role !== "student"}
                                            viewLesson={viewLessonDifferentiation}
                                            superLessonData={hasDifferentiationLessonPage}
                                            bookId={chapter}
                                            pageId={page}
                                            setVocModal={setVocModal}
                                            onLessonOpen={onLessonViewDiff}
                                            data={data}
                                            superLessonOpen={superLessonOpen}
                                            onDifferentiationOpen={(value) => {
                                                setDifferentiationOpen(value);
                                            }}
                                            from={'ebook'}
                                            openDiffernationToolModal={openDiffernationToolModal}
                                            setToolsDropdown={setToolsDropdown}
                                            mediaQuery={mediaQuery}
                                            setOpenDiffernationToolModal={setOpenDiffernationToolModal}    
                                            DisplaySuperLessonAndDiff={DisplaySuperLessonAndDiff} 
                                        />
                                    )}
                                </Col>
                                <Col span={3} className='addInsRw' style={{ alignItems: "flex-end", justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                                    <ViewGallery pages={data?.content} />
                                    <TwoSidePages pageId={page} getAllPages={data?.content} onTwoSideChange={(val) => onTwoSideChange(val)} onTwoSideButtonClick={() => preloadImage()} />
                                    <BookMarkWrapper>
                                        {isTeacher && <BookmarkLinkWrapper> <AddBookmarkButton
                                            color="primary"
                                            variant="contained"
                                            style={{
                                                marginRight: bookBookmarks ? '4px' : 0, borderTopRightRadius: "8px", borderTopLeftRadius: "8px", marginLeft: "1.5px"
                                            }}
                                            onClick={() => {
                                                bookmarkhandler()
                                            }}
                                        >
                                            {bookmarkloader ? <Loading size={20} white /> : <Bookmark color={get(bookBookmarks, "page", '') == page ? "secondary" : "action"} />}
                                        </AddBookmarkButton></BookmarkLinkWrapper>}
                                        {bookBookmarks ? (<BookmarkLinkWrapper>
                                            <MarkButton onClick={() => {
                                                goToBookmark()
                                            }}>
                                                Pg:{getNumberMask(get(bookBookmarks, "page", ''))}
                                            </MarkButton>
                                            {isTeacher && <TrashButton
                                                onClick={() => {
                                                    setRemoveBookMark(true)
                                                }}
                                            >
                                                <Delete color='secondary' />
                                            </TrashButton>}
                                        </BookmarkLinkWrapper>) : null}
                                    </BookMarkWrapper>

                                </Col>
                            </Row>
                            <EbookGallleryView getRef={getImageRef} onPageSelect={ind => history.push(generateUrl(ind))} />

                            <div ref={imageRef}
                                style={{ position: "relative", zIndex: 0 }}
                            >
                                {
                                    hasSuperLessonPage?.from && currentUser?.role !== "student" &&
                                    <LessonViewModal
                                        superLessonData={hasSuperLessonPage}
                                        showModal={hasSuperLessonPage?.from && currentUser?.role !== "student"}
                                        open={viewLesson}
                                        viewLessonType={viewLessonType}
                                        onClose={() => {
                                            setViewLesson(false)
                                            setSuperLessonOpen(false)
                                        }} data={data}
                                        from={'ebook'}
                                    />
                                }
                                {
                                    hasDifferentiationLessonPage?.from && currentUser?.role !== "student" &&
                                    <DifferentiationViewModal
                                        superLessonData={hasDifferentiationLessonPage}
                                        showModal={hasDifferentiationLessonPage?.from && currentUser?.role !== "student"}
                                        open={viewLessonDifferentiation}
                                        viewLessonType={viewLessonTypeDiff}
                                        onClose={() => {
                                            setViewLessonDifferentiation(false)
                                            setDifferentiationOpen(false)
                                        }}
                                        data={data}
                                        from={'ebook'}
                                    />
                                }
                                <MainbookPopup getRef={getImageRef} />
                                <ImageHighlighter imageUrl={getSrcPage(page)} userId={currentUser.id} pageId={page} bookId={chapter} setImageConfiguration={setImageConfiguration} imageConfiguration={imageConfiguration} />
                                <SentenceStarterPopup getRef={getImageRef} />
                                {/* <Images src={getSrcPage(page)} /> */}
                            </div>

                        </ImageContainer>
                        {/* Right Section */}
                        <ToolsContainer>
                            <EbookTooling
                                classUsers={classUsers}
                                activity={activity}
                                chapter={chapter}
                                history={history}
                                limit={data?.content.length}
                                page={page}
                                quiz={quiz}
                                data={data}
                                showCopyRight={showCopyRight}
                                tips={page_tips}
                                existTeacherEdition={existTeacherEdition}
                                existShowAnswer={existShowAnswer}
                                ebookFlag={ebookFlag}
                                setEbookFlag={setEbookFlag}
                                ebookFlagTeacherEdition={ebookFlagTeacherEdition}
                                setEbookFlagTeacherEdition={setEbookFlagTeacherEdition}
                                showSpecialSupport={showSpecialSupport}
                                setShowSpecialSupport={setShowSpecialSupport}
                                studentViewFlag={studentViewFlag}
                                setStudentViewFlag={setStudentViewFlag}
                                handleImagePrint={handleImagePrint}
                                printLoader={printLoader}
                                setSubmitRef={setSubmitRef}
                                timeInt={timeInt}
                                timmer={timeIntRef.current}
                                onFinish={onFinish}
                                onPageChage={re => clearPreviewRef.current = re}
                                lessonPlan={lessonPlan}
                                rubrics={rubrics}
                                assignmentPlan={assignmentPlan}
                                booksAllList={booksAllList}
                                pageSrc={getSrcPage(page)}
                                book={book}
                                links={getHyperlink()}
                                learnPlanModals={learnPlanModal}
                                setLearnPlanModals={setLearnPlanModal}
                                workbook_plan={workbook_plan}
                                main_book={main_book}
                                dataBook={dataBook}
                                imageConfiguration={imageConfiguration}
                            />
                        </ToolsContainer>
                    </Container>
                </Wrapper>
            </MainWrapper>
            <Drawer
                title="Drawer with extra actions"
                className='unitListDrawer'
                onClose={onClose}
                open={opens}
            >
                <h5 style={{ fontSize: 16, fontWeight: 600, color: 'black', marginLeft: 10, marginTop: 10 }}>All Units</h5>
                <RenderMenu menu={data?.structures} />
            </Drawer>
        </>
    );
};

//  -- Prop types ---------------------
EbookLayout.propTypes = {
    children: string
};

//  -- Default props ------------------
EbookLayout.defaultProps = {
    children: 'Hello world'
};

export default EbookLayout;
