//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const invokeMulticlassPopup = () => ({ type: ActionTypes.multiclassStudent.invokeMulticlassPopup });
export const revokeMulticlassPopup = () => ({ type: ActionTypes.multiclassStudent.revokeMulticlassPopup });
export const disbaleMulticlassCreate = () => ({ type: ActionTypes.multiclassStudent.disableMulticlassCreate});
export const disbaleMulticlassFail = (returnedPayload) => ({ type: ActionTypes.multiclassStudent.disableMulticlassFail, payload: returnedPayload });
export const disbaleMulticlassSuccess = (returnedPayload) => ({ type: ActionTypes.multiclassStudent.disableMulticlassSuccess, payload: returnedPayload });

//  -- List All books ----------------
