import React from "react";

const AnimatedCheckMark = ({checkMarkColor="#fff", backgroundColor="#5cb85c"}) => {
  return (
    <svg id="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle cx="26" cy="26" r="25" fill={backgroundColor} />
      <path fill="none" stroke={checkMarkColor} strokeWidth="5" d="M14 27l8 8 16-16" />
    </svg>
  );
};

export default AnimatedCheckMark;
