//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as Actions from "./actions"



//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.wheelModal;

//  -- Examples array selector --------

export const useWheelModal = () => {
    const wheelModal = useSelector(selector, shallowEqual)
    const dispatch = useDispatch()
    const invokeWheelModal = () => {
        dispatch(Actions.invokeWheelModal())
    }
    const revokeWheelModal = () => {
        dispatch(Actions.revokeWheelModal())
    }
    return [wheelModal, invokeWheelModal, revokeWheelModal];
}

const checkListModalselector = (state) => state.checkListModal;
export const useCheckList = () => {
    const checkListModal = useSelector(checkListModalselector, shallowEqual)
    const dispatch = useDispatch()
    const invokeCheckListView = () => {
        dispatch(Actions.invokeCheckListView())
    }
    const removeCheckListModal = () => {
        dispatch(Actions.revokeCheckListModal())
    }
    return [checkListModal, invokeCheckListView, removeCheckListModal];
}

const trafficSelector = (state) => state.trafficModal;
export const useTraffic = () => {
    const trafficModal = useSelector(trafficSelector, shallowEqual)
    const dispatch = useDispatch()
    const invokeTrafficView = () => {
        dispatch(Actions.invokeTrafficView())
    }
    const removeTrafficModal = () => {
        dispatch(Actions.revokeTrafficModal())
    }
    return [trafficModal, invokeTrafficView, removeTrafficModal];
}

const noiseModalselector = (state) => state.noiseModal;
export const useNoiseMeter = () => {
    const noiseModal = useSelector(noiseModalselector, shallowEqual)
    const dispatch = useDispatch()
    const invokeNoiseModal = () => {
        dispatch(Actions.invokeNoiseModal())
    }
    const removeNoiseModal = () => {
        dispatch(Actions.revokeNoiseModal())
    }
    return [noiseModal, invokeNoiseModal, removeNoiseModal];
}
