import React , {useRef}from "react"
import {  MicOutlined } from "@material-ui/icons"
import {  Button } from "@material-ui/core"
import styled from 'styled-components';
import { useComponentVisible } from "./hooks";
import COLOR from 'utils/constants/color';
import AudioRecorder from "features/AudioRecorder"

const AudioRecorderButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`;
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: ${p=>p.bottom?p.bottom:"60px"};
  overflow: visible;
  width: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 51%) 20px 20px 60px;
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
`
export default function Audio({ onRecorderDone , bottom="60px", hasBoxShadow=false}) {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);
  const recorder = useRef()
    return (
        <Wrapper>
            {isComponentVisible && (
                <EmojiWrapper  ref={ref} bottom={bottom}>
                     <AudioRecorder  
                     wrapperStyle={{borderBottom:'0px'}}
                       message={`To begin your recording click the "Record" button below.`}
                       doneButton={<Button onClick={() =>setIsComponentVisible(false)} 
                       style={{ marginLeft: '8px' }}>Done</Button>}
                       ref={recorder} 
                       handleAudio={onRecorderDone} />
                </EmojiWrapper>
            )}
            <AudioRecorderButton
                onClick={() => setIsComponentVisible(true)}
            >
               <MicOutlined  style={{ color: COLOR.lightseagreen200 }} />
            </AudioRecorderButton>
        </Wrapper>
    );
}
