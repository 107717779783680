//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep,find } from 'lodash';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const classUsersInitial = [];
const userClassesInitial = [];

export const classUsers = (state = classUsersInitial, action) => {
    switch (action.type) {
        case ActionTypes.userClasses.listClassUsersSuccess:
            return action.payload;
        case ActionTypes.userClasses.createUserClassSuccess:
        case ActionTypes.users.registerStudentSuccess:
            return [action.payload, ...state];
        case ActionTypes.userClasses.deleteUserClassSuccess:
            return state.filter(x => x.ucId !== action.payload);
        case ActionTypes.users.updateUserSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload };
                }
                return x;
            });
        case ActionTypes.userClasses.listClassUsersFail:
        case ActionTypes.userClasses.createUserClassFail:
        case ActionTypes.userClasses.deleteUserClassFail:
        case ActionTypes.users.registerStudentFail:
        case ActionTypes.users.updateUserFail:
        default:
            return state;
    }
};

export const userClasses = (state = userClassesInitial, action) => {
    switch (action.type) {
        case ActionTypes.userClasses.listUserClassesSuccess:
            return action.payload;
        case ActionTypes.userClasses.createUserClassSuccess:
            return [action.payload, ...state];
        case ActionTypes.classes.updateClassSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload };
                }
                return x;
            });
        case ActionTypes.userClasses.deleteUserClassSuccess:
            return state.filter(x => x.id !== action.payload);
        case ActionTypes.userClasses.updateClassConfig:
            const dt = cloneDeep(state)
            const result = dt.map(x => {
                if (x.id === action.payload.classId) {
                    return ({ ...x, config: action.payload });
                } else {
                    return ({ ...x, config: x.config ? x.config : {} })
                }
            });
            return result;
        case ActionTypes.userClasses.updateUserClassesOrder:
            const dtr = cloneDeep(state)
            const results = dtr.map(x => {
                const has_order = find(action.payload, ['id', x.id])
                if (has_order) {
                    return ({ ...x, order: has_order.order });
                }
                return x;
            });
            return results;
        case ActionTypes.userClasses.listUserClassesFail:
        case ActionTypes.userClasses.createUserClassFail:
        case ActionTypes.userClasses.deleteUserClassFail:
        default:
            return state;
    }
};

export const usersByClass = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.userClasses.setUsersByClass:
            return action.payload;
        case ActionTypes.userClasses.updateUsersByClass:
            return action.payload;
        case ActionTypes.userClasses.clearUsersByClass:
            return {};
            default:
                return state;
        }
}