import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import { Row } from "antd";
import AccordinButton from "components/AccordinButton";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  get,
  has,
  map,
  shuffle,
} from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useUsersByClass } from "store/userClasses/selectors";
import { useCurrentUser } from "store/users/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import { WriteEditWhite } from "utils/assets/images";
import COLOR from "utils/constants/color";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { generateRandomLightColor } from "utils/methods/math";
import { v4 } from "uuid";
import NewWheelComponent from "../NewWheel";
import { useUserWidgetsConfig } from "utils/methods/firebaseUserWidgets";
import { useFirebase } from "containers/FirebaseContext";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import DemoPreview from "components/ViewDemoModal";
import Loading from "components/Loading";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
  //   margin: 15px auto;/
  transform: translate(31%, 0%);

  @media (max-width: 1450px) {
    // width: 96%;
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    // width: 95%;
    transform: translate(0%, 0%);
  }
`;
const EditListOption = styled.div`
  text-align: center;
  align-items: center;
  background-image: linear-gradient(
    90deg,
    ${COLOR.WheelEditColor1} 0%,
    ${COLOR.WheelEditColor2} 40%,
    ${COLOR.WheelEditColor3} 100%
  );
  color: #fff;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
`;
const IconsImg = styled.img`
  height: 15px;
  width: 15px;
  object-fit: cover;
  margin-left: 10px;
`;
const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const COLORS = [
  COLOR.PinkExam,
  COLOR.OrangeExam,
  COLOR.green,
  COLOR.radish100,
  COLOR.blueish100,
  COLOR.blueishdeep,
  COLOR.pink100,
  COLOR.sky100,
  COLOR.indigo100,
  COLOR.lightseagreen100,
  COLOR.yellow,
  COLOR.blue,
  COLOR.red,
  COLOR.redishpink,
  COLOR.greentrue,
  COLOR.tabActive,
  COLOR.bluelight,
  COLOR.WheelEditColor1,
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "25px",
    width: "300px",
  },
  rootDif: {
    width: "200px",
  },
  rootDifFull: {
    width: "100%",
  },
  sizeSmall: {
    width: "55px",
    height: "25px",
  },
  checkedSwitch: {
    transform: "translateX(30px) !important",
  },
  label: {
    textTransform: "uppercase",
  },
  colorPrimary: {
    color: `${COLOR.green}!important`,
  },

  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    minWidth: 120,
  },
  formControlStudent: {
    backgroundColor: "#fff",
    width: "400px",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));
const MultiMenu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: () => null,
};

const MultiSelecter = ({ options, onSelect, defaultValue, onListChange,setUser,loading, setDoneLoading, setStudentSelectModal }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;
  const [list, setList] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  
  useEffect(() => {
    if(defaultValue?.length > 0){
      setSelected(defaultValue);
    }
    else{
      // setSelected(options);
      setList([]);
    }
 
  }, [defaultValue]);

  const onDone = () => {
    onSelect(selected, 'create');
  };

  const onChange = (txt, i, key) => {
    let _lst = cloneDeep(list);
    _lst[i][key] = txt;
    setList(_lst);
  };
  const onRemove = (i) => {
    let _lst = cloneDeep(list);
    _lst.splice(i, 1);
    setList(_lst);
  };
  const addNew = () => {
    let _lst = cloneDeep(list);
    _lst.push({ firstName: "", lastName: "", id: list.length });
    setList(_lst);
  };
  const onDoneAddItem = () => {
    let _lst = filter(list, (lst) => lst.firstName || lst.lastName).map(
      (item) => `${item.firstName}  ${item.lastName}`
    );
    if (_lst.length > 0) {
      setUser([...selected, ..._lst ]);
      onListChange(_lst);

      onSelect([...selected, ..._lst], true);
      setList([]);
    } else {
    }
  };
  return (
    <FormControl variant="outlined" className={classes.formControlStudent}>
      {map(list, (lst, i) => (
        <ModalRow style={{ justifyContent: "space-between" }}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            style={{ width: 150, color: "white" }}
            InputLabelProps={{ shrink: true, required: true }}
            inputProps={{ shrink: true, required: true }}
            variant="outlined"
            onChange={(e) => {
              const txt = e.target.value;
              onChange(txt, i, "firstName");
            }}
            value={lst.fistName}
          />
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            style={{ width: 150, color: "white" }}
            InputLabelProps={{ shrink: true, required: true }}
            inputProps={{ shrink: true, required: true }}
            variant="outlined"
            onChange={(e) => {
              const txt = e.target.value;
              onChange(txt, i, "lastName");
            }}
            value={lst.lastName}
          />
          <IconButton
            color="secondary"
            onClick={() => {
              onRemove(i);
            }}
          >
            <Delete />
          </IconButton>
        </ModalRow>
      ))}
      {/* <ModalRow>
        <Button color="primary" variant="contained" onClick={addNew}>
          <Add /> Add student
        </Button>
      </ModalRow> */}
      <ModalRow>
        <Button color="primary" variant="contained" onClick={addNew}>
          <Add /> Add student
        </Button>
        <Row>
          {selected?.length === 0 && (
             <Button
             style={{ marginRight: "4px" }}
             onClick={() => {
              setStudentSelectModal(false);
             }}
           >
             Cancel
           </Button>
          )}
         
          <Button
            disabled={loading || selected?.length === 0 }
            color="primary"
            variant="contained"
            onClick={async () => {
              setDoneLoading(true);
              if (!list.length) {
                onDone();
              } else {
                onDoneAddItem();
              }
            }}
          >
          {loading ? <Loading white={true} /> : "Done"}
            
          </Button>
        </Row>
      </ModalRow>
      <FormControl variant="outlined" className={classes.formControlStudent}>
        <InputLabel
          id="mutiple-select-label"
          style={{ backgroundColor: "#fff" }}
        >
          Select students
        </InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => (
            <div className={classes.chips}>
              {/* { `${isAllSelected||selected.length==0?"All students":"Students("+selected.length+")"}`} */}
              <Chip
                label={`${isAllSelected || selected.length == 0
                  ? "All students"
                  : "Students(" + selected.length + ")"
                  }`}
                className={classes.chip}
              />
            </div>
          )}
          MenuProps={MultiMenu}
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
          {map(options, (option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox
                  checked={Boolean(find(selected, (k) => k === option))}
                />
              </ListItemIcon>
              <ListItemText primary={`${option}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormControl>
  );
};

const WidgetWheel = ({ currentClass, sharableWidget }) => {
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const { firestore } = useFirebase();
  const currentUser = useCurrentUser();
  const usersByClass = useUsersByClass();
  const { createCollectionWidgets } = useCollectionWidgets();
  const { createUserWidgetsConfig } = useUserWidgetsConfig();
  const [result, setResult] = useState("");
  const [showAccordin, setShowAccordin] = useState(true);
  const [usersList, setUsers] = useState([]);
  const [segments, setSegments] = useState([]);
  const [segColors, setSegColors] = useState([]);
  const [dimensions, setDimensions] = useState({});
  const [studentSelectModal, setStudentSelectModal] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  

  const isTeacher = get(currentUser, "role") === "teacher";
  const isStudent = get(currentUser, "role") === "student";
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "widget_wheel"
  );
  const filterShareWheel = find(
    sharableWidget,
    (obj) => obj?.widget_type === "widget_wheel"
  );

  useEffect(() => {
    if (usersByClass && has(currentClass, "id")) {
      const usr = map(
        usersByClass[currentClass.id],
        (k) => `${k.firstName || ""} ${k.lastName || ""}`
      ); 
      setUsers(usr);
    }
  }, [usersByClass, currentClass, filterByWidget, filterShareWheel]);

  const newStudents = useMemo(() => {
    return segments.map(fullName => {
      return fullName;
    });
  }, [segments]);

  const newStudentList = useMemo(()=>{
    if(usersList?.length > 0) {
      const data = newStudents?.map(data => {
        if(!usersList?.includes(data)){
          return data;
        }
        return;
      }).filter(k => k!== undefined);

      if(data?.length !==0){
        return [...usersList, ...data]
      }
      else{
        return usersList;
      }
    }
  },[newStudents,usersList]);



  const fetchSegments = () => {
    if (
      !filterByWidget?.view &&
      filterByWidget?.menu !== "widget_wheel" &&
      !filterShareWheel?.id
    ) {
      setSegments([]);
      setSegColors([]);
      setResult("");
    } else {
      if (has(currentClass, "id")) {
        setSegments(shuffle(shuffle(usersList)));
        const segColors = map(
          usersList,
          (k, i) => `${COLORS[i] ? COLORS[i] : generateRandomLightColor()}`
        );
        setSegColors(segColors);
        setResult("");
      } else {
        setSegments([]);
        setSegColors([]);
        setResult("");
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    const unsubscribe = listenToWidgetUserConfig(get(currentClass, 'id', ''));
    return () => {
      unsubscribe();
    };
  }, [firestore, currentClass, currentUser, filterByWidget, usersList, filterShareWheel])

  const listenToWidgetUserConfig = (classID) => {
    if (!classID || !currentUser || filterByWidget?.menu !== "widget_wheel") {
      return () => { };
    }
    const fifteenHoursAgo = new Date();
    fifteenHoursAgo.setHours(fifteenHoursAgo.getHours() - 12);
    const collectionRef = collection(firestore, `widgets_user_config/${classID}/widgets`);
    const q = query(collectionRef,
      where("id", "==", 'widget_wheel'),
      where("modified_on", ">=", fifteenHoursAgo)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let sharableData = null;
      querySnapshot.forEach((doc) => {
        sharableData = { id: doc.id, ...doc.data() };
      });
      if (sharableData) {
        setSegments(sharableData?.user_data || []);
        setStudentSelectModal(false);
        if (!sharableData?.user_data?.includes(result)) {
          setResult("");
        }
        const segColors = map(
          [...sharableData?.user_data],
          (k, i) => `${COLORS[i] ? COLORS[i] : generateRandomLightColor()}`
        );
        setSegColors(segColors);
      } else {
        fetchSegments();
      }
    }, (error) => {
      console.error("Error listening to widgets user config changes:", error);
    });

    return unsubscribe;
  };


  const shareWheel = () => {
    const shareData = {
      id: v4(),
      class_id: currentClass?.id,
      created_by: currentUser?.id,
      created_at: new Date(),
      widget_type: "widget_wheel",
      status: true,
      data: [],
    };
    createCollectionWidgets(shareData);
  };

  const onClose = async () => {
    setStudentSelectModal(false);
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    setStudentSelectModal(false);
    setResult("");
  };

  const onFinished = (winner) => {
    if (segments.length > 2) {
      const fIndex = findIndex(segments, (s) => s !== winner);
      const _sg = filter(segments, (s) => s !== winner);
      if (fIndex > -1) {
        const _sgColor = [...segColors];
        _sgColor.splice(fIndex, 1);
        setSegColors(_sgColor);
      }
      setTimeout(() => {
        setSegments(_sg);
      }, 1000);
    }
    setResult(winner);
  };

  const getSegments = useCallback(() => {
    const newSegments = map(segments, (k, i) => ({ color: segColors[i], label: k })) || [];
    return newSegments;
  }, [segments, segColors]);

  const handleTouch = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchEdit = (event) => {
    event.preventDefault();
    setStudentSelectModal(true);
  };




  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const SendOnFirebase = async (value) => {
    try{
      const WidgetsData = {
        id: 'widget_wheel',
        modified_on: new Date(),
        user_data: value
      }
      await createUserWidgetsConfig(WidgetsData, currentClass?.id)
      
    }catch(error){
      console.log(error);
    }
    finally{
      setDoneLoading(false);
    }

  }

  return (
    <div div style={{
      position: "relative",
      zIndex: 1000
    }} >
      {((filterByWidget?.view && filterByWidget?.menu === "widget_wheel") ||
        filterShareWheel?.id) && (
          <Container className="allCheckListSEction wigetsContainer">
            <div className="mainCheckList">
              <ResizeAndDragabble
                defaultValue={{
                  x: 150,
                  y: 205,
                  width: windowSize?.width >= 768 ? 501 : 270,
                  height: windowSize?.width >= 768 ? 600 : 323,
                }}
                // minWidth={430}
                minHeight={!showAccordin ? null : 323}
                showAccordin={showAccordin}
                resize={(data) => setDimensions(data)}
                resizeEnd={(data) => setDimensions(data)}
              >
                <div
                  className="checkListTopSection"
                  style={{
                    position: "relative",
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                    height: showAccordin ? "100%" : "0px",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0px",

                  }}
                >
                  <CancelIcon
                    onClick={() => onClose()}
                    onTouchStart={handleTouch}
                    style={{
                      zIndex: 1,
                      marginLeft: "5px",
                      position: "absolute",
                      right: 11,
                      top: 14,
                      fontSize: 20,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                  <div className={`checkListFirstTxt absolute top-0 w-full bg-blue-10 pt-17px pb-14px ${showAccordin ? "br-topLeft-topRight-20px" : "br-20px"
                    } `}>
                    <AccordinButton open={showAccordin} onClose={() => setShowAccordin(prev => !prev)} />
                    <span className="text-white text-ft12-700">Wheel</span>
                  </div>
                  {showAccordin && (
                    <>
                      <div className="mt-49px">
                        <NewWheelComponent
                          dimensions={dimensions}
                          segments={getSegments()}
                          onFinished={onFinished}
                        />
                      </div>
                      <div className="widget-wheel-text">{result}</div>
                      {isTeacher && (
                        <div className={'add-preview-wheel'}>
                          <EditListOption
                            onClick={() => {
                              setStudentSelectModal(true)
                            }}
                            onTouchStart={handleTouchEdit}
                          >
                            Edit <IconsImg src={WriteEditWhite} />
                          </EditListOption>
                          <DemoPreview video_id={"1way-wheel-demo"} wrapperStyle={{ justifyContent: "flex-end", marginLeft: '5px' }} />
                        </div>
                      )}
                    </>
                  )}


                </div>
              </ResizeAndDragabble>
            </div>
          </Container>
        )}
      {studentSelectModal && (
        <div style={{ marginTop: "20px" }}>
          <Modal open={studentSelectModal}>
            <ModalContent>
              <MultiSelecter
                segements={segments}
                options={newStudentList}
                onListChange={(items) => {
                  setUsers([...usersList, ...items]);
                }}
                onSelect={async (value, from) => {
                  try{
                    if (from === 'close') {
                      setStudentSelectModal(false);
                      setDoneLoading(false);
                    } else {
                      SendOnFirebase(value);
                    }
                  }catch(err){
                    console.log(err);
                  }
                }}
                defaultValue={segments}
                setUser={setUsers}
                loading={doneLoading}
                setDoneLoading={setDoneLoading}
                setStudentSelectModal={setStudentSelectModal}
              />
            </ModalContent>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default WidgetWheel;
