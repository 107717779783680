//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const createStory = (returnedPayload) => ({ type: ActionTypes.story.createStorySuccess , payload: returnedPayload  });
export const updateStory = (returnedPayload) => ({ type: ActionTypes.story.updateStorySuccess, payload: returnedPayload });
export const listStory = (returnedPayload) => ({ type: ActionTypes.story.listStorySuccess , payload: returnedPayload });
export const deleteStory = (id) => ({ type: ActionTypes.story.deleteStorySuccess, payload:id });

export const setStandards = (returnedPayload) => ({ type: ActionTypes.story.setStandardsSuccess , payload:returnedPayload });
export const setNewStandards = (returnedPayload) => ({ type: ActionTypes.story.createStanderdsSuccess , payload:returnedPayload });
export const setUpdatedStandards = (returnedPayload) => ({ type: ActionTypes.story.updateStanderdsSuccess , payload:returnedPayload });
export const setDeleteStandards = (returnedPayload) => ({ type: ActionTypes.story.deleteStanderdsSuccess , payload:returnedPayload });
//  -- List logs ----------------

export const setOverallLiteracyPurpose = (returnedPayload) => ({ type: ActionTypes.literacy_purpose.setOverAll , payload:returnedPayload });