//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create Notification ----------

export const setPlayGameModal = (returnedPayload) => ({ type: ActionTypes.game.set_player_modal, payload: returnedPayload });
export const setOpenOneGame = (returnedPayload) => ({ type: ActionTypes.game.set_one_game_modal, payload: returnedPayload });
export const setClassGameActivity = (returnedPayload) => ({ type: ActionTypes.game.set_class_game_activity, payload: returnedPayload });
export const clearClassGameActivity = () => ({ type: ActionTypes.game.clear_class_game_activity });