//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- Update user --------------------
export const setIdea = (returnedPayload) => ({ type: ActionTypes.idea.setIdea, payload: returnedPayload });
export const updateIdea = (returnedPayload) => ({ type: ActionTypes.idea.updateIdea, payload: returnedPayload });
export const clearIdea = () => ({ type: ActionTypes.idea.clearIdea });



export const setIdeaModal = (returnedPayload) => ({ type: ActionTypes.idea.setIdeaModal, payload: returnedPayload });