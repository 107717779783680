//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import axios from "axios"
import { API } from 'aws-amplify';
import moment from 'moment'
import { get , has } from "lodash"
import  configs from '../../config';
import {setGoogleAuth, updateGoogleAuth } from "./actions"
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List resources ----------------


//  -- List resources ----------------


export const getRefreshToken = async (code) => {
  const token = await API.post("googleClassRoom", "/getRefreshToken", {
    body: {
      code,
      "redirect_uri":document.location.origin
    }
  })
  return token;
}


export const handleGooleClassRoomUserRegistration = async (key) => {
  try {
    return API.post("googleClassRoom", "/handleUser", {
      body: {
        "access_token": key
      }
    })
  } catch (error) {
    return null;
  }

}
export const handleGoogleUserClasses = async (id) => {
  try {
    return API.post("googleClassRoom", "/handleUserCourses", {
      body: {
        googleId:id
      }
    })
  } catch (error) {
    return null;
  }

}
export const handleGoogleClassRoomStudents = async (id,classId) => {
  try {
    return API.post("googleClassRoom", "/handleStudent", {
      body: {
        googleId:id,
        classId
      }
    })
  } catch (error) {
    return null;
  }

}
export const handleUpdateGradeToGoogleClassRoom = async ( teacherGoogleId, studentGoogleId, googleCourseId, assignmentId, grade ) => {
  try {
    return API.put("googleClassRoom", "/updateGrade", {
      body: {
        teacherGoogleId, 
        studentGoogleId, 
        googleCourseId, 
        assignmentId, 
        grade
      }
    })
  } catch (error) {
    return null;
  }

}

export const handleCreateGoogleAssignment = async (googleId, assignment,courseId) => {
  try {
    return API.post("googleClassRoom", "/createAssignment", {
      body: {
        googleId,
        assignment,
        googleClassId:courseId
      }
    })
  } catch (error) {
    return null;
  }
}
export const handleDeleteGoogleAssignment = async (googleId, courseId, assignmentId) => {
  try {
    return API.post("googleClassRoom", "/deleteAssignment", {
      body: {
        googleId,
        assignmentId,
        googleClassId:courseId
      }
    })
  } catch (error) {
    return null;
  }
}
export const handleUpdateGoogleAssignment = async (googleId, assignment,courseId, assignmentId) => {
  try {
    return API.put("googleClassRoom", "/updateAssignment", {
      body: {
        googleId,
        assignment,
        assignmentId,
        googleClassId:courseId
      }
    })
  } catch (error) {
    return null;
  }
}
export const handleSyncClassworkGeades = async (googleId,classId,googleCourseId) => {
  try {
    return API.put("googleClassRoom", "/synAssignmentGrade", {
      body: {
        googleId,
        classId,
        googleCourseId
      }
    })
  } catch (error) {
    return null;
  }
}
export const handleSyncClassworkAssignment = async (googleId,classId) => {
  try {
    return API.post("googleClassRoom", "/syncClassAssignment", {
      body: {
        googleId,
        classId
      }
    })
  } catch (error) {
    return null;
  }
}
export const handleUserByGoogleName = async (name) => {
  if(typeof name!="string") return [];
  try {
    const firstName = name.replace(/\s\s+/g, ' ').split(" ")[0].trim();
    const lastName = name.replace(/\s\s+/g, ' ').split(" ")[1]?.trim();
      const user = await API.post("googleClassRoom", `/listUserByName`,{
        body:{
          firstName,
          lastName
        }
      })
      return user;

  } catch (error) {
      return []
  }
}
export const handleUserByGoogleId= async (googleId) => {
  if(!googleId) return [];
  try {
      const user = await API.post("googleClassRoom", `/getUserInfoById`,{
        body:{
          googleId
        }
      })
      return user;

  } catch (error) {
      return []
  }
}

