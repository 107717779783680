//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { MenuOutlined } from '@ant-design/icons';
import { Button, Modal, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Edit } from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Drawer, Dropdown, Menu, Row, Tooltip } from 'antd';
import { Auth } from "aws-amplify";
import CotationLoader from 'components/CotationLoader';
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
import Section from 'components/Section';
import SentryWrap from 'components/Sentry';
//  -- Features -----------------------
import AdminAnnouncements from 'features/AdminAnnouncements';
import AdminVisuals from 'features/AdminVisuals';
import ChapterManagementBook from 'features/ChapterManagementBook';
import ChapterManagementNew from 'features/ChapterManagementNew';
import Gradebook from 'features/Gradebook';
import ActivityForm from 'features/forms/Activity';
import ClassForm from 'features/forms/Class';
import ClassFormDistrict from 'features/forms/ClassDistrict';
import QuizForm from 'features/forms/Quiz';
import _, { filter, find, get, has, map , uniqBy } from "lodash";
import queryString from 'query-string';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleListClassSubmissions, handleUpdateSubmission } from 'store/submissions/thunks';
//  -- Thunks -------------------------
import { handleListActivities, handleListCustomAssignment } from 'store/activities/thunks';
import { handleCreateClassActivity, handleDeleteClassActivity, handleListClassActivities, handleUpdateClassActivity } from 'store/classActivities/thunks';
import { handleCreateClassQuiz, handleDeleteClassQuiz, handleListClassQuizzes, handleUpdateClassQuiz } from 'store/classQuizzes/thunks';
import { getClassBySectionId, handleCheckCode, handleCreateClass, handleDeleteClassData, handleGetClass, handleGetClassByCode, handleUpdateClass } from 'store/classes/thunks';
import { sendWelcomeEmailForSchoology } from "store/email/thunks";
import { handleListBubbleClassNotes } from 'store/notes/thunks';
import { handleListOrgsByParent } from 'store/organizations/thunks';
import { handleListClassQuestions } from 'store/questions/thunks';
import { handleListQuizzes } from 'store/quizzes/thunks';
import { handleListUserBooks } from 'store/userBooks/thunks';
import { handleCreateUserClass, handleListClassUsers, handleListUserByClassForTeacher, handleListUserClasses } from 'store/userClasses/thunks';
import { handleGetUserPreference } from 'store/userPreference/thunks';
import { handleCreateUser, handleListOrgUsers, handleUpdateNewUser } from 'store/users/thunks';
import styled from 'styled-components';
import { IntroVideo, IntroVideoPoster, locationImg } from "utils/assets/images";
//  -- Constants ----------------------
import AssignmentList from "components/AssignmentLists";
import moment from 'moment-timezone';
import { handleListClassBookmarks } from 'store/bookmark/thunks';
import { handeleCanvasUserClasses, handleCreateCanvasAssignment, handleDeleteCanvasAssignment, handleSyncGrade, handleUpdateCanvasAssignment , handleListCanvasAssignmentCat , handleSyncAssignment} from "store/canvas/thunks";
import { useSocket } from 'store/chat/selectors';
import { handleInvokeFeedbackForm } from 'store/feedback/thunks';
import { handleListInstructionByUser } from "store/instructions/thunks";
import { handleCreateNotification } from 'store/notification/thunks';
import { handleDeleteUserFromOrganization } from 'store/organizations/thunks';
import { handleSyncSchoologyClasses , handleSyncSchoologyClassesGrades  } from 'store/schoology/thunks';
import { useSchoologyAssignment, useSchoologyGradeCategory } from "store/schoologyauth/selectors";
import { handleSetSchoologyAuth } from 'store/schoologyauth/thunks';
import Schoology from 'utils/Schoology';
import COLOR from 'utils/constants/color';
import { modifyAssignmentWithDiff } from 'utils/lib/ebookHelper';
import { validateQuizeActivityData } from "utils/methods/createQuizeActivityValidator";
import { getRandomCotation } from "utils/methods/math";
import { handleCreateGoogleAssignment , handleUpdateGoogleAssignment , handleDeleteGoogleAssignment } from 'store/googleClassRoom/thunks';
import WelcomeVideo from 'components/WelcomeVideo';
import { handleSyncClassworkAssignment , handleSyncClassworkGeades } from "store/googleClassRoom/thunks"
import { useClassGameActivity } from 'store/one_game/selectors';
import TuneIcon from '@material-ui/icons/Tune';
import useMobileDeviceDetection from './chat/hooks';
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


const ButtonClear = styled.button`
    background-color: transparent;
    border: none;
    // color: #848485;
    cursor: pointer;
    outline: none;
    padding: 0px;
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 16px;
`;

const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black200};
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 2px 0px;
    text-transform: uppercase;
`;

const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;

const DrawerContainer = styled.div`
    height: 100%;
    padding: 40px;
    width: 100%;
`;

const FormWrap = styled.div`
    /* max-width: 240px; */
    width: 100%;
`;

const Group = styled.div`
    display: none;

    @media (min-width: 992px) {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
`;

const HeaderContainer = styled.div`
    align-items: center;
    /* border-bottom: 1px solid ${COLOR.black700}; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 6px 0px 6px;
    position: relative;

    @media (min-width: 992px) {
        margin-bottom: 16px;
    }
`;

const MobileMenu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;

    @media (min-width: 992px) {
        display: none;
    }
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

const TabButton = styled.button`
    background-color: transparent;
    border: none;
    /* color: ${p => p.active ? COLOR.black100 : COLOR.black500}; */
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 21px;
    text-transform: uppercase;
    transition: all 300ms ease;
    margin-right: 5px;
    border-radius: 7px;
    cursor:${p => p.disabled ? "not-allowed" : "pointer"}
`;

const Title = styled.span`
    display: inline-block;
    background-color: #ff682c;
    color: #ffffff;
    font-size: 12px;
    border-radius: 7px;
    padding: 4px 13px 4px 26px !important;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: .5px;
`;

const TitleContainer = styled.div`
    align-items: center;
    display: inline-flex;
    flex-direction; row;
    position: relative;
    margin-bottom: 8px;

    @media (min-width: 992px) {
      margin-bottom: 0px;
    }
`;

const ButtonMoving = styled.div`
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
`;

const useStyles = makeStyles((theme) => ({
    manageColor: {
        backgroundColor: '#cf26b8 !important',
        margin: '5px'
    },
    gradebookColor: {
        backgroundColor: '#ffb100 !important',
        margin: '5px'


    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));


const VideoContainer = styled.div`
height: auto;
flex: 1;
justify-content: center;
`;
const Mobicon = styled.img`
  height: 30px;
  width: 30px;
  align-self: center;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 3px;
`;
const Video = styled.video`
border-radius: 8px;
box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
height: auto;
flex: 1;
width: 50%;
justify-content: center;
@media only screen and (max-width: 600px){
    width: 95%;
}
`;

const TitleVideo = styled.span`
color: ${COLOR.white};
display: inline-block;
font-size: 16px;
flex: 1;
font-weight: 300;
justify-content: center;
text-align: center;
@media (min-width: 768px) {
    font-size: 24px;
}
`;

const RowVideo = styled.div`
flex: 1;
flex-wrap: wrap;
text-align: center;
justify-content: center;
align-content: center;
`;
const Container = styled.div`
display: flex;
`
const Text = styled.div`
    font-size: 15px;
    color:${COLOR.black300};
    white-space: pre-line;
    text-align: left;
`;

const seenInterFace = {
    "messageIds": [],
    "type": "seen_deliver",
    "to": "",
    "me": "",
    "deliverAt": "",
    "readAt": "",
    "seen": true,
    'code': 'system_notification'
}


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Admin dashboard container
//
//  -- Description
//  Admin class view. Contains current class data (grades, assignments submitted,
//  etc.), activities and quizzes, assigned assignments and class communication.
//
//  -- Props
//  activities {array} - list of available activities
//  classActivities {array} - list of activities assigned to current class
//  classQuizzes {array} - list of quizzes assigned to current class
//  currentClass {object} - selected class
//  currentOrg {object} - current user organization
//  currentUser {object} - current authenticated user
//  quizzes {array} - list of available quizzes
//
//
const AdminDashboard = ({
    activities,
    classActivities,
    classQuizzes,
    currentClass,
    currentOrg,
    currentUser,
    quizzes,
    userClasses,
    userBooksList,
    booksAllList,
    schools,
    users,
    classUsers,
    submissions,
    classNotes,
    classQuestions,
    selectedUser,
    schoologyAuth
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { socketConnection, reCreateSocket } = useSocket()
    const search = queryString.parse(history.location.search);
    const [classDrawer, toggleClassDrawer] = useState(false);
    const [classDistrictDrawer, toggleClassDistrictDrawer] = useState(false);
    const [classData, setClassData] = useState({ code: [...Array(10)].map(i => (~~(Math.random() * 36)).toString(36)).join('') });
    const [activityDrawer, toggleActivityDrawer] = useState(false);
    const [quizDrawer, toggleQuizDrawer] = useState(false);
    const [activityData, setActivityData] = useState({});
    const [quizData, setQuizData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoadingFlag] = useState(true);
    const [editing, setEditing] = useState(false);
    const [editedClass, editClass] = useState(currentClass?.displayName);
    const [announcementDrawer, toggleAnnouncementDrawer] = useState(false);
    const [showClassData, setShowClassData] = useState(true);
    const [modalOpen, setModalState] = useState(false);
    const [cotationLoader, setCotationLoader] = useState(true);
    const isReviewAccount = currentUser?.reviewAccount || false
    const [deleteClassAssignedActivityToggle, setdeleteClassAssignedActivityToggle] = useState(false);
    const [deleteClassAssignedQuizToggle, setdeleteClassAssignedQuizToggle] = useState(false);
    const [data, setData] = useState({});
    const [dataDistrict, setDataDistrict] = useState({});
    const classes = useStyles();
    const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
    const isCleverUser = !!(currentUser && currentUser.cleverId);
    const isCanvasUser = !!(currentUser && currentUser.canvas_id);
    const isGoogleUser = !!(currentUser && currentUser.googleId);
    const isClassLinkuser = !!(currentUser && currentUser.classlinkid);
    const isUserFromOtherPlatform =  isSchoologyUser || isCleverUser || isGoogleUser || isClassLinkuser || isCanvasUser
    const cotationRef = useRef(null)
    const currentUserId = (selectedUser && selectedUser.id)?selectedUser.id : get(currentUser,"id")
    const [initialSubmission,setInitialSubmission] = useState([])
    const [schoologyGradeCategory] = useSchoologyGradeCategory()
    const [contentLoaderMessage,setContentLoaderMessage] = useState('')
    const [schoologyAssignment,listSchoologyAssignment] = useSchoologyAssignment()
    const  { fetchClassGameActivity } = useClassGameActivity()
    if (!cotationRef.current) {
        cotationRef.current = getRandomCotation()
    }
    const client = new Schoology(schoologyAuth);
    const [studentsName, setStudentsName] = React.useState([]);
    const [studentsError, setStudentsError] = React.useState(false);
    const [diffSection, setDiffSection] = useState(false);
    const [errorCodeClassUpdate, setErrorCodeClassUpdate] = useState(false);
    const [lateSection, setlateSection] = useState(false);
    const [durationSection, setDurationSection] = useState(false);
    const listStudents =  filter(classUsers, x => x.role === 'student').map(u=>({id: u.id,description: `${u.lastName}  ${u.firstName} (${u.username})`}));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);
    const listCurrentClassInfo = async () => {
        if (get(currentClass, "id")) {
            dispatch(handleListClassQuestions(get(currentClass, "id")));
            dispatch(handleListClassUsers(get(currentClass, "id")));
            dispatch(handleListClassActivities(get(currentClass, "id")));
            dispatch(handleListClassQuizzes(get(currentClass, "id")));
            dispatch(handleListClassSubmissions(get(currentClass, "id")))
        }
    }
    const listAllAssignments = async () => {
        if (!Array.isArray(activities) || activities.length == 0) {
           await  dispatch(handleListActivities());
        }
       
        if (!Array.isArray(quizzes) || quizzes.length == 0) {
          await   dispatch(handleListQuizzes());
        }
        await dispatch(handleListCustomAssignment(get(currentUser,"role"),get(currentUser,"id")))
    }
    const listOrgInfo = async () => {
        if (has(currentOrg, "id")) {
            dispatch(handleListOrgsByParent(get(currentOrg, "id")));
            dispatch(handleListOrgUsers([currentOrg, ...schools]));
        }
    }
    const listCurrentClass = async () => {
        if ((!currentClass || !currentClass.id) && selectedUser && selectedUser.id) {
            getClass(true)
        }
    }
    const listInstruction = async() =>{
        if(has(currentUser, "id")){
            await dispatch(handleListInstructionByUser(currentUser.id))
        }
         
    }
    const fetchApis = async () => {
        setLoadingFlag(true);
        setCotationLoader(true);
        const currentClassId = localStorage.getItem("cureentClassId")
        if ((currentClassId && currentClassId === currentClass?.id) || (!currentClass?.id && currentClassId == "noclasssid")) {
            setLoadingFlag(false)
            setCotationLoader(false);
            setContentLoaderMessage(``)
        }
        if(userClasses&&userClasses.length==0 && !isUserFromOtherPlatform){
            history.push("/teacher/home")
        }
        await Promise.allSettled([
            listCurrentClassInfo(),
            listAllAssignments(),
            listOrgInfo(),
            listInstruction(),
            listCurrentClass(),
            dispatch(handleListUserByClassForTeacher(get(currentUser, "id"))),
            fetchClassGameActivity()
        ]).then(()=>{
        })

        localStorage.setItem('coatationLoader', true)
        localStorage.setItem("cureentClassId", currentClass?.id ?? "noclasssid")
        setLoadingFlag(false);
        setCotationLoader(false);
        setContentLoaderMessage(``)
        handleSchoology()
        handleCanvasUser()
        syncGradeForCanvas()
        handleGoogleClassRomm()
        if(_.has(currentClass,"id")){
            dispatch(handleListClassBookmarks(currentClass.id));
            dispatch(handleListBubbleClassNotes(currentClass.id));
        }
        dispatch(handleListUserBooks(currentUserId))
        dispatch(handleGetUserPreference(currentUserId))


    }
    const handleCanvasUser = async () => {
       if(currentUser&&currentUser.canvas_id){
         await handeleCanvasUserClasses(null, currentUser.canvas_id, currentUser.canvas_auth_id)
         await dispatch(handleListCanvasAssignmentCat(currentUser.canvas_id,currentClass.canvas_id,currentUser.canvas_auth_id))
       }
    }
    const handleSchoology = async () => {
        try {
            if (has(currentUser,"schoologyid")&&currentUser.schoologyid) {
                await handleSyncSchoologyClasses(currentUser?.schoologyid)
                await dispatch(handleListClassUsers(currentClass.id));
                await dispatch(handleListUserClasses(currentUserId));
                dispatch(handleSetSchoologyAuth(currentUser.schoologyid,client.setToken))
            }
        } catch (error) {
        }
    }
    const handleGoogleClassRomm = async () => {
        try {
            if (has(currentUser,"googleId")&&currentUser.googleId) {
                await handleSyncClassworkAssignment(currentUser.googleId,currentClass.id);
                await listAllAssignments();
                await handleSyncClassworkGeades(currentUser.googleId,currentClass.id,currentClass.googleId);
                await  dispatch(handleListClassSubmissions(get(currentClass, "id")))
            }
        } catch (error) {
        }
    }
    useEffect(()=>{
        if(submissions?.length>0&&initialSubmission.length==0){
            setInitialSubmission(submissions)
        }
    },[submissions])
    useEffect(() => {
        if (currentUser && currentUser.schoologyid && (classActivities?.length > 0 || classQuizzes?.length > 0) & !cotationLoader&&initialSubmission?.length>0) {
           if(schoologyAuth?.oauth_token){
                getGradesFromScoology();
                getAssignmentFromSchoology();
           }
        }
    }, [classActivities,classQuizzes,cotationLoader, schoologyAuth,initialSubmission])

    const getGradesFromScoology = async () => {

        try {
            if (currentClass?.sectionId) {
                await handleSyncSchoologyClassesGrades(currentUser.id, currentClass.id);
                await dispatch(handleListClassSubmissions(currentClass?.id))
            }

        } catch (error) {
            console.log(error, "bid-err");
        }

    }
    const syncGradeForCanvas = async () => {
        try {
            if (currentClass?.canvas_id) {
                await handleSyncGrade(currentUser.canvas_id,currentClass.canvas_course_id , currentClass.canvas_id, currentUser.canvas_auth_id )
                await handleSyncAssignment(currentUser.canvas_id,currentClass.canvas_course_id , currentClass.canvas_id, currentUser.canvas_auth_id )
                dispatch(handleListClassSubmissions(get(currentClass, "id")))
                dispatch(handleListClassActivities(get(currentClass, "id")));
                dispatch(handleListClassQuizzes(get(currentClass, "id")));
            }
        } catch (error) {
            console.log(error, "bid-err");
        }

    }
    const getAssignmentFromSchoology =_.debounce(async () => {
        try {
            if (currentClass?.sectionId) {
                const assignment = await client.getSectionAssignment(currentClass.sectionId)
                if (assignment && Array.isArray(assignment.assignment)) {
                    let _assignment = assignment.assignment;
                    const updateTolausd = [];
                    const deleteFromLausd = []
                    const schoologActivityyAssignment = filter(classActivities, k => k.sAssignmentId)
                    const schoologQuizAssignment = filter(classQuizzes, k => k.sAssignmentId)
                    const allAssignment = [...schoologActivityyAssignment, ...schoologQuizAssignment]
                    map(_assignment, async a => {
                        let _lausdAssignment = find(allAssignment, k => k.sAssignmentId == a.id)
                        let s_time = new Date(parseInt(`${a.last_updated}000`))
                        if (_lausdAssignment) {
                            let l_time = _lausdAssignment.modified?new Date(_lausdAssignment.modified):new Date(parseInt(`${_lausdAssignment.createdAt}`))   
                            if (moment(l_time).isBefore(moment(s_time))&&moment().diff(moment(l_time),"minutes")>3) {
                                let s_due =new Date(a.due).toISOString()
                                let l_due = _lausdAssignment.dueDate
                                if (a.title != _lausdAssignment.displayName || s_due != l_due) {
                                    updateTolausd.push({ ..._lausdAssignment, displayName: a.title, dueDate: s_due })
                                }
                            }
                        }
                    })
                    // map(allAssignment, a => {
                    //     const hasAssignment = find(_assignment, k => k.id == a.sAssignmentId)
                    //     if (!hasAssignment) {
                    //         deleteFromLausd.push(a)
                    //     }
                    // })
                    // if (deleteFromLausd.length > 0) {
                    //     await Promise.allSettled(deleteFromLausd.map(async a => {
                    //         if (a.quizId) {
                    //             await dispatch(handleDeleteClassQuiz(a.quizId, currentClass.id))
                    //         } else {
                    //             await dispatch(handleDeleteClassActivity(a.activityId, currentClass.id))
                    //         }
                    //     }))
                    // }
                    if (updateTolausd.length > 0) {
                        await Promise.allSettled(updateTolausd.map(async a => {
                            if (a.quizId) {
                                await dispatch(handleUpdateClassQuiz(a.id, a))
                            } else {
                                await dispatch(handleUpdateClassActivity(a.id, a))
                            }
                        }))
                    }
                    console.log(deleteFromLausd.length, "delete-Lausd", updateTolausd.length, "update-lausd");
                }

            }
        } catch (error) {
            console.log(error, "bid-err");
        }
    },2000)
    const getAssignmentCreation = (assignmentdata, userdata) => {
        try {
            let _assignmentinfo = assignmentdata?.data ?? {};
            let quizdata = {
                answers: [],
                assignment: _assignmentinfo,
                assignmentId: _assignmentinfo.id,
                assignmentType: _assignmentinfo.typeId,
                chapter: _assignmentinfo.chapter,
                classId: currentClass.id,
                complete: false,
                createdAt: new Date(),
                feedback: '',
                grade: '0',
                graded: true,
                isLate: false,
                orgId: currentOrg?.id,
                retakes: assignmentdata.retakes,
                sAssignmentId: assignmentdata.sAssignmentId,
                retaking: true,
                submissions: 0,
                timmer: 0,
                user: userdata,
                userId: userdata.id,
                bookId: _assignmentinfo.bookId,
                manualGrading: false,
                exceptionStatus: 0
            }
            return quizdata;
        } catch (error) {
            return {};
        }
    }
    const sendWelcomeEmail = async (data) => {
        await sendWelcomeEmailForSchoology(data)
    }
    const updateSubmission = async (subm) => {
        if (!subm || !subm.userId) return;
        await dispatch(handleUpdateSubmission(subm?.id, subm, subm))
    }
    const createUser = async (data, booksUser) => {
        return await dispatch(handleCreateUser({
            auth: {
                username: data.username,
                password: data.password,
            },
            user: {
                firstName: data.firstName,
                lastName: data.lastName,
                orgId: data.orgId,
                role: data.role,
                schoologyid: data.schoologyid,
            },
            userBooks: booksUser
        }));
    }
    const assignToClass = async (classId, userId) => {
        await dispatch(handleCreateUserClass({ userId: userId, classId: classId }))
    }
    const createClassFromSchoology = async (books, data) => {
        let booksUser = {
            "books": books,
            "userId": "",
            "validityDate": "2022-01-01",
            "orgId": currentOrg?.id
        }
        let _data = {
            orgId: currentOrg?.id,
            teacherId: currentUser?.id,
            ...data
        }
        try {
            let sectionClass = await getClassBySectionId(data.sectionId)
            if (sectionClass && sectionClass.length > 0) {
                await dispatch(handleCreateUserClass({ classId: sectionClass[0].id, userId: currentUser?.id }));
                if (!currentClass || !currentClass.id) {
                    getClass(true, sectionClass[0].id)
                }
                return;
            }
        } catch (error) {
            console.log('====================================');
            console.log(error, "getting section error");
            console.log('====================================');
        }
        const classObj = await dispatch(handleCreateClass({
            ..._data
        }, { ...booksUser }));
        await dispatch(handleCreateUserClass({ classId: classObj?.id, userId: currentUser?.id }));
        getClass(true, classObj?.id)
    }
    useEffect(() => {
        fetchApis();
        setInitialSubmission([])
    }, [currentClass])

    useEffect(() => {
        // Validar si usuario es nuevo
        // if (currentUser?.newUser) {
        //     if (!cotationLoader) {
        //         setModalState(true);
        //     }
        // }
        if (!cotationLoader) {
            setModalState(true);
        }
    }, [currentUser, cotationLoader])
    const handleCloseVideo = (() => {
        currentUser.newUser = false;
        setModalState(false);
    });

    const updateUser = async (e) => {
        e.preventDefault();
        await dispatch(handleUpdateNewUser(currentUserId));
        currentUser.newUser = false;
        setModalState(false);
    };

    const generateCode = async () => {
        const code = [...Array(10)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
        const exists = await dispatch(handleCheckCode(code));

        if (exists) {
            generateCode();
        } else {
            setData({
                ...data,
                displayName: '',
                code
            })
            setDataDistrict({
                ...dataDistrict,
                displayName: '',
                code
            })
        }
    };



    const getClass = async (hasClassLoad, classId) => {
        if (hasClassLoad) {

            const classes = await dispatch(handleListUserClasses(currentUserId));
            if (classId) {
                await dispatch(handleGetClass(classId));
            }
            else if (classes.length > 0) {
                await dispatch(handleGetClass(classes[0].id));
            }
            setClassData({});
            toggleClassDrawer(false);
        };
    }

    const getClassDistrict = async (e) => {
        toggleClassDistrictDrawer(false);
    };

    const createActivity = async (e) => {
        e.preventDefault();
        let studentsSelected = [];
        let activityDataForCreate = {
            classId: currentClass.id,
            ...activityData,
            activityId: activityData.id,
            studentsSelected: studentsSelected,
        }
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(activityData, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(activityData, 'chapter', ''),
                page: get(activityData, 'page', ''),
                id: get(activityData, 'id', ''),
            },
            message: `Your teacher Created this Assignment`,
            createdBy: get(currentClass, 'id', ''),
            type: 'create_activity',
            seen: true
        }
        if (!activityDataForCreate.bookId) activityDataForCreate.bookId = activityData.chapter
        const { isValid, reason } = validateQuizeActivityData(activityDataForCreate, "activity")
        if (!isValid) {
            setDataLoading(false);
            console.log(reason)
            return;
        }
        if (diffSection && studentsName.length == 0) {
            setStudentsError(true);
            setDataLoading(false);
            return;
        }

        setStudentsError(false);
        setDataLoading(true);
        activityDataForCreate.studentsSelected = studentsName.length > 0 ? createArrayStudents(studentsName) : [];
        const sAssignmentData = await createAssignmetToSchoologyMultipleClass(activityDataForCreate);
        const canvasData = await createAssignmetToCanvasMultipleClass(activityDataForCreate);
        if (sAssignmentData && sAssignmentData.length > 0) {
            activityDataForCreate.sAssignmentData = sAssignmentData
        }
        const googleCreateAssignment = await createAssignmentsForGoogleClassRoom(activityDataForCreate)
        if(googleCreateAssignment&&googleCreateAssignment.length>0){
            activityDataForCreate.googleClassRoomAssignment = googleCreateAssignment;
        }
        if(canvasData&&canvasData.length>0){
            activityDataForCreate.canvasData = canvasData
        }
        await dispatch(handleCreateClassActivity(activityDataForCreate));
        sendNotifications(notificationData)
        toggleActivityDrawer(false);
        setDataLoading(false);
        setActivityData({});
        setlateSection(false)
        setDurationSection(false)
    };

    const deleteActivity = async (e) => {
        e.preventDefault();
        setDataLoading(true);
        if( activityData.quizId){
            await dispatch(handleDeleteClassQuiz(
                activityData.quizId,
                activityData.classId
            ));
        }else if(quizData.activityId){
            await dispatch(handleDeleteClassActivity(
                activityData.activityId,
                activityData.classId
            ));
        }else {
            return ;
        }
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(activityData, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(activityData, 'data.chapter', ''),
                page: get(activityData, 'data.page', ''),
                id: get(activityData, 'data.id', ''),
            },
            message: `Your teacher Delete this Assignment`,
            createdBy: get(currentClass, 'id', ''),
            type: 'delete_activity',
            seen: true
        }
        sendNotifications(notificationData)
        if(activityData && activityData.canvas_id){
            try {
                await handleDeleteCanvasAssignment(currentUser.canvas_id,[{
                    canvas_id: activityData.canvas_id,
                    canvas_section_id:currentClass.canvas_id,
                    canvas_course_id:currentClass.canvas_course_id
                }],currentUser.canvas_auth_id);
            } catch (error) {
                
            }
        }
        if (activityData && activityData.sAssignmentId && currentClass.sectionId) {
            await client.setToken(schoologyAuth);
            await client.deleteAssignment(currentClass.sectionId, activityData.sAssignmentId)
            listSchoologyAssignment(currentClass.sectionId)
        }
        if(currentClass.googleId&&activityData.googleId){
            await handleDeleteGoogleAssignment(currentUser.googleId,currentClass.googleId,activityData.googleId)
        }
        setdeleteClassAssignedActivityToggle(false);
        setDataLoading(false);
        setActivityData({});
    };

    const updateActivity = async (e) => {
        e.preventDefault();
        let toCreateStudent = [];
        let infoDiffSection = {};
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(activityData, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(activityData, 'data.chapter', ''),
                page: get(activityData, 'data.page', ''),
                id: get(activityData, 'data.id', ''),
            },
            message: `Your teacher Update this Activity`,
            createdBy: get(currentClass, 'id', ''),
            type: 'update_activity',
            seen: true
        }
        if (activityData && activityData.sAssignmentId && currentClass.sectionId) {
            if (activityData.sAssignmentId && currentClass.sectionId) {
                let u = await updateAssignmentToSchoology(activityData)
            }
        }
        if (activityData && activityData.canvas_id && currentClass.canvas_id) {
            const assignmentData =  [{
                "canvas_user_id": currentUser.canvas_id,
                "canvas_id": activityData.canvas_id,
                "canvas_section_id": currentClass.canvas_id,
                "canvas_course_id": currentClass.canvas_course_id,
                "due_date": get(activityData, "dueDate"),
                "weightage": get(activityData, "weightage"),
                "displayName": get(activityData, "displayName"),
                "max_point":activityData.max_point
            }]
            await handleUpdateCanvasAssignment(currentUser.canvas_id,assignmentData,currentUser.canvas_auth_id)
        }
        if (diffSection) {
            infoDiffSection = {
                diff: activityData.diff,
                retakesDif: activityData.retakesDif,
                dueDateDif: activityData.dueDateDif,
                scheduleAt:  activityData.scheduleAtDiff ||  activityData.scheduleAt ,
                lateDate: activityData.lateDate,
            }
            const diffstudent = map(activityData.diff, item => item.userId);
            const studentArray = createArrayStudents(studentsName);
            toCreateStudent = studentArray.filter(item => !diffstudent.includes(item.studentId));
        } else {
            infoDiffSection = {
                diff: [],
                retakesDif: "",
                dueDateDif: ""
            }
        }
        setDataLoading(true);
        if(currentClass.googleId&&activityData.googleId){
            await handleUpdateGoogleAssignment(currentUser.googleId,activityData,currentClass.googleId,activityData.googleId)
        }
        await dispatch(handleUpdateClassActivity(
            activityData.id,
            {
                ...activityData,
                retakes: activityData.retakes && activityData.retakes.trim(),
                dueDate: activityData.dueDate,
                lateDate: activityData.lateDate,
                timeDuration: activityData.timeDuration,
                classes: activityData.classes,
                weightage: activityData.weightage,
                displayName: activityData.displayName,
                classId:activityData.classId
            },
            infoDiffSection,
            toCreateStudent,
            activityData,
            activityData.updateTOAllClass
        ));
        await dispatch(handleListClassActivities(currentClass.id));
        sendNotifications(notificationData)
        toggleActivityDrawer(false);
        setDataLoading(false);
        setDiffSection(false);
        setStudentsName([]);
        setActivityData({});
        setlateSection(false)
        setDurationSection(false)
    };
    const createAssignmentsForGoogleClassRoom = async (assignment) => {
        if(currentUser.googleId){
            return Promise.all(map(assignment.classes,async cls=>{
                const classInfo = find(userClasses,["id",cls.id]);
                if(classInfo&&classInfo.googleId){
                    const assignmentData = currentClass.id === cls.id?assignment:({...assignment,dueDate:cls.dueDate})
                    const createdAssignment =  await handleCreateGoogleAssignment(currentUser.googleId,assignmentData,classInfo.googleId)
                    return ({classId:classInfo.id,googleAssignmentId:get(createdAssignment,"id")})
                }else{
                    return null;
                }
               
            })).then(v=>v.filter(k=>k))
        }else{
            return [];
        }
       }
 
    const createAssignmetToCanvasMultipleClass = async (quizeDataforCreate) => {
        if(!get(currentUser,"canvas_id")||!quizeDataforCreate) return;
        const hasClass = map(quizeDataforCreate?.classes, e =>{
            const dueDate = e.id === currentClass.id ? quizeDataforCreate.dueDate :e.dueDate
            return({...e,
            "due_date": dueDate,
            "weightage": quizeDataforCreate.weightage?quizeDataforCreate.weightage:'100',
            "displayName": quizeDataforCreate.displayName,
            "canvas_section_id":get(find(userClasses,['id',e.id]),"canvas_id"),
            "canvas_course_id":get(find(userClasses,['id',e.id]),"canvas_course_id"),
            "grade_type":quizeDataforCreate.grade_type,
            "max_point":quizeDataforCreate.max_point,
            "final_grade_exclude":quizeDataforCreate.final_grade_exclude
        })})
        const createdAssignment = await handleCreateCanvasAssignment(currentUser.canvas_id,hasClass,currentUser.canvas_auth_id)
        const result =  map(createdAssignment,q=>{
            if(q&&q.canvas_course_id){
                const classInfo = find(userClasses,uc=>uc.canvas_id ==q.canvas_section_id)
                return({
                    canvas_section_id:q.canvas_section_id,
                    canvas_course_id:q.canvas_course_id,
                    canvas_assignment_id:q.id,
                    classId:get(classInfo,"id")
                })
            }
            return null;
        }).filter(q=>q)   
        return result;   
    }
    const createAssignmetToSchoologyMultipleClass = async (quizeDataforCreate) => {
        const hasClass = filter(quizeDataforCreate?.classes, e => e?.sectionId);
        const sAssignmentData = [];
        if (hasClass.length > 0) {
            await Promise.allSettled(map(hasClass, async item => {
                const schoologyCategory = item.schoologyCategory?item.schoologyCategory:quizeDataforCreate.schoologyCategory
                let s_id = await createAssignmentToSchoology({...quizeDataforCreate,schoologyCategory}, item.sectionId)
                const schoologyCategoryData = find(schoologyGradeCategory, k => k.id == schoologyCategory);
                const isWightageEnabled = find(schoologyGradeCategory, k=>k.weight>0);
                let max_point  = 100;
                let grade_type = 'percent';
                let weightage = 100;
                if(schoologyCategoryData){
                    if(schoologyCategoryData.calculation_type == 2){
                        grade_type = 'points'
                    }
                    if(has(schoologyCategoryData,"default_max_points")){
                        max_point =   schoologyCategoryData.default_max_points
                    }
                    if(isWightageEnabled){
                        weightage = get(schoologyCategoryData,'weight') || 100 ;
                    }
                }
                if (s_id) {
                    sAssignmentData.push({
                        classId: item.id,
                        sAssignmentId: s_id.id,
                        max_point,
                        grade_type,
                        weightage
                    })
                }
            }))
        } else {
            let s_assignment = await createAssignmentToSchoology(quizeDataforCreate, currentClass.sectionId)
            if (s_assignment) {
                let max_point  = 100;
                let grade_type = 'percent';
                let weightage = 100;
                sAssignmentData.push({
                    classId: currentClass.id,
                    sAssignmentId: s_assignment.id,
                    max_point,
                    grade_type,
                    weightage
                })
            }
        }
        return sAssignmentData;
    }
    const createAssignmentToSchoology = async (quizeDataforCreate, sectionId) => {
        let hasClass = filter(quizeDataforCreate?.classes, e => e?.sectionId);
        let toBeUnassigned = filter(hasClass, e => e?.sectionId !== sectionId);
        if (currentUser.schoologyid && sectionId) {
            let grading_category = get(quizeDataforCreate, "schoologyCategory") ;
            let weightage = quizeDataforCreate.weightage
            if (!weightage || isNaN(parseInt(weightage))) {
                weightage = 100;
            }

            client.setToken(schoologyAuth);
            let data = {
                "title": quizeDataforCreate.displayName,
                "description": quizeDataforCreate.displayName,
                "due": `${quizeDataforCreate.dueDate}`,
                "type": "assignment",
                "assignees": [`${currentUser.schoologyid}`],
                "grading_group_ids": [],
                "grading_category": grading_category
            }
            const createAssignment = await client.createAssignment(sectionId, data)
            listSchoologyAssignment(sectionId)
            map(toBeUnassigned, item => {
                client.unassignAssignment(item.sectionId, createAssignment.id)
            })
            return createAssignment;

        }
        return null
    }
    const updateAssignmentToSchoology = async (quizeDataforCreate) => {
        let sectionId = currentClass.sectionId;
        const classConfig = find(quizeDataforCreate.classes, ['id', currentClass.id])
        if (classConfig && classConfig.sectionId) {
            sectionId = classConfig.sectionId;
        }
        if (currentUser.schoologyid && sectionId) {
            client.setToken(schoologyAuth);
            const classConfig = find(quizeDataforCreate.classes,['id',currentClass.id])
            let data = {
                "title": quizeDataforCreate.displayName,
                "due": `${quizeDataforCreate.dueDate}`,
                "grading_category":get(classConfig,"schoologyCategory")?get(classConfig,"schoologyCategory"): quizeDataforCreate.schoologyCategory
            }
            const v =  await client.updateAssignment(sectionId, quizeDataforCreate.sAssignmentId, data)
            listSchoologyAssignment(sectionId)
            return v;
        }
        return null
    }

    const createQuiz = async (e) => {
        e.preventDefault();
        let studentsSelected = [];
        let quizeDataforCreate = {
            classId: currentClass.id,
            ...quizData,
            quizId: quizData.id,
            studentsSelected: studentsSelected,
        }
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(quizData, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(quizData, 'chapter', ''),
                page: get(quizData, 'page', ''),
                id: get(quizData, 'id', ''),
            },
            message: `your teacher Created this Quiz`,
            createdBy: get(currentClass, 'id', ''),
            type: 'create_quiz',
            seen: true
        }
        if (!quizeDataforCreate.bookId) quizeDataforCreate.bookId = quizData.chapter
        const { isValid, reason } = validateQuizeActivityData(quizeDataforCreate, "quiz")
        if (!isValid) {
            setDataLoading(false);
            console.log(reason)
            return;
        }
        quizeDataforCreate.studentsSelected = studentsName.length > 0 ? createArrayStudents(studentsName) : [];

        const sAssignmentData = await createAssignmetToSchoologyMultipleClass(quizeDataforCreate);
        const canvasData = await createAssignmetToCanvasMultipleClass(quizeDataforCreate);
        if (sAssignmentData && sAssignmentData.length > 0) {
            quizeDataforCreate.sAssignmentData = sAssignmentData
        }
        const googleCreateAssignment = await createAssignmentsForGoogleClassRoom(quizeDataforCreate)
        if(googleCreateAssignment&&googleCreateAssignment.length>0){
            quizeDataforCreate.googleClassRoomAssignment = googleCreateAssignment;
        }
        if(canvasData&&canvasData.length>0){
            quizeDataforCreate.canvasData = canvasData
        }
        if (diffSection && studentsName.length == 0) {
            setStudentsError(true);
            setDataLoading(false);
            return;
        }
        setStudentsError(false);
        setDataLoading(true);
        await dispatch(handleCreateClassQuiz(
            quizeDataforCreate
        ));
        await dispatch(handleListClassQuizzes(currentClass.id));
        sendNotifications(notificationData)
        toggleQuizDrawer(false);
        setDataLoading(false);
        setQuizData({});
        setlateSection(false)
        setDurationSection(false)
    };

    const invokSend = (data) => {
        let socket = socketConnection ?? '';
        if (socket) {
            try {
                if (socket.readyState == 3) {
                    reCreateSocket()
                    setTimeout(() => {
                        socket.send(JSON.stringify(data))
                    }, 2000)
                } else {
                    socket.send(JSON.stringify(data))
                }
            } catch (error) {
                console.log(error, "error")
            }
        }
    }

    const sendNotifications = async (notificationData) => {  
        try {
            const updateNotification = await dispatch(handleCreateNotification(notificationData))
            const messageCreate = {
                ...updateNotification,
                senderId: get(currentUser, 'id', ''),
                receiverId: null,
                classId: get(currentClass, 'id', ''),
                code: 'system_notification'
            }
        invokSend(messageCreate)
        } catch (error) {
            console.log(error, "error")
        }
    }

    const deleteClassQuiz = async (e) => {
        e.preventDefault();
        setDataLoading(true);
        if( quizData.quizId){
            await dispatch(handleDeleteClassQuiz(
                quizData.quizId,
                quizData.classId
            ));
        }else if(quizData.activityId){
            await dispatch(handleDeleteClassActivity(
                quizData.activityId,
                quizData.classId
            ));
        }else {
            return ;
        }
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(quizData, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(quizData, 'data.chapter', ''),
                page: get(quizData, 'data.page', ''),
                id: get(quizData, 'data.id', ''),
            },
            message: `Your teacher Delete this Quiz`,
            createdBy: get(currentClass, 'id', ''),
            type: 'delete_quiz',
            seen: true
        }
        sendNotifications(notificationData)
        if (quizData && quizData.sAssignmentId && currentClass.sectionId) {
            await client.setToken(schoologyAuth);
            await client.deleteAssignment(currentClass.sectionId, quizData.sAssignmentId)
            listSchoologyAssignment(currentClass.sectionId)
        }
        if(currentClass.googleId&&quizData.googleId){
            await handleDeleteGoogleAssignment(currentUser.googleId,currentClass.googleId,quizData.googleId)
        }
        if(quizData && quizData.canvas_id){
            try {
                await handleDeleteCanvasAssignment(currentUser.canvas_id,[{
                    canvas_id: quizData.canvas_id,
                    canvas_section_id:currentClass.canvas_id,
                    canvas_course_id:currentClass.canvas_course_id
                }],currentUser.canvas_auth_id);
            } catch (error) {
                
            }
        }
        setdeleteClassAssignedQuizToggle(false);
        setDataLoading(false);
        setQuizData({});
    };

    const updateQuiz = async (e) => {
        e.preventDefault();
        let infoDiffSection = {};
        let toCreateStudent = [];
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(quizData, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(quizData, 'data.chapter', ''),
                page: get(quizData, 'data.page', ''),
                id: get(quizData, 'data.id', ''),
            },
            message: `Your teacher Update this Quiz`,
            createdBy: get(currentClass, 'id', ''),
            type: 'update_quiz',
            seen: true
        }
        if (diffSection) {
            infoDiffSection = {
                diff: quizData.diff,
                retakesDif: quizData.retakesDif,
                dueDateDif: quizData.dueDateDif,
                levelExercise: quizData.levelExercise,
                scheduleAt:  quizData.scheduleAtDiff ||  quizData.scheduleAt ,
                lateDate: quizData.lateDate,
            }
            const diffstudent = map(quizData.diff, item => item.userId);
            const studentArray = createArrayStudents(studentsName);
            toCreateStudent = studentArray.filter(item => !diffstudent.includes(item.studentId));
        } else {
            infoDiffSection = {
                diff: [],
                retakesDif: "",
                dueDateDif: "",
                levelExercise: ""
            }
        }
        setDataLoading(true);
        if (quizData.sAssignmentId && currentClass.sectionId) {
            let u = await updateAssignmentToSchoology(quizData)
        }
        if(currentClass.googleId&&quizData.googleId){
            await handleUpdateGoogleAssignment(currentUser.googleId,quizData,currentClass.googleId,quizData.googleId)
        }
        if (quizData && quizData.canvas_id && currentClass.canvas_id) {
            const assignmentData =  [{
                "canvas_user_id": currentUser.canvas_id,
                "canvas_id": quizData.canvas_id,
                "canvas_section_id": currentClass.canvas_id,
                "canvas_course_id": currentClass.canvas_course_id,
                "due_date": get(quizData, "dueDate"),
                "weightage": get(quizData, "weightage"),
                "displayName": get(quizData, "displayName"),
                "max_point":quizData.max_point
            }]
            await handleUpdateCanvasAssignment(currentUser.canvas_id, assignmentData ,currentUser.canvas_auth_id)
        }
        await dispatch(handleUpdateClassQuiz(
            quizData.id,
            {
                ...quizData,
                retakes: quizData.retakes && quizData.retakes.trim(),
                dueDate: quizData.dueDate,
                lateDate: quizData.lateDate,
                timeDuration: quizData.timeDuration || 0,
                classes: quizData.classes,
                weightage: quizData.weightage,
                displayName: quizData.displayName,
                scheduleAt: quizData.scheduleAt,
                classId:quizData.classId,
            },
            infoDiffSection,
            toCreateStudent,
            quizData,
            quizData.updateTOAllClass
        ));

        await dispatch(handleListClassActivities(currentClass.id));
        await dispatch(handleListClassQuizzes(currentClass.id));
        sendNotifications(notificationData);
        toggleQuizDrawer(false);
        setDataLoading(false);
        setDiffSection(false);
        setStudentsName([]);
        setQuizData({});
        setlateSection(false)
        setDurationSection(false)
    };

    const updateClassData = (id, value) => {
        setClassData({
            ...classData,
            [id]: value
        });
    };

    const updateActivityData = (id, value) => {
        const val = value&&value.length > 1 && value[0] === '0' ? value.replace('0', '') : value;
        setActivityData({
            ...activityData,
            [id]: val
        });
    };

    const updateQuizData = (id, value) => {
        const val = value&&value.length > 1 && value[0] === '0' ? value.replace('0', '') : value;
        setQuizData({
            ...quizData,
            [id]: val
        });
    };

    const updateClassname = e => {
        editClass({
            ...editedClass,
            [e.target.id]: e.target.value
        })
        setErrorCodeClassUpdate(false);
    };
    const toolbarNav = () => {
        return (
            <Menu className="MobTabContent">
                <Menu.Item>
                    <ButtonClear onClick={() => history.push('/admin/class?view=home')}>
                        Home
                    </ButtonClear>
                </Menu.Item>
                <Menu.Item disabled={userClasses?.length == 0}>
                    <ButtonClear onClick={() => history.push('/admin/class?view=manage')}>
                        Assignments
                    </ButtonClear>
                </Menu.Item>
                <Menu.Item disabled={userClasses?.length == 0}>
                    <ButtonClear onClick={() => history.push('/admin/class?view=gradebook')}>
                        GradeBook
                    </ButtonClear>
                </Menu.Item>
                {/* <Menu.Item>
                    <ButtonClear onClick={() => toggleAnnouncementDrawer(true)}>
                        Announcements
                    </ButtonClear>
                </Menu.Item> */}
                {!isUserFromOtherPlatform && <Menu.Item>
                    <ButtonClear onClick={() => currentUser?.role === 'admin' ? toggleClassDistrictDrawer(true) : toggleClassDrawer(true)}>
                        Create Class
                    </ButtonClear>
                </Menu.Item>}
                <Menu.Item disabled={userClasses?.length == 0}>
                    <ButtonClear onClick={() => history.push('/admin/class?view=classdata')}>
                        Class data
                    </ButtonClear>
                </Menu.Item>
                {/* <Menu.Item>
                    <ButtonClear onClick={() => history.push('/learn')}>Learn</ButtonClear>
                </Menu.Item> */}
                <Menu.Item>
                    <ButtonClear onClick={() => history.push('/how-to-videos')}>How to Videos</ButtonClear>
                </Menu.Item>
                {currentUser?.role === 'admin' && (
                    <Menu.Item>
                        <ButtonClear onClick={() => history.push('/logs')}>Logs</ButtonClear>
                    </Menu.Item>
                )}
                {/* <Menu.Item>
                    <ButtonClear onClick={invokeFeedback}>Feedback</ButtonClear>
                </Menu.Item> */}
            </Menu>
        )
    };

    const infoUpdateClass = async (e) => {
        let codeFinal;
        if (editedClass.code == undefined) {
            codeFinal = currentClass.code;
        } else {
            codeFinal = editedClass.code;
        }
        let displayNameFinal;
        if (editedClass.displayName == undefined) {
            displayNameFinal = currentClass.displayName;
        } else {
            displayNameFinal = editedClass.displayName;
        }
        let codeClass;
        if (codeFinal != currentClass.code) {
            codeClass = await dispatch(handleGetClassByCode(editedClass.code.trim()));
        }
        if (codeClass == undefined) {
            setErrorCodeClassUpdate(false);
            await dispatch(handleUpdateClass(currentClass.id, {
                ...currentClass,
                displayName: displayNameFinal,
                code: codeFinal
            }));
            currentClass.displayName = displayNameFinal;
            currentClass.code = codeFinal;
            await dispatch(handleListUserClasses(currentUserId));
        } else {
            setErrorCodeClassUpdate(true);
        }
    }

    //  -- Delete Class -------------------
    const [deleteToggle, setDeleteToggle] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const deleteClass = async () => {
        setDeleting(true);
        await dispatch(handleDeleteClassData(currentClass.id));
        const listclass = await dispatch(handleListUserClasses(currentUserId))
        await dispatch(handleGetClass(listclass[0].id));
        const userToRemove = _.filter(classUsers, (user) => user.id != currentUserId);
        const orgId = !!currentOrg.parentId ? currentOrg.parentId : currentOrg?.id;
        await dispatch(handleDeleteUserFromOrganization(orgId, userToRemove.map(user => `'${user.id}'`)));
        setDeleting(false);
        setDeleteToggle(false);
    };

    const openEditQuiz = (quiz) => {
        if (get(quiz,"diff.length",0) > 0) {
            if (get(quiz,"userDiff.length",0) > 0) {
                let stuName = [];
                for (var u in users) {
                    if (users[u]) {
                        let name = `${users[u].lastName}  ${users[u].firstName} (${users[u].username})`
                        stuName.push(name);
                    }
                }
                setStudentsName(stuName);
            } else {
                setStudentsName([])
            }
                setDiffSection(true);
                quiz = modifyAssignmentWithDiff(quiz,currentClass)
                setQuizData({
                    editing: true,
                    dueDateDif: quiz.diff[0].dueDate,
                    retakesDif: quiz.diff[0].retakes,
                    levelExercise: quiz.diff[0].levelExercise,
                    scheduleAtDiff: quiz.diff[0].scheduleAt ,
                    ...quiz
                });
                toggleQuizDrawer(true);
        } else {
            if (quiz.lateDate) {
                setlateSection(true)
            }
            if (quiz.timeDuration > 0) {
                setDurationSection(true)
            }
            setQuizData({
                editing: true,
                ...quiz
            });
            toggleQuizDrawer(true);
        }
    }

    const openEditActivity = (activity) => {
        if (get(activity,"diff.length",0) > 0) {
            if (get(activity,"userDiff.length",0) > 0) {
                let users = activity.userDiff;
                let stuName = [];
                for (var u in users) {
                    if (users[u]) {
                        let name = `${users[u].lastName}  ${users[u].firstName} (${users[u].username})`
                        stuName.push(name);
                    }
                }
                setStudentsName(stuName);
            } else {
                setStudentsName([])
               
            }
            activity = modifyAssignmentWithDiff(activity,currentClass)
            setDiffSection(true);
            setActivityData({
                editing: true,
                dueDateDif: activity.diff[0].dueDate,
                retakesDif: activity.diff[0].retakes,
                scheduleAtDiff: activity.diff[0].scheduleAt ,
                ...activity
            });
            toggleActivityDrawer(true);
        } else {
            if (activity.lateDate) {
                setlateSection(true)
            }
            if (activity.timeDuration > 0) {
                setDurationSection(true)
            }
            setActivityData({
                editing: true,
                ...activity
            });
            toggleActivityDrawer(true);
        }
    }
    const invokeFeedback = () => {
        dispatch(handleInvokeFeedbackForm())
    }
    const handleChangeMultiSelect = (event) => {
        setStudentsError(false);
        setStudentsName(event.target.value);
    };

    function createArrayStudents(studentsName) {
        let arrayStudents = [];
        for (var s in studentsName) {
            let finded = listStudents.find(e => e.description == studentsName[s]);
            if (finded) {
                let name = finded.description?.includes('(') && finded.description?.includes(')') ? finded.description.substring(0, finded.description.indexOf("(")) : finded.description;
                arrayStudents.push({
                    studentId: finded.id,
                    description: name
                })
            }
        }
        return arrayStudents;
    }
    const getTeacherBook = useCallback(() => {
        const ubook = currentClass && currentClass.books ? currentClass : userBooksList;
        if (_.has(ubook, "books")) {
            const userBooksDt = { ...ubook, books: [] }
            map(ubook.books, ub => {
                const bk = find(booksAllList, ['id', ub.bookId])
                if (bk && bk.teacherEditionBook) {
                    const tbk = find(booksAllList, ['id', bk.teacherEditionBook])
                    if (tbk) {
                        const hasAlready = find(ubook.books, ["bookId", tbk.id])
                        if (!hasAlready) {
                            userBooksDt.books.push({
                                "bookId": tbk.id,
                                "description": tbk.description
                            })
                        }

                    }
                }
                userBooksDt.books.push(ub)
            })
            userBooksDt.books = uniqBy(userBooksDt.books,"bookId");
            return userBooksDt;
        } else {
            return ({ books: [] })
        }
    },[currentClass,userBooksList,booksAllList])
     const  onswithClass = async(class_id,displayName)=>{
        setCotationLoader(true)
        setContentLoaderMessage(`Moving to ${displayName}`)
        await dispatch(handleGetClass(class_id))
    }
    const getFilteredCloneQuiz = useCallback(()=>{
        const clonedActivities= filter(activities,k=>k.cloneId).map(k=>k.cloneId)
        return filter(quizzes,k=>!clonedActivities.includes(k.id))
    },[quizzes,activities])

    const getFilteredCloneAcivity = useCallback(()=>{
        const clonedActivities= filter(activities,k=>k.cloneId).map(k=>k.cloneId) 
        return filter(activities,k=>!clonedActivities.includes(k.id))
    },[quizzes,activities])

    const deviceType = useMobileDeviceDetection();

    return (
        <>
            <Modal
                className={classes.modal}
                open={cotationLoader}
            >
                <VideoContainer>
                    <CotationLoader cotation={cotationRef.current} messages={contentLoaderMessage} />
                </VideoContainer>
            </Modal>
            {/* <Modal
                className={classes.modal}
                open={modalOpen}
                onClose={handleCloseVideo}
            >
                <VideoContainer>
                    <RowVideo>
                        <TitleVideo>{videoTitle}</TitleVideo>
                    </RowVideo>
                    <RowVideo>
                        <Video onContextMenu={(e) => {
                            e.preventDefault();
                        }} controls controlsList="nodownload" width="50%"
                            poster={IntroVideoPoster}
                        >
                            <source src={IntroVideo} type="video/mp4" />
                        </Video>
                    </RowVideo>
                    <RowVideo>
                        <div className={classes.root}>
                            <Button variant="contained" size="large" color="primary" onClick={handleCloseVideo}>
                                WATCH LATER
                            </Button>
                            {isReviewAccount ? null :
                                <Button variant="contained" size="large" color="secondary" onClick={updateUser}>
                                    DON'T SHOW AGAIN
                                </Button>}
                        </div>
                    </RowVideo>
                </VideoContainer>
            </Modal> */}
            <WelcomeVideo open={modalOpen} onClose={handleCloseVideo} />
            <Modal
                open={deleteClassAssignedActivityToggle}
                onClose={() => setdeleteClassAssignedActivityToggle(false)}
            >
                <ModalContent>
                    Are you sure you want to delete the assignment? All grades will be removed as well.
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setdeleteClassAssignedActivityToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={deleteActivity}
                            className={`${dataLoading ? "disabled" : ""}`}
                        >
                            {dataLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Modal
                open={deleteClassAssignedQuizToggle}
                onClose={() => setdeleteClassAssignedQuizToggle(false)}
            >
                <ModalContent>
                    Are you sure you want to delete the assignment? All grades will be removed as well.
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setdeleteClassAssignedQuizToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={deleteClassQuiz}
                            className={`${dataLoading ? "disabled-button" : ""}`}
                        >
                            {dataLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>

            <Modal
                open={deleteToggle}
                onClose={() => setDeleteToggle(false)}
            >
                <ModalContent>
                    <Text><strong>{`Deleting this class will also delete the following:`}</strong></Text>
                    <Text>{"— All students from your roster\n— All grades and assessments submitted \n— All data related to this class (notes, questions, etc.) \n— You will never be able to get this data again Are you sure you want to delete it? "}</Text>
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setDeleteToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={deleteClass}
                            className={`${deleting ? "disabled-button" : ""}`}
                        >
                            {deleting ? <Loading size={20} white /> : 'Remove'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Drawer
                destroyOnClose
                height='100%'
                className='assignments-drawer assignments-drawerCstm'
                onClose={() => {
                    toggleQuizDrawer(false);
                    setQuizData({});
                    setStudentsName([]);
                    setDiffSection(false);
                    setlateSection(false)
                    setDurationSection(false)
                }}
                placement='top'
                open={quizDrawer}
                key='quizNew'
            >
                
                    <SentryWrap>
                        <QuizForm
                            listStudents={listStudents}
                            data={quizData}
                            handleChange={e => updateQuizData(e.target.id, e.target.value)}
                            handleChangeLevel={e => updateQuizData('levelExercise', e.target.value)}
                            handleSubmit={quizData.editing ? updateQuiz : createQuiz}
                            handleChangeMultiSelect={handleChangeMultiSelect}
                            diffSection={diffSection}
                            lateSection={lateSection}
                            setDiffSection={setDiffSection}
                            setlateSection={setlateSection}
                            setDurationSection={setDurationSection}
                            durationSection={durationSection}
                            studentsName={studentsName}
                            studentsError={studentsError}
                            loading={dataLoading}
                            onClose={() => toggleQuizDrawer(false)}
                            booksAllList={booksAllList}
                        />
                    </SentryWrap>
               
            </Drawer>
            <Drawer
                height='100%'
                destroyOnClose
                className='assignments-drawer assignments-drawerCstm'
                onClose={() => {
                    toggleActivityDrawer(false);
                    setActivityData({});
                    setStudentsName([]);
                    setDiffSection(false);
                    setlateSection(false)
                    setDurationSection(false)
                }}
                placement='top'
                open={activityDrawer}
                key='activityNew'
            >
                <>
                    <SentryWrap>
                        <ActivityForm
                            listStudents={listStudents}
                            data={activityData}
                            handleChange={e => updateActivityData(e.target.id, e.target.value)}
                            handleSubmit={activityData.editing ? updateActivity : createActivity}
                            handleChangeMultiSelect={handleChangeMultiSelect}
                            diffSection={diffSection}
                            lateSection={lateSection}
                            setDiffSection={setDiffSection}
                            setlateSection={setlateSection}
                            setDurationSection={setDurationSection}
                            durationSection={durationSection}
                            studentsName={studentsName}
                            studentsError={studentsError}
                            loading={dataLoading}
                            onClose={() => toggleActivityDrawer(false)}
                            booksAllList={booksAllList}
                        />
                    </SentryWrap>
                </>
            </Drawer>
            <Drawer
             destroyOnClose
                height='100%'
                onClose={() => {
                    toggleClassDrawer(false);
                    setData({});
                }}
                placement='top'
                open={classDrawer}
                key='classNew'
                className="addNewDrawer"
            >
                <>
                    <SentryWrap>
                        {classDrawer && <ClassForm
                            data={data}
                            dataBooks={userBooksList}
                            listBooks={booksAllList}
                            setData={setData}
                            currentOrg={currentOrg}
                            currentUser={currentUser}
                            currentClass={currentClass}
                            classUsers={classUsers}
                            classActivities={classActivities}
                            classQuizzes={classQuizzes}
                            classNotes={classNotes}
                            classQuestions={classQuestions}
                            //handleChange={e => updateClassData(e.target.id, e.target.value)}
                            //handleSubmit={createClass}
                            //loading={dataLoading}
                            userClasses={userClasses}
                            onClosed={(hasClassLoad, classId) => {
                              
                                setData({})
                                getClass(hasClassLoad, classId);
                                toggleClassDrawer(false)

                            }}
                        />}
                    </SentryWrap>
                </>
            </Drawer>
            <Drawer
             destroyOnClose
                height='100%'
                onClose={() => {
                    toggleClassDistrictDrawer(false);
                    setDataDistrict({});
                }}
                placement='top'
                open={classDistrictDrawer}
                key='classNew1'>
                <CenterContainer>
                    <SentryWrap>
                        <ClassFormDistrict
                            data={dataDistrict}
                            listBooks={_.get(userBooksList,"books",[])}
                            setData={setDataDistrict}
                            currentOrg={currentOrg}
                            currentUser={currentUser}
                            currentClass={currentClass}
                            classUsers={classUsers}
                            schools={schools}
                            classActivities={classActivities}
                            classQuizzes={classQuizzes}
                            classNotes={classNotes}
                            classQuestions={classQuestions}
                            userClasses={userClasses}
                            teachers={filter(users, x => x.role === 'teacher')}
                            onClosed={() => {
                                getClassDistrict();
                            }}
                        />
                    </SentryWrap>
                </CenterContainer>
            </Drawer>
            <Drawer
                destroyOnClose
                height='100%'
                onClose={() => toggleAnnouncementDrawer(false)}
                placement='top'
                open={announcementDrawer}
                key='announcements'>
                <DrawerContainer>
                    <SentryWrap>
                        <AdminAnnouncements type="class" />
                    </SentryWrap>
                </DrawerContainer>
            </Drawer>
            <Row className="tabCollection wrapperAdmnDash" style={{ flexDirection: deviceType ? 'column' : 'unset' }}>
                <Col className="contentAdmnDash"  style={{flex: deviceType ? 1 : "undefined", minWidth: deviceType ? "100%" : undefined }} xs={{ span: 24 }} sm={24} xl={{span: search.view == "home" && 17}} lg={{ span: search.view == "home" ? "" :(!search.view) ? 17 : 24 }}>
                <>
                    <div>
                        <Row>
                            <Col>
                                <Group>
                                    <ButtonGroup className="tabBtnGrup">
                                        <TabButton
                                            style={{ backgroundImage: getColor((search.view == "home" || !search.view)) }}
                                            active={search.view === 'home'} onClick={() => history.push('/admin/class?view=home')}>
                                            Home
                                        </TabButton>
                                        <TabButton
                                            style={{ backgroundImage: getColor(search.view === 'manage') }}
                                            disabled={userClasses?.length == 0}
                                            active={search.view === 'manage'} onClick={() => history.push('/admin/class?view=manage')}>
                                            Assignments
                                        </TabButton>
                                        <TabButton
                                            style={{ backgroundImage: getColor(search.view === 'gradebook') }}
                                            disabled={userClasses?.length == 0}
                                            active={search.view === 'gradebook'} onClick={() => history.push('/admin/class?view=gradebook')}>
                                            Gradebook
                                        </TabButton>
                                        {!isUserFromOtherPlatform  && <TabButton
                                            style={{ backgroundImage: getColor(search.view === 'admin') }}
                                            onClick={() => {
                                                generateCode();
                                                currentUser?.role === 'admin' ? toggleClassDistrictDrawer(true) : toggleClassDrawer(true);
                                            }}>
                                            Create Class
                                        </TabButton>}
                                        <TabButton
                                            style={{ backgroundImage: getColor(search.view === 'classdata') }}
                                            disabled={userClasses?.length == 0}
                                            active={search.view === 'classdata'} onClick={() => history.push('/admin/class?view=classdata')}>
                                            Class data
                                        </TabButton>
                                        <div className="customLinksUpr">
                                            <ul className="customLinks">
                                                <li onClick={() => history.push('/how-to-videos')}>How to Videos</li>
                                                {currentUser?.role === 'admin' && (
                                                    <li onClick={() => history.push('/logs')}>Logs</li>
                                                )}
                                            </ul>
                                        </div>
                                    </ButtonGroup>
                                </Group>
                            </Col>
                        </Row>

                        <HeaderContainer>
                            { <TitleContainer>
                                <img src={locationImg} className="locationIcon" />
                                {/* <Mobicon src={mobileMenuimg} /> */}
                                {/* <MenuBook style={{ marginRight: '10px', marginTop: '-2px' }} /> */}
                                    {userClasses.length == 0 && !isUserFromOtherPlatform && (
                                        <>
                                            <ButtonMoving>
                                                <Button style={{ backgroundColor: 'red', color: 'white' }}
                                                    onClick={() => {
                                                        generateCode();
                                                        currentUser?.role === 'admin' ? toggleClassDistrictDrawer(true) : toggleClassDrawer(true);
                                                    }}
                                                    variant="contained"
                                                    type='submit'
                                                >
                                                    Create Class
                                                </Button>
                                            </ButtonMoving>
                                        </>
                                    )}
                                    {userClasses.length > 0 && (
                                        <>
                                            <Title>{currentClass?.displayName} ({currentClass?.code})</Title>
                                            {!isUserFromOtherPlatform && <Tooltip placement="bottom" title={isReviewAccount ? "Only for Review!" : "Edit"}>
                                                <ButtonClear onClick={() => {
                                                    if (isReviewAccount) {
                                                        return;
                                                    }
                                                    toggleClassDrawer(true);
                                                    setData(currentClass);
                                                }} style={{ marginLeft: '9px', display: 'inline-flex', color: '#ffffff', verticalAlign: 'middle', minWidth: '25px', minHeight: '25px', justifyContent: 'center', alignItems: 'center', borderRadius: '13px', backgroundImage: 'linear-gradient(to right, #0097b2, #00578d)' }}>
                                                    <Edit style={{ fontSize: '18px', marginBottom: '-1px' }} />
                                                </ButtonClear>
                                            </Tooltip>}
                                            {userClasses.length > 1 && !isUserFromOtherPlatform && !isReviewAccount && (
                                                <Tooltip placement="bottom" title="Delete">
                                                    <ButtonClear onClick={() => setDeleteToggle(true)} style={{ marginLeft: '6px', display: 'inline-flex', color: '#ffffff', verticalAlign: 'middle', minWidth: '25px', minHeight: '25px', justifyContent: 'center', alignItems: 'center', borderRadius: '13px', backgroundImage: 'linear-gradient(to right, #0097b2, #00578d)' }}>
                                                        <Close style={{ fontSize: '20px', marginBottom: '-1px' }} />
                                                    </ButtonClear>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                            </TitleContainer>
                            }
                            <MobileMenu className="TabMobMenuUpr">
                                <Dropdown className="TabMobMenu" overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                                    <ButtonLink onClick={e => e.preventDefault()}>
                                        {/* <MenuOutlined style={{ fontSize: '18px' }} /> */}
                                            <TuneIcon style={{ fontSize: '32px' }} />
                                            {/* <FilterOutlined /> */}
                                    </ButtonLink>
                                </Dropdown>
                            </MobileMenu>
                        </HeaderContainer>
                        {errorCodeClassUpdate &&
                            <FormHelperText error>Code class already exists</FormHelperText>
                        }
                    </div>
                    {(search.view == "home" || !search.view) && (
                        <>
                            <Section
                                description="Below are the chapters for the book. Clicking on a chapter will display the book."
                                header='Home'>
                                    <LoadingWrapper loading={loading} hasCotationLoaded={true}>
                                        <SentryWrap>
                                            <ChapterManagementBook
                                                userBooksList={getTeacherBook()}
                                                booksAllList={booksAllList}
                                                listBook={userBooksList}
                                                currentOrg={currentOrg}
                                            />
                                        </SentryWrap>
                                    </LoadingWrapper>
                            </Section>
                        </>
                    )}
                    {search.view === 'manage' && (
                        <>
                            <LoadingWrapper loading={loading}>
                                <SentryWrap>
                                    <ChapterManagementNew
                                        setShowClassData={setShowClassData}
                                        booksAllList={booksAllList}
                                        activities={getFilteredCloneAcivity()}
                                        userBooksList={getTeacherBook()}
                                        classActivities={classActivities}
                                        classQuizzes={classQuizzes}
                                        currentOrg={currentOrg}
                                        quizzes={getFilteredCloneQuiz()}
                                        handleCreateActivity={activity => {
                                            setActivityData(activity);
                                            setStudentsName([]);
                                            setDiffSection(false);
                                            toggleActivityDrawer(true);
                                        }}
                                        handleEditActivity={activity => {
                                            openEditActivity(activity);
                                        }}
                                        handleDeleteActivity={activity => {
                                            setActivityData({
                                                delete: true,
                                                ...activity
                                            });
                                            setdeleteClassAssignedActivityToggle(true);
                                        }}
                                        handleCreateQuiz={quiz => {
                                            setQuizData(quiz);
                                            setStudentsName([]);
                                            setDiffSection(false);
                                            toggleQuizDrawer(true);
                                        }}
                                        handleDeleteQuiz={quiz => {
                                            setQuizData({
                                                delete: true,
                                                ...quiz
                                            });
                                            setdeleteClassAssignedQuizToggle(true);
                                        }}
                                        handleEditQuiz={quiz => {
                                            openEditQuiz(quiz);
                                        }}
                                    />
                                </SentryWrap>
                            </LoadingWrapper>
                        </>
                    )}
                    {search.view === 'gradebook' && (
                        <>
                            <Gradebook
                                handleEditQuiz={quiz => {
                                    openEditQuiz(quiz);
                                }}
                                handleEditActivity={activity => {
                                    openEditActivity(activity);
                                }}
                                onswithClass={onswithClass}
                            />
                        </>
                    )}
                    {search.view === 'classdata' && (
                        <>
                            {/* {showClassData && (
                                <>
                                    <Section header='Class Data'>
                                    <LoadingWrapper loading={loading}>
                                        <SentryWrap>
                                            <AdminVisuals
                                                activities
                                            />
                                        </SentryWrap>
                                    </LoadingWrapper>
                                </Section>
                                </>
                            )} */}
                            <>
                                <Section header='Class Data'>
                                    <LoadingWrapper loading={loading}>
                                        <SentryWrap>
                                            <AdminVisuals
                                                activities
                                            />
                                        </SentryWrap>
                                    </LoadingWrapper>
                                </Section>
                            </>
                        </>
                    )}
                </>
                </Col>
                {(search.view == "home" || !search.view)&&  <Col className="sidebarAdmnDash assignment-list-dashboard" xs={{ span: 24 }} sm={24} lg={{ span:  search.view != "home" && 7 }} xl={{span: search.view == "home" && 7}} style={{minWidth: deviceType ? "95%" : undefined}}>
                    <div className='teacherAssignmentList'>
                      <AssignmentList/>
                    </div>
                </Col>}
            </Row>
        </>
    );
};

//  -- Prop types ---------------------
AdminDashboard.propTypes = {};

//  -- Default props ------------------
AdminDashboard.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    activities: state.activities,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    quizzes: state.quizzes,
    userClasses: state.userClasses,
    userBooksList: state.userBooksList,
    booksAllList: state.booksAllList,
    schools: state.schools,
    users: state.users,
    classUsers: state.classUsers,
    submissions: state.submissions,
    classNotes: state.classNotes,
    classQuestions: state.classQuestions,
    selectedUser: state.selectedUser,
    schoologyAuth: state.schoologyAuth,
});

export default connect(mapStateToProps, null)(AdminDashboard);
