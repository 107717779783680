//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

//  -- Components ---------------------
import { Dropdown, Menu } from 'antd';
import { Button, IconButton } from '@material-ui/core';
import { Add, Block, ThumbUp, ThumbUpOutlined } from '@material-ui/icons';
import PrintIcon from '@material-ui/icons/Print';
import { MenuOutlined } from '@ant-design/icons';
import Loading from 'components/Loading';
import Note from 'components/NoteNew';
import Question from 'components/QuestionsByStudent';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------
import NoteForm from 'features/forms/Note';
import QuestionForm from 'features/forms/Question';
import PageEditor from 'features/forms/PageEditor';

//  -- Thunks -------------------------
import { handleCreateLike, handleDeleteLike } from 'store/likes/thunks';
import { handleCreateBubbleNote } from 'store/notes/thunks';
import { handleCreateQuestion } from 'store/questions/thunks';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import {filter , get } from "lodash"
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const AssignmentDrawer = styled.div`
    background-color: ${COLOR.white};
    height: 100%;
    overflow: scroll;
    position: absolute;
    left: ${p => p.active ? '0px' : 'calc(100% + 32px)'};
    top: 32px;
    transition: left 300ms ease, width 300ms ease;
    width: ${p => p.active ? '100%' : '0px'};
    z-index: 1;
`;

const ButtonClear = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 18px;
    outline: none;
    padding: 0px;
`;

const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black200};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 2px 0px;
    text-transform: uppercase;
`;

const CreationDrawer = styled(AssignmentDrawer)`
    height: 100%;
    padding: ${p => p.active ? '24px 12px' : '0px'};
`;

const EmptyState = styled.div`
    align-items: center;
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px;
`;

const EmptyStateText = styled.span`
    color: ${COLOR.black500};
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin: 8px 0px 0px;
    text-align: center;
`;

const FormDescription = styled.span`
    color: ${COLOR.black400};
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 24px;
`;

const FormHeader = styled.span`
    color: ${COLOR.black200};
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
`;



const Page = styled.div`
    align-items: center;
    color: ${COLOR.black100};
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    width: 80px;
`;

const Section = styled.div`
    border-bottom: 1px dashed ${COLOR.black700};
    padding: 0px 0px 24px;
    margin-bottom: 16px;

    &:first-child {
        border-bottom: none;
        margin-bottom: 0px;
        padding: 0px;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0px;
    }
`;

const SectionHeader = styled.span`
    align-items: center;
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 8px;
    text-transform: uppercase;
    width: 100%;
`;

const Toolbar = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black600};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 4px;
    width: 100%;
`;

const Wrapper = styled.div`
    height: 100%;
    min-height: 600px;
    width: 100%;
    overflow: hidden;
    position: relative;
`;


//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook tooling
//
//  -- Description
//  Ebook student tooling
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const NewsletterTooling = ({
    currentClass,
    currentOrg,
    currentUser,
    id,
    likes,
    limit,
    notes,
    page,
    questions,
    handleImagePrint,
    printLoader
}) => {
    const dispatch = useDispatch();
    const [creationType, setCreationType] = useState(null);
    const [likeLoading, setLikeLoading] = useState(false);
    const pageLike = filter(likes,x => (x.page == page && x.chapter === id));
    const pageNotes = filter(notes,x => (x.page == page && x.chapter === id));
    const pageQuestions = filter(questions,x => (x.page === page && x.chapter === id));
    const isDemo= currentUser?.demo || false
    const isReviewAccount = currentUser?.reviewAccount || false
    const orgPermissions = useCurrentOrgPermission()
    const hasPrintOption = get(orgPermissions,"printing",true)
    const createNote = (data, color) => {
        dispatch(handleCreateBubbleNote({
            content: data,
            color: color,
            chapter: id,
            page,
            userId: currentUser?.id,
            classId: currentClass.id,
            orgId: currentOrg?.id
        }));
        setCreationType(null);
    };

    const createQuestion = (data) => {
        dispatch(handleCreateQuestion({
            content: data,
            chapter: id,
            page,
            userId: currentUser?.id,
            classId: currentClass.id,
            orgId: currentOrg?.id
        }));
        setCreationType(null);
    };

    const toolbarNav = () => {
        return (
            <Menu>
                <Menu.Item>
                    <ButtonClear onClick={() => setCreationType('note')}>
                        Add Note
                    </ButtonClear>
                </Menu.Item>
                <Menu.Item>
                    <ButtonClear onClick={() => setCreationType('question')}>
                        Add Question
                    </ButtonClear>
                </Menu.Item>
            </Menu>
        )
    };

    return (
        <Wrapper>
            <Toolbar>
                <Page>
                {(isDemo||isReviewAccount||!hasPrintOption)?null:
                    <IconButton onClick={handleImagePrint} aria-label="print" disabled={printLoader}>
                    {printLoader?<Loading size={20} />: <PrintIcon />}  
                    </IconButton> }
                    {pageLike?.length > 0 ? (
                        <ButtonClear
                            onClick={async () => {
                                setLikeLoading(true);
                                await dispatch(handleDeleteLike(pageLike[0].id));
                                setLikeLoading(false);
                            }}
                            style={{ marginRight: '6px' }}
                        >
                            {likeLoading ? (
                                <Loading size={18} />
                            ) : (
                                <ThumbUp style={{ color: COLOR.green300, fontSize: '18px' }} />
                            )}
                        </ButtonClear>
                    ) : (
                        <ButtonClear
                            onClick={async () => {
                                setLikeLoading(true);
                                await dispatch(handleCreateLike({ chapter: id, page, userId: currentUser?.id, orgId: currentOrg?.id, classId: currentClass.userId }));
                                setLikeLoading(false);
                            }}
                            style={{ marginRight: '6px' }}
                        >
                            {likeLoading ? (
                                <Loading size={18} />
                            ) : (
                                <ThumbUpOutlined style={{ fontSize: '18px' }} />
                            )}
                        </ButtonClear>
                    )}
                    Pg.
                    <PageEditor
                        activity={null}
                        chapter={id}
                        limit={limit}
                        page={page}
                        quiz={null}
                        value={page}
                        numberMasked={page}
                    />
                </Page>
                <Dropdown overlay={toolbarNav()} placement="bottomRight" trigger={['click']}>
                    <ButtonLink onClick={e => e.preventDefault()}>
                        menu
                        <MenuOutlined style={{ marginLeft: '4px' }} />
                    </ButtonLink>
                </Dropdown>
            </Toolbar>

            <div>
                {/* <Section>
                    <SectionHeader>
                        Notes
                        <Button
                            color="primary"
                            onClick={() => setCreationType('note')}
                            style={{ fontSize: '12px' }}
                        >
                            <Add style={{ marginRight: '4px', fontSize: '13px' }} />
                            Add Note
                        </Button>
                    </SectionHeader>
                    {pageNotes.length > 0 ? (
                        pageNotes.map(x => <Note key={x.id} note={x} />)
                    ) : (
                        <EmptyState>
                            <Block style={{ color: COLOR.black600, fontSize: '40px' }} />
                            <EmptyStateText>You haven't created any notes on this page.</EmptyStateText>
                        </EmptyState>
                    )}
                </Section>
                {/* {currentUser?.role === 'student' && (
                    <Section>
                        <SectionHeader>
                            Questions
                            <Button
                                color="primary"
                                onClick={() => setCreationType('question')}
                                style={{ fontSize: '12px' }}
                            >
                                <Add style={{ marginRight: '4px', fontSize: '13px' }} />
                                Add Question
                            </Button>
                        </SectionHeader>
                        {pageQuestions.length > 0 ? (
                            pageQuestions.map(x => <Question key={x.id} question={x} />)
                        ) : (
                            <EmptyState>
                                <Block style={{ color: COLOR.black600, fontSize: '40px' }} />
                                <EmptyStateText>You haven't created any questions on this page.</EmptyStateText>
                            </EmptyState>
                        )}
                    </Section>
                )} */}
            </div>

            <CreationDrawer active={creationType}>
                {creationType === 'note' ? (
                    <>
                        <FormHeader>Add a Note</FormHeader>
                        <FormDescription>Students will have their notes for the entire school year, motivate them to write as many notes as they need. This is a great tool to keep their work organized. You can write your notes here as well, keep track of any important information.</FormDescription>
                        <NoteForm handleClose={() => setCreationType(null)} handleSubmit={createNote} />
                    </>
                ) : (
                    <>
                        <FormHeader>Ask a Question</FormHeader>
                        <FormDescription>This a great tool to check the student understanding of the work being assimilated. You can ask them to explain what they learn or check for understanding throughout the class.</FormDescription>
                        <QuestionForm handleClose={() => setCreationType(null)} handleSubmit={createQuestion} />
                    </>
                )}
            </CreationDrawer>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
NewsletterTooling.propTypes = {};

//  -- Default props ------------------
NewsletterTooling.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    likes: state.likes,
    notes: state.notes,
    questions: state.questions,
});

export default connect(mapStateToProps, null)(NewsletterTooling);
