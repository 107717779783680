import React from "react"
import EmptyLessonPlaceholder from "utils/assets/images/empty-lesson-placeholder.svg";

export const EmptyImagePlaceholder = ({noData = 'No hay lecciones'}) =>{
    return (
      <div style={{display: "flex", position: "relative",justifyContent:"center",height: "100%",alignItems: "center"}}>
      <div>
        <div className="No-hay-lesson">
        <div>{noData}</div>
        <div>PARA MOSTRAR</div>
        </div>
        <img src={EmptyLessonPlaceholder} className="empty-lesson-placeholder" alt="text" />
      </div>
      </div>
    )
  }