//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Add, Block } from "@material-ui/icons";
import PrintIcon from "@material-ui/icons/Print";
//  -- Components ---------------------
import { Dropdown, Menu } from "antd";
import AssignableNotification from "components/AssignableNotification";
import AssignedNotification from "components/AssignedNotification";
import AssignmentNotification from "components/AssignmentNotification";
import AssignmentPreview from "components/AssignmentPreview";
import Interaction from "components/Interaction";
import Loading from "components/Loading";
import Note from "components/NoteNew";
import PastAssignment from "components/PastAssignment";
import Question from "components/QuestionsByStudent";
//  -- Features -----------------------
import Assignment from "features/Assignment";
import NoteForm from "features/forms/Note";
import PageEditor from "features/forms/PageEditor";
import QuestionForm from "features/forms/Question";
import _, { filter, find, findIndex } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
//  -- Thunks -------------------------
import { handleCreateBubbleNote } from "store/notes/thunks";
import { handleCreateQuestion } from "store/questions/thunks";
import styled from "styled-components";
import {
  getBookData,
  getPageData,
  modifyAssignmentForStudent,
} from "utils/lib/ebookHelper";
import Schoology from "utils/Schoology";
//  -- Constants ----------------------
import { Modal } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import Alert from "@material-ui/lab/Alert";
import { Col, Drawer, Row } from "antd";
import LessionPlanPrinter from "components/LessionPlanPrinter";
import AssignmentForm from "features/forms/AssignmentForm";
import FinalExamAssignment from "features/forms/FinalExamAssignment";
import { get, map } from "lodash";
import "moment-timezone";
import { useMemo } from "react";
import { useReactToPrint } from "react-to-print";
import { useAllBook } from "store/books/selectors";
import {
  useCurrentOrgPermission,
  useOrgTimezone,
} from "store/organizations/selectors";
import { useServertime } from "store/servertime/selectors";
import COLOR from "utils/constants/color";
import { isBeforTime } from "utils/methods/math";
import {
  useMainbookView,
  useSentenceView,
} from "store/main_book_popup/selectors";
import useMobileDeviceDetection from "containers/chat/hooks";
import { bubbleNotes, UpdatedNoteIcon } from "utils/assets/images";
import LinkList from "components/HyperlinkViewer";
import { useShareNotification } from "utils/methods/firebaseShareAssignment";
import Draggable from "react-draggable";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import CancelIcon from "@material-ui/icons/Cancel";
import { SearchOutlined } from "@ant-design/icons";
import { Edit } from "@material-ui/icons";
import BubbleNotes from "components/Notes";
import  NoActivity from "utils/assets/images/noActivity.svg";
import GraphicOrganizerAssignments from 'components/GraphicOrganizerAssignment';
import GraphicAssignmentPreview from 'components/GraphicOrganizerAssignmentPreview';

const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    background-color: #7978c7 !important;
    border: none;
    padding: 0; /* Optional: remove padding for tighter fit */
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  color: white;
  border-bottom: 1px dotted white;
  padding: 10px;
  &:hover {
    background-color: inherit !important; /* Keeps the same background color */
  }
`;

const ButtonClear = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

// Main component with hamburger menu
const DropdownMenu = ({ isStudent, isTeacher, navigate }) => {
  const menu = (
    <Menu style={{ backgroundColor: "#7978c7" }}>
      {isStudent && (
        <StyledMenuItem key="calendar">
          <ButtonClear onClick={() => navigate("calendar")}>
            Calendar
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isStudent && (
        <StyledMenuItem key="studentData">
          <ButtonClear onClick={() => navigate("studentData")}>
            Student Data
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isTeacher && (
        <StyledMenuItem key="manage">
          <ButtonClear onClick={() => navigate("manage")}>
            Assignments
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isTeacher && (
        <StyledMenuItem key="gradebook">
          <ButtonClear onClick={() => navigate("gradebook")}>
            Gradebook
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isTeacher && (
        <StyledMenuItem key="classdata">
          <ButtonClear onClick={() => navigate("classdata")}>
            Class Data
          </ButtonClear>
        </StyledMenuItem>
      )}
    </Menu>
  );

  return (
    <StyledDropdown
      overlay={menu}
      trigger={["click"]} // Dropdown opens on click
      placement="bottomLeft" // Opens from the bottom-left
    >
      <IconButton aria-label="menu" style={{ height: 30, width: 30 }}>
        <MenuIcon style={{ fontSize: "24px", color: "#ffffff" }} />{" "}
        {/* Hamburger icon */}
      </IconButton>
    </StyledDropdown>
  );
};

const AssignmentDrawer = styled.div`
  background-color: #fcfcfc;
  height: 100%;
  overflow: scroll;
  position: absolute;
  left: ${(p) => (p.active ? "0px" : "calc(100% + 32px)")};
  top: 50px;
  transition: left 300ms ease, width 300ms ease;
  width: ${(p) => (p.active ? "100%" : "0px")};
  z-index: 1;
`;

// const ButtonClear = styled.button`
//     background-color: transparent;
//     border: none;
//     color: ${COLOR.white};
//     cursor: pointer;
//     height: 18px;
//     outline: none;
//     padding: 0px;
//     transition: all 300ms ease;

//     &:hover {
//         color: ${COLOR.black};
//     }
// `;

const ButtonLink = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${COLOR.black200};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 2px 0px;
  text-transform: uppercase;
`;

const CreationDrawer = styled(AssignmentDrawer)`
  height: 100%;
  padding: ${(p) => (p.active ? "24px 12px" : "0px")};
`;

const EmptyState = styled.div`
  align-items: center;
  border: 1px dashed ${COLOR.black700};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
`;

const EmptyStateText = styled.span`
  color: ${COLOR.black500};
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin: 8px 0px 0px;
  text-align: center;
`;

const FormDescription = styled.span`
  color: ${COLOR.black400};
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24px;
`;

const FormHeader = styled.span`
  color: ${COLOR.black200};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const Page = styled.div`
  align-items: center;
  color: ${COLOR.black100};
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  width: 80px;
`;

const Section = styled.div`
  border-bottom: 1px dashed ${COLOR.black700};
  padding: 0px 0px 24px;
  margin-bottom: 16px;
  position: relative;
  &:first-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding: 0px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`;
const PartitonDotted = styled.div`
  border-bottom: 1px dashed ${COLOR.black};
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  margin-top: 16px;
`;
const SectionHeader = styled.span`
  align-items: center;
  color: ${COLOR.black200};
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 8px;
  text-transform: uppercase;
  width: 100%;
`;

const Toolbar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${COLOR.black600};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
`;

const Wrap = styled.div`
  align-items: flex-start;
  border-bottom:${p=>p.empty?'0px':'1px'} dashed ${COLOR.black800};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${p=>p.empty?'0px':'0px 0px'};
  padding-left: 10px;
  padding-top:${p => p.empty ? '0px' : '0px'};
  padding-bottom:${p => p.empty ? '0px' : '0px'};
  flex-wrap: wrap;
  justify-content: start;
`;

const Wrapper = styled.div`
  height: 100%;
  // min-height: 600px;
  min-height: 955px;
  width: 100%;
  background-color: #efefef;
  position: relative;

     @media (max-width: 1367px){
        min-height:unset;
    }
  
`;
// overflow: hidden;

const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Video = styled.video`
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: auto;
  outline: none;
  width: 100%;
`;
const TimmerWrapper = styled.div`
  color: ${COLOR.black100};
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  left: 45%;
`;

const LessionPlan = styled.button`
    background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    // border: none;
    border:1px solid #015ECC;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 27.2px;
    color: white;
    z-index: 1;
    padding: 1px;
    font-size: 12.1px;
    font-weight: 400;
    margin-left:4px;

    @media only screen and (max-width: 1367px){
      margin-top:5px;
    }

      @media (max-width: 992px) {
        // display:none;
      }
}
`;
const LessionPlanInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  padding-left: 5px;
  padding-right: 5px;
`;
const AssignmentPlan = styled.button`
    // background-image: linear-gradient(to right,${COLOR.indigo500}, ${COLOR.indigo600});
    background-color: #020A52;
    // border: none;
    border:1px solid #020A52;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 27.2px;
    color: white;
    z-index: 1;
    padding: 1px;
    font-size: 12.1px;
    font-weight: 400;
    margin-left: 4px;

    @media only screen and (max-width: 1367px){
      margin-top:5px;
    }
}
`;
const AssignmentPlanInner = styled.div`
  display: flex;
  // flex-direction: column;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  padding-left: 6px;
  padding-right: 6px;
`;
const LearnPlanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
`;
const PlannerWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
`;

const LessionPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 90%;
  position: relative;
  justify-content: center;
  padding: 20px;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const CloseBtn = styled.div`
  background-color: rgb(204, 80, 71);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;
const ImageContainer = styled.div`
  display: flex;
`;
const Images = styled.img`
  background-color: ${COLOR.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  object-fit: cover;
  height: 600px;
  @media (max-width: 768px) {
    height: 400px;
  }
  @media (min-width: 768px) {
    height: 620px;
  }
  @media (min-width: 1600px) {
    height: 700px;
  }
  @media print {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
const LessionImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  background-color: transparent;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;
const LessionContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  max-width: 1320px;
  padding: 64px 0px 0px;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (min-width: 997px) {
    flex-direction: row;
    padding: 0px 30px;
  }
`;
const ButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  color: ${COLOR.black600};
  cursor: pointer;
  height: 40px;
  left: ${(p) => `${p.left}px`};
  outline: none;
  position: absolute;
  right: ${(p) => `${p.right}px`};
  top: 50%;
  transition: all 300ms ease;
  width: 40px;
  z-index: 1;
`;

const SearchArea = styled.div`
  border-radius: 20px;
  height: 38px;
  width: 345px;
  display: flex;
  flex-direction: row;
  // padding:2px;
  // background: #3c7275;
  background: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-left: 6px;
`;
const SearchIconArea = styled.div`
  width: 45px;
  height: 38px;
  // border-radius: 50%;
  border-radius: 50% 0% 0% 50%;
  background: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SearchInput = styled.input`
  width: 90%;
  height: 30px;
  font-size: 15px;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 5px;
  // color:#fff;
  color: black;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR.white};
  }
`;

const ParentContainer = styled.div`
// max-height: 793px;
    max-height: ${({ isStudent }) => (isStudent ? '885px' : '793px')};
    height: ${p => p.isEmpty && !p.isStudent ? "793px" : "885px"};
    overflow: ${p=> p.isEmpty ? "hidden" :"auto"};
`;
const PrintWrapper = styled.div``;
const useStylesTips = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paddingList: {
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  rootListItem: {
    marginTop: "0px",
    marginBottom: "0px",
    textAlign: "justify",
  },
  guttersListItem: {
    paddingLeft: "3px",
    paddingRight: "1px",
  },
  scroll: {
    height: " 250px",
    overflowY: "auto",
  },
}));

const useStyles = makeStyles({
  root: {
    margin: "5px",
    // width: '175px'
  },
  sizeSmall: {
    width: "55px",
    height: "25px",
  },
  checkedSwitch: {
    transform: "translateX(30px) !important",
  },
  label: {
    textTransform: "uppercase",
  },
});

const Accordion = withStyles({
  root: {
    border: "1px solid white",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#03989e",
    color: "white",
    textTransform: "capitalize",
    borderBottom: "1px solid white",
    marginBottom: -1,
    minHeight: 30,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);

//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook tooling
//
//  -- Description
//  Ebook student tooling
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const EbookTooling = ({
  classUsers,
  activity,
  activities,
  chapter,
  classActivities,
  classQuizzes,
  currentClass,
  currentOrg,
  currentUser,
  interactions,
  likes,
  limit,
  notes,
  page,
  questions,
  quiz,
  quizzes,
  data,
  ebookFlag,
  setEbookFlag,
  ebookFlagTeacherEdition,
  setEbookFlagTeacherEdition,
  submissions,
  tips,
  existTeacherEdition,
  existShowAnswer,
  showCopyRight,
  showSpecialSupport,
  setShowSpecialSupport,
  studentViewFlag,
  setStudentViewFlag,
  handleImagePrint,
  setSubmitRef,
  timeInt,
  timmer,
  onFinish,
  onPageChage = () => null,
  printLoader,
  schoologyAuth,
  lessonPlan,
  rubrics,
  assignmentPlan,
  workbook_plan,
  main_book,
  booksAllList,
  pageSrc,
  book,
  learnPlanModals,
  setLearnPlanModals,
  links,
  dataBook,
  imageConfiguration= {}
}) => {
  const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId) || [];
  const deviceType = useMobileDeviceDetection();
  const dispatch = useDispatch();
  const history = useHistory();
  const servertime = useServertime();
  const timezone = useOrgTimezone();
  const [creationType, setCreationType] = useState(null);
  const bookAllList = useAllBook();
  const { listenToSentenceData } = useShareNotification();
  const [customAssignmentDrawer, setCustomAssignmentDrawer] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assignmentData, setAssignmentData] = useState({});
  const [activityDrawer, toggleActivityDrawer] = useState(false);
  const [learnPlanModal, setLearnPlanModal] = useState(false);
  const [assignmentPlanModal, setAssignmentPlanModal] = useState(false);
  const [showDraggable, setShowDraggable] = useState(false);
  const [showAccordin, setShowAccordin] = useState(true);
  // const [dimensions, setDimensions] = useState({});
  const [showAllNotes, setShowAllNotes] = useState(false);
  const isStudent = currentUser && currentUser.role === "student";
  const isTeacher = currentUser && currentUser?.role === "teacher";
  const [openGraphicAssignmentPrivew,setOpenGraphicAssignmentPrivew] = useState(false)
  const [graphicAssignment,setGraphicAssignment] = useState({})
  // lession plan
  const hasLessionPlanPage = lessonPlan
    ? find(lessonPlan, (p) => p.page == page)
    : null;
  const lessonPlanBook = hasLessionPlanPage
    ? find(booksAllList, (p) => p.id == hasLessionPlanPage.resourceId)
    : null;
  const lessionPlanPages = get(lessonPlanBook, "pages", []);
  const [lessonPageindex, setLessionPageindex] = useState(0);
  const [lessionPrintLoader, setlessionPrintLoader] = useState(false);

  // Sentence Starter
  const [sentenceData, setSentenceData] = useState({});

  // rubrics plan
  const hasRubricsPlanPage = rubrics
    ? find(rubrics, (p) => p.page == page)
    : null;
  const rubricsPlanBook = hasRubricsPlanPage
    ? find(booksAllList, (p) => p.id == hasRubricsPlanPage.resourceId)
    : null;
  const rubricsPlanPages = get(rubricsPlanBook, "pages", []);
  const [rubricsPageindex, setRubricsPageindex] = useState(0);
  const [rubricsPrintLoader, setRubricsPrintLoader] = useState(false);
  const [rubricsPlanModal, setRubricsPlanModal] = useState(false);

  // assignment plan
  const hasAssignmentPlanPage = assignmentPlan
    ? find(assignmentPlan, (p) => p.page == page)
    : null;
  const assignmentPlanBook = hasAssignmentPlanPage
    ? find(booksAllList, (p) => p.id == hasAssignmentPlanPage.resourceId)
    : null;
  const assignmentPlanPages = get(assignmentPlanBook, "pages", []);
  const [assignmentPageindex, setAssignmentPageindex] = useState(0);
  const [assignmentPrintLoader, setAssignmentPrintLoader] = useState(false);

  //workbook plan
  const hasWorkbookPlanPage = workbook_plan
    ? find(workbook_plan, (p) => p.page == page)
    : null;
  const WorkbookPlanBook = hasWorkbookPlanPage
    ? find(booksAllList, (p) => p.id == hasWorkbookPlanPage.resourceId)
    : null;
  const WorkbookPlanPages = get(WorkbookPlanBook, "pages", []);
  const [WorkbookPageindex, setWorkbookPageindex] = useState(0);
  const [workbookPlanModal, setWorkbookPlanModal] = useState(false);

  //main book
  const hasMain_bookPage = main_book
    ? find(main_book, (p) => p.page == page)
    : null;
  const mainBook = hasMain_bookPage
    ? find(booksAllList, (p) => p.id == hasMain_bookPage.resourceId)
    : null;
  const mainBookPages = get(mainBook, "pages", []);
  const { invokeMainbookView } = useMainbookView();
  const { invokeSentenceView } = useSentenceView();

  const [alertSakeBar, setAlertSakeBar] = useState({
    open: false,
    message: "",
  });
  const orgPermissions = useCurrentOrgPermission();
  const hasPrintOption = useMemo(
    () => get(orgPermissions, "printing", true),
    [orgPermissions]
  );
  const hasteacherEdition = useMemo(
    () => get(orgPermissions, "teacherEdition", true),
    [orgPermissions]
  );
  const isBookPrintable = useMemo(
    () => get(find(bookAllList, ["id", chapter]), "printable"),
    [chapter, bookAllList]
  );
  // ------new logic -------//
  const isAssessmentBook = useMemo(
    () => book && book.is_assessment_book,
    [book]
  );
  const bookData = getBookData(
    activities,
    quizzes,
    questions,
    notes,
    likes,
    interactions,
    classActivities,
    classQuizzes,
    chapter,
    currentClass.id,
    isAssessmentBook
  );
  const disableAssignment = isAssessmentBook;
  const hasAllAssignButton = false;
  // const hasAllAssignButton = ((chapter == 31 || chapter == 35 || chapter == 36 || chapter == 45 || chapter == 50 || chapter == 51) && ((bookData.classQuizzes.length == 0 && bookData.classActivities.length == 0) || (bookData.quizzes?.length == bookData.classQuizzes.length && bookData.classActivities.length == bookData.activities.length)) && (bookData.quizzes?.length > 0 || bookData.classQuizzes > 0))
  const isEntireExamAssigned =
    bookData.activities.length == bookData.classActivities.length &&
    bookData.quizzes?.length == bookData.classQuizzes.length;
  const remainingActivity = filter(
    bookData.activities,
    (activity) =>
      find(
        bookData.classActivities,
        (classActivity) => classActivity.activityId == activity.id
      ) == undefined
  );
  const remainingQuiz = filter(
    bookData.quizzes,
    (quiz) =>
      find(bookData.classQuizzes, (classQuiz) => classQuiz.quizId == quiz.id) ==
      undefined
  );
  const pageData = getPageData(
    bookData,
    page,
    quiz,
    activity,
    isAssessmentBook
  );
  const pageInteractions = pageData.pageInteractions;
  const videoSupport = pageData.videoSupport;
  const pageLike = pageData.pageLike;
  const pageNotes = pageData.pageNotes;
  const pageQuestions = pageData.pageQuestions;
  const availableActivity = modifyAssignmentForStudent(
    pageData.availableActivity,
    isStudent,
    false,
    currentUser,
    servertime,
    timezone,
    currentClass,
    getHiddenBooks
  );
  const assignableActivity = pageData.assignableActivity;
  const activeActivity = pageData.activeActivity;
  const availableQuiz = modifyAssignmentForStudent(
    pageData.availableQuiz,
    isStudent,
    true,
    currentUser,
    servertime,
    timezone,
    currentClass,
    getHiddenBooks
  );
  const assignableQuiz = pageData.assignableQuiz;
  const activeQuiz = pageData.activeQuiz;
  const backBtnRef = useRef(null);
  const forwardBtnRef = useRef(null);
  const client = new Schoology();
  const isDemo = currentUser?.demo || false;
  const isReviewAccount = currentUser?.reviewAccount || false;
  client.setToken(schoologyAuth);
  const onDeleteGetAssignment = async () => {
    // await dispatch(handleListActivities());
    // await dispatch(handleListQuizzes());
    // await dispatch(handleListCustomAssignment(get(currentUser, "role"), get(currentUser, "id")))
  };

  useEffect(() => {
    const unsubscribe = listenToSentenceData("admin_sentence", (data) => {
      setSentenceData(data?.admin_sentence);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    onPageChage(onPageSwipe);
  }, [onPageChage]);
  useEffect(() => {
    document.addEventListener("keydown", checkKey);
    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, []);
  const classes = useStyles();
  const classesTips = useStylesTips();

  const [studentsName, setStudentsName] = React.useState([]);
  const [studentsError, setStudentsError] = React.useState(false);
  const [diffSection, setDiffSection] = useState(false);

  let infoClassUSers = filter(classUsers, (x) => x.role === "student");
  let listStudents = [];
  for (var u in infoClassUSers) {
    listStudents.push({
      id: infoClassUSers[u].id,
      description: `${infoClassUSers[u].lastName}  ${infoClassUSers[u].firstName} (${infoClassUSers[u].username})`,
    });
  }

  const tipsTalented = filter(tips, (x) => x.texType === "talented");
  const tipsSpecial = filter(tips, (x) => x.texType === "special");

  let existSpecialSupport = false;
  //Bug Tips
  if (
    videoSupport.length > 0 ||
    tipsTalented.length > 0 ||
    tipsSpecial.length > 0
  ) {
    existSpecialSupport = true;
  }

  let existStudentView = false;
  if (availableActivity.length || availableQuiz.length > 0) {
    existStudentView = true;
  }

  const createNote = (data, color) => {
    dispatch(
      handleCreateBubbleNote({
        content: data,
        color: color,
        chapter,
        page,
        userId: currentUser?.id,
        classId: currentClass.id,
        orgId: currentOrg?.id,
      })
    );
    createNotesQuestion(data, "n");
    setCreationType(null);
  };
  const createNotesQuestion = (data, type) => {
    if (currentClass?.sectionId) {
      client
        .getEnrollmentsBySectionId(currentClass.sectionId)
        .then((enrollments) => {
          let recipient_ids = "";
          Promise.allSettled(
            map(enrollments?.enrollment, async (_enrl) => {
              if (_enrl.admin == 0) {
                return;
              }
              recipient_ids += _enrl.uid + ",";
            })
          ).then(() => {
            let message = `You have a ${
              type == "n" ? "notes" : "Question"
            } please look at :- https://test.nosotrosonline.com/?path=ebook?chapter=${chapter}_page=${page}_activity=null_quiz=null`;
            const _data = {
              subject: `${type == "n" ? "notes" : "Question"} on LAUSD`,
              message: message,
              recipient_ids: recipient_ids.substring(
                0,
                recipient_ids.length - 1
              ),
            };
            client
              .createMessages(_data)
              .then((res) => {})
              .catch((e) => {});
          });
        });
    }
  };
  const createQuestion = (data) => {
    dispatch(
      handleCreateQuestion({
        content: data,
        chapter,
        page,
        userId: currentUser?.id,
        classId: currentClass.id,
        orgId: currentOrg?.id,
      })
    );
    createNotesQuestion(data, "q");
    setCreationType(null);
  };
  const navigateToStudentDashboard = (to) => {
    switch (to) {
      case "calendar":
        history.push("/student/class?view=assessments");
        break;
      case "studentData":
        history.push("/student/class?view=studentData");
        break;
      default:
        history.push("/student/class?view=home");
        break;
    }
  };
  const navigateToTeacherDashboard = (to) => {
    switch (to) {
      case "manage":
        history.push("/admin/class?view=manage");
        break;
      case "gradebook":
        history.push("/admin/class?view=gradebook");
        break;
      case "classdata":
        history.push("/admin/class?view=classdata");
        break;
      default:
        history.push("/admin/class?view=home");
        break;
    }
  };

  // const toolbarNav = () => {
  //     return (
  //         <Menu style={{ backgroundColor: "rgb(121 120 199)" }}>
  //             {isStudent && (<Menu.Item style={{ borderBottom: "1px dotted white" }}>
  //                 <ButtonClear onClick={() => navigateToStudentDashboard("calendar")}>
  //                     calendar
  //                 </ButtonClear>
  //             </Menu.Item>)}
  //             {isStudent && (<Menu.Item>
  //                 <ButtonClear onClick={() => navigateToStudentDashboard("studentData")}>
  //                     Student Data
  //                 </ButtonClear>
  //             </Menu.Item>)}
  //             {isTeacher && (<Menu.Item>
  //                 <ButtonClear onClick={() => navigateToTeacherDashboard("manage")}>
  //                     Assignments
  //                 </ButtonClear>
  //             </Menu.Item>)}
  //             {isTeacher && (<Menu.Item>
  //                 <ButtonClear onClick={() => navigateToTeacherDashboard("gradebook")}>
  //                     Gradebook
  //                 </ButtonClear>
  //             </Menu.Item>)}
  //             {isTeacher && (<Menu.Item>
  //                 <ButtonClear onClick={() => navigateToTeacherDashboard("classdata")}>
  //                     Class Data
  //                 </ButtonClear>
  //             </Menu.Item>)}
  //         </Menu>
  //     )
  // };

  const closeAssignment = () => {
    history.push(
      `/ebook?chapter=${chapter}&page=${page}&activity=null&quiz=null`
    );
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const setFlagsShowAnswer = (flag) => {
    setEbookFlagTeacherEdition(false);
    setEbookFlag(flag);
    setStudentViewFlag(false);
  };

  const setFlagsTeacherEdition = (flag) => {
    setEbookFlagTeacherEdition(flag);
    setEbookFlag(false);
    setStudentViewFlag(false);
  };

  const setShowStudentView = (flag) => {
    setStudentViewFlag(flag);
    setEbookFlag(false);
    setEbookFlagTeacherEdition(false);
  };

  const handleChangeMultiSelect = (event) => {
    setStudentsError(false);
    setStudentsName(event.target.value);
  };

  function createArrayStudents(studentsName) {
    let arrayStudents = [];
    for (var s in studentsName) {
      let finded = listStudents.find((e) => e.description == studentsName[s]);
      if (finded) {
        arrayStudents.push({
          studentId: finded.id,
          description: finded.description,
        });
      }
    }
    return arrayStudents;
  }
  const assignmentPreivew = (assignment) => {
    try {
        const assignmentData = assignment.data ? assignment.data : assignment
        if(assignmentData.activity_type === "graphic_organizer"){
            setOpenGraphicAssignmentPrivew(true)
            setGraphicAssignment(assignmentData)
        }else{
            setAssignmentData(assignment.data ? assignment.data : assignment)
            setPreview(true)
        }
    } catch (error) {
    }
}
  const onPageSwipe = () => {
    setPreview(false);
    setAssignmentData({});
  };
  const isPastDue = (_quiz) => {
    if (_quiz && _quiz.dueDate && _quiz.lateDate) {
      return isBeforTime(_quiz.lateDate, servertime, timezone);
    } else if (_quiz && _quiz.dueDate) {
      return isBeforTime(_quiz.dueDate, servertime, timezone);
    } else {
      return false;
    }
  };
  const getSrcPage = () => {
    if (lessionPlanPages) {
      const page = lessionPlanPages[lessonPageindex];
      return page ? page.resource : null;
    }
  };
  const getSrcAssignmentPage = () => {
    if (assignmentPlanPages) {
      const page = assignmentPlanPages[assignmentPageindex];
      return page ? page.resource : null;
    }
  };
  const getSrcWorkbookPage = () => {
    if (
      WorkbookPageindex >= 0 &&
      WorkbookPlanPages &&
      WorkbookPlanPages.length > 0
    ) {
      const page = WorkbookPlanPages[WorkbookPageindex];
      return page ? page.resource : null;
    }
  };
  const getSrcRubricsPage = () => {
    if (rubricsPlanPages) {
      const page = rubricsPlanPages[rubricsPageindex];
      return page ? page.resource : null;
    }
  };
  const imageRef = useRef();
  const handleLessionImagePrint = useReactToPrint({
    content: () => imageRef.current,
    onBeforeGetContent: () => {
      setlessionPrintLoader(true);
      setAssignmentPrintLoader(true);
      setRubricsPrintLoader(true);
    },
    onBeforePrint: () => {
      setlessionPrintLoader(false);
      setAssignmentPrintLoader(false);
      setRubricsPrintLoader(false);
    },
    onAfterPrint: () => {
      setlessionPrintLoader(false);
      setAssignmentPrintLoader(false);
      setRubricsPrintLoader(false);
    },
  });
  const handlePrintLessons = () => {
    if (!hasPrintOption) {
      setAlertSakeBar({ open: true, message: "Restricted access (legend)" });
      return;
    }
    handleLessionImagePrint();
  };
  function checkKey(e) {
    let isLessonPlan =
      document.getElementsByClassName("lessonPlanPopUp").length > 0;
    let isRubricsPlan =
      document.getElementsByClassName("rubricsPopUp").length > 0;
    if (isLessonPlan || isRubricsPlan) {
      e = e || window.event;
      if (e.keyCode == "37") {
        if (backBtnRef.current && !backBtnRef.current.disabled) {
          backBtnRef.current.click();
        }
      } else if (e.keyCode == "39") {
        if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
          forwardBtnRef.current.click();
        }
      }
    }
  }
  const closeAlert = () => {
    setAlertSakeBar({ open: false, message: "" });
  };
  const hasActiveAssignmentDrawer = () => {
    const quizSubmissions = parseInt(
      get(
        find(submissions, ["assignmentId", activeQuiz?.data.id]),
        "submissions",
        0
      )
    );
    const quizRetake = parseInt(get(activeQuiz, "retakes", 0));
    const activitySubmissions = parseInt(
      get(
        find(submissions, ["assignmentId", activeActivity?.data.id]),
        "submissions",
        0
      )
    );
    const activityRetatke = parseInt(get(activeActivity, "retakes", 0)) + 1;

    if (
      (typeof quiz == "string" && quiz.length > 2 && quiz !== "null") ||
      (typeof activity == "string" &&
        activity.length > 2 &&
        activity !== "null")
    ) {
      return (
        quizSubmissions < quizRetake || activitySubmissions < activityRetatke
      );
    } else {
      return false;
    }
  };
  const redirectToMainBook = () => {
    const redirectTO = {
      page: page,
      chapter: chapter == 13 ? 7 : 8,
    };
    const activitiesAndQuizzes = [
      availableActivity,
      assignableActivity,
      availableQuiz,
      assignableQuiz,
      activeQuiz,
      activeActivity,
    ].find((activity) => activity && activity[0]);
    let hasRedirectTo = null;
    let assignment_data = null;
    if (activitiesAndQuizzes) {
      hasRedirectTo = get(activitiesAndQuizzes[0], "redirectTo");
      assignment_data = activitiesAndQuizzes[0];
    }
    if (hasRedirectTo && typeof hasRedirectTo === "object") {
      redirectTO.chapter = hasRedirectTo.chapter;
      redirectTO.page = hasRedirectTo.page;
    } else if (assignment_data) {
      redirectTO.chapter =
        assignment_data.chapter || assignment_data.data.chapter;
      redirectTO.page = assignment_data.page || assignment_data.data.page;
    }

    history.push(
      `/ebook?chapter=${redirectTO.chapter}&page=${redirectTO.page}&activity=null&quiz=null`
    );
  };
  const onBookClick = () => {
    const mainBookFromIndex = findIndex(
      mainBookPages,
      (p) => p.pageId == get(hasMain_bookPage, "from", 0)
    );
    const mainBookTo = findIndex(
      mainBookPages,
      (p) => p.pageId == get(hasMain_bookPage, "to", 0)
    );
    if (mainBookFromIndex >= 0 && mainBookTo >= 0) {
      const main_book_pages = Array.isArray(mainBookPages)
        ? mainBookPages.slice(mainBookFromIndex, mainBookTo)
        : [];
      invokeMainbookView(main_book_pages);
    }
  };

  const onSentanceClick = () => {
    invokeSentenceView(sentenceData);
  };

  const lessionPlanFromIndex = findIndex(
    lessionPlanPages,
    (p) => p.pageId == get(hasLessionPlanPage, "from", 0)
  );
  const lessionPlanTo = findIndex(
    lessionPlanPages,
    (p) => p.pageId == get(hasLessionPlanPage, "to", 0)
  );

  const assignmentPlanFromIndex = findIndex(
    assignmentPlanPages,
    (p) => p.pageId == get(hasAssignmentPlanPage, "from", 0)
  );
  const assignmentPlanTo = findIndex(
    assignmentPlanPages,
    (p) => p.pageId == get(hasAssignmentPlanPage, "to", 0)
  );

  const workbookPlanFromIndex = findIndex(
    WorkbookPlanPages,
    (p) => p.pageId == get(hasWorkbookPlanPage, "from", 0)
  );
  const workbookPlanTo = findIndex(
    WorkbookPlanPages,
    (p) => p.pageId == get(hasWorkbookPlanPage, "to", 0)
  );

  const rubricsPlanFromIndex = findIndex(
    rubricsPlanPages,
    (p) => p.pageId == get(hasRubricsPlanPage, "from", 0)
  );
  const rubricsPlanTo = findIndex(
    rubricsPlanPages,
    (p) => p.pageId == get(hasRubricsPlanPage, "to", 0)
  );

  const onBubbleNotesClick = () => {
    setShowDraggable((prevState) => !prevState);
  };
  const handleSeeAllNotesClick = () => {
    setShowAllNotes(!showAllNotes);
  };
  const notesData = [
    { page: "Page. 70", title: "Los cognados Falsos" },
    { page: "Page. 70", title: "Title" },
    { page: "Page. 70", title: "Title" },
    { page: "Page. 70", title: "Title" },
    { page: "Page. 70", title: "Title" },
    { page: "Page. 70", title: "Title" },
    { page: "Page. 70", title: "Title" },
    { page: "Page. 70", title: "Title" },
  ];
  const handleBackClick = () => {
    setShowAllNotes(false);
  };
  const onSaveAndClose = () => {
    setShowDraggable(!showDraggable);
  };

  const newMaskId = get(
    find(get(data, "content"), (k) => k.pageId == page),
    "numberMasked",
    page
  );

  let isEmpty;
 if (!isStudent) {
   isEmpty =
     pageInteractions?.length === 0 &&
     videoSupport.length === 0 &&
     tipsTalented.length === 0 &&
     tipsSpecial.length === 0 &&
     availableActivity?.length === 0 &&
     assignableActivity?.length === 0 &&
     availableQuiz?.length === 0 &&
     assignableQuiz.length === 0;
 } else {
   isEmpty =
     pageInteractions?.length === 0 &&
     videoSupport.length === 0 &&
     tipsTalented.length === 0 &&
     tipsSpecial.length === 0 &&
     availableQuiz?.length === 0 &&
     availableActivity?.length === 0 
 }

   const EmptySection = () => {
    return (
        <>
        {isEmpty && (
            <div className='empty-section-container'>
            <img src={NoActivity} alt='' />
            <div className='empty-text-label'>
            No activities to show on this page
            </div>
        </div>
        )}
        </>
        
    )
   }

  return (
    <div className="eBookTolScrn">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertSakeBar.open}
        autoHideDuration={3000}
        onClose={closeAlert}
      >
        <Alert
          onClose={() => {
            setAlertSakeBar({ open: false, message: "" });
          }}
          severity="error"
        >
          <strong>ALERT:</strong> {alertSakeBar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={learnPlanModal || learnPlanModals}
        onClose={() => {
          setLearnPlanModal(false);
          setLearnPlanModals(false);
        }}
        className={"lessonPlanPopUp"}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        <LearnPlanWrapper>
          <LessionPlanContainer>
            <CloseBtn
              onClick={() => {
                setLearnPlanModal(false);
                setLessionPageindex(0);
                setLearnPlanModals(false);
              }}
            >
              <Close
                style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
              />
            </CloseBtn>
            <div
              style={{
                position: "absolute",
                right: "40px",
                top: "0px",
                zIndex: "1",
              }}
            >
              <LessionPlanPrinter
                onPrintSingle={handlePrintLessons}
                printLoader={lessionPrintLoader}
                images={_.slice(
                  lessionPlanPages,
                  lessionPlanFromIndex > 0 ? lessionPlanFromIndex : 0,
                  lessionPlanTo
                )
                  .map((k) => k.resource)
                  .filter((k) => k)}
              />
            </div>
            {lessonPageindex > lessionPlanFromIndex && (
              <ButtonContainer
                ref={backBtnRef}
                disabled={lessonPageindex <= lessionPlanFromIndex}
                onClick={() => {
                  setLessionPageindex(lessonPageindex - 1);
                }}
                left={0}
              >
                <ArrowBackIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            {lessonPageindex < lessionPlanTo && (
              <ButtonContainer
                ref={forwardBtnRef}
                disabled={lessonPageindex >= lessionPlanTo}
                onClick={() => {
                  setLessionPageindex(lessonPageindex + 1);
                }}
                right={0}
              >
                <ArrowForwardIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            <LessionContainer>
              <PrintWrapper ref={imageRef}>
                <Images src={getSrcPage()} />
              </PrintWrapper>
            </LessionContainer>
          </LessionPlanContainer>
        </LearnPlanWrapper>
      </Modal>
      <Modal
        open={assignmentPlanModal}
        onClose={() => setAssignmentPlanModal(true)}
        className={"lessonPlanPopUp"}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        <LearnPlanWrapper>
          <LessionPlanContainer>
            <CloseBtn
              onClick={() => {
                setAssignmentPlanModal(false);
                setAssignmentPageindex(0);
              }}
            >
              <Close
                style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
              />
            </CloseBtn>

            <IconButton
              onClick={handlePrintLessons}
              aria-label="print"
              style={{
                position: "absolute",
                right: "40px",
                top: "0px",
                zIndex: "1",
              }}
              disabled={assignmentPrintLoader}
            >
              {assignmentPrintLoader ? <Loading size={20} /> : <PrintIcon />}
            </IconButton>
            {assignmentPageindex > assignmentPlanFromIndex && (
              <ButtonContainer
                ref={backBtnRef}
                disabled={assignmentPageindex <= assignmentPlanFromIndex}
                onClick={() => {
                  setAssignmentPageindex(assignmentPageindex - 1);
                }}
                left={0}
              >
                <ArrowBackIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            {assignmentPageindex < assignmentPlanTo && (
              <ButtonContainer
                ref={forwardBtnRef}
                disabled={assignmentPageindex >= assignmentPlanTo}
                onClick={() => {
                  setAssignmentPageindex(assignmentPageindex + 1);
                }}
                right={0}
              >
                <ArrowForwardIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            <LessionContainer>
              <PrintWrapper ref={imageRef}>
                <Images src={getSrcAssignmentPage()} />
              </PrintWrapper>
            </LessionContainer>
          </LessionPlanContainer>
        </LearnPlanWrapper>
      </Modal>

      {/* rubrics plan modal */}

      <Modal
        open={rubricsPlanModal}
        onClose={() => setRubricsPlanModal(true)}
        className={"rubricsPopUp"}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        <LearnPlanWrapper>
          <LessionPlanContainer>
            <CloseBtn
              onClick={() => {
                setRubricsPlanModal(false);
                setRubricsPageindex(0);
              }}
            >
              <Close
                style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
              />
            </CloseBtn>

            <IconButton
              onClick={handlePrintLessons}
              aria-label="print"
              style={{
                position: "absolute",
                right: "40px",
                top: "0px",
                zIndex: "1",
              }}
              disabled={rubricsPrintLoader}
            >
              {rubricsPrintLoader ? <Loading size={20} /> : <PrintIcon />}
            </IconButton>
            {rubricsPageindex > rubricsPlanFromIndex && (
              <ButtonContainer
                ref={backBtnRef}
                disabled={rubricsPageindex <= rubricsPlanFromIndex}
                onClick={() => {
                  setRubricsPageindex(rubricsPageindex - 1);
                }}
                left={0}
              >
                <ArrowBackIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            {rubricsPageindex < rubricsPlanTo && (
              <ButtonContainer
                ref={forwardBtnRef}
                disabled={rubricsPageindex >= rubricsPlanTo}
                onClick={() => {
                  setRubricsPageindex(rubricsPageindex + 1);
                }}
                right={0}
              >
                <ArrowForwardIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            <LessionContainer>
              <PrintWrapper ref={imageRef}>
                <Images src={getSrcRubricsPage()} />
              </PrintWrapper>
            </LessionContainer>
          </LessionPlanContainer>
        </LearnPlanWrapper>
      </Modal>
      {/* end of rubrics plan */}

      {/* Workbook plan modal start */}
      <Modal
        open={workbookPlanModal}
        onClose={() => setWorkbookPlanModal(true)}
        className={"lessonPlanPopUp"}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        <LearnPlanWrapper>
          <LessionPlanContainer>
            <CloseBtn
              onClick={() => {
                setWorkbookPlanModal(false);
                setWorkbookPageindex(0);
              }}
            >
              <Close
                style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
              />
            </CloseBtn>

            {/* <IconButton onClick={handlePrintLessons} aria-label="print"
                            style={{ position: "absolute", right: "40px", top: "0px", zIndex: "1" }}
                            disabled={assignmentPrintLoader}>
                            {assignmentPrintLoader ? <Loading size={20} /> : <PrintIcon />}
                        </IconButton> */}
            {WorkbookPageindex > workbookPlanFromIndex && (
              <ButtonContainer
                ref={backBtnRef}
                disabled={WorkbookPageindex <= workbookPlanFromIndex}
                onClick={() => {
                  setWorkbookPageindex(WorkbookPageindex - 1);
                }}
                left={0}
              >
                <ArrowBackIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            {WorkbookPageindex < workbookPlanTo && (
              <ButtonContainer
                ref={forwardBtnRef}
                disabled={WorkbookPageindex >= workbookPlanTo}
                onClick={() => {
                  setWorkbookPageindex(WorkbookPageindex + 1);
                }}
                right={0}
              >
                <ArrowForwardIos style={{ fontSize: "40px" }} />
              </ButtonContainer>
            )}
            <LessionContainer>
              <PrintWrapper ref={imageRef}>
                <Images src={getSrcWorkbookPage()} />
              </PrintWrapper>
            </LessionContainer>
          </LessionPlanContainer>
        </LearnPlanWrapper>
      </Modal>

      {/* Workbook plan modal end */}
      <AssignmentForm
        open={customAssignmentDrawer}
        onClose={() => setCustomAssignmentDrawer(false)}
        bookId={chapter}
        pageId={page}
        pageSrc={pageSrc}
      />

      <Row
        span={17}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "9px",
        }}
        className="ebookMobleView"
      >
        <TimmerWrapper>
          {timeInt !== 0 && currentUser?.role == "student" ? timeInt : ""}
        </TimmerWrapper>
        <PlannerWrapper>
          {/* {!preview && isTeacher ? (
            <Section
              style={{
                overflowY: "auto",
                position: "relative",
                marginBottom: 0,
                borderBottomWidth: 0,
                padding: 5,
              }}
              className="containNoteSection"
            >
              <SectionHeader className="NewAssignBtn ebookBtnHIdeDeskTop">
                <Button
                  color="primary"
                  onClick={() => setCreationType("note")}
                  style={{ fontSize: "12px", marginLeft: 10 }}
                  className="dontShowInDesktop"
                >
                  <Add style={{ marginRight: "4px", fontSize: "13px" }} />
                  Add Note
                </Button>
              </SectionHeader>
            </Section>
          ) : null} */}
        {!preview && isTeacher && (
          <>
            <AssignmentPlan
            className='ebooks-button'
            onClick={() => setCustomAssignmentDrawer(true)}
        //     style={{ color: "white",
        //     height: 27.2, backgroundColor:"#01989E",fontSize: "10px",
        //     width:85, marginRight: '4px', fontWeight:"700"
                        
        // }}
        >
            <AssignmentPlanInner><Add style={{ fontSize: '13px', fontWeight:700 }} /> Assignment</AssignmentPlanInner>
            </AssignmentPlan>
            
            </>
          )}
          {hasLessionPlanPage && currentUser?.role !== "student" ? (
            <LessionPlan
              onClick={() => {
                if (get(orgPermissions, "lessonPlan", true)) {
                  setLearnPlanModal(true);
                  setLessionPageindex(lessionPlanFromIndex);
                } else {
                  setAlertSakeBar({
                    open: true,
                    message: "Restricted access (legend)",
                  });
                }
              }}
            >
              <LessionPlanInner>Lesson Plan</LessionPlanInner>
            </LessionPlan>
          ) : null}
          {sentenceData?.id && (
            <AssignmentPlan onClick={onSentanceClick}>
              <AssignmentPlanInner>Sentence starters</AssignmentPlanInner>
            </AssignmentPlan>
          )}
          {hasMain_bookPage ? (
            <AssignmentPlan onClick={onBookClick}>
              <AssignmentPlanInner>Book</AssignmentPlanInner>
            </AssignmentPlan>
          ) : null}
          {hasWorkbookPlanPage && currentUser?.role !== "student" ? (
            <AssignmentPlan
              onClick={() => {
                setWorkbookPlanModal(true);
                setWorkbookPageindex(workbookPlanFromIndex);
              }}
            >
              <AssignmentPlanInner>Workbook</AssignmentPlanInner>
            </AssignmentPlan>
          ) : null}
          {hasAssignmentPlanPage && currentUser?.role !== "student" ? (
            <AssignmentPlan
              onClick={() => {
                setAssignmentPlanModal(true);
                setAssignmentPageindex(assignmentPlanFromIndex);
              }}
            >
              <AssignmentPlanInner>Quiz</AssignmentPlanInner>
            </AssignmentPlan>
          ) : null}
          {hasRubricsPlanPage && currentUser?.role !== "student" ? (
            <AssignmentPlan
              onClick={() => {
                setRubricsPlanModal(true);
                setRubricsPageindex(rubricsPlanFromIndex);
              }}
            >
              <AssignmentPlanInner>Rubrics</AssignmentPlanInner>
            </AssignmentPlan>
          ) : null}
        </PlannerWrapper>
      </Row>

      <Wrapper style={{minHeight: imageConfiguration?.clientHeight}} className="ebokToolUpr">
        <Toolbar>
          {/* Buttons */}

          <Row
            style={{
              paddingTop: "9px",
              paddingBottom: "11px",
              paddingLeft: "23px",
              paddingRight: "24px",
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "#012B70",
            }}
            className="ebookBtnView"
          >
            <Col
              span={5}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Page style={{ color: "#ffffff" }}>
                {!isDemo &&
                  !isReviewAccount &&
                  hasPrintOption &&
                  isBookPrintable && (
                    <IconButton
                      onClick={handleImagePrint}
                      style={{ padding: 0, color: "white" }}
                      aria-label="print"
                      disabled={printLoader}
                    >
                      {printLoader ? <Loading size={20} /> : <PrintIcon />}
                    </IconButton>
                  )}
                P.
                <PageEditor
                  activity={activity}
                  chapter={chapter}
                  limit={limit}
                  page={page}
                  numberMasked={newMaskId}
                  content={data?.content}
                  quiz={quiz}
                  value={page}
                />
              </Page>
            </Col>

            <Col
              span={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <div
                className="bubble-note-img"
                onClick={() => {
                  onBubbleNotesClick();
                }}
              >
                <img src={bubbleNotes} alt="Bubbleimg" />
              </div> */}
              {/* {showDraggable && ( */}
                <BubbleNotes
                  pageSrc={pageSrc}
                  data={data}
                  page={page}
                  newMaskNote={newMaskId}
                  currentUser={currentUser}
                  pageNotes={pageNotes}
                  chapter={chapter}
                  dataBook={dataBook}
                />
              {/* )} */}

              {/* <DropdownMenu
                isStudent={isStudent}
                isTeacher={isTeacher}
                navigate={
                  isTeacher
                    ? navigateToTeacherDashboard
                    : navigateToStudentDashboard
                }
              /> */}

              {/* <div className="bubble-notes">
                <div
                  className="bubble-note-img"
                  onClick={() => {
                    onBubbleNotesClick()
                    const getNumberMasked = get(
                      find(get(data, "content"), (k) => k.pageId == page),
                      "numberMasked",
                      page
                    )
                    console.log('getNumberMasked', getNumberMasked, 'image', pageSrc);
                  }}
                >
                  <img src={bubbleNotes} alt="Bubbleimg" />
                </div>

                {showDraggable && (
                  <ResizeAndDragabble
                   className="allNotesSection"
                    defaultValue={{
                      x: 150,
                      y: 205,
                      width: 532,
                      height: 674,
                    }}
                    minWidth={215}
                    minHeight={255}
                    // resize={(data) => setDimensions(data)}
                    // resizeEnd={(data) => setDimensions(data)}
                    showAccordin={showAccordin}
                  >
                    <div
                      className="checkListTopSection"
                      style={{
                        position: "relative",
                        width: "100%",
                        height: showAccordin ? "100%" : "0px",
                        boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                        padding: "20px",
                        paddingBottom: "50px",
                      }}
                    >
                      <div>
                        <div className="topNotesScection">
                          <SearchArea>
                            <SearchIconArea>
                              <SearchOutlined />
                            </SearchIconArea>
                            <SearchInput placeholder="" />
                          </SearchArea>
                          <IconButton color="primary" style={{ padding: 0 }}>
                            <Edit
                              style={{ fontSize: "30px", color: "#3E5F8F" }}
                            />
                          </IconButton>
                        </div>
                        {showAllNotes ? (
                          <div>
                            <div className="seeAllAndBackBtn">
                              <button>SEE ALL NOTES</button>
                              <button onClick={handleBackClick}>BACK</button>
                            </div>
                            {notesData.map((note, index) => (
                              <div
                                key={index}
                                className="notsMidTxt notsPageAndTitle"
                              >
                                <label>
                                  <span>{note.page}</span>
                                  <span> {note.title}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="notsMidTxt">
                            <p>Titulo: Los cognados Falsos</p>
                            <label>
                              <span>Page. 70</span>
                              <span> (Notes will be saved on this page)</span>
                              <button onClick={handleSeeAllNotesClick}>
                                SEE ALL NOTES
                              </button>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "#010A52",
                        height: "75px",
                        borderRadius: "0% 0% 20px 20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button className="notesSaveCloseBtn" onClick={onSaveAndClose}>
                        Save and Close
                      </button>
                    </div>
                  </ResizeAndDragabble>
                )}

                <DropdownMenu
                  isStudent={isStudent}
                  isTeacher={isTeacher}
                  navigate={
                    isTeacher
                      ? navigateToTeacherDashboard
                      : navigateToStudentDashboard
                  }
                />
              </div> */}
            </Col>
          </Row>
        </Toolbar>
        {/* <LinkList links={links} /> */}
        {/* Toogles buttons */}
        {!isStudent && (
          <div className="mt-10px">
            <Wrap className="ebokToolWrap" empty={!(existTeacherEdition && hasteacherEdition) && !existSpecialSupport && !(existShowAnswer || existStudentView) && !links}>
              <LinkList links={links} />
              {existTeacherEdition && hasteacherEdition && (
                <FormControlLabel
                  classes={{
                    root: classes.root,
                    // label: classes.label,
                    label: `${classes.label} ${classes.additionalSupportLabel}`,
                  }}
                  control={
                    <Switch
                      classes={{
                        sizeSmall: classes.sizeSmall,
                        checked: classes.checkedSwitch,
                      }}
                      checked={ebookFlagTeacherEdition}
                      onChange={(e) =>
                        setFlagsTeacherEdition(e.target.checked)
                      }
                      name="viewTeacherEdition"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Teacher Edition"
                />
              )}
              {existShowAnswer && (
                <FormControlLabel
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                  control={
                    <Switch
                      classes={{
                        sizeSmall: classes.sizeSmall,
                        checked: classes.checkedSwitch,
                      }}
                      checked={ebookFlag}
                      onChange={(e) =>
                        setFlagsShowAnswer(e.target.checked)
                      }
                      name="viewFlag"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Show Answers"
                />
              )}

              {existSpecialSupport && (
                <FormControlLabel
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                  control={
                    <Switch
                      classes={{
                        sizeSmall: classes.sizeSmall,
                        checked: classes.checkedSwitch,
                      }}
                      checked={showSpecialSupport}
                      onChange={(e) =>
                        setShowSpecialSupport(e.target.checked)
                      }
                      name="viewSpecialSupport"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Additional support"
                />
              )}
            </Wrap>
          </div>
        )}
        {/* end toogle buttons */}
        {!isStudent  && <GraphicOrganizerAssignments page={page} chapter={chapter} />}
        {!isStudent && <GraphicAssignmentPreview open={openGraphicAssignmentPrivew} setOpen={setOpenGraphicAssignmentPrivew} assignment={graphicAssignment} page_id={page}  bookId={chapter} />}
        <ParentContainer style={{maxHeight: imageConfiguration?.clientHeight - 200}} isEmpty={isEmpty} isStudent={isStudent}>
        <EmptySection />
        {pageInteractions?.length > 0 ? (
            <Section className="playAudio">
              {pageInteractions?.map((action) => (
                <Interaction key={action.id} data={action} />
              ))}
            </Section>
          ) : null}
          {!isStudent && disableAssignment && (
            <SectionHeader style={{ justifyContent: "flex-end" }}>
              <Button
                color="primary"
                onClick={redirectToMainBook}
                style={{ fontSize: "12px" }}
              >
                Please assign an assignment from{" "}
                {chapter == "13" ? "Anécdotas 1" : "Anécdotas 2"}
              </Button>
            </SectionHeader>
          )}
          {!isStudent && hasAllAssignButton && (
            <SectionHeader style={{ justifyContent: "flex-end" }}>
              <Button
                color="primary"
                onClick={() => toggleActivityDrawer(true)}
                style={{ fontSize: "12px" }}
              >
                {isEntireExamAssigned
                  ? " Edit Entire Exam"
                  : "Assign Entire Exam"}
              </Button>
            </SectionHeader>
          )}
          <Drawer
            height="100%"
            onClose={() => toggleActivityDrawer(false)}
            placement="top"
            open={activityDrawer}
            key="activityNew"
            className="assignments-drawer"
          >
            <CenterContainer>
              <FinalExamAssignment
                assignment={
                  remainingQuiz.length > 0 || remainingActivity > 0
                    ? remainingQuiz.length > 0
                      ? remainingQuiz[0]
                      : remainingActivity[0]
                    : bookData.classQuizzes[0]
                }
                listStudents={listStudents}
                handleChangeMultiSelect={handleChangeMultiSelect}
                diffSection={diffSection}
                setDiffSection={setDiffSection}
                studentsName={studentsName}
                studentsError={studentsError}
                activities={remainingActivity}
                quizzes={remainingQuiz}
                onClose={() => toggleActivityDrawer(false)}
                isEdit={isEntireExamAssigned}
                classActivities={classActivities}
                classQuizzes={classQuizzes}
              />
            </CenterContainer>
          </Drawer>
          <div
            style={{ paddingInline: "23px", backgroundColor: "#EFEFEF" }}
            className="ebookMobButton"
          >
            {showSpecialSupport && (
              <>
                {(videoSupport.length > 0 ||
                  tipsTalented.length > 0 ||
                  tipsSpecial.length > 0) && (
                  <>
                    <br></br>
                    {videoSupport.length > 0 && (
                      <Accordion
                        square
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography>Video Support</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Section className="playAudio">
                            <Video
                              onContextMenu={(e) => {
                                e.preventDefault();
                                showCopyRight();
                              }}
                              controls
                              controlsList="nodownload"
                              width="100%"
                            >
                              <source
                                src={videoSupport[0].content}
                                type="video/mp4"
                              />
                            </Video>
                          </Section>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {tipsTalented.length > 0 && (
                      <Accordion
                        square
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                        >
                          <Typography>Gifted & Talented</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{
                            root: classesTips.scroll,
                          }}
                        >
                          <List
                            component="nav"
                            className={classesTips.root}
                            aria-label="contacts"
                            classes={{
                              padding: classesTips.paddingList,
                            }}
                          >
                            {tipsTalented.map((item, key) => {
                              return (
                                <div>
                                  <ListItem
                                    key={key}
                                    button
                                    classes={{
                                      gutters: classesTips.guttersListItem,
                                    }}
                                  >
                                    <ListItemText
                                      disableTypography
                                      style={{ color: item.color }}
                                      primary={item.textTip}
                                      classes={{
                                        root: classesTips.rootListItem,
                                      }}
                                    />
                                  </ListItem>
                                </div>
                              );
                            })}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {tipsSpecial.length > 0 && (
                      <Accordion
                        square
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                      >
                        <AccordionSummary
                          aria-controls="panel3d-content"
                          id="panel3d-header"
                        >
                          <Typography>Special Needs</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{
                            root: classesTips.scroll,
                          }}
                        >
                          <List
                            component="nav"
                            className={classesTips.root}
                            aria-label="contacts"
                            classes={{
                              padding: classesTips.paddingList,
                            }}
                          >
                            {tipsSpecial.map((item, key) => {
                              return (
                                <div>
                                  <ListItem
                                    key={key}
                                    button
                                    classes={{
                                      gutters: classesTips.guttersListItem,
                                    }}
                                  >
                                    <ListItemText
                                      disableTypography
                                      style={{ color: item.color }}
                                      primary={item.textTip}
                                      classes={{
                                        root: classesTips.rootListItem,
                                      }}
                                    />
                                  </ListItem>
                                </div>
                              );
                            })}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </>
                )}
              </>
            )}
            <Section className="">
              {isStudent ? (
                <>
                  {availableActivity.length > 0
                    ? map(availableActivity, (act, i) => {
                        const isPast = isPastDue(act);
                        if (!isPast)
                          return (
                            <>
                              {i == 0 && (
                                <SectionHeader>Activities</SectionHeader>
                              )}
                              <AssignmentNotification
                                assignment={act}
                                onClick={() =>
                                  history.push(
                                    `/ebook?chapter=${chapter}&page=${page}&activity=${act.data?.id}&quiz=null`
                                  )
                                }
                                submissions={submissions}
                                userRol={currentUser?.role}
                                currentUser={currentUser}
                                currentClass={currentClass}
                              />
                            </>
                          );
                        return (
                          <>
                            {i == 0 && (
                              <SectionHeader>Activities</SectionHeader>
                            )}
                            <PastAssignment
                              assignment={act}
                              submissions={submissions}
                            />
                          </>
                        );
                      })
                    : null}
                  {availableQuiz.length > 0
                    ? map(availableQuiz, (act, i) => {
                        const isPast = isPastDue(act);
                        if (!isPast)
                          return (
                            <>
                              {i == 0 && (
                                <SectionHeader>Activities</SectionHeader>
                              )}
                              <AssignmentNotification
                                assignment={act}
                                onClick={() =>
                                  history.push(
                                    `/ebook?chapter=${chapter}&page=${page}&activity=null&quiz=${act.data?.id}`
                                  )
                                }
                                submissions={submissions}
                                userRol={currentUser?.role}
                                currentUser={currentUser}
                                currentClass={currentClass}
                              />
                            </>
                          );
                        return (
                          <>
                            {i == 0 && (
                              <SectionHeader>Activities</SectionHeader>
                            )}
                            <PastAssignment
                              assignment={act}
                              submissions={submissions}
                            />
                          </>
                        );
                      })
                    : null}
                </>
              ) : null}
              {!isStudent ? (
                <div>
                  {availableActivity.length > 0 && !preview
                    ? map(availableActivity, (act) => (
                        <AssignedNotification
                          assignment={act}
                          listStudents={listStudents}
                          handleChangeMultiSelect={handleChangeMultiSelect}
                          diffSection={diffSection}
                          setDiffSection={setDiffSection}
                          studentsName={studentsName}
                          studentsError={studentsError}
                          setStudentsName={setStudentsName}
                          currentClass={currentClass}
                          onPreviewCick={() => {
                            assignmentPreivew(act);
                          }}
                          isAssignedAllExam={
                            hasAllAssignButton && isEntireExamAssigned
                          }
                        />
                      ))
                    : null}
                  {assignableActivity.length > 0 && !preview
                    ? map(assignableActivity, (aAct) => (
                        <AssignableNotification
                          assignment={aAct}
                          allAssignable={activities}
                          listStudents={listStudents}
                          handleChangeMultiSelect={handleChangeMultiSelect}
                          diffSection={diffSection}
                          setDiffSection={setDiffSection}
                          studentsName={studentsName}
                          studentsError={studentsError}
                          disabled={disableAssignment}
                          chapter={chapter}
                          onPreviewCick={() => {
                            assignmentPreivew(aAct);
                          }}
                          onDeleteGetAssignment={() => {
                            onDeleteGetAssignment();
                          }}
                        />
                      ))
                    : null}

                  {availableQuiz.length && !preview
                    ? map(availableQuiz, (aq) => (
                        <AssignedNotification
                          assignment={aq}
                          allAssignable={quizzes}
                          listStudents={listStudents}
                          handleChangeMultiSelect={handleChangeMultiSelect}
                          diffSection={diffSection}
                          setDiffSection={setDiffSection}
                          studentsName={studentsName}
                          studentsError={studentsError}
                          setStudentsName={setStudentsName}
                          currentClass={currentClass}
                          onPreviewCick={() => {
                            assignmentPreivew(aq);
                          }}
                          isAssignedAllExam={
                            hasAllAssignButton && isEntireExamAssigned
                          }
                        />
                      ))
                    : null}
                  {assignableQuiz.length > 0 && !preview
                    ? map(assignableQuiz, (aq) => (
                        <AssignableNotification
                          assignment={aq}
                          listStudents={listStudents}
                          handleChangeMultiSelect={handleChangeMultiSelect}
                          diffSection={diffSection}
                          setDiffSection={setDiffSection}
                          studentsName={studentsName}
                          studentsError={studentsError}
                          disabled={disableAssignment}
                          chapter={chapter}
                          onPreviewCick={() => {
                            assignmentPreivew(aq);
                          }}
                        />
                      ))
                    : null}
                </div>
              ) : null}
              {preview ? (
                <AssignmentPreview
                  data={assignmentData}
                  onClose={onPageSwipe}
                />
              ) : null}
            </Section>

            {/* Custom assignment Area start  */}
            {!preview && isTeacher ? (
              <Section
                style={{
                  overflowY: "auto",
                  // backgroundColor:
                  //   pageNotes?.length > 0
                  //     ? "rgb(163 163 163 / 12%)"
                  //     : "transparent",
                  position: "relative",
                  marginBottom: 0,
                  borderBottomWidth: 0,
                  padding: 5,
                }}
                className="containNoteSection"
              >
                {/* #TODO Cna be enable after testing on prod. */}
                <SectionHeader className="NewAssignBtn ebookBtnHIde">
                  {/* <Button
                  color="primary"
                  onClick={() => setCustomAssignmentDrawer(true)}
                  style={{ fontSize: "12px" }}
                >
                  <Add style={{ marginRight: "4px", fontSize: "13px" }} />
                  Add Assignment
                </Button> */}
                  <Button
                    color="primary"
                    onClick={() => setCreationType("note")}
                    style={{ fontSize: "12px", marginLeft: 10 }}
                    className="dontShowInDesktop"
                  >
                    <Add style={{ marginRight: "4px", fontSize: "13px" }} />
                    Add Note
                  </Button>
                </SectionHeader>
              </Section>
            ) : null}
            {deviceType && isStudent && (
              <SectionHeader className="NewAssignBtn">
                {/* <Button
                                color="primary"
                                onClick={() => setCustomAssignmentDrawer(true)}
                                style={{ fontSize: '12px' }}
                            >
                                <Add style={{ marginRight: '4px', fontSize: '13px' }} />
                                Add Assignment
                            </Button> */}
                {/* <Button
                  color="primary"
                  onClick={() => setCreationType("note")}
                  style={{ fontSize: "12px", marginLeft: 10 }}
                  className="dontShowInDesktop"
                >
                  <Add style={{ marginRight: "4px", fontSize: "13px" }} />
                  Add Note
                </Button> */}
              </SectionHeader>
            )}
            {/* Custom assignment area end */}
            {/* {!preview ? (
              <Section
                style={{
                  overflowY: "auto",
                  // backgroundColor:
                  //   pageNotes?.length > 0
                  //     ? "rgb(163 163 163 / 12%)"
                  //     : "transparent",
                  position: "relative",
                  marginBottom: 0,
                  borderBottomWidth: 0,
                  padding: 5,
                }}
                className="containNoteSection"
              >
                <SectionHeader className="dontShowInMobile">
                  <Button
                    // color="primary"
                    onClick={() => setCreationType("note")}
                    className="note-button"
                    style={{ fontSize: "12px" }}
                  >
                    <Add style={{ marginRight: "4px", fontSize: "13px" }} />
                    Notes
                  </Button>
                </SectionHeader>
                {console.log("allnaveen", pageNotes)}
                {pageNotes.length > 0 ? (
                  pageNotes.map((x) => <Note key={x.id} note={x} />)
                ) : (
                  <EmptyState>
                    <img
                      style={{ color: COLOR.black600, fontSize: "40px" }}
                      src={UpdatedNoteIcon}
                      alt="empty-note-icon"
                    />
                    
                    <EmptyStateText>
                      You haven't created any notes on this page.
                    </EmptyStateText>
                  </EmptyState>
                )}
              </Section>
            ) : null} */}
            {/* <PartitonDotted /> */}

          </div>
          <CreationDrawer active={creationType}>
            {creationType === "note" ? (
              <>
                <FormHeader>Add a Note</FormHeader>
                <FormDescription>
                  Students will have their notes for the entire school year,
                  motivate them to write as many notes as they need. This is a
                  great tool to keep their work organized. You can write your
                  notes here as well, keep track of any important information.
                </FormDescription>
                <NoteForm
                  handleClose={() => setCreationType(null)}
                  handleSubmit={createNote}
                />
              </>
            ) : (
              <>
                <FormHeader>Ask a Question</FormHeader>
                <FormDescription>
                  This a great tool to check the student understanding of the
                  work being assimilated. You can ask them to explain what they
                  learn or check for understanding throughout the class.
                </FormDescription>
                {creationType ? (
                  <QuestionForm
                    handleClose={() => setCreationType(null)}
                    handleSubmit={createQuestion}
                  />
                ) : null}
              </>
            )}
          </CreationDrawer>
          <AssignmentDrawer active={hasActiveAssignmentDrawer()}>
            {activity && activeActivity ? (
              <Assignment
                chapter={chapter}
                currentSubmission={find(submissions, [
                  "assignmentId",
                  activeActivity?.data.id,
                ])}
                data={activeActivity?.data}
                shuffle={activeActivity?.shuffled}
                onClose={closeAssignment}
                page={page}
                retakes={activeActivity.retakes}
                userRol={currentUser?.role}
                setSubmitRef={setSubmitRef}
                dueDate={activeActivity.dueDate}
                timmer={timmer}
                onFinish={onFinish}
              />
            ) : null}
            {quiz && activeQuiz ? (
              <Assignment
                chapter={chapter}
                currentSubmission={find(submissions, [
                  "assignmentId",
                  activeQuiz?.data.id,
                ])}
                data={activeQuiz?.data}
                shuffle={activeQuiz?.shuffled}
                onClose={closeAssignment}
                page={page}
                retakes={activeQuiz.retakes}
                userRol={currentUser?.role}
                setSubmitRef={setSubmitRef}
                dueDate={activeQuiz.dueDate}
                timmer={timmer}
                onFinish={onFinish}
              />
            ) : null}
          </AssignmentDrawer>
        </ParentContainer>
      </Wrapper>
    </div>
  );
};


//  -- Prop types ---------------------
EbookTooling.propTypes = {};

//  -- Default props ------------------
EbookTooling.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  activities: state.activities,
  classActivities: state.classActivities,
  classQuizzes: state.classQuizzes,
  currentClass: state.currentClass,
  currentOrg: state.currentOrg,
  currentUser: state.currentUser,
  likes: state.likes,
  interactions: state.interactions,
  notes: state.notes,
  questions: state.questions,
  quizzes: state.quizzes,
  submissions: state.submissions,
  schoologyAuth: state.schoologyAuth,
});

export default connect(mapStateToProps, null)(EbookTooling);
