//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map, cloneDeep, findIndex, get } from "lodash"
import { sortMessage } from 'utils/methods/math';
import { filter } from 'lodash';
//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const chatUserInit = [];

export const chatUser = (state = chatUserInit, action) => {
    switch (action.type) {
        case ActionTypes.chat.users.setChatUser:
            return action.payload;
        case ActionTypes.chat.users.updateChatUser:
            return action.payload;
        case ActionTypes.chat.users.clearChatUser:
            return state;
        case ActionTypes.chat.status.setActive:
            return map(state, u => {
                return ({ ...u, user: u.user.map(k => k.id == action.payload ? ({ ...k, active: true }) : k) })
            });
        case ActionTypes.chat.status.deactive:
            return map(state, u => {
                return ({ ...u, user: map(u.user, k => k.id == action.payload ? ({ ...k, active: false }) : k) })
            });
        case ActionTypes.chat.users.updateChatUserConfig:
            return map(state, u => {
                return ({
                    ...u, user: map(u.user, k => {
                        if (k.id === get(action, "payload.id")) {
                            return ({ ...k, config: action.payload })
                        } else {
                            return k;
                        }
                    })
                })
            });
        default:
            return state;
    }
};
export const chatMessages = (state = chatUserInit, action) => {
    let newmsg;
    switch (action.type) {
        case ActionTypes.chat.message.setMessage:
            return action.payload;
        case ActionTypes.chat.message.createMessage:
            const newSate = new Set(cloneDeep(state))
            newSate.add(action.payload)
            return sortMessage(Array.from(newSate));
        case ActionTypes.chat.message.updateMessage:
            return sortMessage(action.payload);
        case ActionTypes.chat.message.updateseenDelivery:
            let newSatemsg = cloneDeep(state)
            map(action.payload, msgDt => {
                const hasIndex = findIndex(newSatemsg, ['id', msgDt.id])
                if (hasIndex >= 0) {
                    newSatemsg[hasIndex] = { ...newSatemsg[hasIndex], ...msgDt }
                } else {
                    return msgDt;
                }
            })
            return sortMessage(newSatemsg);
        case ActionTypes.chat.message.messageStore:
            newmsg = cloneDeep(state)
            newmsg = map(state, msgDt => {
                if (msgDt.id === action.payload) {
                    return ({ ...msgDt, quee: false })
                } else {
                    return msgDt;
                }
            })
            return sortMessage(newmsg);
        case ActionTypes.chat.message.userMessageSeenUpdate:
            newmsg = cloneDeep(state)
            newmsg = map(state, msgDt => {
                if (msgDt.senderId === action.payload && !msgDt.seen) {
                    return ({ ...msgDt, seen: true, deliverAt: new Date().toISOString() })
                } else {
                    return msgDt;
                }
            })
            return sortMessage(newmsg);
        default:
            return state;
    }
};

export const socketConnection = (state = null, action) => {
    switch (action.type) {
        case ActionTypes.chat.socket.setSocket:
            return action.payload;
        case ActionTypes.chat.socket.updateSocket:
            return action.payload;
        case ActionTypes.chat.socket.clearSocket:
            return null;
        default:
            return state;
    }
};
const initalChatConfig = {}
export const userChatConfig = (state = initalChatConfig, action) => {
    switch (action.type) {
        case ActionTypes.chat.chatUserConfig.setChatUserConfig:
            return action.payload;
        case ActionTypes.chat.chatUserConfig.updateChatUserConfig:
            return action.payload;
        case ActionTypes.chat.chatUserConfig.clearChatUserConfig:
            return null;
        default:
            return state;
    }
};
export const scheduledMessage = (state = [], action) => {
    const latestState = cloneDeep(state)
    switch (action.type) {
        case ActionTypes.chat.scheduledMessage.setScheduledMessage:
            return action.payload;
        case ActionTypes.chat.scheduledMessage.createScheduledMessage:
            latestState.push(action.payload)
            return latestState;
        case ActionTypes.chat.scheduledMessage.updateScheduledMessage:
            return map(latestState, k => {
                if (k.id === action.payload.id) {
                    return ({ ...k, ...action.payload })
                } else {
                    return k;
                }
            })
        case ActionTypes.chat.scheduledMessage.removeScheduledMessage:
            return filter(latestState, k => {
                return k.id !== action.payload && k.message.id != action.payload
            })
        case ActionTypes.chat.scheduledMessage.clearScheduledMessage:
            return [];
        default:
            return state;
    }
};