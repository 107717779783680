//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import styled from 'styled-components';
// -- Components ----------------------
import Loading from 'components/Loading';



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const ChildWrapper = styled.div`
    height: ${p => p.loading === 'true' ? '0px' : 'auto'};
    margin-top: ${p => p.loading === 'true' ? '32px' : '0px'};
    opacity: ${p => p.loading === 'true' ? 0 : 1};
    transition: margin-top 500ms ease, opacity 500ms ease;
`;

const Wrapper = styled.div`
    align-items: center;
    display: ${p => p.loading === 'true' ? 'flex' : 'none'};
    justify-content: center;
    padding: 24px 0px;
    width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Loading wrapper
//
//  -- Description
//  Loading component that controls loading display
//
//  -- Props
//  children {node} - child node passed to component
//  loading {bool} - loading flag indicator
//


const LoadingWrapper = ({ children, loading}) => {
return(
    <>
        <Wrapper loading={loading ? 'true' : 'false'}>
            <Loading />
        </Wrapper>
        <ChildWrapper loading={loading ? 'true' : 'false'}>
            {children}
        </ChildWrapper>
    </>
)};

//  -- Prop types ---------------------
LoadingWrapper.propTypes = {};

//  -- Default props ------------------
LoadingWrapper.defaultProps = {};

export default LoadingWrapper;
