//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash"
//  -- Actions ------------------------
import * as NotificationActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create Notification --------------------
export const handleCreateNotification = (data) => async(dispatch) => {
    // await dispatch(NotesActions.createNote());

    try {
        const notificationData = await API.post('notificationRDS', `/addNotification`, {
            body: { ...data }
        });
        await dispatch(NotificationActions.createNotificationSuccess(notificationData));
        return notificationData
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};

//  -- List user Notification ----------------
export const handleListNotification = (id) => async(dispatch) => {
    // await dispatch(NotesActions.listUserNotes());
    try {
        const notificationRDS = await API.get('notificationRDS', `/getNotification/${id}`);
        await dispatch(NotificationActions.setNotification(notificationRDS));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};


//  -- Delete user Notification ----------
export const handleDeleteNotification = (id) => async (dispatch) => {
    if (!id) return;
    try {
        await API.del('notificationRDS', `/deleteNotification/${id}`);
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

//  -- Update Mark All As Done -------------------
export const handleMarkAsDoneNotify = (data) => async (dispatch) => {
    try {
        const maskAsRead = await API.put('notificationRDS', `/seenNotification`, {
            body: { ...data }
        });
        await dispatch(NotificationActions.updateNotificationSeen(maskAsRead));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};