//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { filter , map } from 'lodash'

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const tipsBookInitial = {};

export const tipsBook = (state = tipsBookInitial, action) => {
    switch (action.type) {
        case ActionTypes.tips.listTipsBookSuccess:
            return action.payload;
        case ActionTypes.tips.listTipsBookFail:
            return state;
        case ActionTypes.tips.createTipsBookSuccess:
            return [action.payload, ...state];
        case ActionTypes.tips.updateTipsBookSuccess:
            return map(state,st=>(st.id==action.payload.id?action.payload:st))
        case ActionTypes.tips.deleteTipsBookSuccess:
            return filter(state,st=>st.id!=action.payload)
        default:
            return state;
    }
};