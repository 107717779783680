import { Modal } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { Drawer , Menu , Dropdown  , message} from 'antd';
import { filter, get, has, map } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

//  -- Components ---------------------
import { Close } from '@material-ui/icons';
import ActivityForm from 'features/forms/Activity';
import { find } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useActivities } from 'store/activities/selectors';
import { useAllBook } from 'store/books/selectors';
import { handleCreateCanvasAssignment } from 'store/canvas/thunks';
import { useSocket } from 'store/chat/selectors';
import { useClassActivities } from 'store/classActivities/selectors';
import { handleCreateClassActivity } from 'store/classActivities/thunks';
import { useCurrentClass } from 'store/classes/selectors';
import { handleCreateGoogleAssignment } from 'store/googleClassRoom/thunks';
import { handleCreateNotification } from 'store/notification/thunks';
import { useSchoologyAssignment, useSchoologyAuth, useSchoologyGradeCategory } from 'store/schoologyauth/selectors';
import { useClassUsers, useUserClasses } from 'store/userClasses/selectors';
import { useCurrentUser } from 'store/users/selectors';
import color from 'utils/constants/color';
import { ebookConfigDinamic, getNumberMask } from 'utils/lib/ebookHelper';
import { validateQuizeActivityData } from 'utils/methods/createQuizeActivityValidator';
import Schoology from 'utils/Schoology';
import { CaretDownOutlined } from "@ant-design/icons";
import { handleCloneCustomAssignment } from 'store/activities/thunks';
const LearnPlanWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;
    padding:0px !important;
`;

const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;
const ContollButton = styled.button`
    background-image: linear-gradient(to right,${color.indigo500}, ${color.indigo600});
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 25px;
    color: white;
    z-index: 1;
    padding: 3px;
    margin-bottom:12px;
    margin-top:8px;
    margin-left:12px;
}
`;
const AssignButton = styled.button`
    background-image: linear-gradient(to right,${p => p.active ? color.greentrue : color.indigo500}, ${p => p.active ? color.greentrue : color.indigo600});
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 25px;
    color: white;
    z-index: 1;
    padding: 3px;
    margin-bottom:12px;
    margin-top:8px;
    position: absolute;
    bottom: -10px;
    border-radius: 12px;
    padding: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
}
`;



function getGraphicOrganizerData(data) {
    const MIN_PAGE = 518;
    const MAX_PAGE = 545;
    if (Array.isArray(data)) {
        return filter(data, k => {
            return parseInt(k.pageId) >= MIN_PAGE && parseInt(k.pageId) <= MAX_PAGE
        })
    }
    return [];
}
const Items = ({ onClick = () => { }, category, assignedActivity, assignment  , getNumberMask}) => {
    const is_assigned = assignedActivity && assignedActivity.length>0;
    const onNewAssign = () => {
        onClick("create", assignment)
    }
    const openAssignedAssignment = act => {
        onClick("view", act)
    }
    const assignedAssignmentView = () => {
        return (<Menu>
            {assignedActivity && assignedActivity.length > 0 ? assignedActivity?.map((act, index) => (
                <Menu.Item key={index} onClick={() => { openAssignedAssignment(act) }}>
                    {`Assigned on page.${getNumberMask(act.assigned_on_page)}`}
                </Menu.Item>
            )) : <Menu.Item>
                Assigned
            </Menu.Item>}
        </Menu>)
    }
    return (<div className='myCategoryList' style={{ width: 300 }}>
        <div className="ShowDetailBnr">
            <img style={{ height: "auto" , cursor:"default" }} src={category.src} alt="" />
            {is_assigned ?
                <Dropdown
                    overlay={assignedAssignmentView()}
                    trigger={['click']}>
                    <AssignButton
                        active
                        style={{ bottom: "24px" }}
                    >
                        {`Assigned`}  <CaretDownOutlined style={{ fontSize: '18px', marginLeft: '6px', color: "#fff" }} />
                    </AssignButton>
                </Dropdown>
                : null}
             <AssignButton
                onClick={onNewAssign}>
                Assign now
            </AssignButton>
        </div>
        <div className='categoryBtnTxt' style={{ height: "auto", paddingBottom: 10 }}>
            <div className='onway-teacher-txt' style={{ fontSize: 14, height: "auto", minHeight: 50, paddingLeft: 8 }}>
                {get(category, "description", "") || ""}
            </div>
            <div className='persionIconImg' style={{ fontSize: 8, color: "#fff" }}>
                <PersonIcon className='iconClrChange' />
                <span>{"ONE WAY EDUCATION"}</span>
            </div>
        </div>
    </div>)
}
function GraphicOrganizerAssignments({ page , chapter }) {
    const booksAllList = useAllBook()
    const [filteredData, setFilteredData] = useState([]);
    const activities = useActivities();
    const classActivities = useClassActivities();
    const bookClassAssignment = useMemo(() => filter(classActivities, q => q.data?.chapter == chapter && q.data.assignment_page != null), [classActivities])
    const bookAssignment = useMemo(() => filter(activities, q => q.chapter == '7' && q.assignment_page != null), [activities])
    const [pageModalInfo, setPageModalInfo] = React.useState({
        open: false,
        src: ''
    });
    const dispatch = useDispatch()
    const currentClass = useCurrentClass();
    const currentUser = useCurrentUser()
    const classUsers = useClassUsers();
    const history = useHistory();
    /** assignment area */
    let infoClassUSers = filter(classUsers, x => x.role === 'student');
    let listStudents = [];
    for (var u in infoClassUSers) {
        listStudents.push({
            id: infoClassUSers[u].id,
            description: `${infoClassUSers[u].lastName}  ${infoClassUSers[u].firstName} (${infoClassUSers[u].username})`
        })
    }
    const { socketConnection, reCreateSocket } = useSocket()
    const [activityDrawer, toggleActivityDrawer] = useState(false);
    const [quizDrawer, toggleQuizDrawer] = useState(false);
    const [activityData, setActivityData] = useState({});
    const [studentsError, setStudentsError] = React.useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [lateSection, setlateSection] = useState(false);
    const [durationSection, setDurationSection] = useState(false);
    const schoologyAuth = useSchoologyAuth()
    const client = new Schoology(schoologyAuth)
    const [, listSchoologyAssignment] = useSchoologyAssignment()
    const userClasses = useUserClasses()
    const [schoologyGradeCategory] = useSchoologyGradeCategory()
    const sendNotifications = async (notificationData) => {
        try {
            const updateNotification = await dispatch(handleCreateNotification(notificationData))
            const messageCreate = {
                ...updateNotification,
                senderId: get(currentUser, 'id', ''),
                receiverId: '',
                classId: get(currentClass, 'id', ''),
                code: 'system_notification'
            }
            invokSend(messageCreate)
        } catch (error) {
            console.log(error, "error")
        }
    }

    const invokSend = (data) => {
        let socket = socketConnection ?? '';
        if (socket) {
            try {
                if (socket.readyState == 3) {
                    reCreateSocket()
                    setTimeout(() => {
                        socket.send(JSON.stringify(data))
                    }, 2000)
                } else {
                    socket.send(JSON.stringify(data))
                }
            } catch (error) {
                console.log(error, "error")
            }
        }
    }

    const createActivity = async (e) => {
        e.preventDefault();
        let studentsSelected = [];
        let actvityDataForCreate = {
            classId: currentClass.id,
            ...activityData,
            activityId: activityData.id,
            studentsSelected: studentsSelected,
        }
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(activityData, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: '7',
                page: page,
                id: get(activityData, 'id', ''),
            },
            message: `Your teacher Create this Activity`,
            createdBy: get(currentClass, 'id', ''),
            type: 'create_activity',
            seen: true
        }
        if (!actvityDataForCreate.bookId) actvityDataForCreate.bookId = activityData.chapter
        let { isValid, reason } = validateQuizeActivityData(actvityDataForCreate, "activity")
        if (!isValid) {
            setDataLoading(false);
            console.log(reason)
            return;
        }
        setStudentsError(false);
        setDataLoading(true);
        const new_activity = await dispatch(handleCloneCustomAssignment(page,chapter,activityData.id,currentUser.id))
        if(new_activity&&new_activity.id){
            actvityDataForCreate.activityId=new_activity.id
        }else{
           message.error("Something went wrong!")
           setDataLoading(false);
           return;
        }
        actvityDataForCreate.studentsSelected = [];
        actvityDataForCreate.assigned_on_page = page;
        const sAssignmentData = await createAssignmetToSchoologyMultipleClass(actvityDataForCreate);
        const canvasData = await createAssignmetToCanvasMultipleClass(actvityDataForCreate);
        if (sAssignmentData && sAssignmentData.length > 0) {
            actvityDataForCreate.sAssignmentData = sAssignmentData
        }
        const googleCreateAssignment = await createAssignmentsForGoogleClassRoom(actvityDataForCreate)
        if (googleCreateAssignment && googleCreateAssignment.length > 0) {
            actvityDataForCreate.googleClassRoomAssignment = googleCreateAssignment;
        }
        if (canvasData && canvasData.length > 0) {
            actvityDataForCreate.canvasData = canvasData
        }
        await dispatch(handleCreateClassActivity(actvityDataForCreate));
        sendNotifications(notificationData)
        toggleActivityDrawer(false);
        setDataLoading(false);
        setActivityData({});
    };
    useEffect(() => {
        if (!activityDrawer) {
            setActivityData({})
            setStudentsError(false)
            setDataLoading(false)
            setlateSection(false)
            setDurationSection(false)
        }
    }, [activityDrawer])

    const updateActivityData = (id, value) => {
        setActivityData({
            ...activityData,
            [id]: value
        });
    };
    const handleAssignment = (type ,assignment) => {
        if(!assignment) return;
        if(type ==="create") {
        toggleActivityDrawer(true);
        assignment.page = page;
        assignment.chapter = chapter;
        assignment.bookId = chapter;
        setActivityData(assignment);
        handleClose()
        }else{
            history.push(`/ebook?chapter=${assignment.chapter||assignment?.data.chapter||'7'}&page=${assignment.assigned_on_page}&activity=null&quiz=null`)
            handleClose()
        }
    }
    const createAssignmentsForGoogleClassRoom = async (assignment) => {
        if (currentUser.googleId) {
            return Promise.all(map(assignment.classes, async cls => {
                const classInfo = find(userClasses, ["id", cls.id]);
                if (classInfo && classInfo.googleId) {
                    const assignmentData = currentClass.id === cls.id ? assignment : ({ ...assignment, dueDate: cls.dueDate })
                    const createdAssignment = await handleCreateGoogleAssignment(currentUser.googleId, assignmentData, classInfo.googleId)
                    return ({ classId: classInfo.id, googleAssignmentId: get(createdAssignment, "id") })
                } else {
                    return null;
                }

            })).then(v => v.filter(k => k))
        } else {
            return [];
        }
    }
    const createAssignmetToSchoologyMultipleClass = async (quizeDataforCreate) => {
        const hasClass = filter(quizeDataforCreate?.classes, e => e?.sectionId);
        const sAssignmentData = [];
        if (hasClass.length > 0) {
            await Promise.allSettled(map(hasClass, async item => {
                const schoologyCategory = item.schoologyCategory ? item.schoologyCategory : quizeDataforCreate.schoologyCategory
                let s_id = await createAssignmentToSchoology({ ...quizeDataforCreate, schoologyCategory }, item.sectionId)
                const schoologyCategoryData = find(schoologyGradeCategory, k => k.id == schoologyCategory);
                const isWightageEnabled = find(schoologyGradeCategory, k => k.weight > 0);
                let max_point = 100;
                let grade_type = 'percent';
                let weightage = 100;
                if (schoologyCategoryData) {
                    if (schoologyCategoryData.calculation_type == 2) {
                        grade_type = 'points'
                    }
                    if (has(schoologyCategoryData, "default_max_points")) {
                        max_point = schoologyCategoryData.default_max_points
                    }
                    if (isWightageEnabled) {
                        weightage = get(schoologyCategoryData, 'weight') || 100;
                    }
                }
                if (s_id) {
                    sAssignmentData.push({
                        classId: item.id,
                        sAssignmentId: s_id.id,
                        max_point,
                        grade_type,
                        weightage
                    })
                }
            }))
        } else {
            let s_assignment = await createAssignmentToSchoology(quizeDataforCreate, currentClass.sectionId)
            if (s_assignment) {
                let max_point = 100;
                let grade_type = 'percent';
                let weightage = 100;
                sAssignmentData.push({
                    classId: currentClass.id,
                    sAssignmentId: s_assignment.id,
                    max_point,
                    grade_type,
                    weightage
                })
            }
        }
        return sAssignmentData;
    }
    const createAssignmetToCanvasMultipleClass = async (quizeDataforCreate) => {
        if (!get(currentUser, "canvas_id") || !quizeDataforCreate) return;
        const hasClass = map(quizeDataforCreate?.classes, e => {
            const dueDate = e.id === currentClass.id ? quizeDataforCreate.dueDate : e.dueDate
            return ({
                ...e,
                "due_date": dueDate,
                "weightage": quizeDataforCreate.weightage ? quizeDataforCreate.weightage : '100',
                "displayName": quizeDataforCreate.displayName,
                "canvas_section_id": get(find(userClasses, ['id', e.id]), "canvas_id"),
                "canvas_course_id": get(find(userClasses, ['id', e.id]), "canvas_course_id"),
                "grade_type": quizeDataforCreate.grade_type,
                "max_point": quizeDataforCreate.max_point,
                "final_grade_exclude": quizeDataforCreate.final_grade_exclude
            })
        })
        const createdAssignment = await handleCreateCanvasAssignment(currentUser.canvas_id, hasClass, currentUser.canvas_auth_id)
        const result = map(createdAssignment, q => {
            if (q && q.canvas_course_id) {
                const classInfo = find(userClasses, uc => uc.canvas_id == q.canvas_section_id)
                return ({
                    canvas_section_id: q.canvas_section_id,
                    canvas_course_id: q.canvas_course_id,
                    canvas_assignment_id: q.id,
                    classId: get(classInfo, "id")
                })
            }
            return null;
        }).filter(q => q)
        return result;
    }
    const createAssignmentToSchoology = async (quizeDataforCreate, sectionId) => {
        let hasClass = filter(quizeDataforCreate?.classes, e => e?.sectionId);
        let toBeUnassigned = filter(hasClass, e => e?.sectionId !== sectionId);

        if (currentUser.schoologyid && sectionId) {
            let grading_category = get(quizeDataforCreate, "schoologyCategory");
            let weightage = quizeDataforCreate.weightage
            if (!weightage || isNaN(parseInt(weightage))) {
                weightage = 100;
            }
            client.setToken(schoologyAuth);
            let data = {
                "title": quizeDataforCreate.displayName,
                "description": quizeDataforCreate.displayName,
                "due": `${quizeDataforCreate.dueDate}`,
                "type": "assignment",
                "assignees": [`${currentUser.schoologyid}`],
                "grading_group_ids": [],
                "grading_category": grading_category
            }
            const createAssignment = await client.createAssignment(sectionId, data)
            listSchoologyAssignment(sectionId)
            map(toBeUnassigned, item => {
                client.unassignAssignment(item.sectionId, createAssignment.id)
            })
            return createAssignment;

        }
        return null
    }
    const onCloseDrawer = () => {
        toggleQuizDrawer(false)
        toggleActivityDrawer(false)
        setActivityData({})
        setStudentsError(false)
        setDataLoading(false)
        setlateSection(false)
        setDurationSection(false)
    }

    /** assignment area end */

    useEffect(() => {
        if (Array.isArray(booksAllList)) {
            const data = ebookConfigDinamic("7", booksAllList);
            const pageData = getGraphicOrganizerData(data.content)
            setFilteredData(pageData)
        }
    }, [booksAllList])

   const getNumberMaskText = (page_id)=>{
          return getNumberMask('7',page_id,booksAllList)
   }
    const handleOpen = (item) => setPageModalInfo({
        open: true,
    });
    const handleClose = () => setPageModalInfo({
        open: false,
        src: ''
    });
    return (
        <div>
            <Drawer
                height='100%'
                destroyOnClose
                onClose={onCloseDrawer}
                placement='top'
                open={activityDrawer}
                key='activityNew'
                className='assignments-drawer'
            >
                <>
                    <ActivityForm
                        data={activityData}
                        handleChange={e => updateActivityData(e.target.id, e.target.value)}
                        handleSubmit={createActivity}
                        listStudents={listStudents}
                        studentsError={studentsError}
                        setDurationSection={setDurationSection}
                        durationSection={durationSection}
                        setlateSection={setlateSection}
                        onClose={() => toggleActivityDrawer(false)}
                        booksAllList={booksAllList}
                    />
                </>
            </Drawer>
            <Modal
                open={pageModalInfo.open}
                onClose={handleClose}
                className={"rubricsPopUp"}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
                onContextMenu={e => {
                    e.preventDefault();
                }}
            >
                <LearnPlanWrapper className='teacherStrategies'>
                    <CloseBtn onClick={handleClose}>
                        <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }} />
                    </CloseBtn>
                    <div className='myCategorys' style={{ height: "95vh", overflow: "auto", alignItems: "center", marginTop: 0 }}>
                        {filteredData.map((category, index) => (<Items
                            category={category}
                            key={index + "_cat"}
                            onClick={handleAssignment}
                            assignedActivity={filter(bookClassAssignment, ["data.assignment_page", category.pageId])}
                            assignment={find(bookAssignment, ["assignment_page", category.pageId])}
                            getNumberMask={getNumberMaskText}
                        />))}
                    </div>

                </LearnPlanWrapper>
            </Modal>
            <ContollButton onClick={() => handleOpen()}>Graphic Organizers </ContollButton>
        </div>
    )
}

export default GraphicOrganizerAssignments;