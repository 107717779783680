//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create activity ----------------
/*export const createActivity = () => ({ type: ActionTypes.activities.createActivity });
export const createActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.createActivityFail, payload: returnedPayload });
export const createActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.createActivitySuccess, payload: returnedPayload });

//  -- Get activity -------------------
export const getActivity = () => ({ type: ActionTypes.activities.getActivity });
export const getActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.getActivityFail, payload: returnedPayload });
export const getActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.getActivitySuccess, payload: returnedPayload });

//  -- List chapter activities --------
export const listChapterActivities = () => ({ type: ActionTypes.activities.listChapterActivities });
export const listChapterActivitiesFail = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesFail, payload: returnedPayload });
export const listChapterActivitiesSuccess = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesSuccess, payload: returnedPayload });*/

//  -- List books ----------------
export const listBookUnits = () => ({ type: ActionTypes.bookUnits.listUnits });
export const listBookUnitsFail = (returnedPayload) => ({ type: ActionTypes.bookUnits.listUnitsFail, payload: returnedPayload });
export const listBookUnitsSuccess = (returnedPayload) => ({ type: ActionTypes.bookUnits.listUnitsSuccess, payload: returnedPayload });

export const updateBookUnits = () => ({ type: ActionTypes.bookUnits.updateUnits });
export const updateBookUnitsFail = (returnedPayload) => ({ type: ActionTypes.bookUnits.updateUnitsFail, payload: returnedPayload });
export const updateBookUnitsSuccess = (returnedPayload) => ({ type: ActionTypes.bookUnits.updateUnitsSuccess, payload: returnedPayload });



