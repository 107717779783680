//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const currentClassInitial = {};
const classesInitial = [];

export const classes = (state = classesInitial, action) => {
    switch (action.type) {
        case ActionTypes.classes.listOrgClassesSuccess:
            return action.payload;
        case ActionTypes.classes.listClassesSuccess:
            return action.payload;
        case ActionTypes.classes.createClassSuccess:
            return [action.payload, ...state];
        case ActionTypes.classes.listOrgClassesFail:
        case ActionTypes.classes.listClassesFail:
        case ActionTypes.classes.createClassFail:
        case ActionTypes.classes.updateClassFail:
        default:
            return state;
    }
};

export const allClasses = (state = classesInitial, action) => {
    switch (action.type) {
        case ActionTypes.classes.listAllClassesSuccess:
            return action.payload;
        case ActionTypes.classes.listAllClassesFail:
        default:
            return state;
    }
};

export const currentClass = (state = currentClassInitial, action) => {
    switch (action.type) {
        case ActionTypes.classes.getClassSuccess:
            return action.payload;
        case ActionTypes.classes.updateClassSuccess:
            return { ...state, ...action.payload };
        case ActionTypes.classes.getClassFail:
        default:
            return state;
    }
};
