//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from '@material-ui/core';
//  -- Components --------------------- 
import { Col, Drawer, Dropdown, Menu, Row } from 'antd';

import Loading from 'components/Loading';
import { TbgIconimg } from "utils/assets/images";
//  -- Features -----------------------
import GradeTable from 'features/GradeTable';
import 'jspdf-autotable';
import { arrayOf, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { handleListAllBooks } from 'store/books/thunks';
//  -- Thunks -------------------------
import { handleListClassQuestions } from 'store/questions/thunks';
import { useStory } from 'store/story/selectors';
import { handleListUserBooks } from 'store/userBooks/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import { handleCreateUserPreference, handleUpdateUserPreference } from 'store/userPreference/thunks';
import styled from 'styled-components';
import { filterMainGradebookClassQuizOrActivity } from 'utils/lib/ebookHelper';
//  -- Constants ----------------------
import TuneIcon from '@material-ui/icons/Tune';
import _, { map, find, get, filter } from 'lodash';
import { useApExams } from 'store/apexams/selectors';
import { useClassGameActivity } from 'store/one_game/selectors';
import COLOR from 'utils/constants/color';
import ParentGradetable from './ParentGradetable';
import AssignmentReviewParent from 'features/forms/AssignmentReviewParent';
const GRADE_PREF = [
    { key: "average", value: "Average Grade" },
    { key: "scale_1_5", value: "Grade in 0-4" },
    { key: 'gradebook_a_f', value: "ParentGradebook with A-F" }
]
const ORDER_BY = [
    { key: "asc", value: "Ascending" },
    { key: "desc", value: "Descending" },
]


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const ModalContent = styled.div`
    align-items: center;
    background-color: #11ffee00;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;


const Button = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.green200};
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;

    &:hover {
        color: ${COLOR.green}
    }
`;




const Header = styled.div`
    align-items: center;
    border-bottom: 1px dashed ${COLOR.black800};
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 8px;
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
`;

const Wrapper = styled.div`
    width: 100%;
`;

const TbgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 130px;
    background-color: ${COLOR.blueish100};
    border: 3px solid ${COLOR.indigo400};
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    `
const TbgIconWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
    `
const TbgIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
}
    `

const TbgTitle = styled.span`
    color: ${COLOR.white};
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    `
const TbgTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    `
const TbgList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 14px;
`
const TbgListItem = styled.li`
    color: ${COLOR.white};
    font-size: 10px;
    text-align: left;
    font-weight: 400;
    display: flex;
    flex-direction:row;
    margin-top: 4px;
    align-items:center;
    `
const ColorBox = styled.div`
    height:10px;
    width:20px;
    margin-right: 5px;
`;


const getCellStyle = (isLate, isDiffStudent, isTbg) => {
    if (isLate) {
        return ({ backgroundColor: "rgba(255,0,0,0.8)" })
    } else if (isDiffStudent) {
        return ({ backgroundColor: "#00ff134f" })
    } else if (isTbg) {
        return ({ backgroundColor: "#72bef1e0" })
    } else {
        return ({})
    }
}


const ParentGradebook = ({
    classActivities,
    classQuizzes,
    currentClass,
    currentOrg,
    classUsers,
    submissions,
    currentUser,
    booksAllList,
    userPreference,
    activities,
    quizzes,
}) => {
    const story = useStory()
    const apExam = useApExams()
    const { class_game_activity } = useClassGameActivity()
    const classQuizzesData = useMemo(() => filterMainGradebookClassQuizOrActivity(classQuizzes, story, get(apExam[0], "exams")), [classQuizzes, story, apExam])
    const classActivityData = useMemo(() => [...class_game_activity, ...filterMainGradebookClassQuizOrActivity(classActivities, story, get(apExam[0], "exams"))], [classActivities, story, apExam, class_game_activity])
    const assignment_submission = useMemo(() => {
        return submissions.map(k => {
            if (k.assignmentType === 'game' || k.assignmentType === 'debate') {
                const game = find(class_game_activity, ['session_id', k.assignmentId])
                if (game) {
                    return ({
                        ...k,
                        assignment: game.assignment,
                    })
                } else {
                    return k;
                }
            }
            return k;
        })
    }, [submissions, class_game_activity]);
    const currentUserId = currentUser?.parent_of;
    const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
    const isCanvasUser = !!(currentUser && currentUser.canvas_id);
    const isGoogleUser = !!(currentUser && currentUser.googleId);
    const howGradeScale = !isGoogleUser && !isCanvasUser && !isSchoologyUser
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const isReviewAccount = currentUser?.reviewAccount || false
    const [gradeView, setGradeViw] = useState("gradetable")
    const [gradeData, setGradeData] = useState([])
    const [LegendsShow, setLegendsShow] = useState(false);
    const scale_1_5 = userPreference?.gradePref == "scale_1_5"
    const gradebook_a_f = userPreference?.gradePref == "gradebook_a_f"
    const [updatePrefLoader, setUpdatePrefLoader] = useState(false);
    const [updateorderByLoader, setUpdateOrderByLoader] = useState(false);
    const [orderBy, setOrderBy] = useState("desc")
    const [isCurrentSem, setCurrentSem] = useState(true)
    const [selectedAssignmentUserSubmission, setSelectedAssignmentSubmissions] = useState([])
    const [assignmentData, setAssignmentData] = useState({});
    const [assignmentDrawer, toggleAssignmentDrawer] = useState(false);
    const users = useMemo(() => filter(classUsers, ["id", currentUser?.parent_of]), [currentUser])


    const [current, setCurrent] = useState('mail');
    const onClickMenu = (e) => {
        if (e.key === 'Ascending') {
            setOrderBy('asc')
            handleUpdateorderByPref('asc')
        }
        if (e.key === 'Descending') {
            setOrderBy('desc')
            handleUpdateorderByPref('desc')
        }
        if (e.key === 'Average_Grade') {
            handleUpdateGradePref('average')
        }

        if (e.key === 'Grade_in') {
            handleUpdateGradePref('scale_1_5')
        }
        if (e.key === 'Gradebook_with') {
            handleUpdateGradePref('gradebook_a_f')
        }
        if (e.key === 'Legends') {
            handleLegendsShow();
        }
        setCurrent(e.key);
    };

    useEffect(() => {
        if (userPreference && typeof userPreference.orderBy === "string") {
            if (userPreference.orderBy === "asc" || userPreference.orderBy === 'desc') {
                setOrderBy(userPreference.orderBy)
            }
        }
    }, [userPreference])
    useEffect(() => {
        fetchAllData();
    }, [currentClass, dispatch])
    const handleLegendsShow = () => {
        setLegendsShow(true);
    }
    const TbgLabels = () => {
        return (
            <TbgContainer>
                <TbgTitleWrapper>
                    <TbgIconWrapper>
                        <TbgIcon src={TbgIconimg} />
                    </TbgIconWrapper>
                    <TbgTitle>Legends</TbgTitle>
                </ TbgTitleWrapper>
                <TbgList>
                    <TbgListItem>TBG: To Be Graded</TbgListItem>
                    <TbgListItem><ColorBox style={getCellStyle(true)} /> RED: Late Submission</TbgListItem>
                    <TbgListItem><ColorBox style={getCellStyle(false, true)} /> GREEN: Differentiation</TbgListItem>
                    <TbgListItem><ColorBox style={getCellStyle(false, false, true)} /> BLUE: TBG </TbgListItem>
                </TbgList>
            </TbgContainer>
        )
    }
    const listAllBooks = async () => {
        if (!booksAllList || booksAllList.length == 0) {
            dispatch(handleListAllBooks())
        }
    }
    const reviewAssignment = (data, tempData, users, userSubmissions) => {
        const _assignment = _.concat(classActivityData, classQuizzesData, class_game_activity).filter(a => (a.quizId === data?.assignmentId || a.activityId === data?.assignmentId || a.session_id == data?.assignmentId))[0];
        let sAssignmentId = get(data, "sAssignmentId");
        if (_assignment && _assignment.sAssignmentId) {
            sAssignmentId = _assignment.sAssignmentId;
        }
        if (data) {
            data.sAssignmentId = sAssignmentId;
        }
        if (!data) {
            const utemp = _.cloneDeep(tempData);
            utemp.isTemp = true;
            data = utemp;
            data.sAssignmentId = sAssignmentId;
        }
        const userdata = map(users, usr => {
            const sub = userSubmissions[usr.id]
            if (sub) {
                const asub = find(sub, n => get(n, "assignmentId") == get(data, "assignmentId", get(tempData, "assignmentId")))
                if (asub) {
                    asub.sAssignmentId = sAssignmentId;
                    return asub
                } else {
                    const utemp = _.cloneDeep(tempData);
                    utemp.userId = usr.id;
                    utemp.user = usr;
                    utemp.isTemp = true;
                    utemp.sAssignmentId = sAssignmentId;
                    return utemp;
                }
            } else {
                const utemp = _.cloneDeep(tempData);
                utemp.userId = usr.id;
                utemp.user = usr;
                utemp.isTemp = true;
                utemp.sAssignmentId = sAssignmentId;
                return utemp;
            }
        })
        setSelectedAssignmentSubmissions(userdata);
        setAssignmentData(data);
        toggleAssignmentDrawer(true);
    }
    const fetchAllData = async () => {
        setLoading(true);
        const currentClassIdForGrade = localStorage.getItem("currentClassIdForGrade")
        if ((currentClassIdForGrade && currentClassIdForGrade === currentClass?.id) || (!currentClass?.id && currentClassIdForGrade === "noclasssid")) {
            setLoading(false);
        }
        await Promise.allSettled([
            dispatch(handleListUserBooks(currentUserId)),
            listAllBooks(),
            dispatch(handleListClassUsers(get(currentClass, "id"))),
        ])


        localStorage.setItem("currentClassIdForGrade", currentClass?.id ?? "noclasssid")
        setLoading(false);
        await dispatch(handleListClassQuestions(currentClass.id))
    }


    useEffect(() => {
        fetchAllData();
    }, [currentClass])

    const handleUpdateGradePref = async (_scale) => {
        setUpdatePrefLoader(true)
        if (!userPreference || typeof userPreference == "object" && Object.keys(userPreference).length == 0) {
            await dispatch(handleCreateUserPreference(currentUser?.id, { gradePref: _scale }))
        } else {
            await dispatch(handleUpdateUserPreference(currentUser?.id, { gradePref: _scale }))
        }
        // await getPref()
        setUpdatePrefLoader(false)
    }
    const handleUpdateorderByPref = async (_orderBy) => {
        setUpdateOrderByLoader(true)
        if (currentUser) {
            if (!userPreference || typeof userPreference == "object" && Object.keys(userPreference).length == 0) {
                await dispatch(handleCreateUserPreference(currentUser.id, { orderBy: _orderBy }))
            } else {
                await dispatch(handleUpdateUserPreference(currentUser.id, { orderBy: _orderBy }))
            }
        }
        setUpdateOrderByLoader(false)
    }
    const getAssignmentCreation = (assignmentdata, userdata) => {
        try {
            const is_game = assignmentdata && (assignmentdata.type === 'game' || assignmentdata.type === 'debate');
            if (is_game) {
                let quizdata = {
                    answers: [],
                    assignment: assignmentdata.assignment,
                    assignmentId: assignmentdata.session_id,
                    assignmentType: assignmentdata.type,
                    chapter: assignmentdata.bookId,
                    classId: currentClass.id,
                    complete: false,
                    createdAt: Date.now(),
                    feedback: '',
                    grade: '0',
                    graded: true,
                    isLate: false,
                    orgId: currentOrg?.id,
                    retakes: 0,
                    retaking: false,
                    submissions: 0,
                    timmer: 0,
                    user: userdata,
                    userId: userdata.id,
                    bookId: assignmentdata.bookId,
                    isTemp: true
                }
                return quizdata;
            } else {
                let _assignmentinfo = assignmentdata.data ?? {};
                let quizdata = {
                    answers: [],
                    assignment: _assignmentinfo,
                    assignmentId: _assignmentinfo.id,
                    assignmentType: _assignmentinfo.typeId,
                    chapter: _assignmentinfo.chapter,
                    classId: currentClass.id,
                    complete: false,
                    createdAt: Date.now(),
                    feedback: '',
                    grade: '0',
                    graded: true,
                    isLate: false,
                    orgId: currentOrg?.id,
                    retakes: assignmentdata.retakes,
                    retaking: false,
                    submissions: 0,
                    timmer: 0,
                    user: userdata,
                    userId: userdata.id,
                    bookId: _assignmentinfo.bookId,
                    isTemp: true
                }
                return quizdata;
            }

        } catch (error) {
            return {};
        }
    }


    const gradeNav = () => {
        return (
            <Menu>
                {GRADE_PREF.map((item) => (<Menu.Item key={item.key} style={{ padding: '0px' }}>

                    <PaddedBtn onClick={() => {
                        handleUpdateGradePref(item.key)
                    }}>
                        {item.value}
                    </PaddedBtn>

                </Menu.Item>))}
            </Menu>
        );
    };
    const orderNav = () => {
        return (
            <Menu>
                {ORDER_BY.map((item) => (<Menu.Item key={item.key} style={{ padding: '0px' }}>

                    <PaddedBtn onClick={() => {
                        setOrderBy(item.key)
                        handleUpdateorderByPref(item.key)
                    }}>
                        {item.value}
                    </PaddedBtn>

                </Menu.Item>))}
            </Menu>
        );
    };
    const items = [
        {
            key: 'sub1',
            icon: <TuneIcon style={{ fontSize: 32, color: '#2d8a88' }} />,
            children: [
                {
                    key: 'Order_By_date',
                    label: 'Order By date',
                    children: [
                        {
                            key: 'Ascending',
                            label: 'Ascending',
                        },
                        {
                            key: 'Descending',
                            label: 'Descending',
                        },
                    ],
                },
                howGradeScale && {
                    key: 'Gradebook_with_A-F',
                    label: 'ParentGradebook with A-F',
                    children: [
                        {
                            key: 'Average_Grade',
                            label: 'Average Grade',
                        },
                        {
                            key: 'Grade_in',
                            label: 'Grade in 0-4',
                        },
                        {
                            key: 'Gradebook_with',
                            label: 'ParentGradebook with A-F',
                        },
                    ],
                },

                {
                    key: 'Legends',
                    label: 'Legends',
                }
            ].filter(Boolean),
        },
    ];


    return (
        <>
            <Modal
                open={LegendsShow}
                onClose={() => setLegendsShow(false)}
            ><ModalContent>
                    <TbgLabels />
                </ModalContent>
            </Modal>
            <Drawer
                height='100%'
                destroyOnClose
                onClose={() => {
                    toggleAssignmentDrawer(false);
                    setAssignmentData({});
                    setSelectedAssignmentSubmissions([])
                }}
                placement='top'
                open={assignmentDrawer}
                key='assignmentNew'
            >
                {assignmentDrawer &&
                    <AssignmentReviewParent
                        booksAllList={booksAllList}
                        data={assignmentData}
                        usersSubmissionData={selectedAssignmentUserSubmission}
                        currentClass={currentClass}
                        handleClose={() => {
                            setLoading(true)
                            toggleAssignmentDrawer(false)
                            setLoading(false)
                            fetchAllData();
                        }}
                        scale_1_5={userPreference?.gradePref == "scale_1_5"}
                        gradebook_a_f={userPreference?.gradePref === "gradebook_a_f"}
                    />
                }
            </Drawer>
            <Wrapper>
                <Row gutter={[0, 40]}>
                    <Col span={24}>
                        <Header className='gradeBokHdr'>
                            <Wrap className='gradeBokWrp'>
                                <Dropdown overlay={orderNav()} placement="bottomLeft" trigger={['click']}>
                                    <Button style={{ marginRight: '16px', display: "flex", flexDirection: "row", alignItems: "center" }}
                                        disabled={updateorderByLoader}
                                        onClick={(e) => {
                                            e.preventDefault()
                                        }} >
                                        <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                        {updateorderByLoader && <Loading size={15} />}
                                        Order By date ({orderBy})
                                    </Button>
                                </Dropdown>
                                {howGradeScale &&
                                    <Dropdown overlay={gradeNav()} placement="bottomLeft" trigger={['click']}>
                                        <Button style={{ marginRight: '16px', display: "flex", flexDirection: "row", alignItems: "center" }}
                                            disabled={updatePrefLoader}
                                            onClick={(e) => {
                                                e.preventDefault()
                                            }} >
                                            <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                            {updatePrefLoader && <Loading size={15} />}
                                            {scale_1_5 ? GRADE_PREF[1].value : gradebook_a_f ? GRADE_PREF[2].value : GRADE_PREF[0].value}
                                        </Button>
                                    </Dropdown>

                                }
                                <Button onClick={() => {
                                    handleLegendsShow();
                                }} style={{ marginRight: '16px' }}>
                                    <InfoCircleOutlined style={{ marginRight: '4px' }} />
                                    Legends
                                </Button>
                            </Wrap>
                        </Header>
                        <ParentGradetable
                            activities={activities}
                            currentClass={currentClass}
                            currentOrg={currentOrg}
                            dataLoading={loading}
                            reviewAssignment={reviewAssignment}
                            userReviewAssignment={() => { }}
                            setUserData={() => { }}
                            toggleUserDrawer={() => { }}
                            toggleChangePasswordDrawer={() => { }}
                            quizzes={quizzes}
                            submissions={assignment_submission}
                            orderBy={orderBy}
                            users={users}
                            setEditing={() => { }}
                            setUserBook={() => { }}
                            booksAllList={booksAllList}
                            isReviewAccount={isReviewAccount}
                            handleEditQuiz={() => { }}
                            handleEditActivity={() => { }}
                            currentUser={currentUser}
                            userPreference={userPreference}
                            classActivities={classActivityData}
                            getAssignmentCreation={getAssignmentCreation}
                            classQuizzes={classQuizzesData}
                            gradeView={gradeView}
                            setGradeViw={setGradeViw}
                            setExamData={() => { }}
                            gradeData={gradeData}
                            setGradeData={setGradeData}
                            editPrevSemData={false}
                            isCurrentSem={isCurrentSem}
                            setCurrentSem={setCurrentSem}
                        />
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
ParentGradebook.propTypes = {
    data: arrayOf(shape({})),
    header: string,
};

//  -- Default props ------------------
ParentGradebook.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentUser: state.currentUser,
    classUsers: state.classUsers,
    userBooksList: state.userBooksList,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
    userPreference: state.userPreference,
    selectedUser: state.selectedUser,
    activities: state.activities,
    quizzes: state.quizzes,
    schoologyAuth: state.schoologyAuth
});

export default connect(mapStateToProps, null)(ParentGradebook); 
