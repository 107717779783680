//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { map } from 'lodash';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const bookUnitListSelecter = (state) => state.bookUnitList;

//  -- Examples array selector --------

export const useBookUnits = () => {
    const selectedData = useSelector(bookUnitListSelecter, shallowEqual)
    return selectedData;
}
