//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const likesInitial = null;

export const likes = (state = likesInitial, action) => {
    switch (action.type) {
        case ActionTypes.likes.createLikeSuccess:
            return [action.payload, ...state];
        case ActionTypes.likes.deleteLikeSuccess:
            return state.filter(x => x.id !== action.payload.id);
        case ActionTypes.likes.listLikesSuccess:
        case ActionTypes.likes.listChapterLikesSuccess:
        case ActionTypes.likes.listClassLikesSuccess:
        case ActionTypes.likes.listOrgLikesSuccess:
        case ActionTypes.likes.listUserLikesSuccess:
            return action.payload;
        case ActionTypes.likes.createLikeFail:
        case ActionTypes.likes.deleteLikeFail:
        case ActionTypes.likes.listLikesFail:
        case ActionTypes.likes.listChapterLikesFail:
        case ActionTypes.likes.listClassLikesFail:
        case ActionTypes.likes.listOrgLikesFail:
        case ActionTypes.likes.listUserLikesFail:
            return state;
        default:
            return state;
    }
};
