//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map, filter } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const bookmarksInit = [];




export const bookmarks = (state = bookmarksInit, action) => {
    switch (action.type) {
        case ActionTypes.bookmarks.liastClassBookmarksSuccess:
            return action.payload;
        case ActionTypes.bookmarks.liastClassBookmarksFail:
            return state;
        case ActionTypes.bookmarks.updateBookmarks:
            return map(state, x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload };
                } else {
                    return x;
                }
            });
        case ActionTypes.bookmarks.createClassBookmarksSuccess:
            return [...state, action.payload]
        case ActionTypes.bookmarks.deleteBookmark:
            return filter(state, k => k.id != action.payload)
        default:
            return state;
    }
};
