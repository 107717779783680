//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as Actions from "./actions"



//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.debateModal;

//  -- Examples array selector --------

export const useDebateModal = () => {
    const debateModal = useSelector(selector, shallowEqual)
    const dispatch = useDispatch()
    const invokeDebateModal = () => {
        dispatch(Actions.invokeDebateModal())
    }
    const revokeDebateModal = () => {
        dispatch(Actions.revokeDebateModal())
    }
    return [debateModal, invokeDebateModal, revokeDebateModal];
}
