//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
//  -- Components ---------------------
import AnimatedText from "components/AnimatedText";
import CoversCard from 'components/AdminBooks/ResourceCover';
import CoversCardList from 'components/AdminBooks/ResourceVideoCover';
import Loading from 'components/Loading';
import { find, get, map } from "lodash";
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAlertView } from "store/alert/selectors";
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useDemoStatus } from "store/users/selectors";
import styled from 'styled-components';
import { ResourceBgimg } from 'utils/assets/images';
//  -- Constants ----------------------
import { useResources, useVideos } from 'store/resources/selectors';
import { ResourceSymbol } from 'utils/config/reference';
import { SearchIcon, MenuIcon } from "utils/assets/images"
import { Drawer, Dropdown, Menu, Tooltip, Col, Row } from 'antd';
import COLOR from 'utils/constants/color';
import { isEmpty, flatMap, filter, findIndex, cloneDeep, has } from 'lodash';
import { Categorias } from "utils/config/reference"
import { handleListResources, handleListVideos, handleUpdateResources, handleDeleteResources, handleUpdateVideos } from "store/resources/thunks"
import { Button, IconButton, ButtonGroup, Modal, TextField, InputLabel, FormControl, MenuItem, Select, FormControlLabel, makeStyles, Switch, Typography, FormLabel, FormHelperText } from '@material-ui/core'
import PageImagePicker from "components/AdminBooks/PageImagePicker"
import { Close, Add } from "@material-ui/icons"
import { Autocomplete } from '@material-ui/lab'
import { v4 } from 'uuid';
import AssignmentForm from "components/AdminBooks/AssignmentForm";
import Assignment from "components/AdminBooks/Assignments";
import VideoPicker from "components/AdminBooks/VideoPicker";


const PAGE_CATEGORY = [
    'Other',
    'Entretenimiento',
    'Tecnología',
    'Deportes',
    'Historias',
    'Cultura',
    'Música',
    'Curiosidades',
    'Cine',
    'Fantasía',
    'Realismo',
    'Ciencia ficción',
    'Magia',
    'Espacio',
    '6-8th ',
    '9-10th',
    '11-12th'
]

const IB_Theme = [
    'Identidad',
    'Experiencias',
    'Ingenio humano',
    'Organización social',
    'Compartir el planeta',   
]

const AP_THEME = [
    "Desafíos globales",
    "Ciencia y tecnología",
    "Vida contemporánea",
    "Identidades personales y públicas",
    "Familias y comunidades",
    "Belleza y estética",
    "Las sociedades en contacto",
    "La construcción del género",
    "El tiempo y el espacio",
    "La creación literaria",
    "Las relaciones interpersonales",
    "La dualidad del ser"
];

const ANÉCDOTAS_THEMES = [
    'La unión como base',
    'Los tipos de relaciones en la sociedad',
    'La familia, el núcleo de la sociedad',
    'La juventud, mi juventud',
    'En busca de la paz',
    'Mi mundo que sigue innovando',
    'Los valores y mis responsabilidades',
    'La inmigración en el mundo',
    'Mi vida contemporánea',
    'Las redes nos enredan',
    'La influencia de los influencers',
    'Mi identidad en el mundo',
    'La fantasía y la imaginación',
    'La forma de vivir: ¿De vuelta a la normalidad o nueva normalidad?',
    'Mi comunidad es también mi familia',
    'Mi salud, mi vida',
    'Una historia para contar: Somos latinos, somos hispanos',
    'Enfrentando los desafíos del mundo',
    'Las familias y su enlace con la sociedad',
    'Las sociedades en contacto',
    'Las familias en diferentes sociedades',
    'El impacto de la cultura y el lenguaje en las identidades',
    'La construcción del género',
    'La influencia de la lengua y la cultura en la identidad',
    'Contemplando el arte como influencia en la vida',
    'La línea del tiempo',
    'La influencia de la belleza y el arte',
    'La tecnología y sus beneficios en la vida diaria',
    'El arte, un puente hacia la literatura',
    'Cómo la ciencia y la tecnología afectan nuestras vidas',
    'La vida contemporánea y sus cambios en las comunidades',
    'Interconexión, colaboración y resultados',
    'Los factores que impactan la calidad de nuestra vida',
    'Las sociedades frente a los retos',
    'Las múltiples facetas del ser humano',
    'Los cambios ambientales, políticos y sociales'
];

//  -- Features -----------------------
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 90vh;
    justify-content: center;
    width: 100%;
`;

export const Wrapper = styled.div`
    height: 100%;
    width:calc(100% + 48px);
    margin-left: -24px;
    padding: 0px 24px;
    /* background-image:url(${ResourceBgimg}); */
    background-repeat: no-repeat;
    justify-content:center;

     @media only screen and (max-width: 600px) {
        padding: 0px 10px;
     }
`;
const Block = styled.div`
   min-height:600px;
`;
// export const Wrapper = styled.div`
//     height: 100%;
//     width: 100%;
//     background-image:url(${ResourceBgimg})
// `;
const ListItem = styled.li`
  margin: 0px 7px 14px;
  width: calc(16% - 14px);
  
    @media only screen and (max-width: 1200px) {
        width: calc(20% - 10px);
    }
    @media only screen and (max-width: 992px) {
        width: calc(24% - 10px);
    }
    @media only screen and (min-width: 600px) and (max-width: 800px) {
        margin: 0px 16px 32px;
        width: calc(50% - 32px);
    }
    @media only screen and (max-width: 600px) {
        margin: 0px 12px 24px;
        width: calc(50% - 24px);
    }
`
const List = styled.ul`
    /* width: calc(100% + 40px); */
    /* width: calc(100% + -200px); */
  
    height: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: center;
`;
//   margin-left: 12%;
// width: 75%;
const ListItemCovers = styled.li`
    margin: 0px 16px 32px;
    width: calc(16% - 25px);

    @media only screen and (min-width: 600px) and (max-width: 800px) {
        margin: 0px 16px 32px;
        width: calc(50% - 32px);
    }
    @media only screen and (max-width: 600px) {
        margin: 0px 12px 24px;
        width: calc(50% - 24px);
    }
`
const ListCovers = styled.ul`
    height: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
`;
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const CategoryMenu = styled.div`
    background-color:${COLOR.tabActive};
    border-radius: 0px 12px 12px 12px;
    color: #fff;
    height: 25px;
    padding-left: 20px;
    padding-right: 2px;
    margin-top: 20px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:150px;
    align-items: center;
    cursor: pointer;

`;
const MenuIconImg = styled.img`
    margin-left:10px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
`;
const SearchInput = styled.input`
    width:100px;
    color:#fff;
    border:none;
    background: transparent;
    outline: none;
    text-align: center;
    font-weight: 800;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${COLOR.white};
    }
    ::focus{
        outline: none;
    }
`;
const ModalContent = styled.div`
left: 50%;
width:70vw;
padding: 0px 24px;
position: absolute;
min-height:350px;
top: 50%;
transform: translate(-50%, -50%);
background-color:#fff;
padding:10px;
`;
const ModalConfigArea = styled.div`
min-height:75vh;
max-height:90vh;
overflow:auto;
margin-top:10px;
padding:20px;
`;
const FormArea = styled.div`
    overflow:auto;
    margin-top:10px;
`;
const AssignmemntArea = styled.div`
  margin-top: 16px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    margin-left:5px;
    margin-top:5px;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const EditResources = ({ editResourseModal, onCloseModal, resourcesItem }) => {
    const [resourceVideo, setResourcesVideo] = useState({})
    const [loading, setloading] = useState(false)
    const [error, setError] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        if (resourcesItem) {
            setResourcesVideo(resourcesItem)
            setError([])
        }
    }, [resourcesItem])

    const onChangeResourseItem = (key, value) => {
        const data = cloneDeep(resourceVideo)
        data[key] = value;
        setResourcesVideo(data)
    }
    const onCreateOrUpdate = async () => {
        let err = []
        if (!resourceVideo.src) {
            err.push({
                key: "src", message: 'Cover image required!'
            })
        }
        if (!resourceVideo.poster) {
            err.push({
                key: "poster", message: 'Poster image required!'
            })
        }
        if (!resourceVideo.key) {
            err.push({
                key: "key", message: 'Key required!'
            })
        }
        if (!resourceVideo.displayName) {
            err.push({
                key: "displayName", message: 'displayName required!'
            })
        }
        setError(err)
        if (err.length == 0) {
            setloading(true)
            delete resourceVideo.add;
            await dispatch(handleUpdateResources(resourceVideo))
            setloading(false)
            onCloseModal()
        }
    }
    return (<Modal
        open={editResourseModal}
        onClose={() => { }}
        style={{ height: '100%', width: '100%' }}
    >
        <ModalContent>
            <Row style={{ justifyContent: "space-between" }}>
                <TitleText>{has(resourcesItem, "add") ? 'Add Resource' : 'Update Resource'}</TitleText>
                <IconButton onClick={onCloseModal} style={{ padding: 0, marginRight: 10 }}>
                    <Close color="secondary" />
                </IconButton>
            </Row>
            <ModalConfigArea>
                <Row gutter={[24, 24]}>
                    <Col xs={24} lg={12}>
                        <FormLabel component="legend">Cover image:</FormLabel>
                        <PageImagePicker
                            src={get(resourceVideo, "src")}
                            path={`/resources/${get(resourceVideo, "key")}`}
                            onChange={item => { onChangeResourseItem("src", item.url) }}
                            hasDescription={false}
                        />
                    </Col>
                    <Col xs={24} lg={12} style={{ paddingLeft: 10, paddingTop: 10 }}>
                        <FormLabel component="legend">Poster for videos:</FormLabel>
                        <PageImagePicker
                            src={get(resourceVideo, "poster")}
                            path={`/resources/${get(resourceVideo, "key")}`}
                            style={{ height: 270, marginBottom: 24 }}
                            onChange={item => { onChangeResourseItem("poster", item.url) }}
                            hasDescription={false}
                        />
                        <Row justify="start" style={{ marginBottom: 20, }} gutter={[18, 18]}>
                            <Col xs={24} lg={12}>  <TextField
                                id="id"
                                name="id"
                                label="Id"
                                InputLabelProps={{ shrink: true, required: true }}
                                type="text"
                                required
                                disabled
                                variant="outlined"
                                onChange={e => {
                                    onChangeResourseItem("id", e.target.value)
                                }}
                                value={get(resourceVideo, "id")}
                            />
                            </Col>
                            <Col xs={24} lg={12}>
                                <TextField
                                    id="key"
                                    name="key"
                                    label="key"
                                    error={find(error, ['key', 'key'])}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="text"
                                    required
                                    variant="outlined"
                                    onChange={e => {
                                        onChangeResourseItem("key", e.target.value)
                                    }}
                                    value={get(resourceVideo, "key")}
                                />
                            </Col>
                        </Row>
                        <Row justify="start" style={{ marginBottom: 20, }}>
                            <Col xs={24} lg={24}>
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    error={find(error, ['key', 'displayName'])}
                                    label="Display name"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onChangeResourseItem("displayName", e.target.value)
                                    }}
                                    value={get(resourceVideo, "displayName")}
                                />
                            </Col>
                        </Row>
                        {error.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                            {error.map(v => (<FormHelperText key={v.key} error>
                                * {get(v, "message")}
                            </FormHelperText>))}
                        </Row> : null}
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                            <Button fullWidth onClick={onCreateOrUpdate} variant="contained" color="primary">
                                {loading ? <Loading white size={15} /> : null}
                                {has(resourcesItem, "add") ? "Create" : "Update"} </Button>
                        </Row>

                    </Col>
                </Row>
            </ModalConfigArea>
        </ModalContent>
    </Modal>)
}
const EditVideos = ({ editVideoModal, onCloseModal, onCreateOrUpdateVideos, videoData, loading, onEditVideo }) => {
    const [videoItem, setVideoItem] = useState({})
    const classes = useStyles();
    const [error, setError] = useState([])
    useEffect(() => {
        if (videoData) {
            setVideoItem(videoData)
            setError([])
        }
    }, [videoData])

    const onChangeData = (key, value) => {
        const dt = cloneDeep(videoItem)
        dt[key] = value
        setVideoItem(dt)
    }
    const onCreate = () => {
        let err = []
        if (!videoItem.src) {
            err.push({
                key: "src", message: 'Cover image required!'
            })
        }
        if (!videoItem.thumbnail) {
            err.push({
                key: "thumbnail", message: 'Thumbnail image required!'
            })
        }
        if (!videoItem.displayName) {
            err.push({
                key: "displayName", message: 'displayName required!'
            })
        }
        setError(err)
        if (err.length == 0) {
            delete videoItem.add;
            onCreateOrUpdateVideos(videoItem)
        }

    }
    return (<Modal
        open={editVideoModal}
        onClose={() => { }}
        style={{ height: '100%', width: '100%' }}
    >
        <ModalContent>
            <Row style={{ justifyContent: "space-between" }}>
                <TitleText>{has(videoData, "add") ? 'Add Video' : 'Update Video'}</TitleText>
                <IconButton onClick={onCloseModal} style={{ padding: 0, marginRight: 10 }}>
                    <Close color="secondary" />
                </IconButton>
            </Row>
            <ModalConfigArea>
                <Row>
                    <Col lg={12} xs={24}>
                        <FormLabel component="legend">Source image:</FormLabel>
                        <PageImagePicker
                            src={get(videoItem, "src")}
                            path={`/resources/videos`}
                            onChange={item => {
                                onChangeData("src", item.url)
                            }}
                            hasDescription={false}
                        />
                    </Col>
                    <Col lg={12} xs={24} style={{ paddingLeft: 10, paddingTop: 10 }}>
                        {!has(videoData, "add") ? <Button onClick={onEditVideo} variant="contained" color="primary" >
                            Edit video & assignment
                        </Button> : null}
                        <FormArea style={{ height: "65vh"}}>
                            <FormLabel component="legend">Thumbnail (less then 500kb)</FormLabel>
                            <PageImagePicker
                                src={get(videoItem, "thumbnail")}
                                path={`/resources/videos`}
                                style={{ height: 270, marginBottom: 24 }}
                                onChange={item => {
                                    onChangeData("thumbnail", item.url)
                                }}
                                hasDescription={false}
                            />
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <TextField
                                        id="id"
                                        name="id"
                                        label="id"
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        disabled
                                        variant="outlined"
                                        onChange={e => {
                                            onChangeData("id", e.target.value)
                                        }}
                                        value={get(videoItem, "id")}
                                    />
                                </Col>
                            </Row>
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <TextField
                                        id="resource"
                                        name="resource"
                                        label="resource"
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        disabled
                                        variant="outlined"
                                        onChange={e => {
                                            onChangeData("resource", e.target.value)
                                        }}
                                        value={get(videoItem, "resource")}
                                    />
                                </Col>
                            </Row>

                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <TextField
                                        id="displayName"
                                        name="displayName"
                                        label="displayName"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        type="text"
                                        required
                                        variant="outlined"
                                        onChange={e => {
                                            onChangeData("displayName", e.target.value)
                                        }}
                                        value={get(videoItem, "displayName")}
                                    />
                                </Col>
                            </Row>
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <TextField
                                        id="description"
                                        name="description"
                                        label="description"
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        onChange={e => {
                                            onChangeData("description", e.target.value)
                                        }}
                                        value={get(videoItem, "description")}
                                    />
                                </Col>
                            </Row>

                            {/* <Row justify="start" style={{ marginBottom: 10, }}>
                             
                            </Row> */}
                            <Row justify="space-between" style={{ marginBottom: 20, }}>
                                {/* <Col xs={12} lg={12}> */}
                                    <FormControlLabel
                                        classes={{
                                            root: classes.rootDifFull,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Switch
                                                classes={{
                                                    sizeSmall: classes.sizeSmall,
                                                    checked: classes.checkedSwitch
                                                }}
                                                checked={Boolean(get(videoItem, "demo"))}
                                                id="demo"
                                                onChange={e => {
                                                    onChangeData("demo", e.target.checked)
                                                }}
                                                name="demo"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="demo"
                                    />
                                {/* </Col> */}
                                {/* <Col xs={12} lg={12}> */}
                                    <FormControlLabel
                                        classes={{
                                            root: classes.rootDifFull,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Switch
                                                classes={{
                                                    sizeSmall: classes.sizeSmall,
                                                    checked: classes.checkedSwitch
                                                }}
                                                checked={get(videoItem, "new")}
                                                id="new"
                                                onChange={e => {
                                                    onChangeData("new", e.target.checked)
                                                }}
                                                name="new"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="new"
                                    />
                                {/* </Col> */}
                            </Row>
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="tags-outlined"
                                        options={PAGE_CATEGORY}
                                        defaultValue={get(videoItem, "tags", '') ? get(videoItem, "tags", '').split(",") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onChangeData('tags', value.join(","))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="Tags"
                                                placeholder="Tags"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            {/* for anécdotas themes */}
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="anécdotas-themes-outlined"
                                        options={ANÉCDOTAS_THEMES}
                                        defaultValue={get(videoItem, "anécdotas_themes", '') ? get(videoItem, "anécdotas_themes", '').split(",") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onChangeData('anécdotas_themes', value.join(","))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="Anécdotas Themes"
                                                placeholder="Anécdotas Themes"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            {/* ap themes */}
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="ap-themes-outlined"
                                        options={AP_THEME}
                                        defaultValue={get(videoItem, "ap_themes", '') ? get(videoItem, "ap_themes", '').split(",") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onChangeData('ap_themes', value.join(","))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="AP Themes"
                                                placeholder="Ap Themes"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            {/* ib themes */}
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <Col xs={24} lg={24}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="ib-themes-outlined"
                                        options={IB_Theme}
                                        defaultValue={get(videoItem, "ib_themes", '') ? get(videoItem, "ib_themes", '').split(",") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onChangeData('ib_themes', value.join(","))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="IB Themes"
                                                placeholder="IB Themes"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row justify="start" style={{ marginBottom: 10, }}>
                                <Col xs={24} lg={24}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="Categorias">Categorias</InputLabel>
                                        <Select
                                            labelId="Categorias"
                                            id="Categorias"
                                            value={get(videoItem, "categorias", '')}
                                            onChange={(v) => {
                                                onChangeData('categorias', v.target.value)
                                            }}
                                            label="Categorias"
                                        >
                                            {PAGE_CATEGORY.map(v => (<MenuItem key={v} value={v}>{v}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </FormArea>
                        {error.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                            {error.map(v => (<FormHelperText key={v.key} error>
                                * {get(v, "message")}
                            </FormHelperText>))}
                        </Row> : null}
                        <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                            <Button fullWidth onClick={onCreate} variant="contained" color="primary">
                                {loading ? <Loading white size={15} /> : null}
                                {has(videoData, "add") ? "Create" : "Update"}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </ModalConfigArea>
        </ModalContent>
    </Modal>)
}
const VideoAssignment = ({ id, videoItem, quizzes }) => {
    const [quizDrawer, setQuizDrawer] = useState(false)
    const onCreateOrUpdate = () => {
        setQuizDrawer(false);
    };
    return (<>
        {quizDrawer ? <Drawer
            height="100%"
            destroyOnClose
            className="assignments-drawer"
            onClose={() => {
                setQuizDrawer(false);
            }}
            placement="top"
            visible={quizDrawer}
        >
            {quizDrawer && (
                <AssignmentForm
                    pageSrc={get(videoItem, "src")}
                    bookId={id}
                    pageId={id}
                    onCreateOrUpdate={onCreateOrUpdate}
                    type={"quizresource"}
                />
            )}
        </Drawer> : null}
        <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ margin: 2 }}
            onClick={() => {
                setQuizDrawer(true);
            }}
        >
            <Add /> Assignment
        </Button>
        <AssignmemntArea>
            {map(quizzes, (aq) => (
                <Assignment
                    key={aq.id}
                    pageSrc={get(videoItem, "src")}
                    pageId={id}
                    bookId={id}
                    assignment={aq}
                />
            ))}
        </AssignmemntArea>
    </>)
}
const VideoDrawer = ({ videoDrawer, setVideoDrawer, video, quizzes, videoItem, resourceItem, setAlert }) => {
    const [videoObj, setVideoObj] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const videoId = get(videoItem, "id")
    useEffect(() => {
        setVideoObj(video)
    }, [video])
    const updateVideos = async () => {
        if (!videoObj || !videoObj.id) {
            return;
        }
        if (!videoObj.src) {
            setAlert({ open: true, message: "Video source required!", type: "error" })
            return;
        }
        setLoading(true)

        const data = await dispatch(handleUpdateVideos(videoObj))

        if (has(data, "id")) {
            setAlert({ open: true, message: "Video updated successfully!", type: "success" })
            // setVideoDrawer(false)
        } else {
            setAlert({ open: true, message: "Something went wrong!", type: "error" })
        }
        setLoading(false)
    }
    const onCangeValue = (key, value) => {
        if (videoObj) {
            let v = { ...videoObj }
            v[key] = value
            setVideoObj(v)
        } else {
            let v = { ...videoObj, id: videoId }
            v[key] = value
            setVideoObj(v)
        }
        if (key === "src") {
            setTimeout(updateVideos, 1000)
        }
    }
    return (videoDrawer ? <Drawer
        height="100%"
        destroyOnClose
        className="assignments-drawer"
        onClose={() => {
            setVideoDrawer(false);
        }}
        placement="top"
        visible={videoDrawer}
    >
        <Row style={{ height: "100%", width: "100%" }}>
            <Col span={14} style={{ paddingRight: 16 }}>
                <VideoPicker
                    poster={get(resourceItem, "poster")}
                    src={get(videoObj, "src")}
                    onChange={(item) => { onCangeValue("src", item.url) }}
                    onChangeDescription={(txt) => { onCangeValue("displayName", txt) }}
                    description={get(videoObj, "displayName")}
                    hasDescription />
                <Button
                    disabled={!videoObj || !videoObj.id}
                    fullWidth variant="contained" color="primary" onClick={updateVideos}>
                    {loading ? <Loading white size={15} /> : null}  Update
                </Button>
            </Col>
            <Col span={10} style={{ paddingRight: 16, paddingLeft: 16 }}>

                <VideoAssignment id={get(videoItem, "id")} videoItem={videoItem} quizzes={quizzes} />
            </Col>
        </Row>
    </Drawer> : null)
}
const VideoSection = ({ Items, displayName, onClick, onAdd, onDelete }) => {
    return (
        <Block>
            <Row>
                <AnimatedText text={displayName} />
            </Row>
            <Row style={{ marginTop: 16 }}>
                <Col span={{ span: 24 }} >
                    <Button variant="contained" color="primary" onClick={onAdd}>
                        <Add /> Add videos
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <ListCovers>
                        {map(Items, (podcast, i) =>
                        (
                            <ListItemCovers key={podcast.id}>
                                <CoversCardList data={podcast} onClick={() => onClick(podcast)} onDelete={() => onDelete(podcast.id)} />
                            </ListItemCovers>
                        ))}
                    </ListCovers>
                </Col>
            </Row>
        </Block>
    )
}

const ResourcesContainer = ({ quizzes }) => {
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const link = search.link == 'undefined' || !search.link || search.link == 'null' ? "1" : search.link
    const resources = useResources()
    const videos = useVideos()
    const [keyProductos, setKeyProductos] = useState(link);
    const [resourceData, setResourceData] = useState(resources)
    const [loading, setLoading] = useState(false);
    const [resourcesItem, setResourcesItem] = useState(null)
    const [editResourseModal, setEditResourceModal] = useState(false)
    const [editVideoModal, setEditVideoModal] = useState(false)
    const [videoItemItem, setVideoItemItem] = useState(null)
    const [videoUpdateLoader, setVideoUpdateLoader] = useState(false)
    const [videoDrawer, setVideoDrawer] = useState(false)
    const dispatch = useDispatch();
    const classes = useStyles();
    const [state, setState] = React.useState({
        open: false,
        message: '',
        type: 'success'
    });
    const sortResources = (res) => {
        return map(res, re => {
            const newVideo = filter(re.videos, ['new', true])
            const oldVideo = filter(re.videos, k => !k.new)
            return ({ ...re, videos: [...newVideo, ...oldVideo] })
        })
    }
    useEffect(() => {
        if (!isEmpty(resources)) {
            setResourceData(sortResources(resources))
        }
    }, [resources])


    const fetchData = async () => {
        if (Array.isArray(resources) && resources.length > 0) {
            setLoading(false);
        } else {
            setLoading(true);
        }
        await dispatch(handleListResources())
        await dispatch(handleListVideos())
        setLoading(false);

    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleClose = () => {
        setState({ open: false, message: "", type: "success" });
    };

    const onCoverCLick = (key) => {

        setKeyProductos(key)

    }
    const getVideosByKey = useCallback(() => {
        return get(find(resourceData, ['id', keyProductos]), "videos", [])
    }, [keyProductos, resourceData])
    const getFImage = useCallback(() => {
        switch (keyProductos) {
            case "3":
                return ResourceSymbol.avina;
            case "5":
                return ResourceSymbol.herramientas;
            case "1":
                return ResourceSymbol.matias;
            case "2":
                return ResourceSymbol.americaPodcast;
            case "4":
                return ResourceSymbol.ruzia;
            default:
                return ResourceSymbol.matias;
        }
    }, [keyProductos])
    const onCloseModal = () => {
        setEditResourceModal(false)
        setResourcesItem(null)
        setVideoItemItem(null)
        setEditVideoModal(false)
    }

    const onVideoClick = (videoItem) => {
        setVideoItemItem(videoItem)
        setEditVideoModal(true)
    }
    const onAddVideos = () => {
        const index = findIndex(resourceData, ['id', keyProductos])
        let dt = resourceData[index]
        setVideoItemItem({
            "id": v4(),
            "new": true,
            "src": "",
            "demo": false,
            "tags": "",
            "resource": get(dt, "displayName"),
            "thumbnail": "",
            "categorias": "",
            "description": "",
            "displayName": "",
            "anécdotas_themes": "",
            "ap_themes": "",
            "ib_themes": "",
            add: true
        })
        setEditVideoModal(true)
    }
    const addResources = () => {
        let maxId = Math.max(...resourceData.map(o => parseInt(o.id)))
        if (isNaN(maxId) || maxId == -Infinity) {
            maxId = 0;
        }
        setResourcesItem({
            id: `${maxId + 1}`,
            displayName: '',
            key: `${maxId + 1}`,
            poster: '',
            src: '',
            videos: [],
            add: true
        })
        setEditResourceModal(true)

    }
    const onCreateOrUpdateVideos = async (data) => {

        setVideoUpdateLoader(true)
        const index = findIndex(resourceData, ['id', keyProductos])
        if (index >= 0) {
            let dt = resourceData[index]
            const hasVideo = find(get(dt, "videos"), ["id", get(data, 'id')])
            if (hasVideo) {
                dt.videos = map(dt.videos, v => v.id == data.id ? data : v)
            } else {
                dt.videos.unshift(data)
            }
            const resp = await dispatch(handleUpdateResources(dt))
            if (has(resp, "id")) {
                setState({ open: true, message: "Resources updated successfully!", type: "success" })
                setVideoUpdateLoader(false)
                onCloseModal()
            } else {
                setState({ open: true, message: "having some issue while updated resources!", type: "error" })
            }
        }
    }
    const onDeleteResources = async (id) => {
        const re = await dispatch(handleDeleteResources(id))
        if (has(re, "id")) {
            setState({
                open: true,
                message: "Resources removed!",
                type: "error"
            })
        }

    }
    const onRemoveVideos = async (id) => {
        const index = findIndex(resourceData, ['id', keyProductos])
        if (index >= 0) {
            let dt = resourceData[index]
            dt.videos = filter(dt.videos, v => v.id != id)
            const re = await dispatch(handleUpdateResources(dt))
            if (has(re, "id")) {
                setState({
                    open: true,
                    message: "Videos removed!",
                    type: "error"
                })
            }
        }
    }
    const { open, message, type } = state;
    return (
        <Wrapper>
            <VideoDrawer
                videoDrawer={videoDrawer}
                setVideoDrawer={setVideoDrawer}
                video={find(videos, ['id', get(videoItemItem, "id")])}
                quizzes={filter(quizzes, ['chapter', get(videoItemItem, "id")])}
                videoItem={videoItemItem}
                resourceItem={find(resourceData, ['id', keyProductos])}
                setAlert={setState}
            />
            <EditResources editResourseModal={editResourseModal} onCloseModal={onCloseModal} resourcesItem={resourcesItem} />
            <EditVideos
                editVideoModal={editVideoModal}
                onCloseModal={onCloseModal}
                resourcesItem={resourcesItem}
                videoData={videoItemItem}
                loading={videoUpdateLoader}
                onEditVideo={() => {
                    setEditVideoModal(false)
                    setVideoDrawer(true)
                }}
                onCreateOrUpdateVideos={onCreateOrUpdateVideos} />
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={type}>
                    <strong>ALERT:</strong> {message}
                </Alert>
            </Snackbar>
            {loading ? (
                <CenterContainer>
                    <Loading />
                </CenterContainer>
            ) : (
                <>
                    <Row style={{ marginTop: 16 }}>
                        <Col span={{ span: 24 }} >
                            <Button variant="contained" color="primary" onClick={addResources} >
                                <Add /> Add resource
                            </Button>
                        </Col>
                    </Row>
                    <Row className="resourcList">
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }} className="resorceListing">
                            <List>
                                {map(resourceData, portada => (
                                    <ListItem key={portada.key} className={`${portada.id == keyProductos ? "card-resource-selected" : ""}`}>
                                        <CoversCard data={portada} handleClick={() => onCoverCLick(portada.id)}
                                            onDelete={() => {
                                                onDeleteResources(portada.id)
                                            }}
                                            onEditClick={() => {
                                                setResourcesItem(portada)
                                                setEditResourceModal(true)
                                            }} />
                                    </ListItem>
                                ))}
                            </List>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }}>
                            <div className="resorceImg">
                                <img src={getFImage().bg} className="resorceImgBg" />
                                {!!getFImage().img1 && <img src={getFImage().img1} className="resorceImgUpr" loader={<Loading />} />}
                            </div>
                        </Col>
                    </Row>
                    <VideoSection
                        Items={getVideosByKey()}
                        onAdd={onAddVideos}
                        onClick={onVideoClick}
                        onDelete={onRemoveVideos}
                        displayName={get(find(resourceData, ['id', keyProductos]), "displayName", '')}
                    />
                </>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ResourcesContainer.propTypes = {};

//  -- Default props ------------------
ResourcesContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    activities: state.activities,
    quizzes: state.quizzes
});

export default connect(mapStateToProps, null)(ResourcesContainer);
