//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, TextField } from '@material-ui/core';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import moment from 'moment';
import { lighten } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
//  -- Thunks -------------------------
import styled from 'styled-components';
//  -- Constants ----------------------
import { ArrowRight } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import COLOR from 'utils/constants/color';
//  -- Lib ----------------------------
import _, { concat, filter, find, findIndex, get, uniqBy } from 'lodash';
import { imageFetch, imageFetchFromPodcast, isVideo } from 'utils/lib/ebookHelper';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import GradeDisplay from 'components/GradeDisplay';
import useMobileDeviceDetection from 'containers/chat/hooks';
import AudioRecorderParent from 'features/AudioRecorderParent';
import { useClassActivities } from 'store/classActivities/selectors';
import { useClassQuizzes } from 'store/classQuizzes/selectors';
import { useClassGameActivity } from 'store/one_game/selectors';
import { useResources } from 'store/resources/selectors';
import { useCurrentUser } from 'store/users/selectors';
import { debatePoster } from "utils/assets/images";
const COLORSTATUS = ["", COLOR.greentrue, COLOR.orange100, COLOR.red]
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;
const UserAddignmentLabel = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    height: 40px;
    background: #e7edf2;
    padding: 10px;
    display: flex;
    align-items: center;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px; 
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;

const ImageContiner = styled.div`
    background-color: transparent;
    border: none;
    flex: 1;
    outline: none;
    width: 100%;
        @media(max-width:1366px){
        display:none;
    }
`;

const Notification = styled.div`
    background-color: ${p => p.bgColor ? p.bgColor : lighten(0.45, '#3D5F8F')};
    border: 1px dashed ${p => p.bgColor ? p.color : '#3D5F8F'};
    border-radius: 3px;
    color: ${p => p.color ? p.color : '#3D5F8F'};
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 24px;
    padding: 8px;
`;

const Wrapper = styled.div`
    margin: 0px auto; 
    max-width: 100%;
    padding: 0px;
    width: 100%;
`;

const Rotate = styled.div`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;
const AccordianTitle = styled(Typography)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
`;
const PreviewArea = styled.div`
   max-height:60vh;
   overflow: auto;
  ::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

const Accordion = withStyles({
    root: {
        border: '1px solid white',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
const CurrentSubmission = {
    backgroundColor: '#9933ff'
}
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#03989e',
        color: 'white',
        textTransform: 'capitalize',
        borderBottom: '1px solid white',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        margin: 0
    },
}))(MuiAccordionDetails);
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Activity form
//
//  -- Description
//  Create / edit activity questions
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <ActivityForm
//      data={data}
//      handleChange={func}
//      handleSubmit={func}
//  />
//
const sortname = (a, b, key, orderBy = "ASC") => {
    try {
        const nameA = get(a, key, "").toLowerCase()?.replace(/\s/g, '');
        const nameB = get(b, key, "").toLowerCase()?.replace(/\s/g, '');
        if (orderBy === "ASC") {
            if (nameA < nameB) { return -1 }
            else if (nameA > nameB) { return 1 }
            else { return 0 }
        } else {
            if (nameA > nameB) { return -1 }
            else if (nameA < nameB) { return 1 }
            else { return 0 }
        }
    } catch (error) {
        return 0;
    }
}
const hasGradeInA_F = (val) => {
    if (typeof val === "string") {
        const hasInGrade = [{ key: 'A', value: 90 }, { key: 'B', value: 70 }, { key: 'C', value: 50 }, { key: 'D', value: 30 }, { key: 'F', value: 10 }].find(k => k.key.toLowerCase() === val.toLowerCase())
        if (hasInGrade) {
            return ({
                value: hasInGrade.value,
                hasValue: true
            })
        }
        return ({
            value: val,
            hasValue: false
        })
    } else {
        return ({
            value: val,
            hasValue: false
        })
    }

}
const AssignmentReviewParent = ({
    data,
    booksAllList,
    handleClose,
    scale_1_5,
    gradebook_a_f,
    currentClass,
    usersSubmissionData,
    ap_scale
}) => {
    const deviceType = useMobileDeviceDetection();
    const history = useHistory();
    const currentUser = useCurrentUser()
    const [grade, setGrade] = useState('');
    const [feedback, setFeedback] = useState('');
    const [audioFeedback, setAudioFeedback] = useState(null);
    const [loading, setLoading] = useState(null);
    const [updateLoading, setUpdateLoading] = useState(null);
    const resources = useResources()
    const schoologyAuth = useSelector(state => state.schoologyAuth);
    const [pastSubmissions, setPastSubmissions] = useState([])
    const [expanded, setExpanded] = React.useState(null);
    const [selectedUser, setSelectedUser] = useState()
    const [submissionData, setSubmissionData] = useState()
    const [allUserSubmissions, setAllUserSubmissions] = useState([])
    const [allFilteredUser, setAllFilteredUser] = useState([])
    const { class_game_activity } = useClassGameActivity()
    const { timmerStatus, submissionStatus, inProgressStatus } = useMemo(() => {
        const timmer = get(submissionData, "timmer", 0);
        const complete = get(submissionData, "complete");
        const answersLength = get(submissionData, "answers.length", 0);
        const questionsLength = get(submissionData, "assignment.questions.length", 0);
        const submissions = get(submissionData, "submissions");
        const retaking = get(submissionData, "retaking");
        const assignmentAnswersLength = get(submissionData, "assignment.answers.length", 0);

        const timmerStatus = timmer > 0 && complete && answersLength <= questionsLength;
        const submissionStatus = submissions == 0 && answersLength == 0;
        const inProgressStatus = !complete && retaking && questionsLength > assignmentAnswersLength;
        return {
            timmerStatus,
            submissionStatus,
            inProgressStatus,
        };
    }, [submissionData]);
    const feedbackAudioRef = useRef()
    const inputRef = useRef()
    const classActivities = useClassActivities();
    const classQuizzes = useClassQuizzes()
    const inputsRefs = useRef([])
    const assignment = useMemo(() => concat(classActivities, classQuizzes, class_game_activity).find(k => k.quizId === data.assignmentId || k.activityId === data.assignmentId || k.seesion_id === data.assignmentId), [classActivities, classQuizzes, submissionData, class_game_activity])
    const isUngraded = useMemo(() => Boolean(get(assignment, "ungraded")), [assignment]);
    const grade_type = assignment?.grade_type;
    const isPointSystem = grade_type === "points"
    const current_assignment_returned = useMemo(() => isReturned(isUngraded, submissionData), [submissionData, isUngraded])

    useEffect(() => {
        if (!selectedUser) {
            setSelectedUser(get(data, "user"))
            setUpdateLoading(null);
        }
        setSubmissionData(data)
    }, [data, scale_1_5, gradebook_a_f])
    useEffect(() => {
        setAllUserSubmissions(usersSubmissionData);
        setAllFilteredUser(usersSubmissionData)
    }, [usersSubmissionData])
    function setDefaultValue() {
        const { grade, face_grade } = getGradeval(submissionData)
        const is_game = get(submissionData, "assignment.typeId", '') === "game"
        const is_debate = get(submissionData, "assignmentType", '') === "debate"
        const isCompleteTbg = filter(get(submissionData, "assignment.questions", []), (e) => e.type != "multipleChoice").length === get(submissionData, "assignment.questions.length", 0)
        let expected_grade = isPointSystem ? face_grade : grade;
        expected_grade = expected_grade >= 0 ? expected_grade : ''
        const has_not_graded = (isCompleteTbg && !is_game && !is_debate) && submissionData?.manualGrading
        const grd = has_not_graded || !submissionData || !submissionData.id ? '' : expected_grade
        setGrade(grd);
    }
    useEffect(() => {
        setPastSubmissions(getpastSubmissions(submissionData))
        setDefaultValue()
        setFeedback(submissionData?.feedback || '');
        setAudioFeedback(submissionData?.audioFeedback || null);
        if (feedbackAudioRef.current) {
            feedbackAudioRef.current.reset()
            feedbackAudioRef.current.setAudio(submissionData?.audioFeedback)
        }
        setExpanded(get(submissionData, 'id'));
    }, [submissionData, classActivities, classQuizzes])
    const setData = (dt) => {
        setSelectedUser(get(dt, "user"))
        setSubmissionData(dt)
    }

    function getGradeval(dt) {
        let grade = get(dt, "grade", '');
        const face_grade = get(dt, "face_grade", '');
        if (scale_1_5) {
            if (grade != null) {
                if (!isNaN(grade)) {
                    grade = Math.min(4, Math.floor((Math.max(0, grade - 1)) / 20)).toString();
                }
            }
        } else if (gradebook_a_f) {
            const isTemp = get(dt, "isTemp");
            const isGraded = get(dt, "graded");

            if ((isGraded && isTemp) || !isGraded) {
                return ({ grade: '-', face_grade });
            }

            if (!isNaN(grade)) {
                const g = parseInt(grade);
                if (g < 20) {
                    grade = 'F';
                } else if (g < 40) {
                    grade = 'D';
                } else if (g < 60) {
                    grade = 'C';
                } else if (g < 80) {
                    grade = 'B';
                } else if (g < 100) {
                    grade = 'A';
                } else {
                    grade = '-';
                }
            } else {
                grade = "-";
            }
        }
        return ({ grade, face_grade });
    }

    const getpastSubmissions = (dt) => {
        const grouped = _.groupBy(get(dt, "pastSubmissions", []), "submissions")
        const p_submission = uniqBy(get(dt, "pastSubmissions", []), "id").sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt))
        return p_submission
            .map((k, i) => {
                let is_return = get(grouped[k.submissions], "length", 0) > 1;
                const nextSubmission = p_submission[i + 1]
                if (nextSubmission && nextSubmission.submissions === k.submissions) {
                    is_return = false;
                }
                return ({ ...k, is_return })
            })
    }

    const handleChangeExpend = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : null);
    };
    const getHigestMark = (assign, from_past) => {
        if (!assign) {
            return { isHighest: false, val: '', progress: false };
        }
        const assigneGrade = get(assign, "grade", 0);
        const submissionGrade = get(submissionData, "grade", 0);
        const inProgress =
            !get(assign, "complete") &&
            get(assign, "retaking") &&
            get(submissionData, "assignment.questions.length", 0) >
            get(assign, "assignment.answers.length", 0);

        const assignVal = getAssignmentGradeValue(assign);

        let highest = assigneGrade;

        pastSubmissions.forEach((pt) => {
            const ptGrade = get(pt, "grade", 0);
            const past_grade = getAssignmentGradeValue(pt);
            if (ptGrade > highest && past_grade !== "TBG") {
                highest = ptGrade;
            }
        });

        if (submissionGrade > highest && !inProgress && assignVal !== "TBG") {
            highest = submissionGrade;
        }

        if (highest > assigneGrade || inProgress) {
            return {
                isHighest: false,
                val: assignVal,
                progress: get(assign, "id") === get(submissionData, "id") && inProgress,
            };
        } else {
            return {
                isHighest: assignVal !== "TBG" && assignVal !== 0,
                val: assignVal,
                progress: false,
            };
        }
    };

    const pastSubmissionView = useMemo(() => {
        if (!pastSubmissions || pastSubmissions.length === 0) {
            return null;
        }
        return (
            <div>
                {pastSubmissions.map((pt, i) => {
                    const hmark = getHigestMark(pt, true);
                    const is_returned = pt.is_return;
                    let mark = hmark.isHighest ? `Highest(${hmark.val || ''})` : `(${hmark.progress ? "in-progress" : hmark.val})`;
                    mark = isUngraded ? "ungraded" : mark;
                    const p_submissions = get(pt, "submissions")
                    const returned_at = get(submissionData, "returned_at") || [];
                    const isReturnedData = find(returned_at, k => k == p_submissions);
                    return (
                        <Accordion square expanded={expanded === pt.id} onChange={handleChangeExpend(pt.id)} key={pt.id}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <AccordianTitle>
                                    <span>{"Submission-" + (i + 1)} {mark} {isReturnedData || is_returned ? "(Returned)" : null}</span>{" "}
                                    <Rotate open={expanded === pt.id}><ArrowRight /></Rotate>
                                </AccordianTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                                <GradeDisplay
                                    answers={pt.answers}
                                    feedback={pt.feedback}
                                    audioFeedback={pt.audioFeedback}
                                    ai_feedback={get(pt, "ai_feedback")}
                                    ai_suggestion={get(pt, "ai_suggestion")}
                                    grade={isPointSystem ? get(pt, "face_grade") : get(pt, "grade")}
                                    name={get(data, "displayName", get(data, "assignment.displayName"))}
                                    questions={get(data, "assignment.questions", [])}
                                    type={get(data, "assignmentType") === "quiz" ? "quiz" : null}
                                    isExpended
                                    isLate={false}
                                    hasFeedback={true && (get(pt, "feedback") || get(pt, "audioFeedback"))}
                                    isUngraded={isUngraded}
                                    assignment={pt}
                                />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        );
    }, [pastSubmissions, expanded, submissionData]);


    const CurrentSubmissionView = useMemo(() => {
        if (submissionData?.submissions !== 0 || (submissionData?.submissions === 0 && submissionData?.returned > 0)) {
            const LastSub = pastSubmissions.length + 1;
            // const CurrentSub = LastSub + 1;
            const Title = LastSub === 1 ? LastSub : `${LastSub} (Last Submission)`;
            const hmark = getHigestMark(submissionData);
            let mark = hmark.isHighest ? `Highest(${hmark.val})` : `(${hmark.progress ? "in-progress" : hmark.val})`;
            mark = isUngraded ? "ungraded" : mark;
            const is_game = submissionData?.assignmentType === "game";
            const is_debate = submissionData?.assignmentType === "debate";
            return (
                <div>
                    {<Accordion square expanded={expanded === get(submissionData, "id")} onChange={handleChangeExpend(get(submissionData, "id"))}>
                        <AccordionSummary style={CurrentSubmission} aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle>
                                <span>{"Submission-" + Title}  {mark} {(submissionData?.returned > 0 && submissionData?.submissions === (submissionData?.returned - 1)) ? `(Returned)` : null}</span>
                                <Rotate open={expanded === get(submissionData, "id")}><ArrowRight /></Rotate>
                            </AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GradeDisplay
                                key={get(submissionData, "id")}
                                answers={get(submissionData, "answers")}
                                feedback={get(submissionData, "feedback")}
                                audioFeedback={get(submissionData, "audioFeedback")}
                                ai_suggestion={get(submissionData, "ai_suggestion")}
                                ai_feedback={get(submissionData, "ai_feedback")}
                                grade={current_assignment_returned ? 'returned' : getAssignmentGradeValue(submissionData)}
                                name={get(submissionData, "displayName", get(submissionData, "assignment.displayName"))}
                                questions={get(submissionData, "assignment.questions", [])}
                                type={get(submissionData, "assignmentType") === "quiz" ? "quiz" : null}
                                isExpended
                                isLate={false}
                                is_game={is_game}
                                is_debate={is_debate}
                                isUngraded={isUngraded}
                                selected_student_id={submissionData?.userId}
                                hasFeedback={false}
                                assignment={submissionData}
                            />
                        </AccordionDetails>
                    </Accordion>}
                </div>
            );
        }
        return null;
    }, [submissionData, expanded, pastSubmissions]);

    const closeModal = () => {
        setFeedback('');
        setAudioFeedback(null);
        handleClose();
    }

    const getStatus = (dt) => {
        const exception = get(dt, "exceptionStatus");
        const createAt = get(dt, "createdAt")
        const complete = get(dt, "complete")
        const isTemp = get(dt, "isTemp")
        const isProgress = (!get(dt, "complete") && get(dt, "retaking") && get(dt, "assignment.questions.length", 0) > get(dt, "assignment.answers.length", 0));
        const isGradedByTeacher = get(dt, "submissions") == 0 && get(dt, "answers.length", 0) == 0 && get(dt, "pastSubmissions.length", 0) == 0
        const { grade } = getGradeval(dt)
        if (exception > 0) {
            if (exception == 1) {
                // return <span style={{ color: COLORSTATUS[exception] }}> Excused</span>
                return <span>{currentUser.schoologyid ? "Exempt" : "Excused"}</span>
            } else if (exception == 2) {
                return <span> Incomplete</span>
            } else if (exception == 3) {
                return <span> Missing</span>
            }
        } else if (isGradedByTeacher && !isTemp && grade > 0) {
            return "Graded Without Submission"
        }
        else if (isProgress) {
            return "In progress"
        }
        else if (complete && createAt) {
            return `Completed on ${moment(parseInt(createAt)).format('MMM, DD YYYY')}`;
        } else if (createAt && !isTemp) {
            return `${moment(parseInt(createAt)).format('MMM, DD YYYY')}`;
        } else {
            return "Not submitted."
        }
    }

    function getAssignmentGradeValue(dt) {
        let isTbgObj = find(get(dt, "assignment.questions", []), (e) => e.type != "multipleChoice")
        let isTbg = typeof isTbgObj == "object"
        let tbgTxt = isTbg ? "TBG" : "-"
        let manualGrading = get(dt, "manualGrading", false)
        let retaking = get(dt, "retaking", false)
        const isTemp = get(dt, "isTemp")
        const { grade, face_grade } = getGradeval(dt)
        if (isPointSystem && face_grade >= 0) return face_grade;
        if (hasGradeInA_F(grade).hasValue) return grade;
        else if (isTemp || (retaking && grade == 0)) return "-";
        else if (isTbg && manualGrading) return tbgTxt;
        else if (grade >= 0) return grade;
        else return "-"
    }
    const onBlurInput = () => {
        document.activeElement.blur();
    }
    const handleKeyDown = useCallback((e) => {
        const activeIndex = findIndex(allUserSubmissions, e => get(selectedUser, "id") == get(e, "user.id"))
        const hasref = inputsRefs.current.find(e => e == document.activeElement)
        if (activeIndex < 0 || !allUserSubmissions) return;
        const { key } = e;
        switch (key) {
            case "ArrowUp":
            case 38:
                if (activeIndex > 0 && allUserSubmissions[activeIndex - 1]) {
                    setData(allUserSubmissions[activeIndex - 1])
                    onBlurInput()
                }

                break;
            case "ArrowDown":
            case 40:
                if (activeIndex < allUserSubmissions.length && allUserSubmissions[activeIndex + 1]) {
                    setData(allUserSubmissions[activeIndex + 1])
                    onBlurInput()
                }
                break;
            case "Enter":
            case 13:
                if (!hasref) {
                    if (inputsRefs.current[activeIndex]) {
                        inputsRefs.current[activeIndex].focus();
                        inputsRefs.current[activeIndex].select();
                    }
                } else {
                    if (inputRef.current === document.activeElement) {
                        return false;
                    }
                    onBlurInput()
                    if (activeIndex < allUserSubmissions.length && allUserSubmissions[activeIndex + 1]) {
                        setData(allUserSubmissions[activeIndex + 1])
                    }
                }
            default:
                break;
        }
    }, [allUserSubmissions, selectedUser])
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };

    }, [handleKeyDown]);
    const handleFocus = (event) => event.target.select();
    const categories = ["All", "Missing", "Incomplete", get(currentUser, "schoologyid") ? "Exempt" : "Excused", "Returned"];

    function isReturned(isUngraded, submission) {
        return !isUngraded && submission && Object.keys(submission || {}).length > 0 && parseInt(get(submission, "submissions")) == (parseInt(get(submission, "returned", 0)) - 1)
    }

    const PosterIg = useMemo(() => {
        if (!get(data, "assignment")) return null;
        const is_debate = get(submissionData, "assignmentType", '') === "debate"
        if (is_debate) {
            return <ImageContiner>
                <Image src={debatePoster} alt='ebook image' />
            </ImageContiner>
        } else {
            return (
                <ImageContiner>
                    {isVideo(get(data, "chapter"), resources) ? <Image src={imageFetchFromPodcast(get(data, "chapter"), resources)} alt='ebook image' /> :
                        <Image src={imageFetch(booksAllList, get(data, "assignment.chapter"), get(data, "assignment.page"))} alt='ebook image' />}
                </ImageContiner>
            )
        }

    }, [submissionData, data, resources])
    return (
        <Wrapper onSubmit={e => e.preventDefault()}>
            <Header>Assignment Review</Header>
            <Row gutter={[16, 24]} wrap style={{ flexDirection: deviceType ? 'column' : 'row' }}>
                <Col xs={24} sm={14} md={11} style={{ marginBottom: 14 }}>
                    <UserAddignmentLabel><strong style={{ marginRight: '6px' }}>Student:</strong> <span style={{ marginRight: '3px' }}>{get(selectedUser, "firstName", "")} {get(selectedUser, "lastName", "")}</span> | <strong style={{ marginLeft: '3px', marginRight: '6px' }}>Status: </strong>{" "} {getStatus(submissionData)}</UserAddignmentLabel>
                    {PosterIg}
                </Col>
                <Col xs={24} sm={10} md={13} style={{ marginBottom: 14, borderLeft: "2px dotted #423a3a61", borderRight: "2px dotted #423a3a61", minWidth: deviceType ? '100%' : 'initial' }}>
                    {inProgressStatus && (
                        <Notification>
                            {get(selectedUser, "firstName", "")} {get(selectedUser, "lastName", "")} is in the process of {get(submissionData, "pastSubmissions.length") == 0 ? "taking" : "retaking"} this assignment and due to that you might see partial/no answers below. If there is a grade for this assignment it has been stored from a previous submission.
                        </Notification>
                    )}
                    {submissionStatus && (
                        <Notification bgColor={COLOR.yellow} color={COLOR.white}>
                            The student has not submitted this assignment
                        </Notification>
                    )}
                    {timmerStatus && (
                        <Notification>
                            This is a time-test assessment, if there are any blank answers, it means the student did not complete within time given.
                        </Notification>
                    )}
                    <PreviewArea>
                        {pastSubmissionView}
                        {CurrentSubmissionView}
                    </PreviewArea>
                    {feedback && (
                        <Row style={{ marginBottom: '24px', marginTop: 12 }}>
                            <Description style={{ marginBottom: 0, opacity: !submissionData || !submissionData.id ? 0.5 : 1 }}>
                                Student Feedback:
                            </Description>
                            <TextField
                                id="feedback"
                                onChange={() => { }}
                                disabled={true}
                                variant="outlined"
                                value={feedback}
                                multiline
                                rows={10}
                                className={"feedbackform"}
                            />
                        </Row>
                    )}
                    {submissionData && submissionData.id && <Row>
                        <AudioRecorderParent
                            maxTime={120}
                            wrapperStyle={{ border: '0px solid #e8e8e8', padding: '0px 0px 0px' }}
                            ref={feedbackAudioRef}
                            message={"Record your Feedback"}
                            handleAudio={(url) => {
                                // setAudioFeedback(url)
                                // handleSubmit(null, url, false)
                            }}
                            refresh={() => history.go(0)}
                            source={audioFeedback}
                            hasSaveButton={submissionData && submissionData.id}
                        />
                    </Row>}
                </Col>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssignmentReviewParent.propTypes = {};

//  -- Default props ------------------
AssignmentReviewParent.defaultProps = {};

export default AssignmentReviewParent;