//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { handleGetServertime } from "./thunks"

//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.servertime;

//  -- Examples array selector --------

export const useServertime = () => {
    const dispatch = useDispatch()
    const selectedData = useSelector(selector, shallowEqual)
    if(selectedData){
        return selectedData;
    }else{
        dispatch(handleGetServertime())
        return null;
    }
}

