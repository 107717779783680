//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { ArrowRight, Note } from "@material-ui/icons";
import { filter } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
//  -- Config -------------------------
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { TrueMarkimg } from "utils/assets/images";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Bold = styled.span`
  font-weight: 600;
`;

const Button = styled.button`
  align-items: center;
  background-color: rgb(219, 240, 241);
  border: 1px dashed ${COLOR.black700};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
  outline: none;
  padding: 6px 8px;
  width: 100%;
`;

const QuestionWrap = styled.button`
  background-color: rgb(234, 227, 236);
  border: 1px solid ${COLOR.black800};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 6px;
  outline: none;
  padding: 8px 10px;
  text-transform: uppercase;
  width: 100%;
`;

const Container = styled.div`
  height: ${(p) => `${p.height}px`};
  overflow: hidden;
  transition: height 300ms ease;
  width: 100%;
`;

const Count = styled.span`
  font-size: 11px;
  text-transform: uppercase;
`;

const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;

const Wrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  width: 100%;
`;
const TrueIcon = styled.img`
  height: 20px;
  width: 30px;
  margin-top: 2px;
  object-fit: cover;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Todo
//
//  -- Description
//  ...
//
//  -- Props
//  ...
//
const StudentNotes = ({ index, questions, onClick, student,getDisplayName }) => {
  const [height, setHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);

 


  const hasAnswered = (item) => {
    if (Array.isArray(item)) {
      return (
        item.filter((k) => {
          return k.answer;
        }).length > 0
      );
    } else {
      return false;
    }
  };
  useEffect(() => {
    let heightMag = 0;
    let total = 0;
    {
      Object.keys(questions).map((chapter) => {
        Object.keys(questions[chapter]).map((page) => {
          heightMag += 1;
          total += questions[chapter][page].length;
        });
      });
    }
    setHeight(heightMag * 39 + 8);
    setTotal(total);
  }, [questions]);

  return (
    <Wrapper>
      <Button onClick={() => setOpen(!open)}>
        <Wrap>
          <Rotate open={open}>
            <ArrowRight />
          </Rotate>
          <span>
            {student?.firstName} {student?.lastName}
          </span>
        </Wrap>
        <Count>
          Question: <Bold>{total}</Bold>
        </Count>
      </Button>
      <Container height={open ? "100%" : 0}>
        {Object.keys(questions).map((chapter) => {
          return Object.keys(questions[chapter]).map((page) => (
            <QuestionWrap
              key={page}
              onClick={() => onClick(chapter, page, student.id, index)}
            >
              <Wrap>
                {getDisplayName(chapter,page)}
                {hasAnswered(questions[chapter][page]) && (
                  <TrueIcon src={TrueMarkimg} />
                )}
              </Wrap>
              {questions[chapter][page].length}
            </QuestionWrap>
          ));
        })}
      </Container>
    </Wrapper>
  );
};

//  -- Prop types ---------------------
StudentNotes.propTypes = {};

//  -- Default props ------------------
StudentNotes.defaultProps = {};

export default StudentNotes;
