//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { CloseOutlined, FileSearchOutlined, LinkOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { home } from '@material-ui/icons';
//  -- Components ---------------------
import { Button, Drawer, Dropdown, Layout, Menu, Space } from 'antd';
import LogoIcon from 'components/LogoIcon';

//  -- Features -----------------------
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//  -- Thunks -------------------------
import { handleLogout } from 'store/auth/thunks';
import { useCurrentUser, useProfilePicturePicker, useUserProfileConfig } from "store/users/selectors";
import styled from 'styled-components';
//  -- Constants ----------------------
import { handleInvokeFeedbackForm } from 'store/feedback/thunks';
import { useCurrentOrg } from "store/organizations/selectors";
import { profileArrow, userImg } from "utils/assets/images";
import COLOR from 'utils/constants/color';
import { HomeOutlined, DatabaseOutlined, BarChartOutlined, FileTextOutlined, BookOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { getAvatar } from 'utils/assets/images/lootie';
const { Header, Sider, Content } = Layout;


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const routes = {
    home: '/district',
    dataBrowsers: '/data-browsers',
    literacy_purpose: '/literacy',
    proficiency: '/proficiency',
    contentExams:"/content_exam",
    'apExams': '/ap_exams',
};

const SideNavSection = styled.div`
`;


const OverviewText = styled.span`
  color: black;
  font-size: 19px;
  font-weight: 600;
  display:flex !important;
  align-items:center;
`;
const DistnameText = styled.div`
  color: black;
  display:flex !important;
  align-items:center;
  padding-left: 18px;
  font-size: 14px;
  font-weight: 400;
  margin-top: -16px;
`;
const RowContainer = styled.div`
    border-left: ${p => p.border && `1px solid ${COLOR.black700}`};
    margin-left: 10px;
    padding-left: 20px;
`;

const UserContainer = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;
const UserDetails = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (min-width: 768px) {
        display: flex;
    }
`;
const UserDetailsMob = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (max-width: 768px) {
        display: flex;
    }
`;



const UserName = styled.span`
    color: black;
    display: block;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 0px;
`;
const Wrappers = styled.div`
  height: 100%;
  width: 100%;
`;


const StyledMenu = styled(Menu)`
  .ant-menu-item-selected {
    background-color: rgb(96,179,249) !important;
    color: #fff !important;
    border-radius: 10px !important;
    
    .ant-menu-item-icon, .ant-menu-item-title {
      color: white !important;
    }
  }

  .ant-menu-item-icon, .ant-menu-item-title {
    color: rgb(96,179,249); // color for non-active icons and text
  }
`;

const MenuItem = styled(Menu.Item)`
  &.ant-menu-item {
    margin: 0 !important;
    border-radius: 10px;
    padding-left: 25px !important; // adjust padding to match your design
    padding-right: 25px !important;
    font-weight: bold;
    ::after {
        border-right:none !important;
    }
    // &:hover {
    //   background-color: #e6f7ff !important; // color when hovering over the item
    //   .ant-menu-item-icon, .ant-menu-item-title {
    //     color: rgb(96,179,249) !important;
    //   }
    // }
  }
`;
const StyledList = styled(List)`
  width: 250px;
  height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
const StyledListItem = styled(ListItem)`
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #f4f4f4;
  }
  &.Mui-selected, &.Mui-selected:hover {
    background-color: #blue;
    color: white;
  }
`;
const StyledListItemIcon = styled(ListItemIcon)`
  color: inherit;
`;

const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };
const NavigationMenu = ({ }) => {
    const [current, setCurrent] = useState('home');
    const history = useHistory();
    const handleClick = e => {
        setCurrent(e.key);
        onClick(e)
    };
    const onClick = ({ key }) => {
        const path = routes[key];
        if (path)   history.push(path);
    };
    useEffect(() => {
        const pathname = history.location.pathname
        const key = getKeyByValue(routes, pathname)
        if (key) {
            setCurrent(key)
        }
    }, [history])
  return (
    <StyledMenu onClick={handleClick} selectedKeys={[current]} mode="inline">
      <MenuItem key="home" icon={<HomeOutlined style={{ fontSize: 18 }} />}>
        Home
      </MenuItem>
      <MenuItem key="dataBrowsers" icon={<DatabaseOutlined  style={{ fontSize: 18 }} />}>
        Data Browser
      </MenuItem>
      <MenuItem key="proficiency" icon={<BarChartOutlined style={{ fontSize: 18 }} />}>
        Proficiency Exams
      </MenuItem>
      <MenuItem key="contentExams" icon={<FileTextOutlined style={{ fontSize: 18 }} />}>
        Content Exams
      </MenuItem>
      <MenuItem key="apExams" icon={<BookOutlined style={{ fontSize: 18 }}  />}>
        AP Exams
      </MenuItem>
      <MenuItem key="literacy_purpose" icon={<BookOutlined style={{ fontSize: 18 }} />}>
        Literacy with Purpose
      </MenuItem>
    </StyledMenu>
  );
};


export default function District_admin({ children }) {
    const [placement, setPlacement] = useState('left');
    const [opens, setOpens] = useState(false);
    const currentUser = useCurrentUser()
    const currentOrg  = useCurrentOrg()
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const { profile_config } = useUserProfileConfig()
    const { showProfilePhotoViewer } = useProfilePicturePicker()
    const toggleDrawer = () => setOpens(!opens);
    const toggleCollapse = () => setCollapsed(!collapsed);

    const onClose = () => {
        setOpens(false);
    };
    const showDrawer = () => {
        setOpens(true);
    };
    const getRoute = () => {
        history.push('/district');
    };
    const onLogOutPress = () => {
        dispatch(handleLogout());
        history.push('/');
    }
    const navigate = (path) => {
        history.push(path);
        setOpens(false)
    };

    const handleClick = ({ key }) => {
        const routes = {
            home: '/district',
            dataBrowsers: '/data-browsers',
            literacy_purpose: '/literacy',
            proficiency: '/proficiency',
        };
        const path = routes[key];
        if (path) navigate(path);
    };
    const invokeFeedback = () => {
        dispatch(handleInvokeFeedbackForm())
    }
    const userNav = () => (
        <Menu>
            <Menu.Item style={{ padding: '0px' }}>
                <Button onClick={showProfilePhotoViewer}>
                    Change Avatar
                </Button>
            </Menu.Item>
            <Menu.Item key="reset-password">
                <Button type="text" onClick={() => navigate('/account')}>Reset Password</Button>
            </Menu.Item>
            <Menu.Item key="report-issue">
                <Button type="text" onClick={invokeFeedback}>Report an issue</Button>
            </Menu.Item>
            <Menu.Item key="logout">
                <Button type="text" onClick={onLogOutPress}>Logout</Button>
            </Menu.Item>
        </Menu>
    );
    const settinguserNav = () => (
        <Menu>
            <Menu.Item key="reset-password">
                <Button type="text" onClick={() => navigate('/account')}>Reset Password</Button>
            </Menu.Item>
            <Menu.Item key="report-issue">
                <Button type="text" onClick={invokeFeedback}>Report an issue</Button>
            </Menu.Item>
        </Menu>
    );

    const MenURanderer = useMemo(() => (<NavigationMenu />), [search])
    const SettingRander = useMemo(() => {
        return (<div style={{ }}>
            <Dropdown overlay={settinguserNav()} placement="bottomRight" trigger={['click']}>
                <StyledListItem
                    button
                    onClick={(event) => { event.preventDefault() }}
                >
                    <StyledListItemIcon>
                        <SettingsIcon />
                    </StyledListItemIcon>
                    <ListItemText  primary="Settings" />
                </StyledListItem>
            </Dropdown>
            <StyledListItem
                button
                onClick={onLogOutPress}
            >
                <StyledListItemIcon>
                    <ExitToAppIcon />
                </StyledListItemIcon>
                <ListItemText primary="Logout" />
            </StyledListItem>
        </div>)
    }, [currentUser])

    const getUserProfileImg = useMemo(() => {
        if (profile_config && profile_config.config) {
            const avt = getAvatar(profile_config.config.profile_pic)
            if (avt) {
                return avt
            }
        }
        return userImg;
    }, [profile_config])
    return (
        <Wrappers>
            <Layout>
                <Drawer
                    title={get(currentOrg, 'displayName', '')}
                    placement={placement}
                    closable={false}
                    onClose={onClose}
                    open={opens}
                    key={placement}
                    width="378"
                    className="ownDrawer ownDrawerDistrict"
                    extra={
                        <Space>

                            <CloseOutlined onClick={onClose} style={{ color: 'white', fontSize: 20 }} />
                        </Space>
                    }
                >
                    <SideNavSection style={{ paddingLeft:5 , paddingRight:5 }}>
                    <div style={{ width: "100%", height: "80vh", display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: 30, paddingBottom: 30, }}>
                    {MenURanderer}
                    {SettingRander}
                    </div>
                    </SideNavSection>
                </Drawer>

                <Sider
                    trigger={null}
                    width={250}
                    collapsible collapsed={collapsed}
                    className="hides left-menu-container"
                    style={{
                        background: "rgb(255,255,255)", 
                        paddingLeft: 5, 
                        paddingRight: 5
                    }}>
                    <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: 30, paddingBottom: 30, }}>
                        {MenURanderer}
                        {SettingRander}
                    </div>
                </Sider>
                <Layout style={{ background:"rgb(255,255,255)"}}>
                <Header
                        className="cstmHeaderOld setBGSection"
                        style={{ backgroundImage:"none" , backgroundColor:"rgba(255,255,255)"}}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }} className="headDropdown">
                            <Button
                                className="hides"
                                type="text"
                                // icon=
                                onClick={toggleCollapse}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                    color: 'white'
                                }}
                            >
                                <OverviewText>
                                    Welcome back,
                                 {get(currentUser, 'firstName', '')} {get(currentUser, 'lastName', '')}
                                 </OverviewText>
                            </Button>
                            <Button
                                className="unhides"
                                type="text"
                                // icon=
                                onClick={() => showDrawer()}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                    color: 'white'
                                }}
                            >
                                <OverviewText>{collapsed ? <MenuUnfoldOutlined style={{ marginRight: '5px' }} /> : <MenuFoldOutlined style={{ marginRight: '5px' }} />} 
                                Welcome back </OverviewText>
                            </Button>
                        </div>
                        <RowContainer style={{ marginRight: 10 }} className="usrDetailUpr" border={currentUser?.role === 'teacher' || currentUser?.role === 'admin'}>
                            <Dropdown className="usrDetailInr" overlay={userNav()} placement="bottomRight" trigger={['click']}>
                                <UserContainer>
                                    <UserDetails className="usrDetail">
                                        <div className="usrDetailInfo" style={{ borderTop: 'none', borderBottom: 'none' }}>
                                            <div className="usrPic"><img src={getUserProfileImg} /></div>
                                            <div><UserName>{currentUser?.firstName} {currentUser?.lastName}</UserName></div>
                                            <div className="usrArrow"><img src={profileArrow} /></div>
                                        </div>
                                    </UserDetails>
                                    <UserDetailsMob className="usrDetail">
                                        <div className="usrPic"><img src={getUserProfileImg} /></div>
                                    </UserDetailsMob>
                                </UserContainer>
                            </Dropdown>
                        </RowContainer>
                    </Header>
                    <DistnameText> {get(currentOrg, 'displayName', '')}</DistnameText>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: '86vh',
                            background: 'white',
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Wrappers>
    )
}
