//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createOneGameVideo = (returnedPayload) => ({ type: ActionTypes.one_game_video.create_one_game_video, payload: returnedPayload });

export const updateOneGameVideo = (returnedPayload) => ({ type: ActionTypes.one_game_video.update_one_game_video, payload: returnedPayload });

export const deleteOneGameVideo = (returnedPayload) => ({ type: ActionTypes.one_game_video.delete_one_game_video, payload: returnedPayload });

export const listOneGameVideo = (returnedPayload) => ({ type: ActionTypes.one_game_video.list_one_game_video, payload: returnedPayload });

