import React from 'react';
import { Col, Row } from 'antd';
import Link from '@material-ui/core/Link';
import { Modal, Box } from '@material-ui/core';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinks from './../containers/videoLinks';
import VideoLinkDropdown from './../containers/videoLinkDropdown';
import { Pic1 , videoLink , videoBlog, bloglink, closeModal, modalBanner } from "utils/assets/images"
const style = { };
const ButtonLink = styled.button``;

const classNav = () => {
    return (
       <VideoLinkDropdown /> 
    );
};

export default function LearnScreen() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <Row className="learnContentUpr" gutter={[25, 0]}>

                <Col xs={24} sm={24} md={6} className="searchMob">
                   <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            Search
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </Col>

                <Col xs={24} sm={24} md={18}>
                    <div className="learnContent">
                        <Row gutter={[25, 0]} className="learnDetail">
                            <Col xs={12} sm={12} md={8} className="cstmLrnRow">
                                <div className="ShowDetailBnr">
                                    <img src={Pic1} />
                                    <Link className="ShowDetail"><img src={videoLink} onClick={handleOpen} /></Link>
                                </div>
                                <div className="learnCntLink"><a>Critical Language Acquisition</a></div>

                                <Modal open={open} onClose={handleClose} className="modelCntnt">
                                   <Box sx={style} className="modelCntntIner">
                                     <div className="lernModalBg">
                                      <div className="modelDtl">   
                                        <img src={modalBanner} />
                                        <img src={closeModal} onClick={handleClose} className="closeModelInfo" />
                                      </div>
                                     </div>
                                   </Box>
                                </Modal>
                                
                            </Col>
                            <Col xs={12} sm={12} md={8} className="cstmLrnRow">
                                <div className="ShowDetailBnr">
                                    <img src={Pic1} />
                                    <Link className="ShowDetail"><img src={bloglink} /></Link>
                                </div>
                                <div className="learnCntLink"><a>Critical Language Acquisition</a></div>
                            </Col>
                            <Col xs={12} sm={12} md={8} className="cstmLrnRow">
                                <div className="ShowDetailBnr">
                                    <img src={Pic1} />
                                    <Link className="ShowDetail"><img src={videoLink} /></Link>
                                </div>
                                <div className="learnCntLink"><a>Critical Language Acquisition</a></div>
                            </Col>
                            <Col xs={12} sm={12} md={8} className="cstmLrnRow">
                                <div className="ShowDetailBnr">
                                    <img src={Pic1} />
                                    <Link className="ShowDetail"><img src={videoLink} /></Link>
                                </div>
                                <div className="learnCntLink"><a>Critical Language Acquisition</a></div>
                            </Col>
                            <Col xs={12} sm={12} md={8} className="cstmLrnRow">
                                <div className="ShowDetailBnr">
                                    <img src={Pic1} />
                                    <Link className="ShowDetail"><img src={videoBlog} /></Link>
                                </div>
                                <div className="learnCntLink"><a>Critical Language Acquisition</a></div>
                            </Col>
                            <Col xs={12} sm={12} md={8} className="cstmLrnRow">
                                <div className="ShowDetailBnr">
                                    <img src={Pic1} />
                                    <Link className="ShowDetail"><img src={videoLink} /></Link>
                                </div>
                                <div className="learnCntLink"><a>Critical Language Acquisition</a></div>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col xs={24} sm={24} md={6} className="searchWeb">
                   <VideoLinks />
                </Col>

            </Row>
        </>
    );
}
