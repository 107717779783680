import React, { useEffect, useMemo, useState } from 'react'
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
import { get, map } from 'lodash';
import styled from 'styled-components';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { ArrowRight, Close, Search, Edit , ArrowForward, Add  } from '@material-ui/icons';
import COLOR from 'utils/constants/color';
import { useDispatch } from 'react-redux';
import { handleListSchoologyCources , handleUpdateSchoologyCources  } from 'store/booksUnits/thunks';
import { Col, Row } from 'antd';
import SelectableBook from 'components/SelectableBook';
import { useAllBook } from 'store/books/selectors';
import { useDistricts } from 'store/organizations/selectors';
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:90vh;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalSection =  styled.div`
    background-color: ${COLOR.white};
    display: inline-flex;
    flex-direction: column;
    width:90vw;
    height:100%;
    overflow:auto;
    padding: 16px;
`;
const BookSection = styled.div`
    width: 100%;
`
const CourseUpdate = ({ openEditModal, setOpenEditModal, selectedData , onUpdate , schoology_district }) => {
    const [courseName, setCourseName] = useState(get(selectedData, 'displayName', ''))
    const [courseBooks, setCourseBooks] = useState([])
    const [district_id,setdistrict_id] =useState(null);
    const [schoolId,setschoolId] =useState(null);
    const [selectableBooks,setSelectableBooks] = useState([])
    const [loader, setLoader] = useState(null)
    const bookAllList = useAllBook()
    useEffect(() => {
        let listBooks = [];

        for (var book in bookAllList) {
            let isNotAssignable = bookAllList[book].isNotAssignable;
            let _data = {
                id: bookAllList[book].id,
                description: bookAllList[book].description,
                count: bookAllList[book].count,
            };
            if (!isNotAssignable) {
                listBooks.push(_data);
            }
        }
        setSelectableBooks(listBooks)
    }, [bookAllList])
    useEffect(() => {
        if (selectedData) {
            setCourseName(get(selectedData, 'displayName', ''))
            setdistrict_id(get(selectedData, 'district_id'))
            setschoolId(get(selectedData, 'schoolId'))
            const books = get(selectedData, 'books', []) || []
            if(Array.isArray(books)){
                setCourseBooks(books.map(k=>k?.bookId))
            }else{
                setCourseBooks([])
            }
        }
    }, [selectedData])
    const dispatch = useDispatch()
    const mapCourse = () =>{
        return courseBooks.map(id=>{
            const csr = selectableBooks.find(k=>k.id==id)
            if(csr) {
                return {
                    bookId: csr.id,
                    ...csr
                }
            }
            return null
        }).filter(k=>k)
    }
    const onCourseUpdate = async () => {
        const data = {
            id: get(selectedData, 'id', null),
            displayName: courseName,
            books: mapCourse(),
            district_id,
            schoolId
        }
        setLoader(true)
        await dispatch(handleUpdateSchoologyCources(data))
        if(onUpdate){
            onUpdate()
        }
        setLoader(false)
        setOpenEditModal(false)
    }
    return (
        <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
            <ModalContent>
                <Row style={{ justifyContent: "flex-end", width: "100%" }}>
                    <IconButton
                        onClick={e => setOpenEditModal(false)}
                        style={{ padding: 0, marginRight: 10 }}
                    >
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <h2>{selectedData && selectedData.id ? "Update Course" : "Create Course"}</h2>
                <Row style={{ width: "100%" }}>
                    <TextField value={courseName} onChange={(e) => setCourseName(e.target.value)} label="Course Name *" variant="outlined" style={{ maxWidth: 400 }} />
                    <TextField value={schoolId} onChange={(e) => setschoolId(e.target.value)} label="course code" variant="outlined" style={{ maxWidth: 400 , marginLeft:12}} />
                </Row>
                <Row style={{ width: "100%" }}>
                    <FormControl variant="outlined" style={{ width:400,marginTop:12}}>
                        <InputLabel id="schoolLabel">{'Select Organization'}</InputLabel>
                        <Select
                            labelId="schoolLabel"
                            id="orgId"
                            onChange={e => {
                                setdistrict_id(e.target.value)
                            }}
                            value={district_id}

                        >
                            {schoology_district.map(district => (
                                <MenuItem key={district.id} value={district.id} >
                                    {district.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Row>
                <ModalSection>
                    <SelectableBook
                        chapter={selectableBooks}
                        selected={courseBooks}
                        preSelected={[]}
                        onSelect={(item) => {
                            setCourseBooks(item)
                        }} />
                </ModalSection>
                <Row style={{ width: "100%", marginTop: 20 , justifyContent:"flex-end"}}>
                    <Button variant="contained" color="primary" onClick={onCourseUpdate}>{loader?<Loading size={14} white/>:null} {selectedData && selectedData.id ? "Update" : "Create"}</Button>
                </Row>
            </ModalContent>
        </Modal>
    )
}







export default function SchoologyBookMap() {
    const [courseList,setCourseList] = useState([])
    const [loading , setLoading] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const dispatch = useDispatch()
    const district = useDistricts()
    const schoology_district = useMemo(() => {
        if (Array.isArray(district)) {
            return district.filter(k => Boolean(k.schoolId)|| k.schoolId == '0').map(k=>({
                id:k.id,
                displayName:k.displayName
            }))
        }
        return []
    }, [district])
    async function fetchdata() {
        setLoading(true)
        const data = await dispatch(handleListSchoologyCources())
        if (data && data.success) {
            setCourseList(get(data, 'data', []))
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchdata()
    }, [])
    const onEdit = (data) => {
        setSelectedData(data)
        setOpenEditModal(true)
    }
  return (
   <>
        <CourseUpdate openEditModal={openEditModal} selectedData={selectedData} setOpenEditModal={setOpenEditModal} onUpdate={fetchdata} schoology_district={schoology_district}  />
          <Wrapper>
          <Button variant="contained" color="primary" onClick={()=>onEdit({})}><Add/> Add Course</Button>
              <LoadingWrapper loading={loading}>
                  {courseList.length > 0 ? <Table style={{ width: "100%", textAlign: "center" }}>
                      <TableHead>
                          <TableRow>
                              <TableCell style={{ textAlign: "center" }}>Course name</TableCell>
                              <TableCell style={{ textAlign: "center" }}>Number of books</TableCell>
                              <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {map(courseList, cuser => {
                              return (<>
                                  <TableRow key={cuser.id}>
                                      <TableCell style={{ textAlign: "center" }}>{get(cuser, "displayName", '')}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}>{get(cuser, "books.length", '')}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}><Button style={{ width: 85, height: 35 }} variant="text" onClick={()=>onEdit(cuser)} > <Edit style={{ color: COLOR.green }} /> </Button></TableCell>
                                  </TableRow>
                              </>)
                          })}
                      </TableBody>
                  </Table> : null}
              </LoadingWrapper>
          </Wrapper>
   </>
  )
}
