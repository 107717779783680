//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as OrganizationActions from './actions';
import * as Sentry from "@sentry/react";
import {map, filter, flatMap} from 'lodash'

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create organization -------------------
export const handleCreateOrganization = (data) => async (dispatch) => {
    await dispatch(OrganizationActions.createOrganization());

    try {
        const organization = await API.post('organizationsRDS', `/organizations`, {
            body: { ...data }
        });

        await dispatch(OrganizationActions.createOrganizationSuccess(organization));
        return organization;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.createOrganizationFail(error));
    }
};

//  -- Get organization ----------------------
export const handleGetOrganization = (organizationID) => async (dispatch) => {
    await dispatch(OrganizationActions.getOrganization());

    try {
        const organization = await API.get('organizationsRDS', `/organization/${organizationID}`);
        await dispatch(OrganizationActions.getOrganizationSuccess(organization));
        return organization;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.getOrganizationFail(error));
    }
};

//  -- List orgs by parent ------------
export const handleListOrgsByParent = (id) => async (dispatch) => {
    await dispatch(OrganizationActions.listOrgsByParent());
    try {
        const organizations = await API.get('organizationsRDS', `/orgByParent/${id}`);
        await dispatch(OrganizationActions.listOrgsByParentSuccess(organizations));
        return organizations;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.listOrgsByParentFail(error));
    }
};
export const handleListDashboardInfo = (id) => async (dispatch) => {
    try {
        const organizations = await API.get('organizationsRDS', `/district_dash_info/${id}`);
        if (organizations && organizations.statusCode === 200) {
            await dispatch(OrganizationActions.setDistDashboardInfo(organizations));
        }
        return organizations;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
};
export const handleContentExamGraphData = (config) => async (dispatch) => {
    try {
        const organizations = await API.post('organizationsRDS', `/content_exam_graphdata`, {
            body: config
        });
        if (organizations && organizations.statusCode === 200) {
            await dispatch(OrganizationActions.setDistContentExamGraphData({
                config:config,
                data:organizations
            }));
        }
        return organizations;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
};
export const handleListContentExamData = (config) => async (dispatch) => {
    try {
        const organizations = await API.post('organizationsRDS', `/content_exam_overall`, {
            body: config
        });
        // if (organizations && organizations.statusCode === 200) {
        //     await dispatch(OrganizationActions.setDistContentExamGraphData({
        //         config:config,
        //         data:organizations
        //     }));
        // }
        return organizations;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
};
export const handleListContentPassingExamData = (config) => async (dispatch) => {
    try {
        const organizations = await API.post('organizationsRDS', `/passing_per_question`, {
            body: config
        });
        // if (organizations && organizations.statusCode === 200) {
        //     await dispatch(OrganizationActions.setDistContentExamGraphData({
        //         config:config,
        //         data:organizations
        //     }));
        // }
        return organizations;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
};
export const handleListContentPassingExamStudentData = (config) => async (dispatch) => {
    try {
        const organizations = await API.post('organizationsRDS', `/passing_by_user_ce`, {
            body: config
        });
        // if (organizations && organizations.statusCode === 200) {
        //     await dispatch(OrganizationActions.setDistContentExamGraphData({
        //         config:config,
        //         data:organizations
        //     }));
        // }
        return organizations;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
};
export const handleListbookUsed = (id,data) => async (dispatch) => {
    try {
        const organizations = await API.post('organizationsRDS', `/list_book_used/${id}`,{
            body: data
        });
        if (organizations && typeof organizations === 'object') {
            await dispatch(OrganizationActions.setDistBookUsesGraphData({
                config:data,
                data:organizations
            }));
        }
        return organizations;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return error;
    }
};

//  -- List orgs by parent ------------
export const handleListGetSchool = (id) => async (dispatch) => {
    await dispatch(OrganizationActions.listOrgsByParent());
    try {
        const organizations = await API.get('organizationsRDS', `/getSchool/${id}`);
        await dispatch(OrganizationActions.listOrgsByParentSuccess(organizations));
        return organizations;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.listOrgsByParentFail(error));
    }
};

//  -- List organizations -------------------
export const handleListDistricts = () => async (dispatch) => {
    await dispatch(OrganizationActions.listDistricts());

    try {
        const organizations = await API.get('organizationsRDS', `/organizations/district`);
        await dispatch(OrganizationActions.listDistrictsSuccess(organizations));
        return organizations;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.listDistrictsFail(error));
    }
};

// get district with correct counts --------------------------------
export const handleGetDistrictsWithCount = () => async (dispatch) => {
    await dispatch(OrganizationActions.listDistricts());
    const DISTRICT_LENGTH = 500;
    try {
        const numberOfDistrict = await API.get('organizationsRDS', `/listAllOrgLength/district`);
        const districtArr = Array.apply(
            0,
            new Array(Math.floor(numberOfDistrict.length / DISTRICT_LENGTH) + 1)
          ).map((k, i) => i);
          const allOrganization = await Promise.allSettled(
            map(districtArr, async (k) => {
              return API.get("organizationsRDS", `/getDistrict/${DISTRICT_LENGTH}/${k * DISTRICT_LENGTH}`);
            })
          ).then((v) => filter(v, ["status", "fulfilled"]).map((k) => k.value));
          const organizations = flatMap(allOrganization);
        // const organizations = await API.get('organizationsRDS', `getDistrict/{limit}/{offset}`);
        await dispatch(OrganizationActions.listDistrictsSuccess(organizations));
        return organizations;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.listDistrictsFail(error));
    }
};

//  -- List organizations School -------------------
export const handleListSchools = () => async (dispatch) => {
    await dispatch(OrganizationActions.listAllSchools());
    const ORGANIZATION_LENGTH = 500;
    try {
        const numberOfOrg = await API.get('organizationsRDS', `/listAllOrgLength/school`);
        const organizationArr = Array.apply(
            0,
            new Array(Math.floor(numberOfOrg.length / ORGANIZATION_LENGTH) + 1)
          ).map((k, i) => i);
          const allOrganization = await Promise.allSettled(
            map(organizationArr, async (k) => {
              return API.get("organizationsRDS", `/listAllOrg/school/${ORGANIZATION_LENGTH}/${k * ORGANIZATION_LENGTH}`);
            })
          ).then((v) => filter(v, ["status", "fulfilled"]).map((k) => k.value));
          // const organizations = await API.get('organizationsRDS', `/listAllOrg/school/10/1`);
          const organizations = flatMap(allOrganization);
        await dispatch(OrganizationActions.listAllSchoolsSuccess(organizations));
        return organizations;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.listAllSchoolsFail(error));
    }
};
export const handleListOrgTeacher = (orgId) => async (dispatch) => {
    await dispatch(OrganizationActions.listorgTeacherData());

    try {
        let teacherData = await API.get('organizationsRDS', `/teacherDataByOrg/${orgId}`);
        if(!Array.isArray(teacherData)){
            teacherData = []
        }
        await dispatch(OrganizationActions.listorgTeacherDataSuccess(teacherData));
        return teacherData;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.listOrganizationsFail(error));
    }
};



//  -- Update organization -------------------
export const handleUpdateOrganization = (organizationID, data) => async (dispatch) => {
    await dispatch(OrganizationActions.updateOrganization());

    try {
        await API.put('organizationsRDS', `/organizations/${organizationID}`, {
            body: { ...data }
        });

        await dispatch(OrganizationActions.updateOrganizationSuccess({ id: organizationID, ...data }));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(OrganizationActions.updateOrganizationFail(error));
    }
};

export const handleDeleteUserFromOrganization = (organizationID, users) => async (dispatch) => {
    if (!organizationID || !users || !users?.length) return;
    try {
        await API.post('organizationsRDS', `/deleteUserFromOrg`, {
            body: { users, orgId: organizationID }
        });
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        console.log('error', error);
    }
};


export const getOrganizationById = async (orgId) => {
    try {
        const org = await API.get('organizationsRDS', `/organization/${orgId}`);
        return org;
    } catch (error) {
        return {};
    }
}

export const getOrganizationByParent = async (parentId) => {
    try {
        const org = await API.get("organizationsRDS",`/orgByParent/${parentId}`);
        return org;
    } catch (error) {
        return [];
    }
}
export const getOrganizationBySchoolId = async (schoolid) => {
    try {
        return  await  API.get('organizationsRDS', `/schoolId/${schoolid}`);
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return {};
    }
}

export const getOrganizationByClasslinkId = async (uid,tentId) =>{
    try {
     return await API.get("organizationsRDS", `/classlink/${uid}/${tentId}`);
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return ({error})
    }
}

export const handleUpdateLicences = async (orgId) =>{
    try {
        return await API.get("organizationsRDS", `/licensesUpdate/${orgId}`);
       } catch (error) {
           if(error&&error.message!="Network Error"){
               Sentry.captureException(error)
            }
           return ({error})
       }
}