//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { findIndex } from 'lodash'

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const userInitial = null;
const usersInitial = [];
const allUserrInitial = [];
export const currentUser = (state = userInitial, action) => {
    switch (action.type) {
        case ActionTypes.users.getUserSuccess:
            return action.payload;
        case ActionTypes.users.getUserFail:
            return state;
        default:
            return state;
    }
};

export const users = (state = usersInitial, action) => {
    switch (action.type) {
        case ActionTypes.users.createUserSuccess:
            return [action.payload, ...state];
        case ActionTypes.users.deleteUserSuccess:
            return state.filter(x => x.id !== action.payload.id);
        case ActionTypes.users.updateUserSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload.data };
                }
                return x;
            });
        case ActionTypes.users.userEnableSuccess:
            const updatedIndex = findIndex(state, user => user.id === action.payload.id);
            if (updatedIndex !== -1) {
                const newState = [...state];
                newState[updatedIndex] = action.payload;
                return newState;
            } else {
                return state;
            }
        case ActionTypes.users.listUsersSuccess:
        case ActionTypes.users.listOrgUsersSuccess:
            return action.payload;
        case ActionTypes.users.createUserFail:
        case ActionTypes.users.deleteUserFail:
        case ActionTypes.users.updateUserFail:
        case ActionTypes.users.listUsersFail:
        case ActionTypes.users.listOrgUsersFail:
            return state;
        default:
            return state;
    }
};

export const distTeacher = (state = usersInitial, action) => {
    switch (action.type) {
        case ActionTypes.users.listAllTeacherUsersSuccess:
            return action.payload;
        case ActionTypes.users.listAllTeacherUsersFail:
            return state;
        case ActionTypes.users.userEnableSuccess:
                const updatedIndex = findIndex(state,user => user.id === action.payload.id);
                if (updatedIndex !== -1) {
                    const newState = [...state];
                    newState[updatedIndex] = action.payload;
                    return newState;
                } else {
                    return state;
                }
        default:
            return state;
    }
};
export const distStudents = (state = usersInitial, action) => {
    switch (action.type) {
        case ActionTypes.users.listAllStudentsUsersSuccess:
            return action.payload;
        case ActionTypes.users.listAllStudentsUsersFail:
            return state;
        default:
            return state;
    }
};


export const allUser = (state = allUserrInitial, action) => {
    switch (action.type) {
        case ActionTypes.users.listAllUsersSuccess:
            return action.payload;
        case ActionTypes.users.userEnableSuccess:
            const updatedIndex = findIndex(state,user => user.id === action.payload.id);
            if (updatedIndex !== -1) {
                const newState = [...state];
                newState[updatedIndex] = action.payload;
                return newState;
            } else {
                return state;
            }
        case ActionTypes.users.listAllUsersFail:
        case ActionTypes.users.userEnableFail:
            return state;
        default:
            return state;
    }
};

export const allUserCount = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.users.listAllStudentUsersSuccess:
            return action.payload;
        case ActionTypes.users.listAllStudentUsersFail:
        default:
            return state;
    }
};

export const profile_config = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.users.set_profile_config:
            return action.payload;
        case ActionTypes.users.update_profile_config:
            return { ...state, ...action.payload };
        case ActionTypes.users.clear_profile_config:
            return state;
        default:
            return state;
    }
}

export const profile_picture_picker = (state = false , action) => {
    switch (action.type) {
        case ActionTypes.users.show_profile_pic_viewer:
            return action.payload;
        default:
            return state;
    }
}

export const all_district_teacher = (state = [] , action) => {
    switch (action.type) {
        case ActionTypes.users.list_all_district_teacher:
            return action.payload;
        default:
            return state;
    }
}