import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import VideoLinks from '../../containers/videoLinks';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinkDropdown from '../../containers/videoLinkDropdown';
import VideoLinkDetailsBanner from '../VideoLinkDetailsBanner';

const ButtonLink = styled.button``;

const classNav = () => {
    return (
        <VideoLinkDropdown />
    );
};

export default function LearnScreen() {
    return (
        <>
            <div className="detailMain">
                <Row gutter={[25, 0]}>

                    <Col xs={24} sm={24} md={6} className="searchMob">
                        <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                            <ButtonLink onClick={e => e.preventDefault()}>
                                Search
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </ButtonLink>
                        </Dropdown>
                    </Col>

                    <Col xs={24} sm={24} md={18}>
                        <div className="detailInfo detailSpc">

                            {/* Satrt Include Banner */}
                                 <VideoLinkDetailsBanner />
                             {/* End Include Banner */}

                            <div><h1>The data driven instructor </h1></div>
                            <div>
                                <p>Data, data, data! We hear it everywhere, but which data can improve your classroom culture? I have found it useful to look beyond quantitative data. As the
                                beginning of a new year is approaching, ask yourself if you have a qualitative data tool that can give you a snapshot of individualized student needs, student barriers,
                                their stories. Looking at qualitative data is the first step for creating a conducive learning environment, since it helps build relationships. </p>
                                <p>Survey your class the first week of school, read the data, connect and make it your semester’s goal to build relationships. Always be positive and make your classroom
                                a place where students can immerse themselves in reflection and motivation. Show that you care when students fail, succeed and improve. As a rule of thumb, I have student
                                reflections every 5 weeks and students have the opportunity to reflect on their work habits as well as reflect on their instructor’s teaching strategies. Yes, they get to
                                grade me and tell me why they rated me the way they did. Reviewing their responses  allows me to adjust my pacing, reteach a concept, or simply throw out the window practices
                                that were not liked by my students. Qualitative data has given me a chance to focus on what is important to my students and what is working for them. This tool creates discourse
                                between students and teachers and if presented with consistency, the responses are more and more insightful. We are all in this together and remember quantitative data gives you
                                numeric values that are significant for  your instruction but qualitative gives you the possible reasons of those values. Get to know your students since day 1, there is nothing more
                                valuable in instruction than making connections.</p>
                                <p>Place a link to my Surveys Beginning of the Year and 5 week reflection surveys </p>
                            </div>
                            <div><label><b>- Vivanie Barrios, Los Angeles, California </b></label></div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={6} className="searchWeb">
                        <VideoLinks />
                    </Col>

                </Row>
            </div>

        </>
    );
}
