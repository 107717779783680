//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash'
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List tips books ----------------
export const handleTipsBook = (bookId) => async (dispatch) => {
    await dispatch(ActivityActions.listTipsBook());
    try {
        let tipsBook = []
        const tipsBookRDS = await API.get('tipsByBookRDS', `/tipsByBook/${bookId}`);
        if (Array.isArray(tipsBookRDS) && tipsBookRDS.length > 0) {
            tipsBook = tipsBookRDS
        }
        await dispatch(ActivityActions.listTipsBookSuccess(tipsBook));
        return tipsBook;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.listTipsBookFail(error));
    }
};


export const handleListTipsBook = () => async (dispatch) => {
    await dispatch(ActivityActions.listTipsBook());
    try {
        let tipsBook = []
        const tipsBookRDS = await API.get('tipsByBookRDS', `/list`);
        if (Array.isArray(tipsBookRDS) && tipsBookRDS.length > 0) {
            tipsBook = tipsBookRDS
        }
        await dispatch(ActivityActions.listTipsBookSuccess(tipsBook));
        return tipsBook;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.listTipsBookFail(error));
    }
};

export const handleCreateTipsBook = (data) => async (dispatch) => {
    await dispatch(ActivityActions.createTipsBook());
    try {
        const tipsBookRDS = await API.post('tipsByBookRDS', `/create`, {
            body: data
        });
        if (has(tipsBookRDS, "id")) {
            await dispatch(ActivityActions.createTipsBookSuccess(tipsBookRDS));
        }
        return tipsBookRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.createTipsBookFail(error));
    }
};
export const handleUpdateTipsBook = (data) => async (dispatch) => {
    await dispatch(ActivityActions.updateTipsBook());
    try {
        const tipsBookRDS = await API.put('tipsByBookRDS', `/update`, {
            body: data
        });
        if (has(tipsBookRDS, "id")) {
            await dispatch(ActivityActions.updateTipsBookSuccess(tipsBookRDS));
        }
        return tipsBookRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.updateTipsBookFail(error));
    }
};
export const handleDeleteTipsBook = (id) => async (dispatch) => {
    await dispatch(ActivityActions.deleteTipsBook());
    try {
        const tipsBookRDS = await API.del('tipsByBookRDS', `/delete/${id}`);
        if (has(tipsBookRDS, "id")) {
            await dispatch(ActivityActions.deletetTipsBookSuccess(id));
        }
        return tipsBookRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.deleteTipsBookFail(error));
    }
};

