//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep } from 'lodash';
import  { filter } from 'lodash'

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const quizzesInitial = [];
const quizInitial = {};

export const quiz = (state = quizInitial, action) => {
    switch (action.type) {
        case ActionTypes.quizzes.getQuizSuccess:
            return action.payload;
        case ActionTypes.quizzes.getQuizFail:
            return state;
        default:
            return state;
    }
};

export const quizzes = (state = quizzesInitial, action) => {
    switch (action.type) {
        case ActionTypes.quizzes.createQuizSuccess:
            return [action.payload, ...state];
        case ActionTypes.quizzes.updateQuizSuccess:
            const v = cloneDeep(state)
            return v.map(k => {
                if (k.id === action.payload.id) {
                    return { ...k, ...action.payload }
                }
                return k;
            })
        case ActionTypes.quizzes.deleteQuizSuccess:
            const delv = cloneDeep(state)
            return filter(delv,k =>k.id != action.payload.id)
        case ActionTypes.quizzes.listQuizzesSuccess:
        case ActionTypes.quizzes.listChapterQuizzesSuccess:
            return action.payload;
        case ActionTypes.quizzes.createQuizFail:
        case ActionTypes.quizzes.listQuizzesFail:
        case ActionTypes.quizzes.listChapterQuizzesFail:
            return state;
        default:
            return state;
    }
};
