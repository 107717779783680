import React from 'react'
import styled from 'styled-components'
import "./customStyle.css"

export default function StudentPolicy() {
    return (
        <div className="termsContentUpr">
          <div className="termsContent">
            <div className="stdntHead">
               <h1>One Way Education, LLC Student Information Privacy Notice</h1>
               <h2>Last modified: October 10th, 2022</h2>
            </div>
            <div className="stdntEduTxt">
                <p>One Way Education, LLC (“One Way Education,” “us,” or “we”) is committed to protecting the privacy of 
                students using One Way’s services. This One Way Education, LLC Student Information Privacy Notice supplements 
                One Way’s general Private Policy and applies toK-12 student users of One Way’s and Learning Site® online services 
                and related mobile applications (the “Services”). This Privacy Policy describes how One Way collects and uses the 
                information provided by or on behalf of students when registering for and using our Services so that you can understand 
                how we protect and use your information. If you areunder 18, we suggest that you review this information with your parents. 
                By visiting or using our Services, you acknowledge and consent to the information handling practices describedin this Student 
                Information Privacy Notice and our general Privacy Policy.</p>
                <p>End users of the Services may access the Services in varying ways. Where a student registers with One Way directly, the student 
                would be considered a customer of One Way Education, LLC. In other circumstances, One Way may enter into a contract directly with 
                aschool pursuant to which the Services would be used as part of the school’s curriculum in which case One Way may receive student 
                data directly from the school or through aschool’s third-party “rostering” service provider. In certain other circum stances, a teacherfrom 
                a student’s school may provide registration data for students who will have access tothe Services in connection with a particular course or program. 
                When One Way provides Services through a school or teacher at a school (which, for purposes of this Student Information Privacy Notice are referred to 
                collectively as a “School”) as part of the school’s curriculum, we may have access to personal information about K-12 students (“StudentUsers”) and certain 
                information provided or received in connection with the Services may be considered a student “educational record” under applicable law. In addition, 
                personally identifiable student information or data created or provided by a student or parent throughuse of the Services, created or provided by a School in 
                connection with the Services, or gathered through use of the Services may be protected by certain state Student Information Privacy laws.</p>
                <p>This Student Information Privacy Notice describes how One Way handles and protects the personal and educational information of Student Users of our Services. 
                This StudentInformation Privacy Notice pertains only to One Way’s handling and use of personally identifiable information of Student Users. Our general Private Policy 
                describes our practices for collecting, using, maintaining, and disclosing other information of users of our Services. To the extent that there is a conflict between the 
                terms of this Student Information Privacy Notice and our general Privacy Policy with regard to the personally identifiable information of a Student User, the terms of this 
                Student Information Privacy Notice shall control.
                </p>
            </div>

            <div>
               <div className="stdntEduSlid2">
                {/* <h3>One Way Education, LLC Student Information Privacy Notice</h3> */}
                <h4>What information do we collect?</h4>
                <p>If you create an account for use of the One Way Education, LLC Services or communicate with One Way you may provide what is generally 
                called “personal information” or “personaldata,” such as your full name, email address or other information that can be used to identify you. 
                Information that cannot be used to identify you as an individual is called “de-identified” or non-personal information.</p>
                <p>We collect information, including personal information, in a variety of ways which may vary according to your use of the Services and your account settings. 
                The categories of personal information we collect may include:</p>
              </div>
              <div className="stdntSlid2Txt">
                <label>Contact and profile information.</label>
                <p>When you create an account with One Way or communicate with us we may collect personal information including your name, 
                email address, school name, school district name, teacher name, class name, and course name/ID. In addition, we may ask you for personal 
                information at other times, such as when you contact our technical support team,send us an email, or otherwise communicate with us.</p>
              </div>  
              <div className="stdntSlid2Txt">
                <label>Information from Others.</label>
                <p>We make available certain features on our Services that allow other users to provide us information about you. For example, a parent or 
                School may provide information relating to a Student User, such as when a School or parent registers an account for their student or child 
                and provide us with certain information about the student or child, such as username.</p>
              </div> 
              <div className="stdntSlid2Txt">
                <label>Information about your use of our Services.</label>
                <p>We may collect usage information about your use of our Services, such as material saccessed, 
                lessons completed, problems you have attempted, and test and quiz results.</p>
              </div> 
              <div className="stdntSlid2Txt">
                <label>Location information.</label>
                <p>We may collect and use information about your location or infer your approximate location based on your IP, 
                but we do not collect the precise geo location of you or your device</p>
              </div> 
              <div className="stdntSlid2Txt">
                <label>Usage and device information.</label>
              </div> 

            </div>

            <div>
               <div className="stdntEduSlid2">
                {/* <h3>One Way Education, LLC Student Information Privacy Notice</h3> */}
                <p>We may use various technologies that automatically record certain technical information from your browser or device when you visit our website, read our emails, use our Services or otherwise engage with us. This information is typically collected through a variety of tracking technologies, including cookies, web beacons, Flash objects, log files, and similar technology (collectively, “tracking technologies”). These tracking technologies collect information about how you use the Services (e.g., the pages you view, the links you click, and other actions you take on the Services), information about your browser and online usage patterns (e.g., Internet Protocol (“IP”) address, browser type, browser language, pages viewed, whether you opened an email, links clicked), and information about the device(s) you use to access the Services (e.g., mobile device identifier, mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, and whether you access the Services from multiple devices). We may collect analytics data or use third-party analytics tools such as Google Analytics or Amazon Analytics, to help us measure traffic and usage trends for the Services and to understand more about the demographics of our users. You can learn more about Google’s and Amazon’s practices at
                   <a href='http://www.google.com/policies/privacy/partners'> http://www.google.com/policies/privacy/partners</a>,
                   <a href='https://aws.amazon.com/privacy/'>https://aws.amazon.com/privacy/</a> ,  
                   and view its currently available opt-out options at
                  <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a>,
                   <a href='https://docs.aws.amazon.com/pinpoint/latest/userguide/channels-sms-limitations-opt-out.html'>https://docs.aws.amazon.com/pinpoint/latest/userguide/channels-sms-limitations-opt-out.html</a></p>
              </div>

              <div className="stdntEduSlid2">
                <h3>How we use the information we collect</h3>
                <p>One Way Education, LLC uses this information in the following ways:</p>
              </div>
              <div className="stdntSlid2Txt">
                <label>To provide and enhance our Services.</label>
                <p>One Way uses the information you provide or that we collect to operate, maintain, enhance, and provide all of the features of our Services.</p>
              </div>
              <div className="stdntSlid2Txt">
                <label>To personalize your experience.</label>
                <p>We use the information to personalize your experience while using the Services, including on various devices you may use to access the Services.</p>
              </div> 
              <div className="stdntSlid2Txt">
                <label>To communicate with you.</label>
                <p>We may use your information to communicate with you about your account and respond to inquiries</p>
              </div> 
              <div className="stdntSlid2Txt">
                <label>To understand, improve, and develop our Services.</label>
                <p>One Way uses all of the information that you provide or that we collect from users to understand and analyze the usage trends, 
                learning behaviors, and preferences of our users, to improve the way the Services works and looks, and to create new features and functionality. 
                We may also use information to maintain, develop, support and improve our Services and other educational products and Services. When we use Student 
                User information for these purposes, we de-identify or aggregate the data so that an individual user may not be personally identified.</p>
              </div> 
            <div className="stdntSlid2Txt">
              <label>Third-Party Services</label>
              <p>We utilize a variety of Third-Party Services to facilitate the operation of our Service. Presently, these include <a href='https://marketingplatform.google.com/about/analytics/'> Google Analytics </a>  and <a href='https://developers.google.com/terms/api-services-user-data-policy'> Google APIs </a> with the potential for additional services in the future.
                Our learning site use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy'> Google API Services  User Data Policy </a>, including the Limited Use requirements.</p>
            </div> 
            </div>

            <div className="stdntEdu">
                <h4>Student Users and Student Education Records</h4>
                <p>When the Services are used as part of the School’s educational curriculum, the personal information related to the School’s Student 
                Users that is (i) provided to One Way by a student or by a School, or (ii) collected by One Way during the provision of the Services to a School, 
                may include information defined as “educational records” by the Family Educational Rights and Privacy Act (“FERPA”). In addition, we may gather other 
                information through use of the Services that describes or identifies a Student User which may come within the definition of “student data,” “covered information,” 
                or “personally identifiable information” that may be protected under certain state Student Information Privacy laws. For purposes ofthis Student Information Privacy 
                Notice, we call this information, collectively, “Student Education Records.”</p>

                <p>Our collection and use of Student Education Records is governed by our contracts with the Schools, by our Privacy Policy, and by applicable privacy laws. 
                For example, we work with Schools to help protect personal information from the Student’s educational record, asrequired by the Family Educational Rights and 
                Privacy Act (“FERPA”), and we work toprotect the personal information of students under 13 consistent with the Children’s Online Privacy Protection Act (“COPPA”). 
                We also observe applicable requirements of state Student Information Privacy laws when using or storing Student Education Records.</p>

                <p>In our effort to protect personal and educational information of Student Users and to comply with applicable law, we observe the following standards and 
                protocols regarding Student Education Records:</p>

                <ul>
                    <li>We collect, maintain, use and share Student Education Records only for an authorized educational purpose in connection with the Services, 
                    or as directed by the School, the Student User and/or the student’s parent or legal guardian (a “Parent”).</li>
                    <li>We do not disclose Student Education Records for targeted advertising purposes and do not engage in targeted advertising of Student Users.</li>
                    <li>We do not build a personal profile of a Student User other than in furtherance of an educational purpose in connection with use of the Services.</li>
                    <li>We maintain a data security program designed to protect Student Education Records maintained by the Services.</li>
                    <li>We will take reasonable steps to protect the Student Education Records from unauthorized access, destruction, use, modification, or disclosure</li>
                    <li>We will never sell Student Education Records unless the sale is part of a corporate transaction, such as a merger, acquisition, bankruptcy, or other sale of assets, 
                    in which case we will require the new owner to continue to honor the terms provided in this Privacy Policy or we will provide the School with notice and an opportunity 
                    to opt-out of the transfer of Student Education Records by deleting the Student Education Records before the transfer occurs</li>
                    <li>We will aggregate or de-identify student data used for purposes of helping us to improve our services or develop or improve our products or services.</li>
                    <li>We will contractually require any service providers that we engage in connection withthe Services who may receive Student Education Records acquired through our 
                    Services to use the information only to provide the contracted services, not to further disclose the information, to maintain reasonable data security measures, and tocomply with applicable data privacy laws.</li>
                    <li>We will clearly and transparently disclose our data policies and practices to our users.</li>
                    <li>We will not make any material changes to our Privacy Policy or contractual agreements that relate to the collection or use of Student Education Records without first giving 
                    notice to the School and providing a choice before the Student Education Records are used in a materially different manner than was disclosed when the information was collected.</li>
                    <li>We will delete Student User information if the school requests deletion of data under its control.</li>
                </ul>

                <p>Educational records are with in the control of the School that has entered into a contract with One Way Education, LLC. If you have any questions about reviewing, modifying, or deleting the 
                educational records of a Student User accessing the Services through a School, please contact your School directly</p>
            </div>

            <div>
                <div className="howShr">
                    <label>How we share and disclose Student Education Records.</label>
                    <p>Schools, including teachers, will have access to Student Education Records contained onthe Services. 
                    Parents may have access to Student Education Records contained on the Services through the School. 
                    If you have any questions about reviewing, modifying, ordeleting the educational records of a Student User accessing 
                    the Services through a School, please contact your School directly</p>
                </div>
                <div className="howShr">
                    <label>How we share and disclose Student Education Records.</label>
                    <p>Schools, including teachers, will have access to Student Education Records contained onthe Services. 
                    Parents may have access to Student Education Records contained on the Services through the School. 
                    If you have any questions about reviewing, modifying, ordeleting the educational records of a Student User accessing 
                    the Services through a School, please contact your School directly We may use or make permitted disclosures of student 
                    information and data as required or allowed by applicable law.</p>
                </div>
                <div className="howShr">
                    <label>How we retain and delete Student Education Records.</label>
                    <p>Student Education Records stored within the Services are protected through technical, physical, and administrative 
                    safeguards instituted by One Way Education, LLC.</p>
                    <p>We will not knowingly retain Student Education Records beyond the time period required to support an educational purpose, 
                    unless authorized by a School, adult student or Parent.</p>
                    <p>Student Users who create their own accounts (or their Parents) can delete their accountsand all personal information associated 
                    with the account by sending us an email at SUPPORT@1WAYEDUCATION.COM</p>
                    <p>The School is responsible for managing Student Education Records which the School nolonger needs for an educational purpose by submitting 
                    a deletion request when such data isno longer needed. Please note that One Way Education cannot comply with a School’s request to delete personal 
                    information in a user account except for Student User account screated by a School (i.e., using a School email address and/or an account login provided bya School) 
                    pursuant to a contractual agreement between the School and One Way, or unlessthe Student User (or the user’s Parent) requests deletion directly. The School is responsible 
                    for managing Student Education Records which the School nolonger needs for an educational purpose by submitting a deletion request when such data isno longer needed. 
                    Please note that One Way Education cannot comply with a School’s request to delete personal information in a user account except for Student User accounts created by 
                    a School (i.e., using a School email address and/or an account login provided bya School) pursuant to a contractual agreement between the School and One Way, or unless the 
                    Student User (or the user’s Parent) requests deletion directly.</p>
                </div>

                <div className="howShrMid">
                    <label>Questions about Student Education Records.</label>
                    <p>If you have questions about specific practices relating to Student Education Records provided to One Way by a School, 
                    please direct your questions to your School.</p>
                </div>

                <div className="howShr">
                    <label>One Way Privacy Policy for Student Users under 13 Years of Age (Young Student Users)</label>
                    <p>The Children’s Online Privacy Protection Act (“COPPA”) provides certain protections forinformation provided by individuals 
                        under the age of 13. One Way does not knowingly collect any personal information from children under the age of 13 unless and 
                        until theSchool has obtained appropriate parental consent for the student to use the One Way Services. One Way collects and uses 
                        data regarding Student Users at the direction of andunder the control of a School. One Way relies on each School to provide appropriate 
                        notice to parents of the School's use of third party service providers such as One Way, and for the Schools to provide consent, if necessary, 
                        and authorization for One Way to collect dataregarding Student Users, as permitted by COPPA. If One Way learns that personal information of a 
                        Young Student User has been collected on our Services without parental or School consent, One Way will take appropriate steps to delete the information. 
                        If you are aparent or guardian and discover that your child under the age of 13 has a registered 
                        account with our Services without your consent, please advise One Way at <strong>support@1wayeducation.com</strong> and request that we delete your child’s personal informationfrom our systems.</p>
                </div>

                <div className="howShr">
                    <label>Changes and updates to Privacy Policy</label>
                    <p>We will provide notice on our website if we make any material changes to our privacy policies that relate to the 
                    collection or use of Student Education Records</p>
                </div>

                <div className="howShr">
                    <label>Contacting One Way Education, LLC</label>
                    <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
                    <p>By email: SUPPORT@1WAYEDUCATION.COM</p>
                    <p>By mail: One Way Education, LLC</p>
                    <p>5600 NW Central Dr, Suite 278, Houston, TX 77092</p>
                    <p>By Phone: 1 (888) 746 – 2388</p>
                    <p>Monday – Friday: 8 am to 5 pm (Central Time)</p>
                </div>

            </div>
        </div>
      </div>
    )
}
