//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create submission --------------
export const createSubmission = () => ({ type: ActionTypes.pastSubmissions.createPastSub });
export const createSubmissionFail = (returnedPayload) => ({ type: ActionTypes.pastSubmissions.createPastSubFail, payload: returnedPayload });
export const updatePastSubmissions = (payload) =>({type:ActionTypes.submissions.updatePastSubmissions , payload : payload})
//  -- Delete user submissions --------
