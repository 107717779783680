//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createFavouriteVideo = (returnedPayload) => ({ type: ActionTypes.favourite_video.create_favourite_video, payload: returnedPayload });

export const updateFavouriteVideo = (returnedPayload) => ({ type: ActionTypes.favourite_video.update_favourite_video, payload: returnedPayload });

export const listFavouriteVideo = (returnedPayload) => ({ type: ActionTypes.favourite_video.list_favourite_video, payload: returnedPayload });

export const listOfMostUsedVideo = (returnedPayload) => ({ type: ActionTypes.favourite_video.most_used_video, payload: returnedPayload });
