//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _, { flatMap, map, filter, has } from "lodash"
import moment from 'moment';

//  -- Actions ------------------------
import * as SubmissionsActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create submission --------------

export const handleCreateSubmission = (data) => async(dispatch) => {
    await dispatch(SubmissionsActions.createSubmission());
    try {
        const submission = await API.post('assignmentsRDS', `/assignment`, {
            body: { ...data }
        });
        const assignment = { ...submission , assignment:data.assignment}
        await dispatch(SubmissionsActions.createSubmissionSuccess(assignment));
        return submission 
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(SubmissionsActions.createSubmissionFail(error));
    }
};



//  -- List class submissions ---------
export const handleListClassSubmissions = (classID,returnVal) => async(dispatch) => {
    await dispatch(SubmissionsActions.listClassSubmissions());
   if(!classID) return;
   const SUBMISSION_LENGTH = 300
    try {
        
         const numberOfSubmission = await API.get('assignmentsRDS', `/getSubmissionLengthByClassId/${classID}`);
        if (numberOfSubmission && numberOfSubmission.length) {
            const submissionArr = Array.apply(0, new Array(Math.floor(numberOfSubmission.length / SUBMISSION_LENGTH) + 1)).map((_k, i) => i)
            const allSubmission = await Promise.allSettled(map(submissionArr, async k => {
                return API.get('assignmentsRDS', `/submissionByClass/${classID}/${k * SUBMISSION_LENGTH}/${SUBMISSION_LENGTH}`);
            })
            ).then(v => filter(v, ["status", "fulfilled"]).map(k => k.value))
            const allSubmissionData = flatMap(allSubmission);
            await dispatch(SubmissionsActions.listClassSubmissionsSuccess(allSubmissionData));
            return allSubmissionData;
        } else {
            const submissionDataRDS = await API.get('assignmentsRDS', `/assignmentsByClass/${classID}`);
            let submissionData = []
            if (submissionDataRDS.length > 0) {
                submissionData = submissionData.concat(submissionDataRDS)
            }
            if (returnVal) {
                return submissionData;
            }
            await dispatch(SubmissionsActions.listClassSubmissionsSuccess(submissionData));
            return submissionData;
        }
        
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(SubmissionsActions.listClassSubmissionsFail(error));
    }
};




//  -- List user submissions ----------
export const handleListUserSubmissions = (userID,cb) => async(dispatch) => {
    await dispatch(SubmissionsActions.listUserSubmissions());
    try {
        const submissionData=[];
        const submissionsRDS = await API.get('assignmentsRDS', `/assignmentsByUser/${userID}`);
        if(Array.isArray(submissionsRDS)&& submissionsRDS.length>0){
            submissionData.push(...submissionsRDS)
        }
       if(cb){
           return submissionData;
       }
        await dispatch(SubmissionsActions.listUserSubmissionsSuccess(submissionData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(SubmissionsActions.listUserSubmissionsFail(error));
    }
};

//  -- Update submission --------------
export const handleUpdateSubmission = (submissionID, data,tempData) => async(dispatch) => {
    await dispatch(SubmissionsActions.updateSubmission());
    let _submission =null
    let submissiom_id=submissionID
    let postData=data;
    let cDate= moment().valueOf()
    if(typeof tempData=="object"){
        tempData.complete=true;
    }
    if(typeof data.complete != "boolean"){
        data.complete=true;
    }
     try {
         if(!submissionID&&tempData){
             _submission =  await API.post('assignmentsRDS', `/assignment`, {
                body: { ...tempData }
               });
         }
     } catch (error) {
         console.log("error in create submission at the tiome of updatation",error);
     }
    try {
        if(!submissiom_id&&_submission){
            submissiom_id=_submission.id
            tempData.id = _submission.id
            postData=tempData
        }
      if(!submissiom_id&&!_submission){
          return
      }
        postData.createdAt= cDate;
        const submission = await API.put('assignmentsRDS', `/assignments/${submissiom_id}`, {
            body: { ...postData }
        });
        const assignment = data?.assignment ? data.assignment : tempData?.assignment
        const user_data =  data?.user ? data.user : tempData?.user
        if (!submissionID) {
            await dispatch(SubmissionsActions.createSubmissionSuccess(({ ...submission, assignment:assignment , user:user_data })));
        } else {
            await dispatch(SubmissionsActions.updateSubmissionSuccess({ ...submission, assignment: assignment , user:user_data }));
        }
        return submission
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(SubmissionsActions.updateSubmissionFail(error));
    }
};
export const handleUpdateMetSubmission = (data) => async(dispatch) => {
    try {
        const submission = await API.put('assignmentsRDS', `/updateMetSubmission`, {
            body:data
        });
        if(has(submission,"id")){
            await dispatch(SubmissionsActions.updateSubmissionSuccess(submission));
        }
        return submission;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};
export const handleAiFeedback = (submission_id, language_feedback) => async(dispatch) => {
    try {
        const submission = await API.post('assignmentsRDS', `/ai_feedback`, {
            body:{
                "submission_id":submission_id,
                "language_feedback" : language_feedback
            }
        });
        if(has(submission,"id")){
            await dispatch(SubmissionsActions.updateSubmissionSuccess(submission));
        }
        return submission;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
    }
};
export const handleUpdateSubmissionsToAll  = (submissions,classId) => async(dispatch) => {
    try {
     const submission =  await API.put('assignmentsRDS', `/updateToAllGrade`, {
         body: { submissions  }
        });
 
      await dispatch(handleListClassSubmissions(classId))  
      return submission;
    } catch (error) {
     
    }
 };
export const handleReturnSubmission = (submissionID) => async (dispatch) => {
    await dispatch(SubmissionsActions.updateSubmission());

    try {
        const submission = await API.put('assignmentsRDS', `/returnSubmission/${submissionID}`, {
        });
        if (has(submission, "id")) {
            await dispatch(SubmissionsActions.updateSubmissionSuccess(submission));
        }
        return submission
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(SubmissionsActions.updateSubmissionFail(error));
    }
};
export const handleUpdateGrade = (data) => async (dispatch) => {
    try {
        const submission = await API.put('assignmentsRDS', `/updateGrade`, {
            body: data
        });
        if(submission){
            await dispatch(SubmissionsActions.updateSubmissionSuccess(submission));
            return submission
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(SubmissionsActions.updateSubmissionFail(error));
    }
}


