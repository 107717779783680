//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useReducer,
} from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import COLOR from "utils/constants/color";
import { get, map, isEmpty, has, find, isArray, groupBy } from "lodash";
import { Row, Col, Progress, Avatar, Dropdown, Menu, Badge } from "antd";
import {
  ArrowBack,
  Done,
  DoneAll,
  AttachFile,
  Image,
  CloudDownload,
  AccountCircle,
  QueryBuilder,
  Close,
  MenuBook,
  InfoRounded,
} from "@material-ui/icons";
import { v4 } from "uuid";
import moment from "moment";
import { debounce, filter } from "underscore";
import {
  TransparentLogoWhite,
  MessageRocket,
  MobIcon,
  WriteEdit,
  TrashSvg,
} from "utils/assets/images";
import {
  Typography,
  ImageList,
  ImageListItem,
  Popover,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import {
  CaretDownOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { makeStyles } from "@material-ui/core/styles";
import { storage } from "firebaseConfig";
import {
  ref as fireRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import EmojiSelector from "./emojiPicker";
import {
  useScheduledMessages,
  useChatConfig,
  useChatMessage,
} from "store/chat/selectors";
import {
  handleCreateScheduleMessage,
  handleSendMessageChat,
  handleUpdateScheduleMessage,
  handleRemoveScheduleMessage,
  handleUpdateSeenMessageByUserId,
} from "store/chat/thunks";
import { Scheduler } from "./scheduler";
import Loading from "components/Loading";
import useMobileDeviceDetection, { useComponentVisible } from "./hooks";
import { Settings } from "./settings";
import { MessageSeenModal } from "./MessageSeenModal";
import { MessageRestric } from "./MessageRestric";
import { CheckMark } from "./CheckMark";
import AudioRecorder from "./AudioRecorder";
import { ELEPHANT, getAvatar } from "utils/assets/images/lootie";
import SendIcon from "@material-ui/icons/Send";
import Lottie from "lottie-react";
import { useUserClasses } from "store/userClasses/selectors";
const MAX_FILE_SIZE = 25165824;
const time_format = "HH:mm";

const useStyles = makeStyles((theme) => ({
  bulbicon: {
    color: " rgb(223 175 109)",
    fontSize: "18px",
    rotate: "deg(180)",
    transform: "rotate(180deg)",
    marginRight: "5px",
  },
  usericon: {
    color: "rgb(255, 255, 255)",
    fontSize: "30px",
    marginRight: " 5px",
  },
  addIcon: {
    color: "rgb(255, 255, 255)",
    fontSize: "20px",
    marginRight: " 5px",
  },
  attachIcon: {
    color: "rgb(255, 255, 255)",
  },
  emojiIcon: {
    color: COLOR.yellow,
  },
  timePicker: {
    background: "rgb(80,114,116)",
    // borderRadius: '20px',
    // height: '25px',
    // color: '#fff',
    // width: '90px',
    // fontSize: '12px',
    // borderBottom:"none"
  },
}));
//  -- Constants ----------------------
//  -- Components ---------------------
//  -- Thunks -------------------------

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const messageInterface = {
  id: "",
  message: "",
  senderId: "",
  classId: "",
  orgId: "",
  receiverId: "",
  media: null,
  type: "text",
  brodcastId: "",
  createdAt: "",
  quee: true,
  code: "message",
  senderName: "",
};
const seenInterFace = {
  messageIds: [],
  type: "seen_deliver",
  to: "",
  me: "",
  deliverAt: "",
  readAt: "",
  seen: true,
  code: "seen_deliver",
};
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid #a5a5a5;
  background-color: transpatent;
  display: flex;
  align-items: center;
  margin-right: 0px;
  position: relative;
`;
const UserName = styled.span`
  color: rgb(218, 218, 218);
  text-transform: capitalize;
  font-size: 12px;
  line-height: 17px;
  @media (max-width: 569px) {
    max-width: 150px;
  }
`;
const MessageArea = styled.div`
  // height: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  // max-height: calc(78vh);
  max-height: calc(90vh);
  padding-bottom: 32px;
  @media screen and (max-width: 450px) {
    padding-bottom: 125px !important;
  }
  @media screen and (max-width: 992px) {
    // padding-bottom: 45px;
    padding-bottom: 70px;
  }
  @media screen and (min-width: 1280px) {
    max-height: calc(77vh);
  }
`;
const MessageBox = styled.div`
  display: flex;
  border-top: 1px solid #a5a5a5;
  position: relative;
  @media (max-width: 992px) {
    height: initial;
  }
`;
const MessageInQuee = styled.div`
  height: 50px;
  //    background:rgba(0,0,0,0.5);
  background: #797979;
  width: 100%;
  position: absolute;
  top: -50px;
  display: flex;
`;
const ProgressQuee = styled.div`
  height: 5px;
  width: 100%;
  position: absolute;
  top: -5px;
  display: flex;
`;
const MessageInQueeScheduled = styled.div`
  min-height: 50px;
  max-height: 200px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: absolute;
  bottom: -50px;
  display: flex;
  flex-direction: column;
  z-index: 11;
  overflow: auto;
`;
const SendButton = styled.div`
  height: 40px;
  width: 100px;
  border-radius: 20px;
  background: #2991fa;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 568px) {
    height: 36px;
  }
`;
const SendArea = styled.div`
  width: ${(p) => (p.isStudent ? "100%" : "70%")};
  border-right: ${(p) => (p.isStudent ? "none" : "0.5px solid #fff")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
const SendOptionArea = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputBox = styled.input`
  width: 94%;
  border-radius: 20px;
  border: 1px solid;
  padding: 10px;
  outline: none;
  @media screen and (max-width: 568px) {
    padding: 6px 8px;
  }
`;
const MessageRow = styled.div`
  display: flex;
  flex-direction: column;
`;
const TimeDisplay = styled.span`
  font-size: 10px;
  text-align: right;
  display: flex;
  align-self: flex-end;
  color: #fff;
  margin-bottom: 10px;
  position: absolute;
  left: 0px;
  bottom: -30px;
  min-width: 140px;
`;
const TimeDisplayScheduled = styled.span`
  font-size: 10px;
  text-align: right;
  display: flex;
  align-self: flex-end;
  color: black;
  min-width: 140px;
  font-weight: bold;
`;
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  width: fit-content;
  position: relative;
  min-width: 100px;
  margin-top: 10px;
  margin-bottom: 15px;
  word-break: break-word;
`;
const Logo = styled.img`
  height: 64px;
  width: auto;
  @media (max-width: 668px) {
    height: 42px;
  }
`;
const RowHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 568px) {
    display: block;
    flex-direction: initial;
  }
`;
const LogoCol = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 568px) {
    width: 100%;
    justify-content: space-between;
    padding-right: 6px;
  }
`;
const NameCol = styled.div`
  // width:50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  @media (max-width: 568px) {
    width: 100%;
    justify-content: space-between;
    //   padding: 0px 12px;
  }
`;
const EmojiBox = styled.div`
  width: 2vw;
  height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  @media (max-width: 992px) {
    width: initial;
    height: initial;
    margin-bottom: 0px;
  }
`;
const MessageRocketOnNoMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: capitalize;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    padding-top: 32px;
    min-height: 58vh;
    padding-bottom: 32px;
  }
`;
const NoMessageImg = styled.img`
  height: 60%;
  width: auto;
  @media screen and (max-width: 1366px) {
    width: 100% !important;
    height: initial !important;
  }
`;
const MessageForNoMessage = styled.div`
  font-size: 15px;
  white-space: pre-wrap;
  font-weight: bold;
  text-align: center;
  padding: 0px 15px;
  text-transform: initial;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 18px;
  width: 432px;
  @media screen and (max-width: 568px) {
    width: 100%;
  }
`;
const FileUploader = styled.input`
  display: none;
`;
const MediaLink = styled.a`
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 10px;
  &:hover {
    color: ${COLOR.white};
  }
`;
const MediaItems = styled.div`
    height: 30px;
    min-width: 200px;
    max-width: 250px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    display: flex;
    background:${(p) => (p.mine ? "#2075b463" : "#fff")};
    box-shadow:${(p) =>
      p.mine
        ? "-1px -1px 2px 0px #07638d14, -2px 1px 2px 0px #07638d7a, 2px 1px 2px 0px #256fea47;"
        : "-1px -1px 2px 0px #fff, -2px 1px 2px 0px #fff, 2px 1px 2px 0px #fff;"}
    border-radius: 5px;
    padding: 5px;
}
`;
const FileIconArea = styled.div`
  width: 15%;
`;
const FileNameArea = styled.div`
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ui-sans-serif;
`;
const FileDownoladArea = styled.div`
  width: 10%;
`;

const SettingContainer = styled.div`
  background-color: #fff;
  width: 300px;
  min-height: 100px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  border-radius: 6px;
`;
const CloseReapper = styled.button`
  border: none;
  border-radius: 50%;
  background: rgb(7 35 110);
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 35%;
  color: #fff;
`;
const ClassSelector = styled.div`
  min-height: 20px;
  border-radius: 20px;
  background: #2d8a88;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  overlow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 3px 7px;
  font-size: 12px;
  margin: 5px 0px 5px 5px;
`;
const ScheduledMessageButton = styled.div`
  min-height: 25px;
  border-radius: 20px;
  background: rgb(75 156 161 / 80%);
  flex-direction: row;
  color: #fff;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 3px;
  padding-right: 6px;
  font-size: 11px;
  font-weight: 500;
  width: 144px;
  justify-content: center;
  margin: 8px 5px 5px 0px;
`;
const PaddedBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 8px;
  text-align: left;
  width: 100%;
`;
const TrashButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  margin-left: 10px;
`;
const MobIconContainer = styled.img`
  height: 20px;
  width: 20px;
  object-fit: cover;
`;
const ScheduleContainer = styled.div`
  background-color: rgb(210, 207, 228);
  width: 100%;
  height: 50px;
  padding: 5px;
  display: flex;
  border-bottom: 1px solid;
`;
const MessageInnerRow = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #3e4146;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  word-break: break-word;
`;
const getTimeMessageTime = (messageTime) => {
  if (moment().isSame(messageTime, "date")) {
    const hourDiff = moment().diff(moment(messageTime), "hours");
    if (hourDiff > 0) {
      return `${moment(messageTime).format("hh:mm a")}`;
    } else {
      const minutDiff = moment().diff(moment(messageTime), "minutes");
      const secondDiff = moment().diff(moment(messageTime), "seconds");
      return minutDiff > 0
        ? `${minutDiff} minutes ago.`
        : secondDiff > 10
        ? `${secondDiff} seconds ago.`
        : `Just now.`;
    }
  } else if (moment().isSame(messageTime, "year")) {
    return moment(messageTime).format("MMM DD, hh:mm a");
  } else {
    return `${moment(messageTime).format("MM/DD/YYYY hh:mm a")}`;
  }
};
function isChatHourAvailable(userChatHourConf) {
  if (!has(userChatHourConf, "endTime")) return true;
  return (
    moment().isSameOrBefore(
      moment(userChatHourConf.endTime, time_format),
      "minutes"
    ) &&
    moment().isSameOrAfter(
      moment(userChatHourConf.startTime, time_format),
      "minutes"
    )
  );
}
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
const Messages = ({
  currentUser,
  selectedUser,
  socket,
  activeClass,
  socketRenew,
  message,
  FILE_TYPE,
  classes,
  onClassSelect,
  setSelectedUser,
  activeUser,
}) => {
  const isBordcast = !get(selectedUser, "username") && has(selectedUser, "id");
  const selectedUserConfig = get(selectedUser, "config", {});
  const userChatHourConf = get(get(selectedUser, "config", {}), "chatHour");
  const userChatConfig = useChatConfig();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const hasChatHour = isChatHourAvailable(userChatHourConf);
  const scheduledMessage = useScheduledMessages();
  const selectedUserScheduledMessage = filter(
    scheduledMessage,
    (k) =>
      get(k, "message.receiverId") === get(selectedUser, "id") ||
      get(k, "message.brodcastId") === get(selectedUser, "id")
  );
  const isStudent = get(currentUser, "role") === "student";
  const hasMoreClass = !isStudent && classes && classes.length > 1;
  const chatDisabled = isStudent
    ? get(selectedUserConfig, "disabled", false)
    : get(userChatConfig, "disabled", false);
  const dispatch = useDispatch();
  const [messageText, setMessageText] = useState("");
  const messageBoxRef = useRef();
  const [actMessage, setActMessage] = useState([]);
  const cssClasses = useStyles();
  const [mediaFile, setMediaFile] = useState(null);
  const [scheduleEl, setScheduleEl] = React.useState(null);
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [editScheduledMessageval, seteditScheduledMessageval] = useState(null);
  const [broadcastReceivers, setbroadcastReceivers] = useState([]);
  const [deleteSchudelLoader, setDeleteScheduleLoader] = useState(null);
  const [messageSeenData, setMessageSeenData] = useState(null);
  const schedulePopOveropen = Boolean(scheduleEl);
  const schedulePopOverid = schedulePopOveropen ? "simple-popover" : undefined;
  const chatMessages = useChatMessage();
  const userClasses = useUserClasses();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [fileUploaderLoader, setFileUploaderLoader] = useState({
    loading: false,
    per: 0,
    type: "",
  });
  const isDisableSendButton = () => {
    return (!messageText || messageText.length == 0) && !mediaFile;
  };
  const [alertView, setAlertView] = useState({ open: false, message: "" });
  const imgRef = useRef();
  const fileRef = useRef();
  const schedulerRef = useRef();
  const deviceType = useMobileDeviceDetection();
  useEffect(() => {
    const interval = setInterval(forceUpdate, 15000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const archivedItems = userClasses.filter(item => item.archived === false);
  const matchingArchivedItems = classes.filter(item1 =>
    archivedItems.some(archivedItem =>
      item1.id === archivedItem.id && item1.orgId === archivedItem.orgId
    )
  );

  const sendMessage = async () => {
    if (!selectedUser || !activeClass || isDisableSendButton()) return;
    let messageCreate = { ...messageInterface };
    messageCreate.classId = isBordcast ? selectedUser.id : activeClass.id;
    messageCreate.type = mediaFile ? "media" : "text";
    messageCreate.senderId = currentUser.id;
    messageCreate.receiverId = isBordcast ? null : selectedUser.id;
    messageCreate.orgId = currentUser.orgId;
    messageCreate.message = messageText;
    messageCreate.brodcastId = isBordcast ? selectedUser.id : null;
    messageCreate.id = v4();
    messageCreate.createdAt = new Date();
    messageCreate.media = mediaFile;
    messageCreate.senderName = messageCreate.senderName = isBordcast
      ? `${get(selectedUser, "displayName", "")}`
      : `${get(currentUser, "lastName", "")}, ${get(
          currentUser,
          "firstName",
          ""
        )}`;
    try {
      await dispatch(handleSendMessageChat(messageCreate));
      invokSend(messageCreate);
      setMessageText("");
      setMediaFile(null);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const createScheduledMessage = async (scheduledAt) => {
    if (!selectedUser || !activeClass || isDisableSendButton()) return;
    setScheduleLoader(true);
    if (editScheduledMessageval) {
      let messageCreate = { ...editScheduledMessageval };
      messageCreate.message.media = mediaFile;
      messageCreate.message.message = messageText;
      messageCreate.scheduledAt = scheduledAt;
      await dispatch(handleUpdateScheduleMessage(messageCreate));
      setMessageText("");
      setMediaFile(null);
      setScheduleLoader(false);
      seteditScheduledMessageval(null);
    } else {
      let messageCreate = { ...messageInterface };
      messageCreate.classId = isBordcast ? selectedUser.id : activeClass.id;
      messageCreate.type = mediaFile ? "media" : "text";
      messageCreate.senderId = currentUser.id;
      messageCreate.receiverId = isBordcast ? null : selectedUser.id;
      messageCreate.orgId = currentUser.orgId;
      messageCreate.message = messageText;
      messageCreate.brodcastId = isBordcast ? selectedUser.id : null;
      messageCreate.id = v4();
      messageCreate.createdAt = new Date();
      messageCreate.media = mediaFile;
      messageCreate.senderName = isBordcast
        ? `${get(selectedUser, "displayName", "")}`
        : `${get(currentUser, "lastName", "")}, ${get(
            currentUser,
            "firstName",
            ""
          )}`;
      const data = {
        message: messageCreate,
        senderId: currentUser.id,
        receiverId: messageCreate.receiverId,
        scheduledAt: scheduledAt,
      };
      try {
        await dispatch(handleCreateScheduleMessage(data));
        setMessageText("");
        setMediaFile(null);
        setScheduleLoader(false);
      } catch (error) {
        console.log(error, "error");
        setScheduleLoader(false);
      }
    }
  };
  const deleteScheduledMessage = async (item) => {
    setDeleteScheduleLoader(item.id);
    await dispatch(handleRemoveScheduleMessage(item.id));
    setDeleteScheduleLoader(null);
  };
  useEffect(() => {
    setbroadcastReceivers(activeUser || []);
  }, [activeUser]);

  const getUnreadCountForClass = useCallback(
    (classId) => {
      let count = 0;
      if (chatMessages && chatMessages.length > 0) {
        count = (chatMessages || []).reduce((accumulator, c) => {
          if (
            c.receiverId === get(currentUser, "id") &&
            !c.seen &&
            !c.brodcastId &&
            c.classId === classId
          ) {
            return accumulator + 1;
          }
          return accumulator;
        }, 0);
        const brodcastMessage = groupBy(chatMessages, "brodcastId");
        if (get(currentUser, "role") === "student") {
          let v = {};
          Object.keys(brodcastMessage).forEach((key) => {
            if (key != null && key !== "null") {
              v[key] = filter(
                brodcastMessage[key],
                (msg) => !find(msg.brodcastSeen, ["id", currentUser.id])
              ).length;
            }
          });

          const activrClassVal = v[classId] > 0 ? v[classId] : 0;
          count += activrClassVal;
        }
      }
      return count;
    },
    [chatMessages]
  );
  // const fileMessage = async (mediaData) => {
  //     let fileTopLoad = mediaData ? mediaData : mediaFile;
  //     if (!selectedUser || !activeClass && !fileTopLoad) return;
  //     let messageCreate = { ...messageInterface }
  //     messageCreate.classId = isBordcast ? selectedUser.id : activeClass.id
  //     messageCreate.type = "media"
  //     messageCreate.senderId = currentUser.id
  //     messageCreate.receiverId = isBordcast ? null : selectedUser.id
  //     messageCreate.orgId = currentUser.orgId
  //     messageCreate.message = messageText
  //     messageCreate.brodcastId = isBordcast ? selectedUser.id : null;
  //     messageCreate.id = v4()
  //     messageCreate.createdAt = new Date()
  //     messageCreate.media = mediaData ? mediaData : mediaFile
  //     try {
  //         await dispatch(handleSendMessageChat(messageCreate))
  //         invokSend(messageCreate)
  //         setMessageText('')
  //         setMediaFile(null)
  //     } catch (error) {
  //         console.log(error, "error")
  //     }
  // }
  const invokSend = (data) => {
    if (socket) {
      try {
        if (socket.readyState == 3) {
          socketRenew();
          setTimeout(() => {
            socket.send(JSON.stringify(data));
          }, 2000);
        } else {
          socket.send(JSON.stringify(data));
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  useEffect(() => {
    if (messageBoxRef.current) {
      if (actMessage.length != message.length) {
        messageBoxRef.current.scrollTo(0, messageBoxRef.current.scrollHeight);
      }
      if (!isBordcast) {
        const canBeUpdate = filter(
          message,
          (msg) =>
            (!msg.deliverAt || !msg.seen) && msg.senderId != currentUser.id
        );
        if (canBeUpdate.length > 0) {
          markedMessageAsSeen();
        }
      } else {
        if (!isStudent) return;
        const canBeUpdate = filter(
          message,
          (msg) =>
            !find(msg.brodcastDelivered, ["id", currentUser.id]) ||
            !find(msg.brodcastSeen, ["id", currentUser.id])
        );
        if (canBeUpdate.length > 0 && message && message.length > 0) {
          markedMessageAsBrodcast();
        }
      }
    }
    setActMessage(message);
  }, [message, selectedUser]);

  const markedMessageAsSeen = debounce(() => {
    console.log("call for update");
    const markMsg = { ...seenInterFace };
    markMsg.me = currentUser.id;
    markMsg.to = selectedUser.id;
    markMsg.deliverAt = moment().toISOString();
    markMsg.readAt = moment().toISOString();
    dispatch(handleUpdateSeenMessageByUserId(selectedUser.id));
    invokSend(markMsg);
  }, 1000);
  const markedMessageAsBrodcast = debounce(() => {
    const markMsg = {
      code: "seen_deliver_brodcast",
      brodcastId: get(selectedUser, "id"),
      me: get(currentUser, "id"),
    };
    invokSend(markMsg);
  }, 1000);
  const getIconFile = (type, isMine, size = 20) => {
    const iconMap = {
      word: FileWordOutlined,
      excel: FileExcelOutlined,
      powerpoint: FilePptOutlined,
      pdf: FilePdfOutlined,
      default: FileOutlined,
    };

    const IconComponent = iconMap[type] || iconMap.default;

    return (
      <IconComponent
        style={{ color: isMine ? "#fff" : "gray", fontSize: size }}
      />
    );
  };

  const renderTextMessage = (msg) => msg.message;
  const renderMedia = (msg, isMine) => {
    const media = get(msg, "media", []) || [];
    const imageMedis = media.filter((item) => item.type === "image");
    const files = media.filter(
      (item) => item.type !== "image" && item.type !== "audio"
    );
    const audios = media.filter((item) => item.type === "audio");

    if (imageMedis.length > 0) {
      return (
        <ImageList cols={1}>
          {imageMedis.map((item) => (
            <ImageListItem style={{ maxHeight: 120 }} key={item.url}>
              <img
                src={item.url}
                srcSet={item.url}
                style={{ maxHeight: 120, width: "auto", borderRadius: 15 }}
                alt="media"
                loading="lazy"
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = item.url;
                  link.target = "_blank";
                  link.setAttribute("download", true);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      );
    }

    if (files.length > 0) {
      return files.map((f) => (
        <MediaItems key={get(f, "url")} mine={isMine}>
          <MediaLink href={get(f, "url")} download target="_blank">
            <FileIconArea>
              {getIconFile(FILE_TYPE[get(f, "mediaType")], isMine)}
            </FileIconArea>
            <FileNameArea
              style={{ color: isMine ? "#fff" : "gray", fontSize: 14 }}
            >
              {f.name || "File"}
            </FileNameArea>
            <FileDownoladArea>
              <CloudDownload
                style={{ color: isMine ? "#fff" : "gray", fontSize: 20 }}
              />
            </FileDownoladArea>
          </MediaLink>
        </MediaItems>
      ));
    }

    if (audios.length > 0) {
      return audios.map((au) => (
        <audio
          key={au.url}
          controls
          controlsList="nodownload"
          src={au.url}
          style={{ height: "40px" }}
        />
      ));
    }

    return null;
  };

  const renderMessageRow = (msg, isMine, showSeenSymbol) => (
    <MessageRow
      key={msg.id}
      className="imessage"
      style={{ justifyContent: isMine ? "flex-end" : "flex-start" }}
    >
      <MessageContainer className={isMine ? "from-me" : "from-them"}>
        {renderMedia(msg, isMine)}
        {renderTextMessage(msg)}
        <TimeDisplay
          style={
            isMine
              ? { textAlign: "right", alignSelf: "flex-end" }
              : { textAlign: "left", alignSelf: "flex-start" }
          }
        >
          {isBordcast && isMine && (
            <IconButton
              onClick={() => setMessageSeenData(msg)}
              style={{ padding: 0 }}
            >
              <InfoRounded style={{ color: COLOR.white, fontSize: 16 }} />
            </IconButton>
          )}
          <CheckMark
            seen={get(msg, "seen", false)}
            deliverAt={get(msg, "deliverAt")}
            me={isMine}
            showSeenSymbol={showSeenSymbol}
            quee={get(msg, "quee", false)}
            isBrodcast={isBordcast}
            broadcastReceivers={filter(
              broadcastReceivers,
              (k) => k.id != get(currentUser, "id")
            )}
            brodcastSeen={filter(
              get(msg, "brodcastSeen"),
              (k) => k.id != get(currentUser, "id")
            )}
            brodcastDelivered={filter(
              get(msg, "brodcastDelivered"),
              (k) => k.id != get(currentUser, "id")
            )}
            white
          />
          {getTimeMessageTime(msg.createdAt)}
        </TimeDisplay>
      </MessageContainer>
    </MessageRow>
  );
  const messageRender = useCallback(() => {
    const showSeenSymbol = get(selectedUser, "config.messageSeen", true);
    return message.map((msg) => {
      const isMine = msg.senderId == currentUser.id;
      return renderMessageRow(msg, isMine, showSeenSymbol);
    });
  }, [message, selectedUser]);

  useEffect(() => {
    onCloseScheduler();
  }, [selectedUser]);
  const onImagePick = async (event) => {
    try {
      const file = event.target.files[0];
      setFileUploaderLoader({ loading: true, per: 1, type: "image" });
      UploadToStore(file, "image");
    } catch (error) {}
  };
  const onFilePicker = async (event) => {
    try {
      const file = event.target.files[0];
      setFileUploaderLoader({ loading: true, per: 1, type: "media" });
      UploadToStore(file, "media");
    } catch (e) {}
  };
  const UploadToStore = async (file, type) => {
    try {
      if (file.size < MAX_FILE_SIZE) {
        const location = "/chat/messages";
        setMediaFile(null);
        const id = `${moment().format("YYYY-MM-DD")}-${v4()}`;
        const storageRef = fireRef(storage, `${location}/${id}${file.name}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const per = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setFileUploaderLoader({ loading: true, per: per, type: type });
          },
          (error) => {
            setFileUploaderLoader({ loading: false, per: 0, type: "" });
            console.error("Upload error:", error);
            setAlertView({
              open: true,
              message: "Error while uploading file.",
            });
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                const isImage = file.type.includes("image");
                const fileObj = {
                  type: isImage ? "image" : "file",
                  url: downloadURL,
                  mediaType: file.type,
                  name: file.name,
                };
                setMediaFile([fileObj]);
                setFileUploaderLoader({ loading: false, per: 0, type: "" });
              })
              .catch((error) => {
                console.error("Error getting download URL:", error);
                setFileUploaderLoader({ loading: false, per: 0, type: "" });
              });
          }
        );
      } else {
        setFileUploaderLoader({ loading: false, per: 0, type: "" });
        setAlertView({
          open: true,
          message: "File must be less then equal to 25mb.",
        });
      }
    } catch (error) {
      setFileUploaderLoader({ loading: false, per: 0, type: "" });
      console.error("Error:", error);
    }
  };

  const onEmojiPic = (emojiData) => {
    let msg = messageText;
    msg += emojiData.native;
    setMessageText(msg);
  };
  const onClickScheduleOpt = (event) => {
    if (isDisableSendButton() && !isComponentVisible) return;
    setScheduleEl(event.currentTarget);
  };

  const onCloseScheduleOpt = (e) => {
    // if (editScheduledMessageval) return;
    onCloseScheduler();
  };
  const onCloseScheduler = (e, clear) => {
    setScheduleEl(null);
    if (editScheduledMessageval && clear) {
      setScheduleLoader(false);
      seteditScheduledMessageval(null);
      setMediaFile(null);
      setMessageText("");
    }
  };
  const classNav = () => {
    return (
      <Menu>
        {map(matchingArchivedItems, (cls) => {
          if (!cls) return;
          const unseen = getUnreadCountForClass(cls.id);
          const displayName = get(cls, "displayName");
          return (
            <Menu.Item style={{ padding: "0px" }} key={cls.id}>
              <PaddedBtn onClick={() => onClassSelect(cls.id)}>
                {displayName || "class"} <Badge count={unseen}></Badge>
              </PaddedBtn>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };
  return (
    <div className="messagesBox">
      {!!messageSeenData && (
        <MessageSeenModal
          showModal={!!messageSeenData}
          closeModal={() => {
            setMessageSeenData(null);
          }}
          classUsers={broadcastReceivers}
          seenUsers={get(messageSeenData, "brodcastSeen")}
          deliverdUsers={get(messageSeenData, "brodcastDelivered")}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertView.open}
        autoHideDuration={3000}
        onClose={() => {
          setAlertView({ open: false, message: "" });
        }}
      >
        <Alert
          onClose={() => {
            setAlertView({ open: false, message: "" });
          }}
          severity="error"
        >
          <strong>ALERT:</strong> {alertView.message}
        </Alert>
      </Snackbar>
      <Wrapper className="messagesBoxWrap" onClick={() => {}}>
        <Header className="messagesBoxHed">
          <RowHeader
            className="messagesBoxRw"
            style={{ justifyContent: "space-between", padding: "12px 12px" }}
          >
            <NameCol className="messagesBoxNam">
              {selectedUser ? (
                <IconButton
                  className="allUsrArow"
                  aria-label="back"
                  onClick={() => setSelectedUser(null)}
                >
                  <ArrowBack style={{ fontSize: 20, color: "#fff" }} />
                </IconButton>
              ) : null}
              {hasMoreClass ? (
                <Dropdown
                  className="selctChatDrop"
                  overlay={classNav()}
                  placement="bottomLeft"
                  toggle={["click"]}
                >
                  <ClassSelector
                    className="selctChatOptn ShowsMenuChat"
                    onClick={(e) => e.preventDefault()}
                  >
                    <MenuBook className="selctChatMnu" />{" "}
                    {get(activeClass, "displayName", "")}{" "}
                    <CaretDownOutlined className="selctChatOut" />
                  </ClassSelector>
                </Dropdown>
              ) : null}
              {selectedUserScheduledMessage &&
              selectedUserScheduledMessage.length > 0 ? (
                <ScheduledMessageButton
                  className="scheduledWeb"
                  onClick={() => {
                    setIsComponentVisible(true);
                  }}
                >
                  {" "}
                  <MobIconContainer src={MobIcon} />{" "}
                  {selectedUserScheduledMessage.length} Scheduled Message
                </ScheduledMessageButton>
              ) : null}

              <div className="masBxCollect">
                <div className="msgBoxProfile">
                  {!isEmpty(selectedUserConfig) &&
                  selectedUserConfig.avatar &&
                  getAvatar(selectedUserConfig.avatar) ? (
                    <Avatar
                      src={getAvatar(selectedUserConfig.avatar)}
                      size={40}
                      style={{ marginRight: 8 }}
                    />
                  ) : (
                    <AccountCircle
                      style={{
                        color: "rgb(255, 255, 255)",
                        fontSize: "35px",
                        marginRight: " 10px",
                      }}
                    />
                  )}
                  {!isBordcast ? (
                    <UserName>
                      {get(selectedUser, "lastName", "")},{" "}
                      {get(selectedUser, "firstName", "")}(
                      {get(selectedUser, "username", "")})
                    </UserName>
                  ) : (
                    <UserName>
                      {get(selectedUser, "displayName", "")}(Broadcast)
                    </UserName>
                  )}
                </div>
              </div>
            </NameCol>
            <LogoCol
              className="messagesBoxLog mobilemessagesBoxLog"
              style={{ width: "unset" }}
            >
              <LogoCol style={{ width: "unset" }}>
                {/* {selectedUser ? <IconButton className="allUsrArow" aria-label="back" onClick={() => setSelectedUser(null)}>
                                    <ArrowBack style={{ fontSize: 20, color: "#fff" }} />
                                </IconButton> : null} */}

                {selectedUserScheduledMessage &&
                selectedUserScheduledMessage.length > 0 ? (
                  <ScheduledMessageButton
                    className="scheduledMob"
                    onClick={() => {
                      setIsComponentVisible(true);
                    }}
                  >
                    {" "}
                    <MobIconContainer src={MobIcon} />{" "}
                    {selectedUserScheduledMessage.length} Scheduled Message
                  </ScheduledMessageButton>
                ) : null}

                {/* <Logo src={TransparentLogoWhite} className="webChatLogo" /> */}
                {/* {hasMoreClass ? (
                  <Dropdown
                    className="selctChatDrop hideMenuChat"
                    overlay={classNav()}
                    placement="bottomLeft"
                    toggle={["click"]}
                  >
                    <ClassSelector
                      className="selctChatOptn"
                      onClick={(e) => e.preventDefault()}
                    >
                      <MenuBook className="selctChatMnu" />{" "}
                      {get(activeClass, "displayName", "")}{" "}
                      <CaretDownOutlined className="selctChatOut" />
                    </ClassSelector>
                  </Dropdown>
                ) : null} */}

                {hasMoreClass ? (
                  <Dropdown
                    className="selctChatDrop hideMenuChat"
                    overlay={
                      <div className="scrollable-dropdown">{classNav()}</div>
                    }
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <ClassSelector
                      className="selctChatOptn"
                      onClick={(e) => e.preventDefault()}
                    >
                      <MenuBook className="selctChatMnu" />{" "}
                      {get(activeClass, "displayName", "")}{" "}
                      <CaretDownOutlined className="selctChatOut" />
                    </ClassSelector>
                  </Dropdown>
                ) : null}

                <Settings setAlertView={setAlertView} socket={socket} />
              </LogoCol>
            </LogoCol>
          </RowHeader>
          {isComponentVisible && selectedUserScheduledMessage.length > 0 ? (
            <MessageInQueeScheduled
              ref={ref}
              style={{
                bottom: `-${
                  selectedUserScheduledMessage.length > 4
                    ? 200
                    : selectedUserScheduledMessage.length * 50
                }px`,
              }}
            >
              {map(selectedUserScheduledMessage, (sm) => (
                <ScheduleContainer key={sm.id}>
                  <Row style={{ width: "100%" }}>
                    <Col span={22}>
                      <MessageInnerRow>
                        {" "}
                        {sm ? (
                          get(sm.message, "type") == "media" ? (
                            <>
                              {" "}
                              {<AttachFile style={{ fontSize: 10 }} />}{" "}
                              Attachment {get(sm.message, "message", "")}{" "}
                            </>
                          ) : (
                            get(sm.message, "message", "")
                          )
                        ) : (
                          ""
                        )}{" "}
                      </MessageInnerRow>
                      <TimeDisplayScheduled>
                        {moment(sm.scheduledAt).format("MMM DD , hh:mm a")}
                      </TimeDisplayScheduled>
                    </Col>
                    <Col
                      span={2}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <TrashButtonContainer>
                        <MobIconContainer
                          onClick={() => {
                            seteditScheduledMessageval(sm);
                            const mediaf = get(sm, "message.media");
                            const messageT = get(sm, "message.message");
                            setMediaFile(mediaf);
                            setMessageText(messageT);
                            if (schedulerRef.current) {
                              schedulerRef.current.click();
                            }
                          }}
                          src={WriteEdit}
                        />
                      </TrashButtonContainer>
                      <TrashButtonContainer
                        onClick={() => {
                          deleteScheduledMessage(sm);
                        }}
                      >
                        {deleteSchudelLoader === sm.id ? (
                          <Loading size={20} />
                        ) : (
                          <MobIconContainer src={TrashSvg} />
                        )}
                      </TrashButtonContainer>
                    </Col>
                  </Row>
                </ScheduleContainer>
              ))}
            </MessageInQueeScheduled>
          ) : null}
        </Header>
        <MessageArea className="msgSndTypoArea" ref={messageBoxRef}>
          {message && message.length > 0 ? (
            messageRender()
          ) : (
            <MessageRocketOnNoMessage>
              {/* <NoMessageImg src={MessageRocket} /> */}
              <div className="noChatArea">
                <Lottie animationData={ELEPHANT.poster} />
              </div>
              <MessageForNoMessage>
                {get(currentUser, "role") == "teacher"
                  ? `Use this section to message your students individually or as a class. Happy OneWayMessaging!`
                  : "Use this section to message with your teacher or receive class brodcast. Happy OneWayMessaging!"}
              </MessageForNoMessage>
            </MessageRocketOnNoMessage>
          )}
        </MessageArea>
        <MessageBox
          className="msgSndTypoBx"
          style={{ bottom: deviceType ? 56 : 0, padding: 10 }}
        >
          {mediaFile && Array.isArray(mediaFile) ? (
            <MessageInQuee>
              <Row
                style={{
                  width: "100%",
                  flexDirection: "row",
                  color: "#fff",
                  alignItems: "center",
                }}
              >
                {map(mediaFile, (f) => {
                  if (f.type == "image") {
                    return (
                      <>
                        <img
                          key={f.url}
                          src={f.url}
                          srcSet={f.url}
                          style={{ maxHeight: 50, width: "auto" }}
                          alt={"media"}
                          loading="lazy"
                        />
                        <div style={{ marginLeft: 10 }}>
                          {" "}
                          {get(f, "name", "File")}
                        </div>
                      </>
                    );
                  } else if (f.type == "audio") {
                    return (
                      <audio
                        key={f.url}
                        controls
                        controlsList="nodownload"
                        src={f.url}
                        style={{ height: "40px" }}
                      ></audio>
                    );
                  } else {
                    return (
                      <div
                        key={f.url}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {getIconFile(
                            FILE_TYPE[get(f, "mediaType")],
                            true,
                            40
                          )}
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          {" "}
                          {get(f, "name", "File")}
                        </div>
                      </div>
                    );
                  }
                })}
                {map(mediaFile, (f) => (
                  <FileNameArea
                    key={f.name ? f.name : "File"}
                    style={{ color: "#fff", fontSize: 14 }}
                  ></FileNameArea>
                ))}
                {scheduleLoader ? <Loading size={20} white /> : null}
                <CloseReapper onClick={() => setMediaFile(null)}>
                  {" "}
                  <Close style={{ fontSize: 15, color: "#fff" }} />
                </CloseReapper>
              </Row>
            </MessageInQuee>
          ) : null}
          {scheduleLoader && (
            <ProgressQuee className="msgSndProg">
              <LinearProgress
                className="msgSndLine"
                style={{ width: "100%", color: COLOR.green100 }}
              />
            </ProgressQuee>
          )}
          <MessageRestric
            isBordcast={isBordcast}
            isStudent={get(currentUser, "role") == "student"}
            hasChatHour={hasChatHour}
            userChatHourConf={userChatHourConf}
            chatDisabled={chatDisabled}
          >
            <Row
              className="msgSndRw"
              style={{ borderRadius: 40, backgroundColor: "rgb(145 145 145)" }}
            >
              {/* <Col className="fileSection" xs={24} md={3}> */}
              {/* {fileUploaderLoader.loading && fileUploaderLoader.type == "media" ? <Progress type="circle" percent={fileUploaderLoader.per} size={"small"} width={50} className={"fileProgress"} /> : <EmojiBox> <AttachFile className={cssClasses.attachIcon} onClick={() => {
                                    fileRef.current.click()
                                }} />
                                    <FileUploader ref={fileRef} type="file"
                                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf , application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                        onChange={onFilePicker} />
                                </EmojiBox>} */}
              {/* {fileUploaderLoader.loading && fileUploaderLoader.type == "image" ? <Progress type="circle" percent={fileUploaderLoader.per} size={"small"} width={50} className={"fileProgress"} /> : <EmojiBox
                                    onClick={() => {
                                        imgRef.current.click()
                                    }}>
                                    <Image className={cssClasses.attachIcon} />
                                    <FileUploader ref={imgRef} type="file" accept='image/*' onChange={onImagePick} />
                                </EmojiBox>}
                                {!isStudent && <EmojiBox>
                                    <EmojiSelector onEmojiClick={onEmojiPic} />
                                </EmojiBox>}
                                <AudioRecorder onRecorderDone={url=>{
                                    const fileObj = {
                                        type: 'audio',
                                        url: url,
                                        mediaType: 'audio/mp3',
                                        name: 'record.mp3'
                                    }
                                    setMediaFile([fileObj])
                                }} /> */}
              {/* </Col> */}
              <Col
                className="msgSection"
                xs={18}
                md={21}
                style={{ position: "relative" }}
              >
                <textarea
                  style={{ paddingLeft: 37, paddingRight: 75 }}
                  rows="1"
                  placeholder="message.."
                  onChange={(e) => setMessageText(e.target.value.trimStart())}
                  value={messageText}
                  onKeyDown={(e) => {
                    if (e.key == 13 || e.key == "Enter") {
                      if (
                        !mediaFile &&
                        messageText.replace(/\s/g, "").length == 0
                      ) {
                        return;
                      }
                      sendMessage();
                    }
                  }}
                ></textarea>
                {fileUploaderLoader.loading &&
                fileUploaderLoader.type == "media" ? (
                  <Progress
                    type="circle"
                    percent={fileUploaderLoader.per}
                    size={"small"}
                    width={20}
                    style={{ position: "absolute", right: 30, marginRight: 10 }}
                    className={"fileProgress"}
                  />
                ) : (
                  <EmojiBox style={{ position: "absolute", right: 30 }}>
                    {" "}
                    <AttachFile
                      style={{ color: "rgb(67, 150, 156)" }}
                      className={cssClasses.attachIcon}
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    />
                    <FileUploader
                      ref={fileRef}
                      type="file"
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf , application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                      onChange={onFilePicker}
                    />
                  </EmojiBox>
                )}
                {/* {fileUploaderLoader.loading && fileUploaderLoader.type == "image" ? <Progress type="circle" percent={fileUploaderLoader.per} size={"small"} width={50} className={"fileProgress"} /> : <EmojiBox style={{ position: "absolute", right: 55 }} */}
                {fileUploaderLoader.loading &&
                fileUploaderLoader.type == "image" ? (
                  <Progress
                    type="circle"
                    percent={fileUploaderLoader.per}
                    size={"small"}
                    width={20}
                    className={"fileProgress"}
                    style={{ position: "absolute", right: 55, marginRight: 10 }}
                  />
                ) : (
                  <EmojiBox
                    style={{ position: "absolute", right: 55 }}
                    onClick={() => {
                      imgRef.current.click();
                    }}
                  >
                    <Image
                      style={{ color: "rgb(67, 150, 156)" }}
                      className={cssClasses.attachIcon}
                    />
                    <FileUploader
                      ref={imgRef}
                      type="file"
                      accept="image/*"
                      onChange={onImagePick}
                    />
                  </EmojiBox>
                )}
                {/* {!isStudent &&  */}
                <EmojiBox style={{ position: "absolute", left: 5 }}>
                  <EmojiSelector onEmojiClick={onEmojiPic} />
                </EmojiBox>
                {/* } */}
                <div style={{ position: "absolute", right: 10 }}>
                  <AudioRecorder
                    onRecorderDone={(url) => {
                      const fileObj = {
                        type: "audio",
                        url: url,
                        mediaType: "audio/mp3",
                        name: "record.mp3",
                      };
                      setMediaFile([fileObj]);
                    }}
                  />
                </div>
              </Col>
              <Col className="msgSendSection" xs={6} md={3}>
                <SendButton className="msgSndBtn">
                  {!editScheduledMessageval && (
                    <SendArea
                      isStudent={isStudent}
                      aria-disabled={isDisableSendButton()}
                      disabled={isDisableSendButton()}
                      onClick={sendMessage}
                    >
                      <SendIcon />
                    </SendArea>
                  )}
                  {!isStudent ? (
                    <SendOptionArea
                      style={{
                        width: editScheduledMessageval ? "100%" : "30%",
                        color: "#fff",
                      }}
                      aria-disabled={isDisableSendButton()}
                      disabled={isDisableSendButton()}
                      aria-describedby={schedulePopOverid}
                      ref={schedulerRef}
                      onClick={onClickScheduleOpt}
                    >
                      <CaretDownOutlined className="msgSndCare" />
                      {editScheduledMessageval ? "Reschedule" : null}
                    </SendOptionArea>
                  ) : null}
                </SendButton>
                <Popover
                  id={schedulePopOverid}
                  open={schedulePopOveropen}
                  anchorEl={scheduleEl}
                  // hideBackdrop={false}
                  onClose={onCloseScheduleOpt}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  elevation={0}
                  className={"chatSettingPopOver"}
                >
                  <Scheduler
                    onClose={(e) => onCloseScheduler(e, true)}
                    onCreate={createScheduledMessage}
                    setAlertView={setAlertView}
                    value={get(editScheduledMessageval, "scheduledAt")}
                  />
                </Popover>
              </Col>
            </Row>
          </MessageRestric>
        </MessageBox>
      </Wrapper>
    </div>
  );
};

//  -- Prop types ---------------------
Messages.propTypes = {};

//  -- Default props ------------------
Messages.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps, null)(Messages);
