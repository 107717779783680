//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";
import ReactPlayer from "react-player";

//  -- Components ---------------------
import { Col, Row } from "antd";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
//   background-color: ${COLOR.green800};
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
   padding: ${p=> p.page !== "balcon" ? "12px 12px 8px 12px" : ""};
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 1200px){
    padding: ${p=> p.page !== "balcon" && "12px 53px 8px 54px" };
  }
  @media only screen and (max-width: 450px){
    padding: 12px 19px 8px 20px;
  }
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Interaction
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const Interaction = ({ data,page }) => {
  return (
    <Wrapper page={page}>
      <Row>
        <Col span={24}>
          {data.typeId === "audio" && (
            <ReactPlayer
              url={data.content}
              height="40px"
              className="custom-player"
              width="100%"
              controls
              playsinline
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
            />
          )}
          {data.typeId === "video" && (
            <ReactPlayer
              url={data.content}
              height="100%"
              width="100%"
              controls
              playsinline
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
            />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};

//  -- Prop types ---------------------
Interaction.propTypes = {
  data: shape({}),
};

//  -- Default props ------------------
Interaction.defaultProps = {};

export default Interaction;
