// import * as firebase from 'firebase/app'
// import 'firebase/storage'
// import config from './config';

// firebase.initializeApp({
//   apiKey: config.REACT_APP_API_KEY,
//   projectId: config.REACT_APP_PROJECT_ID,
//   storageBucket: config.REACT_APP_STORAGE_BUCKET,
//   appId: config.REACT_APP_APP_ID,
// })

// export const firebaseApp = firebase



// import * as firebase from 'firebase/app'
// import 'firebase/storage'

// firebase.initializeApp({
//   apiKey: process.env.REACT_APP_API_KEY,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   appId: process.env.REACT_APP_APP_ID,
// })

// export const firebaseApp = firebase
// // Firebase storage reference


import * as firebase from 'firebase/app'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_apiKey,
  authDomain: process.env.REACT_APP_FIRESTORE_authDomain,
  projectId: process.env.REACT_APP_FIRESTORE_projectId,
  storageBucket: process.env.REACT_APP_FIRESTORE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIRESTORE_messagingSenderId,
  appId: process.env.REACT_APP_FIRESTORE_appId,
  measurementId: process.env.REACT_APP_FIRESTORE_measurementId,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  databaseURL: process.env.REACT_APP_FIRESTORE_databaseURL
};
const app = firebase.initializeApp(firebaseConfig);
// Initialize Firebase
// const app = firebase.initializeApp({
//     apiKey: process.env.REACT_APP_API_KEY,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     appId: process.env.REACT_APP_APP_ID,
// });

// Firebase storage reference
export const storage = getStorage(app);
export const firebaseApp = app;
