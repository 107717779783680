//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------

//  -- List logs ----------------
export const setActiveClassStudentLog = (returnedPayload) => ({ type: ActionTypes.logs.set_active_class_logs , payload: returnedPayload });
export const clearActiveClassStudentLog = () => ({ type: ActionTypes.logs.clear_active_class_log });



export const setMessageAlertView = (returnedPayload) => ({ type: ActionTypes.alert.invokeMessageAlert, payload: returnedPayload });
export const revokeMessageAlerView = () => ({ type: ActionTypes.alert.revokeMessageAlert });