//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

//  -- Middleware ---------------------
import middleware from 'store/_middleware';

//  -- Reducers -----------------------
import rootReducer from 'store/reducer';

//  -- Config -------------------------
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'activity',
        'classes',
        'classActivities',
        'classQuizzes',
        'classUsers',
        'currentClass',
        'currentOrg',
        'interactions',
        'likes',
        'notes',
        'organizations',
        'posts',
        'quiz',
        'questions',
        'submissions',
        'userClasses',
        'users',
        'profile_config',
        'profile_picture_picker',
        'tipsByBook',
        'books',
        'bookUnitList',
        'multiclassPopupForm',
        'alertView',
        'messageAlertView',
        'booksAllList',
        'chatUser',
        'chatMessages',
        "timmerRunning",
        // 'booksAllList',
        'hyperlikModalInfo',
        'servertime',
       
        'feedbackForm',
        'feedback',
        'socketConnection',
        'userChatConfig',
        'scheduledMessage',
        'schoologyGradeCategory',
        'schoologyAllClassGradeCategory',
        'schoologyAssignment',
        'wheelModal',
        'activities',
        'userBooksList',
        'quizzes',
        'literacy_purpose',
        'ideas',
        'ideaModal',
        'debateModal',
        'oneway_teacher_video',
        'one_game_video',
        'howToVideos',
        'standards',
        'class_game_activity',
        'distDashboardInfo',
        'distExamGraphData',
        'distBookUsesData',
        'distContentExamData',
        'class_user_logs',
        'mainbookPageView',
        'ebookGalleryView',
        'checkListModal',
        'noiseModal',
        'trafficModal',
        'sentenceView',
        'ebook_highlights'
    ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


//  ----------------------------------------------------------------------------
//  Store function
//  ----------------------------------------------------------------------------

const configureStore = () => {
    const store = createStore(persistedReducer, undefined, middleware);
    return store;
};

export default configureStore;
