//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create vocabulary ----------------
// export const createVocabulary = () => ({ type: ActionTypes.vocabulary.createVocabulary });
// export const createVocabularyFail = (returnedPayload) => ({ type: ActionTypes.vocabulary.createVocabularyFail, payload: returnedPayload });
export const createDifferentionLesson = (returnedPayload) => ({ type: ActionTypes.differentiations.createDifferentionLesson, payload: returnedPayload });
export const updateDifferentionLesson = (returnedPayload) => ({ type: ActionTypes.differentiations.updateDifferentionLesson, payload: returnedPayload });

//  -- List vocabulary ----------------
// export const listVocabulary = () => ({ type: ActionTypes.vocabulary.listVocabulary });
// export const listVocabularyFail = (returnedPayload) => ({ type: ActionTypes.vocabulary.listVocabularyFail, payload: returnedPayload });
export const listDifferentionLesson = (returnedPayload) => ({ type: ActionTypes.differentiations.listDifferentionLesson, payload: returnedPayload });

// Delete vocabulary
// export const deleteVocabulary = () => ({ type: ActionTypes.vocabulary.deleteVocabulary });
// export const deleteVocabularySuccess = (returnedPayload) => ({ type: ActionTypes.vocabulary.deleteVocabularySuccess, payload: returnedPayload });
export const deleteDifferentionLesson = (returnedPayload) => ({ type: ActionTypes.differentiations.deleteDifferentionLesson, payload: returnedPayload });