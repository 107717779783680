export const debate_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fdebate_icon.png?alt=media&token=ad095c38-bd0b-4830-aabb-48e18cd55d6c`
export const idea_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fidea_icon.png?alt=media&token=d171f32a-cde6-44db-8aa1-ece77ff6dbff`
export const wheel_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fwheel_icon.png?alt=media&token=4bced24e-b3d0-4b57-a3c6-b3c341e80623`
export const chat_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fchat_icon.png?alt=media&token=84117edc-4dcf-4817-975b-63f61e4b0ef6`
export const activity_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Factivity_icon.png?alt=media&token=84117edc-4dcf-4817-975b-63f61e4b0ef6`
export const chat_menu_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fchat_menu_icon.png?alt=media&token=84117edc-4dcf-4817-975b-63f61e4b0ef6`
export const notification_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fnotification_icon.png?alt=media&token=84117edc-4dcf-4817-975b-63f61e4b0ef6`
export const notes_icon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Ficons%2Fnotes_icon.png?alt=media&token=84117edc-4dcf-4817-975b-63f61e4b0ef6`
export const docIcons = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/docIconss.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const settingIcons = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/settingIconss.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const previewIcons = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/previewIcons.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const editIcons = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/editIconss.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const pencileIcons = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/pencileIcons.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const homeBtnMenu = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/homeBtnMenu.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const bellBtnmenu = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/bellBtnmenu.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const messageBtnmenu = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/messageBtnMenu.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const gradeBtnmenu = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/gradeBtnMenu.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const roboBtnmenu = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/roboBtnMenu.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const booksBtnMenu = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/booksBtnMenu.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`
export const twoSidePageIcon = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/two_side_page.svg?alt=media&token=b0871bf7-069c-4906-9884-68f981cf7ef2`