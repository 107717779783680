//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const feedbackform = {
    isLoading: false,
};
const feedbackInitial = {
    feedback: {},
    isLoading: false,
};

export const feedbackForm = (state = feedbackform, action) => {
    switch (action.type) {
        case ActionTypes.feedback.invokeFeedback:
            return ({ ...state, isLoading: true });
        case ActionTypes.feedback.revokeFeedback:
            return ({ ...state, isLoading: false });
        default:
            return state;
    }
};

export const feedback = (state = feedbackInitial, action) => {
    switch (action.type) {
        case ActionTypes.feedback.createFeedback:
            return ({ ...state, feedback: {}, isLoading: true });
        case ActionTypes.feedback.createFeedbackSuccess:
            return ({ ...state, feedback: action.payload , isLoading: false });
        default:
            return state;
    }
};
