//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const tenantInit = [];

export const tenant = (state = tenantInit, action) => {
    switch (action.type) {
		case ActionTypes.classlink.setTenantSuccess:
            return action.payload;
		case ActionTypes.classlink.setTenantFail:
            return state;
        default:
            return state;
    }
};
