import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton, Button, TextField, Typography } from '@material-ui/core';
import { PauseCircleFilled, Replay , PlayCircleFilled } from '@material-ui/icons';
import styled from 'styled-components';
import { Progress } from 'antd';

const TimerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const CircularWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

const TimeDisplay = styled(Typography)`
    width: 80%;
    border-bottom: 2px solid;
    text-align: center;
    border-bottom: 4px solid transparent;
    border-image: linear-gradient(0.96turn, rgba(121,47,252), rgba(121,47,252), rgba(121,47,252));
    border-image-slice: 1;
    font-weight: 400;
    font-size:2.75rem !important;
`;

const AddMinuteButton = styled.button`
    transform: translate(50%,-50%);
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    top: 16%;
    left: 25%;
    border-radius: 15px;
    background-color: rgba(236,240,242);
    padding: 5px 20px;
    border: 0px;
    cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    width: 150px;
    position: absolute;
    bottom: 14%;
`;

const TimeInput = styled(TextField)`
  width: 80px;
  margin: 0 5px;
  && .MuiInput-underline:before {
    display: none; // Remove bottom border
  }
  && .MuiInput-underline:hover:not(.Mui-disabled):before {
    display: none; // Remove bottom border on hover
  }
  && .MuiInput-underline:after {
    display: none; // Remove bottom border on focus
  }
`;
const TimmerInner = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;   
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;
const TimeCloneMark = styled.span`
    font-weight:bold;
`;
const GradientCircularProgress = ({ percent, thickness }) => (
    <Progress
        type="circle"
        percent={percent}
        width={400}
        showInfo={false}
        trailColor={"rgba(236,240,242)"}
        strokeWidth={thickness}
        strokeColor={{
            '0%': 'rgba(121,47,252)',
            '50%': 'rgba(74,144,201)',
            '100%': 'rgba(89,190,210)',
        }}
    />
);

const formatTime = (time) => {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60) % 60;
    let hours = Math.floor(time / 3600);

    return [hours, minutes, seconds]
        .map((t) => (t < 10 ? `0${t}` : t))
        .join(' : ');
};

function TimerWheel() {
    const [time, setTime] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [inputValues, setInputValues] = useState({
        hours: '',
        minutes: '',
        seconds: '',
    });
    const [initailTimer, setInitialSubmissionTimer] = useState(0)
    const [editNabled,setEditEnabled] = useState(false);
    const audio = new Audio(require("utils/assets/audio/timer_end.mp3"));
    audio.loop = true
    audio.volume = 1

    useEffect(() => {
        return () => {
            stopPlayer()
        }
    }, [])
    const stopPlayer = () => {
        audio.pause()
        audio.currentTime = 0;
    }
    useEffect(() => {
        let interval = null;
        if (isActive && time > 0) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
                if(time <= 5 ){
                    audio.play(); 
                }
            }, 1000);
        } else if (time === 0) {
            setIsActive(false);
            // stopPlayer()
        }
        return () => {
            clearInterval(interval);
            setTimeout(stopPlayer,1000)
            // stopPlayer()
        };
    }, [isActive, time, audio]);

    const handleChange = (name) => (event) => {
        setInputValues({ ...inputValues, [name]: event.target.value });
    };

    const handleStart = () => {
        setIsActive(true);
        setEditEnabled(false);
        const totalTime = parseInt(inputValues.hours || 0) * 3600 +
            parseInt(inputValues.minutes || 0) * 60 +
            parseInt(inputValues.seconds || 0);
        setInitialSubmissionTimer(totalTime);
        setTime(totalTime);
    };

    const handlePause = () => {
        setIsActive(!isActive);
    };

    const handleReset = () => {
        setIsActive(false);
        setTime(0);
        setInitialSubmissionTimer(0);
        setInputValues({
            hours: '',
            minutes: '',
            seconds: '',
        });
    };

    const handleAddOneMinute = () => {
        setInitialSubmissionTimer((prevTime) => prevTime ? prevTime + 60 : 60)
        setTime((prevTime) => prevTime ? prevTime + 60 : 60);
    };
    return (
        <TimerContainer className='timeWhelContainer'>
            <CircularWrapper className='timeWhelWrap'>
                <GradientCircularProgress variant="static" percent={100-(time / initailTimer * 100)} thickness={3} />
                <TimmerInner className='timeWhelTimer'>
                    <AddMinuteButton size="small" onClick={handleAddOneMinute}>
                        +1 min
                    </AddMinuteButton>
                    {editNabled?  <TimeDisplay style={{paddingBottom:4}}>
                <TimeInput
                    type="number"
                    value={inputValues.hours}
                    onTouchStart={(e) => e.stopPropagation()}
                    placeholder='HH'
                    onChange={handleChange('hours')}
                    inputProps={{ style: { textAlign: "center" } }}
                /> <TimeCloneMark>:</TimeCloneMark>
                <TimeInput
                    type="number"
                    value={inputValues.minutes}
                    placeholder='MM'
                    onChange={handleChange('minutes')}
                    inputProps={{ style: { textAlign: "center" } }}
                /> <TimeCloneMark>:</TimeCloneMark>
                <TimeInput
                    type="number"
                    value={inputValues.seconds}
                    autoFocus
                    placeholder='SS'
                    inputProps={{ style: { textAlign: "center" } }}
                    onChange={handleChange('seconds')}
                />
                <Button onClick={handleStart} variant="contained" color="primary" disabled={isActive}>
                    Start
                </Button>
            </TimeDisplay>: <TimeDisplay onClick={()=>{
                setIsActive(false);
                setEditEnabled(true);
            }}  variant="h2">{formatTime(time)}</TimeDisplay>}
                   
                    <Controls>
                        <IconButton onClick={handlePause} size="medium"  style={{backgroundColor:"rgba(121,47,252)",height:60,width:60}}>
                            {isActive ? <PauseCircleFilled style={{ color: "#fff" }} /> : <PlayCircleFilled style={{ color: "#fff" }} />}
                        </IconButton>
                        <IconButton onClick={handleReset} size="medium"  style={{backgroundColor:"rgba(236,240,242)",height:60,width:60}}>
                            <Replay />
                        </IconButton>
                    </Controls>
                </TimmerInner>
            </CircularWrapper>
        </TimerContainer>
    );
}

export default TimerWheel;
