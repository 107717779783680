//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const questionsInitial = [];

export const questions = (state = questionsInitial, action) => {
    switch (action.type) {
        case ActionTypes.questions.createQuestionSuccess:
            return [action.payload, ...state];
        case ActionTypes.questions.deleteQuestionSuccess:
            return state.filter(x => x.id !== action.payload.id);
        case ActionTypes.questions.updateQuestionSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload.data };
                }
                return x;
            });
        case ActionTypes.questions.listQuestionsSuccess:
        case ActionTypes.questions.listChapterQuestionsSuccess:
        case ActionTypes.questions.listClassQuestionsSuccess:
        case ActionTypes.questions.listOrgQuestionsSuccess:
        case ActionTypes.questions.listUserQuestionsSuccess:
            return action.payload;
        case ActionTypes.questions.createQuestionFail:
        case ActionTypes.questions.deleteQuestionFail:
        case ActionTypes.questions.updateQuestionFail:
        case ActionTypes.questions.listQuestionsFail:
        case ActionTypes.questions.listChapterQuestionsFail:
        case ActionTypes.questions.listClassQuestionsFail:
        case ActionTypes.questions.listOrgQuestionsFail:
        case ActionTypes.questions.listUserQuestionsFail:
            return state;
        default:
            return state;
    }
};

export const classQuestions = (state = questionsInitial, action) => {
    switch (action.type) {
        case ActionTypes.questions.updateQuestionSuccess:
            return state.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload.data };
                }
                return x;
            });
        case ActionTypes.questions.listClassQuestionsSuccess:
            return action.payload;
        case ActionTypes.questions.updateQuestionFail:
        case ActionTypes.questions.listClassQuestionsFail:
            return state;
        default:
            return state;
    }
};
