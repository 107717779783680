//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { findIndex, get, isEqual, map, find } from "lodash";
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
//  -- Constants ----------------------
import { UserOutlined } from '@ant-design/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Row, Tooltip, Col } from 'antd';
import MessageRenderer from 'components/MessageRenderer';
import { lighten } from 'polished';
import { useCurrentUser } from 'store/users/selectors';
import { AIfeebackImage, Pinpoint, AiFeedbackImage } from 'utils/assets/images';
import { getAvatar } from 'utils/assets/images/lootie';
import { useActivities } from 'store/activities/selectors';
import { useAllBook } from 'store/books/selectors';
import { Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import COLOR from 'utils/constants/color';
import ReaderSpanish from './ReaderSpanish';
import { useMediaQuery } from '@material-ui/core';
const alphaRef = ['A', 'B', 'C', 'D'];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Answer = styled.span`
    color: ${p => p.correct ? COLOR.green100 : 'red'};
    display: block;
    font-size: 14px;
    font-weight: 600;
    text-decoration: ${p => !p.correct ? 'line-through' : 'none'};
`;

const Button = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black300};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;

const ExpandedContainer = styled.div`
    height: ${p => p.expanded ? 'auto' : '0px'};
    margin-top: ${p => p.expanded && '0px'};
    overflow: hidden;
    padding: ${p => p.expanded && '8px'};
    width: 100%;
`;

const Name = styled.span`
    display: inline-block;
    max-width: 90%;
    color:${p => p.isLate ? COLOR.radish100 : COLOR.black}
`;

const Question = styled.span`
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    align-items: center;
    display: flex;
`;

const Section = styled.div`
    height: auto;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &:last-child {
        margin-bottom: 0px;
    }
`;
const FeedbackSection = styled.div`
    height: auto;
    padding-bottom: 24px;
    width: 100%;
    background-color: rgb(121 120 199);
    padding-left: 24px;
    border-radius: 10px;
`;
const SectionHeader = styled.span`
    border-bottom: 1px dashed ${COLOR.black800};
    color: ${COLOR.green100};
    display: block;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 8px;
    padding-bottom: 4px;
    text-transform: uppercase;
`;
const FeedbackText = styled.span`
    color: ${COLOR.white};
    display: block;
    font-size: 14px;
    margin: 12px;
    `;

const Subsection = styled.div`
    margin-bottom: 16px;
    width: 100%;

    &:last-child {
        margin-bottom: 0px;
    }
`;

const Text = styled.span`
    color: ${COLOR.green100};
    display: inline-block;
`;

const Wrapper = styled.div`
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    margin-bottom: 8px;
    width: 100%;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const Notification = styled.div`
    background-color: ${p => p.bgColor ? p.bgColor : lighten(0.45, '#3D5F8F')};
    border: 1px dashed ${p => p.bgColor ? p.color : '#3D5F8F'};
    border-radius: 3px;
    color: ${p => p.color ? p.color : '#3D5F8F'};
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 24px;
    padding: 8px;
`;
const AIContent = styled.div`
    text-align: left;
    width: 100%;
    border-radius: 20px;
    // padding-right: 20px;
    // padding-top: 20px;
    // padding-bottom: 10px;
    // padding-left: 30px;
    padding:25px 25px 20px 25px;
    background: rgb(241,235,251);
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
`;
const ErrorAiArea = styled.div`
   background:rgb(82 50 128);
   border-radius:10px;
   position: relative;
   color: ${COLOR.white};
   width: 230px;
   padding-left: 10px;
   height: 26px;
   padding-top: 1px;
   margin-top: 25px;
`;
const AllAiSection = styled.div`
    display:flex;
    align-items: center;
`

const AIIcon = styled.img`
    position: absolute;
    height: 50px;
    width: 45px;
    top: -20px;
    right: -15px;
`;
const AIIcons = styled.img`
    position: absolute;
    height: 45px;
    width:38px;
    top: -18px;
    right: -12px;
`;
const ErrorAiAreas = styled.div`
   background:#38b54b;
   border-radius:10px;
   position: relative;
   color: ${COLOR.white};
   width: 230px;
   padding-left: 10px;
   height: 26px;
   padding-top: 1px;
   margin-top: 25px;
   margin-right:2px;
`;

const QImage = styled.img`
    aspect-ratio: 3 / 2;
    max-width:322px;
    border-radius:15px;
`;
const ImageQuestionArea = styled.div`
  display : flex;
  flex-direction: column;
  col-gap:10px;
  row-gap:10px;
`;
const ImageAnswerArea = styled.div`
  display : flex;
  flex-direction: column;
  col-gap:10px;
  row-gap:10px;
  border: 1px dashed black;
  margin-top: 15px;
  padding: 10px;
  position: relative;
}
`;

const AnswerMarkLagendaArea = styled.div`
  position : absolute;
  left : 10px;
  background-color:white;
  top: -12px;
  padding-left: 5px;
  padding-right: 5px;
`
const PointerContainer = styled.div`
   position : relative;
   background-image:url(${p => p.bg ? `'${p.bg}'` : ''});
   max-width:322px;
   background-size: 100% 100%;
   aspect-ratio :3/2;
   border-radius: 15px;
`;
const GraphicContainer = styled.div`
   position : relative;
   background-image:url(${p => p.bg ? `'${p.bg}'` : ''});
   max-width:322px;
   background-size: 100% 100%;
   aspect-ratio :2/3;
   cursor:pointer;
`;
const PuzzleWrapper = styled.div`
    background: #102d71;
    padding: 22px 22px;
    width: 100%;
    border-radius: 17px;
    min-height:80px;
    border: 2px solid ${p => p.is_correct ? COLOR.green100 : COLOR.red};
    display: flex;
    flex-direction: column;
    color: #fff;
`;
const PuzzleRow = styled.div`
    display: flex;
    flex-direction: row;
    color: #fff;
`;
const PinPoint = styled.div`
    background: url('${Pinpoint}') no-repeat center bottom / contain;
    width: 30px;
    height: 35px;
    transform: translate(-50%, 0);
    position: absolute;
    bottom: 30px;
    left: 5%;
`;
const PinPointWrapper = styled.div`
    position: absolute;
    left: ${p => p.x ? p.x : 0}%;
    top:  ${p => p.y ? p.y : 0}%;
    cursor: default;
    width: 30px;
    height: 30px;
`;
const AnswerByUserDebate = styled.div`
    font-weight: 400;
    background-color: ${p => p.active ? "rgb(179 255 251 / 60%)" : "transparent"};
    padding: 3px;
    border-radius: 5px;
`;
const SubComment = styled.div`
   padding-left:16px;
`;
const IconImg = styled.img`
    height: 25px;
    width: 25px;
    border-radius: 15px;
    margin-right: 4px;
`;
const CommentIconImg = styled.img`
    height: 20px;
    width: 20px;
    border-radius: 15px;
    margin-right: 4px;
`;
const Container = styled.div`
  display: flex;
`;

const PrintWrapper = styled.div`
  display: flex;
`;


const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  position: relative;
  justify-content: center;
  padding: 20px;
  background-color: white;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const PlanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
`;
const CloseBtn = styled.div`
  background-color: rgb(204, 80, 71);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;
const AnswerWindowWrapper = styled.div`
   position: relative;
   width: 100%;
`;
const Images = styled.img`
  background-color: ${COLOR.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  object-fit: cover;
  height: 600px;
   height: 96vh;
`;
const ImageModal = styled.div`
    left: 50%;
    max-width: 544px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Grade, answers and feedback display
//
//  -- Props
//  ...
//
function isURL(str) {
    const pattern = /^(https?:\/\/|www\.)[^\s\.]+\.\S{2,}$/;
    return pattern.test(str);
}
const game_q_type = {
    quiz: "Quiz",
    true_or_false: "True or false",
    type_answer: "Type Answer",
    puzzle: "Puzzle",
    poll: "Poll",
    drop_pin: "Drop Pin",
    word_cloud: "Word Cloud",
    open_ended: "Open Ended",
    brainstorm: "Brainstorm",
    informational_page: "Informational Page",
}
const getType = (type) => {
    return game_q_type[type] || ''
}
function sortSubmissionsByUserId(submissions, userId) {
    return submissions.sort((a, b) => {
        const aIsUser = a.user_id === userId;
        const bIsUser = b.user_id === userId;

        if (aIsUser && !bIsUser) {
            return -1;
        } else if (!aIsUser && bIsUser) {
            return 1;
        }
        return 0;
    });
}

const AnswerWindow = ({ src, input_position, answers ,setImageModalState}) => {
    return (
        <AnswerWindowWrapper>
            <Images src={src} />
            {input_position?.map((positions, index) => {
                const ans = Array.isArray(answers) ? answers[index] : {};
                const max_height = positions.max_height ? { maxHeight: positions.max_height } : {};
                const min_height = positions.min_height ? { minHeight: positions.min_height } : {};
                return (<div key={index} style={{
                    position: 'absolute',
                    width: `calc(${positions.width} * 100%)`,
                    height: positions.height ? `calc(${positions.height} * 100%)` : 'auto',
                    left: `calc(${positions.left} * 100%)`,
                    top: `calc(${positions.top} * 100%)`,
                    ...max_height,
                    ...min_height,
                    display: "flex",
                    textWrap: "wrap",
                    overflow: "hidden",
                    fontSize:12
                }}>
                    {ans?.type === 'text' ? ans.answer : <img src={ans?.answer} style={{ width: '100%', height: '100%' , objectFit:"contain" , cursor:"pointer" }} onClick={()=>setImageModalState({open:true,src:ans?.answer})}  />}
                </div>
                )
            })}
        </AnswerWindowWrapper>
    );
};

const QuestionComponent = ({ question, index, answer, isStudent, aiFeedback, aiSuggestion, is_game , is_debate , selected_student_id , is_graphic_organizer , activity , page ,setImageModalState}) => {
    const [open,setOpen] = useState(false)
    const questionContent = useMemo(() => {
        if (!question) return null;
        switch (question.type) {
            case 'multipleChoice':
            case 'essay':
            case 'email':
                return <Question>{index + 1}. {question.question}</Question>;
            case 'audio':
            case 'twoMinAudio':
                return <Question>{index + 1}. {question.audio ? <audio controls controlsList="nodownload" src={question.audio} /> : question.question}</Question>;
            default:
                return null;
        }
    }, [question, index]);
    const ViewQuestionImage = ({ viewimage, type }) => {
        // const q_type = getType(type);
        if (isURL(question.image_url)) {
            return <ImageQuestionArea>
                <Question>{index + 1}. {question.question}</Question>
                {/* {q_type ? <Typography variant="subtitle2" >Type:  {q_type}</Typography> : null} */}
                {viewimage && <QImage src={question.image_url} />}
            </ImageQuestionArea>
        } else {
            return (<>
                <Question>{index + 1}. {question.question}</Question>
                {/* {q_type ? <Typography variant="subtitle2" >Type:  {q_type}</Typography> : null} */}
            </>)
        }
    }
    const gameQuestionContent = useMemo(() => {
        if (!question) return null;
        switch (question.type) {
            case 'true_or_false':
            case 'brainstorm':
            case 'drop_pin':
            case 'type_answer':
            case 'poll':
            case 'quiz':
            case 'puzzle':
            case 'word_cloud':
            case 'open_ended':
                return <ViewQuestionImage viewimage={question.type !== 'drop_pin'} type={question.type} />;
            default:
                return null;
        }
    }, [question, index]);

    const answerContent = useMemo(() => {
        if (!question || !answer) return null;

        const ans = get(answer, "answer");
        const qAnswer = get(question, "answer");

        switch (question.type) {
            case "multipleChoice":
                return (
                    <>
                        <Answer correct={qAnswer == ans}>
                            {alphaRef[ans]} - {question.options[ans]}
                        </Answer>
                        {!isStudent && qAnswer != ans && (
                            <Answer correct>
                                {alphaRef[qAnswer]} - {question.options[qAnswer]}
                            </Answer>
                        )}
                    </>
                );
            case "essay":
            case "email":
                return (
                    <>
                        <MessageRenderer style={{ color: 'rgb(0, 0, 0)' }} data={ans} />
                        <AIFeedback ai_feedback={aiFeedback} ai_suggestion={aiSuggestion}
                        />
                    </>
                );
            case "audio":
            case "twoMinAudio":
                return ans ? (
                    <audio style={{ marginLeft: 12 }} controls controlsList="nodownload" src={ans} />
                ) : null;
            default:
                return null;
        }
    }, [answer, question, isStudent, aiFeedback, aiSuggestion]);
    const ViewAnswerImage = ({ ans, isCorrect, qAnswer, type }) => {
        const is_poll = type === 'poll'
        const show_answer = !isStudent && !isCorrect && !is_poll
        const border_color = (isCorrect || is_poll) ? COLOR.green100 : COLOR.red
        if (isURL(ans)) {
            return <ImageAnswerArea>
                <AnswerMarkLagendaArea>Answer</AnswerMarkLagendaArea>
                <QImage src={ans} style={{ border: `2px solid ${border_color}` }} />
                {show_answer && (
                    <QImage src={qAnswer} style={{ border: `2px solid ${COLOR.green100}` }} />
                )}
            </ImageAnswerArea>
        } else {
            return (<ImageAnswerArea>
                <AnswerMarkLagendaArea>Answer</AnswerMarkLagendaArea>
                <Answer correct={isCorrect || is_poll}>
                    {ans}
                </Answer>
                {show_answer && (
                    <Answer correct>
                        {qAnswer}
                    </Answer>
                )}
            </ImageAnswerArea>)
        }
    }
    const ViewPinPoint = ({ image_url, pointer_poistion }) => {
        const { x: xpoint, y: ypoint } = pointer_poistion || {}
        return (<ImageAnswerArea>
            <AnswerMarkLagendaArea>Answer</AnswerMarkLagendaArea>
            <PointerContainer bg={image_url}>
                <PinPointWrapper x={xpoint} y={ypoint} >
                    <PinPoint />
                </PinPointWrapper>
            </PointerContainer>
        </ImageAnswerArea>)
    }
    const onPreview = () => { setOpen(true); }
    const ViewGraphicOrganizer = ({  }) => {
        const input_position = activity.input_position || []
        return (<ImageAnswerArea>
            <AnswerMarkLagendaArea>Answer</AnswerMarkLagendaArea>
            <GraphicContainer bg={page?.resource} onClick={onPreview}>
                {input_position.map((positions, i) => {
                    const ans  = Array.isArray(answer) ? answer[i] : {};
                    return (
                        <div style={{
                            position: 'absolute',
                            width: `calc(${positions.width} * 100%)`,
                            height: positions.height ? `calc(${positions.height} * 100%)` : 'auto',
                            left: `calc(${positions.left} * 100%)`,
                            top: `calc(${positions.top} * 100%)`,
                            fontSize:12,
                            display: "flex",
                            textWrap:"wrap",
                            overflow: "hidden",
                            maxHeight: "100px",
                        }}>
                            {ans?.type ==='text'?ans.answer: <img src={ans?.answer} style={{ width: '100%', height: '100%' , cursor:"pointer" , objectFit:"contain" }}/> }
                        </div>
                    )
                })}
            </GraphicContainer>
        </ImageAnswerArea>)
    }
    const ViewPuzzleAnswer = ({ given_answer, correct_answer }) => {
        if (!Array.isArray(given_answer) || !Array.isArray(correct_answer)) {
            return <></>
        }
        const isCorrect = isEqual(given_answer, correct_answer)
        return <ImageAnswerArea>
            <AnswerMarkLagendaArea>Answer</AnswerMarkLagendaArea>
            <PuzzleWrapper is_correct={isCorrect}>
                {given_answer.map(v => {
                    const lable_txt_index = findIndex(question.options, k => k == v);
                    const lable_text = lable_txt_index >= 0 ? `(${alphaRef[lable_txt_index]}) - ` : '';
                    return (<PuzzleRow> {lable_text} {" "} {v}</PuzzleRow>)
                })}
            </PuzzleWrapper>
            {!isCorrect && !isStudent ? <PuzzleWrapper is_correct style={{ marginTop: 20 }}>
                {correct_answer.map(v => {
                    const lable_txt_index = findIndex(question.options, k => k == v);
                    const lable_text = lable_txt_index >= 0 ? `(${alphaRef[lable_txt_index]}) - ` : '';
                    return (<PuzzleRow> {lable_text} {" "} {v}</PuzzleRow>)
                })}
            </PuzzleWrapper> : null}
        </ImageAnswerArea>
    }
    const gameAnswerContent = useMemo(() => {
        if (!question || !answer) return null;

        const ans = get(answer, "answer");
        const q_ans = get(question, "answer")
        const qAnswer = Array.isArray(q_ans) && (question.type === "true_or_false" || question.type === "quiz" || question.type === "type_answer") ? q_ans[0] : q_ans;

        switch (question.type) {
            case 'true_or_false':
            case 'quiz':
            case 'type_answer':
            case 'poll':
                return (
                    <ViewAnswerImage ans={ans} isCorrect={qAnswer == ans} qAnswer={qAnswer} type={question.type} />
                );
            case 'open_ended':
            case 'word_cloud':
            case 'brainstorm':
                return (<ImageAnswerArea>
                    <AnswerMarkLagendaArea>Answer</AnswerMarkLagendaArea>
                    <Answer correct>
                        {ans}
                    </Answer>
                </ImageAnswerArea>
                )
            case 'drop_pin':
                return <ViewPinPoint image_url={question.image_url} pointer_poistion={ans} />
            case 'puzzle':
                return (<ViewPuzzleAnswer given_answer={ans} correct_answer={q_ans} />)
            default:
                return null;
        }
    }, [answer, question, isStudent, aiFeedback]);
    if (is_game) {
        return (
            <Subsection>
                {gameQuestionContent}
                {gameAnswerContent}
            </Subsection>
        );
    } else if (is_debate) {
        return (
            <Subsection>
                {<Question>{question.question}</Question>}
                {map(sortSubmissionsByUserId(answer, selected_student_id), (ans, i) => (<ImageAnswerArea key={i}>
                    <AnswerByUserDebate active={selected_student_id === ans.user_id} style={{ fontSize: 14 }}>
                        {ans.user_profile_img ? <IconImg src={getAvatar(ans.user_profile_img)} /> : <UserOutlined size={"small"} style={{ marginRight: 4 }} />}
                        <b>{ans.user_name}</b>:- {ans.answer}</AnswerByUserDebate>
                    <SubComment>
                        {map(ans.comment, cmnt => (<AnswerByUserDebate active={selected_student_id === cmnt.user_id} key={i + cmnt.user_id + "rep"} style={{ fontSize: 13 }}>
                            {cmnt.user_profile_img ? <CommentIconImg src={getAvatar(cmnt.user_profile_img)} /> : <UserOutlined size={"small"} style={{ marginRight: 4 }} />}
                            <b>{cmnt.user_name}</b>:- {cmnt.reply_text}</AnswerByUserDebate>))}
                    </SubComment>
                </ImageAnswerArea>))}
            </Subsection>
        );
    }else if(is_graphic_organizer){
        return (
            <Subsection>
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    className={"lessonPlanPopUp"}
                    style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        zIndex:999999999999
                    }}
                >
                    <PlanWrapper>
                        <PlanContainer>
                            <CloseBtn
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpen(false);
                                }}
                            >
                                <Close
                                    style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }}
                                />
                            </CloseBtn>
                            <Container>
                                <PrintWrapper>
                                    <AnswerWindow src={page?.resource} input_position={activity.input_position || []} answers={answer} setImageModalState={setImageModalState} />
                                </PrintWrapper>
                            </Container>
                        </PlanContainer>
                    </PlanWrapper>
                </Modal>
                <ViewGraphicOrganizer />
            </Subsection>
        );
    }
    else{
        return (
            <Subsection>
                {questionContent}
                {answerContent}
            </Subsection>
        );
    }
};
const AIFeedback = ({ ai_feedback, ai_suggestion }) => {
    const text = "Consejos para mejorar";
    const maxLength = 12;
    const isMobile = useMediaQuery('(max-width: 1250px)');
  const [isPlayingSuggestion, setIsPlayingSuggestion] = useState(false);

  const stripTags = (input) => {
    if(!input) return '';
    let strippedInput = input.replace(/<[^>]*>?/gm, '');
    strippedInput = strippedInput.replace(/```html|```|\*\*/g, '');
    return strippedInput;
  };

    if (ai_feedback) {
        return <div style={{ position: "relative" }}>
            <Row>
                <Col md={24} sm={24} lg={18} >
                <AllAiSection>
                <ErrorAiArea> Errores y cambios necesarios  <AIIcon src={AIfeebackImage} /></ErrorAiArea><div style={{ marginTop: 26, marginLeft: 10, cursor: "help" }} >
                <Tooltip
                    placement={"topLeft"}
                    trigger={"hover"}
                    title={'Should any questions arise or errors be detected, please ensure to carefully review and make the necessary corrections'}>
                    <InfoIcon />
                </Tooltip>
            </div>
            </AllAiSection>
            </Col>
            </Row>
            <AIContent dangerouslySetInnerHTML={{ __html: ai_feedback }} />
            <div style={{ position: 'relative' }}>
                <Row>
                    <Col md={24} sm={24} xs={18} lg={18}>
                    <AllAiSection>
                    <ErrorAiAreas> 
                        {/* {text.length > maxLength ? (
                            <Tooltip title={text}>
                                {text.slice(0, maxLength) + '...'}
                            </Tooltip>

                        ) :(
                            {text}
                        )}
                        
                        <AIIcons src={AiFeedbackImage} /> */}
                        <div>
                            {text.length > maxLength ? (
                                isMobile ? (
                                    <Tooltip title={text}>
                                        <span>{text.slice(0, maxLength) + '...'}</span>
                                    </Tooltip>
                                ) : (
                                    <span>{text}</span>
                                )
                            ) : (
                                <span>{text}</span>
                            )}
                            <AIIcons src={AiFeedbackImage} />
                        </div>



                        </ErrorAiAreas>
                        <div style={{ marginTop: 26, marginLeft: 10, cursor: "help", zIndex:99 }} >
                    <Tooltip
                        placement={"topLeft"}
                        trigger={"hover"}
                        title={'Should any questions arise or errors be detected, please, ask your teacher for feedback.'}>
                        <InfoIcon />
                    </Tooltip>
                </div>
                </AllAiSection>
                </Col>
                <Col md={24} sm={24} xs={6} lg={6}>
                <div className="feedBackSound" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0', width: '100%' }}>
                    <ReaderSpanish text={stripTags(ai_suggestion)} isPlaying={isPlayingSuggestion} setIsPlaying={setIsPlayingSuggestion}  />
                </div>
                </Col>
                </Row>
                <AIContent dangerouslySetInnerHTML={{ __html: stripTags(ai_suggestion) }} />
            </div>
        </div>

    } else {
        return null
    }
}
const GradeDisplay = ({   assignment, answers, feedback, audioFeedback, grade, name, questions, type, isExpended, isLate, hasFeedback = true, isUngraded, ai_feedback, ai_suggestion, is_game , is_debate , selected_student_id }) => {
    const [expanded, setExpanded] = useState(isExpended || false);
    const activities = useActivities()
    const currentUser = useCurrentUser()
    const isStudent = get(currentUser, "role") === "student"
    const answersLength = get(answers, "length", 0);
    const activites = useMemo(()=>find(activities, v=>v.id==assignment?.assignmentId||v.id == assignment?.assignment?.id),[activities,assignment]);
    const allBooks = useAllBook()
    const graphic_book = useMemo(()=>find(allBooks, ['id', '7']),[allBooks,activites]);
    const graphic_page = useMemo(()=>find(get(graphic_book,"pages"),k=>k.pageId == activites?.assignment_page),[graphic_book,activites]);
    const is_graphic_organizer =  useMemo(()=>activites?.activity_type === "graphic_organizer",[activites])
    const [imagemodalOpen, setImageModalState] = useState({open:false,src:''})
    const renderAnswers = useMemo(() => {
        return questions && questions.map((question, i) => {
            if(i>0) return null;
            return (
                <QuestionComponent
                    key={`${i}-question`}
                    question={question}
                    index={i}
                    answer={is_debate || is_graphic_organizer ? answers : answers[i]}
                    is_game={is_game}
                    is_debate={is_debate}
                    isStudent={isStudent}
                    selected_student_id={selected_student_id}
                    aiFeedback={ai_feedback ? ai_feedback[i] : null}
                    is_graphic_organizer={is_graphic_organizer}
                    activity={activites}
                    page={graphic_page}
                    setImageModalState={setImageModalState}
                    aiSuggestion={ai_suggestion ? ai_suggestion[i] : null}
                />
            )
        })
    }, [is_graphic_organizer, questions, is_game, is_debate, answers, ai_feedback, graphic_page, activites])
    return (
        <Wrapper>
            <Button onClick={() => setExpanded(!expanded)}>
                <Name isLate={isLate}>{name}</Name>
                <Text>{isUngraded ? "Ungraded" : grade}</Text>
            </Button>
            <Modal
                open={imagemodalOpen.open}
                onClose={() => setImageModalState({open: false , src:''})}
                style={{ height: '100%', width: '100%', zIndex: 9999999999999 }}
            >
                <ImageModal>
                    <Image src={imagemodalOpen.src} alt='' />
                </ImageModal>
            </Modal>
            {answersLength === 0 ? <Notification bgColor={COLOR.yellow} color={COLOR.white}>
                The assignment was graded without a submission.
            </Notification> : null}
            <ExpandedContainer expanded={expanded}>
                <Section>
                    <SectionHeader>Answers</SectionHeader>
                    {is_graphic_organizer?renderAnswers:questions && questions.map((question, i) => (
                        <QuestionComponent
                            key={`${i}-question`}
                            question={question}
                            index={i}
                            answer={is_debate ? answers : answers[i]}
                            is_game={is_game}
                            is_debate={is_debate}
                            isStudent={isStudent}
                            selected_student_id={selected_student_id}
                            aiFeedback={ai_feedback ? ai_feedback[i] : null}
                            aiSuggestion={ai_suggestion ? ai_suggestion[i] : null}
                        />
                    ))}
                </Section>
                {hasFeedback && <SectionHeader>Teacher Feedback</SectionHeader>}
                {hasFeedback && <FeedbackSection>
                    <Section>
                        {feedback ? <FeedbackText> {feedback} </FeedbackText> : null}
                        {audioFeedback ? <audio controls controlsList="nodownload" src={audioFeedback}></audio> : null}
                        {!audioFeedback && !feedback ? <FeedbackText>No feedback left for this assignment</FeedbackText> : null}
                    </Section>
                </FeedbackSection>}
            </ExpandedContainer>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
GradeDisplay.propTypes = {};

//  -- Default props ------------------
GradeDisplay.defaultProps = {};

export default GradeDisplay;
