import _ from "lodash"

export default (resutlArr=[])=>{
return _.filter(resutlArr,item=>item.status!="rejected").map(item=>item.value)
}
export const getSattledFlatPrommisses=(resutlArr)=>{
    return _.filter(resutlArr,item=>item.status!="rejected").map(item=>item.value).flatMap(k=>k)
}
export const PromiseSettledAll=async(fun)=>{
   return  Promise.allSettled(fun)
           .then(getSattledFlatPrommisses)
}