import React, { useState, useEffect } from "react"
import { Typography, Collapse, TextField } from "@material-ui/core";
import styled from 'styled-components';
import COLOR from 'utils/constants/color';
import { Row, Col } from "antd"
import {
    TimePicker,
    DatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";
import { Close } from "@material-ui/icons";
import { CalendarIcon, ClockIcon } from "utils/assets/images";
import moment from "moment"
import Loading from "components/Loading";
const DATE_FORMAT = "YYYY-MM-DD"

const SettingContainer = styled.div`
    background-color: #fff;
    width: 300px;
    position:relative;
    display: flex;
    flex-direction: column;
    min-height:100px;
    min-height: 100px;
    border-radius: 20px;
    box-shadow: -1px 0px 14px 0px #000000b0, 0px 1px 4px 0px #000000b0;
`;
const RowChatHourSwitch = styled.div` 
   display:flex;
   flex-direction:row;
   width: 100%;
   justify-content: space-between;
   margin: 16px 0px;
`;
const RowTop = styled.div`
    display:flex;
    flex-direction:row;
    width: 100%;
    justify-content: space-between;
    border-top: 10px;
    background: rgb(65,46,81);
    color: #fff;
    padding: 9px 18px;
    min-height: 25px;
    display: flex;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;
const CreateTimeButton = styled.button`
   border:none;
   color:${COLOR.red};
   font-weight: 500;
   font-size: 18px;
   background: #fff;
   cursor: pointer;
`;
const SchedulePer = styled.button`
   border:none;
   color:${COLOR.black100};
    width:100%;
    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    background: #fff;
    cursor: pointer;
`;
const CloseReapper = styled.button`
    border: none;
    border-radius: 50%;
    background: rgb(10 154 241);
    cursor: pointer;
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SaveButton = styled.button`
    border:none;
    width:100%;
    border-radius:5px;
    border: none;
    color: rgb(228 221 213);
    background: rgb(250 153 0);
    border-radius: 15px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    height:30px;
`;
const SubContainer = styled.div`
   width:100%;
   padding: 10px 18px;
   min-height: 176.71px;
`;
const ChoseText = styled.div`
    color:${COLOR.black100};
    width:100%;
    font-weight: 600;
    font-size: 17px;
`;
const DateTimePickerDiv = styled.div`
    height: 30px;
    font-size: 12px;
    background: rgb(36 132 225);
    color: #fff;
    border: none;
    outline: none;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    flex:1;
`;
const TimerIcon = styled.img`
    height: 25px;
    width: 30px;
    object-fit: cover;
`;

export const Scheduler = ({ onClose, onCreate, setAlertView, value }) => {
    const [createTime, setCreateTime] = useState(false)
    const [scheduleTime, setScheduleTime] = useState(moment().add({ minutes: 5 }))
    const [loader, setLoader] = useState(false)
    const onDateChange = (e) => {
        const _date = moment(e.target.value, DATE_FORMAT);
        if (_date.isValid()) {
            const v = moment(scheduleTime).set({
                year: _date.get("year"),
                month: _date.get("month"),
                date: _date.get("date")
            })
            // console.log(_date.get("date"),_date.get("year"),_date.get("day"),_date.get("days"));
            setScheduleTime(v)
        }

    }
    // const onTimeChange = (e) => {
    //     const v = moment(scheduleTime)
    //         .set({
    //             hour: moment(e).get("hours"),
    //             minute: moment(e).get("minutes")
    //         })
    //     if (v.isSameOrAfter(moment(), "minute")) {
    //         setScheduleTime(v)
    //     } else {
    //         setAlertView({ open: true, message: "Date abd Time has to be after current Date and Time" })
    //     }


    // }
    const onInputTimeChange = (e) => {
        const t = e.target.value;
        if (t) {
            const [hr, min] = t.split(":")

            const v = moment(scheduleTime)
                .set({
                    hour: hr,
                    minute: min
                })
            setScheduleTime(v)
        }


    }
    useEffect(() => {
        if (value && moment(value).isValid()) {
            setCreateTime(true)
            setScheduleTime(moment(value))
        }
    }, [value])
    const onClickT7 = () => {
        const v = moment(scheduleTime).add({
            day: 1
        })
            .set({
                hour: 19,
                minute: 0
            })
        setScheduleTime(v)
        onScheduleMessage(v)
    }
    const onClickT4 = () => {
        const v = moment(scheduleTime).add({
            day: 1
        })
            .set({
                hour: 4,
                minute: 0
            })
        setScheduleTime(v)
        onScheduleMessage(v)

    }
    const onScheduleMessage = (v) => {
        let value = moment(v ? v : scheduleTime);
        if (typeof onCreate == "function") {
            if (value.isSameOrBefore(moment(), "minute")) {
                setAlertView({ open: true, message: "Date and Time has to be after current Date and Time" })
                return;
            }
            setLoader(true)
            onCreate(value.toISOString())
            setLoader(false)
            onClose()
        }
    }
    return (<SettingContainer className="schdlMsg">

        <RowTop className="schdlMsgRw">
            <Typography style={{ color: '#ffffffcc', fontSize: '18px', fontWeight: 500 }}
                className="schdlMsgTypo" variant="subtitle2">Schedule message</Typography>
            <CloseReapper onClick={onClose}> <Close color="#fff" style={{ fontSize: 12 }} /></CloseReapper>
        </RowTop>

        <SubContainer className="schdlMsgContn">

            <Collapse className="schdlMsgCol" in={!createTime} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <RowChatHourSwitch className="schdlMsgSwth">
                    <SchedulePer className="schdlMsgPr" onClick={onClickT7}>{loader ? <Loading size={15} /> : "Tommorow at 7:00 am"}</SchedulePer>
                </RowChatHourSwitch>
                <RowChatHourSwitch className="schdlMsgSwth">
                    <SchedulePer className="schdlMsgPr" onClick={onClickT4}>{loader ? <Loading size={15} /> : "Tommorow at 4:00 am"}</SchedulePer>
                </RowChatHourSwitch>
                <RowChatHourSwitch className="schdlMsgHrs">
                    <CreateTimeButton className="schdlMsgTm" onClick={() => { setCreateTime(true) }}>
                        Create Time</CreateTimeButton>
                </RowChatHourSwitch>
            </Collapse>

            <Collapse in={createTime} style={{ width: "100%" }}>
                <Row style={{ width: "100%" }}>
                    <ChoseText>Chose Date and Time</ChoseText>
                </Row>
                <Row style={{ width: "100%", marginTop: 10 }}>
                    <DateTimePickerDiv>
                        <TimerIcon src={CalendarIcon} />

                        {/* <DatePicker placeholder='Date'
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            className={"schedultDateTimePicker"}
                            value={scheduleTime}
                            minDate={moment()}
                            minDateMessage={""}
                            onChange={onDateChange} /> */}

                        <TextField
                            id="date"
                            variant="outlined"
                            type="date"
                            defaultValue={scheduleTime.format(DATE_FORMAT)}
                            className={"schedultDateTimePicker"}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            inputProps={{ min: moment().format("YYYY-MM-DD") }}

                            value={scheduleTime.format(DATE_FORMAT)}
                            onChange={onDateChange}
                        />

                    </DateTimePickerDiv>
                </Row>
                <Row style={{ width: "100%", marginTop: 10 }}>
                    <DateTimePickerDiv>
                        <TimerIcon src={ClockIcon} />
                        <TextField
                            id="time"
                            variant="outlined"
                            type="time"
                            defaultValue={scheduleTime.format("HH:mm")}
                            className={"schedultDateTimePicker"}

                            InputLabelProps={{
                                shrink: false,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            value={scheduleTime.format("HH:mm")}
                            onChange={onInputTimeChange}
                        />
                        {/* <KeyboardTimePicker placeholder='Time'
                            inputVariant="outlined"
                            margin="normal"
                            id="time-picker"
                            className={"schedultDateTimePicker"}
                            format="hh:mm a"
                            ampm
                            value={scheduleTime}
                            minDateMessage={""}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            minDate={moment(scheduleTime).isSame(moment(), "date") ? moment() : null}
                            onChange={onTimeChange} /> */}
                    </DateTimePickerDiv>
                </Row>
                <Row style={{ width: "100%", marginTop: 10, marginBottom: 10 }}>
                    <SaveButton onClick={() => onScheduleMessage(scheduleTime)}>{loader ? <Loading size={15} /> : value ? "Update and Schedule" : "Save and Schedule"} </SaveButton>
                </Row>
            </Collapse>

        </SubContainer>
    </SettingContainer>
    )

}