//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import styled from 'styled-components';

//  -- Components ---------------------
import { Block } from '@material-ui/icons';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Container = styled.div`
    align-items: center;
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px;
`;

const Text = styled.span`
    color: ${COLOR.black500};
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    text-align: center;
`;

const Wrapper = styled.div`
    padding: 24px 0px;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  EmptyState
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const EmptyState = ({ message }) => {
    return (
        <Wrapper>
            <Container>
                <Block style={{ color: COLOR.black600, fontSize: '40px' }} />
                <Text>{message}</Text>
            </Container>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
EmptyState.propTypes = {};

//  -- Default props ------------------
EmptyState.defaultProps = {};

export default EmptyState;
