//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle } from "@material-ui/icons";
import { Avatar, Tooltip } from "antd";
import React, { useMemo } from "react";
import { useChatConfig } from "store/chat/selectors";
import { useProfilePicturePicker, useUserProfileConfig } from 'store/users/selectors';
import styled from 'styled-components';
import { getAvatar } from "utils/assets/images/lootie";

const UserModal = styled.div`
    left: 50%;
    max-width: 85vw;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    @media screen and (max-width: 768px){
        max-width: 95vw;
    }
`;
const UserPopUpArea = styled.div`
    background-color: rgb(82,84,82);
    border-radius: 10px;
    padding: 28px;
    position:relative;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px){
        padding: 15px;
    }
`;
const CloseBtn = styled.div`
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    z-index: 1;
`;
const UserArea = styled.div`
    display:flex;
    flex-direction:column;
    overflow:auto;
    overflow-x:auto;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    align-items: center;
    justify-content: center;
`;
const UserWrapper = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper = styled.div`
    cursor: pointer;
    border: ${p => p.active ? ` 2px solid green` : 'none'};
    padding: 4px;
    flex-direction: row;
    padding: 2px;
    border-radius: 50%;
    margin:${p => p.active ? ` 18px` : '20px'};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    position:relative;
    @media screen and (max-width: 768px){
        margin:${p => p.active ? ` 4px` : '6px'};
    }
`;
const Logo = styled.img`
    height: auto;
    width: 100px;
`;
const LogoBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width:100%;
`;
const LoaderWrapper = styled.div`
    position:absolute;
    z-index:100;
`;
const ImageRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;
const useStyles = makeStyles((theme) => ({
    titleText: {
        fontWeight: 400,
        color: 'rgb(255, 255, 255)',
        fontSize: 20,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 17
    }
}));
export const AVatarSelector = ({ size }) => {
    // const [avatarmodal, setAvatarModal] = useState(false)
    // const [avatarSelected, setAvatarSelected] = useState(null)
    // const [loader, setLoader] = useState(false)
    // const cssClass = useStyles()
    // const { socketConnection } = useSocket()
    // const dispatch = useDispatch();
    // const currentUser = useCurrentUser()
    const useConfig = useChatConfig()
    const { profile_config } = useUserProfileConfig()
    const userAvatar = useMemo(() => {
        let avatar = null;
        if (profile_config && profile_config.config) {
            avatar = profile_config.config.profile_pic;
        } else if (useConfig && useConfig.avatar) {
            avatar = useConfig.avatar;
        }
        return getAvatar(avatar)
    }, [useConfig, profile_config])   
    const { showProfilePhotoViewer } = useProfilePicturePicker()
    // useEffect(() => {
    //     setAvatarSelected(get(useConfig, "avatar"))
    // }, [useConfig])
    // const updateAvatar = async (avatar) => {
    //     setLoader(true)
    //     setAvatarSelected(avatar)
    //     const config = await dispatch(handleUpdateUserChatAvatar(currentUser.id, avatar))
    //     if (socketConnection) {
    //         try {
    //             socketConnection.send(JSON.stringify({ type: "config_update", code: 'config_update', config: config, id: currentUser.id }))
    //         } catch (error) {
    //             console.log(error, "socket send update error")
    //         }
    //     }
    //     // setAvatarModal(false)
    //     setLoader(false)
    // }
    // const cssClasses = useStyles();
    return (<>
        {/* <Modal
            open={avatarmodal}
            onClose={() => setAvatarModal(false)}
            style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            className="avtarModl">
            <UserModal className="avtarUsrModl">
                <UserPopUpArea className="avtarUsrArea">
                    <Typography variant="h4" className={cssClass.titleText}>Please select your avatar:</Typography>
                    <CloseBtn onClick={() => setAvatarModal(false)}>
                        <Close style={{ color: COLOR.white, fontWeight: "bolder", fontSize: 25 }} />
                    </CloseBtn>
                    <UserArea className="avtarUsrImg">
                        {chunk(Object.keys(AVATARS)).map((item,i) => (<ImageRow className="avtarUsrRw" key={`avtar-${i}`}>
                                {map(item, key => (<Wrapper className="avtarUsrWrap" active={key == avatarSelected}
                                    key={key}
                                    onClick={() => {
                                        if (!loader) {
                                            updateAvatar(key)
                                        }
                                    }}>
                                    {loader && key == avatarSelected ? <LoaderWrapper> <Loading size={25} /> </LoaderWrapper> : null}
                                    <Avatar src={AVATARS[key]} size={{xs:84,sm:90,xl:150,lg:100,xxl:160,md:120}} />
                                </Wrapper>))}
                            </ImageRow>)
                        )}
                    </UserArea>
                    <LogoBottomRow>
                        <Logo src={TransparentLogoWhite} />
                    </LogoBottomRow>
                </UserPopUpArea>
            </UserModal>
        </Modal> */}
        <UserWrapper onClick={showProfilePhotoViewer}>
            <Tooltip title="Change Avatar">
                {userAvatar ? <Avatar src={userAvatar} size={size} /> : <AccountCircle style={{
                    color: 'rgb(255, 255, 255)',
                    fontSize: '45px',
                    marginRight: ' 5px'
                }} />}
            </Tooltip>
        </UserWrapper>

    </>)
}