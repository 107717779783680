//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import SentryWrap from "components/Sentry";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { CaretDownOutlined } from '@ant-design/icons';
import { IconButton } from "@material-ui/core";
import {
    ArrowBackIos,
    Close, Home
} from "@material-ui/icons";
// import Table from "components/Table";
import { filter, find, groupBy, has, map } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";

//  -- Thunks -------------------------
import { Button, Card, Col, Dropdown, Input, Layout, Menu, Row, Select, Space } from 'antd';
import Loading from "components/Loading";
import DistrictForm from "features/forms/admin/District";
import DistrictTable from "features/forms/admin/DistrictTable";
import SchoolsTable from "features/forms/admin/SchoolsTable";
import { get } from 'lodash';
import { handleLogout } from "store/auth/thunks";
import { handleCreateBook, handleListActiveInActiveBooks, handleListAllBooks } from "store/books/thunks";
import { handleProcessClassTenant } from "store/classlink/thunks";
import { handleGetCoursesForClever } from "store/clever/thunks";
import { handleInvokeFeedbackForm } from "store/feedback/thunks";
import { createKeystore } from "store/keystore/thunks";
import { useCurrentOrgPermission } from "store/organizations/selectors";
import { handleGetOrganization, handleListDistricts } from "store/organizations/thunks";
import { useTimmerRunning } from "store/timmer/selectors";
import { getUserBooksByOrg } from "store/userBooks/thunks";
import { useUserClasses } from "store/userClasses/selectors";
import { useCurrentUser, useDemoStatus } from "store/users/selectors";
import { v4 } from 'uuid';
import BooksTable from "features/forms/admin/BooksTable";
import { handleListVocabulary, handleListVocabularyNew } from "store/vocabulary/thunks";
const { Header, Sider, Content } = Layout;


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const items = [
    getItem('Home', 'Home', <Home style={{ fontSize: '25px' }} />),
    getItem('Data Browser', 'data-browsers', <Home style={{ fontSize: '25px' }} />),
];

const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
const MENU = [
    { id: "see_districts", value: "Districts" },
    { id: "see_schools", value: "Schools" },
    { id: "see_users", value: "Users" },
    { id: "add_users", value: "Add Users" },
    { id: "edit_books", value: "Edit Books" },
    { id: "add_books", value: "Add Books" },
];
const MENUS = [
    { id: "your_districts", value: "Your Districts" },
];
const OVERVIEWMENU = [
    { id: "see_overview", value: "Overview" },
    { id: "see_settings", value: "Settings" },
];
const BOOKLIST = ["32", "7", "8", "1"];
const SchoolsHeaderItem = [
    "Licenses",
    "Licenses used",
    "Licenses available",
    "Demo",
];
const TeachersHeaderItem = [
    "Classes",
    "students",
    "Activities assigned",
    "Licenses used",
];
const StudentHeaderItem = [
    "Notes Taken",
    "Quiz Completed",
    "Activity Completed",
    "Assignment Past Due",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  padding:20px;
`;

const Column = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  align-items: flex-end;
  border-bottom: 1px dashed ${COLOR.black700};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-bottom: 16px;
`;

const CustomButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${COLOR.green100};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  width: 200px;
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 14px;
`;

const District = styled.div`
  align-items: center;
  background-color: white;
  border: 1px dashed ${COLOR.black700};
  border-radius: 2px;
  color: ${COLOR.black200};
  display: flex;
  flex-direction: row;
  font-size: 13px;
  height: 52px;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 0px 8px;
  position: relative;
  transition: all 450ms ease;
  width: 100%;
  &:hover {
    background-color: #f9f9f9;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
`;

const Headers = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 8px;
`;

const Navigation = styled.div`
  width: 100%;
`;

const NavigationItem = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${COLOR.black700};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  outline: none;
  padding: 16px 12px;
  width: 100%;
`;

const Section = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.span`
  color: ${COLOR.black100};
  display: inline-block;
  font-size: 16px;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const TitleContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black700};
    display: inline-flex;
    flex-direction; row;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  height: 100%;
  // padding: 20px 0px;
  width: 100%;
`;
const OverviewText = styled.span`
  color: white;
  font-size: 19px;
  font-weight: 600;
  display:flex !important;
  align-items:center;
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: -18px;
  margin-left: 18px;
  @media (max-width: 992px) {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
const DataBrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: -18px;
  margin-left: 18px;
  @media (max-width: 992px) {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
`;
const SearchItem = styled.div`
  position: absolute;
  right: -20px;
  min-height: 100px;
  max-height: 300px;
  width: 300px;
  background-color: rgb(130, 222, 228);
  z-index: 99999;
  top: 40px;
  border-radius: 10px;
  border: 1px solid #fff;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const SearchBox = styled.input`
  background-color: rgb(28, 49, 115);
  border: none;
  border-radius: 7px;
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  outline: none;
  padding: 2px 16px;
  width: 130px;
  margin-right: 16px;
  margin-left: 16px;
  text-align: center;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR.white};
  }
  :-ms-input-placeholder {
    color: ${COLOR.white};
  }
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-right: 2px;
    margin-left: 2px;
  }
`;
const ItemsTabs = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  border-radius: 12px;
  min-width: 200px;
  background-color: ${COLOR.indigodeep};
  width: 32%;
  padding: 16px;
  margin-top: 10px;
`;

const ItemsTabsList = styled.div`
  display: inline-block;
  flex-direction: column;
  min-height: 100px;
  border-radius: 12px;
  min-width: 200px;
  background-color: ${COLOR.indigodeep};
  width: 32%;
  padding: 16px;
  margin-top: 10px;
`;
const ItemRow1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-flow: row wrap;
  margin-top: -10px;
  @media (max-width: 992px) {
  }
`;
const Itemicon = styled.div`
  height: 15px;
  width: 15px;
  background-image: linear-gradient(${COLOR.green100}, ${COLOR.pink100});
`;
const ItemLabel = styled.span`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  text-transform: capitalize;
`;
const ItemLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
const ItemValue = styled.span`
  color: #fff;
  font-size: 32px;
  font-weight: 500;
`;

const ItemValueList = styled.span`
  height: 30px;
  width: 190px;
  border-radius: 5px;
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  text-align: center;
  padding-top: 2px;
  flex: 70%;
`;

const ItemValueRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const BookRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-top: 40px;
  background-color: ${COLOR.blueishdeep};
  min-height: 150px;
  border-radius: 12px;
  border: 1px solid ${COLOR.blueish200};
  position: relative;
  width: 100%;
  wrap-content: center;
  flex-wrap: wrap;
  padding: 0px 24px;
`;
const BookItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2%;
  width: 120px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
`;
const BookItem = styled.img`
  width: 120px;
  height: auto;
  box-shadow: 2px -1px 6px rgb(0 0 0 / 35%), 2px 0px 2px rgb(0 0 0 / 22%);
`;
const BookItemLabel = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  white-space: pre;
  margin-left: 30px;
`;
const BookLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-wrap: wrap;
  width: 100%;
  justify-content: center;
  text-align: center;
  position: relative;
`;
const BookWhiteDot = styled.div`
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  position: absolute;
  left: 0px;
`;
const CircularArrow = styled.img`
  height: 35px;
  width: 35px;
`;
const TotalQuizLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
`;
const TotalQuizLabel = styled.span`
  color: ${COLOR.black100};
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
`;
const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding-left: 30px;
`;
const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  width: 100%;
  height: 100%;
  background-color: ${COLOR.indigodeep};
`;
const HomeBtnb = styled.button`
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  margin-bottom: 16px;
`;
const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const CustomTableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding-right: 24px;
`;
const TableHeader = styled.div`
  height: 30px;
  width: 190px;
  border-radius: 5px;
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  justify-content: center;
  align-items: center;
  color: ${COLOR.white};
  text-align: center;
  padding-top: 2px;
  margin-left: 12px;
  margin-right: 12px;
`;
const TableColumn = styled.div`
  height: 30px;
  width: 190px;
  border-radius: 5px;
  background-color: ${COLOR.white};
  color: ${COLOR.black100};
  text-align: center;
  padding-top: 2px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
  margin-right: 12px;
  border: 2px solid ${(p) => (p.bColor ? p.bColor : "#fff")};
`;
const TableLcolumn = styled.div`
  height: 30px;
  width: 250px;
  border-radius: 5px;
  background-color: ${COLOR.white};
  color: ${COLOR.black100};
  padding-left: 10px;
  padding-top: 2px;
  font-size: 14px;
  font-weight: 500;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin-right: 12px;
`;
const TableBlanckHeader = styled.div`
  height: 30px;
  width: 250px;
  margin-right: 12px;
`;
const SearchItemRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  border-bottom: 1.5px dashed #35383d;
  cursor: pointer;
`;
const SearchLItemColumn = styled.div`
  color: ${COLOR.black100};
  font-size: 14px;
  width: 75%;
  padding-top: 5px;
  padding-left: 10px;
  font-weight: 600;
`;
const SearchRItemColumn = styled.div`
  border-left: 1px solid ${COLOR.black100};
  color: ${COLOR.black100};
  font-size: 14px;
  width: 25%;
  padding-top: 5px;
  padding-left: 10px;
  font-weight: 600;
  text-transform: capitalize;
`;
const LoginToBtn = styled.button`
  cursor: pointer;
  background-color: ${COLOR.orange100};
  border: 1px solid ${COLOR.white};
  border-radius: 5px;
  margin-top: 20px;
  width: 200px;
  color: #fff;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const CustomBtn = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${COLOR.black300};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 4px;
  outline: none;

  &:hover {
    color: ${COLOR.green100};
  }
`;

const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;

const Class = styled.button`
  background-color: ${COLOR.black900};
  border: 1px solid ${COLOR.black800};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 6px;
  outline: none;
  padding: 8px 10px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 2px;
`;

const LogoImg = styled.img`
  width: 70%;
  height: auto;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  // border-radius: 12px;
  // background-color: ${COLOR.indigodeep};
  width: 100%;
  // padding: 16px;
  // margin-top: 32px;
`;
const SectionTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ViewBtn = styled.button`
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  color: ${COLOR.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  border-radius: 7px;
  padding: 2px 16px;
  margin-right: 2px;
  margin-left: 2px;
  min-width: 180px;
  height: 30px;
  margin-left: 68.5%;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const CenterItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding-right: 24px;
  margin-bottom: 16px;
  @media (max-width: 992px) {
    margin-top: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
  }
`;

const VideoContainer = styled.div`
  height: auto;
  flex: 1;
  justify-content: center;
`;
const ContainerSecond = styled.div`
  padding: 5px 20px;
`;
const Containermain = styled.div`
`;
const OverViewDist = styled.p`
  font-size:20px;
  font-weight:600;
`;
const CountContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  min-width:100px;
  cursor:pointer;
`;
const CountTitle = styled.span`
  font-size:14px;
  font-weight:600;
  margin-bottom:-15px;
`;
const CountNumber = styled.span`
  font-size:4rem;
  font-weight:700;
   margin-bottom:-15px;
`;
const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
  font-size:12px;
  font-weight:400;
`;
const OverViewValue = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;
const FilterContainer = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;
const BackButtonContainer = styled.div`
  display:flex;
  align-items:center;
`;
const LogoContainer = styled.div`
  margin-top:20px;
  display:flex;
  align-items:center;
justify-content:center;
`;
const RowContainer = styled.div`
    border-left: ${p => p.border && `1px solid ${COLOR.black700}`};
    margin-left: 10px;
    padding-left: 20px;
`;
const UserContainer = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;
const UserDetailsMob = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (max-width: 768px) {
        display: flex;
    }
`;
const UserDetails = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (min-width: 768px) {
        display: flex;
    }
`;
const UserName = styled.span`
color: white;
    display: block;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 0px;
`;
const UserDistrict = styled.span`
    color: white;
    font-size: 10px;
    font-weight: 500;
    line-height: 11px;
    text-align: center;
    margin-top: 5px;
`;

const MobileBar = styled.div`
    /* border-bottom: 1px solid ${COLOR.black800}; */
    display: block;
    height: 60px;
    padding: 8px 16px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    @media (min-width: 992px) {
        display: none;
    }
    @media (min-width: 772px) {
       background-color:black;
    }
    @media (max-width: 568px) {
        padding: 8px 10px;
    }
`;
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    /* color: ${COLOR.black200}; */
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    outline: none;
    padding: 4px 7px;
    color: white;
    text-transform: uppercase;

    &:hover {
        background-color: ${COLOR.green300};
        color: ${COLOR.white}
    }
    @media (min-width:992px) and (max-width:1168px) {
        font-size: 10px;
        padding: 4px 6px;
    }
    @media (max-width: 771px) {
        height: 40px;
    }
    @media (max-width: 568px) {
        padding: 4px 2px;
        font-size: 12px;
        &:hover {
            font-size: 14px;
            background-color: ${COLOR.green300};
            color: ${COLOR.white}
        }
    }
    @media (max-width: 320px) {
        padding: 4px 1px;
        font-size: 10px;
    }
`;
const MobileMenu = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
    line-height: initial;
    @media (min-width: 992px) {
        display: none;
    }
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const ItemTabWraper = ({ label, value, loading, onClick }) => {
    return (
        <ItemsTabs
            style={{
                height: 100,
                width: "18%",
                marginTop: 24,
                cursor: onClick ? "pointer" : "default",
            }}
            onClick={onClick}
        >
            <ItemLabelRow>
                <Itemicon />
                <ItemLabel>{label}</ItemLabel>
            </ItemLabelRow>
            <ItemValueRow>
                {loading ? <Loading /> : <ItemValue>{value}</ItemValue>}
            </ItemValueRow>
        </ItemsTabs>
    );
};
const getBookList = (allBook) => {
    return BOOKLIST.map((e) => find(allBook, { id: e })).filter((e) => e);
};
const BookUsedItem = ({ src, label, value }) => {
    return (
        <BookItemWrapper>
            <BookItem src={src} />
            <BookLabelWrapper>
                <BookWhiteDot />
                <BookItemLabel>
                    {label} ({value})
                </BookItemLabel>
            </BookLabelWrapper>
        </BookItemWrapper>
    );
};
const Sections = ({ label, children, onClose = () => { } }) => {
    return (
        <SectionWrapper>
            <SectionTopRow>
                <SectionTitle>
                    <Itemicon style={{ height: 20, width: 20 }} />
                    <ItemLabel style={{ fontSize: 20 }}>{label}</ItemLabel>
                </SectionTitle>
                <HomeBtnb onClick={onClose}>
                    <Close style={{ color: "#fff", fontWeight: "bold", fontSize: 30 }} />
                </HomeBtnb>
            </SectionTopRow>
            {children}
        </SectionWrapper>
    );
};

const SectionsView = ({
    label,
    children,
    currentView,
    id,
    backButton = () => { },
    onCloseView = () => { },
    onClose = () => { },
    hasBoxButton,
}) => {
    return (
        <SectionWrapper>
            <SectionTopRow>
                <SectionTitle>
                    {/* <Itemicon style={{ height: 20, width: 20 }} /> */}
                    {/* <ItemLabel style={{ fontSize: 20 }}>{label}</ItemLabel> */}
                </SectionTitle>
                {currentView == "boxes" && id != "" && hasBoxButton && (
                    <>
                        <ViewBtn onClick={backButton}>BACK TO BOXES</ViewBtn>
                    </>
                )}
                {id == "" && hasBoxButton && (
                    <>
                        <ViewBtn onClick={onCloseView}>
                            {currentView == "list" ? "VIEW BOXES" : "VIEW LIST"}
                        </ViewBtn>
                    </>
                )}
                {/* <HomeBtnb onClick={onClose}>

          <Home style={{ color: "#fff", fontWeight: "bold", fontSize: 30 }} />
        </HomeBtnb> */}
            </SectionTopRow>
            {children}
        </SectionWrapper>
    );
};


const EditBooksList = ({
    currentOrg,
    districts,
    schools,
    users,
    booksAllList,
}) => {
    const currentUser = useCurrentUser();
    const userClasses = useUserClasses();
    const history = useHistory();
    const dispatch = useDispatch();
    const LICENCEDBOOOK = getBookList(booksAllList);
    const [dataTab, setDataTab] = useState("Home");
    const [distTab, setDistTab] = useState("your_districts");
    const [overViewTab, setOverViewTab] = useState("see_overview");
    const [searchText, setSearchText] = useState("");
    const [userloading, setUserLoading] = useState(true);
    const [currentView, setCurrentView] = useState("boxes");
    const [idSchoolBox, setIdSchoolBox] = useState("");
    const [loginLoader, setLoginLoader] = useState(null)
    const [bookCreateLoader, setBookCreateLoader] = useState(false)
    const cotationRef = useRef(null)
    const [collapsed, setCollapsed] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [district, setDistrict] = useState(null);
    const [districtForm, setDistrictForm] = useState(false);
    const [editDistrict, setEditDistrict] = useState(true);
    const [timmerRunning, ,] = useTimmerRunning();
    const orgPermissions = useCurrentOrgPermission();
    const bookPermissions = get(orgPermissions, "books", [])
    const isTeacherHome = history.location.pathname == "/teacher/home" || history.location.pathname == "/student/home"
    const demoStatus = useDemoStatus()
    const [popupMessage, setPopupMessage] = React.useState('');
    const [selectedDist, setSelectedDist] = useState();
    const [open, setOpen] = React.useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    let isDirector = false;
    if (currentUser?.role === 'admin') {
        isDirector = true;
    }
    if (!cotationRef.current) {
        cotationRef.current = getRandomCotation()
    }
    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push("/");
            }
        }
        fetchData();
    }, []);

    const vocabData = {
        user_id: get(currentUser, "id", ''),
        created_by: 'owe_admin'
    }
    useEffect(() => {
        async function fetchData() {
            // await dispatch(handleListAllBooks());
            // dispatch(handleListVocabulary())
            // dispatch(handleListVocabularyNew(vocabData))
            await dispatch(handleListDistricts());
            dispatch(handleProcessClassTenant())
            dispatch(handleGetCoursesForClever('1'))
            await dispatch(handleListActiveInActiveBooks());
            // await dispatch(handleListInteractionsByType("audio"));
            // await dispatch(handleListInteractionsByType("video"));
            fetchDistrictData();
        }
        fetchData();
    }, []);

    useEffect(() => {
        fetchDistrictData();
    }, [currentOrg]);

    const fetchDistrictData = async () => {
        // try {
        //   setUserLoading(true);
        //   if (!isEmpty(currentOrg)) {
        //     await handleUpdateLicences(currentOrg.id)
        //     await dispatch(handleListOrgsByParent(currentOrg.id))
        //     await dispatch(handleListDistUsers(currentOrg.id));
        //   }
        //   setUserLoading(false);
        // } catch (error) {
        //   setUserLoading(false);
        // }
    };

    let usersStudents = filter(users, (u) => u.role === "student");
    const usersTeachers = filter(users, (u) => u.role === "teacher");
    const teacherByOrg = groupBy(usersTeachers, (u) => u.orgId);
    const studentsByOrg = groupBy(usersStudents, (u) => u.orgId);

    // Array Books
    let listBooks = [];

    for (var book in booksAllList) {
        let isNotAssignable = booksAllList[book].isNotAssignable;
        let _data = {
            id: booksAllList[book].id,
            description: booksAllList[book].description,
            count: booksAllList[book].count,
        };
        if (!isNotAssignable) {
            listBooks.push(_data);
        }
    }

    //  -- Search -------------------
    const [searchedUser, setSearchedUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    //  -- User -----------------------
    const [userData, setUser] = useState(null);

    //  -- Search -----------------------
    const [searResultView, setSearResultView] = useState(false);



    const closeSearchView = () => {
        setSearResultView(false);
    };

    const onSearchItemClick = user => {
        setDataTab("search");
        setSearchText("");
        setSearchedUser([]);
        setSelectedUser(user);
        closeSearchView();

        if (user && user.role == "student") {
            onStudenSelection(user)
        }
    }

    const onStudenSelection = async student => {
    }

    const searchUser = (txt) => {
        const filteredUser = filter(users, (x) =>
            `${x.firstName} ${x.lastName}`.toLowerCase().includes(txt.toLowerCase())
        );
        setSearchedUser(filteredUser);
    };




    const goToClass = async (id, user) => {
        openLoginInNewWindow(user.id)
    };
    const openLoginInNewWindow = async (userId) => {
        setLoginLoader(userId);
        const key = v4()
        await createKeystore(userId, key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoginLoader(null);
    }
    const onAddBook = async () => {
        setBookCreateLoader(true)
        const book = {
            count: false,
            description: '',
            idTypeBook: '1',
            img: '',
            resource: '',
            stateBook: 'active',
            pages: [{
                pageId: 1,
                resource: null,
                description: null,
                NumberMasked: 'I',
                new: true
            }],
            pagesTeacher: [],
            subTopics: [],
            isNotAssignable: false,
            lessonPlan: [],
            teacherEdition: [],
            assignmentPlan: [],
            demoPages: {},
            hyperlinks: [],
            onlyTeacher: false,
            teacherEditionBook: null,
            level: null,
            storyId: null,
            type: null
        }
        const cBook = await dispatch(handleCreateBook(book))
        setBookCreateLoader(false)
        if (has(cBook, "id")) {
            history.push(`/add-book?bookId=${cBook.id}`)
        }
    }
    function navigationToMenu(id) {
        setDistTab(id)
    }
    function navigationToMenus(id) {
        setOverViewTab(id);
    }
    const onAssessments = () => {
        history.push("/exams");
    }
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const schoologyLink = () => {
        history.push('/schoology');
    };
    const classlinkLink = () => {
        history.push('/classlink');
    };
    const cleverLink = () => {
        history.push('/clever');
    };
    const resources = () => {
        history.push('/resources');
    };
    const balconCutural = () => {
        let url = "/balconCultural"
        history.push(url);
    }
    const pd = () => {
        history.push('/pd');
    }
    const editResources = () => {
        history.push('/edit-resources');
    };
    const edit1Wayteacher = () => {
        history.push('/edit-1way-teacher');
    };
    const editSentenceStarter = () => {
        history.push('/sentence-starter');
    };
    const hotToVideos = () => {
        history.push('/edit-how-to-videos');
    };
    const editExam = () => {
        history.push('/edit-exams');
    };

    const onLogOutPress = () => {
        if (!timmerRunning) {
            dispatch(handleLogout());
            history.push('/');
        }
    }

    const invokeFeedback = () => {
        dispatch(handleInvokeFeedbackForm())
    }
    const openAccessDenineMessage = () => {
        setOpen(true)
        setPopupMessage('Restricted access (legend)')
    }
    const resourceNav = () => {
        return (
            <Menu className="resourceNavUpr">
                {/* <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (demoStatus) {
                            openAccessDenineMessage()
                        } else {
                            history.push('/newsletter?id=N823aa3cc210843d3a6469815f2534e5d&page=1')
                        }
                    }}>
                        Worksheets for 1NFORMATE
                    </PaddedBtn>
                </Menu.Item> */}
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (demoStatus) {
                            openAccessDenineMessage()
                        } else {
                            history.push('/graphic_organizers')
                        }
                    }}>
                        Graphic Organizers
                    </PaddedBtn>
                </Menu.Item>
            </Menu>
        );
    };
    const toolbarNav = () => {
        return (<Navigation className="mobileMenu">
            {userClasses.length > 1 && !isDirector && !isTeacherHome ? (
                <NavigationItem>
                    {/* <Dropdown overlay={classNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            Classes
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown> */}
                    <ButtonLink onClick={e => history.push(currentUser?.role === "teacher" ? "/teacher/home" : "/student/home")}>
                        Classes
                    </ButtonLink>
                </NavigationItem>
            ) : (
                <NavigationItem>
                    <ButtonLink onClick={getRoute}>
                        Dashboard
                    </ButtonLink>
                </NavigationItem>
            )}
            {!isTeacherHome && <NavigationItem>
                <Dropdown overlay={eBookNav()} placement="bottomLeft" trigger={['click']}>
                    <ButtonLink onClick={e => e.preventDefault()}>
                        EBook
                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                    </ButtonLink>
                </Dropdown>
            </NavigationItem>}
            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, '1nformate', true) && (
                <NavigationItem>
                    <Dropdown overlay={informateNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            1nformate
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </NavigationItem>
            )}
            {get(orgPermissions, '1WAYRESOURCES', true) && <NavigationItem>
                <ButtonLink onClick={resources}>
                    1WAYRESOURCES
                </ButtonLink>
            </NavigationItem>}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={logs}>
                            Logs
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={schoologyLink}>
                            Schoology
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={classlinkLink}>
                            Classlink
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}
            {currentUser?.role === 'owe_admin' && (
                <>
                    <NavigationItem>
                        <ButtonLink onClick={cleverLink}>
                            Clever
                        </ButtonLink>
                    </NavigationItem>
                </>
            )}
            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, 'balcónCultural', true) && (
                <NavigationItem>
                    <ButtonLink onClick={balconCutural}>
                        Balcón cultural
                    </ButtonLink>
                </NavigationItem>
            )}
            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, 'comunidad', true) && (
                <NavigationItem>
                    <ButtonLink onClick={comunidad}>
                        Comunidad
                    </ButtonLink>
                </NavigationItem>
            )}
            {(currentUser?.role === 'student' || currentUser?.role === 'teacher' || currentUser?.role === 'admin') && get(orgPermissions, 'exams', true) && (
                <NavigationItem>
                    <ButtonLink onClick={onAssessments}>
                        EXAMS
                    </ButtonLink>
                </NavigationItem>
            )}

            {(currentUser?.role === 'teacher') && get(orgPermissions, '1WAYTEACHER', true) && (
                <NavigationItem desktop>
                    <Dropdown overlay={resourceNav()} placement="bottomLeft" trigger={['click']}>
                        <ButtonLink onClick={e => e.preventDefault()}   >
                            1WAYTEACHER
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </NavigationItem>
            )}

            {currentUser?.role === 'teacher' && currentUser?.demo && (
                <NavigationItem >
                    <ButtonLink onClick={pd}>
                        PD
                    </ButtonLink>
                </NavigationItem>
            )}
        </Navigation>)
    };
    const logs = () => {
        history.push('/logs');
    };
    const comunidad = () => {
        let url = "/comunidad"
        history.push(url);
    }
    const getRoute = () => {
        if (isTeacherHome) {
            return;
        }
        if (currentUser?.role === 'admin') {
            history.push('/district');
        } else if (currentUser?.role === 'teacher') {
            history.push('/admin/class?view=home');
            // history.push('/teacher/home');
        } else if (currentUser?.role === 'owe_admin') {
            history.push('/dashboard');
        } else if (currentUser?.role === 'director') {
            history.push('/director');
        } else {
            history.push('/student/class?view=home');
        }
    };

    const informateNav = () => {
        return (
            <Menu>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => history.push('/1nformate')}>
                        1nformate blog
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => history.push('/informate')}>
                        1nformate lessons
                    </PaddedBtn>
                </Menu.Item>
            </Menu>
        );
    };

    const eBookNav = () => {
        const ebooklist = getUserBooksByOrg()
        return (
            <Menu>
                {ebooklist?.length > 0 ? (
                    map(ebooklist, x => {
                        return (
                            <Menu.Item key={x.bookId} style={{ padding: '0px' }}>
                                <PaddedBtn onClick={() => navigateToEbook(x.id)} >
                                    {x.description}
                                </PaddedBtn>
                            </Menu.Item>
                        )
                    })
                ) : (
                    <Menu.Item style={{ padding: '0px' }}>
                        <PaddedBtn>
                            No books
                        </PaddedBtn>
                    </Menu.Item>
                )}
            </Menu>
        )
    };
    const navigateToEbook = (chapter) => {
        const demoPages = get(find(booksAllList, ["id", chapter]), "demoPages")
        if (demoStatus && demoPages) {
            history.push(`/ebook?chapter=${chapter}&page=${demoPages.from}&activity=null&quiz=null`);
        } else {
            history.push(`/ebook?chapter=${chapter}&page=1&activity=null&quiz=null`);
        }
    }
    const substract = (a, b) => {
        let _a = parseInt(a);
        let _b = parseInt(b);
        if (isNaN(_a)) _a = 0;
        if (isNaN(_b)) _b = 0;
        return _a - _b;
    };
    const editBookGroup = () => {
        history.push('/edit-book-grouping')
    }
    const editApExams = () => {
        history.push('/edit-ap-exams')
    }
    const editStories = () => {
        history.push('/edit-story')
    }

    return (
        <SentryWrap>
            <Containermain>
                <Row>
                    <Col>
                        <OverViewDist>
                            Edit Books
                        </OverViewDist>
                    </Col>
                </Row>
                {/* <Row style={{marginTop:30}}>
                    <Col>
                        <Button onClick={editResources}>
                            Edit resources
                        </Button>
                    </Col>
                    <Col style={{marginLeft:5,marginRight:5}}>
                        <Button onClick={editExam}>
                            Edit Exams
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={hotToVideos}>
                            How To Videos
                        </Button>
                    </Col>
                    <Col>
                        <Button style={{ marginLeft: 5, marginRight: 5 }} onClick={() => history.push('/add-book')}>
                            Add Books
                        </Button>
                    </Col>
                </Row> */}
                <Space wrap>
                <Button onClick={edit1Wayteacher}>
                        Edit 1WayTeacher
                    </Button>
                    <Button onClick={editResources}>
                        Edit resources
                    </Button>
                    <Button onClick={editExam}>
                        Edit Exams
                    </Button>
                    <Button onClick={hotToVideos}>
                        How To Videos
                    </Button>
                    <Button onClick={editBookGroup}>
                        Book Grouping
                    </Button>
                    <Button onClick={editApExams}>
                        Edit AP exams
                    </Button>
                    <Button onClick={editStories}>
                        Edit Story
                    </Button>
                    <Button style={{ marginLeft: 5, marginRight: 5 }} onClick={() => history.push('/add-book')}>
                        Add Books
                    </Button>
                    <Button onClick={editSentenceStarter}>
                        Sentence Starter
                    </Button>
                </Space>
                <SectionsView
                    label={""}
                    currentView={currentView}
                    id={"userbox"}
                    backButton={() => { }}
                    onCloseView={() => { }}
                    onClose={() => {
                        setDataTab("");
                    }}
                >
                    <CenterItem>
                        <BooksTable />
                    </CenterItem>
                </SectionsView>
            </Containermain>
        </SentryWrap>
    );
};

//  -- Prop types ---------------------
EditBooksList.propTypes = {};

//  -- Default props ------------------
EditBooksList.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
    activities: state.activities,
    classes: state.classes,
    currentOrg: state.currentOrg,
    districts: state.districts,
    interactions: state.interactions,
    quizzes: state.quizzes,
    schools: state.schools,
    users: state.users,
    booksAllList: state.booksAllList,
});

export default connect(mapStateToProps, null)(EditBooksList);

