//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleInvokeAlert, handleRevokeAlert } from './thunks';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.alertView;

//  -- Examples array selector --------

export const useAlertView = () => {
    const selectedData = useSelector(selector, shallowEqual)
    const dispatch  = useDispatch()
    const invokeAlert = (title,message) =>{
        dispatch(handleInvokeAlert(title,message))
    }
    const revokeAlert = () =>{
        dispatch(handleRevokeAlert())
    }
    return [selectedData,invokeAlert,revokeAlert];
}
