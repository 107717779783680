//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
//  -- Components ---------------------
import { Col, Row , Dropdown , Menu } from 'antd';
import AssessmentCoversCard from 'components/AssessmentCover';
import Section from 'components/Section';
import React, { useEffect  , useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { find, map , get , groupBy  } from "lodash"
import { useCurrentOrgPermission, useOrgTimezone } from 'store/organizations/selectors';
import { useAlertView } from "store/alert/selectors";
//  -- Constants ----------------------
import { useExams } from 'store/exams/selectors';
import COLOR from 'utils/constants/color';
import { useDemoStatus } from "store/users/selectors";
import Loading from "components/Loading";
import { handleListExams } from 'store/exams/thunks';
import { isAfterTime, isBeforTime } from 'utils/methods/math';
import moment from 'moment-timezone';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button } from "@material-ui/core"
//  -- Features -----------------------



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const ListItem = styled.li`
    margin: 0px 4% 32px;
    // width: calc(21%);

    // @media only screen and (min-width: 600px) and (max-width: 800px) {
    //     margin: 0px 16px 32px;
    //     width: calc(50% - 32px);
    // }

    // @media only screen and (max-width: 600px) {
    //     flex-direction: column;
    //     margin: 0px 16px 32px;
    //     width: 100%;
    // }
`
const List = styled.ul`
    height: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content:center;
`;
//   margin-left: 12%;
// width: 75%;
//horizental line with styled-components
const Hr = styled.div`
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 32px;
    width: 100%;
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;

const ExamHighSchool = styled.div`
    color: #AFAFAF;
    font-family: Arial;
    font-size: 27.1px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom:1px solid #AFAFAF;
    padding-bottom:10px;
    padding-top:10px;
`;



//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const noAssignedMsg = "Not Assigned by Teacher"
// const mediumExamMsg="Available by November 1st"
const finalExamMsg = " Available by April 1st"
const AssessmentContainer = ({
    currentUser,
    classActivities,
    classQuizzes
}) => {
    const history = useHistory();
    const exams = useExams()
    const orgTimezone = useOrgTimezone()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [state, setState] = React.useState({
        open: false,
        message: ""
    });
    // const isDemo= currentUser?.demo || false
    const demoStatus = useDemoStatus()
    const isReviewAccount = currentUser?.reviewAccount || false
    const isStudent = currentUser?.role === "student" || false
    const [, invokeAlert, revokeALert] = useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    const [activeSession, setActiveSession] = useState(null);
    const [examGroupData,setExamGroupData] = useState({})
    const [examData, setExamData] = useState([])
    useEffect(() => {
        if (orgPermissions && typeof orgPermissions['exams'] === "boolean") {
            if (!orgPermissions['exams']) {
                invokeAlert()
                setTimeout(() => {
                    history.goBack();
                    revokeALert()
                }, 5000)
            }
        }
    }, [orgPermissions, invokeAlert, revokeALert, history])

    useEffect(() => {
        //sort exam by order
        if (exams) {
            const sortedExams = exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
            const years = new Date().getFullYear();
            const grp = groupBy(sortedExams,"session")
            const upcomming_year = `${years}-${years+1}`;
            const downcomming_year = `${years-1}-${years}`;
            const upcomming = grp[upcomming_year]
            setExamGroupData(grp)
            if(activeSession&& grp[activeSession]){
                setExamData(grp[activeSession])
            }
            else if (upcomming) {
                setActiveSession(upcomming_year)
                setExamData(upcomming)
            } else if (grp[downcomming_year]) {
                setActiveSession(downcomming_year)
                setExamData(grp[downcomming_year])
            }else{
                const keys = Object.keys(grp)[0]
                setActiveSession(keys)
                setExamData(grp[keys])
            }
        }

    }, [exams])

    const loadExams = async () => {
        setLoading(get(exams, "length") == 0)
        await dispatch(handleListExams())
        setLoading(false)
    }
    useEffect(() => {
        loadExams()
    }, [])
    const handleClose = () => {
        setState({ open: false, message: "" });
    };
    const showMsg = (text) => {
        setState({ open: true, message: text });
    };
    const showCopyRight = () => {
        setState({ open: true, message: "Restricted access (legend)" });
    }
    const onCoverCLick = (obj) => {
        const chapter = get(obj,"bookId")

        if (demoStatus || isReviewAccount) {
            showCopyRight()
            return;
        }
        const url = `/ebook?chapter=${chapter}&page=1&activity=null&quiz=null`
        const activitiy = find(classActivities, k => get(k, "data.bookId") == chapter)
        const quiz = find(classQuizzes, k => get(k, "data.bookId") == chapter)
        if(isStudent){
            if (!activitiy && !quiz) {
                showMsg(noAssignedMsg)
                return;
            }else{
                history.push(url); 
            }
        }else{
            const startDate =  get(obj,"startDate")
            const endDate = get(obj,"endDate")
            if (startDate && endDate) {
                if (isAfterTime(startDate, moment(), orgTimezone)) {
                    showMsg(` Available by ${moment(startDate).format("MM/DD/YYYY hh:mm A")}`)
                } else if (isBeforTime(endDate, moment(), orgTimezone)) {
                    showMsg(` Exam is ended on ${moment(endDate).format("MM/DD/YYYY hh:mm A")}`)
                } else {
                    history.push(url);
                }

            } else if (startDate) {
                if (isAfterTime(startDate, moment(), orgTimezone)) {
                    showMsg(` Available by ${moment(startDate).format("MM/DD/YYYY hh:mm A")}`)
                } else {
                    history.push(url);
                }

            } else if (endDate) {
                if (isBeforTime(endDate, moment(), orgTimezone)) {
                    showMsg(` Exam is ended on ${moment(endDate).format("MM/DD/YYYY hh:mm A")}`)
                } else {
                    history.push(url);
                }
            }
            else {
                history.push(url);
            }
        }
    }
    const sortExam = () => {
        if (exams) {
           return exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
        } else {
            return [];
        }
    }

    const onSelectSession = (key) =>{
        setActiveSession(key)
        setExamData(examGroupData[key])
    }
    const menuData = () =>{
        return( <Menu>
           {map(Object.keys(examGroupData), x => {
                    return (
                        <Menu.Item key={x} style={{ padding: '0px' }}>
                          <PaddedBtn  onClick={()=>onSelectSession(x)}>{x}</PaddedBtn> 
                        </Menu.Item>
                    )
                })}
        </Menu>)
    }

    const { open, message } = state;


    const highLevel = examData[0] || [];
    const middleLevel = examData[1] || []

    return (
        <Wrapper onContextMenu={(e) => {
            e.preventDefault();
            showCopyRight()
        }}>
            {loading ? (
                <Loading />
            ) : (<>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        <strong>ALERT:</strong> {message}
                    </Alert>
                </Snackbar>

            <div>
                <div className='topExamSection'><span>EXAMS</span></div>
                <Row gutter={[24, 24]} style={{ marginTop: '3px', marginRight: 0, marginLeft: 0 }}>
                        <Dropdown overlay={menuData()} placement="bottomLeft" trigger={['click']}>
                            <Button onClick={e => e.preventDefault()}  style={{fontSize:"18px"}}>
                                {activeSession ? activeSession : 'sessions'}
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </Button>
                        </Dropdown>
                    </Row>
                {/* <Row style={{ marginTop: '1%', paddingRight: '12px', paddingLeft:'12px'}}>
                    <Col xs={{ span: 12 }} lg={{ span: 12 }} style={{paddingRight:'30px'}}>
                        <ExamHighSchool>
                            <div>HIGH SCHOOL</div>
                        </ExamHighSchool>
                        <Section style={{ fontSize: '18px' }}
                            // description="Exams"
                            // header='Exams'
                            >
                            {map(examData, exm => (
                                <List>
                                    {map(exm.exams, (obj, i) => (
                                        <ListItem key={obj.id}>
                                            <AssessmentCoversCard data={obj} handleClick={() => onCoverCLick(obj)} labelBgColor={COLOR.redishpink}   label={get(exm, "displayName")}  />
                                        </ListItem>
                                    ))}
                                </List>
                            ))}
                        </Section>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 12 }} style={{paddingLeft:'30px'}}>
                        <ExamHighSchool>
                            <div>MIDDLE SCHOOL</div>
                        </ExamHighSchool>
                        <Section style={{ fontSize: '18px' }}
                            // description="Exams"
                            // header='Exams'
                            >
                            {map(examData, exm => (
                                <List>
                                    {map(exm.exams, (obj, i) => (
                                        <ListItem key={obj.id}>
                                            <AssessmentCoversCard data={obj} handleClick={() => onCoverCLick(obj)} labelBgColor={COLOR.redishpink}   label={get(exm, "displayName")}  />
                                        </ListItem>
                                    ))}
                                </List>
                            ))}
                        </Section>
                    </Col>
                </Row> */}

                
                <Row style={{ marginTop: '1%', paddingRight: '12px', paddingLeft:'12px'}}>
                    <Col xs={{ span: 24 }} md={{ span:24 }} lg={{ span: 12 }} className='examHighSchool'>
                        <ExamHighSchool>
                            <div>HIGH SCHOOL</div>
                        </ExamHighSchool>
                        <Section style={{ fontSize: '18px' }}
                            // description="Exams"
                            // header='Exams'
                        >
                            {/* {map(examData, exm => {
                                return ( */}
                                <List>
                                    {map(highLevel?.exams, (obj, i) => (
                                        <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} key={obj.id}>
                                            <ListItem>
                                                <AssessmentCoversCard 
                                                    data={obj} 
                                                    handleClick={() => onCoverCLick(obj)} 
                                                    labelBgColor={COLOR.redishpink} 
                                                    label={get(highLevel, "displayName")} 
                                                />
                                                <div className='examHigMidSchool'><p>{obj.displayName}</p></div>
                                            </ListItem>
                                        </Col>
                                    ))}
                                </List>
                            {/* )
                            }
                            )} */}
                        </Section>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span:24 }} lg={{ span: 12 }} className='examMiddleSchool'>
                        <ExamHighSchool>
                            <div>MIDDLE SCHOOL</div>
                        </ExamHighSchool>
                        <Section style={{ fontSize: '18px' }}>
                            {/* {map(examData, exm => ( */}
                                <List>
                                    {map(middleLevel?.exams, (obj, i) => (
                                        <Col xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} key={obj.id}>
                                            <ListItem>
                                                <AssessmentCoversCard 
                                                    data={obj} 
                                                    handleClick={() => onCoverCLick(obj)} 
                                                    labelBgColor={COLOR.redishpink} 
                                                    label={get(middleLevel, "displayName")} 
                                                />
                                                <div className='examHigMidSchool'><p>{obj.displayName}</p></div>
                                            </ListItem>
                                        </Col>
                                    ))}
                                </List>
                            {/* ))} */}
                        </Section>
                    </Col>
                    <Section style={{ fontSize: '18px', marginLeft:'20px' }} description="Exams"></Section>
                </Row>
         </div>


                {/* <Row style={{ marginTop: '1%' }}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Section style={{ fontSize: '18px' }}
                            description="Resources books"
                            header='Resources'>
                            <List>
                                {map(HIGH_ASSESSMENTS, (obj, i) => (
                                    <ListItem key={obj.key + i}>
                                        <AssessmentCoversCard data={obj} handleClick={() => onCoverCLick(obj)} labelBgColor={COLOR.redishpink} />
                                    </ListItem>
                                ))}
                            </List>
                        </Section>
                    </Col>
                </Row>
                <Hr />
                <Row style={{ marginTop: '1%' }}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <List>
                            {map(MIDDLE_ASSESSMENTS, (obj, i) => (
                                <ListItem key={obj.key + i}>
                                    <AssessmentCoversCard data={obj} handleClick={() => onCoverCLick(obj)} labelBgColor={COLOR.tabActive} />
                                </ListItem>
                            ))}
                        </List>
                    </Col>
                </Row> */}
            </>)}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentContainer.propTypes = {};

//  -- Default props ------------------
AssessmentContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser,
    userBooksList: state.userBooksList,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
});

export default connect(mapStateToProps, null)(AssessmentContainer);
