//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as LikesActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create like --------------------
export const handleCreateLike = (data) => async(dispatch) => {
    await dispatch(LikesActions.createLike());

    try {
        const like = await API.post('likesRDS', `/likes`, {
            body: { ...data }
        });
        await dispatch(LikesActions.createLikeSuccess(like));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(LikesActions.createLikeFail(error));
    }
};

//  -- Delete like --------------------
export const handleDeleteLike = (likeID) => async(dispatch) => {
    await dispatch(LikesActions.deleteLike());

    try {
        await API.del('likesRDS', `/likes/${likeID}`);
        await dispatch(LikesActions.deleteLikeSuccess({ id: likeID }));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(LikesActions.deleteLikeFail(error));
    }
};



//  -- List chapter likes -------------
export const handleListChapterLikes = (chapterID) => async(dispatch) => {
    await dispatch(LikesActions.listChapterLikes());

    try {
        let likes = [];
        const likesRDS = await API.get('likesRDS', `/likesByChapter/${chapterID}`);
        if (Array.isArray(likesRDS) && likesRDS.length > 0) {
            likes = likesRDS
        }

        await dispatch(LikesActions.listChapterLikesSuccess(likes));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(LikesActions.listChapterLikesFail(error));
    }
};




//  -- List user likes ----------------
export const handleListUserLikes = (userID) => async(dispatch) => {
    await dispatch(LikesActions.listUserLikes());

    try {
        let likes = [];
        const likesRDS = await API.get('likesRDS', `/likesByUser/${userID}`);
        if (Array.isArray(likesRDS) && likesRDS.length > 0) {
            likes = likesRDS
        }
        await dispatch(LikesActions.listUserLikesSuccess(likes));
    } catch (error) {
        await dispatch(LikesActions.listUserLikesFail(error));
    }
};
