import queryString from "query-string";
import React from 'react';
import { useHistory } from "react-router-dom";
import CanvasBookMap from "components/admin/bookmaps/canvas"
import CleverBookMap from "components/admin/bookmaps/clever"
import ClasslinkBookMap from "components/admin/bookmaps/classlink"
import GoogleCanvasBookMap from "components/admin/bookmaps/google"
import SchoologyBookMap from "components/admin/bookmaps/schoology";

export default function Bookmaps() {
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const comp = {
        "canvas": <CanvasBookMap />,
        "google": <GoogleCanvasBookMap />,
        "schoology": <SchoologyBookMap />,
        "classlink": <ClasslinkBookMap />,
        "clever": <CleverBookMap />
    }
    if (comp[search.course]) return comp[search.course];
    return (
        <div></div>
    )
}
