//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { combineReducers } from "redux";

//  -- Reducers -----------------------
import { userBooksList } from "store/userBooks/reducers";
import {
    booksAllList,
    bookOrder,
    booksActiveInActive,
} from "store/books/reducers";
import { activities, activity } from "store/activities/reducers";
import { classes, currentClass, allClasses } from "store/classes/reducers";
import { classActivities } from "store/classActivities/reducers";
import { classQuizzes } from "store/classQuizzes/reducers";
import { interaction, interactions } from "store/interactions/reducers";
import { likes } from "store/likes/reducers";
import { bookNotes, classNotes, notes } from "store/notes/reducers";
import {
    currentOrg,
    districts,
    organizations,
    schools,
    orgTeacherData,
    allSchools,
    distDashboardInfo,
    distExamGraphData,
    distBookUsesData,
    distContentExamData
} from "store/organizations/reducers";
import { posts } from "store/posts/reducers";
import { submissions } from "store/submissions/reducers";
import { classQuestions, questions } from "store/questions/reducers";
import { quizzes, quiz } from "store/quizzes/reducers";
import {
    classUsers,
    userClasses,
    usersByClass,
} from "store/userClasses/reducers";
import {
    currentUser,
    users,
    allUser,
    allUserCount,
    distTeacher,
    distStudents,
    profile_config,
    all_district_teacher,
    profile_picture_picker,
} from "store/users/reducers";
import { tipsBook } from "store/tips/reducers";
import { userPreference } from "store/userPreference/reducers";
import { selectedUser } from "store/selectedUser/reducers";
import {
    schoologyAuth,
    schoologyGradeCategory,
    schoologyAssignment,
    schoologyAllClassGradeCategory,
} from "store/schoologyauth/reducers";
import { feedback, feedbackForm } from "store/feedback/reducers";
import { logs } from "store/logs/reducers";
import { bookUnitList } from "store/booksUnits/reducers";
import { multiclassPopupForm } from "store/multiClassStudent/reducers";
import { alertView } from "store/alert/reducers";
import { resources, videos } from "store/resources/reducers";
import { hyperlikModalInfo } from "store/hyperlinkModal/reducers";
import { tenant } from "store/classlink/reducers";
import storage from "redux-persist/lib/storage/session";
import { cleverCourses, cleverUserInfo } from "store/clever/reducers";
import { bookmarks } from "store/bookmark/reducers";
import { servertime } from "store/servertime/reducers";
import {
    chatUser,
    chatMessages,
    socketConnection,
    userChatConfig,
    scheduledMessage,
} from "store/chat/reducers";
import { timmerRunning } from "store/timmer/reducers";
import { exams } from "store/exams/reducers";
import instructions from "store/instructions/reducers";
import { wheelModal, checkListModal, noiseModal, trafficModal } from "store/wheel/reducers";
import { googleAuthKey } from "store/googleClassRoom/reducers";
import { story, standards , literacy_purpose  } from "store/story/reducers";
import { apexams } from "store/apexams/reducers";
import { howToVideos } from "store/howToVideos/reducers";
import { notifications } from "./notification/reducers";
import { canvasAuthDistrict , canvasAssignmentCategory  } from "store/canvas/reducers";
import { welcomeVideos } from "store/welcomeVideo/reducers";
import { licences } from "store/licences/reducers";
import { vocabulary } from './vocabulary/reducers';
import { ideas , ideaModal  } from "./idea/reducers"
import { oneway_teacher_video  } from "./1wayteachervideo/reducers"
import { one_game_video } from "./1GameVideo/reducers"
import {debateModal} from "./OneWayDebate/reducers"
import { class_game_activity } from "./one_game/reducers"
import {oneway_debate} from './debate/reducers'
import { class_user_logs  , messageAlertView } from "./live_logs/reducers";
import { mainbookPageView, ebookGalleryView, sentenceView } from "./main_book_popup/reducers"
import { oneWayWidgets } from './widgets/reducers';
import { superLesson } from "./SuperLesson/reducers";
import { favourite_video } from "./FavouriteVideo/reducers";
import { ebook_highlights } from "./ebook_highlighter/reducers";
import { differentiationLesson } from "./DifferentiationLesson/reducers";
//  ----------------------------------------------------------------------------
//  Root reducer
//  ----------------------------------------------------------------------------
const appReducer = combineReducers({
    activities,
    booksAllList,
    userBooksList,
    activity,
    classes,
    allClasses,
    classActivities,
    classNotes,
    classUsers,
    profile_config,
    profile_picture_picker,
    classQuestions,
    classQuizzes,
    currentClass,
    currentOrg,
    currentUser,
    districts,
    allSchools,
    interaction,
    interactions,
    likes,
    notes,
    organizations,
    posts,
    questions,
    quiz,
    quizzes,
    schools,
    submissions,
    userClasses,
    users,
    allUser,
    tipsBook,
    userPreference,
    selectedUser,
    schoologyAuth,
    feedback,
    feedbackForm,
    logs,
    bookUnitList,
    multiclassPopupForm,
    alertView,
    resources,
    videos,
    orgTeacherData,
    cleverCourses,
    cleverUserInfo,
    tenant,
    bookmarks,
    hyperlikModalInfo,
    servertime,
    chatUser,
    socketConnection,
    chatMessages,
    timmerRunning,
    userChatConfig,
    scheduledMessage,
    exams,
    instructions,
    usersByClass,
    schoologyGradeCategory,
    schoologyAllClassGradeCategory,
    schoologyAssignment,
    wheelModal,
    noiseModal,
    bookOrder,
    googleAuthKey,
    howToVideos,
    canvasAuthDistrict,
    welcomeVideos,
    story,
    standards,
    apexams,
    allUserCount,
    booksActiveInActive,
    licences,
    distTeacher,
    distStudents,
    notifications,
    vocabulary,
    canvasAssignmentCategory,
    literacy_purpose,
    ideas,
    ideaModal,
    oneway_teacher_video,
    one_game_video,
    distDashboardInfo,
    distExamGraphData,
    distBookUsesData,
    distContentExamData,
    debateModal,
    oneway_debate,
    class_game_activity,
    class_user_logs,
    messageAlertView,
    all_district_teacher,
    mainbookPageView,
    ebookGalleryView,
    checkListModal,
    oneWayWidgets,
    trafficModal,
    sentenceView,
    bookNotes,
    superLesson,
    favourite_video,
    ebook_highlights,
    differentiationLesson
});

const rootReducer = (state, action) => {
    if (action.type === "USER_LOGOUT") {
        storage.removeItem("persist:root");
        localStorage.clear();
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};
export default rootReducer;
