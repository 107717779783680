//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Containers ---------------------
import EbookContainer from 'containers/Ebook';
import GraphicOrganizer from 'containers/GrapicOrganizerNew';
import TeacherStrategies from 'containers/TeacherStrategies';
import InformateContainer from 'containers/Informate';
import InstructionsContainer from 'containers/Instructions';
import AssignmentList from 'components/AssignmentLists';
import LoginContainer from 'containers/Login';
import Privacy from 'containers/Privacy/index';
import TermsConditions from 'containers/termCondition';
import PrivacyPolicy from 'containers/PrivacyPolicy';
import StudentPolicy from 'containers/StudentPolicy';
import NewsletterContainer from 'containers/Newsletter';
import NotesContainer from 'containers/Notes';
import QuestionContainer from 'containers/Questions';
import BalconCultural from 'containers/BalconCultural';
import Conectandome from 'containers/Conectandome';
import ConectandomeBook from 'containers/ConectandomeBook';
import Comunidad from 'containers/Comunidad';
import ComunidadBook from 'containers/ComunidadBook';
import AP from 'containers/ap'
import ApBook from 'containers/apBook';
import NotFoundContainer from 'containers/NotFound';
import Pd from 'containers/pd';
import ResourcesContainer from 'containers/Resources';
import AssessmentContainer from "containers/Assessment";
import LessonsContainer from "containers/1WayLession"
import AssessmentAp from "containers/AssessmentAp"
import VideosContainer from 'containers/Videos';
//  -- Features -----------------------
import Wrapper from 'features/Wrapper';
import React, { useEffect } from 'react';
import { Route, Switch, withRouter, Redirect, Prompt, useHistory } from 'react-router-dom';
//  -- Config -------------------------
import { generateRoutes } from 'utils/config/navigation';
import BalconCulturalBook from 'containers/BalconCulturalBook';
import StoryBook from 'containers/StoryBook';
import InformateEbook from './InformateEbook';
import InformateEbookBook from './InformateEbookBook';
import Learn from './learn';
import HowToVideos from './HowtoVideos';
import Logs from './Logs';
import Blog from './Blog';
import VideoBlog from './VideoBlog';
import Details from './details';
import TeachingHeritage from './VideoLinkDetails/TeachingHeritage';
import Differentiation from './VideoLinkDetails/Differentiation';
import ClassPasswords from './VideoLinkDetails/ClassPasswords';
import DataDriven from './VideoLinkDetails/DataDriven';
import HeritageLanguage from './VideoLinkDetails/HeritageLanguage';
import Schoology from "containers/Schoology"
import Classlink from "containers/Classlink"
import CanvasUserView from "containers/CanvasUserView"
import GoogleUserView from "containers/GoogleUserView"
import Clever from "containers/Clever"
import AddDistricts from "containers/AddDistricts";
import AddStandard from "containers/AddStandard";
import AddSchools from "containers/AddSchools";
import AddTeachers from "containers/AddTeachers";
import Settings from "containers/Settings";
import EditBooks from "containers/addBook/editBookList";

import { useCurrentUser } from 'store/users/selectors';
import { get , has } from 'lodash';
import Chat from "containers/chat"
import Notification from "containers/notification";
import { useTimmerRunning } from 'store/timmer/selectors';
import StoryLavel from "containers/StoryLevels"
import GridStoryLevel from "containers/GridStoryLevel"
import HomeScreen from './Home';
import ApGradeBook from "containers/ApGradeBook"
import { useActionPathLog, useActiveClassBrodcastMessage, useActiveClassUrl, useStudentClassPathLogListner } from 'store/live_logs/selectors';
import queryString from 'query-string'
//  ----------------------------------------------------------------------------
//  Routes
//  ----------------------------------------------------------------------------
const Routes = (props) => {
    const state = props.store.getState();
    document.body.style.overflowY = "scroll"
    const currentUser = useCurrentUser()
    const { updateFirebaseOnClose } = useActionPathLog();
    const class_user_logs = useStudentClassPathLogListner()
    const message_listner = useActiveClassBrodcastMessage()
    const activeUrl = useActiveClassUrl()
    const [timmerRunning, ,] = useTimmerRunning()
    const history = useHistory()
    useEffect(() => {
        const handleContextMenu = (event) => {
            if(window.location.origin.includes("localhost")){
                return;
            }
          event.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []);
      useEffect(() => {
        const handleBeforeUnload = (event) => {
            updateFirebaseOnClose(); 
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (activeUrl) {
            const searchParams = queryString.parse(history.location.search);
            if (has(searchParams, "activity") || has(searchParams, "quiz")) {
                if (searchParams.activity == 'null' && searchParams.quiz == 'null') {
                    history.push(activeUrl)
                } else {
                    const finish_later = document.getElementById('finish_later');
                    if (finish_later) {
                        finish_later.click();
                    }
                    setTimeout(() => {
                        history.push(activeUrl)
                    }, 1000)
                }
            } else {
                history.push(activeUrl)
            }
        }
    }, [activeUrl])
    return (
        <Switch>
            <Route exact component={HomeScreen} {...props} path="/" />
            <Route exact component={LoginContainer} {...props} path="/login" />
            <Route exact component={Privacy} {...props} path="/privacy" />
            <Route exact component={PrivacyPolicy} {...props} path="/privacy-policy" />
            <Route exact component={TermsConditions} {...props} path="/terms-conditions" />
            <Route exact component={StudentPolicy} {...props} path="/student-information-privacy-notice" />
            {currentUser && get(currentUser, "id") && get(currentUser, "role") && get(currentUser, "active", true) ? <>
                <Wrapper>
                    <Prompt
                        when={timmerRunning}
                        message={(location) => {
                            return location.pathname == history.location.pathname && location.search.slice(location.search.indexOf("activity")) == history.location.search.slice(history.location.search.indexOf("activity"))
                                ? true
                                : `Timer-based assignments can’t be “finished later”, \n it will be submitted automatically for you if you click on it.`
                        }}
                    >
                    </Prompt>
                    <Route exact component={EbookContainer} {...props} path="/ebook" />
                    <Route exact component={GraphicOrganizer} {...props} path="/graphic_organizers" />
                    <Route exact component={TeacherStrategies} {...props} path="/Strategies" />
                    <Route exact component={NewsletterContainer} {...props} path="/newsletter" />
                    <Route exact component={VideosContainer} {...props} path="/video" />
                    <Route exact component={InstructionsContainer} {...props} path="/instructions" />
                    <Route exact component={NotesContainer} {...props} path="/notes" />
                    <Route exact component={QuestionContainer} {...props} path="/questions" />
                    <Route exact component={InformateContainer} {...props} path="/1nformate" />
                    <Route exact component={Pd} {...props} path="/pd" />
                    <Route exact component={ResourcesContainer} {...props} path="/resources" />
                    <Route exact component={AssessmentContainer} {...props} path="/exams" />
                    <Route exact component={LessonsContainer} {...props} path="/OneWayLessons" />
                    <Route exact component={AssessmentAp} {...props} path="/ap-exams" />
                    <Route exact component={BalconCultural} {...props} path="/balconCultural" />
                    <Route exact component={Conectandome} {...props} path="/Conectandome" />
                    <Route exact component={ConectandomeBook} {...props} path="/ConectandomeBook" />
                    <Route exact component={BalconCulturalBook} {...props} path="/balconCulturalBook" />
                    <Route exact component={StoryBook} {...props} path="/stories" />
                    <Route exact component={Comunidad} {...props} path="/comunidad" />
                    <Route exact component={ComunidadBook} {...props} path="/comunidadBook" />
                    <Route exact component={AP} {...props} path="/ap" />
                    <Route exact component={ApBook} {...props} path="/apBook" />
                    <Route exact component={InformateEbook} {...props} path="/informate" />
                    <Route exact component={InformateEbookBook} {...props} path="/informateBook" />
                    <Route exact component={Learn} {...props} path="/learn" />
                    <Route exact component={HowToVideos} {...props} path="/how-to-videos" />
                    <Route exact component={Logs} {...props} path="/logs" />
                    <Route exact component={Schoology} {...props} path="/schoology" />
                    <Route exact component={Classlink} {...props} path="/classlink" />
                    <Route exact component={CanvasUserView} {...props} path="/canvas" />
                    <Route exact component={GoogleUserView} {...props} path="/google" />
                    <Route exact component={Clever} {...props} path="/clever" />
                    <Route exact component={EditBooks} {...props} path="/editBooks" />
                    <Route exact component={AddDistricts} {...props} path="/addDistrict" />
                    <Route exact component={AddStandard} {...props} path="/addStandard" />
                    <Route exact component={AddSchools} {...props} path="/add-schools" />
                    <Route exact component={AddTeachers} {...props} path="/add-users" />
                    <Route exact component={Settings} {...props} path="/settings" />
                    <Route exact component={Blog} {...props} path="/blog" />
                    <Route exact component={VideoBlog} {...props} path="/video-blog" />
                    <Route exact component={Details} {...props} path="/details" />
                    <Route exact component={TeachingHeritage} {...props} path="/teaching-heritage" />
                    <Route exact component={Differentiation} {...props} path="/differentiation" />
                    <Route exact component={ClassPasswords} {...props} path="/class-passwords" />
                    <Route exact component={DataDriven} {...props} path="/data-driven" />
                    <Route exact component={HeritageLanguage} {...props} path="/heritage-language" />
                    <Route exact component={StoryLavel} {...props} path="/story-levels" />
                    <Route exact component={ApGradeBook} {...props} path="/ap/gradbook" />
                    <Route exact component={GridStoryLevel} {...props} path="/story/gradebook" />
                    <Route exact component={Chat} {...props} path="/chat" />
                    <Route exact component={Notification} {...props} path="/notification" />
                    <Route exact component={AssignmentList} {...props} path="/assignmentList" />
                    {generateRoutes(state.currentUser).map(x => (
                        <Route key={x.path} exact component={x.component} {...props} path={x.path} />
                    ))}

                </Wrapper>
            </> : <Redirect to={"/"} />}
            <Route component={NotFoundContainer} />
        </Switch>
    );
};

export default withRouter(Routes);

