//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { Card, Col, Divider, Input, Modal as ModalSection, Row } from 'antd';
import Loading from 'components/Loading';
//  -- Constants ----------------------
import { filter, find, get, isEmpty, sortBy } from 'lodash';
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleEnableDiableUser, handleListOrgUsers } from "store/users/thunks";
import UserForm from "./User";
const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const StableWrapper = styled.div`
 width: 100%;
  overflow-x: auto;
//   padding:24px;
//   @media (max-width: 992px) {
//     padding:24px 0px !important;
//   }
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const StableWrapperMain = styled.div`
`;

const ViewSectionText = styled.p`
    color:black;
    font-size:17px;
    font-weight:400;
`;
const SectionText = styled.span`
   font-weight:600;
`;
const FilterContainer = styled.span`
        font-size:17px;
        font-weight:500;
        cursor:pointer;
    `;
const ViewDemo = styled.span`
  textAlign: "left";
  color: ${(p) => (p.color)};
`;

const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
  font-size:12px;
  font-weight:600;
`;
const OverViewValue = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const DistrictClassesTable = ({
    users,
    schools,
    classes,
    listBooks,
    allSchools,
    userloading,
    teacherByOrg,
    selectedDist,
    onUserAddOrUpdate = () => null,
    onTabsJump = () => null
}) => {
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [searchUser, setSearchUser] = useState(null);
    const [userForm, setUserForm] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [userData, setUser] = useState(null);
    const currentOrg = useCurrentOrg()
    const [searchText, setSearchText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = React.useState(0);
    const [enableLoader, setEnableLoader] = useState(-1)
    const [alertForAccount, setAlertForAccount] = useState(false)
    const [userToEnable, setuserToEnable] = useState({})
    const [data, setData] = useState({})
    const dispatch = useDispatch();
    const history = useHistory();
    const [modal2Open, setModal2Open] = useState(false);
    const userTeacher = filter(users, obj => obj?.role === 'teacher')
    useEffect(() => {
        if (get(selectedDist, 'id')) {
            setFilteredUsers(sortBy(classes, ["displayName"]))
            setSearchUser(sortBy(classes, ["displayName"]));
        } else {
            setFilteredUsers(sortBy(classes, ["displayName"]))
            setSearchUser(sortBy(classes, ["displayName"]));
        }
    }, [classes])
    const handleSearch = (event) => {
        if (event.target.value && event.target.value.length > 2) {
            var search = event.target.value;
            const filtered = searchUser.filter(x => {
                return (
                    `${x.displayName}`.toLowerCase().includes(search.toLowerCase())
                );
            });

            setFilteredUsers(sortBy(filtered, ["firstName", "lastName"]))
        } else {
            setFilteredUsers(sortBy(searchUser, ["firstName", "lastName"]))
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const enableDisableUser = async (userId, status) => {
        setEnableLoader(userId)
        await handleEnableDiableUser(userId, status)
        await dispatch(handleListOrgUsers(schools));
        setEnableLoader(-1)
        setAlertForAccount(false)
        setuserToEnable({})
    }
    return (
        <>
            <Modal
                open={alertForAccount}
                onClose={() => setAlertForAccount(false)}
            >
                <ModalContent>
                    Are you sure you want to {get(userToEnable, "active", true) ? "disable" : "enable"}  {get(userToEnable, "firstName", '')}  {get(userToEnable, "lastName", '')}
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setAlertForAccount(false)
                                setuserToEnable({})
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginRight: '4px' }}
                            color="secondary"
                            variant="contained"
                            className={`${enableLoader != -1 ? "disabled-button" : ""}`}
                            onClick={() => enableDisableUser(userToEnable.id, !userToEnable.active)}
                        >
                            {enableLoader != -1 ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            {userForm ? (
                <StableWrapper>
                    <UserForm
                        userData={userData}
                        editing={editUser}
                        listBooks={listBooks}
                        teacherByOrg={teacherByOrg}
                        onClose={() => {
                            setEditUser(false);
                            setUserForm(false);
                            setUser(null);
                            onUserAddOrUpdate();
                            setSearchText("");
                        }}
                        schools={schools}
                        currentOrg={currentOrg}
                    />
                </StableWrapper>
            ) : (
                <StableWrapperMain>
                    <Row>
                        <Col xs={24} lg={24}>
                            <ViewSectionText>
                                View <SectionText>Classes</SectionText> records.
                            </ViewSectionText>
                        </Col>
                        <Col xs={24} lg={24}>
                            <FilterContainer className="FilterHeading">
                                <Card
                                    type="inner"
                                    title="Filters"
                                // extra={<a>Clear</a>}
                                >
                                    <Row>
                                        <Col xs={24} lg={24} md={24} className="ColCardPadding">
                                            <Card style={{ width: '100%' }}>
                                                <Input placeholder="Search by name" bordered={false} style={{ height: '32px' }}
                                                    onChange={handleSearch}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </FilterContainer>
                        </Col>
                    </Row>

                    <StableWrapper style={{ marginTop: 30 }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>School Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Code</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Primary Teacher</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Student</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isEmpty(filteredUsers) && (
                                        userloading ? <Loading size={30} /> : (
                                            <TableRow key={'0'} style={{ border: 0 }}>
                                                <TableCell colSpan={6} style={{ textAlign: "center" }}>No results</TableCell>
                                            </TableRow>
                                        )
                                    )}
                                    {(!isEmpty(filteredUsers)) && (
                                        filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            const studentCount = get(row, 'studentCount', '-') ?? '-';
                                            const teacherName = find(userTeacher, obj => obj.id == row?.teacherId);
                                            const schoolName = find(schools, obj => obj.id == row.orgId);
                                            return (
                                                <TableRow key={row.id} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }} onClick={() => {
                                                    row.teacherName = `${get(teacherName, 'firstName', '')} ${get(teacherName, 'lastName', '')}`;
                                                    row.schoolName = get(schoolName, 'displayName', '');
                                                    setData(row)
                                                    setModal2Open(!modal2Open)
                                                }}>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }}>{get(schoolName, 'displayName', '-')}</TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }}>{get(row, 'displayName', '-')} <ViewDemo color={BORDERCOLOR[3]}>{row.archived && "(Archived)"}</ViewDemo></TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }}>{get(row, 'code', '')}</TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }}>{`${get(teacherName, 'firstName', '')} ${get(teacherName, 'lastName', '')}`}</TableCell>
                                                    <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0], cursor: 'pointer' }}>{studentCount ? studentCount : '-'}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {!isEmpty(filteredUsers) && (
                                            <TablePagination
                                                count={filteredUsers.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                backIconButtonProps={{
                                                    color: "secondary"
                                                }}
                                                nextIconButtonProps={{ color: "secondary" }}
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>

                            </Table>
                        </TableContainer>
                    </StableWrapper>
                </StableWrapperMain>
            )}
            <ModalSection
                footer={null}
                title={`${data.displayName} ${data.demo ? '(Demo)' : ''}`}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={'60%'}
                className="FilterHeading"
            >
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Classes Info"
                >
                    <Row>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>School name</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_schools", data)}>{get(data, 'schoolName', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Name</OverViewLabel>
                                <OverViewValue>{get(data, 'displayName', '')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Primary Teacher</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_teachers", data)} >{get(data, 'teacherName', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Code</OverViewLabel>
                                <OverViewValue>{get(data, 'code', '')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col xs={12} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Students</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => {
                                    if (data?.studentCount) {
                                        onTabsJump("see_students", data)
                                    }
                                }}>{data?.studentCount ? get(data, 'studentCount', '-') : '-'}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                </Card>
            </ModalSection>
        </>
    );
};


//  -- Prop types ---------------------
DistrictClassesTable.propTypes = {};

//  -- Default props ------------------
DistrictClassesTable.defaultProps = {};

export default DistrictClassesTable;
