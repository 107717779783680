//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import Loading from 'components/Loading';

//  -- Constants ----------------------
import { filter, find, get, isEmpty, sortBy } from 'lodash';
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { Card, Col, Input, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleGetOrganization, handleListOrgsByParent } from "store/organizations/thunks";
import { handleListDistUsers } from 'store/users/thunks';
import DistrictForm from "./District";
import { useLicences } from 'store/licences/selectors';
const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const StableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const FilterContainer = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;

const StableWrapperMain = styled.div`
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const DistrictTable = ({
    userloading,
    districts,
    allSchools,
    onUpdateOrAdd = () => null,
    onTabsJump = () => null
}) => {
    const dispatch = useDispatch();
    const currentOrg = useCurrentOrg();
    const licencesCount = useLicences();
    const [page, setPage] = React.useState(0);
    const [loader, setLoader] = useState(false);
    const [district, setDistrict] = useState(null);
    const [orgLoader, setOrgLoader] = useState(-1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [enableLoader, setEnableLoader] = useState(-1);
    const [districtForm, setDistrictForm] = useState(false);
    const [editDistrict, setEditDistrict] = useState(false);
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const [integrationFilter, setIntegrationFilter] = useState([]);
    const setFilterdDistrict = (dist) => {
        if (dist) {
            const cOrg = find(dist, d => d.id == get(currentOrg, "id"))
            if (cOrg) {
                const sortedDist = sortBy(filter(dist, d => d.id != get(currentOrg, "id")), ["displayName"])
                const res = [cOrg, ...sortedDist]
                setFilteredDistricts(res)
                setIntegrationFilter(res)
            } else {
                setFilteredDistricts(sortBy(dist, ["displayName"]))
                setIntegrationFilter(sortBy(dist, ["displayName"]))
            }

        } else {
            setFilteredDistricts([])
        }
    }
    useEffect(() => {
        setFilterdDistrict(districts)
    }, [districts])
    const handleSearchDistrict = (event) => {
        if (event.target.value && event.target.value.length > 2) {
            var search = event.target.value;

            const filtered = districts.filter(x => {
                return (
                    `${x.displayName}`.toLowerCase().includes(search.toLowerCase())
                );
            });
            setFilterdDistrict(filtered)
        } else {
            setFilterdDistrict(districts)
        }
    };
    const handleChanges = (value) => {
        switch (value) {
            case 'all':
                setFilteredDistricts(integrationFilter);
                break;
            case 'clever':
                const filterClever = filter(integrationFilter, obj => obj.cleverId)
                setFilteredDistricts(filterClever);
                break;
            case 'classlink':
                const filterClasslink = filter(integrationFilter, obj => obj.classlinkid)
                setFilteredDistricts(filterClasslink);
                break;
            case 'schoology':
                const filterSchoology = filter(integrationFilter, obj => obj.schoolId)
                setFilteredDistricts(filterSchoology);
                break;
            case 'canvas':
                const filterCanvas = filter(integrationFilter, obj => obj.canvas_id)
                setFilteredDistricts(filterCanvas);
                break;
            default:
                break;
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const onDistrictOverview = async (data) => {
        setEnableLoader(data?.id)
        setLoader(true);
        await dispatch(handleListOrgsByParent(data.id))
        await dispatch(handleListDistUsers(data.id));
        onTabsJump('see_overviews', data)
        setLoader(false)
    }

    const checkIntegration = (value) => {
        if (value.cleverId) {
            return 'Clever'
        } else if (value.classlinkid) {
            return 'Classlink'
        } else if (value.canvas_id) {
            return 'Canvas'
        } else if (value.schoolId) {
            return 'Schoology'
        } else {
            return '-'
        }
    }

    const handleCurrentOrgLoader = async (data) => {
        // setEnableLoader(data?.id)
        // setLoader(true);
        if (currentOrg?.id !== data?.id) {
            localStorage.setItem("lastActiveOrgAdmin", data?.id)
            await dispatch(handleGetOrganization(data?.id))
            // setLoader(false);
        }
        // else {
        //     setLoader(false);
        // }
    }
    
    return (
        <>

            {districtForm ? (
                <StableWrapper style={{ padding: 24 }}>
                    <DistrictForm
                        district={district}
                        editing={editDistrict}
                        onClose={() => {
                            setDistrictForm(false);
                            setEditDistrict(false);
                            setDistrict(null);
                            onUpdateOrAdd()
                        }}
                    />
                </StableWrapper>
            ) : (

                <StableWrapperMain>
                    <FilterContainer className="FilterHeading">
                        <Card
                            style={{ marginTop: 16 }}
                            type="inner"
                            title="Filters"
                            // extra={<a>Clear</a>}
                        >
                            <Row>
                                    <Col xs={24} lg={6} md={12} className="ColCardPadding">
                                        <Card style={{ width: '100%' }}>
                                            <Select
                                                bordered={false}
                                                defaultValue="INTEGRATIONS"
                                                style={{ width: '100%', fontSize: 13 }}
                                                onChange={handleChanges}
                                                options={[
                                                    { value: 'all', label: 'All' },
                                                    { value: 'clever', label: 'Clever' },
                                                    { value: 'classlink', label: 'Classlink' },
                                                    { value: 'schoology', label: 'Schoology' },
                                                    { value: 'canvas', label: 'Canvas' }
                                                ]}
                                            />
                                        </Card>
                                    </Col>
                                    <Col xs={24} lg={18} md={12} className="ColCardPadding">
                                        <Card style={{ width: '100%' }}>
                                            <Input placeholder="Search District by name" bordered={false} style={{ height: '32px' }} onChange={handleSearchDistrict} />
                                        </Card>
                                    </Col>

                            </Row>
                        </Card>
                    </FilterContainer>
                    <StableWrapper>


                        <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff", marginTop: 20 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>District</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Launch Date</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Shared Schools</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Integration</TableCell>
                                    {/* <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Active</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(isEmpty(districts)) && (
                                    userloading ? <Loading size={30} /> : (
                                        <TableRow key={'0'} style={{ border: 0 }}>
                                            <TableCell colSpan={6} style={{ textAlign: "center" }}>No results</TableCell>
                                        </TableRow>
                                    )
                                )}
                                {(!isEmpty(filteredDistricts)) && (
                                    filteredDistricts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const date = new Date(row?.startDate)
                                        const options = { day: 'numeric', month: 'short', year: 'numeric' }
                                        const formatDate = date.toLocaleDateString('en-US', options)
                                        const filterSchoolbyParent = filter(allSchools, obj => obj.parentId == row.id).length
                                        const getLicences = find(licencesCount, obj => obj.districtId == row.id && obj.schoolId == null);
                                        return (
                                            <TableRow key={row.id} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }}>
                                                <TableCell style={{ textAlign: "left", cursor: "pointer", display: 'flex', alignItems: "center" }} onClick={() => {
                                                    onDistrictOverview(row);
                                                    handleCurrentOrgLoader(row);
                                                }}> {row?.id === enableLoader && loader && <Loading size={20} />} {row.displayName}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0] }}>{get(getLicences, 'allocatedLicense', '0') || 0}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[1] }}>{row?.startDate ? row?.startDate : '-'}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[2] }}>{filterSchoolbyParent} schools</TableCell>
                                                <TableCell style={{ textAlign: "left", color: 'rgb(200, 109, 152)' }}>{checkIntegration(row)}</TableCell>
                                                {/* <TableCell style={{ textAlign: "center" }}>
                                            <Button onClick={x => handleEditDist(row)} style={{ marginRight: '8px' }} >
                                                <Edit></Edit>
                                            </Button>
                                        </TableCell>
                                        <TableCell style={{ textAlign: "center" }}>
                                            <Button
                                                disabled={currentOrg?.id === row.id}
                                                variant={currentOrg?.id === row.id ? "link" : "contained"}
                                                color="primary"
                                                style={{ fontSize: currentOrg?.id === row.id ? '12px' : '14px' }}
                                                onClick={() => handleCurrentOrgLoader(row.id)}
                                            >
                                                {orgLoader==row.id?<Loading size={'18px'} white  />:(currentOrg?.id === row.id ? 'Active Org' : 'Set Active')}
                                            </Button>
                                        </TableCell> */}
                                            </TableRow>
                                        )
                                    }
                                    )
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {!isEmpty(filteredDistricts) && <TablePagination
                                        count={filteredDistricts.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                    />}
                                </TableRow>
                            </TableFooter>

                        </Table>
                    </StableWrapper>
                </StableWrapperMain>
            )}
        </>
    );
};


//  -- Prop types ---------------------
DistrictTable.propTypes = {};

//  -- Default props ------------------
DistrictTable.defaultProps = {};

export default DistrictTable;
