//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Modal, Row, Select } from 'antd';
import Loading from 'components/Loading';
//  -- Constants ----------------------
import { filter, find, get, isEmpty, sortBy } from 'lodash';
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useLicences } from 'store/licences/selectors';
import { handleListLicences } from 'store/licences/thunks';
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleGetOrganization } from "store/organizations/thunks";
const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const StableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
//   @media (max-width: 992px) {
//     padding:24px 0px;
//   }
`;
const StableWrapperMain = styled.div`
`;

const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
font-size: 12px;
font-weight: 600;
`;
const OverViewValue = styled.span`
font-size: 17px;
font-weight: 500;
cursor: pointer;
`;
const FilterContainer = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;

const ViewDemo = styled.span`
textAlign: "left";
color: ${(p) => (p.color)};
`;
const ProfileContainer = styled.span`

`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const DistrictsTable = ({
    schools,
    districts,
    allSchools,
    userloading,
    onUpdateOrAdd = () => null,
    onTabsJump = () => null,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentOrg = useCurrentOrg();
    const licencesCount = useLicences();
    const [data, setData] = useState("");
    const [page, setPage] = React.useState(0);
    const [orgLoader, setOrgLoader] = useState(-1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [modal2Open, setModal2Open] = useState(false);
    const [districtForm, setDistrictForm] = useState(false);
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const [integrationFilter, setIntegrationFilter] = useState([]);
    const [enableLoader, setEnableLoader] = useState(-1);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        dispatch(handleListLicences())
    }, [])

    const setFilterdDistrict = (dist) => {
        if (dist) {
            const cOrg = find(dist, d => d.id == get(currentOrg, "id"))
            if (cOrg) {
                const sortedDist = sortBy(filter(dist, d => d.id != get(currentOrg, "id")), ["displayName"])
                const res = [cOrg, ...sortedDist]
                setFilteredDistricts(res)
                setIntegrationFilter(res)
            } else {
                setFilteredDistricts(sortBy(dist, ["displayName"]))
                setIntegrationFilter(sortBy(dist, ["displayName"]))
            }

        } else {
            setFilteredDistricts([])
        }
    }
    useEffect(() => {
        setFilterdDistrict(districts)
    }, [districts])
    const handleSearchDistrict = (event) => {
        if (event.target.value && event.target.value.length > 2) {
            var search = event.target.value;

            const filtered = districts.filter(x => {
                return (
                    `${x.displayName}`.toLowerCase().includes(search.toLowerCase())
                );
            });
            setFilterdDistrict(filtered)
        } else {
            setFilterdDistrict(districts)
        }
    };
    const substract = (a, b) => {
        let _a = parseInt(a);
        let _b = parseInt(b);
        if (isNaN(_a)) _a = 0;
        if (isNaN(_b)) _b = 0;
        return _a - _b;
    };
    const handleChange = (value) => {
        switch (value) {
            case 'all':
                setFilteredDistricts(integrationFilter);
                break;
            case 'clever':
                const filterClever = filter(integrationFilter, obj => obj.cleverId)
                setFilteredDistricts(filterClever);
                break;
            case 'classlink':
                const filterClasslink = filter(integrationFilter, obj => obj.classlinkid || obj.classlinkTenantId)
                setFilteredDistricts(filterClasslink);
                break;
            case 'schoology':
                const filterSchoology = filter(integrationFilter, obj => obj.schoolId)
                setFilteredDistricts(filterSchoology);
                break;
            case 'canvas':
                const filterCanvas = filter(integrationFilter, obj => obj.canvas_id)
                setFilteredDistricts(filterCanvas);
                break;
            default:
                break;
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleCurrentOrgLoader = async (data, jumPage) => {
        setEnableLoader(data?.id)
        setLoader(true);
        if (currentOrg?.id !== data?.id) {
            localStorage.setItem("lastActiveOrgAdmin", data?.id)
            await dispatch(handleGetOrganization(data?.id))
            setLoader(false);
        }
        else {
            setLoader(false);
        }
        if (jumPage === 'see_schools') {
            onTabsJump("see_schools", data)
        }
        if (jumPage === 'see_teachers') {
            onTabsJump("see_teachers", data)
        }
        if (jumPage === 'see_students') {
            onTabsJump("see_students", data)
        }
        // setLoader(false);
    }
    const addTeachers = (value) => {
        const editData = {
            userData: data,
            isEdit: true
        }
        const addData = {
            userData: null,
            isEdit: false
        }
        if (value == 'edit') {
            history.push({ pathname: "/addDistrict", state: editData });
        } else {
            history.push({ pathname: "/addDistrict", state: addData });
        }
    }

    const checkIntegration = (value) => {
        if (value.cleverId) {
            return 'Clever'
        } else if (value.classlinkid || value.classlinkTenantId) {
            return 'Classlink'
        } else if (value.canvas_id) {
            return 'Canvas'
        } else if (value.schoolId) {
            return 'Schoology'
        } else {
            return '-'
        }
    }
    return (
        <>
            {districtForm ? (
                <StableWrapper style={{ padding: 24 }}>
                    {/* <DistrictForm
                        district={district}
                        editing={editDistrict}
                        onClose={() => {
                            setDistrictForm(false);
                            setEditDistrict(false);
                            setDistrict(null);
                            onUpdateOrAdd()
                        }}
                    /> */}
                </StableWrapper>
            ) : (
                <StableWrapperMain>
                    <Row style={{ marginBottom: 15 }}>
                        <Col>
                            <Button onClick={() => addTeachers()} style={{ minHeight: 38 }} type="primary">
                                <PlusOutlined /> Add Districts
                            </Button>
                        </Col>
                    </Row>
                    <FilterContainer className="FilterHeading">
                        <Card
                            type="inner"
                            title="Filters"
                        // extra={<a>Clear</a>}
                        >
                            <Row>
                                <Col xs={24} lg={6} md={12} className="ColCardPadding">
                                    <Card style={{ width: '100%' }}>
                                        <Select
                                            bordered={false}
                                            defaultValue="INTEGRATIONS"
                                            style={{ width: '100%', fontSize: 13 }}
                                            onChange={handleChange}
                                            options={[
                                                { value: 'all', label: 'All' },
                                                { value: 'clever', label: 'Clever' },
                                                { value: 'classlink', label: 'Classlink' },
                                                { value: 'schoology', label: 'Schoology' },
                                                { value: 'canvas', label: 'Canvas' }
                                            ]}
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24} lg={18} md={12} className="ColCardPadding">
                                    <Card style={{ width: '100%' }}>
                                        <Input placeholder="Search District by name" bordered={false} style={{ height: '32px' }} onChange={handleSearchDistrict} />
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </FilterContainer>
                    <StableWrapper>

                        <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff", marginTop: 20 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Schools</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Teachers</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Students</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses used</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses available</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Integration</TableCell>
                                    {/* <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Edit</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(isEmpty(filteredDistricts)) && (
                                    userloading ? <Loading size={30} /> : (
                                        <TableRow key={'0'} style={{ border: 0 }}>
                                            <TableCell colSpan={7} style={{ textAlign: "center" }}>No results</TableCell>
                                        </TableRow>
                                    )
                                )}
                                {(!isEmpty(filteredDistricts)) && (
                                    filteredDistricts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const getLicences = find(licencesCount, obj => obj.districtId == row.id && obj.schoolId == null);
                                        const filterSchoolbyParent = filter(allSchools, obj => obj.parentId == row.id).length;
                                        row.countSchool = filterSchoolbyParent;
                                        row.allocated_License = get(getLicences, 'allocatedLicense', '0');
                                        row.used_License = get(getLicences, 'usedLicense', '0');
                                        row.available_Licenses = get(getLicences, 'availableLicenses', '0');
                                        return (
                                            <TableRow key={row.id} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }} onClick={() => setData(row)}>
                                                <TableCell style={{ textAlign: "left", cursor: 'pointer',display: 'flex', alignItems: "center" }} onClick={() => {
                                                    handleCurrentOrgLoader(row)
                                                    setModal2Open(!modal2Open)
                                                }}>{row?.id === enableLoader && loader &&<Loading size={20} />}{row.displayName} <ViewDemo color={BORDERCOLOR[3]}>{row.demo && "(Demo)"}</ViewDemo></TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[2], cursor: 'pointer' }} onClick={() => {
                                                    handleCurrentOrgLoader(row,'see_schools')
                                                    // onTabsJump("see_schools", row);
                                                }}>
                                                    {filterSchoolbyParent} schools
                                                </TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0], cursor: 'pointer' }} onClick={() => {
                                                    handleCurrentOrgLoader(row, 'see_teachers')
                                                    // onTabsJump("see_teachers", row)
                                                }}>
                                                    {get(row, 'teacher_count', 0)}
                                                </TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0], cursor: 'pointer' }} onClick={() => {
                                                    handleCurrentOrgLoader(row,'see_students')
                                                    // onTabsJump("see_students", row)
                                                }}>
                                                    {get(row, 'student_count', 0)}
                                                </TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0] }}>{get(row, 'allocated_License', '0') || 0}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[1] }}>{get(row, 'used_License', '0')}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[2] }}>{get(row, 'available_Licenses', '0')}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: 'rgb(200, 109, 152)' }}>{checkIntegration(row)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {!isEmpty(filteredDistricts) && <TablePagination
                                        count={filteredDistricts.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                    />}
                                </TableRow>
                            </TableFooter>

                        </Table>
                    </StableWrapper>
                </StableWrapperMain>
            )}
            <Modal
                footer={null}
                title={get(data, 'displayName', '')}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={'60%'}
                className="FilterHeading"
            >
                <ProfileContainer>
                    <Button type="primary" ghost onClick={() => addTeachers('edit')}>
                        Edit Profile
                    </Button>
                </ProfileContainer>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Detail View"
                >
                    <Row>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Districts Name</OverViewLabel>
                                <OverViewValue>{get(data, 'displayName', '-')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Licenses</OverViewLabel>
                                <OverViewValue>{get(data, 'allocated_License', '0')}</OverViewValue>
                            </OverViewContainer>

                            <OverViewContainer>
                                <OverViewLabel>Licenses available</OverViewLabel>
                                <OverViewValue>{get(data,'available_Licenses','0')}</OverViewValue>
                            </OverViewContainer>
                            {/* <OverViewContainer>
                                <OverViewLabel>Total Schools</OverViewLabel>
                                <OverViewValue>{get(data, 'teacherCount', '0')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Total Teachers</OverViewLabel>
                                <OverViewValue>{get(data, 'teacherCount', '0')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Total Students</OverViewLabel>
                                <OverViewValue>{get(data, 'studentCount', '0')}</OverViewValue>
                            </OverViewContainer> */}
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Licenses used</OverViewLabel>
                                <OverViewValue>{(get(data, 'used_License','0') )}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Integration</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(200, 109, 152)' }}>{checkIntegration(data)}</OverViewValue>
                            </OverViewContainer>
                            {data?.startDate && (
                                <OverViewContainer>
                                    <OverViewLabel>Start Date</OverViewLabel>
                                    <OverViewValue>{get(data, 'startDate', '')}</OverViewValue>
                                </OverViewContainer>
                            )}
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col xs={12} lg={8}>
                            <OverViewContainer>
                                <OverViewLabel>Schools</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => {
                                    handleCurrentOrgLoader(data,'see_schools')
                                    // onTabsJump("see_schools", data)
                                }}>
                                    {get(data, 'countSchool', '-')}

                                </OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={12} lg={8}>
                            <OverViewContainer>
                                <OverViewLabel>Teachers</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => {
                                    handleCurrentOrgLoader(data,'see_teachers')
                                    // onTabsJump("see_teachers", data)
                                }
                                }>
                                    {get(data, 'teacher_count', '-')}

                                </OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={12} lg={8}>
                            <OverViewContainer>
                                <OverViewLabel>Students</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => {
                                    handleCurrentOrgLoader(data,'see_students');
                                    onTabsJump("see_students", data)
                                }}>

                                    {get(data, 'student_count', '-')}
                                </OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
};


//  -- Prop types ---------------------
DistrictsTable.propTypes = {};

//  -- Default props ------------------
DistrictsTable.defaultProps = {};

export default DistrictsTable;
