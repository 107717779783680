import React, { useEffect, useState } from 'react'
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
import { get, map } from 'lodash';
import styled from 'styled-components';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { ArrowRight, Close, Search, Edit , ArrowForward, Add  } from '@material-ui/icons';
import COLOR from 'utils/constants/color';
import { useDispatch } from 'react-redux';
import { handleListGoogleCourse , handleUpdateGoogleCources  } from 'store/booksUnits/thunks';
import { Col, Row } from 'antd';
import SelectableBook from 'components/SelectableBook';
import { useAllBook } from 'store/books/selectors';
import { useDistricts } from 'store/organizations/selectors';
import { Autocomplete } from '@material-ui/lab';
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:90vh;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalSection =  styled.div`
    background-color: ${COLOR.white};
    display: inline-flex;
    flex-direction: column;
    width:90vw;
    height:100%;
    overflow:auto;
    padding: 16px;
`;
const BookSection = styled.div`
    width: 100%;
`
const CourseUpdate = ({ openEditModal, setOpenEditModal, selectedData , onUpdate}) => {
    const [courseName, setCourseName] = useState(get(selectedData, 'displayName', ''))
    const [courseBooks, setCourseBooks] = useState([])
    const [selectableBooks,setSelectableBooks] = useState([])
    const [orgId,setOrgId] = useState(get(selectedData, 'orgId', ''))
    const [loader, setLoader] = useState(null)
    const bookAllList = useAllBook()
    const all_district = useDistricts()
    useEffect(() => {
        let listBooks = [];

        for (var book in bookAllList) {
            let isNotAssignable = bookAllList[book].isNotAssignable;
            let _data = {
                id: bookAllList[book].id,
                description: bookAllList[book].description,
                count: bookAllList[book].count,
            };
            if (!isNotAssignable) {
                listBooks.push(_data);
            }
        }
        setSelectableBooks(listBooks)
    }, [bookAllList])
    useEffect(() => {
        if (selectedData) {
            setCourseName(get(selectedData, 'displayName', ''))
            const books = get(selectedData, 'books', []) || []
            setOrgId(get(selectedData, 'orgId', ''))
            if(Array.isArray(books)){
                setCourseBooks(books.map(k=>k?.bookId))
            }else{
                setCourseBooks([])
            }
        }
    }, [selectedData])
    const dispatch = useDispatch()
    const mapCourse = () =>{
        return courseBooks.map(id=>{
            const csr = selectableBooks.find(k=>k.id==id)
            if(csr) {
                return {
                    bookId: csr.id,
                    ...csr
                }
            }
            return null
        }).filter(k=>k)
    }
    const onCourseUpdate = async () => {
        const data = {
            id: get(selectedData, "id"),
            displayName: courseName,
            books: mapCourse(),
            orgId: orgId
        }
        setLoader(true)
        await dispatch(handleUpdateGoogleCources(data))
        if(onUpdate){
            onUpdate()
        }
        setLoader(false)
        setOpenEditModal(false)
    }
    return (
        <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
            <ModalContent>
                <Row style={{ justifyContent: "flex-end", width: "100%" }}>
                    <IconButton
                        onClick={e => setOpenEditModal(false)}
                        style={{ padding: 0, marginRight: 10 }}
                    >
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <h2>{selectedData?.id ? "Update Course" : "Add Course"}</h2>
                <Row style={{ width: "100%" }}>
                    <TextField value={courseName}  onChange={(e) => setCourseName(e.target.value)} label="District" variant="outlined" style={{ maxWidth: 400 }} />
                </Row>
                <Row style={{ width: "100%" }}>
                    <FormControl variant="outlined" style={{ width: 400, marginTop: 12 }}>
                        <Autocomplete
                            id="orgId"
                            options={all_district || []}
                            getOptionLabel={(option) => option.displayName || ""}
                            value={all_district.find(district => district.id === orgId) || null}
                            onChange={(event, newValue) => {
                                setOrgId(newValue ? newValue.id : null);
                            }}
                            style={{ height:40 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Organization"
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                </Row>
                <ModalSection>
                    <SelectableBook
                        chapter={selectableBooks}
                        selected={courseBooks}
                        preSelected={[]}
                        onSelect={(item) => {
                            setCourseBooks(item)
                        }} />
                </ModalSection>
                <Row style={{ width: "100%", marginTop: 20 , justifyContent:"flex-end"}}>
                    <Button variant="contained" color="primary" onClick={onCourseUpdate}>{loader?<Loading size={14} white/>:null} {selectedData && selectedData.id ? "Update" : "Create"}</Button>
                </Row>
            </ModalContent>
        </Modal>
    )
}







export default function GoogleCourseMap() {
    const [courseList,setCourseList] = useState([])
    const [loading , setLoading] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const dispatch = useDispatch()
    async function fetchdata() {
        setLoading(true)
        const data = await dispatch(handleListGoogleCourse())
        if (data && data.success) {
            setCourseList(get(data, 'data', []))
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchdata()
    }, [])
    const onEdit = (data) => {
        setSelectedData(data)
        setOpenEditModal(true)
    }
  return (
   <>
        <CourseUpdate openEditModal={openEditModal} selectedData={selectedData} setOpenEditModal={setOpenEditModal} onUpdate={fetchdata}  />
          <Wrapper>
          <Button variant="contained" color="primary" onClick={()=>onEdit({})}><Add/> Add Course</Button>
              <LoadingWrapper loading={loading}>
                  {courseList.length > 0 ? <Table style={{ width: "100%", textAlign: "center" }}>
                      <TableHead>
                          <TableRow>
                              <TableCell style={{ textAlign: "center" }}>District</TableCell>
                              <TableCell style={{ textAlign: "center" }}>Number of books</TableCell>
                              <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {map(courseList, cuser => {
                              return (<>
                                  <TableRow key={cuser.id}>
                                      <TableCell style={{ textAlign: "center" }}>{get(cuser, "displayName", '')}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}>{get(cuser, "books.length", '')}</TableCell>
                                      <TableCell style={{ textAlign: "center" }}><Button style={{ width: 85, height: 35 }} variant="text" onClick={()=>onEdit(cuser)} > <Edit style={{ color: COLOR.green }} /> </Button></TableCell>
                                  </TableRow>
                              </>)
                          })}
                      </TableBody>
                  </Table> : null}
              </LoadingWrapper>
          </Wrapper>
   </>
  )
}
