//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Assignment, Extension, Close } from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import moment from 'moment';
import { lighten } from 'polished';
import { arrayOf, shape } from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Calendarimg, Birdimg } from "utils/assets/images";
import { find, map, get, filter } from "lodash"
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { BookSynoname } from 'utils/config/reference';
import { Tooltip } from 'antd';
import { imageFetch, imageFetchFromPodcast, getBookDisplayName, getAssignmentLable, isVideo } from 'utils/lib/ebookHelper';
import { Modal } from '@material-ui/core';
import { useResources, useVideos } from 'store/resources/selectors';
import Loading from 'components/Loading';
import { Img } from 'react-image';
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import useMobileDeviceDetection from 'containers/chat/hooks';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const AssignmentContainer = styled.div`
    border: 1px solid #F4F5F7;
    height: 100%;
    min-height: 160px;
    padding 8px 4px;
`;

const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ContainerDay = styled.div`
    border-bottom: 4px solid #03989e82;
    border-right: 2px solid #03989e82;
    border-left: 2px solid #03989e82;
    flex: 1;
    margin-bottom: 16px;
    margin-right: 8px;
    min-width: 124px;

    &:last-child {
        margin-right: 0px;
    }
`;

const ControlRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const CustomRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const Date = styled.span`
    color: #fff;
    display: block;
    font-size: 11px;
    line-height: 10px;
    margin-bottom: 6px;
`;

const DayOfWeek = styled.span`
    background-color: #03989e82;
    border-radius: 2px;
    color: ${COLOR.black};
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    padding: 12px 12px;
`;

const Header = styled.span`
    color: ${COLOR.black600};
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
`;

const HeaderBold = styled(Header)`
    color: ${COLOR.black100};
`;

const Item = styled.div`
    align-items: center;
    background-color: ${p => p.deactivated ? COLOR.redishpink : p.disabled ? COLOR.green900 : (p.past ? lighten(0.4, 'red') : COLOR.white)};
    border: 1px dashed ${p => (p.deactivated || p.disabled || p.past) ? COLOR.white : COLOR.black700};
    border-radius: 2px;
    color: ${p => (p.deactivated || p.disabled || p.past) ? COLOR.white : COLOR.black200};
    cursor: ${p => p.past ? 'not-allowed' : 'pointer'};
    display: flex;
    flex-direction: row;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 4px;
    position: relative;
    text-transform: capitalize;
    transition: all 450ms ease;
    width: 100%;

    @media (min-width: 1000px) {
        &:hover {
            background-color: ${p => !p.past && '#f9f9f9'};
            color:${COLOR.green};
            box-shadow: ${p => !p.past && '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'};
        }
    }
`

const Wrapper = styled.div`
    width: 100%;
`;
const CalendarDay = styled.div`
    background-color:${p => p.isToday ? COLOR.orange100 : "#5f4c82"};
    border:3px solid #c5a1cf;
    border-radius: 15px;
    padding: 10px;
    position: relative;
    flex: 1;
    margin-bottom: 16px;
    margin-right: 8px;
    min-width: 124px;
    min-height: 180px;
    &:last-child {
        margin-right: 0px;
    }
`;
const HeaderRow = styled.div`
    flex-direction: row;
    display: flex;
`
const CalendarIcon = styled.div`
    flex: 1;
    background-image: url(${Calendarimg});
    background-repeat: no-repeat;
    background-size: 200% 200%;
    height: 25px;
    width: 30px;
    background-position: center;
`
const Birtdimg = styled.img`
    height: 180px;
    width: 220px;
    position: absolute;
    top: -80px;
    left: -110px;
    z-index: -1;
    user-select: none;
`
const DayTxt = styled.div`
    flex: 6;
    color: white;
    font-size: 20px;
    `;
const ItemTab = styled.button`
    height:100%;
    width:80%;
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    padding:8px;
    cursor: pointer;
`;
const ItemTab2 = styled.button`
    height:100%;
    width:10%;
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    padding:8px;
    cursor: pointer;
`;
const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;
const ImageModal = styled.div`
    left: 50%;
    max-width: 450px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const ModalDescription = styled.div`
    background: #E7E8EA;
    display: flex;
    padding: 5px;
    flex-direction: column;
    position:relative;
`;
const ModelDescRow = styled.div`
  font-size:12px;
  text-align: center;
  font-weight: bold;
`;
const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    color: white;
    z-index: 1;
`;
const AssignmentNameText = styled.span`
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Calendar of events for quick view
//
//  -- Props
//  ...
//
const Calendar = ({
    assignments,
    onSelect,
    booksAllList
}) => {
    const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [selectedDay, setSelectedDay] = useState(moment());
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const weekStart = selectedDay.clone().startOf('isoWeek');
    const [modalOpen, setModalState] = useState(false);
    const [activeAssignment, setActiveAssignment] = useState(null)
    const resources = useResources()
    const videos = useVideos()
    const deviceType = useMobileDeviceDetection();
    const currentClass = useCurrentClass()
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = get(orgPermissions, "books", [])
    const assignmentData = useMemo(() => {
        const archivedBooksSet = new Set();
        const archived_books = get(currentClass, "archived_books", []);
        archived_books.forEach(abk => {
            archivedBooksSet.add(abk.bookId);
            archivedBooksSet.add(abk.chapter);
        });

        return filter(assignments, k => {
            if (archivedBooksSet.has(get(k, "data.bookId")) || archivedBooksSet.has(get(k, "data.chapter"))) {
                return false;
            }

            if (bookPermissions.length === 0) {
                return true;
            }

            const bookaccess = find(bookPermissions, ["bookId", get(k, "data.bookId")]);
            return bookaccess ? bookaccess.available : true;
        });
    }, [assignments, currentClass, bookPermissions]);

    const generateDays = () => {
        const days = [];

        for (let i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days'))
        }

        return days;
    };

    const WEEK = generateDays();

    const selectAssignment = (x) => {
        const id = get(x, "activityId", get(x, "quizId", ""));
        setSelectedAssignment(id);
        onSelect(id);
    };

    const viewInfo = (x) => {
        setActiveAssignment(x)
        setModalState(true)
    }
    const closeViewinfo = () => {
        setModalState(false)
        setActiveAssignment(null)
    }
    const modalSrc = () => {
        return isVideo(get(activeAssignment, "data.page"), resources) ? imageFetchFromPodcast(get(activeAssignment, "data.page"), resources) : imageFetch(booksAllList, get(activeAssignment, "data.chapter"), get(activeAssignment, "data.page"))
    }
    return (
        <Wrapper>
            <Modal
                open={modalOpen}
                onClose={closeViewinfo}
                style={{ height: '100%', width: '100%' }}
            >
                <ImageModal>
                    <ModalDescription>
                        <CloseBtn onClick={closeViewinfo}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 12 }} />
                        </CloseBtn>
                        <ModelDescRow>{getBookDisplayName(booksAllList, get(activeAssignment, "data.chapter"), true, videos, resources)}</ModelDescRow>
                        {/* <ModelDescRow> <strong>Name</strong>:{get(activeAssignment, "displayName", get(activeAssignment, "data.displayName", ""))}</ModelDescRow> */}
                    </ModalDescription>

                    <Img
                        style={{ height: "auto", width: "100%", border: `1px solid ${COLOR.black800}` }}
                        src={modalSrc()}
                        loader={<Loading />}
                    />
                    {/* <Image src={} alt='' /> */}
                </ImageModal>
            </Modal>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <ControlRow>
                        <Header style={{ marginLeft: 30 }}>Week <HeaderBold>{WEEK[0].format('D MMMM')} - {WEEK[6].format('D MMMM')}</HeaderBold></Header>
                        <CustomRow>
                            <ArrowLeftOutlined
                                onClick={() => setSelectedDay(moment(selectedDay).subtract(7, 'days'))}
                                style={{ color: COLOR.black500, cursor: 'pointer', marginLeft: '16px' }}
                            />
                            <ArrowRightOutlined
                                onClick={() => setSelectedDay(moment(selectedDay).add(7, 'days'))}
                                style={{ color: COLOR.black500, cursor: 'pointer', marginLeft: '8px' }}
                            />
                        </CustomRow>
                    </ControlRow>
                </Col>
            </Row>
            <Container style={{ marginTop: 16, flexDirection: deviceType ? 'column' : 'row' }}>
                {WEEK.map((day, i) => {
                    return (
                        <CalendarDay isToday={moment().isSame(day, 'day')} key={day.unix()} style={{ width: deviceType ? '100%' : 'unset' }}>
                            {i == 0 ? <Birtdimg src={Birdimg} /> : null}
                            <Date>{day.format('D MMMM')}</Date>
                            <HeaderRow>
                                <DayTxt>{DAYS[day.day()]}</DayTxt>
                                <CalendarIcon style={{ flex: deviceType ? 'none' : '1', height: deviceType ? 40 : 25, width: deviceType ? 45 : 30 }}></CalendarIcon>
                            </HeaderRow>
                            <AssignmentContainer style={{ paddingLeft: 0, paddingRight: 0, borderWidth: 0, minHeight: 0 }}>
                                {map(assignmentData, x => {
                                    if (!x || x.type === 'game' || x.type === 'debate') return;
                                    const isActive = get(x, "active", true)
                                    if (moment(x.dueDate).format('MDYY') === day.format('MDYY')) {
                                        const id = get(x, "activityId", get(x, "quizId", ""));
                                        return (<Tooltip title={get(x, "displayName", get(x, "data.displayName", ""))} key={id}>
                                            <Item key={x.id} disabled={selectedAssignment === id} deactivated={!isActive} >
                                                <ItemTab disabled={selectedAssignment === id}
                                                    onClick={() => selectAssignment(x)}
                                                >
                                                    {get(x, "data.typeId") === 'activity'
                                                        ? <Extension style={{ height: '18px', marginRight: '4px' }} />
                                                        : <Assignment style={{ height: '18px', marginRight: '4px' }} />
                                                    }
                                                    <AssignmentNameText>
                                                        {getAssignmentLable(x, booksAllList, videos, resources)}
                                                    </AssignmentNameText>
                                                    {!isActive ? "(Deactivated)" : null}
                                                </ItemTab>
                                                <ItemTab2 onClick={() => viewInfo(x)}>
                                                    <InfoCircleFilled />
                                                </ItemTab2>

                                            </Item>
                                        </Tooltip>
                                        );
                                    }
                                })}
                            </AssignmentContainer>
                        </CalendarDay>)
                })}
            </Container>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
Calendar.propTypes = {
    assignments: arrayOf(shape()),
};

//  -- Default props ------------------
Calendar.defaultProps = {
    assignments: [{}],
};

export default Calendar;


// <CheckCircle style={{ height: '18px', marginRight: '4px' }} />