//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { filter } from 'lodash';
import { cloneDeep } from 'lodash';

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const differentiationLessonInitial = []

export const differentiationLesson = (state = differentiationLessonInitial, action) => {

    switch (action.type) {
        case ActionTypes.differentiations.listDifferentionLesson:
            return action.payload;
        case ActionTypes.differentiations.updateDifferentionLesson:
            return state.map(x => { if (x.id === action.payload?.id) { return { ...x, ...action.payload }; } return x; });
        case ActionTypes.differentiations.deleteDifferentionLesson:
            const delv = cloneDeep(state)
            const result = filter(delv,k =>k.id != action.payload.id)
            return result;
        case ActionTypes.differentiations.createDifferentionLesson:
            return action.payload?.data;
        default:
            return state;
    }
}