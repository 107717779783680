import React from 'react';
import color from 'utils/constants/color';

const GaugeMeter = ({ value, width, height , max_value}) => {
    const normalizeValue = (value) => {
        return Math.max(0, Math.min(10, value)); // Ensures value is between 0 and 10
    };

    const calculateRotation = (value) => {
        // Converts value to an angle between -90 (min) and 90 (max) degrees
        return (value / 5) * 180 - 90;
    };

    const normalizedValue = normalizeValue(value);
    const rotation = calculateRotation(normalizedValue);

    return (
        <svg width={width} height={height} viewBox="0 0 100 30" >
            <path d="M 10 40 A 40 40 0 0 1 90 40" fill="none" stroke="#1eaec7" strokeWidth="15" />
            <path d="M 10 40 A 40 40 0 0 1 90 40" fill="none" stroke={max_value && value>max_value ?color.red: '#00d6d3'} strokeWidth="15" strokeDasharray="251" strokeDashoffset={251 * (1 - normalizedValue / 10)} />
            <polygon points="50,40 48,10 50,5 52,10" fill="#020b52" transform={`rotate(${rotation} 50 40)`} />
            {/* Rounded bottom */}
            {/* <line x1="50" y1="40" x2="50" y2="20" transform={`rotate(${rotation} 50 40)`} stroke="#fff" strokeWidth="3" strokeLinecap="round" /> */}
            {/* Sharp top */}
            {/* <line x1="50" y1="20" x2="50" y2="5" transform={`rotate(${rotation} 50 40)`} stroke="#fff" strokeWidth="3" strokeLinecap="butt" /> */}
        </svg>
    );
};

export default GaugeMeter;
