//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, TextField, Input } from '@material-ui/core';
import { Check  , Close} from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Dropdown, Menu, Row } from 'antd';
import Loading from 'components/Loading';
import moment from 'moment';
import { lighten } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//  -- Thunks -------------------------
import { handleUpdateGrade, handleUpdateSubmission } from 'store/submissions/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import { ArrowRight } from "@material-ui/icons";
import AudioRecorder from 'features/AudioRecorder';
import { useHistory } from 'react-router-dom';
import COLOR from 'utils/constants/color';
//  -- Lib ----------------------------
import _, { findIndex, get, has, map, uniqBy, find, filter  } from 'lodash';
import { imageFetch, imageFetchFromPodcast, isVideo } from 'utils/lib/ebookHelper';
import Schoology from 'utils/Schoology';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import GradeDisplay from 'components/GradeDisplay';
import { useSocket } from 'store/chat/selectors';
import { handleCreateNotification } from 'store/notification/thunks';
import { useResources } from 'store/resources/selectors';
import { useCurrentUser } from 'store/users/selectors';
import { debatePoster, MenuIcon } from "utils/assets/images";
import { useCurrentClass } from 'store/classes/selectors';
import { handleUpdateCanvasGrade  } from 'store/canvas/thunks';
import { useClassUsers } from 'store/userClasses/selectors';
import { handleUpdateGradeToGoogleClassRoom } from "store/googleClassRoom/thunks"
import { isBeforTime } from 'utils/methods/math';
const COLORSTATUS = ["", COLOR.greentrue, COLOR.orange100, COLOR.red]
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Container = styled.div`
    align-items: flex-start;
    border-bottom: 1px dashed ${COLOR.black800};
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding-bottom: 24px;
    width: 100%;
`;
const Poligon = styled.div`
    display:flex;
    flex-direction:row;
    clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
    height: 18px;
    width: 18px;
    margin-right: 5px;
    background-color:${p => p.bgColor ? p.bgColor : COLOR.green};
`;
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;
const UserAddignmentLabel = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    height: 40px;
    background: #e7edf2;
    padding: 10px;
    display: flex;
    align-items: center;
`;
const UserSelector = styled(UserAddignmentLabel)`
    font-weight: 400;
    line-height: 18px;
    height: 30px;
    background:${p => p.active ? '#5f4c82' : '#e7edf2'};
    color:${p => p.active ? '#e7edf2' : COLOR.black100};
    box-shadow:  ${p => p.active ? "0px 0px 0px 0px #958585e0, 0px 0px 5px 0px #958585e0" : "0px 0px #fff"};
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    height: 30px;
`;

const UserSelectorList = styled.div`
    max-height: 500px;
    overflow-y: scroll;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px; 
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;

const ImageContiner = styled.div`
    background-color: transparent;
    border: none;
    flex: 1;
    outline: none;
    width: 100%;
`;

const InnerContainer = styled.div`
    margin-left: 8px;
`;

const Notification = styled.div`
    background-color: ${p => p.bgColor ? p.bgColor : lighten(0.45, '#3D5F8F')};
    border: 1px dashed ${p => p.bgColor ? p.color : '#3D5F8F'};
    border-radius: 3px;
    color: ${p => p.color ? p.color : '#3D5F8F'};
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 24px;
    padding: 8px;
`;

const Question = styled.div`
    line-height: 16px;
    margin-bottom: 16px;
`;

const Subtext = styled.span`
    color: ${COLOR.black500};
    display: block;
    font-size: 10px;
    margin: 8px 0px 4px;
    text-transform: uppercase;
`;

const Text = styled.span`
    color: ${COLOR.green100};
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
`;

const Value = styled.span`
    color: ${p => p.color ? p.color : COLOR.black300};
    text-decoration:${p => p.lineThrough ? 'line-through' : 'none'};
    margin-top:${p => p.marginTop ? p.marginTop : 0};
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
`;

const Wrapper = styled.div`
    margin: 0px auto; 
    max-width: 100%;
    padding: 0px;
    width: 100%;
`;
const AnswerWrapper = styled.div`
    display:flex;
    flex-direction:column;
`;
const Rotate = styled.span`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;
const AccordianTitle = styled(Typography)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
`;
const SortingButton = styled.button`
    font-size: 12px;
    border: 1px solid #c3c1c1;
    padding: 2px;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
    color: ${p => p.active ? '#3f53b7' : COLOR.black100};
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    margin: 0px 2px;
`;
const NameText = styled.span`
    margin-right: 6px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const CategoryMenu = styled.div`
    background-color:${COLOR.tabActive};
    border-radius: 0px 12px 12px 12px;
    color: #fff;
    height: 30px;
    padding-left: 20px;
    padding-right: 2px;
    margin-top: 4px;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:70%;
    align-items: center;
    cursor: pointer;

`;
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const MenuIconImg = styled.img`
    margin-left:10px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
    margin-right: 10px;
`;
const MaxPointText = styled.span`
color:${COLOR.gray100}
`;
const PreviewArea = styled.div`
   max-height:60vh;
   overflow: auto;
  ::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;
const GradeEditorInput = withStyles({
    root: {
        border: 'none',
        fontSize: '12px',
        width: '70px',
        background: 'white',
        textAlign: 'center',
        outline: 'none',
    },
    input: {
        textAlign: 'center',
    }
})(Input);


const Accordion = withStyles({
    root: {
        border: '1px solid white',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
const CurrentSubmission = {
    backgroundColor: '#9933ff'
}
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#03989e',
        color: 'white',
        textTransform: 'capitalize',
        borderBottom: '1px solid white',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        margin: 0
    },
}))(MuiAccordionDetails);
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Activity form
//
//  -- Description
//  Create / edit activity questions
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <ActivityForm
//      data={data}
//      handleChange={func}
//      handleSubmit={func}
//  />
//

const hasGradeInA_F = (val) => {
    if (typeof val !== "string" || typeof val === "undefined" || !val) {
        return {
            value: val,
            hasValue: false
        };
    }

    const gradeValues = ['A', 'B', 'C', 'D', 'F'];
    const normalizedVal = val.toUpperCase();

    if (gradeValues.includes(normalizedVal)) {
        return {
            value: normalizedVal,
            hasValue: true
        };
    } else {
        return {
            value: val,
            hasValue: false
        };
    }
}

const StudentReview = ({
    data,
    booksAllList,
    handleClose,
    scale_1_5,
    gradebook_a_f,
    usersSubmissionData
}) => {
    const { socketConnection, reCreateSocket } = useSocket()
    const currentUser = useCurrentUser()
    const inputRef = useRef()
    const inputsRefs = useRef([])
    const feedbackAudioRef = useRef()
    const history = useHistory();
    const dispatch = useDispatch();
    const resources = useResources()
    const [grade, setGrade] = useState('');
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(null);
    const [expanded, setExpanded] = React.useState(null);
    const [audioFeedback, setAudioFeedback] = useState(null);
    const [updateLoading, setUpdateLoading] = useState(null);
    const [pastSubmissions, setPastSubmissions] = useState([])
    const [allFilteredUser, setAllFilteredUser] = useState([])
    const [selectedAssignment, setSelectedAssignment] = useState({})
    const [submissionsData, setSubmissionsData] = useState({})
    const [userSubmissions, setUserSubmissions] = useState(get(data, 'assignments', []));
    // const submissionStatus = useMemo(() => (get(submissionsData, "submissions") == 0 && get(submissionsData, "answers.length", 0) == 0), [submissionsData]);
    const timmerStatus = useMemo(() => get(submissionsData, "timmer", 0) > 0 && get(submissionsData, "complete") && get(submissionsData, "answers.length", 0) <= get(submissionsData, "assignment.questions.length", 0), [submissionsData])
    const submissionStatus = useMemo(() => (get(submissionsData, "submissions") == 0 && get(submissionsData, "answers.length", 0) == 0), [submissionsData]);
    const inProgressSatatus = useMemo(() => (!get(submissionsData, "complete") && get(submissionsData, "retaking") && get(submissionsData, "assignment.questions.length", 0) > get(submissionsData, "assignment.answers.length", 0)), [submissionsData]);
    const assignment_grade = useMemo(()=>getAssignmentGradeValue(submissionsData),[submissionsData])
    const selected_assignment_tbg = assignment_grade === "TBG"
    const current_assignment_returned  = useMemo(()=>isReturned(submissionsData),[submissionsData])

    const [sortBy, setSortBy] = useState({ all: true, missing: null, incomplete: null, excused: null, returned: null })
    const userInfo = get(data, 'user', '');
    const [select,setSelect]=useState("All")
    const currentClass = useCurrentClass();
    const classUsers = useClassUsers()
    const schoologyAuth = useSelector(state => state.schoologyAuth);
    const client = new Schoology(schoologyAuth);
    useEffect(() => {
        setUserSubmissions(get(data, 'assignments', []));
        setAllFilteredUser(get(data, 'assignments', []))
    }, [data])

    useEffect(() => {
        // if (!selectedUser) {
        //     setSelectedUser(get(data, "user"))
        // }
        setSelectedAssignment(userSubmissions[0])
    }, [data]);

    useEffect(() => {
        let submissionData = get(selectedAssignment, "submissions", {})
        const grade_type = get(selectedAssignment, "grade_type")
        const isPointSystem = grade_type === "points"
        const face_grade  = submissionData.face_grade?submissionData.face_grade:0
        setSubmissionsData(submissionData)
        const grade = getGradeval(submissionData)
        setPastSubmissions(getpastSubmissions(submissionData))
        const is_game = get(submissionData, "assignment.typeId", '') === "game"
        const is_debate = get(submissionData, "assignment.typeId", '') === "debate"
        const isCompleteTbg = filter(get(submissionData, "assignment.questions", []), (e) => e.type != "multipleChoice").length === get(submissionData, "assignment.questions.length", 0)
        let expected_grade = isPointSystem ? face_grade : grade;
        const has_not_graded = (isCompleteTbg && !is_game && !is_debate) && submissionData?.manualGrading
        expected_grade = expected_grade>=0? expected_grade:''
        const grd = has_not_graded || !submissionData || !submissionData.id?'':expected_grade
        setGrade(grd);
        setFeedback(submissionData?.feedback || '');
        setAudioFeedback(submissionData?.audioFeedback || null);
        if (feedbackAudioRef.current) {
            feedbackAudioRef.current.reset()
            feedbackAudioRef.current.setAudio(submissionData?.audioFeedback)
        }
        setExpanded(get(submissionData, 'id'));
    }, [selectedAssignment])

    function getGradeval  (dt)  {
        let grade = get(dt, "grade", 0);
        if (scale_1_5) {
            if (!isNaN(grade)) {
                let _g = grade <= 0 ? 0 : _.floor((grade - 1) / 20)
                grade = (_g > 4 ? 4 : Math.floor(_g)).toString();
            }
        }
        else if (gradebook_a_f) {
            if (grade !== "NaN") {
                const g = parseInt(grade)
                const isTemp = get(dt, "isTemp")
                const isGraded = get(dt, "graded")

                if ((isGraded && isTemp) || !isGraded) return '-'
                if (g < 20 && g >= 0) {
                    grade = 'F';
                }
                else if (g < 40 && g >= 20) {
                    grade = 'D';
                }
                else if (g < 60 && g >= 40) {
                    grade = 'C';
                }
                else if (g < 80 && g >= 60) {
                    grade = 'B';
                }
                else if (g < 100 && g >= 80) {
                    grade = 'A';
                } else {
                    grade = '-'
                }
            }
            else {
                grade = "-";
            }
        }
        return grade;
    }
    const getpastSubmissions = (dt) => {
        return uniqBy(get(dt, "pastSubmissions", []), "id").sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt))
    }
    function  isReturned (submission)  {
        return  submission && Object.keys(submission || {}).length > 0 && parseInt(get(submission, "submissions")) == (parseInt(get(submission, "returned", 0)) - 1)
    }
    const getStatus = (dt) => {
        const exception = get(dt, "exceptionStatus");
        const createAt = get(dt, "createdAt")
        const complete = get(dt, "complete")
        const isTemp = get(dt, "isTemp")
        const isProgress = (!get(dt, "complete") && get(dt, "retaking") && get(dt, "assignment.questions.length", 0) > get(dt, "assignment.answers.length", 0));
        const isGradedByTeacher = get(dt, "submissions") == 0 && get(dt, "answers.length", 0) == 0 && get(dt, "pastSubmissions.length", 0) == 0
        const grade = getGradeval(dt)
        if (exception > 0) {
            if (exception == 1) {
                return <span>{get(currentUser, "schoologyid") ? "Exempt" : "Excused"}</span>
            } else if (exception == 2) {
                return <span> Incomplete</span>
            } else if (exception == 3) {
                // return <span style={{ color: COLORSTATUS[exception] }}> Missing</span>
                return <span> Missing</span>
            } else if (exception == 5) {
                // return <span style={{ color: COLORSTATUS[exception] }}> Missing</span>
                return <span> Absent</span>
            } else if (exception == 6) {
                // return <span style={{ color: COLORSTATUS[exception] }}> Missing</span>
                return <span> Late</span>
            }
        } else if (isGradedByTeacher && !isTemp && grade > 0) {
            return "Graded Without Submission"
        }
        else if (isProgress) {
            return "In progress"
        }
        else if (complete && createAt) {
            return `Completed on ${moment(parseInt(createAt)).format('MMM, DD YYYY')}`;
        } else if (createAt && !isTemp) {
            return `${moment(parseInt(createAt)).format('MMM, DD YYYY')}`;
        } else {
            return "Not submitted."
        }
    }
    const isDueDatePassed = (assignment) => {
        if (!assignment) return false;
        const classConfig = find(assignment.classes, ['id', currentClass?.id]);
        const diffConfig = get(classConfig, 'diff');
        const isDiffStudent = find(get(diffConfig, 'student'), ['id', userInfo?.id]);
        if (isDiffStudent) {
            return isBeforTime(diffConfig.dueDateDif);
        } else {
            return assignment.lateDate ? isBeforTime(assignment.lateDate) : isBeforTime(assignment.dueDate);
        }
    };
    const getCompleteInclompleteMark = (isComplete,isInComplete,isActive,assignment)=>{
        const isPastDue = isDueDatePassed(assignment)
        if(isComplete){
          return <Check style={{ color:  isActive?COLOR.white : COLOR.green100, fontSize: 18 }} />
        }else if(isInComplete || isPastDue ){
          return <Close style={{ color:  isActive?COLOR.white : COLOR.red, fontSize: 18 }} />
        }else{
            return <span style={{ color: isActive ? COLOR.white : COLOR.gray100, fontSize: 18 }}  >-</span>
        }
  }
    function getAssignmentGradeValue (dt,assignment) {
    const questions = get(dt, "assignment.questions", []);
    const isTbg = questions.some(e => e.type !== "multipleChoice");
    const tbgTxt = isTbg ? "TBG" : "-";
    const manualGrading = get(dt, "manualGrading", false);
    const retaking = get(dt, "retaking", false);
    const isTemp = get(dt, "isTemp");
    const grade = getGradeval(dt);
    const face_grade = get(dt,"face_grade",0)
    const grade_type = assignment?get(assignment,"grade_type"):get(selectedAssignment,"grade_type")
    // console.log(dt,"**dt",assignment,face_grade,selectedAssignment,grade_type)
        let val = grade;
        if (hasGradeInA_F(grade).hasValue) {
            val = grade;
        } else if (isTemp || (retaking && grade === 0)) {
            val = "-";
        } else if (isTbg && manualGrading) {
            val = tbgTxt;
        } else if (grade >= 0) {
            val = grade;
        } else {
            val = "-";
        }
        if(grade_type === "points"){
            return (typeof val === "number" || (typeof val === "string" && parseInt(val) >= 0)) && (typeof face_grade === "number" || (typeof face_grade === "string" && parseInt(face_grade) >= 0)) ? face_grade : val
        }
        return val;
    }
    const setData = (dt) => {
        setSelectedAssignment(dt)
    }

    const filterStudentList = (key) => {
        setSelect(key)
        let val = null;
        switch (key) {
            case "All":
                setUserSubmissions(allFilteredUser)
                break;
            case "Missing":
                val = filter(allFilteredUser, obj => obj.submissions?.exceptionStatus == 3);
                setUserSubmissions(val)
                break;
            case "Incomplete":
                val = filter(allFilteredUser, obj => obj.submissions?.exceptionStatus == 2);
                setUserSubmissions(val)
                break;
            case "Excused":
                val = filter(allFilteredUser, obj => obj.submissions?.exceptionStatus == 1);
                setUserSubmissions(val)
                break;
            case "Absent":
                val = filter(allFilteredUser, obj => obj.submissions?.exceptionStatus == 5);
                setUserSubmissions(val)
                break;
            case "Late":
                val = filter(allFilteredUser, obj => obj.submissions?.exceptionStatus == 6);
                setUserSubmissions(val)
                break;
            case "Returned":
                val = filter(allFilteredUser, obj => (obj?.returned > 0 && obj?.submissions == (obj?.returned - 1)));
                setUserSubmissions(val)
                break;
            default:
                break;
        }
    }
    const handleFeedback = (e) => {
        setFeedback(e.target.value);
    };
    const onBlurInput = () => {
        document.activeElement.blur();
    }
    const handleKeyDown = useCallback((e) => {
        const activeIndex = findIndex(userSubmissions, e => get(selectedAssignment, "id") == get(e, "id"))
        const hasref = inputsRefs.current.find(e => e == document.activeElement)
        if (activeIndex < 0 || !userSubmissions) return;
        const { key } = e;
        switch (key) {
            case "ArrowUp":
            case 38:
                if (activeIndex > 0 && userSubmissions[activeIndex - 1]) {
                    setData(userSubmissions[activeIndex - 1])
                    onBlurInput()
                }

                break;
            case "ArrowDown":
            case 40:
                if (activeIndex < userSubmissions.length && userSubmissions[activeIndex + 1]) {
                    setData(userSubmissions[activeIndex + 1])
                    onBlurInput()
                }
                break;
            case "Enter":
            case 13:
                if (!hasref) {
                    if (inputsRefs.current[activeIndex]) {
                        inputsRefs.current[activeIndex].focus();
                        inputsRefs.current[activeIndex].select();
                    }
                } else {
                    if (inputRef.current === document.activeElement) {
                        return false;
                    }
                    onBlurInput()
                    if (activeIndex < userSubmissions.length && userSubmissions[activeIndex + 1]) {
                        setData(userSubmissions[activeIndex + 1])
                    }
                }
            default:
                break;
        }
    }, [userSubmissions, selectedAssignment])
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };

    }, [handleKeyDown]);
    const handleFocus = (event) => event.target.select();
    const handleSubmit = async (e, audioFeedbackUrl, updateAndClose, v, feedBackText , assignment) => {
        const grade_type = get(assignment, "grade_type")
        const max_point = assignment?.max_point && !isNaN(assignment?.max_point) ? Number.parseInt(assignment.max_point, 10) : 100
        const isPointSystem = grade_type === "points"

        if (e) {
            e.preventDefault();
        }   
        const has_feedback =  audioFeedbackUrl || feedBackText
        const val =  v ? v : grade;
        if(!val&&val!=0&&!has_feedback)return;
        let tempGrade = v ? v : grade;
        let tempData = { ...submissionsData }
        const notificationData = {
            classId: get(currentClass, 'id', ''),
            displayName: get(assignment, 'displayName', ''),
            userId: get(currentUser, 'id', ''),
            modified: new Date(),
            data: {
                chapter: get(tempData, 'assignment.chapter', ''),
                page: get(tempData, 'assignment.page', ''),
                id: get(tempData, 'assignment.id', ''),
            },
            message: `Your Teacher Graded this Assignment`,
            createdBy: get(tempData, 'userId', ''),
            type: 'update_grade',
            seen: true
        }
        // if ((tempGrade == submissionsData?.grade&&!get(submissionsData,"manualGrading",true))&& !audioFeedbackUrl && !feedBackText) {
        if (((tempGrade == submissionsData?.grade&&!get(submissionsData,"manualGrading",true))&& !audioFeedbackUrl && !feedBackText)||(submissionsData.isTemp&&tempGrade==0)) {
            return;
        }
        setLoading(updateAndClose ? "uc" : "u");
        sendNotifications(notificationData);
        if (has(assignment, "id") || selectedAssignment) {
            setUpdateLoading(assignment.id || selectedAssignment.id)
        }
        if(isPointSystem){
            const per = (val / max_point ) * 100;
            tempGrade=per;
        }
        updateGradeToCanvas(isPointSystem ? val  : tempGrade)
        updateGradetoSchoology(isPointSystem ? val : tempGrade);
        updateGradetoGoogleClassRoom(isPointSystem ? val : tempGrade);
        if (!submissionsData || !submissionsData.id) {
            tempData["grade"] = tempGrade;
            tempData["feedback"] = feedBackText ? feedBackText : feedback;
            tempData["audioFeedback"] = audioFeedbackUrl ? audioFeedbackUrl : audioFeedback;
            tempData["complete"] = false;
            tempData["answers"] = [];
            tempData["graded"] = true;
            tempData["submissions"] = 1;
        }
        tempData.face_grade = val;
        let submissions = null;
        if (get(submissionsData, "id")) {
            const dt = {
                id: submissionsData.id,
                grade: tempGrade,
                audioFeedback: audioFeedbackUrl ? audioFeedbackUrl : audioFeedback,
                feedback: feedBackText ? feedBackText : feedback,
                face_grade:val,
                manualGrading: false
            }
            submissions = await dispatch(handleUpdateGrade(dt))
        } else {
            submissions = await (dispatch(handleUpdateSubmission(get(submissionsData, "id"), {
                answers: submissionsData.answers,
                complete: submissionsData.complete,
                grade: tempGrade,
                submissions: submissionsData.submissions,
                retaking: false,
                graded: true,
                feedback: feedBackText ? feedBackText : feedback,
                audioFeedback: audioFeedbackUrl ? audioFeedbackUrl : audioFeedback,
                manualGrading: audioFeedbackUrl ? submissionsData.manualGrading : false,
                face_grade:val
            }, tempData)))
        }
        setLoading(null);
        setUpdateLoading(null);
        if (submissions) {
            const updateStudentData = map(userSubmissions, k => {
                if (submissions && submissions.assignmentId == get(k, "data.id")) {
                    return ({ ...k, submissions, isTemp: false })
                } else {
                    return k;
                }
            })
            const allFilteredUserData = map(allFilteredUser, k => {
                if (submissions && submissions.assignmentId == get(k,"data.id")) {
                    return ({ ...k, submissions, isTemp: false })
                } else {
                    return k;
                }
            })
            setAllFilteredUser(allFilteredUserData)
            setUserSubmissions(updateStudentData)
        }
    }

    const sendNotifications = async (notificationData) => {
        try {
            const updateNotification = await dispatch(handleCreateNotification(notificationData))
            const messageCreate = {
                ...updateNotification,
                senderId: get(currentUser, 'id', ''),
                receiverId: get(notificationData, 'createdBy', ''),
                // classId: get(currentClass, 'id', ''),
                code: 'system_notification'
            }
            invokSend(messageCreate)
        } catch (error) {
            console.log(error, "error")
        }
    }

    const invokSend = (data) => {
        let socket = socketConnection ?? '';
        if (socket) {
            try {
                if (socket.readyState == 3) {
                    reCreateSocket()
                    setTimeout(() => {
                        socket.send(JSON.stringify(data))
                    }, 2000)
                } else {
                    socket.send(JSON.stringify(data))
                }
            } catch (error) {
                console.log(error, "error")
            }
        }
    }

    const handleChange = (e) => {
        let _value = e.target.value;
        if(_value === '-') return;
        const grade_type = get(selectedAssignment, "grade_type")
        const max_point =  get(selectedAssignment, "max_point")>=0? get(selectedAssignment, "max_point",100):100
        const isPointSystem = grade_type === "points"
        const af = hasGradeInA_F(_value)
        const isAf = af.hasValue && !!gradebook_a_f;
        const isScale = scale_1_5 && _value <= 4 && _value >= 0
        const isAv = !scale_1_5 && !gradebook_a_f && _value <= 100 && _value >= 0
        if(isPointSystem){
            if(_value>=0&&_value<=max_point){
             setGrade(_value);
            }
         }
        else if (isScale || isAf || isAv || _value === "")
            setGrade(_value);
        //    onChangeUpdate(_value)
    };
    const updateGradeToCanvas = async (_val) => {
        if (currentUser && currentUser.canvas_id && currentClass.canvas_id) {
            const assignment = selectedAssignment;
            let assignmentId = assignment?.canvas_id
            if(assignmentId){
                await handleUpdateCanvasGrade(currentUser.canvas_id,{
                    "canvas_assignment_id": assignmentId,
                    "course_id":currentClass.canvas_course_id,
                    "canvas_user_id":get(find(classUsers,["id",get(userInfo,"id")]),"canvas_id"),
                    "grade": _val
                },currentUser.canvas_auth_id)
            }
        }
    }
    const updateGradetoGoogleClassRoom = async(_val) => {
        if (currentClass && currentClass.googleId) {
            try {
                const assignmentId = get(selectedAssignment,"googleId");
                const studentGoogleId = get(find(classUsers,["id",get(userInfo,"id")]),"googleId")
                if(assignmentId){
                    await handleUpdateGradeToGoogleClassRoom(currentUser.googleId,studentGoogleId,currentClass.googleId,assignmentId,_val)
                }
            
            } catch (error) {
                console.log(error, "issue in update grade to classroom");
            }
        }
    }
    const updateGradetoSchoology = (_val) => {
        const userInfoData = find(classUsers,["id",get(userInfo,"id")]);
        const schoologyid =  get(userInfoData,"schoologyid");
        const sAssignmentId = get(selectedAssignment,"sAssignmentId");
        const classSectionId = get(currentClass,"sectionId");
        if (schoologyid && sAssignmentId && classSectionId) {
            try {
                client.getEnrollmentsBySectionId(classSectionId).then(async res => {
                    if (res && res.enrollment) {
                        let userEnrollment = find(res.enrollment, { uid: schoologyid });
                        if (userEnrollment) {
                            client.getSectionAssignmentById(classSectionId, sAssignmentId).then(res => {
                                if (res && res.id) {
                                    let _data = {
                                        "grades": {
                                            "grade": [
                                                {
                                                    "type": "assignment",
                                                    "assignment_id": sAssignmentId,
                                                    "enrollment_id": userEnrollment.id,
                                                    "grade": _val,
                                                    "comment": "grade updated from lausd-grade-book"
                                                }

                                            ]
                                        }

                                    }
                                    client.updateGrades(classSectionId, _data).then(res => {
                                        console.log("updated grade");
                                    }).catch(err => {
                                        console.log(err);
                                    })
                                }

                            }).catch(err => { })
                        }
                    }
                })
            } catch (error) {
                console.log(error, "issue in update grade");
            }
        }
    }
    const handleChangeExpend = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : null);
    };

    const getHigestMark = (assign) => {
        if (!assign) return ({ isHighest: false, val: '', progress: false })
        const assigneGrade = get(assign, "grade", 0)
        const submissionGrade = get(submissionsData, "grade", 0)
        const inProgress = (!get(assign, "complete") && get(assign, "retaking") && get(submissionsData, "assignment.questions.length", 0) > get(assign, "assignment.answers.length", 0))
        const assignVal = getAssignmentGradeValue(assign)
        let highest = assigneGrade;
        map(pastSubmissions, pt => {
            if ((get(pt, "grade", 0) > highest)) {
                let val = getAssignmentGradeValue(pt)
                if (val !== "TBG") {
                    highest = get(pt, "grade", 0);
                }
            }
        })
        if ((submissionGrade > highest) && !inProgress) {
            if (assignVal !== "TBG") {
                highest = submissionGrade;
            }
        }
        if ((highest > get(assign, "grade", 0)) || inProgress) {
            return ({ isHighest: false, val: getAssignmentGradeValue(assign), progress: get(assign, "id") == get(submissionsData, "id") && inProgress })
        } else {
            let val = getAssignmentGradeValue(assign)
            return ({ isHighest: val !== "TBG" && val != 0, val: val, progress: false })
        }
    }

    const pastSubmissionView = useCallback(() => {
        if (pastSubmissions && pastSubmissions.length > 0) {
            const isunGraded = get(selectedAssignment,"ungraded");
            return (<div>
                {map(pastSubmissions, (pt, i) => {
                    const hmark = getHigestMark(pt)
                    let mark = hmark.isHighest ? `Highest(${hmark.val})` : `(${hmark.progress ? "in-progress" : hmark.val})`;
                    mark= isunGraded ? "ungraded" : mark;
                    const isreturned = get(pt, "submissions") == parseInt(get(submissionsData, "returned")) - 1;
                    return (<Accordion square expanded={expanded === pt.id} onChange={handleChangeExpend(pt.id)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle><span>{"Submission-" + pt.submissions} {mark} {isreturned ? `(Returned)` : null}</span> <Rotate open={expanded === pt.id}><ArrowRight /></Rotate> </AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GradeDisplay
                                key={pt?.id}
                                answers={get(pt, "answers")}
                                feedback={get(pt, "feedback")}
                                audioFeedback={get(pt, "audioFeedback")}
                                ai_suggestion={get(pt, "ai_suggestion")}
                                ai_feedback={get(pt, "ai_feedback")}
                                grade={pt?.grade}
                                name={get(submissionsData, "displayName", get(submissionsData, "assignment.displayName"))}
                                questions={get(submissionsData, "assignment.questions", [])}
                                type={get(submissionsData, "assignmentType") == "quiz" ? "quiz" : null}
                                isExpended
                                isLate={false}
                                hasFeedback={true &&(get(pt, "feedback")|| get(pt, "audioFeedback"))}
                                isUngraded={isunGraded}
                                assignment={pt}
                            />
                        </AccordionDetails>
                    </Accordion>
                    )
                })}
            </div>)

        }
        else {
            return null;
        }

    }, [pastSubmissions, expanded])

    const categories = ["All", "Missing", "Incomplete", "Excused", "Returned"];
    const categoryNav = () => {
        return (
            <Menu>
                {map(categories, (ct) => (
                    <Menu.Item key={ct} style={{ padding: "0px" }}>
                        <PaddedBtn
                            onClick={() => {
                                filterStudentList(ct);
                            }}
                        >
                            {ct}
                        </PaddedBtn>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    const CurrentSubmissionView = useCallback(() => {
        if (submissionsData?.submissions != 0 || (submissionsData?.submissions == 0 && submissionsData?.returned > 0)) {
            let PastSub = 0;
            map(pastSubmissions, pt => (
                PastSub = parseInt(pt.submissions)
            ));
            const LastSub = PastSub ? PastSub : 0;
            const CurrentSub = LastSub + 1;
            let Title = "";
            if (CurrentSub === 1) {
                Title = CurrentSub;
            } else {
                Title = CurrentSub + ' (Last Submmission)';
            }
            const isunGraded = get(selectedAssignment,"ungraded");
            const hmark = getHigestMark(submissionsData);
            let mark = hmark.isHighest ? `Highest(${hmark.val})` : `(${hmark.progress ? "in-progress" : hmark.val})`;
            mark= isunGraded ? "ungraded" : mark;
            const is_game = submissionsData?.assignmentType === "game";
            const is_debate = submissionsData?.assignmentType === "debate";
            return (<div>
                {<Accordion square expanded={expanded === get(submissionsData, "id")} onChange={handleChangeExpend(get(submissionsData, "id"))}>
                    <AccordionSummary style={CurrentSubmission} aria-controls="panel1d-content" id="panel1d-header">
                        <AccordianTitle><span>{"Submission-" + Title}  {mark} {(submissionsData?.returned > 0 && submissionsData?.submissions == (submissionsData?.returned - 1)) ? `(Returned)` : null}</span> <Rotate open={expanded === get(submissionsData, "id")}><ArrowRight /></Rotate> </AccordianTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GradeDisplay
                            key={get(submissionsData, "id")}
                            answers={get(submissionsData, "answers")}
                            feedback={get(submissionsData, "feedback")}
                            ai_feedback={get(submissionsData, "ai_feedback")}
                            ai_suggestion={get(submissionsData, "ai_suggestion")}
                            audioFeedback={get(submissionsData, "audioFeedback")}
                            grade={getAssignmentGradeValue(submissionsData,selectedAssignment)}
                            name={get(submissionsData, "displayName", get(submissionsData, "assignment.displayName"))}
                            questions={get(submissionsData, "assignment.questions", [])}
                            type={get(submissionsData, "assignmentType") == "quiz" ? "quiz" : null}
                            isExpended
                            isLate={false}
                            hasFeedback={false}
                            is_game={is_game}
                            selected_student_id={submissionsData?.userId}
                            is_debate={is_debate}
                            isUngraded={isunGraded}
                            assignment={submissionsData}
                        />
                    </AccordionDetails>
                </Accordion>}
            </div>)
        }
    })
    const getMark = (subm, isTbg, isUngraded, isCompleteInComplete, isActive) => {
        const is_return = isReturned(subm)
        if (is_return) {
            return "returned"
        }
        else if (subm.complete && isTbg && !isUngraded && !isCompleteInComplete && isActive) {
            return "TBG";
        } else return null
    }
    const PosterIg = useMemo(() => {
        if (!selectedAssignment) return null;
        const is_debate = get(selectedAssignment, "type", '') === "debate"
        if (is_debate) {
            return <ImageContiner>
                <Image src={debatePoster} alt='ebook image' />
            </ImageContiner>
        } else {
            return (
                <ImageContiner>
                    {isVideo(get(selectedAssignment, "data.chapter"), resources) ? <Image src={imageFetchFromPodcast(get(selectedAssignment, "data.chapter"), resources)} alt='ebook image' /> :
                        <Image src={imageFetch(booksAllList, get(selectedAssignment, "data.chapter"), get(selectedAssignment, "data.page"))} alt='ebook image' />}
                </ImageContiner>
            )
        }

    }, [selectedAssignment, resources])

    return (
        <Wrapper onSubmit={e => e.preventDefault()}>
            <Header>{get(userInfo, 'firstName', '')} {get(userInfo, 'lastName', '')}</Header>
            <Row gutter={[16, 24]} wrap>
                <Col xs={24} sm={14} md={10} style={{ marginBottom: 14 }}>
                   {PosterIg}
                </Col>
                <Col xs={24} sm={10} md={7} style={{ marginBottom: 14, borderLeft: "2px dotted #423a3a61", borderRight: "2px dotted #423a3a61" }}>
                    {inProgressSatatus && (
                        <Notification>
                            {get(userInfo, "firstName", "")} {get(userInfo, "lastName", "")} is in the process of  {get(selectedAssignment,"pastSubmissions.length")==0?"taking":"retaking"}  this assignment and due to that you might see partial/no answers below. If there is a grade for this assignment it has been stored from a previous submission.
                        </Notification>
                    )}
                    {submissionStatus && (
                        <Notification bgColor={COLOR.yellow} color={COLOR.white}>
                            The student has not submitted this assignment
                        </Notification>
                    )}
                    {timmerStatus && (
                        <Notification>
                            This is a time-test assessment, if there are any blank answers, it means the student did not complete within time given.
                        </Notification>
                    )}
                    <PreviewArea>
                        {pastSubmissionView()}
                        {CurrentSubmissionView()}
                    </PreviewArea>
                    <Row style={{ marginBottom: '24px', marginTop: 12 }}>
                        <Description style={{ marginBottom: 0, opacity: !submissionsData || !submissionsData.id ? 0.5 : 1 }}>
                            Student Feedback
                        </Description>
                        <TextField
                            id="feedback"
                            onChange={handleFeedback}
                            variant="outlined"
                            value={feedback}
                            disabled={!submissionsData || !submissionsData.id }
                            multiline
                            rows={10}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.trim().length > 0) {
                                    handleSubmit(null, null, false, null, e.target.value, selectedAssignment)
                                }
                            }}
                            className={"feedbackform"}
                        />
                    </Row>
                   {submissionsData && submissionsData.id ? <Row>
                        <Description style={{ marginBottom: 0 }}>
                            You can record student feedback here.
                        </Description>
                        <AudioRecorder
                            maxTime={120}
                            wrapperStyle={{ border: '0px solid #e8e8e8', padding: '0px 0px 0px' }}
                            ref={feedbackAudioRef}
                            message={"Record your Feedback"}
                            handleAudio={(url) => {
                                setAudioFeedback(url)
                                handleSubmit(null, url, false, null, null, selectedAssignment)
                            }}
                            refresh={() => history.go(0)}
                            source={audioFeedback}
                            hasSaveButton={submissionsData && submissionsData.id}
                        />
                    </Row>:null}
                    <Row justify="end">
                        {!get(selectedAssignment, "ungraded") && get(selectedAssignment, "grade_type") != "complete_incomplete" ? <div style={{position:"relative"}}>
                            <TextField
                                id="grade"
                                label="Grade"
                                onChange={handleChange}
                                variant="outlined"
                                value={grade}
                                ref={inputRef}
                                onFocus={handleFocus}
                                style={{ marginRight: '16px', width: '56px' , textAlign:"center"}}
                                className={"gradeUpdate"}
                                disabled={get(submissionsData, "exceptionStatus") > 0 || get(selectedAssignment, "ungraded")}
                            />
                              <div style={{ paddingLeft: 15, position:"absolute" }}>{current_assignment_returned ? 'returned' : selected_assignment_tbg ? "TBG" : ''}</div>
                        </div> : null}
                        {get(selectedAssignment, "grade_type") === "points" ? <MaxPointText style={{ marginRight: 16, alignSelf: "center", fontSize: "large" }}>{`/${get(selectedAssignment, "max_point", 100)}`}</MaxPointText> : null}
                        <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            disabled={loading || updateLoading || grade === '-' || grade ==='' || grade === null}
                            onClick={e => {
                                handleSubmit(e, null, false, null, null, selectedAssignment)
                                // closeModal()
                            }}
                        >
                            {loading == "u" ? <Loading size={20} white /> : 'Update'}
                        </Button>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={7} style={{ marginBottom: 14 }}>
                    <UserSelector style={{ height: "auto", marginTop: 0 }}>
                        <strong style={{ minWidth: 60 }}>Filter Student</strong>
                        <Dropdown overlay={categoryNav()} placement="bottomLeft" toggle={['click']}>
                            <CategoryMenu>{select}  <MenuIconImg src={MenuIcon} /> </CategoryMenu>
                        </Dropdown>

                    </UserSelector>
                    <UserSelectorList>
                        {
                           userSubmissions.length>0? map(userSubmissions, (usrAssignments, i) => {
                               const isUngraded = get(usrAssignments, "ungraded")
                               const grade_type = get(usrAssignments, "grade_type")
                               let max_point = get(usrAssignments, "max_point", 100)
                               if (max_point == null) {
                                   max_point = 100;
                               }
                               const isCompleteInComplete = grade_type === "complete_incomplete"
                               const isPointSystem = grade_type === "points"
                               const isActive = get(selectedAssignment, "id") == get(usrAssignments, "id")
                               const disabled = updateLoading || get(usrAssignments, "submissions.exceptionStatus") > 0 || isUngraded;
                               const submissions = get(usrAssignments, "submissions", {})
                               const assignment_grade = getAssignmentGradeValue(get(usrAssignments, "submissions", ""),usrAssignments)
                               const isTbg = assignment_grade === "TBG"
                               const value_result = (typeof grade === "number" && grade >= 0) || (typeof grade === "string") ? grade : '-';
                                return (
                                    <UserSelector
                                        key={get(usrAssignments, "user.id")}
                                        onClick={() => { setData(usrAssignments) }}
                                        active={get(selectedAssignment, "data.id") == get(usrAssignments, "data.id")}
                                    >
                                        <NameText>{get(usrAssignments, "displayName", "")}</NameText>
                                        {updateLoading && updateLoading == get(usrAssignments, "id") ? <Loading size={14} white={isActive} /> : null}
                                        {isUngraded ? (get(usrAssignments, "submissions") > 0 ? "Turned in" : "ungraded") : ''}
                                        {isCompleteInComplete?getCompleteInclompleteMark(has(submissions,"id")&&submissions.grade>0 , has(submissions,"id") && submissions.grade==0,isActive,usrAssignments):null}
                                        {getMark(submissions, isTbg, isUngraded, isCompleteInComplete, isActive)}
                                        {!isUngraded && !isCompleteInComplete ? <>  { isActive ?
                                        <GradeEditorInput
                                            disabled={disabled}
                                            inputRef={re => inputsRefs.current[i] = re}
                                            onChange={handleChange}
                                            onFocus={e=>{
                                                if(e.target.value === "-"){
                                                    e.target.value = ''
                                                }
                                                handleFocus(e);
                                            }}
                                            value={value_result}
                                            onBlur={(e) => {
                                                if (updateLoading||e.target.value.includes("TBG") || e.target.value.includes("-") || e.target.value ==='' || e.target.value===null ) return;
                                                handleSubmit(e, null, false , e.target.value,null, usrAssignments)
                                            }}
                                        />
                                        :
                                        <span style={{ margin: 2 }}>
                                             {assignment_grade}
                                             {isPointSystem?<MaxPointText style={{color:isActive?"#fff":COLOR.gray100,marginRight:5}}>{`/${max_point}`}</MaxPointText>:null}
                                        </span>}
                                       </>:null}
                                       {isActive && isPointSystem?<MaxPointText style={{color:isActive?"#fff":COLOR.gray100,marginRight:5}}>{`/${max_point}`}</MaxPointText>:null}
                                        <NameText style={{ width: 150, textAlign: "right" }}>{getStatus(get(usrAssignments, "submissions", ""))}</NameText>
                                    </UserSelector>
                                )
                            })

                                : <div style={{ textAlign: "center", marginTop: "20px" }}>No Data Found</div>
                        }
                    </UserSelectorList>
                </Col>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
StudentReview.propTypes = {};

//  -- Default props ------------------
StudentReview.defaultProps = {};

export default StudentReview;