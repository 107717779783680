//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';

import * as Sentry from "@sentry/react";
import { has } from 'lodash';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------


//  -- List books ----------------
export const handleListClassBookmarks = (classId) => async (dispatch) => {
    try {
        let bookmarks = [];
        const bookmarksResp = await API.get('bookmarks', `/getByClass/${classId}`);
        if (Array.isArray(bookmarksResp) && bookmarksResp.length > 0) {
            bookmarks = bookmarksResp
        }
        await dispatch(ActivityActions.listClassBookmarksSuccess(bookmarks));
        return bookmarks;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.listClassBookmarksFail(error));
    }
};
export const handleCreateBookmarks = (data) => async (dispatch) => {
    try {
        let bookmarks = {};
        const bookmarksResp = await API.post('bookmarks', `/bookmark`, {
            body: data
        });
        if (has(bookmarksResp, "id")) {
            bookmarks = bookmarksResp
            await dispatch(ActivityActions.createClassBookmarksSuccess(bookmarks));
        }
        return bookmarks;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleUpdateBookmarks = (id, page) => async (dispatch) => {
    try {
        let bookmarks = {};
        const bookmarksResp = await API.put('bookmarks', `/bookmark/${id}`, {
            body: { page }
        });
        if (has(bookmarksResp, "id")) {
            bookmarks = bookmarksResp
            await dispatch(ActivityActions.updateClassBookmarks(bookmarks));
        }
        return bookmarks;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.listClassBookmarksFail(error));
    }
};

export const handleDeleteBookmarks = (id) => async (dispatch) => {
    try {
        await API.del('bookmarks', `/bookmark/${id}`);
        await dispatch(ActivityActions.deleteClassBookmark(id));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
