//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
//  -- Components ---------------------
import { Col, Row , Dropdown , Menu } from 'antd';
import ExamCover from 'components/AdminBooks/ExamCover';
import Section from 'components/Section';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { find, map, get , groupBy } from "lodash"
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import Loading from "components/Loading";
import { useExams } from 'store/exams/selectors';
import { handleListExams, handleCreateExams, handleUpdateExams, handleDeleteExam } from 'store/exams/thunks';
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import moment from "moment"
import { Add, Edit, Close, Delete } from '@material-ui/icons';
import { Button, IconButton, FormControl, Switch, Modal, makeStyles, MenuItem, InputLabel, Select, TextField , FormHelperText } from '@material-ui/core';
import {
    DateTimePicker,
} from "@material-ui/pickers";
import { isAfterTime, isBeforTime } from "utils/methods/math"
import { cloneDeep } from 'lodash';
import { has } from 'lodash';
import { DeleteModal } from 'components/Dialog';
import { CaretDownOutlined } from '@ant-design/icons';
//  -- Features -----------------------



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;

const ListItem = styled.li`
    margin: 0px 4% 32px;
    width: calc(21%);

    @media only screen and (min-width: 600px) and (max-width: 800px) {
        margin: 0px 16px 32px;
        width: calc(50% - 32px);
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        margin: 0px 16px 32px;
        width: 100%;
    }
`
const List = styled.ul`
    height: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content:center;
`;
//   margin-left: 12%;
// width: 75%;
//horizental line with styled-components
const Hr = styled.div`
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 32px;
    width: 100%;
`;

const ModalContent = styled.div`
  left: 50%;
  width: 70vw;
  padding: 0px 24px;
  position: absolute;
//   height:80vh;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
`;
const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    margin-left:5px;
    margin-top:5px;
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

function isValidYearNextYearPattern(inputString) {
    const pattern = /^(\d{4})-(\d{4})$/;
    if (!pattern.test(inputString)) {
      return false;
    }
    const [, year, nextYear] = inputString.match(pattern);
    const yearNum = parseInt(year, 10);
    const nextYearNum = parseInt(nextYear, 10);
    return yearNum + 1 === nextYearNum;
  }
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const getMinDate = (date1, date2) => {
    if (date1 && date2) {
        return isBeforTime(date1, date2) ? moment(date2).add(1, "minute").toISOString() : moment(date1).add(1, "minute").toISOString();
    } else if (date1 || date2) {
        return date1 ? moment(date1).add(1, "minute").toISOString() : moment(date2).add(1, "minute").toISOString();
    } else {
        return moment().toString()
    }
}

const EditExams = ({ open, close, data, booksAllList, exam, redirectToBook }) => {
    const [loading, setLoading] = useState(false)
    const [examData, setExamData] = useState()
    const classes = useStyles();
    const dispatch = useDispatch()
    const onCreateOrUpdate = async () => {
        setLoading(true)
        const dt = cloneDeep(exam)
        if (examData && examData.id) {
            exam.exams.map((e, i) => {
                if (e.id === examData.id) {
                    dt.exams[i] = examData
                }
            })
            await dispatch(handleUpdateExams(dt))
        } else {
            let maxId = Math.max(...exam.exams.map(o => parseInt(o.id)))
            if (exam.exams.length == 0) {
                maxId = 1
            } else {
                if (isNaN(maxId) || maxId == -Infinity) {
                    maxId = 0;
                }
                maxId++;
            }
            dt.exams.push({ ...examData, id: maxId, order: maxId })
            await dispatch(handleUpdateExams(dt))
        }
        setLoading(false)
        close()
    }
    useEffect(() => {
        setExamData(data)
    }, [data])
    const isDisabled = !get(examData, "src") || !get(examData, "displayName") || !get(examData, "bookId")
    return (
        <Modal
            open={open}
            onClose={() => close()}
            style={{ height: '100%', width: '100%' }}
        >
            <ModalContent>
                <Row style={{ justifyContent: "space-between" }}>
                    <TitleText>
                        {data ? 'Update Exam' : 'Add Exam'}
                    </TitleText>
                    <IconButton onClick={() => close()} style={{ padding: 0, marginRight: 10 }}>
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <Row>
                        <Col xs={24} lg={12} style={{marginBottom:20}}>
                            <PageImagePicker
                                src={get(examData, "src")}
                                path={`/exams`}
                                onChange={item => {
                                    setExamData({ ...examData, src: item.url })
                                }}
                                hasDescription={false}
                            />
                        </Col>
                        <Col xs={24} lg={12} style={{ paddingLeft: 10, paddingTop: 10 }}>
                            <Row justify="start" style={{ marginBottom: 20, }}>
                                <TextField
                                    id="displayName"
                                    name="displayName"
                                    label="Display name"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        setExamData({ ...examData, displayName: e.target.value })
                                    }}
                                    value={get(examData, "displayName")}
                                />
                            </Row>
                            <Row
                                gutter={[24, 24]}
                                style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}
                                className='floatingLabelBG'
                            >
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="bookId">Book</InputLabel>
                                    <Select
                                        labelId="bookId"
                                        id="bookId"
                                        value={`${get(examData, "bookId")}`}
                                        onChange={(e) => {
                                            setExamData({
                                                ...examData,
                                                bookId: e.target.value,
                                            });
                                        }}
                                    // label="Lession Plan book"
                                    >
                                        {map(booksAllList, (bk) => (
                                            <MenuItem key={bk.id} value={bk.id}>{`${bk.description || ""
                                                }(${bk.id || ""})`}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Row>
                            <Row gutter={[16, 24]} className="durDateSpc">
                                <Col span={24}>
                                    <DateTimePicker
                                        id="StartFrom"
                                        label="Start Date"
                                        inputVariant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={null}
                                        format="MM-dd-yyyy hh:mm a"
                                        initialFocusedDate={moment().toString()}

                                        strictCompareDates
                                        value={get(examData, "startDate")}
                                        onChange={e => {
                                            setExamData({
                                                ...examData,
                                                startDate: e.toISOString(),
                                            });
                                        }} />
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]} className="durDateSpc" style={{marginBottom:30}}>
                                <Col span={24}>
                                    <DateTimePicker
                                        id="ednFromDate"
                                        label="End Date"
                                        inputVariant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={null}
                                        format="MM-dd-yyyy hh:mm a"
                                        initialFocusedDate={moment().toString()}
                                        strictCompareDates
                                        // minDate={get(examData, "startDate") ? getMinDate(get(examData, "startDate")) : null}
                                        minDateMessage={"End Date and Time has to be after the Start Date and Time."}
                                        value={get(examData, "endDate")}
                                        onChange={e => {
                                            setExamData({
                                                ...examData,
                                                endDate: e.toISOString(),
                                            });
                                        }} />
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                                <Button fullWidth
                                    onClick={onCreateOrUpdate}
                                    disabled={isDisabled}
                                    variant="contained" color="primary">
                                    {loading ? <Loading white size={15} /> : null}
                                    {data ? "Update" : "Create"}
                                </Button>
                            </Row>
                            {has(data, "id") ? <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                                <Button fullWidth
                                    onClick={() => {
                                        redirectToBook(examData.bookId)
                                    }}
                                    disabled={isDisabled}
                                    variant="contained" color="primary">
                                    Open book
                                </Button>
                            </Row> : null}
                        </Col>
                    </Row>
                </ModalConfigArea>
            </ModalContent>
        </Modal>
    );
};

const EditExamsLavel = ({ open, close, data , activeSession , examDSessionata}) => {
    const [loading, setLoading] = useState(false)
    const [examData, setExamData] = useState()
    const dispatch = useDispatch()
    const onCreateOrUpdate = async () => {
        setLoading(true)
        if (examData && examData.id) {
            await dispatch(handleUpdateExams(examData))
        } else {
            const hasMiddle = find(examDSessionata,['exam_school','middle'])
            await dispatch(handleCreateExams({ ...examData, exams: [] , session:activeSession , exam_school:!hasMiddle?'middle':'' }))
        }
        setLoading(false)
        close()
    }
    useEffect(() => {
        setExamData(data)
    }, [data])
    const isDisabled = !get(examData, "displayName")
    return (
        <Modal
            open={open}
            onClose={() => close()}
            style={{ height: '100%', width: '100%' }}
        >
            <ModalContent style={{ width: "40vw", height: "auto", paddingTop: 16 }}>
                <Row style={{ justifyContent: "space-between" }}>
                    <TitleText>
                        {data ? 'Update Exam Level' : 'Add Exam Level'}
                    </TitleText>
                    <IconButton onClick={() => close()} style={{ padding: 0, marginRight: 10 }}>
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <Row>
                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 16 }}>
                            <Row justify="start">
                                <Col xs={24} lg={24} style={{ marginBottom: 30, }}>
                                    <TextField
                                        id="displayName"
                                        name="displayName"
                                        label="Display name"
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        onChange={e => {
                                            setExamData({ ...examData, displayName: e.target.value })
                                        }}
                                        value={get(examData, "displayName")}
                                    />
                                </Col>
                                <Col xs={24} lg={24}>
                                    <Button fullWidth
                                        onClick={onCreateOrUpdate}
                                        disabled={isDisabled}
                                        variant="contained" color="primary">
                                        {loading ? <Loading white size={15} /> : null}
                                        {data ? "Update" : "Create"}
                                    </Button>
                                </Col>
                            </Row>
                            {/* <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                             
                            </Row> */}
                        </Col>
                    </Row>
                </ModalConfigArea>
            </ModalContent>
        </Modal>
    );
};

const EditSessions = ({ open, close, examSessionData }) => {
    const [loading, setLoading] = useState(false)
    const [session, setSessions] = useState(null)
    const [error,setError] = useState(null);
    const classes = useStyles();
    const dispatch = useDispatch()
    const onCreateOrUpdate = async () => {
        const isExist  = examSessionData[session];
        if(isExist) {
            setError(`This session already exists. Please add a different one.`)
            return;
        }else if(!isValidYearNextYearPattern(session)){
            setError(`The input must adhere to the 'year-nextyear' pattern, such as '${new Date().getFullYear()}-${new Date().getFullYear()+1}'`)
            return;
        }

        setLoading(true)
        await dispatch(handleCreateExams({ displayName:'High School Level', exams: [] , session:session , exam_school:'high' }))
        setLoading(false)
        close(session);
    }
    return (
        <Modal
            open={open}
            onClose={() => {
                close()
                setSessions(null)
                setError(null)
            }}
            style={{ height: '100%', width: '100%' }}
        >
             <ModalContent style={{ width: "40vw", height: "auto", paddingTop: 16 }}>
                <Row style={{ justifyContent: "space-between" }}>
                    <TitleText>
                        {'Add Exam Session'}
                    </TitleText>
                    <IconButton onClick={() => close()} style={{ padding: 0, marginRight: 10 }}>
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                <Row>
                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 16 }}>
                            {error ? (
                                <FormHelperText error>
                                    {error}
                                </FormHelperText>
                            ) : null}
                            <Row justify="start">
                                <Col xs={24} lg={24} style={{ marginBottom: 30, }}>
                                    <TextField
                                        id="displayName"
                                        name="displayName"
                                        label="yyyy-yyyy"
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        placeholder='yyyy-yyyy'
                                        onChange={e => {
                                            setSessions(e.target.value.trim())
                                        }}
                                        value={session}
                                    />
                                </Col>
                                <Col xs={24} lg={24}>
                                    <Button fullWidth
                                        onClick={onCreateOrUpdate}
                                        disabled={!session}
                                        variant="contained" color="primary">
                                        {loading ? <Loading white size={15} /> : null}
                                         Create
                                    </Button>
                                </Col>
                            </Row>
                            {/* <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                             
                            </Row> */}
                        </Col>
                    </Row>
                </ModalConfigArea>
            </ModalContent>
        </Modal>
    );
};
const AssessmentContainer = ({
    currentUser,
    booksAllList,
}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [examDatam, setExamData] = useState([])
    const dispatch = useDispatch()
    const exams = useExams()
    const [activeExamData, setActiveData] = useState();
    const [selectedExam, setSelectedExam] = useState();
    const [openEditExamModal, setOpenEditExamModal] = React.useState(false);
    const [openEditExamLevelModal, setOpenEditExamLevelModal] = React.useState(false);
    const [deleteLodaing, setDeleteLoading] = useState(false)
    const [openDeleteExamModal, setOpenDeleteExamModal] = React.useState(false);
    const [activeSession, setActiveSession] = useState(null);
    const [examGroupData,setExamGroupData] = useState({})
    const [openAddSesion,setOpenAddSesion] = useState(false);
    const [state, setState] = React.useState({
        open: false,
        message: ""
    });
    const loadExams = async () => {
        setLoading(get(exams, "length") == 0)
        await dispatch(handleListExams())
        setLoading(false)
    }
    useEffect(() => {
        loadExams()
    }, [])
    const redirectToBook = (bookId) => {
        history.push(`/add-book?bookId=${bookId}&pageId=1`)
    }
    useEffect(() => {
        //sort exam by order
        if (exams) {
            const sortedExams = exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
            const years = new Date().getFullYear();
            const grp = groupBy(sortedExams,"session")
            const upcomming_year = `${years}-${years+1}`;
            const downcomming_year = `${years-1}-${years}`;
            const upcomming = grp[upcomming_year]
            setExamGroupData(grp)
            if(activeSession&& grp[activeSession]){
                setExamData(grp[activeSession])
            }
            else if (upcomming) {
                setActiveSession(upcomming_year)
                setExamData(upcomming)
            } else if (grp[downcomming_year]) {
                setActiveSession(downcomming_year)
                setExamData(grp[downcomming_year])
            }else{
                const keys = Object.keys(grp)[0]
                setActiveSession(keys)
                setExamData(grp[keys])
            }
        }

    }, [exams])
    const handleClose = () => {
        setState({ open: false, message: "" });
    };
    const onAddExam = (exmDt, exm) => {
        setActiveData(exmDt)
        setSelectedExam(exm)
        setOpenEditExamModal(true)
    }
    const onAddEditExamLevel = (exm) => {
        setActiveData(exm)
        setOpenEditExamLevelModal(true)
    }
    const onClose = () => {
        setOpenEditExamModal(false)
        setActiveData(null)
        setSelectedExam(null)
        setOpenEditExamLevelModal(false)
    }
    const onSessionDataClosed = (session_id) => {
        setOpenAddSesion(false)
        if (session_id) {
            const grp = groupBy(exams, "session")
            if (grp[session_id]) {
                setExamData(grp[session_id])
                setActiveSession(session_id)
            }
        }
    }
    const onDeleteExam = async () => {
        setDeleteLoading(true)
        await dispatch(handleDeleteExam(get(activeExamData, "id")))
        setActiveData(null)
        setOpenDeleteExamModal(false)
        setDeleteLoading(false)
    }
    const onSelectSession = (key) =>{
        setActiveSession(key)
        setExamData(examGroupData[key])
    }
    const menuData = () =>{
        return( <Menu>
           {map(Object.keys(examGroupData), x => {
                    return (
                        <Menu.Item key={x} style={{ padding: '0px' }}>
                          <PaddedBtn  onClick={()=>onSelectSession(x)}>{x}</PaddedBtn> 
                        </Menu.Item>
                    )
                })}
        </Menu>)
    }
    const { open, message } = state;
    return (
        <Wrapper>
            {loading ? (
                <Loading />
            ) : (<>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        <strong>ALERT:</strong> {message}
                    </Alert>
                </Snackbar>
                <DeleteModal
                    deleteToggle={openDeleteExamModal}
                    onDelete={onDeleteExam}
                    message={`Are you sure you want to delete ${get(activeExamData, "displayName", "this Exam")}?`}
                    setDeleteToggle={() => {
                        setOpenDeleteExamModal(false)
                    }} loading={deleteLodaing} />
                    <EditExams
                        data={selectedExam}
                        open={openEditExamModal}
                        close={onClose}
                        exam={activeExamData}
                        booksAllList={booksAllList}
                        redirectToBook={redirectToBook} />
                    <EditExamsLavel
                        data={activeExamData}
                        open={openEditExamLevelModal}
                        examDSessionata={examDatam}
                        activeSession={activeSession}
                        close={onClose} />
                    <EditSessions
                        examSessionData={examGroupData}
                        open={openAddSesion}
                        close={onSessionDataClosed} />
                    <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                        <Dropdown overlay={menuData()} placement="bottomLeft" trigger={['click']}>
                            <Button onClick={e => e.preventDefault()}>
                                {activeSession ? activeSession : 'sessions'}
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </Button>
                        </Dropdown>
                        <Button onClick={() => setOpenAddSesion(true)} color='primary' variant="contained"  style={{ marginLeft:8}} >
                            <Add /> Add session
                        </Button>
                        <Button onClick={() => onAddEditExamLevel()} color='primary' variant="contained" style={{ marginLeft:8}} >
                            <Add /> Exam level
                        </Button>
                    </Row>
                <Row style={{ marginTop: '1%' }}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Section style={{ fontSize: '18px' }}
                            description="exams"
                            header='Exams'>
                            {map(examDatam, exm => (<LinkArea>
                                <Row>
                                    <Col span={24}>
                                        <Button onClick={() => onAddExam(exm)} color='primary' variant="contained" >
                                            <Add /> exams
                                        </Button>
                                        <Button
                                            onClick={() => { onAddEditExamLevel(exm) }}
                                            color='primary'
                                            variant="contained"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            <Edit /> Edit Exam level
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setOpenDeleteExamModal(true)
                                                setActiveData(exm)
                                            }}
                                            color="secondary"
                                            variant="contained"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            <Delete /> Exam level
                                        </Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '1%' }}>
                                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                        <List>
                                            {map(exm.exams, (obj, i) => (
                                                <ListItem key={obj.id}>
                                                    <ExamCover
                                                        data={obj}
                                                        handleClick={() => onAddExam(exm, obj)}
                                                        labelBgColor={COLOR.redishpink}
                                                        label={get(exm, "displayName")}
                                                        exam={exm}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>

                                    </Col>
                                </Row>
                            </LinkArea>))}
                        </Section>
                    </Col>
                </Row>
            </>)}

        </Wrapper>
    );
};

//  -- Prop types ---------------------
AssessmentContainer.propTypes = {};

//  -- Default props ------------------
AssessmentContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser,
    userBooksList: state.userBooksList,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    booksAllList: state.booksAllList
});

export default connect(mapStateToProps, null)(AssessmentContainer);
