
import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";

const ResizeAndDragabbleNotes = ({
  defaultValue = {
    x: 150,
    y: 205,
    width: 270,
    height: 323,
  },
  minWidth = 270,
  minHeight = 323,
  children,
  aspectRatio = true,
  resize = (data) => data,
  resizeEnd,
  bounds = "window",
  onFocusValue = false,
  showAccordin,
  dimensions,
  from='',
  ...props
}) => {
  const [isResizing, setIsReszing] = useState(false);

  // Calculate the center position
  const centerPosition = () => {
    const x = Math.floor((window.innerWidth - defaultValue.width) / 2);
    const y = Math.floor((window.innerHeight - defaultValue.height) / 2);
    return { x, y };
  };

  const initialPositionNotes = centerPosition();

  useEffect(() => {
    resize({ width: defaultValue.width, height: defaultValue.height });
  }, []);

  const resizeHandleStyles = {
    top: { display: "none" },
    bottom: { display: "none" },
    left: { display: "none" },
    right: { display: "none" },
    topLeft: { cursor: "nwse-resize" },
    topRight: { cursor: "nesw-resize" },
    bottomLeft: { cursor: "nesw-resize" },
    bottomRight: { cursor: "nwse-resize" },
  };

  return (
    <Rnd
      lockAspectRatio={aspectRatio}
      default={{
        x: from === 'notes' && initialPositionNotes.x,
        y: from === 'notes' && initialPositionNotes.y,
        ...defaultValue,
      }}
      minWidth={minWidth}
      minHeight={minHeight}
      bounds={bounds}
      disableDragging={onFocusValue}
      enableResizing={showAccordin}
      resizeHandleStyles={aspectRatio ? resizeHandleStyles : {}}
      onResize={(e, direction, ref, delta, position) => {
        resize({
          width: parseInt(ref.style.width, 10),
          height: parseInt(ref.style.height, 10),
          transform: `${Math.min(
            3,
            parseInt(ref.style.width, 10) / minWidth,
            parseInt(ref.style.height, 10) / minHeight
          )}`,
        });
      }}
      onResizeStart={() => setIsReszing(true)}
      onResizeStop={() => setIsReszing(false)}
      {...props}
    >
      <div style={{ width: "100%", height: "100%" }}>{children}</div>
    </Rnd>
  );
};

export default ResizeAndDragabbleNotes;


