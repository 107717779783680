//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create activity ----------------
export const createActivity = () => ({ type: ActionTypes.activities.createActivity });
export const createActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.createActivityFail, payload: returnedPayload });
export const createActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.createActivitySuccess, payload: returnedPayload });

export const updateActivity = () => ({ type: ActionTypes.activities.updateActivites });
export const updateActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.updateActivitesFail, payload: returnedPayload });
export const updateActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.updateActivitesSuccess, payload: returnedPayload });


export const deleteActivity = () => ({ type: ActionTypes.activities.deleteActivites });
export const deleteActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.deleteActivitesFail, payload: returnedPayload });
export const deleteActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.deleteActivitesSuccess, payload: returnedPayload });




//  -- Get activity -------------------
export const getActivity = () => ({ type: ActionTypes.activities.getActivity });
export const getActivityFail = (returnedPayload) => ({ type: ActionTypes.activities.getActivityFail, payload: returnedPayload });
export const getActivitySuccess = (returnedPayload) => ({ type: ActionTypes.activities.getActivitySuccess, payload: returnedPayload });

//  -- List chapter activities --------
export const listChapterActivities = () => ({ type: ActionTypes.activities.listChapterActivities });
export const listChapterActivitiesFail = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesFail, payload: returnedPayload });
export const listChapterActivitiesSuccess = (returnedPayload) => ({ type: ActionTypes.activities.listChapterActivitiesSuccess, payload: returnedPayload });

//  -- List activities ----------------
export const listActivities = () => ({ type: ActionTypes.activities.listActivities });
export const listActivitiesFail = (returnedPayload) => ({ type: ActionTypes.activities.listActivitiesFail, payload: returnedPayload });
export const listActivitiesSuccess = (returnedPayload) => ({ type: ActionTypes.activities.listActivitiesSuccess, payload: returnedPayload });



export const listCustomAssignmentByUser = (user) => ({ type: ActionTypes.assignmenent.listAssignmentByUser });
export const listCustomAssignmentByUserFail = (returnedPayload) => ({ type: ActionTypes.assignmenent.listAssignmentByUserFail, payload: returnedPayload });
export const listCustomAssignmentByUserSuccess = (returnedPayload) => ({ type: ActionTypes.assignmenent.listAssignmentByUserSuccess, payload: returnedPayload });

export const createCustomAssignment = () =>({ type: ActionTypes.assignmenent.createCustomAssignment });
export const createCustomAssignmentFail = (returnedPayload) =>({ type: ActionTypes.assignmenent.createCustomAssignmentFail , payload: returnedPayload  });
export const createCustomAssignmentSuccess = (returnedPayload) =>({ type: ActionTypes.assignmenent.createCustomAssignmentSuccess , payload: returnedPayload  });


export const updateCustomAssignment = () =>({ type: ActionTypes.assignmenent.updateCustomAssignment });
export const updateCustomAssignmentFail = (returnedPayload) =>({ type: ActionTypes.assignmenent.updateCustomAssignmentFail, payload: returnedPayload  });
export const updateCustomAssignmentSuccess = (returnedPayload) =>({ type: ActionTypes.assignmenent.updateCustomAssignmentSuccess, payload: returnedPayload  });

export const deleteCustomAssignment = () =>({ type: ActionTypes.assignmenent.deleteCustomAssignment });
export const deleteCustomAssignmentFail = (returnedPayload) =>({ type: ActionTypes.assignmenent.deleteCustomAssignmentFail, payload: returnedPayload  });
export const deleteCustomAssignmentSuccess = (returnedPayload) =>({ type: ActionTypes.assignmenent.deleteCustomAssignmentSuccess, payload: returnedPayload  });




