//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";
import { Col, Row } from 'antd';
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { Edit, Delete } from "@material-ui/icons"
import { IconButton, Modal, Button } from "@material-ui/core"
import Loading from "./Loading";
import { useEffect } from 'react';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
    min-height:220px;
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`

const TextContainer = styled.div`
    border-top: 1px solid ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    padding:0px 16px;
    background-color: #171E2D;
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`
const TopRow = styled(Row)`
    border: 0.5px solid #3c393957;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
`

const BalconCover = ({ data, handleClick, onDelete, deleteLoader, setDeleteLoader, deleteToggle, setDeleteToggle }) => {

    return (<>
        <TopRow>
            <Text style={{ fontWeight: "bold", color: "black" }}>
                <strong> Id: {data.id}</strong>
            </Text>
            <IconButton onClick={() => {
                if (data.src) {
                    setDeleteToggle(data.id)
                } else {
                    onDelete(data.id)
                }
            }} style={{ padding: 0 }}>
                <Delete color="secondary" />
            </IconButton>
        </TopRow>
        <Wrapper>
            <div className="card-resource">
                <a onClick={handleClick}>
                    <div className="card-resource-overlay"></div>
                    <Image className="card-resource-image" src={data.src} alt={data.shortName} />
                    <div className="card-resource-details fadeIn-bottom">
                        <h3 className="card-resource-title">{data.shortName} <Edit /> </h3>
                    </div>
                </a>
            </div>
        </Wrapper>
    </>
    );
};

//  -- Prop types ---------------------
BalconCover.propTypes = {
    data: shape({
        img: string,
        title: string,
        description: string,
    }),
    handleClick: func
};

//  -- Default props ------------------
BalconCover.defaultProps = {
    data: {},
};

export default BalconCover;