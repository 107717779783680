//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Actions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------


export const handleListOneGameVideo = () => async (dispatch) => {
    try {
        const video = await API.get('one_game_video', `/list`);
        if (Array.isArray(video)) {
            await dispatch(Actions.listOneGameVideo(video));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleCreateOneGameVideo = (data) => async (dispatch) => {
    try {
        const videos = await API.post('one_game_video', `/create`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.createOneGameVideo(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
export const handleUpdateOneGameVideo = (data) => async (dispatch) => {
    try {
        const videos = await API.put('one_game_video', `/update`, {
            body: data
        });
        if (has(videos, "id")) {
            await dispatch(Actions.updateOneGameVideo(videos));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        console.log(error, "error")
    }
};
export const handleDeleteOneGameVideo = (id) => async (dispatch) => {
    try {
        const videos = await API.del('one_game_video', `/delete/${id}`);
        if (has(videos, "id")) {
            await dispatch(Actions.deleteOneGameVideo(id));
        }
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};