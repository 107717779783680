//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import getSettledPromisses from 'utils/methods/getSettledPromisses';


//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- Fetch district data ------------
export const handleFetchDistrictData = (classes) => async () => {
    let classData = [];

    const cl = classes.flat();
    classData = await Promise.allSettled(
        cl.map(async x => {
            const activities = await API.get('classActivitiesRDS', `/classActivities/${x.id}`);
            const quizzes = await API.get('classQuizzesRDS', `/classQuizzes/${x.id}`);
            
            return {
                orgId: x.orgId,
                teacherId: x.teacherId,
                studentCount: x.studentCount,
                activities,
                quizzes
            };
        })
    ).then(getSettledPromisses)

    return classData;
};
