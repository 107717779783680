//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import { lighten } from 'polished';
import { func } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
//  -- Thunks -------------------------
import { handlePasswordChange } from 'store/users/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';





//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Error = styled.div`
    background-color: ${lighten(0.4, 'red')};
    border: 1px solid red;
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    padding: 8px;
    text-align: center;
    width: 100%;
`;

const Header = styled.span`
    color: ${COLOR.black100};
    display: block;
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 32px;
`;

const Success = styled.div`
    background-color: ${lighten(0.4, COLOR.green)};
    border: 1px solid ${COLOR.green};
    border-radius: 3px;
    color: ${COLOR.green};
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    padding: 8px;
    text-align: center;
    width: 100%;
`;

const Wrapper = styled.form`
    margin: 40px auto 0px;
    width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Account form
//
//  -- Description
//  ...
//
//  -- Props
//  ...
//
const AccountForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [ data, setData ] = useState({});
    const [ status, setStatus ] = useState('');
    const [ toggle, setToggle ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ fail, setFail ] = useState(false);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if(data.newPassword !== data.currentPassword) {
            const result = await dispatch(handlePasswordChange(data));
            result === 'SUCCESS' ? setSuccess(true) : setFail(true);
        } else {
            setStatus('Your new password cannot be your new password!');
        }
        setLoading(false);
    };
    
    const handleToggle = (e) => {
        setToggle(e.target.checked);
    }

    return (
        <Wrapper onSubmit={(e) => handleSubmit(e)}>
            {success && (
                <Success>Password successfuly changed!</Success>
            )}
            {fail && (
                <Error>Password reset unsuccessful. Please check your info below to verify accuracy. If you continue to have issues please contact our team for additional help.</Error>
            )}
            <Header>Password Reset</Header>
            <Row gutter={[0, 24]} style={{ width: '100%' , marginTop:24}}>
                <Col span={24} className="floatingLabelBG">
                    <TextField
                        type={toggle ? 'text' : 'password'}
                        autoComplete="off"
                        variant="outlined"
                        label="Current Password"
                        onChange={(e) => handleChange(e)}
                        id='currentPassword'
                    />
                </Col>
            </Row>
            <Row gutter={[0, 24]} style={{ width: '100%', marginTop:24 }}>
                <Col span={24} className="floatingLabelBG">
                    <TextField
                        type={toggle ? 'text' : 'password'}
                        error={data.newPassword?.length < 8}
                        autoComplete="off"
                        variant="outlined"
                        label="New Password"
                        onChange={(e) => handleChange(e)}
                        id='newPassword'
                        helperText={data.newPassword?.length < 8 && 'Password must be at least 8 characters'}
                    />
                </Col>
            </Row>
            <Row gutter={[0, 24]} style={{ width: '100%',marginTop:24 }}>
                <Col span={24} className="floatingLabelBG">
                    <TextField
                        type={toggle ? 'text' : 'password'}
                        error={data.confirmPassword && data.confirmPassword !== data.newPassword}
                        autoComplete="off"
                        variant="outlined"
                        label="Confirm New Password"
                        onChange={(e) => handleChange(e)}
                        helperText={(data.confirmPassword && data.confirmPassword !== data.newPassword) && 'Must match new password exactly'}
                        id='confirmPassword'
                    />
                </Col>
            </Row>
            <FormControlLabel 
                control={<Checkbox checked={toggle} onChange={handleToggle} inputProps={{ 'aria-label': 'primary checkbox' }}/>}
                label='Show Passwords'
            />
            <Button
                disabled={!(data.newPassword && data.confirmPassword && data.currentPassword && (data.confirmPassword === data.newPassword)) || data.newPassword?.length < 8}
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                type='submit'
            >
                {loading ? <Loading size={20} white /> : 'Change Password'}
            </Button>
        </Wrapper>
    );
};



//  -- Prop types ---------------------
AccountForm.propTypes = {
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
};

//  -- Default props ------------------
AccountForm.defaultProps = {
    handleChange: Function.prototype,
    handleSubmit: Function.prototype,
};

export default AccountForm;