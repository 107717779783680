import * as Sentry from '@sentry/react';
import { API } from 'aws-amplify';
import { signOut as firebaseSignOut, getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirestore , doc , serverTimestamp , setDoc } from 'firebase/firestore';
import { ref as databaseRef, getDatabase, onValue, set as databaseSet, get as databaseGet } from 'firebase/database'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useCurrentUser } from 'store/users/selectors';
import { firebaseApp } from 'firebaseConfig';
// import { set } from "lodash";
const auth = getAuth(firebaseApp);
export const get_access_key = `prod/get_access_token`;

const FirebaseContext = createContext(null);

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }) => {
    const [firebaseUser, setFirebaseUser] = useState(null);
    const firestore = useMemo(() => getFirestore(firebaseApp), []);
    const database = useMemo(() => getDatabase(firebaseApp), []);

    const signInWithCustomTokenApi = async (userId, retryCount = 0) => {
        try {
            const dataa = {
                id: userId // Storing userId under the key 'data'
            };
            const response = await API.post('firebase', `/get_access_token`, {
                body: dataa
            });
            const responseBody = JSON.parse(response.body);
            if (responseBody.hassuccessed) {
                await signInWithCustomToken(auth, responseBody.data);
            }
        } catch (error) {



            if (retryCount < 2) {
                setTimeout(() => {
                    signInWithCustomTokenApi(userId, retryCount + 1)
                }, 2000)
            } else {
                console.log("Max retry count reached.")
                console.error('Error during Firebase sign-in:', error);
                Sentry.captureException(`057 Error during Firebase sign-in ${error}.`);
            }

        }
    };
    const markInActive = async () => {
        try {
            const last_log = sessionStorage.getItem('last_log');
            const last_log_user = sessionStorage.getItem('last_log_user');
            const last_log_class = sessionStorage.getItem('last_log_class');
            if (last_log && last_log_user && last_log_class) {
                const logRef = doc(firestore, last_log, last_log_user);
                const logEntry = {
                    userId: last_log_user,
                    classId: last_log_class,
                    lastVisitedPage: '/',
                    timestamp: serverTimestamp(),
                    query: {},
                    active: false
                };
                await setDoc(logRef, logEntry, { merge: true })
                sessionStorage.removeItem('last_log');
                sessionStorage.removeItem('last_log_user');
                sessionStorage.removeItem('last_log_class');
            }
        } catch (error) {

        }
    }
    const signOutUser = async () => {
        try {
            await markInActive();
            // await firebaseSignOut(auth);
        } catch (error) {
            console.error('Error during Firebase sign-out:', error);
            Sentry.captureException(`058 Error during Firebase sign-out ${error}.`);

        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setFirebaseUser(user);
        });

        return () => unsubscribe();
    }, []);

    const currentUser = useCurrentUser();


    useEffect(() => {
        if (currentUser && currentUser.id) {
            signInWithCustomTokenApi(currentUser.id);
        } else {
            console.log("signout called...");
            if (firebaseUser) {
                signOutUser();
            }
        }
    }, [currentUser]);

    return (
        <FirebaseContext.Provider value={{ firebaseUser, firestore, database, onValue, databaseRef, databaseSet, signInWithCustomTokenApi, signOutUser, databaseGet }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
