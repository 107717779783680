import CancelIcon from "@material-ui/icons/Cancel";
import { find, get, debounce } from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTraffic } from "store/wheel/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import styled from "styled-components";
import ResizeAndDragabble from "./ResizeAndDraggable";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { useCurrentUser } from "store/users/selectors";
import { trafficAudio } from "utils/assets/images";
import { v4 } from "uuid";
import { Button as AntdButton } from "antd";
import color from "utils/constants/color";
import AnimatedCheckMark from "./AnimatedCheckMark";
import ShareIcon from "@material-ui/icons/Share";
import AccordinButton from "./AccordinButton";
import ClickOutside from "./OnClickOutside";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
  //   margin: 15px auto;/
  transform: translate(31%, 0%);

  @media (max-width: 1450px) {
    // width: 96%;
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    // width: 95%;
    transform: translate(0%, 0%);
  }
`;

const TrafficModal = ({ currentClass, sharableWidget }) => {
  const [showAccordin, setShowAccordin] = useState(true);
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets } = useCollectionWidgets();
  const isTeacher = get(currentUser, "role") === "teacher";
  const [dimensions, setDimensions] = useState({});
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "traffic_signal"
  );
  const filterShareTraffic = find(
    sharableWidget,
    (obj) => obj?.widget_type === "traffic_signal"
  );
  const [activeLight, setActiveLight] = useState("red");
  const [descriptions, setDescriptions] = useState({
    red: "",
    yellow: "",
    green: "",
  });
  const sharedData = filterShareTraffic?.data || {};
  let dimensionChecker = dimensions.width < 589 && dimensions.height < 492;

  const handleLightChange = (color) => {
    if (!isTeacher) return;
    setActiveLight(color);
    if (filterShareTraffic?.status) {
      const dataShare = {
        ...filterShareTraffic?.data,
        activeLight: color,
      };
      const editShareData = {
        ...filterShareTraffic,
        data: dataShare,
      };
      createCollectionWidgets(editShareData);
    }
    playAudio();
  };

  const playAudio = () => {
    const audio = new Audio(trafficAudio);
    audio.play();
  };

  const debouncedCreateCollectionWidgets = debounce((editShareData) => {
    createCollectionWidgets(editShareData);
  }, 1000);

  const handleDescriptionChange = async (color, event) => {
    try {
      const updatedDescription = event.target.value ? event.target.value : "";
      setDescriptions((prevDescriptions) => ({
        ...prevDescriptions,
        [color]: updatedDescription,
      }));
      if (filterShareTraffic?.status) {
        const updatedDataShare = {
          ...filterShareTraffic?.data,
          lightDescription: {
            ...descriptions,
            [color]: updatedDescription,
          },
        };
        const editedShareData = {
          ...filterShareTraffic,
          data: updatedDataShare,
        };

        debouncedCreateCollectionWidgets(editedShareData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onClose = async () => {
    const closeShareData = {
      ...filterShareTraffic,
      status: false,
      data: {},
    };
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    setActiveLight("red");
    setDescriptions({
      red: "",
      yellow: "",
      green: "",
    });
    if (filterShareTraffic?.status) {
      createCollectionWidgets(closeShareData);
    }
  };

  const trafficeLightChecker = () => {
    if (!dimensionChecker) {
      if (500 < dimensions.width && dimensions.width < 900) {
        return dimensions.width / 3 + dimensions.width / 5;
      } else if (dimensions.width > 3000) {
        return dimensions.width / 2 + 200;
      } else if (1200 < dimensions.width && dimensions.width <= 1839) {
        return dimensions.width / 3 + dimensions.width / 4;
      } else if (1840 < dimensions.width && dimensions.width < 3000) {
        return dimensions.width / 3 + 462;
      }
      return dimensions.width / 3 + 200;
    }
  };

  const redLightMarginChecker = () => {
    if (!dimensionChecker) {
      if (600 < dimensions.width && dimensions.width <= 800) {
        return "23px";
      }
      if (900 < dimensions.width && dimensions.width <= 1200) {
        return "2px";
      } else if (1200 < dimensions.width && dimensions.width <= 1839) {
        return "26px";
      } else if (1840 < dimensions.width && dimensions.width < 3000) {
        return "53px";
      } else if (dimensions.width > 3000) {
        return "114px";
      } else {
        return;
      }
    }
  };

  const yellowLightMarginChecker = () => {
    if (!dimensionChecker) {
      if (900 < dimensions.width && dimensions.width <= 1200) {
        return "-40px";
      } else if (1200 < dimensions.width && dimensions.width <= 1839) {
        return "-53px";
      } else if (1840 < dimensions.width && dimensions.width < 3000) {
        return "-27px";
      }
      // else if (1200 < dimensions.width && dimensions.width <= 3000) {
      //   return "-179px";
      // }
      else if (dimensions.width > 3000) {
        return "-373px";
      }
    }
  };

  const greenLightMarginChecker = () => {
    if (!dimensionChecker) {
      if (900 < dimensions.width && dimensions.width <= 1200) {
        return "-52px";
      } else if (1200 < dimensions.width && dimensions.width <= 1839) {
        return "-59px";
      }
      //  if (1200 < dimensions.width && dimensions.width <= 3000) {
      //   return "-147px";
      // }
      else if (dimensions.width > 3000) {
        return "-290px";
      }
    }
  };

  const shareTraffic = () => {
    if (!filterShareTraffic?.id) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "traffic_signal",
        status: true,
        data: {
          activeLight: activeLight,
          lightDescription: descriptions,
        },
      };
      createCollectionWidgets(shareData);
    }
  };

  const handleTouch = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchShare = (event) => {
    event.preventDefault();
    shareTraffic();
  };

  const handleTouchLightChange = (event, light) => {
    event.preventDefault();
    if (light === "red") {
      handleLightChange("red");
    } else if (light === "green") {
      handleLightChange("green");
    } else if (light === "yellow") {
      handleLightChange("yellow");
    }
  };

  const handleTouchDescChange = (event, desc) => {
    event.preventDefault();
    if (desc === "red") {
      handleDescriptionChange("red", event);
    } else if (desc === "green") {
      handleDescriptionChange("green", event);
    } else if (desc === "yellow") {
      handleDescriptionChange("yellow", event);
    }
  };

  useEffect(() => {
    setDescriptions((prev) => ({
      ...prev,
      red: sharedData?.lightDescription?.red
        ? sharedData?.lightDescription?.red
        : "",
      yellow: sharedData?.lightDescription?.yellow
        ? sharedData?.lightDescription?.yellow
        : "",
      green: sharedData?.lightDescription?.green
        ? sharedData?.lightDescription?.green
        : "",
    }));
  }, []);

  const [isDragging, setIsDragging] = useState(false);

  return (
    <>
      {((filterByWidget?.view && filterByWidget?.menu === "traffic_signal") ||
        filterShareTraffic?.id) && (
        <Container className="allCheckListSEction wigetsContainer">
          <div className="mainCheckList">
            <ClickOutside onClickOutside={() => setIsDragging(false)}>
              {/* <ResizeAndDragabble
                defaultValue={{
                  x: 150,
                  y: 205,
                  width: isTeacher? 589 : 350,
                  height: isTeacher ? 492 : 323,
                }}
                onFocusValue={isDragging}
                minWidth={270}
                minHeight={323}
                maxWidget={1767}
                showAccordin={showAccordin}
                maxHeight={1476}
                resize={(data) => setDimensions(data)}
              >

                <div
                 onTouchStart={()=>setIsDragging(true)}
                  className="trafficModalSection"
                  style={{
                    position: "relative",
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                    width: "100%",
                    height: showAccordin ? "100%" : "0px",
                    padding: "0px",
                    border: isDragging && "3px solid #002b70" 
                  }}
                  // onTouchEnd={handleTouchEnd}
                  // onTouchMove={handleTouchMove}
              
                >
                  {isTeacher && (
                    <CancelIcon
                      onClick={() => onClose()}
                      onTouchStart={handleTouch}
                      style={{
                        marginLeft: "5px",
                        position: "absolute",
                        right: 7,
                        top: 16,
                        fontSize: 18,
                        color: "red",
                        background: "white",
                        borderRadius: 20,
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {isTeacher && (
                    <div>
                      <span
                        onClick={() => shareTraffic()}
                        onTouchStart={handleTouchShare}
                        style={{
                          marginLeft: "5px",
                          position: "absolute",
                          right: 34,
                          top:17,
                          cursor: "pointer"
                        }}
                      >
                         {!filterShareTraffic?.status ? 
                      <ShareIcon style={{ color: "white", fontSize: 16 }} />
                        : <AnimatedCheckMark />
                      }
                        
                      </span>
                    </div>
                  )}
                  <div
                  className={`checkListFirstTxt bg-blue-10 pt-17px pb-14px ${
                    showAccordin ? "br-topLeft-topRight-15px" : "br-20px"
                  } `}
                >
                  <AccordinButton open={showAccordin} onClose={()=>setShowAccordin(prev => !prev)} />
                  <span className="text-white text-ft12-700">Traffic Signal</span>
                  </div>
                  {showAccordin && (
                    <div
                    className="traffic-light-container"
                    style={{
                      padding: !dimensionChecker && "10px",
                      marginTop: dimensionChecker
                        ? dimensions.width / 28
                        : dimensions.width / 8,
                      display: dimensionChecker && "grid",
                      placeItems: !dimensionChecker && "center",
                      marginLeft:  "14px"
                    }}
                  >
                    <div
                      style={{
                        width: dimensionChecker
                          ? dimensions.width / 3 + 25
                          : dimensions.width / 3,
                        maxWidth: !dimensionChecker
                          ? dimensions.width - 137
                          : "unset",
                        height: trafficeLightChecker(),
                        marginInline:
                          !isTeacher &&
                          Object.values(sharedData?.lightDescription).every(
                            (value) => value === ""
                          ) && "auto",
                      }}
                    
                      className="traffic-light"
                    >
                      <div className="trafficMidSEction">
                        <div
                          style={{
                            width: dimensions.width / 10 + 4,
                            height: dimensions.width / 10 + 4,
                            marginTop: redLightMarginChecker(),
                          }}
                          onClick={() => handleLightChange("red")}
                          onTouchStart={(e) => handleTouchLightChange(e, "red")}
                          className={`light red ${sharedData?.activeLight
                            ? sharedData?.activeLight === "red"
                              ? "active"
                              : "inactive"
                            : activeLight === "red"
                              ? "active"
                              : "inactive"
                            }`}
                        ></div>
                        { (
                          <>
                            {!isTeacher ? (
                              sharedData?.lightDescription?.red ? (
                                <textarea
                                  style={{
                                    width:  dimensionChecker ? dimensions?.width / 2.6 : Math.min(
                                      dimensions.width - 320,
                                      dimensions.height +
                                      50 -
                                      trafficeLightChecker()
                                    ),
                                    fontSize: dimensionChecker && "12px",
                                    marginLeft: dimensionChecker ? dimensions?.width / 12: dimensions?.width / 5,
                                    background: "none",
                                  }}
                                  className="redLightSection red-light red-10"
                                  placeholder="Red light description"
                                  value={sharedData?.lightDescription?.red}
                                  onChange={(event) =>
                                    handleDescriptionChange("red", event)
                                  }
                                
                                  disabled={!isTeacher ? true : false}
                                  onTouchStart={(e) => handleTouchDescChange(e, "red")}
                                />
                              ) : null
                            ) : (
                              <textarea
                                style={{
                                  width:  dimensionChecker ? dimensions?.width / 2.6 : Math.min(
                                    dimensions.width - 320,
                                    dimensions.height +
                                    50 -
                                    trafficeLightChecker()
                                  ),
                                  fontSize: dimensionChecker && "12px",
                                  marginLeft: dimensionChecker ? dimensions?.width / 12: dimensions?.width / 5,
                                }}
                                className="redLightSection red-light red-10"
                                placeholder="Red light description"
                                value={descriptions.red}
                                onChange={(event) =>
                                  handleDescriptionChange("red", event)
                                }
                                disabled={!isTeacher ? true : false}
                              />
                            )}
                          </>
                        )}
                      </div>

                      <div className="trafficMidSEction">
                        <div
                          style={{
                            width: dimensions.width / 10 + 4,
                            height: dimensions.width / 10 + 4,
                            marginTop: yellowLightMarginChecker(),
                          }}
                          onClick={() => handleLightChange("yellow")}
                          onTouchStart={(e) => handleTouchLightChange(e, "yellow")}
                          className={`light yellow ${sharedData?.activeLight
                            ? sharedData?.activeLight === "yellow"
                              ? "active"
                              : "inactive"
                            : activeLight === "yellow"
                              ? "active"
                              : "inactive"
                            }`}
                        ></div>
                        { (
                          <>
                            {!isTeacher ? (
                              sharedData?.lightDescription?.yellow ? (
                                <textarea
                                  style={{
                                    width:  dimensionChecker ? dimensions?.width / 2.6 : Math.min(
                                      dimensions.width - 320,
                                      dimensions.height +
                                      50 -
                                      trafficeLightChecker()
                                    ),
                                    fontSize: dimensionChecker && "12px",
                                    marginLeft: dimensionChecker ? dimensions?.width / 12: dimensions?.width / 5,
                                    background: "none"
                                  }}
                                  className="redLightSection yellow-light yellow-10"
                                  placeholder="Yellow light description"
                                  value={sharedData?.lightDescription?.yellow}
                                  onChange={(event) => {
                                    handleDescriptionChange("yellow", event);
                                  }}
                                  onTouchStart={(e) => handleTouchDescChange(e, "yellow")}
                                  disabled={!isTeacher ? true : false}
                                />
                              ) : null
                            ) : (
                              <textarea
                                style={{
                                  width:  dimensionChecker ? dimensions?.width / 2.6 : Math.min(
                                    dimensions.width - 320,
                                    dimensions.height +
                                    50 -
                                    trafficeLightChecker()
                                  ),
                                  fontSize: dimensionChecker && "12px",
                                  marginLeft: dimensionChecker ? dimensions?.width / 12: dimensions?.width / 5,
                                }}
                                className="redLightSection yellow-light yellow-10"
                                placeholder="Yellow light description"
                                value={descriptions.yellow}
                                onChange={(event) => {
                                  handleDescriptionChange("yellow", event);
                                }}
                                disabled={!isTeacher ? true : false}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div className="trafficMidSEction">
                        <div
                          style={{
                            width: dimensions.width / 10 + 4,
                            height: dimensions.width / 10 + 4,
                            marginTop: greenLightMarginChecker(),
                          }}
                          onClick={() => handleLightChange("green")}
                          onTouchStart={(e) => handleTouchLightChange(e, "green")}
                          className={`light green ${sharedData?.activeLight
                            ? sharedData?.activeLight === "green"
                              ? "active"
                              : "inactive"
                            : activeLight === "green"
                              ? "active"
                              : "inactive"
                            }`}
                        ></div>

                        { (
                          <>
                            {!isTeacher ? (
                              sharedData?.lightDescription?.green ? (
                                <textarea
                                  style={{
                                    width:  dimensionChecker ? dimensions?.width / 2.6 : Math.min(
                                      dimensions.width - 320,
                                      dimensions.height +
                                      50 -
                                      trafficeLightChecker()
                                    ),
                                    fontSize: dimensionChecker && "12px",
                                    marginLeft: dimensionChecker ? dimensions?.width / 12: dimensions?.width / 5,
                                    background: "none"
                                  }}
                                  className="redLightSection green-light green-10"
                                  placeholder="Green light description"
                                  value={sharedData?.lightDescription?.green}
                                  onChange={(event) =>
                                    handleDescriptionChange("green", event)
                                  }
                                  onTouchStart={(e) => handleTouchDescChange(e, "green")}
                                  disabled={!isTeacher ? true : false}
                                />
                              ) : null
                            ) : (
                              <textarea
                                style={{
                                  width:  dimensionChecker ? dimensions?.width / 2.6 : Math.min(
                                    dimensions.width - 320,
                                    dimensions.height +
                                    50 -
                                    trafficeLightChecker()
                                  ),
                                  fontSize: dimensionChecker && "12px",
                                  marginLeft: dimensionChecker ? dimensions?.width / 12: dimensions?.width / 5,
                                }}
                                className="redLightSection green-light green-10"
                                placeholder="Green light description"
                                value={descriptions.green}
                                onChange={(event) =>
                                  handleDescriptionChange("green", event)
                                }
                                disabled={!isTeacher ? true : false}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  )}
                  
                </div>
              </ResizeAndDragabble> */}

              <ResizeAndDragabble
                defaultValue={{
                  x: 150,
                  y: 205,
                  width: isTeacher ? 589 : 350,
                  height: isTeacher ? 492 : 323,
                }}
                onFocusValue={isDragging}
                minWidth={270}
                minHeight={323}
                maxWidget={1767}
                showAccordin={showAccordin}
                maxHeight={1476}
                resize={(data) => setDimensions(data)}
              >
                <div
                  onTouchStart={(event) => {
                    event.stopPropagation();
                    if(!isTeacher){
                      return;
                    }
                    setIsDragging(true);
                  }}
                  className={`trafficModalSection ${
                    isDragging && showAccordin ? "widgetMobAndTab" : ""
                  }`}
                  style={{
                    position: "relative",
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                    width: "100%",
                    height: showAccordin ? "100%" : "0px",
                    padding: "0px",
                  }}
                >
                  {isTeacher && (
                    <CancelIcon
                      onClick={() => onClose()}
                      onTouchStart={handleTouch}
                      style={{
                        marginLeft: "5px",
                        position: "absolute",
                        right: 7,
                        top: 16,
                        fontSize: 18,
                        color: "red",
                        background: "white",
                        borderRadius: 20,
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {isTeacher && (
                    <div>
                      <span
                        onClick={() => shareTraffic()}
                        onTouchStart={handleTouchShare}
                        style={{
                          marginLeft: "5px",
                          position: "absolute",
                          right: 34,
                          top: 17,
                          cursor: "pointer",
                        }}
                      >
                        {!filterShareTraffic?.status ? (
                          <ShareIcon style={{ color: "white", fontSize: 16 }} />
                        ) : (
                          <AnimatedCheckMark />
                        )}
                      </span>
                    </div>
                  )}
                  <div
                    className={`checkListFirstTxt bg-blue-10 pt-17px pb-14px ${
                      showAccordin ? "br-topLeft-topRight-15px" : "br-20px"
                    } `}
                  >
                    <AccordinButton
                      open={showAccordin}
                      isDragging={isDragging}
                      onClose={() => {
                        setIsDragging(false);
                        setShowAccordin((prev) => !prev);
                      }}
                    />
                    <span className="text-white text-ft12-700">
                      Traffic Signal
                    </span>
                  </div>
                  {showAccordin && (
                    <div 
                      className="traffic-light-container"
                      style={{
                        padding: !dimensionChecker && "10px",
                        marginTop: dimensionChecker
                          ? dimensions.width / 28
                          : dimensions.width / 8,
                        display: dimensionChecker && "grid",
                        placeItems: !dimensionChecker && "center",
                        marginLeft: "14px",
                      }}
                    >
                      <div
                        style={{
                          width: dimensionChecker
                            ? dimensions.width / 3 + 25
                            : dimensions.width / 3,
                          maxWidth: !dimensionChecker
                            ? dimensions.width - 137
                            : "unset",
                          height: trafficeLightChecker(),
                          marginInline:
                            !isTeacher &&
                            Object.values(sharedData?.lightDescription).every(
                              (value) => value === ""
                            ) &&
                            "auto",
                        }}
                        className="traffic-light"
                      >
                        <div className="trafficMidSEction">
                          <div
                            style={{
                              width: dimensions.width / 10 + 4,
                              height: dimensions.width / 10 + 4,
                              marginTop: redLightMarginChecker(),
                            }}
                            onClick={() => handleLightChange("red")}
                            // onTouchStart={(e) =>
                            //   handleTouchLightChange(e, "red")
                            // }
                            className={`light red ${
                              sharedData?.activeLight
                                ? sharedData?.activeLight === "red"
                                  ? "active"
                                  : "inactive"
                                : activeLight === "red"
                                ? "active"
                                : "inactive"
                            }`}
                          ></div>
                          {
                            <>
                              {!isTeacher ? (
                                sharedData?.lightDescription?.red ? (
                                  <textarea
                                    style={{
                                      width: dimensionChecker
                                        ? dimensions?.width / 2.6
                                        : Math.min(
                                            dimensions.width - 320,
                                            dimensions.height +
                                              50 -
                                              trafficeLightChecker()
                                          ),
                                      fontSize: dimensionChecker && "12px",
                                      marginLeft: dimensionChecker
                                        ? dimensions?.width / 12
                                        : dimensions?.width / 5,
                                      background: "none",
                                    }}
                                    className="redLightSection red-light red-10"
                                    placeholder="Red light description"
                                    value={sharedData?.lightDescription?.red}
                                    onChange={(event) =>
                                      handleDescriptionChange("red", event)
                                    }
                                    disabled={!isTeacher}
                                    onTouchStart={(e) =>
                                      handleTouchDescChange(e, "red")
                                    }
                                  />
                                ) : null
                              ) : (
                                <textarea
                                  style={{
                                    width: dimensionChecker
                                      ? dimensions?.width / 2.6
                                      : Math.min(
                                          dimensions.width - 320,
                                          dimensions.height +
                                            50 -
                                            trafficeLightChecker()
                                        ),
                                    fontSize: dimensionChecker && "12px",
                                    marginLeft: dimensionChecker
                                      ? dimensions?.width / 12
                                      : dimensions?.width / 5,
                                  }}
                                  className="redLightSection red-light red-10"
                                  placeholder="Red light description"
                                  value={descriptions.red}
                                  onChange={(event) =>
                                    handleDescriptionChange("red", event)
                                  }
                                  disabled={!isTeacher}
                                />
                              )}
                            </>
                          }
                        </div>

                        <div className="trafficMidSEction">
                          <div
                            style={{
                              width: dimensions.width / 10 + 4,
                              height: dimensions.width / 10 + 4,
                              marginTop: yellowLightMarginChecker(),
                            }}
                            onClick={() => handleLightChange("yellow")}
                            // onTouchStart={(e) =>
                            //   handleTouchLightChange(e, "yellow")
                            // }
                            className={`light yellow ${
                              sharedData?.activeLight
                                ? sharedData?.activeLight === "yellow"
                                  ? "active"
                                  : "inactive"
                                : activeLight === "yellow"
                                ? "active"
                                : "inactive"
                            }`}
                          ></div>
                          {
                            <>
                              {!isTeacher ? (
                                sharedData?.lightDescription?.yellow ? (
                                  <textarea
                                    style={{
                                      width: dimensionChecker
                                        ? dimensions?.width / 2.6
                                        : Math.min(
                                            dimensions.width - 320,
                                            dimensions.height +
                                              50 -
                                              trafficeLightChecker()
                                          ),
                                      fontSize: dimensionChecker && "12px",
                                      marginLeft: dimensionChecker
                                        ? dimensions?.width / 12
                                        : dimensions?.width / 5,
                                      background: "none",
                                    }}
                                    className="redLightSection yellow-light yellow-10"
                                    placeholder="Yellow light description"
                                    value={sharedData?.lightDescription?.yellow}
                                    onChange={(event) =>
                                      handleDescriptionChange("yellow", event)
                                    }
                                    onTouchStart={(e) =>
                                      handleTouchDescChange(e, "yellow")
                                    }
                                    disabled={!isTeacher}
                                  />
                                ) : null
                              ) : (
                                <textarea
                                  style={{
                                    width: dimensionChecker
                                      ? dimensions?.width / 2.6
                                      : Math.min(
                                          dimensions.width - 320,
                                          dimensions.height +
                                            50 -
                                            trafficeLightChecker()
                                        ),
                                    fontSize: dimensionChecker && "12px",
                                    marginLeft: dimensionChecker
                                      ? dimensions?.width / 12
                                      : dimensions?.width / 5,
                                  }}
                                  className="redLightSection yellow-light yellow-10"
                                  placeholder="Yellow light description"
                                  value={descriptions.yellow}
                                  onChange={(event) =>
                                    handleDescriptionChange("yellow", event)
                                  }
                                  disabled={!isTeacher}
                                />
                              )}
                            </>
                          }
                        </div>
                        <div className="trafficMidSEction">
                          <div
                            style={{
                              width: dimensions.width / 10 + 4,
                              height: dimensions.width / 10 + 4,
                              marginTop: greenLightMarginChecker(),
                            }}
                            onClick={() => handleLightChange("green")}
                            // onTouchStart={(e) =>
                            //   handleTouchLightChange(e, "green")
                            // }
                            className={`light green ${
                              sharedData?.activeLight
                                ? sharedData?.activeLight === "green"
                                  ? "active"
                                  : "inactive"
                                : activeLight === "green"
                                ? "active"
                                : "inactive"
                            }`}
                          ></div>

                          {
                            <>
                              {!isTeacher ? (
                                sharedData?.lightDescription?.green ? (
                                  <textarea
                                    style={{
                                      width: dimensionChecker
                                        ? dimensions?.width / 2.6
                                        : Math.min(
                                            dimensions.width - 320,
                                            dimensions.height +
                                              50 -
                                              trafficeLightChecker()
                                          ),
                                      fontSize: dimensionChecker && "12px",
                                      marginLeft: dimensionChecker
                                        ? dimensions?.width / 12
                                        : dimensions?.width / 5,
                                      background: "none",
                                    }}
                                    className="redLightSection green-light green-10"
                                    placeholder="Green light description"
                                    value={sharedData?.lightDescription?.green}
                                    onChange={(event) =>
                                      handleDescriptionChange("green", event)
                                    }
                                    onTouchStart={(e) =>
                                      handleTouchDescChange(e, "green")
                                    }
                                    disabled={!isTeacher}
                                  />
                                ) : null
                              ) : (
                                <textarea
                                  style={{
                                    width: dimensionChecker
                                      ? dimensions?.width / 2.6
                                      : Math.min(
                                          dimensions.width - 320,
                                          dimensions.height +
                                            50 -
                                            trafficeLightChecker()
                                        ),
                                    fontSize: dimensionChecker && "12px",
                                    marginLeft: dimensionChecker
                                      ? dimensions?.width / 12
                                      : dimensions?.width / 5,
                                  }}
                                  className="redLightSection green-light green-10"
                                  placeholder="Green light description"
                                  value={descriptions.green}
                                  onChange={(event) =>
                                    handleDescriptionChange("green", event)
                                  }
                                  disabled={!isTeacher}
                                />
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </ResizeAndDragabble>
            </ClickOutside>
          </div>
        </Container>
      )}
    </>
  );
};

export default TrafficModal;
