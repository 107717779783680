//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { bool, number } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {Logo_transparent } from "utils/assets/images"


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


export const Wrapper = styled.svg`
    height: ${p => `${p.height}px`};
    width: auto;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Logo
//
//  -- Description
//  Experimental app logo
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const Logo = ({ height, toggleWhite }) => (
    <img alt='logo' style={{height: '160px'}} src={Logo_transparent}></img>
);

//  -- Prop types ---------------------
Logo.propTypes = {
    height: number,
    toggleWhite: bool,
};

//  -- Default props ------------------
Logo.defaultProps = {
    height: 32,
};

export default Logo;