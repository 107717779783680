//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'lodash';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selectore = (state) => state.tenant;
//  -- Examples array selector --------

export const useTenant = () => {
    const selectedData = useSelector(selectore, shallowEqual)
   return selectedData;
}
