//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState, useRef, useCallback } from 'react';
// import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
//  -- Components ---------------------
import LoadingWrapper from 'components/LoadingWrapper';
//  -- Thunks -------------------------
import { get, map, find, groupBy, isEmpty, filter , has } from 'lodash';
import { lighten } from 'polished';
import Users from "./users"
import Messages from "./messages"
import { handleGetChatUsers, handleGetMessagesChat , handleGetScheduleMessage} from 'store/chat/thunks';
import { useChatUser, useChatMessage, useSocket } from 'store/chat/selectors';
import { useCurrentClass } from 'store/classes/selectors';
import { sortMessage } from 'utils/methods/math';
// import { useOrgTimezone } from 'store/organizations/selectors';
import { Slide } from '@material-ui/core';
// import {useIsMobile} from "./hooks"
const FILE_TYPE = {
    'application/msword': "word",
    "application/vnd.ms-excel": "excel",
    "application/vnd.ms-powerpoint": "powerpoint",
    "text/plain": "text",
    "application/pdf": "pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "word",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "excel",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "powerpoint"
}
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
	height: 100%;
    width:100%;
    border:2px solid #998d8d80;
    border-radius:0px;
    // background:#515251;
    background:#797979;
    margin:auto;
    margin-top:0px;
    min-height: 88vh;
    position: relative;
    z-index: 999;
    @media (max-width: 768px) {
        min-height: initial;
    }
`;
const WrapperForm = styled.form`
	padding: 40px 0px;
	width: 90%;
`;
const Error = styled.div`
    background-color: ${lighten(0.4, 'red')};
    border: 1px solid red;
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin: 0px auto 16px;
    max-width: 480px;
    padding: 8px;
    text-align: center;
    width: 100%;
`;
const Label = styled.div`
   font-size:14px;
   color:${COLOR.white};
`;
const LabelTop = styled.div`
   font-size:18px;
   color:${COLOR.white};
`;

const RowArea = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    @media (max-width: 768px) {
       display: block;
       flex-direction: initial;
     }
`;
const UserColArea = styled.div`
    width:20%;
    display:flex;
    border-right: 1px solid #a5a5a5;
    @media (max-width: 992px) {
        display: block;
        width:100%;
     }
`;
const MessageColArea = styled.div`
    width:80%;
    display:flex;
    flex-direction:row;
    @media (max-width: 992px) {
        width:100%;
     }
`;
const Container = styled.div`
    display:flex;
    flex-direction:column;
    height:75vh;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------

const Chat = ({
    currentUser
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const chatUser = useChatUser()
    const socketRef = useRef()
    const [activeUser, setactiveUser] = useState([])
    const [activeClass, setactiveClass] = useState({})
    const currentClass = useCurrentClass()
    const [selectedUser, setSelectedUser] = useState(null)
    const chatMessages = useChatMessage()
    // console.log(activeUser,"activeUser*****");
    // const isMobile = useIsMobile()
    // const timezone = useOrgTimezone()
    const { socketConnection, reCreateSocket } = useSocket()

    useEffect(() => {
        if (socketConnection) {
            socketRef.current = socketConnection
        }
    }, [socketConnection])
    useEffect(() => {
        if (currentClass && currentClass.id && chatUser) {
            const currentClassChatUser = find(chatUser, c => get(c, "classes.id") === currentClass.id)
            if (isEmpty(activeClass)) {
                setactiveUser(get(currentClassChatUser, "user", []))
                setSelectedUser(get(currentClassChatUser, "user", [])[0])
                setactiveClass(get(currentClassChatUser, "classes", {}))
            } else {
                const currentClassChatUser = find(chatUser, c => get(c, "classes.id") === activeClass.id)
                if (has(currentClassChatUser, "user")) {
                    setactiveUser(get(currentClassChatUser, "user", []))
                    if (selectedUser) {
                        let _selectedUser = find(get(currentClassChatUser, "user", []), n => n.id === selectedUser.id)
                        if (_selectedUser) {
                            setSelectedUser(_selectedUser)
                        }
                    }
                    setactiveClass(get(currentClassChatUser, "classes", {}))
                }
            }
        }
    }, [currentClass, chatUser])
    // useEffect(()=>{
    //     if(perSelectedUser&&!selectedUser){
    //         setactiveUser(preActiveUser)
    //         setSelectedUser(perSelectedUser)
    //         setactiveClass(preActiveClass)
    //     }
    // },[preActiveClass,preActiveUser,perSelectedUser])
    const userLastmessage = (userId) => {
        return sortMessage(filter(chatMessages, c => c.receiverId && (c.senderId === userId || c.receiverId === userId))).reverse()[0]
    }
    const getRecentMessage = (users,classId) =>{
        const userMessages = { ...groupBy(chatMessages, "senderId"), ...groupBy(chatMessages, "receiverId"), ...groupBy(chatMessages, "brodcastId") }
        const userMessagesData = map(Object.keys(userMessages), key => {
            let usr = find(users, ['id', key])
            if(!usr) return null;
            const mData = userLastmessage(key)
            return ({
                usr,
                mData
            })
        }).filter(k => k);
       return  userMessagesData.sort((a, b) => (new Date(get(b, "mData.createdAt")) - new Date(get(a, "mData.createdAt"))))
    }
    const init = async () => {
        setLoading(!chatUser || chatUser.length == 0)
        await dispatch(handleGetChatUsers(currentUser.id))
        await dispatch(handleGetMessagesChat(currentUser.id))
        if(get(currentUser,"role")=="teacher"){
            await dispatch(handleGetScheduleMessage(currentUser?.id))
        }
        setLoading(false)
    }
    useEffect(() => {
        if (currentUser && currentUser.id) {
            init()
        }
    }, [currentUser])

    const onClassSelect = (id) => {
        const currentClassChatUser = find(chatUser, c => get(c, "classes.id") === id)
        const classUsersRecent = getRecentMessage(get(currentClassChatUser, "user", []),get(currentClassChatUser, "classes.id"))
        setactiveUser(get(currentClassChatUser, "user", []))
        setSelectedUser(get(classUsersRecent,"0.usr"))
        setactiveClass(get(currentClassChatUser, "classes", {}))
    }

    const getMessage = useCallback(() => {
        if (selectedUser && selectedUser.id) {
            const brodcastMessage = groupBy(chatMessages, "brodcastId")
            const selectedUserId = selectedUser.id
            if (brodcastMessage[selectedUserId]) {
                return sortMessage(brodcastMessage[selectedUserId]);
            } else {
                return sortMessage(filter(chatMessages, k => k.receiverId && (k.senderId == selectedUserId || k.receiverId && k.receiverId == selectedUserId)))
            }
        } else {
            return []
        }
    }, [selectedUser, chatMessages])

    return (
        <div className="msgSystemMain">
            <LoadingWrapper className="msgSystemLoad" loading={loading}>
                <Wrapper>

                    <RowArea className="msgSystemAra msgSystemWeb">
                        <UserColArea className="msgSystemAraUsr">
                            <Users
                                socket={socketRef.current}
                                activeUser={activeUser}
                                activeClass={activeClass}
                                classes={map(chatUser, u => u.classes)}
                                chatUser={chatUser}
                                onClassSelect={onClassSelect}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                chatMessages={chatMessages}
                                FILE_TYPE={FILE_TYPE}
                            />
                        </UserColArea>
                        <MessageColArea className="msgSystemType">
                            <Messages
                                socket={socketRef.current}
                                socketRenew={reCreateSocket}
                                selectedUser={selectedUser}
                                activeClass={activeClass}
                                activeUser={activeUser}
                                message={getMessage()}
                                FILE_TYPE={FILE_TYPE}
                                classes={map(chatUser, u => u.classes)}
                                onClassSelect={onClassSelect}
                            />
                        </MessageColArea>
                    </RowArea>

                    <Container className="msgSystemMob">
                        <Slide direction="left" in={!selectedUser} mountOnEnter unmountOnExit
                            style={{ background: "transparent", paddingLeft: 10, paddingRight: 10 }}>
                            <UserColArea className="msgSystemAraUsr">
                            <Users
                                socket={socketRef.current}
                                activeUser={activeUser}
                                activeClass={activeClass}
                                classes={map(chatUser, u => u.classes)}
                                chatUser={chatUser}
                                onClassSelect={onClassSelect}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                chatMessages={chatMessages}
                                FILE_TYPE={FILE_TYPE}
                            />
                        </UserColArea>
                        </Slide>
                        {<Slide direction="right" in={!!selectedUser} mountOnEnter unmountOnExit>
                            <MessageColArea className="msgSystemType">
                                <Messages
                                    socket={socketRef.current}
                                    socketRenew={reCreateSocket}
                                    selectedUser={selectedUser}
                                    activeClass={activeClass}
                                    message={getMessage()}
                                    FILE_TYPE={FILE_TYPE}
                                    classes={map(chatUser, u => u.classes)}
                                    onClassSelect={onClassSelect}
                                    setSelectedUser={setSelectedUser}
                                />
                            </MessageColArea>
                        </Slide>}
                    </Container>
               
                </Wrapper>
            </LoadingWrapper>
        </div>
    );
};

//  -- Prop types ---------------------
Chat.propTypes = {};

//  -- Default props ------------------
Chat.defaultProps = {

};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Chat);
