import { Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Close, Delete, Edit } from "@material-ui/icons";
import { Autocomplete } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { Col, Dropdown, Menu, Row } from 'antd';
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import { DeleteModal } from "components/Dialog";
import Loading from 'components/Loading';
import FlatList from 'flatlist-react';
import { cloneDeep, filter, find, findIndex, get, has, isEmpty, map, sortBy } from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAllBook } from 'store/books/selectors';
import { useStory } from 'store/story/selectors';
import { handleCreateStories, handleDeleteStories, handleListStory, handleListStoryStandard, handleUpdateStories } from 'store/story/thunks';
import { useCurrentUser } from 'store/users/selectors';
import styled from 'styled-components';
import { MenuIcon, Pic1, SearchIcon } from "utils/assets/images";
import COLOR from "utils/constants/color";

const PAGE_CATEGORY = [
    'All',
    'Entretenimiento',
    'Tecnología',
    'Deportes',
    'Historias',
    'Cultura',
    'Música',
    'Curiosidades',
    'Cine',
    'Fantasía',
    'Realismo',
    'Ciencia ficción',
    'Magia',
    'Espacio',
    '6-8th ',
    '9-10th',
    '11-12th'
]

const IB_THEME = [
    'Identidad',
    'Experiencias',
    'Ingenio humano',
    'Organización social',
    'Compartir el planeta',
    
]
const AP_THEME = [
    "Desafíos globales",
    "Ciencia y tecnología",
    "Vida contemporánea",
    "Identidades personales y públicas",
    "Familias y comunidades",
    "Belleza y estética",
    "Las sociedades en contacto",
    "La construcción del género",
    "El tiempo y el espacio",
    "La creación literaria",
    "Las relaciones interpersonales",
    "La dualidad del ser"
];

const ANÉCDOTAS_THEMES = [
    'La unión como base',
    'Los tipos de relaciones en la sociedad',
    'La familia, el núcleo de la sociedad',
    'La juventud, mi juventud',
    'En busca de la paz',
    'Mi mundo que sigue innovando',
    'Los valores y mis responsabilidades',
    'La inmigración en el mundo',
    'Mi vida contemporánea',
    'Las redes nos enredan',
    'La influencia de los influencers',
    'Mi identidad en el mundo',
    'La fantasía y la imaginación',
    'La forma de vivir: ¿De vuelta a la normalidad o nueva normalidad?',
    'Mi comunidad es también mi familia',
    'Mi salud, mi vida',
    'Una historia para contar: Somos latinos, somos hispanos',
    'Enfrentando los desafíos del mundo',
    'Las familias y su enlace con la sociedad',
    'Las sociedades en contacto',
    'Las familias en diferentes sociedades',
    'El impacto de la cultura y el lenguaje en las identidades',
    'La construcción del género',
    'La influencia de la lengua y la cultura en la identidad',
    'Contemplando el arte como influencia en la vida',
    'La línea del tiempo',
    'La influencia de la belleza y el arte',
    'La tecnología y sus beneficios en la vida diaria',
    'El arte, un puente hacia la literatura',
    'Cómo la ciencia y la tecnología afectan nuestras vidas',
    'La vida contemporánea y sus cambios en las comunidades',
    'Interconexión, colaboración y resultados',
    'Los factores que impactan la calidad de nuestra vida',
    'Las sociedades frente a los retos',
    'Las múltiples facetas del ser humano',
    'Los cambios ambientales, políticos y sociales'
];


const ModalContent = styled.div`
  left: 50%;
  width: 80vw;
  height:90vh;
  padding: 0px 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color:#fff;
  padding:10px;
  overflow: auto;
   @media only screen and (max-width: 600px) {
        width: 90%;
    }
`;
const ModalConfigArea = styled.div`
  margin-top:10px;
  padding:20px;
`;
const CoverText = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
`;
const LabelText = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    font-weight: 800;
    margin-top: 12px;
    margin-bottom: 12px
`;
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const MenuIconImg = styled.img`
    margin-left:10px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
`;
const SearchInput = styled.input`
    width:100px;
    color:#fff;
    border:none;
    background: transparent;
    outline: none;
    text-align: center;
    font-weight: 800;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${COLOR.white};
    }
    ::focus{
        outline: none;
    }
`;
const CategoryMenu = styled.div`
    background-color:${COLOR.tabActive};
    border-radius: 0px 12px 12px 12px;
    color: #fff;
    height: 25px;
    padding-left: 20px;
    padding-right: 2px;
    margin-top: 20px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:150px;
    align-items: center;
    cursor: pointer;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    margin-left:5px;
    margin-top:5px;
`;

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    formControl: {
        minWidth: 120
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    }
}));

const SaveModal = ({ saveModal, setSaveModal, storyies, booksAllList, redirectToBook }) => {
    const [error, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const edit = has(storyies, 'id')
    const classes = useStyles();
    const [data, setData] = useState({})
    const dispatch = useDispatch()
    const onChange = (key, value) => {
        const dt = cloneDeep(data);
        dt[key] = value;
        setData(dt);
        setErrors([])
    }
    const onCreateOrUpdate = async () => {
        let err = []
        if (!data.title) {
            err.push(`title required!`)
        }
        if (!data.bookId) {
            err.push(`book required!`)
        }
        if (!data.img) {
            err.push(`Cover image required!`)
        }
        if (!data.levels) {
            err.push(`Please set correctly page id into levels`)
        } else {
            map(data.levels, lv => {
                if (!(parseInt(lv.start) >= 0 && parseInt(lv.finish) > 0)) {
                    err.push(`Please set correctly page id into ${lv.level} `)
                } else if (parseInt(lv.start) > parseInt(lv.finish)) {
                    err.push(`Page end id must be greater than start id into ${lv.level} `)
                }
            })
        }
        if (err.length == 0) {
            setLoading(true)
            if (edit) {
                await dispatch(handleUpdateStories(data.id, data))
            } else {
                await dispatch(handleCreateStories(data))
            }
            setLoading(false)
            setSaveModal(false)
        } else {
            setErrors(err)
        }


    }
    const onChangeData = (key, value) => {
        // const dt = cloneDeep(videoItem)
        // dt[key] = value
        // setVideoItem(dt)
    }
    useEffect(() => {
        setErrors([])
        setLoading(false)
        if (Object.keys(storyies).length > 0) {
            setData(storyies)
        } else {
            setData({
                bookId: null,
                img: null,
                title: '',
                categorias: '',
                levels: [
                    {
                        level: 'PREBÁSICO',
                        start: '',
                        finish: '',
                    },
                    {
                        level: 'BASICO',
                        start: '',
                        finish: '',
                    },
                    {
                        level: 'INTERMEDIO',
                        start: '',
                        finish: '',
                    },
                    {
                        level: 'AVANZADO',
                        start: '',
                        finish: '',
                    }
                ]

            })
        }

    }, [storyies])
    const onLevelsPageUpdate = (level, key, value) => {
        const dt = cloneDeep(data);
        if (dt.levels) {
            const levelIndex = findIndex(dt.levels, lv => lv.level == level)
            if (levelIndex >= 0) {
                dt.levels[levelIndex][key] = value;
            } else {
                const lv = { level: level }
                lv[key] = value;
                dt.levels.push(lv)
            }
        } else {
            const lv = { level: level }
            lv[key] = value;
            dt.levels = [lv]
        }
        setData(dt)
        setErrors([])
    }
    const PREBÁSICO = find(data.levels, k => k.level === 'PREBÁSICO')
    const BASICO = find(data.levels, k => k.level === 'BASICO')
    const INTERMEDIO = find(data.levels, k => k.level === 'INTERMEDIO')
    const AVANZADO = find(data.levels, k => k.level === 'AVANZADO')
    return (<Modal
        open={saveModal}
        onClose={() => {
            setSaveModal(false)
            setErrors([])
        }}
        style={{ height: '100%', width: '100%' }}
    >
        <ModalContent>
            <Row style={{ justifyContent: "space-between" }}>
                <TitleText>{edit ? 'Update Story' : 'Add Story'}</TitleText>
                <IconButton onClick={() => setSaveModal(false)} style={{ padding: 0, marginRight: 10 }}>
                    <Close color="secondary" />
                </IconButton>
            </Row>
            <ModalConfigArea>
                <Row gutter={[24,24]}>
                    <Col xs={24} lg={12}>
                        <CoverText>Cover image</CoverText>
                        <PageImagePicker
                            src={get(data, "img")}
                            onChange={item => onChange("img", item.url)}
                            pageId={null}
                            hasDescription={false}
                        />
                    </Col>
                    <Col style={{ paddingLeft: 10, paddingTop: 10 }} xs={24} lg={12}>
                        <Row justify="start" style={{ marginBottom: 20, }}>
                            <TextField
                                id="title"
                                name="title"
                                label="title"
                                InputLabelProps={{ shrink: true, required: true }}
                                type="text"
                                required
                                variant="outlined"
                                onChange={e => {
                                    onChange("title", e.target.value)
                                }}
                                value={get(data, "title")}
                            />
                        </Row>

                        <Row
                            gutter={[24, 24]}
                            style={{ marginTop: 10, marginRight: 0, marginLeft: 0, marginBottom: 20 }}
                        >
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel style={{ backgroundColor: 'white' }} id="bookId">Select Book</InputLabel>
                                <Select
                                    labelId="bookId"
                                    id="bookId"
                                    value={`${get(data, "bookId")}`}
                                    onChange={(e) => {
                                        onChange("bookId", e.target.value);
                                    }}
                                >
                                    {map(booksAllList, (bk) => (
                                        <MenuItem key={bk.id} value={bk.id}>{`${bk.description || ""
                                            }(${bk.id || ""})`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Row>
                        <Row justify="start" style={{ marginBottom: 20, marginTop: 10 }} gutter={[24, 24]}>
                            <Col lg={24} xs={24}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    size="small"
                                    className='categorias-story'
                                    id="categorias-outlined"
                                    options={PAGE_CATEGORY}
                                    defaultValue={get(data, "categorias", '') ? get(data, "categorias", '').split(",") : []}
                                    style={{ width: "100%", minHeight: 30 }}
                                    onChange={(event, value) => {
                                        onChange("categorias", value.join(","));
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            type="text"
                                            label="Categorias"
                                            placeholder="Categorias"
                                        />
                                    )}
                                />
                            </Col>
                        </Row>

                        <Row justify="start" style={{ marginBottom: 20, marginTop: 10 }} gutter={[24, 24]}>
                            <Col lg={24} xs={24}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    size="small"
                                    className='categorias-story'
                                    id="anecdotastheme-outlined"
                                    options={ANÉCDOTAS_THEMES}
                                    defaultValue={get(data, "anécdotas_themes", '') ? get(data, "anécdotas_themes", '').split(",") : []}
                                    style={{ width: "100%", minHeight: 30 }}
                                    onChange={(event, value) => {
                                        onChange("anécdotas_themes", value.join(","));
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            type="text"
                                            label="ANÉCDOTA THEMES"
                                            placeholder="ANÉCDOTAS THEMES"
                                        />
                                    )}
                                />
                            </Col>
                        </Row>

                        <Row justify="start" style={{ marginBottom: 20, marginTop: 10 }} gutter={[24, 24]}>
                            <Col lg={24} xs={24}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    size="small"
                                    className='categorias-story'
                                    id="aptheme-outlined"
                                    options={AP_THEME}
                                    defaultValue={get(data, "ap_themes", '') ? get(data, "ap_themes", '').split(",") : []}
                                    style={{ width: "100%", minHeight: 30 }}
                                    onChange={(event, value) => {
                                        onChange("ap_themes", value.join(","));
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            type="text"
                                            label="AP THEME"
                                            placeholder="AP THEME"
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row justify="start" style={{ marginBottom: 20, marginTop: 10 }} gutter={[24, 24]}>
                            <Col lg={24} xs={24}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    size="small"
                                    className='categorias-story'
                                    id="ibtheme-outlined"
                                    options={IB_THEME}
                                    defaultValue={get(data, "ib_themes", '') ? get(data, "ib_themes", '').split(",") : []}
                                    style={{ width: "100%", minHeight: 30 }}
                                    onChange={(event, value) => {
                                        onChange("ib_themes", value.join(","));
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            type="text"
                                            label="IB THEMES"
                                            placeholder="IB THEMES"
                                        />
                                    )}
                                />
                            </Col>

                        </Row>
                        <LabelText >PREBÁSICO</LabelText>
                        <Row justify="start" style={{ marginBottom: 10, }} gutter={[16, 16]}>
                            <Col span={12}>
                                <TextField
                                    id="PREBÁSICOFROM"
                                    name="PREBÁSICOFROM"
                                    label="Start pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("PREBÁSICO", "start", e.target.value)
                                    }}
                                    value={get(PREBÁSICO, "start")}
                                />
                            </Col>
                            <Col span={12}>
                                <TextField
                                    id="PREBÁSICOFINISH"
                                    name="PREBÁSICOFINISH"
                                    label="End pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("PREBÁSICO", "finish", e.target.value)
                                    }}
                                    value={get(PREBÁSICO, "finish")}
                                />
                            </Col>

                        </Row>
                        <LabelText >BASICO</LabelText>
                        <Row justify="start" style={{ marginBottom: 10, }} gutter={[16, 16]}>
                            <Col span={12}>
                                <TextField
                                    id="BASICOFROM"
                                    name="BASICOFROM"
                                    label="Start pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("BASICO", "start", e.target.value)
                                    }}
                                    value={get(BASICO, "start")}
                                />
                            </Col>
                            <Col span={12}>
                                <TextField
                                    id="BASICOFINISH"
                                    name="BASICOFINISH"
                                    label="End pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("BASICO", "finish", e.target.value)
                                    }}
                                    value={get(BASICO, "finish")}
                                />
                            </Col>

                        </Row>


                        <LabelText >INTERMEDIO</LabelText>
                        <Row justify="start" style={{ marginBottom: 10, }} gutter={[16, 16]}>
                            <Col span={12}>
                                <TextField
                                    id="INTERMEDIOFROM"
                                    name="INTERMEDIOFROM"
                                    label="Start pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("INTERMEDIO", "start", e.target.value)
                                    }}
                                    value={get(INTERMEDIO, "start")}
                                />
                            </Col>
                            <Col span={12}>
                                <TextField
                                    id="INTERMEDIOFINISH"
                                    name="INTERMEDIOFINISH"
                                    label="End pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("INTERMEDIO", "finish", e.target.value)
                                    }}
                                    value={get(INTERMEDIO, "finish")}
                                />
                            </Col>

                        </Row>


                        <LabelText >AVANZADO</LabelText>
                        <Row justify="start" style={{ marginBottom: 10, }} gutter={[16, 16]}>
                            <Col span={12}>
                                <TextField
                                    id="AVANZADOFROM"
                                    name="AVANZADOFROM"
                                    label="Start pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("AVANZADO", "start", e.target.value)
                                    }}
                                    value={get(AVANZADO, "start")}
                                />
                            </Col>
                            <Col span={12}>
                                <TextField
                                    id="AVANZADOFINISH"
                                    name="AVANZADOFINISH"
                                    label="End pageId"
                                    required={false}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    onChange={e => {
                                        onLevelsPageUpdate("AVANZADO", "finish", e.target.value)
                                    }}
                                    value={get(AVANZADO, "finish")}
                                />
                            </Col>

                        </Row>
                        {error.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                            {error.map((v, i) => (<Typography variant="subtitle2" key={i} style={{ whiteSpace: "pre-wrap" }} color="error">
                                * {v}
                            </Typography>))}
                        </Row> : null}
                        <Row gutter={[24, 24]} style={{ marginTop: 30, marginRight: 0, marginLeft: 0 }}>
                            <Button fullWidth onClick={onCreateOrUpdate} variant="contained" color="primary">    {loading ? <Loading white size={15} /> : null}  {edit ? "Update" : "Create"}</Button>
                        </Row>
                        {Boolean(data.bookId) ? <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                            <Button fullWidth
                                onClick={() => {
                                    redirectToBook(data.bookId)
                                }}
                                variant="contained" color="primary">
                                Open book
                            </Button>
                        </Row> : null}
                    </Col>
                </Row>
            </ModalConfigArea>
        </ModalContent>
    </Modal>)
}
function RenderStory({ book, onClick, onDelete }) {
    const image = get(book, "img", Pic1)
    const onClickEdit = () => {
        onClick(book)
    }
    return (<Col xs={24} sm={24} md={12} lg={8}>
        <div className="ImgBtn">
            <label>{get(book, "title", "")}</label>
            <div className="coverBtns">
                <div className="btnsGrpMain">
                    <div className="btnsGrpTxt" style={{ justifyContent: "center" }}>
                        <Button className="btnsTxtYellow" onClick={onClickEdit} ><Edit style={{ fontSize: 20, marginRight: 3 }} />Edit</Button>
                        <Button style={{ marginLeft: 10 }} variant="contained" color="secondary" onClick={() => onDelete(book)} ><Delete style={{ fontSize: 20, marginRight: 3 }} />Delete</Button>
                    </div>
                </div>
                <div><img src={image} /></div>
            </div>
        </div>
    </Col>)
}

export default function StoryLevels() {
    const bookAllList = useAllBook()
    const history = useHistory()
    const story = useStory()
    const dispatch = useDispatch()
    const [storyBookGroups, setStoryBookGroups] = useState({})
    const [selectedStory, setSelectedStory] = useState({})
    const [saveModal, setSaveModal] = useState(false)
    const currentUser = useCurrentUser()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteLodaing, setDeleteLoading] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState('All')
    const [filterdStory, setFilterdStory] = useState([])
    const [serachText, setSearchText] = useState('')
    const [alert, setAlert] = React.useState({
        open: false,
        message: ""
    });
    const isStudent = get(currentUser, "role") === "student"
    useEffect(() => {
        const storyBook = sortBy(story, k => parseInt(k.order), "asc")
        if (!isEmpty(storyBook)) {
            setStoryBookGroups(storyBook)
            setFilterdStory(storyBook)
        }
    }, [story])
    useEffect(() => {
        if (!story || story.length === 0) {
            dispatch(handleListStory())
            dispatch(handleListStoryStandard())
        }
    }, [])

    const handleClose = () => {
        setAlert({
            open: false,
            message: ""
        })
    }
    const onClick = (data) => {
        setSelectedStory(data)
        setSaveModal(true)
    }
    const redirectToBook = (bookId) => {
        history.push(`/add-book?bookId=${bookId}&pageId=1`)
    }
    const onAddStory = () => {
        setSelectedStory({})
        setSaveModal(true)
    }
    const onDeleteStory = async () => {
        setDeleteLoading(true)
        await dispatch(handleDeleteStories(selectedStory.id))
        setDeleteLoading(false)
        setOpenDeleteModal(false)
        setSelectedStory({})
    }
    const categoryNav = () => {
        return (
            <Menu>
                {map(PAGE_CATEGORY, ct => (<Menu.Item key={ct} style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        filterByCategory(ct)
                    }}>
                        {ct}
                    </PaddedBtn>
                </Menu.Item>))}
            </Menu>
        );
    }
    const searchitems = (txt) => {
        setSearchText(txt)
        if (typeof txt == 'string' && txt.length > 0) {
            txt = txt.toLowerCase();
            const fStory = filter(storyBookGroups, st => {
                if (st.title && st.title.length > 0) {
                    if (st.title.toLowerCase().includes(txt)) {
                        return true;
                    }
                } else if (st.categorias && st.categorias.length > 0) {
                    if (st.categorias.toLowerCase().includes(txt)) {
                        return true;
                    }
                }
                return false;
            })
            setFilterdStory(fStory)
        } else {
            setFilterdStory(storyBookGroups)
        }
    }
    const filterByCategory = (category) => {
        setSelectedCategories(category)
        if (category == '' || category == 'All') {
            setFilterdStory(storyBookGroups)
        } else {
            if (typeof category === 'string') {
                const fStory = filter(storyBookGroups, st => {
                    if (st.categorias && st.categorias.length > 0) {
                        category = category.toLowerCase()
                        if (st.categorias.toLowerCase().includes(category)) {
                            return true;
                        }
                    }
                    return false;
                })
                setFilterdStory(fStory)
            }
        }
    }
    return (
        <>
            <SaveModal
                storyies={selectedStory}
                saveModal={saveModal}
                setSaveModal={setSaveModal}
                booksAllList={bookAllList}
                redirectToBook={redirectToBook}
            />
            <DeleteModal
                deleteToggle={openDeleteModal}
                onDelete={onDeleteStory}
                message={`Are you sure you want to delete ${get(selectedStory, "title", "this Story")}?`}
                setDeleteToggle={() => {
                    setOpenDeleteModal(false)
                }} loading={deleteLodaing} />
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={alert.open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {alert.message}
                </Alert>
            </Snackbar>
            <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                <Button onClick={() => onAddStory()} color='primary' variant="contained" >
                    <Add /> Add Story
                </Button>
            </Row>
            <Row style={{ marginTop: 30, marginBottom: 10 }}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Row className="categoryBtns" style={{ marginBottom: 10 }}>
                        <Dropdown className="categoryBtnOne" overlay={categoryNav()} placement="bottomLeft" toggle={['click']}>
                            <CategoryMenu>{selectedCategories === '' || selectedCategories === "All" ? "Categorias" : selectedCategories}  <MenuIconImg src={MenuIcon} /> </CategoryMenu>
                        </Dropdown>
                        <CategoryMenu className="categoryBtnTwo">
                            <SearchInput
                                type={"text"}
                                value={serachText}
                                placeholder="SEARCH"
                                onChange={(e) => { searchitems(e.target.value) }}
                                onFocus={e => e.target.placeholder = ''}
                                onBlur={e => e.target.placeholder = 'SEARCH'}
                            />
                            <MenuIconImg src={SearchIcon} /> </CategoryMenu>
                    </Row>
                    
                    <Row className="ImgBtnMain" gutter={[32, 0]}>

                        <FlatList
                            list={filterdStory}
                            renderItem={(k, i) => (<RenderStory key={i} book={k} onClick={onClick}
                                onDelete={data => {
                                    setSelectedStory(data)
                                    setOpenDeleteModal(true)
                                }}
                            />)}
                            renderWhenEmpty={() => <div>No Story Articles</div>}
                        />
                    </Row>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={4}></Col> */}
            </Row>
        </>
    );
}
