//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as InstructionsActions from './actions';
import * as Sentry from "@sentry/react";
import { has } from "lodash";
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------
export const handleListInstructionByUser = (userId) => async (dispatch) => {
    await dispatch(InstructionsActions.listInstructions());
    try {
        const instructions = await API.get('instructions', `/instructionByUser/${userId}`);
        if (Array.isArray(instructions)) {
            await dispatch(InstructionsActions.listIntstructionsSuccess(instructions));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(InstructionsActions.listInstructionsFail(error));
    }
};

export const handleListInstructionByClass = (classId) => async (dispatch) => {
    await dispatch(InstructionsActions.listInstructions());
    try {
        const instructions = await API.get('instructions', `/instructionByClass/${classId}`);
        if (Array.isArray(instructions)) {
            await dispatch(InstructionsActions.listIntstructionsSuccess(instructions));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(InstructionsActions.listInstructionsFail(error));
    }
};

export const handleCreateInstructions = (data) => async (dispatch) => {
    await dispatch(InstructionsActions.createInstructions());
    try {
        const instructions = await API.post('instructions', '/instruction', { body: data });
        if (has(instructions, "id") && instructions) {
            await dispatch(InstructionsActions.createIntstructionsuccess(instructions));
        }
        return instructions;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(InstructionsActions.createInstructionsFail(error));
    }
};

export const handleUpdateInstructions = (id,data) => async (dispatch) => {
    await dispatch(InstructionsActions.updateInstructions());
    try {
        const instructions = await API.put('instructions', `/update/${id}`, { body: data });
        if (has(instructions, "id") && instructions) {
            await dispatch(InstructionsActions.updateIntstructionsSuccess(instructions));
        }
        return instructions;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(InstructionsActions.updateInstructionsFail(error));
    }
};

export const handleDeleteInstructions = (id) => async (dispatch) => {
    await dispatch(InstructionsActions.deleteInstructions());
    try {
        const instructions = await API.del('instructions', `/delete/${id}`);
        if (has(instructions, "id") && instructions) {
            await dispatch(InstructionsActions.deleteIntstructionsSuccess(id));
        }
        return instructions;
    } catch (error) {
        if (error && error.message!= "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(InstructionsActions.deleteInstructionsFail(error));
    }
};