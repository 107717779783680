//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
//  -- Components ---------------------
import InitialLoading from 'components/InitialLoading';
//  -- Features -----------------------
import EbookLayout from 'features/EbookLayout';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//  -- Thunks -------------------------
import { handleListChapterActivities } from 'store/activities/thunks';
import { handleListClassActivities, handleListClassActivitiesForStudent } from 'store/classActivities/thunks';
import { handleListClassQuizzes, handleListClassQuizzesForStudent } from 'store/classQuizzes/thunks';
import { handleGetBubbleByBook, handleListBubbleUserNotes, handleListUserNotes } from 'store/notes/thunks';
import { handleListClassQuestions, handleListUserQuestions } from 'store/questions/thunks';
import { handleListChapterQuizzes } from 'store/quizzes/thunks';
import { handleListUserSubmissions } from 'store/submissions/thunks';
import { handleListUserBooks } from 'store/userBooks/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import { handleListEbookData } from 'store/books/thunks';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useAlertView } from "store/alert/selectors";
import styled from 'styled-components';
//  -- Lib ----------------------------
import { ebookConfigDinamic } from 'utils/lib/ebookHelper';
import { handleListInstructionByUser, handleListInstructionByClass } from "store/instructions/thunks"
import _, { find, get, has, filter } from 'lodash';



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 90vh;
    justify-content: center;
    width: 100%;
`;

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const EbookContainer = ({
    currentClass,
    currentUser,
    classUsers,
    userBooksList,
    tipsBook,
    classActivities,
    classQuizzes,
    submissions,
    booksAllList
}) => {
    //console.log(userBooksList);
    const history = useHistory();
    const dispatch = useDispatch();
    const search = queryString.parse(history.location.search);
    const [ebookFlag, setEbookFlag] = useState(null);
    const [ebookFlagTeacherEdition, setEbookFlagTeacherEdition] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(false);
    const book = find(booksAllList, { id: search.chapter });
    const lessonPlan = book?.lessonPlan;
    const SuperLesson = book?.superLesson;
    const DifferentiationLesson = book?.differentiationLesson;
    const rubrics = get(book, "rubrics", []);
    const assignmentPlan = book?.assignmentPlan;
    const workbook_plan = book?.workbook_plan;
    const main_book = book?.main_book;
    const [, invokeAlert, revokeALert] = useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    const data = ebookConfigDinamic(search.chapter, booksAllList);
    const userBook = filter(_.get(userBooksList, "books", []), x => x.bookId == search.chapter);
    let dataBook = userBook[0]?.dataBook ?? {};
    if (!userBook[0]?.dataBook) {
        let ubook = filter(booksAllList, x => x.id == search.chapter);
        if (ubook[0]) {
            dataBook = ubook[0]
        }
    }
    const existTeacherEdition = filter(get(dataBook, "teacherEdition"), x => x.pageId == search.page).length > 0;
    const existShowAnswer = filter(get(dataBook, "pagesTeacher"), x => x.pageId == search.page).length > 0;
    const fetchPageExist = filter(get(dataBook, "pages"), x => x.pageId == search.page).length > 0;
    const getPageExist = fetchPageExist ? search.page : history.goBack();
    if (!userBook[0]?.dataBook) {
        let ubook = filter(booksAllList, x => x.id == search.chapter);
        if (ubook[0]) {
            dataBook = ubook[0]
        }
    }
    useEffect(() => {
        setTimeout(() => setMounted(true), 250)
    }, []);
    useEffect(() => {
        if (orgPermissions && typeof orgPermissions['exams'] === "boolean") {
            const assignmentBooks = ['31', '35', '36', '45', '50', '51']
            if (assignmentBooks.includes(search.chapter)) {
                if (!orgPermissions['exams']) {
                    invokeAlert()
                    setTimeout(() => {
                        history.goBack();
                        revokeALert()
                    }, 5000)
                }
            }
        }
    }, [orgPermissions])
    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);
    const listChapterData = async () => {
        // dispatch(handleListUserNotes(get(currentUser, "id")))
        dispatch(handleListBubbleUserNotes(get(currentUser, "id")))
        if (has(search, "chapter")) {
            // dispatch(handleListChapterActivities(search.chapter))
            // dispatch(handleListChapterQuizzes(search.chapter))
            const bubbleData = {
                chapter: search.chapter,
                classId: get(currentClass, "id"),
                userId: get(currentUser, "id")
            }
            dispatch(handleGetBubbleByBook(bubbleData))
            return dispatch(handleListEbookData(search.chapter))
        }
    }
    const listInstruction = async () => {
        if (has(currentUser, "id") && currentUser.role == "teacher") {
            await dispatch(handleListInstructionByUser(currentUser.id))
        }
        if (has(currentClass, "id") && currentUser.role != "teacher") {
            dispatch(handleListInstructionByClass(get(currentClass, "id")));
        }
    }
    const getStudentSubmissions = async () => {
        if (get(currentUser, "role") == "student") {
            return dispatch(handleListUserSubmissions(currentUser?.id))
        }
    }
    const getBookDataForTeacher = async () => {
        if (get(currentUser, "role") === 'teacher' && has(currentClass, "id")) {
            dispatch(handleListClassQuestions(get(currentClass, "id")))
            dispatch(handleListClassUsers(get(currentClass, "id")));
        }
        dispatch(handleListUserQuestions(currentUser?.id))
    }
    const listClassAssignments = async () => {
        if (has(currentClass, "id") && get(currentUser, "role") != "student") {
            dispatch(handleListClassActivities(currentClass.id));
            dispatch(handleListClassQuizzes(currentClass.id));
        } else {
            dispatch(handleListClassActivitiesForStudent(get(currentClass, "id"), get(currentUser, "id")));
            dispatch(handleListClassQuizzesForStudent(get(currentClass, "id"), get(currentUser, "id")));
        }
    }
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await Promise.allSettled([
                listChapterData(),
                getStudentSubmissions(),
                getBookDataForTeacher(),
                listClassAssignments(),
                listInstruction()
            ])
            setLoading(false)
            // await dispatch(handleListUserNotes(currentUser?.id))
            // await dispatch(handleListChapterActivities(search.chapter))
            // await dispatch(handleListChapterQuizzes(search.chapter))
            // await dispatch(handleListEbookData(search.chapter))
            // if(currentUser?.role=="student"){
            //     await dispatch(handleListUserSubmissions(currentUser?.id))
            // }
            // let currentClassId=localStorage.getItem("cureentClassId")
            // if((currentClassId&&currentClassId===currentClass?.id)||(!currentClass?.id&&currentClassId=="noclasssid")){
            //     setLoading(false)
            // }
            // if (currentUser?.role === 'teacher') {
            //    await  dispatch(handleListClassQuestions(currentClass.id))
            //    await  dispatch(handleListClassUsers(currentClass.id));
            // }
            // localStorage.setItem("cureentClassId",currentClass?.id??"noclasssid")
            // setLoading(false);
            // await dispatch(handleListClassActivities(currentClass?.id));
            // await dispatch(handleListClassQuizzes(currentClass?.id));
            // dispatch(handleListUserQuestions(currentUser?.id))
            // dispatch(handleListUserBooks(currentUser?.id))

        }
        fetchData();
    }, [currentUser, search?.chapter, currentClass, dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Wrapper>
            {loading ? (
                <CenterContainer>
                    <InitialLoading mounted={mounted} />
                </CenterContainer>
            ) : (
                <EbookLayout
                    classUsers={classUsers}
                    activity={search.activity}
                    chapter={search.chapter}
                    data={data}
                    tips={tipsBook}
                    existTeacherEdition={existTeacherEdition}
                    existShowAnswer={existShowAnswer}
                    ebookFlag={ebookFlag}
                    setEbookFlag={setEbookFlag}
                    ebookFlagTeacherEdition={ebookFlagTeacherEdition}
                    setEbookFlagTeacherEdition={setEbookFlagTeacherEdition}
                    history={history}
                    page={getPageExist}
                    quiz={search.quiz}
                    currentUser={currentUser}
                    submissions={submissions}
                    classQuizzes={classQuizzes}
                    classActivities={classActivities}
                    lessonPlan={lessonPlan}
                    SuperLesson={SuperLesson}
                    DifferentiationLesson={DifferentiationLesson}
                    rubrics={rubrics}
                    assignmentPlan={assignmentPlan}
                    workbook_plan={workbook_plan}
                    main_book={main_book}
                    booksAllList={booksAllList}
                    currentClass={currentClass}
                    book={book}
                    dataBook={dataBook}
                />
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
EbookContainer.propTypes = {};

//  -- Default props ------------------
EbookContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    classUsers: state.classUsers,
    userBooksList: state.userBooksList,
    tipsBook: state.tipsBook,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
});

export default connect(mapStateToProps, null)(EbookContainer);
