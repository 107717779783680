//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const multiclassPopup = {
    isLoading: false,
    view:false
};


export const multiclassPopupForm = (state = multiclassPopup, action) => {
    switch (action.type) {
        case ActionTypes.multiclassStudent.invokeMulticlassPopup:
            return ({ ...state, isLoading: false , view:true });
        case ActionTypes.multiclassStudent.revokeMulticlassPopup:
            return ({ ...state, isLoading: false , view:false });
        case ActionTypes.multiclassStudent.disableMulticlassSuccess:
            return ({ ...state, isLoading: false , message: action.payload ,view:false });
        case ActionTypes.multiclassStudent.disableMulticlassFail:
            return ({ ...state, isLoading: false , message: action.payload ,view:false });
        case ActionTypes.multiclassStudent.disableMulticlassCreate:
            return ({ ...state, isLoading: true  });
        default:
            return state;
    }
};


