//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------


//  -- List books ----------------
export const handleInvokeMulticlassForm = (feedback) => async (dispatch) => {
    await dispatch(ActivityActions.invokeMulticlassPopup());
}
export const handleRevokeMulticlassForm = () => async (dispatch) => {
    await dispatch(ActivityActions.revokeMulticlassPopup());
}
export const handleChangeUserClassStatus = (userId, classId, cb,hasUpdateStore=true) => async (dispatch) => {
    dispatch(ActivityActions.disbaleMulticlassCreate())
    try {
        const updateStatus = await API.put('userClassesRDS', `/setActiveStudentClass`, {
            body: {
                "userId": userId,
                "classId": classId
            }
        });
        if(hasUpdateStore){
            if (updateStatus && !updateStatus.error) {
                dispatch(ActivityActions.disbaleMulticlassSuccess(updateStatus))
            } else {
                dispatch(ActivityActions.disbaleMulticlassFail(updateStatus))
            }
        }
        if(cb)
        cb(updateStatus)
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        cb(error)
        dispatch(ActivityActions.disbaleMulticlassFail(error))
    }

}