//  ----------------------------------------------------------------------------
//  Assignment Config
//  ----------------------------------------------------------------------------
const getFireStoreUrl = (path) => (`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/${path}?alt=media&token=6a9a7f5a-4959-431c-a305-8eabe60a4d46`)

const get1waysResourceUrl = (image) => getFireStoreUrl(`1waysresources%2F${image}`)
export const BACK_TO_BOOK={
    33:'Balcón cultural',
    41:'1nformate lessons',
    43:'Comunidad',
    49:'Conectándome',
    79:'Worksheet',
    70:"AP",
    69:"AP",
    71:"AP",
    72:"AP",
    73:"AP",
    75:"AP",
    78:"AP",
    

 }

 export const US_STATE =[
    {
      "name": "Alabama",
      "code": "AL"
    },
    {
      "name": "Alaska",
      "code": "AK"
    },
    {
      "name": "Arizona",
      "code": "AZ"
    },
    {
      "name": "Arkansas",
      "code": "AR"
    },
    {
      "name": "California",
      "code": "CA"
    },
    {
      "name": "Colorado",
      "code": "CO"
    },
    {
      "name": "Connecticut",
      "code": "CT"
    },
    {
      "name": "Delaware",
      "code": "DE"
    },
    {
      "name": "Florida",
      "code": "FL"
    },
    {
      "name": "Georgia",
      "code": "GA"
    },
    {
      "name": "Hawaii",
      "code": "HI"
    },
    {
      "name": "Idaho",
      "code": "ID"
    },
    {
      "name": "Illinois",
      "code": "IL"
    },
    {
      "name": "Indiana",
      "code": "IN"
    },
    {
      "name": "Iowa",
      "code": "IA"
    },
    {
      "name": "Kansas",
      "code": "KS"
    },
    {
      "name": "Kentucky",
      "code": "KY"
    },
    {
      "name": "Louisiana",
      "code": "LA"
    },
    {
      "name": "Maine",
      "code": "ME"
    },
    {
      "name": "Maryland",
      "code": "MD"
    },
    {
      "name": "Massachusetts",
      "code": "MA"
    },
    {
      "name": "Michigan",
      "code": "MI"
    },
    {
      "name": "Minnesota",
      "code": "MN"
    },
    {
      "name": "Mississippi",
      "code": "MS"
    },
    {
      "name": "Missouri",
      "code": "MO"
    },
    {
      "name": "Montana",
      "code": "MT"
    },
    {
      "name": "Nebraska",
      "code": "NE"
    },
    {
      "name": "Nevada",
      "code": "NV"
    },
    {
      "name": "New Hampshire",
      "code": "NH"
    },
    {
      "name": "New Jersey",
      "code": "NJ"
    },
    {
      "name": "New Mexico",
      "code": "NM"
    },
    {
      "name": "New York",
      "code": "NY"
    },
    {
      "name": "North Carolina",
      "code": "NC"
    },
    {
      "name": "North Dakota",
      "code": "ND"
    },
    {
      "name": "Ohio",
      "code": "OH"
    },
    {
      "name": "Oklahoma",
      "code": "OK"
    },
    {
      "name": "Oregon",
      "code": "OR"
    },
    {
      "name": "Pennsylvania",
      "code": "PA"
    },
    {
      "name": "Rhode Island",
      "code": "RI"
    },
    {
      "name": "South Carolina",
      "code": "SC"
    },
    {
      "name": "South Dakota",
      "code": "SD"
    },
    {
      "name": "Tennessee",
      "code": "TN"
    },
    {
      "name": "Texas",
      "code": "TX"
    },
    {
      "name": "Utah",
      "code": "UT"
    },
    {
      "name": "Vermont",
      "code": "VT"
    },
    {
      "name": "Virginia",
      "code": "VA"
    },
    {
      "name": "Washington",
      "code": "WA"
    },
    {
      "name": "West Virginia",
      "code": "WV"
    },
    {
      "name": "Wisconsin",
      "code": "WI"
    },
    {
      "name": "Wyoming",
      "code": "WY"
    }
  ]
export const N823aa3cc210843d3a6469815f2534e5d = [
    {
        id: '1',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition1%2FPage-1.jpg?alt=media&token=f5ef5164-7057-4c06-abdd-833f1573eac5',
    },
    {
        id: '2',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition1%2FPage-2.jpg?alt=media&token=4a128fcc-d4b9-4725-ac5a-a244fc9fae40',
    },
    {
        id: '3',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition1%2FPage-3.jpg?alt=media&token=3e71b12d-f13f-448b-a4d5-a61214cb5771',
    }
];

export const e872431215ac4a10ad056fb9f81fcfc1 = [
    {
        id: '1',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition2%2FPage-1.jpg?alt=media&token=f5bafd9c-ada5-46c5-81bc-7baab321ebf0',
    },
    {
        id: '2',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition2%2FPage-2.jpg?alt=media&token=8d2b2af2-ea11-4ff6-87b3-4f269cd7a331',
    },
    {
        id: '3',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition2%2FPage-3.jpg?alt=media&token=ef04a9ad-1bec-4f1a-8dee-8c6f0ce66e40',
    },
    {
        id: '4',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition2%2FPage-4.jpg?alt=media&token=a1fd1d26-f230-4850-a06a-b1adbee846a2',
    },
    {
        id: '5',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition2%2FPage-5.jpg?alt=media&token=f1362af2-d4eb-45a2-a46c-e37df9ed8264',
    },
    {
        id: '6',
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/newsletter%2FEdition2%2FPage-6.jpg?alt=media&token=44844c2c-cd9c-4273-942f-ad6e524ccb22',
    },
];

export const NEWSLETTERS = {
    "N823aa3cc210843d3a6469815f2534e5d": true,
    'e872431215ac4a10ad056fb9f81fcfc1': true,
};
export const Categorias = [
    'Entretenimiento',
    'Tecnología',
    'Deportes',
    'Historias',
    'Cultura',
    'Música',
    'Curiosidades',
    'Cine',
    'Fantasía',
    'Realismo',
    'Ciencia ficción',
    'Magia',
    'Espacio',
    '6-8th ',
    '9-10th',
    '11-12th'
]

export const getFireBaseUrl = (imageName) => (`https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/${imageName}?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222`);


export const CATEGORIAS = [
    {
        title:"All"
    },
    {
        icon: getFireBaseUrl("Entretenimiento.svg"),
        title:"Entretenimiento"
    },
    {
        icon: getFireBaseUrl("Tecnologia.svg"),
        title:"Tecnología"
    },
    {
        icon: getFireBaseUrl("Deportes.svg"),
        title:"Deportes"
    },
    {
        icon: getFireBaseUrl("Historias.svg"),
        title:"Historias"
    },
    {
        icon: getFireBaseUrl("Cultura.svg"),
        title:"Cultura"
    },
    {
        icon: getFireBaseUrl("Musica.svg"),
        title:"Música"
    },
    {
        icon: getFireBaseUrl("Curiosidades.svg"),
        title:"Curiosidades"
    },
    {
        icon: getFireBaseUrl("Cine.svg"),
        title:"Cine"
    },
    {
        icon: getFireBaseUrl("Fantasia.svg"),
        title:"Fantasía"
    },
    {
        icon: getFireBaseUrl("Realismo.svg"),
        title:"Realismo"
    },
    {
        icon: getFireBaseUrl("Ciencia_Ficcion.svg"),
        title:"Ciencia Ficción"
    },
    {
        icon: getFireBaseUrl("Magia.svg"),
        title:"Magia"
    },
    {
        icon: getFireBaseUrl("Espacio.svg"),
        title:"Espacio"
    },
    {
        icon: getFireBaseUrl("six_eight.svg"),
        title:"6-8th"
    },
    {
        icon: getFireBaseUrl("six_eight.svg"),
        title:"9-10th"
    },
    {
        icon: getFireBaseUrl("six_eight.svg"),
        title:"11-12th"
    },
]

export const IBTHEME = [
    {
        icon: getFireBaseUrl("rocket.svg"),
        title: "Identidad"
    },
    {
        icon: getFireBaseUrl("gallery.svg"),
        title: "Experiencias"
    },
    {
        icon: getFireBaseUrl("bulb.svg"),
        title: "Ingenio humano"
    },
    {
        icon: getFireBaseUrl("people.svg"),
        title: "Organización social"
    },
    {
        icon: getFireBaseUrl("global.svg"),
        title: "Compartir el planeta"
    },
]

export const APTHEME = [
    {
        type: "AP Spanish Language",
        icon: getFireBaseUrl("globe.svg"),
        title: "Desafíos globales",
        title2: "Las sociedades en contacto",
        title2Icon: getFireBaseUrl("contact.svg")
    },
    {
        type: "AP Spanish Language",
        icon: getFireBaseUrl("lab.svg"),
        title: "Ciencia y tecnología",
        title2: "La construcción del género",
        title2Icon: getFireBaseUrl("gender.svg")
    },
    {
        type: "AP Spanish Language",
        icon: getFireBaseUrl("wait.svg"),
        title: "Vida contemporánea",
        title2: "El tiempo y el espacio",
        title2Icon: getFireBaseUrl("clock.svg")
    },
    {
        type: "AP Spanish Language",
        icon: getFireBaseUrl("shake_hand.svg"),
        title: "Identidades personales y públicas",
        title2: "La creación literaria",
        title2Icon: getFireBaseUrl("read.svg")
    },
    {
        type: "AP Spanish Language",
        icon: getFireBaseUrl("group_of_people.svg"),
        title: "Familias y comunidades",
        title2: "Las relaciones interpersonales",
        title2Icon: getFireBaseUrl("people.svg")
    },
    {
        type: "AP Spanish Language",
        icon: getFireBaseUrl("boom.svg"),
        title: "Belleza y estética",
        title2: "La dualidad del ser",
        title2Icon: getFireBaseUrl("video-duality.svg")
    },
]

export const AnecdotasThemes = {
    first_phase: [
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("union-video.svg"),
            title: "La unión como base",
            title2: "Los tipos de relaciones en la sociedad",
            title3: "La familia, el núcleo de la sociedad",
            title2Icon: getFireBaseUrl("realtionship-video.svg"),
            title3Icon: getFireBaseUrl("family-necules-video.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("youth.svg"),
            title: "La juventud, mi juventud",
            title2: "En busca de la paz",
            title3: "Mi mundo que sigue innovando",
            title2Icon: getFireBaseUrl("search-of-peace.svg"),
            title3Icon: getFireBaseUrl("my-world-video.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("my-value-video.svg"),
            title: "Los valores y mis responsabilidades",
            title2: "La inmigración en el mundo",
            title3:"Mi vida contemporánea",
            title2Icon: getFireBaseUrl("Immigration.svg"),
            title3Icon: getFireBaseUrl("contemporary.svg"),
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("redis.svg"),
            title: "Las redes nos enredan",
            title2: "La influencia de los influencers",
            title3: "Mi identidad en el mundo",
            title2Icon: getFireBaseUrl("infulencer-video.svg"),
            title3Icon: getFireBaseUrl("finger-print.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("fantasy.svg"),
            title: "La fantasía y la imaginación",
            title2: "La forma de vivir: ¿De vuelta a la normalidad o nueva normalidad?",
            title3: "Mi comunidad es también mi familia",
            title2Icon: getFireBaseUrl("living-video.svg"),
            title3Icon: getFireBaseUrl("office.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("gym-video.svg"),
            title: "Mi salud, mi vida",
            title2: "Una historia para contar: Somos latinos, somos hispanos",
            title3: "Enfrentando los desafíos del mundo",
            title2Icon: getFireBaseUrl("capa-2.svg"),
            title3Icon: getFireBaseUrl("flag.svg")
        },
    ],
    second_phase: [
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("families-link-video.svg"),
            title: "Las familias y su enlace con la sociedad",
            title2: "Las sociedades en contacto",
            title3: "Las familias en diferentes sociedades",
            title2Icon: getFireBaseUrl("Societies-link.svg"),
            title3Icon: getFireBaseUrl("family-necules-video.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("impact_culture.svg"),
            title: "El impacto de la cultura y el lenguaje en las identidades",
            title2: "La construcción del género",
            title3: "La influencia de la lengua y la cultura en la identidad",
            title2Icon: getFireBaseUrl("setting-video.svg"),
            title3Icon: getFireBaseUrl("lang-cult-video.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("paint-video.svg"),
            title: "Contemplando el arte como influencia en la vida",
            title2: "La línea del tiempo",
            title3:"La influencia de la belleza y el arte",
            title2Icon: getFireBaseUrl("timeline-video.svg"),
            title3Icon: getFireBaseUrl("influence-beauty-art.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("robot.svg"),
            title: "La tecnología y sus beneficios en la vida diaria",
            title2: "El arte, un puente hacia la literatura",
            title3: "Cómo la ciencia y la tecnología afectan nuestras vidas",
            title2Icon: getFireBaseUrl("art-bridget.svg"),
            title3Icon: getFireBaseUrl("wait-clock.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("calender.svg"),
            title: "La vida contemporánea y sus cambios en las comunidades",
            title2: "Interconexión, colaboración y resultados",
            title3: "Los factores que impactan la calidad de nuestra vida",
            title2Icon: getFireBaseUrl("link-video.svg"),
            title3Icon: getFireBaseUrl("heart-gym.svg")
        },
        {
            type: "AP Spanish Language",
            icon: getFireBaseUrl("flag.svg"),
            title: "Las sociedades frente a los retos",
            title2: "Las multiples facetas del ser humano",
            title3: "Los cambios ambientales, políticos y sociales",
            title2Icon: getFireBaseUrl("headphone-video.svg"),
            title3Icon: getFireBaseUrl("refresh-video.svg")
        },
    ]
}



export const HIGH_ASSESSMENTS = [
    {
        key: 'entry_exam',
        src: getFireStoreUrl("assessment%2F5_HIGHSCHOOL.jpg"),
        displayName: 'Entry Exam',
        label: "High School Level",
        bookId: 31
    },
    {
        key: 'midterm_exam',
        src: getFireStoreUrl("assessment%2F7_HIGHSCHOOL.jpg"),
        displayName: 'Midterm Exam',
        label: "High School Level",
        bookId: 51
    },
    {
        key: 'final_exam',
        src: getFireStoreUrl("assessment%2F9_HIGHSCHOOL.jpg"),
        displayName: 'Final Exam',
        label: "High School Level",
        bookId: 36
    },
]

export const MIDDLE_ASSESSMENTS = [
    {
        key: 'middle_entry_exam',
        src: getFireStoreUrl("assessment%2F5.jpg"),
        displayName: 'Entry Exam',
        label: "Middle School Level",
        bookId: 45
    },
    {
        key: 'middle_midterm_exam',
        src: getFireStoreUrl("assessment%2F7.jpg"),
        displayName: 'Midterm Exam',
        label: "Middle School Level",
        bookId: 50
    },
    {
        key: 'middle_final_exam',
        src: getFireStoreUrl("assessment%2F9.jpg"),
        displayName: 'Final Exam',
        label: "Middle School Level",
        bookId: null
    },
]



export const ResourceSymbol = {
    herramientas: {
        bg: get1waysResourceUrl("gloab.png"),
        img1: get1waysResourceUrl("brain.png")
    },
    ruzia: {
        bg: get1waysResourceUrl("gloab.png"),
        img1: get1waysResourceUrl("pinkgirl.png"),
        img2: get1waysResourceUrl("yplay.png")

    },
    avina: {
        bg: get1waysResourceUrl("gloab.png"),
        img1: get1waysResourceUrl("orangeboy.png")
    },
    americaPodcast: {
        bg: get1waysResourceUrl("gloabrec.png")
    },
    matias: {
        bg: get1waysResourceUrl("gloab.png"),
        img1: get1waysResourceUrl("manwithball.png")
    }

}


export const COTATION = [
    {
        message: "Lo que importa en la vida no es lo que te ocurre, sino lo que recuerdas y cómo lo recuerdas",
        author: "Gabriel García Márquez"
    },
    {
        message: "El amor es eterno mientras dura",
        author: "Gabriel García Márquez"
    },
    {
        message: "Puedes ser solamente una persona para el mundo, pero para una persona tú eres el mundo",
        author: "Gabriel García Márquez"
    },
    {
        message: "Nunca dejes de sonreír, ni siquiera cuando estés triste, porque nunca sabes quién se puede enamorar de tu sonrisa",
        author: "Gabriel García Márquez"
    },
    {
        message: "También el amor se aprende",
        author: "Gabriel García Márquez"
    },
    {
        message: "Leer un poema es escucharlo con los ojos; oírlo es verlo con nuestros oídos",
        author: "Octavio Paz"
    },
    {
        message: "Un verdadero amigo es quien te toma de la mano y te toca el corazón",
        author: "Gabriel García Márquez"
    },
    {
        message: "Me gustaría ser valiente mi dentista asegura que no lo soy",
        author: "Jorge Luis Borges",
        size:"sml"
    },
    {
        message: "Soñar es la actividad estética más antigua",
        author: "Jorge Luis Borges",
        size:"sml"
    },
    {
        message: "He sospechado alguna vez que la única cosa sin misterio es la felicidad, porque se justifica por sí sola",
        author: "Jorge Luis Borges"
    },
    {
        message: "El tiempo es el mejor antologista, o el único, tal vez",
        author: "Jorge Luis Borges",
        size:"sml"
    },
    {
        message: "Si viéramos realmente el universo, tal vez lo entenderíamos",
        author: "Jorge Luis Borges",
        size:"sml"
    },
    {
        message: "El cine es mi vida mi biografía",
        author: "Guillermo del Toro",
        size:"sml"
    },
    {
        message: "Elige un trabajo que ames, y no tendrás que trabajar un solo día de tu vida",
        author: "Confucio"
    },
    {
        message: "Un día sin risa es un día perdido",
        author: "Charles Chaplin",
        size:"sml"
    },
    {
        message: "No tengo talentos especiales solo soy apasionadamente curioso",
        author: "Albert Einstein",
        size:"sml"
    },
    {
        message: "No cuentes los días, haz que los días cuenten",
        author: "Muhammad Ali",
        size:"sml"
    },
    {
        message: "No hay camino para la verdad, la verdad es el camino",
        author: "Mahatma Gandhi",
        size:"sml"
    },
    {
        message: "Escribe lo que no debe ser olvidado",
        author: "Isabel Allende",
        size:"sml"
    },
    {
        message: "Merece tu sueño",
        author: "Octavio Paz",
        size:"sml"
    },
    {
        message: "Si se quiere viajar lejos, no hay mejor nave que un libro",
        author: "Emily Dickinson",
        size:"sml"
    },
    {
        message: "La mejor vida no es la más duradera, sino aquella que está repleta de buenas acciones",
        author: "Marie Curie"
    },
    {
        message: "No hay nada más hermoso que la risa",
        author: "Frida Kahlo",
        size:"sml"
    },
    {
        message: "Las tristezas no se quedan para siempre cuando caminamos en dirección a lo que siempre hemos deseado",
        author: "Paulo Coelho"
    },
    {
        message: "De la quietud nace la inspiración y del movimiento surge la creatividad",
        author: "Isabel Allende"
    },
    {
        message: "Me pinto a mí misma porque soy a quien mejor conozco",
        author: "Frida Kahlo",
        size:"sml"
    },
    {
        message: "Cuando quieres realmente una cosa, todo el universo conspira para ayudarte a conseguirla",
        author: "Paulo Coelho"
    },
    {
        message: "El que busca la verdad corre el riesgo de encontrarla",
        author: "Isabel Allende",
        size:"sml"
    },
    {
        message: "Si vas a ser alguien, debes ser un líder, no un seguidor sé fuerte, lo imposible siempre es posible",
        author: "Selena Quintanilla-Pérez, cantante y compositora",
        size:"sml"
    },
    {
        message: "Donde no puedas amar, no te demores",
        author: "Frida Kahlo",
        size:"sml"
    },
    {
        message: "Recordar un buen momento es sentirse feliz de nuevo",
        author: "Gabriela Mistral",
        size:"sml"
    },
    {
        message: "La libertad es contagiosa, uno siempre quiere más",
        author: "Yoani Sánchez",
        size:"sml"
    },
    {
        message: "No digas lo que piensas, pero piensa lo que dices",
        author: "Gabriela Mistral",
        size:"sml"
    },
    {
        message: "No le tengo miedo a nada, no me quejo incluso cuando me ocurren cosas horribles, continúo hacia adelante",
        author: "Sofía Vergara"
    },
    {
        message: "El éxito está en la vida que uno lleva, en cómo vive, cómo goza, cómo se integra, cómo disfruta de las pequeñas cosas",
        author: "Laura Esquivel"
    },
    {
        message: "Damos la vida sólo a lo que odiamos",
        author: "Rosario Castellanos",
        size:"sml"
    },
    {
        message: "El mayor peligro que nos depara el futuro es la apatía",
        author: "Jane Goodall",
        size:"sml"
    },
    {
        message: "Debemos decirles a nuestras jóvenes que sus voces son importantes",
        author: "Malala Yousafzai"
    },
    {
        message: "Nada es imposible, la propia palabra lo dice: puedo hacerlo",
        author: "Audrey Hepburn",
        size:"sml"
    },
    {
        message: "La paz comienza con una sonrisa",
        author: "Madre Teresa de Calcuta",
        size:"sml"
    }
];

export const BookSynoname =
{
    "1": "AM3",
    "2": "C2",
    "3": "C3",
    "4": "C4",
    "5": "C%",
    "6": "C6",
    "7": "A1",
    "8": "A2",
    "9": "AL1",
    "10": "AL2",
    "11": "A1W",
    "12": "A2W",
    "13": "A1A",
    "14": "A2A",
    "15": "",
    "16": "",
    "17": "",
    "18": "",
    "19": "",
    "20": "",
    "21": "",
    "22": "",
    "23": "A1-D",
    "24": "A2-D",
    "25": "AL1-D",
    "26": "AL2-D",
    "27": "A1A-D",
    "28": "A2A-D",
    "29": "A1W-D",
    "30": "A2W-D",
    "31": "H-E1",
    "32": "AI",
    "33": "BAC",
    "34": "AI-D",
    "35": "ME",
    "36": "Examen final",
    "37": "AIW",
    "45": "M-E1",
    "49": "CO",
    "50":"M-E2",
    "51":"H-E2"
}

export const INFORMATE_BASE64 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/ebook%2FInformate%2FPortadas%2FCoverPrincipal.jpg?alt=media&token=51d94eac-9f4d-4800-9f71-3a245b784018'

export const COMUNIDAD_BASE64 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/portadas%2FComunidad%2FCOVER.jpg?alt=media&token=b1117529-629c-4274-86c6-b4c8b54cb220'

export const BALCON_COVER_BASE64 = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/ebook%2FBCultural%2F1%20Portada.jpg?alt=media&token=035a095d-91f6-4c20-b760-e64e80afb13b'

export const CONECTANDOME_IMG = 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/ebook%2FConectandome%2FConectandome%20%20Portada.jpg?alt=media&token=0d812807-2f03-439f-a2bd-fe118e55bb1c'

export const KEY_FOR_REMOVE = ["cureentClassId", "coatationLoader", "cureentClassId", "islodadedOnceBalcon", "cureentClassId", "newsLatterCurrentClass", "notes_visited", "question_visited", "currentUserId", "videoScreenloaded", "adminVisualClassId", "currentClassIdForGrade", "gradebookUserId", "schoologyhandled", "currentClassId","forUser",'admin_last_update']

export const ASSIGNMET_GRADE_DISPLAY = [
  {
    name: "Percentage",
    grade: "percent",
  },
  {
    name: "Complete/Incomplete",
    grade: "complete_incomplete",
  },
];


export const GRADE_TYPE_ALL = [
    {
        type:'Percentage',
        value:'percent',
    },
    {
        type:'Points',
        value:'points',
    }
    ]
   export const GRADE_TYPE_CANVAS = [
    {
        type:'Percentage',
        value:'percent',
    },
    {
        type:'Points',
        value:'points',
    },
    {
        type:'Complete / Incomplete ',
        value:'complete_incomplete',
    }]


export const SPANISH_ITEM_LOWER = ["á", "é", "í", "ó", "ú", "ü", "ñ", "¿", "?", "¡", "!"];
export const SPANISH_ITEM_UPPER = ["Á", "É", "Í", "Ó", "Ú", "Ü", "Ñ", "¿", "?", "¡", "!"];

export const QUESTION_TYPE_ACTIVITY = [
    {
        type: 'essay',
        value: 'essay',
    },
    {
        type: 'email',
        value: 'email',
    },
    {
        type: 'audio (also upload your own)',
        value: 'audio',
    },
    {
        type: 'twoMinAudio (only recording)',
        value: 'twoMinAudio',
    },
    {
        type: 'multipleChoice',
        value: 'multipleChoice',
    }
]


export const IB_Theme = [
    'Identidad',
    'Experiencias',
    'Ingenio humano',
    'Organización social',
    'Compartir el planeta',   
]

export const AP_THEME = [
    "Desafíos globales",
    "Ciencia y tecnología",
    "Vida contemporánea",
    "Identidades personales y públicas",
    "Familias y comunidades",
    "Belleza y estética",
    "Las sociedades en contacto",
    "La construcción del género",
    "El tiempo y el espacio",
    "La creación literaria",
    "Las relaciones interpersonales",
    "La dualidad del ser"
];

export const ANÉCDOTAS_THEMES = [
    'La unión como base',
    'Los tipos de relaciones en la sociedad',
    'La familia, el núcleo de la sociedad',
    'La juventud, mi juventud',
    'En busca de la paz',
    'Mi mundo que sigue innovando',
    'Los valores y mis responsabilidades',
    'La inmigración en el mundo',
    'Mi vida contemporánea',
    'Las redes nos enredan',
    'La influencia de los influencers',
    'Mi identidad en el mundo',
    'La fantasía y la imaginación',
    'La forma de vivir: ¿De vuelta a la normalidad o nueva normalidad?',
    'Mi comunidad es también mi familia',
    'Mi salud, mi vida',
    'Una historia para contar: Somos latinos, somos hispanos',
    'Enfrentando los desafíos del mundo',
    'Las familias y su enlace con la sociedad',
    'Las sociedades en contacto',
    'Las familias en diferentes sociedades',
    'El impacto de la cultura y el lenguaje en las identidades',
    'La construcción del género',
    'La influencia de la lengua y la cultura en la identidad',
    'Contemplando el arte como influencia en la vida',
    'La línea del tiempo',
    'La influencia de la belleza y el arte',
    'La tecnología y sus beneficios en la vida diaria',
    'El arte, un puente hacia la literatura',
    'Cómo la ciencia y la tecnología afectan nuestras vidas',
    'La vida contemporánea y sus cambios en las comunidades',
    'Interconexión, colaboración y resultados',
    'Los factores que impactan la calidad de nuestra vida',
    'Las sociedades frente a los retos',
    'Las múltiples facetas del ser humano',
    'Los cambios ambientales, políticos y sociales'
];
