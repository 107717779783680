//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import { has } from "lodash";
//  -- Actions ------------------------
import * as Sentry from "@sentry/react";
import { sortBy } from "lodash";
import * as SuperLessonActions from './actions';
import axios from 'axios';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create Super Lesson --------------------

export const handleCreateDifferentiationLesson = (data) => async (dispatch) => {
    // await dispatch(SuperLessonActions.createVocabulary());
    try {
        const lessonData = await API.post('differentiation_lesson', `/create`, {
            body: data
        });
        if (data.id) {
            await dispatch(SuperLessonActions.updateDifferentionLesson(lessonData));
        } else {
            await dispatch(SuperLessonActions.createDifferentionLesson(lessonData));
        }
        return lessonData
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};

export const handleAiDifferentiationLesson = (LessonData) => async (dispatch) => {
    // await dispatch(ActivityActions.createBooks());

    try {
        // const data = await API.post("vocabularyRDS", `/ai_differentiation_lesson`, {
        //     body: LessonData,
        // });
        const lessonData = {
            ...LessonData,
            mode: process.env.REACT_APP_STAGE === "prod" ? "prod" : "dev",
          };
          const base_url = process.env.REACT_APP_STAGE === "prod"?`https://autologin.nosotrosonline.com:8080/differentiation_lesson`:`https://autologin.nosotrosonline.com:3000/DifferentiationApi/create-differentiation-lesson`;
          const data = await axios
            .post(base_url, lessonData)
            .then((response) => response.data)
            .then((response) => response);
        // if (LessonData.id) {
        //     await dispatch(SuperLessonActions.updateLessonSuccess(data));
        // } else {
            await dispatch(SuperLessonActions.createDifferentionLesson(data));
        // }
        return data;
    } catch (error) {
        console.log("create book error", error);
    }
};

export const handleGetDifferentiationLesson = (data) => async (dispatch) => {
    try {
        const lessonData = await API.post('differentiation_lesson', `/list`, {
            body: data
        });
        await dispatch(SuperLessonActions.listDifferentionLesson(lessonData));
        return lessonData;
    } catch (error) {
        if (error) {
            Sentry.captureException(error)
            return null;
        }
    }
};

//  -- Delete Book vocabulary ----------
export const handleDeleteDifferentiationLesson = (id) => async (dispatch) => {
    if (!id) return;
    // dispatch(SuperLessonActions.deleteVocabulary())
    try {
        const vocabulary = await API.del('differentiation_lesson', `/delete/${id}`);
        if (has(vocabulary, "id")) {
            await dispatch(SuperLessonActions.deleteDifferentionLesson(vocabulary));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
    }
};
