//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
export const Wrapper = styled.div`
    height: auto;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Message renderer
//
//  -- Description
//  Renders custom message payload from text editor component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const MessageRenderer = ({ data }) => {
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    const Element = ({ attributes, children, element }) => {
        switch (element.type) {
            case 'block-quote':
                return <blockquote {...attributes}>{children}</blockquote>
            case 'bulleted-list':
                return <ul {...attributes} style={{ paddingLeft: '16px',textAlign:"left" }}>{children}</ul>
            case 'heading-one':
                return <h1 {...attributes} style={{textAlign:"left"}}>{children}</h1>
            case 'heading-two':
                return <h2 {...attributes} style={{textAlign:"left"}}>{children}</h2>
            case 'list-item':
                return <li {...attributes} style={{textAlign:"left"}}>{children}</li>
            case 'numbered-list':
                return <ol {...attributes} style={{ paddingLeft: '16px',textAlign:"left" }}>{children}</ol>
            default:
                return <p {...attributes} style={{textAlign:"left"}}>{children}</p>
        }
    };

    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }
      
        if (leaf.italic) {
            children = <em>{children}</em>
        }
      
        if (leaf.underline) {
            children = <u>{children}</u>
        }
      
        return <span {...attributes}>{children}</span>
    };
   if(typeof data === 'string'){
      return <div className='styled-text' dangerouslySetInnerHTML={{__html:data}}  style={{textAlign:"left", width:"100%"}} /> 
   }
   else if(!data){
       return ""
   }
   editor.children = data;
   return (
       <Wrapper>
           <Slate editor={editor} value={data}>
               <Editable
                   renderElement={renderElement}
                   renderLeaf={renderLeaf}
                   autoFocus
                   readOnly={true}
               />
           </Slate>
       </Wrapper>
   );
};

//  -- Prop types ---------------------
MessageRenderer.propTypes = {};

//  -- Default props ------------------
MessageRenderer.defaultProps = {};

export default MessageRenderer;
