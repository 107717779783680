
import {
    IMG_7574,
    IMG_7575,
    IMG_7576,
    IMG_7577,
    IMG_7595,
    IMG_7596,
    IMG_7600,
    IMG_7605,
    IMG_7611,
    IMG_7614
} from 'utils/assets/images';
import { find } from 'lodash'
export const AVATARS = {
    IMG_7614,
    IMG_7611,
    IMG_7605,
    IMG_7600,
    IMG_7574,
    IMG_7575,
    IMG_7576,
    IMG_7577,
    IMG_7595,
    IMG_7596
}
export const PROFILE_AVATARS = [
    {
        poster: require("./lottie/BlueMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F1.png?alt=media&token=e2a16008-ede0-43d8-bca8-c9b2783d7dd7",
        key: 'BlueMonsterdata'
    },
    {
        poster: require("./lottie/GradientMonster2data.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F2.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'GradientMonster2data'
    },
    {
        poster: require("./lottie/GradientMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F3.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'GradientMonsterdata'
    },
    {
        poster: require("./lottie/GreenMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F4.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'GreenMonsterdata'
    },
    {
        poster: require("./lottie/LimeMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F5.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'LimeMonsterdata'
    },
    {
        poster: require("./lottie/OrangeMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F6.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'OrangeMonsterdata'
    },
    {
        poster: require("./lottie/PinkMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F7.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'PinkMonsterdata'
    },

    {
        poster: require("./lottie/PurpleMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F8.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'PurpleMonsterdata'
    },
    {
        poster: require("./lottie/redmonster2data.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F12.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'redmonster2data'
    },
    {
        poster: require("./lottie/SkyBlueMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F9.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'SkyBlueMonsterdata'
    },
    {
        poster: require("./lottie/Teal Monster2data.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F10.png?alt=media&token=9303f6a2-63f2-45b4-88f6-3df5c775d28f",
        key: 'TealMonster2data'
    },
    {
        poster: require("./lottie/YellowMonsterdata.json"),
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/lootie-animation%2F11.png?alt=media&token=1ef9cab0-cb88-481d-8e21-a933685bbac7",
        key: 'YellowMonsterdata'
    }
]

export const NOTIFICATIONICONS = {
    poster: require("./lottie/nonotification.json")
}
export const ELEPHANT = {
    poster: require("./lottie/elephant.json")
}



export const getAvatar = (avatar) => {
    if (avatar) {
        const file_data = find(PROFILE_AVATARS, ["key", avatar]);
        if (file_data) return file_data.thumbnail
        else if (AVATARS[avatar]) {
            return AVATARS[avatar]
        }
        return null;
    }
}