//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Delete } from '@material-ui/icons';
import MessageRenderer from 'components/MessageRenderer';
import moment from 'moment';
import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
// -- Thunks --------------------------
import { handleDeletePost } from 'store/posts/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';





//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: #3D5F8F;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: -3px;
    outline: none;
    padding: 0px;
    transition: all 300ms ease;

    &:hover {
        color: red;
    }
`;

const Content = styled.div`
    background-color: ${p => p.background && (p.admin ? COLOR.green800 : COLOR.black800)};
    border-radius: 10px;
    font-size: 12px;
    font-weight: 400;
    padding: ${p => p.background ? '10px' : '0px'};
`;

const Date = styled.span`
    display: block;
    font-size: 10px;
`;

const Details = styled.span`
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
`;

const Divider = styled.div`
    margin: 0px 2px;
`;

const Header = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2px;
`;

const Row = styled.div`
    algin-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
`;

const Text = styled.span`
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
`;

const Wrapper = styled.div`
    margin-bottom: 32px;
    margin-right: 4px;

    &:first-child {
        margin-bottom: 0px;
    }
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Announcement
//
//  -- Description
//  Announcement component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcement data={data} />
//
const AnnouncementWrap = ({
    children,
    currentUser,
    data,
}) => {
    const dispatch = useDispatch();
    const onDelete = async () => {
        await dispatch(handleDeletePost(data.id));
    };

    const renderMessage = () => {
        if (typeof children === 'string') {
            return (
                <ReactPlayer
                    url={children}
                    height="40px"
                    width="100%"
                    controls
                    playsinline
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />
            );
        } else {
            return (
                <MessageRenderer data={children} />
            );
        }
    }

    return (
        <Wrapper>
            {data?.userData && (
                <Header>
                    <Details>{data.userData?.firstName} {data.userData?.lastName}</Details>
                    <Divider />
                    {data.classData ? (
                        <Details>({data.classData?.displayName})</Details>
                    ) : (
                        <Details>({data.orgData?.displayName})</Details>
                    )}
                </Header>
            )}
            <Content admin={data?.userData?.role === 'admin'} background={typeof children !== 'string'}>
                {renderMessage()}
            </Content>
            <Row>
                <Date>{moment(parseInt(data.createdAt)).format('MMM, DD YYYY @ hh:mm')}</Date>
                {((data.classData && currentUser?.role !== 'student') || data.userId === currentUser?.id) ? (
                    <Button onClick={onDelete}>
                        <Text>Delete</Text>
                        <Delete style={{ fontSize: '14px', marginLeft: '2px' }} />
                    </Button>
                ) : (
                    <div />
                )}
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
AnnouncementWrap.propTypes = {};

//  -- Default props ------------------
AnnouncementWrap.defaultProps = {
    fontSize: 10
};

export default AnnouncementWrap;