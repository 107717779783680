import { Tooltip } from 'antd';
import React from 'react';

export const TootipWrapper=({children,hasToolTip,label, position,hideVisability})=>{
    if(hasToolTip){
       return(<Tooltip  
                 placement={position?position:"topLeft"}
                 trigger={"hover"}
                  title={typeof label=="string"?label:`This activity includes a "timer", are you sure you want to start it?`}>
                    {children}
              </Tooltip>)
    }
      else {
         return <>{children}</>
    }

}