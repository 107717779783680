//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

export const userLogin = () => ({ type: ActionTypes.auth.userLogin });
export const userLoginFail = (returnedPayload) => ({ type: ActionTypes.auth.userLoginFail, payload: returnedPayload });
export const userLoginSuccess = (returnedPayload) => ({ type: ActionTypes.auth.userLoginSuccess, payload: returnedPayload });

export const userLogout = () => ({ type: ActionTypes.auth.userLogout });
export const userLogoutFail = (returnedPayload) => ({ type: ActionTypes.auth.userLogoutFail, payload: returnedPayload });
export const userLogoutSuccess = (returnedPayload) => ({ type: ActionTypes.auth.userLogoutSuccess, payload: returnedPayload });
export const userLogoutStorageClean = () => ({ type: ActionTypes.auth.USER_LOGOUT_CLEAN_STORAGE })