//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
//  -- Components ---------------------
import InitialLoading from 'components/InitialLoading';
import PodcastLayout from 'features/PodcastLayoutNew';
import { filter , get , has } from "lodash";
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleListClassActivities , handleListClassActivitiesForStudent } from 'store/classActivities/thunks';
import { handleListClassQuizzes  , handleListClassQuizzesForStudent} from 'store/classQuizzes/thunks';
//  -- Thunks -------------------------
import { handleListUserLikes } from 'store/likes/thunks';
import { handleGetBubbleByBook, handleListUserNotes } from 'store/notes/thunks';
import { handleListClassQuestions, handleListUserQuestions } from 'store/questions/thunks';
// import { handleListChapterQuizzes } from 'store/quizzes/thunks';
import { handleListUserSubmissions } from 'store/submissions/thunks';
import { handleTipsBook } from 'store/tips/thunks';
// import { handleListClassUsers } from 'store/userClasses/thunks';
import { useAlertView } from "store/alert/selectors";
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import styled from 'styled-components';
//  -- Lib ----------------------------
import { useVideos } from "store/resources/selectors";
import { find } from 'lodash';




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 90vh;
    justify-content: center;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const VideosContainer = ({
    currentClass,
    currentUser,
    tipsBook,
    quizzes,
    classUsers,
    classQuizzes,
    classActivities,
    interactions,
    submissions,
    activities,
    questions,
    notes,
    likes
}) => {
 
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingContent,setLoadingContent]=useState(true)
    const [mounted, setMounted] = useState(false);
    const [,invokeAlert,revokeALert ] =useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    const search = useMemo(()=>queryString.parse(history.location.search),[history.location.search]) ;
    const videos = useVideos()

    useEffect(() => {
        setTimeout(() => setMounted(true), 250)
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);
    useEffect(()=>{
        if(orgPermissions && typeof orgPermissions['1WAYRESOURCES']==="boolean"){
          if(!orgPermissions['1WAYRESOURCES']){
            invokeAlert()
            setTimeout(()=>{
              history.goBack();
              revokeALert()
            },4000)
          }
        }
      },[orgPermissions])
    useEffect(() => {
        const videoData = find(videos,['id',search.id])
        setData(videoData);
        
    }, [search.id,videos])
    const getBookQuestinAndQuiz = async () => {
        if (has(currentClass, "id") && get(currentUser, "role") != "student") {
            dispatch(handleListClassActivities(currentClass.id));
            dispatch(handleListClassQuizzes(currentClass.id));
          } else {
            dispatch(handleListClassActivitiesForStudent(get(currentClass, "id"), get(currentUser, "id")));
            dispatch(handleListClassQuizzesForStudent(get(currentClass, "id"), get(currentUser, "id")));
          }
      }
    const getStudentSubmissions =async () =>{
        if(currentUser?.role=="student"){
            await dispatch(handleListUserSubmissions(currentUser?.id))
         }
    }
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setLoadingContent(true)
            const videoScreenloaded =localStorage.getItem('videoScreenloaded')
            if(videoScreenloaded&&videoScreenloaded==currentClass.id){
                setLoadingContent(false)
            }
            // if(reourcesQuizzes.length==0){
            //      await dispatch(handleListChapterQuizzes(search.id))    
            // }
           const payloadForNotes = {
                chapter : search.id,
                classId: get(currentClass, "id"),
                userId: get(currentUser, "id")
            }
           await Promise.allSettled([
            getBookQuestinAndQuiz(),
            // dispatch(handleListUserQuestions(currentUser?.id)),
            dispatch(handleListUserNotes(currentUser?.id)),
            dispatch(handleListUserLikes(currentUser?.id)),
            getStudentSubmissions(),
            dispatch(handleTipsBook(search.id)),
            dispatch(handleGetBubbleByBook(payloadForNotes))
           ])
            // if(currentClass&&currentClass.id){
            //     await  dispatch(handleListClassQuestions(currentClass.id))
            //     await  dispatch(handleListClassQuizzes(currentClass.id))
            //   }
            // if(currentUser&&currentUser?.id){
            //     dispatch(handleListUserQuestions(currentUser?.id))
            //     dispatch(handleListUserNotes(currentUser?.id))
            //     dispatch(handleListUserLikes(currentUser?.id))
            // }
            // if(currentUser?.role=="student"){
            //     await dispatch(handleListUserSubmissions(currentUser?.id))
            //  }
           
            // dispatch(handleTipsBook(search.id))
            setLoadingContent(false)
            localStorage.setItem('videoScreenloaded', currentClass?.id);
            setLoading(false);
        }
        fetchData();
    }, [currentUser?.id, currentClass?.id, dispatch])

    return loading ? (
        <CenterContainer>
            <InitialLoading mounted={mounted} />
        </CenterContainer>
    ) : (
        <PodcastLayout 
            data={data} 
            id={search.id}
            quiz={search.quiz}
            activity={search.activity}
            loading={loading}
            tipsBook={tipsBook}
            quizzes={quizzes}
            classUsers={classUsers} 
            classQuizzes={classQuizzes}
            classActivities={classActivities}
            submissions={submissions}
            loadingContent={loadingContent}
            currentUser={currentUser}
            currentClass={currentClass}
            interactions={interactions}
            activities={activities}
            questions={questions}
            notes={notes}
            likes={likes}
        />
    );
};

//  -- Prop types ---------------------
VideosContainer.propTypes = {};

//  -- Default props ------------------
VideosContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    tipsBook: state.tipsBook,
    quizzes: state.quizzes,
    classUsers: state.classUsers,
    classQuizzes: state.classQuizzes,
    classActivities: state.classActivities,
    submissions: state.submissions,
    likes: state.likes,
    notes: state.notes,
    questions: state.questions,
    activities: state.activities,
    interactions: state.interactions,
});

export default connect(mapStateToProps, null)(VideosContainer);
