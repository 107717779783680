import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  IconButton,
  Switch,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Check, Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import { Button as AntdButton, Row as Rows } from "antd";
import AccordinButton from "components/AccordinButton";
import AnimatedCheckMark from "components/AnimatedCheckMark";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import { find, get, has, map, shuffle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useUsersByClass } from "store/userClasses/selectors";
import { useCurrentUser } from "store/users/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import { default as COLOR, default as color } from "utils/constants/color";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { v4 } from "uuid";
import ShareIcon from "@material-ui/icons/Share";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  // min-height: 500px;
  // background-color: rgb(58,58,58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: default;
  left: 25%;
  border-radius: 13px;
`;

const LogoTopRow = styled.div``;
const Logo = styled.img`
  height: auto;
  width: 160px;
`;

const CloseBtn = styled.div`
  background-color: ${COLOR.PinkExam};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const EditListOption = styled.div`
  text-align: center;
  align-items: center;
  background-image: linear-gradient(
    90deg,
    ${COLOR.WheelEditColor1} 0%,
    ${COLOR.WheelEditColor2} 40%,
    ${COLOR.WheelEditColor3} 100%
  );
  color: #fff;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
`;

const ResetButton = styled.div`
  text-align: center;
  align-items: center;
  background: #d11919;
  color: white;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
  // @media (max-width: 997px) {
  //   margin-top: 16px;
  // }
`;

const UserArea = styled.div`

  display: ${props => props?.dataExist ? 'flex' : ''};
  flex-wrap:wrap;
  justify-content: center;
  // flex-direction: row;
  margin-top: 16px;
  // flex-wrap: wrap;
  height: calc(100% - 100px);
  place-items: center;
  // grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  overflow: hidden auto !important;
  @media (max-width: 997px) {
    max-width: 90vw;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }
`;

const SectionsGroup = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GroupHeaderText = styled.div`
  background-color: #fd9d1a;
  padding: 8px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 200px;
  text-align: center;
  color: #fff;
  justify-content: center;
`;

const UserListArea = styled.div`
  margin-inline: auto;
  background: linear-gradient(90deg, #DF6074 0%, #BD57B4 30%, #A451E7 50%, #9A59E8 60%, #7579F0 90%, #6786F4 100%) !important;
  min-height: 114px;
  width: 200px;
  border-radius: 10px;
  padding: 10px;
  margin: ${props => props?.type ? '12px 16px' : ''} ;
  margin-bottom:  ${props => !props?.type && "10px" }
`;

const UserItem = styled.div`
  background: ${(p) => (p.getMyUser ? "#152B69" : "#fff")};
  color: ${(p) => (p.getMyUser ? "#fff" : "" )};
  padding: 8px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
`;

const UserInput = styled(TextField)`
  margin-top: 8px;
`;

const UserInputControled = styled(TextField)`
  margin-top: 8px;

  .MuiInputBase-root {
    height: 25px; // Adjust the height as needed
    padding: 0 14px; // Reduce padding to decrease height

    .MuiInputBase-input {
      font-size: 0.875rem; // Adjust font size to match the smaller input
    }
  }

  .MuiInputLabel-root {
    font-size: 0.875rem; // Adjust label font size to match
  }

  .MuiOutlinedInput-root {
    padding: 0;
    position: relative;
  }
`;

const UserListAreaEmpty = styled.div`
  background: #cbbbe7;
  min-height: 114px;
  min-width: 175px;
  border-radius: 10px;
  padding: 10px;
  margin: 12px 16px;
  display: flex;
  align-items: center;
`;

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
})(TextField);

const STATIONS = [
  { label: "LEE", enabled: true, custom: false },
  { label: "ESCRIBE", enabled: true, custom: false },
  { label: "ESCUCHA", enabled: true, custom: false },
  { label: "DEBATE", enabled: true, custom: false },
  { label: "INVESTIGA", enabled: true, custom: false },
];

const useStyles = makeStyles((theme) => ({
  noSpinners: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const StudentSectionArea = ({
  setNumberOfGroups,
  numberOfGroups,
  createStudentGroup,
  studentGroup,
  resetGroup,
  stations,
  onStationChange,
  dimensions,
  isTeacher,
  sharedData,
  setStudentOfCurrentClass,
  currentClass,
  studentOfCurrentClass,
  currentUser,
}) => {
  const classes = useStyles();
  const [groups, setGroups] = useState(studentGroup);
  const [dragging, setDragging] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [newUser, setNewUser] = useState({});
  const [showInput, setShowInput] = useState({});
  const [addStations, setAddStations] = useState(false);
  const [stationsList, setStationsList] = useState(stations);
  const [newStation, setNewStation] = useState({
    label: "",
    enabled: true,
    custom: true,
  });
  const [editableIndex, setEditableIndex] = useState(-1);

  useEffect(() => {
    setGroups(studentGroup);
  }, [studentGroup]);
  useEffect(() => {
    setStationsList(stations);
  }, [stations]);

  const handleToggleEnabled = (index) => {
    const updatedStations = [...stationsList];
    updatedStations[index].enabled = !updatedStations[index].enabled;
    onStationChange(updatedStations);
  };

  const handleDeleteStation = (index) => {
    const updatedStations = stationsList.filter((_, i) => i !== index);
    onStationChange(updatedStations);
  };

  const handleDragStart = (e, user, groupIndex) => {
    if (!isTeacher) return;
    const dragData = { userId: user.id, groupIndex };
    setDragging(user.id);
    e.dataTransfer.setData("application/json", JSON.stringify(dragData));
    e.target.style.opacity = "0.5";
  };

  const handleDragEnd = (e) => {
    console.log("dragEnd");
    setDragging(null);
    setExpandedGroup(null);
    e.target.style.opacity = ""; // Reset visual cue
  };

  const handleDragOver = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex);
  };

  const handleDragEnter = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex); // Same as DragOver, to ensure consistency
  };

  const handleDragLeave = (e, groupIndex) => {
    if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
      setExpandedGroup(null);
    }
  };

  const handleDrop = (e, groupIndex) => {
    e.preventDefault();
    const dropData = JSON.parse(e.dataTransfer.getData("application/json"));
    if (dropData.groupIndex !== groupIndex) {
      const sourceGroupStudents = groups[dropData.groupIndex].students.filter(
        (user) => user.id !== dropData.userId
      );
      const droppedUser = groups[dropData.groupIndex].students.find(
        (user) => user.id === dropData.userId
      );
      const targetGroupStudents = [...groups[groupIndex].students, droppedUser];

      const newGroups = groups.map((group, index) => {
        if (index === dropData.groupIndex)
          return { ...group, students: sourceGroupStudents };
        if (index === groupIndex)
          return { ...group, students: targetGroupStudents };
        return group;
      });

      setGroups(newGroups);
    }
    setExpandedGroup(null);
    setDragging(null);
  };
  const toggleInput = (groupIndex) => {
    setShowInput((prev) => ({ ...prev, [groupIndex]: !prev[groupIndex] }));
  };

  const handleAddNewUserToGroup = (groupIndex) => {
    if (newUser[groupIndex]) {
      const updatedGroups = groups.map((group, index) => {
        if (index === groupIndex) {
          return {
            ...group,
            students: [...group.students, newUser[groupIndex]],
          };
        }
        return group;
      });

      setGroups(updatedGroups);
      setNewUser({ ...newUser, [groupIndex]: null });
      const newData = {
        ...newUser[groupIndex],
        id: v4(),
        role: "student",
        classId: currentClass?.id,
      };
      setStudentOfCurrentClass([...studentOfCurrentClass, newData]);
    }
  };

  const handleNewUserChange = (e, groupIndex) => {
    setNewUser({
      ...newUser,
      [groupIndex]: { ...newUser[groupIndex], [e.target.name]: e.target.value },
    });
  };

  // Function to handle scrolling when dragging near the edges
  const userAreaRef = useRef(null);
  const handleScroll = (event) => {
    const { clientY } = event;
    const userArea = userAreaRef.current;

    if (!userArea) return;

    const { top, bottom } = userArea.getBoundingClientRect();
    const offset = 10;

    if (clientY - top < offset) {
      userArea.scrollTop -= offset;
    } else if (bottom - clientY < offset) {
      userArea.scrollTop += offset;
    }
  };

  const handleTouchToggleInput = (event, groupIndex, value) => {
    event.preventDefault();
    if (value === "add_user") {
      toggleInput(groupIndex);
    } else {
      handleAddNewUserToGroup(groupIndex);
      toggleInput(groupIndex);
    }
  };

  const getNewgroup = sharedData?.group || [];

  return (
    <>
     <div style={{ marginTop: dimensions?.width > 250 ? "40px": "10px"}} className="student-per-group">Student per station</div>
      {isTeacher && (
        <Rows onTouchStart={handleScroll}
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: dimensions.width > 250 ? "30px": "10px",
            flexDirection:  "row",
            gap: "5px",
          }}
        >
          <InputField
            id="noOfGroup"
            name="noOfGroup"
            // label="Students per stations"
            style={{ width: dimensions.width < 299 ? 50 : 83, color: "#000" }}
            InputLabelProps={{
              shrink: true,
              required: true,
              style: { color: "#000", fontSize: 14 },
            }}
            inputProps={{
              shrink: true,
              required: true,
              style: { color: "#000" },
            }}
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (e.target.value >= 0) {
                setNumberOfGroups(e.target.value);
              }
            }}
            value={numberOfGroups}
            className={`${classes.noSpinners} group-input-label`}
          />
          <EditListOption
            style={{ width: dimensions?.width < 299 && "76px"}}
            onClick={createStudentGroup}
            onTouchStart={createStudentGroup}
          >
            Generate
          </EditListOption>
          <ResetButton   style={{ width: dimensions?.width < 299 && "47px"}}  onClick={resetGroup} onTouchStart={resetGroup}>
            Reset
          </ResetButton>
        </Rows>
      )}
      <UserArea dataExist={getNewgroup.length > 0} ref={userAreaRef} onDragOver={handleScroll}>
        {getNewgroup.length > 0 ? (
          getNewgroup.map((group, groupIndex) => (
            <SectionsGroup>
              <GroupHeaderText>{group.station || ""}</GroupHeaderText>
              <UserListArea type={true}
                key={group.station}
                style={{ borderRadius: 20 }}
                className={
                  expandedGroup === groupIndex ? "expandOnDragOver" : ""
                }
                onDrop={(e) => handleDrop(e, groupIndex)}
                onDragOver={(e) => handleDragOver(e, groupIndex)}
                onDragEnter={(e) => handleDragEnter(e, groupIndex)}
                onDragLeave={(e) => handleDragLeave(e, groupIndex)}
              >
                {group.students.map((user) => {
                  const MyUser = user?.id === currentUser?.id;
                  return (
                    <UserItem
                      getMyUser={MyUser}
                      key={user.id}
                      style={{ cursor: !isTeacher ?  "default" : "move" }}
                      className={dragging === user.id ? "hidden" : ""}
                      draggable={isTeacher ? true : false}
                      onDragStart={(e) => handleDragStart(e, user, groupIndex)}
                      onDragEnd={handleDragEnd}
                    >
                      {`${user.firstName || ""} ${user.lastName || ""}`}
                    </UserItem>
                  );
                })}
                {!showInput[groupIndex] && isTeacher && (
                  <IconButton
                    color="primary"
                    style={{ color: COLOR.blueishdeep }}
                    onClick={() => toggleInput(groupIndex)}
                    onTouchStart={(e) =>
                      handleTouchToggleInput(e, groupIndex, "add_user")
                    }
                  >
                    <AddIcon />
                  </IconButton>
                )}
                {showInput[groupIndex] && (
                  <div>
                    <UserInput
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      variant="outlined"
                      size="small"
                      style={{ color: "white" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.firstName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <UserInput
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      variant="outlined"
                      size="small"
                      style={{ color: "white", marginTop: "10px" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.lastName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: "10px", borderRadius: "50%" }}
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        handleAddNewUserToGroup(groupIndex);
                        toggleInput(groupIndex);
                      }}
                      onTouchStart={(e) =>
                        handleTouchToggleInput(e, groupIndex, "save_user")
                      }
                    />
                  </div>
                )}
              </UserListArea>
            </SectionsGroup>
          ))
        ) : (
          <>
            {isTeacher ? (
              <UserListArea type={false}>
                {map(stationsList, (station, i) => (
                  <UserItem key={station.label} style={{ cursor: "default" }}>
                    {editableIndex === i ? (
                      <UserInputControled
                        type="text"
                        name="station"
                        placeholder="station"
                        variant="outlined"
                        size="small"
                        autoFocus
                        style={{ color: "white" }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ shrink: true }}
                        value={newStation.label}
                        onChange={(e) => {
                          setNewStation({
                            ...newStation,
                            label: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      station.label
                    )}
                    {editableIndex === i ? (
                      <Check
                        style={{ cursor: "pointer", fontSize: 18 }}
                        onTouchStart={() => {
                            setEditableIndex(-1);
                            if (newStation.label.length > 0) {
                              let v = [...stationsList];
                              v[i] = newStation;
                              onStationChange(v);
                              setNewStation({
                                label: "",
                                enabled: true,
                                custom: true,
                              });
                            }
                          }
                        }
                        onClick={() => {
                          setEditableIndex(-1);
                          if (newStation.label.length > 0) {
                            let v = [...stationsList];
                            v[i] = newStation;
                            onStationChange(v);
                            setNewStation({
                              label: "",
                              enabled: true,
                              custom: true,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div>
                        {" "}
                        <EditOutlined
                          style={{ cursor: "pointer" }}
                          onTouchStart={() => {
                            setEditableIndex(i);
                            setNewStation(station);
                            setAddStations(false);
                          }}
                          onClick={() => {
                            setEditableIndex(i);
                            setNewStation(station);
                            setAddStations(false);
                          }}
                        />
                        {!station.custom && (
                          <Switch
                            checked={station.enabled}
                            size="small"
                            onChange={() => handleToggleEnabled(i)}
                            name="enabled"
                            color="primary"
                          />
                        )}
                        {station.custom && (
                          <IconButton
                            size="small"
                            color="secondary"
                            style={{ marginLeft: 10, fontSize: 20 }}
                            onClick={() => handleDeleteStation(i)}
                            onTouchStart={() => handleDeleteStation(i)}
                          >
                            <Delete style={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                      </div>
                    )}
                  </UserItem>
                ))}
                {!addStations ? (
                  <IconButton
                    color="primary"
                    onClick={() => setAddStations(true)}
                    onTouchStart={() => setAddStations(true)}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <div>
                    <UserInput
                      type="text"
                      name="station"
                      placeholder="station"
                      variant="outlined"
                      size="small"
                      style={{ color: "white" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newStation.label}
                      onChange={(e) => {
                        setNewStation({ ...newStation, label: e.target.value });
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: "10px", borderRadius: "50%" }}
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        if (newStation.label.length > 0) {
                          onStationChange([...stationsList, newStation]);
                          setNewStation({
                            label: "",
                            enabled: true,
                            custom: true,
                          });
                          setAddStations(false);
                        } else {
                          setAddStations(false);
                        }
                      }}
                      onTouchStart={() => {
                        if (newStation.label.length > 0) {
                          onStationChange([...stationsList, newStation]);
                          setNewStation({
                            label: "",
                            enabled: true,
                            custom: true,
                          });
                          setAddStations(false);
                        } else {
                          setAddStations(false);
                        }
                      }}
                    />
                  </div>
                )}
              </UserListArea>
            ) : (
              <UserListAreaEmpty
              className="gradient-blue-pink"
              style={{
                justifyContent: "center",
              }}
            >
              <span style={{ color: "white", fontSize: 18 }}>Not available.</span>
            </UserListAreaEmpty>
              // <div>
              //   <span style={{ color: "black", fontSize: 18 }}>
              //     Station is not available yet.
              //   </span>
              // </div>
            )}
          </>
        )}
      </UserArea>
    </>
  );
};

const WidgetsStation = ({ currentClass, sharableWidget }) => {
  const timeoutRef = useRef();
  const [editorFocus, setEditorFocus] = useState(false);
  const [showAccordin, setShowAccordin] = useState(true);
  const [dimensions, setDimensions] = useState({});
  const usersByClass = useUsersByClass();
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets } = useCollectionWidgets();
  const isTeacher = get(currentUser, "role") === "teacher";
  const [stationStudentGroup, setStationStudentGroup] = useState([]);
  const [stations, setStations] = useState(STATIONS);
  const [studentOfCurrentClass, setStudentOfCurrentClass] = useState(
    usersByClass[currentClass.id]
  );
  const [numberOfStudentPerStation, setNumberOfStudentsPerStation] =
    useState(0);
    const [studentMinimized, setStudentMinimized] = useState(false);
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "new_station"
  );
  const filterShareStation = find(
    sharableWidget,
    (obj) => obj?.widget_type === "new_station"
  );

  const [isStudentGenerated, setStudentGenerated] = useState(false);

  useEffect(() => {
    if (usersByClass && has(currentClass, "id")) {
      setStations(STATIONS);
    }
  }, [usersByClass]);

  const createStudentGroupStation = () => {
    setStudentGenerated(true);
    if (usersByClass) {
      // const studentOfCurrentClass = usersByClass[currentClass.id];
      if (Array.isArray(studentOfCurrentClass)) {
        const shuffledStudents = shuffle(studentOfCurrentClass);
        let filteredStations = stations
          .filter((k) => k.enabled && k.label)
          .map((k) => k.label);
        const studentGroupsWithStations = filteredStations.map(
          (station, index) => {
            let start = index * numberOfStudentPerStation;
            let end =
              index === filteredStations.length - 1
                ? undefined
                : start + parseInt(numberOfStudentPerStation);
            let groupStudents = shuffledStudents.slice(start, end);

            return {
              station: station,
              students: groupStudents,
            };
          }
        );
        setStationStudentGroup(studentGroupsWithStations);
        const dataShare = {
          ...filterShareStation?.data,
          group: studentGroupsWithStations,
        };
        const editShareData = {
          ...filterShareStation,
          data: dataShare,
        };
        createCollectionWidgets(editShareData);
      } else {
        setStationStudentGroup([]);
      }
    } else {
      setStationStudentGroup([]);
    }
  };
  const onClose = async () => {
    if(!isTeacher){
      setStudentMinimized(true);
      return;
    }
    await onResetGroup();
    const closeShareData = {
      ...filterShareStation,
      status: false,
      data: {},
    };
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    if (filterShareStation?.status) {
      createCollectionWidgets(closeShareData);
    }
    setStudentOfCurrentClass(usersByClass[currentClass.id]);
  };

  const shareStation = () => {
    if (!filterShareStation?.id) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "new_station",
        status: true,
        data: {
          group: stationStudentGroup,
        },
      };
      createCollectionWidgets(shareData);
    }
  };

  const onResetGroup = () => {
    const dataShare = {
      ...filterShareStation?.data,
      group: [],
    };
    const editShareData = {
      ...filterShareStation,
      data: dataShare,
    };
    setStudentGenerated(false);
    setNumberOfStudentsPerStation(0);
    setStationStudentGroup([]);
    createCollectionWidgets(editShareData);
  };

  const handleTouchClose = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchShare = (event) => {
    event.preventDefault();
    shareStation();
  };

  const sharedData = filterShareStation?.data || {};


  const handleTouchStart = () => {
    setEditorFocus(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setEditorFocus(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {(get(currentUser,"role") == "student" && filterShareStation?.id && (studentMinimized === false) ) && (
        <Container className="allCheckListSEction wigetsContainer">
          <div className="mainCheckList">
            <ResizeAndDragabble
              defaultValue={{
                x: 150,
                y: 205,
                width:  windowSize?.width >= 768 ? 468 : 215,
                height: windowSize?.width >= 768 ? 555 : 255,
              }}
              aspectRatio={true}
              minWidth={215}
              minHeight={255}
              showAccordin={showAccordin}
              resize={(data) => setDimensions(data)}
              page={"station"}
              onFocusValue={editorFocus}
            >
              <div
                style={{
                  backgroundColor: showAccordin && "#fff",
                  position: "relative",
                  boxShadow: showAccordin && "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                  height: "100%",
                  width: "100%",
                  // display: "grid",
                  placeItems: !isStudentGenerated && "center",
                  borderRadius: "13px",
                  overflow: stations.length !== 0 ? "hidden auto" : "auto",
                  overflowX: "hidden",
                  marginBottom: "20px",
                }}
                className="station-cancel"
              >
                
                  <CancelIcon
                    onClick={() => onClose()}
                    onTouchStart={handleTouchClose}
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      right: 7,
                      top: 9.5,
                      fontSize: 18,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                
                {isTeacher && (
                  <div>
                    <span
                      onClick={() => shareStation()}
                      onTouchStart={handleTouchShare}
                      style={{
                        marginLeft: "5px",
                        position: "absolute",
                        right: 40,
                        top: 10.5,
                      }}
                    >
                      {!filterShareStation?.status ? (
                        <ShareIcon style={{ color: "white", fontSize: 16 }} />
                      ) : (
                        <AnimatedCheckMark />
                      )}
                      {/* <AntdButton type="primary" style={{ borderRadius: 10, minWidth: 60, backgroundColor: filterShareStation?.status ? color.greentrue : color.green100, border: "0px" }}>
                        {filterShareStation?.status ? 'Sharing' : 'Share'}
                      </AntdButton> */}
                    </span>
                  </div>
                )}

              <div
              className={`checkListFirstTxt w-full bg-blue-10 h-44px pt-17px pb-14px ${
                showAccordin ? "" : "br-20px"
              } `}
            >
                    <AccordinButton
                      open={showAccordin}
                      onClose={() => setShowAccordin((prev) => !prev)}
                      shareableWidget = {sharableWidget}
                    />
              <span className="text-white text-ft12-700">Station</span>
            </div>
                {showAccordin && (
                  <div onTouchStart={handleTouchStart} >
                    <StudentSectionArea
                      dimensions={dimensions}
                      setNumberOfGroups={setNumberOfStudentsPerStation}
                      createStudentGroup={createStudentGroupStation}
                      studentGroup={stationStudentGroup}
                      resetGroup={() => {
                        onResetGroup();
                      }}
                      stations={stations}
                      onStationChange={setStations}
                      numberOfGroups={numberOfStudentPerStation}
                      isTeacher={isTeacher}
                      sharedData={sharedData}
                      currentClass={currentClass}
                      currentUser={currentUser}
                      setStudentOfCurrentClass={setStudentOfCurrentClass}
                      studentOfCurrentClass={studentOfCurrentClass}
                    />
                  </div>
                )}
              </div>
            </ResizeAndDragabble>
          </div>
        </Container>
      )}
    </>
  );
};

export default WidgetsStation;
