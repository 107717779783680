//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { MenuOutlined } from '@ant-design/icons';
import { MenuImg } from "utils/assets/images"
import { Button, FormControlLabel, IconButton, Switch } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Add, Block, ThumbUp, ThumbUpOutlined } from '@material-ui/icons';
import PrintIcon from '@material-ui/icons/Print';
//  -- Components ---------------------
import { Dropdown, Menu  , Row, Col} from 'antd';
import AssignableNotification from 'components/AssignableNotification';
import AssignedNotification from 'components/AssignedNotification';
import AssignmentNotification from 'components/AssignmentNotification';
import AssignmentPreview from 'components/AssignmentPreview';
import Interaction from 'components/Interaction';
import Loading from 'components/Loading';
import Note from 'components/NoteNew';
import PastAssignment from 'components/PastAssignment';
import Question from 'components/QuestionsByStudent';
//  -- Features -----------------------
import Assignment from 'features/Assignment';
import NoteForm from 'features/forms/Note';
import PageEditor from 'features/forms/PageEditor';
import QuestionForm from 'features/forms/Question';
import _,{ filter, find, groupBy, uniqBy, get , map  , findIndex } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useRef , useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//  -- Thunks -------------------------
import { handleCreateLike, handleDeleteLike } from 'store/likes/thunks';
import { handleCreateBubbleNote } from 'store/notes/thunks';
import { handleCreateQuestion } from 'store/questions/thunks';
import styled from 'styled-components';
import { getBookData, getPageData , modifyAssignmentForStudent , getStoryUrl ,  generateUrlForNonEbook , getBackToUr, updateUrlPathWithActivityOrQuiz } from 'utils/lib/ebookHelper';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import { Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useOrgTimezone } from 'store/organizations/selectors';
import { useServertime } from 'store/servertime/selectors';
import { isBeforTime } from "utils/methods/math"
import AssignmentForm from "features/forms/AssignmentForm"
import { useStory } from "store/story/selectors";
import "moment-timezone"
import LessionPlanPrinter from "components/LessionPlanPrinter"
import { BACK_TO_BOOK } from 'utils/config/reference';
import MenuIcon from '@material-ui/icons/Menu';
import { useMainbookView, useSentenceView } from 'store/main_book_popup/selectors';
import LinkList from 'components/HyperlinkViewer';
import { useShareNotification } from 'utils/methods/firebaseShareAssignment';
import BubbleNotes from 'components/Notes';
import  NoActivity from "utils/assets/images/noActivity.svg";
import { useBookUnits } from 'store/booksUnits/selectors';
import GraphicAssignmentPreview from 'components/GraphicOrganizerAssignmentPreview';
import GraphicOrganizerAssignments from 'components/GraphicOrganizerAssignment';
import { useAllBook } from 'store/books/selectors';

const StyledDropdown = styled(Dropdown)`
.ant-dropdown-menu {
    background-color: #7978c7 !important;
    border: none;
    padding: 0; /* Optional: remove padding for tighter fit */
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  color: white; 
  border-bottom: 1px dotted white; 
  padding: 10px;
  &:hover {
    background-color: inherit !important; /* Keeps the same background color */
  }
`;

const ButtonClear = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;



// Main component with hamburger menu
const DropdownMenu = ({ isStudent, isTeacher, navigate }) => {
    const menu = (
      <Menu style={{ backgroundColor:"#7978c7"}}>
        {isStudent && (
          <StyledMenuItem key="calendar">
            <ButtonClear onClick={() => navigate("calendar")}>
              Calendar
            </ButtonClear>
          </StyledMenuItem>
        )}
        {isStudent && (
          <StyledMenuItem key="studentData">
            <ButtonClear onClick={() => navigate("studentData")}>
              Student Data
            </ButtonClear>
          </StyledMenuItem>
        )}
        {isTeacher && (
          <StyledMenuItem key="manage">
            <ButtonClear onClick={() => navigate("manage")}>
              Assignments
            </ButtonClear>
          </StyledMenuItem>
        )}
        {isTeacher && (
          <StyledMenuItem key="gradebook">
            <ButtonClear onClick={() => navigate("gradebook")}>
              Gradebook
            </ButtonClear>
          </StyledMenuItem>
        )}
        {isTeacher && (
          <StyledMenuItem key="classdata">
            <ButtonClear onClick={() => navigate("classdata")}>
              Class Data
            </ButtonClear>
          </StyledMenuItem>
        )}
      </Menu>
    );
  
    return (
      <StyledDropdown
        overlay={menu}
        trigger={['click']} // Dropdown opens on click
        placement="bottomLeft" // Opens from the bottom-left
      >
        <IconButton aria-label="menu" style={{ height: 30 , width:30 }}>
        <MenuIcon style={{ fontSize: "24px", color: "#ffffff" }} />
        </IconButton>
      </StyledDropdown>
    );
  };
  


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const AssignmentDrawer = styled.div`
    background-color: #fcfcfc;
    height: 100%;
    overflow: scroll;
    position: absolute;
    left: ${p => p.active ? '0px' : 'calc(100% + 32px)'};
    top: 50px;
    transition: left 300ms ease, width 300ms ease;
    width: ${p => p.active ? '100%' : '0px'};
    z-index: 1;
`;

// const ButtonClear = styled.button`
//     background-color: transparent;
//     border: none;
//     color: ${COLOR.white};
//     cursor: pointer;
//     height: 18px;
//     outline: none;
//     padding: 0px;
//     transition: all 300ms ease;

//     &:hover {
//         color: ${COLOR.black};
//     }
// `;

const ButtonLink = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black200};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 2px 0px;
    text-transform: uppercase;
`;

const CreationDrawer = styled(AssignmentDrawer)`
    height: 100%;
    padding: ${p => p.active ? '24px 12px' : '0px'};
`;

const EmptyState = styled.div`
    align-items: center;
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px;
`;

const EmptyStateText = styled.span`
    color: ${COLOR.black500};
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin: 8px 0px 0px;
    text-align: center;
`;

const FormDescription = styled.span`
    color: ${COLOR.black400};
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 24px;
`;

const FormHeader = styled.span`
    color: ${COLOR.black200};
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
`;



const Page = styled.div`
    align-items: center;
    color: ${COLOR.black100};
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    width: 80px;
`;

const Section = styled.div`
    border-bottom: 1px dashed ${COLOR.black700};
    padding: 0px 0px 24px;
    margin-bottom: 16px;
    position: relative;
    &:first-child {
        border-bottom: none;
        margin-bottom: 0px;
        padding: 0px;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0px;
    }
`;
const PartitonDotted = styled.div`
border-bottom: 1px dashed ${COLOR.black};
width:100%;
height:1px;
margin-bottom: 16px;
margin-top: 16px;
`
const SectionHeader = styled.span`
    align-items: center;
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 8px;
    text-transform: uppercase;
    width: 100%;
`;

const Toolbar = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black600};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
`;


const Wrap = styled.div`
  align-items: flex-start;
  border-bottom:${p=>p.empty?'0px':'1px'} dashed ${COLOR.black800};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${p=>p.empty?'0px':'0px 0px'};
  padding-left: 10px;
  padding-top:${p => p.empty ? '0px' : '10px'};
  padding-bottom:${p => p.empty ? '0px' : '0px'};
  flex-wrap: wrap;
  justify-content: start;
`;

const Wrapper = styled.div`
     height: 100%;
  // min-height: 600px;
//   min-height: 904px;
  width: 100%;
  background-color: #efefef;
  position: relative;

     @media (max-width: 1025px){
        min-height:unset;
    }
  
`;
// overflow: hidden;




const Video = styled.video`
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    height: auto;
    outline: none;
    width: 100%;
`;
const TimmerWrapper = styled.div`
color: ${COLOR.black100};
display: inline-block;
font-size: 14px;
font-weight: 600;
line-height: 16px;
position:absolute;
top:5px;
left:45%
`;
const LessionPlan = styled.button`
   background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    // border: none;
    border:1px solid #015ECC;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 27.2px;
    color: white;
    z-index: 1;
    padding: 1px;
    font-size: 12.1px;
    font-weight: 400;
    margin-left:4px;

    @media only screen and (max-width: 1025px){
      margin-top:5px;
    }

      @media (max-width: 992px) {
        // display:none;
      }
}
`;
const LessionPlanInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border:1px solid #fff;
    padding-left: 10px;
    padding-right: 10px;
`;
const AssignmentPlan = styled.button`
    // background-image: linear-gradient(to right,${COLOR.indigo500}, ${COLOR.indigo600});
    background-color: #020A52;
    // border: none;
    border:1px solid #020A52;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 27.2px;
    color: white;
    z-index: 1;
    padding: 1px;
    font-size: 12.1px;
    font-weight: 400;
    margin-left: 4px;

    @media only screen and (max-width:  1025px){
      margin-top:5px;
    }
}
`;
const AssignmentPlanInner = styled.div`
  display: flex;
  // flex-direction: column;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  padding-left: 6px;
  padding-right: 6px;
`;
const LearnPlanWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;
`;
const LessionPlanContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 90%;
    position: relative;
    justify-content: center;
    padding: 20px;
    background-color: white;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;
const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;
const ImageContainer = styled.div`
    display: flex;  
`;
const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    object-fit: cover;
    height:600px;
    @media (max-width: 768px) {
        height:400px;
    }
    @media (min-width: 768px) {
        height:620px;
    }
    @media (min-width: 1600px) {
        height:700px;
    }
    @media print {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
`;
const LessionImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    background-color: transparent;
    overflow: hidden;
    position: relative;
    z-index: 1;
`;
const LessionContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    max-width: 1320px;
    padding: 64px 0px 0px;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    @media (min-width: 1025px) {
        flex-direction: row;
        padding: 0px 30px;
    }
`;
const ButtonContainer = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.black600};
    cursor: pointer;
    height: 40px;
    left: ${p => `${p.left}px`};
    outline: none;
    position: absolute;
    right: ${p => `${p.right}px`};
    top: 50%;
    transition: all 300ms ease;
    width: 40px;
    z-index: 1;
    
`;
const PlannerWrapper = styled.div`
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
`;

const PrintWrapper = styled.div`
`;

const useStylesTips = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    paddingList: {
        paddingBottom: '0px',
        paddingTop: '0px'
    },
    rootListItem: {
        marginTop: '0px',
        marginBottom: '0px',
        textAlign: 'justify'
    },
    guttersListItem: {
        paddingLeft: '3px',
        paddingRight: '1px'
    },
    scroll: {
        height: ' 250px',
        overflowY: 'auto'
    }
}));

const useStyles = makeStyles({
    root: {
        margin: '5px',
        // width: '175px'
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
});

const Accordion = withStyles({
    root: {
        border: '1px solid white',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#03989e',
        color: 'white',
        textTransform: 'capitalize',
        borderBottom: '1px solid white',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiAccordionDetails);

const ParentContainer = styled.div`
max-height: ${({ isStudent }) => (isStudent ? '709px' : '709px')};
overflow: auto;
backgroundColor: "#EFEFEF"
`;

//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook tooling
//
//  -- Description
//  Ebook student tooling
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const BalconCulturalTooling = ({
    classUsers,
    activity,
    activities,
    classActivities,
    classQuizzes,
    currentClass,
    currentOrg,
    currentUser,
    interactions,
    likes,
    limit,
    notes,
    page,
    questions,
    quiz,
    quizzes,
    data,
    ebookFlag,
    setEbookFlag,
    ebookFlagTeacherEdition,
    setEbookFlagTeacherEdition,
    submissions,
    tips,
    existTeacherEdition,
    existShowAnswer,
    showCopyRight,
    showSpecialSupport,
    setShowSpecialSupport,
    studentViewFlag,
    setStudentViewFlag,
    setSubmitRef,
    timeInt,
    timmer,
    onFinish,
    onPageChage = () => null,
    pageNumber,
    booksAllList,
    id,
    CHAPTER_ID,
    lessonPlan,
    rubrics,
    assignmentPlan,
    workbook_plan,
    main_book,
    bookInfo,
    setPage,
    links,
    dataBook,
    pageSrc,
    handleImagePrint,
    printLoader,
    imageConfiguration
}) => {
    const getHiddenBooks = get(currentClass,"hide_books",[])?.map(data => data?.bookId) || [];
    const bookUnitList = useBookUnits()
    const BALCON_PAGES = _.get(_.find(bookUnitList, ["bookId", CHAPTER_ID]), "pages", [])
    const dispatch = useDispatch();
    const history = useHistory();
    const servertime = useServertime()
    const timezone = useOrgTimezone()
    const story = useStory()
    const {listenToSentenceData} = useShareNotification()
    const bookData = getBookData(activities, quizzes, questions, notes, likes, interactions, classActivities, classQuizzes, CHAPTER_ID);
    const pageData = getPageData(bookData, page, quiz, activity)
     // lession plan 
     const hasLessionPlanPage = lessonPlan ? find(lessonPlan, p => p.page == page) : null;
     const lessonPlanBook = hasLessionPlanPage ? find(booksAllList, p => p.id == hasLessionPlanPage.resourceId) : null;
     const lessionPlanPages = get(lessonPlanBook, "pages", [])
     const [lessonPageindex, setLessionPageindex] = useState(0);
     const [lessionPrintLoader, setlessionPrintLoader] = useState(false)

    // rubrics plan 
    const hasRubricsPlanPage = rubrics ? find(rubrics, p => p.page == page) : null;
    const rubricsPlanBook = hasRubricsPlanPage ? find(booksAllList, p => p.id == hasRubricsPlanPage.resourceId) : null;
    const rubricsPlanPages = get(rubricsPlanBook, "pages", [])
    const [rubricsPageindex, setRubricsPageindex] = useState(0);
    const [rubricsPrintLoader, setRubricsPrintLoader] = useState(false)
    const [rubricsPlanModal, setRubricsPlanModal] = useState(false)

     // assignment plan
     const hasAssignmentPlanPage = assignmentPlan ? find(assignmentPlan, p => p.page == page) : null;
     const assignmentPlanBook = hasAssignmentPlanPage ? find(booksAllList, p => p.id == hasAssignmentPlanPage.resourceId) : null;
     const assignmentPlanPages = get(assignmentPlanBook, "pages", [])
     const [assignmentPageindex, setAssignmentPageindex] = useState(0);
     const [assignmentPrintLoader, setAssignmentPrintLoader] = useState(false)

        
    //workbook plan
    const hasWorkbookPlanPage = workbook_plan ? find(workbook_plan, p => p.page == page) : null;
    const WorkbookPlanBook = hasWorkbookPlanPage ? find(booksAllList, p => p.id == hasWorkbookPlanPage.resourceId) : null;
    const WorkbookPlanPages = get(WorkbookPlanBook, "pages", [])
    const [WorkbookPageindex, setWorkbookPageindex] = useState(0);
    const [workbookPlanModal, setWorkbookPlanModal] = useState(false)

   //main book 
   const hasMain_bookPage = main_book ? find(main_book, p => p.page == page) : null;
   const mainBook = hasMain_bookPage ? find(booksAllList, p => p.id == hasMain_bookPage.resourceId) : null;
   const mainBookPages = get(mainBook, "pages", [])
   const { invokeMainbookView } = useMainbookView()
   const { invokeSentenceView } = useSentenceView();

    const isStudent = currentUser && currentUser.role === 'student'
    const isTeacher = currentUser && currentUser?.role === 'teacher'
    const [openGraphicAssignmentPrivew,setOpenGraphicAssignmentPrivew] = useState(false)
    const [graphicAssignment,setGraphicAssignment] = useState({})
    // Sentence Starter
    const [sentenceData, setSentenceData] = useState({})
    
    const [learnPlanModal, setLearnPlanModal] = useState(false)
    const [assignmentPlanModal, setAssignmentPlanModal] = useState(false)
    const [preview, setPreview] = useState(false)
    const [assignmentData, setAssignmentData] = useState({})
    const [creationType, setCreationType] = useState(null);
    const [likeLoading, setLikeLoading] = useState(false);
    const pageInteractions = pageData.pageInteractions;
    const videoSupport = pageData.videoSupport;
    const pageLike = pageData.pageLike;
    const pageNotes = pageData.pageNotes;
    const pageQuestions = pageData.pageQuestions;
    const availableActivity = modifyAssignmentForStudent(pageData.availableActivity, isStudent, false, currentUser, servertime, timezone , currentClass,getHiddenBooks);
    const assignableActivity = pageData.assignableActivity;
    const activeActivity = pageData.activeActivity;
    const availableQuiz = modifyAssignmentForStudent(pageData.availableQuiz, isStudent, true, currentUser, servertime, timezone , currentClass,getHiddenBooks);
    const assignableQuiz = pageData.assignableQuiz;
    const activeQuiz = pageData.activeQuiz;
    const backBtnRef = useRef(null)
    const forwardBtnRef = useRef(null)
    const orgPermissions = useCurrentOrgPermission()
    const hasPrintOption = useMemo(()=>get(orgPermissions,"printing",true),[orgPermissions])
    const hasteacherEdition =useMemo(()=>get(orgPermissions,"teacherEdition",true),[orgPermissions])
    const [alertSakeBar,setAlertSakeBar] = useState({open:false,message:''})
    const [customAssignmentDrawer, setCustomAssignmentDrawer] = useState(false);
    const isDemo = currentUser?.demo || false;
    const isReviewAccount = currentUser?.reviewAccount || false;
    const bookAllList = useAllBook()
    const isBookPrintable = useMemo(
        () => get(find(bookAllList, ["id", CHAPTER_ID]), "printable"),
        [bookAllList]
    );
    const generateUrl = (page, activity, quiz) => {
        const storyUrl = getStoryUrl(story, CHAPTER_ID, page, quiz, activity)
        const backToLabel = BACK_TO_BOOK[CHAPTER_ID]
        const url = generateUrlForNonEbook(id,page,activity,quiz,backToLabel,null,CHAPTER_ID,false)
        if (url && backToLabel) {
            return url;
        }
        else if (storyUrl) {
            return storyUrl;
        } else{
            updateUrlPathWithActivityOrQuiz(window.location.href, activity, quiz)
        }
       
    }

    useEffect(() => {
        const unsubscribe = listenToSentenceData("admin_sentence", (data) => {
            setSentenceData(data?.admin_sentence);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        onPageChage(onPageSwipe)
    }, [onPageChage])

    const classes = useStyles();
    const classesTips = useStylesTips();

    const [studentsName, setStudentsName] = React.useState([]);
    const [studentsError, setStudentsError] = React.useState(false);
    const [diffSection, setDiffSection] = useState(false);

    let infoClassUSers = filter(classUsers, x => x.role === 'student');
    let listStudents = [];
    for (var u in infoClassUSers) {
        listStudents.push({
            id: infoClassUSers[u].id,
            description: `${infoClassUSers[u].lastName}  ${infoClassUSers[u].firstName} (${infoClassUSers[u].username})`
        })
    }
  
    const getSrcPage = () => {
        if (lessionPlanPages) {
            const page = lessionPlanPages[lessonPageindex];
            return page ? page.resource : null
        }
    }
    const getSrcAssignmentPage = () => {
        if (assignmentPlanPages) {
            const page = assignmentPlanPages[assignmentPageindex];
            return page ? page.resource : null
        }
    }
    const getSrcWorkbookPage = () => {
        if (WorkbookPageindex>=0&&WorkbookPlanPages&&WorkbookPlanPages.length>0) {
            const page = WorkbookPlanPages[WorkbookPageindex];
            return page ? page.resource : null
        }
    }
    const getSrcRubricsPage = () => {
        if (rubricsPlanPages) {
            const page = rubricsPlanPages[rubricsPageindex];
            return page ? page.resource : null
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", () => { })
        }
    }, [])
    function checkKey(e) {
        let isLessonPlan = document.getElementsByClassName("lessonPlanPopUp").length > 0;
        let isRubricsPlan = document.getElementsByClassName("rubricsPopUp").length > 0;
        if (isLessonPlan || isRubricsPlan) {
            e = e || window.event;
            if (e.keyCode == '37') {
                if (backBtnRef.current && !backBtnRef.current.disabled) {
                    backBtnRef.current.click()
                }

            }
            else if (e.keyCode == '39') {
                if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                    forwardBtnRef.current.click()
                }

            }
        }
    }
    const imageRef = useRef();
    
    const handlePrintLessons = () => {
        if (!hasPrintOption) {
            setAlertSakeBar({ open: true, message: 'Restricted access (legend)' })
            return;
        }
        handleLessionImagePrint()
    }
    const handleLessionImagePrint = useReactToPrint({
        content: () => imageRef.current,
        onBeforeGetContent: () => {
            setlessionPrintLoader(true)
            setAssignmentPrintLoader(true)
            setRubricsPrintLoader(true)
        },
        onBeforePrint: () => {
            setlessionPrintLoader(false)
            setAssignmentPrintLoader(false)
            setRubricsPrintLoader(false)
        },
        onAfterPrint: () => {
            setlessionPrintLoader(false)
            setAssignmentPrintLoader(false)
            setRubricsPrintLoader(false)
        }
    });
  
    const tipsTalented =  filter(tips, x => x.texType === 'talented');
    const tipsSpecial = filter(tips, x => x.texType === 'special');

    let existSpecialSupport = false;
    if (videoSupport.length > 0 || tipsTalented.length > 0 || tipsSpecial.length > 0) {
        existSpecialSupport = true;
    }

    let existStudentView = false;
    if (availableActivity.length || availableQuiz.length > 0) {
        existStudentView = true;
    }


    const createNote = (data, color) => {
        dispatch(handleCreateBubbleNote({
            content: data,
            color: color,
            chapter: CHAPTER_ID,
            page,
            userId: currentUser?.id,
            classId: currentClass.id,
            orgId: currentOrg?.id
        }));
        setCreationType(null);
    };

    const createQuestion = (data) => {
        dispatch(handleCreateQuestion({
            content: data,
            chapter: CHAPTER_ID,
            page,
            userId: currentUser?.id,
            classId: currentClass.id,
            orgId: currentOrg?.id
        }));
        setCreationType(null);
    };

    const navigateToStudentDashboard = (to) => {
        switch (to) {
            case "calendar":
                history.push("/student/class?view=assessments")
                break;
            case "studentData":
                history.push("/student/class?view=studentData")
                break;
            default:
                history.push("/student/class?view=home")
                break;
        }
    }
    const navigateToTeacherDashboard = (to) => {
        switch (to) {
            case "manage":
                history.push("/admin/class?view=manage")
                break;
            case "gradebook":
                history.push("/admin/class?view=gradebook")
                break;
            case "classdata":
                history.push("/admin/class?view=classdata")
                break;
            default:
                history.push("/admin/class?view=home")
                break;
        }
    }

    const toolbarNav = () => {
        return (
            <Menu style={{ backgroundColor: "rgb(121 120 199)" }}>
                {currentUser?.role === 'student' && (<Menu.Item style={{ borderBottom: "1px dotted white" }}>
                    <ButtonClear onClick={() => navigateToStudentDashboard("calendar")}>
                        calendar
                    </ButtonClear>
                </Menu.Item>)}
                {currentUser?.role === 'student' && (<Menu.Item>
                    <ButtonClear onClick={() => navigateToStudentDashboard("studentData")}>
                        Student Data
                    </ButtonClear>
                </Menu.Item>)}
                {currentUser?.role === 'teacher' && (<Menu.Item>
                    <ButtonClear onClick={() => navigateToTeacherDashboard("manage")}>
                        Assignments
                    </ButtonClear>
                </Menu.Item>)}
                {currentUser?.role === 'teacher' && (<Menu.Item>
                    <ButtonClear onClick={() => navigateToTeacherDashboard("gradebook")}>
                        Gradebook
                    </ButtonClear>
                </Menu.Item>)}
                {currentUser?.role === 'teacher' && (<Menu.Item>
                    <ButtonClear onClick={() => navigateToTeacherDashboard("classdata")}>
                        Class Data
                    </ButtonClear>
                </Menu.Item>)}
            </Menu>
        )
    };

    const closeAssignment = () => {
        history.push(generateUrl(page, null, null))
    };

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const setFlagsShowAnswer = (flag) => {
        setEbookFlagTeacherEdition(false);
        setEbookFlag(flag);
        setStudentViewFlag(false);
    }

    const setFlagsTeacherEdition = (flag) => {
        setEbookFlagTeacherEdition(flag);
        setEbookFlag(false);
        setStudentViewFlag(false);
    }



    const handleChangeMultiSelect = (event) => {
        setStudentsError(false);
        setStudentsName(event.target.value);
    };
    const isPastDue = (_quiz) => {
        if (_quiz?.dueDate && _quiz.lateDate) {
            return isBeforTime(_quiz.lateDate,servertime,timezone)
        } else if (_quiz?.dueDate) {
            return isBeforTime(_quiz.dueDate,servertime,timezone)
        } else {
            return false
        }
    }

    const assignmentPreivew = (assignment) => {
        try {
            const assignmentData = assignment.data ? assignment.data : assignment
            if(assignmentData.activity_type === "graphic_organizer"){
                setOpenGraphicAssignmentPrivew(true)
                setGraphicAssignment(assignmentData)
            }else{
                setAssignmentData(assignment.data ? assignment.data : assignment)
                setPreview(true)
            }
        } catch (error) {
        }
    }
    const onPageSwipe = () => {
        setPreview(false)
        setAssignmentData({})
    }
    const hasActiveAssignmentDrawer = () => {
        return (typeof quiz == "string" && quiz.length > 2 && quiz !== 'null') || (typeof activity == "string" && activity.length > 2 && activity !== 'null')
    }
    const onChangeNumber = (newNumber) => {
        const start = parseInt(get(bookInfo, 'start',0), 10)
        const finish = parseInt(get(bookInfo, 'finish',0), 10)
        const expPageId = parseInt(newNumber, 10)+start-1;
        if(expPageId<=finish&&!isNaN(newNumber)&&expPageId>0&&newNumber>0){
            history.push(generateUrl(expPageId,null,null))
            setPage(parseInt(expPageId, 10))
        }else{
            setPage(parseInt(page, 10))
            history.push(generateUrl(page,null,null))
        }
    }
    async function onAssignmentClick(act) {
        const uri =  generateUrl(page, act.data?.id, null);
        if (uri) {
            history.push(uri)
        }
    }
    const onBookClick = () =>{
        const mainBookFromIndex = findIndex(mainBookPages, p => p.pageId == get(hasMain_bookPage, "from", 0))
        const mainBookTo = findIndex(mainBookPages, p => p.pageId == get(hasMain_bookPage, "to", 0))
        if(mainBookFromIndex>=0 && mainBookTo>=0){
            const main_book_pages =  Array.isArray(mainBookPages) ? mainBookPages.slice(mainBookFromIndex,mainBookTo):[]
            invokeMainbookView(main_book_pages)
        }
       

    }
    const lessionPlanFromIndex = findIndex(lessionPlanPages,p=>p.pageId==get(hasLessionPlanPage,"from",0))
    const lessionPlanTo =  findIndex(lessionPlanPages,p=>p.pageId==get(hasLessionPlanPage,"to",0))

    const assignmentPlanFromIndex = findIndex(assignmentPlanPages,p=>p.pageId==get(hasAssignmentPlanPage,"from",0))
    const assignmentPlanTo =  findIndex(assignmentPlanPages,p=>p.pageId==get(hasAssignmentPlanPage,"to",0))

    const rubricsPlanFromIndex = findIndex(rubricsPlanPages, p => p.pageId == get(hasRubricsPlanPage, "from", 0))
    const rubricsPlanTo = findIndex(rubricsPlanPages, p => p.pageId == get(hasRubricsPlanPage, "to", 0))

    const workbookPlanFromIndex = findIndex(WorkbookPlanPages, p => p.pageId == get(hasWorkbookPlanPage, "from", 0))
    const workbookPlanTo = findIndex(WorkbookPlanPages, p => p.pageId == get(hasWorkbookPlanPage, "to", 0))

    const newMaskId = get(
        find(get(data, "content"), (k) => k.pageId == page),
        "numberMasked",
        page
      );

 let isEmpty;
 if (!isStudent) {
   isEmpty =
     pageInteractions?.length === 0 &&
     videoSupport.length === 0 &&
     tipsTalented.length === 0 &&
     tipsSpecial.length === 0 &&
     availableActivity?.length === 0 &&
     assignableActivity?.length === 0 &&
     availableQuiz?.length === 0 &&
     assignableQuiz.length === 0;
 } else {
   isEmpty =
     pageInteractions?.length === 0 &&
     videoSupport.length === 0 &&
     tipsTalented.length === 0 &&
     tipsSpecial.length === 0 &&
     availableQuiz?.length === 0 &&
     availableActivity?.length === 0 
 }

   const EmptySection = () => {
    return (
        <>
        {isEmpty && (
            <div className='empty-section-container allEmptySection'>
            <img src={NoActivity} alt='' />
            <div className='empty-text-label'>
            No activities to show on this page
            </div>
        </div>
        )}
        </>
        
    )
   }
  
    return (
        <>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertSakeBar.open}
                autoHideDuration={3000}
                onClose={()=>{ setAlertSakeBar({open:false,message:''})}}>
                <Alert onClose={()=>{ setAlertSakeBar({open:false,message:''})}} severity="error">
                    <strong>ALERT:</strong> {alertSakeBar.message}
                </Alert>
            </Snackbar>
            <Modal
                open={learnPlanModal}
                onClose={() => setLearnPlanModal(false)}
                className={"lessonPlanPopUp"}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            >
                <LearnPlanWrapper>
                    <LessionPlanContainer>
                        <CloseBtn onClick={() => { setLearnPlanModal(false); setLessionPageindex(0) }}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }} />
                        </CloseBtn>
                        <div style={{ position: "absolute", right: "40px", top: "0px", zIndex: "1" }}>
                            <LessionPlanPrinter
                                onPrintSingle={handlePrintLessons}
                                printLoader={lessionPrintLoader} images={_.slice(lessionPlanPages, lessionPlanFromIndex > 0 ? lessionPlanFromIndex : 0, lessionPlanTo).map(k => k.resource).filter(k => k)} />
                        </div>
                        {/* <IconButton onClick={handlePrintLessons} aria-label="print"
                            style={{ position: "absolute", right: "40px", top: "0px", zIndex: "1" }}
                            disabled={lessionPrintLoader}>
                            {lessionPrintLoader ? <Loading size={20} /> : <PrintIcon />}
                        </IconButton> */}
                        {lessonPageindex > lessionPlanFromIndex && <ButtonContainer
                            ref={backBtnRef}
                            disabled={lessonPageindex <= lessionPlanFromIndex}
                            onClick={() => {
                                setLessionPageindex(lessonPageindex - 1)
                            }}
                            left={0}
                        >
                            <ArrowBackIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        {lessonPageindex < lessionPlanTo && <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={lessonPageindex >= lessionPlanTo}
                            onClick={() => {
                                setLessionPageindex(lessonPageindex + 1)
                            }}
                            right={0}
                        >
                            <ArrowForwardIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        <LessionContainer>
                            <PrintWrapper ref={imageRef}>
                                <Images src={getSrcPage()} />
                            </PrintWrapper>
                        </LessionContainer>
                    </LessionPlanContainer>
                </LearnPlanWrapper>
            </Modal>
            <Modal
                open={assignmentPlanModal}
                onClose={() => setAssignmentPlanModal(true)}
                className={"lessonPlanPopUp"}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            >
                <LearnPlanWrapper>
                    <LessionPlanContainer>
                        <CloseBtn onClick={() => { setAssignmentPlanModal(false); setAssignmentPageindex(0) }}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }} />
                        </CloseBtn>
                        <IconButton onClick={handlePrintLessons} aria-label="print"
                            style={{ position: "absolute", right: "40px", top: "0px", zIndex: "1" }}
                            disabled={assignmentPrintLoader}>
                            {assignmentPrintLoader ? <Loading size={20} /> : <PrintIcon />}
                        </IconButton>
                        {assignmentPageindex > assignmentPlanFromIndex && <ButtonContainer
                            ref={backBtnRef}
                            disabled={assignmentPageindex <= assignmentPlanFromIndex}
                            onClick={() => {
                                setAssignmentPageindex(assignmentPageindex - 1)
                            }}
                            left={0}
                        >
                            <ArrowBackIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        {assignmentPageindex < assignmentPlanTo && <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={assignmentPageindex >= assignmentPlanTo}
                            onClick={() => {
                                setAssignmentPageindex(assignmentPageindex + 1)
                            }}
                            right={0}
                        >
                            <ArrowForwardIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        <LessionContainer>
                            <PrintWrapper ref={imageRef}>
                                <Images src={getSrcAssignmentPage()} />
                            </PrintWrapper>
                        </LessionContainer>
                    </LessionPlanContainer>
                </LearnPlanWrapper>
            </Modal>
              {/* rubrics plan modal */}

              <Modal
                open={rubricsPlanModal}
                onClose={() => setRubricsPlanModal(true)}
                className={"rubricsPopUp"}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            >
                <LearnPlanWrapper>
                    <LessionPlanContainer>
                        <CloseBtn onClick={() => { setRubricsPlanModal(false); setRubricsPageindex(0) }}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }} />
                        </CloseBtn>
                       
                        <IconButton onClick={handlePrintLessons} aria-label="print"
                            style={{ position: "absolute", right: "40px", top: "0px", zIndex: "1" }}
                            disabled={rubricsPrintLoader}>
                            {rubricsPrintLoader ? <Loading size={20} /> : <PrintIcon />}
                        </IconButton>
                        {rubricsPageindex > rubricsPlanFromIndex && <ButtonContainer
                            ref={backBtnRef}
                            disabled={rubricsPageindex <= rubricsPlanFromIndex}
                            onClick={() => {
                                setRubricsPageindex(rubricsPageindex - 1)
                            }}
                            left={0}
                        >
                            <ArrowBackIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        {rubricsPageindex < rubricsPlanTo && <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={rubricsPageindex >= rubricsPlanTo}
                            onClick={() => {
                                setRubricsPageindex(rubricsPageindex + 1)
                            }}
                            right={0}
                        >
                            <ArrowForwardIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        <LessionContainer>
                            <PrintWrapper ref={imageRef}>
                                <Images src={getSrcRubricsPage()} />
                            </PrintWrapper>
                        </LessionContainer>
                    </LessionPlanContainer>
                </LearnPlanWrapper>
            </Modal>
            {/* end of rubrics plan */}
            {/* Workbook plan modal start */}
            <Modal
                open={workbookPlanModal}
                onClose={() => setWorkbookPlanModal(true)}
                className={"lessonPlanPopUp"}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
            >
                <LearnPlanWrapper>
                    <LessionPlanContainer>
                        <CloseBtn onClick={() => { setWorkbookPlanModal(false); setWorkbookPageindex(0) }}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }} />
                        </CloseBtn>

                        {/* <IconButton onClick={handlePrintLessons} aria-label="print"
                            style={{ position: "absolute", right: "40px", top: "0px", zIndex: "1" }}
                            disabled={assignmentPrintLoader}>
                            {assignmentPrintLoader ? <Loading size={20} /> : <PrintIcon />}
                        </IconButton> */}
                        {WorkbookPageindex > workbookPlanFromIndex && <ButtonContainer
                            ref={backBtnRef}
                            disabled={WorkbookPageindex <= workbookPlanFromIndex}
                            onClick={() => {
                                setWorkbookPageindex(WorkbookPageindex - 1)
                            }}
                            left={0}
                        >
                            <ArrowBackIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        {WorkbookPageindex < workbookPlanTo && <ButtonContainer
                            ref={forwardBtnRef}
                            disabled={WorkbookPageindex >= workbookPlanTo}
                            onClick={() => {
                                setWorkbookPageindex(WorkbookPageindex + 1)
                            }}
                            right={0}
                        >
                            <ArrowForwardIos style={{ fontSize: '40px' }} />
                        </ButtonContainer>}
                        <LessionContainer>
                            <PrintWrapper ref={imageRef}>
                                <Images src={getSrcWorkbookPage()} />
                            </PrintWrapper>
                        </LessionContainer>
                    </LessionPlanContainer>
                </LearnPlanWrapper>
            </Modal>

            {/* Workbook plan modal end */}
            <AssignmentForm open={customAssignmentDrawer} onClose={() => setCustomAssignmentDrawer(false)} bookId={CHAPTER_ID} pageId={page} />
           
            <Row span={17}  className="BalconMobleView" style={{ display:"flex" , justifyContent:"flex-end" , alignItems:"center", marginBottom: "9px", }}>
                            <TimmerWrapper>
                                {timeInt !== 0 && currentUser?.role == 'student' ? timeInt : ""}
                            </TimmerWrapper>
                            <PlannerWrapper>
                                        {!preview && isTeacher ? (
                                    <AssignmentPlan  onClick={() => setCustomAssignmentDrawer(true)}  className='ebooks-button'>
                                        {/* #TODO Cna be enable after testing on prod. */}
                                        <AssignmentPlanInner>
                                        <Add style={{ fontSize: '13px', fontWeight:700 }} />
                                        Assignment
                                        </AssignmentPlanInner>
                                      
                                    </AssignmentPlan>
                                ) : null}
                                {hasLessionPlanPage && currentUser?.role !== "student" ? (<LessionPlan onClick={() => {
                                    if (get(orgPermissions, "lessonPlan", true)) {
                                        setLearnPlanModal(true)
                                        setLessionPageindex(lessionPlanFromIndex)
                                    } else {
                                        setAlertSakeBar({ open: true, message: 'Restricted access (legend)' })
                                    }
                                }}>
                                    <LessionPlanInner>Lesson Plan</LessionPlanInner>
                                </LessionPlan>) : null}
                                {/* {sentenceData?.id && (<AssignmentPlan onClick={() => {
                                    invokeSentenceView(sentenceData);
                                }}>
                                    <AssignmentPlanInner>Sentence starters</AssignmentPlanInner>
                                </AssignmentPlan>)} */}
                                {hasAssignmentPlanPage && currentUser?.role !== "student" ? (<AssignmentPlan onClick={() => {
                                    setAssignmentPlanModal(true)
                                    setAssignmentPageindex(assignmentPlanFromIndex)
                                }}>
                                    <AssignmentPlanInner>Quiz</AssignmentPlanInner>
                                </AssignmentPlan>) : null}
                                {hasRubricsPlanPage && currentUser?.role !== "student" ? (<AssignmentPlan onClick={() => {
                                    setRubricsPlanModal(true)
                                    setRubricsPageindex(rubricsPlanFromIndex)
                                }}>
                                    <AssignmentPlanInner>Rubrics</AssignmentPlanInner>
                                </AssignmentPlan>) : null}
                                {hasWorkbookPlanPage && currentUser?.role !== "student" ? (<AssignmentPlan onClick={() => {
                                    setWorkbookPlanModal(true)
                                    setWorkbookPageindex(workbookPlanFromIndex)
                                }}>
                                    <AssignmentPlanInner>Workbook</AssignmentPlanInner>
                                </AssignmentPlan>) : null}
                                {hasMain_bookPage  ? (<AssignmentPlan onClick={onBookClick}>
                                    <AssignmentPlanInner>Book</AssignmentPlanInner>
                                </AssignmentPlan>) : null}
                            </PlannerWrapper>


                        </Row>
            <Wrapper  className="ebokToolUpr">
     
            <Toolbar>
                    <Row style={{   paddingTop: "9px",
              paddingBottom: "11px",
              paddingLeft: "23px",
              paddingRight: "24px",
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "#012B70",}}>
                        <Col span={5}  style={{ display:"flex" , justifyContent:"flex-start" , alignItems:"center" }} >
                            <Page style={{ color: "#ffffff" }}>
                                {!isDemo &&
                                    !isReviewAccount &&
                                    hasPrintOption &&
                                    isBookPrintable && (
                                        <IconButton
                                            onClick={handleImagePrint}
                                            style={{ padding: 0, color: "white" }}
                                            aria-label="print"
                                            disabled={printLoader}
                                        >
                                            {printLoader ? <Loading size={20} /> : <PrintIcon />}
                                        </IconButton>
                                    )}
                                P.
                                <PageEditor
                                    activity={activity}
                                    chapter={CHAPTER_ID}
                                    limit={limit}
                                    page={page}
                                    quiz={quiz}
                                    value={pageNumber}
                                    numberMasked={pageNumber}
                                    onChangeNumber={onChangeNumber}
                                />
                            </Page>

                        </Col>
                                            
                        <Col span={2}  style={{ display:"flex" , justifyContent:"flex-end" , alignItems:"center" }}>
                        <BubbleNotes
                        pageName={"balcon"}
                  pageSrc={pageSrc}
                  data={data}
                  page={page}
                  newMaskNote={pageNumber}
                  currentUser={currentUser}
                  pageNotes={pageNotes}
                  chapter={CHAPTER_ID}
                  dataBook={dataBook}
                  BALCON_PAGES={BALCON_PAGES}
                />
                            <DropdownMenu isStudent={isStudent} isTeacher={isTeacher} navigate={isTeacher ? navigateToTeacherDashboard : navigateToStudentDashboard} />

                        </Col>
                    </Row>
                </Toolbar>
             
                {!isStudent && (
                    <Wrap className="ebokToolWrap" empty={!(existTeacherEdition && hasteacherEdition) && !existSpecialSupport && !(existShowAnswer || existStudentView) && !links} >
                        <div className='newEbokToolWrap'>
                            {existTeacherEdition && hasteacherEdition && (
                                <FormControlLabel
                                    classes={{
                                        root: classes.root,
                                        label: classes.label,
                                    }}
                                    control={
                                        <Switch
                                            classes={{
                                                sizeSmall: classes.sizeSmall,
                                                checked: classes.checkedSwitch
                                            }}
                                            checked={ebookFlagTeacherEdition}
                                            onChange={e => setFlagsTeacherEdition(e.target.checked)}
                                            name="viewTeacherEdition"
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label="Teacher Edition"
                                />
                            )}
                            {existSpecialSupport && (
                                <FormControlLabel
                                    classes={{
                                        root: classes.root,
                                        label: classes.label,
                                    }}
                                    control={
                                        <Switch
                                            classes={{
                                                sizeSmall: classes.sizeSmall,
                                                checked: classes.checkedSwitch
                                            }}
                                            checked={showSpecialSupport}
                                            onChange={e => setShowSpecialSupport(e.target.checked)}
                                            name="viewSpecialSupport"
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label="Additional support"
                                />
                            )}
                            {(existShowAnswer || existStudentView) && (
                                <>

                                    {existShowAnswer && (
                                        <FormControlLabel
                                            classes={{
                                                root: classes.root,
                                                label: classes.label,
                                            }}
                                            control={
                                                <Switch
                                                    classes={{
                                                        sizeSmall: classes.sizeSmall,
                                                        checked: classes.checkedSwitch
                                                    }}
                                                    checked={ebookFlag}
                                                    onChange={e => setFlagsShowAnswer(e.target.checked)}
                                                    name="viewFlag"
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label="Show Answers"
                                        />
                                    )}


                                </>
                            )}
                            <LinkList links={links} />
                        </div>
                    </Wrap>
                )}
                {/* end toogle buttons */}
                {!isStudent  && <GraphicOrganizerAssignments page={page} chapter={CHAPTER_ID} />}
                {!isStudent && <GraphicAssignmentPreview open={openGraphicAssignmentPrivew} setOpen={setOpenGraphicAssignmentPrivew} assignment={graphicAssignment} page_id={page}  bookId={CHAPTER_ID} />}
                <EmptySection />
                <ParentContainer style={{ marginTop: "10px", paddingInline: "23px"}} isStudent={isStudent}>
                {pageInteractions?.length > 0 && (
                        <Section className="playAudio balconPlayAudio">
                            {pageInteractions?.map(action => (
                                <Interaction key={action.id} data={action} page="balcon" />
                            ))}
                        </Section>
                    )}
                <div >
                    {showSpecialSupport && (
                        <>
                            {(videoSupport.length > 0 || tipsTalented.length > 0 || tipsSpecial.length > 0) && (
                                <>
                                    <br></br>
                                    {videoSupport.length > 0 && (
                                        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography>Video Support</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Section className="playAudio">
                                                    <Video onContextMenu={(e) => {
                                                        e.preventDefault();
                                                        showCopyRight()
                                                    }} controls controlsList="nodownload" width="100%">
                                                        <source src={videoSupport[0].content} type="video/mp4" />
                                                    </Video>
                                                </Section>
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                    {tipsTalented.length > 0 && (
                                        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                                <Typography>Gifted & Talented</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                classes={{
                                                    root: classesTips.scroll
                                                }}>
                                                <List component="nav" className={classesTips.root} aria-label="contacts"
                                                    classes={{
                                                        padding: classesTips.paddingList
                                                    }}>
                                                    {tipsTalented.map((item, key) => {
                                                        return (
                                                            <div>
                                                                <ListItem key={key} button
                                                                    classes={{
                                                                        gutters: classesTips.guttersListItem
                                                                    }}>
                                                                    <ListItemText
                                                                        disableTypography
                                                                        style={{ color: item.color }}
                                                                        primary={item.textTip}
                                                                        classes={{
                                                                            root: classesTips.rootListItem
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </div>
                                                        );
                                                    })}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                    {tipsSpecial.length > 0 && (
                                        <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                <Typography>Special Needs</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                classes={{
                                                    root: classesTips.scroll
                                                }}>
                                                <List component="nav" className={classesTips.root} aria-label="contacts"
                                                    classes={{
                                                        padding: classesTips.paddingList
                                                    }}>
                                                    {tipsSpecial.map((item, key) => {
                                                        return (
                                                            <div>
                                                                <ListItem key={key} button
                                                                    classes={{
                                                                        gutters: classesTips.guttersListItem
                                                                    }}>
                                                                    <ListItemText
                                                                        disableTypography
                                                                        style={{ color: item.color }}
                                                                        primary={item.textTip}
                                                                        classes={{
                                                                            root: classesTips.rootListItem
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </div>
                                                        );
                                                    })}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                </>
                            )}
                        </>
                    )}
                     <Section className="">
                        {isStudent ? <>
                            {availableActivity.length > 0 ? map(availableActivity, (act, i) => {
                                const isPast = isPastDue(act)
                                if (!isPast) return (<>
                                    {i == 0 && <SectionHeader>
                                        Activities
                                    </SectionHeader>}
                                    <AssignmentNotification
                                        assignment={act}
                                        onClick={() =>  onAssignmentClick(act)}
                                        submissions={submissions}
                                        userRol={currentUser?.role}
                                        currentUser={currentUser}
                                        currentClass={currentClass}
                                    /></>)
                                return (<>
                                    {i == 0 && <SectionHeader>
                                        Activities
                                    </SectionHeader>}
                                    <PastAssignment
                                        assignment={act}
                                        submissions={submissions}
                                    /></>)
                            }) : null}
                            {availableQuiz.length > 0 ? map(availableQuiz, (act, i) => {
                                const isPast = isPastDue(act)
                                if (!isPast) return (<>
                                    {i == 0 && <SectionHeader>
                                        Activities
                                    </SectionHeader>}
                                    <AssignmentNotification
                                        assignment={act}
                                        onClick={() => history.push(generateUrl(page, null, act.data?.id))}
                                        submissions={submissions}
                                        userRol={currentUser?.role}
                                        currentUser={currentUser}
                                        currentClass={currentClass}
                                    /></>)
                                return (<>
                                    {i == 0 && <SectionHeader>
                                        Activities
                                    </SectionHeader>}
                                    <PastAssignment
                                        assignment={act}
                                        submissions={submissions}
                                    /></>)
                            }) : null}
                        </> : null}
                        {!isStudent ? <>
                            {availableActivity.length > 0 && !preview ? (
                                map(availableActivity, act => (
                                    <AssignedNotification
                                        assignment={act}
                                        listStudents={listStudents}
                                        handleChangeMultiSelect={handleChangeMultiSelect}
                                        diffSection={diffSection}
                                        setDiffSection={setDiffSection}
                                        studentsName={studentsName}
                                        studentsError={studentsError}
                                        setStudentsName={setStudentsName}
                                        currentClass={currentClass}
                                        onPreviewCick={() => { assignmentPreivew(act) }}
                                        booksAllList={booksAllList}
                                        viewType={"balcon"}
                                    />
                                ))

                            ) : null}
                            {assignableActivity.length > 0 && !preview ? (
                                map(assignableActivity, aAct => (
                                    <AssignableNotification
                                        assignment={aAct}
                                        listStudents={listStudents}
                                        handleChangeMultiSelect={handleChangeMultiSelect}
                                        diffSection={diffSection}
                                        setDiffSection={setDiffSection}
                                        studentsName={studentsName}
                                        studentsError={studentsError}
                                        onPreviewCick={() => { assignmentPreivew(aAct) }}
                                    />
                                ))) : null}

                            {availableQuiz.length && !preview ? (
                                map(availableQuiz, aq => (
                                    <AssignedNotification
                                        assignment={aq}
                                        listStudents={listStudents}
                                        handleChangeMultiSelect={handleChangeMultiSelect}
                                        diffSection={diffSection}
                                        setDiffSection={setDiffSection}
                                        studentsName={studentsName}
                                        studentsError={studentsError}
                                        setStudentsName={setStudentsName}
                                        currentClass={currentClass}
                                        onPreviewCick={() => { assignmentPreivew(aq) }}
                                    />
                                ))) : null}
                            {assignableQuiz.length > 0 && !preview ? (
                                map(assignableQuiz, aq => (<AssignableNotification
                                    assignment={aq}
                                    listStudents={listStudents}
                                    handleChangeMultiSelect={handleChangeMultiSelect}
                                    diffSection={diffSection}
                                    setDiffSection={setDiffSection}
                                    studentsName={studentsName}
                                    studentsError={studentsError}
                                    onPreviewCick={() => { assignmentPreivew(aq) }}
                                />))
                            ) : null}
                        </> : null}
                        {preview ? <AssignmentPreview data={assignmentData} onClose={onPageSwipe} /> : null}
                    </Section>

                     
                    {/* SECCIÓN DE NOTAS */}
                    
                    {/* {!preview ? (
                        <Section style={{
                            height: '400px', overflowY: 'auto', backgroundColor: pageNotes?.length > 0 ? "rgb(163 163 163 / 12%)" : "transparent",
                            position: "relative",
                            marginBottom: 0, borderBottomWidth: 0, padding: 5
                        }}>
                            <SectionHeader>
                                Notes
                                <Button
                                    color="primary"
                                    onClick={() => setCreationType('note')}
                                    style={{ fontSize: '12px' }}
                                >
                                    <Add style={{ marginRight: '4px', fontSize: '13px' }} />
                                    Add Note
                                </Button>
                            </SectionHeader>
                            {pageNotes.length > 0 ? (
                                pageNotes.map(x => <Note key={x.id} note={x} />)
                            ) : (
                                <EmptyState>
                                    <Block style={{ color: COLOR.black600, fontSize: '40px' }} />
                                    <EmptyStateText>You haven't created any notes on this page.</EmptyStateText>
                                </EmptyState>
                            )}
                        </Section>
                    ) : null} */}
                    {/* <PartitonDotted /> */}

                    {/* {isStudent ? (
                        <Section style={{ backgroundColor: pageQuestions?.length > 0 ? "rgb(163 163 163 / 12%)" : "transparent", padding: 5, position: "relative" }}>
                            <SectionHeader>
                                Questions
                                <Button
                                    color="primary"
                                    onClick={() => setCreationType('question')}
                                    style={{ fontSize: '12px' }}
                                >
                                    <Add style={{ marginRight: '4px', fontSize: '13px' }} />
                                    Add Question
                                </Button>
                            </SectionHeader>
                            {pageQuestions.length > 0 ? (
                                pageQuestions.map((x, i) => <Question key={i} question={x} view={'question'} hasAnswerView={currentUser?.role == "teacher"} />)
                            ) : (
                                <EmptyState>
                                    <Block style={{ color: COLOR.black600, fontSize: '40px' }} />
                                    <EmptyStateText>You haven't created any questions on this page.</EmptyStateText>
                                </EmptyState>
                            )}
                        </Section>
                    ) : null} */}
                </div>

                </ParentContainer>
                

                <CreationDrawer active={creationType}>
                    {creationType === 'note' ? (
                        <>
                            <FormHeader>Add a Note</FormHeader>
                            <FormDescription>Students will have their notes for the entire school year, motivate them to write as many notes as they need. This is a great tool to keep their work organized. You can write your notes here as well, keep track of any important information.</FormDescription>
                            <NoteForm handleClose={() => setCreationType(null)} handleSubmit={createNote} />

                        </>
                    ) : (
                        <>
                            <FormHeader>Ask a Question</FormHeader>
                            <FormDescription>This a great tool to check the student understanding of the work being assimilated. You can ask them to explain what they learn or check for understanding throughout the class.</FormDescription>
                            <QuestionForm handleClose={() => setCreationType(null)} handleSubmit={createQuestion} />
                        </>
                    )}
                </CreationDrawer>
                <AssignmentDrawer active={hasActiveAssignmentDrawer()}>
                    {activity && activeActivity ? (
                        <Assignment
                            chapter={CHAPTER_ID}
                            currentSubmission={find(submissions, ['assignmentId', activeActivity?.data.id])}
                            data={activeActivity?.data}
                            shuffle={activeActivity?.shuffled}
                            onClose={closeAssignment}
                            page={page}
                            retakes={activeActivity.retakes}
                            userRol={currentUser?.role}
                            setSubmitRef={setSubmitRef}
                            dueDate={activeActivity.dueDate}
                            timmer={timmer}
                            redirectTo={generateUrl(page, null, null)}
                            onFinish={onFinish}
                        />
                    ) : null}
                    {quiz && activeQuiz ? (
                        <Assignment
                            chapter={CHAPTER_ID}
                            currentSubmission={find(submissions, ['assignmentId', activeQuiz?.data.id])}
                            data={activeQuiz?.data}
                            shuffle={activeQuiz?.shuffled}
                            onClose={closeAssignment}
                            page={page}
                            retakes={activeQuiz.retakes}
                            userRol={currentUser?.role}
                            setSubmitRef={setSubmitRef}
                            dueDate={activeQuiz.dueDate}
                            timmer={timmer}
                            onFinish={onFinish}
                            redirectTo={generateUrl(page, null, null)}
                        />
                    ) : null}
                </AssignmentDrawer>

            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
BalconCulturalTooling.propTypes = {};

//  -- Default props ------------------
BalconCulturalTooling.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    activities: state.activities,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    likes: state.likes,
    interactions: state.interactions,
    notes: state.notes,
    questions: state.questions,
    quizzes: state.quizzes,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
});

export default connect(mapStateToProps, null)(BalconCulturalTooling);
