import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinks from './../containers/videoLinks';
import VideoLinkDropdown from './../containers/videoLinkDropdown';
import {blogbanner , videoLink , videoImg, videoBlog} from 'utils/assets/images';
const ButtonLink = styled.button``;

const classNav = () => {
    return (
        <VideoLinkDropdown />
    );
};

export default function LearnScreen() {
    return (
        <>
            <Row className="learnContentUpr" gutter={[25, 0]}>
               <Col xs={24} sm={24} md={6} className="searchMob">
                   <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                        <ButtonLink onClick={e => e.preventDefault()}>
                            Search
                            <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                        </ButtonLink>
                    </Dropdown>
                </Col>
                <Col xs={24} sm={24} md={18}>
                    <div className="vdoblogBanerUpr">
                        <div className="vdoblogBaner">
                            <img src={blogbanner} />
                            <a className="vdobannerHeading"><img src={videoBlog} /></a>
                        </div>
                        <div className="blogVdoMain">
                            <h1>Critical Language Acquisition</h1>
                            <div className="blogVdo">
                                <img src={videoImg} className="blogVdoOrg" />
                                <a className="playVdoBtn"><img src={videoLink} /></a>
                            </div>
                            <div className="blogVdoTxt">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={6} className="searchWeb">
                   <VideoLinks />
                </Col>
            </Row>
        </>
    );
}
