import React from 'react';
import {blogbanner, bloglink} from 'utils/assets/images';
export default function Banner() {
    return (
        <>
            <div className="blogBaner blogBanerAdded">
                <img src={blogbanner} />
                <a className="bannerHeading"><img src={bloglink} /></a>
            </div>
        </>
    );
}
