//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map , filter } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const interactionsInitial = [];
const interactionInitial = null;

export const interaction = (state = interactionInitial, action) => {
    switch (action.type) {
        case ActionTypes.interactions.getInteractionSuccess:
            return action.payload;
        case ActionTypes.interactions.getInteractionFail:
            return state;
        default:
            return state;
    }
};

export const interactions = (state = interactionsInitial, action) => {
    switch (action.type) {
        case ActionTypes.interactions.createInteractionSuccess:
            return [action.payload, ...state];
        case ActionTypes.interactions.listInteractionsSuccess:
        case ActionTypes.interactions.listChapterInteractionsSuccess:
            return action.payload;
        case ActionTypes.interactions.listInteractionsByTypeSuccess:
            return [...action.payload, ...state];
        case ActionTypes.interactions.updateInteractionSuccess:
            return map(state,dt=>{
                if(dt.id===action.payload.id){
                    return action.payload;
                }
                return dt;
            })
        case ActionTypes.interactions.deleteInteractionSuccess:
            return filter(state,dt=>dt.id!==action.payload)
        case ActionTypes.interactions.createInteractionFail:
        case ActionTypes.interactions.listInteractionsFail:
        case ActionTypes.interactions.listChapterInteractionsFail:
            case ActionTypes.interactions.listInteractionsByTypeFail:
            return state;
        default:
            return state;
    }
};
