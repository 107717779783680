import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import CancelIcon from "@material-ui/icons/Cancel";
import { Row, Col, Progress, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import { noise_meter_icon, TransparentLogoWhite } from "utils/assets/images";
import { wheel_icon } from "utils/assets/images/icons";
import COLOR from "utils/constants/color";
import Meter from "./Meter";
import { find } from "lodash";
import CustomSlider from "./Slider";
import { useNoiseMeter, useTraffic } from "store/wheel/selectors";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import { Star } from "@material-ui/icons";
import AccordinButton from "components/AccordinButton";
import { revokeTrafficModal } from "store/wheel/actions";
import { useDispatch } from "react-redux";
const bellSound = `https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2Fsound%2Fbell.mp3?alt=media&token=05f67512-9a45-460b-95f3-91f1782af067`;
const maxValue = 128.0;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  min-height: 500px;
  // background-color: #ffffff;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: default;
  left: 25%;
  border-radius: 13px;
`;
const LogoTopRow = styled.div``;
const Logo = styled.img`
  height: auto;
  width: 160px;
`;
const LogoBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-weight: bolder;
  font-size: 24px;
  font-size: 20px;
  letter-spacing: 1px;
`;

const CloseBtn = styled.div`
  background-color: ${COLOR.PinkExam};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: transparent;
`;
const WheelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  z-index: 11;
  transition: all 1s ease 0s;
  cursor: pointer;
`;
const WheelImg = styled.img`
  height: auto;
  width: 50px;
  margin-bottom: 5px;
`;
const Note = styled.div`
  color: ${COLOR.white};
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
`;
const NoteWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
`;
const NoiseMeter = ({sharableWidget}) => {
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterNoiseMeter = find(
    trafficData,
    (obj) => obj?.menu === "noise_meter"
  );

  const elementRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({});
  const [noiseLevel, setNoiseLevel] = useState(0);
  const [audioStream, setAudioStream] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [alertCount, setAlertCount] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [showAccordin, setShowAccordin] = useState(true);
  const [noiseModal, invokeNoiseModal, revokeNoiseModal] = useNoiseMeter();
  const [max_noise_level, setMaxNoiseLevel] = useState(4);
  const [muteNotification, setMuteNotification] = useState(false);
  const [notificationalertCount, setNotificationAlertCount] = useState(0);
  const audio = new Audio(bellSound);
  const [worker, setWorker] = useState(null);
  const trackerWaiting = useRef(false);

  const startMicrophone = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const context = new (window.AudioContext || window.webkitAudioContext)();
      const analyserNode = context.createAnalyser();
      const source = context.createMediaStreamSource(stream);

      source.connect(analyserNode);
      // analyserNode.fftSize = 1024;
      // analyserNode.minDecibels = -90;
      // analyserNode.maxDecibels = -10;
      analyserNode.fftSize = 512;
      analyserNode.minDecibels = -90;
      analyserNode.maxDecibels = -10;
      analyserNode.smoothingTimeConstant = 0.4;
      // analyserNode.smoothingTimeConstant = 0.4;

      setAudioStream(stream);
      setAudioContext(context);
      setAnalyser(analyserNode);
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };
  const stopMicrophone = () => {
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }
    if (audioContext) {
      audioContext.close();
      setAudioContext(null);
    }
    setAnalyser(null);
  };

  useEffect(() => {
    if (!isMuted) {
      startMicrophone();
    } else {
      stopMicrophone();
    }

    return () => {
      stopMicrophone();
    };
  }, [isMuted]);

  useEffect(() => {
    let interval;
    if (analyser && !isMuted) {
      interval = setInterval(() => {
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((a, b) => a + b, 0);
        const average = sum / dataArray.length;
        const normalizedLevel = (average / maxValue) * 10;
        const norm = average / 2.56;
        if (normalizedLevel >= max_noise_level) {
          // console.log(average,normalizedLevel,norm,"-=norm")
          // console.log(normalizedLevel,"normalizedLevel",norm);
          playSound();
        }

        setNoiseLevel(normalizedLevel);
      }, 100);
    }

    return () => clearInterval(interval);
  }, [analyser, isMuted, max_noise_level, muteNotification]);

  useEffect(() => {
    const newWorker = new Worker(`${process.env.PUBLIC_URL}/timeWorker.js`);
    setWorker(newWorker);
    newWorker.onmessage = (event) => {
      switch (event.data.type) {
        case "tick":
          setNotificationAlertCount(event.data.countdownTime);
          trackerWaiting.current = true;
          break;
        case "done":
          setNotificationAlertCount(0);
          trackerWaiting.current = false;
          break;
        case "reset":
          setNotificationAlertCount(event.data.countdownTime);
          trackerWaiting.current = false;
          break;
      }
    };
    return () => newWorker.terminate();
  }, []);

  const playSound = () => {
    // console.log( muteNotification  , trackerWaiting.current )
    if (muteNotification || trackerWaiting.current) return;
    trackerWaiting.current = true;
    setAlertCount((p) => p + 1);
    audio.play();
    setNotificationAlertCount(5);
    worker.postMessage({ command: "start", time: 5 });
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };
  const onClose = async () => {
    await dispatch(revokeTrafficModal(filterNoiseMeter?.id));
    revokeNoiseModal();
    setNoiseLevel(0);
    setAlertCount(0);
    setIsMuted(true);
  };

    const handleTouch = (event) => {
    event.preventDefault();
    onClose();
  };

  const noisePercant = (notificationalertCount / 5) * 100;


  return (
    <>
      {filterNoiseMeter?.view && filterNoiseMeter?.menu === "noise_meter" ? (
        <div>
          <Container className="spinWheelCntnr overflow-unset">
            <div className="mainCheckList">
              <ResizeAndDragabble
                defaultValue={{
                  width: 270,
                  // 514,
                  height: 340,
                }}
                resize={(data) => setDimensions(data)}
                minWidth={270}
                minHeight={340}
                showAccordin={showAccordin}
              >
                <div
                  className="checkListTopSection"
                  style={{
                    padding: "8px 8px",
                    position: "relative",
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                    // height: isPredefinedImage ? "auto" : "100%",
                    height: showAccordin ? "100%" : "0px",
                    width: "100%",
                    padding: "0px"
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      display: "grid",
                      placeItems: "center",
                      // justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                    ref={elementRef}
                  >
                    <div
                      style={{ marginBottom: "0px" }}
                      className="noise-cancel"
                    >
                      <CancelIcon
                        onClick={() => onClose()}
                        onTouchStart={handleTouch}
                        style={{
                          zIndex: 1,
                          marginLeft: "5px",
                          position: "absolute",
                          right: 11,
                          top: 14,
                          fontSize: 20,
                          color: "red",
                          background: "white",
                          borderRadius: 20,
                          cursor: "pointer",
                        }}
                      />
                    </div>

                    <div   style={{
                        fontSize: `${`clamp(21px, ${
                          dimensions.width / 17
                        }px, 100px)`}`,
                      }} className={`checkListFirstTxt w-full absolute top-0  bg-blue-10 pt-17px pb-14px ${
                    showAccordin ? "br-topLeft-topRight-20px" : "br-20px"
                  } `}>
                    <AccordinButton open={showAccordin} onClose={()=>setShowAccordin(prev => !prev)}  />
                   <span className="text-white text-ft12-700">  Max Noise</span>
                </div>
                {showAccordin && (
                    <>
                    <Row
                      gutter={[10, 10]}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "29px",
                      }}
                    >
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Meter
                          value={noiseLevel}
                          height={
                            dimensions.width > 600
                              ? (dimensions.width / 2 + 150) -200
                              : dimensions.width / 5 + 82
                          }
                          width={
                            dimensions.width > 600
                              ? dimensions.width / 3 + 150
                              : dimensions.width / 5 + 150
                          }
                          max_value={max_noise_level}
                        />
                      </Col>
                      {/* <Col span={4} style={{ display:"flex" , justifyContent:"center", flexDirection:"column" , justifyItems:"flex-start" , alignItems:"center" , gap:24  }}>
            <IconButton onClick={toggleMute} onTouchEnd={toggleMute} aria-label="mute" style={{ backgroundColor: isMuted?COLOR.red:COLOR.green100 , height:70 , width:70 }} >
              {isMuted ? <MicOffIcon style={{ color: COLOR.white , fontSize:"38px"}}  /> : <MicIcon style={{ color: COLOR.white , fontSize:"38px"}} />}
            </IconButton>
            <div style={{ position: "relative" }}>
              <Progress type="circle" percent={(notificationalertCount / 5) * 100} size={"small"} width={78} className={"fileProgress"} showInfo={false} style={{ position:"absolute" , left:-4 , top:-4 }} strokeWidth={6} />
              <IconButton aria-label="notifications" style={{ background: COLOR.white, height: 70, width: 70 }} onClick={() => setMuteNotification(!muteNotification)} onTouchEnd={() => setMuteNotification(!muteNotification)}>
                <Badge badgeContent={alertCount} color="secondary">
                  {muteNotification ? <NotificationsOffIcon style={{ color: COLOR.yellow, fontSize: "54px" }} /> : <NotificationsIcon style={{ color: COLOR.yellow, fontSize: "54px" }} />}
                </Badge>
              </IconButton>
            </div>
           
          </Col> */}
                    </Row>
                    <Row
                      gutter={[10, 10]}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: 30,
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <CustomSlider
                          dimensions={dimensions}
                          value={max_noise_level}
                          onChange={setMaxNoiseLevel}
                        />
                      </Col>
                      {/* <Col
                        span={4}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <Logo src={noise_meter_icon} />
                      </Col> */}
                    </Row>
                    <Row>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          justifyItems: "flex-start",
                          alignItems: "center",
                          gap: 24,
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <Progress
                            type="circle"
                            percent={(notificationalertCount / 5) * 100}
                            size={"small"}
                            width={78}
                            className={"fileProgress"}
                            showInfo={false}
                            style={{ position: "absolute", left: -4, top: -4, display: "none" }}
                            strokeWidth={6}
                          />
                          <IconButton
                            aria-label="notifications"
                            style={{
                              background: COLOR.white,
                              height: `clamp(42px, ${
                                dimensions.width / 15
                              }px, 220px)`,
                              width: `clamp(42px, ${
                                dimensions.width / 15
                              }px, 220px)`,
                              border: `3px solid #f5f5f5`,
                              padding: "21px",
                            }}
                            onClick={() =>
                              setMuteNotification(!muteNotification)
                            }
                            onTouchEnd={() =>
                              setMuteNotification(!muteNotification)
                            }
                          >
                            <div
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                border: `6px solid ${
                                  noisePercant === 100
                                    ? "rgb(82, 196, 26)"
                                    : "#f5f5f5"
                                }`,
                              }}
                            />
                            <Badge badgeContent={alertCount} color="secondary">
                              {muteNotification ? (
                                <NotificationsOffIcon
                                  style={{
                                    color: COLOR.yellow,
                                    fontSize: `clamp(24px, ${
                                      dimensions.width / 15
                                    }px, 72px)`,
                                  }}
                                />
                              ) : (
                                <NotificationsIcon
                                  style={{
                                    color: COLOR.yellow,
                                    fontSize: `clamp(24px, ${
                                      dimensions.width / 15
                                    }px, 72px)`,
                                  }}
                                />
                              )}
                            </Badge>
                          </IconButton>

                          {/* <img src={require('../../utils/assets/images/noise_meter_bell.png')} alt="" aria-label="notifications" style={{ background: COLOR.white, height: 70, width: 70 }} onClick={() => setMuteNotification(!muteNotification)} onTouchEnd={() => setMuteNotification(!muteNotification)}>
                <Badge badgeContent={alertCount} color="secondary">
                  {muteNotification ? <NotificationsOffIcon style={{ color: COLOR.yellow, fontSize: "54px" }} /> : <NotificationsIcon style={{ color: COLOR.yellow, fontSize: "54px" }} />}
                </Badge>
              </img> */}

                          {/* <img src={require('./utils/assets/images/GameLogo.jpg')} alt="" /> */}
                        </div>
                        <IconButton
                          onClick={toggleMute}
                          onTouchEnd={toggleMute}
                          aria-label="mute"
                          style={{
                            backgroundColor: isMuted
                              ? COLOR.red
                              : COLOR.green100,
                            height: `clamp(42px, ${
                              dimensions.width / 15
                            }px, 220px)`,
                            width: `clamp(42px, ${
                              dimensions.width / 15
                            }px, 220px)`,
                          }}
                        >
                          {isMuted ? (
                            <MicOffIcon
                              style={{
                                color: COLOR.white,
                                fontSize: `clamp(24px, ${
                                  dimensions.width / 16
                                }px, 220px)`,
                                width: 72,
                              }}
                            />
                          ) : (
                            <MicIcon
                              style={{
                                color: COLOR.white,
                                fontSize: `clamp(24px, ${
                                  dimensions.width / 16
                                }px, 220px)`,
                                width: "72px",
                              }}
                            />
                          )}
                        </IconButton>
                      </Col>
                    </Row>
                    <NoteWrapper>
                      {/* <Tag height={20}>
                            <Star
                              style={{
                                height: "12px",
                                margin: "-1px 4px 0px 0px",
                                width: "12px",
                               
                              }}
                            />{" "}
                            NOTE
                          </Tag> */}
                      <Note
                        style={{
                          color: "#000",
                          fontSize: `clamp(13px, ${
                            dimensions?.width / 35
                          }px, 40px)`,
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        Sensitivity scale from 1 to 10: 1 is most sensitive, 10
                        is least sensitive.
                      </Note>
                    </NoteWrapper>
</>
                )}
                   
                    
                  </div>
                </div>
              </ResizeAndDragabble>
            </div>
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default NoiseMeter;

export const NoiseMeterViewer = ({}) => {
  const [, invokeNoiseModal] = useNoiseMeter();
  return (
    <WheelWrapper onClick={invokeNoiseModal} className="wheelSpinImg">
      <WheelImg src={wheel_icon} />
    </WheelWrapper>
  );
};

{
  /* <Meter value={noiseLevel}/>
<CustomSlider value={max_noise_level} onChange={setMaxNoiseLevel}  />
<IconButton aria-label="notifications">
  <Badge badgeContent={alertCount} color="secondary">
    <NotificationsIcon />
  </Badge>
</IconButton>
<IconButton onClick={toggleMute} aria-label="mute">
  {isMuted ? <MicOffIcon /> : <MicIcon />}
</IconButton> */
}

// if (maxVolume > max_noise_level) {
//   setAlertCount(prev => prev + 1);
//   audio.play();
// }

// useEffect(() => {
//   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//   const analyser = audioContext.createAnalyser();
//   const audio = new Audio(bellSound);

//   const getMicrophoneInput = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const source = audioContext.createMediaStreamSource(stream);
//       source.connect(analyser);
//       analyser.fftSize = 512;
//       analyser.minDecibels = -90;
//       analyser.maxDecibels = -10;
//       analyser.smoothingTimeConstant = 0.4;
//     } catch (err) {
//       console.error('Error accessing microphone:', err);
//     }
//   };

//   if (!isMuted) {
//     getMicrophoneInput();
//   }

//   const interval = setInterval(() => {
//     if (!isMuted) {
//       const dataArray = new Uint8Array(analyser.frequencyBinCount);
//       analyser.getByteFrequencyData(dataArray);
//       const sum = dataArray.reduce((a, b) => a + b, 0);
//       const average = sum / dataArray.length / 2.56;
//       setNoiseLevel(average);
//       console.log(average)

//     }
//   }, 100);

//   return () => {
//     clearInterval(interval);
//     audioContext.close();
//     if (chartRef.current) {
//       chartRef.current.chart.destroy();
//     }
//   };
// }, [isMuted,max_noise_level]);
