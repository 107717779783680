import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { map, filter, find, cloneDeep } from "lodash";
import { Delete, Add } from "@material-ui/icons";
import { Row } from "antd";
import styled from "styled-components";
import { selectedUser } from "store/selectedUser/reducers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme) => ({
  noSpinners: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const EditModal = ({
  options,
  onSelect,
  defaultValue,
  onListChange,
  hideModal,
  setEditSelectedOption,
  editSelectedOption,
  setStudentFitlerStudentClass,
  setStudentOfCurrentClass,
  filteredStudentClass,
  studentOfCurrentClass,
  createStudentPayload,
  createStudentGroup,
  SendOnFirebase,
  isStudentGenerated,
  tweleveHourOption,
  setUsers
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const isAllSelected =
    options.length > 0 && selected.length === options.length;
  const [list, setList] = useState([]);
  const MultiMenu = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    variant: "menu",
    getContentAnchorEl: () => null,
  };

  // handle change for dropdown
  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      setEditSelectedOption(selected.length === options.length ? [] : options);
      return;
    }

    setSelected(value);
    setEditSelectedOption(value);
  };

  useEffect(() => {
    if (editSelectedOption?.length > 0 || tweleveHourOption?.length > 0) {
      setSelected(tweleveHourOption);
      setEditSelectedOption(tweleveHourOption);
    } else {
      setSelected(options);
      setEditSelectedOption(options);
    }
    setList([]);
  }, [tweleveHourOption]);

  const onDone = () => {
    onSelect(selected);
  };

  const onChange = (txt, i, key) => {
    let _lst = cloneDeep(list);
    _lst[i][key] = txt;
    setList(_lst);
  };
  const onRemove = (i) => {
    let _lst = cloneDeep(list);
    _lst.splice(i, 1);
    setList(_lst);
  };

  const addNew = () => {
    let _lst = cloneDeep(list);
    _lst.push({ firstName: "", lastName: "", id: list.length });
    setList(_lst);
  };
  const onDoneAddItem = async () => {
    let _lst = filter(list, (lst) => lst.firstName || lst.lastName).map(
      (item) => `${item.firstName}  ${item.lastName}`
    );

    if (_lst.length > 0) {
      setUsers([...options, ..._lst])
      SendOnFirebase([...selected, ..._lst]);
      setSelected([...selected, ..._lst]);

      setEditSelectedOption([...selected, ..._lst]);
      onListChange(_lst);


      onSelect([...selected, ..._lst], true);


      const updatedStudentOfCurrentClass = [
        ...studentOfCurrentClass,
        ...createStudentPayload(list),
      ];

      setStudentOfCurrentClass(updatedStudentOfCurrentClass); // add a new user in original list

      setStudentFitlerStudentClass(updatedStudentOfCurrentClass); // add a new user in filter  list


      setList([]);
    } else {
    }
  };


  // useEffect(()=>{
  //   const getNewUserData = tweleveHourOption?.filter((student) =>
  //     !options.includes(
  //       student
  //         .trim()
  //         .replace(/\s+/g, " ")
  //     )
  //   );

  //   const data = getNewUserData?.map(data => {
  //     if (!data) return { firstName: '', lastName: '' };

  //     const name = data.trim().split(" ");
  //     const firstName = name[0] || '';
  //     const lastName = name[1] || '';

  //     return { firstName, lastName };
  //   });

  //   const updatedStudentOfCurrentClass = [
  //     ...studentOfCurrentClass,
  //     ...createStudentPayload(data),
  //   ];
  //   setUsers([...new Set([...options, ...getNewUserData])]);
  //   setStudentFitlerStudentClass(updatedStudentOfCurrentClass);
  //   setStudentOfCurrentClass(updatedStudentOfCurrentClass);
  // },[])
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormControl
      style={{ width: "410px" }}
      variant="outlined"
      className={classes.formControlStudent}
    >
      <div
        style={{
          border: list?.length === 0 && "none",
          margin: list?.length === 0 && "0px",
          padding: list?.length === 0 && "0px",
        }}
        className="w-full form-field-container"
      >
        {map(list, (lst, i) => (
          <ModalRow style={{ justifyContent: "space-between" }}>
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              style={{ width: 150, color: "white", marginRight: "5px" }}
              InputLabelProps={{ shrink: true, required: true }}
              inputProps={{ shrink: true, required: true }}
              variant="outlined"
              onChange={(e) => {
                const txt = e.target.value;
                onChange(txt, i, "firstName");
              }}
              value={lst.fistName}
            />
            -
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              style={{ width: 150, color: "white", marginLeft: "5px" }}
              InputLabelProps={{ shrink: true, required: true }}
              inputProps={{ shrink: true, required: true }}
              variant="outlined"
              onChange={(e) => {
                const txt = e.target.value;
                onChange(txt, i, "lastName");
              }}
              value={lst.lastName}
            />
            <IconButton
              color="secondary"
              onClick={() => {
                onRemove(i);
              }}
            >
              <Delete />
            </IconButton>
          </ModalRow>
        ))}
        {list?.length > 0 && (
          <div className="flex items-center justify-end">
            <Button
              style={{ marginRight: "4px", background: "lightGrey" }}
              onClick={() => {
                setList([]);
              }}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              variant="contained"
              style={{ float: "right" }}
              onClick={() => {
                if (!list.length) {
                  onDone();
                } else {
                  onDoneAddItem();
                }
              }}
            >
              Done
            </Button>
          </div>
        )}
      </div>

      {/* <ModalRow>
          <Button color="primary" variant="contained" onClick={addNew}>
            <Add /> Add student
          </Button>
        </ModalRow> */}
      <ModalRow>
        <Button color="primary" variant="contained" onClick={addNew}>
          <Add /> {list?.length > 0 ? "Add more student" : "Add student"}
        </Button>
        {open && <span style={{color: 'red'}}>{popupMessage}</span>}
        <Row>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              if (editSelectedOption?.length === 0) {
                setOpen(true)
                setPopupMessage('Atleast one student Required')
              } else {
                hideModal();
                SendOnFirebase(editSelectedOption)
                if (isStudentGenerated) {
                  const normalizedEditSelectedOption = editSelectedOption?.map(
                    (option) => option?.trim()?.replace(/\s+/g, " ")
                  );

                  const filteredData = studentOfCurrentClass?.filter((student) =>
                    normalizedEditSelectedOption?.includes(
                      `${student.firstName} ${student.lastName}`
                        .trim()
                        .replace(/\s+/g, " ")
                    )
                  );
                  const result = filteredData?.length > 0 ? filteredData : studentOfCurrentClass;

                  setStudentFitlerStudentClass(result);
                  createStudentGroup(result);
                }
              }
            }}
          >
            Done
          </Button>
        </Row>
      </ModalRow>
      <FormControl variant="outlined" className={classes.formControlStudent}>
        <InputLabel
          id="mutiple-select-label"
          style={{ backgroundColor: "#fff" }}
        >
          Select students
        </InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => (
            <div className={classes.chips}>
              {/* { `${isAllSelected||selected.length==0?"All students":"Students("+selected.length+")"}`} */}
              <Chip
                label={`${isAllSelected || selected.length == 0
                  ? "All students"
                  : "Students(" + selected.length + ")"
                  }`}
                className={classes.chip}
              />
            </div>
          )}
          MenuProps={MultiMenu}
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
          {map(options, (option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox
                  checked={Boolean(find(selected, (k) => k === option))}
                />
              </ListItemIcon>
              <ListItemText primary={`${option}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormControl>
  );
};
export default EditModal;
