import React from "react"
import { EmojiEmotions } from "@material-ui/icons"
import styled from 'styled-components';
import { useComponentVisible } from "./hooks";
import COLOR from 'utils/constants/color';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const EmojiButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`;
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: ${p=>p.bottom?p.bottom:"60px"};
  overflow: visible;
  @media (max-width:996px){
    left:0;
  }
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
`
export default function EmojiPicker({ onEmojiClick , bottom="60px"}) {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);

    return (
        <Wrapper>
            {isComponentVisible && (
                <EmojiWrapper ref={ref} bottom={bottom}>
                    <Picker
                        data={data}
                        onEmojiSelect={onEmojiClick}
                        theme="light"
                        previewPosition="none" 
                        style={{height:300,width:250}}
                        />
                </EmojiWrapper>
            )}
            <EmojiButton
                onClick={() => setIsComponentVisible(true)}
            >
                <EmojiEmotions style={{ color: COLOR.yellow }} />
            </EmojiButton>
        </Wrapper>
    );
}
