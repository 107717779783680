import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import VideoLinks from './../containers/videoLinks';
import { CaretDownOutlined } from '@ant-design/icons';
import VideoLinkDropdown from './../containers/videoLinkDropdown';

const ButtonLink = styled.button``;

const classNav = () => {
    return (
        <VideoLinkDropdown />
    );
};

export default function LearnScreen() {
    return (
        <>
            <div className="detailMain">
                <Row gutter={[25, 0]}>

                    <Col xs={24} sm={24} md={6} className="searchMob">
                        <Dropdown overlay={classNav()} trigger={['click']} className="mobSrchLink" style={{ left: 'initial' }}>
                            <ButtonLink onClick={e => e.preventDefault()}>
                                Search
                                <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                            </ButtonLink>
                        </Dropdown>
                    </Col>

                    <Col xs={24} sm={24} md={18}>

                        <div className="detailInfo detailSpc">
                            <div><h1>Teaching Heritage Language Learners </h1></div>
                            <div>
                                <p>Teaching Spanish varies if you are teaching Language 2 students or  Heritage Language Learners. According to Dr. María Carreira there are three must do’s when teaching HLL:
                                1. Expand proficiency by focusing on what students already know,
                                2. Respond to learners motivations and experiences,
                                3. Start with the learner, not the curriculum (STARTALK 2021). </p>
                                <p>Teachers should instruct with a macro-based approach: using authentic materials to later conduct and
                                interleave “form focused instruction”. Scaffolding is the key to achieve formal academic registers in
                                language learning and with HLL Olga Kagan’s “From to Principles”: Teachers  take HLL from listening to
                                reading, speaking to writing, home based register to academic register, everyday life activities to academic
                                activities, HLL motivations to classroom content. These principles allow teachers to expose students to instructional
                                strategies that target all four language modalities: listening, reading, speaking, and writing. As a rule of thumb when
                                you are planning your lessons, start with listening activities that take student to speaking activities. Build on those two
                                modalities to introduce reading activities that then take students to sources of content for academic writing. Always remember to
                                build on what learners already know! Have fun teaching! </p>
                            </div>
                            <div><label><b>Vivanie Barrios, Los Angeles, California </b></label></div>
                        </div>

                        <div className="detailInfo">
                            <div><h2>Differentiation</h2></div>
                            <div>
                                <p>Differentiation is understanding that we teach students that are ALL different. Students learn at different pace
                                and  in different ways. The number one differentiation strategy is lowering the affective filter or in simple words
                                creating a learning environment where students trust each other as they learn and as they make mistakes. Next, we have to
                                make sure our instruction is purposeful and students see the real life value of learning specific content. To assess that content,
                                we must provide opportunities for formative assessment where students become accountable for their own learning in individual as well as
                                collaborative settings. Finally, the key element in differentiation, is flexibility. A teacher must understand that an assignment might look
                                different when providing student voice, choice, therefore the biggest asset of differentiation in a classroom is not a book or a tool, is the teacher.
                                We must understand differences in student learning and flexible enough to make input engaging and comprehensible. </p>
                            </div>
                        </div>

                        <div className="detailDiffer detailSpc">
                            <Row gutter={[25, 0]}>
                                <Col xs={24} sm={24} md={24}><h3>Differentiation Tips: </h3></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Get to know your students</label></Col>
                                <Col xs={12} sm={12} md={12}><p>Consistent Teacher to Student Feedback</p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Grouping</label></Col>
                                <Col xs={12} sm={12} md={12}><p>Using Graphic organizers</p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Peer/ Buddy System </label></Col>
                                <Col xs={12} sm={12} md={12}><p>Authentic IPAs</p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Choice Boards  </label></Col>
                                <Col xs={12} sm={12} md={12}><p>Be consistent with your strategies. </p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Peer to peer feedback   </label></Col>
                                <Col xs={12} sm={12} md={12}><p>Analyze formative and summative </p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={12} sm={12} md={12}><label>Project Based Learning  </label></Col>
                                <Col xs={12} sm={12} md={12}><p>assessment results </p></Col>
                            </Row>
                            <Row gutter={[25, 0]}>
                                <Col xs={24} sm={24} md={24} className="OrgLink">
                                    <h4>Place a Link to the Graphic Organizers </h4>
                                    <p> - Vivanie Barrios, Los Angeles, California </p>
                                </Col>
                            </Row>
                        </div>

                        <div className="detailInfo detailSpc">
                            <div><h1>Class Passwords/ Contraseñas</h1></div>
                            <div>
                                <p>“Soy fuerte y puedo superar todos los retos de mi vida". Let's be real, teaching is no easy job, and it’s fair to say that we all
                                need a little encouragement and self-affirmation now and then, and so do our students. In order to enter my classroom, each student
                                must recite the “contraseña”, which is a phrase/ quote that they either memorize or read off of. I give them their contraseña for the
                                week on a Monday and change it every two weeks, giving students plenty of time to rehearse and recite it. These phrases/ quotes are usually
                                short, sweet and to the point. They are helpful in so many ways and serve many purposes. I use them in my Spanish 1 class to teach students helpful
                                phrases that can be used in interpersonal communication in and outside class. For example, after students learn the verb “ser” they are then given the
                                contraseña “No es justo” towards the beginning of the first semester. Throughout the year, they end up using the phrase during class, especially when I
                                assign them homework. Students get very creative and feel confident using the phrases, since  they have been rehearsed and used many times. </p>

                                <p>For my Spanish speakers, I give them an affirmation as their contraseña such as the one mentioned above. I find that this creates a positive environment right
                                from the moment students enter the classroom. It also gives students an opportunity to practice their pronunciation without the stress of doing it in front of the
                                whole class, and allows for immediate correction. It is an easy way to expose students to more vocabulary, idioms and useful phrases  in a way that is comprehensible.
                                Besides exposing students to new vocabulary, practicing pronunciation, and creating a positive learning environment, these phrases can also be beneficial for all proficiency
                                levels when teaching grammar concepts as students hear the grammar concept in a natural and comprehensible manner. </p>
                                <p>
                                    <strong>Here are some examples:</strong>
                                    <ul>
                                        <li>“Cada día trae nuevas opciones” </li>
                                        <li>“La mejor manera de predecir el futuro es inventarlo”</li>
                                        <li>“Siempre parece imposible hasta que se convierte en realidad”</li>
                                        <li>“Es igual de importante el viaje que el destino”</li>
                                    </ul>
                                </p>
                            </div>
                            <div><label><b>- Jodie Sullivan, Los Angeles, California </b></label></div>
                        </div>

                        <div className="detailInfo detailSpc">
                            <div><h1>Heritage Language Teaching objectives </h1></div>
                            <div>
                                <p>We all have objectives in life, they range from personal, financial to health and economical objectives. Have you asked yourself what
                                objectives are best to set when teaching Spanish to Spanish Speakers, Receptive Bilingual, or Heritage Language Learners?</p>
                                <p>According to Guadalupe Valdés, our main objectives should be language maintenance, expansion of linguistic skills in L1 and L2, and
                                the transfer of reading and writing skills between languages. In short, promote an environment of learning where students feel comfortable
                                using the language.  Expand motivation in students to strive to be truly bilingual, and bicultural. Create environments where students feel
                                comfortable receiving and giving feedback. Welcome cross linguistic references and promote that “orgullo” of being bilingual.</p>
                                <p>Do not  forget that the language skills learned in Spanish class are transferred to all the other subject areas. Cada granito de arena cuenta.</p>
                            </div>
                            <div><label><b>- Vivanie Barrios, Los Angeles, California</b></label></div>
                        </div>

                        <div className="detailInfo detailSpc">
                            <div><h1>The data driven instructor </h1></div>
                            <div>
                                <p>Data, data, data! We hear it everywhere, but which data can improve your classroom culture? I have found it useful to look beyond quantitative data. As the
                                beginning of a new year is approaching, ask yourself if you have a qualitative data tool that can give you a snapshot of individualized student needs, student barriers,
                                their stories. Looking at qualitative data is the first step for creating a conducive learning environment, since it helps build relationships. </p>
                                <p>Survey your class the first week of school, read the data, connect and make it your semester’s goal to build relationships. Always be positive and make your classroom
                                a place where students can immerse themselves in reflection and motivation. Show that you care when students fail, succeed and improve. As a rule of thumb, I have student
                                reflections every 5 weeks and students have the opportunity to reflect on their work habits as well as reflect on their instructor’s teaching strategies. Yes, they get to
                                grade me and tell me why they rated me the way they did. Reviewing their responses  allows me to adjust my pacing, reteach a concept, or simply throw out the window practices
                                that were not liked by my students. Qualitative data has given me a chance to focus on what is important to my students and what is working for them. This tool creates discourse
                                between students and teachers and if presented with consistency, the responses are more and more insightful. We are all in this together and remember quantitative data gives you
                                numeric values that are significant for  your instruction but qualitative gives you the possible reasons of those values. Get to know your students since day 1, there is nothing more
                                valuable in instruction than making connections.</p>
                                <p>Place a link to my Surveys Beginning of the Year and 5 week reflection surveys </p>
                            </div>
                            <div><label><b>- Vivanie Barrios, Los Angeles, California </b></label></div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={6} className="searchWeb">
                        <VideoLinks />
                    </Col>

                </Row>
            </div>

        </>
    );
}
