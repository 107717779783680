//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import CancelIcon from "@material-ui/icons/Cancel";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

//  -- Components ---------------------

//  -- Constants ----------------------
import { Button, makeStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { filter, find, get } from "lodash";
import { useDispatch } from "react-redux";
import { useDifferentiationLesson } from "store/DifferentiationLesson/selectors";
import { handleAiDifferentiationLesson, handleGetDifferentiationLesson } from "store/DifferentiationLesson/thunks";
import { useCurrentUser } from "store/users/selectors";
import "../Differentiation/index.css";
import CreateDifferentiationModal from "./CreateDifferentiationModal";
import { Tooltip } from "antd";
import { useAllBook } from "store/books/selectors";



// Super-lession css
const ModalSuperPlane = styled.div`
  align-items: center;
  background-color: #000f4c;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px !important;
  padding: 24px 28px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

// end Super-lession css

const useStyles = makeStyles({
  disabledButton: {
    color: "white", // Text color when disabled
    "&.Mui-disabled": {
      color: "white", // Ensures the color remains white even if the button is disabled
    },
  },
});

const Differentiation = ({
  superLessonData,
  bookId,
  pageId,
  style,
  setVocModal,
  viewLesson,
  showModal,
  data,
  onLessonOpen = () => null,
  onDifferentiationOpen = () => null,
  superLessonOpen,
  from,
  setOpenDiffernationToolModal,
  setToolsDropdown,
  openDiffernationToolModal,
  mediaQuery,
  DisplaySuperLessonAndDiff
}) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const superLesson = useDifferentiationLesson();
  const [alertSakeBar, setAlertSakeBar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [superLession, setSuperLession] = useState(false);
  const [showReplacementMessage, setShowReplacementMessage] = useState(false);
  const [createLesson, setCreateLesson] = useState(false);
  const filterLesson = find(superLesson, (data) => data?.userId === currentUser?.id && data?.access_by?.includes(currentUser?.id)) || {};
  const filterShareLesson = filter(superLesson, (data) => data?.userId !== currentUser?.id && data?.access_by?.includes(currentUser?.id)) || [];
  const [filterMyData, setFilterMyData] = useState(filterLesson);
  const [filterSharedLesson, setFilterSharedLesson] = useState(filterShareLesson);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const booksAllList = useAllBook()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getNumberMask = useCallback((_page) => {
    let book = find(booksAllList, k => k.id == bookId)
    if (book) {
      let numberMask = find(book.pages, k => k.pageId == _page)
      if (numberMask) {
        if (numberMask.NumberMasked)
          return numberMask.NumberMasked
        else
          return _page;
      } else {
        return _page;
      }
    } else {
      return _page;
    }
  }, [bookId, booksAllList])

  const getPageInfo = useCallback(() => {
    const mask_from = getNumberMask(superLessonData?.from)
    const mask_to = getNumberMask(superLessonData?.to)
    if (from === 'ebook') {
      return mask_from && mask_to ? `(P. ${mask_from}-${mask_to})` : ''
    } else {
      return null;
    }
  }, [superLessonData])

  useEffect(() => {
    setFilterSharedLesson(filterShareLesson);
    setFilterMyData(filterLesson);
  }, [superLesson])

  useEffect(() => {
    onFetchLesson();
  }, [pageId, bookId]);

  const onFetchLesson = async () => {
    const data = {
      bookId: bookId,
      pageId: pageId,
      userId: currentUser.id,
    };
    await dispatch(handleGetDifferentiationLesson(data));
  };

  const closeAlert = () => {
    setAlertSakeBar({ open: false, message: "", severity: "success" });
  };

  const onLessonAi = async (differentiation_data) => {
    const { limit, ...superLessonPayload } = filterMyData;
    const data = {
      ...superLessonPayload,
      bookId,
      pageId,
      from_page: superLessonData.from,
      to_page: superLessonData.to,
      userId: currentUser.id,
      differentiation_data,
      access_by: [currentUser.id]
    };
    setCreateLesson(true);
    setSuperLession(false);
    const SuperLessonData = await dispatch(handleAiDifferentiationLesson(data));
    setCreateLesson(false);
    if (SuperLessonData?.success && SuperLessonData?.status_code === 200) {
      setAlertSakeBar({
        open: true,
        message: "Created Succesfully",
        severity: "success",
      });
      onLessonOpen();
    }
    else if (SuperLessonData?.status_code === 429 || SuperLessonData?.status_code === 404) {
      setAlertSakeBar({
        open: true,
        message:
          "you've reached the limit for creating Differentiation on this page today.",
        severity: "error",
      });
    } else {
      setAlertSakeBar({
        open: true,
        message:
          "An error occurred. Please contact the administrator for assistance.",
        severity: "error",
      });
    }
  };

  useEffect(()=>{
    if (!viewLesson && openDiffernationToolModal) {
      setToolsDropdown(false);
      setOpenDiffernationToolModal(false);
      setSuperLession(!superLession);
      setFilterMyData(filterLesson);
      setFilterSharedLesson(filterShareLesson);
      onFetchLesson()
      setShowReplacementMessage(false)
      onDifferentiationOpen(!superLession)
    }
  },[openDiffernationToolModal])

  const handleSuperLesson = () => {
    if (superLessonOpen) {
      setAlertSakeBar({
        open: true,
        message:
          "Please close the SuperLesson, to open the Differentiation",
        severity: "error",
      });
    } else {
      if (!viewLesson) {
        setSuperLession(!superLession);
        setFilterMyData(filterLesson);
        setFilterSharedLesson(filterShareLesson);
        onFetchLesson()
        setShowReplacementMessage(false)
        onDifferentiationOpen(!superLession)
      }
    }
  };

  const handleMyLession = async () => {
    // setCreateLesson(true);
    // setSuperLession(false);
    setShowReplacementMessage(false);
    await onLessonAi({});
  };

  const getMaskIdFrom = get(
    find(get(data, "content"), (k) => k.pageId === superLessonData?.from),
    "numberMasked",
    superLessonData?.from
  );

  const getMaskIdTo = get(
    find(get(data, "content"), (k) => k.pageId === superLessonData?.to),
    "numberMasked",
    superLessonData?.to
  );

  const tooltipText = `Differentiation (P. ${getMaskIdFrom}-${getMaskIdTo})`;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertSakeBar.open}
        autoHideDuration={3000}
        onClose={closeAlert}
      >
        <Alert
          onClose={() => {
            setAlertSakeBar({ open: false, message: "" });
          }}
          severity={alertSakeBar.severity}
        >
          <strong>ALERT:</strong> {alertSakeBar.message}
        </Alert>
      </Snackbar>
      {/* <Tooltip title={getPageInfo()}> */}
      {(isMobile >= mediaQuery) || !DisplaySuperLessonAndDiff &&(
          <Button
          style={{ minWidth: "unset", height: "36px", borderRadius: "0px !important", ...(style ? style : {}) }}
          onClick={handleSuperLesson}
          className="vocabularyBtnDiff vocDeskTab scheduledWeb superLessonButton"
        >
          {(isMobile <=768 || from !== 'ebook') ? 'Differentiation' : `Differentiation ${getPageInfo()}`}
        </Button>
      )}

{!(isMobile >= mediaQuery) || !DisplaySuperLessonAndDiff  &&(
          <Button
          style={{ minWidth: "unset", height: "36px", borderRadius: "0px !important", ...(style ? style : {}) }}
          onClick={handleSuperLesson}
          className="vocabularyBtnDiff vocDeskTab scheduledWeb superLessonButton"
        >
          {(isMobile <=768 || from !== 'ebook') ? 'Differentiation' : `Differentiation ${getPageInfo()}`}
        </Button>
      )}
      
      {/* </Tooltip> */}
      <>
        {superLession && (
          <div
            className="superLessionModal"
          // style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
          >
            <ModalSuperPlane className="DifferentiationLessionContents">
              <span onClick={() => {
                setSuperLession(false);
                onDifferentiationOpen(false);
                setShowReplacementMessage(false)
              }}>
                <CancelIcon
                  style={{
                    position: "absolute",
                    right: -7,
                    top: -7,
                    fontSize: 18,
                    color: "red",
                    background: "white",
                    borderRadius: 20,
                    cursor: "pointer",
                  }}
                />
              </span>
              <Button
                className={classes.disabledButton}
                onClick={() => {
                  onLessonOpen("shared");
                  setSuperLession(false);
                }}
                disabled={filterSharedLesson?.length === 0}
                style={{ opacity: filterSharedLesson?.length === 0 ? 0.6 : 1 }}
              >
                See shared differentiations
              </Button>
              <Button
                className={classes.disabledButton}
                onClick={() => {
                  onLessonOpen();
                  setSuperLession(false);
                }}
                disabled={!filterMyData ||
                  !filterMyData.id ||
                  (typeof filterMyData === 'object' && Object.keys(filterMyData).length === 0)}
                style={{
                  opacity: !filterMyData ||
                    !filterMyData.id ||
                    (typeof filterMyData === 'object' && Object.keys(filterMyData).length === 0) ? 0.6 : 1
                }}
              >
                See saved differentiations
              </Button>
              <Button
                // onClick={handleCreateNewLesson}
                onClick={async () => {
                  {/*----------------------------------Remove limit for one month -----------------------*/ }
                  // if (filterMyData?.limit >= 2) {
                  //   setAlertSakeBar({
                  //     open: true,
                  //     message:
                  //       "you've reached the limit for creating Differentiation on this page today.",
                  //     severity: "error",
                  //   });
                  //   return;
                  // }
                  if (filterMyData?.id) {
                    setShowReplacementMessage(true);
                  } else {
                    await onLessonAi({});
                  }
                }}
              >
                New differentiation
              </Button>
            </ModalSuperPlane>
          </div>
        )}
        {showReplacementMessage && filterMyData?.id && (
          <div className="allCreateLession">
            <div className="creatLessionSection">
              <p>
                By creating a new lesson, your saved lesson will be replaced
              </p>
              <div className="createBtnSection">
                <Button onClick={handleMyLession}>I agree</Button>
                <Button onClick={() => setShowReplacementMessage(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        {showModal && (
          <CreateDifferentiationModal open={createLesson} loader={loader} />
        )}
      </>
    </>
  );
};

export default Differentiation;
