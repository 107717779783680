//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { EnterOutlined } from '@ant-design/icons';
import { shape } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

//  -- Components ---------------------
import { Input as InputArea } from 'antd';

//  -- Constants ----------------------
import { Button, IconButton, Modal } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import { Add, Close, Delete } from "@material-ui/icons";
import Alert from '@material-ui/lab/Alert';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteModal } from "components/Dialog";
import { filter, get, map, sortBy } from "lodash";
import { useDispatch } from 'react-redux';
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentUser } from 'store/users/selectors';
import { useVocabulary } from 'store/vocabulary/selectors';
import { handleCreateVocabulary, handleCreateVocabularyAI, handleDeleteVocabulary, handleListVocabularyNew, setDataVocabular , handleGenerateVocabularyWithAI } from 'store/vocabulary/thunks';
import Loading from './Loading';
import TextReader from './Reader';
import { useAudio } from 'utils/context/AudioProvider';



const ModalContent = styled.div`
  left: 50%;
  max-width:100vw;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
`;

const ActionText = styled.p`
    font-size:14px;
    color:white;
    font-weight:500;
`;
const StyledTable = styled.table`
  width: 100%; 
  overflow-y: auto;
  max-height: 550px; 
  border-collapse: collapse; 
  td {
    border: 0.5px solid #fff; 
  }
  th {
    border: 0.5px solid #fff; 
  }
`;
const StyledHeader = styled.tr`
    position: sticky;
    top: 0;
    background-color: #fff; 
    z-index: 10;
`;
const StyledTh= styled.th`
    background: #5d4187;
    color: #ffffff;
`

const Vocabilary = ({ pageId, bookId, style, isCheckingOpen, setVocModal, vocabilaryButtonClassName="" , isVocabulary=false }) => {
    const currentUser = useCurrentUser()
    const currentClass = useCurrentClass()
    const bookVocabulary = useVocabulary()
    const [alertSakeBar, setAlertSakeBar] = useState({ open: false, message: '', severity: "success" })
    const dispatch = useDispatch()
    const [openVocabulary, setVocabulary] = useState(false)
    const [vocabularyPage, setVocabularyPage] = useState(true)
    const [vocabularyMy, setVocabularyMy] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteVocab, setDeleteVocab] = useState({});
    const [addVocabData, setAddVocabData] = useState({});
    const [showWordLoader, setShowWordLoader] = useState(false)
    const [deleteVoc, setDeleteVoc] = useState(false);
    const [vocabularyAI, setVocabularyAI] = useState();
    const [getBooksVocab, setGetBooksVocab] = useState([]);
    const [getBooksVocabStd, setGetBooksVocabStd] = useState([]);
    const [newItem, setNewItem] = useState({ isEditable: true });
    const vocabdomRef = useRef()
    useEffect(() => {
        if (isCheckingOpen) {
            setVocabulary(true);
        }
        else {
            setVocabulary(false);
        }
    }, [isCheckingOpen])
    useEffect(() => {
        if (currentUser.role == 'student') {

            const filterVocab = filter(bookVocabulary, obj => obj.chapter == bookId && obj.page == pageId && obj.created_by == 'student' && obj.user_id == currentUser?.id);
            setGetBooksVocabStd(filterVocab)
        }
        const filterVocab = filter(bookVocabulary, obj => obj.chapter == bookId && obj.page == pageId);
        // const filteredVocabClass = filter(filterVocab, obj => obj.class_id == get(currentClass, "id") || obj.class_id == null);

        setGetBooksVocab(filterVocab)
    }, [bookVocabulary, bookId, pageId])


    const onFilterVocabularyStd = () => {
        let sorted_obj = sortBy(bookVocabulary,
            [
                function (o) {
                    if (o.data && typeof o.data.word === 'string') {
                        return o.data.word.toLowerCase();
                    }
                    return '';
                }
            ]);
        return sorted_obj;

    }


    useEffect(() => {
        setTimeout(() => {
            if (addVocabData.word == undefined) {
            } else {
                addVocabulary()
            }
        }, 2000)

    }, [vocabularyAI])



    useEffect(() => {
        const data = {
            user_id: get(currentUser, "id", ''),
            created_by: 'owe_admin'
        }
        const dataStd = {
            class_id: get(currentClass, "id", ''),
            created_by: 'owe_admin'
        }
        dispatch(handleListVocabularyNew(currentUser.role == 'student' ? dataStd : data, bookId, pageId, currentUser?.id))
    }, [])

 


    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: '', severity: 'success' })
    }
    const handleVocabularyClose = () => {
        if(setVocModal){
            setVocModal(false);
        }
        setVocabulary(false);
        setAddVocabData({})
        setVocabularyAI({})
        const dataToSend = getBooksVocab.filter(item => !item.isEditable);
        setGetBooksVocab(dataToSend)
        dispatch(setDataVocabular(onFilterVocabularyStd()))
    }

    const handleVocabularyPage = () => {
        setVocabularyPage(true)
        setVocabularyMy(false)
    }
    const handleVocabularyMy = () => {
        setVocabularyPage(false)
        setVocabularyMy(true)
    }


    const handleUpdateData = async (data) => {
        let datas = getBooksVocab.find(item => item.id === data?.id);
        await dispatch(handleCreateVocabulary(datas));
        setAlertSakeBar({
            open: true,
            message: "Updated Succesfully",
            severity: "success",
        })
    }

    const handleUpdateDataStd = async (data) => {
        let datas = getBooksVocabStd.find(item => item.id === data?.id);
        await dispatch(handleCreateVocabulary(datas));
        setAlertSakeBar({
            open: true,
            message: "Updated Succesfully",
            severity: "success",
        })
    }

    const onDeleteVocab = async () => {
        await dispatch(handleDeleteVocabulary(deleteVocab?.id));
        setDeleteModal(false);
        setDeleteVoc(false);
    }
    function editDataProperty(targetId, newValue, propertyName) {
        const itemToEdit = getBooksVocab.find(item => item.id === targetId);
        const updatedData = getBooksVocab.map((item) =>
            item.id === targetId
                ? {
                    ...item,
                    data: {
                        ...item.data,
                        [propertyName]: newValue,
                    },
                }
                : item
        );
        if (itemToEdit) {
            itemToEdit.data[propertyName] = newValue;
        }
        setGetBooksVocab(updatedData)
    }

    function editDataPropertyStd(targetId, newValue, propertyName) {
        const itemToEdit = getBooksVocabStd.find(item => item.id === targetId);
        const updatedData = getBooksVocabStd.map((item) =>
            item.id === targetId
                ? {
                    ...item,
                    data: {
                        ...item.data,
                        [propertyName]: newValue,
                    },
                }
                : item
        );
        if (itemToEdit) {
            itemToEdit.data[propertyName] = newValue;
        }
        setGetBooksVocabStd(updatedData)
    }

    const handleAddVocData = (key, value) => {
        if (key == 'word') {
            const v = addVocabData;
            addVocabData[key] = value;
            setAddVocabData(v);
        } else {
            const v = vocabularyAI;
            vocabularyAI[key] = value;
            setVocabularyAI(v);
        }
    }
    const addVocabulary = (vocabData) => {
        const dataToAdd =  vocabData?vocabData:vocabularyAI
        const dataVocab = {
            book_id: bookId,
            page: pageId,
            chapter: bookId,
            created_by: currentUser?.role,
            user_id: get(currentUser, 'id', ''),
            class_id: get(currentClass, 'id', ''),
            data: {
                word: addVocabData.word,
                ...dataToAdd
            },
        };
        const result = dispatch(handleCreateVocabulary(dataVocab));
        setAlertSakeBar({
            open: true,
            message: "Created Succesfully",
            severity: "success",
        })
        if (result) {
            setAddVocabData({})
            setVocabularyAI({})
        }
    }

    const callVocAI = async () => {
        if (addVocabData?.word) {
            setShowWordLoader(true);
            const result = await dispatch(handleGenerateVocabularyWithAI({
                topic: addVocabData?.word,
                book_id: bookId,
                page: pageId,
                chapter: bookId,
                created_by: currentUser?.role,
                user_id: get(currentUser, 'id', ''),
                class_id: get(currentClass, 'id', '')
            }));
           
        if (result&&!result.error) {
            setAlertSakeBar({
                open: true,
                message: "Created Succesfully",
                severity: "success",
            })
            vocabdomRef.current.scrollTo(0, vocabdomRef.current.scrollHeight)
        }else{
            setAlertSakeBar({
                open: true,
                message: "No Result Found",
                severity: "error",
            })
        }
        setShowWordLoader(false);
    }
    }

    const addNewItem = () => {
        const isEditableItemExists = getBooksVocab.some(item => item.isEditable);
        if (!isEditableItemExists) {
            const updatedData = [...getBooksVocab, newItem];
            setGetBooksVocab(updatedData);
        }
    };

    const addNewItemForStd = () => {
        const isEditableItemExists = getBooksVocabStd.some(item => item.isEditable);
        if (!isEditableItemExists) {
            const updatedData = [...getBooksVocabStd, newItem];
            setGetBooksVocabStd(updatedData);
        }
    };
    const { stopAudio } = useAudio();

    
  useEffect(()=>{
      if(!openVocabulary){
        stopAudio();
      }
  },[openVocabulary])
    return (<>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertSakeBar.open}
            autoHideDuration={3000}
            onClose={closeAlert}>
            <Alert onClose={() => { setAlertSakeBar({ open: false, message: '' }) }} severity={alertSakeBar.severity}>
                <strong>ALERT:</strong> {alertSakeBar.message}
            </Alert>
        </Snackbar>

        <DeleteModal deleteToggle={deleteModal} loading={deleteVoc} onDelete={() => {
            onDeleteVocab();
            setDeleteVoc(true);
        }}
            setDeleteToggle={setDeleteModal}
            message={`Are you sure to remove this "${deleteVocab?.data?.word}" Vocabulary`} />

        <Button style={{borderRadius:"0px !important" , minWidth: "unset",height: "36px", marginLeft: currentUser?.role === 'student' && 0 , ...(style?style:{})}}
            onClick={() => setVocabulary(!openVocabulary)}
            // className={`vocabularyBtn vocDeskTab scheduledWeb ${vocabilaryButtonClassName}`}
            className={`${isVocabulary ? 'videoSectionVocab' : `vocabularyBtn vocDeskTab scheduledWeb ${vocabilaryButtonClassName}`}`}
            >vocabulary</Button>
        <Modal className='vocabulModal' open={openVocabulary} onClose={handleVocabularyClose}
            style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}>
            <ModalContent className='vocabulContents'>

                <Close color="secondary" onClick={handleVocabularyClose} className='vocabulClose' />
                <div className='VocabularyTab FirstVocabularyTab'>
                    <Button onClick={handleVocabularyPage}>Page Vocabulary</Button>
                    <Button style={{ display: 'none' }}></Button>
                    {get(currentUser, "role") === "student" && <Button onClick={handleVocabularyMy}>My Vocabulary</Button>}
                </div>
                <div className='vocabulTableUpr' style={{ maxHeight: 550, overflowY: 'scroll' }} ref={vocabdomRef}>
                    {vocabularyPage &&
                        <StyledTable border className='vocabulTable'>
                            <StyledHeader className='vocabulHeadTh'>
                                <StyledTh  style={{ minWidth:150, maxWidth:250 }}>Word</StyledTh>
                                <StyledTh  style={{ minWidth:150}}>English translation</StyledTh>
                                <StyledTh>Definition</StyledTh>
                                <StyledTh style={{ minWidth:200 }}>Synonymos</StyledTh>
                                <StyledTh style={{ minWidth:200 }}>Antonyms</StyledTh>
                                <StyledTh>Sentence in Spanish</StyledTh>
                                {/* <th>Sentence in English</th> */}
                                <StyledTh>Actions</StyledTh>
                            </StyledHeader>
                            {map(getBooksVocab, (value, k) => {
                                let data = get(value, 'data', '')
                                if (value?.created_by == 'student') return
                                return (
                                    <tr className='vocabulHeadTr vocIcons' key={k}>
                                        <td  style={{ minWidth:150, maxWidth:250}}>
                                            {value?.isEditable == true ? (
                                                <div style={{ position: 'relative' }}>
                                                    <InputArea
                                                        onPressEnter={() => {
                                                            if(!showWordLoader){
                                                                callVocAI();                             
                                                            } 
                                                            
                                                        }}
                                                        onChange={(d) => { handleAddVocData('word', d.target.value) }}
                                                        placeholder='word'
                                                        autoSize
                                                        autoFocus
                                                        style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200 }}
                                                    />
                                                    <div style={{ position: 'absolute', top: 6, right: 4 }}>
                                                        {showWordLoader ? <Loading size={17} /> : <EnterOutlined disabled={showWordLoader} onClick={() => {
                                                             if(!showWordLoader){
                                                                callVocAI();                                                             
                                                            } 
                                                            
                                                        }} style={{ fontSize: '25px' }} />}
                                                    </div>
                                                </div>
                                            ) : (<>
                                               <TextReader stopAudio={stopAudio}  text={get(data, 'word', '')} language={"es-ES"} style={{ float:"right"}} />
                                                <InputArea
                                                    disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' || value?.created_by === 'teacher')}
                                                    value={get(data, 'word', '')}
                                                    onChange={(d) => editDataProperty(value.id, d.target.value, 'word')}
                                                    placeholder='word'
                                                    autoSize
                                                    autoFocus
                                                    style={{ width: 150, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200 }}
                                                />
                                               
                                              </>
                                            )}
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio}  text={get(data, 'english_translation', '')} language={"en-US"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateData(value)
                                                }}
                                                disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' && value?.created_by === 'teacher')}
                                                value={get(data, 'english_translation', '')}
                                                onChange={(d) => editDataProperty(value.id, d.target.value, 'english_translation')}
                                                autoSize
                                                style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                            
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio}  text={get(data, 'definition', '')} language={"es-ES"} style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateData(value)
                                                }}
                                                disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' && value?.created_by === 'teacher')}
                                                value={get(data, 'definition', '')}
                                                onChange={(d) => editDataProperty(value.id, d.target.value, 'definition')}
                                                autoSize
                                                style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio}  text={get(data, 'synonyms', '')} language={"es-ES"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateData(value)
                                                }}
                                                disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' && value?.created_by === 'teacher')}
                                                value={get(data, 'synonyms', '')}
                                                onChange={(d) => editDataProperty(value.id, d.target.value, 'synonyms')}
                                                autoSize
                                                style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio} text={get(data, 'antonyms', '')} language={"es-ES"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateData(value)
                                                }}
                                                disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' && value?.created_by === 'teacher')}
                                                value={get(data, 'antonyms', '')}
                                                onChange={(d) => editDataProperty(value.id, d.target.value, 'antonyms')}
                                                autoSize
                                                style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                        </td>
                                        <td
                                        >
                                            <TextReader stopAudio={stopAudio}  text={get(data, 'sentence', '')} language={"es-ES"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateData(value)
                                                }}
                                                disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' && value?.created_by === 'teacher')}
                                                value={get(data, 'sentence', '')}
                                                onChange={(d) => editDataProperty(value.id, d.target.value, 'sentence')}
                                                autoSize
                                                style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />   
                                        </td>
                                        {/* <td
                                        >
                                            <TextReader text={get(data, 'sentence_english', '')} language={"en-US"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateData(value)
                                                }}
                                                disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' && value?.created_by === 'teacher')}
                                                value={get(data, 'sentence_english', '')}
                                                onChange={(d) => editDataProperty(value.id, d.target.value, 'sentence_english')}
                                                autoSize
                                                style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />   
                                        </td> */}
                                        {get(currentUser, "role") == "student" ? (
                                            <>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <ActionText>
                                                            {value?.created_by === 'owe_admin' ? 'Admin' : 'Teacher'}
                                                        </ActionText>
                                                    </div>
                                                </td>
                                            </>

                                        ) : (
                                            <>
                                                {value?.created_by !== 'owe_admin' ? (

                                                    <td style={{ textAlign: 'center' }}>

                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Delete style={{ fontSize: '20px' }} onClick={() => {
                                                                setDeleteModal(true);
                                                                setDeleteVocab(value);
                                                            }} />
                                                        </div>
                                                    </td>
                                                )
                                                    :
                                                    <td style={{ textAlign: 'center' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <ActionText>
                                                                Admin
                                                            </ActionText>
                                                        </div>
                                                    </td>
                                                }
                                            </>
                                        )}
                                    </tr>
                                )
                            })}
                            {get(currentUser, "role") !== "student" && (
                                <tr className='vocabulHeadTr'>
                                    <td><IconButton>
                                        <Add style={{ fontSize: '18px' }} onClick={() => {
                                            addNewItem()
                                        }} />
                                    </IconButton>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </StyledTable>
                    }

                    {vocabularyMy && get(currentUser, "role") === "student" &&
                        <StyledTable border className='vocabulTable'>
                            <StyledHeader className='vocabulHeadTh'>
                                <StyledTh style={{ minWidth:150, maxWidth:250 }}>Word</StyledTh>
                                <StyledTh style={{ minWidth:150}}>English translation</StyledTh>
                                <StyledTh>Definition</StyledTh>
                                <StyledTh style={{ minWidth:200 }} >Synonymos</StyledTh>
                                <StyledTh style={{ minWidth:200 }}>Antonyms</StyledTh>
                                <StyledTh>Sentence in Spanish</StyledTh>
                                {/* <th>Sentence in English</th> */}
                                <StyledTh>Actions</StyledTh>
                            </StyledHeader>
                            {map(getBooksVocabStd, (value, k) => {
                                let data = get(value, 'data', '')
                                return (
                                    <tr className='vocabulHeadTr vocIcons' key={k}>
                                         <td  style={{ minWidth:150, maxWidth:250}}>
                                            {value?.isEditable == true ? (
                                                <div style={{ position: 'relative' }}>
                                                    <InputArea
                                                        onPressEnter={() => {
                                                            if(!showWordLoader){
                                                            callVocAI();                                             
                                                            }
                                                        }}
                                                        onChange={(d) => { handleAddVocData('word', d.target.value) }}
                                                        placeholder='word'
                                                        autoSize
                                                        autoFocus
                                                        style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200 }}
                                                    />
                                                    <div style={{ position: 'absolute', top: 6, right: 4 }}>
                                                        {showWordLoader ? <Loading size={17} /> : <EnterOutlined disabled={showWordLoader} onClick={() => {
                                                            if(!showWordLoader){
                                                                callVocAI();       
                                                            }
                                                        }} style={{ fontSize: '25px' }} />}
                                                    </div>
                                                </div>
                                            ) : (<>
                                             <TextReader stopAudio={stopAudio}  text={get(data, 'word', '')} language={"es-ES"} style={{ float: "right" }} />
                                                <InputArea
                                                    disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' || value?.created_by === 'teacher')}
                                                    value={get(data, 'word', '')}
                                                    onChange={(d) => editDataPropertyStd(value.id, d.target.value, 'word')}
                                                    placeholder='word'
                                                    autoSize
                                                    style={{ width: 150, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200 }}
                                                />
                                               
                                            </>
                                            )}
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio}  text={get(data, 'english_translation', '')} language={"en-US"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateDataStd(value)
                                                }}
                                                value={get(data, 'english_translation', '')}
                                                onChange={(d) => editDataPropertyStd(value.id, d.target.value, 'english_translation')}
                                                autoSize
                                                style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />     
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio}  text={get(data, 'definition', '')} language={"es-ES"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateDataStd(value)
                                                }}
                                                value={get(data, 'definition', '')}
                                                onChange={(d) => editDataPropertyStd(value.id, d.target.value, 'definition')}
                                                autoSize
                                                style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                           
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio}  text={get(data, 'synonyms', '')} language={"es-ES"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateDataStd(value)
                                                }}
                                                value={get(data, 'synonyms', '')}
                                                onChange={(d) => editDataPropertyStd(value.id, d.target.value, 'synonyms')}
                                                autoSize
                                                style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                            
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio} text={get(data, 'antonyms', '')} language={"es-ES"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateData(value)
                                                }}
                                                disabled={value?.created_by === 'owe_admin' || (currentUser?.role === 'student' && value?.created_by === 'teacher')}
                                                value={get(data, 'antonyms', '')}
                                                onChange={(d) => editDataProperty(value.id, d.target.value, 'antonyms')}
                                                autoSize
                                                style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                        </td>
                                        <td>
                                        <TextReader stopAudio={stopAudio} text={get(data, 'sentence', '')} language={"es-ES"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateDataStd(value)
                                                }}
                                                value={get(data, 'sentence', '')}
                                                onChange={(d) => editDataPropertyStd(value.id, d.target.value, 'sentence')}
                                                autoSize
                                                style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                        </td>
                                        {/* <td>
                                        <TextReader text={get(data, 'sentence_english', '')} language={"en-US"}  style={{ float:"right"}} />
                                            <TextArea
                                                onBlur={() => {
                                                    handleUpdateDataStd(value)
                                                }}
                                                value={get(data, 'sentence_english', '')}
                                                onChange={(d) => editDataPropertyStd(value.id, d.target.value, 'sentence_english')}
                                                autoSize
                                                style={{ width: 200, backgroundColor: 'transparent', borderColor: 'transparent', color: 'white', fontWeight: 200, padding: 0 }}
                                            />
                                        </td> */}
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Delete style={{ fontSize: 20 }} onClick={() => {
                                                    setDeleteModal(true);
                                                    setDeleteVocab(value);
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr className='vocabulHeadTr'>
                                <td><IconButton>
                                    <Add style={{ fontSize: '18px' }} onClick={() => {
                                        addNewItemForStd()
                                    }} />
                                </IconButton>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </StyledTable>
                    }
                </div>
            </ModalContent>
        </Modal>

    </>);
};

//  -- Prop types ---------------------
Vocabilary.propTypes = {
    data: shape({}),
};

//  -- Default props ------------------
Vocabilary.defaultProps = {};

export default Vocabilary;


