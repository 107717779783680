import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import BottomDropDown from "utils/assets/images/resource-dropdown.svg";
import ClickOutside from "components/OnClickOutside";

const CategoryMenu = styled.div`
  background-color: #0b1c2b;
  border-radius: 30px;
  color: #fff;
  // width: 201px;
  height: 42px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  // text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #2784a3;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 768px) {
    // width: 100% !important;
    height: 36px;
    color: #fff;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const PaddedBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 8px;
  text-align: left;
  width: 100%;
`;

export const ApThemeDropDown = ({
  data,
  open,
  dropDownHandler,
  themeFilter,
  closeDropDown,
  handleDropdownClick
}) => {
  const createHeaderRow = () => (
    <div className="d-grid grid-2-column">
      {["AP Spanish", "AP Spanish Literature"].map((title, index) => (
        <div key={index} className="flex-1" disabled>
          <div className={`pl-30px ${index === 1 ? "pl-0" : ""}`}>
            <div className="whitespace-nowrap w-250px md-w-unset ap-spanish-label border-b-lightGrey p-18px">
              {title}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const createMenuItem = (ct, index) => (
    <PaddedBtn
      className="d-grid grid-2-column ib-theme-button"
      style={{ padding: 0, height: "60px" }}
    >
      {["", "2"].map((suffix, i) => {
        const title = ct[`title${suffix}`];
        const icon = ct[`${suffix ? "icon" : "icon"}`];
        return (
          <Tooltip key={i} placement="top" title={title}>
            <div
              onClick={() => dropDownHandler("apThemeFilter", title)}
              className="border-for-inner dropdown-menus-item cursor-pointer h-full d-flex items-center w-full"
              style={{ paddingLeft: "20px" }}
            >
              <input
                className="w-16px-h-16px ap-theme-dropdown-checkmark"
                type="checkbox"
                checked={themeFilter?.includes(title)}
              />
              <img
                src={icon}
                loading="lazy"
                className="image-dropdown-ancodtas mdm-d-none"
                alt=""
              />
              <div className="whitespace-nowrap dropdown-list-name-ap-theme">
                {title}
              </div>
            </div>
          </Tooltip>
        );
      })}
    </PaddedBtn>
  );

  return (
    <>
      <CategoryMenu
        style={{ border: "1px solid #ffffff" }}
        onClick={() => handleDropdownClick("AP THEMES")}
        className="cursor-pointer box-shadow-resource-hover md-w-full-2 w-173px lg-w-157px arrow-contanier-resoruce category-dropdown-resource h-43px lg-h-36px"
      >
        <Tooltip placement="top" title={themeFilter?.join(", ").toString()}>
          <span className="whitespace-nowrap overflow-hidden dropdown-text lg-pl-17px pl-16px w">
            {themeFilter && themeFilter !== "" && themeFilter?.length > 0
              ? themeFilter.join(",").length > 20
                ? themeFilter.join(",").slice(0, 20) + "..."
                : themeFilter
              : "AP THEMES"}
          </span>
        </Tooltip>

        <div className="dropdown-resource-arrow-container">
          <div
            style={{
              transition: "transform 0.4s ease-in-out",
              transform:
                open
                  ? "rotate(-180deg)"
                  : "rotate(0deg)",
            }}
          >
            <img src={BottomDropDown} alt="" />
          </div>
        </div>
      </CategoryMenu>
      {open && (
        <ClickOutside onClickOutside={closeDropDown} className="ap-theme-dropdown bg-dropdown-for-resource top-59px  box-shadow-dropdown-resource border-radius-14px absolute z-index-990">
          {createHeaderRow()}
          {data?.map(createMenuItem)}
        </ClickOutside>
      )}
    </>
  );
};
