//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Assignment, Close, Extension, WatchLater } from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import { TootipWrapper } from "components/TooltipWrapper";
import _, { find, get, map } from 'lodash';
import moment from 'moment';
import { lighten } from 'polished';
import { arrayOf, shape } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Birdimg, Calendarimg } from 'utils/assets/images';
//  -- Thunks -------------------------
import { handleCreatePastSubmission } from 'store/pastSubmission/thunks';
import { handleUpdateSubmission } from 'store/submissions/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import { Modal } from '@material-ui/core';
import Loading from 'components/Loading';
import RetakeModal from "components/RetakeModal";
import "moment-timezone";
import { Img } from 'react-image';
import { useBookUnits } from "store/booksUnits/selectors";
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentOrgPermission, useOrgTimezone } from 'store/organizations/selectors';
import { useResources, useVideos } from 'store/resources/selectors';
import { useServertime } from 'store/servertime/selectors';
import { useStory } from "store/story/selectors";
import { BACK_TO_BOOK } from 'utils/config/reference';
import COLOR from 'utils/constants/color';
import { generateUrlForNonEbook, getAssignmentLable, getBookDisplayName, getExceptionStatus, getStoryUrl, getUserAssignment, imageFetch, imageFetchFromPodcast, isVideo } from 'utils/lib/ebookHelper';
import { isBeforTime } from "utils/methods/math";
import useMobileDeviceDetection from 'containers/chat/hooks';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const AssignmentContainer = styled.div`
    border: 1px solid #F4F5F7;
    height: 100%;
    min-height: 160px;
    padding 8px 4px;
`;

const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
`;

const ContainerDay = styled.div`
    border-bottom: 4px solid #03989e82;
    border-right: 2px solid #03989e82;
    border-left: 2px solid #03989e82;
    flex: 1;
    margin-bottom: 16px;
    margin-right: 8px;
    min-width: 124px;

    &:last-child {
        margin-right: 0px;
    }
`;
const CalendarDay = styled.div`
    background-color:${p => p.isToday ? COLOR.orange100 : "#5f4c82"};
    border:3px solid #c5a1cf;
    border-radius: 15px;
    padding: 10px;
    position: relative;
    flex: 1;
    margin-bottom: 16px;
    margin-right: 8px;
    min-width: 124px;
    min-height: 180px;
    &:last-child {
        margin-right: 0px;
    }
`;
const HeaderRow = styled.div`
    flex-direction: row;
    display: flex;
`
const CalendarIcon = styled.div`
    flex: 1;
    background-image: url(${Calendarimg});
    background-repeat: no-repeat;
    background-size: 200% 200%;
    height: 20px;
    background-position: center;
`
const Birtdimg = styled.img`
    height: 180px;
    width: 220px;
    position: absolute;
    top: -80px;
    left: -110px;
    z-index: -1;
    background-color: transparent;
    user-select: none;
`
const DayTxt = styled.div`
    flex: 6;
    color: white;
    font-size: 20px;`
const ControlRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const CustomRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const Date = styled.span`
    color:#fff;
    display: block;
    font-size: 11px;
    line-height: 10px;
    margin-bottom: 6px;
`;

const DayOfWeek = styled.span`
    background-color: #03989e82;
    border-radius: 2px;
    color: ${COLOR.black};
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    padding: 12px 12px;
`;

const Header = styled.span`
    color: ${COLOR.black600};
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
`;

const HeaderBold = styled(Header)`
    color: ${COLOR.black100};
`;

const Item = styled.div`
    align-items: center;
    background-color: ${p => p.deactivated ? COLOR.redishpink : p.disabled ? COLOR.green900 : (p.past ? lighten(0.4, 'red') : COLOR.white)};
    border: 1px dashed ${p => (p.deactivated || p.disabled || p.past) ? COLOR.white : COLOR.black700};
    border-radius: 2px;
    color: ${p => (p.deactivated || p.disabled) ? COLOR.white : COLOR.black200};
    cursor: ${p => p.past ? 'not-allowed' : 'pointer'};
    display: flex;
    flex-direction: row;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 4px;
    position: relative;
    text-transform: capitalize;
    transition: all 450ms ease;
    width: 100%;

    @media (min-width: 1000px) {
        &:hover {
            background-color: ${p => !p.past && '#f9f9f9'};
            color: ${p => !p.past && COLOR.green};
            box-shadow: ${p => !p.past && '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'};
        }
    }
`

const Wrapper = styled.div`
    width: 100%;
`;
const NoUpcoomingText = styled.div`
    color: ${COLOR.radish100};
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
    margin-bottom: 50px;
`;
const ItemTab = styled.button`
    height:100%;
    width:80%;
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    padding:8px;
    cursor: pointer;
`;
const ItemTab2 = styled.button`
    height:100%;
    width:10%;
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    padding:8px;
    cursor: pointer;
`;
const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;
const ImageModal = styled.div`
    left: 50%;
    max-width: 450px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const ModalDescription = styled.div`
    background: #E7E8EA;
    display: flex;
    padding: 5px;
    flex-direction: column;
    position:relative;
`;
const ModelDescRow = styled.div`
  font-size:12px;
  text-align: center;
  font-weight: bold;
`;
const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    color: white;
    z-index: 1;
`;
const AssignmentNameText = styled.span`
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Calendar
//
//  -- Description
//  Calendar of events for quick view
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const Calendar = ({
    assignments,
    submissions,
    booksAllList,
    userBooks,
    currentUser
}) => {
    const deviceType = useMobileDeviceDetection();
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = _.get(orgPermissions, "books", [])
    const servertime = useServertime()
    const timezone = useOrgTimezone()
    const story = useStory()
    const [modalOpen, setModalState] = useState(false);
    const [activeAssignment, setActiveAssignment] = useState(null)
    const [isRetakeOpen, setisRetakeOpen] = useState(false);
    const currentClass = useCurrentClass()
    const [assignmentData, setAssignmentData] = useState({ data: null, submission: null, retakeAvailable: null })
    const userAssignment = getUserAssignment(userBooks, booksAllList, assignments, currentUser, bookPermissions,currentClass)
    const resources = useResources()
    const videos = useVideos()
    const dispatch = useDispatch();
    const history = useHistory();
    const bookUnits = useBookUnits()
    const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [selectedDay, setSelectedDay] = useState(moment());
    const weekStart = selectedDay.clone().startOf('isoWeek');
    const generateDays = () => {
        const days = [];

        for (let i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days'))
        }

        return days;
    };
    const WEEK = generateDays();

    const generateUrl = (CHAPTER_ID, page, activity, quiz,is_graphic_organizer) => {
        const storyUrl = getStoryUrl(story, CHAPTER_ID, page, quiz, activity)
        const backToLabel = BACK_TO_BOOK[CHAPTER_ID]
        const BALCON_PAGES = _.get(_.find(bookUnits, ["bookId", CHAPTER_ID]), "pages", [])
        const bookitem = _.find(BALCON_PAGES, k => parseInt(k.start) <= page && parseInt(k.finish) >= page)
        const url = generateUrlForNonEbook(bookitem?.id, page, activity, quiz, backToLabel, null, CHAPTER_ID,is_graphic_organizer)
        if (url && backToLabel) {
            return url;
        }
        else if (storyUrl) {
            return storyUrl;
        } else {
            return null;
        }
    }
    const graphicOrganizationRedirect = (data) => {
        if (data?.data.activity_type === "graphic_organizer") {
            history.push(`/ebook?chapter=${data?.data?.chapter}&page=${data?.data?.page}&assignment_type=graphic_organizer&id=${data.id}`);
            return true;
        }
        return false;
    }
    const onClick = async (data, submission, retakeAvailable) => {
        const chapter = _.get(data, "data.chapter", "")
        const assignmentId = _.get(data, "data.id")
        const assignmentPage = _.get(data, "data.page", 0)
        const typeId = _.get(data, "data.typeId", "")
        const is_graphic_organizer = _.get(data, "data.type") === "graphic_organizer"
        const generatedUri = generateUrl(chapter, assignmentPage, typeId.includes('activity') ? assignmentId : null, typeId.includes('quiz') ? assignmentId : null,is_graphic_organizer)
        if (submission?.complete && !retakeAvailable) {
            return;
        } else if (submission?.complete && retakeAvailable) {
            await dispatch(handleCreatePastSubmission({  submissionId: submission?.id  }))
            // await dispatch(handleUpdateSubmission(submission?.id, {
            //     ...submission,
            //     complete: false,
            //     retaking: true,
            //     answers: [],
            //     graded: false,
            //     feedback: '',
            //     audioFeedback: null,
            //     timmer: 0
            // }))
            if (isVideo(get(data, "data.chapter"), resources)) {
                redirectToAssignmentVideo(get(data, "data.chapter"), get(data, "data.id"));
            }
            else if (generatedUri) {
                history.push(generatedUri)
            }
            else {
                if(!graphicOrganizationRedirect(data)){
                    history.push(`/ebook?chapter=${data?.data?.chapter}&page=${assignmentPage}&activity=${data?.data.typeId === 'activity' ? assignmentId : null}&quiz=${data?.data.typeId === 'quiz' ? assignmentId : null}`);
                }
            }
        } else {
            if (isVideo(get(data, "data.chapter"), resources)) {
                redirectToAssignmentVideo(get(data, "data.chapter"), get(data, "data.id"));
            } else if (generatedUri) {
                history.push(generatedUri)
            }
            else {
                if(!graphicOrganizationRedirect(data)){
                    history.push(`/ebook?chapter=${data?.data?.chapter}&page=${assignmentPage}&activity=${data?.data.typeId === 'activity' ? assignmentId : null}&quiz=${data?.data.typeId === 'quiz' ? assignmentId : null}`);
                }
            }
        }

    };
    const redirectToAssignmentVideo = (id, quizId) => {
        // const getUri = (q, a) => (`video?id=${id}&quiz=${q}&activity=${a}&link=${search.link}`)
        let url = `/video?id=${id}&quiz=${quizId}`
        history.push(url);
    }
    const getNumberMask = (chapter, _page) => {
        let book = find(booksAllList, { id: chapter })
        if (book) {
            let numberMask = find(book.pages, k=>k.pageId == _page)
            if (numberMask) {
                return numberMask.NumberMasked
            } else {
                return _page;
            }
        } else {
            return _page;
        }
    }
 
    const viewInfo = (x) => {
        setActiveAssignment(x)
        setModalState(true)
    }
    const closeViewinfo = () => {
        setModalState(false)
        setActiveAssignment(null)
    }
    const modalSrc = () => {
        return isVideo(get(activeAssignment, "data.page"), resources) ? imageFetchFromPodcast(get(activeAssignment, "data.page"), resources) : imageFetch(booksAllList, get(activeAssignment, "data.chapter"), get(activeAssignment, "data.page"))
    }

    return (
        <Wrapper>
            <RetakeModal isRetakeOpen={isRetakeOpen} onClose={() => {
                setAssignmentData({
                    data: null,
                    submission: null,
                    retakeAvailable: null
                })
                setisRetakeOpen(false)
            }}
                onSubmit={() => onClick(assignmentData.data, assignmentData.submission, assignmentData.retakeAvailable)} />
            <Modal
                open={modalOpen}
                onClose={closeViewinfo}
                style={{ height: '100%', width: '100%' }}
            >
                <ImageModal>
                    <ModalDescription>
                        <CloseBtn onClick={closeViewinfo}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 12 }} />
                        </CloseBtn>
                        <ModelDescRow>{getBookDisplayName(booksAllList, get(activeAssignment, "data.chapter"), true, videos, resources)}</ModelDescRow>
                        {/* <ModelDescRow> <strong>Name</strong>:{get(activeAssignment, "displayName", get(activeAssignment, "data.displayName", ""))}</ModelDescRow> */}
                    </ModalDescription>

                    <Img
                        style={{ height: "auto", width: "100%", border: `1px solid ${COLOR.black800}` }}
                        src={modalSrc()}
                        loader={<Loading />}
                    />
                    {/* <Image src={} alt='' /> */}
                </ImageModal>
            </Modal>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <ControlRow>
                        <Header style={{ marginLeft: 30 }}>Week <HeaderBold>{WEEK[0].format('D MMMM')} - {WEEK[6].format('D MMMM')}</HeaderBold></Header>
                        <CustomRow>
                            <ArrowLeftOutlined
                                onClick={() => setSelectedDay(moment(selectedDay).subtract(7, 'days'))}
                                style={{ color: COLOR.black500, cursor: 'pointer', marginLeft: '16px' }}
                            />
                            <ArrowRightOutlined
                                onClick={() => setSelectedDay(moment(selectedDay).add(7, 'days'))}
                                style={{ color: COLOR.black500, cursor: 'pointer', marginLeft: '8px' }}
                            />
                        </CustomRow>
                    </ControlRow>
                </Col>
            </Row>
            <Container style={{ marginTop: 16, flexDirection: deviceType ? 'column' : 'row' }}>
                {WEEK.map((day, i) => {
                    return (
                        <CalendarDay isToday={moment().isSame(day, 'day')} key={day.unix()} style={{ width: deviceType ? '100%' : 'unset' }}>
                            {i == 0 ? <Birtdimg src={Birdimg} /> : null}
                            <Date>{day.format('D MMMM')}</Date>
                            <HeaderRow>
                                <DayTxt>{DAYS[day.day()]}</DayTxt>
                                <CalendarIcon style={{ flex: deviceType ? 'none' : '1', height: deviceType ? 40 : 25, width: deviceType ? 45 : 30 }}></CalendarIcon>
                            </HeaderRow>
                            <AssignmentContainer style={{ paddingLeft: 0, paddingRight: 0, borderWidth: 0, minHeight: 0 }}>
                                {map(userAssignment, x => {
                                    const getHiddenBooks = get(currentClass,"hide_books",[]).map(data => data?.bookId) || [];
                                    if(get(currentUser,"role")=='student' && getHiddenBooks?.includes(x?.data?.bookId)){
                                        return null;
                                    }
                                    const submission = find(submissions, ['assignmentId', x.data?.id]);
                                    const { isException, exceptionStatusText } = getExceptionStatus(submission,currentUser)
                                    const hasTooltip = !isNaN(parseInt(x.timeDuration)) && parseInt(x.timeDuration) > 0
                                    const isActive = get(x, "active", true)
                                    const deactivateForStudent  = x?.deactivated_student?.includes(currentUser?.id);
                                    let isBeforeLateDate = true
                                    let retakeAvailable = false;
                                    let dueDate = x.dueDate;
                                    const diff = x.diff ? x.diff[0] : null
                                    const hasUserDiff = find(x.userDiff, ['id', currentUser?.id])
                                    if (hasUserDiff && diff) {
                                        retakeAvailable = submission ? parseInt(submission?.submissions, 10) < (parseInt(diff?.retakes, 10) + 1) : true;
                                        dueDate = diff.dueDate;
                                    } else {
                                        retakeAvailable = submission ? parseInt(submission?.submissions, 10) < (parseInt(x?.retakes, 10) + 1) : true;
                                        dueDate = x.dueDate;
                                    }
                                    if (x?.lateDate == "always") {
                                        isBeforeLateDate = false
                                    } else {
                                        isBeforeLateDate = typeof x?.lateDate == "undefined" || !x?.lateDate ? true : isBeforTime(x?.lateDate, servertime, timezone);
                                    }
                                    if (moment(dueDate).format('MDYY') === day.format('MDYY')) {
                                        const isPast = isBeforTime(dueDate, servertime, timezone)
                                        if (isPast && isBeforeLateDate) {
                                            return (<TootipWrapper label={!isActive || deactivateForStudent ? "Assignment is deactivated by teacher" : get(x, "displayName", get(x, "data.displayName", ""))} hasToolTip key={x.id}>
                                                <Item key={x.id} past onClick={e => e.preventDefault()}>
                                                    <ItemTab>
                                                        {x?.assignment?.type === 'activity'
                                                            ? <Extension style={{ height: '18px', marginRight: '4px' }} />
                                                            : <Assignment style={{ height: '18px', marginRight: '4px' }} />
                                                        }
                                                        {/* {x?.activityId ? 'Activity' : 'Quiz'} ({x?.activityId ? 'A' : 'Q'} */}
                                                        {getAssignmentLable(x,booksAllList,videos,resources)}
                                                        <br />
                                                        {!isActive || deactivateForStudent ? "(Deactivated)" : null}
                                                        {/* ) */}
                                                    </ItemTab>
                                                    <ItemTab2 onClick={() => viewInfo(x)}>
                                                        <InfoCircleFilled />
                                                    </ItemTab2>
                                                </Item>
                                            </TootipWrapper>
                                            );
                                        } else {
                                            return (
                                            <TootipWrapper 
                                            hasToolTip label={!isActive || deactivateForStudent ? "Assignment is deactivated by teacher" : (hasTooltip && !(get(submission, "complete", false) && !retakeAvailable)) ? "This activity includes a timer, are you sure you want to start it?" : isException ? `Assignment is marked as ${exceptionStatusText}` : get(x, "displayName", get(x, "data.displayName", ""))} key={x.id}>
                                                <Item
                                                    past={isException}
                                                    disabled={(submission?.complete && !retakeAvailable) || isException}
                                                    deactivated={!isActive || deactivateForStudent} >
                                                    <ItemTab
                                                        disabled={(submission?.complete && !retakeAvailable) || isException}
                                                        deactivated={!isActive || deactivateForStudent}
                                                        onClick={() => {
                                                            if (isException || !isActive || deactivateForStudent) {
                                                                return;
                                                            }
                                                            if (submission?.complete && retakeAvailable) {
                                                                setisRetakeOpen(true)
                                                                setAssignmentData({
                                                                    data: x,
                                                                    submission: submission,
                                                                    retakeAvailable: retakeAvailable
                                                                })
                                                            } else {
                                                                onClick(x, submission, retakeAvailable)
                                                            }
                                                        }}
                                                    >
                                                        {x?.data?.typeId === 'activity'
                                                            ? <Extension style={{ height: '18px', marginRight: '4px' }} />
                                                            : <Assignment style={{ height: '18px', marginRight: '4px' }} />
                                                        }
                                                        {/* {x?.activityId ? 'A' : 'Q'} */}
                                                        <AssignmentNameText>
                                                            {getAssignmentLable(x, booksAllList, videos, resources)}
                                                        </AssignmentNameText>
                                                         {!isActive || deactivateForStudent? "(Deactivated)" : (submission?.complete && retakeAvailable ? 'Retake' : (isException ? exceptionStatusText : (submission?.complete ? 'Completed' : (submission ? 'Resume' : 'Due'))))}
                                                       
                                                        {hasTooltip &&
                                                            <WatchLater style={{ height: '18px', marginLeft: '4px', marginTop: '4px' }} />
                                                        }
                                                    </ItemTab>
                                                    <ItemTab2 onClick={() => viewInfo(x)}>
                                                        <InfoCircleFilled />
                                                    </ItemTab2>
                                                </Item>
                                            </TootipWrapper>
                                            );
                                        }
                                    }
                                })}
                            </AssignmentContainer>
                        </CalendarDay>
                    )
                })}
            </Container>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
Calendar.propTypes = {
    assignments: arrayOf(shape()),
};

//  -- Default props ------------------
Calendar.defaultProps = {
    assignments: [{}],
};

export default Calendar;


// <CheckCircle style={{ height: '18px', marginRight: '4px' }} />