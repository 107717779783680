//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { ArrowForward, ArrowRight } from "@material-ui/icons";
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { PlusOutlined } from '@ant-design/icons';
import { Button as ButtonSection, Card, Col, Divider, Input, Modal, Row, Select } from 'antd';
import Loading from 'components/Loading';
//  -- Constants ----------------------
import { get, isEmpty, sortBy, filter, find } from 'lodash';
import { useDispatch } from "react-redux";
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createKeystore } from "store/keystore/thunks";
import { useCurrentOrg } from 'store/organizations/selectors';
import { v4 } from 'uuid';
import SchoolForm from "./School";
import { handleGetOrgClasses } from 'store/classes/thunks';
const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Header = styled.span`
    color: ${COLOR.black100};
    display: block;
	font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
	margin: 0px auto;
    // max-width: 480px;
    width: 100%;
`;
const StableWrapper = styled.div`
 width: 100%;
  overflow-x: auto;
//   padding:24px;
//   @media (max-width: 992px) {
//     padding:24px 0px !important;
//   }
`;
const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;
const StableWrapperMain = styled.div`
`;
const ViewSectionText = styled.p`
    color:black;
    font-size:17px;
    font-weight:400;
`;
const SectionText = styled.span`
   font-weight:600;
`;
const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
font-size: 12px;
font-weight: 600;
`;
const OverViewValue = styled.span`
font-size: 17px;
font-weight: 500;
cursor: pointer;
text-transform:capitalize;
`;
const ProfileContainer = styled.span`

`;

const ViewDemo = styled.span`
textAlign: "left";
color: ${(p) => (p.color)};
`;
const FilterContainer = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const SchoolTable = ({
    schools,
    districts,
    userloading,
    studentsByOrg,
    teacherByOrg,
    selectedDist,
    onUserAddOrUpdate = () => null,
    onTabsJump = () => null
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentOrg = useCurrentOrg()
    const [data, setData] = useState("");
    const [page, setPage] = React.useState(0);
    const [school, setSchool] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [schoolForm, setSchoolForm] = useState(false);
    const [editSchool, setEditSchool] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [loginLoader, setLoginLoader] = useState(null);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [integrationFilter, setIntegrationFilter] = useState([])
    const [showSchoolsTeachers, setShowSchoolsTeachers] = useState(-1);
    useEffect(() => {
        dispatch(handleGetOrgClasses([currentOrg, ...schools]));
    }, [])
    useEffect(() => {
        let filterSchool = [];
        if (get(selectedDist, 'id')) {
            if (get(selectedDist, 'typeId') == 'district') {
                filterSchool = filter(schools, obj => obj.parentId == get(selectedDist, 'id'))
            } else {
                filterSchool = filter(schools, obj => obj.id == get(selectedDist, 'orgId'))
            }
            setFilteredSchools(sortBy(filterSchool, ["displayName"]));
            setIntegrationFilter(sortBy(filterSchool, ["displayName"]))
        } else {
            setFilteredSchools(sortBy(schools, ["displayName"]));
            setIntegrationFilter(sortBy(schools, ["displayName"]))
        }
    }, [schools])
    const handleSearchDistrict = (event) => {
        if (event.target.value && event.target.value.length > 2) {
            var search = event.target.value;

            const filtered = schools.filter(x => {
                return (
                    `${x.displayName}`.toLowerCase().includes(search.toLowerCase())
                );
            });
            setFilteredSchools(sortBy(filtered, ["displayName"]))
        } else {
            setFilteredSchools(sortBy(schools, ["displayName"]))
            setShowSchoolsTeachers(-1);
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const goToClass = async (id, user) => {
        openLoginInNewWindow(user.id)
    };
    const openLoginInNewWindow = async (userId) => {
        setLoginLoader(userId);
        const key = v4()
        await createKeystore(userId, key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoginLoader(null);
    }
    const substract = (a, b) => {
        let _a = parseInt(a);
        let _b = parseInt(b);
        if (isNaN(_a)) _a = 0;
        if (isNaN(_b)) _b = 0;
        return _a - _b;
    };
    const addSchools = (value) => {
        const editData = {
            editSchool: true,
            school: data
        }
        const addData = {
            school: null,
            editSchool: false
        }
        if (value == 'edit') {
            history.push({ pathname: "/add-schools", state: editData });
        } else {
            history.push({ pathname: "/add-schools", state: addData });
        }
    }

    const handleChange = (value) => {
        switch (value) {
            case 'all':
                setFilteredSchools(integrationFilter);
                break;
            case 'clever':
                const filterClever = filter(integrationFilter, obj => obj.cleverId)
                setFilteredSchools(filterClever);
                break;
            case 'classlink':
                const filterClasslink = filter(integrationFilter, obj => obj.classlinkid || obj.classlinkTenantId)
                setFilteredSchools(filterClasslink);
                break;
            case 'schoology':
                const filterSchoology = filter(integrationFilter, obj => obj.schoolId)
                setFilteredSchools(filterSchoology);
                break;
            case 'canvas':
                const filterCanvas = filter(integrationFilter, obj => obj.canvas_id)
                setFilteredSchools(filterCanvas);
                break;
            default:
                break;
        }
    };

    const checkIntegration = (value) => {
        if (value.cleverId) {
            return 'Clever'
        } else if (value.classlinkid || value.classlinkTenantId) {
            return 'Classlink'
        } else if (value.canvas_id) {
            return 'Canvas'
        } else if (value.schoolId) {
            return 'Schoology'
        } else {
            return '-'
        }
    }

    return (
        <>
            {schoolForm ? (
                <StableWrapper style={{ padding: 24 }}>
                    <SchoolForm
                        currentOrg={currentOrg}
                        school={school}
                        editing={editSchool}
                        onClose={() => {
                            setSchoolForm(false);
                            setSchool(null);
                            setEditSchool(false);
                            onUserAddOrUpdate()
                        }}
                    />
                </StableWrapper>
            ) : (
                <StableWrapperMain>
                    <Row style={{ marginBottom: 15 }}>
                        <Col>
                            <ButtonSection style={{ minHeight: 38 }} type="primary" onClick={() => addSchools()}>
                                <PlusOutlined /> Add Schools
                            </ButtonSection>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={24}>
                            <ViewSectionText>
                                View <SectionText>Schools</SectionText> records.
                            </ViewSectionText>
                        </Col>
                        <Col xs={24} lg={24}>
                            <FilterContainer className="FilterHeading">
                                <Card
                                    type="inner"
                                    title="Filters"
                                    // extra={<a onClick={() => {
                                    //     console.log('clicked!!!!!!')
                                    // }} >Clear</a>}
                                >
                                    <Row>
                                        <Col xs={24} lg={6} md={12} className="ColCardPadding">
                                            <Card style={{ width: '100%' }}>
                                                <Select
                                                    bordered={false}
                                                    defaultValue="INTEGRATIONS"
                                                    style={{ width: '100%', fontSize: 13 }}
                                                    onChange={handleChange}
                                                    options={[
                                                        { value: 'all', label: 'All' },
                                                        { value: 'clever', label: 'Clever' },
                                                        { value: 'classlink', label: 'Classlink' },
                                                        { value: 'schoology', label: 'Schoology' },
                                                        { value: 'canvas', label: 'Canvas' }
                                                    ]}
                                                />
                                            </Card>
                                        </Col>
                                        <Col xs={24} lg={18} md={12} className="ColCardPadding">
                                            <Card style={{ width: '100%' }}>
                                                <Input placeholder="Search Schools by name" bordered={false} style={{ height: '32px' }} onChange={handleSearchDistrict} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </FilterContainer>
                        </Col>
                    </Row>
                    <StableWrapper style={{ marginTop: 30 }}>
                        <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}></TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>District Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses used</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses available</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Students</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Teachers</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Integration</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isEmpty(filteredSchools) && (
                                    userloading ? <Loading size={30} /> : (
                                        <TableRow key={'0'} style={{ border: 0 }}>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>No results</TableCell>
                                        </TableRow>
                                    )
                                )}
                                {(!isEmpty(filteredSchools)) && (
                                    filteredSchools.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const districtName = find(districts, obj => obj.id == row.parentId);
                                        return (
                                            <>
                                                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }}>
                                                    <TableCell style={{ textAlign: "center" }} onClick={() => { setShowSchoolsTeachers(showSchoolsTeachers == index ? -1 : index) }}>
                                                        <Rotate open={showSchoolsTeachers == index}>
                                                            <ArrowRight />
                                                        </Rotate>
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                        row.distrcitName = get(districtName, 'displayName', '');
                                                        setData(row);
                                                        setModal2Open(!modal2Open)
                                                    }}>{row.displayName} <ViewDemo color={BORDERCOLOR[3]}>{row.demo && "(Demo)"}</ViewDemo></TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                        // setData(row);
                                                        // setModal2Open(!modal2Open)
                                                    }}>{get(districtName, 'displayName', '')} <ViewDemo color={BORDERCOLOR[3]}>{get(districtName, 'demo', '') && "(Demo)"}</ViewDemo></TableCell>
                                                    <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0] }}>{row?.licenses || 0}</TableCell>
                                                    <TableCell style={{ textAlign: "left", color: BORDERCOLOR[1] }}>{(row?.maxCount != "undefined" ? row?.maxCount : 0)}</TableCell>
                                                    <TableCell style={{ textAlign: "left", color: BORDERCOLOR[2] }}>{substract(row?.licenses, (row?.maxCount != "undefined" ? row?.maxCount : 0))}</TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => onTabsJump("see_students", row)}>{get(row, 'student_count', '0')}</TableCell>
                                                    <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => onTabsJump("see_teachers", row)}>{get(row, 'teacher_count', '0')}</TableCell>
                                                    <TableCell style={{ textAlign: "left", color: 'rgb(200, 109, 152)' }}>{checkIntegration(row)}</TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: (showSchoolsTeachers == index ? '' : 'none') }} height={showSchoolsTeachers == index && teacherByOrg[row.id]?.length > 0 ? ((teacherByOrg[row.id]?.length * 39) + 8) : 0}>
                                                    <TableCell colSpan={24}>
                                                        <Table style={{ width: "100%" }}>
                                                            {teacherByOrg[row.id]?.map((t) => (
                                                                <TableRow key={t.id} style={{ border: 0, width: "100%" }}>
                                                                    <TableCell style={{ textAlign: "center" }}>{`${get(t, "firstName", "")} ${get(t, "lastName", "")}`}</TableCell>
                                                                    <TableCell style={{ textAlign: "center" }}>
                                                                        <Button onClick={() => { goToClass(t.id, t); }} disabled={!t.active} style={{ cursor: t.active ? "pointer" : "not-allowed", color: t.active ? "black" : "red" }}>
                                                                            {loginLoader == t.id && <Loading size={14} />}  Log in to account
                                                                            <ArrowForward style={{ fontSize: "11px", marginLeft: "8px" }} />
                                                                        </Button>
                                                                    </TableCell>
                                                                    
                                                                </TableRow>
                                                            ))}
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    }

                                    )
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {!isEmpty(filteredSchools) && <TablePagination
                                        count={filteredSchools.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                    />}
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </StableWrapper>
                </StableWrapperMain>
            )}
            <Modal
                footer={null}
                title={get(data, 'displayName', '')}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={'60%'}
                className="FilterHeading"
            >
                <ProfileContainer>
                    <ButtonSection type="primary" ghost onClick={() => addSchools('edit')}>
                        Edit Profile
                    </ButtonSection>
                </ProfileContainer>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Detail View"
                >
                    <Row>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Schools Name</OverViewLabel>
                                <OverViewValue>{get(data, 'displayName', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Districts Name</OverViewLabel>
                                <OverViewValue>{get(data, 'distrcitName', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Licenses</OverViewLabel>
                                <OverViewValue>{get(data, 'licenses', '')}</OverViewValue>
                            </OverViewContainer>


                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Licenses available</OverViewLabel>
                                <OverViewValue>{substract(data.licenses, (data.maxCount != "undefined" ? data.maxCount : 0))}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Licenses used</OverViewLabel>
                                <OverViewValue>{(get(data, 'maxCount', '') != "undefined" ? get(data, 'maxCount', '') : 0)}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Integration</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(200, 109, 152)' }}>{checkIntegration(data)}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col xs={12} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Students</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_students", data)}>{get(data, 'student_count', '0')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={12} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Teachers</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_teachers", data)}>{get(data, 'teacher_count', '0')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>

                </Card>
            </Modal>
        </>
    );
};


//  -- Prop types ---------------------
SchoolTable.propTypes = {};

//  -- Default props ------------------
SchoolTable.defaultProps = {};

export default SchoolTable;
