//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleInvokeMainbook, handleRevokeMainbook , handleInvokeEbookGalleryView , handleRevokeEbookGalleryView, handleSentenceRevoke, handleSentenceInvoke} from './thunks';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.mainbookPageView;

//  -- Examples array selector --------

export const useMainbookView = () => {
    const selectedData = useSelector(selector, shallowEqual)
    const dispatch = useDispatch()
    const invokeMainbookView = (pages) => {
        dispatch(handleInvokeMainbook(true, pages))
    }
    const revokeMainbookView = () => {
        dispatch(handleRevokeMainbook())
    }
    return {
        mainbookPageView: selectedData,
        invokeMainbookView,
        revokeMainbookView
    };
}

const galleryselector = (state) => state.ebookGalleryView;
export const useEbookGalleryView = () => {
    const selectedData = useSelector(galleryselector, shallowEqual)
    const dispatch = useDispatch()
    const invokeEbookGalleryView = (pages) => {
        dispatch(handleInvokeEbookGalleryView(true, pages))
    }
    const revokeEbookGalleryView = () => {
        dispatch(handleRevokeEbookGalleryView())
    }
    return {
        ebookGalleryView: selectedData,
        invokeEbookGalleryView,
        revokeEbookGalleryView
    };
}

const sentenceSelector = (state) => state.sentenceView;

//  -- Examples array selector --------

export const useSentenceView = () => {
    const selectedData = useSelector(sentenceSelector, shallowEqual)
    const dispatch = useDispatch()
    const invokeSentenceView = (pages) => {
        dispatch(handleSentenceInvoke(true, pages))
    }
    const revokeSentenceView = () => {
        dispatch(handleSentenceRevoke())
    }
    return {
        sentenceView: selectedData,
        invokeSentenceView,
        revokeSentenceView
    };
}