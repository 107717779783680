//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel, MenuItem,
    Modal,
    Select,
    TextField
} from '@material-ui/core';
//  -- Components ---------------------
import { Card, Col, message as MessageAlert , Row } from 'antd';
import { Settings } from '@material-ui/icons';
import Loading from 'components/Loading';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
//  -- Thunks -------------------------
import { Switch } from "antd";
import {
    DatePicker,
} from "@material-ui/pickers";
import _, { get, map , find  } from "lodash";
import moment from "moment";
import { handleCreateOrganization, handleListDistricts, handleUpdateOrganization } from 'store/organizations/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import timeZone from "moment-timezone";
import { useEffect } from 'react';
import { useAllBook, useBookOrder } from 'store/books/selectors';
import { useHistory } from 'react-router-dom';
import COLOR from 'utils/constants/color';
import Section from 'components/Section';
import OrderBookGroup from 'containers/addBook/orderBookGroup';
import { groupBookByOrder } from 'utils/lib/ebookHelper';
import { handleCreateLicences } from 'store/licences/thunks';
import { isBeforTime } from 'utils/methods/math';
import CanvasConfig from './CanvasConfig';
import { updateCanvasKeyInfo } from 'store/canvas/thunks';
const TabWidth = '160px'
const TabHeight = '25px'
const ANECDOUS = ['7', '11', '9', '13', '8', '12', '10', '14']
const INFO = [
    {
        key: "1nformate",
        label: "1nformate"
    },
    {
        key: "comunidad",
        label: "Comunidad"
    },
    {
        key: "balcónCultural",
        label: "Balcón cultural"
    },
    {
        key: "1WAYRESOURCES",
        label: "1WAYRESOURCES"
    },

]
const EXAMS = [
    {
        key: "exams",
        label: "Exams"
    },
    {
        key: "1WAYTEACHER",
        label: "1WAYTEACHER"
    },
    {
        key: "conectandome",
        label: "Conectandome"
    },


]
const APEXAMS = [
    {
        key: "ap",
        label: "AP Exam"
    },
    {
        key: "1WAYSTORY",
        label: "1WAYSTORY"
    }


]
const BOOKS1 = [
    {
        key: "32",
        label: "Anécdotas Inicial"
    },
    {
        key: "46",
        label: "Anécdotas Inicial 2"
    },
    {
        key: "7",
        label: "Anécdotas 1"
    },
    {
        key: "8",
        label: "Anécdotas 2"
    },
    {
        key: "1",
        label: "America Ed 3"
    },
    {
        key: "13",
        label: "Assessment 1"
    }
]
const BOOKS2 = [
    {
        key: "11",
        label: "Workbook 1"
    },
    {
        key: "12",
        label: "Workbook 2"
    },
    {
        key: "9",
        label: "Anécdotas Literarias 1"
    },
    {
        key: "10",
        label: "Anécdotas Literarias 2"
    },
    {
        key: "14",
        label: "Assessment"
    },

]

const EBOOK_OPTION = [
    {
        key: "printing",
        label: "PRINTING"
    },
    {
        key: "lessonPlan",
        label: "Lesson Plan"
    },
    {
        key: "teacherEdition",
        label: "Teacher Editions"
    }
]




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Header = styled.span`
    color: ${COLOR.black100};
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
	margin: 0px auto;
    // max-width: 480px;
    width: 100%;
`;
const DemoButton = styled.div`
    border: 0.5px solid white;
    background-color: ${p => p.bgColor ? p.bgColor : `rgb(113,0,225)`};
    font-size: 10px;
    font-weight: 600;
    outline: none;
    width:${p => p.width ? p.width : `80px`};
    color: white;
    border-radius: 3px;
    text-align:center;
    height:${p => p.height ? p.height : `20px`};
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    display: flex;
`;
const SwitchWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;
const SwitchWrappers = styled.div`
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-direction:row;
    width:100%;
    gap:24px;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

const SectionTitle = styled.div`
    font-size: 14px;
    color: #ffffff;
    background: ${p => p.bgColor ? p.bgColor : '#00c4cc'};
    padding: 4px 8px;
    border-radius: 6px !important;
    cursor: pointer;


    position: absolute;
    top: -10px;
    left: -6px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;
const ContainerSwitch = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
`;
const Labels = styled.div`
    color:black;
    font-weight:500;
    font-size:14px;
`;

const BookSection = styled.div`
    border: 2px solid #e9e9e9;
    box-shadow: ${(p) => p.active ? p.preActive ? "-3px 2px 8px 0px #9d0001, 3px 1px 5px #9d0000;" : " -3px 2px 8px 0px #00989d, 3px 1px 5px #00989d;" : ""};
    margin-bottom: 26px;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    width: fit-content;
    margin-right: 16px;
    padding-bottom: 16px;
    
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const SwitchController = ({ bgColor, value, onChange = () => { }, label, width = '80px', height = '20px', disabled }) => {
    return (<SwitchWrapper>
        <DemoButton style={{ minHeight: 32 }} width={width} height={height} bgColor={bgColor}>{label}</DemoButton>
        <FormControlLabel
            style={{ marginTop: 8 }}
            control={
                <Switch
                    onChange={onChange}
                    name="view"
                    color="primary"
                    size="default"
                    checked={value}
                    className={"districForm"}
                    disabled={disabled ? disabled : false}
                />
            }
            className={"districForm"}
            label=""
        />
    </SwitchWrapper>)
}

const SwitchControllers = ({ bgColor, value, onChange = () => { }, label, width = '80px', height = '20px', disabled }) => {
    return (<SwitchWrappers>
        <DemoButton style={{ minHeight: 32 }} width={width} height={height} bgColor={bgColor}>{label}</DemoButton>
        <FormControlLabel
            style={{ marginTop: 8 }}
            control={
                <Switch
                    onChange={onChange}
                    name="view"
                    color="primary"
                    size="default"
                    checked={value}
                    className={"districForm"}
                    disabled={disabled ? disabled : false}
                />
            }
            className={"districForm"}
            label=""
        />
    </SwitchWrappers>)
}
const initialPermissions = {
    "1nformate": true,
    "comunidad": true,
    "balcónCultural": true,
    "1WAYRESOURCES": true,
    "exams": true,
    "1WAYTEACHER": true,
    "conectandome": true,
    "printing": true,
    "lessonPlan": true,
    "teacherEdition": true,
    "active": true,
    "userActive": true,
    "books": [],
    "ap": true,
    "1WAYSTORY": true,

}
const defaultCanvasSec = {
    id: null, base_url: '', key: '', secrate: '', created_on: '', api_version: 'api/v1', orgId: "", display_name:'', redirect_uri: 'https://nosotrosonline.com/login'
}

const DistrictForm = ({
    district,
    editing,
    onClose,
    booksAllList
}) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({ demo: true, startDate: moment().format("YYYY-MM-DD") });
    const [permissions, setPermissions] = useState(initialPermissions)
    const [loading, setLoading] = useState(false);
    const [licensesError, setLicensesError] = useState(false);
    const [licensesErrorMsg, setLicensesErrorMsg] = useState('');
    const [alertForAccount, setAlertForAccount] = useState(false)
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const allBooks = useAllBook()
    const [isClaver, setIsClaver] = useState(false)
    const [isCanvas, setIsCanvas] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [canvasKeyInfo, setCanvasKeyInfo] = useState(defaultCanvasSec);
    const [canvas_id, setCanvas_id] = useState(null)
    const [selectedExam, setSelectedExam] = useState();
    const [openEditExamModal, setOpenEditExamModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState();
    const bookOrder = useBookOrder()
    const [allBookOrder, setAllBookOrder] = useState(bookOrder);
    const [examTitle, setExamTitle] = useState('');
    const history = useHistory();
    const can_be_canvas = ! Boolean(district?.classlinkid || district?.cleverId || district?.schoolId) 
    const [canvas_error,setCanvasError] = useState({error:false,message:''})
    useEffect(() => {
        if (!_.isEmpty(district)) {
            if(district.canvas_id) {
                setCanvas_id(district.canvas_id)
                setIsCanvas(true)
            }else{
                setIsCanvas(false)
                setCanvas_id(null)
            }
            if (district.cleverId) {
                setIsClaver(true)
            } else {
                setIsClaver(false)
            }
            setData(district)
        } else {
            setData({ demo: true, startDate: moment().format("YYYY-MM-DD") })
            setIsClaver(false)
            setIsCanvas(false)
            setCanvas_id(null)
        }
    }, [district])
    useEffect(() => {
        setExamTitle(get(data, 'title'))
    }, [data])
    useEffect(() => {
        if (Array.isArray(allBooks)) {
            const books = _.filter(allBooks, ['isNotAssignable', false]).filter(k => !k.description?.toLowerCase()?.includes('demo'))
            let bookSelector = []
            _.map(ANECDOUS, i => {
                let f = _.find(books, item => item.id == i)
                if (f) bookSelector.push(f)
            })
            const remaintArr = _.filter(books, item => !ANECDOUS.includes(item.id))
            bookSelector.push(...remaintArr)
            const resp = permissions;
            const distPermissions = _.get(district, "permissions", null);
            if (distPermissions) {
                _.map(bookSelector, bs => {
                    if (!_.find(distPermissions.books, ['bookId', bs.id])) {
                        let obj = {}
                        obj['bookId'] = bs.id;
                        obj['displayName'] = bs.description;
                        obj['available'] = true
                        resp.books.push(obj);
                    }
                })
                setPermissions(distPermissions)
            } else {
                _.map(bookSelector, bs => {
                    if (!_.find(resp.books, ['bookId', bs.id])) {
                        let obj = {}
                        obj['bookId'] = bs.id;
                        obj['displayName'] = bs.description;
                        obj['available'] = true
                        resp.books.push(obj);
                    }
                })
                setPermissions(resp)
            }
        }
    }, [allBooks, district])
    const handleChange = e => {

        if (Number(e.target.value) < Number(data.maxCount)) {

            setLicensesError(true);
            setLicensesErrorMsg('Assigned licenses cannot be less than already used.');
        }
        else {
            setLicensesError(false);
            setLicensesErrorMsg('');
        }

        setData({
            ...data,
            [e.target.id]: e.target.value
        });


    };

    const onSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setDataLoading(true)
        if (data.displayName == '' || !data.displayName) return;
        setLoading(true);
        if(isCanvas&&!canvasKeyInfo.id){
            setDataLoading(false)
            setLoading(false);
             MessageAlert.error(`Canvas key information are required for this district please add.`)
            setCanvasError({ error:true,message:`Canvas key information are required for this district please add.`})
            return
        }else{
            setCanvasError({ error:false,message:`Canvas key information are required for this district please add.`})
        }
        if (editing) {
            await dispatch(handleUpdateOrganization(district.id, {
                ...district,
                ...data,
                permissions,
                canvas_id: canvasKeyInfo.id ? canvasKeyInfo.id : null
            }));
            if(canvasKeyInfo.id){
                await updateCanvasKeyInfo({...canvasKeyInfo,orgId:district.id});
            }
            await dispatch(handleListDistricts());
            onClose();
            history.push('/data-browsers');
        } else {
            const createDistrict = await dispatch(handleCreateOrganization({
                ...data,
                canvas_id: canvasKeyInfo.id ? canvasKeyInfo.id : null,
                typeId: 'district',
                logo: '',
                parentId: 'null',
                studentCount: 0,
                maxCount: 0,
                permissions
            }));
            await dispatch(handleListDistricts());
            const now = moment();
            const unixTimestamp = now.valueOf();
            if(canvasKeyInfo.id){
                await updateCanvasKeyInfo({...canvasKeyInfo,orgId:createDistrict.id});
            }
            dispatch(handleCreateLicences({
                districtId: get(createDistrict, 'id', ''),
                allocatedLicense: get(createDistrict, 'licenses', ''),
                usedLicense: get(createDistrict, 'maxCount', 0),
                // schoolId: null,
                modified: unixTimestamp,
                availableLicenses: get(createDistrict, 'licenses', '')
            }))
            onClose();
            history.push('/data-browsers');
        }
        setDataLoading(false)
        setLoading(false);
    };
    const handlePermissionChange = (key, value) => {
        const resp = permissions;
        resp[key] = value;
        setPermissions(resp)
        forceUpdate()
    }
    const handleUpdateData = (key, value) => {
        const resp = data;
        resp[key] = value;
        setData(resp)
        forceUpdate()
    }
    const getValue = (key) => {
        return permissions[key]
    }
    const getData = (key) => {
        return data[key]
    }
    const getBookValue = (bookId) => {
        return _.find(permissions.books, ['bookId', bookId]).available
    }
    const handleBookAvailableChanges = (bookIds, value) => {
        const res = permissions;
        let fbook = _.filter(booksAllList, k => find(bookIds, l => l == k.id)).map(k => ({ bookId: k.id, displayName: k.description }));
        const bks = [...res.books, ...fbook];
        res.books = map(bks, item => {
            if (find(bookIds, k => k == item.bookId)) {
                item.available = value;
                return item;
            } else {
                return item;
            }
        })
        setPermissions(res)
        forceUpdate()
    }
    const backToDataBrowser = () => {
        history.push('/data-browsers');
    };

    const onEditGroup = (grpData, index) => {
        setSelectedExam(grpData)
        setActiveIndex(index)
        setOpenEditExamModal(true)
    }
    const onBookLicenseChange = (checked, title, orderIndex) => {
        const resp = permissions;
        let countable = resp[`licenses-count`] || []
        let keytitle = title.replace(/\s+/g, '')
        const is_exist_index = countable.findIndex(k => k.key == keytitle)
        if (countable.length == 0) {
            countable = [{
                key: keytitle,
                title: title,
                order_index: orderIndex,
                count: checked
            }]
        } else {
            if (is_exist_index >= 0) {
                countable[is_exist_index].count = checked
            } else {
                countable.push({
                    key: keytitle,
                    title: title,
                    order_index: orderIndex,
                    count: checked
                })
            }
        }
        resp[`licenses-count`] = countable;
        setPermissions(resp)
        forceUpdate()
    };
    const getBookLicensesCountStatus = (title) => {
        let countable = permissions[`licenses-count`] || []
        let keytitle = title.replace(/\s+/g, '')
        const is_exist = countable.find(k => k.key == keytitle)
        if (is_exist) {
            return is_exist.count
        }
        return true;
    }
    const orderedBook = groupBookByOrder(booksAllList, allBookOrder);
    const getStartMintime = (value) => {
        if (get(data, "editing") && value) {
           return  moment(value).toISOString();
        } else {
            return moment().toISOString()
        }
    }
    const getEnableStatus = useCallback((item) => {
        const bookPermissions = get(permissions, "books", [])
        let isEnabled = true;
        let message = "Books "
        let disableCount = 0; 
        map(get(item,"books",[]),bk=>{
            const bookaccess = _.find(bookPermissions, b => b.bookId == bk.id)
            if(bookaccess && _.has(bookaccess,"available")){
                if(!bookaccess.available){
                    isEnabled = false;
                    disableCount++;
                    message+= bk.description || '';
                }
            }
        })
        message += "are disabled prior, please enable or disable by grouping!"
        if(disableCount ===get(item,"books.length",0)){
            message = ''
        }
        return ({ isEnabled , message })

    },[booksAllList,allBookOrder,permissions])
    const enableDiableGroup = (status,groups) => {
         handleBookAvailableChanges(map(groups,k=>k.id),status)
    }
    return (
        <Wrapper onSubmit={onSubmit}>
            <Modal
                open={alertForAccount}
                onClose={() => setAlertForAccount(false)}
            >
                <ModalContent>
                    Are you sure you want to {get(permissions, "userActive", true) ? "enable" : "disable"} all account
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                handlePermissionChange("userActive", !permissions.userActive)
                                setAlertForAccount(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginRight: '4px' }}
                            color="secondary"
                            variant="contained"
                            className={`${dataLoading ? "disabled-button" : ""}`}
                            onClick={() => onSubmit()}
                        >
                            {dataLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <div className="FilterHeading">
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title={editing ? 'Update District' : 'Create District'}

                >
                    <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 8 }}>

                        <Col span={24}>
                            <Row gutter={[24, 24]} style={{ width: '100%', paddingLeft: 10, marginBottom: 20 }}>
                                <SwitchControllers label={"DEMO"} bgColor={COLOR.orange100} value={getData('demo')} onChange={handleUpdateData.bind(this, 'demo')} />
                            </Row>
                            {/* <Header >{editing ? 'Update District' : 'Create District'}</Header> */}
                            <Row className="floatingLabelBG">
                                <TextField
                                    id="displayName"
                                    onChange={handleChange}
                                    label="District Name"
                                    variant="outlined"
                                    value={data?.displayName}
                                    style={{ borderColor: COLOR.black100, background: 'white' }}
                                />
                            </Row>
                            <Row style={{ marginTop: 25 }} className="floatingLabelBG">
                                <TextField
                                    id="licenses"
                                    error={licensesError}
                                    onChange={handleChange}
                                    label="Licenses"
                                    variant="outlined"
                                    helperText={licensesError && licensesErrorMsg}
                                    value={data?.licenses}
                                    style={{ borderColor: COLOR.black100 }}
                                />
                            </Row>
                            {editing && <Row style={{ marginTop: 25 }} className="floatingLabelBG">
                                <TextField
                                    disabled
                                    id="usedLicenses"
                                    label="Used Licenses"
                                    variant="outlined"
                                    value={data?.maxCount}
                                    style={{ borderColor: COLOR.black100 }}
                                />
                            </Row>}
                            {editing && <Row style={{ marginTop: 25 }} className="floatingLabelBG">
                                <TextField
                                    disabled
                                    id="freeLicenses"
                                    label="Licenses Available"
                                    variant="outlined"
                                    value={data?.licenses - data?.maxCount}
                                    style={{ borderColor: COLOR.black100 }}
                                />
                            </Row>}
                            <Row style={{ marginTop: 25 }} className="floatingLabelBG">
                                <FormControl variant="outlined">
                                    <InputLabel id="timezonelabel" style={{ borderColor: COLOR.black100 }}>Select Timezone</InputLabel>
                                    <Select
                                        labelId="timezonelabel"
                                        id="timezone"
                                        onChange={e => {
                                            setData({
                                                ...data,
                                                timezone: e.target.value
                                            });
                                        }}
                                        value={get(data, "timezone", timeZone.tz.guess())}
                                        style={{ borderColor: COLOR.black100 }}
                                    >
                                        {map(timeZone.tz.names(), tz => (
                                            <MenuItem key={tz} value={tz}>
                                                {tz}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Row>
                            <CanvasConfig
                                isCanvas={isCanvas}
                                setIsCanvas={setIsCanvas}
                                setCanvasKeyInfo={setCanvasKeyInfo}
                                can_be_canvas={can_be_canvas}
                                disabled={get(data, "canvas_id", null) ? true : editing}
                                canvasKeyInfo={canvasKeyInfo}
                                canvas_id={data?.canvas_id} 
                                orgId={data?.id}
                            />
                            <Row gutter={[16, 24]} style={{ marginTop: 25, paddingLeft: 10, justifyContent: "space-between", paddingRight: 10 }}>   
                                <DatePicker
                                    label="Start Date"
                                    inputVariant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    format="MM-dd-yyyy"
                                    // minDate={getStartMintime(get(data, "startDate"))}
                                    style={{ width: '45%', height: 40 }}
                                    disabledDate={(date) => date.isBefore(moment(), 'day')}
                                    placeholder={"Start date"}
                                    onChange={e => {
                                        handleUpdateData('startDate', e && moment(e).format('YYYY-MM-DD'))
                                        if (data.endDate && moment(data.endDate).isBefore(e)) {
                                            handleUpdateData('endDate', null)
                                        }
                                      
                                    }} className={'districFormDate'} value={getData('startDate') ? moment(getData('startDate')) : null} />
                                
                                 
                                <DatePicker
                                    label="End Date"
                                    inputVariant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    minDate={getStartMintime(get(data, "startDate"))}
                                    format="MM-dd-yyyy"
                                    style={{ width: '45%', height: 40 }}
                                    disabledDate={(date) => data.startDate ? date.isBefore(moment(data.startDate), 'day') : date.isBefore(moment(), 'day')}
                                    placeholder={"End date"} onChange={e => handleUpdateData('endDate', e ? moment(e).format('YYYY-MM-DD') : null)} className={'districFormDate'} value={getData('endDate') ? moment(getData('endDate')) : null} />
                            </Row>
                            {editing && <Row gutter={[16, 24]} style={{ marginTop: 20, paddingLeft: 10, justifyContent: "space-between", paddingRight: 10 }} >
                                <SwitchControllers bgColor={'rgb(203,17,118)'} label={`${"ACTIVE/DEACTIVE"} - ALL DISTRICT ACCOUNT`} width={100} height={'30px'} value={get(permissions, "userActive", true)} onChange={e => {
                                    setAlertForAccount(true)
                                    handlePermissionChange("userActive", e)
                                }
                                } />
                            </Row>}
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className="FilterHeading">
                <Card
                    style={{ marginTop: 16, marginBottom: 20 }}
                    type="inner"
                    title={editing ? 'Update District' : 'Create District'}
                >
                    <Row gutter={[24, 24]} style={{ width: '100%', marginTop: 8 }}>
                        <Col span={24}>
                            <Row gutter={[16, 24]} style={{}}>
                                {_.map(INFO, item => (<div style={{ margin: '0px 10px' }} key={item.key}><SwitchController label={item.label} value={getValue(item.key)} onChange={handlePermissionChange.bind(this, item.key)} width={TabWidth} height={TabHeight} /></div>))}

                            </Row>
                            <Row gutter={[16, 24]} style={{ marginTop: 12 }}>
                                {_.map(EXAMS, item => (<div style={{ margin: '0px 10px' }} key={item.key}><SwitchController label={item.label} value={getValue(item.key)} width={TabWidth} height={TabHeight} onChange={handlePermissionChange.bind(this, item.key)} /></div>))}

                            </Row>
                            <Row gutter={[16, 24]} style={{ marginTop: 12 }}>
                                {_.map(APEXAMS, item => (<div style={{ margin: '0px 10px' }} key={item.key}><SwitchController label={item.label} value={getValue(item.key)} width={TabWidth} height={TabHeight} onChange={handlePermissionChange.bind(this, item.key)} /></div>))}

                            </Row>
                            <Row gutter={[16, 24]} style={{ marginTop: 12 }}>
                                {_.map(EBOOK_OPTION, item => (<div style={{ margin: '0px 10px' }} key={item.key}><SwitchController label={item.label} value={getValue(item.key)} onChange={handlePermissionChange.bind(this, item.key)} width={TabWidth} bgColor={COLOR.bluelight} height={TabHeight} /></div>))}
                            </Row>


                            {/* book grouping code  */}
                            <Row style={{ marginTop: '1%' }}>
                                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                    {/* <Section style={{ fontSize: '18px' }}
                                        description="Resources books"
                                        header='Resources'> */}
                                    {map(orderedBook, (books, index) => {
                                        const {isEnabled , message }  = getEnableStatus(books);

                                        return (
                                            <LinkArea>
                                                <Col span={24} style={{ marginBottom: 10 }}>
                                                    <ContainerSwitch style={{width:100, marginBottom:16, marginTop:16 , marginLeft:16 }}>
                                                        <Labels>Enable</Labels>
                                                        <Switch checked={isEnabled}   onChange={(e)=>enableDiableGroup(e, books?.books)} />
                                                    </ContainerSwitch>
                                                </Col>
                                                <Col span={24} style={{ marginBottom: !isEnabled?10:0 }}>
                                                     {!isEnabled &&<span>{message}</span> }
                                                </Col>
                                                <Row style={{ marginTop: '1%', marginLeft: 20 }}>
                                                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                                                        <BookSection>
                                                            <SectionTitle bgColor={books.color} >{books.title}</SectionTitle>
                                                            <OrderBookGroup chapter={books?.books} disabled={true}  onRearrangeDone={()=>{}}/>
                                                        </BookSection>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <ContainerSwitch style={{ justifyContent: "flex-start", rowGap: 4, gap: 4 }}>
                                                            <Labels>Activate paid licenses.</Labels>
                                                            <Switch checked={getBookLicensesCountStatus(books.title)} onChange={e => onBookLicenseChange(e, books.title, index)} />
                                                        </ContainerSwitch>
                                                    </Col>
                                                </Row>
                                            </LinkArea>
                                        )
                                    })}
                                    {/* </Section> */}
                                </Col>
                            </Row>


                            {/* end if book grouping */}

                            {/* <Row gutter={[16, 24]} style={{ marginTop: 12 }}>
                                {_.map(permissions.books, item => (<div style={{ margin: '0px 10px' }} key={item.bookId}><SwitchController label={item.displayName} width={TabWidth} height={TabHeight} bgColor={COLOR.indigo300} value={getBookValue(item.bookId)} onChange={v => handleBookAvailableChanges(item.bookId, v)} /></div>))}

                            </Row> */}
                        </Col>
                    </Row>
                </Card>
            </div>
            <Row style={{ width: '100%', marginTop: 8 }}>
                <Col span={4}>
                    <Button disabled={!data.displayName || licensesErrorMsg} style={{ width: '100%' }} variant="contained" color="primary" type='submit'>
                        {loading ? <Loading size={20} white /> : (editing ? 'Update' : 'Create')}
                    </Button>
                </Col>
                <Col span={16}></Col>
                <Col span={4}>
                    <Button variant="contained" color="secondary" className={"districForm"} onClick={backToDataBrowser} style={{ marginRight: '8px', width: '100%', textTransform: 'inherit' }}>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Wrapper>
    );
};


//  -- Prop types ---------------------
DistrictForm.propTypes = {};
const mapStateToProps = state => ({
    booksAllList: state.booksAllList
});
//  -- Default props ------------------
DistrictForm.defaultProps = {};

// export default DistrictForm;
export default connect(mapStateToProps, null)(DistrictForm);
