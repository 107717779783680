import { IconButton, Typography } from '@material-ui/core';
import { PauseCircleFilled, Replay } from '@material-ui/icons';
import { Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
const TimerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const CircularWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

const TimeDisplay = styled(Typography)`
  width: 80%;
  border-bottom: 2px solid;
  text-align: center;
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(0.96turn, rgba(121, 47, 252), rgba(121, 47, 252), rgba(121, 47, 252));
  border-image-slice: 1;
  font-weight: 400;
  font-size: 48px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 150px;
  position: absolute;
  bottom: 14%;
`;

const TimmerInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const formatTime = (time, format = "12") => {
    const momentFormat = format === "12" ? "hh:mm:ss A" : "HH:mm:ss";
    return moment().format(momentFormat);
  };

function SystemTimerWheel({ isMilitaryClock }) {
  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    let interval = setInterval(() => {
        setCurrentTime(Date.now());
      }, 1000);
    return () => clearInterval(interval);
  }, [currentTime]);

  return (
    <TimerContainer className='clckTimrContain'>
      <CircularWrapper>
        <Progress
          type="circle"
          percent={0}
          width={400}
          showInfo={false}
          trailColor={"rgba(236,240,242)"}
          strokeWidth={3}
          strokeColor={{
            '0%': 'rgba(121,47,252)',
            '50%': 'rgba(74,144,201)',
            '100%': 'rgba(89,190,210)',
          }}
        />
        <TimmerInner>
        <TimeDisplay variant="h2" style={{ fontSize: '48px' }}>{formatTime(currentTime, isMilitaryClock ? "24" : "12")}</TimeDisplay>
        </TimmerInner>
      </CircularWrapper>
    </TimerContainer>
  );
}

export default SystemTimerWheel;
