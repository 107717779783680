//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { invert, lighten } from 'polished'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
//  -- Components ---------------------
import { Assignment, Extension, Delete } from '@material-ui/icons'
import { Modal, Button, IconButton } from '@material-ui/core'
import { Drawer, Row, Col } from 'antd'

//  -- Constants ----------------------
import COLOR from 'utils/constants/color'
import { useDispatch } from 'react-redux';
//  -- Features -----------------------

//  -- Thunks -------------------------
import { find, get , has } from 'lodash'
import { useEffect } from 'react'
import AssignmentForm from './AssignmentForm'
import Loading from 'components/Loading'
import { handleDeleteActivities } from "store/activities/thunks";
import { handleDeleteQuizzes } from "store/quizzes/thunks";
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`

const RowItem = styled.div`
  align-items: ${(p) => p.align || 'center'};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px;
  }
`
const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`

const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`

const TextLarge = styled(Text)`
  color: #3d5f8f;
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
`

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`

const Wrapper = styled.button`
  background-color: ${(p) =>
        p.bgColor ? p.bgColor : lighten(0.55, '#3D5F8F')};
  border: 2px dashed
    ${(p) =>
        p.borderColor
            ? lighten(0.2, invert(p.bgColor))
            : lighten(0.3, '#3D5F8F')};
  border-radius: 4px;
  color: #3d5f8f;
  cursor: ${(p) => (p.disabled ? 'no-drop' : 'pointer')};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  outline: none;
  padding: 12px 12px 10px;
  transition: all 300ms ease;
  width: 100%;

  &:hover {
    background-color: ${(p) =>
        p.hoverColor ? p.hoverColor : lighten(0.5, '#3D5F8F')};
  }
`
const BookDescRow = styled(RowItem)`
  font-size: 10px;
  padding-left: 30px;
  margin-top: -15px;
`
const Columns = styled(Col)`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Announcement
//
//  -- Description
//  Announcement component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Announcement data={data} />
//

const Assignments = ({ assignment, pageSrc, bookId, pageId }) => {
    const [drawer, toggleDrawer] = useState(false)
    const colorCode = get(assignment, 'colorCode')
    const bookDescription = get(assignment, 'bookDescription')
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => { }, [])
    const onCloseDrawer = () => {
        toggleDrawer(false)
    }
    const onDelete = async () => {
        if (has(assignment, "id")) {
            setDeleting(true)
            if (assignment.typeId == "activity") {
                await dispatch(handleDeleteActivities(assignment.id))

            } else {
                await dispatch(handleDeleteQuizzes(assignment.id))
            }
            setDeleting(false)
            setDeleteToggle(false)
        }
    }
    const onCreateOrUpdate = () => {
        toggleDrawer(false)
    }
    return (
        <>
            <Modal open={deleteToggle} onClose={() => setDeleteToggle(false)}>
                <ModalContent>
                    <Text>
                        <strong>{`Are you sure to delete this ${get(
                            assignment,
                            'typeId',
                            'assignment',
                        )}?`}</strong>
                    </Text>
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setDeleteToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={onDelete}
                            className={`${deleting ? 'disabled-button' : ''}`}
                        >
                            {deleting ? <Loading size={20} white /> : 'Delete'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Drawer
                height="100%"
                onClose={onCloseDrawer}
                placement="top"
                visible={drawer}
                key="quizNew"
                className="assignments-drawer"
            >
                <>
                    <AssignmentForm
                        pageSrc={pageSrc}
                        data={assignment}
                        bookId={bookId}
                        pageId={pageId}
                        onCreateOrUpdate={onCreateOrUpdate}
                        type={get(assignment, 'typeId', '')}
                    />
                </>
            </Drawer>
            <Wrapper bgColor={colorCode} borderColor={null} hoverColor={colorCode}>
                <RowItem align="flex-start">
                    {assignment?.typeId === 'activity' ? (
                        <Extension style={{ height: '18px', marginRight: '4px' }} />
                    ) : (
                        <Assignment style={{ height: '18px', marginRight: '4px' }} />
                    )}
                    <Title>{get(assignment, 'displayName', '')}</Title>
                </RowItem>
                {bookDescription ? (
                    <BookDescRow>
                        <strong>Book</strong>: {bookDescription}
                    </BookDescRow>
                ) : null}
                <RowItem>
                    <Column>
                        <Text>
                            Type :<strong>{get(assignment, 'typeId')}</strong>
                        </Text>
                    </Column>
                </RowItem>
                <Row>
                    <Columns span={16} style={{ justifyContent: "flex-start" }}>
                        <Text>
                            Number of questions:{' '}
                            <strong style={{ color: COLOR.black300 }}>
                                {get(assignment, 'questions.length')}
                            </strong>
                        </Text>
                    </Columns>
                    <Columns span={4}>
                        <IconButton style={{ padding: 0 }} color="secondary" onClick={() => setDeleteToggle(true)}>
                            <Delete color="secondary" />
                        </IconButton>
                    </Columns>
                    <Columns span={4}>
                        <TextLarge
                            onClick={() => {
                                toggleDrawer(true)
                            }}
                        >
                            Edit
                        </TextLarge>
                    </Columns>
                </Row>
            </Wrapper>
        </>
    )
}

//  -- Prop types ---------------------
Assignments.propTypes = {}

//  -- Default props ------------------
Assignments.defaultProps = {
    fontSize: 10,
}

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
    currentClass: state.currentClass,
    booksAllList: state.booksAllList,
    currentUser: state.currentUser,
    schoologyAuth: state.schoologyAuth,
})

export default connect(mapStateToProps, null)(Assignments)
