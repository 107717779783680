//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
import SentryWrap from "components/Sentry";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
//  -- Components ---------------------
import { Modal } from "@material-ui/core";
// import Table from "components/Table";
import { filter, find, groupBy } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Features -----------------------
import { getRandomCotation } from "utils/methods/math";

//  -- Thunks -------------------------
import CotationLoader from 'components/CotationLoader';
import DistrictClassesTable from "features/forms/admin/DistrictClassesTable";
import DistrictSchoolTable from "features/forms/admin/DistrictSchoolTable";
import DistrictStudentTable from "features/forms/admin/DistrictStudentTable";
import DistrictTeacherTable from "features/forms/admin/DistrictTeacherTable";
import { isEmpty } from 'lodash';
import { handleGetOrgClasses } from "store/classes/thunks";
import { handleProcessClassTenant } from "store/classlink/thunks";
import { createKeystore } from "store/keystore/thunks";
import { handleGetOrganization, handleListDistricts, handleListGetSchool } from "store/organizations/thunks";
import { handleListDistStudent, handleListDistTeacher, handleListDistUsers } from "store/users/thunks";
import { Spin } from "antd"
import { v4 } from 'uuid';
import moment from 'moment';

const MENU = [
  { id: "see_schools", value: "Schools" },
  { id: "see_teachers", value: "Teachers" },
  { id: "see_students", value: "Students" },
  { id: "see_classes", value: "Classes" }
];
const BOOKLIST = ["32", "7", "8", "1"];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0px;
  width: 100%;
`;
 
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
//   justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 12px;
//   border: 2px solid ${COLOR.black100};
//   background-color: ${COLOR.blueish400};
  margin-top: ${(p) => (p.top ? p.top : "50px")};
  align-items: center;
  position: relative;
  padding: 2px 0px;
  flex-wrap: wrap;
`;
const MenuBtn = styled.button`
  background-color:white;
  border-top:none;
  border-left:none;
  border-right:none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  padding: 2px 16px 10px 16px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
 
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
  // border-radius: 12px;
  // background-color: ${COLOR.indigodeep};
  width: 100%;
  // padding: 16px;
  margin-top: 32px;
`;
const SectionTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ViewBtn = styled.button`
  background-color: ${COLOR.indigo100};
  border: 1px solid ${COLOR.white};
  color: ${COLOR.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  transition: all 300ms ease;
  border-radius: 7px;
  padding: 2px 16px;
  margin-right: 2px;
  margin-left: 2px;
  min-width: 180px;
  height: 30px;
  margin-left: 68.5%;
  @media (max-width: 992px) {
    margin-top: 10px;
  }
`;
const VideoContainer = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const getBookList = (allBook) => {
  return BOOKLIST.map((e) => find(allBook, { id: e })).filter((e) => e);
};


const SectionsView = ({
  label,
  children,
  currentView,
  id,
  backButton = () => { },
  onCloseView = () => { },
  onClose = () => { },
  hasBoxButton,
}) => {
  return (
    <SectionWrapper>
      <SectionTopRow>
        {/* <SectionTitle>
                    <Itemicon style={{ height: 20, width: 20 }} />
                    <ItemLabel style={{ fontSize: 20 }}>{label}</ItemLabel>
                </SectionTitle> */}
        {currentView == "boxes" && id != "" && hasBoxButton && (
          <>
            <ViewBtn onClick={backButton}>BACK TO BOXES</ViewBtn>
          </>
        )}
        {id == "" && hasBoxButton && (
          <>
            <ViewBtn onClick={onCloseView}>
              {currentView == "list" ? "VIEW BOXES" : "VIEW LIST"}
            </ViewBtn>
          </>
        )}
        {/* <HomeBtnb onClick={onClose}>

                    <Home style={{ color: "#fff", fontWeight: "bold", fontSize: 30 }} />
                </HomeBtnb> */}
      </SectionTopRow>
      {children}
    </SectionWrapper>
  );
};


const DistrictDataBrowsers = ({
  currentOrg,
  districts,
  schools,
  users,
  booksAllList,
  allSchools,
  allUser,
  classes,
  allClasses,
  distTeacher,
  distStudents
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const LICENCEDBOOOK = getBookList(booksAllList);
  const [dataTab, setDataTab] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userloading, setUserLoading] = useState(true);
  const [currentView, setCurrentView] = useState("boxes");
  const [idSchoolBox, setIdSchoolBox] = useState("");
  const [loginLoader, setLoginLoader] = useState(null)
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedDist, setSelectedDist] = useState()
  const cotationRef = useRef(null)
  if (!cotationRef.current) {
    cotationRef.current = getRandomCotation()
  }
  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
    setDataTab("see_schools");
  }, []);
  
  useEffect(() => {
    async function fetchData() {
      await dispatch(handleListDistricts());
      dispatch(handleProcessClassTenant())
      dispatch(handleGetOrgClasses([currentOrg, ...schools]));
    }
    fetchData();
  }, []);


  useEffect(() => {
    fetchDistrictData();
  }, [currentOrg]);
  
  const fetchDistrictData = async () => {
    try {
      setUserLoading(true);
      const has_loaded  = localStorage.getItem("admin_last_update");
      if(has_loaded && moment().isSame(has_loaded,"date")){
        setDataLoading(false);
      }else{
        setDataLoading(true);
      }
      if (!isEmpty(currentOrg)) {
        // await handleUpdateLicences(currentOrg.id)
        await Promise.allSettled([
          dispatch(handleListGetSchool(currentOrg.id)),
          dispatch(handleListDistUsers(currentOrg.id)),
          dispatch(handleListDistTeacher(currentOrg.id)),
          dispatch(handleListDistStudent(currentOrg.id))
        ])
        // dispatch(handleListGetSchool(currentOrg.id));
        // dispatch(handleListDistUsers(currentOrg.id));
        // dispatch(handleListDistTeacher(currentOrg.id));
        // dispatch(handleListDistStudent(currentOrg.id));
      }
      setUserLoading(false);
    } catch (error) {
      setUserLoading(false);
    } finally {
      localStorage.setItem("admin_last_update", moment().toISOString());
      setDataLoading(false)
    }
  };

  let usersStudents = filter(users, (u) => u.role === "student");
  const usersTeachers = filter(users, (u) => u.role === "teacher");
  const teacherByOrg = groupBy(usersTeachers, (u) => u.orgId);
  const studentsByOrg = groupBy(usersStudents, (u) => u.orgId);

  // Array Books
  let listBooks = [];

  for (var book in booksAllList) {
    let isNotAssignable = booksAllList[book].isNotAssignable;
    let _data = {
      id: booksAllList[book].id,
      description: booksAllList[book].description,
      count: booksAllList[book].count,
    };
    if (!isNotAssignable) {
      listBooks.push(_data);
    }
  }

  //  -- Search -------------------
  const [searchedUser, setSearchedUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  //  -- User -----------------------
  const [userData, setUser] = useState(null);

  //  -- Search -----------------------
  const [searResultView, setSearResultView] = useState(false);



  const closeSearchView = () => {
    setSearResultView(false);
  };

  const onSearchItemClick = user => {
    setDataTab("search");
    setSearchText("");
    setSearchedUser([]);
    setSelectedUser(user);
    closeSearchView();

    if (user && user.role == "student") {
      onStudenSelection(user)
    }
  }

  const onStudenSelection = async student => {
  }
  const openLoginInNewWindow = async (userId) => {
    setLoginLoader(userId);
    const key = v4()
    await createKeystore(userId, key)
    const url = `${window.location.origin}?key=${key}`
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
    setLoginLoader(null);
  }

  function navigationToMenu(id) {
    setDataTab(id);
    if (id == 'see_districts') {
      setSelectedDist('')
    }
  }
  return (
    <SentryWrap>
      <Modal
        open={dataLoading}
      >
        <VideoContainer>
          <Spin size="large" />
        </VideoContainer>
      </Modal>
      <Wrapper className={"adminForm"}>
        {/* <OverviewText>Data Browser</OverviewText> */}

        <MenuWrapper top={"10px"} style={{ width: "100%" }}>
          {MENU.map((x) => (
            <MenuBtn style={{ borderBottom: dataTab === x?.id ? '5px solid rgb(28,49,115)' : '5px solid #fcfcfc', color: dataTab === x?.id ? 'rgb(28,49,115)' : 'black' }} key={x.id} onClick={() => navigationToMenu(x.id)}>
              {x.value}
            </MenuBtn>
          ))}
        </MenuWrapper>
        {dataTab == MENU[0].id && (
          <>
            <SectionsView
              label={"Schools"}
              currentView={currentView}
              id={idSchoolBox}
              backButton={() => { }}
              onCloseView={() => {
                setCurrentView(currentView == "list" ? "boxes" : "list");
              }}
              onClose={() => { }}
            >
              <DistrictSchoolTable
                districts={districts}
                schools={schools}
                selectedDist={selectedDist}
                allSchools={allSchools}
                userloading={userloading}
                studentsByOrg={studentsByOrg}
                teacherByOrg={teacherByOrg}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                  dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />
            </SectionsView>
          </>
        )}
        {dataTab == MENU[1].id && (
          <>
            <SectionsView
              label={"Teachers"}
              currentView={currentView}
              id={idSchoolBox}
              backButton={() => { }}
              onCloseView={() => {
                setCurrentView(currentView == "list" ? "boxes" : "list");
              }}
              onClose={() => { }}
            >
              <DistrictTeacherTable
                users={users}
                schools={schools}
                classes={classes}
                distTeacher={distTeacher}
                userloading={userloading}
                selectedDist={selectedDist}
                teacherByOrg={teacherByOrg}
                studentsByOrg={studentsByOrg}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                  dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />

            </SectionsView>
          </>
        )}
        {dataTab == MENU[2].id && (
          <>
            <SectionsView
              label={"Students"}
              currentView={currentView}
              id={idSchoolBox}
              backButton={() => { }}
              onCloseView={() => {
                setCurrentView(currentView == "list" ? "boxes" : "list");
              }}
              onClose={() => { }}
            >
              <DistrictStudentTable
                classes={classes}
                users={users}
                distStudents={distStudents}
                schools={schools}
                allUser={allUser}
                allClasses={allClasses}
                allSchools={allSchools}
                userloading={userloading}
                selectedDist={selectedDist}
                teacherByOrg={teacherByOrg}
                studentsByOrg={studentsByOrg}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                  dispatch(handleGetOrganization(currentOrg?.id));
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
              />

            </SectionsView>
          </>
        )}
        {dataTab == MENU[3].id && (
          <>
            <SectionsView
              label={"Classes"}
              currentView={currentView}
              id={"userbox"}
              backButton={() => { }}
              onCloseView={() => { }}
              onClose={() => {
                setDataTab("");
              }}
            >
              <DistrictClassesTable
                users={users}
                allUser={allUser}
                classes={classes}
                allClasses={allClasses}
                allSchools={allSchools}
                userloading={userloading}
                teacherByOrg={teacherByOrg}
                selectedDist={selectedDist}
                schools={[...schools, currentOrg]}
                onUserAddOrUpdate={() => {
                  fetchDistrictData();
                }}
                onTabsJump={(data, distData) => {
                  setDataTab(data);
                  setSelectedDist(distData)
                }}
                listBooks={listBooks}
              />
            </SectionsView>
          </>
        )}
      </Wrapper>
    </SentryWrap>
  );
};

//  -- Prop types ---------------------
DistrictDataBrowsers.propTypes = {};

//  -- Default props ------------------
DistrictDataBrowsers.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  activities: state.activities,
  classes: state.classes,
  currentOrg: state.currentOrg,
  districts: state.districts,
  interactions: state.interactions,
  quizzes: state.quizzes,
  schools: state.schools,
  users: state.users,
  booksAllList: state.booksAllList,
  allSchools: state.allSchools,
  allUser: state.allUser,
  allClasses: state.allClasses,
  distTeacher: state.distTeacher,
  distStudents: state.distStudents
});

export default connect(mapStateToProps, null)(DistrictDataBrowsers);
