//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { find, groupBy, forEach, isEmpty,filter,orderBy,get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

//  -- Components ---------------------
import { Col, Row } from 'antd';
import InitialLoading from 'components/InitialLoading';
import Loading from 'components/Loading';
import Note from 'components/NoteNew';
import Section from 'components/Section';
import StudentNotes from 'components/StudentNotesForTeacher';
import StudentNotesList from 'components/StudentNotesList';
import { handleListUserNotes } from 'store/notes/thunks';
//  -- Config -------------------------
import { NEWSLETTERS } from 'utils/config/reference';
import { useResources, useVideos } from 'store/resources/selectors';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Lib ----------------------------
import { imageFetchDinamic, newsImageFetch,imageFetchFromPodcast,getPodcastBookName } from 'utils/lib/ebookHelper';

//  -- Thunks -------------------------
import { handleListBubbleClassNotes } from 'store/notes/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import { handleListClassQuestions } from 'store/questions/thunks';
import { handleListAllBooks } from 'store/books/thunks';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import ReactHighcharts from "react-highcharts";
import moment from "moment";
import {
  NoNotesimg,
  SadEmojiimg,
  mobileMenuimg
} from "utils/assets/images"


const config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0,
      height: 140,
      animation: true,
      backgroundColor: "transparent",
    },
    title: {
      text: "0",
      align: "center",
      verticalAlign: "middle",
      y: 10,
  
      style: {
        fontWeight: 200,
        fontSize: "24px",
        color: "#fff",
      },
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return `${this.key ?? ""}` + ":" + this.y + "%";
      },
    },
    plotOptions: {
      series: {
        animation: {
          duration: 2000,
        },
      },
      pie: {
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            legendItemClick: () => false,
          },
        },
        showInLegend: false,
        borderWidth: 0,
        colors: ["rgb(90,177,208)", "rgb(130,222,228)"],
      },
    },
    series: [
      {
        innerSize: "67%",
        data: [
          { name: "Overall Grade", y: 1 },
          { name: "", y: 1 },
        ],
      },
    ],
  };
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
    height: 100%;
    min-height: 300px;
    padding: 64px 0px;
    width: 100%;

    @media (min-width: 800px) {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0px;
    }
`;

const HeaderContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${COLOR.black700};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
`;

const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;

const ImageContiner = styled.div`
    background-color: transparent;
    border: none;
    flex: 1;
    outline: none;
    width: 100%;
`;

const Title = styled.span`
    color: ${COLOR.black100};
    display: inline-block;
    font-size: 16px;
    font-weight: 300;

    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-bottom: 48px;
    width: 100%;
`;

const ContentWrapper = styled.div`
  height: ${(p) => (p.height ? p.height : "30%")};
`;
const OverallGrade = styled.div`
  width: 190px;
`;
const OverallGradeSection = styled.div``;
const OverallGradeTitleSec = styled.div`
  background-color: ${COLOR.indigo300};
  min-height: 100px;
  flex-direction: row;
  display: flex;
`;
const BookTitileSection = styled.div`
  background-color: ${COLOR.indigo300};
  min-height: 50px;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
`;
const BookTitle = styled.div`
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  `
const NoteDateTitle = styled.div`
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  `
const OverallGradeTitle = styled.div`
  color: white;
  font-size: 24px;
  font-weight: 300;
  white-space: pre-line;
  margin:auto;
  margin-left: 15%;
  line-height: 24px;
  margin-top: 4%;
`;
const SearchByTitle = styled(OverallGradeTitle)`
  color: white;
  font-weight: 300;
  white-space: pre-line;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 13px;
  text-align: end;
  margin-right: 10px;
  margin-left: auto;
`;
const OverallGradeTitleWrapper = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;
const WrapperSec1 = styled.div`
  height: 100%;
  width: 100%;
  border:1px solid #abaeb3;
  border-bottom: 10px solid rgb(130,222,228);
  margin-bottom: 0px;
  border-bottom-width:${p=>p.bottomWidth?p.bottomWidth:"10px"};
`;
const DropdownSec = styled.div`
  background-color: ${COLOR.green100};
  min-height: 50px;
  width: 100%;
`;
const Dropdown = styled.button`
  width: 200px;
  height: 30px;
  margin-top: 10px;
  background-color: ${COLOR.white};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 3px;
  border-width: 0px;
`;
const DropdownTitle = styled.div`
  color: ${COLOR.black300};
  font-size: 12px;
  font-weight: 600;
  width: 150px;
`;

const DropdownContainer = styled.div`
  display: flex;
  position:relative;
`


const SectionContainer = styled.div`
  min-height:250px;
  width:100%;
  overflow-y:auto;
`
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top:${p=>p.paddingTop}
  `
const NoAssignmentImg = styled.img`
  width: 100%;
  object-fit: cover;
  `
const NoNotes = styled.div`
width: 100%;
min-height: 300px;
justify-content: center;
align-items: center;
`
const NoNotesText =styled.div`
align-items: center;
color: #35383d;
font-size: 28px;
font-weight: 800;
white-space: pre-line;
margin: auto;
line-height: 28px;
text-transform: uppercase;
text-align: center;
justify-content: center;
`

const Input = styled.input`
    background-color: transparent;
    border: 1px dashed transparent;
    border-radius: 3px;
    box-shadow: none;
    font-size: inherit;
    font-weight: 600;
    outline: none;
    padding: 0px 2px;
    transition: all 300ms ease;
    width: 170px;
`;
const SadEmogiImg = styled.img`
height: 240px;
width: 300px;
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
margin: auto;
object-fit: cover;
`
const Mobicon = styled.img`
  height: 30px;
  width: 30px;
  align-self: center;
  object-fit:cover;
`
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Student Notes Container
//
//  -- Props
//  ...
//
const NotesContainer = ({
    currentClass,
    currentUser,
    classNotes,
    classUsers,
    booksAllList,
    notes,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(false);
    const [selected, setSelected] = useState(false);
    const [chapter, setChapter] = useState(null);
    const [page, setPage] = useState(null);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const [notesForClass,setNotesForClass] = useState([]);
    const [notsForClassFiltered,setNotesForClassFiltered] = useState([])
    const overChartRef=React.useRef(null)
    const [tempValue,setTempValue] = useState("");
    const [notesForRender,setNotesForRender]=useState([])
    const [notesDate,setNotesDate]=useState(moment().format('MM/DD/YYYY'))
    const [filteredNotesForStudent,setFilteredNotesForStudent]=useState([])
    const videos = useVideos()
    const resources= useResources()
    const isTeacher=currentUser?.role==="teacher"
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = get(orgPermissions, "books", [])
    const classNotesArray = useMemo(() => {
      const archivedBooksSet = new Set();
      const archived_books = get(currentClass, "archived_books", []);
  
      archived_books.forEach(abk => {
          archivedBooksSet.add(abk.bookId);
      });
  
      return filter(classNotes, k => {
          if (archivedBooksSet.has(get(k, "chapter"))) {
              return false;
          }
  
          if (bookPermissions.length === 0) {
              return true;
          }
  
          const bookId = get(k, "chapter");
          const bookaccess = find(bookPermissions, ["bookId", bookId]);
  
          return bookaccess ? bookaccess.available : true;
      });
  }, [classNotes, currentClass, bookPermissions]);
  
  const notesArray = useMemo(() => {
    const archivedBooksSet = new Set();
    const archived_books = get(currentClass, "archived_books", []);

    archived_books.forEach(abk => {
      archivedBooksSet.add(abk.bookId);
    });

    return filter(notes, k => {
      if (archivedBooksSet.has(get(k, "chapter"))) {
        return false;
      }

      if (bookPermissions.length === 0) {
        return true;
      }

      const bookId = get(k, "chapter");
      const bookaccess = find(bookPermissions, ["bookId", bookId]);

      return bookaccess ? bookaccess.available : true;
    });
  }, [notes, currentClass, bookPermissions]);
    const hasNotesForClass=isTeacher?classNotesArray.length>0:notesArray.length>0
    useEffect(() => {
        setTimeout(() => setMounted(true), 250);
    }, []);
    const getNameOfBook=(c)=>{
      if(!c) return ""
        let book = find(booksAllList,function(item){return item.id==c})
      if(book){
          return book.description;
      }else{
          return getPodcastBookName(c)
      }
 }
    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
           let isVisited=localStorage.getItem("notes_visited")==currentClass.id;
            setLoading(true);
            if(isVisited){
              setLoading(false)
            }
            if(currentUser&&currentUser?.role=="student"){
              await dispatch(handleListUserNotes(currentUser?.id));
            }else{
              await dispatch(handleListBubbleClassNotes(currentClass.id));
              await dispatch(handleListClassUsers(currentClass.id));
            }
            if (currentUser?.role === 'teacher') {
               await  dispatch(handleListClassQuestions(currentClass.id))
            }
            setLoading(false);
            localStorage.setItem("notes_visited",currentClass.id)
            await dispatch(handleListAllBooks());
        }
        fetchData();
    }, [currentClass.id]);
    useEffect(()=>{
      if(isTeacher){
        const notesByStudentCount =Object.keys(groupBy(classNotesArray, 'userId')).length;
        const numberOfStudent=classUsers?.length??0;
        if(numberOfStudent>0){
        const overallGrade=Math.round((notesByStudentCount/numberOfStudent)*100);
        config.series[0].data = [
          { name: "Notes taken", y: overallGrade },
          { name: "", y: 100 - overallGrade},
        ];
        config.title.text = `${notesByStudentCount??"0"}`;
        if (overChartRef.current) {
          overChartRef.current.chart.update(config);
        }
        setNotes()
      }
    }
    },[classNotes,classUsers])
    useEffect(()=>{
      if(!isTeacher&&notesArray?.length>0){
        setDefaultSelectedNotesForStudent(notesArray)
        setFilteredNotesForStudent(notesArray)
        config.series[0].data = [
          { name: "Notes taken", y: notesArray?.length??"0" },
          { name: "", y: 100},
        ];
        config.title.text = `${notesArray?.length??0}`;
        if (overChartRef.current) {
          overChartRef.current.chart.update(config);
        }
      }
    },[notes,classUsers])
    const setNotes=()=>{
      let allnotes=renderNotes()
      setNotesForClass(allnotes)
      setNotesForClassFiltered(allnotes)
      setDefaultSelectedNotes(allnotes)
    }
const renderNotes = () => {
    const notesByUserId = groupBy(classNotesArray, 'userId');
    const data = [];
    Object.entries(notesByUserId).forEach(([userId, userNotes]) => {
        const sortedNotes = {};

        const chapterGroups = groupBy(userNotes, 'chapter');
        Object.entries(chapterGroups).forEach(([chapter, chapterNotes]) => {
            const pageGroups = groupBy(chapterNotes, 'page');
            sortedNotes[chapter] = pageGroups;
        });

        const user = classUsers.find(user => user.id === userId);
        if (user) {
            data.push({
                notes: sortedNotes,
                student: user,
            });
        }
    });

    return data;
};

    const setDefaultSelectedNotesForStudent=(notes)=>{
      let seletedNotes=notes[0]
      if(!Array.isArray(seletedNotes)&&typeof seletedNotes=="object"){
        setSelected(seletedNotes.id)
        selectNotesForStudent(seletedNotes.chapter,seletedNotes.page,seletedNotes)
      }
    }
    const selectNotesForStudent=(chapter,page,note)=>{
      try{
      setImageLoading(false);
      setChapter(chapter);
      setPage(page);
      setSelected(true);
      if(note){
        setNotesForRender([note]);
        if(note.createdAt&&!isNaN(parseInt(note.createdAt))){
          setNotesDate(moment(new Date(parseInt(note.createdAt))).format('MM/DD/YYYY'))
        }
      
      }
    } catch (error) {
      setImageLoading(false);
      console.log(error);
    }
    }
    const setDefaultSelectedNotes=(notes)=>{
      try {
        let notesForStudent ={}
        if(selectedStudentId){
          notesForStudent=find(notes,x=>{
            return get(x,"student.id","").toLocaleLowerCase()==selectedStudentId.toLocaleLowerCase()
           });
         }else{
          notesForStudent=notes[0];
         }
        setImageLoading(true);
        if (notesForStudent) {
          const _chapter =chapter?chapter:Object.keys(notesForStudent.notes ?? {})[0];
          setSelectedStudentId(get(notesForStudent,"student.id",""));

          const  slectedNotesByChapter =notesForStudent.notes[_chapter];
          if (slectedNotesByChapter) {
            const _page = page?page:Object.keys(slectedNotesByChapter)[0];
            const  selectedNotesByPage = slectedNotesByChapter[_page];
            if (selectedNotesByPage) {
              setChapter(_chapter);
              setPage(_page);
              setSelected(true);
             const  noteArray=orderBy(selectedNotesByPage,obj=>moment(obj?.createdAt),"desc")
             if(!isNaN(parseInt(noteArray[0].createdAt))){
              setNotesDate(moment(parseInt(noteArray[0].createdAt)).format('MM/DD/YYYY'))
             }
           
             setNotesForRender(noteArray)
            }
          }
        }
        setImageLoading(false);
      } catch (error) {
        setImageLoading(false);
        console.log(error);
      }
    }
    const selectNote = (chapter, _page, studentId, i,allNotes) => {
      try {
      setSelectedStudentId(studentId);
       let noteArray= notsForClassFiltered[i]?.notes[chapter][_page]
       if(Array.isArray(allNotes)&&notsForClassFiltered.length==0&&allNotes.length>0){
        noteArray= allNotes[i]?.notes[chapter][_page] 
        }
        noteArray=orderBy(noteArray,obj=>moment(obj?.createdAt),"desc")
        setChapter(chapter)
        setPage(_page)
        setSelected(true);
        if(Array.isArray(noteArray)){
          if(noteArray[0]?.createdAt){
            if(!isNaN(parseInt(noteArray[0].createdAt))){
                setNotesDate(moment(parseInt(noteArray[0].createdAt)).format('MM/DD/YYYY'))
            }
          }else{
            setNotesDate("")
          }
          setNotesForRender(noteArray)
        }else{
          setNotesDate("")
          setNotesForRender([])
        } 
      } catch (error) {
        console.log(error);
      }
       
    };
    const handleChange = (e) => {
      setTempValue(e.target.value)
      filterNotes(e.target.value)
    }
    const filterNotes = (text) => {
    if(isTeacher){
      if(typeof text=="string"&&text.length>0){
        let txt=text.toLocaleLowerCase().trimLeft().trimRight()
        let fnotes=  filter(notesForClass,k=>{
        let name=(k.student?.firstName?.toLocaleLowerCase()??"")+" "+( k.student?.lastName?.toLocaleLowerCase()??"")
            name=name.trimLeft()
            name=name.trimRight()
          let hasName =name.includes(txt) 
          let hasInUserName = k.student?.username?.toLocaleLowerCase().includes(txt)
          return (
            hasName||hasInUserName
          );
        })
        if(fnotes.length>0){ 
          setNotesForClassFiltered(fnotes)
        }else{
          setNotesForClassFiltered([])
        }
      }else{
        setNotesForClassFiltered(notesForClass)
      }
    }else{
      if(typeof text=="string"&&text.length>0){
        let txt=text.toLocaleLowerCase().trimLeft().trimRight()
        let fnotes=  filter(notesArray,k=>{
          return getDisplayName(k.chapter,k.page).toLowerCase().includes(txt)
        })
        if(fnotes.length>0){ 
          setFilteredNotesForStudent(fnotes)
        }else{
          setFilteredNotesForStudent([])
        }
      }else{
        setFilteredNotesForStudent(notesArray)
      }
    }
    }
    const renderNotesSection=()=>{
      if(isTeacher){
        if(notsForClassFiltered.length > 0 ){
          return( <SectionContent
            paddingTop={"16px"}
          >
          {notsForClassFiltered.map((x, i) => (
             <StudentNotes
                key={get(x,"student.id",Math.random())}
                index={i}
                notes={get(x,"notes",[])}
                onClick={selectNote}
                student={get(x,"student",{})}
                getDisplayName={getDisplayName}
                booksAllList={booksAllList}
                />
           ))}
          </SectionContent>)
        }
        else{
          return(<NoAssignmentImg src={NoNotesimg} />)
        }
      }else{
      if(filteredNotesForStudent.length > 0 ){
        return(<SectionContent
          paddingTop={"16px"}
        >
          <StudentNotesList 
          notes={filteredNotesForStudent}
          onClick={selectNotesForStudent} 
          getDisplayName={getDisplayName}
          booksAllList={booksAllList}  />
         </SectionContent>)
    }else{
      return(<NoAssignmentImg src={NoNotesimg} />)
    }
      }
    }
    const getNameVideo = ((idVideo) => {
      const videoDisplayName = get(find(videos,x => x.id == idVideo),'displayName','');
      return 'Video '+videoDisplayName;
  });
    const getDisplayName=(chapter,page)=>{
      let NumberMasked = find(find(booksAllList,["id",chapter])?.pages,k=>k.pageId == page)?.NumberMasked
      let _page = NumberMasked?NumberMasked:page
        let chapterName=""
        if(chapter&&chapter.length<=2){
          chapterName =getNameOfBook(chapter)
        }else{
          if(NEWSLETTERS[chapter]){
              chapterName = "Newsletter" 
          }else if(find(videos,['id',chapter])){
              chapterName= getNameVideo(chapter)
          }else{
              chapterName= "Podcast";
          }
        }
      let chapterSubname=""
      if(chapter.length > 5 && !NEWSLETTERS[chapter]){
        chapterSubname = ""
      }else{
        chapterSubname = ` | Pg. ${_page}`
      }
    return chapterName+" "+chapterSubname
    }

    return loading ? (
        <CenterContainer>
            <InitialLoading mounted={mounted} />
        </CenterContainer>
    ) : (<>
         <HeaderContainer style={{marginTop:50}}>
             <Mobicon src={mobileMenuimg} />
                   <Title>{currentClass?.displayName} Notas</Title>
            </HeaderContainer>
        <Wrapper>
        <Row style={{width:"100%",marginTop:10}}>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 10 }}
            >
              <WrapperSec1>
                <OverallGradeSection>
                  <OverallGradeTitleSec>
                    <OverallGrade>
                      <ReactHighcharts config={config} ref={overChartRef} />
                    </OverallGrade>
                    <OverallGradeTitleWrapper>
                      <OverallGradeTitle>
                        {isTeacher? "See what notes your \n students are taking":"See your notes by page \n number or book name"}
                      </OverallGradeTitle>
                    </OverallGradeTitleWrapper>
                  </OverallGradeTitleSec>
                  <DropdownSec>
                    <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }} >
                     <SearchByTitle>{isTeacher?"Search by Name":"Search by page or book"}</SearchByTitle>
                     </Col>
                     <Col xs={{ span: 24 }} lg={{ span: 8 }} >
                        <DropdownContainer>
                        <Dropdown>
                        <Input
                          id="page"
                          onChange={handleChange}
                          value={tempValue}
                          placeholder='Search'
                      />
                        </Dropdown>
                        </DropdownContainer>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 8 }} >
                        </Col>
                    </Row>
                  </DropdownSec>
                </OverallGradeSection>
                <SectionContainer>
                 {renderNotesSection()}
                </SectionContainer>
              </WrapperSec1>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 14 }}
              style={{paddingLeft:10,
              }}
            >
            <WrapperSec1 bottomWidth={"6px"}>
              <BookTitileSection>
                <BookTitle>book: {getNameOfBook(chapter)}</BookTitle>
                <NoteDateTitle>date taken or edited: {notesDate}</NoteDateTitle>
              </BookTitileSection>
              <Row style={{height:"87%"}}>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 16 }}
              >
                {imageLoading ? (
                     <CenterContainer>
                          <Loading />
                       </CenterContainer>
                        ) : (<>
                {selected && (NEWSLETTERS[chapter] || chapter.length > 0) ? (
                  <ImageContiner>
                  <Image src={NEWSLETTERS[chapter] ? newsImageFetch(chapter, page)?.src :imageFetchFromPodcast(chapter,resources)?imageFetchFromPodcast(chapter,resources): imageFetchDinamic(chapter, page, booksAllList)?.resource} alt='' />
                  </ImageContiner>):<NoNotes>
                    {!hasNotesForClass&&<>
                      <NoNotesText style={{marginTop:50}}>
                        {isTeacher? "No Worries, You Will\n Have Tons of student \n notes when your \n student start using\n their note-taking tools.":"No Notes Yet"}
                      </NoNotesText>
                      <NoNotesText>
                      <SadEmogiImg src={SadEmojiimg} />
                    </NoNotesText>
                  </>}
                  </NoNotes>
                } </>)}                 
                </Col>
            <Col
                xs={{ span: 24 }}
                lg={{ span: 8 }}
                style={{paddingLeft:10,paddingRight:10 }}
              >
                {hasNotesForClass?
                selected&&(NEWSLETTERS[chapter] || chapter.length >0) &&
                (notesForRender.length>0&&notesForRender.map((x, i) => (<Note key={i} note={x} view={'notas'} />))):
                <Note hasNoNotes/>
              }
                </Col>
              </Row>
            </WrapperSec1>
            </Col>
         </Row>   
        </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
NotesContainer.propTypes = {};

//  -- Default props ------------------
NotesContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    classNotes: state.classNotes,
    classUsers: state.classUsers,
    booksAllList: state.booksAllList,
    notes:state.notes,
});

export default connect(mapStateToProps, null)(NotesContainer);
