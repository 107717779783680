//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createApExams = () => ({ type: ActionTypes.apexams.createApExams });
export const createApExamsFail = (returnedPayload) => ({ type: ActionTypes.apexams.createApExamsFail, payload: returnedPayload });
export const createApExamsSuccess = (returnedPayload) => ({ type: ActionTypes.apexams.createApExamsSuccess, payload: returnedPayload });

export const updateApExams = () => ({ type: ActionTypes.apexams.updateApExams });
export const updateApExamsFail = (returnedPayload) => ({ type: ActionTypes.apexams.updateApExamsFail, payload: returnedPayload });
export const updateApExamsSuccess = (returnedPayload) => ({ type: ActionTypes.apexams.updateApExamsSuccess, payload: returnedPayload });

export const deleteApExams = () => ({ type: ActionTypes.apexams.deleteApExams });
export const deleteApExamsFail = (returnedPayload) => ({ type: ActionTypes.apexams.deleteApExamsFail, payload: returnedPayload });
export const deleteApExamsSuccess = (returnedPayload) => ({ type: ActionTypes.apexams.deleteApExamsSuccess, payload: returnedPayload });


//  -- List apexams --------------
export const listApExams = () => ({ type: ActionTypes.apexams.listApExams });
export const listApExamssFail = (returnedPayload) => ({ type: ActionTypes.apexams.listApExamsFail, payload: returnedPayload });
export const listApExamssSuccess = (returnedPayload) => ({ type: ActionTypes.apexams.listApExamsSuccess, payload: returnedPayload });

