//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as Actions from "./actions"



//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.hyperlikModalInfo;

//  -- Examples array selector --------

export const useHyperlinkModal = () => {
    const hyperlinkInfo = useSelector(selector, shallowEqual)
    const dispatch = useDispatch()
    const invokeHyperlink = (links) => {
        dispatch(Actions.invokeHyperlinkModal(links))
    }
    const revokeHyperlink = () => {
        dispatch(Actions.revokeHyperlinkModal())
    }
    return [hyperlinkInfo, invokeHyperlink, revokeHyperlink];
}
