import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, IconButton, Button, TextField, Typography } from '@material-ui/core';
import { PauseCircleFilled, Replay, PlayCircleFilled } from '@material-ui/icons';
import styled from 'styled-components';
import { Progress } from 'antd';
import { useCurrentUser } from 'store/users/selectors';
import { useCurrentClass } from 'store/classes/selectors';
import { useCollectionWidgets } from 'utils/methods/firebaseWidgets';
import { v4 } from 'uuid';
import { useFirebase } from 'containers/FirebaseContext';
import {
    collection,
    onSnapshot,
    query,
    where,
} from 'firebase/firestore';
import { get } from 'lodash';

const TimerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
//   padding: 20px;
margin-top: 20px;
`;

const CircularWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
//   margin-top:38px;
`;

const TimeDisplay = styled(Typography)`
    width: 80%;
    border-bottom: 2px solid;
    text-align: center;
    border-bottom: 4px solid transparent;
    border-image: linear-gradient(0.96turn, rgba(121,47,252), rgba(121,47,252), rgba(121,47,252));
    border-image-slice: 1;
    font-weight: 400;
`;

const AddMinuteButton = styled.button`
    // transform: translate(50%,-50%);
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    top: 12%;
    // left: 25%;
    border-radius: 15px;
    background-color: rgba(236,240,242);
    padding: 5px 20px;
    border: 0px;
    cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
    width: 150px;
    position: absolute;
    bottom: 14%;
`;

const TimeInput = styled(TextField)`
  width: 80px;
  margin: 0 5px;
  && .MuiInput-underline:before {
    display: none; // Remove bottom border
  }
  && .MuiInput-underline:hover:not(.Mui-disabled):before {
    display: none; // Remove bottom border on hover
  }
  && .MuiInput-underline:after {
    display: none; // Remove bottom border on focus
  }
`;
const TimmerInner = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;   
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;
const TimeCloneMark = styled.span`
    font-weight:bold;
`;
const GradientCircularProgress = ({ percent, thickness, clockSize }) => (
    <Progress
        type="circle"
        percent={percent}
        width={clockSize.width - 40}
        showInfo={false}
        trailColor={"rgba(236,240,242)"}
        strokeWidth={thickness}
        strokeColor={{
            '0%': 'rgba(121,47,252)',
            '50%': 'rgba(74,144,201)',
            '100%': 'rgba(89,190,210)',
        }}
    />
);

const formatTime = (time) => {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60) % 60;
    let hours = Math.floor(time / 3600);

    return [hours, minutes, seconds]
        .map((t) => (t < 10 ? `0${t}` : t))
        .join(' : ');
};

function TimerWheelWorker({ clockSize, isTeacher, setEditorFocus }) {
    const currentClass = useCurrentClass();
    const currentUser = useCurrentUser();
    const { firestore } = useFirebase();
    const { createCollectionTimer } = useCollectionWidgets();
    const [time, setTime] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [inputValues, setInputValues] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [initialTimer, setInitialSubmissionTimer] = useState(0);
    const [editEnabled, setEditEnabled] = useState(false);
    const [worker, setWorker] = useState(null);
    const [widgetTimer, setWidgetTimer] = useState({});
    const audioRef = useRef(new Audio(require("utils/assets/audio/timer_end.mp3")));

    useEffect(() => {
        const unsubscribe = listenToWidgetTimer(get(currentClass, 'id', ''));
        return () => {
            unsubscribe();
        };
    }, [firestore, currentClass, currentUser, worker])

    const listenToWidgetTimer = (classID) => {
        if (!classID || !currentUser) {
            return () => { };
        }
        const collectionRef = collection(firestore, "widget_timer");
        const q = query(collectionRef, where("class_id", "==", classID));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const doc = querySnapshot.docs[0];
            const sharableData = { id: doc?.id, ...doc?.data() };
            setWidgetTimer(sharableData)
            if (worker && sharableData?.status === 'start') {
                worker.postMessage({ command: sharableData?.newState ? 'start' : 'stop', time: sharableData?.time });
            } else if (worker && sharableData?.status === 'reset') {
                worker.postMessage({ command: 'reset' });
            } else if (worker && sharableData?.status === 'add_minutes') {
                worker.postMessage({ command: 'update', time: sharableData?.time });
            } else if (worker && sharableData?.status === 'done') {
                worker.postMessage({ command: 'done' });
            }
        }, (error) => {
            console.error("Error listening to session changes:", error);
        });

        return unsubscribe;
    }

    useEffect(() => {
        const audio = audioRef.current;
        audio.loop = true;
        audio.volume = 1;

        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);

    useEffect(() => {
        if (isActive && time > 0 && isTeacher) {
            if (time <= 5) {
                audioRef.current.play();
            }
        } else {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [isActive, time]);
    useEffect(() => {
        const newWorker = new Worker(`${process.env.PUBLIC_URL}/timeWorker.js`);
        setWorker(newWorker);
        newWorker.onmessage = (event) => {
            switch (event.data.type) {
                case 'tick':
                    if (event.data.countdownTime >= 0) {
                        setTime(event.data.countdownTime);
                    }
                    break;
                case 'done':
                    setIsActive(false);
                    break;
                case 'reset':
                    setTime(event.data.countdownTime);
                    break;
            }
        };
        return () => newWorker.terminate();
    }, []);

    const handleStart = () => {
        if (inputValues?.hours === 0 && inputValues?.minutes === 0 && inputValues?.seconds === 0) return;
        const totalTime = parseInt(inputValues.hours || 0) * 3600 +
            parseInt(inputValues.minutes || 0) * 60 +
            parseInt(inputValues.seconds || 0);
        if (totalTime > 0) {
            setIsActive(true);
            setEditEnabled(false);
            setInitialSubmissionTimer(totalTime);
            setTime(totalTime);
            // worker.postMessage({ command: 'start', time: totalTime });
            if (widgetTimer?.id) {
                const updateTimer = {
                    ...widgetTimer,
                    time: totalTime,
                    newState: !isActive,
                    status: 'start',
                    initialSubmissionTimer: initialTimer
                }
                createCollectionTimer(updateTimer)
            } else {
                const timerWidgets = {
                    id: v4(),
                    class_id: currentClass?.id,
                    created_by: currentUser?.id,
                    created_at: new Date(),
                    time: totalTime,
                    newState: !isActive,
                    status: 'start',
                    initialSubmissionTimer: initialTimer
                }
                createCollectionTimer(timerWidgets)
            }
        }
    };

    const handlePause = () => {
        if (inputValues?.hours === 0 && inputValues?.minutes === 0 && inputValues?.seconds === 0) return;
        // if (inputValues?.hours > 0 || inputValues?.minutes > 0 || inputValues?.seconds > 0) {
            if (widgetTimer?.id) {
                const updateTimer = {
                    ...widgetTimer,
                    time: time,
                    newState: !isActive,
                    status: 'start',
                    initialSubmissionTimer: initialTimer
                }
                createCollectionTimer(updateTimer)
            } else {
                const timerWidgets = {
                    id: v4(),
                    class_id: currentClass?.id,
                    created_by: currentUser?.id,
                    created_at: new Date(),
                    time: time,
                    newState: !isActive,
                    status: 'start',
                    initialSubmissionTimer: initialTimer
                }
                createCollectionTimer(timerWidgets)
            }
            const newState = !isActive;
            setIsActive(newState);
        // }
    };

    const handleReset = () => {
        setIsActive(false);
        setTime(0);
        setInitialSubmissionTimer(0);
        setInputValues({
            hours: 0,
            minutes: 0,
            seconds: 0,
        });
        if (widgetTimer?.id) {
            const updateTimer = {
                ...widgetTimer,
                time: 0,
                newState: isActive,
                status: 'reset',
                initialSubmissionTimer: 0
            }
            createCollectionTimer(updateTimer)
        }
    };

    const handleAddOneMinute = () => {
        setInitialSubmissionTimer((prevTime) => prevTime ? prevTime + 60 : 60)
        const newInitialSubmissionTime = (initialTimer || 0) + 60;
        const newTime = (time || 0) + 60;
        setTime(newTime);
        setInputValues({
            hours: Math.floor(newTime / (60 * 60)),
            minutes: Math.floor(newTime / 60),
            seconds: newTime % 60,
        });
        if (widgetTimer?.id) {
            const updateTimer = {
                ...widgetTimer,
                time: newTime,
                newState: isActive,
                status: 'add_minutes',
                initialSubmissionTimer: newInitialSubmissionTime,
            }
            createCollectionTimer(updateTimer)
        }
    };
    const handleChange = (name) => (event) => {
        const value = event.target.value;
        if (!isNaN(value) && value !== '' && Number(value) >= 0) {
            setInputValues({ ...inputValues, [name]: Number(value) });
        }
    };
    

    const handleTouchMinute = (event) => {
        event.preventDefault();
        handleAddOneMinute();
    }

    const handleTouchStartReset = (event, value) => {
        event.preventDefault();
        if (value === 'start') {
            handlePause();
        } else {
            handleReset()
        }
    }

    const handleTouchEditTimer = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsActive(false);
        setEditEnabled(true);
    }

    const handleTouchEditStart = (event) => {
        event.preventDefault();
        handleStart()
    }

    const handleTouchStart = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const initialNewTimer = widgetTimer?.initialSubmissionTimer ? widgetTimer?.initialSubmissionTimer : initialTimer;
    return (
        <TimerContainer className='timeWhelContainer'>
            <CircularWrapper className='timeWhelWrap'>
                <GradientCircularProgress variant="static" percent={100 - (time / initialNewTimer * 100)} thickness={3} clockSize={clockSize} />
                <TimmerInner className='timeWhelTimer'>
                    {isTeacher && (
                        <AddMinuteButton size="small" onClick={handleAddOneMinute} onTouchStart={handleTouchMinute} style={{ fontSize: clockSize.width / 22 }}>
                            +1 min
                        </AddMinuteButton>
                    )}
                    {editEnabled && isTeacher ? <TimeDisplay onMouseEnter={()=> setEditorFocus(true)} onMouseLeave={()=> setEditorFocus(false)} style={{ paddingBottom: 4 }}>
                        <TimeInput
                            type="number"
                            value={inputValues.hours}
                            placeholder='HH'
                            onChange={handleChange('hours')}
                            onTouchStart={handleTouchStart}
                            inputProps={{ style: { textAlign: "center" } }}
                        /> <TimeCloneMark>:</TimeCloneMark>
                        <TimeInput
                            type="number"
                            value={inputValues.minutes}
                            placeholder='MM'
                            onChange={handleChange('minutes')}
                            onTouchStart={handleTouchStart}
                            inputProps={{ style: { textAlign: "center" } }}
                        /> <TimeCloneMark>:</TimeCloneMark>
                        <TimeInput
                            type="number"
                            value={inputValues.seconds}
                            autoFocus
                            placeholder='SS'
                            inputProps={{ style: { textAlign: "center" } }}
                            onChange={handleChange('seconds')}
                            onTouchStart={handleTouchStart}
                        />
                        <Button onClick={handleStart} onTouchStart={handleTouchEditStart} variant="contained" color="primary" disabled={isActive}>
                            Start
                        </Button>
                    </TimeDisplay> : <TimeDisplay style={{ fontSize: clockSize?.width / 10 }} onClick={() => {
                        setIsActive(false);
                        setEditEnabled(true);
                    }} onTouchStart={handleTouchEditTimer} variant="h2">{formatTime(time)}</TimeDisplay>}
                    {isTeacher && (
                        <Controls style={{width: clockSize.width < 300 ?  101 : 150}}>
                            <IconButton onClick={handlePause} onTouchStart={(e) => handleTouchStartReset(e, 'start')} size="medium" style={{ backgroundColor: "rgba(121,47,252)", height: clockSize.width < 300 ?  40 : 60, width: clockSize.width < 300 ?  40 : 60 }} disabled={((inputValues?.hours === 0 && inputValues?.minutes === 0 && inputValues?.seconds === 0))} >
                                {isActive ? <PauseCircleFilled style={{ color: "#fff" }} /> : <PlayCircleFilled style={{ color: "#fff" }} />}
                            </IconButton>
                            <IconButton onClick={handleReset} onTouchStart={(e) => handleTouchStartReset(e, 'reset')} size="medium" style={{ backgroundColor: "rgba(236,240,242)",height: clockSize.width < 300 ?  40 : 60, width: clockSize.width < 300 ?  40 : 60  }} disabled={((inputValues?.hours === 0 && inputValues?.minutes === 0 && inputValues?.seconds === 0))}>
                                <Replay />
                            </IconButton>
                        </Controls>
                    )}
                </TimmerInner>
            </CircularWrapper>
        </TimerContainer>
    );
}

export default TimerWheelWorker;
