import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CirclePicker } from 'react-color';
import _, { get } from "lodash"
import { Col, Row } from 'antd';
// import { Button } from '@material-ui/core';
import { Button } from 'antd';
import NotesTextEditor from 'components/NotesTextEditor';
import { handleBubbleUpdateNote, handleCreateBubbleNote, handleGetBubbleByBook, handleListBubbleUserNotes } from "store/notes/thunks";
import { useDispatch } from "react-redux";
import COLOR from "utils/constants/color";
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentOrg } from 'store/organizations/selectors';
import { useCurrentUser } from 'store/users/selectors';
import Loading from 'components/Loading';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
    width: 100%;
    // margin-top:20px;

    @media (max-width: 1024px) {
        margin-top:0px;
    }
`;
const FormHeader = styled.span`
  // color: ${COLOR.black200};
  display: inline-block;
  // font-size: 16px;
  // font-weight: 600;
  margin-bottom: 4px;
  // color: #000;
  font-family: Arial;
  font-size: 17.5px;
  font-weight: 700;
`;

const FormDescription = styled.span`
  color: ${COLOR.black400};
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    display:none;
}
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Note form
const   NoteForm = ({
    pageNotes,
    data,
    page,
    chapter,
    close = () => {},
    draggable= () => {}
}) => {
    const initialState = [{
        type: 'paragraph ',
        children: [
            {
                text: ''
            }
        ]
    }];

    const initialNotes = pageNotes?.content?.content ? pageNotes?.content?.content : pageNotes?.content;
    const [noteData, setNoteData] = useState(initialNotes);
    const [colors, setColors] = useState(pageNotes ? pageNotes?.color?.color : null)
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const currentClass = useCurrentClass();
    const currentOrg = useCurrentOrg();
    const currentUser = useCurrentUser();
    const handleColorChange = (color) => {
        setColors({
            color: color.hex
        });
    };

    useEffect(()=>{
        setColors(pageNotes?.color?.color)
    },[pageNotes])

    const onSaveAndClose = async () => {
        if (isNoteEmpty || !noteData) return
        if (data?.length > 0) {
            setLoader(true);
            const id = pageNotes?.id;
            const data = {
                content: noteData
            };
            const color = colors;
            await dispatch(handleBubbleUpdateNote(id, data, color));
        } else {
            setLoader(true);
            const data = {
                content: {
                    content: noteData
                },
                color: colors,
                chapter,
                page,
                userId: currentUser?.id,
                classId: currentClass.id,
                orgId: currentOrg?.id,
                title: ''
            }
            await dispatch(handleCreateBubbleNote(data));
        }
        const bubbleData = {
            chapter,
            classId: get(currentClass, "id"),
            userId: get(currentUser, "id")
        }
        await dispatch(handleGetBubbleByBook(bubbleData))
        dispatch(handleListBubbleUserNotes(get(currentUser, "id")))
        setLoader(false);
        close();
    }

    const isNoteEmpty = noteData === '<p><br></p>';
    return (
        <Wrapper onMouseEnter={()=> draggable(true)} onMouseLeave={()=> draggable(false)}>
            <Row>
                <FormHeader>{data?.length > 0 ? 'Edit a Note' : 'Add a Note'}</FormHeader>
                {/* <FormDescription>
                    Students will have their notes for the entire school year, motivate
                    them to write as many notes as they need. This is a great tool to
                    keep their work organized. You can write your notes here as well,
                    keep track of any important information.
                </FormDescription> */}
            </Row>

            <Row gutter={[0, 32]}>
                <Col span={24} style={{ padding: '24px 4px 12px', marginTop: "30px" }} className='bubbleCircleWraper'>
                    <CirclePicker onChangeComplete={handleColorChange}  />
                </Col>
            </Row>
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <NotesTextEditor
                        page="ebookAndBubble"
                        handleChange={setNoteData}
                        value={noteData}
                    />
                </Col>
            </Row>

            <Row>
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        backgroundColor: "#010A52",
                        height: "75px",
                        borderRadius: "0% 0% 18px 18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Button
                        className="notesSaveCloseBtn"
                        onClick={() => onSaveAndClose(isNoteEmpty)}
                        // onTouchStart={() => {
                        //     onSaveAndClose(isNoteEmpty)
                        // }}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        disabled={(isNoteEmpty || !noteData)}
                    >
                        {loader && <Loading size={17} />} Save and Close
                    </Button>
                </div>
            </Row>
        </Wrapper>
    );
};

export default NoteForm;
