//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import { ArrowRight, Close, Search, Visibility , ArrowForward, Settings, BookOutlined  } from '@material-ui/icons';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
//  -- Constants ----------------------
import Typography from '@material-ui/core/Typography';
import base64 from "base-64";
import COLOR from 'utils/constants/color';
//  -- Components ---------------------
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
//  -- Thunks -------------------------
import _, { find, get, isEmpty, map } from 'lodash';
import { lighten } from 'polished';
import { useTenant } from 'store/classlink/selectors';
import { getClasslinkUserInfo, getClasslinkUserInfoOnOwe, handleUserByClassLinkName } from 'store/classlink/thunks';
import { createKeystore } from "store/keystore/thunks";
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useDistricts } from 'store/organizations/selectors';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;

const WrapperForm = styled.form`
	padding: 18px 0px;
	width: 100%;
`;
const Error = styled.div`
    background-color: ${lighten(0.4, 'red')};
    border: 1px solid red;
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin: 0px auto 16px;
    max-width: 480px;
    padding: 8px;
    text-align: center;
    width: 100%;
`;
const Label = styled.div`
   font-size:14px;
   color:${COLOR.white};
`;
const LabelTop = styled.div`
   font-size:18px;
   color:${COLOR.white};
`;
const ItemsTabsList = styled.div`
    display: inline-block;
    flex-direction: column;
    min-height: 100px;
    border-radius: 12px;
    min-width: 200px;
    background-color: ${COLOR.indigodeep};
    width: 90%;
    padding: 16px;
    margin-top: 10px;
    min-height: 220px;
`;
const ClassUsersWrapper = styled.div`
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    margin-bottom: 8px;
    width:102%;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:90vh;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalSection = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:100%;
    overflow:auto;
    text-align: center;
`;


const AccordianTitle = styled(Typography)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
`
const Accordion = withStyles({
    root: {
        border: '1px solid white',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#03989e',
        color: 'white',
        textTransform: 'capitalize',
        borderBottom: '1px solid white',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiAccordionDetails);
const Rotate = styled.div`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;
const ContentArea = styled.div`
width: 90%;
margin-top: 28px;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------

const UserInfoAres = ({ classLinkUserData, data }) => {
    const [loader,setLoader] = useState(false);
    const openLoginInNewWindow = async () => {
        setLoader(true);
        const key = v4()
        await createKeystore(get(data, "users.id", ''), key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoader(false);
      }
    return (<Row style={{ width: "100%" }}>
        <Col span={12}>
            {!isEmpty(data.users) && <ItemsTabsList>
                <LabelTop>User on OWE Platform </LabelTop>
                <Label>Id: {get(data, "users.id", '')}  </Label>
                <Label >Create at: {get(data, "users.createdAt", null) ? moment(parseInt(get(data, "users.createdAt", ''))).format("MM/DD/YYYY") : null}</Label>
                <Label>First Name: {get(data, "users.firstName", '')}</Label>
                <Label >Last name: {get(data, "users.lastName", '')}</Label>
                <Label >Role: {get(data, "users.role", '')}</Label>
                <Label >User Name: {get(data, "users.username", '')}</Label>
                <Label >Password: {get(data, "users.password") ? base64.decode(get(data, "users.password", '')) : null}</Label>
                <Button color="primary" variant="contained" onClick={openLoginInNewWindow}>
                    {loader && <Loading size={14} white />}
                    Login to account
                    <ArrowForward
                        style={{
                            fontSize: "11px",
                            marginLeft: "8px",
                        }}
                    />
                </Button>
            </ItemsTabsList>}
        </Col>
        <Col span={12}>
            {!isEmpty(classLinkUserData.users) && <ItemsTabsList>
                <LabelTop>User on Classlink </LabelTop>
                <Label>sourcedId: {get(classLinkUserData, "users.sourcedId", '')}  </Label>
                <Label>username: {get(classLinkUserData, "users.username", '')}  </Label>
                <Label>Family Name: {get(classLinkUserData, "users.familyName", '')}</Label>
                <Label >Given Name: {get(classLinkUserData, "users.givenName", '')}</Label>
                <Label >Email: {get(classLinkUserData, "users.email", '')}</Label>
                <Label >role: {get(classLinkUserData, "users.role", '')}</Label>
            </ItemsTabsList>}
        </Col>
    </Row>)
}
const ClassInfoArea = ({ handleChange, data, classEpendOwe, classLinkUserData }) => {
    return (<Row style={{ width: "100%" }}>
        <Col span={12} style={{display:"flex",justifyContent:"center"}}>
            <ContentArea>
                {map(data.classes, cls => {
                    return (<Accordion square expanded={classEpendOwe == cls.id} onChange={handleChange(cls.id)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle><span>{cls.displayName}</span> <Rotate open={classEpendOwe == cls.id}><ArrowRight /></Rotate></AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ClassUsersWrapper>
                                <TableContainer>
                                    <Table style={{ width: "100%", textAlign: "center" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>Id</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Username</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>First name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Last Name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Classlink Id</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Password</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(cls.classUsers, cuser => {
                                                return (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "id", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "username", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "firstName", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "lastName", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "classlinkid", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "password", null) ? base64.decode(get(cuser, "password")) : null}</TableCell>
                                                    </TableRow>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ClassUsersWrapper>
                        </AccordionDetails>
                    </Accordion>
                    )
                })}
            </ContentArea>
        </Col>
        <Col span={12} style={{display:"flex",justifyContent:"center"}}>
            <ContentArea>
                {map(classLinkUserData.classes, cls => {
                    return (<Accordion square expanded={classEpendOwe == cls.sourcedId} onChange={handleChange(cls.sourcedId)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle><span>Course: {cls.title} </span> <Rotate open={classEpendOwe == cls.sourcedId}><ArrowRight /></Rotate></AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ClassUsersWrapper>
                                <TableContainer>
                                    <Table style={{ width: "100%", textAlign: "center" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>SourcedId</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Family Name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>User name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>email</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>givenName</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(cls.classUsers, cuser => {
                                                return (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "sourcedId", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "familyName", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "username", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "email", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "givenName", '')}</TableCell>
                                                    </TableRow>

                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ClassUsersWrapper>


                        </AccordionDetails>
                    </Accordion>
                    )
                })}
            </ContentArea>
        </Col>
    </Row>)
}
const ClasslinkContainer = ({
}) => {
    const tenant = useTenant()
    const districts = useDistricts();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ users: null, classes: [] });
    const [classLinkUserData, setClassLinkUserData] = useState({ users: null, classes: [] });
    const [errorMessage, setErrorMessage] = useState(null)
    const [classEpendOwe, setClassExpendOwe] = useState(-1)
    const [selectedTenant, setSelectedTenant] = useState('')
    const [classlinkId, setClasslinkId] = useState('')
    const [classlinkUsersname, setClasslinkUsersname] = useState('')
    const [userList, setUserList] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const history = useHistory()
    const handleChange = (panel) => (event, newExpanded) => {
        setClassExpendOwe(newExpanded ? panel : -1);
    };

    const fetchClasslinkUserByName = async () => {
        if (classlinkUsersname) {
            setLoading(true)
            const users = await handleUserByClassLinkName(classlinkUsersname.trim())
            setUserList(users)
            setLoading(false)
        }

    }

    const getOrgName = useCallback((tenentId) => {
        let dist = find(districts, ["classlinkTenantId", tenentId])
        return dist ? dist.displayName : tenentId
    }, [tenant, districts])
    const fetchApis = async (tenent,sourceId) => {
        setLoading(sourceId)
        setErrorMessage(null)
        if (!tenent || tenent.length == 0) {
            setErrorMessage("No tenent id found")
            setLoading(false)
            return;
        }
        if (!sourceId || sourceId.length == 0) {
            setErrorMessage("No source id found.")
            setLoading(false)
            return;
        }
        const oweUser = await getClasslinkUserInfoOnOwe(tenent, sourceId)
        const classlinkuser = await getClasslinkUserInfo(tenent, sourceId)

        if (isEmpty(get(oweUser, "users")) && isEmpty(get(classlinkuser, "users"))) {
            setErrorMessage("User source id not found on both platform")
        } else if (isEmpty(get(oweUser, "users"))) {
            setErrorMessage("User not found on OWE platform")
        } else if (isEmpty(get(classlinkuser, "users"))) {
            setErrorMessage("User not found on Classlink platform")
        }
        setData({
            users: get(oweUser, "users"),
            classes: get(oweUser, "classes", []),
        })
        setClassLinkUserData({
            users: get(classlinkuser, "users"),
            classes: get(classlinkuser, "classes", []),
        })
        setOpenModal(true)
        setLoading(false)
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedTenant && classlinkId) {
            fetchApis(selectedTenant,classlinkId);
        }else{
            fetchClasslinkUserByName()
        }

    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={e => setOpenModal(false)}
                style={{ height: "100%", width: "100%" }}
            >
                <ModalContent>
                    <Row style={{ justifyContent: "flex-end", width: "100%" }}>
                        <IconButton
                            onClick={e => setOpenModal(false)}
                            style={{ padding: 0, marginRight: 10 }}
                        >
                            <Close color="secondary" />
                        </IconButton>
                    </Row>
                    {errorMessage ? <Error>{errorMessage}</Error> : null}
                    <ModalSection>
                        <UserInfoAres data={data} classLinkUserData={classLinkUserData}  />
                        <ClassInfoArea data={data} classLinkUserData={classLinkUserData} classEpendOwe={classEpendOwe} handleChange={handleChange} />
                    </ModalSection>

                </ModalContent>
            </Modal>
            <Row gutter={[24, 24]}>
                <Col>
                    <Button variant="contained" startIcon={<BookOutlined style={{ color: "#fff", fontSize: 14 }} />} color="primary" style={{ height: 40 }} onClick={e => {
                        e.preventDefault();
                        history.push("/book-maps?course=classlink")
                    }}>
                        Book mapping
                    </Button>
                    <Button variant="contained" color="primary" startIcon={<Settings style={{ color: "#fff", fontSize: 14 }} />} style={{ height: 40, marginLeft: 8 }} onClick={e => {
                        e.preventDefault();
                        history.push("/keys?course=classlink")
                    }}>
                        Manage keys
                    </Button>
                </Col>
            </Row>
            <WrapperForm onSubmit={handleSubmit}>
                    <Row gutter={[24, 24]}>
                    <Col span={4}>
                        <FormControl variant="outlined">
                            <InputLabel id="tenantLabel" style={{ backgroundColor: "rgb(252,252,252)" }}>Select tenant</InputLabel>
                            <Select
                                labelId="tenantLabel"
                                id="role"
                                onChange={e => setSelectedTenant(e.target.value)}
                                value={selectedTenant}
                            >
                                {_.map(tenant, t => (
                                    <MenuItem key={t.id} value={t.tenantId}>
                                        {getOrgName(t.tenantId)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                    <Col>
                        <TextField
                            id="source_id"
                            name="source_id"
                            label="Source Id"
                            InputLabelProps={{ shrink: true, required: true }}
                            type="text"
                            variant="outlined"
                            onChange={e => {
                                setErrorMessage(null)
                                setClasslinkId(e.target.value)
                            }}
                            value={classlinkId}
                        />
                    </Col>
                    <Col>or</Col>
                    <Col>
                        <TextField
                            id="name"
                            name="name"
                            label="First name or last name"
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            variant="outlined"
                            onChange={e => {
                                setErrorMessage(null)
                                setClasslinkUsersname(e.target.value)
                            }}
                            value={classlinkUsersname}
                        />
                    </Col>

                    <Col>
                        <Button disabled={loading} variant="contained" color="primary" type="submit">
                            {(loading) ? <Loading size={20} white /> : 'Search'}
                            <Search style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                        </Button>
                    </Col>
                </Row>
                {/* {errorMessage ? <Error>{errorMessage}</Error> : null} */}
            </WrapperForm>
            <Wrapper>
                <LoadingWrapper loading={false}>
                    {userList.length > 0 ? <Table style={{ width: "100%", textAlign: "center" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: "center" }}>Username</TableCell>
                                <TableCell style={{ textAlign: "center" }}>First name</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Last Name</TableCell>
                                <TableCell style={{ textAlign: "center" }}>role</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Classlink source Id</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Password</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Compare</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {map(userList, cuser => {
                                return (<>
                                    <TableRow key={cuser.id}>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "username", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "firstName", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "lastName", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "role", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "classlinkid", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "password", null) ? base64.decode(get(cuser, "password")) : null}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}><Button disabled={loading == get(cuser, "classlinkid")} style={{ width: 85, height: 35 }} variant="text" onClick={() => fetchApis(get(cuser, "classlinkTenantId"),get(cuser, "classlinkid"))}>{loading == get(cuser, "classlinkid") ? <Loading size={15}  /> : <Visibility style={{ color: COLOR.green }} />} </Button></TableCell>
                                    </TableRow>
                                </>)
                            })}
                        </TableBody>
                    </Table> : null}
                </LoadingWrapper>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
ClasslinkContainer.propTypes = {};

//  -- Default props ------------------
ClasslinkContainer.defaultProps = {
    logs: []
};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    logs: state.logs,
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(ClasslinkContainer);
