//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import _, { filter, find, groupBy, map, get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { Close, Search } from '@material-ui/icons';
import { Col, Dropdown, Menu } from 'antd';
import Loading from 'components/Loading';
import ReactHighcharts from "react-highcharts";
import { CologG, LogoOnExam, TableBg } from "utils/assets/images";
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------

//  -- Thunks -------------------------
import { TootipWrapper } from 'components/TooltipWrapper';
import { Button } from "@material-ui/core"
import { CaretDownOutlined } from '@ant-design/icons';
// import { HIGH_ASSESSMENTS, MIDDLE_ASSESSMENTS } from "utils/config/reference"
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getBgColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR
const PLACEMNETCOLOR = [COLOR.greentrue, COLOR.green900, COLOR.yellow, COLOR.blueish100, COLOR.orange100, COLOR.radish100, , COLOR.red]
const ACIFL_LEVEL = ['ADV HIGH ', 'ADV MID', 'ADV LOW', 'INTER HIGH', 'INTER MID', 'INTER LOW', 'NOVICE HIGH', 'NOVICE MID', 'NOVICE LOW', 'FALTANTES']
const ACIFL_LEVEL_ABBR = ['AH ', 'AM', 'AL', 'IH', 'IM', 'IL', 'NH', 'NM', 'NL', 'FA']
const EXAM_SCHOOLS = ['HIGH SCHOOL', 'MIDDLE SCHOOL']
const EXAM_LABELS = ['Interpretativa(A)', 'Interpretativa(B)', 'Comprensión lit..', 'Comprensión aud..', 'Comunicación int..', 'Presentación']
const EXAM_LABELS_FULL = ['Interpretativa(A)', 'Interpretativa(B)', 'Comprensión literaria', 'Comprensión auditiva', 'Comunicación interpersonal', 'Presentación oral informal']
const years = new Date().getFullYear();
const upcomming_year = `${years}-${years + 1}`;
const downcomming_year = `${years - 1}-${years}`;

const config = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        marginTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        height: 120,
        width: 120,
        animation: true,
        backgroundColor: "transparent",
    },
    title: {
        text: "0%",
        align: "center",
        verticalAlign: "middle",
        y: 15,
        style: {
            fontWeight: 200,
            fontSize: "14px",
        },
    },
    tooltip: {
        enabled: true,
        formatter: function () {
            return `${this.key ?? ""}` + ":" + this.y + "%";
        }
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            cursor: "pointer",
            dataLabels: {
                enabled: false,
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%',
            showInLegend: false,
            borderWidth: 0,
            colors: ["rgb(90,177,208)", COLOR.gray100],
        }
    },
    series: [{
        type: 'pie',
        name: '',
        innerSize: '50%',
        data: [
            ['', 50.0],
            ['', 50.0],
        ]
    }]
};



//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CustomBtn = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black300};
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 4px;
    outline: none;

    &:hover {
        color: ${COLOR.green100};
    }
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;


// border-right-color:${COLOR.black100}
const OverflowOne = styled.div`
    height: auto;
    overflow-x: scroll;
    width: 15%;
    display: inline-block;
    @media (max-width: 634px){
      width: 50%;
    }
`;
const OverflowTwo = styled.div`
    height: auto;
    overflow-x: scroll;
    width: 70%;
    display: inline-block;
    border-right:1px solid black;
    @media (max-width: 634px) {
        width: 50%;
    }
`;

const AssignmentDeleteWrapper = styled.div`
   cursor:pointer;
   margin-top: 5px;
   opacity:0;
`;

const TableCell = styled.div`
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    padding: 0px 4px;
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const TableCellEdit = styled.div`
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    padding: 0px 4px;
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};

    &:first-child {
        justify-content: flex-start;
    }
    &:hover {
        color: white;
    }
`;
const AssignmentLabel = styled.button`
  border:none;
  background-color:transparent;
  width:100%;
  cursor:pointer;
  overflow:hidden;
`;
const OneLogoRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 8px 4px 8px;
    margin-bottom: 4px;
`;

const TableRow = styled.div`
    align-items: center;
    background-color: ${p => p.index % 2 === 0 ? '#40a9ff5e' : 'transparent'};
    border: 1px solid;
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    padding: 0px 8px;
    width: 100%;
`;

const Table = styled.div`
    padding-bottom: 0px;
    width: ${p => `${p.width}px`};
`;


const Wrapper = styled.div`
    width: 100%;
`;
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
`;
const TbgImgWrapper = styled.div`
    height: 69px;
    width: 100%;
    display: flex;
    justify-content: center;
`
const TbgImage = styled.img`
    width: 100%;
    height: 135%;
`
const Tab = styled.button`
    border: none;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColor(p.active)};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;
const SyncTab = styled(Tab)`
    justify-self: flex-end;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    margin-left:5px;
    margin-right:5px;
    height: 35px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
`;
const BookNameTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  `;

const TbgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 93px;
    background-color: ${COLOR.blueish100};
    border: 3px solid ${COLOR.indigo400};
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    `
const OverviewIcon = styled.div`
    width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
    background-image: url(${p => p.src});
    background-position: center;
    background-size: 2880% 288%;
    background-repeat: no-repeat;
}
    `
const TbgTitle = styled.span`
    color: ${COLOR.white};
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    `
const TbgTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    `
const TbgList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 14px;
`
const TbgListItem = styled.li`
    color: ${COLOR.white};
    font-size: 10px;
    font-weight: 400;
    `

const Logo = styled.img`
  width: 60px;
  border-radius: 50%;
`;
const OneWayText = styled.span`
  color: #424447;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  text-transform: uppercase;
  line-height: 20px;
`;

const AssignmentTxt = styled.div`
    font-size: 14px;
    margin-left: 20px;
    font-weight: 600;
`;
const NameContent = styled.span`
    color: #fff;
    margin-left: 10px;
    width: 220px;

`;
const StudentNameTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    height:40px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    `;
const QuestionTab = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(128,161,90);
    width: 95%;
    height: 40px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
`;
const StudentName = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(89,118,243);
    width: 95%;
    height: 40px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
`;
const HeaderTab = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:${COLOR.indigo100};
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:0px 5px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const HeaderTabP = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:rgb(11,9,84);
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const TableTopBtnCont = styled.div`
    justify-content: center;
    align-items: center;
    padding-left: 1.5%;
    overflow: hidden;
    display: flex;
    padding-left:5px;
    padding-right:5px;
`;
const TopTabCont = styled.div`
    display:flex;
    flex-direction:row;
`;
const TotalNStudentTxt = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
    white-space:pre-line;
    `;
const Exam2TableHeader = styled(TableHeader)`
    background-color:rgb(54,58,78);
    height:45px;
    border-radius:5px;
    align-items:center;
 

`;
const ChartContainer = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap:wrap;
    @media (max-width: 992px) {
       width:100%;
      }
    
`;
const ChartContainerInner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:50px;
    margin-left:10px;
    margin-right:10px;
`;
const ChartLabel = styled.span`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -20px;
`;
const SearchBox = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 7px;
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  outline: none;
  padding: 2px 16px;
  width: 90%;
  margin-right: 2px;
  margin-left: 2px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
  }
  :-ms-input-placeholder {
    color: #fff;
  }
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const SearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color:rgb(126,112,165);
    color: ${COLOR.green};
    font-size: 14px;
    align-items: center;
    width:100%;
    padding-right:12px;
    height: 30px;
    @media (max-width: 992px) {
       min-width:200px;
       margin-bottom:30px;
      }
`;
const ExamTableSection1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    justify-content: space-between;
    `;
const ExamTableSection2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 992px) {
        width: 100%;
    }
    `;
const TableItem = styled.td`
    height:40px;
    width: 25%;
    border: 0.1px solid rgba(0,0,0,0.2);
    border-collapse: collapse;
    text-align:center;
    padding-top: 10px;
    `;
const ExamGradeTable = styled.table`
  border-collapse: collapse;
`;
const ExamGradeBoday = styled.tbody`
`;
const ExamRow = styled.tr`
    display: flex;
    flex-direction: row;`;
const PlacementText = styled.span`
       color:${p => p.textColor ? p.textColor : COLOR.gray100}
    `;
const GraphRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:20px;
   @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    }

`;
const ExamOverflowTwo = styled(OverflowTwo)`
    width:90%;
    border-right: 0px;
    @media (max-width: 992px) {
        width:100%;
    }
    `;
const CloseBtn = styled.span`
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    align-self: flex-end;
    display:flex;
    flex-direction:row;
    color:rgb(197,2,91);
    font-weight:bold;
    justify-content: flex-end;
    `;
const ExamGradeSec = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image:url(${TableBg});
    padding:16px;
    margin-bottom:60px;
`;
const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;
const SortingMenu = styled.span`
  background-color: ${(p) => (p.active ? COLOR.indigo100 : COLOR.white)};
`;
const ChartPerText = styled.span`
    position: absolute;
    top: -10px;
    font-size: 11px;
`;

const ExamButton = styled.button`
     background-color:${COLOR.blueishdeep};
     width:120px;
     height:30px;
     border-radius:5px;
     margin-left:5px;
     margin-right:5px;
     border: 1px solid ${p => p.active ? "#f6ebeb" : "transparent"};
     cursor: pointer;
     box-sizing: border-box;
     box-shadow:${p => p.active ? "2px 2px 2px rgb(35 53 89 / 19%), -2px -2px 2px rgb(35 53 89 / 19%)" : "none"};
     color:rgb(255,255,255);
}
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;


const sortname = (a, b) => {
    const nameA = get(a, "lastName", "").toLowerCase()?.replace(/\s/g, '');
    const nameB = get(b, "lastName", "").toLowerCase()?.replace(/\s/g, '');;
    if (nameA < nameB) { return -1 }
    else if (nameA > nameB) { return 1 }
    else { return 0 }
}
const sortAssignment = (a, b) => parseInt(get(a, "data.page", 0)) > parseInt(get(b, "data.page", 0)) ? 1 : -1
const sortAssignedAssignment = (a, b) => parseInt(get(a, "page", 0)) > parseInt(get(b, "page", 0)) ? 1 : -1


const examAssignment = (classQuizzes, classActivities, quizzes, activities, exams) => {
    const _EARLY_HIGH = get(find(exams, k => k.order == 1), "exams[0].bookId")
    const _EARLY_MID = get(find(exams, k => k.order == 2), "exams[0].bookId")
    const _MID_HIGH = get(find(exams, k => k.order == 1), "exams[1].bookId")
    const _MID_MID = get(find(exams, k => k.order == 2), "exams[1].bookId")
    const _FINAL_HIGH = get(find(exams, k => k.order == 1), "exams[2].bookId")
    const _FINAL_MID = get(find(exams, k => k.order == 2), "exams[2].bookId")
    const allAssingnment = []
    const allAssignableAssignment = []
    if (Array.isArray(classActivities)) {
        allAssingnment.push(...classActivities)
    }
    if (Array.isArray(classQuizzes)) {
        allAssingnment.push(...classQuizzes)
    }
    if (Array.isArray(quizzes)) {
        allAssignableAssignment.push(...quizzes)
    }
    if (Array.isArray(activities)) {
        allAssignableAssignment.push(...activities)
    }
    const earlyHighExam = filter(allAssingnment, c => get(c, "data.chapter") == _EARLY_HIGH && get(c, "data.type") != 'custom').sort(sortAssignment)
    const earlyMidExam = filter(allAssingnment, c => get(c, "data.chapter") == _EARLY_MID && get(c, "data.type") != 'custom').sort(sortAssignment)
    const earlyHighExamAssignments = filter(allAssignableAssignment, c => c.chapter == _EARLY_HIGH && c.type != 'custom').sort(sortAssignedAssignment)
    const earlyMidExamAssignments = filter(allAssignableAssignment, c => c.chapter == _EARLY_MID && c.type != 'custom').sort(sortAssignedAssignment)
    const midHighExam = filter(allAssingnment, c => get(c, "data.chapter") == _MID_HIGH && get(c, "data.type") != 'custom').sort(sortAssignment)
    const mindMidExam = filter(allAssingnment, c => get(c, "data.chapter") == _MID_MID && get(c, "data.type") != 'custom').sort(sortAssignment)
    const midHighExamAssignments = filter(allAssignableAssignment, c => c.chapter == _MID_HIGH && c.type != 'custom').sort(sortAssignedAssignment)
    const midMidExamAssignments = filter(allAssignableAssignment, c => c.chapter == _MID_MID && c.type != 'custom').sort(sortAssignedAssignment)


    const finalHighExam = filter(allAssingnment, c => get(c, "data.chapter") == _FINAL_HIGH && get(c, "data.type") != 'custom').sort(sortAssignment)
    const finalMidExam = filter(allAssingnment, c => get(c, "data.chapter") == _FINAL_MID && get(c, "data.type") != 'custom').sort(sortAssignment)
    const finalHighExamAssignments = filter(allAssignableAssignment, c => c.chapter == _FINAL_HIGH && c.type != 'custom').sort(sortAssignedAssignment)
    const finaldMidExamAssignments = filter(allAssignableAssignment, c => c.chapter == _FINAL_MID && c.type != 'custom').sort(sortAssignedAssignment)
    return ({ earlyHighExam, earlyMidExam, earlyHighExamAssignments, earlyMidExamAssignments, midHighExam, mindMidExam, midHighExamAssignments, midMidExamAssignments, finalHighExam, finalMidExam, finalHighExamAssignments, finaldMidExamAssignments })
}

const getBookId = (exams, examType, level) => {
    if (examType == "exam1") {
        return get(find(exams, k => k.order == level), "exams[0].bookId")
    }
    else if (examType == "exam2") {
        return get(find(exams, k => k.order == level), "exams[1].bookId")
    } else if (examType == "exam3") {
        return get(find(exams, k => k.order == level), "exams[2].bookId")
    }

}
const ExamTable = ({
    activities,
    dataLoading,
    quizzes,
    users,
    submissions,
    currentUser,
    classActivities,
    classQuizzes,
    setExamData,
    examType,
    onClose,
    onExamSwitch,
    exams,
    onExamTabClick
}) => {
    // console.log(exams,"examsexams")
    const [filteredStudent, setFilteredStudent] = useState([])
    const [activeSession, setActiveSession] = useState(upcomming_year);
    const [activeExamData, setActiveExamData] = useState()
    const examData = useMemo(() => examAssignment(classQuizzes, classActivities, quizzes, activities, activeExamData), [classQuizzes, classActivities, quizzes, activities, activeExamData])
    const [listStudentUser, setListStudentUser] = useState(users && users.length > 0 ? filter(users, x => x.role === 'student' && x.active).sort(sortname) : new Array(1).fill({}, 0, 1))
    const [examSchool, setExamSchool] = useState(EXAM_SCHOOLS[0])
    const studentSubmissions = groupBy(submissions, 'userId');
    const bookIdHigh = getBookId(activeExamData, examType, 1)
    const bookIdMid = getBookId(activeExamData, examType, 2)
    const isHigh = examSchool == EXAM_SCHOOLS[0]
    const isCombined = examType === "combined"


    useEffect(() => {
        if (exams) {
            const grp = groupBy(exams, "session")
            if (activeSession && grp[activeSession]) {
                setActiveExamData(grp[activeSession])
            } else if (grp[downcomming_year]) {
                setActiveExamData(grp[downcomming_year])
                if (activeSession != downcomming_year) {
                    setActiveSession(downcomming_year)
                }
            }
            else {
                setActiveExamData(exams)
            }
        }
    }, [exams, activeSession])
    useEffect(() => {
        handleSetExamData()
    }, [examSchool, examType,filteredStudent])
    useEffect(() => {
        const allstudents = _.uniqBy(filter(users, x => x.role === 'student').sort(sortname), "username");
        let arr = new Array(1).fill({}, 0, 1)
        setListStudentUser(allstudents.length > 0 ? allstudents : arr)
        setFilteredStudent(allstudents.length > 0 ? allstudents : arr)
    }, [users])

    const getExamAssignmentData = (etype) => {
        if (!etype) etype = examType;
        if (etype == "exam3") {
            const assignmentData = isHigh ? examData.finalHighExam : examData.finalMidExam;
            const bookAssignment = isHigh ? examData.finalHighExamAssignments : examData.finaldMidExamAssignments;
            return ({
                assignmentData,
                bookAssignment
            })
        }
        else if (etype == "exam2") {
            const assignmentData = isHigh ? examData.midHighExam : examData.mindMidExam;
            const bookAssignment = isHigh ? examData.midHighExamAssignments : examData.midMidExamAssignments;
            return ({
                assignmentData,
                bookAssignment
            })
        } else {
            const assignmentData = isHigh ? examData.earlyHighExam : examData.earlyMidExam;
            const bookAssignment = isHigh ? examData.earlyHighExamAssignments : examData.earlyMidExamAssignments;
            return ({
                assignmentData,
                bookAssignment
            })
        }
    }
    const getCombinedLatestAssignemntBookId = () => {
        const exam1 = getExamAssignmentData("exam1")
        const exam2 = getExamAssignmentData("exam2")
        const exam3 = getExamAssignmentData("exam3")
        let bookId = null
        let exam = exam1
        if (exam3.assignmentData.length > 0) {
            bookId = isHigh ? getBookId(activeExamData, "exam3", 1) : getBookId(activeExamData, "exam3", 2)
            exam = 'exam3'
        } else if (exam2.assignmentData.length > 0) {
            bookId = isHigh ? getBookId(activeExamData, "exam2", 1) : getBookId(activeExamData, "exam2", 2)
            exam = 'exam2'
        } else {
            bookId = isHigh ? getBookId(activeExamData, "exam1", 1) : getBookId(activeExamData, "exam1", 2)
            exam = 'exam1'
        }
        return { bookId, exam }
    }
    const getExamGrade = (user, i) => {
        let result = Array.from(new Array(EXAM_LABELS.length + 1)).fill("-")
        const data = getExamAssignmentData()
        const assignmentData = data.assignmentData
        const bookAssignment = data.bookAssignment
        if (!user) {
            result = ["-"]
            map(bookAssignment, (assign, i) => {
                const cAssignment = find(assignmentData, k => k.data.id == assign.id)
                if (cAssignment) {
                    result.push(assign?.questions ? assign?.questions?.length : '-')
                } else {
                    result.push("-")
                }

            })
            if (result.length < EXAM_LABELS.length + 1) {
                result.splice(2, 0, "-");
            }
        } else {
            const data = studentSubmissions[user?.id];
            if (data && assignmentData.length > 0) {
                result = ["-"]
                let fresult = map(bookAssignment, (assign, i) => {
                    let submission = find(data, dt => dt.assignmentId == assign.id)
                    const cAssignment = find(assignmentData, k => k.data.id == assign.id)
                    if (submission && cAssignment) {
                        return submission.grade || submission.grade == 0 ? submission.grade : '-'
                    } else {
                        return "-"
                    }
                })
                result.push(...fresult)
                if (result.length < EXAM_LABELS.length + 1) {
                    result.splice(2, 0, "N\\A");
                }
            }
        }
        if(result.length < EXAM_LABELS.length+1){
            let re = EXAM_LABELS.length+1 - result.length;
            result.push(...Array.from(new Array(re)).fill("-"))
        }
        return result;
    }
    const getExamGradeCombine = useCallback((user, i) => {
        const res = {
            exam1: '',
            exam2: '',
            exam3: ''
        }
        const result = [res]
        Object.keys(res).map(key => {
            const data = getExamAssignmentData(key)
            const assignmentData = data.assignmentData
            const bookAssignment = data.bookAssignment
            if (!user) {
                map(bookAssignment, (assign, i) => {
                    const cAssignment = find(assignmentData, k => k.data.id == assign.id)
                    if (cAssignment) {
                        if (result[i + 1]) {
                            result[i + 1][key] = get(assign, "questions.length", '-')
                        } else {
                            result[i + 1] = {
                                exam1: '-',
                                exam2: '-',
                                exam3: '-'
                            }
                            result[i + 1][key] = get(assign, "questions.length", '-')
                        }
                    } else {
                        if (result[i + 1]) {
                            result[i + 1][key] = '-'
                        } else {
                            result[i + 1] = {
                                exam1: '-',
                                exam2: '-',
                                exam3: '-'
                            }
                            result[i + 1][key] = '-'
                        }
                    }
                })
            } else {
                const data = studentSubmissions[user?.id];
                if (data && assignmentData.length > 0) {
                    result[0] = {}
                    map(bookAssignment, (assign, i) => {
                        let submission = find(data, dt => dt.assignmentId == assign.id)
                        const cAssignment = find(assignmentData, k => k.data.id == assign.id)
                        if (submission && cAssignment) {
                            if (result[i + 1]) {
                                result[i + 1][key] = submission.grade || submission.grade == 0 ? submission.grade : '-'
                            } else {
                                result[i + 1] = {
                                    exam1: '-',
                                    exam2: '-',
                                    exam3: '-'
                                }
                                result[i + 1][key] = submission.grade || submission.grade == 0 ? submission.grade : '-'
                            }
                        } else {
                            if (result[i + 1]) {
                                result[i + 1][key] = '-'
                            } else {
                                result[i + 1] = {
                                    exam1: '-',
                                    exam2: '-',
                                    exam3: '-'
                                }
                                result[i + 1][key] = '-'
                            }
                        }
                    })
                }
            }

        })
        if(result.length < EXAM_LABELS.length+1){
            let re = EXAM_LABELS.length+1 - result.length;
            result.push(...Array.from(new Array(re)).fill({
                exam1: '-',
                exam2: '-',
                exam3: '-',
            }))
        }
        return result
    }, [examData, examSchool])
    const getPlacement = (user) => {
        let bookId = null;
        let examtype = examType;
        if (isCombined) {
            const cr = getCombinedLatestAssignemntBookId().bookId
            examtype = cr.exam;
            bookId = cr.bookId;
        } else {
            bookId = isHigh ? bookIdHigh : bookIdMid
        }
        let txt = "";
        const data = studentSubmissions[user?.id];
        let finalSubmission = []
        let qCount = 0
        let correctCount = 0
        const edata = getExamAssignmentData(examtype)
        const assignmentData = edata.assignmentData;
        if (data) {
            finalSubmission = filter(data, k => get(k, "assignment.chapter") == bookId)
            qCount = finalSubmission.length;
            if (finalSubmission.length > 0) {
                map(finalSubmission, assin => {
                    correctCount += assin.grade && !Number.isNaN(assin.grade) ? parseInt(assin.grade) : 0
                })
            }
        }
        if (assignmentData && assignmentData.length) {
            if (correctCount > 0) {
                let pers = correctCount / qCount;
                if (pers > 80 && pers <= 100) {
                    txt = ACIFL_LEVEL[2]
                }
                else if (pers > 60 && pers <= 80) {
                    txt = ACIFL_LEVEL[3]

                }
                else if (pers > 40 && pers <= 60) {
                    txt = ACIFL_LEVEL[4]

                }
                else if (pers > 20 && pers <= 40) {
                    txt = ACIFL_LEVEL[5]
                }
                else if (pers >= 0 && pers <= 20) {
                    txt = ACIFL_LEVEL[6]
                } else {
                    txt = ACIFL_LEVEL[7]
                }
            } else {
                if (txt == "")
                    txt = "NOT STARTED";
            }
            let hasNotSubmission = false
            map(assignmentData, a => {
                if (!find(finalSubmission, k => k.assignment.id == get(a, "data.id"))) {
                    hasNotSubmission = true
                }
            })
            if (finalSubmission.length != 0 && (finalSubmission.length < assignmentData.length && hasNotSubmission)) {
                txt = "NOT FINISHED"
            }
            else if (finalSubmission.length == 0) {
                txt = "NOT STARTED"
            }
        } else {
            txt = "NOT ASSIGNED"
        }
        return txt;
    }
    const getPlacementCombine = (user) => {
        const res = {
            exam1: '',
            exam2: '',
            exam3: ''
        }
        if (!user) return res;
        Object.keys(res).map(key => {
            const bookId = isHigh ? getBookId(activeExamData, key, 1) : getBookId(activeExamData, key, 2)
            let txt = "";
            const data = studentSubmissions[user.id];
            let finalSubmission = []
            let qCount = 0
            let correctCount = 0
            const edata = getExamAssignmentData(key)
            const assignmentData = edata.assignmentData;
            if (data) {
                finalSubmission = filter(data, k => k?.assignment?.chapter == bookId)
                qCount = finalSubmission.length;
                if (finalSubmission.length > 0) {
                    map(finalSubmission, assin => {
                        correctCount += assin.grade && !Number.isNaN(assin.grade) ? parseInt(assin.grade) : 0
                    })
                }
            }
            if (assignmentData && assignmentData.length) {
                if (correctCount > 0) {
                    let pers = correctCount / qCount;
                    if (pers > 80 && pers <= 100) {
                        txt = ACIFL_LEVEL_ABBR[2]
                    }
                    else if (pers > 60 && pers <= 80) {
                        txt = ACIFL_LEVEL_ABBR[3]

                    }
                    else if (pers > 40 && pers <= 60) {
                        txt = ACIFL_LEVEL_ABBR[4]

                    }
                    else if (pers > 20 && pers <= 40) {
                        txt = ACIFL_LEVEL_ABBR[5]
                    }
                    else if (pers >= 0 && pers <= 20) {
                        txt = ACIFL_LEVEL_ABBR[6]
                    } else {
                        txt = ACIFL_LEVEL_ABBR[7]
                    }
                } else {
                    if (txt == "")
                        txt = "NS";
                }
                if (finalSubmission.length != 0 && finalSubmission.length != assignmentData.length) {
                    txt = "NF"
                }
                else if (finalSubmission.length == 0) {
                    txt = "NS"
                }
            } else {
                txt = "NA"
            }
            res[key] = txt
        })
        return res;
    }
    const getPlacemEntColor = (v) => {
        if (v == ACIFL_LEVEL[2] || v == ACIFL_LEVEL_ABBR[2]) {
            return PLACEMNETCOLOR[0]
        } else if (v == ACIFL_LEVEL[3] || v == ACIFL_LEVEL_ABBR[3]) {
            return PLACEMNETCOLOR[1]
        } else if (v == ACIFL_LEVEL[4] || v == ACIFL_LEVEL_ABBR[4]) {
            return PLACEMNETCOLOR[2]
        } else if (v == ACIFL_LEVEL[5] || v == ACIFL_LEVEL_ABBR[5]) {
            return PLACEMNETCOLOR[3]
        } else if (v == ACIFL_LEVEL[6] || v == ACIFL_LEVEL_ABBR[6]) {
            return PLACEMNETCOLOR[4]
        } else if (v == ACIFL_LEVEL[7] || v == ACIFL_LEVEL_ABBR[7]) {
            return PLACEMNETCOLOR[5]
        } else if (v == ACIFL_LEVEL[8] || v == ACIFL_LEVEL_ABBR[8]) {
            return PLACEMNETCOLOR[7]
        } else if (v == 'NOT FINISHED' || v == 'NF') {
            return COLOR.yellow
        }
        else {
            return COLOR.gray100;
        }
    }
    const filterStudent = (e) => {
        let value = e.target.value?.toLowerCase();
        let st = filter(listStudentUser, (student) => {
            return (student?.username?.toLowerCase()?.includes(value?.toLowerCase()) || student?.firstName?.toLowerCase()?.includes(value?.toLowerCase()) || student?.lastName?.toLowerCase()?.includes(value?.toLowerCase()))
        })
        if (value && value.length > 0) {
            setFilteredStudent(st)
        } else {
            setFilteredStudent(listStudentUser)
        }
    }
    const getChartConfig = (type) => {
        let conf = _.cloneDeep(config);
        let per = 0;
        conf.chart.height = 90
        conf.chart.width = 80
        conf.title.style.fontSize = "10px"
        conf.title.y = 10
        let bookId = null;
        if (isCombined) {
            bookId = getCombinedLatestAssignemntBookId().bookId
        } else {
            bookId = isHigh ? bookIdHigh : bookIdMid
        }
        if (type == ACIFL_LEVEL[ACIFL_LEVEL.length - 1]) {
            let sub = 0;
            const totalStudent = listStudentUser.length;
            listStudentUser.map(u => {
                const userSUb = studentSubmissions[u?.id];
                if (userSUb) {
                    const hasSub = filter(userSUb, k => k.chapter == bookId).length > 0
                    if (hasSub) {
                        sub++;
                    }
                }
            })
            conf.plotOptions.pie.colors[0] = "rgb(197,2,91)"
            per = totalStudent - sub > 0 ? (((totalStudent - sub) / totalStudent) * 100).toFixed(0) : 0
        } else {
            const dt = {};
            map(listStudentUser, u => {
                const placement = getPlacement(u)
                if (dt[placement]) {
                    dt[placement] = dt[placement] + 1
                } else {
                    dt[placement] = 1
                }
            })
            const totalStudent = listStudentUser.length;
            if (dt[type]) {
                let t = dt[type];
                per = ((t / totalStudent) * 100).toFixed(0)
            }
        }
        if (per > 0) {
            let v = [
                [type, parseFloat(per)],
                ['', 100 - per],
            ]
            let txt = `${per ?? "0"}%`;
            conf.title.text = txt;
            conf.series[0].data = v;
        }
        return conf;
    }
    const getWidth = (index) => {
        const minSize = 110
        let txt = ACIFL_LEVEL[index]
        if (!txt) return minSize + 50
        return txt.length * 2 + minSize + 10

    }
    const handleSetExamData = () => {
        try {
            const rowData = new Set()
            let bookId = null;
            if (isCombined) {
                bookId = getCombinedLatestAssignemntBookId().bookId
            } else {
                bookId = isHigh ? bookIdHigh : bookIdMid
            }
            const jsondata = {
                name: '',
                username: '',
                placement: ''
            }
            getExamGrade().map((k, i) => {
                if (i == 0) {
                    jsondata.placement = '';
                } else {
                    jsondata[EXAM_LABELS_FULL[i - 1]] = k
                }
            })
            rowData.add(jsondata)
            map(filteredStudent, user => {
                const res = getExamGrade(user);
                const userData = {
                    name: `${user.lastName || ""}, ${user.firstName || ""}`,
                    username: user.username,
                    placement: ''
                }
                map(res, (k, ind) => {
                    if (ind == 0) {
                        let v = getPlacement(user, res)
                        userData.placement = v;
                    } else {
                        userData[EXAM_LABELS_FULL[ind - 1]] = k;
                    }
                    rowData.add(userData)
                })

            })
            const extraData = {}
            const dt = {};
            let sub = 0;
            map(listStudentUser, u => {
                const placement = getPlacement(u)
                if (dt[placement]) {
                    dt[placement] = dt[placement] + 1
                } else {
                    dt[placement] = 1
                }
            })
            map(listStudentUser, u => {
                const userSUb = studentSubmissions[u?.id];
                if (userSUb) {
                    const hasSub = filter(userSUb, k => k.chapter == bookId).length > 0
                    if (hasSub) {
                        sub++;
                    }
                }
            })
            const totalStudent = listStudentUser.length;
            map(ACIFL_LEVEL, (k, i) => {
                let per = 0;
                if (dt[k]) {
                    let t = dt[k];
                    per = ((t / totalStudent) * 100).toFixed(0)
                }
                if (i == ACIFL_LEVEL.length - 1) {
                    per = totalStudent - sub > 0 ? (((totalStudent - sub) / totalStudent) * 100).toFixed(0) : 0
                }
                extraData[k] = `${per}%`
            })
            // rowData.add(extraData)
            const finalData = { data: Array.from(rowData), firstRow: extraData }
            setExamData(finalData)
        } catch (error) {
            console.log(error, "error in exam gradebook downolad")
        }
    }
    const menuData = () => {
        return (<Menu>
            {map(Object.keys(groupBy(exams, "session")), x => {
                return (
                    <Menu.Item key={x} style={{ padding: '0px' }}>
                        <PaddedBtn onClick={() => setActiveSession(x)}>{x}</PaddedBtn>
                    </Menu.Item>
                )
            })}
        </Menu>)
    }

    return (
        <Wrapper>
            {dataLoading ? (
                <Loading />
            ) : (
                <div>
                    <ExamGradeSec>
                        <OneLogoRow>
                            <Col span={20} style={{ alignItems: "center", display: "flex", flexDirection: "row" , flexWrap:"wrap"}}>
                                <Logo src={LogoOnExam} />
                                <OneWayText>
                                    {"ONE  WAY"}
                                    <AssignmentTxt> {"Assignments"}</AssignmentTxt>
                                </OneWayText>
                                <Dropdown overlay={menuData()} placement="bottomLeft" trigger={['click']}>
                                    <Button onClick={e => e.preventDefault()}>
                                        {activeSession ? activeSession : 'sessions'}
                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                    </Button>
                                </Dropdown>
                                <ExamButton
                                    active={examType === "exam1"}
                                    style={{ marginLeft: 0 }}
                                    onClick={() => {
                                        onExamTabClick("exam1");
                                    }}
                                >
                                    Exam1
                                </ExamButton>
                                <ExamButton
                                    active={examType === "exam2"}
                                    onClick={() => {
                                        onExamTabClick("exam2");
                                    }}
                                >
                                    Exam2
                                </ExamButton>
                                <ExamButton
                                    active={examType === "exam3"}
                                    onClick={() => {
                                        onExamTabClick("exam3");
                                    }}
                                >
                                    Exam3
                                </ExamButton>
                                <ExamButton
                                    active={examType == "combined"}
                                    onClick={() => {
                                        onExamTabClick("combined");
                                    }}
                                >
                                    Combined
                                </ExamButton>
                            </Col>
                            <Col span={4}>
                                <CloseBtn onClick={onClose}>
                                    CLOSE <Close />
                                </CloseBtn>
                            </Col>
                        </OneLogoRow>
                        <Row style={{ marginTop: 20, paddingLeft: 12 }}>
                            <Col style={{ width: 25, paddingTop: 8 }}>
                                <OverviewIcon src={CologG} />
                            </Col>
                            <Col span={8}>
                                <span style={{ fontSize: 20, fontWeight: 600 }}>
                                    Overview
                                </span>
                            </Col>
                            <Col span={14} style={{ justifyContent: "flex-end", display: "flex" }}>
                                {EXAM_SCHOOLS.map((key) => (
                                    <Tab
                                        bgColor={key == examSchool ? null : COLOR.orange100}
                                        active={key == examSchool}
                                        key={key}
                                        onClick={() => {
                                            setExamSchool(key);
                                        }}
                                    >
                                        {key}
                                    </Tab>
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            <TotalNStudentTxt>{`Total number \n of  students: ${listStudentUser?.length}`}</TotalNStudentTxt>
                        </Row>
                        <GraphRow>
                            <Col span={4} style={{ alignItems: "flex-end", paddingTop: 0 }}>
                                <SearchBoxContainer>
                                    <SearchBox
                                        placeholder="Find your student"
                                        onChange={filterStudent}
                                    />
                                    <Search style={{ fontSize: 18 }} />
                                </SearchBoxContainer>
                            </Col>
                            <Col span={20}>
                                <ChartContainer>
                                    {map(ACIFL_LEVEL, k => {
                                        return (<ChartContainerInner key={k}>
                                            <ChartLabel style={{ fontSize: 10, marginBottom: -20, color: ACIFL_LEVEL[ACIFL_LEVEL.length - 1] == k ? "rgb(197,2,91)" : COLOR.black }}>{k}</ChartLabel>
                                            <ReactHighcharts config={getChartConfig(k)} />
                                        </ChartContainerInner>)
                                    })}
                                </ChartContainer>
                            </Col>
                        </GraphRow>
                        <div style={{ display: "flex", flex: 1 }}>
                            <ExamOverflowTwo>
                                <ExamGradeTable>
                                    <ExamGradeBoday>
                                        <Exam2TableHeader>
                                            <ExamTableSection1 style={{ width: 220 }}>
                                                <NameContent>Name</NameContent>
                                            </ExamTableSection1>
                                            <ExamTableSection2>
                                                <TopTabCont>
                                                    <TableTopBtnCont style={{ width: 140 }}>
                                                        <HeaderTabP style={{ fontSize: 12, width: "90%" }}>Placement</HeaderTabP>
                                                    </TableTopBtnCont>
                                                    {map(EXAM_LABELS, (key, index) => (<TableTopBtnCont key={key + index} style={{ width: getWidth(index) }}>
                                                        <TootipWrapper hasToolTip label={EXAM_LABELS_FULL[index]}>
                                                            <HeaderTab key={key} style={{ fontSize: 12, textOverflow: "ellipsis", width: "90%" }}>{key}</HeaderTab>
                                                        </TootipWrapper>
                                                    </TableTopBtnCont>
                                                    ))}
                                                </TopTabCont>
                                            </ExamTableSection2>
                                        </Exam2TableHeader>
                                    </ExamGradeBoday>
                                </ExamGradeTable>
                                <ExamGradeBoday style={{ display: "flex", flexDirection: "row", marginTop: -3 }}>
                                    <ExamTableSection1>
                                        {isCombined && <StudentNameTable>
                                            <QuestionTab style={{ backgroundColor: COLOR.indigo200 }}>Exams</QuestionTab>
                                        </StudentNameTable>}
                                        <StudentNameTable>
                                            <QuestionTab>Questions per section:</QuestionTab>
                                        </StudentNameTable>
                                        {filteredStudent?.map((user, i) => (
                                            <StudentNameTable>
                                                <StudentName>{user.firstName} , {user.lastName}</StudentName>{" "}
                                            </StudentNameTable>
                                        ))}
                                    </ExamTableSection1>
                                    {!isCombined ?
                                        <ExamTableSection2>
                                            <ExamGradeTable borderd>
                                                <ExamRow>
                                                    {getExamGrade().map((k, i) => {
                                                        let _width = i == 0 ? 140 : getWidth(i - 1);
                                                        return (
                                                            <TableItem style={{ width: _width }}>{i == 0 ? "" : k}</TableItem>
                                                        )
                                                    })}
                                                </ExamRow>
                                                {filteredStudent?.map((user, i) => {
                                                    const res = getExamGrade(user);
                                                    return (
                                                        <ExamRow>
                                                            {map(res, (k, ind) => {
                                                                let v = getPlacement(user, res)
                                                                let _width = ind == 0 ? 140 : getWidth(ind - 1);
                                                                return (
                                                                    <TableItem style={{ width: _width }}>
                                                                        {ind == 0 ? (
                                                                            <PlacementText textColor={getPlacemEntColor(v)}>
                                                                                {v}
                                                                            </PlacementText>
                                                                        ) : (
                                                                            k
                                                                        )}
                                                                    </TableItem>
                                                                )
                                                            })}
                                                        </ExamRow>
                                                    );
                                                })}
                                            </ExamGradeTable>
                                        </ExamTableSection2> :
                                        <ExamTableSection2>
                                            <ExamGradeTable borderd>
                                                <ExamRow>
                                                    {map(['', ...EXAM_LABELS], (k, i) => {
                                                        let _width = i == 0 ? 140 : getWidth(i - 1);
                                                        return (<div key={k + i} style={{ backgroundColor: COLOR.indigo400, color: "#fff", width: _width }}>
                                                            <TableItem style={{ width: _width / 3 }}>E1</TableItem>
                                                            <TableItem style={{ width: _width / 3 }}>E2</TableItem>
                                                            <TableItem style={{ width: _width / 3 }}>E3</TableItem>
                                                        </div>)
                                                    })}
                                                </ExamRow>
                                                <ExamRow>
                                                    {getExamGradeCombine().map((k, i) => {
                                                        //  console.log(k,"kkkk")
                                                        let _width = i == 0 ? 140 : getWidth(i - 1);
                                                        return (
                                                            <div key={k + i} style={{ width: _width, backgroundColor: i % 2 != 0 ? "rgb(242,248,254)" : "transparent" }}>
                                                                <TableItem style={{ width: _width / 3 }}>
                                                                    {i == 0 ? "" : k.exam1}
                                                                </TableItem>
                                                                <TableItem style={{ width: _width / 3 }}>
                                                                    {i == 0 ? "" : k.exam2}
                                                                </TableItem>
                                                                <TableItem style={{ width: _width / 3 }}>
                                                                    {i == 0 ? "" : k.exam3}
                                                                </TableItem>
                                                            </div>
                                                        )
                                                    })}
                                                </ExamRow>
                                                {map(filteredStudent, (user, i) => {
                                                    const res = getExamGradeCombine(user);
                                                    return (
                                                        <ExamRow>
                                                            {map(res, (k, ind) => {
                                                                let v = getPlacementCombine(user)
                                                                let _width = ind == 0 ? 140 : getWidth(ind - 1);
                                                                return (
                                                                    <div key={ind} style={{ width: _width, backgroundColor: ind % 2 != 0 ? "rgb(242,248,254)" : "transparent" }}>

                                                                        {ind == 0 ? (
                                                                            <div key={k + i}>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    <PlacementText textColor={getPlacemEntColor(v.exam1)}>
                                                                                        {v.exam1}
                                                                                    </PlacementText>
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    <PlacementText textColor={getPlacemEntColor(v.exam2)}>
                                                                                        {v.exam2}
                                                                                    </PlacementText>
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    <PlacementText textColor={getPlacemEntColor(v.exam3)}>
                                                                                        {v.exam3}
                                                                                    </PlacementText>
                                                                                </TableItem>
                                                                            </div>
                                                                        ) : (
                                                                            <div key={k + i}>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    {k.exam1}
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    {k.exam2}
                                                                                </TableItem>
                                                                                <TableItem style={{ width: _width / 3 }}>
                                                                                    {k.exam3}
                                                                                </TableItem>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            })}
                                                        </ExamRow>
                                                    );
                                                })}
                                            </ExamGradeTable>
                                        </ExamTableSection2>}
                                </ExamGradeBoday>
                            </ExamOverflowTwo>
                        </div>
                    </ExamGradeSec>
                </div>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ExamTable.propTypes = {};

//  -- Default props ------------------
ExamTable.defaultProps = {};

export default ExamTable;
