//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';

//  -- Features -----------------------
import InstructionsLayout from 'features/InstructionsLayout';


//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const InstructionsContainer = () => {
    return (
        <InstructionsLayout />
    );
};

//  -- Prop types ---------------------
InstructionsContainer.propTypes = {};

//  -- Default props ------------------
InstructionsContainer.defaultProps = {};

export default InstructionsContainer;
