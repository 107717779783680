import React, { useState } from 'react';
import { Popover, Input, List } from 'antd';
import { Search as SearchIcon, SearchOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import { useAllBook } from 'store/books/selectors';
import { useResources, useVideos } from 'store/resources/selectors';
import _,{ filter , map , debounce } from 'lodash';
import { TagFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { generateUrlForNonEbook, getStoryUrl, isVideo } from 'utils/lib/ebookHelper';
import { useStory } from 'store/story/selectors';
import { BACK_TO_BOOK } from 'utils/config/reference';
import { useBookUnits } from 'store/booksUnits/selectors';
const StyledListItem = styled(List.Item)`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  &:hover {
    background-color: #f5f5f5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin-right: 16px;
  object-fit:  cover;
`;
const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  align-item: center;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
`;

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px; 
  flex:6;
`;
const DescriptonText = styled.div`
  font-size: 14px;
`;
const Titletext = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const Tags = styled.div`
  font-size: 12px;
  font-weight: 300;
`;
const ListContainer = styled.div`
  max-height: 50vh;  
  overflow-y: auto;
  min-height: 25vh;
`;
const dummy = [
    {
        title: 'Example Title 1',
        description: 'Description of item 1',
        image: 'https://via.placeholder.com/50'
    },
    {
        title: 'Example Title 2',
        description: 'Description of item 2',
        image: 'https://via.placeholder.com/50'
    },
    // Add more items as needed
]
//function to check is text included with non case sensitive
function SearchComponent() {
    const [visible, setVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const booksAllList = useAllBook();
    const resources = useResources();
    const [filteredItems, setFilteredItems] = useState([]);
    const history = useHistory();
    const story = useStory()
    const bookUnits = useBookUnits()


    const onSearch = debounce((text) => {
        let textLower = text.toLowerCase().trimStart();
        textLower = textLower.trimStart()
        if (textLower.length === 0) {
            setFilteredItems([]);
            return;
        }
        const items = booksAllList.flatMap(book => {
            const { pages, description: title, id } = book;
            const filteredPages = pages.filter(page => 
                page.description?.toLowerCase().includes(textLower) ||
                page.tags?.toLowerCase().includes(textLower)
            );
    
            return filteredPages.map(page => ({
                title: `${title || ''} Pg.${page.NumberMasked || page.pageId || ''}`,
                description: page.description,
                image: page.resource,
                chapter: id,
                pageId: page.pageId,
                tags: page.tags,
                isEbook: true
            }));
        });
    
        resources.forEach(resource => {
            resource.videos.forEach(video => {
                if ([video.tags, video.displayName, resource.categories, video.description].some(text => 
                    text?.toLowerCase().includes(textLower))
                ) {
                    items.push({
                        title: `${resource.displayName || ''}-${video.displayName || ''}`,
                        description: video.description,
                        image: video.thumbnail,
                        chapter: video.id, 
                        pageId: video.id,
                        tags: video.tags,
                        isVideo: true
                    });
                }
            });
        });
    
        setFilteredItems(items);
    }, 300); // Debounce time can be adjusted based on the actual use case
    
    const redirectToAssignmentVideo = (id) => {
        let url = `/video?id=${id}&quiz=null`
        history.push(url);
    }
    const generateUrl = (CHAPTER_ID, page) => {
        const storyUrl = getStoryUrl(story, CHAPTER_ID, page, null, null)
        const backToLabel = BACK_TO_BOOK[CHAPTER_ID]
        const BALCON_PAGES = _.get(_.find(bookUnits, ["bookId", CHAPTER_ID]), "pages", [])
        const bookitem = _.find(BALCON_PAGES, k => parseInt(k.start) <= page && parseInt(k.finish) >= page)
        const url = generateUrlForNonEbook(bookitem?.id, page, null, null, backToLabel, null, CHAPTER_ID)
        if (url && backToLabel) {
            return url;
        }
        else if (storyUrl) {
            return storyUrl;
        } else {
            return null;
        }
    }
    const redirectToBook = (chapter , page) => {
        const generatedUrl = generateUrl(chapter,page) 
        if (isVideo(chapter, resources)) {
            redirectToAssignmentVideo(chapter);
        }
        else if (generatedUrl) {
            history.push(generatedUrl); 
        }
        else {
            history.push(`/ebook?chapter=${chapter}&page=${page}&activity=null&quiz=null`);
        }
    }

    const handleVisibleChange = visible => {
        clearData()
        setVisible(visible)
    };
    const onClick = (chapter, page) => {
        redirectToBook(chapter, page);
        setVisible(false)
        clearData()
    }
    const clearData = () => {
        setSearchTerm('')
        setFilteredItems([])
    }
    const content = (
        <div>
            <Input
                placeholder="Search..."
                value={searchTerm}
                autoFocus
                onChange={e => {
                    setSearchTerm(e.target.value);
                    onSearch(e.target.value)
                }}
                prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                style={{ width: '100%', marginBottom: 10 }}
            />
            <ListContainer>
                <List
                    itemLayout="horizontal"
                    dataSource={filteredItems}
                    pagination={{
                        pageSize: 15,
                        showSizeChanger: false
                    }}
                    renderItem={item => (
                        <StyledListItem onClick={() => onClick(item.chapter, item.pageId)}>
                            <Container>
                                <ImageContainer>
                                    <StyledImage alt={item.title} src={item.image} />
                                </ImageContainer>

                                <StyledDescription>
                                    <Titletext>{item.title}</Titletext>
                                    <DescriptonText>{item.description}</DescriptonText>
                                    {item.tags ? <Tags> <TagFilled className='iconFldChange' /> {item.tags}</Tags> : null}
                                </StyledDescription>
                            </Container>
                        </StyledListItem>
                    )}
                />
            </ListContainer>

        </div>
    );

    return (
        <div>
            <Popover
                content={content}
                title="Search"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
                placement="bottom"
                overlayStyle={{ width: '500px' }}
            >
                <SearchIcon style={{ fontSize: 20, cursor: 'pointer', color: 'white' }} />
            </Popover>
        </div>
    );
}

export default SearchComponent;
