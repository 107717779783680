//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { map } from 'lodash';


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const bookmarksSelecter = (state) => state.bookmarks;

//  -- Examples array selector --------

export const useBookmarks = () => {
    const selectedData = useSelector(bookmarksSelecter, shallowEqual)
    return selectedData;
}
