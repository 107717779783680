//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------

const selecter = (state) => state.instructions;

//  -- Examples array selector --------

export const useInstructions = () => {
    const selectedData = useSelector(selecter, shallowEqual)
    return selectedData;
}