//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List resources ----------------


//  -- List resources ----------------
export const handleListResources = () => async (dispatch) => {

    try {
        let resources = [];
        const data = await API.get('resources', `/listResources`);
        if (Array.isArray(data) && data.length > 0) {
            resources = data
        }
        await dispatch(ActivityActions.resourceSuccess(resources));
        return resources;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.resourceFail(error));
    }
};
export const handleUpdateResources = (data) => async (dispatch) => {

    try {
        const resources = await API.put('resources', `/updateResources`, {
            body: data
        });
        if (has(resources, "id")) {
            await dispatch(ActivityActions.updateResourceSuccess(resources));
        }
        // console.log(resources,"===resources===");
        return resources;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.resourceFail(error));
    }
};
export const handleUpdateVideos = (data) => async (dispatch) => {

    try {
        const resources = await API.put('resources', `/updateVideos`, {
            body: data
        });
        if (has(resources, "id")) {
            await dispatch(ActivityActions.updateVideoResourceSuccess(resources));
        }
    
        return resources;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.resourceFail(error));
    }
};

export const handleDeleteResources = (id) => async (dispatch) => {

    try {
        const resources = await API.del('resources', `/deleteResources/${id}`, {
            
        });
        if (has(resources, "id")) {
            await dispatch(ActivityActions.deleteResourceSuccess(resources));
        }
        // console.log(resources,"===resources===");
        return resources;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.resourceFail(error));
    }
};


export const handleListVideos = () => async (dispatch) => {

    try {
        let videos = []
        const data = await API.get('resources', `/listVideos`);
        if (Array.isArray(data) && data.length > 0) {
            videos = data
        }
        await dispatch(ActivityActions.videoResourceSuccess(videos));
        return videos;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.videoResourceFail(error));
    }
};


export const handleListResourcesAndVideo = () => async (dispatch) => {
    dispatch(handleListResources())
    dispatch(handleListVideos())
}