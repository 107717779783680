import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { Popper, TextField } from "@material-ui/core";
import {  Star } from "@material-ui/icons";
import styled from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";
import { handleDeactivateByUser } from "store/classActivities/thunks";
import Tag from "components/Tag";
import COLOR from "utils/constants/color";
import Loading from 'components/Loading';

const Note = styled.span`
  color: ${COLOR.black400};
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;


const CloseIcon = ({ onClose }) => (
  <div onClick={onClose} className="cancel-icon-station">
    <CancelIcon />
  </div>
);

const DeactivateByStudent = ({ open, close, studentList = [], assignmentData }) => {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false); 
  const [selectedStudent, setSelectedStudent] = useState([]);

  const disabled = useMemo(() => studentList.every(student => Object.keys(student).length === 0), [studentList]);

  const getDefaultValue = useMemo(() => {
    const deactivatedStudentId = assignmentData?.deactivated_student;
    return studentList
      ?.filter(data => deactivatedStudentId?.includes(data?.id))
      ?.map(data => data?.firstName ? `${data.firstName} ${data.lastName}`.trim() : "");
  }, [assignmentData?.deactivated_student, studentList]);

  const allStudents = useMemo(() => 
    studentList?.map(data => data?.firstName ? `${data.firstName} ${data.lastName}`.trim() : ""),
  [studentList]);

  const getUserData = useMemo(() => {
    const namesToFind = selectedStudent?.map(data => data?.trim());
    return studentList?.filter(user => 
      namesToFind.includes(`${user.firstName} ${user.lastName}`.trim())
    );
  }, [selectedStudent, studentList]);

  const getUserSelectId = useMemo(() => 
    getUserData?.map(data => data?.id),
  [getUserData]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("body-overflow-hidden");
    }
    return () => {
      document.body.classList.remove("body-overflow-hidden");
    };
  }, [open]);

  const handleDone = async () => {
    try{
      setLoading(true);
      const payload = {
        id: assignmentData?.id,
        type: assignmentData?.data?.typeId,
        deactivated_student: getUserSelectId,
      };
      await dispatch(handleDeactivateByUser(payload));
    }
    catch(error){
      console.log(error);
    }finally{
      setLoading(false);
      close();
    }

  };

  if (!open) return null;

  return (
    <div className="custom-fullscreen-container">
      <div className="deactivate-student-container">
        <div className="deactivate-student-inner-container">
          <CloseIcon onClose={close} />

          <div style={{ flexDirection: "column" }} className="flex">
            <Autocomplete
              multiple
              freeSolo
              id="anécdotas-themes-outlined"
              options={allStudents}
              style={{ width: "100%" }}
              defaultValue={getDefaultValue}
              onChange={(event, value) => setSelectedStudent(value)}
              disabled={disabled}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Select Students for deactive"
                  placeholder="Select Students"
                />
              )}
            />

            <div className="flex items-center mt-10px">
              <Tag height={20}>
                <Star style={{ height: "12px", margin: "-1px 4px 0px 0px", width: "12px" }} />
                NOTE
              </Tag>
              <Note>
                The assignment will be deactivated for the selected student.
              </Note>
            </div>
          </div>

          <div className="flex items-center w-full justify-end cancel-done-container">
            <button onClick={close} className="cancel-button">
              Cancel
            </button>
            <button disabled={loading} style={{opacity: loading ? "0.5" : "1" }} onClick={handleDone} className="done-button">
            {loading ? <Loading size={20} white /> : 'Done'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeactivateByStudent;