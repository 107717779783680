//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const postsInitial = null;

export const posts = (state = postsInitial, action) => {
    switch (action.type) {
        case ActionTypes.posts.createPostSuccess:
            return [action.payload, ...state];
        case ActionTypes.posts.deletePostSuccess:
            return state.filter(x => x.id !== action.payload.id);
        case ActionTypes.posts.listPostsSuccess:
        case ActionTypes.posts.listClassPostsSuccess:
        case ActionTypes.posts.listOrgPostsSuccess:
            return action.payload;
        case ActionTypes.posts.createPostFail:
        case ActionTypes.posts.deletePostFail:
        case ActionTypes.posts.listPostsFail:
        case ActionTypes.posts.listClassPostsFail:
        case ActionTypes.posts.listOrgPostsFail:
            return state;
        default:
            return state;
    }
};
