import { useFirebase } from "containers/FirebaseContext";
import {
    doc as firestoreDoc,
    getDoc,
    setDoc,
    updateDoc
} from "firebase/firestore";

export const useResourceViews = () => {
    const { firestore } = useFirebase();
    const createResourceViews = async (ResourceData) => {
        try {
            const generated_view_id = ResourceData?.id;
            const docRef = firestoreDoc(
                firestore,
                "most_viewed_video",
                generated_view_id
            );
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                await updateDoc(docRef, ResourceData);
            } else {
                await setDoc(docRef, ResourceData);
            }
            return;
        } catch (error) {
            console.log(error, "error");
        }
    };
    
    return { createResourceViews };
};
