//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { filter } from 'lodash';
import { cloneDeep } from 'lodash';

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const widgetsInit = [];


export const oneWayWidgets = (state = widgetsInit, action) => {
    switch (action.type) {
        case ActionTypes.widgets.listWidgets:
            return action.payload;
        case ActionTypes.widgets.addWidgets:
            return [...state, action.payload];
        case ActionTypes.widgets.deleteWidgets:
            const delv = cloneDeep(state)
            const result = filter(delv, k => k.id != action.payload.id)
            return result;
        case ActionTypes.widgets.updateWidgets:
            return state.map(x => { if (x.id === action.payload?.id) { return { ...x, ...action.payload }; } return x; });
        default:
            return state;
    }
};