//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from 'react';
import { func, shape } from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.button`
    align-items: center;
    background-color: transparent;
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    color: ${COLOR.black200};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 8px;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        border: 1px dashed ${COLOR.green500};
        color: ${COLOR.green100};
    }
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Student list item
//
//  -- Description
//  Student information for class listing
//
//  -- Props
//  data {object} - student information
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const Submission = ({
    data,
    onClick,
}) => (
    <Wrapper onClick={onClick}>
        <span>{data?.user?.firstName || ""} {data?.user?.lastName || ""}</span>
        <span>{moment(data?.createdAt).format('MMM, DD YYYY hh:mm a')}</span>
    </Wrapper>
);

//  -- Prop types ---------------------
Submission.propTypes = {
    data: shape({}),
    onEdit: func,
    onRemove: func,
};

//  -- Default props ------------------
Submission.defaultProps = {
    data: {}
};

export default Submission;