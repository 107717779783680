//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Check, Close, Flag, MoreVert } from '@material-ui/icons';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
//  -- Thunks -------------------------
import styled from 'styled-components';
//  -- Constants ----------------------
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { find, get, has } from 'lodash';
import { useCurrentUser } from 'store/users/selectors';
import { AbsentSvg, LateSvg } from "utils/assets/images";
import COLOR from 'utils/constants/color';
import { isBeforTime } from 'utils/methods/math';
import { Dropdown, Menu } from 'antd';
const COLORSTATUS = ["", COLOR.greentrue, COLOR.orange100, COLOR.red, "", "", COLOR.yellow]
const Poligon = styled.div`
    display:flex;
    flex-direction:row;
    clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
    height: 18px;
    width: 18px;
    margin-right: 5px;
    background-color:${p => p.bgColor ? p.bgColor : COLOR.green};
`;




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.button`
    align-items: center;
    background-color: ${COLOR.green100};
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    display: flex;
    height: 24px;
    margin-left: 4px;
    justify-content: center;
    opacity: 0;
    outline: none;
    padding: 2px;
    transition: all 300ms ease;
    width: 24px;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`;

const ButtonFlag = styled.button`
    align-items: center;
    border: none;
    border-radius: 2px;
    color: white;
    display: flex;
    height: 24px;
    margin-left: 4px;
    justify-content: center;
    opacity: 0;
    outline: none;
    padding: 2px;
    transition: all 300ms ease;
    width: 24px;
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`;

const Input = styled.input`
    border: 1px solid;
    border-radius: 3px;
    box-shadow: none;
    font-size: inherit;
    outline: none;
    padding: 0px 4px;
    transition: all 300ms ease;
    text-align:center;
    width: 40px;
`;

const Form = styled.form`
    algin-items: center;
    color: ${p => p.graded ? 'inherit' : 'red'};
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;

    &:hover ${Input} {
        border: 1px solid ${p => p.disabled ? 'white' : 'white'};
    }

    &:hover ${Button} {
        opacity: ${p => p.disabled ? 1 : 1};
        color: white;
    }
`;
const CheckBox = styled.div`
    align-items: center;
    height: 24px;
    display: flex;
    justify-content: center;
    width: 24px;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    transition: all 300ms ease;
    `;
const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    `;
const TbgArea = styled.div`
    position: absolute;
    bottom: -12px;
    font-size: 8px;
    color:${COLOR.black}
`;
const StatusText = styled.div`
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: ${p => p.color ? p.color : '#1b1e23'};
    font-weight: normal;
`;
const MaxPointText = styled.span`
color:${COLOR.gray100}
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Grade editing form
//
//  -- Description
//  Allows editing of grades/submissions
//
//  -- Props
//  ...
//
//  -- State
//  ...
//



const ParentGradeEditor = React.forwardRef(({
    assignmentId,
    submission,
    userId,
    value,
    disabled,
    assignment,
    user,
    currentClass,
    onFocus,
    isTbg,
    isLate,
    ungraded,
    face_grade,
    submissions,
    assignments,
    tempData,
    reviewAssignment
}, ref) => {
    const [tempValue, setTempValue] = useState(value);
    const [tempFlag, setTempFlag] = useState(submission?.exceptionStatus || 0);
    const currentUser = useCurrentUser()
    const inputRef = useRef()
    const [alertSakeBar, setAlertSakeBar] = useState({ open: false, message: '', severity: "success" })
    const hasUser = useMemo(() => has(user, "id"), [user]);
    const grade_type = assignment?.grade_type;
    const max_point = assignment?.max_point && !isNaN(assignment?.max_point) ? Number.parseInt(assignment.max_point, 10) : 100
    const isCompleteInComplete = grade_type === "complete_incomplete"
    const isPointSystem = grade_type === "points"
    const editValue = () => {
        const input = document.getElementById(assignmentId + userId);
        input.focus();
        input.select();
    };
    const setVal = () => {
        if (isPointSystem) {
            const val = ((typeof face_grade == "number" && face_grade >= 0) || (typeof face_grade == "string" && face_grade >= 0)) ? face_grade : null;
            setTempValue(val);
        } else {
            setTempValue(value);
        }
        setTempFlag(submission?.exceptionStatus || 0);
    }
    useEffect(() => {
        setVal()
    }, [value, submission, assignment, face_grade])


    useImperativeHandle(ref, () => ({
        focus: () => {
            editValue()
        },
        blur: () => {
            inputRef.current.blur();
        },
        isFocused: () => {
            const input = document.getElementById(assignmentId + userId);
            return input === document.activeElement;
        },
        ref: inputRef.current
    }));

    const graded = () => {
        if (submission?.retaking) {
            return true;
        } else if (submission?.complete && submission?.graded) {
            return true;
        } else if (!submission) {
            return true;
        } else {
            return false;
        }
    }
    const isDueDatePassed = () => {
        if (!assignment) return false;
        const classConfig = find(assignment.classes, ['id', currentClass?.id]);
        const diffConfig = get(classConfig, 'diff');
        const isDiffStudent = find(get(diffConfig, 'student'), ['id', user?.id]);
        if (isDiffStudent) {
            return isBeforTime(diffConfig.dueDateDif);
        } else {
            return assignment.lateDate ? isBeforTime(assignment.lateDate) : isBeforTime(assignment.dueDate);
        }
    };
    const getCompleteInclompleteMark = () => {
        const isComplete = has(submission, "id") && submission.grade > 0;
        const isInComplete = has(submission, "id") && submission.grade == 0;
        const isPastDue = isDueDatePassed()
        const isPassedIncomplete = !has(submission, "id") && isPastDue
        if (isComplete) {
            return <Check style={{ color: COLOR.green100, fontSize: 18 }} />
        } else if (isInComplete || isPassedIncomplete) {
            return <Close style={{ color: COLOR.red, fontSize: 18 }} />
        } else {
            return <span style={{ color: COLOR.gray100, fontSize: 18 }}>-</span>
        }
    }
    const getStatusIcon = useCallback((status, fromTab) => {
        if (isNaN(status)) {
            if (status === "complete") {
                return <Check style={{ color: COLOR.white, fontSize: 15, marginRight: 5, marginTop: 2, backgroundColor: COLOR.green100, borderRadius: 20 }} />;
            } else if (status === "incomplete") {
                return <Close style={{ color: COLOR.white, fontSize: 15, marginRight: 5, marginTop: 2, backgroundColor: COLOR.red, borderRadius: 20 }} />;
            }
        } if (status == 5) {
            return <img src={AbsentSvg} style={{ marginRight: fromTab ? 0 : 5, height: 20 }} />
        }
        if (status == 6) {
            return <img src={LateSvg} style={{ marginRight: fromTab ? 0 : 5, height: 20 }} />
        }
        else {
            return <Poligon bgColor={COLORSTATUS[status]} style={{ marginRight: fromTab ? 0 : 5 }} />
        }
    })
    const getStatusByCode = useCallback((status) => {
        if (status == 0) {
            return ''
        } else if (status == 1) {
            return currentUser.schoologyid ? "Exempt" : "Excused";
        } else if (status == 2) {
            return 'Incomplete'
        } else if (status == 3) {
            return 'Missing'
        } else {
            return ""
        }
    }, [])

    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: '', severity: '' })
    }

    const renderStatusText = useMemo(() => {
        if (!ungraded && submission && Object.keys(submission || {}).length > 0 && parseInt(get(submission, "submissions")) == (parseInt(get(submission, "returned", 0)) - 1)) {
            return (<StatusText>Returned
            </StatusText>)
        }
        else if (ungraded && submission && Object.keys(submission).length > 0) {
            return (<StatusText>Turned in</StatusText>)
        }
        else if (tempFlag > 0) {
            return (<StatusText color={COLORSTATUS[tempFlag]}>{getStatusByCode(tempFlag)}</StatusText>)
        } else if (isTbg && !ungraded) {
            return (<StatusText>TBG</StatusText>)
        }
        else if (isLate && !ungraded) {
            return (<StatusText>Done late</StatusText>)
        }
        else if (!ungraded && parseInt(get(submission, "submissions")) > 1 || (parseInt(get(submission, "returned", 0)) > 0 && parseInt(get(submission, "submissions")) == 1)) {
            return (<StatusText>Resubmitted
            </StatusText>)
        }
        else if (ungraded) {
            return (<StatusText>Ungraded</StatusText>)
        }
        else {
            return null;
        }

    }, [tempFlag, isTbg, ungraded, isLate, submission])

    const visabilityNavForFLag = useCallback(() => {
        return (
            <Menu key={assignmentId + "nav"}>
                <Menu.Item onClick={() => {
                    reviewAssignment(getReviewSubmission())
                }}>
                    <MenuItem>
                        View Submission
                    </MenuItem>
                </Menu.Item>
            </Menu>
        );
    }, [submission, user, submissions]);

    const userNavForFLag = () => {
        return <></>
    }

    const getReviewSubmission = useCallback(() => {
        if (has(submission, "id")) {
            submission.user = user;
            if (!has(submission, "assignment")) {
                submission.assignment = get(assignments, "data");
            }
            submission.ungraded = ungraded;
            return submission
        } else {
            tempData.user = user;
            if (!has(tempData, "assignment")) {
                tempData.assignment = get(assignments, "data");
            }
            tempData.ungraded = ungraded;
            return tempData;
        }
    }, [submission, tempData, user, ungraded, submissions]);

    const getValue = () => {
        if (ungraded) return "-";
        else if (tempFlag == 0 || !tempFlag) {
            if (tempValue === null || tempValue === "NULL") {
                return '-';
            }
            return (typeof tempValue === "number" && tempValue >= 0) || (typeof tempValue === "string") ? tempValue : '-';
        } else if (tempFlag == 3) {
            return '0';
        } else {
            return "--";
        }
    }

    const isDisabled = (disabled && tempFlag <= 0) || !hasUser

    return (<>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertSakeBar.open}
            autoHideDuration={3000}
            onClose={closeAlert}>
            <Alert onClose={() => { setAlertSakeBar({ open: false, message: '' }) }} severity={alertSakeBar.severity}>
                <strong>ALERT:</strong> {alertSakeBar.message}
            </Alert>
        </Snackbar>
        <Form
            disabled
            onSubmit={e => { }}
            graded={graded()}
        >
            <ButtonFlag
                disabled={isDisabled || ungraded}
                onClick={e => {
                    e.preventDefault()
                }}
                style={{ marginRight: 5 }}>
                {/* <Flag style={{ fontSize: '16px' }} /> */}
            </ButtonFlag>
            {!isCompleteInComplete ? <Input
                disabled
                id={assignmentId + userId}
                className='cell-input'
                ref={inputRef}
                value={getValue()}
                style={{ color: 'black' }}
            /> : <CheckBox>{getCompleteInclompleteMark()}</CheckBox>}
            {!isCompleteInComplete ? renderStatusText : null}
            {isPointSystem ? <MaxPointText>{`/${max_point}`}</MaxPointText> : null}
            <Dropdown overlay={visabilityNavForFLag()}
                placement="bottomLeft" trigger={['click']}
                disabled={isDisabled}
            >
                <Button disabled={isDisabled} onClick={(e) => e.preventDefault()} type="button">
                    <MoreVert style={{ fontSize: '16px' }} />
                </Button>
            </Dropdown>
        </Form>
    </>
    );
});

//  -- Prop types ---------------------
ParentGradeEditor.propTypes = {};

//  -- Default props ------------------
ParentGradeEditor.defaultProps = {};

export default ParentGradeEditor;
