//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { find } from 'lodash';

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const selectedUserInit = {};

export const selectedUser = (state = selectedUserInit, action) => {
    switch (action.type) {
        case ActionTypes.selectedUser.setSelectedUser:
            return action.payload;
        case ActionTypes.selectedUser.clearSelectedUser:
            return selectedUserInit;
        default:
            return state;
    }
};
