//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { ArrowRight, Note } from '@material-ui/icons';
import { filter,map } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//  -- Config -------------------------
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';





//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Bold = styled.span`
    font-weight: 600;
`;

const Button = styled.button`
    align-items: center;
    background-color: rgb(219,240,241);
    border: 1px dashed ${COLOR.black700};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;
    outline: none;
    padding: 6px 8px;
    width: 100%;
`;

const NoteWrap = styled.button`
    background-color: ${COLOR.black900};
    border: 1px solid ${COLOR.black800};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 6px;
    outline: none;
    padding: 8px 10px;
    text-transform: uppercase;
    width: 100%;
`;

const Container = styled.div`
    height: ${p => `${p.height}px`};
    overflow: hidden;
    transition: height 300ms ease;
    width: 100%;
`;

const Count = styled.span`
    font-size: 11px;
    text-transform: uppercase;
`;

const Rotate = styled.div`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;

const Wrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const Wrapper = styled.div`
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Todo
//
//  -- Description
//  ...
//
//  -- Props
//  ...
//
const StudentNotes = ({
    notes,
    onClick,
    getDisplayName
}) => {  
    return (
        <Wrapper>
            <Container height={"100%"}>
              
                    {map(notes,note => (
                        <NoteWrap key={note.id} onClick={() => onClick(note.chapter, note.page,note)}>
                            <Wrap>
                                <Note style={{ fontSize: '14px', marginRight: '4px', marginTop: '-2px' }} />
                                {getDisplayName(note.chapter,note.page)}
                            </Wrap>
                        </NoteWrap>
                    ))}
               
            </Container>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
StudentNotes.propTypes = {};

//  -- Default props ------------------
StudentNotes.defaultProps = {};

export default StudentNotes;