//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { find } from 'lodash';

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { cloneDeep } from 'lodash';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const submissionsInitial = [];

export const submissions = (state = submissionsInitial, action) => {
    switch (action.type) {
        case ActionTypes.submissions.createSubmissionSuccess:
            return [action.payload, ...state];
        case ActionTypes.submissions.updateSubmissionSuccess:
            const dt = cloneDeep(state)
            return dt.map(x => {
                if (x.id === action.payload.id) {
                    return { ...x, ...action.payload };
                }
                return x;
            });
        case ActionTypes.submissions.deleteUserSubmissionSuccess:
            return state.filter(x => !find(action.payload, ['id', x.id]));
        case ActionTypes.submissions.listQuestionsSuccess:
        case ActionTypes.submissions.listAssignmentSubmissionsSuccess:
        case ActionTypes.submissions.listClassSubmissionsSuccess:
        case ActionTypes.submissions.listOrgSubmissionsSuccess:
        case ActionTypes.submissions.listUserSubmissionsSuccess:
            return action.payload;
        case ActionTypes.submissions.createSubmissionFail:
        case ActionTypes.submissions.updateSubmissionFail:
        case ActionTypes.submissions.listQuestionsFail:
        case ActionTypes.submissions.listAssignmentSubmissionsFail:
        case ActionTypes.submissions.listClassSubmissionsFail:
        case ActionTypes.submissions.listOrgSubmissionsFail:
        case ActionTypes.submissions.listUserSubmissionsFail:
            return state;
        case ActionTypes.submissions.updatePastSubmissions:
            return state.map(x => {
                if (x.id === action.payload.submissionId) {
                    if (Array.isArray(x.pastSubmissions)) {
                        let _pastSubmissions = x.pastSubmissions;
                        _pastSubmissions.push(action.payload)
                        x.pastSubmissions = _pastSubmissions
                        return x
                    } else {
                        x.pastSubmissions = [action.payload]
                        return x
                    }
                }
                return x;
            });
        default:
            return state;
    }
};
