//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as IntereactionActions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------
export const handleListChapterInteractions = (chapterID) => async (dispatch) => {
    await dispatch(IntereactionActions.listChapterInteractions());

    try {
        let interactions = [];
        const interactionsRDS = await API.get('interactionsRDS', `/interactionsByChapter/${chapterID}`);
        if (Array.isArray(interactionsRDS) && interactionsRDS.length > 0) {
            interactions = interactionsRDS
        }
        await dispatch(IntereactionActions.listChapterInteractionsSuccess(interactions));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(IntereactionActions.listChapterInteractionsFail(error));
    }
};

//  -- List interactions by type ------
export const handleListInteractionsByType = (type) => async (dispatch) => {
    await dispatch(IntereactionActions.listInteractionsByType());

    try {
        let interactions = [];
        const interactionsRDS = await API.get('interactionsRDS', `/interactionsByType/${type}`);
        if (Array.isArray(interactionsRDS) && interactionsRDS.length > 0) {
            interactions = interactionsRDS
        }
        await dispatch(IntereactionActions.listInteractionsByTypeSuccess(interactions));
        return interactions;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(IntereactionActions.listInteractionsByTypeFail(error));
    }
};

export const handleListInteractions = () => async (dispatch) => {
    await dispatch(IntereactionActions.listInteractions());

    try {
        let interactions = [];
        const interactionsRDS = await API.get('interactionsRDS', `/list`);
        if (Array.isArray(interactionsRDS) && interactionsRDS.length > 0) {
            interactions = interactionsRDS
        }
        await dispatch(IntereactionActions.listInteractionsSuccess(interactions));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(IntereactionActions.listInteractionsFail(error));
    }
};

export const handleCreateInteractions = (data) => async (dispatch) => {
    await dispatch(IntereactionActions.createInteraction());
    try {
        const interactionsRDS = await API.post('interactionsRDS', `/interactions`, {
            body: data
        });
        if (has(interactionsRDS, "id")) {
            await dispatch(IntereactionActions.createInteractionSuccess(interactionsRDS));
        }
        return interactionsRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(IntereactionActions.createInteractionFail(error));
    }
};
export const handleUpdateInteractions = (data) => async (dispatch) => {
    await dispatch(IntereactionActions.updateInteraction());
    try {
        const interactionsRDS = await API.put('interactionsRDS', `/update`, {
            body: data
        });
        if (has(interactionsRDS, "id")) {
            await dispatch(IntereactionActions.updateInteractionSuccess(interactionsRDS));
        }
        return interactionsRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(IntereactionActions.updateInteractionFail(error));
    }
};
export const handleDeleteInteractions = (id) => async (dispatch) => {
    await dispatch(IntereactionActions.deleteInteraction());
    try {
        const interactionsRDS = await API.del('interactionsRDS', `/delete/${id}`);
        if (has(interactionsRDS, "id")) {
            await dispatch(IntereactionActions.deleteInteractionSuccess(id));
        }
        return interactionsRDS;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(IntereactionActions.deleteInteractionFail(error));
    }
};