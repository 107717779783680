//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setIdea, updateIdea, clearIdea , setIdeaModal } from "./actions"
//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.ideas;

export const useIdeas = () => {
    const selectedData = useSelector(selector, shallowEqual)
    const dispatch = useDispatch()
    const setIdeaData = (ideas) => {
        dispatch(setIdea(ideas))
    }
    const updateIdeaData = (ideas) => {
        dispatch(updateIdea(ideas))
    }
    const clearIdeaData = () => {
        dispatch(clearIdea())
    }
    return ({ ideasData: selectedData, clearIdeaData, updateIdeaData, setIdeaData });
}


const selectorIdea = (state) => state.ideaModal;


export const useIdeaModal = () => {
    const selectedData = useSelector(selectorIdea, shallowEqual);
    const dispatch = useDispatch()

    const viewIdeaModal = () => {
        dispatch(setIdeaModal(true))
    }
    const hideIdeaModal = () => {
        dispatch(setIdeaModal(false))
    }

    return ({ ideaModal: selectedData, viewIdeaModal, hideIdeaModal });
}