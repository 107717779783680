//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  -- List books ----------------
export const videoResourceInit = () => ({ type: ActionTypes.resources.setVideoResourcesInit });
export const videoResourceSuccess = (returnedPayload) => ({ type: ActionTypes.resources.setVideoResourcesSuccess, payload: returnedPayload });
export const videoResourceFail = () => ({ type: ActionTypes.resources.setVideoResourcesFail });
export const resourceInit = () => ({ type: ActionTypes.resources.setResourcesInit });
export const resourceSuccess = (returnedPayload) => ({ type: ActionTypes.resources.setResourcesSuccess, payload: returnedPayload });
export const resourceFail = () => ({ type: ActionTypes.resources.setResourcesFail });

export const updateResourceSuccess = (returnedPayload) => ({ type: ActionTypes.resources.updateResourcesSuccess, payload: returnedPayload });
export const updateVideoResourceSuccess = (returnedPayload) => ({ type: ActionTypes.resources.updateVideoResourcesSuccess, payload: returnedPayload });

export const deleteResourceSuccess = (returnedPayload) => ({ type: ActionTypes.resources.deleteResourcesSuccess, payload: returnedPayload });

//  -- List All books ----------------
