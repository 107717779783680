//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------


//  -- List books ----------------
export const handleInvokeMainbook = (open,pages) => async (dispatch) => {
    await dispatch(ActivityActions.invokeMainBook(open,pages));
}
export const handleRevokeMainbook = () => async (dispatch) => {
    await dispatch(ActivityActions.revokeMainbook());
}

export const handleSentenceInvoke = (open,pages) => async (dispatch) => {
    await dispatch(ActivityActions.invokeSentence(open,pages));
}
export const handleSentenceRevoke = () => async (dispatch) => {
    await dispatch(ActivityActions.revokeSentence());
}

export const handleInvokeEbookGalleryView = (open,pages) => async (dispatch) => {
    await dispatch(ActivityActions.invokeEbookGalleryView(open,pages));
}
export const handleRevokeEbookGalleryView = () => async (dispatch) => {
    await dispatch(ActivityActions.revokeEbookGalleryView());
}
