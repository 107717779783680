//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash"
//  -- Actions ------------------------
import * as NotesActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create note --------------------
export const handleCreateNote = (data) => async(dispatch) => {
    await dispatch(NotesActions.createNote());

    try {
        const noteData = await API.post('notesRDS', `/notes`, {
            body: { ...data }
        });
        await dispatch(NotesActions.createNoteSuccess(noteData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.createNoteFail(error));
    }
};

export const handleCreateBubbleNote = (data) => async(dispatch) => {
    await dispatch(NotesActions.createNote());

    try {
        const noteData = await API.post('notesRDS', `/bubbleNoteCreate`, {
            body: { ...data }
        });
        await dispatch(NotesActions.createNoteSuccess(noteData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.createNoteFail(error));
    }
};

// List Notes by Book, class and userId

export const handleGetBubbleByBook = (data) => async(dispatch) => {
    try {
        const noteData = await API.post('notesRDS', `/bubbleByBook`, {
            body: { ...data }
        });
        await dispatch(NotesActions.listNoteByBookSuccess(noteData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
         await dispatch(NotesActions.listNoteByBookFail(error));
    }
};

//  -- List class notes ---------------
export const handleListClassNotes = (classID) => async(dispatch) => {
    await dispatch(NotesActions.listClassNotes());

    try {
        const notesRDS = await API.get('notesRDS', `/notesByClass/${classID}`);
        let notesDataArr=[]
        if(Array.isArray(notesRDS)&&notesRDS.length>0){
            notesDataArr=notesRDS
        }
        await dispatch(NotesActions.listClassNotesSuccess(notesDataArr));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.listClassNotesFail(error));
    }
};

export const handleListBubbleClassNotes = (classID) => async(dispatch) => {
    await dispatch(NotesActions.listClassNotes());

    try {
        const notesRDS = await API.get('notesRDS', `/bubbleByClass/${classID}`);
        let notesDataArr=[]
        if(Array.isArray(notesRDS)&&notesRDS.length>0){
            notesDataArr=notesRDS
        }
        await dispatch(NotesActions.listClassNotesSuccess(notesDataArr));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.listClassNotesFail(error));
    }
};

//  -- List user notes ----------------
export const handleListUserNotes = (userID,cb) => async(dispatch) => {
    await dispatch(NotesActions.listUserNotes());

    try {
         let notes = [];
        const notesRDS = await API.get('notesRDS', `/notesByUser/${userID}`);
        if(Array.isArray(notesRDS)&&notesRDS.length>0){
            notes=notesRDS
        }
        if(cb)  return notes;
        await dispatch(NotesActions.listUserNotesSuccess(notes));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.listUserNotesFail(error));
    }
};

export const handleListBubbleUserNotes = (userID,cb) => async(dispatch) => {
    await dispatch(NotesActions.listUserNotes());

    try {
         let notes = [];
        const notesRDS = await API.get('notesRDS', `/bubbleByUser/${userID}`);
        if(Array.isArray(notesRDS)&&notesRDS.length>0){
            notes=notesRDS
        }
        if(cb)  return notes;
        await dispatch(NotesActions.listUserNotesSuccess(notes));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.listUserNotesFail(error));
    }
};


//  -- Update class quiz --------------
export const handleUpdateNote = (noteID, data, color) => async(dispatch) => {
    await dispatch(NotesActions.updateNote());

    try {
         await API.put('notesRDS', `/notes/${noteID}`, {
            body: { 
                "content": data,
                "color": color 
            }
        });
        await dispatch(NotesActions.updateNoteSuccess({ id: noteID, ...data }));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.updateNoteFail(error));
    }
};

export const handleBubbleUpdateNote = (noteID, data, color) => async(dispatch) => {
    await dispatch(NotesActions.updateNote());

    try {
         await API.put('notesRDS', `/bubbleUpdate/${noteID}`, {
            body: { 
                "content": data,
                "color": color 
            }
        });
        await dispatch(NotesActions.updateNoteSuccess({ id: noteID, ...data }));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(NotesActions.updateNoteFail(error));
    }
};