import React from 'react'
import styled from 'styled-components'
import "./customStyle.css"

export default function PrivacyPolicy() {
    return (
        <div className="termsContentUpr">
          <div className="termsContent">
           
           <div className="policyHead">
             <h1>One Way Education, LLC Data Privacy Policy</h1>
             <h2>Last modified: October 10th, 2022</h2>
           </div>

            <div className="policyIntro">
               <label>Introduction</label>
                    <p>One Way Education ("Company" or "We") respects your privacy and are committed to protecting it through our adoption of and compliance with this policy.
                        This policy describes the types of information we may collect from you or that you may provide when you visit the One Way Education, LLC website or access our Learning Site® (collectively, our "Website") and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
                </div> 

            <div className="policyApply policyApplyul">   
                <p>This policy applies to information we collect:</p>
                <ul>
                    <li>On the Website.</li>
                    <li>In email, text, and other electronic messages between you and the Website.</li>
                    <li>Through mobile and desktop applications you download, which provide dedicatednon-browser-based interaction between you and the Website.</li>
                </ul>
            </div>

            <div className="policyApply policyApplyul">   
                <p>It does not apply to information collected by:</p>
                    <ul>
                        <li>us offline or through any other means, including on any other website operated by One Way Education, LLC or any third party (including our affiliates and subsidiaries);
                        </li>
                     or
                        <li>any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website..</li>
                </ul>
                <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time. Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
            </div>

            <div className="policyIntro">
               <label>Children Under the Age of 13</label>
               <p>Except for use of our educational services for students which are covered by the One Way Education, LLC Student Information Privacy Notice, our Website is generally not intended for children under 13 years of age. No one under age 13 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 13. If you are under 13, do not register on the Website, use or provide any information on this Website or on or through any of its features, make any purchases through the Website, use any of the interactive or public comment features of the Website or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal 
                information from a child under 13 without verification of parental consent, we will delete that information. If you believe 
                we might have any information from or about a child under 13, please contact us at: Phone: 1 (888) 746 - 2388; 
                Email: <a href="mailto:Support@1wayeducation.com"><b>Support@1wayeducation.com</b></a>; or Postal Address: 5600 NW Central Dr, ST 278, Houston, TX 77092</p>
            </div> 



                <div className="policyIntro">
                    <label>A Note on Student Users and Education Records</label>
                    <p>One Way Education has additional policies, protocols, and protections in place for the personal information and education records of K-12 student users of One Way’s educational services. Our use and disclosure of student information is more limited than described in this general Privacy Policy and certain provisions of this Privacy Policy do not apply to student information. Please see the  <b>One Way Education, LLC Student Information Privacy</b> Notice for more specific information on how One Way collects, uses, maintains, protects, and discloses student information. To the extent that there is a conflict between the terms of this Privacy Policy and the One Way Education, LLC Student Information Privacy Notice with regard to the personally identifiable information of a student user, the terms of the Student Information Privacy Notice shall control.
                    </p>
                </div>  

            <div className="policyApply policyApplyul"> 
                <label>Information We Collect About You and How We Collect It</label>  
                <p>We collect several types of information from and about users of our Website, including information:</p>
                <ul>
                    <li>that identifies, relates to, describes, references, is capable of being associated with, or could 
                    reasonably be linked, directly or indirectly, with a particular consumer ordevice or by which you may be 
                    personally identified, such as name, postal address, e-mail address, telephone number, or any other identifier 
                    by which you may be contacted online or offline ("personal information")</li>
                    <li>that is about you but does not identify you individually, such as anonymous demographic data; and/or</li>
                    <li>about your internet connection, the equipment you use to access our Website, and usage details.</li>
                </ul>
            </div>

            <div className="policyApply policyApplyul"> 
                <p>We collect this information:</p>  
                <ul>
                    <li>Directly from you when you provide it to us.</li>
                    <li>Automatically as you navigate through the Website. Information collected automatically may include usage details, 
                    IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
                    <li>From third parties, for example, our business partners.</li>
                </ul>
            </div>

            <div className="policyApply policyApplyul"> 
             {/* <h4>One Way Education, LLC Data Privacy Policy</h4> */}
             <label className="policyAplyItlc">Information You Provide to Us</label>
                <p>The information we collect on or through our Website may include:</p>  
                <ul>
                    <li>Information that you provide by filling in forms or placing orders on our Website. 
                    This includes information provided at the time of registering to use our Website, subscribing to our service, 
                    posting material, or requesting further products orservices. We may also ask you for information when you interact w
                    ith us or report aproblem with our Website.</li>
                    <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                    <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                    <li>Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be 
                    required to provide financial information before placing anorder through our Website.</li>
                    <li>Your search queries on the Website.</li>
                </ul>
                <p>You also may provide information to be published or displayed (<b>"posted"</b>) on public areas of the Website, 
                or transmitted to other users of the Website or third parties (collectively, <b>"User Contributions"</b>). 
                Your User Contributions are posted on and transmitted to others at your own risk. Although we may limit access 
                to certain pages or you may set certain privacy settings for such information by logging into your account profile, 
                please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions 
                of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and 
                do not guarantee that your User Contributions will not be viewedby unauthorized persons.</p>
            </div>

            <div className="policyApply policyApplyul"> 
             <label className="policyAplyItlc">Information We Collect Through Automatic Data Collection Technologies</label>
                <p>As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, 
                    browsing actions, and patterns, including:</p>  
                <ul>
                    <li>Details of your visits to our Website, including traffic data, location data, logs, andother communication data and the resources that you access and use on the Website.</li>
                    <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
                </ul>
                <p>We also may use these technologies to collect information about your online activities overtime and across third-party websites or 
                other online services (behavioral tracking). Most web browsers have settings where you can adjust tracking settings. Please note that 
                One Way does not change its practices or procedures in response to a “Do Not Track” signal from a web browser or in the HTTP header from a browser or mobile application.</p>
            </div>

            <div className="policyApply policyApplyul"> 
             {/* <h4>One Way Education, LLC Data Privacy Policy</h4> */}
                <p>The information we collect automatically is statistical data and may include personal information, or we may maintain it 
                or associate it with personal information we collect inother ways or received from third parties. It helps us to improve our 
                Website and to deliver a better and more personalized service, including by enabling us to:</p>  
                <ul>
                    <li>Estimate our audience size and usage patterns.</li>
                    <li>Store information about your preferences, allowing us to customize our Website according to your individual interests.</li>
                    <li>Speed up your searches.</li>
                    <li>Recognize you when you return to our Website.</li>
                </ul>
            </div>

            <div className="policyApply policyApplyul">
                <p>The technologies we use for this automatic data collection may include:</p>  
                <ul>
                    <li><b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive ofyour computer. 
                    You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, 
                    if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted 
                    your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</li>
                    <li><b>Flash Cookies.</b> Certain features of our Website may use local stored objects (or Flash cookies) to collect and 
                    store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser 
                    settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, 
                    see <span style={{ textDecoration: 'underline' }}>Choices About How We Use and Disclose Your Information.</span></li>
                    <li><b>Web Beacons.</b> Pages of our the Website and our e-mails may contain small electronic files known as web beacons 
                    (also referred to as clear gifs, pixel tags, andsingle-pixel gifs) that permit the Company, for example, to count 
                    users who have visited those pages or opened an email and for other related website statistics (for example, recording the 
                    popularity of certain website content and verifying systemand server integrity).</li>
                </ul>
            </div>

            <div className="policyApply policyApplyul"> 
             <label>How We Use Your Information</label>
                <p>We use information that we collect about you or that you provide to us, including any personal information:</p>  
                <ul>
                    <li>To present our Website and its contents to you.</li>
                    <li>To provide you with information, products, or services that you request from us.</li>
                    <li>To fulfill any other purpose for which you provide it.</li>
                    <li>To provide you with notices about your account, including expiration and renewal notices.</li>
                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, 
                    including for billing and collection</li>
                    <li>To notify you about changes to our Website or any products or services we offer or provide though it.</li>
                    <li>To allow you to participate in interactive features on our Website.</li>
                    <li>In any other way we may describe when you provide the information.</li>
                    <li>For any other purpose with your consent.</li>
                </ul>
                <p>We may also use your information to contact you about our own goods and services that may be of interest to you.</p>
            </div>
                <div className="policyApply policyApplyul">
                    <label>Third-Party Services</label>
                    <p>
                        We utilize a variety of Third-Party Services to facilitate the operation of our Service. Presently, these include <a href='https://marketingplatform.google.com/about/analytics/'> Google Analytics </a> and <a href='https://developers.google.com/terms/api-services-user-data-policy'> Google APIs </a>with the potential for additional services in the future.
                        Our learning site use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy'> Google API Services  User Data Policy </a>, including the Limited Use requirements.
                    </p>
                </div>
          
            
            <div className="policyApply policyApplyul"> 
             <label className="policyAplyItlc">Disclosure of Your Information</label>
                <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
                <p>We do not disclose information to third-party advertisers for their direct marketing purposes.</p>
                <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>  
                <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to 
                    keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of 
                    some or all of One Wayassets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information 
                    held by One Way about our Website usersis among the assets transferred</li>
                    <li>To fulfill the purpose for which you provide it.</li>
                    <li>For any other purpose disclosed by us when you provide the information.</li>
                    <li>With your consent.</li>
                </ul>
            </div>

            <div className="policyApply policyApplyul"> 
             <label className="policyAplyItlc">We may also disclose your personal information:</label>
                <ul>
                <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                <li>To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes.</li>
                <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of One Way Education, LLC, 
                    our customers, users, employees, or others.</li>  
                </ul>
            </div>

            <div className="policyApply policyApplyul"> 
             {/* <h4>One Way Education, LLC Data Privacy Policy</h4> */}
             <label>Choices About How We Use and Disclose Your Information</label>
                <p>We strive to provide you with choices regarding the personal information you provide to us. 
                    We have created mechanisms to provide you with the following control over your information:</p>  
                <ul>
                    <li><b>Tracking Technologies and Advertising.</b> You can set your browser to refuse all orsome browser cookies, 
                    or to alert you when cookies are being sent. To learn howyou can manage your Flash cookie settings, visit the Flash player 
                    settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible 
                    or not function properly.</li>
                    <li><b>Promotional Offers from the Company.</b> If you do not wish to have your email address used by the Company to promote our own products 
                    or services, you can opt-out by sending us an email stating your request to <b>support@1wayeducation.com</b>. If we have sent you a promotional email, 
                    you may send us a return email asking to be omitted from future email distributions. This optout does not apply to information provided to the Company 
                    as a result of a product purchase, warranty registration, product service experience or other transactions.</li>
                </ul>
            </div>

            <div className="policyApply"> 
             <label>Accessing and Correcting Your Information</label>
                <p>You can review and change your personal information by logging into the Website and visiting your account profile page.</p>  
                <p>You may also send us an email at support@1wayeducation.com to request access to, correct or delete any personal information that 
                you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate 
                a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect</p>
                <p>If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages or 
                might have been copied or stored byother Website users.</p>
            </div>

                <div className="policyApply">
                    <label>Data Security</label>
                    <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.</p>
                    <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website. <br />
                        Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
                    </p>

                </div>


            <div className="policyApply"> 
             <label>Contact Information</label>
                <p>By email: SUPPORT@1WAYEDUCATION.COM</p>
                <p>By mail: One Way Education, LLC</p>
                <p>5600 NW Central Dr, Suite 278, Houston, TX 77092</p>
                <p>By Phone: 1 (888) 746 – 2388</p>
                <p>Monday – Friday: 8 am to 5 pm (Central Time)</p>
            </div>


        </div>
      </div>
    )
}
