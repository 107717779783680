//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash'
import * as ClassActivityActions from '../classActivities/actions';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create activity ----------------


//  -- Get activity -------------------


//  -- List chapter activities --------
export const handleListChapterActivities = (chapterID) => async (dispatch) => {
    await dispatch(ActivityActions.listChapterActivities());

    try {
        let activities = [];
        const activitiesRDS = await API.get('activitiesRDS', `/activitiesByChapter/${chapterID}`);
        if (Array.isArray(activitiesRDS) && activitiesRDS.length > 0) {
            activities = activitiesRDS
        }
        await dispatch(ActivityActions.listChapterActivitiesSuccess(activities));
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.listChapterActivitiesFail(error));
    }
};

//  -- List activities ----------------
export const handleListActivities = () => async (dispatch) => {
    await dispatch(ActivityActions.listActivities());

    try {
        let activities = [];
        const activitiesRDS = await API.get('activitiesRDS', `/activities`);
        if (Array.isArray(activitiesRDS) && activitiesRDS.length > 0) {
            activities = activitiesRDS
        }
        await dispatch(ActivityActions.listActivitiesSuccess(activities));
        return activities;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.listActivitiesFail(error));
    }
};


export const handleCreateActivities = (activity) => async (dispatch) => {
    dispatch(ActivityActions.createActivity())
    try {
        const activitiesRDS = await API.post('activitiesRDS', `/create`, {
            body: activity
        });
        if (has(activitiesRDS, "id")) {
            await dispatch(ActivityActions.createActivitySuccess(activitiesRDS))
        } else {
            await dispatch(ActivityActions.createActivityFail(activitiesRDS))
        }
    } catch (error) {
        console.log(error, "error in create activities");
    }
}
export const handleUpdateActivities = (id, activity) => async (dispatch) => {
    dispatch(ActivityActions.createActivity())
    try {
        const activitiesRDS = await API.put('activitiesRDS', `/update/${id}`, {
            body: activity
        });
        if (has(activitiesRDS, "id")) {
            await dispatch(ActivityActions.updateActivitySuccess(activitiesRDS))
        } else {
            await dispatch(ActivityActions.updateActivityFail(activitiesRDS))
        }
    } catch (error) {
        console.log(error, "error in create activities");
    }
}

export const handleDeleteActivities = (id) => async (dispatch) => {
    dispatch(ActivityActions.deleteActivity())
    try {
        const activitiesRDS = await API.del('activitiesRDS', `/delete/${id}`, {
        });
        if (has(activitiesRDS, "id")) {
            await dispatch(ActivityActions.deleteActivitySuccess(activitiesRDS))
        }
    } catch (error) {
        console.log(error, "error in create activities");
    }
}
export const handleListCustomAssignment = (role,userId) => async dispatch =>{
    await dispatch(ActivityActions.listCustomAssignmentByUser())

    try {
        const assignments =  await API.get('customAssignments',`/byUser/${role}/${userId}`);
        if(Array.isArray(assignments)){
            await dispatch(ActivityActions.listCustomAssignmentByUserSuccess(assignments))
        }
        return assignments;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ActivityActions.listCustomAssignmentByUserFail(error));
    }
}

export const handleCreateCustomAssignment = (data) => async dispatch =>{
    await dispatch(ActivityActions.createCustomAssignment())

    try {
        const assignments =  await API.post('customAssignments',`/create`,{
            body:data
        });
        if(has(assignments,"id")){
            await dispatch(ActivityActions.createCustomAssignmentSuccess(assignments))
        }
        return assignments;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ActivityActions.createCustomAssignmentFail(error));
    }
}
export const handleCloneCustomAssignment = (page, chapter, assignment_id, created_by) => async dispatch => {
    await dispatch(ActivityActions.createCustomAssignment())

    try {
        const assignments = await API.post('customAssignments', `/cloneAssignment`, {
            body: {
                "page": page,
                "chapter": chapter,
                "assignment_id": assignment_id,
                "created_by": created_by
            }
        });
        // if (has(assignments, "id")) {
        //     await dispatch(ActivityActions.createCustomAssignmentSuccess(assignments))
        // }
        return assignments;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ActivityActions.createCustomAssignmentFail(error));
    }
}

export const handleUpdateCustomAssignment = (data, deleteClone) => async dispatch =>{
    await dispatch(ActivityActions.updateCustomAssignment())

    try {
        const assignments =  await API.put('customAssignments',`/update/${data.id}`,{
            body:data
        });
        if(has(assignments,"id")){
            if(deleteClone === 'deleteClone'){
                await dispatch(ActivityActions.deleteCustomAssignmentSuccess(assignments?.id))
                await dispatch(ClassActivityActions.deleteClassActivitySuccess({ id: assignments?.id}));
            } else {
                await dispatch(ActivityActions.updateCustomAssignmentSuccess(assignments))
            }
        }
        return assignments;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ActivityActions.updateCustomAssignmentFail(error));
    }
}
export const handleDeleteCustomAssignment = (id) => async dispatch =>{
    await dispatch(ActivityActions.deleteCustomAssignment())
    try {
        const assignments =  await API.del('customAssignments',`/delete/${id}`);
        if(has(assignments,"id")){
            await dispatch(ActivityActions.deleteCustomAssignmentSuccess(id))
            await dispatch(ClassActivityActions.deleteClassActivitySuccess({ id }));
        }
        return assignments;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ActivityActions.deleteCustomAssignmentFail(error));
    }
}

export const handleGetAssignmentStatus = async (activityID,userId)=>{
    try {
        const statusData = await API.get('activitiesRDS', `/getAssignedStatusByUser/${activityID}/${userId}`);
        return statusData;
    } catch (error) {
        return []
    }
   
}

export const handleGetActivityById = async (activityID)=>{
    try {
        const statusData = await API.get('activitiesRDS', `/listByIdActivities/${activityID}`);
        return statusData;
    } catch (error) {
        return []
    }
   
}