import React from 'react'
import { Typography } from "@material-ui/core"
import moment from "moment"
const time_format = "HH:mm";

export function MessageRestric({ children, isBordcast, isStudent, hasChatHour, userChatHourConf , chatDisabled }) {
    if (isBordcast && isStudent) {
        return (<Typography className="sndMsgBetwen" align='center' variant="subtitle1">You can’t reply to broadcast messages. </Typography>)
    } else if (!hasChatHour && userChatHourConf && isStudent) {
        return (<Typography className="sndMsgBetwen" align='center' variant="subtitle1">{`You can only send messages to teacher between ${moment(userChatHourConf.startTime, time_format).format("hh:mm a")} to  ${moment(userChatHourConf.endTime, time_format).format("hh:mm a")}`}</Typography>)
    }
    else if(chatDisabled){
        return (<Typography className="sndMsgBetwen" align='center' variant="subtitle1">{isStudent?"Chat is disabled by the teacher.":"It appears that chat has been disabled. If you would like to continue , please enable it. "}</Typography>)
    }
    return (
        <>
            {children}
        </>
    )
}