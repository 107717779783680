//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
//  -- Thunks -------------------------
import { handleForcePasswordChange } from 'store/users/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';






//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
    margin: 0px auto;
    max-width: 480px;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Change password form
//
//  -- Description
//  Change password
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const ChangePasswordForm = ({
    userData,
    loading,
    onClosed
}) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [matchError, setMatchError] = useState(false);
    const [formatPasswordError, setFormatPasswordError] = useState(false);

    const handleChange = e => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
      
    const handleClickShowPasswordNew = () => {
        setShowPasswordNew(!showPasswordNew);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if(data.password === data.newpassword){
            setMatchError(false);
            let goodPassword = verifyPassword(data.password);
            if(goodPassword){
                let upPsw = {
                    username: userData.username,
                    password: data.password
                }
                await dispatch(handleForcePasswordChange(upPsw));
                onClosed();
            }
        }else{
            setMatchError(true);
        }
    };

    function verifyPassword(password){
        var paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if(password.match(paswd)) 
        {
            setFormatPasswordError(false);
            return true;
        }else
        { 
            setFormatPasswordError(true);
            return false;
        }
    }

    return (
        <Wrapper onSubmit={onSubmit}>
            <Header>Change Password User: {userData.firstName} {userData.lastName}</Header>
            <Description>Please use 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.</Description>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
                    <Col span={12}>
                    <TextField
                        id="password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        value={data?.password}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleClickShowPassword}
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        />
                    </Col>
                    <Col span={12}>
                    <TextField
                        id="newpassword"
                        variant="outlined"
                        type={showPasswordNew ? 'text' : 'password'}
                        label="Confirm password"
                        value={data?.newpassword}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleClickShowPasswordNew}
                                >
                                {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        />
                    </Col>
                    <Col>
                    { formatPasswordError &&
                            <FormHelperText error>Wrong password, try again.</FormHelperText>
                        }
                    </Col>
                    <Col>
                    { matchError &&
                            <FormHelperText error>Passwords doesn't match.</FormHelperText>
                        }
                    </Col>
                </Row>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
                <Col span={24}>
                    <Button
                        disabled={!(data.password && data.newpassword)}
                        variant="contained"
                        color="primary"
                        style={{ width: '100%' }}
                        type='submit'
                    >
                        {loading ? <Loading size={20} white /> : 'Update password'}
                    </Button>
                </Col>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ChangePasswordForm.propTypes = {};

//  -- Default props ------------------
ChangePasswordForm.defaultProps = {};

export default ChangePasswordForm;