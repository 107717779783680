//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const invokeAlert= (title , message) => ({ type: ActionTypes.alert.invokeAlert , payload:{title , message} });
export const revokeAlert = () => ({ type: ActionTypes.alert.revokeAlert ,payload:'' });

//  -- List All books ----------------
