import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  IconButton,
  Modal,
  Switch,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Check, Delete } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import ShareIcon from "@material-ui/icons/Share";
import { Row as Rows } from "antd";
import AnimatedCheckMark from "components/AnimatedCheckMark";
import { useFirebase } from "containers/FirebaseContext";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { find, get, has, map, shuffle } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useUsersByClass } from "store/userClasses/selectors";
import { useCurrentUser } from "store/users/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import { WriteEditWhite } from "utils/assets/images";
import { default as COLOR } from "utils/constants/color";
import { useUserWidgetsConfig } from "utils/methods/firebaseUserWidgets";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { v4 } from "uuid";
import EditModal from "./editModal";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const STATIONS = [
  { label: "LEE", enabled: true, custom: false },
  { label: "ESCRIBE", enabled: true, custom: false },
  { label: "ESCUCHA", enabled: true, custom: false },
  { label: "DEBATE", enabled: true, custom: false },
  { label: "INVESTIGA", enabled: true, custom: false },
];

const WidgetStationModal = ({ sharableWidget, currentClass }) => {
  const { firestore } = useFirebase();
  const { createUserWidgetsConfig } = useUserWidgetsConfig();
  const [editorFocus, setEditorFocus] = useState(false);
  const usersByClass = useUsersByClass();
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets } = useCollectionWidgets();
  const isTeacher = get(currentUser, "role") === "teacher";
  const dispatch = useDispatch();
  const timeoutRef = useRef();
  const [stations, setStations] = useState(STATIONS);
  const [usersList, setUsers] = useState([]);
  const [studentOfCurrentClass, setStudentOfCurrentClass] = useState(
    usersByClass[currentClass.id]
  );
  const [filteredStudentClass, setStudentFitlerStudentClass] = useState(
    usersByClass[currentClass.id]
  );
  const [numberOfStudentPerStation, setNumberOfStudentsPerStation] =
    useState(0);
  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "new_station"
  );
  const filterShareStation = find(
    sharableWidget,
    (obj) => obj?.widget_type === "new_station"
  );
  const [isStudentGenerated, setStudentGenerated] = useState(false);
  const sharedData = filterShareStation?.data || {};

  const [studentMinimized, setStudentMinimized] = useState(false);
  const [stationStudentGroup, setStationStudentGroup] = useState(
    sharedData?.group ? sharedData?.group : []
  );
  const [tweleveHourOption, setTweleveHourOption] = useState([]);
  const [classId, setClassId] = useState(currentClass.id);
  const [editSelectedOption, setEditSelectedOption] = useState(
    tweleveHourOption?.length > 0 ? tweleveHourOption : []
  );
  const[shareable,setShareable] = useState([]);

  const widgetStationOpen =
  get(currentUser, "role") === "teacher"
    ? filterByWidget?.view && filterByWidget?.menu === "new_station"
    : filterShareStation?.id && !studentMinimized;

    useEffect(()=>{
      if(classId !== currentClass.id){
        onResetGroup();
      }
    },[classId])

  useEffect(() => {
    if(classId !== currentClass.id){
      onResetGroup();
    }

    const updatedStudents = usersByClass[currentClass.id];
    setStudentOfCurrentClass(updatedStudents);
    setStudentFitlerStudentClass(updatedStudents);

  }, [usersByClass, currentClass.id]);


  // useEffect(() => {
  //   updateEditShare(stationStudentGroup);
  // }, [stationStudentGroup]);

  useEffect(() => {
    const unsubscribe = listenToWidgetUserConfig(get(currentClass, "id", ""));
    return () => {
      unsubscribe();
    };
  }, [
    firestore,
    currentClass,
    currentUser,
    filterByWidget,
    usersList,
    filterShareStation,
  ]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (widgetStationOpen) {
      document.body.classList.add("body-overflow-hidden");
    }

    return () => {
      document.body.classList.remove("body-overflow-hidden");
    };
  }, [widgetStationOpen]);

  useEffect(() => {
    if (usersByClass && has(currentClass, "id")) {
      const usr = map(
        usersByClass[currentClass.id],
        (k) => `${k.firstName || ""} ${k.lastName || ""}`
      );
      setUsers(usr);
    }
  }, [usersByClass, currentClass]);


 

  useEffect(() => {
    if (usersByClass && has(currentClass, "id")) {
      setStations(STATIONS);
    }
  }, [usersByClass]);

  const onCloseHandler = async () => {
    if (isTeacher) {
      await dispatch(revokeTrafficModal(filterByWidget?.id));
      return;
    }
    setStudentMinimized(true);
  };

  const shareHandler = async () => {
    if(stationStudentGroup?.length === 0){
      return;
    }
    if (!filterShareStation?.status) {
      shareStation();
      return;
    }
    // await onResetGroup();
    const closeShareData = {
      ...filterShareStation,
      status: false,
      data: {},
    };
    if (filterShareStation?.status) {
      createCollectionWidgets(closeShareData);
    }
    setStudentOfCurrentClass(usersByClass[currentClass.id]);
    setStudentFitlerStudentClass(usersByClass[currentClass.id]);
  };

  const createStudentGroupStation = (studentClassData) => {
    // use filter

    //edit -->  add ->original one dropdown list use original one
    setStudentGenerated(true);

    if (!usersByClass || !Array.isArray(studentClassData)) {
      setStationStudentGroup([]);
      return;
    }

    const shuffledStudents = shuffle([...studentClassData]);
    // studentOfCurrentClass --> originalone - add not remove in thus user
    // make one copy for filter of studentClass used for shuffle and generate remove or add data as well
    const filteredStations = stations
      .filter((station) => station.enabled && station.label)
      .map((station) => station.label);

    const studentGroupsWithStations = filteredStations.map((station, index) => {
      const start = index * numberOfStudentPerStation;
      const end =
        index === filteredStations.length - 1
          ? undefined
          : start + parseInt(numberOfStudentPerStation);

      return {
        station,
        students: shuffledStudents.slice(start, end),
      };
    });

    setStationStudentGroup(studentGroupsWithStations);

    const updatedShareData = {
      ...filterShareStation,
      data: {
        ...filterShareStation?.data,
        group: studentGroupsWithStations,
      },
    };
    if (filterShareStation?.id) {
      createCollectionWidgets(updatedShareData);
    }
  };

  const onClose = async () => {
    await onResetGroup();
    const closeShareData = {
      ...filterShareStation,
      status: false,
      data: {},
    };
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    if (filterShareStation?.status) {
      createCollectionWidgets(closeShareData);
    }
    setStudentOfCurrentClass(usersByClass[currentClass.id]);
  };

  const shareStation = (data = stationStudentGroup) => {
    if (!filterShareStation?.id) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "new_station",
        status: true,
        data: {
          group: data,
        },
      };
      createCollectionWidgets(shareData);
    }
  };

  const updateEditShare = (filteredData) => {
    const dataShare = {
      ...filterShareStation?.data,
      group: filteredData,
    };
    const editShareData = {
      ...filterShareStation,
      data: dataShare,
    };
    createCollectionWidgets(editShareData);
  };

  const onResetGroup = () => {
    const dataShare = {
      ...filterShareStation?.data,
      group: [],
    };
    const editShareData = {
      ...filterShareStation,
      data: dataShare,
    };
    setStudentGenerated(false);
    setNumberOfStudentsPerStation(0);
    setStationStudentGroup([]);
    createCollectionWidgets(editShareData);
  };

  const handleTouchStart = () => {
    setEditorFocus(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setEditorFocus(false);
    }, 1000);
  };

  


  const SendOnFirebase = (value) => {
    const WidgetsData = {
      id: "new_station",
      modified_on: new Date(),
      user_data: value,
    };
    createUserWidgetsConfig(WidgetsData, currentClass?.id);
  };



  const listenToWidgetUserConfig = (classID) => {
    if (!classID || !currentUser || filterByWidget?.menu !== "new_station") {
      return () => {};
    }
    const fifteenHoursAgo = new Date();
    fifteenHoursAgo.setHours(fifteenHoursAgo.getHours() - 12);
    const collectionRef = collection(
      firestore,
      `widgets_user_config/${classID}/widgets`
    );
    const q = query(
      collectionRef,
      where("id", "==", "new_station"),
      where("modified_on", ">=", fifteenHoursAgo)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        let sharableData = null;
        querySnapshot.forEach((doc) => {
          sharableData = { id: doc.id, ...doc.data() };
        });

        if (sharableData) {
          setTweleveHourOption(sharableData?.user_data || []);
        } else {
          setTweleveHourOption([]);
        }
      },
      (error) => {
        console.error("Error listening to widgets user config changes:", error);
      }
    );

    return unsubscribe;
  };

  function createStudent(fullName) {
    const [firstName, ...lastNameParts] = fullName.trim().split(/\s+/);
    const lastName = lastNameParts.join(' ');
    return {
        id: v4(), // Generating a random UUID
        firstName,
        lastName,
        role: "student",
        classId: classId // Using the same classId as others
    };
}


const newStudents = useMemo(() => {
  return tweleveHourOption.map(fullName => {
    const trimmedFullName = fullName.trim();
    return createStudent(trimmedFullName);
  });
}, [tweleveHourOption]);

useEffect(() => {
  if(usersByClass[currentClass?.id]?.length > 0){
    setStudentOfCurrentClass(prevStudents => {
      const updatedStudents = [...prevStudents];
      const studentsToAdd = [];
  
      newStudents.forEach(newStudent => {
        const exists = updatedStudents.some(student => 
          `${student.firstName} ${student.lastName}`.trim() === `${newStudent.firstName} ${newStudent.lastName}`.trim()
        );
        
        if (!exists) {
          studentsToAdd.push(newStudent);
        }
      });
  
      if (studentsToAdd.length > 0) {
        return [...updatedStudents, ...studentsToAdd];
      }
  
      return updatedStudents;
    });
  }
 
}, [newStudents]);


  return (
    <>
      {widgetStationOpen && (
        <div className="custom-fullscreen-container">
          <div className="modal-container">
            <div className="heading-label">New Station</div>
            <div className="modal-inner-container">
              {isTeacher && (
                <>
                  <button
                    disabled={stationStudentGroup?.length === 0}
                    style={{
                      backgroundColor: filterShareStation?.status && "green",
                    }}
                    onClick={shareHandler}
                    className="share-button-widget-modal "
                  >
                    {!filterShareStation?.status ? (
                      <ShareIcon style={{ color: "white", fontSize: 13 }} />
                    ) : (
                      <AnimatedCheckMark />
                    )}
                    {!filterShareStation?.status ? "Share" : "Shared"}
                  </button>
                </>
              )}

              <CloseIcon onClose={onCloseHandler} />
            </div>
            <div>
              <StudentSectionArea
                usersList={usersList}
                setUsers={setUsers}
                usersByClass={usersByClass}
                setNumberOfGroups={setNumberOfStudentsPerStation}
                createStudentGroup={createStudentGroupStation}
                studentGroup={stationStudentGroup}
                resetGroup={() => {
                  onResetGroup();
                }}
                stations={stations}
                onStationChange={setStations}
                numberOfGroups={numberOfStudentPerStation}
                isTeacher={isTeacher}
                currentClass={currentClass}
                currentUser={currentUser}
                setStudentOfCurrentClass={setStudentOfCurrentClass}
                studentOfCurrentClass={studentOfCurrentClass}
                filteredStudentClass={filteredStudentClass}
                setStudentFitlerStudentClass={setStudentFitlerStudentClass}
                setStationStudentGroup={setStationStudentGroup}
                SendOnFirebase={SendOnFirebase}
                tweleveHourOption={tweleveHourOption}
                isStudentGenerated={isStudentGenerated}
                editSelectedOption={editSelectedOption}
                setEditSelectedOption={setEditSelectedOption}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CloseIcon = ({ onClose }) => {
  return (
    <div onClick={onClose} className="cancel-icon-station">
      <CancelIcon />
    </div>
  );
};

export default WidgetStationModal;

const StudentSectionArea = ({
  setNumberOfGroups,
  numberOfGroups,
  createStudentGroup,
  studentGroup,
  resetGroup,
  stations,
  onStationChange,
  isTeacher,
  setStudentOfCurrentClass,
  currentClass,
  studentOfCurrentClass,
  currentUser,
  setUsers,
  usersList,
  setStationStudentGroup,
  filteredStudentClass,
  setStudentFitlerStudentClass,
  SendOnFirebase,
  tweleveHourOption,
  isStudentGenerated,
  editSelectedOption,
  setEditSelectedOption
}) => {
  const classes = useStyles();
  const [groups, setGroups] = useState(studentGroup);
  const [studentSelectModal, setStudentSelectModal] = useState(false);
  const [dragging, setDragging] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [newUser, setNewUser] = useState({});
  const [showInput, setShowInput] = useState({});
  const [addStations, setAddStations] = useState(false);
  const [stationsList, setStationsList] = useState(stations);
  const [newStation, setNewStation] = useState({
    label: "",
    enabled: true,
    custom: true,
  });
  const [editableIndex, setEditableIndex] = useState(-1);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [segments, setSegments] = useState([]);
  const [result, setResult] = useState("");


  
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setGroups(studentGroup);
  }, [studentGroup]);

  useEffect(() => {
    setStationsList(stations);
  }, [stations]);

  const handleToggleEnabled = (index) => {
    const updatedStations = [...stationsList];
    updatedStations[index].enabled = !updatedStations[index].enabled;
    onStationChange(updatedStations);
  };

  const handleDeleteStation = (index) => {
    const updatedStations = stationsList.filter((_, i) => i !== index);
    onStationChange(updatedStations);
  };

  const handleDragStart = (e, user, groupIndex) => {
    if (!isTeacher) return;
    const dragData = { userId: user.id, groupIndex };
    setDragging(user.id);
    e.dataTransfer.setData("application/json", JSON.stringify(dragData));
    e.target.style.opacity = "0.5";
  };

  const handleDragEnd = (e) => {
    setDragging(null);
    setExpandedGroup(null);
    e.target.style.opacity = ""; // Reset visual cue
  };

  const handleDragOver = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex);
  };

  const handleDragEnter = (e, groupIndex) => {
    e.preventDefault();
    setExpandedGroup(groupIndex); // Same as DragOver, to ensure consistency
  };

  const handleDragLeave = (e, groupIndex) => {
    if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
      setExpandedGroup(null);
    }
  };

  const handleDrop = (e, groupIndex) => {
    e.preventDefault();
    const dropData = JSON.parse(e.dataTransfer.getData("application/json"));
    if (dropData.groupIndex !== groupIndex) {
      const sourceGroupStudents = groups[dropData.groupIndex].students.filter(
        (user) => user.id !== dropData.userId
      );
      const droppedUser = groups[dropData.groupIndex].students.find(
        (user) => user.id === dropData.userId
      );
      const targetGroupStudents = [...groups[groupIndex].students, droppedUser];

      const newGroups = groups.map((group, index) => {
        if (index === dropData.groupIndex)
          return { ...group, students: sourceGroupStudents };
        if (index === groupIndex)
          return { ...group, students: targetGroupStudents };
        return group;
      });

      setGroups(newGroups);
      setStationStudentGroup(newGroups);
    }
    setExpandedGroup(null);
    setDragging(null);
  };
  const toggleInput = (groupIndex) => {
    setShowInput((prev) => ({ ...prev, [groupIndex]: !prev[groupIndex] }));
  };

  const handleAddNewUserToGroup = (groupIndex) => {
    if (newUser[groupIndex]) {
      const updatedGroups = groups.map((group, index) => {
        const newUserData = {
          id: v4(),
          role: "student",
          classId: currentClass?.id,
          ...newUser[groupIndex],
        };
        if (index === groupIndex) {
          return {
            ...group,
            students: [...group.students, newUserData],
          };
        }
        return group;
      });

      setStationStudentGroup(updatedGroups);

      setGroups(updatedGroups);

      const updatedStudentOfFilterCurrentClass = [
        ...studentOfCurrentClass,
        ...createStudentPayload([newUser[groupIndex]]),
      ];
      setStudentOfCurrentClass(updatedStudentOfFilterCurrentClass);
      setStudentFitlerStudentClass(updatedStudentOfFilterCurrentClass);

      const newStudentData = `${newUser[groupIndex]?.firstName} ${newUser[groupIndex]?.lastName}`;
      setUsers([...usersList, newStudentData]);
      setEditSelectedOption([...editSelectedOption, newStudentData]);

      setNewUser({ ...newUser, [groupIndex]: null });
      const newData = {
        ...newUser[groupIndex],
        id: v4(),
        role: "student",
        classId: currentClass?.id,
      };
      setStudentOfCurrentClass([...studentOfCurrentClass, newData]);
    }
  };

  const handleNewUserChange = (e, groupIndex) => {
    setNewUser({
      ...newUser,
      [groupIndex]: { ...newUser[groupIndex], [e.target.name]: e.target.value },
    });
  };

  // Function to handle scrolling when dragging near the edges
  const userAreaRef = useRef(null);
  const handleScroll = (event) => {
    const { clientY } = event;
    const userArea = userAreaRef.current;

    if (!userArea) return;

    const { top, bottom } = userArea.getBoundingClientRect();
    const offset = 10;

    if (clientY - top < offset) {
      userArea.scrollTop -= offset;
    } else if (bottom - clientY < offset) {
      userArea.scrollTop += offset;
    }
  };

  const handleTouchToggleInput = (event, groupIndex, value) => {
    event.preventDefault();
    if (value === "add_user") {
      toggleInput(groupIndex);
    } else {
      handleAddNewUserToGroup(groupIndex);
      toggleInput(groupIndex);
    }
  };

  const getNewgroup = groups;

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };


  const createStudentPayload = (items) => {
    const newUserListData = items?.map((name) => {
      const firstName = name?.firstName;
      const lastName = name?.lastName;

      return {
        id: v4(),
        role: "student",
        classId: currentClass?.id,
        firstName: firstName,
        lastName: lastName,
      };
    });
    return newUserListData;
  };

  return (
    <>
      {isTeacher && (
        <Rows
          onTouchStart={handleScroll}
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "52px",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          <InputField
            id="noOfGroup"
            name="noOfGroup"
            label={windowSize?.width >= 768 ? "Students per stations" : ""}
            style={{
              width: windowSize?.width >= 768 ? 124 : 46,
              color: "#000",
            }}
            InputLabelProps={{
              shrink: true,
              required: true,
              style: { color: "#000", fontSize: 14 },
            }}
            inputProps={{
              shrink: true,
              required: true,
              style: { color: "#000" },
            }}
            type="number"
            variant="outlined"
            onChange={(e) => {
              if (e.target.value >= 0) {
                setNumberOfGroups(e.target.value);
              }
            }}
            value={numberOfGroups}
            className={`${classes.noSpinners} group-input-label`}
          />
          <EditListOption
            onClick={() => {
              if(numberOfGroups?.toString()?.trim()?.length === 0 || numberOfGroups.toString()?.trim() === "0"){
                return;
              }
              const normalizedEditSelectedOption = editSelectedOption?.map(
                (option) => option?.trim()?.replace(/\s+/g, " ")
              );

              const filteredData = studentOfCurrentClass?.filter((student) =>
                normalizedEditSelectedOption?.includes(
                  `${student.firstName} ${student.lastName}`
                    ?.trim()
                    ?.replace(/\s+/g, " ")
                )
              );

               // Check if filteredData is an empty array
              const result = filteredData?.length > 0 ? filteredData : studentOfCurrentClass;

              setStudentFitlerStudentClass(result);
              createStudentGroup(result);
            }}
          
          
          >
            Generate
          </EditListOption>
          <ResetButton
            // style={{ width: "47px" }}
            onClick={resetGroup}
          >
            Reset
          </ResetButton>
          <EditListOption onClick={() => setStudentSelectModal(true)}>
            Edit <IconsImg src={WriteEditWhite} />
          </EditListOption>
        </Rows>
      )}
      <UserArea
        type={getNewgroup?.length > 0}
        dataExist={getNewgroup?.length > 0}
        ref={userAreaRef}
        onDragOver={handleScroll}
      >
        {groups?.length > 0 ? (
          groups?.map((group, groupIndex) => (
            <SectionsGroup>
              <GroupHeaderText>{group.station || ""}</GroupHeaderText>
              <UserListArea
                type={true}
                key={group.station}
                style={{ borderRadius: 20 }}
                className={
                  expandedGroup === groupIndex ? "expandOnDragOver" : ""
                }
                onDrop={(e) => handleDrop(e, groupIndex)}
                onDragOver={(e) => handleDragOver(e, groupIndex)}
                onDragEnter={(e) => handleDragEnter(e, groupIndex)}
                onDragLeave={(e) => handleDragLeave(e, groupIndex)}
              >
                {group.students.map((user) => {
                  const MyUser = user?.id === currentUser?.id;
                  return (
                    <UserItem
                      type={getNewgroup?.length > 0}
                      getMyUser={MyUser}
                      key={user.id}
                      className={dragging === user.id ? "hidden" : ""}
                      draggable={isTeacher ? true : false}
                      onDragStart={(e) => handleDragStart(e, user, groupIndex)}
                      onDragEnd={handleDragEnd}
                    >
                      {`${user.firstName || ""} ${user.lastName || ""}`}
                    </UserItem>
                  );
                })}
                {!showInput[groupIndex] && isTeacher && (
                  <IconButton
                    color="primary"
                    style={{ color: COLOR.blueishdeep }}
                    onClick={() => toggleInput(groupIndex)}
                    onTouchStart={(e) =>
                      handleTouchToggleInput(e, groupIndex, "add_user")
                    }
                  >
                    <AddIcon />
                  </IconButton>
                )}
                {showInput[groupIndex] && (
                  <div>
                    <UserInput
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="first-name-color"
                      variant="outlined"
                      size="small"
                      style={{ color: "white" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.firstName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <UserInput
                      type="text"
                      name="lastName"
                      className="last-name-color"
                      placeholder="Last Name"
                      variant="outlined"
                      size="small"
                      style={{ color: "white", marginTop: "10px" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newUser[groupIndex]?.lastName || ""}
                      onChange={(e) => handleNewUserChange(e, groupIndex)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: "10px", borderRadius: "50%" }}
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        handleAddNewUserToGroup(groupIndex);
                        toggleInput(groupIndex);
                      }}
                      onTouchStart={(e) =>
                        handleTouchToggleInput(e, groupIndex, "save_user")
                      }
                    />
                  </div>
                )}
              </UserListArea>
            </SectionsGroup>
          ))
        ) : (
          <>
            {isTeacher ? (
              <UserListArea type={false}>
                {map(stationsList, (station, i) => (
                  <UserItem key={station.label} style={{ cursor: "default" }}>
                    {editableIndex === i ? (
                      <UserInputControled
                        type="text"
                        name="station"
                        placeholder="station"
                        variant="outlined"
                        size="small"
                        autoFocus
                        style={{ color: "white" }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ shrink: true }}
                        value={newStation.label}
                        onChange={(e) => {
                          setNewStation({
                            ...newStation,
                            label: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      station.label
                    )}
                    {editableIndex === i ? (
                      <Check
                        style={{ cursor: "pointer", fontSize: 18 }}
                        onTouchStart={() => {
                          setEditableIndex(-1);
                          if (newStation.label.length > 0) {
                            let v = [...stationsList];
                            v[i] = newStation;
                            onStationChange(v);
                            setNewStation({
                              label: "",
                              enabled: true,
                              custom: true,
                            });
                          }
                        }}
                        onClick={() => {
                          setEditableIndex(-1);
                          if (newStation.label.length > 0) {
                            let v = [...stationsList];
                            v[i] = newStation;
                            onStationChange(v);
                            setNewStation({
                              label: "",
                              enabled: true,
                              custom: true,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div>
                        <EditOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditableIndex(i);
                            setNewStation(station);
                            setAddStations(false);
                          }}
                        />
                        {!station.custom && (
                          <Switch
                            checked={station.enabled}
                            size="small"
                            onChange={() => handleToggleEnabled(i)}
                            name="enabled"
                            color="primary"
                          />
                        )}
                        {station.custom && (
                          <IconButton
                            size="small"
                            color="secondary"
                            style={{ marginLeft: 10, fontSize: 20 }}
                            onClick={() => handleDeleteStation(i)}
                            onTouchStart={() => handleDeleteStation(i)}
                          >
                            <Delete style={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                      </div>
                    )}
                  </UserItem>
                ))}
                {!addStations ? (
                  <IconButton
                    color="primary"
                    onClick={() => setAddStations(true)}
                    onTouchStart={() => setAddStations(true)}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <div>
                    <UserInput
                      type="text"
                      name="station"
                      placeholder="station"
                      variant="outlined"
                      size="small"
                      style={{ color: "white" }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ shrink: true }}
                      value={newStation.label}
                      onChange={(e) => {
                        setNewStation({ ...newStation, label: e.target.value });
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: "10px", borderRadius: "50%" }}
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        if (newStation.label.length > 0) {
                          onStationChange([...stationsList, newStation]);
                          setNewStation({
                            label: "",
                            enabled: true,
                            custom: true,
                          });
                          setAddStations(false);
                        } else {
                          setAddStations(false);
                        }
                      }}
                      onTouchStart={() => {
                        if (newStation.label.length > 0) {
                          onStationChange([...stationsList, newStation]);
                          setNewStation({
                            label: "",
                            enabled: true,
                            custom: true,
                          });
                          setAddStations(false);
                        } else {
                          setAddStations(false);
                        }
                      }}
                    />
                  </div>
                )}
              </UserListArea>
            ) : (
              <UserListAreaEmpty
                className="gradient-blue-pink"
                style={{
                  justifyContent: "center",
                }}
              >
                <span style={{ color: "white", fontSize: 18 }}>
                  Not available.
                </span>
              </UserListAreaEmpty>
            )}
          </>
        )}
      </UserArea>

      <Modal open={studentSelectModal}>
        <ModalContent style={{ marginTop: "20px" }}>
          <EditModal
            options={usersList}
            onListChange={(items) => {
              setUsers([...usersList, ...items]);
            }}
            onSelect={(value) => {
              setSegments(value);
              // setStudentSelectModal(false);
              if (!value.includes(result)) {
                setResult("");
              }
            }}
            setEditSelectedOption={setEditSelectedOption}
            editSelectedOption={editSelectedOption}
            defaultValue={segments}
            hideModal={() => setStudentSelectModal(false)}
            studentOfCurrentClass={studentOfCurrentClass}
            filteredStudentClass={filteredStudentClass}
            setStudentFitlerStudentClass={setStudentFitlerStudentClass} //filter current class
            setStudentOfCurrentClass={setStudentOfCurrentClass} // original current class
            // addListHandler={addListHandler}
            createStudentGroup={createStudentGroup}
            createStudentPayload={createStudentPayload}
            SendOnFirebase={SendOnFirebase}
            tweleveHourOption={tweleveHourOption}
            isStudentGenerated={isStudentGenerated}
            setUsers={setUsers}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  // min-height: 500px;
  // background-color: rgb(58,58,58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: default;
  left: 25%;
  border-radius: 13px;
`;

const LogoTopRow = styled.div``;
const Logo = styled.img`
  height: auto;
  width: 160px;
`;

const CloseBtn = styled.div`
  background-color: ${COLOR.PinkExam};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const EditListOption = styled.div`
  text-align: center;
  align-items: center;
  background-image: linear-gradient(
    90deg,
    ${COLOR.WheelEditColor1} 0%,
    ${COLOR.WheelEditColor2} 40%,
    ${COLOR.WheelEditColor3} 100%
  );
  color: #fff;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 80px;
  }
`;

const ResetButton = styled.div`
  text-align: center;
  align-items: center;
  background: #d11919;
  color: white;
  display: flex;
  transition: all 300ms ease;
  white-space: nowrap;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  font-weight: bolder;
  cursor: pointer;
  // @media (max-width: 997px) {
  //   margin-top: 16px;
  // }

  @media only screen and (max-width: 768px) {
    width: 80px;
  }
`;

const UserArea = styled.div`
  display: ${(props) => (props?.dataExist ? "flex" : "")};
  flex-wrap: wrap;
  justify-content: start;
  // flex-direction: row;
  margin-top: 52px;
  // flex-wrap: wrap;
  height: calc(100% - 100px);
  //   place-items: center;

  max-width: ${(props) => (!props?.type ? "443px" : "")};
  // max-height: ${(props) => (!props?.type ? "431px" : "460px")};
  margin-inline: ${(props) => (!props?.type ? "auto" : "")};

  // grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  // overflow: hidden auto !important;
  //   @media (max-width: 997px) {
  //     max-width: 90vw;
  //   }

  ::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

const SectionsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-inline: 8px;
  }
`;

const GroupHeaderText = styled.div`
  background-color: #fd9d1a;
  padding: 8px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 200px;
  text-align: center;
  color: #fff;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const UserListArea = styled.div`
  margin-inline: auto;
  background: linear-gradient(
    90deg,
    #df6074 0%,
    #bd57b4 30%,
    #a451e7 50%,
    #9a59e8 60%,
    #7579f0 90%,
    #6786f4 100%
  ) !important;
  min-height: 114px;
  width: ${(props) => (!props?.type ? "" : "200px")};
  border-radius: 10px;
  padding: 10px;
  margin: ${(props) => (props?.type ? "12px 16px" : "")};
  margin-bottom: ${(props) => !props?.type && "10px"};
  height: ${(props) => (props?.type ? "100%" : "")};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const UserItem = styled.div`
  background: ${(p) => (p.getMyUser ? "#152B69" : "#fff")};
  color: ${(p) => (p.getMyUser ? "#fff" : "")};
  padding: 8px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
`;

const UserInput = styled(TextField)`
  margin-top: 8px;
`;

const UserInputControled = styled(TextField)`
  margin-top: 8px;

  .MuiInputBase-root {
    height: 25px; // Adjust the height as needed
    padding: 0 14px; // Reduce padding to decrease height

    .MuiInputBase-input {
      font-size: 0.875rem; // Adjust font size to match the smaller input
    }
  }

  .MuiInputLabel-root {
    font-size: 0.875rem; // Adjust label font size to match
  }

  .MuiOutlinedInput-root {
    padding: 0;
    position: relative;
  }
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const UserListAreaEmpty = styled.div`
  background: #cbbbe7;
  min-height: 114px;
  min-width: 175px;
  border-radius: 10px;
  padding: 10px;
  margin: 12px 16px;
  display: flex;
  align-items: center;
`;

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  noSpinners: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const IconsImg = styled.img`
  height: 15px;
  width: 15px;
  object-fit: cover;
  margin-left: 10px;
`;

const ModalContent = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;
