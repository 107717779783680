//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector , useDispatch } from 'react-redux'
import * as action from "./actions"



//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.timmerRunning;

//  -- Examples array selector --------

export const useTimmerRunning = () => {
    const selectedData = useSelector(selector, shallowEqual)
    const dispatch = useDispatch()
    const setTimmerRunning = () =>{
        dispatch(action.createInitTimmer(true))
    }
    const clearTimmerRunning = () =>{
        dispatch(action.clearTimmer(false))
    }
    return [selectedData,setTimmerRunning,clearTimmerRunning]
}

