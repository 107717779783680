import React, { useCallback, useEffect, useMemo, useState } from "react";
import { get, find, filter } from "lodash";
import { bubbleNotes } from "utils/assets/images";
import { Rnd } from "react-rnd";
import styled from "styled-components";
import COLOR from "utils/constants/color";
import { SearchOutlined } from "@ant-design/icons";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Dropdown, Menu } from "antd";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import NoteForm from "features/forms/BubbleNotes";
import MessageRenderer from "components/MessageRenderer";
import { useNotesByBook } from "store/notes/bubbleSelector";
import { lighten } from "polished";
import ResizeAndDragabbleNotes from "./ResizeAndDragabbleNotes";
import { imageFetch, imageFetchFromPodcast, isVideo } from "utils/lib/ebookHelper";
import { useResources } from "store/resources/selectors";
import { useAllBook } from "store/books/selectors";
import ClickOutside from "./OnClickOutside";

const SearchArea = styled.div`
  border-radius: 20px;
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #ddd;
  justify-content: center;
  align-items: center;
`;

const SearchIconArea = styled.div`
  width: 45px;
  height: 38px;
  border-radius: 50% 0% 0% 50%;
  background: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchInput = styled.input`
  width: 90%;
  height: 30px;
  font-size: 15px;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 5px;
  color: black;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR.black500};
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  color: white;
  border-bottom: 1px dotted white;
  padding: 10px;
  &:hover {
    background-color: inherit !important;
  }
`;

const ButtonClear = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    background-color: #7978c7 !important;
    border: none;
    padding: 0;
  }
`;

const Image = styled.img`
  background-color: ${COLOR.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: auto;
  width: 33.87%;
  display: flex;
  margin: 0 auto;
  margin-top: 22px;
`;

const NotesText = styled.span`
  color: #545658;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 5px;
  margin-top: 3px;
`;

const NoteSec = styled.div`
  background-color: ${(p) => (p.color ? lighten(0.4, `${p.color}`) : "white")};
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
  margin-top: 20px;
  height: 127px;
  padding: 5px;
  overflow: auto;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #010a52;
  height: 75px;
  border-radius: 0% 0% 18px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DropdownMenu = ({ isStudent, isTeacher, navigate }) => {
  const menu = (
    <Menu style={{ backgroundColor: "#7978c7" }}>
      {isStudent && (
        <StyledMenuItem key="calendar">
          <ButtonClear onClick={() => navigate("calendar")}>
            Calendar
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isStudent && (
        <StyledMenuItem key="studentData">
          <ButtonClear onClick={() => navigate("studentData")}>
            Student Data
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isTeacher && (
        <StyledMenuItem key="manage">
          <ButtonClear onClick={() => navigate("manage")}>
            Assignments
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isTeacher && (
        <StyledMenuItem key="gradebook">
          <ButtonClear onClick={() => navigate("gradebook")}>
            Gradebook
          </ButtonClear>
        </StyledMenuItem>
      )}
      {isTeacher && (
        <StyledMenuItem key="classdata">
          <ButtonClear onClick={() => navigate("classdata")}>
            Class Data
          </ButtonClear>
        </StyledMenuItem>
      )}
    </Menu>
  );

  return (
    <StyledDropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
      <IconButton aria-label="menu" style={{ height: 30, width: 30 }}>
        <MenuIcon style={{ fontSize: "24px", color: "#ffffff" }} />
      </IconButton>
    </StyledDropdown>
  );
};

const BubbleNotes = ({
  currentUser,
  pageSrc,
  newMaskNote,
  pageNotes,
  page,
  data,
  chapter,
  dataBook,
  BALCON_PAGES=[],
  pageName=""
}) => {
  const [showDraggable, setShowDraggable] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [viewNote, setViewNote] = useState({});
  const [serachText, setSearchText] = useState("");
  const isStudent = currentUser && currentUser.role === "student";
  const isTeacher = currentUser && currentUser?.role === "teacher";
  const history = useHistory();
  const seeAllNotes = useNotesByBook();
  const [listSeeAllNotes, setListSeeAllNotes] = useState(seeAllNotes);
  const [isEditing, setIsEditing] = useState(false);

  // // Define the initial position for Rnd
  // const initialX = window.innerWidth / 2 - 1175; // 200 = (default width / 2)
  // const initialY = window.innerHeight / 2 - 368; // 200 = (default height / 2)

  useEffect(() => {
    setListSeeAllNotes(seeAllNotes);
  }, [seeAllNotes]);
  const bookAllList = useAllBook();
  const resources = useResources()
  const modalSrc = useMemo(() => isVideo(chapter, resources) ? imageFetchFromPodcast(chapter, resources) : imageFetch(bookAllList, chapter, viewNote?.page),[viewNote,resources,bookAllList]);

  const getSrcPage = useCallback(
    (page) => {
      let dataPage = find(data?.content, (x) => x.pageId == Number(page));
      let url = "";
      if (dataPage) {
        url = dataPage.src;
      }
      return url;
    },
    [viewNote]
  );

  const navigateToStudentDashboard = (to) => {
    switch (to) {
      case "calendar":
        history.push("/student/class?view=assessments");
        break;
      case "studentData":
        history.push("/student/class?view=studentData");
        break;
      default:
        history.push("/student/class?view=home");
        break;
    }
  };

  const navigateToTeacherDashboard = (to) => {
    switch (to) {
      case "manage":
        history.push("/admin/class?view=manage");
        break;
      case "gradebook":
        history.push("/admin/class?view=gradebook");
        break;
      case "classdata":
        history.push("/admin/class?view=classdata");
        break;
      default:
        history.push("/admin/class?view=home");
        break;
    }
  };

  const onBubbleNotesClick = () => {
    setShowDraggable((prevState) => !prevState);
    setShowNote(false);
    setIsEditing(false);
  };

  const handleSeeAllNotesClick = () => {
    setShowAllNotes(!showAllNotes);
    setShowNote(false);
  };

  const handleBackClick = () => {
    setShowAllNotes(false);
  };

  const onSaveAndClose = () => {
    setShowDraggable(!showDraggable);
    setShowNote(false);
    setIsEditing(false);
  };

  const handleAllNotesClick = (note) => {
    setViewNote(note);
    setShowAllNotes(false);
    setShowNote(true);
  };

  const searchitems = (txt) => {
    setSearchText(txt);
    if (typeof txt == "string" && txt.length > 0) {
      txt = txt.toLowerCase();
      const fStory = filter(seeAllNotes, (st) => {
        const newMaskId = get(
          find(get(data, "content"), (k) => k.pageId == st?.page),
          "numberMasked",
          st?.page
        );
        if (newMaskId && newMaskId.length > 0) {
          if (newMaskId.toLowerCase().includes(txt)) {
            return true;
          }
        } else if (st?.content?.content && st?.content?.content?.length > 0) {
          if (st?.content?.content?.toLowerCase().includes(txt)) {
            return true;
          }
        }
        return false;
      });
      setListSeeAllNotes(fStory);
    } else {
      setListSeeAllNotes(seeAllNotes);
    }
  };

  const messageRenderData = pageNotes[0]?.content?.content
    ? pageNotes[0]?.content?.content
    : pageNotes[0]?.content;

  const messageRenderAllData = viewNote?.content?.content
    ? viewNote?.content?.content
    : viewNote?.content;

  const messageRenderForColor = pageNotes[0]?.color?.color ? pageNotes[0]?.color?.color : pageNotes[0]?.color
  const newMaskIdForAll = get(
    find(get(data, "content"), (k) => k.pageId == viewNote?.page),
    "numberMasked",
    viewNote?.page
  );

  const [position, setPosition] = useState({
    x: -450,
    y: 50,
    width: 215,
    height: 255,
  });

  const handleResize = () => {
    if (window.innerWidth <= 400) {
      // Mobile view
      setPosition((prev) => ({ ...prev, x: -260 }));
    } else if (window.innerWidth <= 768) {
      // Tablet view
      setPosition((prev) => ({ ...prev, x: -240 }));
    } else if (window.innerWidth <= 1024) {
      // Tablet view
      setPosition((prev) => ({ ...prev, x: -400 }));
    } else {
      // Desktop view
      setPosition((prev) => ({ ...prev, x: -450 }));
    }
  };

  useEffect(() => {
    handleResize(); // Set initial position based on screen size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getShortNameForName = useMemo(() =>{
    const allPageNumber = seeAllNotes.map((item) => item.page);

    const getShortName = allPageNumber.map((item2) => {
      const data = BALCON_PAGES.find((balcon) => {
        return balcon.start <= item2 && item2 <= balcon.finish;
      });
      return data;
    });
    return getShortName;
},[seeAllNotes,BALCON_PAGES]);

const viewNoteShortName = useMemo(()=>{
  const data = BALCON_PAGES.find((balcon) => {
    return balcon.start <= viewNote?.page && viewNote?.page <= balcon.finish;
  });
  return (data|| {});
},[viewNote,BALCON_PAGES])

const [disableDragOnFocus, setDisableDragOnFocus] = useState(false);
const [isDragging, setIsDragging] = useState(false);


  return (
    <div className="bubble-notes">
      <div
        className="bubble-note-img"
        onClick={() => {
          onBubbleNotesClick();
        }}
      >
        <img src={bubbleNotes} alt="Bubbleimg" />
      </div>

      {showDraggable && (
        <ClickOutside onClickOutside={() => setIsDragging(false)}>
        <ResizeAndDragabbleNotes
          // onFocusValue={disableDragOnFocus}
          onFocusValue={isDragging}
          // className="bubbleNotesTopSection"
          defaultValue={position}
          minWidth="var(--min-width)"
          minHeight="var(--min-height)"
          bounds="window"
          enableResizing={false}
          from="notes"
          style={{
            boxShadow:
              "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
            backgroundColor: COLOR.white,
            overflow: "hidden",
          }}
          className={`bubbleNotesTopSection ${
            isDragging  ? "NotesMobAndTab" : ""
          }`}
        >
          <div 
            // onTouchStart={() => setIsDragging(true)}
            // onClick={() => setIsDragging(true)}
            onTouchStart={(event) => {
              event.stopPropagation();
              setIsDragging(true)}}
          >
            <div>
              {showAllNotes ? (
                <div className="topNotesScection">
                  <SearchArea>
                    <SearchIconArea>
                      <SearchOutlined />
                    </SearchIconArea>
                    <SearchInput
                      type={"text"}
                      value={serachText}
                      placeholder="Search by page"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Search by page")}
                      onChange={(e) => {
                        searchitems(e.target.value);
                      }}
                    />
                  </SearchArea>
                </div>
              ) : (
                <div className="pencil-icon-notes-form" style={{ textAlign: "end" }}>
                  <IconButton
                    color="primary"
                    style={{ padding: 0 }}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                    // onTouchStart={() => {
                    //   setIsEditing(!isEditing);
                    // }}
                  >
                    <Edit style={{ fontSize: "30px", color: "#3E5F8F" }} />
                  </IconButton>
                </div>
              )}
              {!showNote && (
                <>
                  {isEditing ? (
                    <div>
                      <NoteForm
                        pageNotes={pageNotes[0]}
                        data={pageNotes?.length > 0 ? pageNotes : []}
                        page={page}
                        chapter={chapter}
                        close={onSaveAndClose}
                        draggable={(value) => setDisableDragOnFocus(value)}
                      />
                    </div>
                  ) : showAllNotes ? (
                    <div>
                      <div className="seeAllAndBackBtn">
                        <button>SEE ALL NOTES</button>
                        <button
                          onClick={handleBackClick}
                          // onTouchStart={handleBackClick}
                        >
                          BACK
                        </button>
                      </div>
                      <div className="allBubbleNotesList">
                        {listSeeAllNotes && listSeeAllNotes.length > 0 ? (
                          listSeeAllNotes.map((note, index) => {
                            const newMaskId = get(
                              find(
                                get(data, "content"),
                                (k) => k.pageId == note?.page
                              ),
                              "numberMasked",
                              note?.page
                            );
                            return (
                              <div
                              onClick={() => {
                                handleAllNotesClick(note);
                              }}
                              // onTouchStart={() => {
                              //   handleAllNotesClick(note);
                              // }}
  
                                key={index}
                                className="notsMidTxt notsPageAndTitle"
                              >
                                <label style={{ cursor: "pointer" }}>
                                  <span>{`Page. ${pageName !== "balcon" ? newMaskId : `${getShortNameForName[index]?.shortName}`}`}</span>
                                  <span>
                                    {" "}
                                    {note.title || dataBook?.description}
                                  </span>
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <div className="bubbleNotesNoData">No Data</div>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div>
                      <div className="notsMidTxt">
                        <p>{`Titulo: ${pageName==="balcon" ? viewNoteShortName?.shortName || "" : ""} ${dataBook?.description || dataBook?.displayName || ""}`}</p>
                        <label>
                          {pageName !== "balcon" && (
                            <>
                            {newMaskNote!=="" && newMaskNote?.length > 0 && (
                              <span>{`Page. ${newMaskNote}`}</span>
                          )}
                            </>
                            
                          )}
                          <span> (Notes will be saved on this page)</span>
                          {pageName !== "videosection" && (
                            <button
                            onClick={handleSeeAllNotesClick}
                            onTouchStart={handleSeeAllNotesClick}
                          >
                            SEE ALL NOTES
                          </button>
                          )}
                          
                        </label>
                        <Image src={pageSrc} alt="" />

                        <NoteSec color={messageRenderForColor} className="bubbleNotsMidTxt">
                          <NotesText>Notes:</NotesText>
                          <MessageRenderer
                            style={{ color: "rgb(0 0 0)" }}
                            data={messageRenderData}
                          />
                        </NoteSec>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {showNote && (
              <>
                {isEditing ? (
                  <div onMouseEnter={()=> setDisableDragOnFocus(true)} onMouseLeave={()=> setDisableDragOnFocus(false)}>
                    <NoteForm
                      pageNotes={viewNote}
                      data={[viewNote]}
                      page={viewNote?.page}
                      chapter={viewNote?.chapter}
                      close={onSaveAndClose}
                      draggable={(value) => setDisableDragOnFocus(value)}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="notsMidTxt bubbleNotsMidTxt">
                      <p>{`Titulo:  ${pageName==="balcon" ? viewNoteShortName?.shortName || "" : ""} ${pageName==="videosection"? dataBook?.displayName :dataBook?.description }`}</p>
                      <label>
                      {pageName !== "balcon" && (
                        <>
                        <span>{`Page. ${newMaskIdForAll}`}</span>
                        </>
                      )}
                        
                        <span> (Notes will be saved on this page)</span>
                        <button
                          onClick={handleSeeAllNotesClick}
                          onTouchStart={handleSeeAllNotesClick}
                        >
                          SEE ALL NOTES
                        </button>
                      </label>
                      <Image src={ modalSrc} alt="" />

                      <NoteSec color={viewNote?.color?.color}>
                        <NotesText>Notes:</NotesText>
                        <MessageRenderer
                          style={{ color: "rgb(0 0 0)" }}
                          data={messageRenderAllData}
                        />
                      </NoteSec>
                    </div>
                  </div>
                )}
              </>
            )}
            {!isEditing && (
              <Footer>
                <button
                  className="notesSaveCloseBtn"
                  onClick={onSaveAndClose}
                  onTouchStart={onSaveAndClose}
                >
                  Close
                </button>
              </Footer>
            )}
          </div>
        </ResizeAndDragabbleNotes>
        </ClickOutside>
      )}

      {(pageName !== "balcon" && pageName !=="videosection") && (
        <DropdownMenu
        isStudent={isStudent}
        isTeacher={isTeacher}
        navigate={
          isTeacher ? navigateToTeacherDashboard : navigateToStudentDashboard
        }
      />
      )}
    
    </div>
  );
};

export default BubbleNotes;
