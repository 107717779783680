import React, { useRef } from "react";
import { Tooltip } from "antd";
import ClickOutside from "components/OnClickOutside";
import styled from "styled-components";
import BottomDropDown from "utils/assets/images/resource-dropdown.svg";

const CategoryMenu = styled.div`
  background-color: #0b1c2b;
  border-radius: 30px;
  color: #fff;
  // width: 201px;
  height: 42px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  // text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #2784a3;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 768px) {
    // width: 100% !important;
    height: 36px;
    color: #fff;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const AncotasDropDown = ({
  data,
  open,
  dropDownHandler,
  anecotasThemeFilter,
  closeDropDown,
  handleDropdownClick,
  isResizingWindow,
  typeOf,
}) => {
  return (
    <DropdownForLaptopAncotas
      open={open}
      anecotasThemeFilter={anecotasThemeFilter}
      closeDropDown={closeDropDown}
      dropDownHandler={dropDownHandler}
      data={data}
      handleDropdownClick={handleDropdownClick}
      isResizingWindow={isResizingWindow}
      typeOf={typeOf}
    />
  );
};

const DropdownForLaptopAncotas = ({
  dropDownHandler,
  data,
  anecotasThemeFilter,
  closeDropDown,
  handleDropdownClick,
  open,
  isResizingWindow,
  typeOf
}) => {
  const ref = useRef();

  const renderSection = (title, dataSource, titleProperty) => (
    <>
      <div className="text-center border-b-white mi-17px anctodas-theme-dropdown-heading pt-24px pb-13px">
        {title}
      </div>
      <div className="d-grid place-items-start grid-2-column">
        {[0, 1]?.map((columnIndex) => (
          <div key={`column-${columnIndex}`} className="w-full">
            {dataSource
              .slice(columnIndex * 3, (columnIndex + 1) * 3)
              .map((ct, index) => (
                <div
                  onClick={() =>
                    dropDownHandler("anecotasThemeFilter", ct[titleProperty])
                  }
                  key={`${title}-${columnIndex}-${index}`}
                  className="border-b-13314C"
                >
                  <div
                    className={`anctodas-theme-dropdown-label gap-10px flex items-center pt-15px pb-15px ${
                      columnIndex === 0 ? "pi-17px" : ""
                    }`}
                  >
                    <input
                      className="w-16px-h-16px"
                      type="checkbox"
                      checked={anecotasThemeFilter?.includes(ct[titleProperty])}
                    />
                    {ct[titleProperty].length > 24
                      ? ct[titleProperty].slice(0, 24) + "..."
                      : ct[titleProperty]}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </>
  );
  const createLabelDiv = (titles) => (
    <div className="flex-1" disabled={true}>
      <div className="pl-34px grid-3-template bg-column-gap-45px">
        {titles.map((title, index) => (
          <div
            key={index}
            style={{ paddingLeft: 0 }}
            className="w-250px ap-spanish-label border-b-lightGrey p-18px"
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );

  const createThemeItem = (ct) => (
    <div
      className="ib-theme-button grid-3-template  border-for-inner"
      style={{ padding: 0, height: "60px" }}
    >
      {["", "2", "3"].map((suffix, i) => {
        const title = ct[`title${suffix}`];
        const icon = ct[`icon`];
        return (
          <Tooltip key={i} placement="top" title={title}>
            <div
              onClick={() => dropDownHandler("anecotasThemeFilter", title)}
              className={`${
                i === 0 ? "pl-34px" : ""
              } dropdown-menus-item cursor-pointer h-full d-flex items-center w-fit-content`}
              style={{
                paddingLeft: i !== 0 ? "20px" : undefined,
                marginLeft: i !== 0 ? "20px" : undefined,
              }}
            >
              <input
                className="w-16px-h-16px"
                type="checkbox"
                checked={anecotasThemeFilter?.includes(title)}
              />
              <img
                src={icon}
                loading="lazy"
                className="image-dropdown-ancodtas"
                alt=""
              />
              <div className="ellipse dropdown-list-name-ap-theme">{title}</div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );

  return (
    <>
      <CategoryMenu
        ref={ref}
        style={{
          paddingRight: "0px",
          paddingLeft: "0px",
          border: "1px solid #ffffff",
          position: "relative",
          cursor: "pointer",
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleDropdownClick("ANéCDOTAS THEMES");
        }}
        className="md-w-full-2 box-shadow-resource-hover w-247px lg-w-231px box-shadow-resource-hover  arrow-contanier-resoruce  h-43px lg-h-36px  searchIconMob"
      >
        <Tooltip
          title={anecotasThemeFilter?.join(", ").toString()}
          placement="top"
        >
          <span className="dropdown-text lg-pl-17px pl-16px lg-pr-4px">
            {anecotasThemeFilter &&
            anecotasThemeFilter?.length > 0 &&
            anecotasThemeFilter !== ""
              ? anecotasThemeFilter.join(",").length > 20
                ? anecotasThemeFilter?.join(",").slice(0, 20) + "..."
                : anecotasThemeFilter
              : "ANÉCDOTAS THEMES"}
          </span>
        </Tooltip>

        {/* <MenuIconImg src={MenuIcon} />{" "} */}
        <div className="dropdown-resource-arrow-container themeSearchIconMob">
          <div
            style={{
              transition: "transform 0.4s ease-in-out",
              transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            }}
          >
            <img src={BottomDropDown} alt="" />
          </div>
        </div>
      </CategoryMenu>
      {open && (
        <>
          {/* mobile dropdown */}
          {isResizingWindow <= 1024 && (
            <ClickOutside
              onClickOutside={closeDropDown}
              className="mob-anactoas-drop lg-d-block d-none bg-dropdown-for-resource box-shadow-dropdown-resource border-radius-14px absolute z-index-990 overflow-auto max-height-700px "
            >
              {renderSection("Anécdotas Inicial 1", data?.first_phase, "title")}
              {renderSection(
                "Anécdotas Inicial 2",
                data?.first_phase,
                "title2"
              )}
              {renderSection(
                "Anécdotas Inicial 3",
                data?.first_phase,
                "title3"
              )}
              {renderSection("Anécdotas 1", data?.second_phase, "title")}
              {renderSection("Anécdotas 2", data?.second_phase, "title2")}
              {renderSection("América Ed. 4", data?.second_phase, "title3")}
            </ClickOutside>
          )}

          {isResizingWindow >= 768 && (
            <ClickOutside
              onClickOutside={closeDropDown}
              // className="lg-d-none anoct-dropdown bg-dropdown-for-resource top-59px  box-shadow-dropdown-resource border-radius-14px absolute z-index-990"
              className={`lg-d-none ${
                typeOf === "story" ? "allStoryDropSection" : ""
              } anoct-dropdown bg-dropdown-for-resource top-59px box-shadow-dropdown-resource border-radius-14px absolute z-index-990`}
            >
              {" "}
              {/* laptop dropdown */}
              {createLabelDiv([
                "Anécdotas Inicial 1",
                "Anécdotas Inicial 2",
                "Anécdotas Inicial 3",
              ])}
              {data?.first_phase?.map(createThemeItem)}
              {createLabelDiv(["Anécdotas 1", "Anécdotas 2", "América Ed. 4"])}
              {data?.second_phase?.map(createThemeItem)}
            </ClickOutside>
          )}
        </>
      )}
    </>
  );
};

export default AncotasDropDown;
