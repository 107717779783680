import { API } from 'aws-amplify';
import axios from "axios";
import base64 from "base-64";
import { get, has, isEmpty } from 'lodash';
const cleverConfig = {
    tokenUrl: 'https://clever.com/oauth/tokens',
    baseUrl: 'https://api.clever.com/v3.0/',
    clientId: '681feb271465461a954c',
    clientSecrate: '16d96ef8d32d7733501a4047b1c898ba1b1fd3d3',
    redirect_uri: 'https://nosotrosonline.com'
};


export class Clever {
    clientId = cleverConfig.clientId;
    clientSecrate = cleverConfig.clientSecrate;
    accessToken = null;
    constructor( clientId, clientSecrate, accessToken ) {
        if (clientId) {
            this.clientId = clientId;
        }

        if (clientSecrate) {
            this.clientSecrate = clientSecrate;
        }
        if (accessToken) {
            this.accessToken = accessToken;
        }
    }
    getUserByCode = async (code) => {
       const cleverUser = await API.get("clever",`/usercode/${code}`)
       return cleverUser;
    }
    getDistrictToken = async () => {
        if (!this.clientId || !this.clientSecrate) return "Client id or client secrate not found."
        const config = {
            method: 'get',
            url: cleverConfig.tokenUrl,
            headers: {
                Authorization: `Basic ${base64.encode(`${this.clientId}:${this.clientSecrate}`)}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const result = await (await axios(config)).data
        const disttoken = get(result, "data.0.access_token")
        this.accessToken = disttoken;
        return disttoken ? disttoken : result
    }
    getDistricts = async () => {
        const config = {
            method: 'get',
            url: 'https://api.clever.com/v3.0/districts',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`
            }
        };
        return axios(config)
    }
    getUserById = async (id) => {
        const config = {
            method: 'get',
            url: `${cleverConfig.baseUrl}users/${id}`,
            headers: {
                'Authorization': `Bearer ${this.accessToken}`
            }
        };
        return axios(config)
    }
    getUser = async (token) => {
        this.accessToken = token;
        const config = {
            method: 'get',
            url: `${cleverConfig.baseUrl}me`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const currentUser = await this.makeRequest(config).then(dt=>dt.data);
        if (!isEmpty(currentUser) && has(currentUser, 'data')) {
            const id = get(currentUser, 'data.id');
            config.url = `${cleverConfig.baseUrl}users/${id}`;
            const userInfo = await (await axios(config)).data;
            return userInfo;
        }
        return currentUser;
    }
    makeRequest = async ({url,method,header,data}) =>{
        return API.post('schoologyRDS', `makeRequest`, {
            body: {
              headers:header,
              method:method,
              url: url,
              data:data
            },
          })
    }
}