
import React from 'react'
import { Done, DoneAll, QueryBuilder } from "@material-ui/icons"
import { isArray } from "lodash"
import COLOR from 'utils/constants/color';
const FONT_SIZE = 15
const SeenTrue = <DoneAll style={{ color: COLOR.green100, fontSize: FONT_SIZE }} />
const DeliveredWhite = <DoneAll style={{ color: COLOR.white, fontSize: FONT_SIZE }} />
const DeliveredBlack = <DoneAll style={{ color: COLOR.black100, fontSize: FONT_SIZE }} />
const SentWhite = <Done style={{ color: COLOR.white, fontSize: FONT_SIZE }} />
const SentBlack = <Done style={{ color: COLOR.black100, fontSize: FONT_SIZE }} />
const QueeWhite = <QueryBuilder style={{ color: COLOR.white, fontSize: FONT_SIZE, marginTop: -2, marginRight: 2 }} />
const QueeBlack = <QueryBuilder style={{ color: COLOR.black100, fontSize: FONT_SIZE, marginTop: -2, marginRight: 2 }} />

export const CheckMark = ({ seen, deliverAt, me, showSeenSymbol, quee, isBrodcast, broadcastReceivers, brodcastSeen, brodcastDelivered, white }) => {
    const Delivered = white ? DeliveredWhite : DeliveredBlack
    const Sent = white ? SentWhite : SentBlack;
    const Quee = white ? QueeWhite : QueeBlack;
    if (!me) return null;
    if (isBrodcast) {
        if (isArray(broadcastReceivers) && (isArray(brodcastSeen) || isArray(brodcastDelivered))) {
            if (broadcastReceivers.length == 0) {
                return Sent
            }
            else if (isArray(brodcastSeen) && brodcastSeen.length >= broadcastReceivers.length) {
                return SeenTrue
            } else if (isArray(brodcastDelivered) && brodcastDelivered.length >= broadcastReceivers.length) {
                return Delivered
            }
        }
    }
    if (seen&&showSeenSymbol) {
        return SeenTrue
    }
    if (deliverAt) {
        return Delivered
    }
    if (quee) {
        return Quee
    }
    return Sent
}