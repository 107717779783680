//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';

export const listClassBookmarksSuccess = (returnedPayload) => ({ type: ActionTypes.bookmarks.liastClassBookmarksSuccess,payload: returnedPayload });
export const updateClassBookmarks = (returnedPayload) => ({ type: ActionTypes.bookmarks.updateBookmarks, payload: returnedPayload });
export const listClassBookmarksFail = (returnedPayload) => ({ type: ActionTypes.bookmarks.liastClassBookmarksFail, payload: returnedPayload });
export const createClassBookmarksSuccess = (returnedPayload) => ({ type: ActionTypes.bookmarks.createClassBookmarksSuccess, payload: returnedPayload });
export const deleteClassBookmark = (id) => ({ type: ActionTypes.bookmarks.deleteBookmark, payload: id });



