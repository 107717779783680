//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------

import Loading from 'components/Loading';
import { get, cloneDeep } from "lodash";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//  -- Constants ----------------------
import { Col, Drawer, Row } from 'antd';

import { Button, FormLabel } from '@material-ui/core';
import PageImagePicker from "components/AdminBooks/PageImagePicker";
import VideoPicker from "components/AdminBooks/VideoPicker";
import { useWelcomeVideos } from 'store/welcomeVideo/selectors';
import { handleUpdateWelcomeVideos } from "store/welcomeVideo/thunks"

export const WelcomeVideoNew = ({ videoDrawer, setVideoDrawer }) => {
    const [videoObj, setVideoObj] = useState({})
    const [loading, setLoading] = useState(false)
    const welcomeVideo = useWelcomeVideos()
    const dispatch = useDispatch()
    useEffect(() => {
        if (welcomeVideo) {
            setVideoObj(welcomeVideo)
        }
    }, [welcomeVideo])

    const updateVideos = async (data) => {
        setLoading(true);
        await dispatch(handleUpdateWelcomeVideos(data?data:videoObj))
        setLoading(false);
        // if(!data){
        //  setVideoDrawer(false);
        // }
       
    }
    const onCangeValue = (key, value) => {
        const re = cloneDeep(videoObj)
        re[key] = value
        setVideoObj(re);
    }
    return (
  <>
        <Row style={{ height: "100%", width: "100%" }}>
            <Col lg={12} xs={24} style={{ paddingRight: 16,marginBottom:30 }}>
                <VideoPicker
                    poster={get(videoObj, "poster")}
                    src={get(videoObj, "src")}
                    onChange={(item) => { 
                        onCangeValue("src", item.url)
                        updateVideos({...videoObj,src:item.url})
                     }}
                    onChangeDescription={(txt) => { onCangeValue("displayName", txt) }}
                    description={get(videoObj, "displayName")}
                    hasDescription />
            </Col>
                <Col lg={12} xs={24} style={{ paddingRight: 16, paddingLeft: 16 }}>
                <FormLabel style={{marginBottom:30}} component="legend">Cover image:</FormLabel>
                <PageImagePicker
                    src={get(videoObj, "poster")}
                    path={`/resources`}
                    onChange={item => { 
                        onCangeValue("poster", item.url)
                        updateVideos({...videoObj,poster:item.url})
                     }}
                     style={{height:"max-content"}}
                    hasDescription={false}
                />
                <Button
                    disabled={!videoObj || !videoObj.id || loading }
                    style={{ marginTop:16,marginTop:30}}
                    fullWidth variant="contained" color="primary" onClick={()=>updateVideos()}
                >
                    {loading ? <Loading white size={15} /> : null}  Update
                </Button>
            </Col>
            </Row>
        </>
    )
}