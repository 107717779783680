//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState , useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { find, groupBy, forEach, isEmpty, filter, orderBy ,get} from "lodash";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

//  -- Components ---------------------
import { Col, Row } from "antd";
import InitialLoading from "components/InitialLoading";
import Loading from "components/Loading";
import Questions from "components/QuestionsByStudent";
import StudentQuestions from "components/StudentQuestionForTeacher";
import StudentQuestionsList from "components/StudentQuestionList";
import { handleListUserQuestions, handleUpdateQuestion } from 'store/questions/thunks';
//  -- Config -------------------------
import { NEWSLETTERS } from "utils/config/reference";

//  -- Constants ----------------------
import COLOR from "utils/constants/color";

//  -- Lib ----------------------------
import { imageFetchDinamic, newsImageFetch,imageFetchFromPodcast,getPodcastBookName } from "utils/lib/ebookHelper";

//  -- Thunks -------------------------

import { handleListClassUsers } from "store/userClasses/thunks";
import { handleListClassQuestions } from "store/questions/thunks";
import { handleListAllBooks } from "store/books/thunks";
import ReactHighcharts from "react-highcharts";
import moment from "moment";
import { SadEmojiimg, mobileMenuimg, noQuestionimg ,TrueMarkimg } from "utils/assets/images";
import { useResources, useVideos } from "store/resources/selectors";
import { useCurrentOrgPermission } from 'store/organizations/selectors';

const config = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
    marginTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    spacingTop: 0,
    height: 140,
    animation: true,
    backgroundColor: "transparent",
  },
  title: {
    text: "0",
    align: "center",
    verticalAlign: "middle",
    y: 10,
    style: {
      fontWeight: 200,
      fontSize: "24px",
      color: "#fff",
    },
  },
  tooltip: {
    enabled: true,
    formatter: function () {
      return `${this.key ?? ""}` + ":" + this.y + "%";
    },
  },
  plotOptions: {
    series: {
      animation: {
        duration: 2000,
      },
    },
    pie: {
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      point: {
        events: {
          legendItemClick: () => false,
        },
      },
      showInLegend: false,
      borderWidth: 0,
      colors: ["rgb(90,177,208)", "rgb(130,222,228)"],
    },
  },
  series: [
    {
      innerSize: "67%",
      data: [
        { name: "Overall Grade", y: 1 },
        { name: "", y: 1 },
      ],
    },
  ],
};

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  height: 100%;
  min-height: 300px;
  padding: 64px 0px;
  width: 100%;

  @media (min-width: 800px) {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0px;
  }
`;

const HeaderContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${COLOR.black700};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
`;

const Image = styled.img`
  border: 1px solid ${COLOR.black800};
  width: 100%;
  height: auto;
`;

const ImageContiner = styled.div`
  background-color: transparent;
  border: none;
  flex: 1;
  outline: none;
  width: 100%;
`;

const Title = styled.span`
  color: ${COLOR.black100};
  display: inline-block;
  font-size: 16px;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 48px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  height: ${(p) => (p.height ? p.height : "30%")};
`;
const OverallGrade = styled.div`
  width: 190px;
`;
const OverallGradeSection = styled.div``;
const OverallGradeTitleSec = styled.div`
  background-color: ${COLOR.indigo300};
  min-height: 100px;
  flex-direction: row;
  display: flex;
`;
const BookTitileSection = styled.div`
  background-color: ${COLOR.indigo300};
  min-height: 50px;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
`;
const BookTitle = styled.div`
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
`;
const QuestionDateTitle = styled.div`
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
`;
const OverallGradeTitle = styled.div`
  color: white;
  font-size: 24px;
  font-weight: 300;
  white-space: pre-line;
  margin: auto;
  margin-left: 15%;
  line-height: 24px;
  margin-top: 4%;
`;
const SearchByTitle = styled(OverallGradeTitle)`
    color: white;
    font-weight: 300;
    white-space: pre-line;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 13px;
    text-align: end;
    margin-right: 10px;
    margin-left: auto;
`;
const OverallGradeTitleWrapper = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;
const WrapperSec1 = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #abaeb3;
  border-bottom: 10px solid rgb(130, 222, 228);
  margin-bottom: 0px;
  border-bottom-width: ${(p) => (p.bottomWidth ? p.bottomWidth : "10px")};
`;
const DropdownSec = styled.div`
  background-color: ${COLOR.green100};
  min-height: 50px;
  width: 100%;
`;
const Dropdown = styled.button`
  width: 200px;
  height: 30px;
  margin-top: 10px;
  background-color: ${COLOR.white};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 3px;
  border-width: 0px;
`;
const DropdownTitle = styled.div`
  color: ${COLOR.black300};
  font-size: 12px;
  font-weight: 600;
  width: 150px;
`;

const DropdownContainer = styled.div`
  display: flex;
  position: relative;
`;

const SectionContainer = styled.div`
  min-height: 250px;
  width: 100%;
  overflow-y: auto;
`;
const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${(p) => p.paddingTop};
`;
const NoAssignmentImg = styled.img`
  width: 100%;
  object-fit: cover;
`;
const NoQuestions = styled.div`
  width: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
`;
const NoQuestionsText = styled.div`
  align-items: center;
  color: #35383d;
  font-size: 28px;
  font-weight: 800;
  white-space: pre-line;
  margin: auto;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
`;

const Input = styled.input`
  background-color: transparent;
  border: 1px dashed transparent;
  border-radius: 3px;
  box-shadow: none;
  font-size: inherit;
  font-weight: 600;
  outline: none;
  padding: 0px 2px;
  transition: all 300ms ease;
  width: 170px;
`;
const SadEmogiImg = styled.img`
  height: 240px;
  width: 300px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin: auto;
  object-fit: cover;
`;
const Mobicon = styled.img`
  height: 30px;
  width: 30px;
  align-self: center;
  object-fit: cover;
`;
const Legend =styled.div`
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
`;
const Questionicon = styled.img`
height: 30px;
width: 40px;
-webkit-align-self: center;
-ms-flex-item-align: center;
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
margin-top: -4px;
margin-right: -5px;
object-fit: fill;
`;
const QuestioniconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 13px;
    margin-left: auto;
    margin-right: 10px;
  `;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  -- Description
//  Student Question Container
//
//  -- Props
//  ...
//
const QuestionContainer = ({
  currentClass,
  currentUser,
  classQuestions,
  classUsers,
  questions,
  booksAllList,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [selected, setSelected] = useState(false);
  const [chapter, setChapter] = useState(null);
  const [page, setPage] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [questinsForClass, setQuestionsForClass] = useState([]);
  const [questionsForClassFiltered, setQuestionsForClassFiltered] = useState(
    []
  );
  const overChartRef = React.useRef(null);
  const [tempValue, setTempValue] = useState("");
  const [QuestionsForRender, setQuestionsForRender] = useState([]);
  const [filterdQuestionForStudent, setFilterdQuestionForStudent] = useState([]);
  const [questionsDate, setQuestionsDate] = useState(moment().format('MM/DD/YYYY'));
  const videos = useVideos()
  const resources = useResources()
  const isTeacher=currentUser?.role==="teacher";
  const orgPermissions = useCurrentOrgPermission()
  const bookPermissions = get(orgPermissions, "books", [])
  const classQuestionsArray = useMemo(() => {
    const archivedBooksSet = new Set();
    const archived_books = get(currentClass, "archived_books", []);

    archived_books.forEach(abk => {
        archivedBooksSet.add(abk.bookId);
    });

    return filter(classQuestions, k => {
        if (archivedBooksSet.has(get(k, "chapter"))) {
            return false;
        }

        if (bookPermissions.length === 0) {
            return true;
        }

        const bookId = get(k, "chapter");
        const bookaccess = find(bookPermissions, ["bookId", bookId]);

        return bookaccess ? bookaccess.available : true;
    });
}, [classQuestions, currentClass, bookPermissions]);

const questionsArray = useMemo(() => {
  const archivedBooksSet = new Set();
  const archived_books = get(currentClass, "archived_books", []);
  archived_books.forEach(abk => {
    archivedBooksSet.add(abk.bookId);
  });
  return filter(questions, k => {
    if (archivedBooksSet.has(get(k, "chapter"))) {
      return false;
    }

    if (bookPermissions.length === 0) {
      return true;
    }

    const bookId = get(k, "chapter");
    const bookaccess = find(bookPermissions, ["bookId", bookId]);

    return bookaccess ? bookaccess.available : true;
  });
}, [questions, currentClass, bookPermissions]);
  const hasQuestionForClass=isTeacher? classQuestionsArray?.length >0:questionsArray?.length>0

  useEffect(() => {
    setTimeout(() => setMounted(true), 250);
  }, []);
  const getNameOfBook = (c) => {
    if (!c) return "";
    let book = find(booksAllList, function (item) {
      return item.id == c;
    });
    if (book) {
      return book.description;
    } else {
      return getPodcastBookName(c);
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      let isVisited=localStorage.getItem("question_visited")==currentClass.id;
      setLoading(true);
      if(isVisited){
        setLoading(false)
      }
    if (currentUser?.role === 'student') {
        await dispatch(handleListUserQuestions(currentUser?.id))
    }else{
      await dispatch(handleListClassQuestions(currentClass.id));
      await dispatch(handleListClassUsers(currentClass.id));
    }
      setLoading(false);
      localStorage.setItem("question_visited",currentClass.id)
      dispatch(handleListAllBooks());
    }
    fetchData();
  }, [currentClass.id,currentUser]);
  useEffect(() => {
    if (classQuestionsArray?.length > 0&&currentUser?.role==='teacher') {
        const notesByStudentCount =Object.keys(groupBy(classQuestionsArray, 'userId')).length;
        const numberOfStudent=classUsers?.length??0;
        if(numberOfStudent>0){
        const overallGrade=Math.round((notesByStudentCount/numberOfStudent)*100);
        config.series[0].data = [
          { name: "Question asked", y: overallGrade },
          { name: "", y: 100 - overallGrade},
        ];
        config.title.text = `${notesByStudentCount??"0"}`;
        if (overChartRef.current) {
          overChartRef.current.chart.update(config);
        }
    }
      const questions = getQuestions();
      setQuestionsForClass(questions);
      setQuestionsForClassFiltered(questions);
      setDefaultSelectedQuestions(questions);
    }
  }, [classQuestions, classUsers]);
  useEffect(() => {
    if (questionsArray?.length > 0&&currentUser?.role==='student') {
      setFilterdQuestionForStudent(questionsArray);
      setDefaultSelectedQuestionsForStudent(questionsArray);
      config.series[0].data = [
        { name: "Question asked", y: questionsArray?.length??0 },
        { name: "", y: 100},
      ];
      config.title.text = `${questionsArray?.length??"0"}`;
      if (overChartRef.current) {
        overChartRef.current.chart.update(config);
      }
    } 
  },[questions,currentUser])

  const getNameVideo = (idVideo) => {
    const videoDisplayName = get(find(videos,x => x.id == idVideo),'displayName','');
    return 'Video '+videoDisplayName;
  };
  const getQuestions = () => {
    const quetions = groupBy(classQuestionsArray, "userId");
    let data = [];
    if (!isEmpty(quetions)) {
      forEach(quetions, (x, key) => {
        let sortedQuestions = {};
        const chapterGroups = groupBy(x, "chapter");

        forEach(chapterGroups, (y, chapter) => {
          const pageGroups = groupBy(y, "page");
          sortedQuestions[chapter] = pageGroups;
        });
        const user = find(classUsers, ["id", key]);
        if (user) {
          data.push({
            questions: sortedQuestions,
            student: user,
          });
        }
      });
    }
    return data;
  };
  const setDefaultSelectedQuestions = (allQuestions) => {
    try {
      let questionsForStudent ={}
      if(selectedStudentId){
         questionsForStudent=find(allQuestions,x=>{
          return get(x,"student.id","").toLocaleLowerCase()==selectedStudentId.toLocaleLowerCase()
         });
       }else{
          questionsForStudent=allQuestions[0];
       }
      setImageLoading(true);
      if (questionsForStudent) {
        const _chapter =chapter?chapter:Object.keys(questionsForStudent.questions ?? {})[0];
        setSelectedStudentId(get(questionsForStudent,"student.id",""));
        const  slectedQ =questionsForStudent.questions[_chapter];
        if (slectedQ) {
          const _page = page?page:Object.keys(slectedQ)[0];
          const  selectedQuestion = slectedQ[_page];
          if (selectedQuestion) {
            setChapter(_chapter);
            setPage(_page);
            setSelected(true);
           const questionArray = orderBy(
                selectedQuestion,
              (obj) => moment(new Date(obj?.questionCreated)),
              "desc"
            );
            if(!isNaN(parseInt(get(questionArray[0],"questionCreated")))){
              setQuestionsDate(moment(new Date(parseInt(get(questionArray[0],"questionCreated")))).format('MM/DD/YYYY'));
            }
           setQuestionsForRender(questionArray);
          }
        }
        setImageLoading(false);
      }
      setImageLoading(false);
    } catch (error) {
      setImageLoading(false);
      console.log(error);
    }
  };
  const selectQuestion = (chapter, _page, studentId, i, allQuestions) => {
    setSelectedStudentId(studentId);
    try {
    let questionArray =
        questionsForClassFiltered[i]?.questions[chapter][_page];
      if (
        Array.isArray(allQuestions) &&
        questionsForClassFiltered.length == 0 &&
        allQuestions.length > 0
      ) {
        questionArray = allQuestions[i]?.questions[chapter][_page];
      }
      questionArray = orderBy(
        questionArray,
        (obj) => moment(new Date(obj?.questionCreated)),
        "desc"
      );
      setChapter(chapter);
      setPage(_page);
      setSelected(true);
      if (Array.isArray(questionArray)) {
        if (questionArray[0]?.questionCreated) {
          if(!isNaN(parseInt(get(questionArray[0],"questionCreated")))){
            setQuestionsDate(moment(new Date(parseInt(get(questionArray[0],"questionCreated")))).format('MM/DD/YYYY'));
          }
        } else {
          setQuestionsDate("");
        }
        setQuestionsForRender(questionArray);
      } else {
        setQuestionsDate("");
        setQuestionsForRender([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setDefaultSelectedQuestionsForStudent = (allQuestions) => {
      let questionOne = get(allQuestions, [0], {});
      if(!isEmpty(questionOne)){
        selectQuestionForStudent(questionOne.chapter, questionOne.page, questionOne);
      }
  }
  const selectQuestionForStudent = (chapter, page, question) => {
    try {
      setImageLoading(false);
      setChapter(chapter);
      setPage(page);
      setSelected(true);
      if(question){
        setQuestionsForRender([question]);
        if(!isNaN(parseInt(get(question,"questionCreated")))){
          setQuestionsDate(moment(new Date(parseInt(get(question,"questionCreated")))).format('MM/DD/YYYY'));
        }
        if(!question.viewed){
          updateQuestionView(question)
        }
      }
    } catch (error) {
      setImageLoading(false);
      console.log(error);
    }
  };
  const getDisplayName=(chapter,page)=>{
    let NumberMasked = find(find(booksAllList,["id",chapter])?.pages,k=>k.pageId == page)?.NumberMasked
    let _page = NumberMasked?NumberMasked:page
    let chapterName=""
    if(chapter&&chapter.length<=2){
      chapterName =getNameOfBook(chapter)
    }else{
       if(NEWSLETTERS[chapter]){
          chapterName = "Newsletter" 
       }else if(find(videos,['id',chapter])){
          chapterName= getNameVideo(chapter)
       }else{
          chapterName=getPodcastBookName(chapter)?? "Podcast";
       }
    }
  let chapterSubname=""
  if(chapter.length > 5 && !NEWSLETTERS[chapter]){
    chapterSubname = ""
  }else{
     chapterSubname = ` | Pg. ${_page}`
  }
return chapterName+" "+chapterSubname
}
 
  const handleChange = (e) => {
    setTempValue(e.target.value);
    filterQuestions(e.target.value);
  };
  const filterQuestions = (text) => {
    if(isTeacher){
    if (typeof text == "string" && text.length > 0) {
      let txt=text.toLocaleLowerCase().trimLeft().trimRight()
      let fQuestions = filter(questinsForClass, (k) => {
        let name=(k.student?.firstName?.toLocaleLowerCase()??"")+" "+( k.student?.lastName?.toLocaleLowerCase()??"")
         name=name.trimLeft()
         name=name.trimRight()
        let hasName =name.includes(txt) 
        let hasInUserName = k.student?.username?.toLocaleLowerCase().includes(txt)
        return (
          hasName||hasInUserName
        );
      });
      if (fQuestions.length > 0) {
        setQuestionsForClassFiltered(fQuestions);
      } else {
        setQuestionsForClassFiltered([]);
      }
    } else {
      setQuestionsForClassFiltered(questinsForClass);
    }}else{
      if (typeof text == "string" && text.length > 0) {
        let txt=text.toLocaleLowerCase().trimLeft().trimRight()
        let fQuestions = filter(questions, (k) => {
           return getDisplayName(k.chapter,k.page).toLowerCase().includes(txt)
        });
        if (fQuestions.length > 0) {
          setFilterdQuestionForStudent(fQuestions);
        } else {
          setFilterdQuestionForStudent([]);
        }

      }else{
        setFilterdQuestionForStudent(questions);
      }
    }
  };
  const updateQuestionView =async(question)=>{
     dispatch(handleUpdateQuestion(question?.id, { ...question, viewed: true }))
  }
  const renderQuetionSection=()=>{
      if(currentUser?.role==='teacher'){
        if(questionsForClassFiltered.length > 0 ){
          return( <SectionContent
            paddingTop={"16px"}
          >
          {questionsForClassFiltered.map((x, i) => (
                    <StudentQuestions
                      key={x.id+i}
                      index={i}
                      questions={x?.questions ?? []}
                      onClick={selectQuestion}
                      student={x.student}
                      booksAllList={booksAllList}
                      getDisplayName={getDisplayName}
                    />
                  ))}
          </SectionContent>)
        }
        else{
          return(<NoAssignmentImg src={noQuestionimg} />)
        }
      }else{
      if(filterdQuestionForStudent.length > 0 ){
        return(<SectionContent
          paddingTop={"16px"}
        >
          <StudentQuestionsList 
          questions={filterdQuestionForStudent}
          onClick={selectQuestionForStudent} 
          booksAllList={booksAllList} 
          getDisplayName={getDisplayName}
          />
         </SectionContent>)
    }else{
      return(<NoAssignmentImg src={noQuestionimg} />)
    }
      }
}
  return loading ? (
    <CenterContainer>
      <InitialLoading mounted={mounted} />
    </CenterContainer>
  ) : (
    <>
      <HeaderContainer style={{ marginTop: 50 }}>
        <Mobicon src={mobileMenuimg} />
        <Title>{currentClass?.displayName} Questions</Title>
      </HeaderContainer>
      <Wrapper>
        <Row style={{ width: "100%", marginTop: 10 }}>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <WrapperSec1>
              <OverallGradeSection>
                <OverallGradeTitleSec>
                  <OverallGrade>
                    <ReactHighcharts config={config} ref={overChartRef} />
                  </OverallGrade>
                  <OverallGradeTitleWrapper>
                    <OverallGradeTitle>
                     {isTeacher?"See what question your \n students are taking":"See the questions you asked\n and the teacher answers"}
                    </OverallGradeTitle>
                  </OverallGradeTitleWrapper>
                </OverallGradeTitleSec>
                <DropdownSec>
                  <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }} >
                    <SearchByTitle>{isTeacher?"Search by Name":"Search by page or book"}</SearchByTitle>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }} >
                    <DropdownContainer>
                      <Dropdown>
                        <Input
                          id="page"
                          onChange={handleChange}
                          value={tempValue}
                          placeholder="Search"
                        />
                      </Dropdown>
                    </DropdownContainer>
                      </Col>
                     <Col xs={{ span: 24 }} lg={{ span: 8 }} >
                      <QuestioniconWrapper>
                        <Questionicon src={TrueMarkimg} />
                        <Legend>questioned answered</Legend>
                      </QuestioniconWrapper>
                    </Col>
                  </Row>
                </DropdownSec>
              </OverallGradeSection>
              <SectionContainer>
                {renderQuetionSection()}
              </SectionContainer>
            </WrapperSec1>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 14 }} style={{ paddingLeft: 10 }}>
            <WrapperSec1 bottomWidth={"6px"}>
              <BookTitileSection>
                <BookTitle>book: {getNameOfBook(chapter)}</BookTitle>
                <QuestionDateTitle>
                 Question added on: {questionsDate}
                </QuestionDateTitle>
              </BookTitileSection>
              <Row style={{height:"87%"}}>
                <Col xs={{ span: 24 }} lg={{ span:16}}>
                  {imageLoading ? (
                    <CenterContainer>
                      <Loading />
                    </CenterContainer>
                  ) : (
                    <>
                      {selected &&
                      (NEWSLETTERS[chapter] || chapter.length>0)? (
                        <ImageContiner>
                          <Image
                            src={
                              NEWSLETTERS[chapter]
                                ? newsImageFetch(chapter, page)?.src
                                :imageFetchFromPodcast(chapter,resources)?imageFetchFromPodcast(chapter,resources): imageFetchDinamic(chapter, page, booksAllList)
                                    ?.resource
                            }
                            alt=""
                          />
                        </ImageContiner>
                      ) : (
                        <NoQuestions>
                          {!hasQuestionForClass && (
                            <>
                              <NoQuestionsText style={{marginTop:80}}>
                                {isTeacher?
                                  "No Worries, You Will\n See all the questions  \nyour  students ask you \n in this page.":
                                  "No Questions Yet"
                                }
                              </NoQuestionsText>
                              <NoQuestionsText>
                              <SadEmogiImg src={SadEmojiimg} />
                                </NoQuestionsText>
                            </>
                          )}
                        </NoQuestions>
                      )}{" "}
                    </>
                  )}
                </Col>
             <Col
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                {hasQuestionForClass?
                  selected&&(NEWSLETTERS[chapter] || chapter.length>0) &&
                (QuestionsForRender.length>0&&QuestionsForRender.map((x, i) => (<Questions key={i} question={x} view={'question'} booksAllList={booksAllList} hasAnswerView={currentUser?.role=="teacher"} />))):
                <Questions hasNoQuestion  />}
                </Col>
              </Row>
            </WrapperSec1>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

//  -- Prop types ---------------------
QuestionContainer.propTypes = {};

//  -- Default props ------------------
QuestionContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentClass: state.currentClass,
  currentUser: state.currentUser,
  classQuestions: state.classQuestions,
  classUsers: state.classUsers,
  booksAllList: state.booksAllList,
  questions: state.questions,
});

export default connect(mapStateToProps, null)(QuestionContainer);
