//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create interaction -------------
export const createOnewayTeacherVideo = (returnedPayload) => ({ type: ActionTypes.oneway_teacher.create_oneway_teacher_video, payload: returnedPayload });

export const updateOnewayTeacherVideo = (returnedPayload) => ({ type: ActionTypes.oneway_teacher.update_oneway_teacher_video, payload: returnedPayload });

export const deleteOnewayTeacherVideo = (returnedPayload) => ({ type: ActionTypes.oneway_teacher.delete_oneway_teacher_video, payload: returnedPayload });

export const listOnewayTeacherVideo = (returnedPayload) => ({ type: ActionTypes.oneway_teacher.list_oneway_teacher_video, payload: returnedPayload });

