//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { BellOutlined, AreaChartOutlined, BookOutlined, CaretDownOutlined, FileSearchOutlined, HeatMapOutlined, LinkOutlined, MenuFoldOutlined, MenuOutlined, MenuUnfoldOutlined, MergeCellsOutlined, MessageOutlined, SettingOutlined, CloseOutlined } from '@ant-design/icons';
import { Home } from '@material-ui/icons';
//  -- Components ---------------------
import { Button, Drawer, Dropdown, Layout, Menu, Space  , Select } from 'antd';
import LogoIcon from 'components/LogoIcon';

//  -- Features -----------------------
import { get , map , orderBy  , find , sortBy , filter } from 'lodash';
import React, { useState , useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//  -- Thunks -------------------------
import { handleLogout } from 'store/auth/thunks';
import { useCurrentUser, useProfilePicturePicker, useUserProfileConfig } from "store/users/selectors";
import styled from 'styled-components';
//  -- Constants ----------------------
import { handleInvokeFeedbackForm } from 'store/feedback/thunks';
import { useCurrentOrg } from "store/organizations/selectors";
import { profileArrow, userImg } from "utils/assets/images";
import COLOR from 'utils/constants/color';
import { MenuItem } from "@material-ui/core"
import { handleGetOrganization } from 'store/organizations/thunks';
import { getAvatar } from 'utils/assets/images/lootie';
const { Header, Sider, Content } = Layout;


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const itemss = [
    getItem('Home', 'Home', <Home style={{ fontSize: '20px' }} />),
    getItem('Data Browser', 'dataBrowser', <FileSearchOutlined style={{ fontSize: '20px' }} />),
    getItem('Standard', 'standard', <FileSearchOutlined style={{ fontSize: '20px' }} />),
    getItem('Integrations', 'ebook', <MergeCellsOutlined style={{ fontSize: '20px' }} />, [
        getItem('Schoology', 'Schoology', <AreaChartOutlined style={{ fontSize: '20px' }} />),
        getItem('Classlink', 'Classlink', <LinkOutlined style={{ fontSize: '20px' }} />),
        getItem('Clever', 'Clever', <HeatMapOutlined style={{ fontSize: '20px' }} />),
        getItem('Canvas', 'Canvas', <HeatMapOutlined style={{ fontSize: '20px' }} />),
        getItem('Google', 'Google', <HeatMapOutlined style={{ fontSize: '20px' }} />)
    ]
    ),
    getItem('Edit Books', 'editBooks', <BookOutlined style={{ fontSize: '20px' }} />),
    getItem('1Game', 'oneGame', <MergeCellsOutlined style={{ fontSize: '20px' }} />, [
        getItem('Tutorials', 'Tutorials', <AreaChartOutlined style={{ fontSize: '20px' }} />),
        getItem('Update', 'Update', <AreaChartOutlined style={{ fontSize: '20px' }} />)
    ]
    ),
    getItem('Settings', 'settings', <SettingOutlined style={{ fontSize: '20px' }} />),
];

const SideNavSection = styled.div`
`;
const LogoContainer = styled.div`
  margin-top:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`;

const OverviewText = styled.span`
  color: white;
  font-size: 19px;
  font-weight: 600;
  display:flex !important;
  align-items:center;
`;
const RowContainer = styled.div`
    border-left: ${p => p.border && `1px solid ${COLOR.black700}`};
    margin-left: 10px;
    padding-left: 20px;
`;

const UserContainer = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
`;
const UserDetails = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (min-width: 768px) {
        display: flex;
    }
`;
const UserDetailsMob = styled.div`
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 8px;

    @media (max-width: 768px) {
        display: flex;
    }
`;

const MobileBar = styled.div`
    /* border-bottom: 1px solid ${COLOR.black800}; */
    display: block;
    height: 60px;
    padding: 8px 16px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    @media (min-width: 992px) {
        display: none;
    }
    @media (min-width: 772px) {
       background-color:black;
    }
    @media (max-width: 568px) {
        padding: 8px 10px;
    }
`;

const UserName = styled.span`
    color: white;
    display: block;
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 0px;
`;
const Wrappers = styled.div`
  height: 100%;
  width: 100%;
`;

export default function District_admin({ children , districts }) {
    const [placement, setPlacement] = useState('left');
    const [opens, setOpens] = useState(false);
    const currentUser = useCurrentUser()
    const currentOrg  = useCurrentOrg()
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [editName, setEditName] = useState(false)
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const { profile_config } = useUserProfileConfig()
    const { showProfilePhotoViewer } = useProfilePicturePicker()
    useEffect(() => {
        setFilterdDistrict(districts)
    }, [districts])

    const setFilterdDistrict = (dist) => {
        if (dist) {
            const cOrg = find(dist, d => d.id == get(currentOrg, "id"))
            if (cOrg) {
                const sortedDist = sortBy(filter(dist, d => d.id != get(currentOrg, "id")), ["displayName"])
                const res = [cOrg, ...sortedDist]
                setFilteredDistricts(res)
            } else {
                setFilteredDistricts(sortBy(dist, ["displayName"]))
            }

        } else {
            setFilteredDistricts([])
        }
    }
    const onClose = () => {
        setOpens(false);
    };
    const showDrawer = () => {
        setOpens(true);
    };
    const getRoute = () => {
        history.push('/dashboard');
    };
    const onLogOutPress = () => {
        dispatch(handleLogout());
        history.push('/');
    }
    const navigate = (path) => {
        history.push(path);
        setOpens(false)
    };

    const handleClick = ({ key }) => {
        const routes = {
            "Home": "/dashboard",
            "dataBrowser": "/data-browsers",
            logs: "/logs",
            "Schoology": "/schoology",
            "Classlink": "/classlink",
            "Clever": "/clever",
            "Canvas": "/canvas",
            "Google": "/google",
            "editBooks": "/editBooks",
            "settings": "/settings",
            "standard": "/standard",
            "Tutorials": "/edit-1game-tutorial",
            "Update": "/edit-1game-update"
        };
        const path = routes[key];
        if (path) navigate(path);
    };
    const invokeFeedback = () => {
        dispatch(handleInvokeFeedbackForm())
    }
    const handleCurrentOrgLoader = async (data) => {
        if (currentOrg?.id !== data) {
            localStorage.setItem("lastActiveOrgAdmin", data)
            await dispatch(handleGetOrganization(data))
        }
    }
    const userNav = () => (
        <Menu>
            <Menu.Item style={{ padding: '0px' }}>
                <Button onClick={showProfilePhotoViewer}>
                    Change Avatar
                </Button>
            </Menu.Item>
            <Menu.Item key="reset-password">
                <Button type="text" onClick={() => navigate('/account')}>Reset Password</Button>
            </Menu.Item>
            <Menu.Item key="report-issue">
                <Button type="text" onClick={invokeFeedback}>Report an issue</Button>
            </Menu.Item>
            <Menu.Item key="logout">
                <Button type="text" onClick={onLogOutPress}>Logout</Button>
            </Menu.Item>
        </Menu>
    );
    const getUserProfileImg = useMemo(() => {
        if (profile_config && profile_config.config) {
            const avt = getAvatar(profile_config.config.profile_pic)
            if (avt) {
                return avt
            }
        }
        return userImg;
    }, [profile_config])
    return (
        <Wrappers>
        <Layout>
            <Drawer
                title={get(currentOrg, 'displayName', '')}
                placement={placement}
                closable={false}
                onClose={onClose}
                open={opens}
                key={placement}
                width="378"
                className="ownDrawer"
                extra={
                    <Space>

                        <CloseOutlined onClick={onClose} style={{ color: 'white', fontSize: 20 }} />
                    </Space>
                }
            >
                <SideNavSection>
                    <LogoContainer onClick={getRoute}>
                        {!collapsed ? <LogoIcon height={100} /> : <LogoIcon height={60} />}
                    </LogoContainer>
                        <Menu
                            className="bgImages"
                            style={{ marginTop: '20px' }}
                            onClick={handleClick}
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={['Home']}
                            items={itemss}
                        />
                </SideNavSection>
            </Drawer>
            <Sider trigger={null} collapsible collapsed={collapsed} className="hides">
                <LogoContainer onClick={getRoute}>
                    {!collapsed ? <LogoIcon height={100} /> : <LogoIcon height={60} />}
                </LogoContainer>
                <Menu
                    className="bgImages"
                    style={{ marginTop: '20px' }}
                    onClick={handleClick}
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['Home']}
                    items={itemss}
                />
            </Sider>
            <Layout>
                <Header
                    className="cstmHeaderOld setBGSection"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }} className="headDropdown">
                        {!editName ? (
                            <div style={{ alignItems: 'center' }} className="manageBtn" onClick={() => setEditName(!editName)}>
                                <Button
                                    type="text"
                                    className="hides"
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                        fontSize: '19px',
                                        width: 50,
                                        height: 64,
                                        color: 'white'
                                    }}
                                >
                                    {collapsed ? <MenuUnfoldOutlined style={{ marginRight: '5px', marginTop: 5 }} /> : <MenuFoldOutlined style={{ marginRight: '5px', marginTop: 5 }} />}
                                </Button>
                                <OverviewText>
                                    {get(currentOrg, 'displayName', '')}
                                </OverviewText>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    type="text"
                                    className="hides"
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                        fontSize: '19px',
                                        width: 50,
                                        height: 64,
                                        color: 'white'
                                    }}
                                >
                                    {collapsed ? <MenuUnfoldOutlined style={{ marginRight: '5px', marginTop: 5 }} /> : <MenuFoldOutlined style={{ marginRight: '5px', marginTop: 5 }} />}
                                </Button>
                                <Select
                                    onBlur={() => setEditName(false)}
                                    defaultValue={get(currentOrg, 'displayName', '')}
                                    showSearch
                                    style={{
                                        width: 200,
                                    }}
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    bordered={false}
                                    className='mainLabel hides'
                                    onChange={(e) => {
                                        handleCurrentOrgLoader(e)
                                        setEditName(false)
                                    }}
                                >
                                    {map(orderBy(filteredDistricts, "asc"), st => (<MenuItem key={st?.id} value={st.id} label={st.displayName}
                                    >{st.displayName}</MenuItem>))}
                                </Select>
                            </div>

                        )}
                        <Button
                            type="text"
                            className="unhides"
                            onClick={() => showDrawer()}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                                color: 'white'
                            }}
                        >
                            <OverviewText>{collapsed ? <MenuUnfoldOutlined style={{ marginRight: '5px' }} /> : <MenuFoldOutlined style={{ marginRight: '5px' }} />} {get(currentOrg, 'displayName', '')}</OverviewText>
                        </Button>
                      

                    </div>

                    <RowContainer className="usrDetailUpr" border={currentUser?.role === 'teacher' || currentUser?.role === 'admin'}>
                        <Dropdown className="usrDetailInr" overlay={userNav()} placement="bottomRight" trigger={['click']}>
                            <UserContainer>
                                <UserDetails className="usrDetail">
                                    <div className="usrDetailInfo" style={{ borderTop: 'none', borderBottom: 'none' }}>
                                        <div className="usrPic"><img src={getUserProfileImg} /></div>
                                        <div><UserName>{currentUser?.firstName} {currentUser?.lastName}</UserName></div>
                                        <div className="usrArrow"><img src={profileArrow} /></div>
                                    </div>
                                    {/* <UserDistrict>{currentOrg?.displayName}</UserDistrict> */}
                                </UserDetails>
                                <UserDetailsMob className="usrDetail">
                                    <div className="usrPic"><img src={getUserProfileImg} /></div>
                                </UserDetailsMob>
                            </UserContainer>
                        </Dropdown>
                    </RowContainer>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: '86vh',
                        background: 'white',
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    </Wrappers>
    )
}
