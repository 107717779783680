//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as LogsActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const handleCreateLog = (data) => async(dispatch) => {
	// logs disable for temprory
	return;
	await dispatch(LogsActions.createLog());

	try {
		const logData = await API.post('logsRDS', `/logs`, {
			body: { ...data }
		});

		await dispatch(LogsActions.createLogSuccess(logData));
	} catch (error) {
		if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
		await dispatch(LogsActions.createLogFail(error));
	}
};

//  -- List logs ----------------
export const handleListLogs = (startDate = undefined, endDate = undefined) => async(dispatch) => {
	await dispatch(LogsActions.listLogs());

	try {
		let logs = [];
		var url = `/logs`;

		if (startDate && endDate) {
			url += `?startDate=${startDate}&endDate=${endDate}`;
		}

		const logsRDS = await API.get('logsRDS', url);

		if (Array.isArray(logsRDS) && logsRDS.length > 0) {
			logs = logsRDS
		}

		await dispatch(LogsActions.listLogsSuccess(logs));

		return logs;
	} catch (error) {
		if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
		await dispatch(LogsActions.listLogsFail(error));
	}
};
