//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

//  -- Components ---------------------
import InitialLoading from 'components/InitialLoading';

//  -- Features -----------------------
import NewsletterLayout from 'features/NewsletterLayout';

//  -- Lib ----------------------------
import { newsletterConfig } from 'utils/lib/newsletterHelper';

//  -- Thunks -------------------------
import { handleListUserLikes } from 'store/likes/thunks';
import { handleListUserNotes } from 'store/notes/thunks';
import { handleListClassQuestions, handleListUserQuestions } from 'store/questions/thunks';
import { useAlertView } from "store/alert/selectors";
import { useCurrentOrgPermission } from 'store/organizations/selectors';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 90vh;
    justify-content: center;
    width: 100%;
`;




//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const NewsletterContainer = ({
    currentClass,
    currentUser,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const search = queryString.parse(history.location.search);
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(false);
    const [alertView,invokeAlert,revokeALert ] =useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    useEffect(()=>{
        if(orgPermissions && typeof orgPermissions['1WAYTEACHER']==="boolean"){
          if(!orgPermissions['1WAYTEACHER']){
            invokeAlert()
            setTimeout(()=>{
              history.goBack();
              revokeALert()
            },4000)
          }
        }
      },[orgPermissions])
    useEffect(() => {
        setTimeout(() => setMounted(true), 250)
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);

    const data = newsletterConfig(search.id);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let newsLatterCurrentClass=localStorage.getItem("newsLatterCurrentClass")
            if((newsLatterCurrentClass&&newsLatterCurrentClass===currentClass?.id)||(!currentClass?.id&&newsLatterCurrentClass==="noclasssid")){
                setLoading(false);
            }
            if(currentUser?.id){
            await dispatch(handleListUserLikes(currentUser?.id))
            await dispatch(handleListUserNotes(currentUser?.id))
            }
            
            if (currentUser?.role === 'teacher') {
                await dispatch(handleListClassQuestions(currentClass.id))
            }
            localStorage.setItem("newsLatterCurrentClass",currentClass?.id??"noclasssid")
            setLoading(false);
            dispatch(handleListUserQuestions(currentUser?.id))
        }
        fetchData();
    }, [currentUser?.id, currentClass?.id, dispatch])

    return loading ? (
        <CenterContainer>
            <InitialLoading mounted={mounted} />
        </CenterContainer>
    ) : (
        <NewsletterLayout data={data} id={search.id} page={parseInt(search.page, 10)} />
    );
};

//  -- Prop types ---------------------
NewsletterContainer.propTypes = {};

//  -- Default props ------------------
NewsletterContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentUser: state.currentUser,
});

export default connect(mapStateToProps, null)(NewsletterContainer);
