//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//  -- Components ---------------------
import { filter, find } from "lodash";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';



const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: 120
    },
    formControlStudent: {
        backgroundColor: "#fff"
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultiMenu = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    variant: "menu",
    getContentAnchorEl: () => null,
};


function MultiStudentSelecter({ options, onSelect, defaultValue  , wrapperStyle={} , label}) {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [initialLoaded, setInitialLoaded] = useState(false)
    const isAllSelected =
        options.length > 0 && selected.length === options.length;
    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options);
            onSelect(selected.length === options.length ? [] : options)
            return;
        }
        onSelect(value)
        setSelected(value);
    };
    useEffect(() => {
        if (defaultValue && selected.length == 0 && !initialLoaded) {
            if (defaultValue.length > 0) {
                setSelected(filter(options, k => defaultValue.includes(k.id)))
            } else {
                setSelected(options);
            }
            setInitialLoaded(true)
        }
    }, [defaultValue, options])
    return (
        <FormControl variant="outlined" className={classes.formControlStudent} style={wrapperStyle}>
            <InputLabel id="mutiple-select-label" style={{ backgroundColor: "#fff" }}>{label?label:"Select students"}</InputLabel>
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {`${isAllSelected || selected.length == 0 ? "All students" : "Students(" + selected.length + ")"}`}
                    </div>
                )}
                MenuProps={MultiMenu}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                    />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.id} value={option}>
                        <ListItemIcon>
                            <Checkbox checked={Boolean(find(selected, k => k.id === option.id))} />
                        </ListItemIcon>
                        <ListItemText primary={`${option.lastName || ''} ,${option.firstName || ''}`} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultiStudentSelecter;