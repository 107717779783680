//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import { Button as ButtonSection } from 'antd';
import Loading from 'components/Loading';
import { get } from 'lodash';
import { createKeystore } from 'store/keystore/thunks';
import styled from "styled-components"
import { v4 } from 'uuid';


const ModalContent = styled.div`
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

export default function Login_as({ user }) {
    const [loginLoader, setLoginAs] = useState(false);
    const [loginAsModal, setLoginAsModal] = useState(false);
    const openLoginInNewWindow = async () => {
        const userId = user.id;
        setLoginAs(userId);
        const key = v4()
        await createKeystore(userId, key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoginAs(null);
        setLoginAsModal(false)
    }
    return (
        <>
            <Modal
                open={loginAsModal}
                onClose={() => setLoginAsModal(false)}
            >
                <ModalContent>
                    You will  {`Log in as ${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`} , any changes you make will be APPLIED to this account.
                    <ModalRow>
                        <Button
                            color="secondary"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setLoginAsModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={openLoginInNewWindow}
                        >
                            {loginLoader ? <Loading size={20} white /> : `Log in as ${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <ButtonSection type="primary" style={{ marginLeft: 10 }} disabled={!user?.active || loginLoader} onClick={() => { setLoginAsModal(true) }} >
                {`Log in as ${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`}
            </ButtonSection>
        </>
    )
}
