//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

//  -- Components ---------------------
import { Col, Row } from 'antd';
import { Button } from '@material-ui/core';
import MessageRenderer from 'components/MessageRenderer';
import TextEditor from 'components/TextEditor';
import Loading from 'components/Loading';
//  -- Config -------------------------
import { NEWSLETTERS } from 'utils/config/reference';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Lib ----------------------------
import { imageFetch, newsImageFetch } from 'utils/lib/ebookHelper';

//  -- Thunks -------------------------
import { handleUpdateQuestion } from 'store/questions/thunks';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Image = styled.img`
    border: 1px solid ${COLOR.black800};
    width: 100%;
    height: auto;
`;

const ImageContiner = styled.div`
    background-color: transparent;
    border: none;
    flex: 1;
    outline: none;
    width: 100%;
    
    @media (min-width: 800px) {
        max-width: 480px;
    }
`;

const QuestionContainer = styled.div`
    margin-top: 80px;
    padding-bottom: 64px;
    width: 100%;

    @media (min-width: 800px) {
        margin-top: 0px;
        padding-bottom: 0px;
    }
`;

const Text = styled.span`
    color: ${COLOR.green100};
    font-size: 14px;
    font-weight: 600;
`;

const Wrapper = styled.form`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top:10px;
    @media (min-width: 800px) {
        flex-direction: row;
        padding: 0px;
    }
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Question form
//
//  -- Description
//  Create question
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <AnswerQuestionForm
//      handleClose={func}
//      handleSubmit={func}
//  />
//
const AnswerQuestionForm = ({
    data,
    handleClose,
}) => {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(false);
    const [loading,setLoading] = useState(false);
    const initialState = [{
        type: 'paragraph ',
        children: [
            {
                text: ''
            }
        ]
    }];
    const [answerData, setAnswerData] = useState("");
    
    const onClose = (e) => {
        e.preventDefault();
        setRefresh(true);
        setAnswerData("");
        setTimeout(() => setRefresh(false), 500);
        handleClose();
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await dispatch(handleUpdateQuestion(data.id, {
            answer: answerData,
            answerCreated: Date.now()
        }));
        setLoading(false);
        onClose(e);
    };
    React.useEffect(() => {
        if (data.answer) {
            setAnswerData(data.answer);
        }
    }, [data]);
    return (
        <Wrapper onSubmit={onSubmit}>
            <QuestionContainer>
             {data?.user? <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Text>Question from {data?.user?.firstName} {data?.user?.lastName}</Text>
                    </Col>
                </Row>:null}
                <Row gutter={[0, 24]}>
                    <Col span={24} style={{paddingLeft:10}}>
                        <MessageRenderer data={data?.question} />
                    </Col>
                </Row>
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        {!refresh && (
                            <TextEditor
                                handleChange={setAnswerData}
                                value={answerData}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            disabled={loading}
                            style={{ width: '100%' }}
                        >
                          {loading ? <Loading size={20} white /> : 'Submit'}  
                        </Button>
                        <Button
                            color="secondary"
                            onClick={onClose}
                            type='submit'
                            style={{ marginTop: '8px', width: '100%' }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </QuestionContainer>
        </Wrapper>
    );
};


//  -- Prop types ---------------------
AnswerQuestionForm.propTypes = {};

//  -- Default props ------------------
AnswerQuestionForm.defaultProps = {};

export default AnswerQuestionForm;