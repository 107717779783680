import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import Loading from 'components/Loading';
import jsPDF from 'jspdf';
import { Menu , Dropdown } from "antd"
export default function LessionPlanPrinter({images,onPrintSingle , printLoader}) {
   const [loader,setLoader]  = useState(false)
    const generatePDF = async () => {
        try {
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imageUrls = images;
 
    
          const loadImage = async (url) => {
            try {
              const response = await fetch(url);
              const blob = await response.blob();
              return URL.createObjectURL(blob);
            } catch (error) {
              console.error(`Error loading image from URL ${url}:`, error);
              return null;
            }
          };
    
          const addImagesToPDF = async () => {
            let num=0;
            for (const imageUrl of imageUrls) {
              const imageSrc = await loadImage(imageUrl);
              if (imageSrc) {
                if (pdf.internal.getNumberOfPages() > 0) {
                  pdf.addPage(); // Add a new page for each image (except the first one)
                }
                pdf.addImage(imageSrc, 'JPEG', 10, 10, 190, 0); // Adjust the positioning and dimensions as needed
              }
            }
            // pdf.save('generated.pdf');
            pdf.deletePage(1)
            printPDF(pdf.output('blob'))
           
          };
         setLoader(true);
         await addImagesToPDF();
         setLoader(false);
        } catch (error) {
          console.error(error);
        }
      };
    
    const printPDF = (pdfBlob) => {
        if (pdfBlob) {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = pdfUrl;

            // Append the iframe to the body and print
            document.body.appendChild(iframe);

            // Wait for the iframe to load and then print
            iframe.onload = () => {
                iframe.contentWindow.print();

                // Remove the iframe after printing
                setTimeout(() => {
                    document.body.removeChild(iframe);
                    URL.revokeObjectURL(pdfUrl);
                }, 100000);
            };
        }
    };
    const userNav = () => {
        return (
            <Menu className='print-lession-plan-menu' >
                <Menu.Item onClick={onPrintSingle}>
                     Print this page
                </Menu.Item>
                <Menu.Item onClick={generatePDF}>
                     Print entire lesson plan
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <Dropdown disabled={loader||printLoader} className='print-lession-plan' overlay={userNav()}  placement="top" >
            <IconButton aria-label="print" onClick={e => e.preventDefault()}>
                {loader || printLoader ? <Loading size={20} /> : <PrintIcon />}
            </IconButton>
        </Dropdown>
      
    )
}

