import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Modal as MuiModal,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Chat , Close } from "@material-ui/icons"
import { message, Modal, Table, Tooltip ,Input , Button as AntdButton , Row } from 'antd'
import { find } from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAllBook } from 'store/books/selectors'
import { useCurrentClass } from 'store/classes/selectors'
import { useClassStudentLogs, useIsUriMovable, useMessageAlertView, useMessageBroadcaster, useUrlBroadcaster } from 'store/live_logs/selectors'
import { useResources, useVideos } from 'store/resources/selectors'
import { useClassUsers } from 'store/userClasses/selectors'
import styled from "styled-components"
import { default as color, default as COLOR } from 'utils/constants/color'
import { getBookDisplayName } from 'utils/lib/ebookHelper'
import Loading from './Loading'
import { SendOutlined } from '@ant-design/icons';
import Acent from './Acent'
import { message_alert_bg1, message_alert_bg2, message_alert_bg3 } from 'utils/assets/images'
import { useCurrentUser } from 'store/users/selectors'
import DemoPreview from './ViewDemoModal'
import { activity_icon } from 'utils/assets/images/icons'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const MultiMenu = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    variant: "menu",
    getContentAnchorEl: () => null,
};

const TableContainer = styled.div`
  margin-bottom: 20px;
`;
const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: rgb(239,246,254); 
  }
  .ant-table-tbody > tr > td {
    background-color: #fff; 
  }
`;
const CustomButton = styled(Button)`
    height: 30px;
    border-radius: 50px !important;
    margin-right: 10px !important;
`
const CustomBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    text-align: left;
    margin-left: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background:#fff;
  border-radius: 20px;
  position: relative;
  border: 1px solid;
  margin-top: 12px;
`;

const CharacterCount = styled.span`
  position: absolute;
  right: 50px; 
  font-size: 12px;
  color: #a0a0a0;
`;

const StyledInput = styled(Input)`
  border: none;
  outline: none;
  background: transparent !important;
  &:focus {
    box-shadow: none;
  }
  flex-grow: 1;
`;

const StyledButton = styled(AntdButton)`
  background: transparent !important;
  border: none;
  margin-left: 5px;
  &:hover, &:focus {
    background: transparent !important;
    border: none;
  }
`;

const MoveButton = styled(Button)`
    &.Mui-disabled {
        cursor: not-allowed !important;
        background-color: #e9e9ff !important;
        pointer-events: all !important;
    }
`;

const IconWrapper = styled.div`
  max-height: 58px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: transparent;
  z-index: 11;
  -webkit-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  cursor: pointer;
  margin-bottom: 5px;
}
`;
const fail_pass = {
    fail: "🔴",
    pass: "🟢",
    deactive:'⚪️',
    inactive:'🟡'
}
const sortAndFormatUserData = (user_list_data) => {
    user_list_data.sort((a, b) => {
        // Sort by login_status first, placing false at the bottom
        if (a.login_status && !b.login_status) return -1;
        if (!a.login_status && b.login_status) return 1;

        // Sort by active status
        if (a.active && !b.active) return -1;
        if (!a.active && b.active) return 1;

        // Sort by page similarity
        if (a.has_same_page && !b.has_same_page) return -1;
        if (!a.has_same_page && b.has_same_page) return 1;

        // Sort by date, newer dates first
        const dateA = moment(a.last_modified);
        const dateB = moment(b.last_modified);
        return dateB.diff(dateA); 
    });
    return user_list_data;
};




export default function ActiveStudentStatus({ style = {}, wrapperStyle = {}, activeStatus, setActiveStatus }) {
    const class_user_logs = useClassStudentLogs()
    const class_user = useClassUsers()
    const [user_list, setUserList] = useState([])
    const currentClass = useCurrentClass()
    const history = useHistory()
    const [ideaModal, setIdeaModal] = useState(false);
    function isSamePage(logs) {
        const { lastVisitedPage, query } = logs;
        const searchParams = queryString.parse(history.location.search);
        const currentPath = history.location.pathname
        if (lastVisitedPage == currentPath) {
            if (currentPath === '/stories') {
                return searchParams.id === query.id && searchParams.page === query.page && searchParams.level == query.level
            }
            else if (currentPath === '/apBook') {
                return searchParams.chapter === query.chapter && searchParams.id === query.id && searchParams.page == query.page
            }
            else if (currentPath === '/ebook') {
                return searchParams.chapter === query.chapter && searchParams.page == query.page
            }
            if (currentPath === '/informateBook' || currentPath === '/balconCulturalBook' || currentPath === '/ConectandomeBook' || currentPath === '/ComunidadBook') {
                return searchParams.id === query.id && searchParams.page === query.page
            }
            if (currentPath === '/video') {
                return searchParams.id === query.id
            }
            return true;
        }else if(lastVisitedPage === '/student/class' && currentPath ==='/admin/class'){
            return true
        }
        return false;
    }
    useEffect(() => {
        if (!ideaModal) return;
        if (currentClass) {
            let user_list_data = [];
            class_user.forEach((user) => {
                if (user.role === 'student') {
                    const logs = class_user_logs.find(log => log.userId === user.id && log.classId === currentClass.id);
                    if (logs) {
                        const mintDif = moment().diff(logs.timestamp.toDate(), "minutes");
                        user_list_data.push({
                            user_id: user.id,
                            name: `${user.lastName || ''}, ${user.firstName || ''}`,
                            has_same_path: logs.lastVisitedPage == history.location.pathname,
                            has_same_page: isSamePage(logs),
                            last_modified: moment(logs.timestamp.toDate()).toISOString(),
                            login_status: true, 
                            ...logs,
                            active: logs.active && logs.idle_time >= 0 ? mintDif - logs.idle_time < 30 : false
                        });
                    } else {
                        user_list_data.push({
                            user_id: user.id,
                            name: `${user.lastName || ''}, ${user.firstName || ''}`,
                            has_same_path: false,
                            has_same_page: false,
                            last_modified: null,
                            login_status: false,
                            active: false
                        });
                    }
                }
            });
            setUserList(sortAndFormatUserData(user_list_data));
        } else {
            setUserList([]);
        }
    }, [class_user_logs, class_user, currentClass, history.location, ideaModal]);
    
    const hideIdeaModal = () => {
        setIdeaModal(false)
        setActiveStatus(false)
        const mref = document.getElementsByClassName("msgSystemWrap")[0]
        if(mref){
            mref.classList.remove("msgSystemWrapIndex")
        }
    }
    const viewIdeaModal = () => {
        setIdeaModal(true)
        const mref = document.getElementsByClassName("msgSystemWrap")[0]
        if(mref){
            mref.classList.add("msgSystemWrapIndex")
        }
    }

    return (
        <div style={{ display:"flex",...wrapperStyle}}>
            <Modal
                visible={ideaModal || activeStatus}
                onCancel={hideIdeaModal}
                className='activeStudentModal'
            >
                <IdeaContent user_list={user_list}/>
            </Modal>
            <IconWrapper
                onClick={viewIdeaModal}
                className="debateLogoBtnPart"
            >
                <img style={{ height: 28 }} id='debade_icon' src={activity_icon} alt="Student activity" title='Student activity' />
            </IconWrapper>
            {/* <CustomButton style={style}
                onClick={viewIdeaModal}
                className='activeStudentLogBtn vocDeskTab'>

            </CustomButton> */}
        </div>
    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: 120
    },
    formControlStudent: {
        backgroundColor: "#fff",
        maxWidth:"250px"
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));

function MultiSelecter({ options, onSelect , selectedUser }) {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    const isAllSelected = useMemo(() => (options.length > 0 && selected.length === options.length), [options, selected]);
    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options.map(k=>k.user_id));
            onSelect(selected.length === options.length ? [] : options.map(k=>k.user_id))
            return;
        }
        onSelect(value)
        setSelected(value);
    };
    useEffect(() => {
        setSelected(selectedUser)
    }, [selectedUser])
    function getStatus(item) {
        if (!item.active) {
            if (!item.active && !item.login_status) {
                return fail_pass.deactive
            }
            return fail_pass.deactive
        } else if (item.has_same_page && item.idle_time > 10) {
            return fail_pass.inactive
        } else if (item.has_same_page) {
            return fail_pass.pass
        }
        else {
            return fail_pass.fail
        }
    }
    return (
        <FormControl variant="outlined" className={classes.formControlStudent}>
            <InputLabel id="mutiple-select-label" style={{ backgroundColor: "#fff" }}>Select students</InputLabel>
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {`${isAllSelected || selected.length == 0 ? "All students" : "Students(" + selected.length + ")"}`}
                    </div>
                )}
                MenuProps={MultiMenu}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                    />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.user_id} value={option.user_id}>
                        <ListItemIcon>
                            <Checkbox checked={Boolean(find(selected, k => k === option.user_id))} />
                        </ListItemIcon>
                        <ListItemText primary={`${option.name} ${getStatus(option)}`} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

function MessageSender({ onSend }) {
    const inputRef = useRef(null);
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        setMessage(e.target.value.substring(0, 60)); 
    };
    const handleInputAcentChange = (e) => {
        if (e) {
            setMessage(e.substring(0, 60));
        }
    };

    const handleSend = () => {
        if (message.trim()) {
            onSend(message);
            setMessage('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };
    return (
        <div style={{position: "relative",maxWidth: "650px" }}>
            <Acent
                inputRef={inputRef.current?.input} setValue={handleInputAcentChange}
                style={{
                    position: "absolute",
                    right: 20,
                    top: -30
                }} />
            <InputContainer>
                <StyledInput
                    ref={inputRef}
                    value={message}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Send a message to students"
                    maxLength={60}
                    autoFocus
                />
                <CharacterCount>{`${message.length}/60`}</CharacterCount>
                <StyledButton
                    icon={<SendOutlined />}
                    onClick={handleSend}
                    disabled={!message.trim()} // Disable button if message is empty
                    type="text"
                />
            </InputContainer>
        </div>
    );

}


const IdeaContent = ({ user_list }) => {
    const [loader,setLoader] = useState(false);
    const [broadcastloader,seBroadcasttLoader] = useState(false);
    const [selectedUsers,setSelectedUsers] = useState([]);
    const [messageSenderEnable,setMessageSenderEnable] = useState(false);
    const bookAllList = useAllBook()
    const urlBroadcaster = useUrlBroadcaster()
    const videos = useVideos();
    const resource = useResources();
    const isMovable = useIsUriMovable()
    const  broadcastMessageToClass  = useMessageBroadcaster()
    const sendAudioUrl = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/audio%2Fmixkit-long-pop-2358.wav?alt=media&token=71986a77-d7e2-4d99-a85a-e34b7833cdee";
    const messageSentAudio = new Audio(sendAudioUrl);
    async function bringStudent(){
        setLoader(true)
        await urlBroadcaster()
        message.success('success!',3);
        setLoader(false)
    }
    function getCurrentLoaction(path, v) {
        if(!path) return 'Not logged in'
        if (path === '/') return 'Home'
        if (path === '/stories' || path === '/story-levels') return 'Stories'
        if (path === '/apBook' || path === "/ap") return 'AP'
        if (path === '/ap-exams') return 'AP Exams'
        if (path === '/ebook') {
            const book = getBookDisplayName(bookAllList, v.query.chapter, false, videos, resource)
            if (book) {
                return book;
            }
            return 'Ebook'
        }
        if (path === '/informateBook' || path === '/informate') return 'Informate'
        if (path === '/1nformate') return 'Informate blog'
        if (path === '/balconCulturalBook' || path === '/balconCultural') return 'Balcón cultural'
        if (path === '/ConectandomeBook' || path === '/Conectandome') return 'Conectándome'
        if (path === '/ComunidadBook' || path === '/comunidad') return 'Comunidad'
        if (path === "/resources") return '1WAYRESOURCES'
        if (path === '/video') {
            const book = getBookDisplayName(bookAllList, v.query.id, true, videos, resource)
            if (book) {
                return book;
            }
            return '1WAYRESOURCES'
        }
        if (path === '/OneWayLessons') return '1WAYLESSONS'
        if (path === '/student/class') return 'Home'
        if (path === '/exams') return 'Exams'
        return path === "/" ? 'logout' : path.replace("/", '')
    }
    const getIdealTime = (idleTime, item) => {
        if (item.active) {
            if (idleTime > 0) {
                if (idleTime > 30) {
                    return `more then 30 minutes`
                } else {
                    return `${idleTime} minutes`
                }
            }
        }
        return ''
    }
    function getStatus(c, item) {
        if (!item.active && !item.login_status) {
            return <Tooltip
                placement={"top"}
                trigger={"hover"}
                title={`Not logged in`}>
                <span>
                    {fail_pass.deactive}
                </span>
            </Tooltip>
        }
        else if (!item.active) {
            return <Tooltip
                placement={"top"}
                trigger={"hover"}
                title={`No status`}>
                <span>
                    {fail_pass.deactive}
                </span>
            </Tooltip>
        } else if (c && item.idle_time > 30) {
            return <Tooltip
                placement={"top"}
                trigger={"hover"}
                title={`Inactive on page`}>
                <span>
                    {fail_pass.inactive}
                </span>
            </Tooltip>
        }
        else if (c) {
            return <Tooltip
                placement={"top"}
                trigger={"hover"}
                title={`Active on this page`}>
                <span>
                    {fail_pass.pass}
                </span>
            </Tooltip>
        }
        else {
            return <Tooltip
                placement={"top"}
                trigger={"hover"}
                title={`Active on another page`}>
                <span>
                    {fail_pass.fail}
                </span>
            </Tooltip>
        }
    }
    function onChatClick(item) {
        let popupRef = document.getElementById('chat-popup-window');
        if (popupRef) {
            let event = new CustomEvent("click", {
                detail: {
                    userId: item.user_id,
                    forceClick: true
                },
                bubbles: true,
                cancelable: true,
            });
            popupRef.dispatchEvent(event);
        }
    }
    async function sendBroadcastMessage(messageText){
        seBroadcasttLoader(true);
        await broadcastMessageToClass(messageText,selectedUsers)
        message.success('Message sent successfully!',3);
        messageSentAudio.play(); 
        setSelectedUsers([])
        setMessageSenderEnable(false)
        seBroadcasttLoader(false);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (c, v) => (
                <span>
                    {c}
                    <CustomBtn onClick={() => onChatClick(v)}>
                        <Chat style={{ fontSize:14 }} />
                    </CustomBtn>
                </span>
            )
        },
        // {
        //     title: 'Last action',
        //     dataIndex: 'time_ago',
        //     key: 'time_ago'
        // },
        {
            title: 'Current location',
            dataIndex: 'lastVisitedPage',
            key: 'lastVisitedPage',
            render: (c, v) => (
                <span>
                    {getCurrentLoaction(c, v)}
                </span>
            )
        },
        // {
        //     title: 'Active',
        //     dataIndex: 'active',
        //     key: 'active',
        //     render: c => (
        //         <span style={{ color: c ? color.green : color.red }}>
        //             {c ? fail_pass.pass : fail_pass.fail}
        //         </span>
        //     )
        // },
        {
            title: 'Active on this page',
            dataIndex: 'has_same_page',
            key: 'has_same_page',
            render: (c,item) => (
                <span>
                    {getStatus(c, item)}
                </span>
            )
        },
        {
            title: 'Idle time',
            dataIndex: 'idle_time',
            key: 'idle_time',
            render: (c,item) => (
                <span style={{ color: color.OrangeExam }}>
                    {getIdealTime(c,item)}
                </span>
            )
        }
    ];
    return (
        <div>
            <div className='activeStudentMdlContent'>
                <DemoPreview video_id={"student-activity-demo"} wrapperStyle={{ justifyContent: "flex-end" }} />
                <TableContainer>
                    <CustomTable columns={columns} dataSource={user_list} pagination={{ position: "bottomRight" }} />
                    <div style={{ marginTop: "-45px", display: "flex", flexDirection: "row", gap: 5 }}>
                        <MoveButton
                            onClick={bringStudent}
                            disabled={!isMovable}
                            endIcon={loader ? <Loading size={14} white /> : null}
                            className='activeStudentLogBtn'>
                            Move student to this page
                        </MoveButton>
                    </div> 
                </TableContainer>
                <div>
                    <MultiSelecter options={user_list} selectedUser={selectedUsers} onSelect={setSelectedUsers} />
                    <MessageSender onSend={sendBroadcastMessage} />
                </div> 
            </div>
        </div>
    );
};



const FeedBackWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;
const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60%;
  background-color: rgb(58, 58, 58);
  background-image: url(${p => p.bg});
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  padding: 20px;
  padding-top: 0px;
  aspect-ratio: 16 / 9;
  @media (max-width: 768px) { /* Tablet screens, typically up to 768px */
    min-height: 40%;
  }

  @media (max-width: 480px) { /* Mobile screens, typically up to 480px */
    width: 100%;
  }
`;
const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;

const AlertTitle = styled.div`   
    font-weight: bold;
    margin-bottom: 5px;
    white-space: pre-line;
    text-align: center;
    width: 100%;
    color: #fff;
    max-width: 80%;
    align-self: center;
    margin-top: 20%;
    font-family: 'Arial Black', 'Impact', sans-serif;
    padding: 10px;
    font-size: calc(16px + 0.5vw); /* Starting font size for small screens with a bit of scaling */

    @media (min-width: 1200px) {
        font-size: 24px; /* Fixed font size for large screens */
    }

    @media (max-width: 768px) {
        font-size: calc(16px + 0.5vw); 
    }

    @media (max-width: 480px) {
        font-size: calc(12px +  0.5vw); 
    }
`;

const ALERT_BG =[message_alert_bg1,message_alert_bg2,message_alert_bg3]
export const MessageAlertViewer = () => {
    const { messageAlertView, revokeMessageAlert } = useMessageAlertView()
    const currentUser = useCurrentUser()
    const bg = useMemo(() => ALERT_BG[Math.floor(Math.random() * ALERT_BG.length)], [messageAlertView])
    const sendAudioUrl = "https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/audio%2Fmixkit-bubble-pop-up-alert-notification-2357.wav?alt=media&token=cf017922-0118-4cea-8ab7-46b306047d74";
    const messageSentAudio = new Audio(sendAudioUrl);
    useEffect(()=>{
        if(messageAlertView.view){
            messageSentAudio.play().catch(e=>{
                console.log("Failed to play")
            });
        }
    },[messageAlertView])
    if (!currentUser || currentUser.role != 'student') {
        return null;
    }
    return (<MuiModal
        open={messageAlertView.view}
        onClose={revokeMessageAlert}
        style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
    >
        <FeedBackWrapper>
            <FeedbackContainer bg={bg}>
            <CloseBtn onClick={revokeMessageAlert}>
                        <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                    </CloseBtn>
           <AlertTitle>{messageAlertView.message || ""}</AlertTitle>
            </FeedbackContainer>
        </FeedBackWrapper>
    </MuiModal>)
}