//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { PlusOutlined } from '@ant-design/icons';
import { Button as ButtonSection, Card, Col, Input, Modal as ModalSection, Row } from 'antd';
import Loading from 'components/Loading';
//  -- Constants ----------------------
import base64 from 'base-64';
import { filter, get, isEmpty, sortBy } from 'lodash';
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleEnableDiableUser } from "store/users/thunks";
import UserForm from "./User";
import { openLoginInNewWindow  } from "store/keystore/thunks"
import Login_as from './Login_as';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const StableWrapper = styled.div`
 width: 100%;
  overflow-x: auto;
//   padding:24px;
//   @media (max-width: 992px) {
//     padding:24px 0px !important;
//   }
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const StableWrapperMain = styled.div`
`;

const ViewSectionText = styled.p`
    color:black;
    font-size:17px;
    font-weight:400;
`;
const SectionText = styled.span`
   font-weight:600;
`;
const FilterContainer = styled.span`
        font-size:17px;
        font-weight:500;
        cursor:pointer;
    `;
const ProfileContainer = styled.span`
    display:flex;
    align-items:center;
    flex-direction:row;
    `;
const OverViewContainer = styled.div`
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        margin-bottom:20px;
    `;
const OverViewLabel = styled.span`
font-size: 12px;
font-weight: 600;
`;
const OverViewValue = styled.span`
font-size: 17px;
font-weight: 500;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const DistrictsAdminTable = ({
    users,
    schools,
    listBooks,
    userloading,
    teacherByOrg,
    onUserAddOrUpdate = () => null,
    onTabsJump = () => null,
}) => {
    const [modal2Open, setModal2Open] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [userForm, setUserForm] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [userData, setUser] = useState(null);
    const currentOrg = useCurrentOrg()
    const [searchText, setSearchText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = React.useState(0);
    const [enableLoader, setEnableLoader] = useState(-1)
    const [alertForAccount, setAlertForAccount] = useState(false)
    const [userToEnable, setuserToEnable] = useState({})
    const dispatch = useDispatch();
    const history = useHistory();
    const [schoolName, setSchoolName] = useState();
    const [loginasLoader,setLoginasLoader] = useState(false);
    const [loginAsModal,setLoginAsModal] = useState(false);
    const filterTeacher = filter(users, obj => obj?.role !== 'teacher' && obj.role !== 'student' && obj?.role !== '');
    const [data, setData] = useState();
    useEffect(() => {
        setFilteredUsers(sortBy(filterTeacher, ["firstName", "lastName"]))
    }, [users])
    const handleSearch = (event) => {

        if (event.target.value && event.target.value.length > 3) {
            var search = event.target.value;
            const filtered = filterTeacher.filter(x => {
                return (
                    `${x.firstName} ${x.lastName}`.toLowerCase().includes(search.toLowerCase())
                    ||
                    `${x.role}`.toLowerCase().includes(search.toLowerCase())
                );
            });

            setFilteredUsers(sortBy(filtered, ["firstName", "lastName"]))
        } else {
            setFilteredUsers(sortBy(filterTeacher, ["firstName", "lastName"]))
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const enableDisableUser = async (userId, status) => {
        setEnableLoader(userId)
        await dispatch(handleEnableDiableUser(userId, status));
        setEnableLoader(-1)
        setAlertForAccount(false)
        setuserToEnable({})
    }
    const addTeachers = (value) => {
        const editData = {
            userData: data,
            isEdit: true
        }
        const addData = {
            userData: null,
            isEdit: false
        }
        if (value == 'edit') {
            history.push({ pathname: "/add-users", state: editData });
        } else {
            history.push({ pathname: "/add-users", state: addData });
        }
    }
    const loginAsUser = async ()=>{
        setLoginasLoader(true);
        await openLoginInNewWindow(data.id);
        setLoginasLoader(false);
   }
    return (
        <>
            <ModalSection
                footer={null}
                title={get('data', 'displayName', '')}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={'60%'}
                className="FilterHeading"
            >
                <ProfileContainer>
                    <ButtonSection type="primary" ghost
                        onClick={() => addTeachers('edit')}
                    >
                        Edit Profile
                    </ButtonSection>
                    <Login_as user={data} />
                </ProfileContainer>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Detail View"
                >
                    <Row>
                        <Col xs={24} lg={24}>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>First name</OverViewLabel>
                                <OverViewValue>{get(data, 'firstName', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Username</OverViewLabel>
                                <OverViewValue>{get(data, 'username', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Role</OverViewLabel>
                                <OverViewValue>{get(data, 'role', '')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Last name</OverViewLabel>
                                <OverViewValue>{get(data, 'lastName', '')}</OverViewValue>
                            </OverViewContainer>

                            <OverViewContainer>
                                <OverViewLabel>Password</OverViewLabel>
                                <OverViewValue>{data?.password ? base64.decode(data?.password) : '-'}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                    {/* <Divider />
                    <Row>
                        <Col xs={12} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>School Name</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_schools", data)}>{schoolName}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row> */}
                </Card>
            </ModalSection>

            <Modal
                open={alertForAccount}
                onClose={() => setAlertForAccount(false)}
            >
                <ModalContent>
                    Are you sure you want to {get(userToEnable, "active", true) ? "disable" : "enable"}  {get(userToEnable, "firstName", '')}  {get(userToEnable, "lastName", '')}
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setAlertForAccount(false)
                                setuserToEnable({})
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginRight: '4px' }}
                            color="secondary"
                            variant="contained"
                            className={`${enableLoader != -1 ? "disabled-button" : ""}`}
                            onClick={() => enableDisableUser(userToEnable.id, !userToEnable.active)}
                        >
                            {enableLoader != -1 ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            {userForm ? (
                <StableWrapper>
                    <UserForm
                        userData={userData}
                        editing={editUser}
                        listBooks={listBooks}
                        teacherByOrg={teacherByOrg}
                        onClose={() => {
                            setEditUser(false);
                            setUserForm(false);
                            setUser(null);
                            onUserAddOrUpdate();
                            setSearchText("");
                        }}
                        schools={schools}
                        currentOrg={currentOrg}
                    />
                </StableWrapper>
            ) : (
                <StableWrapperMain>
                    <Row style={{ marginBottom: 15 }}>
                        <Col>
                            <ButtonSection style={{ minHeight: 38 }} type="primary" onClick={() => addTeachers()}>
                                <PlusOutlined /> Add District Admin
                            </ButtonSection>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={24}>
                            <ViewSectionText>
                                View <SectionText>District Admins</SectionText> records.
                            </ViewSectionText>
                        </Col>
                        <Col xs={24} lg={24}>
                            {/* <TextField id="search" name="search" label="Search" type="text" variant="filled" onKeyUp={handleSearch} size="small" style={{ backgroundColor: "rgba(255, 255, 255, 1)" }} /> */}
                            <FilterContainer className="FilterHeading">
                                <Card
                                    type="inner"
                                    title="Filters"
                                    // extra={<a>Clear</a>}
                                >
                                    <Row>
                                        <Col xs={24} lg={24} md={24} className="ColCardPadding">
                                            <Card style={{ width: '100%' }}>
                                                    <Input placeholder="Search District Admins by name" bordered={false} style={{ height: '32px' }}
                                                    onChange={handleSearch}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </FilterContainer>
                        </Col>
                    </Row>

                    <StableWrapper style={{ marginTop: 30 }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>First Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Last Name</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Username</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Role</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Password</TableCell>
                                        <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Status</TableCell>
                                        {/* <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Edit</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(filteredUsers?.length < 1) && (
                                        userloading ? <Loading size={30} /> : (
                                            <TableRow key={'0'} style={{ border: 0 }}>
                                                <TableCell colSpan={6} style={{ textAlign: "center" }}>No results</TableCell>
                                            </TableRow>
                                        )
                                    )}
                                    {(!isEmpty(filteredUsers)) && (
                                        filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <TableRow key={row.id} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, }} onClick={x => { }}>
                                                <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                    setModal2Open(!modal2Open)
                                                    setData(row);
                                                }}>{row.firstName}</TableCell>
                                                <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                    setModal2Open(!modal2Open)
                                                    setData(row);
                                                }}>{row.lastName}</TableCell>
                                                <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                    setModal2Open(!modal2Open)
                                                    setData(row);
                                                }}>{row.username}</TableCell>
                                                <TableCell style={{ textAlign: "left", cursor: 'pointer' }} onClick={() => {
                                                    setModal2Open(!modal2Open)
                                                    setData(row);
                                                }}>{row.role}</TableCell>
                                                <TableCell style={{ textAlign: "left" }}>
                                                    {
                                                        row.password ? base64.decode(row.password) : '-'
                                                    }
                                                </TableCell>
                                                <TableCell style={{ textAlign: "center" }}>
                                                    <Button
                                                        variant="contained"
                                                        color={row.active ? "primary" : "secondary"}
                                                        onClick={x => {
                                                            setAlertForAccount(true)
                                                            setuserToEnable(row)
                                                            // enableDisableUser(row.id, !row.active)
                                                        }} style={{ marginRight: '8px', color: COLOR.white, fontSize: 12, width: 80 }} >
                                                        {enableLoader == row.id ? <Loading size={'18px'} white /> : (row.active ? "Enabled" : 'Disabled')}
                                                    </Button>
                                                </TableCell>
                                                {/* <TableCell style={{ textAlign: "center" }}> */}
                                                {/* <Button onClick={x => {
                                                        handleEditUser(row)
                                                    }} style={{ marginRight: '8px' }} >
                                                        <Edit></Edit>
                                                    </Button> */}
                                                {/* </TableCell> */}
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {!isEmpty(filteredUsers) && (
                                            <TablePagination
                                                count={filteredUsers.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                backIconButtonProps={{
                                                    color: "secondary"
                                                }}
                                                nextIconButtonProps={{ color: "secondary" }}
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>

                            </Table>
                        </TableContainer>
                    </StableWrapper>
                </StableWrapperMain>
            )}

        </>
    );
};


//  -- Prop types ---------------------
DistrictsAdminTable.propTypes = {};

//  -- Default props ------------------
DistrictsAdminTable.defaultProps = {};

export default DistrictsAdminTable;
