//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";
import { map, find, filter } from "lodash";
//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import { useEffect } from 'react';
import { useAllBook, useBookOrder } from "store/books/selectors"
import { mapBookByOrder, mapBookByOrderCreateUser } from "utils/lib/ebookHelper"
import { Col, Row } from 'antd';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
  height: 100px;
  width: 80px;
`;

const Text = styled.div`
  font-size: 9px;
  line-height: 10px;
  color: white;
  white-space: pre-line;
`;

const TextContainer = styled.div`
  border-top: 1px solid ${COLOR.black800};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  width: 80px;
  padding:2px;
  background-color: #171e2d;
`;

const Wrapper = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  height: auto;
  outline: none;
  transition: all 300ms ease;
  width: 100%;
 
  

`;

const ListItemCovers = styled.li`
  margin-top: 32px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom:10px;

  @media only screen and (max-width: 500px){
    margin-left: 0px;
  }
  
  box-shadow: ${(p) =>
    p.active ? p.preActive ? "-3px 2px 8px 0px #9d0001, 3px 1px 5px #9d0000;" : " -3px 2px 8px 0px #00989d, 3px 1px 5px #00989d;" : ""};
`;
const List = styled.ul`
  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
  margin-top:24px;
  display: flex;
  flex-direction: column;
`;
const BookSection = styled.div`
    border: 2px solid #e9e9e9;
    box-shadow: ${(p) => p.active ? p.preActive ? "-3px 2px 8px 0px #9d0001, 3px 1px 5px #9d0000;" : " -3px 2px 8px 0px #00989d, 3px 1px 5px #00989d;" : ""};
    margin-bottom: 26px;
    width: 100%;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    width: fit-content;
    margin-right: 16px;
    padding-bottom: 16px;
    
`;
const SectionTitle = styled.div`
    font-size: 14px;
    color: #ffffff;
    background: ${p=>p.bgColor?p.bgColor:'#00c4cc'};
    padding: 4px 8px;
    border-radius: 6px !important;
    cursor: pointer;
    position: absolute;
    top: -10px;
    left: -6px;
`;
function filterOrdersByOrderIds(oneArray, orderIds) {
  const filteredOrders = [];

  for (const orderItem of oneArray) {
    if (
      orderItem.order.some((itemId) =>
        orderIds.some((orderId) => orderId.id === itemId)
      )
    ) {
      filteredOrders.push(orderItem);
    }
  }

  return filteredOrders;
}
const SelectableBook = ({ chapter, selected, onSelect, preSelected, disabled }) => {
  const [bookid, setBookid] = useState([]);
  const bookOrder = useBookOrder()
  const bookAllList = useAllBook()
  const orderedBook = mapBookByOrderCreateUser(bookAllList, filterOrdersByOrderIds(bookOrder,chapter))
  const isActive = React.useCallback((id) => { return bookid.includes(id) })
  const handleSelect = (data) => {
    if (preSelected.includes(data.id)) {
      return;
    }
    let bids = Array.from(bookid);
    if (bids.includes(data.id)) {
      bids = bids.filter((i) => i != data.id);
    } else {
      bids.push(data.id);
    }
    onSelect(bids);
    setBookid(bids);
  }
  const handleSelectArea = (data) => {
    let bids = Array.from(bookid);
    const hasBook = filter(data, bk => bookid.includes(bk.id))
    if (data.length <= hasBook.length) {
      bids = bids.filter((i) => !find(data,k=>k.id==i));
    } else if (hasBook.length > 0) {
      map(data, k => {
        if (!bookid.includes(k.id)) {
          bids.push(k.id)
        }
      })
    } else {
      map(data, k => {
          bids.push(k.id)
      })
    }
    onSelect(bids);
    setBookid(bids);
  }
  useEffect(() => {
    try {
      if (selected && selected.length > 0 && bookid.length == 0) {
        setBookid(selected);
      }
    } catch (error) {

    }
  }, [selected])
  const filterAssignableBook = (books) =>{
    const unassignablebook = filter(bookAllList,book =>book.isNotAssignable).map(k=>k.id)
    return  filter(books,k=>!unassignablebook.includes(k.id))

  }
  const RenderItem = React.useCallback(() => {
    return map(orderedBook, bk => {
      const books = filterAssignableBook(bk.books);
      const hasBook = filter(books, k => bookid.includes(k.id)).length > 0
      return (
        <div>
      <BookSection key={bk.title} active={hasBook}
        onClick={(e) => {
          if (disabled) return;
          e.preventDefault();
          handleSelectArea(books);
        }}>
        {bk.title ? <SectionTitle bgColor={bk.color} >{bk.title}</SectionTitle> : null}
        <List>
          <div style={{display:'flex',flexDirection:'row'}} className="allCreateBook">
          {map(books, book => {
            if (!book || !book.id) return null;
            const activeStatus = isActive(book.id);
            const preactive = preSelected?.includes(book.id);
            return (
              <ListItemCovers key={book.id} active={activeStatus} preActive={preactive}>
                <Wrapper>
                  <div className="card-resource">
                    <a >
                      <div className="card-resource-overlay" style={{ opacity: activeStatus ? 0.5 : 0 }}></div>
                      <Image
                        className="card-resource-image"
                        src={book.img}
                        alt={book.description}
                      />
                      <TextContainer>
                        <Text>{book.description}</Text>
                      </TextContainer>
                    </a>
                  </div>
                </Wrapper>
              </ListItemCovers>
            )
          })}
          </div>
        </List>
      </BookSection>
      </div>
      )
    })
  }, [bookid, orderedBook,bookAllList])

  // const RenderItem=React.useCallback(()=>{
  //     return(map(chapter, (data) => {
  //       const activeStatus = isActive(data.id);
  //       const preactive = preSelected?.includes(data.id);
  //       return(<ListItemCovers key={data.id} active={activeStatus} preActive={preactive}>
  //         <Wrapper>
  //           <div className="card-resource">
  //             <a
  //               onClick={(e) => {
  //                 if(disabled) return;
  //                 e.preventDefault();
  //                 handleSelect(data);
  //               }}
  //             >
  //               <div className="card-resource-overlay" style={{opacity:activeStatus?0.5:0}}></div>
  //               <Image
  //                 className="card-resource-image"
  //                 src={data.img}
  //                 alt={data.description}
  //               />
  //               <TextContainer>
  //                 <Text>{data.description}</Text>
  //               </TextContainer>
  //             </a>
  //           </div>
  //         </Wrapper>
  //       </ListItemCovers>
  //     )}))
  // },[bookid,chapter])
  return (
    <List>
      <RenderItem />
    </List>
  );
};

//  -- Prop types ---------------------
SelectableBook.propTypes = {
  data: shape({
    img: string,
    title: string,
    displayName: string,
  }),
  handleClick: func,
};

//  -- Default props ------------------
SelectableBook.defaultProps = {
  data: {},
};

export default SelectableBook;
