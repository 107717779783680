import CancelIcon from "@material-ui/icons/Cancel";
import ShareIcon from "@material-ui/icons/Share";
import React, { useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import { find, get, debounce } from "lodash";
import { SPANISH_ITEM_LOWER, SPANISH_ITEM_UPPER } from "utils/config/reference";
import SunEditor from "suneditor-react";
import {
  blockquote,
  align,
  font,
  fontSize,
  fontColor,
  hiliteColor,
  horizontalRule,
  list,
  table,
  formatBlock,
  lineHeight,
  template,
  paragraphStyle,
  textStyle,
  link,
  image,
} from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import { useCurrentUser } from "store/users/selectors";
import { useCollectionWidgets } from "utils/methods/firebaseWidgets";
import { v4 } from "uuid";
import { Button as AntdButton } from "antd";
import color from "utils/constants/color";
import AccordinButton from "components/AccordinButton";
import AnimatedCheckMark from "components/AnimatedCheckMark";
import { useMobileDetect } from "containers/chat/hooks";
import ClickOutside from "../OnClickOutside";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
  //   margin: 15px auto;/
  transform: translate(31%, 0%);

  @media (max-width: 1450px) {
    // width: 96%;
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    // width: 95%;
    transform: translate(0%, 0%);
  }
`;
const NotebookContainer = styled.div`
  border: 2px solid #009688;
  border-radius: 10px;
  width: 641px;
  height: 350px;
  background-color: white;
  position: relative;
  overflow: auto;
`;

const FixedToolbarEditorContainer = styled.div`
  .sun-editor {
    height: 100%;
  }

  .sun-editor .se-toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
  }

  .sun-editor .se-wrapper {
    height: calc(
      100% - 41px
    ); /* Adjust this value based on the height of the toolbar */
    overflow: auto;
  }
`;

const BUTTON_LIST = [
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike"],
  ["removeFormat"],
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "table"],
  ["fullScreen"],
  [
    {
      name: "SPANISH_ITEM_LOWER",
      dataCommand: "SPANISH_ITEM_LOWER",
      buttonClass: "",
      title: `${SPANISH_ITEM_LOWER[0]}`,
      dataDisplay: "submenu",
      innerHTML: `<span>${SPANISH_ITEM_LOWER[0]}</span>`,
    },

    //...
  ],
  [
    {
      name: "SPANISH_ITEM_UPPER",
      dataCommand: "SPANISH_ITEM_UPPER",
      buttonClass: "",
      title: `${SPANISH_ITEM_UPPER[0]}`,
      dataDisplay: "submenu",
      innerHTML: `<span>${SPANISH_ITEM_UPPER[0]}</span>`,
    },
  ],
];

const SPANISH_ITEM_LOWER_PLUGIN = {
  name: "SPANISH_ITEM_LOWER",
  display: "submenu",
  add: function (core, targetElement) {
    let listDiv = this.setSubmenu.call(core);
    var self = this;
    listDiv.querySelectorAll(".se-btn-list-lower").forEach(function (btn) {
      btn.addEventListener("click", self.onClick.bind(core));
    });
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu: function () {
    const listDiv = this.util.createElement("DIV");
    listDiv.className = "se-submenu se-list-layer";
    listDiv.innerHTML = `<div class="se-list-inner se-list-font-size" style="width:30px;">
      <ul class="se-list-basic">
         ${SPANISH_ITEM_LOWER.map(
           (item) =>
             `<li><button type="button" class="se-btn-list-lower" value="${item}">${item}</button></li>`
         ).join("")}
      </ul></div>`;

    return listDiv;
  },
  onClick: function (e) {
    e.preventDefault()
    const value = e.target.value;
    const node = this.util.createElement("span");
    this.util.addClass(node, "se-custom-tag");
    node.textContent = value;
    this.insertNode(node);
    const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
    node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);
    this.submenuOff();
  },
};

const SPANISH_ITEM_UPPER_PLUGIN = {
  name: "SPANISH_ITEM_UPPER",
  display: "submenu",
  add: function (core, targetElement) {
    let listDiv = this.setSubmenu.call(core);
    var self = this;
    listDiv.querySelectorAll(".se-btn-list-upper").forEach(function (btn) {
      btn.addEventListener("click", self.onClick.bind(core));
    });
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu: function () {
    const listDiv = this.util.createElement("DIV");
    listDiv.className = "se-submenu se-list-layer";
    listDiv.innerHTML = `<div class="se-list-inner se-list-font-size" style="width:30px;">
      <ul class="se-list-basic">
         ${SPANISH_ITEM_UPPER.map(
           (item) =>
             `<li><button type="button" class="se-btn-list-upper" value="${item}">${item}</button></li>`
         ).join("")}
      </ul></div>`;

    return listDiv;
  },
  onClick: function (e) {
    const value = e.target.value;
    const node = this.util.createElement("span");
    this.util.addClass(node, "se-custom-tag");
    node.textContent = value;
    this.insertNode(node);
    const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
    node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);
    this.submenuOff();
  },
};

const WidgetsText = ({ currentClass, sharableWidget }) => {
  const isMobileDevice = useMobileDetect();
  const [editorFocus, setEditorFocus] = useState(false);
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const { createCollectionWidgets, updateTextCollectionWidgets } =
    useCollectionWidgets();
  const [text, setText] = useState("");
  const [dimensions, setDimensions] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [showAccordin, setShowAccordin] = useState(true);

  const trafficData = trafficModal?.widget_menu ?? [];
  const isTeacher = get(currentUser, "role") === "teacher";
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "widget_text"
  );

  const filterShareText = find(
    sharableWidget,
    (obj) => obj?.widget_type === "widget_text"
  );

  const onClose = async () => {
    const closeShareData = {
      ...filterShareText,
      status: false,
      data: {},
    };
    await dispatch(revokeTrafficModal(filterByWidget?.id));
    setText("");
    if (filterShareText?.status) {
      createCollectionWidgets(closeShareData);
    }
  };

  const debounceEditorFocus = debounce(() => {
    setEditorFocus(false);
  }, 1000);

  const handleEditorChange = (content) => {
    setText(content);
    // if (filterShareText?.status) {
    //   const editShareData = {
    //     ...filterShareText,
    //     data: {
    //       text: content,
    //     },
    //   };
    //   createCollectionWidgets(editShareData);
    //   // debouncedCreateCollectionWidgets(editShareData);
    // } else {
    //   updateTextCollectionWidgets(currentClass.id, content);
    // }
  };

  const shareText = () => {
    if (!filterShareText?.id) {
      const shareData = {
        id: v4(),
        class_id: currentClass?.id,
        created_by: currentUser?.id,
        created_at: new Date(),
        widget_type: "widget_text",
        status: true,
        data: {
          text: text,
        },
      };
      createCollectionWidgets(shareData);
    } else {
      const shareData = {
        ...filterShareText,
        data: {
          text: text,
        },
      };
      createCollectionWidgets(shareData);
    }
  };

  const updateTextOnShare = () => {
    try {
      setDisabled(true);
      const shareData = {
        ...filterShareText,
        data: {
          text: text,
        },
      };
      createCollectionWidgets(shareData);
    } catch (err) {
      console.log(err);
    } finally {
      setDisabled(false);
    }
  };

  const handleTouch = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchEdit = (event) => {
    event.preventDefault();
    handleEditorChange(event);
  };

  const handleTouchShare = (event) => {
    event.preventDefault();
    shareText();
  };

  const sharedData = filterShareText?.data || {};
  const displayButtons = isTeacher ? BUTTON_LIST : [];
  const  plugins = [
    blockquote,
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    textStyle,
    SPANISH_ITEM_LOWER_PLUGIN,
    SPANISH_ITEM_UPPER_PLUGIN
  ]

  const [isDragging,setIsDragging] = useState(false);

  return (
    <>
      {((filterByWidget?.view && filterByWidget?.menu === "widget_text") ||
        filterShareText?.id) && (
        <Container className="allCheckListSEction wigetsContainer">
             {isMobileDevice && (
        <style>
          {`
            .sun-editor-editable, .sun-editor-editable * {
              font-size: 11px !important;
              max-font-size: 11px !important;
            }
          `}
        </style>
      )}
          <div  className="mainCheckList">
          <ClickOutside onClickOutside={() => setIsDragging(false) } >
            <ResizeAndDragabble
              defaultValue={{
                x: 150,
                y: 205,
                // width: 270,
                // height: 323,
                width: isTeacher ? 671 : 255,
                height: isTeacher ? 433 : 220,
              }}
              onFocusValue={isDragging}
              minHeight={isTeacher ? 323 : 255}
              minWidth={isTeacher ? 270 : 220}
              resize={(data) => setDimensions(data)}
              showAccordin={showAccordin}
              // onFocusValue={editorFocus}
              page={"text"}
            >
              <div
              onTouchStart={(event)=>{
                event.stopPropagation();
                if(!isTeacher){
                  return;
                }
                setIsDragging(true)
              }}
                // className="checkListTopSection"
                className={`checkListTopSection ${
                  isDragging && showAccordin ? "widgetMobAndTab" : ""
                }`}
                style={{
                  position: "relative",
                  boxShadow: showAccordin && "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                  width: "100%",
                  height: "100%",
                  overflowX: "hidden",
                  padding: "0px",
                  // border: isDragging && "3px solid #002b70"
                }}
              >
                <div
                  className={`checkListFirstTxt bg-blue-10 h-44px pt-17px pb-14px ${
                    showAccordin ? "br-topLeft-topRight-15px" : "br-20px"
                  } `}
                >
                    <AccordinButton
                      open={showAccordin}
                      onClose={() => {
                        setIsDragging(false);
                        setShowAccordin((prev) => !prev);
                      }}
                      shareableWidget = {sharableWidget}
                      />

                  <span className="text-white text-ft12-700">Text</span>
                </div>
                {isTeacher && (
                  <CancelIcon
                    onClick={() => onClose()}
                    onTouchStart={handleTouch}
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      right: 17,
                      top: 13,
                      fontSize: 18,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  />
                )}
                {isTeacher && (
                  <>
                    <span
                      onClick={() => shareText()}
                      // onTouchStart={handleTouchShare}
                      style={{
                        marginLeft: "5px",
                        position: "absolute",

                        right: "40px",
                        top: "9px",
                      }}
                    >
                      <AntdButton
                        disabled={(() => {
                          const tempElement = document.createElement("div");
                          tempElement.innerHTML = text;

                          const textContent =
                            tempElement.textContent || tempElement.innerText;
                          return textContent.length === 0;
                        })()}
                        style={{
                          borderRadius: 10,

                          color: "white",
                          backgroundColor: "transparent",
                          border: "0px",
                        }}
                      >
                        {!filterShareText?.status ? (
                          <ShareIcon style={{ color: "white", fontSize: 16 }} />
                        ) : (
                          <AnimatedCheckMark />
                        )}
                        {/* {filterShareText?.status ? "Sharing" : "Share"} */}
                      </AntdButton>
                    </span>
                    {filterShareText?.status && (
                      <span
                        onClick={() => updateTextOnShare()}
                        onTouchStart={updateTextOnShare}
                        style={{
                          marginLeft: "5px",
                          position: "absolute",
                          right: 126,
                          top: 5,
                        }}
                      >
                        <AntdButton
                          type="dashed"
                          disabled={disabled}
                          style={{
                            borderRadius: 10,
                            minWidth: 60,
                            backgroundColor: "lightBlue",
                            color: "black",
                            border: "0px",
                          }}
                        >
                          Update
                        </AntdButton>
                      </span>
                    )}
                  </>
                )}

                {showAccordin && (
                  <div style={{ padding: "25px", paddingLeft: "14px" }}>
                    <NotebookContainer
                      style={{
                        overflowX: "hidden",
                        width: dimensions?.width ? dimensions.width - 30 : 641,
                        height: dimensions?.height
                          ? dimensions.height - 100
                          : 350,
                      }}
                    >
                      <FixedToolbarEditorContainer
                        onMouseEnter={() => setEditorFocus(true)}
                        onMouseLeave={() => setEditorFocus(false)}
                        onTouchStart={()=> {
                          setEditorFocus(true);
                          debounceEditorFocus()
                        }}
                      >                      
                        {sharedData?.text ? (
                          <SunEditor
                            onChange={handleEditorChange}
                            onTouchStart={handleTouchEdit}              
                            setContents={sharedData?.text}
                            height="100%"
                            placeholder="Please type here..."
                            setOptions={{
                              height: "100%",
                              width: "100%",
                              showPathLabel: false,
                              buttonList: displayButtons,
                              plugins: plugins,
                            }}
                            setDefaultStyle={
                              "text-align: left; height:100%; min-height:235px;"
                            }
                            style={{ color: "#000000" }}
                            disable={!isTeacher ? true : false}
                          />
                        ) : (
                          <SunEditor
                            onChange={handleEditorChange}
                            onTouchStart={handleTouchEdit}
                            setContents={sharedData?.text}
                            height="100%"
                            placeholder="Please type here..."
                            setOptions={{
                              height: "100%",
                              width: "100%",
                              showPathLabel: false,
                              buttonList: displayButtons,
                              plugins:plugins,
                            }}
                            setDefaultStyle={
                              "text-align: left; height:100%; min-height:235px;font-size: 11px"
                            }
                            style={{ color: "#000000" }}
                            disable={!isTeacher ? true : false}
                          />
                        )}
                      </FixedToolbarEditorContainer>
                    </NotebookContainer>
                  </div>
                )}
              </div>
            </ResizeAndDragabble>
            </ClickOutside>
          </div>
        </Container>
        //{" "}
      )}
    </>
  );
};

export default WidgetsText;
