//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const logsInitial = [];

export const class_user_logs = (state = logsInitial, action) => {
    switch (action.type) {
        case ActionTypes.logs.set_active_class_logs:
            return action.payload;
        case ActionTypes.logs.clear_active_class_log:
            return logsInitial;
        default:
            return state;
    }
};


const alertViewInit = {
    view:false,
    message:''
};


export const messageAlertView = (state = alertViewInit, action) => {
    switch (action.type) {
        case ActionTypes.alert.invokeMessageAlert:
            return ({ view: true, message: action.payload });
        case ActionTypes.alert.revokeMessageAlert:
            return ({ view: false, message: '' });
        default:
            return state;
    }
};
