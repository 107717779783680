//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


const selector = (state) => state.canvasAuthDistrict;

//  -- Examples array selector --------

export const useCanvasDistrictAuth = () => {
    const selectedData = useSelector(selector, shallowEqual)
   return selectedData;
}

const cat_selector = (state) => state.canvasAssignmentCategory;
export const useCanvasAssignmentCategory = () => {
    const selectedData = useSelector(cat_selector,shallowEqual)
    return selectedData;
}