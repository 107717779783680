//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as Actions from "./actions"
// import { useCurrentGameSession } from 'store/game_session/selectors';
// import { handleUpdateCurrentGameSessionStatus } from "store/game_session/thunks"
import { useCurrentClass } from 'store/classes/selectors';
import { useEffect } from 'react';
import { handleListClassGameActivity } from "./thunks"
const selectore = (state) => {
    return state.games
};
export const use1Game = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}

const player_selecter = (state) => state.playGameModal
export const usePlayModal = () => {
    const playGameModal = useSelector(player_selecter, shallowEqual)
    const dispatch = useDispatch();

    const closePlayerModal = () => {
        dispatch(Actions.setPlayGameModal({
            open: false,
            data: {}
        }))
    }
    const openPlayerModal = (data) =>{
        dispatch(Actions.setPlayGameModal({
            open: true,
            data: data
        }))
    }
    return { closePlayerModal, playGameModal , openPlayerModal }
}
// const oneGameModal_selecter = (state) => state.oneGameModal
// export const useOneGameModal = () => {
//     const oneGameModal = useSelector(oneGameModal_selecter, shallowEqual)
//     const { currentGameSession } = useCurrentGameSession()
//     const dispatch = useDispatch();
//     const closeOnegamerModal = () => {
//         if (currentGameSession.session_id && currentGameSession.state !== "ended") {
//             dispatch(handleUpdateCurrentGameSessionStatus(currentGameSession.session_id, "ended"))
//         }
//         dispatch(Actions.setOpenOneGame({
//             open: false,
//             iframeUrl: ''
//         }))
//     }
//     const openOnegameModal = (url) => {
//         dispatch(Actions.setOpenOneGame({
//             open: true,
//             iframeUrl: url
//         }))
//     }
//     return { closeOnegamerModal, oneGameModal, openOnegameModal }
// }


const class_game_activity_select = (state) => state.class_game_activity
export const useClassGameActivity = () => {
    const class_game_activity = useSelector(class_game_activity_select, shallowEqual)
    const currentClass = useCurrentClass();
    const dispatch = useDispatch();
    const fetchClassGameActivity = async () => {
        // dispatch(Actions.clearClassGameActivity());
        await dispatch(handleListClassGameActivity(currentClass.id))
    }
    // useEffect(() => {
    //     if (currentClass && currentClass.id) {
    //         fetchClassGameActivity()
    //     }
    // }, [currentClass])

    return ({
        class_game_activity,
        fetchClassGameActivity
    })
}