import React, { useState , useEffect  } from "react";
import { DatePicker , Form  } from "antd";
// import 'antd/dist/antd.css';
import moment from "moment";
import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  margin-top: 16px;
  width: 100%;
`;

const Label = styled.label`
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  padding: 0 4px;
  font-size: 12px;
  color: #090606b3;
  transition: 0.2s ease all;
  pointer-events: none;
  
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  ${({ hasError }) =>
    hasError &&
    css`
      border-color: red;
    `}
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
const CustomDateTimePicker = ({ value, onChange, minDate, label, disabled , minDateMessage  }) => {
    const [focused, setFocused] = useState(false);
    const [hasError, setHasError] = useState(false);
    const handleFocus = () => setFocused(true);
    const handleBlur = () => !value && setFocused(false);
    const handleChange = (date) => onChange && onChange(date);
  
    const disabledDate = (current) =>
      minDate && current && current.isBefore(moment(minDate), 'minute');

    useEffect(() => {
      if (value && minDate && moment(value).isBefore(moment(minDate),"minute")) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }, [value, minDate]);
    const min_message = minDateMessage ? minDateMessage : `Date must be after ${moment(minDate).format("MM-DD-YYYY h:mm A")}`;
    return (
        <Container>
            <StyledDatePicker
                showTime={{ use12Hours: true, format: 'h:mm A' }}
                format="MM-DD-YYYY h:mm A"
                value={value?moment(value):null}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabledDate={disabledDate}
                onSelect={handleChange}
                onPanelChange={handleChange}
                disabled={disabled}
                hasLabel={!!label}
                getPopupContainer={(trigger) => trigger.parentElement}
                dropdownClassName="custom-dropdown createDateTime"
                hasError={hasError}
            />
            <Label focused={focused || value}>{label}</Label>
            {hasError && <ErrorMessage>{min_message}</ErrorMessage>}
        </Container>
    );
};


export default CustomDateTimePicker;
