//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const setEbookHighlight = (highlight) => ({
  type: ActionTypes.ebook_highlight.set_ebook_highlight,
  payload:highlight,
});
export const createOrUpdateEbookHighlight = (highlight) => ({
  type: ActionTypes.ebook_highlight.update_ebook_highlight,
  payload:highlight,
});

export const clearEbookHighlighter = () => ({
    type: ActionTypes.ebook_highlight.clear_ebook_highlight,
});