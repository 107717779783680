//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useReducer , useState , useCallback  , useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get, map, groupBy, find, filter } from "lodash"
import { Avatar } from 'antd';
import {  SearchOutlined, SendOutlined } from '@ant-design/icons';
import COLOR from 'utils/constants/color';
import { WbIncandescent, AccountCircle, Add, AttachFile, Close } from "@material-ui/icons"
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Collapse, Paper } from '@material-ui/core';
import { sortMessage } from "utils/methods/math"
import Typography from '@material-ui/core/Typography';
import moment from "moment"
import { AVatarSelector } from "./avatarSelector";
import { getAvatar } from 'utils/assets/images/lootie';

moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  })

//  -- Constants ----------------------
//  -- Components ---------------------
//  -- Thunks -------------------------
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Wrapper = styled.div`
	width: 100%;
    height: 100%;
    align-item: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
     @media (max-width: 768px) {
       min-height: 100vh;
     }
`;
const UserList = styled.div`
    min-height: 40px;
    width: 98.5%;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    margin-top: 10px;
    background: transparent;
    border: none;
    color: #fff;
    text-align: left;
    display:flex;
    flex-direction:row;
    font-size: 12px;
    align-items: center;
`;

const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const OnlineIndicater = styled.div`
    height:10px;
    width:10px;
    background-color:${COLOR.green100};
    border-radius: 50%;
    position: absolute;
    right: 0px;
    top: auto;
`; 
const MessageTopArea = styled.div`ß
    border-top-left-radius: 20px;
    display:flex;
    flex-direction:row;
    border-bottom:1px solid #a5a5a5;
`;
const UserArea = styled.div`
    display:flex;
    flex-direction:column;
    padding: 0px 15px 10px;
    overflow:auto;
    overflow-x:hidden;
    width: 100%;
    padding-top: 0px;
`;
const OptionArea = styled.div`
    width: 100%;
    padding: 20px 15px 5px;
`;
const MessageText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
`;
const MessageTopIcon = styled.div` `;
const MessageIcon = styled.img`
    margin-left: -10px;
    width: 100%;
    height: auto;
`;
const SearchArea = styled.div`
    border-radius: 20px;
    height: 32px;
    display:flex;
    flex-direction:row;
    padding:2px;
    background: rgb(75 156 161 / 80%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 6px;
`;
const SearchIconArea = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

`;
const SearchInput = styled.input`
    width:90%;
    height:30px;
    font-size:15px;
    background: transparent;
    border: none;
    outline: none;
    margin-left: 5px;
    color:#fff;
    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${COLOR.white};
    }
`;
const ClassAnnouncment = styled.div`
    min-height: 32px;
    margin-bottom: 10px;
    background: #3c7275;
    color: #f7f9f9;
    width: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    text-overflow: ellipsis;
    padding: 8px 8px;
    border-radius: 3px;
`;
const RecentButton = styled.div`
    min-height: 32px;
    margin-bottom: 0px;
    color: #fff;
    width: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    text-overflow: ellipsis;
    padding: 20px 15px 5px;
`;
const UserRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction:row;
    width:100%;
    position: relative;
`;
const AvtarIconCol = styled.div`
    display: flex;
    align-items: center;
    width:17%;
    position:relative;
    @media (max-width: 992px) {
        width: auto;
        margin-right: 12px;
    }
`;
const AvtarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    position:relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
`;
const NameCol = styled.div`
    display: flex;
    align-items: center;
    width:83%;
    flex-direction:column;
    @media (max-width: 768px) { 
      width: 88%;
      margin-right: 12px;
    }  
`;
const NameInnerRow = styled.div`
    display: flex;
    width:100%;
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
`;
const NameText = styled.span`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
`;
const TimeText = styled.span`
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    width: 40%;
    text-align: right;
}
`;
const MessageInnerRow = styled.div`
    display: block;
    width:100%;
    font-size: 10px;
    color: #ffffffd9;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ui-sans-serif;
    align-items:center;
`;
const UserModal = styled.div`
    left: 50%;
    max-width: 400px;
    padding: 0px 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;
const UserPopUpArea = styled.div`
    background-color: #fff;
    height: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 20px;
    position:relative;
`;
const MessageCounterNotificationv = styled.span`
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0px;
    top: 40%;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    background: red;
    overflow: hidden;
`;
const CloseBtn = styled.div`
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    z-index: 1;
`;
const useStyles = makeStyles((theme) => ({
    bulbicon: {
        color: ' rgb(223 175 109)',
        fontSize: '18px',
        rotate: 'deg(180)',
        transform: 'rotate(180deg)',
        marginRight: '5px'
    },
    usericon: {
        color: 'rgb(255, 255, 255)',
        fontSize: '30px',
        marginRight: ' 5px'
    },
    usericonPopUp: {
        color: COLOR.gray100,
        fontSize: '30px',
        marginRight: ' 5px'
    },
    usericonPopUp: {
        color: COLOR.gray100,
        fontSize: '30px',
        marginRight: ' 5px'
    },
    addIcon: {
        color: 'rgb(255, 255, 255)',
        fontSize: '20px',
        marginRight: ' 5px'
    },
    sendIcon: {
        color: 'rgb(255, 255, 255)',
        fontSize: '20px',
        marginRight: ' 5px'
    },
    fileIconWhite: {
        color: "#fff",
        fontSize: 10
    },
    fileIconGray: {
        color: COLOR.gray100,
        fontSize: 10
    },
    newMessageTitle: {
        marginTop: '-10px',
        marginBottom: '10px',
        fontWeight: "bold",
        textAlign: "center"
    }
}));
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------

const getTimeMessageTime = (messageTime) => {
    const dayDiff = moment().diff(moment(messageTime), "day");
    const weekDiff = moment().diff(moment(messageTime), "week");
    const monthDiff = moment().diff(moment(messageTime), "month");
    const yearDiff = moment().diff(moment(messageTime), "year")
    if (!messageTime) return null;
    if (dayDiff == 0) {
        const hourDiff = moment().diff(moment(messageTime), "hours")
        const minutDiff = moment().diff(moment(messageTime), "minutes")
        const secondDiff = moment().diff(moment(messageTime), "seconds")
        if (secondDiff > 15) {
            if (minutDiff > 59) {
                return `${hourDiff} hr`
            }
            return minutDiff > 0 ? `${minutDiff} min` : `${secondDiff} sec`;
        }
        return `Just now.`
    } else {
        if (dayDiff > 7) {
            if (weekDiff > 5) {
                if (monthDiff > 12) {
                    return `${yearDiff} y`
                }
                return `${monthDiff} m`
            }
            return `${weekDiff} w`
        }
        return `${dayDiff} d`
    }
}
const Users = ({
    currentUser,
    socket,
    activeUser,
    activeClass,
    classes,
    onClassSelect,
    selectedUser,
    setSelectedUser,
    chatMessages,
    chatUser,
    FILE_TYPE
}) => {
    // const isStudent = get(currentUser, "role") == 'student'
    // const currentClass = useCurrentClass()

    const cssClasses = useStyles();
    const [userModal, setuserModal] = useState(false)
    const [expendRecent, setExpendRecent] = useState(true)
    const [recentUserMessages, setRecentMessage] = useState([])
    const [filteredUser, setFilteredUser] = useState([])
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [unreadBrodCastMessage,setUnreadBrodcastmessage] = useState({})
    useEffect(() => {
        if(chatMessages&&chatMessages.length>0){
        const brodcastMessage = groupBy(chatMessages, "brodcastId")
        if(get(currentUser,"role")==="student"){
            let v = {};
            Object.keys(brodcastMessage).map(key=>{
            if(key==null||!key || key=="null"){ 
                return;
            }
            else{
                v[key] =  filter(brodcastMessage[key],msg =>!find(msg.brodcastSeen, ["id", currentUser.id])).length;
            }
            })
            setUnreadBrodcastmessage(v)
        }
    }
    }, [chatMessages])
    useEffect(() => {
        setFilteredUser(activeUser)
    }, [activeUser])
    useEffect(() => {
        const interval = setInterval(forceUpdate, 15000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    useEffect(() => {
        const userMessages = { ...groupBy(chatMessages, "senderId"), ...groupBy(chatMessages, "receiverId"), ...groupBy(chatMessages, "brodcastId") }
        const userMessagesData = map(Object.keys(userMessages), key => {
            let usr = find(activeUser, ['id', key])
            let isBrodcast = false;
            if (!usr) {
                if (key !== activeClass.id) return null;
                usr = find(classes, ['id', key])
                if (usr) {
                    isBrodcast = true;
                } else {
                    return null;
                }
            }
            const mData = isBrodcast ? brodcastLastmessage(key) : userLastmessage(key)
            const unseenCount = isBrodcast ? 0 : unseenMessage(key)
            return ({
                usr,
                mData,
                unseenCount
            })
        }).filter(k => k);
        const sorteduserMessagesData = userMessagesData.sort((a, b) => (new Date(get(b, "mData.createdAt")) - new Date(get(a, "mData.createdAt"))))
        setRecentMessage(sorteduserMessagesData)
    }, [chatMessages, activeUser])

    const closeuserModal = () => {
        setuserModal(false)
        setFilteredUser(activeUser)
    }
    const userLastmessage = (userId) => {
        return sortMessage(filter(chatMessages, c => c.receiverId && (c.senderId === userId || c.receiverId === userId))).reverse()[0]
    }
    const brodcastLastmessage = (userId) => {
        return sortMessage(filter(chatMessages, c => c.brodcastId === userId)).reverse()[0]
    }
    const unseenMessage = (userId) => {
        return filter(chatMessages, c => (c.senderId === userId && c.receiverId && !c.seen)).length
    } 
    const getUserIcon = (usr,popup) => {
        const avatar = getAvatar(get(usr, "config.avatar"))
        return (avatar ? <Avatar size={30} src={avatar} /> : <AccountCircle className={popup?cssClasses.usericonPopUp:cssClasses.usericon} />)
    }
    const RecenctRender = useCallback(() => {
        return (map(recentUserMessages, dt => {
            const usr = dt.usr
            const mData = dt.mData
            const unseenCount = dt.unseenCount
            const isBordcast = !get(usr, "username")
            return (<UserList key={get(usr, "id")} active={get(selectedUser, "id") === get(usr, "id")} onClick={() => setSelectedUser(usr)}>
                <UserRow>
                    <AvtarIconCol>
                        <AvtarContainer>
                            {getUserIcon(usr)}
                            {usr.active && <OnlineIndicater />}
                        </AvtarContainer>
                    </AvtarIconCol>
                    <NameCol>
                        {unseenCount > 0 ? <MessageCounterNotificationv>{unseenCount}</MessageCounterNotificationv> : null}
                        <NameInnerRow> <NameText>{isBordcast ? `${get(usr, "displayName")} (announcement)` : `${get(usr, "lastName", "")} ,  ${get(usr, "firstName", "")}`} </NameText> <TimeText>{getTimeMessageTime(get(mData, "createdAt"))} </TimeText> </NameInnerRow>
                        <MessageInnerRow> {mData ? get(mData, "type") == "media" ? <> {<AttachFile style={{ color: "#fff", fontSize: 10 }} />} Attachment  {get(mData, "message", "")} </> : get(mData, "message", "") : ''} </MessageInnerRow>
                    </NameCol>
                </UserRow>
            </UserList>)
        }))
    }, [recentUserMessages])
    const onSearchUser = (e) => {
        try {
            let txt = e.target.value ? e.target.value : '';
            if (txt && txt.length > 0) {
                txt = txt.toLowerCase().replace(/\s/g, '')
                const val = filter(activeUser, act => {
                    const lastName = get(act, "lastName", "").toLowerCase().replace(/\s/g, '');
                    const firstName = get(act, "firstName", "").toLowerCase().replace(/\s/g, '');
                    const username = get(act, "username", "").toLowerCase().replace(/\s/g, '');
                    if (lastName.includes(txt)) {
                        return true;
                    } else if (firstName.includes(txt)) {
                        return true;
                    } else if (username.includes(txt)) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setFilteredUser(val)

            } else {
                setFilteredUser(activeUser)
            }
        } catch (error) {
            setFilteredUser(activeUser)
        }
    }
    return (
        <>
            <Modal
                open={userModal}
                onClose={closeuserModal}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }} className="msgSystemMdlMn">
                <UserModal className="msgSystemMdlUsr">
                    <UserPopUpArea className="msgSystemMdlAra">
                        <Typography variant="h6" className={cssClasses.newMessageTitle} >New Message</Typography>
                        <CloseBtn onClick={closeuserModal}>
                            <Close style={{ color: COLOR.black100, fontWeight: "bolder", fontSize: 25 }} />
                        </CloseBtn>
                        <SearchArea>
                            <SearchIconArea>
                                <SearchOutlined />
                            </SearchIconArea>
                            <SearchInput placeholder='Search' onChange={onSearchUser} />
                        </SearchArea>
                        <UserArea style={{ height: '55vh' , padding:0 }}>
                            {map(filteredUser, usr => {
                                return (<UserList key={get(usr, "id")} active={get(selectedUser, "id") === get(usr, "id")} onClick={() => {
                                    setSelectedUser(usr)
                                    setuserModal(false)
                                }}>
                                    <UserRow>
                                        <AvtarContainer>
                                            {getUserIcon(usr,true)}
                                            {usr.active && <OnlineIndicater />}
                                        </AvtarContainer>
                                        <NameCol>
                                            <NameInnerRow style={{ color: COLOR.gray100, paddingLeft: 5 }}>  {get(usr, "lastName", "")} ,  {get(usr, "firstName", "")} </NameInnerRow>
                                        </NameCol>
                                    </UserRow>

                                </UserList>)
                            })}
                        </UserArea>
                    </UserPopUpArea>
                </UserModal>
            </Modal>
            <Wrapper className="msgSystemMdlMsg">

                <MessageTopArea className="msgSystemMdlTp">
                    <MessageText>OneWayMessaging</MessageText>
                    <MessageTopIcon><AVatarSelector size={40} /></MessageTopIcon> 
                </MessageTopArea>

                <OptionArea className="msgSystemMdlOpn">
                    <ClassAnnouncment onClick={() => setuserModal(true)}>
                        <Add className={cssClasses.addIcon} /> New message
                    </ClassAnnouncment>
                    <ClassAnnouncment onClick={() => setSelectedUser(activeClass)}>
                        <WbIncandescent className={cssClasses.bulbicon} /> Class Announcement
                        {unreadBrodCastMessage[get(activeClass,"id")] > 0 ? <MessageCounterNotificationv style={{backgroundColor:COLOR.red, position:"relative",marginLeft:10}}>{unreadBrodCastMessage[get(activeClass,"id")]}</MessageCounterNotificationv> : null}
                    </ClassAnnouncment>
                    <RecentButton onClick={() => { setExpendRecent(!expendRecent) }}>
                        <SendOutlined className={cssClasses.sendIcon} /> Recent Messages
                    </RecentButton>
                </OptionArea>

                <UserArea className="msgSystemMdlusr2">
                    <Collapse in={expendRecent} style={{ background: "transparent" }}>
                        <Paper elevation={0} style={{ background: "transparent" }}>
                            {RecenctRender()}
                        </Paper>
                    </Collapse>
                </UserArea>

            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
Users.propTypes = {};

//  -- Default props ------------------
Users.defaultProps = {

};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Users);
