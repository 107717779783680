import {
  Button,
  FormHelperText,
  IconButton,
  TextField
} from "@material-ui/core";
import {
  Close,
  Delete
} from "@material-ui/icons";
import { Col, Row } from "antd";
import { cloneDeep, findIndex, get, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px; 
    @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;
const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;
const UnitsSubArea = styled.div`
  margin-top: 16px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    // margin-left:5px;
    // margin-top:5px;
`;

const SubTopicUnits = ({ onCloseDrawer, data, onUpdateUnits }) => {
  const [subTopics, setSubTopics] = useState([])
  const unitScrollRef = useRef();
  const [error, setError] = useState([])
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setSubTopics(data ? data : [])
  }, [data])
  const onAddUnist = () => {
    const _subTopics = subTopics ? cloneDeep(subTopics) : []
    const lastElement = subTopics[subTopics.length - 1]
    let subTopicId = 1;
    if (lastElement) {
      subTopicId = parseInt(lastElement.subTopicId) + 1
    }
    _subTopics.push({
      "shortName": "",
      "startPage": "",
      "finishPage": "",
      "subTopicId": subTopicId,
      "description": ""
    })
    setSubTopics(_subTopics)
    if (unitScrollRef.current) {
      unitScrollRef.current.scrollTo(
        0,
        unitScrollRef.current.scrollHeight
      );
    }
  }
  const onClickAdd = () => {
    onAddUnist();

  }
  const onRemoveUnits = (id) => {
    const _units = findIndex(subTopics, k => k.subTopicId == id)
    const _subTopics = subTopics ? cloneDeep(subTopics) : []
    if (_units >= 0) {
      _subTopics.splice(_units, 1)
    }
    onUpdateUnits(_subTopics)
    setSubTopics(_subTopics)
  }

  const onChangeSubTopics = (id, data) => {
    const _units = findIndex(subTopics, k => k.subTopicId == id)
    const _subTopics = subTopics ? cloneDeep(subTopics) : []
    if (_units >= 0) {
      _subTopics[_units] = data;
    }
    setSubTopics(_subTopics)
  }
  const onAddSubunits = (id) => {
    const _units = findIndex(subTopics, k => k.subTopicId == id)
    const _subTopics = subTopics ? cloneDeep(subTopics) : []
    if (_units >= 0) {
      if (_subTopics[_units] && Array.isArray(_subTopics[_units].subUnits)) {
        _subTopics[_units].subUnits.push({
          "label": "",
          "start": '',
          "finish": ''
        })
      } else {
        _subTopics[_units].subUnits = [{
          "label": "",
          "start": '',
          "finish": ''
        }]
      }
      setSubTopics(_subTopics)
    }
  }
  const onRemoveSubunits = (id, index) => {
    const _units = findIndex(subTopics, k => k.subTopicId == id)
    const _subTopics = subTopics ? cloneDeep(subTopics) : []
    if (_units >= 0) {
      if (_subTopics[_units] && Array.isArray(_subTopics[_units].subUnits)) {
        _subTopics[_units].subUnits.splice(index, 1)
        setSubTopics(_subTopics)
        onUpdateUnits(_subTopics)
      }
    }
  }
  const onUpdate = () => {
    const _error = []
    map(subTopics, (sb, i) => {
      _error.push({
        "shortName": !sb.shortName,
        "startPage": !sb.startPage,
        "finishPage": !sb.finishPage,
        subUnits: map(get(sb, "subUnits"), su => ({
          "label": !su.label,
          "start": !su.start,
          "finish": !su.finish
        }))
      })
    })
    if (JSON.stringify(_error).includes('true')) {
      setHasError(true)
    } else {
      setHasError(false)
      onUpdateUnits(subTopics)
      onCloseDrawer()
    }
    setError(_error)
  }
  const onChangeUpdateError = (index, key) => {
    let e = cloneDeep(error);
    if (e[index]) {
      e[index][key] = false
      setError(e)
    }
  }

  const onChangeSububitUpdateError = (index, i, key) => {
    let e = cloneDeep(error);
    if (e[index] && error[index].subUnits) {
      e[index].subUnits[i][key] = false
      setError(e)
    }
  }
  const getSubUnitError = (index, i, key) => {
    return get(error[index] && error[index].subUnits ? error[index].subUnits[i] : {}, key)
  }
  return (<ModalContent>
    <Row style={{ justifyContent: "space-between" }}>
      <TitleText>Add Units</TitleText>
      <Button
        variant="contained"
        color="primary"
        onClick={onClickAdd}
        style={{ padding: 0 }}
      >
        Add Units
      </Button>
      <IconButton
        onClick={() => onCloseDrawer()}
        style={{ padding: 0, marginRight: 10 }}
      >
        <Close color="secondary" />
      </IconButton>
    </Row>
    <ModalConfigArea ref={unitScrollRef}>
      {map(subTopics, (sb, index) => (
        <LinkArea key={sb.subTopicId}>
          <Row
            gutter={[24, 24]}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <IconButton
              onClick={() => onRemoveUnits(sb.subTopicId)}
              style={{ padding: 0, marginRight: 10 }}
            >
              <Delete color="secondary" />
            </IconButton>
          </Row>
          <Row style={{ margin: 5, marginBottom: 20 }} gutter={[24, 24]}>
            <Col xs={24} lg={12}>
              <TextField
                id="startPage"
                name="startPage"
                error={get(error[index], "startPage")}
                label="Start pageId"
                InputLabelProps={{ shrink: true, required: true }}
                type="number"
                variant="outlined"
                onChange={(e) => {
                  onChangeUpdateError(index, "startPage")
                  onChangeSubTopics(sb.subTopicId, {
                    ...sb,
                    startPage: e.target.value,
                  });
                }}
                value={get(sb, "startPage")}
              />
            </Col>
            <Col xs={24} lg={12}>
              <TextField
                id="finishPage"
                name="finishPage"
                error={get(error[index], "finishPage")}
                label="End pageId"
                InputLabelProps={{ shrink: true, required: true }}
                type="number"
                variant="outlined"
                onChange={(e) => {
                  onChangeUpdateError(index, "finishPage")
                  onChangeSubTopics(sb.subTopicId, {
                    ...sb,
                    finishPage: e.target.value,
                  });
                }}
                value={get(sb, "finishPage")}
              />
            </Col>
            <Col xs={24} lg={12}>
              <TextField
                id="shortName"
                name="shortName"
                error={get(error[index], "shortName")}
                label="Sort name"
                InputLabelProps={{ shrink: true, required: true }}
                type="text"
                variant="outlined"
                onChange={(e) => {
                  onChangeUpdateError(index, "shortName")
                  onChangeSubTopics(sb.subTopicId, {
                    ...sb,
                    shortName: e.target.value,
                  });
                }}
                value={get(sb, "shortName")}
              />
            </Col>
            <Col xs={24} lg={12}>
              <TextField
                id="description"
                name="description"
                label="Description"
                InputLabelProps={{ shrink: true }}
                type="text"
                variant="outlined"
                onChange={(e) => {
                  onChangeSubTopics(sb.subTopicId, {
                    ...sb,
                    description: e.target.value,
                  });
                }}
                value={get(sb, "description")}
              />
            </Col>
          </Row>
          <Row style={{ justifyContent: "flex-end", marginTop: 10 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onAddSubunits(sb.subTopicId);
              }}
              style={{ padding: 0, marginRight: 10 }}
            >
              Add subunits
            </Button>
          </Row>
          {map(get(sb, "subUnits"), (su, i) => (
            <UnitsSubArea key={i}>
              <Row
                gutter={[24, 24]}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <IconButton
                  onClick={() => onRemoveSubunits(sb.subTopicId, i)}
                  style={{ padding: 0, marginRight: 10 }}
                >
                  <Delete color="secondary" />
                </IconButton>
              </Row>
              <Row style={{ margin: 5, marginTop: 12 }} gutter={[24, 24]}>
                <Col span={24}>
                  <TextField
                    id="label"
                    name="label"
                    label="Label"
                    InputLabelProps={{ shrink: true }}
                    error={getSubUnitError(index, i, "label")}
                    type="text"
                    variant="outlined"
                    onChange={(e) => {
                      onChangeSububitUpdateError(index, i, "label")
                      const v = cloneDeep(sb);
                      v.subUnits[i].label = e.target.value;
                      onChangeSubTopics(sb.subTopicId, v);
                    }}
                    value={get(su, "label")}
                  />
                </Col>
              </Row>
              <Row style={{ margin: 5, marginTop: 12 }}>
                <Col span={24}>
                  <TextField
                    id="start"
                    name="start"
                    label="start PageId"
                    error={getSubUnitError(index, i, "start")}
                    InputLabelProps={{ shrink: true, required: true }}
                    type="number"
                    variant="outlined"
                    onChange={(e) => {
                      onChangeSububitUpdateError(index, i, "start")
                      const v = cloneDeep(sb);
                      v.subUnits[i].start = e.target.value;
                      onChangeSubTopics(sb.subTopicId, v);
                    }}
                    value={get(su, "start")}
                  />
                </Col>
              </Row>
              <Row style={{ margin: 5, marginTop: 12 }}>
                <Col span={24}>
                  <TextField
                    id="finish"
                    name="finish"
                    label="finish pageId"
                    error={getSubUnitError(index, i, "finish")}
                    InputLabelProps={{ shrink: true, required: true }}
                    type="number"
                    variant="outlined"
                    onChange={(e) => {
                      onChangeSububitUpdateError(index, i, "finish")
                      const v = cloneDeep(sb);
                      v.subUnits[i].finish = e.target.value;
                      onChangeSubTopics(sb.subTopicId, v);
                    }}
                    value={get(su, "finish")}
                  />
                </Col>
              </Row>
            </UnitsSubArea>
          ))}
        </LinkArea>
      ))}
    </ModalConfigArea>
    {hasError ? (
      <FormHelperText error>
        All marked field are required.
      </FormHelperText>
    ) : null}
    <Row
      gutter={[24, 24]}
      style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
    >
      <Button
        fullWidth
        disabled={subTopics.length == 0}
        onClick={onUpdate}
        color="primary"
        variant="contained"
      >
        Update
      </Button>
    </Row>

  </ModalContent>)
}

export default SubTopicUnits;