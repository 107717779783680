//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React from "react";
import { shape, string, number, func } from "prop-types";
import styled from "styled-components";
import Unlike from "utils/assets/images/unlike-image.svg"
import Liked from "utils/assets/images/liked-image.svg"

//  -- Constants ----------------------
import COLOR from "utils/constants/color";
import Loading from "./Loading";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const Image = styled.img`
    width: 100%;
`;

const Text = styled.div`
    // color: rgb(0 0 0 / 85%);
    font-size: 15px;
    color:white;
    white-space: pre-line;
`;

const TextContainer = styled.div`
    border-top: 1px solid ${COLOR.black800};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    padding:0px 16px;
    background-color: #171E2D;
`;

const Wrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    cursor: pointer;
    height: auto;
    outline: none;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        opacity: 0.7;
    }
`;


const BalconCover = ({ data, handleClick,page = "", handleFavouriteVideo,loader,allFavorites }) => {
 const getFavorites =allFavorites&& allFavorites?.some(video => video.id === data.id)
    return (
        <Wrapper>
            <div className="card-resource">
                <a onClick={handleClick}>
                    <div className="card-resource-overlay"></div>
                    <Image className="card-resource-image" src={data.src} alt={data.shortName} />
                    <div className="card-resource-details fadeIn-bottom">
                        <h3 className="card-resource-title">{data.shortName}</h3>
                    </div>
                </a>
                {page === "balcon" && (
                        <div onClick={() => handleFavouriteVideo(data)} className="like-unlike-resource-container">
                            {loader === data?.id ? <div style={{marginBottom: 6}}><Loading size={18} /></div> : <img width={23} height={23} src={getFavorites ? Liked : Unlike} alt="" />}
                        </div>
                    )}
            </div>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
BalconCover.propTypes = {
    data: shape({
        img: string,
        title: string,
        description: string,
    }),
    handleClick: func
};

//  -- Default props ------------------
BalconCover.defaultProps = {
    data: {},
};

export default BalconCover;