//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'


const selectore = (state) => {
    return state.vocabulary
};
export const useVocabulary = () => {
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}
