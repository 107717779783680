import { useFirebase } from "containers/FirebaseContext";
import { collection, deleteDoc, doc, doc as firestoreDoc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";

export const useShareNotification = () => {
    const { firestore } = useFirebase();
    const createShareNotification = async (shareNotificationData, shareId) => {
        try {
            const generated_session_id = shareId + shareNotificationData?.id;
            const docRef = firestoreDoc(firestore, "share_notifications", generated_session_id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap?.data();
                const newData = {
                    ...shareNotificationData,
                    notification_list: [...data?.notification_list, ...shareNotificationData?.notification_list]
                }
                await updateDoc(docRef, newData)
            } else {
                await setDoc(docRef, shareNotificationData)
            }
            return;
        } catch (error) {
            console.log(error, "error")
        }
    }

    const listenToSentenceData = (keyID, callback) => {
        if (!keyID) {
            return () => { };
        }
        const collectionRef = collection(firestore, "sentence_starter");
        const q = query(collectionRef, where("id", "==", keyID));
    
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const sharableData = {};
            querySnapshot.forEach((doc) => {
                sharableData[doc.id] = doc.data();
            });
            callback(sharableData);
        }, (error) => {
            console.error("Error listening to session changes:", error);
        });
    
        return unsubscribe;
    };    

    const createSentenceStarter = async (sentenceData) => {
        try {
            const generated_id = "admin_sentence";
            const docRef = firestoreDoc(firestore, "sentence_starter", generated_id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                await updateDoc(docRef, sentenceData);
            } else {
                await setDoc(docRef, sentenceData);
            }
            return;
        } catch (error) {
            console.log(error, "error")
        }
    }

    const deleteSentenceData = async (docId) => {
        try {
            const docRef = doc(firestore, "sentence_starter", docId);
            await deleteDoc(docRef);
            console.log(`Document with ID ${docId} successfully deleted!`);
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };


    const updateShareNotification = async (id, userId) => {
        try {
            const notificationsRef = collection(firestore, "share_notifications");
            const q = query(notificationsRef, where("id", "==", id));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                const data = doc.data();
                const existingUser = data.userSeen && data.userSeen.includes(userId);
                if (!existingUser) {
                    const docRef = firestoreDoc(notificationsRef, doc.id);
                    await updateDoc(docRef, {
                        userSeen: [...(data.userSeen || []), userId]
                    });
                }
            })
        } catch (error) {
            // Handle errors
        }
    }
    return { createShareNotification, updateShareNotification, createSentenceStarter, deleteSentenceData, listenToSentenceData }
}