import { Col, Modal, Row } from "antd";
import { DeleteModal } from "components/Dialog";
import { useFirebase } from "containers/FirebaseContext";
import {
  FieldValue,
  arrayRemove,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import { get, map } from "lodash";
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDebateModal } from "store/OneWayDebate/selectors";
import { useCurrentClass } from "store/classes/selectors";
import { useOneWayDebate } from "store/debate/selectors";
import { handleCreateOneWayDebate, handleUpdateOneWayDebate, handleSyncDebate } from "store/debate/thunks";
import { useCurrentUser, useUserProfileConfig } from "store/users/selectors";
import styled from "styled-components";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { debounce } from 'underscore';
import {
  DebateNewLogo,
  newDebates
} from "utils/assets/images";
import { v4 } from 'uuid';
import DebateCommentSection from "./DebateComponent/DebateCommentSection";
import DebateContentType from "./DebateComponent/DebateContentType";
import DebateLiveSection from "./DebateComponent/DebateLiveSection";
import DebateStudentDetail from "./DebateComponent/DebateStudentDetail";
import DebateStudentType from "./DebateComponent/DebateStudentType";
import StudentContent from "./DebateComponent/StudentDebate/StudentContent";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useClassUsers, useUserClasses } from "store/userClasses/selectors";
import { useClassGameActivity } from "store/one_game/selectors";
import DebateTimer from "./DebateComponent/DebateTimer";
import { handleListClassSubmissions } from "store/submissions/thunks";
import DemoPreview from "components/ViewDemoModal";
import { debate_icon } from "utils/assets/images/icons"
const ResponseText = styled.div`
  margin-left: 20px;
`;

const DebateModalWrapper = styled.div`
  max-height: 58px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: transparent;
  z-index: 11;
  -webkit-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  cursor: pointer;
  margin-bottom: 5px;
}
`;

const LoaderWrapper = styled.div`
  // position:absolute;
  z-index: 100;
`;

const DebateModal = ({debatesModal,setDebatesModal}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const currentClass = useCurrentClass();
  const [debateModal, invokeDebateModal, revokeDebateModal] = useDebateModal();
  const [editClick, setEditClick] = useState(true);
  const [contentType, setContentType] = useState(false);
  const [previousDebateId, setPreviousDebateId] = useState(null);
  const [allEditClick, setAllEditClick] = useState(true);
  const [allContentType, setAllContentType] = useState(false);
  const [openTeacherModal, setOpenTeacherModal] = useState("");
  const [openStudentModal, setOpenStudentModal] = useState("");

  const [studentEditClick, setStudentEditClick] = useState(true);
  const [studentContentType, setStudentContentType] = useState(false);
  const [indexs, setIndex] = useState(0);

  const [studentEditBlog, setStudentEditBlog] = useState(true);
  const [blogContentType, setBlogContentType] = useState(false);

  const [studentCommentSec, setStudentCommentSec] = useState(true);
  const [commentContentType, setCommentContentType] = useState(false);
  const [closeStudentModal, setCloseStudentModal] = useState(false);

  const [shareLoader, setShareLoader] = useState(false);

  const [debateResponse, setDebateResponse] = useState({});
  const [timer, setTimer] = useState(0);

  const [studentSubmission, setStudentSubmission] = useState([]);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [showStudentComment, setShowStudentComment] = useState(false);

  const [endLoader, setEndLoader] = useState(false);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
  });
  const [inputValuestudent, setInputValuestudent] = useState("");
  const { firestore, database, onValue, databaseRef, databaseSet } = useFirebase();
  const [deleteModal, setDeleteModal] = useState(false);
  const { fetchClassGameActivity } = useClassGameActivity();
  const { profile_config } = useUserProfileConfig();
  const user_profile_img = get(profile_config, "config.profile_pic", "");

  const debate_configuration = get(debateResponse, 'configurations', '') || {};

  useEffect(() => {
    const fetchData = async () => {
      const twoDaysAgo = moment().subtract(2, 'days').toISOString();
      const collectionRef = collection(firestore, "debate");
      const q = query(collectionRef, where("status", "==", false), where("created_at", "<=", twoDaysAgo));
      try {
        // const querySnapshots = await getDocs(collectionRef);
        // const totaCount = querySnapshots.size;
        const querySnapshot = await getDocs(q);
        const count = querySnapshot.size;
        if (count > 0) {
          querySnapshot.forEach(doc => {
            deleteDoc(doc.ref).then(() => {
              return
            }).catch(error => {
              console.error(`Error deleting document: ${error}`);
            });
          });
        }
      } catch (error) {
        console.error(`Error getting documents: ${error}`);
      }
    };

    fetchData();
  }, [])

  const createNewCollection = async (value) => {
    // Create a new collection
    setSubmitLoader(true);
    const user_id = get(currentUser, 'id', '');
    const studentAnswer = {
      answer_time: moment().toISOString(),
      user_id: get(currentUser, "id", ''),
      user_name: `${get(currentUser, 'firstName', '')} ${get(currentUser, 'lastName', '')}`,
      answer_text: value,
      debate_id: debateResponse?.id,
      submission_id: v4(),
      response_id: '',
      comment: [],
      user_profile_img: user_profile_img,
    }
    const generated_session_id = user_id + debateResponse?.id;
    const docRef = doc(firestore, "debate_submission", generated_session_id);
    try {
      // Start the transaction
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, studentAnswer)
      } else {
        await setDoc(docRef, studentAnswer)
      }
      if (debateResponse?.debate_type === 'individual_question') {
        setShowEndScreen(true);
      } else {
        // setShowStudentComment(true);
        if (timer === 0) {
          setShowEndScreen(true)
        } else {
          setShowStudentComment(true);
        }
      }
      setSubmitLoader(false);
      setInputValuestudent('');
    } catch (e) {
      console.log('Error adding document: ', e);
    }
  };

  useEffect(() => {
    const filteredData = studentSubmission.filter((submission) => submission.comment.length > parseInt(debate_configuration?.total_receive));
    if (filteredData?.length > 0) {
      deleteExtraComment(filteredData)
    }
  }, [studentSubmission])


  const deleteExtraComment = async (filteredData) => {
    const lastSubmission = filteredData[filteredData.length - 1];
    const lastComment = lastSubmission.comment[lastSubmission.comment.length - 1];
    const lastResponseId = lastSubmission.comment[lastSubmission.comment.length - 1].response_id;
    const generated_session_id = lastResponseId + debateResponse?.id;
    const docRef = doc(firestore, "debate_submission", generated_session_id);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, { comment: arrayRemove(lastComment) });
        if (lastComment?.user_id === get(currentUser, "id")) {
          setAlert({
            open: true,
            message: "The maximum number of answers for this response has been reached. Please try again in a different response.",
          });
        }
      }
    } catch (e) {
      console.log("Error adding document: ", e);
    }
  }

  useEffect(() => {
    const unsubscribe = listenToNewDebates(
      currentClass?.id,
      handleChangeDebate,
      openTeacherModal
    );

    return () => {
      unsubscribe();
    };
  }, [firestore, currentClass?.id, previousDebateId, openTeacherModal]);

  const listenToNewDebates = (classId, onChange, openTeacherModal) => {
    if (!classId) {
      return () => { }; // Return an empty function to avoid errors
    }
    const collectionRef = collection(firestore, "debate");
    const q = query(collectionRef, where("class_id", "==", classId)); // Assuming currentClass is an object containing the ID
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const sessions = [];
        querySnapshot.forEach((doc) => {
          sessions.push({ id: doc.id, ...doc.data() });
        });
        onChange(sessions, openTeacherModal);
      },
      (error) => {
        // console.error('Error listening to new debates:', error);
      }
    );
    return unsubscribe; // Return the unsubscribe function
  };

  const handleChangeDebate = (sessions, openTeacherModal) => {


    const currentDate = new Date();
    const filteredData = sessions?.find((item) => {
      const createdAt = new Date(item.created_at);
      // Compare the dates (ignoring time)
      return (
        createdAt.toDateString() === currentDate.toDateString() &&
        item?.status === true
      );
    });
    if (filteredData) {

      if (filteredData.id !== previousDebateId) {
        setCloseStudentModal(false); // Set closeStudentModal to false when a new debate starts
        setPreviousDebateId(filteredData.id); // Update previousDebateId to the ID of the new debate
        listenToShareData(currentClass?.id);
        if (get(currentUser, "role") === "teacher" && !openTeacherModal) {
          // invokeDebateModal();
          setEditClick(false);
          if (filteredData.debate_type === "individual_question") {
            setAllContentType(true);
          }
          if (filteredData.debate_type === "live_debate") {
            setCommentContentType(true);
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   if(debateResponse && currentUser ){
  //   const collectionRef = collection(firestore, "debate_submission");
  //   const q = query(collectionRef,
  //       where("user_id", "==", get(currentUser, 'id', '')),
  //       where("status", "==", true)

  //   );

  //   onSnapshot(q, (querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //           const data = doc.data();
  //           console.log(data);
  //           if (data.answer_text.trim() !== '') {
  //               setShowEndScreen(true)
  //           } else {
  //             setShowEndScreen(false)
  //           }
  //       });
  //   });
  // }
  // }, [debateResponse, currentUser])
  useEffect(() => {
    if (debateResponse && currentUser) {
      const collectionRef = collection(firestore, "debate_submission");

      const q = query(
        collectionRef,
        where("debate_id", "==", get(debateResponse, "id", ""))
      );
      const timerRef = databaseRef(
        database,
        "debateTimer/" + debateResponse.id
      );
      onValue(timerRef, (snapshot) => {
        const timerData = snapshot.val();
        const timer = timerData?.time || 0;
        onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();

            if (
              openStudentModal === "" &&
              get(currentUser, "role") === "student"
            ) {
              if (
                data.answer_text.trim() !== "" &&
                data.user_id == currentUser.id
              ) {
                if (debateResponse?.debate_type === "individual_question") {
                  setShowEndScreen(true);
                } else {
                  if (debateResponse?.debate_type === "live_debate") {
                    if (timer === 0) {
                      invokeDebateModal();

                      setShowEndScreen(true);
                    } else {
                      setShowStudentComment(true);
                    }
                  }
                }
              } else if (
                data.answer_text.trim() === "" &&
                data.user_id == currentUser.id &&
                debateResponse?.debate_type === "live_debate"
              ) {
                if (timer === 0) {
                  invokeDebateModal();

                  setShowEndScreen(true);
                }
              }
            }
          });
        });
      });
    }
  }, [debateResponse, currentUser, openStudentModal]);
  // setCommentContentType(true)
  const onClose = () => {
    setDebatesModal(false);
    if (get(currentUser, "role") === "student") {
      setCloseStudentModal(true);
    }
    if (
      Object.keys(debateResponse).length > 0 &&
      get(currentUser, "role") === "teacher"
    ) {
      setDeleteModal(true);
    } else {
      updateStatus();
    }
  };

  const endDebateUpdate = async (debateId) => {
    const sessionRef = doc(firestore, "debate", debateId);
    try {
      await runTransaction(firestore, async (transaction) => {
        const sessionDoc = await transaction.get(sessionRef);
        if (!sessionDoc.exists()) {
          return;
        }
        transaction.update(sessionRef, { status: false });
      });
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
  };

  const updateOneWayDebate = async () => {
    const collectionRef = collection(firestore, "debate_submission");
    const q = query(
      collectionRef,
      where("debate_id", "==", debateResponse?.id)
    );
    onSnapshot(
      q,
      (querySnapshot) => {
        const sessions = [];
        querySnapshot.forEach((doc) => {
          sessions.push({ id: doc.id, ...doc.data() });
        });
        const updateData = {
          ...debateResponse,
          status: false,
          answer: JSON.stringify(sessions),
        };
        dispatch(handleUpdateOneWayDebate(updateData));
        syncAssignment(updateData);
      },
      (error) => {
        console.error("Error listening to session changes:", error);
      }
    );
    await endDebateUpdate(debateResponse?.id);
  };
  async function syncAssignment(debutInfo) {
    const { grade: is_graded } = debutInfo.configurations;
    const answer = JSON.parse(get(debutInfo, "answer", "[]"));
    if (is_graded) {
      await dispatch(
        handleSyncDebate({
          debut_id: debutInfo.id,
          submissions: map(answer, (info) => ({
            answer: info.answer_text,
            comment: map(info.comment, (k) => ({
              reply_text: k.reply_text,
              user_id: k.user_id,
              user_name: k.user_name,
              user_profile_img: k.user_profile_img,
            })),
            user_id: info.user_id,
            user_name: info.user_name,
            user_profile_img: info.user_profile_img,
          })),
        })
      );
      Promise.allSettled([
        fetchClassGameActivity(),
        dispatch(handleListClassSubmissions(currentClass.id, false)),
      ]);
    } else {
      console.log("No need to sync grade assignment");
    }
  }
  const updateStatus = async () => {
    if (
      Object.keys(debateResponse).length > 0 &&
      get(currentUser, "role") === "teacher"
    ) {
      setEndLoader(true);
      await updateOneWayDebate();
      setEndLoader(false);
      setDeleteModal(false);
    }
    closeAllState();
  };

  const closeAllState = () => {
    setEditClick(true);
    setContentType(false);
    setAllEditClick(true);
    setAllContentType(false);
    setStudentEditClick(true);
    setStudentContentType(false);
    setStudentEditBlog(true);
    setBlogContentType(false);
    setStudentCommentSec(true);
    setCommentContentType(false);
    setInputValuestudent("");
    revokeDebateModal();
  };

  const handleClickHide = () => {
    setEditClick(false);
    setContentType(true);
  };

  const onCheckActiveDebate = async (debate) => {
    const currentTime = new Date();
    const createdAt = new Date(debate.created_at);
    const totalTimeParts = debate.configurations.total_time;
    const totalMilliseconds = parseInt(totalTimeParts) * 1000;
    const debateEndTime = new Date(createdAt.getTime() + totalMilliseconds);
    if (currentTime >= debateEndTime) {
      await updateStatus();
      revokeDebateModal();
      return;
    }
  };

  useEffect(() => {
    if (Object.keys(debateResponse).length > 0) {
      onCheckActiveDebate(debateResponse);
    }
  }, [debateResponse]);

  // Function to format time as mm:ss
  function formatTime(totalSeconds) {
    // const totalSeconds = Math.floor(milliseconds / 1000);
    // const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
      }${seconds}`;
  }
  const listenToShareData = (classId) => {
    if (!classId) {
      console.log("Class ID is undefined. Cannot listen to session changes.");
      return;
    }

    try {
      const collectionRef = collection(firestore, "debate");
      const q = query(collectionRef, where("class_id", "==", classId));
      const unsubscribe = onSnapshot(
        q,
        async (querySnapshot) => {
          const sessions = [];
          querySnapshot.forEach((doc) => {
            sessions.push({ id: doc.id, ...doc.data() });
          });
          const currentDate = new Date();
          const filteredData = sessions.find((item) => {
            const createdAt = new Date(item.created_at);
            // Compare the dates (ignoring time)
            return (
              createdAt.toDateString() === currentDate.toDateString() &&
              item?.status === true
            );
          });
          // if (filteredData?.status === "show_name") {
          //   setShowStudentNames(true);
          // } else if (filteredData?.status === "hide_name") {
          //   setShowStudentNames(false);
          // }
          if (filteredData) {
            const getPreviousTime = await getDebateTimer(filteredData);
            const timeComponents =
              getPreviousTime ? getPreviousTime : filteredData?.configurations?.total_time || 0;
            setDebateResponse(filteredData);
            if (get(currentUser, "role") === "teacher") {
              saveTimmerRealTime(filteredData, timeComponents, 'main listen');
            }
          } else {
            setDebateResponse({});
            closeAllState();
          }
          if (get(currentUser, "role") === "student") {
            if (filteredData) {
              invokeDebateModal();
            } else {
              revokeDebateModal();
            }
          }
          setShowEndScreen(false);
          setShowStudentComment(false);
        },
        (error) => {
          console.log("Error listening to session changes:", error);
        }
      );

      return unsubscribe;
    } catch (error) {
      console.log("listen error", error);
      // Sentry.captureException(`062 listen error!.${error}`
    }
  };

  const getDebateTimer = (filteredData) => {
    if (filteredData) {
      return new Promise((resolve, reject) => {
        const timerRef = databaseRef(
          database,
          "debateTimer/" + filteredData.id
        );
        onValue(timerRef, (snapshot) => {
          const timerData = snapshot.val();
          const timer = timerData?.time || 0;
          if (timer > 0) {
            resolve(timer);
          } else {
            resolve(0);
          }
        })
      })
    }
  }

  const handleShare = async (
    debateType,
    configuration,
    question,
    grade,
    gradeConfig
  ) => {
    const key = v4();
    setShareLoader(true);
    const configurations = {
      grade,
      grade_config: grade ? gradeConfig : {},
      showStudent: false,
      ...configuration,
    };
    const submitData = {
      id: key,
      question,
      debate_type: debateType,
      user_id: get(currentUser, "id", ""),
      class_id: get(currentClass, "id", ""),
      status: true,
      configurations,
      answer: {},
    };
    const result = await dispatch(handleCreateOneWayDebate(submitData));
    if (result) {
      await addDataWithUUIDIfNotExist(key, result);
      if (debateType === "live_debate") {
        handleClickCommentDetail();
      } else {
        setAllEditClick(false);
        setAllContentType(true);
      }
    }
    setShareLoader(false);
  };

  // Function to add data with UUID if the document with the ID does not exist
  const addDataWithUUIDIfNotExist = async (id, data) => {
    const docRef = doc(firestore, "debate", id);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      try {
        await setDoc(docRef, data);
      } catch (e) {
        console.log("Error adding document: ", e);
      }
    }
  };
  useEffect(() => {
    let session_unsubscribe;
    if (!closeStudentModal) {
      session_unsubscribe = listenToShareData(currentClass?.id);
    }
    return () => {
      if (session_unsubscribe) {
        session_unsubscribe();
      }
    };
  }, [firestore]);

  useEffect(() => {
    let session_unsubscribe;
    // if (debateResponse && debateResponse?.id) {
    session_unsubscribe = listenToSubmissionData(
      debateResponse?.id,
      userSubmissionsHandler
    );
    // }
    return () => {
      if (session_unsubscribe) {
        session_unsubscribe();
      }
    };
  }, [firestore, debateResponse]);

  const listenToSubmissionData = (debateId, onChange) => {
    if (!debateId) {
      return () => { };
    }
    try {
      const collectionRef = collection(firestore, "debate_submission");
      const q = query(collectionRef, where("debate_id", "==", debateId));
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const submission = [];
          querySnapshot.forEach((doc) => {
            submission.push({ id: doc.id, ...doc.data() });
          });
          onChange(submission);
        },
        (error) => {
          console.log("Error listening to session changes:", error);
        }
      );

      return unsubscribe;
    } catch (error) {
      console.log("listen error", error);
    }
  };

  const userSubmissionsHandler = (data) => {
    setStudentSubmission(data);
  };

  useEffect(() => {
    try {
      if (get(currentUser, "role") !== "teacher") return;
      if (timer && Object.keys(debateResponse).length > 0) {
        const intervalFunction = () => {
          if (timer === 0) {
            clearInterval(countdownInterval);
            saveTimmerRealTime(debateResponse, 0, 'lisener 1');
            return;
          }
          if (timer > 0) {
            const newTime = timer - 1;
            if (newTime != timer) {
              saveTimmerRealTime(debateResponse, newTime, 'lisener 2');
            }
          }
        };
        const countdownInterval = setInterval(intervalFunction, 1000);
        return () => clearInterval(countdownInterval);
      }
    } catch (e) {
      console.log("error running time", e);
    }
  }, [timer, debateResponse]);

  useEffect(() => {
    if (Object.keys(debateResponse).length > 0) {
      const timerRef = databaseRef(
        database,
        "debateTimer/" + debateResponse.id
      );
      const unsubscribe = onValue(timerRef, (snapshot) => {
        try {
          const timerData = snapshot.val();
          setTimer(timerData?.time);
        } catch (error) {
          console.log("error in database timer", error);
        }
      });
      return () => unsubscribe();
    }
  }, [database, debateResponse]);

  const saveTimmerRealTime = async (debateData, time, from) => {
    const dbRef = databaseRef(database, "debateTimer/" + debateData.id);
    databaseSet(dbRef, { time: time })
      .then((res) => { })
      .catch((error) => {
        console.log("Error updating time in the database:", error);
      });
  };

  const editTimer = async (editValue) => {
    try {
      const newEditTime = editValue + timer;
      if (newEditTime < 3600) {
        saveTimmerRealTime(debateResponse, newEditTime, 'edit');
        // const docRef = doc(firestore, "debate", debateResponse?.id);
        // // Start the transaction
        // const docSnap = await getDoc(docRef);
        // if (docSnap.exists()) {
        //   await updateDoc(docRef, { "configurations.total_time" : newEditTime });
        // }
      }
    } catch (e) {
      console.log("Error adding document: ", e);
    }
  };

  const handleClickStudentDetail = (i) => {
    setIndex(i);
    setStudentEditClick(false);
    setStudentContentType(true);
  };

  const handleClickLiveDebate = () => {
    setStudentEditBlog(false);
    setBlogContentType(true);
  };

  const handleClickCommentDetail = () => {
    setStudentCommentSec(false);
    setCommentContentType(true);
  };

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: "",
    });
  };

  useEffect(() => {
    if (debateModal.view === true) {
      document.body.style.setProperty("overflow", "hidden", "important");
    } else {
      document.body.style.setProperty("overflow", "", "important");
    }
  }, [debateModal]);
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="error">
          <strong>ALERT:</strong> {alert.message}
        </Alert>
      </Snackbar>
      <DebateModalWrapper
        role={get(currentUser, "role")}
        onClick={() => {
          if (
            get(currentUser, "role") === "student" &&
            Object.keys(debateResponse).length === 0
          ) {
            setAlert({
              open: true,
              message: "No active debate",
            });
          } else {
            invokeDebateModal();
          }
        }}
        className="debateLogoBtnPart"
      >
        <img style={{ height: get(currentUser, "role") === "student" ? 40 : 30 }} src={get(currentUser, "role") === "student" ? debate_icon : newDebates} alt="Debate" />
      </DebateModalWrapper>
      <Modal
        visible={debateModal.view || debatesModal}
        onCancel={onClose}
        className="ideaOneModel debateOneModel"
        maskClosable={false}
      >
        {/* {Object.keys(debateResponse).length > 0 && !showEndScreen && <DebateTimer debateResponse={debateResponse} />} */}
        {/* {get(currentUser, "role") == "student" ? "" :  */}
        {get(currentUser, "role") === "student" && debateResponse ? (
          <StudentContent
            debateResponse={debateResponse}
            timer={formatTime(timer)}
            submitLoader={submitLoader}
            showEndScreen={showEndScreen}
            showStudentComment={showStudentComment}
            studentSubmission={studentSubmission}
            onStudentAnswer={(value) => {
              createNewCollection(value);
            }}
            setInputValuestudent={setInputValuestudent}
            inputValuestudent={inputValuestudent}
            setShowEndScreen={setShowEndScreen}
            setShowStudentComment={setShowStudentComment}
            setOpenStudentModal={setOpenStudentModal}
          />
        ) : (
          <>
            {studentEditBlog && editClick && (
              <DebateContent
                handleClickHide={() => {
                  handleClickHide();
                }}
                handleClickLiveDebate={() => handleClickLiveDebate()}
              />
            )}
            {allEditClick && contentType && (
              <DebateContentType
                shareLoader={shareLoader}
                // aiLoader={aiLoader}
                // handleClickHideSec={(value, question, grade) => handleShare('individual_question', value, question, grade)}
                handleClickHideSec={debounce(
                  (value, question, grade, gradeConfig) => {
                    handleShare(
                      "individual_question",
                      value,
                      question,
                      grade,
                      gradeConfig
                    );
                  },
                  500
                )}
                onAiError={() => {
                  setAlert({
                    open: true,
                    message: "Please try with other question.",
                  });
                }}
                setOpenTeacherModal={setOpenTeacherModal}
              />
            )}
          </>
        )}

        {studentEditClick && allContentType && debateResponse && (
          <>
            <DebateStudentType
              handleClickStudentDetail={(i) => handleClickStudentDetail(i)}
              debateInfo={debateResponse}
              timer={formatTime(timer)}
              studentSubmission={studentSubmission}
              onEditTimer={(val) => {
                editTimer(val);
              }}
            />
          </>
        )}

        {studentContentType && (
          <DebateStudentDetail
            debateInfo={debateResponse}
            indexs={indexs}
            studentSubmission={studentSubmission}
            handleClickAnswerDetail={() => {
              setStudentEditClick(true);
              setStudentContentType(false);
            }}
          />
        )}
        {studentCommentSec && blogContentType && (
          <DebateLiveSection
            shareLoader={shareLoader}
            handleLiveShare={(value, question, grade, gradeConfig) => {
              handleShare("live_debate", value, question, grade, gradeConfig);
            }}
            onAiError={() => {
              setAlert({
                open: true,
                message: "Please try with other question.",
              });
            }}
            setOpenTeacherModal={setOpenTeacherModal}
          />
        )}

        {commentContentType && (
          <>
            <DebateCommentSection
              studentSubmission={studentSubmission}
              debateResponse={debateResponse}
              timer={formatTime(timer)}
              onEditTimer={(val) => {
                editTimer(val);
              }}
            />
          </>
        )}
      </Modal>
      <DeleteModal
        message={`Are you sure you want to end this debate?`}
        deleteToggle={deleteModal}
        setDeleteToggle={setDeleteModal}
        loading={endLoader}
        onDelete={updateStatus}
        buttonTitle={"Yes"}
      />
    </div>
  );
};

const DebateContent = ({
  handleClickHide = () => null,
  handleClickLiveDebate = () => null,
}) => {
  const inpRef = useRef();
  return (
    <div className="ideaMdlUpr">
      <Row>
        <Col xs={24} xl={6}>
          <div className="debateMdlTop">
            <img src={DebateNewLogo} alt="Debate" />
            <DemoPreview video_id={"debate-demo"} wrapperStyle={{ width: "50%" }} />
          </div>
        </Col>
        <Col xs={24} md={12} xl={9}>
          <div className="debateTopSec">
            <div
              className="debateCircle debateYellow"
              onClick={() => {
                handleClickHide();
              }}
            >
              Individual <br /> question
            </div>
          </div>
        </Col>
        <Col xs={24} md={12} xl={9}>
          <div className="debateTopSec">
            <div
              className="debateCircle debeteBlue"
              onClick={() => {
                handleClickLiveDebate();
              }}
            >
              Live blog <br /> debate
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DebateModal;
