import React from 'react';

// const ResponseText = styled.div`
//   margin-left: 20px;
// `;

const ImLate = () => {
 
    return (
        <>
          <div className="goodjobpage">
          <img src="https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/debateLogos.png?alt=media&token=e1a09441-0c55-4be0-a545-d0a579f8d7222" alt="Debate" className="debate1waystickerImg" />
        </div>
        <div style={{display:'flex',alignItems: 'center',justifyContent: 'center',marginTop:20}}>
            <img
            src={require('../../../utils/assets/images/nomoretimes.png')}
              alt="Debates"
            style={{height:30,zIndex:99999}}
            />
        </div>
          <div className="goodjobbg" style={{bottom:105}}>
            <img
            src={require('../../../utils/assets/images/hopeimLate.gif')}
              alt="Debate"
            className="goodjobimage"
            style={{width:350,paddingTop:110}}
            />
          </div>
        </>
      );
    };

export default ImLate;


