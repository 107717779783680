//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { IntroVideo, IntroVideoPoster } from "utils/assets/images";

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const logsInitial = {
    id:1,
    src:IntroVideo,
    poster:IntroVideoPoster,
    displayName:"Intro Video"
}

export const welcomeVideos = (state = logsInitial, action) => {
    switch (action.type) {
        case ActionTypes.welcome_videos.setWelcomeVideos:
            return action.payload;
        case ActionTypes.welcome_videos.updateWelcomeVideos:
            return action.payload;
        default:
            return state;
    }
};
