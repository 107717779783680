import { Col, Row, Progress } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import COLOR from "utils/constants/color"
import { FileUploader } from "react-drag-drop-files";
import { storage } from 'firebaseConfig';
import { ref as fireRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import moment from "moment"
import { v4 } from 'uuid';
import { Img } from "react-image"
import Loading from 'components/Loading';
import { AddToPhotos, Audiotrack } from "@material-ui/icons"

const fileTypes = ["MP3", "WAV", "M4A", "AAC"];


const ImageContainer = styled.div`
    height: auto;
    width:100%;
    display:flex;
    padding-left:24px;
    padding-right:24px;
    margin-bottom:16px;
`;

const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;
const Container = styled.div`
    min-height: 75vh;
    width: 100%;
    position: relative;
    background-color:${p => p.empty ? "rgba(0,0,0,0.1)" : "transparent"}
`;

const Page = styled.div`
    align-items: center;
    color: ${COLOR.black100};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
`;


const Input = styled.input`
    background-color: transparent;
    border: 1px dashed gray;
    border-radius: 3px;
    box-shadow: none;
    font-size: inherit;
    font-weight: 400;
    outline: none;
    padding: 0px 2px;
    transition: all 300ms ease;
    width:250px;
`;
const FileUploadArea = styled.div`
    height: 100%;
    width:100%;
    border: 1px dashed gray;
    padding: 5px 12px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Column = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
}
`;
const Text = styled.div`
    font-size: 12px;
`;

export default function AudioPicker({ onChange, style , message }) {
    const [loader, setLoader] = useState(false)
    const [loadingMap, setLoadingMap] = useState(0)

    const handleChange = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        uploadToStorage(file)
        reader.onloadend = function (e) {
            let v = reader.result;
        }.bind(this);
    };
    const uploadToStorage = async (file) => {
        if (file) {
            const location = '/books/AudioPicker';
            const id = `${moment().format('YYYY-MM-DD')}-${v4()}`;
            const storageRef = fireRef(storage, `${location}/${id}${file.name}`);
            try {
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        const per = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setLoader(true);
                        setLoadingMap(per);
                    },
                    (error) => {
                        console.error("Upload error:", error);
                        setLoader(false);
                        setLoadingMap(0);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setLoader(false);
                            setLoadingMap(0);
                            onChange(downloadURL);
                        }).catch((error) => {
                            console.error("Error getting download URL:", error);
                            setLoader(false);
                            setLoadingMap(0);
                        });
                    }
                );
            } catch (error) {
                console.error("Upload error:", error);
                setLoader(false);
                setLoadingMap(0);
            }
        } else {
            console.log("No file to upload.");
        }
    };
    return (
        <ImageContainer onContextMenu={(e) => { }} style={style}>
            <FileUploader
                handleChange={handleChange}
                name="file"
                classes={"audio-picker-question-upload"}
                types={fileTypes}
            >  <FileUploadArea>
                    {loader && <Progress type="line" percent={loadingMap} size={"small"} className={"fileProgress"} showInfo={false} />}
                    <Row className="box box-1" style={{ display: "flex", justifyContent: "space-between" }}>
                        <Column>
                            <Audiotrack color="primary" />
                        </Column>
                        <Column >
                            <Text>
                              {message?message:"Upload or drop a file right here"}
                            </Text>
                        </Column>
                        <Column>
                            <Text>
                                {fileTypes.join(",")}
                            </Text>
                        </Column>
                    </Row>

                </FileUploadArea>
            </FileUploader>
        </ImageContainer>
    )
}
