//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useEffect, useState, useRef, useReducer, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { arrayOf, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';

//  -- Components ---------------------
import { Col, Row } from 'antd';
import { Button } from '@material-ui/core';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ArrowForward, CheckCircle, Done } from '@material-ui/icons';
import Loading from 'components/Loading';
import TextEditor from 'components/TextEditor';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import _, { filter, map, find, get } from "lodash"
//  -- Features -----------------------
import AudioRecorder from 'features/AudioRecorder';
import Schoology from 'utils/Schoology';
//  -- Thunks -------------------------
import { handleCreateSubmission, handleUpdateSubmission , handleAiFeedback } from 'store/submissions/thunks';
import Interaction from '../components/Interaction';
import { useClassQuizzes } from 'store/classQuizzes/selectors';
import { useClassActivities } from 'store/classActivities/selectors';
import { useOrgTimezone } from 'store/organizations/selectors';
import { useServertime } from 'store/servertime/selectors';
import { isBeforTime } from "utils/methods/math"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert'
import "moment-timezone"
import { handleUpdateCanvasGrade , handleCreateCanvaSubmissions } from 'store/canvas/thunks';
import { handleCreateNotification } from 'store/notification/thunks';
import { useSocket } from 'store/chat/selectors';
import { handleUpdateGradeToGoogleClassRoom } from "store/googleClassRoom/thunks"
var isInSubmit = null;
const wait_timmer = 1000
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const AssignmentDescription = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
`;

const AssignmentTitle = styled.span`
    color: ${COLOR.black100};
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
`;

const Bar = styled.div`
    background-color: ${p => p.complete ? COLOR.green100 : COLOR.green800};
    flex: 1;
    height: 6px;
    margin-right: 4px;
    transition: all 500ms ease;

    &:last-child {
        margin-right: 0px;
    }
`;

const BarContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const ButtonLink = styled.button`
    algin-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.green100};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    margin-right: 16px;
    margin-top: 2px;
    outline: none;
    padding: 0px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: ${p => p.active ? 'auto' : '0px'};
    margin-top: ${p => p.active ? '0px' : '24px'};
    max-width: ${p => p.maxWidth ? `${p.maxWidth}px` : "auto"};
    opacity: ${p => p.active ? 1 : 0};
    overflow: visible;
    transition margin-top 500ms ease, opacity 500ms ease;
    width: 100%;
    z-index: 1;
`;

const Content = styled.div`
    // margin-top: 24px;
    margin-top: 15px;
`;

const Detail = styled(AssignmentDescription)`
    color: ${COLOR.black600};
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
`;

const Option = styled.button`
    background-color: ${p => p.selected ? COLOR.green800 : COLOR.white};
    border: 1px dashed ${p => p.selected ? COLOR.green300 : COLOR.black700};
    border-radius: 3px;
    color: ${p => p.selected ? COLOR.white : COLOR.black400};
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 6px;
    outline: none;
    text-align: left;
    transition: all 300ms ease;
    width: 100%;

    &:hover {
        box-shadow: ${p => p.selected ? 'none' : '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
        color: '${p => p.selected ? COLOR.green100 : COLOR.black200}';
    }
`;

const Question = styled(AssignmentTitle)`
    font-size: 16px;
    line-height: 22px;
`;

const QuestionContainer = styled(Container)`
    margin-top: 0px;
    position: relative;
    padding-bottom: 24px;
`;

const RelativeContainer = styled.div`
    position: relative;
    width: 100%;
    // margin-bottom: 10px;
`;

const Section = styled.div`
    // margin-bottom: 48px;
     margin-bottom: 15px;
    width: 100%;
`;

const SectionHeader = styled.span`
    border-bottom: 1px dashed ${COLOR.black700};
    color: ${COLOR.green100};
    display: block;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.075em;
    margin-bottom: 24px;
    padding-bottom: 4px;
    text-transform: uppercase;
    width: 100%;
`;

const Success = styled.span`
    color: ${COLOR.blac400};
    font-size: 16px;
    font-weight: 600
`;

const Wrap = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const Wrapper = styled.div`
    height: 100%;
    // max-width: 480px;
    max-width: 100%;
    padding: 24px 16px 0px;
    width: 100%;
    overflow-y: auto;
    height: 95%;
`;


//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Assignment
//
//  -- Description
//  Assignment participation workflow
//
//  -- Props
//  data {object} - activity or quiz data
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Assignment data={data} />
//
const Assignment = ({
    chapter,
    currentClass,
    currentOrg,
    currentSubmission,
    currentUser,
    data,
    onClose,
    page,
    retakes,
    interactions,
    userRol,
    setSubmitRef,
    timmer,
    onFinish,
    dueDate,
    redirectTo,
    schoologyAuth,
    shuffle
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const servertime = useServertime()
    const timezone = useOrgTimezone()
    const [step, setStep] = useState(0);
    const [question, setQuestion] = useState(0);
    const [selectedQ, setSelectedQ] = useState(false);
    const [selections, setSelections] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shuffledQuestions, setShuffledQuestions] = useState([]);
    const { socketConnection, reCreateSocket } = useSocket();
    const [finishlaterAlert, setFinishLaterAlert] = useState({ open: false, message: "" })
    const classQuizzes = useClassQuizzes()
    const classActivities = useClassActivities()
    const audioRef = useRef();
    const client = new Schoology(schoologyAuth)
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const pageInteractions = filter(interactions, x => (x.chapter === chapter && x.page === page));
    const assignment = (data?.typeId == "quiz") ? find(classQuizzes, { quizId: data.id }) : find(classActivities, { activityId: data.id });
    const grade_type = assignment?.grade_type;
    const isCompleteInComplete = grade_type === "complete_incomplete"
    const max_point = assignment?.max_point && !isNaN(assignment?.max_point) ? Number.parseInt(assignment.max_point, 10) : 100
    const isPointSystem =  grade_type === "points"

    const [assignmentCreationLoading, setAssignmentCreationLoading] = useState(false)

    useEffect(() => {
        if (shuffle) {
            if (currentSubmission && parseInt(currentSubmission.finishlater) >= 0 && currentSubmission.finishlater != null) {
                const answeredArr=get(data, "questions", []).filter((item,index)=>currentSubmission?.answers[index]!=null&&item)
                const remaining=get(data, "questions", []).filter((item,index)=>currentSubmission?.answers[index]==null&&item)
                 const shuffled = remaining.map((question, i) => {
                    if (question?.options) {
                        const options = shuffleArray(question?.options?.map((option, index) => ({ value: option, oldIndex: index })));
                        return { ...question, oldIndex: i, options };
                    } else {
                        return { ...question, oldIndex: i };
                    }
                  });
                  const QuestionShuffle = shuffleArray(shuffled)
                  const completeShuffle = [...answeredArr, ...QuestionShuffle]
                //   console.log('answeredArr', answeredArr);
                  
                //   console.log('completeShuffle', completeShuffle);
                  setShuffledQuestions(completeShuffle);
            } else {
                const shuffled = get(data, "questions", []).map((question, i) => {
                    if (question?.options) {
                        const options = shuffleArray(question?.options?.map((option, index) => ({ value: option, oldIndex: index })));
                        return { ...question, oldIndex: i, options };
                    } else {
                        return { ...question, oldIndex: i };
                    }
                  });
                //   console.log('if ka else', shuffleArray(shuffled))
                  setShuffledQuestions(shuffleArray(shuffled));
            }
        } else {
            const shuffled = get(data, "questions", []).map((question, i) => {
                if (question?.options) {
                    const options = question?.options?.map((option, index) => ({ value: option, oldIndex: index }));
                    return { ...question, oldIndex: i, options };
                } else {
                    return { ...question, oldIndex: i };
                }
              });
            //   console.log('shuffled else', shuffled);
              
            setShuffledQuestions(shuffled);
        }
    }, [])

    useEffect(() => {
        if (currentSubmission) {
            if (currentSubmission.answers && currentSubmission.answers.length > 0 && selections.length == 0) {
                setSelections(currentSubmission.answers)
            }
        }
        if (step <= 0) {
            if (currentSubmission) {
                let currentSubLength = currentSubmission ? currentSubmission.answers?.length : 0
                currentSubLength = currentSubLength < data.questions.length ? currentSubLength : currentSubLength - 1

                if (currentSubmission && parseInt(currentSubmission.finishlater) >= 0 && currentSubmission.finishlater != null) {
                    setStep(parseInt(currentSubmission.finishlater))
                    forceUpdate()
                } else {
                    setStep(selectedQ ? question + 1 : currentSubmission ? currentSubLength : 0);
                }

            }
        }

    }, [currentSubmission])
    useEffect(() => {
        if (currentSubmission && Array.isArray(currentSubmission.pastSubmissions) && currentSubmission.pastSubmissions.length > 0) {
            const pastsub = currentSubmission.pastSubmissions.sort((a, b) => parseInt(b.submissions) - parseInt(a.submissions))[0]
            const hasSub = selections[step]
            const finishAnswer = currentSubmission.answers[step]
            if (pastsub) {
                // if (selections.length == 0) {
                //     setSelections(pastsub.answers)
                //     forceUpdate();
                // }
                if (!hasSub) {
                    if (finishAnswer) {
                        setSelections(currentSubmission.answers)
                        forceUpdate();
                    }
                    else if (pastsub.answers[step]) {
                        let _selection = selections;
                        _selection[step] = pastsub.answers[step]
                        setSelections(_selection)
                        forceUpdate();
                    }
                }
            }
        }

    }, [currentSubmission, step])

    const handleChange = useCallback((answer, type, question) => {
        let data = [];
        data = selections.length > 0 ? selections : []
        data[question] = {
            answer: answer,
            type: type,
            is_correct: true
        }
        setSelections(data);
        forceUpdate();
    });
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);
    useEffect(() => {
        return () => {
            if (!isMounted.current) {
                if (timmer > 0) {
                    updateAnswers(true, null, true)
                }
            }
        }
    }, [timmer, isMounted]);
    function checkIsCorrect(qId,selectedOption){
        let isCorrect = false
        if(data.questions[qId].type === "multipleChoice"){
            if(data.questions[qId].answer == selectedOption){
                isCorrect = true
            }
        }else{
            isCorrect = true;   
        }
        return isCorrect
    }

    const handleSelect = (question, answer, type) => {
        setQuestion(question);
        setSelectedQ(true);
        let data = [];
        data = selections.length > 0 ? selections : []
        data[question] = {
            answer: answer,
            type: type,
            is_correct:  checkIsCorrect(question,answer)
        }
        setSelections(data);  
        forceUpdate();
    };

    const gradeQuiz = () => {
        let total = 0;
        let correct = 0;
        let manualGrading = false
        let face_grade = 0
            try {
                map(data.questions, (e, i) => {
                    if (selections[i] && selections[i].type === 'string') {
                        total++;
                        if (selections[i].answer == data.questions[i].answer) {
                            correct += 1;
                        }
                    } else {
                        manualGrading = true;
                    }
                })
            } catch (error) {
                console.log(error, "error in calculating grade");
            }
            let grade = total > 0 ? ((correct / total) * 100).toFixed(0) : 0;
            grade = isCompleteInComplete ? 100 : grade;
            manualGrading = isCompleteInComplete ? false : manualGrading
            const pointPerQuestion = (max_point / total);
            face_grade = isPointSystem ? correct * pointPerQuestion : grade
            return ({ grade:Math.round(grade), manualGrading , face_grade:Math.round(face_grade) })
    };

    const sendNotifications = async (notificationData) => {
        try {
            const updateNotification = await dispatch(handleCreateNotification(notificationData))
            const messageCreate = {
                ...updateNotification,
                senderId: get(currentUser, 'id', ''),
                receiverId: get(notificationData, 'createdBy', ''),
                // classId: get(currentClass, 'id', ''),
                code: 'system_notification'
            }
            invokSend(messageCreate)
        } catch (error) {
            console.log(error, "error")
        }
    }

    const invokSend = (data) => {
        let socket = socketConnection ?? '';
        if (socket) {
            try {
                if (socket.readyState == 3) {
                    reCreateSocket()
                    setTimeout(() => {
                        socket.send(JSON.stringify(data))
                    }, 2000)
                } else {
                    socket.send(JSON.stringify(data))
                }
            } catch (error) {
                console.log(error, "error")
            }
        }
    }

    const updateAnswers = async (complete, finishAt, notRedirected) => {
        let isLate = false
        let _timmer = timmer;
        if (dueDate) {
            isLate = isBeforTime(dueDate, servertime, timezone);
        }
        if (complete && onFinish && typeof onFinish == "function") {
            onFinish();
        }

        if (!currentSubmission && complete) {
            const { grade, manualGrading , face_grade } = await gradeQuiz() ?? {}
            setLoading(true);
            setAssignmentCreationLoading(false)
            const submission_data = await dispatch(handleCreateSubmission({
                assignmentId: data.id,
                assignmentType: data.typeId,
                classId: currentClass.id,
                orgId: currentOrg?.id,
                userId: currentUser?.id,
                chapter: chapter,
                grade: isCompleteInComplete ? '100' : grade,
                face_grade: isCompleteInComplete ? max_point : face_grade,
                answers: selections,
                complete: complete,
                finishLater: finishAt >= 0 ? finishAt : null,
                submissions: 1,
                retakes: retakes,
                retaking: false,
                graded: false,
                timmer: _timmer,
                isLate,
                manualGrading
            }))
            if(submission_data&&submission_data.id){
                dispatch(handleAiFeedback(submission_data.id, data?.language_feedback));
            }
            setLoading(false)
            setCompleted(true);
            setAssignmentCreationLoading(false)
            const notificationData = {
                classId: get(currentClass, 'id', ''),
                displayName: get(data, 'displayName', ''),
                userId: get(currentUser, 'id', ''),
                modified: new Date(),
                data: {
                    chapter: chapter,
                    page: page,
                    id: get(data, 'id', ''),
                },
                message: `${get(currentUser, 'firstName', '')} ${get(currentUser, 'lastName', '')} Submit this Assignment`,
                createdBy: get(currentClass, 'teacherId', ''),
                type: 'update_activity',
                seen: true
            }
            if (redirectTo) {
                history.replace(redirectTo)
                return;
            }
            const urlstring = `${history.location.pathname}${history.location.search}`.replace(/quiz=[0-9a-zA-Z-]*/g, 'quiz=null').replace(/activity=[0-9a-zA-Z-]*/g, 'activity=null')
            setTimeout(() => history.replace(urlstring), wait_timmer)
            sendNotifications(notificationData);
        } else if (!currentSubmission && !complete) {
            setAssignmentCreationLoading(true)
            let quizdata = {
                assignmentId: data.id,
                assignmentType: data.typeId,
                classId: currentClass.id,
                orgId: currentOrg?.id,
                userId: currentUser?.id,
                chapter: chapter,
                grade: isCompleteInComplete?'100':'',
                face_grade: isCompleteInComplete ? max_point : '',
                answers: selections,
                complete:complete,
                submissions: 0,
                retakes: retakes,
                finishLater: finishAt >= 0 ? finishAt : null,
                graded: false,
                retaking: true,
                feedback: '',
                timmer: _timmer,
                isLate,
                assignment:data
            }
            // const {isValid,reason}=validateData(quizdata,["assignmentId","assignmentType","classId","orgId","userId","chapter"])
            await dispatch(handleCreateSubmission(quizdata))
            setAssignmentCreationLoading(false)
            // console.log('resume sdfds', complete);
        } else {
            if (complete && get(currentSubmission, 'returned', '') > 0) {
                finalSubmit(_timmer, notRedirected)
                const notificationData = {
                    classId: get(currentClass, 'id', ''),
                    displayName: get(data, 'displayName', ''),
                    userId: get(currentUser, 'id', ''),
                    modified: new Date(),
                    data: {
                        chapter: chapter,
                        page: page,
                        id: get(data, 'id', ''),
                    },
                    message: `${get(currentUser, 'firstName', '')} ${get(currentUser, 'lastName', '')} Re-Submited this Assignment`,
                    createdBy: get(currentClass, 'teacherId', ''),
                    type: 'update_activity',
                    seen: true
                }
                sendNotifications(notificationData);
            } else if(complete && get(currentSubmission, 'submissions', '') > 0){
                finalSubmit(_timmer, notRedirected)
                const notificationData = {
                    classId: get(currentClass, 'id', ''),
                    displayName: get(data, 'displayName', ''),
                    userId: get(currentUser, 'id', ''),
                    modified: new Date(),
                    data: {
                        chapter: chapter,
                        page: page,
                        id: get(data, 'id', ''),
                    },
                    message: `${get(currentUser, 'firstName', '')} ${get(currentUser, 'lastName', '')} Retake this Assignment`,
                    createdBy: get(currentClass, 'teacherId', ''),
                    type: 'update_activity',
                    seen: true
                }
                sendNotifications(notificationData);
            } else if (complete) {
                finalSubmit(_timmer, notRedirected)
                const notificationData = {
                    classId: get(currentClass, 'id', ''),
                    displayName: get(data, 'displayName', ''),
                    userId: get(currentUser, 'id', ''),
                    modified: new Date(),
                    data: {
                        chapter: chapter,
                        page: page,
                        id: get(data, 'id', ''),
                    },
                    message: `${get(currentUser, 'firstName', '')} ${get(currentUser, 'lastName', '')} Submit this Assignment`,
                    createdBy: get(currentClass, 'teacherId', ''),
                    type: 'update_activity',
                    seen: true
                }
                sendNotifications(notificationData);
            } else {
                setAssignmentCreationLoading(true)
                await dispatch(handleUpdateSubmission(
                    currentSubmission.id,
                    {
                        answers: selections,
                        complete:complete,
                        grade: isCompleteInComplete?100:currentSubmission.grade,
                        face_grade:currentSubmission.face_grade,
                        submissions: currentSubmission.submissions,
                        retaking: true,
                        graded: false,
                        feedback: '',
                        timmer: _timmer,
                        finishLater: finishAt >= 0 ? finishAt : null,
                        isLate,
                        assignment:data
                    }
                ));
                setAssignmentCreationLoading(false)
            }
        }
        if(isCompleteInComplete){
            updateGradeToCanvas('complete')
        }
    };
    const finalSubmit = async (_timmer, notRedirected) => {
        onFinish && onFinish()
        if (isInSubmit) return;
        isInSubmit = true;
        let isLate = false
        if (dueDate) {
            isLate = isBeforTime(dueDate, servertime, timezone);
        }
        let complete = true
        setLoading(true);
        const { grade, manualGrading , face_grade } = await gradeQuiz(complete, currentSubmission) ?? {}
        let submi = isNaN(currentSubmission?.submissions) ? 0 : parseInt(currentSubmission?.submissions, 10)
        ++submi;
        await canvasSubmissionCreate()
        if (typeof grade ==="number" && grade >= 0) {
            const v =  isPointSystem?face_grade:grade
            updateGradetoSchoology(v, 0)
            updateGradeToCanvas(v)
            updateGradetoGoogleClassRoom(v)
        }
        setAssignmentCreationLoading(true)
        await dispatch(handleUpdateSubmission(
            currentSubmission.id,
            {
                answers: selections,
                complete: complete,
                grade: isCompleteInComplete?'100':grade,
                submissions: submi,
                retaking: false,
                graded: data.typeId === 'quiz' ? true : false,
                feedback: '',
                timmer: 0,
                manualGrading,
                isLate,
                face_grade,
                assignment:data
            }
        ));
        dispatch(handleAiFeedback(currentSubmission.id, data?.language_feedback));

        setAssignmentCreationLoading(false)
        setTimeout(() => { isInSubmit = null }, wait_timmer)
        setLoading(false)
        setCompleted(true);
        if (redirectTo && !notRedirected) {
            history.replace(redirectTo)
            return;
        }
        const urlstring = `${history.location.pathname}${history.location.search}`.replace(/quiz=[0-9a-zA-Z-]*/g, 'quiz=null').replace(/activity=[0-9a-zA-Z-]*/g, 'activity=null')
        setTimeout(() => history.replace(urlstring), wait_timmer)
    }
    const [subCheck, setSubCheck] = useState(false);
    const noAudiosRef = useRef(false)
    const subCheckRef = useRef(false)
    const submissionCheck = async () => {
        if (data?.questions && data?.questions[0]?.type !== 'audio' && data?.questions[0]?.type !== 'twoMinAudio') {
            subCheckRef.current = true
        } else if (noAudiosRef.current) {
            subCheckRef.current = false
        } else {
            subCheckRef.current = true
        }
    }

    submissionCheck();
    useEffect(() => {
        if (subCheckRef.current) {
            if (!subCheck)
                setSubCheck(true)
        } else if (noAudiosRef.current) {
            if (subCheck)
                setSubCheck(false)
        } else {
            setSubCheck(!subCheck)
        }
    }, [noAudiosRef.current, subCheckRef.current])
    useEffect(() => {
        const isFirefox = navigator.userAgent.indexOf("Firefox") > -1;
        if (navigator.permissions && !isFirefox) {
            navigator.permissions.query(
                { name: 'microphone' }
            ).then(function (permissionStatus) {
                if (permissionStatus.state == "denied") {
                    noAudiosRef.current = true
                }
                permissionStatus.onchange = function () {
                    if (this.state == "denied") {
                        noAudiosRef.current = true
                    } else {
                        noAudiosRef.current = false
                    }

                }

            })
        }
        return () => {
            onFinish && onFinish()
        }
    }, [])
    useEffect(() => {
        if (setSubmitRef) {
            setSubmitRef(updateAnswers)
        }
    }, [setSubmitRef])
    const disabledCheck = useCallback((index) => {
        try {
            let question = data?.questions[index]
            if (data?.questions?.length > 1) {
                if (question?.type === 'essay') {
                    return textInputDisableCheck(index)
                }
                return selections.length < index + 1;
            } else {
                if (question?.type === 'essay') {
                    return textInputDisableCheck(index)
                }
                return selections.length < 1
            }
        } catch (error) {
            console.log(error, 'error in disabledCheck');
        }
    }, [selections, data?.questions])
    const textInputDisableCheck = useCallback((index) => {
        try {
            let question = data?.questions[index]
            let answered = selections ? selections[index] : {}
            let hasValue = false;
            if (question?.type === 'essay') {
                if (Array.isArray(answered?.answer)) {
                    map(answered?.answer, item => {
                        if (hasValue) return;
                        map(item.children, itm => {
                            if (hasValue) return;
                            if (itm.text && itm.text.length > 0) {
                                hasValue = true
                            } else if (itm.children) {
                                map(itm.children, itms => {
                                    if (itms.text && itms.text.length > 0) {
                                        hasValue = true;
                                    }
                                })
                            }
                        })
                    })
                    return !hasValue
                }
                else if (typeof answered?.answer === 'string') {
                    if(typeof answered?.answer === 'string') {
                        let resp = answered.answer.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').trim();
                        if(resp.length > 0) {
                            return false;
                        }else {
                            return true;
                        }
                    }else{
                        return true; 
                    }
                }
                else {
                    return true;
                }
            }
        } catch (error) {
            console.log(error, "error in textInputDisableCheck");
        }
    }, [selections])
    const updateGradeToCanvas = async (_val) => {
        if (currentUser && currentUser.canvas_id && currentClass.canvas_id) {
            let assignmentId = assignment?.canvas_id ||  assignment?.data?.canvas_id
                await handleUpdateCanvasGrade(currentUser.canvas_id,{
                    "canvas_assignment_id": assignmentId,
                    "course_id":currentClass.canvas_course_id,
                    "canvas_user_id":currentUser.canvas_id,
                    "grade": isCompleteInComplete?'complete': _val
                },currentUser.canvas_auth_id)
        }
    }
    const canvasSubmissionCreate = async () => {
        if (currentUser && currentUser.canvas_id && currentClass.canvas_id) {
            let assignmentId = assignment?.canvas_id ||  assignment?.data?.canvas_id
                await handleCreateCanvaSubmissions(currentUser.canvas_id,{
                    "canvas_assignment_id": assignmentId,
                    "course_id":currentClass.canvas_course_id,
                    "canvas_user_id":currentUser.canvas_id,
                },currentUser.canvas_auth_id)
        }
    }
    const updateGradetoSchoology = (_val, statusCode) => {
        if (currentClass && currentClass.sectionId && currentUser && currentUser.schoologyid) {
            try {
                client.getEnrollmentsBySectionId(currentClass?.sectionId).then(async res => {
                    if (res && res.enrollment) {
                        let userEnrollment = find(res.enrollment, { uid: currentUser.schoologyid });
                        if (!assignment.sAssignmentId) {
                            const _assignments = await client.getSectionAssignment(currentClass?.sectionId);
                            const all_assignment = await client.getSectionAssignmentWithLimit(currentClass?.sectionId, _assignments.total);
                            const assignmentf = find(all_assignment?.assignment, { title: assignment.displayName });
                            if (assignmentf) {
                                assignment.sAssignmentId = assignmentf.id;
                            }
                        }
                        if (userEnrollment && assignment?.sAssignmentId) {
                            client.getSectionAssignmentById(currentClass?.sectionId, assignment.sAssignmentId).then(res => {
                                if (res && res.id) {
                                    let _data = {
                                        "grades": {
                                            "grade": [
                                                {
                                                    "type": "assignment",
                                                    "assignment_id": assignment.sAssignmentId,
                                                    "enrollment_id": userEnrollment.id,
                                                    "grade": _val,
                                                    "exception": statusCode || 0,
                                                    "comment": "grade updated from lausd-grade-book"
                                                }

                                            ]
                                        }
                                    }
                                    client.updateGrades(currentClass?.sectionId, _data).then(res => {
                                        console.log("updated grade", res);
                                    })
                                }
                            })
                        }
                    }
                })
            } catch (error) {
                console.log(error, "issue in update grade");
            }
        }
    }
    const updateGradetoGoogleClassRoom = async (_val) => {
        if (currentClass && currentClass.googleId && currentUser && currentUser.googleId) {
            try {
                let assignmentId = assignment?.googleId || assignment?.data?.googleId
                await handleUpdateGradeToGoogleClassRoom(currentUser.googleId, currentUser.googleId, currentClass.googleId, assignmentId, _val)
            } catch (error) {
                console.log(error, "issue in update grade to classroom");
            }
        }
    }
    const finishlaterClick = async () => {
        if (assignmentCreationLoading) return;
        if (timmer > 0) {
            setFinishLaterAlert({ open: true, message: 'This functionality is blocked for time-based activities.' })
        } else {
            updateAnswers(false, step)
            onClose()
        }
    }
    const shuffleArray = (array) => {
        // console.log('array', array);
        
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    return (
        <Wrapper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={finishlaterAlert.open}
                autoHideDuration={3000}
                onClose={() => { setFinishLaterAlert({ open: false, message: '' }) }}>
                <Alert onClose={() => { setFinishLaterAlert({ open: false, message: '' }) }} severity="error">
                    <strong>ALERT:</strong> {finishlaterAlert.message}
                </Alert>
            </Snackbar>
            <Container active={step > -1}>
                <Section>
                    <Row>
                        <SectionHeader>Instructions</SectionHeader>
                    </Row>
                    {pageInteractions?.length > 0 && (
                        <Section className="addAudio" style={{ marginBottom: 24 }}>
                            {map(pageInteractions, action => (
                                <Interaction key={action.id} data={action} />
                            ))}
                        </Section>
                    )}
                    <Row align="middle" gutter={[0, 16]}>
                        <Col span={14}>
                            <AssignmentTitle>{_.get(assignment, "displayName", _.get(data, "displayName", ''))}</AssignmentTitle>
                        </Col>
                    </Row>
                    {data?.description && (
                        <Row gutter={[0, 24]}>
                            <Col span={24}>
                                <AssignmentDescription>{data?.description}</AssignmentDescription>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col span={24}>
                            <BarContainer>
                                {map(get(data, "questions", []), (x, index) => (
                                    <Bar key={index} complete={step > index - 1} />
                                ))}
                            </BarContainer>
                        </Col>
                    </Row>
                </Section>
            </Container>
            {map(shuffledQuestions, (question, index) => {
                if (!question || step >= data?.questions.length) {
                    if (step + 1 < data.questions.length) {
                        setStep(step + 1)
                    } else {
                        setStep(0)
                    }
                    return;
                }
                return (
                    <RelativeContainer key={index}>
                        {step === index && <QuestionContainer key={question.id} active={step === index}>
                            <Detail>Question {index + 1} of {data?.questions.length}</Detail>
                            <Question>{question.question}</Question>
                            <Content>
                                {question.type === 'email' && (
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <TextEditor
                                                handleChange={data => handleChange(data, 'email', question?.oldIndex)}
                                                value={!!selections[question?.oldIndex]?.answer ? selections[question?.oldIndex]?.answer : ""}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {question.type === 'essay' && (
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <TextEditor
                                                handleChange={data => handleChange(data, 'essay', question?.oldIndex)}
                                                value={!!selections[question?.oldIndex]?.answer ? selections[question?.oldIndex]?.answer : ""}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {question.type === 'multipleChoice' && (
                                    <>
                                        {map(get(question, "options", []), (option, i) => {
                                            if (!option || !option.value) return null;
                                            // console.log('option', option)
                                            return (
                                                <Row key={i} style={{ marginBottom: '8px' }}>
                                                    <Option selected={selections[question?.oldIndex] && selections[question?.oldIndex].answer === `${option?.oldIndex}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        handleSelect(question?.oldIndex, `${option?.oldIndex}`, 'string', selections)
                                                    }}>{option?.value}</Option>
                                                </Row>
                                            )
                                        })}
                                    </>
                                )}
                                {question.type === 'audio' && (
                                    <>
                                        <ReactPlayer
                                            url={question.audio}
                                            height="40px"
                                            width="100%"
                                            controls
                                            playsinline
                                            onPlay={() => {

                                            }}
                                            onPause={() => {

                                            }}
                                            onEnded={() => { }}
                                            style={{ marginBottom: '8px' }}
                                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                        />
                                        <AudioRecorder
                                            ref={audioRef}
                                            maxTime={question.duration || 20}
                                            handleAudio={url => handleSelect(question?.oldIndex, url, 'audio')}
                                            index={index}
                                            currentSubmission={currentSubmission}
                                            refresh={() => history.go(0)}
                                        />
                                    </>
                                )}
                                {question.type === 'twoMinAudio' && (
                                    <>
                                        <AudioRecorder
                                           maxTime={question.duration || 120}
                                            handleAudio={url => handleSelect(question?.oldIndex, url, 'audio')}
                                            refresh={() => history.go(0)}
                                        />
                                    </>
                                )}
                            </Content>
                            {subCheck && (
                                <Row align="middle" justify="space-between" style={{ marginTop: '16px' }}>
                                    {step > 0 && (
                                        <ButtonLink onClick={() => {
                                            setStep(step - 1)
                                        }}>
                                            <ArrowLeftOutlined style={{ height: '10px', marginRight: '8px' }} />
                                        </ButtonLink>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={disabledCheck(question?.oldIndex)}
                                        style={{ cursor: assignmentCreationLoading ? "progress" : "pointer" }}
                                        onClick={() => {
                                            if (assignmentCreationLoading) return;
                                            if (data?.questions.length === index + 1) {
                                                if (userRol === 'student') {
                                                    updateAnswers(true);
                                                }
                                            } else {
                                                if (selections[index] && (currentSubmission?.answers[index]?.answer !== selections[index]?.answer)) {
                                                    if (userRol === 'student') {
                                                        updateAnswers(false);
                                                    }
                                                    setStep(step + 1);
                                                } else {
                                                    if (data?.questions.length === index + 1) {
                                                        onClose();

                                                    }
                                                    else {
                                                        setStep(step + 1);
                                                    }
                                                }
                                            }
                                        }}
                                        type='submit'
                                    >
                                        {loading || assignmentCreationLoading ? (
                                            <Loading size={20} white />
                                        ) : (
                                            <>
                                                {!completed && data?.questions.length === index + 1 ? 'Submit Assignment' : 'Next Question'}
                                                {completed && 'Submitted'}
                                                {!completed && data?.questions.length !== index + 1 && (
                                                    <ArrowForward style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                                                )}
                                                {data?.questions.length === index + 1 && (
                                                    <Done style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                                                )}
                                            </>
                                        )}
                                    </Button>
                                </Row>
                            )}
                            {/* {timmer > 0 ? <Typography
                                variant='subtitle1'
                                align='center'
                                style={{ color: COLOR.redishpink , fontSize:12, lineHeight:'12px', marginTop: '16px'}}
                            >
                               Timer-based assignments can’t be “finished later”, it will be submitted automatically for you if you click on it. 
                            </Typography> : null} */}
                            <Button
                                color={"secondary"}
                                onClick={finishlaterClick}
                                type='submit'
                                id={"finish_later"}
                                style={{ width: '100%', marginBottom: "20px", cursor: timmer > 0 ? "not-allowed" : "pointer" }}
                            >
                                {userRol === 'student' ? 'Finish Later' : 'Close Preview'}

                            </Button>

                        </QuestionContainer>}
                    </RelativeContainer>
                )
            })}
            {completed && (
                <Wrap>
                    <CheckCircle style={{ color: COLOR.green100, fontSize: '80px', marginBottom: '24px' }} />
                    <Success>Assignment Submitted!</Success>
                </Wrap>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
Assignment.propTypes = {
    data: shape({
        chapter: string,
        description: string,
        display_name: string,
        page: string,
        questions: arrayOf(shape({
            id: string,
            options: arrayOf(string),
            question: string,
            type: string,
        })),
        retakes: string,
    }),
    onSubmit: func,
};

//  -- Default props ------------------
Assignment.defaultProps = {
    data: {}
};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    interactions: state.interactions,
    schoologyAuth: state.schoologyAuth
});

export default connect(mapStateToProps, null)(Assignment);
