import React, { useEffect, useState } from 'react'
import { Button, Modal, TextField, Tooltip } from '@material-ui/core';
import { sendInvitationEmail } from 'store/email/thunks';
import styled from 'styled-components';
import { message, Row, Col } from "antd"
import COLOR from "utils/constants/color"
import Loading from 'components/Loading';
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

export default function ParentInvitationModal({ open, selectedUser, currentUser, onClose }) {
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: ''
    })
    const [loader,setLoader] = useState(false)
    const onSubmitInvitation = async () => {
        if (data.email) {
            const re = /\S+@\S+\.\S+/;
            if (!re.test(data.email)) {
                message.error('Please enter a valid email address');
                return;
            } else {
                setLoader(true)
                try {
                    const send_resp =  await sendInvitationEmail({
                        to:data.email,
                        studentName:`${selectedUser?.firstName ||''} ${selectedUser?.lastName ||''}`,
                        teacherName:`${currentUser.firstName ||''} ${currentUser.lastName ||''}`, 
                        student_id:selectedUser.id, 
                        teacher_id:currentUser.id, 
                        firstName:data.firstName, 
                        lastName:data.lastName, 
                        orgId:currentUser.orgId
                    })
                    if(send_resp && send_resp.success){
                        onClose()
                        setData({
                            firstName: '',
                            lastName: '',
                            email: ''
                        })
                        message.success(`Invitation sent successfully`)
                    }else{
                        if(send_resp&&send_resp.error){
                        message.error(send_resp.message)
                        }else{
                            message.error(`Something went wrong`)
                        }
                        console.log(send_resp.message,"aaaa")
                    } 
                } catch (error) {
                    message.error(`Something went wrong`)
                }
              
                setLoader(false)
            }
        }
        
    }
    useEffect(() => {
        if (open) {
            setData({
                firstName: '',
                lastName: '',
                email: ''
            })
        }
    }, [open])
    const onChangeData = (key, value) => {
        let dt = { ...data }
        dt[key] = value
        setData(dt)
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContent>
                <ModalRow style={{ marginBottom: 12 }}>Enter details to invite parent for {selectedUser.firstName || ''} {selectedUser.lastName || ''}.</ModalRow>
                <Row style={{ marginBottom: 12 , width: "100%"  }} >
                    <TextField
                        autoComplete="off"
                        id="firstName"
                        defaultValue={' '}
                        required
                        autoFocus
                        className='invite_parent'
                        value={data.firstName}
                        onChange={e => onChangeData(e.target.id, e.target.value)}
                        label="First Name"
                        variant="outlined"
                    />
                </Row>
                <Row style={{ marginBottom: 12 , width: "100%"  }} >
                <TextField
                        autoComplete="off"
                        id="lastName"
                        defaultValue={' '}
                        required
                        className='invite_parent'
                        value={data.lastName}
                        onChange={e => onChangeData(e.target.id, e.target.value)}
                        label="Last Name"
                        variant="outlined"
                    />
                </Row>
                <Row style={{ marginBottom: 12, width: "100%" }}>
                    <TextField
                        id="email"
                        label="Enter email"
                        variant="outlined"
                        className='invite_parent'
                        value={data.email}
                        onChange={e => onChangeData(e.target.id, e.target.value)}
                    />
                </Row>

                <ModalRow>
                    <Button
                        style={{ marginRight: '4px' }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onSubmitInvitation}
                         startIcon={loader?<Loading size={14} white />:null}
                        disabled={!data.email || !data.firstName || !data.lastName}
                    >
                        Send 
                    </Button>
                </ModalRow>
            </ModalContent>
        </Modal>
    )
}
