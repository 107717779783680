//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Modal, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Check, Close } from "@material-ui/icons";
import { map } from "lodash";
import Lottie from 'lottie-react';
import React, { useEffect, useState } from "react";
import { useSocket } from "store/chat/selectors";
import { useCurrentUser, useProfilePicturePicker, useUserProfileConfig } from 'store/users/selectors';
import styled from 'styled-components';
import {
    TransparentLogoWhite
} from 'utils/assets/images';
import { PROFILE_AVATARS } from "utils/assets/images/lootie";
import COLOR from 'utils/constants/color';
import Loading from "./Loading";

const UserModal = styled.div`
    transform: translate(-50%, -50%);
    max-width: 950px;
    padding: 20px;
    outline: none; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    @media screen and (max-width: 768px){
        max-width: 95vw;
    }
`;
const UserPopUpArea = styled.div`
    background-color: rgb(82,84,82);
    border-radius: 10px;
    padding: 28px;
    position:relative;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px){
        padding: 15px;
    }
`;
const CloseBtn = styled.div`
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    z-index: 1;
`;
const UserArea = styled.div`
    display:flex;
    flex-direction:column;
    overflow:auto;
    overflow-x:auto;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    align-items: center;
    justify-content: center;
`;
const UserWrapper = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper = styled.div`
    cursor: pointer;
    border: ${p => p.active ? ` 2px solid green` : 'none'};
    padding: 4px;
    flex-direction: row;
    padding: 2px;
    border-radius: 50%;
    margin:${p => p.active ? ` 18px` : '20px'};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    position:relative;
    @media screen and (max-width: 768px){
        margin:${p => p.active ? ` 4px` : '6px'};
    }
`;
const Logo = styled.img`
    height: auto;
    width: 100px;
`;
const LogoBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width:100%;
`;
const LoaderWrapper = styled.div`
    position:absolute;
    z-index:100;
    top:50%;
    left:40%;
`;
const ImageRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;
const useStyles = makeStyles((theme) => ({
    titleText: {
        fontWeight: 400,
        color: 'rgb(255, 255, 255)',
        fontSize: 20,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 17
    }
}));
const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 700px; // Set max-width here
    background-color: white; // Assuming you want a white background
    padding: 20px; // Add some padding
    box-shadow: 24; // Material-UI's recommended shadow for modals
    outline: 'none'; // Remove outline to avoid focusing style
`;

export const ProfileAvatarPicker = ({ }) => {
    const { profile_picture_picker, hideProfilePhotoViewer } = useProfilePicturePicker()
    const [avatarSelected, setAvatarSelected] = useState(null)
    const { profile_config, updateserProfileConfig } = useUserProfileConfig()
    const [loader, setLoader] = useState(false)
    const cssClass = useStyles()
    const { socketConnection } = useSocket()
    const currentUser = useCurrentUser()
    useEffect(() => {
        if (profile_config && profile_config.config) {
            setAvatarSelected(profile_config.config.profile_pic)
        }

    }, [profile_config])
    const updateAvatar = async (avatar) => {
        setLoader(true)
        setAvatarSelected(avatar)
        const { chat_config } = await updateserProfileConfig({ profile_pic: avatar })
        if (socketConnection && chat_config) {
            try {
                socketConnection.send(JSON.stringify({ type: "config_update", code: 'config_update', config: chat_config, id: currentUser.id }))
            } catch (error) {
                console.log(error, "socket send update error")
            }
        }
        setTimeout(hideProfilePhotoViewer, 1000)
        setLoader(false)
    }



    const addShadowLottie = [
        'drop-shadow(10px 10px 5px #5cc6e0)', 
        'drop-shadow(10px 10px 5px #FF0000)',
        'drop-shadow(10px 10px 5px #9345a8)',
        'drop-shadow(10px 10px 5px #436e2a)',
        'drop-shadow(10px 10px 5px #39b002)',
        'drop-shadow(10px 10px 5px #f79934)',
        'drop-shadow(10px 10px 5px #f2498d)',
        'drop-shadow(10px 10px 5px #43345c)',
        'drop-shadow(10px 10px 5px #ed2926)',
        'drop-shadow(10px 10px 5px #3d9acc)',
        'drop-shadow(10px 10px 5px #19827f)',
        'drop-shadow(10px 10px 5px #f2eb1d)',
      ];
      

    
    return (<>
        <Modal
            open={profile_picture_picker}
            onClose={hideProfilePhotoViewer}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "transparent" }}
            className="avtarModl">
            <UserModal className="avtarUsrModl">
                <UserPopUpArea className="avtarUsrArea">
                    <Typography variant="h4" className={cssClass.titleText}>Please select your avatar:</Typography>
                    <CloseBtn onClick={hideProfilePhotoViewer}>
                        <Close style={{ color: COLOR.white, fontWeight: "bolder", fontSize: 25 }} />
                    </CloseBtn>
                    <UserArea className="avtarUsrImg">
                        {/* <div className='avatarConatiner'>
                            {map(PROFILE_AVATARS, (avatar) => {
                                return(
                                <div
                                    key={avatar.key} // add unique key here
                                    className={`addShadowLottie ${avatar.key === avatarSelected ? 'addShadowLottieSelected' : ''
                                        }`}
                                    style={{ height: 135, width: 135, cursor: 'pointer', position: 'relative' }}
                                    onClick={() => updateAvatar(avatar?.key)}
                                >
                                    {avatar.key === avatarSelected ? <Check color="primary" style={{ position: "absolute", right: 0, top: 5, background: "#fff", borderRadius: "50%", zIndex: 999 }} /> : null}
                                    {loader && avatar.key == avatarSelected ? <LoaderWrapper> <Loading size={25} /> </LoaderWrapper> : null}
                                    <Lottie animationData={avatar?.poster} />
                                </div>
                                )
                            })}
                        </div> */}
                        <div className='avatarConatiner'>
                        {PROFILE_AVATARS.map((avatar, index) => (
                            <div
                            key={avatar.key}
                            className={`addShadowLottie-${index} ${avatar.key === avatarSelected ? 'addShadowLottieSelected' : ''}`}
                            style={{
                                height: 135,
                                width: 135,
                                cursor: 'pointer',
                                position: 'relative',
                                filter: addShadowLottie[index]
                            }}
                            onClick={() => updateAvatar(avatar.key)}
                            >
                            {avatar.key === avatarSelected ? <Check color="primary" style={{ position: "absolute", right: 0, top: 5, background: "#fff", borderRadius: "50%", zIndex: 999 }} /> : null}
                            {loader && avatar.key === avatarSelected ? <LoaderWrapper> <Loading size={25} /> </LoaderWrapper> : null}
                            <Lottie animationData={avatar.poster} />
                            </div>
                        ))}
                        </div>
                    </UserArea>
                    <LogoBottomRow>
                        <Logo src={TransparentLogoWhite} />
                    </LogoBottomRow>
                </UserPopUpArea>
            </UserModal>
        </Modal>
    </>)
}