import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    makeStyles
} from "@material-ui/core";
import {
    Add,
    Close,
    Delete
} from "@material-ui/icons";
import { Row } from "antd";
import { DeleteModal } from "components/Dialog";
import Loading from "components/Loading";
import _, { cloneDeep, get, has, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from 'react-player';
import { useDispatch } from "react-redux";
import { handleCreateInteractions, handleDeleteInteractions, handleUpdateInteractions } from "store/interactions/thunks";
import styled from "styled-components";
import AudioPicker from "../AudioPicker";
import VideoPicker from "../VideoPicker";

const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px; 
    @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;

const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    // margin-left:5px;
    // margin-top:5px;
`;
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const IntrectionModal = ({ data, close, pageId, bookId, open, setAlertSakeBar }) => {
  const [interaction, setIntrection] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [seletedItem, setSeletedItem] = useState(null)
  const [changeItemIndex, setChangeItemIndex] = useState([])
  const [error, setError] = useState(false)
  const scrollRef = useRef();
  const dispatch = useDispatch()
  const classes = useStyles();
  useEffect(() => {
    if (data && data.length > 0) {
      setIntrection(data)
    } else {
      setIntrection([{
        chapter: bookId,
        bookId,
        page: pageId,
        content: '',
        "typeId": 'video'
      }])
    }
  }, [data, bookId, pageId])
  useEffect(() => {
    if (!open) {
      setSeletedItem(null)
      setChangeItemIndex([])
    }
  }, [open])
  const onUpdate = async () => {
    setError(false)
    setLoading(true)
    const toUpdate = _.filter(interaction, (k, i) => !k.id || changeItemIndex.indexOf(k.id) !== -1)
    const res = await Promise.allSettled(map(toUpdate, async intre => {
      if (has(intre, "id")) {
        const intr = await dispatch(handleUpdateInteractions(intre))
        return intr;
      } else {
        const intr = await dispatch(handleCreateInteractions(intre))
        return intr;
      }
    }))
    setAlertSakeBar({
      open: true,
      message: "Intrection updated successfully!",
      severity: "success"
    })
    close()
    // if (data) {
    //   const intr = await dispatch(handleUpdateInteractions(interaction))
    //   if (has(intr, "id")) {
    //     setAlertSakeBar({
    //       open: true,
    //       message: "Intrection updated successfully!",
    //       severity: "success"
    //     })
    //     close()
    //   } else {
    //     setAlertSakeBar({
    //       open: true,
    //       message: "Error while updating Intrection!",
    //       severity: "error"
    //     })
    //   }
    // } else {
    //   const intr = await dispatch(handleCreateInteractions(interaction))
    //   if (has(intr, "id")) {
    //     setAlertSakeBar({
    //       open: true,
    //       message: "Intrection created successfully!",
    //       severity: "success"
    //     })
    //     close()
    //   } else {
    //     setAlertSakeBar({
    //       open: true,
    //       message: "Error while creating Intrection!",
    //       severity: "error"
    //     })
    //   }
    // }
    setLoading(false)
  }
  const onDelete = async () => {
    if (!seletedItem) return;
    setDeleteLoader(true)
    const intr = await dispatch(handleDeleteInteractions(get(seletedItem, "id")));
    if (has(intr, "id")) {
      setAlertSakeBar({
        open: true,
        message: "Intrection deleted successfully!",
        severity: "error"
      })
      close()
    } else {
      setAlertSakeBar({
        open: true,
        message: "Error while deleting Intrection!",
        severity: "error"
      })
    }
    setDeleteLoader(false)
    setDeleteModal(false)
    setSeletedItem(null)
  }
  const onChange = (index, key, value) => {
    const item = cloneDeep(interaction)
    const changeItem = cloneDeep(changeItemIndex)
    if (!changeItem.includes(item[index].id)) {
      changeItem.push(item[index].id)
    }
    setChangeItemIndex(changeItem)
    item[index][key] = value;
    setIntrection(item)
  }
  const onAddIntrection = () => {
    const item = cloneDeep(interaction)
    item.push({
      chapter: bookId,
      bookId,
      page: pageId,
      content: '',
      "typeId": 'audio'
    })
    setIntrection(item)
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
      }
    }, 500);
  }
  const onRemove = (i) => {
    const item = cloneDeep(interaction)
    item.splice(i, 1)
    setIntrection(item)
  }
  return (
    <Modal
      open={open}
      onClose={close}
      style={{ height: "100%", width: "100%" }}
    >
      <ModalContent style={{ width: "70vw" }}>
        <DeleteModal
          deleteToggle={deleteModal}
          onDelete={onDelete}
          loading={deleteLoader}
          setDeleteToggle={setDeleteModal}
          message={`Are you sure to remove intrections on  pageId ${pageId}`} />

        <Row style={{ justifyContent: "space-between" }}>
          <TitleText>
            Interactions
          </TitleText>
          <IconButton
            onClick={close}
            style={{ padding: 0 }}
          >
            <Close color="secondary" />
          </IconButton>
        </Row>
        <ModalConfigArea ref={scrollRef}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={onAddIntrection}
          >
            Add Intreaction
          </Button>
          {map(interaction, (intr, i) => (
            <LinkArea key={intr.id + i}>
              <Row
                gutter={[24, 24]}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <IconButton
                  onClick={() => {
                    if (has(intr, "id")) {
                      setDeleteModal(true)
                      setSeletedItem(intr)
                    } else {
                      onRemove(i)
                    }
                  }}
                  style={{ padding: 0, marginRight: 10 }}
                >
                  <Delete color="secondary" />
                </IconButton>
              </Row>
              <Row
                gutter={[24, 24]}
                style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="typeId" style={{ backgroundColor: "#fff" }}>Type</InputLabel>
                  <Select
                    labelId="typeId"
                    id="typeId"
                    value={`${get(intr, "typeId")}`}
                    onChange={(e) => {
                      onChange(i, "typeId", e.target.value)
                    }}
                  >
                    {map(['video', 'audio'], (bk) => (
                      <MenuItem key={bk} value={bk}>{bk}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Row>
              <Row style={{ width: "100%", marginTop: 16, justifyContent: "center" }}>
                {get(intr, "typeId") == "video" ? (
                  <VideoPicker
                    style={{ height: "auto" }}
                    hasDescription={false}
                    path={"/video/intraction"}
                    src={get(intr, "content")}
                    onChange={item => {
                      onChange(i, "content", item.url)
                    }} />) :
                  (<>
                    <AudioPicker
                      style={{ justifyContent: "center" }}
                      onChange={item => {
                        onChange(i, "content", item)
                      }}
                    />
                    <ReactPlayer
                      url={get(intr, "content")}
                      height="40px"
                      width="100%"
                      controls
                      playsinline
                      style={{ marginBottom: '8px', marginLeft: 12, marginRight: 12 }}
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    />
                  </>
                  )}
              </Row>
            </LinkArea>
          ))}
        </ModalConfigArea>
        {/* {error ? (
            <FormHelperText error>
              Content  required.!
            </FormHelperText>
          ) : null} */}
        <Row
          gutter={[24, 24]}
          style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
        >
          <Button
            fullWidth
            onClick={onUpdate}
            color="primary"
            variant="contained"
          >
            {loading ? <Loading white size={14} /> : null}  {data ? "Update" : "Create"}
          </Button>
        </Row>
      </ModalContent>
    </Modal>)
}


export default IntrectionModal;