import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    TextareaAutosize,
    makeStyles
} from "@material-ui/core";
import {
    Close,
    Delete
} from "@material-ui/icons";
import { Col, Row } from "antd";
import { DeleteModal } from "components/Dialog";
import Loading from "components/Loading";
import { get, has, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleCreateTipsBook, handleDeleteTipsBook, handleUpdateTipsBook } from "store/tips/thunks";
import styled from "styled-components";
import COLOR from "utils/constants/color";

const TipsType = ['transcription', 'description', 'talented', 'special']

const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  min-height: 350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px; 
    @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;
const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: 10px;
`;
const LinkArea = styled.div`
  padding: 10px;
  margin: 2px;
  border: 1px dashed;
  margin-top: 24px;
`;
const TextAreaAuto = styled(TextareaAutosize)`
    height: 200px;
    width: 100%;
    border-radius: 2px;
    padding: 5px;
    overflow: hidden;
    border-color: rgb(0 0 0 / 32%);
    ::focus-visible {
      outline: ${COLOR.green100}auto 1px !importent;
     }
    ::focus{
      border-color: ${COLOR.green100} !importent;
  }
`;
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    // margin-left:5px;
    // margin-top:5px;
`;
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const TipsModal = ({ data, close, pageId, bookId, open, setAlertSakeBar }) => {
    const [tip, setTip] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const classes = useStyles();
    const dispatch = useDispatch()
  
    useEffect(() => {
      if (data) {
        setTip(data)
      } else {
        setTip({
          "bookId": bookId,
          color: '',
          page: pageId,
          "textTip": '',
          "texType": 'special'
        })
      }
    }, [data, bookId, pageId])
    const onUpdate = async () => {
      if (!tip.textTip) {
        setError(true)
        return;
      }
      setError(false)
      setLoading(true)
      if (data) {
        const intr = await dispatch(handleUpdateTipsBook(tip))
        if (has(intr, "id")) {
          setAlertSakeBar({
            open: true,
            message: "Intrection updated successfully!",
            severity: "success"
          })
          close()
        } else {
          setAlertSakeBar({
            open: true,
            message: "Error while updating Intrection!",
            severity: "error"
          })
        }
      } else {
        const intr = await dispatch(handleCreateTipsBook(tip))
        if (has(intr, "id")) {
          setAlertSakeBar({
            open: true,
            message: "Intrection created successfully!",
            severity: "success"
          })
          close()
        } else {
          setAlertSakeBar({
            open: true,
            message: "Error while creating Intrection!",
            severity: "error"
          })
        }
      }
      setLoading(false)
    }
    const onDelete = async () => {
      setDeleteLoader(true)
      const intr = await dispatch(handleDeleteTipsBook(get(tip, "id")));
      if (has(intr, "id")) {
        setAlertSakeBar({
          open: true,
          message: "Tips deleted successfully!",
          severity: "error"
        })
        close()
      } else {
        setAlertSakeBar({
          open: true,
          message: "Error while deleting Tips!",
          severity: "error"
        })
      }
      setDeleteLoader(false)
      setDeleteModal(false)
    }
    return (
      <Modal
        open={open}
        onClose={close}
        style={{ height: "100%", width: "100%" }}
      >
        <ModalContent>
          <DeleteModal
            deleteToggle={deleteModal}
            loading={deleteLoader}
            onDelete={onDelete}
            setDeleteToggle={setDeleteModal}
            message={`Are you sure to remove tips on  pageId ${pageId}`} />
          <Row style={{ justifyContent: "space-between" }}>
            <TitleText>
              Tips
            </TitleText>
            <IconButton
              onClick={close}
              style={{ padding: 0 }}
            >
              <Close color="secondary" />
            </IconButton>
          </Row>
          <ModalConfigArea>
            <LinkArea>
              <Row
                gutter={[24, 24]}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {data ? <IconButton
                  onClick={() => setDeleteModal(true)}
                  style={{ padding: 0, marginRight: 10 }}
                >
                  <Delete color="secondary" />
                </IconButton> : null}
              </Row>
              <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <TextField
                    id="pageId"
                    name="pageId"
                    label="pageId"
                    disabled
                    InputLabelProps={{ shrink: true, required: true }}
                    type="number"
                    variant="outlined"
                    onChange={(e) => {
  
                    }}
                    value={pageId}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <TextField
                    id="color"
                    name="color"
                    label="color"
                    placeholder="#ffffff"
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    variant="outlined"
                    onChange={(e) => {
                      setTip({
                        ...tip,
                        color: e.target.value,
                      });
                    }}
                    value={get(tip, "from")}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="textTip" style={{ backgroundColor: "#fff", paddingLeft: 2, paddingRight: 2 }}>Tip Text</InputLabel>
                    <TextAreaAuto
                      id="textTip"
                      type="text"
                      minRows={3}
                      maxRows={5}
                      variant="outlined"
                      onChange={(e) => {
                        setError(false)
                        setTip({
                          ...tip,
                          textTip: e.target.value,
                        });
                      }}
                      value={get(tip, "textTip")}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row
                gutter={[24, 24]}
                style={{ marginTop: 20, marginRight: 0, marginLeft: 0 }}
                className="floatingLabelBG"
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="texType">Type</InputLabel>
                  <Select
                    labelId="texType"
                    id="texType"
                    value={`${get(tip, "texType")}`}
                    onChange={(e) => {
                      setError(false)
                      setTip({
                        ...tip,
                        texType: e.target.value,
                      });
                    }}
                  // label="Lession Plan book"
                  >
                    {map(TipsType, (bk) => (
                      <MenuItem key={bk} value={bk}>{`${bk || ""}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Row>
            </LinkArea>
            {error ? (
              <FormHelperText error>
                Tip text required.!
              </FormHelperText>
            ) : null}
            <Row
              gutter={[24, 24]}
              style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
            >
              <Button
                fullWidth
                // disabled={}
                onClick={onUpdate}
                color="primary"
                variant="contained"
              >
                {loading ? <Loading white size={14} /> : null}   {data ? "Update" : "Create"}
              </Button>
            </Row>
          </ModalConfigArea>
        </ModalContent>
      </Modal>)
  }

export default TipsModal;