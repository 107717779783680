//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash";
import { PromiseSettledAll } from 'utils/methods/getSettledPromisses';
//  -- Actions ------------------------
import * as ActivityActions from './actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- List books ----------------
export const handleListUserBooks = (userId) => async (dispatch) => {
    await dispatch(ActivityActions.listUserBooks());

    try {
        let booksByUser = {}
        const booksByUserRDS = await API.get('userBooksRDS', `/userBooksByUser/${userId}`);
        const hasError = _.get(booksByUserRDS,"error",false)
        const isEmpty = _.isEmpty(booksByUserRDS)
        if (!hasError&&!isEmpty) {
            booksByUser = booksByUserRDS
        }
        await dispatch(ActivityActions.listUserBooksSuccess(booksByUserRDS));
        // -----------------------------------modified api end ----------------------------//
        return booksByUser;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(ActivityActions.listUserBooksFail(error));
    }
};

export const handleRemoveBooksFromClass = (data) => async (dispatch) => {
    try {
        await API.post('userBooksRDS', `/removeUserBook`, {
            body: data
        });

    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        console.log(error);
    }
}
export const handleUpdateUserBook = (id, books) => async (dispatch) => {
    try {
        let booksByUser = {}
        const booksByUserRDS = await API.put('userBooksRDS', `/userBooks/${id}`, {
            body: {
                books
            }
        });
        const hasError = _.get(booksByUserRDS,"error",false)
        const isEmpty = _.isEmpty(booksByUserRDS)
        if (!hasError&&!isEmpty) {
            booksByUser = booksByUserRDS
        }
        await dispatch(ActivityActions.listUserBooksSuccess(booksByUserRDS));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return error;
    }
}
export const getUserBooksByOrg = async (orgId) => {
    try {
        return await API.get('userBooksRDS', `/userBooksByOrg/${orgId}`);
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return null;
    }
}
