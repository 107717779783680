//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash"

//  -- Actions ------------------------
import * as pastSubmissionsActions from './actions';
import * as SubmissionsActions from '../submissions/actions';
import * as Sentry from "@sentry/react";

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create submission --------------

export const handleCreatePastSubmission = (data) => async (dispatch) => {
    try {
    const submission_data =  await API.post('pastSubmission', `/pastSubmissions`, {
            body: data
        });
        if(_.has(submission_data,"past_submission")){
            await dispatch(pastSubmissionsActions.updatePastSubmissions(submission_data.past_submission))  
            await dispatch(SubmissionsActions.updateSubmissionSuccess(submission_data.submission));
        }
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        return error
    }
};






