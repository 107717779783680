//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { handleGetWelcomeVideos } from "./thunks"

//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.welcomeVideos;

//  -- Examples array selector --------

export const useWelcomeVideos = () => {
    const selectedData = useSelector(selector, shallowEqual)
    return selectedData;
}

