import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Button, Modal } from 'antd';
import { useFirebase } from 'containers/FirebaseContext';
import {
    collection,
    onSnapshot,
    query,
    updateDoc,
    where
} from 'firebase/firestore';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { handleGetActivityById } from 'store/activities/thunks';
import styled from "styled-components";
import COLOR from "utils/constants/color";
const { confirm } = Modal;


const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`

export default function AssignableSharePopup({ currentClass, currentUser, onGetAssignment = () => { }, onNoData = () => { } }) {
    const history = useHistory();
    const { firestore } = useFirebase();
    const [openModal, setOpenModal] = useState(false);
    const [notificationData, setNotificationData] = useState([])

    useEffect(() => {
        const unsubscribe = listenToNotificationData(get(currentClass, 'id', ''));
        return () => {
            unsubscribe();
        };
    }, [firestore, currentClass])

    const listenToNotificationData = () => {
        const collectionRef = collection(firestore, "share_notifications");
        const q = query(collectionRef,
            where("createdBy", "!=", currentUser.id),
            where("notification_list", "array-contains", currentUser.id)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const notifications = [];
            querySnapshot.forEach((doc) => {
                notifications.push({ id: doc.id, ...doc.data() });
            });
            if (notifications?.length > 0) {
                setOpenModal(true);
                setNotificationData(notifications)
            }
        }, (error) => {
            console.error("Error listening to session changes:", error);
        });

        return unsubscribe;
    };

    const onDeleteNotification = async (deleteData) => {
        const collectionRef = collection(firestore, "share_notifications");
        const q = query(collectionRef, where("notification_list", "array-contains", currentUser.id), where("id", "==", deleteData?.id));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const idIndex = data.notification_list.indexOf(currentUser.id);
                if (idIndex !== -1) {
                    data.notification_list.splice(idIndex, 1);
                    updateDoc(doc.ref, { notification_list: data.notification_list })
                        .then(() => {
                            onGetAssignment()
                            // setOpenModal(false)
                        })
                        .catch((error) => {
                            console.error("Error updating document: ", error);
                        });
                }
            });
        }, (error) => {
            console.error("Error listening to session changes:", error);
        });

        return unsubscribe;
    }

    const onView = async (activityData) => {
        const chapter = get(activityData, "chapter", '')
        const assignmentPage = get(activityData, "page", 0)
        if (chapter == "33" || chapter == "41" || chapter == "43" || chapter == "49") {

        } else {
            history.push(`/ebook?chapter=${chapter}&page=${assignmentPage}&activity=${null}&quiz=${null}`);
        }
        const getActivityExist = await handleGetActivityById(activityData?.id);
        if (getActivityExist?.length === 0) {
            onNoData('Assignment not found!');
        }
        onDeleteNotification(activityData)
        destroyAll()
    }

    // showConfirm()

    const destroyAll = () => {
        Modal.destroyAll();
    };
    const showConfirm = (obj) => {
        confirm({
            icon: null,
            content: (
                <Text style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: 264 }}>
                    <CheckCircleOutlineIcon style={{ color: '#83ce34', fontSize: 50, marginRight: 6 }} />
                    <strong style={{ fontSize: 16, fontWeight: 500, color: 'black' }}>{obj?.message}</strong>
                    {/* <Button onClick={destroyAll}>Click to destroy all</Button> */}
                </Text>
            ),
            okText: 'View',
            cancelText: 'Cancel',
            onOk() {
                onView(obj)
            },
            onCancel() {
                onDeleteNotification(obj)
            },
        });
    };

    useEffect(() => {
        if (notificationData.length > 0) {
            const displayNotification = (index) => {
              if (index < notificationData.length) {
                showConfirm(notificationData[index]);
                setTimeout(() => {
                  displayNotification(index + 1);
                }, 500);
              }
            };
      
            displayNotification(0);
          }
    }, [notificationData]);

    return null;
}
