// import { KeyboardArrowDownOutlined, KeyboardArrowRightOutlined } from "@material-ui/icons";
// import React, { useEffect } from "react";
// import { useTraffic } from "store/wheel/selectors";

// const AccordinButton = ({ open, onClose,isDragging }) => {
//   // const [trafficModal] = useTraffic();
//   // const trafficData = trafficModal?.widget_menu ?? [];
  
//   // useEffect(() => { 
//   // if(trafficData?.length === 1 && !open){
//   //   onClose()
//   // }
//   // }, [trafficData])


//   const onCancel = (event) => {
//     event.stopPropagation();
//     onClose()
//   }

//   // const onCancelAccoridian = (event) => {
//   //   event.stopPropagation();
//   //   onClose()
//   // }
  
//   return (
//     <>
//       {open ? (
//         <KeyboardArrowDownOutlined
//           onClick={isDragging && onClose}
//           onTouchStart={!isDragging && onCancel}
//           style={{
//             position: "absolute",
//             left: "9px",
//             top: "15px",
//             color: "white",
//             cursor: "pointer",
//           }}
//         />
//       ) : (
//         <KeyboardArrowRightOutlined
//         onClick={isDragging && onClose}
//         onTouchStart={!isDragging && onCancel}
//           style={{
//             position: "absolute",
//             left: "9px",
//             top: "15px",
//             color: "white",
//             cursor: "pointer",
//           }}
//         />
//       )}
//     </>
//   );
// };

// export default AccordinButton;








import { KeyboardArrowDownOutlined, KeyboardArrowRightOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useTraffic } from "store/wheel/selectors";

const AccordinButton = ({ open, onClose, isDragging }) => {
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  const onCancel = (event) => {
    event.stopPropagation();
    onClose();
  };

  const handleClickOrTouch = (event) => {
    if (isTouchDevice()) {
      onCancel(event);
    } else {
       if (!isDragging) {
        onCancel(event);
       }
    }
  };

  return (
    <>
      {open ? (
        <KeyboardArrowDownOutlined
          onClick={!isTouchDevice() ? handleClickOrTouch : null}
          onTouchStart={isTouchDevice() ? handleClickOrTouch : null}
          style={{
            position: "absolute",
            left: "9px",
            top: "15px",
            color: "white",
            cursor: "pointer",
          }}
        />
      ) : (
        <KeyboardArrowRightOutlined
          onClick={!isTouchDevice() ? handleClickOrTouch : null}
          onTouchStart={isTouchDevice() ? handleClickOrTouch : null}
          style={{
            position: "absolute",
            left: "9px",
            top: "15px",
            color: "white",
            cursor: "pointer",
          }}
        />
      )}
    </>
  );
};

export default AccordinButton;

