import { Button, Col, Row } from "antd";
import Loading from "components/Loading";
import { get } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { DebateNewLogo } from "utils/assets/images";
import DebateCommentSection from "../DebateCommentSection";
import GoodJob from "../LastPage/GoodJob";
import ImLate from "../LastPage/ImLate";
import Acent from "components/Acent";

const LoaderWrapper = styled.div`
    // position:absolute;
    z-index: 100;
  `;
const ResponseText = styled.div`
  margin-left: 20px;
`;

const StudentContent = ({ onStudentAnswer = () => null, handleStudentReply = () => null, debateResponse, timer, submitLoader, showEndScreen, inputValuestudent, setInputValuestudent, showStudentComment, studentSubmission, setShowEndScreen, setShowStudentComment, setOpenStudentModal }) => {
  // const [inputValuestudent, setInputValuestudent] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const textareaRef = useRef(null);
  const SPANISH_ITEM_LOWER = ["á", "é", "í", "ó", "ú", "ü", "ñ", "¿", "¡"];
  const SPANISH_ITEM_UPPER = ["Á", "É", "Í", "Ó", "Ú", "Ü", "Ñ", "¿", "¡"];

  const timeComponents = timer.split(":");
  const minutes = parseInt(timeComponents[0]); // Extract minutes part
  const seconds = parseInt(timeComponents[1]); // Extract seconds part
  const totalTimeInSeconds = minutes * 60 + seconds; // Convert minutes to seconds and add seconds

  useEffect(() => {
    if (totalTimeInSeconds === 0) {
      if (totalTimeInSeconds === 0 && inputValuestudent === '') {
        if (debateResponse?.debate_type === 'live_debate') {
          setShowStudentComment(false);
          setShowEndScreen(true);
        } else {
          setShowEndScreen(true);
        }
      }
      else {
        onStudentAnswer(inputValuestudent);
      }
    }
  }, [totalTimeInSeconds])


  const insertAccent = (accent) => {
    const textarea = textareaRef.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    const newInputValue =
      inputValuestudent.substring(0, startPos) +
      accent +
      inputValuestudent.substring(endPos);

    // setInputValue(newInputValue);
    // setInputValuestudent(newInputValue.substring(0, 130));
    setInputValuestudent(newInputValue);

    setDropdownVisible(false);

    // Move the cursor to the right after the added accent
    const newCursorPos = startPos + accent.length;

    // Set the new cursor position immediately after the added accent
    setTimeout(() => {
      textarea.setSelectionRange(newCursorPos, newCursorPos);
      textarea.focus();
    }, 0);
  };
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const handleBlur1 = () => {
    setDropdownVisible(false);
  };



  const handleChange = (e) => {
    const value = e.target.value;
    setInputValuestudent(value);
    setOpenStudentModal(value);
  };

  return (
    <>
      {showStudentComment && (
        <DebateCommentSection
          studentSubmission={studentSubmission}
          debateResponse={debateResponse}
          timer={timer}
          handleStudentReply={handleStudentReply} />
      )}
      {showEndScreen && (
        <>
          {debateResponse?.debate_type === 'live_debate' ? <ImLate /> : <GoodJob /> }
        </>
      )}
      {!showEndScreen && !showStudentComment && (
        <div className="ideaMdlUpr">
          <Row>
            <Col xs={24}>
              <div className="allDebateCommitSection">
                <div className="debateMdlTop debateAllTopTxt studentCommitModal debateAllTopSpace">
                  <img src={DebateNewLogo} alt="Debate" />
                  <ResponseText className="responsetextstyle smallremovemargin">
                    <span>{get(debateResponse, 'question', '')}</span>
                  </ResponseText>
                </div>
                <div className="stdntTopCommitSec">
                  <div className="studntCommitSection">
                    <span>{timer}</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24}>
              <div className="ideaMdlContent">
                
                <div className="debateMdlLbl">
                  <div style={{ position: 'absolute', right: 0, top: -30 }}>
                    <Acent inputRef={textareaRef} setValue={setInputValuestudent} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
                    </div>
                  {/* <div
                    style={{
                      position: "absolute",
                      right: 23,
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div className="dropdown-container">
                      <button
                        className="dropdown-button accent-select"
                        onClick={toggleDropdown}
                        onBlur={() => handleBlur1()}
                        style={{ padding: "0.5px" }}
                      >
                        Á &#8659;
                      </button>

                      {dropdownVisible && (
                        <div className="dropdown-content accent-select">
                          <ul className="accent-list">
                            {SPANISH_ITEM_LOWER.map((accent, index) => (
                              <li
                                key={index}
                                className="accent-list-item"
                                onMouseDown={() => insertAccent(accent)}
                              >
                                {accent}
                              </li>
                            ))}
                          </ul>

                          <ul className="accent-list">
                            {SPANISH_ITEM_UPPER.map((accent, index) => (
                              <li
                                key={index}
                                className="accent-list-item"
                                onMouseDown={() => insertAccent(accent)}
                              >
                                {accent}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div> */}
                  <textarea
                    ref={textareaRef}
                    placeholder="Type answer here:"
                    value={inputValuestudent}
                    onChange={(e) => {
                      handleChange(e)
                    }}

                    style={{ height: "200px", padding: "30px" }}
                  />
                  <Button
                    style={{ display: 'flex', alignItems: 'center' }}
                    // className={`btn btn-icon btn-primary ms-3 shareBtnSec debateStudenSubmitButton ${!inputValuestudent && "btnDisabled"
                    //   }`}
                    className="shareBtnSec debateStudenSubmitButton"
                    type="primary"
                    // disabled={!inputValuestudent || inputValuestudent.trim() === ""}
                    disabled={inputValuestudent.trim() === ""}
                    onClick={() => {
                      onStudentAnswer(inputValuestudent);
                    }}
                  >
                    {submitLoader ? (
                      <LoaderWrapper>
                        <Loading size={18} white />
                      </LoaderWrapper>
                    ) : null} Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default StudentContent;