//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map , filter , uniqBy , cloneDeep } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const activitiesInitial = [];
const activityInitial = {};

export const activity = (state = activityInitial, action) => {
    switch (action.type) {
        case ActionTypes.activities.getActivitySuccess:
            return action.payload;
        case ActionTypes.activities.getActivityFail:
            return state;
        default:
            return state;
    }
};

export const activities = (state = activitiesInitial, action) => {
    switch (action.type) {
        case ActionTypes.activities.createActivitySuccess:
            return uniqBy([action.payload, ...state],"id");
        case ActionTypes.activities.updateActivitesSuccess:
            const v = cloneDeep(state)
            return v.map(k => {
                if (k.id === action.payload.id) {
                    return { ...k, ...action.payload }
                }
                return k;
            })
        case ActionTypes.activities.deleteActivitesSuccess:
            const delv = cloneDeep(state)
            return filter(delv,k =>k.id != action.payload.id)
        case ActionTypes.activities.listActivitiesSuccess:
        case ActionTypes.activities.listChapterActivitiesSuccess:
            return  uniqBy([...action.payload, ...state],"id");
        case ActionTypes.activities.createActivityFail:
        case ActionTypes.activities.listActivitiesFail:
        case ActionTypes.activities.listChapterActivitiesFail:
            return state;
        case ActionTypes.assignmenent.listAssignmentByUserSuccess:
            return uniqBy([...action.payload, ...state],"id");
        case ActionTypes.assignmenent.createCustomAssignmentSuccess:
            return [action.payload, ...state];
        case ActionTypes.assignmenent.updateCustomAssignmentSuccess:
            return map(state,st=>st.id===action.payload.id?action.payload:st)
        case ActionTypes.assignmenent.deleteCustomAssignmentSuccess:
            return filter(state,st=>st.id!==action.payload)
        default:
            return state;
    }
};
