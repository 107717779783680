//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

//  -- Components ---------------------
import { Row } from 'antd';

//  -- Constants ----------------------
import { Button, Chip, FormControl, IconButton, Input, InputLabel, MenuItem, Modal, Select, makeStyles } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import { Add, Close, Delete, Edit, PlayCircleFilled , Mic as  MicIcon } from "@material-ui/icons";
import Alert from '@material-ui/lab/Alert';
import { DeleteModal } from "components/Dialog";
import AudioRecorder from 'features/AudioRecorder';
import { filter, find, get, has, map } from "lodash";
import { useDispatch } from 'react-redux';
import { useAllBook } from "store/books/selectors";
import { useCurrentClass } from 'store/classes/selectors';
import { useInstructions } from 'store/instructions/selectors';
import { handleCreateInstructions, handleDeleteInstructions, handleUpdateInstructions } from "store/instructions/thunks";
import { useUserClasses } from 'store/userClasses/selectors';
import { useCurrentUser } from 'store/users/selectors';
import { handleCreateVocabulary } from 'store/vocabulary/thunks';
import COLOR from 'utils/constants/color';
import Loading from './Loading';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
    background-color: ${COLOR.green800};
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 12px 12px 8px 12px;
    position: relative;
    width: 100%;

    &:last-child {
        margin-bottom: 0px;
    }
`;
const ModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
`;

const AllModalContent = styled.div`
  left: 50%;
  max-width: 544px;
  min-width: 450px;
  padding: 0px 24px;
  position: relative;
  border-radius: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;

  @media (max-width:560px){
    min-width: 95%;
    margin-right:20px;
  }
`;

// const ModalContents = styled.div`
//   left: 50%;
//   max-width: 544px;
//   min-width: 450px;
//   padding: 0px 24px;
//   position: absolute;
//   border-radius: 10px;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   background-color: #fff;
//   padding: 10px;
//   max-height:550px;
//   overflow:scroll;
// `;
const ModalConfigArea = styled.div`
  max-height: 75vh;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 10px;
`;
const ActionText = styled.p`
    font-size:14px;
    color:white;
    font-weight:500;
`;
const InstButton = styled(Button)`
   border-radius: 8px 8px 0 0 !important;
   box-shadow: none !important;
   font-size: 10px;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Instructions
//
//  -- Description
//  TODO
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    }

}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const Instructions = ({ pageId, bookId, setInstModal, instModal }) => {
    const currentUser = useCurrentUser()
    const currentClass = useCurrentClass()
    const instructions = useInstructions()
    const userClasses = useUserClasses()
    const [instruction, setIntruction] = useState(null)
    const bookAllList = useAllBook()
    const [selectableClass, setSelectableClass] = useState([])
    const [openRecorder, setRecorder] = useState(false)
    const [playerModal, setPlayerModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [alertSakeBar, setAlertSakeBar] = useState({ open: false, message: '', severity: "success" })
    const [deletePageModal, setDeletePageModal] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const dispatch = useDispatch()
    const classes = useStyles()
    const [addVocabData, setAddVocabData] = useState({});
    const [vocabularyAI, setVocabularyAI] = useState();

    useEffect(() => {
        if (instModal) {
            if (has(instruction, "id") && get(currentUser, "role") === "student") {
                setPlayerModal(true)
            }
            if (get(currentUser, "role") === "teacher") {
                setRecorder(true)
            }
        }
    }, [instModal])

    useEffect(() => {
        const instr = find(instructions, k => k.bookId == bookId && k.pageId == pageId)
        if (instr && find(instr.classes, c => c == get(currentClass, "id"))) {
            setIntruction(instr);
        } else {
            const cls = []
            if (currentClass && currentClass.id) {
                cls.push(currentClass.id)
            }
            setIntruction({
                source: null,
                classes: cls,
                "createdBy": get(currentUser, "id"),
                "bookId": bookId,
                pageId: pageId
            })
        }
    }, [pageId, bookId, instructions, currentClass])

    useEffect(() => {
        setTimeout(() => {
            if (addVocabData.word == undefined) {
            } else {
                addVocabulary()
            }
        }, 2000)

    }, [vocabularyAI])

    useEffect(() => {
        try {
            if (userClasses && Array.isArray(userClasses) && userClasses.length > 0) {
                const booksNotAssigable = filter(bookAllList, b => b.isNotAssignable).map(book => book.id)
                const sclass = filter(userClasses, x => {
                    if (bookId && bookId.length > 5 || booksNotAssigable.includes(bookId)) {
                        return true
                    }
                    if (Array.isArray(get(x, "books.books"))) {
                        return filter(get(x, "books.books"), k => k.bookId == bookId).length > 0
                    }
                    else {
                        return false;
                    }
                }).map(e => ({ ...e, displayName: `${e.displayName} (${e.code})` }));
                setSelectableClass(sclass);
            }
        } catch (error) {
            console.log(error, "error in selecting class");
        }
    }, [userClasses, bookAllList])


    const onCloseModal = () => {
        setPlayerModal(false)
        setRecorder(false)
        setInstModal(false)
    }
    const onCreateUpdate = async () => {
        if (has(instruction, "id")) {
            setLoading(true)
            const d = await dispatch(handleUpdateInstructions(instruction.id, instruction))
            if (has(d, "id")) {
                setAlertSakeBar({
                    open: true,
                    message: "Updated instruction!",
                    severity: "success",
                })
                onCloseModal()
            } else {
                setAlertSakeBar({
                    open: true,
                    message: "Failed to update instruction!",
                    severity: "error",
                })
            }
            setLoading(false)
        } else {
            setLoading(true)
            const d = await dispatch(handleCreateInstructions(instruction))
            if (has(d, "id")) {
                setAlertSakeBar({
                    open: true,
                    message: "Created instruction!",
                    severity: "success",
                })
                onCloseModal()
            } else {
                setAlertSakeBar({
                    open: true,
                    message: "Failed to create instruction!",
                    severity: "error",
                })
            }
            setLoading(false)
        }
    }
    const onRemove = async () => {
        setDeleteLoader(true)
        const d = await dispatch(handleDeleteInstructions(instruction.id))
        if (has(d, "id")) {
            setAlertSakeBar({
                open: true,
                message: "Deleted instruction!",
                severity: "success",
            })
        } else {
            setAlertSakeBar({
                open: true,
                message: "Failed to delete instruction!",
                severity: "error",
            })
        }
        setDeletePageModal(false)
        setDeleteLoader(false)
    }
    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: '', severity: 'success' })
    }

    const addVocabulary = () => {
        const dataVocab = {
            book_id: bookId,
            page: pageId,
            chapter: bookId,
            created_by: currentUser?.role,
            user_id: get(currentUser, 'id', ''),
            class_id: get(currentClass, 'id', ''),
            data: {
                word: addVocabData.word,
                ...vocabularyAI
            },
        };
        const result = dispatch(handleCreateVocabulary(dataVocab));
        setAlertSakeBar({
            open: true,
            message: "Created Succesfully",
            severity: "success",
        })
        if (result) {
            setAddVocabData({})
            setVocabularyAI({})
        }
    }

    return (<>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertSakeBar.open}
            autoHideDuration={3000}
            onClose={closeAlert}>
            <Alert onClose={() => { setAlertSakeBar({ open: false, message: '' }) }} severity={alertSakeBar.severity}>
                <strong>ALERT:</strong> {alertSakeBar.message}
            </Alert>
        </Snackbar>
        <DeleteModal deleteToggle={deletePageModal} setDeleteToggle={setDeletePageModal} loading={deleteLoader} message={`Are you sure to remove Instruction on this page?`} onDelete={onRemove} />
        <Row style={{minWidth: "unset", height: "36px"}}className="scheduledWeb">
            {has(instruction, "id") && get(currentUser, "role") === "student" ?
                <InstButton size="small" color='primary' variant="contained"  onClick={() => setPlayerModal(true)}>
                    PLAY  <PlayCircleFilled color="error" style={{ marginLeft: 6, backgroundColor: "#fff", borderRadius: "50%" }} />
                </InstButton> : null}
            {get(currentUser, "role") === "teacher" ?
                <InstButton size="small" color='primary' variant="contained"  onClick={() => setRecorder(true)} className='ebookRecord'>
                    {/* {has(instruction, "id") ? "PLAY" : "RECORD"}  */}
                    {has(instruction, "id") ? <PlayCircleFilled color="error" style={{ backgroundColor: "#fff", borderRadius: "50%" }} /> : <MicIcon color="error" style={{ backgroundColor: "#fff", borderRadius: "50%" }} />} {has(instruction, "id") && <Edit style={{ marginLeft: 6, color: "#fff" }} /> 
                    // : <Add style={{ marginLeft: 6, color: "#fff" }} />
                }
                </InstButton> : null}
            {/* <Button onClick={() => handleVocabulary(true)} className='vocabularyBtn vocDesk'>vocabulary</Button> */}
        </Row>
       
        <Modal
            open={openRecorder} onClose={onCloseModal}
            style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}>
            <AllModalContent>
                <Row style={{ justifyContent: "flex-end" }}>
                    <IconButton
                        onClick={onCloseModal}
                        style={{ padding: 0, marginRight: 10 }}
                    >
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <Row style={{ justifyContent: "flex-end" }}>
                    {has(instruction, "id") ? <IconButton onClick={() => setDeletePageModal(true)}>
                        <Delete color='error' />
                    </IconButton> : null}
                </Row>
                <ModalConfigArea>
                    <AudioRecorder
                        source={get(instruction, "source")}
                        message={`To begin your recording click the "Record" button below.`}
                        handleAudio={url => {
                            setIntruction({
                                ...instruction,
                                source: url
                            })
                        }}
                    />
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel id="classLabel">Classes</InputLabel>
                        <Select
                            labelId="classLabel"
                            id="classes"
                            multiple
                            input={<Input id="select-multiple-chip" />}
                            value={get(instruction, "classes", [])}
                            onChange={e => {
                                setIntruction({
                                    ...instruction,
                                    classes: e.target.value
                                })

                            }}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {map(selected, (value) => (
                                        <Chip key={value} label={get(find(selectableClass, ["id", value]), "displayName")} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {map(selectableClass, x => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button disabled={get(instruction, "classes.length", 0) == 0 || !get(instruction, "source") || loading} onClick={onCreateUpdate} fullWidth variant="contained" color="primary">
                        {loading && <Loading size={15} white />}  {has(instruction, "id") ? "Update" : "Create"}
                    </Button>
                </ModalConfigArea>
            </AllModalContent>
        </Modal>

        <Modal
            open={playerModal} onClose={onCloseModal}
            style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}>
            <ModalContent>
                <Row style={{ justifyContent: "flex-end" }}>
                    <IconButton
                        onClick={onCloseModal}
                        style={{ padding: 0, marginRight: 10 }}
                    >
                        <Close color="secondary" />
                    </IconButton>
                </Row>
                <ModalConfigArea>
                    <ReactPlayer
                        url={get(instruction, "source")}
                        height="40px"
                        width="100%"
                        controls
                        playsinline
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    />
                </ModalConfigArea>
            </ModalContent>
        </Modal>
    </>);
};

//  -- Prop types ---------------------
Instructions.propTypes = {
    data: shape({}),
};

//  -- Default props ------------------
Instructions.defaultProps = {};

export default Instructions;


