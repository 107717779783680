//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as Sentry from "@sentry/react";
import { has } from 'lodash';
import * as QuizActions from './actions';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- Create quiz --------------------


//  -- List chapter quizzes --------
export const handleListChapterQuizzes = (chapterID) => async(dispatch) => {
    await dispatch(QuizActions.listChapterQuizzes());

    try {
        let quizzes = []
        const quizzesRDS = await API.get('quizzesRDS', `/quizzesByChapter/${chapterID}`);
        if(Array.isArray(quizzesRDS)&& quizzesRDS.length>0){
            quizzes = quizzesRDS
        }
        await dispatch(QuizActions.listChapterQuizzesSuccess(quizzes));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(QuizActions.listChapterQuizzesFail(error));
    }
};

//  -- List quizzes ----------------
export const handleListQuizzes = () => async(dispatch) => {
    await dispatch(QuizActions.listQuizzes());

    try {
        let  quizzes = []
        const quizzesRDS = await API.get('quizzesRDS', `/quizzes`);
        if(Array.isArray(quizzesRDS)&& quizzesRDS.length>0){
            quizzes = quizzesRDS
        }
        await dispatch(QuizActions.listQuizzesSuccess(quizzes));
        return quizzes;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(QuizActions.listQuizzesFail(error));
    }
};

export const handleCreateQuizzes = (quiz) => async (dispatch) => {
    dispatch(QuizActions.createQuiz())
    try {
        const quizzesRDS = await API.post('quizzesRDS', `/create`, {
            body: quiz
        });
        if (has(quizzesRDS, "id")) {
            await dispatch(QuizActions.createQuizSuccess(quizzesRDS))
        } else {
            await dispatch(QuizActions.createQuizFail(quizzesRDS))
        }
    } catch (error) {
        console.log(error, "error in create quizzes");
    }
}
export const handleUpdateQuizzes = (id,quiz) => async (dispatch) => {
    dispatch(QuizActions.updateQuiz())
    try {
        const quizzesRDS = await API.put('quizzesRDS', `/update/${id}`, {
            body: quiz
        });
        if (has(quizzesRDS, "id")) {
            await dispatch(QuizActions.updateQuizSuccess(quizzesRDS))
        } else {
            await dispatch(QuizActions.updateQuizFail(quizzesRDS))
        }
    } catch (error) {
        console.log(error, "error in update quizzes");
    }
}

export const handleDeleteQuizzes = (id) => async (dispatch) => {
    dispatch(QuizActions.deleteQuiz())
    try {
        const quizzesRDS = await API.del('quizzesRDS', `/delete/${id}`);
        if (has(quizzesRDS, "id")) {
            await dispatch(QuizActions.deleteQuizSuccess(quizzesRDS))
        }
       
    } catch (error) {
        console.log(error, "error in update quizzes");
    }
}