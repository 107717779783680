//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
//  -- Components ---------------------
import { Col, Row } from 'antd';
import BalconCover from "components/BookUnitCover";
import Loading from "components/Loading";
import _, { chunk, filter, get, find, findIndex, cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlertView } from "store/alert/selectors";
import { useBookUnits } from "store/booksUnits/selectors";
import { handleListBookUnits, handleUpdateUnits } from "store/booksUnits/thunks";
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useDemoStatus } from "store/users/selectors";
import styled from "styled-components";
import { AP_THEME, BALCON_COVER_BASE64,ANÉCDOTAS_THEMES,IB_Theme } from "utils/config/reference";
import COLOR from "utils/constants/color";
import queryString from 'query-string';
import { Autocomplete } from '@material-ui/lab'
import { Button, IconButton, ButtonGroup, Modal, TextField, InputLabel, FormControl, MenuItem, Select, FormControlLabel, makeStyles, Switch, Typography } from '@material-ui/core'
import PageImagePicker from "components/AdminBooks/PageImagePicker"
import Pages from "components/AdminBooks/pages"
import { Close , Star  } from "@material-ui/icons"
import Tag from 'components/Tag';
import moment from "moment"
import {
    DateTimePicker,
} from "@material-ui/pickers";
//  -- Constants ----------------------

//  -- Features -----------------------

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const PAGE_CATEGORY = [
    'Other',
    'Entretenimiento',
    'Tecnología',
    'Deportes',
    'Historias',
    'Cultura',
    'Música',
    'Curiosidades',
    'Cine',
    'Fantasía',
    'Realismo',
    'Ciencia ficción',
    'Magia',
    'Espacio',
    '6-8th ',
    '9-10th',
    '11-12th'
]
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  min-height: 90vh;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 0px 24px;
  justify-content: center;
`;
const Block = styled.div`
  min-height: 300px;
`;
const FormArea = styled.div`
    overflow:auto;
    margin-top:10px;
`;
const Note = styled.span`
    color: ${COLOR.black400};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
`;
// export const Wrapper = styled.div`
//     height: 100%;
//     width: 100%;
//     background-image:url(${BgImage})
// `;

const ListItem = styled.li`
  margin: 0px 16px 0px;
  width: calc(14% - 32px);

  @media only screen and (min-width: 600px) and (max-width: 800px) {
    margin: 0px 16px 32px;
    width: calc(50% - 32px);
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0px 16px 32px;
    width: 100%;
  }
`;
const List = styled.ul`
  /* width: calc(100% + 40px); */
  /* width: calc(100% + -200px); */

  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
`;
//   margin-left: 12%;
// width: 75%;
const ListItemCovers = styled.li`
  margin: 0px 16px 32px;
  width: calc(16% - 25px);

  @media only screen and (min-width: 600px) and (max-width: 800px) {
    margin: 0px 16px 32px;
    width: calc(50% - 32px);
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0px 16px 32px;
    width: 100%;
  }
`;
const ListCovers = styled.ul`
  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
`;
const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
    padding:10px 0px;
    padding-bottom:20px;
`;
const BoxRow = styled.div`
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 20px;

    @media (max-width: 768px) {
      display: inherit;
    }
`;

const Column = styled.div`
  width:23%;
  cursor: pointer;
  margin: 10px;
  position:relative;
  @media (max-width: 768px) {
     width:93%;
  }
`;

const CoverSecion = styled.div`
  width:100%;
  height:100%;
  overflow-y:${p => p.overflowItem ? "scroll" : "hidden"};
`
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const CategoryMenu = styled.div`
    background-color:${COLOR.tabActive};
    border-radius: 0px 12px 12px 12px;
    color: #fff;
    height: 25px;
    padding-left: 20px;
    padding-right: 2px;
    margin-top: 20px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:150px;
    align-items: center;
    cursor: pointer;

`;
const MenuIconImg = styled.img`
    margin-left:10px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
`;
const SearchInput = styled.input`
    width:100px;
    color:#fff;
    border:none;
    background: transparent;
    outline: none;
    text-align: center;
    font-weight: 800;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${COLOR.white};
    }
    ::focus{
        outline: none;
    }
`;
const NewTag = styled.div`
    color:#fff;
    background-color:rgb(211,51,49);
    text-transform: uppercase;
    font-weight:800;
    font-weight: 800;
    width: 60px;
    position: absolute;
    text-align:center;
    top: -22px;
`;

const ModalContent = styled.div`
  left: 50%;
  width:70vw;
  padding: 0px 24px;
  position: absolute;
//   min-height:350px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color:#fff;
  padding:20px;
  overflow:auto;
   @media only screen and (max-width: 600px) {
        min-width: 90% !important;
    }
`;
const ModalConfigArea = styled.div`
//   max-height:75vh;
//   overflow:auto;
  margin-top:10px;
   @media only screen and (max-width: 600px) {
     max-height:75vh;
    }
`;
const Page = styled.div`
    align-items: center;
    color: ${COLOR.black100};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
`;

const ModalRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`
const Text = styled.span`
  color: ${COLOR.black500};
  font-size: 11px;
  margin-top: 2px;
  text-align: left;
`
const ModalRowDelete = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
`
const ModalContentDelete = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  border-radius: 3px;
  display: inline-flex;
  flex-direction: column;
  left: 50%;
  max-width: 320px;
  padding: 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`
const TitleText = styled.span`
    font-size: 18px;
    color: black;
    font-weight:600;
    // margin-left:5px;
    // margin-top:5px;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const filterWithTextVideos = (units, txt) => {
    const itemtext = txt.toLowerCase().trimStart().trimEnd()
    return filter(units, k => {
        if (get(k, 'categorias', "").toLowerCase().includes(itemtext) || get(k, 'tags', "").toLowerCase().includes(itemtext)) {
            return true;
        } else if (get(k, 'shortName', "").toLowerCase().includes(itemtext)) {
            return true;
        } else {
            return false;
        }
    })

}
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const sortUnits = function (a, b) {
    if (Number(a.id) < Number(b.id)) {
        return 1;
    }
    if (Number(a.id) > Number(b.id)) {
        return -1;
    }
    // a must be equal to b
    return 0;
}
const BookUnits = ({
    currentUser
}) => {
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const bookUnitList = useBookUnits()
    const [pages, setPages] = useState([])
    const [bookId, setBookId] = useState('')
    const [displayName, setDisplayName] = useState([])
    const [editUnit, setEditUnit] = useState(null)
    const [cover, setCover] = useState(null)
    const [unitModal, setUnitModal] = useState(false)
    const classes = useStyles();
    const [pgData, setPgData] = useState(null)
    const [saveModal, setSaveModal] = useState(false)
    const [bookErrors, setErrors] = useState([])
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [alertSakeBar, setAlertSakeBar] = useState({ open: false, message: '', severity: "success" })
    useEffect(() => {
            const data = find(bookUnitList, ["bookId", search.bookId])
            if (data) {
                setEditUnit(data)
                setCover(data.img)
                setPages(sortUnitsByNew(get(data, "pages", [])))
                setBookId(data.bookId)
                setDisplayName(get(data, "displayName", ''))

            }
    }, [bookUnitList])

    const sortUnitsByNew = (res) => {
        const newUnits = filter(res, ["new", true]).sort(sortUnits)
        const oldUnits = filter(res, k => !k.new).sort(sortUnits)
        return [...newUnits, ...oldUnits]
    }
    const onPageInfoChange = (key, value) => {
        const _pgData = cloneDeep(pgData)
        _pgData[key] = value
        setPgData(_pgData)
    }
    const onCloseModal = () => {
        setUnitModal(false)
        setPgData(null)
        setErrors([])
    }
    const onCreateOrUpdate = async () => {
        const errors = []
        let pagesData = pages;
        if (pgData) {
            const index = findIndex(pages, p => p.id === pgData?.id)
            if (index >= 0) {
                pagesData = _.map(pages, p => p.id === pgData.id ? pgData : p)
            } else {
                pagesData = [pgData, ...pages]
            }
            if (parseInt(pgData.start) >= parseInt(pgData.finish) || parseInt(pgData.start) == 0) {
                setAlertSakeBar({
                    open: true,
                    message: pgData.start == 0 ? "Start pageId must be greater then zero " : "Start pageId must be less then finish pageId",
                    severity: "error"
                })
                return;
            }
        }
        const book = {
            id: editUnit.id,
            pages: pagesData,
            displayName: displayName.replace(/^'+|'+$/g, ''),
            img: cover.replace(/^'+|'+$/g, ''),
            bookId: bookId

        }
        if (!book.displayName || book.displayName.length == 0) {
            errors.push({ type: 'description_error', message: 'Book title required.\n' })
        }
        if (!book.img || book.img.length == 0) {
            errors.push({ type: 'cover_error', message: 'Cover image required.\n' })
        }

        setErrors(errors)
        if (errors.length == 0) {
            setLoading(true)
            if (editUnit) {
                console.log('cover', cover, 'displayName', displayName.replace(/^'+|'+$/g, ''));
                
                const unit = await dispatch(handleUpdateUnits(editUnit.id, book))
                if (_.has(unit, "id")) {
                    setAlertSakeBar({
                        open: true,
                        message: "Book units updated successfully",
                        severity: "success"
                    })
                    setUnitModal(false)
                    setSaveModal(false)
                } else {
                    setAlertSakeBar({
                        open: true,
                        message: "Some thing went wrong!",
                        severity: "error"
                    })
                }

            }
            setLoading(false)
        } else {
            setLoading(false)
        }

    }
    const redirectToBook = () => {
        history.push(`/add-book?bookId=${bookId}&pageId=${pgData.start}`)
    }
    const onddUnits = () => {
        let mxId = Math.max(...pages.map(o => parseInt(o.id)))
        if(isNaN(mxId)||mxId==-Infinity){
            mxId = 0;
        }
        const item = {
            "id": `${mxId + 1}`,
            "new": true,
            "src": "",
            "tags": "",
            "start": "",
            "finish": "",
            "shortName": "",
            "categorias": "",
            "anécdotas_themes": "",
            "ap_themes": "",
            "ib_themes": "",
        }
        // setPages([item, ...pages])
        setPgData(item)
        setUnitModal(true)
    }

    const closeAlert = () => {
        setAlertSakeBar({ open: false, message: '', severity: 'success' })
    }

    const onDelete = async (id) => {
        setDeleteLoader(true)
        const index = findIndex(pages, ['id', id])
        const oldIndex = findIndex(editUnit.pages, ['id', id])
        if (oldIndex >= 0) {
            const ePages = cloneDeep(editUnit.pages)
            const eData = cloneDeep(editUnit)
            ePages.splice(oldIndex, 1)
            eData.pages = ePages
            setEditUnit(eData)
            const unit = await dispatch(handleUpdateUnits(editUnit.id, eData))
            setUnitModal(false)
            setAlertSakeBar({
                open: true,
                message: "Book units deleted successfully",
                severity: "error"
            })
            setSaveModal(false)

        }
        if (index >= 0) {
            const _pgs = cloneDeep(pages)
            _pgs.splice(index, 1)
            setPages(_pgs)
        }
        setDeleteLoader(false)
        setDeleteToggle(false)
    }
    const updateDisabled = useCallback(() => {
        if (!pgData) return true;
        return !!(!pgData.start || !pgData.finish || !pgData.shortName || !pgData.src);
    }, [pgData])

    return (
        <Wrapper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertSakeBar.open}
                autoHideDuration={3000}
                onClose={closeAlert}>
                <Alert onClose={() => { setAlertSakeBar({ open: false, message: '' }) }} severity={alertSakeBar.severity}>
                    <strong>ALERT:</strong> {alertSakeBar.message}
                </Alert>
            </Snackbar>
            <Modal open={Boolean(deleteToggle)} onClose={() => setDeleteToggle(false)}>
                <ModalContentDelete>
                    <Text>
                        <strong>{`Are you sure to delete this?`}</strong>
                    </Text>
                    <ModalRowDelete>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setDeleteToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                onDelete(deleteToggle)
                            }}
                        >
                            {deleteLoader ? <Loading size={15} white /> : 'Delete'}
                        </Button>
                    </ModalRowDelete>
                </ModalContentDelete>
            </Modal>
            <Modal
                open={saveModal}
                onClose={() => setSaveModal(false)}
                style={{ height: '100%', width: '100%' }}
            >
                <ModalContent>
                    <Row style={{ justifyContent: "space-between" }}>
                        <TitleText>{editUnit ? 'Update' : 'Create' }</TitleText>
                        <IconButton onClick={() => setSaveModal(false)}>
                            <Close color="secondary" />
                        </IconButton>
                    </Row>
                    <ModalConfigArea>
                        <Row>
                            <Col xs={24} lg={12}>
                                <PageImagePicker
                                    src={cover}
                                    path={`/units/${bookId}`}
                                    onChange={item => { setCover(item.url) }}
                                    hasDescription={false}
                                />
                            </Col>
                            <Col xs={24} lg={12} style={{ paddingLeft: 10, paddingTop: 10 }}>
                                <Row justify="start" style={{ marginBottom: 20, }}>
                                    <TextField
                                        id="displayName"
                                        name="displayName"
                                        label="Display name"
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        onChange={e => {
                                            setDisplayName(e.target.value)
                                        }}
                                        value={displayName}
                                    />
                                </Row>
                                <Row justify="start" style={{ marginBottom: 30, }}>
                                    <TextField
                                        id="boodid"
                                        name="bookid"
                                        label="Book Id"
                                        required={false}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        onChange={e => {
                                            setBookId(e.target.value)
                                        }}
                                        value={bookId}
                                    />
                                </Row>
                                {bookErrors.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                                    {bookErrors.map(v => (<Typography variant="subtitle2" key={v.type} style={{ whiteSpace: "pre-wrap" }} color="error">
                                        * {get(v, "message")}
                                    </Typography>))}
                                </Row> : null}
                                <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                                    <Button fullWidth onClick={onCreateOrUpdate} variant="contained" color="primary">
                                        {loading ? <Loading white size={15} /> : null}
                                        {editUnit ? "Update" : "Create"}
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </ModalConfigArea>
                </ModalContent>
            </Modal>
            <Modal
                open={unitModal}
                onClose={() => { }}
                style={{ height: '100%', width: '100%' }}
            >
                <ModalContent>
                    <Row style={{ justifyContent: "space-between" }}>
                        <TitleText>{editUnit ? 'Edit Unit' : 'Create Unit'}</TitleText>
                        <IconButton onClick={onCloseModal} style={{ padding: 0}}>
                            <Close color="secondary" />
                        </IconButton>
                    </Row>
                    <ModalConfigArea>
                        <Row>
                            <Col xs={24} lg={12}>
                                <PageImagePicker
                                    src={get(pgData, "src")}
                                    path={`/units/${bookId}`}
                                    onChange={item => { onPageInfoChange('src', item.url) }}
                                    hasDescription={false}
                                />
                            </Col>
                            <Col xs={24} lg={12} style={{ paddingLeft: 10, paddingTop: 10 }}>
                            <FormArea style={{ height: "65vh"}}>
                                <Row justify="start" style={{ marginBottom: 10,marginTop:20 }}>
                                    <TextField
                                        id="id"
                                        name="id"
                                        label="Id"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        type="text"
                                        required
                                        disabled
                                        variant="outlined"
                                        onChange={e => {

                                        }}
                                        value={get(pgData, "id")}
                                    />
                                </Row>
                                <Row justify="start" style={{ marginTop: 20 }}>
                                    <Col xs={24} lg={12} style={{paddingRight:5}}>
                                        <TextField
                                            id="start"
                                            name="start"
                                            label="start pageId"
                                            required={false}
                                            InputLabelProps={{ shrink: true }}
                                            type="number"
                                            variant="outlined"
                                            onChange={e => {
                                                onPageInfoChange('start', e.target.value)
                                            }}
                                            value={get(pgData, "start")}
                                        />
                                    </Col>
                                    <Col xs={24} lg={12} style={{ paddingLeft: 5 }}>
                                        <TextField
                                            id="finish"
                                            name="finish"
                                            label="Finish pageId"
                                            required={false}
                                            InputLabelProps={{ shrink: true }}
                                            type="number"
                                            variant="outlined"
                                            onChange={e => {
                                                onPageInfoChange('finish', e.target.value)
                                            }}
                                            value={get(pgData, "finish")}
                                        />
                                    </Col>

                                </Row>
                                <Row justify="start" style={{ marginTop: 20 }}>
                                    <FormControlLabel
                                        classes={{
                                            root: classes.rootDifFull,
                                            label: classes.label,
                                        }}
                                        control={
                                            <Switch
                                                classes={{
                                                    sizeSmall: classes.sizeSmall,
                                                    checked: classes.checkedSwitch
                                                }}
                                                checked={Boolean(get(pgData, "new"))}
                                                id="New"
                                                onChange={e => {
                                                    onPageInfoChange('new', e.target.checked)
                                                }}
                                                name="New"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label="New"
                                    />
                                </Row>
                                <Row justify="start" style={{ marginTop: 20 }}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="tags-outlined"
                                        options={PAGE_CATEGORY}
                                        defaultValue={get(pgData, "tags", '') ? get(pgData, "tags", '').split(",") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onPageInfoChange('tags', value.join(","))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="Tags"
                                                placeholder="Tags"
                                            />
                                        )}
                                    />
                                </Row>
                                <Row justify="start" style={{ marginTop: 20 }}>
                                    <TextField
                                        id="shortName"
                                        name="shortName"
                                        label="Display name"
                                        required={false}
                                        InputLabelProps={{ shrink: true }}
                                        type="text"
                                        variant="outlined"
                                        onChange={e => {
                                            onPageInfoChange('shortName', e.target.value)
                                        }}
                                        value={get(pgData, "shortName")}
                                    />
                                </Row>

                                <Row gutter={[24, 24]} style={{ marginTop: 20, marginRight: 0, marginLeft: 0 }} className="floatingLabelBG">
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="Categorias">Categorias</InputLabel>
                                        <Select
                                            labelId="Categorias"
                                            id="Categorias"
                                            value={get(pgData, "Categorias", '')}
                                            onChange={(v) => {
                                                onPageInfoChange('Categorias', v.target.value)
                                            }}
                                            label="Categorias"
                                        >
                                            {PAGE_CATEGORY.map(v => (<MenuItem key={v} value={v}>{v}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Row>
                                <Row justify="start" style={{ marginTop: 20 }}>
                                <Col xs={24} lg={24}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="anécdotas-themes-outlined"
                                        options={ANÉCDOTAS_THEMES}
                                        defaultValue={get(pgData, "anécdotas_themes", '') ? get(pgData, "anécdotas_themes", '').split(",,") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onPageInfoChange('anécdotas_themes', value.join(",,"))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="Anécdotas Themes"
                                                placeholder="Anécdotas Themes"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row justify="start" style={{ marginTop: 20, }}>
                                <Col xs={24} lg={24}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="ap-themes-outlined"
                                        options={AP_THEME}
                                        defaultValue={get(pgData, "ap_themes", '') ? get(pgData, "ap_themes", '').split(",") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onPageInfoChange('ap_themes', value.join(","))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="AP Themes"
                                                placeholder="Ap Themes"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            {/* ib themes */}
                            <Row justify="start" style={{ marginTop: 20, }}>
                                <Col xs={24} lg={24}>
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        id="ib-themes-outlined"
                                        options={IB_Theme}
                                        defaultValue={get(pgData, "ib_themes", '') ? get(pgData, "ib_themes", '').split(",") : []}
                                        style={{ width: "100%" }}
                                        onChange={(event, value) => {
                                            onPageInfoChange('ib_themes', value.join(","))
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                type="text"
                                                label="IB Themes"
                                                placeholder="IB Themes"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                                <Row gutter={[16, 24]} className="durDateSpc">
                                <Col span={24}>
                                    <DateTimePicker
                                        id="StartFrom"
                                        label="Start Date"
                                        inputVariant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={null}
                                        format="MM-dd-yyyy hh:mm a"
                                        initialFocusedDate={moment().toString()}
                                        strictCompareDates
                                        value={get(pgData, "startDate")}
                                        onChange={e => {
                                            onPageInfoChange('startDate',  e.toISOString())
                                        }} />
                                </Col>
                                <Tag height={20}><Star style={{ height: '12px', margin: '-1px 4px 0px 0px', width: '12px' }} /> NOTE</Tag>
                                <Note>This will take effect after the given date and time.</Note>
                            </Row>
                            </FormArea>
                                {bookErrors.length > 0 ? <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0, flexDirection: "column", display: "flex" }}>
                                    {bookErrors.map(v => (<Typography variant="subtitle2" key={v.type} style={{ whiteSpace: "pre-wrap" }} color="error">
                                        * {get(v, "message")}
                                    </Typography>))}
                                </Row> : null}
                                <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                                    <Button fullWidth onClick={onCreateOrUpdate}
                                        disabled={updateDisabled()}
                                        variant="contained" color="primary">
                                        {loading ? <Loading white size={15} /> : null}
                                        {editUnit ? "Update" : "Create"}
                                    </Button>
                                </Row>
                                <Row gutter={[24, 24]} style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }}>
                                    <Button fullWidth onClick={redirectToBook} variant="contained" color="primary">
                                        Open book
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </ModalConfigArea>
                </ModalContent>
            </Modal>
            <Row style={{ marginTop: 16 }}>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                </Col>
                <Col xs={{ span: 24 }} style={{ display: "flex", flexDirection: "row" }} lg={{ span: 14 }}>

                    <Button onClick={onddUnits} style={{ minWidth: 150, marginRight: 10 }} variant="contained" color="primary"> Add units</Button>
                    <Button onClick={() => setSaveModal(true)} style={{ minWidth: 150 }} variant="contained" color="primary"> Save</Button>
                </Col>
            </Row>
            <Row style={{ paddingTop: "1%", paddingBottom: 0 }}>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                    <ImageContainer
                    >
                        <Pages src={cover} path={`/units/${bookId}`} onChange={(item) => { setCover(item.url) }} description={displayName} onChangeDescription={v => setDisplayName(v)} />
                    </ImageContainer>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                    <CoverSecion overflowItem={pages.length > 12}>
                        {chunk(pages, 4).map((bookitem, i) => {
                            return (<BoxRow key={i + "bookrow"}> {bookitem.map((item, index) => {
                                return (
                                    <Column key={index + i + "bookcol"}>
                                        {get(item, "new", false) ? <NewTag>New</NewTag> : null}
                                        <BalconCover handleClick={() => {
                                            setPgData(item)
                                            setUnitModal(true)

                                        }}
                                            deleteLoader={deleteLoader}
                                            setDeleteLoader={setDeleteLoader}
                                            deleteToggle={deleteToggle}
                                            setDeleteToggle={setDeleteToggle}
                                            onDelete={onDelete}
                                            data={item}
                                        />
                                    </Column>
                                );
                            })}
                            </BoxRow>)
                        })}
                    </CoverSecion>
                </Col>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
BookUnits.propTypes = {};

//  -- Default props ------------------
BookUnits.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    userBooksList: state.userBooksList,
    tipsBook: state.tipsBook,
    classUsers: state.classUsers,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    submissions: state.submissions,
    booksAllList: state.booksAllList
});

export default connect(mapStateToProps, null)(BookUnits);
