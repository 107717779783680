//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
//  -- Components ---------------------
import { Col, Row } from 'antd';
import BalconCover from "components/BalconCover";
import Loading from "components/Loading";
import _, { chunk, filter, get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlertView } from "store/alert/selectors";
import { useBookUnits } from "store/booksUnits/selectors";
import { handleListBookUnits } from "store/booksUnits/thunks";
import { useCurrentOrgPermission, useOrgTimezone } from 'store/organizations/selectors';
import { useDemoStatus } from "store/users/selectors";
import styled from "styled-components";
import { SearchIcon } from "utils/assets/images";
import { BALCON_COVER_BASE64, getFireBaseUrl } from "utils/config/reference";
import COLOR from "utils/constants/color";
import { isAfterTime } from "utils/methods/math";
import moment from "moment"
import { useFavouriteVideo } from "store/FavouriteVideo/selectors";
import { handleCreateFavouriteVideo, handleListFavouriteVideo } from "store/FavouriteVideo/thunks";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useFirebase } from "./FirebaseContext";
import { useResourceViews } from "utils/methods/firebaseResource";
import { EmptyImagePlaceholder } from "components/EmptyPlaceholderLesson";
const CHAPTER_ID = '33'
//  -- Constants ----------------------

//  -- Features -----------------------

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  min-height: 90vh;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 0px 24px;
  // Responsive padding for devices up to 450px
  @media (max-width: 450px) {
    padding: 0px 24px 0px 30px;
  }

  // Responsive padding for devices between 451px and 1366px
  @media (min-width: 451px) and (max-width: 1000px) {
    padding: 0px 0px 0px 30px;
  }
  @media (min-width: 1001px) and (max-width: 1366px) {
    padding: 0px 0px 0px 20px;
  }

  justify-content: center;
`;
const Block = styled.div`
  min-height: 300px;
`;
// export const Wrapper = styled.div`
//     height: 100%;
//     width: 100%;
//     background-image:url(${BgImage})
// `;

const ListItem = styled.li`
  margin: 0px 16px 0px;
  width: calc(14% - 32px);

//   @media only screen and (min-width: 600px) and (max-width: 800px) {
  @media only screen and (min-width: 600px) and (max-width: 1030px) {

    margin: 0px 16px 32px;
    width: calc(50% - 32px);
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0px 16px 32px;
    width: 100%;
  }
`;
const ListItems = styled.p`
    font-size:32px;
    font-weight:600;
    text-align:center;

    @media only screen and (min-width: 250px) and (max-width: 450px) {
        padding-top: 6%;
        font-size: 11px;
    }

    // @media only screen and (min-width: 600px) and (max-width: 800px) {
    // font-size:25px;
    // }
    @media only screen and (min-width: 600px) and (max-width: 1030px) {
        padding-top: 6%;
        // font-size:25px;
        font-size:21px;

    }
`;
const List = styled.ul`
  /* width: calc(100% + 40px); */
  /* width: calc(100% + -200px); */

  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
`;
//   margin-left: 12%;
// width: 75%;
const ListItemCovers = styled.li`
  margin: 0px 16px 32px;
  width: calc(16% - 25px);

  @media only screen and (min-width: 600px) and (max-width: 800px) {
    margin: 0px 16px 32px;
    width: calc(50% - 32px);
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0px 16px 32px;
    width: 100%;
  }
`;
const ListCovers = styled.ul`
  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
`;
const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
    padding:10px 0px;
    padding-bottom:20px;
`;
const BoxRow = styled.div`
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 20px;

    @media (max-width: 768px) {
      display: inherit;
    }
`;

const Column = styled.div`
  width:23%;
  cursor: pointer;
  margin: 10px;
  position:relative;
  @media (max-width: 768px) {
     width:93%;
  }
`;

const CoverSecion = styled.div`
  width:100%;
  height:100%;
  // overflow-y:${p => p.overflowItem ? "scroll" : "hidden"};
`
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const CategoryMenu = styled.div`
    background-color:${COLOR.tabActive};
    border-radius: 0px 12px 12px 12px;
    color: #fff;
    height: 25px;
    padding-left: 20px;
    padding-right: 2px;
    margin-top: 20px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:150px;
    align-items: center;
    cursor: pointer;

`;
const MenuIconImg = styled.img`
    margin-left:10px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
`;
const SearchInput = styled.input`
    width:100px;
    color:#fff;
    border:none;
    background: transparent;
    outline: none;
    text-align: center;
    font-weight: 800;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${COLOR.white};
    }
    ::focus{
        outline: none;
    }
`;
const NewTag = styled.div`
    color:#fff;
    background-color:rgb(211,51,49);
    text-transform: uppercase;
    font-weight:800;
    font-weight: 800;
    width: 60px;
    position: absolute;
    text-align:center;
    top: -22px;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const filterWithTextVideos = (units, txt) => {
  const itemtext = txt.toLowerCase().trimStart().trimEnd()
  return filter(units, k => {
    if (get(k, 'categorias', "").toLowerCase().includes(itemtext) || get(k, 'tags', "").toLowerCase().includes(itemtext)) {
      return true;
    } else if (get(k, 'shortName', "").toLowerCase().includes(itemtext)) {
      return true;
    } else {
      return false;
    }
  })

}
const sortUnits = function (a, b) {
  if (Number(a.id) < Number(b.id)) {
    return 1;
  }
  if (Number(a.id) > Number(b.id)) {
    return -1;
  }
  // a must be equal to b
  return 0;
}
const BalconCulturalSmDvc = ({
    currentUser,
    searchQuery ="",
    apThemeFilter=[],ibThemeFilter=[],selectedCategoray="",anecotasThemeFilter=[], isSelectedFilter="", mostUsedVideo
  }) => {
    const history = useHistory();
    const bookUnitList = useBookUnits()
    const demoStatus = useDemoStatus()
    const [, invokeAlert, revokeALert] = useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    const BALCON_PAGES = _.get(_.find(bookUnitList, ["bookId", CHAPTER_ID]), "pages", [])
    const BALCON_IMAGE = _.get(_.find(bookUnitList, ["bookId", CHAPTER_ID]), "img", BALCON_COVER_BASE64)
    const [units, setUnits] = useState(BALCON_PAGES)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loader,setLoader] = useState('');
    const [alert, setAlert] = useState({
      open: false,
      message: '',
      type: 'error'
    });
    const timeZone = useOrgTimezone();
    const allFavorites = useFavouriteVideo();
    const { firestore } = useFirebase()
    const { createResourceViews } = useResourceViews()
    const [fetchFavoritesList, setFetchFavorites] = useState(allFavorites);
    const [isFetchDataComplete, setIsFetchDataComplete] = useState(false);

    useEffect(() => {
      if (isFetchDataComplete) {
        const createFavourite = async () => {
          await dispatch(handleCreateFavouriteVideo(fetchFavoritesList));
        }
        createFavourite();
      }
    }, [fetchFavoritesList])

    useEffect(()=>{
      if(selectedCategoray === "category"){
        fetchFavorites();
      }
    },[isSelectedFilter])

    useEffect(() => {
      if (orgPermissions && typeof orgPermissions['balcónCultural'] === "boolean") {
        if (!orgPermissions['balcónCultural']) {
          invokeAlert()
          setTimeout(() => {
            history.goBack();
            revokeALert()
          }, 5000)
        }
      }
    }, [orgPermissions])
    const showCopyRight = () => {
      setAlert({
        open: true,
        message: "Content is protected!",
        type: "error"
      })
    }
    useEffect(() => {
      setUnits(sortUnitsByNew(BALCON_PAGES))
    }, [bookUnitList])
    const fetchData = async () => {
      const forUserLoaded = localStorage.getItem("forUser");
      if (forUserLoaded == currentUser?.id || forUserLoaded == "nouser") {
        setLoading(false);
      } else {
        if (Array.isArray(bookUnitList) && bookUnitList.length > 0) {
          setLoading(false);
        } else {
          setLoading(true);
        }
      }
      await dispatch(handleListBookUnits())
      setLoading(false);
      localStorage.setItem("forUser", currentUser?.id ?? "nouser");
    }
    useEffect(() => {
      fetchData()
    }, [])
    const showAdoption = () => {
      setAlert({
        open: true,
        message: "Coming soon!",
        type: "success"
      })
    }
    const handleClose = () => {
      setAlert({
        open: false,
        message: "",
        type: "error"
      })
    };
    const onPageClick = (item, i, index) => {
      const startDate = get(item, 'startDate');
      if (startDate) {
        if (isAfterTime(startDate, moment(), timeZone)) {
          showAdoption()
        } else {
          const url = `/balconCulturalBook?id=${item.id}&page=${item.start}&activity=null&quiz=null`
          history.push(url)
        }
      }
      else {
        const firstitem = i == 0 && index === 0
        if (demoStatus && !firstitem) {
          setAlert({
            open: true,
            message: "Restricted access (legend)",
            type: "error"
          })
          return;
        }
        const url = `/balconCulturalBook?id=${item.id}&page=${item.start}&activity=null&quiz=null`
        history.push(url)
      }
    }
    const sortUnitsByNew = (res) => {
      const newUnits = filter(res, ["new", true]).sort(sortUnits)
      const oldUnits = filter(res, k => !k.new).sort(sortUnits)
      return [...newUnits, ...oldUnits]
    }
    const searchUnits = (text) => {
      if (!text || text.length < 2) {
        setUnits(sortUnitsByNew(BALCON_PAGES))
      } else {
        setUnits(sortUnitsByNew(filterWithTextVideos(BALCON_PAGES, text)))
      }
    }
    const BoxRow = styled.div`
  display: flex;
  flex-wrap: nowrap; // Ensures the items do not wrap to a new line
  justify-content: flex-start; // Aligns items at the start
  margin-bottom: 1rem;
`;

    // Styled component for each column to align within the row
    const Column = styled.div`
    // flex: 1; // Ensures each column has equal width
    // padding: 0.5rem;
    // @media only screen and (min-width: 1000px) and (max-width: 1030px) {
    //     width: max-content; // Adjust width when viewport is between 1000px and 1030px
    //   }
      flex: none; // Prevents column from stretching
   padding: 0.5rem;
   
  @media only screen and (max-width: 450px) {
    width: 25%; /* Adjust width for screens up to 450px */
  }

  
  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    width: 25%; /* Adjust width for screens between 451px and 1366px */
  }
  @media only screen and (min-width: 1001px) and (max-width: 1366px) {
    width: 24vw; /* Adjust width for screens between 451px and 1366px */
  }

    `;

    const [isMobile, setIsMobile] = useState(window.innerWidth < 450);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // No dependencies, it runs on mount and unmount

  const chunkSize = isMobile ? 4 : 4; // Determine chunk size based on screen width
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1030);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1030);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount

  const className = isSmallScreen ? 'newdes' : '';
  const newdes = {
    

    color: '#fff',
  backgroundColor: 'rgb(211, 51, 49)', // conditional
  textTransform: 'uppercase',
  fontWeight: 800,
  width: '60px',
  position: 'absolute',
  textAlign: 'center',
  top: '-13px',
  // fontSize: '10px',
  };


  const getFilteredResult = (result) => {
    const matchThemes = (dataThemes, filterThemes) => {
      if (!dataThemes || dataThemes.length === 0 || filterThemes.length === 0) return false;
      const themes = dataThemes.toLowerCase().split(',').map(theme => theme.trim());
      return filterThemes.some(filter => 
        themes.some(theme => theme.includes(filter.toLowerCase()))
      );
    };
  
    let filteredResults = new Set();

    if(searchQuery !== "" && searchQuery?.length > 0){
        sortUnitsByNew(filterWithTextVideos(BALCON_PAGES, searchQuery)).forEach(item => filteredResults.add(item));
    }

    if(selectedCategoray !== "" && selectedCategoray?.toLowerCase() !== "all"){
      result.filter(data => data.Categorias?.toLowerCase().includes(selectedCategoray?.toLowerCase()))
      .forEach(item => filteredResults.add(item));
    }
  
    if (anecotasThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.anécdotas_themes, anecotasThemeFilter))
            .forEach(item => filteredResults.add(item));
            console.log(filteredResults)
    }
  
    if (ibThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ib_themes, ibThemeFilter))
            .forEach(item => filteredResults.add(item));
    }
  
    if (apThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ap_themes, apThemeFilter))
            .forEach(item => filteredResults.add(item));
    }
  
    // If no filters are active, return all results
    if (filteredResults.size === 0 && 
        anecotasThemeFilter.length === 0 && 
        ibThemeFilter.length === 0 && 
        apThemeFilter.length === 0 &&
        (selectedCategoray.length === 0 || selectedCategoray.toLowerCase() === "all") &&
        searchQuery?.length === 0
      ) {
      return result;
    }
  
    return Array.from(filteredResults);
  };

  const [mostViewed,setMostViewed] = useState([]);

  const fetchFavorites = async () => {
    const data = {
      user_id: currentUser.id,
      type: 'balcon'
    }
    const result = await dispatch(handleListFavouriteVideo(data));
    setIsFetchDataComplete(true);
    setFetchFavorites(result);
  }
  const balconUnits = useMemo(()=>{
    if(isSelectedFilter==="category"){
      return  sortUnitsByNew(getFilteredResult(fetchFavoritesList?.videos));
    }
    else if(isSelectedFilter === "used"){
      return getFilteredResult(mostUsedVideo);
    }
    else if(isSelectedFilter === "viewed"){
      const data = BALCON_PAGES.filter(item =>
        mostViewed.some(viewItem => viewItem.id === item.id)
      ).map(item => {
        const viewItem = mostViewed.find(view => view.id === item.id)
        return { ...item, viewed_video: viewItem ? viewItem.viewed_video : 0 };
      })
      return  sortUnitsByNew(getFilteredResult(data)).sort((a, b) => b.viewed_video - a.viewed_video)  ;
    }
    const data = sortUnitsByNew(getFilteredResult(BALCON_PAGES));
    return data;
  },[apThemeFilter,ibThemeFilter,anecotasThemeFilter,selectedCategoray,isSelectedFilter,searchQuery,BALCON_PAGES, mostUsedVideo])



  const handleFavouriteVideo = async (data) => {
    const isBookInFavorites = fetchFavoritesList?.videos?.some(video => video.id === data.id);
    const updatedVideos = isBookInFavorites
    ? fetchFavoritesList.videos.filter(video => video.id !== data.id)
    : [...(fetchFavoritesList?.videos || []), data];
    let updatedData;
    if (fetchFavoritesList?.id) {
      updatedData = {
        ...fetchFavoritesList,
        videos: updatedVideos,
      };
    } else {
      updatedData = {
        user_id: currentUser.id,
        videos: updatedVideos,
        type: 'balcon'
      };
    }
    setLoader(data?.id);
    setFetchFavorites(updatedData);
    // await dispatch(handleCreateFavouriteVideo(updatedData));
    setLoader('');
  }


  useEffect(() => {
    let unsubscribe = () => {};
  
    listenToMostViewed("balcon-cultural").then(unsubscribeFunc => {
      unsubscribe = unsubscribeFunc;
    });
  
    return () => unsubscribe();
  }, []);

  const listenToMostViewed = (type) => {
    return new Promise((resolve) => {
      const collectionRef = collection(firestore, "most_viewed_video");
      const q = query(collectionRef,where("type", "==", type), orderBy("viewed_video", "desc"));
      
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const viewData = [];
          querySnapshot.forEach((doc) => {
            viewData.push({ id: doc.id, ...doc.data() });
          });
          setMostViewed(viewData);
        },
        (error) => {
          console.error("Error listening to session changes:", error);
        }
      );
  
      resolve(unsubscribe);
    });
  };

  const createViewedVideo = (id) => {
    const data = mostViewed.find(data => data.id === id);
    const CreateNewView = {
      type: "balcon-cultural",
      id: id,
      viewed_video: (data?.viewed_video || 0) + 1
    };
    createResourceViews(CreateNewView)
  };

    return (
      <Wrapper
        onContextMenu={(e) => {
          e.preventDefault();
          showCopyRight();
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alert.open}
          autoHideDuration={3000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={alert.type}>
            <strong>ALERT:</strong> {alert.message}
          </Alert>
        </Snackbar>
        {loading ? (
          <CenterContainer>
            <Loading />
          </CenterContainer>
        ) : (
          <>
            {/* <Row>
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                <CategoryMenu style={{ marginLeft: 30 }}>
                  <SearchInput type={"text"} placeholder="SEARCH" onChange={(e) => { searchUnits(e.target.value) }}  onFocus={e => e.target.placeholder = ''} onBlur={e => e.target.placeholder = 'SEARCH'} />
                  <MenuIconImg src={SearchIcon} />
                </CategoryMenu>
              </Col>
            </Row> */}
            <Row style={{ paddingTop: "1%", paddingBottom: 0, justifyContent: (balconUnits?.length === 0)  && "center" }}>
              {/* <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <ImageContainer
                >
                  <Images src={BALCON_IMAGE} />
                </ImageContainer>
              </Col> */}
              <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                <CoverSecion overflowItem={units.length > 12}>
                  {/* {chunk(units, 4).map((bookitem, i) => { */}
                  {balconUnits?.length > 0 ? (
                    <>
                     {chunk(balconUnits, chunkSize).map((bookitem, i) => {


return (<BoxRow key={i + "bookrow"}> {bookitem.map((item, index) => {
  return (
    <Column key={index + i + "bookcol"}>
      {get(item, "new", false) ? <NewTag style={newdes}>New</NewTag> : null}
      <BalconCover handleClick={() => {
        createViewedVideo(item?.id);
        onPageClick(item, i, index)
      }}
      page="balcon"
      loader={loader}
      allFavorites={fetchFavoritesList.videos}
      handleFavouriteVideo={handleFavouriteVideo}
      data={item}
      />
    </Column>
  );
})}
</BoxRow>)
})}
                    </>
                  ) : (
                    <EmptyImagePlaceholder />
                  )}
                 
                </CoverSecion>
              </Col>
            </Row>
          </>
        )}
      </Wrapper>
    );
  };
  
  //  -- Prop types ---------------------
  BalconCulturalSmDvc.propTypes = {};
  
  //  -- Default props ------------------
  BalconCulturalSmDvc.defaultProps = {};
  
  //  ----------------------------------------------------------------------------
  //  Redux
  //  ----------------------------------------------------------------------------
  const mapStateToProps = (state) => ({
    currentClass: state.currentClass,
    currentUser: state.currentUser,
    userBooksList: state.userBooksList,
    tipsBook: state.tipsBook,
    currentClass: state.currentClass,
    classUsers: state.classUsers,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    submissions: state.submissions,
    booksAllList: state.booksAllList
  });

  export default connect(mapStateToProps, null)(BalconCulturalSmDvc);
  // export default BalconCulturalSmDvc;