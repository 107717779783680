//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useRef,useState ,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import styled from 'styled-components';
import { useReactToPrint  } from 'react-to-print';

//  -- Components ---------------------
import { ArrowBackIos, ArrowForwardIos,ArrowBack } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------
import NewsletterTooling from 'features/NewsletterTooling';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const ButtonContainer = styled.button`
    background-color: transparent;
    border: none; 
    color: ${COLOR.black600};
    cursor: pointer;
    height: 40px;
    left: ${p => `${p.left}px`};
    outline: none;
    position: absolute;
    right: ${p => `${p.right}px`};
    top: 0px;
    transition: all 300ms ease;
    width: 40px;
    z-index: 1;

    @media (min-width: 800px) {
        position: absolute;
        top: 0%;

        &:hover {
            color: ${COLOR.black200};
        }
    }
`;

const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    max-width: 1320px;
    padding: 64px 0px 0px;
    position: relative;
    width: 100%;

    @media (min-width: 800px) {
        flex-direction: row;
        padding: 0px 64px;
    }
`;

const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
`;

const ToolsContainer = styled.div`
    height: 100%;
    margin-top: 48px;
    width: 100%;

    @media (min-width: 800px) {
        margin-left: 48px;
        margin-top: 0px;
        width: 300px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    padding: 2% 0px;
    position: relative;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook
//
//  -- Description
//  Ebook display component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const NewsletterLayout = ({
    data,
    id,
    page,
}) => {
    const history = useHistory();
    const generateUrl = (page) => `/newsletter?id=${id}&page=${page}`;
    const [printLoader,setPrintloader]=useState(false)
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;

    const showCopyRight = () => {
        setState({open: true,vertical: 'top', horizontal: 'center'});
    }

    const handleClose = () => {
        setState({ ...state, open: false });
    };
   useEffect(() => {
    preloadImage()
     }, [])
    // Used to printPDF
    const imageRef = useRef();
    const handleImagePrint = useReactToPrint({
        content: () => imageRef.current,
        onBeforeGetContent:()=>{setPrintloader(true)},
        onBeforePrint:()=>{
            setPrintloader(false)
        }
    });
    const preloadImage =()=>{ 
        let cPage=parseInt(page,10)
        for(let i=cPage;i<cPage+2;i++){
            if(data[i]?.src)
            new Image().src=data[i]?.src
        }
    }
    return (
        <>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open} 
            autoHideDuration={3000}
            onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> Content is protected!
                </Alert>
            </Snackbar>
            <Button 
             style={{paddingLeft:0,marginLeft:-10}}
              onClick={() => {
               history.push("/resources")  
          }} >
           <ArrowBack style={{ fontSize: '14px', marginRight: '4px' }} /> Back to Resources              
        </Button>
        <Wrapper className="slideLftRghtUpr">
          <div className="slideLftRght"> 
                <ButtonContainer
                    disabled={page === 1} onClick={() => history.push(generateUrl(page - 1))} left={0}>
                    <ArrowBackIos style={{ fontSize: '40px' }} />
                </ButtonContainer>
                <ButtonContainer
                    disabled={page === data?.length}
                    onClick={() =>{ 
                        preloadImage()
                        history.push(generateUrl(page + 1))}}
                    right={0}>
                    <ArrowForwardIos style={{ fontSize: '40px' }} />
                </ButtonContainer>
            </div>
            <Container className="eBookTabLftUpr">
                <ImageContainer onContextMenu={(e)=>{
                        e.preventDefault();
                        showCopyRight()
                }}>
                    <div ref={imageRef} >
                    <Images src={data[page - 1]?.src} />
                    </div>
                    
                </ImageContainer>
                <ToolsContainer>
                    <NewsletterTooling
                        id={id}
                        limit={data.length}
                        page={page}
                        handleImagePrint={handleImagePrint}
                        printLoader={printLoader}
                    />
                </ToolsContainer>
            </Container>
        </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
NewsletterLayout.propTypes = {
    children: string
};

//  -- Default props ------------------
NewsletterLayout.defaultProps = {
    children: 'Hello world'
};

export default NewsletterLayout;
