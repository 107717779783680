//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { CheckSquareFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { find , get } from 'lodash';
import { useAllBook } from "store/books/selectors"
import { NEWSLETTERS } from "utils/config/reference";

const getNewLatterUri=(page)=>(`newsletter?id=N823aa3cc210843d3a6469815f2534e5d&page=${page}`)
const getEbookUri=(chapter,page,activity,quiz)=>(`/ebook?chapter=${chapter}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`)
const getUri=(chapter,page,activity,quiz,booksAllList)=>{
    if(NEWSLETTERS[chapter]){
        return getNewLatterUri(page)
    }
    find(booksAllList,['id',chapter])
    if(find(booksAllList,['id',chapter])){
        return getEbookUri(chapter,page,activity,quiz)
    }
}

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 3px;
    color: ${p => p.color};
    cursor: pointer;
    font-size: 21px;
    height: 32px;
    margin: 0px;
    outline: none;
    transition: all 300ms ease;

    &:hover {
        color: ${p => p.color};
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 32px;
    right: -15px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index:99;
`;

const Input = styled.input`
    background-color: transparent;
    border: 1px dashed transparent;
    border-radius: 3px;
    box-shadow: none;
    font-size: inherit;
    font-weight: 600;
    outline: none;
    padding: 0px 2px;
    transition: all 300ms ease;
    width:60px;
`;

const Form = styled.form`
    algin-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Page changing form
//
//  -- Description
//  Allows changing the page in the ebook and newsletter tooling
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
const PageEditor = ({
    activity,
    chapter,
    limit,
    page,
    quiz,
    value,
    editDiabled=false,
    content,
    numberMasked,
    onChangeNumber
}) => {
    const history = useHistory();
    const [editing, toggleEditing] = useState(false);
    const [tempValue, setTempValue] = useState(value);
    const bookAllList = useAllBook()
    useEffect(() => {
        if(tempValue!==numberMasked){
             setTempValue(numberMasked)
        }
    },[numberMasked])
   
    const editValue = () => {
        toggleEditing(true);
    };

    const handleChange = (e) => {
        e.preventDefault();
        if(e.target.value.length>0||!e.target.value){
            setTempValue(e.target.value);
        } 
    };

    const submit = async (e) => {
        e.preventDefault();
        if(onChangeNumber){
            onChangeNumber(tempValue)
            toggleEditing(false);
            return;
        }
        const pageData=find(content,item=>{
            if(!item?.numberMasked||!tempValue) return null;
            if(`${tempValue}`.toLowerCase()===`${item?.numberMasked}`.toLowerCase()){
                return item;
            }else{
                return null;
            }
        })
        if(pageData){
            history.push(getUri(chapter,pageData.pageId,activity,quiz , bookAllList ))
        }
        else if (tempValue !== '' && parseInt(tempValue, 10) >= 1 && parseInt(tempValue, 10) <= limit) {
          let pageD = find(content,item=>(parseInt(tempValue, 10) == item.pageId))
          if(pageD){
            history.push(getUri(chapter,parseInt(tempValue, 10),activity,quiz,bookAllList))
          }else{
            setTempValue(numberMasked); 
          }
        } else {
            // history.push(getUri(chapter,page,activity,quiz,bookAllList))
            setTempValue(numberMasked);
        }
        
        toggleEditing(false);
    };

    return (
        <Form onSubmit={submit}>
            <Input
                id="page"
                onClick={editValue}
                onChange={handleChange}
                value={tempValue}
                disabled={editDiabled}
            />
            {editing && (
                <Column>
                    <Button color={COLOR.green100}  type='submit'>
                        <CheckSquareFilled />
                    </Button>
                </Column>
            )}
        </Form>
    );
};

//  -- Prop types ---------------------
PageEditor.propTypes = {};

//  -- Default props ------------------
PageEditor.defaultProps = {};

export default PageEditor;