import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Switch, TimePicker, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DebateNewLogo, EditDebate } from "utils/assets/images";
import { map, get } from "lodash";
import { MenuItem, Select } from "@material-ui/core";
import { useFirebase } from "containers/FirebaseContext";
import { useCurrentUser } from "store/users/selectors";

function formatTime(totalSeconds) {
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

const DebateTimer = ({
    debateResponse,
}) => {
    const { firestore, database, onValue, databaseRef, databaseSet, } = useFirebase();
    const currentUser = useCurrentUser();
    const [timer, setTimer] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const handleEditTImer = () => {
        setIsEdit(!isEdit);
    }


    const editTimer = async (editValue) => {
        try {
          const newEditTime = editValue + timer;
          if (newEditTime < 3600) {
            saveTimmerRealTime(debateResponse, newEditTime);
            // const docRef = doc(firestore, "debate", debateResponse?.id);
            // // Start the transaction
            // const docSnap = await getDoc(docRef);
            // if (docSnap.exists()) {
            //   await updateDoc(docRef, { "configurations.total_time" : newEditTime });
            // }
          }
        } catch (e) {
          console.log('Error adding document: ', e);
        }
      }

    useEffect(() => {
        try {
            if (timer && Object.keys(debateResponse).length > 0) {
                const intervalFunction = () => {
                    if (timer === 0) {
                        clearInterval(countdownInterval);
                        saveTimmerRealTime(debateResponse, 0);
                        return;
                    }
                    if (timer > 0 && get(currentUser, "role") === "teacher") {
                        const newTime = timer - 1;
                        if (newTime != timer) {
                            saveTimmerRealTime(debateResponse, newTime);
                        }
                    }
                };
                const countdownInterval = setInterval(intervalFunction, 1000);
                return () => clearInterval(countdownInterval);
            }
        } catch (e) {
            console.log('error running time', e);
        }
    }, [timer, debateResponse])

    const saveTimmerRealTime = async (debateData, time) => {
        const dbRef = databaseRef(database, 'debateTimer/' + debateData.id)

        databaseSet(dbRef, { time: time })
            .then((res) => {
            })
            .catch((error) => {
                console.log('Error updating time in the database:', error)
            })
    }


    useEffect(() => {
        if (Object.keys(debateResponse).length > 0) {
            const timerRef = databaseRef(database, 'debateTimer/' + debateResponse.id)
            const unsubscribe = onValue(timerRef, (snapshot) => {
                try {
                    const timerData = snapshot.val();
                    setTimer(timerData?.time);
                } catch (error) {
                    console.log('error in database timer', error);
                }
            })
            return () => unsubscribe()
        }
    }, [database, debateResponse])

    return (
        <div className="uprDebateStudntListing">
            <div className="debateMdlTop debateAllTopTxt debateAllTopSpace">
                <img src={DebateNewLogo} alt="Debate" />
                <div className="topSliderHeaderTxt">
                    <span>{debateResponse?.question}</span>
                </div>
            </div>
            <div className="stdntTopCommitSec">
                <div className="studntCommitSection">
                    <span>{formatTime(timer)}</span>
                    {isEdit &&
                        <div className="blogInputBox">
                            <div style={{ marginRight: 12 }}>
                                <button onClick={() => {
                                    editTimer(1 * 60);
                                }}>+ 1</button>
                            </div>
                        </div>
                    }
                    {get(currentUser, "role") === "teacher" && <img style={{ cursor: 'pointer' }} src={EditDebate} alt="Debate" onClick={() => { handleEditTImer() }} />}
                </div>
            </div>
        </div>
    );
};

export default DebateTimer;