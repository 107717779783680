//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ExamsActions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';
import * as OrganizationActions from '../organizations/actions';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------


export const handleListExams = () => async (dispatch) => {
    await dispatch(ExamsActions.listExamss());

    try {
        const exams = await API.get('exams', `/list`);
        if (Array.isArray(exams)) {
            await dispatch(ExamsActions.listExamssSuccess(exams));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.listExamssFail(error));
    }
};

export const handleCreateExams = (data) => async (dispatch) => {
    await dispatch(ExamsActions.createExams());
    try {
        const exam = await API.post('exams', `/create`, {
            body: data
        });
        if (has(exam, "id")) {
            await dispatch(ExamsActions.createExamsSuccess(exam));
        }
        return exam;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.createExamsFail(error));
    }
};
export const handleUpdateExams = (data) => async (dispatch) => {
    await dispatch(ExamsActions.updateExams());
    try {
        const exam = await API.put('exams', `/update`, {
            body: data
        });
        if (has(exam, "id")) {
            await dispatch(ExamsActions.updateExamsSuccess(exam));
        }
        return exam;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.updateExamsFail(error));
    }
};
export const handleDeleteExam = (id) => async (dispatch) => {
    await dispatch(ExamsActions.deleteExams());
    try {
        const exam = await API.del('exams', `/delete/${id}`);
        if (has(exam, "id")) {
            await dispatch(ExamsActions.deleteExamsSuccess(id));
        }
        return exam;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.deleteExamsFail(error));
    }
};
export const handleListExamDataByConfig = (data) => async (dispatch) => {
    try {
        const result = await API.post('exams', `/list_exam_grades`, {
            body: data
        });

        return result;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};
export const handleListExamDataforGraph = (data) => async (dispatch) => {
    try {
        const result = await API.post('exams', `/exam_graph_data`, {
            body: data
        });
        if (result) {
            await dispatch(OrganizationActions.setDistExamGraphData({
                config: data,
                data: result
            }));
        }
        return result;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};
export const handleGetGradebookData = (data) => async (dispatch) => {
    try {
        const result = await API.post('exams', `/get_gradebook_data`, {
            body: data
        });
        return result;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};
export const handleCreateDownloadList = (data) => async (dispatch) => {
    try {
        const result = await API.post('exams', `/create_download_gradebook`, {
            body: data
        });
        return result;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};
export const handlelistDownloads = (data) => async (dispatch) => {
    try {
        const result = await API.post('exams', `/list_download_by_class`, {
            body: data
        });
        return result;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};