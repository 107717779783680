//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Modal as ModalSection } from '@material-ui/core';
import { ArrowForward, Edit } from "@material-ui/icons";
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { Button as ButtonSection, Card, Col, Divider, Input, Modal, Row, Select } from 'antd';
import Loading from 'components/Loading';
import { PlusOutlined } from '@ant-design/icons';
import base64 from 'base-64';
//  -- Constants ----------------------
import { get, isEmpty, sortBy, filter, find } from 'lodash';
import { useDispatch } from "react-redux";
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { createKeystore } from "store/keystore/thunks";
import { useCurrentOrg } from 'store/organizations/selectors';
import { v4 } from 'uuid';
import SchoolForm from "./School";
import { useHistory } from 'react-router-dom';
import { handleEnableDiableUser, handleListOrgUsers, handleSearchStudents } from 'store/users/thunks';
import { handleGetOrgClasses } from 'store/classes/thunks';
import { handleListUserClasses } from 'store/userClasses/thunks';
const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const StableWrapper = styled.div`
 width: 100%;
  overflow-x: auto;
//   padding:24px;
//   @media (max-width: 992px) {
//     padding:24px 0px !important;
//   }
`;
const StableWrapperMain = styled.div`
`;
const ViewSectionText = styled.p`
    color:black;
    font-size:17px;
    font-weight:400;
`;
const SectionText = styled.span`
   font-weight:600;
`;

const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
font-size: 12px;
font-weight: 600;
`;
const OverViewValue = styled.span`
font-size: 17px;
font-weight: 500;
cursor: pointer;
text-transform:capitalize;
`;
const ProfileContainer = styled.span`
    display:flex;
    align-items:center;
    flex-direction:row;
`;
const FilterContainer = styled.span`
        font-size:17px;
        font-weight:500;
        cursor:pointer;
    `;
const ViewDemo = styled.span`
textAlign: "left";
color: ${(p) => (p.color)};
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const ParentTable = ({
    users,
    schools,
    classes,
    userloading,
    teacherByOrg,
    selectedDist,
    distStudents,
    onUserAddOrUpdate = () => null,
    onTabsJump = () => null,
    allUser
}) => {
    const [schoolForm, setSchoolForm] = useState(false);
    const [editSchool, setEditSchool] = useState(false);
    const [school, setSchool] = useState(null);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [showSchoolsTeachers, setShowSchoolsTeachers] = useState(-1);
    const currentOrg = useCurrentOrg()
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = React.useState(0);
    const [loginLoader, setLoginLoader] = useState(null)
    const [modal2Open, setModal2Open] = useState(false);
    const [enableLoader, setEnableLoader] = useState(-1);
    const [searchStudent, setSearchStudent] = useState([]);
    const [data, setData] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const [schoolName, setSchoolName] = useState({});
    const [alertForAccount, setAlertForAccount] = useState(false)
    const [userToEnable, setuserToEnable] = useState({})


   
  
    useEffect(() => {
        dispatch(handleGetOrgClasses([currentOrg, ...schools]));
    }, [])
    useEffect(() => {
        const parentUsers = filter(users, obj => obj?.role === 'parent')
        setFilteredSchools(sortBy(parentUsers, ["firstName", "lastName"]))
        setSearchStudent(sortBy(parentUsers, ["firstName", "lastName"]))
    }, [users])

    const handleSearch = async (event) => {
        if (event.target.value && event.target.value.length > 2) {
            var search = event.target.value;
            const searchStudentByApi = await handleSearchStudents(currentOrg?.id, search);
            // if (searchStudentByApi?.length > 0) {
            const filtered = searchStudentByApi?.length && searchStudentByApi.filter(x => {
                return (
                    `${x.firstName} ${x.lastName}`.toLowerCase().includes(search.toLowerCase())
                )
            })
            setFilteredSchools(sortBy(filtered, ["displayName"]))
            // }
        } else {
            setFilteredSchools(sortBy(searchStudent, ["displayName"]))
            setShowSchoolsTeachers(-1);
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const goToClass = async (id, user) => {
        openLoginInNewWindow(user.id)
    };
    const openLoginInNewWindow = async (userId) => {
        setLoginLoader(userId);
        const key = v4()
        await createKeystore(userId, key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoginLoader(null);
    }

    const options = [];
    for (let i = 10; i < 36; i++) {
        options.push({
            value: i.toString(36) + i,
            label: i.toString(36) + i,
        });
    }

    const addTeachers = (value) => {
        const editData = {
            userData: data,
            isEdit: true
        }
        const addData = {
            userData: null,
            isEdit: false
        }
        if (value == 'edit') {
            history.push({ pathname: "/add-users", state: editData });
        } else {
            history.push({ pathname: "/add-users", state: addData });
        }
    }

    const enableDisableUser = async (userId, status) => {
        setEnableLoader(userId)
        await dispatch(handleEnableDiableUser(userId, status));
        setEnableLoader(-1)
        setAlertForAccount(false)
        setuserToEnable({})
    }

    const handleTeacherClasses = async (e) => {
        await dispatch(handleListUserClasses(e?.id))
    }
    const onClickRow = (row,schoolName) =>{
        setModal2Open(!modal2Open);
        setData(row);
        setSchoolName(schoolName?.displayName);
    }
    return (
        <>
            <ModalSection
                open={alertForAccount}
                onClose={() => setAlertForAccount(false)}
            >
                <ModalContent>
                    Are you sure you want to {get(userToEnable, "active", true) ? "disable" : "enable"}  {get(userToEnable, "firstName", '')}  {get(userToEnable, "lastName", '')}
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setAlertForAccount(false)
                                setuserToEnable({})
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ marginRight: '4px' }}
                            color="secondary"
                            variant="contained"
                            className={`${enableLoader != -1 ? "disabled-button" : ""}`}
                            onClick={() => enableDisableUser(userToEnable.id, !userToEnable.active)}
                        >
                            {enableLoader != -1 ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </ModalSection>
            {schoolForm ? (
                <StableWrapper style={{ padding: 24 }}>
                    <SchoolForm
                        currentOrg={currentOrg}
                        school={school}
                        editing={editSchool}
                        onClose={() => {
                            setSchoolForm(false);
                            setSchool(null);
                            setEditSchool(false);
                            onUserAddOrUpdate()
                        }}
                    />
                </StableWrapper>
            ) : (
                <StableWrapperMain>
                    <Row style={{ marginBottom: 15 }}>
                        <Col>
                            <ButtonSection style={{ minHeight: 38 }} type="primary" onClick={() => addTeachers()}>
                                <PlusOutlined /> Add Parent
                            </ButtonSection>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={24}>
                            <ViewSectionText>
                                View <SectionText>Parents</SectionText> records.
                            </ViewSectionText>
                        </Col>
                        <Col xs={24} lg={24}>
                            <FilterContainer className="FilterHeading">
                                <Card
                                    type="inner"
                                    title="Filters"
                                // extra={<a>Clear</a>}
                                >
                                    <Row>
                                        <Col xs={24} lg={24} md={24} className="ColCardPadding">
                                            <Card style={{ width: '100%' }}>
                                                <Input placeholder="Search Students by name" bordered={false} style={{ height: '32px' }}
                                                    onChange={handleSearch}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </FilterContainer>
                        </Col>
                    </Row>

                    <StableWrapper style={{ marginTop: 30 }}>
                        <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>School Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>First Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Last Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Parent of</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isEmpty(filteredSchools) && (
                                    userloading ? <Loading size={30} /> : (
                                        <TableRow key={'0'} style={{ border: 0 }}>
                                            <TableCell colSpan={6} style={{ textAlign: "center" }}>No results</TableCell>
                                        </TableRow>
                                    )
                                    )}
                                {(!isEmpty(filteredSchools)) && (
                                    filteredSchools.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const schoolName = find(schools, obj => obj.id == row.orgId);
                                        const demoSchool = get(schoolName, 'demo', '');
                                        const student = find(users, obj => obj.id == row.parent_of)
                                        row.student = student;
                                        return (
                                            <>
                                                <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, cursor: 'pointer' }} onClick={() => handleTeacherClasses(row)}>
                                                    <TableCell onClick={() => { onClickRow(row, schoolName) }} style={{ textAlign: "left", cursor: 'pointer' }} >{get(schoolName, 'displayName', '')} <ViewDemo color={BORDERCOLOR[3]}>{demoSchool && "(Demo)"}</ViewDemo></TableCell>
                                                    <TableCell onClick={() => { onClickRow(row, schoolName) }} style={{ textAlign: "left" }}>{row.firstName}</TableCell>
                                                    <TableCell onClick={() => { onClickRow(row, schoolName) }} style={{ textAlign: "left" }}>{row.lastName}</TableCell>
                                                    <TableCell onClick={() => { onClickRow(row, schoolName) }} style={{ textAlign: "left" }}>{student ? `${student.firstName || ''} ${student.lastName}` : ''}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>
                                                        <Button
                                                            variant="contained"
                                                            color={row.active ? "primary" : "secondary"}
                                                            onClick={x => {
                                                                setAlertForAccount(true)
                                                                setuserToEnable(row)
                                                            }} style={{ marginRight: '8px', color: COLOR.white, fontSize: 12, width: 80 }} >
                                                            {enableLoader == row.id ? <Loading size={'18px'} white /> : (row.active ? "Enabled" : 'Disabled')}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: (showSchoolsTeachers == index ? '' : 'none') }} height={showSchoolsTeachers == index && teacherByOrg[row.id]?.length > 0 ? ((teacherByOrg[row.id]?.length * 39) + 8) : 0}>
                                                    <TableCell colSpan={6}>
                                                        <Table style={{ width: "100%" }}>
                                                            {teacherByOrg[row.id]?.map((t) => (
                                                                <TableRow key={t.id} style={{ border: 0, width: "100%" }}>
                                                                    <TableCell style={{ textAlign: "center" }}>{`${get(t, "firstName", "")} ${get(t, "lastName", "")}`}</TableCell>
                                                                    <TableCell style={{ textAlign: "center" }}>
                                                                        <Button onClick={() => { goToClass(t.id, t); }} disabled={!t.active} style={{ cursor: t.active ? "pointer" : "not-allowed", color: t.active ? "black" : "red" }}>
                                                                            {loginLoader == t.id && <Loading size={14} />}  Log in to account
                                                                            <ArrowForward style={{ fontSize: "11px", marginLeft: "8px" }} />
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    }

                                    )
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {!isEmpty(filteredSchools) && <TablePagination
                                        count={filteredSchools.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                    />}
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </StableWrapper>
                </StableWrapperMain>
            )}

            <Modal
                footer={null}
                title={get(data, 'firstName', '') + ' ' + get(data, 'lastName', '')}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={'60%'}
                className="FilterHeading"
            >
                <ProfileContainer>
                    <ButtonSection type="primary" ghost onClick={() => addTeachers('edit')}>
                        Edit Profile
                    </ButtonSection>
                    <ButtonSection type="primary" style={{ marginLeft: 10 }} disabled={!data?.active} onClick={() => { goToClass(data?.id, data); }} >
                        {`Log in as ${get(data, 'firstName', '')} ${get(data, 'lastName', '')}`}
                    </ButtonSection>
                </ProfileContainer>
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="Detail View"
                >
                    <Row>
                        <Col xs={24} lg={24}>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>First name</OverViewLabel>
                                <OverViewValue>{get(data, 'firstName', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Username</OverViewLabel>
                                <OverViewValue>{get(data, 'username', '')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Status</OverViewLabel>
                                <OverViewValue>{data?.active ? "Enabled" : 'Disabled'}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>Last name</OverViewLabel>
                                <OverViewValue>{get(data, 'lastName', '')}</OverViewValue>
                            </OverViewContainer>

                            <OverViewContainer>
                                <OverViewLabel>Password</OverViewLabel>
                                <OverViewValue>{data?.password ? base64.decode(data?.password) : ''}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>Parent of</OverViewLabel>
                                <OverViewValue>{`${get(data, 'student.firstName', '')} ${get(data, 'student.lastName', '-')}`}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col xs={12} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>School Name</OverViewLabel>
                                <OverViewValue style={{ color: 'rgb(20, 100, 255)', cursor: 'pointer' }} onClick={() => onTabsJump("see_schools", data)}>{schoolName}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
};


//  -- Prop types ---------------------
ParentTable.propTypes = {};

//  -- Default props ------------------
ParentTable.defaultProps = {};

export default ParentTable;
