
import React from 'react';
import styled from 'styled-components';


//create tootltip in html and css
const Tooltip = styled.div`
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #191515a1;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    font-size: 12px;
    z-index: 1;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
    transition: all 0.5s;
    text-align: center;
    padding: 5px 5px;
    display: flex;
    width: 230px;
    justify-content: center;
`;
const TooltipArrow = styled.div`
    position: absolute;
    top: calc(100%);
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #191515a1;
    z-index: 1;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? '1' : '0'};
`;
//Tootip wrapper 
const TooltipWrapper = styled.div`
    position: relative; 
    &:hover ${Tooltip} {
        visibility: visible;
        opacity: 1;
    }
    &:hover ${TooltipArrow} {
        visibility: visible;
        opacity: 1;
    }
`;
//Tooltip arrow 

export default function Toottip(props) {
    const { hasToolTip, hideVisability, title, children } = props;
    return (
        <TooltipWrapper>
            {children}
            {hasToolTip && !hideVisability && (<>
                <Tooltip>
                    {title}
                </Tooltip>
                <TooltipArrow />
            </>)}
        </TooltipWrapper>
    );
}

