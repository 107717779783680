import { DownOutlined } from "@ant-design/icons";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Button, Checkbox, Col, Dropdown, Input, Menu, Row, Switch } from "antd";
import Loading from "components/Loading";
import React,  { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { DebateNewLogo, IdeaAi } from "utils/assets/images";
import { handleDebateAI } from "store/debate/thunks";
import { map, get, filter , find } from "lodash";
import { debounce } from 'underscore';
import { MenuItem, Select, TextField, makeStyles } from "@material-ui/core";
import { useClassUsers } from "store/userClasses/selectors";
import { useSchoologyGradeCategory } from 'store/schoologyauth/selectors';
import { useCurrentUser } from "store/users/selectors";
import { GRADE_TYPE_ALL, GRADE_TYPE_CANVAS } from 'utils/config/reference';
import { useCanvasAssignmentCategory } from 'store/canvas/selectors';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import { useCurrentClass } from "store/classes/selectors";
import Acent from "components/Acent";
const LoaderWrapper = styled.div`
  // position:absolute;
  z-index: 100;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSelect-select': {
            backgroundColor: 'transparent', // Set background color to transparent
        },
    },
}));

const GradeConfig = ({ options, value, label, onChange, hasInput, objValue = {} }) => {
    const label_txt = useMemo(() => {
        const f_value = value ? find(options, k => k.value == value || k.id == value) : null;
        if (f_value) {
            return `${label}(${f_value.title || f_value.type})`
        }
        return label;
    }, [value, options, label])
    const handleMenuClick = (e) => {
        const f_value = find(options, k => k.value == e.key || k.id == e.key)
        if (f_value) {
            if(f_value.value === "points"){
                onChange({ ...f_value, max_point: 100 })
            }else{
                onChange(f_value)
            }

        }
    };
    const menu = (
        <Menu onClick={handleMenuClick}>
            {map(options, pt => <Menu.Item key={pt.id || pt.value}>
                {pt.title || pt.type}
            </Menu.Item>)}
        </Menu>
    );
    const handleInputChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 100) {
            onChange({ ...objValue, max_point: value });
        }
    };
    return (
        <Row style={{ marginBottom: 8 }}>
            <Col lg={12} xs={24} md={12} style={{ display: "flex", height: 30 }}>
                <Dropdown overlay={menu}>
                    <div className='linksSectionIcon text-dark' style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        {value ? (
                            <span style={{ height: 20, marginRight: 8 }}>
                                <CheckCircleOutlineIcon style={{ color: 'green' }} />
                            </span>
                        ) : (
                            <span style={{ height: 20, marginRight: 8 }}>
                                <AddCircleSharpIcon />
                            </span>
                        )}
                        {label_txt}
                    </div>
                </Dropdown>
                {hasInput && value === "points" && <Input
                    placeholder="max points"
                    defaultValue={"100"}
                    inputMode="numeric"
                    style={{ borderRadius: 20, width: 100, marginLeft: 8 }}
                    type="numeric"
                    max={100}
                    min={0}
                    onChange={handleInputChange} />}
            </Col>
        </Row>
    );
};


const DebateLiveSection = ({
    handleLiveShare = () => null,
    shareLoader,
    setOpenTeacherModal,
    onAiError = () => null
}) => {
    const classes = useStyles();
    const classUsers = useClassUsers()
    const totalClassStudent = get(filter(classUsers, x => x.role === 'student'), "length", 0)
    const [isTextVisible, setIsTextVisible] = useState(false);
    const [configurations, setConfigurations] = useState({
        total_receive: `${totalClassStudent > 5 ? 5 : totalClassStudent - 1}`,
        total_replies: `${totalClassStudent > 8 ? 8 : totalClassStudent - 1}`
    }
    );
    const [inputValue, setInputValue] = useState("");
    const [timeInput, setTimeInput] = useState("");
    const [replies, setReplies] = useState("1");
    const [response, setResponse] = useState("1");
    const [isLiveDebate, setIsLiveDebate] = useState(false);
    const [liveDebateLoader, setLiveDebateLoader] = useState();
    const [liveDebateInputVal, setLiveDebateInputVal] = useState("");
    const [isAiResult, setIsAiResult] = useState(false);
    const [isAiSelect, setIsAiSelect] = useState("");
    const [wordCount, setWordCount] = useState(0);
    const [wordCountAi, setWordCountAi] = useState(0);
    const [handleSubmitAiResult, setHandleAiResult] = useState([]);
    const [repliesText, setRepliesText] = useState(false)
    const [responseText, setResponseText] = useState(false)

    const currentUser = useCurrentUser()
    const currentClass = useCurrentClass()

    const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
    const isCleverUser = !!(currentUser && currentUser.cleverId);
    const isCanvasUser = !!(currentUser && currentUser.canvas_id);
    const isGoogleUser = !!(currentUser && currentUser.googleId);
    const isClassLinkuser = !!(currentUser && currentUser.classlinkid);

    const [game_grade_category, setGameGradeCategory] = useState({});
    const [game_grade_type, setGameGradeType] = useState({});
    const [game_ungraded, setGameUngraded] = useState(false);

    const [,,schoologyAllClassGradeCategory]= useSchoologyGradeCategory()
    const canvasAssignmentCategory =  useCanvasAssignmentCategory()

    const grade_type = useMemo(() => {
        if (currentClass && currentClass.canvas_id) {
            return GRADE_TYPE_CANVAS;
        }else{
            return GRADE_TYPE_ALL;
        }
    },[currentClass]);

    const grade_category = useMemo(() => {
        if (currentClass.canvas_id) {
            const grade_cat = canvasAssignmentCategory[currentClass.canvas_id];
            if(Array.isArray(grade_cat)){
                return grade_cat.map(k=>({
                    id:k.id,
                    title:k.name
                }))
            }
            return []
        } else if (currentClass.sectionId) {
            const grade_cat = schoologyAllClassGradeCategory[currentClass.sectionId];
            if(Array.isArray(grade_cat)){
                console.log(grade_cat,"grade_cat--grade_cat")
                return grade_cat.map(k=>({
                    id:k.id,
                    title:k.title,
                    grade_type:k.calculation_type == 2 ?'points':'percent'
                }))
            }
            return []
        }
        return [];
    }, [currentClass, schoologyAllClassGradeCategory, canvasAssignmentCategory])
    // const inpRef = useRef();

    const handleInputFields = (key, value) => {
        let debateInfo = configurations ?? {};
        debateInfo[key] = value;
        setConfigurations(debateInfo);
    };
    const isValidFormat = (data) => {
        return Array.isArray(data) && data.every(item => item.hasOwnProperty('question'));
    };
    const handleClickLiveDebate = debounce(async () => {
        if (liveDebateInputVal) {
            setLiveDebateLoader(true);
            const result = await handleDebateAI({
                topic: liveDebateInputVal,
            });
            if (isValidFormat(result)) {
                setHandleAiResult(result);
                setIsAiResult(!isAiResult);
            } else {
                onAiError();
            }
            setLiveDebateLoader(false);
        }
        // setIsLiveDebate(false);
    }, 1000);

    const handleLiveDebate = () => {
        setIsLiveDebate(true);
        // setIsLiveDebate(true);
        setIsAiResult(false);
        setIsAiSelect("");
        setInputValue("");
    };

    const textareaRef1 = useRef(null);
    const textareaRef2 = useRef(null);

    const handleWordLimitAI = (text) => {
        const maxLength = 200;
        const trimmedValue = text.slice(0, maxLength);
        setLiveDebateInputVal(trimmedValue);
        setWordCountAi(trimmedValue.length);
    };

    const handleWordLimit = (text) => {
        const maxLength = 200;
        const trimmedValue = text.slice(0, maxLength);
        setInputValue(trimmedValue);
        setWordCount(trimmedValue.length);
    };

    return (
        <div className="ideaMdlUpr">
            <Row>
                <Col xs={24}>
                    <div className="mainDbteValeSec">
                        <div className="debateMdlTop">
                            <img src={DebateNewLogo} alt="Debate" />
                        </div>
                        <div
                            className="debateValeSection"
                            onClick={() => handleLiveDebate()}
                        >
                            <span>{`${isAiResult ? "Ask Vale again" : "Pregúntale a Vale"
                                }`}</span>
                            <img src={IdeaAi} alt="Idea" />
                        </div>
                    </div>
                </Col>
                <Col xs={24}>
                    {!isLiveDebate ? (
                        <div className="liveDebateMdlLbl" style={{ position: 'relative' }}>
                            <Acent inputRef={textareaRef1} setValue={handleWordLimit}  style={{ marginBottom: 5 , display:"flex" ,justifyContent:"flex-end" }} isDisabled={wordCount === 200} />
                            <textarea
                                ref={textareaRef1}
                                placeholder="OPTION 2: Type a question where students will be able to debate with each other in an open chat."
                                value={inputValue}
                                onChange={(e) => {
                                    handleWordLimit(e.target.value);
                                }}
                            />
                            <div className="wordCountDebate" style={{ position: 'absolute', top: 30, right: 10 }}>
                                <span>{`${wordCount}/200`}</span>
                            </div>
                        </div>
                    ) : !isAiResult ? (
                        <>
                            <div className="ideaMdlContent debateMdlCont">
                                <div className="allDebateCntnt">
                                    <span>Crea una pregunta sobre:</span>
                                </div>
                                <div className="debateMdlLbl">
                                    <div className="wordCountDebate" style={{ position: 'absolute', top: 4, right: 10 }}>
                                        <span>{`${wordCountAi}/200`}</span>
                                    </div>
                                    <div style={{ position: 'absolute', right:0,top:-30}}>
                                        <Acent inputRef={textareaRef2} setValue={handleWordLimitAI}  style={{ marginBottom: 5 , display:"flex" ,justifyContent:"flex-end" }} isDisabled={wordCountAi === 200} />
                                    </div>
                                    <textarea
                                        ref={textareaRef2}
                                        placeholder="Type Here....."
                                        value={liveDebateInputVal}
                                        onChange={(e) => handleWordLimitAI(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === "Enter" &&
                                                !e.shiftKey &&
                                                liveDebateInputVal.trim() !== ""
                                            ) {
                                                if (!liveDebateLoader) {
                                                    e.preventDefault();
                                                    handleClickLiveDebate();
                                                }
                                                else {
                                                    e.preventDefault();
                                                }
                                            }
                                        }}
                                    />
                                    <Button
                                        disabled={liveDebateLoader}
                                        className="debateEnterButton liveDebateClickBtn"
                                        onClick={() => {
                                            if (liveDebateInputVal.trim() !== "") {
                                                handleClickLiveDebate();
                                            }
                                        }}
                                    >
                                        {liveDebateLoader ? (
                                            <LoaderWrapper>
                                                <Loading size={18} />
                                            </LoaderWrapper>
                                        ) : null}
                                        &#9166;
                                    </Button>
                                </div>
                            </div>

                        </>
                    ) : (
                        <Col xs={24}>
                            <div className="ideaMdlContent debateMdlCont">
                                <div className="allDebateCntnt">
                                    <span>Crea una pregunta sobre:</span>
                                </div>
                                <div className="allDebteResultPart debateMdlLbl">
                                    {map(handleSubmitAiResult, (item, index) => {
                                        return (
                                            <div className="debateResultSec">
                                                <span style={{minWidth: '80px'}}>{get(item, "question", "")}</span>
                                                <div
                                                    className={`blogInputBox ${isAiSelect === get(item, "question", "")
                                                        ? "blogInputBoxSelect"
                                                        : ""
                                                        }`}
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            setIsAiSelect(get(item, "question", ""));
                                                            setInputValue(get(item, "question", ""));
                                                        }}
                                                    >{`${isAiSelect === get(item, "question", "")
                                                        ? "Selected"
                                                        : "Select"
                                                        }`}</Button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Col>
                    )}
                    <div className="midLiveDebateSec">
                        <span>
                            How many <span className="debateHighlight">REPLIES</span> does
                            each student need to make in total?
                        </span>
                        <div className="blogInputBox">
                            {/* <Input
                                type="number"
                                value={replies}
                                min={0}
                                max={20}
                                // onChange={(e) => {
                                //     const values = e.target.value;

                                //     if (values === "0") {
                                //         setReplies("");
                                //     } else {
                                //         handleInputFields("total_replies", values);
                                //         setReplies(values);
                                //     }

                                //     // handleInputFields("total_replies", values);
                                //     // setReplies(values);
                                // }}
                                onChange={(e) => {
                                    const values = e.target.value;
                                    const regex = /^[0-9\b]+$/;

                                    if (values === "" || regex.test(values)) {
                                        if (values === "0") {
                                            setReplies("");
                                        } else {
                                            handleInputFields("total_replies", values);
                                            setReplies(values);
                                        }
                                    }
                                }}
                            /> */}

                            <TextField
                                id="weightage"
                                value={configurations?.total_replies}
                                // disabled={data.editing}
                                onChange={(e) => {
                                    let val = e.target.value
                                    if (!isNaN(val) && val !== 0) {
                                        // val = Math.min(val, totalClassStudent > 8 ? 8 : totalClassStudent - 1);
                                        // val = Math.min(val, 8);
                                        if(val > (totalClassStudent > 8 ? 8 : totalClassStudent - 1)){
                                            setRepliesText(true);
                                        }else{
                                            handleInputFields("total_replies", val);
                                            setReplies(val);
                                            setRepliesText(false);
                                        }
                                    }
                                }}
                                variant="outlined"
                                defaultValue={totalClassStudent > 8 ? 8 : totalClassStudent - 1}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        // max: `${totalClassStudent > 8 ? 8 : totalClassStudent - 1}`,
                                        // max: 8,
                                    }
                                }}
                            />
                        </div>

                    </div>

                    {repliesText && (
                        <div className="repliesTxtSection">
                            <p>{`${totalClassStudent > 8 ? 'The maximum number of replies is 8.' : 'You cannot assign more replies than the total number of students.'}`}</p>
                        </div>
                    )}


                    <div className="midLiveDebateSec">
                        <span>
                            How many
                            <span className="debateResponseHighlight"> RESPONSES</span> can
                            each student receive in total?
                        </span>
                        <div className="blogInputBox">
                            {/* <Input
                                max={20}
                                type="number"
                                min={0}
                                value={response}
                                onChange={(e) => {
                                    const values = e.target.value;
                                    const regex = /^[0-9\b]+$/;
                                    if (values === "" || regex.test(values)) {
                                        if (values === "0") {
                                            setResponse("");
                                        } else {
                                            handleInputFields("total_receive", values);
                                            setResponse(values);
                                        }
                                    }
                                    // handleInputFields("total_receive", values);
                                    // setResponse(values);
                                }}
                            /> */}
                            <TextField
                                id="weightage"
                                value={configurations?.total_receive}
                                // disabled={data.editing}
                                onChange={(e) => {
                                    let val = e.target.value
                                    if (!isNaN(val) && val !== 0) {
                                        // val = Math.min(val, totalClassStudent > 5 ? 5 : totalClassStudent - 1);
                                        // val = Math.min(val, 5);
                                        if(val > (totalClassStudent > 5 ? 5 : totalClassStudent - 1)) {
                                            setResponseText(true)
                                        }else{
                                            handleInputFields("total_receive", val);
                                            setResponse(val);
                                            setResponseText(false)
                                        }
                                    }
                                }}
                                variant="outlined"
                                defaultValue={totalClassStudent > 5 ? 5 : totalClassStudent - 1}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        // max: `${totalClassStudent > 5 ? 5 : totalClassStudent - 1}`,
                                        // max: 5,
                                    }
                                }}
                            />

                            {/* <InputNumber min={0} max={10} defaultValue={0}
                                onChange={(val) => {
                                    // const values = e.target.value
                                    handleInputFields('total_receive', val);
                                    setResponse(val)
                                }}
                            /> */}
                        </div>
                    </div>

                    {responseText && (
                        <div className="repliesTxtSection">
                            <p>{`${totalClassStudent > 5 ? "The maximum number of responses is 5" : "You cannot assign more replies than the total number of students."}`}</p>
                        </div>
                    )}


                    <div className="midLiveDebateSec">
                        <span>How many minutes should this debate last?</span>
                        <div className="blogInputBox">
                            {/* <Input
                                value={configurations?.total_time}
                                onChange={(e) => {
                                    const value = e?.target?.value || "";
                                    handleInputFields('total_time', value)
                                }} /> */}
                            {/* <TimePicker onChange={e => {
                                const times = getTimeFromDate(e?._d)
                                handleInputFields('total_time', times);
                                setTimeInput(times);
                            }} /> */}
                            {/* <TimePicker
                                onChange={(e) => {
                                    const times = getTimeFromDate(e?._d);

                                    if (times === "0:0:0" || times === "NaN:NaN:NaN") {
                                        setTimeInput("");
                                    } else {
                                        handleInputFields("total_time", times);
                                        setTimeInput(times);
                                    }
                                }}
                            /> */}
                            <div style={{ border: '1px solid #d9d9d9', backgroundColor: 'white', minWidth: 100, borderRadius: 20 }}>
                                <Select
                                    className={classes.root}
                                    disableUnderline={true}
                                    style={{ width: '100%' }}
                                    required={true}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={(v) => {
                                        const times = v.target.value;
                                        handleInputFields("total_time", times * 60);
                                        setTimeInput(times);
                                        setOpenTeacherModal(times)
                                    }}
                                    label="Type"
                                >
                                    {[...Array(59)].map((_, index) => (
                                        <MenuItem key={index + 1} value={index + 1}>{index + 1 + ' ' + 'min'}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24}>
                    <div className="debeteCountTypeSec debeteCountTypeSpace">
                        <div className="debatCountTxt">
                            <span>Count for a grade</span>
                            <Switch
                                defaultChecked={false}
                                onChange={() => {
                                    let s_value = !isTextVisible
                                    setIsTextVisible(s_value);
                                    if(!s_value){
                                        setGameGradeType({})
                                        setGameGradeCategory({})
                                        setGameUngraded(false)
                                    }
                                }}
                            />
                        </div>
                        <div className="custoMshareBtn">
                            <Button
                                style={{ display: "flex", alignItems: "center" }}
                                type="primary"
                                className="shareBtnSec"
                                onClick={() => {
                                    handleLiveShare(configurations, inputValue, isTextVisible ,{
                                        grade_category:game_grade_category,
                                        grade_type:game_grade_type,
                                        ungraded:game_ungraded
                                    });
                                }}
                                // disabled={inputValue === '' || timeInput === ''}
                                // disabled={!(inputValue && timeInput && response && replies)}
                                disabled={inputValue.trim() === "" || !timeInput || !response || !replies}
                            >
                                {shareLoader ? (
                                    <LoaderWrapper>
                                        <Loading size={18} white />
                                    </LoaderWrapper>
                                ) : null}{" "}
                                Share
                            </Button>
                        </div>
                    </div>
                </Col>
                {isTextVisible && (
                    <>
                        <div className='aiGmMdlInr' style={{ width: "100%" }}>
                            {!isSchoologyUser && <GradeConfig label={"Choose grading system"} value={game_grade_type?.value} options={grade_type} onChange={v => {
                                setGameGradeType(v)
                                setGameUngraded(false)
                            }} hasInput objValue={game_grade_type} />}
                            {isCanvasUser || isSchoologyUser ? <GradeConfig label={" Choose category"} value={game_grade_category?.id} options={grade_category} onChange={v => {
                                setGameGradeCategory(v)
                                setGameUngraded(false)
                            }} objValue={game_grade_category} /> : null}
                            {!isSchoologyUser && !isCanvasUser ? <div className='linksSectionIcon text-dark' style={{ paddingLeft: 4 }}>
                                <Checkbox checked={game_ungraded} className="custom-checkbox" style={{ fontSize: 16, fontWeight: "600" }} onChange={() => {
                                    setGameUngraded(!game_ungraded)
                                    setGameGradeType({})
                                    setGameGradeCategory({})
                                }}>
                                    {"Ungraded"}
                                </Checkbox>
                            </div> : null}
                        </div>
                    </>
                )}
            </Row>
        </div>
    );
};
export default DebateLiveSection;
