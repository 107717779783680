//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";

//  -- Actions ------------------------
import * as AuthActions from './actions';

//  -- Lib ----------------------------
import { authHelper } from 'utils/lib/authHelper';

//  -- Thunks -------------------------
import { handleGetClass } from 'store/classes/thunks';
import { handleGetOrganization, handleListDistricts } from 'store/organizations/thunks';
import { handleCheckUserStatus, handleGetUser } from 'store/users/thunks';
import { handleListUserClasses } from 'store/userClasses/thunks';
import { handleInvokeAlert } from "store/alert/thunks"
import _, { filter, find, get } from "lodash"
import * as Sentry from "@sentry/react";
import { KEY_FOR_REMOVE } from "utils/config/reference"
import { clearScoket } from "socket"
import  moment  from 'moment';
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------

//  -- User Login ---------------------
export const handleLogin = (data) => async (dispatch) => {
    await dispatch(AuthActions.userLogin());

    try {
        const res = authHelper(data);
        const user = await Auth.signIn(res);
        await dispatch(AuthActions.userLoginSuccess("Success"));
        return user;
    } catch (error) {
        if (error && error.message == "User is disabled.") {
            dispatch(handleInvokeAlert("Your account is now inactive.", "Please, contact our customer support for answers."))
        }
        if (error && error.code != "NotAuthorizedException" && error.code != "NetworkError") {
            Sentry.captureMessage(error.message)
        }
        await dispatch(AuthActions.userLoginFail(error));
        return false;
    }
};

//  -- User data fetch ----------------
export const handleRedirect = (data, schoologyUser, isGame) => async (dispatch) => {
    await dispatch(AuthActions.userLogin());
    
    try {
        await dispatch(AuthActions.userLogin());
        const userId = schoologyUser?.id || _.get(data, 'attributes.custom:userId');

        if (!userId) {
            Sentry.captureMessage(`UserId not found for redirect #1: ${JSON.stringify(data)}`);
            dispatch(handleInvokeAlert(`"Unable to fetch user information.`));
            return { path: null, currentOrg: null, user: null, message: "Unable to fetch user information due to missing userId." };
        }

        const dbUser = await dispatch(handleGetUser(userId));
        if (!dbUser) {
            Sentry.captureMessage('dBuser not found #2');
            dispatch(handleInvokeAlert(`"Unable to fetch user information.`));
            return { path: null, currentOrg: null, user: null, message: "Unable to fetch user information." };
        }

        const currentOrg = await dispatch(handleGetOrganization(dbUser.orgId));
        /* TODO temprory disable user account end date checking */
        // if ((dbUser.endDate && moment(dbUser.endDate, "YYYY-MM-DD").isBefore(moment(), "day")) || !dbUser.active) {
        //     dispatch(handleInvokeAlert(`Your account is no longer active. Please, contact your organization's administrator.`));
        //     return { path: null, currentOrg: null, user: null, message: `Your account is no longer active. Please, contact your organization's administrator.` };
        // }
        if (!dbUser.active) {
            dispatch(handleInvokeAlert(`Your account is no longer active. Please, contact your organization's administrator.`));
            return { path: null, currentOrg: null, user: null, message: `Your account is no longer active. Please, contact your organization's administrator.` };
        }
        if (currentOrg && currentOrg.parent) {
            let parentOrg = currentOrg.parent;
            const startDate = parentOrg.startDate
            const endDate = parentOrg.endDate
            if (startDate && moment(startDate, "YYYY-MM-DD").isAfter(moment(), "day")) {
                dispatch(handleInvokeAlert("Your organization is not active yet. Please, contact your organization's administrator."));
                return { path: null, currentOrg: null, user: null, message: "Your organization is not active yet." };
            } else if (endDate && moment(endDate, "YYYY-MM-DD").isBefore(moment(), "day")) {
                dispatch(handleInvokeAlert("Your organization is no longer active. Please, contact your organization's administrator."));
                return { path: null, currentOrg: null, user: null, message: "Your organization is no longer active." };
            }
        }

        const roleRedirects = {
            'owe_admin': '/dashboard',
            'admin': '/district',
            'director': '/director',
            'teacher': '/teacher/home',
            'student': '/student/home',
            'parent': '/parent/home'
        };

        return { path: roleRedirects[dbUser.role] || '/', currentOrg, user: dbUser, message: null };

    } catch (error) {
        Sentry.captureException(error);
        await dispatch(AuthActions.userLoginFail(error));
        return { path: null, currentOrg: null, user: null, message: "An error occurred during the redirection process." };
    }
};


//  -- User data fetch ----------------
export const handleFetchUserData = (user) => async (dispatch) => {
    if (_.isEmpty(user)) return;
    await dispatch(AuthActions.userLogin());

    try {
        if (user.role === 'owe_admin') {
            const orgs = await dispatch(handleListDistricts())
            let orgId = get(orgs[0], "id")
            const lastActiveOrgAdmin = localStorage.getItem("lastActiveOrgAdmin")
            if (lastActiveOrgAdmin) {
                if (find(orgs, ["id", lastActiveOrgAdmin])) {
                    orgId = lastActiveOrgAdmin;
                }
            }
            await dispatch(handleGetOrganization(orgId))

        }
        if (user.role === 'admin') {
            await dispatch(handleGetOrganization(user.orgId))
        }

        if (user.role === 'distrito') {
            await dispatch(handleGetOrganization(user.orgId))
        }

        if (user.role === 'teacher') {
            await dispatch(handleGetOrganization(user.orgId))
            const classes = await dispatch(handleListUserClasses(user.id));
            const LastActiveclassID = localStorage.getItem("LastActiveclassID")
            const hasClass = find(classes, ['id', LastActiveclassID])
            if (hasClass && LastActiveclassID) {
                await dispatch(handleGetClass(hasClass.id));
            } else {
                if (classes.length > 0) {
                    await dispatch(handleGetClass(classes[0].id));
                }
            }

        }
        if (user.role === 'parent') {
            await dispatch(handleGetOrganization(user.orgId))
            const classes = await dispatch(handleListUserClasses(user.parent_of));
            const LastActiveclassID = localStorage.getItem("LastActiveclassID")
            const hasClass = find(classes, ['id', LastActiveclassID])
            if (hasClass && LastActiveclassID) {
                await dispatch(handleGetClass(hasClass.id));
            } else {
                if (classes.length > 0) {
                    await dispatch(handleGetClass(classes[0].id));
                }
            }

        }

        if (user.role === 'student') {
            await dispatch(handleGetOrganization(user.orgId));
            const classes = await dispatch(handleListUserClasses(user.id));
            const LastActiveclassID = localStorage.getItem("LastActiveclassID")
            const hasClass = find(classes, ['id', LastActiveclassID])
            if (hasClass && LastActiveclassID) {
                await dispatch(handleGetClass(hasClass.id));
            } else {
                if (classes.length > 0) {
                    await dispatch(handleGetClass(classes[0].id));
                }
            }
        }


        // TODO: if admin fetch org, classes, users and route to district dashboard
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(AuthActions.userLoginFail(error));
        return false;
    }
};

//  -- User Logout --------------------
export const handleLogout = () => async (dispatch) => {
    await dispatch(AuthActions.userLogout());

    try {
        await Auth.signOut({ global: true });
        await dispatch(AuthActions.userLogoutStorageClean())
        await dispatch(AuthActions.userLogoutSuccess('Success'));
        localStorage.clear();
        clearScoket()
        return false;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(AuthActions.userLogoutFail(error));
    }
};
//------------ Clean Cache and Local Storage -------------------
export const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    KEY_FOR_REMOVE.map(key => localStorage.removeItem(key))
};