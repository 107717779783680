//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
//  -- Components ---------------------
import { ArrowBack, ArrowBackIos, ArrowForwardIos, Bookmark, Delete } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
//  -- Features -----------------------
import BalconCulturalTooling from 'features/BalconCulturalTooling';
import { filter, find, groupBy, get, findIndex } from 'lodash';
import moment from 'moment';
import { string } from 'prop-types';
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import { handleCreateBookmarks, handleUpdateBookmarks, handleDeleteBookmarks } from 'store/bookmark/thunks';
import { useBookmarks } from 'store/bookmark/selectors';
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import { useDispatch } from 'react-redux';
import { useAllBook } from 'store/books/selectors';
import { useCurrentClass } from 'store/classes/selectors';
import { useHyperlinkModal } from 'store/hyperlinkModal/selectors';
import { useTimmerRunning } from 'store/timmer/selectors';
import { generateUrlForNonEbook, getBackToUrl } from "utils/lib/ebookHelper"
import Idea from "features/Idea"
import Vocabilary from "components/Vocabilary"
import MainbookPopup from 'components/MainbookPopup';
import EbookGallleryView, { ViewGallery } from 'components/EbookGallleryView';
import SentenceStarterPopup from 'components/SentenceStarterPopup';
import Differentiation from 'components/Differentiation/Differentiation';
import LessonViewModal from 'components/SuperLesson/LessonViewModal';
import SuperLessons from 'components/SuperLesson/SuperLesson';
import DifferentiationViewModal from 'components/Differentiation/DifferentiationViewModal';
import ImageHighlighter from 'components/ImageHighlighter';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const ButtonContainer = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.black600};
    cursor: pointer;
    height: 40px;
    left: ${p => `${p.left}px`};
    outline: none;
    position: absolute;
    right: ${p => `${p.right}px`};
    top: 10px;
    transition: all 300ms ease;
    width: 40px;
    z-index: 1;
    
`;

const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    max-width: 1320px;
    padding: 64px 0px 0px;
    position: relative;
    width: 100%;

    @media (min-width: 1100px) {
        flex-direction: row;
        padding: 0px 30px;
    }
`;

const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: auto;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const NavContainer = styled.div`
    display: none;

    @media (min-width: 997px) {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        width: 24px;
    }
`;

const NavItemOverflow = styled.div`
    display: inline-table;
    font-size: 0.95em;
    margin-right: 1px;
    margin-top: 1px;
    overflow: hidden;
    transition: width 300ms ease 500ms;
    width: 60px;
`;

const NavItem = styled.button`
    background-color: ${p => p.active ? COLOR.indigo100 : COLOR.green900};
    border: none;
    border-radius: 2px;
    box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    color: ${p => p.active ? COLOR.white : COLOR.white};
    cursor: pointer;
    /* display: initial; */
    flex-direction: row;
    font-size: 0.85em;
    font-weight: 600;
    margin-bottom: 4px;
    outline: none;
    padding: 8px;
    transition: all 300ms ease;

    &:hover {
        box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    }

    &:hover ${NavItemOverflow} {
      /*   width: ${p => p.active ? '8px' : '49px'}; */
        width: '49px'};
    }
`;

const ToolsContainer = styled.div`
    height: 100%;
    margin-top: 48px;
    width: 100%;
    flex: 1;
    // max-width: 469px;
    max-width: 485px;

    @media (max-width: 1100px){
        max-width: unset;
    }
    
    // @media (min-width: 1025px) {
    //     margin-left: 11px;
    //     margin-top: 0px;
    //     // width: 322px;
    // }
    @media (min-width: 1100px) {
        // margin-left: 48px;
        margin-left: 30px;
        margin-top: ${p => p.isTeacher ? "-32px" : "-3px"}
        // width: 350px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    padding: 100px 0px;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
        padding: 61px 0px !important;
    }
`;
const ItemWrapper = styled.div`
    display: flex;
   flex-direction:row;
  
`;
const ListItem = styled.li`
    list-style-type: none;
    display: block;
    background-color: ${p => p.active ? COLOR.indigo100 : "#006b6e"};
    border: none;
    border-radius: 2px;
    box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    color: ${p => p.active ? COLOR.white : COLOR.white};
    cursor: pointer;
    /* display: initial; */
    flex-direction: row;
    font-size: 0.75em;
    font-weight: 600;
    margin-bottom: 4px;
    outline: none;
    padding: 8px;
    transition: all 300ms ease;

    &:hover {
        box-shadow: ${p => p.active && '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'};
    }

    &:hover ${NavItemOverflow} {
    /*   width: ${p => p.active ? '8px' : '49px'}; */
        width: '49px'};
    }
`
const List = styled.ul`
    padding: 0px;
    margin-bottom:-10px;
    display: block;
    width: 100%;
`
const BackButton = styled.button`
    background-color: ${COLOR.indigo200};
    width: max-content;
    color: ${COLOR.white};
    border: none;
    outline: none;
    padding: 5px 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @media (max-width: 450px) {
        padding-left: 0px;
    padding-right: 3px;
    }
   `
const BookmarkButton = styled(Button)`
    margin-right: 4px;
    font-size: 10px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
   `;
const BookmarkLinkWrapper = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 0px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    display:flex;
    flex-direction:row;
`;
const TrashButton = styled.button`
    padding: 2px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
`;
const MarkButton = styled.button`
    padding: 2px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
`;
const AddBookmarkButton = styled.button`
    padding: 2px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    background-color:${COLOR.green};
    padding-right: 10px;
    padding-left: 5px;
    color: #fff;
    border-radius: 0px;
    font-size: 12px;
    width: 40px;
    height: 36px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const IdeaRow = styled.div`
   display: flex !important;
   flex-direction: column !important;
   justify-content: flex-start !important;
`;
const BookMarkWrapper = styled.div`
  display: flex !important;
`;
//  ----------------------------------------------------------------------------
//  Feature
//  ----------------------------------------------------------------------------
//
//  Ebook
//
//  -- Description
//  Ebook display component
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const BalconLayout = ({
    classUsers,
    activity,
    data,
    page,
    quiz,
    ebookFlag,
    setEbookFlag,
    ebookFlagTeacherEdition,
    setEbookFlagTeacherEdition,
    tips,
    existTeacherEdition,
    existShowAnswer,
    submissions,
    currentUser,
    classActivities,
    classQuizzes,
    setPage,
    id,
    bookData,
    backToLabel,
    CHAPTER_ID,
    lessonPlan,
    rubrics,
    assignmentPlan,
    workbook_plan,
    main_book,
    level,
    SuperLesson = [],
    DifferentiationLesson = [],
    chapter = ""
}) => {
    const [imageConfiguration, setImageConfiguration] = useState({});
    const [showSpecialSupport, setShowSpecialSupport] = React.useState(false);
    const [studentViewFlag, setStudentViewFlag] = React.useState(false);
    const [printLoader, setPrintloader] = useState(false)
    const [removeBookMark, setRemoveBookMark] = useState(false);
    const [deleteBookmarkloader, setDeleteBookmarloader] = useState(false)
    const [superLessonOpen, setSuperLessonOpen] = useState(false);
    const [differentiationOpen, setDifferentiationOpen] = useState(false);
    const booksAllList = useAllBook()
    const [, invokeHyperlink,] = useHyperlinkModal()
    const dataBook = find(booksAllList, ['id', CHAPTER_ID]);
    const bookHyperlinks = get(dataBook, "hyperlinks")
    const pageHyperlink = get(find(bookHyperlinks, p => p.page == page), "links")
    const backBtnRef = useRef(null)
    const forwardBtnRef = useRef(null)
    const history = useHistory();
    const timmerRef = useRef(null)
    const timeIntRef = useRef(0)
    const clearPreviewRef = useRef(null)
    const [timeInt, setTimeInt] = useState(0)
    const submitRef = useRef(null)
    const chapterActivities = groupBy(classActivities, 'data.chapter');
    const chapterQuizzes = groupBy(classQuizzes, 'data.chapter');

    const classBookmark = useBookmarks()
    const [, setTimmerRunning, clearTimmerRunning] = useTimmerRunning()
    const bookBookmarks = find(classBookmark, b => {
        const start = parseInt(bookData.start, 10);
        const end = parseInt(bookData.finish, 10)
        const markPage = parseInt(b.page, 10);
        if (b.bookId != CHAPTER_ID) {
            return false;
        } else if (markPage > start && markPage < end) {
            return true;
        } else if (markPage == start || markPage == end) {
            return true;
        } else {
            return false;
        }
    })
    const [bookmarkloader, setbookmarloader] = useState(false)
    const getNumberMask = useCallback((_page) => (_page - parseInt(bookData.start, 10) + 1), [CHAPTER_ID, bookData, classBookmark])
    const currentClass = useCurrentClass()
    const dispatch = useDispatch()
    const book_tips = useMemo(() => filter(tips, x => x.bookId == CHAPTER_ID), [CHAPTER_ID]);
    const page_tips = useMemo(() => filter(book_tips, x => x.page == page && x.bookId == CHAPTER_ID), [book_tips, page]);
    const generateUrl = (page) => {
        return generateUrlForNonEbook(id, page, activity, quiz, backToLabel, level, CHAPTER_ID)
    }
    // const generateUrl = (page) => {
    //     if(backToLabel == 'Balcón cultural')
    //         return `/balconCulturalBook?id=${id}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`
    //     if(backToLabel == '1nformate lessons')   
    //         return `/informateBook?id=${id}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
    //     if(backToLabel == 'Comunidad')   
    //         return `/ComunidadBook?id=${id}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
    //     if(backToLabel == 'Conectándome')   
    //         return `/ConectandomeBook?id=${id}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
    //     if(backToLabel == 'AP')   
    //         return `/apBook?chapter=${CHAPTER_ID}&id=${id}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
    //     if(backToLabel == 'Stories')  {
    //         return  `/stories?id=${id}&level=${level}&page=${page}&activity=${activity ?? null}&quiz=${quiz ?? null}`;
    //     } 

    // }


    // let tipsFiltered = [];
    // if(tips.length != undefined){
    //     tipsFiltered =filter(tips,x => x.page === page);
    // }
    const [open, setOpen] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const showCopyRight = () => {
        setPopupMessage("Content is protected!")
        setOpen(true);

    }

    const handleClose = () => {
        setOpen(false);
    };
    const getSrcPage = useCallback((page) => {
        let dataPage = find(data?.content, x => x.pageId == Number(page));
        let url = "";
        if (dataPage) {
            if (ebookFlagTeacherEdition && dataPage.teacherEditionPageSrc) {
                url = dataPage.teacherEditionPageSrc
            } else if (ebookFlag && dataPage.answerPageSrc) {
                url = dataPage.answerPageSrc
            } else {
                url = dataPage.src;
            }

        }
        return url;
    }, [data, page, ebookFlagTeacherEdition, ebookFlag])
    const getImageRef = () => {
        return imageRef.current;
    }
    const setFlagsFalse = () => {
        setStudentViewFlag(false);
        setShowSpecialSupport(false);
        setEbookFlag(false);
        setEbookFlagTeacherEdition(false);
    }

    const imageRef = useRef();
    const handleImagePrint = useReactToPrint({
        content: () => imageRef.current,
        onBeforeGetContent: () => { setPrintloader(true) },
        onBeforePrint: () => {
            setPrintloader(false)
        }
    });

    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", () => { })
        }
    }, [])
    useEffect(() => {
        preloadImage()
    }, [data])
    useEffect(() => {
        if (currentUser?.role != 'student') return;
        try {
            let actAct = find(chapterActivities[CHAPTER_ID], x => (get(x, "data.id") === activity))
            let actqz = find(chapterQuizzes[CHAPTER_ID], x => (get(x, "data.id") === quiz))
            let Actdata = find(submissions, ['assignmentId', get(actAct, "activityId")])
            let qzdata = find(submissions, ['assignmentId', get(actqz, "quizId")])
            let _timmer = 0
            let _durration = 0;
            if (activity && typeof activity === "string" && activity != "null" && activity != "undefined" && actAct) {
                _durration = actAct.timeDuration
                if (Actdata) {
                    if (!Actdata.complete) {
                        _timmer = Actdata.timmer
                    }
                    handleTimmer(_durration, _timmer)
                } else {
                    handleTimmer(_durration, _timmer)
                }

            } else if (quiz && typeof quiz === "string" && quiz != "null" && quiz != "undefined" && actqz) {
                _durration = actqz.timeDuration
                if (qzdata) {
                    if (!qzdata.complete) {
                        _timmer = qzdata.timmer
                        handleTimmer(_durration, _timmer)
                    }
                } else {
                    handleTimmer(_durration, _timmer)
                }
            }

        } catch (error) {
            console.log(error, "errr")
        }

    }, [activity, quiz, classActivities, classQuizzes])
    const handleTimmer = (timeDuration, timer) => {
        try {
            if (timeDuration && !isNaN(parseInt(timeDuration)) && parseInt(timeDuration) > 0) {
                let _timer = parseInt(timer) > 0 ? parseInt(timer) : parseInt(timeDuration) * 60
                if (timmerRef.current) { onFinish() };
                timeIntRef.current = _timer;
                setTimmerRunning()
                timmerRef.current = setInterval(() => {
                    if (timeIntRef.current > 0) {
                        timeIntRef.current = timeIntRef.current - 1
                        let timePrint = moment.utc(timeIntRef.current * 1000).format('HH:mm:ss');
                        setTimeInt(timePrint)
                    } else {
                        if (typeof submitRef.current == "function") {
                            submitRef.current(true)
                            submitRef.current = null
                            onFinish()
                        }
                    }
                }, 1000)
            }
        } catch (error) {
            console.log(error, "error in effect");
        }
        return () => {
            onFinish()
        }
    }
    const onFinish = () => {
        clearInterval(timmerRef.current)
        setTimeInt(0)
        timeIntRef.current = 0
        timmerRef.current = null
        clearTimmerRunning()
    }
    function checkKey(e) {
        e = e || window.event;
        let isLessonPlan = document.getElementsByClassName("lessonPlanPopUp").length > 0;
        let isDrawerOpen = document.getElementsByClassName("ant-drawer-open").length > 0;
        let isModalOpen = document.getElementsByClassName("ant-modal-mask").length > 0;
        let isRubrics = document.getElementsByClassName("rubricsPopUp").length > 0;
        let isMainBook = document.getElementsByClassName("main-book-popup").length > 0;
        if (isDrawerOpen || isLessonPlan || isRubrics || isModalOpen || isMainBook) return;
        if (isDrawerOpen) return;
        if (e.keyCode == '37') {
            if (backBtnRef.current && !backBtnRef.current.disabled) {
                backBtnRef.current.click()
            }

        }
        else if (e.keyCode == '39') {
            if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                forwardBtnRef.current.click()
            }

        }

    }
    const setSubmitRef = (ref) => {
        submitRef.current = ref
    }
    const preloadImage = () => {
        let cPage = parseInt(page, 10) + 1
        if (cPage + 3 > data?.content?.length) return;
        for (let i = cPage; i < cPage + 5; i++) {
            let url = getSrcPage(i)
            if (url) {
                new Image().src = url
            }
        }
    }
    const backToButton = () => {
        // if (windowWidth < 1366) {
        //   history.push(`/story-levels?backto=${backToLabel}`);
        // } else {
        const backToUrl = getBackToUrl(backToLabel, CHAPTER_ID);
        if (backToUrl) {
            history.push(backToUrl);
        }
        // }
    };
    const bookmarkhandler = async () => {
        setbookmarloader(true)
        if (!bookBookmarks) {
            await dispatch(handleCreateBookmarks({
                bookId: CHAPTER_ID,
                page: page,
                userId: get(currentUser, "id", ""),
                classId: get(currentClass, "id", "")
            }))
        } else {
            if (bookBookmarks.page == page) {
                setbookmarloader(false)
                return;
            }
            await dispatch(handleUpdateBookmarks(bookBookmarks.id, page))
        }
        setbookmarloader(false)
    }
    const goToBookmark = () => {
        setPage(parseInt(bookBookmarks.page, 10));
        history.push(generateUrl(bookBookmarks.page));
    };
    const deleteBookMark = async () => {
        setDeleteBookmarloader(true)
        await dispatch(handleDeleteBookmarks(bookBookmarks.id))
        setRemoveBookMark(false)
        setDeleteBookmarloader(false)
    }
    const getPageContent = useMemo(() => {
        const start_page = parseInt(bookData?.start, 10);
        const end_page = parseInt(bookData?.finish, 10);
        if (start_page && end_page && data?.content) {
            const startIndex = findIndex(data.content, k => k.pageId == start_page)
            const endIndex = findIndex(data.content, k => k.pageId == end_page)
            if (startIndex >= 0 && endIndex >= 0) {
                return data.content.slice(startIndex, endIndex);
            }
        }
        return [];
    }, [data, bookData])
    const getHyperlink = () => {
        let links = null;
        if (pageHyperlink) {
            links = pageHyperlink;
            if (ebookFlagTeacherEdition) {
                links = filter(pageHyperlink, ["type", "teacher_ed"])
            } else {
                links = filter(pageHyperlink, k => k.type !== "teacher_ed")
            }
        }
        return links;
    }
    const isTeacher = get(currentUser, "role") === "teacher";
    const [vocModal, setVocModal] = useState(false);
    const [viewLessonDifferentiation, setViewLessonDifferentiation] = useState(false);
    const [viewLesson, setViewLesson] = useState(false);
    const [viewLessonType, setViewLessonType] = useState("")
    const [viewLessonTypeDiff, setViewLessonTypeDiff] = useState("")

    const hasSuperLessonPage = SuperLesson
        ? find(SuperLesson, (p) => p.page == page)
        : null;

    const hasDifferentiationLessonPage = DifferentiationLesson
        ? find(DifferentiationLesson, (p) => p.page == page)
        : null;


    const onLessonViewDiff = (val) => {
        setViewLessonDifferentiation(true);
        setViewLessonTypeDiff(val)
    }

    const onLessonView = (val) => {
        setViewLesson(true);
        setViewLessonType(val)
    }

    const disableBackWrad = () => {
        return parseInt(page, 10) <= parseInt(bookData?.start, 10)
    }
    const disableForWard = () => {
        return parseInt(page, 10) >= parseInt(bookData?.finish, 10)
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {popupMessage}
                </Alert>
            </Snackbar>
            <Modal
                open={removeBookMark}
                onClose={() => setRemoveBookMark(false)}
            ><ModalContent>
                    Are you sure to remove bookmarks?
                    <ModalRow>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setRemoveBookMark(false)
                            }}
                        >
                            No
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                deleteBookMark()
                            }}
                        >
                            {deleteBookmarkloader ? <Loading size={18} white /> : "Yes"}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Wrapper>

                <ButtonContainer
                    ref={backBtnRef}
                    disabled={disableBackWrad()}
                    onClick={() => {
                        setViewLesson(false);
                        setViewLessonDifferentiation(false);
                        setDifferentiationOpen(false);
                        setPage(parseInt(page, 10) - 1);
                        setSuperLessonOpen(false);
                        history.push(generateUrl(parseInt(page, 10) - 1));
                        if (typeof clearPreviewRef.current == "function") {
                            clearPreviewRef.current()
                        }
                        // onFinish()
                        setFlagsFalse();
                    }}
                    left={0}
                >
                    <ArrowBackIos style={{ fontSize: '40px' }} />
                </ButtonContainer>
                <ButtonContainer
                    ref={forwardBtnRef}
                    disabled={disableForWard()}
                    onClick={() => {
                        setViewLesson(false);
                        setViewLessonDifferentiation(false);
                        setDifferentiationOpen(false);
                        setSuperLessonOpen(false);
                        preloadImage()
                        if (typeof clearPreviewRef.current == "function") {
                            clearPreviewRef.current()
                        }
                        setPage(parseInt(page, 10) + 1);
                        history.push(generateUrl(parseInt(page, 10) + 1));
                        setFlagsFalse();
                    }}
                    right={0}
                >
                    <ArrowForwardIos style={{ fontSize: '40px' }} />
                </ButtonContainer>
                <Container>

                    <ImageContainer onContextMenu={(e) => {
                        e.preventDefault();
                        showCopyRight()
                    }}>
                        <Row style={{ marginTop: -30 }}>
                            <Col span={20} style={{ justifyContent: "flex-start", display: "flex", flexDirection: "row", alignItems: "end" }}>
                                <BackButton style={{ marginRight: "1.5px", whiteSpace: "nowrap", display: "flex", alignItems: "center", padding: "8px" }} onClick={backToButton}>
                                    <ArrowBack style={{ fontSize: '14px', marginRight: '4px' }} />
                                    Back {window.innerWidth >= 768 && `to ${backToLabel}`}
                                </BackButton>
                                <Vocabilary vocabilaryButtonClassName='vocab-button-balcon' pageId={page} bookId={CHAPTER_ID} style={{ borderRadius: "0px !importent" }} />
                                {hasSuperLessonPage?.from && currentUser?.role !== "student" && (
                                    <SuperLessons
                                        showModal={true}
                                        viewLesson={viewLesson}
                                        superLessonData={hasSuperLessonPage}
                                        bookId={chapter}
                                        pageId={page}
                                        setVocModal={setVocModal}
                                        onLessonOpen={onLessonView}
                                        onSuperLessonOpen={(value) => {
                                            setSuperLessonOpen(value);
                                        }}
                                        data={data}
                                        differentiationOpen={differentiationOpen}
                                        from={'balcon'}
                                    />
                                )}
                                {hasDifferentiationLessonPage?.from && currentUser?.role !== "student" && (
                                    <Differentiation
                                        showModal={hasDifferentiationLessonPage?.from && currentUser?.role !== "student"}
                                        viewLesson={viewLessonDifferentiation}
                                        superLessonData={hasDifferentiationLessonPage}
                                        bookId={chapter}
                                        pageId={page}
                                        setVocModal={setVocModal}
                                        onLessonOpen={onLessonViewDiff}
                                        data={data}
                                        superLessonOpen={superLessonOpen}
                                        onDifferentiationOpen={(value) => {
                                            setDifferentiationOpen(value);
                                        }}
                                        from={'balcon'}
                                    />
                                )}
                            </Col>
                            <Col span={4} style={{ gap: "1.3px", justifyContent: "flex-end", display: "flex", flexDirection: "row", alignItems: "end", }}>
                                <ViewGallery pages={getPageContent} />
                                <BookMarkWrapper>
                                    {isTeacher && <BookmarkLinkWrapper> <AddBookmarkButton
                                        color="primary"
                                        variant="contained"
                                        style={{ marginRight: bookBookmarks ? '4px' : 0 }}
                                        onClick={() => {
                                            bookmarkhandler()
                                        }}
                                    >
                                        {bookmarkloader ? <Loading size={20} white /> : <Bookmark color={get(bookBookmarks, "page", '') == page ? "secondary" : "action"} />}
                                    </AddBookmarkButton></BookmarkLinkWrapper>}
                                    {bookBookmarks ? (<BookmarkLinkWrapper>
                                        <MarkButton onClick={() => {
                                            goToBookmark()
                                        }}>
                                            Pg:{getNumberMask(get(bookBookmarks, "page", ''))}
                                        </MarkButton>
                                        {isTeacher && <TrashButton
                                            onClick={() => {
                                                setRemoveBookMark(true)
                                            }}
                                        >
                                            <Delete color='secondary' />
                                        </TrashButton>}

                                    </BookmarkLinkWrapper>) : null}
                                </BookMarkWrapper>
                            </Col>
                        </Row>
                        <EbookGallleryView getRef={getImageRef} onPageSelect={ind => {
                            history.push(generateUrl(ind))
                            setPage(ind)
                        }} viewPageAsIndex />
                        <div ref={imageRef}
                            style={{ position: "relative" }}
                        >
                            {
                                hasSuperLessonPage?.from && currentUser?.role !== "student" &&
                                <LessonViewModal
                                    superLessonData={hasSuperLessonPage}
                                    showModal={hasSuperLessonPage?.from && currentUser?.role !== "student"}
                                    open={viewLesson}
                                    viewLessonType={viewLessonType}
                                    onClose={() => {
                                        setViewLesson(false)
                                        setSuperLessonOpen(false)
                                    }}
                                    data={data}
                                    from={'balcon'} />
                            }
                            {
                                hasDifferentiationLessonPage?.from && currentUser?.role !== "student" &&
                                <DifferentiationViewModal 
                                superLessonData={hasDifferentiationLessonPage} 
                                showModal={hasDifferentiationLessonPage?.from && currentUser?.role !== "student"} 
                                open={viewLessonDifferentiation}
                                viewLessonType={viewLessonTypeDiff} 
                                onClose={() => {
                                    setViewLessonDifferentiation(false)
                                    setDifferentiationOpen(false)
                                }} 
                                data={data}
                                from={'balcon'}
                                />
                            }
                            <MainbookPopup getRef={getImageRef} />
                            <ImageHighlighter imageUrl={getSrcPage(page)} userId={currentUser.id} pageId={`${page}`} bookId={chapter}  imageConfiguration={imageConfiguration} setImageConfiguration={setImageConfiguration}/>
                            <SentenceStarterPopup getRef={getImageRef} />
                            {/* <Images src={getSrcPage(page)} /> */}
                        </div>

                    </ImageContainer>
                    <ToolsContainer isTeacher={currentUser?.role != 'student'}>
                        <BalconCulturalTooling
                            classUsers={classUsers}
                            activity={activity}
                            chapter={CHAPTER_ID}
                            history={history}
                            limit={data?.content.length}
                            page={`${page}`}
                            quiz={quiz}
                            data={data}
                            showCopyRight={showCopyRight}
                            tips={page_tips}
                            existTeacherEdition={existTeacherEdition}
                            existShowAnswer={existShowAnswer}
                            ebookFlag={ebookFlag}
                            setEbookFlag={setEbookFlag}
                            ebookFlagTeacherEdition={ebookFlagTeacherEdition}
                            setEbookFlagTeacherEdition={setEbookFlagTeacherEdition}
                            showSpecialSupport={showSpecialSupport}
                            setShowSpecialSupport={setShowSpecialSupport}
                            studentViewFlag={studentViewFlag}
                            setStudentViewFlag={setStudentViewFlag}
                            handleImagePrint={handleImagePrint}
                            printLoader={printLoader}
                            setSubmitRef={setSubmitRef}
                            timeInt={timeInt}
                            timmer={timeIntRef.current}
                            onFinish={onFinish}
                            onPageChage={re => clearPreviewRef.current = re}
                            pageNumber={`${parseInt(page) - parseInt(bookData?.start) + 1}`}
                            lessonPlan={lessonPlan}
                            rubrics={rubrics}
                            assignmentPlan={assignmentPlan}
                            workbook_plan={workbook_plan}
                            main_book={main_book}
                            id={id}
                            CHAPTER_ID={CHAPTER_ID} // chapter
                            bookInfo={bookData}
                            setPage={setPage}
                            links={getHyperlink()}
                            dataBook={dataBook}
                            pageSrc={getSrcPage(page)}
                            imageConfiguration={imageConfiguration}
                        />
                    </ToolsContainer>
                </Container>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
BalconLayout.propTypes = {
    children: string
};

//  -- Default props ------------------
BalconLayout.defaultProps = {
    children: 'Hello world'
};

export default BalconLayout;
