import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Col, Row, Button } from 'antd';
import PersonIcon from '@material-ui/icons/Person';
import { Modal, Box , IconButton } from '@material-ui/core';
import { TagFilled } from '@ant-design/icons';
import { useOneWayTeacherVideo } from 'store/1wayteachervideo/selectors';
import { handleListOneWayTeacherVideo } from 'store/1wayteachervideo/thunks';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import { closeModal, videoLink } from "utils/assets/images"
import { filter, get } from 'lodash'

import { Auth } from "aws-amplify";
//  -- Components ---------------------
import { useHistory } from 'react-router-dom';
import { ebookConfigDinamic } from 'utils/lib/ebookHelper';
import { useAlertView } from "store/alert/selectors";
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useAllBook } from 'store/books/selectors';
import { useReactToPrint } from 'react-to-print';
import { Close , Print as PrintIcon} from '@material-ui/icons';
import Loading from 'components/Loading';
import color from 'utils/constants/color';

const LearnPlanWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;
`;

const LessionPlanContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 90%;
    position: relative;
    justify-content: center;
    padding: 20px;
    background-color: white;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;
const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;
const LessionContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    max-width: 1320px;
    padding: 64px 0px 0px;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    @media (min-width: 997px) {
        flex-direction: row;
        padding: 0px 30px;
    }
`;
const PrintWrapper = styled.div`
`;

const Images = styled.img`
    background-color: ${color.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    object-fit: cover;
    height:600px;
    @media (max-width: 768px) {
        height:400px;
    }
    @media (min-width: 768px) {
        height:620px;
    }
    @media (min-width: 1600px) {
        height:700px;
    }
    @media print {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
`;


function getGraphicOrganizerData(data) {
    const MIN_PAGE = 518;
    const MAX_PAGE = 545;
    if (Array.isArray(data)) {
        return filter(data, k => {
            return parseInt(k.pageId) >= MIN_PAGE && parseInt(k.pageId) <= MAX_PAGE
        })
    }
    return [];
}

function TeacherStrategies() {
    const imageRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const booksAllList = useAllBook()
    const [, invokeAlert, revokeALert] = useAlertView()
    const orgPermissions = useCurrentOrgPermission()
    const hasPrintOption = useMemo(() => get(orgPermissions, "printing", true), [orgPermissions])
    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [contents,setContents] = useState([]);
    const [pageModalInfo, setPageModalInfo] = React.useState({
        open: false,
        src: ''
    });
    const [printLoader, setPrintLoader] = useState(false)

    useEffect(() => {
        setTimeout(() => setMounted(true), 250)
    }, []);

    useEffect(() => {
        if (orgPermissions && typeof orgPermissions['1WAYTEACHER'] === "boolean") {
            if (!orgPermissions['1WAYTEACHER']) {
                invokeAlert()
                setTimeout(() => {
                    history.goBack();
                    revokeALert()
                }, 4000)
            }
        }
    }, [orgPermissions])
    useEffect(() => {
        async function fetchData() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch {
                history.push('/');
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (Array.isArray(booksAllList)) {
            const data = ebookConfigDinamic("7", booksAllList);
            const pageData = getGraphicOrganizerData(data.content)
            setContents(pageData)
            setFilteredData(pageData)
        }
    }, [booksAllList])

    const handleLessionImagePrint = useReactToPrint({
        content: () => imageRef.current,
        onBeforeGetContent: () => {
            setPrintLoader(true);
        },
        onBeforePrint: () => {
            setPrintLoader(false);
        },
        onAfterPrint: () => {
            setPrintLoader(false);
        }
    });
    const handleOpen = (item) => setPageModalInfo({
        open: true,
        src: item.src
    });
    const handleClose = () => setPageModalInfo({
        open: false,
        src: ''
    });
    const filterItemTxt = (txt) => {
        if (txt.length > 0) {
            setFilteredData(filter(contents, k => k.description ? k.description.toLowerCase().includes(txt.toLowerCase()) : false))
        } else {
            setFilteredData(contents)
        }
    }
    const loader = () => {
        return (<div class="skeleton-wrapper">
            <div class="skeleton-card" style={{ textAlign: "center" }}>{loading ? '' : "No content available!"}</div></div>)
    }
    return (
        <div className="teacherStrategies">
            <Modal
                open={pageModalInfo.open}
                onClose={handleClose}
                className={"rubricsPopUp"}
                style={{ height: '100%', width: '100%', backgroundColor: "transparent" }}
                onContextMenu={e=>{
                    e.preventDefault();
                }}
            >
                <LearnPlanWrapper>
                    <LessionPlanContainer>
                        <CloseBtn onClick={handleClose}>
                            <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 25 }} />
                        </CloseBtn>

                        <IconButton onClick={() => handleLessionImagePrint()} aria-label="print"
                            style={{ position: "absolute", right: "40px", top: "0px", zIndex: "1" }}
                            disabled={printLoader}>
                            {printLoader ? <Loading size={20} /> : <PrintIcon />}
                        </IconButton>

                        <LessionContainer>
                            <PrintWrapper ref={imageRef}>
                                <Images src={pageModalInfo.src} />
                            </PrintWrapper>
                        </LessionContainer>
                    </LessionPlanContainer>
                </LearnPlanWrapper>
            </Modal>
            <Row className='catGryFilterRow'>
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                    <div className='filterTxts'>
                        <p>Choose your graphic organizer!</p>
                    </div>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <div className='catGryfilterSearch'>
                        <p>Search:</p>
                        <input type="text" placeholder="" onChange={e => filterItemTxt(e.target.value)} />
                    </div>
                </Col>
            </Row>

            <div className='myCategorys'>
                {filteredData.length > 0 ? filteredData.map((category, index) => (
                    <div className='myCategoryList' style={{width:300}} key={index}>
                        <div className="ShowDetailBnr" onClick={()=>handleOpen(category)}>
                            <img style={{ height: "auto" }} src={category.src} alt="" /></div>
                        <div className='categoryBtnTxt' style={{ height: "auto", paddingBottom: 10 }}>
                            <div className='onway-teacher-txt' style={{ fontSize: 14, height: "auto", minHeight: 50 , paddingLeft:8 }}>
                                {get(category, "description", "") || ""}
                            </div>
                            <div className='persionIconImg' style={{ fontSize: 8 , color: "#fff" }}>
                                <PersonIcon className='iconClrChange' />
                                <span>{"ONE WAY EDUCATION"}</span>
                            </div>
                        </div>
                    </div>
                )) : loader()}
            </div>

        </div>
    )
}

export default TeacherStrategies;