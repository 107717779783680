//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create submission --------------
export const createSubmission = () => ({ type: ActionTypes.submissions.createSubmission });
export const createSubmissionFail = (returnedPayload) => ({ type: ActionTypes.submissions.createSubmissionFail, payload: returnedPayload });
export const createSubmissionSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.createSubmissionSuccess, payload: returnedPayload });

//  -- Delete user submissions --------
export const deleteUserSubmissions = () => ({ type: ActionTypes.submissions.deleteUserSubmissions });
export const deleteUserSubmissionsFail = (returnedPayload) => ({ type: ActionTypes.submissions.deleteUserSubmissionsFail, payload: returnedPayload });
export const deleteUserSubmissionsSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.deleteUserSubmissionsSuccess, payload: returnedPayload });

//  -- Delete user submissions --------
export const deleteUserSubmissionsWithAssignment = () => ({ type: ActionTypes.submissions.deleteUserSubmissionsWithAssignment });
export const deleteUserSubmissionsWithAssignmentFail = (returnedPayload) => ({ type: ActionTypes.submissions.deleteUserSubmissionsWithAssignmentFail, payload: returnedPayload });
export const deleteUserSubmissionsWithAssignmentSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.deleteUserSubmissionsWithAssignmentSuccess, payload: returnedPayload });

//  -- Get submission -----------------
export const getSubmission = () => ({ type: ActionTypes.submissions.getSubmission });
export const getSubmissionFail = (returnedPayload) => ({ type: ActionTypes.submissions.getSubmissionFail, payload: returnedPayload });
export const getSubmissionSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.getSubmissionSuccess, payload: returnedPayload });

//  -- List chapter submissions -------
export const listAssignmentSubmissions = () => ({ type: ActionTypes.submissions.listAssignmentSubmissions });
export const listAssignmentSubmissionsFail = (returnedPayload) => ({ type: ActionTypes.submissions.listAssignmentSubmissionsFail, payload: returnedPayload });
export const listAssignmentSubmissionsSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.listAssignmentSubmissionsSuccess, payload: returnedPayload });

//  -- List class submissions ---------
export const listClassSubmissions = () => ({ type: ActionTypes.submissions.listClassSubmissions });
export const listClassSubmissionsFail = (returnedPayload) => ({ type: ActionTypes.submissions.listClassSubmissionsFail, payload: returnedPayload });
export const listClassSubmissionsSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.listClassSubmissionsSuccess, payload: returnedPayload });

//  -- List organization submissions --
export const listOrgSubmissions = () => ({ type: ActionTypes.submissions.listOrgSubmissions });
export const listOrgSubmissionsFail = (returnedPayload) => ({ type: ActionTypes.submissions.listOrgSubmissionsFail, payload: returnedPayload });
export const listOrgSubmissionsSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.listOrgSubmissionsSuccess, payload: returnedPayload });

//  -- List user submissions ----------
export const listUserSubmissions = () => ({ type: ActionTypes.submissions.listUserSubmissions });
export const listUserSubmissionsFail = (returnedPayload) => ({ type: ActionTypes.submissions.listUserSubmissionsFail, payload: returnedPayload });
export const listUserSubmissionsSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.listUserSubmissionsSuccess, payload: returnedPayload });

//  -- Update submission --------------
export const updateSubmission = () => ({ type: ActionTypes.submissions.updateSubmission });
export const updateSubmissionFail = (returnedPayload) => ({ type: ActionTypes.submissions.updateSubmissionFail, payload: returnedPayload });
export const updateSubmissionSuccess = (returnedPayload) => ({ type: ActionTypes.submissions.updateSubmissionSuccess, payload: returnedPayload });
