//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- users ----------------
export const setChatUser = (returnedPayload) => ({ type: ActionTypes.chat.users.setChatUser, payload: returnedPayload });
export const clearChatUser = () => ({ type: ActionTypes.chat.users.clearChatUser });
export const updateChatUser = (returnedPayload) => ({ type: ActionTypes.chat.users.updateChatUser, payload: returnedPayload });
export const updateUsersConfigStatus = (config) => ({ type: ActionTypes.chat.users.updateChatUserConfig, payload: config })
//user status
export const setUserActiveStatus = (userId) => ({ type: ActionTypes.chat.status.setActive, payload: userId })
export const setUserDeactiveStatus = (userId) => ({ type: ActionTypes.chat.status.deactive, payload: userId })


//messages
export const setMessage = (messages) => ({ type: ActionTypes.chat.message.setMessage, payload: messages })
export const setMessageStored = (id) => ({ type: ActionTypes.chat.message.messageStore, payload: id })
export const updateMessage = (messages) => ({ type: ActionTypes.chat.message.updateMessage, payload: messages })
export const createMessage = (message) => ({ type: ActionTypes.chat.message.createMessage, payload: message })
export const updateSeenDelivery = (message) => ({ type: ActionTypes.chat.message.updateseenDelivery, payload: message })
export const updateUserSeenMessageByUserId = (senderId) =>({type: ActionTypes.chat.message.userMessageSeenUpdate , payload:senderId })


export const createSocket = (socket) => ({ type: ActionTypes.chat.socket.setSocket, payload: socket })
export const updateSocket = (socket) => ({ type: ActionTypes.chat.socket.updateSocket, payload: socket })
export const clearSocket = () => ({ type: ActionTypes.chat.socket.clearSocket })


//userChatConfig

export const createChatUserConfig = (config) => ({ type: ActionTypes.chat.chatUserConfig.setChatUserConfig, payload: config })
export const updateChatUserConfig = (config) => ({ type: ActionTypes.chat.chatUserConfig.updateChatUserConfig, payload: config })
export const clearChatUserConfig = () => ({ type: ActionTypes.chat.chatUserConfig.clearChatUserConfig })


//scheduled message
export const setScheduledMessage = (msg) => ({ type: ActionTypes.chat.scheduledMessage.setScheduledMessage, payload: msg })
export const createScheduledMessage = (msg) => ({ type: ActionTypes.chat.scheduledMessage.createScheduledMessage, payload: msg })
export const updateScheduledMessage = (msg) => ({ type: ActionTypes.chat.scheduledMessage.updateScheduledMessage, payload: msg })
export const clearScheduledMessage = () => ({ type: ActionTypes.chat.scheduledMessage.clearScheduledMessage })
export const removeScheduledMessage = (msgId) => ({ type: ActionTypes.chat.scheduledMessage.removeScheduledMessage, payload: msgId })