//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map , filter , cloneDeep , orderBy } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const Initial = []

export const story = (state = Initial, action) => {
    switch (action.type) {
        case ActionTypes.story.createStorySuccess:
            return [...state,action.payload];
        case ActionTypes.story.updateStorySuccess:
            return map(state,st=>{
                if(st.id===action.payload.id) {
                    return action.payload;
                }
                return st;
            });
        case ActionTypes.story.createStoryFailure:  
        case ActionTypes.story.updateStoryFailure:
        case ActionTypes.story.listStorySuccess:
            return action.payload;
        case ActionTypes.story.deleteStorySuccess:
            return filter(state, st=>st.id!=action.payload)
        default:
            return state;
    }
};

export const standards = (state = [], action) => {
  const nState = cloneDeep(state);
  switch (action.type) {
    case ActionTypes.story.setStandardsSuccess:
      return orderBy(action.payload, "id", "desc");
    case ActionTypes.story.createStanderdsSuccess:
      nState.concat(action.payload);
      return orderBy(nState, "id", "desc");
    case ActionTypes.story.updateStanderdsSuccess:
      return orderBy(
        map(nState, (st) => {
          if (st.id == action.payload.id) {
            return action.payload;
          } else {
            return st;
          }
        }),
        "id",
        "desc"
      );
    case ActionTypes.story.deleteStanderdsSuccess:
      return orderBy(
        filter(nState, (st) => st.id != action.payload.id),
        "id",
        "desc"
      );
    case ActionTypes.story.setStandardsFailure:
      return state;
    default:
      return state;
  }
};

const literacy_purpose_init = [];

export const literacy_purpose = (state = literacy_purpose_init, action)=>{
  switch (action.type) {
    case ActionTypes.literacy_purpose.setOverAll:
        return action.payload
    default:
        return state;
}
}