//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Modal, TextField } from '@material-ui/core';

//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import { lighten } from 'polished';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//  -- Thunks -------------------------
import { handleLogin, handleRedirect } from 'store/auth/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import { Auth } from "aws-amplify";
import queryString from 'query-string';
import Schoology from 'utils/Schoology';
import COLOR from 'utils/constants/color';
import { authHelper } from 'utils/lib/authHelper';

import { makeStyles } from '@material-ui/core/styles';
import { Close, } from '@material-ui/icons';
import { useGoogleLogin } from '@react-oauth/google';
import CotationLoader from 'components/CotationLoader';
import config from 'config';
import { get, has, isEmpty, map } from 'lodash';
import storage from 'redux-persist/lib/storage';
import { useCanvasDistrictAuth } from 'store/canvas/selectors';
import { handeleCanvasCode, handeleCanvasUserClasses, handeleCanvasUserLogin, handleCheckUserLti, handleListDistrictAuth , handleCheckUserAuthOnLti } from "store/canvas/thunks";
import { getClasslinkInformation, handleClassLinksUserInfo, handleUserClassesSync } from 'store/classlink/thunks';
import { getUserByCleverId, handleCleverClassesUsers, handleCleverUserClasses, handleCleverUserRegistration } from 'store/clever/thunks';
import { getRefreshToken, handleGoogleUserClasses, handleGooleClassRoomUserRegistration } from "store/googleClassRoom/thunks";
import { getKeystore } from 'store/keystore/thunks';
import { handleSchoologyLogin, handleSyncSchoologyClasses } from 'store/schoology/thunks';
import { handleCreateLogs, handleCreateSchoologyAuth, handleSetSchoologyAuth, handleUpdateLogs } from 'store/schoologyauth/thunks';
import { getUserById, handleCheckUsername } from 'store/users/thunks';
import { BirdLogo } from "utils/assets/images";
import { Clever } from "utils/clever";
import { getRandomCotation } from "utils/methods/math";
import { handleInvokeAlert } from 'store/alert/thunks';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const SCOPE = 'https://www.googleapis.com/auth/classroom.courses  https://www.googleapis.com/auth/classroom.rosters https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.profile.emails'
const client = new Schoology();




//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 18px;
`;

const Description = styled.span`
    color: ${COLOR.black500};
    display: block;
    font-size: 12px;
    font-weight: 300;
`;

const Error = styled.div`
    background-color: ${lighten(0.45, 'red')};
    border: 1px solid ${lighten(0.2, 'red')};
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    width: 100%;
`;
const AutoLoginMessage = styled.div`
    border-radius: 3px; 
    color: red;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    width: 100%;
`

const Form = styled.form`
    align-items: center;
    display: flex; 
    flex-direction: column;
    margin-top: 24px;
`;

const Link = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.green100};
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    transition: all 300ms ease;

    &:hover {
        text-decoration: underline;
    }
`;

const RegisterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
`;

const Text = styled.span`
    color: ${COLOR.black400};
    display: inline-block;
    font-size: 13px;
    margin-right: 3px;
`;

const Toggle = styled.button`
    background-color: transparent;
    border: none;
    border-bottom: 2px solid ${p => p.active ? COLOR.green100 : COLOR.black800};
    color: ${p => p.active ? COLOR.black200 : COLOR.black500};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 10px 0px; 
    padding-top: 0px;
    text-transform: uppercase;
    transition: all 300ms ease;
    width: 50%;

    &:hover {
        border-bottom: 2px solid ${COLOR.green700};
        color: ${COLOR.black400};
    }
`;

const Wrapper = styled.div`
    max-width: 400px;
    position: relative;
    width: 100%;
`;
const VideoContainer = styled.div`
    height: auto;
    flex: 1;
    justify-content: center;
`;
const FeedBackWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;
const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50%;
    min-height: 50%;
    background-color: rgb(58,58,58);
    position: relative;
    justify-content: center;
    padding: 20px;
    padding-top: 0px;

`;


const HyperLinkContainer = styled.div`
    width:100%;
    background-image: linear-gradient(to right, rgb(189,38,95), rgb(217,87,61));
    color: white;
    z-index: 1;
    white-space: pre-line;
    padding: 10px;
    min-height: 50px;
`;
const HyperLinksInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border:1px solid #fff;
    min-height: 50px;
    color: #fff;
    padding: 15px;
`;
const LinksItem = styled.a`
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
        color: ${COLOR.white};
    }
    &:before {
        display: inline-block;
        content: '';
        -webkit-border-radius: 0.375rem;
        border-radius: 0.375rem;
        height: 0.75rem;
        width: 0.75rem;
        margin-right: 0.5rem;
        background-color: #fff;
    }
`;

const BirdLogoImg = styled.div`
    width: 270px;
    height: 180px;
    margin-top: 20px;
    background-image: url(${BirdLogo});
    background-position: top;
    background-size: 150% 130%;
    background-repeat: no-repeat;
`;

const CloseBtn = styled.div`
    background-color: rgb(204,80,71);
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    z-index: 1;
`;

const GoogleClassroomButton = styled.button`
  background-color: #fff; /* Google Classroom color */
  color: black;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s;
  font-family: 'Roboto', sans-serif;

  &::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/assets%2F96x96_yellow_stroke_icon%402x%20(1).png?alt=media&token=744575cd-08ce-4750-b0c1-117b2f1acdda');
    background-size: cover;
    margin-right: 8px;
    vertical-align: middle;
  }
`;
 
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  -- Description
//  User authentication
//
//  -- Props
//  ...
//
const LoginForm = ({ toggleRegistration, toggleTransition }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const search = queryString.parse(history.location.search);
    const schoologyAuth = useSelector(state => state.schoologyAuth);
    const [isAutoLogin, setIsAutoLogin] = useState(false);
    const [autologinMessage, setAutologinMessage] = useState('Please wait, while auto login is in process');
    const classes = useStyles();
    const [loginProcess, setLoginProcess] = useState(false)
    const cleverClient = new Clever()
    //  -- Form Data ---------------------
    const [adminData, setAdminData] = useState({});
    const [studentData, setStudentData] = useState({}); // useState({ password: 'holder' });
    const [canvasDistrict , setCanvasDistrict] = useState({});
    const [canvasDistrictLoader,setCanvasDistrictLoader] = useState(false)
    const [canvasDistModal , setCanvasDistrictModal] = useState(false);
    const canvasAuthDistrict = useCanvasDistrictAuth()
    let authInfo = {};

    const handleCode = async () => {
        try {
            if (typeof search === "object" && search.code) {
                setLoading(true);
                setIsAutoLogin(true);
                const   canvas_selected_auth_id =   localStorage.getItem("canvas_selected_district")
                const canvasAuth = await handeleCanvasCode(search.code,canvas_selected_auth_id)
                if(canvasAuth&&canvasAuth.access_token){
                    localStorage.removeItem("canvas_selected_district")
                    handleCanvas(canvasAuth)
                    return;
                }
                const cleverResponse =  await cleverClient.getUserByCode(search.code)
                if (cleverResponse && cleverResponse.access_token) {
                    handleClever(cleverResponse)
                    return;
                }
                

                getClasslinkInformation(search.code).then(async response => {
                    const data = response;
                    if (has(data,"SourcedId")) {
                        let res = authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD })
                        await Auth.signIn(res);
                        const userData = await  handleClassLinksUserInfo(data.SourcedId, data.TenantId)
                        if(has(userData,"id")){
                            await handleUserClassesSync( data.SourcedId,data.TenantId)
                            loginWithSchoology(userData)
                        }else{
                          if(has(userData,"error")&&typeof userData.error=="string"){
                            setAutologinMessage(userData.error);
                            setError(userData.error);
                            setLoading(false);
                            setIsAutoLogin(false);
                          }else{
                            setAutologinMessage('Unable to register user with code');
                            setError('Unable to register user with code');
                            setLoading(false);
                            setIsAutoLogin(false);
                          }
                        }
                    } else {
                        setAutologinMessage('Unable to fetch user with code');
                        setError('Unable to fetch user with code');
                        setLoading(false);
                        setIsAutoLogin(false);
                    }
                });
                 }
            else if(typeof search === "object" && search.custom_canvas_api_domain){
                setLoading(true);
                setIsAutoLogin(true);
                const { custom_canvas_api_domain , custom_canvas_user_id  , custom_canvas_course_id } = search
                const canv_user =  await handleCheckUserLti(custom_canvas_user_id,custom_canvas_api_domain)
                if(canv_user&&canv_user.success){
                    const udata = canv_user.data;
                    if(udata&&udata.auth_found){
                         await  handleCanvas(canv_user.data)  
                    }else if(udata.auth){
                        const auth_data = udata.auth
                        if(auth_data&&auth_data.base_url){
                            localStorage.setItem("canvas_selected_district",auth_data.id)
                            authenticateWithCanvas(auth_data.base_url,auth_data.key,auth_data.redirect_uri)  
                        }else{
                            setAutologinMessage(canv_user && canv_user.message ? canv_user.message : 'Something went wrong');
                            setError(canv_user && canv_user.message ? canv_user.message : 'Something went wrong');
                            setLoading(false);
                            setIsAutoLogin(false);
                        }
                    }else{
                        setAutologinMessage('Your district is not configured. Please contact the system administrator.');
                    setError('Your district is not configured. Please contact the system administrator.');
                    setLoading(false);
                    setIsAutoLogin(false);  
                    }
                }else{
                    setAutologinMessage('Something went wrong');
                    setError('Something went wrong');
                    setLoading(false);
                    setIsAutoLogin(false);
                }
            }else if(typeof search === "object" && search.lti_message_type){
                if(document.referrer&&search.user_id){
                    const domain = new URL(document.referrer).hostname;
                    const canv_user =  await handleCheckUserLti(search.user_id,domain)
                    if(canv_user&&canv_user.success){
                        const udata = canv_user.data;
                        if(udata&&udata.auth_found){
                             await  handleCanvas(canv_user.data)  
                        }else if(udata.auth){
                            const auth_data = udata.auth
                            if(auth_data&&auth_data.base_url){
                                localStorage.setItem("canvas_selected_district",auth_data.id)
                                authenticateWithCanvas(auth_data.base_url,auth_data.key,auth_data.redirect_uri)  
                            }else{
                                setAutologinMessage(canv_user && canv_user.message ? canv_user.message : 'Something went wrong');
                                setError(canv_user && canv_user.message ? canv_user.message : 'Something went wrong');
                                setLoading(false);
                                setIsAutoLogin(false);
                            }
                        }else{
                            setAutologinMessage('Your district is not configured. Please contact the system administrator.');
                        setError('Your district is not configured. Please contact the system administrator.');
                        setLoading(false);
                        setIsAutoLogin(false);  
                        }
                    }else{
                        setAutologinMessage('Something went wrong');
                        setError('Something went wrong');
                        setLoading(false);
                        setIsAutoLogin(false);
                    }
                }
               else{
                setAutologinMessage('Something went wrong');
                setError('Something went wrong');
                setLoading(false);
                setIsAutoLogin(false);
               }
            }
            else if(typeof search === "object" && search.id_token){
                setLoading(true);
                setIsAutoLogin(true);
                const { id_token } = search
                const canv_user =  await handleCheckUserAuthOnLti(id_token,document.referrer)
                if(canv_user&&canv_user.success){
                    const udata = canv_user.data;
                    if(udata&&udata.auth_found){
                         await  handleCanvas(canv_user.data)  
                    }else if(udata.auth){
                        const auth_data = udata.auth
                        if(auth_data&&auth_data.base_url){
                            localStorage.setItem("canvas_selected_district",auth_data.id)
                            authenticateWithCanvas(auth_data.base_url,auth_data.key,auth_data.redirect_uri)  
                        }else{
                            setAutologinMessage(canv_user && canv_user.message ? canv_user.message : 'Something went wrong');
                            setError(canv_user && canv_user.message ? canv_user.message : 'Something went wrong');
                            setLoading(false);
                            setIsAutoLogin(false);
                        }
                    }
                }else{
                    setAutologinMessage(canv_user&&canv_user.message?canv_user.message:'Something went wrong');
                    setError(canv_user&&canv_user.message?canv_user.message:'Something went wrong');
                    setLoading(false);
                    setIsAutoLogin(false);
                }
            }
        } catch (error) {
            console.log(error,"errorerror")
            setAutologinMessage('There was an error while processing the auto login');
            setError('There was an error while processing the auto login');
            setLoading(false);
            setIsAutoLogin(false);
        }
    }
    const handleRegisterCleverUser = async(id,token) => {
        const registedUserData = await handleCleverUserRegistration(id,token);
        if(has(registedUserData,"id")){
            try {
            setAutologinMessage('User registered fetching user sections....');
            await handleCleverUserClasses(id, token);
            setAutologinMessage('User sections fetched , fetching class users ....');
            await handleCleverClassesUsers(id,token);
            } catch (error) {
                console.log(error,"error in handling userclass");
            }
            
            const userinfo = registedUserData;
            if(userinfo){
                let res = authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD })
                await Auth.signIn(res);
                loginWithSchoology(userinfo)
            }
            else {
                setAutologinMessage('Unable to register this  clever user.');
                setError('Unable to register this  clever user.');
                setLoading(false);
                setIsAutoLogin(false);
            }

        } else {
            setAutologinMessage('Unable to register this  clever user.');
            setError('Unable to register this  clever user.');
            setLoading(false);
            setIsAutoLogin(false);
        }

    }
    const handleClever = async (users) => {
        try {
            if (users && users.access_token) {
                const cleverUserId = get(users,"data.id")
                const userinfo = await  getUserByCleverId(cleverUserId)
                if(userinfo){
                    setAutologinMessage('User found,fetching user sections....');
                    setAutologinMessage('User sections fetched , fetching class users ....');
                       await  Promise.allSettled([
                         handleCleverUserClasses(cleverUserId,users.access_token),
                         handleCleverClassesUsers(cleverUserId,users.access_token)
                       ]).then(res=>{
                        console.log(res,"on sync class and books for clever");
                       })
                    let res = authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD })
                    await Auth.signIn(res);
                    loginWithSchoology(userinfo)
                } else {
                    handleRegisterCleverUser(cleverUserId, users.access_token);

                }

            } else {
                setAutologinMessage("Unable to get user from Clever.");
                setError("Unable to get user from Clever.");
                setLoading(false);
                setIsAutoLogin(false);
                return;
            }
        } catch (error) {
            setAutologinMessage('There was an error while processing the auto login');
            setError('There was an error while processing the auto login');
            setLoading(false);
            setIsAutoLogin(false);
        }

    }
    const handleCanvas = async (authInfo) =>{
        await Auth.signIn(authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD }));
        if(authInfo&&authInfo.access_token){
            localStorage.setItem('canvas_auth_token',authInfo.access_token);
        }
        if(has(authInfo,"username")){
            try {
                await handeleCanvasUserClasses(authInfo.access_token,authInfo.canvas_id,authInfo.canvas_auth_id)
            } catch (error) {  
            }
            loginWithSchoology(authInfo)
        }
       else if (authInfo.access_token) {
        setAutologinMessage('Please wait, while auto login is in process');
        setError('Please wait, while auto login is in process');
        setLoading(true);
        setIsAutoLogin(true);
        const hasReg = await handeleCanvasUserLogin(authInfo.access_token,get(authInfo,"user.global_id"),authInfo.auth_id)
        setAutologinMessage('User data fetched successfully, waiting for sync classes...');
        if (has(hasReg, "id")) {
            await handeleCanvasUserClasses(authInfo.access_token,hasReg.canvas_id,authInfo.auth_id)
            loginWithSchoology(hasReg)
        } else {
            if(hasReg&&typeof hasReg.error == "string") {
                setAutologinMessage(hasReg.error);
                setError(hasReg.error);
                setLoading(false);
                setIsAutoLogin(false);
            }
           
        }
    }else{
        setAutologinMessage('Unable to fetch user with code');
        setError('Unable to fetch user with code'); 
        setLoading(false);
        setIsAutoLogin(false);
    }
    }
    const getSchoology = async () => {
        try {
            if (typeof search === "object" && ((search.uid && search.role_id && search.school_nid && search.school_title) || (search.uid && search.oauth_token))) {
                const UID = search.context_id ? search.context_id : search.uid;
                let res = authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD })
                setLoading(true);
                setIsAutoLogin(true);
                await Auth.signIn(res);
                const searchItem = search.school_nid ? search : JSON.parse(localStorage.getItem('searchItem') ?? {});
                const userdata = await handleSchoologyLogin({
                    "schoologyid": UID,
                    "redirectItem": {
                        "building_nid": searchItem.building_nid,
                        "building_title": searchItem.building_title,
                        "school_nid": searchItem.school_nid,
                        "school_title": searchItem.school_title,
                        ...searchItem
                    }
                })
                if (get(userdata, "user.id")) {
                     setAutologinMessage(`User found classes syncing ......`);
                     setError(`User found classes syncing ......`);
                     const last_log_id = localStorage.getItem("log_id");
                    if (last_log_id) {
                        await handleUpdateLogs({
                            id: last_log_id,
                            login_status: "user_login_success",
                            error: null,
                            updates: userdata
                        })
                    }
                    await handleSyncSchoologyClasses(UID)
                    loginWithSchoology(userdata.user)
                } else {
                    const last_log_id = localStorage.getItem("log_id");
                    if(last_log_id){
                        await handleUpdateLogs({
                            id:last_log_id,
                            login_status:"user_login_fail" ,
                            error:userdata,
                            updates:null
                        })
                    }
                    console.log(userdata)
                    if (typeof userdata.error == "string") {
                        setAutologinMessage(userdata.error);
                        setError(userdata.error);
                        setLoading(false);
                    } else {
                        setAutologinMessage(`having some issue while login please try again`);
                        setError(`having some issue while login please try again`);
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
                setIsAutoLogin(false);
            }

        } catch (error) {
            setLoading(false);
            setIsAutoLogin(false);
            console.log(error, "eeee");
        }
    }

    const loginWithSchoology = async (userData) => {
        let route = await dispatch(handleRedirect({}, userData));
        if (route.path) {
            if (route.path == "/") {
                setLoading(false);
                setIsAutoLogin(false);
                setError(route.message)
            } else {
                toggleTransition();
                setTimeout(() => history.push(route.path), 700);
            }
        } else {
            setLoading(false);
            setIsAutoLogin(false);
            setError(route.message)
        }
    }

    const handleChange = (e, type) => {

        if (error) {
            setError(null);
        }

        if (type === 'studentData') {
            setStudentData({
                ...studentData,
                [e.target.id]: e.target.value
            });
        } else {
            setAdminData({
                ...adminData,
                [e.target.id]: e.target.value
            });
        }
    };
    const requestToken = () => {
        if (!search.oauth_token) {
            client.getRequestToken().then(data => {
                authInfo = data;
                localStorage.setItem("authInfo", JSON.stringify(authInfo));
                localStorage.setItem("searchItem", JSON.stringify(search))
                let callback = `${window.location.origin}/?uid=${search.uid}&role_id=${search.role_id}&school_nid=${search.school_nid}&school_title=${search.school_title}`
                let _url = `https://app.schoology.com/oauth/authorize?oauth_token=${data.oauth_token}&oauth_callback=${callback}`;
                popitup(_url, "login")
            })
        }
    }
    const handleSchoology = async () => {
        try {
            if (typeof search === "object" && ((search.uid && search.role_id && search.school_nid && search.school_title))) {
                setLoading(true);
                setIsAutoLogin(true);
                await handleCreateLogs({
                    url:window.location.href,
                    user_id:search.uid,
                    data:search
                })
                let res = authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD })
                await Auth.signIn(res);
                let hasAuth = await dispatch(handleSetSchoologyAuth(search.uid))
                if (hasAuth) {
                    Auth.signOut();
                    getSchoology()
                } else {
                    requestToken()
                }
            }
            if (search.oauth_token) {
                setLoading(true);
                setIsAutoLogin(true);
                onAuthApproved()
            }
        } catch (error) {
            console.log(error, "eeee");
        }
    }
    function popitup(url, windowName) {
        window.open(url, "_self");
        return false;
    }
    const onAuthApproved = () => {
        authInfo = JSON.parse(localStorage.getItem("authInfo"));
        client.getAccessToken(authInfo).then(async _data => {
            const UID = search.uid;
            const last_log_id = localStorage.getItem("log_id");
            if(last_log_id){
                await handleUpdateLogs({
                    id:last_log_id,
                    login_status:"authenticated" ,
                    error:null,
                    updates:_data
                })
            }
            if (_data.oauth_token && _data.oauth_token_secret) {
                let authCreated = await dispatch(handleCreateSchoologyAuth({ oauth_token: _data.oauth_token, oauth_token_secret: _data.oauth_token_secret, id: UID }))
                if (authCreated) {
                    getSchoology()
                } else {
                    getSchoology()
                }
            }
        })
    }
    const handleRedirectItem = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            let redircted = redirectToEbook()
            if (redircted) {
                return;
            }
        } catch (e) {
        }
    }
    const redirectToEbook = () => {
        let path = search.path
        if (path) {
            path = path.replace(/_/g, '&');
            path = `/${path}`

            if (path.includes("ebook?chapter")) {
                history.push(path)
                return true;
            }
            return false;
        }
        return false;
    }
    React.useEffect(() => {
        if (search.path) {
            handleRedirectItem()
        }
        handleSchoology()
        handleCode()
    }, [])
    const handleOnceLogin = async () => {
        setIsAutoLogin(true)
        const keyinfo = await getKeystore(search.key)
        if (!isEmpty(keyinfo)) {
            const userId = keyinfo.userId;
            if (userId) {
                let res = authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD })
                await Auth.signIn(res);
                const user = await getUserById(userId)
                const userInfo = user[0]
                if (userInfo  && typeof userInfo.active === "boolean" && !userInfo.active) {
                    dispatch(handleInvokeAlert("Your account is now inactive.", "Please, contact our customer support for answers."))
                    setIsAutoLogin(false)
                } else {
                    await loginWithSchoology(userInfo)
                    setIsAutoLogin(false)
                }
            } else {
                setError('Token is expired , Please try login again from Admin')
                setIsAutoLogin(false)
            }
        } else {
            setError('Token is expired , Please try login again from Admin')
            setIsAutoLogin(false)
        }
    }
    React.useEffect(() => {
        if (search.key) {
            handleOnceLogin()
        }
    }, [])
    const fetchCanvasDistrict = async () => {
        setCanvasDistrictLoader(true);
        await dispatch(handleListDistrictAuth())
        setCanvasDistrictLoader(false)
    }
    React.useEffect(()=>{
        fetchCanvasDistrict()
    },[])
    // React.useEffect(()=>{
    // const   canvas_selected_district =   localStorage.setItem("canvas_selected_district")
    // if(canvas_selected_district){
    //     setCanvasDistrict(find(canvasAuthDistrict["id",canvas_selected_district]))
    // }
    // },[canvasAuthDistrict])
    //  -- Form Submit --------------------
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e, type) => {
        e.preventDefault();
        storage.removeItem('persist:root')
        let authenticated;
        let route;

        setLoading(true);
        if (localStorage && localStorage.clear) {
            localStorage.clear()
        }
        if (type === 'student') {
            authenticated = await dispatch(handleLogin({ ...studentData, username: studentData.studentUsername.trim() }));
        } else {
            authenticated = await dispatch(handleLogin({ ...adminData, username: adminData.adminUsername.trim() }));
        }
        if (type === "administrator" && authenticated) {
            const nameCheck = await dispatch(handleCheckUsername(authenticated.username));
            if (nameCheck && (nameCheck.role != "owe_admin" && nameCheck.role != "admin")) {
                Auth.signOut()
                const isStudentOrTeacher = nameCheck.role === "student" || nameCheck.role === "teacher"
                const message = isStudentOrTeacher ? "Please login in student tab." : "Please login with adminstrator account."
                setError(message);
                setLoading(false);
                return;
            } else {
                setLoading(false);
                setError(null);
            }
        }
        if (authenticated) {
            route = await dispatch(handleRedirect(authenticated));
            if(route.path=="/"||!route.path){
                setError(route.message)
                setLoading(false);
                setIsAutoLogin(false);
            } else {
                toggleTransition();
                if (search.path) {
                    let redircted = redirectToEbook()
                    if (redircted) {
                        return;
                    }
                }
                setTimeout(() => history.push(route.path), 700);

            }
        } else {
            setLoading(false); setIsAutoLogin(false);
            setError('Invalid auth credentials, please try again.')
        }
    };

    //  -- Form Control -------------------
    const [formFlag, toggleFormFlag] = useState(false);
    const toggleForm = (e) => {
        e.preventDefault();
        setError(null);
        toggleFormFlag(!formFlag);
    };
    const cotationRef = useRef(null)
    if (!cotationRef.current) {
        cotationRef.current = getRandomCotation()
    }

    const responseGoogle = async (tokenResponse) => {
        if (tokenResponse&&tokenResponse.code) {
            
            setLoginProcess(true);
            setAutologinMessage('Please wait, while auto login is in process');
            setError('Please wait, while auto login is in process');
            setLoading(true);
            setIsAutoLogin(true);
            const refteshToken = await getRefreshToken(tokenResponse.code)
            if(!refteshToken||!refteshToken.refresh_token) {
                setAutologinMessage("Unable to authenticate!");
                setError('Unable to authenticate!');
                setLoading(false);
                setIsAutoLogin(false);
                return null;
            }
            const hasReg = await handleGooleClassRoomUserRegistration(refteshToken.access_token)
            setAutologinMessage('User data fetched successfully, waiting for sync classes...');
          
            if (has(hasReg, "id")) {
                await handleGoogleUserClasses(hasReg.googleId)
                await Auth.signIn(authHelper({ username: config.REACT_APP_USER_ACCOUNT, password: config.REACT_APP_USER_PASSWORD }));
                loginWithSchoology(hasReg)
            } else {
                if(hasReg&&typeof hasReg.error == "string") {
                    setAutologinMessage(hasReg.error);
                    setError(hasReg.error);
                    setLoading(false);
                    setIsAutoLogin(false);
                }
               
            }
            setLoginProcess(false);
        }
    }
    const googlelogin = useGoogleLogin({
        onSuccess: responseGoogle,
        scope: SCOPE,
        flow: 'auth-code',
    });
    const authenticateWithCanvas = (basurl,clientId,redirct_uri) => {
        let _redirect_uri = window.location.origin.includes("localhost")||!redirct_uri?window.location.origin:redirct_uri
        _redirect_uri = _redirect_uri.replace('www.','')
       window.location.href = `${basurl}/login/oauth2/auth?client_id=${clientId}&response_type=code&redirect_uri=${_redirect_uri} `
    };
    return (
        <Wrapper>
            <Modal
                className={classes.modal}
                open={isAutoLogin}
            >
                <VideoContainer>
                    <CotationLoader cotation={cotationRef.current} messages={autologinMessage} />
                </VideoContainer>
            </Modal>
            <Modal
                className={classes.modal}
                open={canvasDistModal}
                onClose={()=>{
                    setCanvasDistrictModal(false);
                }}
            >
                   <FeedBackWrapper>
                    <FeedbackContainer>
                        <Row style={{ justifyContent: "center" }}>
                          <div style={{color:"#fff" , fontWeight:"bold"}}> Please select district to complete your authentication.</div>
                            <CloseBtn onClick={()=> setCanvasDistrictModal(false)} >
                                <Close style={{ color: "#fff", fontWeight: "bolder", fontSize: 35 }} />
                            </CloseBtn>
                        </Row>
                    
                        <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                            <HyperLinkContainer>
                                <HyperLinksInner>
                                    {map(canvasAuthDistrict, liks => (<Button 
                                    key={liks.id}
                                    style={{ color:"#fff"}}
                                    onClick={()=>{
                                        localStorage.setItem("canvas_selected_district",liks.id)
                                        authenticateWithCanvas(liks.base_url,liks.key,liks.redirect_uri)
                                    }}
                                    >{liks.display_name}</Button>))}
                                </HyperLinksInner>
                            </HyperLinkContainer>
                        </Row>
                    </FeedbackContainer>
                </FeedBackWrapper>
            </Modal>
            <Container>
                <Toggle active={!formFlag} onClick={toggleForm}>Student / Teacher</Toggle>
                <Toggle active={formFlag} onClick={toggleForm}>Administrator</Toggle>
            </Container>

            {!formFlag && (
                <>
                    {error && (
                        <Row gutter={[0, 24]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Error>{error}</Error>
                            </Col>
                        </Row>
                    )}
                    <Description>If you do not remember your credentials, please get in contact with your teacher.</Description>
                    <Form onSubmit={e => onSubmit(e, 'student')}>
                        <Row gutter={[0, 24]} style={{ width: '100%' }}>
                            <Col span={24} className="floatingLabelBG">
                                <TextField
                                    autoComplete="off"
                                    defaultValue={""}
                                    id="studentUsername"
                                    label="Unique Username"
                                    onChange={e => handleChange(e, 'studentData')}
                                    variant="outlined"
                                />
                            </Col>
                        </Row> 
                        <Row gutter={[0, 24]} style={{ width: '100%' }} className="addSpc">
                            <Col span={24} className="floatingLabelBG">
                                <TextField
                                    autoComplete="off"
                                    id="password"
                                    label="Password"
                                    onChange={e => handleChange(e, 'studentData')}
                                    variant="outlined"
                                    type="password"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[0, 24]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Button
                                    disabled={(!studentData.studentUsername || studentData.studentUsername?.length < 2 || !studentData.password)}
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    type='submit'
                                >
                                    {loading ? <Loading size={20} white /> : 'Sign In'}
                                </Button>
                                {isAutoLogin && <AutoLoginMessage>{"Please Wait"}</AutoLoginMessage>}
                            </Col>
                            <Col span={24}>
                                <Button
                                    disabled={canvasDistrictLoader}
                                    variant="outlined"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    onClick={()=>setCanvasDistrictModal(true)}
                                    type='button'
                                >
                                {canvasDistrictLoader?<Loading />:null}  Login with Canvas 
                                </Button>

                            </Col>
                        </Row>
                        <Row gutter={[0, 24]} style={{ width: '100%', marginTop: 16 }}>
                            <Col span={24}>
                                <GoogleClassroomButton style={{ width: '100%' }} onClick={e=>{
                                    e.preventDefault();
                                    googlelogin()
                                }}>
                                    {loginProcess ? <Loading size={20} /> : "Google Classroom"}
                                </GoogleClassroomButton>
                                {/* <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    onClick={googlelogin}
                                >{loginProcess ? <Loading size={20} /> : "Google Classroom"}</Button> */}
                            </Col>
                        </Row>
                    </Form>
                    <RegisterContainer>
                        <Text>Need to register for a class?</Text>
                        <Link onClick={toggleRegistration}>Register Now</Link>
                    </RegisterContainer>
                </>
            )}
            {formFlag && (
                <>
                    {error && (
                        <Row gutter={[0, 24]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <Error>{error}</Error>
                            </Col>
                        </Row>
                    )}
                    <Description>Having an issue logging in? Contact your system adminstrator for help.</Description>
                    <Form onSubmit={e => onSubmit(e, 'administrator')}>
                        <Row gutter={[0, 24]} style={{ width: '100%' }}>
                            <Col span={24}>
                                <TextField
                                    autoComplete="off"
                                    defaultValue={" "}
                                    id="adminUsername"
                                    label="Unique Username"
                                    onChange={e => handleChange(e, 'adminData')}
                                    variant="outlined"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[0, 24]} style={{ width: '100%' }} className="addSpc">
                            <Col span={24} className="floatingLabelBG">
                                <TextField
                                    autoComplete="off"
                                    id="password"
                                    label="Password"
                                    onChange={e => handleChange(e, 'adminData')}
                                    variant="outlined"
                                    type="password"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[0, 24]} style={{ width: '100%' }} className="addSpcBtn">
                            <Col span={24}>
                                <Button
                                    disabled={!adminData.password || (!adminData.adminUsername || adminData.adminUsername?.length < 2)}
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    type='submit'
                                >
                                    {loading ? <Loading size={20} white /> : 'Sign In'}
                                </Button>

                            </Col>
                        </Row>
                    </Form>
                </>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
LoginForm.propTypes = {};

//  -- Default props ------------------
LoginForm.defaultProps = {};

export default LoginForm;
