//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { ArrowForward } from "@material-ui/icons";
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { Card, Col, Input, Modal, Row } from 'antd';
import Loading from 'components/Loading';

//  -- Constants ----------------------
import { get, isEmpty, sortBy } from 'lodash';
import { useDispatch } from "react-redux";
import COLOR from "utils/constants/color";
//  -- Thunks -------------------------
import { useEffect } from 'react';
import { createKeystore } from "store/keystore/thunks";
import { useCurrentOrg } from 'store/organizations/selectors';
import { v4 } from 'uuid';
import SchoolForm from "./School";
const BORDERCOLOR = [
    "rgb(200,109,152)",
    "rgb(146,158,227)",
    "rgb(80,233,200)",
    "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Header = styled.span`
    color: ${COLOR.black100};
    display: block;
	font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
	margin: 0px auto;
    // max-width: 480px;
    width: 100%;
`;
const StableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;
const OverViewContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:20px;
`;
const OverViewLabel = styled.span`
  font-size:12px;
  font-weight:400;
`;
const OverViewValue = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
`;
const FilterContainer = styled.span`
  font-size:17px;
  font-weight:500;
  cursor:pointer;
  margin-bottom:10px;
`;
const StableWrapperMain = styled.div`
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const SchoolsTable = ({
    schools,
    userloading,
    studentsByOrg,
    teacherByOrg,
    onUserAddOrUpdate = () => null
}) => {
    const [schoolForm, setSchoolForm] = useState(false);
    const [editSchool, setEditSchool] = useState(false);
    const [school, setSchool] = useState(null);
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [showSchoolsTeachers, setShowSchoolsTeachers] = useState(-1);
    const currentOrg = useCurrentOrg()
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = React.useState(0);
    const [loginLoader, setLoginLoader] = useState(null);
    const [modal2Open, setModal2Open] = useState(false);
    const [data, setData] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setFilteredSchools(sortBy(schools, ["displayName"]))
    }, [schools])
    const handleSearch = (event) => {
        if (event.target.value && event.target.value.length > 2) {
            var search = event.target.value;

            const filtered = schools.filter(x => {
                return (
                    `${x.displayName}`.toLowerCase().includes(search.toLowerCase())
                );
            });
            setFilteredSchools(sortBy(filtered, ["displayName"]))
        } else {
            setFilteredSchools(sortBy(schools, ["displayName"]))
            setShowSchoolsTeachers(-1);
        }
    };
    const handleAddSchools = () => {
        setSchool({});
        setSchoolForm(true);
        setFilteredSchools(null);
        setShowSchoolsTeachers(-1);
    }
    const handleEditSchools = (school) => {
        setEditSchool(true);
        setSchool(school);
        setSchoolForm(true);
        setFilteredSchools(sortBy(schools, ["displayName"]))
        setShowSchoolsTeachers(-1);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const goToClass = async (id, user) => {
        openLoginInNewWindow(user.id)
    };
    const openLoginInNewWindow = async (userId) => {
        setLoginLoader(userId);
        const key = v4()
        await createKeystore(userId, key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoginLoader(null);
    }
    const substract = (a, b) => {
        let _a = parseInt(a);
        let _b = parseInt(b);
        if (isNaN(_a)) _a = 0;
        if (isNaN(_b)) _b = 0;
        return _a - _b;
    };
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    return (
        <>
            {schoolForm ? (
                <StableWrapper style={{ padding: 24 }}>
                    <SchoolForm
                        currentOrg={currentOrg}
                        school={school}
                        editing={editSchool}
                        onClose={() => {
                            setSchoolForm(false);
                            setSchool(null);
                            setEditSchool(false);
                            onUserAddOrUpdate()
                        }}
                    />
                </StableWrapper>
            ) : (
                <StableWrapperMain>
                    <FilterContainer className="FilterHeading">
                        <Card
                            style={{ marginTop: 16 }}
                            type="inner"
                            title="Filters"
                            // extra={<a>Clear</a>}
                        >
                            <Row>
{/* 
                                <Col xs={24} lg={6} md={8} className="ColCardPadding">
                                    <Card style={{ width: '100%' }}>
                                        <Select
                                            bordered={false}
                                            defaultValue="SHARING TYPE"
                                            style={{ width: '100%' }}
                                            onChange={handleChange}
                                            options={[
                                                { value: 'all', label: 'All' },
                                                { value: 'not_shared', label: 'Not Shared' },
                                                { value: 'shared', label: 'Shared' },
                                            ]}
                                        />
                                    </Card>
                                </Col> */}
                                <Col xs={24} lg={24} md={24} className="ColCardPadding">
                                    <Card style={{ width: '100%' }}>
                                            <Input placeholder="Search by name" onChange={handleSearch} bordered={false} style={{ height: '32px' }} />
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </FilterContainer>
                    <StableWrapper style={{ marginTop: 20 }}>
                        {/* <Row style={{ paddingBottom: 12 }}>
                        <Col span={4}>
                            <Button variant="contained" color="default" onClick={handleAddSchools} style={{ textTransform: 'inherit' }}>
                                <Add style={{ fontSize: "16px", marginRight: "4px" }} />
                                Add
                            </Button>
                        </Col>
                        <Col offset={16} span={4}>
                            <TextField id="search" name="search" label="Search" type="text" variant="filled" onKeyUp={handleSearch} size="small" style={{ backgroundColor: "rgba(255, 255, 255, 1)" }} />
                        </Col>
                    </Row> */}
                        <Table stickyHeader aria-label="sticky table" style={{ width: "100%", textAlign: "center", backgroundColor: "#fff" }}>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell style={{ textAlign: "center" }}></TableCell> */}
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Name</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses used</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Licenses available</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Teachers</TableCell>
                                    <TableCell style={{ textAlign: "left", fontSize: 16, fontWeight: '600' }}>Students</TableCell>
                                    {/* <TableCell style={{ textAlign: "center" }}>Edit</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isEmpty(schools) && (
                                    userloading ? <Loading size={30} /> : (
                                        <TableRow key={'0'} style={{ border: 0 }}>
                                            <TableCell colSpan={6} style={{ textAlign: "center" }}>No results</TableCell>
                                        </TableRow>
                                    )
                                )}
                                {(!isEmpty(filteredSchools)) && (
                                    filteredSchools.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <>
                                            <TableRow key={index + "schools"} style={{ '&:lastChild td, &:lastChild th': { border: 0 }, cursor: 'pointer' }} onClick={() => {
                                                setModal2Open(!modal2Open)
                                                setData(row)
                                            }}>
                                                {/* <TableCell style={{ textAlign: "center" }} onClick={() => { setShowSchoolsTeachers(showSchoolsTeachers == index ? -1 : index) }}>
                                                <Rotate open={showSchoolsTeachers == index}>
                                                    <ArrowRight />
                                                </Rotate>
                                            </TableCell> */}
                                                <TableCell style={{ textAlign: "left" }}>{`${row.displayName} ${row.demo ? '(Demo)' : ''}`}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[0] }}>{row.licenses || 0}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[1] }}>{(row.maxCount != "undefined" ? row.maxCount : 0)}</TableCell>
                                                <TableCell style={{ textAlign: "left", color: BORDERCOLOR[2] }}>{substract(row.licenses, (row.maxCount != "undefined" ? row.maxCount : 0))}</TableCell>
                                                <TableCell style={{ textAlign: "left" }}>{get(row, 'teacherCount','0')}</TableCell>
                                                <TableCell style={{ textAlign: "left" }}>{get(row, 'studentCount', '0')}</TableCell>
                                                {/* <TableCell style={{ textAlign: "center", color: BORDERCOLOR[3] }}>{row.demo ? "true" : "false"}</TableCell> */}
                                                {/* <TableCell style={{ textAlign: "center" }}>
                                                <Button onClick={() => { handleEditSchools(row) }} style={{ marginRight: '8px' }} >
                                                    <Edit></Edit>
                                                </Button>
                                            </TableCell> */}
                                            </TableRow>
                                            <TableRow style={{ display: (showSchoolsTeachers == index ? '' : 'none') }} height={showSchoolsTeachers == index && teacherByOrg[row.id]?.length > 0 ? ((teacherByOrg[row.id]?.length * 39) + 8) : 0}>
                                                <TableCell colSpan={6}>
                                                    <Table style={{ width: "100%" }}>
                                                        {teacherByOrg[row.id]?.map((t) => (
                                                            <TableRow key={t.id} style={{ border: 0, width: "100%" }}>
                                                                <TableCell style={{ textAlign: "center" }}>{`${get(t, "firstName", "")} ${get(t, "lastName", "")}`}</TableCell>
                                                                <TableCell style={{ textAlign: "center" }}>
                                                                    <Button onClick={() => { goToClass(t.id, t); }} disabled={!t.active} style={{ cursor: t.active ? "pointer" : "not-allowed", color: t.active ? "black" : "red" }}>
                                                                        {loginLoader == t.id && <Loading size={14} />}  Log in to account
                                                                        <ArrowForward style={{ fontSize: "11px", marginLeft: "8px" }} />
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    {!isEmpty(filteredSchools) && <TablePagination
                                        count={filteredSchools.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                    />}
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </StableWrapper>
                </StableWrapperMain>
            )}
            <Modal
                footer={null}
                title={`${data.displayName} ${data.demo ? '(Demo)' : ''}`}
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={'60%'}
                className="FilterHeading"
            >
                <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title="School Info"
                >
                    <Row>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>TOTAL LICENSES</OverViewLabel>
                                <OverViewValue>{data.licenses || 0}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>TOTAL LICENSES USED</OverViewLabel>
                                <OverViewValue>{(data.maxCount != "undefined" ? data.maxCount : 0)}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>TOTAL LICENSES AVAILABLE</OverViewLabel>
                                <OverViewValue>{substract(data.licenses, (data.maxCount != "undefined" ? data.maxCount : 0))}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                        <Col xs={24} lg={12}>
                            <OverViewContainer>
                                <OverViewLabel>TOTAL STUDENTS</OverViewLabel>
                                <OverViewValue>{get(data, 'studentCount', '0')}</OverViewValue>
                            </OverViewContainer>
                            <OverViewContainer>
                                <OverViewLabel>TOTAL TEACHERS</OverViewLabel>
                                <OverViewValue>{get(data, 'teacherCount', '0')}</OverViewValue>
                            </OverViewContainer>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
};


//  -- Prop types ---------------------
SchoolsTable.propTypes = {};

//  -- Default props ------------------
SchoolsTable.defaultProps = {};

export default SchoolsTable;
