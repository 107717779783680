import { MenuItem, Select, makeStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Button, Checkbox, Col, Dropdown, Input, Menu, Row, Switch } from "antd";
import Loading from "components/Loading";
import { get, map, find } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useCanvasAssignmentCategory } from 'store/canvas/selectors';
import { useCurrentClass } from "store/classes/selectors";
import { handleDebateAI } from "store/debate/thunks";
import { useSchoologyGradeCategory } from 'store/schoologyauth/selectors';
import { useCurrentUser } from "store/users/selectors";
import styled from "styled-components";
import { debounce } from 'underscore';
import { DebateNewLogo, IdeaAi } from "utils/assets/images";
import { GRADE_TYPE_ALL, GRADE_TYPE_CANVAS } from 'utils/config/reference';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import Acent from "components/Acent";
const LoaderWrapper = styled.div`
  // position:absolute;
  z-index: 100;
`;


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-select': {
      backgroundColor: 'transparent', // Set background color to transparent
    },
  },
}));

const GradeConfig = ({ options, value, label, onChange, hasInput, objValue = {} }) => {
  const label_txt = useMemo(() => {
    const f_value = value ? find(options, k => k.value == value || k.id == value) : null;
    if (f_value) {
      return `${label}(${f_value.title || f_value.type})`
    }
    return label;
  }, [value, options, label])
  const handleMenuClick = (e) => {
    const f_value = find(options, k => k.value == e.key || k.id == e.key)
    if (f_value) {
      if (f_value.value === "points") {
        onChange({ ...f_value, max_point: 100 })
      } else {
        onChange(f_value)
      }

    }
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      {map(options, pt => <Menu.Item key={pt.id || pt.value}>
        {pt.title || pt.type}
      </Menu.Item>)}
    </Menu>
  );


  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 100) {
      onChange({ ...objValue, max_point: value });
    }
  };
  return (
    <Row style={{ marginBottom: 8 }}>
      <Col lg={12} xs={24} md={12} style={{ display: "flex", height: 30 }}>
        <Dropdown overlay={menu}>
          <div className='linksSectionIcon text-dark' style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            {value ? (
              <span style={{ height: 20, marginRight: 8 }}>
                <CheckCircleOutlineIcon style={{ color: 'green' }} />
              </span>
            ) : (
              <span style={{ height: 20, marginRight: 8 }}>
                <AddCircleSharpIcon />
              </span>
            )}
            {label_txt}
          </div>
        </Dropdown>
        {hasInput && value === "points" && <Input
          placeholder="max points"
          defaultValue={"100"}
          inputMode="numeric"
          style={{ borderRadius: 20, width: 100, marginLeft: 8 }}
          type="numeric"
          max={100}
          min={0}
          onChange={handleInputChange} />}
      </Col>
    </Row>
  );
};

const DebateContentType = ({
  shareLoader,
  handleClickHideSec = () => null,
  setOpenTeacherModal,
  onAiError = () => null
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [aiInputValue, setAiInputValue] = useState("");
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isAiBox, setIsAiBox] = useState(false);
  const [isAiResult, setIsAiResult] = useState(false);
  const [configurations, setConfigurations] = useState({});
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [aiLoader, setAiLoader] = useState(false);
  const [timeInput, setTimeInput] = useState("");

  const [isAiSelect, setIsAiSelect] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [wordCountAi, setWordCountAi] = useState(0);
  const [handleSubmitAiResult, setHandleAiResult] = useState([]);
  const currentUser = useCurrentUser()
  const currentClass = useCurrentClass()

  const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
  const isCanvasUser = !!(currentUser && currentUser.canvas_id);

  const [game_grade_category, setGameGradeCategory] = useState({});
  const [game_grade_type, setGameGradeType] = useState({});
  const [game_ungraded, setGameUngraded] = useState(false);

  const [, , schoologyAllClassGradeCategory] = useSchoologyGradeCategory()
  const canvasAssignmentCategory = useCanvasAssignmentCategory()

  const grade_type = useMemo(() => {
    if (currentClass && currentClass.canvas_id) {
      return GRADE_TYPE_CANVAS;
    } else {
      return GRADE_TYPE_ALL;
    }
  }, [currentClass]);

  const grade_category = useMemo(() => {
    if (currentClass.canvas_id) {
      const grade_cat = canvasAssignmentCategory[currentClass.canvas_id];
      if (Array.isArray(grade_cat)) {
        return grade_cat.map(k => ({
          id: k.id,
          title: k.name
        }))
      }
      return []
    } else if (currentClass.sectionId) {
      const grade_cat = schoologyAllClassGradeCategory[currentClass.sectionId];
      if (Array.isArray(grade_cat)) {
        return grade_cat.map(k => ({
          id: k.id,
          title: k.title,
          grade_type: k.calculation_type == 2 ? 'points' : 'percent'
        }))
      }
      return []
    }
    return [];
  }, [currentClass, schoologyAllClassGradeCategory, canvasAssignmentCategory])
  // const inpRef = useRef();

  const handleVisibleTxt = () => {
    setIsAiBox(true);
    setIsAiResult(false);
    setIsAiSelect("");
    setInputValue("");
  };

  const isValidFormat = (data) => {
    return Array.isArray(data) && data.every(item => item.hasOwnProperty('question'));
  };

  const handleVisiableAiBox = debounce(async () => {
    if (aiInputValue) {
      setAiLoader(true);
      const result = await handleDebateAI({
        topic: aiInputValue,
      });
      if (isValidFormat(result)) {
        setHandleAiResult(result);
        setIsAiResult(!isAiResult);
        setIsAiBox(false);
      } else {
        onAiError();
    }
      setAiLoader(false);
    }
  }, 1000);



  const handleInputFields = (key, value) => {
    let debateInfo = configurations ?? {};
    debateInfo[key] = value;
    setConfigurations(debateInfo);
  };


  const handleWordLimit = (text) => {
    const maxLength = 200;
    const trimmedValue = text.slice(0, maxLength);
    setInputValue(trimmedValue);
    setWordCount(trimmedValue.length);
  };

  const handleWordLimitAI = (text) => {
    const maxLength = 200;
    const trimmedValue = text.slice(0, maxLength);
    setAiInputValue(trimmedValue);
    setWordCountAi(trimmedValue.length);
  };

  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);


  return (
    <div className="ideaMdlUpr">
      <Row>
        <Col xs={24}>
          <div className="mainDbteValeSec">
            <div className="debateMdlTop">
              <img src={DebateNewLogo} alt="Debate" />
            </div>
            <div
              className="debateValeSection"
              onClick={() => handleVisibleTxt()}
            >
              <span>{`${isAiResult ? "Ask Vale again" : "Pregúntale a Vale"
                }`}</span>
              <img src={IdeaAi} alt="Idea" />
            </div>
          </div>
        </Col>

        {!isAiBox ? (
          !isAiResult ? (
            <Col xs={24}>
              <div className="ideaMdlContent">
                <div className="debateMdlLbl">
                  <Acent inputRef={textareaRef1} setValue={handleWordLimit} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} isDisabled={wordCount === 200} />
                  <textarea
                    ref={textareaRef1}
                    placeholder="Type question and click share to show in students screens or ask Vale to create it for you."
                    value={inputValue}
                    onChange={(e) => {
                      handleWordLimit(e.target?.value);
                    }}
                  />
                  <div className="wordCountDebate" style={{ position: 'absolute', top: 30, right: 10 }}>
                    <span>{`${wordCount}/200`}</span>
                  </div>
                </div>
              </div>
              <div className="midLiveDebateSec">
                <span>How many minutes should this debate last?</span>
                <div className="blogInputBox">
                  {/* <TimePicker
                    onChange={(e) => {
                      const times = getTimeFromDate(e?._d) ?? "";
                      if (times === "0:0:0" || times === "NaN:NaN:NaN") {
                        setTimeInput("");
                      } else {
                        handleInputFields("total_time", times);
                        setTimeInput(times);
                      }
                    }}
                  /> */}
                  <div style={{ border: '1px solid #d9d9d9', backgroundColor: 'white', minWidth: 130, borderRadius: 20 }}>

                    <Select
                      className={classes.root}
                      disableUnderline={true}
                      style={{ width: '100%' }}
                      required={true}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(v) => {
                        const times = v.target.value;
                        handleInputFields("total_time", times * 60);
                        setTimeInput(times);
                        setOpenTeacherModal(times)
                      }}
                      label="Type"
                    >
                      {[...Array(59)].map((_, index) => (
                        <MenuItem key={index + 1} value={index + 1}>{index + 1 + ' ' + 'min'}</MenuItem>
                      ))}
                    </Select>
                  </div>

                </div>
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <div className="ideaMdlContent debateMdlCont">
                <div className="allDebateCntnt">
                  <span>Crea una pregunta sobre:</span>
                </div>
                <div className="allDebteResultPart debateMdlLbl">
                  {map(handleSubmitAiResult, (item, index) => {
                    return (
                      <div className="debateResultSec">
                        <span style={{minWidth: '80px'}}>{get(item, "question", "")}</span>
                        <div
                          className={`blogInputBox ${isAiSelect === get(item, "question", "")
                              ? "blogInputBoxSelect"
                              : ""
                            }`}
                        >
                          <Button
                            onClick={() => {
                              setIsAiSelect(get(item, "question", ""));
                              setInputValue(get(item, "question", ""));
                            }}
                          >{`${isAiSelect === get(item, "question", "")
                              ? "Selected"
                              : "Select"
                            }`}</Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="midLiveDebateSec">
                <span>How many minutes should this debate last?</span>
                <div className="blogInputBox">
                  {/* <TimePicker
                    onChange={(e) => {
                      const times = getTimeFromDate(e?._d) ?? "";
                      if (times === "0:0:0" || times === "NaN:NaN:NaN") {
                        setTimeInput("");
                      } else {
                        handleInputFields("total_time", times);
                        setTimeInput(times);
                      }
                    }}
                  /> */}
                  <div style={{ border: '1px solid #d9d9d9', backgroundColor: 'white', minWidth: 100, borderRadius: 20 }}>
                    <Select
                      className={classes.root}
                      disableUnderline={true}
                      style={{ width: '100%' }}
                      required={true}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(v) => {
                        const times = v.target.value;
                        handleInputFields("total_time", times * 60);
                        setTimeInput(times);
                      }}
                      label="Type"
                    >
                      {[...Array(59)].map((_, index) => (
                        <MenuItem key={index + 1} value={index + 1}>{index + 1 + ' ' + 'min'}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </Col>
          )
        ) : (
          <Col xs={24}>
            <div className="ideaMdlContent debateMdlCont">
              <div className="allDebateCntnt">
                <span>Crea una pregunta sobre:</span>
              </div>
              <div className="debateMdlLbl">
                <div className="wordCountDebate" style={{ position: 'absolute', top: 4, right: 10 }}>
                  <span>{`${wordCountAi}/200`}</span>
                </div>
                <div style={{ position: 'absolute', right: 0, top: -30 }}>
                  <Acent inputRef={textareaRef2} setValue={handleWordLimitAI} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} isDisabled={wordCountAi === 200} />
                </div>
                <textarea
                  ref={textareaRef2}
                  placeholder="Type Here....."
                  value={aiInputValue}
                  onChange={(e) => {
                    handleWordLimitAI(e.target?.value);
                    setIsButtonVisible(true);
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      !e.shiftKey &&
                      aiInputValue.trim() !== ""
                    ) {
                      if (!aiLoader) {
                        e.preventDefault();
                        setIsButtonVisible(true);
                        handleVisiableAiBox();
                      }
                      else {
                        e.preventDefault();
                      }

                    }
                  }}
                />
                {isButtonVisible && (
                  <Button
                    disabled={aiLoader}
                    className="debateEnterButton liveDebateClickBtn"
                    onClick={() => {
                      if (aiInputValue.trim() !== "") {
                        handleVisiableAiBox();
                      }
                    }}
                  >
                    {aiLoader ? (
                      <LoaderWrapper>
                        <Loading size={18} />
                      </LoaderWrapper>
                    ) : null}
                    &#9166;
                  </Button>
                )}
              </div>
            </div>
          </Col>
        )}

        <Col xs={24}>
          <div className="debeteCountTypeSec debeteCountTypeSpace">
            <div className="debatCountTxt">
              <span>Count for a grade</span>
              <Switch
                defaultChecked={false}
                onChange={() => {
                  let s_value = !isTextVisible
                  setIsTextVisible(s_value);
                  if (!s_value) {
                    setGameGradeType({})
                    setGameGradeCategory({})
                    setGameUngraded(false)
                  }
                }}
              />
            </div>
            <div className="custoMshareBtn">
              <Button
                style={{ display: "flex", alignItems: "center" }}
                className="shareBtnSec"
                type="primary"
                disabled={inputValue.trim() === "" || timeInput === ""}
                onClick={() => {
                  handleClickHideSec(configurations, inputValue.trim(), isTextVisible, {
                    grade_category: game_grade_category,
                    grade_type: game_grade_type,
                    ungraded: game_ungraded
                  });
                }}
              >
                {shareLoader ? (
                  <LoaderWrapper>
                    <Loading size={18} white />
                  </LoaderWrapper>
                ) : null}
                Share
              </Button>
            </div>
          </div>
        </Col>
        {isTextVisible && (
          <>
            <div className='aiGmMdlInr' style={{ width: "100%" }}>
              {!isSchoologyUser && <GradeConfig label={"Choose grading system"} value={game_grade_type?.value} options={grade_type} onChange={setGameGradeType} hasInput objValue={game_grade_type} />}
              {isCanvasUser || isSchoologyUser ? <GradeConfig label={" Choose category"} value={game_grade_category?.id} options={grade_category} onChange={setGameGradeCategory} objValue={game_grade_category} /> : null}
              {!isSchoologyUser && !isCanvasUser ? <div className='linksSectionIcon text-dark' style={{ paddingLeft: 4 }} >
                <Checkbox checked={game_ungraded} className="custom-checkbox" style={{ fontSize: 16, fontWeight: "600" }} onChange={() => setGameUngraded(!game_ungraded)}>
                  {"Ungraded"}
                </Checkbox>
              </div> : null}
            </div>
          </>
        )}
      </Row>
    </div>
  );
};

export default DebateContentType;
