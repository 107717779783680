const dev = {
    apiGateway: {
        REGION: "us-east-1",
        ACTIVITIES: "https://xwflihso0b.execute-api.us-east-1.amazonaws.com/prod",
        USERBOOKS: "https://tq4kk4wak6.execute-api.us-east-1.amazonaws.com/prod",
        BOOKS: "https://6e5rf1b45m.execute-api.us-east-1.amazonaws.com/prod",
        ASSIGNMENT_SUBMISSIONS: "https://k28o8lmekk.execute-api.us-east-1.amazonaws.com/prod",
        ASSIGNMENT_SUBMISSIONS_GREADES: "https://415063appd.execute-api.us-east-1.amazonaws.com/prod",
        CLASS_ACTIVITIES: "https://rmr3ot2f0h.execute-api.us-east-1.amazonaws.com/prod",
        CLASSES: "https://1s108eon24.execute-api.us-east-1.amazonaws.com/prod",
        CLASS_QUIZZES: "https://652mk7ppyd.execute-api.us-east-1.amazonaws.com/prod",
        DIFFERENTIATION: "https://d9tew38hs8.execute-api.us-east-1.amazonaws.com/prod",
        INTERACTIONS: "https://dlwws9igu4.execute-api.us-east-1.amazonaws.com/prod",
        LIKES: "https://ea4tsd5s20.execute-api.us-east-1.amazonaws.com/prod",
        NOTES: "https://0ek5279n84.execute-api.us-east-1.amazonaws.com/prod",
        ORGANIZATIONS: "https://qx0k3i3756.execute-api.us-east-1.amazonaws.com/prod",
        POSTS: "https://mxoslo6oqk.execute-api.us-east-1.amazonaws.com/prod",
        QUESTIONS: "https://ohcmwbsrz3.execute-api.us-east-1.amazonaws.com/prod",
        QUIZZES: "https://r8cndkgz4l.execute-api.us-east-1.amazonaws.com/prod",
        USER_CLASSES: "https://d84djkt126.execute-api.us-east-1.amazonaws.com/prod",
        USERS: "https://qdsqev5fzl.execute-api.us-east-1.amazonaws.com/prod",
        TIPS: "https://kw9ps9so2k.execute-api.us-east-1.amazonaws.com/prod",
        USERS_PREFERENCE: "https://vmp5cbuzoh.execute-api.us-east-1.amazonaws.com/prod",
        ACTIVITYRDS: "https://0eu3a341f8.execute-api.us-east-1.amazonaws.com/prod",
        BOOKSRDS: "https://dzhm8zytul.execute-api.us-east-1.amazonaws.com/prod",
        CLASSACTIVITYRDS: " https://7fs6aklj1g.execute-api.us-east-1.amazonaws.com/prod",
        CLASSRDS: "https://pxqs2zgqv9.execute-api.us-east-1.amazonaws.com/prod",
        CLASSQUIZZESRDS: "https://jr8z321598.execute-api.us-east-1.amazonaws.com/prod",
        DIFFERENTIATIONRDS: "https://fwthwplzbg.execute-api.us-east-1.amazonaws.com/prod",
        DISTRICTSRDS: "https://bfcfnvyhw8.execute-api.us-east-1.amazonaws.com/prod",
        INTERACTIONRDS: "https://4hj8yp5udg.execute-api.us-east-1.amazonaws.com/prod",
        LIKESRDS: "https://mpr0i6sncb.execute-api.us-east-1.amazonaws.com/prod",
        USERRDS: "https://dmdi98kjvl.execute-api.us-east-1.amazonaws.com/prod",
        NOTESRDS: "https://mqkxxvc4w5.execute-api.us-east-1.amazonaws.com/prod",
        ORGANIZATIONRDS: "https://nivc2qm4rd.execute-api.us-east-1.amazonaws.com/prod",
        POSTSRDS: "https://dn5zvr3ivl.execute-api.us-east-1.amazonaws.com/prod",
        QUESTIONRDS: "https://bujbrm8p9e.execute-api.us-east-1.amazonaws.com/prod",
        QUIZZERDS: "https://a2wx0mki2i.execute-api.us-east-1.amazonaws.com/prod",
        SCHOOLSRDS: "https://mqt7hmdi5e.execute-api.us-east-1.amazonaws.com/prod",
        TIPSRDS: "https://jpc2824soi.execute-api.us-east-1.amazonaws.com/prod",
        USERBOOKSRDS: "https://cmbv0r2mbf.execute-api.us-east-1.amazonaws.com/prod",
        USERCLASSESRDS: "https://dppekc2oob.execute-api.us-east-1.amazonaws.com/prod",
        USERS_PREFERENCE_RDS: "https://8wikn1c0id.execute-api.us-east-1.amazonaws.com/prod",
        SUBMISSIONSRDS: " https://4qrre6rmgb.execute-api.us-east-1.amazonaws.com/prod",
        SCHOOLOGY: "https://du0lnkn7dk.execute-api.us-east-1.amazonaws.com/prod/",
        SCHOOLOGY_RDS: "https://72ol2r1qrk.execute-api.us-east-1.amazonaws.com/prod/",
        FEEDBACK_RDS: "https://7dn2ai43g4.execute-api.us-east-1.amazonaws.com/prod",
        LOGSRDS: "https://362oyibeh3.execute-api.us-east-1.amazonaws.com/prod",
        CLASSLINKRDS: "https://rpycuyt503.execute-api.us-east-1.amazonaws.com/prod",
        EMAILSERVICE: "https://oviiyvkm5b.execute-api.us-east-1.amazonaws.com/prod",
        PASTSUBMISSIONS: "https://t9tf5foq20.execute-api.us-east-1.amazonaws.com/prod",
        BOOKUNITS: 'https://1bxrhez287.execute-api.us-east-1.amazonaws.com/prod',
        ORGPERMISSIONS: "https://j9prza1h51.execute-api.us-east-1.amazonaws.com/prod",
        RESOURCES: "https://sab3d08hdg.execute-api.us-east-1.amazonaws.com/prod",
        KEYSTORE: 'https://yd96yl1ql2.execute-api.us-east-1.amazonaws.com/prod',
        CLEVER: "https://66d9z2ivwh.execute-api.us-east-1.amazonaws.com/prod",
        BOOKMARKS: "https://r17tzfianf.execute-api.us-east-1.amazonaws.com/prod",
        WS: "wss://85kwykdvs8.execute-api.us-east-1.amazonaws.com/production",
        CHAT: "https://gnsemo1860.execute-api.us-east-1.amazonaws.com/prod",
        EXAMS: ' https://sjmjhfmp6f.execute-api.us-east-1.amazonaws.com/prod',
        INSTRUCTIONS: "https://putl56wh0e.execute-api.us-east-1.amazonaws.com/prod",
        ASSIGNMENT: "https://52uniq9rf9.execute-api.us-east-1.amazonaws.com/prod",
        GOOGLE_CLASSROOM: "https://jfdi3cp0l2.execute-api.us-east-1.amazonaws.com/prod",
        CANVAS: "https://iu6enj9fdj.execute-api.us-east-1.amazonaws.com/prod",
        NOTIFICATION: 'https://zv7ywpnl29.execute-api.us-east-1.amazonaws.com/prod',
        STORY: "https://2tlsu10aa8.execute-api.us-east-1.amazonaws.com/prod",
        APEXAMS: "https://m8okhqrlvi.execute-api.us-east-1.amazonaws.com/prod",
        HOWTOVIDEOS: 'https://6nw22y03f1.execute-api.us-east-1.amazonaws.com/prod',
        LICENCES: 'https://rz8bfloylj.execute-api.us-east-1.amazonaws.com/prod',
        VOCABULARY: "https://8c9l6d0v77.execute-api.us-east-1.amazonaws.com/prod",
        ONEWAYTEACHER_VIDEO: 'https://bdzplnsltd.execute-api.us-east-1.amazonaws.com/prod',
        ONE_GAME_VIDEO: 'https://9xwncd38ce.execute-api.us-east-1.amazonaws.com/prod',
        ONE_WAY_DEBATE: 'https://pcad60b6vb.execute-api.us-east-1.amazonaws.com/prod',
        ONEGAME:'https://c7avbux13g.execute-api.us-east-1.amazonaws.com/prod',
        FIREBASE: 'https://osegmheyk6.execute-api.us-east-1.amazonaws.com/prod',
        TEXT_SPEECH:'https://osegmheyk6.execute-api.us-east-1.amazonaws.com/prod',
        CHECKLIST: 'https://3fru772fq9.execute-api.us-east-1.amazonaws.com/prod',
        WIDGETS: 'https://a55qv7thj9.execute-api.us-east-1.amazonaws.com/prod',
        SUPER_LESSON: 'https://baxtb4530l.execute-api.us-east-1.amazonaws.com/prod',
        FAVOURITE_VIDEO: 'https://k3wiy1dfqh.execute-api.us-east-1.amazonaws.com/prod',
        EBOOK_HIGHLIGHT:'https://jtw1bqjuze.execute-api.us-east-1.amazonaws.com/prod',
        DIFFERENTIATION_LESSON: 'https://ribnuw4wtf.execute-api.us-east-1.amazonaws.com/prod',

    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    isDev: true
}

const prod = {
    apiGateway: {
        REGION: "us-east-1",
        ACTIVITIES: "https://wxod2a04c3.execute-api.us-east-1.amazonaws.com/prod",
        USERBOOKS: "https://b5r5u6s4fc.execute-api.us-east-1.amazonaws.com/prod",
        BOOKS: "https://3h0t8m64sa.execute-api.us-east-1.amazonaws.com/prod",
        ASSIGNMENT_SUBMISSIONS:
            "https://109lee5fs0.execute-api.us-east-1.amazonaws.com/prod",
        ASSIGNMENT_SUBMISSIONS_GREADES:
            "https://ejcffbjicl.execute-api.us-east-1.amazonaws.com/prod",
        CLASS_ACTIVITIES:
            "https://82vxdqfj9j.execute-api.us-east-1.amazonaws.com/prod",
        CLASSES: "https://mi6yywfsq6.execute-api.us-east-1.amazonaws.com/prod",
        CLASS_QUIZZES:
            "https://imgy5pqjk7.execute-api.us-east-1.amazonaws.com/prod",
        DIFFERENTIATION:
            "https://7kvph5het6.execute-api.us-east-1.amazonaws.com/prod",
        INTERACTIONS: "https://srrp3bpm9i.execute-api.us-east-1.amazonaws.com/prod",
        LIKES: "https://d4r00gie6j.execute-api.us-east-1.amazonaws.com/prod",
        NOTES: "https://5zq89ux0ti.execute-api.us-east-1.amazonaws.com/prod",
        ORGANIZATIONS:
            "https://ib6bmedbyi.execute-api.us-east-1.amazonaws.com/prod",
        POSTS: "https://a2esh0ziug.execute-api.us-east-1.amazonaws.com/prod",
        QUESTIONS: "https://7qok6v2wlg.execute-api.us-east-1.amazonaws.com/prod",
        QUIZZES: "https://um79schtrl.execute-api.us-east-1.amazonaws.com/prod",
        USER_CLASSES: "https://uaiw1r1xfk.execute-api.us-east-1.amazonaws.com/prod",
        USERS: "https://n272qceg39.execute-api.us-east-1.amazonaws.com/prod",
        TIPS: "https://j58s6ecsra.execute-api.us-east-1.amazonaws.com/prod",
        USERS_PREFERENCE:
            "https://y4pvz8mq5m.execute-api.us-east-1.amazonaws.com/prod",
        ACTIVITYRDS: "https://db2ixkiraa.execute-api.us-east-1.amazonaws.com/prod",
        BOOKSRDS: "https://4ronru96v8.execute-api.us-east-1.amazonaws.com/prod",
        CLASSACTIVITYRDS:
            "https://sc8dd6jjaj.execute-api.us-east-1.amazonaws.com/prod",
        CLASSRDS: "https://vqnepb7wr7.execute-api.us-east-1.amazonaws.com/prod",
        CLASSQUIZZESRDS:
            "https://ib1826viz2.execute-api.us-east-1.amazonaws.com/prod",
        DIFFERENTIATIONRDS:
            "https://6fgegycr1d.execute-api.us-east-1.amazonaws.com/prod",
        DISTRICTSRDS: "https://0m3vndgcei.execute-api.us-east-1.amazonaws.com/prod",
        INTERACTIONRDS:
            "https://7pnjeeqth7.execute-api.us-east-1.amazonaws.com/prod",
        LIKESRDS: "https://6ukj6abgh1.execute-api.us-east-1.amazonaws.com/prod",
        USERRDS: "https://8mddprsm71.execute-api.us-east-1.amazonaws.com/prod",
        NOTESRDS: "https://p029sqb8e0.execute-api.us-east-1.amazonaws.com/prod",
        ORGANIZATIONRDS:
            "https://x7mop4lafd.execute-api.us-east-1.amazonaws.com/prod",
        POSTSRDS: "https://ystgkbz2i8.execute-api.us-east-1.amazonaws.com/prod",
        QUESTIONRDS: "https://j0jvs0fv93.execute-api.us-east-1.amazonaws.com/prod",
        QUIZZERDS: "https://19ijjuhpzl.execute-api.us-east-1.amazonaws.com/prod",
        SCHOOLSRDS: "https://a0pz0mbezc.execute-api.us-east-1.amazonaws.com/prod",
        TIPSRDS: "https://on1j2zk7x5.execute-api.us-east-1.amazonaws.com/prod",
        USERBOOKSRDS: "https://44vg15cspa.execute-api.us-east-1.amazonaws.com/prod",
        USERCLASSESRDS:
            "https://7l2v116635.execute-api.us-east-1.amazonaws.com/prod",
        USERS_PREFERENCE_RDS:
            "https://ervml5stsa.execute-api.us-east-1.amazonaws.com/prod",
        SUBMISSIONSRDS:
            "https://p853s7iafl.execute-api.us-east-1.amazonaws.com/prod",
        SCHOOLOGY: " https://du0lnkn7dk.execute-api.us-east-1.amazonaws.com/prod/",
        SCHOOLOGY_RDS:
            "https://5eku0o30bg.execute-api.us-east-1.amazonaws.com/prod/",
        FEEDBACK_RDS: "https://pvnprpfd04.execute-api.us-east-1.amazonaws.com/prod",
        LOGSRDS: "https://uonrbv5b82.execute-api.us-east-1.amazonaws.com/prod",
        CLASSLINKRDS: "https://wo0xw3f08c.execute-api.us-east-1.amazonaws.com/prod",
        EMAILSERVICE: "https://nthjhxy573.execute-api.us-east-1.amazonaws.com/prod",
        PASTSUBMISSIONS:
            "https://4r5dwq2me5.execute-api.us-east-1.amazonaws.com/prod",
        BOOKUNITS: "https://10gpx17eu8.execute-api.us-east-1.amazonaws.com/prod",
        ORGPERMISSIONS:
            "https://ztpdf2up6g.execute-api.us-east-1.amazonaws.com/prod",
        RESOURCES: "https://2kgesw4fne.execute-api.us-east-1.amazonaws.com/prod",
        KEYSTORE: "https://5sethuxvth.execute-api.us-east-1.amazonaws.com/prod",
        CLEVER: "https://kme2vx5k15.execute-api.us-east-1.amazonaws.com/prod",
        BOOKMARKS: "https://uz03uzqcyh.execute-api.us-east-1.amazonaws.com/prod",
        WS: "wss://5kata7w641.execute-api.us-east-1.amazonaws.com/production",
        CHAT: "https://acl3xu9sma.execute-api.us-east-1.amazonaws.com/prod",
        EXAMS: "https://uthskp60l0.execute-api.us-east-1.amazonaws.com/prod",
        INSTRUCTIONS: "https://89q6ak1me2.execute-api.us-east-1.amazonaws.com/prod",
        ASSIGNMENT: "https://6nk8kuviv3.execute-api.us-east-1.amazonaws.com/prod",
        GOOGLE_CLASSROOM:
            "https://v8vpelhx80.execute-api.us-east-1.amazonaws.com/prod",
        CANVAS: "https://i6ttug2cuh.execute-api.us-east-1.amazonaws.com/prod",
        STORY: "https://0xkemhub36.execute-api.us-east-1.amazonaws.com/prod",
        APEXAMS: "https://v7trahe1zc.execute-api.us-east-1.amazonaws.com/prod",
        HOWTOVIDEOS: "https://nvtr1h6xw5.execute-api.us-east-1.amazonaws.com/prod",
        LICENCES: "https://q3ahl30901.execute-api.us-east-1.amazonaws.com/prod",
        NOTIFICATION: "https://y3y3p46j00.execute-api.us-east-1.amazonaws.com/prod",
        VOCABULARY: "https://49y4ca8ukg.execute-api.us-east-1.amazonaws.com/prod",
        ONEWAYTEACHER_VIDEO: 'https://wodn3djtza.execute-api.us-east-1.amazonaws.com/prod',
        ONE_GAME_VIDEO: 'https://2lc8bq6mz4.execute-api.us-east-1.amazonaws.com/prod',
        ONEGAME:'https://mxbosn0tw8.execute-api.us-east-1.amazonaws.com/prod',
        ONE_WAY_DEBATE: 'https://ftilcxxzdi.execute-api.us-east-1.amazonaws.com/prod',
        FIREBASE: 'https://ln7vzulfm8.execute-api.us-east-1.amazonaws.com/prod',
        TEXT_SPEECH:'https://osegmheyk6.execute-api.us-east-1.amazonaws.com/prod',
        CHECKLIST: '',
        WIDGETS: 'https://5ntl0inx9i.execute-api.us-east-1.amazonaws.com/prod',
        SUPER_LESSON: 'https://989g109njg.execute-api.us-east-1.amazonaws.com/prod',
        FAVOURITE_VIDEO: 'https://6orlbu8bt0.execute-api.us-east-1.amazonaws.com/prod',
        EBOOK_HIGHLIGHT:'https://2hfcat7asc.execute-api.us-east-1.amazonaws.com/prod',
        DIFFERENTIATION_LESSON: 'https://37v0v4uhc4.execute-api.us-east-1.amazonaws.com/prod',
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_PRD_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_PRD_APP_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_PRD_IDENTITY_POOL_ID,
    },
    isDev: false,
};


const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Common config values
    REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
    REACT_APP_PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
    REACT_APP_APP_ID: process.env.REACT_APP_APP_ID,
    REACT_APP_USER_ACCOUNT: process.env.REACT_APP_USER_ACCOUNT,
    REACT_APP_USER_PASSWORD: process.env.REACT_APP_USER_PASSWORD,
    OPENAI_KEY: process.env.REACT_APP_OPENAI_KEY,
    G_CLIENT_ID:"427835405630-c85sahn2l7a40s2muj1pjku0b1ujbtmk.apps.googleusercontent.com",
    schoology: {
        consumerKey: process.env.REACT_APP_consumerKey,
        secrateKey: process.env.REACT_APP_secrateKey
    },
    logging: {
        schoologySuccess: true,
        schoologyFailed: true,
    },
    ...config
};


