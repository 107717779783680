
import config from '../config';
var socket;
export const SocketIo = (userId) => {
   
    if (!socket||socket.readyState==3) {
        console.log("socket called for new connection")
        socket = new WebSocket(config.apiGateway.WS+"?userId="+userId)
        return socket;
    } else {
        console.log(socket.readyState,"check socket")
        return socket;
    }
}
export const clearScoket = ()=>{
    console.log("socket calling clear")
    if(socket){
        socket.close(1000,"logout_close")
    }
    socket = null
}