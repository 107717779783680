// src/hooks/useUserWidgetsConfig.js
import { useFirebase } from "containers/FirebaseContext";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const useUserWidgetsConfig = () => {
  const { firestore } = useFirebase();

  const createUserWidgetsConfig = async (WidgetsData, classId) => {
    try {
      const widgetId = WidgetsData?.id;

      const classDocRef = doc(firestore, "widgets_user_config", classId);
      const classDocSnap = await getDoc(classDocRef);

      if (!classDocSnap.exists()) {
        await setDoc(classDocRef, { classId });
      }
      const widgetDocRef = doc(collection(firestore, `widgets_user_config/${classId}/widgets`), widgetId);
      const widgetDocSnap = await getDoc(widgetDocRef);
      if (widgetDocSnap.exists()) {
        await updateDoc(widgetDocRef, WidgetsData);
      } else {
        await setDoc(widgetDocRef, WidgetsData);
      }

      return;
    } catch (error) {
      console.log(error, "error");
    }
  };

  return { createUserWidgetsConfig };
};
