import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useSentenceView } from 'store/main_book_popup/selectors';
import styled from "styled-components";
import COLOR from 'utils/constants/color';

const Wrapper = styled.div`
    position: absolute;
    top: -36px;
    width: ${p => p.width}px;
    height: ${p => p.height}px;
    background-color: rgba(0, 0, 0,0.3);
    box-shadow: 7px -3px 26px 20px rgb(66 66 66), -4px -3px 26px 15px rgb(95 109 110);
`;
const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    object-fit: fill;
    height:97%;
    width: 100%;
    @media print {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
`;
export default function SentenceStarterPopup({ getRef }) {
    const { sentenceView, revokeSentenceView } = useSentenceView()
    const [pages, setPages] = useState("")
    const containerRef = getRef()
    const [style, setStyle] = useState({ height: 0, width: 0 })
    const backBtnRef = useRef();
    const forwardBtnRef = useRef();

    useEffect(() => {
        if (sentenceView.open) {
            setPages(sentenceView.pages?.url)
        } else {
            setPages("")
        }
    }, [sentenceView])

    useEffect(() => {
        function updateStyle() {
            if (containerRef) {
                const { width, height } = containerRef.getBoundingClientRect()
                setStyle({ height, width })
            }
        }

        updateStyle();
        window.addEventListener('resize', updateStyle);

        return () => {
            window.removeEventListener('resize', updateStyle);
        }
    }, [sentenceView])
    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener("keydown", () => { })
        }
    }, [])
    const handleClose = () => {
        revokeSentenceView();
    };
    function checkKey(e) {
        let isMainBook = document.getElementsByClassName("main-book-popup").length > 0;
        if (isMainBook) {
            e = e || window.event;
            if (e.keyCode == '37') {
                if (backBtnRef.current && !backBtnRef.current.disabled) {
                    backBtnRef.current.click()
                }

            }
            else if (e.keyCode == '39') {
                if (forwardBtnRef.current && !forwardBtnRef.current.disabled) {
                    forwardBtnRef.current.click()
                }

            }
        }
    }
    const getPgSrc = () => {
        if (pages) {
            return pages
        }
    }

    if (!sentenceView.open) return null;
    return (
        <Wrapper width={style.width} height={style.height + 36} className={sentenceView.open ? 'main-book-popup' : ''}>
            <CloseOutlined onClick={handleClose} style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                cursor: 'pointer',
                fontSize: '16px',
                color: 'white',
                background: 'red',
                padding: '5px',
                borderRadius: '50%'
            }} />
            <Images src={getPgSrc()} />
        </Wrapper>
    )
}
