//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';
import _ from "lodash"
//  -- Actions ------------------------
import * as LicencesActions from './actions';
import * as Sentry from "@sentry/react";
//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------
//  -- Create Licences --------------------
export const handleCreateLicences = (data) => async(dispatch) => {
    await dispatch(LicencesActions.createLicences());
    try {
        const licencesData = await API.post('licencesRDS', `/addLicence`, {
            body: { ...data }
        });
        await dispatch(LicencesActions.createLicencesSuccess(licencesData));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(LicencesActions.createLicencesFail(error));
    }
};
//  -- List Licences ---------------
export const handleListLicences = () => async(dispatch) => {
    await dispatch(LicencesActions.listLicences());
    try {
        const licencesRDS = await API.get('licencesRDS', `/getLicence`);
        await dispatch(LicencesActions.listLicencesSuccess(licencesRDS));
        return licencesRDS;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        await dispatch(LicencesActions.listLicencesFail(error));
    }
};


//  -- Update district Licences -------------------
export const handleUpdateDistrictLicences = (organizationID, data) => async (dispatch) => {
    // await dispatch(LicencesActions.updateOrganization());

    try {
        const updateLicence = await API.put('licencesRDS', `/updateDistrictLicence/${organizationID}`, {
            body: { ...data }
        });

        // await dispatch(LicencesActions.updateLicencesSuccess(updateLicence));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        // await dispatch(LicencesActions.updateOrganizationFail(error));
    }
};

//  -- Update School Licences -------------------
export const handleUpdateSchoolLicences = (organizationID, data) => async (dispatch) => {
    // await dispatch(LicencesActions.updateOrganization());

    try {
        await API.put('licencesRDS', `/updateSchoolLicence/${organizationID}`, {
            body: { ...data }
        });

        // await dispatch(LicencesActions.updateOrganizationSuccess({ id: organizationID, ...data }));
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        // await dispatch(LicencesActions.updateOrganizationFail(error));
    }
};


//  -- List Licences ---------------
export const handleUpdateDistLicence = (orgId) => async(dispatch) => {
    // await dispatch(LicencesActions.listLicences());
    try {
        const licencesRDS = await API.get('licencesRDS', `/updateDistrict/${orgId}`);
        // await dispatch(LicencesActions.listLicencesSuccess(licencesRDS));
        return licencesRDS;
    } catch (error) {
        if(error&&error.message!="Network Error"){
            Sentry.captureException(error)
         }
        // await dispatch(LicencesActions.listLicencesFail(error));
    }
};