//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useRef, useEffect, useCallback, useState, useReducer } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import COLOR from 'utils/constants/color';
import { get, map, has, find, isArray } from 'lodash';
import { Row, Col, Progress } from "antd"
import { Done, DoneAll, AttachFile, CloudDownload, QueryBuilder, Close, InfoRounded } from "@material-ui/icons"
import { v4 } from 'uuid';
import moment from "moment"
import { debounce, filter } from 'underscore';
import { MessageRocket, uploadImg } from 'utils/assets/images'
import { Typography, ImageList, ImageListItem, Popover, IconButton } from "@material-ui/core"
import { CaretDownOutlined, FileExcelOutlined, FilePdfOutlined, FilePptOutlined, FileWordOutlined, FileOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core/styles';
import { storage } from 'firebaseConfig';
import {  ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert'
import EmojiSelector from "../emojiPicker";
import { MessageSeenModal } from "../MessageSeenModal"
import { useChatConfig } from 'store/chat/selectors';
import { handleCreateScheduleMessage, handleSendMessageChat, handleUpdateScheduleMessage, handleUpdateSeenMessageByUserId } from "store/chat/thunks";
import { Scheduler } from "../scheduler"
import Loading from 'components/Loading';
import { MessageRestric } from "../MessageRestric"
import {CheckMark } from "../CheckMark"
import AudioRecorder from "../AudioRecorder"
const MAX_FILE_SIZE = 25165824
const time_format = "HH:mm";
const useStyles = makeStyles((theme) => ({
    bulbicon: {
        color: ' rgb(223 175 109)',
        fontSize: '18px',
        rotate: 'deg(180)',
        transform: 'rotate(180deg)',
        marginRight: '5px'
    },
    usericon: {
        color: COLOR.black100,
        fontSize: '30px',
        marginRight: ' 5px'
    },
    addIcon: {
        color: COLOR.black100,
        fontSize: '20px',
        marginRight: ' 5px'
    },
    attachIcon: {
        color: "#757575",
        fontSize: 24
    },
    emojiIcon: {
        color: COLOR.yellow,
    },
    timePicker: {
        background: 'rgb(80,114,116)',
        // borderRadius: '20px',
        // height: '25px',
        // color: '#fff',
        // width: '90px',
        // fontSize: '12px',
        // borderBottom:"none"
    }
}));
//  -- Constants ----------------------
//  -- Components ---------------------
//  -- Thunks -------------------------

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const messageInterface = {
    "id": "",
    "message": "",
    "senderId": "",
    "classId": "",
    "orgId": "",
    "receiverId": "",
    "media": null,
    "type": "text",
    "brodcastId": "",
    "createdAt": "",
    "quee": true,
    "code": 'message',
    "senderName":''
}
const seenInterFace = {
    "messageIds": [],
    "type": "seen_deliver",
    "to": "",
    "me": "",
    "deliverAt": "",
    "readAt": "",
    "seen": true,
    'code': 'seen_deliver'

}
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
    display:flex;
    flex-direction:column;
    justify-content: space-between; 
`;
const MessageArea = styled.div`
    height:100%;
    overflow-y:auto;
    overflow-x:hidden;
    height:52vh;
`
const MessageBox = styled.div`
    display:flex;
    flex-direction:column;
    //height: 17vh;
    border-top: 1px solid #fff;
    position:relative;
`;
const MessageInQuee = styled.div`
       height:50px;
       background:rgba(0,0,0,0.5);
       width:100%;
       position:absolute;
       top:-50px;
       display:flex
`;

const SendButton = styled.div`
    height: 25px;
    width: 70px;
    border-radius: 20px;
    background: rgb(20 89 194);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-right: -10px;
`;
const SendArea = styled.div`
    width: ${p => p.isStudent ? "100%" : "70%"};
    border-right:  ${p => p.isStudent ? "none" : "0.5px solid #fff"};
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
`;
const SendOptionArea = styled.div`
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const InputBox = styled.input`
   width:100%;
   border-radius:20px;
   border:1px solid;
   padding:10px;
   outline:none;
   
`;
const MessageRow = styled.div`
  display:flex;
  flex-direction: column;
`;
const TimeDisplay = styled.span`
   font-size:10px;
   text-align: right;
   display: flex;
   align-self: flex-end;
   color:${COLOR.black100};
   margin-bottom:10px;
   position: absolute;
   left: 0px;
   bottom: -30px;
   min-width: 140px;
`;
const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom:0px;
    width: fit-content;
    position:relative;
    min-width:100px;
    margin-top: 10px;
    margin-bottom: 15px;
`;
const EmojiBox = styled.div`
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
`;
const MessageRocketOnNoMessage = styled.div`
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    color: #fff;
    text-transform: capitalize;
    flex-direction: column;
`;
const NoMessageImg = styled.img`
    height: auto;
    width: 100%;
    @media screen and (max-width: 992px){
        width: 100% !important;
        height: initial !important;
    }
`;
const MessageForNoMessage = styled.div`
   font-size: 15px;
   white-space: pre-wrap;
   font-weight:bold;
   text-align: center;
   padding: 0px 15px;
   text-transform: initial;
   line-height: 22px;
   margin-top: 10px;
   margin-bottom: 10px;
   color:${COLOR.black100};
`;
const FileUploader = styled.input`
   display:none;   
`;
const MediaLink = styled.a`
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 10px;
    &:hover {
        color: ${COLOR.white};
    }
`;
const MediaItems = styled.div`
    height: 30px;
    width: 100%;
    max-width: 250px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    display: flex;
    background:${p => p.mine ? "#2075b463" : "#fff"};
    box-shadow:${p => p.mine ? "-1px -1px 2px 0px #07638d14, -2px 1px 2px 0px #07638d7a, 2px 1px 2px 0px #256fea47;" : "-1px -1px 2px 0px #fff, -2px 1px 2px 0px #fff, 2px 1px 2px 0px #fff;"}
    border-radius: 5px;
    padding: 5px;
}
`;
const FileIconArea = styled.div`
   width:15%;
`;
const FileNameArea = styled.div`
    width:75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ui-sans-serif;
`;
const FileDownoladArea = styled.div`
   width:10%;
`;

const CloseReapper = styled.button`
    border: none;
    border-radius: 50%;
    background: rgb(7 35 110);
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    right:20px;
    top: 35%;
    color:#fff;
`;
const getTimeMessageTime = (messageTime) => {
    if (moment().isSame(messageTime, "date")) {
        const hourDiff = moment().diff(moment(messageTime), "hours")
        if (hourDiff > 0) {
            return `${moment(messageTime).format("hh:mm a")}`
        } else {
            const minutDiff = moment().diff(moment(messageTime), "minutes")
            const secondDiff = moment().diff(moment(messageTime), "seconds")
            return minutDiff > 0 ? `${minutDiff} minutes ago.` : secondDiff > 10 ? `${secondDiff} seconds ago.` : `Just now.`;
        }

    } else if (moment().isSame(messageTime, "year")) {
        return moment(messageTime).format("MMM DD, hh:mm a")
    }
    else {
        return `${moment(messageTime).format("MM/DD/YYYY hh:mm a")}`;
    }
}
function isChatHourAvailable(userChatHourConf) {
    if (!has(userChatHourConf, "endTime")) return true;
    return moment().isSameOrBefore(moment(userChatHourConf.endTime, time_format), "minutes") && moment().isSameOrAfter(moment(userChatHourConf.startTime, time_format), "minutes")
}
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
const Messages = ({
    currentUser,
    selectedUser,
    socket,
    activeClass,
    socketRenew,
    message,
    FILE_TYPE,
    expendChatArea,
    activeUsers,
}) => {
    const isBordcast = !get(selectedUser, "username") && has(selectedUser, "id");
    const selectedUserConfig = get(selectedUser, "config", {})
    const showSeenSymbol = get(selectedUserConfig, "messageSeen", true)
    const userChatConfig = useChatConfig()
    const userChatHourConf = get(get(selectedUser, "config", {}), "chatHour")
    const hasChatHour = isChatHourAvailable(userChatHourConf);
    const [broadcastReceivers, setbroadcastReceivers] = useState([])
    // console.log(showSeenSymbol,hasChatHour ,selectedUserConfig,"--=selectedUserConfig=-")
    // const userChatConfig = useChatConfig()
    const isStudent = get(currentUser, "role") === "student";
    const chatDisabled = isStudent ?  get(selectedUserConfig,"disabled",false): get(userChatConfig,"disabled",false)
    const dispatch = useDispatch()
    const [messageText, setMessageText] = useState('')
    const messageBoxRef = useRef()
    const [actMessage, setActMessage] = useState([])
    const cssClasses = useStyles();
    const [mediaFile, setMediaFile] = useState(null)
    const [scheduleEl, setScheduleEl] = React.useState(null);
    const [scheduleLoader, setScheduleLoader] = useState(false)
    const [editScheduledMessageval, seteditScheduledMessageval] = useState(null)
    const schedulePopOveropen = Boolean(scheduleEl);
    const schedulePopOverid = schedulePopOveropen ? 'simple-popover' : undefined;
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [messageSeenData, setMessageSeenData] = useState(null)
    const [fileUploaderLoader, setFileUploaderLoader] = useState({
        loading: false,
        per: 0,
        type: ''
    })
    const isDisableSendButton = () => {
        return ((!messageText || messageText.length == 0) && !mediaFile)
    }
    const [alertView, setAlertView] = useState({ open: false, message: "" })
    const imgRef = useRef()
    const fileRef = useRef()
    const schedulerRef = useRef()
    useEffect(() => {
        setbroadcastReceivers(activeUsers ? activeUsers : [])
    }, [activeUsers])
    useEffect(() => {
        const interval = setInterval(forceUpdate, 15000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    const sendMessage = async () => {
        if (!selectedUser || !activeClass || isDisableSendButton()) return;
        let messageCreate = { ...messageInterface }
        messageCreate.classId = isBordcast ? selectedUser.id : activeClass.id
        messageCreate.type = mediaFile ? "media" : "text"
        messageCreate.senderId = currentUser.id
        messageCreate.receiverId = isBordcast ? null : selectedUser.id
        messageCreate.orgId = currentUser.orgId
        messageCreate.message = messageText
        messageCreate.brodcastId = isBordcast ? selectedUser.id : null;
        messageCreate.id = v4()
        messageCreate.createdAt = new Date()
        messageCreate.media = mediaFile
        messageCreate.senderName = isBordcast?`${get(selectedUser, "displayName", "")}`:`${get(currentUser, "lastName", "")}, ${get(currentUser, "firstName", "")}`
        try {
            await dispatch(handleSendMessageChat(messageCreate))
            invokSend(messageCreate)
            setMessageText('')
            setMediaFile(null)
        } catch (error) {
            console.log(error, "error")
        }
    }
    const createScheduledMessage = async (scheduledAt) => {
        if (!selectedUser || !activeClass || isDisableSendButton()) return;
        setScheduleLoader(true)
        if (editScheduledMessageval) {
            let messageCreate = { ...editScheduledMessageval }
            messageCreate.message.media = mediaFile;
            messageCreate.message.message = messageText
            messageCreate.scheduledAt = scheduledAt
            await dispatch(handleUpdateScheduleMessage(messageCreate))
            setMessageText('')
            setMediaFile(null)
            setScheduleLoader(false)
            seteditScheduledMessageval(null)
        } else {
            let messageCreate = { ...messageInterface }
            messageCreate.classId = isBordcast ? selectedUser.id : activeClass.id
            messageCreate.type = mediaFile ? "media" : "text"
            messageCreate.senderId = currentUser.id
            messageCreate.receiverId = isBordcast ? null : selectedUser.id
            messageCreate.orgId = currentUser.orgId
            messageCreate.message = messageText
            messageCreate.brodcastId = isBordcast ? selectedUser.id : null;
            messageCreate.id = v4()
            messageCreate.createdAt = new Date()
            messageCreate.media = mediaFile
            messageCreate.senderName = isBordcast?`${get(selectedUser, "displayName", "")}`:`${get(currentUser, "lastName", "")}, ${get(currentUser, "firstName", "")}`
            const data = {
                message: messageCreate,
                senderId: currentUser.id,
                receiverId: messageCreate.receiverId,
                scheduledAt: scheduledAt
            }
            try {
                await dispatch(handleCreateScheduleMessage(data))
                setMessageText('')
                setMediaFile(null)
                setScheduleLoader(false)
            } catch (error) {
                console.log(error, "error")
                setScheduleLoader(false)
            }

        }

    }
    const invokSend = (data) => {
        if (socket) {
            try {
                if (socket.readyState == 3) {
                    socketRenew()
                    setTimeout(() => {
                        socket.send(JSON.stringify(data))
                    }, 2000)
                } else {
                    socket.send(JSON.stringify(data))
                }
            } catch (error) {
                console.log(error, "error")
            }
        }
    }
    useEffect(() => {
        if (messageBoxRef.current) {
            if (actMessage.length != message.length) {
                messageBoxRef.current.scrollTo(0, messageBoxRef.current.scrollHeight)
            }
            if (!isBordcast) {
                const canBeUpdate = filter(message, msg => (!msg.deliverAt || !msg.seen) && msg.senderId != currentUser.id)
                if (canBeUpdate.length > 0) {
                    markedMessageAsSeen()
                }
            } else {
                if (!isStudent) return;
                const canBeUpdate = filter(message, msg => !find(msg.brodcastDelivered, ["id", currentUser.id]) || !find(msg.brodcastSeen, ["id", currentUser.id]))
                if (canBeUpdate.length > 0 && message && message.length > 0) {
                    markedMessageAsBrodcast()
                }
            }

        }
        setActMessage(message)
    }, [message, selectedUser])

    const markedMessageAsSeen = debounce(() => {
        if (!expendChatArea) return;
        console.log("call for update")
        const markMsg = { ...seenInterFace }
        markMsg.me = currentUser.id
        markMsg.to = selectedUser.id
        markMsg.deliverAt = moment().toISOString()
        markMsg.readAt = moment().toISOString()
        dispatch(handleUpdateSeenMessageByUserId(selectedUser.id))
        invokSend(markMsg)
    }, 1000)
    const markedMessageAsBrodcast = debounce(() => {
        if (!expendChatArea) return;
        console.log("call for update brodcast seen")
        const markMsg = {
            code: "seen_deliver_brodcast",
            brodcastId: get(selectedUser, "id"),
            me: get(currentUser, "id")
        }
        invokSend(markMsg)
    }, 1000)
    const getIconFile = (type, isMine, size = 20) => {
        switch (type) {
            case "word":
                return <FileWordOutlined style={{ color: isMine ? "#fff" : 'gray', fontSize: size }} />;
            case "excel":
                return <FileExcelOutlined style={{ color: isMine ? "#fff" : 'gray', fontSize: size }} />;
            case "powerpoint":
                return <FilePptOutlined style={{ color: isMine ? "#fff" : 'gray', fontSize: size }} />;
            case "pdf":
                return <FilePdfOutlined style={{ color: isMine ? "#fff" : 'gray', fontSize: size }} />;
            default:
                return <FileOutlined style={{ color: isMine ? "#fff" : 'gray', fontSize: size }} />;
        }
    }
    const renderTextMessage = (msg) => msg.message
    const renderMedia = (msg, isMine) => {
        const media = get(msg, "media", []);
        const files = filter(media, k => k.type !== "image"&& k.type!="audio");
        const audios = filter(media, k =>k.type=="audio");
        const imageMedis = filter(media, k => k.type == "image");
        if (imageMedis.length > 0) {
            return (<ImageList cols={1}>
                {map(imageMedis, (item) => (
                    <ImageListItem style={{ maxHeight: 120 }} key={item.url}>
                        <img
                            src={item.url}
                            srcSet={item.url}
                            style={{ maxHeight: 120, width: "auto", borderRadius: 15 }}
                            alt={"media"}
                            loading="lazy"
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = item.url;
                                link.target = "_blank"
                                link.setAttribute("download", true)
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>)
        }
        if (files.length > 0) {
            return (map(files, f => (<MediaItems key={get(f, "url")} mine={isMine}>
                <MediaLink href={get(f, "url")} download target={"_blank"} >
                    <FileIconArea>
                        {getIconFile(FILE_TYPE[get(f, "mediaType")], isMine)}
                    </FileIconArea>
                    <FileNameArea style={{ color: isMine ? "#fff" : 'gray', fontSize: 14 }} >
                        {f.name ? f.name : "File"}
                    </FileNameArea>
                    <FileDownoladArea>
                        <CloudDownload style={{ color: isMine ? "#fff" : 'gray', fontSize: 20 }} />
                    </FileDownoladArea>
                </MediaLink>
            </MediaItems>)));
        }
        if(audios.length){
            return  map(audios,au=>(<audio key={au.url} controls controlsList="nodownload"  src={au.url} style={{     height: '20px', width: '120px' }}></audio>))
         }
        return null;
    }
    const messageRender = useCallback(() => {
        return map(message, msg => {
            const isMine = msg.senderId == currentUser.id
            return (
                <MessageRow key={msg.id} className="imessage-popup" style={{ justifyContent: isMine ? "flex-end" : "flex-start" }}>
                    <MessageContainer className={isMine ? "from-me-popup" : "from-them-popup"}>
                        {renderMedia(msg, isMine)}
                        {renderTextMessage(msg)}
                        <TimeDisplay style={isMine ? { textAlign: "right", alignSelf: "flex-end" } : { textAlign: "left", alignSelf: "flex-start" }}>
                            {isBordcast && isMine && <IconButton onClick={() => setMessageSeenData(msg)} style={{ padding: 0 }}><InfoRounded style={{ color: COLOR.black100, fontSize: 16 }} /></IconButton>}
                            <CheckMark
                                seen={get(msg, "seen", false)}
                                deliverAt={get(msg, "deliverAt")}
                                me={isMine}
                                showSeenSymbol={showSeenSymbol}
                                quee={get(msg, "quee", false)}
                                isBrodcast={isBordcast}
                                broadcastReceivers={filter(broadcastReceivers,k=>k.id!=get(currentUser,"id"))}
                                brodcastSeen={filter(get(msg, "brodcastSeen"),k=>k.id!=get(currentUser,"id"))}
                                brodcastDelivered={filter(get(msg,"brodcastDelivered"),k=>k.id!=get(currentUser,"id"))}
                            />
                            {getTimeMessageTime(msg.createdAt)}
                        </TimeDisplay>
                    </MessageContainer>
                </MessageRow>
            )
        })
    }, [message, selectedUser, broadcastReceivers])

    const onImagePick = async (event) => {
        try {
            const file = event.target.files[0];
            setFileUploaderLoader({ loading: true, per: 1, type: "image" })
            UploadToStore(file, "image")
        } catch (error) {

        }
    }
    const onFilePicker = async (event) => {
        try {
            const file = event.target.files[0];
            setFileUploaderLoader({ loading: true, per: 1, type: "media" })
            UploadToStore(file, "media")
        } catch (e) {

        }
    }
    const UploadToStore = async (file, type) => {
        try {
          if (file.size < MAX_FILE_SIZE) {
            setMediaFile(null);
            const location = '/chat/messages';
            const id = `${moment().format('YYYY-MM-DD')}-${v4()}`;
            const storageRef = ref(storage, `${location}/${id}${file.name}`);
            
            const uploadTask = uploadBytesResumable(storageRef, file);
      
            uploadTask.on('state_changed',
              (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                const per = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setFileUploaderLoader({ loading: true, per: per, type: type });
              }, 
              (error) => {
                setFileUploaderLoader({ loading: false, per: 0, type: "" });
                setAlertView({ open: true, message: "Error while uploading file." });
                console.error("Upload error:", error);
              }, 
              () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  const isImage = file.type.includes("image");
                  const fileObj = {
                    type: isImage ? 'image' : 'file',
                    url: downloadURL,
                    mediaType: file.type,
                    name: file.name
                  };
                  setMediaFile([fileObj]);
                  // fileMessage([fileObj]);
                //   console.log("File available at", downloadURL);
                });
                setFileUploaderLoader({ loading: false, per: 0, type: '' });
              }
            );
          } else {
            setFileUploaderLoader({ loading: false, per: 0, type: "" });
            setAlertView({ open: true, message: "File must be less then equal to 25mb." });
          }
        } catch (error) {
          setFileUploaderLoader({ loading: false, per: 0, type: "" });
          console.error("Error:", error);
        }
      };
    const onEmojiPic = (emojiData) => {
        let msg = messageText;
        msg += emojiData.native
        setMessageText(msg)
    }
    const onClickScheduleOpt = (event) => {
        if (isDisableSendButton()) return;
        setScheduleEl(event.currentTarget);
    };

    const onCloseScheduleOpt = () => {
        setScheduleEl(null);
    };
    const onCloseScheduler = () => {
        setScheduleEl(null);
        setScheduleLoader(false)
        seteditScheduledMessageval(null)
    }
    return (
        <>
            {!!messageSeenData && <MessageSeenModal
                showModal={!!messageSeenData}
                closeModal={() => { setMessageSeenData(null) }}
                classUsers={broadcastReceivers}
                seenUsers={get(messageSeenData, "brodcastSeen")}
                deliverdUsers={get(messageSeenData, "brodcastDelivered")}
            />}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertView.open}
                autoHideDuration={3000}
                onClose={() => { setAlertView({ open: false, message: '' }) }}>
                <Alert onClose={() => { setAlertView({ open: false, message: '' }) }} severity="error">
                    <strong>ALERT:</strong> {alertView.message}
                </Alert>
            </Snackbar>
            <Wrapper onClick={() => { }} className='msgAtchUpr'>
                <MessageArea ref={messageBoxRef}>
                    {message && message.length > 0 ? messageRender() : <MessageRocketOnNoMessage>
                        <NoMessageImg src={MessageRocket} />
                        <MessageForNoMessage>
                            {get(currentUser, "role") == 'teacher' ? ` Use this section to message your students individually or as a class. Happy OneWayMessaging!` : 'Use this section as communicate with your teacher or get class brodcast.'}</MessageForNoMessage>
                    </MessageRocketOnNoMessage>}
                </MessageArea>
                <MessageBox className='msgAtchBox'>
                    {mediaFile && Array.isArray(mediaFile) ? <MessageInQuee>
                        <Row style={{ width: "100%", flexDirection: "row", color: "#fff", alignItems: "center" }}>
                            {map(mediaFile, f => {
                                if (f.type == "image") {
                                    return <>
                                        <img
                                            key={f.url}
                                            src={f.url}
                                            srcSet={f.url}
                                            style={{ maxHeight: 50, width: "auto" }}
                                            alt={"media"}
                                            loading="lazy"
                                        />
                                        <div style={{ marginLeft: 10 }}> {get(f, "name", "File")}</div>
                                    </>

                                } 
                                else if (f.type == "audio") {
                                    return(<audio key={f.url} controls controlsList="nodownload"  src={f.url}  style={{     height: '40px', width: '200px' }}></audio>)
                                }
                                else {
                                    return <div key={f.url} style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                                        <div style={{ height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}> {getIconFile(FILE_TYPE[get(f, "mediaType")], true, 40)}</div>
                                        <div style={{ marginLeft: 10 }}> {get(f, "name", "File")}</div>
                                    </div>
                                }
                            })
                            }
                            {map(mediaFile, f => (<FileNameArea key={f.name ? f.name : "File"} style={{ color: "#fff", fontSize: 14 }} >
                            </FileNameArea>))}
                            {scheduleLoader ? <Loading size={20} white /> : null}
                            <CloseReapper onClick={() => setMediaFile(null)}> <Close style={{ fontSize: 15, color: "#fff" }} /></CloseReapper>
                        </Row>

                    </MessageInQuee> : null}
                    <MessageRestric
                        isBordcast={isBordcast}
                        isStudent={get(currentUser, "role") == "student"}
                        hasChatHour={hasChatHour}
                        userChatHourConf={userChatHourConf}
                        chatDisabled={chatDisabled}
                    ><>
                            <Row style={{ width: "100%", paddingLeft: '15px', paddingRight: '15px' }}>
                                <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <textarea
                                        style={{
                                            border: "none",
                                            background: " #f3f3f3",
                                            borderRadius: '0px',
                                            minHeight: "50px",
                                            width: '100%',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            paddingLeft: '7px',
                                            paddingRight: '7px',
                                            outline: 'none',
                                            resize: 'none'
                                        }}
                                        onChange={e => setMessageText(e.target.value.trimStart())} value={messageText} onKeyDown={e => {
                                            if (e.key == 13 || e.key == "Enter") {
                                                if (!mediaFile && messageText.replace(/\s/g, '').length == 0) {
                                                    return
                                                }
                                                sendMessage()
                                            }
                                        }}
                                        placeholder="message.."></textarea>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", paddingLeft: '15px', paddingRight: '15px' }}>
                                <Col span={10} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    {fileUploaderLoader.loading && fileUploaderLoader.type == "media" ? <Progress type="circle" percent={fileUploaderLoader.per} size={"small"} width={30} className={"fileProgress"} /> : <EmojiBox style={{ transform: 'rotate(-45deg)' }}> <AttachFile className={cssClasses.attachIcon} onClick={() => {
                                        fileRef.current.click()
                                    }} />
                                        <FileUploader ref={fileRef} type="file"
                                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf , application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                            onChange={onFilePicker} />
                                    </EmojiBox>}
                                    {fileUploaderLoader.loading && fileUploaderLoader.type == "image" ? <Progress type="circle" percent={fileUploaderLoader.per} size={"small"} width={30} className={"fileProgress"} /> : <EmojiBox
                                        onClick={() => {
                                            imgRef.current.click()
                                        }}>
                                        <img style={{ height: 18, width: "auto" }} src={uploadImg} />
                                        <FileUploader ref={imgRef} type="file" accept='image/*' onChange={onImagePick} />
                                    </EmojiBox>}
                                    {!isStudent && <EmojiBox>
                                        <EmojiSelector onEmojiClick={onEmojiPic} bottom={"100px"} />
                                    </EmojiBox>}
                                    <AudioRecorder 
                                    hasBoxShadow
                                    onRecorderDone={url=>{
                                    const fileObj = {
                                        type: 'audio',
                                        url: url,
                                        mediaType: 'audio/mp3',
                                        name: 'record.mp3'
                                    }
                                    setMediaFile([fileObj])
                                }} />
                                </Col>
                                <Col span={10} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <SendButton >
                                        <SendArea isStudent={isStudent} aria-disabled={isDisableSendButton()} disabled={isDisableSendButton()} onClick={sendMessage} >Send</SendArea>
                                        {!isStudent ? <SendOptionArea aria-disabled={isDisableSendButton()} disabled={isDisableSendButton()} aria-describedby={schedulePopOverid} ref={schedulerRef} onClick={onClickScheduleOpt}>
                                            <CaretDownOutlined style={{ fontSize: 15, color: '#fff' }} />
                                        </SendOptionArea> : null}
                                    </SendButton>
                                    <Popover
                                        id={schedulePopOverid}
                                        open={schedulePopOveropen}
                                        anchorEl={scheduleEl}
                                        onClose={onCloseScheduleOpt}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        className={"chatSettingPopOver"}
                                    >
                                        <Scheduler onClose={onCloseScheduler} onCreate={createScheduledMessage} setAlertView={setAlertView} value={get(editScheduledMessageval, "scheduledAt")} />

                                    </Popover>
                                </Col>
                            </Row>
                        </>
                    </MessageRestric>
                </MessageBox>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
Messages.propTypes = {};

//  -- Default props ------------------
Messages.defaultProps = {

};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Messages);
