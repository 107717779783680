//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { findIndex, map, cloneDeep, filter } from 'lodash';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const videosInit = []
const resourcesInit = [];

export const videos = (state = videosInit, action) => {
    switch (action.type) {
        case ActionTypes.resources.setVideoResourcesSuccess:
            return action.payload;
        case ActionTypes.resources.setVideoResourcesFail:
            return state;
        case ActionTypes.resources.updateVideoResourcesSuccess:
            const videos = cloneDeep(state)
            const ind = findIndex(videos, ['id', action.payload.id])
            if (ind >= 0) {
                return map(videos, r => {
                    if (r.id == action.payload.id) {
                        return ({ ...r, ...action.payload })
                    } else {
                        return r;
                    }
                })
            } else {
                videos.push(action.payload)
                return videos;
            }
        default:
            return state;
    }
};

export const resources = (state = resourcesInit, action) => {
    switch (action.type) {
        case ActionTypes.resources.setResourcesSuccess:
            return action.payload;
        case ActionTypes.resources.setResourcesFail:
            return state;
        case ActionTypes.resources.updateResourcesSuccess:
            const resource = cloneDeep(state)
            const ind = findIndex(resource, ['id', action.payload.id])
            if (ind >= 0) {
                return map(resource, r => {
                    if (r.id == action.payload.id) {
                        return ({ ...r, ...action.payload })
                    } else {
                        return r;
                    }
                })
            } else {
                resource.push(action.payload)
                return resource;
            }
        case ActionTypes.resources.deleteResourcesSuccess:
            return filter(state, st => st.id != action.payload.id)
        default:
            return state;
    }
};
