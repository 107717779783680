import { ClockCircleOutlined } from "@ant-design/icons";
import { TimerRounded, WatchLater, WatchLaterOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import AccordinButton from "components/AccordinButton";
import ResizeAndDragabble from "components/ResizeAndDraggable";
import StopwatchWheel from "components/Stopwatch";
import TimerWheelWorker from "components/TimeWheelWithWorker";
import { find, get } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useCurrentUser } from "store/users/selectors";
import { revokeTrafficModal } from "store/wheel/actions";
import { useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
  //   margin: 15px auto;/
  transform: translate(31%, 0%);

  @media (max-width: 1450px) {
    // width: 96%;
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    // width: 95%;
    transform: translate(0%, 0%);
  }
`;

const StopwatchButton = styled.button`
  // padding: 10px;
  border: none;
  width: 18px;
  height: 18px;
  background: transparent;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  z-index: 9999;
  font-size: 12px;
`;

const StyledClockIcon = styled(ClockCircleOutlined)`
  margin-right: 8px;
  color: white;
  // font-size: 16px;
`;

const StyledStopWatchIcon = styled(TimerRounded)`
  margin-right: 8px;
  color: white;
  font-size: 21px !important;
`;

const StyledTimerIcon = styled(WatchLater)`
margin-right: 8px;
color: white;
font-size: 21px !important;
`
const WidgetsTimer = ({ currentClass, sharableWidget }) => {
  const [showAccordin, setShowAccordin] = useState(true);
  const [dimensions, setDimensions] = useState({})
  const dispatch = useDispatch();
  const [trafficModal] = useTraffic();
  const currentUser = useCurrentUser();
  const isTeacher = get(currentUser, "role") === "teacher";
  const [isStopWatch, setIsStopWatch] = useState(false);
  const [editorFocus,setEditorFocus] = useState(false);

  const trafficData = trafficModal?.widget_menu ?? [];
  const filterByWidget = find(
    trafficData,
    (item) => item?.menu === "widget_timer"
  );
  const filterShareTimer = find(sharableWidget, (obj) => obj?.widget_type === 'widget_timer')

  const onClose = async () => {
    await dispatch(revokeTrafficModal(filterByWidget?.id));
  };

  const handleTouchClose = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleTouchWatchChange = (event) => {
    event.preventDefault();
    onWatchChange()
  }

  const onWatchChange = () => {
    setIsStopWatch(!isStopWatch)
  }

  return (
    <>
      {((filterByWidget?.view && filterByWidget?.menu === "widget_timer") || filterShareTimer?.id) && (
        <Container className="allCheckListSEction wigetsContainer">
          <div className="mainCheckList">
            <ResizeAndDragabble
           
              defaultValue={{
                x: 150,
                y: 205,
                // width: 470,
                // height: 502,
                width: 270,
                height: 323,
              }}
              // minWidth={470}
              //  minHeight={!showAccordin ? 502 : null}
              resize={(data) => {
                setDimensions(data)
              }}
              showAccordin={showAccordin}
              // onFocusValue={editorFocus}
            >
              <div
                className="checkListTopSection"
                style={{
                  position: "relative",
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                  width: "100%",
                  height: showAccordin ? "100%" : "0px",
                  padding: "0px"
                }}
              >
                <div className="mainTimerShareBtn" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  {isTeacher && (
                    <div style={{position: "absolute", top: "14px", right: "32px" }}>
                      <StopwatchButton onClick={() => {
                        onWatchChange();
                      }}
                        onTouchStart={handleTouchWatchChange}>
                          {isStopWatch ? <StyledTimerIcon /> : <StyledStopWatchIcon />}
  
                      </StopwatchButton>
                    </div>
                  )}
                      <div
                  className={`checkListFirstTxt w-full bg-blue-10 pt-17px pb-14px ${
                    showAccordin ? "br-topLeft-topRight-20px" : "br-20px"
                  } `}
                >
                 <AccordinButton open={showAccordin} onClose={()=> setShowAccordin(prev => !prev)} />

                  <span className="text-white text-ft12-700">{isStopWatch ? "Timer" : "Stopwatch"}</span>
                </div>
                  <div className="timerCancelBtn" style={{ display: "flex", position: "absolute", right: "10px", top: "13px" }}>
                    {isTeacher && (
                      <CancelIcon
                        onClick={() => onClose()}
                        onTouchStart={handleTouchClose}
                        style={{
                          marginLeft: "5px",
                          // position: "absolute",
                          right: 7,
                          top: 16,
                          fontSize: 18,
                          color: "red",
                          background: "white",
                          borderRadius: 20,
                          cursor: 'pointer'
                        }}
                      />
                    )}
                  </div>

                </div>
                {showAccordin && (
                    isStopWatch ? <StopwatchWheel clockSize={dimensions} isTeacher={isTeacher} /> : <TimerWheelWorker setEditorFocus={setEditorFocus} clockSize={dimensions} isTeacher={isTeacher} />
                )}
               
              </div>
            </ResizeAndDragabble>
          </div>
        </Container>
      )}
    </>
  );
};

export default WidgetsTimer;
