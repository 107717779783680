import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";

const ResizeAndDragabble = ({
  defaultValue = {
    x: 150,
    y: 205,
    width: 270,
    height: 323,
  },
  minWidth = 270,
  minHeight = 323,
  children,
  aspectRatio = true,
  resize = (data) => data,
  resizeEnd,
  bounds = "window",
  onFocusValue = false,
  showAccordin,
  setDirectionResizing,
  page,
  ...props
}) => {
  const [dimensions, setDimensions] = useState({
    width: defaultValue?.width,
    height: defaultValue?.height
  });
  const [minHieghtChecker,setMinCheck] = useState(false);
  const [isResizing, setIsReszing] = useState(false);
  const [previousPosition, setPreviousPosition] = useState({
    x: Math.floor((Math.random() * (window.innerWidth - minWidth)) / 2),
    y: Math.floor((Math.random() * (window.innerHeight - minHeight)) / 2),
  });
  const { x, y, ...others } = defaultValue;

  useEffect(() => {
    resize({ width: defaultValue.width, height: defaultValue.height });
  }, []);

  const resizeHandleStyles = {
    top: { display: "none" },
    bottom: { display: "none" },
    left: { display: "none" },
    right: { display: "none" },
    topLeft: { cursor: "nwse-resize" },
    topRight: { cursor: "nesw-resize" },
    bottomLeft: { cursor: "nesw-resize" },
    bottomRight: { cursor: "nwse-resize" },
  };

  const generateNewPosition = () => {
    let newX, newY;
    do {
      newX = Math.floor((Math.random() * (window.innerWidth - minWidth)) / 2);
      newY = Math.floor((Math.random() * (window.innerHeight - minHeight)) / 2);
    } while (newX === previousPosition.x && newY === previousPosition.y);

    return { x: newX, y: newY };
  };

  const initialPosition = generateNewPosition();

  const minHeightForSpecificPage= 44;

  useEffect(()=>{
    const checkMinHeight = () => {
      if(showAccordin!== undefined && !showAccordin ){
        if(page === "station" || page === "text" || page === "group"){
          setMinCheck(minHeightForSpecificPage);
          return 
        }
        setMinCheck(0);
        return minHeight;
      }else{
        setMinCheck(minHeight);
        return minHeight;
      }
   
    }
  checkMinHeight();
    
  },[showAccordin])



  return (
    <Rnd
      // style={{ zIndex: 99999999 }}
      lockAspectRatio={aspectRatio}
      default={{
        x: initialPosition.x,
        y: initialPosition.y,
        ...others,
      }}
      size={{
        height: !showAccordin ? "0px" : dimensions?.height,
        width: dimensions?.width,
      }}
      minWidth={minWidth}
      minHeight={minHieghtChecker}
      bounds={bounds}
      disableDragging={isResizing || onFocusValue}
      enableResizing={showAccordin}
      resizeHandleStyles={aspectRatio ? resizeHandleStyles : {}}
      onResize={(e, direction, ref, delta, position) => {
        if(page === "clock"){
          if(direction === "topLeft" || direction === "topRight" || direction === "bottomLeft" || direction === "bottomRight"){
            setDirectionResizing(true)
          }
          else{
            setDirectionResizing(false);
          }
        }
        const layout = {
          width: parseInt(ref.style.width, 10),
          height: parseInt(ref.style.height, 10),
          transform: `${Math.min(
            3,
            parseInt(ref.style.width, 10) / minWidth,
            parseInt(ref.style.height, 10) / minHeight
          )}`,
        };
        setDimensions(layout);

        resize(layout);
      }}
      onResizeStart={() => setIsReszing(true)}
      onResizeStop={() => setIsReszing(false)}
      {...props}
    >
      <div style={{ width: "100%", height: "100%" }}  onTouchStart={(e) => e.stopPropagation()}>{children}</div>
    </Rnd>
  );
};

export default ResizeAndDragabble;
