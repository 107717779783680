import React from 'react';
import styled from 'styled-components';

// -- Components ----------------------
import Loading from 'components/Loading';
import {CotationBgimg ,CotationBgimgNew } from "utils/assets/images";



const btnpurple="rgba(86,29,223)"

const CotationWrapper=styled.div`
    background-image:url(${CotationBgimgNew});
    background-repeat: no-repeat;
    width: 500px;
    height: 500px;
    background-position: center;
    background-size: 100% 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: auto;
    position: relative;
    @media (max-width: 992px) {
        // width: 300px;
        // height: 300px;
        width: 320px;
        height: 342px;
        margin-top: -50%;
    }
`
const TextMessage = styled.span`
    color:#fff;
    font-size: 18px;
    font-weight: 600;

    @media (max-width: 992px) {
        font-size: 14px;
        position: relative;
        right: 8px;
        top: 10px;
    }
`;
const TextAuthor= styled.span`
    color:#fff;
    font-size: 14px;
    font-weight: 400;
    align-self: center;
    text-align: right;
    background-color: ${btnpurple};
    margin-left: -28px;
    border-radius: 12px;
    padding: 2px 24px;
    margin-top:10px;
    min-width:100px;
    /* Conditional styles */
    ${props =>
        props.longAuthor &&
        `
        @media (max-width: 992px) {
            line-height: 11.5px;
            width: 233px;
        }
        @media (min-width: 993px) {
            position: relative;
            left: 15px;
            // bottom: 5px;
            // line-height: 11.5px;
            // width: 233px;
        }
    `}
    @media (max-width: 992px) {
        position: relative;
        bottom: 48px;
        left: 14px;
        text-wrap: wrap;
    }
    `;

const TextContainer = styled.div`
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: flex-end;
    width: 100%;
    padding: 0px 16px;
    ${props =>
        props.smallmsg &&
        `
        @media (min-width: 0px) {
            position: relative;
            top: 20px;
        }
    `}
    ${props =>
        props.longAuthor &&
        `
        @media (min-width: 0px) {
            position: relative;
            top: 0px;
        }
    `}
`;
const LoaderInnerWrapper = styled.div`
    width:80px;
    height:80px;
    margin-top:16px;
`
const MessageWrapper = styled.div `
    border-left: 4px solid white;
    border-bottom: 4px solid white;
    margin-top: 20px;
    margin-right: 125px;
    margin-left: 85px;
    padding: 10px;

    @media (max-width: 992px) {
        margin-top: 25px;
        margin-right: 50px;
        margin-left: 0px;
        position: relative;
        bottom: 40px;
        left: 26px;
    }
`
const MessagesContainer = styled.div`
    color: #fff;
    justify-content: center;
    width: 100%;
    display: flex;
    margin-top: 30px;
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    left: 0%;
    bottom: 0px;
    white-space: pre-wrap;
    padding: 8px;
`;


const  CotationLoader=({cotation,messages})=>{
    return(
    <CotationWrapper>
        <LoaderInnerWrapper>
        <Loading size={62} />
        </LoaderInnerWrapper>
          {messages&& <MessagesContainer>{messages}</MessagesContainer>}
        <TextContainer smallmsg={cotation?.size === "sml"} longAuthor={cotation?.author === "Selena Quintanilla-Pérez, cantante y compositora"}>
            <MessageWrapper>
            <TextMessage>{cotation?.message}</TextMessage>
            </MessageWrapper>
           {cotation?.author&& <TextAuthor longAuthor={cotation?.author === "Selena Quintanilla-Pérez, cantante y compositora"} >- {cotation?.author}</TextAuthor>}
        </TextContainer>
    </CotationWrapper>)
}

export default CotationLoader;