//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
//  -- Components ---------------------
import AnimatedText from "components/AnimatedText";
import CoversCard from "components/Covers";
import CoversCardList from "components/CoversList";
import Loading from "components/Loading";
import { find, get, map } from "lodash";
import queryString from "query-string";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAlertView } from "store/alert/selectors";
import { useCurrentOrgPermission } from "store/organizations/selectors";
import { useDemoStatus } from "store/users/selectors";
import styled from "styled-components";
import { ResourceBgimg } from "utils/assets/images";
import { TextField } from "@material-ui/core";
//  -- Constants ----------------------
import { useResources } from "store/resources/selectors";
import { AnecdotasThemes, APTHEME, getFireBaseUrl, IBTHEME, ResourceSymbol } from "utils/config/reference";
import { SearchIcon, MenuIcon } from "utils/assets/images";
import { Drawer, Dropdown, Menu, Tooltip, Col, Row, Button } from "antd";
import COLOR from "utils/constants/color";
import { isEmpty, flatMap, filter } from "lodash";
import { Categorias } from "utils/config/reference";
import { handleListResources, handleListVideos } from "store/resources/thunks";
import { placeImg, colorImg, pic33 } from "utils/assets/images";
import HeartIcon from "utils/assets/images/heart-icon.svg";
import BackIcon from "utils/assets/images/back-icon-resource.svg";
import ChampionIcon from "utils/assets/images/champion.svg";
import TaskIcon from "utils/assets/images/task-icon.svg";
import BottomDropDown from "utils/assets/images/resource-dropdown.svg"
import "../utils/assets/css/one-way-resourece.css";

import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { useFirebase } from "containers/FirebaseContext";



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ArrowDownwardOutlined, KeyboardArrowDown, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@material-ui/icons";
import { useOutsideClick } from "./chat/hooks";
import { handleCreateFavouriteVideo, handleListFavouriteVideo, handleListMostUsedVideo } from "store/FavouriteVideo/thunks";
import { useFavouriteVideo } from "store/FavouriteVideo/selectors";
import DropdownMenuForAnc from "components/DropDowns/DropdownMenu";
//  -- Features -----------------------
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const VideoSectionWrapper = styled.div`
  margin-top: 50px;
  padding: 0px 24px;

  @media only screen and (max-width: 600px) {
    padding: 0px 10px;
    margin-top: 18px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1168px) {
    padding: 0px 24px 0px 41px;
  }
  @media only screen and (min-width: 600px) and (max-width: 768px) {
    margin-top: 30px;
  }
`;
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  min-height: 90vh;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: calc(100% + 48px);
  margin-left: -24px;
  //   padding: 0px 24px;
  /* background-image:url(${ResourceBgimg}); */
  background-repeat: no-repeat;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    // padding: 0px 10px;
  }
`;
const Block = styled.div`
  min-height: 600px;
`;
// const Block = styled.div`
//    min-height:600px;
// `;
// export const Wrapper = styled.div`
//     height: 100%;
//     width: 100%;
//     background-image:url(${ResourceBgimg})
// `;
const ListItem = styled.li`
  margin: 0px 7px 14px;
  width: calc(16% - 14px);

  @media only screen and (max-width: 1200px) {
    width: calc(20% - 10px);
  }
  @media only screen and (max-width: 992px) {
    width: calc(24% - 10px);
  }
  @media only screen and (min-width: 600px) and (max-width: 800px) {
    margin: 0px 16px 32px;
    width: calc(50% - 32px);
  }
  @media only screen and (max-width: 600px) {
    margin: 0px 12px 24px;
    width: calc(50% - 24px);
  }
`;
const List = styled.ul`
  /* width: calc(100% + 40px); */
  /* width: calc(100% + -200px); */

  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
`;
//   margin-left: 12%;
// width: 75%;
const ListItemCovers = styled.li`
  margin: 0px 9px 32px;
  // width: calc(16% - 25px);
  width: calc(13% - -12px);

  @media only screen and (min-width: 1600px) {
      width: calc(13%);
  }


  // margin-left: 30px;
  // @media only screen and (min-width: 600px) and (max-width: 800px) {
  //   margin: 0px 16px 32px;
  //   width: calc(50% - 32px);
  // }
  @media only screen and (max-width: 600px) {
    margin: 0px 12px 24px;
    width: calc(50% - 24px);
  }
  

  @media only screen and (min-width: 450px) and (max-width: 670px) {
    margin: 0px 6px 32px;
    width: calc(27% - 25px);
  }
  // @media only screen and (min-width: 390px) and (max-width: 450px) {
  //   margin: 0px 6px 32px;
  //   width: calc(28% - 25px);
  // }
  @media only screen and (min-width: 670px) and (max-width: 900px) {
    margin: 0px 6px 32px;
    width: calc(26% - 21px);
  }
  @media only screen and (min-width: 900px) and (max-width: 1024px) {
    margin: 0px 6px 32px;
    width: calc(20% - 12px);
  }
  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    margin: 0px 8px 32px;
    width: calc(13% - 4px);
  }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    margin: 0px 9px 32px;
    width: calc(13% - 3px);
  }    


  // @media only screen and (min-width: 1024px) and (orientation: portrait) {
  
  // }
  // @media only screen and (min-width: 1024px) and (orientation: landscape) {
  //     // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
     
  // }
  
  
`;
const ListCovers = styled.ul`
  height: auto;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
`;
const PaddedBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 8px;
  text-align: left;
  width: 100%;
`;
const CategoryMenu = styled.div`
  background-color: #0B1C2B;
  border-radius: 30px;
  color: #fff;
  // width: 201px;
  height: 42px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  // text-align: center;
  color: #FFF;
 font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #2784A3;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 768px) {
    // width: 100% !important;
    height: 36px;
    color: #fff;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const MenuIconImg = styled.img`
  margin-left: 10px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
`;
const SearchInput = styled.input`
  width: 100%;
  hieght: 1000%
  color: #fff;
  border: none;
  background: transparent;
  outline: none;
  text-align: left;
  font-weight: 800;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR.white};
  }
  ::focus {
    outline: none;
  }
`;

const SearchCategry = styled.div`
    display:flex;
    align-items: center;
    justify-content:center;


`;
const ClassContainer = styled.div`
  min-height: 170px;
  height: 11vh;
  padding-top: 40px;
  padding-bottom: 38px;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GridContainer = styled.section`
  // background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
  // padding-inline: 24px;
  padding-inline: 19px;
  padding-bottom: 40px;
  box-sizing: border-box;
  text-align: left;

  @media (min-width: 320px) and (max-width: 400px) {
    padding-inline: 10px;
  }
`;

const GridContent = styled.div`
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  gap: 23px;
  max-width: 153%;
  z-index: 1;

  /* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  //   & > * {
  //     flex: 0 0 auto; /* Prevent children from shrinking */
  //   }
`;

const EmptyImagePlaceholder = getFireBaseUrl("novideos.png") || "";

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const filterWithTextVideos = (videos, txt) => {
  const itemtext = txt.toLowerCase().trimStart().trimEnd();
  return filter(videos, (k) => {
    if (
      get(k, "categorias", "").toLowerCase().includes(itemtext) ||
      get(k, "tags", "").toLowerCase().includes(itemtext)
    ) {
      return true;
    } else if (
      get(k, "resource", "").toLowerCase().includes(itemtext) ||
      get(k, "displayName", "").toLowerCase().includes(itemtext)
    ) {
      return true;
    } else {
      return false;
    }
  });
};
const VideoSection = ({ Items, keyProductos, isProtectedContent, handleFavouriteVideo, allFavorites, loader }) => {
  const resources = useResources();
  const displayName = get(
    find(resources, ["key", keyProductos]),
    "displayName",
    ""
  );
  return (
    <Block>
      <Row style={{ paddingBottom: "10px" }}>
        <AnimatedText text={displayName} page={"resource"} />
      </Row>
      <Row style={{ marginTop: "2%" }}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <ListCovers>
            {Items && Items.length !== 0 ? (
              <>
                {map(Items, (podcast, i) => (
                  <ListItemCovers key={podcast.id}>
                    <CoversCardList
                      data={podcast}
                      link={keyProductos}
                      isProtectedForDemo={isProtectedContent(i)}
                      handleFavouriteVideo={handleFavouriteVideo}
                      allFavorites={allFavorites}
                      loader={loader}
                      index={i}
                      page={"1wayResource"}
                    />
                  </ListItemCovers>
                ))}
              </>
            ) : (
              <div className="placeholder-image-container">
                <img
                  className="md-w-300px mdm-w-500px"
                  src={EmptyImagePlaceholder}
                  alt="placeholder-image"
                />
              </div>

            )
            }

          </ListCovers>
        </Col>
      </Row>
    </Block>
  );
};

const ResourcesContainer = ({ currentClass, currentUser }) => {

  const [isSelectedFilter, setIsSelectedFilter] = useState("");
  const history = useHistory();
  const search = queryString.parse(history.location.search);
  const link =
    search.link == "undefined" || !search.link || search.link == "null"
      ? "matias"
      : search.link;
  const resources = useResources();
  const [keyProductos, setKeyProductos] = useState(link);
  const [resourceData, setResourceData] = useState(resources);
  const [categorias, setCategorais] = useState(Categorias);
  const [selectedCategoray, setSelectedCatogray] = useState("");
  const [, invokeAlert, revokeALert] = useAlertView();
  const orgPermissions = useCurrentOrgPermission();
  const demoStatus = useDemoStatus();
  const allFavorites = useFavouriteVideo();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    open: false,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState({ type: "", open: false });
  const [allVideo, setAllVideos] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [apThemeFilter, setApThemeFilter] = useState([]);
  const [ibThemeFilter, setIbThemeFilter] = useState([]);
  const [anecotasThemeFilter, setAnecotasThemeFilter] = useState([]);
  const [loader, setLoader] = useState('');
  const [mostUsed, setMostUsed] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchFavoritesList, setFetchFavorites] = useState(allFavorites);
  const [isFetchDataComplete, setIsFetchDataComplete] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isFetchDataComplete) {
      const createFavourite = async () => {
        await dispatch(handleCreateFavouriteVideo(fetchFavoritesList));
      }
      createFavourite();
    }
  }, [isFetchDataComplete, fetchFavoritesList])

  const { firestore } = useFirebase()

  const sortResources = (res) => {
    return map(res, (re) => {
      const newVideo = filter(re.videos, ["new", true]);
      const oldVideo = filter(re.videos, (k) => !k.new);
      return { ...re, videos: [...newVideo, ...oldVideo] };
    });
  };
  useEffect(() => {
    if (!isEmpty(resources)) {
      setResourceData(sortResources(resources));
      const allresourcesCategories = Array.from(
        new Set(
          flatMap(map(resources, (k) => k.videos)).map((k) => k.categorias)
        )
      ).filter((k) => k);
      if (allresourcesCategories.length == 0) {
        allresourcesCategories.push(...Categorias);
      }
      allresourcesCategories.push("All");
      setCategorais(allresourcesCategories);
    }
  }, [resources]);

  const filterByCategory = (cat) => {
    if (cat == "All") {
      setResourceData(sortResources(resources));
      // setKeyProductos(resources[0].key);
    } else {
      // const resourceCateFilter = sortResources(
      //   map(resources, (re) => ({
      //     ...re,
      //     videos: filter(re.videos, ["categorias", cat]),
      //   })).filter((k) => k.videos.length > 0)
      // );
      // setKeyProductos(resourceCateFilter[0].key);
      // setResourceData(resourceCateFilter);
    }
  };

  const searchByTagOrCategoary = (txt) => {
    setSearchQuery(txt);
    if (!txt || txt.length < 0) {
      setResourceData(sortResources(resources));
      // setKeyProductos(resources[0].key);
    } else {
      // const resourceCateFilter = sortResources(
      //   map(resources, (re) => ({
      //     ...re,
      //     videos: filterWithTextVideos(re.videos, txt),
      //   })).filter((k) => k.videos.length > 0)
      // );
      // setKeyProductos(get(resourceCateFilter[0], "key", ""));
      // setResourceData(resourceCateFilter);
    }
  };

  const isReviewAccount = currentUser?.reviewAccount || false;
  const [resourceView, setResourceView] = useState([]);

  const isProtectedContent = useCallback(
    (i) => {
      if (demoStatus && i >= 1) {
        return true;
      } else if (isReviewAccount && i >= 6) {
        return true;
      } else {
        return false;
      }
    },
    [demoStatus, isReviewAccount]
  );
  useEffect(() => {
    if (
      orgPermissions &&
      typeof orgPermissions["1WAYRESOURCES"] === "boolean"
    ) {
      if (!orgPermissions["1WAYRESOURCES"]) {
        invokeAlert();
        setTimeout(() => {
          history.goBack();
          revokeALert();
        }, 4000);
      }
    }
  }, [orgPermissions]);
  const fetchData = async () => {
    const forUserLoaded = localStorage.getItem("forUserResources");
    if (forUserLoaded == currentUser?.id || forUserLoaded == "nouser") {
      setLoading(false);
    } else {
      if (Array.isArray(resources) && resources.length > 0) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    await Promise.allSettled([
      dispatch(handleListResources()),
      dispatch(handleListVideos()),
      fetchFavorites(),
      fetchMostUsedVideo()
    ])
    setLoading(false);
    localStorage.setItem("forUserResources", currentUser?.id ?? "nouser");
    setIsFetchDataComplete(true);
  };

  const fetchMostUsedVideo = async () => {
    const { chapter } = await dispatch(handleListMostUsedVideo());
    setMostUsed(chapter)
  }

  const fetchFavorites = async () => {
    const data = {
      user_id: currentUser.id,
      type: 'video'
    }
    const result = await dispatch(handleListFavouriteVideo(data));
    setFetchFavorites(result);
  }

  const handleClose = () => {
    setState({ open: false });
  };
  const showCopyRight = () => {
    setState({ open: true });
  };
  const onCoverCLick = (key) => {
    if (key == "graphic_organizers") {
      history.push("/graphic_organizers");
    } else if (key == "worksheets") {
      history.push("/newsletter?id=N823aa3cc210843d3a6469815f2534e5d&page=1");
    } else {
      setKeyProductos(key);
    }
  };

  useEffect(() => {
    fetchVideoData();
  }, [resourceData])

  const fetchVideoData = async () => {
    const videos = resourceData.reduce((acc, item) => {
      return acc.concat(item.videos);
    }, []);
    setAllVideos(videos)
  }

  const getFilteredResult = (result) => {
    const matchThemes = (dataThemes, filterThemes) => {
      if (!dataThemes || dataThemes.length === 0 || filterThemes.length === 0) return false;
      const themes = dataThemes.toLowerCase().split(',').map(theme => theme.trim());
      return filterThemes.some(filter =>
        themes.some(theme => theme.includes(filter.toLowerCase()))
      );
    };

    let filteredResults = new Set();

    if (searchQuery !== "" && searchQuery?.length > 0) {
      result.filter(data => {
        if ((data?.categorias?.toLowerCase().includes(searchQuery?.toLowerCase()))
          || (data?.tags?.toLowerCase().includes(searchQuery?.toLowerCase()))
        ) {
          return true
        }
        else if ((data?.resource?.toLowerCase().includes(searchQuery?.toLowerCase()))
          || (data?.displayName?.toLowerCase().includes(searchQuery?.toLowerCase()))
        ) {
          return true
        }
        return false
      })
        .forEach(item => filteredResults.add(item));
    }

    if (selectedCategoray !== "" && selectedCategoray?.toLowerCase() !== "all") {
      result.filter(data => data.categorias?.toLowerCase().includes(selectedCategoray?.toLowerCase()))
        .forEach(item => filteredResults.add(item));
    }

    if (anecotasThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.anécdotas_themes, anecotasThemeFilter))
        .forEach(item => filteredResults.add(item));
    }

    if (ibThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ib_themes, ibThemeFilter))
        .forEach(item => filteredResults.add(item));
    }

    if (apThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ap_themes, apThemeFilter))
        .forEach(item => filteredResults.add(item));
    }

    // If no filters are active, return all results
    if (filteredResults.size === 0 &&
      anecotasThemeFilter.length === 0 &&
      ibThemeFilter.length === 0 &&
      apThemeFilter.length === 0 &&
      (selectedCategoray.length === 0 || selectedCategoray.toLowerCase() === "all") &&
      searchQuery?.length === 0
    ) {
      return result;
    }

    return Array.from(filteredResults);
  };

  const getVideosBYKey = useCallback(() => {
    const result = get(find(resourceData, ["key", keyProductos]), "videos", []);
    if (isSelectedFilter === "viewed") {
      const filteredResult = allVideo.filter(item =>
        resourceView.some(viewItem => viewItem.id === item.id)
      ).map(item => {
        // Find the corresponding resourceview item
        const viewItem = resourceView.find(view => view.id === item.id)
        // Add the viewed_count to the item
        return { ...item, viewed_video: viewItem ? viewItem.viewed_video : 0 };
      }).sort((a, b) => b.viewed_video - a.viewed_video)

      return getFilteredResult(filteredResult);
    } else if (isSelectedFilter === 'category') { // it's for favorite video
      return getFilteredResult(fetchFavoritesList?.videos);
    } else if (isSelectedFilter === 'used') {
      const filterUsed = filter(result, (obj) => obj?.id === mostUsed)
      return getFilteredResult(filterUsed);
    }

    if (searchQuery.length > 0) {
      return getFilteredResult(allVideo);
    }

    if (ibThemeFilter.length > 0 || apThemeFilter.length > 0 || anecotasThemeFilter.length > 0 || (selectedCategoray?.length > 0 && selectedCategoray?.toLowerCase() !== "all")) {
      return getFilteredResult(result);
    }
    return getFilteredResult(result);


  }, [keyProductos, searchQuery, selectedCategoray, resourceData, isSelectedFilter, resourceView, ibThemeFilter, apThemeFilter, anecotasThemeFilter, allVideo]);

  const getFImage = useCallback(() => {
    switch (keyProductos) {
      case "avina":
        return ResourceSymbol.avina;
      case "herramientas":
        return ResourceSymbol.herramientas;
      case "matias":
        return ResourceSymbol.matias;
      case "podcast":
        return ResourceSymbol.americaPodcast;
      case "yuli":
        return ResourceSymbol.ruzia;
      default:
        return ResourceSymbol.matias;
    }
  }, [keyProductos]);

  const clearAllFilter = () => {
    setSelectedCatogray("");
    setIbThemeFilter([]);
    setApThemeFilter([]);
    setAnecotasThemeFilter([]);
  }


  const dropDownHandler = (action, value) => {
    if (action === "category") {
      filterByCategory(value);
      setSelectedCatogray(value);
      setIsDropdownOpen(false);
    }
    else if (action === "ibThemeFitler") {
      setIbThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    } else if (action === "apThemeFilter") {
      setApThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    }
    else if (action === "anecotasThemeFilter") {
      setAnecotasThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    }

  }

  const { open } = state;

  const handleDropdownClick = useCallback((value) => {
    setIsDropdownOpen(prev => ({
      type: value,
      open: prev.type !== value || !prev.open
    }));
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = '#0B1C2B';
    document.body.classList.add("overflow-x-hidden");

    return () => {
      document.body.classList.remove("overflow-x-hidden");
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    let unsubscribe = () => { };

    listenToMostViewed("resource").then(unsubscribeFunc => {
      unsubscribe = unsubscribeFunc;
    });

    return () => unsubscribe();
  }, []);

  const listenToMostViewed = (type) => {
    return new Promise((resolve) => {
      const collectionRef = collection(firestore, "most_viewed_video");
      const q = query(collectionRef, where("type", "==", type), orderBy("viewed_video", "desc"));

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const viewData = [];
          querySnapshot.forEach((doc) => {
            viewData.push({ id: doc.id, ...doc.data() });
          });
          const sortedData = viewData.sort((a, b) => b.viewed_video - a.viewed_video);
          setResourceView(sortedData);
        },
        (error) => {
          console.error("Error listening to session changes:", error);
        }
      );

      resolve(unsubscribe);
    });
  };


  const handleFavouriteVideo = async (data) => {
    const isBookInFavorites = fetchFavoritesList?.videos?.some(video => video.id === data.id);
    const updatedVideos = isBookInFavorites
      ? fetchFavoritesList.videos.filter(video => video.id !== data.id)
      : [...(fetchFavoritesList?.videos || []), data];
    let updatedData;
    if (fetchFavoritesList?.id) {
      updatedData = {
        ...fetchFavoritesList,
        videos: updatedVideos,
      };
    } else {
      updatedData = {
        user_id: currentUser.id,
        videos: updatedVideos,
        type: 'video'
      };
    }
    setLoader(data?.id);
    setFetchFavorites(updatedData)
    // await dispatch(handleCreateFavouriteVideo(updatedData));
    setLoader('');
  }

  return (
    <Wrapper
      onContextMenu={(e) => {
        e.preventDefault();
        showCopyRight();
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          <strong>ALERT:</strong> Content is protected!
        </Alert>
      </Snackbar>
      {loading ? (
        <CenterContainer>
          <Loading />
        </CenterContainer>
      ) : (
        <div className="bg-resource-page">

          <div className="pt-20px image-search-container">
            <CategoryMenu
              className="md-mb-28px w-147px search-bar box-shadow-resource-hover h-43px lg-h-36px lg-w-unset"
              style={{ border: "1px solid #ffffff", paddingRight: 0 }}
            >
              <SearchInput
                className="search-input"
                type={"text"}
                placeholder="SEARCH"
                onChange={(e) => {
                  searchByTagOrCategoary(e.target.value);
                }}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "SEARCH")}
              />
              <div className="dropdown-resource-arrow-container">
                <div
                  style={{
                    transition: "transform 0.4s ease-in-out",
                  }}
                >
                  <img
                    // className="-mt-5px"
                    src={getFireBaseUrl("search-icon-placeholder.svg")}
                    alt=""
                  />
                </div>
              </div>
              {/* <MenuIconImg src={SearchIcon} />{" "} */}
            </CategoryMenu>

            <div className="flex flex-col md-column-reverse md-margin-0">
              <Row className="md-w-unset lg-grid lg-grid-four-column md-d-grid md-two-row-template one-way-resource-container pi-0 grid-4-row-template gap-55px lg-gap-31px pi-0">
                {map(resourceData, (portada) => (
                  <div
                    key={portada.key}
                    className={`${portada.key === keyProductos ? "resourceSelect" : ""
                      } md-flex-1 grid-item `}
                  >
                    <CoversCard
                      data={portada}
                      handleClick={() => {
                        clearAllFilter();
                        setIsSelectedFilter("");
                        onCoverCLick(portada.key);
                      }}
                    />
                  </div>
                ))}
              </Row>

              <Row className="">
                <Row className="dropdown-library-center resourceDropDown gap-37px wrap  md-wrap " justify={"start"}>

                  <Row className="all-dropdown-mobile-view gap-37px lg-gap-20px md-wrap nowrap lg-gap-26px">

                    <div className="md-w-full-2" style={{ position: "relative" }}>
                      {/* start dropdown */}
                      <DropdownMenuForAnc type={"category"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={selectedCategoray} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "category" &&
                        isDropdownOpen?.open} data={categorias} />
                    </div>

                    {/* mob ib dropdown */}
                    {isMobile <= 768 && (
                      <div className="md-w-full-2  md-d-block d-none" style={{ position: "relative" }}>
                        <DropdownMenuForAnc type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                          closeDropDown={() => handleDropdownClick("")} themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ibtheme" &&
                            isDropdownOpen?.open} data={IBTHEME} />
                      </div>
                    )}

                    <div className="md-w-full-2" style={{ position: "relative" }}>
                      <DropdownMenuForAnc type={"ANéCDOTAS THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={anecotasThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ANéCDOTAS THEMES" &&
                        isDropdownOpen?.open} data={AnecdotasThemes} isResizingWindow={isMobile}
                      />
                    </div>
                    <div className="md-w-full-2" style={{ position: "relative" }}>
                      <DropdownMenuForAnc type={"AP THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={apThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "AP THEMES" &&
                        isDropdownOpen?.open} data={APTHEME} />
                    </div>

                    {/* laptop ib dropdown */}
                    {isMobile >= 768 && (
                      <div className="md-w-full-2 md-d-none d-block" style={{ position: "relative" }}>
                        <DropdownMenuForAnc type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                          closeDropDown={() => handleDropdownClick("")} themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ibtheme" &&
                            isDropdownOpen?.open} data={IBTHEME} />
                      </div>
                    )}


                    {/* End dropdown */}
                  </Row>


                  <Row style={{ margin: 0 }} className="md-d-none  flex gap-28px lg-gap-19px boxes-parent lg-ml-0px nowrap">
                    {isSelectedFilter === "category" ? (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                          clearAllFilter();
                          setIsSelectedFilter("");
                          fetchVideoData()
                        }}
                        className="w-197px md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                      >
                        <div className="back-text-resource">Back</div>
                        <img
                          width={33}
                          src={BackIcon}
                          alt="heart"
                          className="w-30px h-25px md-h-20 md-w-18px"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          clearAllFilter();
                          setIsSelectedFilter("category")
                          setAllVideos(fetchFavoritesList?.videos || [])
                        }}
                        className="md-w-104px  h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                      >
                        <img src={HeartIcon} alt="heart" className="mr-6px md-h-20 md-w-18px w-30px h-25px" />
                        <div className="my-library-font">MIS FAVORITOS</div>
                      </div>
                    )}
                    {isSelectedFilter === "used" ? (
                      <div
                        onClick={(event) => {
                          clearAllFilter();
                          event.stopPropagation();
                          setIsSelectedFilter("");
                        }}
                        className="w-197px  md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                      >
                        <div className="back-text-resource">Back</div>
                        <img
                          width={33}
                          src={BackIcon}
                          alt="heart"
                          className="w-30px h-25px md-h-20 md-w-18px"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          clearAllFilter();
                          setIsSelectedFilter("used");
                        }}
                        className="md-h-20 md-w-18px md-w-104px h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                      >
                        <img src={TaskIcon} alt="task" className="mr-6px md-h-20 md-w-18px w-30px h-25px" />
                        <div className="my-library-font">Más UTILIZADOS</div>
                      </div>
                    )}
                    {isSelectedFilter === "viewed" ? (
                      <div
                        onClick={(event) => {
                          clearAllFilter();
                          event.stopPropagation();
                          setIsSelectedFilter("");
                        }}
                        className="w-219px md-w-104px cursor-pointer justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                      >
                        <div className="back-text-resource">Back</div>
                        <img
                          width={33}
                          src={BackIcon}
                          alt="heart"
                          className="w-30px h-25px md-h-20 md-w-18px"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          clearAllFilter();
                          fetchVideoData()
                          setIsSelectedFilter("viewed");
                        }}
                        className=" h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-16px pl-24px"
                      >
                        <img
                          src={ChampionIcon}
                          alt="champion"
                          className="mr-6px md-h-20 md-w-18px w-30px h-25px"
                        />
                        <div className="my-library-font">
                          más VISTOS EN EL PAíS
                        </div>

                      </div>
                    )}
                  </Row>

                </Row>


                {/* laptop my library */}


              </Row>

            </div>

            {/* mobile my library */}
            <Row style={{ margin: 0 }} className="md-d-flex d-none gap-28px lg-gap-19px boxes-parent lg-ml-0px nowrap">
              {isSelectedFilter === "category" ? (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    clearAllFilter();
                    setIsSelectedFilter("");
                    fetchVideoData()
                  }}
                  className="w-197px md-br-8px md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                >
                  <div className="back-text-resource">Back</div>
                  <img
                    width={33}
                    src={BackIcon}
                    alt="heart"
                    className="w-30px h-25px md-h-20 md-w-18px"
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    clearAllFilter();
                    setIsSelectedFilter("category")
                    setAllVideos(fetchFavoritesList?.videos || [])
                  }}
                  className="w-197px md-br-8px md-w-104px justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                >
                  <div className="my-library-font">MIS FAVORITOS</div>
                  <img src={HeartIcon} alt="heart" className="md-h-20 md-w-18px w-30px h-25px" />
                </div>
              )}
              {isSelectedFilter === "used" ? (
                <div
                  onClick={(event) => {
                    clearAllFilter();
                    event.stopPropagation();
                    setIsSelectedFilter("");
                  }}
                  className="w-197px md-br-8px  md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                >
                  <div className="back-text-resource">Back</div>
                  <img
                    width={33}
                    src={BackIcon}
                    alt="heart"
                    className="w-30px h-25px md-h-20 md-w-18px"
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    clearAllFilter();
                    setIsSelectedFilter("used");
                  }}
                  className="w-197px md-br-8px  md-h-20 md-w-18px md-w-104px justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                >
                  <div className="my-library-font">Más UTILIZADOS</div>
                  <img src={TaskIcon} alt="task" className="md-h-20 md-w-18px w-30px h-25px" />
                </div>
              )}
              {isSelectedFilter === "viewed" ? (
                <div
                  onClick={(event) => {
                    clearAllFilter();
                    event.stopPropagation();
                    setIsSelectedFilter("");
                  }}
                  className="w-197px md-br-8px md-w-104px cursor-pointer justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                >
                  <div className="back-text-resource">Back</div>
                  <img
                    width={33}
                    src={BackIcon}
                    alt="heart"
                    className="w-30px h-25px md-h-20 md-w-18px"
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    clearAllFilter();
                    fetchVideoData()
                    setIsSelectedFilter("viewed");
                  }}
                  className="w-197px md-br-8px md-w-104px justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                >
                  <div className="my-library-font">
                    más VISTOS EN EL PAíS
                  </div>
                  <img
                    src={ChampionIcon}
                    alt="champion"
                    className="md-h-20 md-w-18px w-30px h-25px"
                  />
                </div>
              )}
            </Row>

            {/* <p className="md-d-none d-block my-library-text">Mi biblioteca</p> */}

          </div>

          <Row className="resourcList">
            {/* <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 14 }}
              className="resorceListing"
            >
              <List>
                {map(resourceData, (portada) => (
                  <ListItem
                    key={portada.key}
                    className={`${
                      portada.key == keyProductos
                        ? "card-resource-selected"
                        : ""
                    }`}
                  >
                    <CoversCard
                      data={portada}
                      handleClick={() => onCoverCLick(portada.key)}
                    />
                  </ListItem>
                ))}
              </List>
            </Col> */}
            {/* <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }}>
              <div className="resorceImg">
                <img src={getFImage().bg} className="resorceImgBg" />
                {!!getFImage().img1 && (
                  <img
                    src={getFImage().img1}
                    className="resorceImgUpr"
                    loader={<Loading />}
                  />
                )}
              </div>
            </Col> */}
          </Row>
          <VideoSectionWrapper>
            <VideoSection
              Items={getVideosBYKey()}
              keyProductos={keyProductos}
              loader={loader}
              allFavorites={fetchFavoritesList?.videos}
              isProtectedContent={isProtectedContent}
              handleFavouriteVideo={handleFavouriteVideo}
            />
          </VideoSectionWrapper>
        </div>
      )}
    </Wrapper>
  );
};

// const OnewWayResource = () => {
//   return (
// <Resources2 />
//   );
// };

//  -- Prop types ---------------------
ResourcesContainer.propTypes = {};

//  -- Default props ------------------
ResourcesContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentClass: state.currentClass,
  currentUser: state.currentUser,
  userBooksList: state.userBooksList,
  tipsBook: state.tipsBook,
});

export default connect(mapStateToProps, null)(ResourcesContainer);
