import { DeleteOutlined, EditOutlined, FullscreenOutlined } from "@ant-design/icons";
import { CircularProgress } from "@material-ui/core";
import { Button, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useCheckList, useTraffic } from "store/wheel/selectors";
import styled from "styled-components";
import Draggable from 'react-draggable';
import { TextField } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { filter, map, find } from 'lodash';
import { useDispatch } from "react-redux";
import { useCurrentUser } from "store/users/selectors";
import { invokeCheckListView, revokeTrafficModal } from "store/wheel/actions";
import COLOR from "utils/constants/color";
import CheckListEmoji from "../containers/chat/checklistEmoji";
import { useOneWayWidgets } from "store/widgets/selectors";
import { handleCreateWidgets, handleDeleteWidgets } from "store/widgets/thunks";
import { useUserClasses } from "store/userClasses/selectors";
import { MultiSelecter } from "utils/methods/MultiSelector";
import { getFormattedTime } from "utils/methods/math";
import { v4 } from 'uuid';
import { Close } from "@material-ui/icons";
import { TransparentLogoWhite } from "utils/assets/images";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 800px;
    min-height: 550px;
    background-color: rgb(58,58,58);
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    z-index: 1;
    position: fixed;
    cursor: default;
    left: 25%;
    border-radius: 13px;
    z-index: 99;
`;
const TimeInputContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TimeSelect = styled.select`
  border: none;
  background: transparent;
  font-size: inherit;
//   margin: 0 5px; /* Adjust margin to control space between elements */
  width: 48px; /* Adjust width of each dropdown as needed */
`;

const TimeSeparator = styled.span`
  margin: 0 5px;
`;

const TimerContainer = styled.div`
  gap:22px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  padding: 20px;
  @media (max-width:1366px){
    flex-direction:column;
  }
`;

const LogoTopRow = styled.div``;
const Logo = styled.img`
  height: auto;
  width: 160px;
`;

const CloseBtn = styled.div`
  background-color: ${COLOR.PinkExam};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 1;
`;

const EmojiBox = styled.div`
    width: 2vw;
    font-size:30px;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position:relative;
    z-index:1;
    @media (max-width: 992px) {
        width: initial;
        height: initial;
        margin-bottom: 0px;
    }
`;

const CheckListModal = ({ currentClass }) => {
    const dispatch = useDispatch();
    const currentUser = useCurrentUser()
    const [trafficModal] = useTraffic();
    const userClasses = useUserClasses()
    const dataCheckList = useOneWayWidgets()
    const [title, setTitle] = useState("");
    const [tasks, setTasks] = useState([]);
    const [isEdit, setIsEdit] = useState('');
    const [hour, setHour] = useState(getFormattedTime()?.hour || '12');
    const [minute, setMinute] = useState(getFormattedTime()?.minute || '00');
    const [period, setPeriod] = useState(getFormattedTime()?.period || 'AM');
    const [showAddTime, setShowAddTime] = useState(false);
    const [loaderDelete, setLoaderDelete] = useState(-1);
    const [loaderCreate, setLoaderCreate] = useState(false);
    const [combinedTime, setCombinedTime] = useState('');
    const [loaderAllCreate, setLoaderAllCreate] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [, invokeCheckList, removeCheckListModal] = useCheckList();
    const [isDraggable, setIsDraggable] = useState(true);
    const draggableRef = useRef(null);
    const [onFocusValue,setOnFocusValue] = useState(false)
    const filterByClass = filter(dataCheckList, (item) => item?.class_id ? item?.class_id?.includes(currentClass?.id) : item && item?.type === 'checklist');
    const trafficData = trafficModal?.widget_menu ?? [];
    const filterByWidget = find(trafficData, (item) => item?.menu === 'widget_checklist');

    useEffect(() => {
        if (isEdit && isEdit?.data?.list_type === "timeBased") {
            setShowAddTime(true);
        }
        else {
            setShowAddTime(false);
        }
    }, [isEdit])

    useEffect(() => {
        const formattedTime = `${hour}:${minute} ${period}`;
        setCombinedTime(formattedTime);
    }, [hour, minute, period]);

    const handleEmojiSelect = (emoji) => {
        console.log(emoji.native);

        // setShowEmojiSelector(false);
    };

    const handleTitle = (value) => {
        setTitle(value);
    };

    const handleAddTask = () => {
        setTasks([...tasks, { text: "", imageUrl: "", minutes: "1" }]);
    };

    const handleTaskChange = (index, text, key) => {
        const newTasks = [...tasks];
        newTasks[index][key] = text;
        setTasks(newTasks);
    };

    const handleDeleteTask = (index) => {
        const newTasks = [...tasks];
        newTasks.splice(index, 1);
        setTasks(newTasks);
    };

    const deleteList = async (deleteId) => {
        setLoaderDelete(deleteId);
        await dispatch(handleDeleteWidgets(deleteId))
        setLoaderDelete(-1);
        if(isEdit && isEdit?.id === deleteId){
            refreshAll();
        }
    }

    const handleAddTime = () => {
        setShowAddTime(!showAddTime);
    };
    function convertTo24Hour(time) {
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':');
        hours = parseInt(hours, 10);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes.length < 2 ? `0${minutes}` : minutes;
        return `${hours}:${minutes}`;
    }

    const handleCreate = async () => {
        if (isEdit?.id) {
            if (showAddTime) {
                tasks.forEach((task, index) => {
                    if (index === 0) {
                        task.startTime = convertTo24Hour(combinedTime);
                    } else {
                        const prevTaskEndTime = tasks[index - 1].endTime;
                        task.startTime = prevTaskEndTime;
                    }
                    const time24hr = convertTo24Hour(task.startTime);
                    const [hours, minutes] = time24hr.split(':').map(Number);
                    const totalMinutes = hours * 60 + minutes + parseInt(task.minutes);
                    const endHours = Math.floor(totalMinutes / 60) % 24;
                    const endMinutes = totalMinutes % 60;
                    task.endTime = `${endHours}:${endMinutes < 10 ? '0' : ''}${endMinutes}`;
                })
            }
            const updateData = {
                tasks,
                title: title,
                check_time: showAddTime ? combinedTime : null,
                list_type: showAddTime ? "timeBased" : "checkBox"
            }
            const updateNewCheckList = {
                ...isEdit,
                user_id: currentUser?.id,
                type: 'checklist',
                class_id: selectedUsers,
                data: updateData
            }
            await dispatch(handleCreateWidgets(updateNewCheckList))
        }
        else {

            if (showAddTime) {
                tasks.forEach((task, index) => {
                    if (index === 0) {
                        task.startTime = convertTo24Hour(combinedTime);
                    } else {
                        const prevTaskEndTime = tasks[index - 1].endTime;
                        task.startTime = prevTaskEndTime;
                    }
                    const time24hr = convertTo24Hour(task.startTime);
                    const [hours, minutes] = time24hr.split(':').map(Number);
                    const totalMinutes = hours * 60 + minutes + parseInt(task.minutes);
                    const endHours = Math.floor(totalMinutes / 60) % 24;
                    const endMinutes = totalMinutes % 60;
                    task.endTime = `${endHours}:${endMinutes < 10 ? '0' : ''}${endMinutes}`;
                })
            }

            tasks.forEach(element => {
                element._id = v4();
            })
            const newData = {
                tasks,
                title,
                check_time: showAddTime ? combinedTime : null,
                list_type: showAddTime ? "timeBased" : "checkBox"
            }
            const newCheckList = {
                user_id: currentUser?.id,
                type: 'checklist',
                class_id: selectedUsers,
                data: newData,
            }
            await dispatch(handleCreateWidgets(newCheckList))
        }
        refreshAll()
    };

    const refreshAll = () => {
        setTitle("");
        setTasks([]);
        setShowAddTime(false);
        setLoaderCreate(false);
        setLoaderAllCreate(false);
        setIsEdit("");
        setSelectedUsers([]);
    }

    const handleUpdate = (data) => {
        const updateData = {
            ...data
        }

        setIsEdit(updateData);
        setTasks(updateData?.data?.tasks)
        setTitle(updateData?.data?.title);
    }

    const handleHourChange = (event) => {
        setHour(event.target.value);
    };

    const handleMinuteChange = (event) => {
        setMinute(event.target.value);
    };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    const onCheckListView = async (data) => {
        await dispatch(invokeCheckListView(data));
        // onClose()
    }

    const onClose = async () => {
        await dispatch(revokeTrafficModal(filterByWidget?.id))
    };
    const isButtonDisabled = !title || tasks.length === 0 || selectedUsers?.length === 0;



    // const handleOnDragEnd = (result) => {
    //     if (!result.destination) return;

    //     // const items = Array.from(tasks);
    //     const newTask = [...tasks]
    //     // const [reorderedItem] = items.splice(result.source.index, 1);
    //     const [movedItem] = newTask.splice(result.source.index, 1);
    //     // items.splice(result.destination.index, 0, reorderedItem);
    //     newTask.splice(result.destination.index, 0, movedItem);

    //     // Update the state with the new order
    //     handleTaskChange(tasks);
    //   };



    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const newTaskList = Array.from(tasks);
        const [movedItem] = newTaskList.splice(result.source.index, 1);
        newTaskList.splice(result.destination.index, 0, movedItem);

        setTasks(newTaskList);
    };

    const handleTouch = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleTouchAddTime = (event, value) => {
        event.preventDefault();
        if (value === 'add_time') {
            handleAddTime()
        } else if (value === 'task') {
            handleAddTask()
        }
    }

    const handleTouchDeleteTask = (event, index) => {
        event.preventDefault();
        handleDeleteTask(index)
    }

    const handleTouchStart = (e) => {
        e.preventDefault();
    };

    const handleTouchEnd = (e) => {
        e.preventDefault();
    };





    const handleOutsideClick = (event) => {
        if (draggableRef.current && !draggableRef.current.contains(event.target)) {
            setIsDraggable(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleInsideClick = () => {
        setIsDraggable(false);
    };



    return (
        <>
            {filterByWidget?.view && filterByWidget?.menu === 'widget_checklist' &&
                // <div className="draggable-bounds-parent">

                    // <Draggable bounds="parent">
                    //     <Container className="allCheckListSEction wigetsContainer">
                    //         <div className='check-list-cancel'>
                    //             <CancelIcon
                    //                 onClick={() => onClose()}
                    //                 onTouchStart={handleTouch}
                    //                 style={{
                    //                     marginLeft: "5px",
                    //                     position: "absolute",
                    //                     right: 0,
                    //                     top: 0,
                    //                     fontSize: 30,
                    //                     color: "red",
                    //                     background: "white",
                    //                     borderRadius: 20,
                    //                     cursor: "pointer"
                    //                 }}
                    //             />
                    //         </div>
                    //         <TimerContainer className="allCheckListSEction">
                    //             <div className="mainCheckList mainCheckListFull">
                    //                 <div className="checkListTopSection">
                    //                     <div className="checkListFirstTxt">
                    //                         <span>Check List</span>
                    //                         <img
                    //                             src={require("../utils/assets/images/greenRight.png")}
                    //                             alt=""
                    //                         />
                    //                     </div>
                    //                     <div className="checkListSearch">
                    //                         <p>Title:</p>
                    //                         <input value={title} type="text" placeholder="" onChange={(e) => {
                    //                             handleTitle(e?.target?.value);
                    //                         }}
                    //                             onTouchStart={handleTouchStart}
                    //                             onTouchEnd={handleTouchEnd} />
                    //                         <div className="checkListAddBtn">
                    //                             <Button onClick={handleAddTime} onTouchStart={(e) => handleTouchAddTime(e, 'add_time')}>{!showAddTime ? 'Add time' : 'Checklist'}</Button>
                    //                         </div>
                    //                     </div>
                    //                     {showAddTime && (
                    //                         <div style={{ textAlign: "right", marginTop: '12px', marginBottom: '15px' }}>
                    //                             {/* <span className="circle"> <i class="fas fa-times-circle"></i></span> */}

                    //                             {/* You can also customize the icon with props */}
                    //                             <CancelIcon color="secondary" style={{ fontSize: 17, position: 'relative', top: 3 }} />
                    //                             <span style={{ fontWeight: 700 }}>Start Time: </span>
                    //                             <TimeInputContainer>
                    //                                 <TimeSelect value={hour} onChange={handleHourChange} className="timefont">
                    //                                     {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
                    //                                         <option key={hour} value={hour} >{hour}</option>
                    //                                     ))}
                    //                                 </TimeSelect>
                    //                                 <TimeSeparator>:</TimeSeparator>
                    //                                 <TimeSelect value={minute} onChange={handleMinuteChange} className="timefont">
                    //                                     {Array.from({ length: 60 }, (_, i) => i).map(minute => (
                    //                                         <option key={minute} value={minute < 10 ? `0${minute}` : `${minute}`}>{minute}</option>
                    //                                     ))}
                    //                                 </TimeSelect>
                    //                                 <TimeSeparator>:</TimeSeparator>
                    //                                 <TimeSelect value={period} onChange={handlePeriodChange} className="timefont">
                    //                                     <option value="AM">AM</option>
                    //                                     <option value="PM">PM</option>
                    //                                 </TimeSelect>
                    //                             </TimeInputContainer>
                    //                         </div>
                    //                     )}
                    //                     <div className="checkListAddTaskBtn">
                    //                         <Button onClick={handleAddTask} onTouchStart={(e) => handleTouchAddTime(e, 'task')}>Add Task</Button>
                    //                         <MultiSelecter
                    //                             options={userClasses}
                    //                             selectedUser={selectedUsers}
                    //                             onSelect={setSelectedUsers}
                    //                             titleName={'Select Classes'}
                    //                             selectTitle={'Classes'}
                    //                         />
                    //                     </div>
                    //                     <div className="checkListSCroll" style={{ position: 'relative',paddingRight:"10px", minHeight: tasks.length > 0  && showAddTime && "142px" }}>
                    //                         {tasks.map((task, index) => {
                    //                             return (
                    //                                 <div>
                    //                                     <div key={index} className="mainMidSection TxtAreaContext" style={{ marginTop: tasks.length > 0 ? 40 : 20 }}>
                    //                                         <div className="checkListMidSection">
                    //                                             {/* <FullscreenOutlined className="checkLictIcon" /> */}
                    //                                             <img
                    //                                                 src={require("../utils/assets/images/checkList_Drag.png")}
                    //                                                 alt=""
                    //                                             />
                    //                                             <EmojiBox>
                    //                                                 <CheckListEmoji imageUrl={task?.imageUrl} onEmojiClick={(val) => {
                    //                                                     handleTaskChange(index, val.native, 'imageUrl')
                    //                                                 }} />
                    //                                             </EmojiBox>
                    //                                         </div>
                    //                                         <div className="checkListMidSearch">
                    //                                             <input value={task.text} type="text" onChange={(e) => handleTaskChange(index, e.target.value, 'text')} />
                    //                                             <DeleteOutlined onClick={() => handleDeleteTask(index)} onTouchStart={(e) => handleTouchDeleteTask(e, index)} className="checkListDelete" />
                    //                                         </div>
                    //                                     </div>
                    //                                     {showAddTime && (
                    //                                         // <div style={{ textAlign: "right", marginTop: '5px' }}>
                    //                                         // <div style={{ marginTop: '5px',position: "relative",
                    //                                         // left: "320px" }}>
                    //                                         <div className="" style={{ position: "absolute", right: 92, marginTop: 10 }}>
                    //                                             <span style={{ fontWeight: 700 }}>Minutes: </span>
                    //                                             <span className="blogInputBoxe">
                    //                                                 <TextField
                    //                                                     id="weightag"
                    //                                                     defaultValue={1}
                    //                                                     variant="outlined"
                    //                                                     type="number"
                    //                                                     InputProps={{
                    //                                                         inputProps: {
                    //                                                             min: 1,
                    //                                                             max: 60,
                    //                                                             step: 1,
                    //                                                         }
                    //                                                     }}
                    //                                                     size="small"
                    //                                                     style={{ width: '30px' }}
                    //                                                     onChange={(e) => handleTaskChange(index, e.target.value, 'minutes')}
                    //                                                 />
                    //                                             </span>
                    //                                         </div>
                    //                                     )}
                    //                                 </div>
                    //                             )
                    //                         })}
                    //                     </div>
                    //                     {/* <MultiSelecter
                    //         options={userClasses}
                    //         selectedUser={selectedUsers}
                    //         onSelect={setSelectedUsers}
                    //         titleName={'Select Classes'}
                    //         selectTitle={'Classes'}
                    //     /> */}
                    //                     {isEdit ? (
                    //                         <div className="checklistLstBtn">
                    //                             {isEdit?.class_id && (
                    //                                 <Button loading={loaderCreate} disabled={isButtonDisabled} onClick={() => {
                    //                                     setLoaderCreate(true);
                    //                                     handleCreate("current");
                    //                                 }}
                    //                                 onTouchStart={(e) => {
                    //                                     e.preventDefault();
                    //                                     setLoaderCreate(true);
                    //                                     handleCreate("current");
                    //                                 }}>{'Update'}</Button>
                    //                             )}
                    //                             {/* {!isEdit?.class_id && (
                    //                 <Button loading={loaderAllCreate} disabled={isButtonDisabled} onClick={() => {
                    //                     setLoaderAllCreate(true);
                    //                     handleCreate("allClass");
                    //                 }}>{'Update for all my classes'}</Button>
                    //             )} */}
                    //                         </div>
                    //                     ) : (
                    //                         <div className="checklistLstBtn">
                    //                             <Button
                    //                                 loading={loaderCreate}
                    //                                 disabled={isButtonDisabled}
                    //                                 onClick={() => {
                    //                                     setLoaderCreate(true);
                    //                                     handleCreate("current");
                    //                                 }}
                    //                                 onTouchStart={(e) => {
                    //                                     e.preventDefault();
                    //                                     setLoaderCreate(true);
                    //                                     handleCreate("current");
                    //                                 }}>{'Create'}</Button>
                    //                             {/* <Button loading={loaderAllCreate} disabled={isButtonDisabled} onClick={() => {
                    //                 setLoaderAllCreate(true);
                    //                 handleCreate("allClass");
                    //             }}>{'Create for all my classes'}</Button> */}
                    //                         </div>
                    //                     )}
                    //                 </div>
                    //             </div>
                    //             {filterByClass?.length > 0 && (
                    //                 <div className="w-full">
                    //                     <div className="listViewWheel">
                    //                         {map(filterByClass, (obj) => {
                    //                             const checkData = obj?.data || {}
                    //                             return (
                    //                                 <div className="mainCheckList mainCheckListView">
                    //                                     <div className="checkListTopSection mainCheckListView">
                    //                                         <div className="checkListFirstTxt checkListContainer">
                    //                                             <div className="checkListCursor" onClick={() => {
                    //                                                 onCheckListView(obj);
                    //                                             }}
                    //                                                 onTouchStart={(e) => {
                    //                                                     e.preventDefault();
                    //                                                     onCheckListView(obj);
                    //                                                 }}>
                    //                                                 {checkData?.title && checkData?.title?.length > 10 ? (
                    //                                                     <Tooltip title={checkData?.title}>
                    //                                                         {checkData?.title?.slice(0, 10) + "..."}
                    //                                                     </Tooltip>
                    //                                                 ) : (
                    //                                                     <span>{checkData?.title}</span>
                    //                                                 )}
                    //                                             </div>
                    //                                             <div>
                    //                                                 <EditOutlined
                    //                                                     onClick={() => handleUpdate(obj)}
                    //                                                     onTouchStart={(e) => {
                    //                                                         e.preventDefault();
                    //                                                         handleUpdate(obj)
                    //                                                     }}
                    //                                                     className="checkListDelete"
                    //                                                     style={{ marginLeft: '5px' }}
                    //                                                 />
                    //                                                 {loaderDelete === obj?.id ? <CircularProgress style={{ width: 20, height: 20, marginLeft: 10 }} /> : <DeleteOutlined onClick={() => deleteList(obj?.id)} onTouchStart={(e) => {
                    //                                                     e.preventDefault();
                    //                                                     deleteList(obj?.id)
                    //                                                 }} className="checkListDelete" style={{ marginLeft: '5px' }} />}
                    //                                             </div>
                    //                                         </div>
                    //                                     </div>
                    //                                 </div>
                    //                             )
                    //                         })}

                    //                     </div>
                    //                     <div className="checklistLstBtn">
                    //                         <Button
                    //                             onClick={() => {
                    //                                 refreshAll()
                    //                             }}
                    //                             onTouchStart={(e) => {
                    //                                 e.preventDefault();
                    //                                 refreshAll()
                    //                             }}>{'Create new check list'}</Button>
                    //                     </div>
                    //                 </div>
                    //             )}
                    //         </TimerContainer>
                    //     </Container>
                    // </Draggable>


                    <Draggable bounds="parent" disabled={!isDraggable || onFocusValue}>
                    <Container
                        // className="allCheckListSEction wigetsContainer"
                        ref={draggableRef}
                        onMouseDown={handleInsideClick}
                        onTouchStart={handleInsideClick}
                        className={`allCheckListSEction wigetsContainer ${
                            !isDraggable  ? "widgetMobAndTab" : ""
                          }`}
                    >
                        <div className='check-list-cancel'>
                            <CancelIcon
                                onClick={() => onClose()}
                                style={{
                                    marginLeft: "5px",
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    fontSize: 30,
                                    color: "red",
                                    background: "white",
                                    borderRadius: 20,
                                    cursor: "pointer"
                                }}
                            />
                        </div>
                        <TimerContainer className="allCheckListSEction">
                            <div className="mainCheckList mainCheckListFull">
                                <div className="checkListTopSection">
                                    <div className="checkListFirstTxt">
                                        <span>Check List</span>
                                        <img src={require("../utils/assets/images/greenRight.png")} alt="" />
                                    </div>
                                    <div className="checkListSearch">
                                        <p>Title:</p>
                                        <input value={title} type="text" placeholder="" onChange={(e) => handleTitle(e?.target?.value)} />
                                        <div className="checkListAddBtn">
                                            <Button onClick={handleAddTime}>{!showAddTime ? 'Add time' : 'Checklist'}</Button>
                                        </div>
                                    </div>
                                    {showAddTime && (
                                        <div onMouseEnter={()=> setOnFocusValue(true)} onMouseLeave={()=> setOnFocusValue(false)} style={{ textAlign: "right", marginTop: '12px', marginBottom: '15px' }}>
                                            <CancelIcon color="secondary" style={{ fontSize: 17, position: 'relative', top: 3 }} />
                                            <span style={{ fontWeight: 700 }}>Start Time: </span>
                                            <TimeInputContainer>
                                                <TimeSelect value={hour} onChange={handleHourChange} className="timefont">
                                                    {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
                                                        <option key={hour} value={hour} >{hour}</option>
                                                    ))}
                                                </TimeSelect>
                                                <TimeSeparator>:</TimeSeparator>
                                                <TimeSelect value={minute} onChange={handleMinuteChange} className="timefont">
                                                    {Array.from({ length: 60 }, (_, i) => i).map(minute => (
                                                        <option key={minute} value={minute < 10 ? `0${minute}` : `${minute}`}>{minute}</option>
                                                    ))}
                                                </TimeSelect>
                                                <TimeSeparator>:</TimeSeparator>
                                                <TimeSelect value={period} onChange={handlePeriodChange} className="timefont">
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </TimeSelect>
                                            </TimeInputContainer>
                                        </div>
                                    )}
                                    <div className="checkListAddTaskBtn">
                                        <Button onClick={handleAddTask}>Add Task</Button>
                                        <MultiSelecter
                                            options={userClasses}
                                            selectedUser={selectedUsers}
                                            onSelect={setSelectedUsers}
                                            titleName={'Select Classes'}
                                            selectTitle={'Classes'}
                                        />
                                    </div>
                                    <div className="checkListSCroll" style={{ position: 'relative', paddingRight: "10px", minHeight: tasks.length > 0 && showAddTime && "142px" }}>
                                        {tasks.map((task, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="mainMidSection TxtAreaContext" style={{ marginTop: tasks.length > 0 ? 40 : 20 }}>
                                                        <div className="checkListMidSection">
                                                            <img src={require("../utils/assets/images/checkList_Drag.png")} alt="" />
                                                            <EmojiBox>
                                                                <CheckListEmoji imageUrl={task?.imageUrl} onEmojiClick={(val) => handleTaskChange(index, val.native, 'imageUrl')} />
                                                            </EmojiBox>
                                                        </div>
                                                        <div className="checkListMidSearch">
                                                            <input value={task.text} type="text" onChange={(e) => handleTaskChange(index, e.target.value, 'text')} />
                                                            <DeleteOutlined onClick={() => handleDeleteTask(index)} className="checkListDelete" />
                                                        </div>
                                                    </div>
                                                    {showAddTime && (
                                                        <div style={{ position: "absolute", right: 92, marginTop: 10 }}>
                                                            <span style={{ fontWeight: 700 }}>Minutes: </span>
                                                            <span className="blogInputBoxe">
                                                                <TextField
                                                                    id="weightag"
                                                                    defaultValue={1}
                                                                    variant="outlined"
                                                                    type="number"
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 1,
                                                                            max: 60,
                                                                            step: 1,
                                                                        }
                                                                    }}
                                                                    size="small"
                                                                    style={{ width: '30px' }}
                                                                    onChange={(e) => handleTaskChange(index, e.target.value, 'minutes')}
                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {isEdit ? (
                                        <div className="checklistLstBtn">
                                            {isEdit?.class_id && (
                                                <Button loading={loaderCreate} disabled={isButtonDisabled} onClick={() => {
                                                    setLoaderCreate(true);
                                                    handleCreate("current");
                                                }}>{'Update'}</Button>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="checklistLstBtn">
                                            <Button
                                                loading={loaderCreate}
                                                disabled={isButtonDisabled}
                                                onClick={() => {
                                                    setLoaderCreate(true);
                                                    handleCreate("current");
                                                }}>{'Create'}</Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {filterByClass?.length > 0 && (
                                <div className="w-full">
                                    <div className="listViewWheel">
                                        {map(filterByClass, (obj) => {
                                            const checkData = obj?.data || {}
                                            return (
                                                <div className="mainCheckList mainCheckListView" key={obj.id}>
                                                    <div className="checkListTopSection mainCheckListView">
                                                        <div className="checkListFirstTxt checkListContainer">
                                                            <div className="checkListCursor" onClick={() => {
                                                                onCheckListView(obj);
                                                            }}>
                                                                {checkData?.title && checkData?.title?.length > 10 ? (
                                                                    <Tooltip title={checkData?.title}>
                                                                        {checkData?.title?.slice(0, 10) + "..."}
                                                                    </Tooltip>
                                                                ) : (
                                                                    <span>{checkData?.title}</span>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <EditOutlined
                                                                    onClick={() => handleUpdate(obj)}
                                                                    className="checkListDelete"
                                                                    style={{ marginLeft: '5px' }}
                                                                />
                                                                {loaderDelete === obj?.id ? <CircularProgress style={{ width: 20, height: 20, marginLeft: 10 }} /> : <DeleteOutlined onClick={() => deleteList(obj?.id)} className="checkListDelete" style={{ marginLeft: '5px' }} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="checklistLstBtn">
                                        <Button onClick={refreshAll}>{'Create new check list'}</Button>
                                    </div>
                                </div>
                            )}
                        </TimerContainer>
                    </Container>
                </Draggable>


                // </div>
            }
        </>
    );
};

export default CheckListModal;