//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import styled from 'styled-components';

//  -- Components ---------------------
import { Help } from '@material-ui/icons';

//  -- Constants ----------------------
import COLOR from 'utils/constants/color';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Button = styled.button`
    background-color: transparent;
    border: none;
    color: #3D5F8F;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: ${p => !p.noPadding ? '4px 0px' : '0px'};
    text-transform: uppercase;
`;

const SectionDescription = styled.div`
    align-items: flex-start;
    background-color: ${COLOR.black900};
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: ${COLOR.black300};
    display: flex;
    flex-direction: column;
    font-size: 12px;
    height: ${p => p.open ? 'auto' : '0px'};
    margin: 16px 0px;
    overflow: hidden;
    padding: ${p => p.open ? '16px' : '0px'};
    width: 100%;
    visibility: hidden;
`;

const SectionHeader = styled.span`
    align-items: flex-end;
    // border-bottom: 1px dashed ${COLOR.black800};
    color: ${p=> p.textColor!=="" ? p.textColor:  COLOR.black300};
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight 600;
    justify-content: space-between;
    margin-bottom: ${p => !p.description && '16px'};
    padding-bottom: 4px;
    text-transform: uppercase;
    width: 100%;
     visibility: hidden;
`;

const Wrapper = styled.div`
    width: 100%;
    padding-top:20px;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  -- Description
//  ...
//
//  -- Props
//  ...
//
const Section = ({ children, description, header,color="" }) => {
    const [open, setOpen] = useState(false);

    return (
        <Wrapper style={{ paddingTop:'0px' }}>
            <SectionHeader description={description} textColor={color} >
                {header}
                {description && (
                    <Button style={{color: color && color}} noPadding onClick={() => setOpen(!open)} >
                        {open ? 'Hide' : 'Help'}
                        <Help style={{ fontSize: '14px', marginBottom: '-2px', marginLeft: '4px'}} />
                    </Button>
                )}
            </SectionHeader>
            {description && (
                <SectionDescription open={open}>
                    {description}
                </SectionDescription>
            )}
            {children}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
Section.propTypes = {};

//  -- Default props ------------------
Section.defaultProps = {};

export default Section;