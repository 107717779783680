//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
//  -- Components ---------------------
import { Col, Row } from 'antd';
import AssessmentCoversCard from 'components/AssessmentCover';
import Section from 'components/Section';
import { get, map, cloneDeep } from "lodash";
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import styled from 'styled-components';
//  -- Constants ----------------------
import { oneWayLessonsBalcon, oneWayLessonsComunidad, oneWayLessonsConectandome } from "utils/assets/images";
import COLOR from 'utils/constants/color';
//  -- Features -----------------------
import BalconCulturalSmDvc from './BalconCulturalSmDvc';
import ConectandomeSmDvc from './ConectandomeSmDvc';
import ComunidadSmDvc from './ComunidadSmDvc';
import { useLocation } from 'react-router-dom'; // If you're using React Router
import AnimatedText from 'components/AnimatedText';
import { AnecdotasThemes, APTHEME, CATEGORIAS, Categorias, getFireBaseUrl, IBTHEME } from 'utils/config/reference';
import DropdownMenu from 'components/DropDowns/DropdownMenu';
import HeartIcon from "utils/assets/images/heart-icon.svg";
import BackIcon from "utils/assets/images/back-icon-resource.svg";
import ChampionIcon from "utils/assets/images/champion.svg";
import TaskIcon from "utils/assets/images/task-icon.svg";
import { handleListMostUsedEbookById } from 'store/FavouriteVideo/thunks';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------


export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const ListItem = styled.li`
    margin: 0px 4% 32px;
    width: calc(21%);
    // padding-top:50px;
    @media only screen and (min-width: 250px) and (max-width: 600px) {
        margin: 0px 16px 32px;
        // width: calc(50% - 32px);
        width: calc(20% - 32px);
        // border: .2px solid grey;
    border-radius: 7px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (min-width: 600px) and (max-width: 1366px) {
        margin: 0px 16px 32px;
        // width: calc(50% - 32px);
        width: calc(31% - 32px);
        // border: .2px solid grey;
    border-radius: 7px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        // margin: 0px 16px 32px;
        margin: 0px 5px 32px;

        width: 100%;
    }
    @media only screen and (max-width: 600px) {
       
        width: 30%;

    }
`;
const ListItems = styled.p`
    font-size:32px;
    font-weight:600;
    text-align:center;

    @media only screen and (min-width: 250px) and (max-width: 600px) {
        padding-top: 6%;
        font-size: 11px;
    }

    // @media only screen and (min-width: 600px) and (max-width: 800px) {
    // font-size:25px;
    // }
    @media only screen and (min-width: 600px) and (max-width: 1366px) {
        padding-top: 6%;
        // font-size:25px;
        font-size:21px;
        }
`;
const List = styled.ul`
    height: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content:center;
`;

const StyledList = styled.div`
width: 100%; // Default width
height: auto;
align-items: flex-start;
display: flex;
flex-direction: row;
flex-wrap: wrap;
list-style-type: none;
justify-content: center;
// Media query to set width to 75% between 1000px and 1030px
//   @media only screen and (min-width: 1000px) and (max-width: 1030px) {
//     width: 75%;
//   }
`;

const CategoryMenu = styled.div`
    background-color: #0b1c2b;
border-radius: 30px;
color: #fff;
width: 300px;
height: 42px;
// margin-top: 20px;
display: flex;
align-items: center;
// justify-content: center;
// text-align: center;
color: #fff;
// font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
border: 1px solid #fff;
color: #fff;
padding-left: 15px;
// padding-right: 15px;
justify-content: space-between;
// margin: 20px 10px;
// margin: 20px 20px 20px 10px;

@media screen and (max-width: 1200px) {
  font-size: 14px;
}
@media screen and (max-width: 576px) {
  // margin: 15px 8px;
}


`;

const SearchInput = styled.input`
  // width: 100px;
// width: 80px;
height: 100%;
color: #fff;
border: none;
background: transparent;
outline: none;
// text-align: center;
// font-weight: 800;
::placeholder,
::-webkit-input-placeholder {
  color: ${COLOR.white};
}
::focus {
  outline: none;
}
@media only screen and (max-width: 360px) {
  width: 92px;
}
`;

const lessonData = [
  {
    "id": "1",
    "order": "1",
    "displayName": "",
    "exams": [
      {
        "id": 1,
        "src": `${oneWayLessonsBalcon}`,
        "type": 'balcon',
        "displayName": "Balcón cultural",
        key: "balcónCultural"
      },
      {
        "id": 2,
        "src": `${oneWayLessonsConectandome}`,
        "type": 'conectandome',
        "displayName": "Conectándome",
        "key": "conectandome"
      },
      {
        "id": 3,
        "src": `${oneWayLessonsComunidad}`,
        "type": 'comunidad',
        "displayName": "Comunidad",
        key: "comunidad"
      }
    ]
  }
]


const AssessmentContainer = ({ }) => {

  const history = useHistory();
  const orgPermissions = useCurrentOrgPermission()


  // const onCoverCLick = (obj) => {
  //     if (obj?.type == 'balcon') {
  //         let url = "/balconCultural"
  //         history.push(url);
  //     } else if (obj?.type == 'conectandome') {
  //         let url = "/Conectandome"
  //         history.push(url);
  //     } else {
  //         let url = "/comunidad"
  //         history.push(url);
  //     }
  // }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation(); // Gets the current location
  const [smallDeviceComponent, setSmallDeviceComponent] = useState(<BalconCulturalSmDvc />);
  const [objectType, setObjectType] = useState({ type: "balcon" });
  const dispatch = useDispatch();

  const [mostUsedVideo, setMostUsedVideo] = useState([]);
  const [apThemeFilter, setApThemeFilter] = useState([]);
  const [ibThemeFilter, setIbThemeFilter] = useState([]);
  const [anecotasThemeFilter, setAnecotasThemeFilter] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [isDropdownOpen, setIsDropdownOpen] = useState({ type: "", open: false });
  const [selectedCategoray, setSelectedCatogray] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSelectedFilter, setIsSelectedFilter] = useState("");


  const chapterIdLookup = useMemo(() => ({
    balcon: "33",
    conectandome: "49",
    default: "43"
  }), []);

  const chapterId = useMemo(() =>
    chapterIdLookup[objectType?.type] || chapterIdLookup.default,
    [objectType?.type, chapterIdLookup]
  );

  const fetchData = useCallback(async (chapterId, signal) => {
    try {
      const data = await dispatch(handleListMostUsedEbookById(chapterId, signal));
      if (data) {
        setMostUsedVideo([data]);
      }
      else {
        setMostUsedVideo([]);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Fetch error:', error);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchData(chapterId, abortController.signal);

    return () => abortController.abort();
  }, [chapterId, fetchData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const backto = queryParams.get('backto');

    // console.log(backto)
    // Set the component based on the value of 'backto'
    if (backto === 'Balcón cultural') {
      setObjectType({ type: "balcon" })
      setSmallDeviceComponent(<BalconCulturalSmDvc />);
    }
    if (backto === 'Conectándome') {
      setObjectType({ type: "conectandome" })
      setSmallDeviceComponent(<ConectandomeSmDvc />);
    }
    if (backto === 'Comunidad') {
      setObjectType({ type: "" })
      setSmallDeviceComponent(<ComunidadSmDvc />);
    }
  }, [location.search]);

  const onCoverCLick = (obj) => {
    if (windowWidth <= 1100) {
      setIsSelectedFilter("");
      setObjectType(obj);
      // let componentToRender;

      // if (obj?.type === 'balcon') {
      //   componentToRender = <BalconCulturalSmDvc />;
      // } else if (obj?.type === 'conectandome') {
      //   componentToRender = <ConectandomeSmDvc />;
      // } else {
      //   componentToRender = <ComunidadSmDvc />;
      // }

      // setSmallDeviceComponent(componentToRender);
    }
    // else if (windowWidth >= 1000 && windowWidth <= 1366) {

    //     let componentToRender;

    //     if (obj?.type === 'balcon') {
    //       componentToRender = <BalconCulturalSmDvc />;
    //     } else if (obj?.type === 'conectandome') {
    //       componentToRender = <ConectandomeSmDvc />;
    //     } else {
    //       componentToRender = <ComunidadSmDvc />;
    //     }

    //     setSmallDeviceComponent(componentToRender);
    // }
    else {

      if (obj?.type == 'balcon') {
        let url = "/balconCultural"
        history.push(url);
      } else if (obj?.type == 'conectandome') {
        let url = "/Conectandome"
        history.push(url);
      } else {
        let url = "/comunidad"
        history.push(url);
      }
    }
  };
  const lessions = useMemo(() => {
    if (orgPermissions) {
      let leseeionPlaner = cloneDeep(lessonData)
      return leseeionPlaner.map(exm => {
        exm.exams = exm.exams.filter(obj => {
          return get(orgPermissions, obj.key, true)
        })
        return exm;
      })
    } else {
      return lessonData;
    }
  }, [orgPermissions])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //   const StyledRow = styled.div`
  //   @media only screen and (min-width: 0px) and (max-width: 1030px) {
  //     display: block;
  //   }

  //   @media only screen and (min-width: 1031px) {
  //     display: none;
  //   }
  // `;

  useEffect(() => {
    document.body.classList.add("blue-bg-ebook");
    document.body.classList.add("overflow-x-hidden");

    return () => {
      document.body.classList.remove("overflow-x-hidden");
      document.body.classList.remove("blue-bg-ebook");
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const clearAllFilter = () => {
    setSelectedCatogray("");
    setIbThemeFilter([]);
    setApThemeFilter([]);
    setAnecotasThemeFilter([]);
  }

  const handleDropdownClick = useCallback((value) => {
    setIsDropdownOpen(prev => ({
      type: value,
      open: prev.type !== value || !prev.open
    }));
  }, []);

  const dropDownHandler = (action, value) => {
    if (action === "category") {
      setSelectedCatogray(value?.title);
      handleDropdownClick("")
    }
    if (action === "ibThemeFitler") {
      setIbThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    } else if (action === "apThemeFilter") {
      setApThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    }
    else if (action === "anecotasThemeFilter") {
      setAnecotasThemeFilter(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => item !== value)
        }
        return [...prev, value]
      })
    }
  }

 
  

  return (
    <Wrapper>
      <Row style={{ marginTop: '1%' }}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Section style={{ fontSize: '18px' }} >
            {(windowWidth <= 1100) && (
              <CategoryMenu
                className="md-mb-28px w-147px search-bar box-shadow-resource-hover h-43px lg-h-36px lg-w-unset"
                style={{ border: "1px solid #ffffff", paddingRight: 0 }}
              >
                <SearchInput
                  className="search-input"
                  style={{ width: "100%" }}
                  type={"text"}
                  value={searchQuery}
                  placeholder="SEARCH"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="dropdown-resource-arrow-container">
                  <div
                    style={{
                      transition: "transform 0.4s ease-in-out",
                    }}
                  >
                    <img
                      // className="-mt-5px"
                      src={getFireBaseUrl("search-icon-placeholder.svg")}
                      alt=""
                    />
                  </div>
                </div>
                {/* <MenuIconImg src={SearchIcon} />{" "} */}
              </CategoryMenu>
            )}

            <Row style={{ marginTop: '1%' }}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ fontSize: '18px' }}>
                <Section style={{ fontSize: '18px', color: "white" }}
                  description="Resources books"
                  header='Resources'
                  color="white"
                >
                  {lessions.map(exm => (
                    <StyledList className='lessionList'>
                      {map(exm.exams, (obj, i) => {
                        return (
                          <ListItem key={obj.id}>

                            {/* <div style={{ 
                                                            padding: '0.5%', 
                                                            '@media only screen and (min-width: 600px) and (max-width: 800px)': { 
                                                                padding: '0.5%', 
                                                            } 
                                                        }}> */}
                            <div>
                              {windowWidth > 1100 && <ListItems>
                                <AnimatedText text={obj.displayName} textColor="white" page='oneWayLesons' />
                              </ListItems>}

                              <AssessmentCoversCard data={obj} handleClick={() => onCoverCLick(obj)} labelBgColor={COLOR.redishpink} label={get(exm, "displayName")} showLabel={false} />
                            </div>
                            {/* <ListItems>{obj.displayName}</ListItems> */}
                            {windowWidth <= 1100 && <ListItems>
                              <AnimatedText fontSize={"11px"} text={obj.displayName} textColor="white" page='oneWayLesons' />
                            </ListItems>}
                          </ListItem>
                        )
                      })}
                    </StyledList>
                  ))}
                </Section>
              </Col>
            </Row>
            {/* <Row>{windowWidth <= 1031 && <BalconCultura/>}</Row> */}
            {/* <Row style={{ width: windowWidth >= 1000 && windowWidth <= 1030 ? 'max-content' : 'auto' }} >{windowWidth <= 1030 && <BalconCulturalSmDvc />}</Row>
                        <Row style={{ width: windowWidth <= 1030 ? '93vw' : 'auto' }} >{windowWidth <= 1030 && <ConectandomeSmDvc />}</Row>
                        <Row style={{ width: windowWidth <= 1030 ? '93vw' : 'auto' }} >{windowWidth <= 1030 && <ComunidadSmDvc />}</Row> */}
            {/* {windowWidth <= 1030 && smallDeviceComponent && ( */}
            {windowWidth <= 1100 && (
              <>
                <Row style={{ marginTop: "20px", marginBottom: "20px", justifyContent: "center" }} className="all-dropdown-mobile-view gap-37px lg-gap-20px md-wrap nowrap lg-gap-26px">

                  <div className="md-w-full-2" style={{ position: "relative" }}>
                    {/* start dropdown */}
                    <DropdownMenu type={"category"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={selectedCategoray} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "category" &&
                      isDropdownOpen?.open} data={CATEGORIAS} />
                  </div>

                  {/* mob ib dropdown */}
                  {isMobile <= 768 && (
                    <div className="md-w-full-2  md-d-block d-none" style={{ position: "relative" }}>
                      <DropdownMenu type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                        closeDropDown={() => handleDropdownClick("")} themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ibtheme" &&
                          isDropdownOpen?.open} data={IBTHEME} />
                    </div>
                  )}

                  <div className="md-w-full-2" style={{ position: "relative" }}>
                    <DropdownMenu type={"ANéCDOTAS THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={anecotasThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ANéCDOTAS THEMES" &&
                      isDropdownOpen?.open} data={AnecdotasThemes} isResizingWindow={isMobile}
                    />
                  </div>
                  <div className="md-w-full-2" style={{ position: "relative" }}>
                    <DropdownMenu type={"AP THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={apThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "AP THEMES" &&
                      isDropdownOpen?.open} data={APTHEME} />
                  </div>

                  {/* laptop ib dropdown */}
                  {isMobile >= 768 && (
                    <div className="md-w-full-2 md-d-none d-block" style={{ position: "relative" }}>
                      <DropdownMenu type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                        closeDropDown={() => handleDropdownClick("")} themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ibtheme" &&
                          isDropdownOpen?.open} data={IBTHEME} />
                    </div>
                  )}


                  {/* End dropdown */}
                </Row>
                <Row style={{ margin: 0, marginBottom: "20px", display: windowWidth <= 1100 ? "flex" : "none", justifyContent: "center" }} className="gap-28px lg-gap-19px boxes-parent lg-ml-0px nowrap">
                  {isSelectedFilter === "category" ? (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        clearAllFilter();
                        setIsSelectedFilter("");
                        // fetchVideoData()
                      }}
                      className="w-197px md-br-8px md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                    >
                      <div className="back-text-resource">Back</div>
                      <img
                        width={33}
                        src={BackIcon}
                        alt="heart"
                        className="w-30px h-25px md-h-20 md-w-18px"
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        clearAllFilter();
                        setIsSelectedFilter("category")
                        // setAllVideos(allFavorites?.videos||[])
                      }}
                      className="w-197px md-br-8px md-w-104px justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                    >
                      <div className="my-library-font">MIS FAVORITOS</div>
                      <img src={HeartIcon} alt="heart" className="md-h-20 md-w-18px w-30px h-25px" />
                    </div>
                  )}
                   {isSelectedFilter === "used" ? (
    <div
      onClick={(event) => {
        clearAllFilter();
        event.stopPropagation();
        setIsSelectedFilter("");
      }}
      className="w-197px md-br-8px  md-w-104px cursor-pointer justify-between h-43px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
    >
       <div className="back-text-resource">Back</div>
      <img
        width={33}
        src={BackIcon}
        alt="heart"
        className="w-30px h-25px md-h-20 md-w-18px"
      />
    </div>
  ) : (
    <div
      onClick={() => {
        clearAllFilter();
        setIsSelectedFilter("used");
      }}
      className="w-197px md-br-8px  md-h-20 md-w-18px md-w-104px justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
    >
      <div className="my-library-font">Más UTILIZADOS</div>
      <img src={TaskIcon} alt="task"  className="md-h-20 md-w-18px w-30px h-25px" />
    </div>
  )}
                  {isSelectedFilter === "viewed" ? (
                    <div
                      onClick={(event) => {
                        clearAllFilter();
                        event.stopPropagation();
                        setIsSelectedFilter("");
                      }}
                      className="w-197px md-br-8px md-w-104px cursor-pointer justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                    >
                      <div className="back-text-resource">Back</div>
                      <img
                        width={33}
                        src={BackIcon}
                        alt="heart"
                        className="w-30px h-25px md-h-20 md-w-18px"
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        clearAllFilter();
                        // fetchVideoData()
                        setIsSelectedFilter("viewed");
                      }}
                      className="w-197px md-br-8px md-w-104px justify-between h-45px br-14px box-shadow-resource-hover flex items-center bg-gradient-pink cursor-pointer pr-9px pl-12px"
                    >
                      <div className="my-library-font">
                        más VISTOS EN EL PAíS
                      </div>
                      <img
                        src={ChampionIcon}
                        alt="champion"
                        className="md-h-20 md-w-18px w-30px h-25px"
                      />
                    </div>
                  )}
                </Row>
              </>

            )}


            {windowWidth < 1000 && (

              <Row style={{ width: '94vw', paddingBottom: '4vh' }}>
                {(() => {
                  switch (objectType?.type) {
                    case "balcon":
                      return <BalconCulturalSmDvc mostUsedVideo={mostUsedVideo} isSelectedFilter={isSelectedFilter} anecotasThemeFilter={anecotasThemeFilter} searchQuery={searchQuery} apThemeFilter={apThemeFilter} ibThemeFilter={ibThemeFilter} selectedCategoray={selectedCategoray} />;
                    case "conectandome":
                      return <ConectandomeSmDvc mostUsedVideo={mostUsedVideo} isSelectedFilter={isSelectedFilter} anecotasThemeFilter={anecotasThemeFilter} searchQuery={searchQuery} apThemeFilter={apThemeFilter} ibThemeFilter={ibThemeFilter} selectedCategoray={selectedCategoray} />;
                    default:
                      return <ComunidadSmDvc mostUsedVideo={mostUsedVideo} isSelectedFilter={isSelectedFilter} anecotasThemeFilter={anecotasThemeFilter} searchQuery={searchQuery} apThemeFilter={apThemeFilter} ibThemeFilter={ibThemeFilter} selectedCategoray={selectedCategoray} />;
                  }
                })()}
                {/* {smallDeviceComponent} */}
              </Row>
            )}
            {(windowWidth >= 1000 && windowWidth <= 1100) && (

              <Row style={{ paddingBottom: '4vh' }}>
                {(() => {
                  switch (objectType?.type) {
                    case "balcon":
                      return <BalconCulturalSmDvc mostUsedVideo={mostUsedVideo} isSelectedFilter={isSelectedFilter} anecotasThemeFilter={anecotasThemeFilter} searchQuery={searchQuery} apThemeFilter={apThemeFilter} ibThemeFilter={ibThemeFilter} selectedCategoray={selectedCategoray} />;
                    case "conectandome":
                      return <ConectandomeSmDvc mostUsedVideo={mostUsedVideo} isSelectedFilter={isSelectedFilter} anecotasThemeFilter={anecotasThemeFilter} searchQuery={searchQuery} apThemeFilter={apThemeFilter} ibThemeFilter={ibThemeFilter} selectedCategoray={selectedCategoray} />;
                    default:
                      return <ComunidadSmDvc mostUsedVideo={mostUsedVideo} isSelectedFilter={isSelectedFilter} anecotasThemeFilter={anecotasThemeFilter} searchQuery={searchQuery} apThemeFilter={apThemeFilter} ibThemeFilter={ibThemeFilter} selectedCategoray={selectedCategoray} />;
                  }
                })()}
              </Row>
            )}



          </Section>
        </Col>
      </Row>

    </Wrapper>
  );
};

//  -- Prop types ---------------------
AssessmentContainer.propTypes = {};

//  -- Default props ------------------
AssessmentContainer.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
  currentUser: state.currentUser,
  userBooksList: state.userBooksList,
  classActivities: state.classActivities,
  classQuizzes: state.classQuizzes,
});

export default connect(mapStateToProps, null)(AssessmentContainer);
