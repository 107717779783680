import { Button, Modal } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Col, Row , Drawer } from 'antd';
import Loading from 'components/Loading';
import _, { filter, find, get, groupBy, map, uniqBy , has, findIndex  } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAllBook } from 'store/books/selectors';
import { useResources, useVideos } from 'store/resources/selectors';
import DescriptionIcon from '@material-ui/icons/Description';
import styled from 'styled-components';
import {
    datagrid_close,
    datagrid_logoColored,
    datagrid_searchIcon
} from "utils/assets/images";
import { getAssignmentLable, filterStoryClassQuizOrActivity } from 'utils/lib/ebookHelper';

import { handleListClassSubmissions , handleUpdateMetSubmission  } from 'store/submissions/thunks';
//  -- Thunks -------------------------
import { handleListActivities, handleListCustomAssignment } from 'store/activities/thunks';
import { handleListClassActivities , handleUpdateClassActivity } from 'store/classActivities/thunks';
import { handleListClassQuizzes , handleUpdateClassQuiz } from 'store/classQuizzes/thunks';
import { handleListQuizzes } from 'store/quizzes/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import COLOR from "utils/constants/color";
import { useStory, useStandards } from 'store/story/selectors';
import GradeTable from 'features/GradeTableStory';
import AssignmentReview from 'features/forms/AssignmentReview';
import { modifyAssignmentWithDiff } from 'utils/lib/ebookHelper';
import { useSchoologyAssignment  } from "store/schoologyauth/selectors"
import Schoology from 'utils/Schoology';
import SentryWrap from 'components/Sentry';
import ActivityForm from 'features/forms/Activity';
import QuizForm from 'features/forms/Quiz';
import { useUserPreference } from "store/userPreference/selectors"
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
const RESULT = {
    fail: "🔴",
    pass: "🟢"
}
const ModalContent = styled.div`
        align-items: center;
        background-color: #11ffee00;
        border-radius: 3px;
        display: inline-flex;
        flex-direction: column;
        left: 50%;
        padding: 16px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
    `;
const Search = styled.input`
    ::placeholder,
    ::-webkit-input-placeholder {
    color: ${COLOR.white};
    }
`;
const MessageContgainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${COLOR.white};
    border: 3px solid ${COLOR.indigo400};
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    overflow: auto;
    padding: 12px;
    min-height: fit-content;
    max-height: 80%;
    `
const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    `;
const NameItem = styled.div`
`;
const sortname = (a, b) => {
    const nameA = a?.lastName?.toLowerCase()?.replace(/\s/g, '');
    const nameB = b?.lastName?.toLowerCase()?.replace(/\s/g, '');;
    if (nameA < nameB) { return -1 }
    else if (nameA > nameB) { return 1 }
    else { return 0 }
}

const groupAssignment = (submissions) => {
    return groupBy(submissions, 'userId');

}


export const GridStoryLevel = ({
    classActivities,
    classQuizzes,
    currentClass,
    currentOrg,
    classUsers,
    submissions,
    currentUser,
    activities,
    quizzes,
    schoologyAuth
}) => {
    const [DataShow, setDataShow] = useState(false);
    const [listStudentUser, setListStudentUser] = useState([])
    const [filteredStudent, setFilteredStudent] = useState([])
    const standards = useStandards()
    const story = useStory()
    const userPreference = useUserPreference()
    const classQuizzesData = useMemo(() => filterStoryClassQuizOrActivity(classQuizzes, story), [classQuizzes, story])
    const classActivityData = useMemo(() => filterStoryClassQuizOrActivity(classActivities, story), [classActivities, story])
    const studentSubmissions = groupAssignment(submissions)
    const booksAllList = useAllBook()
    const videos = useVideos()
    const resources = useResources()
    const dispatch = useDispatch()
    const [dataLoading, setDataloading] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [DataShow2, setDataShow2] = useState(false);
    const [defaultColDef] = useState({ editable: false });
    const [assignmentData, setAssignmentData] = useState({});
    const [selectedAssignmentUserSubmission, setSelectedAssignmentSubmissions] = useState([])
    const [assignmentDrawer, toggleAssignmentDrawer] = useState(false);
    const[loading,setLoading] = useState(false);
    const [lateSection, setlateSection] = useState(false);
    const [durationSection, setDurationSection] = useState(false);
    const [activityData, setActivityData] = useState({});
    const [activityDrawer, toggleActivityDrawer] = useState(false);
    const [schoologyAssignment,listSchoologyAssignment] = useSchoologyAssignment()
    const [labelDescriptionView, setLabelDescriptionView] = useState({
        open: false,
        describe: ""
    })
    const client = new Schoology(schoologyAuth);
    const [quizDrawer, toggleQuizDrawer] = useState(false);
    const [quizData, setQuizData] = useState({});

    const stickyCellRenderer = (p) => {
        const value = p.value;
        const usernamename = ` ${get(value, "lastName", "")}, ${get(value, "firstName", "")}`;
        return (
            <div className="quesBtns">
                <a>
                    <NameItem style={{ width: "80%" }}>{!value ? 'No of questions' : usernamename}</NameItem>
                    {value && get(value, "id") ? <DescriptionIcon style={{ color: "#fff" }} onClick={() => { handleoverAllShow(value); }} /> : null}
                </a>
            </div>
        )
    }
    const getStandard = (abbr) => {
        const dt = get(find(standards, ["abbr", abbr]), "description")
        if (dt) return dt;
        return null
    }
    const modelCellRenderer = (p) => {
        const { value } = get(p.value, "submissionData", {})
        const numberOfQuestion = get(p.value, "numberOfQuestion")
        const level = get(p.value, "data.level")
        return (
            <div className="eyeShwDataUpr">
                <div className="eyeShwData">
                    <span>{numberOfQuestion >= 0 ? numberOfQuestion : (value >= 0 ? value : '-')}</span>
                    {level ? <VisibilityIcon onClick={() => { handleGradeValueShow(p.value); }} /> : null}
                </div>
                <p>{level}</p>
            </div>
        )
    }
    const QuestionHeader = () => {
        return (
            <div className="quesTxtIcon">
                <label><span>Question</span><QuestionAnswerIcon /></label>
            </div>
        )
    }

    const [columnDefs, setColsDefs] = useState([
        {
            headerName: "Name of the story:",
            children: [
                {
                    headerName: "Name of the story:",
                    children: [{
                        headerName: "Page Number:",
                        field: "Date Assigned:",
                        cellRenderer: stickyCellRenderer,
                        width: 210,
                        pinned: 'left',
                    }],
                },
            ],
        },

    ])

    const [rowData, setRowData] = useState([
        { user: {}, '-': '' }
    ]);

    const [assignmentRowData, setAssignmentRowData] = useState([
    ]);
    const StandardCellRender = (p) => {
        const value = p.value;
        const fullText = getStandard(value)
        return (
            <div className="quesTxtIcon">
                {fullText ? <Tooltip title={fullText}>
                    <label>
                        <span>{value}</span>
                        <InfoOutlined onClick={() => {
                            setLabelDescriptionView({ open: true, describe: fullText })
                        }} />
                    </label>
                </Tooltip> : <label>
                    <span>{value?value:''}</span>
                </label>}

            </div>
        )
    }
    const onMetChange = async(met,assignment,question_no  , standard)=>{
        const sub = get(assignment,"submissionData.submission")
        if(!sub) return;
        const answers = get(sub,"answers",[])
        if(!answers[question_no]) {
            answers[question_no] ={}
        }
        const alreadyMet =  answers[question_no]?.met || []; 
        if(alreadyMet){
            const indx = findIndex(alreadyMet,{standard})
            if(indx>=0){
                alreadyMet[indx] = {
                    standard,
                    met
                }
            answers[question_no].met = alreadyMet;
            }else{
                alreadyMet.push({
                    standard,
                    met
                })
                answers[question_no].met = alreadyMet;
            }
        }else{
            answers[question_no].met = [{
                standard,
                met
            }];
        }
        
        const submi = await dispatch(handleUpdateMetSubmission(
            {
                id:sub.id,
                answers: answers,
               
            }
        ));
        assignment.submissionData.submission = submi;
        dispatch(handleListClassSubmissions(get(currentClass, "id"),true))
    }
    const onchangeIcon = (id,isPass)=>{
        const  metref =  document.getElementById(id)
        if(metref){
            metref.innerHTML = isPass ? RESULT.pass:RESULT.fail
        }


    }
    const resultNave = (assignment,question_no  , standard ) =>{
         return( <Menu>
         {map(RESULT, (flg, i) => (
             <Menu.Item
               key={i}
               onClick={() => {
                onMetChange(i=="pass",assignment,question_no  , standard);
                onchangeIcon(assignment.id+"met_icon"+question_no,i=="pass")
               }}
             >
               <MenuItem>
                {flg}
               </MenuItem>
             </Menu.Item>
         ))}
       </Menu>)
    }
    const MetCellRender = (p) => {
        const value = p.value;
        if(typeof value !== "object"|| Object.keys(value).length==0) return <></>
        const { isCorrect , assignment , isOpenEnded , question_no  , standard , answer , hasSubmissions  , hasAnswered } = value
        const result_icon = hasSubmissions && hasAnswered ? (isCorrect?RESULT.pass:RESULT.fail):'-'
        const hasMet =  find(answer?.met,["standard",standard]);
        const isMet = get(hasMet,"met")
        const met_icon = Boolean(isMet)?RESULT.pass:RESULT.fail
        return (
            <div className="quesTxtIcon">
                {!isOpenEnded ? result_icon : <Dropdown disabled={!hasSubmissions} overlay={resultNave(assignment, question_no, standard)}
                    placement="bottomLeft" trigger={['click']}
                >
                    {hasSubmissions ? <span> <span id={assignment.id + "met_icon" + question_no}>{hasMet ? met_icon : "⚪️"} </span> <CaretDownOutlined style={{ fontSize: '10px', marginLeft: '6px' }} /></span> : <span>-</span>}
                </Dropdown>}
            </div>
        )
    }
    const [assignmentColDefs] = useState([
        { field: 'Question', width: 118, headerComponent: QuestionHeader },
        { field: 'Standard', width: 260, cellRenderer: StandardCellRender, },
        { field: 'Met/Fail', width: 90 , cellRenderer:MetCellRender },
        { field: 'Level/Assigned', width: 140 },
        { field: 'Average per question', width: 180 },
    ])

    const [assignmentOverAllRowData, setAssignmentOverAllRowData] = useState([

    ]);
    const [assignmentOverAllColDefs] = useState([
        { field: 'Standard', width: 230, cellRenderer: StandardCellRender, },
        { field: 'Met', width: 90 },
        { field: 'Fail', width: 90 },
        { field: 'Basico', width: 110 },
        { field: 'Intermedio', width: 110 },
        { field: 'Avanzado', width: 110 },
    ])

    const listCurrentClassInfo = async () => {
        if (get(currentClass, "id")) {
            dispatch(handleListClassUsers(get(currentClass, "id")));
            dispatch(handleListClassActivities(get(currentClass, "id")));
            dispatch(handleListClassQuizzes(get(currentClass, "id")));
            dispatch(handleListClassSubmissions(get(currentClass, "id")))
        }
    }
    const listAllAssignments = async () => {
        if (!Array.isArray(activities) || activities.length == 0) {
            await dispatch(handleListActivities());
        }

        if (!Array.isArray(quizzes) || quizzes.length == 0) {
            await dispatch(handleListQuizzes());
        }
        await dispatch(handleListCustomAssignment(get(currentUser, "role"), get(currentUser, "id")))
    }

    const fetchApis = async () => {
        setDataloading(true)
        await Promise.allSettled([
            listCurrentClassInfo(),
            listAllAssignments(),
        ])
        setDataloading(false)
    }
    useEffect(() => {
        fetchApis()
    }, [])

    useEffect(() => {
        const allstudents = _.uniqBy(filter(classUsers, x => x.role === 'student').sort(sortname), "username");
        let arr = new Array(1).fill({}, 0, 1)
        setListStudentUser(allstudents.length > 0 ? allstudents : arr)
        setFilteredStudent(allstudents.length > 0 ? allstudents : arr)

    }, [classUsers])


    const handleGradeValueShow = (assignment,subData) => {
        const orderedSemDt = [...classActivityData, ...classQuizzesData]
        const groupedSubmission = subData?subData:studentSubmissions
        const questions = get(assignment, "data.questions", [])
        const level = get(assignment, "data.level", "")
        const user = get(assignment, "user", {})
        const subm = find(groupedSubmission[user.id], ['assignmentId', get(assignment, "data.id")]);
        const smAnswer = get(subm, "answers", [])
        const rowData = []
        const standard = {
        }
        map(orderedSemDt, smDt => {
            const subm = find(groupedSubmission[user.id], ['assignmentId', get(smDt, "data.id")]);
            const smAnswer = get(subm, "answers", [])
            map(get(smDt, "data.questions", []), (q, i) => {
                // if(!subm) return ;
                const ans = smAnswer[i];
                const isCorrect = get(ans, "answer") == get(q, "answer");
                const std = q.standard?q.standard:''
                const allStd = std.split(",").filter(k=>k)
                map(allStd,st=>{
                    if (standard[st]) {
                        const prevAn = standard[st];
                        standard[st] = {
                            correct: isCorrect ? prevAn.correct + 1 : prevAn.correct,
                            total: prevAn.total + 1
                        }
                    } else {
                        standard[st] = {
                            correct: isCorrect ? 1 : 0,
                            total: 1
                        }
                    }
                })
                
            })
        });
       
        if (questions.length > 0) {
            map(questions, function (value, key) {
                // if(!subm) return;
                const isCorrect = get(smAnswer[key], "answer") == get(value, "answer")
                const standed  = get(value, "standard",'');
                const allStd = standed.split(",").filter(k=>k)
                map(allStd,std=>{
                    const st = standard[std]
                   const stdString = standard[std] ? `${st.correct} out of ${st.total}` : '';
                   rowData.push({ 'Question': key + 1, Standard: std , 'Met/Fail': { isCorrect, assignment , isOpenEnded:value.type!=='multipleChoice',standard:std , question_no :key, answer:smAnswer[key] , hasSubmissions:!!subm , hasAnswered:smAnswer[key]}, 'Level/Assigned': level, 'Average per question': stdString })
                })
            })
        }
        if (rowData.length < 10) {
            let len = 10 - rowData.length;
            let arr = new Array(len).fill({ 'Question': null, Standard: '', 'Met/Fail': {}, 'Level/Assigned': '', 'Average per question': '' }, 0, len)
            rowData.push(...arr)
        }
        setAssignmentRowData(rowData)
        setDataShow(true);
    };
    const handleoverAllShow = (user) => {
        const orderedSemDt = [...classActivityData, ...classQuizzesData];
        const standard = {};

        for (const smDt of orderedSemDt) {
            const subm = studentSubmissions[user.id]?.find(s => s.assignmentId === smDt.data.id);
            const smAnswer = subm?.answers || [];
            const questions = smDt.data.questions || [];

            questions.forEach((q, i) => {
                let isCorrect = false;
                const ans = smAnswer[i];
                const allStd = (q.standard ? q.standard.split(",").filter(k => k) : []);

                for (const st of allStd) {
                    isCorrect =  q.type !== 'multipleChoice'
                    ? ans?.met?.some(m => m.standard === st && m.met)
                    :  `${ans?.answer}` === `${q.answer}`;
                    const level = smDt.data.level || "";
                    const isOpenEnded = q.type !== 'multipleChoice';
                    const isGradedForOpenEnded =  ans?.met?.some(m => m.standard === st);
                    let total_to_add =  isOpenEnded ? (isGradedForOpenEnded ? 1 :0):1;
                    if (standard[st]) {
                        standard[st].hasSubmissions = standard[st].hasSubmissions || !!subm;
                        standard[st].correct +=  isCorrect ? 1 : 0;
                        standard[st].total +=  total_to_add;
                        standard[st][level] += 1;
                    } else {
                        standard[st] = {
                            hasSubmissions: !!subm,
                            correct: isCorrect ? 1 : 0,
                            total:  total_to_add,
                            BASICO: level === "BASICO" ? 1 : 0,
                            INTERMEDIO: level === "INTERMEDIO" ? 1 : 0,
                            AVANZADO: level === "AVANZADO" ? 1 : 0
                        };
                    }
                }
            });
        }

        const rowData = Object.entries(standard).map(([key, val]) => {
            const metPercent = Math.round((val.correct / Math.max(val.total, 1)) * 100);
            return {
                Standard: key,
                Met: val.hasSubmissions ? `${metPercent}%` : '-',
                Fail: val.hasSubmissions ? `${100 - metPercent}%` : '-',
                Basico: val.BASICO,
                Intermedio: val.INTERMEDIO,
                Avanzado: val.AVANZADO
            };
        });

        // Fill up rowData to ensure at least 10 entries
        while (rowData.length < 10) {
            rowData.push({ Standard: null, Met: '', Fail: '', Basico: null, Intermedio: null, Avanzado: null });
        }

        setAssignmentOverAllRowData(rowData);
        setDataShow2(true);
    };
      
      
    // const handleoverAllShow = (user) => {
    //     const orderedSemDt = [...classActivityData, ...classQuizzesData]
    //     const rowData = []
    //     const standard = {
    //     }
    //     map(orderedSemDt, smDt => {
    //         const subm = find(studentSubmissions[user.id], ['assignmentId', get(smDt, "data.id")]);
    //         const smAnswer = get(subm, "answers", [])
    //         const level = get(smDt, "data.level", "")
    //         map(get(smDt, "data.questions", []), (q, i) => {
    //             // if(!subm) return;
    //             const ans = smAnswer[i];
    //             let isCorrect = get(ans, "answer") == get(q, "answer");
    //             const std = q.standard?q.standard:''
    //             const allStd = std.split(",").filter(k=>k)
    //             const hasSubmissions = !!subm 
    //             map(allStd,st=>{
    //                 if(q.type !== "multipleChoice"){
    //                     if(ans?.met){
    //                         const met_data =  find(ans?.met,["standard",st])
    //                         if(met_data){
    //                           isCorrect = met_data.met
    //                         }
    //                         if(!met_data){
    //                           return ;
    //                         }
    //                       }else{
    //                         return;
    //                       }
    //                 }
    //                 if (standard[st]) {
    //                     const prevAn = standard[st];
    //                     const BASICO = level == "BASICO" ? prevAn.BASICO + 1 : prevAn.BASICO;
    //                     const INTERMEDIO = level == "INTERMEDIO" ? prevAn.INTERMEDIO + 1 : prevAn.INTERMEDIO;
    //                     const AVANZADO = level == "AVANZADO" ? prevAn.AVANZADO + 1 : prevAn.AVANZADO
    //                     standard[st] = {
    //                         correct: isCorrect ? prevAn.correct + 1 : prevAn.correct,
    //                         total: prevAn.total + 1,
    //                         BASICO,
    //                         INTERMEDIO,
    //                         AVANZADO
    //                     }
    //                 } else {
    //                     const BASICO = level == "BASICO" ? 1 : 0;
    //                     const INTERMEDIO = level == "INTERMEDIO" ? 1 : 0;
    //                     const AVANZADO = level == "AVANZADO" ? 1 : 0;
    //                     standard[st] = {
    //                         correct: isCorrect ? 1 : 0,
    //                         total: 1,
    //                         BASICO,
    //                         INTERMEDIO,
    //                         AVANZADO
    //                     }
    //                 }
    //             });
              
    //         })
    //     });
    //     Object.keys(standard).map(key => {
    //         const val = standard[key];
    //         const total = Math.max(val.total, 1)
    //         const correct = val.correct >= 0 ? val.correct : 0;
    //         const met = Math.round((correct / total) * 100);
    //         rowData.push({ Standard: key, 'Met': `${met}%`, 'Fail': `${100 - met}%`, 'Basico': get(val, "BASICO", 0), 'Intermedio': get(val, "INTERMEDIO", 0), 'Avanzado': get(val, "AVANZADO", 0) })
    //     })
    //     if (rowData.length < 10) {
    //         let len = 10 - rowData.length;
    //         let arr = new Array(len).fill({ 'Standard': null, Met: '', 'Fail': '', 'Basico': null, 'Intermedio': null, "AVANZADO": null }, 0, len)
    //         rowData.push(...arr)
    //     }
    //     setAssignmentOverAllRowData(rowData)
    //     setDataShow2(true);
    // };
    const updateAssignmentToSchoology = async (quizeDataforCreate) => {
        let sectionId = currentClass.sectionId;
        if (currentUser.schoologyid && sectionId) {
            client.setToken(schoologyAuth);
            let data = {
                "title": quizeDataforCreate.displayName,
                "due": `${quizeDataforCreate.dueDate}`,
                "grading_category": quizeDataforCreate.schoologyCategory
            }
            const v =  await client.updateAssignment(sectionId, quizeDataforCreate.sAssignmentId, data)
            listSchoologyAssignment(sectionId)
            return v;
        }
        return null
    }
    const calculateValue = (assignment, user) => {
        if (!user || !get(assignment, "data")) return ({ value: "-", submission: {} })
        const subm = find(studentSubmissions[user.id], ['assignmentId', get(assignment, "data.id")]);
        const value = (subm && subm.grade >= 0 ? subm.grade : '-')
        return { value, submission: subm }
    };
    useEffect(() => {
        const orderedSemDt = [...classActivityData, ...classQuizzesData]
        if (orderedSemDt.length < 8) {
            let len = 8 - orderedSemDt.length;
            let arr = new Array(len).fill({}, 0, len)
            orderedSemDt.push(...arr)
        }
        const colsDt = [
            {
                headerName: "Name of story:",
                children: [
                    {
                        headerName: "Page Number:",
                        children: [{
                            headerName: "Date:",
                            cellRenderer: stickyCellRenderer,
                            width: 210,
                            field: "user",
                            pinned: 'left',
                        }],
                    },
                ],
            },
        ]


        map(orderedSemDt, smDt => {
            colsDt.push(
                {
                    headerName: get(smDt, "displayName"),
                    field: get(smDt, "id"),
                    children: [
                        {
                            headerName: getAssignmentLable(smDt, booksAllList, videos, resources),
                            children: [{
                                headerName: get(smDt, "dueDate") ? moment(get(smDt, "dueDate")).format("MMM DD") : '',
                                field: get(smDt, "id"),
                                headerClass: "story-ag-cell-value",
                                cellRenderer: modelCellRenderer,
                                refData: smDt,
                            }],
                        },
                    ],
                })
        })
        const listUser = filteredStudent;
        const rows = map(listUser, k => {
            const res = { user: { ...k } }
            map(orderedSemDt, smDt => {
                const submissionData = calculateValue(smDt, k)
                if (get(smDt, "id")) {
                    res[get(smDt, "id")] = {
                        ...smDt,
                        user: k,
                        submissionData
                    }
                } else {
                    res['-'] = {
                        user: k,
                        submissionData
                    }
                }

            });
            return res;
        })
        const numberOfQuestionByAssignment = {}
        map(orderedSemDt, smDt => {
            if (get(smDt, "id")) {
                numberOfQuestionByAssignment[get(smDt, "id")] = {
                    numberOfQuestion: get(smDt, "data.questions.length", 0),
                }
            } else {
                numberOfQuestionByAssignment['-'] = '-'
            }
        });
        rows.unshift(numberOfQuestionByAssignment)
        setRowData(rows)
        setColsDefs(colsDt)
    }, [filteredStudent, submissions, classActivities, classQuizzes])
    const onSearchStudent = (e) => {
        let text = e.target.value;
        setSearchInput(text);
        if (typeof text === "string" && text.length > 0) {
            text = text.toLowerCase()
            const ftStudent = filter(listStudentUser, st => {
                if (st) {
                    const firstName = st.firstName ? st.firstName : '';
                    const lastName = st.lastName ? st.lastName : '';
                    if (firstName.toLowerCase().includes(text) || lastName.toLowerCase().includes(text)) {
                        return true;
                    }
                }
                return false;
            })
            setFilteredStudent(ftStudent)
        } else {
            setFilteredStudent(listStudentUser)
        }
    }
    const getAssignmentCreation = (assignmentdata, userdata) => {
        try {
            let _assignmentinfo = assignmentdata.data ?? {};
            let quizdata = {
                answers: [],
                assignment: _assignmentinfo,
                assignmentId: _assignmentinfo.id,
                assignmentType: _assignmentinfo.typeId,
                chapter: _assignmentinfo.chapter,
                classId: currentClass.id,
                complete: false,
                createdAt: Date.now(),
                feedback: '',
                grade: '0',
                graded: true,
                isLate: false,
                orgId: currentOrg?.id,
                retakes: assignmentdata.retakes,
                retaking: false,
                submissions: 0,
                timmer: 0,
                user: userdata,
                userId: userdata.id,
                bookId: _assignmentinfo.bookId,
                isTemp: true
            }
            return quizdata;
        } catch (error) {
            return {};
        }
    }
    const reviewAssignment = (data, tempData, users, userSubmissions) => {
        const _assignment = [...classActivityData, ...classQuizzesData].filter(a => (a.quizId === data?.assignmentId || a.activityId === data?.assignmentId))[0];
        let sAssignmentId = get(data, "sAssignmentId");
        if (_assignment && _assignment.sAssignmentId) {
            sAssignmentId = _assignment.sAssignmentId;
        }
        if (data) {
            data.sAssignmentId = sAssignmentId;
        }
        if (!data) {
            const utemp = _.cloneDeep(tempData);
            utemp.isTemp = true;
            data = utemp;
            data.sAssignmentId = sAssignmentId;
        }
        const userdata = map(users, usr => {
            const sub = userSubmissions[usr.id]
            if (sub) {
                const asub = find(sub, n => get(n, "assignmentId") == get(data, "assignmentId", get(tempData, "assignmentId")))
                if (asub) {
                    asub.sAssignmentId = sAssignmentId;
                    return asub
                } else {
                    const utemp = _.cloneDeep(tempData);
                    utemp.userId = usr.id;
                    utemp.user = usr;
                    utemp.isTemp = true;
                    utemp.sAssignmentId = sAssignmentId;
                    return utemp;
                }
            } else {
                const utemp = _.cloneDeep(tempData);
                utemp.userId = usr.id;
                utemp.user = usr;
                utemp.isTemp = true;
                utemp.sAssignmentId = sAssignmentId;
                return utemp;
            }
        })
        setSelectedAssignmentSubmissions(userdata)
        setAssignmentData(data);
        toggleAssignmentDrawer(true);
    };
    const openEditActivity = (activity) => {
        if (get(activity,"diff.length",0) > 0) {
            activity = modifyAssignmentWithDiff(activity,currentClass)
            setActivityData({
                editing: true,
                dueDateDif: activity.diff[0].dueDate,
                retakesDif: activity.diff[0].retakes,
                scheduleAtDiff: activity.diff[0].scheduleAt ,
                ...activity
            });
            toggleActivityDrawer(true);
        } else {
            if (activity.lateDate) {
                setlateSection(true)
            }
            if (activity.timeDuration > 0) {
                setDurationSection(true)
            }
            setActivityData({
                editing: true,
                ...activity
            });
            toggleActivityDrawer(true);
        }
    }
    const openEditQuiz = (quiz) => {
        if (get(quiz,"diff.length",0) > 0) {
                quiz = modifyAssignmentWithDiff(quiz,currentClass)
                setQuizData({
                    editing: true,
                    dueDateDif: quiz.diff[0].dueDate,
                    retakesDif: quiz.diff[0].retakes,
                    levelExercise: quiz.diff[0].levelExercise,
                    scheduleAtDiff: quiz.diff[0].scheduleAt ,
                    ...quiz
                });
                toggleQuizDrawer(true);
        } else {
            if (quiz.lateDate) {
                setlateSection(true)
            }
            if (quiz.timeDuration > 0) {
                setDurationSection(true)
            }
            setQuizData({
                editing: true,
                ...quiz
            });
            toggleQuizDrawer(true);
        }
    }
    const updateActivityData = (id, value) => {
        const val = value&&value.length > 1 && value[0] === '0' ? value.replace('0', '') : value;
        setActivityData({
            ...activityData,
            [id]: val
        });
    };
    const updateActivity = async (e) => {
        e.preventDefault();
        let toCreateStudent = [];
        let infoDiffSection = {};
        if (activityData && activityData.sAssignmentId && currentClass.sectionId) {
            if (activityData.sAssignmentId && currentClass.sectionId) {
                let u = await updateAssignmentToSchoology(activityData)
            }
        }
        setLoading(true);
        await dispatch(handleUpdateClassActivity(
            activityData.id,
            {
                ...activityData,
                retakes: activityData.retakes && activityData.retakes.trim(),
                dueDate: activityData.dueDate,
                lateDate: activityData.lateDate,
                timeDuration: activityData.timeDuration,
                classes: activityData.classes,
                weightage: activityData.weightage,
                displayName: activityData.displayName,
                classId:activityData.classId
            },
            infoDiffSection,
            toCreateStudent,
            activityData,
            activityData.updateTOAllClass
        ));
        await dispatch(handleListClassActivities(currentClass.id));
        toggleActivityDrawer(false);
        setLoading(false);
        setActivityData({});
        setlateSection(false)
        setDurationSection(false)
    };
    const updateQuiz = async (e) => {
        e.preventDefault();
        let infoDiffSection = {};
        let toCreateStudent = [];
        setLoading(true);
        if (quizData.sAssignmentId && currentClass.sectionId) {
            let u = await updateAssignmentToSchoology(quizData)
        }
        await dispatch(handleUpdateClassQuiz(
            quizData.id,
            {
                ...quizData,
                retakes: quizData.retakes && quizData.retakes.trim(),
                dueDate: quizData.dueDate,
                lateDate: quizData.lateDate,
                timeDuration: quizData.timeDuration || 0,
                classes: quizData.classes,
                weightage: quizData.weightage,
                displayName: quizData.displayName,
                scheduleAt: quizData.scheduleAt,
                classId:quizData.classId,
            },
            infoDiffSection,
            toCreateStudent,
            quizData,
            quizData.updateTOAllClass
        ));
        await dispatch(handleListClassQuizzes(currentClass.id));
        toggleQuizDrawer(false);
        setLoading(false)
        setQuizData({});
        setlateSection(false)
        setDurationSection(false)
    };
    const updateQuizData = (id, value) => {
        const val = value&&value.length > 1 && value[0] === '0' ? value.replace('0', '') : value;
        setQuizData({
            ...quizData,
            [id]: val
        });
    };
    return (<>
     <Drawer
                height='100%'
                destroyOnClose
                className='assignments-drawer assignments-drawerCstm'
                onClose={() => {
                    toggleActivityDrawer(false);
                    setActivityData({});
                    setlateSection(false)
                    setDurationSection(false)
                }}
                placement='top'
                open={activityDrawer}
                key='activityNew'
            >
                <>
                    <SentryWrap>
                        <ActivityForm
                            listStudents={listStudentUser}
                            data={activityData}
                            handleChange={e => updateActivityData(e.target.id, e.target.value)}
                            handleSubmit={activityData.editing ? updateActivity :()=>{}}
                            handleChangeMultiSelect={()=>{}}
                            diffSection={false}
                            lateSection={lateSection}
                            setDiffSection={()=>{}}
                            setlateSection={setlateSection}
                            setDurationSection={setDurationSection}
                            durationSection={durationSection}
                            studentsName={[]}
                            studentsError={null}
                            loading={loading}
                            onClose={() => toggleActivityDrawer(false)}
                            booksAllList={booksAllList}
                        />
                    </SentryWrap>
                </>
            </Drawer>
            <Drawer
                destroyOnClose
                height='100%'
                className='assignments-drawer assignments-drawerCstm'
                onClose={() => {
                    toggleQuizDrawer(false);
                    setQuizData({});
                    setlateSection(false)
                    setDurationSection(false)
                }}
                placement='top'
                open={quizDrawer}
                key='quizNew'
            >
                
                    <SentryWrap>
                        <QuizForm
                            listStudents={listStudentUser}
                            data={quizData}
                            handleChange={e => updateQuizData(e.target.id, e.target.value)}
                            handleChangeLevel={e => updateQuizData('levelExercise', e.target.value)}
                            handleSubmit={quizData.editing ? updateQuiz : ()=>{}}
                            handleChangeMultiSelect={()=>{}}
                            lateSection={lateSection}
                            setDiffSection={()=>{}}
                            setlateSection={setlateSection}
                            setDurationSection={setDurationSection}
                            durationSection={durationSection}
                            studentsName={[]}
                            studentsError={null}
                            loading={loading}
                            onClose={() => toggleQuizDrawer(false)}
                            booksAllList={booksAllList}
                        />
                    </SentryWrap>
               
            </Drawer>
        <Drawer
            height='100%'
            destroyOnClose
            onClose={() => {
                toggleAssignmentDrawer(false);
                setAssignmentData({});
                setSelectedAssignmentSubmissions([])
            }}
            placement='top'
            visible={assignmentDrawer}
            key='assignmentNew'
        >
          <AssignmentReview
                booksAllList={booksAllList}
                data={assignmentData}
                usersSubmissionData={selectedAssignmentUserSubmission}
                currentClass={currentClass}
                handleClose={() => {
                    setLoading(true)
                    toggleAssignmentDrawer(false)
                    setLoading(false)
                }}
                scale_1_5={userPreference?.gradePref == "scale_1_5"}
                gradebook_a_f={userPreference?.gradePref === "gradebook_a_f"}
            /> 
        </Drawer>
        <Modal
            open={labelDescriptionView.open}
            onClose={() => setLabelDescriptionView({ open: false, describe: "" })}
        ><ModalContent>
                <MessageContgainer>
                    {labelDescriptionView.describe}
                </MessageContgainer>
            </ModalContent>
        </Modal>
        <div className="dataSectionBg">
            <div className="container">
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className="dataHead">
                            <div className="dataHeadLogo"><img src={datagrid_logoColored} title="One Way Assessments" alt="" /></div>
                        </div>
                        <Row className="dataOverRow" gutter={[32, 0]}>
                            <Col xs={24} sm={18} md={18} lg={17}>
                                <div className="numStudent">
                                    <label>Overview</label>
                                    <p>Total number of student: {get(filter(listStudentUser, k => k.id), "length", 0)}</p>
                                </div>
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={7}>
                                <div className="findStudent">
                                    <Search type="text" value={searchInput} onChange={onSearchStudent} placeholder="Find your student" />
                                    <img src={datagrid_searchIcon} title="" alt="" />
                                </div>
                            </Col>
                            <div className="clearFindStdnt"></div>
                        </Row>

                        <div className="dataColloect">
                            {/* Start of Model 1 */}
                            <div className="eyeDataUpr">
                                <Modal className="eyeDataMdl" open={DataShow2} onClose={() => setDataShow2(false)}>
                                    <ModalContent>
                                        <div className="ag-theme-alpine eyeDataInr">
                                            <a className="closeEyeData" onClick={() => setDataShow2(false)}><img src={datagrid_close} title="" alt="" /></a>
                                            <AgGridReact
                                                rowData={assignmentOverAllRowData}
                                                columnDefs={assignmentOverAllColDefs}
                                                className="eyeDataTable2"
                                                columnHoverHighlight={true}>
                                            </AgGridReact>
                                        </div>
                                    </ModalContent>
                                </Modal>
                            </div>
                            {/* End of Model 1 */}

                            {/* Start of Model 2  Inner Table Model */}
                            <div className="eyeDataUpr">
                                <Modal className="eyeDataMdl" open={DataShow} onClose={() => setDataShow(false)}>
                                    <ModalContent>
                                        <div className="ag-theme-alpine eyeDataInr">
                                            <a className="closeEyeData" onClick={() => setDataShow(false)}><img src={datagrid_close} title="" alt="" /></a>
                                            <AgGridReact
                                                rowData={assignmentRowData}
                                                columnDefs={assignmentColDefs}
                                                className="eyeDataTable"
                                                columnHoverHighlight={true}>
                                            </AgGridReact>
                                        </div>
                                    </ModalContent>
                                </Modal>
                            </div>
                            {/* End of Model 2 */}

                            {/* Start of Data Table  */}
                            <Row className="storyDataWrap">
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    {/* <div className="ag-theme-alpine storyDataUpr">
                                        {dataLoading ? (
                                            <Loading />
                                        ) : (<AgGridReact
                                            rowData={rowData}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            suppressRowClickSelection={true}
                                            className="storyData"
                                            rowHeight={50}
                                            gridOptions={{
                                                getRowStyle: params => {
                                                    if (params.node.rowIndex === 0) {
                                                        return { background: COLOR.lightgreen };
                                                    }
                                                },
                                            }}
                                            columnHoverHighlight={true}>
                                        </AgGridReact>)}
                                    </div> */}
                                    <div className="ag-theme-alpine storyDataUpr" style={{ marginTop:24 }}>
                                    <GradeTable
                                        activities={activities}
                                        currentClass={currentClass}
                                        currentOrg={currentOrg}
                                        dataLoading={dataLoading}
                                        quizzes={quizzes}
                                        submissions={submissions}
                                        users={filteredStudent}
                                        booksAllList={booksAllList}
                                        isReviewAccount={false}
                                        handleEditQuiz={quiz => {
                                            openEditQuiz(quiz);
                                        }}
                                        handleEditActivity={activity => {
                                            openEditActivity(activity);
                                        }}
                                        currentUser={currentUser}
                                        classActivities={classActivityData}
                                        getAssignmentCreation={getAssignmentCreation}
                                        classQuizzes={classQuizzesData}
                                        handleoverAllShow={handleoverAllShow}
                                        handleGradeValueShow={handleGradeValueShow}
                                        reviewAssignment={reviewAssignment}
                                    />
                                    </div>
                                </Col>
                            </Row>
                            {/* End of Data Table  */}

                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </>);
};
const mapStateToProps = state => ({
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentUser: state.currentUser,
    classUsers: state.classUsers,
    userBooksList: state.userBooksList,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
    userPreference: state.userPreference,
    selectedUser: state.selectedUser,
    activities: state.activities,
    quizzes: state.quizzes,
    schoologyAuth: state.schoologyAuth
});

export default connect(mapStateToProps, null)(GridStoryLevel); 