
//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------


const init = []

export const canvasAuthDistrict = (state = init, action) => {
    switch (action.type) {
        case ActionTypes.canvas.setDistrictAuth:
            return action.payload;
        case ActionTypes.canvas.updateDistrictAuth:
            return action.payload;
        case ActionTypes.canvas.clearDistrictAuth:
            return init;
        default:
            return state;
    }
};

const category = {}
export const canvasAssignmentCategory = (state = category, action) =>{
    switch (action.type) {
        case ActionTypes.canvas.setCanvasAssignmentsCategory:
            return action.payload;
        case ActionTypes.canvas.updateCanvasAssignmentsCategory:
            return action.payload;
        case ActionTypes.canvas.cleearCanvasAssignmentsCategory:
            return category;
        default:
            return state;
    }

}