//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map, filter, cloneDeep } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const videoInitial = [];




export const one_game_video = (state = videoInitial, action) => {
    switch (action.type) {
        case ActionTypes.one_game_video.create_one_game_video:
            return [action.payload, ...state];
        case ActionTypes.one_game_video.list_one_game_video:
            return action.payload;
        case ActionTypes.one_game_video.update_one_game_video:
            return map(state, dt => {
                if (dt.id === action.payload.id) {
                    return action.payload;
                }
                return dt;
            })
        case ActionTypes.one_game_video.delete_one_game_video:
            return filter(state,dt=>dt.id!==action.payload)
        default:
            return state;
    }
};
