import InitialLoading from "components/InitialLoading";
import React, { useEffect, useState } from "react";
import Baseball from "utils/assets/images/Baseball.gif";
import Dance from "utils/assets/images/Dance.gif";
import Girlsoccer from "utils/assets/images/Girlsoccer.gif";
import LetterB from "utils/assets/images/LetterB.gif";

const messages = [
  { text: "We are loading the magic now.", image: Baseball },
  { text: "The magic takes some time.", image: Dance },
  { text: "It is now happening!", image: Girlsoccer },
  { text: "Ok, you ready! Welcome.", image: LetterB },
];

const CreateDifferentiationModal = ({ open, loader}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [openDropDown, setOpenDropDown] = useState({
    gradeLevel: false,
    state: false,
    timeFrame: false,
    days: false,
    standard: false,
    activities: false,
  });

  const updateState = (name, value) => {
    setOpenDropDown(prev => ({ ...prev, [name]: value }));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex(prevIndex => (prevIndex + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleMenuClose = (value) => {
    updateState(value, false);
  };

  const handleScroll = () => {
    const openKeys = Object.keys(openDropDown).filter(key => openDropDown[key]);
    if (openKeys.length) {
      openKeys.forEach(key => handleMenuClose(key));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [openDropDown]);

  return (
    <>
      {open && (
        <div className="superLessionModal">
          <div className={`createMyLession create-lesson-modal ${loader ? 'createMyLessonLoader' : ''}`}>
            <div className="super-lesson-waiting">
              <InitialLoading mounted={true} transparent={true} />
              <span className="super-lesson-waiting-txt">
                {messages[currentMessageIndex].text}
              </span>
              <span className="differentiation-lesson-waiting-txt">
                {'Note: We are creating differentiation strategies based on proficiency: Basic - Intermediate - Advance.'}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateDifferentiationModal;
