//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create vocabulary ----------------
export const createVocabulary = () => ({ type: ActionTypes.vocabulary.createVocabulary });
export const createVocabularyFail = (returnedPayload) => ({ type: ActionTypes.vocabulary.createVocabularyFail, payload: returnedPayload });
export const createVocabularySuccess = (returnedPayload) => ({ type: ActionTypes.vocabulary.createVocabularySuccess, payload: returnedPayload });
export const updateVocabularySuccess = (returnedPayload) => ({ type: ActionTypes.vocabulary.updateVocabularySuccess, payload: returnedPayload });

//  -- List vocabulary ----------------
export const listVocabulary = () => ({ type: ActionTypes.vocabulary.listVocabulary });
export const listVocabularyFail = (returnedPayload) => ({ type: ActionTypes.vocabulary.listVocabularyFail, payload: returnedPayload });
export const listVocabularySuccess = (returnedPayload) => ({ type: ActionTypes.vocabulary.listVocabularySuccess, payload: returnedPayload });

// Delete vocabulary
export const deleteVocabulary = () => ({ type: ActionTypes.vocabulary.deleteVocabulary });
export const deleteVocabularySuccess = (returnedPayload) => ({ type: ActionTypes.vocabulary.deleteVocabularySuccess, payload: returnedPayload });
