//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { string } from 'prop-types';
import styled from 'styled-components';

//  -- Components ---------------------
import { Col, Row } from 'antd';
import { Button } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import TextEditor from 'components/TextEditor';
import Loading from 'components/Loading';

//  -- Features -----------------------
import AudioRecorder from 'features/AudioRecorder';

//  -- Thunks -------------------------
import { handleCreatePost, handleListClassPosts, handleListOrgPosts } from 'store/posts/thunks';


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Btn = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: #3D5F8F;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 16px;
    outline: none;
    text-transform: uppercase;
`;

const Wrapper = styled.form`
    margin: 0px auto;
    max-width: 480px;
    width: 100%;
`;


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Quiz form
//
//  -- Description
//  Create / edit quiz questions
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <PostForm
//      data={data}
//      handleChange={func}
//      handleSubmit={func}
//  />
//
const PostForm = ({
    currentClass,
    currentOrg,
    currentUser,
    type
}) => {
    const initialState = [{
        type: 'text',
        children: [{ text: '' }]
    }];
    const dispatch = useDispatch();
    const [postData, setPostData] = useState("");
    const [selected, setSelected] = useState(false);
    const [audio, setAudio] = useState(false);
    const [url, setUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const createAudioPost = async (e) => {
        e.preventDefault();

        setLoading(true);
        await dispatch(handleCreatePost({
            userId: currentUser?.id,
            classId: type === 'class' ? currentClass.id : 'null',
            orgId: currentOrg.parentId !== 'null' ? currentOrg.parentId : currentOrg?.id,
            content: url
        }));

        if (type === 'class') {
            await dispatch(handleListClassPosts(currentClass.id));
        } else {
            const id = currentOrg.parentId !== 'null' ? currentOrg.parentId : currentOrg?.id;
            await dispatch(handleListOrgPosts(id));
        }

        setSelected(false);
        setLoading(false);
    };

    const createTextPost = async (e) => {
        e.preventDefault();

        setLoading(true);
        await dispatch(handleCreatePost({
            userId: currentUser?.id,
            classId: type === 'class' ? currentClass.id : 'null',
            orgId: currentOrg.parentId !== 'null' ? currentOrg.parentId : currentOrg?.id,
            content: postData
        }));

        if (type === 'class') {
            await dispatch(handleListClassPosts(currentClass.id));
        } else {
            const id = currentOrg.parentId !== 'null' ? currentOrg.parentId : currentOrg?.id;
            await dispatch(handleListOrgPosts(id));
        }

        setSelected(false);
        setPostData("");
        setLoading(false);
    };

    return (
        <Wrapper onSubmit={createTextPost}>
            {!selected && (
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Button
                            onClick={() => {
                                setAudio(true);
                                setSelected(true);
                            }}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: '#3D5F8F', width: '100%' }}
                        >
                            Audio Post
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            onClick={() => {
                                setAudio(false);
                                setSelected(true);
                            }}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: '#3D5F8F', width: '100%' }}
                        >
                            Text Post
                        </Button>
                    </Col>
                </Row>
            )}
            {selected && (
                <>
                    <Btn onClick={() => setSelected(false)} type="button">
                        <ArrowBack style={{ height: '12px', margin: '0px 6px 0px 0px' }} />
                        Back to Selections
                    </Btn>
                    {audio ? (
                        <>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <AudioRecorder
                                        message="Record your message by clicking the 'Record' button below."
                                        handleAudio={url => setUrl(url)}
                                        storageLoc="/audio/posts"
                                    />
                                </Col>
                                <Row justify="end" style={{ widt: '100%' }}>
                                    <Button
                                        disabled={!url}
                                        onClick={createAudioPost}
                                        variant="contained"
                                        type='button'
                                        style={{ color: 'white', backgroundColor: '#3D5F8F', width: '100%' }}
                                    >
                                        {loading ? <Loading size={20} white /> : 'Post Audio'}
                                        <ArrowForward style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                                    </Button>
                                </Row>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <TextEditor
                                        handleChange={setPostData}
                                        value={postData}
                                    />
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Button
                                    disabled={!postData}
                                    variant="contained"
                                    type='submit'
                                    style={{ color: 'white', backgroundColor: '#3D5F8F', width: '100%' }}
                                >
                                    {loading ? <Loading size={20} white /> : 'Post Text'}
                                    <ArrowForward style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                                </Button>
                            </Row>
                        </>
                    )}
                </>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
PostForm.propTypes = {
    type: string
};

//  -- Default props ------------------
PostForm.defaultProps = {
    data: {}
};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
});

export default connect(mapStateToProps, null)(PostForm);
