//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Auth } from "aws-amplify";
//  -- Components ---------------------
import InitialLoading from "components/InitialLoading";
//  -- Features -----------------------
import BalconCulturalLayout from "features/BalconCulturalLayout";
import _, { filter, find , get , has } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
//  -- Thunks -------------------------
import { handleListChapterActivities } from "store/activities/thunks";
import { handleListClassActivities , handleListClassActivitiesForStudent } from 'store/classActivities/thunks';
import { handleListClassQuizzes  , handleListClassQuizzesForStudent} from 'store/classQuizzes/thunks';
import { handleListUserNotes } from "store/notes/thunks";
import {
  handleListClassQuestions,
  handleListUserQuestions,
} from "store/questions/thunks";
import { handleListChapterQuizzes } from "store/quizzes/thunks";
import { handleListUserSubmissions } from "store/submissions/thunks";
import { handleListUserBooks } from "store/userBooks/thunks";
import { handleListClassUsers } from "store/userClasses/thunks";
import { handleListEbookData } from 'store/books/thunks';
import { useAlertView } from "store/alert/selectors";
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import styled from "styled-components";
//  -- Lib ----------------------------
import { ebookConfigDinamic } from "utils/lib/ebookHelper";
import { useBookUnits } from "store/booksUnits/selectors"

const CHAPTER_ID = "49";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CenterContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  min-height: 90vh;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------
//
//  Not found container
//
//  -- Description
//  Failed route catch container
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//
const ConectandomeContainer = ({
  currentClass,
  currentUser,
  classUsers,
  userBooksList,
  tipsBook,
  classActivities,
  classQuizzes,
  submissions,
  booksAllList,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [alertView,invokeAlert,revokeALert ] =useAlertView()
  const orgPermissions = useCurrentOrgPermission()
  const search = queryString.parse(history.location.search);
  const bookUnitList = useBookUnits()
  const CHAPTER_PAGES = _.get(_.find(bookUnitList, ["bookId", CHAPTER_ID]), "pages", [])
  const bookData = filter(CHAPTER_PAGES, item => item.id === search.id)[0] ?? {};
  const [ebookFlag, setEbookFlag] = useState(null);
  const [ebookFlagTeacherEdition, setEbookFlagTeacherEdition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState("1");
  const book = find(booksAllList, { id: CHAPTER_ID });
  const lessonPlan = book?.lessonPlan;
  const rubrics = get(book,"rubrics",[]);
  const assignmentPlan = book?.assignmentPlan;
  const workbook_plan = book?.workbook_plan;
  const main_book = book?.main_book;
  const SuperLesson = book?.superLesson;
  const DifferentiationLesson = book?.differentiationLesson;
  let dataBook = filter(booksAllList, (x) => x.id == CHAPTER_ID)[0] ?? {};
  const fetchPageExist = filter(get(dataBook, "pages"), x => x.pageId == search.page).length > 0;

  useEffect(() => {
    if (search.page) {
      if (!fetchPageExist) return history.goBack();
      setPage(search.page);
    } else {
      setPage(bookData.start ?? "1");
    }
  }, [bookData])
  useEffect(()=>{
    if(orgPermissions && typeof orgPermissions['Conectándome']==="boolean"){
      if(!orgPermissions['Conectándome']){
        invokeAlert()
        setTimeout(()=>{
          history.goBack();
          revokeALert()
        },4000)
      }
    }
  },[orgPermissions])
  const data = ebookConfigDinamic(
    CHAPTER_ID,
    booksAllList
  );

  const getExitTeacherEdition = React.useCallback((_page) => {
    let existTeacherEdition = false;
    if (dataBook.teacherEdition != undefined) {
      existTeacherEdition =
        filter(dataBook.teacherEdition, (x) => x.pageId == _page).length > 0;
    }
    return existTeacherEdition;
  }, [dataBook, page]);
  const getShowAnswers = React.useCallback((_page) => {
    let existShowAnswer = false;
    if (dataBook.pagesTeacher != undefined) {
      existShowAnswer = filter(dataBook.pagesTeacher, (x) => x.pageId == _page).length > 0;
    }
    return existShowAnswer;
  }, [dataBook, page]);

  useEffect(() => {
    setTimeout(() => setMounted(true), 250);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await Auth.currentAuthenticatedUser();
      } catch {
        history.push("/");
      }
    }
    fetchData();
  }, []);
  const listChapterData = async () => {
    dispatch(handleListUserNotes(get(currentUser, "id")))
    dispatch(handleListEbookData(CHAPTER_ID))
  }
  const getStudentSubmissions = async () => {
    if (get(currentUser, "role") == "student") {
      return dispatch(handleListUserSubmissions(currentUser?.id))
    }
  }
  const getBookDataForTeacher = async () => {
    if (get(currentUser, "role") === 'teacher' && has(currentClass, "id")) {
      dispatch(handleListClassQuestions(get(currentClass, "id")))
      dispatch(handleListClassUsers(get(currentClass, "id")));
    }
    dispatch(handleListUserQuestions(get(currentUser,"id")))
  }
  const listClassAssignments = async () => {
    if (has(currentClass, "id") && get(currentUser, "role") != "student") {
      dispatch(handleListClassActivities(currentClass.id));
      dispatch(handleListClassQuizzes(currentClass.id));
    } else {
      dispatch(handleListClassActivitiesForStudent(get(currentClass, "id"), get(currentUser, "id")));
      dispatch(handleListClassQuizzesForStudent(get(currentClass, "id"), get(currentUser, "id")));
    }
  }
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const islodadedOnceBalcon = localStorage.getItem("islodadedOnceBalcon");
      const currentClassId = localStorage.getItem("cureentClassId");
      if (
        (islodadedOnceBalcon &&
          currentClassId &&
          currentClassId === currentClass?.id) ||
        (!currentClass?.id && currentClassId == "noclasssid")
      ) {
        setLoading(false);
      }
      await Promise.allSettled([
        listChapterData(),
        getStudentSubmissions(),
        getBookDataForTeacher(),
        listClassAssignments()
    ])
      // await dispatch(handleListUserNotes(currentUser?.id));
      // await dispatch(handleListChapterActivities(CHAPTER_ID));
      // await dispatch(handleListChapterQuizzes(CHAPTER_ID));
      // await dispatch(handleListEbookData(CHAPTER_ID))
      // if (submissions && submissions.length > 0) {
      //   dispatch(handleListUserSubmissions(currentUser?.id));
      // } else {
      //   await dispatch(handleListUserSubmissions(currentUser?.id));
      // }
      // if (currentUser?.role === "teacher") {
      //   await dispatch(handleListClassQuestions(currentClass.id));
      //   await dispatch(handleListClassUsers(currentClass.id));
      // }
      // await dispatch(handleListClassActivities(currentClass?.id));
      // await dispatch(handleListClassQuizzes(currentClass?.id));
      localStorage.setItem("cureentClassId", currentClass?.id ?? "noclasssid");
      localStorage.setItem("islodadedOnceBalcon", "true");
      setLoading(false);
      dispatch(handleListUserQuestions(currentUser?.id));
      dispatch(handleListUserBooks(currentUser?.id));
    }
    fetchData();
  }, [currentUser?.id, currentClass?.id, dispatch]);

  return (
    <Wrapper>
      {loading ? (
        <CenterContainer>
          <InitialLoading mounted={mounted} />
        </CenterContainer>
      ) : (
        <BalconCulturalLayout
          classUsers={classUsers}
          activity={search.activity ?? "null"}
          data={data}
          tips={tipsBook}
          existTeacherEdition={getExitTeacherEdition(page)}
          existShowAnswer={getShowAnswers(page)}
          ebookFlag={ebookFlag}
          setEbookFlag={setEbookFlag}
          ebookFlagTeacherEdition={ebookFlagTeacherEdition}
          setEbookFlagTeacherEdition={setEbookFlagTeacherEdition}
          history={history}
          page={page}
          setPage={setPage}
          quiz={search.quiz ?? "null"}
          currentUser={currentUser}
          submissions={submissions}
          classQuizzes={classQuizzes}
          classActivities={classActivities}
          id={search.id}
          bookData={bookData}
          backToLabel={'Conectándome'}
          CHAPTER_ID={CHAPTER_ID}
          lessonPlan={lessonPlan}
          rubrics={rubrics}
          assignmentPlan={assignmentPlan}
          workbook_plan={workbook_plan}
          main_book={main_book}
          SuperLesson={SuperLesson}
          DifferentiationLesson={DifferentiationLesson}
          chapter={CHAPTER_ID}
        />
      )}
    </Wrapper>
  );
};

//  -- Prop types ---------------------
ConectandomeContainer.propTypes = {};

//  -- Default props ------------------
ConectandomeContainer.defaultProps = {};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = (state) => ({
  currentClass: state.currentClass,
  currentUser: state.currentUser,
  classUsers: state.classUsers,
  userBooksList: state.userBooksList,
  tipsBook: state.tipsBook,
  classActivities: state.classActivities,
  classQuizzes: state.classQuizzes,
  submissions: state.submissions,
  booksAllList: state.booksAllList,
});

export default connect(mapStateToProps, null)(ConectandomeContainer);
