//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create log ----------------
export const setWelcomeVideos = (returnedPayload) => ({ type: ActionTypes.welcome_videos.setWelcomeVideos , payload: returnedPayload  });
export const updateWelcomeVideos = (returnedPayload) => ({ type: ActionTypes.welcome_videos.updateWelcomeVideos, payload: returnedPayload });

//  -- List logs ----------------

