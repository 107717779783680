//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { filter } from 'lodash';
import { cloneDeep } from 'lodash';

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const superLessonInitial = []

export const superLesson = (state = superLessonInitial, action) => {

    switch (action.type) {
        case ActionTypes.super_lesson.listLessonSuccess:
            return action.payload;
        case ActionTypes.super_lesson.updateLessonSuccess:
            return state.map(x => { if (x.id === action.payload?.id) { return { ...x, ...action.payload }; } return x; });
        case ActionTypes.super_lesson.deleteLessonSuccess:
            const delv = cloneDeep(state)
            const result = filter(delv,k =>k.id != action.payload.id)
            return result;
        case ActionTypes.super_lesson.createLessonSuccess:
            return action.payload?.data;
        default:
            return state;
    }
}