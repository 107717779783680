import { Tooltip } from "antd";
import ClickOutside, { OnClickOutside } from "components/OnClickOutside";
import React from "react";
import styled from "styled-components";

import BottomDropDown from "utils/assets/images/resource-dropdown.svg";

const CategoryMenu = styled.div`
  background-color: #0b1c2b;
  border-radius: 30px;
  color: #fff;
  // width: 201px;
  height: 42px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  // text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #2784a3;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 768px) {
    // width: 100% !important;
    height: 36px;
    color: #fff;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

const PaddedBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 8px;
  text-align: left;
  width: 100%;
`;

const CategroyDropdown = ({
  data,
  open,
  dropDownHandler,
  themeFilter,
  closeDropDown,
  handleDropdownClick
}) => {
  return (
    <>
      <CategoryMenu
        onClick={() => handleDropdownClick("category")}
        className="cursor-pointer md-w-full-2 box-shadow-resource-hover  arrow-contanier-resoruce w-181px category-dropdown-resource"
      >
        <span className="dropdown-text lg-pl-17px pl-16px">
          {themeFilter === "" || themeFilter === "All" ? (
            <span style={{ color: "#2784A3" }} className="dropdown-text">
              CATEGORIAS
            </span>
          ) : (
            <Tooltip placement="top" title={themeFilter}>
              {themeFilter}
            </Tooltip>
          )}
        </span>

        <div className="dropdown-resource-arrow-container">
          <div
            style={{
              transition: "transform 0.4s ease-in-out",
              transform:
                open
                  ? "rotate(-180deg)"
                  : "rotate(0deg)",
            }}
          >
            <img src={BottomDropDown} alt="" />
          </div>
        </div>
      </CategoryMenu>
      {open && (
          <ClickOutside onClickOutside={closeDropDown} className="bg-dropdown-for-resource top-59px  box-shadow-dropdown-resource border-radius-14px absolute z-index-990">
            {data?.map((ct, index) => (
              <React.Fragment key={index}>
                <PaddedBtn
                  className="border-for-inner dropdown-menus-item"
                  style={{
                    padding: "0",
                    height: "60px",
                    borderBottom:
                      index === data.length - 1
                        ? "unset !important"
                        : undefined,
                  }}
                  onClick={() => dropDownHandler("category", ct)}
                >
                  <div
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "11px",
                      paddingBottom: "11px",
                    }}
                    className=""
                  >
                    <label className="dropdown-list-name-ap-theme">
                      {typeof ct === "object" && ct !== null
                        ? ct.title
                        : typeof ct === "string"
                        ? ct
                        : ""}
                    </label>
                  </div>
                </PaddedBtn>
              </React.Fragment>
            ))}
          </ClickOutside>
      )}
    </>
  );
};

export default CategroyDropdown;
