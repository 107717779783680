//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { CaretDownOutlined } from '@ant-design/icons';
import Snackbar from '@material-ui/core/Snackbar';
import { filter, find, groupBy, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
//  -- Components ---------------------
import { Button, Modal, TextField, Tooltip } from '@material-ui/core';
import { MoreVert, Warning } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { Dropdown, Menu, message } from 'antd';
import EmptyState from 'components/EmptyState';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { handleChangeStatusActivity, handleDeleteClassActivity, handleUpdateClassActivity } from 'store/classActivities/thunks';
import { handleChangeStatusQuizzes, handleDeleteClassQuiz, handleUpdateClassQuiz } from 'store/classQuizzes/thunks';
import { useExams } from 'store/exams/selectors';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { TableBg } from "utils/assets/images";
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';

//  -- Features -----------------------
import GradeEditor from 'features/forms/GradeEditor';

//  -- Thunks -------------------------
import base64 from "base-64";
import { TootipWrapper } from 'components/TooltipWrapper';
import _, { get, has, map } from 'lodash';
import moment from "moment";
import { useCallback } from 'react';
import { useBookUnits } from "store/booksUnits/selectors";
import { handleDeleteGoogleAssignment } from 'store/googleClassRoom/thunks';
import { useResources, useVideos } from 'store/resources/selectors';
import { useSchoologyAssignment, useSchoologyGradeCategory } from "store/schoologyauth/selectors";
import { useStory } from "store/story/selectors";
import { handleListClassSubmissions, handleUpdateSubmission, handleUpdateSubmissionsToAll } from 'store/submissions/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import { handleGetUserPreference } from 'store/userPreference/thunks';
import { handleDeleteUserData } from 'store/users/thunks';
import Schoology from 'utils/Schoology';
import { handleSyncSchoologyClassesGrades} from "store/schoology/thunks"
import { BACK_TO_BOOK } from 'utils/config/reference';
import { generateUrlForNonEbook, getAssignmentLable, getBookDisplayName, getStoryUrl, isVideo } from 'utils/lib/ebookHelper';
import { getCurrenSem, semeterDateByYear } from "utils/methods/math";
import ExamTables from "./ExamGradeTable";

import { handleDeleteCanvasAssignment } from "store/canvas/thunks";
import { openLoginInNewWindow } from "store/keystore/thunks";
import { handledeleteDebate } from 'store/debate/thunks';
import { useClassGameActivity } from 'store/one_game/selectors';
import ParentInvitationModal from 'containers/parent/ParentInvitationModal';
import ModalMenu from 'components/Modals';
const rowOneColor = "#7ed6df"
const rowTwoColor = "#03989e"
const rowSemester = COLOR.OrangeExam
const rowThreeColor = "#0abde3"
const getKey = (sem, year) => (`${sem == 1 ? "semester 1" : "semester 2"} - ${year}`)
const ACTIVE_BTN_COLOR = `linear-gradient(to bottom right, ${COLOR.indigo100} , ${COLOR.indigo300})`
const DEACTIVE_BTN_COLOR = `linear-gradient(to right, ${COLOR.lightseagreen100} , ${COLOR.lightseagreen200})`
const getBgColor = (isActive) => isActive ? ACTIVE_BTN_COLOR : DEACTIVE_BTN_COLOR
const getBgColorSemester = (isActive) => isActive ? ACTIVE_BTN_COLOR : rowSemester
const NAME_SHORTING = [{ key: "last_a_z", title: "Last Name, A-Z" }, { key: "last_z_a", title: "Last Name, Z-A" }, { key: "first_a_z", title: "First Name, A-Z" }, { key: "first_z_a", title: "First Name, Z-A" }]
const TABLE_CELL_HEIGHT = '60px';
const TABLE_CELL_MIN_WIDTH = 150

const getSemDateTime = (sem) => {
    let dt = sem.split(" ")
    const year = dt[3]
    const semNum = dt[1]
    const { sem1EndDate, sem1StartDate, sem2EndDate, sem2StartDate } = semeterDateByYear(year)
    if (semNum == 1) {
        return ({ start: sem1StartDate, end: sem1EndDate })
    } else {
        return ({ start: sem2StartDate, end: sem2EndDate })
    }

}


//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const CustomBtn = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${COLOR.black300};
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 4px;
    outline: none;

    &:hover {
        color: ${COLOR.green100};
    }
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;


// border-right-color:${COLOR.black100}
const OverflowOne = styled.div`
    height: auto;
    width: 417px;
    display: inline-block;
    border-right: 1px solid black;
    @media (max-width: 634px){
    
    }
`;
const OverflowTwo = styled.div`
    height: auto;
    display: inline-block;
    @media (max-width: 634px) {
       
    }
`;

const AssignmentDeleteWrapper = styled.div`
   cursor:pointer;
   margin-top: 5px;
   opacity:0;
`;

const TableCell = styled.div`
    text-align: center;
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    overflow:hidden;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const DueDateTableCell = styled(TableCell)`
   background-color: ${rowThreeColor};
   color: white;
   height: 30px; 
   font-size: 12px;
   font-weight: bold;
   border-right: 1px solid ${COLOR.black100};
   text-align: center;
   justify-content: center !important;
`;
const AssignmentTableCell = styled(TableCell)`
   background-color: ${p => p.assignmentActive ? rowTwoColor : COLOR.redishpink};
   color: white;
   height: 30px; 
   font-size: 12px;
   font-weight: bold;
   border-right: 1px solid ${COLOR.black100};
   text-align: center;
   justify-content: center !important;
   cursor: pointer;
`;
const BookLabelTableCell = styled(TableCell)`
   background-color: ${rowOneColor};
   color: white;
   height: 30px; 
   font-size: 10px;
   font-weight: bold;
   border-right: 1px solid ${COLOR.black100};
   text-align: center;
   justify-content: center !important;
   cursor: pointer;
   padding-left: 4;
   padding-right: 4;
`;
const AverageTableCell = styled(TableCell)`
   width:208px;
   font-size: 14px;
   height:${TABLE_CELL_HEIGHT};
`;
const UserNameCellTableCell = styled(TableCell)`
   width:208px;
   color:${p=>p.userActive?"inherit":COLOR.radish100};
   height:${TABLE_CELL_HEIGHT};
`;

const TableCellSemester = styled.div`
    text-align: center;
    align-items: center;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColorSemester(p.active)};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const TableCellExam = styled.div`
    text-align: center;
    align-items: center;
    background-image: linear-gradient(90deg, ${COLOR.PinkExam} 0%, ${COLOR.OrangeExam} 100%); 
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${p => p.header ? '16px' : '48px'};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    justify-content: center;
    &:first-child {
        justify-content: flex-start;
    }
    &:hover ${AssignmentDeleteWrapper} {
        opacity: ${p => p.disabled ? 0 : 1};
    }
`;

const TableCellEdit = styled.div`
    align-items: center;
    background-color: ${p => !p.header && (p.active ? '#03989e82' : 'transparent')};
    color: ${p => p.header && (p.active ? COLOR.green100 : COLOR.black500)};
    display: flex;
    font-size: ${p => p.header && '11px'};
    font-weight: ${p => p.active ? 600 : 500};
    height: ${TABLE_CELL_HEIGHT};
    justify-content: ${p => p.start ? 'flex-start' : 'center'};
    min-width: ${p => p.minWidth ? `${p.minWidth}px` : p.width};
    text-transform: ${p => p.header && 'uppercase'};
    transition: all 300ms ease;
    white-space: nowrap;
    width: ${p => p.width || '56px'};
    border-right: 1px solid ${COLOR.black100};
    &:first-child {
        justify-content: flex-start;
    }
    &:hover {
        color: white;
    }
`;
const AssignmentLabel = styled.button`
  border:none;
  background-color:transparent;
  width:100%;
  cursor:pointer;
  overflow:hidden;
`;
const OneLogoRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
`;

const TableRow = styled.div`
    align-items: center;
    background-color: ${p => p.active ? '#03989e82' : p.index % 2 === 0 ? 'rgb(243, 249, 255)' : 'transparent'};
    border-bottom: 1px solid;
    color: ${COLOR.black200};
    display: flex;
    flex-direction: row;
    width: auto;
`;

const Table = styled.div`
    padding-bottom: 0px;
`;


const Wrapper = styled.div`
    width: 100%;
`;
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
`;
const TbgImgWrapper = styled.div`
    height: 69px;
    width: 100%;
    display: flex;
    justify-content: center;
`
const TbgImage = styled.img`
    width: 100%;
    height: 135%;
`
const Tab = styled.button`
    border: none;
    background-image: ${p => p.bgColor ? `linear-gradient(to right, ${p.bgColor} , ${p.bgColor})` : getBgColor(p.active)};
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;
const TabExam = styled.button`
    border: none;
    background-image: linear-gradient(90deg, ${COLOR.PinkExam} 0%, ${COLOR.OrangeExam} 100%); 
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px 0px;
    text-transform: uppercase;
    width: 150px;
    color: white;
    margin-right: 8px;
    border-radius: 3px;
`;
const SyncTab = styled(Tab)`
    justify-self: flex-end;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    margin-left:5px;
    margin-right:5px;
    height: 35px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
`;
const BookNameTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  `;

const TbgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 93px;
    background-color: ${COLOR.blueish100};
    border: 3px solid ${COLOR.indigo400};
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    `
const TbgIconWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
    `
const TbgIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
}
    `
const OverviewIcon = styled.div`
    width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: center;
    margin-left: -2.4px;
    margin-top: -2.5px;
    background-image: url(${p => p.src});
    background-position: center;
    background-size: 2880% 288%;
    background-repeat: no-repeat;
}
    `
const TbgTitle = styled.span`
    color: ${COLOR.white};
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    `
const TbgTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    `
const TbgList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 14px;
`
const TbgListItem = styled.li`
    color: ${COLOR.white};
    font-size: 10px;
    font-weight: 400;
    `

const Logo = styled.img`
  width: 60px;
  border-radius: 50%;
`;
const OneWayText = styled.span`
  color: #424447;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  text-transform: uppercase;
  line-height: 20px;
`;

const AssignmentTxt = styled.div`
    font-size: 14px;
    margin-left: 20px;
    font-weight: 600;
`;
const NameContent = styled.span`
    color: #fff;
    margin-left: 10px;
    width: 220px;

`;
const StudentNameTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    height:40px;
    padding-right: 20px;;
    `;
const QuestionTab = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(128,161,90);
    width: 95%;
    height: 25px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
`;
const StudentName = styled.span`
    color: #fff;
    font-size: 12px;
    background-color: rgb(89,118,243);
    width: 95%;
    height: 25px;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 5px;
`;
const HeaderTab = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:${COLOR.indigo100};
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:0px 5px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const HeaderTabP = styled.div`
    height:25px;
    border:1px solid white;
    border-radious:5px;
    background-color:rgb(11,9,84);
    color: white;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 3px 5px;
    display:flex;
    overflow: hidden;
`;
const TableTopBtnCont = styled.div`
    justify-content: center;
    align-items: center;
    padding-left: 1.5%;
    overflow: hidden;
    display: flex;
    padding-left:5px;
    padding-right:5px;
`;
const TopTabCont = styled.div`
    display:flex;
    flex-direction:row;
`;
const TotalNStudentTxt = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
    white-space:pre-line;
    `;
const Exam2TableHeader = styled(TableHeader)`
    background-color:rgb(54,58,78);
    height:45px;
    border-radius:5px;
    align-items:center;
 

`;
const ChartContainer = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap:wrap;
    @media (max-width: 992px) {
       width:100%;
      }
    
`;
const ChartContainerInner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:50px;
    margin-left:10px;
    margin-right:10px;
`;
const ChartLabel = styled.span`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: -20px;
`;
const SearchBox = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 7px;
  color: ${COLOR.white};
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  outline: none;
  padding: 2px 16px;
  width: 90%;
  margin-right: 2px;
  margin-left: 2px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
  }
  :-ms-input-placeholder {
    color: #fff;
  }
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
const SearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color:rgb(126,112,165);
    color: ${COLOR.green};
    font-size: 14px;
    align-items: center;
    width:100%;
    padding-right:12px;
    height: 30px;
    @media (max-width: 992px) {
       min-width:200px;
       margin-bottom:30px;
      }
`;
const ExamTableSection1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    margin-top:10px;
    `;
const ExamTableSection2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 992px) {
        width: 100%;
    }
    `;
const TableItem = styled.td`
    height:40px;
    width: 25%;
    border: 0.1px solid rgba(0,0,0,0.2);
    border-collapse: collapse;
    text-align:center;
    padding-top: 10px;
    `;
const ExamGradeTable = styled.table`
  border-collapse: collapse;
`;
const ExamRow = styled.tr`
    display: flex;
    flex-direction: row;`;
const PlacementText = styled.span`
       color:${p => p.textColor ? p.textColor : COLOR.gray100}
    `;
const GraphRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:20px;
   @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    }

`;
const ExamOverflowTwo = styled(OverflowTwo)`
    width:90%;
    border-right: 0px;
    @media (max-width: 992px) {
        width:100%;
    }
    `;
const CloseBtn = styled.span`
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    align-self: flex-end;
    display:flex;
    flex-direction:row;
    color:rgb(197,2,91);
    font-weight:bold;
    justify-content: flex-end;
    `;
const ExamGradeSec = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image:url(${TableBg});
    padding:16px;
`;
const Rotate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 300ms ease;
  width: 16px;
`;
const SortingMenu = styled.span`
  background-color: ${(p) => (p.active ? COLOR.indigo100 : COLOR.white)};
`;
const ChartPerText = styled.span`
    position: absolute;
    top: -10px;
    font-size: 11px;
`;
const PaddedBtn = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    padding: 8px;
    text-align: left;
    width: 100%;
`;
const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    width: 90%;
    border-right: none;
    margin-bottom: 52px;
    @media (max-width: 634px){
        width: 100%;
        overflow: scroll;
    }
`;
const TableWrapperInner2 = styled.div`
    overflow: scroll;
    overflow-y: hidden;
    @media (max-width: 634px){
        overflow: visible;
        
    }
    ::-webkit-scrollbar {
        width: 3px;
        height: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
`;
const AssignmentLabelText = styled.span`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Gradebook
//
//  -- Description
//  District and class announcements
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <Gradebook />
//



const sortname = (a, b) => {
    const nameA = a?.lastName?.toLowerCase()?.replace(/\s/g, '');
    const nameB = b?.lastName?.toLowerCase()?.replace(/\s/g, '');;
    if (nameA < nameB) { return -1 }
    else if (nameA > nameB) { return 1 }
    else { return 0 }
}
const SemesterToShow = () => {
    const currentYear = moment().get("year");
    const lastYear = currentYear - 1;
    const semYearToShow = { sem1: '', sem2: '' }
    const { sem1StartDate, sem1EndDate } = semeterDateByYear(currentYear)
    const currentSem = getCurrenSem();

    if (moment().isSameOrAfter(sem1StartDate) && moment().isSameOrBefore(sem1EndDate)) {
        semYearToShow.sem1 = getKey(1, currentYear);
        semYearToShow.sem2 = getKey(2, currentYear);
    } else {
        semYearToShow.sem1 = getKey(1, lastYear);
        semYearToShow.sem2 = currentSem;
    }
    return semYearToShow;

}
const defaultAssignmentData = {}
defaultAssignmentData[getCurrenSem()] = new Array(8).fill({}, 0, 8)

const hasGradeInA_F = (val) => {
    const gradeLookup = {
        'a': 90, 'b': 70, 'c': 50, 'd': 30, 'f': 10
    };

    if (typeof val === "string") {
        const lowerVal = val.toLowerCase();
        if (lowerVal in gradeLookup) {
            return {
                value: gradeLookup[lowerVal],
                hasValue: true
            };
        }
    }

    return {
        value: val,
        hasValue: false
    };
};


const GradeTable = ({
    activities,
    currentClass,
    currentOrg,
    dataLoading,
    quizzes,
    users,
    reviewAssignment,
    userReviewAssignment,
    setUserData,
    submissions,
    toggleUserDrawer,
    setEditing,
    toggleChangePasswordDrawer,
    booksAllList,
    isReviewAccount,
    handleEditQuiz,
    handleEditActivity,
    currentUser,
    userPreference,
    classActivities,
    classQuizzes,
    setGradeViw,
    setExamData,
    setGradeData,
    getAssignmentCreation,
    orderBy,
    editPrevSemData,
    setCurrentSem
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const orgPermissions = useCurrentOrgPermission()
    const story = useStory()
    const bookPermissions = _.get(orgPermissions, "books", [])
    const currentSem = getCurrenSem()
    const schoologyAuth = useSelector(state => state.schoologyAuth);
    const client = new Schoology(schoologyAuth);
    const [open, setModalState] = useState(false);
    const [dectiavateByStudentModal, setDectivateByStudent] = useState({open: false, assignmentData: {}});
    const [gradePopulateModal,setGradePopulateModal] = useState(false);
    const [inviteParentModal,setInviteParentModal] = useState(false);
    const [selectedPopulateAssignment,setSelectedPopulateAssignment] = useState(null);
    const [loaderPopulateAssignment,setloaderPopulateAssignment] = useState(null);
    const [schoologyGradeCategory,] = useSchoologyGradeCategory()
    const [gradeToAll,setGradeToAll] = useState(null);
    const  { fetchClassGameActivity } = useClassGameActivity()
    const exams = useExams()
    const sortExam = () => {
        if (exams) {
            return exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
        } else {
            return [];
        }
    }
    const [passwordToView, setPasswordToView] = useState({
        username: "",
        password: "",
        message: '',
        open: false
    });
    const [selectedUser, setSelectedUser] = useState({});
    const [semAssignment, setSemAssignment] = useState(defaultAssignmentData);
    const [activeSemData, setActiveSemData] = useState(new Array(8).fill({}, 0, 8));
    const scale_1_5 = userPreference?.gradePref == "scale_1_5"
    const gradebook_a_f = userPreference?.gradePref == "gradebook_a_f"
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [activeGradeTab, setActiveGradeTab] = useState("")
    const [filteredStudent, setFilteredStudent] = useState([])
    const [activeSem, setActiveSem] = useState(currentSem);
    const isSchoologyUser = !!(currentUser && currentUser.schoologyid)
    const isCleverUser = !!(currentUser && currentUser.cleverId);
    const isCanvasUser = !!(currentUser && currentUser.canvas_id);
    const isGoogleUser = !!(currentUser && currentUser.googleId);
    const isClassLinkuser = !!(currentUser && currentUser.classlinkid);
    const isUserFromOtherPlatform =  isSchoologyUser || isCleverUser || isGoogleUser || isClassLinkuser || isCanvasUser
    const [listStudentUser, setListStudentUser] = useState(users && users.length > 0 ? filter(users, x => x.role === 'student' && x.active).sort(sortname) : new Array(1).fill({}, 0, 1))
    const [deleteClassAssignmentToggle, setDeleteClassAssignmentToggle] = useState(false);
    const [assignmentData, setAssignmentData] = useState(null)
    const [deleteAssignmentLoading, setSeleteAssignmentLoading] = useState(false);
    const [nameShortType, setNameShortType] = useState(NAME_SHORTING[0]);
    const videos = useVideos()
    const resources = useResources()
    const [openPop, setOpen] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState('');
    const [schoologyAssignment, listSchoologyAssignment] = useSchoologyAssignment()
    const [activeIndex, setActiveIndex] = useState(-1)
    const inputRefs = useRef([]);
    const inputCells = useRef([]);
    const inputRowCells = useRef([]);
    const nameCellRefs = useRef([]);
    const [loginAsUserId,setLoginAsUserId] = useState(null);
    const [loginasLoader,setLoginasLoader] = useState(false);
    const [loginAsModal,setLoginAsModal] = useState(false);
    let CountSemester = 0
    const orderedActiveSemData = useMemo(() => {
        const val = _.orderBy(filter(activeSemData, k => k.dueDate), ["dueDate"], [orderBy])
        val.push(...filter(activeSemData, k => !k.dueDate))
        return val;
    }, [orderBy, activeSemData])
    const numRows = listStudentUser.length;
    const numCols = orderedActiveSemData.length;
    const sortByName = (a, b) => {
        const sortingFunctions = {
            [NAME_SHORTING[0].key]: (nameA, nameB) => nameA.localeCompare(nameB, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[1].key]: (nameA, nameB) => nameB.localeCompare(nameA, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[2].key]: (nameA, nameB) => nameA.localeCompare(nameB, 'en', { sensitivity: 'base' }),
            [NAME_SHORTING[3].key]: (nameA, nameB) => nameB.localeCompare(nameA, 'en', { sensitivity: 'base' }),
        };
    
        const getKey = (type) => {
            return (type === NAME_SHORTING[2].key || type === NAME_SHORTING[3].key) ? 'firstName' : 'lastName';
        };
    
        const key = getKey(nameShortType.key);
    
        const getNormalizedString = (obj, key) =>
            obj[key].toLowerCase().replace(/\s/g, "") +
            obj[key === "firstName" ? "lastName" : "firstName"]
                .toLowerCase()
                .replace(/\s/g, "");
    
        const nameA = getNormalizedString(a, key);
        const nameB = getNormalizedString(b, key);
    
        return sortingFunctions[nameShortType.key](nameA, nameB);
    };
    const getUserName = (usr) => {
        if (!usr || !has(usr, "firstName")) return "";

        const firstName = get(usr, "firstName", "");
        const lastName = get(usr, "lastName", "");

        if (nameShortType.key === NAME_SHORTING[2].key || nameShortType.key === NAME_SHORTING[3].key) {
            return `${firstName}, ${lastName}`;
        }

        return `${lastName}, ${firstName}`;
    };
    useEffect(() => {
        handleSetgradeData()
    }, [users, activeSemData, submissions, activeGradeTab])
    useEffect(() => {
        const allstudents = _.uniqBy(filter(users, x => x.role === 'student').sort(sortByName), "username");
        const defaultStudent = [{}];  
        setListStudentUser(allstudents.length > 0 ? allstudents : defaultStudent);
        setFilteredStudent(allstudents.length > 0 ? allstudents : defaultStudent);

    }, [users])
    useEffect(() => {
        setFilteredStudent(filteredStudent.sort(sortByName))
        setListStudentUser(listStudentUser.sort(sortByName))
        setTimeout(forceUpdate, 500)

    }, [nameShortType])

    useEffect(() => {
        const toShowSemObj = SemesterToShow();
    
        if (!classActivities || !classQuizzes) return;
    
        const uniqueActivity = uniqBy(classActivities, "activityId");
        const uniqueQuiz = uniqBy(classQuizzes, 'quizId');
        const archived_books = get(currentClass,"archived_books",[])
        let _assignments = [...uniqueActivity, ...uniqueQuiz].filter(k => {
            if (archived_books&&archived_books.some(abk => abk.bookId === get(k, "data.bookId") || abk.bookId === get(k, "data.chapter"))) {
                return false;
            }
            if (bookPermissions.length === 0) {
                return true;
            }
            const bookaccess = _.find(bookPermissions, ["bookId", _.get(k, "data.bookId")]);
            return bookaccess ? bookaccess.available : true;
        })
    
        const _sortedAssignments = _assignments.slice().sort((a, b) => moment(a.dueDate).diff(b.dueDate));
        
        let semData = {};
        _sortedAssignments.forEach(assignment => {
            const year = moment(assignment.dueDate).format("YYYY");
            const { sem1StartDate, sem1EndDate, sem2StartDate, sem2EndDate } = semeterDateByYear(year);
            const sem1 = semData[getKey(1, year)] || [];
            const sem2 = semData[getKey(2, year)] || [];
            
            const currentDate = moment(assignment.dueDate);
    
            if (currentDate.isBetween(sem1StartDate, sem1EndDate, 'day', '[]')) {
                sem1.push(assignment);
            } else if (currentDate.isBetween(sem2StartDate, sem2EndDate, 'day', '[]')) {
                sem2.push(assignment);
            }
    
            semData[getKey(1, year)] = sem1;
            semData[getKey(2, year)] = sem2;
        });
         Object.keys(semData).forEach(key=>{
            if (semData[key] !== undefined && semData[key].length < 8) {
                const len = 8 - semData[key].length;
                const arr = Array.from({ length: len }, () => ({}));
                semData[key] = semData[key].concat(arr);
              }
         })
        const activeSemData = semData[currentSem] || new Array(8).fill({}, 0, 8);
        setActiveSemData(activeSemData);
    
        const tempSemData = {};
        Object.keys(toShowSemObj).forEach(key => {
            const dt = semData[toShowSemObj[key]] || new Array(8).fill({}, 0, 8);
            tempSemData[toShowSemObj[key]] = dt;
        });
    
        setSemAssignment(tempSemData);
    }, [classActivities, classQuizzes,currentClass]);
    
    useEffect(() => {
        getPref()
        getGradesFromScoology()
        getAssignmentFromSchoology()
    }, [classActivities, classQuizzes])
    const getPref = async () => {
        await dispatch(handleGetUserPreference(currentUser?.id));

    }
    useEffect(() => {
        setCurrentSem(currentSem == activeSem)
    }, [activeSem, currentSem])
    const PopPupExam = (message) => {
        setPopupMessage(message)
        setOpen(true);

    }

    const handleKeyDown = useCallback(
        (e) => {
            if(document.querySelector('.ant-drawer')||document.querySelector('.one_game_open')) return;
            const { key } = e;
            const hasref = inputRefs.current.find(e=>e&&e.ref==document.activeElement)
            const currentInput = inputRefs.current[activeIndex]
            const row = Math.floor(activeIndex / numCols);
            const StartRow = row * numCols;
            const EndRow = StartRow + numCols;
            switch (key) {
                case "ArrowUp":
                case 38:
                    if (activeIndex / numCols >= 1) {
                        onBlurInput()
                        setActiveIndex(activeIndex - numCols);
                        // onFousInput(activeIndex - numCols);
                    }
                    break;
                case "ArrowDown":
                case 40:
                    // Move down a row, add num cols to index
                    if (activeIndex < numRows * numCols - numCols) {
                        onBlurInput()
                        setActiveIndex(activeIndex + numCols);
                        // onFousInput(activeIndex + numCols);
                    }
                    break;
                case "ArrowRight":
                case 39:
                    if (activeIndex < (EndRow-1)) {
                        setActiveIndex(activeIndex + 1);
                        onBlurInput()
                        // onFousInput(activeIndex + 1);
                    }
                    break;
                case "ArrowLeft":
                case 37:
                    if (activeIndex > StartRow) {
                        setActiveIndex(activeIndex - 1);
                        onBlurInput()
                        // onFousInput(activeIndex - 1);
                    }
                    break;
                case "Enter":
                case 13:
                    if (hasref) {
                        if (activeIndex < numRows * numCols - numCols) {
                            onBlurInput()
                            setActiveIndex(activeIndex + numCols);
                        } else {
                            onBlurInput()
                        }
                    }else{
                        if(currentInput&&currentInput.ref.disabled){
                            if (activeIndex < numRows * numCols - numCols) {
                                setActiveIndex(activeIndex + numCols);
                            }  
                        }else{
                            onFousInput(activeIndex);
                        }
                    }
                    break;
                case "Escape":
                    onBlurInput()
                    break;
                default:
                    break;
            }
        },
        [activeIndex, numRows, numCols]
    );

    const onFousInput = (index) => {
        const inputRef = inputRefs.current[index];
        if (inputRef) {
            inputRef.focus();
        }
    }
    const onBlurInput = () => {
        document.activeElement.blur();
    }
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);
    const handleClose = () => {
        setOpen(false);
    };
  
    const updateSubmission = async (subm) => {
        if (!subm || !subm.userId) return;
        await dispatch(handleUpdateSubmission(subm?.id, subm, subm))
    }
    const getGradesFromScoology = async () => {
        try {
            if (currentClass?.sectionId) {
                await handleSyncSchoologyClassesGrades(currentUser.id, currentClass.id);
                await dispatch(handleListClassSubmissions(currentClass?.id))
            }

        } catch (error) {
            console.log(error, "bid-err");
        }

    }
    const history = useHistory();
    const bookUnits = useBookUnits()

    const generateUrl = (CHAPTER_ID, page, activity, quiz) => {
        const storyUrl = getStoryUrl(story, CHAPTER_ID, page, quiz, activity)
        const backToLabel = BACK_TO_BOOK[CHAPTER_ID]
        const BALCON_PAGES = _.get(_.find(bookUnits, ["bookId", CHAPTER_ID]), "pages", [])
        const bookitem = _.find(BALCON_PAGES, k => parseInt(k.start) <= page && parseInt(k.finish) >= page)
        const url = generateUrlForNonEbook(bookitem?.id, page, activity, quiz, backToLabel, null, CHAPTER_ID)
        if (url && backToLabel) {
            return url;
        }
        else if (storyUrl) {
            return storyUrl;
        } else {
            return null;
        }
    }
    const goToBook = (chapter, pageId) => {
        const generatedUrl = generateUrl(chapter,pageId, null, null) 
        if (isVideo(chapter, resources)) {
            history.push(`/video?id=${chapter}&quiz=null`);
        } else if (generatedUrl){
            history.push(generatedUrl);
        }
        else {
            history.push(`/ebook?chapter=${chapter}&page=${pageId}&activity=null&quiz=null`);
        }
    }
    const booknav = (assignment, is_game , is_debate ) => {
        const isActive = get(assignment, "active", true)
        const isUngraded = get(assignment, "ungraded", false)
        if (is_game) {
            return (<Menu>
                {!is_debate && <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        goToBook(assignment.bookId, assignment.pageId)
                    }}>
                        Go to book
                    </PaddedBtn>
                </Menu.Item>}
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (assignment && Object.keys(assignment).length > 0) {
                            setDeleteClassAssignmentToggle(true)
                            setAssignmentData(assignment)
                        }
                    }}>
                        Delete
                    </PaddedBtn>
                </Menu.Item>
                {!isUngraded && <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn
                        onClick={() => {
                            setGradePopulateModal(true)
                            setSelectedPopulateAssignment(assignment);
                        }}>
                        Populate the grade
                    </PaddedBtn>
                </Menu.Item>}
            </Menu>)
        }
        return (
            <Menu>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (assignment?.quizId) {
                            handleEditQuiz(assignment)
                        } else if (assignment.activityId) {
                            handleEditActivity(assignment)
                        }
                    }}>
                        Edit
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        goToBook(get(assignment, "data.chapter"), get(assignment, "data.page"))
                    }}>
                        Go to book
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn onClick={() => {
                        if (assignment && Object.keys(assignment).length > 0) {
                            setDeleteClassAssignmentToggle(true)
                            setAssignmentData(assignment)
                        }
                    }}>
                        Delete
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn style={{ color: isActive ? COLOR.black : COLOR.red }}
                        onClick={() => {
                            if (get(assignment, "data.typeId") == "quiz" || get(assignment, "data.typeId") === "quizresource") {
                                activeDeactiveQuizzes(get(assignment, "id"), !isActive)
                            } else {
                                activeDeactiveActivites(get(assignment, "id"), !isActive)
                            }
                        }}>
                        {isActive ? "Deactivate" : "Activate"}
                    </PaddedBtn>
                </Menu.Item>
                <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn 
                        onClick={() => setDectivateByStudent({open: true, assignmentData: assignment })}>
                         Deactivate for student
                    </PaddedBtn>
                </Menu.Item>
                {!isUngraded && <Menu.Item style={{ padding: '0px' }}>
                    <PaddedBtn
                        onClick={() => {
                            setGradePopulateModal(true)
                            setSelectedPopulateAssignment(assignment);
                        }}>
                        Populate the grade
                    </PaddedBtn>
                </Menu.Item>}
            </Menu>
        );
    };


    const activeDeactiveQuizzes = async (id, active) => {
        await dispatch(handleChangeStatusQuizzes(id, active))
    }
    const activeDeactiveActivites = async (id, active) => {
        await dispatch(handleChangeStatusActivity(id, active))

    }
    const getAssignmentFromSchoology = async () => {
        try {
            if (currentClass?.sectionId) {
                const assignment = await client.getSectionAssignment(currentClass.sectionId)
                if (assignment && Array.isArray(assignment.assignment)) {
                    let _assignment = assignment.assignment;
                    const updateTolausd = [];
                    const deleteFromLausd = []
                    const schoologActivityyAssignment = filter(classActivities, k => k.sAssignmentId)
                    const schoologQuizAssignment = filter(classQuizzes, k => k.sAssignmentId)
                    const allAssignment = [...schoologActivityyAssignment, ...schoologQuizAssignment]
                    map(_assignment, async a => {
                        let _lausdAssignment = find(allAssignment, k => k.sAssignmentId == a.id)
                        let s_time = new Date(parseInt(`${a.last_updated}000`))
                        if (_lausdAssignment) {
                            let l_time = _lausdAssignment.modified ? new Date(_lausdAssignment.modified) : new Date(parseInt(`${_lausdAssignment.createdAt}`))
                            if (moment(l_time).isBefore(moment(s_time)) && moment().diff(moment(l_time), "minutes") > 3) {
                                let s_due = new Date(a.due).toISOString()
                                let l_due = _lausdAssignment.dueDate
                                if (a.title != _lausdAssignment.displayName || s_due != l_due) {
                                    updateTolausd.push({ ..._lausdAssignment, displayName: a.title, dueDate: s_due })
                                }
                            }
                        }
                    })
                    // map(allAssignment, a => {
                    //     const hasAssignment = find(_assignment, k => k.id == a.sAssignmentId)
                    //     if (!hasAssignment) {
                    //         deleteFromLausd.push(a)
                    //     }
                    // })
                    // if (deleteFromLausd.length > 0) {
                    //     await Promise.all(deleteFromLausd.map(async a => {
                    //         if (a.quizId) {
                    //             await dispatch(handleDeleteClassQuiz(a.quizId, currentClass.id))
                    //         } else {
                    //             await dispatch(handleDeleteClassActivity(a.activityId, currentClass.id))
                    //         }
                    //     }))
                    // }
                    if (updateTolausd.length > 0) {
                        await Promise.all(updateTolausd.map(async a => {
                            if (a.quizId) {
                                await dispatch(handleUpdateClassQuiz(a.id, a))
                            } else {
                                await dispatch(handleUpdateClassActivity(a.id, a))
                            }
                        }))
                    }
                }

            }
        } catch (error) {
            console.log(error, "bid-err");
        }
    }
    const checkSchoologyAssignment = (assignment) => {
        if (!assignment?.sAssignmentId || !assignment) return null;
        const is_game = assignment.type === "debate" || assignment.type === "game"
        const hasAssignment = find(schoologyAssignment, k => k.id == assignment?.sAssignmentId)
        return hasAssignment || is_game ? null : `Deleted from schoology!`
    }

    // if (students.length < 1) {
    //     let arr = new Array(1).fill({}, 0, 1)
    //     students.push(...arr)
    // }
    const studentSubmissions = groupBy(submissions, 'userId');
    const getWeightedGrade = (grade, weight) => {
        if (weight === 0) {
            return 0;
        }
        return (grade) * weight / 100;
    };
    const getWeightageFroQuiz = (assignment) => {
        return classQuizzes?.find(x => x.quizId === assignment.id)?.weightage
    }
    const getWeightageForActivity = (assignment) => {
        return classActivities?.find(x => x.activityId === assignment.id)?.weightage
    }
    const getWeightageForAssignment = useCallback((assignemnt) => {
        let weightage = 100;
        if (assignemnt.typeId === 'quiz') {
            let calculatedWeightage = getWeightageFroQuiz(assignemnt);
            if (!isNaN(calculatedWeightage) && calculatedWeightage > 0 && calculatedWeightage <= 100) {
                weightage = calculatedWeightage
            }
        } else {
            let calculatedWeightage = getWeightageForActivity(assignemnt);
            if (!isNaN(calculatedWeightage) && calculatedWeightage > 0 && calculatedWeightage <= 100) {
                weightage = calculatedWeightage
            }
        }
        return weightage;
    }, [classQuizzes, classActivities]) 
    const getSkipAssignment = (data) => {
        if(!isSchoologyUser) return data;
        for (const key in data) {
            if (data.hasOwnProperty(key) && Array.isArray(data[key])) {
                const drop_lowest = get(find(schoologyGradeCategory, k => k.id == key), "drop_lowest", 0)
                data[key].sort((a, b) => a.grade - b.grade);
                data[key].splice(0, drop_lowest);
            }
        }
        return data;
    }

    const calculateGrade = useMemo(() => (userID) => {
        if (!userID) return "-";
        let numberOfAssignment = 0;
        let total = 0;
        const data = studentSubmissions[userID];
        const currentSemSubmitted = map(activeSemData, x => {
            if (!x || !x.classId) return null;
            const sub = find(data, y => y.assignmentId === x.quizId || y.assignmentId === x.activityId)
            const schoologyCategory =  get(find(x.classes,["id",currentClass.id]),"schoologyCategory")
            return sub ? ({ ...sub, assignment: x , schoologyCategory }) : null;
        }).filter(k => k)
        const assignemtBySchoologyCategory = {}
        if(isSchoologyUser){
            map(currentSemSubmitted, x => {
                if( get(x,"assignment.ungraded")) return;
                if (!isNaN(x.grade) && !x.manualGrading) {
                    const grd = getWeightedGrade(parseInt(x.grade, 10), getWeightageForAssignment(x.assignment))
                    if(assignemtBySchoologyCategory[x.schoologyCategory]){                 
                        assignemtBySchoologyCategory[x.schoologyCategory].push({
                            assignemtId:x.assignment.id,
                            grade: x.exceptionStatus > 0 ? 0 : parseInt(grd, 10)
                        })
                    }else{
                        assignemtBySchoologyCategory[x.schoologyCategory] = [{
                            assignemtId:x.assignment.id,
                            grade: x.exceptionStatus > 0 ? 0 : parseInt(grd, 10)
                        }]
                    }
                }
            })
        }
        const skippedAssignment  = getSkipAssignment(assignemtBySchoologyCategory)
        map(currentSemSubmitted, x => {
            if( get(x,"assignment.ungraded")) return;
            const grade_type = get(x,"assignment.grade_type")
            const ispointsystem = grade_type === "points";
            const iscomplete_incomplete = grade_type === "complete_incomplete"
            const max_points = get(x,"assignment.max_point",100)
            const weightage = parseInt(get(x,"assignment.weightage","100") || "100",10)
            if(isSchoologyUser){
                const schoologyCategory = get(x,"schoologyCategory");
                const gradeAssignment  = skippedAssignment[schoologyCategory]
                const hasAssignment = find(gradeAssignment,k=>k.assignemtId == get(x,"assignment.id"))
               if(!hasAssignment) return;
            }
            
            if (x.exceptionStatus != 1 && x.exceptionStatus != 2 && x.exceptionStatus != 5 && x.exceptionStatus != 6 && !x.manualGrading) {
                numberOfAssignment++;
            }
            if (!isNaN(x.grade) && !x.manualGrading) {
                if(ispointsystem){
                    const face_grade =  get(x, "face_grade")
                    if(max_points > 0 && !isNaN(face_grade)){
                        let grd  = (face_grade / max_points ) * 100;
                        if(!isNaN(weightage)){
                            grd =  getWeightedGrade(grd,weightage)
                        }
                        total += x.exceptionStatus > 0 ? 0 : grd;
                    }

                }else if(iscomplete_incomplete){
                      if(parseInt(x.grade, 10)>0){
                        let grd = getWeightedGrade(100,weightage)
                        total += x.exceptionStatus > 0 ? 0 : grd;
                      }
                }else{
                    const grd = getWeightedGrade(parseInt(x.grade, 10), getWeightageForAssignment(x.assignment))
                    if (!isNaN(parseInt(grd, 10))) {
                        total += x.exceptionStatus > 0 ? 0 : grd;
                    }
                }
            }
        })
        const grade = (parseInt(total, 10) / parseInt(numberOfAssignment, 10)).toFixed(0);
        let gradeMarks = grade;
        if (scale_1_5) {
            if (grade !== "NaN") {
                const g = grade <= 0 ? 0 : _.floor((grade - 1) / 20)
                gradeMarks = g > 4 ? 4 : g;
            }
            else {
                gradeMarks = "-";
            }
        }
        if (gradebook_a_f) {
            if (grade !== "NaN") {
                const g = parseInt(grade)
                if (g <= 20 && g >= 0) {
                    gradeMarks = 'F';
                }
                else if (g <= 40 && g >= 21) {
                    gradeMarks = 'D';
                }
                else if (g <= 60 && g >= 41) {
                    gradeMarks = 'C';
                }
                else if (g <= 80 && g >= 61) {
                    gradeMarks = 'B';
                }
                else if (g >= 80) {
                    gradeMarks = 'A';
                } else {
                    gradeMarks = '-'
                }

            }
            else {
                gradeMarks = "-";
            }
        }
        return gradeMarks !== "NaN" ? gradeMarks : '-';
    }, [submissions, activeSem, scale_1_5, classActivities, classQuizzes, activeSemData, gradebook_a_f]);

    const calculateValue = useMemo(() => (assignment, user) => {
        if (!user || !get(assignment, "id")) return ({ value: "--", isTbg: false, isLate: false, manualGrading: false, completeTbg: false  , })
        let isDiffStudent = false;
        const grade_type = assignment?.grade_type;
        const is_game = assignment.type === "game"
        const is_debate = assignment.type === "debate"
        const complete_incomplete = grade_type === "complete_incomplete"
        const point_system =  grade_type === "points"
        const assignment_id = is_game || is_debate ?assignment.session_id:get(assignment, "data.id")
        if (assignment.diff) {
            map(assignment.diff, x => x.userId === user.id ? isDiffStudent = true : null)
        }
        if (Array.isArray(assignment.classes) && assignment.classes.length > 0) {
            const classConfig = find(assignment.classes, ['id', get(currentClass, "id")])
            if (classConfig && classConfig.diff) {
                isDiffStudent = Boolean(find(classConfig.diff.student, k => k == user.id))
            }
        }
        const hasAssigned = is_game || is_debate  ? true : assignment.users == null || assignment.users.length == 0 || assignment.users.includes(user.id)
        const user_submission = find(studentSubmissions[user.id], ['assignmentId',assignment_id ]);
        if (!user_submission) {
            return { value: null, isTbg: false, isLate: false, isDiffStudent, manualGrading: false, completeTbg: false, hasAssigned, is_game , face_grade: null, point_system, complete_incomplete , is_debate}
        } else if (!user_submission.complete && user_submission.pastSubmissions.length === 0 && !is_game && !is_debate) {
            return { value: null, isTbg: false, isLate: false, isDiffStudent, manualGrading: false, completeTbg: false, hasAssigned, is_game ,face_grade: null, point_system, complete_incomplete  , is_debate }
        }
        const retaking = get(user_submission, "retaking", false)
        let score = 0;
        const isTbgObj = find(get(user_submission, "assignment.questions", []), (e) => e.type != "multipleChoice")
        const isTbg = typeof isTbgObj == "object"
        const isAllTab = filter(get(user_submission, "assignment.questions", []), (e) => e.type != "multipleChoice").length === get(user_submission, "assignment.questions.length", 0)
        // const isPartialTbg = isTbg && !isAllTab;
        // const tbgTxt = isTbg ? "TBG" : "-"
        const manualGrading = get(user_submission, "manualGrading")
        const face_grade =  get(user_submission, "face_grade")
        let value =  user_submission && user_submission.grade >= 0 ? user_submission.grade : '-'
        value = manualGrading && (isAllTab&&!is_game && !is_debate ) ?"-":value;
        value = score > 0 ? score : value
        value = retaking && value == 0 ? "-" : value
        let isLate = false
        if (user_submission) {
            isLate = user_submission.isLate;
        }
        if (!isNaN(value) && scale_1_5) {
            if(value!== null && value !== ''){
                let _g = value <= 0 ? 0 : _.floor((value - 1) / 20)
                value = `${_g > 4 ? 4 : _g}`
            }
        }
        else if (!isNaN(value) && gradebook_a_f && value !== null) {
            const g = parseInt(value)
            if (g <= 20 && g >= 0) {
                value = 'F';
            }
            else if (g <= 40 && g >= 21) {
                value = 'D';
            }
            else if (g <= 60 && g >= 41) {
                value = 'C';
            }
            else if (g <= 80 && g >= 61) {
                value = 'B';
            }
            else if (g >= 80) {
                value = 'A';
            } else {
                value = '-'
            }
        }
        return { value, isTbg, isLate, isDiffStudent, manualGrading, completeTbg: isAllTab, hasAssigned , face_grade , point_system , complete_incomplete ,is_game  , is_debate }
    });

    const userNav = (user) => {
        return (
            <Menu className='allGradeBookDropDwn'>
                {<Menu.Item
                    disabled={!user || !user.active}
                    style={{ color:!user.active?COLOR.red:COLOR.black100}}
                    onClick={() => {
                        if (user && user.id) {
                            setLoginAsUserId(user.id)
                            setLoginAsModal(true);
                        }
                    }}>
                    Login as {!user.active?"(Inactive)":""}
                </Menu.Item>}
                 <Menu.Item onClick={() => {
                  if(user&&user.id){
                    setInviteParentModal(true)
                    setSelectedUser(user);
                  }
                }}>
                    Invite parent
                </Menu.Item>
                {!isUserFromOtherPlatform && <Menu.Item onClick={() => {
                    setUserData({
                        editing: true,
                        ...user
                    });
                    setEditing(true);
                    toggleUserDrawer(true);
                }}>
                    Edit
                </Menu.Item>}
                {!isUserFromOtherPlatform && <Menu.Item onClick={() => {
                    setUserData({
                        ...user
                    });
                    toggleChangePasswordDrawer(true);
                }}>
                    Change password
                </Menu.Item>}
                <Menu.Item onClick={() => {
                    const filterActiveSemData = orderedActiveSemData.filter(obj => Object.keys(obj).length > 0);
                    userReviewAssignment(user, filterActiveSemData,  studentSubmissions)
                }}>
                    View Submissions
                </Menu.Item>
                <Menu.Item onClick={() => {
                    if (has(user, "password") && user.password && user.password.length > 0) {
                        setPasswordToView({
                            username: user.username,
                            password: base64.decode(user.password),
                            open: true,
                            message: ''
                        })
                    } else {
                        setPasswordToView({
                            username: '',
                            password: '',
                            open: true,
                            message: `No password is stored for username:- ${user.username}.`
                        })
                    }
                }}>
                    View username and  password
                </Menu.Item>
                {!isUserFromOtherPlatform && <Menu.Item onClick={() => {
                    setSelectedUser(user);
                    setModalState(true);
                }}>
                    Remove
                </Menu.Item>}
            </Menu>
        );
    };
    const sortingNav = (user) => {
        return (
            <Menu>
                {NAME_SHORTING.map(item => (<Menu.Item key={item.title} onClick={() => {
                    setNameShortType(item)
                }} style={{ backgroundColor: item.key == nameShortType.key ? COLOR.indigo600 : "#fff", color: item.key == nameShortType.key ? COLOR.white : COLOR.gray100 }}>
                    {item.title}
                </Menu.Item>))}
            </Menu>
        );
    };
    const getCellStyle = useCallback((isLate, isDiffStudent, isActiveCol) => {
        if (isLate) {
            if (isActiveCol) {
                return ({ backgroundColor: "rgba(255,0,0,0.2)", border: "2px solid white" })
            }
            return ({ backgroundColor: "rgba(255,0,0,0.2)" })
        } else if (isDiffStudent) {
            if (isActiveCol) {
                return ({ backgroundColor: "#00ff134f", border: "2px solid white" })
            }
            return ({ backgroundColor: "#00ff134f" })
        }
        else if (isActiveCol) {
            return ({ border: "2px solid white" })
        }
        else {
            return ({})
        }
    })
    const removeUser = async () => {
        setLoading(true);
        const orgId = currentOrg.parentId !== 'null' ? currentOrg.parentId : currentOrg?.id;
        await dispatch(handleDeleteUserData(selectedUser.id, selectedUser.username, orgId, currentClass.id));
        await dispatch(handleListClassUsers(currentClass.id));
        setSelectedUser({});
        setLoading(false);
        setModalState(false);
    };

    const deleteAssignment = async (e) => {
        e.preventDefault();
        setSeleteAssignmentLoading(true);
        const is_game = assignmentData?.type === 'game' || assignmentData?.type === 'debate'
        try {
            if (assignmentData && assignmentData?.quizId &&!is_game) {
                await dispatch(handleDeleteClassQuiz(
                    assignmentData.quizId,
                    assignmentData.classId
                ));
            } else if (assignmentData && assignmentData.activityId &&!is_game) {
                await dispatch(handleDeleteClassActivity(
                    assignmentData.activityId,
                    assignmentData.classId
                ));
            }else if(is_game){
               await dispatch(handledeleteDebate(assignmentData.session_id))
               await fetchClassGameActivity()
               
            }
            if(assignmentData && assignmentData.canvas_id){
                try {
                    await handleDeleteCanvasAssignment(currentUser.canvas_id,[{
                        canvas_id: assignmentData.canvas_id,
                        canvas_section_id:currentClass.canvas_id,
                        canvas_course_id:currentClass.canvas_course_id
                    }],currentUser.canvas_auth_id);
                } catch (error) {
                    
                }
            }
            if (assignmentData && assignmentData.sAssignmentId && assignmentData.sAssignmentId.length > 0 && currentClass.sectionId) {
                await client.setToken(schoologyAuth);
                await client.deleteAssignment(currentClass.sectionId, assignmentData.sAssignmentId)
                listSchoologyAssignment(currentClass.sectionId)
            }
            if(currentClass.googleId&&assignmentData.googleId){
                await handleDeleteGoogleAssignment(currentUser.googleId,currentClass.googleId,assignmentData.googleId)
            }
            onDeleteSuccess()
        } catch (error) {
            onDeleteSuccess()
        }

    };
    const onDeleteSuccess = () => {
        setDeleteClassAssignmentToggle(false);
        setSeleteAssignmentLoading(false)
        setAssignmentData(null)
    }
    const onExamTabClick = (tab) => {
        setActiveGradeTab(tab);
        setGradeViw("exam")
        if (tab == "exam2") {
            const hExam = get(find(exams, k => k.order == 1), "exams[1]")
            const mExam = get(find(exams, k => k.order == 2), "exams[1]")
            const hStartDate = get(hExam, "startDate")
            const mStartDate = get(mExam, "startDate")
            if (hStartDate && mStartDate) {
                if (moment().isBefore(hStartDate) && moment().isBefore(mStartDate)) {
                    PopPupExam(`Exam is available after ${moment(hStartDate).isBefore(mStartDate) ? moment(hStartDate).format("DD/MM/YYYY") : moment(mStartDate).format("DD/MM/YYYY")}`)
                    return;
                }
            }
        } else if (tab == "exam3") {
            const hExam = get(find(exams, k => k.order == 1), "exams[2]")
            const mExam = get(find(exams, k => k.order == 2), "exams[2]")
            const hStartDate = get(hExam, "startDate")
            const mStartDate = get(mExam, "startDate")
            if (hStartDate && mStartDate) {
                if (moment().isBefore(hStartDate) && moment().isBefore(mStartDate)) {
                    PopPupExam(`Exam is available after ${moment(hStartDate).isBefore(mStartDate) ? moment(hStartDate).format("DD/MM/YYYY") : moment(mStartDate).format("DD/MM/YYYY")}`)
                    return;
                }
            }
        }
       
    }
    const handleSetgradeData = () => {
        try {
            const row1 = ['', '', '', 'Books']
            const row2 = ['', '', '', 'assignment']
            const row3 = ['', 'username', 'password', 'due date/average']
            const userData = []
            map(activeSemData, (assignment, i) => {
                let bookname = getBookDisplayName(booksAllList, assignment?.data?.chapter)
                row1.push(bookname)
                if (assignment?.data?.typeId == 'quizresource') {
                    row2.push(`${assignment?.data?.bookId}(${assignment?.displayName || assignment?.data?.displayName || ""})`)
                } else {
                    row2.push(`${getAssignmentLable(assignment, booksAllList, videos, resources)}(${assignment?.displayName || assignment?.data?.displayName || ""})`)
                }
                if (assignment?.dueDate) {
                    row3.push(`${moment(assignment?.dueDate).format("MMM DD")}`)
                } else {
                    row3.push('')
                }
            })
            map(listStudentUser, (user, i) => {
                let data = []
                data.push(`${user.lastName}, ${user.firstName}`)
                data.push(user.username)
                data.push(user.password ? base64.decode(user.password) : '')
                data.push(calculateGrade(user.id))
                map(activeSemData, (assignment, i) => {
                    let { value } = calculateValue(assignment, user)
                    data.push(value)
                })

                userData[i] = data;
            })
            const finaldata = [row1, row2, row3, ...userData]
            setGradeData(finaldata)
        } catch (error) {
            console.log(error, "error in exam gradebook downolad")
        }
    }
    const onSemClick = (key) => {
        const currentYear = parseInt(moment().format("YYYY"))
        let yearc = parseInt(key.split("-")[1])
        if (key == currentSem || yearc <= currentYear) {
            setActiveSem(key)
            setActiveSemData(semAssignment[key])
        } else {
            setOpen(true)
            const startMonth = key.split("-")[0].includes(1) ? "June" : "January"
            setPopupMessage(`Available ${startMonth}  ${yearc + 1}`)
        }
    }

    const isEditable = useCallback(() => {
        if (editPrevSemData) return false;
        else if (currentSem !== activeSem) {
            const datetime = getSemDateTime(activeSem);
            if (datetime.end.isSameOrBefore(moment())) {
                return datetime.end.isBefore(moment().subtract(1, "month"))
            } else {
                return true;
            }
        } else {
            return false;
        }
    }, [activeSem, currentSem, editPrevSemData])
    const getValue=(hasAssigned,value)=>{
        return hasAssigned ? typeof value === "string" ? value.toUpperCase() : value : "N/A"
    }
    // Table cell rendered start
    const renderTableCell = useMemo(() => {
        return map(listStudentUser, (user, i) => (
            <TableRow   ref={(el) => (inputRowCells.current[i] = el)}  key={get(user,"id") ? get(user,"id") : i + "user"} index={i + 1} style={{ borderLeft: '0px' }}>
                {map(orderedActiveSemData, (assignment, j) => {
                    const { value, isTbg, isLate, isDiffStudent, manualGrading, completeTbg, hasAssigned, face_grade , point_system , complete_incomplete , is_game , is_debate } = calculateValue(assignment, user)
                    const tempData = getAssignmentCreation(assignment, user)
                    const index = i * numCols + j;
                    const ungraded = get(assignment,"ungraded",false)

                    const isActiveUserClass = typeof user?.userclass?.active === "boolean" ? user.userclass.active : false;
                    const has_assignment = is_game || is_debate ? assignment?.id : assignment?.data?.id;
                    const disable_edit = isEditable() || !hasAssigned || !isActiveUserClass || !has_assignment

                    const assignmentId =  is_game || is_debate ?assignment.session_id:assignment?.data?.id
                    return (
                            <TableCellEdit
                                style={getCellStyle(isLate, isDiffStudent)}
                                key={get(assignment,"id") || index}
                                ref={(el) => (inputCells.current[index] = el)}
                                minWidth={TABLE_CELL_MIN_WIDTH}
                            >
                                <GradeEditor
                                    disabled={disable_edit}
                                    assignmentId={assignment?.id ?? (j + "assignment")}
                                    isReviewAccount={isReviewAccount}
                                    data={find(studentSubmissions[user?.id], ['assignmentId', assignmentId])}
                                    reviewAssignment={(v) => { reviewAssignment(v, tempData, listStudentUser, studentSubmissions) }}
                                    tempData={tempData}
                                    submission={find(studentSubmissions[user.id], ['assignmentId', assignmentId])}
                                    userId={user?.id ?? (j + "userr")}
                                    value={getValue(hasAssigned,value)}
                                    scale_1_5={scale_1_5}
                                    gradebook_a_f={gradebook_a_f}
                                    assignments={assignment}
                                    currentClass={currentClass}
                                    user={user}
                                    assignment={assignment}
                                    isTbg={isTbg && manualGrading}
                                    studentSubmissions={studentSubmissions[user.id]}
                                    onFlagMenuViewChange={(visiblity) => {
                                      
                                    }}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    onFocus={() => {
                                        setActiveIndex(index)
                                    }}
                                    onBlur={() => { }}
                                    isLate={isLate}
                                    hasTbg={isTbg}
                                    isDiffStudent={isDiffStudent}
                                    ungraded={ungraded}
                                    face_grade={face_grade}
                                    point_system={point_system} 
                                    complete_incomplete ={complete_incomplete}
                                    submissions={submissions}
                                />

                            </TableCellEdit>
                    )
                })}
            </TableRow>
        ))
    }, [listStudentUser, orderedActiveSemData, submissions, activeSem, currentSem, editPrevSemData,userPreference])

    const renderDueDates = useMemo(() => {
        return (map(orderedActiveSemData, (assignment, i) => (
            <span key={`${i} assignment`}>
                <DueDateTableCell
                    key={`${assignment?.id} - ${i}`}
                    id={assignment?.id}
                    minWidth={TABLE_CELL_MIN_WIDTH}
                >
                    {get(assignment, "dueDate") ? <span>{`${moment(get(assignment, "dueDate")).format("MMM DD")}`}</span> : <span></span>}
                </DueDateTableCell>
            </span>
        )))
    }, [orderedActiveSemData])

    const renderAssignmentLabel = useMemo(() => {
        return (map(orderedActiveSemData, (assignment, i) => {
            const isActive = get(assignment, "active", true)
            const disbaled = !get(assignment, "id")
            const is_game = get(assignment, "type") === 'game';
            const is_debate = get(assignment, "type") === 'debate'
            const cell_style = is_game ? { backgroundColor: COLOR.sky200 } :is_debate?{ backgroundColor: COLOR.tabActive } :{}
            return (<span key={i + "assignment"}>
                <Dropdown overlay={booknav(assignment, is_game || is_debate,is_debate)} disabled={disbaled} placement="bottomRight" trigger={['click']}>
                    <AssignmentTableCell
                        assignmentActive={isActive}
                        key={assignment?.id}
                        id={assignment?.id}
                        header="true"
                        minWidth={TABLE_CELL_MIN_WIDTH}
                        style={cell_style}
                    >
                        <Tooltip title={get(assignment, "displayName", get(assignment, "data.displayName", ""))}>
                            <AssignmentLabelText>{is_game || is_debate ? get(assignment, "displayName", '') : getAssignmentLable(assignment, booksAllList, videos, resources)}</AssignmentLabelText>
                        </Tooltip>
                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                    </AssignmentTableCell>
                </Dropdown>
            </span>)
        }))
    }, [orderedActiveSemData, booksAllList, videos, resources])

    const renderBookLabel = useMemo(() => {
        return (map(orderedActiveSemData, (assignment, i) => {
            const is_game = assignment?.type === "game"
            const is_debate = assignment?.type === "debate"
            const chapter = is_game ?assignment.bookId : assignment?.data?.chapter
            let bookname =  is_debate?'Debate':getBookDisplayName(booksAllList, chapter , true, videos, resources)
            const booktooltip = checkSchoologyAssignment(assignment) ? checkSchoologyAssignment(assignment) : bookname
            return (<span key={i + "assignment"}>
                <BookLabelTableCell
                    key={assignment?.id + i}
                    id={assignment?.id}
                    header="true"
                    minWidth={TABLE_CELL_MIN_WIDTH}
                >
                    <Tooltip title={booktooltip}><AssignmentLabelText>
                        {checkSchoologyAssignment(assignment) ? <Warning style={{ color: COLOR.yellow, fontSize: 12 }} /> : null}  <BookNameTitle>{bookname}</BookNameTitle>
                    </AssignmentLabelText>
                    </Tooltip>
                </BookLabelTableCell>
            </span>)
        }))
    }, [orderedActiveSemData, booksAllList, videos, resources, schoologyAssignment])
    useEffect(() => {
        map(listStudentUser, (user, i) => {
            const activeRow = activeIndex >= 0 && Math.floor(activeIndex / numCols) === i;
            if (activeRow) {
                if (inputRowCells.current[i]) {
                    inputRowCells.current[i].classList.add('selectedRowAndColumn')
                }
                if(nameCellRefs.current[i]) {
                    nameCellRefs.current[i].classList.add('selectedRowAndColumn')
                }
            } else {
                if (inputRowCells.current[i]) {
                    inputRowCells.current[i].classList.remove('selectedRowAndColumn')
                }
                if(nameCellRefs.current[i]) {
                    nameCellRefs.current[i].classList.remove('selectedRowAndColumn')
                }
            }
            map(orderedActiveSemData, (assignment, j) => {
                const index = i * numCols + j;
                const isActiveCol = activeIndex >= 0 && (activeIndex - ((i * numCols) + j)) % numCols === 0;
                const isActiveCell = (isActiveCol && activeIndex >= 0 && Math.floor(activeIndex / numCols) === i)
                if (isActiveCol) {
                    if (inputCells.current[index]) {
                        if (isActiveCell) {
                            inputCells.current[index].classList.add('active-input-cell')
                        } else {
                            inputCells.current[index].classList.remove('active-input-cell')
                        }
                        inputCells.current[index].classList.add('selectedRowAndColumn')

                    }
                } else {
                    if (inputCells.current[index]) {
                        inputCells.current[index].classList.remove('selectedRowAndColumn')
                        inputCells.current[index].classList.remove('active-input-cell')
                    }
                }
            })
        })
    }, [activeIndex, listStudentUser, orderedActiveSemData])
 
    const onChangeGradeToAllValue = (txt) =>{
          if(gradebook_a_f){
            if(!txt||['a','b','c','d','e','f'].indexOf(txt.toLowerCase()) !== -1){
                setGradeToAll(txt.toUpperCase());
            }else{
                setGradeToAll(gradeToAll?gradeToAll:'');
            }
          }else if(scale_1_5){
             if(txt>=0&&txt<=4){
                setGradeToAll(txt)
             }else{
                setGradeToAll(gradeToAll?gradeToAll:'');
            }
          }else if(txt>=0&&txt<=100){
             setGradeToAll(txt)
          }else{
            setGradeToAll(gradeToAll?gradeToAll:'');
        }
    }
    const onClosePopulateGrade = () =>{
        setGradePopulateModal(false)
        setSelectedPopulateAssignment(null);
        setloaderPopulateAssignment(false);
        setGradeToAll(null);

    }
    const getPopulateHelpingMaessage = () => {
        let message='';
        if (gradebook_a_f) {
            message = "Enter a grade between A to F";
        } else if (scale_1_5) {
            message = "Enter a grade between 0 to 4";
        } else {
            message = "Enter grade between 0 to 100";
        }

        return message;
    }

    const getUserSubmissions =  (assignment, user) => {
        if (!assignment || !user) return null;
        const hasAssigned = assignment.users == null || assignment.users.length == 0 || assignment.users.includes(user.id)
        const userSubmissions = find(studentSubmissions[user.id], ['assignmentId', get(assignment, "data.id")]);
        let val = null;
        if (scale_1_5 && gradeToAll <= 4) {
            val = gradeToAll == 0 ? gradeToAll : `${(gradeToAll * 20) + 10}`
        }
        else if (gradebook_a_f && hasGradeInA_F(gradeToAll).hasValue) {
            val = hasGradeInA_F(gradeToAll).value
        } else {
            if (gradeToAll >= 0 && gradeToAll <= 100) {
                val = gradeToAll;
            }
        }
        if(!val){
           return null;
        }
        if (hasAssigned) {
            if (userSubmissions) {
                if(userSubmissions.exceptionStatus>0){
                    return null;
                }
                userSubmissions.manualGrading = false
                userSubmissions.complete = true;
                userSubmissions.graded = true;
                userSubmissions.grade = val;
                delete userSubmissions.assignment
                delete userSubmissions.user
                return userSubmissions;
            } else {
                const tempData = getAssignmentCreation(assignment, user)
                tempData.submissions = 1;
                tempData.manualGrading = false;
                tempData.complete = true;
                tempData.graded = true;
                tempData.grade = val;
                delete tempData.assignment
                delete tempData.user
                return tempData;
            }
        } else {
            return null;
        }

    };
    const onPopulateToAll = async () =>{
        setloaderPopulateAssignment(true);
        const submissionData = map(listStudentUser,usr=>getUserSubmissions(selectedPopulateAssignment,usr)).filter(k=>k);
        if(submissionData.length>0){
            await dispatch(handleUpdateSubmissionsToAll(submissionData,currentClass.id));
        }
        onClosePopulateGrade()
    }
    const loginAsStudent = async ()=>{
         setLoginasLoader(true);
         await openLoginInNewWindow(loginAsUserId);
         setLoginasLoader(false);
         setLoginAsModal(false);
    }
 
   const mainGradeTableActive = activeGradeTab == ""
    return (
        <Wrapper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openPop}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    <strong>ALERT:</strong> {popupMessage}
                </Alert>
            </Snackbar>
            <ModalMenu type='deactivateByStudent' assignmentData={dectiavateByStudentModal?.assignmentData} studentList={listStudentUser} open={dectiavateByStudentModal?.open} close={()=> setDectivateByStudent(({open: false, assignmentData: {}}))} />
            <Modal
                open={loginAsModal}
                onClose={() => setLoginAsModal(false)}
            >
                <ModalContent>
                       You will login as your student, any changes you make will be APPLIED to this account.
                    <ModalRow>
                        <Button
                          color="secondary"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setLoginAsModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={loginAsStudent}
                        >
                            {loginasLoader ? <Loading size={20} white /> : 'Login as Student'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>       
            <Modal
                open={gradePopulateModal}
                onClose={onClosePopulateGrade}
            >
                <ModalContent>
                    Populate the grade, and it will update all students with a similar grade.
                    <TextField
                        id="outlined-required"
                        label="Enter a grade"
                        placeholder='Enter a grade'
                        variant="outlined"
                        value={gradeToAll}
                        helperText={getPopulateHelpingMaessage()}
                        onChange={e=>onChangeGradeToAllValue(e.target.value)}
                        margin='10px'
                        autoFocus
                    />
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={onClosePopulateGrade}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onPopulateToAll}
                            disabled={!gradeToAll||loaderPopulateAssignment}
                        >
                            {loaderPopulateAssignment ? <Loading size={20} white /> : 'Update'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <ParentInvitationModal
                open={inviteParentModal}
                selectedUser={selectedUser}
                onClose={() => {
                    setInviteParentModal(false);
                    setSelectedUser({})
                }}
                currentUser={currentUser}
            />
            <Modal
                open={open}
                onClose={() => setModalState(false)}
            >
                <ModalContent>
                    Are you sure you want to remove this student? All information and assignment submissions will be deleted.
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setModalState(false)
                                setSelectedUser({})
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={removeUser}
                        >
                            {loading ? <Loading size={20} white /> : 'Remove'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Modal
                open={passwordToView.open}
                onClose={() => setPasswordToView({
                    username: '',
                    password: '',
                    open: false,
                    message: ''
                })}
            >
                <ModalContent style={{ minWidth: 300 }}>
                    <div>
                        {!!passwordToView.password && <Row> username:- {passwordToView.username} </Row>}
                        {!!passwordToView.password && <Row> password:- {passwordToView.password} </Row>}
                        {!!passwordToView.message && <Row> {passwordToView.message} </Row>}
                    </div>
                    <ModalRow>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                                setPasswordToView({
                                    username: '',
                                    password: '',
                                    open: false,
                                    message: ''
                                })
                            }}
                        >
                            ok
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Modal
                open={deleteClassAssignmentToggle}
                onClose={() => setDeleteClassAssignmentToggle(false)}
            >
                <ModalContent>
                    Are you sure you want to delete the assignment? All grades will be removed as well.
                    <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => onDeleteSuccess()}
                            disabled={deleteAssignmentLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={(e) => deleteAssignmentLoading ? null : deleteAssignment(e)}
                            className={`${deleteAssignmentLoading ? "disabled-button" : ""}`}
                        >
                            {deleteAssignmentLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            {dataLoading ? (
                <Loading />
            ) : (
                <div>
                        {mainGradeTableActive ?
                                <TableWrapper className="orgTableData">
                                    <OverflowOne>
                                        <Table>
                                        <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                                <TableCellExam header="true" width='415px' style={{ width:'100%',backgroundColor: rowSemester, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', justifyContent: "center" }}
                                                    onClick={() => {
                                                        onExamTabClick("exam1")
                                                    }}>
                                                    <div>
                                                        Exams
                                                    </div>
                                                </TableCellExam>
                                            </TableHeader>
                                            <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                                {Object.keys(semAssignment).map((key, index) => {
                                                    CountSemester = Object.keys(semAssignment).length
                                                    return (
                                                        <TableCellSemester header="true" width={"208px"} style={{ backgroundColor: rowSemester, color: 'white', height: "30px", fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', justifyContent: "center" }}
                                                            active={key == activeSem} key={key} onClick={() => onSemClick(key)}>
                                                            {key}
                                                        </TableCellSemester>
                                                    )
                                                })
                                                }
                                                {CountSemester === 1 &&
                                                    <TableCellSemester header="true" width='208px' style={{ backgroundColor: rowTwoColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', justifyContent: "center" }}></TableCellSemester>
                                                }
                                            </TableHeader>
                                            <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                                <Dropdown overlay={sortingNav()} trigger={['click']}>
                                                    <TableCell header="true" width='208px' style={{ backgroundColor: rowThreeColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', justifyContent: "center" }}>
                                                        <div>
                                                            {nameShortType.title}
                                                        </div>
                                                        <CaretDownOutlined style={{ fontSize: '11px', marginLeft: '6px' }} />
                                                    </TableCell>
                                                </Dropdown>
                                                {/* <TableCell header="true" width='112px' style={{ backgroundColor: rowThreeColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', borderRight: '1px solid white', justifyContent: "center" }}>Username</TableCell> */}
                                                <TableCell header="true" width={"208px"} style={{ backgroundColor: rowThreeColor, color: 'white', height: '30px', fontSize: '12px', fontWeight: 'bold', justifyContent: "center" }}>Average</TableCell>
                                            </TableHeader>
                                            {listStudentUser.map((user, i) => (
                                                <TableRow key={user?.id ?? i + "user"} index={i + 1} style={{ borderRight: '0px' }} ref={(el) => (nameCellRefs.current[i] = el)}>
                                                    <UserNameCellTableCell userActive={user?.userclass?.active}>
                                                        <Dropdown overlay={userNav(user)} disabled={isReviewAccount || Object.keys(user ?? {}).length == 0} placement="bottomLeft" trigger={['click']}>
                                                            <CustomBtn onClick={e => e.preventDefault()}>
                                                                <MoreVert />
                                                            </CustomBtn>
                                                        </Dropdown>
                                                        {getUserName(user)}
                                                    </UserNameCellTableCell>
                                                    <AverageTableCell header active style={{ fontSize: '14px' }}>
                                                        <TootipWrapper hasToolTip label={"Average grade"}>
                                                            {calculateGrade(user.id)}
                                                        </TootipWrapper>
                                                    </AverageTableCell>
                                                </TableRow>
                                            ))}
                                        </Table>
                                    </OverflowOne>
                                    <TableWrapperInner2>
                                        <OverflowTwo >
                                            <Table>
                                                <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                                    {renderBookLabel}
                                                </TableHeader>
                                                <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                                    {renderAssignmentLabel}
                                                </TableHeader>
                                                <TableHeader style={{ paddingBottom: 0, marginBottom: 0, borderBottom: "1px solid" }}>
                                                    {renderDueDates}
                                                </TableHeader>
                                                {listStudentUser.length === 0 ? (
                                                    <EmptyState message="Currently 0 students enrolled in this class." />
                                                ) : (
                                                    renderTableCell
                                                )}
                                            </Table>
                                        </OverflowTwo>
                                    </TableWrapperInner2>
                                </TableWrapper> : <ExamTables
                                onClose={() => {
                                    setActiveGradeTab(""); setGradeViw('gradetable')
                                }}
                                onExamTabClick={onExamTabClick}
                                activities={activities}
                                dataLoading={dataLoading}
                                quizzes={quizzes}
                                users={users}
                                submissions={submissions}
                                currentUser={currentUser}
                                classActivities={classActivities}
                                classQuizzes={classQuizzes}
                                setExamData={setExamData}
                                examType={activeGradeTab}
                                exams={sortExam()}
                            />}
                </div>
            )}
        </Wrapper>
    );
};

//  -- Prop types ---------------------
GradeTable.propTypes = {};

//  -- Default props ------------------
GradeTable.defaultProps = {};

export default GradeTable;
