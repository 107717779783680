//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create class activity ----------
export const createUserClass = () => ({ type: ActionTypes.userClasses.createUserClass });
export const createUserClassFail = (returnedPayload) => ({ type: ActionTypes.userClasses.createUserClassFail, payload: returnedPayload });
export const createUserClassSuccess = (returnedPayload) => ({ type: ActionTypes.userClasses.createUserClassSuccess, payload: returnedPayload });

//  -- Delete class activity ----------
export const deleteUserClass = () => ({ type: ActionTypes.userClasses.deleteUserClass });
export const deleteUserClassFail = (returnedPayload) => ({ type: ActionTypes.userClasses.deleteUserClassFail, payload: returnedPayload });
export const deleteUserClassSuccess = (returnedPayload) => ({ type: ActionTypes.userClasses.deleteUserClassSuccess, payload: returnedPayload });

//  -- List class activities ----------
export const listUserClasses = () => ({ type: ActionTypes.userClasses.listUserClasses });
export const listUserClassesFail = (returnedPayload) => ({ type: ActionTypes.userClasses.listUserClassesFail, payload: returnedPayload });
export const listUserClassesSuccess = (returnedPayload) => ({ type: ActionTypes.userClasses.listUserClassesSuccess, payload: returnedPayload });

//  -- List class users ---------------
export const listClassUsers = () => ({ type: ActionTypes.userClasses.listClassUsers });
export const listClassUsersFail = (returnedPayload) => ({ type: ActionTypes.userClasses.listClassUsersFail, payload: returnedPayload });
export const listClassUsersSuccess = (returnedPayload) => ({ type: ActionTypes.userClasses.listClassUsersSuccess, payload: returnedPayload });
export const updateUserClassesOrder = (returnedPayload) => ({ type: ActionTypes.userClasses.updateUserClassesOrder, payload: returnedPayload });

export const updateClassConfig = (returnedPayload) => ({ type: ActionTypes.userClasses.updateClassConfig, payload: returnedPayload });

export const setUsersByClass = (returnedPayload) => ({ type: ActionTypes.userClasses.setUsersByClass, payload: returnedPayload });
export const updateUsersByClass = (returnedPayload) => ({ type: ActionTypes.userClasses.updateUsersByClass, payload: returnedPayload });
export const clearUsersByClass = (returnedPayload) => ({ type: ActionTypes.userClasses.clearUsersByClass });