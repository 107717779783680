//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';
import { map , filter } from "lodash"

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const examsInitial = [];

export const oneway_debate = (state = examsInitial, action) => {
    switch (action.type) {
        case ActionTypes.oneway_debate.create_oneway_debate:
            return [action.payload, ...state];
        case ActionTypes.oneway_debate.list_oneway_debate:
            return action.payload;
        case ActionTypes.oneway_debate.update_oneway_debate:
            return map(state,dt=>{
                if(dt.id===action.payload.id){
                    return action.payload;
                }
                return dt;
            })
        case ActionTypes.oneway_debate.delete_oneway_debate:
            return filter(state,dt=>dt.id!==action.payload)
        default:
            return state;
    }
};
