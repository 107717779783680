//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Actions
//  ----------------------------------------------------------------------------

//  -- Create class activity ----------
export const createClassActivity = () => ({ type: ActionTypes.classActivities.createClassActivity });
export const createClassActivityFail = (returnedPayload) => ({ type: ActionTypes.classActivities.createClassActivityFail, payload: returnedPayload });
export const createClassActivitySuccess = (returnedPayload) => ({ type: ActionTypes.classActivities.createClassActivitySuccess, payload: returnedPayload });
export const createDeactivateByStudent = (returnedPayload) => ({ type: ActionTypes.classActivities.createDeactivateByStudent, payload: returnedPayload });

//  -- Delete class activity ----------
export const deleteClassActivity = () => ({ type: ActionTypes.classActivities.deleteClassActivity });
export const deleteClassActivityFail = (returnedPayload) => ({ type: ActionTypes.classActivities.deleteClassActivityFail, payload: returnedPayload });
export const deleteClassActivitySuccess = (returnedPayload) => ({ type: ActionTypes.classActivities.deleteClassActivitySuccess, payload: returnedPayload });

//  -- List class activities ----------
export const listClassActivities = () => ({ type: ActionTypes.classActivities.listClassActivities });
export const listClassActivitiesFail = (returnedPayload) => ({ type: ActionTypes.classActivities.listClassActivitiesFail, payload: returnedPayload });
export const listClassActivitiesSuccess = (returnedPayload) => ({ type: ActionTypes.classActivities.listClassActivitiesSuccess, payload: returnedPayload });

//  -- Update class activity ----------
export const updateClassActivity = () => ({ type: ActionTypes.classActivities.updateClassActivity });
export const updateClassActivityFail = (returnedPayload) => ({ type: ActionTypes.classActivities.updateClassActivityFail, payload: returnedPayload });
export const updateClassActivitySuccess = (returnedPayload) => ({ type: ActionTypes.classActivities.updateClassActivitySuccess, payload: returnedPayload });
