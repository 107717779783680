//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import React, { useState } from 'react';
import styled from 'styled-components';
//  -- Components ---------------------
import { ArrowLeftOutlined } from '@ant-design/icons';

//  -- Constants ----------------------
import { filter, find, get, sortBy } from 'lodash';
//  -- Thunks -------------------------
import { Button, Col, Row } from 'antd';
import DistrictForm from 'features/forms/admin/District';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentOrg } from 'store/organizations/selectors';
import { handleGetOrganization } from "store/organizations/thunks";

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const StableWrapperMain = styled.div`
    margin-top:10px;
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------

const AddDistricts = ({
    userloading,
    districts,
    onUpdateOrAdd = () => null,
    onTabsJump = () => null
}) => {
    const location = useLocation();
    const data = location.state;
    const [districtForm, setDistrictForm] = useState(false);
    const [editDistrict, setEditDistrict] = useState(data?.isEdit);
    const [district, setDistrict] = useState(data?.userData);
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const currentOrg = useCurrentOrg()
    const dispatch = useDispatch();
    const history = useHistory();

    const setFilterdDistrict = (dist) => {
        if (dist) {
            const cOrg = find(dist, d => d.id == get(currentOrg, "id"))
            if (cOrg) {
                const sortedDist = sortBy(filter(dist, d => d.id != get(currentOrg, "id")), ["displayName"])
                const res = [cOrg, ...sortedDist]
                setFilteredDistricts(res)
            } else {
                setFilteredDistricts(sortBy(dist, ["displayName"]))
            }

        } else {
            setFilteredDistricts([])
        }
    }
    useEffect(() => {
        setFilterdDistrict(districts)
    }, [districts])

    // useEffect(() => {
    //     async function fetchData() {
    //         // await dispatch(handleListAllBooks());
    //         // await dispatch(handleListDistricts());
    //         // dispatch(handleProcessClassTenant())
    //         // dispatch(handleGetCoursesForClever('1'))
    //         // await dispatch(handleListInteractionsByType("audio"));
    //         // await dispatch(handleListInteractionsByType("video"));
    //         fetchDistrictData();
    //     }
    //     fetchData();
    // }, []);

    useEffect(() => {
        fetchDistrictData();
    }, [currentOrg]);
    const fetchDistrictData = async () => {
        // try {
        // //   setUserLoading(true);
        //   if (!isEmpty(currentOrg)) {
        //     await handleUpdateLicences(currentOrg.id)
        //     await dispatch(handleListOrgsByParent(currentOrg.id))
        //     await dispatch(handleListDistUsers(currentOrg.id));
        //   }
        // //   setUserLoading(false);
        // } catch (error) {
        // //   setUserLoading(false);
        // }
    };
    const backToDataBrowser = () => {
        history.push('/data-browsers');
    };

    return (
        <>
            <Row>
                <Col lg={24}>
                    <Button type="link" primary style={{ paddingLeft: 0, fontSize: 17, marginBottom: 20 }} onClick={backToDataBrowser}>
                        <ArrowLeftOutlined />
                        Back
                    </Button>
                </Col>
            </Row>
            <StableWrapperMain>
                <DistrictForm
                    district={district}
                    editing={editDistrict}
                    onClose={() => {
                        setDistrictForm(false);
                        setEditDistrict(false);
                        setDistrict(null);
                        fetchDistrictData();
                        dispatch(handleGetOrganization(currentOrg?.id))
                    }}
                /> 
            </StableWrapperMain>
        </>
    );
};


//  -- Prop types ---------------------
AddDistricts.propTypes = {};

//  -- Default props ------------------
AddDistricts.defaultProps = {};

export default AddDistricts;
