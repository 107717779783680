import { Button } from '@material-ui/core';
import React, { useCallback, useEffect } from "react";
//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row } from 'antd';
import StudentClassCard from "components/StudentClassCard";
import FlatList from 'flatlist-react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { get , filter } from "lodash"





const CenterContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
`;
const ButtonClear = styled.button`
    border-radius: 7px;
    color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    margin: 10px;
`;
const useStyles = makeStyles((theme) => ({
    titleText: {
        fontWeight: 400,
        color: 'rgb(255, 255, 255)',
        fontSize: 20,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 17
    }
}));


function LearnScreen({
    currentUser,
    userClasses,
}) {
    const history = useHistory()

    const renderItem = useCallback((item, index) => {
        return (<StudentClassCard key={item.id} classInfo={item} />)
    },[userClasses])

    const redirectToHome = () => {
        history.push(`/student/class`);
    }
    useEffect(() => {
        if (userClasses && userClasses.length == 0 || userClasses.length ==1) {
            const isArchived = get(userClasses[0],"archived",false); 
            if(!isArchived){
                redirectToHome()
            }  
        }
    }, [userClasses])
    return (
        <>
            <Row className="teacherHm">
                <Col xs={24} sm={24} md={24} lg={20}>
                    <Row gutter={[40, 40]}>
                        <div className="techrHmBtn">
                            <Button>My classes</Button>
                        </div>
                    </Row>
                    <Row className="teacherHmInr" gutter={[40, 40]} style={{paddingLeft:16, marginBottom:80}}>
                        <FlatList
                            list={filter(userClasses,k=>!k.archived)}
                            renderItem={renderItem}
                            renderWhenEmpty={() => <div>No classes available</div>}
                            sortBy={["id"]}
                        />
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4}></Col>
            </Row>
        </>
    );
}

const mapStateToProps = state => ({
    activities: state.activities,
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    currentUser: state.currentUser,
    quizzes: state.quizzes,
    userClasses: state.userClasses,
    userBooksList: state.userBooksList,
    booksAllList: state.booksAllList,
    schools: state.schools,
    users: state.users,
    classUsers: state.classUsers,
    submissions: state.submissions,
    classNotes: state.classNotes,
    classQuestions: state.classQuestions,
    selectedUser: state.selectedUser,
    schoologyAuth: state.schoologyAuth,
});

export default connect(mapStateToProps, null)(LearnScreen);
