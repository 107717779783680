//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { Button, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import { ArrowRight, Close, Search, Visibility , ArrowForward  } from '@material-ui/icons';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Schoology from 'utils/Schoology';
//  -- Constants ----------------------
import Typography from '@material-ui/core/Typography';
import base64 from "base-64";
import COLOR from 'utils/constants/color';
//  -- Components ---------------------
import Loading from 'components/Loading';
import LoadingWrapper from 'components/LoadingWrapper';
import { handleUserBySchoologyId, handleUserBySchoologyName } from "store/schoologyauth/thunks";
//  -- Thunks -------------------------
import { get, isEmpty, map } from 'lodash';
import { lighten } from 'polished';
import { createKeystore } from "store/keystore/thunks";
import { v4 } from 'uuid';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`;

const WrapperForm = styled.form`
	padding: 40px 0px;
	width: 100%;
`;
const Error = styled.div`
    background-color: ${lighten(0.4, 'red')};
    border: 1px solid red;
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin: 0px auto 16px;
    max-width: 480px;
    padding: 8px;
    text-align: center;
    width: 100%;
`;
const Label = styled.div`
   font-size:14px;
   color:${COLOR.white};
`;
const LabelTop = styled.div`
   font-size:18px;
   color:${COLOR.white};
`;
const ItemsTabsList = styled.div`
  display: inline-block;
  flex-direction: column;
  min-height: 100px;
  border-radius: 12px;
  min-width: 200px;
  background-color: ${COLOR.indigodeep};
  width: 90%;
  padding: 16px;
  margin-top: 10px;
  min-height: 220px;
`;
const ClassUsersWrapper = styled.div`
    border: 1px dashed ${COLOR.black700};
    border-radius: 3px;
    margin-bottom: 8px;
    width:102%;
    &:last-child {
        margin-bottom: 0px;
    }
`;

const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:90vh;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalSection =  styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    width:90vw;
    height:100%;
    overflow:auto;
    text-align: center;
`;

const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;


const AccordianTitle = styled(Typography)`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:100%;
`
const Accordion = withStyles({
    root: {
        border: '1px solid white',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#03989e',
        color: 'white',
        textTransform: 'capitalize',
        borderBottom: '1px solid white',
        marginBottom: -1,
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
    },
    content: {
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiAccordionDetails);
const Rotate = styled.div`
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 4px;
    transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: all 300ms ease;
    width: 16px;
`;
const ContentArea = styled.div`
width: 90%;
margin-top: 28px;
`;
//  ----------------------------------------------------------------------------
//  Container
//  ----------------------------------------------------------------------------


const UserInfoAres = ({ schoologyData, data  , fechingSchoology}) => {
    const [loader,setLoader] = useState(false);
    const openLoginInNewWindow = async (userId) => {
        setLoader(true);
        const key = v4()
        await createKeystore(get(data, "users.id", ''), key)
        const url = `${window.location.origin}?key=${key}`
        window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
        setLoader(false);
      }
    return (<Row style={{width:"100%"}}>
        <Col span={12}>
            {!isEmpty(data.users) && <ItemsTabsList>
                <LabelTop>User on OWE Platform </LabelTop>
                <Label>Id: {get(data, "users.id", '')}  </Label>
                <Label >Create at: {get(data, "users.createdAt", null) ? moment(parseInt(get(data, "users.createdAt", ''))).format("MM/DD/YYYY") : null}</Label>
                <Label>First Name: {get(data, "users.firstName", '')}</Label>
                <Label >Last name: {get(data, "users.lastName", '')}</Label>
                <Label >Role: {get(data, "users.role", '')}</Label>
                <Label >User Name: {get(data, "users.username", '')}</Label>
                <Label >Password: {get(data, "users.password") ? base64.decode(get(data, "users.password", '')) : null}</Label>
                <Button color="primary" variant="contained" onClick={openLoginInNewWindow}>
                    {loader && <Loading size={14} white />}
                    Login to account
                    <ArrowForward
                        style={{
                            fontSize: "11px",
                            marginLeft: "8px",
                        }}
                    />
                </Button>

            </ItemsTabsList>}
        </Col>
        <Col span={12}>
        {fechingSchoology?<Loading size={20}/>:null}
            {!isEmpty(schoologyData.users) && <ItemsTabsList>
                <LabelTop>User on Schoology </LabelTop>
                <Label>Uid: {get(schoologyData, "users.uid", '')}  </Label>
                <Label>School Id: {get(schoologyData, "users.school_id", '')}  </Label>
                <Label>First Name: {get(schoologyData, "users.name_first", '')}</Label>
                <Label >Last name: {get(schoologyData, "users.name_last", '')}</Label>
                <Label >Email: {get(schoologyData, "users.primary_email", '')}</Label>
                <Label >Building Id: {get(schoologyData, "users.building_id", '')}</Label>
            </ItemsTabsList>}
        </Col>
    </Row>)
}
const ClassInfoArea = ({ handleChange, data, classEpendOwe, schoologyData }) => {
    return (<Row style={{width:"100%"}}>
        <Col span={12} style={{display:"flex",justifyContent:"center"}}>
            <ContentArea>
                {map(data.classes, cls => {
                    return (<Accordion square expanded={classEpendOwe == cls.sectionId} onChange={handleChange(cls.sectionId)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle><span>{cls.displayName}</span> <Rotate open={classEpendOwe == cls.sectionId}><ArrowRight /></Rotate></AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ClassUsersWrapper>
                                <TableContainer>
                                    <Table style={{ width: "100%", textAlign: "center" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>Id</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Username</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>First name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Last Name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Schoology Id</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Password</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(cls.classUsers, cuser => {
                                                return (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "id", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "username", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "firstName", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "lastName", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "schoologyid", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "password", null) ? base64.decode(get(cuser, "password")) : null}</TableCell>
                                                    </TableRow>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ClassUsersWrapper>
                        </AccordionDetails>
                    </Accordion>
                    )
                })}
            </ContentArea>
        </Col>
        <Col span={12} style={{display:"flex",justifyContent:"center"}}>
            <ContentArea>
                {map(schoologyData.classes, cls => {
                    return (<Accordion square expanded={classEpendOwe == cls.sectionId} onChange={handleChange(cls.sectionId)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <AccordianTitle><span>Course: {cls.course_title}  --  Section: {cls.section_title}</span> <Rotate open={classEpendOwe == cls.sectionId}><ArrowRight /></Rotate></AccordianTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ClassUsersWrapper>
                                <TableContainer>
                                    <Table style={{ width: "100%", textAlign: "center" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>Uid</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>School uid</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>First name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Last Name</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>status</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Admin</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(cls.classUsers, cuser => {
                                                return (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "uid", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "school_uid", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "name_first", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "name_last", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "status", '')}</TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "admin", 0) == 1 ? "true" : "false"}</TableCell>
                                                    </TableRow>

                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ClassUsersWrapper>


                        </AccordionDetails>
                    </Accordion>
                    )
                })}
            </ContentArea>
        </Col>

    </Row>)
}
const LogsContainer = ({
    logs,
    currentUser
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [data, setData] = useState({ users: null, classes: [], auth: null });
    const [schoologyData, setSchoologyUserData] = useState({ users: null, classes: [], auth: null });
    const [search, setSearch] = useState('');
    const [originalLogs, setOriginalLogs] = useState([]);
    const [schoology_id, setSchoology] = useState("")
    const [schoologyName, setSchoologyName] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)
    const client = new Schoology()
    const [classEpendOwe, setClassExpendOwe] = useState(-1)
    const [userList, setUserList] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [fechingSchoology, setFechingSchoology] = useState(false)
    // console.log("original" + JSON.stringify(data));

    const handleChange = (panel) => (event, newExpanded) => {
        setClassExpendOwe(newExpanded ? panel : -1);
    };




    const fetchApis = async (id) => {
        setLoading(id)
        setErrorMessage(null)
        setFechingSchoology(true)
        setSchoologyUserData({ users: null, classes: [], auth: null })
        const result = await handleUserBySchoologyId(id)
        if (result.error) {
            setErrorMessage(result.message)
            setLoading(null)
            return;
        }
        setData({
            auth: get(result, "auth"),
            users: get(result, "users"),
            classes: get(result, "classes", []),
        })
        setOpenModal(true)
        if (result.auth) {
            await client.setToken(result.auth)
            await fetchFromSchoology(get(result, "users", {}))
        } else {
            setErrorMessage("No authentication was found for this Schoology user, which is why they are unable to fetch user data from Schoology.")
            setFechingSchoology(false)
        }
        setLoading(null)
    }
    const fetchFromSchoology = async (user) => {
        const userInfo = await client.getUserInfoById(user.schoologyid);
        const classes = []
        client.getSectionByUserId(user.schoologyid).then(async res => {
            if (res && typeof res === 'object') {
                const section = res.section
                Promise.allSettled(map(section, async (e) => {
                    const sectionEnroll = await client.getEnrollmentsBySectionId(e.id);
                    classes.push({
                        course_title: e.course_title,
                        section_title: e.section_title,
                        code: e.id,
                        sectionId: e.id,
                        courseId: e.course_id,
                        schoolId: e.school_id,
                        classUsers: get(sectionEnroll, "enrollment", [])
                    })
                })
                ).then(res => {
                    setFechingSchoology(false)
                    setSchoologyUserData({
                        classes: classes,
                        users: userInfo
                    })
                })
            }
        }).catch(() => {
            setErrorMessage("Unable to fetch user data from schoology")
            setFechingSchoology(false)
        })
    }
    const fetchSchoologyUserByName = async () => {
        if (schoologyName) {
            setLoading(true)
            const userFromSchoology = await handleUserBySchoologyName(schoologyName.trim())
            setUserList(userFromSchoology)
            setSchoologyUserData({ users: null, classes: [], auth: null })
            setLoading(false)
        }

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setUserList([])
        if (schoology_id) {
            fetchApis(schoology_id);
            fetchSchoologyUserByName()
        } else {
            fetchSchoologyUserByName()
        }

    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={e => setOpenModal(false)}
                style={{ height: "100%", width: "100%" }}
            >
                <ModalContent>
                    <Row style={{ justifyContent: "flex-end", width:"100%" }}>
                        <IconButton
                            onClick={e => setOpenModal(false)}
                            style={{ padding: 0, marginRight: 10 }}
                        >
                            <Close color="secondary" />
                        </IconButton>
                    </Row>
                    {errorMessage ? <Error>{errorMessage}</Error> : null}
                    <ModalSection>
                        <UserInfoAres data={data} schoologyData={schoologyData} fechingSchoology={fechingSchoology} />
                        <ClassInfoArea data={data} schoologyData={schoologyData} classEpendOwe={classEpendOwe} handleChange={handleChange} />
                    </ModalSection>
                  
                </ModalContent>
            </Modal>
            <WrapperForm onSubmit={handleSubmit}>
                <Row gutter={[24, 24]}>
                    <Col>
                        <Button variant="contained" color="primary" style={{ height: 40 }} onClick={e=>{
                           e.preventDefault();
                            history.push("/book-maps?course=schoology") 
                        }}>
                            Book mapping
                        </Button>
                    </Col>
                    <Col>
                        <TextField
                            id="schoology_id"
                            name="schoology_id"
                            label="schoology id"
                            InputLabelProps={{ shrink: true, }}
                            type="text"
                            variant="outlined"
                            onChange={e => {
                                setErrorMessage(null)
                                setSchoology(e.target.value)
                            }}
                            value={schoology_id}
                        />
                    </Col>
                    <Col>Or</Col>
                    <Col>
                        <TextField
                            id="name"
                            name="name"
                            label="First name or last name"
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            variant="outlined"
                            onChange={e => {
                                setErrorMessage(null)
                                setSchoologyName(e.target.value)
                            }}
                            value={schoologyName}
                        />
                    </Col>
                    <Col>
                        <Button disabled={loading} variant="contained" color="primary" type="submit" style={{height:40}}>
                            {(loading) ? <Loading size={20} white /> : 'Search'}
                            <Search style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                        </Button>
                    </Col>
                </Row>
                {/* {errorMessage ? <Error>{errorMessage}</Error> : null} */}
            </WrapperForm>
            <Wrapper>
                <LoadingWrapper loading={false}>
                    {userList.length > 0 ? <Table style={{ width: "100%", textAlign: "center" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: "center" }}>Username</TableCell>
                                <TableCell style={{ textAlign: "center" }}>First name</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Last Name</TableCell>
                                <TableCell style={{ textAlign: "center" }}>role</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Schoology Id</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Password</TableCell>
                                <TableCell style={{ textAlign: "center" }}>Compare</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {map(userList, cuser => {
                                return (<>
                                    <TableRow key={cuser.id}>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "username", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "firstName", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "lastName", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "role", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "schoologyid", '')}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{get(cuser, "password", null) ? base64.decode(get(cuser, "password")) : null}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}><Button disabled={loading == get(cuser, "schoologyid")} style={{width:85,height:35}} variant="text" onClick={() => fetchApis(get(cuser, "schoologyid"))}>{loading == get(cuser, "schoologyid") ? <Loading size={15}  /> : <Visibility style={{ color: COLOR.green }} />} </Button></TableCell>
                                    </TableRow>
                                </>)
                            })}
                        </TableBody>
                    </Table> : null}
                </LoadingWrapper>
            </Wrapper>
        </>
    );
};

//  -- Prop types ---------------------
LogsContainer.propTypes = {};

//  -- Default props ------------------
LogsContainer.defaultProps = {
    logs: []
};

//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({
    logs: state.logs,
    currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(LogsContainer);
