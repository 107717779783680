import { Checkbox, LinearProgress } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { revokeCheckListModal } from "store/wheel/actions";
import { useCheckList } from "store/wheel/selectors";
import styled from "styled-components";
import COLOR from "utils/constants/color";
import CheckListWorker from "./CheckListWorker";
import ResizeAndDragabble from "./ResizeAndDraggable";
import TimeProgressBar from "./TimeProgressBar";
import ClickOutside from "./OnClickOutside";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  // min-height: 500px;
  // background-color: rgb(58, 58, 58);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  z-index: 99999999;
  position: fixed;
  cursor: grab;
  // left: 25%;
  //   margin: 15px auto;/
  transform: translate(31%, 0%);

  @media (max-width: 1450px) {
    // width: 96%;
    transform: translate(0%, 0%);
  }
  @media (max-width: 992px) {
    // width: 95%;
    transform: translate(0%, 0%);
  }
`;

const WheelCheckList = ({ currentClass }) => {
  const [dimensions, setDimensions] = useState({});
  const dispatch = useDispatch();
  const [checkListModal] = useCheckList();
  const [checked, setChecked] = useState([]);
  const [worker, setWorker] = useState(null);

  const [currentTaskIndex, setCurrentTaskIndex] = useState(-1);
  const [taskQue, setTaskQue] = useState([]);
  const [progress, setProgress] = useState(0);
  const filterByClass = checkListModal?.data ?? [];
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    if (Array.isArray(checkListModal?.data)) {
    }
  }, [checkListModal?.data]);

  useEffect(() => {
    if (
      taskQue.length > 0 &&
      currentTaskIndex > 0 &&
      taskQue.length > currentTaskIndex
    ) {
      const task = taskQue[currentTaskIndex];
      const totalTime = parseInt(task.minutes || 0) * 60;
      worker.postMessage({ command: "start", time: totalTime });
    }
  }, [currentTaskIndex, taskQue]);

  const onTaskDone = () => {
    setCurrentTaskIndex(currentTaskIndex + 1);
  };

  useEffect(() => {
    const newWorker = new Worker(`${process.env.PUBLIC_URL}/timeWorker.js`);
    setWorker(newWorker);
    newWorker.onmessage = (event) => {
      switch (event.data.type) {
        case "tick":
          // setTime(event.data.countdownTime);
          const task = taskQue[currentTaskIndex];
          const totalTime = parseInt(task.minutes || 0) * 60;
          const percentage = Math.min(
            100,
            (event.data.countdownTime / totalTime) * 100
          );
          setProgress(percentage);
          console.log("tick", event.data, "percentage", percentage);
          break;
        case "done":
          onTaskDone();
          console.log("done", event.data);
          break;
      }
    };
    return () => newWorker.terminate();
  }, []);

  // const calculateProgress = (taskText) => {
  //   return progress[taskText] || 0;
  // };

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`;
  }

  const calculateProgress = (startTime, endTime) => {
    const start = new Date();
    const end = new Date();
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    start.setHours(startHours);
    start.setMinutes(startMinutes);
    const [endHours, endMinutes] = endTime.split(":").map(Number);
    end.setHours(endHours);
    end.setMinutes(endMinutes);

    const currentTime = Date.now();
    if (currentTime < start.getTime()) {
      return 0;
    }

    if (currentTime > end.getTime()) {
      return 100;
    }

    const totalTime = end - start;
    const elapsedTime = currentTime - start.getTime();
    const progress = (elapsedTime / totalTime) * 100;
    console.log("progress", progress);
    return progress;
  };

  // useEffect(() => {
  //   const timers = [];
  //   filterByClass.forEach((obj) => {
  //     if (obj.data.list_type === 'timeBased') {
  //       const time24hr = convertTo24Hour(obj.data.check_time);
  //       const timeDifference = getTimeDifference(time24hr);

  //       const timer = setTimeout(() => {
  //         obj.data.tasks.forEach((task) => {
  //           const taskStartTime = new Date().getTime() + timeDifference;
  //           const taskEndTime = taskStartTime + parseInt(task.minutes) * 60000;
  //           const totalTime = parseInt(task.minutes || 0) * 60
  //           worker.postMessage({ command: 'start', time: totalTime });
  //           const taskTimer = setInterval(() => {
  //             const currentTime = new Date().getTime();
  //             const elapsedTime = currentTime - taskStartTime;
  //             const totalDuration = taskEndTime - taskStartTime;
  //             const percentage = Math.min(100, (elapsedTime / totalDuration) * 100);
  //             setProgress(prev => ({ ...prev, [task.text]: percentage }));
  //             if (percentage >= 100) {
  //               clearInterval(taskTimer);
  //             }
  //           }, 1000);

  //           timers.push(taskTimer);
  //         });
  //       }, timeDifference);

  //       timers.push(timer);
  //     }
  //   });

  //   return () => {
  //     timers.forEach(timer => clearTimeout(timer));
  //   };
  // }, [filterByClass]);

  useEffect(() => {
    const timers = [];

    filterByClass.forEach((obj) => {
      if (obj.data.list_type === "timeBased") {
        const time24hr = convertTo24Hour(obj.data.check_time);
        const timeDifference = getTimeDifference(time24hr);

        obj.data.tasks.forEach((task) => {
          const taskStartTime = new Date().getTime() + timeDifference;
          const taskEndTime = taskStartTime + parseInt(task.minutes) * 60000;
          const totalTime = parseInt(task.minutes || 0) * 60;

          // Create a new worker for each task
          const newWorker = new Worker(
            `${process.env.PUBLIC_URL}/timeWorker.js`
          );

          // Handle messages from the worker
          newWorker.onmessage = (event) => {
            switch (event.data.type) {
              case "tick":
                const currentTime = new Date().getTime();
                const elapsedTime = currentTime - taskStartTime;
                const totalDuration = taskEndTime - taskStartTime;
                const percentage = Math.floor(
                  Math.min(100, (elapsedTime / totalDuration) * 100)
                );
                setProgress((prev) => ({ ...prev, [task.text]: percentage }));
                break;
              case "done":
                console.log("done", event.data);
                // Handle 'done' event if needed
                break;
              case "reset":
                console.log("reset", event.data);
                // Handle 'reset' event if needed
                break;
              default:
                break;
            }
          };

          // Start the worker with the total time for the task
          newWorker.postMessage({ command: "start", time: totalTime });

          timers.push(newWorker);
        });
      }
    });

    setFilterData(filterByClass);
    // Cleanup function
    return () => {
      timers.forEach((worker) => worker.terminate());
    };
  }, [filterByClass]);

  function convertTo24Hour(time) {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
    hours = parseInt(hours, 10);

    if (modifier === "PM" && hours < 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes.length < 2 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}`;
  }

  function getTimeDifference(targetTime) {
    const [targetHours, targetMinutes] = targetTime.split(":").map(Number);

    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();

    const targetDate = new Date(now);
    targetDate.setHours(targetHours);
    targetDate.setMinutes(targetMinutes);
    targetDate.setSeconds(0);
    targetDate.setMilliseconds(0);

    const timeDifference = targetDate - now;

    return timeDifference >= 0
      ? timeDifference
      : timeDifference + 24 * 60 * 60 * 1000;
  }

  const handleChange = (event, value) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setChecked([...checked, value]);
    } else {
      setChecked(checked.filter((item) => item !== value));
    }
  };

  const onClose = async (data) => {
    await dispatch(revokeCheckListModal(data));
  };

  const handleTouch = (event, obj) => {
    event.preventDefault();
    onClose(obj);
};

const [isDragging, setIsDragging] = useState(false);

  return (
    <>
      {filterData?.map((obj, index) => {
        const checkData = obj?.data || {};
        {
          /* <CheckListWorker checkData={checkData} /> */
        }
        return (
          <Container
            key={index}
            className="allCheckListSEction wigetsContainer"
          >
            <div className="mainCheckList">
            <ClickOutside onClickOutside={() => setIsDragging(false)}>
              <ResizeAndDragabble
                defaultValue={{
                  x: 150,
                  y: 205,
                  width: 430,
                  height: null,
                }}
                onFocusValue={isDragging}
                minWidth={430}
                minHeight={190}
                aspectRatio={false}
                resize={(data) => {
                  setFilterData((prev) =>
                    prev.map((element, indexFilter) => {
                      if (indexFilter === index) {
                        return { ...element, ...data };
                      }
                      return element;
                    })
                  );
                }}
              >
                <div
                  // className="checkListTopSection"
                  style={{
                    position: "relative",
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
                    width: "100%",
                    height: "100%",
                  }}
                  onTouchStart={(event) => {
                    event.stopPropagation();
                    setIsDragging(true)}}
                  className={`checkListTopSection ${
                    isDragging ? "widgetMobAndTab" : ""
                  }`}
                >
                  <CancelIcon
                    onClick={() => onClose(obj)}
                    onTouchStart={(e) => handleTouch(e, obj)}
                    style={{
                      marginLeft: "5px",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      fontSize: 30,
                      color: "red",
                      background: "white",
                      borderRadius: 20,
                    }}
                  />

                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "auto",
                    }}
                  >
                    <div className="checkListTopWidth overflow-unset">
                      <div className="checkListFirstTxt">
                        <span
                          style={{
                            fontSize: `${`clamp(21px, ${
                              obj.width / 17
                            }px, 100px)`}`,
                          }}
                        >
                          {checkData?.title}
                        </span>
                      </div>
                      {checkData?.tasks.map((task, index) => (
                        <>
                          <div
                            key={index}
                            className="mainMidSection secondCheckList checkListName"
                          >
                            <div className="checkListMidSection secondCheckListNum">
                              {task?.imageUrl ? (
                                <span
                                  style={{
                                    fontSize: `${`clamp(20px, ${
                                      obj.width / 13
                                    }px, 90px)`}`,
                                  }}
                                >
                                  {task?.imageUrl}
                                </span>
                              ) : (
                                <img
                                  style={{
                                    width: `${`clamp(25px, ${
                                      obj.width / 13
                                    }px, 72px)`}`,
                                  }}
                                  src={require("../utils/assets/images/checkList_Emoji.jpg")}
                                  alt=""
                                />
                              )}
                              <span
                                style={{
                                  textDecoration: checked.includes(
                                    task?._id ? task?._id : task?.text
                                  )
                                    ? "line-through"
                                    : "none",

                                  fontSize: `${`clamp(21px, ${
                                    obj.width / 18
                                  }px, 80px)`}`,
                                }}
                              >
                                {task?.text ? task?.text : "Untitled List"}
                              </span>
                            </div>
                            {checkData?.list_type === "checkBox" && (
                              <div className="checkbox-container">
                                <Checkbox
                              
                              sx={{
                                "&.MuiSvgIcon-root": {
                                  fontSize: `${Math.max(Math.min(obj.width / 13, 80), 20)}px`, // Apply the dynamic size here
                                },
                              }}
                                  checked={checked.includes(
                                    task?._id ? task?._id : task?.text
                                  )}
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      task?._id ? task?._id : task?.text
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                          {checkData.list_type === "timeBased" && (
                            <div
                              key={index}
                              className="mainMidSection secondCheckList checkListName"
                            >
                              <span
                                style={{
                                  fontSize: `${`clamp(14px, ${
                                    obj.width / 35
                                  }px, 40px)`}`,
                                  textDecoration: checked.includes(task?.text)
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {convertTo12HourFormat(task.startTime)}
                              </span>
                              <TimeProgressBar
                                startTime={task.startTime}
                                endTime={task.endTime}
                              />
                              
                              <span
                                style={{
                                  fontSize: `${`clamp(14px, ${
                                    obj.width / 35
                                  }px, 40px)`}`,
                                  textDecoration: checked.includes(task?.text)
                                    ? "line-through"
                                    : "none",
                                }}
                              >
                                {convertTo12HourFormat(task.endTime)}
                              </span>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </ResizeAndDragabble>
            </ClickOutside>
            </div>
          </Container>
        );
      })}
    </>
  );
};

export default WheelCheckList;
