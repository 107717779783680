//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import Loading from 'components/Loading';
import _ from "lodash";
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//  -- Thunks -------------------------
import { handleCheckCode, handleCreateClass, handleGetClass, handleGetClassByCode, handleUpdateClass } from 'store/classes/thunks';
import { handleRemoveBooksFromClass } from "store/userBooks/thunks";
import { handleCreateUserClass, handleListUserClasses } from 'store/userClasses/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import SelectableBook from 'components/SelectableBook';
import { map } from "lodash";
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import COLOR from 'utils/constants/color';
import { validateData } from 'utils/methods/validateData';

//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Wrapper = styled.form`
    margin: 0px auto;
    max-width: 480px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height:100%;
`;
const BookSection = styled.div`
    margin-left: 5%;
    margin-right: 5%;
    margin-top: -30px;
    width: 100%;
`;
const BookErrorSec = styled.div`
    padding-left:16px;  
    `;

const BtnConatiner = styled.div`
    display:flex;
    flex-direction:column;
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;
const Text = styled.div`
    font-size: 15px;
    color:${COLOR.black300};
    white-space: pre-line;
    text-align: left;
`;
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
   
  function getStyles(name, booksName, theme) {
    return {
      fontWeight:
      booksName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Class form
//
//  -- Description
//  Create / edit class information
//
//  -- Props
//  handleChange {func} - invoked on input change
//  handleSubmit {func} = invoked on submit
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  <ClassFormDistrict handleChange={func} handleSubmit={func} />
//
const filterAssignableBook=(_userBooksList)=>{
    return _.filter(_userBooksList,e=>e.isNotAssignable!=="true")
 }
const ClassFormDistrict = ({
    data,
    setData,
    currentOrg,
    currentUser,
    listBooks,
    onClosed,
    schools,
    teachers,
    currentClass,
    classUsers,
    classActivities,
    classQuizzes,
    classNotes,
    classQuestions,
    userClasses
}) => {
    let booksByUser = [];
    const orgPermissions = useCurrentOrgPermission()
    const bookPermissions = _.get(orgPermissions, "books", [])
    const assignableBooks = _.filter(filterAssignableBook(listBooks), k => {
        if (bookPermissions.length == 0) {
            return true;
        }
        const bookaccess = _.find(bookPermissions, b=>b.bookId==k.id||b.bookId==k.bookId)
        if (bookaccess) {
            return bookaccess.available
        } else {
            return true;
        }
    })
    const chapterBook = map(assignableBooks,e=>({...e,...e.dataBook}))
    const classes = useStyles();
    const theme = useTheme();
    const [booksName, setBooksName] = React.useState(booksByUser);
    //const [data, setData] = useState({})});
    const [booksError, setBooksError] = useState(false);
    const [noLicensesError, setNoLicensesError] = useState(false);
    const dispatch = useDispatch();
    const [classCodeError, setClassCodeError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [schoolError, setSchoolError] = useState(false);
    const isUpdate = data.id != undefined || data.editing;
    const [selectedBook, setSelectedBook] = useState([]);
    const [deleteBookModal, setDeleteBookModal] = React.useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [deletableBookName, setDeletableBookName] = React.useState('');
    const [errorClassPresent, setErrorClassPresent] = useState(false);
    const checkCode = async e => {
        const exists = await dispatch(handleCheckCode(e.target.value));
        if (exists) {
            setClassCodeError(true)
        } else {
            setClassCodeError(false)
        }
    };

    const handleChange = e => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    };

    const handleSelect = (id, value) => {
        setData({
            ...data,
            [id]: value
        });
    };

    const handleChangeMultiSelect = (event) => {
        setBooksError(false);
        setNoLicensesError(false);
        setBooksName(event.target.value);
    };

    const submit = async (e) => {
        e.preventDefault();
        if(isUpdate){
            const preSelectedBooks = data?.books?.map((e) => e.bookId);
            const removedBooks = _.difference(preSelectedBooks, selectedBook);
            if (removedBooks.length>0) {
                let bookname=null
            removedBooks.map(e=>{
                if(!bookname){
                    bookname=chapterBook.find(k=>k.id===e)?.description
                }else{
                    bookname=bookname+", "+chapterBook.find(k=>k.id===e)?.description
                }
                setDeletableBookName(bookname)
            })
                setDeleteBookModal(true)
            }
            else{
                infoUpdateClass();
            }
           
            return;
        }
        const exists = await dispatch(handleCheckCode(e.target.value));
        if(!exists){
            var booksUser = [];
            if(selectedBook.length > 0){
                setBooksError(false);
                booksUser = {
                    "books": createArrayBooks(selectedBook),
                    "userId": "",
                    "validityDate": "2022-01-01",
                    "orgId": currentOrg?.id
                }

                let _data={
                    orgId: currentOrg?.id,
                    teacherId: currentUser?.id,
                    ...data
                }
               
                let validStatus=validateData(booksUser,["books","orgId"])
                let validStatus2=validateData(_data,["code","displayName","orgId","teacherId"])
                if(!validStatus.isValid||!validStatus2.isValid){
                    console.log(validStatus.reason,validStatus2.reason,"invalid")
                    return;
                }
                setLoading(true);
                const classObj = await dispatch(handleCreateClass({
                    ..._data
                },{...booksUser}));

                await dispatch(handleCreateUserClass({ classId: classObj?.id, userId: currentUser?.id }));

                setData({});
                setLoading(false);
                onClosed();
            }else{
                setBooksError(true);
            }
        }else{
            setClassCodeError(true);
        }
    };

    function createArrayBooks(selectedBook){
        let arrayBooks = [];
        for(var b in selectedBook){
            let finded = chapterBook.find(e => e.id == selectedBook[b]);
            if(finded){
                arrayBooks.push({
                    bookId: finded.id,
                    description: finded.description
                })
            }
           
        }
        return arrayBooks;
    }
  

     useEffect(()=>{
        if(Array.isArray(data.books)&&data.books.length>0){
            setSelectedBook(data.books.map((i)=>i.bookId??i.id))
        }
    },[data]) 
    const infoUpdateClass = async (e) => {
        let codeFinal;
        if(!data) return;
        setDataLoading(true);
        setLoading(true);
        const preSelectedBooks = data?.books?.map((e) => e.bookId);
        if(selectedBook.length > 0){
        if(data.code == undefined){
            codeFinal = currentClass.code;
        }else{
            codeFinal = data.code;
        }
        let displayNameFinal;
        if(data.displayName == undefined){
            displayNameFinal = currentClass.displayName;
        }else{
            displayNameFinal = data.displayName;
        }
        let codeClass;
        if(codeFinal != currentClass.code){
            codeClass = await dispatch(handleGetClassByCode(data.code.trim()));
        }
        handleDeletedBook(preSelectedBooks)
        let sbook=createArrayBooks(selectedBook)
        data["books"]= sbook
        let booksUser = {
            "books": sbook,
            "userId": "",
            "validityDate": "2022-01-01",
            "orgId": currentOrg?.id
        }
        if(codeClass == undefined){
            let _finaldata={
                orgId: currentOrg?.id,
                teacherId: currentUser?.id,
                displayName: displayNameFinal,
                code: codeFinal,
                ...data
            }
           
          await dispatch(handleUpdateClass(currentClass.id,_finaldata,booksUser,data.userBookId));
            currentClass.displayName = displayNameFinal;
            currentClass.code = codeFinal;
            await dispatch(handleListUserClasses(currentUser?.id));
            await(dispatch(handleGetClass(currentClass.id)))
        }else{
        //    loader
        setBooksError(true);
        setLoading(false);
        }
        setLoading(false);
        onClosed(false);
        setSelectedBook([])
        setDataLoading(false);
        setDeleteBookModal(false);
    }
    }
    const handleDeletedBook = (preSelectedBooks) =>{
        try {
        if (Array.isArray(preSelectedBooks) && preSelectedBooks.length > 0) {
            const removedBooks = _.difference(preSelectedBooks, selectedBook);
            if (removedBooks.length) {
                const removableActivity=[]
                const removableQuiz=[]
                const removableQuestions=[]
                const removableNotes=[];
                const userArray=_.filter(classUsers,(e)=>e.id!=currentUser?.id).map(e=>e.id);
                map(removedBooks,_b=>{
                 let acti= _.filter(classActivities,act=>(act.data.chapter==_b||act.data.bookId==_b));
                 let quiz= _.filter(classQuizzes,qz=>(qz.data.chapter==_b||qz.data.bookId==_b));
                 const cq =  _.filter(classQuestions,q=>q.chapter==_b)
                 const cn =  _.filter(classNotes,n=>n.chapter==_b)
                    acti.map(e=>{
                        removableActivity.push(`'${e.id}'`)
                    })
                    quiz.map(e=>{
                        removableQuiz.push(`'${e.id}'`)
                    })
                    cq.map(e=>{
                        removableQuestions.push(`'${e.id}'`)
                    })
                    cn.map(e=>{
                        removableNotes.push(`'${e.id}'`)
                    })
                })
                const data={
                    removableActivity,
                    removableQuiz,
                    removableQuestions,
                    removableNotes,
                    removedBooks,
                    userArray,
                    classId:currentClass.id
                }
               dispatch(handleRemoveBooksFromClass(data));
            }
          }
        } catch (error) {
          console.log(error,"Error in deleting book related data")   
        }
    }
    return (
        <Wrapper id="classCreationForm" onSubmit={submit}>
              <Modal
                open={deleteBookModal}
                onClose={() => setDeleteBookModal(false)}
            >
                <ModalContent>
                <Text><strong>{`Deleting ${deletableBookName} book from your class will also delete the following:`}</strong></Text>
                <Text>{"—All grades and assessments submitted\n—All data related to this book (notes, questions, etc.)\n—You will never be able to get this data again Are you sure you want to delete it? "}</Text>
                   <ModalRow>
                        <Button
                            style={{ marginRight: '4px' }}
                            onClick={() => setDeleteBookModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={infoUpdateClass}
                            className={`${dataLoading ? "disabled-button" : ""}`}
                        >
                            {dataLoading ? <Loading size={20} white /> : 'Yes'}
                        </Button>
                    </ModalRow>
                </ModalContent>
            </Modal>
            <Header>Create a Class</Header>
            <Description>You can create your class periods in this section. Remember to name your classes after the subject’s names and levels to make it easier on you and your students using NosotrosOnline.</Description>
            <Row gutter={[16, 24]}>
                <Col span={16}>
                    <TextField
                        id="displayName"
                        label="Class Name"
                        onChange={handleChange}
                        required
                        variant="outlined"
                        value={data?.displayName}
                    />
                </Col>
                <Col span={8}>
                    <TextField
                        id="code"
                        error={classCodeError}
                        label="Class Code"
                        onBlur={checkCode}
                        onChange={handleChange}
                        variant="outlined"
                        helperText={classCodeError && 'Class code not available'}
                        value={data?.code}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col span={12}>
                    <TextField
                        id="startDate"
                        label="Start Date"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Col>
                <Col span={12}>
                    <TextField
                        id="endDate"
                        label="End Date"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <FormControl variant="outlined">
                        <InputLabel id="schoolLabel">Select School</InputLabel>
                        <Select
                            labelId="schoolLabel"
                            id="orgId"
                            onChange={e => handleSelect('orgId', e.target.value)}
                            value={data?.orgId}
                        >
                            {schools?.map(school => (
                                <MenuItem key={school.id} value={school.id}>
                                    {school.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <FormControl variant="outlined">
                        <InputLabel id="teacherLabel">Select a Teacher</InputLabel>
                        <Select
                            labelId="teacherLabel"
                            id="teacherId"
                            onChange={e => handleSelect('teacherId', e.target.value)}
                            value={data?.teacherId}
                        >
                            {teachers?.map(teacher => (
                                <MenuItem key={teacher.id} value={teacher.id}>
                                    {teacher.firstName} {teacher.lastName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ width: '100%',margin:"auto"}}>
                <BookSection>
                  <SelectableBook 
                  chapter={chapterBook} 
                  selected={isUpdate?selectedBook:[]} 
                //   preSelected={isUpdate?map(data?.books,(i)=>i.bookId??i.id):[]} 
                  preSelected={[]}
                  onSelect={(item)=>{
                  setSelectedBook(item)
                  if(item.length>0){
                    setBooksError(false);
                    setNoLicensesError(false);
                  }else{
                    setBooksError(true);
                  }
                  }}  />
                </BookSection>
                <BookErrorSec>
                
                    { noLicensesError &&
                        <FormHelperText error>The selected school does not have enough licenses available</FormHelperText>
                    }
                    </BookErrorSec>
            </Row>
            {/* <Row gutter={[24, 24]} style={{ width: '100%' }}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="bookList-label">Books by user</InputLabel>
                    <Select
                        labelId="bookList-label"
                        id="bookList-mutiple-chip"
                        multiple
                        value={booksName}
                        onChange={handleChangeMultiSelect}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                        >
                        {assignableBooks.map((list) => (
                            <MenuItem key={list.id} value={list.description} style={getStyles(list.description, booksName, theme)}>
                            {list.description}
                            </MenuItem>
                        ))}
                    </Select>
                    { booksError &&
                            <FormHelperText error>Choose at least one book</FormHelperText>
                        }
                    { noLicensesError &&
                        <FormHelperText error>The selected school does not have enough licenses available</FormHelperText>
                    }
                </FormControl>
            </Row> */}
            <Row justify="end">
            <BtnConatiner>
                     {booksError && <FormHelperText error>Choose at least one book</FormHelperText>}
                <Button
                    disabled={!(data.displayName && data.code && data.orgId && data.teacherId && !classCodeError)}
                    variant="contained"
                    color="primary"
                    type='submit'
                >
                    {loading ? <Loading size={20} white /> :isUpdate?'Update Class':'Create Class'}
                    <ArrowForward style={{ height: '18px', margin: '0px 0px 0px 6px', width: '18px' }} />
                </Button>
                </BtnConatiner>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
ClassFormDistrict.propTypes = {
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
};

//  -- Default props ------------------
ClassFormDistrict.defaultProps = {
    handleChange: Function.prototype,
    handleSubmit: Function.prototype,
};

export default ClassFormDistrict;