//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux'



//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selectore = (state) => state.apexams;
//  -- Examples array selector --------



export const useApExams = () =>{
    const selectedData = useSelector(selectore, shallowEqual)
    return selectedData;
}