 
 const hasKeyAndValue=(data,key)=>{
     return data[key]&&data[key].length>0 
   }
export const validateData=(data,keyArray=[])=>{
  let isValid=true;
  let reason=""
  if(typeof data=="object"&&Object.keys(data).length>0){
      if(keyArray&&Array.isArray(keyArray)){
        keyArray.map(item=>{
            if(!hasKeyAndValue(data,item)){
                isValid=false
                reason=`${item} unavailable`
            }
          })  
      }
  }else{
      isValid =false
  }
  return {isValid,reason};
}