import { CloseOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row, Skeleton } from 'antd';
import React, { useEffect, useState , useRef } from 'react';
import { Img } from 'react-image';
import { useEbookGalleryView } from 'store/main_book_popup/selectors';
import styled from "styled-components";

const Wrapper = styled.div`
    box-shadow: -1px 0px 8px 0px #1b1b1b;
    height:100%;
    width:100%;
    background: linear-gradient(to bottom, rgb(0 0 0 / 80%) 0%, rgb(0 0 0 / 35%) 50%, rgb(0 0 0 / 83%) 100%);
`;
const GalleryWrapper = styled.div`
   height:95%;
   width:100%;
   padding:32px;
   overflow-y: auto;
   overflow-x: hidden;
   &::-webkit-scrollbar {
    height: 10px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #fff;
  }
  
  &::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
  }
  
  &::-webkit-scrollbar-track:active {
    background-color: #fff;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #000088;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000088;
  }
  
  &::-webkit-scrollbar-thumb:active {
    background-color:  #000088;
  }
`;
const Text = styled.div`
color: rgb(255, 255, 255);
font-size: 14px;
padding-right: 32px;
font-weight: 700;
`;
const StyledCard = styled(Card)`
    width: 100%;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.05); // Slight scale up on hover
    }
`;

const StyledCover = styled.div`
    img {
        height: 100px;
        object-fit: contain; // Ensures the image covers the area without distortion
        width: 100%;
    }
`;

const StyledMeta = styled(Card.Meta)`
    padding: 8px;
    text-align: center;
    width: 100%;
`;

const ImageSkeleton = styled(Skeleton.Image)`
    height: 100px !important;
    width: 100% !important;
`;

export default function EbookGallleryView({ getRef , onPageSelect , viewPageAsIndex }) {
    const { ebookGalleryView , revokeEbookGalleryView } = useEbookGalleryView()
    const [pages, setPages] = useState([])
    const containerRef = getRef()
    const [style, setStyle] = useState({ height: 0, width: 0 })

    useEffect(() => {
        if (ebookGalleryView.open) {
            setPages(ebookGalleryView.pages)
        } else {
            setPages([])
        }
    }, [ebookGalleryView])

    useEffect(() => {
        function updateStyle() {
            if (containerRef) {
                const { width, height } = containerRef.getBoundingClientRect()
                setStyle({ height, width })
            }
        }

        updateStyle();
        window.addEventListener('resize', updateStyle);

        return () => {
            window.removeEventListener('resize', updateStyle);
        }
    }, [ebookGalleryView])

    const handleClose = () => {
        revokeEbookGalleryView();
    };
    const handleImageClick = (index) => {
        onPageSelect(index)
        revokeEbookGalleryView()
    };

    if (!ebookGalleryView.open) return null;
    const page_num = (k, i) => {
        if (viewPageAsIndex) {
            return i + 1
        } else if (k?.numberMasked) {
            return k.numberMasked
        } else {
            return k?.pageId || ''
        }
    };
    return (
        <Modal
        visible={ebookGalleryView.open}
        onCancel={handleClose}
        width="90vw"
        style={{ top: "10%" ,  backgroundColor: 'transparent' }}
        bodyStyle={{ padding: 0, height: '80vh', overflow: 'hidden' , backgroundColor:"transparent" }}
        footer={null}
        closable={false}
        maskStyle={{ backgroundColor:"transparent"}}
        wrapClassName="custom-modal-style" 
    >
        <CloseOutlined onClick={handleClose} style={{ 
            position: 'absolute', 
            top: '-10px', 
            right: '-10px', 
            cursor: 'pointer', 
            fontSize: '16px', 
            color: 'white', 
            background: 'red', 
            padding: '5px', 
            borderRadius: '50%' 
        }} />
            <Wrapper>
                <GalleryWrapper>
                    <Row gutter={[8, 8]} style={{ animation: 'fadeIn 0.5s' }}>
                        {pages.map((image, index) => (
                            <Col key={index}
                                span={6}
                                xs={8} sm={8} md={4} lg={2} xl={2}
                            >
                                <StyledCard
                                    hoverable
                                    bodyStyle={{ padding: 0 }}
                                    cover={
                                        <StyledCover>
                                            <LazyImage
                                                src={image.thumbnail || image.src}
                                                alt={`img-${index}`}
                                                onClick={() => handleImageClick(image.pageId)}
                                                placeholder={<ImageSkeleton active />}
                                            />
                                            {/* <Img
                                                src={image.thumbnail ||  image.src}
                                                alt={`img-${index}`}
                                                loader={<ImageSkeleton active />} 
                                                onClick={() => handleImageClick(image.pageId)}
                                                unloader={<ImageSkeleton active />}
                                            /> */}
                                        </StyledCover>
                                    }
                                >
                                    <StyledMeta
                                        title={page_num(image, index)}
                                    />
                                </StyledCard>
                            </Col>
                        ))}
                    </Row>

                </GalleryWrapper>
                <Row style={{ height:"5%"}}>
                    <Col span={24} style={{ display: "flex", justifyContent: "flex-end" , alignItems:"center"}}>
                        <Text>Please , Double click to select page.</Text>
                    </Col>
                </Row>
            </Wrapper>

    </Modal>
    )
}
const GalleryBtn = styled.button`
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    background-color: #012B70;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #fff;
    // border-radius: 0px;
    font-size: 12px;
    height: 36px;
    width: 40.8px;
    padding-inline: 11px;
    padding-top: 10px;
    padding-bottom: 9px;
`;
const GalleryIcon = () => {
    const blue = "#002b70"
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30px" height="100%">
        <rect width="100" height="100" fill={blue} />
        <rect x="5" y="5" width="40" height="40" fill="white" />
        <rect x="55" y="5" width="40" height="40" fill="white" />
        <rect x="5" y="55" width="40" height="40" fill="white" />
        <rect x="55" y="55" width="40" height="40" fill="white" />
        <rect x="10" y="10" width="30" height="30" fill={blue} />
        <rect x="60" y="10" width="30" height="30" fill={blue} />
        <rect x="10" y="60" width="30" height="30" fill={blue} />
        <rect x="60" y="60" width="30" height="30" fill={blue} />
    </svg>
    )
}
export const ViewGallery = ({ pages }) => {
    const { invokeEbookGalleryView } = useEbookGalleryView()

    return (
    <GalleryBtn onClick={() => invokeEbookGalleryView(pages)}>
        <GalleryIcon />
    </GalleryBtn>)
}

const LazyImage = ({ src, alt, onClick, placeholder, style }) => {
    const [loaded, setLoaded] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setLoaded(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [src]);

    return (
        <div ref={imgRef} style={style}>
            {loaded ? (
                <Img
                    src={src}
                    alt={alt}
                    loader={<ImageSkeleton active />}
                    onClick={onClick}
                    unloader={<ImageSkeleton active />}
                /> 
                // <img src={src} alt={alt} onClick={onClick} style={{ width: '100%', height: 'auto' }} />
            ) : (
                placeholder || <div style={{ height: '100px', backgroundColor: '#f0f0f0' }}></div>
            )}
        </div>
    );
};