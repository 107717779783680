import { AddToPhotos } from "@material-ui/icons";
import { Col, Input, Progress, Row } from 'antd';
import { storage } from 'firebaseConfig';
import { ref as fireRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import styled from 'styled-components';
import COLOR from "utils/constants/color";
import { v4 } from 'uuid';
const fileTypes = ["MP4","MOV"];


const ImageContainer = styled.div`
    height: auto;
    flex: 1;
    // height:70vh;
`;

const Images = styled.img`
    background-color: ${COLOR.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
`;
const Container = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    align-item:center;
    justify-content:center;
    position: relative;
    background-color:${p => p.empty ? "rgba(0,0,0,0.1)" : "transparent"}
`;

const Page = styled.div`
    align-items: center;
    color: ${COLOR.black100};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
`;

// const Input = styled.input`
//     background-color: transparent;
//     border: 1px dashed gray;
//     border-radius: 3px;
//     box-shadow: none;
//     font-size: inherit;
//     font-weight: 400;
//     outline: none;
//     padding: 0px 2px;
//     transition: all 300ms ease;
//     width:250px;
// `;
const FileUploadArea = styled.div`
    height: 100%;
    border: 1px dashed gray;
    padding: 5px 12px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Text = styled.div`
    font-size: 12px;
`;
const Column = styled(Col)`
display: flex;
    justify-content: center;
    align-items: center;
}
`;
const VideoContainer = styled.div`
  width: 100%;
  margin-bottom: 0px;
  border-bottom-width: ${(p) => (p.bottomWidth ? p.bottomWidth : "10px")};
`;
const Video = styled.video`
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: auto;
  outline: none;
  max-height:400px;
  width: 100%;
`;
export default function VideoPicker({ src, poster, onChange, description, onChangeDescription, style, hasDescription, pageId, path }) {
    const [source, setSource] = useState(src)
    const [loader, setLoader] = useState(false)
    const [loadingMap, setLoadingMap] = useState(0)
    const videoRef = useRef()
    useEffect(() => {
        setSource(src)
    }, [src])
    const handleChange = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        uploadToStorage(file, pageId)
        reader.onloadend = function (e) {
            let v = reader.result;
            setSource(v)
        }.bind(this);
    };
    useEffect(()=>{
       if(source&& videoRef.current){
          videoRef.current.load()
       }
    },[source])
    const uploadToStorage = async (file, index) => {
        if (file) {
          const location = path || '/videos';
          const id = `${moment().format('YYYY-MM-DD')}-${v4()}${file.name}`;
          const fileRef = fireRef(storage, `${location}/${id}`);
      
          try {
            const uploadTask = uploadBytesResumable(fileRef, file);
      
            uploadTask.on('state_changed',
              (snapshot) => {
                const per = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setLoadingMap(per);
                setLoader(true);
              }, 
              (error) => {
                console.error("Upload error:", error);
                setLoader(false);
                setLoadingMap(0);
              }, 
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setLoader(false);
                  setLoadingMap(0);
                  setSource(downloadURL); 
                  onChange({ url: downloadURL, pageId: index });
                });
              }
            );
          } catch (error) {
            console.error("Upload error", error);
            setLoader(false);
            setLoadingMap(0);
          }
        } else {
          console.log("No file to upload.");
        }
      };
    return (
        <ImageContainer onContextMenu={(e) => { }} style={style}>
            {hasDescription ? <Row>
                <Col span={24} style={{ display: "flex", flexDirection: "row", marginBottom: 20 }} >
                    {/* <Page>
                        desctiption :&nbsp; <Input type="text" value={description || ''} onChange={e => onChangeDescription(e.target.value)} />
                    </Page> */}
                    <Input  addonBefore="Desctiption" defaultValue="mysite" type="text" value={description || ''} onChange={e => onChangeDescription(e.target.value)} />
                </Col>
            </Row> : null}
            <Container>
                <VideoContainer>
                    <Video
                        controls
                        ref={videoRef}
                        poster={poster}
                        controlsList="nodownload"
                        width="100%"
                    >
                        <source src={source} type="video/mp4" />
                    </Video>
                </VideoContainer>
                <FileUploader
                    classes={"file-uploader-cover"}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}

                >
                    <FileUploadArea>
                        {loader && <Progress type="line" percent={loadingMap} size={"small"} className={"fileProgress"} showInfo={false} />}
                        <Row className="box box-1" style={{display:"flex",justifyContent:"space-between"}}>
                            <Column>
                                <AddToPhotos color="primary" />
                            </Column>
                            <Column >
                                <Text>
                                    Upload or drop a file right here
                                </Text>
                            </Column>
                            <Column>
                                <Text>
                                    {fileTypes.join(",")}
                                </Text>
                            </Column>
                        </Row>

                    </FileUploadArea>
                </FileUploader>
            </Container>
        </ImageContainer>
    )
}
