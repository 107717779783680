
export const instructionVideos = [
    {
        src: 'https://owedev-welcome-videos.s3.amazonaws.com/An%C3%A9cdotas_+vida%2Cactualidad+y+cultura.mp4',
        displayName: '1. Welcome Video',
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F1.%20How%20to%20self-register%20(student).mp4?alt=media&token=7666e4be-2114-44a7-b433-1a5fbb2cf1c9',
        displayName: '2. How to self-register (student)',
    },
    /* {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F2.%20How%20to%20log%20in%20as%20a%20teacher.mp4?alt=media&token=4c99977d-83d4-4722-a1f2-3eeb56c771de',
        displayName: '3. How to log in as a teacher',
    }, */
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F3.%20How%20register%20students%20via%20the%20gradebook.mp4?alt=media&token=e28263bd-5cd5-48e3-b16d-7c12a243d4f4',
        displayName: '3. How to register students via the gradebook',
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F4.%20How%20to%20edit%20and%20delete%20students%20via%20gradebook.mp4?alt=media&token=13ba6f52-097a-4877-a05f-b2ab22f2a419',
        displayName: '4. How to edit and delete students via gradebook',
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F5.%20How%20to%20create%20a%20class.mp4?alt=media&token=2e0186ae-6e81-49d8-88e6-bbe567622b54',
        displayName: '5. How to create a class',
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F6%20How%20to%20assign%20activities%20and%20quizzes.mp4?alt=media&token=35ea818d-aa5f-4716-b727-dd52b414f059',
        displayName: '6. How to assign activities and quizzes',
    },
    /* {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F7%20How%20to%20post%20announcements.mp4?alt=media&token=98255e90-39dd-433c-86d7-a9dd4dfda0ab',
        displayName: '8. How to post announcements',
    }, */
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/yo-americano.appspot.com/o/video%2Finstruction%2F8%20How%20to%20grade%20assignments.mp4?alt=media&token=c9f50f8a-eca8-4607-93f2-69e69a81eff8',
        displayName: '7. How to grade assignments',
    },
];
