//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const invokeDebateModal = () => ({ type: ActionTypes.debate.invokeDebateModal });
export const revokeDebateModal = () => ({ type: ActionTypes.debate.revokeDebateModal });

//  -- List All books ----------------
