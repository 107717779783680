//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const booksInitial = {};

export const userBooksList = (state = booksInitial, action) => {
    switch (action.type) {
        case ActionTypes.userBooks.listUserBooksSuccess:
            return action.payload;
        case ActionTypes.userBooks.listUserBooksFail:
            return state;
        default:
            return state;
    }
};
