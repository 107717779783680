import React , {Component} from 'react';
import Link from '@material-ui/core/Link';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

const Index = ({ }) => {
    const history = useHistory();
        return (
            <ul className="learnListing learnListingMob">
                <li><Link onClick={() => history.push('/teaching-heritage')}>1. Teaching Heritage Language Learners</Link></li>
                <li><Link onClick={() => history.push('/differentiation')}>2. Differentiation</Link></li>
                <li><Link onClick={() => history.push('/class-passwords')}>3. Class Passwords/ Contraseñas </Link></li>
                <li><Link>4. Play</Link></li>
                <li><Link>5. Critical Language Acquisition</Link></li>
                <li><Link>6. Critical Language Awareness</Link></li>
                <li><Link>7. Focus on Meaning and on Form</Link></li>
                <li><Link>8. The Primacy Recency Effect</Link></li>
                <li><Link>9. Lowering the Affective Filter</Link></li>
                <li><Link>10. From Listening to Reading</Link></li>
                <li><Link>11. From Speaking to Writing</Link></li>
                <li><Link>12. Jamboard & Anécdotas Graphic organizers</Link></li>
                <li><Link>13. Integrated Performance Assessments</Link></li>
                <li><Link>14. GRASPS</Link></li>
                <li><Link>15. Interpretative Communication Activities</Link></li>
                <li><Link>16. Interpersonal Communication Activities</Link></li>
                <li><Link>17. Presentational Communication Activities</Link></li>
                <li><Link>18. Integrating the three modes of communication</Link></li>
                <li><Link onClick={() => history.push('/heritage-language')}>19. Heritage Language Teaching Objectives</Link></li>
                <li><Link onClick={() => history.push('/data-driven')}>20. The data driven instructor</Link></li>
            </ul>
    );
};
//  -- Prop types ---------------------
Index.propTypes = {};

//  -- Default props ------------------
Index.defaultProps = {};


//  ----------------------------------------------------------------------------
//  Redux
//  ----------------------------------------------------------------------------
const mapStateToProps = state => ({ });
export default connect(mapStateToProps, null)(Index);
