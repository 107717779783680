import { BottomNavigation, BottomNavigationAction, Box } from "@material-ui/core";
import WheelModal from "components/Wheel";
import useMobileDeviceDetection, { useTabletDetection, useTabletCheck, useDeviceType } from "containers/chat/hooks";
import useIsMobile from "containers/chat/hooks";
import NoiseMeter, { NoiseMeterViewer } from "components/Widget/NoiseMeter";
import WidgetsMenu from "components/widgetsMenu";
import Chat from "containers/chat/popup";
import DebateModal from 'features/OneWayDebateModal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import IdeaModal from 'features/ideaModal';
import { useHistory } from 'react-router-dom';
import { useCurrentClass } from "store/classes/selectors";
import { useCurrentUser } from 'store/users/selectors';
import { revokeAllWidget } from "store/wheel/actions";
import { useNoiseMeter } from "store/wheel/selectors";
import styled from 'styled-components';
// import OneGameJoinModal from 'components/OneGame/OneGameJoinModalButton';
import DescriptionIcon from '@material-ui/icons/Description';
import { bellBtnmenu, booksBtnMenu, gradeBtnmenu, homeBtnMenu, messageBtnmenu, roboBtnmenu } from "utils/assets/images/icons";
import { isMobileDevice } from "utils/methods/math";
import { WidgetsOutlined } from "@material-ui/icons";

const Wrapper = styled.div`
   position: fixed;
   right: 0px;
   bottom: 0px;
   display: flex;
   flex-direction: row;
   z-index:999;
   align-items: flex-end;
//    justify-content:flex-end;
   gap: 4px;
`;

export default function BottomIcons({ sharableWidget }) {
    const history = useHistory();
    const deviceType = useMobileDeviceDetection();
    const tabletType = useTabletDetection();
    const [widgetOpened, setIsWidgetOpened] = useState(false);
    const dispatch = useDispatch();
    const currentUser = useCurrentUser()
    const currentClass = useCurrentClass();
    const [noiseModal, invokeNoiseModal, revokeNoiseModal] = useNoiseMeter()
    const isTeacherHome = history.location.pathname === "/teacher/home" || history.location.pathname === "/student/home";
    const isChatWindow = window.location.pathname.includes("/chat");
    const isStudent = currentUser?.role === "student";
    const isTeacher = currentUser?.role === "teacher";
    const [value, setValue] = React.useState(0);
    const [currentValue, setCurrentValue] = React.useState(0);
    const [ideasModal, setIdeasModal] = useState(false);
    const teacherOrStudent = isStudent || isTeacher;
    const [previewClass, setPreviewClass] = useState(currentClass?.id)

    useEffect(() => {
        resetWidget()
    }, [currentClass])

    const resetWidget = async () => {
        if (currentClass?.id !== previewClass) {
            await dispatch(revokeAllWidget());
            revokeNoiseModal();
            setPreviewClass(currentClass?.id)
        }
    }

    if (!currentUser || isTeacherHome) return null;



    const pathChecker = history?.location?.pathname?.includes("/chat");

    return (

        <>
            <Wrapper style={{ width: deviceType ? '100%' : 'initial', zIndex: !pathChecker && 99999999999 }}>
                {deviceType ? <div className="bottom-container" style={{ width: '100%', border: '0.6px solid #e7eaef', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                    {teacherOrStudent && (
                        <Box className="bottom-container-box" >
                            <BottomNavigation
                                showLabels
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                            >
                                <BottomNavigationAction icon={<img src={homeBtnMenu} style={{ height: 20, boxShadow: currentValue === 1 ? '' : 'none' }} />} onClick={() => {
                                    history.push(currentUser?.role === "teacher" ? "/admin/class?view=home" : "/student/class?view=home")
                                    setCurrentValue(1);
                                }} />
                                <BottomNavigationAction icon={<img src={bellBtnmenu} style={{ height: 20, boxShadow: currentValue === 2 ? '' : 'none' }} />} onClick={() => {
                                    history.push('/notification')
                                    setCurrentValue(2)
                                }} />
                                <BottomNavigationAction icon={<img src={messageBtnmenu} style={{ height: 20, boxShadow: currentValue === 3 ? '' : 'none' }} />} onClick={() => {
                                    history.push('/chat')
                                    setCurrentValue(3)
                                }} />
                                {tabletType && currentUser?.role === "teacher" && (
                                    <BottomNavigationAction icon={<WidgetsOutlined style={{ height: 20, boxShadow: currentValue === 8 ? '' : 'none' }} />} onClick={() => {
                                        // history.push(currentUser?.role === "teacher" ? "/admin/class?view=home" : "/student/class?view=home")
                                        setIsWidgetOpened(true);
                                        setCurrentValue(8);
                                    }} />
                                )}
                                {currentUser?.role === "teacher" ? <BottomNavigationAction icon={<img src={gradeBtnmenu} style={{ height: 20, boxShadow: currentValue === 4 ? '' : 'none' }} />} onClick={() => {
                                    history.push('/admin/class?view=gradebook')
                                    setCurrentValue(4)
                                }} />
                                    : <BottomNavigationAction icon={<img src={gradeBtnmenu} style={{ height: 20, boxShadow: currentValue === 5 ? '' : 'none' }} />} onClick={() => {
                                        history.push('/student/class?view=assessments')
                                        setCurrentValue(5)
                                    }} />}
                                {currentUser?.role === "teacher" ? <BottomNavigationAction icon={<img src={roboBtnmenu} style={{ height: 20, boxShadow: currentValue === 6 ? '' : 'none' }} />} onClick={() => {
                                    setIdeasModal(true)
                                    setCurrentValue(6)
                                }} />
                                    : <BottomNavigationAction icon={<img src={booksBtnMenu} style={{ height: 20, boxShadow: currentValue === 7 ? '' : 'none' }} />} onClick={() => {
                                        history.push('/assignmentList')
                                        setCurrentValue(7)
                                    }} />
                                }

                            </BottomNavigation>
                        </Box>
                    )}
                </div> : <div style={{ display: 'flex', alignItems: 'flex-end', gap: 4, zIndex: '99999' }}>
                    {/* {isTeacher && <WheelModal />}
                    {isTeacher && <IdeaModal />} */}
                    {/* {isStudent && !isTeacher && <OneGameJoinModal />} */}
                    {isStudent && <DebateModal />}
                    {!isChatWindow && isStudent && <Chat />}
                </div>}

                <div style={{ display: 'none' }}><IdeaModal ideasModal={ideasModal} setIdeasModal={setIdeasModal} /></div>
            </Wrapper>
            <Wrapper>
                {isTeacher && (
                    <div className="widget-menu-bar" style={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: "end", gap: 4, zIndex: '999', position: "absolute", maxWidth: "28%", right: 0, left: "auto" }}>
                        <WidgetsMenu sharableWidget={sharableWidget} tabletMode={tabletType} widgetOpened={widgetOpened} setIsWidgetOpened={() => setIsWidgetOpened(false)} />
                    </div>
                )}
            </Wrapper>

        </>
    );
}
