import { DownloadOutlined } from '@ant-design/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Button as MtBtn,
  Select
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { endOfMonth, endOfWeek, formatISO, startOfMonth, startOfWeek, subDays } from 'date-fns';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState , forwardRef, useImperativeHandle} from 'react';
import { useClassUsers, useUserClasses, useUsersByClass } from 'store/userClasses/selectors';
import styled from 'styled-components';
import COLOR from 'utils/constants/color';
import { getCurrentSameDateRange } from 'utils/methods/math';
import MultiStudentSelecter from './MultiStudentSelector';
import { useDispatch } from 'react-redux';
import { useCurrentClass } from 'store/classes/selectors';
import Loading from './Loading';
import { handleGetGradebookData, handlelistDownloads } from 'store/exams/thunks';
import { message } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Badge, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MultiClassSelecter from './MultiClassSelecter';
import { map } from "lodash"
import { useSchoologyGradeCategory } from 'store/schoologyauth/selectors';

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: theme.spacing(1),
    maxHeight: 440, // total max height for header (40px) + body (400px)
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    overflow:"hidden"
  },
  accordionSummary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    maxHeight: 40, // max height for summary header
    alignItems: 'center',
    display: 'flex',
    justifyContent: "center",

  },
  accordionDetails: {
    flexDirection: 'column',
    maxHeight: 400, // max height for accordion body
    overflowY: 'auto', // make accordion body scrollable
  },
  textWrapper:{
    paddingBottom: 8,
    paddingTop: 8,
    borderTop: "0.1px solid",
    borderBottom: "0.1px solid"
  }
}));

const DownloadAccordion = ({ currentClass }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };
  const fetchItems = async () => {
    try {
      setLoading(true)
      const response = await dispatch(handlelistDownloads({
        class_id: currentClass.id
      }))
      if (Array.isArray(response)) {
        setItems(response)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  }
  useEffect(() => {
    if (expanded) {
      fetchItems()
    }
  }, [expanded])
  useEffect(() => {
    fetchItems()
  }, [])
  return (
    <Accordion className={classes.accordion} expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} color='#fff' />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ display: "flex", alignItems: "center" }}>Downloads {loading ? <Loading size={14} /> : null}</Typography>
        {/* <Badge
          badgeContent={items.length}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
         
        </Badge> */}
         <IconButton onClick={(e) => e.stopPropagation()} edge="end" color="inherit" style={{ color: "#fff" }}>
            <CloudDownloadIcon style={{ color: "#fff" }} color='#fff' />
          </IconButton>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {items.map((item) => (
          <Typography key={item.id} component="div" style={{ width: "100%" }} className={classes.textWrapper}>
            <a style={{ width: "100%", wordBreak: "break-all" }} href={item.url} download>{item.title}</a>
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};


const currentSemDate = getCurrentSameDateRange()
const current_sem = `${currentSemDate.from_date}/${currentSemDate.end_date}`
const generateDateRanges = () => {
  const now = new Date();
  const startOfCurrentWeek = startOfWeek(now, { weekStartsOn: 1 }); // Monday
  const endOfCurrentWeek = endOfWeek(now, { weekStartsOn: 1 });
  const startOfLastWeek = startOfWeek(subDays(now, 7), { weekStartsOn: 1 });
  const endOfLastWeek = endOfWeek(subDays(now, 7), { weekStartsOn: 1 });
  const startOfCurrentMonth = startOfMonth(now);
  const endOfCurrentMonth = endOfMonth(now);
  const startOfLastMonth = startOfMonth(subDays(now, 30));
  const endOfLastMonth = endOfMonth(subDays(now, 30));
  const last30DaysStart = subDays(now, 30);
  const last30DaysEnd = now;
  return [
    { label: 'Current semester', value: current_sem },
    { label: 'Current week', value: `${formatISO(startOfCurrentWeek)}/${formatISO(endOfCurrentWeek)}` },
    { label: 'Last week', value: `${formatISO(startOfLastWeek)}/${formatISO(endOfLastWeek)}` },
    { label: 'Current month', value: `${formatISO(startOfCurrentMonth)}/${formatISO(endOfCurrentMonth)}` },
    { label: 'Last month', value: `${formatISO(startOfLastMonth)}/${formatISO(endOfLastMonth)}` },
    { label: 'Last 30 days', value: `${formatISO(last30DaysStart)}/${formatISO(last30DaysEnd)}` },
  ];
};
const GradeTypeSelector = ({ onSelect }) => {
  const [selectedGradeType, setSelectedGradeType] = useState('all')
  const Options = [
    { label: 'All', value: 'all' },
    { label: 'graded', value: `graded` },
    { label: 'un-graded', value: `ungraded` }
  ]
  const handleChange = (event) => {
    setSelectedGradeType(event.target.value);
    onSelect(event.target.value)
  };
  return (<FormControl variant="outlined" fullWidth style={{ marginTop: 16 }}>
    <InputLabel>Select grade type</InputLabel>
    <Select
      value={selectedGradeType}
      onChange={handleChange}
      label="Date range"
    >
      {Options.map((range, index) => (
        <MenuItem key={index} value={range.value}>
          {range.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>)
}
const DateRangeDropdown = ({ onDatePicked }) => {
  const [selectedDateRange, setSelectedDateRange] = useState(current_sem);
  const [dateRanges, setDateRanges] = useState([]);
  const [open, setOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());


  useEffect(() => {
    setDateRanges(generateDateRanges());
  }, []);

  const handleChange = (event) => {
    if (event.target.value === 'custom') {
      setOpen(true);
    } else {
      onDatePicked(event.target.value)
      setSelectedDateRange(event.target.value);
    }
  };

  const handleCustomDateChange = () => {
    const customRange = `${formatISO(customStartDate)}/${formatISO(customEndDate)}`;
    setSelectedDateRange(customRange);
    onDatePicked(customRange)
    setOpen(false);
  };
  const getDisplayValue = (value) => {
    if (value === 'custom') return 'Custom date range';
    const range = dateRanges.find((range) => range.value === value);
    return range ? range.label : 'Custom date range';
  };

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Date range</InputLabel>
        <Select
          value={selectedDateRange}
          onChange={handleChange}
          label="Date range"
          renderValue={() => getDisplayValue(selectedDateRange)}
        >
          {dateRanges.map((range, index) => (
            <MenuItem key={index} value={range.value}>
              {range.label}
            </MenuItem>
          ))}
          <MenuItem value="custom">Custom date range</MenuItem>
        </Select>
      </FormControl>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Custom Date Range</DialogTitle>
        <DialogContent>
          <DateTimePicker
            label="Start Date"
            inputVariant="outlined"
            value={customStartDate}
            format="MM-dd-yyyy hh:mm a"
            onChange={setCustomStartDate}
            fullWidth
          />
          <DateTimePicker
            label="End Date"
            style={{ marginTop: 16 }}
            inputVariant="outlined"
            format="MM-dd-yyyy hh:mm a"
            value={customEndDate}
            onChange={setCustomEndDate}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <MtBtn onClick={() => setOpen(false)} color="primary">
            Cancel
          </MtBtn>
          <MtBtn onClick={handleCustomDateChange} color="primary">
            Apply
          </MtBtn>
        </DialogActions>
      </Dialog>
    </>
  );
};






const Button = styled.button`
    background-color: transparent;
    border: none;
    color: ${COLOR.green200};
    cursor: pointer;
    outline: none;
    transition: all 300ms ease;

    &:hover {
        color: ${COLOR.green}
    }
`;
const ModalContent = styled.div`
    align-items: center;
    background-color: ${COLOR.white};
    border-radius: 3px;
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 320px;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const ModalRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
`;

const GradeDownloader = forwardRef(({ onDownoad, scale_1_5, gradebook_a_f }, ref) =>  {
  const [gradePopulateModal, setGradePopulateModal] = React.useState(false);
  const currentClass = useCurrentClass()
  const classUsers = useClassUsers()
  const userClasses = useUserClasses()
  const dispatch = useDispatch()
  const [loader, setLoading] = useState(null)
  const [selected_date, setSelectedDate] = useState(current_sem)
  const [selected_students, set_selected_students] = useState([])
  const [fetch_to, setfetch_to] = useState("all")
  const [selected_class, set_selected_class] = useState([])
  const usersByClass = useUsersByClass()
  const [, , schoologyAllClassGradeCategory] = useSchoologyGradeCategory()
  useImperativeHandle(ref, () => ({
    open: () => {
      setGradePopulateModal(true);
    },
  }));
  useEffect(() => {
    if (gradePopulateModal) {
      setSelectedDate(current_sem)
      set_selected_students([])
      setfetch_to("all")
    }
  }, [gradePopulateModal])
  useEffect(() => {
    set_selected_class([currentClass])
  }, [currentClass, gradePopulateModal]);
  const onClosePopulateGrade = () => {
    setGradePopulateModal(false);
  }
  const onDatePicked = (value) => {
    setSelectedDate(value);
  }
  const handleFetchData = async (type) => {
    if (!selected_date) return;
    setLoading(type);
    const classes_list = selected_class.length > 0?selected_class:userClasses
      await Promise.allSettled(map(classes_list ,async uc=>{
        const c_student = selected_students.map(k => k.id)
        const schoologyGradeCategory = schoologyAllClassGradeCategory? schoologyAllClassGradeCategory[uc.sectionId]:[] || []
        try {
          const row1 = ['', '', '', 'Books']
          const row2 = ['', '', '', 'assignment']
          const row3 = ['', 'username', 'password', 'due date/average']
          const userData = []
          const from_date = selected_date.split('/')[0];
          const end_date = selected_date.split("/")[1]
          const data = await dispatch(handleGetGradebookData({
            "from_date": from_date,
            "end_date": end_date,
            "class_id": uc.id,
            "students": classes_list.length === 1 ? c_student : [],
            scale_1_5,
            gradebook_a_f,
            schoologyGradeCategory:schoologyGradeCategory,
            fetch_to
          }))
          if (data && data.result) {
            row1.push(...data.book_name)
            row2.push(...data.assignment_name)
            row3.push(...data.date_list)
            userData.push(...data.result)
          }
          const finaldata = [row1, row2, row3, ...userData]
          onDownoad(type, finaldata,`${uc.displayName}-${moment(from_date).format("YYYY-MM-DD HH:mm:ss")}-between-${moment(end_date).format("YYYY-MM-DD HH:mm:ss")}.${type}`,uc.id)
        } catch (error) {
          console.log(error, "error in exam gradebook downolad")
          // message.error("Something went wrong")
         
        }
      })).then(() => {
        message.success("Downloaded successfully")
      });
      setLoading(null);
      onClosePopulateGrade()
  }
  const getClassStudents = useMemo(() => {
    if (selected_class.length === 1 && typeof usersByClass) {
      const class_id = selected_class[0].id
      return usersByClass[class_id] || []
    } else return [];
  }, [selected_class, usersByClass])
  return (<>
    <Modal
      open={gradePopulateModal}
      onClose={onClosePopulateGrade}
    >
      <ModalContent>
        <DownloadAccordion currentClass={currentClass} />
        {gradePopulateModal && <DateRangeDropdown onDatePicked={onDatePicked} />}
        {gradePopulateModal && selected_class.length === 1  && <MultiStudentSelecter options={getClassStudents} onSelect={(value) => {
          set_selected_students(value)
        }}
          defaultValue={selected_students} wrapperStyle={{ marginTop: "16px" }}
        />}
        {gradePopulateModal && <MultiClassSelecter options={userClasses} onSelect={(value) => {
          set_selected_class(value)
          set_selected_students([])
        }}
          defaultValue={selected_class} wrapperStyle={{ marginTop: "16px" }}
        />}
        {gradePopulateModal && <GradeTypeSelector
          onSelect={(value) => {
            setfetch_to(value)
          }}
        />}
        <ModalRow>
          <MtBtn
            color="secondary"
            style={{ marginRight: '4px' }}
            onClick={onClosePopulateGrade}
          >
            Cancel
          </MtBtn>
          <MtBtn
            color="primary"
            style={{ marginRight: '4px' }}
            onClick={() => {
              handleFetchData("XLSX")
            }}
          >
            {loader === "XLSX" ? <Loading size={14} /> : <DownloadOutlined style={{ marginRight: '4px' }} />}
            XLSX
          </MtBtn>
          <MtBtn
            color="primary"
            style={{ marginRight: '4px' }}
            onClick={() => {
              handleFetchData("CSV")
            }}

          >
            {loader === "CSV" ? <Loading size={14} /> : <DownloadOutlined style={{ marginRight: '4px' }} />}
            CSV
          </MtBtn>
        </ModalRow>
      </ModalContent>
    </Modal>
    <Button onClick={e => setGradePopulateModal(true)} style={{ marginRight: '16px' }}>
      <DownloadOutlined style={{ marginRight: '4px' }} />
      Download
    </Button>
  </>

  )
})


export default GradeDownloader;