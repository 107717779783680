//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { API } from 'aws-amplify';

//  -- Actions ------------------------
import * as ExamsActions from './actions';
import * as Sentry from "@sentry/react";
import { has } from 'lodash';

//  ----------------------------------------------------------------------------
//  Thunks
//  ----------------------------------------------------------------------------


//  -- List chapter interactions ------


export const handleListApExams = () => async (dispatch) => {
    await dispatch(ExamsActions.listApExams());

    try {
        const apexams = await API.get('apexams', `/list`);
        if (Array.isArray(apexams)) {
            await dispatch(ExamsActions.listApExamssSuccess(apexams));
        }
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.listApExamssFail(error));
    }
};

export const handleCreateApExams = (data) => async (dispatch) => {
    await dispatch(ExamsActions.createApExams());
    try {
        const exam = await API.post('apexams', `/create`, {
            body: data
        });
        if (has(exam, "id")) {
            await dispatch(ExamsActions.createApExamsSuccess(exam));
        }
        return exam;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.createApExamsFail(error));
    }
};
export const handleUpdateApExams = (data) => async (dispatch) => {
    await dispatch(ExamsActions.updateApExams());
    try {
        const exam = await API.put('apexams', `/update`, {
            body: data
        });
        if (has(exam, "id")) {
            await dispatch(ExamsActions.updateApExamsSuccess(exam));
        }
        return exam;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.updateApExamsFail(error));
    }
};
export const handleDeleteApExam = (id) => async (dispatch) => {
    await dispatch(ExamsActions.deleteApExams());
    try {
        const exam = await API.del('apexams', `/delete/${id}`);
        if (has(exam, "id")) {
            await dispatch(ExamsActions.deleteApExamsSuccess(id));
        }
        return exam;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        await dispatch(ExamsActions.deleteApExamsFail(error));
    }
};

export const handleListApExamDataByConfig = (data) => async (dispatch) => {
    try {
        const result = await API.post('apexams', `/list_by_org`, {
            body: data
        });

        return result;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};
export const handleListApExamDataForGraphByConfig = (data) => async (dispatch) => {
    try {
        const result = await API.post('apexams', `/dist_graph_data`, {
            body: data
        });

        return result;
    } catch (error) {
        if (error && error.message != "Network Error") {
            Sentry.captureException(error)
        }
        return null;
    }
};