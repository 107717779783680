//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as Actions from "./actions"
import { useCurrentClass } from 'store/classes/selectors';
import { useCurrentUser } from 'store/users/selectors';
import Schoology from 'utils/Schoology';
import { useEffect } from 'react';
import { get , map } from 'lodash'
import { useUserClasses } from 'store/userClasses/selectors';
//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.schoologyAuth;
const categorySelector = (state) => state.schoologyGradeCategory;
const categoryAllSelector = (state) => state.schoologyAllClassGradeCategory;
const schoologyAssignmentSelector = (state) => state.schoologyAssignment;
//  -- Examples array selector --------

export const useSchoologyAuth = () => {
    const auth = useSelector(selector, shallowEqual)
    return auth;
}



export const useSchoologyGradeCategory = () => {
    const schoologyGradeCategory = useSelector(categorySelector, shallowEqual)
    const schoologyAllClassGradeCategory = useSelector(categoryAllSelector, shallowEqual)
    const currentClass = useCurrentClass()
    const userClasses = useUserClasses()
    const currentUser = useCurrentUser()
    const auth = useSchoologyAuth()
    const client = new Schoology(auth);
    const dispatch = useDispatch()
    const listSchoologyGradeCategory = async (sectionId) => {
        try {
            const catrgories = await client.getGradingCateogryBySectionId(sectionId)
            dispatch(Actions.setSchoologyGradeCategory(get(catrgories,"grading_category",[])))
        } catch (error) {
            
        }
      
    }
    const listAllclassSchoologyGradeCategory = async () => {
        const catog = {}
       Promise.allSettled(map(userClasses,async cls=>{
            if(cls&&cls.sectionId){
                const catrgories = await client.getGradingCateogryBySectionId(cls.sectionId)
                catog[cls.sectionId] = get(catrgories,"grading_category",[])
            }
        })).then(()=>{
            dispatch(Actions.setSchoologyAllClassGradeCategory(catog))
        })
    }
    
    useEffect(() => {
        client.setToken(auth)
        
    }, [auth])
    useEffect(() => {
        if (auth && currentClass && currentClass.sectionId && currentUser && currentUser.schoologyid) {
            listSchoologyGradeCategory(currentClass.sectionId)
        }
    }, [currentClass, currentUser, auth])

    useEffect(() => {
        if (auth && currentUser && currentUser.schoologyid) {
            listAllclassSchoologyGradeCategory()
        }
    }, [userClasses, currentUser, auth])

    return [schoologyGradeCategory, listSchoologyGradeCategory , schoologyAllClassGradeCategory];
}

export const useSchoologyAssignment = () => {
    const schoologyAssignment = useSelector(schoologyAssignmentSelector, shallowEqual)
    const currentClass = useCurrentClass()
    const currentUser = useCurrentUser()
    const auth = useSchoologyAuth()
    const client = new Schoology(auth);
    const dispatch = useDispatch()

    const listSchoologyAssignment = async (sectionId) => {
        const assignment = await client.getSectionAssignment(sectionId)
        dispatch(Actions.setSchoologyAssignment(get(assignment,"assignment",[])))
    }
    useEffect(() => {
        client.setToken(auth)
        
    }, [auth])
    useEffect(() => {
        if (auth && currentClass && currentClass.sectionId && currentUser && currentUser.schoologyid) {
            listSchoologyAssignment(currentClass.sectionId)
        }
    }, [currentClass, currentUser, auth])
    return [schoologyAssignment,listSchoologyAssignment];
}