import { Button, Col, Row, Switch } from "antd";
// import {  MessageOutlined } from "antd/icons";
import { MessageOutlined } from "@ant-design/icons";

import { get } from "lodash";
import moment from "moment";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useCurrentUser, useUserProfileConfig } from "store/users/selectors";
import styled from "styled-components";
import {
  DebateNewLogo,
  DebateComment,
  EditDebate
} from "utils/assets/images";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { MenuItem, Select, debounce } from "@material-ui/core";
import { getAvatar } from "utils/assets/images/lootie";
import { useFirebase } from "containers/FirebaseContext";
import Acent from "components/Acent";
import { v4 } from 'uuid';
import { onValue } from "firebase/database";

const ResponseText = styled.div`
  margin-left: 20px;
`;

const DebateCommentSection = ({
  studentSubmission,
  debateResponse,
  timer,
  onEditTimer = () => null}) => {
  const currentUser = useCurrentUser();
  const [showStudentNames, setShowStudentNames] = useState(false);
  const [editTime, setEditTime] = useState(false);
  const configurations = get(debateResponse, 'configurations', '') || {};
  const { profile_config } = useUserProfileConfig()
  // Count the comments given by the specified user
  const commentCount = studentSubmission.reduce((total, answer) => {
    const userComments = answer.comment.filter(comment => comment.user_id === get(currentUser, "id", ""));
    return total + userComments.length;
  }, 0);
  const remainingReplies = parseInt(configurations?.total_replies) - commentCount;
  const [openedAccordions, setOpenedAccordions] = useState([]);
  const { firestore, databaseRef, databaseSet, database } = useFirebase();
  const [commentValue, setCommentValue] = useState("");
  const handleAccordionToggle = (index) => {
    if (openedAccordions.includes(index)) {
      setOpenedAccordions(openedAccordions.filter(item => item !== index));
    } else {
      setOpenedAccordions([...openedAccordions, index]);
    }
  };
  const getUserProfileImg = (img) => {
    if (img) return getAvatar(img)
    return DebateComment;
  }

  const Accordion = ({ num, title, children, subtitle = "", responseInfo, comments, configurations, openedAccordions, handleAccordionToggle, user_profile_img, commentValue }) => {
    const [hideBtn, setHideBtn] = useState(true);
    const [showTextArea, setShowTextArea] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [isOpen, setOpen] = useState(false);
    const handleIconClick = () => {
      setShowTextArea(true);
    };

    useEffect(() => {
      if (commentText === '' && commentValue) {
        setCommentText(commentValue)
      }
    }, [commentValue])

    const handleTextAreaChange = (text) => {
      setCommentText(text);
      setCommentValue(text);
    };

    const handleCommentSubmit = async () => {
      if (parseInt(configurations?.total_receive) < comments?.length) return;
      const userReplyData = {
        reply_time: moment().toISOString(),
        user_id: get(currentUser, "id", ""),
        user_name: `${get(currentUser, "firstName", "")} ${get(currentUser, "lastName", "")}`,
        reply_text: commentText,
        response_id: get(responseInfo, "user_id", ""),
        user_profile_img: get(profile_config, "config.profile_pic", ''),
        comment_id: v4()
      };
      await updateComments(userReplyData);
      // Reset the state after submission
      // setShowTextArea(false);
      // setCommentText("");
      setCommentValue("");
      setHideBtn(true);
    };

    const updateComments = async (comment) => {
      const user_profile_imgs = get(profile_config, "config.profile_pic", "");
      const generated_session_id = comment?.response_id + debateResponse?.id;
      const docRef = doc(firestore, "debate_submission", generated_session_id);
      try {
        // Start the transaction
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const existingData = docSnap.data();
          const existingComments = existingData.comment || [];
          comment.user_profile_img = user_profile_imgs;
          const updatedComments = [...existingComments, comment];
          await updateDoc(docRef, { comment: updatedComments });
        }
      } catch (e) {
        console.log("Error adding document: ", e);
      }
    };

    const responseComments = get(responseInfo, "comment") || [];
    const findResponse = responseComments.some((itm) => itm.user_id === get(currentUser, "id", ""));
    const textareaCommentRef = useRef(null);
    return (
      <div className="accordionWrapper">
        <div
          className={`accordionTitle ${openedAccordions.includes(num) ? "open" : ""}`}
          onClick={() => handleAccordionToggle(num)}
        >
          <div className="acordCont">
            <div className="debateHeader">
              <span>
                <img src={user_profile_img} alt="Debate" />
              </span>
              <div className="debateContent">
                <p>{title}</p>
                <div className="stdntComntTopShow">
                  <p>{subtitle}</p>
                  <div style={{ position: "absolute", right: 0, top: 0 }} className={`stdntComntShow ${parseInt(configurations?.total_receive) <= comments?.length ? "stdntComntShowDone" : ""}`}>
                    <label>{comments?.length || 0}</label>
                  </div>
                </div>
              </div>
            </div>

            {!findResponse && remainingReplies > 0 && get(currentUser, "role") === "student" && responseInfo?.user_id !== get(currentUser, "id", '') && parseInt(configurations?.total_receive) !== comments?.length && (
              <div className="commentIcon" style={{ position: "absolute", top: 27, right: -2 }}>
                <Button onClick={() => handleIconClick()} shape="circle" disabled={timer === '00:00' ? true : false}>
                  <MessageOutlined
                    className="messageIcon"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className={`accordion-item ${openedAccordions.includes(num) ? "" : "collapsed"}`}>
          <div className="accordion-content">{children}</div>

          {!findResponse && remainingReplies > 0 && get(currentUser, "role") === "student" && responseInfo?.user_id !== get(currentUser, "id", '') && parseInt(configurations?.total_receive) !== comments?.length && openedAccordions.includes(num) && remainingReplies > 0 && (
            <>
              <div className="commentAccentBtn">
                <Acent inputRef={textareaCommentRef} setValue={handleTextAreaChange} style={{ marginBottom: 5, display: "flex", justifyContent: "flex-end" }} />
              </div>
              <div className="commentTextArea studentCommentSec">
                <img
                  src={getUserProfileImg(get(profile_config, "config.profile_pic", ''))}
                  alt="Debate"
                  className="notificationImage"
                />

                <textarea
                  ref={textareaCommentRef}
                  placeholder="Enter your comment..."
                  value={commentText}
                  onChange={(e) => handleTextAreaChange(e.target.value)}
                />
                <Button
                  className="debateEnterButton liveDebateClickBtn" style={{ position: 'absolute', bottom: 5, right: 40 }}
                  onClick={() => {
                    setHideBtn(false);
                    handleCommentSubmit();
                  }}
                  disabled={!commentText.trim() || !hideBtn}
                >
                  &#9166;
                </Button>

              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const commentRender = useMemo(() => {
    return (
      <>
        {/* {get(currentUser, "role") === "student" && (
          <div className="studentReplyTxt">
            <span>You can only post one reply per student</span>
          </div>
        )} */}
        <div className="studentsubmissionbg">
          {studentSubmission.map((debate, index) => (
            <Accordion
              num={index}
              title={
                (showStudentNames || get(currentUser, 'id', '') === debate?.user_id)
                  ? `${debate?.user_name}`
                  : `Student ${index + 1}`
              }
              user_profile_img={getUserProfileImg(debate?.user_profile_img)}
              subtitle={debate?.answer_text}
              responseInfo={debate}
              comments={debate?.comment}
              configurations={configurations}
              openedAccordions={openedAccordions}
              commentValue={commentValue}
              handleAccordionToggle={handleAccordionToggle}
            >
              {debate?.comment?.map((comments, pIndex) => (
                <div key={pIndex} className="createCollapse studentCommentSec" style={{ display: 'flex', marginBottom: 10 }}>
                  <img
                    src={getUserProfileImg(comments?.user_profile_img)}
                    alt="Debate"
                    className="submissionnotification"
                  />
                  <div>
                    {get(currentUser, 'id', '') === comments?.user_id ? (
                      <p>{comments?.user_name}</p>
                    ) : (
                      <p>{showStudentNames
                        ? `${comments?.user_name}`
                        : `Student ${(studentSubmission?.findIndex((item) => item?.user_id == comments?.user_id)) + 1}`}</p>
                    )}
                    <p style={{ fontSize: 15, fontWeight: 500 }}>{comments?.reply_text}</p>
                  </div>
                </div>
              ))}
            </Accordion>
          ))}
        </div>
      </>
    )
  }, [studentSubmission, showStudentNames, openedAccordions]);

  const getTimeFromDate = (timer) => {
    const timeComponents = timer.split(":");
    const minutes = parseInt(timeComponents[0]); // Extract minutes part
    const seconds = parseInt(timeComponents[1]); // Extract seconds part
    const totalTimeInSeconds = minutes * 60 + seconds; // Convert minutes to seconds and add seconds

    return totalTimeInSeconds;
  };

  const audio = new Audio(require("utils/assets/audio/timer_end.mp3"));
  audio.volume = 1

  useEffect(() => {
    if (get(currentUser, 'role', '') === 'teacher') {
      if (getTimeFromDate(timer) <= 0) {
        audio.pause()
      } else if (getTimeFromDate(timer) <= 5) {
        audio.play();
      } else {

      }
    }
  }, [timer, audio]);
  
  useEffect(() => {
    if (Object.keys(debateResponse).length > 0) {
      const timerRef = databaseRef(
        database,
        "debateStatus/" + debateResponse.id
      );
      const unsubscribe = onValue(timerRef, (snapshot) => {
        try {
          const statusData = snapshot.val();
          setShowStudentNames(statusData?.showStatus);
        } catch (error) {
          console.log("error in database timer", error);
        }
      });
      return () => unsubscribe();
    }
  }, [database, debateResponse]);

  const showStudentName = async (check) => {
    const dbRef = databaseRef(database, "debateStatus/" + debateResponse.id);
    databaseSet(dbRef, { showStatus: check })
      .then((res) => { })
      .catch((error) => {
        console.log("Error updating time in the database:", error);
      });
  };

  return (
    <div className="ideaMdlUpr">
      <Row>
        <Col xs={24}>
          <div className="allDebateCommitSection">
            <div className="debateMdlTop debateAllTopTxt studentCommitModal debateAllTopSpace">
              <img src={DebateNewLogo} alt="Debate" />
              <ResponseText>
                <span>{debateResponse?.question}</span>
              </ResponseText>
            </div>
            <div className="stdntTopCommitSec">
              <div className="studntCommitSection">
                <div className={getTimeFromDate(timer) <= 0 ? 'blackTimerSection' : (getTimeFromDate(timer) <= 5 && get(currentUser, 'role', '') === 'teacher') ? 'stduntTimerSection' : ''}>
                  <span>{timer}</span>
                </div>
                {editTime && getTimeFromDate(timer) > 0 &&
                  <div className="blogInputBox">
                    <div style={{ marginRight: 12 }}>
                      <button onClick={() => {
                        onEditTimer(1 * 60);
                      }}>+ 1</button>
                    </div>
                  </div>
                }
                {get(currentUser, "role") === "teacher" && getTimeFromDate(timer) > 0 && (
                  <img style={{ cursor: 'pointer' }} src={EditDebate} alt="Debate" onClick={() => {
                    setEditTime(!editTime);
                  }} />
                )}
              </div>
              {get(currentUser, "role") === "teacher" ? (
                <div>
                  <div className="debatcommitTxt">
                    <span>Show student names</span>
                    <Switch checked={showStudentNames} onClick={(checked) => {
                      showStudentName(checked)
                    }} />
                  </div>
                </div>
              ) : (
                <div className="debatcommitTxtLeft">
                  <span>How many REPLIES left:</span>
                  <div className={`stdntComntShow ${parseInt(remainingReplies) === 0 ? "stdntComntShowDone" : ""}`}>
                    <label>{remainingReplies}</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>

        <Col xs={24}>
          {get(currentUser, "role") === "student" && (
            <div className="studentReplyTxt">
              <span>You can only post one reply per student</span>
            </div>
          )}
          <div className="allLiveDebateSec">
            <div className="createCollapse">
              {studentSubmission.length === 0 ? (
                <div className="studentResponceSecLiveDebate">
                  Waiting for students response...
                </div>
              ) : (
                <div className="studentsubmissionmainbg">
                  {commentRender}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DebateCommentSection;
