import { Button } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Col, Dropdown, Menu, Row, Tooltip } from "antd";
import FlatList from "flatlist-react";
import { filter, find, get, isEmpty, map, sortBy, has, concat } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAllBook } from "store/books/selectors";
import { useClassActivities } from "store/classActivities/selectors";
import { useClassQuizzes } from "store/classQuizzes/selectors";
import { useStory } from "store/story/selectors";
import { handleListStory, handleListStoryStandard } from "store/story/thunks";
import { useCurrentUser } from "store/users/selectors";
import styled from "styled-components";
import { MenuIcon, Pic1, SearchIcon } from "utils/assets/images";
import COLOR from "utils/constants/color";
import { filterStoryClassQuizOrActivity } from "utils/lib/ebookHelper";
import {
  handleListClassQuizzes,
  handleListClassQuizzesForStudent,
} from "store/classQuizzes/thunks";
import {
  handleListClassActivities,
  handleListClassActivitiesForStudent,
} from "store/classActivities/thunks";
import { useCurrentClass } from "store/classes/selectors";
import BottomDropDown from "utils/assets/images/story-dropdown.svg";
import HeartIcon from "utils/assets/images/heart-icon.svg";
import Unlike from "utils/assets/images/unlike1.svg";
import Liked from "utils/assets/images/like1.svg";
import StorySearch from "utils/assets/images/storySearch.svg";
import {
  IBTHEME,
  APTHEME,
  AnecdotasThemes,
  CATEGORIAS,
  getFireBaseUrl,
} from "utils/config/reference";
import BackIcon from "utils/assets/images/back-icon.svg";
import {
  handleCreateFavouriteVideo,
  handleListFavouriteVideo,
} from "store/FavouriteVideo/thunks";
import { useFavouriteVideo } from "store/FavouriteVideo/selectors";
import Loading from "components/Loading";
import DropdownMenu from "components/DropDowns/DropdownMenu";
import { EmptyImagePlaceholder } from "components/EmptyPlaceholderLesson";

const PAGE_CATEGORY = [
  "All",
  "Entretenimiento",
  "Tecnología",
  "Deportes",
  "Historias",
  "Cultura",
  "Música",
  "Curiosidades",
  "Cine",
  "Fantasía",
  "Realismo",
  "Ciencia ficción",
  "Magia",
  "Espacio",
  "6-8th ",
  "9-10th",
  "11-12th",
];
const PaddedBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  padding: 8px;
  text-align: left;
  width: 100%;
`;
const MenuIconImg = styled.img`
  // margin-left: 10px;
  height: 16px;
  // background-color: #fff;
  // border-radius: 50%;
  // padding: 2px;
`;
const SearchInput = styled.input`
  // width: 100px;
  // width: 80px;
  height: 100%;
  color: #fff;
  border: none;
  background: transparent;
  outline: none;
  // text-align: center;
  // font-weight: 800;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR.white};
  }
  ::focus {
    outline: none;
  }
  @media only screen and (max-width: 360px) {
    width: 92px;
  }

`;
const CategoryMenu = styled.div`
  background-color: #0b1c2b;
  border-radius: 30px;
  color: #fff;
  width: 300px;
  height: 42px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  // text-align: center;
  color: #fff;
  // font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #fff;
  color: #fff;
  padding-left: 15px;
  // padding-right: 15px;
  justify-content: space-between;
  // margin: 20px 10px;
  // margin: 20px 20px 20px 10px;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 576px) {
    // margin: 15px 8px;
  }
`;

function RenderStory({
  book,
  onClick,
  handleMyFavourite,
  allFavorites,
  loader,
  setAlert
}) {
  const image = get(book, "img", Pic1);
  const BASICO = find(book.levels, (k) => k.level === "BASICO");
  const PREBÁSICO = find(book.levels, (k) => k.level === "PREBÁSICO");
  const INTERMEDIO = find(book.levels, (k) => k.level === "INTERMEDIO");
  const AVANZADO = find(book.levels, (k) => k.level === "AVANZADO");
  const getFavorites = allFavorites?.some((video) => video.id === book.id);

  return (
    <Col xs={24} sm={12} md={8} lg={8} xl={4}>
      <div className="ImgBtn storyImgBtn">
        {/* <label>{get(book, "title", "")}</label> */}
        <div className="coverBtns">
          <div className="btnsGrpMain">
            <div className="btnsGrpTxt storyBtnsGrpTxt">
              <div className="storyFistTwoBtn">
                <Button
                  className="btnsTxtGreen"
                  onClick={() =>
                    // onClick(
                    //   get(book, "bookId"),
                    //   get(book, "id"),
                    //   "PREBÁSICO",
                    //   PREBÁSICO
                    // )
                    setAlert({
                      open: true,
                      message: "Coming Soon",
                    })
                  }
                >
                  Prebásico
                </Button>
                <Button
                  className="btnsTxtYellow"
                  onClick={() =>
                    onClick(
                      get(book, "bookId"),
                      get(book, "id"),
                      "BASICO",
                      BASICO
                    )
                  }
                >
                  Básico
                </Button>
              </div>
              <div className="storyFistTwoBtn">
                <Button
                  className="btnsTxtPurpal"
                  onClick={() =>
                    onClick(
                      get(book, "bookId"),
                      get(book, "id"),
                      "INTERMEDIO",
                      INTERMEDIO
                    )
                  }
                >
                  Intermedio
                </Button>
                <Button
                  className="btnsTxtAsmani"
                  onClick={() =>
                    onClick(
                      get(book, "bookId"),
                      get(book, "id"),
                      "AVANZADO",
                      AVANZADO
                    )
                  }
                >
                  Avanzado
                </Button>
              </div>
            </div>
          </div>
          <div>
            <div
              onClick={() => {
                if (loader === "") {
                  handleMyFavourite(book);
                }
              }}
              className="like-unlike-resource-container storyLikeUnLike"
            >
              {loader === book?.id ? (
                <div style={{ marginBottom: 6 }}>
                  <Loading size={18} />
                </div>
              ) : (
                <img
                  width={39}
                  height={39}
                  src={getFavorites ? Liked : Unlike}
                  alt=""
                />
              )}
            </div>
            <img src={image} />
          </div>
        </div>
        <label>{get(book, "title", "")}</label>
      </div>
    </Col>
  );
}
export default function StoryLevels() {
  const history = useHistory();
  const story = useStory();
  const currentClass = useCurrentClass();
  const dispatch = useDispatch();
  const [storyBookGroups, setStoryBookGroups] = useState([]);
  const [filterdStory, setFilterdStory] = useState([]);
  const currentUser = useCurrentUser();
  const classQuizzes = useClassQuizzes();
  const classActivities = useClassActivities();
  const allFavorites = useFavouriteVideo();
  const [selectedCategories, setSelectedCategories] = useState("All");
  const [serachText, setSearchText] = useState("");
  const [isSelectedFav, setIsSelectedFav] = useState("");
  const [loader, setLoader] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    type: "",
    open: false,
  });
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
  });
  const isStudent = get(currentUser, "role") === "student";
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [apThemeFilter, setApThemeFilter] = useState([]);
  const [ibThemeFilter, setIbThemeFilter] = useState([]);
  const [anecotasThemeFilter, setAnecotasThemeFilter] = useState([]);
  const [fetchFavoritesList, setFetchFavorites] = useState(allFavorites);
  const [isFetchDataComplete, setIsFetchDataComplete] = useState(false);

  useEffect(() => {
    if (isFetchDataComplete) {
      const createFavourite = async () => {
        await dispatch(handleCreateFavouriteVideo(fetchFavoritesList));
      }
      createFavourite();
    }
  }, [fetchFavoritesList])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const [isSelectedFilter, setIsSelectedFilter] = useState("");


  const dropDownHandler = (action,value) => {
    if(action === "category"){
      filterByCategory(value?.title);
    }
    else if(action === "ibThemeFitler"){
        setIbThemeFilter(prev => {
          if(prev.includes(value)){
            return prev.filter(item => item !== value)
          }
          return [...prev,value]
        })
    }else if(action === "apThemeFilter"){
      setApThemeFilter(prev => {
        if(prev.includes(value)){
          return prev.filter(item => item !== value)
        }
        return [...prev,value]
      })
    }
    else if(action === "anecotasThemeFilter"){
        setAnecotasThemeFilter(prev => {
          if(prev.includes(value)){
            return prev.filter(item => item !== value)
          }
          return [...prev,value]
        })
    }
  
  }

  useEffect(() => {
    fetchAllStory();
  }, [story]);

  const fetchAllStory = () => {
    const storyBook = sortBy(story, (k) => parseInt(k.order), "asc");
    if (!isEmpty(storyBook)) {
      setStoryBookGroups(storyBook);
      setFilterdStory(storyBook);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#0B1C2B';
    document.body.classList.add("overflow-x-hidden");

    return () => {
      document.body.classList.remove("overflow-x-hidden");
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    const fetchAll = async () => {
      await Promise.allSettled([
        dispatch(handleListStory()),
        dispatch(handleListStoryStandard()),
        fetchFavorites(),
        listClassAssignments(),
      ])
      setIsFetchDataComplete(true);
    }
    fetchAll();
  }, []);

  const fetchFavorites = async () => {
    const data = {
      user_id: currentUser.id,
      type: "story",
    };
    const result = await dispatch(handleListFavouriteVideo(data));
    setFetchFavorites(result);
  };

  const listClassAssignments = async () => {
    if (has(currentClass, "id") && get(currentUser, "role") != "student") {
      dispatch(handleListClassActivities(currentClass.id));
      dispatch(handleListClassQuizzes(currentClass.id));
    } else {
      dispatch(
        handleListClassActivitiesForStudent(
          get(currentClass, "id"),
          get(currentUser, "id")
        )
      );
      dispatch(
        handleListClassQuizzesForStudent(
          get(currentClass, "id"),
          get(currentUser, "id")
        )
      );
    }
  };
  const checkIsAssignment = (bookId, pageStart, pageEnd) => {
    const assassignment = concat(classQuizzes, classActivities)
      .filter((item) => get(item, "data.chapter", "") == bookId)
      .find(
        (item) =>
          parseInt(get(item, "data.page", "")) >= parseInt(pageStart) &&
          parseInt(get(item, "data.page", "")) <= parseInt(pageEnd)
      );
    return Boolean(assassignment);
  };
  const onClick = (bookId, id, level, levelData) => {
    if (bookId) {
      if (isStudent) {
        const hasAssignment = checkIsAssignment(
          bookId,
          get(levelData, "start"),
          get(levelData, "finish")
        );
        if (hasAssignment) {
          redirectToEbook(id, level, get(levelData, "start"));
        } else {
          setAlert({
            open: true,
            message: "Not Assigned by Teacher",
          });
        }
      } else {
        redirectToEbook(id, level, get(levelData, "start"));
      }
    } else {
      setAlert({
        open: true,
        message: "Not available",
      });
    }
  };
  const redirectToEbook = (id, level, pageStart) => {
    if (!pageStart) return;
    const url = `/stories?id=${id}&page=${pageStart}&level=${level}&activity=null&quiz=null`;
    history.push(url);
  };
  const handleClose = () => {
    setAlert({
      open: false,
      message: "",
    });
  };

  const handleMyFavourite = async (book) => {
    const isBookInFavorites = fetchFavoritesList?.videos?.some(
      (video) => video.id === book.id
    );
    const updatedVideos = isBookInFavorites
      ? fetchFavoritesList.videos.filter((video) => video.id !== book.id)
      : [...(fetchFavoritesList?.videos || []), book];
    let data;
    if (fetchFavoritesList?.id) {
      data = {
        ...fetchFavoritesList,
        videos: updatedVideos,
      };
    } else {
      data = {
        user_id: currentUser.id,
        videos: updatedVideos,
        type: "story",
      };
    }
    setLoader(book?.id);
    setFetchFavorites(data);
    // await dispatch(handleCreateFavouriteVideo(data));
    setLoader("");
  };

  const searchitems = (txt) => {
    setSearchText(txt);
    // if (typeof txt == "string" && txt.length > 0) {
    //   txt = txt.toLowerCase();
    //   const fStory = filter(storyBookGroups, (st) => {
    //     if (st.title && st.title.length > 0) {
    //       if (st.title.toLowerCase().includes(txt)) {
    //         return true;
    //       }
    //     } else if (st.categorias && st.categorias.length > 0) {
    //       if (st.categorias.toLowerCase().includes(txt)) {
    //         return true;
    //       }
    //     }
    //     return false;
    //   });
    //   setFilterdStory(fStory);
    // } else {
    //   setFilterdStory(storyBookGroups);
    // }
  };

  const getFilteredArray = (result) => {
    const matchThemes = (dataThemes, filterThemes) => {
      if (!dataThemes || dataThemes.length === 0 || filterThemes.length === 0) return false;
      const themes = dataThemes.toLowerCase().split(',').map(theme => theme.trim());
      return filterThemes.some(filter => 
        themes.some(theme => theme.includes(filter.toLowerCase()))
      );
    };
  
    let filteredResults = new Set();

    if(serachText !== "" && serachText?.length > 0){
      result.filter(data => {
        if((data?.categorias?.toLowerCase().includes(serachText?.toLowerCase()))
        || (data?.tags?.toLowerCase().includes(serachText?.toLowerCase()))
        ){
          return true
        }
        else if((data?.resource?.toLowerCase().includes(serachText?.toLowerCase()))
          || (data?.displayName?.toLowerCase().includes(serachText?.toLowerCase()))
          ){
            return true
          }
          return false
      })
      .forEach(item => filteredResults.add(item));
    }

    if(selectedCategories !== "" && selectedCategories?.toLowerCase() !== "all"){
      result.filter(data => data.categorias?.toLowerCase().includes(selectedCategories?.toLowerCase()))
      .forEach(item => filteredResults.add(item));
    }
  
    if (anecotasThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.anécdotas_themes, anecotasThemeFilter))
            .forEach(item => filteredResults.add(item));
    }
  
    if (ibThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ib_themes, ibThemeFilter))
            .forEach(item => filteredResults.add(item));
    }
  
    if (apThemeFilter.length > 0) {
      result.filter(data => matchThemes(data.ap_themes, apThemeFilter))
            .forEach(item => filteredResults.add(item));
    }
  
    // If no filters are active, return all results
    if (filteredResults.size === 0 && 
        anecotasThemeFilter?.length === 0 && 
        ibThemeFilter?.length === 0 && 
        apThemeFilter?.length === 0 &&
        (selectedCategories.length === 0 || selectedCategories.toLowerCase() === "all") &&
        serachText?.length === 0
      ) {
      return result;
    }
  
    return Array.from(filteredResults);
  }
  
  const filteredArray = useMemo(() => {
    const dataToFilter = isSelectedFav === "favourites" ? fetchFavoritesList?.videos : storyBookGroups;
    return getFilteredArray(dataToFilter);
  }, [selectedCategories, ibThemeFilter, storyBookGroups, anecotasThemeFilter, apThemeFilter, isSelectedFav, serachText, fetchFavoritesList]);
  
  const clearAllFilter = () => {
    setIbThemeFilter([]);
    setAnecotasThemeFilter([]);
    setApThemeFilter([]);
    setSelectedCategories("All")
  }


  const filterByCategory = (category) => {
    setSelectedCategories(category);
    // if (category == "" || category == "All") {
    //   setFilterdStory(storyBookGroups);
    // } else {
    //   if (typeof category === "string") {
    //     const fStory = filter(storyBookGroups, (st) => {
    //       if (st.categorias && st.categorias.length > 0) {
    //         category = category.toLowerCase();
    //         if (st.categorias.toLowerCase().includes(category)) {
    //           return true;
    //         }
    //       }
    //       return false;
    //     });
    //     setFilterdStory(fStory);
    //   }
    // }
    handleDropdownClick("");
  };


  const handleDropdownClick = (type) => {
    setIsDropdownOpen((prevState) => ({
      type,
      open: prevState.type === type ? !prevState.open : true,
    }));
  };


  useEffect(() => {
    document.body.style.backgroundColor = '#0B1C2B';
    document.body.classList.add("overflow-x-hidden");

    return () => {
      document.body.classList.remove("overflow-x-hidden");
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          <strong>ALERT:</strong> {alert.message}
        </Alert>
      </Snackbar>
      <Row
        style={{ background: "#0B1C2B", padding: "0 24px" }}
        className="storyManagePadding"
      >
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="allStoryDrpDwn">
          <CategoryMenu
        className={`story-search-mobile topStorySearch lg-h-36px`}
        style={{ width: "280px", position: "relative", paddingLeft: "19px", marginTop: "20px" }}
      >
        <SearchInput
          className="search-input w-full"
          type={"text"}
          value={serachText}
          placeholder="SEARCH"
          // onFocus={(e) => (e.target.placeholder = "")}
          // onBlur={(e) => (e.target.placeholder = "SEARCH")}
          onChange={(e) => {
            searchitems(e.target.value);
          }}
        />

        <div
          className="dropdownStoryArrowContainer"
          style={{ position: "absolute", top: "0px", right: "0px" }}
        >
          <MenuIconImg src={StorySearch} />
        </div>
      </CategoryMenu>
           

            <Row className="newStorySection all-dropdown-mobile-view gap-37px story-search-container lg-gap-10px  lg-noWrap lg-gap-26px ">
              
            <div className="md-w-full-2" style={{position: "relative"}}>
              {/* start dropdown */}
                <DropdownMenu type={"category"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")}  themeFilter={selectedCategories} dropDownHandler={dropDownHandler} open={ isDropdownOpen.type === "category" &&
                  isDropdownOpen?.open} data={CATEGORIAS} />
              </div>
             
                {/* mob ib dropdown */}
                {isMobile <= 768 && (
                    <div className="md-w-full-2  md-d-block d-none" style={{position: "relative"}}> 
                      <DropdownMenu type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                     closeDropDown={() => handleDropdownClick("")}  themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={ isDropdownOpen.type === "ibtheme" &&
                          isDropdownOpen?.open} data={IBTHEME} />
                    </div>
                )}

              <div className="md-w-full-2"  style={{position: "relative"}}>
              <DropdownMenu type={"ANéCDOTAS THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")} themeFilter={anecotasThemeFilter} dropDownHandler={dropDownHandler} open={isDropdownOpen.type === "ANéCDOTAS THEMES" &&
                  isDropdownOpen?.open} data={AnecdotasThemes} isResizingWindow={isMobile}
                  typeOf={"story"}
                      />
              </div>
                <div className="md-w-full-2" style={{position: "relative"}}>
                <DropdownMenu type={"AP THEMES"} handleDropdownClick={handleDropdownClick} closeDropDown={() => handleDropdownClick("")}  themeFilter={apThemeFilter} dropDownHandler={dropDownHandler} open={ isDropdownOpen.type === "AP THEMES" &&
                    isDropdownOpen?.open} data={APTHEME} />
                </div>

              {/* laptop ib dropdown */}
              {isMobile >=768 && (
                <div className="md-w-full-2 md-d-none d-block" style={{position: "relative"}}>
              <DropdownMenu type={"ibtheme"} handleDropdownClick={handleDropdownClick}
                     closeDropDown={() => handleDropdownClick("")}  themeFilter={ibThemeFilter} dropDownHandler={dropDownHandler} open={ isDropdownOpen.type === "ibtheme" &&
                          isDropdownOpen?.open} data={IBTHEME} />
              </div>
              )}

<CategoryMenu
        className={`story-search-laptop topStorySearch lg-h-36px`}
        style={{ width: "280px", position: "relative", paddingLeft: "19px" }}
      >
        <SearchInput
          className="search-input w-full"
          type={"text"}
          value={serachText}
          placeholder="SEARCH"
          // onFocus={(e) => (e.target.placeholder = "")}
          // onBlur={(e) => (e.target.placeholder = "SEARCH")}
          onChange={(e) => {
            searchitems(e.target.value);
          }}
        />

        <div
          className="dropdownStoryArrowContainer"
          style={{ position: "absolute", top: "0px", right: "0px" }}
        >
          <MenuIconImg src={StorySearch} />
        </div>
      </CategoryMenu>
            </Row>
          </div>
          {/* <Row className="unitBtnsMain" gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={24}>
                            <div className="unitBtns">
                                <a><input type="button" value="select a unit" /><img src={threeDotImg} /></a>
                                <a><input type="button" value="Search" /><img src={searchIconImage} /></a>
                            </div>
                        </Col>
                    </Row> */}

          <Row className="ImgBtnMain" gutter={[32, 0]}>
            {isSelectedFav === "favourites" ? (
              <div
                // style={{height:"23vh"}}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsSelectedFav("");
                  clearAllFilter();
                  fetchAllStory();
                }}
                className="newStoryFavoritos storyFavoritosHover storyFavoritosPointer"
              >
                {/* <div className="back-text md-ml-4px">Back</div> */}
                <img
                  width={33}
                  src={BackIcon}
                  alt="heart"
                  // className="mt-15px back-image"
                  className="storyFavoritosImgMob"
                />
                <div className="storyContainerText">
                  Back
                </div>
                <img
                  width={33}
                  src={BackIcon}
                  alt="heart"
                  // className="mt-15px back-image"
                  className="storyFavoritosImg storyFavoritosFstImg"
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  setIsSelectedFav("favourites");
                  clearAllFilter();
                  // setFilterdStory(allFavorites?.videos);
                }}
                className="storyFavoritos storyFavoritosHover storyFavoritosPointer"
              >
                <div className="storyContainerText">
                  MIS <br /> FAVORITOS
                </div>
                <img
                  src={HeartIcon}
                  alt="heart"
                  className="storyFavoritosImg"
                />
              </div>
            )}
            <FlatList
              list={filteredArray}
              renderItem={(k, i) => (
                <RenderStory
                  key={i}
                  book={k}
                  loader={loader}
                  allFavorites={fetchFavoritesList?.videos}
                  onClick={onClick}
                  setAlert={setAlert}
                  handleMyFavourite={handleMyFavourite}
                />
              )}
              renderWhenEmpty={() => (
                // <div className="placeholder-image-container">
                //   <img
                //     className="md-w-300px mdm-w-500px"
                //     src={getFireBaseUrl("novideos.png")}
                //     alt="placeholder-image"
                //   />
                // </div>
                <div className="placeholder-image-container">
                  <EmptyImagePlaceholder noData="No hay historias" />
                </div>
              )}
            />
          </Row>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={4}></Col> */}
      </Row>
    </>
  );
}
