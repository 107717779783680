//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

const ideasInit = []

export const ideas = (state = ideasInit, action) => {
    switch (action.type) {
        case ActionTypes.idea.setIdea:
            return action.payload;
        case ActionTypes.idea.updateIdea:
            return action.payload;
        case ActionTypes.idea.clearIdea:
            return state;
        default:
            return state;
    }
};




export const ideaModal = (state = false, action) => {
    switch (action.type) {
        case ActionTypes.idea.setIdeaModal:
            return action.payload;
        default:
            return state;
    }
}