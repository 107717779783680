
//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
  Button as ButtonUpdate,
  FormControl,
  FormHelperText,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextareaAutosize,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
//  -- Components ---------------------
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Modal, Row } from "antd";
import Loading from "components/Loading";
//  -- Constants ----------------------
import { cloneDeep, filter, find, get, isEmpty } from "lodash";
//  -- Thunks -------------------------
import { Delete, Edit } from "@material-ui/icons";
import { DeleteModal } from "components/Dialog";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStandards } from "store/story/selectors";
import { handleDeleteStanders, handleUpdateStanders } from "store/story/thunks";
const BORDERCOLOR = [
  "rgb(200,109,152)",
  "rgb(146,158,227)",
  "rgb(80,233,200)",
  "rgb(238,206,151)",
];
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------

const StableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  //   @media (max-width: 992px) {
  //     padding:24px 0px;
  //   }
`;
const StableWrapperMain = styled.div``;

const FilterContainer = styled.span`
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
`;

const TextAreaAuto = styled(TextareaAutosize)`
  height: 200px;
  max-height: 210px;
  width: 100%;
  border-radius: 2px;
  padding: 5px;
  overflow: hidden;
  border-color: rgb(0 0 0 / 32%);
  ::focus-visible {
    outline: #03989e 1px !importent;
    border: 5px solid red !important;
  }
  ::focus {
    border-color: #03989e !importent;
  }
`;
//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  District creation form
//
//  -- Description
//  ...
//
//  -- Props
//  handleSubmit {func} = invoked on submit
//
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const StanderForm = ({ standers, onChangeStanders }) => {
  const [standerData, setStanderData] = useState(null);
  const [error, setError] = useState(null);
  const allStanderds = useStandards();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loader,setLoader] = useState(false);
  useEffect(() => {
    if (standers) {
      setStanderData(standers);
    }
  }, [standers]);

  const onUpdate = async () => {
    if (!standerData.abbr) {
      setError("Abbreviation is required");
      return;
    } else if (!standerData.description) {
      setError("Description is required");
      return;
    } else if (!standerData.grade_level) {
      setError("Grade level is required");
      return;
    }
    const hasAllReady = find(
      allStanderds,
      (std) =>
        std.id != standerData.id &&
        standerData.abbr.toLowerCase() == std.abbr.toLowerCase()
    );
    if (hasAllReady) {
      setError("Abbreviation is already taken");
      setTimeout(() => {
        setError(null);
      }, 5000);
      return;
    }
    setError(null);
    setLoader(true);
    await dispatch(handleUpdateStanders(standerData));
    setLoader(false);
    onChangeStanders();
  };
  const onChangeData = (key,value) => {
      const res = cloneDeep(standerData);
      res[key] = value;
      setStanderData(res);
  }
  return (
    <div>
      <Row gutter={[15, 15]}>
        <Col xs={24} lg={24}>
          {/* <Card> */}
          <Row gutter={[24, 24]} style={{ marginTop: 20 }}>
            <Col xs={24} lg={24}>
              <TextField
                id="title"
                name="title"
                label="Abbreviation"
                InputLabelProps={{ shrink: true }}
                type="text"
                variant="outlined"
                onChange={(e) => {
                    onChangeData("abbr", e.target.value);
                }}
                value={get(standerData, "abbr", "")}
              />
            </Col>
            <Col xs={24} lg={24} className="floatingLabelBG">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="type">Grade Level</InputLabel>
                <TextField
                  id="grade_level"
                  name="grade_level"
                  label="Grade Level"
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  variant="outlined"
                  onChange={(e) => {
                    onChangeData("grade_level", e.target.value);
                  }}
                  value={get(standerData, "grade_level", "")}
                />
              </FormControl>
            </Col>
            <Col xs={24} lg={24}>
              <FormControl variant="outlined">
                <InputLabel
                  id="textTip"
                  style={{
                    backgroundColor: "#fff",
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  Discrptions
                </InputLabel>
                <TextAreaAuto
                  id="hypelink"
                  name="hypelink"
                  label="Discrptions"
                  InputLabelProps={{ shrink: true, required: true }}
                  type="text"
                  variant="outlined"
                  onChange={(e) => {
                    onChangeData("description", e.target.value);
                  }}
                  value={get(standerData, "description", "")}
                  style={{ height: 80, borderRadius: 4 , overflow:"scroll" }}
                />
              </FormControl>
            </Col>
          </Row>
        </Col>
      </Row>
      {error? (
        <FormHelperText error>
         {error}
        </FormHelperText>
      ) : null}
      <Row
        gutter={[24, 24]}
        style={{ marginTop: 16, marginRight: 0, marginLeft: 0 }}
      >
        <ButtonUpdate
          onClick={onUpdate}
          color="primary"
          variant="contained"
          disabled={!standerData || loader}
          style={{ minWidth: 200 }}
        >
        {loader?<Loading white size={14}/>:"Update"}  
        </ButtonUpdate>
      </Row>
    </div>
  );
};

const StandardTable = ({ userloading }) => {
  const [filteredStanders, setFilteredStanders] = useState([]);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = React.useState(0);
  const [editStanderModal, setEditStanderModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState("");
  const history = useHistory();
  const standerds = useStandards();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [ loading , setLoader] = useState(false);

  useEffect(() => {
    setFilteredStanders(standerds);
  }, [standerds]);

  const onSearch = (event) => {
    if (event.target.value && event.target.value.length >=1) {
      var search = event.target.value;

      const filtered = filter(standerds, (x) => {
        return  `${x.abbr}`.toLowerCase().includes(search.toLowerCase());;
      });
      setFilteredStanders(filtered);
    } else {
    setFilteredStanders(standerds);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const addTeachers = (value) => {
    const editData = {
      userData: data,
      isEdit: true,
    };
    const addData = {
      userData: null,
      isEdit: false,
    };
    if (value == "edit") {
      history.push({ pathname: "/addStandard", state: editData });
    } else {
      history.push({ pathname: "/addStandard", state: addData });
    }
  };
  const onDeleteItem = async () => {
    if (!selectedItem) return;
    setLoader(true);
    await dispatch(handleDeleteStanders(selectedItem.id));
    setLoader(false);
    setDeleteToggle(false);
    setSelectedItem(null);
  };
  return (
    <>
      <StableWrapperMain>
        <Row style={{ marginBottom: 15 }}>
          <Col>
            <Button
              onClick={() => addTeachers()}
              style={{ minHeight: 38 }}
              type="primary"
            >
              <PlusOutlined /> Add Standard
            </Button>
          </Col>
        </Row>
        <FilterContainer className="FilterHeading">
          <Card type="inner" title="Filters">
            <Row>
              <Col xs={24} lg={24} md={24} className="ColCardPadding">
                <Card style={{ width: "100%" }}>
                  <Input
                    placeholder="Search by name"
                    bordered={false}
                    style={{ height: "32px" }}
                    onChange={onSearch}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </FilterContainer>
        <StableWrapper>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#fff",
              marginTop: 20,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: "left", fontSize: 16, fontWeight: "600" }}
                >
                  Abbreviation
                </TableCell>
                <TableCell
                  style={{ textAlign: "left", fontSize: 16, fontWeight: "600" }}
                >
                  Grade Level
                </TableCell>
                <TableCell
                  style={{ textAlign: "left", fontSize: 16, fontWeight: "600" }}
                >
                  Discrptions
                </TableCell>
                <TableCell
                  style={{ textAlign: "left", fontSize: 16, fontWeight: "600" }}
                >
                  Edit
                </TableCell>
                <TableCell
                  style={{ textAlign: "left", fontSize: 16, fontWeight: "600" }}
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(filteredStanders) &&
                (userloading ? (
                  <Loading size={30} />
                ) : (
                  <TableRow key={"0"} style={{ border: 0 }}>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      No results
                    </TableCell>
                  </TableRow>
                ))}
              {!isEmpty(filteredStanders) &&
                filteredStanders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        key={row.id}
                        style={{
                          "&:lastChild td, &:lastChild th": { border: 0 },
                        }}
                        onClick={() => setData(row)}
                      >
                        <TableCell
                          style={{ textAlign: "left", cursor: "pointer" }}
                          onClick={() => {}}
                        >
                          {" "}
                          {get(row, "abbr", "-")}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            color: BORDERCOLOR[0],
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        >
                          {get(row, "grade_level", "-")}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            color: BORDERCOLOR[0],
                            cursor: "pointer",
                            maxWidth: 380,
                          }}
                          onClick={() => {}}
                        >
                          {get(row, "description", "-")}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            color: "black",
                            cursor: "pointer",
                            maxWidth: 380,
                          }}
                          onClick={() => {
                            setEditStanderModal(!editStanderModal);
                            setSelectedItem(row);
                          }}
                        >
                          <Edit />
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "left",
                            color: "red",
                            cursor: "pointer",
                            maxWidth: 380,
                          }}
                          onClick={() => {
                            setDeleteToggle(true);
                            setSelectedItem(row);
                          }}
                        >
                          <Delete />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
            <TableFooter>
              <TableRow>
                {!isEmpty(filteredStanders) && (
                  <TablePagination
                    count={filteredStanders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    backIconButtonProps={{
                      color: "secondary",
                    }}
                    nextIconButtonProps={{ color: "secondary" }}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </StableWrapper>
      </StableWrapperMain>
      <Modal
        footer={null}
        title={"Edit"}
        centered
        open={editStanderModal}
        onOk={() => setEditStanderModal(false)}
        onCancel={() => setEditStanderModal(false)}
        width={"40%"}
        className="FilterHeading"
      >
        <StanderForm
          standers={selectedItem}
          onChangeStanders={(data) => {
            setSelectedItem(null);
            setEditStanderModal(false);
          }}
        />
      </Modal>
      <DeleteModal
        deleteToggle={deleteToggle}
        setDeleteToggle={setDeleteToggle}
        onDelete={onDeleteItem}
        message={`Are you sure to delete - This Standard`}
        loading={loading}
      />
    </>
  );
};

//  -- Prop types ---------------------
StandardTable.propTypes = {};

//  -- Default props ------------------
StandardTable.defaultProps = {};

export default StandardTable;
