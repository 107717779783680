//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';



//  -- List books ----------------
export const invokeWheelModal = () => ({ type: ActionTypes.wheel.invokeWheelModal });
export const revokeWheelModal = () => ({ type: ActionTypes.wheel.revokeWheelModal });


export const invokeNoiseModal = () => ({ type: ActionTypes.wheel.invokeNoiseMeter });
export const revokeNoiseModal = () => ({ type: ActionTypes.wheel.revokeNoiseMeter });

//  -- List All books ----------------


//  -- CheckList Modal -------------------

export const invokeCheckListView = (returnedPayload) => ({ type: ActionTypes.wheel.invokeCheckList, payload: returnedPayload });
export const revokeCheckListModal = (returnedPayload) => ({ type: ActionTypes.wheel.revokeCheckList, payload: returnedPayload });


//  -- CheckList Modal -------------------

export const invokeTrafficView = (returnedPayload) => ({ type: ActionTypes.wheel.invokeTrafficView, payload: returnedPayload });
export const revokeTrafficModal = (returnedPayload) => ({ type: ActionTypes.wheel.revokeTrafficModal, payload: returnedPayload });
export const revokeAllWidget = (returnedPayload) => ({ type: ActionTypes.wheel.revokeAllWidget, payload: returnedPayload });