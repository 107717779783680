//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import { shallowEqual, useSelector } from 'react-redux';

//  ----------------------------------------------------------------------------
//  Selectors
//  ----------------------------------------------------------------------------

//  -- Examples data ------------------
const selector = (state) => state.story;

//  -- Examples array selector --------

export const useStory = () => {
   const selectedData = useSelector(selector, shallowEqual)
   return selectedData
}
const standardSelector = state => state.standards
export const useStandards = () => useSelector(standardSelector, shallowEqual)

const overallLiteracySelector = state => state.literacy_purpose
export const useOverallLiteracyPurpose = () => useSelector(overallLiteracySelector, shallowEqual)
