//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem, Select, TextField
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//  -- Components ---------------------
import { Col, Row } from 'antd';
import { API } from 'aws-amplify';
import Loading from 'components/Loading';
import _, { filter, map, get } from "lodash";
import { lighten } from 'polished';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleUpdateClass } from 'store/classes/thunks';
import { handleUpdateOrganization, getOrganizationById } from 'store/organizations/thunks';
import { handleListClassUsers, handleListUserByClassForTeacher } from 'store/userClasses/thunks';
//  -- Thunks -------------------------
import { handleCheckUsername, handleRegisterStudent, handleUpdateUser } from 'store/users/thunks';
import styled from 'styled-components';
//  -- Constants ----------------------
import COLOR from 'utils/constants/color';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SelectableBook from 'components/SelectableBook';
import { useCurrentOrgPermission } from 'store/organizations/selectors';
import { useCurrentUser } from 'store/users/selectors';
//  ----------------------------------------------------------------------------
//  Styles
//  ----------------------------------------------------------------------------
const Description = styled.span`
    color: ${COLOR.black300};
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
`;

const Error = styled.div`
    background-color: ${lighten(0.4, 'red')};
    border: 1px solid red;
    border-radius: 3px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    width: 100%;
`;

const Header = styled(Description)`
    color: ${COLOR.black100};
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
`;

const Success = styled.div`
    background-color: ${lighten(0.4, COLOR.green)};
    border: 1px solid ${COLOR.green};
    border-radius: 3px;
    color: ${COLOR.green};
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    width: 100%;
`;

const Wrapper = styled.form`
    margin: 0px auto;
    max-width: 480px;
    width: 100%;
    overflow-y: auto;
`;
const BookErrorSec = styled.div`
    padding-left:16px;  
    `
const BookSection = styled.div`
    margin-left: 5%;
    margin-right: 5%;
    margin-top: -30px;
    width: 100%;
`;
const Legend = styled.div`
    color: #383a3e;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-left: 10px;
    margin-bottom: 16px;
`;

//  ----------------------------------------------------------------------------
//  Component
//  ----------------------------------------------------------------------------
//
//  Student form
//
//  -- Description
//  Student creation/editing
//
//  -- Props
//  ...
//
//  -- State
//  ...
//
//  -- Methods
//  ...
//
//  -- Usage
//  ...
//


const StudentForm = ({
    data,
    handleChange,
    currentClass,
    error,
    success,
    onClosed,
    newListBooks,
    setFormatPasswordError,
    formatPasswordError,
    isReviewAccount
}) => {

    // Array Books  
    const [openReviewDialog, setReviewDialog] = React.useState(false);
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }));
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const currentUser = useCurrentUser();
    const [booksError, setBooksError] = useState(false);
    const [createUserError, setCreateUserError] = useState(null)
    const [noLicensesError, setNoLicensesError] = useState(false);
    const [userError, setUserError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [gradeError, setGradeError] = useState(false);
    const [dialog, setDialog] = useState({ open: false, message: "" })
    const isUpdate = data.id != undefined || data.editing;
    const [userNameLimit, setUserNameLimit] = useState(false)
    const checkUsername = async (value) => {
        const nameCheck = await dispatch(handleCheckUsername(value?.trim()));
        if (nameCheck) {
            setUsernameError(true)
        } else if(value?.length < 5 || value?.length > 45) {
            setUserNameLimit(true)
        } else {
            setUsernameError(false)
            setUserNameLimit(false)
        }
    };
    const usernameValidation = async (id, value) => {
        var validation = /^[0-9a-zA-Z]+$/;
        if (value === "" || validation.test(value)) {
            handleChange(id, value);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setCreateUserError(null)

       if(data.username?.length < 5 || data.username?.length > 45) {
            return;
        }

        if (!currentClass.orgId) {
            setDialog({ open: true, message: "Please create class before creating student" })
            return;
        }
        if (isReviewAccount) {
            setReviewDialog(true)
            return;
        }
        let allForm = true;
        allForm = validInput('username');
        allForm = validInput('firstName');
        allForm = validInput('lastName');
        allForm = validInput('grade');
        if (allForm) {
            let goodPassword = true;
            if (!data?.editing) {
                goodPassword = verifyPassword(data.password);
            }
            if (goodPassword) {
                // var booksUser = [];
                // if(selectedBook.length > 0){
                setBooksError(false);
                setCreateUserError(null)
                // booksUser = {
                //     "books": createArrayBooks(selectedBook),
                //     "userId": "",
                //     "validityDate": "2022-01-01",
                //     "orgId": currentClass.orgId
                //     }
                let infoUser = {
                    ...data,
                    orgId: currentClass.orgId,
                    role: 'student'
                }
                setLoading(true);
                if (data?.editing) {
                    // const org = await getOrganizationById(currentClass.orgId);
                    // let licensesBooks =filter( booksUser.books,x => x.count == true).length;
                    // let currentLicensesBooks = filter(data.books,x => x.count == true).length;
                    // const newOrgCount = Number(org.maxCount) + Number(licensesBooks) - Number(currentLicensesBooks);                     
                    // const orgData = {
                    //     studentCount: org.studentCount.toString(),
                    //     maxCount: newOrgCount.toString()
                    // };
                    // if (newOrgCount < parseInt(org.licenses)) {
                    //     await dispatch(handleUpdateUser(data.id, {
                    //         ...data
                    //     },{
                    //     ...booksUser},
                    //     data.userBookId));
                    //     await dispatch(handleUpdateOrganization(org.id, { ...org, ...orgData }))
                    //     await dispatch(handleListClassUsers(currentClass.id));
                    //     onClosed();
                    // }else{
                    //     setNoLicensesError(true);
                    // }
                    await dispatch(handleUpdateUser(data.id, { ...data }));
                    await dispatch(handleListClassUsers(currentClass.id));
                    await dispatch(handleListUserByClassForTeacher(get(currentUser, "id")));
                    onClosed();
                } else {
                    const org = await getOrganizationById(currentClass.orgId);
                    let licensesBooks = filter(get(currentClass, "books"), x => x.count == true).length;
                    const newOrgCount = Number(org.maxCount) + Number(licensesBooks);
                    let studentLicenses = Number(org.studentCount) + 1;
                    const orgData = {
                        studentCount: studentLicenses.toString(),
                        maxCount: newOrgCount.toString()
                    };

                    if (newOrgCount < Number(org.licenses)) {
                        const newUser = await dispatch(handleRegisterStudent(infoUser, null, [currentClass]));
                        if (!newUser.error) {
                            await dispatch(handleUpdateOrganization(org.id, { ...org, ...orgData }))
                            await dispatch(handleListClassUsers(currentClass.id));
                            await dispatch(handleListUserByClassForTeacher(get(currentUser, "id")));
                            setLoading(false);
                            onClosed();
                        } else {
                            if (newUser.errorDesctiption && newUser.errorDesctiption.code == "UsernameExistsException") {
                                setCreateUserError(" Please, create the student again with a new username. ")
                            } else {
                                setCreateUserError(newUser.message || "Something went wrong!")
                            }
                        }
                    } else {
                        console.log('no licenses')
                        setNoLicensesError(true);
                        setLoading(false);
                        setCreateUserError(null)
                    }
                }
                setLoading(false);
                // }else{
                //     setBooksError(true);
                //     setCreateUserError(null)
                // } 
            }
        }
    };

    function validInput(input) {
        switch (input) {
            case 'username':
                if (data.username == undefined || data.username == null || data.username == '') {
                    setUserError(true);
                    return false;
                } else {
                    setUserError(false);
                    return true;
                }
            case 'firstName':
                if (data.firstName == undefined || data.firstName == null || data.firstName == '') {
                    setFirstNameError(true);
                    return false;
                } else {
                    setFirstNameError(false);
                    return true;
                }
            case 'lastName':
                if (data.lastName == undefined || data.lastName == null || data.lastName == '') {
                    setLastNameError(true);
                    return false;
                } else {
                    setLastNameError(false);
                    return true;
                }
            case 'grade':
                if (data.grade == undefined || data.grade == null || data.grade == '') {
                    setGradeError(true);
                    return false;
                } else {
                    setGradeError(false);
                    return true;
                }
            default:
                return false;
        }
    }




    function verifyPassword(password) {
        var paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (password.match(paswd)) {
            setFormatPasswordError(false);
            return true;
        } else {
            setFormatPasswordError(true);
            return false;
        }
    }
    function closeDialoge() {
        setDialog({ open: false, message: "" })
    }
    return (
        <Wrapper onSubmit={onSubmit}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openReviewDialog}
                autoHideDuration={3000}
                onClose={() => setReviewDialog(false)}>
                <Alert onClose={() => setReviewDialog(false)} severity="error">
                    <strong>ALERT:</strong> Only for Review!
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={dialog.open}
                autoHideDuration={3000}
                onClose={closeDialoge}>
                <Alert onClose={closeDialoge} severity="error">
                    <strong>ALERT:</strong>{dialog.message}
                </Alert>
            </Snackbar>

            <Header>{data?.editing ? 'Update Information' : 'Create a Student'}</Header>
            <Description>When adding a student, please remember that for the password, you HAVE to use at least one number, one symbol, as well as one upper and one lower case letter. The total number of characters should be between 8 and 15. On the other hand, to create the username, you MUST use 8 – 15 characters that include at least one uppercase and one lowercase letter.</Description>
            {error && (
                <Row gutter={[0, 24]} style={{ width: '100%' }}>
                    <Col span={24}>
                        <Error>{error}</Error>
                    </Col>
                </Row>
            )}
            {success && (
                <Row gutter={[0, 24]} style={{ width: '100%' }}>
                    <Col span={24}>
                        <Success>{success}</Success>
                    </Col>
                </Row>
            )}
            {!data.editing && (
                <Row gutter={[24, 24]} style={{ width: '100%' }}>
                    <Col span={24} className="floatingLabelBG">
                        <TextField
                            error={usernameError || userNameLimit}
                            id="username"
                            onBlur={e => checkUsername(e.target.value)}
                            onChange={e => usernameValidation(e.target.id, e.target.value)}
                            onFocus={() => setUsernameError(false)}
                            label="Unique Username"
                            variant="outlined"
                            value={data?.username}
                            helperText={usernameError ? 'Username not available' : userNameLimit ? "Username must be between 5 to 45 character" : ""}
                        />
                    </Col>
                </Row>
            )}
            <Row gutter={[24, 24]} style={{ width: '100%' }} className="stdntNameSpc">
                <>
                    {!data.editing && (
                        <>
                            <Col span={12} className="floatingLabelBG">
                                <TextField
                                    id="password"
                                    error={formatPasswordError}
                                    onChange={e => handleChange(e.target.id, e.target.value)}
                                    label="Password"
                                    variant="outlined"
                                    value={data?.password}
                                    onBlur={e => verifyPassword(e.target.value)}
                                    helperText={formatPasswordError && 'Please use 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.'}
                                />
                            </Col>
                        </>
                    )}
                    <Col span={12}>
                        <TextField
                            id="firstName"
                            onChange={e => handleChange(e.target.id, e.target.value)}
                            label="First Name"
                            variant="outlined"
                            value={data?.firstName ?? ' '}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            id="lastName"
                            onChange={e => handleChange(e.target.id, e.target.value)}
                            label="Last Name"
                            variant="outlined"
                            value={data?.lastName ?? ' '}
                        />
                    </Col>
                    <Col span={12}>
                        <TextField
                            id="grade"
                            onChange={e => handleChange(e.target.id, e.target.value)}
                            label="Grade"
                            variant="outlined"
                            value={data?.grade ?? ' '}
                        />
                    </Col>
                </>
            </Row>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
                <Col span={24}>
                    <Button
                        disabled={(data?.editing ? !(data.firstName && data.lastName && data.grade && data.username && !usernameError) : !(data.firstName && data.lastName && data.username && data.password && data.grade && !usernameError))}
                        variant="contained"
                        color="primary"
                        style={{ width: '100%' }}
                        type='submit'
                    >
                        {loading ? <Loading size={20} white /> : (data?.editing ? 'Update' : 'Create')}
                    </Button>
                </Col>
            </Row>
        </Wrapper>
    );
};

//  -- Prop types ---------------------
StudentForm.propTypes = {};

//  -- Default props ------------------
StudentForm.defaultProps = {};

export default StudentForm;