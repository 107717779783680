
import { Col, Row } from 'antd';
import { get } from "lodash";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { handleListClassSubmissions } from 'store/submissions/thunks';
//  -- Thunks -------------------------
import { handleListActivities, handleListCustomAssignment } from 'store/activities/thunks';
import { handleListClassActivities } from 'store/classActivities/thunks';
import { handleListClassQuizzes } from 'store/classQuizzes/thunks';
import { handleListQuizzes } from 'store/quizzes/thunks';
import { handleListClassUsers } from 'store/userClasses/thunks';
import { useApExams } from 'store/apexams/selectors';
import Loading from 'components/Loading';
import ExamTablesAp from "features/ExamGradeTableAp";

export const ApGradeBook = ({
    classActivities,
    classQuizzes,
    currentClass,
    classUsers,
    submissions,
    currentUser,
    activities,
    quizzes,
}) => {
 

    const dispatch = useDispatch()
    const [dataLoading, setDataloading] = useState(false)
    const exams = useApExams()
    const sortExam = () => {
        if (exams) {
            return exams.sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                if (a.order < b.order) {
                    return -1
                }
                return 0
            })
        } else {
            return [];
        }
    }
 
    const listCurrentClassInfo = async () => {
        if (get(currentClass, "id")) {
            dispatch(handleListClassUsers(get(currentClass, "id")));
            dispatch(handleListClassActivities(get(currentClass, "id")));
            dispatch(handleListClassQuizzes(get(currentClass, "id")));
            dispatch(handleListClassSubmissions(get(currentClass, "id")))
        }
    }
    const listAllAssignments = async () => {
        if (!Array.isArray(activities) || activities.length == 0) {
            await dispatch(handleListActivities());
        }

        if (!Array.isArray(quizzes) || quizzes.length == 0) {
            await dispatch(handleListQuizzes());
        }
        await dispatch(handleListCustomAssignment(get(currentUser, "role"), get(currentUser, "id")))
    }

    const fetchApis = async () => {
        setDataloading(true)
        await Promise.allSettled([
            listCurrentClassInfo(),
            listAllAssignments(),
        ])
        setDataloading(false)
    }
    useEffect(() => {
        fetchApis()
    }, [])

  return (<>
      <div>
        <div className="container">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className="dataColloect">
                <Row className="storyDataWrap">
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div style={{ marginTop: 24 }}>
                      {dataLoading ? (
                        <Loading />
                      ) : (
                        <div>
                          <ExamTablesAp
                            activities={activities}
                            dataLoading={dataLoading}
                            quizzes={quizzes}
                            users={classUsers}
                            submissions={submissions}
                            classActivities={classActivities}
                            classQuizzes={classQuizzes}
                            exams={sortExam()}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {/* End of Data Table  */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>);
};
const mapStateToProps = state => ({
    classActivities: state.classActivities,
    classQuizzes: state.classQuizzes,
    currentUser: state.currentUser,
    classUsers: state.classUsers,
    userBooksList: state.userBooksList,
    currentClass: state.currentClass,
    currentOrg: state.currentOrg,
    submissions: state.submissions,
    booksAllList: state.booksAllList,
    userPreference: state.userPreference,
    selectedUser: state.selectedUser,
    activities: state.activities,
    quizzes: state.quizzes,
    schoologyAuth: state.schoologyAuth
});

export default connect(mapStateToProps, null)(ApGradeBook); 