import {COTATION} from "utils/config/reference"
import { random , get , isArray } from "lodash"
import moment from "moment"
import "moment-timezone"
import { useEffect, useLayoutEffect, useState } from "react"
export const getRandomCotation=()=>{
    const rendNum=random(0,COTATION.length-1);
    return COTATION[rendNum];
}
const getKey = (sem, year) => (`${sem == 1 ? "semester 1" : "semester 2"} - ${year}`)
export const semeterDateByYear=(year)=>{
    const sem1StartDate = moment().set({'year':year,'month':6,'date':1});
    const sem1EndDate = moment().set({'year':year,'month':11,'date':31});
    const sem2StartDate = moment().set({'year':year,'month':0,'date':1});
    const sem2EndDate = moment().set({'year':year,'month':5,'date':30});
    return ({
        sem1StartDate,
        sem1EndDate,
        sem2StartDate,
        sem2EndDate
    })

}
export const getCurrentSameDateRange = () => {
    const current_sem = getCurrenSem();
    const year = moment().format("YYYY")
    if (current_sem.includes("semester 1")) {
        return ({
            from_date: moment().set({ 'year': year, 'month': 6, 'date': 1 }).toISOString(),
            end_date: moment().set({ 'year': year, 'month': 11, 'date': 31 }).toISOString()
        })
    } else {
        return ({
            from_date: moment().set({ 'year': year, 'month': 0, 'date': 1 }).toISOString(),
            end_date: moment().set({ 'year': year, 'month': 5, 'date': 30 }).toISOString()
        })
    }
}
export const getCurrenSem=()=>{
    const sem1StartDate = moment().set({'month':6,'date':1});
    const sem1EndDate = moment().set({'month':11,'date':31});
    const today=moment()
    if(today.isSameOrAfter(sem1StartDate,"day") && today.isSameOrBefore(sem1EndDate,"day")){
        return getKey(1,moment().format("YYYY"));
    }
    else {
        return getKey(2,moment().format("YYYY"))
    }
}
export const getDateTime = (obj, key ,defVal) => {
    const datetime = get(obj, key);
    if (typeof datetime == "string") {
        if (datetime.includes("T")) return datetime;
        else if (datetime.length > 0) {
            return `${datetime}T23:59`
        } else {
            return null;
        }
    } else {
        return defVal;
    }
}

export const isBeforTime = (time1,time2,timezone) =>{
    if(typeof  timezone =="string" && timezone.length>0){
        return moment(time1).tz(timezone).isBefore(moment(time2?time2:new Date()).tz(timezone),"minute")
    }else{
        return moment(time1).isBefore(moment(time2?time2:new Date()),"minute")
    }
}
export const isAfterTime = (time1,time2,timezone) =>{
    if(typeof  timezone =="string" && timezone.length>0){
        return moment(time1).tz(timezone).isAfter(moment(time2?time2:new Date()).tz(timezone),"minute")
    }else{
        return moment(time1).isAfter(moment(time2?time2:new Date()),"minute")
    }
}
export const sortMessage = (messages) => {
    if(isArray(messages)){
       return messages.sort((a,b)=>(new Date(a.createdAt) - new Date((b.createdAt))))
    }else{
        return []
    }
}

export function convertToRoman(num) {
    const roman = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    };
    let str = '';
    for (let i of Object.keys(roman)) {
      let q = Math.floor(num / roman[i]);
      num -= q * roman[i];
      str += i.repeat(q);
    }
    return str;
  }
export function ValidationOfRomanNumerals(str) {
    let regex = new RegExp(/^M{0,3}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/);
    if (str == null) {
        return false;
    }
    return regex.test(str)
}

function romanvalue(r) {
    if (r == 'I')
        return 1;
    if (r == 'V')
        return 5;
    if (r == 'X')
        return 10;
    if (r == 'L')
        return 50;
    if (r == 'C')
        return 100;
    if (r == 'D')
        return 500;
    if (r == 'M')
        return 1000;
    return -1;
}

function romanToDecimal(str) {
    let res = 0;
    let rmoval = str.toUpperCase()
    for (let i = 0; i < rmoval.length; i++) {
        let s1 = romanvalue(rmoval.charAt(i));
        if (i + 1 < rmoval.length) {
            let s2 = romanvalue(rmoval.charAt(i + 1));
            if (s1 >= s2) {
                res = res + s1;
            }
            else {
                res = res + s2 - s1;
                i++;
            }
        }
        else {
            res = res + s1;
        }
    }
    return res;
}



export const getNextNumberMask = (num,secondLast,pageId=1) => {
    const a_sm = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    const a_lg = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    if (!isNaN(parseInt(num))) {
        return parseInt(num) + 1
    }
   else if (ValidationOfRomanNumerals(num)&&(ValidationOfRomanNumerals(secondLast)||!isNaN(parseInt(secondLast))||!secondLast)) {
        return convertToRoman(parseInt(romanToDecimal(num)) + 1)
    } 
    else if (a_sm.includes(num)) {
        const num_ind = a_sm.lastIndexOf(num);
        if (num_ind < 25) {
            return a_sm[num_ind + 1]
        } else {
            return pageId;
        }
    }
    else if (a_lg.includes(num)) {
        const num_ind = a_lg.lastIndexOf(num);
        if (num_ind < 25) {
            return a_lg[num_ind + 1]
        } else {
            return pageId;
        }
    }
    else return pageId
}

export const generateRandomLightColor = () => {
    const red = Math.floor(Math.random() * 255)
    const green = Math.floor(Math.random() * 255)
    const blue = Math.floor(Math.random() * 255)
    const opacity = 0.5
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}

export const getRandomColorInHexa = () =>{
    return Math.floor(Math.random()*16777215).toString(16);
}

export const sortname = (a, b) => {
    const nameA = a?.lastName?.toLowerCase()?.replace(/\s/g, '');
    const nameB = b?.lastName?.toLowerCase()?.replace(/\s/g, '');;
    if (nameA < nameB) { return -1 }
    else if (nameA > nameB) { return 1 }
    else { return 0 }
}

export const  isValidColorCode = (colorCode)=> {
    // Hexadecimal color regex pattern (e.g., #RRGGBB or #RGB)
    const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  
    // RGB color regex pattern (e.g., rgb(255, 0, 0))
    const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
  
    // RGBA color regex pattern (e.g., rgba(255, 0, 0, 0.5))
    const rgbaRegex = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*((1(\.0)?)|(0(\.\d+)?))\)$/;
  
    // Check if it matches the hexadecimal color code pattern
    if (hexRegex.test(colorCode)) {
      return true;
    }
  
    // Check if it matches the RGB color code pattern
    if (rgbRegex.test(colorCode)) {
      // Extract RGB values from the color code
      const [, r, g, b] = colorCode.match(rgbRegex);
      // Check if each value is within the valid range (0-255)
      if (Number(r) >= 0 && Number(r) <= 255 &&
          Number(g) >= 0 && Number(g) <= 255 &&
          Number(b) >= 0 && Number(b) <= 255) {
        return true;
      }
    }
  
    // Check if it matches the RGBA color code pattern
    if (rgbaRegex.test(colorCode)) {
      const [, r, g, b, a] = colorCode.match(rgbaRegex);
      if (Number(r) >= 0 && Number(r) <= 255 &&
          Number(g) >= 0 && Number(g) <= 255 &&
          Number(b) >= 0 && Number(b) <= 255 &&
          (Number(a) >= 0 && Number(a) <= 1)) {
        return true;
      }
    }
    return false;
  }

  
  export const getCurrentSemStartAndEndDate = () => {
    const sem1StartDate = moment().set({ 'month': 6, 'date': 1, 'hour': 0, 'minute': 0 });
    const sem1EndDate = moment().set({ 'month': 11, 'date': 31, 'hour': 23, 'minute': 59 });
    const sem2StartDate = moment().set({ 'month': 0, 'date': 1, 'hour': 0, 'minute': 0 });
    const sem2EndDate = moment().set({ 'month': 5, 'date': 30, 'hour': 23, 'minute': 59 });
    const today = moment();

    if (today.isSameOrAfter(sem1StartDate, "day") && today.isSameOrBefore(sem1EndDate, "day")) {
        return ({
            start: sem1StartDate,
            end: sem1EndDate
        });
    } else {
        return ({
            start: sem2StartDate,
            end: sem2EndDate
        });
    }
}

export const getFormattedTime = () => {
    const date = new Date();
    let hour = date.getHours();
    const minute = date.getMinutes().toString().padStart(2, '0');
    const period = hour >= 12 ? 'PM' : 'AM';
    
    hour = hour % 12;
    hour = hour ? hour : 12;
    
    return { hour: hour.toString(), minute: minute.toString(), period };
  };


// export const isIOS = () => {
//     return [
//         'iPad Simulator',
//         'iPhone Simulator',
//         'iPod Simulator',
//         'iPad',
//         'iPhone',
//         'iPod'
//     ].includes(navigator.platform)
//         // iPad on iOS 13 detection
//         || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
// };


export const isMobileDevice = () => {
    const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    const isAndroidMobile = /Android/i.test(navigator.userAgent) && /Mobile/i.test(navigator.userAgent);
    const isAndroidTablet = /Android/i.test(navigator.userAgent) && !/Mobile/i.test(navigator.userAgent);
    if (isIOS || isAndroidMobile || isAndroidTablet) {
        return true;
    }
    else{
        return false;
    }
};

export const testValidUrl = (url) => {
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlRegex.test(url);
}
