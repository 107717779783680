//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from 'store/constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const logsInitial = null

export const servertime = (state = logsInitial, action) => {
    switch (action.type) {
        case ActionTypes.servertime.createServerTime:
            return action.payload;
        case ActionTypes.servertime.updateServertime:
            return action.payload;
        default:
            return state;
    }
};
