import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Modal as MuiModal,
} from '@material-ui/core'
import { find } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { default as color, default as COLOR } from 'utils/constants/color'
import React, { useEffect, useMemo, useState } from 'react'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultiMenu = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    variant: "menu",
    getContentAnchorEl: () => null,
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '25px',
        width: '300px'
    },
    rootDif: {
        width: '200px'
    },
    rootDifFull: {
        width: "100%"
    },
    sizeSmall: {
        width: '55px',
        height: '25px'
    },
    checkedSwitch: {
        transform: 'translateX(30px) !important'
    },
    label: {
        textTransform: 'uppercase',
    },
    colorPrimary: {
        color: `${COLOR.green}!important`
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
        minWidth: 120
    },
    formControlStudent: {
        backgroundColor: "#fff",
        maxWidth:"250px"
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));


export const MultiSelecter = ({ options, onSelect , selectedUser, titleName, selectTitle }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    const isAllSelected = useMemo(() => (options.length > 0 && selected.length === options.length), [options, selected]);
    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options.map(k=>k.id));
            onSelect(selected.length === options.length ? [] : options.map(k=>k.id))
            return;
        }
        onSelect(value)
        setSelected(value);
    };
    useEffect(() => {
        setSelected(selectedUser)
    }, [selectedUser])

    return (
        <FormControl variant="outlined" className={classes.formControlStudent}>
            {/* <InputLabel id="mutiple-select-label" style={{ backgroundColor: "#fff" }}>{titleName}</InputLabel> */}
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {`${isAllSelected || selected.length == 0 ? `All ${selectTitle}` : `${selectTitle} (${selected.length})`}`}
                    </div>
                )}
                MenuProps={MultiMenu}
            >
                <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                    />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        <ListItemIcon>
                            <Checkbox checked={Boolean(find(selected, k => k === option.id))} />
                        </ListItemIcon>
                        <ListItemText primary={`${option.displayName}`} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
